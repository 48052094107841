<style src="./RgTabsItem.scss" lang="scss" scoped></style>
<template lang="html">
  <li
    :class="{ selected: selected, valid: checkValidTab }"
    class="rg-tabs-item"
    @click="chooseItem"
  >
    <span
      v-show="checkValidTab"
      class="badge-valid"
      title="Contém informações inválidas"
      >!</span
    >
    <div v-if="granted === false" class="permission-locked">
      <IconLocked class="icon-lock" />
    </div>
    {{ label }}
  </li>
</template>

<script>
import RgValidatorMixin from "~tokio/primitive/validation/RgValidatorMixin";
import { IconLocked } from "../../../../primitive/icon";
export default {
  name: "RgTabsItem",
  components: {
    IconLocked,
  },
  mixins: [RgValidatorMixin],
  props: {
    label: {
      default: "",
    },
    selected: {
      default: 0,
    },
    validated: {
      default: null,
    },
    granted: {
      default: true,
    },
  },
  data() {
    return {
      anotherRules: {
        validList: (pValue, pError) => {
          let statusReturn = true;
          if (this.validated !== null && this.validated === false) {
            statusReturn = false;
            pError.push("Contém informações inválidas");
          }
          return statusReturn;
        },
      },
      error: [],
    };
  },
  computed: {
    validValue() {
      return true;
    },
    checkValidTab() {
      return this.validated === false;
    },
  },
  watch: {
    validated(pVal) {
      if (pVal === true) this.error = [];
    },
  },
  methods: {
    chooseItem() {
      this.$emit("click");
    },
  },
};
</script>
