<style src="./PersonalContact.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="personal-contact">
    <RgInputContact
      label="DADOS DO CONTATO"
      @new-contact="refreshContactList"
    />
    <RgListContact :refresher="listRefresher" label="LISTA DE CONTATOS" />
  </section>
</template>

<script>
import RgInputContact from "./rg-input-contact/RgInputContact";
import RgListContact from "./rg-list-contact/RgListContact";

export default {
  name: "PersonalContact",
  components: {
    RgInputContact,
    RgListContact,
  },
  props: {
    pesId: {
      default: "",
    },
  },
  data() {
    return {
      listRefresher: "",
    };
  },
  methods: {
    refreshContactList() {
      this.listRefresher = new Date().getTime();
    },
  },
};
</script>
