<style src="./SearchResult.scss" lang="scss" scoped></style>
<template>
  <section
    v-shortkey="['enter']"
    class="module-list"
    @shortkey="selectFirstItem"
  >
    <div
      v-for="(module, idx) in resultList"
      :key="idx"
      :class="{ 'menu-button--selected': idx === selectedItem }"
      class="search-menu-button"
      @click="selectingItem(module)"
    >
      <component :is="module.iconName" class="icon" />

      <span class="search-operation">{{ module.name }}</span>

      <div class="module-name-container">
        <span class="search-module-name">{{ module.module }}</span>
      </div>
    </div>
  </section>
</template>

<script>
import * as Icons from "~tokio/primitive/icon/modules";
import { IconEllipsis } from "~tokio/primitive";

export default {
  name: "SearchResult",
  components: {
    ...Icons,
    IconEllipsis,
  },
  props: {
    resultList: {
      default: null,
    },
  },
  data() {
    return {
      selectedItem: null,
    };
  },
  methods: {
    selectingItem(moduleItem) {
      this.$emit("open-link", moduleItem.link);
    },
    selectFirstItem() {
      const firstItem = this.resultList && this.resultList[0].link;
      if (firstItem) this.$emit("open-link", firstItem);
    },
  },
};
</script>
