import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  return AuthLegacyRequest.post(
    "exames2/controller-exame-agendamento-procedimento/transferir",
    variables,
  )
    .then(({ data }) => {
      if (data.dados === "Erro ao transferir os agendamentos") {
        return {
          status: false,
          msg: "Erro ao transferir os agendamentos",
        };
      } else if (data.dados === "ProfissionalNaoRealizaProcedimentoException") {
        return {
          status: false,
          msg:
            "Não foi possível realizar a(s) transferêcia(s). Motivo: Procedimento(s) não realizado(s) pelo profissional de destino",
        };
      }
      return data.dados;
    })
    .catch((pErr) => {
      console.log("error", pErr);
      throw pErr;
    });
};
