<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
export default {
  name: "RgComboboxOrderBy",
  mixins: [RgComboboxMixin],
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },

  data: () => {
    return {};
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      const data = this.data;

      this.mData = [];

      data.forEach((item) => {
        this.mData.push({ value: item.data, text: item.label });
      });

      if (this.mData.length === 1) {
        this.$emit("selectOnlyItem", this.mData[0].value);
      }
    },
  },
};
</script>
