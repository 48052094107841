<style src="./RgUnitHealthSearch.scss" lang="scss" scoped></style>

<template lang="html">
  <div
    :class="{ 'rg-unit-health-search__focus': focusContainer }"
    class="rg-unit-health-search"
  >
    <form class="rg-unit-health-search--unit-form">
      <div class="rg-unit-health-search--search">
        <IconSearch class="rg-unit-health-search--icon-search" />
        <input
          v-model="search"
          type="search"
          class="rg-unit-health-search--search-input"
          placeholder="Procure por uma unidade de saúde"
          @focus="changeFocus(true)"
          @blur="changeFocus(false)"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { IconSearch } from "~tokio/primitive";

export default {
  name: "RgUnitHealthSearch",
  components: {
    IconSearch,
  },
  data() {
    return {
      search: "",
      focusContainer: false,
    };
  },
  watch: {
    search(pData) {
      this.$emit("search", pData);
    },
  },
  methods: {
    changeFocus(pState) {
      this.focusContainer = pState;
    },
  },
};
</script>
