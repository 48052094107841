<style src="./ModalHospBillChildbirthTincture.scss" lang="scss" scoped></style>
<template>
  <div v-if="show" class="modal-hospitalization-billing-childbirth-tincture">
    <RgBaseModal
      :show="show"
      overflow-y-scroll
      class="base-modal-class"
      @close="close"
    >
      <div slot="header" class="title-modal">
        <h3>Dados Adicionais do Procedimento</h3>
      </div>
      <div slot="body" class="content-modal">
        <RgContentHospBillChildbirthTincture
          :procedure="procedure"
          :required-documents="requiredDocuments"
          @onFinish="onFinish"
          @close="close"
        />
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import RgContentHospBillChildbirthTincture from "./rg-content-hosp-bill-childbirth-tincture/RgContentHospBillChildbirthTincture";
import { RgBaseModal } from "~tokio/primitive/notification";

export default {
  name: "ModalHospBillChildbirthTincture",
  components: {
    RgContentHospBillChildbirthTincture,
    RgBaseModal,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    procedure: {
      default: "",
    },
    requiredDocuments: {},
  },
  data() {
    return {
      list: [],
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    onFinish(event) {
      this.$emit("onFinish", event);
    },
  },
};
</script>
