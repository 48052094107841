import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation($personId: Int!) {
    CreatePatient(pes_id: $personId) {
      pac_id
      pac_id_pessoas
      pac_id_motivo_inabilitacao
      pac_id_inabilitado_por
      pac_prontuario_unico
      pac_data_cadastro
      pac_ativo
      pac_bloqueado
    }
  }
`;

export default ({ commit }, pPersonId) => {
  const variables = {
    personId: pPersonId,
  };

  try {
    const { data } = GraphQLClient.mutate({ mutation, variables });
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};
