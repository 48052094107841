import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async (variables) => {
  return AuthLegacyRequest.post(
    "exames2/controller-exame-agendamento-procedimento/combo-box-horarios-disponiveis-baseado-em-vagas-ocupadas",
    variables,
  )
    .then(({ data }) => {
      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
