import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation($user: UserInputType) {
    SaveUser(user: $user) {
      usu_id
      usu_id_grupos_usuario
      usu_id_municipios
      usu_id_unidades_saude_padrao
      usu_id_setor_padrao
      usu_id_clientes
      usu_nome
      usu_login
      usu_ativo
      usu_senha
      usu_token_pep
    }
  }
`;

export default async ({ rootState }, pUser) => {
  const variables = {
    user: pUser,
  };
  try {
    const { data } = await GraphQLClient.mutate({
      mutation,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.SaveUser;
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors);
  }
};
