<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchSector from "./action/SearchSector";

export default {
  name: "RgComboboxSector",
  mixins: [RgComboboxMixin],
  props: {
    unitHealthId: {
      required: true,
      default: 0,
    },
    procedureName: {
      type: String,
      default: "",
    },
  },
  data: () => {
    return {};
  },
  watch: {
    procedureName(pValue) {
      this.fillData();
    },
    unitHealthId(pValue) {
      this.fillData();
    },
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];
      const unitHealthId = Number(this.unitHealthId);
      const isToFilter = unitHealthId > 0;

      if (isToFilter) {
        const data = await SearchSector(this.unitHealthId, this.procedureName);

        data.forEach((item) => {
          this.mData.push({ value: item.data, text: item.label });
        });
      }
    },
  },
};
</script>
