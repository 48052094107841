<template>
  <div :class="mainClass">
    <RgSearch
      ref="RgSearch"
      v-model="mutableExamList"
      :search-function="actionSearchFilter"
      :clear-function="actionCleanForm"
      :build-filter="generateFilter"
      :show-footer="false"
      :show-title="false"
      :show-empty-message="false"
      :showTitle="false"
      :disabledShorkey="showModalConfirmDeletion"
      @afterPerformSearch="actionAfterSearchFilter"
      @count="getCountValue"
    >
      <div slot="filters">
        <FilterPerDay v-if="isPerDay" ref="FilterPerDay" />
        <FilterPerPeriod v-else ref="FilterPerPeriod" />
      </div>

      <div v-if="mutableExamList.length > 0" :class="searchClass">
        <div class="content-grid">
          <legend class="legend">Profissionais</legend>

          <div class="table-one">
            <SmartTable
              ref="smartTableEmployee"
              :name="getSmartTableName + 'Professional'"
              :columns="COLUMN_TABLE_ONE"
              :body="mutableExamList"
              :total="Number(mutableExamList.length)"
              :initial-columns="INITIAL_COLUMNS"
              :show-pagination="false"
              :isOpaque="hasVacancyUnavailable"
              :colorLine="actionColorLine"
              index-column="eea_id"
              second-index-column="data_exame"
              toggleSelected
              @getLine="selectEmployeeData"
            >
              <div slot="legends" class="captions">
                <div class="circle --orange" />
                <span class="caption">Procedimento inabilitado</span>

                <div class="circle --green" />
                <span class="caption">Sem procedimento associado</span>

                <div class="circle --grey" />
                <span class="caption">Sem vagas disponíveis</span>

                <div class="circle --red" />
                <span class="caption">Inabilitado no dia</span>
              </div>
            </SmartTable>
          </div>

          <div class="painel-button">
            <RgNewScheduleButton
              :disabled="!scheduleSelected"
              title="Agendar exame"
              class="buttons-top"
              @click="actionSchedule"
            />

            <RgEditButton
              :disabled="!scheduleSelected"
              title="Editar Escala de Atendimento"
              class="buttons-top"
              @click="actionOpenModalChangeProfessionalScale"
            />

            <RgPrinterButton
              :disabled="!hasScheduledPatient"
              title="Imprimir ficha de controle ambulatorial"
              class="buttons-top"
              @click="actionPrintControl"
            />
          </div>

          <div class="separator" />

          <legend class="legend">Pacientes Agendados para a Escala</legend>

          <div class="table-two">
            <SmartTable
              ref="smartTableSchedule"
              :name="getSmartTableName + 'Patient'"
              :columns="COLUMN_TABLE_TWO"
              :body="mutableExamSchedule.data"
              :total="Number(mutableExamSchedule.total)"
              :initial-columns="7"
              :show-pagination="false"
              toggleSelected
              @getLine="getSchedulePatientScaleRow"
            />
          </div>

          <div class="painel-button">
            <RgEditButton
              :disabled="examSelected"
              class="buttons-top"
              title="Editar Agendamento"
              @click="actionEditExamAppointment"
            />

            <RgShowButton
              :disabled="examSelected"
              class="buttons-top"
              title="Detalhes"
              @click="actionOpenModalExamDetails"
            />

            <RgDeleteScheduleButton
              :disabled="examSelected"
              title="Excluir Agendamento"
              class="buttons-top"
              @click="openModalConfirmSchedulingDeletion"
            />

            <RgPersonCardButton
              :disabled="examSelected"
              title="Editar Paciente"
              class="buttons-top"
              @click="actionPatientRecord"
            />

            <RgPersonDropdown
              :disabled="examSelected"
              :item="employeeData || {}"
              :action-options="itemActionOptionsPerson(employeeData)"
              show-up
              show-left
              class="dropdown-top"
            />

            <RgDropdownPrinter
              :disabled="examSelected"
              :item="employeeData || {}"
              :action-options="itemActionOptionsPrinter(employeeData)"
              show-up
              show-left
              class="dropdown-top"
            />
          </div>
        </div>
      </div>

      <div v-else class="no-result">
        <IconEmpty />
        <span class="message">
          Não foram encontrados resultados para essa busca
        </span>
      </div>
    </RgSearch>

    <ModalConfirmDeletion
      v-bind="propsModalConfirmDeletion"
      :maxlength="250"
      @close="actionCloseModalConfirmDeletion"
      @reason="setReason"
      @reSearch="reSearchTables"
    />

    <ModalChangeProfessionalScale
      ref="modalChangeProfessionalScale"
      :show="showModalChangeProfessionalScale"
      :snap="employeeData || {}"
      @reSearch="reSearchTables"
      @close="actionCloseModalChangeProfessionalScale"
      @error="handleErrorChangeProfessionalScale"
    />

    <ModalExamDetails
      :show="showModalExamDetails"
      :procedure="patientData ? patientData.stp_novo_nome_procedimento : ''"
      :pxe-id="patientData ? Number(patientData.pxe_id) : 0"
      :unity-health="patientData ? Number(patientData.uns_id) : 0"
      :exam-id="patientData ? Number(patientData.exa_id) : 0"
      @close="actionCloseModalExamDetails"
    />

    <ModalPatientImageInRow
      ref="modalPatientImageInRow"
      :show="showModalPatientImageInRow"
      :fill-id="filId"
      :modId="examModuleId"
      hideButtons
      @close="closeModalPatientImageInRow"
    />

    <ModalTypesPrint
      ref="modalTypePrint"
      :show="showModalTypesPrint"
      :exam-ids="[examIdToPrint]"
      @close="actionCloseModalTypesPrint"
    />

    <ModalScaleConflict
      ref="modalScaleConflict"
      :show="showModalScaleConflict"
      :conflicts="scaleConflicts"
      :history="scaleHistory"
      @close="actionCloseModalScaleConflict"
    />
  </div>
</template>

<script>
import moment from "moment";
import { isEmpty } from "lodash";
import ConsultationControlHTML from "$exam/submodules/schedule/html/ConsultationControlHTML";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb.js";
import RecommendationsSheetHTML from "$exam/submodules/schedule/html/RecommendationsSheetHTML";

import {
  RgNewScheduleButton,
  RgDeleteScheduleButton,
  RgEditButton,
  RgPrinterButton,
  RgPersonDropdown,
  RgPersonCardButton,
  RgShowButton,
  IconEmpty,
  RgDropdownPrinter,
} from "~tokio/primitive";

import RgSearch from "~tokio/foundation/rg-search/RgSearch";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";

import {
  ModalChangeProfessionalScale,
  ModalScaleConflict,
  ModalExamDetails,
  ModalTypesPrint,
} from "$exam/submodules/schedule/component/";

import {
  ModalPatientImageInRow,
  ModalConfirmDeletion,
} from "~tokio/primitive/modal";

import FilterPerDay from "$exam/submodules/schedule/component/main/component/filter/FilterPerDay";
import FilterPerPeriod from "$exam/submodules/schedule/component/main/component/filter/FilterPerPeriod";

import ExamMixinPerDay from "$exam/submodules/schedule/component/main/mixin/ExamMixinPerDay";
import ExamMixinPerPeriod from "$exam/submodules/schedule/component/main/mixin/ExamMixinPerPeriod";
import PermissionsMixin from "$exam/submodules/schedule/component/main/mixin/PermissionsMixin";

import GetColorRowSmartTable from "~common/utils/GetColorRowSmartTable";

export default {
  name: "ExamSchedulePerDay",
  components: {
    RgSearch,
    SmartTable,
    RgNewScheduleButton,
    RgEditButton,
    RgPrinterButton,
    RgPersonDropdown,
    RgDropdownPrinter,
    RgPersonCardButton,
    RgDeleteScheduleButton,
    ModalConfirmDeletion,
    ModalChangeProfessionalScale,
    ModalPatientImageInRow,
    ModalTypesPrint,
    ModalScaleConflict,
    FilterPerDay,
    FilterPerPeriod,
    RgShowButton,
    ModalExamDetails,
    IconEmpty,
  },
  mixins: [ExamMixinPerDay, ExamMixinPerPeriod, PermissionsMixin],
  props: {
    isPerDay: Boolean,
  },

  data() {
    return {
      showModalChangeProfessionalScale: false,
      mutableExamList: [],
      employeeData: null,
      payloadFilterExamSchedule: {},
      patientData: null,
      filId: null,
      totalAgenda: 0,
      procedureId: "",
      modalChangeProfessionalScale: false,
      eapId: null,
      showModalConfirmDeletion: false,
      showModalPatientImageInRow: false,
      showModalExamDetails: false,
      showModalTypesPrint: false,
      showModalScaleConflict: false,
      examIdToPrint: null,
      mutableExamSchedule: {
        data: [],
        total: 0,
      },
      form: {
        selectedUnity: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        selectedSector: null,
        exe_codigo_procedimento: null,
        selectedScale: null,
        date: moment().format("DD/MM/YYYY"),
        selectedHealthPlan: null,
      },
      routersNotResetFilterPerDay: [
        "exam.schedule.per-day.form",
        "exam.schedule.per-day.patient",
        "exam.schedule.per-day.edit.form",
        "exam.schedule.per-day.observation-queue",
      ],
      routersNotResetFilterPerPeriod: [
        "exam.schedule.per-period.edit.form",
        "exam.schedule.per-period.patient",
        "exam.schedule.per-period.form",
        "exam.schedule.per-period.observation-queue",
      ],
      scaleConflicts: [],
      scaleHistory: [],
    };
  },

  computed: {
    actionColorLine() {
      return {
        get: (item) => {
          if (
            parseInt(item.funcionario_inabilitado) === 1 ||
            parseInt(item.eha_atendimento) === 0
          ) {
            return GetColorRowSmartTable("red");
          }
          if (parseInt(item.eea_quantidade_restantes) === 0) {
            return GetColorRowSmartTable("grey");
          }
          if (parseInt(item.vinculo_procedimento_ativo) === 0) {
            return GetColorRowSmartTable("orange");
          }
          if (parseInt(item.possuiProcedimento) === 0) {
            return GetColorRowSmartTable("green");
          }
        },
      };
    },

    getSmartTableName() {
      return `ExamSchedule${this.isPerDay ? "PerDay" : "PerPeriod"}`;
    },

    examModuleId() {
      return this.$store.state.Login.route_module_map.exam;
    },
    hasVacancyUnavailable() {
      return {
        check: (row) => {
          if (row.eea_quantidade_restantes) {
            return parseInt(row.eea_quantidade_restantes) <= 0;
          }
        },
      };
    },

    mainClass() {
      return this.isPerDay
        ? "exam-schedule-per-day"
        : "exam-schedule-per-period";
    },

    searchClass() {
      return this.isPerDay
        ? "exam-schedule-per-day-search"
        : "exam-schedule-per-period-search";
    },

    selectedEmployee() {
      return this.$store.getters["Exam/Schedule/GET_SELECTED_EMPLOYEE"];
    },

    examSelected() {
      return !this.employeeData || !this.patientData;
    },

    scheduleSelected() {
      return this.employeeData !== null;
    },

    hasFreeSchedule() {
      return (
        this.employeeData &&
        Number(this.employeeData.eea_quantidade_restantes) > 0
      );
    },

    hasScheduledPatient() {
      return (
        this.mutableExamSchedule.data &&
        this.mutableExamSchedule.data.length > 0
      );
    },

    appointmentIdToRemove() {
      return this.patientData && this.patientData.exa_id
        ? Number(this.patientData.exa_id)
        : 0;
    },

    canSchedulePatient() {
      return this.scheduleSelected && this.hasPermissionsToSchedule();
    },

    canEditSchedulePatient() {
      return !this.examSelected && this.hasPermissionsToEditSchedule();
    },

    printOptionsPreference() {
      return this.isPerDay
        ? this.$store.getters["Login/GET_PREFERENCES"][
            "tViewBuscaAgendamentoDia.opcoesImpressao"
          ]
        : this.$store.getters["Login/GET_PREFERENCES"][
            "tViewBuscaAgendamentoPeriodo.opcoesImpressao"
          ];
    },

    propsModalConfirmDeletion() {
      const show = this.showModalConfirmDeletion;

      const confirm = () => this.actionDeleteSchedule();

      const msgSuccess = "Agendamento removido com sucesso!";

      return { show, confirm, msgSuccess };
    },
  },

  created() {
    this.INITIAL_COLUMNS = 8;

    const isPerPeriod = !this.isPerDay;

    if (isPerPeriod) {
      this.COLUMN_TABLE_ONE = [
        { name: "Profissional", key: "fun_nome" },
        { name: "Ocupação", key: "ocp_nome" },
        { name: "Dia da Semana", key: "dsm_nome" },
        { name: "Início", key: "inicio" },
        { name: "Qtd. de Horários", key: "eea_quantidade_exames" },
        { name: "Qtd. Ocupada", key: "eea_quantidade_marcadas" },
        { name: "Qtd. Livre", key: "eea_quantidade_restantes" },
        { name: "Tipo de Escala", key: "ees_nome" },
        { name: "Tipo de Horário", key: "eti_nome" },
      ];
    } else {
      this.COLUMN_TABLE_ONE = [
        { name: "Profissional", key: "fun_nome" },
        { name: "Ocupação", key: "ocp_nome" },
        { name: "Início", key: "inicio" },
        { name: "Qtd. de Horários", key: "eea_quantidade_exames" },
        { name: "Qtd. Ocupada", key: "eea_quantidade_marcadas" },
        { name: "Qtd. Livre", key: "eea_quantidade_restantes" },
        { name: "Tipo de Escala", key: "ees_nome" },
        { name: "Tipo de Horário", key: "eti_nome" },
      ];
    }

    this.COLUMN_TABLE_TWO = [
      { name: "Hora", key: "eap_hora" },
      { name: "Paciente", key: "pes_nome" },
      { name: "Procedimento", key: "stp_novo_nome_procedimento" },
      { name: "Subprocedimentos", key: "subProcedimento" },
      { name: "Vagas Ocupadas", key: "eap_vagas_ocupadas" },
      { name: "Status Meu Esus", key: "sme_nome" },
    ];

    if (isPerPeriod) {
      this.COLUMN_TABLE_ONE.splice(2, 0, { name: "Data", key: "data_exame" });
      this.INITIAL_COLUMNS = 9;
    }
  },

  destroyed() {
    this.cleanVuex();
  },

  mounted() {
    const isExamSchedule = this.$route.params.isExamSchedule;

    if (isExamSchedule) {
      this.handleExamSchedulePrint(this.$route.params.examId);
    }

    this.fillFilter();
    // this.setFilterOnStore();
  },

  methods: {
    cleanVuex() {
      if (!this.isPerDay) {
        // Aqui eu verifico as rotas que a limpeza dos filtros não será necessária.
        // ... Caso exista alguma rota que esteja limpando o filtro erroneamente,
        // ... adicionar a mesma neste array.
        const foundRoute = this.findRouterNotResetFilter(this.$route.name);

        if (!foundRoute) {
          this.removeFilterPerPeriod();
        }

        const perDayExist = ValidateIfRouteExistInBreadscrumb(
          this.routePerDay,
          this.$route.meta.breadcrumb,
        );

        if (perDayExist) {
          this.$store.commit("Exam/Schedule/RESET_FILTER_DATE");
          this.$store.commit("Exam/Schedule/RESET_FILTER_EXAMS_PER_DAY");

          this.$store.commit("Exam/Schedule/UNSELECT_PATIENT_DATA");
          this.$store.commit("Exam/Schedule/UNSELECT_EMPLOYEE_DATA");
        }
      } else {
        // Aqui eu verifico as rotas que a limpeza dos filtros não será necessária.
        // ... Caso exista alguma rota que esteja limpando o filtro erroneamente,
        // ... adicionar a mesma neste array.
        const foundRoute = this.findRouterNotResetFilter(this.$route.name);

        if (!foundRoute) {
          this.removeFilterPerDay();
        }
        const perPeriodExist = ValidateIfRouteExistInBreadscrumb(
          this.routePerPeriod,
          this.$route.meta.breadcrumb,
        );

        if (perPeriodExist) {
          this.$store.commit("Exam/Schedule/RESET_FILTER_EXAMS_PER_PERIOD");

          this.$store.commit("Exam/Schedule/UNSELECT_PATIENT_DATA");
          this.$store.commit("Exam/Schedule/UNSELECT_EMPLOYEE_DATA");
        }
      }
    },

    async reSearchTables() {
      try {
        await this.$refs.RgSearch.performSearch();

        if (this.selectedEmployee) {
          if (this.$refs.smartTableEmployee) {
            this.$refs.smartTableEmployee.selectRow(this.selectedEmployee);
            await this.selectEmployeeData(this.selectedEmployee, true);
          }
        }
      } catch (pError) {
        this.$toaster.error(pError);
      }
    },

    async actionPrintControl() {
      const client = this.$store.getters["Login/GET_CLIENT_CODE"];
      const employee = { client, ...this.employeeData };

      const variable = {
        eha_id: employee.eha_id,
        profissional: employee.profissional,
      };

      const data = await this.$store.dispatch(
        "Exam/Schedule/GET_PRINT_CONSULTATION_CONTROL",
        variable,
      );

      const html = ConsultationControlHTML(employee, data);
      this.$utils.print.printHtml(html);
    },

    generateFilter() {
      const filter = this.isPerDay
        ? this.$refs.FilterPerDay.getForm()
        : this.$refs.FilterPerPeriod.getForm();

      const variables = this.isPerDay
        ? this.$refs.FilterPerDay.getVariables()
        : this.$refs.FilterPerPeriod.getVariables();

      this.setFilterOnStore(filter);

      return variables;
    },

    setFilterOnStore(filter) {
      // #ARTHUR: COMENTEI O CÓDIGO POIS NÃO ESTAVA ATUALIZANDO A STORE CASO #4092
      // const filterCommit = this.$store.getters[
      //   "Exam/Schedule/GET_FILTER_EXAM_PER_DAY"
      // ];
      const path = this.isPerDay
        ? "Exam/Schedule/SET_FILTER_EXAMS_PER_DAY"
        : "Exam/Schedule/SET_FILTER_EXAMS_PER_PERIOD";

      // if (filterCommit) {
      //   this.$store.commit(path, filterCommit);
      // } else {
      this.$store.commit(path, filter);
      // }
    },

    async actionSearchFilter(pData) {
      try {
        this.$loader.start("Buscando agenda dos profissionais.");
        const path = this.isPerDay
          ? "Exam/Schedule/SEARCH_EMPLOYEE_AGENDA"
          : "Exam/Schedule/SEARCH_EMPLOYEE_SCHEDULE_PERIOD";
        const data = await this.$store.dispatch(path, pData);
        return data;
      } catch (e) {
        this.$toaster.warning(e);
      } finally {
        this.$loader.finish();
      }
    },

    actionAfterSearchFilter(submitFromButton) {
      if (this.$refs.smartTableSchedule) {
        this.$refs.smartTableSchedule.cleanSelectRow();
      }
      if (this.$refs.smartTableEmployee) {
        this.$refs.smartTableEmployee.cleanSelectRow();
      }
      this.mutableExamSchedule = {
        data: [],
        total: 0,
      };
      this.employeeData = null;

      if (submitFromButton && this.$refs.smartTableEmployee) {
        this.$refs.smartTableEmployee.setScrollTopZero();
      }
    },

    generateFilterExamSchedule(idHistorySchedule) {
      const filter = this.isPerDay
        ? this.$refs.FilterPerDay.getForm()
        : this.$refs.FilterPerPeriod.getForm();

      const variables = {
        arrFormData: {
          uns_id: filter.selectedUnity || filter.unit,
          set_id: filter.selectedSector || filter.sector,
          ees_id: filter.selectedScale,
          data_inicial: filter.date || moment().format("DD/MM/YYYY"),
          eha_id: Number(idHistorySchedule),
        },
      };
      return variables;
    },

    async searchFilterExamsSchedule() {
      try {
        // this.$loader.start();
        // await this.$refs.RgSearch.performSearch();

        this.mutableExamSchedule = await this.$store.dispatch(
          "Exam/Schedule/SEARCH_APPOINTMENT_SCHEDULE",
          this.payloadFilterExamSchedule,
        );

        if (this.isPerDay) {
          const filter = this.$refs.FilterPerDay.getVariables();
          if (filter.arrFormData.stp_id) {
            this.mutableExamSchedule.data = this.mutableExamSchedule.data.filter(
              (el) => {
                return (
                  parseInt(el.stp_id) === parseInt(filter.arrFormData.stp_id)
                );
              },
            );
          }
        }

        this.$store.commit(
          "Exam/Schedule/SET_EXAM_SCHEDULE",
          this.mutableExamSchedule,
        );
      } catch (Err) {
        this.$toaster.error(Err);
      }
    },

    actionPatientRecord() {
      const patientId = this.patientData.pes_id;
      this.$store.commit("Person/Patient/SET_PERSON_ID", Number(patientId));

      const routerName = this.isPerDay ? this.perDay : this.perPeriod;

      this.$router.push({
        name: `exam.schedule.${routerName}.patient`,
      });
    },

    selectEmployeeData(pData) {
      if (pData) {
        const index = this.mutableExamList.findIndex((row) => {
          return (
            row.eea_id === pData.eea_id && row.data_exame === pData.data_exame
          );
        });
        this.employeeData = this.mutableExamList[index];

        this.$store.commit(
          "Exam/Schedule/SELECT_EMPLOYEE_DATA",
          this.employeeData,
        );

        if (this.employeeData?.eha_id) {
          this.payloadFilterExamSchedule = this.generateFilterExamSchedule(
            this.employeeData.eha_id,
          );

          this.searchFilterExamsSchedule();
        }
      } else {
        this.patientData = null;
        this.employeeData = null;
        this.$refs.smartTableSchedule.cleanSelectRow();
        this.mutableExamSchedule = {
          data: [],
          total: 0,
        };
        this.$store.commit("Exam/Schedule/UNSELECT_PATIENT_DATA");
      }
    },

    getSchedulePatientScaleRow(pValue) {
      this.$store.commit("Exam/Schedule/SELECT_PATIENT_DATA", pValue);

      this.patientData = pValue;
      this.filId = (pValue && Number(pValue.fil_id)) || null;
    },

    getCountValue(pValue) {
      this.totalAgenda = Number(pValue) || 0;
    },

    actionSchedule() {
      if (!this.hasFreeSchedule) {
        return this.$toaster.warning(
          "Profissional não tem mais vagas disponíveis!",
          "Sem vagas",
        );
      }

      if (this.employeeData && this.employeeData.possuiProcedimento === "0") {
        return this.$toaster.warning(
          "A escala não pode ser usada pois este profissional não possui um procedimento associado a ele.",
        );
      }

      this.$store.commit("Exam/Schedule/UNSELECT_PATIENT_DATA");

      this.$store.commit(
        "Exam/Schedule/SELECT_EMPLOYEE_DATA",
        this.employeeData,
      );

      const routeName = this.isPerDay
        ? "exam.schedule.per-day.form"
        : "exam.schedule.per-period.form";

      this.$router.push({ name: routeName });
    },

    validateProcedure(pItem, pErrors) {
      if (this.validateEmployee({}, pErrors) && isEmpty(pItem)) {
        pErrors.push("Campo obrigatório");
      }

      return pErrors.length <= 0;
    },

    itemActionOptionsPerson(pItem) {
      return [
        {
          label: "Exibir observações do paciente na fila de regulação",
          action: this.actionObservationPatient,
          disable: false,
        },
        {
          label: "Exibir imagens do paciente na fila de regulação",
          action: this.actionOpenModalPatientImageInRow,
          disable: false,
        },
      ];
    },

    itemActionOptionsPrinter(pItem) {
      if (!this.patientData) {
        return;
      }

      return [
        {
          label: "Imprimir comprovante de agendamento",
          action: () => {
            this.examIdToPrint = this.patientData.eap_id;
            this.actionOpenModalTypesPrint();
          },
          disable: false,
        },
        {
          label: "Imprimir recomendações e preparo do exame",
          action: this.actionPrintRecommendationsSheet,
          disable: false,
        },
      ];
    },

    actionOpenModalTypesPrint() {
      this.showModalTypesPrint = true;
    },

    actionCloseModalTypesPrint() {
      this.showModalTypesPrint = false;
    },

    actionCloseModalScaleConflict() {
      this.showModalScaleConflict = false;
    },

    actionOpenModalScaleConflict() {
      this.showModalScaleConflict = true;
    },

    itemActionOptionsPep(pItem) {
      return [
        {
          label: "Enviar atendimento para o PEP",
          disable: false,
        },
        {
          label: "Cancelar atendimento no PEP",
          disable: false,
        },
      ];
    },

    actionObservationPatient() {
      if (this.filId) {
        const patientData = this.patientData;
        const str = this.isPerDay ? this.perDay : this.perPeriod;
        this.$router.push({
          name: `exam.schedule.${str}.observation-queue`,
          params: { patientData },
        });
      } else {
        this.$toaster.warning(
          "Este paciente não foi cadastrado pela fila de regulação.",
        );
      }
    },

    actionOpenModalPatientImageInRow() {
      if (this.filId) {
        this.showModalPatientImageInRow = true;
      } else {
        this.$toaster.warning(
          "Este paciente não foi cadastrado pela fila de regulação.",
        );
      }
    },
    closeModalPatientImageInRow() {
      this.showModalPatientImageInRow = false;
    },

    async actionPrintRecommendationsSheet() {
      const data = {
        client: this.$store.getters["Login/GET_CLIENT_CODE"],
        unit: this.$store.getters["Login/GET_UNIT_HEALTH_NAME"],
        hour: moment().format("HH:mm"),
        date: moment().format("DD/MM/YYYY"),
      };

      try {
        const withoutSubProcedure = this.patientData.subProcedimento === "NÃO";

        const path = withoutSubProcedure
          ? "Exam/Schedule/GET_PRINT_PROCEDURE_RECOMMENDATIONS_SHEET"
          : "Exam/Schedule/GET_PRINT_SUB_PROCEDURE_RECOMMENDATIONS_SHEET";

        const result = await this.$store.dispatch(path, {
          intIdExameAgendamentoProcedimento: this.patientData.eap_id,
        });

        const hasRecomendation =
          (result.length > 0 && result[0].possuiRecomendacao === "1") ||
          (result && result.possuiRecomendacao === "1");

        if (hasRecomendation) {
          const html = RecommendationsSheetHTML(result, data);
          this.$utils.print.printHtml(html);
        } else {
          this.$toaster.warning(`Não existem recomendações para este exame.`);
        }
      } catch (error) {
        this.$toaster.error(error);
      }
    },

    openModalConfirmSchedulingDeletion() {
      this.showModalConfirmDeletion = true;
    },

    actionOpenModalExamDetails() {
      this.showModalExamDetails = true;
    },

    actionCloseModalExamDetails() {
      this.showModalExamDetails = false;
    },

    actionCleanForm() {
      this.mutableExamList = [];
      this.employeeData = null;
      this.patientData = null;
      this.payloadFilterExamSchedule = {};
      this.mutableExamSchedule = {
        data: [],
        total: 0,
      };
      this.totalAgenda = 0;
      this.procedureId = "";

      this.isPerDay
        ? this.$refs.FilterPerDay.cleanFilter()
        : this.$refs.FilterPerPeriod.cleanFilter();

      this.$refs.smartTableEmployee.cleanSelectRow();
      this.$refs.smartTableSchedule.cleanSelectRow();
      this.cleanVuex();
    },

    actionCloseModalConfirmDeletion() {
      this.showModalConfirmDeletion = false;
    },

    setReason(value) {
      this.reason = value;
    },

    async actionDeleteSchedule() {
      try {
        this.$loader.start("Excluindo...");
        const variables = {
          intIdExameAgendamentoProcedimento: this.patientData.eap_id,
          strMotivo: this.reason,
        };
        await this.$store.dispatch("Exam/Schedule/REMOVE_EXAM", variables);

        this.$toaster.success("Exclusão realizada com sucesso.");
        await this.searchFilterExamsSchedule();
        this.clearSelectionSchedule();
      } catch (err) {
        this.$toaster.error(err.message);
      } finally {
        this.$loader.finish();
      }
    },

    actionCloseModalChangeProfessionalScale() {
      this.showModalChangeProfessionalScale = false;
    },

    actionOpenModalChangeProfessionalScale() {
      // const hasPermission = this.$Permissions.sector.has(
      //   this.permissions.alteracaoDadosDia,
      //   this.employeeData.set_id,
      // );

      // if (hasPermission) {
      this.showModalChangeProfessionalScale = true;
      // } else {
      //   this.$toaster.warning("Usuário sem permissão");
      // }
    },

    actionEditExamAppointment() {
      // TODO - ver permissões

      // if (!this.hasPermissionsToEditSchedule()) {
      //   this.$toaster.error(
      //     "Usuário sem permissão para a edição de Consulta.",
      //     "Usuário Sem Permissão!",
      //   );
      //   return;
      // }

      const routeName = this.isPerDay
        ? "exam.schedule.per-day.edit.form"
        : "exam.schedule.per-period.edit.form";

      this.$router.push({ name: routeName });
    },

    hasPermissionsToEditSchedule() {
      const isReservaTecnica = this.employeeData.cte_id === "2";
      if (isReservaTecnica) {
        return this.$Permissions.sector.has(
          this.permissions.agendamentoAlterarAgendamentoDiaReservaTecnica,
          this.employeeData.set_id,
        );
      } else {
        return this.$Permissions.sector.has(
          this.permissions.agendamentoAlterar,
          this.employeeData.set_id,
        );
      }
    },

    async fillFilter() {
      const complete = this.isPerDay
        ? await this.$refs.FilterPerDay.fillFilter()
        : await this.$refs.FilterPerPeriod.fillFilter();
      if (complete) {
        this.reSearchTables();
      }
    },

    async handleExamSchedulePrint(examId) {
      this.examIdToPrint = examId;
      this.actionOpenModalTypesPrint();
    },

    clearSelectionSchedule() {
      this.patientData = null;
      this.$refs.smartTableSchedule.cleanSelectRow();
    },

    findRouterNotResetFilter(pRouteName) {
      const routers = this.isPerDay
        ? this.routersNotResetFilterPerDay
        : this.routersNotResetFilterPerPeriod;

      const foundRoute = !!routers.find((ele) => ele === pRouteName);

      return foundRoute;
    },

    handleErrorChangeProfessionalScale(pData) {
      const CONFLICT_SCALE_EXCEPTION =
        "ExameConflitoAlteracaoDePeriodoHorarioException";

      const isConflictScaleError = pData.exception === CONFLICT_SCALE_EXCEPTION;

      if (isConflictScaleError) {
        const exceptionDetails = Object.assign({}, pData.detalhes_exececao);
        const hasConflictsData = exceptionDetails.escalas?.length > 0;
        const hasHistoryData = exceptionDetails.historicos?.length > 0;

        this.scaleConflicts = hasConflictsData ? exceptionDetails.escalas : [];

        this.scaleHistory = hasHistoryData ? exceptionDetails.historicos : [];

        this.actionOpenModalScaleConflict();
      } else {
        this.$toaster.error(pData.mensagem);
      }
    },
  },
};
</script>
