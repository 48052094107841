<style lang="scss" scoped>
@import "src/styles/1.settings/colors.scss";

.filter-title {
  display: block;
  width: 100%;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 11px;
  padding: 2px 0 1px 0;
  margin-left: 0.5em;
  margin-top: 1em;
  background-color: $default;
}
</style>

<template>
  <div>
    <div class="row filter-fields">
      <RgComboboxUnithealth v-model="form.unsId" />
    </div>
    <div class="row filter-fields">
      <div class="row">
        <RgInputMasked
          v-model="form.hospitalizationNumber"
          :mask="['########']"
          class="col-12"
          label="Número da Internação"
          placeholder="Informe o número da internação"
        />
      </div>
      <div class="row">
        <RgInputMasked
          v-model="form.medicalRecord"
          :mask="['###############']"
          class="col-12"
          label="Prontúario"
          placeholder="Informe o prontuário"
        />
      </div>
      <div class="row">
        <RgInput
          v-model="form.name"
          class="col-12"
          label="Nome do Paciente"
          placeholder="Informe o nome do paciente"
        />
      </div>
      <div class="row">
        <span class="filter-title">Período de Entrada</span>
        <RgInputDate
          v-model="form.entryInitialDate"
          class="col-6 littleAjust"
          label="Data Inicial"
          placeholder="Informe a data inicial"
        />
        <RgInputDate
          v-model="form.entryFinalDate"
          class="col-6"
          label="Data Final"
          placeholder="Informe a data final"
        />
      </div>

      <div class="row">
        <span class="filter-title littleAjust">Período de Saída</span>
        <RgInputDate
          v-model="form.exitInitialDate"
          class="col-6 ajust"
          label="Data Inicial"
          placeholder="Informe a data inicial"
        />
        <RgInputDate
          v-model="form.exitFinalDate"
          class="col-6 ajust"
          label="Data Final"
          placeholder="Informe a data final"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { RgInputDate, RgInput, RgInputMasked } from "~tokio/primitive";
import RgComboboxUnithealth from "$billing/common/component/rg-combobox-unithealth/RgComboboxUnithealth";

export default {
  name: "UnbilledExits",
  components: {
    RgInput,
    RgInputDate,
    RgInputMasked,
    RgComboboxUnithealth,
  },
  data() {
    return {
      resultTitle: "Relatório Saídas Não Faturadas",
      form: {
        initialDate: "",
        finalDate: "",
        unsId: 0,
      },
    };
  },
  computed: {
    columnTable() {
      return this.COLUMN;
    },
    getNameSmartTable() {
      return "UnbilliedExits";
    },
  },
  watch: {
    "form.name"() {
      if (this.form.name) {
        this.form.name = this.form.name.toUpperCase();
      }
    },
  },
  created() {
    this.COLUMN = [
      { name: "Nome do Paciente", key: "nomePaciente" },
      { name: "Prontuário", key: "prontuario" },
      { name: "Número Internação", key: "int_numero" },
      { name: "Data da Entrada", key: "dataEntrada" },
      { name: "Data da Saída", key: "dataSaida" },
    ];
  },
  mounted() {
    this.form.unsId = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
  },
  methods: {
    buildFilter() {
      return {
        unsId: this.form.unsId ? this.form.unsId : null,
        hospitalizationNumber: this.form.hospitalizationNumber
          ? this.form.hospitalizationNumber
          : null,
        name: this.form.name ? this.form.name : null,
        medicalRecord: this.form.medicalRecord ? this.form.medicalRecord : null,
        entryInitialDate: this.form.entryInitialDate
          ? this.formatDate(this.form.entryInitialDate)
          : null,
        entryFinalDate: this.form.entryFinalDate
          ? this.formatDate(this.form.entryFinalDate)
          : null,
        exitInitialDate: this.form.exitInitialDate
          ? this.formatDate(this.form.exitInitialDate)
          : null,
        exitFinalDate: this.form.exitFinalDate
          ? this.formatDate(this.form.exitFinalDate)
          : null,
      };
    },
    async doSearch(pData) {
      try {
        return this.$store.dispatch(
          "Billing/HospitalizationBilling/GET_UNBILLED_EXITS_REPORT",
          pData,
        );
      } catch (error) {
        return false;
      }
    },
    clearFilter() {
      this.form = {
        initialDate: "",
        finalDate: "",
        unsId: 0,
      };
    },
  },
};
</script>
