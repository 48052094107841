export default {
  EXISTS_PES_ID: (state) => !!state.patient.pes_id,
  GET_PERSON_ID: (state) => state.patient.pes_id,
  GET_PERSONAL_DATA: (state) => state.patient.personalDetail,
  GET_ADDRESS: (state) => state.patient.address,
  GET_DOCUMENT: (state) => state.patient.document,
  GET_ATTACHMENTS: (state) => state.patient.attachments,
  GET_DOCUMENT_FOREIGN: (state) => state.patient.document.foreign,
  GET_CONTACT: (state) => state.patient.contact,
  GET_PATIENT_INFO: (state) => state.patient.patientInfo,
  GET_LAST_PERSON_ID_SAVE: (state) => state.last_person_save,
  GET_FILTER_PATIENT: (state) => state.filter_patient,
  IS_NEWBORN: (state) => state.patient.newBorn === true,
  GET_FILTER_UNIFY_PATIENT: (state) => state.filter_unify_patient,
  GET_SELECTED_PATIENTS_UNIFY: (state) => state.selected_patients_unify_data,
};
