<style src="./PrintHeader.scss" lang="scss" scoped></style>
<template>
  <section class="report-header">
    <div class="vendor-logo">
      <img
        ref="imageLogoContainer"
        src=""
        alt="Logo"
        class="vendor-logo-image"
      />
    </div>

    <div class="unit-info">
      <span class="information">RG ESUS - Sistema Hospitalar</span>
      <span class="information"
        >{{ unitHealthInfo.name }} / {{ unitHealthInfo.city }} -
        {{ unitHealthInfo.state }}</span
      >
      <span class="information"
        >{{ unitHealthInfo.address }} - Nº {{ unitHealthInfo.number }}</span
      >
      <span class="description">{{ title }}</span>
    </div>
  </section>
</template>

<script>
import LocalStorage from "~common/local-storage/LocalStorage";

export default {
  name: "PrintHeader",
  props: {
    unitHealthInfo: {
      default: null,
    },
    title: {
      default: "Relatório",
    },
  },
  mounted() {
    this.openClientLogo();
  },
  methods: {
    openClientLogo() {
      const clientLogo = LocalStorage.getObject("client-logo");
      this.$refs.imageLogoContainer.src = clientLogo;
    },
  },
};
</script>
