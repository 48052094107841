import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (context, conId) => {
  return AuthLegacyRequest.post(
    "/integracoes/controller-integracao-rg-pep/enviar-atendimento",
    {
      con_id: conId,
    },
  )
    .then(({ data }) => {
      data = HandleResponse(data);
      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
