<style src="./ImportedBillingToBillButton.scss" lang="scss" scoped></style>
imported-billing-to-billed-button
<template>
  <section class="imported-billing-to-billed-button">
    <button
      class="btn-small button btn-primary"
      @click.stop.prevent="confirmBillImportedBulletin"
    >
      Faturar Boletim Importado
    </button>
    <RgConfirmModal
      :show="show"
      title="Faturar Boletim Importado"
      @confirmed="billImportedBulletin"
      @denied="show = false"
    >
      <div slot="body">
        <p>
          Um boletim importado quando faturado <b>não</b> voltará para o seu
          estado de importado novamente! Favor verificar se há erros e confirmar
          a operação.
        </p>
      </div>
    </RgConfirmModal>
  </section>
</template>
<script>
import { RgConfirmModal } from "~tokio/primitive/notification";
export default {
  name: "ImportedBillingToBillButton",
  components: {
    RgConfirmModal,
  },
  props: {},
  data: () => {
    return {
      show: false,
    };
  },
  methods: {
    confirmBillImportedBulletin() {
      this.show = true;
    },
    billImportedBulletin() {
      this.show = false;
      this.$emit("confirmed");
    },
  },
};
</script>
