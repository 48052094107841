import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query($recordNumber: String, $unitHealth: Int) {
    people(pac_prontuario_unico: $recordNumber, uns_id: $unitHealth) {
      rows {
        pes_nome
        pes_id
        patient {
          pac_id
          pac_prontuario_unico
          person {
            gender {
              sex_id
              sex_sigla
            }
          }
        }
      }
    }
  }
`;

export default async (variables) => {
  try {
    const { data } = await GraphQLClient.query({ query, variables });
    return data.people.rows;
  } catch (err) {
    const jsonResponse = JSON.parse(JSON.stringify(err));
    return Promise.reject(jsonResponse.graphQLErrors[0].message);
  }
};
