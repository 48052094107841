import SearchIndividualForm from "./SearchIndividualForm";
import GetIndividualForm from "./GetIndividualForm";
import GetBreed from "./GetBreed";
import GetEthnicities from "./GetEthnicities";
import GetGenre from "./GetGenre";
import GetSexualOrientation from "./GetSexualOrientation";
import GetDegreeKinship from "./GetDegreeKinship";
import GetOccupations from "./GetOccupations";
import GetSchoolingAB from "./GetSchoolingAB";
import GetLaborMarket from "./GetLaborMarket";
import GetChildSupport from "./GetChildSupport";
import GetDeficiency from "./GetDeficiency";
import GetReasonOutRegister from "./GetReasonOutRegister";
import GetWeight from "./GetWeight";
import GetHeartDisease from "./GetHeartDisease";
import GetKidneyDisease from "./GetKidneyDisease";
import GetRespiratoryDisease from "./GetRespiratoryDisease";
import GetStreetTime from "./GetStreetTime";
import GetFoodDay from "./GetFoodDay";
import GetPersonalHygiene from "./GetPersonalHygiene";
import GetFoodOrigin from "./GetFoodOrigin";
import SaveIndividualForm from "./SaveIndividualForm";

export default {
  SearchIndividualForm,
  GetIndividualForm,
  GetBreed,
  GetEthnicities,
  GetGenre,
  GetSexualOrientation,
  GetDegreeKinship,
  GetOccupations,
  GetSchoolingAB,
  GetLaborMarket,
  GetChildSupport,
  GetDeficiency,
  GetReasonOutRegister,
  GetWeight,
  GetHeartDisease,
  GetKidneyDisease,
  GetRespiratoryDisease,
  GetStreetTime,
  GetFoodDay,
  GetPersonalHygiene,
  GetFoodOrigin,
  SaveIndividualForm,
};
