<style src="./RgEditSmall.scss" lang="scss" scoped></style>
<template lang="html">
  <div
    :class="{ 'edit-small__expanded': expandConfirmation, disabled: disabled }"
    class="edit-small"
  >
    <button
      v-show="editState"
      :disabled="disabled"
      data-id="editar"
      type="button"
      class="edit-small--trytoedit"
      @click.stop.prevent="tryToRemove()"
    >
      <IconPencil />
    </button>

    <button
      v-show="!editState"
      ref="button-confirm"
      :disabled="disabled"
      data-id="editar-confirmar"
      type="button"
      class="edit-small--confirm-edit"
      @blur="lostFocus"
      @click.stop.prevent="confirmEdit()"
    >
      <IconPencil />
      <span>Editar</span>
    </button>
  </div>
</template>

<script>
import { IconPencil } from "~tokio/primitive/icon";

export default {
  name: "RgEditSmall",
  components: {
    IconPencil,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    dataId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      editState: true,
      expandConfirmation: false,
    };
  },
  methods: {
    tryToRemove() {
      this.editState = false;
      this.expandConfirmation = true;
      this.$nextTick(() => {
        this.$refs["button-confirm"].focus();
      });
    },
    confirmEdit() {
      this.expandConfirmation = false;
      this.editState = true;
      this.$emit("confirm-edit");
    },
    lostFocus() {
      this.expandConfirmation = false;
      this.editState = true;
    },
  },
};
</script>
