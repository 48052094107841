import GET_PATIENT_LIST from "./GetPatientList";
import GET_PATIENT_BY_ID from "./GetPatientById";
import GET_PATIENT_LIST_UNIFY from "./GetPatientListUnify";
import CREATE_PATIENT from "./CreatePatient";
import RESEND_PATIENT_TO_LOTUS from "./ResendPatientToLotus";
import UPDATE_PATIENT from "./UpdatePatient";
import PRINT_PATIENT_TOKEN from "./PrintPatientToken";
import PRINT_PATIENT_CARD from "./PrintPatientCard";
import GET_CUSTOM_HISTORY_PATIENT_LIST from "./GetCustomHistoryPatientList";
import CREATE_PATIENT_FOR_PERSON from "./CreatePatientForPerson";
import SEARCH_PATIENTS_GENERAL_HISTORY from "./SearchPatientsGeneralHistory";
import UNIFY_PATIENT from "./UnifyPatient";
import GET_PATIENT_BY_PESID_FOR_UNIFY from "./GetPatientByPesIdForUnify";
import SEARCH_PERSON from "./SearchPerson";

export default {
  GET_PATIENT_LIST,
  GET_PATIENT_BY_ID,
  GET_PATIENT_LIST_UNIFY,
  CREATE_PATIENT,
  PRINT_PATIENT_TOKEN,
  PRINT_PATIENT_CARD,
  GET_CUSTOM_HISTORY_PATIENT_LIST,
  UPDATE_PATIENT,
  RESEND_PATIENT_TO_LOTUS,
  CREATE_PATIENT_FOR_PERSON,
  SEARCH_PATIENTS_GENERAL_HISTORY,
  UNIFY_PATIENT,
  GET_PATIENT_BY_PESID_FOR_UNIFY,
  SEARCH_PERSON,
};
