<style src="./DefinePeriod.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="export-billing">
    <RgConfirmModal
      :show="showModal"
      :message="changePeriodMessage"
      style="background-color: red"
      title="Você tem certeza que deseja trocar de Competência?"
      @close="closeModal"
      @confirmed="period"
      @denied="closeModal"
    />
    <div class="form">
      <ModuleBox
        :granted="hasPermission"
        class="module-box-export-billing"
        title="Definir periodo para faturamento"
      >
        <div class="row">
          <RgComboboxMonth v-model="month" class="col-4" label="Mês" />
          <RgComboboxYear v-model="year" class="col-3" label="Ano" />
          <button
            type="submit"
            class="btn-gerar-faturamento col-3 btn btn-success"
            @click="confirmPeriodChange"
          >
            Definir Competência
          </button>
        </div>
        <div
          v-if="showUnifiedTableVersionMessage.status"
          class="period-warning"
        >
          <IconExclamationInverse />
          <span class="period-label">
            <h3>Competência diferente do SIGTAP</h3>
            <p>A Competência informada ainda não foi importada pelo sistema.</p>
            <p>
              Última competência importada pelo sistema é:
              <strong>{{
                showUnifiedTableVersionMessage.unifiedTableVersion ||
                "Não identificado"
              }}</strong>
            </p>
          </span>
        </div>
      </ModuleBox>
    </div>
  </section>
</template>

<script>
import ModuleBox from "~tokio/foundation/modulebox/Modulebox";
import RgComboboxMonth from "../../common/component/rg-combobox-month/RgComboboxMonth";
import RgComboboxYear from "../../common/component/rg-combobox-year/RgComboboxYear";
import ChangeCurrentPeriod from "../actions/ChangeCurrentPeriod";
import CheckPeriod from "../actions/CheckPeriod";
import GetCurrentPeriod from "$billing/bdsia/action/GetCurrentPeriod";
import RgConfirmModal from "~tokio/primitive/notification/modal/RgConfirmModal";
import { IconExclamationInverse } from "~tokio/primitive";

export default {
  name: "DefinePeriod",
  components: {
    ModuleBox,
    RgComboboxMonth,
    RgComboboxYear,
    RgConfirmModal,
    IconExclamationInverse,
  },
  data: () => {
    return {
      year: "",
      month: "",
      showModal: false,
      changePeriodMessage:
        " Caso a competência informada seja anterior a 04 competências, contando com a atual, os lançamentos só terão fins estatísticos.",
    };
  },
  computed: {
    periodDate() {
      return `${this.year}${this.month}`;
    },
    showUnifiedTableVersionMessage() {
      const result = {
        period: this.$store.getters["Billing/BDSia/GET_PERIOD_DATE"],
        unifiedTableVersion: this.$store.getters[
          "Billing/BDSia/GET_UNIFIED_TABLE_VERSION"
        ],
      };
      result.status = result.period !== result.unifiedTableVersion;

      return result;
    },
    unitHealthId() {
      return this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    },
    hasPermission() {
      return this.$Permissions.global.has(
        "faturamento.atendimentosEmergencia",
        this.$store.getters["Login/GET_UNI_HEALTH_ID"],
      );
    },
  },
  mounted() {
    // SERVIÇO PARA PEGAR ULTIMA COMPETENCIA SELECIONADA
    const cmp = this.$store.getters["Billing/BDSia/GET_PERIOD_DATE"];
    this.year = cmp.substring(0, 4);
    this.month = cmp.substring(4, 6);
  },
  methods: {
    confirmPeriodChange() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    async changePeriod() {
      try {
        await ChangeCurrentPeriod(this.periodDate, this.unitHealthId);
        GetCurrentPeriod();
        this.$toaster.success("Competência alterada com sucesso!");
      } catch (pErr) {
        this.$toaster.error("Erro ao definir competência! " + pErr.message);
      }

      this.showModal = false;
    },
    async period() {
      const checkPeriod = await CheckPeriod(this.periodDate, this.unitHealthId);
      this.$store.commit(
        "Billing/BDSia/SET_UNIFIED_TABLE_VERSION",
        checkPeriod.data.period,
      );
      this.showUnifiedTableMessage = false;
      this.changePeriod();
    },
  },
};
</script>
