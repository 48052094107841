import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation(
    $hospitalizationId: Int!
    $bedId: Int!
    $patientId: Int
    $indigentId: Int
    $typeBedId: Int
    $hospitalizationExitId: Int!
    $observationHistoric: String!
    $deathCause: String
  ) {
    DeleteHospitalizationExit(
      hospitalizationId: $hospitalizationId
      bedId: $bedId
      patientId: $patientId
      indigentId: $indigentId
      typeBedId: $typeBedId
      hospitalizationExitId: $hospitalizationExitId
      observationHistoric: $observationHistoric
      deathCause: $deathCause
    ) {
      exitExcluded
    }
  }
`;
export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.mutate({ mutation, variables });
    return data.DeleteHospitalizationExit;
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors[0].message);
  }
};
