<template lang="html">
  <svg
    enable-background="new 0 0 512 512"
    version="1.1"
    viewBox="0 0 512 512"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="#c0cadd">
      <g fill="#c0cadd">
        <path
          d="m437 362h-107v-136c0-36.219-25.81-66.524-60-73.493v-31.507h15c8.284 0 15-6.716 15-15v-91c0-8.284-6.716-15-15-15h-240c-8.284 0-15 6.716-15 15v91c0 8.284 6.716 15 15 15h15v31.507c-34.191 6.969-60 37.274-60 73.493v241c0 24.813 20.187 45 45 45h392c41.355 0 75-33.645 75-75s-33.644-75-75-75zm-197-332h30v61h-30v-61zm-60 0h30v61h-30v-61zm-60 0h30v61h-30v-61zm-60 0h30v61h-30v-61zm15 151c8.284 0 15-6.716 15-15v-45h150v45c0 8.284 6.716 15 15 15 19.557 0 36.239 12.539 42.43 30h-264.86c6.191-17.461 22.874-30 42.43-30zm-30 301c-8.271 0-15-6.729-15-15v-15h211.51c2.258 11.079 6.967 21.274 13.532 30h-210.04zm196.51-59.999h-211.51v-181h270v122.51c-29.344 5.98-52.511 29.147-58.491 58.491zm119.49 59.999h-46c-24.813 0-45-20.187-45-45s20.187-45 45-45h46v90zm76 0h-46v-90h46c24.813 0 45 20.187 45 45s-20.186 45-45 45z"
          fill="#c0cadd"
        />
      </g>
      <g fill="#c0cadd">
        <path
          d="m210 316h-30v-30c0-8.284-6.716-15-15-15s-15 6.716-15 15v30h-30c-8.284 0-15 6.716-15 15s6.716 15 15 15h30v31c0 8.284 6.716 15 15 15s15-6.716 15-15v-31h30c8.284 0 15-6.716 15-15s-6.716-15-15-15z"
          fill="#c0cadd"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "Pharmacy",
};
</script>
