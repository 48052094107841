<style src="./RgReport.scss" lang="scss" scoped></style>
<template lang="html">
  <div class="base-cruds-view">
    <section class="tabs-options">
      <RgTabs :itens="itens">
        <div slot="materiais">
          <RgReportMaterialPerSurgery />
        </div>
        <div slot="profissionais">
          <RgReportTeamProductivity />
        </div>
      </RgTabs>
    </section>
  </div>
</template>

<script>
import { RgTabs } from "~tokio/foundation/container";
import RgReportMaterialPerSurgery from "./rg-report-material-per-surgery/RgReportMaterialPerSurgery";
import RgReportTeamProductivity from "./rg-report-team-productivity/RgReportTeamProductivity";

export default {
  name: "RgReport",
  components: {
    RgTabs,
    RgReportMaterialPerSurgery,
    RgReportTeamProductivity,
  },
  data() {
    return {
      itens: [
        {
          label: "Materiais por Cirurgia",
          slot: "materiais",
          granted: this.hasPermission(
            "centro_cirurgico.relatorioCentroCirurgico.relatorioMaterialCirugicoPorCirurgia",
          ),
        },
        {
          label: "Produtividade dos Profissionais",
          slot: "profissionais",
          granted: this.hasPermission(
            "centro_cirurgico.relatorioCentroCirurgico.relatorioProdutividadeProfissionais",
          ),
        },
      ],
    };
  },
  methods: {
    hasPermission(pNamespace) {
      return this.$Permissions.global.has(pNamespace);
    },
  },
};
</script>
