<template lang="html">
  <svg
    id="Capa_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M150,271c-33.076,0-60,26.909-60,60v15c0,8.276,6.738,15,15,15h90c8.262,0,15-6.724,15-15v-15
			C210,297.909,183.076,271,150,271z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M150,151c-16.553,0-30,13.462-30,30c0,16.538,13.447,30,30,30s30-13.462,30-30C180,164.462,166.553,151,150,151z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M467,61H45C20.186,61,0,81.186,0,106v300c0,24.814,20.186,45,45,45h422c24.814,0,45-20.186,45-45V106
			C512,81.186,491.814,61,467,61z M317,151h90c19.773,0,19.854,30,0,30h-90C297.227,181,297.146,151,317,151z M240,346
			c0,24.814-20.186,45-45,45h-90c-24.814,0-45-20.186-45-45v-15c0-49.629,40.371-90,90-90c-33.076,0-60-26.909-60-60
			s26.924-60,60-60s60,26.909,60,60s-26.924,60-60,60c49.629,0,90,40.371,90,90V346z M437,361H287c-19.773,0-19.854-30,0-30h150
			C456.773,331,456.854,361,437,361z M437,301H287c-19.773,0-19.854-30,0-30h150C456.773,271,456.854,301,437,301z M437,241H287
			c-19.773,0-19.854-30,0-30h150C456.773,211,456.854,241,437,241z"
        />
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {
  name: "DocumentPerson",
};
</script>
