<style src="./NoRecord.scss" lang="scss" scoped></style>
<template>
  <section class="no-record">
    <p class="title">{{ personName }} ainda não é paciente.</p>
    <p class="description">
      Para torná-lo em paciente é necessário clicar no botão abaixo.
    </p>
    <button
      type="button"
      class="btn-medium btn-primary bottom-command"
      @click="defineAsPatient"
    >
      DEFINIR COMO PACIENTE
    </button>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "NoRecord",
  computed: {
    ...mapGetters({
      personName: "Patient/GET_PERSON_NAME",
      isPatient: "Patient/GET_PATIENT_RECORD",
      personId: "Patient/GET_PERSON_ID",
    }),
  },
  methods: {
    defineAsPatient() {
      this.$store
        .dispatch("Patient/CREATE_NEW_PATIENT", this.personId)
        .then(() => {
          this.$toaster.toast.success(
            "Número de prontuário único gerado com sucesso!",
          );
        })
        .catch((pErr) => {
          this.$toaster.toast.error("Falha ao definir pessoa como paciente");
        });
    },
  },
};
</script>
