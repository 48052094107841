<template>
  <div class="information-tooltip">
    <div class="tooltip-container">
      <IconInfoHelper class="svg" />
      <span class="tooltip-message">{{ message }}</span>
    </div>
  </div>
</template>

<script>
import { IconInfoHelper } from "~tokio/primitive";
export default {
  name: "InformationTooltip",
  components: {
    IconInfoHelper,
  },
  props: {
    message: {
      type: String,
      default: "Informe a mensagem do tooltip",
    },
  },
};
</script>
