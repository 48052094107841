var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fieldset',{staticClass:"rg-radio-bed-type"},[_c('span',{staticClass:"label"},[_vm._v(" Listar por Leito ")]),_c('div',{staticClass:"container unselect"},[_c('div',{staticClass:"radio -extra justify-content-center align-items-center",class:{
        '-activeextra _color-white': _vm.selectedStatus.includes(
          _vm.BED_STATUS.EXTRAS
        ),
      },on:{"click":function($event){return _vm.toggleStatus(_vm.BED_STATUS.EXTRAS)}}},[_vm._v(" Extra ")]),_c('div',{staticClass:"radio -observacao justify-content-center align-items-center",class:{
        '-activeobservacao _color-white': _vm.selectedStatus.includes(
          _vm.BED_STATUS.OBSERVACAO
        ),
      },on:{"click":function($event){return _vm.toggleStatus(_vm.BED_STATUS.OBSERVACAO)}}},[_vm._v(" Observação ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }