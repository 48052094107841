import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query ListUnitHealthMappedPep($unitHealthId: Int, $placeId: Int) {
    unitHealthMappedPep(uns_id: $unitHealthId, lca_id: $placeId) {
      rows {
        uns_id
        uns_nome
      }
    }
  }
`;
export default async (variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    return data.unitHealthMappedPep.rows;
  } catch (err) {
    return err.graphQLErrors;
  }
};
