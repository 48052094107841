import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation CreateEmployee(
    $person: PersonInputType
    $employee: EmployeeInputType
    $address: AddressInputType
  ) {
    CreateEmployee(person: $person, address: $address, employee: $employee) {
      fun_id
    }
  }
`;

export default async ({ rootState }, { person, address }) => {
  const variables = {
    employee: {
      fun_id_municipios: rootState.Login.user.usu_id_municipios,
      fun_id_clientes: rootState.Login.user.usu_id_clientes,
      fun_ativo: true,
      fun_habilitado: true,
    },
    person,
    address,
  };

  try {
    const { data } = await GraphQLClient.mutate({
      mutation,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.CreateEmployee;
  } catch (err) {
    return Promise.reject(err.graphQLErrors);
  }
};
