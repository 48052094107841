const getters = {
  filterList: (store) => Object.keys(store.filterListBy),
  filterValue: (store, getters) => (pStatus) => {
    return store.filterListBy[pStatus];
  },
  getAllStages: (store) => {
    return store.stages;
  },
  getAllSurgeryStage: (store) => {
    return store.calendar.calendarMap;
  },
  getSurgeryByStage: (store) => (pStage) => {
    return store.calendar.calendarMap[pStage];
  },
  stageTime: (store) => {
    const startTime = store.calendar.config.startTime;
    const endTime = store.calendar.config.endTime;
    return { endTime, startTime };
  },
  getStyleScrollPosition: (store) => {
    const posLeft = `${store.calendar.stageColumnContainer.posLeft}px`;
    return { left: posLeft };
  },
  getScrollPosition: (store) => {
    return store.calendar.stageColumnContainer.posLeft;
  },
  getStageWidth: (store) => {
    return {
      stageContainerWidth:
        store.calendar.stageColumnContainer.stageContainerWidth,
      stageInsideWidth: store.calendar.stageColumnContainer.stageInsideWidth,
    };
  },
  getSelectedItemId: (store) => {
    return store.selectedItem ? store.selectedItem.cci_id : false;
  },
  getSelectedSurgeryCode: (store) => {
    return store.selectedItem ? store.selectedItem.cci_numero : null;
  },
  getSelectedSurgeryStatus: (store) => {
    return store.selectedItem
      ? store.selectedItem.cci_id_status_controles_cirurgicos
      : null;
  },
  GET_CALENDAR_SECTOR: (store) => store.calendar.sector,
};

export default getters;
