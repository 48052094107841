import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import TransferExams from "$exam/submodules/schedule/views/transfer/TransferExams";
import { OpenMenuModule } from "~common/utils/app/menu";
export default {
  path: "/exam/schedule/transfer",
  name: "exam.schedule.transfer",
  components: {
    default: TransferExams,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Exame", link: OpenMenuModule("exam") },
      { label: "Transferência de Agendamentos" },
    ],
  },
};
