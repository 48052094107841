import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query procedureDetails($DT_COMPETENCIA: String, $CO_PROCEDIMENTO: String) {
    procedureDetails(
      DT_COMPETENCIA: $DT_COMPETENCIA
      CO_PROCEDIMENTO: $CO_PROCEDIMENTO
    ) {
      rows {
        CO_DETALHE
        CO_PROCEDIMENTO
        NO_DETALHE
        DT_COMPETENCIA
      }
    }
  }
`;

export default async ({ commit }, { DT_COMPETENCIA, CO_PROCEDIMENTO }) => {
  try {
    const variables = {
      DT_COMPETENCIA,
      CO_PROCEDIMENTO,
    };
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.procedureDetails.rows;
  } catch (err) {
    return err.graphQLErrors;
  }
};
