import BaseModel from "~common/model/BaseModel";

class SurgeryEmployeeModel extends BaseModel {
  defineEndPointURI() {
    return "surgery-center/surgery-employee";
  }

  getSurgeryTeam(pSurgeryId, pSectorId) {
    return new Promise((resolve, reject) => {
      this._request
        .get(`/surgery-center/get-surgery-team/${pSurgeryId}`)
        .then((pData) => {
          resolve(pData.data || null);
        });
    });
  }
}
export default new SurgeryEmployeeModel();
