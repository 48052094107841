import moment from "moment";
const PatientListHTML = (pInfo, pData) => {
  const emissao = moment().format("DD/MM/YYYY");
  let body = "";

  pData.corpo.forEach((item) => {
    "<tr>";
    body += "<td>" + item.nomePaciente + "</td>";
    body += "<td>" + item.unidadeFila + "</td>";
    body += "<td>" + item.uns_solicitante + "</td>";
    body += "<td>" + item.nomeFuncionarioSolicitante + "</td>";
    body += "<td>" + item.usuarioFila + "</td>";
    body += "<td>" + item.flg_data + "</td>";
    body += "<td>" + item.flg_obs + "</td>";
    body += "<td>" + item.usuarioExclusao + "</td></tr>";
  });

  const html = `
  <!DOCTYPE html>
    <html>
    <head>
      <meta charset="utf-8">
      <title>Esus</title>
      <style type="text/css">

    @media print {
        body {
            margin: 0;
            padding: 0;
            line-height: 1.4em;
            font: 12pt Georgia, "Times New Roman", Times, serif;
            color: #000;
        }

        table {
            page-break-inside: auto;
        }

        tr {
            page-break-inside: avoid; page-break-after: auto;
        }

        footer {
            page-break-inside: avoid; page-break-after: auto;
        }
    }

    body {
        font-family: Verdana, Geneva, sans-serif;
    }

    .logoCliente {
        float: left;
        max-width: 150px;
        max-height: 100px;
        width: 150px;
        height: 100px;
    }

    .logoEsus{
        float: right;
        max-width:  150px;
        max-height: 100px;
        width:  150px;
        height: 100px;
    }

    .center-locale {
      text-align: center;
      margin: 0;
      font-size: 12pt;
    }

    .cabecalho {
      width: 100%;
      float: left;
      overflow: hidden;
      table-layout: fixed;
      display: block;
      word-wrap: break-word;
      position: relative;
    }

    .brasao {
      max-width: 150px;
      max-height: 100px;
      margin-right: 1%;
      float: right;
      width: 150px;
      height: 100px;
    }

    .h1 {
      font-size: 12pt;
      text-transform: uppercase;
      text-align: center;
      font-weight: bold;
      position: relative;
    }

    table {
        margin: 30px 0px 10px 0px;
        border-collapse: collapse;
        table-layout: fixed;
        width: 100%;
    }

    th:nth-child(1),
    tr td:nth-child(1) {
        width: 20%;
        font-size: 7.5pt;
    }

    th:nth-child(2),
    tr td:nth-child(2) {
        width: 20%;
    }

    th:nth-child(3),
    tr td:nth-child(3) {
        width: 20%;
    }

    th:nth-child(4),
    tr td:nth-child(4) {
      width: 30%;
      font-size: 7.5pt;
    }

    th:nth-child(5),
    tr td:nth-child(5) {
        width: 20%;
    }

    th:nth-child(6),
    tr td:nth-child(6) {
        width: 20%;
    }

    th:nth-child(7),
    tr td:nth-child(7) {
      width: 30%;
      word-break: break-word;
      font-size: 8pt;
    }

    th:nth-child(8),
    tr td:nth-child(8) {
      width: 20%;
      font-size: 7.5pt;
    }

    td, th {
        padding: 0 8px;
        border: 1px solid #ddd;
        text-align: center;
        font-size: 8.5pt;
    }

    .footer {
        width: 100%;
        margin: 10px 0px;
        font-size: 8pt;
    }
    </style>
  </head>
  <body>
  <page size="A4">
  <p class="center-locale">${pInfo.cli_nome || ""}</p>
      <div class="cabecalho">
        <img src="/static/images/logo-esus.png" class="brasao" />
      </div>

      <p class="h1">Pacientes Excluidos Pela Fila</p>

      <table class="table">
        <thead>
          <tr>
            <th>Paciente</th>
            <th>Unidade de Saúde</th>
            <th>Unidade Solicitante</th>
            <th>Médico Solicitante</th>
            <th>Usuário Fila</th>
            <th>Data Exclusão</th>
            <th>Motivo Exclusão</th>
            <th>Usuário Exclusão</th>
          </tr>
        </thead>
        <tbody>
            ${body}
        </tbody>
      </table>

      <div class="footer">
        <p>Emitido em ${emissao}</p>
      </div>
    </page>
  </body>
</html>
  `;

  return html;
};

export default PatientListHTML;
