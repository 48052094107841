import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (context, bolId) => {
  return AuthLegacyRequest.post(
    "integracoes/controller-integracao-rg-pep/enviar-cancelamento-atendimento-emergencia",
    {
      bol_id: bolId,
    },
  )
    .then(({ data }) => {
      data = HandleResponse(data);
      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
