import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query GetSignature($toSign: String) {
    GetSignature(toSign: $toSign) {
      key
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.GetSignature.key;
  } catch (error) {
    return error.graphQLErrors;
  }
};
