import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import AppointmentPatientObservationQueue from "$appointment/submodules/schedule/views/observation-queue/AppointmentPatientObservationQueue";
import { OpenMenuModule } from "~common/utils/app/menu";

export default {
  path: "/appointment/schedule/patient-schedule/patient-observation-queue",
  name: "appointment.schedule.patient-schedule.patient-observation-queue",
  components: {
    default: AppointmentPatientObservationQueue,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Consulta", link: OpenMenuModule("appointment") },
      {
        label: "Agendados pela Fila",
        link: "/appointment/schedule/patient-schedule",
      },
      { label: "Observações do Paciente na Fila" },
    ],
  },
};
