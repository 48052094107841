import store from "@/store";

const isActive = (module) => {
  const useLegacyBackend = store.state.Login.useLegacyBackend;
  const validLegacyToken = store.state.Login.legacyToken !== null;
  const dependsOnLegacyBackend = module.dependsOnLegacyBackend;

  if (dependsOnLegacyBackend) {
    // o Modulo em questão depende do backend legado e o backend legado nao foi
    // utilizado no Login, nessa caso o modulo deve ficar desabilitado
    if (!useLegacyBackend || !validLegacyToken) {
      return false;
    }
    return module.active;
  }
  return module.active === undefined ? true : module.active;
};

export default isActive;
