const clientNameTitle = (cli_nome) => {
  if (cli_nome) {
    return `<span>${cli_nome}</span>`;
  }
  return "";
};

const ScheduleThermalOneWayHTML = (pListExams) => {
  let html = ``;
  pListExams.forEach((element) => {
    html += `
    <!DOCTYPE html>
    <html>

    <head>
        <meta charset="UTF-8">
        <style type="text/css">
            html,
            body,
            div,
            span,
            p {
                margin: 0;
                padding: 0;
                border: 0;
                font-size: 100%;
                font: inherit;
                vertical-align: baseline;
            }

            body {
                width: 7cm;
                margin: .1cm;
                font-size: 8pt;
                font-family: Verdana, sans-serif;
            }

            div,
            p {
                width: 6.5cm;
                margin: .2cm auto;
                word-wrap: break-word;
                line-height: 120%;
            }

            .logo {
                width: 60px;
            }

            .strong {
                font-weight: bold;
                text-transform: uppercase;
            }

            .label {
                width: 2.2cm;
                display: inline-block;
						}

						.fila {
							display: ${element.agendadoPelaFila ? "block" : "none"}
						}

            @media print {
                body {
                    width: 7cm;
                    margin: .1cm;
                    font-size: 8pt;
                    font-family: Verdana, sans-serif;
                }

                div,
                p {
                    width: 6.5cm;
                    margin: .2cm auto;
                    word-wrap: break-word;
                    line-height: 120%;
                }

                .logo {
                    width: 60px;
                }

                .strong {
                    font-weight: bold;
                    text-transform: uppercase;
                }

                .label {
                    width: 2.2cm;
                    display: inline-block;
                }

                @page {
                    margin: 0;
                    padding: 0;
                    border: 0;
                }

            }
        </style>
    </head>

    <body>
        <div>
					<img id="imageLogoContainer" class="logo">
            ${clientNameTitle(element.cli_nome)}
        </div>

        <p>${element.uns_nome}</p>

        <p>${element.tlg_nome} ${element.end_logradouro}, ${
      element.end_numero
    } ${element.end_complemento} - ${element.bai_nome} ${element.mun_nome}</p>

        <p>Referência: ${element.end_referencia}</p>

        <p>Comparecer ao exame</p>

        <p>Data: <b>${element.eha_data_exame}</b> as <b>${
      element.eap_hora
    }</b></p>

        <p><span class="label">Paciente:</span> ${element.pes_nome}</p>

        <p><span class="label">Nome Social:</span> ${
          element.pes_nome_social
        }</p>

        <p><span class="label">Prontuário:</span> ${element.ppr_numero}</p>

        <p><span class="label">Pront. Único:</span> ${
          element.pac_prontuario_unico
        }</p>

        <p><span class="label">Cartão Sus:</span> ${element.crs_numero}</p>

        <p><span class="label">Setor:</span> ${element.set_nome}</p>

        <p><span class="label">Local:</span> ${element.lca_nome}</p>

        <p><span class="label">Procedimento:</span> ${
          element.stp_novo_nome_procedimento
        }</p>

        <p><span class="label">SubProcedimentos:</span> ${
          element.subprocedimentos
        }</p>

        <p><span class="label">Profissional:</span> <span class="strong">${
          element.atendente
        }</span></p>

        <p>Ocupação: <span class="strong">${element.ocp_nome}</span< /p>

        <p>${element.observacoes ? element.observacoes : "-"}</p>

        <p>Exame marcado no dia</p>

        <p>${element.data_marcacao} <b>as</b> ${element.hora_marcacao}</p>

        <p><b>por: </b>${element.usu_nome}</p>

        <div class="fila">
          <label style="width: 200px;">${element.agendadoPelaFila}</label>
        </div>
        <div class="fila">
          <label>Profissional Regulador: </label>
          <span>${element.lblNomeRegulador} ${element.nomeRegulador}</span>
        </div>

        <p><b>Senha efetivação: </b>${element.eap_senha_efetivacao}</p>
        ${element.recomendacaoPreparo}

				<hr />
			</body>

    </html>
		`;
  });
  return html;
};

export default ScheduleThermalOneWayHTML;
