import { render, staticRenderFns } from "./PrintReportTeamProductivity.vue?vue&type=template&id=28ddf886&scoped=true&lang=html&"
import script from "./PrintReportTeamProductivity.vue?vue&type=script&lang=js&"
export * from "./PrintReportTeamProductivity.vue?vue&type=script&lang=js&"
import style0 from "./PrintReportTeamProductivity.scss?vue&type=style&index=0&id=28ddf886&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28ddf886",
  null
  
)

export default component.exports