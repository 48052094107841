<style
  src="./RgContentHospBillChildbirthTincture.scss"
  lang="scss"
  scoped
></style>
<template lang="html">
  <div class=".rg-content-hospitalization-childbirth-tincture">
    <RgContentHospitalizationTeam
      :procedure="procedure"
      :required-documents="requiredDocuments"
      @setProfessionalList="setProfissionalList"
    />
    <form class="form">
      <RgValidatorForm ref="validator">
        <RgTitle title="Parto com Laqueadura" />
        <div class="row">
          <RgInputMasked
            v-model="childbirthTincture.ipa_numero_sis_prenatal"
            :rules="{ required: true }"
            class="col-2"
            label="Nº SIS Pré Natal:"
            type="number"
            mask="############"
          />
          <RgInputMasked
            v-model="childbirthTincture.ipa_qtd_nascidos_vivos"
            :rules="{ required: true }"
            class="col-2"
            label="Nasc/Vivos:"
            type="number"
            mask="#"
          />
          <RgInputMasked
            v-model="childbirthTincture.ipa_qtd_obito_fetal"
            :rules="{ required: true }"
            class="col-2"
            label="Óbito/Fetal:"
            type="number"
            mask="#"
          />
          <RgInputMasked
            v-model="childbirthTincture.ipa_qtd_alta"
            :rules="{ required: true }"
            class="col-2"
            label="Alta(s):"
            type="number"
            mask="#"
          />
          <RgInputMasked
            v-model="childbirthTincture.ipa_qtd_transferencia"
            :rules="{ required: true }"
            class="col-2"
            label="Transferência(s):"
            type="number"
            mask="#"
          />
          <RgInputMasked
            v-model="childbirthTincture.ipa_qtd_obito"
            :rules="{ required: true }"
            class="col-2"
            label="Óbito(s):"
            type="number"
            mask="#"
          />
          <RgInputMasked
            v-model="childbirthTincture.ilv_qtd_filhos"
            :rules="{ required: true }"
            class="col-3"
            label="Quantidade de Filhos: "
            placeholder="0"
            mask="##"
          />
          <RgRadioYesNo
            v-model="childbirthTincture.ilv_gestacao_alto_risco"
            :rules="{ required: true }"
            :default-value="false"
            class="rg-radio col-2"
            label="Gestação Alto Risco:"
            tab-index="1"
          />
          <RgComboboxInstructionLevel
            v-model="childbirthTincture.ilv_id_internacoes_grau_instrucoes"
            :rules="{ required: true }"
            label="Grau de Instrução:"
            class="col-3"
          />
          <rg-combobox-cid
            v-model="childbirthTincture.ilv_codigo_cid10"
            :procedure="procedure.iap_codigo_procedimentos"
            :rules="{ required: true }"
            class="col-4"
            label="CID Principal:"
            title="Classificação Internacional de Doenças"
            @input="cidSelected"
          />

          <RgComboboxContraceptiveMethod
            v-model="
              childbirthTincture.ilv_id_internacoes_metodos_contraceptivos1
            "
            :rules="{ required: true }"
            label="Método Contraceptivo 1:"
            class="col-4"
          />

          <RgComboboxContraceptiveMethod
            v-model="
              childbirthTincture.ilv_id_internacoes_metodos_contraceptivos2
            "
            :rules="{ required: true }"
            label="Método Contraceptivo 2:"
            class="col-4"
          />
        </div>

        <div class="row">
          <button
            :disabled="!childbirthTincture.professionalTeamList.length > 0"
            type="button"
            class="btn btn-success col-2"
            @click="confirm"
          >
            Confimar
          </button>
          <button class="col-2 btn btn-default" @click.stop.prevent="close">
            Fechar
          </button>
        </div>
      </RgValidatorForm>
    </form>
  </div>
</template>

<script>
import { RgValidatorForm, RgTitle, RgInputMasked } from "~tokio/primitive";
import RgContentHospitalizationTeam from "$billing/hospitalization-billing/common/component/container/content-hospitalization-team/RgContentHospitalizationTeam";
import RgRadioYesNo from "$surgery-center/foundation/component/rg-radio-yes-no/RgRadioYesNo";
import RgComboboxInstructionLevel from "../../../combobox/rg-combobox-instruction-level/RgComboboxInstructionLevel";
import RgComboboxContraceptiveMethod from "../../../combobox/rg-combobox-contraceptive-method/RgComboboxContraceptiveMethod";
import mask from "vue-the-mask";
import { AlertError } from "../../../../service";

export default {
  name: "RgContentHospBillChildbirthTincture",
  components: {
    RgValidatorForm,
    RgTitle,
    RgInputMasked,
    RgContentHospitalizationTeam,
    RgRadioYesNo,
    RgComboboxInstructionLevel,
    RgComboboxContraceptiveMethod,
  },
  directives: {
    mask,
  },
  props: {
    procedure: {
      default: "",
    },
    requiredDocuments: {},
  },
  data() {
    return {
      professionalCaptionRaw: "pes_nome",
      childbirthTincture: {
        type: "childbirth-tincture",
        professionalTeamList: [],
      },
    };
  },
  mounted() {
    if (this.procedure.additionalData[0]) {
      this.childbirthTincture = this.procedure.additionalData[0];
    }
  },
  methods: {
    async confirm(pEvent) {
      if (await this.validateForm()) {
        this.$emit("onFinish", this.childbirthTincture);
      }
    },

    setProfissionalList(pEvent) {
      this.childbirthTincture.professionalTeamList = pEvent;
    },

    close() {
      this.$emit("close");
    },

    async validateForm() {
      const valid = await this.$refs.validator.validate();
      if (!this.validateNumberOfBirth()) return false;
      // if (!this.validateTeamMembers()) return false
      return valid;
    },

    validateNumberOfBirth() {
      const total =
        parseInt(this.childbirthTincture.ipa_qtd_obito) +
        parseInt(this.childbirthTincture.ipa_qtd_transferencia) +
        parseInt(this.childbirthTincture.ipa_qtd_alta);
      if (parseInt(this.childbirthTincture.ipa_qtd_nascidos_vivos) < total) {
        AlertError(
          "Número de altas, transferências e óbitos não devem ultrapassar o total de nascidos vivos",
        );
        return false;
      }
      return true;
    },

    validateTeamMembers() {
      const hasAnesthetist = this.childbirthTincture.professionalTeamList.filter(
        (prof) => prof.ieq_id_internacoes_funcoes_profissionais === 6,
      );
      const hasSurgeon = this.childbirthTincture.professionalTeamList.filter(
        (prof) => prof.ieq_id_internacoes_funcoes_profissionais === 1,
      );
      if (hasAnesthetist.length && hasSurgeon.length) {
        return true;
      }
      AlertError(
        "Necessário ao menos um cirurgião e um anestesista na equipe.",
      );
      return false;
    },

    cidSelected(pData) {
      if (pData && pData[0].text) {
        this.childbirthTincture.ilv_codigo_cid10 = pData[0].text;
      }
    },
  },
};
</script>
