import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query HomeVisitForm(
    $cvd_id: Int
    $cvd_id_microareas: Int
    $data_atendimento_inicial: String
    $data_atendimento_final: String
    $cvd_id_desfecho_visita: Int
    $cvd_id_turno: Int
    $data_nascimento_inicial: String
    $data_nascimento_final: String
    $exported: Boolean
    $limit: Int
    $offset: Int
    $orderDirection: String
    $orderField: String
    $uns_id: Int
    $equ_id: Int
    $are_id: Int
    $pab_nome: String
    $fab_id: Int
  ) {
    SearchHomeVisitForm(
      cvd_id: $cvd_id
      cvd_id_microareas: $cvd_id_microareas
      data_atendimento_inicial: $data_atendimento_inicial
      data_atendimento_final: $data_atendimento_final
      cvd_id_desfecho_visita: $cvd_id_desfecho_visita
      cvd_id_turno: $cvd_id_turno
      data_nascimento_inicial: $data_nascimento_inicial
      data_nascimento_final: $data_nascimento_final
      exported: $exported
      uns_id: $uns_id
      equ_id: $equ_id
      are_id: $are_id
      pab_nome: $pab_nome
      fab_id: $fab_id
      limit: $limit
      offset: $offset
      orderDirection: $orderDirection
      orderField: $orderField
    ) {
      rows {
        header_form {
          cfi_id
          cfi_id_equipes
          cfi_id_funcionarios_atencao_basica
          cfi_cartao_sus
          cfi_cnes
          cfi_codigo_ine
          cfi_codigo_cbo
          team {
            equ_nome_equipe
            area {
              are_id
              unit_health {
                uns_id
                uns_cnes
                uns_nome
              }
              are_nome
              are_descricao
            }
          }
          basic_attention_employee {
            fab_id
            fab_id_funcionarios
            employee {
              person {
                pes_nome
              }
            }
          }
        }
        shift {
          tur_id
          tur_descricao
        }
        property_type {
          tim_id
          tim_codigo_ab
          tim_descricao
        }
        microarea {
          mic_id
          mic_id_areas
          mic_codigo
          mic_descricao
        }
        outcome {
          des_codigo_ab
          des_descricao_desfecho
        }
        gender {
          sex_id
          sex_sigla
          sex_nome
        }
        followup {
          aco_id
          aco_codigo_ab
          aco_nome
        }
        active_search {
          bat_id
          bat_codigo_ab
          bat_nome
        }
        environment_control {
          cov_id
          cov_codigo_ab
          cov_nome
        }
        cvd_id
        cvd_id_cabecalho_fichas
        cvd_id_turno
        cvd_id_desfecho_visita
        cvd_id_sexos
        cvd_id_microareas
        cvd_id_tipos_imovel
        cvd_visita_compartilhada
        cvd_data
        cvd_unico
        cvd_data_exportacao
        cvd_prontuario
        cvd_documento
        cvd_nascimento
        cvd_observacao
        cvd_fora_area
        cvd_peso
        cvd_altura
        patient_basic_attention {
          pab_nome
        }
      }
      count
    }
  }
`;

export default async (pContext, pFilter) => {
  try {
    const variables = { ...pFilter };
    const { data } = await GraphQLClient.query({
      query,
      fetchPolicy: "no-cache",
      variables,
    });
    return data.SearchHomeVisitForm;
  } catch (err) {
    console.log(err);
    return err.graphQLErrors;
  }
};
