import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query waterSupply {
    SearchWaterSupply(limit: 100) {
      rows {
        aba_id
        aba_codigo_ab
        aba_nome
      }
      count
    }
  }
`;

export default async ({ commit, state }) => {
  if (state.water_supply.length > 0) {
    return;
  }
  try {
    const { data } = await GraphQLClient.query({
      query,
      fetchPolicy: "no-cache",
    });
    commit("SET_WATER_SUPPLY", data.SearchWaterSupply.rows);
  } catch (err) {
    console.log(err);
    return err.graphQLErrors;
  }
};
