import { OpenMenuModule } from "~common/utils/app/menu";

import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

import ExamPriority from "$exam/submodules/register/view/priority/ExamPriority.vue";

export default {
  path: "/exam/register/priority",
  name: "exam.register.priority",
  components: {
    default: ExamPriority,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Exame", link: OpenMenuModule("exam") },
      {
        label: "Cadastro de Prioridades",
        link: "/exam/register/priority",
      },
    ],
  },
};
