import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async (variables) => {
  return AuthLegacyRequest.post(
    "/consultas/controller-agendamento-consulta/combo-box-horarios-disponiveis",
    variables,
  )
    .then(({ data }) => {
      data.dados.data = data.dados.recordSet;

      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
