const actions = {};

const state = {
  periodNumber: null,
  periodDate: null,
  unifiedTableVersion: null,
};

const mutations = {
  SET_NUMBER: (store, pNumber) => {
    store.periodNumber = pNumber;
  },
  SET_PERIOD_DATE: (store, pDate) => {
    store.periodDate = pDate;
  },
  SET_UNIFIED_TABLE_VERSION: (store, pNumber) => {
    store.unifiedTableVersion = pNumber;
  },
};

const getters = {
  GET_PERIOD_NUMBER: (store) => {
    return store.periodNumber;
  },
  GET_PERIOD_DATE: (store) => {
    return store.periodDate;
  },
  GET_UNIFIED_TABLE_VERSION: (store) => {
    return store.unifiedTableVersion;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
