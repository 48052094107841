import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async (variable) => {
  return AuthLegacyRequest.post(
    "exames2/controller-exame-escala-agendamento/obter-quantidade-vagas-restantes",
    variable,
  )
    .then(({ data }) => {
      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
