<template lang="html">
  <svg
    id="Layer_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <path
      class="st00"
      d="M425.9,459.9v43.3c0,4.9-4,8.8-8.8,8.8H94.9c-4.9,0-8.8-4-8.8-8.8v-43.3c0-45.3,36.6-82.7,81.9-82.3
    c25.5,0.2,46.1-20.3,46.1-45.6v-7.5l0.2-0.4c-39.6-16.4-67.5-55.4-67.5-101v-3.5c-15.7,0-27.6-12.4-27.6-27.1V85
    c0-16.5,13.3-29.8,29.8-29.8C162.3,21.9,194.6,0,230.5,0h75.7c48.5,0,87.8,39.3,87.8,87.8v103.1c0,15.8-12.8,28.7-28.7,28.7v3.5
    c0,45.5-27.9,84.6-67.5,101l0.2,0.4v7.5c0,25.3,20.6,45.8,46.1,45.6C389.4,377.1,425.9,414.6,425.9,459.9L425.9,459.9z"
    />
    <path
      class="st10"
      d="M425.9,459.9v43.3c0,4.9-4,8.8-8.8,8.8H94.9c-4.9,0-8.8-4-8.8-8.8v-43.3c0-45.3,36.6-82.7,81.9-82.3
    c6.6,0.1,13-1.3,18.7-3.8v0c13.3,30.3,45,48.7,78.2,44.8c26.4-3.1,49.7-20,60.6-44.8v0c5.7,2.5,12,3.8,18.7,3.8
    C389.4,377.1,425.9,414.6,425.9,459.9z"
    />
    <path
      class="st20"
      d="M393.9,87.8v103.1c0,15.8-12.8,28.7-28.7,28.7c0-11.9,0-41.2,0-68.2c0-29.3-23.7-53-53-53H199.7
    c-29.3,0-53,23.7-53,53v68.2c-15.7,0-27.6-12.4-27.6-27.1V85c0-16.5,13.3-29.8,29.8-29.8C162.3,21.9,194.6,0,230.5,0h75.7
    C354.6,0,393.9,39.3,393.9,87.8L393.9,87.8z"
    />
    <path
      class="st30"
      d="M365.2,223c0,45.5-27.9,84.6-67.5,101c-71.5,29.7-151-22.9-151-101c0-7.4,0-63.1,0-71.6c0-29.3,23.7-53,53-53
    h112.6c29.3,0,53,23.7,53,53V223z"
    />
    <path
      class="st40"
      d="M191.7,383.5c6.3,10.1,0.8,23.3-10.7,26.3c-27.7,7.2-64.1,25.8-64.1,78.5V512H94.9c-4.9,0-8.8-4-8.8-8.8v-43.3
    c0-32.9,19.4-61.8,48.4-75h0c26.3-11.8,33.8-3.4,52.1-11C188.1,377.2,189.8,380.4,191.7,383.5L191.7,383.5z"
    />
    <path
      class="st40"
      d="M319.9,383.5c-6.3,10.1-0.8,23.3,10.7,26.3c27.7,7.2,64.1,25.8,64.1,78.5V512h22.1c4.9,0,8.8-4,8.8-8.8v-43.3
    c0-32.9-19.4-61.8-48.4-75h0c-26.3-11.8-33.8-3.4-52.1-11C323.6,377.2,321.8,380.4,319.9,383.5L319.9,383.5z"
    />
    <path
      class="st50"
      d="M231.3,293.6c18,11.2,3.8,38.8-15.9,30.9c-40.4-16.2-68.6-55.6-68.6-101.4v-71.6c0-11.1,3.4-21.5,9.3-30
    c7.3-10.7,23.9-5.4,23.8,7.6c0,0.3,0,71.7,0,71.9C179.9,241.3,201,274.7,231.3,293.6L231.3,293.6z"
    />
    <path
      class="st60"
      d="M299.2,342.5c-27.2,11.4-57.1,12.3-86.4,0c1.5-6.2,1.2-10.2,1.2-18.1l0.2-0.4c26.6,11,56.9,11.1,83.5,0l0.2,0.4
    C297.9,332.3,297.7,336.3,299.2,342.5L299.2,342.5z"
    />
    <path
      class="st40"
      d="M264.8,418.6v49.3c0,4.9-4,8.8-8.8,8.8c-4.9,0-8.8-4-8.8-8.8v-49.3C253.1,419.3,258.9,419.3,264.8,418.6z"
    />
    <path
      class="st70"
      d="M345.5,9.3c11.9,6,7.5,23.9-5.8,23.8c-0.4,0-75.8,0-76.2,0c-33.7,0-64.3,19.3-78.9,49.3
    c-1.7,3.4-4.9,5.7-8.6,6.5c-13.6,2.8-23.8,14.8-23.8,29.2v9.8c-3.5,7.1-5.5,15.1-5.5,23.6v49.1c0,12.2-15.9,17.3-22.8,7.3
    c-3-4.4-4.8-9.7-4.8-15.3V85c0-16.5,13.3-29.8,29.8-29.8C162.3,21.9,194.6,0,230.5,0h75.7C320.3,0,333.7,3.4,345.5,9.3L345.5,9.3z"
    />
  </svg>
</template>

<script>
export default {
  name: "IconPatientMan",
};
</script>

<style type="text/css">
.st00 {
  fill: #ffe171;
}
.st10 {
  fill: #407baf;
}
.st20 {
  fill: #42434d;
}
.st30 {
  fill: #ffe67b;
}
.st40 {
  fill: #3bc0f2;
}
.st50 {
  fill: #ffffa5;
}
.st60 {
  fill: #ffc37b;
}
.st70 {
  fill: #4d4e59;
}
</style>
