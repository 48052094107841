import { GraphQLClient, gql } from "@/common/request/GraphQLClient";
import {
  preparePatientInfo,
  preparePersonInfo,
  prepareDocuments,
  getRecordNumber,
} from "./HospitalizationBillingAihHelper";
import {
  HospitalizationPatientBillingFields,
  PersonFields,
} from "../../common/service/fields/";

const query = gql`
query getPatientInfoByHospitalizationOrAih($hospitalizationBillingAihIdPassed:Boolean!, $hospitalizationBillingAihId: Int, $hospitalizationId: Int, $unitHealthId: Int!) {
	hospitalizations(int_id: $hospitalizationId) @skip(if: $hospitalizationBillingAihIdPassed){
		rows {
      int_id
			patient {
        pac_id
        record_numbers(ppr_id_unidades_saude:$unitHealthId) {
          rows{
            ppr_numero
          }
        }
        person {
          ${PersonFields}
        }
			}
		}
	}

  hospitalizationBillingAih(iaf_id:$hospitalizationBillingAihId) @include(if: $hospitalizationBillingAihIdPassed) {
    rows{
      hospitalizationBillingPatient {
        ${HospitalizationPatientBillingFields}
      }
    }
  }
}
`;

export default async (
  { commit },
  {
    hospitalizationBillingAihId,
    hospitalizationId,
    unitHealthId,
    ignoreId = false,
  },
) => {
  const hospitalizationBillingAihIdPassed = !!hospitalizationBillingAihId;
  const variables = {
    hospitalizationBillingAihIdPassed,
    hospitalizationBillingAihId,
    hospitalizationId,
    unitHealthId,
  };
  const { data } = await GraphQLClient.query({
    query,
    variables,
    fetchPolicy: "no-cache",
  });

  if (
    (!data.hospitalizations || data.hospitalizations.rows.length < 1) &&
    (!data.hospitalizationBillingAih ||
      data.hospitalizationBillingAih.rows.length < 1)
  ) {
    return false;
  }

  if (hospitalizationBillingAihIdPassed) {
    commit(
      "SET_PATIENT_INFO_TAB",
      preparePatientInfo(
        data.hospitalizationBillingAih.rows[0].hospitalizationBillingPatient,
        ignoreId,
      ),
    );
  } else if (data.hospitalizations.rows[0].patient) {
    const fieldsPrepared = preparePersonInfo(
      data.hospitalizations.rows[0].patient.person,
    );
    const documents = prepareDocuments(
      data.hospitalizations.rows[0].patient.person,
    );
    const recordNumber = getRecordNumber(data.hospitalizations.rows[0].patient);

    commit("SET_PATIENT_UNIT_RECORD_NUMBER", recordNumber);
    commit("SET_PATIENT_DOCUMENTS", documents);
    commit("SET_PATIENT_INFO_TAB", fieldsPrepared);
  }

  return true;
};
