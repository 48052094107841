<template lang="html">
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 9.99949C0 10.5526 0.446715 10.9993 0.999836 10.9993H7.09494V14.2421C7.09541 14.6471 7.33682 15.0127 7.71228 15.1665C8.08494 15.3212 8.51618 15.2349 8.80165 14.9495L13.0443 10.7068C13.4352 10.3159 13.4347 9.68309 13.0443 9.29262L8.80165 5.05C8.51618 4.76453 8.08587 4.67781 7.71181 4.83297C7.33776 4.98766 7.09448 5.35281 7.09448 5.75734V9.00012H0.999367C0.446715 9.00012 0 9.44684 0 9.99949Z"
      fill="white"
    />
    <path
      d="M0.999836 6.1223C1.55202 6.1223 1.99967 5.67464 1.99967 5.12246V2.99998C1.99967 2.44779 2.44873 1.99967 2.99951 1.99967H8.99759C8.99805 1.99967 8.99899 1.99967 8.99946 1.99967H16.9991C17.2063 1.99967 17.3985 2.06295 17.5578 2.17076C17.5639 2.17498 17.5695 2.17967 17.5756 2.18389C17.5958 2.19795 17.616 2.21248 17.6347 2.22795C17.6455 2.23686 17.6553 2.2467 17.6652 2.25608C17.6788 2.26826 17.6928 2.27998 17.7055 2.29264C17.7181 2.30529 17.7299 2.31936 17.742 2.33295C17.7514 2.34326 17.7613 2.35311 17.7702 2.36342C17.7856 2.38217 17.8002 2.40232 17.8142 2.42248C17.8185 2.42857 17.8231 2.4342 17.8274 2.44029C17.9352 2.60014 17.9985 2.79232 17.9985 2.99904V16.9986C17.9985 17.2058 17.9352 17.398 17.8274 17.5574C17.8231 17.5635 17.8185 17.5691 17.8142 17.5752C17.8002 17.5953 17.7856 17.6155 17.7702 17.6342C17.7613 17.645 17.7514 17.6549 17.742 17.6647C17.7299 17.6783 17.7181 17.6924 17.7055 17.705C17.6928 17.7177 17.6788 17.7294 17.6652 17.7416C17.6549 17.751 17.645 17.7608 17.6347 17.7697C17.616 17.7852 17.5958 17.7997 17.5756 17.8138C17.5695 17.818 17.5639 17.8227 17.5578 17.8269C17.398 17.9347 17.2058 17.998 16.9991 17.998H2.99998C2.44779 17.998 1.99967 17.5489 1.99967 16.9981V14.8757C1.99967 14.3235 1.55202 13.8758 0.999836 13.8758C0.447653 13.8777 0 14.3254 0 14.8775V17C0 18.657 1.34296 20 2.99998 20H16.9996C17.8264 20 18.5764 19.6634 19.1197 19.1202C19.663 18.5769 19.9995 17.8269 19.9995 17V2.99998C19.9995 2.17311 19.663 1.42311 19.1197 0.879837C18.5769 0.33656 17.8269 0 17 0H8.99993C8.99946 0 8.99852 0 8.99805 0H2.99998C1.34296 0 0 1.34343 0 2.99998V5.12199C0 5.67464 0.447653 6.1223 0.999836 6.1223Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "Exit",
};
</script>
