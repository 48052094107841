import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query($icn_id: Int, $icn_codigo: String, $icn_descricao: String) {
    hospitalizationCnaer(
      icn_id: $icn_id
      icn_codigo: $icn_codigo
      icn_descricao: $icn_descricao
    ) {
      rows {
        icn_id
        icn_descricao
        icn_codigo
      }
    }
  }
`;

export default async (variables, findOne = false) => {
  const { data } = await GraphQLClient.query({ query, variables }).catch(
    (err) => {
      console.error(err);
    },
  );
  if (!findOne) {
    return data.hospitalizationCnaer.rows;
  } else {
    return data.hospitalizationCnaer.rows[0];
  }
};
