import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

import { OpenMenuModule } from "~common/utils/app/menu";

import EmergencyRegisterExit from "$emergency/bulletin/views/register-exit/EmergencyRegisterExit";

export default {
  path: "/emergency/bulletin/register-exit",
  name: "emergency.bulletin.register-exit",
  components: {
    default: EmergencyRegisterExit,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Emergência", link: OpenMenuModule("emergency") },
      { label: "Boletins", link: "/emergency/bulletin" },
      {
        label: "Cadastrar Saída",
        link: "/emergency/bulletin/register-exit",
      },
    ],
  },
};
