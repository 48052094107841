<style src="./BulletinFilter.scss" lang="scss" scoped></style>
<template lang="html">
  <div>
    <form @submit.prevent="submitForm">
      <RgValidatorForm ref="validator">
        <div class="row">
          <RgBulletinBillingStatusOption v-model="form.status" class="col-12" />
        </div>

        <div class="row">
          <RgInputDate
            v-model="form.initialDate"
            class="col-6"
            label="Data Inicial"
            placeholder="dd/mm/aaaa"
          />
          <RgInputDate
            v-model="form.finalDate"
            class="col-6"
            label="Data Final"
            placeholder="dd/mm/aaaa"
          />
        </div>

        <div class="row">
          <RgInput
            ref="bulletinNumberField"
            v-model="form.bol_numero"
            v-shortkey="['alt', 'b']"
            class="col-12"
            label="Número do boletim"
            placeholder="Informe o número do boletim"
            @shortkey.native="setBulletinNumberFieldFocus"
          />
        </div>

        <div class="row">
          <RgInput
            ref="personField"
            v-model="form.pes_nome"
            v-shortkey="['alt', 'p']"
            class="col-12"
            label="Paciente"
            placeholder="Informe o nome do paciente"
            @shortkey.native="setPersonFieldFocus"
          />
        </div>
      </RgValidatorForm>
    </form>
  </div>
</template>

<script>
import { RgInput, RgInputDate, RgValidatorForm } from "~tokio/primitive";
import RgBulletinBillingStatusOption from "../../../common/component/rg-bulletin-billing-status-option/RgBulletinBillingStatusOptions";
import moment from "moment";

export default {
  name: "BulletinFilter",
  components: {
    RgInput,
    RgInputDate,
    RgBulletinBillingStatusOption,
    RgValidatorForm,
  },
  data() {
    return {
      form: {
        initialDate: "",
        finalDate: "",
        pes_nome: "",
        bol_numero: "",
        status: "null",
      },
    };
  },
  computed: {
    hasPermission() {
      return this.$Permissions.global.has(
        "faturamento.atendimentosEmergencia",
        this.$store.getters["Login/GET_UNI_HEALTH_ID"],
      );
    },
  },
  mounted() {
    // esusng/esusng#742 Pedido de Nova Iguaçu para não preencher mais o filtro com a ultima busca
    // const bulletinSearchFilter = this.$store.getters['Billing/Bulletin/GET_BULLETIN_SEARCH_FILTER']
    // if (bulletinSearchFilter.status) {
    //   this.form = bulletinSearchFilter
    //   this.submitForm()
    // }
  },
  methods: {
    submitForm() {
      if (this.validateForm()) {
        // esusng/esusng#742
        // this.$store.commit('Billing/Bulletin/SET_BULLETIN_SEARCH_FILTER', this.form)
        const sendObject = this.prepareFilter();
        this.$emit("submit-filter", sendObject);
      }
    },
    prepareFilter() {
      const sendObject = Object.assign({}, this.sanitizeFields());
      sendObject.status = this.form.status;
      sendObject.pes_nome = this.form.pes_nome;
      sendObject.bol_numero = this.form.bol_numero;
      sendObject.initialDate = this.form.initialDate;
      sendObject.finalDate = this.form.finalDate;
      sendObject.uns_id = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
      sendObject.period = this.$store.getters["Billing/BDSia/GET_PERIOD_DATE"];
      return sendObject;
    },
    sanitizeFields() {
      const retObject = {};
      for (const item in this.form) {
        if (this.form[item]) {
          retObject[item] = this.form[item];
        }
      }
      if (retObject.bol_numero) {
        retObject.bol_numero = parseInt(retObject.bol_numero);
      }

      return retObject;
    },
    billing() {
      this.$router.push("billing");
    },
    setBulletinNumberFieldFocus() {
      this.$refs.bulletinNumberField.setFocus();
    },
    setPersonFieldFocus() {
      this.$refs.personField.setFocus();
    },
    cleanForm() {
      this.form = {
        initialDate: "",
        finalDate: "",
        pes_nome: "",
        bol_numero: "",
        status: "null",
      };
      this.submitForm();
    },
    validateForm() {
      if (!this.$refs.validator.validate()) return false;
      const InitialDate = this.form.initialDate
        ? moment(this.form.initialDate, "DD-MM-YYYY").format("YYYY-MM-DD")
        : "";
      const FinalDate = this.form.finalDate
        ? moment(this.form.finalDate, "DD-MM-YYYY").format("YYYY-MM-DD")
        : "";
      if (InitialDate && FinalDate && FinalDate < InitialDate) {
        this.$toaster.error("Data final não pode ser menor que a data inicial");
        return false;
      }
      if (FinalDate && FinalDate > moment().format("YYYY-MM-DD")) {
        this.$toaster.error("Data final não pode ser maior que a data atual");
        return false;
      }
      return true;
    },
  },
};
</script>
