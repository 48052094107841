import { GraphQLClient, gql } from "@/common/request/GraphQLClient";
import {
  FlattenData,
  RemoveUnusedFields,
  SanitizeFields,
} from "./PersonHelper";

const mutation = gql`
  mutation($person: PersonInputType!, $address: AddressInputType) {
    CreatePerson(person: $person, address: $address) {
      pes_nome
      pes_id
    }
  }
`;

export default async (store, personInfo) => {
  const person = Object.assign({}, personInfo);
  const address = Object.assign({}, personInfo.address);
  delete person.address;

  const flattendedData = FlattenData({ address, person });
  const removedUnusedFields = RemoveUnusedFields(flattendedData);
  const sanitizedFields = SanitizeFields(removedUnusedFields);

  const variables = sanitizedFields;

  try {
    const { data } = await GraphQLClient.mutate({ mutation, variables });
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};
