<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchAppointmentObjective from "./action/SearchAppointmentObjective";
export default {
  name: "RgComboboxAppointmentObjective",
  mixins: [RgComboboxMixin],
  props: {
    healthUnitId: {
      required: true,
    },
    healthPlansId: {
      required: true,
    },
    active: {
      required: true,
    },
  },
  data: () => {
    return {};
  },
  watch: {
    healthPlansId() {
      this.fillData();
    },
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];
      if (!this.healthUnitId || !this.healthPlansId) {
        return;
      }
      const data = await SearchAppointmentObjective({
        intIdUnidadeSaude: this.healthUnitId,
        intIdPlanoSaude: this.healthPlansId,
        ativo: this.active,
      });
      data.forEach((item) => {
        this.mData.push({ value: item.data, text: item.label });
      });
    },
  },
};
</script>
