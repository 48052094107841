<style src="./ScheduleFilter.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="schedule-filter">
    <div class="filters">
      <div
        v-shortkey="['alt', '1']"
        :class="{ 'filter-box-container': filterComponent === 'PersonFilter' }"
        class="option-filter-container"
        title="Buscar por pessoa "
        @click="changeFilter('PersonFilter')"
        @shortkey="changeFilter('PersonFilter')"
      >
        <IconUser
          :class="{ 'option-filter--person-selected': styleActivePerson }"
          class="option-filter"
        />
        <IconCheck v-if="styleActivePerson" class="option-indicator" />
      </div>
      <div
        v-shortkey="['alt', '2']"
        :class="{ 'filter-box-container': filterComponent === 'DateFilter' }"
        class="option-filter-container"
        title="Buscar por data "
        @click="changeFilter('DateFilter')"
        @shortkey="changeFilter('DateFilter')"
      >
        <IconCalendar
          :class="{ 'option-filter--date-selected': styleActiveDate }"
          class="option-filter"
        />
        <IconCheck v-if="styleActiveDate" class="option-indicator" />
      </div>
      <div
        v-shortkey="['alt', '3']"
        :class="{ 'filter-box-container': filterComponent === 'ClinicFilter' }"
        class="option-filter-container"
        title="Buscar por Clinica "
        @click="changeFilter('ClinicFilter')"
        @shortkey="changeFilter('ClinicFilter')"
      >
        <IconSector
          :class="{ 'option-filter--sector-selected': styleActiveClinic }"
          class="option-filter"
        />
        <IconCheck v-if="styleActiveClinic" class="option-indicator" />
      </div>
      <div
        v-shortkey="['alt', '4']"
        :class="{ 'filter-box-container': filterComponent === 'StatusFilter' }"
        class="option-filter-container"
        title="Buscar por Status "
        @click="changeFilter('StatusFilter')"
        @shortkey="changeFilter('StatusFilter')"
      >
        <IconStatus
          :class="{ 'option-filter--status-selected': styleActiveStatus }"
          class="option-filter"
        />
        <IconCheck v-if="styleActiveStatus" class="option-indicator" />
      </div>
    </div>

    <div
      :is="filterComponent"
      class="filter-component--content"
      @close="closeFilter(filterComponent)"
    />
  </section>
</template>

<script>
import {
  IconCalendar,
  IconHospital,
  IconSector,
  IconUser,
  IconStatus,
  IconCheck,
} from "~tokio/primitive";
import ClinicFilter from "./clinic-filter/ClinicFilter";
import DateFilter from "./date-filter/DateFilter";
import StatusFilter from "./status-filter/StatusFilter";
import UnithealthFilter from "./unithealth-filter/UnithealthFilter";
import PersonFilter from "./person-filter/PersonFilter";

export default {
  name: "ScheduleFilter",
  components: {
    IconCheck,
    IconCalendar,
    IconHospital,
    IconSector,
    IconUser,
    IconStatus,
    ClinicFilter,
    DateFilter,
    StatusFilter,
    UnithealthFilter,
    PersonFilter,
  },
  data() {
    return {
      filterComponent: "",
    };
  },
  computed: {
    styleActivePerson() {
      return this.$store.getters["SurgeryCenter/Surgery/filterList"].includes(
        "main",
      );
    },
    styleActiveDate() {
      return this.$store.getters["SurgeryCenter/Surgery/filterList"].includes(
        "cci_data_inicio",
      );
    },
    styleActiveClinic() {
      return this.$store.getters["SurgeryCenter/Surgery/filterList"].includes(
        "inc_id",
      );
    },
    styleActiveStatus() {
      return this.$store.getters["SurgeryCenter/Surgery/filterList"].includes(
        "cci_id_status_controles_cirurgicos",
      );
    },
    styleActiveUnithealth() {
      return this.$store.getters["SurgeryCenter/Surgery/filterList"].includes(
        "uns_id",
      );
    },
  },
  methods: {
    changeFilter(pFilter) {
      if (pFilter === this.filterComponent) {
        this.filterComponent = "";
      } else {
        this.filterComponent = pFilter;
      }
    },

    closeFilter(pFilterComponent) {
      this.filterComponent = "";
    },
  },
};
</script>
