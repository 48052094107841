/**
 * @param {*} pProcedure
 */
const PreparedBioslabExtraInfoToSave = (pProcedure) => {
  return {
    lpe_id: pProcedure.lpe_id,
    lpe_codigo_sigtap: pProcedure.codigo,
    lpe_codigo_exame_bioslab_mne: pProcedure.codigoExameMne,
    lpe_objeto_analise: replaceSpecial(
      pProcedure.objectAnalisis.lob_nome_objetos,
    ),
    lpe_material_biologico: replaceSpecial(pProcedure.collectMaterial),
    lpe_situacao: replaceSpecial(""),
    lpe_mensagem_erro: replaceSpecial(""),
    object_analise_id: pProcedure.objectAnalisis.lob_id,
    lpe_ativo: "1",
    lpe_motivo_inclusao: pProcedure.reasonInclusion,
  };
};

const replaceSpecial = (str) => {
  if (str === null || str === "" || str === undefined) {
    return str;
  } else {
    const oldCaracter =
      "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ";
    const newCaracter =
      "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr";
    let newstr = "";
    let i, change, a;
    for (i = 0; i < str.length; i++) {
      change = false;
      for (a = 0; a < oldCaracter.length; a++) {
        if (str.substr(i, 1) === oldCaracter.substr(a, 1)) {
          newstr += newCaracter.substr(a, 1);
          change = true;
          break;
        }
      }
      if (change === false) {
        newstr += str.substr(i, 1);
      }
    }
    return newstr;
  }
};

export default PreparedBioslabExtraInfoToSave;
