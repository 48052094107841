<style src="./RgInputCpf.scss" lang="scss"></style>
<template lang="html">
  <fieldset class="rg-input--component">
    <RgFormBase :label="label">
      <div slot="right-label">
        <RgValidationAlert :alert="error" />
      </div>
      <div class="rg-input--base">
        <div class="rg-input--textbox-container">
          <input
            v-model="inputValue"
            v-mask="'### ### ### ##'"
            :placeholder="placeholder"
            :tabindex="tabIndex"
            type="text"
            @keypress="keypress"
            @keydown="keydown"
            @keyup="keyup"
            @blur="validateBlur"
          />
        </div>
      </div>
    </RgFormBase>
  </fieldset>
</template>

<script>
import { RgFormBase } from "~tokio/foundation/container";
import { RgValidatorMixin, RgValidationAlert } from "../../validation";
import RgInputMixin from "../mixin/RgInputMixin";
import Cpf from "./RgInputCpfHelper";
import { mask } from "vue-the-mask";

export default {
  name: "RgInputCpf",
  components: {
    RgValidationAlert,
    RgFormBase,
  },
  directives: {
    mask,
  },
  mixins: [RgValidatorMixin, RgInputMixin],
  props: {
    label: {
      type: String,
      default: "CPF",
    },
    placeholder: {
      type: String,
      default: "",
    },
    checkDigit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      anotherRules: {},
    };
  },
  watch: {
    checkDigit(pValue) {
      this.setAnotherRules(pValue);
    },
  },
  mounted() {
    this.setAnotherRules(this.checkDigit);
  },
  methods: {
    /**
     * @param {boolean} pCheckCPF
     */
    setAnotherRules(pCheckCPF) {
      this.anotherRules = pCheckCPF ? { checkDigit: Cpf.checkDigit } : {};
    },
    validateBlur() {
      this.validate();
    },
    keypress(ev) {
      this.$emit("keypress", ev);
    },
    keydown(ev) {
      this.$emit("keydown", ev);
    },
    keyup(ev) {
      this.$emit("keyup", ev);
    },
  },
};
</script>
