<style src="./HospitalizationList.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="hospitalization-list">
    <ModuleBox
      :granted="hasPermission"
      title="Lista de Internação"
      class="hospitalization-box"
    >
      <div slot="title">
        <slot name="actions" />
      </div>
      <ListContent
        v-if="hospitalizationList && !hospitalizationList.isAihType"
        :hospitalization-list="hospitalizationList"
        @selectHospitalization="selectHospitalization"
      />
      <ListContentAih
        v-else
        :hospitalization-list="hospitalizationList"
        @selectHospitalization="selectHospitalization"
        @reloadSearch="emitReloadSearch"
      />
      <div slot="footer" class="list-footer">
        <div class="list-size">Total de Registros: {{ listSize }}</div>
        <div class="list-pagination">
          Página: {{ pagination.current }}/{{ numberOfPages }}
        </div>
      </div>
    </ModuleBox>
  </section>
</template>

<script>
import ModuleBox from "~tokio/foundation/modulebox/Modulebox";
import ListContent from "../list-content/ListContent";
import ListContentAih from "../list-content-aih/ListContentAih";

export default {
  name: "HospitalizationList",
  components: {
    ModuleBox,
    ListContent,
    ListContentAih,
  },
  props: {
    pagination: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      hospitalizationList: null,
    };
  },
  computed: {
    hospitalization() {
      return this.$store.getters[
        "Billing/HospitalizationBilling/GET_HOSPITALIZATION_AIH_LIST"
      ];
    },
    hasPermission() {
      return this.$Permissions.global.has(
        "faturamento.faturar",
        this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
      );
    },
    numberOfPages() {
      return Math.ceil(this.listSize / this.pagination.limit);
    },
    listSize() {
      return this.hospitalizationList && this.hospitalizationList.count
        ? this.hospitalizationList.count
        : 0;
    },
  },
  watch: {
    hospitalization(pHospitalizationList) {
      this.hospitalizationList = pHospitalizationList;
    },
  },
  methods: {
    selectHospitalization(hospitalizationId) {
      this.$emit("selectHospitalization", hospitalizationId);
    },
    emitReloadSearch() {
      this.$emit("reloadSearch");
    },
  },
};
</script>
