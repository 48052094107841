import { GraphQLClient, gql } from "@/common/request/GraphQLClient";
import { DeepCopy } from "~utils/object";
const query = gql`
  query People(
    $name: String
    $mother: String
    $birthday: String
    $cns: String
    $cpf: String
    $gender: [Int]
    $recordNumber: String
    $unitRecordNumber: String
    $isPatient: Boolean
    $unitHealthId: Int
    $active: Boolean
    $limit: Int
    $offset: Int
  ) {
    people(
      pes_nome: $name
      pes_mae: $mother
      pes_nascimento: $birthday
      cpf: $cpf
      cns: $cns
      pes_id_sexos: $gender
      pac_prontuario_unico: $recordNumber
      ppr_numero: $unitRecordNumber
      isPatient: $isPatient
      uns_id: $unitHealthId
      pes_ativo: $active
      limit: $limit
      offset: $offset
    ) {
      count
      rows {
        pes_id
        pes_nome
        pes_foto
        pes_nome_social
        pes_nascimento
        pes_mae
        pes_ativo
        pes_email
        pes_profissao
        pes_id_locais_atendimento_pleni
        gender {
          sex_id
          sex_nome
          sex_sigla
        }
        patient {
          pac_id
          pac_prontuario_unico
          record_numbers {
            rows {
              ppr_numero
              ppr_id_unidades_saude
            }
          }
        }
        cns {
          crs_id
          crs_numero
        }
        cpf {
          cpf_id
          cpf_numero
        }
        telephones {
          rows {
            tel_id
            tel_numero
            tel_recado

            ddd {
              ddd_id
              ddd_codigo
            }
          }
        }
        address {
          end_cep
          end_logradouro
          end_numero
          end_referencia
          end_complemento
          neighborhood {
            bai_nome
            city {
              mun_id
              mun_nome
              state {
                est_id
                est_sigla
              }
            }
          }
          publicPlaceType {
            tlg_id
            tlg_codigo
            tlg_abreviatura
            tlg_nome
          }
        }
        pleniIntegration {
          ipn_id
          ipn_status
          ipn_data_log
        }

        notification {
          rows {
            nop_id
            nop_id_ddds
            nop_celular
          }
        }
      }
    }
  }
`;

const formatData = (pFilters) => {
  const filters = { ...pFilters };
  if (pFilters.birthday && pFilters.birthday.length === 10) {
    filters.birthday = pFilters.birthday.split("/").reverse().join("-");
  }
  if (pFilters.cpf && pFilters.cpf.length > 0) {
    filters.cpf = pFilters.cpf.replace(/\D/g, "");
  }

  if (pFilters.cns && pFilters.cns.length > 0) {
    filters.cns = pFilters.cns.replace(/\D/g, "");
  }
  return filters;
};

const documentsNotCompleted = (variables) => {
  if (variables.cpf && variables.cpf.length !== 11) {
    return true;
  }

  if (variables.cns && variables.cns.length !== 15) {
    return true;
  }
  return false;
};

export default async ({ commit }, filters = {}) => {
  try {
    const variables = formatData(filters);

    if (documentsNotCompleted(variables)) {
      return [];
    }

    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "network-only",
    });

    // remove reference
    return DeepCopy(data.people);
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors[0].message);
  }
};
