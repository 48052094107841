import { GraphQLClient, gql } from "@/common/request/GraphQLClient";
import moment from "moment";

const query = gql`
  query Employee($funId: Int) {
    employees(fun_id: $funId) {
      rows {
        fun_conselho_classe
        fun_crs_numero_ab
        person {
          pes_ativo
          pes_id
          pes_nome
          pes_nascimento
          pes_nome_social
          pes_mae
          pes_pai
          pes_profissao
          pes_obs
          pes_foto
          pes_id_usuarios
          gender {
            sex_id
          }
          religion {
            rel_id
          }
          bloodType {
            tps_id
          }
          maritalStatus {
            ecv_id
          }
          ethnicity {
            etn_id
          }
          nationality {
            nac_id
            nac_nome
          }
          city {
            mun_id
            mun_nome
          }
          scholarity {
            esc_id
          }
          jobStatus {
            sit_id
          }
          telephones {
            rows {
              tel_id
              tel_numero
              tel_recado
              ddd {
                ddd_id
                ddd_codigo
              }
            }
          }
          address {
            end_cep
            end_logradouro
            end_numero
            end_complemento
            end_referencia
            publicPlaceType {
              tlg_id
              tlg_nome
            }
            subNeighborhood {
              sub_nome
            }
            neighborhood {
              bai_nome
              city {
                mun_id
                mun_nome
                state {
                  est_sigla
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default async (store, { funId = null }) => {
  const variables = {
    funId,
  };

  let ret = null;
  try {
    ret = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    if (ret.data.employees.rows.length < 1) return false;
  } catch (e) {
    return false;
  }

  const { data } = ret;
  const rawField = JSON.parse(JSON.stringify(data.employees.rows[0]));
  const noRefFields = rawField.person;

  const classCouncilSplitted = splitClassCouncil(rawField);
  const phoneSplitted = splitPhone(noRefFields);
  const additionalInfoSplitted = splitAdditionalInfo(noRefFields);
  const fieldsPrepared = prepareBasicData(noRefFields);

  return {
    basicInfo: fieldsPrepared,
    phoneInfo: phoneSplitted,
    additionalInfo: additionalInfoSplitted,
    employeeData: classCouncilSplitted,
  };
};

const prepareBasicData = (pFields) => {
  delete pFields.nationality;
  delete pFields.city;
  delete pFields.pes_pai;
  delete pFields.religion;
  delete pFields.bloodType;
  delete pFields.maritalStatus;
  delete pFields.ethnicity;
  delete pFields.scholarity;
  delete pFields.jobStatus;
  delete pFields.pes_profissao;
  delete pFields.pes_obs;
  delete pFields.telephones;
  delete pFields.user;
  delete pFields.fun_conselho_classe;
  delete pFields.fun_crs_numero_ab;

  if (pFields.patient) {
    delete pFields.patient.record_numbers;
  }

  pFields.pes_nascimento = pFields.pes_nascimento
    ? moment(pFields.pes_nascimento).format("DD/MM/YYYY")
    : "Não Informado";

  return pFields;
};

const splitPhone = (pFields) => {
  const phoneSplitted = pFields.telephones.rows;

  pFields.telephones = null;
  return phoneSplitted;
};

const splitAdditionalInfo = (pFields) => {
  const additionalInfoSplitted = {
    nationality: pFields.nationality.nac_nome,
    city: pFields.city.mun_nome,
    pes_pai: pFields.pes_pai,
    pes_id_religiao: pFields.religion.rel_id,
    pes_id_tipos_sanguineos: pFields.bloodType.tps_id,
    pes_id_estados_civis: pFields.maritalStatus.ecv_id,
    pes_id_etnias: pFields.ethnicity.etn_id,
    pes_id_nacionalidades: pFields.nationality.nac_id,
    pes_id_municipios: pFields.city.mun_id,
    pes_id_escolaridades: pFields.scholarity.esc_id,
    pes_id_situacao_trabalho: pFields.jobStatus.sit_id,
    pes_profissao: pFields.pes_profissao,
    pes_obs: pFields.pes_obs,
    pes_id_usuarios: pFields.pes_id_usuarios,
    user: pFields.user ? pFields.user.usu_nome : "",
  };

  pFields.nationality = null;
  pFields.city = null;
  pFields.pes_pai = null;
  pFields.religion.rel_id = null;
  pFields.bloodType.tps_id = null;
  pFields.maritalStatus.ecv_id = null;
  pFields.ethnicity.etn_id = null;
  pFields.scholarity.esc_id = null;
  pFields.jobStatus = null;
  pFields.pes_profissao = null;
  pFields.pes_obs = null;
  pFields.pes_id_usuarios = null;

  return additionalInfoSplitted;
};

const splitClassCouncil = (pFields) => {
  const classCouncil = {
    fun_conselho_classe: pFields.fun_conselho_classe,
    fun_crs_numero_ab: pFields.fun_crs_numero_ab,
  };

  return classCouncil;
};
