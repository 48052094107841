<style src="./SurgeryScheduler.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="surgery-scheduler">
    <StageColumns :stages="stages" />

    <div ref="stageContainer" class="scheduler">
      <div class="scheduler-container">
        <HourGuide />
        <div
          ref="stageInsideContainer"
          :style="styleScrollPos"
          class="stage-container"
        >
          <GridStageHour :stages="stages" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import StageColumns from "./stage-columns/StageColumns";
import HourGuide from "./hour-guide/HourGuide";
import GridStageHour from "./grid-stage-hour/GridStageHour";
import { mapGetters } from "vuex";

export default {
  name: "SurgeryScheduler",
  components: {
    StageColumns,
    HourGuide,
    GridStageHour,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      stages: "SurgeryCenter/Surgery/getAllStages",
      styleScrollPos: "SurgeryCenter/Surgery/getStyleScrollPosition",
    }),
  },
  mounted() {
    const stageWidth = {
      stageContainer: this.$refs.stageContainer.clientWidth,
      stageInsideContainer: this.$refs.stageInsideContainer.clientWidth,
    };
    this.$store.dispatch("SurgeryCenter/Surgery/setStageWidth", stageWidth);
  },
};
</script>
