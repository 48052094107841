<style src="./RgInputDate.scss" lang="scss"></style>
<script>
import { RgInputDateMixin } from "../mixin";

export default {
  name: "RgInputDate",
  mixins: [RgInputDateMixin],
  props: {
    label: {
      type: String,
      default: "Data",
    },
    placeholder: {
      type: String,
      default: "dd/mm/aaaa",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
