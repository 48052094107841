<template>
  <PermissionLoader
    :to-close="permissionLoaderLoading"
    :titleLoader="permissionLoaderTitle"
  />
</template>
<script>
import PermissionLoader from "./PermissionLoader";
export default {
  name: "PermissionLoaderGlobal",
  components: {
    PermissionLoader,
  },
  computed: {
    permissionLoaderLoading() {
      return this.$store.state.PermissionLoader.loading;
    },
    permissionLoaderTitle() {
      return this.$store.state.PermissionLoader.message;
    },
  },
};
</script>
