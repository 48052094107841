<template>
  <section class="foundation-schedules">
    <RgSearch
      ref="RgSearch"
      v-model="mutableList"
      :buildFilter="generateFilter"
      :searchFunction="actionSearchFilter"
      :clearFunction="actionCleanForm"
      showEmptyMessage
      :itemHeight="29.5"
      :maxRegister="30"
      resultTitle="Lista de Pacientes Agendados pela Fila"
      @afterPerformSearch="actionAfterSearchFilter"
      @count="getCountValue"
    >
      <section slot="filters" class="foundation-schedules-filter">
        <section class="grid">
          <div class="filter-title">
            <span class="title"> Dados de Agendamento (Destino) </span>
          </div>

          <div class="selectinput">
            <RgComboboxUnithealth
              v-model="form.unitDestiny"
              label="Unidade de Saúde"
              class="inputitem"
              default-text="Todas"
              :permission="permissionScreen"
            />
          </div>

          <div class="selectinput">
            <RgComboboxSector
              v-model="form.sectorDestiny"
              class="inputitem"
              label="Setor"
              default-text="Todos"
              :disabled="!form.unitDestiny"
              :unit-health="form.unitDestiny"
            />
          </div>

          <div class="selectinput">
            <RgComboboxPlace
              v-model="form.placeDestiny"
              class="inputitem"
              default-text="Todos"
              :disabled="!form.sectorDestiny"
              :sector="form.sectorDestiny"
            />
          </div>

          <div v-if="isAppointment" class="selectinput">
            <RgSuggestCboSector
              ref="Cbo"
              v-model="form.ocp_nome"
              :disabled="!form.sectorDestiny"
              :class="{ disable: !form.sectorDestiny }"
              :sectorId="Number(form.sectorDestiny)"
              label="Ocupação"
              placeholder="Digite o nome da ocupação"
              class="inputitem"
              @selected="selectedCbo"
            />
          </div>

          <div v-else class="selectinput">
            <RgComboboxProceduresFilter
              ref="procedure"
              v-model="form.stp_id"
              :disabled="!form.sectorDestiny"
              :class="{ disable: !form.sectorDestiny }"
              :variable="{ intIdSetor: form.sectorDestiny }"
              label="Procedimento"
              placeholder="Digite o nome do procedimento"
              class="inputitem"
            />
          </div>

          <div class="selectinput">
            <RgSuggestEmployee
              ref="employee"
              v-model="form.pes_nome"
              :maxlength="100"
              :ocuppation-id="form.ocp_id"
              :sector="form.sectorDestiny"
              :vus-responsavel="false"
              :disabled="!form.sectorDestiny"
              :class="{ disable: !form.sectorDestiny }"
              label="Profissional"
              placeholder="Digite o nome do profissional"
              class="inputitem"
              sectorRequired
              @selected="selectingEmployee"
            />
          </div>

          <div class="selectinput">
            <RgSuggestUser
              ref="userDestiny"
              v-model="form.userDestiny"
              label="Usuário"
              @selected="selectingUser"
            />
          </div>

          <div class="selectinput two-columns">
            <RgInputDate
              v-model="form.scheduleDateStart"
              :rules="{ fn: verifyScheduleDate }"
              label="Data Inicial"
              placeholder="dd/mm/aaaa"
              class="inputitem"
            />
            <RgInputDate
              v-model="form.scheduleDateEnd"
              label="Data Final"
              placeholder="dd/mm/aaaa"
              class="inputitem"
            />
          </div>

          <div class="filter-title">
            <span class="title"> Dados do Paciente </span>
          </div>

          <div class="selectinput">
            <RgInput
              ref="patient"
              v-model="form.patientName"
              :disabled="!!form.pac_id"
              :class="{ disable: !!form.pac_id }"
              class="inputitem"
              placeholder="Digite o nome do paciente"
              label="Paciente"
            />
          </div>

          <div class="selectinput">
            <RgSuggestSmartPerson
              ref="patient"
              v-model="form.specificPatientName"
              :disabled="form.patientName.length > 0"
              :with-patient-module="permissionSuggest"
              :enabled-patient="false"
              filterOnlyDocument
              @selected="selectingPatient"
            />
          </div>

          <div class="filter-title">
            <span class="title"> Dados da Regulação (Origem) </span>
          </div>

          <div class="selectinput">
            <RgComboboxUnithealth
              v-model="form.unitOrigem"
              label="Unidade de Saúde"
              class="inputitem"
              :default-text="'Todas'"
              :permission="'consulta.filaConsulta.exibir'"
            />
          </div>

          <div class="selectinput">
            <RgSuggestUser
              ref="userOrigin"
              v-model="form.userOrigin"
              label="Usuário"
              @selected="selectingUserOrigin"
            />
          </div>

          <div class="selectinput two-columns">
            <RgInputDate
              v-model="form.insertDateStart"
              :rules="{ fn: verifyRegulationDate }"
              placeholder="dd/mm/aaaa"
              label="Data Inicial"
              class="inputitem"
            />
            <RgInputDate
              v-model="form.insertDateEnd"
              placeholder="dd/mm/aaaa"
              label="Data Final"
              class="inputitem"
            />
          </div>

          <div class="filter-title">
            <span class="title"> Dados da Solicitação </span>
          </div>

          <div class="selectinput">
            <RgComboboxUnithealth
              v-model="form.unitRequesting"
              class="inputitem"
              :default-text="'Todas'"
              :permission="'consulta.filaConsulta.exibir'"
            />
          </div>

          <div class="selectinput">
            <RgSuggestEmployee
              ref="employeeRequest"
              v-model="form.pes_nome_solicitante"
              :maxlength="100"
              :vus-responsavel="false"
              label="Profissional Solicitante"
              placeholder="Digite o nome do profissional"
              class="inputitem"
              @selected="selectingRequestEmployee"
            />
          </div>

          <div class="selectinput">
            <ComboboxPriority
              v-model="form.priority"
              :module-id="submoduleId"
              label="Prioridade"
              default-text="Todas"
              class="inputitem"
            />
          </div>

          <div class="selectinput">
            <RgRadioCustom
              ref="RgRadioCustom"
              :list="RADIO_DATA"
              :value="form.withPrint"
              uniqueKey="id"
              @input="onInputRadioCustom"
            />
          </div>
        </section>
      </section>

      <section class="foundation-schedules-search">
        <SmartTable
          ref="smartTable"
          :name="
            isAppointment
              ? 'AppointmentFoundationSearchSchedules'
              : 'ExamFoundationSearchSchedules'
          "
          :columns="isAppointment ? COLUMN_APPOINTMENT : COLUMN_EXAM"
          :body="mutableList"
          :dynamic-height="getSpaceTable"
          :total="Number(totalList)"
          :initial-columns="5"
          :show-pagination="false"
          :colorLine="actionColorLine"
          :showColorInFirstColumn="false"
          toggle-selected
          circle-indicator
          class="smart-table"
          @getLine="selectRowData"
        >
          <div slot="top-buttons" class="top-buttons">
            <RgPersonDropdown
              :disabled="!selectedRow"
              :item="selectedRow || {}"
              class="_mg-r-10"
              :action-options="itensActionOptionsPerson(selectedRow)"
              show-down
              show-left
            />

            <RgDropdownPrinter
              :item="selectedRow || {}"
              :action-options="itensActionOptionsPrint(selectedRow)"
              class="_mg-r-5"
              show-down
              show-left
            />
          </div>
        </SmartTable>
      </section>
    </RgSearch>

    <ModalInformationPrint
      :show="showModalInformationPrint"
      :list-size="totalList"
      @close="closeModalInformationPrint"
    />

    <ModalPatientImageInRow
      v-if="selectedRow && modalPatientImageInRow"
      hide-buttons
      :show="modalPatientImageInRow"
      :fill-id="Number(selectedRow.fil_id)"
      :modId="submoduleId"
      @close="closeModalPatientImageInRow"
    />
  </section>
</template>

<script>
import RgSuggestSmartPerson from "~tokio/primitive/suggest/rg-suggest-smart-person/RgSuggestSmartPerson.vue";
import { RgInput, RgInputDate } from "~tokio/primitive";

import RgSuggestEmployee from "~tokio/primitive/suggest/rg-suggest-employee/RgSuggestEmployee.vue";
import RgSuggestUser from "~tokio/primitive/suggest/rg-suggest-user/RgSuggestUser.vue";
import RgSuggestCboSector from "~tokio/primitive/suggest/rg-suggest-cbo-sector/RgSuggestCboSector.vue";

import { RgRadioCustom } from "~tokio/primitive/radio";
import { RgPersonDropdown, RgDropdownPrinter } from "~tokio/primitive/dropdown";

import {
  RgComboboxUnithealth,
  RgComboboxSector,
  RgComboboxPlace,
} from "~tokio/primitive/combobox";
import { ModalPatientImageInRow } from "~tokio/primitive/modal";
import ModalInformationPrint from "~tokio/primitive/modal/modal-information-print/ModalInformationPrint";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";

import moment from "moment";

import RgSearch from "~tokio/foundation/rg-search/RgSearch";

import ComboboxPriority from "~tokio/primitive/combobox/rg-combobox-priority/RgComboboxPriority";
import RgComboboxProceduresFilter from "$exam/submodules/schedule/component/combobox/rg-combobox-procedures-filter/RgComboboxProceduresFilter";

const FORM_BASE = {
  unitOrigem: null,
  unitDestiny: null,
  sectorDestiny: null,
  placeDestiny: null,
  ocp_nome: "",
  ocp_codigo: "",
  ocp_id: null,
  stp_id: null,
  fun_id_solicitante: 0,
  pes_nome_solicitante: "",
  vin_id_solicitante: 0,
  fun_id: 0,
  pes_nome: "",
  patientName: "",
  pac_id: 0,
  pes_id: 0,
  specificPatientName: "",
  vin_id: 0,
  usu_id_destiny: 0,
  userDestiny: "",
  unitRequesting: null,
  scheduleDateStart: null,
  scheduleDateEnd: null,
  insertDateStart: null,
  insertDateEnd: null,
  requestingDoctor: null,
  priority: null,
  patient: null,
  patientDocument: null,
  usu_id_origin: 0,
  userOrigin: "",
  withPrint: 2,
};

export default {
  name: "FoundationSearchSchedules",
  components: {
    RgSearch,
    SmartTable,
    RgComboboxUnithealth,
    RgComboboxSector,
    RgInput,
    RgInputDate,
    RgSuggestSmartPerson,
    ComboboxPriority,
    RgDropdownPrinter,
    RgPersonDropdown,
    RgSuggestUser,
    ModalPatientImageInRow,
    RgRadioCustom,
    RgComboboxPlace,
    RgSuggestCboSector,
    RgSuggestEmployee,
    RgComboboxProceduresFilter,
    ModalInformationPrint,
  },

  props: {
    isAppointment: Boolean,
    printHtml: Object,
  },

  data() {
    return {
      mutableList: [],
      totalList: null,
      form: this.$utils.obj.DeepCopy(FORM_BASE),
      selectedRow: null,
      activeRow: null,
      tableHeight: null,
      modalPatientImageInRow: false,
      showModalInformationPrint: false,
    };
  },

  computed: {
    actionColorLine() {
      return {
        get: (item) => {
          return item.pfi_cor;
        },
      };
    },
    permissionScreen() {
      const permission = this.isAppointment
        ? "consulta.buscaPacientesAgendadosFilaConsulta.Exibir"
        : "exame2.buscaPacientesAgendadosFilaExames.exibir";
      return permission;
    },

    submoduleId() {
      return this.isAppointment
        ? this.$store.state.Login.route_module_map.appointment
        : this.$store.state.Login.route_module_map.exam;
    },

    hasMutableList() {
      if (!this.mutableList) {
        return false;
      }
      return this.mutableList.length > 0;
    },

    getSpaceTable() {
      return this.tableHeight;
    },

    permissionSuggest() {
      return this.isAppointment ? ["appointment"] : ["exame2"];
    },
  },

  watch: {
    "form.unitDestiny"(pValue, pPrev) {
      if (!pValue || (pValue && pPrev && pValue !== pPrev)) {
        this.form.sectorDestiny = 0;
        this.form.placeDestiny = 0;
      }
    },

    "form.sectorDestiny"(pValue) {
      if (!pValue || !isNaN(pValue)) {
        this.form.placeDestiny = 0;
        this.form.ocp_nome = "";
        this.form.ocp_codigo = "";
        this.form.ocp_id = 0;
      }
    },

    "form.ocp_nome"() {
      this.form.pes_nome = "";
      this.form.vin_id = 0;
      this.form.fun_id = 0;
    },

    "form.pac_id"() {
      this.form.patientName = "";
    },

    "form.patientName"() {
      this.form.pac_id = 0;
      this.form.specificPatientName = "";
    },
  },

  created() {
    this.RADIO_DATA = [
      { title: "Impresso", blue: true, id: 1 },
      { title: "Não Impresso", blue: true, id: 0 },
    ];

    this.COLUMN_APPOINTMENT = [
      { name: "Paciente", key: "nomePaciente" },
      { name: "Data de Agendamento", key: "data_agendada" },
      { name: "Unidade de Saúde Destino", key: "unidadeAgendamento" },
      { name: "Setor Destino", key: "set_nome" },
      { name: "Local de Atendimento Destino", key: "lca_nome" },
      { name: "Ocupação", key: "ocp_nome" },
      { name: "Profissional", key: "nomeFuncionarioAgendamento" },
      { name: "Usuário Destino", key: "usuarioAgendamento" },
      { name: "Data de Entrada", key: "fil_data" },
      { name: "Unidade de Saúde Origem", key: "uns_solicitante" },
      { name: "Prioridade", key: "pfi_nome", statusColor: true },
      { name: "Usuário Origem", key: "usuarioFila" },
      { name: "Unidade de Saúde Solicitante", key: "unidadeFila" },
      { name: "Profissional Solicitante", key: "nomeFuncionarioSolicitante" },
      { name: "Impresso", key: "comprovanteImpresso" },
    ];

    this.COLUMN_EXAM = [
      { name: "Paciente", key: "nomePaciente" },
      { name: "Data de Agendamento", key: "data_agendada" },
      { name: "Unidade de Saúde Destino", key: "unidadeAgendamento" },
      { name: "Setor Destino", key: "set_nome" },
      { name: "Local de Atendimento Destino", key: "lca_nome" },
      {
        name: "Procedimento",
        key: "stp_novo_nome_procedimento",
        align: "left",
      },
      { name: "Profissional", key: "nomeFuncionarioAgendamento" },
      { name: "Usuário Destino", key: "usuarioAgendamento" },
      { name: "Data de Entrada", key: "fil_data" },
      { name: "Unidade de Saúde Origem", key: "uns_solicitante" },
      { name: "Prioridade", key: "pfi_nome", statusColor: true },
      { name: "Usuário Origem", key: "usuarioFila" },
      { name: "Unidade de Saúde Solicitante", key: "unidadeFila" },
      { name: "Profissional Solicitante", key: "nomeFuncionarioSolicitante" },
      { name: "Impresso", key: "comprovanteImpresso" },
    ];
  },

  beforeDestroy() {
    const pathName = this.isAppointment ? "appointment" : "exam";
    const validateIfExist = ValidateIfRouteExistInBreadscrumb(
      `/${pathName}/schedule/patient-schedule`,
      this.$route.meta.breadcrumb,
    );

    if (!validateIfExist) {
      const moduleName = this.isAppointment ? "Appointment" : "Exam";
      this.$store.commit(
        `${moduleName}/Schedule/RESET_FILTER_SCHEDULE_PATIENT_LIST`,
      );
    }
  },

  mounted() {
    this.fillFilter();
    this.handleAfterSearchFilter();
  },

  methods: {
    async fillFilter() {
      try {
        const moduleName = this.isAppointment ? "Appointment" : "Exam";

        const existFilterData = this.$store.getters[
          `${moduleName}/Schedule/GET_FILTER_SCHEDULE_PATIENT_LIST`
        ];

        if (existFilterData) {
          this.$loader.start("Carregando dados da última busca...");

          this.form = {
            ...this.form,
            ...existFilterData,
          };

          this.form.unitDestiny = existFilterData.unitDestiny;
          this.form.sectorDestiny = existFilterData.sectorDestiny;
          this.$nextTick(() => {
            this.form.placeDestiny = existFilterData.placeDestiny;
          });

          if (this.isAppointment) {
            if (this.$refs.Cbo) {
              this.$refs.Cbo.forceSelection({
                ocp_nome: existFilterData.ocp_nome,
                ocp_codigo: existFilterData.ocp_codigo,
                ocp_id: existFilterData.ocp_id,
              });
            }
          }

          if (!this.isAppointment) {
            this.form.stp_id = existFilterData.procedure;
          }

          if (this.$refs.employee) {
            this.$refs.employee.forceSelection({
              pes_nome: existFilterData.pes_nome,
              vin_id: existFilterData.vin_id,
              employeeRelationship: {
                employee: {
                  fun_id: existFilterData.fun_id,
                },
              },
            });
          }

          if (this.$refs.employeeRequest) {
            this.$refs.employeeRequest.forceSelection({
              pes_nome: existFilterData.pes_nome_solicitante,
              vin_id: existFilterData.vin_id_solicitante,
              employeeRelationship: {
                employee: {
                  fun_id: existFilterData.fun_id_solicitante,
                },
              },
            });
          }

          if (this.$refs.userDestiny) {
            this.$refs.userDestiny.forceSelection({
              usu_nome: existFilterData.userDestiny,
              usu_id: existFilterData.usu_id_destiny,
            });
          }

          if (this.$refs.userOrigin) {
            this.$refs.userOrigin.forceSelection({
              usu_nome: existFilterData.userOrigin,
              usu_id: existFilterData.usu_id_origin,
            });
          }

          if (this.$refs.patient && existFilterData.pes_id) {
            this.$refs.patient.fillPatientById(existFilterData.pes_id);
          }

          if (this.$refs.SmartTable) {
            this.$refs.SmartTable.cleanSelectRow();
          }
          await this.$refs.RgSearch.performSearch();
          this.$loader.finish();
        }
      } catch (Err) {
        this.$toaster.warning("Erro ao carregar os dados da navegação", Err);
        this.$loader.finish();
      }
    },

    selectingPatient(pValue) {
      if (pValue && pValue.source && pValue.source.patient) {
        const { pes_id } = pValue.source;
        const { pac_id } = pValue.source.patient;

        this.form.pac_id = pac_id;
        this.form.pes_id = pes_id;
      } else {
        this.form.pes_id = 0;
        this.form.pac_id = 0;
        this.form.specificPatientName = "";
      }
    },

    onInputRadioCustom(item) {
      if (item) {
        this.form.withPrint = item.id;
      } else {
        this.form.withPrint = 2;
      }
    },

    selectingUser(pValue) {
      if (pValue && pValue.source) {
        const { usu_id, usu_nome } = pValue.source;
        this.form.usu_id_destiny = usu_id;
        this.form.userDestiny = usu_nome;
      } else {
        this.form.usu_id_destiny = 0;
        this.form.userDestiny = "";
      }
    },

    selectingUserOrigin(pValue) {
      if (pValue && pValue.source) {
        const { usu_id, usu_nome } = pValue.source;
        this.form.usu_id_origin = usu_id;
        this.form.userOrigin = usu_nome;
      } else {
        this.form.usu_id_origin = 0;
        this.form.userOrigin = "";
      }
    },

    selectingEmployee(pValue) {
      if (Object.keys(pValue.source).length > 0) {
        const source = pValue.source;
        const { pes_nome, vin_id } = source;
        const existEmployeeData =
          source.employeeRelationship && source.employeeRelationship.employee;

        this.form.fun_id =
          !!existEmployeeData && existEmployeeData.fun_id
            ? existEmployeeData.fun_id
            : 0;
        this.form.pes_nome = pes_nome;
        this.form.vin_id = vin_id;
      } else {
        this.form.fun_id = 0;
        this.form.vin_id = 0;
        this.form.pes_nome = "";
      }
    },

    selectingRequestEmployee(pValue) {
      if (Object.keys(pValue.source).length > 0) {
        const source = pValue.source;
        const { pes_nome, vin_id } = source;
        const existEmployeeData =
          source.employeeRelationship && source.employeeRelationship.employee;

        this.form.fun_id_solicitante =
          !!existEmployeeData && existEmployeeData.fun_id
            ? existEmployeeData.fun_id
            : 0;
        this.form.pes_nome_solicitante = pes_nome;
        this.form.vin_id_solicitante = vin_id;
      } else {
        this.form.fun_id_solicitante = 0;
        this.form.vin_id_solicitante = 0;
        this.form.pes_nome_solicitante = "";
      }
    },

    selectedCbo(pValue) {
      const hasSuggestion = Object.keys(pValue.source).length > 0;
      try {
        if (hasSuggestion) {
          this.$loader.start();
          this.form.ocp_id = pValue.source.ocp_id;
          this.form.ocp_nome = pValue.source.ocp_nome;
          this.form.ocp_codigo = pValue.source.ocp_codigo;
        } else {
          this.form.ocp_id = 0;
          this.form.ocp_nome = "";
          this.form.ocp_codigo = "";
        }
      } finally {
        this.$loader.finish();
      }
    },

    // ACTIONS GRID
    async selectRowData(pItem, pIndex) {
      this.selectedRow = pItem;
      this.activeRow = pIndex;
    },

    validateColor(pItem) {
      return pItem && pItem.comprovanteImpresso === "NÃO"
        ? "#CCCCCC"
        : "#1E88A9";
    },

    itensActionOptionsPrint(row) {
      const list = [
        {
          label: "Lista de Pacientes Agendados",
          action: () => this.printPatientsList(),
        },
        {
          label: "Comprovante de Agendamento",
          disable: !this.selectedRow,
          action: () => {
            if (!this.selectedRow) {
              this.$toaster.warning("Por favor, selecione um paciente.");
              return;
            }
            this.printScheduleVoucher();
          },
        },
      ];

      if (this.isAppointment) {
        list.push({
          label: "Ficha de Autorização de Consulta",
          disable: !this.selectedRow,
          action: () => {
            if (!this.selectedRow) {
              this.$toaster.warning("Por favor, selecione um paciente.");
              return;
            }
            this.printConsultationAuthorization();
          },
        });
      }

      return list;
    },

    itensActionOptionsPerson(row) {
      return [
        {
          label: "Observações do Paciente na Fila",
          action: () => this.patientObservation(),
        },
        {
          label: "Arquivos do Paciente na Fila",
          action: () => {
            this.modalPatientImageInRow = true;
          },
        },
      ];
    },

    closeModalInformationPrint() {
      this.showModalInformationPrint = false;
    },

    // ACTIONS DROPDOWN
    async printPatientsList() {
      if (this.totalList > 1000) {
        this.showModalInformationPrint = true;
        return;
      }

      const payload = {
        arrFormData: {
          ...this.getPayloadFilter(this.form),
          fil_comprovante_impresso: 2,
          cli_id: this.$store.getters["Login/GET_USER_ID_CLIENT"],
        },
        modulo: this.isAppointment ? 2 : 33,
      };

      this.$loader.start("Preparando impressão...");
      const html = await this.printHtml.patientsList(payload, this.selectedRow);
      this.$utils.print.printHtml(html);
      this.$loader.finish();
    },

    async printScheduleVoucher() {
      let payload = {};
      if (this.isAppointment) {
        payload = {
          intIdConsulta: this.selectedRow.con_id,
        };
      } else {
        payload = { ids: [this.selectedRow.eap_id] };
      }

      try {
        this.$loader.start("Preparando impressão...");
        const html = await this.printHtml.scheduleVoucher(
          payload,
          this.selectedRow,
        );
        this.$utils.print.printHtml(html);
        this.$loader.finish();
        this.$loader.start("Carregando listagem...");
        await this.$refs.RgSearch.performSearch();
        this.$loader.finish();
      } catch (err) {
        this.$toaster.warning(err);
      } finally {
        this.$loader.finish();
      }
    },

    async printConsultationAuthorization() {
      const payload = {
        intIdConsulta: this.selectedRow.con_id,
      };

      this.$loader.start("Preparando impressão...");
      const html = await this.printHtml.consultationAuthorization(
        payload,
        this.selectedRow,
      );
      this.$utils.print.printHtml(html);
      this.$loader.finish();
    },

    patientObservation() {
      if (this.selectedRow.fil_id) {
        const patientObservationRouterName = this.isAppointment
          ? "appointment.schedule.patient-schedule.patient-observation-queue"
          : "exam.schedule.patient-schedule.patient-observation-queue";

        const appointmentsData = {
          ...this.selectedRow,
          pes_nome: this.selectedRow.nomePaciente,
        };

        const patientData = {
          ...this.selectedRow,
          pes_nome: this.selectedRow.nomePaciente,
        };

        this.$router.push({
          name: patientObservationRouterName,
          params: this.isAppointment ? { appointmentsData } : { patientData },
        });
      } else {
        this.$toaster.warning(
          "Este paciente não foi cadastrado pela fila de regulação.",
        );
      }
    },

    // ACTIONS FILTER
    closeModalPatientImageInRow() {
      this.modalPatientImageInRow = false;
    },

    actionAfterSearchFilter() {
      this.handleAfterSearchFilter();
    },

    handleAfterSearchFilter() {
      // GET DYNAMIC HEIGHST
      if (this.$refs.RgSearch?.$refs.listContent) {
        const searchArea = this.$refs.RgSearch.$refs.listContent.offsetHeight;
        this.tableHeight = searchArea - 40;

        if (this.$refs.smartTable) this.$refs.smartTable.cleanSelectRow();
        this.selectedLine = null;
        this.selectedRow = null;
      }
    },

    generateFilter() {
      const variables = {
        arrFormData: {
          ...this.getPayloadFilter(this.form),
        },
      };

      const moduleName = this.isAppointment ? "Appointment" : "Exam";

      this.$store.commit(
        `${moduleName}/Schedule/SET_FILTER_SCHEDULE_PATIENT_LIST`,
        this.$utils.obj.DeepCopy(this.form),
      );

      return variables;
    },

    actionSearchFilter(pData) {
      try {
        this.$loader.start();

        const path = this.isAppointment
          ? "Appointment/Schedule/SCHEDULE_PATIENT_APPOINTMENT"
          : "Exam/Schedule/SCHEDULE_PATIENT_EXAM";

        return this.$store.dispatch(path, pData);
      } catch (pErr) {
        this.$toaster.error(pErr);
      } finally {
        this.$loader.finish();
      }
    },

    getCountValue(pValue) {
      this.totalList = Number(pValue) || 0;
    },

    actionCleanForm() {
      this.mutableList = [];
      this.selectedRow = null;
      this.activeRow = null;
      this.form = this.$utils.obj.DeepCopy(FORM_BASE);

      if (this.$refs.smartTable) {
        this.$refs.smartTable.cleanSelectRow();
      }

      const moduleName = this.isAppointment ? "Appointment" : "Exam";
      this.$store.commit(
        `${moduleName}/Schedule/RESET_FILTER_SCHEDULE_PATIENT_LIST`,
      );

      this.$refs.RgRadioCustom.forceUpdate();
    },

    // OUTROS
    getPayloadFilter(filter) {
      return {
        pfi_id: this.form.priority,
        set_id: this.form.sectorDestiny,
        lca_id: this.form.placeDestiny,
        ocp_id: this.form.ocp_id,
        stp_id: this.form.stp_id,
        pac_id: this.form.pac_id,
        usu_id_agendamento: this.form.usu_id_destiny,
        pes_nome: this.form.patientName,

        periodoFinal: this.form.scheduleDateEnd,
        periodoInicial: this.form.scheduleDateStart,
        periodoFilaFinal: this.form.insertDateEnd,
        periodoFilaInicial: this.form.insertDateStart,

        fun_id_solicitante: this.form.fun_id_solicitante,
        fun_id: this.form.fun_id,

        uns_id: this.form.unitOrigem,
        uns_id_destino: this.form.unitDestiny,
        uns_solicitante: this.form.unitRequesting,

        fil_id_agendado_por: this.form.usu_id_origin,
        fil_comprovante_impresso: this.form.withPrint,
        smd_id: this.isAppointment ? 160 : 164,
      };
    },

    verifyScheduleDate(pValue, pErrors) {
      const initialDate = moment(this.form.scheduleDateStart, "DD/MM/YYYY");
      const finalDate = moment(this.form.scheduleDateEnd, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }
    },

    verifyRegulationDate(pValue, pErrors) {
      const initialDate = moment(this.form.insertDateStart, "DD/MM/YYYY");
      const finalDate = moment(this.form.insertDateEnd, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }
    },
  },
};
</script>
