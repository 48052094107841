<template>
  <div class="foundation-search-excludes-schedules">
    <RgSearch
      ref="RgSearch"
      v-model="mutableList"
      :build-filter="generateFilter"
      :clearFunction="actionCleanForm"
      :max-register="40"
      :item-height="29.5"
      :search-function="actionSearchFilter"
      resultTitle="Lista de Pacientes Excluídos pela Fila"
      @afterPerformSearch="actionAfterSearchFilter"
      @count="getCountValue"
    >
      <div slot="filters" class="foundation-search-excludes-schedules-filter">
        <div class="grid">
          <div class="filter-title">
            <span class="title">Dados da Regulação (Origem)</span>
          </div>

          <div class="selectinput">
            <RgComboboxUnithealth
              v-model="form.unitOrigem"
              :permission="permissionScreen"
              label="Unidade de Saúde"
              class="inputitem"
              default-text="Todas"
            />
          </div>

          <div class="selectinput">
            <RgSuggestUser
              ref="user"
              v-model="form.userName"
              label="Usuário"
              clearFunction
              placeholder="Digite o nome de usuário"
              @selected="selectingUser"
            />
          </div>

          <div class="selectinput">
            <div class="period">
              <RgInputDate
                v-model="form.initialDate"
                placeholder="dd/mm/aaaa"
                label="Data Inicial"
                class="date"
                :rules="{ fn: validateDate }"
              />

              <RgInputDate
                v-model="form.finalDate"
                placeholder="dd/mm/aaaa"
                label="Data Final"
                class="date"
                :rules="{ fn: validateDate }"
              />
            </div>
          </div>

          <div class="filter-title">
            <span class="title">Dados da Solicitação </span>
          </div>

          <div class="selectinput">
            <RgComboboxUnithealth
              v-model="form.unitSolicitate"
              :permission="permissionScreen"
              label="Unidade de Saúde"
              class="inputitem"
              default-text="Todas"
            />
          </div>

          <div class="selectinput">
            <RgSuggestEmployee
              ref="employee"
              v-model="form.requestingProfessional"
              class="inputitem"
              label="Profissional Solicitante"
              placeholder="Digite o profissional solicitante"
              @selected="selectingProfessional"
            />
          </div>

          <div class="filter-title">
            <span class="title">Dados do Paciente </span>
          </div>

          <div class="selectinput">
            <RgInput
              v-model="form.allPatientName"
              class="inputitem"
              placeholder="Digite o nome do paciente"
              label="Paciente"
            />
          </div>

          <div class="selectinput">
            <RgSuggestSmartPerson
              ref="person"
              v-model="form.specificPatientName"
              :with-patient-module="permissionSuggest"
              :enabled-patient="false"
              :rules="{ forceSelection: true }"
              filterOnlyDocument
              @selected="selectingPatient"
            />
          </div>
        </div>
      </div>

      <div class="foundation-search-excludes-schedules-search">
        <SmartTable
          ref="SmartTable"
          :name="
            isAppointment
              ? 'AppointmentFoundationSearchExclusions'
              : 'ExamFoundationSearchExclusions'
          "
          class="smart-table"
          :dynamic-height="getSpaceTable"
          :initial-columns="5"
          toggle-selected
          v-bind="propsSmartTable"
          @getLine="selectRowData"
        >
          <div slot="top-buttons" class="top-buttons">
            <!-- <RgLessButton
              :disabled="!selectedRow"
              :class="{ disable: !selectedRow }"
              @click="backPatientFromQueue"
            /> -->

            <RgPersonDropdown
              :item="selectedRow || {}"
              :disabled="!selectedRow"
              :class="{ disable: !selectedRow }"
              :action-options="itensActionOptionsPerson(selectedRow)"
              show-down
              show-left
            />
            <RgPrinterButton
              title="Lista de Pacientes Excluídos"
              @click="printExcludedPatient"
            />
          </div>
        </SmartTable>
      </div>
    </RgSearch>

    <ModalInformationPrint
      :show="modalInformationPrint"
      :list-size="totalList"
      @close="closeModalInformationPrint"
    />

    <ModalBackPatientFromQueue
      :show="modalBackPatient"
      @close="closeModalBackPatient"
    />

    <ModalPatientImageInRow
      :show="modalPatientImageInRow"
      :fill-id="filId"
      :modId="moduleId"
      hideButtons
      @close="closeModalPatientImageInRow"
    />
  </div>
</template>

<script>
import moment from "moment";
import { RgInputDate, RgInput } from "~tokio/primitive";
import RgSuggestSmartPerson from "~tokio/primitive/suggest/rg-suggest-smart-person/RgSuggestSmartPerson.vue";
import RgSuggestEmployee from "~tokio/primitive/suggest/rg-suggest-employee/RgSuggestEmployee.vue";
import RgSuggestUser from "~tokio/primitive/suggest/rg-suggest-user/RgSuggestUser.vue";
import {
  RgPrinterButton,
  // RgLessButton,
} from "~tokio/primitive/button";
import { RgPersonDropdown } from "~tokio/primitive/dropdown";
import { RgComboboxUnithealth } from "~tokio/primitive/combobox";
import RgSearch from "~tokio/foundation/rg-search/RgSearch";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";
import {
  ModalPatientImageInRow,
  ModalInformationPrint,
} from "~tokio/primitive/modal";
import { ModalBackPatientFromQueue } from "./components";

const COLUMN_TABLE = [
  { name: "Paciente", key: "nomePaciente" },
  { name: "Data de Exclusão", key: "flg_data" },
  { name: "Motivo da Exclusão", key: "flg_obs", align: "left" },
  { name: "Usuário Exclusão", key: "usuarioExclusao" },
  { name: "Unidade de Saúde Origem", key: "unidadeFila" },
  { name: "Usuário Origem", key: "usuarioFila" },
  { name: "Unidade de Saúde Solicitante", key: "uns_solicitante" },
  { name: "Profissional Solicitante", key: "nomeFuncionarioSolicitante" },
];

const INITIAL_COLUMNS = 8;

const FORM_BASE = {
  unitOrigem: null,
  userName: "",
  usu_id: null,
  initialDate: "",
  finalDate: "",
  unitSolicitate: null,
  requestingProfessional: "",
  allPatientName: "",
  specificPatientName: "",
  pac_id: null,
  pes_id: null,
  fun_id: null,
  vin_id: null,
};

export default {
  name: "FoundationSearchExclusions",
  components: {
    RgSearch,
    SmartTable,
    RgComboboxUnithealth,
    RgSuggestEmployee,
    RgInputDate,
    RgSuggestSmartPerson,
    RgSuggestUser,
    RgInput,
    RgPrinterButton,
    RgPersonDropdown,
    ModalPatientImageInRow,
    ModalInformationPrint,
    // RgLessButton,
    ModalBackPatientFromQueue,
  },

  props: {
    isAppointment: Boolean,
    printHtml: Object,
  },

  data() {
    return {
      mutableList: [],
      tableHeight: null,
      totalList: 0,
      selectedRow: null,
      modalPatientImageInRow: false,
      modalBackPatient: false,
      modalInformationPrint: false,

      requestingProfessional: null,
      filId: null,
      form: this.$utils.obj.DeepCopy(FORM_BASE),
    };
  },
  computed: {
    getSpaceTable() {
      return this.tableHeight;
    },

    propsSmartTable() {
      const columns = COLUMN_TABLE;
      const body = this.mutableList;
      const total = Number(this.totalList);
      const initialColumns = INITIAL_COLUMNS;
      const indexColumn = this.flg_id;
      const showPagination = false;

      return {
        columns,
        body,
        total,
        initialColumns,
        indexColumn,
        showPagination,
      };
    },

    permissionScreen() {
      return this.isAppointment
        ? "consulta.pacientesExcluidosFilaConsulta"
        : "exame2.pacientesExcluidosFilaExame";
    },

    permissionSuggest() {
      return this.isAppointment ? ["appointment"] : ["exame2"];
    },

    subModuleId() {
      const pacientesExcluidosFilaExame = 245;
      const pacientesExcluidosFilaConsulta = 246;

      return this.isAppointment
        ? pacientesExcluidosFilaConsulta
        : pacientesExcluidosFilaExame;
    },

    moduleId() {
      return this.isAppointment
        ? this.$store.state.Login.route_module_map.appointment
        : this.$store.state.Login.route_module_map.exam;
    },
  },

  beforeDestroy() {
    const pathName = this.isAppointment ? "appointment" : "exam";
    const validateIfExist = ValidateIfRouteExistInBreadscrumb(
      `/${pathName}/schedule/patient-exclude`,
      this.$route.meta.breadcrumb,
    );

    if (!validateIfExist) {
      const moduleName = this.isAppointment ? "Appointment" : "Exam";
      this.$store.commit(`${moduleName}/Schedule/RESET_FILTER_PATIENT_EXCLUDE`);
    }
  },

  mounted() {
    this.fillFilter();
  },

  methods: {
    selectingProfessional(pProfessional) {
      const source =
        pProfessional &&
        pProfessional.source &&
        pProfessional.source.employeeRelationship;
      if (source) {
        const { pes_nome, vin_id } = pProfessional.source;
        const { fun_id } = pProfessional.source.employeeRelationship.employee;

        this.form.requestingProfessional = pes_nome;
        this.form.fun_id = fun_id;
        this.form.vin_id = vin_id;
      } else {
        this.form.requestingProfessional = "";
        this.form.fun_id = null;
      }
    },

    selectingPatient(pValue) {
      if (pValue && pValue.source && pValue.source.patient) {
        const { pes_id } = pValue.source;
        const { pac_id } = pValue.source.patient;

        this.form.pac_id = pac_id;
        this.form.pes_id = pes_id;
      } else {
        this.form.pac_id = null;
        this.form.pes_id = null;
        this.form.specificPatientName = "";
      }
    },

    selectingUser(pValue) {
      if (pValue && pValue.source) {
        const { usu_id, usu_nome } = pValue.source;
        this.form.usu_id = usu_id;
        this.form.userName = usu_nome;
      } else {
        this.form.usu_id = null;
        this.form.userName = "";
      }
    },

    closeModalInformationPrint() {
      this.modalInformationPrint = false;
    },

    closeModalBackPatient() {
      this.modalBackPatient = false;
    },

    async printExcludedPatient() {
      try {
        if (this.totalList > 1000) {
          this.modalInformationPrint = true;
          return;
        }

        const modules = this.isAppointment ? "Appointment" : "Exam";
        const path = `${modules}/Schedule/GET_PRINT_PATIENT_EXCLUDE`;

        const variables = {
          arrFormData: {
            uns_id: this.form.unitOrigem,
            smd_id: this.subModuleId,
            usu_id_agendamento: this.form.usu_id,
            periodoFilaInicial: this.form.initialDate,
            periodoFilaFinal: this.form.finalDate,
            uns_solicitante: this.form.unitSolicitate,
            fun_id_solicitante: this.form.requestingProfessional,
            pac_id: this.form.pac_id,
            pes_nome: this.form.allPatientName,
          },
          modulo: this.moduleId,
        };

        const data = await this.$store.dispatch(path, variables);

        const clientName = this.$store.getters["Login/GET_CLIENT_NAME"];
        const info = {
          cli_nome: clientName,
        };

        const html = await this.printHtml.patientsList(info, data);
        this.$utils.print.printHtml(html);
      } catch (error) {
        this.$toaster.error(error.message);
      }
    },

    async backPatientFromQueue() {
      this.modalBackPatient = true;
    },

    itensActionOptionsPerson(row) {
      return [
        {
          label: "Observações do Paciente na Fila",
          action: this.observationPatient,
        },
        {
          label: "Arquivos do Paciente na Fila",
          action: this.openModalPatientImageInRow,
        },
      ];
    },

    observationPatient() {
      if (this.selectedRow && this.selectedRow.fil_id) {
        const pathName = this.isAppointment
          ? "appointment.schedule.patient-exclude.patient-observation-queue"
          : "exam.schedule.patient-exclude.queue.patient-observation-queue";
        const params = this.isAppointment
          ? { appointmentsData: this.selectedRow }
          : { patientData: this.selectedRow };
        this.$router.push({
          name: pathName,
          params,
        });
      }
    },

    selectRowData(row) {
      this.selectedRow = row;
      this.filId = Number(this.selectedRow?.fil_id);
    },

    actionAfterSearchFilter(submitFromButton) {
      // GET DYNAMIC HEIGHT
      if (this.$refs.RgSearch.$refs.listContent) {
        this.tableHeight =
          this.$refs.RgSearch.$refs.listContent.offsetHeight - 35;
      }

      if (this.$refs.SmartTable) {
        this.$refs.SmartTable.cleanSelectRow();
      }

      this.selectedRow = null;
    },

    async fillFilter() {
      try {
        const moduleName = this.isAppointment ? "Appointment" : "Exam";

        const existFilterData = this.$store.getters[
          `${moduleName}/Schedule/GET_FILTER_PATIENT_EXCLUDE`
        ];

        if (existFilterData) {
          this.$loader.start("Carregando dados da ultima busca.");

          if (this.$refs.employee) {
            this.$refs.employee.forceSelection({
              pes_nome: existFilterData.requestingProfessional,
              vin_id: existFilterData.vin_id,
              fun_id: existFilterData.fun_id,
            });
          }

          if (this.$refs.user) {
            this.$refs.user.forceSelection({
              usu_nome: existFilterData.userName,
              usu_id: existFilterData.usu_id,
            });
          }

          if (this.$refs.person && existFilterData.pes_id) {
            this.$refs.person.fillPatientById(existFilterData.pes_id);
          }

          this.form = existFilterData;

          if (this.$refs.SmartTable) {
            this.$refs.SmartTable.cleanSelectRow();
          }
          await this.$refs.RgSearch.performSearch();
          this.$loader.finish();
        }
      } catch (Err) {
        this.$toaster.warning("Erro ao carregar os dados da navegação", Err);
        this.$loader.finish();
      }
    },

    async researchTables() {
      try {
        await this.$refs.RgSearch.performSearch();

        if (this.selectedRow) {
          if (this.$refs.SmartTable) {
            this.$refs.SmartTable.selectRow(this.selectedRow);
            await this.selectRowData(this.selectedRow, true);
          }
        }
      } catch (pError) {
        this.$toaster.error(pError);
      }
    },

    generateFilter() {
      const variables = {
        arrFormData: {
          fil_comprovante_impresso: null,
          fil_id_agendado_por: this.form.usu_id,
          fun_id: 0,
          fun_id_solicitante: this.form.fun_id,
          lca_id: 0,
          ocp_id: 0,
          pac_id: this.form.pac_id,
          periodoFilaInicial: this.form.initialDate,
          periodoFilaFinal: this.form.finalDate,
          periodoFinal: "",
          periodoInicial: "",
          pfi_id: 0,
          set_id: 0,
          smd_id: this.subModuleId,
          uns_id: this.form.unitOrigem,
          uns_id_destino: 0,
          uns_solicitante: this.form.unitSolicitate,
          usu_id_agendamento: 0,
          pes_nome: this.form.allPatientName,
        },
      };

      const moduleName = this.isAppointment ? "Appointment" : "Exam";

      this.$store.commit(
        `${moduleName}/Schedule/SET_FILTER_PATIENT_EXCLUDE`,
        this.form,
      );

      return variables;
    },

    actionSearchFilter(pData) {
      try {
        this.$loader.start("Buscando...");
        const modules = this.isAppointment ? "Appointment" : "Exam";
        const path = `${modules}/Schedule/SEARCH_PATIENT_EXCLUDE_IN_QUEUE`;

        return this.$store.dispatch(path, pData);
      } catch (e) {
        this.$toaster.warning(e.message);
      } finally {
        this.$loader.finish();
      }
    },

    getCountValue(pValue) {
      this.totalList = Number(pValue) || 0;
    },

    openModalPatientImageInRow() {
      this.modalPatientImageInRow = true;
    },

    closeModalPatientImageInRow() {
      this.modalPatientImageInRow = false;
    },

    actionCleanForm() {
      this.mutableList = [];
      this.form = this.$utils.obj.DeepCopy(FORM_BASE);
      if (this.$refs.SmartTable) {
        this.$refs.SmartTable.cleanSelectRow();
      }
      this.selectedRow = null;
      this.filId = null;
      const moduleName = this.isAppointment ? "Appointment" : "Exam";
      this.$store.commit(`${moduleName}/Schedule/RESET_FILTER_PATIENT_EXCLUDE`);
    },

    validateDate(value, errors) {
      if (this.form.initialDate && this.form.finalDate) {
        const initial = moment(this.form.initialDate, "DD/MM/YYYY");
        const final = moment(this.form.finalDate, "DD/MM/YYYY");

        const isValidInitialValidity = moment(initial).isSameOrBefore(final);
        if (!isValidInitialValidity) {
          errors.push("A data inicial não pode ser maior que a data final.");
          return false;
        }
      }
      return true;
    },
  },
};
</script>
