import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import EmployeeDetail from "@/app/employee/view/employee-detail/EmployeeDetail";

export default {
  path: "/register/employee/detail/:employeeId",
  name: "register.employee.editDetail",
  components: {
    default: EmployeeDetail,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Funcionario", link: "/register/employee/search" },
      { label: "Editar Funcionário" },
    ],
  },
};
