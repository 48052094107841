import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query KidneyDisease {
    SearchKidneyDisease {
      rows {
        dre_id
        dre_codigo_ab
        dre_descricao_doenca_renal
      }
    }
  }
`;

export default async ({ commit, state }) => {
  if (state.kidneyDisease.length > 0) {
    return;
  }
  try {
    const { data } = await GraphQLClient.query({
      query,
      fetchPolicy: "no-cache",
    });
    commit("SET_KIDNEY_DISEASE", data.SearchKidneyDisease.rows);
  } catch (err) {
    console.log(err);
    return err.graphQLErrors;
  }
};
