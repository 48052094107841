import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query CheckModalityProcedure(
    $procedure: String!
    $competence: String!
    $modality: String!
  ) {
    checkModalityProcedure(
      CO_PROCEDIMENTO: $procedure
      DT_COMPETENCIA: $competence
      CO_MODALIDADE: $modality
    )
  }
`;

export default async ({ procedure, competence, modality }) => {
  if (!procedure || !competence || !modality) return true;

  const variables = {
    procedure,
    competence,
    modality: ("" + modality).padStart(2, "0"),
  };

  const { data } = await GraphQLClient.query({ query, variables }).catch(
    (err) => {
      console.error(err);
    },
  );

  return data.checkModalityProcedure;
};
