<template>
  <div class="quick-appointment-schedule-form-per-day">
    <form class="form-container" @submit.stop.prevent="saveForm">
      <RgValidatorForm ref="validator">
        <FormBase title="Paciente" class="module-patient">
          <div class="content">
            <RgSuggestPatientInformation
              ref="patient"
              v-model="suggestPatient"
              :with-patient-module="['appointment']"
              :enabled-patient="false"
              :rules="{ forceSelection: true, required: true }"
              :disabledOnlySuggest="disabledPatientForm || isEditing"
              :extra-data="['withoutAddress']"
              class="inputpatient"
              @patientInfo="getPatientData"
            />
          </div>
        </FormBase>

        <FormBase
          title="Profissional"
          class="module-employee"
          :class="{ 'disable unselect': disableOrEnableModule }"
        >
          <div class="grid row-1">
            <RgInput
              :value="employeeData.pes_nome || employeeData.fun_nome"
              :class="{ disable: true }"
              :disabled="true"
              label="Nome do Profissional"
              class="disabled-field"
            />

            <RgInput
              :value="employeeData.uns_nome"
              :disabled="true"
              :class="{ disable: true }"
              label="Unidade de Saúde"
              class="disabled-field"
            />

            <RgInput
              :value="employeeData.set_nome"
              :disabled="true"
              :class="{ disable: true }"
              label="Setor"
              class="disabled-field"
            />

            <RgInput
              v-if="!typeSchedule"
              :value="employeeData.data_consulta || employeeData.DataConsulta"
              :disabled="true"
              :class="{ disable: true }"
              label="Data Atendimento"
              class="disabled-field"
            />
          </div>

          <div class="grid row-2">
            <RgComboboxPlaces
              ref="place"
              v-model="form.con_id_locais_atendimento"
              :class="{ 'disable unselect': disableOrEnableModule }"
              :disabled="disableOrEnableModule"
              :sector-id="employeeData.set_id"
              :rules="{ required: true }"
              label="Local de Atendimento"
            />

            <RgComboboxHealthPlansWithObjective
              ref="healthPlans"
              v-model="form.con_id_planos_saude"
              :class="{ 'disable unselect': disableOrEnableModule }"
              :disabled="disableOrEnableModule"
              :module-id="subModuleId"
              :rules="{ required: true }"
              label="Convênio"
            />

            <RgComboboxAppointmentObjective
              ref="objective"
              v-model="form.con_id_tipos_consultas_unidade"
              :disabled="!form.con_id_planos_saude"
              :class="{ disable: !form.con_id_planos_saude }"
              :health-unit-id="employeeData.uns_id"
              :health-plans-id="form.con_id_planos_saude"
              :active="1"
              :rules="{ required: true }"
              label="Objetivo de Consulta"
            />

            <RgInputHour
              v-if="!blockedAppointmentSchedulingTime && !typeSchedule"
              ref="hour"
              v-model="form.con_hora"
              :class="{ 'disable unselect': disableOrEnableModule }"
              :disabled="disableOrEnableModule"
              :rules="{
                required: !blockedAppointmentSchedulingTime && !typeSchedule,
              }"
              placeholder="10:00"
              label="Hora *"
            />

            <RgComboboxHoursEmployerSchedule
              v-if="blockedAppointmentSchedulingTime && !typeSchedule"
              ref="comboHour"
              v-model="form.con_hora"
              :schedule-scale-id="employeeData.eag_id"
              :schedule-history-id="employeeData.hag_id"
              :initial-hour="employeeData.eag_inicio_atendimentos"
              :class="{ 'disable unselect': disableOrEnableModule }"
              :disabled="disableOrEnableModule"
              :appointment-date="
                employeeData.data_consulta || employeeData.DataConsulta
              "
              :alter-hour="form.hourAppointmentAlter"
              :rules="{
                required: blockedAppointmentSchedulingTime && !typeSchedule,
              }"
              label="Horário"
              noLoader
              @finishLoader="onFinishLoaderRgComboboxHoursEmployerSchedule"
            />
          </div>
        </FormBase>

        <div v-if="typeSchedule" class="module-multiple">
          <FormBase
            title="Agendamento Múltiplos"
            class="area-1"
            :class="{ 'disable unselect': disableOrEnableModule }"
          >
            <div class="grid row-3">
              <div class="row-3-1">
                <RgInputDate
                  ref="dateInput"
                  v-model="form.date"
                  :dates-available="datesEnable"
                  :class="{ 'disable unselect': disableOrEnableModule }"
                  :disabled="disableOrEnableModule"
                  label="Data"
                  placeholder="DD/MM/AAAA"
                />

                <RgComboboxHoursEmployerSchedule
                  v-if="blockedAppointmentSchedulingTime && typeSchedule"
                  ref="comboHourMultiple"
                  v-model="form.con_hora"
                  :disabled="!dateIsValid"
                  :schedule-scale-id="employeeData.eag_id"
                  :schedule-history-id="employeeData.hag_id"
                  :initial-hour="employeeData.eag_inicio_atendimentos"
                  :appointment-date="getDate"
                  :alter-hour="form.hourAppointmentAlter"
                  :rules="{ required: false }"
                  label="Horário"
                  noLoader
                  @finishLoader="onFinishLoaderRgComboboxHoursEmployerSchedule"
                />

                <RgNewButton
                  small
                  data-id="adicionar-procedimento"
                  title="Adicionar"
                  class="button-add unselect"
                  type="button"
                  @click="addDateHour"
                />
              </div>
            </div>
          </FormBase>

          <div
            class="list"
            :class="{ 'disable unselect': disableOrEnableModule }"
          >
            <div class="title">
              <p class="text">Data</p>
              <p class="text">Hora</p>
              <div
                title="Apagar todos"
                class="location-icon"
                @click="removeAll"
              >
                <IconGarbage class="iconremove" />
              </div>
            </div>

            <div class="content medium-scroll">
              <div v-for="(item, idx) in dateHourList" :key="idx" class="item">
                <p class="text">{{ item.hag_data_consulta }}</p>
                <p class="text">{{ item.con_hora }}</p>
                <div
                  title="Apagar"
                  class="location-icon"
                  @click="removeOne(idx)"
                >
                  <IconGarbage class="iconremove" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <span v-if="typeSchedule" class="total-schedule">
          Total de agendamentos : {{ dateHourList.length }}
        </span>
      </RgValidatorForm>
    </form>

    <ModalConfirmSchedule
      ref="modalConfirmSchedule"
      :patientScheduleId="Number(patient.pcc_id)"
      :unitHealthId="Number(employeeData.uns_id)"
      :scheduleDate="employeeData.DataConsulta || employeeData.data_consulta"
      :show="showModalHistorySchedule"
      @save="actSaveFormByModalConfirmSchedule"
      @cancel="closeModalHistorySchedule"
      @close="closeModalHistorySchedule"
    />

    <ModalConfirmDefault
      :show="unavailableHour.show"
      :message="unavailableHour.message"
      title="Horário Indisponível"
      yes-label="SIM"
      no-label="NÃO"
      @getYes="changeToAvailableHourAndSave"
      @getOut="closeModalUnavailableHour"
    />
  </div>
</template>
<script>
import RgComboboxPlaces from "$appointment/common/component/rg-combobox-places/RgComboboxPlaces";
import RgComboboxAppointmentObjective from "$appointment/common/component/rg-combobox-appointment-objective/RgComboboxAppointmentObjective";
import RgComboboxHoursEmployerSchedule from "$appointment/common/component/rg-combobox-hours-employer-schedule/RgComboboxHoursEmployerSchedule";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb.js";
import ModalConfirmSchedule from "$appointment/submodules/schedule/component/modal/modal-confirm-schedule/ModalConfirmSchedule";

import moment from "moment";
import {
  ModalConfirmDefault,
  RgValidatorForm,
  RgInputHour,
  RgInput,
  RgSuggestPatientInformation,
  RgInputDate,
  RgNewButton,
  RgComboboxHealthPlansWithObjective,
} from "~tokio/primitive";
import FormBase from "~tokio/foundation/form-base/FormBase";
import { AlertError } from "~tokio/primitive/notification";
import { mapGetters } from "vuex";

export default {
  name: "QuickAppointmentScheduleForm",
  components: {
    ModalConfirmDefault,
    RgValidatorForm,
    RgInputHour,
    RgInput,
    RgComboboxPlaces,
    RgSuggestPatientInformation,
    RgNewButton,
    RgComboboxHealthPlansWithObjective,
    RgComboboxAppointmentObjective,
    FormBase,
    RgComboboxHoursEmployerSchedule,
    RgInputDate,
    ModalConfirmSchedule,
  },
  props: {
    disabledPatient: {
      default: false,
    },
    typeSchedule: {
      type: Boolean,
      default: false,
    },
    employeeData: {
      default: () => {
        return {};
      },
    },
    appointmentData: {
      default: () => {
        // #BALBI quando a funcionalidade estiver mais madura e definida, remover atributos que nao sao utilizados
        return {
          con_id: null,
          set_id: null,
          con_id_efetivacao_consulta: null,
          pac_id: null,
          tcu_id_unidades_saude: null,
          pcc_id: null,
          pes_id: null,
          pes_nome: null,
          con_hora: null,
          consulta: null,
          hag_data_consulta: null,
          con_id_historicos_agendamento: null,
          tcu_novo_tipo_consulta: null,
          efc_nome: null,
          uns_nome: null,
          con_numero: null,
          enviado: null,
          sme_nome: null,
          atendida: null,
          fil_id: null,
          con_id_planos_saude: null,
          con_id_agendado_por: null,
          con_id_tipos_consultas_unidade: null,
          con_id_pacientes_consultas: null,
          con_id_locais_atendimento: null,
          con_id_motivos_nao_efetivacao: null,
          con_id_filas_consultas: null,
          con_id_status_meu_esus: null,
          con_motivo_cancelamento_meu_esus: null,
          con_obs: null,
          blnAlertarPacienteConsultadoHoje: null,
        };
      },
    },
  },
  data() {
    return {
      unavailableHour: {
        show: false,
        message: "",
        nextAvailableHour: "",
      },
      suggestPatient: null,
      patientInfo: {},
      showModalHistorySchedule: false,
      dateIsValid: false,
      hourIsValid: false,
      disabledPatientForm: false,
      dateHourList: [],
      datesEnable: [],
      patient: {
        pac_id: null,
        pcc_id: null,
      },
      form: {
        con_id_locais_atendimento: "",
        con_id_planos_saude: "",
        con_id_tipos_consultas_unidade: "",
        con_hora: "",
        con_hora_unico: "",
        con_hora_multiplo: "",
        date: "",
        date_multiplo: "",
        hourAppointmentAlter: "",
      },
      periodsDate: [],
      finishLoader: {
        comboHour: false,
        edit: false,
      },
    };
  },

  computed: {
    ...mapGetters({
      lastPersonIdSaved: "Person/Patient/GET_LAST_PERSON_ID_SAVE",
      getFillForm: "Appointment/Schedule/GET_FORM_APPOINTMENT",
    }),

    subModuleId() {
      return 2;
    },

    disabledModalPatientHistory() {
      return !this.patient.pac_id;
    },

    isPerDay() {
      return !this.$route.name.includes("per-period");
    },

    isEditing() {
      return (
        (this.appointmentData != null && this.appointmentData.con_id > 0) ||
        this.$route.name === "appointment.schedule.per-periods.edit.form"
      );
    },

    blockPatientEdition() {
      return this.disabledPatient || this.isEditing;
    },

    preference() {
      return this.$store.getters["Login/GET_PREFERENCES"][
        "tViewMarcacaoConsulta.con_bloquear_horario_agendamento"
      ];
    },

    blockedAppointmentSchedulingTime() {
      return this.preference === "1";
    },

    preferenceSchedulingSameDay() {
      return this.$store.getters["Login/GET_PREFERENCES"][
        "tViewAgendamento.rbt_permitirAgendamentosMesmoDia"
      ];
    },

    schedulingSameDay() {
      return this.preferenceSchedulingSameDay === "1";
    },

    getDate() {
      return this.form.date ? this.form.date.replaceAll("-", "/") : "";
    },

    disableOrEnableModule() {
      return !(this.patient.pac_id && this.suggestPatient);
    },
  },

  watch: {
    finishLoader: {
      handler() {
        if (
          this.isEditing &&
          this.finishLoader.comboHour &&
          this.finishLoader.edit
        ) {
          this.$loader.finish();
        }
      },
      deep: true,
    },

    async "form.date"(pValue) {
      if (pValue && pValue.length === 10) {
        this.dateIsValid = true;

        if (this.periodsDate && this.periodsDate.length <= 0) {
          await this.setDaysPossible();
        }

        this.employeeData.hag_id = this.getAttributeDayByDate(pValue, "hag_id");
        this.employeeData.eag_id = this.getAttributeDayByDate(pValue, "eag_id");
        this.employeeData.eag_quantidade_marcadas = this.getAttributeDayByDate(
          this.form.date,
          "eag_quantidade_marcadas",
        );
        this.employeeData.eag_inicio_atendimentos = this.getAttributeDayByDate(
          this.form.date,
          "eag_inicio_atendimentos",
        );
        this.employeeData.eag_id_dias_semana = this.getAttributeDayByDate(
          this.form.date,
          "eag_id_dias_semana",
        );
        this.employeeData.eag_intervalo_consultas = this.getAttributeDayByDate(
          this.form.date,
          "eag_intervalo_consultas",
        );
        this.employeeData.eag_quantidade_consultas = this.getAttributeDayByDate(
          this.form.date,
          "eag_quantidade_consultas",
        );
      }
    },

    "form.con_hora"(pValue) {
      if (pValue && pValue.length === 5) {
        this.hourIsValid = true;
      }
      if (this.typeSchedule) {
        this.form.date_multiplo = this.form.date;
        this.form.con_hora_multiplo = this.form.con_hora;
      } else {
        this.form.con_hora_unico = this.form.con_hora;
      }
    },

    dateHourList(pValue) {
      pValue && pValue.length > 0 && this.patient.pac_id
        ? (this.disabledPatientForm = true)
        : (this.disabledPatientForm = false);
    },

    appointmentData() {
      this.loadDataForEditing();
    },

    typeSchedule(pValue) {
      if (!pValue) {
        const date =
          this.employeeData.data_consulta || this.employeeData.DataConsulta;
        this.employeeData.hag_id = this.getAttributeDayByDate(date, "hag_id");
        this.employeeData.eag_id = this.getAttributeDayByDate(date, "eag_id");

        this.form.con_hora = this.form.con_hora_unico || this.form.con_hora;
        this.form.date = date;
      } else {
        this.form.con_hora = this.form.con_hora_multiplo || null;
        this.form.date = this.form.date_multiplo || null;
      }
    },

    suggestPatient(pValue) {
      if (!pValue) {
        this.patientInfo = {};
      }
    },
  },

  created() {
    if (this.isEditing) {
      // #BALBI caso for carregar os demais dados do paciente, vamos precisar buscar no backend novo
      // pois no appointmentData nao temos tudo, por hora estou colocando apenas o nome e removendo os botoes de
      // histórico, novo paciente e impressao
      this.suggestPatient = this.appointmentData.pes_nome;
    }

    this.REASONS_BLOCKED_PATIENT = {
      1: "NÃO INFORMADO",
      2: "INABILITADO",
      3: "DESCARTADO",
      4: "ÓBITO",
      5: "INATIVIDADE",
      6: "PRONTUÁRIO DUPLICADO",
      7: "TRIAGEM",
      8: "MOTIVOS ADMINISTRATIVOS",
    };
  },

  mounted() {
    if (this.lastPersonIdSaved) {
      this.$refs.patient.fillPatientById(this.lastPersonIdSaved);
      this.fillForm();
    }

    const existThisRouteFromSchedulePerDay = ValidateIfRouteExistInBreadscrumb(
      "/appointment/schedule/form",
      this.$route.meta.breadcrumb,
    );

    this.loadDataForEditing();
    if (
      !existThisRouteFromSchedulePerDay &&
      this.periodsDate &&
      this.periodsDate.length <= 0
    ) {
      this.setDaysPossible();
    }
  },

  methods: {
    fillForm() {
      if (this.getFillForm) {
        this.$store.commit(
          "Appointment/Schedule/SELECT_EMPLOYEE_DATA",
          this.getFillForm.employeeData,
        );
        this.form = this.getFillForm.form;
        this.dateHourList = this.getFillForm.dateHourList;
        this.$store.commit("Appointment/Schedule/CLEAN_FORM_APPOINTMENT");
      }
    },

    onFinishLoaderRgComboboxHoursEmployerSchedule() {
      this.finishLoader.comboHour = true;
    },

    enableAdd() {
      if (!this.patient.pac_id) {
        return AlertError("Informe o nome do paciente.");
      }

      if (!this.form.con_id_locais_atendimento) {
        return AlertError("Informe o local de atendimento.");
      }

      if (!this.form.con_id_planos_saude) {
        return AlertError("Informe o convênio.");
      }

      if (!this.form.con_id_tipos_consultas_unidade) {
        return AlertError("Informe o objetivo da consulta.");
      }

      if (!this.hourIsValid && !this.dateIsValid) {
        return AlertError("Informe a data e o horário.");
      }

      return true;
    },
    async addDateHour() {
      const newValue = {
        con_id: this.appointmentData.con_id || 0,
        con_id_historicos_agendamento: this.getAttributeDayByDate(
          this.form.date,
          "hag_id",
        ),
        eag_id: this.getAttributeDayByDate(this.form.date, "eag_id"),
        eag_quantidade_marcadas: this.getAttributeDayByDate(
          this.form.date,
          "eag_quantidade_marcadas",
        ),
        hag_hora_inicio_consulta_funcionario: this.getAttributeDayByDate(
          this.form.date,
          "eag_inicio_atendimentos",
        ),
        hag_id: this.getAttributeDayByDate(this.form.date, "hag_id"),
        hag_id_dias_semana: this.getAttributeDayByDate(
          this.form.date,
          "eag_id_dias_semana",
        ),
        hag_id_escalas_agendamentos: this.getAttributeDayByDate(
          this.form.date,
          "eag_id",
        ),
        hag_intervalo_consultas: this.getAttributeDayByDate(
          this.form.date,
          "eag_intervalo_consultas",
        ),
        hag_quantidade_consultas: this.getAttributeDayByDate(
          this.form.date,
          "eag_quantidade_consultas",
        ),
        blnAlertarPacienteConsultadoHoje: true,
        con_hora: this.form.con_hora,
        con_id_locais_atendimento: this.form.con_id_locais_atendimento,
        con_id_pacientes_consultas: this.patient.pcc_id,
        con_id_planos_saude: this.form.con_id_planos_saude,
        con_id_tipos_consultas_unidade: this.form
          .con_id_tipos_consultas_unidade,
        hag_data_consulta: this.form.date.replace(/-/g, "/"),
        set_id: this.employeeData.set_id,
        tcu_id_unidades_saude: this.employeeData.uns_id,
        tipo_agendamento: "",
      };

      if (this.enableAdd()) {
        if (
          this.dateHourList.some(
            (item) => item.hag_data_consulta === newValue.hag_data_consulta,
          )
        ) {
          return AlertError("Já existe uma agendamento nessa data.");
        }
        this.dateHourList.push(newValue);
        this.form.date = "";
        this.dateIsValid = false;
        this.form.con_hora = "";
        this.form.con_hora_multiplo = "";
        this.hourIsValid = false;
      }
    },

    formatDataBR(pValue) {
      return pValue ? this.$utils.date.BrazilianDateFormat(pValue) : "";
    },

    removeAll() {
      this.dateHourList = [];
    },

    removeOne(pIndex) {
      this.dateHourList.splice(pIndex, 1);
    },

    async actSaveFormByModalConfirmSchedule() {
      const result = await this.saveForm(true);
      this.showModalHistorySchedule = false;
      this.$emit("saveFormByModalConfirmSchedule", result);
    },

    async saveForm(isSaveByModal = false) {
      const isInvalid = !(await this.isFormValid());

      if (isInvalid) {
        this.$toaster.warning("Verifique os campos.");
        return false;
      }

      const isAppointmentBatchHasTableEmpty =
        this.typeSchedule &&
        (this.dateHourList.length < 1 || !this.dateHourList);

      if (isAppointmentBatchHasTableEmpty) {
        this.$toaster.warning("Informe os Agendamentos Múltiplos.");
        return false;
      }

      const variables = this.formatData();
      if (isSaveByModal) {
        variables.blnAlertarPacienteConsultadoHoje = false;
      }

      let data = null;

      try {
        this.$loader.start();
        if (this.isEditing) {
          const permissions = {
            blnPermissaoAlterarNormal: this.$Permissions.sector.has(
              "consulta.agendamento.alterar",
              this.employeeData.set_id,
            ),

            blnPermissaoAlterarReservaTecnica: this.$Permissions.sector.has(
              "consulta.agendamento.alterarAgendamentoDiaReservaTecnica",
              this.employeeData.set_id,
            ),
          };

          await this.$store.dispatch(
            "Appointment/Schedule/EDIT_SCHEDULE_PATIENT",
            { variables, permissions },
          );

          this.$toaster.success("Agendamento alterado com sucesso!");

          return true;
        } else {
          const permissions = this.getPermissionsSchedule();

          if (this.typeSchedule) {
            data = await this.$store.dispatch(
              "Appointment/Schedule/SCHEDULE_PATIENT_IN_BATCH",
              { variables: this.dateHourList, permissions },
            );
          } else {
            data = await this.$store.dispatch(
              "Appointment/Schedule/SCHEDULE_PATIENT",
              { variables, permissions },
            );
          }

          this.$toaster.success("Agendamento realizado com sucesso!");

          if (data.status) {
            const appointmentId = data.dados;
            return appointmentId;
          }
        }
      } catch (pErr) {
        const isTimeUnavailableExecption =
          pErr.message === "HorarioIndisponivelException";

        const isAlreadyScheduleTodaySameEmployeeException =
          pErr.message === "PacienteConsultadoHojeAtendenteException";

        const isAlreadyScheduleTodayException =
          pErr.message === "PacienteConsultadoHojeException";

        if (isTimeUnavailableExecption) {
          if (pErr.esus_response && pErr.esus_response.trace) {
            this.unavailableHour.nextAvailableHour = pErr.esus_response.trace;
            this.unavailableHour.show = true;
            this.unavailableHour.message = `${variables.con_hora} não está disponível. Agendar para ${this.unavailableHour.nextAvailableHour}?`;
            return false;
          }
        }

        if (isAlreadyScheduleTodayException) {
          if (this.schedulingSameDay) this.showModalHistorySchedule = true;
          else
            this.$toaster.error(
              "Esse usuário não possui preferência para agendar pacientes no mesmo dia.",
              "Problemas ao salvar agendamento",
            );

          return false;
        }

        if (isAlreadyScheduleTodaySameEmployeeException) {
          let errorMessage = `Problemas ao salvar agendamento`;

          const appointmentDate =
            this.employeeData.data_consulta || this.employeeData.DataConsulta;

          errorMessage = isAlreadyScheduleTodaySameEmployeeException
            ? `Este paciente já possui consulta agendada no dia ${appointmentDate} para este profissional.`
            : `Este paciente já possui consulta agendada no dia ${appointmentDate}.`;

          this.$toaster.error(errorMessage);
        } else {
          this.$toaster.error(pErr.message);
          return false;
        }
      } finally {
        this.$loader.finish(1000);
      }
    },

    getPermissionsSchedule() {
      const today = moment({ h: 0, m: 0, s: 0 });

      const scheduleDate = moment(
        this.employeeData.data_consulta || this.employeeData.DataConsulta,
        "DD/MM/YYYY",
      );

      if (scheduleDate < today) {
        return {
          blnPermissaoNormal: this.$Permissions.sector.has(
            "consulta.agendamento.AgendamentoRetroativo",
            this.employeeData.set_id,
          ),
          blnPermissaoReserva: this.$Permissions.sector.has(
            "consulta.agendamento.agendamentoRetroativoReservaTecnica",
            this.employeeData.set_id,
          ),
        };
      }

      return {
        blnPermissaoNormal: this.$Permissions.sector.has(
          "consulta.agendamento.AgendamentoFuturo",
          this.employeeData.set_id,
        ),
        blnPermissaoReserva: this.$Permissions.sector.has(
          "consulta.agendamento.agendamentoFuturoReservaTecnica",
          this.employeeData.set_id,
        ),
      };
    },
    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    formatData() {
      const form = {};

      // apenas na edição ?
      form.con_id = this.appointmentData.con_id || 0;

      form.blnAlertarPacienteConsultadoHoje = true; // vai enviar sempre true ?
      form.con_hora = this.form.con_hora;
      form.con_id_historicos_agendamento = this.employeeData.hag_id; // esta vindo zerado Daniel comentou que dependendo pode vir com alguma informacao
      form.con_id_locais_atendimento = this.form.con_id_locais_atendimento;
      form.con_id_pacientes_consultas = this.patient.pcc_id;
      form.con_id_planos_saude = this.form.con_id_planos_saude;
      form.con_id_tipos_consultas_unidade = this.form.con_id_tipos_consultas_unidade;
      form.eag_id = this.employeeData.eag_id;
      form.eag_quantidade_marcadas = this.employeeData.eag_quantidade_marcadas;
      form.hag_data_consulta =
        this.employeeData.data_consulta || this.employeeData.DataConsulta;
      // nao temos essa informacao no employeeData pode ser que venha caso exista histporico agendamento
      form.hag_hora_inicio_consulta_funcionario =
        this.employeeData.hag_hora_inicio_consulta_funcionario ||
        this.employeeData.eag_inicio_atendimentos;
      form.hag_id = this.employeeData.hag_id;
      form.hag_id_dias_semana =
        this.employeeData.hag_id_dias_semana ||
        this.employeeData.eag_id_dias_semana; // caso nao venha do hag posso mandar do eag ?
      form.hag_id_escalas_agendamentos =
        this.employeeData.hag_id_escalas_agendamentos ||
        this.employeeData.eag_id; // caso nao venha do hag posso mandar do eag ?
      form.hag_intervalo_consultas =
        this.employeeData.hag_intervalo_consultas ||
        this.employeeData.eag_intervalo_consultas; // caso nao venha do hag posso mandar do eag ?
      form.hag_quantidade_consultas =
        this.employeeData.hag_quantidade_consultas ||
        this.employeeData.eag_quantidade_consultas; // caso nao venha do hag posso mandar do eag ?
      form.set_id = this.employeeData.set_id;
      form.tcu_id_unidades_saude = this.employeeData.uns_id;
      form.tipo_agendamento = ""; // oque seria tipo agendamento ?

      return form;
    },

    async getPatientData(pPatient) {
      const hasPerson = pPatient && pPatient.pes_id > 0;
      const patientBlocked =
        hasPerson &&
        pPatient.patient &&
        pPatient.patient.pac_id_motivo_inabilitacao;

      if (patientBlocked) {
        const patientIdReasonBlocked =
          pPatient.patient.pac_id_motivo_inabilitacao;

        this.$emit("canSave", false);
        return this.$toaster.warning(
          "Paciente inativo. Motivo: " +
            `${this.REASONS_BLOCKED_PATIENT[patientIdReasonBlocked]}.`,
        );
      }

      if (!pPatient?.patient && hasPerson) {
        // call action to create patient and fill pPatient.patient with patient data?
        const result = await this.$store.dispatch(
          "Person/Patient/CREATE_PATIENT_FOR_PERSON",
          {
            pes_id: pPatient.pes_id,
            withPatientAppointment: true,
          },
        );
        pPatient.patient = result;
      }
      if (pPatient?.patient !== null && hasPerson) {
        this.patient.pac_id =
          pPatient && pPatient.patient ? pPatient.patient.pac_id : null;

        this.patient.pcc_id =
          this.patient.pac_id && pPatient.patient.patient_appointment
            ? pPatient.patient.patient_appointment.pcc_id
            : null;

        this.$emit("canSave", true);

        this.fillPatientInfo(pPatient);
      } else {
        this.patientInfo = {};
        this.patient = {
          pac_id: "",
          pcc_id: "",
        };
        this.$emit("canSave", false);
        // this.$refs.patientHistory.cleanSearchForm();
      }
    },
    fillPatientInfo(pData) {
      this.patientInfo = {
        pac_id: pData.patient.pac_id,
        pes_id: pData.pes_id,
        pac_nome: pData.pes_nome,
        sex_sigla: pData && pData.gender ? pData.gender.sex_sigla : null,
        pes_nascimento:
          pData && pData.pes_nascimento ? pData.pes_nascimento : null,
        crs_numero: pData && pData.cns ? pData.cns.crs_numero : null,
        pes_mae: pData && pData.pes_mae ? pData.pes_mae : null,
        cpf: pData && pData.cpf ? pData.cpf.cpf_numero : null,
        prontuario_unico:
          pData && pData.patient ? pData.patient.pac_prontuario_unico : null,
      };
    },

    closeModalHistorySchedule() {
      this.showModalHistorySchedule = false;
    },
    async changeToAvailableHourAndSave() {
      try {
        this.form.con_hora = this.unavailableHour.nextAvailableHour;
        this.$emit("saveWithAvailableHour");
      } catch (err) {}
    },
    closeModalUnavailableHour() {
      this.unavailableHour.show = false;
      this.unavailableHour.message = "";
      this.unavailableHour.nextAvailableHour = "";
    },
    async loadDataForEditing() {
      if (this.isEditing && this.appointmentData.pes_id) {
        this.$loader.start();
        this.form.con_hora = this.appointmentData.con_hora;
        this.form.con_id_locais_atendimento = this.appointmentData.con_id_locais_atendimento;
        this.form.con_id_planos_saude = this.appointmentData.con_id_planos_saude;
        this.form.con_id_tipos_consultas_unidade = this.appointmentData.con_id_tipos_consultas_unidade;
        this.patient = {
          pcc_id: this.appointmentData.pcc_id,
          pac_id: this.appointmentData.pac_id,
        };
        this.form.hourAppointmentAlter = this.appointmentData.con_hora;

        await this.$store
          .dispatch("Appointment/Schedule/GET_PATIENT_BASIC_INFO", {
            pesId: this.appointmentData.pes_id,
          })
          .then((personData) => {
            this.$refs.patient.fillPatientById(personData.pes_id);
          })
          .catch((pErr) => {
            this.$toaster.error(
              "Falha ao carregar os dados da pessoa para exibição, isso não afeta a alteraçao dos dados: " +
                pErr.message,
            );
          })
          .finally(() => {
            // this.$loader.finish(1000);
            this.finishLoader.edit = true;
          });
      }
    },
    async setDaysPossible() {
      try {
        this.$loader.start("Carregando datas disponíveis...");
        const pData = {
          intIdSetor: Number(this.employeeData.set_id),
          intIdOcupacao: Number(this.employeeData.ocp_id),
          intIdPessoa: Number(this.employeeData.pes_id),
          intIdUnidadeSaude: Number(this.employeeData.uns_id),
          strDataInicial: moment().startOf("year").format("MM/DD/YYYY"),
          strDataFinal: moment()
            .startOf("year")
            .add("years", 1)
            .format("MM/DD/YYYY"),
        };

        this.periodsDate = await this.$store.dispatch(
          "Appointment/Schedule/GET_DAY_ON_WEEK_FOR_PERIODS",
          pData,
        );

        this.periodsDate = this.periodsDate.map((item) => {
          return {
            eag_inicio_atendimentos: item.eag_inicio_atendimentos.substr(0, 5),
            data: item.data,
            eag_id: item.eag_id,
            hag_id: item.hag_id,
            eag_id_dias_semana: item.eag_id_dias_semana,
            eag_quantidade_consultas: item.eag_quantidade_consultas,
            eag_intervalo_consultas: item.eag_intervalo_consultas,
            eag_quantidade_marcadas: item.eag_quantidade_marcadas,
          };
        });

        this.datesEnable = this.periodsDate.map((item) =>
          moment(item.data, "DD-MM-YYYY").format("YYYY-MM-DD"),
        );
      } catch (Err) {
        console.error(Err);
      } finally {
        this.$loader.finish();
      }
    },

    cleanForm() {
      this.suggestPatient = null;
      this.dateIsValid = false;
      this.hourIsValid = false;
      this.dateHourList = [];

      this.patient = {
        pac_id: null,
        pcc_id: null,
      };

      this.form = {
        con_id_locais_atendimento: "",
        con_id_planos_saude: "",
        con_id_tipos_consultas_unidade: "",
        con_hora: "",
        date: "",
      };

      this.patientInfo = {};

      this.$refs.patient.cleanValidate();
      this.$refs.place.cleanValidate();
      this.$refs.healthPlans.cleanValidate();
      this.$refs.objective.cleanValidate();

      if (!this.blockedAppointmentSchedulingTime && !this.typeSchedule) {
        this.$refs.hour.cleanValidate();
      }

      if (this.blockedAppointmentSchedulingTime && !this.typeSchedule) {
        this.$refs.comboHour.cleanValidate();
      }

      this.disabledPatientForm = false;
    },

    getAttributeDayByDate(pDate, pAtrr) {
      if (this.periodsDate.length <= 0) return;
      return this.periodsDate.find((ele) => ele.data === pDate)[pAtrr];
    },
  },
};
</script>
