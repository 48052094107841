<template>
  <section class="patient-form">
    <FormBase title="Paciente" class="form-base-patient">
      <div class="grid-patient">
        <div class="row-1">
          <div class="flex">
            <RgInput
              v-model="form.recordNumber"
              :class="{ disable: true }"
              :disabled="true"
              label="Prontuário Único"
            />

            <InformationTooltip
              v-if="!existsPesId"
              :message="TOOLTIP"
              class="align-field"
            />
          </div>

          <div class="flex">
            <RgInputMasked
              v-model="form.ppr_numero"
              :class="{ disable: cantEdit }"
              :disabled="cantEdit"
              label="Prontuário da Unidade"
              mask="###########"
            />
          </div>

          <div v-if="isPrivateSector" class="flex">
            <RgInput
              v-model="form.pes_empresa_pleni"
              :maxlength="100"
              label="Empresa"
            />
          </div>
        </div>

        <div class="row-2">
          <p>
            <strong>{{ titleUnitInfoArea }}</strong>
          </p>
        </div>

        <div v-if="isPrivateSector" class="row-4">
          <RgComboboxUnithealthMappedPep
            ref="unit"
            v-model="pleni.uns_id"
            :rules="{ required: isPrivateSector }"
            label="Unidade de Saúde"
          />

          <RgComboboxSectorMappedPep
            ref="sector"
            v-model="pleni.set_id"
            label="Setores"
            :rules="{ required: isPrivateSector }"
            :unit-health-id="pleni.uns_id"
          />

          <RgComboboxPlaceMappedPep
            ref="place"
            v-model="pleni.lca_id"
            :rules="{ required: isPrivateSector }"
            label="Local de Atendimento"
            :sector-id="pleni.set_id"
          />
        </div>

        <div v-else class="row-3">
          <RgInput
            v-model="form.uns_nome"
            class="disable"
            label="Unidade de Saúde"
            disabled
          />

          <RgInput v-model="form.area" class="disable" label="Área" disabled />

          <RgInput
            v-model="form.microarea"
            class="disable"
            label="Microárea"
            disabled
          />

          <RgInput
            v-model="form.team"
            class="disable"
            label="Equipe"
            disabled
          />

          <RgInput
            v-model="form.responsible"
            class="disable"
            label="ACS Responsável"
            disabled
          />

          <RgInputBirthday
            v-model="form.lastMeetDate"
            class="disable"
            label="Data da última visita domiciliar"
            placeholder="dd/mm/aaaa"
            disabled
          />
        </div>
      </div>
    </FormBase>
  </section>
</template>

<script>
import {
  RgComboboxUnithealthMappedPep,
  RgComboboxSectorMappedPep,
  RgComboboxPlaceMappedPep,
  RgInput,
  RgInputBirthday,
  RgInputMasked,
  InformationTooltip,
} from "~tokio/primitive";
import FormBase from "~tokio/foundation/form-base/FormBase";
import { mapGetters } from "vuex";

export default {
  name: "PatientForm",
  components: {
    FormBase,
    RgInput,
    RgInputBirthday,
    RgInputMasked,
    InformationTooltip,
    RgComboboxUnithealthMappedPep,
    RgComboboxPlaceMappedPep,
    RgComboboxSectorMappedPep,
  },
  props: {
    cantEdit: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isPrivateSector: false,
      pleni: {
        uns_id: null,
        set_id: null,
        lca_id: null,
      },
      form: {
        pac_id: null,
        recordNumber: null,
        ppr_numero: null,
        uns_id: null,
        uns_nome: "",
        area: "",
        microarea: "",
        team: "",
        responsible: "",
        lastMeetDate: "",
      },
    };
  },

  computed: {
    ...mapGetters({
      existsPesId: "Person/Patient/EXISTS_PES_ID",
      patientData: "Person/Patient/GET_PATIENT_INFO",
      isNewBorn: "Person/Patient/IS_NEWBORN",
    }),

    titleUnitInfoArea() {
      return this.isPrivateSector
        ? "- Dados da Atenção Primária"
        : "- Dados da Atenção Básica";
    },
  },

  watch: {
    patientData(pValue) {
      const hasValue = pValue && Object.keys(pValue).length > 0;
      const isToLoadData = !this.isNewBorn && hasValue;

      if (isToLoadData) {
        const patient = this.$utils.obj.DeepCopy(pValue);

        this.loadPatientInformation(patient);
      }
    },
  },

  created() {
    this.TOOLTIP =
      "Esse campo será gerado automaticamente após realizar o cadastro.";
  },

  mounted() {
    this.isPrivateSector = this.$store.getters["Login/GET_PRIVATE_SECTOR"];
    this.form.uns_id = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
  },

  methods: {
    loadPatientInformation(patient) {
      const hasUnitHealthFromStore = this.getValue(patient, "uns_id") > 0;

      const unitHealthId = hasUnitHealthFromStore
        ? this.getValue(patient, "uns_id")
        : this.form.uns_id;

      try {
        this.form.pac_id = patient && patient.pac_id ? patient.pac_id : null;

        this.form.uns_id = unitHealthId;
        this.form.recordNumber = this.getValue(patient, "pac_prontuario_unico");
        this.form.ppr_numero = this.getValue(
          patient,
          "record_numbers",
          "ppr_numero",
        );
        this.form.uns_nome = this.getValue(patient, "uns_nome");
        this.form.area = this.getValue(patient, "area");
        this.form.microarea = this.getValue(patient, "microarea");
        this.form.team = this.getValue(patient, "equipe");
        this.form.responsible = this.getValue(patient, "acs");
        this.form.lastMeetDate = this.getValue(patient, "data_visita");
        this.pleni.lca_id = this.getValue(patient, "pleni", "lca_id");
        this.pleni.set_id = this.getValue(patient, "pleni", "set_id");
        this.pleni.uns_id = this.getValue(patient, "pleni", "uns_id");
        this.form.pes_empresa_pleni = this.getValue(
          patient,
          "pleni",
          "pes_empresa",
        );
      } catch (pErr) {
        this.$toaster.error("Falha ao carregar as informações do paciente.");
      }
    },

    getValue(pData, pKey, pChildKey = null) {
      let hasValue = null;
      let data = null;

      if (pChildKey) {
        hasValue = pData && pData[pKey] && pData[pKey][pChildKey];
        data = hasValue ? pData[pKey][pChildKey] : null;
      } else {
        hasValue = pData && pData[pKey];
        data = hasValue ? pData[pKey] : null;
      }
      return data;
    },

    getPatientForm() {
      const patient = {
        pac_id: this.form.pac_id,
        ppr_numero: this.form.ppr_numero,
        pac_prontuario_unico: this.form.recordNumber,
        uns_id: this.form.uns_id,
        pleni_place_id: this.pleni.lca_id,
        pleni_company: this.form.pes_empresa_pleni,
      };

      return patient;
    },

    cleanForm() {
      this.form.pac_id = null;
      this.form.recordNumber = null;
      this.form.ppr_numero = null;
      this.form.uns_nome = "";
      this.form.uns_id = "";
      this.form.area = "";
      this.form.microarea = "";
      this.form.team = "";
      this.form.responsible = "";
      this.form.lastMeetDate = "";
      this.cleanPleniInfo();
    },

    cleanPleniInfo() {
      this.pleni.uns_id = null;
      this.pleni.set_id = null;
      this.pleni.lca_id = null;
      if (this.$refs.unit) {
        this.$refs.unit.cleanValidate();
      }
      if (this.$refs.sector) {
        this.$refs.sector.cleanValidate();
      }
      if (this.$refs.place) {
        this.$refs.place.cleanValidate();
      }
    },
  },
};
</script>
