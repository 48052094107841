<style src="./RgHospitalizationStatus.scss" lang="scss" scoped></style>
<template lang="html">
  <div class="status">
    <div v-if="isInactive" :class="{ 'status-inactive unselect': isInactive }">
      <span class="align-text">Inativo</span>
      <div class="square" />
    </div>

    <div v-if="isActive" :class="{ 'status-active unselect': isActive }">
      <span class="align-text">Ativo</span>
      <div class="square" />
    </div>

    <div v-if="isExtra" :class="{ 'status-extra unselect': isExtra }">
      <span class="align-text">Extra</span>
      <div class="square" />
    </div>

    <div
      v-if="isObservacao"
      :class="{ 'status-observation unselect': isObservacao }"
    >
      <span class="align-text">Observação</span>
      <div class="square" />
    </div>
  </div>
</template>

<script>
export default {
  name: "RgHospitalizationStatus",
  props: {
    generateStatus: {
      type: Array,
      default: () => [],
    },
    activeStatus: {
      type: Number,
    },
  },
  computed: {
    isInactive() {
      return this.activeStatus === 0;
    },

    isActive() {
      return this.activeStatus === 1;
    },

    isExtra() {
      return this.generateStatus.includes(1);
    },

    isObservacao() {
      return this.generateStatus.includes(2);
    },
  },
};
</script>
