<template>
  <div v-shortkey="['esc']" class="rg-confirm-modal" @shortkey="close">
    <RgBaseModal :show="show" class="rg-base-modal" @close="close">
      <div slot="header" class="confirm-header">
        <span class="title">{{ title }}</span>
      </div>

      <slot slot="body" name="body" class="confirm-body">
        <span class="message" v-html="message" />
      </slot>

      <div slot="footer" class="confirm-footer">
        <button class="cancel" @click.stop.prevent="noClicked">
          {{ noLabel }}
        </button>
        <button class="confirm" @click.stop.prevent="yesClicked">
          {{ yesLabel }}
        </button>
      </div>
    </RgBaseModal>
  </div>
</template>

<script type="text/javascript">
import RgBaseModal from "./RgBaseModal";
export default {
  name: "RgConfirmModal",
  components: {
    RgBaseModal,
  },
  props: {
    yesLabel: {
      type: String,
      default: "Sim. Tenho certeza",
    },
    noLabel: {
      type: String,
      default: "Cancelar",
    },
    title: {
      type: String,
      default: "Confirmar",
    },
    message: {
      type: String,
      default: "Deseja confirmar?",
    },
    show: {
      type: Boolean,
      default: undefined,
    },
  },
  methods: {
    yesClicked: function () {
      this.close();
      this.$emit("confirmed");
    },
    noClicked: function () {
      this.close();
      this.$emit("denied");
    },
    close: function () {
      this.$emit("close");
    },
  },
};
</script>
