import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  /*
    PAYLOAD DO METODO

    fil_conselho_classe = "123456/2302"
    fil_data_pedido = "09/10/2020"
    fil_id_agendado_por = "1"
    fil_id_funcionarios = "424"
    fil_id_pessoas = "67519"
    fil_id_prioridades_fila = "317"
    fil_id_unidades_saude = "664"
    fil_id_unidades_saude_solicitante = "667"
    fil_ordem_regulada = "0"
    fio_observacao = ""
    flc_id = 2440
    flc_id_sinonimos_ocupacoes_cbo = "25"
    flc_nome_sinonimos_ocupacoes = "ADMINISTRADOR"
*/
  const sendData = {
    arrFormData: variables,
  };
  return AuthLegacyRequest.post(
    "/consultas/controller-fila-consulta/alterar",
    sendData,
  )
    .then(({ data }) => {})
    .catch((pErr) => {
      throw pErr;
    });
};
