<template lang="html">
  <svg
    width="13.979mm"
    height="14.031mm"
    version="1.1"
    viewBox="0 0 13.979 14.031"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(-72.443 -147.68)">
      <path
        d="m85.949 158.74-2.3094-2.3094c0.41989-0.83977 0.69982-1.8195 0.69982-2.7993 0-3.2891-2.6593-5.9484-5.9484-5.9484-3.2891 0-5.9484 2.6593-5.9484 5.9484 0 3.2891 2.6593 5.9484 5.9484 5.9484 0.97974 0 1.9595-0.27993 2.7993-0.69982l2.3094 2.3094c0.69982 0.69981 1.7495 0.69981 2.4494 0 0.62984-0.69982 0.62984-1.7496 0-2.4494zm-7.558-0.55985c-2.5193 0-4.5488-2.0295-4.5488-4.5488s2.0295-4.5488 4.5488-4.5488c2.5193 0 4.5488 2.0295 4.5488 4.5488s-2.0295 4.5488-4.5488 4.5488z"
        fill="#fff"
        stroke-width=".027337"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "SearchExam",
};
</script>
