<style src="./SurgeryMapHeader.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="surgery-map-header">
    <div class="vendor-logo">
      <img
        ref="imageLogoContainer"
        src=""
        alt="Logo"
        class="vendor-logo-image"
      />
    </div>

    <div class="unit-info">
      <span class="information">RG ESUS - Sistema Hospitalar</span>
      <span class="information"
        >{{ unitHealthInfo.name }} / {{ unitHealthInfo.city }} -
        {{ unitHealthInfo.state }}</span
      >
      <span class="information"
        >{{ unitHealthInfo.address }} - Nº {{ unitHealthInfo.number }}</span
      >
    </div>

    <div class="header-title">
      <span class="title">{{ unitHealthInfo.name }}</span>
      <span class="description">Mapa Cirúrgico</span>
    </div>
  </section>
</template>
<script>
import LocalStorage from "~common/local-storage/LocalStorage";

export default {
  name: "SurgeryMapHeader",
  props: {
    unitHealth: {
      default: "",
    },
    headerInfo: {
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      unitHealthName: "---",
      unitHealthInfo: {
        name: "",
        city: "",
        state: "",
        address: "",
        number: "",
      },
    };
  },
  watch: {
    headerInfo: {
      handler: function () {
        this.fillUnitHealthInfo();
      },
      deep: true,
    },
  },
  mounted() {
    this.openClientLogo();
    this.fillUnitHealthInfo();
  },
  methods: {
    openClientLogo() {
      const clientLogo = LocalStorage.getObject("client-logo");
      this.$refs.imageLogoContainer.src = clientLogo;
    },
    fillUnitHealthInfo() {
      this.unitHealthInfo.name = this.headerInfo.name;
      this.unitHealthInfo.city = this.headerInfo.city;
      this.unitHealthInfo.state = this.headerInfo.state;
      this.unitHealthInfo.address = this.headerInfo.address;
      this.unitHealthInfo.number = this.headerInfo.number;
    },
  },
};
</script>
