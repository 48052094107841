<style src="./BaseCrudsView.scss" lang="scss" scoped></style>
<template lang="html">
  <div class="base-cruds-view">
    <section class="tabs-options">
      <RgTabs :itens="itens">
        <div slot="material">
          <RgSurgeryMaterialTypeCrud />
        </div>
        <div slot="kits">
          <RgSurgeryKitTypeCrud />
        </div>
        <div slot="equipamento">
          <RgSurgeryEquipmentTypeCrud />
        </div>
        <div slot="funcoes">
          <RgSurgeryEmployeeFunctionsCrud />
        </div>
        <div slot="grupos-funcoes">
          <RgSurgeryEmployeeFunctionsGroupCrud />
        </div>
        <div slot="clinicas">
          <RgSurgeryRequestingClinicCrud />
        </div>
        <div slot="ocupacoes">
          <RgSurgeryClinicOccupationCrud />
        </div>
      </RgTabs>
    </section>
  </div>
</template>

<script>
import { RgTabs } from "~tokio/foundation/container";
import RgSurgeryKitTypeCrud from "$surgery-center/surgery/component/container/rg-surgery-kit-type-crud/RgSurgeryKitTypeCrud";
import RgSurgeryMaterialTypeCrud from "$surgery-center/surgery/component/container/rg-surgery-material-type-crud/RgSurgeryMaterialTypeCrud";
import RgSurgeryEquipmentTypeCrud from "$surgery-center/surgery/component/container/rg-surgery-equipment-type-crud/RgSurgeryEquipmentTypeCrud";
import RgSurgeryEmployeeFunctionsCrud from "$surgery-center/surgery/component/container/rg-surgery-employee-functions-crud/RgSurgeryEmployeeFunctionsCrud";
import RgSurgeryEmployeeFunctionsGroupCrud from "$surgery-center/surgery/component/container/rg-surgery-employee-functions-group-crud/RgSurgeryEmployeeFunctionsGroupCrud";
import RgSurgeryRequestingClinicCrud from "$surgery-center/surgery/component/container/rg-surgery-requesting-clinic-crud/RgSurgeryRequestingClinicCrud";
import RgSurgeryClinicOccupationCrud from "$surgery-center/surgery/component/container/rg-surgery-clinic-occupation-crud/RgSurgeryClinicOccupationCrud";

export default {
  name: "BaseCrudsView",
  components: {
    RgSurgeryKitTypeCrud,
    RgSurgeryMaterialTypeCrud,
    RgSurgeryEquipmentTypeCrud,
    RgTabs,
    RgSurgeryEmployeeFunctionsCrud,
    RgSurgeryEmployeeFunctionsGroupCrud,
    RgSurgeryRequestingClinicCrud,
    RgSurgeryClinicOccupationCrud,
  },
  data() {
    return {
      tabSelected: 0,
      itens: [
        {
          label: "Material",
          granted: this.$Permissions.global.has("centro_cirurgico"),
          slot: "material",
        },
        {
          label: "Kits",
          granted: this.$Permissions.global.has("centro_cirurgico"),
          slot: "kits",
        },
        {
          label: "Equipamento",
          granted: this.$Permissions.global.has("centro_cirurgico"),
          slot: "equipamento",
        },
        {
          label: "Funções",
          granted: this.$Permissions.global.has("centro_cirurgico"),
          slot: "funcoes",
        },
        {
          label: "Grupos de Funções",
          granted: this.$Permissions.global.has("centro_cirurgico"),
          slot: "grupos-funcoes",
        },
        {
          label: "Clinicas",
          granted: this.$Permissions.global.has("centro_cirurgico"),
          slot: "clinicas",
        },
        {
          label: "Ocupações",
          granted: this.$Permissions.global.has("centro_cirurgico"),
          slot: "ocupacoes",
        },
      ],
    };
  },
};
</script>
