<template>
  <section class="details-alteration-container">
    <div class="subtitle-modal">
      <span class="text">Cadastro de Pacientes - Alterações</span>
    </div>

    <div class="content-detail">
      <PersonPersonalData
        id="personal-data"
        ref="personal"
        cantEdit
        class="mg-form"
      />

      <PersonAddress id="address-data" ref="address" cantEdit class="mg-form" />

      <PersonDocument
        id="document-data"
        ref="document"
        cantEdit
        class="mg-form"
      />

      <PersonContacts
        id="contacts-data"
        ref="contacts"
        cantEdit
        class="mg-form"
      />

      <PatientForm id="patient-data" ref="patient" cantEdit class="mg-form" />
    </div>
  </section>
</template>

<script>
import {
  PersonPersonalData,
  PersonAddress,
  PersonDocument,
  PersonContacts,
} from "$person/common/components/";
import PatientForm from "$person/patient/component/container/patient-form/PatientForm";

export default {
  name: "DetailsAlterationContainer",
  components: {
    PersonPersonalData,
    PersonDocument,
    PersonAddress,
    PersonContacts,
    PatientForm,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
