<style src="./RgComboboxSurgeryEmployeeFunctionsGroup.scss" lang="scss"></style>
<script>
import { RgRemoteComboboxMixin } from "~tokio/primitive";
import SurgeryEmployeeFunctionsGroupModel from "$surgery-center/surgery/model/SurgeryEmployeeFunctionsGroupModel";
export default {
  name: "RgComboboxSurgeryEmployeeFunctionsGroup",
  mixins: [RgRemoteComboboxMixin],
  data: () => {
    return {
      cache: false,
      model: SurgeryEmployeeFunctionsGroupModel,
    };
  },
  computed: {
    filter() {
      return { grc_ativo: 1 };
    },
  },
  methods: {
    formatItem(pData) {
      return {
        value: pData.grc_id,
        text: pData.grc_nome,
      };
    },
  },
};
</script>
