<style src="./ModalConfirmAddExam.scss" lang="scss" scoped></style>
<template lang="html">
  <aside class="modal-confirm-add-exam">
    <RgBaseModal
      ref="modalAdd"
      v-shortkey="['esc']"
      :show="show"
      withCloseButton
      class="rg-alert-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header">
        <h2 class="title">Adicionar procedimento</h2>
      </div>

      <div slot="body">
        <h3 class="sub-title" />
        <RgTextArea
          v-model="reasonAddExam"
          data-id="caixa-motivo"
          :rules="{ required: true }"
          :maxlength="500"
          class="input-textarea"
          label="Motivo"
        />
      </div>

      <div slot="footer" class="confirm-actions-footer">
        <RgCancelButton class="cancel" medium @click="close" />

        <RgConfirmButton
          ref="confirmButton"
          v-shortkey="['enter']"
          :disabled="!hasReason"
          class="confirm-actions-item"
          large
          @shortkey.native="add"
          @click="add"
        />
      </div>
    </RgBaseModal>
  </aside>
</template>

<script>
import { isEmpty } from "lodash";
import {
  RgBaseModal,
  RgTextArea,
  RgCancelButton,
  RgConfirmButton,
} from "~tokio/primitive";

export default {
  name: "ModalConfirmAddExam",
  components: {
    RgBaseModal,
    RgTextArea,
    RgCancelButton,
    RgConfirmButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      reasonAddExam: null,
    };
  },
  computed: {
    hasReason() {
      return !isEmpty(this.reasonAddExam);
    },
  },
  watch: {
    show() {
      this.reasonAddExam = null;
    },
    reasonAddExam(pValue) {
      this.$emit("input", pValue);
    },
  },
  methods: {
    add() {
      try {
        this.$refs.confirmButton.actionSubmit();
        this.$emit("exam-add", this.reasonAddExam);
        this.$refs.confirmButton.actionDone();
      } catch (pErr) {
        this.$toaster.error(`Erro ao tentar adicionar procedimento. ${pErr}`);
        this.$refs.confirmButton.fail();
      }
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
