/*
 * Recebe uma String com o link do arquivo que você deseja realizar o download
 * @param url {String} String do link
 * @param name {String} String do nome do arquivo
 */
const DownloadByUrl = (url, name) => {
  if (!url) {
    console.error("URL não informada. (url, name)");
    return false;
  }
  if (!name) {
    console.error("Nome da imagem não informada. (url, name)");
    return false;
  }
  fetch(url).then((t) => {
    return t.blob().then((b) => {
      var a = document.createElement("a");
      a.href = URL.createObjectURL(b);
      a.setAttribute("download", name);
      a.click();
      return true;
    });
  });

  return false;
};

export { DownloadByUrl };
export default DownloadByUrl;
