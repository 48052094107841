import { GraphQLClient, gql } from "@/common/request/GraphQLClient";
import {
  FlattenData,
  RemoveUnusedFields,
  SanitizeFields,
} from "./PersonHelper";

// import moment from 'moment'

const mutation = gql`
  mutation(
    $personId: Int!
    $person: PersonInputType!
    $address: AddressInputType
  ) {
    UpdatePerson(personId: $personId, person: $person, address: $address) {
      pes_nome
    }
  }
`;

export default async (store, personInfo) => {
  const personId = personInfo.person.pes_id;
  const person = Object.assign({}, personInfo.person);
  const address = Object.assign({}, personInfo.address);

  delete person.address;
  address.end_ativo = 1;
  const flattendedData = FlattenData({ address, person });
  const removedUnusedFields = RemoveUnusedFields(flattendedData);
  const sanitizedFields = SanitizeFields(removedUnusedFields);

  const variables = { personId, ...sanitizedFields };

  try {
    const { data } = await GraphQLClient.mutate({ mutation, variables });
    return data;
  } catch (err) {
    return Promise.reject(err.graphQLErrors[0]);
  }
};
