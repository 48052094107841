import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  return AuthLegacyRequest.post(
    "tabelas/controller-prioridade-fila/buscar",
    variables,
  )
    .then(({ data }) => {
      if (data.dados === "0") {
        return [];
      }
      data = HandleResponse(data);

      data.dados.resultado.map((item) => {
        item.pfi_cor = item.pfi_cor.replace("0x", "#");
        item.pfi_ativo = Boolean(Number(item.pfi_ativo));
      });

      data.dados.data = data.dados.resultado;

      delete data.dados.resultado;

      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
