export default {
  GET_HOSPITALIZATION_AIH_LIST: (state) => state.hospitalizationList,
  GET_HOSPITALIZATION_SEARCH_FILTER: (state) => state.searchFilter,
  GET_HOSPITALIZATION_ID: (state) => state.hospitalizationId,
  GET_HOSPITALIZATION_BILLING_AIH_ID: (state) =>
    state.hospitalizationBillingAihId,
  GET_HOSPITALIZATION_BILLING_AIH_STATUS: (state) =>
    state.hospitalizationAihInfo.iaf_faturado,
  GET_HOSPITALIZATION_AIH_PROCEDURES: (state) =>
    state.hospitalizationAihProcedures,
  GET_HOSPITALIZATION_BILLING_DIAGNOSTICS_INFO: (state) =>
    state.hospitalizationBillingDiagnosticsInfo,
  GET_HOSPITALIZATION_AIH_INFO: (state) => state.hospitalizationAihInfo,
  GET_PATIENT_INFO_TAB: (state) => state.patientInfoTab,
  GET_PATIENT_DOCUMENTS: (state) => state.patientDocuments,
  GET_PATIENT_UNIT_RECORD_NUMBER: (state) => state.patientUnitRecordNumber,
  GET_PATIENT_CNS: (state) => state.patientInfoTab.icf_cns,
  GET_AIH_NUMBER: (state) => state.hospitalizationAihInfo.iaf_numero_aih,
  GET_HOSPITALIZATION_BILLING_INFO_TAB: (state) =>
    state.hospitalizationBillingInfoTab,
  GET_HOSPITALIZATION_AIH_RELEASE_REQUEST_CODE: (state) =>
    state.hospitalizationAihReleaseRequestCode,
  GET_HOSPITALIZATION_DATE: (state) =>
    state.hospitalizationAihInfo.iaf_data_internacao,
  GET_EXIT_DATE: (state) => state.hospitalizationAihInfo.iaf_data_saida,
  GET_PRESENTATION: (state) => state.hospitalizationAihInfo.iaf_apresentacao,
  GET_HOSPITALIZATION_BILLING_CHARACTER: (state) =>
    state.hospitalizationBillingInfoTab.inf_codigo_carater_internacao,
  GET_PATIENT_SEARCHED: (state) => state.patientSearched,
};
