import qz from "qz-tray";
import QZSignRequest from "./QZSignRequest";
import QZCertificateRequest from "./QZCertificateRequest";
/**
 * Cria a conexão com o cliente da máquina
 */
const QZConnection = async () => {
  try {
    const hasConnection = qz.websocket.isActive();
    if (!hasConnection) {
      await qz.websocket.connect();
    }
  } catch (error) {
    throw Error("Não foi possível conectar com o cliente QZ na máquina");
  }
};

/**
 * Seta o certificado no cliente da máquina
 */
const QZCertificate = (pData) => {
  try {
    qz.security.setCertificatePromise(function (resolve, reject) {
      QZCertificateRequest().then((response) => {
        const { data } = response;
        resolve(data.GetCertificate.certificate);
      });
    });
  } catch (error) {
    console.log("Error:", error);
    throw Error("Não foi possível validar o certificado");
  }
};

/**
 * Seta o certificado no cliente da máquina
 */
const QZSign = (toSign) => {
  try {
    qz.security.setSignaturePromise(function (toSign) {
      return function (resolve, reject) {
        const variables = {
          toSign,
        };

        QZSignRequest(variables).then((response) => {
          const { data } = response;
          resolve(data.GetSignature.key);
        });
      };
    });
  } catch (error) {
    console.log("Error:", error);
    throw Error("Não foi possível setar assinatura");
  }
};

/**
 * Busca as impressoras disponíveis na rede
 * @return {Array} Nomes das impressoras na rede
 */
const QZFindPrinters = async () => {
  try {
    await QZConnection();
    const availablePrinters = await qz.printers.find();

    return availablePrinters;
  } catch (error) {
    throw Error("Não foi possível encontrar impressora(s)");
  }
};

/**
 * Realiza a impressão de maneira raw, utilizando diretamente comandos EPL
 * @param {string} pData String com comandos epl a serem impressos
 * @param {string} pPrinter O Nome da impressora a qual receberá os dados para imprimir
 */
const QZRawPrint = async (pData, pPrinter) => {
  try {
    await QZConnection();

    if (!pData) {
      throw new Error("Não existe dados para serem impressos");
    }
    const dataToPrint = [pData];
    const printer = qz.configs.create(pPrinter);

    return qz.print(printer, dataToPrint);
  } catch (error) {
    throw Error("Não foi possível realizar a impressão");
  }
};

export { QZFindPrinters, QZCertificate, QZSign, QZRawPrint };
