<style src="./Bulletin.scss" lang="scss" scoped></style>
<template lang="html">
  <div class="container">
    <RgSearch
      ref="RgSearch"
      :search-function="searchFunction"
      :build-filter="buildFilter"
      :clear-function="clearForm"
      :item-height="70"
      new-url="/billing/billing"
      filter-title="Filtro de Busca"
      result-title="Lista de Boletins"
      @afterSearch="updateList"
    >
      <div slot="filters">
        <BulletinFilter ref="filter" />
      </div>

      <div>
        <BulletinList ref="list" />
      </div>
    </RgSearch>
  </div>
</template>

<script>
import RgSearch from "~tokio/foundation/rg-search/RgSearch.vue";
import BulletinFilter from "../component/bulletin-filter/BulletinFilter";
import BulletinList from "../component/bulletin-list/BulletinList";
import searchBulletinBilling from "../action/searchBulletinBilling";
// import BdSiaModel from '$billing/bdsia/model/BdSia'

export default {
  name: "Bulletin",
  components: {
    RgSearch,
    BulletinFilter,
    BulletinList,
  },
  data() {
    return {
      filterData: {
        uns_id: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        status: "null",
        period: this.$store.getters["Billing/BDSia/GET_PERIOD_DATE"],
      },
      buildFilter: () => {},
    };
  },
  computed: {
    uns_id() {
      return this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    },
  },
  watch: {
    uns_id: function () {
      this.filterData.uns_id = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    },
  },
  mounted() {
    this.clearForm();
    this.buildFilter = this.$refs.filter
      ? this.$refs.filter.prepareFilter
      : () => {};
    const bulletinSearchFilter = this.$store.getters[
      "Billing/Bulletin/GET_BULLETIN_SEARCH_FILTER"
    ];
    if (bulletinSearchFilter.status !== null) this.$refs.RgSearch.submitForm();
  },
  methods: {
    updateList(pValue) {
      this.$store.commit("Billing/Bulletin/SET_BULLETIN_LIST", pValue);
    },
    searchFunction(pValue) {
      return searchBulletinBilling(pValue);
    },
    clearForm() {
      if (this.$refs.filter && this.$refs.list) {
        this.$refs.filter.cleanForm();
        this.$refs.list.clearContentList();
      }
    },
  },
};
</script>
