import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  const sendData = {
    arrFormData: variables.arrFormData,
    modulo: variables.modulo,
  };

  return AuthLegacyRequest.post(
    "tabelas/controller-pacientes-excluidos-fila/obter-impressao-pacientes-excluidos-pela-fila",
    sendData,
  )
    .then(({ data }) => {
      data = HandleResponse(data);
      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
