<template lang="html">
  <svg
    width="58"
    height="58"
    viewBox="0 0 58 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.3919 19.5835C16.5598 19.5835 15.894 20.2492 15.894 20.9981V30.3181C15.894 31.1502 16.5598 31.7327 17.3919 31.7327H24.7147C25.5468 31.7327 26.2125 31.067 26.2125 30.3181C26.2125 29.4859 25.5468 28.9034 24.7147 28.9034H18.8897V20.9981C18.8065 20.2492 18.1408 19.5835 17.3919 19.5835Z"
      fill="white"
    />
    <path
      d="M34.2843 22.4961C35.2829 22.4961 36.1982 21.664 36.1982 20.6654C36.1982 19.6669 35.3661 18.8347 34.2843 18.8347C33.2025 18.8347 32.4536 19.5836 32.4536 20.5822C32.4536 21.5808 33.2858 22.4961 34.2843 22.4961Z"
      fill="white"
    />
    <path
      d="M33.2855 41.6353C34.2841 41.6353 35.1994 40.8031 35.1994 39.8046C35.1994 38.806 34.3673 37.9739 33.2855 37.9739C32.2037 37.9739 31.3716 38.806 31.3716 39.8046C31.3716 40.8031 32.2037 41.6353 33.2855 41.6353Z"
      fill="white"
    />
    <path
      d="M26.3787 41.0527C23.7991 43.2162 20.4706 44.3812 16.8092 44.298C9.23672 44.0483 3.07891 37.9737 2.82926 30.4013C2.66284 22.3296 9.1535 15.6725 17.3084 15.6725C20.637 15.6725 23.7159 16.7543 26.2123 18.7514C26.7948 19.2507 27.7102 19.1675 28.2094 18.5018C28.7087 17.9193 28.6255 17.0039 27.9598 16.5046C24.9641 14.0914 21.1363 12.76 17.3084 12.76C7.73887 12.76 0 20.4989 0 30.0684C0 39.3884 7.48923 47.044 16.8092 47.2105C21.2195 47.2937 25.297 45.7958 28.3759 43.133C29.0416 42.5505 29.0416 41.5519 28.2926 40.9694C27.7101 40.6366 26.9612 40.6366 26.3787 41.0527Z"
      fill="white"
    />
    <path
      d="M56.419 22.4961H41.1077C40.2756 22.4961 39.6099 21.8304 39.6099 20.9982V20.2493C39.6099 19.4172 40.2756 18.7515 41.1077 18.7515H56.5022C57.3344 18.7515 58.0001 19.4172 58.0001 20.2493V20.9982C58.0001 21.8304 57.3344 22.4961 56.419 22.4961Z"
      fill="white"
    />
    <path
      d="M56.5018 31.8159H43.4373C42.6052 31.8159 41.9395 31.1502 41.9395 30.4013V29.5692C41.9395 28.737 42.6052 28.1545 43.4373 28.1545H56.5018C57.334 28.1545 57.9997 28.8203 57.9997 29.5692V30.4013C57.9997 31.1502 57.334 31.8159 56.5018 31.8159Z"
      fill="white"
    />
    <path
      d="M56.419 41.552H41.1077C40.2756 41.552 39.6099 40.8863 39.6099 40.0542V39.3885C39.6099 38.5563 40.2756 37.8906 41.1077 37.8906H56.5022C57.3344 37.8906 58.0001 38.5563 58.0001 39.3885V40.1374C58.0001 40.8863 57.3344 41.552 56.419 41.552Z"
      fill="white"
    />
    <path
      d="M36.6141 31.8159C37.6127 31.8159 38.528 30.9838 38.528 29.9852C38.528 28.9867 37.6959 28.1545 36.6141 28.1545C35.6155 28.1545 34.7002 28.9867 34.7002 29.9852C34.7002 30.9838 35.5323 31.8159 36.6141 31.8159Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "ListServiceScale",
};
</script>
