<template>
  <div>
    <div class="row filter-fields">
      <RgComboboxUnithealth v-model="form.uns_id" />
    </div>

    <div class="row filter-fields">
      <rg-input-masked
        v-model="form.presentation"
        :mask="['BM/DDDD']"
        :tokens="tokens"
        :rules="{ fn: validatePeriod }"
        label="Apres.:"
        class="col-12"
      />
    </div>

    <div class="row filter-fields">
      <RgTypeaheadProcedure
        v-model="form.mainProcedure"
        :rules="{
          /*fn: validateRequestedProcedure*/
        }"
        :type="'AIH_PRINCIPAL'"
        label="Procedimento Principal:"
        placeholder="Digite a sugestão"
      />
    </div>

    <div class="row filter-fields">
      <RgRadioHospitalizationFilterStatusOption
        v-model="form.status"
        :aih="true"
        class="col-12"
      />
    </div>
  </div>
</template>

<script>
import { isEmpty } from "lodash";
import { RgTypeaheadProcedure } from "$billing/common";
import RgComboboxUnithealth from "$billing/common/component/rg-combobox-unithealth/RgComboboxUnithealth";
import {
  ValidatePeriodBilling,
  MountPresentation,
} from "../../../common/service/";
import RgRadioHospitalizationFilterStatusOption from "../../../common/component/radio/rg-radio-hospitalization-filter-status-option/RgRadioHospitalizationFilterStatusOption";

export default {
  name: "MainProcedure",
  components: {
    RgComboboxUnithealth,
    RgTypeaheadProcedure,
    RgRadioHospitalizationFilterStatusOption,
  },
  data() {
    return {
      resultTitle: "AIH por Procedimento Principal",
      form: {
        uns_id: 0,
        mainProcedure: "",
        presentation: "",
        status: "0",
      },
      tokens: {
        B: { pattern: /^([0-1])$/ },
        M: { pattern: /^([0-9])$/ },
        D: { pattern: /\d/ },
      },
    };
  },
  computed: {
    columnTable() {
      return this.COLUMN;
    },
    getNameSmartTable() {
      return "MainProcedure";
    },
  },
  watch: {
    "form.mainProcedure"(pData) {
      if (isEmpty(pData)) this.form.mainProcedure = null;
    },
  },
  created() {
    this.COLUMN = [
      { name: "Nome do Paciente", key: "icf_nome" },
      { name: "Prontuário", key: "iaf_prontuario" },
      { name: "Nº Aih", key: "iaf_numero_aih" },
      { name: "Procedimento Principal", key: "inp_novo_nome_procedimento" },
    ];
  },
  mounted() {
    this.form.uns_id = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    this.updatePresentationDate();
  },
  methods: {
    updatePresentationDate() {
      const cmp = this.$store.getters[
        "Billing/PeriodHospitalizationBilling/GET_PERIOD_HOSPITALIZATION_BILLING"
      ];
      if (!cmp) return;
      this.form.presentation = MountPresentation(cmp);
    },
    buildFilter() {
      return {
        uns_id: this.form.uns_id,
        mainProcedure: !this.form.mainProcedure
          ? null
          : this.form.mainProcedure.replace(/\D+/g, "").trim(),
        presentation: this.form.presentation,
        status: this.form.status,
      };
    },
    async doSearch(pData) {
      try {
        return this.$store.dispatch(
          "Billing/HospitalizationBilling/GET_MAIN_PROCEDURE_REPORT",
          pData,
        );
      } catch (error) {
        return false;
      }
    },
    validatePeriod(pAih, pErrors) {
      return ValidatePeriodBilling(this.form.presentation, pErrors);
    },
    clearFilter() {
      this.form = {
        uns_id: 0,
        mainProcedure: "",
        presentation: "",
        status: "0",
      };
    },
  },
};
</script>
