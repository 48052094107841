<style src="./ModalPeriodShow.scss" lang="scss" scoped></style>
<template>
  <div
    v-shortkey.push="['f3']"
    class="modal-period-show"
    @shortkey="showModalToggle"
  >
    <div
      v-if="modalIsShowing"
      tabindex="0"
      class="modal-container"
      @keydown.esc="closeModal"
    >
      <transition name="slide-fade">
        <div class="form-box">
          <span class="period-caption">Competência:</span>
          <span class="period-number">{{ periodNumber }}</span>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalPeriodShow",
  components: {},
  data() {
    return {
      modalIsShowing: false,
    };
  },
  computed: {
    periodNumber() {
      return this.$store.getters["Billing/BDSia/GET_PERIOD_NUMBER"];
    },
  },
  methods: {
    showModalToggle() {
      this.modalIsShowing = !this.modalIsShowing;
    },
    closeModal() {
      this.modalIsShowing = false;
    },
  },
};
</script>
