<style src="./RgResendButton.scss" lang="scss" scoped></style>
<template>
  <div class="rg-resend-button">
    <button
      ref="button-confirm"
      :data-id="dataId"
      :title="title"
      :class="{
        'rg-resend-button -small': small,
        'rg-resend-button -medium': medium,
        'rg-resend-button -large': large,
      }"
      class="button"
      @click.stop.prevent="confirm()"
    >
      <div v-show="medium || large" class="text">
        <span>Reenviar</span>
      </div>

      <div v-show="large" class="separator" />

      <div v-show="!medium" class="icon">
        <IconRightArrow />
      </div>
    </button>
  </div>
</template>
<script>
import { IconRightArrow } from "~tokio/primitive/icon/symbols";

export default {
  name: "RgResendButton",
  components: {
    IconRightArrow,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    dataId: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "Reenviar",
    },
    small: {
      type: Boolean,
      default: true,
    },
    medium: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    confirm() {
      this.$nextTick(() => {
        this.$refs["button-confirm"].focus();
      });
      this.$emit("click");
    },
  },
};
</script>
