<style src="./RgCsvButton.scss" lang="scss" scoped></style>
<template>
  <div class="rg-csv-button">
    <RgButton
      :title="title"
      :disabled="disabled"
      :permission="permission"
      :data-id="dataId"
      :class="{
        'rg-csv-button -small': small,
        'rg-csv-button -medium': medium,
        'rg-csv-button -large': large,
      }"
      class="button"
      @click="clicked"
    >
      <div v-show="medium || large" class="text">
        <span>Exportar CSV</span>
      </div>

      <div v-show="large" class="separator" />

      <div v-show="!medium" class="icon">
        <IconCsv />
      </div>
    </RgButton>
  </div>
</template>

<script>
import { IconCsv } from "~tokio/primitive/icon/symbols";
import RgButton from "~tokio/primitive/button/rg-button/RgButton.vue";
import RgButtonMixin from "~tokio/primitive/button/rg-button/RgButtonMixin.js";

export default {
  name: "RgCsvButton",
  components: {
    IconCsv,
    RgButton,
  },
  mixins: [RgButtonMixin],
  data() {
    return {
      statusWaiting: false,
    };
  },
  methods: {
    setWaiting() {
      this.statusWaiting = true;
    },
    setStartOrFinish() {
      this.statusWaiting = false;
    },
  },
};
</script>
