import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  const vari = { ...variables.arrFormData };
  return AuthLegacyRequest.post("emergencias/controller-pre-cadastro/buscar", {
    arrFiltro: vari,
  })
    .then(({ data }) => {
      data = HandleResponse(data);
      data.dados.data = data.dados.recordSet;

      data.dados.data.forEach((element) => {
        element.data_inicio = element.entrada.substring(0, 10);
        element.hora_inicio = element.entrada.substring(11, 16);
      });

      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
