<template>
  <Modulebox
    title="Observações do Paciente na Fila"
    class="hospitalization-patient-observation-queue"
  >
    <div class="body">
      <FormBase title="Paciente" class="content-form">
        <form class="form-container" @submit.stop.prevent="registerObservation">
          <RgValidatorForm ref="validator">
            Paciente:
            <strong>
              {{ hospitalizationsData && hospitalizationsData.pes_nome }}
            </strong>

            <div class="textarea">
              <RgTextArea
                ref="reason"
                v-model="form.reason"
                :rules="{ required: true }"
                :maxlength="500"
                label="Motivo"
                class="text-area"
              />
            </div>

            <div class="textarea">
              <RgTextArea
                ref="observation"
                v-model="form.observation"
                :rules="{ required: true }"
                :maxlength="500"
                label="Observação"
                class="text-area"
              />
            </div>

            <div class="actions">
              <RgCleanButton title="Limpar" class="clean" @click="cleanForm" />

              <RgAddButton
                :disabled="disabledSave"
                :class="{ disable: disabledSave }"
                large
                title="Adicionar observação"
                class="add"
                @click="registerObservation"
              />
            </div>

            <div v-if="hasObservations" class="table">
              <table class="table-data unselect">
                <tr class="header">
                  <th class="title check"></th>
                  <th class="title">Motivo</th>
                  <th class="title">Observação</th>
                  <th class="title">Usuário</th>
                  <th class="title">Data</th>
                  <th class="title">Hora</th>
                </tr>

                <tr
                  v-for="(item, index) in mutableListPatientObservationsQueue"
                  :key="index"
                  :class="{ active: selectedRows.includes(item.fio_id) }"
                  class="data-table"
                  @click="getValue(item, index)"
                >
                  <td class="icon-check">
                    <div
                      v-if="selectedRows.includes(item.fio_id)"
                      class="check"
                    >
                      <IconCheck />
                    </div>
                  </td>

                  <td class="data">
                    {{ item ? removeHtmlTag(item.fio_motivo) : "" }}
                  </td>

                  <td class="data">
                    {{ item ? removeHtmlTag(item.fio_observacao) : "" }}
                  </td>

                  <td class="data">
                    {{ item ? item.usu_nome : "" }}
                  </td>

                  <td class="data">
                    {{ item ? item.data_observacao : "" }}
                  </td>

                  <td class="data">
                    {{ item ? item.hora_observacao : "" }}
                  </td>
                </tr>
              </table>
            </div>

            <div v-else class="no-result">
              <IconEmpty />
              <span class="message"> Não foram encontradas observações </span>
            </div>

            <div class="actions-footer">
              <RgShowButton
                :disabled="disableView"
                :class="{ disable: disableView }"
                small
                title="Visualizar observação"
                class="buttons"
                @click="openModalViewObservation"
              />
              <RgPrinterButton
                :disabled="selectedRows.length < 1"
                :class="{ disable: selectedRows.length < 1 }"
                small
                title="Imprimir observação"
                class="buttons"
                @click="print"
              />
            </div>
          </RgValidatorForm>
        </form>
      </FormBase>
    </div>

    <div slot="footer" class="footer">
      <RgBackButton
        medium
        :disabled="disabledByModal"
        title="Voltar"
        @click="comeBackPage"
      />
    </div>

    <ModalViewObservation
      ref="registerObservation"
      :show="modalRegisterObservation"
      :observation-data="hospitalizationsData"
      :view-data="hospitalizationViewData"
      :view-observations="view"
      @close="closeModalViewObservation"
    />
  </Modulebox>
</template>

<script>
import {
  RgAddButton,
  RgTextArea,
  RgPrinterButton,
  RgShowButton,
  IconEmpty,
  RgCleanButton,
  RgValidatorForm,
  IconCheck,
  RgBackButton,
} from "~tokio/primitive";
import { Modulebox } from "~tokio/foundation";
import ModalViewObservation from "$hospitalization/hospitalizations/queue/component/modal/modal-view-observation/ModalViewObservation";
import ScheduleObservationPatientQueueHTML from "$hospitalization/hospitalizations/queue/html/ScheduleObservationPatientQueueHTML";
import FormBase from "~tokio/foundation/form-base/FormBase";

import { AlertError } from "~tokio/primitive/notification";

export default {
  name: "HospitalizationPatientObservationQueue",
  components: {
    ModalViewObservation,
    Modulebox,
    RgAddButton,
    RgPrinterButton,
    RgShowButton,
    IconEmpty,
    FormBase,
    RgTextArea,
    RgCleanButton,
    RgValidatorForm,
    IconCheck,
    RgBackButton,
  },

  data() {
    return {
      hospitalizationsData: null,
      hospitalizationViewData: null,
      selectedRows: [],
      listSelected: [],
      valueRow: null,
      mutableListPatientObservationsQueue: null,
      modalRegisterObservation: false,
      activeRow: false,
      view: false,
      printer: [],
      printerArray: [],
      form: {
        reason: "",
        observation: "",
      },
    };
  },
  computed: {
    getPermissionSaveObservations() {
      return this.$Permissions.global.has(
        "internacao.filaInternacao.incluirObservacao",
      );
    },

    hasObservations() {
      return (
        this.mutableListPatientObservationsQueue &&
        this.mutableListPatientObservationsQueue.length >= 1
      );
    },

    disableView() {
      return this.selectedRows && this.selectedRows.length !== 1;
    },

    disabledSave() {
      return this.form.reason.length < 5;
    },
  },

  mounted() {
    this.hospitalizationsData = this.$route.params.hospitalizationsData;
    this.searchFilter(this.hospitalizationsData);
  },

  methods: {
    async searchFilter(pData) {
      this.mutableListPatientObservationsQueue = await this.$store.dispatch(
        "Hospitalization/Queue/SEARCH_PATIENT_OBSERVATION_QUEUE",
        {
          arrFiltro: {
            fil_id: pData.fil_id,
            pes_id: pData.pes_id,
            pes_nome: pData.pes_nome,
            tipos_leito: "",
            limiteFim: 1000000,
            limiteInicio: 0,
          },
        },
      );

      this.mutableListPatientObservationsQueue = this.mutableListPatientObservationsQueue.data;
    },

    getValue(pItem, pIndex) {
      if (this.selectedRows.includes(pItem.fio_id)) {
        this.selectedRows.splice(this.selectedRows.indexOf(pItem.fio_id), 1);
        // this.listSelected.splice(pItem, 1);
        this.listSelected = this.listSelected.filter(function (observation) {
          return observation.fio_id !== pItem.fio_id;
        });
        this.filId = Number(pItem.fio_id) || null;
      } else {
        this.selectedRows.push(pItem.fio_id);
        this.listSelected.push(pItem);
        this.filId = Number(pItem.fio_id) || null;
      }
    },

    isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    async registerObservation() {
      if (!(await this.isFormValid())) {
        return AlertError("Verifique os Campos");
      } else {
        if (this.form.reason.length < 5) {
          this.$toaster.warning(
            "Preencha no mínimo 5 caracteres no campo motivo.",
          );
          return;
        }

        if (this.form.observation.length < 5) {
          this.$toaster.warning(
            "Preencha no mínimo 5 caracteres no campo observação.",
          );
          return;
        }

        const fil_id = this.hospitalizationsData.fil_id;

        const variables = {
          fio_id_filas: fil_id,
          fio_motivo: this.form.reason,
          fio_observacao: this.form.observation,
        };

        try {
          if (this.getPermissionSaveObservations) {
            await this.$store.dispatch(
              "Hospitalization/Queue/SAVE_OBSERVATION_QUEUE",
              variables,
            );
            this.searchFilter(this.hospitalizationsData);
            this.$toaster.success("Observação salva com sucesso!");
            this.cleanForm();
          }
        } catch (error) {
          this.$toaster.error(error);
          this.cleanForm();
        }
      }
    },

    async print() {
      const client = this.$store.getters["Login/GET_CLIENT_CODE"];
      const hospitalizationsData = { client, ...this.hospitalizationsData };
      const html = ScheduleObservationPatientQueueHTML(
        hospitalizationsData,
        this.listSelected,
      );
      this.$utils.print.printHtml(html);
      this.printerArray = [];
    },

    removeHtmlTag(pValue) {
      const regex = /(&nbsp;|<([^>]+)>)/gi;
      return pValue.replace(regex, "");
    },

    openModalViewObservation() {
      this.view = true;
      this.modalRegisterObservation = true;
      this.hospitalizationViewData = this.listSelected[0];
    },

    closeModalViewObservation(pActionTaken = false) {
      this.modalRegisterObservation = false;
      this.view = false;
      this.reSearchAfterCloseModal(pActionTaken);
    },

    comeBackPage() {
      this.$router.go(-1);
    },

    cleanForm() {
      this.form = {
        reason: "",
        observation: "",
      };
      this.$refs.reason.cleanValidate();
      this.$refs.reason.resetCharacter();
      this.$refs.observation.cleanValidate();
      this.$refs.observation.resetCharacter();
    },
  },
};
</script>
