<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import getData from "./action";
export default {
  name: "RgComboboxTransferPlace",
  mixins: [RgComboboxMixin],
  props: {
    intIdSetor: {
      required: true,
    },
  },
  watch: {
    intIdSetor() {
      this.fillData();
    },
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      if (!this.intIdSetor) return Promise.resolve([]);

      this.mData = [];

      this.disableLoading = true;
      const data = await getData({
        intIdSetor: this.intIdSetor,
      });

      data.forEach((item) => {
        this.mData.push({ item: item, value: item.data, text: item.label });
      });
      this.disableLoading = false;
    },
  },
};
</script>
