<template lang="html">
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26ZM9.2254 7.38182C8.71631 6.87273 7.89091 6.87273 7.38182 7.38182C6.87273 7.89091 6.87273 8.71631 7.38182 9.2254L11.377 13.2206L7.38337 17.2142C6.87428 17.7233 6.87428 18.5487 7.38338 19.0578C7.89247 19.5669 8.71787 19.5669 9.22696 19.0578L13.2206 15.0642L17.2142 19.0578C17.7233 19.5669 18.5487 19.5669 19.0578 19.0578C19.5669 18.5487 19.5669 17.7233 19.0578 17.2142L15.0642 13.2206L19.0594 9.2254C19.5685 8.71631 19.5685 7.89091 19.0594 7.38182C18.5503 6.87273 17.7249 6.87273 17.2158 7.38182L13.2206 11.377L9.2254 7.38182Z"
      fill="#2B5D8A"
    />
  </svg>
</template>

<script>
export default {
  name: "Close",
};
</script>
