<script>
import { RgRemoteTypeaheadMixin } from "~tokio/primitive";
import SurgeryEquipmentModel from "$surgery-center/surgery/model/SurgeryEquipmentModel";
export default {
  name: "RgTypeaheadSurgeryEquipment",
  mixins: [RgRemoteTypeaheadMixin],
  props: {
    label: {
      default: "Equipamento",
    },
    min: {
      default: "2",
    },
  },
  data() {
    return {
      model: SurgeryEquipmentModel,
      filter: () => {
        return {
          tie_nome: this.inputValue,
        };
      },
    };
  },
  methods: {
    formatItem(item) {
      return {
        value: item.tie_id,
        text: item.tie_nome,
      };
    },
  },
};
</script>
