<style src="./RgSuggestSmartPerson.scss" lang="scss" scoped></style>
<template>
  <div class="rg-suggest-patient">
    <RgSuggest
      ref="innerSuggest"
      v-model="inputValue"
      :disabled="disabled"
      :data-id="dataId"
      :placeholder="placeholderSuggest"
      :rules="{ ...rules, fn: validateField }"
      :label="labelSuggest"
      :confirmToRemove="confirmToRemove"
      :message="message"
      :mask="mask"
      min="1"
      @itemSelected="clearMask"
      @clean="setMask"
    >
      <RgSuggestItem
        v-for="(item, idx) in suggestionList"
        :key="idx"
        :data-item="dataItem"
        :idx="idx"
        :item="item"
        class="patient-item"
        @itemSelected="clearMask"
      >
        <!-- <div> -->
        <span
          :class="{ 'field-search': colFilter.col === 'peopleName' }"
          class="pes-name"
          title="Nome"
        >
          <IconUser />
          <span class="mg-l-5"
            >Nome: <strong>{{ item.pes_nome }}</strong></span
          >
        </span>

        <div :class="{ 'field-search': colFilter.col === 'birthDate' }">
          <IconCalendar />
          <span class="mg-l-5"
            >Data de Nascimento :
            <strong>{{ item.pes_nascimento | dateBR }}</strong></span
          >
        </div>
        <!-- </div> -->

        <span class="pes-mother" title="Nome da Mãe">
          <IconGenderFemale />
          <span class="mg-l-5"
            >Nome da mãe: <strong>{{ item.pes_mae }}</strong></span
          >
        </span>

        <div class="group">
          <div :class="{ 'field-search': colFilter.col === 'cns' }">
            <IconCard />
            <span class="mg-l-5"
              >CNS:
              <strong>{{ item.cns && item.cns.crs_numero | Cns }}</strong></span
            >
          </div>

          <div :class="{ 'field-search': colFilter.col === 'cpf' }">
            <IconCard />
            <span class="mg-l-5"
              >CPF: <strong>{{ item.cpf.cpf_numero | Cpf }}</strong></span
            >
          </div>
        </div>

        <div :class="{ 'field-search': colFilter.col === 'recordNumber' }">
          <IconCard />
          <span class="mg-l-5"
            >Prontuário Único:
            <strong>{{
              item.patient ? item.patient.pac_prontuario_unico : ""
            }}</strong></span
          >
        </div>
      </RgSuggestItem>
    </RgSuggest>

    <div v-if="showOptionRadios" class="filter-suggest-patient">
      <div v-for="(item, idx) in validFilters" :key="idx" class="input">
        <input
          v-model="colFilter"
          :value="item"
          :disabled="disabled"
          :checked="idx === 0"
          type="radio"
          name="filter"
        />
        <span class="align-span">
          {{ item.label }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { isEmpty } from "lodash";
import { RgSuggest, RgSuggestMixin, RgSuggestItem } from "~tokio/primitive";
import {
  IconCalendar,
  IconGenderFemale,
  IconUser,
  IconCard,
} from "~tokio/primitive/icon";
import { SearchPerson, SearchPersonById } from "./action";
import moment from "moment";

export default {
  name: "RgSuggestSmartPerson",
  components: {
    RgSuggest,
    RgSuggestItem,
    IconCalendar,
    IconGenderFemale,
    IconCard,
    IconUser,
  },
  filters: {
    Cns(pValue) {
      if (!pValue) return "CNS NÃO CADASTRADO";
      return pValue.replace(/(\d{3})(\d{4})(\d{4})(\d{4})/gi, "$1 $2 $3 $4");
    },

    Cpf(pValue) {
      if (!pValue) return "CPF NÃO CADASTRADO";
      return pValue;
    },

    dateBR(pValue) {
      const isValid = moment(pValue).isValid();
      return isValid
        ? moment(pValue).format("DD/MM/YYYY")
        : "Data não informada";
    },
  },
  mixins: [RgSuggestMixin],
  props: {
    dataItem: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    removeFilter: {
      type: Array,
      default: () => [],
    },
    enabledPatient: {
      type: Boolean,
      default: false,
    },
    withPatientModule: {
      type: Array,
      default: function () {
        return [];
      },
    },
    extraData: {
      type: Array,
      default: function () {
        return [];
      },
    },
    showOptionRadios: {
      type: Boolean,
      default: true,
    },

    permitFreeText: {
      type: Boolean,
      default: false,
    },

    filterOnlyDocument: Boolean,
  },
  data() {
    return {
      captionRaw: "pes_nome",
      colFilter: { label: "Nome", col: "peopleName" },
      filters: [
        { label: "Nome", col: "peopleName" },
        { label: "Prontuário Único", col: "recordNumber" },
        { label: "CPF", col: "cpf" },
        { label: "CNS", col: "cns" },
      ],
      // { label: "Data de Nascimento", col: "birthDate" },
      documentFilters: [
        { label: "Prontuário Único", col: "recordNumber" },
        { label: "CPF", col: "cpf" },
        { label: "CNS", col: "cns" },
      ],
      mask: null,
      validfilters: [],
    };
  },
  computed: {
    existFilter() {
      return this.hasFilter && this.hasFilter.length > 0;
    },
    labelSuggest() {
      this.reset();
      if (this.filterOnlyDocument) {
        return "Documentação";
      } else {
        switch (this.colFilter.col) {
          case "peopleName":
            return "Nome do Paciente";
          case "cpf":
            return "CPF do Paciente";
          case "cns":
            return "CNS do Paciente";
          case "recordNumber":
            return "Prontuário Único do Paciente";
          // case "birthDate":
          //   return "Data de Nascimento";
          default:
            return "";
        }
      }
    },
    placeholderSuggest() {
      switch (this.colFilter.col) {
        case "peopleName":
          return "Digite o nome do paciente";
        case "cpf":
          return "Digite o CPF do paciente";
        case "cns":
          return "Digite o CNS do paciente";
        case "recordNumber":
          return "Digite o prontuário único do paciente";
        // case "birthDate":
        //   return "Digite a data de nascimento do paciente";
        default:
          return "Documento";
      }
    },
    withPatientAppointment() {
      return this.withPatientModule.includes("appointment");
    },
    withPatientExam() {
      return this.withPatientModule.includes("exam2");
    },
    withPatientHospitalization() {
      return this.withPatientModule.includes("hospitalization");
    },
    withPatientEmergency() {
      return this.withPatientModule.includes("emergency");
    },
    withTelephone() {
      return this.extraData.includes("telephone");
    },
    withEthnicity() {
      return this.extraData.includes("ethnicity");
    },
    withNaturality() {
      return this.extraData.includes("naturality");
    },
    withNationality() {
      return this.extraData.includes("nationality");
    },
    withoutAddress() {
      return this.extraData.includes("withoutAddress");
    },
  },
  watch: {
    "colFilter.col"(pValue) {
      this.setMask();
    },
    inputValue(pValue) {
      if (!pValue) {
        this.cleanValidate();
      }
    },
  },
  created() {
    this.setDefaultFilter();
  },

  mounted() {
    this.setMask();
  },

  methods: {
    setDefaultFilter() {
      if (this.filterOnlyDocument) {
        this.validFilters = this.documentFilters;
      } else {
        this.validFilters = this.filters.filter(
          (filter) =>
            !this.removeFilter.find((remove) => remove.label === filter.label),
        );
      }
      this.colFilter = this.validFilters && this.validFilters[0];
    },

    emitData(pData) {
      this.setDefaultFilter();

      this.$refs.innerSuggest.itemSelected = true;

      const withoutSuggestionList =
        !this.suggestionList ||
        (this.suggestionList && this.suggestionList.length === 0);

      if (withoutSuggestionList) {
        this.suggestionList.push({
          ...pData,
        });
      }

      this.$refs.innerSuggest.$parent.selectingItemFromSuggestList(pData);
    },

    doSearch(pSearchTerm, pLimit = null) {
      const hasValidMask =
        (this.mask && this.colFilter.col === "recordNumber") ||
        (this.mask && pSearchTerm && pSearchTerm.length === this.mask.length);

      if (!this.mask || hasValidMask) {
        const variables = this.mountFilter(pSearchTerm);

        variables.limit = pLimit;
        variables.withPatientAppointment = this.withPatientAppointment;
        variables.withPatientExam = this.withPatientExam;
        variables.withPatientHospitalization = this.withPatientHospitalization;
        variables.withPatientEmergency = this.withPatientEmergency;

        variables.withTelephone = this.withTelephone;
        variables.withEthnicity = this.withEthnicity;
        variables.withNaturality = this.withNaturality;
        variables.withNationality = this.withNationality;
        variables.withoutAddress = this.withoutAddress;

        variables.active = true;

        if (this.enabledPatient) variables.isSearchable = this.enabledPatient;

        return SearchPerson(variables);
      }
      return null;
    },

    mountFilter(pSearchTerm) {
      switch (this.colFilter.col) {
        case "cpf":
          return { cpf: pSearchTerm.replace(/\.|-/gm, "") };
        case "cns":
          return { cns: pSearchTerm.replace(/\.|-/gm, "") };
        case "recordNumber":
          return { recordNumber: pSearchTerm.toString().padStart(10, "0") };
        case "birthDate":
          return { birthDate: this.formatDate(pSearchTerm) };
        default:
          return { peopleName: pSearchTerm };
      }
    },

    async fillPatientById(pPersonId) {
      const variables = {
        pesId: Number(pPersonId),
        withPatientAppointment: this.withPatientAppointment,
        withPatientExam: this.withPatientExam,
        withPatientHospitalization: this.withPatientHospitalization,
        withPatientEmergency: this.withPatientEmergency,
        withTelephone: this.withTelephone,
        withEthnicity: this.withEthnicity,
        withNaturality: this.withNaturality,
        withNationality: this.withNationality,
      };

      const patientData = await SearchPersonById(variables);

      const objPatient = await this.mountObjectPatient(patientData);

      this.forceSelection(objPatient);

      this.$store.commit("Person/Patient/DESTROY_LAST_PERSON_ID_SAVE");

      return patientData;
    },

    async mountObjectPatient(patientData) {
      const mun_nome =
        patientData.address?.neighborhood?.city?.mun_nome ||
        patientData.mun_nome;

      const objPatient = {
        pes_id: patientData.pes_id,
        pes_nome: patientData.pes_nome,
        pac_nome: patientData.pes_nome,
        mun_nome: mun_nome,
        pes_nascimento: patientData.pes_nascimento,
        sex_sigla: patientData.gender.sex_sigla,
        pes_mae: patientData.pes_mae,
        pes_pai: patientData.pes_pai,
        cpf: patientData && patientData.cpf ? patientData.cpf : null,
        pac_prontuario_unico:
          patientData && patientData.patient
            ? patientData.patient.pac_prontuario_unico
            : null,
        cns: {
          crs_numero:
            patientData && patientData.cns ? patientData.cns.crs_numero : null,
        },
        patient: {
          pac_id:
            patientData && patientData.patient
              ? patientData.patient.pac_id
              : null,
          pac_prontuario_unico:
            patientData && patientData.patient
              ? patientData.patient.pac_prontuario_unico
              : null,
        },
        address: {
          end_cep: patientData.address?.end_cep,
          end_complemento: patientData.address?.end_complemento,
          end_logradouro: patientData.address?.end_logradouro,
          end_numero: patientData.address?.end_numero,
          neighborhood: {
            bai_id: patientData.address?.neighborhood?.bai_id,
            bai_nome: patientData.address?.neighborhood?.bai_nome,
            city: {
              mun_nome: patientData.address?.neighborhood?.city?.mun_nome,
            },
          },
          publicPlaceType: {
            tlg_id: patientData.address?.publicPlaceType?.tlg_id,
            tlg_nome: patientData.address?.publicPlaceType?.tlg_nome,
          },
        },
      };

      if (this.withPatientAppointment) {
        const auxData = patientData.patient.patient_appointment;

        objPatient.patient.patient_appointment = {
          pcc_ativo: auxData.pcc_ativo,
          pcc_id: auxData.pcc_id,
          pcc_id_pacientes: auxData.pcc_id_pacientes,
        };
      }

      if (this.withPatientExam) {
        const auxData = patientData.patient.patient_exam;

        objPatient.patient.patient_exam = {
          pxe_id: auxData.pxe_id,
          pxe_id_pacientes: auxData.pxe_id_pacientes,
        };
      }

      if (this.withPatientHospitalization) {
        const auxData = patientData.patient.patient_hospitalization;

        objPatient.patient.patient_hospitalization = {
          pci_ativo: auxData.pci_ativo,
          pci_id: auxData.pci_id,
          pci_id_pacientes: auxData.pci_id_pacientes,
        };
      }

      if (this.withPatientEmergency) {
        const auxData = patientData.patient.patient_emergency;

        objPatient.patient.patient_emergency = {
          pce_ativo: auxData.pce_ativo,
          pce_id: auxData.pce_id,
          pce_id_pacientes: auxData.pce_id_pacientes,
        };
      }

      if (this.withTelephone) {
        const auxData = patientData.telephones.rows;

        objPatient.telephones = {
          rows: auxData,
        };
      }

      if (this.withEthnicity) {
        const auxData = patientData.ethnicity;

        objPatient.ethnicity = {
          etn_codigo_sisaih: auxData.etn_codigo_sisaih,
          etn_id: auxData.etn_id,
          etn_nome: auxData.etn_nome,
        };
      }

      if (this.withNaturality) {
        const auxData = patientData.city;

        objPatient.city = {
          mun_id: auxData.mun_id,
          mun_nome: auxData.mun_nome,
          state: {
            est_id: auxData.state?.est_id,
            est_nome: auxData.state?.est_nome,
            est_sigla: auxData.state?.est_sigla,
          },
        };
      }

      if (this.withNationality) {
        const auxData = patientData.nationality;

        objPatient.nationality = {
          nac_id: auxData.nac_id,
          nac_nome: auxData.nac_nome,
        };
      }

      return objPatient;
    },

    forceSelection(pValue) {
      const hasPersonPatientData =
        pValue &&
        !isEmpty(pValue.pes_nome) &&
        pValue.patient &&
        pValue.patient.pac_id > 0;

      if (this.permitFreeText) {
        this.suggestionList.push({
          pes_nome: pValue.pes_nome,
        });

        this.$refs.innerSuggest.$parent.selectingItemFromSuggestList(pValue);
      } else if (hasPersonPatientData) {
        this.$refs.innerSuggest.itemSelected = true;

        const withoutSuggestionList =
          !this.suggestionList ||
          (this.suggestionList && this.suggestionList.length === 0);

        if (withoutSuggestionList) {
          this.suggestionList.push({
            pes_nome: pValue.pes_nome,
            patient: {
              pac_id: pValue.patient.pac_id,
            },
            ...pValue,
          });
        }
        this.$refs.innerSuggest.$parent.selectingItemFromSuggestList(pValue);
      }
    },

    validate() {
      this.$refs.innerSuggest.validate();
    },

    cleanValidate() {
      this.$refs.innerSuggest.cleanValidate();
    },

    reset() {
      this.inputValue = null;
      if (this.$refs.innerSuggest) {
        this.$refs.innerSuggest.resetSuggestionList();
        this.$refs.innerSuggest.$refs.input.focus();
      }
    },

    formatDate(pDate) {
      return this.$utils.date.BrazilianDateToDatabase(pDate);
    },

    clearMask() {
      this.mask = null;
    },

    validateField(pValue, pErrors) {
      if (!pValue) {
        return true;
      }

      const format = /[!@#$%^&*()_+\-={};':"\\|,.|0-9]+/;
      if (this.colFilter.col === "peopleName" && format.test(pValue)) {
        pErrors.push("Não pode conter números e nem caracteres especiais.");
        return false;
      }

      if (this.permitFreeText && pValue.length < 2) {
        pErrors.push("Mínimo de 2 caracteres.");
        return false;
      }

      if (
        this.colFilter.col === "cpf" &&
        !this.$utils.app.Document.verifyCpfNumber(pValue)
      ) {
        pErrors.push(" CPF Inválido.");
        return false;
      }

      if (
        this.colFilter.col === "cns" &&
        !this.$utils.app.Document.verifyCnsNumber(pValue)
      ) {
        pErrors.push(" CNS Inválido.");
        return false;
      }

      return true;
    },

    setMask() {
      switch (this.colFilter.col) {
        case "cpf":
          this.mask = "###########";
          this.captionRaw = "cpf.cpf_numero";
          break;
        case "cns":
          this.mask = "###############";
          this.captionRaw = "cns.crs_numero";
          break;
        // case "birthDate":
        //   this.mask = "##/##/####";
        //   this.captionRaw = "pes_nascimento";
        //   break;
        case "recordNumber":
          this.mask = "################";
          this.captionRaw = "patient.pac_prontuario_unico";
          break;
        default:
          this.mask = null;
          this.captionRaw = "pes_nome";
      }
    },
  },
};
</script>
