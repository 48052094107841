<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
export default {
  name: "RgComboboxNotEffectivatedReason",
  mixins: [RgComboboxMixin],
  data: () => {
    return {};
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];
      const data = await this.$store.dispatch(
        "Exam/IndividualEffectivation/GET_NOT_EFFECTIVATE_REASONS",
      );
      data.rows.forEach((item) => {
        this.mData.push({ value: item.emn_id, text: item.emn_nome });
      });
    },
  },
};
</script>
