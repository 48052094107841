import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchHospitalizationDestinationPlace($ild_nome: String, $limit: Int) {
    hospitalizationLocaleDestiny(ild_nome: $ild_nome, limit: $limit) {
      rows {
        ild_id
        ild_nome
      }
    }
  }
`;

export default async (variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    return data.hospitalizationLocaleDestiny;
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors);
  }
};
