import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async ({ set_id }) => {
  return AuthLegacyRequest.post(
    "unidadesaude/controller-local-atendimento/combo-box-locais-atendimento-por-setor-pep",
    { set_id },
  )
    .then(({ data }) => {
      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
