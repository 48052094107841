const state = {
  listItems: [],
  filterListBy: {
    limit: 300,
    // offset: 0,
    cci_id_status_controles_cirurgicos: [1],
  },
  selectedItem: null,
  stages: [],
  calendar: {
    sector: null,
    actualDate: null,
    config: {
      startTime: 1501459200000,
      endTime: 1501545599000,
    },
    stageColumnContainer: {
      posLeft: 0,
      stageInsideWidth: 0,
      stageContainerWidth: 0,
    },
    calendarMap: {
      sala1: [
        {
          startTime: 1501855200000, // 14:00:00
          surgeries: [
            {
              id: 8,
              main: true,
              clinic: "ORTOPEDIA",
              medicalRecord: "201105050123",
              duration: 30,
              medic: "Dr. who",
              name: "Jhon doe",
            },
            {
              id: 9,
              clinic: "OTORRINOLARINGOLOGIA",
              medicalRecord: "201105056589",
              duration: 30,
              medic: "Dr. who",
              name: "Jane doe",
            },
          ],
        },
        {
          startTime: 1501857000000, // 14:30:00
          surgeries: [
            {
              id: 10,
              main: true,
              clinic: "OTORRINOLARINGOLOGIA",
              medicalRecord: "201105056589",
              duration: 85,
              name: "Jhonny",
            },
          ],
        },
      ],
    },
  },
};

export default state;
