export default async ({ commit }) => {
  commit("SET_PATIENT_SEARCHED", true);

  commit("DESTROY_HOSPITALIZATION_AIH_INFO");
  commit("DESTROY_PATIENT_INFO_TAB");
  commit("DESTROY_HOSPITALIZATION_BILLING_INFO_TAB");

  commit("SET_HOSPITALIZATION_ID", null);
  commit("SET_HOSPITALIZATION_BILLING_AIH_ID", null);
  commit("SET_PATIENT_UNIT_RECORD_NUMBER", null);
  commit("SET_HOSPITALIZATION_BILLING_INFO_TAB", null);
  commit("SET_HOSPITALIZATION_BILLING_DIAGNOSTICS_INFO", null);
  commit("SET_HOSPITALIZATION_AIH_PROCEDURES", null);
};
