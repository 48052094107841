<template>
  <Modulebox
    :granted="hasPermission"
    :title="isEdition ? `Editar Fila` : `Inserir na Fila`"
    class="hospitalization-registration-queue"
  >
    <div class="hospitalization-registration-queue-body">
      <form class="form-container" @submit.stop.prevent="saveForm">
        <RgValidatorForm ref="validator">
          <FormBase title="Paciente" class="module-patient">
            <div class="content-inputs">
              <RgSuggestPatientInformation
                ref="patientInfoSuggest"
                v-model="suggestPatient"
                :rules="{ forceSelection: true, required: true }"
                :disabledOnlySuggest="isEdition"
                :with-patient-module="['hospitalization']"
                :pac_prontuario_unico="
                  patientInfo && patientInfo.prontuario_unico
                "
                :enabled-patient="false"
                :is-patient="false"
                :extra-data="['withoutAddress']"
                class="patient-input"
                @patientInfo="getPatientData"
              />
            </div>
          </FormBase>

          <FormBase
            title="Dados da Regulação"
            class="module-regulation"
            :class="{ disable: !hasIdPatient }"
          >
            <div class="content-grid">
              <div class="content-grid row-1">
                <RgInputDate
                  v-model="form.entryDate"
                  :disabled="true"
                  tabIndex="-1"
                  :class="{ disable: true }"
                  label="Data de Entrada"
                  placeholder="dd/mm/aaaa"
                />

                <RgInputHour
                  v-model="form.entryHour"
                  :disabled="true"
                  tabIndex="-1"
                  :class="{ disable: true }"
                  label="Hora de Entrada"
                />

                <RgSuggestTypeBed
                  ref="typeBed"
                  v-model="form.bedType"
                  :rules="{ required: true, forceSelection: true }"
                  :disabled="!hasIdPatient"
                  :class="{
                    disable: !hasIdPatient,
                  }"
                  @selected="selectingBedType"
                />

                <RgComboboxWaitingPlace
                  v-model="form.waitingPlace"
                  :disabled="!hasIdPatient"
                  :class="{ disable: !hasIdPatient }"
                  label="Local de Espera"
                />

                <RgComboboxUnithealthExternal
                  ref="unitInsertion"
                  v-model="form.originUnit"
                  :disabled="!hasIdPatient"
                  :class="{
                    disable: !hasIdPatient,
                  }"
                  :tabIndex="!hasIdPatient ? '-1' : '0'"
                  label="Unidade Externa Solicitante"
                />
              </div>

              <div class="row-2 content-grid">
                <RgSuggestHospitalizationQueueProcedures
                  ref="procedure1"
                  v-model="form.mainProcedure"
                  :patient-gender="patientInfo.sex_sigla"
                  :rules="{ forceSelection: true }"
                  :disabled="!hasIdPatient"
                  :class="{
                    disable: !hasIdPatient,
                  }"
                  data-id="procedimento"
                  data-item="procedimento-item"
                  placeholder="Informe o Procedimento"
                  label="Procedimento Principal"
                  @selected="selectingMainProcedure"
                />
                <RgSuggestHospitalizationQueueProcedures
                  ref="procedure2"
                  v-model="form.secondProcedure"
                  :patient-gender="patientInfo.sex_sigla"
                  :rules="{ forceSelection: true }"
                  :disabled="!hasIdPatient || !hasMainProcedure"
                  :class="{
                    disable: !hasIdPatient || !hasMainProcedure,
                  }"
                  data-id="procedimento"
                  data-item="procedimento-item"
                  placeholder="Informe o Procedimento"
                  label="Procedimento - 2"
                  @selected="selectingSecondProcedure"
                />
                <RgSuggestHospitalizationQueueProcedures
                  ref="procedure3"
                  v-model="form.thirdProcedure"
                  :patient-gender="this.patientInfo.sex_sigla"
                  :rules="{ forceSelection: true }"
                  :disabled="!hasIdPatient || !hasSecondProcedure"
                  :class="{
                    disable: !hasIdPatient || !hasSecondProcedure,
                  }"
                  data-id="procedimento"
                  data-item="procedimento-item"
                  placeholder="Informe o Procedimento"
                  label="Procedimento - 3"
                  @selected="selectingThirdProcedure"
                />
              </div>

              <div class="row-3 content-grid">
                <RgSuggestCid
                  ref="mainCid"
                  v-model="form.mainCid"
                  :rules="{ forceSelection: true }"
                  :disabled="!hasIdPatient || !hasMainProcedure"
                  :class="{
                    disable: !hasIdPatient || !hasMainProcedure,
                  }"
                  :procedureCode="inp_novo_codigo_procedimento"
                  data-id="CID"
                  label="CID10 Principal"
                  @selected="selectingMainCid"
                />

                <RgSuggestCid
                  ref="secondCid"
                  v-model="form.secondCid"
                  :rules="{ forceSelection: true }"
                  :disabled="!hasIdPatient || !hasMainCid"
                  :class="{
                    disable: !hasIdPatient || !hasMainCid,
                  }"
                  data-id="CID"
                  label="CID10 - 2"
                  @selected="selectingSecondCid"
                />

                <RgSuggestCid
                  ref="thirdCid"
                  v-model="form.thirdCid"
                  :rules="{ forceSelection: true }"
                  :disabled="!hasIdPatient || !hasSecondCid"
                  :class="{
                    disable: !hasIdPatient || !hasSecondCid,
                  }"
                  data-id="CID"
                  label="CID10 - 3"
                  @selected="selectingThirdCid"
                />
              </div>
            </div>
          </FormBase>

          <FormBase
            title="Dados da Solicitação"
            class="module-solicitation"
            :class="{ disable: !hasIdPatient }"
          >
            <div class="content-grid">
              <RgSuggestEmployee
                ref="employee"
                v-model="requestingProfessional"
                label="Profissional Solicitante"
                :disabled="!hasIdPatient || isEdition"
                :class="{ disable: !hasIdPatient || isEdition }"
                :rules="{ required: true }"
                :maxlength="100"
                @selected="selectingProfessional"
                @clear="form.classCouncil = null"
              />

              <RgInput
                v-model="form.classCouncil"
                label="Conselho de Classe"
                :rules="{ required: !form.requestingProfessionalId }"
                :disabled="disableClassCouncil || isEdition"
                :class="{ disable: disableClassCouncil || isEdition }"
                :maxlength="20"
              />

              <RgComboboxUnithealth
                ref="unitSolicitation"
                v-model="form.requesterUnit"
                :disabled="!hasIdPatient"
                :class="{ disable: !hasIdPatient }"
                :tabIndex="!hasIdPatient ? '-1' : '0'"
                :rules="{ required: true }"
                label="Unidade de Saúde Solicitante"
              />

              <RgComboboxPriority
                ref="priority"
                v-model="form.priority"
                :rules="{ required: true }"
                :module-id="hospitalizationModuleId"
                :disabled="!hasIdPatient"
                :tabIndex="!hasIdPatient ? '-1' : '0'"
                :class="{ disable: !hasIdPatient }"
                label="Prioridade"
                class="inputitem"
              />

              <RgInputDate
                ref="date"
                v-model="form.requestDate"
                :rules="{ maxTodayDate: true, required: true }"
                :tabIndex="!hasIdPatient ? '-1' : '0'"
                label="Data da Solicitação"
                placeholder="dd/mm/aaaa"
                :disabled="isEdition"
                :class="{ disable: isEdition }"
                :max-date="new Date()"
              />
            </div>

            <div class="area" :class="{ disable: !hasIdPatient }">
              <div class="cbo-area">
                <p class="label">Tipos de Leito</p>
                <div class="table-area">
                  <div
                    v-for="(queuePerPerson, id) in queuesPerPerson"
                    :key="id"
                    class="item"
                  >
                    {{ queuePerPerson.nome }}
                  </div>
                </div>
              </div>

              <RgTextArea
                v-if="!isEdition"
                ref="textArea"
                v-model="form.observations"
                :maxlength="500"
                :disabled="!hasIdPatient"
                :class="{ disable: !hasIdPatient }"
                label="Observações"
                class="inputbox"
              />
            </div>
          </FormBase>
        </RgValidatorForm>
      </form>
    </div>

    <div class="footer">
      <RgCleanButton
        v-show="!isEdition"
        small
        class="clean"
        title="Limpar"
        @click="showConfirmFieldClearing"
      />

      <RgCancelButton
        v-if="showBackButton"
        ref="cancelButton"
        medium
        class="cancel"
        @click="cancel"
      />

      <RgSaveButton
        medium
        class="save"
        :disabled="!hasIdPatient"
        @click="confirmSaveForm(false)"
      />

      <MediumButton
        v-if="!isEdition"
        medium
        class="save"
        label="Salvar e inserir arquivo"
        :disabled="!hasIdPatient"
        @click="confirmSaveForm(true)"
      />
    </div>

    <ModalConfirmFieldClearing
      ref="confirmClearing"
      :show="modalConfirmFieldClearing"
      @getYes="confirmCleanForm"
      @getOut="closeConfirmFieldClearing"
    />

    <ModalTypePrintInsertQueue
      :show="modalPrint"
      :filId="fil_id"
      @close="closeModalPrint"
    />

    <ModalPatientImageInRow
      ref="modalPatientImageInRow"
      :show="modalPatientImageInRow"
      :fill-id="fil_id"
      :modId="hospitalizationModuleId"
      @close="closeModalPatientImageInRow"
    />

    <ModalConfirmDefault
      :show="modalRemovePendency"
      :message="`${this.patientInfo.pac_nome} já se encontra na fila para ${this.bedTypeName}.`"
      title="Confirmar paciente na fila no mesmo tipo de leito?"
      yes-label="Sim"
      no-label="Não"
      @getYes="saveForm"
      @getOut="closeModalRemovePendency"
    />
  </Modulebox>
</template>
<script>
import {
  RgCleanButton,
  RgSaveButton,
  RgSuggestPatientInformation,
  RgInputDate,
  RgInput,
  RgValidatorForm,
  RgComboboxUnithealth,
  RgSuggestEmployee,
  RgInputHour,
  RgTextArea,
  RgCancelButton,
  RgComboboxPriority,
  AlertError,
  ModalConfirmDefault,
  MediumButton,
  RgSuggestCid,
} from "~tokio/primitive";
import { ModalPatientImageInRow } from "~tokio/primitive/modal";

import moment from "moment";
import FormBase from "~tokio/foundation/form-base/FormBase";
import Modulebox from "~tokio/foundation/modulebox/Modulebox";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";
import ModalTypePrintInsertQueue from "$hospitalization//hospitalizations/queue/component/modal/modal-type-print-insert-queue/ModalTypePrintInsertQueue";
import ModalConfirmFieldClearing from "~tokio/primitive/modal/modal-confirm-field-clearing/ModalConfirmFieldClearing";
import RgSuggestTypeBed from "$hospitalization/common/component/suggest/rg-suggest-type-bed/RgSuggestTypeBed";
import RgComboboxWaitingPlace from "$hospitalization/hospitalizations/queue/component/combobox/wating-place/RgComboboxWaitingPlace";
import RgComboboxUnithealthExternal from "$hospitalization/hospitalizations/queue/component/combobox/unithealth-external/RgComboboxUnithealthExternal";
import RgSuggestHospitalizationQueueProcedures from "$hospitalization/common/component/suggest/rg-suggest-hospitalization-queue-procedures/RgSuggestHospitalizationQueueProcedures";
import { mapGetters } from "vuex";

export default {
  name: "HospitalizationInsertQueue",
  components: {
    Modulebox,
    FormBase,
    RgValidatorForm,
    ModalConfirmFieldClearing,
    RgSuggestPatientInformation,
    RgInputDate,
    RgInput,
    RgComboboxUnithealth,
    RgSuggestEmployee,
    RgComboboxPriority,
    RgInputHour,
    RgSaveButton,
    RgCleanButton,
    RgTextArea,
    RgCancelButton,
    ModalTypePrintInsertQueue,
    RgSuggestTypeBed,
    RgComboboxWaitingPlace,
    RgComboboxUnithealthExternal,
    RgSuggestHospitalizationQueueProcedures,
    RgSuggestCid,
    ModalConfirmDefault,
    MediumButton,
    ModalPatientImageInRow,
  },
  data: () => {
    return {
      dataPatientQueue: null,
      appointmentQueueId: null,
      patientInfo: {},
      suggestPatient: null,
      modalRemovePendency: false,
      requestingProfessional: null,
      period: null,
      modalConfirmFieldClearing: false,
      modalPrint: false,
      modalPatientImageInRow: false,
      showBackButton: true,
      dataLoadAlter: null,
      fil_id: 0,
      form: {
        patientId: null,
        entryDate: moment().format("DD/MM/YYYY"),
        entryHour: moment().format("HH:mm:ss"),
        bedType: null,
        waitingPlace: null,
        originUnit: null,
        mainProcedure: null,
        secondProcedure: null,
        thirdProcedure: null,
        mainProcedureId: null,
        secondProcedureId: null,
        thirdProcedureId: null,
        mainCid: null,
        secondCid: null,
        thirdCid: null,
        int_id_cid10_main: null,
        int_id_cid10_second: null,
        int_id_cid10_third: null,
        requestingProfessionalId: null,
        classCouncil: null,
        requesterUnit: null,
        priority: null,
        requestDate: null,
        observations: null,
      },
      bedTypeName: null,
      itl_id: null,
      queuesPerPerson: [],
      personData: null,
      selectedTypeBed: null,
      inp_novo_codigo_procedimento: null,
      disableClassCouncil: true,
    };
  },

  computed: {
    ...mapGetters({
      userLogin: "Login/GET_COMPLETE_LOGIN_INFO",
      unitHealthId: "Login/GET_UNIT_HEALTH_ID",
      lastPersonIdSaved: "Person/Patient/GET_LAST_PERSON_ID_SAVE",
    }),

    hasPermission() {
      return this.$Permissions.global.has("internacao.filaInternacao.incluir");
    },

    hasIdPatient() {
      return Boolean(
        Number.isInteger(this.patientInfo.pes_id) && this.suggestPatient,
      );
    },

    hasMainProcedure() {
      return this.form.mainProcedure;
    },

    hasSecondProcedure() {
      return this.form.secondProcedure;
    },

    hasMainCid() {
      return this.form.mainCid;
    },

    hasSecondCid() {
      return this.form.secondCid;
    },

    isEdition() {
      return this.personData !== null;
    },

    hospitalizationModuleId() {
      return this.$store.state.Login.route_module_map.hospitalization;
    },

    mainProcedureId() {
      return this.form.mainProcedureId;
    },

    secondProcedureId() {
      return this.form.secondProcedureId;
    },

    thirdProcedureId() {
      return this.form.thirdProcedureId;
    },

    mainCidId() {
      return this.form.int_id_cid10_main;
    },

    secondCidId() {
      return this.form.int_id_cid10_second;
    },

    thirdCidId() {
      return this.form.int_id_cid10_third;
    },
  },

  watch: {
    mainProcedureId(pValue) {
      if (!pValue) {
        const cleanProcedureAndCid = 1;
        this.cleanProcedures(cleanProcedureAndCid);
        this.cleanCid(cleanProcedureAndCid);
      }
    },

    secondProcedureId(pValue) {
      if (!pValue) {
        const cleanProcedureAndCid = 2;
        this.cleanProcedures(cleanProcedureAndCid);
        this.cleanCid(cleanProcedureAndCid);
      }
    },

    mainCidId(pValue) {
      if (!pValue) {
        const cleanCid = 1;
        this.cleanCid(cleanCid);
      }
    },

    secondCidId(pValue) {
      if (!pValue) {
        const cleanProcedureAndCid = 2;
        this.cleanProcedures(cleanProcedureAndCid);
        this.cleanCid(cleanProcedureAndCid);
      }
    },
  },

  created() {
    this.REASONS_BLOCKED_PATIENT = {
      1: "NÃO INFORMADO",
      2: "INABILITADO",
      3: "DESCARTADO",
      4: "ÓBITO",
      5: "INATIVIDADE",
      6: "PRONTUÁRIO DUPLICADO",
      7: "TRIAGEM",
      8: "MOTIVOS ADMINISTRATIVOS",
    };
  },

  async mounted() {
    if (this.lastPersonIdSaved) {
      this.$refs.patientInfoSuggest.fillPatientById(this.lastPersonIdSaved);
    }

    const fromMenu = this.$route.name === "hospitalization.queue.queue-insert";
    if (fromMenu) {
      this.showBackButton = false;
    }

    if (this.$route.name !== "hospitalization.queue.edit-insert") {
      this.$store.commit(
        "Hospitalization/Queue/UNSELECT_QUEUE_EXTERNAL_HOSPITALIZATION_DATA",
      );
    }

    this.setInitialData();
    const lineSelected = await this.$store.getters[
      "Hospitalization/Queue/GET_SELECTED_EXTERNAL_HOSPITALIZATION_RESULT"
    ];

    if (lineSelected) {
      this.dataLoadAlter = lineSelected[0];
    }

    if (this.dataLoadAlter) {
      await this.loadDataForEditing();
    }
  },

  beforeDestroy() {
    const validateIfExistInsert = ValidateIfRouteExistInBreadscrumb(
      "/hospitalization/queue/insert",
      this.$route.meta.breadcrumb,
    );

    const validateIfExistEdit = ValidateIfRouteExistInBreadscrumb(
      "/hospitalization/queue/edit-insert",
      this.$route.meta.breadcrumb,
    );

    if (!validateIfExistEdit && !validateIfExistInsert) {
      this.$store.commit(
        "Hospitalization/Queue/UNSELECT_QUEUE_EXTERNAL_HOSPITALIZATION_DATA",
      );
    }
  },

  methods: {
    cleanProcedures(pProcedureClean) {
      const isProcedureOne = pProcedureClean === 1;
      const isProcedureTwo = pProcedureClean === 2;
      const isProcedureThree = pProcedureClean === 3;

      if (isProcedureOne) {
        this.form.secondProcedureId = null;
        this.form.secondProcedure = null;
      }

      if (isProcedureOne || isProcedureTwo) {
        this.form.thirdProcedureId = null;
        this.form.thirdProcedure = null;
      }

      if (isProcedureOne || isProcedureTwo || isProcedureThree) {
        this.form.thirdProcedureId = null;
        this.form.thirdProcedure = null;
      }
    },

    cleanCid(pCidClean) {
      const isCidOne = pCidClean === 1;
      const isCidTwo = pCidClean === 2;
      const isCidThree = pCidClean === 3;

      if (isCidOne) {
        this.form.mainCid = "";
        this.form.int_id_cid10_main = null;
      }

      if (isCidOne || isCidTwo) {
        this.form.secondCid = "";
        this.form.int_id_cid10_second = null;
      }

      if (isCidOne || isCidTwo || isCidThree) {
        this.form.thirdCid = "";
        this.form.int_id_cid10_third = null;
      }
    },

    closeModalPatientImageInRow() {
      this.modalPatientImageInRow = false;
    },

    async confirmSaveForm(insertImageInRow) {
      const validadeForm = await this.isFormValid();
      const validateBedType = await this.validateEqualsBedType();

      if (!validadeForm) {
        AlertError("Verifique os campos");
        return;
      }

      if (!validateBedType && !this.isEdition) {
        this.modalRemovePendency = true;
        return;
      }

      this.saveForm(insertImageInRow);
    },

    async saveForm(insertImageInRow) {
      try {
        this.$loader.start();
        this.fil_id = 0;

        const variables = {
          fil_data_pedido: this.form.requestDate,
          flt_id_internacoes_tipos_leito: this.itl_id,
          itl_nome: this.bedTypeName,
          flt_id_internacoes_locais_espera: this.form.waitingPlace
            ? Number(this.form.waitingPlace)
            : null,
          flt_id_internacoes_procedimentos_1: this.form.mainProcedureId,
          flt_id_internacoes_procedimentos_2: this.form.secondProcedureId,
          flt_id_internacoes_procedimentos_3: this.form.thirdProcedureId,
          flt_id_cid10_1: this.form.int_id_cid10_main,
          flt_id_cid10_2: this.form.int_id_cid10_second,
          flt_id_cid10_3: this.form.int_id_cid10_third,
          fil_id_funcionarios: this.form.requestingProfessionalId,
          fil_conselho_classe: this.form.classCouncil,
          fil_id_unidades_saude: this.$store.getters[
            "Login/GET_UNIT_HEALTH_ID"
          ],
          fil_id_unidades_saude_solicitante: this.form.requesterUnit,
          fil_id_pessoas: this.patientInfo.pes_id,
          flt_id_internacoes_unidades_solicitantes: this.form.originUnit,
          fil_id_prioridades_fila: this.form.priority,
          fio_observacao: this.form.observations,
          fil_id_agendado_por: this.userLogin.user.usu_id,
        };

        if (!this.form.requestingProfessionalId) {
          delete variables.fil_id_funcionarios;
          variables.fil_funcionario_externo = this.requestingProfessional;
        }

        if (this.isEdition) {
          variables.fil_ordem_regulada = this.dataLoadAlter.fil_ordem_regulada;
          variables.flt_id = this.dataLoadAlter.flt_id;
          await this.$store.dispatch(
            "Hospitalization/Queue/EDIT_HOSPITALIZATION_QUEUE",
            variables,
          );
          this.$router.go(-1);
        } else {
          const data = await this.$store.dispatch(
            "Hospitalization/Queue/ADD_HOSPITALIZATION_QUEUE",
            variables,
          );
          this.fil_id = data;
          this.modalPrint = true;

          if (insertImageInRow) {
            this.modalPatientImageInRow = true;
          }
        }

        this.$toaster.success("Operação realizada com sucesso.");
        this.$store.commit(
          "Hospitalization/Queue/UNSELECT_QUEUE_EXTERNAL_HOSPITALIZATION_DATA",
        );
        this.clearForm();
      } catch (error) {
        this.$toaster.error(error);
      } finally {
        this.modalRemovePendency = false;
        this.$loader.finish();
      }
    },

    validateEqualsBedType() {
      return this.queuesPerPerson.every((list, index, array) => {
        return this.queuesPerPerson[index].nome !== this.bedTypeName;
      });
    },

    formatDataBR(pValue) {
      if (!pValue) {
        return "";
      }

      if (pValue.includes("-")) {
        return this.$utils.date.BrazilianDateFormat(pValue);
      } else {
        return pValue;
      }
    },

    async loadDataForEditing() {
      try {
        this.$loader.start();
        const { flt_id } = this.dataLoadAlter;
        this.dataPatientQueue = await this.$store.dispatch(
          "Hospitalization/Queue/GET_DATA_PATIENT_HOSPITALIZATION_QUEUE",
          { flt_id },
        );

        this.personData = await this.$store.dispatch(
          "Hospitalization/Queue/GET_PATIENT_BASIC_INFO",
          { pesId: this.dataPatientQueue.fil_id_pessoas },
        );

        await this.fillPatientInfo(this.personData);

        this.$refs.patientInfoSuggest.fillPatientById(this.personData.pes_id);

        this.form.entryDate = this.dataPatientQueue.fil_data.split(" ")[0];
        this.form.entryHour = this.dataPatientQueue.fil_data.split(" ")[1];

        const bedType = this.dataPatientQueue.objTipoLeito;
        if (bedType) {
          this.form.bedType = bedType.itl_nome;
          this.form.bedTypeName = bedType.itl_nome;
          this.$refs.typeBed.forceSelection(bedType);
        }

        const waitingPlace = this.dataPatientQueue.objLocalEspera;

        if (waitingPlace) {
          this.form.waitingPlace = waitingPlace.ile_id;
        }

        this.form.originUnit = Number(
          this.dataPatientQueue.flt_id_internacoes_unidades_solicitantes,
        );

        const mainCid = this.dataPatientQueue.objCid10_1;

        if (mainCid) {
          this.form.int_id_cid10_main = mainCid.cid_id;

          const cid = {
            cid_codigo: mainCid.cid_id,
            cid_nome: mainCid.cid_nome,
          };

          this.$refs.mainCid.forceSelection(cid);
        }

        const secondCid = this.dataPatientQueue.objCid10_2;

        if (secondCid) {
          this.form.int_id_cid10_second = secondCid.cid_id;

          const cid = {
            cid_codigo: secondCid.cid_id,
            cid_nome: secondCid.cid_nome,
          };

          this.$refs.secondCid.forceSelection(cid);
        }

        const thirdCid = this.dataPatientQueue.objCid10_3;

        if (thirdCid) {
          this.form.int_id_cid10_third = thirdCid.cid_id;

          const cid = {
            cid_codigo: thirdCid.cid_id,
            cid_nome: thirdCid.cid_nome,
          };

          this.$refs.thirdCid.forceSelection(cid);
        }

        this.fillProcedures();
        if (this.dataPatientQueue?.fil_id_funcionarios) {
          this.form.requestingProfessionalId = this.dataPatientQueue?.fil_id_funcionarios;
        }

        if (
          this.dataPatientQueue.fil_funcionario_externo &&
          !this.dataPatientQueue.objFuncionario.fun_id
        ) {
          this.requestingProfessional = this.dataPatientQueue.fil_funcionario_externo;
        } else {
          const employeeData = this.dataPatientQueue.objFuncionario;
          const bond =
            employeeData &&
            employeeData.objVinculo &&
            employeeData.objVinculo.vin_vinculos;
          if (employeeData && bond)
            this.$refs.employee.forceSelection({
              pes_nome: employeeData.pes_nome,
              vin_id: Number(bond[0].vin_id),
            });

          this.requestingProfessional = this.dataPatientQueue.objFuncionario.pes_nome;
        }

        this.form.classCouncil = this.dataPatientQueue.fil_conselho_classe;
        this.disableClassCouncil = !!this.dataPatientQueue.fil_conselho_classe;
        this.form.requesterUnit = Number(
          this.dataPatientQueue.fil_id_unidades_saude_solicitante,
        );
        this.form.priority = Number(
          this.dataPatientQueue.fil_id_prioridades_fila,
        );
        this.form.requestDate = this.dataPatientQueue.fil_data_pedido;
        this.form.observations = this.dataPatientQueue.objFilaObservacao.fio_observacao;
      } catch (Err) {
        this.$toaster.error(Err);
      } finally {
        this.$loader.finish();
      }
    },

    fillProcedures() {
      const maxProcedure = 3;

      for (let i = 1; i <= maxProcedure; i++) {
        const hasProcedure =
          this.dataPatientQueue[`flt_id_internacoes_procedimentos_${i}`]
            .length > 0;

        if (hasProcedure) {
          const {
            inp_id,
            inp_novo_nome_procedimento,
            inp_novo_codigo_procedimento,
          } = this.dataPatientQueue[`objProcedimento${i}`];

          this.$refs[`procedure${i}`].forceSelection({
            inp_id,
            inp_novo_nome_procedimento,
            inp_novo_codigo_procedimento,
          });
        }
      }
    },

    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    registerPatient() {
      const validateIfExist = ValidateIfRouteExistInBreadscrumb(
        "/hospitalization/queue/queue-insert",
        this.$route.meta.breadcrumb,
      );

      if (validateIfExist) {
        this.$router.push({
          name: "hospitalization.queue.queue-insert.new-patient",
        });
      } else {
        this.$router.push({
          name: "hospitalization.queue.insert.new-patient",
        });
      }
    },

    patientRecord() {
      const patientId = this.patientInfo.pes_id;

      this.$store.commit("Person/Patient/SET_PERSON_ID", patientId);
      this.$store.commit("Person/Patient/SET_LAST_PERSON_ID_SAVE", patientId);

      const validateIfExist =
        this.$route.name === "hospitalization.queue.insert";

      if (validateIfExist) {
        this.$router.push({
          name: "hospitalization.queue.insert.edit-patient",
          patientId,
        });
      } else {
        this.$router.push({
          name: "hospitalization.queue.edit-insert.edit-patient",
          patientId,
        });
      }
    },

    async getPatientData(pPatient) {
      try {
        const hasPerson = pPatient && pPatient.pes_id > 0;
        const patientBlocked =
          hasPerson &&
          pPatient.patient &&
          pPatient.patient.pac_id_motivo_inabilitacao;

        if (patientBlocked) {
          const patientIdReasonBlocked =
            pPatient.patient.pac_id_motivo_inabilitacao;

          AlertError(
            "Paciente inativo. Motivo: " +
              `${this.REASONS_BLOCKED_PATIENT[patientIdReasonBlocked]}`,
          );
        }

        if (!pPatient.patient && hasPerson) {
          const result = await this.$store.dispatch(
            "Person/Patient/CREATE_PATIENT_FOR_PERSON",
            {
              pes_id: pPatient.pes_id,
              withPatientHospitalization: true,
            },
          );
          pPatient.patient = result;
        }

        if (pPatient.patient !== null && hasPerson) {
          const patient = pPatient.patient;
          const patientHospitalization = patient.patient_hospitalization;

          this.patientInfo.pac_id = patient.pac_id ? patient.pac_id : null;
          this.patientInfo.pci_id =
            this.patientInfo.pac_id && patientHospitalization
              ? patientHospitalization.pci_id
              : null;
          this.fillPatientInfo(pPatient);
        } else {
          this.patientInfo = {
            pac_id: "",
            pci_id: "",
          };
        }

        if (this.patientInfo.pes_id) {
          this.queuesPerPerson = await this.$store.dispatch(
            "Hospitalization/Queue/SEARCH_HOSPITALIZATION_QUEUES_PER_PERSON",
            { intIdPessoa: this.patientInfo.pes_id },
          );
        }
      } catch (error) {
        console.error(error);
      }
    },

    selectingBedType(pBedType) {
      const source = pBedType && pBedType.source;
      if (source) {
        this.bedTypeName = source.itl_nome;
        this.itl_id = source.itl_id;
      } else {
        this.bedTypeName = null;
        this.itl_id = null;
      }
    },

    cancel() {
      this.$router.push({ name: "hospitalization.queue" });
    },

    selectingMainProcedure(pProcedure) {
      if (!this.procedureAlreadyExists(pProcedure)) {
        const source = pProcedure.source;

        this.form.mainProcedureId = source ? source.inp_id : null;
      }
    },

    selectingSecondProcedure(pProcedure) {
      if (!this.procedureAlreadyExists(pProcedure)) {
        const source = pProcedure.source;

        this.form.secondProcedureId = source ? source.inp_id : null;
      }
    },

    selectingThirdProcedure(pProcedure) {
      if (!this.procedureAlreadyExists(pProcedure)) {
        const source = pProcedure.source;

        this.form.thirdProcedureId = source ? source.inp_id : null;
      }
    },

    procedureAlreadyExists(pValue) {
      const source = pValue && pValue.source;
      if (source) {
        const allSelectedProcedures = [
          this.form.mainProcedure,
          this.form.secondProcedure,
          this.form.thirdProcedure,
        ];
        const selectedProcedure = source.inp_novo_nome_procedimento;
        if (allSelectedProcedures.includes(selectedProcedure)) {
          AlertError("Procedimento já selecionado!");
          return true;
        }
      }
      return false;
    },

    selectingMainCid(pCid) {
      if (!this.cidAlreadyExists(pCid)) {
        if (pCid && pCid.source && pCid.source.cid_id) {
          this.form.int_id_cid10_main =
            pCid && pCid.source.cid_id ? pCid.source.cid_id : null;
          // this.mainCid = pCid && pCid.source.cid_nome ? pCid.source.cid_nome : "";
        } else {
          this.form.int_id_cid10_main = null;
        }
      }
    },

    selectingSecondCid(pCid) {
      if (!this.cidAlreadyExists(pCid)) {
        if (pCid && pCid.source && pCid.source.cid_id) {
          this.form.int_id_cid10_second =
            pCid && pCid.source.cid_id ? pCid.source.cid_id : null;
          // this.mainCid = pCid && pCid.source.cid_nome ? pCid.source.cid_nome : "";
        } else {
          this.form.int_id_cid10_second = null;
        }
      }
    },

    selectingThirdCid(pCid) {
      if (!this.cidAlreadyExists(pCid)) {
        if (pCid && pCid.source && pCid.source.cid_id) {
          this.form.int_id_cid10_third =
            pCid && pCid.source.cid_id ? pCid.source.cid_id : null;
          // this.mainCid = pCid && pCid.source.cid_nome ? pCid.source.cid_nome : "";
        } else {
          this.form.int_id_cid10_third = null;
        }
      }
    },

    cidAlreadyExists(pValue) {
      const source = pValue && pValue.source;
      if (source) {
        const allSelectedCids = [
          this.form.mainCid,
          this.form.secondCid,
          this.form.thirdCid,
        ];
        const selectedCid = source.cid_nome;
        if (allSelectedCids.includes(selectedCid)) {
          AlertError("CID10 já selecionado!");
          return true;
        }
      }
      return false;
    },

    selectingProfessional(pProfessional) {
      const source = pProfessional && pProfessional.source;
      if (source) {
        if (
          source.employeeRelationship &&
          source.employeeRelationship.vin_id_funcionarios
        ) {
          this.form.requestingProfessionalId =
            source.employeeRelationship.vin_id_funcionarios;
        }
        this.form.classCouncil = source.fun_conselho_classe;
        this.disableClassCouncil = !!source.fun_conselho_classe;
      } else {
        this.form.requestingProfessionalId = null;
      }
    },

    showConfirmFieldClearing() {
      this.modalConfirmFieldClearing = true;
    },

    closeModalPrint() {
      this.modalPrint = false;
      this.fil_id = 0;
    },

    confirmCleanForm() {
      this.clearForm();
      this.modalConfirmFieldClearing = false;
    },

    closeConfirmFieldClearing() {
      this.modalConfirmFieldClearing = false;
    },

    fillPatientInfo(pData) {
      this.patientInfo = {
        pac_id: pData.patient.pac_id,
        pes_id: pData.pes_id,
        pac_nome: pData.pes_nome,
        sex_sigla: pData && pData.gender ? pData.gender.sex_sigla : null,
        crs_numero: pData && pData.cns ? pData.cns.crs_numero : null,
        pes_mae: pData && pData.pes_mae ? pData.pes_mae : null,
        cpf: pData && pData.cpf ? pData.cpf.cpf_numero : null,
        prontuario_unico:
          pData && pData.patient ? pData.patient.pac_prontuario_unico : null,
      };
    },

    setInitialData() {
      if (!this.isEdition) {
        this.form.originUnit = this.unitHealthId;
        this.form.requesterUnit = this.unitHealthId;
      }
    },

    closeModalRemovePendency() {
      this.modalRemovePendency = false;
    },

    clearForm() {
      if (this.isEdition) {
        this.form.originUnit = null;
        this.bedType = null;
        this.form.bedTypeName = null;
        this.form.requesterUnit = null;
        this.form.priority = null;
      } else {
        this.patientInfo = {};
        this.suggestPatient = null;
        this.bedTypeName = null;
        this.requestingProfessional = null;
        this.period = null;
        this.queuesPerPerson = [];
        this.modalConfirmFieldClearing = false;
        this.form = {
          patientId: null,
          entryDate: moment().format("DD/MM/YYYY"),
          entryHour: moment().format("HH:mm:ss"),
          bedType: null,
          waitingPlace: null,
          originUnit: null,
          mainProcedure: null,
          secondProcedure: null,
          thirdProcedure: null,
          mainProcedureId: null,
          secondProcedureId: null,
          thirdProcedureId: null,
          mainCid: null,
          secondCid: null,
          thirdCid: null,
          int_id_cid10_main: null,
          int_id_cid10_second: null,
          int_id_cid10_third: null,
          requestingProfessionalId: null,
          classCouncil: null,
          requesterUnit: null,
          priority: null,
          requestDate: null,
          observations: null,
        };
        this.setInitialData();
        this.$refs.patientInfoSuggest.cleanValidate();
        this.$refs.typeBed.cleanValidate();
        this.$refs.employee.cleanValidate();
        this.$refs.date.cleanValidate();
        this.$refs.priority.cleanValidate();
        this.$refs.unitInsertion.cleanValidate();
        this.$refs.unitSolicitation.cleanValidate();
        this.$refs.textArea.resetCharacter();
      }
    },
  },
};
</script>
