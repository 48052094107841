import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation($suspendSurgery: SuspendedSurgeryInputType) {
    SuspendSurgery(suspendSurgery: $suspendSurgery) {
      cci_id
    }
  }
`;

export default (pStore, pInfo) => {
  const variables = {
    suspendSurgery: {
      ccu_observacao: pInfo.ccu_observacao || "",
      ccu_id_controles_cirurgicos: pInfo.ccu_id_controles_cirurgicos,
      ccu_id_motivos_suspensoes: pInfo.ccu_id_motivos_suspensoes,
      sectorId: pInfo.sector_id,
    },
  };
  return new Promise((resolve, reject) => {
    GraphQLClient.mutate({ mutation, variables })
      .then((result) => {
        pStore.commit("CLEAN_SELECTION");
        return resolve(result.data);
      })
      .catch((err) => {
        return reject(err.graphQLErrors);
      });
  });
};
