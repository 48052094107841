<script>
export default {
  data() {
    return {
      filterValue: "",
      filterKey: "",
    };
  },
  methods: {
    changeFilter(pStatus) {
      this.$store.dispatch("SurgeryCenter/Surgery/fillFilter", {
        filterKey: pStatus,
        filterValue: this.filterValue,
      });
      this.$store.dispatch("SurgeryCenter/Surgery/filter");
    },
    closeFilter() {
      this.$emit("close");
    },
  },
};
</script>
