import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query favoriteOperation(
    $opf_id_unidades_saude: Int!
    $opf_id_usuarios: Int!
  ) {
    favoriteOperation(
      opf_id_unidades_saude: $opf_id_unidades_saude
      opf_id_usuarios: $opf_id_usuarios
    ) {
      count
      rows {
        opf_id
        opf_id_unidades_saude
        opf_id_usuarios
        opf_rota
        opf_nome_rota
        opf_nome_icone
        opf_modulos
        opf_permissao
        opf_ativo
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    return data.favoriteOperation;
  } catch (err) {
    return Promise.reject(err.graphQLErrors);
  }
};
