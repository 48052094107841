<template>
  <Modulebox :title="individualOrLot" class="exam-register-schedule-queue">
    <form class="form-container" @submit.stop.prevent="saveForm">
      <RgValidatorForm ref="validator">
        <div class="body">
          <FormBase :title="patientOrPatients" class="content-form">
            <div class="actions-top">
              <RgPersonCardButton
                :disabled="!patientSelect"
                :class="{ disable: !patientSelect }"
                class="register"
                title="Editar paciente"
                @click="editPatient"
              />
              <RgPrinterButton
                :disabled="!patientSelect"
                :class="{ disable: !patientSelect }"
                class="printer"
                title="Imprimir carteirinha"
                @click="patientPrintCard"
              />
              <!-- <RgHistoryButton
                :disabled="!patientSelect"
                :class="{disable: !patientSelect}"
                class="history"
                title="Histórico do paciente"
                @click="historyPatient"
              /> -->
              <RgDeleteButton
                v-show="mutableListPatient && mutableListPatient.length > 1"
                :disabled="!patientSelect"
                :class="{ disable: !patientSelect }"
                class="delete"
                title="Remover paciente da tabela"
                @click="removePatientToList"
              />
            </div>

            <table v-if="mutableListPatient" class="table-patient">
              <div class="thead">
                <tr class="header-grid">
                  <th class=""></th>
                  <th class="patient">Pacientes</th>
                  <th class="medical-record">Prontuário Único</th>
                </tr>
              </div>

              <div class="tbody">
                <tr
                  v-for="(item, index) in mutableListPatient"
                  :key="index"
                  :class="{ selected: index === activeRow }"
                  class="body-grid"
                  @click="selectPatient(item, index)"
                >
                  <td>
                    <div v-if="index === activeRow" class="check">
                      <IconCheck />
                    </div>
                  </td>
                  <td>{{ item.pes_nome }}</td>
                  <td>{{ item.pac_prontuario_unico }}</td>
                </tr>
              </div>
            </table>
          </FormBase>

          <FormBase title="Procedimento" class="module-procedure">
            <div class="content-grid">
              <span class="name-procedure"
                >Procedimento: <strong>{{ procedureName }}</strong></span
              >
              <div class="row-1 content-grid">
                <RgComboboxUnitHealthProcedure
                  ref="unit"
                  v-model="form.unitHealth"
                  :rules="{ required: true }"
                  :permission="'exame2.filaExame.agendar'"
                  :procedureName="procedureName"
                  label="Unidade de Saúde"
                />

                <RgComboboxSectorProcedureProfessional
                  ref="sector"
                  v-model="form.sector"
                  :rules="{ required: true }"
                  :disabled="!form.unitHealth"
                  :class="{ disable: !form.unitHealth }"
                  :unithHealthId="Number(form.unitHealth)"
                  :procedureName="procedureName"
                  :submoduleId="submoduleId"
                  default-text="Selecione"
                  label="Setor"
                  class="inputitem"
                  @selectItem="selectItemSector"
                />

                <RgComboboxPlaceSector
                  ref="locale"
                  v-model="form.locale"
                  :disabled="!form.sector"
                  :class="{ disable: !form.sector }"
                  :rules="{ required: true }"
                  :sectorId="Number(form.sector)"
                  label="Local de Atendimento"
                  @selectItem="selectItemPlace"
                />
              </div>

              <div class="row-2 content-grid">
                <RgComboboxProfessionalSectorProcedure
                  ref="employee"
                  v-model="form.responsibleProfessional"
                  :disabled="!form.sector"
                  :class="{ disable: !form.sector }"
                  :rules="{ required: true }"
                  :sectorId="Number(form.sector)"
                  :procedureName="procedureName"
                  label="Profissional"
                  @select="selectItemProfessional"
                  @selectOnlyItem="selectItemProfessional"
                />

                <RgInputDate
                  ref="date"
                  v-model="form.date"
                  :rules="{ required: true }"
                  :disabled="!form.responsibleProfessional"
                  :class="{ disable: !form.responsibleProfessional }"
                  :dates-available="datesEnable"
                  label="Data"
                  placeholder="dd/mm/aaaa"
                />

                <RgComboboxScalesWithProcedure
                  ref="scale"
                  v-model="form.scale"
                  :disabled="!form.date"
                  :class="{ disable: !form.date }"
                  :rules="{ required: true }"
                  :sectorId="Number(form.sector)"
                  :bondId="Number(form.vus_id)"
                  :procedureName="procedureName"
                  :date="form.date"
                  label="Escala"
                  @change="selectItemScale"
                />

                <RgComboboxVacanciesAvailableScale
                  v-if="!hasLot"
                  ref="vacancie"
                  v-model="form.vacancie"
                  :disabled="!form.scale"
                  :class="{ disable: !form.scale }"
                  :rules="{ required: true }"
                  :scale="Number(form.scale)"
                  :date="form.date"
                  label="Vagas"
                  @selectOnlyItem="selectItemVacancie"
                />

                <RgComboboxHour
                  v-if="showComboHour"
                  ref="comboHour"
                  v-model="form.hour"
                  :rules="{ required: true }"
                  :disabled="!form.date || !form.scale"
                  :class="{ disable: !form.date || !form.scale }"
                  :scaleSchedule="Number(form.scale)"
                  :historySchedule="Number(selectedScale.eha_id)"
                  :dateExam="form.date ? form.date : ''"
                  :occupiedPlaces="
                    Number(form.vacancie) ? Number(form.vacancie) : 1
                  "
                  :eapId="null"
                  hasVacancies
                  label="Horário"
                  @selectOnlyItem="selectItemHour"
                />

                <RgComboboxProceduresProfessionalAgreements
                  ref="healthPlan"
                  v-model="form.healthPlan"
                  :disabled="!form.sector"
                  :class="{ disable: !form.sector }"
                  :rules="{ required: true }"
                  :sectorId="Number(form.sector)"
                  :bondId="Number(form.vus_id)"
                  :procedureName="procedureName"
                  label="Convênios"
                  @selectItem="selectItemHealthPlan"
                />
              </div>
            </div>
          </FormBase>

          <FormBase title="Subprocedimentos" class="module-subprocedure">
            <div v-if="activeRow === null" class="empty">
              <IconEmpty />
              <span class="text">Nenhum paciente selecionado.</span>
            </div>
            <div v-else-if="!hasSubprocedureList" class="empty">
              <IconEmpty />
              <span class="text">Nenhum subprocedimento encontrado.</span>
            </div>
            <div v-else class="content-grid">
              <table class="table unselect">
                <tr class="header">
                  <th class="title check"></th>
                  <th class="title">Subprocedimento</th>
                  <th class="title">Realiza na Unidade</th>
                </tr>

                <tr
                  v-for="(item, index) in subprocedureList"
                  :key="index"
                  class="body"
                  :class="{ selected: item.selected }"
                  @click="selectSubprocedure(item)"
                >
                  <td class="icon-check">
                    <div v-if="item.selected" class="check">
                      <IconCheck />
                    </div>
                  </td>

                  <td class="data">
                    {{ item.subProcedimento }}
                  </td>

                  <td class="data">
                    {{ item.heldUnit ? item.heldUnit : "" }}
                  </td>
                </tr>
              </table>
            </div>
          </FormBase>
        </div>
      </RgValidatorForm>
    </form>

    <div slot="footer" class="footer">
      <RgCleanButton
        small
        title="Salvar"
        class="clean"
        @click="showConfirmFieldClearing"
      />

      <RgCancelButton
        ref="cancelButton"
        medium
        class="cancel"
        @click="cancel"
      />

      <RgSaveButton
        ref="save"
        medium
        title="Salvar"
        class="save"
        @click="saveForm"
      />
    </div>

    <ModalConfirmFieldClearing
      ref="confirmClearing"
      :show="modalConfirmFieldClearing"
      @getYes="confirmCleanForm"
      @getOut="closeConfirmFieldClearing"
    />
  </Modulebox>
</template>

<script>
import {
  RgSaveButton,
  RgCleanButton,
  RgValidatorForm,
  RgInputDate,
  RgCancelButton,
  RgPersonCardButton,
  RgPrinterButton,
  // RgHistoryButton,
  RgDeleteButton,
  IconEmpty,
  IconCheck,
} from "~tokio/primitive";

import { Modulebox } from "~tokio/foundation";

import moment from "moment";
import FormBase from "~tokio/foundation/form-base/FormBase";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";
import ModalConfirmFieldClearing from "~tokio/primitive/modal/modal-confirm-field-clearing/ModalConfirmFieldClearing";

import RgComboboxUnitHealthProcedure from "$exam/submodules/schedule/component/combobox/rg-combobox-unit-health-procedure/RgComboboxUnitHealthProcedure";
import RgComboboxSectorProcedureProfessional from "$exam/submodules/schedule/component/combobox/rg-combobox-sector-procedure-professional/RgComboboxSectorProcedureProfessional";
import RgComboboxPlaceSector from "$exam/submodules/schedule/component/combobox/rg-combobox-place-sector/RgComboboxPlaceSector";
import RgComboboxProfessionalSectorProcedure from "$exam/submodules/schedule/component/combobox/rg-combobox-professional-sector-procedure/RgComboboxProfessionalSectorProcedure";
import RgComboboxProceduresProfessionalAgreements from "$exam/submodules/schedule/component/combobox/rg-combobox-procedures-professional-agreements/RgComboboxProceduresProfessionalAgreements";
import RgComboboxScalesWithProcedure from "$exam/submodules/schedule/component/combobox/rg-combobox-scales-with-procedure/RgComboboxScalesWithProcedure";
import RgComboboxVacanciesAvailableScale from "$exam/submodules/schedule/component/combobox/rg-combobox-vacancies-available-scale/RgComboboxVacanciesAvailableScale";
import RgComboboxHour from "$exam/common/component/rg-combobox-hour/RgComboboxHour";
import SearchPersonById from "$exam/submodules/schedule/store/actions/SearchPersonById";
import { mapGetters } from "vuex";

const FORM = {
  unitHealth: null,
  sector: null,
  locale: null,
  responsibleProfessional: null,
  date: null,
  scale: null,
  hour: null,
  vacancie: null,
  healthPlan: null,
  vus_id: null,
  fun_id: null,
};

export default {
  name: "ExamRegisterScheduleQueue",
  components: {
    RgComboboxUnitHealthProcedure,
    RgSaveButton,
    Modulebox,
    FormBase,
    RgCleanButton,
    RgComboboxPlaceSector,
    RgValidatorForm,
    RgComboboxSectorProcedureProfessional,
    RgInputDate,
    ModalConfirmFieldClearing,
    RgComboboxProfessionalSectorProcedure,
    RgComboboxProceduresProfessionalAgreements,
    RgComboboxScalesWithProcedure,
    RgComboboxVacanciesAvailableScale,
    RgComboboxHour,
    IconEmpty,
    RgCancelButton,
    RgPersonCardButton,
    RgPrinterButton,
    // RgHistoryButton,
    RgDeleteButton,
    IconCheck,
  },

  data() {
    return {
      mutableListPatient: [],
      listAllSubprocedures: [],
      datesEnable: [],
      periodsDate: [],
      heldUnitList: [],
      subprocedureListSelected: [],
      subprocedureList: null,
      indexListAllSubprocedures: null,
      showComboHour: false,
      heldUnit: "",
      queueExamId: null,
      procedureName: null,
      getDateSchedule: null,
      requestProfessional: null,
      activeRow: null,
      personId: null,
      modalConfirmFieldClearing: false,
      buttonEditPatient: false,
      indexPatient: null,
      pesId: null,
      nameUnit: null,
      form: this.$utils.obj.DeepCopy(FORM),
      selectedScale: null,
    };
  },

  computed: {
    ...mapGetters({
      lastPersonIdSaved: "Person/Patient/GET_LAST_PERSON_ID_SAVE",
    }),

    hasSubprocedureList() {
      return this.subprocedureList && this.subprocedureList.length > 0;
    },

    individualOrLot() {
      return this.mutableListPatient && this.mutableListPatient.length > 1
        ? "Agendamento em Lote"
        : "Agendar Individual";
    },

    patientOrPatients() {
      return this.mutableListPatient && this.mutableListPatient.length > 1
        ? "Pacientes"
        : "Paciente";
    },

    patientSelect() {
      return this.activeRow !== null;
    },

    hasLot() {
      return this.mutableListPatient && this.mutableListPatient.length > 1;
    },

    submoduleId() {
      return 123;
    },
  },

  watch: {
    "form.unitHealth"(pValue, pPrev) {
      if (pValue && typeof pValue === "object") {
        this.nameUnit = pValue[0].text;
      }

      if (!pValue) {
        this.form.sector = null;
        this.heldUnit = "";
      }

      if (pValue && pPrev) {
        if (pValue !== pPrev) {
          this.form.sector = null;
          this.form.locale = null;
          this.form.responsibleProfessional = null;
          this.form.healthPlan = null;
          this.resetDataForDate();
          this.cleanFieldsValidation();
        }
      }
    },

    "form.sector"(pValue, pPrev) {
      if (pValue && pPrev) {
        this.form.locale = null;
        this.form.responsibleProfessional = null;
        this.resetDataForDate();
      }
    },

    "form.responsibleProfessional"(pValue, pPrev) {
      if (pValue && pPrev) {
        if (pValue !== pPrev[0].value) {
          this.form.date = null;
          this.form.scale = null;
          this.form.hour = null;
          this.form.vacancie = null;
          this.periodsDate = null;
          this.showComboHour = false;
        }
      }

      if (pValue && this.requestProfessional) {
        this.form.vus_id = this.requestProfessional.vus_id;
        this.form.fun_id = this.requestProfessional.fun_id;
        this.setDaysPossible();
      }
    },

    "form.date"(pDate) {
      if (pDate && pDate.length === 10) {
        this.dateIsValid = true;
        this.getDateSchedule = this.form.date ? pDate.replaceAll("-", "/") : "";
      }

      if (!pDate) {
        this.form.hour = false;
      }

      if (this.periodsDate) {
        this.periodsDate.eea_id = Number(
          this.getAttributeDayByDate(pDate, "eea_id"),
        );
      }
    },
  },

  async mounted() {
    this.form.unitHealth = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    this.nameUnit = this.$store.getters["Login/GET_UNIT_HEALTH_NAME"];

    const existData = await this.$store.getters[
      "Exam/Schedule/GET_SELECTED_PATIENT_LIST_QUEUE"
    ];

    if (existData) {
      if (this.lastPersonIdSaved) {
        const patientData = await SearchPersonById({
          pesId: this.lastPersonIdSaved,
        });
        if (patientData) {
          const index = existData.findIndex(
            (row) => Number(row.pes_id) === Number(this.lastPersonIdSaved),
          );

          existData[index].pes_nome = patientData.pes_nome;
        }
        this.$store.commit("Person/Patient/DESTROY_LAST_PERSON_ID_SAVE");
      }

      this.mutableListPatient = existData;
      this.procedureName = this.mutableListPatient[0].fle_nome_procedimento;

      await this.chargeList();
      if (this.mutableListPatient.length === 1) {
        this.selectPatient(this.mutableListPatient[0], 0);
      }
    }
  },

  beforeDestroy() {
    const validateIfExist = ValidateIfRouteExistInBreadscrumb(
      "/exam/schedule/queue/schedule",
      this.$route.meta.breadcrumb,
    );

    if (!validateIfExist) {
      this.$store.commit("Exam/Schedule/UNSELECT_PATIENT_LIST_QUEUE_DATA");
    }
  },

  methods: {
    async chargeList() {
      for (const item of this.mutableListPatient) {
        const subprocedureList = await this.$store.dispatch(
          "Exam/Schedule/SUBPROCEDURES_REGULATION_EXTERNAL",
          item.fle_id,
        );

        const obj = {
          subprocedureList: subprocedureList,
          fle_id: item.fle_id,
        };

        await this.checkPerform(obj.subprocedureList);

        this.listAllSubprocedures.push(obj);
      }
    },

    async checkPerform(subprocedureList) {
      return Promise.all(
        subprocedureList.map(async (item) => {
          const heldUnitList = await this.$store.dispatch(
            "Exam/Schedule/GET_UNITS_PERFORM_SUB_PROCEDURE",
            item.id,
          );
          const sameUnitHealth = heldUnitList.some((item) => {
            return item.uns_nome === this.nameUnit;
          });
          if (sameUnitHealth) {
            item.heldUnit = "SIM";
          } else {
            item.heldUnit = "NÃO";
          }
        }),
      );
    },

    async searchSubProceduresByFleId(fleId) {
      this.$loader.start();
      this.indexListAllSubprocedures = this.listAllSubprocedures.findIndex(
        (el) => {
          return el.fle_id === fleId;
        },
      );
      this.subprocedureList = this.listAllSubprocedures[
        this.indexListAllSubprocedures
      ].subprocedureList;
      this.$loader.finish();
    },

    formatData() {
      const patients = [];

      this.mutableListPatient.forEach((item) => {
        const objectSubprocedure = this.listAllSubprocedures.find((el) => {
          return el.fle_id === item.fle_id;
        });

        const subprocedure = objectSubprocedure
          ? objectSubprocedure.subprocedureList
          : null;

        subprocedure.forEach((el) => {
          delete el.heldUnit;
        });

        if (!this.hasLot) {
          this.subprocedureListSelected.forEach((el) => {
            delete el.heldUnit;
            delete el.selected;
          });
        }

        patients.push({
          eap_hora: this.form.hour
            ? this.form.hour
            : this.selectedScale.eea_inicio_atendimentos,
          exa_id_pacientes_exames: Number(item.pxe_id),
          vus_id: Number(this.form.vus_id),
          eap_data_pedido: item.fil_data_pedido,
          eap_id_funcionarios: Number(this.form.fun_id),
          eha_id: Number(this.selectedScale.eha_id),
          eha_id_exames_tipo_escalas: this.selectedScale
            .eea_id_exames_tipo_escalas,
          eha_data_exame: this.form.date,
          eap_custo_procedimento: null,
          eap_id: 0,
          eap_id_filas_exames: Number(item.fle_id),
          eap_id_locais_atendimento: Number(this.form.locale),
          pls_id: Number(this.form.healthPlan),
          eea_id: Number(this.form.scale),
          eap_vagas_ocupadas: Number(this.form.vacancie)
            ? Number(this.form.vacancie)
            : 1,
          eap_id_exames_procedimentos_profissionais: 0,
          eap_solicitante_externo: null,
          stp_novo_nome_procedimento: item.fle_nome_procedimento,
          stp_subProcedimentos: this.hasLot
            ? subprocedure
            : this.subprocedureListSelected,
          set_id: Number(this.form.sector),
          eap_id_sinonimos_procedimentos: item.sip_id
            ? Number(item.sip_id)
            : null,
          eap_quantidade: 1,
        });
      });

      return patients;
    },

    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    async validateSubprocedurePerformed() {
      let validate = true;
      this.listAllSubprocedures.map((el) => {
        el.subprocedureList.map((item) => {
          if (item.heldUnit === "NÃO") {
            validate = false;
          }
        });
      });
      return validate;
    },

    async validateSelectedSubprocedurePerformed() {
      let validate = true;
      this.subprocedureListSelected.map((el) => {
        if (el.heldUnit === "NÃO") {
          validate = false;
        }
      });
      return validate;
    },

    async saveForm() {
      try {
        const isValidForm = await this.isFormValid();
        const isValidAllSubprocedurePerformed = await this.validateSubprocedurePerformed();
        const isValidSelectedSubprocedurePerformed = await this.validateSelectedSubprocedurePerformed();

        const isValidSubprocedureSelected =
          this.subprocedureList?.length > 0 &&
          this.subprocedureListSelected?.length < 1;

        if (!isValidForm) {
          this.$toaster.warning("Verifique os campos.");
          return false;
        }

        if (!this.hasLot && isValidSubprocedureSelected) {
          this.$toaster.warning(
            "É obrigatório a seleção de um subprocedimento.",
          );
          return false;
        }

        if (this.hasLot && !isValidAllSubprocedurePerformed) {
          this.$toaster.warning(
            "A unidade de saúde selecionada não realiza todos os subprocedimentos dos pacientes listados.",
          );
          return false;
        }

        if (!this.hasLot && !isValidSelectedSubprocedurePerformed) {
          this.$toaster.warning(
            "A unidade de saúde selecionada não realiza todos os subprocedimentos selecionados para o paciente.",
          );
          return false;
        }

        this.$loader.start();

        const variables = this.formatData();

        const result = await this.$store.dispatch(
          "Exam/Schedule/SAVE_EXAM_SCHEDULE_QUEUE",
          variables,
        );

        if (
          result.hasError &&
          result.exception?.length > 0 &&
          result.mensagem?.length > 0
        ) {
          this.$toaster.error(result.mensagem, "Falha ao salvar agendamento.");
          return false;
        }

        this.$toaster.success("Agendamento realizado com sucesso!");

        const params = {
          isExamSchedule: true,
          examId: result.dados,
        };

        this.$router.push({
          name: "exam.schedule.queue",
          params,
        });

        return true;
      } catch (pErr) {
        this.$toaster.error(pErr.message, "Falha ao salvar agendamento.");
      } finally {
        this.$refs.save.actionDone();
        this.$loader.finish();
      }
    },

    async setDaysPossible() {
      try {
        if (!Number(this.form.responsibleProfessional)) {
          return;
        }

        this.$loader.start("Carregando datas...");
        const pData = {
          set_id: Number(this.form.sector),
          vus_id: Number(this.form.vus_id),
          dataInicial: moment().startOf("year").format("MM/DD/YYYY"),
          dataFinal: moment()
            .startOf("year")
            .add("years", 1)
            .format("MM/DD/YYYY"),
        };

        this.periodsDate = await this.$store.dispatch(
          "Exam/Schedule/GET_DAYS_ON_WEEK_OF_PERIODS",
          pData,
        );

        this.periodsDate = this.periodsDate.map((item) => {
          return {
            eea_id: item.eea_id,
            data: item.data,
          };
        });

        this.datesEnable = this.periodsDate.map((item) =>
          moment(item.data, "DD-MM-YYYY").format("YYYY-MM-DD"),
        );
        this.$loader.finish();
      } catch (Err) {
        this.periodsDate = [];
        this.datesEnable = [];
        this.$toaster.error(Err, "Erro na busca pelas datas.");
      }
    },

    getAttributeDayByDate(pDate, pAtrr) {
      if (this.periodsDate.length <= 0) return;
      return this.periodsDate.find((ele) => ele.data === pDate)[pAtrr];
    },

    editPatient() {
      this.$store.commit("Person/Patient/SET_PERSON_ID", parseInt(this.pesId));
      this.$router.push({
        name: "exam.schedule.queue.schedule.edit-patient",
      });
      this.activeRow = null;
    },

    async patientPrintCard() {
      try {
        if (this.pesId) {
          const html = await this.$store.dispatch(
            "Person/Patient/PRINT_PATIENT_CARD",
            { pes_id: parseInt(this.pesId) },
          );

          this.$utils.print.printHtml(html);
        }
      } catch (Err) {
        this.$toaster.error(
          "Erro ao gerar a impressão da carteirinha do paciente",
        );
      }
    },

    removePatientToList() {
      if (this.hasLot) {
        this.mutableListPatient.splice(this.indexPatient, 1);
        this.listAllSubprocedures.splice(this.indexListAllSubprocedures, 1);
        this.activeRow = null;
        if (this.mutableListPatient.length === 1) {
          this.selectPatient(this.mutableListPatient[0], 0);
        }
      }
    },

    selectPatient(item, index) {
      this.activeRow = index;
      this.pesId = item.pes_id;
      this.indexPatient = index;
      this.searchSubProceduresByFleId(item.fle_id);
    },

    selectSubprocedure(item) {
      if (!this.hasLot) {
        if (item.selected) {
          item.selected = false;
          this.subprocedureListSelected.splice(
            this.subprocedureListSelected.indexOf(item),
            1,
          );
        } else {
          this.subprocedureListSelected.push(item);
          item.selected = true;
        }
        this.$forceUpdate();
      }
    },

    selectItemSector(pValue) {
      this.form.sector = pValue;
    },

    selectItemPlace(pValue) {
      this.form.locale = pValue;
    },

    selectItemProfessional(pValue) {
      this.form.responsibleProfessional = pValue.value;
      this.requestProfessional = pValue;
    },

    selectItemScale(pValue) {
      if (pValue && pValue.length > 0) {
        this.form.scale = pValue[0].value;
        this.selectedScale = pValue[0];
        if (this.selectedScale.eea_id_exames_tipo_escalas === "2") {
          this.showComboHour = true;
        } else {
          this.showComboHour = false;
        }
      } else {
        this.form.scale = null;
        this.selectedScale = null;
        this.showComboHour = false;
      }
    },

    selectItemVacancie(pValue) {
      this.form.vacancie = pValue;
    },

    selectItemHour(pValue) {
      this.form.hour = pValue;
    },

    selectItemHealthPlan(pValue) {
      this.form.healthPlan = pValue;
    },

    showConfirmFieldClearing() {
      this.modalConfirmFieldClearing = true;
    },

    confirmCleanForm() {
      this.clearForm();
      this.modalConfirmFieldClearing = false;
    },

    closeConfirmFieldClearing() {
      this.modalConfirmFieldClearing = false;
    },

    cancel() {
      this.$router.push({
        name: "exam.schedule.queue",
      });
    },

    resetDataForDate() {
      this.form.date = null;
      this.form.scale = null;
      this.form.vacancie = null;
      this.form.hour = null;
      this.periodsDate = null;
      this.showComboHour = false;
    },

    clearForm() {
      this.datesEnable = [];
      this.periodsDate = [];
      this.heldUnitList = [];
      this.heldUnit = "";
      this.getDateSchedule = null;
      this.requestProfessional = null;
      this.modalConfirmFieldClearing = false;
      this.buttonEditPatient = false;
      this.periodsDate = null;
      this.form = this.$utils.obj.DeepCopy(FORM);
      this.form.unitHealth = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
      this.nameUnit = this.$store.getters["Login/GET_UNIT_HEALTH_NAME"];

      if (!this.hasLot) {
        this.activeRow = 0;

        this.subprocedureListSelected = [];
        this.subprocedureList.forEach((el) => {
          delete el.selected;
        });
      }

      this.cleanFieldsValidation();
    },

    cleanFieldsValidation() {
      const fieldsRefs = [
        "unit",
        "sector",
        "locale",
        "employee",
        "date",
        "scale",
        "vacancie",
        "comboHour",
        "healthPlan",
      ];

      fieldsRefs.forEach((field) => {
        if (this.$refs[field]) {
          this.$refs[field].cleanValidate();
        }
      });
    },
  },
};
</script>
