import LocalStorage from "~common/local-storage/LocalStorage";

const ScheduleHealthcareBulletin = (pData) => {
  const logo = LocalStorage.getObject("client-logo");

  const html = `
  <!DOCTYPE html>
  <html>

  <head>
      <meta charset="utf-8">
      <title>Esus</title>
      <style type="text/css">
        .linha
        {
          padding: 24px 0 0 0;
          border-bottom: 1px solid black;
        }

        .linha2
        {
        padding: 0 3px 0 0;
        border-bottom: 2px solid #fff;
      }

      .linha3
      {
        border-bottom: 2px solid #000;
      }

      .ml
      {
        margin: 3px 0 -15px 0;
      }

      .barra
      {
        margin: -10px 10px 0 10px;
      }

      .cid
      {
        font-weight: normal;
      }

      .impresso
      {
        margin: 0 40px 0 0;
        float: right;
      }

      .procedimento
      {
        margin: -22px 40px 0 0;
        float: right;
        text-transform: uppercase;
      }

      .barra
      {
        margin: -20px 45px 0 0;
        float: right;
        font-weight: bold;
      }

      .brasao
      {
        max-width:  150px;
        max-height: 100px;
        margin-right: 1%;
        float: left;
        width:  150px;
        height: 100px;
      }

      .logo-esus
      {
        float: left;
        max-width: 140px;
        max-height: 90px;
        margin-left: 5%;
        width: 140px;
        height: 90px;
      }

      .table-topo
      {
        width: 50%;
        float: left;
        overflow: hidden;
        table-layout: fixed;
        display: block;
        word-wrap: break-word;
      }

      .teste{
        font-size: 14px;
      }
    </style>
  </head>

  <body>
      <img src="${logo}" class="brasao">

      <table cellpadding="4" cellspacing="0" class="table-topo">
        <tr>
          <td><strong>${pData.lbl_uns_nome}</strong></td>
        </tr>
        <tr>
          <td>Boletim de Atendimento Médico: ${pData.lbl_con_id}</td>
        </tr>
      </table>

      <img src="/static/images/logo-esus.png" class="logo-esus">

      <table width="100%" cellpadding="4" cellspacing="0">
        <tr>
          <td class="impresso">Impresso em: ${pData.lbl_data_hora_impressao}</td>
        </tr>
      </table>

      <div class="linha3"></div>
    <div class="teste">
      <table width="100%" cellpadding="4" cellspacing="0">
        <tr>
          <td width="40%">Identificador: ${pData.lbl_id}</td>
          <td width="30%">Prontuário: ${pData.lbl_prontuario}</td>
          <td width="30%">No. Atendimento: ${pData.lbl_atendimento}</td>
        </tr>
      </table>

      <table width="100%" cellpadding="4" cellspacing="0">
        <tr>
          <td width="65%">Nome: ${pData.lbl_pac_nome}</td>
          <td width="25%">Sexo: ${pData.lbl_pac_sexo}</td>
          <td width="10%">Idade: ${pData.lbl_pac_idade}</td>
        </tr>
      </table>

      <div class="linha3"></div>

      <table width="100%" cellpadding="4" cellspacing="0">
        <tr>
          <td width="40%">Clínica: ${pData.lbl_uns_setor}</td>
          <td width="22%">Data de: ${pData.lbl_con_data}</td>
          <td width="19%">Hora Inicial: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;</td>
          <td width="19%">Hora Final: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;</td>
        </tr>
      </table>

      <table width="100%" cellpadding="4" cellspacing="0">
        <tr>
          <td width="100%">Médico: ${pData.lbl_con_medico}</td>
        </tr>
      </table>

      <div class="ml"></div>

      <table width="100%" cellpadding="4" cellspacing="0">
        <tr>
          <td width="60%" class="linha"><span class="linha2">Tipo de Atendimento:</span></td>
          <td width="40%" class="linha"><span class="linha2">&nbsp;Grupo de Atendimento:</span></td>
        </tr>
      </table>

      <div class="linha3 ml"></div>

      <table width="100%" cellpadding="4" cellspacing="0">
        <tr>
          <td width="100%" class="linha td"><span class="linha2">Anamnese:</span></td>
        </tr>
        <tr>
          <td width="100%" class="linha"></td>
        </tr>
        <tr>
          <td width="100%" class="linha"></td>
        </tr>
        <tr>
          <td width="100%" class="linha"></td>
        </tr>
      </table>

      <div class="ml"></div>

      <table width="100%" cellpadding="4" cellspacing="0">
        <tr>
          <td width="30%" class="linha"><span class="linha2">Pressão:</span></td>
          <td width="20%" class="linha"><span class="linha2">&nbsp;Pulso:</span></td>
          <td width="20%" class="linha"><span class="linha2">&nbsp;Temperatura:</span></td>
          <td width="30%" class="linha"><span class="linha2">&nbsp;Freq. Respiratória:</span></td>
        </tr>
      </table>

      <div class="linha3 ml"></div>

      <table width="100%" cellpadding="4" cellspacing="0">
        <tr>
          <td width="100%" class="linha"><span class="linha2">Consulta Médica:</span></td>
        </tr>
        <tr>
          <td width="100%" class="linha"></td>
        </tr>
        <tr>
          <td width="100%" class="linha"><span class="procedimento">Procedimento SIA/SUS</span></td>
        </tr>
        <tr>
          <td width="100%" class="linha"><span class="barra">|&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;|</span></td>
        </tr>
        <tr>
          <td width="100%" class="linha"><span class="barra">|&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;|</span></td>
        </tr>
        <tr>
          <td width="100%" class="linha"><span class="barra">|&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;|</span></td>
        </tr>
        <tr>
          <td width="100%" class="linha"><span class="barra">|&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;|</span></td>
        </tr>
      </table>

      <div class="linha3 ml"></div>

      <table width="100%" cellpadding="4" cellspacing="0">
        <tr>
          <td width="100%" class="linha"><span class="linha2">Exames Compl. Solicitados e:</span></td>
        </tr>
        <tr>
          <td width="100%" class="linha"></td>
        </tr>
        <tr>
          <td width="100%" class="linha"></td>
        </tr>
      </table>

      <div class="ml"></div>

      <table width="100%" cellpadding="4" cellspacing="0">
        <tr>
          <td width="100%" class="linha"><span class="linha2">Prescrição:</span></td>
        </tr>
        <tr>
          <td width="100%" class="linha"></td>
        </tr>
        <tr>
          <td width="100%" class="linha"></td>
        </tr>
        <tr>
          <td width="100%" class="linha"></td>
        </tr>
      </table>

      <div class="ml"></div>

      <table width="100%" cellpadding="4" cellspacing="0">
        <tr>
          <td width="100%" class="linha"><span class="linha2">Diagnóstico Provável:</span></td>
        </tr>
        <tr>
          <td width="100%" class="linha"><span class="barra"><span class="cid">CID 10</span> |&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|</span></td>
        </tr>
      </table>

      <div class="ml"></div>

      <table width="100%" cellpadding="4" cellspacing="0">
        <tr>
          <td width="100%" class="linha"><span class="linha2">Encaminhamento:</span></td>
        </tr>
        <tr>
          <td width="100%" class="linha"></td>
        </tr>
      </table>
    </div>
  </body>

  </html>
    `;
  return html;
};

export default ScheduleHealthcareBulletin;
