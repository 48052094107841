<template>
  <div class="modal-patient-care-history">
    <RgBaseModal
      ref="modal"
      v-shortkey="['esc']"
      :show="show"
      class="rg-base-modal"
      with-close-button
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="modal-patient-care-history-header">
        <div class="title">Histórico de Atendimentos do Paciente</div>
      </div>

      <div slot="body" class="modal-patient-care-history-body">
        <span class="information">Atendimentos prestados ao paciente</span>
        <FormBase title="Filtro" class="filter">
          <RgValidatorForm ref="validator">
            <span class="patient-name">{{
              patientInfo.pes_nome || patientInfo.pac_nome
            }}</span>
            <div class="content">
              <RgComboboxModules v-model="form.moduleId" />
              <RgInputDate
                ref="initialDate"
                v-model="form.initialDate"
                :rules="{ fn: validateDate }"
                label="Data Inicial"
              />
              <RgInputDate
                ref="finalDate"
                v-model="form.finalDate"
                :rules="{ fn: validateDate }"
                label="Data Final"
              />
              <RgRadioCustom
                label="Regulação"
                v-bind="propsRadio"
                @input="inputRadioCustom"
              />
              <div class="actions">
                <RgCleanButton @click="cleanFilter" />
                <RgSearchButton ref="search" @submit="searchFilter" />
              </div>
            </div>
          </RgValidatorForm>
        </FormBase>
        <FormBase title="Lista de Atendimentos" class="list">
          <SmartTable
            v-if="mutableAttendanceList.length > 0"
            name="ModalPatientCareHistory"
            :columns="COLUMN"
            :body="mutableAttendanceList"
            :total="Number(mutableAttendanceList.length)"
            :initial-columns="6"
            :show-pagination="false"
            class="smart-table-patient-history"
            :removeBtnColumns="true"
            dontSelect
          />
          <div v-else class="no-result">
            <IconEmpty />
            <span class="message">
              Não foram encontrados resultados para essa busca
            </span>
          </div>
        </FormBase>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import moment from "moment";
import RgComboboxModules from "$hospitalization/common/component/combobox/rg-combobox-modules/RgComboboxModules";
import { FormBase, SmartTable } from "~tokio/foundation";
import {
  RgValidatorForm,
  RgBaseModal,
  RgInputDate,
  RgCleanButton,
  RgSearchButton,
  RgRadioCustom,
  IconEmpty,
} from "~tokio/primitive";

export default {
  name: "ModalPatientCareHistory",
  components: {
    RgValidatorForm,
    RgBaseModal,
    FormBase,
    SmartTable,
    RgInputDate,
    RgCleanButton,
    RgSearchButton,
    RgRadioCustom,
    RgComboboxModules,
    IconEmpty,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    patientInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      mutableAttendanceList: [],
      form: {
        patientId: null,
        patientName: null,
        moduleId: null,
        initialDate: null,
        finalDate: null,
        regulation: null,
      },
    };
  },
  computed: {
    propsRadio() {
      const list = [
        { title: "Sim", blue: true, id: 1 },
        { title: "Não", blue: true, id: 2 },
      ];
      const value = this.form.regulation;
      const uniqueKey = "id";
      return { list, uniqueKey, value };
    },
  },

  watch: {
    show(pValue) {
      if (!pValue) {
        this.close();
      }

      if (pValue && this.patientInfo?.pes_id) {
        this.searchFilter();
      }
    },
  },

  created() {
    this.COLUMN = [
      { name: "Módulo", key: "nome_modulo" },
      { name: "Unidade de Saúde", key: "uns_nome", align: "left" },
      { name: "Setor", key: "set_nome", align: "left" },
      { name: "Data", key: "dataHora", align: "center" },
      { name: "Usuário", key: "usu_nome", align: "left" },
      { name: "Regulação", key: "regulacao", align: "center" },
    ];
  },
  methods: {
    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    generateFilter() {
      const variables = {
        arrFiltro: {
          pes_id: this.patientInfo.pes_id,
          mod_id: this.form.moduleId,
          periodoInicial: this.form.initialDate,
          periodoFinal: this.form.finalDate,
          rdg_regulacao: this.form.regulation,
        },
      };

      return variables;
    },

    async searchFilter() {
      try {
        if (!(await this.isFormValid())) {
          this.$toaster.warning("Verifique os campos");
          this.$refs.search.actionDone();
          return false;
        }

        this.$loader.start();

        this.$refs.search.actionSubmit();
        const variables = this.generateFilter();
        this.mutableAttendanceList = await this.$store.dispatch(
          "Person/Patient/SEARCH_PATIENTS_GENERAL_HISTORY",
          variables,
        );
        this.$refs.search.actionDone();
      } catch (pErr) {
        this.$toaster.error(pErr);
        this.$refs.search.actionDone();
      } finally {
        this.$loader.finish();
      }
    },

    selectingPatient(pPatient) {
      const source = pPatient.source ? pPatient.source : null;
      const hasPerson = source && source.pes_id > 0;

      if (hasPerson) {
        this.form.patientId = source.pes_id;
        this.form.patientName = source.pes_nome;
      }
    },

    inputRadioCustom(pRadio) {
      pRadio
        ? (this.form.regulation = pRadio.id)
        : (this.form.regulation = null);
    },

    validateDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialDate, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalDate, "DD/MM/YYYY");

      const validation = initialDate.isAfter(finalDate);

      if (validation) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }
    },

    cleanFilter() {
      this.$refs.initialDate.cleanValidate();
      this.$refs.finalDate.cleanValidate();
      this.mutableAttendanceList = [];
      this.form = {
        patientId: null,
        patientName: null,
        moduleId: null,
        initialDate: null,
        finalDate: null,
        regulation: null,
      };
    },

    close() {
      this.cleanFilter();
      this.$emit("close");
    },
  },
};
</script>
