import TYPE_SCALE_SEARCH from "./type-scale/Search";
import TYPE_SCALE_CHECK_DUPLICITY from "./type-scale/CheckDuplicityColor";
import TYPE_SCALE_DELETE from "./type-scale/DeleteTypeScale";
import TYPE_SCALE_LOAD from "./type-scale/LoadTypeScale";
import TYPE_SCALE_REGISTER from "./type-scale/RegisterTypeScale";
import TYPE_SCALE_UPDATE from "./type-scale/UpdateTypeScale";

import LIST_SERVICE_SCALE_SEARCH from "./list-service-scale/Search";
import REGISTER_SERVICE_SCALE from "./list-service-scale/Register";
import EDIT_SERVICE_SCALE from "./list-service-scale/Edit";

import REMOVE_TYPE_SCALE from "./list-service-scale/Remove";
import SEARCH_HISTORY_SCALE_LIST from "./list-service-scale/SearchHystoryScaleList";

import PRINT_SERVICE_SCALE from "./PrintServiceScale";
import SPLIT_SERVICE_SCALE from "./SplitServiceScale";

import SEARCH_EXAM_PRIORITY from "./exam-priority/SearchExamPriorities";
import ADD_EXAM_PRIORITY from "./exam-priority/AddExamPriority";
import DELETE_EXAM_PRIORITY from "./exam-priority/DeleteExamPriority";
import ACTIVE_EXAM_PRIORITY from "./exam-priority/ActiveExamPriority";
import EDIT_EXAM_PRIORITY from "./exam-priority/EditExamPriority";

export default {
  TYPE_SCALE_SEARCH,
  TYPE_SCALE_CHECK_DUPLICITY,
  TYPE_SCALE_DELETE,
  TYPE_SCALE_LOAD,
  TYPE_SCALE_REGISTER,
  TYPE_SCALE_UPDATE,
  LIST_SERVICE_SCALE_SEARCH,
  REMOVE_TYPE_SCALE,
  REGISTER_SERVICE_SCALE,
  EDIT_SERVICE_SCALE,
  SEARCH_HISTORY_SCALE_LIST,
  PRINT_SERVICE_SCALE,
  SEARCH_EXAM_PRIORITY,
  ADD_EXAM_PRIORITY,
  DELETE_EXAM_PRIORITY,
  ACTIVE_EXAM_PRIORITY,
  SPLIT_SERVICE_SCALE,
  EDIT_EXAM_PRIORITY,
};
