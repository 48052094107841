import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query searchCustomHospitalizationOutputDetail(
    $int_id: Int!
    $inl_id: Int!
    $int_id_ocupacoes_cbo: Int!
  ) {
    customHospitalizationOutputDetail(
      int_id: $int_id
      inl_id: $inl_id
      int_id_ocupacoes_cbo: $int_id_ocupacoes_cbo
    ) {
      count
      rows {
        int_id
        inl_id
        int_id_ocupacoes_cbo
        isa_id
        isa_data
        isa_hora
        usu_nome
        isa_causa_obito
        ild_nome
        igs_nome
        nome_funcionario
        ocupacao
        ocp_codigo
        observacao
      }
    }
  }
`;
export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    return data.customHospitalizationOutputDetail.rows[0];
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors);
  }
};
