import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query {
    surgerySuspendedReasons {
      rows {
        msu_id
        msu_nome
      }
      count
    }
  }
`;

export default async (pStore) => {
  const { data } = await GraphQLClient.query({ query }).catch((err) => {
    throw err;
  });
  return data.surgerySuspendedReasons;
};
