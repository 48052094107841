import AuthRequest from "~common/request/AuthRequest";
import Sanitize from "~common/model/BaseSanitizer";

export default (pFilter) => {
  return AuthRequest.get("/billing/bulletin/search-bulletin", pFilter).then(
    (pResult) => {
      const baseSanitizer = new Sanitize();
      return baseSanitizer.sanitize(pResult.data);
    },
  );
};
