var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modulebox',{staticClass:"exam-type-scale",attrs:{"granted":_vm.hasViewPermission,"title":_vm.drawTitle}},[_c('section',{staticClass:"exam-type-scale-body"},[_c('form',{staticClass:"form-container",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.insertUpdateTypeScale($event)}}},[_c('RgValidatorForm',{directives:[{name:"shortkey",rawName:"v-shortkey",value:(['enter']),expression:"['enter']"}],ref:"validator",nativeOn:{"shortkey":function($event){return (function () {})($event)}}},[_c('FormBase',{attrs:{"title":"Tipo de Escala"}},[_c('section',{staticClass:"form-grid"},[_c('RgInput',{ref:"type",class:{
                disable: !_vm.hasInsertOrUpdatePermission || _vm.disabledByModal,
              },attrs:{"label":"Tipo","placeholder":"Digite a tipificação da escala","maxlength":50,"rules":{ required: true },"disabled":!_vm.hasInsertOrUpdatePermission || _vm.disabledByModal},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}}),_c('RgInputColor',{staticClass:"color",class:{
                disable: !_vm.hasInsertOrUpdatePermission || _vm.disabledByModal,
              },attrs:{"label":"Cor","rules":{ required: true },"disabled":!_vm.hasInsertOrUpdatePermission || _vm.disabledByModal},model:{value:(_vm.form.color),callback:function ($$v) {_vm.$set(_vm.form, "color", $$v)},expression:"form.color"}}),_c('div',{staticClass:"actions"},[(!_vm.editMode)?_c('RgCleanButton',{attrs:{"permission":_vm.hasViewPermission,"disabled":_vm.disabledByModal,"small":"","title":"Limpar"},on:{"click":_vm.clearForm}}):_vm._e(),(_vm.editMode)?_c('MediumButton',{attrs:{"permission":_vm.hasViewPermission,"disabled":!_vm.selectedRow.ees_id || _vm.disabledByModal,"backgroundColor":"#fb7e36","label":"Cancelar"},on:{"click":_vm.clearForm}}):_vm._e(),(!_vm.editMode)?_c('RgAddButton',{ref:"insertBtn",attrs:{"disabled":_vm.disabledByModal,"permission":_vm.hasInsertPermission,"large":""},on:{"click":_vm.insertUpdateTypeScale}}):_vm._e(),(_vm.editMode)?_c('RgSaveButton',{ref:"updateBtn",attrs:{"disabled":_vm.disabledByModal,"permission":_vm.hasUpdatePermission,"large":""},on:{"click":_vm.insertUpdateTypeScale}}):_vm._e()],1)],1)]),_c('FormBase',{attrs:{"title":"Tipos Cadastrados"}},[_c('section',{staticClass:"form-table"},[_c('RgList',{ref:"rgList",staticClass:"list",class:{
                disable: _vm.editMode,
              },attrs:{"search-function":_vm.search,"build-filter":_vm.generateFilter,"register-per-page":5,"max-register":8,"disabled":_vm.disabledByModal || _vm.editMode,"backendLegacy":""},model:{value:(_vm.mutableGridList),callback:function ($$v) {_vm.mutableGridList=$$v},expression:"mutableGridList"}},[_c('RgTable',_vm._b({class:{
                  disable: _vm.editMode,
                },attrs:{"disabled":_vm.editMode}},'RgTable',_vm.propsRgTable,false),[_c('div',{staticClass:"my-header",attrs:{"slot":"header"},slot:"header"},[_c('RgEditButton',{attrs:{"small":"","disabled":!_vm.selectedRow.ees_id || _vm.disabledByModal || _vm.editMode,"permission":_vm.hasUpdatePermission},on:{"click":_vm.clickEdit}}),_c('RgLessButton',{attrs:{"backgroundColor":"#F96B70","title":"Remover","disabled":!_vm.selectedRow.ees_id || _vm.disabledByModal || _vm.editMode,"permission":_vm.hasDeletePermission},on:{"click":_vm.clickDelete}})],1),_vm._l((_vm.mutableGridList),function(item){return _c('tr',{key:item.ees_id,staticClass:"tr",class:{
                    selected: _vm.selectedRow.ees_id === item.ees_id,
                  },attrs:{"slot":"rows"},on:{"click":function($event){return _vm.clickSelectRow(item)}},slot:"rows"},[_c('td',{staticClass:"data"},[_c('IconCircleTable',{attrs:{"color":item.ees_cor}})],1),_c('td',{staticClass:"data text"},[_vm._v(" "+_vm._s(item.ees_nome)+" ")])])})],2)],1)],1)])],1)],1)]),(_vm.showModalDeletion)?_c('GenericTypeScale',_vm._b({on:{"close":_vm.onCloseModalDeletion}},'GenericTypeScale',_vm.propsModalDeletion,false),[_c('div',{staticClass:"modal-message",attrs:{"slot":"message"},slot:"message"},[_c('span',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.selectedRow.ees_nome)+" selecionada para exclusão ")])]),_c('div',{staticClass:"footer",attrs:{"slot":"footer"},slot:"footer"},[_c('div',{staticClass:"footer-buttons"},[_c('RgCancelButton',{attrs:{"medium":""},on:{"click":_vm.onCloseModalDeletion}}),_c('LargeButton',{attrs:{"backgroundColor":"#F96B70","label":"Excluir","separator":""},on:{"click":_vm.actionRemove}},[_c('IconError',{staticClass:"icon",attrs:{"slot":"icon"},slot:"icon"})],1)],1)])]):_vm._e(),(_vm.showModalDuplicityColor)?_c('GenericTypeScale',_vm._b({staticClass:"generic-type",on:{"close":_vm.onCloseModalDuplicityColor}},'GenericTypeScale',_vm.propsModalDuplicityColor,false),[_c('div',{staticClass:"modal-message",attrs:{"slot":"message"},slot:"message"},[_c('div',{staticClass:"information"},[_vm._v("Manter a cor do novo tipo de escala:")]),_c('div',{staticClass:"scale"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.form.type))]),_c('div',{staticClass:"square-color",style:({ backgroundColor: _vm.form.color })})])]),_c('div',{staticClass:"footer",attrs:{"slot":"footer"},slot:"footer"},[_c('RgCancelButton',{attrs:{"medium":""},on:{"click":_vm.onCloseModalDuplicityColor}}),_c('RgConfirmButton',{attrs:{"medium":""},on:{"click":_vm.confirmDuplicityColor}})],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }