<template>
  <div class="modal-confirm-patient-unification">
    <RgBaseModal
      ref="modal"
      v-shortkey="['esc']"
      :show="show"
      class="rg-base-modal"
      with-close-button
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <div class="title">Confirmar Unificação do Paciente</div>
      </div>

      <div slot="body" class="body">
        <FormBase title="Prévia do Cadastro Unificado">
          <div v-if="dataUnification" class="patient-unification">
            <div class="icon">
              <RgTypePatient
                :pac-id="isPatient(patientInfo)"
                :sex="patientSex(patientInfo)"
                class="patient-type"
              />
            </div>
            <div class="column">
              <span
                >Nome: <b>{{ dataUnification.pes_nome }}</b></span
              >
              <span
                >Nome da Mãe: <b>{{ dataUnification.pes_mae }}</b></span
              >
              <span
                >Data de Nascimento:
                <b>{{ dataUnification.pes_nascimento }}</b></span
              >
              <span
                >Prontuário Único:
                <b>{{ dataUnification.pac_prontuario_unico }}</b></span
              >
            </div>

            <div class="column">
              <span
                >CPF:
                <b>{{ dataUnification.cpf_numero || "Não informado" }}</b></span
              >
              <span
                >CNS:
                <b>{{ dataUnification.crs_numero || "Não informado" }}</b></span
              >
              <span
                >PIS:
                <b>{{ dataUnification.pis_numero || "Não informado" }}</b></span
              >
            </div>
          </div>
        </FormBase>
      </div>

      <div slot="footer" class="footer">
        <RgCancelButton medium @click="close"> </RgCancelButton>
        <RgSaveButton title="Unificar" label="Unificar" medium @click="save">
        </RgSaveButton>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { RgBaseModal, RgCancelButton, RgSaveButton } from "~tokio/primitive";

import FormBase from "~tokio/foundation/form-base/FormBase";
import RgTypePatient from "$hospitalization/common/component/status/rg-type-patient/RgTypePatient";

export default {
  name: "ModalPatientCareHistory",
  components: {
    RgBaseModal,
    FormBase,
    RgTypePatient,
    RgCancelButton,
    RgSaveButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    patientInfo: {
      type: Object,
      default: () => {},
    },
    dataUnification: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },

  watch: {
    show(pValue) {
      if (!pValue) {
        this.close();
      }
    },
  },

  methods: {
    isPatient(pItem) {
      return pItem?.patient?.pac_id ? pItem.patient.pac_id : 0;
    },

    patientSex(pItem) {
      return pItem?.pes_id_sexos ? pItem.pes_id_sexos : 1;
    },

    save() {
      this.$emit("save");
    },

    close() {
      this.$emit("close");
    },
  },
};
</script>
