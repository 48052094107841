<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchUnitHealthPep from "./action/SearchUnitHealthPep";
export default {
  name: "RgComboboxUnitHealthPep",
  mixins: [RgComboboxMixin],
  data: () => {
    return {};
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      const data = await SearchUnitHealthPep();

      this.mData = [];

      data.forEach((item) => {
        this.mData.push({ value: item.data, text: item.label });
      });

      if (this.mData.length === 1) {
        this.$emit("selectOnlyItem", this.mData[0].value);
      }
    },
    // },
  },
};
</script>
