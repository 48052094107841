import RgInputCpfHelper from "~tokio/primitive/input/rg-input-cpf/RgInputCpfHelper";
import RgInputCnpjHelper from "~tokio/primitive/input/rg-input-cnpj/RgInputCnpjHelper";
import RgInputSuscardHelper from "~tokio/primitive/input/rg-input-suscard/RgInputSuscardHelper";

const TYPES = {
  CPF: 1,
  CNS: 2,
  CNPJ: 3,
  CNES_PROPRIO: 4,
  CNES_TERCEIROS: 5,
};

/**
 * Valida documento dependendo do seu tipo
 *
 * @param {string|number} pDocument
 * @param {number} pDocumentType
 * @param {string[]} pErrors
 * @returns {boolean}
 */
const DocumentValid = (pDocument, pDocumentType, pErrors) => {
  switch (pDocumentType) {
    case TYPES.CPF:
      return RgInputCpfHelper.checkDigit(pDocument, pErrors);

    case TYPES.CNS:
      return RgInputSuscardHelper.checkDigit(pDocument, pErrors);

    case TYPES.CNPJ:
      return RgInputCnpjHelper.checkDigit(pDocument, pErrors);

    default:
      return true;
  }
};

export default DocumentValid;
