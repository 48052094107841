<style src="./RgBedStatus.scss" lang="scss" scoped></style>
<template lang="html">
  <div :class="{ 'tiny-bed': tinyBed, 'small-bed': !tinyBed }">
    <div v-if="isActive === 0" title="Leito Inativo">
      <IconDeadBed class="svg-inactive" />
    </div>
    <div v-else-if="generateSituationStatus === 1" title="Leito Vazio">
      <IconBed class="svg" :color="'#66D48F'" :shadow="'#66D48F'" />
    </div>
    <div v-else-if="generateSituationStatus === 2" title="Leito em Reserva">
      <IconBed class="svg" :color="'#5295E2'" :shadow="'#5295E2'" />
    </div>
    <div v-else-if="generateSituationStatus === 3" title="Leito Ocupado">
      <IconBed class="svg" :color="'#F96B70'" :shadow="'#F96B70'" />
    </div>
    <div v-else-if="generateSituationStatus === 4" title="Leito em Desinfecção">
      <IconBed class="svg" :color="'#EDC76A'" :shadow="'#EDC76A'" />
    </div>
    <div v-else-if="generateSituationStatus === 5" title="Leito em Manutenção">
      <IconBed class="svg" :color="'#ABABAB'" :shadow="'#ABABAB'" />
    </div>
  </div>
</template>
<script>
import { IconBed, IconDeadBed } from "~tokio/primitive/icon/symbols";
export default {
  name: "RgBedStatus",
  components: {
    IconBed,
    IconDeadBed,
  },
  props: {
    generateSituationStatus: {
      type: Number,
      default: 0,
    },
    isActive: {
      type: Number,
      default: 1,
    },
    tinyBed: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
