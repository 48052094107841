<template lang="html">
  <svg
    width="12"
    height="10"
    viewBox="0 0 12 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.625 0H1.37503C0.616516 0 0 0.685018 0 1.52781V8.47219C0 9.31498 0.616516 10 1.37503 10H10.625C11.3835 10 12 9.31498 12 8.47219V1.52781C12 0.685018 11.3835 0 10.625 0ZM3.75 2.22219C4.43903 2.22219 4.99997 2.84556 4.99997 3.61115C4.99997 4.37663 4.43903 5 3.75 5C3.06097 5 2.50003 4.37663 2.50003 3.61115C2.50003 2.84556 3.06097 2.22219 3.75 2.22219ZM6 7.36115C6 7.59115 5.832 7.77781 5.625 7.77781H1.875C1.668 7.77781 1.5 7.59115 1.5 7.36115V7.08333C1.5 6.24054 2.11652 5.55552 2.87503 5.55552H4.62497C5.38348 5.55552 6 6.24054 6 7.08333V7.36115ZM10.125 7.77781H7.37503C7.16803 7.77781 7.00003 7.59115 7.00003 7.36115C7.00003 7.13114 7.16803 6.94448 7.37503 6.94448H10.125C10.332 6.94448 10.5 7.13114 10.5 7.36115C10.5 7.59115 10.332 7.77781 10.125 7.77781ZM10.125 5.55552H7.37503C7.16803 5.55552 7.00003 5.36886 7.00003 5.13885C7.00003 4.90885 7.16803 4.72219 7.37503 4.72219H10.125C10.332 4.72219 10.5 4.90885 10.5 5.13885C10.5 5.36886 10.332 5.55552 10.125 5.55552ZM10.125 3.33333H7.37503C7.16803 3.33333 7.00003 3.14667 7.00003 2.91667C7.00003 2.68667 7.16803 2.5 7.37503 2.5H10.125C10.332 2.5 10.5 2.68667 10.5 2.91667C10.5 3.14667 10.332 3.33333 10.125 3.33333Z"
      fill="#D0D0D0"
    />
  </svg>
</template>

<script>
export default {
  name: "CardId",
};
</script>
