module.exports = {
  checkEmail(pEmail, pError) {
    if (!pEmail) return true;

    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    if (!re.test(pEmail)) {
      pError.push("E-mail inválido");
      return false;
    }
    return true;
  },
};
