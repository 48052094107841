<template lang="html">
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.2096 23.3038H18.4157V27.5098C18.4157 28.0782 18.8704 28.5329 19.4387 28.5329H24.6679C25.2363 28.5329 25.691 28.0782 25.691 27.5098V23.3038H29.897C30.4654 23.3038 30.9201 22.8491 30.9201 22.2807V17.0515C30.9201 16.4832 30.4654 16.0284 29.897 16.0284H25.691V11.8224C25.691 11.254 25.2363 10.7993 24.6679 10.7993H19.4387C18.8704 10.7993 18.4157 11.254 18.4157 11.8224V15.9148H14.2096C13.6412 15.9148 13.1865 16.3695 13.1865 16.9379V22.167C13.1865 22.8491 13.6412 23.3038 14.2096 23.3038ZM15.2327 18.0746H19.4387C20.0071 18.0746 20.4618 17.6199 20.4618 17.0515V12.8455H23.6448V17.0515C23.6448 17.6199 24.0995 18.0746 24.6679 18.0746H28.8739V21.2576H24.6679C24.0995 21.2576 23.6448 21.7123 23.6448 22.2807V26.4867H20.4618V22.2807C20.4618 21.7123 20.0071 21.2576 19.4387 21.2576H15.2327V18.0746V18.0746Z"
      fill="white"
    />
    <path
      d="M19.4388 31.7158H8.86684C8.29846 31.7158 7.84375 32.1705 7.84375 32.7389V43.3108C7.84375 43.8792 8.29846 44.3339 8.86684 44.3339H19.4388C20.0072 44.3339 20.4619 43.8792 20.4619 43.3108V32.7389C20.4619 32.1705 20.0072 31.7158 19.4388 31.7158ZM18.4157 42.2878H10.0036V33.8757H18.4157V42.2878V42.2878Z"
      fill="white"
    />
    <path
      d="M23.6445 32.7389V43.3108C23.6445 43.8792 24.0992 44.3339 24.6676 44.3339H35.2396C35.8079 44.3339 36.2627 43.8792 36.2627 43.3108V32.7389C36.2627 32.1705 35.8079 31.7158 35.2396 31.7158H24.6676C24.0992 31.7158 23.6445 32.1705 23.6445 32.7389ZM25.6907 33.762H34.1028V42.1741H25.6907V33.762Z"
      fill="white"
    />
    <path
      d="M28.9876 61.7265H15.2327V52.746H28.9876V61.7265ZM4.66075 35.8082V7.50267H30.3517C30.9201 7.50267 31.3748 7.04796 31.3748 6.47958C31.3748 5.91119 30.9201 5.45649 30.3517 5.45649H2.04618V2.27354H42.0604V5.45649H34.7851C34.2167 5.45649 33.762 5.91119 33.762 6.47958C33.762 7.04796 34.2167 7.50267 34.7851 7.50267H39.4458V61.7265H31.0338V52.746H31.3748C32.2842 52.746 32.9663 52.064 32.9663 51.1545C32.9663 50.2451 32.2842 49.5631 31.3748 49.5631H12.8455C11.9361 49.5631 11.254 50.2451 11.254 51.1545C11.254 52.064 11.9361 52.746 12.8455 52.746H13.1865V61.7265H4.66075V40.3553V35.8082ZM41.492 61.7265V28.5329V21.1439V7.50267H43.0835C43.6519 7.50267 44.1066 7.04796 44.1066 6.47958V1.25045C44.1066 0.682063 43.6519 0.227356 43.0835 0.227356H1.02309C0.454707 0.227356 0 0.682063 0 1.25045V6.47958C0 7.04796 0.454707 7.50267 1.02309 7.50267H2.61457V21.2576V28.6465V35.9219V40.4689V61.9538L2.72824 62.9769C2.72824 63.5453 3.18295 64 3.75133 64H40.3553C40.9236 64 41.3783 63.5453 41.3783 62.9769"
      fill="white"
    />
    <path
      d="M55.8147 0C52.0634 0 48.9941 3.06927 48.9941 6.82061C48.9941 10.5719 52.0634 13.6412 55.8147 13.6412C59.5661 13.6412 62.6354 10.5719 62.6354 6.82061C62.6354 3.06927 59.6798 0 55.8147 0ZM59.3387 7.61634H56.6105V10.3446C56.6105 10.7993 56.2695 11.1403 55.8147 11.1403C55.36 11.1403 55.019 10.7993 55.019 10.3446V7.61634H52.2908C51.8361 7.61634 51.495 7.27531 51.495 6.82061C51.495 6.3659 51.8361 6.02487 52.2908 6.02487H55.019V3.29663C55.019 2.84192 55.36 2.50089 55.8147 2.50089C56.2695 2.50089 56.6105 2.84192 56.6105 3.29663V6.02487H59.3387C59.7934 6.02487 60.1345 6.3659 60.1345 6.82061C60.1345 7.27531 59.7934 7.61634 59.3387 7.61634Z"
      fill="white"
    />
    <path
      d="M55.8147 16.1421C52.0634 16.1421 48.9941 19.2114 48.9941 22.9627C48.9941 26.714 52.0634 29.7833 55.8147 29.7833C59.5661 29.7833 62.6354 26.714 62.6354 22.9627C62.6354 19.2114 59.6798 16.1421 55.8147 16.1421ZM59.4524 23.3037L56.6105 26.1456C56.3831 26.373 56.1558 26.4867 55.8147 26.4867C55.4737 26.4867 55.2464 26.373 55.019 26.1456L51.154 22.3943C50.9266 22.167 50.813 21.9396 50.813 21.5986C50.813 21.2575 50.9266 21.0302 51.154 20.8028C51.3814 20.5755 51.6087 20.4618 51.9497 20.4618C52.2908 20.4618 52.5181 20.5755 52.7455 20.8028L55.8147 23.8721L57.8609 21.8259C58.0883 21.5986 58.3156 21.4849 58.6567 21.4849C58.9977 21.4849 59.2251 21.5986 59.4524 21.8259C59.9071 22.167 59.9071 22.849 59.4524 23.3037Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: "RegisterUnitHealth",
};
</script>
