<template>
  <aside
    v-shortkey.push="['f2']"
    class="modal-shortcut-help"
    @shortkey="showModalToggle"
  >
    <div
      v-if="modalIsShowing"
      tabindex="0"
      class="modal-container"
      @keydown.esc="closeModal"
    >
      <div class="form-box">
        <span class="shortcut-title">Atalhos de teclado</span>
        <div class="row">
          <div class="screen-container col-6">
            <span class="title">Geral1</span>
            <div class="shortcut-container">
              <span class="shortcut">F2</span>
              <span class="description"
                >Mostra a lista de teclas de atalho</span
              >
            </div>
          </div>
          <div class="screen-container col-6">
            <div class="shortcut-container">
              <span class="shortcut">F10</span>
              <span class="description">Abre / Fecha o menu</span>
            </div>
          </div>
        </div>

        <slot />
      </div>
    </div>
  </aside>
</template>

<script>
export default {
  name: "ModalShortcutHelp",
  data() {
    return {
      modalIsShowing: false,
    };
  },
  methods: {
    showModalToggle() {
      this.modalIsShowing = !this.modalIsShowing;
    },
    closeModal() {
      this.modalIsShowing = false;
    },
  },
};
</script>
