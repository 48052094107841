<script>
export default {
  name: "RgBaseSelectionMixin",
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {},
    disable: {
      default: undefined,
    },
    /**
     * the Data to be render on the selection
     */
    data: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data: function () {
    return {
      selectedItem: "",
    };
  },
};
</script>
