<template lang="html">
  <div
    v-if="show"
    v-shortkey="['enter']"
    class="modal-delete-register-exit"
    @shortkey.native="
      {
      }
    "
  >
    <form class="form-container" @submit.stop.prevent="saveForm">
      <RgValidatorForm ref="validator">
        <RgBaseModal
          ref="Modal"
          v-shortkey="['esc']"
          :show="show"
          with-close-button
          class="rg-base-modal"
          @close="close"
          @shortkey.native="close"
        >
          <div slot="header" class="header">
            <div class="title">Excluir Saída</div>
          </div>

          <div slot="body" class="body">
            <div class="informations">
              <RgTypePatient
                :pac-id="Number(isPatient)"
                :sex="isPatient ? patientGender : null"
                class="patient-type"
              />

              <div class="area">
                <span class="data">
                  Paciente:
                  <strong>
                    {{ bulletinData && bulletinData.pes_nome }}
                  </strong>
                </span>

                <span class="data">
                  Boletim:
                  <strong>
                    {{ bulletinData && bulletinData.bol_numero }}
                  </strong>
                </span>
              </div>

              <div class="area">
                <span class="data">
                  Unidade de Saúde:
                  <strong>
                    {{ bulletinData && bulletinData.uns_nome }}
                  </strong>
                </span>

                <span class="data">
                  Setor:
                  <strong>
                    {{ bulletinData && bulletinData.set_nome }}
                  </strong>
                </span>
              </div>

              <div class="area">
                <span class="data">
                  Local de Atendimento:
                  <strong>
                    {{ bulletinData && bulletinData.lca_nome }}
                  </strong>
                </span>
              </div>
            </div>

            <div class="texts-area">
              <RgTextArea
                v-model="form.reason"
                :rules="{ required: true }"
                :maxlength="255"
                class="area"
                label="Motivo"
              />
            </div>
          </div>

          <div slot="footer" class="footer">
            <RgCleanButton small @click="cleanForm" />
            <RgSaveButton
              ref="submit"
              medium
              :disabled="!hasValidReason"
              :class="{ disable: !hasValidReason }"
              @click="saveForm"
            />
          </div>
        </RgBaseModal>
      </RgValidatorForm>
    </form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  RgValidatorForm,
  RgBaseModal,
  RgTextArea,
  RgSaveButton,
  RgCleanButton,
} from "~tokio/primitive";
import RgTypePatient from "$hospitalization/common/component/status/rg-type-patient/RgTypePatient";

export default {
  name: "ModalDeleteRegisterExit",
  components: {
    RgValidatorForm,
    RgBaseModal,
    RgTextArea,
    RgSaveButton,
    RgCleanButton,
    RgTypePatient,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    bulletinData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        reason: null,
      },
      patientGender: null,
    };
  },

  computed: {
    ...mapGetters({
      unitHealthId: "Login/GET_UNIT_HEALTH_ID",
    }),

    permission() {
      return !!this.$Permissions.global.has(
        "emergencia.boletim.excluirSaida",
        this.unitHealthId,
      );
    },

    isPatient() {
      return this.bulletinData && this.bulletinData.pac_id
        ? this.bulletinData.pac_id
        : 0;
    },

    hasValidReason() {
      return this.form.reason && this.form.reason.length >= 5;
    },
  },

  watch: {
    show(pValue) {
      if (!pValue) {
        this.close();
      }

      this.bulletinData.pep_sexo === "M"
        ? (this.patientGender = 1)
        : (this.patientGender = 0);
    },
  },

  methods: {
    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    async saveForm() {
      if (!(await this.isFormValid())) {
        this.$toaster.warning("Verifique os Campos");
        return false;
      }

      if (!this.permission) {
        this.$toaster.warning(
          "Você não possui permissão para excluir a saída.",
          "Usuário sem permissão!",
        );
        this.$refs.submit.actionDone();
        return false;
      }

      try {
        this.$loader.start();

        const variables = {
          idDestiny: this.bulletinData.saida,
          reason: this.form.reason,
        };

        await this.$store.dispatch(
          "Emergency/Bulletin/DELETE_REGISTER_EXIT",
          variables,
        );

        this.$refs.submit.actionDone();
        this.$toaster.success("Cadastro de saída realizado com sucesso!");
        this.$emit("reSearch");
        this.close();
      } catch (pErr) {
        this.$refs.submit.fail();

        const error = pErr.toString();

        if (error.includes("Error:")) {
          this.$toaster.error(
            error.substring(6, error.length),
            "Erro ao salvar cadastro de saída.",
          );
        } else {
          this.$toaster.error(pErr, "Erro ao salvar cadastro de saída.");
        }
      } finally {
        this.$loader.finish();
      }
    },

    cleanForm() {
      this.form = {
        reason: null,
      };
    },

    close() {
      this.$emit("close");
      this.cleanForm();
    },
  },
};
</script>
