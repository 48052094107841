// import { GraphQLClient, gql } from '@/common/request/GraphQLClient'

// const query = gql`query NotEffectivedExamsReason($emn_id: Int) {
//   notEffectivedExamsReason(emn_id: $emn_id) {
//     rows {
//       emn_id
//       emn_nome
//     }
//     count
//   }
// }`

export default async ({ commit }, emn_id) => {
  throw new Error("NÃO IMPLEMENTADO AINDA");

  // return new Promise(async (resolve, reject) => {
  //   const { data } = await GraphQLClient.query({ query, variables: { emn_id }, fetchPolicy: 'no-cache' }).catch((err) => { return reject(err) })
  //   return data.notEffectivedExamsReason.count === 1
  //     ? resolve(data.notEffectivedExamsReason.rows[0])
  //     : resolve(data.notEffectivedExamsReason)
  // })
};
