import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query GetSignature($toSign: String) {
    GetSignature(toSign: $toSign) {
      key
    }
  }
`;

const QZSignRequest = (variables) => {
  return GraphQLClient.query({ query, variables, fetchPolicy: "no-cache" });
};

export { QZSignRequest };
export default QZSignRequest;
