<style src="./PrintFrameInfo.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="patient-frame-info">
    <span class="label">{{ label }}</span>
    <slot />
  </section>
</template>

<script>
export default {
  name: "PrintFrameInfo",
  props: {
    label: {
      default: "",
    },
  },
};
</script>
