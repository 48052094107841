import BaseModel from "~common/model/BaseModel";

class UnitHealthInfo extends BaseModel {
  defineEndPointURI() {
    return "surgery-center/unit-health";
  }

  showHeaderInfo(pUnitHealthId) {
    return this._request.get(
      `${this._endPointURI}/${pUnitHealthId}/header-info`,
    );
  }
}
export default new UnitHealthInfo();
