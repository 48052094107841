import { GraphQLClient, gql } from "@/common/request/GraphQLClient";
import {
  preparePatientToSave,
  prepareHospitalizationToSave,
  prepareAihToSave,
  prepareCidToSave,
  prepareHospitalizationProcedureToSave,
} from "./HospitalizationBillingAihHelper";

const mutation = gql`
  mutation SaveTabs(
    $main: HospitalizationBillingAihInputType
    $patient: HospitalizationPatientBillingInputType
    $hospitalization: HospitalizationBillingInputType
    $cid: [HospitalizationAihSecondaryCidType]
    $procedure: [HospitalizationAihProceduresInputType]
  ) {
    SaveHospitalizationBillingAihTabs(
      hospitalizationBillingAih: $main
      hospitalizationPatientTab: $patient
      hospitalizationBillingTab: $hospitalization
      hospitalizationCidTab: $cid
      hospitalizationProceduresTab: $procedure
    ) {
      hospitalizationBillingAih {
        iaf_id
      }
      hospitalizationPatientTab {
        icf_id
      }
      hospitalizationBillingTab {
        inf_id
      }
      hospitalizationCidTab {
        iac_id
      }
      hospitalizationProceduresTab {
        iap_id
      }
    }
  }
`;

export default async ({ rootState, getters }) => {
  const main = prepareAihToSave(getters.GET_HOSPITALIZATION_AIH_INFO);
  const patient = preparePatientToSave(getters.GET_PATIENT_INFO_TAB);
  const hospitalization = prepareHospitalizationToSave(
    getters.GET_HOSPITALIZATION_BILLING_INFO_TAB,
  );
  const cid = prepareCidToSave(
    getters.GET_HOSPITALIZATION_BILLING_DIAGNOSTICS_INFO,
  );
  const procedure = prepareHospitalizationProcedureToSave(
    getters.GET_HOSPITALIZATION_AIH_PROCEDURES,
  );

  const variables = {
    main: main,
    patient,
    hospitalization,
    cid,
    procedure,
  };

  try {
    const { data } = await GraphQLClient.mutate({
      mutation,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.SaveHospitalizationBillingAihTabs;
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors);
  }
};
