import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query People($peopleName: String, $isSearchable: Boolean) {
    people(
      pes_nome: $peopleName
      isPatient: true
      isSearchable: $isSearchable
    ) {
      rows {
        pes_id
        pes_nome
        pes_mae
        pes_pai
        pes_nascimento
        gender {
          sex_id
          sex_sigla
        }
        patient {
          pac_id
          pac_prontuario_unico
          pac_ativo
        }
        cns {
          crs_numero
        }
      }
    }
  }
`;

export default async ({ variables }) => {
  const { data } = await GraphQLClient.query({
    query,
    variables,
    fetchPolicy: "no-cache",
  }).catch((err) => {
    console.error(err);
  });
  return data.people.rows;
};
