import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query Employee(
    $personName: String
    $motherName: String
    $birthDate: String
    $personNotId: Number
  ) {
    people(
      pes_nome: $personName
      pes_mae: $motherName
      pes_nascimento: $birthDate
      pes_id_n: $personNotId
    ) {
      rows {
        pes_id
        employee {
          fun_id
        }
      }
    }
  }
`;

export default async (
  { commit },
  {
    personName = null,
    motherName = null,
    birthDate = null,
    personNotId = null,
  },
) => {
  if (!personName || !motherName || !birthDate) return false;
  const variables = {
    personName,
    motherName,
    birthDate: convertDateBr(birthDate),
    personNotId,
  };

  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.people.rows;
  } catch (err) {
    return err.graphQLErrors;
  }
};

const convertDateBr = (pDateUS) => {
  const splittedDate = pDateUS.split("/");
  return splittedDate.reverse().join("-");
};
