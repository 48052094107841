import LocalStorage from "~common/local-storage/LocalStorage";

const ScheduleThermalTwoWayHTML = (pValue) => {
  const logo = LocalStorage.getObject("client-logo");
  const html = `
    <!DOCTYPE html>
    <html>

    <head>
        <meta charset="UTF-8">
        <style type="text/css">
            html,
            body,
            div,
            span,
            p {
                margin: 0;
                padding: 0;
                border: 0;
                font-size: 100%;
                font: inherit;
                vertical-align: baseline;
            }

            body {
                width: 7cm;
                margin: .1cm;
                font-size: 8pt;
                font-family: Verdana, sans-serif;
            }

            div,
            p {
                width: 6.5cm;
                margin: .2cm auto;
                word-wrap: break-word;
                line-height: 120%;
            }

            .logo {
                width: 60px;
            }

            .strong {
                font-weight: bold;
                text-transform: uppercase;
            }

            .label {
                width: 2.2cm;
                display: inline-block;
            }

            .fila {
                display: ${pValue.agendadoPelaFila ? "block" : "none"};
            }

            @media print {
                body {
                    width: 7cm;
                    margin: .1cm;
                    font-size: 8pt;
                    font-family: Verdana, sans-serif;
                }

                div,
                p {
                    width: 6.5cm;
                    margin: .2cm auto;
                    word-wrap: break-word;
                    line-height: 120%;
                }

                .logo {
                    width: 60px;
                }

                .strong {
                    font-weight: bold;
                    text-transform: uppercase;
                }

                .label {
                    width: 2.2cm;
                    display: inline-block;
                }

                @page {
                    margin: 0;
                    padding: 0;
                    border: 0;
                }

            }
        </style>
    </head>

    <body>
        <div>
            <img class="logo" src=${logo}>
            <span>${pValue.cli_nome}</span>
        </div>

        <p>${pValue.uns_nome}</p>

        <p>RUA ${pValue.end_logradouro}, ${pValue.end_numero} ${
    pValue.end_complemento
  } - ${pValue.bai_nome} ${pValue.mun_nome}</p>

        <p>Referência: ${pValue.end_referencia}</p>

        <p>Comparecer a consulta</p>

        <p>Data: <b>${pValue.hag_data_consulta}</b> as <b>${
    pValue.eap_hora
  }</b></p>

        <p><span class="label">Paciente:</span> ${pValue.pes_nome}</p>

        <p><span class="label">Nome Social:</span> ${pValue.pes_nome_social}</p>

        <p><span class="label">Prontuário:</span> ${pValue.ppr_numero}</p>

        <p><span class="label">Pront. Único:</span> ${
          pValue.pac_prontuario_unico
        }</p>

        <p><span class="label">Cartão Sus:</span> ${pValue.crs_numero}</p>

        <p><span class="label">Setor:</span> ${pValue.set_nome}</p>

        <p><span class="label">Local:</span> ${pValue.lca_nome}</p>

        <p><span class="label">Objetivo:</span> ${
          pValue.tcu_novo_tipo_consulta
        }</p>

        <p><span class="label">Profissional:</span> <span class="strong">${
          pValue.atendente
        }</span></p>

        <p>Ocupação: <span class="strong">${pValue.ocp_nome}</span< /p>

                <p>${pValue.observacoes}</p>

                <p>Consulta marcada no dia</p>

                <p>${pValue.data_marcacao} <b>as</b> ${pValue.hora_marcacao}</p>

                <p><b>por: </b>${pValue.usu_nome}</p>

                <div class="fila"><label style="width: 200px;">Paciente agendado pela fila</label></div>
                <div class="fila"><label>Profissional Regulador: </label><span>${
                  pValue.nomeRegulador
                }</span></div>

				<hr />
            <div>
                <img class="logo" src=${logo}>
                <span>${pValue.cli_nome}</span>
			</div>

			<p>${pValue.uns_nome}</p>

			<p>RUA ${pValue.end_logradouro}, ${pValue.end_numero} ${
    pValue.end_complemento
  } - ${pValue.bai_nome} ${pValue.mun_nome}</p>

			<p>Referência: ${pValue.end_referencia}</p>

			<p>Comparecer a consulta</p>

			<p>Data: <b>${pValue.hag_data_consulta}</b> as <b>${pValue.eap_hora}</b></p>

			<p><span class="label">Paciente:</span> ${pValue.pes_nome}</p>

			<p><span class="label">Nome Social:</span> ${pValue.pes_nome_social}</p>

			<p><span class="label">Prontuário:</span> ${pValue.ppr_numero}</p>

			<p><span class="label">Pront. Único:</span> ${pValue.pac_prontuario_unico}</p>

			<p><span class="label">Cartão Sus:</span> ${pValue.crs_numero}</p>

			<p><span class="label">Setor:</span> ${pValue.set_nome}</p>

			<p><span class="label">Local:</span> ${pValue.lca_nome}</p>

			<p><span class="label">Objetivo:</span> ${pValue.tcu_novo_tipo_consulta}</p>

			<p><span class="label">Profissional:</span> <span class="strong">${
        pValue.atendente
      }</span></p>

			<p>Ocupação: <span class="strong">${pValue.ocp_nome}</span< /p>

            <p>${pValue.observacoes}</p>

            <p>Consulta marcada no dia</p>

            <p>${pValue.data_marcacao} <b>as</b> ${pValue.hora_marcacao}</p>

            <p><b>por: </b>${pValue.usu_nome}</p>

            <div class="fila"><label style="width: 200px;">Paciente agendado pela fila</label></div>
            <div class="fila"><label>Profissional Regulador: </label><span>${
              pValue.nomeRegulador
            }</span></div>

            <hr />

    </body>

    </html>
		`;
  return html;
};

export { ScheduleThermalTwoWayHTML };
export default ScheduleThermalTwoWayHTML;
