import MenuMap from "./MenuMap.json";
import isActive from "./isActive";
import { PermissionHasByGlobal } from "$access-control/model/PermissionManager";

export const GetSubModulesFromJson = (pModule) => {
  const menuMapSubModules = MenuMap[pModule].subModule.filter((submodule) => {
    return submodule.active !== false;
  });
  const subModules = [];

  for (const subModule of menuMapSubModules) {
    const active = isActive(subModule);
    const showSubModule = active && PermissionHasByGlobal(subModule.permission);
    if (showSubModule) {
      subModules.push({
        name: subModule.name,
        iconName: subModule.iconName,
        permission: subModule.permission,
        active: active,
        className: subModule.className,
        module: MenuMap[pModule].name,
        link: active === false ? null : subModule.link,
      });
    }
  }

  return subModules;
};
