<template lang="html">
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 290 290"
    style="enable-background: new 0 0 290 290"
    xml:space="preserve"
  >
    <g>
      <rect y="220" width="70" height="70" />
      <rect y="110" width="70" height="70" />
      <rect width="70" height="70" />
      <rect x="110" y="220" width="70" height="70" />
      <rect x="110" y="110" width="70" height="70" />
      <rect x="110" width="70" height="70" />
      <rect x="220" y="220" width="70" height="70" />
      <rect x="220" y="110" width="70" height="70" />
      <rect x="220" width="70" height="70" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "MenuSquares",
};
</script>
