<template lang="html">
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 186.076 186.076"
    style="enable-background: new 0 0 186.076 186.076"
    xml:space="preserve"
  >
    <g>
      <path
        d="M93.038,0C41.737,0,0,41.737,0,93.038s41.737,93.038,93.038,93.038s93.038-41.737,93.038-93.038S144.339,0,93.038,0z
      M93.038,176.076C47.25,176.076,10,138.826,10,93.038S47.25,10,93.038,10s83.038,37.25,83.038,83.038
      S138.826,176.076,93.038,176.076z"
      />
      <path
        d="M93.038,19.341c-40.637,0-73.697,33.061-73.697,73.697s33.061,73.697,73.697,73.697s73.697-33.061,73.697-73.697
      S133.675,19.341,93.038,19.341z M93.038,160.735c-37.329,0-67.697-30.369-67.697-67.697s30.369-67.697,67.697-67.697
      s67.697,30.369,67.697,67.697S130.367,160.735,93.038,160.735z"
      />
      <path
        d="M115.656,53.862L99.938,81.088c4.126,2.392,6.915,6.847,6.915,11.95h31.427C138.28,76.294,129.177,61.686,115.656,53.862z"
      />
      <path
        d="M93.038,106.853c-2.514,0-4.866-0.686-6.899-1.865L70.42,132.214c6.656,3.851,14.376,6.066,22.618,6.066
      s15.963-2.215,22.618-6.066l-15.719-27.226C97.904,106.167,95.552,106.853,93.038,106.853z"
      />
      <path
        d="M86.139,81.088L70.42,53.862c-13.521,7.823-22.624,22.432-22.624,39.176h31.427C79.224,87.935,82.012,83.48,86.139,81.088z
      "
      />
      <path
        d="M93.038,101.853c4.86,0,8.814-3.954,8.814-8.814s-3.954-8.814-8.814-8.814s-8.814,3.954-8.814,8.814
      S88.178,101.853,93.038,101.853z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: "Xray",
};
</script>
