<template>
  <section class="recent-operation-container">
    <h3 class="title">Últimas Operações Realizadas</h3>

    <div ref="modulesArea" class="recent-list">
      <div
        v-for="(item, idx) in getRecentOperations"
        v-show="getRecentOperations.length > 0"
        :key="idx"
        class="list"
      >
        <p class="from module unselect">
          {{ item.module }}
        </p>

        <button class="button-list unselect" @click.stop.prevent="go(item)">
          <component :is="item.iconName" class="icon" />
          <p class="text">
            {{ item.name }}
          </p>
        </button>
      </div>
      <div v-show="getRecentOperations.length <= 0" class="empty">
        <IconEmpty class="svg" />

        Não foram encontradas operações recentes
      </div>
    </div>
  </section>
</template>

<script>
import * as Icons from "~tokio/primitive/icon/modules";
import { IconEmpty } from "~tokio/primitive/icon/symbols";

export default {
  name: "RecentOperationContainer",
  components: { ...Icons, IconEmpty },
  computed: {
    getRecentOperations() {
      return this.$store.getters["Home/GET_RECENT_OPERATION"];
    },

    calcItemPerPage() {
      const size_item =
        window.outerHeight >= 800 ? this.SIZE_ITEM_BIG : this.SIZE_ITEM_DEFAULT;
      return Math.ceil(this.$refs.modulesArea.offsetWidth / size_item);
    },
  },

  created() {
    this.SIZE_ITEM = 80;
    this.SIZE_ITEM_BIG = 100;
  },

  mounted() {
    this.$store.commit("Home/SET_ITEM_PER_PAGE", this.calcItemPerPage);
  },

  methods: {
    go(pData) {
      this.$store.commit("Home/PUSH_RECENT_OPERATION", pData);
      this.$router.push(pData.link);
    },
  },
};
</script>
