import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query FamilyMembers($ref_documento: String) {
    SearchPatientBasicAttention(pab_documento: $ref_documento) {
      rows {
        family {
          pab_id
          pab_documento
          pab_nome
          pab_nascimento
          pab_id_grau_parentesco
          pab_documento_responsavel
        }
      }
    }
    SearchFamilyResponsable(ref_documento: $ref_documento) {
      rows {
        ref_id
        ref_id_domicilio
        ref_documento
        members(includeResponsable: 1) {
          pab_id
          pab_documento
          pab_nome
          pab_nascimento
          pab_id_grau_parentesco
          pab_documento_responsavel

          degreeKinship {
            gpa_nome
          }
        }
      }
    }
  }
`;

export default async (pContext, pFilter) => {
  try {
    const variables = { ...pFilter };
    const { data } = await GraphQLClient.query({
      query,
      fetchPolicy: "no-cache",
      variables,
    });
    if (
      data.SearchFamilyResponsable &&
      data.SearchFamilyResponsable.rows.length > 0
    ) {
      return data.SearchFamilyResponsable.rows[0].members;
    } else if (
      data.SearchPatientBasicAttention &&
      data.SearchPatientBasicAttention.rows.length > 0
    ) {
      return data.SearchPatientBasicAttention.rows[0].family;
    }
    return [];
  } catch (err) {
    console.log(err);
    return err.graphQLErrors;
  }
};
