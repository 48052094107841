<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
export default {
  name: "RgComboboxMonth",
  mixins: [RgComboboxMixin],
  data: () => {
    return {
      mData: [
        { value: "01", text: "JANEIRO" },
        { value: "02", text: "FEVEREIRO" },
        { value: "03", text: "MARÇO" },
        { value: "04", text: "ABRIL" },
        { value: "05", text: "MAIO" },
        { value: "06", text: "JUNHO" },
        { value: "07", text: "JULHO" },
        { value: "08", text: "AGOSTO" },
        { value: "09", text: "SETEMBRO" },
        { value: "10", text: "OUTUBRO" },
        { value: "11", text: "NOVEMBRO" },
        { value: "12", text: "DEZEMBRO" },
      ],
    };
  },
};
</script>
