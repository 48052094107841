<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchScale from "./action/SearchScale";
export default {
  name: "RgComboboxScale",
  mixins: [RgComboboxMixin],
  props: {
    hoursSelected: Array,
    hours: {
      type: Object,
    },
    interval: {
      type: Number,
    },
    dateExam: {
      type: String,
    },
    sectorId: {
      type: Number,
    },
    attendance: {
      type: Boolean,
    },
    attendanceScheduledExams: {
      type: Boolean,
    },
    sectorLink: {
      type: Number,
    },
  },
  data: () => {
    return {};
  },
  watch: {},
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      const data = await SearchScale({
        strData: this.dateExam,
        intIdSetor: this.sectorId,
        blnEhaAtendimento: this.attendance,
        blnAtendComExamesMarcados: this.attendanceScheduledExams,
        intIdVinculoSetor: this.sectorLink,
      });
      data.forEach((item) => {
        this.mData.push({
          value: item.data,
          text: item.label,
          eea_inicio_atendimentos: item.eea_inicio_atendimentos,
          item: item,
        });
      });

      if (data.length === 1) {
        this.$emit("selectOnlyItem", this.mData[0].value);
      }
    },
  },
};
</script>
