<template lang="html">
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.20011 11.3333H18.8001C19.4627 11.3333 20 10.7962 20 10.1332C20 9.47036 19.4629 8.93311 18.8001 8.93311H1.20011C0.537252 8.93327 0 9.47052 0 10.1332C0 10.7961 0.537252 11.3333 1.20011 11.3333Z"
      fill="white"
    />
    <path
      d="M9.96687 5.93342C11.6028 5.93342 12.9334 4.60277 12.9334 2.96671C12.9334 1.33082 11.6028 0 9.96687 0C8.33114 0 7 1.33082 7 2.96671C6.99984 4.6026 8.33082 5.93342 9.96687 5.93342Z"
      fill="white"
    />
    <path
      d="M12.9334 16.1663C12.9334 14.5304 11.6028 13.1997 9.96671 13.1997C8.33114 13.1997 7 14.5304 7 16.1663C7 17.8018 8.33065 19.1328 9.96671 19.1328C11.6026 19.1328 12.9334 17.8025 12.9334 16.1663Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "IconSplit",
};
</script>

<style type="text/css"></style>
