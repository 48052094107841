var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"hospitalization-billing"},[_c('section',{staticClass:"area-tabs"},[_c('RgConfirmModal',{staticStyle:{"background-color":"red"},attrs:{"show":_vm.confirmNew,"message":_vm.newMsg,"title":"Confirmação"},on:{"close":_vm.handleNew,"confirmed":_vm.newBilling,"denied":_vm.handleNew}}),_c('ModuleBox',{staticClass:"aih-header",attrs:{"granted":_vm.hasPermission,"title":"Faturamento AIH"}},[_c('div',{attrs:{"slot":"info"},slot:"info"},[_c('div',{class:("status status-" + _vm.status)},[_vm._v(" "+_vm._s(_vm.statusTitle)+" ")])]),_c('div',{attrs:{"slot":"title"},slot:"title"},[_c('RgSubmit',{ref:"btnNew",attrs:{"label":" Novo +","wait-label":"Novo"},on:{"submit":_vm.handleNew}}),_c('RgSubmit',{directives:[{name:"shortkey",rawName:"v-shortkey",value:(['ctrl', 'f']),expression:"['ctrl', 'f']"}],ref:"btnBill",attrs:{"disabled":!_vm.canBill,"label":"Faturar","wait-label":"Faturando..."},on:{"submit":_vm.billAih},nativeOn:{"shortkey":function($event){return _vm.billAihForShortkey($event)}}})],1),_c('HospitalizationBillingAihInfo',{ref:"aihHeader",attrs:{"validation-tabs":_vm.validationTabs},on:{"patientUnitRecordNumberSelected":_vm.setPersonId}}),_c('RgFormPanel',[_c('RgFormPanelItem',{directives:[{name:"shortkey",rawName:"v-shortkey",value:(['ctrl', '1']),expression:"['ctrl', '1']"}],class:{
            active: _vm.stepProgress === 1 && _vm.errors.patient,
            validated: !_vm.errors.patient,
            'active-with-error': _vm.stepProgress === 1 && !_vm.errors.patient,
          },on:{"change-step":function($event){return _vm.changeStep(1)}},nativeOn:{"shortkey":function($event){return _vm.changeStep(1)}}},[_c('IconPersonModule',{attrs:{"slot":"icon"},slot:"icon"}),_vm._v(" Paciente ")],1),_c('RgFormPanelItem',{directives:[{name:"shortkey",rawName:"v-shortkey",value:(['ctrl', '2']),expression:"['ctrl', '2']"}],class:{
            active: _vm.stepProgress === 2 && _vm.errors.hospitalization,
            validated: !_vm.errors.hospitalization,
            'active-with-error':
              _vm.stepProgress === 2 && !_vm.errors.hospitalization,
          },on:{"change-step":function($event){return _vm.changeStep(2)}},nativeOn:{"shortkey":function($event){return _vm.changeStep(2)}}},[_c('IconHospitalInternment',{attrs:{"slot":"icon"},slot:"icon"}),_vm._v(" Internação ")],1),_c('RgFormPanelItem',{directives:[{name:"shortkey",rawName:"v-shortkey",value:(['ctrl', '3']),expression:"['ctrl', '3']"}],class:{
            active: _vm.stepProgress === 3 && _vm.errors.diagnosticTab,
            validated: !_vm.errors.diagnosticTab,
            'active-with-error': _vm.stepProgress === 3 && !_vm.errors.diagnosticTab,
          },on:{"change-step":function($event){return _vm.changeStep(3)}},nativeOn:{"shortkey":function($event){return _vm.changeStep(3)}}},[_c('IconClipboard',{attrs:{"slot":"icon"},slot:"icon"}),_vm._v(" Diagnóstico ")],1),_c('RgFormPanelItem',{directives:[{name:"shortkey",rawName:"v-shortkey",value:(['ctrl', '4']),expression:"['ctrl', '4']"}],class:{
            active: _vm.stepProgress === 4 && _vm.errors.procedureTab,
            validated: !_vm.errors.procedureTab,
            'active-with-error': _vm.stepProgress === 4 && !_vm.errors.procedureTab,
          },on:{"change-step":function($event){return _vm.changeStep(4)}},nativeOn:{"shortkey":function($event){return _vm.changeStep(4)}}},[_c('IconSyringe',{attrs:{"slot":"icon"},slot:"icon"}),_vm._v(" Procedimento ")],1)],1),_c('div',{staticClass:"tabs"},[_c('HospitalizationBillingPatientInfo',{directives:[{name:"show",rawName:"v-show",value:(_vm.stepProgress === 1),expression:"stepProgress === 1"}],ref:"patientTab",attrs:{"validation-tabs":_vm.validationTabs,"saving-father":_vm.saving},on:{"saveInfoTabs":_vm.saveInfoTabs,"increaseStepProgress":function($event){_vm.stepProgress++}}}),_c('HospitalizationBillingHospitalizationInfo',{directives:[{name:"show",rawName:"v-show",value:(_vm.stepProgress === 2),expression:"stepProgress === 2"}],ref:"hospitalizationTab",attrs:{"validation-tabs":_vm.validationTabs,"saving-father":_vm.saving},on:{"saveInfoTabs":_vm.saveInfoTabs,"increaseStepProgress":function($event){_vm.stepProgress++}}}),_c('HospitalizationBillingDiagnosticInfo',{directives:[{name:"show",rawName:"v-show",value:(_vm.stepProgress === 3),expression:"stepProgress === 3"}],ref:"diagnosticTab",attrs:{"validation-tabs":_vm.validationTabs,"saving-father":_vm.saving},on:{"saveInfoTabs":_vm.saveInfoTabs,"increaseStepProgress":function($event){_vm.stepProgress++}}}),_c('HospitalizationBillingProcedureInfo',{directives:[{name:"show",rawName:"v-show",value:(_vm.stepProgress === 4),expression:"stepProgress === 4"}],ref:"procedureTab",attrs:{"step-progress":_vm.stepProgress,"validation-tabs":_vm.validationTabs,"saving-father":_vm.saving},on:{"saveInfoTabs":_vm.saveInfoTabs}})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }