<style src="./BillingForm.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="billing-form">
    <form class="form">
      <RgValidatorForm ref="validator">
        <ModuleBox
          :granted="hasPermission"
          :disabled="!hasBofId"
          title="Informações do profissional"
          class="module-box"
        >
          <div class="row">
            <RgSuggestEmployee
              v-model="employee.pdf_nome_profissional"
              :rules="{ required: true }"
              class="col-10"
              label="Profissional"
              placeholder="Nome do Profissional | Código CBO | Nº CNS"
              @selected="employeeSelected"
            />
            <RgInputSuscard
              v-model="employee.pdf_cns"
              :rules="{ required: true }"
              class="col-2"
              label="CNS"
              placeholder="Código do CNS do profissional"
            />
            <RgTypeaheadCbo
              v-model="employee.pdf_cod_cbo"
              :rules="{ required: true }"
              class="col-10"
              label="CBO"
              placeholder="Código do CBO"
            />
            <RgInputCodeIne
              v-model="employee.pdf_cod_ine"
              class="col-2"
              label="Código INE"
            />
          </div>

          <div class="row btn-wrapper">
            <button
              v-if="!editingProcedure"
              v-shortkey="['alt', 'd']"
              type="button"
              class="btn btn-primary"
              @click="showDefaultProcedureModel"
              @shortkey="showDefaultProcedureModel"
            >
              Adicionar Procedimentos Padrão
            </button>

            <button
              v-if="!editingProcedure"
              type="button"
              class="btn btn-clean"
              @click="cleanEmployeeForm"
            >
              Limpar formulário
            </button>
          </div>
        </ModuleBox>

        <ModuleBox
          :disabled="!hasBofId"
          title="Procedimentos"
          class="module-box"
        >
          <div class="row">
            <RgInputDateDay
              v-model="procedure.pdf_data_atendimento"
              :rules="{ required: true }"
              class="col-2"
              label="Data do atendimento"
            />
            <RgTypeaheadProcedure
              v-model="procedure.pdf_cod_procedimento"
              :rules="{ required: true }"
              class="col-8"
              label="Procedimento"
              placeholder="Informe o nome ou código do procedimento"
              type="BPA"
            />
            <RgInput
              v-model="procedure.pdf_quantidade"
              :rules="{ required: true }"
              class="col-2"
              label="Quantidade"
            />
          </div>

          <div class="row">
            <RgComboboxServiceCharacter
              v-model="procedure.pdf_id_internacoes_caracteres"
              class="col-5"
              label="Caráter de atendimento"
            />
            <RgComboboxCid
              v-model="procedure.pdf_cid"
              :procedure="procedure.pdf_cod_procedimento"
              class="col-5"
              label="CID"
              placeholder="CID"
            />
            <RgInputCnpj
              v-model="procedure.pdf_cnpj"
              class="col-2"
              label="CNPJ"
            />
          </div>

          <div class="row">
            <RgComboboxService
              v-model="procedure.pdf_servico"
              :procedure="procedure.pdf_cod_procedimento"
              class="col-5"
              label="Serviço"
              placeholder="Código do serviço"
            />
            <RgComboboxClassification
              v-model="procedure.pdf_classificacao"
              :procedure="procedure.pdf_cod_procedimento"
              :service="procedure.pdf_servico"
              class="col-5"
              label="Classificação"
              placeholder="Código da classificação"
            />
            <RgInput
              v-model="procedure.pdf_num_autorizacao"
              class="col-2"
              label="Nº da autorização"
              placeholder="Informe o nº da autorização"
            />
          </div>

          <div class="row btn-wrapper">
            <button
              v-if="!editingProcedure"
              type="button"
              class="btn btn-success"
              @click="listInsert"
            >
              Adicionar Procedimento
            </button>
            <button
              v-if="editingProcedure"
              type="button"
              class="btn btn-success"
              @click="editProcedure"
            >
              Alterar Procedimento
            </button>

            <button
              v-if="editingProcedure"
              type="button"
              class="btn btn-danger"
              @click="cleanEditForm"
            >
              Cancelar edição
            </button>

            <button
              v-if="!editingProcedure"
              type="button"
              class="btn btn-clean"
              @click="cleanProcedureForm"
            >
              Limpar formulário
            </button>
          </div>
        </ModuleBox>

        <BillingList @edit-procedure-item="fillProdedureToEdit" />
      </RgValidatorForm>
    </form>
    <RgConfirmModal
      :show="showDuplicatedProcedureModal"
      :message="duplicatedModalIncrease"
      yes-label="Sim, aumentar Quantidade"
      title="Procedimento Duplicado"
      @confirmed="saveDuplicatedWithIncrement"
      @denied="showDuplicatedProcedureModal = false"
    />
  </section>
</template>

<script>
import ModuleBox from "~tokio/foundation/modulebox/Modulebox";
import {
  RgInput,
  RgInputCnpj,
  RgInputSuscard,
  RgValidatorForm,
  RgInputCodeIne,
} from "~tokio/primitive";
import RgTypeaheadCbo from "$billing/common/component/rg-typeahead-cbo/RgTypeaheadCbo";
import RgTypeaheadProcedure from "$billing/common/component/rg-typeahead-procedure/RgTypeaheadProcedure";
import RgComboboxServiceCharacter from "$billing/common/component/rg-combobox-service-character/RgComboboxServiceCharacter";
import RgComboboxService from "$billing/common/component/rg-combobox-service/RgComboboxService";
import RgComboboxClassification from "$billing/common/component/rg-combobox-classification/RgComboboxClassification";
import RgComboboxCid from "$billing/common/component/rg-combobox-cid/RgComboboxCid";
import RgInputDateDay from "$billing/common/component/rg-input-date-day/RgInputDateDay";
import RgSuggestEmployee from "$billing/common/component/rg-suggest-employee/RgSuggestEmployee";
import BillingList from "../billing-list/BillingList";
import SaveProcedureIntoDatabase from "../../action/SaveProcedureIntoDatabase";
import UpdateProcedure from "../../action/UpdateProcedure";
import GetBulletinInfo from "../../action/getBulletinInfo";
import { toast, RgConfirmModal } from "~tokio/primitive/notification";
import moment from "moment";

export default {
  name: "BillingForm",
  components: {
    ModuleBox,
    RgInput,
    RgInputSuscard,
    RgTypeaheadCbo,
    RgTypeaheadProcedure,
    RgComboboxServiceCharacter,
    RgComboboxService,
    RgComboboxClassification,
    RgComboboxCid,
    RgInputDateDay,
    RgInputCodeIne,
    RgInputCnpj,
    BillingList,
    RgSuggestEmployee,
    RgValidatorForm,
    RgConfirmModal,
  },
  data() {
    return {
      patientPanelExpanded: false,
      editingProcedure: false,
      employee: {
        pdf_nome_profissional: "",
        pdf_cod_cbo: "",
        pdf_cns: "",
        pdf_cod_ine: null,
      },
      procedure: {
        pdf_data_atendimento: "",
        pdf_cod_procedimento: "",
        pdf_quantidade: "1",
        pdf_cid: null,
        pdf_cnpj: null,
        pdf_servico: null,
        pdf_classificacao: null,
        pdf_id_internacoes_caracteres: 2,
        pdf_num_autorizacao: null,
      },
      procedureList: [],
      showDuplicatedProcedureModal: false,
      duplicatedModalIncrease: "",
      totalQuantity: 0,
    };
  },
  computed: {
    bulletinId() {
      return this.$store.getters["Billing/Bulletin/GET_SELECTED_BULLETIN"];
    },
    hasBofId() {
      return this.$store.getters["Billing/Billing/HAS_BOF_ID"];
    },
    hasPermission() {
      return this.$Permissions.global.has(
        "faturamento.atendimentosEmergencia",
        this.$store.getters["Login/GET_UNI_HEALTH_ID"],
      );
    },
  },
  watch: {
    bulletinId() {
      this.refreshBillingForm();
    },
  },
  mounted() {
    this.refreshBillingForm();
  },
  methods: {
    expandContractPatientPanel() {
      this.patientPanelExpanded = !this.patientPanelExpanded;
    },
    selectProcedure() {},
    selectEmployee(pVal) {
      if (pVal && pVal.details) {
        this.employee.pdf_cod_cbo = pVal.details.cbo;
        this.employee.pdf_cns = pVal.details.cns;
        this.employee.pdf_cod_ine = pVal.details.equ_codigo_ine;
      }
    },
    async listInsert() {
      if (await this.validateForm()) {
        const procedures = this.procedure;
        this.SaveProcedure(procedures)
          .then(() => {
            this.cleanProcedureForm();
          })
          .catch((pErr) => {});
      }
    },
    checkDuplicateProcedure(pProcedure) {
      const procedureList = this.$store.getters[
        "Billing/Billing/GET_PROCEDURES_LIST"
      ];
      for (const procedureItem in procedureList) {
        const equalProcedure =
          pProcedure.pdf_cod_procedimento ===
          procedureList[procedureItem].pdf_cod_procedimento;
        const equalEmployeeName =
          this.employee.pdf_nome_profissional ===
          procedureList[procedureItem].pdf_nome_profissional;
        const equalCboCode =
          this.employee.pdf_cod_cbo ===
          procedureList[procedureItem].pdf_cod_cbo;
        const equalDate =
          this.$utils.date.BrazilianDateToDatabase(
            this.procedure.pdf_data_atendimento,
          ) === procedureList[procedureItem].pdf_data_atendimento;
        if (equalDate && equalProcedure && equalEmployeeName && equalCboCode) {
          return procedureList[procedureItem];
        }
      }
      return true;
    },
    saveDuplicatedWithIncrement() {
      this.showDuplicatedProcedureModal = false;
      const pProcedure = this.checkDuplicateProcedure(this.procedure);
      pProcedure.pdf_quantidade = this.totalQuantity;
      this.updateProcedure(pProcedure);
    },
    updateProcedure(pProcedure) {
      // eslint-disable-next-line eqeqeq
      pProcedure.pdf_cid =
        !pProcedure.pdf_cid || pProcedure.pdf_cid === "0"
          ? null
          : pProcedure.pdf_cid;
      // eslint-disable-next-line eqeqeq
      pProcedure.pdf_servico =
        !pProcedure.pdf_servico || pProcedure.pdf_servico === "0"
          ? null
          : pProcedure.pdf_servico;
      // eslint-disable-next-line eqeqeq
      pProcedure.pdf_classificacao =
        !pProcedure.pdf_classificacao || pProcedure.pdf_classificacao === "0"
          ? null
          : pProcedure.pdf_classificacao;
      return new Promise((resolve, reject) => {
        UpdateProcedure(pProcedure)
          .then(() => {
            toast.success("Procedimento alterado com sucesso");
            this.$store.dispatch("Billing/Billing/LOAD_PROCEDURE_LIST");
            this.cleanEditForm();
            resolve();
          })
          .catch((pError) => {
            toast.error("Erro ao realizar atualização");
            resolve();
          });
      });
    },
    SaveProcedure(pProcedures, pIsDefaultProcedure = false) {
      const duplicatedProcedure = this.checkDuplicateProcedure(pProcedures);
      if (duplicatedProcedure !== true) {
        if (!pIsDefaultProcedure) {
          const sumQuantity =
            parseInt(duplicatedProcedure.pdf_quantidade) +
            parseInt(this.procedure.pdf_quantidade);
          this.duplicatedModalIncrease = `Há uma entrada desse procedimento para a mesma data onde a quantidade atual é <strong>${duplicatedProcedure.pdf_quantidade}</strong>.<br />Você deseja somar <strong>${this.procedure.pdf_quantidade}</strong> a essa quantidade resultando o total em <strong>${sumQuantity}</strong>?`;
          this.showDuplicatedProcedureModal = true;
          this.totalQuantity = sumQuantity;
        }
        return Promise.reject(new Error("Procedimento Duplicado"));
      }
      const bofId = {
        pdf_id_boletins_faturamento: this.$store.getters[
          "Billing/Billing/HAS_BOF_ID"
        ],
      };
      const attendanceDate = {
        pdf_data_atendimento: this.$utils.date.BrazilianDateToDatabase(
          pProcedures.pdf_data_atendimento,
        ),
      };
      // eslint-disable-next-line eqeqeq
      pProcedures.pdf_cid =
        !pProcedures.pdf_cid || pProcedures.pdf_cid === "0"
          ? null
          : pProcedures.pdf_cid;
      // eslint-disable-next-line eqeqeq
      pProcedures.pdf_servico =
        !pProcedures.pdf_servico || pProcedures.pdf_servico === "0"
          ? null
          : pProcedures.pdf_servico;
      // eslint-disable-next-line eqeqeq
      pProcedures.pdf_classificacao =
        !pProcedures.pdf_classificacao || pProcedures.pdf_classificacao === "0"
          ? null
          : pProcedures.pdf_classificacao;
      const prepareToSave = Object.assign(
        {},
        bofId,
        this.employee,
        pProcedures,
        attendanceDate,
      );

      return new Promise((resolve, reject) => {
        SaveProcedureIntoDatabase(prepareToSave)
          .then(() => {
            toast.success("Cadastro realizado com sucesso");
            this.$store.dispatch("Billing/Billing/LOAD_PROCEDURE_LIST");
            resolve();
          })
          .catch((pError) => {
            const errors =
              pError && pError.response && pError.response.errors
                ? pError.response.errors
                : [];
            toast.error(errors.join("<br><br>"), "Erro ao realizar cadastro", {
              timeOut: 0,
            });
            resolve();
          });
      });
    },
    async validateForm() {
      const valid = await this.$refs.validator.validate();
      if (valid) {
        return this.validateAttendanceDate();
      }
    },
    validateAttendanceDate() {
      const DateConverted = moment(
        this.procedure.pdf_data_atendimento,
        "DD/MM/YYYY",
      );
      if (!DateConverted.isValid()) {
        toast.error("Data de atendimento inválida");
        return false;
      }
      const DateFormat = DateConverted.format("YYYY-MM-DD");
      const Competence = this.$store.getters[
        "Billing/BDSia/GET_PERIOD_DATE"
      ].replace(/(\d{4})(\d{2})/gi, "$1-$2");
      const AttendanceDate = moment(
        this.procedure.pdf_data_atendimento,
        "DD/MM/YYYY",
      ).format("YYYY-MM");
      if (
        moment(Competence).diff(AttendanceDate, "months") < 0 ||
        moment(Competence).diff(AttendanceDate, "months") > 3
      ) {
        toast.error(
          "Competência atual não abrange a data de atendimento informada",
        );
        return false;
      }
      if (DateFormat > moment().format("YYYY-MM-DD")) {
        toast.error("Data de atendimento não pode ser maior que a data atual");
        return false;
      }
      return true;
    },
    cleanProcedureForm() {
      this.editingProcedure = false;
      this.employee.pdf_id = null;
      this.procedure = {
        pdf_data_atendimento: this.procedure.pdf_data_atendimento,
        pdf_cod_procedimento: "",
        pdf_quantidade: "1",
        pdf_cid: null,
        pdf_cnpj: null,
        pdf_servico: null,
        pdf_classificacao: null,
        pdf_id_internacoes_caracteres: 2,
        pdf_num_autorizacao: null,
      };
    },
    cleanEmployeeForm() {
      this.editingProcedure = false;
      this.employee = {
        pdf_nome_profissional: "",
        pdf_cod_cbo: "",
        pdf_cns: "",
        pdf_cod_ine: null,
      };
    },
    employeeSelected(pEmployeeSelected) {
      if (pEmployeeSelected && pEmployeeSelected.source) {
        this.employee.pdf_cod_cbo = pEmployeeSelected.source.ocp_codigo;
        this.employee.pdf_cns = pEmployeeSelected.source.crs_numero;
        this.employee.pdf_cod_ine = pEmployeeSelected.source.equ_codigo_ine;
      }
    },
    refreshBillingForm() {
      const pBulletinId = this.$store.getters[
        "Billing/Bulletin/GET_SELECTED_BULLETIN"
      ];
      if (!pBulletinId) return false;
      GetBulletinInfo(pBulletinId).then((pResult) => {
        const bolDate = moment(pResult.bol_data).format("DD/MM/YYYY");
        this.procedure.pdf_data_atendimento = bolDate;
        this.$emit("attendance-date", bolDate);
      });
    },
    saveDefaultProcedures({ procedures, attendanceDate }) {
      return new Promise((resolve, reject) => {
        for (const procedure in procedures) {
          const computedProcedure = {
            pdf_data_atendimento: attendanceDate,
            pdf_cod_procedimento: procedures[procedure],
            pdf_quantidade: "1",
            pdf_cid: null,
            pdf_cnpj: null,
            pdf_servico: null,
            pdf_classificacao: null,
            pdf_id_internacoes_caracteres: 2,
            pdf_num_autorizacao: null,
          };
          this.SaveProcedure(computedProcedure, true).catch((pErr) => {
            toast.error(pErr.message, "Erro ao inserir o procedimento");
          });
          resolve();
        }
      });
    },
    showDefaultProcedureModel() {
      if (
        this.employee.pdf_nome_profissional &&
        this.employee.pdf_nome_profissional !== ""
      ) {
        this.$emit("show-modal-default-procedure");
      } else {
        toast.warning(
          "É necessário ter um profissional para adicionar procedimentos",
          "Procedimento padrão",
        );
      }
    },
    fillProdedureToEdit(pProcedureItem) {
      this.editingProcedure = true;
      this.procedure = pProcedureItem;
      this.procedure.pdf_data_atendimento = moment(
        pProcedureItem.pdf_data_atendimento,
        "YYYY-MM-DD",
      ).format("DD/MM/YYYY");
      this.employee = pProcedureItem;
    },
    cleanEditForm() {
      this.cleanEmployeeForm();
      this.cleanProcedureForm();
    },
    async editProcedure() {
      if (await this.validateForm()) {
        let { bof_sexo, bof_data_nascimento } = this.$store.getters[
          "Billing/Billing/GET_ALL_INFO"
        ];
        bof_data_nascimento = moment().diff(
          this.$utils.date.BrazilianDateToDatabase(bof_data_nascimento),
          "years",
          false,
        );
        const procedure = Object.assign({}, this.procedure, {
          bof_sexo,
          fco_idade: bof_data_nascimento,
        });
        procedure.pdf_data_atendimento = this.$utils.date.BrazilianDateToDatabase(
          procedure.pdf_data_atendimento,
        );
        // eslint-disable-next-line eqeqeq
        procedure.pdf_cid =
          !procedure.pdf_cid || procedure.pdf_cid === "0"
            ? null
            : procedure.pdf_cid;
        // eslint-disable-next-line eqeqeq
        procedure.pdf_servico =
          procedure.pdf_servico || procedure.pdf_servico === "0"
            ? null
            : procedure.pdf_servico;
        // eslint-disable-next-line eqeqeq
        procedure.pdf_classificacao =
          procedure.pdf_classificacao || procedure.pdf_classificacao === "0"
            ? null
            : procedure.pdf_classificacao;
        try {
          await UpdateProcedure(procedure);
          toast.success("Procedimento alterado com sucesso");
          this.$store.dispatch("Billing/Billing/LOAD_PROCEDURE_LIST");
          this.cleanProcedureForm();
          return true;
        } catch (pError) {
          const errors = pError && pError.errors ? pError.errors : [];
          toast.error(errors.join("<br><br>"), "Erro ao realizar atualização");
          return true;
        }
      }
    },
  },
};
</script>
