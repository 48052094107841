import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchOccupationReport(
    $uns_id: Int
    $set_id: Int
    $set_nome: String
    $ocp_id: Int
    $ocp_nome: String
    $initialDate: String
    $finalDate: String
    $toPrint: Boolean
    $toCsv: Boolean
    $toExcel: Boolean
    $limit: Int
    $offset: Int
  ) {
    SearchOccupationReport(
      uns_id: $uns_id
      set_id: $set_id
      set_nome: $set_nome
      ocp_id: $ocp_id
      ocp_nome: $ocp_nome
      initialDate: $initialDate
      finalDate: $finalDate
      toPrint: $toPrint
      toCsv: $toCsv
      toExcel: $toExcel
      limit: $limit
      offset: $offset
    ) {
      html
      count
      csv
      excel
      fileName
      rows {
        OCUPACAO
        OBJETIVO_CONSULTA
        DISPONIBILIZADAS
        AGENDADAS
        EFETIVADAS
        NAO_EFETIVADAS
        AGUARDANDO
        VAGAS_RESTANTES
        PRODUTIVIDADE
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.SearchOccupationReport;
  } catch (err) {
    return err.graphQLErrors;
  }
};
