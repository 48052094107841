<style src="./PrintMap.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="surgery-map">
    <div class="print-head">
      <SurgeryMapHeader :header-info="headerInfo" />

      <section class="surgery-map-info">
        <span class="date">{{ dateStartEnd }} --- </span>
        <span class="date-time">{{ timeStartEnd }}</span>
        <div class="space-between" />
        <span class="username">Usuário: {{ username }}</span>
        <span class="print-date">{{ printDate }}</span>
      </section>
    </div>

    <section class="surgery-map-list-container">
      <ul>
        <li
          v-for="(item, idx) in fillData"
          :key="idx"
          class="surgery-map--item"
        >
          <div class="column col-time">
            <span class="hora" title="Hora do início">{{
              item.cci_hora_inicio | filterShortTime
            }}</span>
            <span class="tipo" title="Tipo de cirurgia">{{
              item.cci_id_tipos_cirurgias === 1 ? "ROTINA" : "CONDICIONADA"
            }}</span>
          </div>

          <div class="column col-person-place">
            <div class="row-person">
              <span class="name" title="Nome do paciente">{{
                item.pes_nome
              }}</span>
              <div class="person-detail">
                <span class="age" title="Idade do paciente"
                  >{{ item.pes_nascimento | filterAge }} anos</span
                >
                <span class="record-number" title="Nº dp prontuário">{{
                  item.ppr_numero | fillZero
                }}</span>
              </div>
            </div>

            <div class="row-place">
              <span class="row-title">Setor / Sala / Leito</span>
              <span class="bed" title="Setor / Sala / Leito">{{
                concatPlace(item)
              }}</span>

              <span class="row-title margin-top">Clínica</span>
              <span class="clinic" title="Clínica">{{
                item.inc_nome | nonInformed
              }}</span>

              <span class="row-title margin-top">Enfermaria</span>
              <span class="ward" title="Enfermaria">{{
                item.leito_lca_nome | nonInformed
              }}</span>

              <span class="optionals margin-top">
                <span v-if="item.cci_cti !== 0">CTI</span>
                <span v-if="item.rax_nome !== 'NENHUM'">{{
                  item.rax_nome
                }}</span>
                <span v-if="item.cci_reserva_sangue !== 0">SANGUE</span>
              </span>
            </div>
          </div>

          <div class="column col-procedure">
            <span class="column-title">PROCEDIMENTOS</span>
            <span
              v-for="(procedure, procedureIdx) in item.procedures"
              :key="procedureIdx"
              :title="procedure.tuc_nome"
              class="procedure-item"
            >
              {{ procedure.tuc_nome }}
            </span>
          </div>

          <div class="column col-team">
            <span class="column-title">EQUIPE</span>
            <div
              v-for="(employee, employeeIdx) in item.employees"
              :key="employeeIdx"
              :title="employee.pes_nome"
              class="team-item"
            >
              <span class="function-name">{{ employee.fnc_nome }}</span>
              <span class="employee-name">{{ employee.pes_nome }}</span>
            </div>
          </div>

          <div class="column col-obs">
            <span class="column-title">OBS</span>
            <span v-if="item.cci_observacao" class="obs">{{
              item.cci_observacao
            }}</span>
          </div>
        </li>
      </ul>
    </section>
  </section>
</template>

<script>
import SurgeryControlModel from "$surgery-center/surgery/model/SurgeryControlModel";
import SurgeryMapHeader from "./surgery-map-header/SurgeryMapHeader";
import UnitHealthInfoModel from "$surgery-center/unit-health/model/UnitHealthInfo";

import moment from "moment";
moment.locale("pt-br");

export default {
  name: "PrintMap",
  components: {
    SurgeryMapHeader,
  },
  filters: {
    nonInformed(pString) {
      return pString || "NÃO INFORMADO";
    },
    fillZero(pNumber) {
      if (!pNumber) return "";
      return `0000000000${pNumber}`.slice(-10);
    },
    filterAge(pAge) {
      const dateNow = new Date();
      const birthDate = new Date(pAge);
      const age = Math.floor((dateNow - birthDate) / 365 / 24 / 60 / 60 / 1000);
      return age;
    },
    filterShortTime(pTime) {
      const shortTime = (pTime + ":00").slice(0, 5);
      return shortTime;
    },
    prettifyList(pList, pParam) {
      const list = pList.map((pValue) => pValue[pParam]);
      return list.join(", ");
    },
    prettifyBoolean(pBoolean) {
      const word = pBoolean ? "S" : "N";
      return word;
    },
    zeroFill(pMedicalRecord) {
      const medicalRecord = pMedicalRecord;
      if (medicalRecord) {
        return ("0000000000" + medicalRecord).slice(-10);
      }
    },
    prettifyListEmployee(pList, pParam) {
      let ret = pList.filter((pItem) => pItem.ceq_id_funcoes === 6);
      ret = ret.length === 0 ? pList[0] : ret[0];
      if (typeof pParam === "string") {
        return ret[pParam];
      } else {
        let result = "";
        const separator = " - ";
        pParam.forEach((item) => {
          result = result.concat(ret[item] + separator);
        });
        return result.substr(0, result.length - separator.length);
      }
    },
  },
  data() {
    return {
      incomingData: {},
      fillData: {},
      headerInfo: {
        name: "",
        city: "",
        state: "",
        address: "",
        number: "",
      },
    };
  },
  computed: {
    printDate() {
      const formatedData = moment(new Date()).format("DD/MM/YYYY HH:mm:ss");
      return `Data Impressão: ${formatedData}`;
    },
    username() {
      return this.$store.getters["Login/GET_USER_NAME"];
    },
    dateTimeStart() {
      const rawStartDate = this.incomingData.initial_date;
      const startDate = moment(rawStartDate).format("DD/MM/YYYY");
      return `${startDate}`;
    },
    dateStartEnd() {
      const startDate = moment(this.incomingData.initial_date).format(
        "DD/MM/YYYY",
      );
      const endDate = moment(this.incomingData.final_date).format("DD/MM/YYYY");
      return `${startDate} - ${endDate}`;
    },
    timeStartEnd() {
      const startTime = this.incomingData.initial_hour
        ? moment(this.incomingData.initial_hour, "HH:mm").format("HH:mm")
        : "00:00";
      const endTime = this.incomingData.final_hour
        ? moment(this.incomingData.final_hour, "HH:mm").format("HH:mm")
        : "23:59";
      return `${startTime} ~ ${endTime}`;
    },
  },
  mounted() {
    const auxData = this.getParams(window.location.search);
    this.incomingData = JSON.parse(auxData);
    this.$nextTick(() => {
      this.fillPrintForm(this.incomingData);
    });
  },
  methods: {
    fillPrintForm(pFilters) {
      SurgeryControlModel.getSurgeryMap(pFilters).then((pRes) => {
        if (pRes) {
          this.fillData = pRes;
          this.$nextTick(() => {
            this.getUnitHealthData().then(() => {
              window.print();
            });
          });
        }
      });
    },
    getUnitHealthData() {
      return new Promise((resolve, reject) => {
        UnitHealthInfoModel.showHeaderInfo(this.incomingData.uns_id).then(
          (res) => {
            this.headerInfo.name = res.data.uns_nome;
            this.headerInfo.city = res.data.mun_nome;
            this.headerInfo.state = res.data.est_sigla;
            this.headerInfo.address = res.data.end_logradouro;
            this.headerInfo.number = res.data.end_numero;
            resolve();
          },
        );
      });
    },
    getParams(pParams) {
      const AUX = decodeURIComponent(pParams);
      const firstSplit = AUX.toString().split(/=|&/gi);
      const paramsSplited = firstSplit[1].split(",");
      return paramsSplited;
    },
    concatPlace(pItem) {
      const retJoined = [];
      const sector = pItem.centro_cirurgico;
      const placeAttendance = pItem.sala;
      const bed = pItem.inl_nome;
      if (sector) retJoined.push(sector);
      if (placeAttendance) retJoined.push(placeAttendance);
      if (bed) retJoined.push(bed);
      return retJoined.join(" / ");
    },
  },
};
</script>
