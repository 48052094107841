import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation ResendPatientToLotus($personId: Int!) {
    ResendPatientToLotus(personId: $personId) {
      success
      message
    }
  }
`;

const ResendPatientToLotus = async (store, personInfo) => {
  const variables = personInfo;

  try {
    const { data } = await GraphQLClient.mutate({ mutation, variables });
    return data.ResendPatientToLotus;
  } catch (err) {
    const jsonResponse = JSON.parse(JSON.stringify(err));
    return Promise.reject(jsonResponse.graphQLErrors[0].message);
  }
};

export default ResendPatientToLotus;
