<style src="./GridBlock.scss" lang="scss" scoped></style>
<template lang="html">
  <div class="grid-block-container">
    <div
      :style="style"
      :title="title"
      class="grid-block"
      @click="selectSurgery"
    >
      <div v-if="details.length > 1" class="conditioned-indicator">
        {{ details.length - 1 }}
      </div>
      <div class="block-label block-label--records">
        <span>
          {{ details[0].name }}
        </span>
      </div>
    </div>
    <RgListConditionedModal
      v-if="details.length > 1"
      :surgeries-details="details"
      :show="showModalConditionedList"
      @close="closeModalConditionedlList"
    />
  </div>
</template>

<script>
import RgListConditionedModal from "$surgery-center/foundation/component/rg-list-conditioned-modal/RgListConditionedModal";
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "GridBlock",
  components: {
    RgListConditionedModal,
  },
  props: {
    stageId: {
      default: null,
    },
    hourBlock: {
      default: null,
    },
    details: {
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      style: {},
      showModalConditionedList: false,
      ordering: 0,
      formatedHour: "",
      formatedDuration: "",
    };
  },
  computed: {
    ...mapGetters({
      stageTotalTime: "SurgeryCenter/Surgery/stageTime",
    }),
    title() {
      this.formatHour();
      this.formatDuration();
      return `Hora Início: ${this.formatedHour}     Duração:  ${this.formatedDuration} minutos\n\nNº Cirurgia: ${this.details[0].surgeryCode}\nClínica: ${this.details[0].clinic}\nPaciente: ${this.details[0].name}\nMédico Solicitante: ${this.details[0].medic}`;
    },
  },
  watch: {
    details: {
      handler: function () {
        this.setBlockStyle();
      },
      deep: true,
    },
  },
  mounted() {
    this.setBlockStyle();
  },
  methods: {
    setBlockStyle() {
      if (!this.$parent.$refs[`stageContainer_${this.stageId}`]) return null;
      const stageHeight = this.$parent.$refs[
        `stageContainer_${this.stageId}`
      ][0].clientHeight;
      const stageTime = this.stageTotalTime;
      const duration = this.details[0].duration;
      const timeHeight = stageTime.endTime - stageTime.startTime;
      const blockTop = this.calcBlockTop({
        stageTime,
        stageHeight,
        timeHeight,
      });
      const blockHeight = this.calcBlockHeight({
        stageHeight,
        duration,
        timeHeight,
      });
      const ordering = blockTop.number;
      this.style = {
        top: blockTop.style,
        height: blockHeight,
        "z-index": ordering,
      };
    },
    calcBlockTop({ stageTime, stageHeight, timeHeight }) {
      const hourBlock = this.hourBlock - stageTime.startTime;
      const blockTop =
        (stageHeight * (hourBlock + 7.5 * 1000 * 60)) / timeHeight;
      return {
        style: blockTop.toFixed(8) + "px",
        number: Math.floor(blockTop),
      };
    },
    calcBlockHeight({ stageHeight, duration, timeHeight }) {
      const durationTimestamp = duration * 60 * 1000;
      const blockHeight = (stageHeight * durationTimestamp) / timeHeight;
      return blockHeight.toFixed(8) + "px";
    },
    selectSurgery() {
      window.location.href = "#scheduling-surgery";
      const item = {
        cci_id: this.details[0].id,
        cci_id_setores: this.$store.getters[
          "SurgeryCenter/Surgery/GET_CALENDAR_SECTOR"
        ],
      };
      if (this.details.length > 1) {
        this.showModalConditionedList = true;
      } else {
        this.$store.dispatch("SurgeryCenter/Surgery/editSurgery", { item });
      }
    },
    closeModalConditionedlList() {
      this.showModalConditionedList = false;
    },
    formatHour() {
      let hour = this.details[0].initialHour;
      let temp = "";
      hour = hour.split(":");
      temp =
        hour[1] === "00" ? parseInt(hour[0]) + "h" : hour[0] + ":" + hour[1];
      this.formatedHour = temp;
    },
    formatDuration() {
      const duration = moment.duration(this.details[0].duration, "minutes");
      const hours =
        duration.hours() <= 9 ? `0${duration.hours()}` : duration.hours();
      const minutes =
        duration.minutes() <= 9 ? `0${duration.minutes()}` : duration.minutes();
      this.formatedDuration = hours + ":" + minutes;
    },
  },
};
</script>
