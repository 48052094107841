import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query hospitalizationAihProcedures($iafId: Int) {
    hospitalizationAihProcedures(iaf_id: $iafId) {
      iap_id
      iap_id_internacoes_procedimentos
      iap_id_funcionarios
      iap_cbo
      iap_nome_executante
      iap_documento_executante
      iap_id_internacoes_documentos_aih
      iap_numero_documento
      iap_quantidade
      iap_tipo_procedimentos
      iap_codigo_procedimentos
      iap_codigo_servico
      iap_codigo_classificacao
      iap_cnes
      iap_cnpj
      iap_competencia
      procedure {
        CO_REGISTRO
      }
      additionalData
    }
  }
`;

export default async ({ commit }, { iaf_id }) => {
  try {
    const variables = {
      iafId: iaf_id,
    };
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.hospitalizationAihProcedures;
  } catch (err) {
    return err.graphQLErrors;
  }
};
