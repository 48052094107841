<script>
import { RgRemoteComboboxMixin } from "~tokio/primitive";
import ListSectorsWithActiveProfessional from "./action/ListSectorsWithActiveProfessional";

export default {
  name: "RgComboboxSectorActiveProfessionalLegacy",
  mixins: [RgRemoteComboboxMixin],
  props: {
    unitHealthId: {
      required: true,
      default: 0,
    },
    label: {
      type: String,
      default: "Setor",
    },
    defaultText: {
      type: String,
      default: "Todos",
    },
  },
  watch: {
    unitHealthId(pValue, pPreviously) {
      const isDiferentValue = pValue !== pPreviously;

      if (isDiferentValue) {
        this.getDataFromRemote();
      }
    },
  },
  methods: {
    doSearch() {
      const hasUnitHealthId = this.unitHealthId > 0;

      if (!hasUnitHealthId) return Promise.resolve([]);

      return ListSectorsWithActiveProfessional({
        intIdUnidade: this.unitHealthId,
      });
    },
    formatItem(item) {
      return {
        value: item.data,
        text: `${item.label}`,
      };
    },
    blur() {
      if (this.isNecessary) {
        this.validate();
        this.$emit("blur");
      }
    },
  },
};
</script>
