<template lang="html">
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 202.071 202.071"
    style="enable-background: new 0 0 202.071 202.071"
    xml:space="preserve"
  >
    <path
      d="M50.518,188.535h101.038l50.515-87.5l-50.515-87.5H50.518L0,101.035L50.518,188.535z M59.178,28.535h83.718l41.854,72.5
  l-41.854,72.5H59.178l-41.858-72.5L59.178,28.535z"
    />
  </svg>
</template>

<script>
export default {
  name: "GeometryHexagon",
};
</script>
