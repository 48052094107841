<style src="./EffectivenessBasicInfo.scss" lang="scss" scoped></style>
<template lang="html">
  <RgValidatorForm ref="form" class="related-info">
    <div class="surgery-form">
      <div class="row">
        <RgComboboxRequestingClinician
          v-model="form.cci_id_internacoes_clinicas"
          :rules="{ required: true }"
          class="col-6"
        />
        <RgComboboxSurgeryRequestingPhysician
          v-model="form.cci_id_funcionarios"
          :rules="{ required: true }"
          :clinician="form.cci_id_internacoes_clinicas"
          class="col-6"
        />
      </div>
      <div class="row">
        <RgComboboxSurgeryReason
          v-model="form.cci_id_finalidades"
          label="Finalidade da Cirurgia"
          class="col-12"
        />
      </div>
      <div class="surgery-type-container">
        <div class="row">
          <RgComboboxSector
            v-model="form.cci_id_setores"
            :rules="{ required: true }"
            class="col-6 combobox-row"
            label="Centro Cirúrgico"
          />
          <RgComboboxPlace
            v-model="form.cci_id_locais_atendimento"
            :sector="form.cci_id_setores"
            :rules="{ required: true }"
            empty-default-text="Selecione um centro cirúrgico"
            class="col-6 combobox-row"
            label="Sala"
          />
        </div>

        <div class="row row-margin">
          <RgInputDate
            v-model="form.ccf_data_entrada"
            :rules="{ required: true, fn: validadeDateInputStart }"
            class="col-3"
            label="Data Entrada"
            placeholder="00/00/0000"
          />
          <RgInputHour
            v-model="form.ccf_hora_entrada"
            :rules="{ required: true, fn: validadeTimeInputStart }"
            class="col-3"
            label="Hora Entrada"
            placeholder="00:00"
          />
          <RgInputDate
            v-model="form.ccf_data_inicio"
            :rules="{ required: true, fn: validadeDateInputStart }"
            class="col-3"
            label="Data Início"
            placeholder="00/00/0000"
          />
          <RgInputHour
            v-model="form.ccf_hora_inicio"
            :rules="{ required: true, fn: validadeTimeInputStart }"
            class="col-3"
            label="Hora Início"
            placeholder="00:00"
          />
        </div>

        <div class="row row-margin">
          <RgInputDate
            v-model="form.ccf_data_termino"
            :rules="{ required: true, fn: validadeDateInputStart }"
            class="col-3"
            label="Data Término"
            placeholder="00/00/0000"
          />
          <RgInputHour
            v-model="form.ccf_hora_termino"
            :rules="{ required: true, fn: validadeTimeInputStart }"
            class="col-3"
            label="Hora Término"
            placeholder="00:00"
          />
          <RgInputDate
            v-model="form.ccf_data_saida"
            :rules="{ required: true, fn: validadeDateInputStart }"
            class="col-3"
            label="Data Saída"
            placeholder="00/00/0000"
          />
          <RgInputHour
            v-model="form.ccf_hora_saida"
            :rules="{ required: true, fn: validadeTimeInputStart }"
            class="col-3"
            label="Hora Saída"
            placeholder="00:00"
          />
        </div>
      </div>

      <section class="row">
        <RgComboboxSurgeryAnesthesia
          v-model="form.cci_id_tipos_anestesias"
          :rules="{ required: true }"
          class="col-6"
          label="Tipo de Anestesia"
        />
        <RgComboboxSurgeryXRay
          v-model="form.cci_id_raios_x"
          :rules="{ required: true }"
          class="col-6 combobox-row"
          label="Raio-X"
        />
      </section>

      <section class="row">
        <RgComboboxContamination
          v-model="form.ccf_id_contaminacoes"
          :rules="{ required: true }"
          class="col-12 combobox-row"
          label="Potencial de Contaminação"
        />
      </section>

      <div class="row">
        <RgRadioYesNo
          v-model="form.cci_retorno_infeccao"
          :yes-value="1"
          :no-value="0"
          default-value="0"
          label="Retorno por Infecção"
          class="rg-radio col-3 combobox-row"
          @noClicked="resetComboPatientSurgeries"
        />
        <RgComboboxPatientSurgeries
          v-model="form.surgery_relationship"
          :surgery="form.cci_id"
          :patient="patientId"
          :disable="isInfecctionReturn"
          class="col-9"
        />
      </div>

      <div class="row">
        <RgRadioYesNo
          v-model="form.cci_cti"
          :yes-value="1"
          :no-value="0"
          default-value="0"
          label="CTI"
          class="rg-radio col-2 combobox-row"
        />
        <RgRadioYesNo
          v-model="form.cci_id_tipos_cirurgias"
          default-value="1"
          yes-value="2"
          no-value="1"
          label="Cirurgia Condicionada"
          class="rg-radio col-3 combobox-row"
        />
        <RgRadioYesNo
          v-model="form.cci_reserva_sangue"
          :yes-value="1"
          :no-value="0"
          default-value="0"
          label="Reservar Sangue"
          class="rg-radio col-3 combobox-row"
        />

        <fieldset class="rg-radio col-4">
          <label class="description--radio">Tipo de Procedimento</label>
          <div class="form-requesting--radio">
            <label class="radio">
              <input
                v-model="form.cci_id_tipos_procedimentos"
                type="radio"
                name="procedure-type"
                value="1"
                class="rg-radio"
              />
              <span>Hospitalar</span>
            </label>
            <label class="radio">
              <input
                v-model="form.cci_id_tipos_procedimentos"
                type="radio"
                name="procedure-type"
                value="2"
                class="rg-radio"
              />
              <span>Ambulatorial</span>
            </label>
          </div>
        </fieldset>
      </div>
    </div>

    <section class="additional-info">
      <RgTabs :itens="itens">
        <section slot="procedimentos" class="scheduling-procedure-list">
          <SchedulingProcedureList
            v-model="form.procedures"
            :surgery-id="surgeryId"
          />
        </section>
        <section slot="equipe" class="scheduling-surgery-employees">
          <SchedulingSurgeryEmployee
            v-model="form.employees"
            :surgery-id="surgeryId"
            :sector-id="form.cci_id_setores"
          />
        </section>
        <section slot="material" class="scheduling-surgery-material">
          <SurgeryMaterial
            v-model="form.materials"
            :surgery-id="surgeryId"
            :sector-id="form.cci_id_setores"
          />
        </section>
        <section slot="kits" class="scheduling-surgery-kits">
          <SurgeryKits
            v-model="form.kits"
            :surgery-id="surgeryId"
            :sector-id="form.cci_id_setores"
          />
        </section>
        <section slot="equipamentos" class="scheduling-surgery-equipment">
          <SurgeryEquipment v-model="form.equipments" :surgery-id="surgeryId" />
        </section>
        <section slot="observacao" class="scheduling-surgery-observation">
          <SurgeryObservation v-model="form.cci_observacao" />
        </section>
      </RgTabs>
    </section>
  </RgValidatorForm>
</template>

<script>
import { AlertError } from "~common/component/tokio/primitive/notification";
import {
  RgInputHour,
  RgInputDate,
  RgValidatorForm,
  RgValidatorMixin,
} from "~tokio/primitive";
import { RgTabs } from "~tokio/foundation/container";
import {
  RgComboboxSector,
  RgComboboxPlace,
} from "$surgery-center/unit-health/component";
import RgComboboxSurgeryRequestingPhysician from "$surgery-center/surgery/component/combobox/rg-combobox-surgery-requesting-physician/RgComboboxSurgeryRequestingPhysician";
import RgComboboxRequestingClinician from "$surgery-center/surgery/component/combobox/rg-combobox-requesting-clinician/RgComboboxRequestingClinician";
import RgComboboxSurgeryAnesthesia from "$surgery-center/surgery/component/combobox/rg-combobox-surgery-anesthesia/RgComboboxSurgeryAnesthesia";
import RgComboboxSurgeryXRay from "$surgery-center/surgery/component/combobox/rg-combobox-surgery-x-ray/RgComboboxSurgeryXRay";
import RgComboboxContamination from "$surgery-center/surgery/component/combobox/rg-combobox-contamination/RgComboboxContamination";

import SchedulingProcedureList from "$surgery-center/surgery/component/scheduling-procedure-list/SchedulingProcedureList";
import SchedulingSurgeryEmployee from "$surgery-center/surgery/component/scheduling-surgery-employee/SchedulingSurgeryEmployee";
import SurgeryMaterial from "$surgery-center/surgery/component/surgery-material/SurgeryMaterial";
import SurgeryKits from "$surgery-center/surgery/component/surgery-kits/SurgeryKits";
import SurgeryEquipment from "$surgery-center/surgery/component/surgery-equipment/SurgeryEquipment";
import SurgeryObservation from "$surgery-center/surgery/component/surgery-observation/SurgeryObservation";
import RgRadioYesNo from "$surgery-center/foundation/component/rg-radio-yes-no/RgRadioYesNo";
import RgComboboxPatientSurgeries from "$surgery-center/surgery/component/combobox/rg-combobox-patient-surgeries/RgComboboxPatientSurgeries";
import RgComboboxSurgeryReason from "$surgery-center/surgery/component/combobox/rg-combobox-surgery-reason/RgComboboxSurgeryReason";
import moment from "moment";

export default {
  name: "EffectivenessBasicInfo",
  components: {
    RgInputHour,
    RgInputDate,
    RgTabs,
    RgComboboxSurgeryRequestingPhysician,
    RgComboboxRequestingClinician,
    RgComboboxSector,
    RgComboboxPlace,
    RgComboboxSurgeryAnesthesia,
    RgComboboxSurgeryXRay,
    RgComboboxContamination,
    SurgeryMaterial,
    SurgeryKits,
    SurgeryEquipment,
    SurgeryObservation,
    SchedulingProcedureList,
    SchedulingSurgeryEmployee,
    RgValidatorForm,
    RgRadioYesNo,
    RgComboboxPatientSurgeries,
    RgComboboxSurgeryReason,
  },
  mixins: [RgValidatorMixin],
  props: {
    surgeryId: {
      default: null,
    },
    value: {
      default: null,
    },
    patientId: {
      default: null,
    },
  },
  data() {
    return {
      itens: [
        { label: "Procedimentos", slot: "procedimentos" },
        { label: "Equipe", slot: "equipe" },
        { label: "Material", slot: "material" },
        { label: "Kits", slot: "kits" },
        { label: "Equipamentos", slot: "equipamentos" },
        { label: "Observação", slot: "observacao" },
      ],
      form: {
        cci_id_internacoes_clinicas: "",
        cci_id_funcionarios: "",
        cci_id_setores: "",
        cci_id_locais_atendimento: "",
        ccf_data_entrada: "", // perform.info
        ccf_hora_entrada: "", // perform.info
        ccf_data_inicio: "", // perform.info
        ccf_hora_inicio: "", // perform.info
        ccf_data_termino: "", // perform.info
        ccf_hora_termino: "", // perform.info
        ccf_data_saida: "", // perform.info
        ccf_hora_saida: "", // perform.info
        cci_id_tipos_anestesias: "",
        cci_id_raios_x: "",
        ccf_id_contaminacoes: "",
        cci_cti: 0,
        cci_id_tipos_cirurgias: 1,
        cci_reserva_sangue: 0,
        cci_id_tipos_procedimentos: 1,
        cci_retorno_infeccao: 0,
        procedures: [],
        employees: [],
        materials: [],
        kits: [],
        equipments: [],
        cci_observacao: "",
      },
    };
  },
  computed: {
    validateProcedures() {
      return this.form.procedures.length > 0;
    },
    validateEmployees() {
      return this.form.employees ? this.form.employees.length > 0 : false;
    },
    validValue() {
      return this.form;
    },
    isInfecctionReturn() {
      return (
        this.form.cci_retorno_infeccao === 0 ||
        this.form.cci_retorno_infeccao === "0"
      );
    },
  },
  watch: {
    form: {
      handler: function (pValue) {
        this.$emit("input", this.form);
      },
      deep: true,
    },
    value(pValue, pOldValue) {
      this.form = pValue;
    },
  },
  methods: {
    validate() {
      this.isValid = this.$refs.form.validate();
      this.error = this.$refs.form.getError();
      return this.isValid;
    },
    resetComboPatientSurgeries() {
      this.form.surgery_relationship = null;
    },
    cleanValidate() {
      this.error = [];
      this.isValid = true;
      this.$refs.form.cleanValidate();
    },
    checkDateTimeStart(pValue, pError) {
      pError.push("É obrigatório inserir um item na lista");
      return false;
    },
    validadeDateInputStart(pValue, pError) {
      const dataEntrada = moment(this.form.ccf_data_entrada, "DD-MM-YYYY");
      const dataSaida = moment(this.form.ccf_data_saida, "DD-MM-YYYY");
      const dataInicio = moment(this.form.ccf_data_inicio, "DD-MM-YYYY");
      const dataTermino = moment(this.form.ccf_data_termino, "DD-MM-YYYY");

      if (dataEntrada.isAfter(dataSaida)) {
        return AlertError(
          "A data de saída não pode ser menor que a data de entrada!",
        );
      } else if (dataEntrada.isAfter(dataInicio)) {
        return AlertError(
          "A data de início não pode ser menor que a data de entrada!",
        );
      } else if (dataInicio.isAfter(dataTermino)) {
        return AlertError(
          "A data de término deve ser maior que a data de início!",
        );
      }
      return true;
    },
    validadeTimeInputStart(pValue, pError) {
      const horaEntrada = moment(this.form.ccf_hora_entrada, "hh:mm");
      const horaSaida = moment(this.form.ccf_hora_saida, "hh:mm ");
      const horaInicio = moment(this.form.ccf_hora_inicio, "hh:mm");
      const horaTermino = moment(this.form.ccf_hora_termino, "hh:mm");

      if (horaEntrada.isAfter(horaSaida)) {
        return AlertError(
          "A hora de saída não pode ser menor que a hora de entrada!",
        );
      } else if (horaEntrada.isAfter(horaInicio)) {
        return AlertError(
          "A hora de início não pode ser menor que a hora de entrada!",
        );
      } else if (horaInicio.isAfter(horaTermino)) {
        return AlertError(
          "A hora de término deve ser maior que a hora de início!",
        );
      }
      return true;
    },
  },
};
</script>
