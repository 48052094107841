import BaseModel from "~common/model/BaseModel";

class SurgeryProcedureModel extends BaseModel {
  defineEndPointURI() {
    return "surgery-center/unified-table";
  }

  getProcedureBySurgeryId(pSurgeryId) {
    return new Promise((resolve, reject) => {
      this._request
        .get("/surgery-center/surgery-procedure", {
          cpr_id_controles_cirurgicos: pSurgeryId,
        })
        .then((pData) => {
          resolve(pData.data || null);
        });
    });
  }
}
export default new SurgeryProcedureModel();
