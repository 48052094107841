<template lang="html">
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 462.4 462.4"
    style="enable-background: new 0 0 462.4 462.4"
    xml:space="preserve"
  >
    <g>
      <path
        d="M210.8,27.2H6.8C3.046,27.2,0,30.247,0,34v401.2h27.2V374c0-3.753,3.046-6.8,6.8-6.8h27.2c3.753,0,6.8,3.047,6.8,6.8v61.2
    h149.6V34C217.6,30.247,214.554,27.2,210.8,27.2z M68,329.8c0,1.877-1.523,3.4-3.4,3.4h-34c-1.877,0-3.4-1.523-3.4-3.4v-34
    c0-1.876,1.523-3.399,3.4-3.399h34c1.877,0,3.4,1.523,3.4,3.399V329.8z M68,255c0,1.877-1.523,3.4-3.4,3.4h-34
    c-1.877,0-3.4-1.523-3.4-3.4v-34c0-1.876,1.523-3.4,3.4-3.4h34c1.877,0,3.4,1.523,3.4,3.4V255z M68,180.2
    c0,1.877-1.523,3.4-3.4,3.4h-34c-1.877,0-3.4-1.523-3.4-3.4v-34c0-1.877,1.523-3.4,3.4-3.4h34c1.877,0,3.4,1.523,3.4,3.4V180.2z
    M68,105.4c0,1.876-1.523,3.4-3.4,3.4h-34c-1.877,0-3.4-1.523-3.4-3.4v-34c0-1.877,1.523-3.4,3.4-3.4h34c1.877,0,3.4,1.523,3.4,3.4
    V105.4z M129.2,329.8c0,1.877-1.523,3.4-3.4,3.4h-34c-1.877,0-3.4-1.523-3.4-3.4v-34c0-1.876,1.523-3.399,3.4-3.399h34
    c1.876,0,3.4,1.523,3.4,3.399V329.8z M129.2,255c0,1.877-1.523,3.4-3.4,3.4h-34c-1.877,0-3.4-1.523-3.4-3.4v-34
    c0-1.876,1.523-3.4,3.4-3.4h34c1.876,0,3.4,1.523,3.4,3.4V255z M129.2,180.2c0,1.877-1.523,3.4-3.4,3.4h-34
    c-1.877,0-3.4-1.523-3.4-3.4v-34c0-1.877,1.523-3.4,3.4-3.4h34c1.876,0,3.4,1.523,3.4,3.4V180.2z M129.2,105.4
    c0,1.876-1.523,3.4-3.4,3.4h-34c-1.877,0-3.4-1.523-3.4-3.4v-34c0-1.877,1.523-3.4,3.4-3.4h34c1.876,0,3.4,1.523,3.4,3.4V105.4z
    M190.4,329.8c0,1.877-1.523,3.4-3.4,3.4h-34c-1.876,0-3.4-1.523-3.4-3.4v-34c0-1.876,1.523-3.399,3.4-3.399h34
    c1.877,0,3.4,1.523,3.4,3.399V329.8z M190.4,255c0,1.877-1.523,3.4-3.4,3.4h-34c-1.876,0-3.4-1.523-3.4-3.4v-34
    c0-1.876,1.523-3.4,3.4-3.4h34c1.877,0,3.4,1.523,3.4,3.4V255z M190.4,180.2c0,1.877-1.523,3.4-3.4,3.4h-34
    c-1.876,0-3.4-1.523-3.4-3.4v-34c0-1.877,1.523-3.4,3.4-3.4h34c1.877,0,3.4,1.523,3.4,3.4V180.2z M190.4,105.4
    c0,1.876-1.523,3.4-3.4,3.4h-34c-1.876,0-3.4-1.523-3.4-3.4v-34c0-1.877,1.523-3.4,3.4-3.4h34c1.877,0,3.4,1.523,3.4,3.4V105.4z
    M457.443,223.006l-204-57.378c-4.338-1.217-8.643,2.04-8.643,6.548V435.2H272v-54.4c0-3.753,3.047-6.8,6.801-6.8H306
    c3.754,0,6.801,3.047,6.801,6.8v54.4h149.6V229.555C462.4,226.508,460.373,223.829,457.443,223.006z M312.801,343.4
    c0,1.877-1.523,3.399-3.4,3.399h-34c-1.877,0-3.4-1.522-3.4-3.399v-34c0-1.877,1.523-3.4,3.4-3.4h34c1.877,0,3.4,1.523,3.4,3.4
    V343.4z M312.801,268.601c0,1.876-1.523,3.399-3.4,3.399h-34c-1.877,0-3.4-1.523-3.4-3.399v-34c0-1.877,1.523-3.4,3.4-3.4h34
    c1.877,0,3.4,1.523,3.4,3.4V268.601z M374,343.4c0,1.877-1.523,3.399-3.4,3.399h-34c-1.877,0-3.4-1.522-3.4-3.399v-34
    c0-1.877,1.523-3.4,3.4-3.4h34c1.877,0,3.4,1.523,3.4,3.4V343.4z M374,268.601c0,1.876-1.523,3.399-3.4,3.399h-34
    c-1.877,0-3.4-1.523-3.4-3.399v-34c0-1.877,1.523-3.4,3.4-3.4h34c1.877,0,3.4,1.523,3.4,3.4V268.601z M435.199,343.4
    c0,1.877-1.521,3.399-3.398,3.399h-34c-1.877,0-3.4-1.522-3.4-3.399v-34c0-1.877,1.523-3.4,3.4-3.4h34
    c1.877,0,3.398,1.523,3.398,3.4V343.4z M435.199,268.601c0,1.876-1.521,3.399-3.398,3.399h-34c-1.877,0-3.4-1.523-3.4-3.399v-34
    c0-1.877,1.523-3.4,3.4-3.4h34c1.877,0,3.398,1.523,3.398,3.4V268.601z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Building",
};
</script>
