<template lang="html">
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 511.999 511.999"
    style="enable-background: new 0 0 511.999 511.999"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M467.666,364.065c10.701-4.29,18.333-15.249,18.333-28.065v-80c0-16.542-12.71-30-28.333-30H411v-69.663
      c0-5.314-2.065-10.143-5.415-13.771c7.046-8.323,12.219-22.488,2.724-45.202c-6.66-15.93-18.248-30.582-24.564-37.897
      c-1.899-2.2-4.662-3.465-7.569-3.465c-2.907,0-5.67,1.265-7.569,3.465c-30.011,34.765-37.701,64.01-21.986,82.874
      c-3.473,3.649-5.62,8.571-5.62,13.995v69.663H291v-99.664c0-5.314-2.065-10.144-5.415-13.771
      c7.046-8.324,12.22-22.488,2.724-45.203c-6.66-15.93-18.248-30.581-24.563-37.897c-1.899-2.2-4.662-3.465-7.569-3.465
      c-2.907,0-5.67,1.265-7.569,3.465c-30.01,34.763-37.701,64.008-21.986,82.874c-3.473,3.65-5.62,8.572-5.62,13.996v99.664h-50.001
      v-69.663c0-5.314-2.065-10.143-5.414-13.77c7.045-8.324,12.219-22.488,2.723-45.203c-6.659-15.931-18.248-30.582-24.563-37.897
      c-1.899-2.201-4.662-3.465-7.569-3.465c-2.907,0-5.67,1.265-7.569,3.465c-30.011,34.764-37.7,64.009-21.986,82.874
      c-3.473,3.65-5.62,8.572-5.62,13.996v69.663H54.332c-15.623,0-28.333,13.458-28.333,30v80c0,12.816,7.632,23.774,18.333,28.065
      l0.001,91.938c0,3.505,0.576,6.87,1.624,9.999h-6.991c-5.522,0-10,4.477-10,10s4.478,10,10,10h434.067c5.522,0,10-4.477,10-10
      s-4.478-10-10-10h-6.991c1.048-3.129,1.624-6.494,1.624-9.999V364.065z M376.134,81.989c12.03,16.333,23.613,38.532,13.216,48.697
      c-3.504,3.426-8.178,5.313-13.162,5.316h-0.128c-5.022-0.03-9.631-1.89-12.978-5.237
      C351.561,119.245,365.431,96.235,376.134,81.989z M361,156.337c0-0.163,0.173-0.336,0.336-0.336h14.604l0.238,0.001
      c0.007,0,0.014-0.001,0.021-0.001h14.466c0.163,0,0.336,0.173,0.336,0.336V226h-30v-69.663H361z M256.133,51.987
      c12.03,16.333,23.614,38.532,13.216,48.698c-3.503,3.426-8.177,5.313-13.161,5.315h-0.129c-5.021-0.03-9.631-1.89-12.977-5.236
      C231.561,89.243,245.43,66.233,256.133,51.987z M270.999,126.336V226h-30v-99.664c0-0.163,0.173-0.336,0.336-0.336h14.602h0.24
      c0.007,0,0.014,0,0.021,0h14.465C270.826,126,270.999,126.173,270.999,126.336z M136.133,81.988
      c12.03,16.332,23.614,38.532,13.216,48.697c-3.503,3.426-8.179,5.313-13.163,5.316h-0.128c-5.021-0.03-9.63-1.89-12.977-5.237
      C111.561,119.244,125.43,96.234,136.133,81.988z M120.998,156.337c0-0.163,0.173-0.336,0.336-0.336h14.604l0.238,0.001
      c0.007,0,0.014-0.001,0.021-0.001h14.467c0.163,0,0.335,0.173,0.335,0.336V226h-30v-69.663H120.998z M447.664,372.341h-31.035
      c-5.522,0-10,4.477-10,10s4.478,10,10,10h31.035v63.66c0,5.417-3.813,9.994-8.325,9.999H72.658
      c-4.513-0.005-8.325-4.582-8.325-9.999v-7.661h31.035c5.522,0,10-4.477,10-10s-4.478-10-10-10H64.333l-0.001-64.274
      c10.702-4.29,18.334-15.249,18.334-28.065v-29.76c0-6.625,4.749-12.222,10.37-12.222c5.621,0,10.37,5.597,10.37,12.222v41.058
      c0,17.768,13.624,32.222,30.371,32.222c16.746,0,30.37-14.455,30.37-32.222v-41.058c0-6.625,4.749-12.222,10.37-12.222
      c5.622,0,10.371,5.597,10.371,12.222v2.557c0,17.767,13.624,32.222,30.37,32.222c16.746,0,30.37-14.455,30.37-32.222v-2.557
      c0-6.625,4.749-12.222,10.37-12.222c5.622,0,10.371,5.597,10.371,12.222v21.057c0,17.767,13.624,32.222,30.37,32.222
      c16.746,0,30.37-14.455,30.37-32.222v-21.057c0-6.625,4.749-12.222,10.37-12.222c5.622,0,10.371,5.597,10.371,12.222v2.557
      c0,17.767,13.624,32.222,30.37,32.222c16.746,0,30.37-14.455,30.37-32.222v-2.557c0-6.625,4.749-12.222,10.371-12.222
      c5.621,0,10.37,5.597,10.37,12.222v29.76c0,12.816,7.632,23.775,18.333,28.065V372.341z M465.999,336c0,5.42-3.816,10-8.333,10
      c-4.517,0-8.333-4.58-8.333-10v-29.76c0-17.768-13.624-32.222-30.37-32.222c-16.747,0-30.371,14.455-30.371,32.222v2.557
      c0,6.625-4.749,12.222-10.37,12.222c-5.621,0-10.37-5.597-10.37-12.222v-2.557c0-17.768-13.624-32.222-30.371-32.222
      c-16.746,0-30.37,14.455-30.37,32.222v21.057c0,6.625-4.749,12.222-10.37,12.222c-5.621,0-10.37-5.597-10.37-12.222V306.24
      c0-17.768-13.624-32.222-30.371-32.222c-16.746,0-30.37,14.455-30.37,32.222v2.557c0,6.625-4.749,12.222-10.37,12.222
      c-5.621,0-10.37-5.597-10.37-12.222v-2.557c0-17.768-13.624-32.222-30.371-32.222c-16.746,0-30.37,14.455-30.37,32.222v41.058
      c0,6.625-4.749,12.222-10.37,12.222c-5.622,0-10.371-5.597-10.371-12.222V306.24c0-17.768-13.624-32.222-30.37-32.222
      c-16.746,0-30.37,14.455-30.37,32.222V336c0,5.42-3.816,10-8.334,10c-4.517,0-8.333-4.58-8.333-10v-80c0-5.42,3.816-10,8.334-10
      h403.331c4.517,0,8.333,4.58,8.333,10V336z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M501.999,466h-0.235c-5.522,0-10,4.477-10,10s4.478,10,10,10h0.235c5.523,0,10-4.477,10-10
      C511.999,470.477,507.522,466,501.999,466z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M10.235,466H10c-5.523,0-10,4.477-10,10s4.477,10,10,10h0.235c5.522,0,10-4.477,10-10
      C20.235,470.477,15.758,466,10.235,466z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M124.334,428.342h-0.235c-5.522,0-10,4.477-10,10s4.478,10,10,10h0.235c5.522,0,10-4.477,10-10
      S129.856,428.342,124.334,428.342z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M387.9,372.343h-0.236c-5.522,0-10,4.477-10,10s4.478,10,10,10h0.236c5.523,0,10-4.477,10-10
      S393.423,372.343,387.9,372.343z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M254.804,367.345h-19c-5.522,0-10,4.477-10,10s4.478,10,10,10h19c5.522,0,10-4.477,10-10S260.326,367.345,254.804,367.345
      z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M182.804,395.849h-13c-5.522,0-10,4.477-10,10s4.478,10,10,10h13c5.523,0,10-4.477,10-10
      C192.804,400.326,188.326,395.849,182.804,395.849z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M405.804,428.342h-23c-5.522,0-10,4.477-10,10s4.478,10,10,10h23c5.522,0,10-4.477,10-10S411.326,428.342,405.804,428.342
      z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M333.304,386.349h-13c-5.522,0-10,4.477-10,10s4.478,10,10,10h13c5.522,0,10-4.477,10-10
      C343.304,390.826,338.826,386.349,333.304,386.349z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M277.304,428.342h-13c-5.522,0-10,4.477-10,10s4.478,10,10,10h13c5.522,0,10-4.477,10-10S282.826,428.342,277.304,428.342
      z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "Cake",
};
</script>
