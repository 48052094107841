import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import ExamPatientObservationQueue from "$exam/submodules/schedule/views/queue-observation/ExamPatientObservationQueue";
import { OpenMenuModule } from "~common/utils/app/menu";

export default {
  path: "/exam/schedule/per-day/observation-queue",
  name: "exam.schedule.per-day.observation-queue",
  components: {
    default: ExamPatientObservationQueue,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Exame", link: OpenMenuModule("exam") },
      {
        label: "Agendamentos por Dia",
        link: "/exam/schedule/per-day",
      },
      { label: "Observações do Paciente na Fila" },
    ],
  },
};
