<template lang="html">
  <svg
    id="Capa_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M362.937,0h-294c-16.542,0-30,13.458-30,30v102c0,5.523,4.478,10,10,10s10-4.477,10-10V30c0-5.514,4.486-10,10-10h294
    c5.514,0,10,4.486,10,10v372c0,5.514-4.486,10-10,10h-294c-5.514,0-10-4.486-10-10V216.333c0-5.523-4.478-10-10-10
    s-10,4.477-10,10V402c0,16.542,13.458,30,30,30h294c16.542,0,30-13.458,30-30V30C392.937,13.458,379.479,0,362.937,0z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M56.006,169.7c-1.859-1.86-4.439-2.92-7.069-2.92s-5.21,1.06-7.07,2.92c-1.861,1.87-2.93,4.45-2.93,7.07
    c0,2.64,1.07,5.21,2.93,7.08c1.86,1.86,4.44,2.93,7.07,2.93c2.629,0,5.209-1.07,7.069-2.93c1.861-1.86,2.931-4.44,2.931-7.08
    C58.937,174.14,57.866,171.57,56.006,169.7z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M96.066,454.99c-1.86-1.86-4.429-2.93-7.07-2.93c-2.63,0-5.21,1.07-7.069,2.93c-1.861,1.86-2.931,4.44-2.931,7.07
    c0,2.64,1.071,5.21,2.931,7.07c1.859,1.87,4.439,2.93,7.069,2.93c2.641,0,5.21-1.06,7.07-2.93c1.861-1.86,2.93-4.43,2.93-7.07
    C98.996,459.43,97.926,456.85,96.066,454.99z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M423,40.063c-5.522,0-10,4.477-10,10v392c0,5.514-4.486,10-10,10H129c-5.522,0-10,4.477-10,10s4.478,10,10,10h274
    c16.542,0,30-13.458,30-30v-392C433,44.54,428.522,40.063,423,40.063z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M463.063,80c-5.522,0-10,4.477-10,10v392c0,5.514-4.486,10-10,10h-314c-5.522,0-10,4.477-10,10s4.478,10,10,10h314
    c16.542,0,30-13.458,30-30V90C473.063,84.477,468.585,80,463.063,80z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M200.937,74h-96c-5.522,0-10,4.477-10,10v96c0,5.523,4.478,10,10,10h96c5.522,0,10-4.477,10-10V84
    C210.937,78.477,206.459,74,200.937,74z M190.937,170h-76V94h76V170z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M168.761,235.402c-3.906-3.904-10.236-3.905-14.143,0l-26.583,26.583l-12.36-12.361c-3.905-3.905-10.235-3.904-14.143,0
    c-3.905,3.905-3.905,10.237,0,14.142l19.432,19.432c1.876,1.875,4.419,2.929,7.071,2.929c2.653,0,5.195-1.054,7.072-2.929
    l33.654-33.654C172.666,245.639,172.666,239.307,168.761,235.402z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M332.604,249H215.937c-5.522,0-10,4.477-10,10s4.478,10,10,10h116.667c5.522,0,10-4.477,10-10S338.126,249,332.604,249z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M326.937,74h-70c-5.522,0-10,4.477-10,10s4.478,10,10,10h70c5.522,0,10-4.477,10-10S332.459,74,326.937,74z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M326.937,122h-70c-5.522,0-10,4.477-10,10s4.478,10,10,10h70c5.522,0,10-4.477,10-10S332.459,122,326.937,122z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M326.937,170h-70c-5.522,0-10,4.477-10,10s4.478,10,10,10h70c5.522,0,10-4.477,10-10S332.459,170,326.937,170z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M168.761,311.769c-3.906-3.904-10.236-3.905-14.143,0l-26.583,26.583l-12.36-12.361c-3.905-3.905-10.235-3.904-14.143,0
    c-3.905,3.905-3.905,10.237,0,14.142l19.432,19.432c1.876,1.875,4.419,2.929,7.071,2.929c2.653,0,5.195-1.054,7.072-2.929
    l33.654-33.654C172.666,322.006,172.666,315.674,168.761,311.769z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M332.604,325H215.937c-5.522,0-10,4.477-10,10s4.478,10,10,10h116.667c5.522,0,10-4.477,10-10S338.126,325,332.604,325z"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "RegisterPerson",
};
</script>
