import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

import ExamSchedulePerPeriodRoute from "$exam/submodules/schedule/views/per-period/ExamSchedulePerPeriod";
import { OpenMenuModule } from "~common/utils/app/menu";

export default {
  path: "/exam/schedule/per-period",
  name: "exam.schedule.per-period",
  components: {
    default: ExamSchedulePerPeriodRoute,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Exame", link: OpenMenuModule("exam") },
      { label: "Agendamentos por Período", link: "/exam/schedule/per-period" },
    ],
  },
};
