import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query ListSectorsMappedPep($unitHealthId: Int!, $placeId: Int) {
    sectorsMappedPep(set_id_unidades_saude: $unitHealthId, lca_id: $placeId) {
      rows {
        set_id
        set_nome
      }
    }
  }
`;
export default async (variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    return data.sectorsMappedPep.rows;
  } catch (err) {
    return err.graphQLErrors;
  }
};
