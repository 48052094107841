<template lang="html">
  <svg
    id="Layer_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <path
        d="M256,0C150.728,0,65.085,85.643,65.085,190.915v292.144c0,15.959,12.982,28.941,28.941,28.941h83.873v-0.052
        c0.078,0,0.156,0.052,0.234,0.052c4.799,0,8.678-3.888,8.678-8.678v-43.945l1.909,1.276c0.26,0.174,0.538,0.365,0.798,0.538
        l8.305,5.58c3.289,2.23,6.5,4.599,9.702,6.968c13.893,10.275,28.264,20.905,48.475,20.905c20.211,0,34.582-10.63,48.475-20.905
        c3.202-2.369,6.413-4.738,9.693-6.968l8.313-5.58c0.113-0.078,0.243-0.156,0.356-0.243c0.946-0.625,1.883-1.258,2.82-1.883v44.258
        c0,4.712,3.766,8.496,8.452,8.626V512h83.872c15.95,0,28.932-12.982,28.932-28.941V190.915C446.915,85.643,361.272,0,256,0z
         M352.204,417.141c-7.741,7.35-16.471,13.885-25.392,20.098c-4.651,3.237-9.338,6.422-14.05,9.572l-8.331,5.597
        c-3.48,2.36-6.882,4.868-10.275,7.376c-12.167,8.999-23.665,17.503-38.157,17.503c-14.492,0-25.99-8.504-38.157-17.503
        c-3.393-2.508-6.795-5.016-10.275-7.376l-8.227-5.537c-4.695-3.141-9.364-6.309-13.998-9.528
        c-8.973-6.248-17.764-12.809-25.548-20.202c-39.346-37.35-42.643-95.536-42.643-147.144c0-31.744,14.761-62.264,39.493-81.634
        c22.294-17.469,47.677-31.275,60.294-37.706C263.376,252.39,365.212,273.66,394.821,277.747
        C394.431,327.35,389.597,381.648,352.204,417.141z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "GenderFemale",
};
</script>
