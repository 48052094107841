<template lang="html">
  <div v-if="show" class="modal-confirm-deletion">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <span class="title"> {{ title }} </span>
      </div>

      <div v-if="!noReason" slot="body" class="body">
        <div v-if="subtitle" class="subtitle">{{ subtitle }}</div>
        <div v-if="bodyText" class="body-text">{{ bodyText }}</div>
        <div class="textarea">
          <RgTextArea
            ref="textArea"
            v-model="reason"
            :maxlength="maxlength"
            :rules="{ required: true }"
            label="Motivo"
            class="rg-text-area"
          />
        </div>
      </div>

      <div v-if="noReason && message" slot="body" class="body">
        <div class="text">
          {{ message }}
        </div>
        <div v-if="bodyText" class="body-text">{{ bodyText }}</div>
      </div>

      <div slot="footer" class="footer">
        <div class="footer-buttons">
          <RgCancelButton medium @click="close" />
          <LargeButton
            ref="confirmButton"
            v-shortkey="['enter']"
            backgroundColor="#f96b70"
            separator
            :label="btnRemoveTitle"
            :title="btnRemoveTitle"
            :disabled="!noReason && reason.length < 5"
            :class="{ disable: !noReason && reason.length < 5 }"
            large
            @shortkey.native="affirm"
            @click="affirm"
          >
            <!-- <div v-if="hasIconSlot" slot="icon" class="icon">
              <slot name="icon" class="icon" />
            </div> -->
            <IconError slot="icon" class="icon" />
          </LargeButton>
        </div>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import {
  RgBaseModal,
  RgCancelButton,
  RgTextArea,
  LargeButton,
} from "~tokio/primitive";

import { IconError } from "~tokio/primitive/icon/symbols";

export default {
  name: "ModalConfirmDeletion",
  components: {
    RgBaseModal,
    RgCancelButton,
    RgTextArea,
    LargeButton,
    IconError,
  },
  props: {
    show: Boolean,
    noReason: Boolean,
    confirm: Function,
    title: {
      type: String,
      default: "Exclusão do Registro",
    },
    subtitle: {
      type: String,
      default: "",
    },
    bodyText: {
      type: String,
      default: "",
    },
    btnRemoveTitle: {
      type: String,
      default: "Excluir",
    },
    msgSuccess: {
      type: String,
      default: "Exclusão realizada com sucesso!",
    },
    maxlength: {
      type: Number,
      default: 500,
    },
    message: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      reason: "",
      disableConfirmButton: true,
    };
  },

  computed: {
    // hasIconSlot() {
    //   return !!this.$slots.icon;
    // },
  },

  watch: {
    reason(pValue) {
      !pValue.trim() || pValue.length < 5
        ? (this.disableConfirmButton = true)
        : this.emitReason();
    },
  },
  methods: {
    emitReason() {
      this.disableConfirmButton = false;
      this.$emit("reason", this.reason);
    },

    cleanReason() {
      this.reason = "";
      this.$refs.textArea.resetCharacter();
    },

    close() {
      if (!this.noReason) {
        this.cleanReason();
      }
      this.$emit("close");
    },

    async affirm() {
      this.$loader.start("Excluindo...");

      await this.confirm(this.reason);
      this.close();

      this.$emit("reSearch");
      this.$loader.finish();
    },
  },
};
</script>
