import { GraphQLClient, gql } from "@/common/request/GraphQLClient";
import PersonFields from "../../common/service/fields/PersonFields";
import {
  preparePersonInfo,
  prepareDocuments,
} from "./HospitalizationBillingAihHelper";

const query = gql`
query GetPatientInfo($personId: Int, $personName: String){
	people(pes_id: $personId, pes_nome: $personName){
    rows {
      ${PersonFields}
    }
  }
}
`;
export default async ({ commit }, { personId, personName }) => {
  const variables = {};

  if (personId) {
    variables.personId = personId;
  } else {
    variables.personName = personName;
  }
  const { data } = await GraphQLClient.query({
    query,
    variables,
    fetchPolicy: "no-cache",
  });

  if (data.people.rows.length < 1) return false;

  const documents = prepareDocuments(data.people.rows[0]);
  const fieldsPrepared = preparePersonInfo(data.people.rows[0], documents);

  commit("SET_PATIENT_DOCUMENTS", documents);
  commit("SET_PATIENT_INFO_TAB", fieldsPrepared);
  return true;
};
