<template lang="html">
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 106.059 106.059"
    style="enable-background: new 0 0 106.059 106.059"
    xml:space="preserve"
  >
    <g>
      <path
        d="M90.544,90.542c20.687-20.685,20.685-54.342,0.002-75.024C69.858-5.172,36.198-5.172,15.515,15.513
      C-5.173,36.198-5.171,69.858,15.517,90.547C36.198,111.23,69.858,111.23,90.544,90.542z M21.302,21.3
      C38.795,3.807,67.261,3.805,84.759,21.302c17.494,17.494,17.492,45.962-0.002,63.455c-17.494,17.494-45.961,17.496-63.455,0.002
      C3.804,67.263,3.806,38.794,21.302,21.3z M58.857,41.671c0-4.798,3.903-8.701,8.703-8.701c4.797,0,8.699,3.902,8.699,8.701
      c0,1.381-1.119,2.5-2.5,2.5s-2.5-1.119-2.5-2.5c0-2.041-1.66-3.701-3.699-3.701c-2.044,0-3.703,1.66-3.703,3.701
      c0,1.381-1.119,2.5-2.5,2.5C59.976,44.171,58.857,43.051,58.857,41.671z M31.134,41.644c0-4.797,3.904-8.701,8.703-8.701
      c4.797,0,8.701,3.903,8.701,8.701c0,1.381-1.119,2.5-2.5,2.5c-1.381,0-2.5-1.119-2.5-2.5c0-2.041-1.66-3.701-3.701-3.701
      c-2.042,0-3.703,1.66-3.703,3.701c0,1.381-1.119,2.5-2.5,2.5S31.134,43.024,31.134,41.644z M54.089,59.371
      c10.084,0,19.084,5.742,22.927,14.63c0.658,1.521-0.041,3.286-1.562,3.943c-1.521,0.66-3.285-0.042-3.943-1.562
      c-2.894-6.689-9.73-11.012-17.421-11.012c-7.869,0-14.747,4.319-17.522,11.004c-0.48,1.154-1.596,1.851-2.771,1.851
      c-0.385,0-0.773-0.074-1.15-0.23c-1.53-0.636-2.256-2.392-1.619-3.921C34.735,65.143,43.788,59.371,54.089,59.371z M25.204,56.801
      c0.001-3.436,4.556-7.535,4.556-7.535c0.438,2.747,1.52,4.344,1.52,4.344c1.218,1.818,1.218,3.507,1.218,3.507
      c0,3.712-3.692,3.68-3.692,3.68C25.204,60.795,25.204,56.801,25.204,56.801z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Sad",
};
</script>
