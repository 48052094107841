<style src="./Billing.scss" lang="scss" scoped></style>
<template lang="html">
  <section
    v-shortkey="['ctrl', 'arrowleft']"
    class="billing"
    @shortkey="goToBulletinList"
  >
    <transition name="show-patient-panel">
      <PatientInfoPanel v-if="showPatientInfoPanel" />
    </transition>
    <PatientInfo />
    <BillingForm
      ref="billingForm"
      @show-modal-default-procedure="showDefaultProcedureModel"
      @attendance-date="selectAttendanceDate"
    />

    <ModalDefaultProcedure
      v-if="modalDefaultProcedure"
      :attendance-date="attendanceDate"
      @close="closeModalDefaultProcedure"
      @insert-default-procedure="insertDefaultProcedure"
    />

    <ModalBillingSearch />
  </section>
</template>

<script>
import BillingForm from "../component/billing-form/BillingForm";
import PatientInfo from "../component/patient-info/PatientInfo";
import PatientInfoPanel from "../component/patient-info-panel/PatientInfoPanel";
import ModalBillingSearch from "../component/modal-billing-search/ModalBillingSearch";
import ModalDefaultProcedure from "../component/modal-default-procedure/ModalDefaultProcedure";
export default {
  name: "Billing",
  components: {
    PatientInfo,
    BillingForm,
    PatientInfoPanel,
    ModalBillingSearch,
    ModalDefaultProcedure,
  },
  data() {
    return {
      showPatientInfoPanel: false,
      modalDefaultProcedure: false,
      attendanceDate: "",
    };
  },
  computed: {
    billingId() {
      return this.$store.getters["Billing/Bulletin/GET_SELECTED_BULLETIN"];
    },
  },
  watch: {
    billingId(pBillingId) {},
  },
  mounted() {
    this.$store.commit("Billing/Bulletin/SET_BULLETIN_LIST", []);
    window.addEventListener("scroll", this.runOnScroll);
  },
  methods: {
    runOnScroll(pScroll) {
      const windowScrollY = window.pageYOffset;
      if (windowScrollY > 350 && this.billingId) {
        this.showPatientInfoPanel = true;
      } else {
        this.showPatientInfoPanel = false;
      }
    },
    insertDefaultProcedure(pProcedures) {
      this.$refs.billingForm.saveDefaultProcedures(pProcedures).then(() => {
        this.modalDefaultProcedure = false;
      });
    },
    showDefaultProcedureModel() {
      this.modalDefaultProcedure = true;
    },
    closeModalDefaultProcedure() {
      this.modalDefaultProcedure = false;
    },
    goToBulletinList(ev) {
      if (ev.srcElement.tagName !== "SECTION") return false;
      this.$router.push("/bulletin");
    },
    selectAttendanceDate(pAttendanceDate) {
      this.attendanceDate = pAttendanceDate;
    },
  },
};
</script>
