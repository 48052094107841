module.exports = `
ics_id
ics_codigo
ics_descricao
ics_tempo_permanencia
ics_idade_menor
ics_idade_maior
ics_qtd
ics_cbo
ics_telefone
ics_cns
`;
