<template>
  <section class="patient-search">
    <RgSearch
      ref="RgSearch"
      v-model="mutablePatientList"
      :search-function="searchFilter"
      :clear-function="cleanForm"
      :build-filter="generateFilter"
      :item-height="78"
      :max-register="20"
      new-url="/person/patient/search/details?newPatient=true"
      result-title="Pacientes"
    >
      <div slot="filters">
        <div class="search-filter">
          <div class="input">
            <span class="filter-title">Dados Pessoais</span>
          </div>

          <div class="input">
            <RgInput
              v-model="form.pes_nome"
              :disabled="hasDocument"
              :class="{ disable: hasDocument }"
              label="Nome"
              class="data"
              placeholder="Digite o nome do paciente"
            />
          </div>

          <div class="input">
            <RgRadioGenderBlock
              ref="gender"
              v-model="form.genders"
              :disabled="hasDocument"
              :class="{ disable: hasDocument }"
              is-checkbox
              class="data"
              label="Sexo"
            />
          </div>

          <div class="input">
            <RgInputDate
              ref="birthdayDate"
              v-model="form.pes_nascimento"
              :disabled="hasDocument"
              :class="{ disable: hasDocument }"
              label="Nascimento"
              class="data"
              placeholder="dd/mm/aaaa"
            />
          </div>

          <div class="input">
            <RgInput
              v-model="form.pes_mae"
              :disabled="hasDocument"
              :class="{ disable: hasDocument }"
              label="Nome da mãe"
              class="data"
              placeholder="Digite o nome da mãe do paciente"
            />
          </div>

          <div class="input">
            <span class="filter-title">Documentos</span>
          </div>

          <div class="input">
            <RgInputCpf
              v-model="form.cpf"
              :disabled="cpfValidation"
              :class="{ disable: cpfValidation }"
            />
          </div>

          <div class="input">
            <RgInputCns
              v-model="form.crs_numero"
              :disabled="cnsValidation"
              :class="{ disable: cnsValidation }"
              class="data"
            />
          </div>

          <div class="input">
            <RgInputMasked
              ref="number"
              v-model="form.recordNumber"
              :mask="['##########']"
              :disabled="recordNumberValidation"
              :class="{ disable: recordNumberValidation }"
              type="number"
              data-id="prontuario"
              label="Prontuário Único"
              class="record"
              title="Número do Prontuário"
              placeholder="Digite o prontuário único"
            />
          </div>

          <div class="input">
            <RgInputMasked
              ref="number"
              v-model="form.ppr_numero"
              :mask="['###########']"
              :disabled="pprValidation"
              :class="{ disable: pprValidation }"
              type="number"
              data-id="prontuario"
              label="Prontuário da Unidade"
              class="record"
              title="Número da Unidade"
              placeholder="Digite o prontuário da unidade"
            />
          </div>
        </div>
      </div>

      <!-- SEARCH  -->
      <div class="search-result">
        <div
          v-for="(item, index) in mutablePatientList"
          :key="index"
          class="patient-result"
        >
          <div class="row-info-1">
            <span
              >Nome: <strong>{{ item.pes_nome }}</strong></span
            >

            <span
              >Data de Nascimento:
              <strong>{{ birthDateFormat(item) }}</strong>
            </span>

            <span
              >Nome da Mãe: <strong>{{ item.pes_mae }}</strong></span
            >
          </div>

          <div class="row-info-2">
            <span>
              Sexo: <strong>{{ item.gender.sex_nome }}</strong>
            </span>

            <span>
              Prontuário Único:
              <strong>{{ renderPatientRecordNumber(item) }}</strong>
            </span>

            <span>
              Prontuário da Unidade:
              <strong>{{ hasUnitMedicalRecord(item) }}</strong>
            </span>
          </div>

          <div class="row-info-3">
            <button
              v-show="disabledActionsGrid(item)"
              class="btn-generate"
              title="Gerar prontuário único"
              @click="generateRecordNumber(item.pes_id)"
            >
              <span class="span">Gerar Prontuário</span>
            </button>
          </div>
          <div class="row-info-4">
            <RgDropdownPrinter
              :item="item"
              :show-up="toShowUp(index)"
              :disabled="disabledActionsGrid(item)"
              :class="{ disable: disabledActionsGrid(item) }"
              :action-options="itemActionOptionsPrinter(item)"
              title="Impressões"
            />

            <SmallButton
              v-if="!isPrivateSector"
              class="btn-newborn"
              title="Vincular recém-nascido"
              :backgroundColor="'#668bce'"
              @click="registerNewborn(item)"
            >
              <IconBaby slot="icon" class="icon" />
            </SmallButton>

            <RgPleniIntegrationButton
              v-else
              :disabled="disablePleniIntegrationButton(item)"
              :title="setPleniIntegrationTitle(item)"
              :integration-status="setPleniIntegrationStatus(item)"
              @click="handlePleniIntegration(item)"
            />

            <RgEditButton
              class="edit-button"
              title="Editar Paciente"
              @click="editPatient(item)"
            />
            <!--
            <rg-dropdown
              v-show="isPrivateSector"
              :item="item"
              :item-id="item.pes_id"
              :show-up="toShowUp(index)"
              :action-options="itemActionOptions(item)"
              class="dropdown"
            />
            -->
          </div>
        </div>
      </div>
    </RgSearch>

    <ModalHistoryRegister
      :show="openHistoryRegister"
      :data="selectedData"
      @close="closeModalHistoryRegister"
    />
  </section>
</template>

<script>
import moment from "moment";
import { ToShowUp } from "$hospitalization/common/component/util/ToShowUp";

import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";

import RgSearch from "~tokio/foundation/rg-search/RgSearch.vue";
import {
  RgInput,
  RgInputDate,
  IconBaby,
  RgEditButton,
  RgInputMasked,
  RgPleniIntegrationButton,
  SmallButton,
  RgDropdownPrinter,
} from "~tokio/primitive";
import RgRadioGenderBlock from "$person/common/components/radio/rg-radio-gender-block/RgRadioGenderBlock";
import RgInputCns from "$person/common/components/input/rg-input-cns/RgInputCns";
import RgInputCpf from "$person/common/components/input/rg-input-cpf/RgInputCpf";

import { ModalHistoryRegister } from "$person/patient/component";

export default {
  name: "PatientSearch",
  components: {
    RgDropdownPrinter,
    RgSearch,
    RgInput,
    RgInputDate,
    RgRadioGenderBlock,
    RgInputCpf,
    RgInputCns,
    IconBaby,
    ModalHistoryRegister,
    RgEditButton,
    RgInputMasked,
    RgPleniIntegrationButton,
    SmallButton,
  },
  data() {
    return {
      isPrivateSector: false,
      selectedData: {},
      mutablePatientList: [],
      form: {
        pes_nome: "",
        pes_nascimento: "",
        pes_mae: "",
        genders: [],
        cpf: null,
        crs_numero: null,
        ppr_numero: null,
        recordNumber: null,
      },
      // MODAL
      openHistoryRegister: false,
    };
  },

  computed: {
    disableDocuments() {
      return (
        (
          this.form.pes_nome ||
          this.form.pes_nascimento ||
          this.form.pes_mae ||
          this.form.genders
        ).length > 0
      );
    },

    disableOtherInfo() {
      return (
        this.cpf ||
        this.cns ||
        this.unitMedicalRecord ||
        this.singleMedicalRecord
      );
    },

    hasCpf() {
      return this.form.cpf && this.form.cpf.length > 0;
    },

    hasCns() {
      return this.form.crs_numero && this.form.crs_numero.length > 0;
    },

    hasRecordNumber() {
      if (!this.form.recordNumber) return false;
      return this.form.recordNumber !== "" && this.form.recordNumber.length > 0;
    },

    hasPpr() {
      if (!this.form.ppr_numero) return false;
      return this.form.ppr_numero !== "" && this.form.ppr_numero.length > 0;
    },

    pprValidation() {
      return (
        this.hasRecordNumber ||
        this.hasCns ||
        this.hasCpf ||
        this.disableDocuments
      );
    },

    recordNumberValidation() {
      return this.hasPpr || this.hasCns || this.hasCpf || this.disableDocuments;
    },

    cnsValidation() {
      return (
        this.hasPpr ||
        this.hasRecordNumber ||
        this.hasCpf ||
        this.disableDocuments
      );
    },

    cpfValidation() {
      return (
        this.hasPpr ||
        this.hasRecordNumber ||
        this.hasCns ||
        this.disableDocuments
      );
    },

    hasDocument() {
      return this.hasCpf || this.hasCns || this.hasRecordNumber || this.hasPpr;
    },
  },

  mounted() {
    this.isPrivateSector = this.$store.getters["Login/GET_PRIVATE_SECTOR"];
    this.fillFilter();
  },

  destroyed() {
    const existThisRoute = ValidateIfRouteExistInBreadscrumb(
      "/person/patient/search",
      this.$route.meta.breadcrumb,
    );

    if (!existThisRoute) {
      this.$store.commit("Person/Patient/RESET_FILTER_PATIENT");
    }
  },

  methods: {
    searchFilter(pData) {
      return this.$store.dispatch("Person/Patient/GET_PATIENT_LIST", {
        ...pData,
      });
    },

    prepareGender() {
      const hasValue = this.form.genders !== "";

      return hasValue
        ? this.$utils.app.Genders.GetIdByInitials(this.form.genders)
        : null;
    },

    generateFilter() {
      const genders = this.prepareGender();

      const variables = {
        name: this.form.pes_nome
          ? this.$utils.str.RemoveExtraSpaces(this.form.pes_nome)
          : null,
        birthday: this.form.pes_nascimento
          ? this.$utils.date.BrazilianDateToDatabase(this.form.pes_nascimento)
          : null,
        mother: this.form.pes_mae
          ? this.$utils.str.RemoveExtraSpaces(this.form.pes_mae)
          : null,
        cpf: this.form.cpf ? this.form.cpf : null,
        cns: this.form.crs_numero ? this.form.crs_numero : null,
        unitRecordNumber: this.form.ppr_numero ? this.form.ppr_numero : null,
        recordNumber: this.form.recordNumber ? this.form.recordNumber : null,
        gender: genders,
        isPatient: true,
        active: true,
        unitHealthId: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
      };

      this.$store.commit("Person/Patient/SET_FILTER_PATIENT", this.form);

      return variables;
    },

    async fillFilter() {
      try {
        const existFilterData = this.$store.getters[
          "Person/Patient/GET_FILTER_PATIENT"
        ];

        if (existFilterData) {
          this.form.pes_nome = existFilterData.pes_nome;
          this.form.genders = existFilterData.genders;
          this.form.pes_nascimento = existFilterData.pes_nascimento;
          this.form.pes_mae = existFilterData.pes_mae;
          this.form.cpf = existFilterData.cpf;
          this.form.crs_numero = existFilterData.crs_numero;
          this.form.ppr_numero = existFilterData.ppr_numero;
          this.form.recordNumber = existFilterData.recordNumber;

          await this.$refs.RgSearch.performSearch();
        }
      } catch (Err) {
        this.$toaster.warning("Erro ao carregar os dados da navegação.");
      } finally {
        this.$loader.finish();
      }
    },

    async generateRecordNumber(personId) {
      try {
        this.$loader.start("Gerando prontuário único");

        await this.$store.dispatch("Patient/CREATE_NEW_PATIENT", personId);
        this.$refs.RgSearch.submitForm(false, true);

        this.$toaster.success("Sucesso ao gerar o prontuário");
      } catch (error) {
        this.$toaster.error("Erro ao gerar o prontuário");
      } finally {
        this.$loader.finish();
      }
    },

    birthDateFormat(pItem) {
      return moment(pItem.pes_nascimento, "YYYY-MM-DD").format("DD/MM/YYYY");
    },

    disabledActionsGrid(pItem) {
      return !pItem.patient;
    },

    renderPatientRecordNumber(pItem) {
      return pItem.patient && pItem.patient.pac_prontuario_unico
        ? pItem.patient.pac_prontuario_unico
        : "Não informado";
    },

    hasUnitMedicalRecord(pItem) {
      const getUnit = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];

      if (pItem && pItem.patient && pItem.patient.record_numbers) {
        const record = pItem.patient.record_numbers;
        record.rows.forEach((item) => {
          if (item.ppr_id_unidades_saude === getUnit) {
            pItem.patient.record_numbers.rows.ppr_numero = item.ppr_numero;
            return true;
          }
        });
        const result = pItem.patient.record_numbers.rows.ppr_numero;
        return result || "Não informado";
      } else {
        return "Não informado";
      }
    },

    itemActionOptionsPrinter(pItem) {
      return [
        {
          label: "Imprimir carteirinha",
          action: this.printCard,
        },
        {
          label: "Imprimir ficha",
          action: this.printToken,
        },
      ];
    },

    async printCard(data) {
      try {
        const hasPersonId = data.pes_id && data.pes_id > 0;

        if (hasPersonId) {
          const html = await this.$store.dispatch(
            "Person/Patient/PRINT_PATIENT_CARD",
            { pes_id: data.pes_id },
          );

          this.$utils.print.printHtml(html);
        }
      } catch (Err) {
        this.$toaster.error(
          "Erro ao gerar a impressão da carteirinha do paciente",
        );
      }
    },

    async printToken(data) {
      try {
        const hasPersonId = data.pes_id && data.pes_id > 0;
        const unsName = this.$store.getters["Login/GET_UNIT_HEALTH_NAME"];
        const unsId = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
        if (hasPersonId) {
          const html = await this.$store.dispatch(
            "Person/Patient/PRINT_PATIENT_TOKEN",
            { pes_id: data.pes_id, uns_nome: unsName, uns_id: unsId },
          );

          this.$utils.print.printHtml(html);
        }
      } catch (Err) {
        this.$toaster.error("Erro ao gerar a impressão da ficha do paciente");
      }
    },

    toShowUp(pItemIndex) {
      return ToShowUp(pItemIndex, this.mutablePatientList);
    },

    showModalHistoryRegister(pData) {
      this.selectedData = pData;

      this.openHistoryRegister = true;
    },

    closeModalHistoryRegister() {
      this.openHistoryRegister = false;
    },

    cleanForm() {
      this.form = {
        pes_nome: "",
        pes_nascimento: "",
        pes_mae: "",
        genders: [],
        cpf: null,
        crs_numero: null,
        ppr_numero: null,
        recordNumber: null,
      };
      this.$refs.birthdayDate.cleanValidate();
      this.mutablePatientList = [];
      this.$refs.gender.clearRadio();
    },

    editPatient(pPatient) {
      this.$store.commit("Person/Patient/SET_PERSON_ID", pPatient.pes_id);
      this.$router.push({ name: "person.patient.search.editpatient" });
    },

    registerNewborn(pPatient) {
      this.$store.commit("Person/Patient/SET_PERSON_ID", pPatient.pes_id);
      this.$store.commit("Person/Patient/SET_NEWBORN", true);

      this.$router.push({ name: "person.patient.search.registernewborn" });
    },

    checkIntegrationPleni(pData) {
      const data = Object.assign({}, pData);
      const hasNotification =
        data.notification.rows && data.notification.rows.length > 0;
      const hasPleniIntegration = data.pleniIntegration !== null;
      const hasEmail = data.pes_email && data.pes_email.length > 0;

      const hasCpf = data.cpf.cpf_numero
        ? data.cpf.cpf_numero.length > 0
        : false;

      const hasCelular = hasNotification
        ? data.notification.rows.some(
            (item) => item.nop_celular && item.nop_celular.length > 0,
          )
        : false;

      return (
        hasPleniIntegration &&
        hasCpf &&
        hasEmail &&
        hasNotification &&
        hasCelular
      );
    },

    setPleniIntegrationTitle(pItem) {
      const withoutIntegrationTitle = "Paciente não enviado para o Pleni";
      const successTitle = "Paciente enviado para o Pleni";
      const warningTitle = "Problemas no envio para o Pleni. Reenviar";

      const hasPleniIntegration = this.checkIntegrationPleni(pItem);

      if (hasPleniIntegration) {
        return pItem.pleniIntegration.ipn_status ? successTitle : warningTitle;
      }

      return withoutIntegrationTitle;
    },

    setPleniIntegrationStatus(pItem) {
      const status = this.checkIntegrationPleni(pItem)
        ? pItem.pleniIntegration.ipn_status
        : null;

      return status;
    },

    async handlePleniIntegration(pItem) {
      try {
        const personId = pItem.pes_id;
        const withoutIntegration = !this.checkIntegrationPleni(pItem);
        const failedIntegration =
          !withoutIntegration && pItem.pleniIntegration.ipn_status !== 1;

        const hasClickAction = withoutIntegration || failedIntegration;

        if (hasClickAction) {
          if (withoutIntegration) {
            this.openRegisterPatient(personId);
          } else {
            this.$loader.start("Reenviando dados para o Pleni");
            await this.resendPatientToLotus(personId);
            this.$refs.RgSearch.submitForm(false, true);
          }
        }
      } catch (error) {
        this.$toaster.error("Erro ao enviar para o Pleni.");
      } finally {
        this.$loader.finish();
      }
    },

    disablePleniIntegrationButton(pItem) {
      const disableButton = !!(
        pItem.pleniIntegration && pItem.pleniIntegration.ipn_status
      );
      return disableButton;
    },

    async resendPatientToLotus(pPersonId) {
      const response = await this.$store.dispatch(
        "Person/Patient/RESEND_PATIENT_TO_LOTUS",
        { personId: pPersonId },
      );

      const hasSuccess = response && response.success;

      hasSuccess
        ? this.$toaster.success("Sucesso ao enviar para o Pleni!")
        : this.$toaster.warning("Falha ao enviar. Favor verifique o cadastro");
    },

    openRegisterPatient(pPersonId) {
      const hash = "#patient-data";

      this.$store.commit("Person/Patient/SET_PERSON_ID", pPersonId);
      this.$router.push({ name: "person.patient.search.editpatient", hash });
    },

    itemActionOptions(pItem) {
      return [
        {
          label: "Vincular recém-nascido",
          action: this.registerNewborn,
          disable: false,
        },
      ];
    },
  },
};
</script>
