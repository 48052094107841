<style src="./SurgeryObs.scss" lang="scss" scoped></style>
<template lang="html">
  <PrintFrameInfo label="Observações">
    <span class="row-3-lines">{{ surgeryObs.cci_observacao }}</span>
  </PrintFrameInfo>
</template>

<script>
import PrintFrameInfo from "../print-frame-info/PrintFrameInfo";
export default {
  name: "SurgeryObs",
  components: {
    PrintFrameInfo,
  },
  props: {
    surgeryObs: {
      default() {},
    },
  },
};
</script>
