<template>
  <section class="list">
    <SmartTable
      ref="smartTableList"
      name="NotificationOnHomologationList"
      :columns="COLUMNS"
      :body="list"
      :total="totalList"
      :show-pagination="false"
      has-check
      remove-btn-columns
      toggle-selected
      @getLine="getValueRow"
    />
    <div v-show="hasList" class="painel-button">
      <RgLessButton
        title="Excluir"
        :disabled="!hasItemSelected"
        :class="{ 'disable unselect': !hasItemSelected }"
        @click="removeItem"
      />
    </div>
  </section>
</template>
<script>
import moment from "moment";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";
import { RgLessButton } from "~tokio/primitive";

export default {
  name: "NotificationOnHomologationList",
  components: {
    SmartTable,
    RgLessButton,
  },

  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  data() {
    return {
      selectedItem: null,
    };
  },

  computed: {
    totalList() {
      return this.list.length || 0;
    },

    hasList() {
      return this.list.length > 0;
    },

    hasItemSelected() {
      return this.selectedItem > 0;
    },
  },

  created() {
    this.COLUMNS = [
      { name: "Nome", key: "noh_nome" },
      { name: "CPF", key: "noh_cpf" },
      { name: "Celular", key: "noh_celular" },
    ];
  },

  methods: {
    getValueRow(pItem) {
      const data = this.$utils.obj.DeepCopy(pItem);
      const hasValue = data && data.noh_id > 0;

      this.selectedItem = hasValue ? data.noh_id : null;
    },

    removeItem() {
      this.$emit("remove", this.selectedItem);
      this.selectedItem = null;
    },

    formatDate(pDate) {
      return moment(pDate).format("D/M/Y H:m:s");
    },
  },
};
</script>
