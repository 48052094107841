import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation DeleteEmployeeRelationship($relationshipId: Int!) {
    DeleteEmployeeRelationship(employeeRelationshipId: $relationshipId) {
      vin_id
    }
  }
`;

export default async (store, { relationshipId }) => {
  if (!relationshipId) Promise.reject(Error("Não foi possível remover"));
  const variables = {
    relationshipId,
  };

  try {
    const { data } = await GraphQLClient.mutate({ mutation, variables });
    return data.DeleteEmployeeRelationship;
  } catch (err) {
    return Promise.reject(err.graphQLErrors[0]);
  }
};
