import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query query($limit: Int) {
    bloodTypes(limit: $limit) {
      rows {
        tps_id
        tps_nome
        tps_rh
        tps_fator
        tps_grupo
      }
    }
  }
`;

export default async (variables) => {
  const { data } = await GraphQLClient.query({ query, variables }).catch(
    (err) => {
      console.error(err);
    },
  );
  return data.bloodTypes.rows;
};
