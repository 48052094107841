const types = {
  IDENTIDADE_OUTROS_EMISSORES: 28,
  CPF: 31,
  TITULO_ELEITOR: 32,
  CTPS: 33,
  ESTRANGEIRO: 34,
  PIS: 35,
  CERTIDAO_DE_NASCIMENTO: 36,
  CERTIDAO_DE_CASAMENTO: 37,
  CERTIDAO_DE_NASCIMENTO_NOVA: 38,
  CARTAO_SUS: 41,
  CARTEIRA_NACIONAL_DE_HABILITACAO: 42,
  DECLARACAO_DE_NASCIDO_VIVO: 43,
};

export default types;
