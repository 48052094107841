<template lang="html">
  <div v-if="show" class="modal-disabled-periods">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <div class="title">Períodos Inabilitados</div>
      </div>

      <div slot="body" class="body">
        <FormBase withoutTitle>
          <div class="grid">
            <div>
              Unidade de Saúde: <span class="data">{{ form.uns_nome }}</span>
            </div>
            <div>
              Profissional: <span class="data">{{ form.pes_nome }}</span>
            </div>
            <div>
              Setor: <span class="data">{{ form.set_nome }}</span>
            </div>
            <div>
              Ocupação: <span class="data">{{ form.ocp_nome }}</span>
            </div>
          </div>
        </FormBase>

        <FormBase title="Períodos Inabilitados">
          <SmartTable
            ref="smartTable"
            :columns="
              isAppointment ? COLUMN_TABLE_APPOINTMENT : COLUMN_TABLE_EXAM
            "
            :body="mutableList"
            :total="Number(total)"
            :initial-columns="4"
            :show-pagination="false"
            :show-table-menu="false"
            index-column="hag_id"
            class="smart-table"
            toggle-selected
            @getLine="selectLine"
          />
        </FormBase>
      </div>

      <div slot="footer" class="footer">
        <LargeButton
          label="Habilitar"
          separator
          class="button"
          backgroundColor="#30AF58"
          :disabled="!this.selectedLine"
          :class="{ disable: !this.selectedLine }"
          @click="turnOnAct"
        >
          <IconOn slot="icon" />
        </LargeButton>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { RgBaseModal, IconOn, LargeButton } from "~tokio/primitive";
import FormBase from "~tokio/foundation/form-base/FormBase";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";
import moment from "moment";

export default {
  name: "ModalDisabledPeriods",
  components: {
    RgBaseModal,
    LargeButton,
    IconOn,
    FormBase,
    SmartTable,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    isAppointment: {
      type: Boolean,
    },
    professionalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        uns_nome: "",
        set_nome: "",
        ocp_nome: "",
        pes_nome: "",
      },
      total: 0,
      mutableList: [],
      selectedLine: null,
    };
  },

  computed: {},

  watch: {
    show(pValue) {
      if (pValue) {
        this.mountModal();
      }
    },
  },

  created() {
    this.COLUMN_TABLE_EXAM = [
      { name: "Dia", key: "data" },
      { name: "Início", key: "eha_hora_inicio_atendimentos" },
      { name: "Final", key: "eha_hora_final_atendimentos" },
      { name: "Motivo", key: "emi_nome" },
    ];

    this.COLUMN_TABLE_APPOINTMENT = [
      { name: "Dia", key: "dia_completo" },
      { name: "Hora", key: "hag_hora_inicio_consulta_funcionario" },
      { name: "Motivo", key: "hag_observacao" },
      { name: "Escala", key: "cte_nome" },
    ];
  },

  methods: {
    close() {
      this.$emit("close");
      this.form = {
        uns_nome: "",
        set_nome: "",
        ocp_nome: "",
        pes_nome: "",
      };
      this.total = 0;
      this.mutableList = [];
      this.$refs.smartTable.cleanSelectRow();
      this.selectedLine = null;
    },

    async turnOnAct() {
      try {
        const now = moment(new Date()).format("YYYY-MM-DD");

        const datePeriodSchedule = moment(
          this.selectedLine.hag_data_consulta,
          "DD/MM/YYYY",
        ).format("YYYY-MM-DD");

        const datePeriodExam = moment(
          this.selectedLine.eha_data_exame,
          "DD/MM/YYYY",
        ).format("YYYY-MM-DD");

        const validateDate = this.isAppointment
          ? moment(now).isAfter(datePeriodSchedule)
          : moment(now).isAfter(datePeriodExam);

        if (validateDate) {
          this.$toaster.warning(
            "Não é possível habilitar um período retroativo.",
          );
          return false;
        }

        if (
          this.selectedLine &&
          (this.selectedLine.hag_id > 0 || this.selectedLine.eha_id > 0)
        ) {
          this.$loader.start("Carregando...");

          const modules = this.isAppointment ? "Appointment" : "Exam";
          const enable = `${modules}/Schedule/EXCLUDE_DISABLED_PERIODS`;

          const variables = {
            intIdHistoricosAgendamento: this.isAppointment
              ? this.selectedLine.hag_id
              : this.selectedLine.eha_id,
          };

          this.$store.dispatch(enable, variables);
          this.$toaster.success("Período habilitado com sucesso!");
          // this.$emit("reSearch");
          this.close();
        }
      } catch (e) {
        this.$toaster.error(e.message);
      } finally {
        this.$loader.finish();
      }
    },

    selectLine(pLines) {
      this.selectedLine = pLines;
    },

    async mountModal() {
      try {
        const existValues = Object.keys(this.professionalData);
        if (existValues && existValues.length > 0) {
          this.$loader.start("Carregando os períodos inabilitados...");
          const {
            uns_nome,
            set_nome,
            ocp_nome,
            pes_nome,
            pes_id,
            ocp_id,
            set_id,
          } = this.professionalData;

          this.form = {
            uns_nome,
            set_nome,
            ocp_nome,
            pes_nome,
          };

          const variablesExam = {
            arrFormData: {
              limiteFim: 1000000,
              limiteInicio: 0,
              ocp_id,
              pes_id,
              set_id,
            },
          };

          const variablesAppointment = {
            limiteFim: 1000000,
            limiteInicio: 0,
            intIdPessoa: pes_id,
            intIdOcupacao: ocp_id,
            intIdSetor: set_id,
          };

          const variables = this.isAppointment
            ? variablesAppointment
            : variablesExam;

          const modules = this.isAppointment ? "Appointment" : "Exam";
          const path = `${modules}/Schedule/SEARCH_DISABLED_DAYS`;
          const dataValues = await this.$store.dispatch(path, variables);

          if (this.isAppointment) {
            this.mutableList = dataValues;
            this.total = dataValues.length;
          } else {
            this.mutableList = dataValues;
            this.total = dataValues;
          }
        }
      } catch (e) {
        this.$toaster.error(e.message);
      } finally {
        this.$loader.finish();
      }
    },
  },
};
</script>
