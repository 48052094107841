<template lang="html">
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="14"
      cy="14"
      r="13"
      fill="#2B5D8A"
      stroke="#2B5D8A"
      stroke-width="2"
      class="background"
    />
    <path
      d="M16.6164 21.1477C15.9473 21.4118 15.4146 21.6121 15.0151 21.7508C14.6167 21.8894 14.1534 21.9588 13.6262 21.9588C12.8163 21.9588 12.1857 21.7607 11.7366 21.3656C11.2876 20.9705 11.0642 20.4698 11.0642 19.8612C11.0642 19.6246 11.0807 19.3824 11.1137 19.1359C11.1479 18.8894 11.2018 18.6121 11.2755 18.3006L12.113 15.3424C12.1868 15.0585 12.2506 14.7888 12.3012 14.5379C12.3518 14.2848 12.376 14.0526 12.376 13.8413C12.376 13.4649 12.2979 13.2008 12.1427 13.0522C11.9854 12.9036 11.6893 12.831 11.248 12.831C11.0323 12.831 10.81 12.8629 10.5822 12.93C10.3566 12.9994 10.1607 13.0621 10 13.1237L10.2212 12.2125C10.7693 11.9891 11.2942 11.7976 11.795 11.6391C12.2957 11.4784 12.7689 11.3992 13.2146 11.3992C14.0191 11.3992 14.6398 11.5951 15.0767 11.9825C15.5114 12.371 15.7305 12.8761 15.7305 13.4968C15.7305 13.6256 15.715 13.8523 15.6853 14.1758C15.6556 14.5005 15.5995 14.7965 15.5181 15.0684L14.685 18.0178C14.6167 18.2544 14.5562 18.5251 14.5012 18.8278C14.4472 19.1304 14.4208 19.3615 14.4208 19.5167C14.4208 19.9085 14.5078 20.1759 14.6839 20.3179C14.8577 20.4599 15.1626 20.5314 15.594 20.5314C15.7976 20.5314 16.0254 20.4951 16.2829 20.4246C16.5382 20.3542 16.7231 20.2915 16.8398 20.2375L16.6164 21.1477ZM16.4689 9.17613C16.0804 9.5371 15.6127 9.71759 15.0657 9.71759C14.5199 9.71759 14.0488 9.5371 13.6571 9.17613C13.2675 8.81515 13.0705 8.37604 13.0705 7.8632C13.0705 7.35145 13.2686 6.91124 13.6571 6.54696C14.0488 6.18159 14.5199 6 15.0657 6C15.6127 6 16.0815 6.18159 16.4689 6.54696C16.8574 6.91124 17.0522 7.35145 17.0522 7.8632C17.0522 8.37714 16.8574 8.81515 16.4689 9.17613Z"
      fill="#FFFFFF"
      class="letter"
    />
  </svg>
</template>

<script>
export default {
  name: "InfoHelper",
};
</script>
