<template lang="html">
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 612.001 612.001"
    style="enable-background: new 0 0 612.001 612.001"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M604.294,244.001l-72.377-49.537V67.698c0-9.778-7.928-17.705-17.705-17.705H427.98c-9.779,0-17.705,7.928-17.705,17.705
      v43.507c0,0-92.934-63.674-94.151-64.523c-5.863-4.086-13.877-4.277-20.122,0L7.707,244.001
      c-8.069,5.523-10.133,16.541-4.611,24.61c3.43,5.012,8.98,7.708,14.628,7.708c3.445,0,6.927-1.003,9.984-3.097l52.377-35.849
      V550.75c0,9.778,7.928,17.705,17.705,17.705h416.42c9.778,0,17.705-7.928,17.705-17.705V237.372l52.377,35.849
      c3.059,2.094,6.539,3.097,9.984,3.097c5.647,0,11.197-2.696,14.628-7.708C614.428,260.543,612.363,249.524,604.294,244.001z
      M342.752,533.045h-73.498v-172.16h73.498V533.045z M496.507,533.045H378.162V343.179c0-9.778-7.928-17.705-17.705-17.705H251.548
      c-9.778,0-17.705,7.928-17.705,17.705v189.865H115.497V213.136L306.003,82.748l190.506,130.39v319.907H496.507z M496.507,170.227
      l-50.822-34.785V85.404h50.822V170.227z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "Home",
};
</script>
