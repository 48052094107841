<style src="./RgForm.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="rg-form">
    <form @submit.prevent>
      <slot />
      <slot name="command">
        <section class="rg-form--commands align">
          <slot name="aftercommand" />
          <button
            v-if="hasClean"
            type="button"
            class="btn btn-clean btn-from-ab"
            :class="{ action: !canClean }"
            :disabled="!canClean"
            @click="cleanOperation"
          >
            Limpar
          </button>
          <button
            v-if="hasCancel"
            type="button"
            class="btn btn-clean"
            :disabled="!canCancel"
            @click="cancelOperation"
          >
            Cancelar
          </button>
          <RgSubmit
            v-if="showSubmit"
            ref="btnSubmit"
            :label="actionLabel"
            :wait-label="actionLabelWait"
            :done-label="actionLabelDone"
            :disabled="!canSubmit"
            @submit="doAction"
            @complete="actionCompleted"
          />
          <hr />
          <button
            v-show="showNextButton"
            class="align btn btn-primary btn-small"
            @click="nextButtonProgress"
          >
            Próximo
          </button>
        </section>
      </slot>
    </form>
  </section>
</template>

<script>
import { RgSubmit, RgValidatorForm } from "../../primitive";
export default {
  name: "RgForm",
  components: {
    RgSubmit,
  },
  extends: { ...RgValidatorForm },
  props: {
    showNextButton: {
      type: Boolean,
      default: false,
    },
    hasCancel: {
      type: Boolean,
      default: false,
    },
    hasClean: {
      type: Boolean,
      default: false,
    },
    showSubmit: {
      type: Boolean,
      default: true,
    },
    actionLabel: {
      type: String,
      default: "Salvar",
    },
    actionLabelWait: {
      type: String,
      default: "Salvando",
    },
    actionLabelDone: {
      type: String,
      default: "Finalizado!",
    },
    actionExternalDisabledStatus: {
      type: Boolean,
      default: false,
    },
    cancelExternalDisabledStatus: {
      type: Boolean,
      default: false,
    },
    cleanExternalDisabledStatus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cancelDisabledStatus: false,
      cleanDisabledStatus: false,
      actionDisabledStatus: false,
    };
  },
  computed: {
    canSubmit() {
      return !this.actionDisabledStatus && !this.actionExternalDisabledStatus;
    },
    canCancel() {
      return !this.cancelDisabledStatus && !this.cancelExternalDisabledStatus;
    },
    canClean() {
      return !this.cleanDisabledStatus && !this.cleanExternalDisabledStatus;
    },
  },
  methods: {
    async doAction() {
      const result = await this.validate();
      if (result) {
        this.cancelDisabledStatus = true;
        this.cleanDisabledStatus = true;
        this.$emit("submit", null, this.requestAction);
      } else {
        this.$emit("submit", this.getError(), null);
        this.$refs.btnSubmit.fail();
      }
    },
    cancelOperation() {
      this.$emit("cancel");
    },
    cleanOperation() {
      this.$emit("clean");
    },
    requestAction() {
      this.actionCompleted();
    },
    actionCompleted() {
      this.cancelDisabledStatus = false;
      this.cleanDisabledStatus = false;
      this.$refs.btnSubmit.actionNormalState();
    },
    nextButtonProgress() {
      this.$emit("nextClicked", true);
    },
  },
};
</script>
