<style src="./SurgeryScheduling.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="surgery-scheduling">
    <BaseSurgeryView>
      <section class="right-content">
        <ModuleBox title="Calendário" class="scheduling-calendar">
          <div class="toggle-list">
            <div class="toggle-button" @click="toggleExpandSchedule">
              <IconDownArrow
                :class="{ 'rotate-arrow': toggleExpandScheduleState }"
                class="symbol"
              />
            </div>
          </div>
          <SchedulingCalendar :class="scheduleCompressdStyle" />
        </ModuleBox>
        <ModuleBox
          id="scheduling-surgery"
          :granted="hasSurgeryPermission"
          class="scheduling-surgery"
          title="Agendamento Cirúrgico"
        >
          <div slot="title">
            <RgButtonPrintSurgery />
          </div>
          <SchedulingSurgeryForm />
        </ModuleBox>
      </section>
    </BaseSurgeryView>
  </section>
</template>

<script>
import ModuleBox from "~tokio/foundation/modulebox/Modulebox";
import SchedulingCalendar from "$surgery-center/surgery/component/scheduling-calendar/SchedulingCalendar";
import { IconDownArrow } from "~tokio/primitive";
import SchedulingSurgeryForm from "$surgery-center/surgery/component/scheduling-surgery-form/SchedulingSurgeryForm";
import BaseSurgeryView from "$surgery-center/surgery/view/BaseSurgeryView";
import RgButtonPrintSurgery from "$surgery-center/surgery/component/button/rg-button-print-surgery/RgButtonPrintSurgery";
export default {
  name: "SurgeryScheduling",
  components: {
    ModuleBox,
    SchedulingCalendar,
    SchedulingSurgeryForm,
    IconDownArrow,
    BaseSurgeryView,
    RgButtonPrintSurgery,
  },
  data() {
    return {
      toggleExpandScheduleState: false,
    };
  },
  computed: {
    scheduleCompressdStyle() {
      const expandedStyle = !this.toggleExpandScheduleState
        ? "scheduling-calendar--compressed"
        : "scheduling-calendar--expanded";
      return expandedStyle;
    },
    hasPermissionList() {
      return this.$Permissions.global.has("centro_cirurgico.cirurgia.exibir");
    },
    hasSurgeryPermission() {
      const ITEM_SELECTED = this.$store.getters[
        "SurgeryCenter/Surgery/getSelectedSurgeryStatus"
      ];
      if (ITEM_SELECTED === 2) {
        return this.$Permissions.global.has(
          "centro_cirurgico.cirurgia.alterarAgendamento",
        );
      } else {
        return this.$Permissions.global.has(
          "centro_cirurgico.cirurgia.cadastrarAgendamento",
        );
      }
    },
  },
  mounted() {
    this.$store.dispatch("SurgeryCenter/Surgery/fillFilter", {
      filterKey: "cci_id_status_controles_cirurgicos",
      filterValue: [1, 2],
    });
    this.$store.dispatch("SurgeryCenter/Surgery/filter");
  },
  methods: {
    toggleExpandSchedule() {
      this.toggleExpandScheduleState = !this.toggleExpandScheduleState;
    },
  },
};
</script>
