<style src="./DropdownButton.scss" lang="scss" scoped></style>
<template>
  <div
    :disabled="disabled"
    :class="{ disable: disabled, large, 'disabled-div': disabled }"
    class="dropdown-button"
    :style="{ 'background-color': backgroundColor }"
  >
    <div class="label">
      {{ label }}
    </div>
    <div v-show="separator" class="separator" />
    <div class="iconarea">
      <slot name="icon" class="icon" />
    </div>
    <div
      :class="{ 'show-up': showUp, 'show-left': showLeft, fixed }"
      class="dropdown"
    >
      <span
        v-for="option in actionOptions"
        :key="generateIdentifier(option)"
        :data-id="generateIdentifier(option)"
        :class="{ 'disable unselect': option.disable, fixed }"
        :disabled="option.disable"
        class="item"
        @click="handleClick(item, option)"
      >
        {{ option.label }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "DropdownButton",
  props: {
    /**
     * @type {{label: string, action: Function}[]} actionOptions
     */
    actionOptions: {
      type: Array,
      default: () => [],
      required: true,
    },
    item: {
      type: Object,
      default: () => {},
    },
    itemId: {
      type: Number,
      default: 0,
    },
    dataId: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showUp: {
      type: Boolean,
      default: false,
    },
    showLeft: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    fixed: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    separator: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      statusWaiting: false,
    };
  },
  methods: {
    generateIdentifier(pOption) {
      return !this.itemId || this.itemId === 0
        ? pOption.label
        : `${pOption.label}-${this.itemId}`;
    },
    /**
     * @param {any} pItem
     * @param {{label: string, action: Function}[]} pOption
     */
    handleClick(pItem, pOption) {
      this.$emit(`on-${pOption.label}`, pItem);
      pOption.action(pItem);
    },
    setWaiting() {
      this.statusWaiting = true;
    },
    setStartOrFinish() {
      this.statusWaiting = false;
    },
  },
};
</script>
