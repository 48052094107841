import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query ruralProduction {
    SearchRuralProduction(limit: 100) {
      rows {
        pru_id
        pru_codigo_ab
        pru_nome
      }
      count
    }
  }
`;

export default async ({ commit, state }) => {
  if (state.rural_production.length > 0) {
    return;
  }
  try {
    const { data } = await GraphQLClient.query({
      query,
      fetchPolicy: "no-cache",
    });
    commit("SET_RURAL_PRODUCTION", data.SearchRuralProduction.rows);
  } catch (err) {
    console.log(err);
    return err.graphQLErrors;
  }
};
