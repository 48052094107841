<style src="./RgSuggestCity.scss" lang="scss" scoped></style>
<template>
  <div class="rg-suggest-city">
    <RgSuggest
      ref="rgSuggest"
      v-model="inputValue"
      :disabled="disabled"
      :data-id="dataId"
      :label="label"
      :placeholder="placeholder"
      :tab-index="tabIndex"
      :rules="rules"
      :search-on-focus="searchOnFocus"
      min="2"
    >
      <RgSuggestItem
        v-for="(item, idx) in suggestionList"
        :key="idx"
        :data-item="dataId"
        :idx="idx"
        :item="item"
        class="city-item"
      >
        <div class="city-row-top">
          <span class="mun-name">{{ item.mun_nome }}</span>
          <span class="mun-state"
            >{{ item.state.est_nome }} - {{ item.state.est_sigla }}</span
          >
        </div>
      </RgSuggestItem>
    </RgSuggest>
  </div>
</template>

<script>
import { RgSuggest, RgSuggestMixin, RgSuggestItem } from "~tokio/primitive";
import { SearchCity } from "./action";
import { isNumber } from "lodash";

export default {
  name: "RgSuggestCity",
  components: {
    RgSuggest,
    RgSuggestItem,
  },
  mixins: [RgSuggestMixin],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    state: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      captionRaw: "mun_nome",
    };
  },
  computed: {
    searchOnFocus() {
      return this.inputValue === "" || this.inputValue === null;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadCity(this.inputValue);
    });
  },
  methods: {
    doSearch(pSearchTerm, pLimit) {
      if (this.state) {
        return SearchCity({
          mun_id_estados: this.state,
          munNome: pSearchTerm,
          limit: pLimit,
        });
      } else {
        return isNumber(pSearchTerm)
          ? SearchCity({ munId: pSearchTerm })
          : SearchCity({ munNome: pSearchTerm });
      }
    },

    async loadCity(pValue) {
      if (pValue < 1) return;

      if (pValue !== this.inputValue) this.inputValue = pValue;

      const result = await this.doSearch(pValue);
      if (result && Array.isArray(result) && result.length > 0) {
        if (result.length === 1) this.selectingItemFromSuggestList(result[0]);
        else {
          const selectedItem = result.find((item) => item.mun_id === pValue);
          this.selectingItemFromSuggestList(selectedItem);
        }
      }
    },
  },
};
</script>
