<template>
  <aside class="modal-history-register">
    <RgBaseModal
      v-shortkey="['esc']"
      :show="show"
      class="rg-alert-base-modal"
      with-close-button
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="modal-history-register-header">
        <RgContentBreadcrumb
          :breadcrumb="breadcrumbOptions()"
          @step="controllerSteps"
        />
      </div>

      <div slot="body" class="modal-history-register-body">
        <HistoryRegisterContainer
          v-if="historyRegister"
          ref="historyRegisterContainer"
          @getLine="changeContainer"
        />

        <DetailsAlterationContainer v-else />

        <div class="action">
          <button
            v-if="historyRegister"
            class="btn-change"
            @click="changeContainer"
          >
            Próximo
          </button>

          <button
            v-if="!historyRegister"
            class="btn-change"
            @click="changeContainer"
          >
            Voltar
          </button>
        </div>
      </div>
    </RgBaseModal>
  </aside>
</template>

<script>
import { RgBaseModal } from "~tokio/primitive";

import HistoryRegisterContainer from "../../container/history-register-container/HistoryRegisterContainer";
import DetailsAlterationContainer from "../../container/details-alteration-container/DetailsAlterationContainer";
import RgContentBreadcrumb from "~tokio/foundation/container/rg-content-breadcrumb/RgContentBreadcrumb";

export default {
  name: "ModalHistoryRegister",
  components: {
    RgContentBreadcrumb,
    RgBaseModal,
    HistoryRegisterContainer,
    DetailsAlterationContainer,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      historyRegister: true,
      id: null,
    };
  },
  watch: {
    data(pValue) {},
    show(pValue) {
      const hasData = pValue && this.data.pes_id;

      if (hasData) {
        const pesId = this.data.pes_id;
        this.$store.commit("Person/Patient/SET_PERSON_ID", pesId);
        this.$refs.historyRegisterContainer.loadData();
      }
    },
  },
  methods: {
    changeContainer() {
      this.historyRegister = !this.historyRegister;
    },
    breadcrumbOptions() {
      return [
        {
          name: "Pesquisa Por Paciente",
          step: false,
          disabled: true,
        },
        {
          name: "Histórico do Cadastro",
          step: this.historyRegister,
        },
        {
          name: "Visualizar Paciente",
          step: !this.historyRegister,
        },
      ];
    },
    controllerSteps(pValue, pIndex) {
      if (pIndex === 1) {
        this.historyRegister = true;
      }
      if (pIndex === 2) {
        this.historyRegister = false;
      }
    },
    close() {
      this.historyRegister = true;
      this.$emit("close");
    },
  },
};
</script>
