import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query {
    hospitalizationInstructionLevel {
      rows {
        igi_id
        igi_descricao
        igi_codigo
      }
    }
  }
`;

export default async (variables) => {
  const { data } = await GraphQLClient.query({ query, variables }).catch(
    (err) => {
      console.error(err);
    },
  );
  return data.hospitalizationInstructionLevel.rows;
};
