<style src="./RgComboboxEmployeeFunction.scss" lang="scss"></style>
<script>
import { isNumber } from "lodash";
import { RgRemoteComboboxMixin } from "~tokio/primitive";
import SurgeryEmployeeFunctionModel from "$surgery-center/surgery/model/SurgeryEmployeeFunctionModel";
export default {
  name: "RgComboboxEmployeeFunction",
  mixins: [RgRemoteComboboxMixin],
  props: {
    emptyDefaultText: {
      default: "Selecione um centro cirúrgico",
    },
    sector: {
      required: true,
      default: false,
    },
  },
  data: () => {
    return {
      model: SurgeryEmployeeFunctionModel,
    };
  },
  computed: {
    filter() {
      if (!isNumber(this.sector)) {
        this.resetCombobox();
        return null;
      }
      const filterStatus = this.sector ? { set_id: this.sector } : false;
      return filterStatus;
    },
  },
  methods: {
    formatItem(pData) {
      return {
        value: pData.fnc_id,
        text: pData.fnc_nome,
      };
    },
  },
};
</script>
