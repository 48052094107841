<script>
import { RgRemoteComboboxMixin } from "~tokio/primitive";
import ListUnitHealthLegacy from "./action/ListUnitHealthLegacy";

export default {
  name: "RgComboboxUnithealthLegacy",
  mixins: [RgRemoteComboboxMixin],
  props: {
    label: {
      type: String,
      default: "Unidade de Saúde",
    },
    defaultText: {
      type: String,
      default: "Selecione",
    },
    emptyDefaultText: {
      type: String,
      default: "Nenhum Item para Selecionar",
    },
    subModuleId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  methods: {
    doSearch() {
      return ListUnitHealthLegacy({ intIdSubModulo: this.subModuleId });
    },
    formatItem(item) {
      return {
        value: item.data,
        text: `${item.uns_nome}`,
      };
    },
    blur() {
      this.validate();
      this.$emit("blur");
    },
  },
};
</script>
