export default {
  name: "RgSuggestMixin",
  props: {
    label: {
      default: "Sugestões",
    },
    placeholder: {
      default: "Digite a sugestão",
    },
    value: {
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      default: null,
    },
    dataId: {
      type: String,
      default: "",
    },
    tabIndex: {
      type: String,
      default: "0",
    },
    confirmToRemove: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: "Deseja mesmo limpar esse campo ?",
    },
  },
  mounted() {
    // #BALBI coloquei aqui para carregar o dado vindo de fora do componente para dentro, ex: tela edição
    this.inputValue = this.value;
  },
  data() {
    return {
      selectedItemIdx: 0,
      total: null,
      exhibitionText: "",
      inputValue: "",
      suggestionList: [],
    };
  },
  computed: {
    showSearch() {
      const shouldShowSearch =
        this.suggestionList && this.suggestionList.length > 0;
      return shouldShowSearch;
    },
  },
  watch: {
    inputValue(pValue) {
      this.$emit("input", pValue);
    },
    value(pValue) {
      this.inputValue = pValue;
    },
  },
  methods: {
    cleanValues() {
      this.selectedItemIdx = 0;
      this.total = null;
      this.exhibitionText = "";
      this.inputValue = "";
      this.suggestionList = [];
      this.$emit("input", this.inputValue);
    },

    search(pSearch, pLimit) {
      this.selectedItemIdx = 0;
      const response = this.doSearch(pSearch, pLimit);
      if (!response) this.suggestionList = [];
      else {
        response
          .then((res) => {
            this.suggestionList = res.rows || res;
            if (!pLimit && res.count) {
              this.total = res.count;
            }
          })
          .catch((pErr) => {
            this.suggestionList = [];
            throw pErr;
          });
      }
    },

    getTotal() {
      return this.total;
    },

    createOutput(pItem) {
      const emitOutput = {
        textRaw: this.inputValue,
        source: pItem,
      };
      return emitOutput;
    },
    formatingItem(pItem) {
      const hasTwoData = this.captionRaw.indexOf(" - ") > 0;

      if (hasTwoData) {
        // SHOW TWO DATA IN INPUT
        const splitedCaptionRaw = this.captionRaw.split(" - ");
        this.inputValue = `${pItem[splitedCaptionRaw[0]]} - ${
          pItem[splitedCaptionRaw[1]]
        }`;
      } else {
        // SHOW ONE DATA ON INPUT. HAS POSSIBILITY TO BE OBJECT
        const objectParams = this.captionRaw.split(".");

        const isDate = /^[0-9]{2,4}[/-][0-9]{2}[/-][0-9]{2,4}$/.test(
          this.inputValue,
        );
        if (isDate && typeof this.inputValue === "string") {
          this.inputValue = this.$utils.date.BrazilianDateFormat(
            pItem[this.captionRaw],
          );
        } else if (objectParams.length === 1) {
          this.inputValue = pItem[objectParams[0]];
        } else if (objectParams.length === 2) {
          this.inputValue = pItem[objectParams[0]][objectParams[1]];
        } else if (objectParams.length === 3) {
          this.inputValue =
            pItem[objectParams[0]][objectParams[1]][objectParams[2]];
        } else {
          this.inputValue = pItem[this.captionRaw];
        }
      }
    },
    selectingItemFromSuggestList(pItem) {
      this.formatingItem(pItem);
      const outputFormated = this.createOutput(pItem);
      this.suggestionList = [];
      this.emitSelection(outputFormated);
    },
    setFocus() {
      this.$refs.rgSuggest.setFocus();
    },
    emitSelection(outputFormated) {
      this.$emit("selected", outputFormated);
    },
    loadComparator(pValue, pResult) {
      throw new Error(
        "loadComparator não implementado. Implemente `loadComparator (pValue, pResult) { ... }` em seu componente filho.",
      );
    },

    async loadValue(pValue) {
      if (!pValue) return;

      if (pValue !== this.inputValue) this.inputValue = pValue;

      const result = await this.doSearch();
      if (result && Array.isArray(result) && result.length > 0) {
        if (result.length === 1) this.forceSelection(result[0]);
        else {
          const selectedItem = this.loadComparator(pValue, result);
          this.forceSelection(selectedItem);
        }
      }
    },

    boxHeight() {
      return 58 * (this.suggestionList.length - 1);
    },

    forceSelection(pValue) {
      if (pValue && this.$refs.rgSuggest) {
        this.$refs.rgSuggest.itemSelected = true;
        if (
          !this.suggestionList ||
          (this.suggestionList && this.suggestionList.length === 0)
        ) {
          this.suggestionList.push(pValue);
        }
        this.$refs.rgSuggest.$parent.selectingItemFromSuggestList(pValue);
      }
    },
    validate() {
      if (this.$refs.rgSuggest) this.$refs.rgSuggest.validate();
    },
    cleanValidate() {
      if (this.$refs.rgSuggest) this.$refs.rgSuggest.cleanValidate();
    },
  },
};
