import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  /*
    PAYLOAD DO METODO

    [fil_id_agendado_por] => 407
    [fil_id_pessoas] => 56243
    [fle_nome_procedimento] => ABCIXIMABE
    [fil_id_prioridades_fila] => 316
    [stp_subProcedimentos] => Array
        (
            [0] => ABERTURA DE COMUNICACAO INTER-ATRIAL
            [1] => ABORDAGEM COGNITIVA COMPORTAMENTAL DO FUMANTE (POR ATENDIMENTO / PACIENTE)
            [2] => ACAO COLETIVA DE BOCHECHO FLUORADO
        )

    [fil_id_unidades_saude] => 2
    [fil_id_funcionarios] => 447
    [fio_observacao] =>
    [fil_conselho_classe] => 70
    [fil_id_unidades_saude_solicitante] => 2
    [fle_quantidade] => 1
    [fil_data_pedido] => 18/01/2021
    [fle_id_sinonimos_procedimentos] => 146
  */
  const sendData = {
    arrFormData: variables,
  };
  return AuthLegacyRequest.post(
    "exames2/controller-fila-exame/alterar",
    sendData,
  )
    .then(({ data }) => {
      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
