import LocalStorage from "~common/local-storage/LocalStorage";

const ScheduleOutpatientConsultationControl = (pEmployeeData, pData) => {
  const logo = LocalStorage.getObject("client-logo");
  const texto = "FICHA DE CONTROLE AMBULATORIAL";

  let dadosTabela = "";
  let number = 1;
  let unithealth = "";

  if (pEmployeeData.uns_nome) {
    unithealth += `<div><label>Unidade de Saúde: </label><span>${pEmployeeData.uns_nome}</span> </div>`;
  } else {
    unithealth += `<div><label>Unidade de Saúde: </label><span>${pEmployeeData.unithealth}</span> </div>`;
  }

  pData.forEach((item) => {
    "<tr>";
    dadosTabela += "<td>" + number++ + "</td>";
    dadosTabela += "<td>" + item.con_hora + "</td>";
    dadosTabela += "<td>" + item.pes_nome + "</td>";
    dadosTabela += "<td>" + item.ppr_numero + "</td>";
    dadosTabela += "<td>" + item.pac_prontuario_unico + "</td>";
    dadosTabela += "<td>" + item.idade + "</td>";
    dadosTabela += "<td>" + item.sex_sigla + "</td>";
    dadosTabela += "<td>" + "" + "</td>";
    dadosTabela += "<td>" + "" + "</td>";
    dadosTabela += "<td>" + "" + "</td></tr>";
  });

  const html = `
  <!DOCTYPE html>
  <html>
  <head>
    <meta charset="utf-8">
    <title>Esus</title>
    <style type="text/css">
      body {
        font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
        /*background: rgb(204,204,204);*/
      }

      h1 {
        font-size: 12pt;
        text-transform: uppercase;
        text-align: center;
      }

      h2 {
        font-size: 11pt;
        font-style: italic;
        text-transform: uppercase;
      }

      h3 {
        font-size: 10pt;
        text-align: center;
        text-transform: uppercase;
      }

      hr {
        margin: 30px 0;
      }

      label,
      span,
      p {
        font-size: 10pt;
      }

      label {
        font-weight: 700;
      }

      table {
        width: 100%;
        font-size: 11px;
        font-weight: none;
        top: 10%;
      }

      tr {
        border: 1px solid grey;
      }

      th {
        border: 1px solid grey;
      }

      td {
        border: 1px solid grey;
      }

      page {
        background: white;
        display: block;
        margin: 0 auto;
        margin-bottom: 0.5cm;
        /*box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);*/
      }

      page[size="A4"] {
        width: 21cm;
        height: 29.7cm;
      }

      .logo-cliente {
        width: 70px;
        display: block;
        margin: 0 90%;
        padding-top: 0.5cm;
      }

      .info-fila div {
        margin-bottom: 6px;
      }

      .info-fila-observacao {
        border-top: 1px solid #888;
        padding: 5pt 0 0 0;
      }

      .ocultar {
        display: none;
      }

      .mostrar {
        display: block;
      }
    </style>
  </head>

  <body>
    <page size="A4">
      <div>
        <h1>${pEmployeeData.client}</h1>
        <img class="logo-cliente" src="${logo}">
      </div>

      <div class="info-fila">
        <h3>${texto}</h3>
        ${unithealth}
        <div><label>Data: </label> <span>${
          pEmployeeData.data_consulta
            ? pEmployeeData.data_consulta
            : pEmployeeData.DataConsulta
        }</span> </div>
        <div><label>Profissional: </label> <span>${
          pEmployeeData.pes_nome
            ? pEmployeeData.pes_nome
            : pEmployeeData.fun_nome
        }</span> </div>
      </div>

      <table class="table">
        <tr class="header">
          <th> Nº </th>
          <th> Hora </th>
          <th> Paciente </th>
          <th> Prontuário </th>
          <th> Pront.Único </th>
          <th> Idade </th>
          <th> Sexo </th>
          <th> Diagnóstico </th>
          <th> 1ª Cons.? </th>
          <th> Ex.Pedido </th>
        </tr>

        <div class="table">
          ${dadosTabela}
        <div>
      </table>
    </page>
  </body>

  </html>
    `;
  return html;
};

export default ScheduleOutpatientConsultationControl;
