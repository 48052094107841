<style src="./RgPaginate.scss" lang="scss" scoped></style>
<template lang="html">
  <div class="rg-paginate">
    <span class="monitor"
      ><b>{{ pageMonitor + 1 }}</b> - <b>{{ pageMonitorTo }}</b> de
      <b>{{ pageCount }}</b></span
    >
    <button
      :disabled="pageNow <= 0"
      type="button"
      class="btn-paginate"
      @click="backwardPage"
    >
      <IconLeftArrow />
    </button>
    <button
      :disabled="pageMonitor + perPage >= pageCount"
      type="button"
      class="btn-paginate"
      @click="forwardPage"
    >
      <IconRightArrow />
    </button>
  </div>
</template>

<script>
import { IconLeftArrow, IconRightArrow } from "~tokio/primitive";
export default {
  name: "RgPaginate",
  components: {
    IconLeftArrow,
    IconRightArrow,
  },
  props: {
    pageNow: {
      default: null,
    },
    perPage: {
      default: null,
    },
    pageCount: {
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {
    pageMonitor() {
      return this.pageNow * this.perPage;
    },
    pageMonitorTo() {
      const pageTo = this.pageMonitor + this.perPage;
      return pageTo > this.pageCount ? this.pageCount : pageTo;
    },
  },
  methods: {
    forwardPage() {
      this.$emit("forward-page");
    },
    backwardPage() {
      this.$emit("backward-page");
    },
  },
};
</script>
