import BillingMainHeader from "$billing/common/component/main-header-billing/MainHeaderBilling";
import Bulletin from "$billing/bulletin/views/Bulletin";
import Billing from "$billing/billing/views/Billing";
import DefinePeriod from "$billing/define-period/views/DefinePeriod";
import DefineHospitalizationPeriod from "$billing/define-hospitalization-period/views/DefinePeriod";
import ExportBilling from "$billing/export-billing/views/ExportBilling";
import ExportHospitalizationBilling from "$billing/export-hospitalization-billing/views/ExportHospitalizationBilling";
import Report from "$billing/report/views/Report";
import BillingModal from "$billing/common/component/billing-modal/BillingModal";
import ConsolidatedBilling from "$billing/consolidated-billing/views/ConsolidatedBilling";
import HospitalizationSearch from "$billing/hospitalization-billing/views/hospitalization-billing-search/HospitalizationBillingSearch";
import HospitalizationDetail from "$billing/hospitalization-billing/views/hospitalization-billing-detail/HospitalizationBillingDetail";
import UnbilliedExits from "$billing/hospitalization-billing/views/report/unbillied-exits/UnbilliedExits";
import MainProcedure from "$billing/hospitalization-billing/views/report/main-procedure/MainProcedure";
import SecondaryProcedure from "$billing/hospitalization-billing/views/report/secondary-procedure/SecondaryProcedure";
import MainDiagnosis from "$billing/hospitalization-billing/views/report/main-diagnosis/MainDiagnosis";
import Homonyms from "$billing/hospitalization-billing/views/report/homonyms/Homonyms";
import AihPerPatient from "$billing/hospitalization-billing/views/report/aih-per-patient/AihPerPatient";
import AihPerSpecialty from "$billing/hospitalization-billing/views/report/aih-per-specialty/AihPerSpecialty";
import PreviewValues from "$billing/hospitalization-billing/views/report/preview-values/PreviewValues";
import EmergencyReport from "$billing/hospitalization-billing/views/report/daily/emergency-report/EmergencyReport";
import InternalizationReport from "$billing/hospitalization-billing/views/report/daily/internalization-report/InternalizationReport";

// REPORT
import BillingReportRoute from "./report/BillingReportRoute";

export const BillingRoute = {
  path: "/billing",
  redirect: {
    name: "billing.bulletin",
  },
};

export const BillingBulletin = {
  path: "/billing/bulletin",
  name: "billing.bulletin",
  components: {
    default: Bulletin,
    header: BillingMainHeader,
    rootModal: BillingModal,
  },
  meta: {
    breadcrumb: [{ label: "Boletins", link: "/billing/bulletin" }],
  },
};

export const HospitalizationBillingSearch = {
  path: "/billing/hospitalization-billing/search",
  name: "hospitalization.billing.search",
  components: {
    default: HospitalizationSearch,
    header: BillingMainHeader,
    rootModal: BillingModal,
  },
  meta: {
    breadcrumb: [
      { label: "Internação AIH", link: "/billing/hospitalization-billing" },
    ],
  },
};

export const HospitalizationBillingDetail = {
  path: "/billing/hospitalization-billing/detail",
  name: "hospitalization.billing.detail",
  components: {
    default: HospitalizationDetail,
    header: BillingMainHeader,
    rootModal: BillingModal,
  },
  meta: {
    breadcrumb: [
      {
        label: "Busca de Internação AIH",
        link: "/billing/hospitalization-billing/search",
      },
      { label: "Nova AIH", link: "/billing/hospitalization-billing" },
    ],
  },
};

export const UnbilliedExitsReport = {
  path: "/billing/hospitalization-billing/report/unbillied-exits",
  name: "hospitalization.billing.report.unbillied-exits",
  components: {
    default: UnbilliedExits,
    header: BillingMainHeader,
    rootModal: BillingModal,
  },
};

export const BillingEmergencyReport = {
  path: "/billing/hospitalization-billing/report/EmergencyReport",
  name: "hospitalization.billing.report.EmergencyReport",
  components: {
    default: EmergencyReport,
    header: BillingMainHeader,
    rootModal: BillingModal,
  },
};

export const BillingInternalizationReport = {
  path: "/billing/hospitalization-billing/report/InternalizationReport",
  name: "hospitalization.billing.report.InternalizationReport",
  components: {
    default: InternalizationReport,
    header: BillingMainHeader,
    rootModal: BillingModal,
  },
};

export const MainProcedureReport = {
  path: "/billing/hospitalization-billing/report/main-procedure",
  name: "hospitalization.billing.report.main-procedure",
  components: {
    default: MainProcedure,
    header: BillingMainHeader,
    rootModal: BillingModal,
  },
  meta: {
    breadcrumb: [
      { label: "Relatórios", link: "/billing/hospitalization-billing/report" },
    ],
  },
};

export const SecondaryProcedureReport = {
  path: "/billing/hospitalization-billing/report/secondary-procedure",
  name: "hospitalization.billing.report.secondary-procedure",
  components: {
    default: SecondaryProcedure,
    header: BillingMainHeader,
    rootModal: BillingModal,
  },
  meta: {
    breadcrumb: [
      {
        label: "Relatórios AIH",
        link: "/billing/hospitalization-billing/report",
      },
    ],
  },
};

export const MainDiagnosisReport = {
  path: "/billing/hospitalization-billing/report/main-diagnosis",
  name: "hospitalization.billing.report.main-diagnosis",
  components: {
    default: MainDiagnosis,
    header: BillingMainHeader,
    rootModal: BillingModal,
  },
  meta: {
    breadcrumb: [
      { label: "Relatórios", link: "/billing/hospitalization-billing/report" },
    ],
  },
};

export const HomonymsReport = {
  path: "/billing/hospitalization-billing/report/homonyms",
  name: "hospitalization.billing.report.homonyms",
  components: {
    default: Homonyms,
    header: BillingMainHeader,
    rootModal: BillingModal,
  },
  meta: {
    breadcrumb: [
      { label: "Relatórios", link: "/billing/hospitalization-billing/report" },
    ],
  },
};

export const AihPerPatientReport = {
  path: "/billing/hospitalization-billing/report/aih-per-patient",
  name: "hospitalization.billing.report.aihPerPatient",
  components: {
    default: AihPerPatient,
    header: BillingMainHeader,
    rootModal: BillingModal,
  },
  meta: {
    breadcrumb: [
      { label: "Relatórios", link: "/billing/hospitalization-billing/report" },
    ],
  },
};

export const AihPerSpecialtyReport = {
  path: "/billing/hospitalization-billing/report/aih-per-specialty",
  name: "hospitalization.billing.report.aihPerSpecialty",
  components: {
    default: AihPerSpecialty,
    header: BillingMainHeader,
    rootModal: BillingModal,
  },
  meta: {
    breadcrumb: [
      { label: "Relatórios", link: "/billing/hospitalization-billing/report" },
    ],
  },
};

export const PreviewValuesReport = {
  path: "/billing/hospitalization-billing/report/preview-values",
  name: "hospitalization.billing.report.previewValues",
  components: {
    default: PreviewValues,
    header: BillingMainHeader,
    rootModal: BillingModal,
  },
  meta: {
    breadcrumb: [
      { label: "Relatórios", link: "/billing/hospitalization-billing/report" },
    ],
  },
};

export const BillingBilling = {
  path: "/billing/billing",
  name: "billing.billing",
  components: {
    default: Billing,
    header: BillingMainHeader,
    rootModal: BillingModal,
  },
  meta: {
    breadcrumb: [
      { label: "Boletins", link: "/billing/bulletin" },
      { label: "Faturamento", link: "/billing" },
    ],
  },
};

export const BillingExport = {
  path: "/billing/export-billing",
  name: "billing.export-billing",
  components: {
    default: ExportBilling,
    header: BillingMainHeader,
    rootModal: BillingModal,
  },
  meta: {
    breadcrumb: [
      { label: "Boletins", link: "/billing/bulletin" },
      { label: "Exportação", link: "/export-billing" },
    ],
  },
};

export const HospitalizationBillingExport = {
  path: "/billing/export-hospitalization-billing",
  name: "billing.export-hospitalization-billing",
  components: {
    default: ExportHospitalizationBilling,
    header: BillingMainHeader,
    rootModal: BillingModal,
  },
  meta: {
    breadcrumb: [
      {
        label: "Internações AIH",
        link: "/billing/hospitalization-billing/search",
      },
      { label: "Exportação", link: "/billing/export-hospitalization-billing" },
    ],
  },
};

export const BillingDefinePeriod = {
  path: "/billing/define-period",
  name: "billing.definePeriod",
  components: {
    default: DefinePeriod,
    header: BillingMainHeader,
    rootModal: BillingModal,
  },
  meta: {
    breadcrumb: [
      { label: "Boletins", link: "/billing/bulletin" },
      { label: "Competência", link: "/define-period" },
    ],
  },
};

export const BillingDefineHospitalizationPeriod = {
  path: "/billing/define-hospitalization-period",
  name: "billing.defineHospitalizationPeriod",
  components: {
    default: DefineHospitalizationPeriod,
    header: BillingMainHeader,
    rootModal: BillingModal,
  },
  meta: {
    breadcrumb: [
      {
        label: "Internação AIH",
        link: "/billing/hospitalization-billing/search",
      },
      { label: "Competência", link: "/define-period" },
    ],
  },
};

export const BillingConsolidated = {
  path: "/billing/consolidated",
  name: "billing.consolidated",
  components: {
    default: ConsolidatedBilling,
    header: BillingMainHeader,
    rootModal: BillingModal,
  },
  meta: {
    breadcrumb: [{ label: "Consolidado" }],
  },
};

export const BillingReport = {
  path: "/billing/emergency",
  name: "billing.emergency",
  components: {
    default: Report,
    header: BillingMainHeader,
    rootModal: BillingModal,
  },
};

export const BillingReports = {
  ...BillingReportRoute,
};
