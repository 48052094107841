import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchHospitalizationBedType(
    $itl_id: Int
    $itl_id_clientes: Int
    $itl_nome: String
  ) {
    hospitalizationBedType(
      itl_id: $itl_id
      itl_id_clientes: $itl_id_clientes
      itl_nome: $itl_nome
    ) {
      rows {
        itl_id
        itl_id_clientes
        itl_nome
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({ query, variables });
    return data.hospitalizationBedType.rows;
  } catch (err) {
    const jsonResponse = JSON.parse(JSON.stringify(err));
    return Promise.reject(jsonResponse.graphQLErrors[0].message);
  }
};
