import GetLocationTypes from "./GetLocationTypes";
import GetHomeSituation from "./GetHomeSituation";
import GetAccessTypes from "./GetAccessTypes";
import GetBuildingMaterial from "./GetBuildingMaterial";
import GetRuralProduction from "./GetRuralProduction";
import GetWaterTreatment from "./GetWaterTreatment";
import GetWaterSupply from "./GetWaterSupply";
import GetBathroomDrain from "./GetBathroomDrain";
import GetTrashDestination from "./GetTrashDestination";
import GetAnimalsTypes from "./GetAnimalsTypes";
import GetHomeTypes from "./GetHomeTypes";
import SearchHomeRegisterForm from "./SearchHomeRegisterForm";
import SaveHomeRegisterForm from "./SaveHomeRegisterForm";
import GetHomeRegisterForm from "./GetHomeRegisterForm";
import GetFamilyResponsableByDocument from "./GetFamilyResponsableByDocument";
import GetIndividualInformationByDocument from "./GetIndividualInformationByDocument";

export default {
  GetLocationTypes,
  GetHomeSituation,
  GetAccessTypes,
  GetBuildingMaterial,
  GetRuralProduction,
  GetWaterSupply,
  GetBathroomDrain,
  GetTrashDestination,
  GetWaterTreatment,
  GetAnimalsTypes,
  GetHomeTypes,
  SearchHomeRegisterForm,
  SaveHomeRegisterForm,
  GetHomeRegisterForm,
  GetFamilyResponsableByDocument,
  GetIndividualInformationByDocument,
};
