const RecommendationsSheetHTML = (pData, snap) => {
  let dadosTabela = "";

  if (pData.length >= 1) {
    pData.forEach((item) => {
      "<tr>";
      dadosTabela +=
        "<p><b>Procedimento:</b>" + item.stp_novo_nome_procedimento;
      dadosTabela += "<p><b>Recomendações:</b>" + item.stp_recomendacao;
    });
  } else {
    dadosTabela += `<p><b>Procedimento: </b>${pData.stp_novo_nome_procedimento}</p>`;
    dadosTabela += `<p><b>Recomendações: </b>${pData.stp_recomendacao}</p>`;
  }

  const html = `
  <!DOCTYPE html>
  <html>
  <head>
    <meta charset="utf-8">
    <title>Esus</title>
      <style type="text/css">
      html, body, div, span, p {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
      }

      body {
        width: 7cm;
        margin: .1cm;
        font-size: 8pt;
        font-family: Verdana, sans-serif;
      }

      div, p {
          width: 6.5cm;
        margin: .2cm auto;
          word-wrap: break-word;
          line-height: 120%;
      }

      @media print{
          body {
          width: 7cm;
          margin: .1cm;
          font-size: 8pt;
          font-family: Verdana, sans-serif;
        }

        div, p {
            width: 6.5cm;
          margin: .2cm auto;
            word-wrap: break-word;
            line-height: 120%;
        }

          @page {
            margin: 0;
            padding: 0;
            border: 0;
          }

      }
    </style>
  </head>

  <body>
	<p>Recomendações e Preparo</p>

	<p>${snap.client}</p>

	<p>${snap.unit}</p>

  <p>Emitido em ${snap.date} ${snap.hour}</p>

  ${dadosTabela}

	<hr/>
</body>

  </html>
    `;
  return html;
};

export default RecommendationsSheetHTML;
