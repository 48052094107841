<style src="./SchedulingCalendar.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="scheduling-calendar-inner">
    <div class="scheduling-panel">
      <CalendarTopDate v-model="calendar.date" />
      <RgComboboxSector
        v-model="calendar.sector"
        :show-default-option="false"
        label="Centro Cirúrgico"
      />
    </div>
    <SurgeryScheduler />
  </section>
</template>

<script>
import CalendarTopDate from "./calendar-top-date/CalendarTopDate";
import SurgeryScheduler from "./surgery-scheduler/SurgeryScheduler";
import RgComboboxSector from "$surgery-center/unit-health/component/rg-combobox-sector/RgComboboxSector";

export default {
  name: "SchedulingCalendar",
  components: {
    CalendarTopDate,
    SurgeryScheduler,
    RgComboboxSector,
  },
  data() {
    return {
      calendar: {
        date: "",
        sector: false,
      },
    };
  },
  watch: {
    calendar: {
      handler(newValue) {
        if (!this.calendar.sector || !this.calendar.date) {
          return false;
        }
        this.$store.dispatch(
          "SurgeryCenter/Surgery/reloadCalendar",
          this.calendar,
        );
      },
      deep: true,
    },
    "calendar.sector": function () {
      if (
        !isNaN(parseFloat(this.calendar.sector)) &&
        isFinite(this.calendar.sector)
      ) {
        this.$store.dispatch(
          "SurgeryCenter/Surgery/loadStages",
          this.calendar.sector,
        );
      }
    },
  },
};
</script>
