<template>
  <section class="ctps-document">
    <FormBase title="CTPS" class="form-base-ctps">
      <div class="grid">
        <div class="row-1">
          <RgSuggestState
            ref="state"
            v-model="form.est_sigla"
            data-id="estado-ctps"
            label="Estado"
            title="Estado CTPS"
            :rules="{ required: required, fn: disableOrEnabledRequired }"
            @selected="selectingState"
          />

          <RgInput
            ref="number"
            v-model="form.ctt_numero"
            data-id="númeto-ctps"
            label="Número"
            title="Número CTPS"
            :rules="{ required: required }"
            :change="disableOrEnabledRequired"
            :maxlength="15"
          />

          <RgInput
            ref="serial"
            v-model="form.ctt_serie"
            data-id="série"
            label="Série"
            title="Série CTPS"
            :rules="{ required: required }"
            :change="disableOrEnabledRequired"
            :maxlength="15"
          />

          <RgInputDate
            ref="dateIssue"
            v-model="form.ctt_emissao"
            placeholder="dd/mm/aaaa"
            class="dateIssue"
            data-id="data-emissão-ctps"
            label="Data de Emissão"
            title="Data de Emissão CTPS"
            :rules="{ required: required }"
            :max-date="new Date()"
          />
        </div>
      </div>

      <!-- <div class="attachment">
        <rg-attachment-button
          :class="{ 'disable': disabledAttachment }"
          :disbaled="disabledAttachment"
          class="button"
          @click="clicked"
        />
      </div> -->
    </FormBase>
  </section>
</template>

<script>
import { isEmpty } from "lodash";
import { RgInput, RgInputDate } from "~tokio/primitive";
import RgSuggestState from "$person/common/components/suggest/rg-suggest-state/RgSuggestState";
import FormBase from "~tokio/foundation/form-base/FormBase";

export default {
  name: "CtpsDocument",
  components: {
    FormBase,
    RgInput,
    RgInputDate,
    RgSuggestState,
  },
  props: {
    ctpsData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        ctt_id: 0,
        ctt_numero: "",
        ctt_serie: "",
        ctt_emissao: "",
        ctt_id_estados: 0,
        est_sigla: "",
      },
      required: false,
    };
  },
  computed: {
    disabledAttachment() {
      const state = this.form.ctt_id_estados !== 0;

      const number = this.form.ctt_numero && this.form.ctt_numero.length > 0;
      const series = this.form.ctt_serie && this.form.ctt_serie.length > 0;
      const dateIssue =
        this.form.ctt_emissao && this.form.ctt_emissao.length >= 10;

      return !(state && number && series && dateIssue);
    },
  },
  watch: {
    ctpsData(pValue) {
      if (!pValue) {
        this.cleanForm();
      }

      if (!isEmpty(this.ctpsData)) {
        this.mountCtpsData(pValue);
      }
    },
    "form.ctt_emissao"() {
      this.disableOrEnabledRequired();
    },
  },
  methods: {
    mountCtpsData(pData) {
      this.form = {
        ctt_id: pData.ctt_id || 0,
        ctt_numero: pData.ctt_numero,
        ctt_serie: pData.ctt_serie,
        ctt_emissao: pData.ctt_emissao,
        ctt_id_estados: pData.ctt_id_estados || 0,
      };

      if (pData.state) {
        this.$refs.state.forceSelection({
          est_id: pData.state.est_id,
          est_sigla: pData.state.est_sigla,
        });
      }
    },

    selectingState(pValue) {
      if (pValue && pValue.source) {
        this.form.ctt_id_estados = pValue.source.est_id
          ? pValue.source.est_id
          : 0;
      } else {
        this.disableOrEnabledRequired();
      }
    },

    getCtps() {
      const ctps = this.$utils.obj.DeepCopy(this.form);
      delete ctps.est_sigla;
      return ctps;
    },

    cleanForm() {
      this.form = {
        ctt_id: 0,
        ctt_numero: "",
        ctt_serie: "",
        ctt_emissao: "",
        ctt_id_estados: 0,
        est_sigla: "",
      };
      this.cleanValidate();
    },

    clicked() {
      this.$emit("click");
    },

    disableOrEnabledRequired() {
      this.required =
        !!this.form.est_sigla ||
        !!this.form.ctt_numero ||
        !!this.form.ctt_serie ||
        !!this.form.ctt_emissao;

      if (!this.required) {
        this.cleanValidate();
      }
    },

    cleanValidate() {
      this.$refs.state.cleanValidate();
      this.$refs.number.cleanValidate();
      this.$refs.serial.cleanValidate();
      this.$refs.dateIssue.cleanValidate();
      this.required = false;
    },
  },
};
</script>
