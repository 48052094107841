import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchHospitalizationExitGroups(
    $igs_nome: String
    $igs_ativo: Int
    $limit: Int
  ) {
    hospitalizationExitGroups(
      igs_nome: $igs_nome
      igs_ativo: $igs_ativo
      limit: $limit
    ) {
      count
      rows {
        igs_id
        igs_nome
        igs_destino_obrigatorio
        igs_ativo
        igs_codigo
      }
    }
  }
`;

export default async (variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    return data.hospitalizationExitGroups;
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors);
  }
};
