<style src="./SchedulingRequest.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="scheduling-request">
    <BaseSurgeryView>
      <section class="right-content">
        <ModuleBox
          id="scheduling-surgery"
          :title="formTitle"
          :granted="hasSchedulingPermission"
          class="scheduling-request-form"
        >
          <div slot="title">
            <RgButtonPrintSurgery />
          </div>
          <SchedulingRequestForm />
        </ModuleBox>
      </section>
    </BaseSurgeryView>
  </section>
</template>

<script>
import ModuleBox from "~tokio/foundation/modulebox/Modulebox";
import SchedulingRequestForm from "$surgery-center/surgery/component/scheduling-request-form/SchedulingRequestForm";
import BaseSurgeryView from "$surgery-center/surgery/view/BaseSurgeryView";
import RgButtonPrintSurgery from "$surgery-center/surgery/component/button/rg-button-print-surgery/RgButtonPrintSurgery";
export default {
  name: "SchedulingRequest",
  components: {
    ModuleBox,
    SchedulingRequestForm,
    BaseSurgeryView,
    RgButtonPrintSurgery,
  },
  computed: {
    formTitle() {
      return this.$store.state.SurgeryCenter.Surgery.selectedItem
        ? "Alterar Pedido"
        : "Cadastro de Pedido Cirúrgico";
    },
    hasSchedulingPermission() {
      const ITEM_SELECTED = this.$store.getters[
        "SurgeryCenter/Surgery/getSelectedItemId"
      ];
      if (ITEM_SELECTED) {
        return this.$Permissions.global.has(
          "centro_cirurgico.cirurgia.alterarPedido",
        );
      } else {
        return this.$Permissions.global.has(
          "centro_cirurgico.cirurgia.cadastrarPedido",
        );
      }
    },
  },
  mounted() {
    this.$store.dispatch("SurgeryCenter/Surgery/fillFilter", {
      filterKey: "cci_id_status_controles_cirurgicos",
      filterValue: [1],
    });
    this.$store.dispatch("SurgeryCenter/Surgery/filter");
  },
  methods: {
    print() {},
  },
};
</script>
