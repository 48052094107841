import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import PatientDetails from "$person/patient/view/patient-details/PatientDetails";
import OpenHospitalizationModule from "$hospitalization/common/util/OpenHospitalizationModule.js";

export default {
  path: "/hospitalization/hospitalizations/search/details/new-patient",
  name: "hospitalization.hospitalizations.search.details.new-patient",
  components: {
    default: PatientDetails,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Internação", link: OpenHospitalizationModule },
      {
        label: "Lista de Internações",
        link: "/hospitalization/hospitalizations/search",
      },
      {
        label: "Cadastrar Internação",
        link: "/hospitalization/hospitalizations/search/details",
      },
      { label: "Cadastrar Paciente" },
    ],
  },
};
