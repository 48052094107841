import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchInternalizationReport(
    $limit: Int
    $offset: Int
    $initialDate: String
    $finalDate: String
    $toPrint: Boolean
    $toCsv: Boolean
    $toExcel: Boolean
  ) {
    SearchInternalizationReport(
      limit: $limit
      offset: $offset
      initialDate: $initialDate
      finalDate: $finalDate
      toPrint: $toPrint
      toCsv: $toCsv
      toExcel: $toExcel
    ) {
      html
      count
      csv
      excel
      fileName
      rows {
        PACIENTE
        IDADE
        TELEFONES
        ENDERECO
        NOME_DA_MAE
        UNIDADE_DE_SAUDE
        DATA_DA_INTERNACAO
        DATA_DA_SAIDA
        FUNCIONARIO
        OCUPACAO
      }
    }
  }
`;

export default async (
  { commit },
  { initialDate, finalDate, toCsv, toExcel, toPrint, limit, offset },
) => {
  try {
    const variables = {
      toCsv,
      toPrint,
      toExcel,
      initialDate,
      finalDate,
      limit,
      offset,
    };
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.SearchInternalizationReport;
  } catch (err) {
    return err.graphQLErrors;
  }
};
