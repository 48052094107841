import SurgeryStatusModel from "$surgery-center/surgery/model/SurgeryStatusModel";
import SurgeryControlModel from "$surgery-center/surgery/model/SurgeryControlModel";
import Store from "@/store";
import moment from "moment";
export default ({ state, commit }) => {
  const unitHealth = Store.getters["Login/GET_UNIT_HEALTH_ID"];
  const filter = {
    cci_id_setores: state.calendar.sector,
    uns_id: unitHealth,
    cci_id_status_controles_cirurgicos: [SurgeryStatusModel.SCHEDULED],
    cci_data_inicio: moment(state.calendar.actualDate).format("YYYY-MM-DD"),
  };
  SurgeryControlModel.search(filter).then((res) => {
    commit("CHANGE_SURGERY_CALENDAR", {
      date: state.calendar.actualDate,
      sector: filter.cci_id_setores,
    });
    commit("LOAD_CALENDAR", res.data);
  });
};
