import SEARCH_EMPLOYEE_AGENDA from "./SearchEmployeeAgenda";
import SEARCH_EMPLOYEE_AGENDA_PER_PERIODS from "./SearchEmployeeAgendaPerPeriods";
import SEARCH_APPOINTMENT_SCHEDULE from "./SearchAppointmentSchedule";

import SCHEDULE_PATIENT from "./SchedulePatient";
import EDIT_SCHEDULE_PATIENT from "./EditSchedulePatient";
import SCHEDULE_LOAD from "./ScheduleLoad";

import SAVE_PROFESSIONAL_SCALE from "./SaveProfessionalScale";

import REMOVE_APPOINTMENT from "./RemoveAppointment";
import GET_PATIENT_BASIC_INFO from "./GetBasicPatientInfo";

import GET_DATA_SCHEDULE_VOUCHER from "./GetDataScheduleVoucher";
import GET_SCHEDULE_VOUCHER from "./GetScheduleVoucher";
import GET_DAYS_AND_PERIODS_INFO_FROM_SECTOR from "./GetDaysAndPeriodsInfoFromSector";
import SEND_APPOINTMENT_PEP from "./SendAppointmentToPEP";
import CANCEL_APPOINTMENT_PEP from "./CancelAppointmentOnPEP";
import GET_HEALTHCARE_BULLETIN from "./GetHealthcareBulletin";
import GET_PATIENT_FOLLOW_UP_SHEET from "./GetPatientFollowUpSheet";
import GET_OUT_PATIENT_CONSULTATION_CONTROL from "./GetOutpatientConsultationControl";
import GET_DAY_ON_WEEK_FOR_PERIODS from "./GetDayOnWeekForPeriods";

import GET_WEEKDAY_BY_PERIOD from "./GetWeekdaysByPeriod";
import SCHEDULE_PATIENT_IN_BATCH from "./SchedulePatientInBatch";

import SEARCH_APPOINTMENT_QUEUE from "./SearchAppointmentQueue";
import ADD_APPOINTMENT_QUEUE from "./AddAppointmentQueue";
import REMOVE_APPOINTMENT_QUEUE from "./RemoveAppointmentQueue";
import REGULATION_APPOITMENT_QUEUE from "./RegulationAppointmentQueue";
import UNREGULATE_APPOITMENT_QUEUE from "./UnregulateAppointmentQueue";
import SAVE_APPOINTMENT_EXTERNAL_SCHEDULE from "./SaveAppointmentExternalSchedule";
import GET_DATA_PATIENT_APPOINTMENT_QUEUE from "./GetDataPatientAppointmentQueue";
import SEARCH_APPOINTMENT_QUEUES_PER_PERSON from "./SearchAppointmentQueuesPerPerson";
import EDIT_APPOINTMENT_QUEUE from "./EditAppointmentQueue";

import SAVE_REGISTER_SCHEDULE_QUEUE_LOT from "./SaveRegisterScheduleQueueLot";
import PRINT_PATIENT_EXTERNAL_SCHEDULE from "./PrintPatientExternalSchedule";
import GET_APPOINTMENT_DETAILS from "./GetAppointmentDetails";
import GET_PATIENT_APPOINTMENT_DETAILS from "./GetPatientAppointmentDetails";
import GET_SCHEDULE_PROOF_INSERTION_QUEUE from "./GetScheduleProofInsertionQueue";
import SEARCH_HISTORY_SCHEDULE from "./SearchHistorySchedule";

import SEARCH_PATIENT_OBSERVATION_QUEUE from "./SearchPatientObservationQueue";
import SAVE_OBSERVATION_QUEUE from "./SaveObservationQueue";

import SEARCH_PENDENCY_QUEUE from "./SearchPendencyQueue";
import SAVE_PENDENCY_QUEUE from "./SavePendencyQueue";
import GET_PRINT_PENDENCY_QUEUE from "./GetPrintPendencyQueue";
import SAVE_RESOLUTION_QUEUE from "./SaveResolutionQueue";
import REMOVE_PENDENCY_QUEUE from "./RemovePendencyQueue";
import REMOVE_RESOLUTION_QUEUE from "./RemoveResolutionQueue";

import ADVANCE_PATIENT_QUEUE from "./AdvancePatientQueue";
import BACK_PATIENT_QUEUE from "./BackPatientQueue";
import MOVE_PATIENT_QUEUE from "./MovePatientQueue";

import GET_TRANSFER_ORIGIN_PATIENTS from "./transfer/GetOriginPatients";
import GET_TRANSFER_DESTINY_PATIENTS from "./transfer/GetDestinyPatients";
import GET_AVAILABLE_VACANCIES from "./transfer/GetAvailableVacancies";
import TRANSFER from "./transfer/Transfer";
import PRINT_TRANSFER from "./transfer/Print";

import CHECK_PROFESSIONAL_HAS_STANDARD_PROCEDURES from "./effectiveness/CheckProfessionalHasStandardProcedures";
import EDIT_EFFECTIVENESS_APPOINTMENT_NEW_SEARCH from "./effectiveness/EditEffectivenessAppointmentNewSearch";
import REGISTER_SCHEDULE_VOUCHER_BATCH from "./RegisterScheduleVoucherBatch";

import SCHEDULE_PATIENT_APPOINTMENT from "./schedule/SchedulePatientAppointment";
import SEARCH_PATIENT_EXCLUDE_IN_QUEUE from "./schedule/SearchPatientExcludeInQueue";
import GET_PRINT_PATIENT_EXCLUDE from "./schedule/GetPrintPatientExclude";
import REGISTER_PRINT_RECEIPT from "./schedule/RegisterPrintReceipt";

import PRINT_PATIENT_SCHEDULE_VOUCHER from "./print/PrintPatientScheduleVoucher";
import PRINT_PATIENT_SCHEDULE_CONSULTATION_AUTHORIZATION from "./print/PrintPatientScheduleConsultationAuthorization";
import PRINT_PATIENT_SCHEDULE_LIST from "./print/PrintPatientScheduleList";

import SEARCH_DISABLED_PERIODS from "./disabled-periods/SearchDisabledPeriods.js";
import SEARCH_DISABLED_DAYS from "./disabled-periods/SearchDisabledDays.js";
import EXCLUDE_DISABLED_PERIODS from "./disabled-periods/ExcludeDisabledPeriods";
import VALIDATE_DISABLE_PERIODS from "./disabled-periods/ValidateDisablePeriods";
import ENABLE_DISABLE_PERIODS from "./disabled-periods/EnableDisablePeriods";

import SEARCH_EXTERNALLY_SCHEDULE from "./externally-schedule/SearchAppointmentExternallySchedule";
import REMOVE_EXTERNALLY_SCHEDULE from "./externally-schedule/RemoveAppointmentExternallySchedule";
import EDIT_EXTERNALLY_SCHEDULE from "./externally-schedule/EditExternallySchedule";

export default {
  REGISTER_PRINT_RECEIPT,
  PRINT_PATIENT_SCHEDULE_LIST,
  PRINT_PATIENT_SCHEDULE_CONSULTATION_AUTHORIZATION,
  PRINT_PATIENT_SCHEDULE_VOUCHER,
  SEARCH_EMPLOYEE_AGENDA,
  SEARCH_EMPLOYEE_AGENDA_PER_PERIODS,
  SEARCH_APPOINTMENT_SCHEDULE,
  SEARCH_PATIENT_OBSERVATION_QUEUE,
  REMOVE_APPOINTMENT,
  SCHEDULE_PATIENT,
  SAVE_PROFESSIONAL_SCALE,
  SCHEDULE_LOAD,
  EDIT_SCHEDULE_PATIENT,
  GET_PATIENT_BASIC_INFO,
  GET_DATA_SCHEDULE_VOUCHER,
  GET_SCHEDULE_VOUCHER,
  GET_DAYS_AND_PERIODS_INFO_FROM_SECTOR,
  SAVE_OBSERVATION_QUEUE,
  SEND_APPOINTMENT_PEP,
  CANCEL_APPOINTMENT_PEP,
  GET_HEALTHCARE_BULLETIN,
  GET_PATIENT_FOLLOW_UP_SHEET,
  GET_OUT_PATIENT_CONSULTATION_CONTROL,
  GET_WEEKDAY_BY_PERIOD,
  SCHEDULE_PATIENT_IN_BATCH,
  GET_DAY_ON_WEEK_FOR_PERIODS,
  SEARCH_APPOINTMENT_QUEUE,
  ADD_APPOINTMENT_QUEUE,
  REMOVE_APPOINTMENT_QUEUE,
  REGULATION_APPOITMENT_QUEUE,
  UNREGULATE_APPOITMENT_QUEUE,
  SAVE_APPOINTMENT_EXTERNAL_SCHEDULE,
  GET_DATA_PATIENT_APPOINTMENT_QUEUE,
  SEARCH_APPOINTMENT_QUEUES_PER_PERSON,
  EDIT_APPOINTMENT_QUEUE,
  SAVE_REGISTER_SCHEDULE_QUEUE_LOT,
  PRINT_PATIENT_EXTERNAL_SCHEDULE,
  GET_APPOINTMENT_DETAILS,
  GET_PATIENT_APPOINTMENT_DETAILS,
  GET_SCHEDULE_PROOF_INSERTION_QUEUE,
  SEARCH_PENDENCY_QUEUE,
  SAVE_PENDENCY_QUEUE,
  GET_PRINT_PENDENCY_QUEUE,
  SAVE_RESOLUTION_QUEUE,
  REMOVE_PENDENCY_QUEUE,
  REMOVE_RESOLUTION_QUEUE,
  ADVANCE_PATIENT_QUEUE,
  BACK_PATIENT_QUEUE,
  MOVE_PATIENT_QUEUE,
  GET_TRANSFER_ORIGIN_PATIENTS,
  GET_TRANSFER_DESTINY_PATIENTS,
  GET_AVAILABLE_VACANCIES,
  TRANSFER,
  PRINT_TRANSFER,
  SEARCH_HISTORY_SCHEDULE,
  CHECK_PROFESSIONAL_HAS_STANDARD_PROCEDURES,
  EDIT_EFFECTIVENESS_APPOINTMENT_NEW_SEARCH,
  REGISTER_SCHEDULE_VOUCHER_BATCH,
  SCHEDULE_PATIENT_APPOINTMENT,
  SEARCH_PATIENT_EXCLUDE_IN_QUEUE,
  GET_PRINT_PATIENT_EXCLUDE,
  SEARCH_DISABLED_PERIODS,
  SEARCH_DISABLED_DAYS,
  EXCLUDE_DISABLED_PERIODS,
  VALIDATE_DISABLE_PERIODS,
  ENABLE_DISABLE_PERIODS,
  SEARCH_EXTERNALLY_SCHEDULE,
  REMOVE_EXTERNALLY_SCHEDULE,
  EDIT_EXTERNALLY_SCHEDULE,
};
