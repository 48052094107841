import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query searchCustomHistoryTransfer(
    $pci_id: Int!
    $initialDate: String
    $finalDate: String
    $searchAll: String
    $limit: Int
    $offset: Int
  ) {
    customHistoryTransfer(
      pci_id: $pci_id
      initialDate: $initialDate
      finalDate: $finalDate
      searchAll: $searchAll
      limit: $limit
      offset: $offset
    ) {
      count
      rows {
        pes_nome
        pes_id
        pac_id
        pci_id
        pac_prontuario_unico
        int_numero
        inl_id
        inl_nome_origem
        uns_nome_origem
        uns_id_origem
        set_nome_origem
        set_id_origem
        lca_nome_origem
        lca_id_origem
        isl_nome_origem
        isl_id_origem
        inl_nome_destino
        uns_nome_destino
        uns_id_destino
        set_nome_destino
        set_id_destino
        lca_nome_destino
        lca_id_destino
        isl_nome_destino
        isl_id_destino
        itf_id
        usu_nome
        itl_nome
        data_hora
        int_id_pacientes_internacao
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    return data.customHistoryTransfer;
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors);
  }
};
