<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchHealthOccupationByEmployeeAndUnit from "./action/SearchHealthOccupationByEmployeeAndUnit";
export default {
  name: "RgComboboxHealthOccupation",
  mixins: [RgComboboxMixin],
  props: {
    unitHealth: {
      required: true,
    },

    employee: {
      required: true,
    },
  },

  data() {
    return {};
  },

  computed: {
    arePropsFilled() {
      const areUnitFilled = this.unitHealth && this.unitHealth > 0;
      const areEmployeeFilled = this.employee && this.employee > 0;

      return areUnitFilled && areEmployeeFilled;
    },
  },

  watch: {
    arePropsFilled(pValue) {
      if (pValue) {
        this.fillData();
      }
    },
  },

  methods: {
    async fillData() {
      this.mData = [];

      if (this.arePropsFilled) {
        const data = await SearchHealthOccupationByEmployeeAndUnit({
          uns_id: this.unitHealth,
          fun_id: this.employee,
        });

        data.forEach((item) => {
          this.mData.push({
            value: item.ocp_codigo,
            text: `${item.ocp_codigo} - ${item.ocp_nome}`,
          });
        });

        const isOnlyItem = this.mData.length === 1;

        if (isOnlyItem) {
          this.$emit("selectOnlyItem", this.mData[0].value);
        }
      }
    },
  },
};
</script>
