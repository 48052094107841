<style src="./SchedulingSurgeryEmployee.scss" lang="scss" scoped></style>
<template lang="html">
  <div class="scheduling-surgery-employee">
    <section class="teams-form">
      <form class="teams-list-form row" @submit.prevent="insertTeam">
        <RgComboboxEmployeeFunction
          v-model="form.employeeFunction"
          :disabled="!sectorId"
          :sector="sectorId"
          label="Função"
          class="col-4"
          @change="pushEmployeeFunction"
        />
        <RgComboboxSurgeryEmployee
          v-model="form.teamMember"
          :disabled="!sectorId"
          :sector-id="sectorId"
          class="col-7"
          @select="pushTeam"
        />
        <div class="form-submit col-1">
          <button class="button">
            <IconAdd class="button--icon" />
          </button>
        </div>
      </form>
    </section>
    <section class="form-list">
      <div class="requesting-list">
        <ul>
          <li>
            <ul class="form-list--container-header row">
              <li class="form-list--employee col-4">Função</li>
              <li class="form-list--employee col-4">Profissional</li>
              <li class="form-list--employee col-3">Ocupação</li>
              <li class="form-list--remove col-1" />
            </ul>
          </li>
          <li
            v-for="(item, i) in teamsList"
            :key="i"
            class="form-list--container-row"
          >
            <ul class="form-list--container-content row">
              <li class="form-list--employee col-4">
                {{ item.employeeFunction.text }}
              </li>
              <li class="form-list--employee col-4">
                {{ item.teamMember.text }}
              </li>
              <li class="form-list--employee col-3">
                {{ item.teamMember.ocp_codigo }} -
                {{ item.teamMember.ocp_nome }}
              </li>
              <li class="form-list--remove col-1">
                <RgRemoveSmall @confirm-remove="removeItem(i)" />
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>
<script>
import {
  IconAdd,
  RgRemoveSmall,
  RgValidatorMixin,
  toast,
} from "~tokio/primitive";
import RgComboboxSurgeryEmployee from "$surgery-center/surgery/component/combobox/rg-combobox-surgery-employee/RgComboboxSurgeryEmployee";
import RgComboboxEmployeeFunction from "$surgery-center/surgery/component/combobox/rg-combobox-employee-function/RgComboboxEmployeeFunction";
import SurgeryEmployeeModel from "$surgery-center/surgery/model/SurgeryEmployeeModel";

export default {
  name: "SchedulingSurgeryEmployee",
  components: {
    IconAdd,
    RgComboboxEmployeeFunction,
    RgRemoveSmall,
    RgComboboxSurgeryEmployee,
  },
  mixins: [RgValidatorMixin],
  props: {
    value: {
      default: null,
    },
    surgeryId: {
      default: null,
    },
    sectorId: {
      default: null,
    },
  },
  data() {
    return {
      anotherRules: {
        filled(employees, pError) {
          if (employees.length <= 0) {
            pError.push("Nenhum Funcionário adicionado");
            return false;
          }
          return true;
        },
      },
      teamsList: [],
      form: {
        teamMember: "",
        employeeFunction: "",
      },
      lMember: {
        text: "",
        value: "",
      },
      lEmployeeFunction: {
        text: "",
        value: "",
      },
    };
  },
  computed: {
    validValue() {
      return this.employee;
    },
    employee() {
      return this.teamsList.map((item) => {
        return {
          ceq_id_funcoes: item.employeeFunction.value,
          ceq_id_funcionarios: item.teamMember.value,
          ceq_id_ocupacoes_cbo: item.teamMember.ocp_id,
          ceq_ocupacoes_cbo: item.teamMember.ocp_nome,
          ceq_nome_funcionario: item.teamMember.text,
        };
      });
    },
  },
  watch: {
    surgeryId(pValue, pOldValue) {
      if (pValue !== pOldValue && pValue > 0) {
        this.fillTeam();
      } else {
        this.teamsList = [];
      }
    },
    value(pNewvalue) {
      if (pNewvalue === null || !pNewvalue.length) {
        this.cleanFields();
        this.procedureList = [];
      }
    },
  },
  methods: {
    insertTeam() {
      if (this.isItensInvalid()) {
        toast.error(
          "Para adicionar um item na lista é preciso selecioná-lo no campo ao lado",
          "Campo vazio",
        );
        return false;
      }
      const itemList = {
        teamMember: { ...this.lMember },
        employeeFunction: { ...this.lEmployeeFunction },
      };
      if (itemList.teamMember.value && itemList.employeeFunction.value) {
        for (let i = 0; i < this.teamsList.length; i++) {
          if (
            this.teamsList[i].employeeFunction.value ===
              itemList.employeeFunction.value &&
            this.teamsList[i].teamMember.value === itemList.teamMember.value
          ) {
            toast.error(
              "O profissional selecionado já esta incluido na lista de equipe para esta função",
              "Falha ao incluir...",
            );
            return;
          }
        }
        this.teamsList.push({ ...itemList });
        this.$emit("input", this.employee);
        this.cleanFields();
      }
    },
    pushTeam(e) {
      if (!e) {
        this.lMember.text = "";
        this.lMember.value = "";
      } else {
        this.lMember.text = e.pes_nome;
        this.lMember.value = e.fun_id;
        this.lMember.ocp_nome = e.ocp_nome;
        this.lMember.ocp_id = e.ocp_id;
        this.lMember.ocp_codigo = e.ocp_codigo;
      }
    },
    pushEmployeeFunction(e) {
      if (!e) {
        this.lEmployeeFunction.text = "";
        this.lEmployeeFunction.value = "";
      } else {
        this.lEmployeeFunction.text = e[0].text;
        this.lEmployeeFunction.value = e[0].value;
      }
    },
    removeItem(index) {
      this.teamsList.splice(index, 1);
      this.$emit("input", this.employee);
    },
    fillTeam() {
      SurgeryEmployeeModel.getSurgeryTeam(this.surgeryId).then(
        (pEmployeeList) => {
          const employeeNew = [];
          const employeesMapList = pEmployeeList.map((pEmployeeItem) => {
            employeeNew.push({
              ceq_id_funcoes: pEmployeeItem.ceq_id_funcoes,
              ceq_id_funcionarios: pEmployeeItem.ceq_id_funcionarios,
              ceq_id_ocupacoes_cbo: pEmployeeItem.ceq_id_ocupacoes_cbo,
              ceq_ocupacoes_cbo: pEmployeeItem.ceq_ocupacoes_cbo,
              ceq_nome_funcionario: pEmployeeItem.ceq_nome_funcionario,
            });
            return {
              teamMember: {
                text: pEmployeeItem.ceq_nome_funcionario,
                ocp_nome: pEmployeeItem.ceq_ocupacoes_cbo,
                ocp_id: pEmployeeItem.ceq_id_ocupacoes_cbo,
                ocp_codigo: pEmployeeItem.ocp_codigo,
                value: pEmployeeItem.ceq_id_funcionarios,
              },
              employeeFunction: {
                text: pEmployeeItem.fnc_nome,
                value: pEmployeeItem.ceq_id_funcoes,
              },
            };
          });

          this.teamsList = employeesMapList;
          this.$emit("input", employeeNew);
        },
      );
    },
    cleanFields() {
      this.form.teamMember = "";
      this.form.employeeFunction = "";
      this.lMember = {};
      this.lEmployeeFunction = {};
    },
    isItensInvalid() {
      const itemValue = this.lMember && this.lMember.value;
      const itemFunction =
        this.lEmployeeFunction && this.lEmployeeFunction.value;
      return !itemValue || !itemFunction;
    },
  },
};
</script>
