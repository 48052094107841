<style src="./SurgeryProcedures.scss" lang="scss" scoped></style>
<template lang="html">
  <PrintFrameInfo label="Procedimentos" class="row-procedures">
    <div class="list-header">
      <span class="list-column-1">Porte</span>
      <span class="list-column-2">Classe</span>
      <span class="list-column-3">Procedimento</span>
    </div>

    <div
      v-for="(procedure, idx) in surgeryData.procedures"
      :key="idx"
      class="list-item"
    >
      <div class="list-column list-column-1">{{ procedure.por_nome }}</div>
      <div class="list-column list-column-2">{{ procedure.cla_nome }}</div>
      <div class="list-column list-column-3">{{ procedure.tuc_nome }}</div>
    </div>
  </PrintFrameInfo>
</template>

<script>
import PrintFrameInfo from "../print-frame-info/PrintFrameInfo";
export default {
  name: "SurgeryProcedures",
  components: {
    PrintFrameInfo,
  },
  props: {
    surgeryData: {
      default() {},
    },
  },
};
</script>
