<template lang="html">
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 58 58"
    style="enable-background: new 0 0 58 58"
    xml:space="preserve"
  >
    <path
      d="M54,0H14v14h2v6.917v19.306c-0.843-0.138-1.712-0.229-2.618-0.221c-5.069,0.07-9.048,2.533-9.354,5.687L4,45.7v6.429
    c0.015,1.166,0.535,2.282,1.506,3.227C7.213,57.019,10.123,58,13.322,58c0.071,0,0.144,0,0.216-0.001
    c1.906-0.026,3.658-0.391,5.129-0.999H49c1.598,0,3-1.316,3-2.817v-4.475V22.292V14h2V0z M45,12V2h2v10H45z M21,12V2h2v10H21z M25,2
    h2v10h-2V2z M29,2h2v10h-2V2z M33,2h2v10h-2V2z M37,2h2v10h-2V2z M41,2h2v10h-2V2z M16,2h3v10h-3V2z M15.552,49.808
    c-0.202,0.035-0.407,0.067-0.615,0.093c-0.084,0.01-0.17,0.016-0.255,0.025c-0.148,0.015-0.295,0.032-0.444,0.042
    c-0.24,0.016-0.482,0.027-0.728,0.03c-0.495,0.007-0.98-0.017-1.454-0.063c-0.038-0.004-0.078-0.005-0.115-0.009
    c-0.43-0.045-0.845-0.117-1.251-0.203l7.014-7.014c0.019,0.007,0.04,0.012,0.058,0.019c0.077,0.029,0.147,0.063,0.222,0.093
    c0.072,0.03,0.136,0.065,0.206,0.096c0.185,0.083,0.372,0.163,0.544,0.256c0.047,0.025,0.092,0.05,0.137,0.076
    c0.255,0.145,0.496,0.299,0.71,0.464c0.01,0.008,0.022,0.015,0.032,0.023c0.239,0.187,0.451,0.384,0.631,0.592l0.079,0.091
    c0.086,0.107,0.165,0.215,0.232,0.326c0.029,0.047,0.058,0.095,0.085,0.144c0.064,0.12,0.114,0.241,0.156,0.364
    c0.015,0.044,0.035,0.085,0.047,0.13c0.044,0.159,0.069,0.321,0.073,0.483c0,0.002,0.001,0.004,0.001,0.007l-0.027,0.259
    c-0.178,1.689-2.394,3.151-5.282,3.669C15.589,49.803,15.571,49.805,15.552,49.808z M20,41.579c-0.016-0.009-0.034-0.018-0.05-0.027
    c-0.02-0.011-0.036-0.024-0.056-0.036c-0.052-0.029-0.113-0.049-0.165-0.078c-0.319-0.173-0.659-0.333-1.019-0.479
    c-0.154-0.063-0.308-0.123-0.467-0.18c-0.083-0.03-0.158-0.066-0.243-0.094V22.917h2V41.579z M13.409,42.001
    C13.473,42,13.536,42,13.6,42c0.634,0,1.245,0.059,1.842,0.144l-6.865,6.865c-0.185-0.087-0.385-0.162-0.556-0.26
    c-0.362-0.207-0.841-0.53-1.243-0.954c-0.015-0.016-0.033-0.031-0.047-0.048c-0.032-0.035-0.059-0.075-0.09-0.111
    c-0.348-0.422-0.567-0.882-0.614-1.366l-0.01-0.363C6.19,43.823,9.488,42.055,13.409,42.001z M13.511,55.999
    c-2.722,0.051-5.257-0.758-6.609-2.076C6.312,53.348,6.008,52.736,6,52.107v-2.318c0.04,0.032,0.086,0.06,0.126,0.091
    c0.128,0.098,0.261,0.194,0.4,0.287c0.121,0.082,0.245,0.16,0.374,0.238c0.044,0.026,0.082,0.055,0.126,0.08
    c0.027,0.016,0.06,0.026,0.087,0.041c0.809,0.457,1.744,0.821,2.78,1.072c0.022,0.005,0.044,0.012,0.066,0.017
    c0.502,0.119,1.024,0.212,1.568,0.276c0.06,0.007,0.12,0.011,0.18,0.017c0.468,0.049,0.948,0.077,1.441,0.082
    c0.059,0.001,0.116,0.01,0.175,0.01c0.071,0,0.143,0,0.215-0.001c0.365-0.005,0.722-0.025,1.074-0.054
    c0.09-0.008,0.178-0.02,0.267-0.029c0.268-0.027,0.533-0.059,0.793-0.099c0.083-0.013,0.163-0.028,0.245-0.043
    c0.275-0.048,0.545-0.102,0.809-0.164c0.052-0.012,0.104-0.025,0.156-0.038c0.312-0.078,0.616-0.164,0.911-0.261
    c0.001,0,0.002-0.001,0.003-0.001c1.277-0.42,2.375-1.014,3.225-1.737c0,0.05,0,0.098,0,0.147c0.002,0.753,0.001,1.383-0.003,1.753
    L21,51.926c0,0.141-0.019,0.282-0.05,0.422c-0.008,0.036-0.023,0.071-0.033,0.107c-0.029,0.104-0.062,0.208-0.108,0.311
    c-0.019,0.044-0.044,0.087-0.066,0.13c-0.047,0.092-0.098,0.183-0.157,0.274c-0.033,0.05-0.07,0.1-0.107,0.15
    c-0.058,0.079-0.122,0.157-0.19,0.234c-0.051,0.058-0.103,0.115-0.159,0.171c-0.061,0.062-0.129,0.123-0.196,0.184
    c-0.074,0.066-0.147,0.133-0.228,0.197c-0.046,0.037-0.097,0.072-0.145,0.108C18.175,55.246,15.936,55.966,13.511,55.999z M50,27H40
    v2h10v2H40v2h10v2H40v2h10v2H40v2h10v2H40v2h10v4H36V23h14V27z M50,21H34v30h16v3.183C50,54.587,49.495,55,49,55H21.679
    c0.417-0.45,0.744-0.935,0.968-1.451c0.007-0.016,0.012-0.032,0.019-0.048c0.091-0.218,0.163-0.441,0.216-0.668
    c0.012-0.05,0.025-0.099,0.035-0.149c0.048-0.247,0.081-0.497,0.082-0.752l0-0.001l0,0c0,0,0,0,0,0l-0.022,0
    c0.034-0.345,0.047-0.886,0.048-1.78c0.001-0.894-0.01-2.14-0.026-3.893l-0.123-1.226l-0.064,0.011
    c-0.002-0.008-0.004-0.016-0.006-0.025c-0.018-0.078-0.036-0.155-0.058-0.231c-0.053-0.183-0.12-0.362-0.199-0.538
    c-0.023-0.052-0.04-0.105-0.066-0.157c-0.11-0.225-0.24-0.445-0.391-0.659c-0.028-0.04-0.063-0.078-0.093-0.118V20.917h-4V14h32V21z
    M52,12h-3V2h3V12z"
    ></path>
    <g></g>
  </svg>
</template>
<script>
export default {
  name: "Drugstore",
};
</script>
