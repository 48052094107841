import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

import { OpenMenuModule } from "~common/utils/app/menu";

import ExamInsertQueue from "$exam/submodules/schedule/views/queue-insert/ExamInsertQueue";

export default {
  path: "/exam/schedule/queue/queue-insert",
  name: "exam.schedule.queue.queue-insert",
  components: {
    default: ExamInsertQueue,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Exame", link: OpenMenuModule("exam") },
      { label: "Regulação", link: "/exam/schedule/queue" },
      { label: "Inserir na Fila", link: "/exam/schedule/queue/queue-insert" },
    ],
  },
};
