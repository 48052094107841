/**
 * MIXIN
 * Attach the basic rules to validate a single field in form.
 */
import { RgValidatorRules } from "./RgValidatorRules";
export default {
  name: "RgValidatorMixin",
  mounted() {
    this.validator = new RgValidatorRules(this.rules);
  },
  props: {
    rules: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      error: [],
      isValid: true, // valido
      valid: true, // validavel
      anotherRules: {},
    };
  },
  computed: {
    validValue() {
      throw new Error(
        "You need to provide a computed with the name validValue and returns his input value tobe tested",
      );
    },
    allRules() {
      return Object.assign({}, this.rules, this.anotherRules);
    },
    hasErrors() {
      return this.error !== null && this.error.length > 0;
    },
  },
  methods: {
    validate() {
      this.validator.defineRules(this.allRules);
      return this.validator
        .isValid(this.validValue, this)
        .then((result) => {
          // removendo erros que possam ter vindo duplicado: mesma mensagem
          this.error = Array.from(new Set(result.errors));
          this.isValid = result.valid;
          return this.isValid;
        })
        .catch((pErr) => {
          this.error = [];
          this.isValid = true;
          return this.isValid;
        });
    },
    cleanValidate() {
      this.error = [];
      this.isValid = true;
    },
    getError() {
      return this.error.length <= 0 ? null : this.error;
    },
  },
  watch: {
    isValid() {
      this.$emit("valid", this.getError());
    },
  },
};
