<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchUnitType from "./action/SearchUnitType";
export default {
  name: "RgComboboxUnitType",
  mixins: [RgComboboxMixin],
  data: () => {
    return {};
  },
  watch: {
    sectorId() {
      this.fillData();
    },
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];
      const data = await SearchUnitType();

      data.forEach((item) => {
        this.mData.push({ value: item.data, text: item.label });
      });

      if (this.mData.length === 1) {
        this.$emit("selectOnlyItem", this.mData[0].value);
      }
    },
  },
};
</script>
