<style
  src="./RgContentHospitalizationBillingChildbirth.scss"
  lang="scss"
  scoped
></style>
<template lang="html">
  <div class=".rg-content-hospitalization-childbirth">
    <RgContentHospitalizationTeam
      :procedure="procedure"
      :required-documents="requiredDocuments"
      @setProfessionalList="setProfissionalList"
    />
    <form class="form">
      <RgValidatorForm ref="validator">
        <RgTitle title="Parto" />
        <div class="row">
          <RgInputMasked
            v-model="childBirth.ipa_numero_sis_prenatal"
            :rules="{ required: true }"
            class="col-2"
            label="Nº SIS Pré Natal:"
            type="number"
            mask="############"
          />
          <RgInputMasked
            v-model="childBirth.ipa_qtd_nascidos_vivos"
            :rules="{ required: true }"
            class="col-2"
            label="Nasc/Vivos:"
            type="number"
            mask="#"
          />
          <RgInputMasked
            v-model="childBirth.ipa_qtd_obito_fetal"
            :rules="{ required: true }"
            class="col-2"
            label="Óbito/Fetal:"
            type="number"
            mask="#"
          />
          <RgInputMasked
            v-model="childBirth.ipa_qtd_alta"
            :rules="{ required: true }"
            class="col-2"
            label="Alta(s):"
            type="number"
            mask="#"
          />
          <RgInputMasked
            v-model="childBirth.ipa_qtd_transferencia"
            :rules="{ required: true }"
            class="col-2"
            label="Transferência(s):"
            type="number"
            mask="#"
          />
          <RgInputMasked
            v-model="childBirth.ipa_qtd_obito"
            :rules="{ required: true }"
            class="col-2"
            label="Óbito(s):"
            type="number"
            mask="#"
          />
        </div>

        <div class="row">
          <button
            :disabled="!childBirth.professionalTeamList.length > 0"
            type="button"
            class="btn btn-success col-2"
            @click="confirm"
          >
            Confimar
          </button>
          <button class="col-2 btn btn-default" @click.stop.prevent="close">
            Fechar
          </button>
        </div>
      </RgValidatorForm>
    </form>
  </div>
</template>

<script>
import { RgValidatorForm, RgTitle, RgInputMasked } from "~tokio/primitive";
import RgContentHospitalizationTeam from "$billing/hospitalization-billing/common/component/container/content-hospitalization-team/RgContentHospitalizationTeam";
import mask from "vue-the-mask";
import { AlertError } from "../../../../service";

export default {
  name: "RgContentHospitalizationBillingChildbirth",
  components: {
    RgValidatorForm,
    RgTitle,
    RgInputMasked,
    RgContentHospitalizationTeam,
  },
  directives: {
    mask,
  },
  props: {
    procedure: {
      default: "",
    },
    requiredDocuments: {},
  },
  data() {
    return {
      professionalCaptionRaw: "pes_nome",
      childBirth: {
        type: "childbirth",
        professionalTeamList: [],
      },
    };
  },
  mounted() {
    if (this.procedure.additionalData[0]) {
      this.childBirth = this.procedure.additionalData[0];
    }
  },
  methods: {
    async confirm(event) {
      if (await this.validateForm()) {
        this.$emit("onFinish", this.childBirth);
      }
    },

    setProfissionalList(event) {
      this.childBirth.professionalTeamList = event;
    },

    close() {
      this.$emit("close");
    },

    async validateForm() {
      const valid = await this.$refs.validator.validate();
      if (!this.validateNumberOfBirth()) return false;
      // if (!this.validateTeamMembers()) return false
      return valid;
    },

    validateNumberOfBirth() {
      const total =
        parseInt(this.childBirth.ipa_qtd_obito) +
        parseInt(this.childBirth.ipa_qtd_transferencia) +
        parseInt(this.childBirth.ipa_qtd_alta);
      if (parseInt(this.childBirth.ipa_qtd_nascidos_vivos) < total) {
        AlertError(
          "Número de altas, transferências e óbitos não devem ultrapassar o total de nascidos vivos",
        );
        return false;
      }
      return true;
    },

    validateTeamMembers() {
      const hasAnesthetist = this.childBirth.professionalTeamList.filter(
        (prof) => prof.ieq_id_internacoes_funcoes_profissionais === 6,
      );
      const hasSurgeon = this.childBirth.professionalTeamList.filter(
        (prof) => prof.ieq_id_internacoes_funcoes_profissionais === 1,
      );
      if (hasAnesthetist.length && hasSurgeon.length) {
        return true;
      }
      AlertError(
        "Necessário ao menos um cirurgião e um anestesista na equipe.",
      );
      return false;
    },
  },
};
</script>
