import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchPeriodAppointmentReport(
    $initialDate: String
    $finalDate: String
    $toPrint: Boolean
    $toCsv: Boolean
    $toExcel: Boolean
    $limit: Int
    $offset: Int
  ) {
    SearchPeriodAppointmentReport(
      initialDate: $initialDate
      finalDate: $finalDate
      toPrint: $toPrint
      toCsv: $toCsv
      toExcel: $toExcel
      limit: $limit
      offset: $offset
    ) {
      html
      count
      csv
      excel
      fileName
      rows {
        PACIENTE
        IDADE
        TELEFONES
        ENDERECO
        RESPONSAVEL_PACIENTE
        UNIDADE_SAUDE
        DATA_ATENDIMENTO
        HORA_ATENDIMENTO
        FUNCIONARIO
        OCUPACAO
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.SearchPeriodAppointmentReport;
  } catch (err) {
    return err.graphQLErrors;
  }
};
