import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  return AuthLegacyRequest.post(
    "tabelas/controller-fila-log/buscar-historico-da-fila",
    { arrFormData: variables },
  )
    .then(({ data }) => {
      data = HandleResponse(data);

      if (data.dados) {
        const rows = data.dados.recordSet;
        const count = Number(data.dados.total);

        return { rows, count };
      }

      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
