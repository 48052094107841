import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query buildingMaterial {
    SearchHomeBuildingMaterial(limit: 100) {
      rows {
        mcd_id
        mcd_codigo_ab
        mcd_nome_material
      }
      count
    }
  }
`;

export default async ({ commit, state }) => {
  if (state.building_material.length > 0) {
    return;
  }
  try {
    const { data } = await GraphQLClient.query({
      query,
      fetchPolicy: "no-cache",
    });
    commit("SET_BUILDING_MATERIAL", data.SearchHomeBuildingMaterial.rows);
  } catch (err) {
    console.log(err);
    return err.graphQLErrors;
  }
};
