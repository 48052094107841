<style src="./RgHourglassButton.scss" lang="scss" scoped></style>
<template>
  <div class="rg-hourglass-button">
    <RgButton
      :data-id="dataId"
      :title="title"
      :permission="permission"
      :disabled="disabled"
      :class="{
        'rg-hourglass-button -small': small,
        'rg-hourglass-button -medium': medium,
        'rg-hourglass-button -large': large,
      }"
      type="button"
      class="button"
      @click="clicked"
    >
      <div v-show="medium || large" class="text">
        <span>{{ label }}}</span>
      </div>

      <div v-show="large" class="separator" />

      <div v-show="!medium" class="icon">
        <IconHourglass />
      </div>
    </RgButton>
  </div>
</template>
<script>
import { IconHourglass } from "~tokio/primitive/icon/symbols";
import RgButton from "~tokio/primitive/button/rg-button/RgButton.vue";
import RgButtonMixin from "~tokio/primitive/button/rg-button/RgButtonMixin";

export default {
  name: "RgAddButton",
  components: {
    IconHourglass,
    RgButton,
  },
  mixins: [RgButtonMixin],
};
</script>
