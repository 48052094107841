<template lang="html">
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 8.50002C0 8.97017 0.379708 9.34988 0.84986 9.34988H6.0307V12.1062C6.0311 12.4505 6.23629 12.7613 6.55544 12.892C6.8722 13.0234 7.23876 12.9501 7.4814 12.7075L11.0876 9.10126C11.4199 8.76896 11.4195 8.23108 11.0876 7.89918L7.4814 4.29295C7.23876 4.0503 6.87299 3.97659 6.55504 4.10848C6.23709 4.23996 6.0303 4.55034 6.0303 4.89419V7.65056H0.849462C0.379708 7.65056 0 8.03027 0 8.50002Z"
      fill="#2B5D8A"
    />
    <path
      d="M0.850349 5.20395C1.3197 5.20395 1.70021 4.82345 1.70021 4.35409V2.54998C1.70021 2.08062 2.08191 1.69972 2.55007 1.69972H7.64844C7.64883 1.69972 7.64963 1.69972 7.65003 1.69972H14.4497C14.6258 1.69972 14.7892 1.75351 14.9246 1.84515C14.9298 1.84874 14.9346 1.85272 14.9398 1.85631C14.9569 1.86826 14.974 1.88061 14.99 1.89376C14.9992 1.90133 15.0075 1.9097 15.0159 1.91766C15.0274 1.92802 15.0394 1.93798 15.0502 1.94874C15.0609 1.9595 15.0709 1.97145 15.0812 1.98301C15.0892 1.99177 15.0976 2.00014 15.1051 2.00891C15.1183 2.02484 15.1306 2.04198 15.1426 2.05911C15.1462 2.06429 15.1502 2.06907 15.1537 2.07425C15.2454 2.21012 15.2992 2.37347 15.2992 2.54918V14.4488C15.2992 14.6249 15.2454 14.7883 15.1537 14.9238C15.1502 14.9289 15.1462 14.9337 15.1426 14.9389C15.1306 14.956 15.1183 14.9732 15.1051 14.9891C15.0976 14.9983 15.0892 15.0066 15.0812 15.015C15.0709 15.0266 15.0609 15.0385 15.0502 15.0493C15.0394 15.06 15.0274 15.07 15.0159 15.0803C15.0071 15.0883 14.9988 15.0967 14.99 15.1042C14.974 15.1174 14.9569 15.1297 14.9398 15.1417C14.9346 15.1453 14.9298 15.1493 14.9246 15.1529C14.7888 15.2445 14.6254 15.2983 14.4497 15.2983H2.55047C2.08111 15.2983 1.70021 14.9166 1.70021 14.4484V12.6443C1.70021 12.175 1.3197 11.7945 0.850349 11.7945C0.380993 11.796 0.000488281 12.1766 0.000488281 12.6459V14.45C0.000488281 15.8585 1.142 17 2.55047 17H14.4501C15.1529 17 15.7904 16.7139 16.2522 16.2521C16.714 15.7904 17.0001 15.1529 17.0001 14.45V2.54998C17.0001 1.84714 16.714 1.20965 16.2522 0.747861C15.7908 0.286076 15.1533 0 14.4505 0H7.65043C7.65003 0 7.64923 0 7.64883 0H2.55047C1.142 0 0.000488281 1.14191 0.000488281 2.54998V4.35369C0.000488281 4.82345 0.380993 5.20395 0.850349 5.20395Z"
      fill="#2B5D8A"
    />
  </svg>
</template>
<script>
export default {
  name: "Logoff",
};
</script>
