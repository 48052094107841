<template lang="html">
  <section class="header-title"></section>
</template>

<script>
export default {
  name: "HeaderTitle",
  data() {
    return {};
  },
};
</script>
