<template>
  <div class="emergency-pre-registration-search">
    <RgSearch
      ref="RgSearchFilter"
      v-model="mutableList"
      v-bind="propsRGSearch"
      @viewClickPagination="viewClickPagination"
      @submitFromButton="actionCleanSelection"
      @count="getCountValue"
    >
      <div slot="filters" class="emergency-preregistration-search-filter">
        <div class="grid">
          <div class="selectinput">
            <RgComboboxUnithealth
              v-model="form.unit"
              default-text="Selecione uma Unidade"
              :rules="{ required: true }"
              label="Unidade de Saúde"
              class="inputitem"
              permission="emergencia.buscaPreCadastro"
              disabled
            />
          </div>

          <div class="selectinput">
            <RgComboboxSectorLegacy
              v-model="form.sector"
              :class="{ 'disable unselect': !disabledSector }"
              :disabled="!disabledSector"
              :rules="{ required: true }"
              :unit-health-id="Number(form.unit)"
              empty-default-text="Selecione uma Unidade"
              :submodule-id="submoduleId"
              label="Setor"
              default-text="Selecione"
              class="inputitem"
            />
          </div>

          <div class="selectinput">
            <RgInput
              ref="patient"
              v-model="form.patientName"
              class="inputitem"
              placeholder="Digite o nome do paciente"
              label="Paciente"
            />
          </div>

          <div class="toggle">
            <span :class="{ active: form.togglePriority }" class="text"
              >Pacientes com prioridade</span
            >
            <RgToggleButton
              class="input-toggle"
              :valueSync="true"
              :value="form.togglePriority"
              @change="onChangeTogglePriorityFilter"
            />
          </div>

          <div class="filter-title">
            <span class="title">Período de Entrada</span>
          </div>

          <div class="selectinput">
            <div class="period">
              <RgInputDate
                v-model="form.initialOrderPeriod"
                placeholder="dd/mm/aaaa"
                label="Data Inicial"
                class="date"
              />

              <RgInputDate
                v-model="form.finalOrderPeriod"
                :max-date="new Date()"
                placeholder="dd/mm/aaaa"
                label="Data Final"
                class="date"
              />
            </div>
          </div>
        </div>
      </div>

      <div v-if="mutableList.length <= 0" slot="menu-top" class="top-button">
        <RgNewButton
          large
          title="Cadastrar Pré-Atendimento"
          class="item"
          :class="{
            'margin-right': hasMutableListaPreRegistration,
            'disable unselect': disableHealthProfessionalActions,
          }"
          :disabled="disableHealthProfessionalActions"
          :permission="hasCreatePermission"
          @click="openInsertPreRegister"
        />
      </div>

      <section class="container">
        <div class="container-grid">
          <div class="table">
            <SmartTable
              ref="smartTableGrid"
              name="PreRegistration"
              v-bind="propsSmartTableGrid"
              class="smart-table"
              @getLine="selectRowData"
            >
              <div slot="top-buttons" class="smart-buttons">
                <RgNewButton
                  title="Cadastrar Pré-Atendimento"
                  class="item"
                  :class="{
                    'margin-right': hasMutableListaPreRegistration,
                    'disable unselect': disableHealthProfessionalActions,
                  }"
                  :disabled="disableHealthProfessionalActions"
                  :permission="hasCreatePermission"
                  @click="openInsertPreRegister"
                />

                <RgLessButton
                  v-if="hasMutableListaPreRegistration"
                  class="item"
                  title="Excluir Pré-Atendimento"
                  :class="{
                    'disable unselect':
                      !selectRow || disableHealthProfessionalActions,
                  }"
                  :disabled="!selectRow || disableHealthProfessionalActions"
                  :permission="hasDeletePermission"
                  @click="openModalDelete"
                />

                <RgShowButton
                  class="item"
                  title="Detalhes do Pré-Atendimento"
                  :class="{
                    'disable unselect': !selectRow,
                  }"
                  :disabled="!selectRow"
                  @click="openModalPreServiceDetails"
                />
              </div>
            </SmartTable>
          </div>
        </div>

        <div v-if="mutableList.length > 0" class="footer">
          <RgValidatorForm ref="validator">
            <div class="field-bottom">
              <ComboSectorPanel
                ref="selectPanel"
                v-model="form.selectPanel"
                label="Painel"
                :rules="{ required: requiredSelectPainel }"
                :class="{ disable: disableSelectPainel }"
                :disabled="disableSelectPainel"
                :unsId="form.unit"
              />
              <ComboSectorPanelCall
                v-model="form.selectCallToSector"
                label="Chamar para"
                :rules="{ required: true }"
                :unsId="form.unit"
              />
              <RgInputCheckbox
                :value="noPanel"
                :class="{ disable: !!form.selectPanel }"
                :disabled="!!form.selectPanel"
                label="Sem Painel"
                class="check-no-panel"
                @change-value="changeCheckboxActive"
              />
            </div>
          </RgValidatorForm>

          <div class="buttons">
            <LargeButton
              ref="BtnOpenModalCall"
              label="Chamar Próximo"
              :backgroundColor="'#1E88A9'"
              :disabled="notFirstLine"
              :permission="hasCallPermission"
              @click="actionOpenModalCall"
            >
              <IconCallNext slot="icon" class="icon" />
            </LargeButton>

            <LargeButton
              ref="BtnStickRow"
              label="Antecipar Chamada"
              :backgroundColor="'#1E88A9'"
              :disabled="!notFirstLine"
              :permission="hasCallPermission"
              @click="actionStickRow"
            >
              <IconStickRow slot="icon" class="icon" />
            </LargeButton>

            <LargeButton
              ref="BtnEndCall"
              label="Limpar Painel"
              :backgroundColor="'#F96B70'"
              :disabled="!form.selectCallToSector"
              @click="actionCleanCall"
            >
              <IconEndCall slot="icon" class="icon" />
            </LargeButton>
          </div>
        </div>
      </section>
    </RgSearch>

    <ModalCall
      v-if="modalCallShow"
      v-bind="propsModalCall"
      @close="onCloseModalCall"
      @call-next="onCallNext"
      @call-again="onCallAgain"
      @meet="onMeet"
    />

    <ModalAnticipateService
      v-if="modalModalAnticipateServiceShow"
      v-bind="propsModalAnticipateService"
    />

    <ModalConfirmDeletion
      v-bind="propsModalConfirmDeletion"
      :maxlength="250"
      :title="'Excluir Pré-Atendimento'"
      :subtitle="'A operação não poderá ser desfeita'"
      :body-text="selectRow && selectRow.prc_nome"
      @close="actionCloseModalDelete"
      @reason="setReason"
      @reSearch="reSearchFilter"
    />

    <ModalPreServiceDetails
      :show="showPreServiceDetails"
      :preServiceData="selectRow"
      @close="closeModalPreServiceDetails"
    />
  </div>
</template>

<script>
import {
  RgInputDate,
  RgComboboxUnithealth,
  RgInput,
  RgNewButton,
  RgLessButton,
  RgComboboxSectorLegacy,
  RgInputCheckbox,
  RgToggleButton,
  LargeButton,
  RgValidatorForm,
  RgShowButton,
} from "~tokio/primitive";

import {
  IconCallNext,
  IconEndCall,
  IconStickRow,
} from "~tokio/primitive/icon/symbols";

import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";

import RgSearch from "~tokio/foundation/rg-search/RgSearch";
import SmartTable from "~tokio/foundation/smart-table/SmartTable.vue";

import { ModalConfirmDeletion } from "~tokio/primitive/modal";

import ModalCall from "$emergency/common/modal/call/Call";
import ModalAnticipateService from "$emergency/common/modal/anticipate-service/AnticipateService";
import ModalPreServiceDetails from "$emergency/pre-registration/component/modal/modal-pre-service-details/ModalPreServiceDetails";

import ComboSectorPanel from "$emergency/common/combobox/sector-panel/SectorPanel";
import ComboSectorPanelCall from "$emergency/common/combobox/sector-panel-call/SectorPanelCall";

import moment from "moment";

const RADIO_LIST_RISK = [
  { value: 1, text: "Iguais a" },
  { value: 2, text: "Maiores ou iguais a" },
  { value: 3, text: "Menores ou iguais a" },
  { value: 0, text: "Não filtrar" },
];

const COLUMN_SMART_GRID = [
  { name: "Setor", key: "set_nome" },
  { name: "Paciente", key: "prc_nome" },
  { name: "Prioridade", key: "prioridade" },
  { name: "Chegada", key: "entrada" },
  { name: "Última Chamada", key: "prc_ultima_chamada" },
  { name: "Quantidade de Chamadas", key: "prc_quantidade_chamadas" },
];

const FORM_FILTER = {
  unit: null,
  sector: null,
  patientName: null,
  bulletin: null,
  initialOrderPeriod: moment().subtract(1, "days").format("DD/MM/YYYY"),
  finalOrderPeriod: moment().format("DD/MM/YYYY"),
  filterRadioRisk: null,
  numberRisk: null,
  togglePriority: false,
  checkShowPreRegistration: false,
  gridList: [],
  selectPanel: null,
  selectCallToSector: null,
};

export default {
  name: "PreRegistration",
  components: {
    ComboSectorPanel,
    ComboSectorPanelCall,
    RgSearch,
    RgInputDate,
    RgComboboxUnithealth,
    RgComboboxSectorLegacy,
    RgInput,
    SmartTable,
    RgNewButton,
    RgLessButton,
    ModalCall,
    LargeButton,
    IconCallNext,
    IconEndCall,
    IconStickRow,
    ModalConfirmDeletion,
    ModalAnticipateService,
    ModalPreServiceDetails,
    RgToggleButton,
    RgValidatorForm,
    RgInputCheckbox,
    RgShowButton,
  },

  data() {
    return {
      mutableList: [],

      selectRow: null,
      isCalling: false,
      total: 0,
      modalCallTitle: "",
      modalCallMessage: "",
      modalCallShow: false,
      modalDeleteShow: false,
      modalModalAnticipateServiceShow: false,
      noPanel: false,
      disableSelectPainel: false,
      requiredSelectPainel: true,

      reasonRemove: null,

      form: this.$utils.obj.DeepCopy(FORM_FILTER),
      itemsRadioRisk: this.$utils.obj.DeepCopy(RADIO_LIST_RISK),

      queueNumber: 0,
      timeoutCall: null,
      disableHealthProfessionalActions: false,
      showPreServiceDetails: false,
      timeInMilliseconds: 15000,
    };
  },

  computed: {
    propsRGSearch() {
      const buildFilter = () => this.generateFilter();
      const searchFunction = (data) => this.searchFilter(data);
      const clearFunction = () => this.cleanForm();
      const itemHeight = 0.1;
      const backendLegacy = true;
      const maxRegister = 99999;
      const infiniteLimit = true;
      const showTitle = true;
      const showEmptyMessage = true;
      const showFooter = false;
      const resultTitle = "Fila do Pré-Atendimento";
      const msgSuccess = "Pré-atendimento excluído com sucesso!";

      return {
        buildFilter,
        searchFunction,
        clearFunction,
        itemHeight,
        backendLegacy,
        maxRegister,
        showTitle,
        showEmptyMessage,
        resultTitle,
        msgSuccess,
        showFooter,
        infiniteLimit,
      };
    },

    propsSmartTableGrid() {
      const body = this.mutableList;
      const total = this.total;
      const columns = COLUMN_SMART_GRID;
      const indexColumn = "id";
      const showPagination = false;
      const hasCheck = false;
      const initialColumns = 8;
      const toggleSelected = true;

      return {
        body,
        total,
        columns,
        indexColumn,
        showPagination,
        hasCheck,
        initialColumns,
        toggleSelected,
      };
    },

    propsModalCall() {
      const title = this.modalCallTitle;
      const message = this.modalCallMessage;
      const show = this.modalCallShow;
      const disabledCallNext = this.disabledCallNext;
      const isCalling = this.isCalling;
      const sectorId = this.sectorId;

      return {
        title,
        message,
        show,
        disabledCallNext,
        isCalling,
        sectorId,
      };
    },

    propsModalAnticipateService() {
      const show = this.modalModalAnticipateServiceShow;
      const name = this.selectRow.prc_nome;
      const isCalling = this.isCalling;
      const sectorId = this.sectorId;
      const meet = (data) => this.onAnticipateServiceMeet(data);
      const call = (data) => this.onAnticipateServiceCall(data);
      const close = (data) => this.onCloseModalAnticipateService(data);

      return {
        show,
        name,
        meet,
        call,
        close,
        isCalling,
        sectorId,
      };
    },

    propsModalConfirmDeletion() {
      const show = this.modalDeleteShow;
      const confirm = () => this.actionRemoveItem();

      return { show, confirm };
    },

    disabledSector() {
      return this.form.unit > 0;
    },

    submoduleId() {
      return 167;
    },

    hasMutableListaPreRegistration() {
      if (!this.mutableList) {
        return false;
      }
      return this.mutableList.length > 0;
    },

    disabledCallNext() {
      return this.queueNumber >= this.mutableList.length - 1;
    },

    notFirstLine() {
      const selected = this.selectRow !== null;
      const isFirst = selected
        ? this.selectRow.prc_id === this.mutableList[0].prc_id
        : false;

      return selected && !isFirst;
    },

    hasCallPermission() {
      return (
        this.$Permissions.sector.has(
          "emergencia.buscaPreCadastro.chamarPaciente",
          this.sectorId,
        ) ||
        this.$Permissions.sector.has(
          "emergencia.buscaPreCadastro.darEntrada",
          this.sectorId,
        )
      );
    },

    hasCreatePermission() {
      return this.$Permissions.sector.has(
        "emergencia.buscaPreCadastro.incluir",
        this.sectorId,
      );
    },

    hasDeletePermission() {
      return this.$Permissions.sector.has(
        "emergencia.buscaPreCadastro.excluir",
        this.sectorId,
      );
    },

    sectorId() {
      if (this.selectRow || (this.mutableList && this.mutableList.length > 0)) {
        return this.selectRow
          ? this.selectRow.set_id
          : this.mutableList[0].set_id;
      }
      return null;
    },
  },

  watch: {
    "form.selectPanel"(value) {
      if (!value) {
        this.form.selectCallToSector = null;
      }
    },
  },

  async mounted() {
    this.form.unit = await this.$store.getters["Login/GET_UNIT_HEALTH_ID"];

    await this.canDoHealthProfessionalActions();

    this.fillFilter();
  },

  beforeDestroy() {
    const validateIfExist = ValidateIfRouteExistInBreadscrumb(
      "/emergency/pre-registration",
      this.$route.meta.breadcrumb,
    );

    if (!validateIfExist) {
      this.$store.commit(
        "Emergency/PreRegistration/RESET_FILTER_EMERGENCY_PRE_REGISTER",
      );
    }
  },

  methods: {
    async canDoHealthProfessionalActions() {
      const healthOccupations = await this.$store.getters[
        "Login/GET_USER_HEALTH_OCCUPATIONS"
      ];

      const healthOccupationsByUnit =
        healthOccupations?.occupations[this.form.unit];

      this.disableHealthProfessionalActions = !healthOccupationsByUnit;
    },

    async onCallAgain() {
      await this.actionCall(this.queueNumber);
    },

    async onCallNext() {
      clearTimeout(this.timeoutCall);
      await this.actionEndCall(this.queueNumber);

      this.addQueueNumber();
      this.modalCallMessage = this.mutableList[this.queueNumber].prc_nome;
      await this.actionCall(this.queueNumber);
    },

    async onMeet() {
      clearTimeout(this.timeoutCall);

      if (!this.noPanel) {
        await this.actionEndCall(this.queueNumber);
      }

      this.selectRow = this.mutableList[this.queueNumber];
      this.$store.commit(
        "Emergency/PreRegistration/SET_SELECT_ROW_PRE_REGISTER",
        this.selectRow,
      );

      const routeName = "emergency.pre-registration.bulletin";
      this.$router.push({ name: routeName });
    },

    async actionOpenModalCall() {
      if (this.mutableList.length <= 0 || !this.form.selectCallToSector) {
        this.$refs.validator.validate();
        this.$toaster.warning("Verifique os campos e tente novamente.");
        return;
      }

      this.modalCallShow = true;
      this.modalCallTitle = "Próximo chamada da fila";
      this.modalCallMessage = this.mutableList[this.queueNumber].prc_nome;
      this.onCallAgain();
    },

    async onCloseModalCall() {
      if (this.isCalling) {
        clearTimeout(this.timeoutCall);
        await this.actionEndCall(this.queueNumber);
      }
      this.modalCallShow = false;
      this.queueNumber = 0;
      this.reSearchFilter();
    },

    selectRowData(data) {
      this.selectRow = data;
      this.$store.commit(
        "Emergency/PreRegistration/SET_SELECT_ROW_PRE_REGISTER",
        this.selectRow,
      );
    },

    actionStickRow() {
      if (!this.form.selectCallToSector) {
        this.$refs.validator.validate();

        this.$toaster.warning("Verifique os campos e tente novamente.");
        return;
      }

      if (!this.form.selectCallToSector) {
        return;
      }

      this.isCalling = false;
      this.modalModalAnticipateServiceShow = true;
    },

    actionCloseModalDelete() {
      this.modalDeleteShow = false;
    },

    setReason(value) {
      this.reasonRemove = value;
    },

    async onAnticipateServiceMeet(obj) {
      clearTimeout(this.timeoutCall);
      await this.actionEndCallByVariable({
        set_id: this.form.selectPanel,
        set_id_destino: this.form.selectCallToSector,
        nome_paciente: this.selectRow.prc_nome,
        prc_id: this.selectRow.prc_id,
      });

      this.selectRow.anticipationReason = obj.reason;

      this.$store.commit(
        "Emergency/PreRegistration/SET_SELECT_ROW_PRE_REGISTER",
        this.selectRow,
      );

      const routeName = "emergency.pre-registration.bulletin";
      this.$router.push({ name: routeName });
    },

    onAnticipateServiceCall(obj) {
      const index = this.findIndexQueueBySelectRow();
      this.actionCall(index);
    },

    async onCloseModalAnticipateService() {
      clearTimeout(this.timeoutCall);
      await this.actionEndCallByVariable({
        set_id: this.form.selectPanel,
        set_id_destino: this.form.selectCallToSector,
        nome_paciente: this.selectRow.prc_nome,
        prc_id: this.selectRow.prc_id,
      });

      this.modalModalAnticipateServiceShow = false;
      this.reSearchFilter();
    },

    async actionRemoveItem() {
      try {
        this.$loader.start("Excluindo Pré-Registro selecionado...");
        const obj = {
          intIdPreCadastro: this.selectRow.prc_id,
          strObservacao: this.reasonRemove,
        };
        await this.$store.dispatch("Emergency/PreRegistration/REMOVE", obj);
        this.$toaster.success("Exclusão realizada com sucesso.");
        await this.$refs.RgSearchFilter.performSearch();
      } catch (err) {
        this.$toaster.error(err.message);
      } finally {
        this.$loader.finish();
      }
    },

    actionCallWithoutPanel(time) {
      setTimeout(() => {
        this.isCalling = false;
      }, time);
    },

    async actionCall(queueNumber) {
      this.isCalling = true;

      if (this.noPanel) {
        this.actionCallWithoutPanel(this.timeInMilliseconds);
      } else {
        try {
          await this.$store.dispatch(
            "Emergency/PreRegistration/CALL",
            this.getVariableCall(queueNumber),
          );
        } catch (e) {
          this.$toaster.error("Não foi possível comunicar com o painel.");
        } finally {
          this.timeoutCall = setTimeout(() => {
            this.actionEndCall(queueNumber);
          }, this.timeInMilliseconds);
        }
      }
    },

    async actionEndCall(queueNumber) {
      this.isCalling = false;

      if (!this.noPanel) {
        try {
          await this.$store.dispatch(
            "Emergency/PreRegistration/END_CALL",
            this.getVariableCall(queueNumber),
          );
        } catch (e) {
          this.$toaster.error("Não foi possível comunicar com o painel.");
        }
      }
    },

    async actionEndCallByVariable(variable) {
      if (!this.noPanel) {
        try {
          this.isCalling = false;
          await this.$store.dispatch(
            "Emergency/PreRegistration/END_CALL",
            variable,
          );
        } catch (e) {
          this.$toaster.error("Não foi possível comunicar com o painel.");
        }
      }
    },

    async actionCleanCall() {
      try {
        await this.$store.dispatch("Emergency/PreRegistration/CLEAN_CALL", {
          set_id: this.form.selectCallToSector,
        });
        this.$toaster.success(
          "Painel do setor correspondente limpo com sucesso.",
        );
      } catch (e) {
        this.$toaster.warning(
          "Painel do setor correspondente está sendo utilizado.",
        );
      } finally {
      }
    },

    openInsertPreRegister() {
      const routeName = "emergency.pre-registration.new";
      this.$router.push({ name: routeName });
    },

    openModalDelete() {
      this.modalDeleteShow = true;
    },

    addQueueNumber() {
      this.queueNumber++;
      if (this.queueNumber > this.mutableList.length) {
        this.queueNumber = 0;
      }
    },

    findIndexQueueBySelectRow() {
      const index = this.mutableList.findIndex((el) => {
        return el.prc_id === this.selectRow.prc_id;
      });
      return index;
    },

    getVariableCall(queueNumber) {
      const patient = this.mutableList[queueNumber];
      const variable = {
        set_id: this.form.selectPanel || 0,
        set_id_destino: this.form.selectCallToSector,
        nome_paciente: patient.prc_nome,
        prc_id: patient.prc_id,
      };
      return variable;
    },

    getCountValue(pValue) {
      this.total = Number(pValue) || 0;
    },

    async reSearchFilter() {
      try {
        if (this.$refs.smartTableGrid) {
          this.$refs.smartTableGrid.cleanSelectRow();
        }
        this.selectRow = null;
        await this.$refs.RgSearchFilter.performSearch();
      } catch (pError) {
        this.$toaster.error(pError);
      }
    },

    onChangeTogglePriorityFilter(value) {
      this.form.togglePriority = value;
    },

    changeCheckboxActive(pValue) {
      this.noPanel = pValue;

      if (pValue === true) {
        this.disableSelectPainel = true;
        this.requiredSelectPainel = false;
        this.$refs.selectPanel.cleanValidate();
      } else {
        this.disableSelectPainel = false;
        this.requiredSelectPainel = true;
      }
    },

    async searchFilter(pData) {
      this.$loader.start("Carregando dados da busca de pré-atendimento");
      const result = await this.$store.dispatch(
        "Emergency/PreRegistration/SEARCH_PRE_REGISTRATION",
        pData,
      );

      if (this.selectRow && this.$refs.smartTableGrid) {
        this.$refs.smartTableGrid.cleanSelectRow();
        this.selectRow = null;
      }

      this.$loader.finish();
      return result;
    },

    generateFilter() {
      const variables = {
        arrFormData: {
          set_id: this.form.sector,
          prc_nome: this.form.patientName ? this.form.patientName : null,
          periodoInicial: this.form.initialOrderPeriod,
          periodoFinal: this.form.finalOrderPeriod,
          prc_prioridade: this.form.togglePriority,
          uns_id: this.form.unit,
          smd_id: 167,

          entradaBoletim: false,
          horaFinal: "",
          horaInicial: "",
          prc_id: 0,
          prc_pressao_arterial_max: 0,
          prc_pressao_arterial_min: 0,
          prc_risco: 0,
          tipoDeRisco: 1,
        },
      };

      this.$store.commit(
        "Emergency/PreRegistration/SET_FILTER_EMERGENCY_PRE_REGISTER",
        this.form,
      );

      return variables;
    },

    async fillFilter() {
      try {
        const existFilterData = this.$store.getters[
          "Emergency/PreRegistration/GET_FILTER_EMERGENCY_PRE_REGISTER"
        ];

        if (existFilterData) {
          this.$loader.start("Carregando...");

          this.form = existFilterData;

          this.clearRowsSelected();

          await this.$refs.RgSearchFilter.performSearch();

          this.$loader.finish();
        }
      } catch (Err) {
        this.$toaster.warning("Erro ao carregar os dados da navegação", Err);
        this.$loader.finish();
      }
    },

    unselectAllList() {
      this.mutableList.forEach((item) => {
        if (this.selectedRows.includes(item.fil_id)) {
          this.selectedRows.splice(this.selectedRows.indexOf(item.fil_id), 1);
          this.listSelected.splice(this.listSelected.indexOf(item), 1);
        }
      });
    },

    viewClickPagination(pValue) {
      if (pValue) {
        this.unselectAllList();
      }
    },

    openModalPreServiceDetails() {
      this.showPreServiceDetails = true;
    },

    closeModalPreServiceDetails() {
      this.showPreServiceDetails = false;
    },

    clearRowsSelected() {
      this.listSelected = [];
      this.selectedRows = [];
    },

    actionCleanSelection() {
      this.selectedRows = [];
      this.listSelected = [];
    },

    cleanForm() {
      this.selectedRows = [];
      this.listSelected = [];
      this.mutableList = [];
      this.selectRow = null;
      this.form = this.$utils.obj.DeepCopy(FORM_FILTER);
      this.form.unit = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    },
  },
};
</script>
