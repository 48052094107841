import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query checkProcedureCbo(
    $procedure: String!
    $cbo: String!
    $competence: String
  ) {
    checkProcedureCbo(
      CO_PROCEDIMENTO: $procedure
      CO_OCUPACAO: $cbo
      DT_COMPETENCIA: $competence
    )
  }
`;

export default async ({ procedure, cbo, competence }) => {
  if (!procedure || !cbo) return true;

  const variables = {
    procedure,
    cbo,
    competence,
  };

  const { data } = await GraphQLClient.query({ query, variables }).catch(
    (err) => {
      console.error(err);
    },
  );

  return data.checkProcedureCbo;
};
