import LocalStorage from "~common/local-storage/LocalStorage";

const Print = (pData, width = 900, height = 800) => {
  const win = window.open("", "win", `width=${width},height=${height}`);
  win.document.open("text/html", "replace");
  win.document.write(pData);
  const elements = win.document.getElementsByClassName("logo");
  const clientLogo = LocalStorage.getObject("client-logo");
  if (elements.length > 0) {
    for (const item of elements) {
      item.src = clientLogo;
    }
  }
  win.document.close();
  setTimeout(() => {
    win.focus();
    win.print();
  }, 500);
};

export default Print;
