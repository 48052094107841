const mutations = {
  SET_SHIFTS: (state, shifts) => {
    state.shifts = shifts;
  },
  SET_PROPERTY_TYPES: (state, property_types) => {
    state.property_types = property_types;
  },
  SET_UNIT_HEALTHS: (state, unit_healths) => {
    state.unit_healths = unit_healths;
  },
  SET_FAMILY_INCOME_OPTIONS: (state, family_income_otpions) => {
    state.family_income_otpions = family_income_otpions;
  },
  SET_NATIONALITIES: (state, nationalities) => {
    state.nationalities = nationalities;
  },
  SET_LOGGED_EMPLOYEE: (state, login_details) => {
    state.logged_employee = login_details;
  },
  SET_TEAM_LINKED: (state, team_linked) => {
    state.team_linked = team_linked;
  },
};

export default mutations;
