import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  return AuthLegacyRequest.post(
    "tabelas/controller-prioridade-fila/ativar",
    variables,
  )
    .then(({ data }) => {
      return HandleResponse(data);
    })
    .catch((pErr) => {
      throw pErr;
    });
};
