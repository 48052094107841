import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation($surgery: SurgeryScheduleInputType) {
    ScheduleSurgery(surgery: $surgery) {
      cci_id
    }
  }
`;

export default (pStore, pSurgery) => {
  const variables = {
    surgery: pSurgery,
  };
  return new Promise((resolve, reject) => {
    GraphQLClient.mutate({ mutation, variables })
      .then((result) => {
        pStore.commit("CLEAN_SELECTION");
        return resolve(result.data);
      })
      .catch((err) => {
        return reject(err.graphQLErrors);
      });
  });
};
