<style src="./SurgeryObservation.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="surgery-observation">
    <textarea v-model="text" />
  </section>
</template>

<script>
export default {
  name: "SurgeryObservation",
  props: {
    value: {},
  },
  data() {
    return {
      text: "",
    };
  },
  watch: {
    value() {
      this.text = this.value;
    },
    text() {
      this.$emit("input", this.text);
    },
  },
};
</script>
