<template lang="html">
  <svg
    width="612"
    height="612"
    version="1.1"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Capsule-drug-medicine-pill-tablet" fill="#c0cadd">
      <path
        d="M60.717,25.3A1,1,0,0,0,60,25H35a1,1,0,0,0-1,1.028l.973,34.029a1.99,1.99,0,0,0,2,1.943H58.028a1.99,1.99,0,0,0,2-1.943L61,26.028A1,1,0,0,0,60.717,25.3ZM58.971,27l-.338,11.836a10.371,10.371,0,0,1-1.874-.739,11.272,11.272,0,0,0-10.191,0A8.333,8.333,0,0,1,42.33,39.12,8.32,8.32,0,0,1,38.087,38.1a13.621,13.621,0,0,0-1.762-.742L36.029,27Zm-22,33-.029-1H53V57H36.886l-.5-17.484c.283.12.557.249.834.382a10.353,10.353,0,0,0,5.11,1.222,10.349,10.349,0,0,0,5.1-1.22,9.281,9.281,0,0,1,8.46,0,11.357,11.357,0,0,0,2.681.974L58.115,57H55v2h3.058l-.029,1Z"
      />
      <rect x="38" y="29" width="3" height="4" />
      <rect x="38" y="35" width="3" height="2" />
      <path
        d="m20 29h-2v-2a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v2h-2a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h2v2a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-2h2a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1zm-1 4h-2a1 1 0 0 0-1 1v2h-2v-2a1 1 0 0 0-1-1h-2v-2h2a1 1 0 0 0 1-1v-2h2v2a1 1 0 0 0 1 1h2z"
      />
      <path
        d="m27 48a7 7 0 1 0 7 7 7.008 7.008 0 0 0-7-7zm0 12a5 5 0 1 1 5-5 5.006 5.006 0 0 1-5 5z"
      />
      <rect x="23" y="54" width="10" height="3" />
      <path
        d="M24.37,16.21l-1.19-.34A3.009,3.009,0,0,1,21,12.98V10h1a1,1,0,0,0,1-1V3a1,1,0,0,0-1-1H8A1,1,0,0,0,7,3V9a1,1,0,0,0,1,1H9v2.98a3.009,3.009,0,0,1-2.18,2.89l-1.19.34A5.022,5.022,0,0,0,2,21.02V45a3.009,3.009,0,0,0,3,3H7.08a6.993,6.993,0,0,0,1.97,5.95c.02.02.04.03.06.05H6a4,4,0,0,0,0,8h8a3.985,3.985,0,0,0,2.96-6.67,6.847,6.847,0,0,0,1.99-1.38A6.993,6.993,0,0,0,20.92,48H25a3.009,3.009,0,0,0,3-3V21.02A5.022,5.022,0,0,0,24.37,16.21ZM9,60H6a2,2,0,0,1,0-4H9ZM9,4H21V8H9Zm5,56H11V56h3a2,2,0,0,1,0,4Zm4.17-8.24L14,47.58,12.58,49l4.18,4.17a5,5,0,0,1-6.93-6.93l1.34,1.35,1.42-1.42-1.35-1.34a5,5,0,0,1,6.93,6.93ZM26,45a1,1,0,0,1-1,1H20.32a7.183,7.183,0,0,0-1.37-1.95,7,7,0,0,0-9.9,0A7.183,7.183,0,0,0,7.68,46H5a1,1,0,0,1-1-1V42H26Zm0-5H4V24H26Zm0-18H4v-.98a3.009,3.009,0,0,1,2.18-2.89l1.19-.34A5.022,5.022,0,0,0,11,12.98V10h8v2.98a5.022,5.022,0,0,0,3.63,4.81l1.19.34A3.009,3.009,0,0,1,26,21.02Z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: "PresentationType",
};
</script>
