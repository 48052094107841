import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query {
    hospitalizationSocialSecurityBond {
      rows {
        ivp_id
        ivp_descricao
        ivp_codigo
      }
    }
  }
`;

export default async (variables) => {
  const { data } = await GraphQLClient.query({ query, variables }).catch(
    (err) => {
      console.error(err);
    },
  );
  return data.hospitalizationSocialSecurityBond.rows;
};
