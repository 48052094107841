import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query($let_id_laboratoriais_pacientes_exames_bioslab: Int!) {
    SearchBioslabExamTags(
      let_id_laboratoriais_pacientes_exames_bioslab: $let_id_laboratoriais_pacientes_exames_bioslab
    ) {
      rows {
        let_id
        let_id_laboratoriais_pacientes_exames_bioslab
        let_codigo_etiqueta
        let_impressao
        let_impresso
      }
    }
  }
`;

export default async (
  { commit },
  { let_id_laboratoriais_pacientes_exames_bioslab },
) => {
  const { data } = await GraphQLClient.query({
    query,
    variables: { let_id_laboratoriais_pacientes_exames_bioslab },
    fetchPolicy: "no-cache",
  }).catch((err) => {
    throw err;
  });
  return data.SearchBioslabExamTags.count === 1
    ? data.SearchBioslabExamTags.rows[0]
    : data.SearchBioslabExamTags;
};
