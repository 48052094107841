import { DeepCopy } from "~utils/object";
import { BrazilianDateToDatabase } from "~utils/date";

const removeDashesAndDots = (pText) => {
  return pText ? pText.replace(/[^\d]/g, "") : null;
};

const personFormatter = (pData) => {
  const hasPersonData = pData.person && Object.keys(pData.person).length > 0;

  if (!hasPersonData) return;

  const data = DeepCopy(pData.person);

  const pleni_place_id = pData.patient ? pData.patient.pleni_place_id : null;
  const pes_empresa_pleni = pData.patient ? pData.patient.pleni_company : null;

  data.pes_id_locais_atendimento_pleni = pleni_place_id;
  data.pes_empresa_pleni = pes_empresa_pleni;

  const hasEmail = pData.contact && pData.contact.email;
  data.pes_email = hasEmail ? pData.contact.email : null;
  data.pes_nascimento = BrazilianDateToDatabase(data.pes_nascimento);

  delete data.sex_sigla;
  delete data.isNaturalized;
  delete data.nationality;
  delete data.naturality;

  return data;
};

const addressFormatter = (pData) => {
  const hasAddressData = pData.address && Object.keys(pData.address).length > 0;

  if (!hasAddressData) return;

  const data = DeepCopy(pData.address);
  const cep = data.end_cep.replace(/-/g, "");
  const publicPlaceTypeId = data.end_id_tipos_logradouros;

  data.tlg_id = publicPlaceTypeId;
  data.end_cep = cep;

  delete data.mun_nome;
  delete data.est_id;
  delete data.est_sigla;
  delete data.tlg_nome;
  delete data.end_id_tipos_logradouros;
  delete data.publicPlaceType;

  return data;
};

const documentFormatter = (pData) => {
  const hasDocumentsData =
    pData.documents && Object.keys(pData.documents).length > 0;
  const hasForeigner = pData.foreign && Object.keys(pData.foreign).length > 0;

  if (!hasDocumentsData && !hasForeigner) return;

  let data = {};

  if (hasDocumentsData) {
    data = { ...DeepCopy(pData.documents) };
  }

  if (hasForeigner) {
    data.foreigner = DeepCopy(pData.foreign);

    data.foreigner.est_chegada_brasil =
      BrazilianDateToDatabase(pData.foreign.est_chegada_brasil) || null;
    data.foreigner.est_naturalizacao =
      BrazilianDateToDatabase(pData.foreign.est_naturalizacao) || null;
    data.foreigner.est_passaporte = pData.foreign.est_passaporte || null;
  }

  return data;
};

const telephoneFormatter = (pData) => {
  const hasContact = pData.contact && Object.keys(pData.contact).length > 0;
  const hasTelephone = pData.contact.phones && pData.contact.phones.length > 0;

  if (!hasContact && !hasTelephone) return;

  const dataCopy = DeepCopy(pData.contact.phones);

  const data = dataCopy.map((telephone) => {
    delete telephone.tel_notification;
    delete telephone.nop_id;
    return telephone;
  });

  return data;
};

const notificationFormatter = (pData) => {
  let notifications = [];

  const hasTelephone = pData.contact.phones && pData.contact.phones.length > 0;

  if (hasTelephone) {
    const phones = DeepCopy(pData.contact.phones);

    const phonesWithNotification = phones.filter(
      (phone) => phone.tel_notification,
    );

    const hasPhoneNotification = phonesWithNotification.length > 0;

    if (hasPhoneNotification) {
      notifications = phonesWithNotification.map((phone) => ({
        nop_celular: removeDashesAndDots(phone.tel_numero),
        nop_id_ddds: phone.tel_id_ddds,
        nop_id: phone.nop_id,
      }));
    }
  }

  const hasAppNotification =
    pData.appNotification && pData.appNotification.cpf_numero;

  if (hasAppNotification) {
    notifications.push({
      nop_cpf: removeDashesAndDots(pData.appNotification.cpf_numero),
      nop_id: pData.appNotification.nop_id,
    });
  }

  return notifications;
};

const patientFormatter = (pData) => {
  const data = DeepCopy(pData.patient);
  delete data.pleni_place_id;
  delete data.pleni_company;

  data.uns_id = Number.isInteger(data.uns_id) ? data.uns_id : null;
  data.ppr_numero = Number.isInteger(Number(data.ppr_numero))
    ? Number(data.ppr_numero)
    : null;
  return data;
};

const DataDBFormatter = (pData) => {
  const person = personFormatter(pData);
  const address = addressFormatter(pData);
  const documents = documentFormatter(pData);
  const telephones = telephoneFormatter(pData);
  const notifications = notificationFormatter(pData);
  const patient = patientFormatter(pData);
  const attachments = pData.attachments;
  return {
    person,
    address,
    documents,
    telephones,
    notifications,
    patient,
    attachments,
  };
};

export { DataDBFormatter };
