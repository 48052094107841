<template lang="html">
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 35 35"
    style="enable-background: new 0 0 35 35"
    xml:space="preserve"
  >
    <g>
      <path
        d="M23.618,21.617l-1.479,0.001c0-4.83-3.927-8.759-8.757-8.761V11.38C19.025,11.381,23.618,15.973,23.618,21.617z
        M13.382,13.938v1.479c3.418-0.001,6.202,2.782,6.202,6.202l1.478-0.001C21.062,17.384,17.616,13.938,13.382,13.938z
        M13.382,16.574v1.479c1.966,0,3.565,1.6,3.565,3.566l1.479-0.001C18.426,18.837,16.162,16.574,13.382,16.574z M22.646,3.186
        L23.75,4.29l-2.404,2.405c4.439,1.586,7.632,5.82,7.632,10.797c0,6.328-5.148,11.477-11.478,11.477
        c-0.089,0-0.173-0.006-0.263-0.013v-2.14l-4.088,4.091L17.24,35v-2.299c0.086,0.008,0.17,0.015,0.259,0.015
        c8.393,0,15.221-6.83,15.221-15.224C32.721,10.909,28.512,5.305,22.646,3.186z M13.896,28.395
        c-4.566-1.516-7.873-5.817-7.873-10.887c0-6.328,5.148-11.477,11.478-11.477c0.088,0,0.174,0.006,0.261,0.013v2.14l4.089-4.091
        L17.758,0v2.299c-0.084-0.008-0.17-0.015-0.258-0.015c-8.395,0-15.221,6.83-15.221,15.224c0,6.544,4.16,12.123,9.972,14.268
        l-0.869-0.866L13.896,28.395z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "WifiSignal",
};
</script>
