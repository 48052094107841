import AuthRequest from "~common/request/AuthRequest";
import { BrazilianDateToDatabase } from "~utils/date";

/**
 * @param {Object} pStore
 * @param {Object} pInfo
 * @param {String} pInfo.cci_data_inicio
 * @param {String} pInfo.cci_hora_inicio
 * @param {String} pInfo.cci_duracao
 * @param {Number} pInfo.lca_id_locais_atendimento
 * @param {Number} pInfo.cci_id_setores
 * @param {Number} pInfo.uns_id O id da unidade de saúde caso nenhum passado utilizará o da unidade logada
 */
export default async (pStore, pInfo) => {
  pInfo.unitId = pInfo.unitId || pStore.getters["Login/GET_UNIT_HEALTH_ID"];
  pInfo.cci_data_inicio = BrazilianDateToDatabase(pInfo.cci_data_inicio);
  return new Promise((resolve, reject) => {
    AuthRequest.post("surgery-center/validate-surgery-conflicts", pInfo)
      .then((response) => {
        resolve(response);
      })
      .catch((pErr) => reject(pErr));
  });
};
