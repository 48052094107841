<template lang="html">
  <svg
    width="47"
    height="59"
    viewBox="0 0 47 59"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M42.3884 20.7402L45.1584 17.9703C45.6007 17.5279 45.6007 16.8106 45.1584 16.3682L41.6338 12.8437C41.1913 12.4013 40.4742 12.4013 40.0316 12.8437L37.2617 15.6136C34.6094 13.674 31.5371 12.2764 28.208 11.5876V8.60966H29.794C30.4195 8.60966 30.9268 8.10248 30.9268 7.47681V1.13285C30.9268 0.507177 30.4195 0 29.794 0H17.106C16.4805 0 15.9732 0.507177 15.9732 1.13285V7.47681C15.9732 8.10248 16.4805 8.60966 17.106 8.60966H18.692V11.5877C15.9975 12.1438 13.4151 13.171 11.063 14.637C10.5319 14.9679 10.3698 15.6666 10.7008 16.1976C11.0317 16.7285 11.7303 16.8908 12.2613 16.5599C15.6121 14.4715 19.4811 13.3676 23.45 13.3676C35.131 13.3676 44.6343 22.8709 44.6343 34.5519C44.6343 46.233 35.131 55.7362 23.45 55.7362C11.769 55.7362 2.2657 46.233 2.2657 34.5519C2.2657 30.583 3.36955 26.714 5.45785 23.3634C5.78887 22.8325 5.62664 22.1337 5.09567 21.8028C4.56471 21.4719 3.86608 21.6342 3.53517 22.165C1.22235 25.8755 0 30.1588 0 34.5519C0 47.4823 10.5195 58.0019 23.45 58.0019C36.3805 58.0019 46.9 47.4823 46.9 34.5519C46.9 29.392 45.2235 24.6171 42.3884 20.7402V20.7402ZM40.8327 15.2467L42.7552 17.1691L40.9564 18.968C40.3529 18.2909 39.711 17.6489 39.0339 17.0455L40.8327 15.2467ZM18.2389 2.2657H28.6611V6.34396H18.2389V2.2657ZM20.9577 11.234V8.60966H25.9423V11.2345C25.1231 11.1476 24.2918 11.1019 23.45 11.1019C22.6142 11.1019 21.7824 11.1465 20.9577 11.234Z"
      fill="white"
    />
    <path
      d="M7.68412 19.919C7.98206 19.919 8.27433 19.7978 8.48493 19.5871C8.69564 19.3764 8.81697 19.0841 8.81697 18.7862C8.81697 18.4882 8.69575 18.196 8.48493 17.9852C8.27433 17.7745 7.98206 17.6533 7.68412 17.6533C7.38618 17.6533 7.0939 17.7745 6.88319 17.9852C6.67248 18.196 6.55127 18.4882 6.55127 18.7862C6.55127 19.0841 6.67237 19.3764 6.88319 19.5871C7.09402 19.7978 7.38618 19.919 7.68412 19.919V19.919Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.5006 16.001C13.283 16.001 5 24.284 5 34.5016L23.5006 34.5016L23.5006 16.001ZM42.0013 34.5016C42.0013 44.719 33.7185 53.002 23.5011 53.0022C13.2835 53.0022 5.00048 44.7192 5.00048 34.5016L23.5007 34.5016V34.5016L42.0013 34.5016Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: "ScaleTypes",
};
</script>
