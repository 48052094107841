<template>
  <section class="form-base">
    <fieldset class="field-form">
      <legend v-if="!withoutTitle" class="title">{{ title }}</legend>
      <div class="form">
        <slot />
      </div>
    </fieldset>
  </section>
</template>

<script>
export default {
  name: "FormBase",
  components: {},
  props: {
    title: {
      type: String,
      default: "Titulo",
    },
    withoutTitle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
};
</script>
