<script>
import RgComboboxMixin from "~tokio/primitive/selection/mixin/RgComboboxMixin";
export default {
  name: "RgRemoteComboboxMixin",
  mixins: [RgComboboxMixin],
  props: {
    selectFirst: {
      type: Boolean,
      default: true,
    },
    defaultText: {
      type: String,
      default: "Selecione",
    },
    emptyDefaultText: {
      type: String,
      default: "Nenhum Item para Selecionar",
    },
  },
  data() {
    return {
      /**
       * @deprecated utilizar metodo doSearch no componente
       */
      model: null,
      /**
       * @deprecated utilizar metodo doSearch no componente
       */
      method: "search",
    };
  },
  computed: {
    filter() {
      return {};
    },
  },
  watch: {
    filter: function (pValue) {
      if (!this.filter) {
        this.setDefaultOption();
        return false;
      }
      this.getData().then(() => {
        if (this.mData.length === 0) {
          this.setDefaultOption();
        }
      });
    },
  },
  mounted() {
    if (this.filter) {
      this.getData().then(() => {
        if (this.selectFirst && this.showDefaultOption === false) {
          this.selectedItem = this.mData[0].value;
        }
      });
    } else {
      this.setDefaultOption();
    }
  },
  methods: {
    getData() {
      return this.getDataFromRemote();
    },
    getDataFromRemote() {
      let promise = null;
      if (this.model) {
        /**
         * @deprecated utilizar somente this.doSearch
         */
        promise = this.model[this.method](this.filter).then((pRes) => {
          return pRes.data;
        });
      } else {
        promise = this.doSearch();
      }
      // no futuro remover esta verificação e substituir por this.doSearch
      return promise
        .then((response) => {
          this.fillData(response);
        })
        .catch(() => {
          this.resetCombobox();
        });
    },
    doSearch() {
      throw new Error("You need to override doSearch");
    },
    fillData(data) {
      if (data.length <= 0) {
        this.resetCombobox();
      }
      this.mData = data.map((item) => {
        return this.formatItem(item);
      });
      this.populateList();

      if (this.value) {
        this.selectedItem = this.value;
      }
    },
    setDefaultOption() {
      if (this.showDefaultOption === true) {
        this.selectedItem = this.defaultValue;
      } else if (this.selectFirst === true) {
        this.selectedItem = null;
      }
    },
    formatItem(item) {
      return item;
    },
    resetCombobox() {
      this.mData = [];

      // #Arthur: Comentei pois está gerando conflitos impedindo o forceSelection em suggest,
      // fiz uma analise, e no momento não teve impacto na logica das combobox.

      // this.$emit("input", this.optionDefaultValue);
    },
  },
};
</script>
