import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query(
    $let_id: Int
    $leb_id: Int
    $let_id_laboratoriais_pacientes_exames_bioslab: Int
  ) {
    SearchBioslabTagInfo(
      let_id: $let_id
      leb_id: $leb_id
      let_id_laboratoriais_pacientes_exames_bioslab: $let_id_laboratoriais_pacientes_exames_bioslab
    ) {
      rows {
        leb_id
        let_id
        let_id_laboratoriais_pacientes_exames_bioslab
        let_codigo_etiqueta
        let_impressao
        let_impresso
        lpe_id
        lpe_codigo_sigtap
        lpe_codigo_exame_bioslab_mne
        lpe_material_biologico
        leb_id_pacientes
        leb_nome_paciente
      }
    }
  }
`;

export default async (
  { commit },
  { let_id, leb_id, let_id_laboratoriais_pacientes_exames_bioslab },
) => {
  const variables = {
    let_id,
    leb_id,
    let_id_laboratoriais_pacientes_exames_bioslab,
  };
  const { data } = await GraphQLClient.query({
    query,
    variables,
    fetchPolicy: "no-cache",
  }).catch((err) => {
    throw err;
  });
  return data.SearchBioslabTagInfo.count === 1
    ? data.SearchBioslabTagInfo.rows[0]
    : data.SearchBioslabTagInfo.rows;
};
