<template>
  <div class="modal-patient-transfer-exchange">
    <RgBaseModal
      v-shortkey="['esc']"
      :show="show"
      :size="modalSize"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <h1 class="title">Transferência ou Permuta de Paciente</h1>
      </div>

      <div slot="body" class="body">
        <div class="information">
          <RgBedStatus
            :title="getSituation === 2 ? 'Reserva' : 'Ocupado'"
            :generate-situation-status="getSituation"
            :is-active="hospitalization ? hospitalization.inl_habilitado : null"
            class="status"
          />

          <div class="grid">
            <span class="data">
              Unidade de Saúde:
              <strong>
                {{
                  hospitalization ? hospitalization.uns_nome : "Não informado"
                }}
              </strong>
            </span>

            <span class="data">
              Local de Atendimento:
              <strong>
                {{
                  hospitalization ? hospitalization.lca_nome : "Não informado"
                }}
              </strong>
            </span>

            <span class="data">
              Número da Internação:
              <strong>
                {{
                  hospitalization ? hospitalization.int_numero : "Não informado"
                }}
              </strong>
            </span>

            <span class="data">
              Setor:
              <strong>
                {{
                  hospitalization ? hospitalization.set_nome : "Não informado"
                }}
              </strong>
            </span>

            <span class="data">
              Leito:
              <strong>
                {{
                  hospitalization ? hospitalization.inl_nome : "Não informado"
                }}
              </strong>
            </span>

            <span class="data">
              Paciente:
              <strong>
                {{ namePatient(item) }}
              </strong>
            </span>
          </div>
        </div>

        <div class="button">
          <button
            :class="{ 'active unselect': isTransfer }"
            :disabled="disabledTransfer"
            class="header-button"
            @click="activeTranfer"
          >
            <span> Transferência </span>
          </button>

          <button
            :class="{ 'active unselect': isExchange }"
            :disabled="disabledExchange"
            class="header-button"
            @click="activeExchange"
          >
            <span> Permuta </span>
          </button>
        </div>

        <div class="search">
          <RgComboboxSector
            v-model="form.set_id"
            :permission="SECTOR_PERMISSION"
            :unit-health="
              hospitalization && hospitalization.uns_id
                ? hospitalization.uns_id
                : null
            "
            label="Setor"
            default-text="Todos"
            empty-default-text="Nenhum setor disponível"
            class="sector"
          />

          <RgComboboxPlace
            v-model="form.lca_id"
            :disabled="disableLocale"
            :class="{ 'disable unselect': disableLocale }"
            :sector="form.set_id"
            default-text="Todos"
            empty-default-text="Nenhum local disponível"
            class="place"
          />

          <RgSuggestTypeBed
            v-show="transfer"
            v-model="bedType"
            class="type-bed"
            @selected="selectingBedType"
          />

          <RgInput
            v-show="exchange"
            v-model="form.int_numero"
            label="Internação"
            title="Número da Internação"
            class="number"
          />

          <RgInput
            v-show="exchange"
            v-model="form.patient"
            label="Nome do Paciente"
            title="Nome do Paciente"
            placeholder="Informe o Nome"
            data-id="paciente"
            data-item="paciente"
            class="patient"
          />

          <div class="action unselect">
            <RgCleanButton
              small
              data-id="limpar"
              class="clean"
              title="Limpar"
              type="button"
              @click="clearForm"
            />

            <RgSearchButton
              ref="buttonSearch"
              v-shortkey="['enter']"
              data-id="buscar"
              label="Buscar"
              title="Buscar"
              @shortkey.native="searchForm(true)"
              @submit="searchForm(true)"
            />
          </div>
        </div>

        <SmartTable
          ref="smartTable"
          name="ModalPatientTransferExchange"
          :initial-columns="4"
          :item-per-page="pagination.limit"
          :columns="columnsSelector"
          :body="items"
          :total="totalItems"
          :max-register="5"
          @getLine="getValueRow"
          @pagination="getPagination"
        />
      </div>

      <div slot="footer" class="footer">
        <div class="action unselect">
          <RgConfirmButton
            ref="save"
            :disable="disableConfirm"
            :class="{ disable: disableConfirm }"
            :title="confirmButtonTitle"
            type="button"
            medium
            @click="manager"
          />
        </div>
      </div>
    </RgBaseModal>

    <div v-if="modalConfirmTransfer">
      <ModalConfirmTransfer
        :show="modalConfirmTransfer"
        :transfer="isTransfer"
        :information="bedDataObject"
        :status="item.isl_nome || ''"
        @reSearch="reSearchAfterChangeFlow"
        @close="closeModalBedSituation"
      />
    </div>
    <div v-if="modalConfirmExchange">
      <ModalConfirmExchange
        :show="modalConfirmExchange"
        :exchange="isExchange"
        :information="bedDataObject"
        @reSearch="reSearchAfterChangeFlow"
        @close="closeModalConfirmExchange"
      />
    </div>
  </div>
</template>
<script>
import SmartTable from "~tokio/foundation/smart-table/SmartTable";
import RgBedStatus from "$hospitalization/common/component/status/rg-bed-status/RgBedStatus";
import RgSuggestTypeBed from "$hospitalization/common/component/suggest/rg-suggest-type-bed/RgSuggestTypeBed";
import ModalConfirmTransfer from "$hospitalization/common/component/modal/modal-confirm-transfer/ModalConfirmTransfer";
import ModalConfirmExchange from "$hospitalization/common/component/modal/modal-confirm-exchange/ModalConfirmExchange";

import {
  RgBaseModal,
  RgInput,
  RgSearchButton,
  RgCleanButton,
  RgConfirmButton,
  RgComboboxSector,
  RgComboboxPlace,
} from "~tokio/primitive";

export default {
  name: "ModalHistoryTransfer",
  components: {
    RgBaseModal,
    SmartTable,
    RgBedStatus,
    RgInput,
    RgSearchButton,
    RgCleanButton,
    RgConfirmButton,
    RgComboboxSector,
    RgComboboxPlace,
    RgSuggestTypeBed,
    ModalConfirmTransfer,
    ModalConfirmExchange,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      modalSize: {
        minWidth: 87,
        maxWidth: 87,
        maxHeight: 36,
      },
      transfer: true,
      exchange: false,
      bedType: "",
      form: {
        set_id: null,
        lca_id: null,
        int_numero: null,
        patient: null,
        itl_id: null,
      },
      pagination: {
        limit: 5,
        offset: 0,
        current: 1,
      },
      items: [],
      totalItems: 0,
      originBedData: {},
      destinyBedData: {},
      bedDataObject: {},
      disableConfirm: true,
      modalConfirmTransfer: false,
      modalConfirmExchange: false,
      hospitalization: null,
    };
  },
  computed: {
    isTransfer() {
      return this.transfer;
    },

    isExchange() {
      return this.exchange;
    },

    disabledTransfer() {
      return false;
    },

    disabledExchange() {
      return false;
    },

    disableLocale() {
      return !this.form.set_id;
    },

    columnsSelector() {
      if (this.isTransfer) {
        return this.COLUMNSTRANSFER;
      } else if (this.isExchange) {
        return this.COLUMNSEXCHANGE;
      }
      return false;
    },

    getSituation() {
      const exist = this.hospitalization && this.hospitalization.isl_id;
      return exist ? this.hospitalization.isl_id : 0;
    },

    confirmButtonTitle() {
      return this.transfer ? "Confirmar Transferência" : "Confirmar Permuta";
    },
  },
  watch: {
    show(pValue) {
      if (!pValue) {
        this.close();
      }
    },

    item(pValue) {
      if (!pValue) {
        this.close();
      }

      this.hospitalization = pValue;
      this.clearForm();
      this.searchForm();
    },

    "form.set_id"(pValue, pPrev) {
      if (!pValue || pValue !== pPrev) {
        this.form.lca_id = null;
      }
    },
  },
  created() {
    this.SECTOR_PERMISSION = 372;

    this.COLUMNSTRANSFER = [
      { name: "Setor", key: "set_nome" },
      { name: "Local de Atendimento", key: "lca_nome" },
      { name: "Leito", key: "inl_nome" },
      { name: "Leito Extra", key: "inl_extra" },
      { name: "Leito de Observação", key: "inl_observacao" },
    ];

    this.COLUMNSEXCHANGE = [
      { name: "Internação", key: "int_numero" },
      { name: "Paciente", key: "paciente" },
      { name: "Setor", key: "set_nome" },
      { name: "Local de Atendimento", key: "lca_nome" },
      { name: "Leito", key: "inl_nome" },
      { name: "Leito Extra", key: "leito_extra" },
      { name: "Leito de Observação", key: "leito_observacao" },
    ];
  },
  methods: {
    async searchForm(submitFromButton = false) {
      try {
        if (this.show) {
          this.$refs.buttonSearch.actionSubmit();

          if (submitFromButton) {
            this.$refs.smartTable.resetLimitOffset();
          }

          const unitHealth = this.hospitalization
            ? this.hospitalization.uns_id
            : null;

          const params = {
            uns_id: unitHealth,
            set_id: this.form.set_id,
            lca_id: this.form.lca_id,
            itl_id: this.form.itl_id,
            limit: this.pagination.limit,
            offset: this.pagination.offset,
          };

          this.form.set_id === false
            ? (params.set_id = null)
            : (params.set_id = this.form.set_id);
          this.form.lca_id === false
            ? (params.lca_id = null)
            : (params.lca_id = this.form.lca_id);

          if (this.transfer) {
            const { rows, count } = await this.$store.dispatch(
              "Hospitalization/SEARCH_CUSTOM_PATIENT_TRANSFER",
              params,
            );

            this.items = rows;
            this.totalItems = count;
          }

          if (this.exchange) {
            const { rows, count } = await this.$store.dispatch(
              "Hospitalization/SEARCH_CUSTOM_PATIENT_EXCHANGE",
              {
                int_id: this.hospitalization.int_id,
                int_numero: parseInt(this.form.int_numero),
                paciente: this.form.patient,
                ...params,
              },
            );

            this.items = rows;
            this.totalItems = count;
          }
          this.$refs.buttonSearch.actionDone();
        }
      } catch (pErr) {
        this.$refs.buttonSearch.fail();
        console.error("pErr", pErr);
      }
    },

    reSearchAfterChangeFlow() {
      this.close();
      this.$emit("reSearch");
    },

    getValueRow(pItem) {
      this.disableConfirm = false;
      this.bedDataObject = {
        origin: (this.originBedData = this.hospitalization),
        destiny: (this.destinyBedData = pItem),
      };
    },

    getPagination(pPagination) {
      this.pagination = pPagination;
      this.searchForm();
    },

    selectingBedType(pValue) {
      this.form.itl_id = pValue.source ? pValue.source.itl_id : null;
    },

    activeTranfer() {
      this.transfer = true;
      this.exchange = false;
      this.clearForm();
      this.searchForm();
    },

    activeExchange() {
      this.transfer = false;
      this.exchange = true;
      this.clearForm();
      this.searchForm();
    },

    manager() {
      if (this.isTransfer) {
        this.showModalBedSituation();
      } else {
        this.showModalConfirmExchange();
      }
    },

    namePatient(pValue) {
      if (!pValue) {
        return "NÃO INFORMADO";
      }

      if (pValue.paciente) {
        // Modal na busca de Internação
        if (pValue.paciente !== "INDIGENTE") {
          return pValue.paciente;
        }
        if (pValue.nome_paciente === "INDIGENTE") {
          return "PACIENTE NÃO IDENTIFICADO";
        }
      }

      if (pValue.nome_paciente) {
        // Modal na busca de Leitos
        if (pValue.nome_paciente !== "INDIGENTE") {
          return pValue.nome_paciente;
        }
        if (pValue.nome_paciente === "INDIGENTE") {
          return "PACIENTE NÃO IDENTIFICADO";
        }
      }
    },

    showModalBedSituation() {
      this.modalConfirmTransfer = true;
    },

    closeModalBedSituation() {
      this.modalConfirmTransfer = false;
    },

    showModalConfirmExchange() {
      this.modalConfirmExchange = true;
    },

    closeModalConfirmExchange() {
      this.modalConfirmExchange = false;
    },

    clearSetting() {
      this.bedDataObject = {};
      this.items = [];
      this.totalItems = 0;
      this.pagination.limit = 5;
      this.pagination.offset = 0;
      this.pagination.current = 1;
      this.disableConfirm = true;
    },

    clearForm() {
      this.clearSetting();
      this.bedType = "";

      this.form = {
        set_id: null,
        lca_id: null,
        int_numero: null,
        patient: null,
        itl_id: null,
      };
      this.searchForm();
    },

    close() {
      this.clearForm();
      this.hospitalization = null;
      this.transfer = true;
      this.exchange = false;
      this.$refs.smartTable.cleanSelectRow();
      this.$emit("close");
    },
  },
};
</script>
