import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query {
    hospitalizationNeonatalExits {
      rows {
        isn_id
        isn_descricao
        isn_codigo
      }
    }
  }
`;

export default async (variables) => {
  const { data } = await GraphQLClient.query({ query, variables }).catch(
    (err) => {
      console.error(err);
    },
  );
  return data.hospitalizationNeonatalExits.rows;
};
