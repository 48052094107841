import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation UpdateEmployeeRelationshipSector(
    $employeeRelationshipSectorId: Int
    $employeeRelationshipSector: EmployeeRelationshipSectorInputType
  ) {
    UpdateEmployeeRelationshipSector(
      employeeRelationshipSectorId: $employeeRelationshipSectorId
      employeeRelationshipSector: $employeeRelationshipSector
    ) {
      vus_id
      vus_carga_horaria_setores
    }
  }
`;

export default async (store, variables) => {
  try {
    const { data } = await GraphQLClient.mutate({ mutation, variables });
    return data.UpdateEmployeeRelationshipSector;
  } catch (err) {
    return Promise.reject(err.graphQLErrors[0]);
  }
};
