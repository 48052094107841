<style lang="scss" src="./RgListConditionedModal.scss" scoped></style>
<template>
  <div v-if="show" class="rg-add-conditioned-modal">
    <RgBaseModal :show="show" @close="close">
      <div slot="icon" class="icon">
        <IconCalendar class="icon-danger" />
      </div>

      <div slot="header" class="confirm-header">
        <h3>Cirurgia condicionada</h3>
        <h3>
          {{ surgeriesDetails[0].initialDate }} -
          {{ surgeriesDetails[0].initialHour }}
        </h3>
      </div>

      <div slot="body" class="confirm-body">
        <p>Selecione na listagem abaixo qual cirurgia deseja editar</p>

        <div class="surgery-data">
          <ul class="surgery-list-container">
            <li class="surgery-list-item">
              <ul class="list-data">
                <li class="list-header list--main" />
                <li class="list-header list--code">Nº Cirurgia</li>
                <li class="list-header list--duration">Duração</li>
                <li class="list-header list--patient">Paciente</li>
                <li class="list-header list--doc">Médico Solicitante</li>
                <li class="list-header list--sector">Clínica Solicitante</li>
              </ul>
            </li>
            <li
              v-for="(surgeryDetail, idx) in surgeriesDetails"
              :key="idx"
              class="surgery-list-item"
              @click="selectSurgery(surgeryDetail.id)"
            >
              <ul class="list-data">
                <li
                  :title="surgeryDetail.surgeryType | titleOfSurgery"
                  class="list-item list--main"
                >
                  <span>{{ surgeryDetail.surgeryType | typeOfSurgery }}</span>
                </li>
                <li
                  :title="surgeryDetail.surgeryCode"
                  class="list-item list--code"
                >
                  {{ surgeryDetail.surgeryCode }}
                </li>
                <li
                  :title="surgeryDetail.duration"
                  class="list-item list--duration"
                >
                  {{ surgeryDetail.duration }}
                </li>
                <li :title="surgeryDetail.name" class="list-item list--patient">
                  {{ surgeryDetail.name }}
                </li>
                <li :title="surgeryDetail.medic" class="list-item list--doc">
                  {{ surgeryDetail.medic }}
                </li>
                <li
                  :title="surgeryDetail.clinic"
                  class="list-item list--sector"
                >
                  {{ surgeryDetail.clinic }}
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      <div slot="footer" class="confirm-actions-footer">
        <button class="btn btn-default" @click.stop.prevent="close">
          Fechar
        </button>
      </div>
    </RgBaseModal>
  </div>
</template>

<script type="text/javascript">
import { RgBaseModal, IconCalendar } from "~tokio/primitive";
const surgeryTypes = [
  { abrev: "R", title: "Cirurgia de rotina" },
  { abrev: "C", title: "Cirurgia condicionada" },
];
export default {
  name: "RgAddConditionedModal",
  components: {
    RgBaseModal,
    IconCalendar,
  },
  filters: {
    typeOfSurgery: (pType) => {
      const retTypeOfSurgery = pType > -1 ? surgeryTypes[pType - 1].abrev : "";
      return retTypeOfSurgery;
    },
    titleOfSurgery: (pType) => {
      const retTypeOfSurgery = pType > -1 ? surgeryTypes[pType - 1].title : "";
      return retTypeOfSurgery;
    },
    formatTime: (pTime) => {
      const formatedTime = pTime.slice(0, 5);
      return formatedTime;
    },
  },
  props: {
    show: {
      type: Boolean,
      default: undefined,
    },
    surgeriesDetails: {
      default: null,
    },
  },
  data() {
    return {};
  },
  methods: {
    selectSurgery(pSurgeryId) {
      const item = {
        cci_id: pSurgeryId,
        cci_id_setores: this.$store.state.SurgeryCenter.Surgery.calendar.sector,
      };
      this.$store.dispatch("SurgeryCenter/Surgery/editSurgery", { item });
      this.$emit("close");
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
