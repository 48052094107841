<template lang="html">
  <svg
    width="512pt"
    height="512pt"
    version="1.1"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="#c0cadd">
      <path
        d="m202 452c-2.6289 0-5.2109 1.0703-7.0703 2.9297-1.8594 1.8594-2.9297 4.4414-2.9297 7.0703s1.0703 5.2109 2.9297 7.0703c1.8594 1.8594 4.4414 2.9297 7.0703 2.9297s5.2109-1.0703 7.0703-2.9297c1.8594-1.8594 2.9297-4.4414 2.9297-7.0703s-1.0703-5.2109-2.9297-7.0703c-1.8594-1.8594-4.4414-2.9297-7.0703-2.9297z"
      />
      <path
        d="m501.32 136.32c-14.211-14.211-37.332-14.211-51.543 0l-45.781 45.781v-148.1c0-18.746-15.254-34-34-34h-336c-18.746 0-34 15.254-34 34v444c0 18.746 15.254 34 34 34h336c18.746 0 34-15.254 34-34v-192.81l97.324-97.328c14.211-14.211 14.211-37.332 0-51.543zm-117.32-102.32v168.1l-20 20v-172.1c0-5.5234-4.4766-10-10-10h-66.832v-20h82.832c7.7188 0 14 6.2812 14 14zm-54.602 274.25 13.5-30.328 16.828 16.828zm-62.23-288.25v40.75c0 6.2031-5.0469 11.25-11.25 11.25h-107.84c-6.2031 0-11.25-5.0469-11.25-11.25v-40.75zm102.83 472h-336c-7.7188 0-14-6.2812-14-14v-444c0-7.7188 6.2812-14 14-14h82.832v20h-66.832c-5.5234 0-10 4.4766-10 10v412c0 5.5234 4.4766 10 10 10h112c5.5234 0 10-4.4766 10-10s-4.4766-10-10-10h-102v-392h56.832v0.75c0 17.23 14.02 31.25 31.25 31.25h107.84c17.23 0 31.25-14.02 31.25-31.25v-0.75h56.832v182.1l-11.391 11.387c-0.86328 0.86719-1.5625 1.8867-2.0625 3.0039l-30.016 67.418c-1.6836 3.7852-0.86328 8.2109 2.0625 11.141 1.918 1.9141 4.4766 2.9297 7.0742 2.9297 1.375 0 2.7578-0.28125 4.0664-0.86328l30.266-13.477v128.36h-102c-5.5234 0-10 4.4766-10 10s4.4766 10 10 10h112c5.5195 0 10-4.4766 10-10v-147.27l17.152-7.6367c1.0547-0.46875 2.0117-1.1211 2.8477-1.9219v172.82c0 7.7188-6.2812 14-14 14zm79.91-281.01-72.824 72.828-23.262-23.262 90.168-90.168 23.262 23.262zm37.273-37.27-5.7891 5.7852-23.258-23.258 5.7852-5.7852c6.4141-6.4141 16.848-6.4141 23.262 0 6.4102 6.4102 6.4102 16.844 0 23.258z"
      />
      <path
        d="m162 120h-64c-5.5234 0-10 4.4766-10 10v64c0 5.5234 4.4766 10 10 10h64c5.5234 0 10-4.4766 10-10v-64c0-5.5234-4.4766-10-10-10zm-10 64h-44v-44h44z"
      />
      <path
        d="m162 230h-64c-5.5234 0-10 4.4766-10 10v64c0 5.5234 4.4766 10 10 10h64c5.5234 0 10-4.4766 10-10v-64c0-5.5234-4.4766-10-10-10zm-10 64h-44v-44h44z"
      />
      <path
        d="m162 340h-64c-5.5234 0-10 4.4766-10 10v64c0 5.5234 4.4766 10 10 10h64c5.5234 0 10-4.4766 10-10v-64c0-5.5234-4.4766-10-10-10zm-10 64h-44v-44h44z"
      />
      <path
        d="m233.58 191.64c1.9531 1.9531 4.5117 2.9297 7.0703 2.9297 2.5586 0 5.1172-0.97656 7.0703-2.9297l45.148-45.148c3.9023-3.9023 3.9023-10.234 0-14.141-3.9062-3.9062-10.238-3.9062-14.145 0l-38.074 38.074-15.176-15.176c-3.9062-3.9062-10.234-3.9062-14.145 0-3.9023 3.9062-3.9023 10.238 0 14.141z"
      />
      <path
        d="m233.58 301.64c1.875 1.875 4.418 2.9297 7.0703 2.9297 2.6523 0 5.1953-1.0547 7.0703-2.9297l45.148-45.145c3.9023-3.9062 3.9023-10.238 0-14.145-3.9062-3.9023-10.238-3.9023-14.145 0l-38.074 38.078-15.176-15.176c-3.9062-3.9062-10.234-3.9062-14.145 0-3.9023 3.9023-3.9023 10.234 0 14.141z"
      />
      <path
        d="m252.1 396.14 15.504 15.5c1.9531 1.9531 4.5117 2.9297 7.0703 2.9297s5.1172-0.97656 7.0703-2.9297c3.9062-3.9062 3.9062-10.238 0-14.141l-15.5-15.504 15.5-15.504c3.9062-3.9023 3.9062-10.234 0-14.141s-10.234-3.9062-14.141 0l-15.504 15.5-15.5-15.5c-3.9062-3.9023-10.234-3.9062-14.145 0-3.9023 3.9062-3.9023 10.238 0 14.141l15.504 15.504-15.504 15.504c-3.9023 3.9023-3.9023 10.234 0 14.141 1.9531 1.9531 4.5156 2.9297 7.0742 2.9297s5.1172-0.97656 7.0703-2.9297z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: "Request",
};
</script>
