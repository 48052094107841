import BaseModel from "~common/model/BaseModel";
import Store from "@/store";
class PatientModel extends BaseModel {
  defineEndPointURI() {
    return "surgery-center/patient";
  }

  recoverRemainingInfo(id) {
    const unitHealth = Store.getters["Login/GET_UNIT_HEALTH_ID"];
    return this._request.get(
      `surgery-center/patient-remaining-info/${id}?uns_id=${unitHealth}`,
    );
  }

  async patientRemainingInfo(id) {
    return this.recoverRemainingInfo(id)
      .then((response) => {
        const emptyMessage = "Não informado";
        const patientInfo = {};
        patientInfo.cns = response.data.crs_numero || emptyMessage;
        patientInfo.bed = response.data.inl_nome
          ? response.data.inl_nome + " - " + response.data.itl_nome
          : emptyMessage;
        patientInfo.bed_sector = response.data.inl_nome
          ? response.data.leito_set_nome
          : emptyMessage;
        patientInfo.bed_number = response.data.int_numero
          ? response.data.int_numero
          : emptyMessage;
        patientInfo.bed_place = response.data.leito_lca_nome
          ? response.data.leito_lca_nome
          : emptyMessage;
        patientInfo.unitRecordNumber = response.data.unitRecordNumber
          ? response.data.unitRecordNumber
          : emptyMessage;
        return patientInfo;
      })
      .catch((pErr) => {
        console.error(pErr);
      });
  }
}
export default new PatientModel();
