<style src="./EffectivenessAnesthesia.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="effectiveness-anesthesia">
    <section class="row">
      <RgInputDate
        v-model="form.ane_data_anestesia"
        label="Data"
        class="col-3"
      />
      <RgInputHour
        v-model="form.ane_hora_anestesia"
        label="Hora"
        class="col-3"
      />
      <RgInput v-model="form.ane_peso" label="Peso" class="col-3" />
      <RgInput v-model="form.ane_altura" label="Altura" class="col-3" />
    </section>

    <section class="row">
      <div class="related-field col-6">
        <span class="label">Anestesia</span>
        <textarea v-model="form.ane_anestesia" class="input-textarea" />
      </div>
      <div class="related-field col-6">
        <span class="label">Risco</span>
        <textarea v-model="form.ane_risco" class="input-textarea" />
      </div>
    </section>

    <section class="row" />

    <section class="row">
      <div class="related-field col-6">
        <span class="label">Técnica</span>
        <textarea v-model="form.ane_tecnica" class="input-textarea" />
      </div>
      <div class="related-field col-6">
        <span class="label">Suporte Ventilatório</span>
        <textarea
          v-model="form.ane_suporte_ventilatorio"
          class="input-textarea"
        />
      </div>
    </section>

    <section class="row">
      <div class="related-field col-6">
        <span class="label">Analgesias</span>
        <textarea v-model="form.ane_analgesias" class="input-textarea" />
      </div>
      <div class="related-field col-6">
        <span class="label">Ocorrências</span>
        <textarea v-model="form.ane_ocorrencias" class="input-textarea" />
      </div>
    </section>
  </section>
</template>
<script>
import {
  RgInput,
  RgInputHour,
  RgInputDate,
  RgValidatorForm,
} from "~tokio/primitive";

export default {
  name: "EffectivenessAnesthesia",
  components: { RgInput, RgInputHour, RgInputDate },
  extends: { ...RgValidatorForm },
  props: {
    value: {
      default: null,
    },
  },
  data() {
    return {
      form: {
        ane_data_anestesia: null,
        ane_hora_anestesia: null,
        ane_peso: null,
        ane_altura: null,
        ane_anestesia: null,
        ane_risco: null,
        ane_tecnica: null,
        ane_suporte_ventilatorio: null,
        ane_analgesias: null,
        ane_ocorrencias: null,
      },
    };
  },
  computed: {
    validValue() {
      return this.validate();
    },
  },
  watch: {
    form: {
      handler: function (pValue) {
        this.$emit("input", this.form);
      },
      deep: true,
    },
    value(pValue, pOldValue) {
      this.form = pValue;
    },
  },
  methods: {
    cleanForm() {
      this.form = {
        ane_data_anestesia: null,
        ane_hora_anestesia: null,
        ane_peso: null,
        ane_altura: null,
        ane_anestesia: null,
        ane_risco: null,
        ane_tecnica: null,
        ane_suporte_ventilatorio: null,
        ane_analgesias: null,
        ane_ocorrencias: null,
      };
    },
  },
};
</script>
