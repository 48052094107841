<style src="./EffectivenessPostAnesthesia.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="effectiveness-post-anesthesia">
    <section class="row">
      <RgInputDate
        v-model="form.poa_data_pos_anestesico"
        label="Data"
        class="input-textarea col-3"
      />
      <RgInputHour
        v-model="form.poa_hora_pos_anestesico"
        label="Hora"
        class="input-textarea col-3"
      />
      <RgInput v-model="form.poa_pa" label="PA" class="col-3" />
      <RgInput v-model="form.poa_pulso" label="Pulso" class="col-3" />
    </section>
    <section class="row">
      <RgComboboxRespiration
        v-model="form.poa_id_respiracoes"
        label="Respiração"
        class="col-4"
      />
      <RgComboboxColoring
        v-model="form.poa_id_coloracoes"
        label="Coloração"
        class="col-4"
      />
      <RgComboboxCirculation
        v-model="form.poa_id_circulacoes"
        label="Circulação"
        class="col-4"
      />
    </section>

    <section class="row">
      <RgComboboxMobility
        v-model="form.poa_id_mobilidades"
        label="Mobilidade"
        class="col-6"
      />
      <RgComboboxConscience
        v-model="form.poa_consciencia"
        label="Consciencia"
        class="col-6"
      />
    </section>

    <section class="related-info row">
      <section class="related-field col-6">
        <span class="label">Anestesia</span>
        <textarea v-model="form.poa_anestesia" class="input-textarea" />
      </section>

      <section class="related-field col-6">
        <span class="label">Analgesia</span>
        <textarea v-model="form.poa_analgesia" class="input-textarea" />
      </section>
    </section>

    <section class="row">
      <section class="related-field col-6">
        <span class="label">PVC</span>
        <textarea v-model="form.poa_pvc" class="input-textarea" />
      </section>

      <section class="related-field col-6">
        <span class="label">Perdas</span>
        <textarea v-model="form.poa_perdas" class="input-textarea" />
      </section>
    </section>

    <section class="row">
      <section class="related-field col-12">
        <span class="label">Ganhos</span>
        <textarea v-model="form.poa_ganhos" class="input-textarea" />
      </section>
    </section>

    <section class="row command-panel">
      <button type="button" class="btn btn-clean" @click="cleanForm">
        Limpar Formulário
      </button>
      <button
        v-if="itemSelected === null"
        type="button"
        class="btn btn-success"
        @click="addItem"
      >
        Adicionar na lista
      </button>
      <button
        v-if="itemSelected !== null"
        type="button"
        class="btn btn-warning"
        @click="updateItem"
      >
        Atualizar Registro
      </button>
    </section>

    <section class="form-list">
      <div class="requesting-list">
        <ul>
          <li>
            <ul class="form-list--container-header row">
              <li class="form-list--employee col-1">Visitas</li>
              <li class="form-list--employee col-2">Data/Hora</li>
              <li class="form-list--employee col-1">PA</li>
              <li class="form-list--employee col-1">Pulso</li>
              <li class="form-list--employee col-1">PVC</li>
              <li class="form-list--employee col-1">Perdas</li>
              <li class="form-list--employee col-1">Ganhos</li>
              <li class="form-list--employee col-1">Anestesia</li>
              <li class="form-list--employee col-1">Analgesia</li>
              <li class="form-list--remove col-2" />
            </ul>
          </li>
          <li v-if="templateListItems.length < 1" class="form-list--list-empty">
            <div class="form-list--container">
              <IconExclamationInverse class="list-empty-icon" />
              <span class="list-empty-label">
                Esse registro ainda não possui dados!
              </span>
            </div>
          </li>
          <li
            v-for="(item, i) in templateListItems"
            :key="i"
            class="form-list--container-row"
            @click="selectItem(i)"
          >
            <ul class="form-list--container-content row">
              <li class="form-list--employee col-1">{{ i + 1 }}</li>
              <li
                class="form-list--employee col-2"
                v-html="
                  concatDateHour(
                    item.poa_data_pos_anestesico,
                    item.poa_hora_pos_anestesico,
                  )
                "
              />
              <li class="form-list--employee col-1">{{ item.poa_pa }}</li>
              <li class="form-list--employee col-1">{{ item.poa_pulso }}</li>
              <li class="form-list--employee col-centered col-1">
                <IconCheck
                  v-if="checkExists(item.poa_pvc)"
                  class="icon-check"
                />
              </li>
              <li class="form-list--employee col-centered col-1">
                <IconCheck
                  v-if="checkExists(item.poa_perdas)"
                  class="icon-check"
                />
              </li>
              <li class="form-list--employee col-centered col-1">
                <IconCheck
                  v-if="checkExists(item.poa_ganhos)"
                  class="icon-check"
                />
              </li>
              <li class="form-list--employee col-centered col-1">
                <IconCheck
                  v-if="checkExists(item.poa_anestesia)"
                  class="icon-check"
                />
              </li>
              <li class="form-list--employee col-centered col-1">
                <IconCheck
                  v-if="checkExists(item.poa_analgesia)"
                  class="icon-check"
                />
              </li>
              <li class="form-list--employee form-list--remove col-2">
                <RgRemoveSmall @confirm-remove="removeItem(i)" />
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </section>
  </section>
</template>
<script>
import {
  toast,
  RgInput,
  RgInputDate,
  RgInputHour,
  IconExclamationInverse,
  IconCheck,
  RgRemoveSmall,
} from "~tokio/primitive";
import RgComboboxRespiration from "$surgery-center/surgery/component/combobox/rg-combobox-respiration/RgComboboxRespiration";
import RgComboboxMobility from "$surgery-center/surgery/component/combobox/rg-combobox-mobility/RgComboboxMobility";
import RgComboboxCirculation from "$surgery-center/surgery/component/combobox/rg-combobox-circulation/RgComboboxCirculation";
import RgComboboxColoring from "$surgery-center/surgery/component/combobox/rg-combobox-coloring/RgComboboxColoring";
import RgComboboxConscience from "$surgery-center/surgery/component/combobox/rg-combobox-conscience/RgComboboxConscience";
import moment from "moment";

export default {
  name: "EffectivenessPostAnesthesia",
  components: {
    RgRemoveSmall,
    RgInput,
    RgInputDate,
    RgInputHour,
    RgComboboxRespiration,
    RgComboboxMobility,
    RgComboboxCirculation,
    RgComboboxColoring,
    RgComboboxConscience,
    IconCheck,
    IconExclamationInverse,
  },
  props: {
    value: {
      default: null,
    },
  },
  data() {
    return {
      templateListItems: [],
      form: {
        poa_id_respiracoes: null,
        poa_id_mobilidades: null,
        poa_id_circulacoes: null,
        poa_id_coloracoes: null,
        poa_id_visitas: null,
        poa_id_controles_cirurgicos_efetivacoes: null,
        poa_data_pos_anestesico: "",
        poa_hora_pos_anestesico: "",
        poa_pa: "",
        poa_pulso: "",
        poa_pvc: "",
        poa_perdas: "",
        poa_ganhos: "",
        poa_consciencia: "",
        poa_anestesia: "",
        poa_analgesia: "",
      },
      itemSelected: null,
    };
  },
  watch: {
    value(pValue, pOldValue) {
      this.cleanForm();
      this.templateListItems = this.value || [];
    },
  },
  methods: {
    addItem() {
      const validForm = this.checkValidation();
      if (validForm) {
        this.templateListItems.push(validForm);
        this.updateList();
      }
    },
    updateItem() {
      const validForm = this.checkValidation();
      if (validForm) {
        this.templateListItems[this.itemSelected] = { ...this.form };
        this.updateList();
      }
    },
    removeItem(pItemId) {
      this.templateListItems.splice(pItemId, 1);
      this.updateList();
    },
    selectItem(pItemId) {
      this.itemSelected = pItemId;
      const formSelected = { ...this.templateListItems[pItemId] };
      formSelected.poa_hora_pos_anestesico = moment(
        formSelected.poa_hora_pos_anestesico,
        "HH mm ss",
      ).format("HH:mm");
      this.form = formSelected;
    },
    concatDateHour(pDate, pHour) {
      const hour = moment(pHour, "HH mm ss").format("HH:mm");
      return `<strong>${pDate}</strong><br />${hour}`;
    },
    checkValidation() {
      let valid = true;
      valid = this.checkDate(this.form.poa_data_pos_anestesico) && valid;
      valid = this.checkHour(this.form.poa_hora_pos_anestesico) && valid;
      return !valid ? false : { ...this.form };
    },
    checkDate(pDate) {
      const validDate = moment(pDate, "DD/MM/YYYY").isValid();
      this.showError(validDate, {
        title: "Data inválida",
        info: "É necessário inserir uma data válida",
      });
      return validDate;
    },
    checkHour(pHour) {
      const validHour = moment(pHour, "HH:mm").isValid();
      this.showError(validHour, {
        title: "Hora inválida",
        info: "É necessário inserir uma hora válida",
      });
      return validHour;
    },
    checkRequired(pData, pField) {
      const validRequired = pData && pData.length > 0;
      this.showError(validRequired, {
        title: "Campo obrigatório",
        info: `O campo ${pField} precisa estar preenchido`,
      });
      return validRequired;
    },
    showError(pBool, { title, info }) {
      if (!pBool) {
        toast.error(info, title);
      }
    },
    checkExists(pValue) {
      return !!pValue;
    },
    updateList() {
      this.$emit("input", this.templateListItems);
      this.cleanForm();
    },
    cleanForm() {
      this.itemSelected = null;
      this.form = {
        poa_id_respiracoes: null,
        poa_id_mobilidades: null,
        poa_id_circulacoes: null,
        poa_id_coloracoes: null,
        poa_id_visitas: null,
        poa_id_controles_cirurgicos_efetivacoes: null,
        poa_data_pos_anestesico: "",
        poa_hora_pos_anestesico: "",
        poa_pa: "",
        poa_pulso: "",
        poa_pvc: "",
        poa_perdas: "",
        poa_ganhos: "",
        poa_consciencia: "",
        poa_anestesia: "",
        poa_analgesia: "",
      };
    },
  },
};
</script>
