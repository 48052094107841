import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation BillHospitalizationBillingAih(
    $hospitalizationBillingAihId: Int
    $status: Int
  ) {
    BillHospitalizationBillingAih(
      hospitalizationBillingAihId: $hospitalizationBillingAihId
      status: $status
    ) {
      iaf_id
      iaf_faturado
    }
  }
`;
export default async ({ commit }, { hospitalizationBillingAihId, status }) => {
  if (!hospitalizationBillingAihId) return false;
  try {
    const variables = {
      hospitalizationBillingAihId,
      status,
    };

    const { data } = await GraphQLClient.mutate({
      mutation,
      variables,
      fetchPolicy: "no-cache",
    });
    commit(
      "SET_HOSPITALIZATION_BILLING_AIH_STATUS",
      data.BillHospitalizationBillingAih.iaf_faturado,
    );
    return data;
  } catch (pErr) {
    throw new Error(pErr);
  }
};
