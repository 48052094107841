<template>
  <Modulebox
    title="Observações do Paciente na Fila"
    class="appointment-patient-observation-queue"
  >
    <div class="body">
      <form class="container-form" @submit.stop.prevent="registerObservation">
        <RgValidatorForm ref="validator">
          <FormBase title="Paciente" class="content-form">
            Paciente:
            <strong>
              {{ appointmentsData && appointmentsData.pes_nome }}
            </strong>

            <div class="content">
              <div class="textarea">
                <RgTextArea
                  ref="reason"
                  v-model="form.reason"
                  :rules="{ required: true }"
                  :maxlength="500"
                  label="Motivo"
                  class="text-area"
                />
              </div>

              <div class="textarea">
                <RgTextArea
                  ref="observation"
                  v-model="form.observation"
                  :rules="{ required: true }"
                  :maxlength="500"
                  label="Observação"
                  class="text-area"
                />
              </div>
            </div>

            <div class="actions">
              <RgCleanButton title="Limpar" class="clean" @click="cleanForm" />

              <RgAddButton
                :disabled="disableAdd"
                :permission="canInclude"
                large
                title="Adicionar observação"
                class="add"
                @click="saveRegisterObservation"
              />
            </div>
          </FormBase>

          <FormBase title="Observações" class="content-form">
            <SmartTable
              v-if="hasObservations"
              ref="smartTable"
              :columns="COLUMN"
              :body="mutableListObservationsQueue"
              :total="listTotal"
              :initial-columns="5"
              :show-pagination="false"
              remove-btn-columns
              has-check
              toggle-selected
              mult-select
              @getMultLines="getValueRow"
            />

            <div v-else class="no-result">
              <IconEmpty />
              <span class="message"> Não foram encontradas observações </span>
            </div>

            <div v-if="hasObservations" class="actions-footer">
              <RgShowButton
                :disabled="disableView"
                small
                title="Visualizar observação"
                class="buttons"
                @click="openModalViewObservation"
              />

              <RgPrinterButton
                :disabled="disablePrint"
                small
                title="Imprimir observação"
                class="buttons"
                @click="print"
              />
            </div>
          </FormBase>
        </RgValidatorForm>
      </form>
    </div>

    <div slot="footer" class="footer">
      <RgBackButton
        ref="cancelButton"
        medium
        class="cancel"
        @click="comeBackPage"
      />
    </div>

    <ModalViewObservation
      ref="registerObservation"
      :show="modalRegisterObservation"
      :observation-data="appointmentsData"
      :view-data="appointmentViewData"
      :view-observations="view"
      @close="closeModalViewObservation"
    />
  </Modulebox>
</template>

<script>
import {
  IconEmpty,
  RgValidatorForm,
  RgTextArea,
  RgAddButton,
  RgPrinterButton,
  RgShowButton,
  RgCleanButton,
  RgBackButton,
} from "~tokio/primitive";
import { Modulebox, SmartTable } from "~tokio/foundation";
import { AlertError } from "~tokio/primitive/notification";

import FormBase from "~tokio/foundation/form-base/FormBase";
import ModalViewObservation from "$appointment/submodules/schedule/component/modal/modal-view-observation/ModalViewObservation";
import ScheduleObservationPatientQueueHTML from "$appointment/submodules/schedule/html/ScheduleObservationPatientQueueHTML";

export default {
  name: "AppointmentPatientObservationQueue",
  components: {
    IconEmpty,
    FormBase,
    RgValidatorForm,
    ModalViewObservation,
    Modulebox,
    RgAddButton,
    RgPrinterButton,
    RgShowButton,
    RgTextArea,
    RgCleanButton,
    RgBackButton,
    SmartTable,
  },

  data() {
    return {
      appointmentsData: null,
      appointmentViewData: null,
      listSelected: [],
      valueRow: null,
      mutableListObservationsQueue: null,
      modalRegisterObservation: false,
      activeRow: false,
      view: false,
      printer: [],
      printerArray: [],
      form: {
        reason: "",
        observation: "",
      },
    };
  },

  computed: {
    permissionSaveObservations() {
      return this.$Permissions.global.has(
        "consulta.filaConsulta.incluirObservacao",
      );
    },

    canInclude() {
      return this.permissionSaveObservations;
    },

    hasObservations() {
      return this.mutableListObservationsQueue?.length >= 1;
    },

    disableAdd() {
      return (
        this.form.reason?.trim().length < 5 ||
        this.form.observation?.trim().length < 5
      );
    },

    disableView() {
      return this.listSelected?.length !== 1;
    },

    disablePrint() {
      const validate = this.listSelected.every((item) => {
        return item.fio_id;
      });

      return !(validate && this.listSelected?.length > 0);
    },

    listTotal() {
      const total = this.mutableListObservationsQueue?.length || 0;
      return total;
    },
  },

  created() {
    this.COLUMN = [
      { name: "Motivo", key: "fio_motivo_exibicao_dataGrid" },
      { name: "Observação", key: "fio_observacao_exibicao_dataGrid" },
      { name: "Usuário", key: "usu_nome" },
      { name: "Data", key: "data_observacao" },
      { name: "Hora", key: "hora_observacao" },
    ];
  },

  mounted() {
    this.appointmentsData = this.$route.params.appointmentsData;
    this.searchFilter(this.appointmentsData);
  },

  methods: {
    async searchFilter(pData) {
      this.mutableListObservationsQueue = await this.$store.dispatch(
        "Appointment/Schedule/SEARCH_PATIENT_OBSERVATION_QUEUE",
        {
          arrFiltro: {
            fil_id: pData.fil_id,
            pes_id: pData.pes_id,
            pes_nome: pData.pes_nome,
            tipos_leito: "",
            limiteFim: 1000000,
            limiteInicio: 0,
          },
        },
      );

      this.mutableListObservationsQueue = this.mutableListObservationsQueue.data;
    },

    getValueRow(pValue) {
      this.listSelected = pValue;
    },

    isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    async saveRegisterObservation() {
      try {
        if (!(await this.isFormValid())) {
          AlertError("Verifique os campos.");
          return false;
        }

        if (this.form.reason.trim()?.length < 5) {
          this.$toaster.warning(
            "Preencha no mínimo 5 caracteres no campo motivo.",
          );
          return;
        }

        if (this.form.observation.trim()?.length < 5) {
          this.$toaster.warning(
            "Preencha no mínimo 5 caracteres no campo observação.",
          );
          return;
        }

        const fil_id = this.appointmentsData.fil_id;

        const variables = {
          fio_id_filas: fil_id,
          fio_motivo: this.form.reason,
          fio_observacao: this.form.observation,
        };

        await this.$store.dispatch(
          "Appointment/Schedule/SAVE_OBSERVATION_QUEUE",
          variables,
        );

        this.searchFilter(this.appointmentsData);

        if (this.listSelected?.length > 0) {
          this.cleanSelectedRow();
        }

        this.$toaster.success("Observação salva com sucesso!");
        this.cleanForm();
      } catch (error) {
        this.$toaster.error(error);
        this.cleanForm();
      }
    },

    async print() {
      const client = this.$store.getters["Login/GET_CLIENT_CODE"];
      const appointmentsData = { client, ...this.appointmentsData };
      const html = ScheduleObservationPatientQueueHTML(
        appointmentsData,
        this.listSelected,
      );
      this.$utils.print.printHtml(html);
      this.printerArray = [];
    },

    openModalViewObservation() {
      this.view = true;
      this.modalRegisterObservation = true;
      this.appointmentViewData = this.listSelected[0];
    },

    closeModalViewObservation() {
      this.modalRegisterObservation = false;
      this.view = false;
    },

    cleanForm() {
      this.form = {
        reason: "",
        observation: "",
      };
      this.$refs.reason.cleanValidate();
      this.$refs.reason.resetCharacter();
      this.$refs.observation.cleanValidate();
      this.$refs.observation.resetCharacter();
    },

    cleanSelectedRow() {
      this.$refs.smartTable.cleanMultSelectedRow();
      this.listSelected = [];
    },

    comeBackPage() {
      this.$router.go(-1);
    },
  },
};
</script>
