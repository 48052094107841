<template lang="html">
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512.002 512.002"
    style="enable-background: new 0 0 512.002 512.002"
    xml:space="preserve"
  >
    <g>
      <path
        d="M504.947,94.404l-20.342-20.342c7.261-13.303,5.165-30.389-5.901-41.457c-11.29-11.288-28.211-13.172-41.458-5.901
    l-20.339-20.34C412.802,2.261,407.345,0,401.541,0c-9.999,0-14.931,5.932-18.721,9.719c-8.471,8.472-8.471,22.258,0,30.733
    l21.717,21.716l-34.587,34.586l-21.716-21.716c-4.106-4.104-9.562-6.364-15.366-6.364c-10.034,0-14.986,5.984-18.717,9.716
    c-8.494,8.492-8.496,22.24-0.001,30.733l8.746,8.746c-13.794,13.794-215.867,215.866-223.08,223.079
    c-7.727,7.728-7.987,20.467,0.087,28.532l-16.388,16.387c-7.757,7.759-9.555,21.175,0.992,31.724
    c-0.42,0.393-81.215,81.198-81.622,81.605c-2.929,2.93-2.929,7.679,0,10.608c2.931,2.929,7.679,2.928,10.608,0
    c0.397-0.397,81.221-81.212,81.605-81.622c10.01,10.01,23.434,9.282,31.724,0.992l16.386-16.386
    c7.376,7.376,19.024,8.032,27.021,1.451c1.221-0.999-9.365,9.512,191.211-191.065c2.929-2.93,2.929-7.679,0-10.608
    c-2.929-2.927-7.678-2.929-10.608,0L182.623,380.775l-8.57-8.571c-2.929-2.929-7.679-2.93-10.608-0.001
    c-2.93,2.929-2.93,7.679-0.001,10.607l8.571,8.572l-10.882,10.882c-2.004,2.004-5.235,1.995-7.231,0.001
    c-22.807-22.811-2.95-2.949-43.479-43.481c-1.993-1.993-1.993-5.238,0-7.23l10.882-10.881l25.388,25.387
    c2.928,2.928,7.678,2.928,10.607,0c2.929-2.93,2.929-7.679,0-10.608l-25.388-25.387l14.526-14.526l15.106,15.106
    c2.93,2.929,7.679,2.928,10.608,0c2.929-2.93,2.929-7.679,0-10.608l-15.106-15.105l15.804-15.804
    c16.234,16.233,16.389,17.301,20.409,17.301c6.624,0,10.044-8.066,5.304-12.806l-15.104-15.103l15.805-15.805l15.106,15.104
    c2.928,2.928,7.677,2.929,10.608-0.001c2.929-2.929,2.929-7.679-0.001-10.608l-15.106-15.104l15.804-15.804
    c16.234,16.233,16.389,17.301,20.409,17.301c6.624,0,10.044-8.066,5.304-12.806l-15.105-15.104l15.805-15.805
    c16.2,16.202,16.386,17.302,20.407,17.302c6.624,0,10.043-8.065,5.305-12.805l-15.104-15.106l15.803-15.804
    c16.201,16.201,16.387,17.302,20.41,17.302c6.625,0,10.043-8.065,5.305-12.806l-15.106-15.106l15.803-15.803l15.105,15.106
    c2.93,2.928,7.678,2.928,10.608,0c2.929-2.93,2.929-7.679,0-10.608l-15.106-15.106l17.981-17.981
    c30.285,30.283,18.794,18.792,50.709,50.707l-17.128,17.127c-2.929,2.93-2.929,7.679,0,10.608c2.93,2.928,7.679,2.928,10.608,0
    l17.127-17.127c7.501,7.501,12.105,13.732,22.735,13.732c10.034,0,14.989-5.986,18.72-9.717c8.491-8.493,8.493-22.242,0-30.733
    l-21.298-21.297l34.585-34.587l21.299,21.298c4.104,4.105,9.561,6.364,15.365,6.364c10.058,0,15.028-6.025,18.72-9.718
    C513.44,116.643,513.44,102.898,504.947,94.404z M110.51,380.087c3.625,3.625,2.063,2.063,22.09,22.091
    c-24.032,24.033-18.378,23.911-38.477,3.811c-2.63-2.628-2.631-6.884-0.001-9.515L110.51,380.087z M473.169,62.626l-24.498-24.497
    C463.591,33.955,477.289,47.647,473.169,62.626z M425.665,173.687c2.63,2.624,2.629,6.886,0,9.513l-3.353,3.353
    c-2.625,2.628-6.885,2.632-9.516,0.002c-94.92-94.927-90.01-88.955-90.01-92.798c0-3.409,1.994-4.781,5.324-8.11
    c2.626-2.628,6.886-2.631,9.515,0L425.665,173.687z M404.369,131.172c-8.465-8.465-15.517-15.517-23.811-23.811l34.586-34.585
    l23.81,23.809L404.369,131.172z M490.985,117.88c-1.272,1.272-2.961,1.972-4.76,1.972c-3.939,0,1.965,4.75-92.796-90.008
    c-2.624-2.624-2.624-6.894-0.002-9.516c3.35-3.349,4.715-5.326,8.113-5.326c3.982,0-2.642-5.43,92.797,90.009
    c1.271,1.271,1.971,2.961,1.971,4.76C496.309,113.179,494.312,114.553,490.985,117.88z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Syringe",
};
</script>
