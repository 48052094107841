import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation SaveHospitalization($hospitalization: HospitalizationInputType) {
    SaveHospitalization(hospitalization: $hospitalization) {
      int_id
    }
  }
`;
const formatData = (pData) => {
  return {
    ...pData.patient,
    ...pData.hospitalization,
    ...pData.companion,
    indigent: pData.indigent,
    is_indigent: pData.is_indigent,
    uns_id: pData.uns_id,
    pes_id: pData.pes_id,
  };
};

export default async ({ commit }, variables) => {
  try {
    const hospitalization = formatData(variables);
    variables = { hospitalization };

    const { data } = await GraphQLClient.mutate({
      mutation,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.SaveHospitalization;
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors);
  }
};
