<template lang="html">
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 469.998 469.998"
    style="enable-background: new 0 0 469.998 469.998"
    xml:space="preserve"
  >
    <g>
      <path
        d="M446.819,4.798c-0.913-2.367-2.962-4.111-5.444-4.638c-2.478-0.52-5.061,0.24-6.856,2.034L276.521,160.192L26.66,410.053
        c-13.69,13.691-13.689,35.969,0.001,49.659c6.633,6.634,15.451,10.286,24.831,10.286s18.197-3.652,24.83-10.284l249.86-249.86
        c2.704-2.705,2.906-6.957,0.616-9.9l17.499-17.5l8.264,7.969c2.943,2.838,7.616,2.797,10.51-0.095l45.166-45.166
        C448.263,105.136,463.768,48.728,446.819,4.798z M257.239,257.582l-28.447-28.448l10.605-10.605l28.448,28.448L257.239,257.582z
        M236.026,278.796l-28.448-28.447l10.606-10.606l28.447,28.448L236.026,278.796z M214.813,300.009l-28.448-28.448l10.605-10.605
        l28.448,28.447L214.813,300.009z M193.599,321.222l-28.447-28.448l10.605-10.605l28.448,28.448L193.599,321.222z M65.714,449.107
        c-3.799,3.8-8.85,5.892-14.223,5.892s-10.425-2.093-14.224-5.893c-7.843-7.843-7.844-20.604-0.001-28.446l117.278-117.278
        l28.447,28.448L65.714,449.107z M278.453,236.369l-28.448-28.448l31.82-31.82l28.447,28.448L278.453,236.369z M397.629,134.555
        l-39.957,39.958l-8.264-7.969c-2.942-2.839-7.616-2.797-10.51,0.095l-22.662,22.662l-23.805-23.806L408.229,49.698
        c-2.13,21.905-13.658,45.49-31.811,63.644l-23.953,23.952c-2.93,2.929-2.93,7.678-0.001,10.606
        c1.465,1.465,3.384,2.197,5.304,2.197c1.919,0,3.839-0.732,5.303-2.196l23.954-23.953c25.432-25.433,39.066-59.274,36.204-89.25
        l13.086-13.086C444.745,58.176,430.071,102.114,397.629,134.555z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: "Surgery",
};
</script>
