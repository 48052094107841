<template lang="html">
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.5066 15.7536L13.1531 1.28492C12.6885 0.480382 11.8566 0 10.9275 0C9.99843 0 9.1664 0.480382 8.7019 1.28492L0.348409 15.7536C-0.116136 16.5582 -0.116136 17.5189 0.348409 18.3235C0.812954 19.1281 1.64489 19.6084 2.57398 19.6084H19.281C20.21 19.6084 21.042 19.128 21.5066 18.3235C21.9711 17.5189 21.9711 16.5582 21.5066 15.7536ZM20.3977 17.6833C20.1646 18.087 19.7471 18.3281 19.281 18.3281H2.57398C2.10777 18.3281 1.69031 18.087 1.45725 17.6833C1.22419 17.2796 1.22419 16.7976 1.45725 16.3939L9.81083 1.92515C10.0439 1.52144 10.4614 1.28043 10.9275 1.28043C11.3936 1.28043 11.8111 1.52144 12.0442 1.92515L20.3977 16.3939C20.6308 16.7976 20.6308 17.2796 20.3977 17.6833Z"
      fill="white"
    />
    <path d="M11.5655 6.39062H10.2852V12.7927H11.5655V6.39062Z" fill="white" />
    <path
      d="M10.9278 14.0723C10.4572 14.0723 10.0742 14.4552 10.0742 14.9259C10.0742 15.3966 10.4572 15.7795 10.9278 15.7795C11.3985 15.7795 11.7815 15.3966 11.7815 14.9259C11.7815 14.4552 11.3985 14.0723 10.9278 14.0723Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "Error",
};
</script>
