<script>
import { RgRemoteComboboxMixin } from "~tokio/primitive";
import SearchProcedures from "./action/SearchProcedures";

export default {
  name: "RgComboboxProcedures",
  mixins: [RgRemoteComboboxMixin],
  props: {
    variable: Object,
  },
  data() {
    return {
      userId: this.$store.getters["Login/GET_USER_ID"],
      list: null,
    };
  },
  watch: {
    variable(value) {
      this.getData();
    },
  },
  methods: {
    async doSearch() {
      if (!this.variable.intIdVinculoSetor) return Promise.resolve([]);
      if (!this.variable.intIdPlanoSaude) return Promise.resolve([]);

      this.list = await SearchProcedures(this.variable);
      this.list.map((el) => {
        if (parseInt(el.sip_id) > 0) {
          el.id = el.sip_id;
        } else {
          el.id = el.label;
        }
      });

      this.$emit("list", this.list);
      return this.list;
    },
    formatItem(item) {
      return {
        value: item.id,
        text: `${item.stp_novo_nome_procedimento}`,
        item: item,
      };
    },
    blur() {
      if (this.isNecessary) {
        this.validate();
        this.$emit("blur");
      }
    },
  },
};
</script>
