<style src="./SurgeryPrintHeader.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="surgery-print-header">
    <div class="vendor-logo">
      <img
        ref="imageLogoContainer"
        src=""
        alt="Logo"
        class="vendor-logo-image"
      />
    </div>

    <div class="header-title">
      <span class="title">{{ unitHealth }}</span>
      <span class="description">Cirurgia</span>
    </div>

    <div class="info">
      <span class="username">Usuário: {{ username }}</span>
      <span class="print-date">{{ printDate }}</span>
    </div>
  </section>
</template>
<script>
import LocalStorage from "~common/local-storage/LocalStorage";

export default {
  name: "SurgeryPrintHeader",
  props: {
    unitHealth: {
      default: "",
    },
    username: {
      default: "",
    },
    printDate: {
      default: "",
    },
  },
  data() {
    return {
      unitHealthName: "---",
    };
  },
  mounted() {
    this.openClientLogo();
  },
  methods: {
    openClientLogo() {
      const clientLogo = LocalStorage.getObject("client-logo");
      this.$refs.imageLogoContainer.src = clientLogo;
    },
  },
};
</script>
