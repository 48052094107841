<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import GetWatingPlaces from "./action";

export default {
  name: "RgComboboxWaitingPlace",
  mixins: [RgComboboxMixin],
  data: () => {
    return {};
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];
      const data = await GetWatingPlaces({
        arrFiltro: {
          ile_nome: null,
        },
      });

      data.forEach((item) => {
        this.mData.push({ value: item.ile_id, text: item.ile_nome });
      });
    },
  },
};
</script>
