export default {
  GET_ALL_INFO: (state) => state.patientInfo,
  GET_PATIENT_NAME: (state) => state.patientInfo.bof_nome || false,
  GET_PROCEDURES_LIST: (state) => state.proceduresList || null,
  HAS_BOF_ID: (state) => state.patientBofId,
  GET_BOF_FATURADO: (state) => state.patientInfo.bof_faturado,
  GET_PROCEDURE_STATUS_BY_INDEX: (state) => (idx) =>
    state.procedureStatus[`item${idx}`]
      ? state.procedureStatus[`item${idx}`]
      : false,
};
