var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fieldset',{staticClass:"rg-radio-exam-type"},[_c('span',{staticClass:"label"},[_vm._v("Origem do exame")]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"radio show-hospitalization",class:{
        'active-hospitalization': _vm.selectedStatus.includes(
          _vm.EXAM_TYPES.INTERNACAO
        ),
      },attrs:{"data-id":"internacao"},on:{"click":function($event){return _vm.toggleStatus(_vm.EXAM_TYPES.INTERNACAO)}}},[_vm._v(" Internação ")]),_c('div',{staticClass:"radio show-emergency",class:{
        'active-emergency': _vm.selectedStatus.includes(_vm.EXAM_TYPES.EMERGENCIA),
      },attrs:{"data-id":"emergencia"},on:{"click":function($event){return _vm.toggleStatus(_vm.EXAM_TYPES.EMERGENCIA)}}},[_vm._v(" Emergência ")]),_c('div',{staticClass:"radio show-scheduled",class:{
        'active-scheduled': _vm.selectedStatus.includes(_vm.EXAM_TYPES.AGENDADO),
      },attrs:{"data-id":"agendado"},on:{"click":function($event){return _vm.toggleStatus(_vm.EXAM_TYPES.AGENDADO)}}},[_vm._v(" Agendado ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }