import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query Documents {
    documents {
      rows {
        doc_id
        doc_nome
      }
    }
  }
`;

export default async ({ commit }) => {
  try {
    const { data } = await GraphQLClient.query({ query });
    return data.documents.rows;
  } catch (err) {
    return Promise.reject(err.graphQLErrors);
  }
};
