import { render, staticRenderFns } from "./RgSuggestSmartPerson.vue?vue&type=template&id=676431d5&scoped=true&"
import script from "./RgSuggestSmartPerson.vue?vue&type=script&lang=js&"
export * from "./RgSuggestSmartPerson.vue?vue&type=script&lang=js&"
import style0 from "./RgSuggestSmartPerson.scss?vue&type=style&index=0&id=676431d5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "676431d5",
  null
  
)

export default component.exports