<style src="./RgSuggestPatientInformation.scss" lang="scss" scoped></style>
<template>
  <div class="rg-suggest-patient-information">
    <div v-if="hasPatientNotIdentified" class="patient-not-identified">
      <input v-model="patientNotIdentified" type="checkbox" value="1" />
      <label for="label"> Paciente não identificado </label>
    </div>

    <div v-if="!patientNotIdentified" class="patient">
      <div class="search-patient">
        <RgSuggestSmartPerson
          ref="patient"
          v-model="suggestPatient"
          :with-patient-module="withPatientModule"
          :rules="rules"
          :remove-filter="removeFilter"
          :extra-data="extraData"
          :permit-free-text="permitFreeText"
          :disabled="disabled || disabledOnlySuggest"
          class="input"
          @selected="selectingPatient"
        />

        <div v-show="showButtons" class="actions">
          <RgPersonCardButton
            :disabled="disabledButton || !isPerson"
            class="card"
            title="Editar Paciente"
            small
            @click="editPatient"
          />

          <RgHistoryButton
            :disabled="disabledButton || !isPerson"
            class="card"
            title="Histório de Atendimento"
            small
            @click="historyPatient"
          />

          <SmallButton
            class="button-open-modal"
            backgroundColor="#1e88a9"
            title="Busca avançada de pacientes"
            :disabled="isPerson"
            @click="openModalSearch"
          >
            <IconSearchExam slot="icon" class="svg" />
          </SmallButton>

          <RgNewButton
            class="new"
            title="Cadastro de Paciente"
            small
            :disabled="isPerson"
            @click="newPatient"
          />
        </div>
      </div>

      <div class="patient-information">
        <div class="title">
          <span class="title">Paciente Selecionado</span>
        </div>

        <div class="container">
          <div class="person">
            <span class="text-info">
              <IconPatientBioslab class="svg" />
              Nome: <strong>{{ patientInfo.pac_nome || "" }}</strong>
            </span>
            <span class="text-info">
              <IconCalendar class="svg" />
              Nascimento:
              <strong :title="fullAgeFormated(patientInfo.pes_nascimento)">
                {{ formatDataBR(patientInfo.pes_nascimento) || "" }}
                <i>{{ partAgeFormated(patientInfo.pes_nascimento) }}</i>
              </strong>
            </span>
            <span class="text-info">
              <IconPatientBioslab class="svg" />
              Nome da Mãe:
              <strong>{{ patientInfo.pes_mae || "" }}</strong>
            </span>
          </div>
        </div>

        <div class="container">
          <div class="document">
            <span class="text-info">
              <IconCardId class="svg" />CNS:
              <strong>{{ patientInfo.crs_numero || "" }}</strong>
            </span>
            <span class="text-info">
              <IconCardId class="svg" />
              CPF: <strong>{{ patientInfo.cpf || "" }}</strong>
            </span>
            <span class="text-info">
              <IconCardId class="svg" />
              Prontuário Único:
              <strong>{{
                patientInfo.prontuario_unico
                  ? patientInfo.prontuario_unico
                  : pac_prontuario_unico || ""
              }}</strong>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="not-patient">
      <RgRadioGenderBlock v-model="gender" :rules="{ required: true }" />
      <RgInput
        v-model="age"
        :rules="{ required: true }"
        label="Idade aproximada"
      />
      <RgInput v-model="description" label="Descrição" />
    </div>

    <ModalPatientCareHistory
      :show="showModalPatientCareHistory"
      :patient-info="patientInfo"
      @close="closeModalPatientCareHistory"
    />

    <ModalAdvancedPatientSearch
      :show="showModalSearch"
      :withPatientModule="withPatientModule"
      :extraData="extraData"
      @selected="getPatientFromModal"
      @close="closeModalSearch"
    />
  </div>
</template>
<script>
import RgSuggestSmartPerson from "~tokio/primitive/suggest/rg-suggest-smart-person/RgSuggestSmartPerson";
import RgRadioGenderBlock from "$person/common/components/radio/rg-radio-gender-block/RgRadioGenderBlock";
import ModalPatientCareHistory from "$person/patient/component/modal/modal-patient-care-history/ModalPatientCareHistory";
import ModalAdvancedPatientSearch from "$person/patient/component/modal/modal-advanced-patient-search/ModalAdvancedPatientSearch";
import moment from "moment";

import {
  IconPatientBioslab,
  IconCardId,
  IconCalendar,
  RgPersonCardButton,
  RgNewButton,
  RgHistoryButton,
  RgInput,
  SmallButton,
  IconSearchExam,
} from "~tokio/primitive";

export default {
  name: "RgSuggestPatientInformation",
  components: {
    RgSuggestSmartPerson,
    IconPatientBioslab,
    IconCardId,
    IconCalendar,
    RgPersonCardButton,
    RgNewButton,
    RgRadioGenderBlock,
    RgHistoryButton,
    ModalPatientCareHistory,
    RgInput,
    SmallButton,
    ModalAdvancedPatientSearch,
    IconSearchExam,
  },
  props: {
    showButtons: {
      type: Boolean,
      default: true,
    },
    rules: {
      type: Object,
      default: () => {},
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    removeFilter: {
      type: Array,
      default: () => [],
    },
    withPatientModule: {
      type: Array,
    },
    hasPatientNotIdentified: {
      type: Boolean,
      default: false,
    },
    disabledOnlySuggest: {
      type: Boolean,
      default: false,
    },
    extraData: {
      type: Array,
      default: function () {
        return [];
      },
    },
    permitFreeText: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    pac_prontuario_unico: null,
  },
  data() {
    return {
      suggestPatient: null,
      patientNotIdentified: null,
      showModalPatientCareHistory: false,
      patientInfo: {},
      gender: null,
      age: null,
      description: null,
      disabledButton: false,
      showModalSearch: false,
    };
  },

  computed: {
    isPerson() {
      return this.patientInfo.pes_id && this.patientInfo.pes_id > 0;
    },
  },

  watch: {
    suggestPatient() {
      this.$emit("input", this.suggestPatient);
    },

    patientNotIdentified(pValue) {
      if (pValue && pValue === true) {
        this.cleanPatient();
      } else {
        this.cleanNotPatient();
      }
    },
    gender(pGender) {
      if (pGender) {
        this.notPatient();
      }
    },
    age(pAge) {
      if (pAge) {
        this.notPatient();
      }
    },
    description(pDescription) {
      if (pDescription) {
        this.notPatient();
      }
    },
  },
  mounted() {},
  methods: {
    getPatientFromModal(pData) {
      this.$refs.patient.emitData(pData);
    },

    async fillPatientById(pPersonId) {
      const hasPersonId = pPersonId && Number(pPersonId) > 0;

      if (hasPersonId) {
        await this.$refs.patient.fillPatientById(pPersonId);
      }
    },
    selectingPatient(pValue) {
      const pData = pValue?.source;

      this.patientInfo = {
        pes_id: pData?.pes_id,
        pac_id: pData?.patient?.pac_id,
        pac_nome: pData?.pes_nome,
        sex_sigla: pData?.gender?.sex_sigla,
        pes_nascimento: pData?.pes_nascimento,
        crs_numero: pData?.cns?.crs_numero,
        pes_mae: pData?.pes_mae,
        cpf: pData?.cpf?.cpf_numero,
        prontuario_unico: pData?.patient?.pac_prontuario_unico,
      };

      const source = pValue.source ? pValue.source : null;
      const hasPerson = source && source.pes_id > 0;
      const patientBlocked =
        hasPerson &&
        source.patient &&
        source.patient.pac_id_motivo_inabilitacao;

      if (patientBlocked) {
        this.disabledButton = true;
      } else {
        this.disabledButton = false;
      }

      this.$emit("patientInfo", pData);
    },

    notPatient() {
      this.patientInfo = {
        gender: this.gender,
        age: this.age,
        description: this.description,
      };

      this.$emit("patientInfo", this.patientInfo);
    },

    editPatient() {
      const patientId = this.patientInfo.pes_id;
      this.$store.commit("Person/Patient/SET_PERSON_ID", patientId);
      this.$store.commit("Person/Patient/SET_LAST_PERSON_ID_SAVE", patientId);

      const routeName = this.$route.name;

      this.$router.push({
        name: `${routeName}.edit-patient`,
      });

      this.$emit("edit");
    },

    newPatient() {
      const routeName = this.$route.name;

      this.$router.push({
        name: `${routeName}.new-patient`,
      });
    },

    historyPatient() {
      this.showModalPatientCareHistory = true;
    },

    closeModalPatientCareHistory() {
      this.showModalPatientCareHistory = false;
    },

    formatDataBR(pValue) {
      if (!pValue) return "";
      return this.$utils.date.BrazilianDateFormat(pValue);
    },

    cleanNotPatient() {
      this.patientInfo = {};
      this.gender = null;
      this.age = null;
      this.description = null;
    },

    cleanPatient() {
      this.suggestPatient = null;
      this.patientInfo = {};
    },

    cleanPatientInfo() {
      this.patientNotIdentified = null;
      this.cleanNotPatient();
      this.cleanPatient();
    },

    cleanValidate() {
      this.$refs.patient.cleanValidate();
    },

    openModalSearch() {
      this.$refs.patient.setDefaultFilter();
      this.showModalSearch = true;
    },

    closeModalSearch() {
      this.showModalSearch = false;
    },

    fullAge(pValue) {
      const birthDate = moment(pValue);
      const currentDate = moment();
      const isBirthDateValid = currentDate.isAfter(birthDate);

      if (!pValue || !isBirthDateValid) return "";

      const birthDateDay = birthDate.date();
      const birthDateMonth = birthDate.month() + 1;
      const birthDateYear = birthDate.year();

      let currentDateDay = currentDate.date();
      let currentDateMonth = currentDate.month() + 1;
      let currentDateYear = currentDate.year();

      let lastMonth = currentDateMonth - 1;

      if (lastMonth === 0) lastMonth = 12;

      if (currentDateDay < birthDateDay) {
        switch (lastMonth) {
          case 1:
          case 3:
          case 5:
          case 7:
          case 8:
          case 10:
          case 12: {
            currentDateDay += 31;
            currentDateMonth--;
            break;
          }
          default: {
            currentDateDay += 30;
            currentDateMonth--;
            break;
          }
        }
      }

      const ageDays = currentDateDay - birthDateDay;

      if (currentDateMonth < birthDateMonth) {
        currentDateMonth += 12;
        currentDateYear--;
      }

      const ageMonths = currentDateMonth - birthDateMonth;

      const ageYears = currentDateYear - birthDateYear;

      return {
        ageYears,
        ageMonths,
        ageDays,
      };
    },

    fullAgeFormated(pValue) {
      const { ageYears, ageMonths, ageDays } = this.fullAge(pValue);

      return (
        ageYears + " ano(s) " + ageMonths + " mes(es) " + ageDays + " dia(s)"
      );
    },

    partAgeFormated(pValue) {
      const { ageYears, ageMonths, ageDays } = this.fullAge(pValue);

      const hasDay = ageDays >= 0;
      const dayTitle = ageDays === 1 ? "(1 dia)" : "(" + ageDays + " dias)";
      const hasMonth = ageMonths > 0;
      const monthTitle =
        ageMonths === 1 ? "(1 mes)" : "(" + ageMonths + " meses)";
      const hasYear = ageYears > 0;
      const yearTitle = ageYears === 1 ? "(1 ano)" : "(" + ageYears + " anos)";

      const partOfage = hasYear
        ? yearTitle
        : hasMonth
        ? monthTitle
        : hasDay
        ? dayTitle
        : "";

      return partOfage;
    },
  },
};
</script>
