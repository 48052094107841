import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchHomonymsReport(
    $unsId: Int
    $name: String
    $medicalRecord: String
    $presentation: String
    $limit: Int
    $offset: Int
    $toPrint: Boolean
    $toCsv: Boolean
    $toExcel: Boolean
  ) {
    SearchHomonymsReport(
      unsId: $unsId
      name: $name
      medicalRecord: $medicalRecord
      presentation: $presentation
      limit: $limit
      offset: $offset
      toPrint: $toPrint
      toCsv: $toCsv
      toExcel: $toExcel
    ) {
      html
      count
      csv
      excel
      fileName
      rows {
        iaf_id
        icf_nome
        iaf_prontuario
        iaf_numero_aih
        icf_cns
        iaf_data_internacao
        iaf_data_saida
      }
    }
  }
`;
export default async ({ commit }, pParameters) => {
  try {
    const variables = { ...pParameters };
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    const html =
      !data.SearchHomonymsReport || !data.SearchHomonymsReport.html
        ? ""
        : data.SearchHomonymsReport.html.replace('\\"', '"');
    const count = !data.SearchHomonymsReport
      ? 0
      : data.SearchHomonymsReport.count;
    const csv = !data.SearchHomonymsReport ? 0 : data.SearchHomonymsReport.csv;
    const excel = !data.SearchHomonymsReport
      ? 0
      : data.SearchHomonymsReport.excel;
    const fileName = !data.SearchHomonymsReport
      ? 0
      : data.SearchHomonymsReport.fileName;
    return {
      html,
      count,
      csv,
      excel,
      fileName,
      rows: data.SearchHomonymsReport.rows,
    };
  } catch (error) {
    throw new Error("Houve um problema ao obter dados do servidor");
  }
};
