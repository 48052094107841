export const FlattenData = ({ address, person }) => {
  address.bai_nome = address.neighborhood.bai_nome;
  address.mun_id = address.neighborhood.city.mun_id;
  address.tlg_id = address.end_id_tipos_logradouros;
  address.sub_nome = address.subNeighborhood.sub_nome;
  address.zon_id = 4;
  person.pes_id_sexos = person.gender.sex_id;
  return { address, person };
};

export const RemoveUnusedFields = ({ address, person }) => {
  delete address.neighborhood;
  delete address.subNeighborhood;
  delete address.publicPlaceType;
  delete address.__typename;
  delete address.end_id_tipos_logradouros;

  delete person.pes_id;
  delete person.gender;
  delete person.patient;
  delete person.__typename;
  return { address, person };
};

export const SanitizeFields = ({ address, person }) => {
  person.pes_nascimento = person.pes_nascimento
    ? person.pes_nascimento.split("/").reverse().join("-")
    : "0000-00-00";
  address.end_cep = address.end_cep
    ? address.end_cep.replace(/[^\d]/g, "")
    : "00000000";

  return { address, person };
};
