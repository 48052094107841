import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchHospitalizationServiceCharacter {
    hospitalizationServiceCharacter {
      rows {
        ica_id
        ica_nome
      }
    }
  }
`;

export default async (variables) => {
  try {
    const { data } = await GraphQLClient.query({ query, variables });
    return data.hospitalizationServiceCharacter.rows;
  } catch (err) {
    return Promise.reject(err.graphQLErrors[0].message);
  }
};
