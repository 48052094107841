import { isEmpty } from "lodash";

/**
 * @param { String } pPeriod O periodo MES/ANO do faturamento
 * @param { String[] } pErrors Array de Mensagens de Erro
 * @returns { Boolean} true|false indicando se a competência do faturamento esta válida.
 */
const ValidateAihNumber = (pPeriod, pErrors) => {
  pPeriod = pPeriod || null;
  pErrors = pErrors || [];

  if (pPeriod && !isEmpty(pPeriod)) {
    const currentYear = new Date().getFullYear();
    const month = parseInt(pPeriod.substr(0, 2));
    const year = parseInt(pPeriod.substr(3, 6));
    // Validando o mês
    if (month < 1 || month > 12) {
      pErrors.push("Mês da Competência inválido.");
      return false;
    }
    // Validando o ano
    if (year < 2000 || year > currentYear + 1 || isNaN(year)) {
      pErrors.push("Ano da Competência inválido.");
      return false;
    }
  }

  return true;
};

export default ValidateAihNumber;
