import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation RemoveFavoriteOperation($opf_id: Int!) {
    RemoveFavoriteOperation(opf_id: $opf_id) {
      opf_id
    }
  }
`;

const RemoveFavoriteOperation = async (store, variables) => {
  try {
    const { data } = await GraphQLClient.mutate({ mutation, variables });
    return data.RemoveFavoriteOperation;
  } catch (err) {
    const jsonResponse = JSON.parse(JSON.stringify(err));
    return Promise.reject(jsonResponse.graphQLErrors[0].message);
  }
};

export default RemoveFavoriteOperation;
