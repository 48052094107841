import { isEmpty } from "lodash";

/**
 * @param {string} pIafNumeroAih null
 * @param {string[]} pErrors []
 * @returns {boolean} boolean
 */
const ValidateAihNumber = (pIafNumeroAih = null, pErrors = []) => {
  if (pIafNumeroAih && !isEmpty(pIafNumeroAih)) {
    const aihNumber = pIafNumeroAih.substring(12, 0);
    const aihType = aihNumber.substring(5, 4);
    const aihChecker = pIafNumeroAih.substring(14, 13);
    let resultado = aihNumber - parseInt(aihNumber / 11);
    resultado = String(resultado).substring(
      String(resultado).length - 1,
      String(resultado).length,
    );

    // Número da AIH deve ter 13 números
    if (pIafNumeroAih.length !== 14) {
      pErrors.push("Número da AIH precisa ter 13 números.");
      return false;
    }
    // Os tipos de AIH variam entre 1 e 5
    if (aihType < 1 || aihType > 5) {
      pErrors.push("Número da AIH inválido.");
      return false;
    }
    // Verifica se o dígito verificador está correto
    if (resultado !== aihChecker) {
      pErrors.push("Número da AIH inválido.");
      return false;
    }
  }

  return true;
};

export default ValidateAihNumber;
