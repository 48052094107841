export default {
  hospitalizationList: [],
  hospitalizationAihProcedures: "",
  hospitalizationBillingDiagnosticsInfo: "",
  selectedHospitalizationId: null,
  hospitalizationId: null,
  hospitalizationBillingAihId: null,
  patientSearched: false,
  hospitalizationAihReleaseRequestCode: {
    ics_id: null,
    ics_codigo: null,
    ics_tempo_permanencia: 0,
    ics_idade_menor: 0,
    ics_idade_maior: 0,
    ics_qtd: 0,
    ics_telefone: 0,
    ics_cns: 0,
  },
  selectedHospitalizationNumber: null,
  searchFilter: {
    initialDate: "",
    finalDate: "",
    pes_nome: "",
    int_numero: "",
  },
  hospitalizationAihInfo: {
    iaf_id: null,
    iaf_id_internacoes: null,
    iaf_apresentacao: "",
    iaf_numero_aih: null,
    iaf_tipo_aih: 1,
    iaf_prontuario: "",
    iaf_data_internacao: "",
    iaf_data_saida: "",
    iaf_orgao_emissor: "",
    iaf_faturado: 0,
  },
  patientUnitRecordNumber: null,
  patientDocuments: {},
  patientInfoTab: {
    icf_id: null,
    icf_nome: null,
    icf_nome_mae: null,
    icf_nome_responsavel: null,
    icf_data_nascimento: null,
    icf_cod_raca: null,
    icf_cod_etnia: null,
    icf_cns: null,
    icf_sexo: null,
    icf_tel: null,
    icf_tel_ddd: null,
    icf_cod_tipo_logradouro: null,
    icf_logradouro: null,
    icf_logradouro_numero: null,
    icf_complemento: null,
    icf_cep: null,
    icf_bairro: null,
    icf_cod_nacionalidade: "",
    icf_cod_municipio: "",
    icf_uf: null,
    icf_tipo_documento: null,
    icf_documento: "",
    icf_indigente: "0",
  },
  hospitalizationBillingInfoTab: {
    inf_id: null,
    inf_procedimento_principal: null,
    inf_mudanca_procedimento: 2, // 1 = true, 2 = false
    inf_procedimento_solicitado: null,
    inf_codigo_modalidade: null,
    inf_codigo_especialidade_leito: null,
    inf_codigo_carater_internacao: null,
    inf_cid_principal: null,
    inf_motivo_encerramento: null,
    inf_nome_solicitante: null,
    inf_documento_solicitante: null,
    inf_documento_tipo_solicitante: null,
    inf_nome_responsavel: null,
    inf_documento_responsavel: null,
    inf_documento_tipo_responsavel: null,
    inf_nome_autorizador: null,
    inf_documento_autorizador: null,
    inf_documento_tipo_autorizador: null,
    inf_data_autorizacao: null,
    inf_aih_numero_anterior: null,
    inf_aih_numero_posterior: null,
    inf_cnpj_empregador: null,
    inf_cnaer: null,
    inf_vinculos_previdencia: null,
    inf_cbo: null,
    inf_codigo_solicitacao_liberacao: null,
    inf_justificativa: null,
  },
  errorTabs: {
    aih: true,
    patient: true,
    hospitalization: true,
    diagnostic: true,
    procedure: true,
  },
};
