<template>
  <div>
    <MedicalLoader :to-close="loading" title-loader="Carregando os dados..." />

    <ModalShowBedHistory
      :hospitalization-bed="history"
      :show="showBedHistory"
      @close="closeModalBedHistory"
    />

    <ModalHospitalizationDetails
      is-from-map-bed
      :hospitalization-info="hospitalizationInfo"
      :show="openBedDetails"
      @close="closeModalBedDetails"
    />

    <ModalPatientTransferExchange
      :item="info"
      :show="openTransfer"
      @reSearch="reSearchAfterChangeFlow"
      @close="closeModalPatientTransferExchange"
    />
  </div>
</template>

<script>
import MedicalLoader from "~tokio/primitive/loader/medical-loader/MedicalLoader";
import ModalHospitalizationDetails from "$hospitalization/common/component/modal/modal-hospitalization-details/ModalHospitalizationDetails.vue";
import ModalShowBedHistory from "$hospitalization/bed/component/modal/modal-show-bed-history/ModalShowBedHistory";
import { ModalPatientTransferExchange } from "$hospitalization/common/component";

export default {
  name: "ManagerMapBed",
  components: {
    ModalHospitalizationDetails,
    ModalPatientTransferExchange,
    ModalShowBedHistory,
    MedicalLoader,
  },
  data() {
    return {
      history: {},
      info: null,
      hospitalizationInfo: null,
      loading: false,
      openBedDetails: false,
      showBedHistory: false,
      openTransfer: false,
    };
  },
  methods: {
    reSearchAfterChangeFlow() {
      this.$parent.reSearch(false, true);
    },

    closeLoader() {
      this.loading = false;
      this.openBedDetails = true;
    },

    openModalBedDetails(hospitalizationBed) {
      this.hospitalizationInfo = hospitalizationBed;
      this.loading = true;
    },

    closeModalBedDetails() {
      this.hospitalizationInfo = null;
      this.openBedDetails = false;
    },

    openModalBedHistory(hospitalizationBed) {
      this.history = hospitalizationBed;
      this.showBedHistory = true;
    },

    closeModalBedHistory() {
      this.history = null;
      this.showBedHistory = false;
    },

    openModalPatientTransferExchange(hospitalizationBed) {
      this.info = hospitalizationBed;
      this.openTransfer = true;
    },

    closeModalPatientTransferExchange() {
      this.info = null;
      this.openTransfer = false;
    },
  },
};
</script>
