let timeOut = null;
export const DebounceDirective = {
  inserted: (el, binding) => {
    const e = document.createEvent("HTMLEvents");
    e.initEvent("debounced", true, true);
    waitKeyUp(el, binding, e);
  },
};

const waitKeyUp = (el, binding, evt) => {
  el.onkeyup = (ev) => {
    const debounceValue = Number.parseInt(binding.value);
    if (!isNaN(debounceValue) && debounceValue > 0) {
      clearTimeout(timeOut);
      timeOut = setTimeout(() => {
        el.dispatchEvent(evt);
      }, binding.value);
    } else {
      el.dispatchEvent(evt);
    }
  };
};
