import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

const arrayExceptions = ["PossuiDestinoException", "DestinoException"];

export default async (context, arrFormData) => {
  return AuthLegacyRequest.post("/emergencias/controller-destino/cadastrar", {
    arrFormData,
  })
    .then(({ data }) => {
      const { dados, trace } = data;

      if (arrayExceptions.includes(dados)) {
        throw new Error(trace);
      }

      return dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
