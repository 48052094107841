import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation SendHospitalizationToPep($hospitalizationId: Int!) {
    SendHospitalizationToPep(hospitalizationId: $hospitalizationId) {
      success
      message
    }
  }
`;
export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.mutate({ mutation, variables });
    return data.SendHospitalizationToPep;
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors[0].message);
  }
};
