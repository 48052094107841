<script>
import RgRemoteTypeaheadMixin from "$billing/common/component/selection/mixin/RgRemoteTypeaheadMixin";
import { SearchCbo, SearchCboBond, FindCboByCode } from "./action";

export default {
  name: "RgTypeheadCbo",
  mixins: [RgRemoteTypeaheadMixin],
  props: {
    label: {
      type: String,
      default: "CBO",
    },
    min: {
      type: String,
      default: "2",
    },
    bond: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      title: "Classificação Brasileira de Ocupações",
      filter: () => {
        return {
          searchQuery: this.inputValue,
        };
      },
    };
  },
  methods: {
    doSearch() {
      if (this.bond) {
        return SearchCboBond({ searchQuery: this.inputValue });
      } else {
        return SearchCbo({ searchQuery: this.inputValue });
      }
    },
    doFind(pValue) {
      return FindCboByCode(pValue);
    },
    formatItem(item) {
      return {
        id: item.ocp_id,
        value: item.ocp_codigo,
        text: `${item.ocp_codigo} - ${item.ocp_nome}`,
      };
    },
  },
};
</script>
