import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation UpdateProfessionalRegistration(
    $classCouncilId: Int
    $councilData: ProfessionalRegistrationInputType
  ) {
    UpdateProfessionalRegistration(
      professionalRegistrationId: $classCouncilId
      professionalRegistration: $councilData
    ) {
      coc_id
    }
  }
`;

export default async ({ rootState }, payload) => {
  const variables = { ...payload };

  try {
    const { data } = await GraphQLClient.mutate({
      mutation,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.UpdateProfessionalRegistration;
  } catch (err) {
    return Promise.reject(err.graphQLErrors);
  }
};
