import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query Schooling {
    SearchSchoolingBasicAttention {
      rows {
        eab_id
        eab_nome
        eab_codigo_ab
      }
    }
  }
`;

export default async ({ commit, state }) => {
  if (state.schoolingAb.length > 0) {
    return;
  }
  try {
    const { data } = await GraphQLClient.query({
      query,
      fetchPolicy: "no-cache",
    });
    commit("SET_SCHOOLING_AB", data.SearchSchoolingBasicAttention.rows);
  } catch (err) {
    console.log(err);
    return err.graphQLErrors;
  }
};
