import AuthRequest from "~common/request/AuthRequest";

function urltoFile(url, filename, mimeType) {
  mimeType = mimeType || (url.match(/^data:([^;]+);/) || "")[1];
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
}

const SendFile = async (file) => {
  const formData = new FormData();
  const f = await urltoFile(file, "webcam.picture.jpg");
  formData.append("file", f);
  return AuthRequest.post("/globals/files/upload", formData);
};

export default SendFile;
