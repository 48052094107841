import AboutSoftware from "../view/AboutSoftware.vue";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

export default {
  path: "/aboutsoftware",
  name: "aboutsoftware",
  components: {
    default: AboutSoftware,
    header: MainHeader,
  },
  meta: {
    breadcrumb: [
      { label: "Configurações", link: "/" },
      { label: "Sobre o Sistema" },
    ],
  },
};
