<style src="./RgSuggestCboSynonyms.scss" lang="scss" scoped></style>
<template>
  <RgSuggest
    ref="innerSuggest"
    v-model="inputValue"
    :data-id="dataId"
    :label="label"
    :title="title"
    :placeholder="placeholder"
    :search-on-focus="searchOnFocus"
    :rules="rules"
    :disabled="disabled"
    min="2"
  >
    <RgSuggestItem
      v-for="(item, idx) in suggestionList"
      :key="idx"
      :data-item="dataItem"
      :idx="idx"
      :item="item"
      class="cbo-item"
    >
      <div class="cbo-row-info">
        <div class="ocp-code">
          <span>{{ item.ocp_codigo || item.CO_OCUPACAO }}</span>
        </div>
        <div class="ocp-name">
          <span>{{ item.soc_nome || item.NO_OCUPACAO }}</span>
        </div>
      </div>
    </RgSuggestItem>
  </RgSuggest>
</template>
<script>
import { isEmpty } from "lodash";
import {
  RgSuggest,
  RgSuggestItem,
  RgSuggestMixin,
} from "~tokio/primitive/selection";
import { SearchCboSynonyms } from "./action";
import StartWithLetter from "./helper/StartWithLetter";

export default {
  name: "RgSuggestCboSynonyms",
  components: {
    RgSuggest,
    RgSuggestItem,
  },
  mixins: [RgSuggestMixin],
  props: {
    employee: {
      type: Number,
      default: null,
    },
    dataItem: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "Ocupação",
    },
    title: {
      type: String,
      default: "Ocupação",
    },
    placeholder: {
      type: String,
      default: "Digite a ocupação",
    },
    noUserPermission: {
      type: Boolean,
      default: false,
    },
    disabled: Boolean,
  },
  data() {
    return {
      captionRaw: "ocp_codigo" + " - " + "soc_nome",
    };
  },
  computed: {
    noItemSelected() {
      return !isEmpty(this.inputValue) && !this.$refs.innerSuggest.itemSelected;
    },
    searchOnFocus() {
      return this.inputValue === "" || this.inputValue === null;
    },
  },
  methods: {
    validate() {
      this.$refs.innerSuggest.validate();
    },

    cleanValidate() {
      this.$refs.innerSuggest.cleanValidate();
    },

    doSearch(pSearchTerm, pLimit = null) {
      let valueCode = null;
      let valueName = null;

      if (pSearchTerm) {
        StartWithLetter(pSearchTerm)
          ? (valueName = pSearchTerm)
          : (valueCode = pSearchTerm);
      }

      return SearchCboSynonyms({
        variables: {
          soc_nome: valueName || pSearchTerm,
          ocp_codigo: valueCode,
          noPermission: this.noUserPermission,
          limit: pLimit,
        },
      });
    },

    forceSelection(pValue) {
      if (pValue && !isEmpty(pValue.soc_nome) && pValue.ocp_codigo > 0) {
        this.$refs.innerSuggest.itemSelected = true;
        if (
          !this.suggestionList ||
          (this.suggestionList && this.suggestionList.length === 0)
        ) {
          this.suggestionList.push({
            soc_nome: pValue.soc_nome,
            ocp_codigo: pValue.ocp_codigo,
          });
        }
        this.$refs.innerSuggest.$parent.selectingItemFromSuggestList(pValue);
        this.$refs.innerSuggest.validate();
      }
    },
  },
};
</script>
