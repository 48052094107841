<template lang="html">
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 476.267 476.267"
    style="enable-background: new 0 0 476.267 476.267"
    xml:space="preserve"
  >
    <g>
      <path
        d="M287.039,244.883l-48.63-14.498c-12.919,15.373-29.208,25.976-47.615,25.976c-18.407,0-34.696-10.603-47.614-25.976
      l-48.631,14.498c-9.266,2.768-15.584,11.197-15.811,20.822c4.825,7.218,10.385,14.084,16.75,20.449
      c25.453,25.454,59.305,39.474,95.306,39.474c36.003,0,69.853-14.02,95.315-39.474c6.364-6.366,11.917-13.232,16.742-20.441
      C302.631,256.08,296.305,247.65,287.039,244.883z"
      />
      <path
        d="M469.183,435.26L341.534,307.611c58.015-74.796,52.854-183.074-15.795-251.715C289.697,19.854,241.77,0,190.793,0
      C139.817,0,91.89,19.854,55.849,55.896c-74.404,74.413-74.404,195.476,0,269.889c36.041,36.049,83.968,55.895,134.944,55.895
      c42.868,0,83.483-14.192,116.78-40.091l127.641,127.641c4.692,4.692,10.839,7.039,16.985,7.039c6.147,0,12.291-2.346,16.983-7.039
      C478.567,459.853,478.567,444.637,469.183,435.26z M78.495,303.138c-61.909-61.924-61.909-162.673,0-224.597
      c29.997-29.988,69.877-46.512,112.298-46.512s82.303,16.524,112.299,46.512c61.916,61.924,61.916,162.673,0,224.597
      c-29.996,29.997-69.877,46.512-112.299,46.512S108.492,333.135,78.495,303.138z"
      />
      <path
        d="M118.846,185.922c4.338,5.463,9.861,8.751,15.138,8.653c11.915,27.326,32.92,50.789,56.913,50.789
      c23.993,0,44.998-23.464,56.913-50.789c5.278,0.098,10.8-3.19,15.138-8.653c2.294-2.885,4.284-6.31,5.66-10.266
      c2.579-7.401,2.54-14.738,0.508-20.371c-1.595-4.414-4.393-7.806-8.315-9.183c-0.639,0.208-1.278,0.437-1.971,0.491
      c-0.214,0.017-0.426,0.022-0.64,0.022c-1.273,0-2.453-0.389-3.562-0.923c-2.108-1.022-3.824-2.814-4.523-5.163
      c-0.169-0.563-4.442-13.837-29.68-13.837c-2.426,0-4.993,0.125-7.698,0.371c0,0-2.639,0.235-6.708,0.235
      c-13.462,0-31.593-2.327-41.519-13.396c-2.099-2.343-3.715-4.965-4.835-7.822c-9.331,5.927-22.748,17.126-27.91,34.722
      c-0.656,2.224-2.207,3.901-4.13,4.933c-1.196,0.645-2.502,1.093-3.917,1.093c-0.328,0-0.655-0.017-0.989-0.061
      c-0.677-0.081-1.283-0.344-1.9-0.579c-3.825,1.42-6.567,4.747-8.14,9.096c-2.032,5.632-2.071,12.969,0.508,20.371
      C114.563,179.612,116.552,183.037,118.846,185.922z"
      />
      <path
        d="M166.331,93.063c-2.551,23.293,26.725,25.844,39.688,25.844c3.639,0,5.993-0.202,5.993-0.202
      c2.988-0.279,5.79-0.404,8.413-0.404c32.826,0,37.776,20.032,37.776,20.032l2.033-15.083c4.277-31.702-17.777-60.935-49.429-65.529
      c-1.847-0.268-3.699-0.399-5.539-0.399c-10.576,0-20.781,4.349-28.124,12.183l-0.557,0.595c-4.857-2.218-10.042-3.311-15.203-3.311
      c-7.43,0-14.811,2.262-21.088,6.687c-11.947,8.419-18.737,22.377-18,36.969l1.409,27.992
      C133.247,105.923,166.331,93.063,166.331,93.063z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: "SearchPerson",
};
</script>
