export default {
  GET_FILTER_APPOINTMENT_PER_DAY: (state) => state.filter_per_day_data,
  GET_FILTER_APPOINTMENT_PER_PERIODS: (state) => state.filter_per_periods_data,
  GET_FILTER_PATIENT_EXCLUDE: (state) => state.filter_patient_exclude_data,
  GET_FILTER_APPOINTMENT_EFFECTIVENESS: (state) =>
    state.filter_appointment_effectiveness_data,
  GET_SELECTED_EMPLOYEE: (state) => state.selected_employee_data,
  GET_SELECTED_EFFECTIVENESS_EMPLOYEE: (state) =>
    state.selected_employee_effectiveness_data,
  GET_EMPLOYEE_SEARCH_RESULT: (state) => state.employee_search_result,
  GET_SELECTED_EXTERNAL_APPOINTMENT_RESULT: (state) =>
    state.selected_queue_external_appointment_data,
  GET_FILTER_APPOINTMENT_QUEUE: (state) => state.filter_appointment_queue_data,
  GET_TYPE_SCHEDULE: (state) => state.type_schedule,
  GET_FORM_APPOINTMENT: (state) => state.form_appointment,
  GET_FILTER_SCHEDULE_PATIENT_LIST: (state) =>
    state.filter_schedule_patient_list,
  GET_FILTER_EXTERNALLY_SCHEDULE: (state) => state.filter_externally_schedule,
};
