<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import getData from "./action";
export default {
  name: "ComboboxReasonsNotEffective",
  mixins: [RgComboboxMixin],
  props: {},
  data: function () {
    return {
      list: [],
    };
  },
  watch: {},
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];
      this.list = await getData();
      this.list.forEach((item) => {
        this.mData.push({
          item: item.data,
          value: item.data,
          text: item.mne_nome,
        });
      });
      // this.disableLoading = false;
    },
  },
};
</script>
