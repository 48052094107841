const PatientIdentificationHTML = (pValue) => {
  const html = `
    <html>
      <head>
        <meta charset="utf-8">
      </head>

      <style type="text/css">
        @media print{
          body{ background-color:#FFFFFF; background-image:none; color:#000000 }
          .banner { display: none; }
          #ad{ display:none;}
          #leftbar{ display:none;}
          #contentarea{ width:100%;}
          @page { margin: -2mm -2mm -2mm -2mm; }
          .page-break { page-break-before: always;}
        }
      </style>

      <body>
      <div style="font-size:10px; font-family: Arial, Helvetica, sans-serif;">
          Paciente : ${pValue.paciente}  <br>
          Prontuário da Unidade : ${pValue.ppr_numero || ""} <br>
          Data de Nascimento : ${pValue.pes_nascimento} <br>
          Prontuário Único : ${pValue.pac_prontuario_unico}
      </div>
      </body>
    </html>
  `;

  return html;
};

export default PatientIdentificationHTML;
