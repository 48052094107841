import Bulletin from "$billing/bulletin/store";
import BDSia from "$billing/bdsia/store";
import Billing from "$billing/billing/store";
import ConsolidatedBilling from "$billing/consolidated-billing/store";
import HospitalizationBilling from "$billing/hospitalization-billing/store";
import PeriodHospitalizationBilling from "$billing/define-hospitalization-period/store";

export default {
  namespaced: true,
  modules: {
    Bulletin,
    BDSia,
    Billing,
    ConsolidatedBilling,
    HospitalizationBilling,
    PeriodHospitalizationBilling,
  },
};
