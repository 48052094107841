<template lang="html">
  <ModalInformation
    ref="ModalInformation"
    class="modal-weight-conflict"
    :title="title"
    :subtitle="subtitle"
    :show="show"
    @close="close"
  >
    <div slot="body-content" class="content">
      <IconInfoHelper class="svg" />
      <div class="message">
        <strong class="title">
          Não é possível manter duas prioridades ativas com o mesmo peso
        </strong>

        <span class="label">Soluções:</span>
        <ul class="list">
          <li class="item">
            Editar o peso da prioridade atual para manter todas ativas
          </li>
          <li class="item">Inativar a outra prioridade com mesmo peso.</li>
        </ul>
      </div>
    </div>
  </ModalInformation>
</template>

<script>
import ModalInformation from "~tokio/primitive/modal/modal-information/ModalInformation";
import { IconInfoHelper } from "~tokio/primitive/icon/";

export default {
  name: "ModalWeightConflict",
  components: {
    ModalInformation,
    IconInfoHelper,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "O peso selecionado está em uso",
    },
    subtitle: {
      type: String,
      default: "Outra prioridade com o mesmo peso está ativa.",
    },
  },

  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
