<style src="./ExamLaboratoryDetails.scss" lang="scss" scoped></style>
<template lang="html">
  <Modulebox
    :granted="hasPermission"
    :title="setTitle"
    class="exam-laboratory-details large-scroll"
  >
    <div slot="title" class="title">
      <div class="buttons-title">
        <RgShowTicketsButton
          v-show="blockScreen"
          data-id="etiqueta"
          title="Etiquetas"
          class="tags"
          @show-tags="showModalTags"
        />

        <RgPdfButton
          v-show="buttons.pdf"
          :disabled="!hasUrl"
          :class="{ disabled: !hasUrl }"
          :open-url="hasUrl"
          :send-url="getUrl"
          class="pdf"
          title="Baixar Resultado"
        />
        <RgErrorButton
          v-show="buttons.pending"
          ref="ErrorButton"
          class="error"
          title="Erros"
          @click="showModalError(errors)"
        />
        <RgNewButton
          v-show="showAddButton"
          small
          title="Novo Pedido"
          class="new-exam"
          type="button"
          @click="prepareNewExam"
        />
      </div>
    </div>

    <form class="content" @submit.stop.prevent="saveSolicitationEffectivation">
      <RgValidatorForm ref="validator">
        <FormBase title="Dados do Pedido" class="form-base">
          <div class="grid-request">
            <div class="grid-request row-1">
              <div class="item-1">
                <RgInput
                  v-model="protocolNumber"
                  :class="{ disable: true }"
                  :disabled="true"
                  data-id="solicitacao"
                  label="Protocolo"
                  title="Número da Solicitação"
                />
              </div>

              <div class="item-2">
                <RgInput
                  v-model="bulletinNumber"
                  :class="{ disable: disableBulletinNumber || hasPersonId }"
                  :disabled="disableBulletinNumber || hasPersonId"
                  data-id="boletim"
                  type="number"
                  label="Boletim"
                  title="Número do Boletim"
                  @blur="searchBulettin"
                />
              </div>

              <div class="item-3">
                <RgInput
                  v-model="hospitalizationNumber"
                  :class="{
                    disable: disableHospitalizationNumber || hasPersonId,
                  }"
                  :disabled="disableHospitalizationNumber || hasPersonId"
                  data-id="internacao"
                  label="Nº Internação"
                  type="number"
                  title="Número da Internação"
                  @blur="searchHospitalization"
                />
              </div>

              <div class="item-4">
                <RgInput
                  v-model="recordNumber"
                  :disabled="
                    disableInputsInRequestData ||
                    disableHospitalizationNumber ||
                    disableBulletinNumber ||
                    hasPersonId
                  "
                  :class="{
                    disable:
                      disableInputsInRequestData ||
                      disableHospitalizationNumber ||
                      disableBulletinNumber ||
                      hasPersonId,
                  }"
                  data-id="prontuario"
                  label="Prontuário"
                  type="number"
                  title="Número do Prontuário"
                  @blur="searchRecordNumber"
                />
              </div>

              <div class="item-5">
                <RgSuggestSmartPerson
                  ref="patient"
                  v-model="suggestPatient"
                  :disabled="
                    disableInputsInRequestData ||
                    disableHospitalizationNumber ||
                    disableBulletinNumber
                  "
                  :remove-filter="REMOVE_FILTER"
                  :enabled-patient="false"
                  :rules="{ required: true, forceSelection: true }"
                  :class="{
                    disable:
                      disableInputsInRequestData ||
                      disableHospitalizationNumber ||
                      disableBulletinNumber,
                  }"
                  :extra-data="[
                    'telephone',
                    'ethnicity',
                    'naturality',
                    'nationality',
                  ]"
                  confirmToRemove
                  message="Todos os campos informados serão apagados, deseja continuar ?"
                  data-id="paciente"
                  data-item="paciente"
                  label="Paciente"
                  class="suggest-patient"
                  placeholder="Informe o nome do paciente"
                  @selected="selectingPatient"
                />

                <RgPersonCardButton
                  v-show="buttons.editPerson"
                  small
                  :disabled="!hasPersonId"
                  :class="{ disable: !hasPersonId }"
                  class="person-button"
                  title="Editar Paciente"
                  @click="patientRecord"
                />

                <RgNewButton
                  v-show="buttons.editPerson"
                  :disabled="hasPersonId || blockScreen"
                  :class="{ disable: hasPersonId || blockScreen }"
                  title="Cadastrar Paciente"
                  large
                  class="person-button"
                  @click="registerPatient"
                />
              </div>

              <div class="item-6">
                <RgInputDate
                  v-model="form.leb_data_solicitacao"
                  :disabled="disableInputsInRequestData || blockScreen"
                  :class="{
                    disable:
                      disableInputsInRequestData ||
                      blockScreen ||
                      !patientIsValid,
                  }"
                  :rules="{ required: true }"
                  placeholder="dd/mm/aaaa"
                  data-id="data-exame"
                  label="Data da Solicitação"
                />
              </div>
            </div>

            <div class="grid-request row-2">
              <div class="item-1">
                <RgSuggestCid
                  ref="mainCid"
                  v-model="mainCid"
                  :disabled="disableInputsInRequestData || blockScreen"
                  :class="{
                    disable:
                      disableInputsInRequestData ||
                      blockScreen ||
                      !patientIsValid,
                  }"
                  data-id="cid"
                  label="CID"
                  placeholder="Informe a classificação"
                  @selected="selectingCidCode"
                />
              </div>
              <div class="item-2">
                <RgSuggestEmployee
                  ref="Requester"
                  v-model="suggestRequestEmployee"
                  :rules="{
                    forceSelection: true,
                    fn: validateProfessionalData,
                    required: true,
                  }"
                  :disabled="disableInputsInRequestData || blockScreen"
                  :class="{
                    disable: disableInputsInRequestData || blockScreen,
                  }"
                  data-id="profissional-solicitante"
                  data-item="profissional-solicitante"
                  placeholder="Informe o nome do profissional solicitante"
                  label="Profissional Solicitante"
                  @selected="selectingRequestEmployee"
                />
              </div>
              <div class="item-3">
                <RgInputDate
                  v-model="form.leb_data_agendamento"
                  :disabled="blockScreenWithoutScheduled"
                  :class="{
                    disable: blockScreenWithoutScheduled,
                  }"
                  :rules="{ fn: verifyRetroactiveDate }"
                  data-id="data-exame-agendado"
                  placeholder="dd/mm/aaaa"
                  label="Data do Agendamento"
                />
              </div>
              <div class="item-4">
                <RgInputHour
                  v-model="form.leb_hora_agendamento"
                  :disabled="blockScreenWithoutScheduled"
                  :class="{
                    disable: blockScreenWithoutScheduled,
                  }"
                  :rules="{ fn: hasAgendationDate }"
                  data-id="hora-exame-agendado"
                  label="Hora do Agendamento"
                  placeholder="HH:MM"
                />
              </div>
            </div>
          </div>
        </FormBase>

        <FormBase title="Dados do Paciente" class="form-base">
          <div class="patient-data">
            <div class="patient-data row1">
              <RgRadioGenderBlock
                ref="gender"
                v-model="examPatientData.gender"
                :disabled="!form.pes_id || blockScreen"
                :class="{
                  disable: !form.pes_id || blockScreen,
                }"
                :rules="{ required: true }"
                label="Sexo"
              />

              <RgInputDate
                ref="birthdayDate"
                v-model="examPatientData.birthday"
                :disabled="!form.pes_id || blockScreen"
                :class="{
                  disable: !form.pes_id || blockScreen,
                }"
                :rules="{ required: true }"
                label="Nascimento"
                placeholder="dd/mm/aaaa"
              />

              <RgComboboxEthnicity
                ref="ethnicity"
                v-model="examPatientData.colorAndRaceId"
                label="Raça/Cor"
                :disabled="!form.pes_id || blockScreen"
                :class="{
                  disable: !form.pes_id || blockScreen,
                }"
                :rules="{ required: !validate.colorAndRace }"
                @change="ethnicityPatient"
              />

              <RgInput
                v-model="patientWeight"
                v-mask="['##', '##,##', '###,##']"
                :rules="{
                  fn: weightIsValid,
                  required: !validate.heightAndWeight,
                }"
                :disabled="!form.pes_id || blockScreen"
                :class="{
                  disable: !form.pes_id || blockScreen,
                }"
                data-id="peso"
                label="Peso (kg)"
                placeholder="Ex.: 60,5"
                class="metric-values"
              />

              <RgInput
                v-model="patientHeight"
                v-mask="('#', '#,##')"
                :disabled="!form.pes_id || blockScreen"
                :class="{
                  disable: !form.pes_id || blockScreen,
                }"
                :rules="{
                  fn: heightIsValid,
                  required: !validate.heightAndWeight,
                }"
                data-id="altura"
                label="Altura"
                placeholder="Ex.: 1,68"
                class="metric-values"
              />
            </div>

            <div class="patient-data row2">
              <RgInput
                v-model="examPatientData.telephone"
                v-mask="['(##) ####-####', '(##) #####-####']"
                :disabled="!form.pes_id || blockScreen"
                :class="{
                  disable: !form.pes_id || blockScreen,
                }"
                :rules="{ required: true }"
                placeholder="(00) 00000-0000"
                label="Telefone"
              />

              <RgInputCns
                v-model="examPatientData.cns"
                :disabled="!form.pes_id || blockScreen"
                :class="{
                  disable: !form.pes_id || blockScreen,
                }"
                :requiredValue="true"
              />

              <RgSuggestNationality
                v-model="examPatientData.nationality"
                label="Nacionalidade"
                :disabled="!form.pes_id || blockScreen"
                :class="{
                  disable: !form.pes_id || blockScreen,
                }"
                :rules="{ required: !validate.nationality }"
                placeholder="Informe o nome do país"
              />

              <RgSuggestCity
                v-model="examPatientData.city"
                label="Naturalidade"
                :disabled="!form.pes_id || blockScreen"
                :class="{
                  disable: !form.pes_id || blockScreen,
                }"
                :rules="{ required: !validate.city }"
                placeholder="Informe o nome do município"
              />
            </div>
          </div>
        </FormBase>

        <FormBase title="Informações Adicionais" class="form-base">
          <div class="grid-info">
            <div :class="{ disable: !isWoman }" class="grid-info row1">
              <div
                :class="{
                  disable: disabledRadioGender || blockScreen,
                }"
              >
                <label class="small-title">Menstruação</label>

                <div class="radio-menstruation" data-id="radio-menstruacao">
                  <input
                    v-model="patientInfo.menstruada"
                    :disabled="disabledRadioGender || blockScreen"
                    :class="{
                      disable: disabledRadioGender || blockScreen,
                    }"
                    type="radio"
                    value="S"
                    checked
                  />
                  <span data-id="menstrua" class="radion-span">Sim</span>

                  <input
                    v-model="patientInfo.menstruada"
                    :disabled="disabledRadioGender || blockScreen"
                    :class="{
                      disable: disabledRadioGender || blockScreen,
                    }"
                    type="radio"
                    value="N"
                  />
                  <span class="radion-span" data-id="nao-menstrua">Não</span>
                </div>
              </div>

              <RgInputDate
                ref="dateMenst"
                v-model="patientInfo.data_menstruacao"
                :disabled="!isMenstruationRadioTrue || blockScreen"
                :class="{
                  disable: !isMenstruationRadioTrue || blockScreen,
                }"
                :rules="{ fn: validateDateMenstruation }"
                placeholder="dd/mm/aaaa"
                data-id="data-menstruacao"
                label="Data da Última Menstruação"
              />
            </div>

            <div class="grid-info row2">
              <RgTextArea
                v-model="patientInfo.info_menstruada"
                :disabled="disableTextAreaMenstruation || blockScreen"
                :class="{
                  disable:
                    disableTextAreaMenstruation ||
                    blockScreen ||
                    !patientIsValid,
                }"
                :maxlength="255"
                data-id="info-menstruacao"
                label="Motivo da Ausência de Menstruação"
              />

              <RgTextArea
                v-model="patientInfo.info_medicamento"
                :disabled="blockScreen"
                :class="{ disable: blockScreen }"
                :maxlength="255"
                data-id="info-medicamentos"
                label="Medicamentos em Uso"
              />
            </div>

            <div class="grid-info row3"></div>
          </div>
        </FormBase>

        <FormBase title="Procedimentos" class="form-base">
          <div class="grid-procedures">
            <div class="grid-procedures row-1">
              <RgSuggestProcedureBioslab
                ref="Procedure"
                v-model="suggestProcedure"
                :disabled="disableProcedureSuggest || blockScreen"
                :class="{
                  disable: disableProcedureSuggest || blockScreen,
                }"
                :alert-empty-result="true"
                data-id="procedimento"
                label="Exames Laboratoriais"
                placeholder="Digite o Procedimento"
                field-caption="lle_procedimentos"
                @selected="selectingProcedure"
              />

              <RgInput
                v-if="matbio"
                v-model="exams.collectMaterial"
                :disabled="blockScreen || !suggestProcedure.length > 0"
                class="align"
                data-id="material-coleta"
                label="Material de Coleta"
              />
              <RgInput
                v-else
                v-model="exams.collectMaterial"
                :disabled="
                  (!matbio || blockScreen) && !suggestProcedure.length > 0
                "
                :class="{ disable: !matbio }"
                data-id="material-coleta"
                label="Material de Coleta"
              />

              <div
                :class="{
                  disable: showObjectExam || blockScreen,
                }"
                class="object-analysis"
              >
                <span class="title-procedure"> Objeto de Análise </span>
                <div>
                  <select
                    v-if="objectExam.length === 0"
                    v-model="exams.objectAnalisis"
                    :disabled="
                      (showObjectExam || blockScreen) &&
                      !suggestProcedure.length > 0
                    "
                    data-id="objeto-análise"
                    class="select-object"
                  />
                  <select
                    v-else
                    v-model="exams.objectAnalisis"
                    :disabled="!suggestProcedure.length > 0"
                    data-id="objeto-análise"
                    class="select-object"
                  >
                    <option value="">Selecione</option>
                    <option
                      v-for="(item, index) in objectExam"
                      :key="index"
                      :value="item"
                    >
                      {{ item.lob_nome_objetos }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="procedure-buttons">
                <RgCleanButton
                  title="Limpar"
                  data-id="limpar-procedimento"
                  :disabled="blockScreen"
                  :class="{ disable: blockScreen }"
                  class="clean"
                  small
                  @click="cleanProcedureInfo"
                />

                <RgNewButton
                  :disabled="disableAddProcedure || blockScreen"
                  :class="{
                    disable: disableAddProcedure || blockScreen,
                  }"
                  small
                  data-id="adicionar-procedimento"
                  title="Adicionar Procedimento"
                  class="new"
                  @click="addProcedure"
                />
              </div>
            </div>
          </div>
        </FormBase>

        <div
          v-if="procedureList && procedureList.length > 0"
          class="quantity-exams"
          :class="{ disable: blockScreen }"
        >
          <span>Quantidade de Procedimentos: {{ qtdExam }}</span>
        </div>

        <div v-if="!hasProcedureList">
          <div class="empty-values">
            <div class="icon">
              <IconEmpty />
            </div>
            <span class="mesage">Não há procedimentos adicionados</span>
          </div>
        </div>

        <div
          v-for="(item, index) in procedureList"
          v-else
          :key="index"
          :class="{ disable: blockScreen }"
          class="procedures-list"
        >
          <div class="grid-procedures-list">
            <div class="grid-procedures-list row-1">
              <div class="item-1 cell-info">
                <span class="title-data">Nome</span>
                <span class="value">{{ item.nome }}</span>
              </div>
              <div class="item-2 cell-info">
                <span class="title-data">Código Bioslab</span>
                <span class="value">{{ item.codigoExameMne }}</span>
              </div>
              <div class="item-2 cell-info">
                <span class="title-data">Código Sigtap</span>
                <span class="value">{{ item.codigo }}</span>
              </div>
              <div class="item-3 cell-info">
                <span class="title-data">Material de Coleta</span>
                <span class="value">{{ item.collectMaterial }}</span>
              </div>
              <div class="item-4 cell-info">
                <span class="title-data">Objeto de Análise</span>
                <span class="value">{{
                  item.objectAnalisis.lob_nome_objetos || "NÃO INFORMADO"
                }}</span>
              </div>
              <div class="item-5">
                <div
                  v-show="item.resultado"
                  class="result-item"
                  title="Resultado Disponível"
                >
                  <IconResultExam />
                </div>
              </div>
              <button
                v-show="buttons.showProcedure"
                :disabled="blockScreen || disabledRemove"
                :class="{
                  disable: blockScreen || disabledRemove,
                }"
                data-id="remover-procedimento"
                title="Remover Procedimento"
                class="button-remove-procedure unselect"
                type="button"
                @click="removeFromProcedureList(index)"
              >
                x
              </button>
            </div>
          </div>
        </div>
      </RgValidatorForm>
    </form>

    <div class="commands">
      <div v-show="buttons.emblem" class="emblem">
        <span class="title-footer">Status: </span>
        <span
          :class="{
            'solicitation-emblem': buttons.solicitation && buttons.agendation,
            'agendation-emblem': buttons.agendation && !buttons.solicitation,
            'pending-emblem': buttons.pending,
            'partially-attended-emblem': buttons.partiallyAttended,
            'attended-emblem': buttons.attended,
          }"
          class="emblem-status unselect"
        >
          {{ currentStatus }}
        </span>
      </div>
      <div v-if="buttons.showButtons" class="buttons-footer">
        <button
          v-show="buttons.showClear"
          type="button"
          data-id="limpar-tela"
          class="btn btn-small btn-default unselect"
          title="Limpar todos os campos"
          @click="showConfirmFieldClearing"
        >
          Limpar
        </button>
        <button
          v-show="buttons.solicitation"
          :class="{ disable: disableButtonSolicitation }"
          :disabled="disableButtonSolicitation"
          :title="buttons.titleSaveSolicititation"
          data-id="salvar-solicitacao"
          class="btn btn-small solicitation-color unselect"
          @click="saveSolicitationEffectivation"
        >
          {{ buttons.titleSaveSolicititation }}
        </button>
        <button
          v-show="buttons.agendation && !buttons.reschedule"
          :class="{ disable: disableButtonAgendation }"
          :disabled="disableButtonAgendation"
          :title="titleSaveAgendation"
          data-id="salvar-agendamento"
          class="btn btn-small agendation-color unselect"
          @click="saveScheduledEffectivation"
        >
          {{ titleSaveAgendation }}
        </button>

        <button
          v-show="buttons.reschedule"
          data-id="atualizar-pendencia"
          class="btn btn-small agendation-color unselect"
          title="Reagendar"
          @click="saveReschedule"
        >
          Reagendar
        </button>

        <div class="buttons-footer">
          <button
            v-show="buttons.pending"
            data-id="atualizar-pendencia"
            class="btn btn-small pending-color unselect"
            title="Atualizar Pendência"
            @click="saveExam"
          >
            Atualizar
          </button>
          <button
            v-show="buttons.pending"
            :class="{ disable: disableButtonPending }"
            :disabled="disableButtonPending"
            data-id="salvar-pendencia"
            class="btn btn-small pending-color unselect"
            title="Agendar Pendência"
            @click="savePendingEffectivation"
          >
            Agendar
          </button>
        </div>
      </div>
    </div>

    <MedicalLoader
      :to-close="loading"
      :status="loader"
      :title-loader="'Aguarde, enviando para o Bioslab...'"
      :title-success="'Enviado com sucesso!'"
      :title-failed="'Erro no envio para o Bioslab... '"
      @close="closeLoader"
    />

    <ModalShowError
      v-shortkey="['esc']"
      :show="showError"
      :procedures="errors"
      @close="closeErrorModal"
      @shortkey.native="closeErrorModal"
    />

    <ModalShowTags
      :can-print="canPrint"
      :bioslab-id="leb_id"
      :show="showTags"
      @close="closeTagModal"
    />

    <ModalConfirmRemoveExam
      :show="showModalConfirmRemove"
      type="button"
      data-id="modal-confirma-exclusao"
      @exam-removed="removeFromProcedureList(index, $event)"
      @close="closeModalConfirmRemove"
    />

    <ModalConfirmAddExam
      :show="showModalConfirmAdd"
      type="button"
      data-id="modal-confirma-adicao"
      @exam-add="addProcedure($event)"
      @close="closeModalConfirmAdd"
    />

    <ModalConfirmFieldClearing
      :show="modalConfirmFieldClearing"
      @getYes="confirmCleanForm"
      @getOut="closeConfirmFieldClearing"
    />
  </Modulebox>
</template>

<script>
import moment from "moment";
import FormBase from "~tokio/foundation/form-base/FormBase";
import { AlertError, toast } from "~tokio/primitive/notification";
import { mask } from "vue-the-mask";
import { isEmpty, clone } from "lodash";
import { IconEmpty, IconResultExam } from "~tokio/primitive/icon/symbols";
import {
  ModalShowError,
  ModalShowTags,
} from "$exam/submodules/view/individual-effectivation/component/modal";
import {
  SearchPatientByRecordNumber,
  SearchPatientByBulletin,
  SearchPatientByHospitalizationNumber,
  SearchCidByCode,
  GetEmployeeById,
} from "./action";
import {
  RgSuggestProcedureBioslab,
  RgSuggestSmartPerson,
  RgSuggestEmployee,
  RgSuggestCid,
  RgInputHour,
  RgInput,
  RgValidatorForm,
  RgInputDate,
  RgPersonCardButton,
  RgShowTicketsButton,
  RgTextArea,
} from "~tokio/primitive";
import {
  RgRadioGenderBlock,
  RgInputCns,
  RgSuggestNationality,
  RgSuggestCity,
  RgComboboxEthnicity,
} from "$person/common/components";
import { RgErrorButton } from "~tokio/primitive/button";
import Modulebox from "~tokio/foundation/modulebox/Modulebox";
import MedicalLoader from "~tokio/primitive/loader/medical-loader/MedicalLoader.vue";
import PreparedBioslabExtraInfoToSave from "$exam/store/actions/PreparedBioslabExtraInfoToSave";
import PrepareBioslabExamToSave from "$exam/store/actions/PrepareBioslabExamToSave";
import RgNewButton from "~tokio/primitive/button/rg-new-button/RgNewButton";
import RgCleanButton from "~tokio/primitive/button/rg-clean-button/RgCleanButton";
import RgPdfButton from "~tokio/primitive/button/rg-pdf-button/RgPdfButton";
import SearchBioslabLaboratoryObject from "./action/SearchBioslabLaboratoryObject";
import EXAM_STATUS_LABORATORY from "$exam/submodules/view/individual-effectivation/component/radio/rg-radio-exam-status-laboratory/GetExamStatusLaboratory";
import ModalConfirmRemoveExam from "$exam/submodules/view/individual-effectivation/component/modal/modal-confirm-remove-exam/ModalConfirmRemoveExam";
import ModalConfirmAddExam from "$exam/submodules/view/individual-effectivation/component/modal/modal-confirm-add-exam/ModalConfirmAddExam";
import ModalConfirmFieldClearing from "~tokio/primitive/modal/modal-confirm-field-clearing/ModalConfirmFieldClearing";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb.js";
import { mapGetters } from "vuex";

export default {
  name: "ExamLaboratoryDetails",
  components: {
    IconEmpty,
    IconResultExam,
    Modulebox,
    MedicalLoader,
    ModalShowError,
    ModalConfirmRemoveExam,
    ModalConfirmAddExam,
    RgSuggestSmartPerson,
    RgInputHour,
    RgSuggestEmployee,
    ModalShowTags,
    RgInput,
    RgInputDate,
    RgValidatorForm,
    RgSuggestCid,
    RgPdfButton,
    RgSuggestProcedureBioslab,
    RgErrorButton,
    RgNewButton,
    RgCleanButton,
    RgPersonCardButton,
    ModalConfirmFieldClearing,
    RgShowTicketsButton,
    FormBase,
    RgRadioGenderBlock,
    RgInputCns,
    RgSuggestNationality,
    RgSuggestCity,
    RgTextArea,
    RgComboboxEthnicity,
  },
  directives: {
    mask,
  },
  data() {
    return {
      examPatientData: {},
      lastIndex: 0,
      protocolNumber: null,
      index: null,
      bulletinNumber: "",
      hospitalizationNumber: "",
      recordNumber: "",
      suggestProcedure: "",
      suggestPatient: "",
      urlExam: "",
      patientWeight: null,
      patientHeight: null,
      currentStatus: "",
      currentStatusId: EXAM_STATUS_LABORATORY.NOVO,
      suggestRequestEmployee: "",
      setTitle: "Novo Pedido",
      examData: {},
      examInfo: {},
      errors: [],
      procedureList: [],
      procedureListToRemove: [],
      objectExam: [],
      leb_id: 0,
      qtdExam: 0,
      loader: 0,
      sectorLink: null,
      mainCid: null,
      hasProcedure: false,
      isEmployeeSelected: false,
      disableInputsInRequestData: false,
      disabledInfoMenstruation: false,
      disableDateSchedule: false,
      disableHourSchedule: false,
      modalConfirmFieldClearing: false,
      patientIsValid: false,
      showTags: false,
      loading: false,
      matbio: false,
      showError: false,
      showModalConfirmRemove: false,
      showModalConfirmAdd: false,
      procedure: {
        no_procecimento: "",
        tp_sexo: "",
        vl_idade_minima: "",
        vl_idade_maxima: "",
      },
      buttons: {
        solicitation: true,
        agendation: true,
        pending: false,
        partiallyAttended: false,
        attended: false,
        showProcedure: true,
        editPerson: true,
        titleUpdate: false,
        showButtons: true,
        disableButtons: false,
        emblem: false,
        showClear: true,
        pdf: false,
        reschedule: false,
      },
      obligatoriness: {
        multiplo: "N",
        dum: "N",
        peso: "N",
        altura: "N",
        cor: "N",
        nacionalidade: "N",
        naturalidade: "N",
      },
      validate: {
        colorAndRace: true,
        heightAndWeight: true,
        nationality: true,
        city: true,
      },
      patientInfo: {
        pes_id: 0,
        sexo_sigla: "",
        nasc: "",
        nome: "",
        peso: "",
        altura: "",
        info_medicamento: "",
        cor: "",
        menstruada: "N", // RADIO
        data_menstruacao: "", // DATA MENSTRUAÇÃO
        info_menstruacao: "", // TEXTAREA
        othersData: {},
      },
      exams: {
        collectMaterial: "",
        objectAnalisis: "",
        codigoExameMne: "",
      },
      employeeRequest: {
        id: 0,
        name: "",
        cpf: "",
        uf: "",
        conselho: null,
        numberCons: null,
      },
      form: {
        pes_id: null,
        exe_id: 0,
        exe_id_unidades_saude: null,
        exe_id_pacientes: null,
        exe_id_vinculos: null,
        exe_id_vinculos_solicitante: null,
        exe_id_boletins: null,
        exe_id_internacoes: null,
        exe_codigo_procedimento: null,
        exe_codigo_cid: null,
        exe_hora_exame_sol: null,
        leb_data_solicitacao: moment().format("DD/MM/YYYY"),
        leb_data_agendamento: "",
        leb_hora_agendamento: "",
        exe_id_exames_efetivacao: null,
      },
    };
  },

  computed: {
    ...mapGetters({
      lastPersonIdSaved: "Person/Patient/GET_LAST_PERSON_ID_SAVE",
    }),

    disabledRemove() {
      return (
        this.protocolNumber &&
        this.protocolNumber.length > 0 &&
        this.procedureList.length === 1
      );
    },

    blockScreen() {
      return (
        this.currentStatus === "Agendado" ||
        this.currentStatus === "Parcialmente Atendido" ||
        this.currentStatus === "Atendido"
      );
    },

    blockScreenWithoutScheduled() {
      return (
        this.currentStatus === "Parcialmente Atendido" ||
        this.currentStatus === "Atendido"
      );
    },

    canPrint() {
      return this.currentStatus !== "Atendido";
    },

    hasPersonId() {
      return this.form.pes_id && this.form.pes_id > 0;
    },

    hasPermission() {
      return true;
    },

    validateExam() {
      return !!(this.form.pes_id && this.employeeRequest.id);
    },

    showObjectExam() {
      return this.objectExam.length === 0 || this.objectExam.length === 157;
    },

    hasProcedureList() {
      return this.procedureList.length >= 1;
    },

    isMenstruationRadioTrue() {
      return this.patientInfo.menstruada === "S";
    },

    isWoman() {
      return this.examPatientData.gender === "F";
    },

    hasUrl() {
      return !isEmpty(this.urlExam);
    },

    getUrl() {
      return window.esusng.serverConfig.reportsURL + this.urlExam;
    },

    titleSaveAgendation() {
      return this.buttons.titleUpdate && !this.buttons.solicitation
        ? "Atualizar"
        : "Agendar";
    },

    titleModalEditPerson() {
      return this.form.pes_id ? "Editar Paciente" : "Cadastrar Paciente";
    },

    disabledRadioGender() {
      return !this.isWoman || this.disabledInfoMenstruation;
    },

    disableBulletinNumber() {
      return (
        this.hospitalizationNumber.length > 0 ||
        this.disableInputsInRequestData ||
        this.blockScreen
      );
    },

    disableHospitalizationNumber() {
      return (
        this.bulletinNumber.length > 0 ||
        this.disableInputsInRequestData ||
        this.blockScreen
      );
    },

    disableProcedureSuggest() {
      return !this.validateExam;
    },

    disableTextAreaMenstruation() {
      return (
        !this.form.pes_id ||
        !this.isWoman ||
        this.disabledInfoMenstruation ||
        this.isMenstruationRadioTrue
      );
    },

    disableAddProcedure() {
      return !this.hasProcedure || this.buttons.disableButtons;
    },

    disableButtonSolicitation() {
      return !this.hasProcedureList || !this.isEmployeeSelected;
    },

    disableButtonAgendation() {
      return (
        !this.isEmployeeSelected ||
        !this.hasProcedureList ||
        (!this.disableDateSchedule && !this.disableHourSchedule)
      );
    },

    disableButtonPending() {
      return (
        !this.isEmployeeSelected ||
        !this.hasProcedureList ||
        !this.disableDateSchedule ||
        !this.form.leb_hora_agendamento
      );
    },

    showAddButton() {
      return (
        (this.buttons.solicitation || this.buttons.agendation) &&
        this.setTitle !== "Novo Pedido" &&
        !this.buttons.attended &&
        !this.buttons.partiallyAttended
      );
    },
  },
  watch: {
    protocolNumber(pValue) {
      this.disableInputsInRequestData = pValue && pValue.length > 0;
    },

    patientWeight(pValue) {
      this.patientInfo.peso = this.sanitizeNumber(pValue);
    },

    patientHeight(pValue) {
      this.patientInfo.altura = this.sanitizeNumber(pValue);
    },

    suggestProcedure(pValue) {
      this.suggestProcedure = pValue;
      if (pValue && pValue.length < 1) {
        this.cleanProcedureInfo();
      }
    },

    procedureList(pValue) {
      this.qtdExam = pValue.length;
    },

    suggestPatient(pValue) {
      this.suggestPatient = pValue;
      if (isEmpty(pValue)) {
        this.patientInfo.nome = "";
        this.recordNumber = null;
      }
    },

    "form.leb_data_agendamento"(pValue) {
      this.disableDateSchedule = pValue.length === 10;
    },

    "form.leb_hora_agendamento"(pValue) {
      if (pValue) {
        this.disableHourSchedule =
          pValue.length === 5 &&
          parseInt(pValue.substring(0, 2)) < 25 &&
          parseInt(pValue.substring(3)) < 60;
      }
    },

    "patientInfo.menstruada"(pValue) {
      if (pValue === "S") {
        this.patientInfo.info_menstruada = "";
      }

      if (pValue === "N") {
        this.patientInfo.data_menstruacao = "";
      }

      if (this.isWoman && pValue === null) {
        this.patientInfo.menstruada = "N";
        this.patientInfo.info_menstruacao = "";
      }
      this.$refs.dateMenst.validate();
    },
  },

  created() {
    this.REMOVE_FILTER = [
      { label: "Prontuário Único", col: "recordNumber" },
      { label: "Data de Nascimento", col: "birthDate" },
    ];
  },

  destroyed() {
    const existThisRoute = ValidateIfRouteExistInBreadscrumb(
      "/exam/laboratory",
      this.$route.meta.breadcrumb,
    );

    if (!existThisRoute) {
      this.$store.commit("Exam/RESET_FILTER_BIOSLAB");
    }
  },

  mounted() {
    if (this.lastPersonIdSaved) {
      this.$refs.patient.fillPatientById(this.lastPersonIdSaved);
    }

    this.examData = this.$route.params.examData;
    if (!this.examData) {
      this.examData = this.$store.getters["Exam/Laboratory/GET_EXAMINFO"];
    }
    this.patientWeight = this.patientInfo.peso;
    this.patientHeight = this.patientInfo.altura;

    this.setExamOrPatient();

    this.mountExam(this.examData);
    this.mountPage(this.examData.leb_id_laboratoriais_status_pedido);
  },

  methods: {
    setExamOrPatient() {
      const ethnicity = this.$refs.ethnicity.getMData();

      const objEthnicity = ethnicity.find((item) => {
        return (
          item.text === this.examData.leb_cor_paciente ||
          item.text === this.patientInfo.othersData.pes_etnias_nomes
        );
      });

      this.examPatientData = {
        gender: this.examData?.leb_sexo_paciente
          ? this.examData.leb_sexo_paciente
          : this.patientInfo.sexo_sigla,
        birthday: this.examData?.leb_nascimento_paciente
          ? moment(this.examData.leb_nascimento_paciente).format("DD/MM/YYYY")
          : this.patientInfo.othersData.pes_nascimento,
        colorAndRace: this.examData?.leb_cor_paciente
          ? this.examData.leb_cor_paciente
          : this.patientInfo.othersData.pes_etnias_nomes,
        colorAndRaceId: objEthnicity ? objEthnicity.value : null,
        telephone: this.examData?.leb_telefone_paciente
          ? this.examData.leb_telefone_paciente
          : this.patientInfo.othersData.tel_full,
        cns: this.examData?.leb_cns_paciente
          ? this.examData.leb_cns_paciente
          : this.patientInfo.othersData.crs_numero,
        nationality: this.examData?.leb_nacionalidade_paciente
          ? this.examData.leb_nacionalidade_paciente
          : this.patientInfo.othersData.nac_nome,
        city: this.examData?.leb_naturalidade_paciente
          ? this.examData.leb_naturalidade_paciente
          : this.patientInfo.othersData.mun_nome,
      };
    },

    ethnicityPatient(pValue) {
      this.examPatientData.colorAndRace = pValue[0].text;
    },

    prepareNewExam() {
      this.mountPage(EXAM_STATUS_LABORATORY.NOVO);
      this.cleanForm();
    },

    verifyRetroactiveDate(pValue, pErrors) {
      const convertSolicitation = this.$utils.date.BrazilianDateToDatabase(
        this.form.leb_data_solicitacao,
      );
      const convertDate = this.$utils.date.BrazilianDateToDatabase(pValue);

      return !pValue || moment(convertDate).isSameOrAfter(convertSolicitation)
        ? true
        : AlertError(
            "A data de agendamento não pode ser retroativa à data de solicitação.",
            pErrors,
          );
    },

    hasAgendationDate(pValue, pErrors) {
      const hourLength = 5;

      if (this.form.leb_data_agendamento) {
        pValue = this.form.leb_hora_agendamento;

        if (pValue.length !== hourLength) {
          return AlertError("A hora é obrigatória", pErrors);
        }
      }
      return true;
    },

    mountPage(status) {
      this.buttons.titleUpdate = true;

      if (!status) {
        this.setTitle = "Novo Pedido";
        this.buttons.emblem = false;
        this.buttons.showClear = true;
        this.buttons.solicitation = true;
        this.buttons.disableButtons = false;
        this.buttons.showButtons = true;
        this.buttons.editPerson = true;
        this.buttons.titleSaveSolicititation = "Solicitar";
        this.currentStatusId = EXAM_STATUS_LABORATORY.NOVO;
        this.currentStatus = "";
      } else {
        this.setTitle = "Editar Pedido";
        this.currentStatusId = status;
        this.buttons.emblem = true;
        this.buttons.showClear = false;

        switch (status) {
          case EXAM_STATUS_LABORATORY.SOLICITADO:
            this.currentStatus = "Solicitado";
            this.buttons.titleSaveSolicititation = "Atualizar";
            this.buttons.solicitation = true;
            this.buttons.agendation = true;
            break;
          case EXAM_STATUS_LABORATORY.AGENDADO:
            this.currentStatus = "Agendado";
            this.disabledInfoMenstruation = true;
            this.buttons.agendation = true;
            this.buttons.reschedule = true;
            break;
          case EXAM_STATUS_LABORATORY.PENDENTE:
            this.currentStatus = "Pendente";
            this.buttons.pending = true;
            this.buttons.solicitation = false;
            this.buttons.agendation = false;
            break;
          case EXAM_STATUS_LABORATORY.PARCIALMENTE_ATENDIDO:
            this.currentStatus = "Parcialmente Atendido";
            this.buttons.partiallyAttended = true;
            this.buttons.pdf = true;
            break;
          case EXAM_STATUS_LABORATORY.ATENDIDO:
            this.currentStatus = "Atendido";
            this.disabledInfoMenstruation = true;
            this.buttons.attended = true;
            this.buttons.pdf = true;
            break;
        }

        if (
          [
            EXAM_STATUS_LABORATORY.AGENDADO,
            EXAM_STATUS_LABORATORY.PARCIALMENTE_ATENDIDO,
            EXAM_STATUS_LABORATORY.ATENDIDO,
          ].includes(status)
        ) {
          this.setTitle = "Visualização do Pedido";
          this.buttons.solicitation = false;
          this.buttons.pending = false;
          this.buttons.editPerson = false;
          this.buttons.showProcedure = false;
          this.buttons.showButtons = false;
          this.buttons.disableButtons = true;
          if (status === EXAM_STATUS_LABORATORY.AGENDADO) {
            this.buttons.showButtons = true;
          }
        }
      }
    },

    async examObjects(pCodeSigtap, pCodeObjectBioslab, validate = true) {
      let result = [];
      let sex = "";

      if (validate) {
        sex = this.examPatientData.gender;

        result = await SearchBioslabLaboratoryObject({
          lle_codigo_sigtap: pCodeSigtap,
          lle_codigo_bioslab_mne: pCodeObjectBioslab,
        });

        for (let i = 0; i < result.length; i++) {
          if (sex === "M") {
            if (result[i].lob_id === 5) {
              result.splice(i, 1);
            }
            if (result[i].lob_id === 6) {
              result.splice(i, 1);
            }
          } else if (sex === "F") {
            if (result[i].lob_id === 7) {
              result.splice(i, 1);
            }
          }
        }
      }

      this.objectExam = result;
    },

    async getListProcedure(pValue) {
      try {
        this.$loader.start("Carregando Procedimentos...");
        const auxArray = await this.$store.dispatch(
          "Bioslab/SEARCH_BIOSLAB_LABORATORY_PATIENT_EXAMS",
          {
            lpe_id_laboratoriais_exames_bioslab: pValue,
            lpe_ativo: "1",
            withExamData: true,
          },
        );

        auxArray.rows.forEach(async (item) => {
          let examReady = false;

          if (item.lpe_url_resultado && item.lpe_url_resultado.length > 0) {
            this.urlExam = item.lpe_url_resultado;
            examReady = true;
          }

          if (item.exam.lle_id) {
            const procedure = item.exam;
            const {
              CO_PROCEDIMENTO,
              NO_PROCEDIMENTO,
              TP_SEXO,
              VL_IDADE_MAXIMA,
              VL_IDADE_MINIMA,
            } = item.exam.procedure;

            if (procedure.lle_ativo !== "0") {
              this.errors.push({
                ...item,
                nome: procedure.lle_procedimentos,
              });
            }

            const obj = {
              lpe_id: item.lpe_id,
              nome: procedure.lle_procedimentos,
              codigo: item.lpe_codigo_sigtap,
              resultado: examReady,
              collectMaterial: item.lpe_material_biologico,
              codigoExameMne: item.lpe_codigo_exame_bioslab_mne,
              pep_id: item.lpe_id_externo_pep,
              objectAnalisis: {
                lob_nome_objetos: item.lpe_objeto_analise,
              },
              obligatoriness: {
                multiplo: procedure.lle_multiplo,
                dum: procedure.lle_dum,
                peso: procedure.lle_peso,
                altura: procedure.lle_altura,
                cor: procedure.lle_cor,
                nacionalidade: procedure.lle_nacionalidade,
                naturalidade: procedure.lle_naturalidade,
              },
              procedure: {
                co_procedimento: CO_PROCEDIMENTO,
                no_procecimento: NO_PROCEDIMENTO,
                tp_sexo: TP_SEXO,
                vl_idade_maxima: VL_IDADE_MAXIMA,
                vl_idade_minima: VL_IDADE_MINIMA,
              },
            };

            this.procedureList.push(obj);
          }

          // ORDER ARRAY BY lpe_id_externo_pep
          this.procedureList.sort((a, b) => {
            return a.pep_id - b.pep_id;
          });
        });
      } catch (err) {
        this.$toaster.error(err.message);
      } finally {
        this.$loader.finish();
      }
    },

    async mountExamByLebId(pLebId, pClearFormBefore = false) {
      const results = await this.searchBioslabLaboratoryExam(pLebId);

      if (results && results.rows && results.rows.length > 0) {
        if (pClearFormBefore) {
          this.cleanForm();
        }

        await this.mountExam(results.rows[0]);
      }
    },

    async mountExam(pExam, pUpdateVuex = true) {
      this.form.exe_id_unidades_saude = this.$store.getters[
        "Login/GET_UNIT_HEALTH_ID"
      ];

      if (pUpdateVuex) {
        this.updateVuex(pExam);
      }

      if (pExam.leb_id) {
        this.$nextTick(async () => {
          this.leb_id = pExam.leb_id;
          const pesId =
            pExam.patient && pExam.patient.person
              ? pExam.patient.person.pes_id
              : 0;

          this.$refs.patient.fillPatientById(pesId);

          this.bulletinNumber = pExam.bol_numero
            ? pExam.bol_numero.toString()
            : "";
          this.hospitalizationNumber = pExam.int_numero
            ? pExam.int_numero.toString()
            : "";
          this.protocolNumber = pExam.leb_codigo_solicitacao;

          if (this.protocolNumber && this.protocolNumber.length > 0) {
            this.disableInputsInRequestData = true;
          }

          this.recordNumber = pExam.leb_prontuario;
          this.patientInfo.sexo_sigla = pExam.leb_sexo_paciente;

          const employeeExtraInfo = await this.$store.dispatch(
            "Employee/GET_EMPLOYEE_SECTOR_RELATIONSHIPS",
            {
              criterias: {
                employeeId: Number(pExam.leb_id_profissional_solicitante),
              },
            },
          );

          const employee_vin_id =
            employeeExtraInfo && employeeExtraInfo.length > 0
              ? employeeExtraInfo[0].employeeRelationship.vin_id
              : 0;

          this.getListProcedure(pExam.leb_id);

          this.employeeRequest.id = pExam.leb_id_profissional_solicitante;
          this.isEmployeeSelected = false;

          const employeeData = await GetEmployeeById({
            funId: Number(pExam.leb_id_profissional_solicitante),
          });

          const requester = {
            pes_nome: employeeData?.basicInfo?.pes_nome || null,
            pes_id: employeeData?.basicInfo?.pes_id || null,
            fun_conselho_classe:
              employeeData?.employeeData?.fun_conselho_classe || null,
            vin_id: employee_vin_id,
            person: {
              address: {
                neighborhood: {
                  city: {
                    state: {
                      est_sigla:
                        employeeData?.basicInfo?.address?.neighborhood?.city
                          ?.state?.est_sigla || null,
                    },
                  },
                },
              },
              cpf: {
                cpf_numero: employeeData?.basicInfo?.cpf?.cpf_numero || null,
              },
            },
            employeeRelationship: {
              employee: {
                fun_id: pExam.leb_id_profissional_solicitante,
              },
              vin_id_funcionarios: pExam.leb_id_profissional_solicitante,
            },
          };

          this.$refs.Requester.forceSelection(requester);

          this.patientInfo.pes_id = pesId;
          this.employeeRequest.id = pExam.leb_id_profissional_solicitante;

          this.form.leb_data_agendamento = pExam.leb_data_agendamento
            ? moment(pExam.leb_data_agendamento, "YYYY-MM-DD").format(
                "DD/MM/YYYY",
              )
            : "";

          this.form.leb_hora_agendamento = pExam.leb_hora_agendamento;

          this.patientInfo.info_medicamento = pExam.leb_medicamentos;

          this.patientInfo.menstruada = pExam.leb_menstruada;

          this.form.leb_data_solicitacao = this.$utils.date.BrazilianDateFormat(
            pExam.leb_data_solicitacao,
          );

          if (pExam.leb_informacoes_menstruacao) {
            if (this.isMenstruationRadioTrue) {
              this.patientInfo.data_menstruacao = this.$utils.date.BrazilianDateFormat(
                pExam.leb_informacoes_menstruacao,
              );
            } else {
              this.patientInfo.info_menstruada =
                pExam.leb_informacoes_menstruacao;
            }
            this.$refs.dateMenst.validate();
          }

          this.patientWeight = pExam.leb_peso !== "0.00" ? pExam.leb_peso : "";
          this.patientHeight =
            pExam.leb_altura !== "0.00" ? pExam.leb_altura : "";

          if (pExam.leb_mensagem_erro) {
            this.errors.push({ leb_mensagem_erro: pExam.leb_mensagem_erro });
          }

          const cidData = await SearchCidByCode({
            cid_codigo: pExam.leb_cid,
          });

          if (cidData && this.$refs.mainCid) {
            this.$refs.mainCid.forceSelection({
              cid_codigo: cidData.cid_codigo,
              cid_nome: cidData.cid_nome,
            });
          }
        });
      }
    },

    async addProcedure(reason = null) {
      if (
        !this.protocolNumber &&
        !this.disableAddProcedure &&
        this.validateAddProcedures()
      ) {
        this.procedureList.push({
          nome: this.suggestProcedure,
          codigo: this.form.exe_codigo_procedimento,
          collectMaterial: this.exams.collectMaterial,
          objectAnalisis: this.exams.objectAnalisis,
          codigoExameMne: this.exams.codigoExameMne,
          procedure: this.procedure,
          obligatoriness: clone(this.obligatoriness),
        });
        this.cleanProcedureInfo();
      }

      if (this.protocolNumber && this.protocolNumber.length > 1) {
        this.showModalConfirmAdd = true;

        if (reason) {
          if (this.validateAddProcedures()) {
            this.procedureList.push({
              nome: this.suggestProcedure,
              codigo: this.form.exe_codigo_procedimento,
              collectMaterial: this.exams.collectMaterial,
              objectAnalisis: this.exams.objectAnalisis,
              codigoExameMne: this.exams.codigoExameMne,
              obligatoriness: clone(this.obligatoriness),
              procedure: this.procedure,
              reasonInclusion: reason,
            });
            this.cleanProcedureInfo();
          }
          this.closeModalConfirmAdd();
        }
      }
    },

    canAddMultiplo() {
      const auxObject = {
        nome: this.suggestProcedure,
        codigo: this.form.exe_codigo_procedimento,
        codigoExameMne: this.exams.codigoExameMne,
        material: this.exams.collectMaterial,
        lob_nome_objetos: !isEmpty(this.exams.objectAnalisis)
          ? this.exams.objectAnalisis.lob_nome_objetos
          : null,
      };

      const getProcedureObjectAnalisys = (pProcedure) => {
        return !isEmpty(pProcedure.objectAnalisis)
          ? pProcedure.objectAnalisis.lob_nome_objetos
          : null;
      };

      const multipleCheck = (pProcedure) => {
        return (
          pProcedure.nome === auxObject.nome &&
          pProcedure.collectMaterial === auxObject.material &&
          getProcedureObjectAnalisys(pProcedure) === auxObject.lob_nome_objetos
        );
      };

      const singleCheck = (pProcedure) => {
        return (
          pProcedure.nome === auxObject.nome &&
          getProcedureObjectAnalisys(pProcedure) === auxObject.lob_nome_objetos
        );
      };

      const existsEqual = this.procedureList.some(
        this.obligatoriness.multiplo === "S" ? multipleCheck : singleCheck,
      );
      return !existsEqual;
    },

    hasMultiplo(examRules = "N", procedure) {
      let count = 0;

      this.procedureList.forEach((item) => {
        const singleCheck =
          item.nome === procedure.nome &&
          (item.objectAnalisis === procedure.objectAnalisis ||
            item.objectAnalisis.lob_nome_objetos ===
              procedure.objectAnalisis.lob_nome_objetos);

        const multipleCheck =
          singleCheck && item.collectMaterial === procedure.material;

        if (examRules === "N") {
          if (singleCheck) {
            count++;
          }
        } else {
          if (multipleCheck) {
            count++;
          }
        }
      });

      return count <= 1;
    },

    validateAge() {
      const minAge = (this.procedure.vl_idade_minima / 12).toFixed(1);
      const maxAge = (this.procedure.vl_idade_maxima / 12).toFixed(1);

      const patienteBirth = moment(
        this.examPatientData.birthday,
        "DD/MM/YYYY",
      ).format("MM-DD-YYYY");

      const ageDifference = moment().diff(patienteBirth);
      const diffDuration = moment.duration(ageDifference);
      const age = diffDuration.years() + "." + diffDuration.months();

      return (
        parseFloat(age) >= parseFloat(minAge) &&
        parseFloat(age) <= parseFloat(maxAge)
      );
    },

    validateColorRace(examRules = "N") {
      const race = this.examPatientData?.colorAndRace;
      if (
        (this.obligatoriness.cor === "S" || examRules === "S") &&
        (!race ||
          race === "" ||
          race === "NÃO INFORMADO" ||
          race === "SEM INFORMAÇÃO")
      ) {
        this.validate.colorAndRace = false;
        return false;
      }
      this.validate.colorAndRace = true;
      return true;
    },

    validateHeightAndWeight(examRulesWeight = "N", examRulesHeight = "N") {
      const weight = this.patientWeight;

      const height = this.patientHeight;

      if (
        (this.obligatoriness.peso === "S" ||
          examRulesWeight === "S" ||
          this.obligatoriness.altura === "S" ||
          examRulesHeight === "S") &&
        (!weight || !height)
      ) {
        this.validate.heightAndWeight = false;
        return false;
      }
      this.validate.heightAndWeight = true;
      return true;
    },

    weightIsValid(pValue, pErrors) {
      pValue = pValue ? pValue.replace(",", ".") : "";

      if (!pValue || (pValue > 0.225 && pValue < 601)) {
        return true;
      } else {
        const errorMessage =
          pValue > 600 ? "O valor máximo é 600." : "O valor mínimo é 00,225.";
        return AlertError(errorMessage, pErrors);
      }
    },

    heightIsValid(pValue, pErrors) {
      pValue = pValue ? pValue.replace(",", ".") : "";
      return !pValue || (pValue > 0.09 && pValue < 2.5)
        ? true
        : AlertError("Altura fora do limite possível.", pErrors);
    },

    validateNationality(examRules = "N") {
      if (
        (this.obligatoriness.nacionalidade === "S" || examRules === "S") &&
        !this.examPatientData.nationality
      ) {
        this.validate.nationality = false;
        return false;
      }
      this.validate.nationality = true;
      return true;
    },

    validateCity(examRules = "N") {
      if (
        (this.obligatoriness.naturalidade === "S" || examRules === "S") &&
        !this.examPatientData.city
      ) {
        this.validate.city = false;
        return false;
      }
      this.validate.city = true;
      return true;
    },

    validateDateMenstruation(pValue, pErrors) {
      if (this.isMenstruationRadioTrue && pValue) {
        const convertData = this.$utils.date.BrazilianDateToDatabase(pValue);
        if (!pValue || pValue.length < 10) {
          return AlertError(
            "A data da última menstruação é obrigatória.",
            pErrors,
          );
        }

        if (moment(convertData).isSameOrAfter(moment())) {
          return AlertError("A data não pode ser futura.", pErrors);
        }
      }
      return true;
    },

    validateObjectAnalysis() {
      if (this.objectExam.length > 0 && !this.exams.objectAnalisis) {
        return false;
      }
      return true;
    },

    checkSexIsValidFemale() {
      if (this.procedure.tp_sexo === "I" || this.procedure.tp_sexo === "N") {
        return true;
      } else {
        return this.procedure.tp_sexo === "F";
      }
    },

    checkSexIsValidMale() {
      if (this.procedure.tp_sexo === "I" || this.procedure.tp_sexo === "N") {
        return true;
      } else {
        return this.procedure.tp_sexo === "M";
      }
    },

    showConfirmFieldClearing() {
      this.modalConfirmFieldClearing = true;
    },

    confirmCleanForm() {
      this.cleanForm();
      this.modalConfirmFieldClearing = false;
    },

    closeConfirmFieldClearing() {
      this.modalConfirmFieldClearing = false;
    },

    validadeMenstruation(examRules = "N") {
      let result = { valid: true, errorMessage: null };

      if (this.isWoman) {
        const dateLastMentrualDate = this.patientInfo.data_menstruacao;
        const convertData = this.$utils.date.BrazilianDateToDatabase(
          dateLastMentrualDate,
        );
        const haveLastMenstrualDate =
          !dateLastMentrualDate || dateLastMentrualDate.length < 10;
        const futureDate = moment(convertData).isSameOrAfter(moment());

        if (this.obligatoriness.dum === "S" || examRules === "S") {
          if (
            !this.isMenstruationRadioTrue &&
            !this.patientInfo.info_menstruada
          ) {
            result = {
              valid: false,
              errorMessage:
                "O motivo da ausência de menstruação é obrigatório.",
            };
          } else if (
            this.isMenstruationRadioTrue &&
            (haveLastMenstrualDate || futureDate)
          ) {
            result = {
              valid: false,
              errorMessage: "A data da última mentruação é obrigatória.",
            };
          }
        }
      }
      return result;
    },

    validadeMaterial(pErrors) {
      return this.exams.collectMaterial.length > 0;
    },

    validateProfessionalData(pValue, pErrors) {
      if (
        pValue &&
        this.isEmployeeSelected &&
        (!this.employeeRequest.cpf ||
          !this.employeeRequest.name ||
          !this.employeeRequest.uf ||
          !this.employeeRequest.numberCons ||
          !this.employeeRequest.conselho)
      ) {
        // this.isEmployeeSelected = false;

        /* :TODO: Comentei a linha acima pois estava desabilitando o botão "Atualizar"
        ao tentar agendar um paciente do qual o cadastro do profissional selecionado
        estivesse com dados obrigatórios faltando (Ex.: o CPF do profissional) */

        this.employeeRequest.id = 0;

        if (!this.employeeRequest.cpf) {
          return AlertError(
            "Profissional não possui CPF no cadastro.",
            pErrors,
          );
        }
        if (!this.employeeRequest.name) {
          return AlertError(
            "Profissional não possui Nome no cadastro.",
            pErrors,
          );
        }
        if (!this.employeeRequest.uf) {
          return AlertError("Profissional não possui UF no cadastro.", pErrors);
        }
        if (!this.employeeRequest.numberCons) {
          return AlertError(
            "Profissional não possui Número do Conselho no cadastro.",
            pErrors,
          );
        }
        if (!this.employeeRequest.conselho) {
          return AlertError(
            "Profissional não possui Tipo do Conselho no cadastro.",
            pErrors,
          );
        }
      }
      return true;
    },

    validateAddProcedures() {
      if (!this.validateExam) {
        return AlertError("Verifique os campos.");
      }

      if (!this.canAddMultiplo()) {
        return AlertError("Esse procedimento não pode ser duplicado.");
      }

      if (this.isWoman) {
        if (!this.checkSexIsValidFemale()) {
          return AlertError(
            "Esse procedimento não pode ser adicionado para o sexo feminino.",
          );
        }
      } else {
        if (!this.checkSexIsValidMale()) {
          return AlertError(
            "Esse procedimento não pode ser adicionado para o sexo masculino.",
          );
        }
      }

      if (!this.validateAge()) {
        return AlertError(
          "A idade do paciente não corresponde ao tipo de procedimento solicitado.",
        );
      }

      if (!this.validateColorRace()) {
        return AlertError("A cor/raça do paciente precisa ser informada.");
      }

      if (!this.validateHeightAndWeight()) {
        return AlertError(
          "O campo de peso e altura são obrigatórios para o procedimento selecionado.",
        );
      }

      if (!this.validateNationality()) {
        return AlertError("O campo de nacionalidade é obrigatório.");
      }

      if (!this.validateCity()) {
        return AlertError("O campo de naturalidade é obrigatório.");
      }

      const menstruationValid = this.validadeMenstruation();
      if (!menstruationValid.valid) {
        return AlertError(menstruationValid.errorMessage);
      }

      if (!this.validadeMaterial()) {
        return AlertError("O campo material de coleta não pode ser vazio.");
      }

      if (!this.validateObjectAnalysis()) {
        return AlertError("O campo objeto de análise precisa ser selecionado.");
      }

      if (!this.validateExamStatusPending()) {
        return AlertError(
          "Os campos de data e hora de agendamento são obrigatórios. Verifique as informações.",
        );
      }

      return true;
    },

    validateProcedureList() {
      const procedure = this.procedureList.some((pProcedure) => {
        if (this.isWoman) {
          if (
            pProcedure.obligatoriness &&
            pProcedure.obligatoriness.dum === "S"
          ) {
            return (
              (this.isMenstruationRadioTrue &&
                !this.patientInfo.data_menstruacao) ||
              (!this.isMenstruationRadioTrue &&
                !this.patientInfo.info_menstruada)
            );
          }
        }
      });

      return !procedure;
    },

    validateExamStatusPending() {
      return this.currentStatusId === EXAM_STATUS_LABORATORY.PENDENTE
        ? this.form.leb_data_agendamento && this.form.leb_hora_agendamento
        : true;
    },

    async saveExam() {
      this.saveEffectivation(false);
    },

    async saveReschedule() {
      this.saveEffectivation(false, EXAM_STATUS_LABORATORY.AGENDADO, true);
    },

    async saveSolicitationEffectivation() {
      this.saveEffectivation(false, EXAM_STATUS_LABORATORY.SOLICITADO);
    },

    async saveScheduledEffectivation() {
      this.saveEffectivation(true, EXAM_STATUS_LABORATORY.AGENDADO);
    },

    async savePendingEffectivation() {
      this.saveEffectivation(true, EXAM_STATUS_LABORATORY.PENDENTE);
    },

    async saveEffectivation(
      pSendToBioslab,
      pStatus = null,
      reschedule = false,
    ) {
      try {
        this.examInfo = this.getExamInfo(reschedule);

        const isValidProcedures = this.isProceduresValid();

        if (isValidProcedures) {
          const status = pStatus || this.currentStatusId;
          const savedValue = await this.saveExamLaboratoryDetails(
            status,
            reschedule,
          );
          if (savedValue) {
            await this.mountExamByLebId(savedValue.leb_id, true);

            if (pSendToBioslab) {
              const hasError = await this.handleSendToBioslab(
                savedValue,
                status,
              );
              if (hasError) {
                throw new Error(hasError);
              }
            } else {
              this.mountPage(savedValue.leb_id_laboratoriais_status_pedido);
            }
          }
        }
      } catch (error) {
        return AlertError(error);
      }
    },

    isProceduresValid() {
      this.procedureList.forEach((pProcedure) => {
        const {
          altura,
          cor,
          dum,
          multiplo,
          nacionalidade,
          naturalidade,
          peso,
        } = pProcedure.obligatoriness;

        this.procedure = pProcedure.procedure;

        this.examObjects(
          pProcedure.codigo,
          pProcedure.codigoExameMne.trim() || "",
          !!pProcedure.objectAnalisis.lob_nome_objetos,
        );

        if (!this.validateHeightAndWeight(peso, altura)) {
          throw new Error(
            "O campo de peso e altura são obrigatórios para o procedimento selecionado.",
          );
        }

        if (!this.validateColorRace(cor)) {
          throw new Error("A cor/raça do paciente precisa ser informada.");
        }

        const menstruationValid = this.validadeMenstruation(dum);
        if (!menstruationValid.valid) {
          throw new Error(menstruationValid.errorMessage);
        }

        if (!this.hasMultiplo(multiplo, pProcedure)) {
          throw new Error("Esse procedimento não pode ser duplicado.");
        }

        if (!this.validateNationality(nacionalidade)) {
          throw new Error("O campo de nacionalidade é obrigatório.");
        }

        if (!this.validateCity(naturalidade)) {
          throw new Error("O campo de naturalidade é obrigatório.");
        }

        if (this.isWoman) {
          if (!this.checkSexIsValidFemale()) {
            throw new Error(
              "Esse procedimento não pode ser adicionado para o sexo feminino.",
            );
          }
        } else {
          if (!this.checkSexIsValidMale()) {
            throw new Error(
              "Esse procedimento não pode ser adicionado para o sexo masculino.",
            );
          }
        }

        if (!this.validateAge()) {
          throw new Error(
            "A idade do paciente não corresponde ao tipo de procedimento solicitado.",
          );
        }

        if (!pProcedure.collectMaterial.length > 0) {
          throw new Error("O campo material de coleta não pode ser vazio.");
        }

        if (this.objectExam.length > 0 && !pProcedure.objectAnalisis) {
          throw new Error("O campo objeto de análise precisa ser selecionado.");
        }
      });

      this.procedure = {
        no_procecimento: "",
        tp_sexo: "",
        vl_idade_maxima: "",
        vl_idade_minima: "",
      };

      return true;
    },

    getExamInfo(reschedule = false) {
      const procedureList = this.procedureList.map((procedure) => {
        const { obligatoriness, ...procedureInfo } = procedure;
        return procedureInfo;
      });

      const extraInfoPerson = this.prepareDataToBioslab();
      let info_mentruation = this.patientInfo.info_menstruada;

      if (this.patientInfo.data_menstruacao && this.isMenstruationRadioTrue) {
        info_mentruation = this.$utils.date.BrazilianDateToDatabase(
          this.patientInfo.data_menstruacao,
        );
      }

      if (reschedule) {
        return {
          leb_id: this.leb_id,
          procedureList,
          leb_data_agendamento: this.form.leb_data_agendamento
            ? this.$utils.date.BrazilianDateToDatabase(
                this.form.leb_data_agendamento,
              )
            : null,
          leb_hora_agendamento: this.form.leb_hora_agendamento
            ? this.form.leb_hora_agendamento
            : null,
        };
      }

      return {
        leb_id: this.leb_id,
        extraInfoPerson,
        procedureList,
        leb_id_pacientes: this.form.exe_id_pacientes,
        leb_id_internacoes: this.form.exe_id_internacoes,
        leb_nome_paciente: this.patientInfo.nome,
        leb_sexo_paciente: this.examPatientData.gender,
        leb_id_profissional_solicitante: this.employeeRequest.id,
        leb_profissional_solicitante: this.employeeRequest.name,
        leb_cpf_profissional: this.employeeRequest.cpf,
        leb_uf_profissional: this.employeeRequest.uf,
        leb_sigla_conselho: this.employeeRequest.conselho,
        leb_numero_conselho: this.employeeRequest.numberCons,
        leb_menstruada: this.patientInfo.menstruada,
        leb_peso: this.patientInfo.peso,
        leb_altura: this.patientInfo.altura,
        leb_medicamentos: this.patientInfo.info_medicamento,
        leb_informacoes_menstruacao: info_mentruation,
        exe_codigo_cid: this.form.exe_codigo_cid,
        exe_id_boletins: this.form.exe_id_boletins,
        exe_codigo_procedimento: this.form.exe_codigo_procedimento,
        exe_id_vinculos: this.form.exe_id_vinculos_solicitante,
        exe_id_unidades_saude: this.form.exe_id_unidades_saude,
        leb_data_agendamento: this.form.leb_data_agendamento
          ? this.$utils.date.BrazilianDateToDatabase(
              this.form.leb_data_agendamento,
            )
          : null,
        leb_hora_agendamento: this.form.leb_hora_agendamento
          ? this.form.leb_hora_agendamento
          : null,
        leb_data_solicitacao: this.$utils.date.BrazilianDateToDatabase(
          this.form.leb_data_solicitacao,
        ),
        leb_prontuario: Number(this.recordNumber),
        leb_codigo_solicitacao: this.protocolNumber,
      };
    },

    async saveExamLaboratoryDetails(pStatus, reschedule) {
      const validation = await this.$refs.validator.validate();
      const validateProcedure = await this.validateProcedureList();

      if (!validation) {
        return AlertError("Verifique os campos.");
      }

      if (!validateProcedure) {
        return AlertError(
          "As informações da menstruação podem ter sido alteradas. Favor verificar se os campos estão preenchidos corretamente.",
        );
      }

      let procedureInfo = [];

      let examInfo = null;
      if (reschedule) {
        examInfo = PrepareBioslabExamToSave(
          this.examInfo,
          EXAM_STATUS_LABORATORY.AGENDADO,
          true,
        );
      } else {
        examInfo =
          pStatus === EXAM_STATUS_LABORATORY.AGENDADO
            ? PrepareBioslabExamToSave(
                this.examInfo,
                EXAM_STATUS_LABORATORY.SOLICITADO,
              )
            : PrepareBioslabExamToSave(this.examInfo, pStatus);
      }

      this.examInfo.procedureList.forEach((item, index) => {
        const bioslabLaboratoryPatientExams = PreparedBioslabExtraInfoToSave(
          item,
        );
        procedureInfo = procedureInfo.concat(bioslabLaboratoryPatientExams);
      });

      const resultsID = await this.$store.dispatch("Bioslab/SAVE_TO_BIOSLAB", {
        examInfo,
        procedureInfo,
      });

      if (this.protocolNumber && this.protocolNumber.length > 1) {
        this.procedureListToRemove.forEach(async (item) => {
          const auxItem = await this.$store.dispatch(
            "Bioslab/SEARCH_BIOSLAB_LABORATORY_PATIENT_EXAMS",
            {
              lpe_id_laboratoriais_exames_bioslab: item.lpe_id,
              lpe_ativo: "0",
            },
          );
          if (item.lpe_id === auxItem.lpe_id) {
            await this.$store.dispatch(
              "Bioslab/SAVE_BIOSLAB_LABORATORY_PATIENT_EXAMS",
              item,
            );
          }
        });
      }

      return !examInfo || !resultsID || !resultsID.SaveToBioslab
        ? null
        : {
            ...examInfo,
            ...resultsID.SaveToBioslab,
          };
    },

    async handleSendToBioslab(pExamInfo, pStatus) {
      let examsStatus = null;
      if (pStatus !== EXAM_STATUS_LABORATORY.SOLICITADO) {
        // eslint-disable-next-line no-unused-vars
        let leb_id = pExamInfo.leb_id;
        examsStatus = await this.sendToBioslab({ leb_id });
        const results = await this.searchBioslabLaboratoryExam(leb_id);

        // Case don't have any connection with bioslab and try schedule
        if (!examsStatus || typeof examsStatus === "string") {
          this.cleanForm();
          this.loader = 2;
          this.mountExam(results.rows[0]);

          if (pStatus === EXAM_STATUS_LABORATORY.AGENDADO) {
            // when is tried schedule and fail, is saving as solicitation
            this.mountPage(EXAM_STATUS_LABORATORY.SOLICITADO);
          } else {
            this.mountPage(pStatus);
          }
        } else {
          if (
            examsStatus === EXAM_STATUS_LABORATORY.AGENDADO &&
            pStatus === EXAM_STATUS_LABORATORY.AGENDADO
          ) {
            // Save as SCHEDULED
            this.examInfo.leb_id = leb_id;
            pExamInfo = PrepareBioslabExamToSave(this.examInfo, pStatus);

            const proceduresWithIds = results.rows[0].biosLabInfo;

            const resultsID = await this.$store.dispatch(
              "Bioslab/SAVE_TO_BIOSLAB",
              {
                pExamInfo,
                proceduresWithIds,
              },
            );

            if (resultsID && resultsID.SaveToBioslab) {
              leb_id = resultsID.SaveToBioslab.leb_id;
              examsStatus =
                resultsID.SaveToBioslab.leb_id_laboratoriais_status_pedido;
            }
          }

          // Case Success!!!
          await this.mountExamByLebId(leb_id, true);
          this.mountPage(examsStatus || pStatus);
        }
      }
    },

    async sendToBioslab({ leb_id }) {
      this.loading = true;
      this.loader = 0;
      try {
        const item = {
          leb_id,
          uns_id: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        };
        const send = await this.$store.dispatch(
          "Exam/Laboratory/SEND_BIOSLAB_REQUEST_SERVICE",
          { lebId: item.leb_id, unsId: item.uns_id },
        );
        this.loader = send === 2 ? 1 : 2;
        return send;
      } catch (err) {
        this.loader = 2;
        if (err === "timeout of 30000ms exceeded") {
          throw new Error("Sem comunicação com o Bioslab.");
        } else {
          throw new Error(err);
        }
      }
    },

    async searchBioslabLaboratoryExam(pLebId) {
      const params = { leb_id: pLebId };
      return this.$store.dispatch(
        "Exam/Laboratory/SEARCH_BIOSLAB_LABORATORY_EXAM",
        params,
      );
    },

    selectingPatient(pPatient) {
      if (!pPatient.source || !pPatient.source.pes_id) {
        this.cleanPersonalData();
        this.cleanProcedures();
        this.cleanPatientData();
        this.bulletinNumber = "";
        this.hospitalizationNumber = "";
        this.protocolNumber = null;
        this.recordNumber = null;
        this.form.pes_id = null;
        this.patientInfo.othersData = {};
        this.mainCid = "";
        this.patientIsValid = false;
        this.employeeRequest = {
          id: null,
          name: "",
          cpf: "",
          uf: "",
          conselho: "",
          numberCons: "",
        };
        this.isEmployeeSelected = false;
        this.suggestRequestEmployee = "";
        this.patientInfo.nome = "";
        return;
      }

      this.form.pes_id = pPatient.source.pes_id;

      this.form.exe_id_pacientes =
        pPatient.source && pPatient.source.patient
          ? pPatient.source.patient.pac_id
          : null;

      this.recordNumber =
        pPatient.source && pPatient.source.patient
          ? String(pPatient.source.patient.pac_prontuario_unico)
          : null;

      if (this.form.pes_id) {
        this.buttons.editPerson = true;
      }

      this.patientInfo.sexo_sigla = pPatient.source.gender
        ? pPatient.source.gender.sex_sigla
        : pPatient.source.sex_sigla;

      const existPhone =
        pPatient.source.telephones.rows &&
        pPatient.source.telephones.rows.length > 0;

      const birthday =
        pPatient.source && pPatient.source.pes_nascimento
          ? pPatient.source.pes_nascimento
          : null;

      this.patientInfo.nome = pPatient.source?.pes_nome;

      this.patientInfo.othersData = {
        pes_id: pPatient.source?.pes_id,
        pes_nome: pPatient.source?.pes_nome,
        pes_nascimento: moment(birthday).format("DD/MM/YYYY"),
        crs_numero:
          pPatient.source?.crs_numero || pPatient.source?.cns?.crs_numero,
        mun_nome:
          pPatient.source?.address?.neighborhood?.city?.mun_nome || null,
        nac_nome: pPatient.source?.nationality?.nac_nome || null,
        est_sigla: pPatient.source?.city?.state?.est_sigla,
        bai_nome: pPatient.source?.address?.neighborhood?.bai_nome,
        end_logradouro: pPatient.source?.address?.end_logradouro,
        end_numero: pPatient.source?.address?.end_numero,
        end_cep: pPatient.source?.address?.end_cep,
        end_complemento: pPatient.source?.address?.end_complemento,
        pes_etnias_nomes: pPatient.source?.ethnicity?.etn_nome,
        tlg_nome: pPatient.source?.address?.publicPlaceType?.tlg_nome,
        tel_full: existPhone
          ? `(${pPatient.source?.telephones?.rows[0]?.ddd?.ddd_codigo}) ${pPatient.source?.telephones?.rows[0]?.tel_numero}`
          : null,
      };

      this.setExamOrPatient();

      try {
        this.patientIsValid = true;
      } catch (Err) {
        this.$toaster.warning(Err);
        this.patientIsValid = false;
      }
    },

    prepareDataToBioslab() {
      return {
        leb_nome_paciente: this.patientInfo.othersData.pes_nome,
        leb_sexo_paciente: this.examPatientData.gender,
        leb_endereco_paciente: this.patientInfo.othersData.end_logradouro,
        leb_id_pacientes: this.patientInfo.othersData.pes_id,
        leb_uf_paciente: this.patientInfo.othersData.est_sigla,
        leb_nacionalidade_paciente: this.examPatientData.nationality,
        leb_naturalidade_paciente: this.examPatientData.city,
        leb_cns_paciente: this.examPatientData.cns,
        leb_municipio_paciente: this.patientInfo.othersData.mun_nome,
        leb_bairro_paciente: this.patientInfo.othersData.bai_nome,
        leb_end_numero_paciente: this.patientInfo.othersData.end_numero,
        leb_cep_paciente: this.patientInfo.othersData.end_cep,
        leb_end_complemento_paciente: this.patientInfo.othersData
          .end_complemento,
        leb_cor_paciente: this.examPatientData.colorAndRace,
        leb_nascimento_paciente: moment(
          this.examPatientData.birthday,
          "DD/MM/YYYY",
        ).format("YYYY-MM-DD"),
        leb_logradouro_paciente: this.patientInfo.othersData.tlg_nome,
        leb_telefone_paciente: this.examPatientData.telephone,
      };
    },

    async selectingRequestEmployee(pRequestEmployee) {
      if (!isEmpty(pRequestEmployee.source)) {
        const source = pRequestEmployee.source;
        const { person, employeeRelationship } = source;
        const employeeExtraInfo = await this.$store.dispatch(
          "Employee/GET_CLASS_COUNCIL",
          { employeeId: employeeRelationship.vin_id_funcionarios },
        );

        this.isEmployeeSelected = true;
        this.form.exe_id_vinculos_solicitante = source.vin_id;
        this.employeeRequest.name = source.pes_nome;
        this.employeeRequest.cpf = person.cpf.cpf_numero;
        this.employeeRequest.uf =
          person.address.neighborhood.city.state.est_sigla;
        this.employeeRequest.id = employeeRelationship.vin_id_funcionarios;
        if (
          employeeExtraInfo[0].coc_numero_conselho &&
          employeeExtraInfo[0].coc_tipo_conselho
        ) {
          this.employeeRequest.numberCons =
            employeeExtraInfo[0].coc_numero_conselho;
          this.employeeRequest.conselho =
            employeeExtraInfo[0].coc_tipo_conselho;
        }
      } else {
        this.employeeRequest = {
          id: null,
          name: "",
          cpf: "",
          uf: "",
          conselho: "",
          numberCons: "",
        };
        this.isEmployeeSelected = false;
      }
    },

    selectingProcedure(pRequestProcedure) {
      const request =
        pRequestProcedure && pRequestProcedure.source
          ? pRequestProcedure.source
          : {};

      const bioslabCode = request.lle_codigo_bioslab_mne
        ? request.lle_codigo_bioslab_mne.trim()
        : "";

      if (
        !pRequestProcedure.source ||
        !pRequestProcedure.source.lle_codigo_sigtap
      ) {
        this.hasProcedure = false;
      } else {
        this.hasProcedure = true;
        this.form.exe_codigo_procedimento =
          pRequestProcedure.source.lle_codigo_sigtap;

        this.examObjects(
          pRequestProcedure.source.lle_codigo_sigtap,
          bioslabCode,
        );

        this.exams.codigoExameMne =
          pRequestProcedure.source.lle_codigo_bioslab_mne;
        this.exams.collectMaterial = pRequestProcedure.source.lle_material;

        this.matbio = this.exams.collectMaterial === "MATBIO";

        if (this.matbio === true && this.exams.collectMaterial === "MATBIO") {
          this.exams.collectMaterial = "";
        }

        this.obligatoriness = {
          multiplo: pRequestProcedure.source.lle_multiplo,
          dum: pRequestProcedure.source.lle_dum,
          peso: pRequestProcedure.source.lle_peso,
          altura: pRequestProcedure.source.lle_altura,
          cor: pRequestProcedure.source.lle_cor,
          nacionalidade: pRequestProcedure.source.lle_nacionalidade,
          naturalidade: pRequestProcedure.source.lle_naturalidade,
        };

        this.procedure = {
          no_procecimento: pRequestProcedure.source.procedure.NO_PROCEDIMENTO,
          tp_sexo: pRequestProcedure.source.procedure.TP_SEXO,
          vl_idade_minima: pRequestProcedure.source.procedure.VL_IDADE_MINIMA,
          vl_idade_maxima: pRequestProcedure.source.procedure.VL_IDADE_MAXIMA,
        };
      }
    },
    selectingCidCode(pCidCode) {
      if (pCidCode.source) {
        const cid = pCidCode.source;
        this.form.exe_codigo_cid = cid.cid_codigo;
      } else {
        this.form.exe_codigo_cid = "";
        this.mainCid = "";
      }
    },

    async searchBulettin() {
      if (this.bulletinNumber.length <= 0) {
        this.cleanPatient();
        return;
      }
      this.patientInfo = {
        sexo_sigla: "",
        nasc: "",
        nome: "",
        info_medicamento: "",
        info_menstruacao: "",
        menstruada: "N",
      };
      const variables = {
        bulletinNumber: Number(this.bulletinNumber),
        bulletinAttended: true,
        unitHealth: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
      };
      try {
        const bulletinInfo = await SearchPatientByBulletin(variables);
        if (bulletinInfo && bulletinInfo.length > 0) {
          if (this.$refs.patient) {
            this.$refs.patient.fillPatientById(
              bulletinInfo[0].patient.person.pes_id,
            );
          }

          this.form.exe_id_boletins = bulletinInfo[0].bol_id;
          this.form.exe_id_pacientes = bulletinInfo[0].patient
            ? bulletinInfo[0].patient.pac_id
            : null;
          this.suggestPatient =
            bulletinInfo[0].patient && bulletinInfo[0].patient.person
              ? bulletinInfo[0].patient.person.pes_nome
              : "Paciente não identificado";
          this.patientInfo.nome = this.suggestPatient;
          this.recordNumber = String(
            bulletinInfo[0].patient.pac_prontuario_unico,
          );
          this.patientInfo.sexo_sigla =
            bulletinInfo[0].patient.person.gender.sex_sigla;
        } else {
          this.cleanPatient();
          toast.error("O boletim informado não foi encontrado.");
        }
      } catch (err) {
        this.cleanPatient();
        console.error(err);
      }
    },

    async searchHospitalization() {
      if (this.hospitalizationNumber.length <= 0) {
        return this.cleanPatient();
      }

      this.patientInfo = {
        sexo_sigla: "",
        nasc: "",
        nome: "",
        info_medicamento: "",
        info_menstruacao: "",
        menstruada: "N",
      };

      const variables = {
        hospitalizationNumber: Number(this.hospitalizationNumber),
        unitHealth: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
      };

      try {
        const hospitalizationInfo = await SearchPatientByHospitalizationNumber(
          variables,
        );
        if (hospitalizationInfo && hospitalizationInfo.length === 1) {
          this.$refs.patient.fillPatientById(
            hospitalizationInfo[0].patient.person.pes_id,
          );
          this.form.exe_id_internacoes = hospitalizationInfo[0].int_id;
          this.form.exe_id_pacientes = hospitalizationInfo[0].patient.pac_id;
          this.suggestPatient = hospitalizationInfo[0].patient.person.pes_nome;
          this.recordNumber = String(
            hospitalizationInfo[0].patient.pac_prontuario_unico,
          );
          this.patientInfo.sexo_sigla =
            hospitalizationInfo[0].patient.person.gender.sex_sigla;
        } else {
          this.cleanPatient();
          toast.warning("A Internação informada não foi encontrada.");
        }
      } catch (err) {
        this.cleanPatient();
        console.error(err);
      }
    },

    async searchRecordNumber() {
      if (!this.recordNumber || this.recordNumber.length <= 0) {
        return this.cleanPatient();
      }

      this.patientInfo = {
        sexo_sigla: "",
        nasc: "",
        nome: "",
        info_medicamento: "",
        info_menstruacao: "",
        menstruada: "N",
      };

      const variables = {
        recordNumber: this.recordNumber,
        unitHealth: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
      };

      try {
        const patientInfo = await SearchPatientByRecordNumber(variables);
        if (patientInfo && patientInfo.length === 1) {
          this.$refs.patient.fillPatientById(patientInfo[0].pes_id);
          const patient = patientInfo[0].patient;
          this.form.exe_id_pacientes = patient.pac_id;
          this.suggestPatient = patientInfo[0].pes_nome;
          this.patientInfo.sexo_sigla = patient.person.gender.sex_sigla;
        } else {
          this.cleanPatient();
          toast.error("O prontuário informado não foi encontrado.");
        }
      } catch (err) {
        this.cleanPatient();
        console.error(err);
      }
    },

    afterSaveBioslab(pData) {
      this.patientInfo.sexo_sigla = pData.leb_sexo_paciente;
      if (
        this.examData.leb_sexo_paciente === "F" &&
        pData.leb_sexo_paciente !== this.examData.leb_sexo_paciente
      ) {
        this.patientInfo.info_menstruacao = "";
        this.patientInfo.menstruada = "N";
      }

      this.updateVuex({
        ...this.examData,
        ...pData,
      });
    },

    updateVuex(pExamData) {
      this.examData = pExamData;
      return this.$store.commit("Exam/Laboratory/SET_EXAMINFO", {
        ...pExamData,
      });
    },

    async removeFromProcedureList(index, validate = null) {
      if (index !== undefined) {
        this.index = index;
      }

      if (this.protocolNumber && this.protocolNumber.length > 1) {
        this.showModalConfirmRemove = true;
        if (validate) {
          this.procedureList[index].lpe_motivo_exclusao = validate;
          const bioslabLaboratoryPatientExams = {
            lpe_id: this.procedureList[index].lpe_id,
            lpe_motivo_exclusao: validate,
          };
          this.procedureListToRemove.push({ bioslabLaboratoryPatientExams });
          this.procedureList.splice(index, 1);
          this.closeModalConfirmRemove();
          this.index = null;
        }
      } else {
        this.procedureList.splice(index, 1);
      }
    },

    sanitizeNumber(pValue) {
      return !pValue
        ? ""
        : pValue.replace(",", ".").replace(/[ a-zA-Z á]/g, "");
    },

    patientRecord() {
      this.$store.commit(
        "Person/Patient/SET_LAST_PERSON_ID_SAVE",
        this.form.pes_id,
      );
      this.$store.commit("Person/Patient/SET_PERSON_ID", this.form.pes_id);
      this.$router.push({ name: "exam.laboratory.patient-edit" });
    },

    registerPatient() {
      this.$router.push({ name: "exam.laboratory.patient-details" });
    },

    showModalError(pValue) {
      this.showError = true;
      this.errors = pValue;
    },

    closeErrorModal() {
      this.showError = false;
    },

    showModalTags() {
      this.showTags = true;
    },

    closeTagModal() {
      this.showTags = false;
    },

    showModalConfirmRemoveExam(pItem, pIndex) {
      this.showModalConfirmRemove = true;
    },

    closeModalConfirmRemove() {
      this.showModalConfirmRemove = false;
    },

    closeModalConfirmAdd() {
      this.showModalConfirmAdd = false;
    },

    closeLoader() {
      this.loading = false;
    },

    cleanForm() {
      this.buttons.emblem = false;
      this.sectorLink = null;
      this.cleanDataRequest();
      this.cleanPersonalData();
      this.cleanProcedures();
      this.cleanPatientData();
      this.$store.commit("Exam/Laboratory/RESET_EXAMINFO");
    },

    cleanPatientData() {
      this.examPatientData = {};
    },

    cleanDataRequest() {
      this.leb_id = 0;
      this.bulletinNumber = "";
      this.hospitalizationNumber = "";
      this.recordNumber = "";
      this.suggestPatient = "";
      this.patientInfo = {
        sexo_sigla: "",
        nasc: "",
        nome: "",
        info_medicamento: "",
        info_menstruacao: "",
        menstruada: "N",
      };
      this.protocolNumber = null;
      this.mainCid = null;
      this.suggestRequestEmployee = "";
      this.employeeRequest = {
        id: null,
        name: "",
        cpf: "",
        uf: "",
        conselho: "",
        numberCons: "",
      };
      this.isEmployeeSelected = false;
      this.form.leb_data_solicitacao = moment().format("DD/MM/YYYY");
      this.form.leb_data_agendamento = "";
      this.form.leb_hora_agendamento = "";
      this.errors = [];
    },

    cleanPatient() {
      this.bulletinNumber = "";
      this.hospitalizationNumber = "";
      this.recordNumber = "";
      this.form.exe_id_pacientes = null;
      this.suggestPatient = "";
      this.patientInfo = {
        sexo_sigla: "",
        nasc: "",
        nome: "",
        info_medicamento: "",
        info_menstruacao: "",
        menstruada: "N",
      };
      this.suggestRequestEmployee = "";
      this.employeeRequest = {
        id: null,
        name: "",
        cpf: "",
        uf: "",
        conselho: "",
        numberCons: "",
      };
    },

    cleanPersonalData() {
      this.patientWeight = "";
      this.patientHeight = "";
      this.patientInfo.info_medicamento = "";
      this.patientInfo.info_menstruada = "";
      this.patientInfo.menstruada = "N";
      this.patientInfo.data_menstruacao = "";
    },

    cleanProcedures() {
      this.suggestProcedure = "";
      this.procedureList = [];
      this.procedureListToRemove = [];
      this.exams = {
        collectMaterial: "",
        objectAnalisis: "",
        codigoExameMne: "",
      };
      this.index = null;
    },

    cleanProcedureInfo() {
      this.hasProcedure = false;
      this.suggestProcedure = "";
      this.exams.collectMaterial = "";
      this.matbio = false;
      this.exams.codigoExameMne = "";
      this.exams.objectAnalisis = "";
      this.objectExam = [];
    },
  },
};
</script>
