<script>
import RgSuggestEmployee from "../rg-suggest-employee/RgSuggestEmployee";
import { SearchHospitalizationEmployeeAuthorizer } from "./action";

export default {
  name: "RgSuggestEmployeeAuthorizer",
  mixins: [RgSuggestEmployee],
  props: {
    /**
     * Define field will show in the caption
     * @type String
     */
    captionRaw: {
      type: String,
      default: "pes_nome",
    },
  },
  methods: {
    doSearch(pSearchTerm) {
      const searchQuery = pSearchTerm;

      // TODO: verificar se é necessário filtrar por uns_id: Store.getters['Login/GET_UNIT_HEALTH_ID']
      if (Number.isInteger(+pSearchTerm)) {
        return SearchHospitalizationEmployeeAuthorizer({ cns: searchQuery });
      } else {
        return SearchHospitalizationEmployeeAuthorizer({
          pesNome: searchQuery,
        });
      }
    },
    validate() {
      this.$refs.suggest.validate();
    },
    cleanValidate() {
      this.$refs.suggest.cleanValidate();
    },
  },
};
</script>
