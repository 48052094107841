<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";

import SearchScale from "./action/SearchScale";

export default {
  name: "RgComboboxScale",
  mixins: [RgComboboxMixin],
  props: {
    sectorId: {
      required: true,
    },
    occupationId: {
      required: true,
    },
    personId: {
      required: true,
    },
    unitId: {
      required: true,
    },
    date: {
      required: true,
    },
  },
  data: () => {
    return {
      dateFormated: "",
    };
  },
  watch: {
    date() {
      this.fillData();
    },
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];

      if (
        this.sectorId &&
        this.occupationId &&
        this.personId &&
        this.unitId &&
        this.date
      ) {
        this.disableLoading = true;
        this.dateFormated = this.date ? this.date.replaceAll("-", "/") : "";

        const data = await SearchScale({
          intIdSetor: this.sectorId,
          intIdOcupacao: this.occupationId,
          intIdPessoa: this.personId,
          intIdUnidadeSaude: this.unitId,
          strData: this.dateFormated,
        });

        data.forEach((item) => {
          this.mData.push({
            value: item.data,
            text: item.eag_inicio_atendimentos,
            item: item,
          });
        });
        this.disableLoading = false;
      }
    },
  },
};
</script>
