import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async (unithHealthId, procedureName, submoduleId) => {
  return AuthLegacyRequest.post(
    "exames2/controller-exame-procedimento/combo-box-setores-com-procedimento-e-profissional",
    {
      intIdUnidadeSaude: unithHealthId,
      strNomeProcedimento: procedureName,
      idSubModulo: submoduleId,
    },
  )
    .then(({ data }) => {
      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
