import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchFamilyIncome {
    SearchFamilyIncome {
      rows {
        rfa_id
        rfa_codigo_ab
        rfa_renda
      }
    }
  }
`;

export default async ({ commit, state }) => {
  if (state.family_income_otpions.length > 0) {
    return;
  }
  try {
    const { data } = await GraphQLClient.query({
      query,
      fetchPolicy: "no-cache",
    });
    commit("SET_FAMILY_INCOME_OPTIONS", data.SearchFamilyIncome.rows);
  } catch (err) {
    console.log(err);
    return err.graphQLErrors;
  }
};
