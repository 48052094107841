import moment from "moment";
import { BrazilianDateToDatabase } from "~utils/date";

const SurgeryStoreHelper = {};
SurgeryStoreHelper.groupByStage = (pSurgeryList) => {
  const byStages = {};
  pSurgeryList.map((pSurgeryItem) => {
    byStages[pSurgeryItem.cci_id_locais_atendimento] =
      byStages[pSurgeryItem.cci_id_locais_atendimento] || [];
    byStages[pSurgeryItem.cci_id_locais_atendimento].push(pSurgeryItem);
  });

  return byStages;
};

SurgeryStoreHelper.groupByTime = (pStageList) => {
  const byTime = {};

  for (const pStageItem in pStageList) {
    const startDate = BrazilianDateToDatabase(
      pStageList[pStageItem].cci_data_inicio,
    );
    const timestamp = new Date(
      `${startDate} ${pStageList[pStageItem].cci_hora_inicio}`,
    ).getTime();
    byTime[timestamp] = byTime[timestamp] || [];
    byTime[timestamp].push({
      id: pStageList[pStageItem].cci_id,
      surgeryCode: pStageList[pStageItem].cci_numero,
      name: pStageList[pStageItem].patient,
      surgeryType: pStageList[pStageItem].cci_id_tipos_cirurgias,
      clinic: pStageList[pStageItem].requesting_clinic,
      medic: pStageList[pStageItem].requesting_doctor,
      medicalRecord: pStageList[pStageItem].medical_record,
      initialDate: pStageList[pStageItem].cci_data_inicio,
      initialHour: pStageList[pStageItem].cci_hora_inicio,
      duration: moment.duration(pStageList[pStageItem].cci_duracao).asMinutes(),
    });
  }

  const orderedByTimeReverse = orderingByTimeReverse(byTime);
  return orderedByTimeReverse;
};

SurgeryStoreHelper.organizeMap = (pGroupedByTime) => {
  const surgeryBlock = [];
  for (const pGroupedItem in pGroupedByTime) {
    surgeryBlock.push({
      startTime: pGroupedItem,
      surgeries: pGroupedByTime[pGroupedItem],
    });
  }

  return surgeryBlock;
};

const orderingByTimeReverse = (pList) => {
  const listReordered = {};
  for (const listTimestamp in pList) {
    listReordered[listTimestamp] = pList[listTimestamp].sort((a, b) => a < b);
  }
  return listReordered;
};
export default SurgeryStoreHelper;
