import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query HomeRegisterForm(
    $dom_id_microareas: Int
    $data_atendimento_inicial: String
    $data_atendimento_final: String
    $exported: Boolean
    $exported_edit: Boolean
    $dom_descricao: String
    $bai_nome: String
    $end_logradouro: String
    $end_cep: String
    $dom_status_recusa: Int
    $limit: Int
    $offset: Int
    $orderDirection: String
    $orderField: String
    $uns_id: Int
    $equ_id: Int
    $are_id: Int
    $fab_id: Int
  ) {
    SearchHome(
      dom_id_microareas: $dom_id_microareas
      data_atendimento_inicial: $data_atendimento_inicial
      data_atendimento_final: $data_atendimento_final
      exported: $exported
      exported_edit: $exported_edit
      dom_descricao: $dom_descricao
      bai_nome: $bai_nome
      end_logradouro: $end_logradouro
      end_cep: $end_cep
      dom_status_recusa: $dom_status_recusa
      limit: $limit
      offset: $offset
      orderDirection: $orderDirection
      orderField: $orderField
      uns_id: $uns_id
      equ_id: $equ_id
      are_id: $are_id
      fab_id: $fab_id
    ) {
      rows {
        dom_id
        dom_data_atendimento
        dom_id_cabecalho_fichas
        dom_status_recusa
        dom_descricao
        dom_fora_area
        dom_nome
        dom_rg
        dom_numero_comodos
        dom_unico_original
        dom_data_exportacao
        dom_numero_moradores
        dom_energia_eletrica
        dom_qtd_animais_domicilio
        dom_animais_domicilio
        dom_latitude
        dom_longitude
        address {
          end_id
          end_logradouro
          end_numero
          end_complemento
          end_referencia
          end_cep
          end_ativo
          end_id_bairros
          neighborhood {
            bai_id
            bai_nome
            bai_id_municipios
            bai_ativo
            city {
              mun_nome
              state {
                est_sigla
              }
            }
          }
        }

        header_form {
          cfi_id
          cfi_id_equipes
          cfi_id_funcionarios_atencao_basica
          cfi_cartao_sus
          cfi_cnes
          cfi_codigo_ine
          cfi_codigo_cbo
          team {
            equ_nome_equipe
            area {
              are_id
              unit_health {
                uns_id
                uns_cnes
                uns_nome
              }
              are_nome
              are_descricao
            }
          }
          basic_attention_employee {
            fab_id
            fab_id_funcionarios
            employee {
              person {
                pes_nome
              }
            }
          }
        }

        institutions {
          isp_profissionais_vinculados
          isp_nome_instituicao
          isp_nome_responsavel
          isp_cargo_instituicao
          isp_telefone_responsavel
          isp_cns_responsavel
        }

        property_type {
          tim_id
          tim_codigo_ab
          tim_descricao
        }

        microarea {
          mic_id
          mic_id_areas
          mic_codigo
          mic_descricao
        }

        home_access {
          ado_id
          ado_codigo_ab
          ado_nome_acesso
        }

        home_localization {
          lmo_id
          lmo_codigo_ab
          lmo_nome_localizacao
        }

        home_situation {
          smo_id
          smo_codigo_ab
          smo_situacao_moradia
        }

        home_building_material {
          mcd_id
          mcd_codigo_ab
          mcd_nome_material
        }

        rural_production {
          pru_id
          pru_codigo_ab
          pru_nome
        }

        water_supply {
          aba_id
          aba_codigo_ab
          aba_nome
        }

        bathroom_drain {
          eba_id
          eba_codigo_ab
          eba_nome_escoamento
        }

        water_treatment {
          tra_id
          tra_codigo_ab
          tra_nome_tratamento
        }

        trash_destination {
          dli_id
          dli_codigo_ab
          dli_nome_destino
        }

        home_types {
          tdo_id
          tdo_codigo_ab
          tdo_nome_domicilio
        }

        animals {
          tad_id
          tad_tipos_animais
        }

        homePhone
        referencePhone
      }
      count
    }
  }
`;

export default async (pContext, pFilter) => {
  try {
    const variables = { ...pFilter };
    const { data } = await GraphQLClient.query({
      query,
      fetchPolicy: "no-cache",
      variables,
    });
    return data.SearchHome;
  } catch (err) {
    console.log(err);
    return err.graphQLErrors;
  }
};
