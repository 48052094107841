const ExamMixinPerPeriod = {
  data() {
    return {
      perPeriod: "per-period",
      routePerPeriod: "/exam/schedule/per-period",
    };
  },
  methods: {
    removeFilterPerPeriod() {
      this.$store.commit("Exam/Schedule/RESET_FILTER_EXAMS_PER_PERIOD");
    },
  },
};

export default ExamMixinPerPeriod;
