export default {
  SET_COUNT: (state, { count }) => {
    state.count = count;
  },
  SET_LIST: (state, { list }) => {
    state.list = list;
  },
  CLEAN_LIST: (state) => {
    state.list = null;
  },
  CLEAN_COUNT: (state) => {
    state.count = 0;
  },
  RESET_STORE: (state) => {
    state.list = null;
    state.count = 0;
  },
  SET_EXAM_STATUS_BY_INDEX: (state, { itemIndex, status }) => {
    const newList = JSON.parse(JSON.stringify(state.list));
    newList[itemIndex].exe_id_exames_efetivacao = status;
    state.list = newList;
  },
  REMOVE_EXAM_STATUS_BY_INDEX: (state, { itemIndex }) => {
    state.list = state.list.filter((item, index) => index !== itemIndex);
  },
  SET_FILTER: (state, pData) => {
    state.filter = pData;
  },
  CLEAN_FILTER: (state) => {
    state.filter = null;
  },
  SET_SELECTED_DATA: (state, pData) => {
    state.selected_data = pData;
  },
  CLEAN_SELECTED_DATA: (state) => {
    state.selected_data = null;
  },
};
