<template>
  <Modulebox
    :granted="hasPermission"
    :title="setTitle"
    class="appointment-scale-register"
  >
    <div ref="content" class="content">
      <RgValidatorForm ref="validator">
        <FormBase title="Profissional" class="professional">
          <RgComboboxUnithealthLegacy
            ref="unitHealth"
            v-model="form.uns_id"
            :disabled="isEdition"
            :class="{ disable: isEdition }"
            :rules="{ required: true }"
            :subModuleId="subModuleId"
          />

          <RgComboboxSectorBondAndPermission
            v-model="form.set_id"
            :unit-health-id="form.uns_id"
            :submodule-id="subModuleId"
            :disabled="!hasUnithealth || isEdition"
            :class="{ disable: !hasUnithealth || isEdition }"
            :rules="{ required: true }"
            default-text="Selecione"
          />

          <RgSuggestCboSector
            ref="occupation"
            v-model="occupation"
            :rules="{ required: true, forceSelection: true }"
            :disabled="!hasSector || isEdition"
            :class="{ disable: !hasSector || isEdition }"
            :sectorId="Number(form.set_id)"
            label="Ocupação"
            placeholder="Digite o nome da ocupação"
            activeProfessional
            @selected="selectingCbo"
          />

          <RgSelectEmployeeBySectorOccupationLegacy
            ref="employee"
            v-model="employee"
            :rules="{ required: true }"
            :sector-id="Number(form.set_id)"
            :occupation-id="Number(form.ocp_id)"
            :disabled="!hasOccupation || isEdition"
            :class="{ disable: !hasOccupation || isEdition }"
            placeholder="Digite o nome do profissional"
            @change="selectingEmployee"
          />
        </FormBase>

        <FormBase title="Escala" class="scale">
          <RgComboboxWeekDays
            ref="weekDay"
            v-model="form.dsm_id"
            :disabled="isEdition"
            :rules="{ required: true }"
            class="week-day"
          />

          <RgInputHour
            ref="initialTime"
            v-model="form.eag_inicio_atendimentos"
            :rules="{ required: true }"
            label="Horário de início"
            placeholder="hh:mm"
            class="initial-time"
            @blur="calculateTimes"
          />

          <RgInputMasked
            ref="breakTime"
            v-model="form.eag_intervalo_consultas"
            :rules="{ required: true, fn: validateMinute }"
            placeholder="minutos"
            mask="##"
            label="Intervalo"
            class="break-time"
            type="number"
            @blur="calculateTimes"
          />

          <RgInputMasked
            ref="quantity"
            v-model="form.eag_quantidade_consultas"
            :rules="{ required: true, fn: validadeQuantity }"
            placeholder="000"
            mask="###"
            label="Quantidade de vagas"
            class="quantity"
            type="number"
            @blur="calculateTimes"
          />

          <RgInputHour
            ref="finalTime"
            v-model="form.eag_final_atendimentos"
            disabled
            :rules="{ required: true }"
            label="Horário de término"
            placeholder="hh:mm"
            class="final-time disable unselect"
          />

          <RgInputHour
            ref="lastAvailableTime"
            v-model="form.eag_ultimo_horario"
            disabled
            :rules="{ required: true }"
            label="Último horário disponível"
            placeholder="hh:mm"
            class="last-time disable unselect"
          />

          <RgInputDate
            ref="initialValidity"
            v-model="form.eag_data_inicio_escala"
            :rules="{ required: true, fn: validateInitialValidity }"
            label="Início da Validade"
            placeholder="dd/mm/aaaa"
            class="inital-validity"
          />

          <RgInputDate
            ref="finalValidity"
            v-model="form.eag_data_fim_escala"
            :rules="{ fn: validateFinalValidity }"
            label="Término da Validade"
            placeholder="dd/mm/aaaa"
            class="final-validity"
          />

          <RgInputCheckbox
            label="Reserva técnica"
            :value="form.chk_reservaTecnica"
            class="technical-reserve"
            :permission="hasPermissionCreateScaleTechnicalReserve"
            @change-value="onChangeCheckTechnicalReserve"
          />

          <div class="buttons">
            <RgCancelButton v-if="isEdition" medium @click="cleanScaleInfo" />
            <RgCleanButton v-else @click="cleanScaleInfo" />

            <RgSaveButton
              v-if="isEdition"
              ref="save"
              large
              @click="saveScale"
            />
            <RgAddButton
              v-else
              large
              :permission="canInclude"
              @click="saveScale"
            />
          </div>
        </FormBase>

        <FormBase title="Escalas cadastradas" class="list-scale">
          <SmartTable
            ref="smartTable"
            name="AppointmentScaleRegister"
            :columns="COLUMN_TABLE"
            :body="listScale"
            :total="Number(total)"
            :item-per-page="10"
            :max-register="10"
            :initial-columns="7"
            :colorLine="actionColorLine"
            :disabled="isEdition"
            index-column="eag_id"
            circle-indicator
            toggle-selected
            class="registered-scales"
            @getLine="selectLine"
            @pagination="getPagination"
          >
            <div slot="legends" class="legends">
              <div class="circle -green" />
              <span class="legend">Na Validade</span>
              <div class="circle -red" />
              <span class="legend">Fora da Validade</span>
              <div class="circle -blue" />
              <span class="legend">Em Breve</span>
            </div>

            <div slot="top-buttons" class="top-buttons">
              <RgEditButton
                class="btns"
                :disabled="!hasSelectedLine || isEdition"
                :permission="hasSelectedLine ? canEdit : true"
                @click="editScale"
              />

              <RgLessButton
                :disabled="!hasSelectedLine || isEdition"
                class="btns"
                title="Remover"
                :permission="hasSelectedLine ? canRemove : true"
                @click="openModalDeleteScale"
              />

              <RgPrinterButton
                :disabled="listScale.length < 1 || !hasEmployee || isEdition"
                class="btns"
                title="Imprimir escala de atendimento do profissional"
                @click="printScaleAttendProfessional"
              />

              <RgHistoryButton
                :disabled="listScale.length < 1 || !hasEmployee || isEdition"
                class="btns"
                title="Histórico de Escalas"
                @click="openModalHistoryScales"
              />
            </div>
          </SmartTable>
        </FormBase>
      </RgValidatorForm>
    </div>

    <div slot="footer" class="footer">
      <RgBackButton medium title="Voltar" @click="goBack" />
    </div>

    <ModalHistoryProfessionalScales
      :show="modalHistoryScales"
      :sector-link-id="Number(form.vus_id)"
      :professional-info="professionalInfo"
      @close="closeModalHistoryScales"
    />

    <ModalConflictProfessionalScales
      :show="modalConflictScales"
      :conflict-list="conflictListScale"
      @close="closeModalConflictScales"
    />

    <ModalConfirmDeletion
      v-bind="propsModalConfirmDeletion"
      @close="closeModalDeleteScales"
    >
      <div slot="icon" class="icon">
        <IconError />
      </div>
    </ModalConfirmDeletion>
  </Modulebox>
</template>

<script>
import moment from "moment";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";
import EmployeeAttendanceScaleHTML from "~tokio/primitive/html/EmployeeAttendanceScaleHTML";
import GetColorRowSmartTable from "~common/utils/GetColorRowSmartTable";
import { mapGetters } from "vuex";
import { FormBase, Modulebox, SmartTable } from "~tokio/foundation";
import {
  RgBackButton,
  RgCleanButton,
  RgAddButton,
  RgComboboxWeekDays,
  RgComboboxUnithealthLegacy,
  RgComboboxSectorBondAndPermission,
  RgSuggestCboSector,
  RgSelectEmployeeBySectorOccupationLegacy,
  RgValidatorForm,
  RgInputMasked,
  RgInputHour,
  RgInputDate,
  RgInputCheckbox,
  RgCancelButton,
  ModalConfirmDeletion,
  RgLessButton,
  RgPrinterButton,
  RgHistoryButton,
  RgEditButton,
  RgSaveButton,
} from "~tokio/primitive";

import {
  ModalHistoryProfessionalScales,
  ModalConflictProfessionalScales,
} from "$appointment/submodules/register/component";

import { IconError } from "~tokio/primitive/icon/symbols";

const FORM = {
  eag_id: 0,
  uns_id: null,
  set_id: null,
  ocp_id: 0,
  vus_id: 0,
  pes_id: 0,
  dsm_id: null,
  eag_inicio_atendimentos: null,
  eag_intervalo_consultas: null,
  eag_quantidade_consultas: null,
  eag_final_atendimentos: null,
  eag_ultimo_horario: null,
  eag_data_inicio_escala: null,
  eag_data_fim_escala: null,
  chk_reservaTecnica: false,
};

export default {
  name: "AppointmentScaleRegister",
  components: {
    FormBase,
    Modulebox,
    RgBackButton,
    RgCleanButton,
    RgAddButton,
    RgCancelButton,
    RgComboboxWeekDays,
    RgComboboxUnithealthLegacy,
    RgComboboxSectorBondAndPermission,
    RgSuggestCboSector,
    RgSelectEmployeeBySectorOccupationLegacy,
    RgValidatorForm,
    RgInputHour,
    RgInputMasked,
    RgInputDate,
    RgInputCheckbox,
    ModalHistoryProfessionalScales,
    ModalConflictProfessionalScales,
    SmartTable,
    ModalConfirmDeletion,
    RgLessButton,
    RgPrinterButton,
    RgHistoryButton,
    RgEditButton,
    RgSaveButton,
    IconError,
  },

  data() {
    return {
      isEdition: false,
      occupation: null,
      employee: null,
      form: this.$utils.obj.DeepCopy(FORM),
      listScale: [],
      conflictListScale: [],
      total: 0,
      selectedLine: null,
      professionalInfo: {},
      modalDeleteScales: false,
      modalHistoryScales: false,
      modalConflictScales: false,
      scaleEndSpentDay: false,
      pagination: {
        limit: 10,
        offset: 0,
        current: 1,
      },
    };
  },

  computed: {
    ...mapGetters({
      unitHealthId: "Login/GET_UNIT_HEALTH_ID",
    }),

    setTitle() {
      return this.isEdition ? "Editar Escala" : "Cadastro de Escala";
    },

    hasUnithealth() {
      return this.form.uns_id > 0;
    },

    hasSector() {
      return this.form.set_id > 0;
    },

    hasOccupation() {
      return this.form.ocp_id > 0;
    },

    hasEmployee() {
      return this.form.pes_id > 0;
    },

    hasSelectedLine() {
      return this.selectedLine && Object.keys(this.selectedLine).length > 0;
    },

    hasInitialTime() {
      const isNotEmpty =
        this.form.eag_inicio_atendimentos !== null &&
        this.form.eag_inicio_atendimentos !== "";

      const isValidLength = this.form.eag_inicio_atendimentos?.length === 5;

      return isNotEmpty && isValidLength;
    },

    hasBreakTime() {
      return (
        this.form.eag_intervalo_consultas !== null &&
        this.form.eag_intervalo_consultas !== ""
      );
    },

    hasQuantity() {
      return (
        this.form.eag_quantidade_consultas !== null &&
        this.form.eag_quantidade_consultas !== ""
      );
    },

    areAllProfessionalInfoFilled() {
      const areAllFilled =
        this.hasUnithealth &&
        this.hasSector &&
        this.hasOccupation &&
        this.hasEmployee;

      return areAllFilled;
    },

    areAllTimeInfoFilled() {
      const areAllFilled =
        this.hasInitialTime && this.hasBreakTime && this.hasQuantity;

      return areAllFilled;
    },

    actionColorLine() {
      return {
        get: (item) => {
          const { eag_data_inicio_escala, eag_data_fim_escala } = item;
          const now = moment(new Date()).format("YYYY-MM-DD");

          const initial = moment(eag_data_inicio_escala, "DD/MM/YYYY").format(
            "YYYY-MM-DD",
          );

          const final = moment(eag_data_fim_escala, "DD/MM/YYYY").format(
            "YYYY-MM-DD",
          );

          if (
            eag_data_inicio_escala !== "N/A" &&
            moment(now).isBefore(initial)
          ) {
            // escalas q data inicio > atual (EM BREVE)
            return GetColorRowSmartTable("blue");
          } else if (
            eag_data_fim_escala !== "N/A" &&
            moment(now).isAfter(final)
          ) {
            // escalas q data final < atual (VENCIDAS)
            return GetColorRowSmartTable("red");
          }
          // não entrando nestas regras significa que está na validade
          return GetColorRowSmartTable("green");
        },
      };
    },

    propsModalConfirmDeletion() {
      const show = this.modalDeleteScales;
      const confirm = () => this.deleteScaleListItem();
      const noReason = true;
      const title = "Excluir a escala selecionada";
      const message = "A operação não poderá ser desfeita";
      const msgSuccess = "Escala excluída com sucesso!";
      const btnRemoveTitle = "Excluir Escala";

      return {
        show,
        confirm,
        noReason,
        title,
        message,
        msgSuccess,
        btnRemoveTitle,
      };
    },

    hasPermissionCreateScaleTechnicalReserve() {
      return !!this.$Permissions.global.has(
        "consulta.escalaAtendimento.incluirReservaTecnica",
        this.unitHealthId,
      );
    },

    hasPermissionIncludes() {
      return !!this.$Permissions.global.has(
        "consulta.escalaAtendimento.incluir",
        this.unitHealthId,
      );
    },

    canInclude() {
      return (
        this.hasPermissionCreateScaleTechnicalReserve ||
        this.hasPermissionIncludes
      );
    },

    hasPermissionUpdate() {
      return !!this.$Permissions.global.has(
        "consulta.escalaAtendimento.alterar",
        this.unitHealthId,
      );
    },

    hasPermissionUpdateReserve() {
      return !!this.$Permissions.global.has(
        "consulta.escalaAtendimento.alterarReservaTecnica",
        this.unitHealthId,
      );
    },

    canEdit() {
      return (
        (this.isNormal && this.hasPermissionUpdate) ||
        this.hasPermissionUpdateReserve
      );
    },

    hasPermissionRemove() {
      return !!this.$Permissions.global.has(
        "consulta.escalaAtendimento.excluir",
        this.unitHealthId,
      );
    },

    hasPermissionRemoveReserve() {
      return !!this.$Permissions.global.has(
        "consulta.escalaAtendimento.excluirReservaTecnica",
        this.unitHealthId,
      );
    },

    hasPermission() {
      return !!this.$Permissions.global.has(
        "consulta.escalaAtendimento",
        this.unitHealthId,
      );
    },

    canRemove() {
      return (
        (this.isNormal && this.hasPermissionRemove) ||
        this.hasPermissionRemoveReserve
      );
    },

    isTechnicalReserve() {
      return (
        this.selectedLine && this.selectedLine.cte_nome === "RESERVA TÉCNICA"
      );
    },

    isNormal() {
      return this.selectedLine && this.selectedLine.cte_nome === "NORMAL";
    },

    subModuleId() {
      return 6;
    },
  },

  watch: {
    "form.uns_id"(pValue, pPreviousValue) {
      const isChangedValue =
        pValue && pPreviousValue && pValue !== pPreviousValue;

      const hasValue = !pValue;

      if (hasValue || isChangedValue) {
        this.form.set_id = null;
        this.occupation = null;
      }
    },

    "form.set_id"(pValue) {
      if (!pValue || !isNaN(pValue)) {
        this.employee = null;
        this.occupation = null;
        if (!this.hasPermissionCreateScaleTechnicalReserve) {
          this.form.chk_reservaTecnica = false;
        }
      }
    },

    occupation(pValue) {
      this.employee = null;
    },
  },

  created() {
    this.COLUMN_TABLE = [
      { name: "Tipo", key: "cte_nome" },
      { name: "Unidade de Saúde", key: "uns_nome" },
      { name: "Setor", key: "set_nome" },
      { name: "Ocupação", key: "ocp_nome" },
      { name: "Profissional", key: "pes_nome" },
      { name: "Dia", key: "dsm_sigla" },
      { name: "Horário de Início", key: "eag_inicio_atendimentos" },
      { name: "Horário de Término", key: "eag_final_atendimentos" },
      { name: "Intervalo", key: "eag_intervalo_consultas" },
      { name: "Vagas", key: "eag_quantidade_consultas" },
      { name: "Validade", key: "validade" },
    ];
  },

  mounted() {
    this.fillForm();
  },

  beforeDestroy() {
    const validateIfExist = ValidateIfRouteExistInBreadscrumb(
      "/appointment/register/scale/search",
      this.$route.meta.breadcrumb,
    );

    const validateIfExistUpdateRoute = ValidateIfRouteExistInBreadscrumb(
      "/appointment/register/scale/search/update",
      this.$route.meta.breadcrumb,
    );

    if (!validateIfExist) {
      this.$store.commit(
        "Appointment/Register/RESET_FILTER_APPOINTMENT_SCALE_LIST",
      );
    }

    if (!validateIfExistUpdateRoute) {
      this.$store.commit(
        "Appointment/Register/RESET_SELECTED_APPOINTMENT_SCALE_LIST_LINE",
      );
    }
  },

  methods: {
    async fillForm() {
      try {
        const dataInStore = this.$store.getters[
          "Appointment/Register/GET_SELECTED_APPOINTMENT_SCALE_LIST_LINE"
        ];

        const data = this.$utils.obj.DeepCopy(dataInStore);

        if (data) {
          this.form = data;
          this.form.set_id = Number(data.set_id);
          this.form.uns_id = Number(data.uns_id);

          if (this.$refs.occupation) {
            await this.$refs.occupation.forceSelection({
              ocp_nome: data.ocp_nome,
              ocp_codigo: data.ocp_codigo,
              ocp_id: Number(data.ocp_id),
            });
          }

          if (this.$refs.employee) {
            this.employee = data.pes_id;
          }

          this.form.eag_data_inicio_escala =
            data.eag_data_inicio_escala !== "N/A"
              ? data.eag_data_inicio_escala
              : "";

          this.form.eag_data_fim_escala =
            data.eag_data_fim_escala !== "N/A" ? data.eag_data_fim_escala : "";

          this.form.chk_reservaTecnica = data.cte_nome === "RESERVA TÉCNICA";

          this.$nextTick(() => {
            this.calculateTimes();
          });

          this.isEdition = true;
        } else {
          const dataInFilterStore = this.$store.getters[
            "Appointment/Register/GET_FILTER_APPOINTMENT_SCALE_LIST"
          ];

          const data = this.$utils.obj.DeepCopy(dataInFilterStore);

          if (data) {
            this.form.set_id = Number(data.sector);
            this.form.uns_id = Number(data.unitHealth);
            this.form.vus_id = Number(data.vus_id);

            if (this.$refs.occupation) {
              await this.$refs.occupation.forceSelection({
                ocp_nome: data.ocp_nome,
                ocp_codigo: data.ocp_codigo,
                ocp_id: Number(data.ocp_id),
              });
            }

            if (this.$refs.employee) {
              this.employee = data.pes_id;
            }
          } else {
            this.form.uns_id = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
          }
        }
      } catch (Err) {
        this.$toaster.error(Err.message);
      }
    },

    calculateTimes() {
      if (this.areAllTimeInfoFilled) {
        this.calculateFinalTime();
        this.calculateLastAvailableTime();
      } else {
        this.form.eag_final_atendimentos = null;
        this.form.eag_ultimo_horario = null;
      }
    },

    calculateFinalTime() {
      const sumMinOfScale =
        this.form.eag_quantidade_consultas * this.form.eag_intervalo_consultas;

      const finalTime = moment(this.form.eag_inicio_atendimentos, "HH:mm").add(
        sumMinOfScale,
        "m",
      );

      this.scaleEndSpentDay = false;
      if (sumMinOfScale > 0) {
        const minutesTo24Hour = moment("23:59:59", "HH:mm").diff(
          finalTime,
          "minutes",
        );
        if (minutesTo24Hour < 0) {
          this.scaleEndSpentDay = true;
        }
      }

      this.form.eag_final_atendimentos = finalTime.format("HH:mm");
    },

    calculateLastAvailableTime() {
      const intervalInMinutes = this.form.eag_intervalo_consultas;

      const lastAvailableTime = moment(
        this.form.eag_final_atendimentos,
        "HH:mm",
      ).subtract(intervalInMinutes, "m");

      this.form.eag_ultimo_horario = lastAvailableTime.format("HH:mm");
    },

    selectLine(pValue) {
      this.selectedLine = pValue;
    },

    openModalDeleteScale() {
      this.modalDeleteScales = true;
    },

    closeModalDeleteScales() {
      this.modalDeleteScales = false;
    },

    openModalHistoryScales() {
      this.form.vus_id = Number(this.listScale[0]?.vus_id);

      this.professionalInfo = {
        pes_nome: this.listScale[0]?.pes_nome,
        uns_nome: this.listScale[0]?.uns_nome,
        set_nome: this.listScale[0]?.set_nome,
        ocp_nome: this.listScale[0]?.ocp_nome,
      };

      this.modalHistoryScales = true;
    },

    closeModalHistoryScales() {
      this.modalHistoryScales = false;
      this.cleanProfessionalInfo();
    },

    openModalConflictScales() {
      this.modalConflictScales = true;
    },

    closeModalConflictScales() {
      this.conflictListScale = [];
      this.modalConflictScales = false;
    },

    async printScaleAttendProfessional() {
      try {
        const data = await this.$store.dispatch(
          "Appointment/Register/GET_EMPLOYEE_ATTENDACE_SCALE_DATA",
          {
            intVusId: this.listScale[0].vus_id,
          },
        );

        const clientName = this.$store.getters["Login/GET_CLIENT_NAME"];

        const info = this.$utils.obj.DeepCopy(this.listScale[0]);
        info.cli_nome = clientName;
        info.fun_conselho_classe =
          (data && data.length > 0 && data[0].fun_conselho_classe) || "";

        const html = EmployeeAttendanceScaleHTML(info, data);
        this.$utils.print.printHtml(html);
      } catch (error) {
        this.$toaster.error(error.message);
      }
    },

    setScaleType() {
      return this.form.chk_reservaTecnica ? [{ cte_id: 2 }] : [{ cte_id: 1 }];
    },

    parseDataToSave() {
      const variables = {
        arrFormData: {
          ...this.form,
          eag_id_vinculos_setores: this.form.vus_id,
          eag_id_dias_semana: this.form.dsm_id,
          blnValidarHistoricoInabilitado: true,
          arrTiposEscala: this.setScaleType(),
          chk_reservaTecnica: this.form.chk_reservaTecnica,
          permissaoAlterarEscalaNormal: this.hasPermissionToUpdateScale(),
          permissaoAlterarReservaTecnica: this.hasPermissionToUpdateScaleTechnicalReserve(),
        },
      };

      return variables;
    },

    handleScaleErros(pData) {
      const isConflictScaleError =
        pData.exception === "ConflitoEscalaDePeriodoHorarioException";

      const exceptionDetails = Object.assign({}, pData.detalhes_exececao);

      if (isConflictScaleError) {
        this.openModalConflictScales();

        const scaleList = Array.isArray(exceptionDetails?.escalas)
          ? exceptionDetails?.escalas
          : [];

        const historyList = Array.isArray(exceptionDetails?.historicos)
          ? exceptionDetails?.historicos
          : [];

        const hasConflictsData = scaleList.length > 0 || historyList.length > 0;

        const list = scaleList.concat(historyList);

        this.conflictListScale = hasConflictsData ? list : [];
      } else {
        const errorMessage = pData.mensagem || "Problemas ao salvar escala.";
        this.$toaster.error(errorMessage);
      }
    },

    hasPermissionToUpdateScale() {
      return (
        this.$Permissions.sector.has(
          "consulta.escalaAtendimento.alterar",
          this.form.set_id,
        ) || this.hasPermissionToUpdateScaleTechnicalReserve
      );
    },

    hasPermissionToUpdateScaleTechnicalReserve() {
      return this.$Permissions.sector.has(
        "consulta.escalaAtendimento.alterarReservaTecnica",
        this.form.set_id,
      );
    },

    checkPermissons() {
      const isScaleTechnicalReserveType = this.form.chk_reservaTecnica === true;
      let namespace = null;
      let errorMessage = null;
      let hasPermission = null;

      if (isScaleTechnicalReserveType) {
        namespace = this.isEdition
          ? "consulta.escalaAtendimento.alterarReservaTecnica"
          : "consulta.escalaAtendimento.incluirReservaTecnica";

        errorMessage = this.isEdition
          ? "Sem permissão para atualizar escala com reserva técnica neste setor"
          : "Sem permissão para incluir escala com reserva técnica neste setor";

        hasPermission = this.$Permissions.sector.has(
          namespace,
          this.form.set_id,
        );
      } else {
        namespace = this.isEdition
          ? "consulta.escalaAtendimento.alterar"
          : "consulta.escalaAtendimento.incluir";

        const hasNormalPermission = this.$Permissions.sector.has(
          namespace,
          this.form.set_id,
        );

        namespace = this.isEdition
          ? "consulta.escalaAtendimento.alterarReservaTecnica"
          : "consulta.escalaAtendimento.incluirReservaTecnica";

        const hasTechnicalReservePermission = this.$Permissions.sector.has(
          namespace,
          this.form.set_id,
        );

        errorMessage = this.isEdition
          ? "Sem permissão para atualizar escala neste setor"
          : "Sem permissão para incluir escala neste setor";

        hasPermission = hasNormalPermission || hasTechnicalReservePermission;
      }

      return { hasPermission, errorMessage };
    },

    async saveScale() {
      try {
        const isValid = await this.$refs.validator.validate();

        if (!isValid) {
          if (this.$refs.save) {
            this.$refs.save.fail();
          }
          this.$toaster.warning("Verifique os campos.");
          return false;
        }

        if (this.scaleEndSpentDay) {
          this.$toaster.warning(
            "A escala ultrapassou 23:59. Caso seja necessário o plantão duplo, faça duas escalas.",
          );
          return false;
        }

        const { hasPermission, errorMessage } = this.checkPermissons();

        if (!hasPermission) {
          if (this.$refs.save) {
            this.$refs.save.fail();
          }
          throw new Error(errorMessage);
        }

        const variables = this.parseDataToSave();

        const path = this.isEdition
          ? "Appointment/Register/EDIT_APPOINTMENT_SCALE"
          : "Appointment/Register/ADD_APPOINTMENT_SCALE";

        const response = await this.$store.dispatch(path, variables);
        const isSuccessfulResponse = response.hasError === false;

        if (isSuccessfulResponse) {
          const message = this.isEdition
            ? "Escala alterada com sucesso !"
            : "Escala cadastrada com sucesso !";

          this.isEdition = false;

          this.$toaster.success(message);

          await this.getListScales();
          this.cleanScaleInfo();
        } else {
          this.handleScaleErros(response);
        }
      } catch (pErr) {
        this.$toaster.error(pErr.message);
      } finally {
        this.$loader.finish();
        if (this.$refs.save) {
          this.$refs.save.done();
          this.$refs.save.finish();
        }
      }
    },

    async editScale() {
      this.isEdition = true;

      const finalValidity =
        this.selectedLine.eag_data_fim_escala !== "N/A"
          ? this.selectedLine.eag_data_fim_escala
          : null;

      const isTechnicalReserve =
        this.selectedLine.cte_nome === "RESERVA TÉCNICA";

      const formatedInterval = this.selectedLine.eag_intervalo_consultas
        ? Number(this.selectedLine.eag_intervalo_consultas.split(" ")[0])
        : null;

      this.form.eag_id = this.selectedLine.eag_id;
      this.form.dsm_id = this.selectedLine.dsm_id;
      this.form.eag_inicio_atendimentos = this.selectedLine.eag_inicio_atendimentos;
      this.form.eag_intervalo_consultas = formatedInterval;
      this.form.eag_quantidade_consultas = this.selectedLine.eag_quantidade_consultas;
      this.form.eag_final_atendimentos = this.selectedLine.eag_final_atendimentos;
      this.form.eag_data_inicio_escala = this.selectedLine.eag_data_inicio_escala;
      this.form.eag_data_fim_escala = finalValidity;
      this.form.chk_reservaTecnica = isTechnicalReserve;
      this.form.eag_ultimo_horario = "";

      await this.calculateTimes();

      const dataToStore = this.$utils.obj.DeepCopy(this.form);

      this.$store.commit(
        "Appointment/Register/SET_SELECTED_APPOINTMENT_SCALE_LIST_LINE",
        dataToStore,
      );

      this.setScrollTop();
    },

    async deleteScaleListItem() {
      try {
        this.$loader.start("Excluindo a escala selecionada...");

        if (!this.hasSelectedLine) {
          throw new Error("Selecione uma linha para realizar a exclusão.");
        }

        const variables = {
          intIdEscalaAgendamento: this.selectedLine.eag_id,
          blnValidarHistoricoInabilitado: true,
        };

        await this.$store.dispatch(
          "Appointment/Register/DELETE_APPOINTMENT_SCALE",
          variables,
        );
        this.$toaster.success("Exclusão realizada com sucesso.");

        await this.getListScales();
      } catch (Err) {
        this.$toaster.error(Err.message);
      } finally {
        this.$loader.finish();
      }
    },

    async getListScales() {
      const isToSearch = this.form.uns_id > 0 && this.form.pes_id > 0;

      if (isToSearch) {
        const variables = {
          arrFormData: {
            idUnidadeSaude: this.form.uns_id || 0,
            set_id: this.form.set_id || 0,
            ocp_id: this.form.ocp_id || 0,
            pes_id: this.form.pes_id,
            smo_id: 6,
            uns_id: this.form.uns_id || 0,
            limiteInicio: this.pagination.offset,
            limiteFim: this.pagination.limit,
          },
        };

        this.$loader.start("Carregando escalas cadastradas...");

        const data = await this.$store.dispatch(
          "Appointment/Register/SEARCH_SCALE_LIST",
          variables,
        );

        this.listScale = data.recordSet || [];
        this.total = data.recordSet ? Number(data.total) : 0;

        this.$refs.smartTable.cleanSelectRow();
        this.selectedLine = null;

        this.$loader.finish();
      }
    },

    cleanScaleInfo() {
      this.form.eag_id = 0;
      this.form.dsm_id = null;
      this.form.eag_inicio_atendimentos = null;
      this.form.eag_intervalo_consultas = null;
      this.form.eag_quantidade_consultas = null;
      this.form.eag_final_atendimentos = null;
      this.form.eag_ultimo_horario = null;
      this.form.eag_data_inicio_escala = null;
      this.form.eag_data_fim_escala = null;
      this.form.chk_reservaTecnica = false;

      this.isEdition = false;

      this.cleanFieldsValidation();
      this.$refs.smartTable.cleanSelectRow();
      this.selectedLine = null;
    },

    cleanProfessionalInfo() {
      this.form.vus_id = 0;
      this.professionalInfo = {};
    },

    cleanPagination() {
      this.pagination.limit = 10;
      this.pagination.offset = 0;
      this.pagination.current = 1;
    },

    cleanFieldsValidation() {
      const fieldsRefs = [
        "weekDay",
        "initialTime",
        "breakTime",
        "quantity",
        "finalTime",
        "lastAvailableTime",
        "initialValidity",
        "finalValidity",
      ];

      fieldsRefs.forEach((field) => {
        if (this.$refs[field]) {
          this.$refs[field].cleanValidate();
        }
      });
    },

    async getPagination(pPagination) {
      this.pagination = pPagination;
      await this.getListScales();
    },

    onChangeCheckTechnicalReserve(value) {
      this.form.chk_reservaTecnica = value;
    },

    selectingCbo(pValue) {
      const hasSuggestion = Object.keys(pValue.source).length > 0;
      if (hasSuggestion) {
        this.$loader.start();
        this.form.ocp_id = pValue.source.ocp_id;
        this.$loader.finish();
      } else {
        this.form.ocp_id = 0;
        this.occupation = null;
      }
    },

    async selectingEmployee(pValue) {
      if (pValue) {
        const { value, vus_id } = pValue;
        this.form.pes_id = value;
        this.form.vus_id = vus_id;
        await this.getListScales();
      } else {
        this.form.pes_id = 0;
        this.form.vus_id = 0;
        this.listScale = [];
        this.total = 0;
        this.cleanPagination();
      }
    },

    validateMinute(pValue, pErrors) {
      const isInvalidRange =
        pValue && (Number(pValue) <= 0 || Number(pValue) > 59);

      if (isInvalidRange) {
        pErrors.push("Informe um número maior que 0 e menor que 60");
        return false;
      }

      return true;
    },

    validadeQuantity(pValue, pErrors) {
      if (pValue && pValue <= 0) {
        pErrors.push("O valor deve ser maior que zero");
        return false;
      }
      return true;
    },

    validateInitialValidity(pValue, pErrors) {
      if (this.form.eag_data_fim_escala) {
        const initial = this.$utils.date.BrazilianDateToDatabase(pValue);

        const final = this.$utils.date.BrazilianDateToDatabase(
          this.form.eag_data_fim_escala,
        );

        const isValidInitialValidity = moment(initial).isSameOrBefore(final);

        if (!isValidInitialValidity) {
          pErrors.push(
            "O Início da Validade deve ser menor ou igual ao Término",
          );
          return false;
        }
      }
      this.$refs.finalValidity.cleanValidate();
      return true;
    },

    validateFinalValidity(pValue, pErrors) {
      if (pValue) {
        const initial = this.$utils.date.BrazilianDateToDatabase(
          this.form.eag_data_inicio_escala,
        );

        const final = this.$utils.date.BrazilianDateToDatabase(pValue);

        const isValidFinalValidity = moment(final).isSameOrAfter(initial);

        if (!isValidFinalValidity) {
          pErrors.push(
            "O Término da Validade deve ser maior ou igual ao Início",
          );
          return false;
        }
      }
      this.$refs.initialValidity.cleanValidate();
      return true;
    },

    goBack() {
      this.$router.go(-1);
    },

    setScrollTop() {
      const elementContainer = this.$refs.content;
      if (this.$refs.content) {
        elementContainer.scrollTop = 0;
      }
    },
  },
};
</script>
