import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation FlowReservationToBusy($flow: FlowReservationToBusyInputType) {
    FlowReservationToBusy(flow: $flow) {
      int_id
    }
  }
`;
export default async ({ commit }, variables) => {
  try {
    const flow = variables;
    variables = { flow };

    const { data } = await GraphQLClient.mutate({
      mutation,
      variables,
      fetchPolicy: "no-cache",
    });

    return data.FlowReservationToBusy;
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors[0].message);
  }
};
