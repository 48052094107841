/**
 * @param {Array} pArray Array de todos os elementos selecionados
 * @param {Array} pCantIncludes Array dos outros que não podem ser inclusos junto
 */
const rules = (pCantIncludes) => (pArray) => {
  if (
    !pCantIncludes ||
    !pArray ||
    pCantIncludes.length === 0 ||
    pArray.length === 0
  ) {
    return true;
  } else {
    const found = pCantIncludes.find((cantInclude) => {
      return pArray.find((el) => {
        return el.value === cantInclude;
      });
    });
    return found === undefined;
  }
};

/**
 * Retorna as solicitações de liberação de crítica
 */
export default () => {
  return [
    { id: 1, related: "cns", name: "CNS", canBeSelected: rules() },
    {
      id: 2,
      related: "idade_maior",
      name: "Idade Maior",
      canBeSelected: rules([3]),
    },
    {
      id: 3,
      related: "idade_menor",
      name: "Idade Menor",
      canBeSelected: rules([2]),
    },
    {
      id: 4,
      related: "qtd",
      name: "Quantidade Máxima",
      canBeSelected: rules(),
    },
    { id: 5, related: "telefone", name: "Telefone", canBeSelected: rules() },
    {
      id: 6,
      related: "tempo_permanencia",
      name: "Tempo de Permanência",
      canBeSelected: rules(),
    },
  ];
};
