<template>
  <section class="configuration-container">
    <div class="title-area">
      <IconConfigure />
      <h3 class="title">{{ title }}</h3>
    </div>

    <div class="carrousel-area">
      <RgPaginationButton
        :disabled="disabledNextAndPrev"
        class="arrow"
        @click="back"
      />

      <div class="content-area">
        <div class="pagination">
          <RgButton
            v-for="(radio, index) in carouselPaginatedData"
            :key="index"
            class="paginate-button"
            :class="{ active: actualPage === index }"
            name="paginate"
            @click="setPage(index)"
          />
        </div>
        <div ref="carouselArea" class="container-area">
          <div
            v-for="item in showOnCarousel"
            :key="item.index"
            :class="{ fadeleft, faderight }"
            class="content-carousel fade"
          >
            <ListItemCarousel
              :title="item.title"
              :subtitle="item.subtitle"
              :listItem="item.list"
              :redirect="item.redirect"
            >
              <component :is="item.icon" slot="icon" class="svg-title" />
            </ListItemCarousel>
          </div>
        </div>
      </div>

      <RgPaginationButton
        :disabled="disabledNextAndPrev"
        class="arrow"
        :rotate="true"
        @click="next"
      />
    </div>
  </section>
</template>

<script>
import { IconConfigure, RgPaginationButton, RgButton } from "~tokio/primitive";
import * as Icons from "~tokio/primitive/icon/symbols";
import ListItemCarousel from "./list-item-carousel/ListItemCarousel";

export default {
  name: "ConfigurationContainer",
  components: {
    IconConfigure,
    RgPaginationButton,
    ListItemCarousel,
    ...Icons,
    RgButton,
  },
  props: {
    title: {
      type: String,
      default: "Configurações",
    },
  },
  data() {
    return {
      showOnCarousel: [],
      carouselPaginatedData: [],
      fadeleft: false,
      faderight: false,
      showPerPage: 2,
      actualPage: 0,
    };
  },

  computed: {
    disabledNextAndPrev() {
      return this.carouselPaginatedData.length === 1;
    },
  },

  created() {
    this.DATA_CAROUSEL = [
      // {
      //   index: 0,
      //   title: "Integrações",
      //   subtitle: "PEP • PLENI • MeuEsus",
      //   icon: "IconIntegration",
      //   list: [
      //     {
      //       label: "Sincronizações com o PEP",
      //       route: "/configuration/integration-services",
      //     },
      //     {
      //       label: "Tokens de Integração",
      //       route: "/configuration/integration-token-register",
      //     },
      //   ],
      // },
      // {
      //   index: 1,
      //   title: "Usuários",
      //   subtitle: "Cadastro • Acesso • Permissões",
      //   icon: "IconUserConfig",
      //   list: [],
      // },
      // {
      //   index: 2,
      //   title: "Módulos",
      //   subtitle: "Emergência • Internação • Pacientes",
      //   icon: "IconModules",
      //   list: [],
      // },
      {
        index: 1,
        title: "Sobre o Sistema",
        subtitle: "Atualizações • Privacidade • Jurídico",
        icon: "IconAbout",
        list: [],
        redirect: () => {
          return "/aboutsoftware";
        },
      },
      {
        index: 2,
        title: "Suporte",
        subtitle:
          "Obtenha atendimento para solucionar suas dúvidas e solicitações.",
        icon: "IconHelp",
        list: [],
        redirect: async () => {
          try {
            const userData = await this.$store.dispatch(
              "Home/GET_INFO_USER_HELPDESK",
            );

            const {
              usu_nome,
              usu_email,
              fone,
              mun_codigo,
              uns_nome,
              mun_nome,
            } = userData[0];

            const url = window.esusng.support;

            const dadosUsuario =
              "/#/?nome=" +
              usu_nome +
              "&email=" +
              usu_email +
              "&telefone=" +
              fone +
              "&municipioId=" +
              mun_codigo +
              "&municipioNome=" +
              mun_nome +
              "&unidadeId=" +
              "&unidadeNome=" +
              uns_nome +
              "&tipoUnidade=0" +
              "&telefoneUnidade=";

            return url.concat(dadosUsuario);
          } catch (e) {
            return window.esusng.support;
          }
        },
      },
    ];
    this.ITEM_WIDTH_MAX = 465;
    this.ITEM_WIDTH_MIN = 331.5;
  },

  mounted() {
    this.restartItem();
  },

  methods: {
    setPage(pIndex) {
      if (this.carouselPaginatedData.length === 1) {
        return;
      }

      const prevIndex = this.carouselPaginatedData.findIndex(
        (item) => item === this.showOnCarousel,
      );

      if (prevIndex > pIndex) {
        this.fadeleft = true;
        this.faderight = false;
      } else {
        this.fadeleft = false;
        this.faderight = true;
      }

      this.actualPage = pIndex;
      this.showOnCarousel = this.carouselPaginatedData[pIndex];
    },

    restartItem() {
      this.carouselPaginatedData = [];
      const containerWidth = this.$refs.carouselArea.offsetWidth;
      const itemWidth =
        window.outerWidth <= 1340 ? this.ITEM_WIDTH_MIN : this.ITEM_WIDTH_MAX;
      this.showPerPage = Math.ceil(containerWidth / itemWidth);

      for (
        let item = 0;
        item < this.DATA_CAROUSEL.length;
        item = item + this.showPerPage
      ) {
        this.carouselPaginatedData.push(
          this.DATA_CAROUSEL.slice(item, item + this.showPerPage),
        );
      }

      this.showOnCarousel = this.carouselPaginatedData[0];
    },

    back() {
      this.fadeleft = true;
      this.faderight = false;

      const index = this.carouselPaginatedData.findIndex(
        (item) => item === this.showOnCarousel,
      );

      index - 1 >= 0
        ? (this.actualPage = index - 1)
        : (this.actualPage = this.carouselPaginatedData.length - 1);

      this.showOnCarousel = this.carouselPaginatedData[this.actualPage];
    },

    next() {
      this.fadeleft = false;
      this.faderight = true;

      const index = this.carouselPaginatedData.findIndex(
        (item) => item === this.showOnCarousel,
      );

      index + 1 < this.carouselPaginatedData.length
        ? (this.actualPage = index + 1)
        : (this.actualPage = 0);

      this.showOnCarousel = this.carouselPaginatedData[this.actualPage];
    },
  },
};
</script>
