<style src="./RgInputDateDay.scss" lang="scss"></style>
<template lang="html">
  <fieldset class="rg-input--component">
    <RgFormBase :label="label">
      <div class="rg-input--base">
        <div class="rg-input--textbox-container">
          <RgValidationAlert :alert="error" />
          <input
            v-model="inputValue"
            v-mask="'##/##/####'"
            :tabindex="tabIndex"
            :autofocus="autofocus"
            type="text"
            @blur="validateBlur"
          />
          <span>{{ weekDay }}</span>
        </div>
      </div>
    </RgFormBase>
  </fieldset>
</template>

<script>
import { RgFormBase } from "~tokio/foundation/container";
import {
  RgInputMixin,
  RgValidatorMixin,
  RgValidationAlert,
} from "~tokio/primitive";
import { mask } from "vue-the-mask";

import moment from "moment";

export default {
  name: "RgInputDateDay",
  components: {
    RgValidationAlert,
    RgFormBase,
  },
  directives: {
    mask,
  },
  mixins: [RgValidatorMixin, RgInputMixin],
  props: {
    label: {
      default: "Data e Dia da Semana",
    },
    autofocus: {
      default: false,
    },
  },
  data() {
    return {
      weekDay: "",
      anotherRules: {
        checkDate(pData, pError) {
          if (moment(pData, "DD/MM/YYYY").isValid()) {
            return pData;
          } else {
            pError.push("Data Inválida");
            return false;
          }
        },
      },
    };
  },
  watch: {
    inputValue(pVal) {
      const DateDay = moment(pVal, "DD/MM/YYYY");
      if (pVal.length === 10 && DateDay.isValid()) {
        this.weekDay = DateDay.format("dddd");
      } else {
        this.weekDay = "";
      }
    },
  },
  methods: {
    validateBlur() {
      this.validate();
    },
  },
};
</script>
