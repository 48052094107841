<template lang="html">
  <svg
    v-if="active"
    id="Capa_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512.002 512.002"
    style="fill: #ffc107"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M511.267,197.258c-1.764-5.431-6.457-9.389-12.107-10.209l-158.723-23.065L269.452,20.157
        c-2.526-5.12-7.741-8.361-13.45-8.361c-5.71,0-10.924,3.241-13.451,8.361l-70.988,143.827l-158.72,23.065
        c-5.649,0.82-10.344,4.778-12.108,10.208c-1.765,5.431-0.293,11.392,3.796,15.377l114.848,111.954L92.271,482.671
        c-0.966,5.628,1.348,11.314,5.967,14.671c2.613,1.898,5.708,2.864,8.818,2.864c2.388,0,4.784-0.569,6.978-1.723l141.967-74.638
        l141.961,74.637c5.055,2.657,11.178,2.215,15.797-1.141c4.619-3.356,6.934-9.044,5.969-14.672l-27.117-158.081l114.861-111.955
        C511.56,208.649,513.033,202.688,511.267,197.258z"
        />
      </g>
    </g>
  </svg>
  <svg
    v-else
    height="511pt"
    viewBox="0 -11 511.99817 511"
    width="511pt"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m510.640625 185.507812c-3.875-11.933593-15.425781-20.132812-31.683594-22.496093l-132.511719-19.257813-59.261718-120.070312c-7.269532-14.734375-18.636719-23.183594-31.183594-23.183594s-23.914062 8.449219-31.1875 23.183594l-59.253906 120.070312-132.519532 19.257813c-16.257812 2.363281-27.808593 10.5625-31.683593 22.496093-3.875 11.933594.648437 25.355469 12.414062 36.820313l95.890625 93.464844-22.640625 131.980469c-2.894531 16.878906 2.039063 26.996093 6.6875 32.507812 5.457031 6.46875 13.40625 10.03125 22.390625 10.03125 6.765625 0 13.957032-1.976562 21.378906-5.878906l118.523438-62.3125 118.527344 62.3125c7.421875 3.902344 14.613281 5.878906 21.375 5.882812h.003906c8.984375 0 16.9375-3.566406 22.390625-10.035156 4.648437-5.511719 9.582031-15.628906 6.683594-32.507812l-22.632813-131.980469 95.882813-93.460938c11.765625-11.46875 16.289062-24.890625 12.410156-36.824219zm-33.347656 15.339844-101.53125 98.96875c-3.535157 3.445313-5.148438 8.414063-4.3125 13.277344l23.964843 139.753906c.699219 4.066406.398438 6.375.121094 7.453125-1.070312-.070312-3.367187-.484375-7.046875-2.417969l-125.507812-65.984374c-2.1875-1.148438-4.582031-1.722657-6.980469-1.722657s-4.796875.574219-6.980469 1.722657l-125.5 65.980468c-3.679687 1.933594-5.980469 2.351563-7.050781 2.421875-.273438-1.078125-.578125-3.386719.121094-7.453125l23.972656-139.75c.832031-4.867187-.78125-9.835937-4.316406-13.277344l-101.535156-98.972656c-2.953126-2.878906-4.066407-4.921875-4.480469-5.957031.941406-.59375 3.042969-1.59375 7.125-2.1875l140.324219-20.390625c4.886718-.710938 9.109374-3.78125 11.292968-8.207031l62.746094-127.144531c1.828125-3.699219 3.425781-5.386719 4.285156-6.101563.855469.714844 2.457032 2.402344 4.28125 6.101563l62.75 127.144531c2.1875 4.425781 6.410156 7.496093 11.296875 8.207031l140.3125 20.390625c4.085938.59375 6.183594 1.59375 7.125 2.1875-.414062 1.03125-1.523437 3.078125-4.476562 5.957031zm0 0"
    />
  </svg>
</template>
<script>
export default {
  name: "Star",
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
