<style src="./RgRadioSearchObjective.scss" lang="scss" scoped></style>
<template lang="html">
  <fieldset class="radio-search-objective">
    <div class="container unselect">
      <div class="radio-unified">
        <div
          :class="{ active: selectedType.includes(TYPE.UNIFIED) }"
          class="radio"
          @click="status(TYPE.UNIFIED)"
        >
          Tabela Unificada
        </div>
      </div>

      <div class="radio-tuss">
        <div
          :class="{ active: selectedType.includes(TYPE.TUSS) }"
          class="radio"
          @click="status(TYPE.TUSS)"
        >
          TUSS
        </div>
      </div>
    </div>
  </fieldset>
</template>

<script>
import TYPE from "./TypeTable";

export default {
  name: "RgRadioSearchObjective",
  components: {},
  props: {
    value: {
      default: null,
    },
  },
  data() {
    return {
      selectedType: [],
    };
  },
  computed: {
    TYPE() {
      return { ...TYPE };
    },
  },
  watch: {
    value(pValue) {
      this.selectedType = pValue;
    },
  },
  mounted() {
    this.selectedType = this.value || [];
  },
  methods: {
    status(pItem) {
      this.selectedType = [];
      this.selectedType.push(pItem);
      this.$emit("input", this.selectedType);
    },
  },
};
</script>
