<style src="./BillingModal.scss" lang="scss" scoped></style>
<template>
  <RootModal>
    <template slot="root-modal">
      <ModalPeriodShow />
    </template>

    <template slot="shortcut">
      <div class="row">
        <div class="screen-container col-6">
          <span class="title">Boletins para faturamento</span>
          <div class="shortcut-container">
            <span class="shortcut">ALT + B</span>
            <span class="description">Foco no campo N° do boletim</span>
          </div>
          <div class="shortcut-container">
            <span class="shortcut">ALT + P</span>
            <span class="description">Foco no campo Paciente</span>
          </div>
        </div>

        <div class="screen-container col-6">
          <span class="title">Faturamento Emergência</span>
          <div class="shortcut-container">
            <span class="shortcut">ALT + D</span>
            <span class="description"
              >Abre opção para inserir os procedimentos padrões</span
            >
          </div>
          <div class="shortcut-container">
            <span class="shortcut">ALT + B</span>
            <span class="description">Busca rápida por um boletim</span>
          </div>
          <div class="shortcut-container">
            <span class="shortcut"><span>CTRL + </span><IconLeftArrow /></span>
            <span class="description">Ir para a tela de Busca de Boletins</span>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="screen-container col-6">
          <span class="title">Modal de procedimentos padrões</span>
          <div class="shortcut-container">
            <span class="shortcut">Ctrl + 1, 2, 3, 4 ou 5</span>
            <span class="description">Marca/desmarca os itens</span>
          </div>
        </div>

        <div class="screen-container col-6">
          <span class="title">Faturamento Internação</span>

          <span class="title">Busca</span>
          <div class="shortcut-container">
            <span class="shortcut">Ctrl + i</span>
            <span class="description"
              >Foca no campo de Número de Internação ou AIH</span
            >
          </div>
          <div class="shortcut-container">
            <span class="shortcut">Ctrl + p</span>
            <span class="description">Foca no campo de Nome do Paciente</span>
          </div>

          <span class="title">Cadastro</span>
          <div class="shortcut-container">
            <span class="shortcut">Ctrl + 1, 2, 3 ou 4</span>
            <span class="description">Troca as Abas</span>
          </div>
          <div class="shortcut-container">
            <span class="shortcut">Ctrl + F</span>
            <span class="description">Aciona o botão Faturar</span>
          </div>
          <div class="shortcut-container">
            <span class="shortcut">Ctrl + S</span>
            <span class="description">Salva informações da Aba</span>
          </div>
        </div>
      </div>
    </template>
  </RootModal>
</template>
<script>
import RootModal from "~tokio/foundation/root-modal/RootModal";
import ModalPeriodShow from "./modal-period-show/ModalPeriodShow";
import { IconLeftArrow } from "~tokio/primitive/icon";

export default {
  name: "BillingModal",
  components: {
    ModalPeriodShow,
    RootModal,
    IconLeftArrow,
  },
};
</script>
