<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchOccupation from "./action/SearchOccupation";
export default {
  name: "RgComboboxOccupation",
  mixins: [RgComboboxMixin],
  props: {
    unitHealth: {
      type: [Number, String],
      required: true,
    },
  },

  data() {
    return {};
  },

  watch: {
    unitHealth(pValue) {
      if (pValue) {
        this.fillData();
      }
    },
  },

  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];

      if (!this.unitHealth) {
        return;
      }

      const data = await SearchOccupation({
        intIdUnidade: this.unitHealth,
        blnSomenteVinculosAtivos: false,
      });

      data.forEach((item) => {
        this.mData.push({ value: item.data, text: item.label });
      });

      this.$emit("loader", this.mData);
    },
  },
};
</script>
