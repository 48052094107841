import Vue from "vue";
import App from "./App";
import router from "./route";
import VueShortKey from "vue-shortkey";
import PermissionManager from "@/app/access-control/model/PermissionManager";
import store from "@/store";
import Toaster from "~tokio/primitive/notification/toaster/Toaster";
import Utils from "~utils/install";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import ToggleButton from "vue-js-toggle-button";
import VCalendar from "v-calendar";
import * as VueGoogleMaps from "vue2-google-maps";
import PermissionLoaderPlugin, {
  createLoader,
} from "~tokio/primitive/loader/permission-loader";
import MedicalLoaderPlugin from "~tokio/primitive/loader/medical-loader";
import "./styles/main.scss";

Vue.config.productionTip = false;
Vue.component("v-select", vSelect);
Vue.use(PermissionManager);
Vue.use(VueShortKey);
Vue.use(Toaster);
Vue.use(ToggleButton);
Vue.use(VCalendar);
Vue.use(PermissionLoaderPlugin, store);
Vue.use(MedicalLoaderPlugin, store);
Vue.use(Utils);
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.GOOGLE_API_KEY,
    libraries: "places", // necessary for places input
  },
});

Vue.config.devtools = process.env.NODE_ENV !== "production";

// #BALBI futuramente colocar esses hooks em outro lugar mais apropriado
router.beforeEach(async (to, from, next) => {
  const loaderPermission = createLoader(store);
  try {
    const isLoggedIn = !!store.getters["Login/GET_TOKEN"];
    const isLoginRoute = to.name === "Login";
    const isAccessControlRoute = to.name === "accesscontrol.access";

    if (isLoggedIn && isLoginRoute) {
      return next({ path: "/home" });
    }
    if (isLoggedIn || isLoginRoute || isAccessControlRoute) {
      loaderPermission.start("Carregando Preferencias");
      await store.dispatch("Login/LOAD_PREFERENCES_BY_MODULES", to);
      loaderPermission.message("Carregando Permissões");
      await store.dispatch("Login/LOAD_PERMISSIONS_BY_MODULES_AND_UNIT", to);
      loaderPermission.finish(500, "Carregando...");
      await next();
    } else {
      next({ path: "/login" });
    }
  } catch (pErr) {
    loaderPermission.finish(
      2000,
      "Erro ao carregar tela, você não foi redirecionado",
    );
    console.log("Falha ao carregar pagina", pErr);
  }
});

router.afterEach(() => {
  store.dispatch("Login/REFRESH_TOKENS");
});

/* eslint-disable no-new */
new Vue({
  el: "#app",
  router,
  store,
  components: { App },
  template: "<App/>",
});
