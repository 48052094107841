<template>
  <Main />
</template>

<script>
import Main from "$exam/submodules/schedule/component/main/Main";

export default {
  name: "ExamSchedulePerPeriod",
  components: {
    Main,
  },
};
</script>
