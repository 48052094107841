<style src="./RgComboboxPlaceMappedPep.scss" lang="scss"></style>
<script>
import { isNumber } from "lodash";
import { RgRemoteComboboxMixin } from "~tokio/primitive";
import ListPlacesMappedPep from "./action/ListPlacesMappedPep";

export default {
  name: "RgComboboxPlaceMappedPep",
  mixins: [RgRemoteComboboxMixin],
  props: {
    label: {
      default: "Local de Atendimento",
    },
    placeId: {
      default: null,
    },
    sectorId: {
      default: null,
    },
  },
  data: () => {
    return {};
  },
  computed: {
    filter() {
      if (!isNumber(this.sectorId)) {
        this.resetCombobox();
        return null;
      } else {
        return {
          set_id: this.sectorId,
        };
      }
    },
  },
  methods: {
    doSearch() {
      return ListPlacesMappedPep({
        placeId: this.placeId,
        sectorId: this.sectorId,
      });
    },

    formatItem(pData) {
      return {
        value: pData.lca_id,
        text: pData.lca_nome,
      };
    },
  },
};
</script>
