<template>
  <div class="smart-table" :class="{ disable: disabled }">
    <div v-if="showTableMenu" class="table-menu">
      <div
        v-if="showSelectAllPage && multSelect && body.length > 0"
        class="mult-selectors"
      >
        <button
          v-if="!selectAll"
          class="check"
          type="button"
          @click="selectAllList"
        >
          <IconBox />
        </button>
        <button
          v-if="selectAll"
          class="check"
          type="button"
          @click="unselectAllList"
        >
          <IconCheckedBox />
        </button>
        <button
          class="check"
          type="button"
          :disable="body.length === 0"
          @click="reverseListSelection"
        >
          <IconArrowBox />
        </button>
        <span class="text"
          >Linhas selecionadas: {{ multSelectedRow.length }}</span
        >
      </div>
      <slot name="legends" class="legends" />
      <slot name="top-buttons" class="top-buttons" />
      <div
        :class="{ 'dropdown-button-fixed': fixedDropdown }"
        class="dropdown-button box-scroll"
      >
        <div v-if="!removeBtnColumns" class="button">
          <span class="text unselect">Colunas</span>
          <IconArrow class="svg" />
        </div>

        <div
          :class="{
            'dropdown-fixed': fixedDropdown,
            'dropdown-absolute': !fixedDropdown,
          }"
          class="dropdown"
        >
          <span class="label">Colunas Exibidas</span>
          <label
            v-for="(col, index) in activeHeader"
            :key="col.key"
            :class="{ 'disable unselect': hasOnlyOneActiveHeader }"
            :disabled="hasOnlyOneActiveHeader"
            class="item actives unselect"
          >
            {{ col.name }}
            <input
              :id="index"
              :checked="col.active"
              class="checkbox"
              type="checkbox"
              @change="toggleStatus(col, col.key)"
            />
          </label>

          <span class="label">Colunas Não Exibidas</span>

          <label
            v-for="(col, index) in inactiveHeader"
            :key="col.key"
            class="item inactives unselect"
          >
            {{ col.name }}
            <input
              :id="index"
              :checked="col.active"
              class="checkbox"
              type="checkbox"
              @change="toggleStatus(col, col.key)"
            />
          </label>
        </div>
      </div>
    </div>
    <div ref="scrollArea" class="table-dropdown" :style="actDynamicHeight">
      <div ref="itemHeight" class="table">
        <thead class="theader">
          <tr class="title">
            <th
              v-for="(col, idx) in columnTable"
              v-show="col.active"
              :key="idx"
              :style="{ textAlign: col.align }"
              class="text"
            >
              {{ col.name }}
            </th>
          </tr>
        </thead>

        <tbody ref="tbody" class="tbody">
          <tr
            v-for="(item, index) in dataTable"
            :key="getRowKey(item, index)"
            :disabled="isDisabledRow(item)"
            :class="{
              active: isSelectedRow(item, index),
              disable: isDisabledRow(item),
              opaque: isOpaque ? isOpaque.check(item) : false,
              color: colorLine && !circleIndicator ? colorLine.get(item) : null,
            }"
            :style="{
              'background-color':
                colorLine && !circleIndicator ? colorLine.get(item) : null,
            }"
            class="body"
            @click="getItem(item, index)"
          >
            <td
              v-for="(col, idx) in columnTable"
              v-show="col.active"
              :key="idx"
              :style="{ textAlign: col.align }"
              class="data -datalimit"
            >
              <div
                :class="{
                  'has-check':
                    ((circleIndicator || hasCheck) &&
                      (isSelectedAndCheckRow(index, idx) ||
                        (idx === firstIndexSelected && showCircleColor))) ||
                    col.statusColor,
                }"
              >
                <span v-if="hasLines" class="marks">
                  <span v-if="isSelectedAndCheckRow(index, idx)" class="check">
                    <IconCheck class="svg" />
                  </span>
                  <ColorIndication
                    v-if="
                      ((idx === firstIndexSelected && showColorInFirstColumn) ||
                        col.statusColor) &&
                      showCircleColor
                    "
                    :value="colorLine.get(item)"
                    class="circle-color"
                  />
                </span>

                <span class="info">{{ item[col.key] || "-" }}</span>
              </div>
            </td>
          </tr>
        </tbody>
      </div>
    </div>

    <RgPagination
      v-show="showPagination"
      ref="paginator"
      :data-length="total"
      :item-per-page="itemPerPage"
      :max-register="maxRegister"
      @pagination="setPagination"
    />
    <slot v-show="!showPagination" />
  </div>
</template>

<script>
import { IconArrow } from "~tokio/primitive/icon/symbols";
import RgPagination from "~tokio/foundation/pagination/RgPagination";
import ColorIndication from "~tokio/primitive/indication/color-indication/ColorIndication";
import {
  IconCheck,
  IconBox,
  IconCheckedBox,
  IconArrowBox,
} from "~tokio/primitive";
import { mapGetters } from "vuex";

export default {
  name: "SmartTable",

  components: {
    IconArrow,
    RgPagination,
    IconCheck,
    IconBox,
    IconCheckedBox,
    IconArrowBox,
    ColorIndication,
  },

  props: {
    name: {
      type: String,
      required: true,
    },

    body: {
      type: Array,
      default: () => {
        return [
          {
            without_result: "Não Há Resultado(s)",
            disabled: true,
            align: "center",
          },
        ];
      },
    },
    indexColumn: {
      type: String,
      default: null,
    },

    secondIndexColumn: {
      type: String,
      default: null,
    },

    columns: {
      type: Array,
      default: () => {
        return [{ name: "Resultado", key: "without_result", align: "center" }];
      },
    },

    initialColumns: {
      type: Number,
      default: 5,
    },

    itemPerPage: {
      type: Number,
      default: 0,
    },

    maxRegister: {
      type: Number,
      default: 15,
    },

    total: {
      type: Number,
      default: 0,
    },

    dynamicHeight: {
      type: Number,
      default: 0,
    },

    showPagination: {
      type: Boolean,
      default: true,
    },

    isOpaque: {
      type: Object,
      default: null,
    },

    colorLine: {
      type: Object,
      default: null,
    },

    toggleSelected: {
      type: Boolean,
      default: false,
    },

    multSelect: Boolean,
    removeBtnColumns: Boolean,
    hasCheck: Boolean,
    dontSelect: Boolean,
    showTableMenu: {
      type: Boolean,
      default: true,
    },

    showSelectAllPage: {
      type: Boolean,
      default: true,
    },

    circleIndicator: {
      type: Boolean,
      default: false,
    },

    showColorInFirstColumn: {
      type: Boolean,
      default: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      fixedDropdown: false,
      header: [],
      pagination: {
        limit: 10,
        offset: 0,
        current: 1,
      },
      timeout: null,
      selectedRow: null,
      rowToSelect: null,
      multSelectedRow: [],
    };
  },

  computed: {
    ...mapGetters({
      userLoginId: "Login/GET_USER_ID",
      unitHealthId: "Login/GET_UNIT_HEALTH_ID",
      getColumns: "User/GET_SMART_TABLE_COLUMNS",
    }),

    firstIndexSelected() {
      return this.columnTable.findIndex((item) => item.active);
    },

    actDynamicHeight() {
      return this.dynamicHeight ? `height: ${this.dynamicHeight}px` : "";
    },

    changePosition() {
      return this.pagination.limit < 6;
    },

    dataTable() {
      const validateData =
        this.total !== 0
          ? this.body
          : [
              {
                without_result: "Não Há Resultado(s)",
                disabled: true,
                align: "center",
              },
            ];
      return validateData;
    },

    columnTable() {
      const validateColumns =
        this.total !== 0
          ? this.columns
          : [{ name: "Resultado", key: "without_result", align: "center" }];
      return this.mountTableHeader(validateColumns, this.initialColumns);
    },

    activeHeader() {
      const isHeaderFilled = this.header.length > 0;
      if (isHeaderFilled) {
        return this.header.filter((item) => item.active);
      }
      return false;
    },

    inactiveHeader() {
      const isHeaderFilled = this.header.length > 0;
      if (isHeaderFilled) {
        return this.header.filter((item) => !item.active);
      }
      return false;
    },

    hasOnlyOneActiveHeader() {
      return this.activeHeader.length === 1;
    },

    selectAll() {
      return this.multSelectedRow.length === this.body.length;
    },

    showCircleColor() {
      return !this.hasCheck && this.circleIndicator;
    },

    hasLines() {
      return this.body.length > 0;
    },
  },

  watch: {
    body(value) {
      this.performRowSelection();
    },
    rowToSelect() {
      this.performRowSelection();
    },

    activeHeader(pValue, pPrev) {
      clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        if (pPrev && pValue[0].key !== "without_result") {
          const link = this.$route.path;

          this.$store.commit("User/SET_SMART_TABLE_COLUMNS", {
            link,
            usu_id: this.userLoginId,
            uns_id: this.unitHealthId,
            columns: pValue,
            name: this.name,
          });
        }
      }, 400);
    },
  },

  created() {
    this.MAX_COLUMNS = 20;
  },

  methods: {
    cleanMultSelectedRow() {
      this.multSelectedRow = [];
    },

    mountTableHeader(ArrColumns, initialColumns = 1) {
      let header;
      const link = this.$route.path;
      const savedHeader = this.getColumns(
        link,
        this.userLoginId,
        this.unitHealthId,
        this.name,
      );

      if (savedHeader) {
        const activeKey = [];
        savedHeader.columns.forEach((item) => {
          activeKey.push(item.key);
        });

        header = ArrColumns.map((item) => {
          if (activeKey.includes(item.key)) {
            return {
              name: item.name,
              key: item.key,
              align: item.align,
              active: true,
              ...item,
            };
          } else {
            return {
              name: item.name,
              key: item.key,
              align: item.align,
              active: false,
              ...item,
            };
          }
        });
      } else {
        header = ArrColumns.map((item, idx) => {
          const active = idx < this.initialColumns;
          return {
            name: item.name,
            key: item.key,
            align: item.align,
            active,
            ...item,
          };
        });
      }

      if (this.total === 0) {
        header = [
          {
            name: "Resultado",
            key: "without_result",
            active: true,
            align: "center",
          },
        ];
      }

      this.header = header;
      return header;
    },

    selectRow(item) {
      this.rowToSelect = item;
    },

    performRowSelection() {
      const withoutRowToSelect = this.rowToSelect !== null;

      if (withoutRowToSelect) {
        const index = this.body.findIndex((row) => {
          const compareIndex =
            this.rowToSelect[this.indexColumn] === row[this.indexColumn];

          const isToSelectSecondIndex =
            this.secondIndexColumn && this.rowToSelect[this.indexColumn];

          if (isToSelectSecondIndex) {
            const compareTwo =
              this.rowToSelect[this.secondIndexColumn] ===
              row[this.secondIndexColumn];

            return compareIndex && compareTwo;
          } else {
            return compareIndex;
          }
        });

        const validIndex = index > -1;

        if (validIndex) {
          this.rowToSelect = null;
          this.selectedRow = index;
          this.setScrollOnSelectedItem();
        }
      }
    },

    getHeaderIndex(pKey) {
      return this.header.findIndex((item) => item.key === pKey);
    },

    getRowKey(item, index) {
      return item[this.indexColumn]
        ? item[this.secondIndexColumn]
          ? item[this.indexColumn] + item[this.secondIndexColumn]
          : item[this.indexColumn]
        : index;
    },

    getActiveColumnsIndex() {
      const actives = [];
      this.header.forEach((item, index) => {
        if (item.active) actives.push(index);
      });
      return actives;
    },

    getFirsActiveColumnIndex() {
      return this.getActiveColumnsIndex()[0];
    },

    getLastActiveColumnIndex() {
      const activeColumns = this.getActiveColumnsIndex();
      return activeColumns[activeColumns.length - 1];
    },

    getActiveColumnsLength() {
      return this.getActiveColumnsIndex().length;
    },

    toggleStatus(pColumn, pKey) {
      pColumn.active = !pColumn.active;

      const index = this.getHeaderIndex(pKey);
      const activesLength = this.getActiveColumnsLength();
      const maxReached = activesLength > this.MAX_COLUMNS;
      const middle = activesLength / 2;
      const currentIndex = index + 1;

      if (maxReached) {
        const idx =
          currentIndex > middle
            ? this.getFirsActiveColumnIndex()
            : this.getLastActiveColumnIndex();

        this.header[idx].active = false;
      }
    },

    setPagination(pPagination) {
      this.cleanSelectRow();

      this.$emit("pagination", pPagination);
    },

    cleanSelectRow() {
      this.selectedRow = null;
    },

    isSelectedAndCheckRow(pIndex, pIndexCol) {
      if (this.total <= 0) {
        return;
      }
      if (!this.hasCheck || pIndexCol !== this.firstIndexSelected) {
        return;
      }

      if (this.multSelect) {
        return this.multSelectedRow.find((el) => el.index === pIndex);
      }
      return this.selectedRow === pIndex;
    },

    isSelectedRow(pItem, pIndex) {
      if (this.total <= 0) {
        return false;
      }
      if (this.multSelect) {
        if (!this.indexColumn || !pItem[this.indexColumn]) {
          return this.multSelectedRow.find((el) => el.index === pIndex);
        } else {
          return this.multSelectedRow.find(
            (el) => el[this.indexColumn] === pItem[this.indexColumn],
          );
        }
      }
      return this.selectedRow === pIndex;
    },

    isDisabledRow(pItem) {
      return pItem.disabled === true;
    },

    getItem(pItem, pIndex) {
      if (this.dontSelect) {
        return;
      }

      if (this.multSelect) {
        this.getMultItems(pItem, pIndex);
        this.$emit("getMultLines", this.multSelectedRow);
      } else {
        const isSameLine = this.selectedRow === pIndex;
        if (this.toggleSelected && isSameLine) {
          this.selectedRow = null;
          this.$emit("getLine", null);
        } else {
          this.selectedRow = pIndex;

          if (pItem && !pItem.without_result) {
            this.$emit("getLine", pItem);
          }
        }
      }
    },

    getMultItems(pItem, pIndex) {
      if (!this.indexColumn || !pItem[this.indexColumn]) {
        const alreadySelected = this.multSelectedRow.find(
          (el) => el.index === pIndex,
        );
        if (alreadySelected) {
          pItem.index = false;
          this.multSelectedRow.map((el, index) => {
            if (el.index === false) {
              this.multSelectedRow.splice(index, 1);
            }
          });
        } else {
          pItem.index = pIndex;
          this.multSelectedRow.push(pItem);
        }
      } else {
        const alreadySelected = this.multSelectedRow.find((el) => {
          return el[this.indexColumn] === pItem[this.indexColumn];
        });

        const index = this.multSelectedRow.findIndex((el) => {
          return el[this.indexColumn] === pItem[this.indexColumn];
        });

        if (alreadySelected) {
          pItem.isSelected = false;
          this.multSelectedRow.splice(index, 1);
        } else {
          pItem.isSelected = true;
          this.multSelectedRow.push(pItem);
        }
      }
    },

    resetLimitOffset() {
      this.$refs.paginator.resetLimitOffset();
      this.$refs.paginator.loadPagination();
    },

    setScrollTopZero() {
      const elementContainer = this.$refs.scrollArea;
      if (this.$refs.scrollArea) {
        elementContainer.scrollTop = 0;
      }
    },

    setScrollOnSelectedItem() {
      this.$nextTick(() => {
        const classElement = "active";
        const element = this.$el.getElementsByClassName(classElement)[0];

        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
        }
      });
    },

    selectAllList() {
      if (this.body.length > 0) {
        this.cleanMultSelectedRow();
        this.body.forEach((ele, index) => {
          ele.index = index;
          this.multSelectedRow.push(ele);
        });
      }

      this.$emit("getMultLines", this.multSelectedRow);
    },

    unselectAllList() {
      if (this.body.length > 0) {
        this.body.forEach((ele, index) => {
          ele.index = false;
        });
        this.cleanMultSelectedRow();
      }

      this.$emit("getMultLines", this.multSelectedRow);
    },

    reverseListSelection() {
      // Antes de alterar essa lógica. Verifica a saída no multSelectedRow.
      this.body.map((ele, i) => {
        if (ele?.index === undefined || ele.index === false) {
          ele.index = i;
        } else {
          ele.index = false;
        }
      });
      this.cleanMultSelectedRow();
      this.multSelectedRow = this.body.filter((e) => {
        return e.index !== false;
      });
      this.$emit("getMultLines", this.multSelectedRow);
    },
  },
};
</script>
