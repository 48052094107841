<style lang="scss" src="./GridServiceScale.scss" scoped></style>
<template>
  <SmartTable
    ref="smartTable"
    name="GridServiceScale"
    :dynamic-height="dynamicHeight"
    v-bind="propsSmartTable"
    @getLine="selectRow"
    @pagination="getPagination"
  >
    <div slot="legends" class="captions">
      <div class="grid-captions">
        <div class="item">
          <div class="ball green" />
          <div>Na Validade</div>
        </div>
        <div class="item">
          <div class="ball red" />
          <div>Fora da Validade</div>
        </div>
        <div class="item">
          <div class="ball blue" />
          <div>Em Breve</div>
        </div>
      </div>
    </div>
    <div
      v-if="!hideAllButtons"
      ref="footerSlot"
      slot="top-buttons"
      class="top-buttons"
    >
      <RgNewButton
        v-if="showNewButton"
        small
        title="Cadastrar"
        :permission="hasPermissionIncludes"
        class="item"
        @click="clickNewServiceScale"
      />

      <RgEditButton
        v-if="showEditButton"
        small
        title="Editar"
        class="item"
        :disabled="disableByRowSelected || isEdition"
        :permission="hasPermissionEdit"
        @click="clickEditServiceScale"
      />

      <RgLessButton
        title="Excluir"
        class="item"
        :disabled="disableByRowSelected || isEdition"
        :permission="hasDeletePermission"
        @click="openModalDeleteScale"
      />

      <RgPrinterButton
        title="Imprimir"
        class="item"
        :disabled="disablePrintAndHistory"
        @click="clickPrint"
      />

      <RgHistoryButton
        small
        title="Histórico"
        class="item"
        :disabled="disablePrintAndHistory"
        :class="{
          disabled: disablePrintAndHistory,
        }"
        @click="openModalHistoryScales"
      />
    </div>
  </SmartTable>
</template>

<script>
import {
  RgNewButton,
  RgEditButton,
  RgLessButton,
  RgPrinterButton,
  RgHistoryButton,
} from "~tokio/primitive";

import SmartTable from "~tokio/foundation/smart-table/SmartTable";
import GetColorRowSmartTable from "~common/utils/GetColorRowSmartTable";

import ExamServiceScaleRowHTML from "../../html/ExamServiceScaleRowHTML";

import moment from "moment";

const COLUMN_TABLE = [
  { name: "Tipo", key: "ees_nome" },
  { name: "Unidade de Saúde", key: "uns_nome" },
  { name: "Setor", key: "set_nome" },
  { name: "Ocupação", key: "ocp_nome" },
  { name: "Profissional", key: "pes_nome" },
  { name: "Dia", key: "dsm_sigla" },
  { name: "Validade", key: "validate" },
  { name: "Horário de Início", key: "eea_inicio_atendimentos" },
  { name: "Horário de Término", key: "eea_final_atendimentos" },
  { name: "Intervalo", key: "eti_nome" },
  { name: "Vagas", key: "eea_quantidade_exames" },
];

export default {
  name: "GridServiceScale",
  components: {
    SmartTable,
    RgNewButton,
    RgEditButton,
    RgLessButton,
    RgPrinterButton,
    RgHistoryButton,
  },

  props: {
    showNewButton: {
      type: Boolean,
      default: true,
    },
    showEditButton: {
      type: Boolean,
      default: true,
    },
    body: null,
    columns: {
      type: Array,
      default: () => COLUMN_TABLE,
    },
    total: {
      type: Number,
      default: 0,
    },
    initialColumns: {
      type: Number,
      default: 7,
    },
    dynamicHeight: {
      type: Number,
      default: 0,
    },
    bond: {
      type: Number,
      default: 0,
    },
    hideAllButtons: Boolean,
    modalScaleConflict: Boolean,
    listingRegistry: Boolean,
    selectedProfessional: Boolean,
    isEdition: Boolean,
    showPagination: Boolean,
    registerScale: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      selectedRow: null,
      modalDeleteShow: false,
      hasEmployee: false,
    };
  },

  computed: {
    hasPermissionIncludes() {
      return !!this.$Permissions.global.has("exame2.examesEscala.incluir");
    },

    hasPermissionEdit() {
      return !!this.$Permissions.global.has("exame2.examesEscala.alterar");
    },

    isListGrid() {
      return this.$route.name === "exam.register.list-service-scale";
    },

    disablePrintAndHistory() {
      return this.body.length < 1 || !this.hasEmployee || this.isEdition;
    },

    propsRadioCustom() {
      const list = [
        { title: "Na validade", green: true, id: 1 },
        { title: "Fora da validade", red: true, id: 2 },
      ];
      const uniqueKey = "id";
      return { list, uniqueKey };
    },

    propsSmartTable() {
      return {
        columns: this.columns,
        body: this.body,
        total: this.total,
        initialColumns: this.initialColumns,
        showPagination: this.showPagination,
        itemPerPage: 10,
        maxRegister: 10,
        indexColumn: "eea_id",
        toggleSelected: true,
        colorLine: this.actionColorLine,
        circleIndicator: true,
        removeBtnColumns: this.modalScaleConflict,
      };
    },

    actionColorLine() {
      return {
        get: (item) => {
          if (item.without_result) {
            return "";
          }
          const { eea_data_inicio_escala, eea_data_fim_escala } = item;
          const now = moment(new Date()).format("YYYY-MM-DD");

          const initial = moment(eea_data_inicio_escala, "DD/MM/YYYY").format(
            "YYYY-MM-DD",
          );

          const final = moment(eea_data_fim_escala, "DD/MM/YYYY").format(
            "YYYY-MM-DD",
          );

          if (
            eea_data_inicio_escala !== "N/A" &&
            moment(now).isBefore(initial)
          ) {
            // escalas q data inicio > atual (EM BREVE)
            return GetColorRowSmartTable("blue");
          } else if (
            eea_data_fim_escala !== "N/A" &&
            moment(now).isAfter(final)
          ) {
            // escalas q data final < atual (VENCIDAS)
            return GetColorRowSmartTable("red");
          }
          // não entrando nestas regras significa que está na validade
          return GetColorRowSmartTable("green");
        },
      };
    },

    disableByRowSelected() {
      return this.selectedRow === null;
    },

    hasDeletePermission() {
      return this.$Permissions.global.has("exame2.examesEscala.excluir");
    },
  },

  watch: {
    selectedProfessional(pValue) {
      if (pValue) {
        this.hasEmployee = true;
      }
    },
    selectedRow(pValue) {
      if (!pValue && this.isListGrid) {
        this.hasEmployee = false;
      }
    },
  },

  methods: {
    clickNewServiceScale() {
      this.$router.push({ name: "exam.register.service-scale" });
    },

    openModalDeleteScale() {
      this.$emit("openModalDeleteScale");
    },

    openModalHistoryScales() {
      this.$emit("openModalHistoryScales");
    },

    clickEditServiceScale() {
      if (!this.listingRegistry) {
        this.$router.push({ name: "exam.register.service-scale.edit" });
      } else {
        this.$emit("edit");
      }
    },

    async clickPrint() {
      try {
        let info = {};
        let vusId = null;

        this.selectedRow?.vus_id
          ? (vusId = this.selectedRow.vus_id)
          : (vusId = this.registerScale.vus_id);

        const clientName = this.$store.getters["Login/GET_CLIENT_NAME"];
        const snap = await this.$store.dispatch(
          "Exam/Register/PRINT_SERVICE_SCALE",
          {
            vus_id: Number(vusId),
          },
        );

        if (snap && snap.length > 0) {
          this.selectedRow
            ? (info = this.$utils.obj.DeepCopy(this.selectedRow))
            : (info = this.$utils.obj.DeepCopy(this.registerScale));

          info.cli_nome = clientName;

          const html = ExamServiceScaleRowHTML(info, snap);
          this.$utils.print.printHtml(html);
        } else {
          this.$toaster.warning(
            "Só é permitida a impressão de escalas dentro da validade.",
          );
          return false;
        }
      } catch (error) {
        this.$toaster.error(error);
      }
    },

    selectRow(row) {
      if (!row) {
        this.selectedRow = null;
      }

      this.selectedRow = row;

      if (this.isListGrid) {
        this.selectedRow?.vus_id > 0
          ? (this.hasEmployee = true)
          : (this.hasEmployee = false);
      }

      this.$emit("selectedRow", this.selectedRow);

      this.$store.commit(
        "Exam/Register/SET_SELECT_ROW_LIST_SERVICE_SCALE",
        row,
      );
    },

    getPagination(pPagination) {
      this.$emit("pagination", pPagination);
    },

    cleanSelectRow() {
      this.$refs.smartTable.cleanSelectRow();
      this.selectedRow = null;
    },
  },
};
</script>
