import GetBulletinStatus from "../action/getBulletinStatus";

export default {
  SELECT_BULLETIN_TO_BILLING: ({ commit }, bulletinId) => {
    GetBulletinStatus(bulletinId).then((res) => {
      commit("SELECT_BULLETIN_ITEM", bulletinId);
      commit("SELECT_BOF_ID", res.data.bulletinStatus.bof_id);
      commit("SELECT_BULLETIN_STATUS", res.data.bulletinStatus.status);
    });
  },
};
