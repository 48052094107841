import AuthRequest from "~common/request/AuthRequest";

export default (pFilter) => {
  return AuthRequest.get(
    "/billing/procedure/search-service-character",
    pFilter,
  ).then((pResult) => {
    return pResult.data;
  });
};
