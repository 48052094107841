<style src="./RgComboboxSurgeryKits.scss" lang="scss"></style>
<script>
import { RgRemoteComboboxMixin } from "~tokio/primitive";
import SurgeryKitModel from "$surgery-center/surgery/model/SurgeryKitModel";
export default {
  name: "RgComboboxSurgeryKits",
  mixins: [RgRemoteComboboxMixin],
  data: () => {
    return {
      model: SurgeryKitModel,
    };
  },
  methods: {
    formatItem(pData) {
      return {
        value: pData.tkc_id,
        text: pData.tkc_nome,
      };
    },
  },
};
</script>
