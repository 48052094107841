<style src="./RootModal.scss" lang="scss" scoped></style>
<template>
  <section class="root-modal">
    <slot name="root-modal" />

    <ModalShortcutHelp>
      <slot name="shortcut" />
    </ModalShortcutHelp>
  </section>
</template>

<script>
import ModalShortcutHelp from "./modal-shortcut-help/ModalShortcutHelp";

export default {
  name: "RootModal",
  components: {
    ModalShortcutHelp,
  },
};
</script>
