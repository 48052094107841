<template lang="html">
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 482.14 482.14"
    style="enable-background: new 0 0 482.14 482.14"
    xml:space="preserve"
  >
    <g>
      <path
        d="M315.459,0H121.812C93.521,0,70.496,23.041,70.496,51.315v189.754h-5.013c-11.418,0-20.677,9.251-20.677,20.679v125.404
    c0,11.427,9.259,20.677,20.677,20.677h5.013v22.995c0,28.305,23.025,51.315,51.315,51.315h264.221
    c28.274,0,51.299-23.011,51.299-51.315V121.449L315.459,0z M79.014,278.973c7.227-1.15,16.642-1.797,26.581-1.797
    c16.516,0,27.226,2.964,35.618,9.283c9.031,6.714,14.704,17.432,14.704,32.783c0,16.642-6.06,28.131-14.452,35.224
    c-9.157,7.61-23.096,11.221-40.125,11.221c-10.196,0-17.423-0.646-22.325-1.292V278.973z M386.033,450.713H121.812
    c-10.955,0-19.875-8.92-19.875-19.889v-22.995h246.31c11.418,0,20.679-9.25,20.679-20.677V261.748
    c0-11.428-9.26-20.679-20.679-20.679h-246.31V51.315c0-10.938,8.92-19.858,19.875-19.858l181.89-0.19v67.233
    c0,19.638,15.934,35.587,35.585,35.587l65.863-0.189l0.741,296.925C405.892,441.793,396.986,450.713,386.033,450.713z
    M164.868,321.951c0-26.066,16.644-45.547,42.325-45.547c26.714,0,41.292,20,41.292,44.002c0,28.511-17.289,45.8-42.711,45.8
    C179.968,366.206,164.868,346.728,164.868,321.951z M305.955,349.816c5.934,0,12.513-1.293,16.39-2.838l2.963,15.352
    c-3.609,1.813-11.741,3.751-22.316,3.751c-30.071,0-45.555-18.708-45.555-43.482c0-29.678,21.166-46.194,47.484-46.194
    c10.198,0,17.936,2.064,21.419,3.861l-3.996,15.619c-4.003-1.688-9.551-3.231-16.516-3.231c-15.619,0-27.745,9.425-27.745,28.778
    C278.084,338.847,288.405,349.816,305.955,349.816z"
      />
      <path
        d="M135.018,319.887c0.126-18.076-10.456-27.611-27.36-27.611c-4.39,0-7.227,0.378-8.905,0.771v56.895
    c1.678,0.395,4.39,0.395,6.841,0.395C123.403,350.462,135.018,340.659,135.018,319.887z"
      />
      <path
        d="M227.713,321.038c0-15.603-7.486-29.03-21.033-29.03c-13.293,0-21.04,12.654-21.04,29.55
    c0,17.037,8.006,29.03,21.167,29.03C220.101,350.588,227.713,337.947,227.713,321.038z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Doc",
};
</script>
