<style src="./FilterBaseContainer.scss" lang="scss" scoped></style>
<template lang="html">
  <div class="filter-base-container">
    <div class="filter-base--content">
      <div class="filter-base--input">
        <slot />
      </div>
      <div v-if="closeButton" class="command-container">
        <button type="button" class="button--close" @click="closeFilter()">
          <IconClose class="icon-button--close" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { IconClose } from "~tokio/primitive";

export default {
  name: "FilterBaseContainer",
  components: {
    IconClose,
  },
  props: {
    closeButton: {
      default: true,
    },
    filterKey: {
      default: null,
    },
  },
  methods: {
    closeFilter() {
      this.$store.dispatch("SurgeryCenter/Surgery/fillFilter", {
        filterKey: this.filterKey,
        filterValue: "",
      });
      this.$store.dispatch("SurgeryCenter/Surgery/filter");
      this.$emit("close");
    },
  },
};
</script>
