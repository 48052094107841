import ProcedureChangeValue from "../common/service/ProcedureChangeValue";

export default {
  SET_HOSPITALIZATION_AIH_LIST: (state, hospitalizationList) => {
    state.hospitalizationList = hospitalizationList;
  },
  SET_HOSPITALIZATION_SEARCH_FILTER: (state, form) => {
    state.searchFilter.initialDate = form.initialDate;
    state.searchFilter.finalDate = form.finalDate;
    state.searchFilter.pes_nome = form.pes_nome;
    state.searchFilter.int_numero = form.int_numero;
  },
  SET_HOSPITALIZATION_ID: (state, hospitalizationId) => {
    state.hospitalizationAihInfo.iaf_id_internacoes = hospitalizationId;
    state.hospitalizationId = hospitalizationId;
    state.hospitalizationBillingAihId = null;
  },

  SET_HOSPITALIZATION_BILLING_AIH_ID: (state, hospitalizationBillingAihId) => {
    state.hospitalizationAihInfo.iaf_id = hospitalizationBillingAihId;
    state.hospitalizationBillingAihId = hospitalizationBillingAihId;
    state.hospitalizationId = null;
  },

  SET_HOSPITALIZATION_BILLING_AIH_STATUS: (state, status) => {
    state.hospitalizationAihInfo.iaf_faturado = status;
  },

  SET_HOSPITALIZATION_AIH_INFO: (state, data) => {
    state.hospitalizationAihInfo = { ...data };
  },

  SET_HOSPITALIZATION_AIH_HOSPITALIZATION_DATE: (state, date) => {
    state.hospitalizationAihInfo.iaf_data_internacao = date;
  },

  SET_HOSPITALIZATION_AIH_EXITS_DATE: (state, date) => {
    state.hospitalizationAihInfo.iaf_data_saida = date;
  },

  SET_PATIENT_INFO_TAB: (state, data) => {
    state.patientInfoTab = { ...data };
  },

  SET_PATIENT_INFO_TAB_ID: (state, pId) => {
    state.patientInfoTab.icf_id = pId;
  },

  SET_BILLING_INFO_TAB_ID: (state, pId) => {
    state.hospitalizationBillingInfoTab.inf_id = pId;
  },

  SET_BILLING_INFO_TAB_AIH_NUMERO_ANTERIOR: (state, pAihNumeroAnterior) => {
    state.hospitalizationBillingInfoTab.inf_aih_numero_anterior = pAihNumeroAnterior;
  },

  SET_HOSPITALIZATION_BILLING_INFO_TAB: (state, data) => {
    state.hospitalizationBillingInfoTab = { ...data };
  },

  SET_HOSPITALIZATION_AIH_PROCEDURES: (state, data) => {
    state.hospitalizationAihProcedures = data;
  },

  SET_HOSPITALIZATION_BILLING_DIAGNOSTICS_INFO: (state, data) => {
    state.hospitalizationBillingDiagnosticsInfo = data;
  },

  SET_PATIENT_DOCUMENTS: (state, data) => {
    state.patientDocuments = { ...data };
  },

  SET_PATIENT_UNIT_RECORD_NUMBER: (state, patientUnitRecordNumber) => {
    state.patientUnitRecordNumber = patientUnitRecordNumber;
  },

  SET_PATIENT_CNS: (state, pCns) => {
    state.patientInfoTab.icf_cns = pCns;
  },

  SET_HOSPITALIZATION_BILLING_CHARACTER: (state, pCharacter) => {
    state.hospitalizationBillingInfoTab.inf_codigo_carater_internacao = pCharacter;
  },

  SET_HOSPITALIZATION_AIH_RELEASE_REQUEST_CODE: (state, data) => {
    state.hospitalizationAihReleaseRequestCode = data;
  },

  SET_PATIENT_SEARCHED: (state, data) => {
    state.patientSearched = data;
  },

  SET_HOSPITALIZATION_BILLING_PROCEDURES: (
    state,
    {
      inf_procedimento_principal,
      inf_procedimento_solicitado,
      inf_cid_principal,
    },
  ) => {
    state.hospitalizationBillingInfoTab.inf_procedimento_principal = inf_procedimento_principal;
    state.hospitalizationBillingInfoTab.inf_procedimento_solicitado = inf_procedimento_solicitado;
    state.hospitalizationBillingInfoTab.inf_cid_principal = inf_cid_principal;
    state.hospitalizationBillingInfoTab.inf_mudanca_procedimento = ProcedureChangeValue(
      inf_procedimento_principal,
      inf_procedimento_solicitado,
    );
  },

  DESTROY_HOSPITALIZATION_AIH_INFO: (state) => {
    state.hospitalizationAihInfo.iaf_id = null;
    state.hospitalizationAihInfo.iaf_id_internacoes = null;
    state.hospitalizationAihInfo.iaf_numero_aih = null;
    state.hospitalizationAihInfo.iaf_tipo_aih = 1;
    state.hospitalizationAihInfo.iaf_prontuario = "";
    state.hospitalizationAihInfo.iaf_data_internacao = "";
    state.hospitalizationAihInfo.iaf_data_saida = "";
    // state.hospitalizationAihInfo.iaf_orgao_emissor = ''
    state.hospitalizationAihInfo.iaf_faturado = 0;
  },

  DESTROY_PATIENT_INFO_TAB: (state) => {
    state.patientInfoTab.icf_id = null;
    state.patientInfoTab.icf_nome = null;
    state.patientInfoTab.icf_nome_mae = null;
    state.patientInfoTab.icf_nome_responsavel = null;
    state.patientInfoTab.icf_data_nascimento = null;
    state.patientInfoTab.icf_cod_raca = null;
    state.patientInfoTab.icf_cod_etnia = null;
    state.patientInfoTab.icf_cns = null;
    state.patientInfoTab.icf_sexo = null;
    state.patientInfoTab.icf_tel = "";
    state.patientInfoTab.icf_tel_ddd = "";
    state.patientInfoTab.icf_cod_tipo_logradouro = null;
    state.patientInfoTab.icf_logradouro = null;
    state.patientInfoTab.icf_logradouro_numero = null;
    state.patientInfoTab.icf_complemento = null;
    state.patientInfoTab.icf_cep = null;
    state.patientInfoTab.icf_bairro = null;
    state.patientInfoTab.icf_cod_municipio = "";
    state.patientInfoTab.icf_cod_nacionalidade = "010";
    state.patientInfoTab.icf_uf = "";
    state.patientInfoTab.icf_tipo_documento = null;
    state.patientInfoTab.icf_documento = "";
    state.patientInfoTab.icf_indigente = "0";
    state.patientDocuments = {};
    state.patientUnitRecordNumber = null;
  },

  DESTROY_HOSPITALIZATION_BILLING_INFO_TAB: (state) => {
    state.hospitalizationBillingInfoTab.inf_id = null;
    state.hospitalizationBillingInfoTab.inf_procedimento_principal = null;
    state.hospitalizationBillingInfoTab.inf_mudanca_procedimento = null;
    state.hospitalizationBillingInfoTab.inf_procedimento_solicitado = null;
    state.hospitalizationBillingInfoTab.inf_codigo_modalidade = null;
    state.hospitalizationBillingInfoTab.inf_codigo_especialidade_leito = null;
    state.hospitalizationBillingInfoTab.inf_codigo_carater_internacao = null;
    state.hospitalizationBillingInfoTab.inf_cid_principal = null;
    state.hospitalizationBillingInfoTab.inf_motivo_encerramento = null;
    state.hospitalizationBillingInfoTab.inf_nome_solicitante = null;
    state.hospitalizationBillingInfoTab.inf_documento_solicitante = null;
    state.hospitalizationBillingInfoTab.inf_documento_tipo_solicitante = null;
    state.hospitalizationBillingInfoTab.inf_nome_responsavel = null;
    state.hospitalizationBillingInfoTab.inf_documento_responsavel = null;
    state.hospitalizationBillingInfoTab.inf_documento_tipo_responsavel = null;
    state.hospitalizationBillingInfoTab.inf_nome_autorizador = null;
    state.hospitalizationBillingInfoTab.inf_documento_autorizador = null;
    state.hospitalizationBillingInfoTab.inf_documento_tipo_autorizador = null;
    state.hospitalizationBillingInfoTab.inf_data_autorizacao = null;
    state.hospitalizationBillingInfoTab.inf_aih_numero_anterior = null;
    state.hospitalizationBillingInfoTab.inf_aih_numero_posterior = null;
    state.hospitalizationBillingInfoTab.inf_cnpj_empregador = null;
    state.hospitalizationBillingInfoTab.inf_cnaer = null;
    state.hospitalizationBillingInfoTab.inf_vinculos_previdencia = null;
    state.hospitalizationBillingInfoTab.inf_cbo = null;
    state.hospitalizationBillingInfoTab.inf_codigo_solicitacao_liberacao = null;
    state.hospitalizationBillingInfoTab.inf_justificativa = null;
    state.hospitalizationAihReleaseRequestCode = null;
  },

  DESTROY_ERROR_TABS: (state) => {
    state.errorTabs.aih = true;
    state.errorTabs.patient = true;
    state.errorTabs.hospitalization = true;
    state.errorTabs.diagnostic = true;
    state.errorTabs.procedure = true;
  },
};
