<template lang="html">
  <svg
    enable-background="new 0 0 502.224 502.224"
    version="1.1"
    viewBox="0 0 502.22 502.22"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="#c0cadd">
      <g fill="#c0cadd">
        <g fill="#c0cadd">
          <path
            d="m470.22 251.3h32c0.117-123.64-100.02-223.97-223.66-224.08-117.67-0.112-215.34 90.892-223.54 208.28l-32.4-32.4-22.624 22.608 68.8 68.8 68.8-68.8-22.688-22.608-27.536 27.536c11.597-105.4 106.44-181.45 211.85-169.85 97.2 10.695 170.84 92.735 171 190.52z"
          />
          <path
            d="m222.34 468.27c11.129 2.844 22.458 4.845 33.888 5.984l3.2-31.856c-9.802-0.97-19.516-2.687-29.056-5.136l-8.032 31.008z"
          />
          <path
            d="m101.22 325.14-29.536 12.32c4.429 10.596 9.66 20.839 15.648 30.64l27.296-16.688c-5.128-8.405-9.61-17.188-13.408-26.272z"
          />
          <path
            d="m159.28 441.04c9.708 6.104 19.872 11.453 30.4 16l12.688-29.376c-9.039-3.903-17.764-8.498-26.096-13.744l-16.992 27.12z"
          />
          <path
            d="m131.92 375.35-24.416 20.688c7.417 8.764 15.494 16.948 24.16 24.48l20.992-24.16c-7.441-6.46-14.374-13.483-20.736-21.008z"
          />
          <path
            d="m397.47 401.7 19.952 24.944c8.984-7.155 17.404-14.992 25.184-23.44l-23.584-21.68c-6.663 7.262-13.868 14.006-21.552 20.176z"
          />
          <path
            d="m288.88 443.01 1.744 32c11.481-0.63 22.896-2.144 34.144-4.528l-6.656-31.312c-9.631 2.027-19.404 3.311-29.232 3.84z"
          />
          <path
            d="m437.38 358.34 26.56 17.856c6.405-9.536 12.07-19.55 16.944-29.952l-28.992-13.568c-4.17 8.915-9.022 17.496-14.512 25.664z"
          />
          <path
            d="m346.4 430.8 11.408 29.904c10.737-4.103 21.142-9.03 31.12-14.736l-15.888-27.776c-8.546 4.876-17.452 9.091-26.64 12.608z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "Chargeback",
};
</script>
