import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query searchCustomPatientTransfer(
    $uns_id: Int # Id da Unidade de Saúde
    $set_id: [Int] # Id do Setor
    $lca_id: Int # Id do Local de Atendimento
    $itl_id: Int # Id do Tipo de Leito
    $limit: Int
    $offset: Int
  ) {
    customPatientTransfer(
      uns_id: $uns_id
      set_id: $set_id
      lca_id: $lca_id
      itl_id: $itl_id
      limit: $limit
      offset: $offset
    ) {
      count
      rows {
        id
        itl_id
        uns_id
        uns_nome
        set_id
        set_nome
        lca_id
        lca_nome
        inl_nome
        ils_nome
        inl_extra
        inl_observacao
        tipos_do_leito
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    return data.customPatientTransfer;
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors);
  }
};
