<template lang="html">
  <svg
    id="Capa_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 472.212 472.212"
    style="enable-background: new 0 0 472.212 472.212"
    xml:space="preserve"
  >
    <path
      d="M464.125,69.08l-54.663-54.663c-2.343-2.344-6.143-2.344-8.485,0c-2.343,2.343-2.343,6.142,0,8.484l22.507,22.507
      l-38.701,38.709l-22.506-22.506c-2.342-2.344-6.141-2.344-8.484-0.002L339.73,75.661L215.187,200.205l-33.393-31.011
      c-1.134-1.054-2.571-1.572-4.011-1.59c-6.118-8.068-27.136-35.389-53.786-65.716C81.275,53.273,45.148,20.448,16.62,4.323L8.97,0
      L7.552,9.94c-4.745,33.996,8.139,67.494,34.466,89.608L152.673,192.5c-0.033,1.507,0.497,3.026,1.6,4.214l31.011,33.393
      l-41.124,41.124c-1.125,1.125-1.757,2.651-1.757,4.242c0,1.592,0.632,3.117,1.757,4.243l15.57,15.57l-29.69,29.69
      c-2.343,2.343-2.343,6.142,0,8.484l3.277,3.277L6.329,463.728l8.485,8.484l126.989-126.989l3.278,3.278
      c1.171,1.172,2.707,1.758,4.243,1.758s3.071-0.586,4.243-1.758l29.689-29.689l15.565,15.564c1.171,1.172,2.707,1.758,4.243,1.758
      c1.535,0,3.07-0.585,4.241-1.756l38.79-38.791L396.41,457.448c6.087,6.555,14.365,10.256,23.309,10.421
      c0.208,0.004,0.416,0.006,0.624,0.006c8.71,0,16.876-3.38,23.055-9.559c6.325-6.325,9.718-14.734,9.552-23.678
      c-0.166-8.944-3.866-17.222-10.421-23.309l-161.86-150.315l136.268-136.258c1.125-1.126,1.757-2.651,1.757-4.243
      c0-1.591-0.632-3.117-1.757-4.242l-23.668-23.668l38.701-38.709l23.671,23.671c1.171,1.172,2.707,1.758,4.243,1.758
      s3.071-0.586,4.243-1.758C466.469,75.222,466.469,71.423,464.125,69.08z M343.971,88.39l18.265,18.265l-119.31,119.31l-18.94-17.589
      L343.971,88.39z M18.686,19.609c59.508,38.479,136.559,138.167,149.421,155.099l-8.198,8.198L49.737,90.36
      C28.665,72.661,17.39,46.671,18.686,19.609z M211.045,257.847l-9.814,9.814l-18.265-18.265l10.49-10.49L211.045,257.847z
      M220.657,304.056l-2.766,2.766l-46.175-46.176l2.766-2.766L220.657,304.056z M150.778,334.319l-6.555-6.554l23.992-23.993
      l6.555,6.555L150.778,334.319z M203.064,321.649l-46.176-46.176l6.342-6.342l46.176,46.176L203.064,321.649z M229.141,295.571
      l-19.425-19.426l9.5-9.5l18.707,20.144L229.141,295.571z M440.952,434.86c0.104,5.655-2.041,10.972-6.04,14.972
      c-3.999,3.999-9.305,6.182-14.972,6.039c-5.655-0.104-10.889-2.444-14.737-6.589L167.001,192.785l10.864-10.864l256.498,238.202
      C438.507,423.972,440.847,429.205,440.952,434.86z M251.724,234.136L370.721,115.14l19.43,19.43L271.868,252.843L251.724,234.136z
      M398.636,126.085l-46.178-46.178l5.574-5.57l46.176,46.177L398.636,126.085z"
    />
  </svg>
</template>
<script>
export default {
  name: "SurgeryCenter",
};
</script>
