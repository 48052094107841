<template>
  <section class="cnh-document">
    <FormBase title="CNH" class="form-base-cnh">
      <RgInputCnh
        v-model="form.cnh_numero"
        :rules="{ fn: validateCnh }"
        class="cnh"
        data-id="número-cnh"
        label="Número"
        title="Número da CNH"
      />

      <!-- <div class="attachment">
        <rg-attachment-button
          :class="{ 'disable': disabledAttachment }"
          :disbaled="disabledAttachment"
          @click="clicked"
        />
      </div> -->
    </FormBase>
  </section>
</template>

<script>
import FormBase from "~tokio/foundation/form-base/FormBase";
import { AlertError } from "~tokio/primitive/notification";
import { RgInputCnh } from "~tokio/primitive";

export default {
  name: "CnhDocument",
  components: {
    FormBase,
    RgInputCnh,
  },
  props: {
    cnhData: {
      type: Object,
      default: () => {},
    },
    rules: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      disabledAttachment: true,
      form: {
        cnh_id: 0,
        cnh_numero: "",
      },
    };
  },
  watch: {
    cnhData(pData) {
      if (pData) {
        this.mountCnh(pData);
      }
    },
    "form.cnh_numero"(pValue) {
      pValue && pValue.length >= 12
        ? (this.disabledAttachment = false)
        : (this.disabledAttachment = true);
    },
  },
  methods: {
    mountCnh(pData) {
      this.form.cnh_id = pData.cnh_id || 0;
      this.form.cnh_numero = pData.cnh_numero;
    },

    validateCnh(pValue, pErrors) {
      if (pValue) {
        const isValidCnh =
          this.form.cnh_numero && this.form.cnh_numero.length < 12;
        if (isValidCnh) {
          return AlertError("Informe todos os números da CNH.", pErrors);
        }
      }
      return true;
    },

    getCnhForm() {
      return this.form;
    },

    cleanForm() {
      this.form = {
        cnh_id: 0,
        cnh_numero: "",
      };
    },

    clicked() {
      this.$emit("click");
    },
  },
};
</script>
