<template lang="html">
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 59.198 59.198"
    style="enable-background: new 0 0 59.198 59.198"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M58.486,37.75H0.712C0.319,37.75,0,38.069,0,38.463v3.119c0,0.395,0.319,0.712,0.712,0.712h23.133
			v1.275H7.712C7.319,43.569,7,43.888,7,44.28v4.365c0,0.394,0.318,0.711,0.712,0.711h43.774c0.395,0,0.712-0.317,0.712-0.711V44.28
			c0-0.392-0.317-0.711-0.712-0.711H35.352v-1.275h23.134c0.393,0,0.712-0.317,0.712-0.712v-3.119
			C59.198,38.07,58.879,37.75,58.486,37.75z"
        />
        <circle cx="47.234" cy="14.617" r="4.775" />
        <path
          d="M1.423,36.506h38.555c0.004,0,14.408-13.872,17.328-16.685c0.283-0.272,0.293-0.724,0.02-1.007
			l-1.521-1.581c-0.272-0.283-0.725-0.292-1.006-0.019l-14.82,14.271v-1.031c0-0.295-0.182-0.379-0.406-0.188l-1.941,1.649
			c-0.326,0.278-0.744,0.432-1.176,0.432c-0.535,0-1.039-0.231-1.385-0.641l-4.637-5.458c-0.648-0.764-0.555-1.911,0.207-2.561
			c0.328-0.277,0.746-0.432,1.177-0.432c0.534,0,1.039,0.233,1.386,0.641l3.115,3.667c0.189,0.226,0.527,0.254,0.752,0.062
			l2.502-2.126c0.225-0.191,0.406-0.584,0.406-0.879v-4.131c0-0.294-0.227-0.459-0.508-0.366L9.345,29.991
			c-0.279,0.094-0.507,0.406-0.507,0.701v2.194H1.423c-0.393,0-0.712,0.317-0.712,0.712v2.194
			C0.712,36.187,1.03,36.506,1.423,36.506z"
        />
        <path
          d="M36.334,28.544L32.73,24.3c-0.427-0.502-1.181-0.562-1.683-0.138
			c-0.502,0.427-0.562,1.182-0.139,1.683l4.637,5.458c0.426,0.502,1.182,0.563,1.684,0.138l3.484-2.959v0.84
			c0,0.295,0.17,0.363,0.377,0.154l8.381-8.437c0.209-0.209,0.154-0.469-0.137-0.514c-0.438-0.069-1.229-0.132-2.631-0.102
			c-1.841,0.039-2.953-0.65-3.48-1.104c-0.226-0.191-0.562-0.229-0.791-0.045l-1.303,1.051c-0.229,0.184-0.416,0.573-0.416,0.868
			v4.153l-3.817,3.243C36.727,28.732,36.476,28.712,36.334,28.544z"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "Cti",
};
</script>
