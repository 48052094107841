import SurgeryStatusModel from "$surgery-center/surgery/model/SurgeryStatusModel";
import SurgeryControlModel from "$surgery-center/surgery/model/SurgeryControlModel";
import Store from "@/store";
import moment from "moment";

export default ({ commit }, pCalendar) => {
  const unitHealth = Store.getters["Login/GET_UNIT_HEALTH_ID"];
  if (!pCalendar.sector || (pCalendar.sector && pCalendar.sector.length < 1))
    return false;
  const filter = {
    cci_id_setores: pCalendar.sector,
    uns_id: unitHealth,
    cci_id_status_controles_cirurgicos: [SurgeryStatusModel.SCHEDULED],
    cci_data_inicio: moment(pCalendar.date).format("YYYY-MM-DD"),
  };
  SurgeryControlModel.search(filter).then((res) => {
    commit("CHANGE_SURGERY_CALENDAR", {
      date: pCalendar.date,
      sector: pCalendar.sector,
    });
    commit("LOAD_CALENDAR", res.data);
  });
};
