import { render, staticRenderFns } from "./GenericTypeScale.vue?vue&type=template&id=481ad90c&scoped=true&"
import script from "./GenericTypeScale.vue?vue&type=script&lang=js&"
export * from "./GenericTypeScale.vue?vue&type=script&lang=js&"
import style0 from "./GenericTypeScale.scss?vue&type=style&index=0&id=481ad90c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "481ad90c",
  null
  
)

export default component.exports