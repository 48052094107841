import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import { OpenMenuModule } from "~common/utils/app/menu";

import UnifyPatientSearch from "$person/patient/view/unify-patient-search/UnifyPatientSearch";

export default {
  path: "/person/patient/unify",
  name: "person.patient.unify",
  components: {
    default: UnifyPatientSearch,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Cadastros", link: OpenMenuModule("register") },
      { label: "Unificação de Pacientes", link: "/person/patient/unify" },
    ],
  },
};
