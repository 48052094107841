import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchCid(
    $cid_codigo: String
    $cid_nome: String
    $cid_sexo: String
    $limit: Int
  ) {
    cid(
      cid_codigo: $cid_codigo
      cid_nome: $cid_nome
      cid_sexo: $cid_sexo
      limit: $limit
    ) {
      count
      rows {
        cid_id
        cid_codigo
        cid_nome
        cid_sexo
      }
    }
  }
`;

export default async (variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    return data.cid;
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors);
  }
};
