import LocalStorage from "~common/local-storage/LocalStorage";
const logo = LocalStorage.getObject("client-logo");

const clientNameTitle = (cli_nome) => {
  if (cli_nome) {
    return `<h2>${cli_nome}</h2>`;
  }
  return "";
};

const getMeiaFolha = (title, snap) => {
  return `<div class="meia-folha">
              <div>
                  <h1>${title}</h1>
                  <img class="logo-cliente" src="${logo}">
                  ${clientNameTitle(snap.cli_nome)}
              </div>

              <div class="info-unidade">
                  <h3>${snap.uns_nome}</h3>
                  <p>${snap.tlg_nome} ${snap.end_logradouro} ${snap.end_numero}
                  ${snap.end_complemento} ${snap.bai_nome} - ${
    snap.mun_nome
  }</p>
              </div>

              <hr>

              <div class="info-agendamento">
                  <p class="referencia">${snap.end_referencia}</p>

                  <div><label>Data:</label> <span>${snap.eha_data_exame} às
                  ${snap.eap_hora}</span></div>
                  <div><label>Paciente:</label> <span>${
                    snap.pes_nome
                  }</span></div>
                  <div><label>Nome Social:</label> <span>${
                    snap.pes_nome_social
                  }</span></div>
                  <div><label>Prontuário:</label> <span>${
                    snap.ppr_numero
                  }</span></div>
                  <div><label>Prontuário Único:</label> <span>${
                    snap.pac_prontuario_unico
                  }</span></div>
                  <div><label>Cartão SUS:</label> <span>${
                    snap.crs_numero
                  }</span></div>
                  <div><label>Setor:</label> <span>${snap.set_nome}</span></div>
                  <div><label>Local:</label> <span>${snap.lca_nome}</span></div>
                  <div class="exibir-info"><label>Procedimento:</label> <span>${
                    snap.stp_novo_nome_procedimento
                  }</span></div>
                  <div class="exibir-info"><label>Modo de Preparo:</label> <span>${
                    snap.stp_recomendacao
                  }</span></div>
                  <div class="exibir-info"><label>Sub Procedimento:</label> <span>${
                    snap.subprocedimentos
                  }</span></div>
                  <div><label>Profissional:</label> <span>${
                    snap.atendente
                  }</span></div>
                  <div><label>Ocupação:</label> <span>${
                    snap.ocp_nome
                  }</span></div>
                  <div style="display:${
                    snap.exibir_senha_efetivacao
                  }"><label>Senha Efetivação:</label>
                      <span>${snap.eap_senha_efetivacao}</span></div>

                  <div><label style="width: 200px;">${
                    snap.agendadoPelaFila
                  }</label></div>
                  <div><label>${snap.lblNomeRegulador}</label> <span>${
    snap.nomeRegulador
  }</span></div>

                  <div><label>Observações:</label> <span>${
                    snap.observacoes ? snap.observacoes : "-"
                  }</span></div>
              </div>
          </div>`;
};

const ScheduleVoucherHTML = (pListExams) => {
  let html = ``;
  pListExams.forEach((element) => {
    html += `
  <!DOCTYPE html>
  <html>

  <head>
      <meta charset="utf-8">
      <title>Nova Impressão (Normal) de Agendamento</title>
      <style type="text/css">
          body {
              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
          }

          page {
              background: white;
              display: block;
              margin: 0 auto;
              margin-bottom: 0.5cm;
          }

          page[size="A4"] {
              width: 21cm;
              height: 30.5cm;
          }

          h1 {
              text-align: center;
              text-transform: uppercase;
              font-size: 14pt;
          }

          h2 {
              margin: 33px 0 0 0;
              font-size: 12pt;
          }

          h3 {
              text-transform: uppercase;
              font-size: 11pt;
          }

          hr {
              width: 70%;
              display: block;
              height: 1px;
              border: 0;
              border-top: 1px solid #666;
          }

          p,
          label,
          span {
              font-size: 11pt;
          }

          .meia-folha {
              width: 47%;
              float: left;
              border: 0;
          }

          .meia-folha:first-child {
              padding: 0 45px 0 0;
          }

          .logo-cliente {
              width: 65px;
              margin: 0 20px 0 0;
              float: left;
          }

          .info-unidade {
              margin: 40px 0 22px 0;
          }

          .info-agendamento {
              margin: 20px 0 0 0;
          }

          .info-agendamento div {
              margin: 0 0 6px 0;
          }

          .info-agendamento label {
              width: 135px;
              display: inline-block;
              font-weight: 700;
          }

          .referencia {
              text-transform: uppercase;
              text-align: center;
          }

          .exibir-info {
              display: ${element.exibir_info};
          }

          @media print {

              body,
              page {
                  margin: 0;
                  box-shadow: 0;
              }

          }
      </style>
  </head>

  <body>

      <page size="A4">
          ${getMeiaFolha("Via do paciente", element)}

          ${getMeiaFolha("Via do ambulatório", element)}
      </page>

  </body>

  </html>
    `;
  });
  return html;
};

export default ScheduleVoucherHTML;
