<style src="./RgComboboxExitReason.scss" lang="scss"></style>
<script>
import { RgRemoteComboboxMixin } from "~tokio/primitive";
import SurgeryExitReasonModel from "$surgery-center/surgery/model/SurgeryExitReasonModel";
export default {
  name: "RgComboboxExitReason",
  mixins: [RgRemoteComboboxMixin],
  data: () => {
    return {
      model: SurgeryExitReasonModel,
    };
  },
  methods: {
    formatItem(pData) {
      return {
        value: pData.mos_id,
        text: pData.mos_nome,
      };
    },
  },
};
</script>
