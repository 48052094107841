import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation CreateIntegrations($integration: IntegrationsInputType) {
    CreateIntegrations(integration: $integration) {
      itg_id
      itg_token
    }
  }
`;
export default async ({ commit }, variables) => {
  try {
    const integration = variables;
    variables = { integration };

    const { data } = await GraphQLClient.mutate({
      mutation,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.CreateIntegrations;
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors[0].message);
  }
};
