<script>
import { RgRemoteTypeaheadMixin } from "~tokio/primitive";
import OccupationModel from "$surgery-center/employee/model/OccupationModel";
export default {
  name: "RgTypeheadOccupation",
  mixins: [RgRemoteTypeaheadMixin],
  props: {
    label: {
      default: "Ocupação",
    },
    min: {
      default: "2",
    },
  },
  data() {
    return {
      model: OccupationModel,
      filter: () => {
        return {
          ocp_nome: this.inputValue,
          ocp_codigo: this.inputValue,
        };
      },
    };
  },
  methods: {
    formatItem(item) {
      return {
        value: item.ocp_id,
        text: `${item.ocp_codigo} - ${item.ocp_nome}`,
      };
    },
  },
};
</script>
