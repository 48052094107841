<template lang="html">
  <svg
    id="Layer_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 485.2 485.2"
    style="enable-background: new 0 0 485.2 485.2"
    xml:space="preserve"
  >
    <g>
      <path
        d="M233.3,272c0,18.6-3.9,32.9-11.6,42.7c-7.7,9.9-19,14.8-33.9,14.8c-14.7,0-25.9-4.8-33.7-14.5
		c-7.8-9.7-11.7-23.6-11.9-41.6v-24.8c0-18.8,3.9-33.1,11.7-42.8c7.8-9.7,19-14.6,33.7-14.6c14.7,0,25.9,4.8,33.7,14.5
		c7.8,9.7,11.7,23.5,11.9,41.6V272z M206.6,244.7c0-11.2-1.5-19.3-4.6-24.4c-3.1-5.1-7.8-7.6-14.3-7.6c-6.3,0-10.9,2.4-13.9,7.3
		c-3,4.8-4.6,12.4-4.8,22.7v32.8c0,11,1.5,19.2,4.5,24.5c3,5.3,7.8,8,14.4,8c6.5,0,11.3-2.6,14.2-7.7c2.9-5.1,4.4-13,4.5-23.5V244.7
		z"
      />
      <path d="M342.9,207.9l-52.1,119.8h-28.2l52.2-113.1h-67V193h95.1V207.9z" />
    </g>
    <g>
      <g>
        <path
          d="M106.1,75.8V15.2c0-8.4,6.8-15.2,15.2-15.2c8.4,0,15.2,6.8,15.2,15.2v60.7c0,8.4-6.8,15.2-15.2,15.2
			C112.9,91,106.1,84.2,106.1,75.8z M363.9,91c8.4,0,15.2-6.8,15.2-15.2V15.2c0-8.4-6.8-15.2-15.2-15.2c-8.4,0-15.2,6.8-15.2,15.2
			v60.7C348.7,84.2,355.5,91,363.9,91z"
        />
      </g>
      <path
        d="M406.9,60.7h-12.6v15.2c0,16.7-13.6,30.3-30.3,30.3c-16.7,0-30.3-13.6-30.3-30.3V60.7h-182v15.2
		c0,16.7-13.6,30.3-30.3,30.3c-16.7,0-30.3-13.6-30.3-30.3V60.7H78.3c-18.2,0-32.9,14.9-32.9,33.3v266.9c0,18.4,14.7,33.3,32.9,33.3
		h179.4h49.7h99.4c18.2,0,32.9-15,32.9-33.3V228v-15.7V94C439.7,75.6,425,60.7,406.9,60.7z M409.4,212.3l0,18v131.3
		c0,1.6-1.2,2.4-2.5,2.4H267.4h-9.7H78.3c-1.4,0-2.5-1.4-2.5-3V151.6h333.6V212.3z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: "Forperiod",
};
</script>
