export default {
  SET_COUNT: (state, { count }) => {
    state.count = count;
  },
  SET_LIST: (state, { list }) => {
    state.list = list;
  },
  CLEAN_LIST: (state) => {
    state.list = null;
  },
  CLEAN_COUNT: (state) => {
    state.count = 0;
  },
  RESET_STORE: (state) => {
    state.list = null;
    state.count = 0;
  },
};
