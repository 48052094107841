import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query($unitHealth: Int, $hospitalizationNumber: Int) {
    hospitalizations(uns_id: $unitHealth, int_numero: $hospitalizationNumber) {
      rows {
        int_id
        int_numero
        patient {
          pac_id
          pac_prontuario_unico
          person {
            pes_id
            pes_nome
            gender {
              sex_id
              sex_sigla
            }
          }
        }
      }
    }
  }
`;

export default async (variables) => {
  try {
    const { data } = await GraphQLClient.query({ query, variables });
    return data.hospitalizations.rows;
  } catch (err) {
    const jsonResponse = JSON.parse(JSON.stringify(err));
    return Promise.reject(jsonResponse.graphQLErrors[0].message);
  }
};
