<template>
  <Modulebox
    :title="isEdition ? 'Editar Unidade' : 'Cadastrar Unidade'"
    class="unit-health-register"
  >
    <div class="body">
      <form class="form-container" @submit.stop.prevent="saveUnitHealth">
        <FormBase title="Unidade de Saúde" class="form-base">
          <RgValidatorForm ref="validatorUnit">
            <div class="grid-unit">
              <Tooltip class="tooltip">
                <IconExclamationHelper slot="icon" />
                <div slot="content" class="content">
                  <span class="title">
                    Para guardar as alterações da seção é necessário:
                  </span>

                  <ul class="list">
                    <li class="item">
                      Apertar o botão salvar no final da página;
                    </li>
                    <li class="item">
                      Ao cancelar a edição/cadastro, o sistema volta para a
                      lista de unidades.
                    </li>
                  </ul>
                </div>
              </Tooltip>
              <div class="second-line grid-unit">
                <RgInput
                  ref="unitName"
                  v-model="unit.name"
                  :rules="{ required: true }"
                  :maxlength="100"
                  label="Nome"
                  placeholder="Digite o nome da unidade"
                />
                <RgInput
                  ref="cnes"
                  v-model="unit.cnes"
                  v-mask="'#######'"
                  :rules="{ required: true, min: 7 }"
                  :maxlength="7"
                  label="CNES"
                  placeholder="0000000"
                />
                <RgInput
                  v-model="unit.issuingBody"
                  :maxlength="15"
                  label="Órgão Emissor"
                  placeholder="Digite o nome do órgão emissor"
                />
                <RgComboboxUnitType
                  ref="unitType"
                  v-model="unit.type"
                  :rules="{ required: true }"
                  label="Tipos de Unidade"
                />
                <RgInputCheckbox v-model="unit.category" label="Terceirizada" />
              </div>

              <div class="third-line grid-unit">
                <RgInput
                  v-model="unit.corporateName"
                  :maxlength="75"
                  label="Razão Social"
                  placeholder="Digite o nome de registro da unidade"
                />
                <RgInputCnpj
                  v-model="unit.cnpj"
                  label="CNPJ"
                  placeholder="00.000.000/0001-00"
                />
                <RgInput
                  v-model="unit.notificationName"
                  :maxlength="45"
                  label="Nome para Notificações"
                  placeholder="Digite o nome para notificações SMS"
                />
                <RgSuggestEmployee
                  ref="clinicalDirector"
                  v-model="unit.clinicalDirector"
                  label="Diretor Clínico"
                  placeholder="Digite e selecione o nome do profissional"
                  notNecessaryBond
                  :filterByActiveOrInactive="false"
                  @selected="selectingClinicalDirector"
                />
              </div>

              <div class="form-separator" />
              <div class="sub-title">Endereço</div>

              <div class="fourth-line grid-unit">
                <RgInputCep
                  ref="cep"
                  v-model="address.cep"
                  :rules="{ required: true, fn: validateCep }"
                  label="CEP"
                  placeholder="00.000-000"
                  @blur="fillAddress"
                />
                <RgSuggestPlace
                  ref="place"
                  v-model="address.publicPlace"
                  :disabled="disablePublicPlace || isEdition"
                  :class="{ disable: disablePublicPlace || isEdition }"
                  label="Logradouro"
                  placeholder="Digite e selecione o tipo"
                  @selected="selectingPlace"
                />
                <RgInput
                  ref="address"
                  v-model="address.name"
                  :rules="{ required: isThereCEP }"
                  :disabled="disableAddress || isEdition"
                  :class="{ disable: disableAddress || isEdition }"
                  :maxlength="100"
                  label="Endereço"
                  placeholder="Digite o nome do logradouro"
                />
                <RgInput
                  ref="neighborhood"
                  v-model="address.neighborhood"
                  :rules="{ required: isThereCEP }"
                  :disabled="disableNeighborhood || isEdition"
                  :class="{ disable: disableNeighborhood || isEdition }"
                  :maxlength="100"
                  label="Bairro"
                  placeholder="Digite o nome do bairro"
                />
              </div>

              <div class="fifth-line grid-unit">
                <RgSuggestCity
                  ref="city"
                  v-model="address.city"
                  :rules="{ required: isThereCEP }"
                  :disabled="disableCity || isEdition"
                  :class="{ disable: disableCity || isEdition }"
                  label="Cidade"
                  placeholder="Digite e selecione o nome da cidade"
                  @selected="selectingCity"
                />
                <RgSuggestState
                  ref="state"
                  v-model="address.state"
                  :rules="{ required: isThereCEP }"
                  :disabled="disableState || isEdition"
                  :class="{ disable: disableState || isEdition }"
                  label="UF"
                  placeholder="Selecione"
                  @selected="selectingUf"
                />
                <RgInput
                  ref="number"
                  v-model="address.number"
                  :rules="{ required: true, addressNumber: true }"
                  :disabled="!isThereCEP"
                  :class="{ disable: !isThereCEP }"
                  :maxlength="10"
                  label="Número"
                  placeholder="000000"
                />
                <RgInput
                  v-model="address.complement"
                  :disabled="!isThereCEP"
                  :class="{ disable: !isThereCEP }"
                  :maxlength="30"
                  label="Complemento"
                  placeholder="Ex.: casa/apto./ 000"
                />
                <RgInput
                  v-model="address.reference"
                  :disabled="!isThereCEP"
                  :class="{ disable: !isThereCEP }"
                  :maxlength="75"
                  label="Referência"
                  placeholder="Ex.: Comércio ou praça próximos ao domicílio"
                />
              </div>
            </div>
          </RgValidatorForm>
        </FormBase>

        <FormBase title="Setor" class="form-base">
          <RgValidatorForm ref="validatorSector">
            <div class="grid-sector">
              <Tooltip class="tooltip">
                <IconLightBulbHelper slot="icon" />
                <div slot="content" class="content">
                  <span class="title">
                    Os campos das seções são independentes:
                  </span>

                  <ul class="list">
                    <li class="item">
                      As alterações salvam automaticamente ao adicionar/editar;
                    </li>
                  </ul>
                </div>
              </Tooltip>
              <div class="second-line grid-sector">
                <RgInput
                  ref="sectorName"
                  v-model="sector.name"
                  :rules="{ required: true }"
                  :disabled="!hasUnitHealthId"
                  :class="{ disable: !hasUnitHealthId }"
                  :maxlength="75"
                  label="Nome"
                  placeholder="Digite o nome do setor"
                />
                <RgInput
                  v-model="sector.responsible"
                  :disabled="!hasUnitHealthId"
                  :class="{ disable: !hasUnitHealthId }"
                  :maxlength="70"
                  label="Responsável"
                  placeholder="Digite o nome do responsável"
                />
                <RgInputPhone
                  v-model="sector.telephone"
                  :disabled="!hasUnitHealthId"
                  :class="{ disable: !hasUnitHealthId }"
                  label="Telefone"
                  placeholder="(00) 0000-0000"
                />
                <div class="actions">
                  <RgCleanButton
                    v-if="!isEditionSector"
                    :disabled="!hasUnitHealthId"
                    @click="cleanSector"
                  />
                  <RgAddButton
                    v-if="!isEditionSector"
                    large
                    :disabled="!hasUnitHealthId"
                    @click="saveSector"
                  />
                  <RgCancelButton
                    v-if="isEditionSector"
                    medium
                    :disabled="!hasUnitHealthId"
                    @click="cancelEditSector"
                  />
                  <RgSaveButton
                    v-if="isEditionSector"
                    medium
                    :disabled="!hasUnitHealthId"
                    @click="saveEditSector"
                  />
                </div>
              </div>

              <div class="form-separator" />
              <div class="sub-title">Setores Cadastrados</div>

              <div
                :disabled="isEditionSector"
                :class="{ disable: isEditionSector }"
                class="sector-list"
              >
                <SmartTable
                  ref="sectorTable"
                  name="UnitHealthRegisterSector"
                  :body="mutableSectorList"
                  :columns="COLUMN_SECTOR"
                  :initial-columns="3"
                  :max-register="10"
                  :total="mutableSectorList.length"
                  :showPagination="false"
                  toggle-selected
                  remove-btn-columns
                  @getLine="getValueRowSector"
                >
                  <div slot="top-buttons" class="top-buttons">
                    <RgEditButton
                      :disabled="!selectedSector || isEditionSector"
                      @click="editSector"
                    />
                    <RgLessButton
                      :disabled="!selectedSector || isEditionSector"
                      @click="openModalRemoveSector"
                    />
                  </div>
                </SmartTable>
              </div>
            </div>
          </RgValidatorForm>
        </FormBase>

        <FormBase title="Local de Atendimento" class="form-base">
          <RgValidatorForm ref="validatorPlace">
            <div class="grid-place">
              <Tooltip class="tooltip">
                <IconLightBulbHelper slot="icon" />
                <div slot="content" class="content">
                  <span class="title">
                    Os campos das seções são independentes:
                  </span>

                  <ul class="list">
                    <li class="item">
                      As alterações salvam automaticamente ao adicionar/editar;
                    </li>
                  </ul>
                </div>
              </Tooltip>
              <div class="second-line grid-place">
                <RgComboboxSector
                  ref="placeSector"
                  v-model="place.sector"
                  :unit-health="Number(unitHealthId)"
                  :rules="{ required: true }"
                  :disabled="!hasUnitHealthId || isEditionPlace"
                  :class="{ disable: !hasUnitHealthId || isEditionPlace }"
                  label="Setor"
                  placeholder="selecione"
                />
                <RgInput
                  ref="placeName"
                  v-model="place.name"
                  :rules="{ required: true }"
                  :disabled="!hasUnitHealthId"
                  :class="{ disable: !hasUnitHealthId }"
                  :maxlength="45"
                  label="Nome"
                  placeholder="Digite o nome do local de atendimento"
                />
              </div>

              <div class="third-line grid-place">
                <RgComboboxUnitHealthPep
                  v-model="place.unitHealthPEP"
                  :disabled="!hasUnitHealthId"
                  :class="{ disable: !hasUnitHealthId }"
                  label="Unidade de Saúde PEP"
                  default-text="Não informado"
                  @change="changeUnitHealthPep"
                />
                <RgComboboxSectorPep
                  v-model="place.sectorPEP"
                  :unit-health-code="unitHealthCodePEP"
                  :disabled="!place.unitHealthPEP"
                  :class="{ disable: !place.unitHealthPEP }"
                  label="Setor PEP"
                  default-text="Não informado"
                  @change="changeSectorPep"
                />
                <RgComboboxPlacePep
                  v-model="place.placePEP"
                  :sector-code="sectorCodePep"
                  :disabled="!place.sectorPEP"
                  :class="{ disable: !place.sectorPEP }"
                  label="Local de Atendimento PEP"
                  default-text="Não informado"
                />
                <div class="actions">
                  <RgCleanButton
                    v-if="!isEditionPlace"
                    :disabled="!hasUnitHealthId"
                    @click="cleanPlace"
                  />
                  <RgAddButton
                    v-if="!isEditionPlace"
                    large
                    :disabled="!hasUnitHealthId"
                    @click="savePlace"
                  />
                  <RgCancelButton
                    v-if="isEditionPlace"
                    medium
                    :disabled="!hasUnitHealthId"
                    @click="cancelEditPlace"
                  />
                  <RgSaveButton
                    v-if="isEditionPlace"
                    medium
                    :disabled="!hasUnitHealthId"
                    @click="saveEditPlace"
                  />
                </div>
              </div>

              <div class="form-separator" />
              <div class="sub-title">Locais Cadastrados</div>

              <div
                :disabled="isEditionPlace"
                :class="{ disable: isEditionPlace }"
                class="place-list"
              >
                <SmartTable
                  ref="placeTable"
                  name="UnitHealthRegisterLocale"
                  :body="mutablePlaceList"
                  :columns="COLUMN_PLACE"
                  :initial-columns="5"
                  :max-register="10"
                  :total="mutablePlaceList.length"
                  :showPagination="false"
                  toggle-selected
                  remove-btn-columns
                  @getLine="getValueRowPlace"
                >
                  <div slot="top-buttons" class="top-buttons">
                    <RgEditButton
                      :disabled="!selectedPlace || isEditionPlace"
                      @click="editPlace"
                    />
                    <RgLessButton
                      :disabled="!selectedPlace || isEditionPlace"
                      @click="openModalRemovePlace"
                    />
                  </div>
                </SmartTable>
              </div>
            </div>
          </RgValidatorForm>
        </FormBase>
      </form>
    </div>

    <div slot="footer" class="footer">
      <RgCleanButton v-if="!hideCleanButton" @click="openModalCleanUnit" />
      <RgCancelButton medium @click="cancel" />
      <RgSaveButton medium @click="editOrSaveUnitHealth" />
    </div>

    <ModalConfirmDeletion
      v-bind="propsModalDeletionSector"
      @close="closeModalRemoveSector"
    >
      <div slot="icon" class="icon">
        <IconError />
      </div>
    </ModalConfirmDeletion>

    <ModalConfirmDeletion
      v-bind="propsModalDeletionPlace"
      @close="closeModalRemovePlace"
    >
      <div slot="icon" class="icon">
        <IconError />
      </div>
    </ModalConfirmDeletion>

    <ModalConfirmFieldClearing
      :show="showModalCleanUnit"
      @getYes="cleanUnit"
      @getOut="closeModalCleanUnit"
    />
  </Modulebox>
</template>

<script>
import { mask } from "vue-the-mask";
import { isEmpty } from "lodash";
import { Modulebox, SmartTable, FormBase } from "~tokio/foundation";

import CheckCEP from "$billing/billing/action/CheckCEP";

import RgInputCep from "$person/common/components/input/rg-input-cep/RgInputCep";
import RgSuggestPlace from "$person/common/components/suggest/rg-suggest-place/RgSuggestPlace";
import RgSuggestCity from "$person/common/components/suggest/rg-suggest-city/RgSuggestCity";
import RgSuggestState from "$person/common/components/suggest/rg-suggest-state/RgSuggestState";
import RgComboboxSector from "~tokio/primitive/combobox/rg-combobox-sector/RgComboboxSector";

import RgComboboxUnitType from "$unit-health/common/components/combobox/rg-combobox-unit-type/RgComboboxUnitType";
import RgComboboxUnitHealthPep from "$unit-health/common/combobox/rg-combobox-unit-health-pep/RgComboboxUnitHealthPep";
import RgComboboxSectorPep from "$unit-health/common/combobox/rg-combobox-sector-pep/RgComboboxSectorPep";
import RgComboboxPlacePep from "$unit-health/common/combobox/rg-combobox-place-pep/RgComboboxPlacePep";

import {
  RgValidatorForm,
  RgInput,
  RgInputCheckbox,
  RgInputCnpj,
  RgInputPhone,
  RgSuggestEmployee,
  RgCancelButton,
  RgSaveButton,
  RgCleanButton,
  RgAddButton,
  RgEditButton,
  RgLessButton,
  Tooltip,
  IconLightBulbHelper,
  IconExclamationHelper,
  IconError,
  ModalConfirmFieldClearing,
  ModalConfirmDeletion,
} from "~tokio/primitive";

const FORM_UNIT = {
  name: "",
  cnes: "",
  issuingBody: "",
  type: null,
  corporateName: "",
  cnpj: "",
  notificationName: "",
  category: false,
  clinicalDirector: "",
  clinicalDirectorId: null,
};

const FORM_ADDRESS = {
  cep: "",
  publicPlaceId: "",
  publicPlace: "",
  name: "",
  neighborhood: "",
  cityId: null,
  city: "",
  stateId: null,
  state: "",
  number: "",
  complement: "",
  reference: "",
};

const FORM_SECTOR = {
  name: "",
  responsible: "",
  telephone: "",
};

const FORM_PLACE = {
  sector: null,
  name: "",
  unitHealthPEP: null,
  sectorPEP: null,
  placePEP: null,
};

export default {
  name: "UnitHealthRegister",
  components: {
    Modulebox,
    FormBase,
    RgValidatorForm,
    SmartTable,
    Tooltip,
    RgInput,
    RgInputCheckbox,
    RgInputCnpj,
    RgInputCep,
    RgInputPhone,
    RgComboboxSector,
    RgComboboxUnitType,
    RgComboboxUnitHealthPep,
    RgComboboxSectorPep,
    RgComboboxPlacePep,
    RgSuggestEmployee,
    RgSuggestPlace,
    RgSuggestCity,
    RgSuggestState,
    RgCancelButton,
    RgSaveButton,
    RgCleanButton,
    RgAddButton,
    RgEditButton,
    RgLessButton,
    IconLightBulbHelper,
    IconExclamationHelper,
    IconError,
    ModalConfirmFieldClearing,
    ModalConfirmDeletion,
  },
  directives: {
    mask,
  },

  props: {},

  data() {
    return {
      unit: this.$utils.obj.DeepCopy(FORM_UNIT),
      address: this.$utils.obj.DeepCopy(FORM_ADDRESS),
      sector: this.$utils.obj.DeepCopy(FORM_SECTOR),
      place: this.$utils.obj.DeepCopy(FORM_PLACE),
      mutableSectorList: [],
      mutablePlaceList: [],
      selectedSector: null,
      selectedPlace: null,
      unitHealthId: null,
      sectorId: null,
      unitHealthData: {},
      sectorCodePep: null,
      unitHealthCodePEP: null,
      typeAttendance: null,
      hasPublicPlace: false,
      hasCity: false,
      hasState: false,
      addressId: null,
      isEditionUnitHealth: false,
      isEditionSector: false,
      isEditionPlace: false,
      showModalCleanUnit: false,
      showModalRemoveSector: false,
      showModalRemovePlace: false,
    };
  },

  computed: {
    isEdition() {
      return this.$route.name === "unit-health.register.edit";
    },

    hasUnitHealthId() {
      return this.unitHealthId && this.unitHealthId > 0;
    },

    isThereCEP() {
      return this.address?.cep?.length >= 9;
    },

    disablePublicPlace() {
      if (!this.isThereCEP || this.address.publicPlace) {
        return true;
      }
      return false;
    },

    disableAddress() {
      if (!this.isThereCEP) {
        return true;
      }
      if (this.isThereCEP && !this.hasPublicPlace) {
        return false;
      }
      return true;
    },

    disableNeighborhood() {
      if (!this.isThereCEP) {
        return true;
      }
      if (this.isThereCEP && !this.hasPublicPlace) {
        return false;
      }
      return true;
    },

    disableCity() {
      if (!this.isThereCEP || this.hasCity) {
        return true;
      }
      return false;
    },

    disableState() {
      if (!this.isThereCEP || this.hasState) {
        return true;
      }
      return false;
    },

    disableCepFields() {
      if (!this.isThereCEP) {
        return true;
      } else {
        return this.hasPublicPlace;
      }
    },

    hideCleanButton() {
      return this.isEdition || this.isEditionUnitHealth;
    },

    propsModalDeletionSector() {
      const show = this.showModalRemoveSector;
      const noReason = true;
      const title = "Excluir Setor";
      const message = "A operação não poderá ser desfeita.";
      const msgSuccess = "Setor excluído com sucesso";
      const btnRemoveTitle = "Excluir";
      const confirm = () => this.removeSector();

      return {
        show,
        noReason,
        confirm,
        title,
        message,
        msgSuccess,
        btnRemoveTitle,
      };
    },

    propsModalDeletionPlace() {
      const show = this.showModalRemovePlace;
      const noReason = true;
      const title = "Excluir Local de Atendimento";
      const message = "A operação não poderá ser desfeita.";
      const msgSuccess = "Local de Atendimento excluído com sucesso";
      const btnRemoveTitle = "Excluir";
      const confirm = () => this.removePlace();

      return {
        show,
        noReason,
        confirm,
        title,
        message,
        msgSuccess,
        btnRemoveTitle,
      };
    },
  },

  watch: {
    "place.sector"(pValue, pPrev) {
      if (pValue || pValue !== pPrev) {
        this.sectorId = pValue;
      }
    },
  },

  destroyed() {},

  created() {
    this.COLUMN_SECTOR = [
      { name: "Nome", key: "set_nome" },
      { name: "Profissional", key: "set_responsavel" },
      // { name: "Telefone", key: "" }, // :TODO: A ROTA DE BUSCA NÃO RETORNA ESSA INFORMAÇÃO
    ];
    this.COLUMN_PLACE = [
      { name: "Setor", key: "set_nome" },
      { name: "Local de Atendimento", key: "lca_nome" },
      { name: "Mapeado com PEP", key: "mapeado_pep" },
    ];
  },

  async mounted() {
    this.unitHealthData = this.$store.getters[
      "UnitHealth/GET_SELECTED_UNIT_HEALTH"
    ];

    if (this.isEdition && this.unitHealthData) {
      this.unitHealthId = this.unitHealthData.uns_id;

      this.fillUnitHealth(this.unitHealthData);
      this.searchSector(this.unitHealthId);
      this.searchPlace();
    }
  },

  methods: {
    async formUnitHealthValid() {
      return this.$refs.validatorUnit
        ? this.$refs.validatorUnit.validate()
        : false;
    },

    async formSectorValid() {
      return this.$refs.validatorSector
        ? this.$refs.validatorSector.validate()
        : false;
    },

    async formPlaceValid() {
      return this.$refs.validatorPlace
        ? this.$refs.validatorPlace.validate()
        : false;
    },

    parseUnitHealthData() {
      const variables = {
        arrFormData: {
          uns_id_funcionarios: Number(this.unit.clinicalDirectorId),
          uns_cnes: this.unit.cnes,
          uns_cnpj: this.unit.cnpj.replace(/[^0-9]/gi, ""),
          uns_id_tipos_unidades_saude: Number(this.unit.type),
          uns_nome: this.unit.name,
          uns_orgao_emissor: this.unit.issuingBody,
          uns_razao_social: this.unit.corporateName,
          bai_id_municipios: Number(this.address.cityId),
          bai_nome: this.address.neighborhood,
          end_cep: this.address.cep.replace(/[^0-9]/gi, ""),
          end_complemento: this.address.complement,
          end_id_tipos_logradouros: Number(this.address.publicPlaceId),
          end_logradouro: this.address.name,
          end_numero: this.address.number,
          end_referencia: this.address.reference,
          est_id: Number(this.address.stateId),
          sub_nome: "",
          uns_id_categoria_unidades_saude: Number(this.unit.category),
          uns_nome_curto: this.unit.notificationName,
          exc_id: 0,
          uns_id_enderecos:
            this.isEdition || this.isEditionUnitHealth
              ? Number(this.addressId)
              : 0,
          end_id:
            this.isEdition || this.isEditionUnitHealth
              ? Number(this.addressId)
              : 0,
          uns_id:
            this.isEdition || this.isEditionUnitHealth
              ? Number(this.unitHealthId)
              : 0,
        },
      };

      return variables;
    },

    async saveUnitHealth() {
      try {
        if (!(await this.formUnitHealthValid())) {
          this.$toaster.warning(
            "Verifique os campos referentes ao formulário da unidade de saúde",
          );
          return false;
        }

        const variables = this.parseUnitHealthData();
        const response = await this.$store.dispatch(
          "UnitHealth/REGISTER_UNIT_HEALTH",
          {
            ...variables,
          },
        );

        if (response.dados) {
          this.isEditionUnitHealth = true;
          this.unitHealthId = response.dados;
          this.fillUnitHealth(this.unitHealthId);
          this.searchSector(this.unitHealthId);
          this.searchPlace();
        }
        this.$toaster.success("Unidade de saúde cadastrada com sucesso");
      } catch (pErr) {
        this.handleError(pErr, "Erro ao cadastrar unidade de saúde");
      }
    },

    async editUnitHealth() {
      try {
        if (!(await this.formUnitHealthValid())) {
          this.$toaster.warning(
            "Verifique os campos referentes ao formulário da unidade de saúde",
          );
          return false;
        }

        const variables = this.parseUnitHealthData();
        await this.$store.dispatch("UnitHealth/EDIT_UNIT_HEALTH", {
          ...variables,
        });

        this.$toaster.success("Unidade de saúde alterada com sucesso");
      } catch (pErr) {
        this.handleError(pErr, "Erro ao alterar unidade de saúde");
      }
    },

    async loadUnitHealth() {
      return await this.$store.dispatch("UnitHealth/LOAD_UNIT_HEALTH", {
        intIdUnidade: Number(this.unitHealthId),
      });
    },

    async fillUnitHealth(pUnit) {
      try {
        this.$loader.start("Carregando informações da unidade de saúde...");

        const data = await this.loadUnitHealth();

        if (pUnit) {
          this.unitHealthId = Number(data.uns_id);
          this.unit.name = data.uns_nome;
          this.unit.cnes = data.uns_cnes;
          this.unit.issuingBody = data.uns_orgao_emissor;
          this.unit.type = Number(data.uns_id_tipos_unidades_saude);
          this.unit.category = Boolean(data.uns_id_categoria_unidades_saude);
          this.unit.corporateName = data.uns_razao_social;
          this.unit.cnpj = data.uns_cnpj;
          this.unit.notificationName = data.uns_nome_curto;

          this.addressId = Number(data.end_id);
          this.address.cep = data.end_cep;
          this.fillAddress(this.address.cep);

          if (this.$refs.clinicalDirector) {
            this.$refs.clinicalDirector.forceSelection({
              pes_nome: data.pes_nome,
              pes_id: data.pes_id,
            });
          }

          if (this.$refs.place) {
            this.$refs.place.forceSelection({
              tlg_id: Number(data.end_id_tipos_logradouros),
              tlg_nome: data.tlg_nome,
            });
          }

          if (this.$refs.city) {
            this.$refs.city.forceSelection({
              mun_id: data.mun_id,
              mun_nome: data.mun_nome,
            });
          }

          if (this.$refs.state) {
            this.$refs.state.forceSelection({
              est_id: data.est_id,
              est_sigla: data.est_sigla,
            });
          }

          this.address.name = data.end_logradouro;
          this.address.neighborhood = data.bai_nome;
          this.address.number = data.end_numero;
          this.address.complement = data.end_complemento;
          this.address.reference = data.end_referencia;
        }
      } catch (pErr) {
        this.$toaster.error(pErr);
      } finally {
        this.$loader.finish(1000);
      }
    },

    async searchSector(pUnitId) {
      try {
        const variables = {
          arrFiltros: {
            uns_id: pUnitId,
            smd_id: 158,
          },
        };

        const result = await this.$store.dispatch("UnitHealth/SEARCH_SECTOR", {
          ...variables,
        });

        this.mutableSectorList = result.recordSet;
      } catch (pErr) {
        this.handleError(pErr, "Erro ao buscar setor");
      }
    },

    parseSectorData() {
      let formatNumber = "";
      let listaTelefones = {};

      if (this.sector.telephone?.length > 0) {
        formatNumber = this.sector.telephone.split(")");
      }

      if (formatNumber.length > 0) {
        listaTelefones = [
          {
            ddd:
              formatNumber.length > 0
                ? formatNumber[0].replace(/[^0-9]/gi, "")
                : null,
            numero:
              formatNumber.length > 0
                ? formatNumber[1].replace(/[^0-9]/gi, "")
                : null,
            ramal: "",
            recado: "",
          },
        ];
      } else {
        listaTelefones = {};
      }

      const variables = {
        arrFormData: {
          set_id: this.isEditionSector ? Number(this.selectedSector.set_id) : 0,
          set_id_unidades_saude: Number(this.unitHealthId),
          set_ativo: true,
          set_nome: this.sector.name,
          set_responsavel: this.sector.responsible,
          listaTelefones,
        },
      };

      return variables;
    },

    async saveSector() {
      try {
        if (!(await this.formSectorValid())) {
          this.$toaster.warning(
            "Verifique os campos referentes ao formulário do setor",
          );
          return false;
        }

        const variables = this.parseSectorData();
        await this.$store.dispatch("UnitHealth/REGISTER_SECTOR", {
          ...variables,
        });

        this.$toaster.success("Setor cadastrado com sucesso");

        this.cleanSector();
        this.searchSector(this.unitHealthId);
        this.$refs.placeSector.reSearchSector();
        this.searchPlace();
      } catch (pErr) {
        this.handleError(pErr, "Erro ao cadastrar setor");
      }
    },

    async editSector() {
      const data = await this.loadSector();

      const formatNumber =
        data.listaTelefones.length > 0
          ? data.listaTelefones[0].ddd + data.listaTelefones[0].numero
          : "";

      this.isEditionSector = true;
      this.sector = {
        name: data.set_nome,
        responsible: data.set_responsavel,
        telephone: formatNumber || "",
      };
    },

    async saveEditSector() {
      try {
        if (!(await this.formSectorValid())) {
          this.$toaster.warning(
            "Verifique os campos referentes ao formulário da unidade de saúde",
          );
          return false;
        }

        const variables = this.parseSectorData();
        await this.$store.dispatch("UnitHealth/EDIT_SECTOR", {
          ...variables,
        });

        this.$toaster.success("Setor alterado com sucesso");

        this.cleanSector();
        this.searchSector(this.unitHealthId);
        this.$refs.placeSector.reSearchSector();
        this.searchPlace();
      } catch (pErr) {
        this.handleError(pErr, "Erro ao alterar setor");
      }
    },

    async removeSector() {
      try {
        await this.$store.dispatch("UnitHealth/REMOVE_SECTOR", {
          intIdSetor: Number(this.selectedSector.set_id),
        });

        this.$toaster.success("Setor excluído com sucesso");

        this.cleanSector();
        this.searchSector(this.unitHealthId);
        this.$refs.placeSector.reSearchSector();
        this.searchPlace();
      } catch (pErr) {
        this.handleError(pErr, "Erro ao excluir setor");
      }
    },

    async loadSector() {
      if (this.sectorId > 0) {
        const result = await this.$store.dispatch("UnitHealth/LOAD_SECTOR", {
          intIdSetor: Number(this.sectorId),
        });
        return result;
      }
    },

    async getValueRowSector(pSector) {
      this.selectedSector = pSector;
      this.sectorId = pSector.set_id;
    },

    async searchPlace() {
      try {
        const variables = {
          arrFiltros: {
            smd_id: 157,
            uns_id: Number(this.unitHealthId),
            set_id: null,
          },
        };

        const result = await this.$store.dispatch("UnitHealth/SEARCH_PLACE", {
          ...variables,
        });

        if (result?.recordSet.length > 0) {
          this.mutablePlaceList = result.recordSet;
        } else {
          this.mutablePlaceList = [];
        }
      } catch (pErr) {
        this.handleError(pErr, "Erro ao buscar local de atendimento");
      }
    },

    parsePlaceData() {
      const variables = {
        arrFormData: {
          lca_ativo: 1,
          lca_id: this.isEditionPlace ? this.selectedPlace.lca_id : 0,
          lca_id_setores: this.place.sector,
          lca_nome: this.place.name,
        },
      };
      return variables;
    },

    async savePlace() {
      try {
        if (!(await this.formPlaceValid())) {
          this.$toaster.warning(
            "Verifique os campos referentes ao formulário do local de atendimento",
          );
          return false;
        }

        const variables = this.parsePlaceData();
        const response = await this.$store.dispatch(
          "UnitHealth/REGISTER_PLACE",
          {
            ...variables,
          },
        );

        const placeId = response.dados;

        const hasInformations =
          this.place.unitHealthPEP !== null &&
          this.place.sectorPEP !== null &&
          this.place.placePEP !== null;

        if (placeId && hasInformations) {
          await this.mapPlacePep(placeId);
        }

        this.$toaster.success("Local de atendimento cadastrado com sucesso");
        this.cleanPlace();
        this.searchPlace();
      } catch (pErr) {
        this.handleError(pErr, "Erro ao cadastrar local de atendimento");
      }
    },

    async editPlace() {
      const data = await this.loadPlace();

      this.isEditionPlace = true;

      this.place = {
        sector: Number(data.set_id),
        name: data.lca_nome,
        unitHealthPEP: data.pel_codigo_unidades_saude || null,
        sectorPEP: data.pel_codigo_setores || null,
        placePEP: data.pel_codigo_locais_atendimento || null,
      };
    },

    async saveEditPlace() {
      try {
        if (!(await this.formPlaceValid())) {
          this.$toaster.warning(
            "Verifique os campos referentes ao formulário do local de atendimento",
          );
          return false;
        }

        const variables = this.parsePlaceData();
        const response = await this.$store.dispatch("UnitHealth/EDIT_PLACE", {
          ...variables,
        });

        const placeId = response.dados;

        const hasInformations =
          this.place.unitHealthPEP &&
          this.place.sectorPEP &&
          this.place.placePEP;

        if (placeId && hasInformations) {
          await this.mapPlacePep(this.selectedPlace.lca_id);
        }

        this.$toaster.success("Local de atendimento alterado com sucesso");
        this.cleanPlace();
        this.searchPlace();
      } catch (pErr) {
        this.handleError(pErr, "Erro ao alterar local de atendimento");
      }
    },

    async removePlace() {
      try {
        await this.$store.dispatch("UnitHealth/REMOVE_PLACE", {
          intIdLocalAtendimento: Number(this.selectedPlace.lca_id),
        });

        this.$toaster.success("Local de atendimento excluído com sucesso");
        this.cleanPlace();
        this.searchPlace();
      } catch (pErr) {
        this.handleError(pErr, "Erro ao excluir local de atendimento");
      }
    },

    async loadPlace() {
      const result = await this.$store.dispatch("UnitHealth/LOAD_PLACE", {
        intIdLocalAtendimento: Number(this.selectedPlace.lca_id),
      });

      this.typeAttendance = result.set_tipos_atendimento_pep;
      this.unitHealthCodePEP = result.pel_codigo_unidades_saude;
      this.sectorCodePep = result.pel_codigo_setores;

      return result;
    },

    async mapPlacePep(pPlaceId) {
      try {
        const variables = {
          uns_id: this.unitHealthId,
          set_id: this.sectorId,
          lca_id: Number(pPlaceId),
          uns_codigo_pep: this.place.unitHealthPEP,
          set_codigo_pep: this.place.sectorPEP,
          lca_codigo_pep: this.place.placePEP,
          set_tipo_setores_pep: this.typeAttendance,
        };

        await this.$store.dispatch("UnitHealth/MAP_PLACE_PEP", {
          ...variables,
        });

        this.$toaster.success("Local de atendimento mapeado com sucesso");
      } catch (pErr) {
        this.handleError(pErr, "Erro ao mapear local de atendimento");
      }
    },

    getValueRowPlace(pPlace) {
      this.selectedPlace = pPlace;
    },

    editOrSaveUnitHealth() {
      this.isEdition || this.isEditionUnitHealth
        ? this.editUnitHealth()
        : this.saveUnitHealth();
    },

    changeUnitHealthPep(pUnitHealth) {
      this.unitHealthCodePEP =
        pUnitHealth && pUnitHealth[0] ? pUnitHealth[0].value : null;

      this.place.sectorPEP = null;
      this.sectorCodePep = null;
      this.place.placePEP = null;
      this.typeAttendance = null;
    },

    changeSectorPep(pSector) {
      if (pSector && pSector[0]) {
        this.typeAttendance = pSector[0].type;
        this.sectorCodePep = pSector[0].value;
      } else {
        this.typeAttendance = null;
        this.sectorCodePep = null;
        this.place.placePEP = null;
      }
    },

    selectingClinicalDirector(pValue) {
      if (pValue) {
        const source = pValue && pValue.source;
        this.unit.clinicalDirectorId =
          source?.employeeRelationship?.vin_id_funcionarios;
      }
    },

    selectingPlace(pValue) {
      if (pValue) {
        const source = pValue && pValue.source;
        this.address.publicPlaceId = source?.tlg_id;
      }
    },

    selectingCity(pValue) {
      if (pValue) {
        const source = pValue && pValue.source;
        this.address.cityId = source?.mun_id;
        this.address.city = source?.mun_nome;
      }
    },

    selectingUf(pValue) {
      if (pValue) {
        const source = pValue;
        this.address.stateId = source?.est_id ? source.est_id : 0;
        this.address.state = source?.est_id ? source.est_id : 0;
      }
    },

    async fillAddress(pValue) {
      const currentCep = pValue ? pValue.replace(/[^0-9]/gi, "") : null;
      const isInvalidCep = currentCep ? currentCep.length !== 8 : true;

      if (isInvalidCep) return;

      try {
        this.$loader.start();
        const searchCep = await CheckCEP(currentCep);
        const hasCep = Object.keys(searchCep).length !== 0;

        if (hasCep) {
          this.address.cityId = searchCep.mun_id;
          this.address.city = searchCep.mun_nome;
          this.hasCity = !!searchCep.mun_nome;

          this.address.stateId = searchCep.est_id;
          this.address.state = searchCep.est_sigla;
          this.hasState = !!searchCep.est_sigla;

          this.address.publicPlaceId = searchCep.tlg_id;
          this.address.publicPlace = searchCep.tlg_nome;
          this.hasPublicPlace = !!searchCep.temLogradouro;

          if (!this.address.name || searchCep.logradouro !== null) {
            this.address.name = searchCep.logradouro
              ? searchCep.logradouro.toUpperCase()
              : "";
          }

          this.address.neighborhood = null;
          if (!this.address.neighborhood || searchCep.bairro !== null) {
            this.address.neighborhood = searchCep.bairro
              ? searchCep.bairro.toUpperCase()
              : "";
          }

          return true;
        } else {
          this.cleanAddress();
          this.$toaster.warning("CEP não encontrado");
          return false;
        }
      } catch (pErr) {
        this.cleanAddress();
        this.$toaster.warning("CEP não encontrado");
      } finally {
        this.$loader.finish();
      }
    },

    validateCep(pCep, pErrors) {
      if (isEmpty(pCep)) {
        return true;
      }
      if (pCep.replace(/[^0-9]/gi, "").length !== 8) {
        pErrors.push("CEP inválido");
        return false;
      }

      return CheckCEP(pCep)
        .then((pCepData) => {
          return true;
        })
        .catch((pError) => {
          pErrors.push(`O CEP ${pCep} não existe`);
          return false;
        });
    },

    cancelEditSector() {
      this.cleanSector();
    },

    cancelEditPlace() {
      this.cleanPlace();
    },

    handleError(pErr, pMessage) {
      const error = pErr.toString();
      if (error.includes("Error:")) {
        this.$toaster.error(error.substring(6, error.length), pMessage);
      } else {
        this.$toaster.error(pErr, pMessage);
      }
    },

    openModalRemoveSector() {
      this.showModalRemoveSector = true;
    },

    closeModalRemoveSector() {
      this.showModalRemoveSector = false;
    },

    openModalRemovePlace() {
      this.showModalRemovePlace = true;
    },

    closeModalRemovePlace() {
      this.showModalRemovePlace = false;
    },

    openModalCleanUnit() {
      this.showModalCleanUnit = true;
    },

    closeModalCleanUnit() {
      this.showModalCleanUnit = false;
    },

    cleanAddress() {
      this.address = this.$utils.obj.DeepCopy(FORM_ADDRESS);
    },

    cleanUnit() {
      this.unit = this.$utils.obj.DeepCopy(FORM_UNIT);
      this.address = this.$utils.obj.DeepCopy(FORM_ADDRESS);

      this.mutableSectorList = [];
      this.mutablePlaceList = [];
      this.selectedSector = null;
      this.selectedPlace = null;
      this.unitHealthData = {};
      this.addressId = null;
      this.unitHealthId = null;
      this.isEditionUnitHealth = false;

      this.$refs.unitName.cleanValidate();
      this.$refs.cnes.cleanValidate();
      this.$refs.unitType.cleanValidate();
      this.$refs.cep.cleanValidate();
      this.$refs.number.cleanValidate();

      this.cleanSector();
      this.cleanPlace();

      this.closeModalCleanUnit();
    },

    cleanSector() {
      this.sector = this.$utils.obj.DeepCopy(FORM_SECTOR);
      this.$refs.sectorName.cleanValidate();
      this.isEditionSector = false;
      this.$refs.sectorTable.cleanSelectRow();
      this.selectedSector = null;
    },

    cleanPlace() {
      this.place = this.$utils.obj.DeepCopy(FORM_PLACE);
      this.$refs.placeSector.cleanValidate();
      this.$refs.placeName.cleanValidate();
      this.isEditionPlace = false;
      this.$refs.placeTable.cleanSelectRow();
      this.selectedPlace = null;
      this.unitHealthCodePEP = null;
      this.sectorCodePep = null;
      this.typeAttendance = null;
    },

    cancel() {
      this.$router.push({ name: "unit-health.search" });
    },
  },
};
</script>
