import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query States($ufSigla: String, $limit: Int) {
    states(est_sigla: $ufSigla, limit: $limit) {
      count
      rows {
        est_id
        est_sigla
      }
    }
  }
`;

export default async (variables) => {
  const { data } = await GraphQLClient.query({ query, variables }).catch(
    (err) => {
      console.error(err);
    },
  );
  return data.states;
};
