import Vue from "vue";
import Router from "vue-router";
import * as HomeRoutes from "$home/route";
import * as UnitHealthRoutes from "$unit-health/route";
import * as AccessControlRoutes from "$access-control/route";
import * as BillingRoute from "$billing/route";
import * as ExamRoute from "$exam/route";
import * as HospitalizationRoute from "$hospitalization/route";
import * as AppointmentRoute from "$appointment/route";
import * as PersonRoute from "$patient/route";
import * as PersonRoutes from "$person/route"; // NEED CHANGE
import * as TabelaUnificadaRoute from "$tabela-unificada/route";
import * as EmployeeRoute from "$employee/route";
import * as UserRoute from "$user/route";
import * as ReportRoutes from "$report/route";
// import * as PharmacyRoutes from '$pharmacy/route'
// import * as BasicAttentionRoutes from "$basic-attention/route";
import SurgeryCenterRoutes from "$surgery-center/route";
import QueueRoutes from "$queue/route";
import NotificationsRoutes from "$notifications/route";
import ShowIcons from "~tokio/primitive/icon/ShowIcons";
import AboutSoftwareRoutes from "@/app/about-software/route";
import * as EmergencyRoutes from "@/app/emergency/route";
import * as ConfigurationRoutes from "$configuration/route";

Vue.use(Router);
const routes = [
  {
    path: "/icons",
    name: "Icons",
    component: ShowIcons,
  },
  ...Object.values(HomeRoutes),
  ...Object.values(AccessControlRoutes),
  ...Object.values(BillingRoute),
  ...Object.values(SurgeryCenterRoutes),
  ...Object.values(ExamRoute),
  ...Object.values(HospitalizationRoute),
  ...Object.values(AppointmentRoute),
  ...Object.values(PersonRoute),
  ...Object.values(PersonRoutes),
  ...Object.values(TabelaUnificadaRoute),
  ...Object.values(EmployeeRoute),
  ...Object.values(UserRoute),
  ...Object.values(QueueRoutes),
  ...Object.values(NotificationsRoutes),
  ...Object.values(ReportRoutes),
  ...Object.values(UnitHealthRoutes),
  // ...Object.values(PharmacyRoutes),
  // ...Object.values(BasicAttentionRoutes),
  ...Object.values(EmergencyRoutes),
  ...Object.values(ConfigurationRoutes),
  AboutSoftwareRoutes,
];

const scrollBehavior = (to, from, savedPosition) => {
  const hasHash = to.hash && to.hash.length > 0;

  const element = hasHash ? document.querySelector(to.hash) : false;

  if (element) {
    return document
      .querySelector(to.hash)
      .scrollIntoView({ behavior: "smooth" });
  }

  if (savedPosition) {
    return savedPosition;
  }

  return { x: 0, y: 0 };
};

const router = new Router({
  mode: "history",
  saveScrollPosition: true,
  routes,
  scrollBehavior,
});

export default router;
