<style src="./RelationshipSectorOptions.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="relationship-sector-options">
    <button type="button" class="button-open" @click.stop="openOptions">
      <IconEllipsis />
    </button>

    <div v-if="panelOpened">
      <ul v-click-outside="closePanel" class="options-container">
        <li
          v-show="!activeStatus"
          class="item"
          @click="activateRelationshipSector"
        >
          Ativar Vínculo Setor
        </li>
        <li
          v-show="activeStatus"
          class="item"
          @click="deactivateRelationshipSector"
        >
          Inativar Vínculo Setor
        </li>

        <li class="remove-bottom" @click="removeRelationshipSector">
          <IconGarbage class="item-icon" />
          Remover Vínculo Setor
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import { IconEllipsis, IconGarbage } from "~tokio/primitive/icon";
import ClickOutside from "vue-click-outside";

export default {
  name: "RelationshipOptions",
  components: {
    IconEllipsis,
    IconGarbage,
  },
  directives: {
    ClickOutside,
  },
  props: {
    activeStatus: {
      default: false,
    },
  },
  data() {
    return {
      panelOpened: false,
    };
  },
  methods: {
    closePanel() {
      this.panelOpened = false;
    },
    openOptions() {
      this.panelOpened = true;
    },
    removeRelationshipSector() {
      this.$emit("remove-relationship-sector");
      this.closePanel();
    },
    activateRelationshipSector() {
      this.$emit("activate-relationship-sector");
      this.closePanel();
    },
    deactivateRelationshipSector() {
      this.$emit("deactivate-relationship-sector");
      this.closePanel();
    },
  },
};
</script>
