<template lang="html">
  <div v-if="show" class="modal-hospitalization-details">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <h1 class="title">Detalhes</h1>
      </div>

      <div slot="body" class="body">
        <!-- BED INFORMATIONS -->
        <fieldset class="bed-area">
          <div class="bed-information">
            <RgBedStatus
              :generate-situation-status="hospitalizationInfo.isl_id || 3"
              :is-active="
                hospitalizationInfo ? hospitalizationInfo.inl_habilitado : null
              "
              class="status"
            />

            <div class="grid">
              <span class="data">
                Unidade de Saúde:
                <strong> {{ hospitalizationInfo.uns_nome }} </strong>
              </span>

              <span class="data">
                Local de Atendimento:
                <strong> {{ hospitalizationInfo.lca_nome }} </strong>
              </span>

              <span class="data">
                Setor:
                <strong> {{ hospitalizationInfo.set_nome }} </strong>
              </span>

              <span class="data">
                Leito:
                <strong> {{ hospitalizationInfo.inl_nome }} </strong>
              </span>
            </div>
          </div>
        </fieldset>

        <!-- HOSPITALIZATION INFORMATIONS -->
        <fieldset v-if="hasHospitalizations" class="hospitalization-area">
          <legend class="title-area">Entrada</legend>

          <div class="hospitalization-information">
            <div class="column-1">
              <div>
                Data e Hora:
                <strong>
                  {{ hospitalization.int_data }}
                </strong>
                às
                <strong>
                  {{ hospitalization.int_hora }}
                </strong>
              </div>

              <div title="Número da Internação">
                N. da Internação:
                <strong>
                  {{ hospitalization.int_numero }}
                </strong>
              </div>

              <div>
                Paciente:
                <strong>
                  {{ hospitalization.paciente }}
                </strong>
              </div>

              <div>
                Prontuário Único:
                <strong>
                  {{ hospitalization.pac_prontuario_unico }}
                </strong>
              </div>

              <div>
                CNS:
                <strong>
                  {{ hospitalization.crs_numero }}
                </strong>
              </div>

              <div>
                Responsável:
                <strong>
                  {{ hospitalization.int_responsavel }}
                </strong>
              </div>
            </div>

            <div class="column-2">
              <div>
                Caráter de Internação:
                <strong>
                  {{ hospitalization.ica_nome }}
                </strong>
              </div>

              <div>
                Profissional Solicitante:
                <strong>
                  {{ hospitalization.fun_solicitante_nome }}
                </strong>
              </div>

              <div>
                Ocupação:
                <strong>
                  {{ hospitalization.ocp_nome }}
                </strong>
              </div>

              <div>
                CID Principal:
                <strong>
                  {{ hospitalization.cid_codigo }} -
                  {{ hospitalization.cid_nome }}
                </strong>
              </div>

              <div>
                Procedimento:
                <strong>
                  {{ hospitalization.inp_novo_nome_procedimento }}
                </strong>
              </div>

              <div>
                PEP:
                <strong>
                  {{ hospitalization.enviadoPep }}
                </strong>
              </div>
            </div>

            <div class="column-3">
              <div>
                Observação:
                <strong>
                  {{ hospitalization.int_obs }}
                </strong>
              </div>
            </div>
          </div>

          <div class="flex-right">
            <div>
              Usuário:
              <strong> {{ hospitalization.usu_nome }} </strong>
            </div>
          </div>
        </fieldset>

        <!-- OUTPUT HOSPITALIZATION INFORMATIONS -->
        <fieldset v-if="hasHospitalizationsOut" class="hospitalization-area">
          <legend class="title-area">Saída</legend>

          <div class="output-hospitalization-information">
            <div class="info-3">
              <div>
                Data e Hora:
                <strong>
                  {{ hospitalizationOut.isa_data }}
                </strong>
                às
                <strong> {{ hospitalizationOut.isa_hora }} </strong>
              </div>

              <div>
                Motivo:
                <strong>
                  {{ hospitalizationOut.igs_nome }}
                </strong>
              </div>

              <div>
                Profissional Responsável:
                <strong>
                  {{ hospitalizationOut.nome_funcionario }}
                </strong>
              </div>

              <div>
                Ocupação:
                <strong>
                  {{ hospitalizationOut.ocupacao }}
                </strong>
              </div>
            </div>

            <div class="info-4">
              <div>
                Observação:
                <strong>
                  {{ hospitalizationOut.observacao }}
                </strong>
              </div>

              <div>
                Dados do Óbito:
                <strong>
                  {{ hospitalizationOut.isa_causa_obito }}
                </strong>
              </div>
            </div>
          </div>

          <div class="flex-right">
            <div>
              Usuário:
              <strong> {{ hospitalizationOut.usu_nome }} </strong>
            </div>
          </div>
        </fieldset>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { RgBaseModal } from "~tokio/primitive";
import RgBedStatus from "$hospitalization/common/component/status/rg-bed-status/RgBedStatus";

export default {
  name: "ModalHospitalizationDetails",
  components: {
    RgBaseModal,
    RgBedStatus,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    hospitalizationInfo: {
      type: Object,
      default: () => {},
    },
    isFromMapBed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hospitalizationOut: null,
      hospitalization: null,
      showInfo: false,
    };
  },
  computed: {
    hasHospitalizations() {
      return (
        this.hospitalizationInfo &&
        this.hospitalizationInfo.int_id &&
        this.situationBedIsBusyOrReservation
      );
    },
    hasHospitalizationsOut() {
      return this.hospitalizationOut && this.hospitalizationOut.isa_id;
    },
    situationBedIsBusyOrReservation() {
      const validateSituation =
        this.hospitalizationInfo.isl_id === this.LEITO_OCUPADO ||
        this.hospitalizationInfo.isl_id === this.LEITO_RESERVA;

      return this.hospitalizationInfo && validateSituation;
    },
  },
  watch: {
    hospitalizationInfo(pValue) {
      if (!pValue) {
        this.close();
      }

      this.hospitalization = pValue;

      if (this.hasHospitalizations) {
        this.isFromMapBed
          ? this.getHospitalizationDataForCommingFromMapBed(pValue)
          : this.getOutHospData(pValue);
      }
    },
  },
  created() {
    this.LEITO_OCUPADO = 3;
    this.LEITO_RESERVA = 2;
  },
  methods: {
    async getOutHospData(pItem) {
      this.hospitalizationOut = await this.$store.dispatch(
        "Hospitalization/Hospitalizations/SEARCH_CUSTOM_HOSPITALIZATION_OUTPUT_DETAIL",
        {
          int_id: pItem.int_id || 0,
          inl_id: pItem.inl_id || 0,
          int_id_ocupacoes_cbo: pItem.int_id_ocupacoes_cbo || 0,
        },
      );
    },

    async getHospitalizationDataForCommingFromMapBed(pData) {
      await this.$store
        .dispatch("Hospitalization/Bed/SEARCH_HOSPITALIZATION", {
          int_id: pData.int_id || 0,
        })
        .then((result) => {
          this.hospitalization = result;

          this.$parent.closeLoader();
        })
        .catch((err) => {
          console.log(err);
          this.$toaster.error("Erro ao carregador os dados da internação");
        });
    },

    showTypeBed() {
      this.showInfo = !this.showInfo;
    },

    close() {
      this.hospitalizationOut = null;
      this.hospitalization = null;
      this.$emit("close");
    },
  },
};
</script>
