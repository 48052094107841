<style lang="scss" scoped>
table {
  .report-column-1 {
    width: 250px;
  }
  .report-column-2 {
    width: 100px;
  }
  .report-column-3 {
    width: 250px;
  }
  .report-column-4 {
    width: 100px;
  }
  .report-column-5 {
    width: 100px;
  }
  .report-column-6 {
    width: 100px;
  }
  .item-iaf-prontuario {
    text-align: center;
  }
  .submit-row {
    margin-top: 40%;
  }

  text-align: left;
  border-collapse: collapse;

  th {
    padding-bottom: 5px;
  }
  td {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
</style>
<template>
  <div>
    <div class="row filter-fields">
      <RgComboboxUnithealth v-model="form.unsId" />
    </div>
    <div class="row filter-fields">
      <RgInputMasked
        v-model="form.presentation"
        :mask="['BM/DDDD']"
        :tokens="tokens"
        :rules="{ fn: validatePeriod }"
        label="Apres.:"
        class="col-12"
      />

      <RgInputMasked
        v-model="form.medicalRecord"
        :mask="['###############']"
        class="col-12"
        label="Prontúario"
        placeholder="Informe o prontuário"
      />

      <RgInput
        v-model="form.name"
        class="col-12"
        label="Nome do Paciente"
        placeholder="Informe o nome do paciente"
      />
    </div>
  </div>
</template>

<script>
import { RgInput, RgInputMasked } from "~tokio/primitive";
import RgComboboxUnithealth from "$billing/common/component/rg-combobox-unithealth/RgComboboxUnithealth";
import {
  ValidatePeriodBilling,
  MountPresentation,
} from "../../../common/service/";

export default {
  name: "AihPerPatient",
  components: {
    RgInput,
    RgInputMasked,
    RgComboboxUnithealth,
  },
  data() {
    return {
      resultTitle: "Relatório de AIH por Paciente",
      form: {
        unsId: 0,
        presentation: "",
        medicalRecord: "",
        name: "",
      },
      tokens: {
        B: { pattern: /^([0-1])$/ },
        M: { pattern: /^([0-9])$/ },
        D: { pattern: /\d/ },
      },
    };
  },
  computed: {
    printReportCss() {
      return (
        ".report-column-1-print { width: 200px; }" +
        ".report-column-2-print { width: 100px; }" +
        ".report-column-3-print { width: 200px; }" +
        ".report-column-4-print { width: 130px; }" +
        ".report-column-5-print { width: 130px; }" +
        ".report-column-6-print { width: 130px; }"
      );
    },
    columnTable() {
      return this.COLUMN;
    },
    getNameSmartTable() {
      return "AihPerPatient";
    },
  },
  watch: {
    "form.name"() {
      if (this.form.name) {
        this.form.name = this.form.name.toUpperCase();
      }
    },
  },
  created() {
    this.COLUMN = [
      { name: "Nome do Paciente", key: "icf_nome" },
      { name: "Prontuário", key: "iaf_prontuario" },
      { name: "Número AIH", key: "iaf_numero_aih" },
      { name: "Valor Serviço Hospitalar", key: "valor_servico_hospitalar" },
      {
        name: "Valor Serviço Profissional",
        key: "valor_servico_profissional",
      },
      { name: "Total", key: "total" },
    ];
  },
  mounted() {
    this.form.unsId = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    this.updatePresentationDate();
  },
  methods: {
    buildFilter() {
      return {
        unsId: this.form.unsId ? this.form.unsId : null,
        name: this.form.name ? this.form.name : null,
        presentation: this.form.presentation
          ? this.form.presentation.replace("/", "")
          : null,
        medicalRecord: this.form.medicalRecord ? this.form.medicalRecord : null,
      };
    },
    async doSearch(pData) {
      try {
        return this.$store.dispatch(
          "Billing/HospitalizationBilling/GET_AIH_PER_PATIENT_REPORT",
          pData,
        );
      } catch (error) {
        return false;
      }
    },
    updatePresentationDate() {
      const cmp = this.$store.getters[
        "Billing/PeriodHospitalizationBilling/GET_PERIOD_HOSPITALIZATION_BILLING"
      ];
      if (!cmp) return;
      this.form.presentation = MountPresentation(cmp);
    },
    validatePeriod(pAih, pErrors) {
      return ValidatePeriodBilling(this.form.presentation, pErrors);
    },
    clearFilter() {
      this.form = {
        unsId: 0,
        presentation: "",
        medicalRecord: "",
        name: "",
      };
    },
  },
};
</script>
