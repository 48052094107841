<template lang="html">
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.7675 13.9846C6.17222 13.9846 0 20.0846 0 27.5532V48.337C0 49.4692 0.925833 50.3816 2.07455 50.3816H8.1439C8.1439 50.3816 8.1439 50.3816 8.16105 50.3816C8.52109 50.3816 8.77827 50.0944 8.77827 49.7733V46.6473L8.91543 46.7487C8.93258 46.7656 8.96687 46.7656 8.96687 46.7994L9.56694 47.2049C9.80697 47.3739 10.047 47.526 10.2527 47.6949C11.2472 48.4215 12.2759 49.1819 13.7675 49.1819C15.2248 49.1819 16.2707 48.4215 17.2822 47.6949C17.5223 47.526 17.728 47.3739 17.968 47.2049L18.5681 46.7994C18.5681 46.7994 18.5852 46.7994 18.5852 46.7825C18.6538 46.7318 18.7224 46.6811 18.8081 46.6473V49.8071C18.8081 50.1282 19.0653 50.4154 19.4254 50.4154H25.4947C26.6434 50.4154 27.5693 49.503 27.5693 48.3708V27.587C27.5521 20.0846 21.3627 13.9846 13.7675 13.9846ZM20.6941 43.6565C20.1454 44.1803 19.4939 44.6365 18.8596 45.0927C18.5338 45.3293 18.1909 45.5321 17.8652 45.7686L17.2651 46.1742C17.0079 46.3431 16.7679 46.529 16.5278 46.698C15.6363 47.3401 14.8133 47.9484 13.7846 47.9484C12.7388 47.9484 11.8987 47.3401 11.0414 46.698C10.8014 46.5121 10.5442 46.3431 10.3042 46.1742L9.7041 45.7686C9.37835 45.549 9.03545 45.3293 8.70969 45.0927C8.05818 44.6534 7.42381 44.1803 6.87517 43.6565C4.02909 41.0036 3.78906 36.8637 3.78906 33.1801C3.78906 30.9158 4.8692 28.7529 6.63514 27.3674C8.22963 26.117 10.0813 25.1369 10.9728 24.6807C14.3161 31.9297 21.6714 33.4335 23.8145 33.7208C23.7631 37.2861 23.4201 41.1387 20.6941 43.6565Z"
      fill="white"
    />
    <path
      d="M55.1198 51.3831V47.107C57.3569 45.3762 58.8939 42.8309 59.2867 39.9292C60.3967 39.8614 61.4213 39.4371 62.2581 38.7245C63.3511 37.7742 64 36.4167 64 34.9744C64 33.7696 63.5731 32.6327 62.7875 31.7334V27.4403C62.7875 19.482 56.264 13 48.2547 13C40.2454 13 33.7048 19.4481 33.7048 27.4403V31.8861C32.9192 32.7685 32.4923 33.8545 32.4923 35.0083C32.4923 36.4507 33.1412 37.8251 34.2342 38.7584C35.071 39.4711 36.1127 39.8953 37.2057 39.9632C37.5814 42.9327 39.1866 45.5798 41.6116 47.3106V51.4H43.9683V48.5663C45.4028 49.1262 46.9227 49.3977 48.4084 49.3977C49.9624 49.3977 51.4482 49.0753 52.8144 48.4984V51.3831H55.1198ZM61.6263 34.9574C61.6263 35.721 61.3018 36.4507 60.7212 36.9428C60.3455 37.2652 59.8673 37.4857 59.3721 37.5536V32.3612C60.7041 32.5648 61.6263 33.6848 61.6263 34.9574ZM37.0861 37.5536C35.737 37.333 34.8319 36.2131 34.8319 34.9574C34.8319 33.7017 35.8736 32.6836 37.0861 32.4121V37.5536ZM39.4428 38.4869V31.2073V30.834C44.5148 31.1225 49.8087 29.6293 54.1122 26.9143C55.8029 28.7299 56.7251 29.6802 57.0325 30.0365C57.0325 30.9528 57.0325 37.4518 57.0325 38.5039C57.0325 43.2381 53.1559 47.107 48.3742 47.107C43.029 47.073 39.4428 42.9496 39.4428 38.4869Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: "Person",
};
</script>
