<template lang="html">
  <svg
    id="Capa_1"
    enable-background="new 0 0 510 510"
    height="512"
    viewBox="0 0 510 510"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <linearGradient
      id="SVGID_1_"
      gradientUnits="userSpaceOnUse"
      x1="157.153"
      x2="399.748"
      y1="198.847"
      y2="441.441"
    >
      <stop offset="0" stop-color="#7faef4" />
      <stop offset="1" stop-color="#4c8df1" />
    </linearGradient>
    <linearGradient id="lg1">
      <stop offset="0" stop-color="#4c8df1" stop-opacity="0" />
      <stop offset="1" stop-color="#4256ac" />
    </linearGradient>
    <linearGradient
      id="SVGID_2_"
      gradientUnits="userSpaceOnUse"
      x1="410.106"
      x2="371.606"
      xlink:href="#lg1"
      y1="173.728"
      y2="61.228"
    />
    <linearGradient
      id="SVGID_3_"
      gradientUnits="userSpaceOnUse"
      x1="343.272"
      x2="387.993"
      y1="58.728"
      y2="103.45"
    >
      <stop offset="0" stop-color="#a7c5fd" />
      <stop offset="1" stop-color="#7faef4" />
    </linearGradient>
    <linearGradient
      id="SVGID_4_"
      gradientTransform="matrix(-1 0 0 1 1574 0)"
      gradientUnits="userSpaceOnUse"
      x1="1319"
      x2="1319"
      xlink:href="#lg1"
      y1="463.7"
      y2="513.288"
    />
    <g>
      <path
        d="m68.17 31.88v446.25c0 17.529 14.341 31.87 31.87 31.87h309.91c17.534 0 31.88-14.346 31.88-31.88v-360.725c0-9.787-3.888-19.173-10.808-26.094l-80.493-80.493c-6.921-6.92-16.307-10.808-26.094-10.808h-224.385c-17.534 0-31.88 14.346-31.88 31.88z"
        fill="url(#SVGID_1_)"
      />
      <g>
        <g>
          <g>
            <g>
              <path
                d="m153.111 246.041h203.778c5.079 0 9.196-4.117 9.196-9.196v-22.425c0-5.079-4.117-9.196-9.196-9.196h-203.778c-5.079 0-9.196 4.117-9.196 9.196v22.425c0 5.079 4.117 9.196 9.196 9.196z"
                fill="#ebeff0"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <path
                d="m153.111 309.756h203.778c5.079 0 9.196-4.117 9.196-9.196v-22.425c0-5.079-4.117-9.196-9.196-9.196h-203.778c-5.079 0-9.196 4.117-9.196 9.196v22.425c0 5.079 4.117 9.196 9.196 9.196z"
                fill="#ebeff0"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <path
                d="m153.111 373.47h203.778c5.079 0 9.196-4.117 9.196-9.196v-22.424c0-5.079-4.117-9.196-9.196-9.196h-203.778c-5.079 0-9.196 4.117-9.196 9.196v22.425c0 5.078 4.117 9.195 9.196 9.195z"
                fill="#ebeff0"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <path
                d="m150.573 437.185h103.155c3.677 0 6.658-2.981 6.658-6.658v-27.5c0-3.677-2.981-6.658-6.658-6.658h-103.155c-3.677 0-6.658 2.981-6.658 6.658v27.5c0 3.677 2.981 6.658 6.658 6.658z"
                fill="#ebeff0"
              />
            </g>
          </g>
        </g>
      </g>
      <path
        d="m350.528 10.808c-4.74-4.74-10.638-8.056-17.028-9.676v103.922l108.33 108.33v-95.99c0-9.787-3.888-19.173-10.808-26.094z"
        fill="url(#SVGID_2_)"
      />
      <path
        d="m440.737 108.443c.118.512.227 1.011.326 1.492h-97.648c-6.914 0-12.52-5.605-12.52-12.52v-96.834c.763.136 1.565.295 2.392.478 7.279 1.61 13.916 5.353 19.188 10.624l77.655 77.655c5.251 5.251 8.938 11.87 10.607 19.105z"
        fill="url(#SVGID_3_)"
      />
      <path
        d="m441.83 447.201v30.919c0 17.534-14.346 31.88-31.88 31.88h-309.91c-17.529 0-31.87-14.342-31.87-31.87v-30.929z"
        fill="url(#SVGID_4_)"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: "FileImg",
};
</script>
