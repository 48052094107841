<style src="./InputRg.scss" lang="scss" scoped></style>

<template>
  <fieldset class="rg-input--component">
    <!-- <rg-form-base
      label="Número do RG"
      class="form-base">
      <div class="rg-input--base">
        <div class="rg-input--textbox-container">
          <rg-validation-alert :alert="error"/>
          <input
            v-model="form.info.rg.rgs_numero"
            type="text"
            @blur="validateBlur">
        </div>
      </div>
    </rg-form-base> -->

    <RgInputRg
      v-model="form.info.rg.rgs_numero"
      class="rg-input--textbox-container"
      @blur="validateBlur"
    />

    <RgComboboxIssuingBody
      v-model="form.info.rg.rgs_id_orgaos_emissores"
      label="Órgão Emissor"
      class="form-base"
    />

    <RgInputDate
      v-model="form.info.rg.rgs_expedicao"
      v-mask="'##/##/####'"
      label="Data de expedição"
      type="text"
      @blur="validateBlur"
    />
  </fieldset>
</template>

<script>
import {
  RgValidatorMixin,
  RgInputRg,
  RgInputDate,
  RgInputMixin,
} from "~tokio/primitive";
import RgComboboxIssuingBody from "$patient/common/components/combobox/rg-combobox-issuing-body/RgComboboxIssuingBody";
import { mask } from "vue-the-mask";
import GetRgByPersonId from "./actions/GetRgByPersonId";
import moment from "moment";

export default {
  name: "InputRg",
  components: {
    RgInputRg,
    RgComboboxIssuingBody,
    RgInputDate,
  },
  directives: {
    mask,
  },
  mixins: [RgValidatorMixin, RgInputMixin],
  props: {
    documentData: {
      default: null,
    },
  },
  data() {
    return {
      form: {
        info: {
          rg: {
            rgs_numero: "",
            rgs_expedicao: "01/01/2018",
            rgs_id_orgaos_emissores: 1,
          },
        },
        mutationName: "AddRgToPerson",
        mutationParams: "$pes_id: Int, $rg: RgInputType",
        mutationArgs: "pes_id: $pes_id, rg: $rg",
        mutationReturnField: "rgs_id",
      },
    };
  },
  watch: {
    "form.info": {
      handler: function () {
        const form = JSON.parse(JSON.stringify(this.form));
        form.info.rg.rgs_numero = form.info.rg.rgs_numero
          ? form.info.rg.rgs_numero.replace(/(\.|-)/gi, "")
          : "";
        this.$emit("formatted-data", form);
      },
      deep: true,
    },
    documentData(pValue) {
      this.form.info.rg = pValue.info.rg;
    },
  },
  mounted() {
    this.readInfo();
  },
  methods: {
    validateBlur() {
      this.validate();
    },
    async readInfo() {
      const data = await GetRgByPersonId();
      this.form.info.rg.rgs_numero = data.rg.rgs_numero;
      this.form.info.rg.rgs_expedicao = moment(data.rg.rgs_expedicao).format(
        "DD/MM/YYYY",
      );
      this.form.info.rg.rgs_id_orgaos_emissores =
        data.rg.rgs_id_orgaos_emissores;
    },
    clean() {
      this.form.info.rg.rgs_numero = "";
      this.form.info.rg.rgs_id_orgaos_emissores = null;
      this.form.info.rg.rgs_expedicao = "";
    },
  },
};
</script>
