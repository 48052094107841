<style src="./PatientInfo.scss" lang="scss" scoped></style>
<template lang="html">
  <PrintFrameInfo label="Informações do Paciente">
    <div class="row">
      <div class="col-4 frame-fieldset">
        <span class="fieldset-label">Nº Cirurgia</span>
        <span class="fieldset-value">{{ patientData.cci_numero }}</span>
      </div>

      <div class="col-4 frame-fieldset">
        <span class="fieldset-label">Setor</span>
        <span class="sector-field fieldset-value">{{
          patientData.bed_sector
        }}</span>
      </div>

      <div class="col-4 frame-fieldset">
        <span class="fieldset-label">Local</span>
        <span class="local-field fieldset-value">{{
          patientData.bed_place
        }}</span>
      </div>
    </div>

    <div class="row">
      <div class="col-4 frame-fieldset">
        <span class="fieldset-label">Idade</span>
        <span class="fieldset-value">{{ patientData.pes_nascimento }}</span>
      </div>

      <div class="col-4 frame-fieldset">
        <span class="fieldset-label">Leito</span>
        <span class="bed-field fieldset-value">{{ patientData.bed }}</span>
      </div>

      <div class="col-4 frame-fieldset">
        <span class="fieldset-label">Prontuário</span>
        <span class="fieldset-value">{{ patientData.unitRecordNumber }}</span>
      </div>
    </div>

    <div class="row">
      <div class="col-4 frame-fieldset">
        <span class="fieldset-label">Mãe</span>
        <span class="mother-field fieldset-value">{{
          patientData.pes_mae
        }}</span>
      </div>

      <div class="col-4 frame-fieldset">
        <span class="fieldset-label">Nº Internação</span>
        <span class="fieldset-value">{{ patientData.bed_number }}</span>
      </div>

      <div class="col-4 frame-fieldset">
        <span class="fieldset-label">CNS</span>
        <span class="fieldset-value">{{ patientData.cns }}</span>
      </div>
    </div>
  </PrintFrameInfo>
</template>

<script>
import PrintFrameInfo from "../print-frame-info/PrintFrameInfo";
export default {
  name: "PatientInfo",
  components: {
    PrintFrameInfo,
  },
  props: {
    patientData: {
      default() {},
    },
  },
};
</script>
