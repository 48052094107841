<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchHospitalizationProfessionalFunction from "./action/SearchHospitalizationProfessionalFunction";

export default {
  name: "RgComboboxSurgeryFunction",
  mixins: [RgComboboxMixin],
  data: () => {
    return {};
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];
      const data = await SearchHospitalizationProfessionalFunction();
      data.forEach((item) => {
        this.mData.push({
          value: item.ifp_id,
          text: item.ifp_descricao,
        });
      });
    },
  },
};
</script>
