<template lang="html">
  <div v-show="show" class="modal-appointment-details">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <div class="title">Detalhes</div>
      </div>

      <div slot="body" class="body">
        <FormBase title="Paciente" class="area">
          <div class="patient-basic-info">
            <span
              >Nome: <b>{{ patientData.pes_nome || "" }}</b></span
            >
            <span
              >CNS: <b>{{ patientData.crs_numero || "" }}</b></span
            >
            <span
              >Nascimento: <b>{{ patientData.pes_nascimento || "" }}</b></span
            >
            <span
              >Idade:
              <b>
                {{
                  patientData.pes_idade && patientData.pes_idade.years
                    ? patientData.pes_idade.years
                    : ""
                }}
              </b>
            </span>
            <span
              >Prontuário da Unidade:
              <b>{{ patientData.ppr_numero || "" }}</b></span
            >
            <span
              >Prontuário Único:
              <b>{{ patientData.pac_prontuario_unico || "" }}</b></span
            >
            <span class="unique-in-row"
              >Mãe: <b>{{ patientData.pes_mae || "" }}</b></span
            >
          </div>
        </FormBase>

        <FormBase title="Consulta" class="area">
          <div class="appointment-info">
            <span
              >Local: <b>{{ appointmentData.lca_nome || "" }}</b></span
            >
            <span
              >Convenio: <b>{{ appointmentData.pls_nome || "" }}</b></span
            >
            <span
              >Agendado por: <b>{{ appointmentData.usu_nome || "" }}</b></span
            >
            <span
              >Situação: <b>{{ appointmentData.efc_nome || "" }}</b></span
            >
            <span class="unique-in-row"
              >Motivo: <b>{{ appointmentData.mne_nome || "" }}</b></span
            >
          </div>
        </FormBase>

        <FormBase title="Meu Esus" class="area">
          <div class="app-info">
            <span
              >Status: <b>{{ appointmentData.sme_nome || "" }}</b></span
            >
            <span
              >Motivo: <b>{{ appointmentData.justificativa || "" }}</b></span
            >
          </div>
        </FormBase>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import { RgBaseModal } from "~tokio/primitive";
import FormBase from "~tokio/foundation/form-base/FormBase";

export default {
  name: "ModalAppointmentDetails",
  components: {
    RgBaseModal,
    FormBase,
  },
  directives: { mask },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
    appointmentId: { default: 0 },
    pccId: { default: 0 },
    unityHealth: { default: 0 },
  },
  data() {
    return {
      appointmentData: {},
      patientData: {},
    };
  },

  watch: {
    show(pValue) {
      if (!pValue) {
        this.close();
      } else {
        this.getConsultationData();
      }
    },
  },

  methods: {
    async getConsultationData() {
      try {
        if (!this.appointmentId) {
          throw new Error("Erro ao receber dados da consulta");
        }

        if (!this.pccId || !this.unityHealth) {
          throw new Error("Erro ao receber dados do paciente e da unidade");
        }
        this.$loader.start();

        const variables = {
          intIdConsulta: this.appointmentId,
        };

        this.appointmentData = await this.$store.dispatch(
          "Appointment/Schedule/GET_APPOINTMENT_DETAILS",
          variables,
        );

        const variablesPatient = {
          intIdPacienteConsulta: this.pccId,
          intIdUnidadeSaude: this.unityHealth,
        };

        this.patientData = await this.$store.dispatch(
          "Appointment/Schedule/GET_PATIENT_APPOINTMENT_DETAILS",
          variablesPatient,
        );
      } catch (Err) {
        this.$toaster.error(Err);
      } finally {
        this.$loader.finish();
      }
    },

    close() {
      this.appointmentData = {};
      this.patientData = {};
      this.$emit("close");
    },
  },
};
</script>
