<style src="./GuideLine.scss" lang="scss" scoped></style>
<template lang="html">
  <li class="guide-line-status">
    <div class="guide-line" />
    <div :title="statusName" class="guide-line-container">
      <component :is="statusStyle" :class="statusStyle" class="status" />
    </div>
  </li>
</template>

<script>
import {
  IconGeometryCircle,
  IconGeometryDiamond,
  IconGeometryHexagon,
  IconGeometryTriangle,
  IconGeometrySquare,
  IconGeometrySuperStar,
} from "~tokio/primitive";
import SurgeryStatusModel from "$surgery-center/surgery/model/SurgeryStatusModel";
export default {
  name: "GuideLine",
  components: {
    IconGeometryCircle,
    IconGeometryDiamond,
    IconGeometryHexagon,
    IconGeometryTriangle,
    IconGeometrySquare,
    IconGeometrySuperStar,
  },
  props: {
    CcStatus: {
      default: null,
    },
  },
  data() {
    return {
      statusIcon: "",
    };
  },
  computed: {
    statusStyle() {
      let statusIcon = "";
      switch (this.CcStatus) {
        case 1:
          statusIcon = "IconGeometryCircle";
          break;
        case 2:
          statusIcon = "IconGeometryDiamond";
          break;
        case 3:
          statusIcon = "IconGeometryHexagon";
          break;
        case 4:
          statusIcon = "IconGeometryTriangle";
          break;
        case 5:
          statusIcon = "IconGeometrySquare";
          break;
        default:
          statusIcon = "IconGeometryCircle";
          break;
      }
      return statusIcon;
    },
    statusName() {
      return SurgeryStatusModel.getSurgeryStatusDescription(this.CcStatus);
    },
  },
};
</script>
