import { GraphQLClient, gql } from "@/common/request/GraphQLClient";
import Store from "@/store";

const query = gql`
  query People($pesId: Int) {
    people(pes_id: $pesId) {
      rows {
        rg {
          rgs_id
          rgs_id_orgaos_emissores
          rgs_numero
          rgs_expedicao
          issuingBody {
            org_id
            org_nome
          }
        }
      }
    }
  }
`;

export default async () => {
  const pesId = Store.getters["Patient/GET_PERSON_ID"];
  const variables = {
    pesId,
  };
  const { data } = await GraphQLClient.query({
    query,
    variables,
    fetchPolicy: "no-cache",
  }).catch((err) => {
    console.error(err);
  });
  return Promise.resolve(
    data.people.rows.length > 0 ? data.people.rows[0] : [],
  );
};
