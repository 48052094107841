<style src="./MainHeader.scss" lang="scss" scoped></style>
<template lang="html">
  <header class="main-header">
    <slot name="main-menu">
      <MainMenu class="main-header--main-menu" />
    </slot>

    <slot name="brand">
      <Brand class="main-header--brand" />
    </slot>

    <slot name="breadscrumb">
      <Breadscrumb class="main-header--breadscrumb" />
    </slot>

    <slot name="header-title">
      <HeaderTitle />
    </slot>

    <slot name="gloal-search">
      <!-- <GlobalSearch class="main-header--global-search" /> -->
    </slot>

    <slot name="user-panel">
      <UserPanel />
    </slot>
  </header>
</template>

<script>
import MainMenu from "./main-menu/MainMenu";
import Brand from "./brand/Brand";
import Breadscrumb from "./breadscrumb/Breadscrumb";
import HeaderTitle from "./header-title/HeaderTitle";
// import GlobalSearch from "./global-search/GlobalSearch";
import UserPanel from "./user-panel/UserPanel";

export default {
  name: "MainHeader",
  components: {
    MainMenu,
    Brand,
    Breadscrumb,
    HeaderTitle,
    // GlobalSearch,
    UserPanel,
  },
};
</script>
