import MainHeader from "~tokio/foundation/main-header/MainHeader";
import Home from "../view/Home";

export const HomeRoute = {
  path: "/home",
  name: "Home",
  components: {
    default: Home,
    header: MainHeader,
  },
  meta: {
    breadcrumb: [{ label: "Bem-vindo" }],
  },
};

export const IndexRoutes = {
  path: "/",
  name: "Index",
  components: {
    default: Home,
    header: MainHeader,
  },
  meta: {
    breadcrumb: [{ label: "Bem-vindo" }],
  },
};
