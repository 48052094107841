<template>
  <section class="quick-appointment-external-schedule-regulation-form">
    <form class="form-container" @submit.stop.prevent="saveForm">
      <RgValidatorForm ref="validator">
        <FormBase title="Paciente" class="form-base">
          <div class="actions-top">
            <RgPersonCardButton
              :disabled="!patientSelect"
              :class="{ disable: !patientSelect }"
              class="register"
              title="Editar paciente"
              @click="editPatient"
            />
            <RgPrinterButton
              :disabled="!patientSelect"
              :class="{ disable: !patientSelect }"
              class="printer"
              title="Imprimir carteirinha"
              @click="patientPrintCard"
            />
            <!-- <RgHistoryButton
            :disabled="!patientSelect"
              :class="{ disable: !patientSelect }"
                class="history"
                title="Histórico do paciente"
                @click="historyPatient"
              /> -->
            <RgDeleteButton
              v-show="form.patientList && form.patientList.length > 1"
              :disabled="!patientSelect"
              :class="{ disable: !patientSelect }"
              class="delete"
              title="Remover paciente da tabela"
              @click="removePatient"
            />
          </div>

          <table v-if="form.patientList" class="table-patient">
            <div class="thead">
              <tr class="header-grid">
                <th class=""></th>
                <th class="patient">Pacientes</th>
                <th class="medical-record">Prontuário Único</th>
              </tr>
            </div>

            <div class="tbody">
              <tr
                v-for="(item, index) in form.patientList"
                :key="index"
                :class="{ selected: index === activeRow }"
                class="body-grid"
                @click="selectLine(item, index)"
              >
                <td>
                  <div v-if="index === activeRow" class="check">
                    <IconCheck />
                  </div>
                </td>
                <td>{{ item.pes_nome }}</td>
                <td>{{ item.pac_prontuario_unico }}</td>
              </tr>
            </div>
          </table>
        </FormBase>

        <FormBase title="Agendar Externo" class="form-base">
          <div class="schedule-area">
            <RgComboboxExternalLocation
              v-model="form.aex_id_locais_externos"
              label="Destino"
              :rules="{ required: true }"
              :IdModulo="MODULE_ID"
            />

            <RgTextArea
              ref="textArea"
              v-model="form.aex_observacao"
              :maxlength="500"
              label="Observação"
              class="textarea"
            />
          </div>
        </FormBase>
      </RgValidatorForm>
    </form>
  </section>
</template>

<script>
import FormBase from "~tokio/foundation/form-base/FormBase";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb.js";
import SearchPersonById from "./actions/SearchPersonById";
import { mapGetters } from "vuex";

import {
  RgValidatorForm,
  RgTextArea,
  RgComboboxExternalLocation,
  RgPersonCardButton,
  RgPrinterButton,
  // RgHistoryButton,
  RgDeleteButton,
  IconCheck,
} from "~tokio/primitive";

export default {
  name: "QuickAppointmentExternalScheduleRegulationForm",
  components: {
    FormBase,
    RgValidatorForm,
    RgTextArea,
    RgComboboxExternalLocation,
    RgPersonCardButton,
    RgPrinterButton,
    // RgHistoryButton,
    RgDeleteButton,
    IconCheck,
  },

  data() {
    return {
      pesId: null,
      indexPatient: null,
      activeRow: null,
      form: {
        aex_id_locais_externos: null,
        aex_observacao: "",
        patientList: [],
      },
    };
  },

  computed: {
    ...mapGetters({
      lastPersonIdSaved: "Person/Patient/GET_LAST_PERSON_ID_SAVE",
    }),

    patientSelect() {
      return this.activeRow !== null;
    },
  },

  watch: {
    "form.patientList"(pValue) {
      if (pValue && pValue.length === 1) {
        this.selectLine(pValue[0], 0);
      }
    },
  },

  created() {
    this.MODULE_ID = 2;
  },

  async mounted() {
    const existData = await this.$store.getters[
      "Appointment/Schedule/GET_SELECTED_EXTERNAL_APPOINTMENT_RESULT"
    ];

    if (existData) {
      if (this.lastPersonIdSaved) {
        const patientData = await SearchPersonById({
          pesId: this.lastPersonIdSaved,
        });
        if (patientData) {
          const index = existData.findIndex(
            (row) => Number(row.pes_id) === Number(this.lastPersonIdSaved),
          );

          existData[index].pes_nome = patientData.pes_nome;
        }
        this.$store.commit("Person/Patient/DESTROY_LAST_PERSON_ID_SAVE");
      }

      this.form.patientList = existData;
    }
  },

  beforeDestroy() {
    const validateIfExist = ValidateIfRouteExistInBreadscrumb(
      "/appointment/schedule/queue/external-schedule-form",
      this.$route.meta.breadcrumb,
    );

    if (!validateIfExist) {
      this.$store.commit(
        "Appointment/Schedule/UNSELECT_QUEUE_EXTERNAL_APPOINTMENT_DATA",
      );
    }
  },

  methods: {
    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    async saveForm() {
      if (!(await this.isFormValid())) {
        this.$toaster.warning("Verifique os Campos");
        return false;
      }

      if (!this.form.patientList) {
        this.$toaster.error("Deve existir pacientes para salvar.");
        return false;
      }

      try {
        this.$loader.start();

        const patientList = [];

        this.form.patientList.map((item, index) => {
          patientList.push({
            fil_id: item.fil_id,
            pac_id: item.pac_id,
            Paciente: item.pes_nome,
            pcc_id: item.pcc_id,
            "Prontuario Unico": item.pac_prontuario_unico,
          });
        });

        const variables = {
          aex_id_locais_externos: this.form.aex_id_locais_externos,
          aex_observacao: this.form.aex_observacao,
          idModulo: this.MODULE_ID,
          pacientes: patientList,
        };

        await this.$store.dispatch(
          "Appointment/Schedule/SAVE_APPOINTMENT_EXTERNAL_SCHEDULE",
          variables,
        );
        this.$toaster.success("Agendamento externo realizado com sucesso!");
        this.$router.push({
          name: "appointment.schedule.queue",
        });
      } catch (Err) {
        this.$toaster.error("Erro ao salvar o agendamento externo.");
      } finally {
        this.$loader.finish();
      }
    },

    selectLine(item, index) {
      this.activeRow = index;
      this.pesId = item.pes_id;
      this.indexPatient = index;
    },

    editPatient() {
      this.$store.commit("Person/Patient/SET_PERSON_ID", Number(this.pesId));
      this.$router.push({
        name: "appointment.schedule.queue.external-schedule-form.new-patient",
      });
      this.activeRow = null;
    },

    async patientPrintCard() {
      try {
        if (this.pesId) {
          const html = await this.$store.dispatch(
            "Person/Patient/PRINT_PATIENT_CARD",
            { pes_id: Number(this.pesId) },
          );

          this.$utils.print.printHtml(html);
        }
      } catch (Err) {
        this.$toaster.error(
          "Erro ao gerar a impressão da carteirinha do paciente",
        );
      }
      this.activeRow = null;
    },

    removePatient(index) {
      if (this.form.patientList && this.form.patientList.length > 1) {
        this.form.patientList.splice(index, 1);
        this.$store.commit(
          "Appointment/Schedule/SELECT_QUEUE_EXTERNAL_APPOINTMENT_DATA",
          Object.values(this.form.patientList),
        );
        this.activeRow = null;
      }
    },
  },
};
</script>
