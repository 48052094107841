<style src="./SurgerySuspendedCancelledInfo.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="surgery--cancel--info">
    <div class="info-block reason">
      <span class="label">Motivo:</span>
      <span class="text">
        {{ info.reason }}
      </span>
    </div>
    <div v-if="hasObservation" class="info-block observation">
      <span class="label">Observação:</span>
      <span class="text">
        {{ info.observation }}
      </span>
    </div>
  </section>
</template>

<script>
export default {
  name: "SurgeryCancelledInfo",
  props: {
    info: {
      required: true,
      default: () => {
        return {
          reason: "",
          observation: "",
        };
      },
    },
  },
  computed: {
    hasObservation() {
      return (
        this.info && this.info.observation && this.info.observation.length !== 0
      );
    },
  },
};
</script>
