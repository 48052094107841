<script>
import { RgTypeaheadMixin } from "~tokio/primitive/selection/mixin";
export default {
  name: "RgRemoteTypeaheadMixin",
  mixins: [RgTypeaheadMixin],
  data() {
    return {
      model: null,
      fields: [],
      filter: () => {
        return {
          value: this.inputValue,
        };
      },
      include: [],
    };
  },
  computed: {},
  mounted() {
    if (this.model && this.value) {
      this.valueChanged();
    }
  },
  methods: {
    performSearch() {
      this.model
        .search(this.filter())
        .then((response) => {
          this.showWait = false;
          this.fillItems(response.data);
        })
        .catch((pErr) => {
          this.showWait = false;
          console.error(pErr);
        });
    },
    fillItems(data) {
      this.items = [];
      data.forEach((item) => {
        this.items.push(this.formatItem(item));
      });
    },
    formatItem(item) {
      return item;
    },
    valueChanged() {
      if (!this.value) return false;
      this.model.find(this.value, this.configSearch).then((response) => {
        const item = this.formatItem(response.data);
        this.select(item);
      });
    },
  },
};
</script>
