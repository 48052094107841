<template>
  <RgBaseModal ref="Modal" aria-modal="true" :show="show" class="modal-upload">
    <div slot="header" class="header">
      <h1 class="title">{{ title }}</h1>
    </div>
    <div slot="body" class="body">
      <div class="content-modal" :class="{ hasData: existFile }">
        <input
          ref="file"
          class="input-file"
          type="file"
          :accept="acceptTypes"
          @change="uploadFile"
        />
        <label class="input-label" @click="openFileDialog">
          <IconUpload class="svg" :class="{ hasImage: existFile }" />

          <p class="text" :class="{ hasImage: existFile }">
            {{ drawInformationMessage() }}
          </p>
        </label>
      </div>

      <div class="description">{{ drawDescription() }}</div>
    </div>

    <div slot="footer" class="footer">
      <div class="control-painel">
        <RgCancelButton medium class="btn-margim" @click="cancelClose" />

        <RgConfirmButton medium type="button" @click="confirmUpload" />
      </div>
    </div>
  </RgBaseModal>
</template>

<script>
import { RgBaseModal, RgConfirmButton, RgCancelButton } from "~tokio/primitive";
import { IconUpload } from "~tokio/primitive/icon/symbols";
import { AlertError } from "../../../../../../app/billing/hospitalization-billing/common/service";
import SendFile from "./actions/SendFile";

export default {
  name: "ModalUpload",

  components: {
    RgBaseModal,
    RgConfirmButton,
    RgCancelButton,
    IconUpload,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    messageWithFile: {
      type: String,
      default: "Arquivo inserido",
    },
    messageWithoutFile: {
      type: String,
      default: "Insira um arquivo",
    },
    title: {
      type: String,
      default: "Upload",
    },
    accept: {
      type: Array,
      default: () => {
        return ["image/png", "image/jpeg", "image/jpg", "application/pdf"];
      },
    },
    maxSizeInKB: {
      type: Number,
      default: 3145728,
    },
    description: {
      type: String,
      default: "Permitidos arquivos de até 3MB ",
    },
  },

  data() {
    return {
      file: null,
    };
  },

  computed: {
    existFile() {
      return this.file !== null;
    },

    acceptTypes() {
      return this.accept.join(", ");
    },
  },

  watch: {},
  methods: {
    openFileDialog() {
      this.$refs.file.click();
    },

    drawInformationMessage() {
      return this.existFile ? this.messageWithFile : this.messageWithoutFile;
    },

    drawDescription() {
      return `${this.description}`;
    },

    confirmUpload() {
      if (this.existFile) {
        this.$loader.start("Enviando Arquivo...");

        SendFile(this.file)
          .then((result) => {
            this.$loader.finish();
            this.$emit("upload", result.data.fileInfo);
            this.close();
          })
          .catch((err) => {
            this.file = null;
            this.$loader.finish();
            this.$toaster.error("Falha ao enviar o arquivo:" + err.message);
          });
      } else {
        AlertError("Adicione um arquivo.");
      }
    },

    validateFile(pFile) {
      const hasFile = pFile !== null;

      if (!hasFile) {
        AlertError("Adicione um arquivo.");
        return false;
      }

      const hasInvalidSize = pFile.size > this.maxSizeInKB;

      if (hasInvalidSize) {
        AlertError("Arquivo com tamanho maior que o esperado");
        return false;
      }

      const fileType = pFile.type;
      const hasInvalidTypes = !this.accept.includes(fileType);

      if (hasInvalidTypes) {
        AlertError(`Formato inválido. Permitidos: ${this.acceptTypes}`);
        return false;
      }

      return true;
    },

    uploadFile(pFile) {
      const fileInUpload = this.$refs.file.files[0];

      const isValid = this.validateFile(fileInUpload);

      if (isValid) {
        this.file = fileInUpload;
      } else {
        this.$refs.file.value = null;
      }
    },

    cancelClose() {
      this.close();
    },

    close() {
      this.file = null;
      this.$refs.file.value = null;
      this.$emit("close");
    },
  },
};
</script>
