import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query searchcustomHistoryHospitalizationBedDetails(
    $ihs_id: Int!
    $inl_id: Int!
    $limit: Int
    $offset: Int
  ) {
    customHistoryHospitalizationBedDetails(
      ihs_id: $ihs_id
      inl_id: $inl_id
      limit: $limit
      offset: $offset
    ) {
      count
      rows {
        int_id
        int_numero
        nome_paciente
        int_data
        int_hora
        int_previsao_alta
        isa_id
        isa_data
        isa_hora
        igs_nome
        ild_nome
        nome_funcionario
        observacao
        ihs_observacao
        ihs_tipo_operacao
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    return data.customHistoryHospitalizationBedDetails.rows[0];
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors);
  }
};
