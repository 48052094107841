<template>
  <div class="favorite-plus-button">
    <RgButton
      :data-id="dataId"
      :disabled="disabled"
      :title="title"
      :permission="permission"
      class="button"
      @click="clicked"
    >
      <div class="iconarea">
        <IconAddExam class="svg" />
      </div>
    </RgButton>
  </div>
</template>
<script>
import RgButton from "~tokio/primitive/button/rg-button/RgButton.vue";
import RgButtonMixin from "~tokio/primitive/button/rg-button/RgButtonMixin.js";
import { IconAddExam } from "~tokio/primitive/icon/symbols";

export default {
  name: "LargeButton",
  components: { RgButton, IconAddExam },
  mixins: [RgButtonMixin],
  props: {
    backgroundColor: {
      type: String,
      default: "#53B1D8",
    },
    fontColor: {
      type: String,
      default: "#fff",
    },
  },
};
</script>
