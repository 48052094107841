import AuthRequest from "~common/request/AuthRequest";
export default async (pModuleId, pNamespaces) => {
  try {
    return AuthRequest.post(
      "/access-control/load-module-permissions-by-unit-id",
      {
        module: pModuleId,
        namespaces: pNamespaces,
      },
    );
  } catch (err) {
    throw new Error(
      `Falha ao buscar permissoes do Modulo ${pModuleId} por unidade.`,
    );
  }
};
