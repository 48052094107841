<style src="./MainHeaderBilling.scss" lang="scss" scoped></style>
<template lang="html">
  <MainHeader>
    <UserPanel slot="user-panel" />
  </MainHeader>
</template>

<script>
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import UserPanel from "./user-panel/UserPanel";

export default {
  name: "MainHeaderBilling",
  components: {
    MainHeader,
    UserPanel,
  },
};
</script>
