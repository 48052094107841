<style src="./RgSuggest.scss" lang="scss" scoped></style>
<template>
  <fieldset :class="{ activated: hasFocus }" class="rg-input--component">
    <RgFormBase :label="label" :required="isRequired">
      <div slot="right-label">
        <div
          v-show="error.length > 0"
          :data-error="`${dataId}-error`"
          class="rg-input--alert-over-positioning"
        >
          <RgValidationAlert :alert="error" class="rg-input--icon" />
        </div>
      </div>
      <div class="rg-input--base">
        <div class="rg-input--textbox-container">
          <div class="rg-input--search-icon">
            <IconSearch v-if="showIcon" class="icon-search" />
          </div>

          <div class="rg-input--side-action">
            <div v-show="false" class="rg-input--btn-calendar">
              <div class="rg-input-wait" />
            </div>
          </div>

          <div
            v-show="showCleanData"
            class="rg-input--remove-icon"
            title="Limpar"
            @click="cleanDataAct(true)"
          >
            <IconDeleteCross class="icon-remove" />
          </div>

          <input
            v-if="!mask"
            ref="input"
            key="inputWithoutMask"
            v-model="inputValue"
            v-shortkey="{
              enter: showSearch ? ['enter'] : [''],
              esc: showSearch ? ['esc'] : [''],
            }"
            v-debounce-directive="debounce"
            :data-id="dataId"
            :disabled="disableInput"
            :readonly="disableInput"
            :placeholder="placeholder"
            :tabindex="disableInput ? '-1' : tabIndex"
            :style="styles"
            :maxlength="maxlength"
            type="text"
            class="rg-input--typeahead-input"
            autocomplete="off"
            @shortkey="action"
            @focus="focusMe"
            @click="focusMe"
            @blur="validateBlur"
            @keydown.up="navigateUp"
            @keydown.down="navigateDown"
            @keydown.13="navigateChoose"
            @keydown.esc="closeSuggestion"
            @debounced="inputChanged"
          />

          <input
            v-else
            ref="input"
            key="inputMask"
            v-model="inputValue"
            v-shortkey="{
              enter: showSearch ? ['enter'] : [''],
              esc: showSearch ? ['esc'] : [''],
            }"
            v-mask="{ mask, tokens }"
            v-debounce-directive="debounce"
            :data-id="dataId"
            :disabled="disableInput"
            :readonly="disableInput"
            :placeholder="placeholder"
            :tabindex="disableInput ? '-1' : tabIndex"
            :style="styles"
            :maxlength="maxlength"
            type="text"
            class="rg-input--typeahead-input"
            autocomplete="off"
            @shortkey="action"
            @focus="focusMe"
            @click="focusMe"
            @blur="validateBlur"
            @keydown.up="navigateUp"
            @keydown.down="navigateDown"
            @keydown.13="navigateChoose"
            @keydown.esc="closeSuggestion"
            @debounced="inputChanged"
          />

          <div
            ref="box"
            class="rg-input--searchbox-result box-scroll"
            tabindex="1"
          >
            <ul
              v-show="showSearch && !escapeSearch && !disabled"
              ref="List"
              v-on:scroll.passive="handleScroll"
            >
              <slot />
              <li v-show="hasMoreValues" class="line">
                <div class="rg-input-wait" />
                Buscando mais dados...
              </li>
            </ul>
          </div>
        </div>
      </div>
    </RgFormBase>

    <ModalConfirmFieldClearing
      :show="showConfirmation"
      :message="message"
      @getYes="cleanSuggest"
      @getOut="closeConfirmation"
      @close="closeConfirmation"
    />
  </fieldset>
</template>

<script>
import { mask } from "vue-the-mask";
import { RgFormBase } from "~tokio/foundation/container";
import {
  IconSearch,
  DebounceDirective,
  IconDeleteCross,
} from "~tokio/primitive/";
import RgValidatorMixin from "~tokio/primitive/validation/RgValidatorMixin";
import RgValidationAlert from "~tokio/primitive/validation/rg-validation-alert/RgValidationAlert";
import ModalConfirmFieldClearing from "~tokio/primitive/modal/modal-confirm-field-clearing/ModalConfirmFieldClearing";

export default {
  name: "RgSuggests",
  components: {
    RgFormBase,
    IconSearch,
    RgValidationAlert,
    IconDeleteCross,
    ModalConfirmFieldClearing,
  },
  directives: {
    debounceDirective: DebounceDirective,
    mask,
  },
  mixins: [RgValidatorMixin],
  props: {
    label: {
      type: String,
      default: "",
    },
    dataId: {
      type: String,
      default: "",
    },
    tabIndex: {
      type: String,
      default: "0",
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      default: null,
    },
    min: {
      default: 0,
    },
    showIcon: {
      default: true,
    },
    mask: {
      default: null,
    },
    tokens: {
      type: Object,
      default: () => {
        return {
          "#": { pattern: /\d/ },
          X: { pattern: /[0-9a-zA-Z]/ },
          S: { pattern: /[a-zA-Z]/ },
          A: { pattern: /[a-zA-Z]/, transform: (v) => v.toLocaleUpperCase() },
          a: { pattern: /[a-zA-Z]/, transform: (v) => v.toLocaleLowerCase() },
          "!": { escape: true },
        };
      },
    },
    styles: {
      default: null,
    },
    debounce: {
      type: Number,
      default: 250,
    },
    searchOnFocus: {
      type: Boolean,
      default: false,
    },
    confirmToRemove: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: "Deseja mesmo limpar esse campo?",
    },
    maxCharBeforeResize: {
      type: Number,
      default: 60,
    },
    maxlength: {
      type: Number,
    },
  },

  data() {
    return {
      inputValue: "",
      isSearching: false,
      escapeSearch: false,
      itemSelected: false,
      hasFocus: false,
      focusIntention: false,
      showConfirmation: false,
      disableInput: this.disabled,
      total: null,
      limit: 20,
      anotherRules: {
        forceSelection: (pData, pError) => {
          if (this.rules && this.rules.forceSelection === true) {
            return this.checkSelection(pData, pError);
          }
        },
      },
    };
  },

  computed: {
    showSearch() {
      const showSearch = this.$parent.showSearch;
      return showSearch;
    },
    validValue() {
      return this.inputValue;
    },
    hasMoreValues() {
      // TODO: Change this validate for something better
      const suggestionListLimit =
        this.$parent.suggestionList &&
        this.$parent.suggestionList.length > 19 &&
        this.$parent.suggestionList.length !== this.total;

      return this.total && this.total >= this.limit && suggestionListLimit;
    },
    isRequired() {
      return this.rules && this.rules.required;
    },

    showCleanData() {
      const ret = this.itemSelected && !this.disableInput && this.inputValue;
      return ret;
    },
  },

  watch: {
    value(pValue) {
      if (!pValue) {
        this.cleanDataAct();
        this.$emit("clean");
      }

      this.inputValue = pValue;
      this.limit = 20;
      if (pValue && pValue.length > 0 && this.itemSelected) {
        this.validate();
      }

      this.setScrollTopZero();
      if (this.itemSelected) {
        this.escapeSearch = true;
      }
    },

    disabled(pValue) {
      this.disableInput = pValue;
    },
  },

  mounted() {
    // Props disabled is reactive, but when the first render start isnt working.
    // this way, is force to change the value when the first loader start
    if (this.disabled) {
      this.disableInput = false;
      this.disableInput = true;
    }
  },

  methods: {
    cleanDataAct(isClicked = false) {
      if (isClicked && this.confirmToRemove) {
        this.showConfirmation = true;
      } else {
        this.cleanSuggest();
      }
    },

    closeModalConfirmation() {
      this.showConfirmation = false;
    },

    cleanSuggest() {
      this.inputValue = "";

      this.itemSelected = false;
      this.$parent.emitSelection({ source: "" });
      this.$emit("input", this.inputValue);
      this.$emit("clear");
      this.$parent.cleanValues();
    },

    async handleScroll(event) {
      const boxHeight = this.$parent.$el.querySelectorAll("ul")[0].clientHeight;
      const boxScrollHeight = this.$parent.$el.querySelectorAll("ul")[0]
        .scrollHeight;
      const scrollPosition = this.$refs.List.scrollTop;

      const isInEnd = boxScrollHeight - scrollPosition === boxHeight;
      try {
        if (isInEnd) {
          this.limit += 20;
          this.total = this.$parent.getTotal();

          if (!this.hasMoreValues) {
            this.limit = this.total;
          }

          await this.$parent.search(this.inputValue, this.limit);
        }
      } catch (err) {
        console.error("Erro ao trazer mais dados");
      }
    },

    closeConfirmation() {
      this.showConfirmation = false;
    },

    navigateUp() {
      this.escapeSearch = false;
      const prevItem = this.$parent.selectedItemIdx - 1;
      if (prevItem > -1) this.$parent.selectedItemIdx = prevItem;
      this.scrollPositionAdjust();
    },

    navigateDown() {
      this.escapeSearch = false;
      const nextItem = this.$parent.selectedItemIdx + 1;
      if (this.$parent.suggestionList.length > nextItem)
        this.$parent.selectedItemIdx = nextItem;
      this.scrollPositionAdjust();
    },

    navigateChoose(e) {
      if (!this.$parent.suggestionList) return;
      const itemSelected = this.$parent.suggestionList[
        this.$parent.selectedItemIdx
      ];
      if (!itemSelected) return false;

      this.itemSelected = true;
      e.preventDefault();
      e.stopPropagation();
      this.$parent.selectingItemFromSuggestList(itemSelected);
      this.escapeSearch = true;
    },

    closeSuggestion() {
      this.escapeSearch = true;
    },

    focusMe() {
      this.escapeSearch = false;
      this.hasFocus = true;
      this.focusIntention = true;
      if (this.searchOnFocus) {
        this.setScrollTopZero();
        this.limit = 20;
        this.$parent.search(this.inputValue || "");
      }
    },

    resetSuggestionList() {
      this.$parent.suggestionList = [];
    },

    inputChanged(pValue) {
      if (this.inputValue === this.$parent.inputValue) return false;

      this.itemSelected = false;
      // existe um bug que faz com que o evento selected seja emitido sempre que o input muda
      // a chamada para emitSelection resolve o problema de o input ser obrigatório
      this.$parent.emitSelection({ source: {} });
      this.$emit("input", this.inputValue);
      if (this.inputValue && this.inputValue.length > this.min) {
        this.$parent.search(this.inputValue);
        this.escapeSearch = false;
      } else {
        this.$parent.suggestionList = [];
      }
    },

    getElementsToAdjustPosition() {
      const elementItem = this.$parent.$el.querySelectorAll("li")[
        this.$parent.selectedItemIdx
      ];
      const elementContainer = this.$parent.$el.querySelectorAll("ul")[0];
      if (!elementItem || !elementContainer) return { elementItem: false };

      return { elementItem, elementContainer };
    },

    scrollPositionAdjust() {
      const {
        elementItem,
        elementContainer,
      } = this.getElementsToAdjustPosition();
      if (!elementItem) return false;
      const elementPositionTop =
        elementItem.offsetTop + elementItem.offsetHeight;
      const containerHeight = elementContainer.offsetHeight;
      const containerHeightDiff = containerHeight + elementContainer.scrollTop;
      const marginBottom = elementPositionTop + elementItem.offsetHeight * 3;
      const elementTop = elementItem.offsetTop;
      const containerScrollTop = elementContainer.scrollTop;
      const marginTop = elementTop - elementItem.offsetHeight * 3;

      if (marginBottom >= containerHeightDiff) {
        elementContainer.scrollTop = marginBottom - containerHeight;
      }
      if (marginTop <= containerScrollTop) {
        elementContainer.scrollTop = marginTop;
      }
    },

    setScrollTopZero() {
      const elementContainer = this.$parent.$el.querySelectorAll("ul")[0];
      elementContainer.scrollTop = 0;
    },

    async validateBlur(e) {
      this.focusIntention = false;
      this.limit = 20;
      setTimeout(() => {
        if (!this.focusIntention) {
          this.$emit("input", this.inputValue);
          this.validate();
          this.hasFocus = false;
          this.showWait = false;
        }
      }, 50);
      await this.forceSelection(this.inputValue);
    },

    setFocus() {
      if ("input" in this.$refs) {
        this.$refs.input.focus();
      }
    },

    forceSelection(pData) {
      if (pData && !this.itemSelected) {
        if (
          this.$parent.suggestionList &&
          this.$parent.suggestionList.length === 1
        ) {
          const itemSelected = this.$parent.suggestionList[
            this.$parent.selectedItemIdx
          ];
          this.$emit("itemSelected");
          if (!itemSelected) return false;

          this.itemSelected = true;
          this.$parent.selectingItemFromSuggestList(itemSelected);
          this.escapeSearch = true;
        }
      }
    },

    checkSelection(pData, pError) {
      if (pData && !this.itemSelected) {
        pError.push("A seleção de um item é obrigatório");
        return false;
      }
      return true;
    },

    action(event) {
      switch (event.srcKey) {
        case "enter":
          this.navigateChoose(event);
          break;
        case "esc":
          this.closeSuggestion();
          break;
      }
    },
  },
};
</script>
