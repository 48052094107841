<style src="./RgTabs.scss" lang="scss" scoped></style>
<template lang="html">
  <div class="rg-tabs">
    <ul class="tab-list">
      <RgTabsItem
        v-for="(item, key) in itens"
        :key="key"
        :validated="item.validated"
        :selected="tabSelected === item"
        :granted="item.granted"
        :label="grabLabel(item)"
        @click="chooseTab(item)"
      />
    </ul>
    <div class="content">
      <div
        v-for="(item, key) in itens"
        v-show="tabSelected === item"
        :key="key"
        :class="{ 'non-granted': item.granted === false }"
      >
        <slot :name="grabSlotName(item)" />
        <div :class="{ 'cover-disabled': item.granted === false }" />
      </div>
    </div>
  </div>
</template>

<script>
import RgTabsItem from "./rg-tabs-item/RgTabsItem";
import { RgValidatorMixin } from "~tokio/primitive/validation";
export default {
  name: "RgTabs",
  components: {
    RgTabsItem,
  },
  mixins: [RgValidatorMixin],
  props: {
    itens: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data: () => {
    return {
      tabSelected: 0,
    };
  },
  computed: {
    validValue() {
      let valid = true;
      this.itens.forEach((item) => {
        if (this.isValidItem(item) === false) {
          valid = false;
        }
      });
      return valid;
    },
  },
  mounted() {
    let hasSelected = false;
    this.$children.forEach((pTabItem) => {
      if (pTabItem.selected) {
        hasSelected = true;
      }
    });
    if (hasSelected === false && this.$children.length > 0) {
      this.$children[0].chooseItem();
    }
  },
  methods: {
    chooseTab(selected) {
      this.tabSelected = selected;
    },
    grabLabel(item) {
      return item.label ? item.label : item;
    },
    grabSlotName(item) {
      return item.slot ? item.slot : "content-" + this.grabLabel(item);
    },
    isValidItem(item) {
      return !(item.validated === false);
    },
  },
};
</script>
