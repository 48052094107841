/**
 * @type String[]
 */
const SpecialProcedures = [
  "0415010012", // TRATAMENTO C/ CIRURGIAS MULTIPLAS
  "0415030013", // TRATAMENTO CIRURGICO EM POLITRAUMATIZADO
  "0415020077", //  PROCEDIMENTOS SEQUENCIAIS EM NEUROCIRURGIA
];

export default SpecialProcedures;
