<template>
  <Modulebox title="Unificar Cadastros" class="unify-patient-register">
    <div class="body">
      <div class="tooltip">
        <Tooltip>
          <IconLightBulbHelper slot="icon" />
          <div slot="content" class="content">
            <span class="title">
              Selecione o cadastro que será utilizado como base para realizar a
              unificação
            </span>
          </div>
        </Tooltip>
      </div>
      <div class="top-buttons">
        <RgLessButton
          :disabled="disableRemoveButton"
          @click="removePatientList(selectedPatient)"
        />
        <RgPersonCardButton
          :disabled="!hasSelectedPatient"
          title="Cadastro do Paciente"
          @click="patientRegister"
        />
        <RgHistoryButton
          :disabled="!hasSelectedPatient"
          title="Histórico de Atendimentos"
          @click="openModalPatientCareHistory"
        />
      </div>
      <div
        v-for="(item, index) in mutableListPatient"
        :key="item.pes_id"
        :class="{ selected: selectedPatient === index }"
        class="list-patient"
        @click="getPatient(item, index)"
      >
        <div class="icon">
          <RgTypePatient
            :pac-id="isPatient(item)"
            :sex="patientSex(item)"
            class="patient-type"
          />
        </div>

        <div class="column">
          <span
            >Nome: <b>{{ item.pes_nome }}</b></span
          >
          <span
            >Nome da Mãe: <b>{{ item.pes_mae }}</b></span
          >
        </div>

        <div class="column">
          <span
            >Data de Nascimento: <b>{{ item.pes_nascimento }}</b></span
          >
          <span
            >Prontuário Único:
            <b>{{
              (item.patient && item.patient.pac_prontuario_unico) ||
              "Não informado"
            }}</b></span
          >
        </div>

        <div class="column">
          <span
            >CPF:
            <b>{{
              (item.cpf && item.cpf.cpf_numero) || "Não informado"
            }}</b></span
          >
          <span
            >CNS:
            <b>{{
              (item.cns && item.cns.crs_numero) || "Não informado"
            }}</b></span
          >
        </div>
      </div>

      <FormBase
        v-if="hasSelectedPatient"
        title="Selecionar Dados para Unificação"
        class="unify-form"
      >
        <Tooltip class="tooltip">
          <IconLightBulbHelper slot="icon" />
          <div slot="content" class="content">
            <span class="title">
              Selecione os dados válidos que serão mantidos no cadastro base. Os
              demais serão descartados
            </span>
          </div>
        </Tooltip>

        <div class="unify-list">
          <div class="header">
            <div class="item">
              <span>Nome</span>
              <span>Data de Nascimento</span>
              <span>Nome da Mãe</span>
              <span>CNS</span>
              <span>CPF</span>
              <span>PIS</span>
            </div>
          </div>

          <div class="body">
            <div
              v-for="item in mutableListPatient"
              :key="item.pes_id"
              class="item"
            >
              <div class="data">
                <input
                  class="radio"
                  type="radio"
                  name="patientName"
                  :checked="item.pes_id === patientSelectedData.pes_id"
                  @change="radioPatientName(item.pes_nome)"
                />
                <span>{{ item.pes_nome }}</span>
              </div>

              <div class="data">
                <input
                  type="radio"
                  class="radio"
                  name="birthDate"
                  :checked="item.pes_id === patientSelectedData.pes_id"
                  @change="radioBirthDate(item.pes_nascimento)"
                />
                <span>{{ item.pes_nascimento }}</span>
              </div>

              <div class="data">
                <input
                  class="radio"
                  type="radio"
                  name="motherName"
                  :checked="item.pes_id === patientSelectedData.pes_id"
                  @change="radioMotherName(item.pes_mae)"
                />
                <span>{{ item.pes_mae }}</span>
              </div>

              <div class="data">
                <input
                  class="radio"
                  type="radio"
                  name="cnsNumber"
                  :checked="item.pes_id === patientSelectedData.pes_id"
                  :disabled="item.cns.cns_erro"
                  @change="radioCnsNumber(item.cns.crs_numero)"
                />
                <span>{{ item.cns.crs_numero || "Não informado" }}</span>
                <span>
                  <RgValidationAlert
                    v-show="item.cns.cns_erro"
                    :alert="item.cns.cns_erro"
                    class="rg-input--icon"
                  />
                </span>
              </div>

              <div class="data">
                <input
                  class="radio"
                  type="radio"
                  name="cpfNumber"
                  :value="item.cpf && item.cpf_numero"
                  :checked="item.pes_id === patientSelectedData.pes_id"
                  :disabled="item.cpf.cpf_erro"
                  @change="radioCpfNumber(item.cpf.cpf_numero)"
                />
                <span>{{ item.cpf.cpf_numero || "Não informado" }} </span>
                <span>
                  <RgValidationAlert
                    v-show="item.cpf.cpf_erro"
                    :alert="item.cpf.cpf_erro"
                    class="rg-input--icon"
                  />
                </span>
              </div>

              <div class="data">
                <input
                  class="radio"
                  type="radio"
                  name="pisNumber"
                  :checked="item.pes_id === patientSelectedData.pes_id"
                  :disabled="item.pisPasep.pis_erro"
                  @change="radioPisNumber(item.pisPasep.pis_numero)"
                />
                <span>{{ item.pisPasep.pis_numero || "Não informado" }}</span>
                <span>
                  <RgValidationAlert
                    v-show="item.pisPasep.pis_erro"
                    :alert="item.pisPasep.pis_erro"
                    class="rg-input--icon"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </FormBase>
    </div>

    <div slot="footer" class="footer">
      <RgCancelButton medium @click="cancel" />
      <RgSaveButton
        title="Confirmar"
        label="Confirmar"
        medium
        :disabled="!hasSelectedPatient"
        @click="openConfirmPatientUnification"
      />
    </div>

    <ModalPatientCareHistory
      :show="showModalPatientCareHistory"
      :patient-info="patientSelectedData || {}"
      :patient-suggest="false"
      @close="closeModalPatientCareHistory"
    />

    <ModalConfirmPatientUnification
      :show="showConfirmPatientUnification"
      :patient-info="patientSelectedData || {}"
      :data-unification="form"
      @save="saveUnification"
      @close="closeConfirmPatientUnification"
    />
  </Modulebox>
</template>

<script>
import { Modulebox, FormBase } from "~tokio/foundation";
import RgTypePatient from "$hospitalization/common/component/status/rg-type-patient/RgTypePatient";
import ModalPatientCareHistory from "$person/patient/component/modal/modal-patient-care-history/ModalPatientCareHistory";
import ModalConfirmPatientUnification from "$person/patient/component/modal/modal-confirm-patient-unification/ModalConfirmPatientUnification";
import RgValidationAlert from "~tokio/primitive/validation/rg-validation-alert/RgValidationAlert";
import Cpf from "$person/common/components/input/rg-input-cpf/utils/RgInputCpfHelper";
import RgInputSuscardHelper from "~tokio/primitive/input/rg-input-suscard/RgInputSuscardHelper";
import Pis from "$person/common/components/input/rg-input-pis/utils/RgInputPisHelper";
import { BrazilianDateToDatabase } from "~utils/date";
import { mapGetters } from "vuex";

import {
  Tooltip,
  IconLightBulbHelper,
  RgCancelButton,
  RgSaveButton,
  RgLessButton,
  RgPersonCardButton,
  RgHistoryButton,
} from "~tokio/primitive";

export default {
  name: "UnifyPatientRegister",
  components: {
    Modulebox,
    FormBase,
    Tooltip,
    IconLightBulbHelper,
    RgTypePatient,
    RgCancelButton,
    RgSaveButton,
    RgLessButton,
    RgPersonCardButton,
    RgHistoryButton,
    ModalPatientCareHistory,
    ModalConfirmPatientUnification,
    RgValidationAlert,
  },

  props: {},

  data() {
    return {
      mutableListPatient: [],
      patientSelectedData: {},
      selectedPatient: null,
      form: {
        pes_nome: null,
        pes_nascimento: null,
        pes_mae: null,
        cpf_numero: null,
        crs_numero: null,
        pis_numero: null,
        pac_prontuario_unico: null,
      },
      showModalPatientCareHistory: false,
      showConfirmPatientUnification: false,
    };
  },

  computed: {
    ...mapGetters({
      lastPersonIdSaved: "Person/Patient/GET_LAST_PERSON_ID_SAVE",
    }),

    hasSelectedPatient() {
      return this.selectedPatient !== null;
    },
    disableRemoveButton() {
      return !this.hasSelectedPatient || this.mutableListPatient.length <= 2;
    },
  },

  watch: {},

  destroyed() {
    this.$store.commit("Person/Patient/DESTROY_LAST_PERSON_ID_SAVE");
  },

  async mounted() {
    this.mutableListPatient = this.$store.getters[
      "Person/Patient/GET_SELECTED_PATIENTS_UNIFY"
    ];
    if (this.lastPersonIdSaved) {
      this.updatePatientEdited(this.lastPersonIdSaved);
    }
    this.validateDocuments();
  },

  methods: {
    radioPatientName(pValue) {
      this.form.pes_nome = pValue || null;
    },

    radioBirthDate(pValue) {
      this.form.pes_nascimento = pValue || null;
    },

    radioMotherName(pValue) {
      this.form.pes_mae = pValue || null;
    },

    radioCnsNumber(pValue) {
      this.form.crs_numero = pValue || null;
    },

    radioCpfNumber(pValue) {
      this.form.cpf_numero = pValue || null;
    },

    radioPisNumber(pValue) {
      this.form.pis_numero = pValue || null;
    },

    setColumnValue(item, index) {
      return item.concat(index);
    },

    getPatient(pItem, pIndex) {
      const isSameLine = this.selectedPatient === pIndex;
      if (isSameLine) {
        this.selectedPatient = null;
        this.patientSelectedData = null;
        this.form.pac_prontuario_unico = null;
        this.$emit("getLine", null);
      } else {
        this.selectedPatient = pIndex;
        this.patientSelectedData = pItem;
        this.form.pac_prontuario_unico =
          pItem.patient && pItem.patient.pac_prontuario_unico;
        this.setForm();
      }
    },

    setForm() {
      this.radioPatientName(this.patientSelectedData.pes_nome);
      this.radioBirthDate(this.patientSelectedData.pes_nascimento);
      this.radioMotherName(this.patientSelectedData.pes_mae);
      this.radioCnsNumber(this.patientSelectedData.cns.crs_numero);
      this.radioCpfNumber(this.patientSelectedData.cpf.cpf_numero);
      this.radioPisNumber(this.patientSelectedData.pisPasep.pis_numero);
    },

    async saveUnification() {
      try {
        this.$loader.start("Unificando prontuários...");
        const variables = [];

        const basePatient = {
          pes_id: this.patientSelectedData.pes_id,
          pes_nome: this.form.pes_nome,
          pes_nascimento: BrazilianDateToDatabase(this.form.pes_nascimento),
          pes_mae: this.form.pes_mae,
          cns: {
            crs_id: this.patientSelectedData.cns
              ? this.patientSelectedData.cns.crs_id
              : null,
            crs_numero: this.form.crs_numero,
          },
          cpf: {
            cpf_id: this.patientSelectedData.cpf
              ? this.patientSelectedData.cpf.cpf_id
              : null,
            cpf_numero: this.form.cpf_numero,
          },
          pisPasep: {
            pis_id: this.patientSelectedData.pisPasep
              ? this.patientSelectedData.pisPasep.pis_id
              : null,
            pis_numero: this.form.pis_numero,
          },
          pac_id: this.patientSelectedData.patient
            ? this.patientSelectedData.patient.pac_id
            : null,
          basePatient: true,
        };

        variables.push(basePatient);

        const filterNotBasePatients = (patient) =>
          patient.pes_id !== this.patientSelectedData.pes_id;

        this.mutableListPatient.filter(filterNotBasePatients).map((patient) => {
          const notBasePatient = {
            pes_id: patient.pes_id,
            cns: {
              crs_id: patient.cns ? patient.cns.crs_id : null,
              crs_numero: null,
            },
            cpf: {
              cpf_id: patient.cpf ? patient.cpf.cpf_id : null,
              cpf_numero: null,
            },
            pisPasep: {
              pis_id: patient.pisPasep ? patient.pisPasep.pis_id : null,
              pis_numero: null,
            },
            basePatient: false,
          };
          variables.push(notBasePatient);
        });

        const response = await this.$store.dispatch(
          "Person/Patient/UNIFY_PATIENT",
          {
            patients: variables,
          },
        );

        const hasSuccess = response && response.success;

        if (hasSuccess) {
          this.$toaster.success("Operação realizada com sucesso!");
          this.closeConfirmPatientUnification();

          this.$store.commit("Person/Patient/RESET_FILTER_UNIFY_PATIENT");
          this.$store.commit("Person/Patient/UNSELECT_PATIENTS_UNIFY");

          this.$router.push({ name: `person.patient.unify` });
        } else {
          this.$toaster.error(response.message);
        }
      } catch (pErr) {
        this.$toaster.error(pErr.message || pErr);
      } finally {
        this.$loader.finish();
      }
    },

    validateDocuments() {
      let pErrs = [];
      this.mutableListPatient.forEach((patient) => {
        pErrs = [];
        const cpfNUmber = patient.cpf && patient.cpf.cpf_numero;
        if (!this.validateCpf(cpfNUmber, pErrs)) {
          patient.cpf.cpf_erro = pErrs;
        }
        pErrs = [];
        const cnsNUmber = patient.cns && patient.cns.crs_numero;
        if (!this.validateCns(cnsNUmber, pErrs)) {
          patient.cns.cns_erro = pErrs;
        }
        pErrs = [];
        const pisNumber = patient.pisPasep && patient.pisPasep.pis_numero;
        if (!this.validatePis(pisNumber, pErrs)) {
          patient.pisPasep.pis_erro = pErrs;
        }
      });
    },

    validateCpf(pValue, pErrs) {
      if (!pValue) return true;
      return Cpf.checkDigit(pValue, pErrs);
    },

    validateCns(pValue, pErrs) {
      if (!pValue) return true;
      return RgInputSuscardHelper.checkDigit(pValue, pErrs);
    },

    validatePis(pValue, pErrs) {
      if (!pValue) return true;
      return Pis.checkDigit(pValue, pErrs);
    },

    validateAllDocuments() {
      if (this.form.cpf_numero) {
        const cpfFilter = (patient) =>
          patient.cpf && patient.cpf.cpf_numero === this.form.cpf_numero;
        const cpfSelected = this.mutableListPatient.filter(cpfFilter);

        const hasCpfError =
          cpfSelected &&
          cpfSelected.length > 0 &&
          cpfSelected[0].cpf &&
          cpfSelected[0].cpf.cpf_erro;

        if (hasCpfError) {
          this.$toaster.warning("Verifique os campos");
          return false;
        }
      }

      if (this.form.crs_numero) {
        const crsFilter = (patient) =>
          patient.cns && patient.cns.crs_numero === this.form.crs_numero;
        const cnsSelected = this.mutableListPatient.filter(crsFilter);

        const hasCnsError =
          cnsSelected &&
          cnsSelected.length > 0 &&
          cnsSelected[0].cns &&
          cnsSelected[0].cns.cns_erro;

        if (hasCnsError) {
          this.$toaster.warning("Verifique os campos");
          return false;
        }
      }

      if (this.form.pis_numero) {
        const pisFilter = (patient) =>
          patient.pisPasep &&
          patient.pisPasep.pis_numero === this.form.pis_numero;
        const pisSelected = this.mutableListPatient.filter(pisFilter);

        const hasPisError =
          pisSelected &&
          pisSelected.length > 0 &&
          pisSelected[0].pisPasep &&
          pisSelected[0].pisPasep.pis_erro;

        if (hasPisError) {
          this.$toaster.warning("Verifique os campos");
          return false;
        }
      }
      return true;
    },

    patientRegister() {
      const patientId = this.patientSelectedData.pes_id;
      this.$store.commit("Person/Patient/SET_PERSON_ID", patientId);
      this.$store.commit("Person/Patient/SET_LAST_PERSON_ID_SAVE", patientId);

      const routeName = this.$route.name;

      this.$router.push({
        name: `${routeName}.patient-record`,
      });
    },

    removePatientList(pIndex) {
      this.mutableListPatient.splice(pIndex, 1);
      this.selectedPatient = null;
    },

    isPatient(pItem) {
      return pItem?.patient?.pac_id ? pItem.patient.pac_id : 0;
    },

    patientSex(pItem) {
      return pItem?.pes_id_sexos ? pItem.pes_id_sexos : null;
    },

    openModalPatientCareHistory() {
      this.showModalPatientCareHistory = true;
    },

    closeModalPatientCareHistory() {
      this.showModalPatientCareHistory = false;
    },

    openConfirmPatientUnification() {
      if (!this.validateAllDocuments()) return;
      this.showConfirmPatientUnification = true;
    },

    closeConfirmPatientUnification() {
      this.showConfirmPatientUnification = false;
    },

    cancel() {
      this.$router.push({ name: "person.patient.unify" });
    },

    async updatePatientEdited(pPersonId) {
      try {
        if (pPersonId) {
          this.$loader.start();
          const data = await this.$store.dispatch(
            "Person/Patient/GET_PATIENT_BY_PESID_FOR_UNIFY",
            { pesId: pPersonId, getRows: true },
          );

          const index = this.mutableListPatient.findIndex(
            (item) => item.pes_id === data.pes_id,
          );

          if (index !== -1) {
            const arrayAux = this.mutableListPatient;
            this.mutableListPatient = [];

            arrayAux[index] = data;

            this.mutableListPatient = arrayAux;
          }

          this.$store.commit("Person/Patient/DESTROY_LAST_PERSON_ID_SAVE");
        }
      } catch (Err) {
        this.$toaster.error(Err);
      } finally {
        this.$loader.finish();
      }
    },
  },
};
</script>
