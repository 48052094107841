import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import NotificationOnHomologation from "$notifications/view/NotificationOnHomologation";

export default {
  path: "/register/configuration/notification-on-homologation",
  name: "register.configuration.notification_on_homologation",
  components: {
    default: NotificationOnHomologation,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Configuração", link: "/home" },
      { label: "Notificações em Homologação" },
    ],
  },
};
