<style
  src="./RgContentHospitalizationBillingVasectomy.scss"
  lang="scss"
  scoped
></style>
<template lang="html">
  <div class="rg-content-hospitalization-billing-vasectomy">
    <div class="team-info">
      <RgContentHospitalizationTeam
        :procedure="procedure"
        :required-documents="requiredDocuments"
        @setProfessionalList="setProfissionalList"
      />
    </div>
    <div class="vasectomy-info">
      <RgTitle title="Vasectomia" />
      <form class="form">
        <RgValidatorForm ref="validator">
          <div class="row">
            <RgInputMasked
              v-model="vasectomy.ilv_qtd_filhos"
              :rules="{ required: true }"
              class="col-3"
              label="Quantidade de Filhos: "
              placeholder="0"
              mask="##"
            />

            <RgComboboxInstructionLevel
              v-model="vasectomy.ilv_id_internacoes_grau_instrucoes"
              :rules="{ required: true }"
              label="Grau de Instrução:"
              class="col-3"
            />

            <RgComboboxCid
              v-model="vasectomy.ilv_codigo_cid10"
              :rules="{ required: true }"
              :procedure="procedure.iap_codigo_procedimentos"
              class="col-4"
              label="CID:"
            />
          </div>

          <div class="contraceptive-methods-info row">
            <RgComboboxContraceptiveMethod
              v-model="vasectomy.ilv_id_internacoes_metodos_contraceptivos1"
              :rules="{ required: true }"
              label="Método Contraceptivo 1:"
              class="col-4"
            />

            <button
              :disabled="!vasectomy.professionalTeamList.length > 0"
              type="button"
              class="btn btn-success col-2"
              @click="confirm"
            >
              Confimar
            </button>

            <button class="col-2 btn btn-default" @click.stop.prevent="close">
              Fechar
            </button>
          </div>
        </RgValidatorForm>
      </form>
    </div>
  </div>
</template>

<script>
import mask from "vue-the-mask";
import { RgValidatorForm, RgTitle, RgInputMasked } from "~tokio/primitive";
import RgComboboxInstructionLevel from "../../../combobox/rg-combobox-instruction-level/RgComboboxInstructionLevel";
import RgComboboxContraceptiveMethod from "../../../combobox/rg-combobox-contraceptive-method/RgComboboxContraceptiveMethod";
import RgContentHospitalizationTeam from "$billing/hospitalization-billing/common/component/container/content-hospitalization-team/RgContentHospitalizationTeam";
import { RgComboboxCid } from "$billing/common";
import { ValidateTeamMembers } from "../../../../service";

export default {
  name: "RgContentHospitalizationBillingVasectomy",
  components: {
    RgValidatorForm,
    RgInputMasked,
    RgComboboxInstructionLevel,
    RgComboboxContraceptiveMethod,
    RgContentHospitalizationTeam,
    RgTitle,
    RgComboboxCid,
  },
  directives: {
    mask,
  },
  props: {
    procedure: {
      type: Object,
      default: () => {},
    },
    requiredDocuments: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      vasectomy: {
        type: "vasectomy",
        ilv_qtd_filhos: 0,
        ilv_id_internacoes_grau_instrucoes: "",
        ilv_id_internacoes_metodos_contraceptivos1: "",
        ilv_codigo_cid10: "",
        ilv_gestacao_alto_risco: 0,
        professionalTeamList: [],
      },
      professionalCaptionRaw: "pes_nome",
    };
  },
  mounted() {
    if (this.procedure.additionalData[0]) {
      this.vasectomy = this.procedure.additionalData[0];
    }
  },
  methods: {
    async confirm(event) {
      if (await this.validateForm()) {
        this.$emit("onFinish", this.vasectomy);
      }
    },
    close() {
      this.$emit("close");
    },
    setProfissionalList(event) {
      this.vasectomy.professionalTeamList = event;
    },
    async validateForm() {
      const valid = await this.$refs.validator.validate();
      // if (!this.validateTeamMembers()) valid = false
      return valid;
    },
    validateTeamMembers() {
      return ValidateTeamMembers(this.vasectomy.professionalTeamList);
    },
  },
};
</script>
