import toastr from "toastr";

export class Toast {
  constructor(options) {
    this._options = {
      closeButton: true,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: "toast-bottom-right",
      preventDuplicates: false,
      showDuration: "300",
      hideDuration: "1000",
      timeOut: "5000",
      extendedTimeOut: "1000",
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
    };
    this._toastr = toastr;

    this._applyOptions(options);
  }

  /**
   *
   * @param {String} message Mensagem para exbir
   * @param {String} title Titulo da Mensagem
   * @param {Object} options EX: {
   *  'closeButton': true,
      'debug': false,
      'newestOnTop': false,
      'progressBar': true,
      'positionClass': 'toast-bottom-right',
      'preventDuplicates': false,
      'showDuration': '300',
      'hideDuration': '1000',
      'timeOut': '5000',
      'extendedTimeOut': '1000',
      'showEasing': 'swing',
      'hideEasing': 'linear',
      'showMethod': 'fadeIn',
      'hideMethod': 'fadeOut'}
   */
  error(message, title, options = {}) {
    this._toastr.error(message, title, options);
  }

  /**
   *
   * @param {String} message Mensagem para exbir
   * @param {String} title Titulo da Mensagem
   * @param {Object} options EX: {
   *  'closeButton': true,
      'debug': false,
      'newestOnTop': false,
      'progressBar': true,
      'positionClass': 'toast-bottom-right',
      'preventDuplicates': false,
      'showDuration': '300',
      'hideDuration': '1000',
      'timeOut': '5000',
      'extendedTimeOut': '1000',
      'showEasing': 'swing',
      'hideEasing': 'linear',
      'showMethod': 'fadeIn',
      'hideMethod': 'fadeOut'}
   */
  info(message, title, options = {}) {
    this._toastr.info(message, title, options);
  }

  /**
   *
   * @param {String} message Mensagem para exbir
   * @param {String} title Titulo da Mensagem
   * @param {Object} options EX: {
   *  'closeButton': true,
      'debug': false,
      'newestOnTop': false,
      'progressBar': true,
      'positionClass': 'toast-bottom-right',
      'preventDuplicates': false,
      'showDuration': '300',
      'hideDuration': '1000',
      'timeOut': '5000',
      'extendedTimeOut': '1000',
      'showEasing': 'swing',
      'hideEasing': 'linear',
      'showMethod': 'fadeIn',
      'hideMethod': 'fadeOut'}
   */
  success(message, title, options = {}) {
    this._toastr.success(message, title, options);
  }

  warning(message, title, options = {}) {
    this._toastr.warning(message, title, options);
  }

  _applyOptions(options) {
    this._toastr.options = Object.assign(this._options, options || {});
  }

  options(options) {
    this._toastr.options = Object.assign(this._options, options || {});
  }
}

export const toast = new Toast();
