<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchInstructionLevel from "./action/SearchInstructionLevel";

export default {
  name: "RgComboboxInstructionLevel",
  mixins: [RgComboboxMixin],
  data: () => {
    return {};
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];
      const data = await SearchInstructionLevel();
      data.forEach((item) => {
        this.mData.push({
          value: item.igi_id,
          text: item.igi_descricao,
        });
      });
    },
  },
};
</script>
