<style src="./RgComboboxRespiration.scss" lang="scss"></style>
<script>
import { RgRemoteComboboxMixin } from "~tokio/primitive";
import SurgeryRespirationModel from "$surgery-center/surgery/model/SurgeryRespirationModel";

export default {
  name: "RgComboboxRespiration",
  mixins: [RgRemoteComboboxMixin],
  data: () => {
    return {
      model: SurgeryRespirationModel,
    };
  },
  methods: {
    formatItem(pData) {
      return {
        value: pData.rsp_id,
        text: pData.rsp_nome,
      };
    },
  },
};
</script>
