import AuthRequest from "~common/request/AuthRequest";
import Store from "@/store";

export default (pProcedure) => {
  const patientInfo = Store.getters["Billing/Billing/GET_ALL_INFO"];
  pProcedure.bof_cns = patientInfo.bof_cns;
  pProcedure.bof_sexo = patientInfo.bof_sexo;
  return new Promise((resolve, reject) => {
    AuthRequest.post(
      "/billing/bulletin-billing/create-procedure-employee-billing",
      pProcedure,
    )
      .then((pResult) => {
        return resolve(pResult);
      })
      .catch((pError) => {
        return reject(pError);
      });
  });
};
