import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async (variables) => {
  return AuthLegacyRequest.post(
    "/principais/controller-plano-saude/combo-box-planos-de-saude-por-unidade-saude",
    variables,
  )
    .then(({ data }) => {
      data.dados.data = data.dados.recordSet;

      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
