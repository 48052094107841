import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation CreatePatientForPerson(
    $pes_id: Int!
    $withPatientAppointment: Boolean!
    $withPatientExam: Boolean!
    $withPatientHospitalization: Boolean!
    $withPatientEmergency: Boolean!
  ) {
    CreatePatientForPerson(pes_id: $pes_id) {
      pac_id
      pac_prontuario_unico
      pac_ativo
      patient_exam @include(if: $withPatientExam) {
        pxe_id
        pxe_id_pacientes
      }
      patient_appointment @include(if: $withPatientAppointment) {
        pcc_id_pacientes
        pcc_id
        pcc_ativo
      }
      patient_hospitalization @include(if: $withPatientHospitalization) {
        pci_id_pacientes
        pci_id
        pci_ativo
      }
      patient_emergency @include(if: $withPatientEmergency) {
        pce_id_pacientes
        pce_id
        pce_ativo
      }
    }
  }
`;

const CreatePatient = async (store, data) => {
  const variables = {
    pes_id: data.pes_id,
    withPatientAppointment: data.withPatientAppointment || false,
    withPatientExam: data.withPatientExam || false,
    withPatientHospitalization: data.withPatientHospitalization || false,
    withPatientEmergency: data.withPatientEmergency || false,
  };

  try {
    const { data } = await GraphQLClient.mutate({ mutation, variables });
    return data.CreatePatientForPerson;
  } catch (err) {
    const jsonResponse = JSON.parse(JSON.stringify(err));
    return Promise.reject(jsonResponse.graphQLErrors[0].message);
  }
};

export default CreatePatient;
