import { GraphQLClient, gql } from "@/common/request/GraphQLClient";
import { BrazilianDateFormat } from "~utils/date";

const query = gql`
  query GetIndividualForm($pab_id: Int, $pab_ativo: Boolean) {
    SearchPatientBasicAttention(pab_id: $pab_id, pab_ativo: $pab_ativo) {
      rows {
        pab_id
        pab_id_cabecalho_fichas
        pab_data_atendimento
        pab_id_microareas
        pab_fora_area
        pab_mae_desconhecida
        pab_pai_desconhecido
        pab_data_naturalizacao
        pab_portaria_naturalizacao
        pab_data_entrada_brasil
        pab_frequenta_escola_creche
        pab_celular
        pab_cuidador_tradicional
        pab_grupo_comunitario
        pab_possui_plano_saude
        pab_comunidade_tradicional
        pab_id_situacao_mercado_trabalho
        pab_id_grau_parentesco
        pab_id_escolaridade_atencao_basica
        pab_id_orientacao_sexual
        pab_id_ocupacoes_cbo
        pab_id_etnias
        pab_id_genero_cidadao
        pab_nome_mae
        pab_nome_pai
        pab_nome
        pab_nome_social
        pab_id_sexos
        pab_id_nacionalidades
        pab_nascimento
        pab_email
        pab_ativo
        pab_id_ab_etnias
        pab_id_municipio_nascimento
        pab_responsavel_familiar
        pab_numero_pis
        pab_documento
        pab_documento_responsavel
        city {
          state {
            est_id
          }
        }
        streetSituation {
          sru_id
          sru_beneficio
          sru_visita_familiar
          sru_situacao_de_rua
          sru_instituicao_acompanha
          sru_referencia_familiar
          streetSituationFoodOrigin {
            soa_id_origem_alimentacao
          }
          streetSituationHygiene {
            srh_id_higiene_pessoal
          }
          sru_id_tempo_rua
          sru_id_alimentacao_dia
        }
        healthSituation {
          ssa_id
          ssa_id_peso
          ssa_causa_internacao
          ssa_gestante
          ssa_fumante
          ssa_usa_alcool
          ssa_usa_outras_drogas
          ssa_hipertensao_arterial
          ssa_diabetes
          ssa_avc_derrame
          ssa_infarto
          ssa_hanseniase
          ssa_tuberculose
          ssa_cancer
          ssa_tratamento_psiquiatra
          ssa_acamado
          ssa_domiciliado
          ssa_praticas_integrativas
          ssa_condicoes_saude_1
          ssa_condicoes_saude_2
          ssa_condicoes_saude_3
          ssa_plantas_medicinais
          heartDiseaseSituation {
            sdc_id_doenca_cardiaca
          }
          kidneyDiseaseSituation {
            sdr_id_doenca_renal
          }
          respiratoryDiseaseSituation {
            ssr_id_doenca_respiratoria
          }
        }
        existCitizenRegister {
          scc_id
          scc_data_obito
          scc_numero_obito
          scc_id_motivo_saida_cadastro
        }
        pacientDeficient {
          pfe_id_deficiencia
        }
        pacientChildSupport {
          ppi_id
          ppi_id_apoio_a_crianca
        }
        transferredFrom {
          tfn_id
          tfn_data_resposta
        }
        transferRequests {
          tfn_id
          tfn_data_resposta
        }
      }
      count
    }
  }
`;

const convertBooleanInt = (value) => {
  if (typeof value === "boolean") {
    return value ? 1 : 0;
  }
  return null;
};

export default async (pContext, pFilter) => {
  try {
    const variables = { ...pFilter, pab_ativo: true };
    const { data } = await GraphQLClient.query({
      query,
      fetchPolicy: "no-cache",
      variables,
    });
    const result = data.SearchPatientBasicAttention.rows[0];

    result.pab_data_atendimento = BrazilianDateFormat(
      result.pab_data_atendimento,
    );
    result.pab_nascimento = BrazilianDateFormat(result.pab_nascimento);
    result.pab_data_naturalizacao = BrazilianDateFormat(
      result.pab_data_naturalizacao,
    );
    result.pab_data_entrada_brasil = BrazilianDateFormat(
      result.pab_data_entrada_brasil,
    );

    if (result.pacientDeficient.length > 0) {
      result.pacientDeficient = result.pacientDeficient.map((item) => {
        return item.pfe_id_deficiencia;
      });
    }

    if (result.healthSituation.heartDiseaseSituation.length > 0) {
      result.healthSituation.heartDiseaseSituation = result.healthSituation.heartDiseaseSituation.map(
        (item) => {
          return item.sdc_id_doenca_cardiaca;
        },
      );
    }

    if (result.healthSituation.kidneyDiseaseSituation.length > 0) {
      result.healthSituation.kidneyDiseaseSituation = result.healthSituation.kidneyDiseaseSituation.map(
        (item) => {
          return item.sdr_id_doenca_renal;
        },
      );
    }

    if (result.healthSituation.respiratoryDiseaseSituation.length > 0) {
      result.healthSituation.respiratoryDiseaseSituation = result.healthSituation.respiratoryDiseaseSituation.map(
        (item) => {
          return item.ssr_id_doenca_respiratoria;
        },
      );
    }

    if (result.existCitizenRegister) {
      result.existCitizenRegister.scc_data_obito = BrazilianDateFormat(
        result.existCitizenRegister.scc_data_obito,
      );
    } else {
      result.existCitizenRegister = {};
    }

    if (result.streetSituation) {
      if (result.streetSituation.streetSituationFoodOrigin.length > 0) {
        result.streetSituation.foodOrigin = result.streetSituation.streetSituationFoodOrigin.map(
          (item) => {
            return item.soa_id_origem_alimentacao;
          },
        );
        delete result.streetSituation.streetSituationFoodOrigin;
      }

      if (result.streetSituation.streetSituationHygiene.length > 0) {
        result.streetSituation.personalHygiene = result.streetSituation.streetSituationHygiene.map(
          (item) => {
            return item.srh_id_higiene_pessoal;
          },
        );
        delete result.streetSituation.streetSituationHygiene;
      }
      result.streetSituation.sru_situacao_de_rua = convertBooleanInt(
        result.streetSituation.sru_situacao_de_rua,
      );
      result.streetSituation.sru_beneficio = convertBooleanInt(
        result.streetSituation.sru_beneficio,
      );
      result.streetSituation.sru_referencia_familiar = convertBooleanInt(
        result.streetSituation.sru_referencia_familiar,
      );
    } else {
      result.streetSituation = {
        sru_situacao_de_rua: null,
        sru_beneficio: null,
        sru_referencia_familiar: null,
        sru_visita_familiar: "",
        sru_instituicao_acompanha: "",
        sru_id_tempo_rua: null,
        sru_id_alimentacao_dia: null,
        foodOrigin: [],
        personalHygiene: [],
      };
    }

    if (!result.pacientChildSupport)
      result.pacientChildSupport = { ppi_id_apoio_a_crianca: null };

    result.pab_fora_area = convertBooleanInt(result.pab_fora_area);
    result.pab_mae_desconhecida = convertBooleanInt(
      result.pab_mae_desconhecida,
    );
    result.pab_pai_desconhecido = convertBooleanInt(
      result.pab_pai_desconhecido,
    );
    result.pab_frequenta_escola_creche = convertBooleanInt(
      result.pab_frequenta_escola_creche,
    );
    result.pab_cuidador_tradicional = convertBooleanInt(
      result.pab_cuidador_tradicional,
    );
    result.pab_grupo_comunitario = convertBooleanInt(
      result.pab_grupo_comunitario,
    );
    result.pab_possui_plano_saude = convertBooleanInt(
      result.pab_possui_plano_saude,
    );
    result.pab_responsavel_familiar = convertBooleanInt(
      result.pab_responsavel_familiar,
    );
    result.pab_status_recusa = convertBooleanInt(result.pab_status_recusa);
    result.healthSituation.ssa_fumante = convertBooleanInt(
      result.healthSituation.ssa_fumante,
    );
    result.healthSituation.ssa_usa_alcool = convertBooleanInt(
      result.healthSituation.ssa_usa_alcool,
    );
    result.healthSituation.ssa_usa_outras_drogas = convertBooleanInt(
      result.healthSituation.ssa_usa_outras_drogas,
    );
    result.healthSituation.ssa_hipertensao_arterial = convertBooleanInt(
      result.healthSituation.ssa_hipertensao_arterial,
    );
    result.healthSituation.ssa_diabetes = convertBooleanInt(
      result.healthSituation.ssa_diabetes,
    );
    result.healthSituation.ssa_avc_derrame = convertBooleanInt(
      result.healthSituation.ssa_avc_derrame,
    );
    result.healthSituation.ssa_infarto = convertBooleanInt(
      result.healthSituation.ssa_infarto,
    );
    result.healthSituation.ssa_hanseniase = convertBooleanInt(
      result.healthSituation.ssa_hanseniase,
    );
    result.healthSituation.ssa_tuberculose = convertBooleanInt(
      result.healthSituation.ssa_tuberculose,
    );
    result.healthSituation.ssa_cancer = convertBooleanInt(
      result.healthSituation.ssa_cancer,
    );
    result.healthSituation.ssa_acamado = convertBooleanInt(
      result.healthSituation.ssa_acamado,
    );
    result.healthSituation.ssa_domiciliado = convertBooleanInt(
      result.healthSituation.ssa_domiciliado,
    );
    result.healthSituation.ssa_tratamento_psiquiatra = convertBooleanInt(
      result.healthSituation.ssa_tratamento_psiquiatra,
    );
    result.healthSituation.ssa_praticas_integrativas = convertBooleanInt(
      result.healthSituation.ssa_praticas_integrativas,
    );

    return result;
  } catch (err) {
    console.log(err);
    return err.graphQLErrors;
  }
};
