<script>
import { RgRemoteComboboxMixin } from "~tokio/primitive";
import GetUnitHealthByBedType from "./action/GetUnitHealthByBedType";

export default {
  name: "RgComboboxHospitalizationUnithealth",
  mixins: [RgRemoteComboboxMixin],
  props: {
    label: {
      default: "Unidade de Saúde",
    },
    bedTypeId: {
      type: Number,
      default: 0,
    },
    inlId: {
      type: Number,
      default: 0,
    },
    fromMapBed: {
      type: Boolean,
      default: false,
    },
    currentSituation: {
      type: String,
      default: "VAZIO",
    },
    permission: {
      type: Number,
      default: null,
    },
    unsId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  watch: {
    bedTypeId(pValue, pPreviously) {
      const isDiferentValue = pValue !== pPreviously;
      if (isDiferentValue && this.bedTypeId) {
        this.getDataFromRemote();
      }
    },
    inlId(pValue, pPreviously) {
      const isDiferentValue = pValue !== pPreviously;
      if (isDiferentValue && this.inlId && this.fromMapBed) {
        this.getDataFromRemote();
      }
    },
  },
  methods: {
    doSearch() {
      const variables = {
        situation: this.currentSituation,
        itl_id: this.bedTypeId,
        inl_id: this.inlId,
        uns_id: this.unsId,
        permission: this.permission,
      };
      return GetUnitHealthByBedType(variables);
    },
    formatItem(item) {
      return {
        value: item.uns_id,
        text: `${item.uns_nome}`,
      };
    },
    blur() {
      this.validate();
      this.$emit("blur");
    },
  },
};
</script>
