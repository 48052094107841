import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query Documents {
    documents {
      rows {
        doc_id
        doc_nome
      }
    }
  }
`;

export default async ({ commit }) => {
  const { data } = await GraphQLClient.query({ query }).catch((err) => {
    console.error(err);
  });
  commit("DOCUMENTS_LIST_TYPE", { list: data.documents.rows });
};
