import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query Changelog($ave_nome_versao: String) {
    changelog(ave_nome_versao: $ave_nome_versao) {
      rows {
        ave_id
        ave_nome_versao
        ave_data_versao
        ave_descricao
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({ query, variables });

    return data.changelog.rows;
  } catch (err) {
    return Promise.reject(err.graphQLErrors);
  }
};
