const generateSource = (pType, pData) => {
  if (pType === "xlsx") {
    return `data:application/xlsx;charset=utf-8;base64,${pData}`;
  }
  return `data:text/${pType};charset=utf-8, ${encodeURI(pData)}`;
};

const DownloadFile = (pData, pType, pFileName) => {
  try {
    const source = generateSource(pType, pData);
    const fileName = `${pFileName}.${pType}`;
    const link = document.createElement("a");

    link.setAttribute("href", source);
    link.setAttribute("download", fileName);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (pErr) {
    console.log("DownloadFile: ", pErr);
    throw new Error("Problemas ao gerar o download");
  }
};

export default DownloadFile;
