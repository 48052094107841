<style lang="scss" scoped>
table {
  .report-column-1 {
    width: 250px;
  }
  .report-column-2 {
    width: 100px;
  }
  .report-column-3 {
    width: 100px;
  }
  .report-column-4 {
    width: 100px;
  }
  text-align: left;
  border-collapse: collapse;

  th {
    padding-bottom: 5px;
  }
  td {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
</style>
<template>
  <div>
    <div class="row filter-fields">
      <RgComboboxUnithealth v-model="form.uns_id" />
    </div>

    <div class="row filter-fields">
      <RgInputMasked
        v-model="form.presentation"
        :mask="['BM/DDDD']"
        :tokens="tokens"
        :rules="{ fn: validatePeriod }"
        label="Apres.:"
        class="col-12"
      />
    </div>

    <div class="row filter-fields">
      <RgTypeaheadHospitalizationBedSpecialty
        ref="bedSpecialty"
        v-model="form.specialty"
        label="Especialidade do Leito:"
      />
    </div>

    <div class="row filter-fields">
      <RgRadioHospitalizationFilterStatusOption
        v-model="form.status"
        :aih="true"
        class="col-12"
      />
    </div>
  </div>
</template>

<script>
import { isEmpty } from "lodash";
import { RgInputMasked } from "~tokio/primitive";
import RgComboboxUnithealth from "$billing/common/component/rg-combobox-unithealth/RgComboboxUnithealth";
import {
  ValidatePeriodBilling,
  MountPresentation,
} from "../../../common/service/";
import RgRadioHospitalizationFilterStatusOption from "../../../common/component/radio/rg-radio-hospitalization-filter-status-option/RgRadioHospitalizationFilterStatusOption";
import RgTypeaheadHospitalizationBedSpecialty from "../../../common/component/suggest/rg-typeahead-hospitalization-bed-specialty/RgTypeaheadHospitalizationBedSpecialty";

export default {
  name: "AihPerSpecialty",
  components: {
    RgInputMasked,
    RgComboboxUnithealth,
    RgRadioHospitalizationFilterStatusOption,
    RgTypeaheadHospitalizationBedSpecialty,
  },
  data() {
    return {
      resultTitle: "AIH por Especialidade",
      form: {
        uns_id: 0,
        specialty: "",
        presentation: "",
        status: "0",
      },
      tokens: {
        B: { pattern: /^([0-1])$/ },
        M: { pattern: /^([0-9])$/ },
        D: { pattern: /\d/ },
      },
    };
  },
  computed: {
    printReportCss() {
      return (
        ".report-column-1-print { width: 330px; }" +
        ".report-column-2-print { width:  90px; }" +
        ".report-column-3-print { width: 100px; }" +
        ".report-column-4-print { width: 180px; }"
      );
    },
    columnTable() {
      return this.COLUMN;
    },
    getNameSmartTable() {
      return "AihPerSpecialty";
    },
  },
  watch: {
    "form.specialty"(pData) {
      if (isEmpty(pData)) {
        this.form.specialty = null;
        this.$refs.bedSpecialty.validate();
      }
    },
  },

  created() {
    this.COLUMN = [
      { name: "Nome do Paciente", key: "icf_nome" },
      { name: "Prontuário", key: "iaf_prontuario" },
      { name: "Nº Aih", key: "iaf_numero_aih" },
      { name: "Especialidade", key: "ils_nome" },
    ];
  },
  mounted() {
    this.form.uns_id = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    this.updatePresentationDate();
  },
  methods: {
    updatePresentationDate() {
      const cmp = this.$store.getters[
        "Billing/PeriodHospitalizationBilling/GET_PERIOD_HOSPITALIZATION_BILLING"
      ];
      if (!cmp) return;
      this.form.presentation = MountPresentation(cmp);
    },
    buildFilter() {
      return {
        uns_id: this.form.uns_id,
        specialty: !this.form.specialty
          ? null
          : this.form.specialty.replace(/\D+/g, "").trim(),
        presentation: this.form.presentation,
        status: this.form.status,
      };
    },
    async doSearch(pData) {
      try {
        return this.$store.dispatch(
          "Billing/HospitalizationBilling/GET_AIH_PER_SPECIALTY_REPORT",
          pData,
        );
      } catch (error) {
        return false;
      }
    },
    validatePeriod(pAih, pErrors) {
      return ValidatePeriodBilling(this.form.presentation, pErrors);
    },
    clearFilter() {
      this.form = {
        uns_id: 0,
        specialty: "",
        presentation: "",
        status: "0",
      };
    },
  },
};
</script>
