<template lang="html">
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 493.353 493.352"
    xml:space="preserve"
  >
    <g>
      <path
        d="M490.786,212.678c-1.718-1.713-3.901-2.568-6.57-2.568H127.907v-63.953c0-3.809-1.812-6.567-5.424-8.28
		c-3.621-1.521-6.952-0.949-9.995,1.713L2.853,240.658C0.95,242.564,0,244.848,0,247.511c0,2.474,0.95,4.665,2.853,6.567
		l109.636,99.928c3.046,2.474,6.377,2.956,9.995,1.427c3.612-1.52,5.424-4.278,5.424-8.278v-63.953h356.315
		c2.663,0,4.854-0.859,6.564-2.567c1.711-1.711,2.566-3.897,2.566-6.563v-54.821C493.356,216.586,492.497,214.393,490.786,212.678z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: "LeftArrow",
};
</script>
