import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchBioslabLaboratoryExamList($searchByWord: String, $limit: Int) {
    SearchBioslabLaboratoryExamList(
      searchByWord: $searchByWord
      limit: $limit
    ) {
      count
      rows {
        lle_id
        lle_codigo_bioslab_mne
        lle_procedimentos
        lle_multiplo
        lle_dum
        lle_peso
        lle_altura
        lle_cor
        lle_nacionalidade
        lle_naturalidade
        lle_material
        lle_codigo_sigtap
        procedure {
          CO_PROCEDIMENTO
          NO_PROCEDIMENTO
          TP_SEXO
          VL_IDADE_MINIMA
          VL_IDADE_MAXIMA
        }
      }
    }
  }
`;

export default async ({ variables }) => {
  const result = await GraphQLClient.query({ query, variables }).catch(
    (err) => {
      console.error(err);
    },
  );
  return result ? result.data.SearchBioslabLaboratoryExamList : [];
};
