<script>
import { RgComboboxMixin } from "~tokio/primitive";
import SearchScalesWithProcedure from "./action/SearchScalesWithProcedure";

export default {
  name: "RgComboboxScaleType",
  mixins: [RgComboboxMixin],
  props: {
    sectorId: {
      type: Number,
    },
    bondId: {
      type: Number,
    },
    procedureName: {
      type: String,
    },
    date: {
      type: String,
    },
  },
  watch: {
    date() {
      this.fillData();
    },
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      if (this.date) {
        this.mData = [];
        const data = await SearchScalesWithProcedure({
          intIdSetor: this.sectorId,
          intVusId: this.bondId,
          strData: this.date,
          strNomeProcedimento: this.procedureName,
        });

        data.forEach((item) => {
          this.mData.push({
            value: item.data,
            text: item.label,
            eha_id: item.eha_id,
            eea_inicio_atendimentos: item.eea_inicio_atendimentos,
            eea_id_exames_tipo_escalas: item.eea_id_exames_tipo_escalas,
          });
        });

        if (this.mData.length === 1) {
          this.$emit("selectItem", this.mData[0]);
        }
      }
    },
  },
};
</script>
