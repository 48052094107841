import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchHospitalizationSituationBed($situation: SituationBedTypeEnum) {
    hospitalizationSituationBed(situation: $situation) {
      rows {
        isl_id
        isl_nome
        isl_cor
      }
    }
  }
`;

export default async (variables) => {
  const { data } = await GraphQLClient.query({ query, variables }).catch(
    (err) => {
      console.error(err);
    },
  );
  return data.hospitalizationSituationBed.rows;
};
