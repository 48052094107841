<template lang="html">
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.2931 17.7068C13.6841 18.0977 14.3161 18.0977 14.7071 17.7068L22 10.4138L24.293 12.7068C24.58 12.9928 25.009 13.0808 25.383 12.9238C25.756 12.7698 26 12.4038 26 11.9998L26 5.99989C26 5.44689 25.552 4.9999 25 4.9999L19.0001 4.9999C18.5961 4.9999 18.2311 5.2429 18.0761 5.61689C17.9211 5.99089 18.0071 6.42088 18.2931 6.70688L20.5861 8.99985L13.2931 16.2928C12.9022 16.6838 12.9022 17.3158 13.2931 17.7068Z"
      fill="white"
    />
    <path
      d="M21.9998 25.9988H7.99997C6.34598 25.9988 5 24.6528 5 22.9988V8.99899C5 7.34501 6.34598 5.99902 7.99997 5.99902H15.9999C16.5519 5.99902 16.9999 6.44602 16.9999 6.99901C16.9999 7.55201 16.5519 7.999 15.9999 7.999H7.99997C7.44897 7.999 6.99998 8.447 6.99998 8.99899V22.9988C6.99998 23.5508 7.44897 23.9988 7.99997 23.9988H21.9998C22.5508 23.9988 22.9998 23.5508 22.9998 22.9988V14.9989C22.9998 14.4459 23.4478 13.9989 23.9998 13.9989C24.5518 13.9989 24.9998 14.4459 24.9998 14.9989V22.9988C24.9998 24.6528 23.6538 25.9988 21.9998 25.9988Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "Unregulate",
};
</script>
