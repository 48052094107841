<template>
  <div class="extra-details-patient-history">
    <div v-if="showModuleTitle" class="title">
      <span>{{ title.textOne }}</span>
    </div>

    <HistoryAppointmentDetails
      v-if="isAppointmentDetails"
      :details="extraDetails"
      :has-regulation-info="showRegulationInfo"
      :has-external-schedule-info="showExternalInfo"
    />

    <HistoryExamDetails
      v-else-if="isExamDetails"
      :details="extraDetails"
      :has-regulation-info="showRegulationInfo"
      :has-external-schedule-info="showExternalInfo"
    />

    <HistoryHospitalizationDetails
      v-else-if="isHospitalizationDetails"
      :details="extraDetails"
      :has-regulation-info="showRegulationInfo"
      :has-external-schedule-info="showExternalInfo"
    />

    <HistoryEmergencyDetails
      v-else-if="isEmergencyDetails"
      :details="extraDetails"
    />
  </div>
</template>

<script>
import MODULE from "./PatientHistoryModules";
import HistoryAppointmentDetails from "./details/HistoryAppointmentDetails";
import HistoryExamDetails from "./details/HistoryExamDetails";
import HistoryHospitalizationDetails from "./details/HistoryHospitalizationDetails";
import HistoryEmergencyDetails from "./details/HistoryEmergencyDetails";

export default {
  name: "ModalPatientHistory",
  components: {
    HistoryAppointmentDetails,
    HistoryExamDetails,
    HistoryHospitalizationDetails,
    HistoryEmergencyDetails,
  },
  props: {
    infoDetails: {
      type: Object,
      default: () => {},
    },
    extraDetails: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      title: {
        textOne: "",
        textRegulation: "Regulação",
      },
    };
  },
  computed: {
    isAppointmentDetails() {
      return this.infoDetails.id_modulo === MODULE.APPOINTMENT;
    },
    isEmergencyDetails() {
      return this.infoDetails.id_modulo === MODULE.EMERGENCY;
    },
    isHospitalizationDetails() {
      return this.infoDetails.id_modulo === MODULE.HOSPITALIZATION;
    },
    isExamDetails() {
      return this.infoDetails.id_modulo === MODULE.EXAM;
    },
    isEmpty() {
      return !this.infoDetails;
    },
    showModuleTitle() {
      return !(
        this.extraDetails && this.extraDetails.external_scheduling_id > 0
      );
    },
    showRegulation() {
      return this.infoDetails && this.infoDetails.regulacao;
    },
    showRegulationInfo() {
      return this.infoDetails.regulacao > 0;
    },
    showExternalInfo() {
      return this.extraDetails && this.extraDetails.external_scheduling_id > 0;
    },
  },
  watch: {
    infoDetails(pValue) {
      if (pValue && pValue.id_modulo) {
        this.mountDetails(pValue);
      }
    },
  },
  mounted() {},
  methods: {
    mountDetails(pData) {
      this.prepareDataByModuleId(pData.id_modulo);
    },

    prepareDataByModuleId(moduleId) {
      switch (moduleId) {
        case MODULE.APPOINTMENT:
          this.title.textOne = "Consulta";
          break;
        case MODULE.EXAM:
          this.title.textOne = "Exame";
          break;
        case MODULE.HOSPITALIZATION:
          this.title.textOne = "Internação";
          break;
        case MODULE.EMERGENCY:
          this.title.textOne = "Emergência";
          break;
      }
    },
  },
};
</script>
