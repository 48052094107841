<style src="./RgIconButton.scss" lang="scss" scoped></style>
<template>
  <div
    :title="permission ? title : 'Usuário sem permissão'"
    class="rg-icon-button"
  >
    <div
      class="button"
      @mouseover="mouseOver"
      @mouseleave="mouseLeave"
      @click="clicked"
    >
      <div
        class="left"
        :class="{
          blue: blue,
          red: red,
          green: green,
          hover: hover,
          disabled: permission ? disabled : true,
        }"
      >
        <div v-if="title" class="title">{{ title }}</div>
        <div v-else class="title">
          <slot name="title"></slot>
        </div>
      </div>

      <div
        class="right"
        :class="{
          blue: blue,
          red: red,
          green: green,
          hover: hover,
          disabled: permission ? disabled : true,
        }"
      >
        <div v-if="showWaiting" class="spinner">
          <div class="icon-spinner" />
        </div>
        <div v-if="showNormalCaption || showDone" class="icon">
          <slot name="icon"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
let timoutDone = null;
const timeoutAction = null;

export default {
  name: "RgIconButton",
  components: {},
  props: {
    title: {
      type: String,
    },
    permission: {
      type: Boolean,
      default: true,
    },
    disabled: Boolean,
    blue: Boolean,
    red: Boolean,
    green: Boolean,
  },
  data() {
    return {
      requestDone: false,
      timeDone: false,
      showNormalCaption: true,
      showWaiting: false,
      showDone: false,

      hover: false,
    };
  },

  methods: {
    mouseOver() {
      this.hover = true;
    },

    mouseLeave() {
      this.hover = false;
    },

    clicked() {
      if (this.disabled || !this.permission) {
        return;
      }

      this.actionSubmit();
      this.$emit("click", true);
    },

    actionSubmit() {
      this.showNormalCaption = false;
      this.showWaiting = true;
      this.showDone = false;
      return new Promise((resolve, reject) => {
        timoutDone = setTimeout(() => {
          this.timeDone = true;
          this.finish();
          resolve();
        }, 800);
      });
    },

    actionDone() {
      this.showNormalCaption = false;
      this.showWaiting = false;
      this.showDone = true;
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          this.actionNormalState();
          resolve();
        }, 800);
      });
    },

    actionNormalState() {
      this.showNormalCaption = true;
      this.showWaiting = false;
      this.showDone = false;
      clearTimeout(timoutDone);
      clearTimeout(timeoutAction);
    },

    done() {
      this.requestDone = true;
    },

    fail() {
      this.actionNormalState();
    },

    finish() {
      if (this.requestDone && this.timeDone) {
        this.requestDone = false;
        this.timeDone = false;
        this.$emit("complete");
        this.actionDone();
      }
    },
  },
};
</script>
