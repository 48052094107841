import * as date from "./date";
import * as print from "./print";
import * as dom from "./dom";
import * as str from "./string";
import * as obj from "./object";
import * as app from "./app";
import * as navigator from "./navigator";

export default function installed(_Vue) {
  _Vue.prototype.$utils = {
    date,
    dom,
    print,
    str,
    obj,
    app,
    navigator,
    arr: {},
  };
  return true;
}
