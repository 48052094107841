import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query trashDestination {
    SearchTrashDestination(limit: 100) {
      rows {
        dli_id
        dli_codigo_ab
        dli_nome_destino
      }
      count
    }
  }
`;

export default async ({ commit, state }) => {
  if (state.trash_destination.length > 0) {
    return;
  }
  try {
    const { data } = await GraphQLClient.query({
      query,
      fetchPolicy: "no-cache",
    });
    commit("SET_TRASH_DESTINATION", data.SearchTrashDestination.rows);
  } catch (err) {
    console.log(err);
    return err.graphQLErrors;
  }
};
