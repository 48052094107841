import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchIndividualForm(
    $are_id: Int
    $pab_nome: String
    $pab_nome_mae: String
    $pab_documento: String
    $end_logradouro: String
    $end_cep: String
    $data_atendimento_inicial: String
    $data_atendimento_final: String
    $data_nascimento_inicial: String
    $data_nascimento_final: String
    $pab_id_microareas: Int
    $uns_id: Int
    $equ_id: Int
    $exported: Boolean
    $exported_edit: Boolean
    $pab_ativo: Boolean
    $fab_id: Int
    $logged_user: Int
    $limit: Int
    $offset: Int
    $orderDirection: String
    $orderField: String
  ) {
    SearchPatientBasicAttention(
      are_id: $are_id
      pab_nome: $pab_nome
      pab_nome_mae: $pab_nome_mae
      pab_documento: $pab_documento
      end_logradouro: $end_logradouro
      end_cep: $end_cep
      data_atendimento_inicial: $data_atendimento_inicial
      data_atendimento_final: $data_atendimento_final
      data_nascimento_inicial: $data_nascimento_inicial
      data_nascimento_final: $data_nascimento_final
      pab_id_microareas: $pab_id_microareas
      uns_id: $uns_id
      equ_id: $equ_id
      fab_id: $fab_id
      logged_user: $logged_user
      exported: $exported
      exported_edit: $exported_edit
      pab_ativo: $pab_ativo
      limit: $limit
      offset: $offset
      orderDirection: $orderDirection
      orderField: $orderField
    ) {
      rows {
        pab_id
        pab_data_atendimento
        pab_fora_area
        pab_mae_desconhecida
        pab_nascimento
        pab_unico_original
        pab_data_exportacao
        pab_nome_mae
        pab_nome
        pab_documento
        pab_ativo
        microarea {
          mic_descricao
        }
        header_form {
          cfi_cartao_sus
          cfi_cnes
          cfi_codigo_ine
          cfi_codigo_cbo
          basic_attention_employee {
            employee {
              person {
                pes_nome
              }
            }
          }
          team {
            equ_nome_equipe
            area {
              unit_health {
                uns_nome
              }
            }
          }
        }
      }
      count
    }
  }
`;

export default async (pContext, pFilter) => {
  try {
    const variables = { ...pFilter, pab_ativo: true };
    const { data } = await GraphQLClient.query({
      query,
      fetchPolicy: "no-cache",
      variables,
    });
    return data.SearchPatientBasicAttention;
  } catch (err) {
    console.log(err);
    return err.graphQLErrors;
  }
};
