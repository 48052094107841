import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query EnvironmentControl {
    SearchEnvironmentControl(limit: 100) {
      rows {
        cov_id
        cov_codigo_ab
        cov_nome
      }
      count
    }
  }
`;

export default async ({ commit, state }) => {
  if (state.environment_control.length > 0) {
    return;
  }
  try {
    const { data } = await GraphQLClient.query({
      query,
      fetchPolicy: "no-cache",
    });
    commit("SET_ENVIRONMENT_CONTROL", data.SearchEnvironmentControl.rows);
  } catch (err) {
    console.log(err);
    return err.graphQLErrors;
  }
};
