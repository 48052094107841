import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query searchCustomHospitalizationDetails($int_id: Int!, $inl_id: Int!) {
    customHospitalizationDetails(int_id: $int_id, inl_id: $inl_id) {
      rows {
        int_id
        int_numero
        int_id_enderecamento_pep
        pes_nome
        pes_id
        pes_id_sexos
        ini_id_sexos
        pac_id
        pes_nascimento
        pac_prontuario_unico
        ocp_nome
        iti_nome
        ica_nome
        int_data
        int_hora
        int_previsao_alta
        usu_nome
        via_id
        via_nome
        ihs_observacao
        inl_nome
        set_nome
        uns_nome
        lca_nome
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    return data.customHospitalizationDetails.rows[0];
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors);
  }
};
