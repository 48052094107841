<style src="./EmployeeDisabledPeriods.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="employee-disabled-periods">
    <h3 class="list-title">Períodos de Inabilitação</h3>
    <ul v-show="list.length > 0">
      <li>
        <ul class="form-list--container-header row">
          <li class="col-3 align-list">Início</li>
          <li class="col-3 align-list">Fim</li>
          <li class="col-3 align-list">Motivo</li>
          <li class="col-3" />
        </ul>
      </li>

      <li v-for="(item, i) in list" :key="i" class="form-list--container-row">
        <ul class="form-list--container-content row">
          <li class="col-3 align-information">
            {{ item.vni_data_inicial | DateBR }}
          </li>
          <li class="col-3 align-information">
            {{ item.vni_data_final | DateBR }}
          </li>
          <li class="col-3 align-information">
            {{ item.disablingReason.mif_nome }}
          </li>
          <li class="form-list--remove col-3">
            <RgRemoveSmall @confirm-remove="removeItem(item)" />
          </li>
        </ul>
      </li>
    </ul>
    <div v-if="!list || list.length === 0" class="empty-container">
      <IconEmpty class="empty-list" />
      <span>Nenhuma Inabilitação para o Período</span>
    </div>
  </section>
</template>

<script>
import { RgRemoveSmall } from "~tokio/primitive";
import { IconEmpty } from "~tokio/primitive/icon/symbols";
import moment from "moment";

export default {
  name: "EmployeeDisabledPeriods",
  components: {
    RgRemoveSmall,
    IconEmpty,
  },
  filters: {
    DateBR: (pDate) => {
      return moment(pDate).format("DD/MM/YYYY");
    },
  },
  props: {
    employeeId: {},
    disablingList: {
      type: Array,
    },
  },
  data: () => {
    return {
      list: [],
    };
  },
  watch: {
    disablingList: function () {
      this.list = this.disablingList;
    },
  },
  methods: {
    removeItem(item) {
      this.$emit("remove-disabling-relationship", item);
    },
    loadList() {
      this.$store.dispatch("Employee/GET_EMPLOYEE_DISABLED_PERIODS", {
        criterias: { employeeId: this.employeeId },
      });
    },
  },
};
</script>
