import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

const arrayExceptions = [
  "NaoPossuiPermissaoException",
  "ConflitoEscalaDePeriodoHorarioException",
];
export default async (context, variables) => {
  return AuthLegacyRequest.post(
    "consultas/controller-escala-agendamento/alterar",
    variables,
  )
    .then(({ data }) => {
      const { dados, detalhes_exececao, trace } = data;

      let exceptionName = "";
      let hasError = false;

      const dataIsNotNumber = isNaN(dados);

      if (dataIsNotNumber) {
        hasError = true;
        exceptionName = arrayExceptions.includes(dados)
          ? dados
          : "Generic Exception";
      }

      const response = {
        hasError: hasError,
        dados: dados,
        detalhes_exececao: detalhes_exececao,
        mensagem: trace,
        exception: exceptionName,
      };

      return response;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
