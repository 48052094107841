<template lang="html">
  <div v-if="show" class="modal-change-professional-scale">
    <RgValidatorForm ref="validator">
      <RgBaseModal
        ref="Modal"
        v-shortkey="['esc']"
        :show="show"
        with-close-button
        class="rg-base-modal"
        @close="close"
        @shortkey.native="close"
      >
        <div slot="header" class="header">
          <div class="title">Editar Escala de Atendimento</div>
        </div>

        <div slot="body" class="body">
          <FormBase class="form-base" title="Escala do Dia">
            <div class="grid informations">
              <div>
                Unidade de Saúde:
                <strong>
                  {{ snap.uns_nome ? snap.uns_nome : this.UNITHEALTHNAME }}
                </strong>
              </div>

              <div>
                Setor:
                <strong> {{ snap.set_nome }} </strong>
              </div>

              <div>
                Profissional:
                <strong>
                  {{ snap.pes_nome ? snap.pes_nome : snap.fun_nome }}
                </strong>
              </div>

              <div>
                Data:
                <strong>
                  {{ snap.data_exame ? snap.data_exame : snap.DataExame }}
                </strong>
              </div>
            </div>

            <div class="inputs">
              <div class="input">
                <RgInput
                  v-model="scale.inicio_atendimentos"
                  v-mask="'##:##'"
                  :rules="{ required: true }"
                  class="field"
                  label="Horário em que a escala começa"
                />
              </div>

              <div class="input">
                <RgInput
                  v-model="scale.quantidade_exames"
                  :rules="{ required: true }"
                  :maxlength="2"
                  class="field"
                  label="Vagas disponíveis na escala"
                />
              </div>

              <div v-if="typeHourInterval" class="input">
                <RgInput
                  v-model="scale.intervalo_exames"
                  :maxlength="2"
                  class="field"
                  label="Intervalo entre procedimentos"
                  :rules="{ required: true, fn: validateMinute }"
                />
              </div>

              <div v-if="typeHourInterval" class="input">
                <RgInput
                  v-model="scale.final_atendimentos"
                  v-mask="'##:##'"
                  class="field"
                  disabled
                  :class="{ disable: true }"
                  label="Último horário para exames"
                />
              </div>

              <div v-if="typeHourInterval" div class="input">
                <RgInput
                  v-model="lastSchedule"
                  v-mask="'##:##'"
                  disabled
                  :class="{ disable: true }"
                  class="field"
                  label="Horário em que a escala termina"
                />
              </div>

              <div v-else class="input">
                <RgInput
                  v-model="lastScheduleScale"
                  v-mask="'##:##'"
                  class="field"
                  label="Horário em que a escala termina"
                  :rules="{ required: true }"
                />
              </div>
            </div>
          </FormBase>
        </div>

        <div slot="footer" class="footer">
          <div class="footer-buttons">
            <RgCancelButton class="cancel" medium @click="close" />

            <RgConfirmButton
              ref="confirmButton"
              v-shortkey="['enter']"
              large
              class="confirm"
              @shortkey.native="save"
              @click="save"
            />
          </div>
        </div>
      </RgBaseModal>

      <ModalConfirmDefault
        :show="showModalConfirmEdit"
        :message="'Existem exames agendados para esta escala. Tem certeza que deseja alterar os dados do dia?'"
        yes-label="Confirmar"
        no-label="Cancelar"
        class="modal-confirm-edit"
        @getYes="confirmEditScale"
        @getOut="closeModalUnavailableHour"
      />
    </RgValidatorForm>
  </div>
</template>

<script>
import moment from "moment";
import FormBase from "~tokio/foundation/form-base/FormBase";
import {
  RgBaseModal,
  RgInput,
  RgConfirmButton,
  RgCancelButton,
  RgValidatorForm,
  ModalConfirmDefault,
} from "~tokio/primitive";
import { mask } from "vue-the-mask";

import { IsValid } from "~common/utils/date";

export default {
  name: "ModalChangeProfessionalScale",
  components: {
    RgBaseModal,
    RgConfirmButton,
    RgCancelButton,
    RgInput,
    FormBase,
    RgValidatorForm,
    ModalConfirmDefault,
  },
  directives: { mask },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    snap: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      lastSchedule: null,
      lastScheduleScale: null,
      showModalConfirmEdit: false,
      payload: {},
      scale: {
        inicio_atendimentos: null,
        quantidade_exames: null,
        intervalo_exames: null,
        final_atendimentos: null,
      },
      alert: true,
    };
  },

  computed: {
    typeHourInterval() {
      return this.snap.eea_id_exames_tipo_escalas === "2";
    },
  },

  watch: {
    show(pValue) {
      if (!pValue) {
        this.close();
      } else {
        this.getScale();
      }
    },
    "scale.inicio_atendimentos"(pValue) {
      if (pValue) {
        this.calculateLastSchedule();
      }
    },
    "scale.quantidade_exames"(pValue) {
      if (pValue) {
        this.calculateLastSchedule();
      }
    },
    "scale.intervalo_exames"(pValue) {
      if (pValue) {
        this.calculateLastSchedule();
      }
    },
  },

  created() {
    this.UNITHEALTHNAME = this.$store.getters["Login/GET_UNIT_HEALTH_NAME"];
  },

  async mounted() {},

  methods: {
    async getScale() {
      this.scale = await this.$store.dispatch(
        "Exam/Schedule/GET_PROFESSIONAL_SCALE",
        {
          intIdEscala: this.snap.eea_id,
          intIdHistoricoEscala: this.snap.eha_id,
        },
      );

      this.scale.inicio_atendimentos = moment(
        this.scale.inicio_atendimentos,
        "HH:mm",
      ).format("HH:mm");

      this.lastScheduleScale = moment(
        this.scale.final_atendimentos,
        "HH:mm",
      ).format("HH:mm");
    },

    calculateLastSchedule() {
      const durationInMinutes =
        this.scale.intervalo_exames * (this.scale.quantidade_exames - 1);

      const endTime = moment(this.scale.inicio_atendimentos, "HH:mm")
        .add(durationInMinutes, "minutes")
        .format("HH:mm");

      this.scale.final_atendimentos = endTime;

      this.lastSchedule = moment(endTime, "HH:mm")
        .add(this.scale.intervalo_exames, "minutes")
        .format("HH:mm");
    },

    close() {
      this.alert = true;
      this.scale = null;
      this.$emit("close");
    },

    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    async save(pConfirmEditScale) {
      try {
        const isInvalid = !(await this.isFormValid());
        if (isInvalid) {
          this.$toaster.warning("Verifique os campos");
          this.$refs.confirmButton.actionDone();

          return false;
        } else {
          const invalidHours = !this.validHours();

          if (invalidHours) {
            return false;
          }

          this.$refs.confirmButton.actionSubmit();
          this.makePayload();

          const response = await this.$store.dispatch(
            "Exam/Schedule/SAVE_PROFESSIONAL_SCALE",
            this.payload,
          );

          if (response.hasError) {
            if (
              response.mensagem ===
              "Horário final menor que a última consulta agendada."
            ) {
              this.$toaster.error(
                "A escala ultrapassou 23:59. Caso seja necessário o plantão duplo, faça duas escalas.",
              );
              return false;
            } else if (
              response.dados === "ExameHorarioInicialInvalidoException"
            ) {
              throw new Error(
                "existem exames agendados para esse profissional no intervalo informado.",
              );
              // this.showModalConfirmEdit = true;
              // this.alert = false;
              // return false;
            } else {
              return this.$emit("error", response);
            }
          }

          if (pConfirmEditScale) {
            await this.$store.dispatch(
              "Exam/Schedule/SAVE_PROFESSIONAL_SCALE",
              this.payload,
            );
            this.close();
          }

          this.$toaster.success("Salvo com sucesso!");
          this.$emit("reSearch", true);
          this.close();
        }
      } catch (error) {
        this.$refs.confirmButton.fail();
        this.$toaster.warning(`Erro ao alterar dados do dia, ${error.message}`);
      } finally {
        this.$refs.confirmButton.actionDone();
      }
    },

    makePayload() {
      let lastSchedule = null;

      if (this.typeHourInterval) {
        lastSchedule = this.lastSchedule;
      } else {
        lastSchedule = this.lastScheduleScale;
      }

      this.payload = {
        eha_id: this.snap.eha_id,
        eha_atendimento: this.snap.eha_atendimento,
        eha_id_exames_escalas_agendamentos: this.snap.eea_id,
        eha_data_exame: this.snap.data_exame,
        eha_id_dias_semana: this.snap.eea_id_dias_semana,
        eha_id_exames_escala: this.snap.eha_id
          ? "0"
          : this.snap.eea_id_exames_escala,
        eha_hora_final_atendimentos: lastSchedule,
        eha_hora_inicio_atendimentos: this.scale.inicio_atendimentos,
        eha_quantidade_exames: parseInt(this.scale.quantidade_exames),
        eha_intervalo_exames: this.scale.intervalo_exames,
        blnExibirAvisos: this.alert,
      };
    },

    confirmEditScale() {
      this.save(true);
      this.showModalConfirmEdit = false;
    },

    closeModalUnavailableHour() {
      this.alert = true;
      this.showModalConfirmEdit = false;
    },

    validHours() {
      if (
        !IsValid(
          `1900-01-01 ${this.scale.inicio_atendimentos}`,
          "YYYY-MM-DD LT",
          true,
        )
      ) {
        this.$toaster.warning("Horário em que a escala começa é inválido.");
        return false;
      }

      if (
        !IsValid(`1900-01-01 ${this.lastScheduleScale}`, "YYYY-MM-DD LT", true)
      ) {
        this.$toaster.warning("Horário em que a escala termina é inválido.");
        return false;
      }
      return true;
    },

    validateMinute(pValue, pErrors) {
      const isInvalidRange =
        pValue && (Number(pValue) <= 0 || Number(pValue) > 59);

      if (isInvalidRange) {
        pErrors.push("Informe um número maior que 0 e menor que 60");
        return false;
      }

      return true;
    },
  },
};
</script>
