import moment from "moment";
import { isEmpty } from "lodash";
import { AlertError } from "./";

/**
 * @param { Integer } pAdditionalYear caso queira acrescentar algum ano ao atual
 */
const currentYear = (pAdditionalYear) => {
  pAdditionalYear = pAdditionalYear || 0;
  const year = new Date().getFullYear();

  return pAdditionalYear ? year + pAdditionalYear : year;
};

/**
 * @param { Date } pDate A data
 * @param { Date } pPeriod A competência do mes
 * @param { Integer } pRange A quantidade de meses que pode ser retroativo.
 * @param { String } pRangeType O tipo de quantidade retroativa.
 * @returns { Boolean} true|false indicando se a competência do faturamento esta válida.
 */
export const inPeriodRetroactive = (pDate, pPeriod, pRange, pRangeType) => {
  const period = moment(pPeriod).subtract(pRange, pRangeType).toString();
  const date = moment(pDate).toString();
  return moment(date).isBefore(period);
};

/**
 * @param { String } pHospitalizationDate A data em que o paciente deu entrada na internação
 * @param { String[] } pErrors Array de Mensagens de Erro
 * @param { Integer } pAdditionalYear Inteiro indicando os anos adicionais para colocar de acrescimo
 * @returns { Boolean} true|false indicando se a competência do faturamento esta válida.
 */
export const ValidateDate = (
  pHospitalizationDate,
  pErrors,
  pAdditionalYear,
) => {
  pAdditionalYear = pAdditionalYear || 0;
  pHospitalizationDate = pHospitalizationDate || null;
  pErrors = pErrors || [];

  if (pHospitalizationDate && !isEmpty(pHospitalizationDate)) {
    const day = parseInt(pHospitalizationDate.substring(0, 2));
    const month = parseInt(pHospitalizationDate.substring(3, 5));
    const year = parseInt(pHospitalizationDate.substring(6, 10));

    // Validação
    if (day < 1 || month > 31) {
      return AlertError("Dia inválido.", pErrors);
    }

    // Validando o Mês
    if (month < 1 || month > 12) {
      return AlertError("Mês inválido.", pErrors);
    }

    // Validando o Ano
    if (year < 2000 || year > currentYear(pAdditionalYear)) {
      return AlertError("Ano inválido.", pErrors);
    }
  }

  return true;
};

/**
 * @param { String } pHospitalizationDate A data em que o paciente deu entrada na internação
 * @param { String } pHospitalizationExits A data em que o paciente deu saida da internação
 * @param { Boolean } pAdvancedValidation Se deve ser realizar validações avançadas
 * @param { String[] } pErrors Array de Mensagens de Erro
 * @returns { Boolean } true|false indicando se a data esta válida.
 */
export const ValidateHospitalizationDate = (
  pHospitalizationDate,
  pHospitalizationExits,
  pAdvancedValidation,
  pErrors,
) => {
  if (!ValidateDate(pHospitalizationDate, pErrors, 0)) return false;

  if (pAdvancedValidation) {
    const initialDate = moment(pHospitalizationDate, "DD/MM/YYYY");
    const finalDate = moment(pHospitalizationExits, "DD/MM/YYYY");

    if (
      pHospitalizationExits &&
      !moment(initialDate).isSameOrBefore(finalDate)
    ) {
      return AlertError(
        "A data de internação deve ser menor que a de saída.",
        pErrors,
      );
    }
  }
  return true;
};

/**
 * @param { String } pHospitalizationDate A data em que o paciente deu entrada na internação
 * @param { String } pHospitalizationExits A data em que o paciente deu saida da internação
 * @param { String } pPeriod A competencia do Faturamento de internação.
 * @param { Boolean } pAdvancedValidation Se deve ser realizar validações avançadas
 * @param { String[] } pErrors Array de Mensagens de Erro
 * @returns { Boolean } true|false indicando se a data esta válida.
 */
export const ValidateHospitalizationDateExits = (
  pHospitalizationDate,
  pHospitalizationExits,
  pPeriod,
  pAdvancedValidation,
  pErrors,
) => {
  const today = new Date();
  const initialDate = moment(pHospitalizationDate, "DD/MM/YYYY");
  const finalDate = moment(pHospitalizationExits, "DD/MM/YYYY");
  const period = moment(pPeriod, "MM/YYYY");
  const range = 6;

  if (moment(finalDate).isAfter(moment(today))) {
    return AlertError("A data de saída não pode ser no futuro.", pErrors);
  }

  if (!ValidateDate(pHospitalizationExits, pErrors, 1)) return false;

  if (pAdvancedValidation) {
    if (!moment(finalDate).isSameOrAfter(initialDate)) {
      return AlertError(
        "A data de saída deve ser maior que a de internação.",
        pErrors,
      );
    }

    if (inPeriodRetroactive(finalDate, period, range, "month")) {
      return AlertError(
        `A data deve ser até ${range} meses retroativos baseado na apresentação.`,
        pErrors,
      );
    }
  }

  return true;
};
