<template lang="html">
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.0615 21.3316H28.7791V25.0492C28.7791 25.5457 29.1908 25.9574 29.6873 25.9574H34.3131C34.8096 25.9574 35.2213 25.5457 35.2213 25.0492V21.3316H38.9389C39.4354 21.3316 39.8471 20.9199 39.8471 20.4234V15.7977C39.8471 15.3012 39.4354 14.8895 38.9389 14.8895H35.2213V11.1719C35.2213 10.6754 34.8096 10.2637 34.3131 10.2637H29.6873C29.1908 10.2637 28.7791 10.6754 28.7791 11.1719V14.8895H25.0615C24.565 14.8895 24.1533 15.3012 24.1533 15.7977V20.4234C24.1533 20.932 24.5529 21.3316 25.0615 21.3316ZM25.9697 16.7059H29.6873C30.1838 16.7059 30.5955 16.2941 30.5955 15.7977V12.0801H33.4049V15.7977C33.4049 16.2941 33.8166 16.7059 34.3131 16.7059H38.0307V19.5152H34.3131C33.8166 19.5152 33.4049 19.927 33.4049 20.4234V24.141H30.5955V20.4234C30.5955 19.927 30.1838 19.5152 29.6873 19.5152H25.9697V16.7059Z"
      fill="white"
    />
    <path
      d="M29.6865 28.7789H20.4229C19.9264 28.7789 19.5146 29.1906 19.5146 29.6871V38.9507C19.5146 39.4472 19.9264 39.8589 20.4229 39.8589H29.6865C30.183 39.8589 30.5947 39.4472 30.5947 38.9507V29.6871C30.5947 29.1785 30.183 28.7789 29.6865 28.7789ZM28.7783 38.0304H21.3311V30.5832H28.7783V38.0304Z"
      fill="white"
    />
    <path
      d="M43.5771 28.7789H34.3135C33.817 28.7789 33.4053 29.1906 33.4053 29.6871V38.9507C33.4053 39.4472 33.817 39.8589 34.3135 39.8589H43.5771C44.0736 39.8589 44.4854 39.4472 44.4854 38.9507V29.6871C44.4854 29.1785 44.0736 28.7789 43.5771 28.7789ZM42.6689 38.0304H35.2217V30.5832H42.6689V38.0304Z"
      fill="white"
    />
    <path
      d="M52.8281 39.8469H57.4539C57.9504 39.8469 58.3621 39.4351 58.3621 38.9387V29.675C58.3621 29.1785 57.9504 28.7668 57.4539 28.7668H52.8281C52.3316 28.7668 51.9199 29.1785 51.9199 29.675V38.9387C51.9199 39.4473 52.3316 39.8469 52.8281 39.8469ZM53.7363 30.5953H56.5457V38.0426H53.7363V30.5953Z"
      fill="white"
    />
    <path
      d="M52.8281 53.7363H57.4539C57.9504 53.7363 58.3621 53.3246 58.3621 52.8281V43.5645C58.3621 43.068 57.9504 42.6562 57.4539 42.6562H52.8281C52.3316 42.6562 51.9199 43.068 51.9199 43.5645V52.8281C51.9199 53.3367 52.3316 53.7363 52.8281 53.7363ZM53.7363 44.4848H56.5457V51.932H53.7363V44.4848Z"
      fill="white"
    />
    <path
      d="M62.0918 19.5152H49.1105V7.44219H50.5152C51.0117 7.44219 51.4234 7.03047 51.4234 6.53398V1.9082C51.4234 1.41172 51.0117 1 50.5152 1H13.4848C12.9883 1 12.5766 1.41172 12.5766 1.9082V6.53398C12.5766 7.03047 12.9883 7.44219 13.4848 7.44219H14.8895V19.5152H1.9082C1.41172 19.5152 1 19.927 1 20.4234V62.0918C1 62.5883 1.41172 63 1.9082 63H62.0918C62.5883 63 63 62.5883 63 62.0918V20.4234C63 19.927 62.5883 19.5152 62.0918 19.5152ZM2.81641 21.3316H14.8895V24.141H2.81641V21.3316ZM24.1531 47.2941V61.1836H16.7059V36.2988C16.7059 35.8023 16.2941 35.3906 15.7977 35.3906C15.3012 35.3906 14.8895 35.8023 14.8895 36.2988V61.1836H2.81641V25.9695H14.8895V32.3391C14.8895 32.8355 15.3012 33.2473 15.7977 33.2473C16.2941 33.2473 16.7059 32.8355 16.7059 32.3391V7.44219H39.2777C39.7742 7.44219 40.1859 7.03047 40.1859 6.53398C40.1859 6.0375 39.7742 5.62578 39.2777 5.62578H14.393V2.81641H49.607V5.62578H43.2375C42.741 5.62578 42.3293 6.0375 42.3293 6.53398C42.3293 7.03047 42.741 7.44219 43.2375 7.44219H47.2941V61.1836H39.8469V47.2941H38.0305V61.1836H25.9574V47.2941H24.1531ZM61.1836 61.1836H49.1105V25.9695H61.1836V61.1836ZM61.1836 24.1531H49.1105V21.3438H61.1836V24.1531Z"
      fill="white"
    />
    <path
      d="M25.9096 47.2942H39.8475H40.1502C40.9252 47.2942 41.5549 46.6645 41.5549 45.8895C41.5549 45.1145 40.9252 44.4848 40.1502 44.4848H23.851C23.076 44.4848 22.4463 45.1145 22.4463 45.8895C22.4463 46.6645 23.076 47.2942 23.851 47.2942H24.1537"
      fill="white"
    />
    <path
      d="M11.1721 28.7789H6.53418C6.0377 28.7789 5.62598 29.1906 5.62598 29.6871V38.9507C5.62598 39.4472 6.0377 39.8589 6.53418 39.8589H11.1721C11.6686 39.8589 12.0803 39.4472 12.0803 38.9507V29.6871C12.0803 29.1785 11.6686 28.7789 11.1721 28.7789ZM10.2639 38.0304H7.44238V30.5832H10.2518V38.0304H10.2639Z"
      fill="white"
    />
    <path
      d="M11.1721 42.6684H6.53418C6.0377 42.6684 5.62598 43.0801 5.62598 43.5766V52.8282C5.62598 53.3246 6.0377 53.7364 6.53418 53.7364H11.1721C11.6686 53.7364 12.0803 53.3246 12.0803 52.8282V43.5645C12.0803 43.068 11.6686 42.6684 11.1721 42.6684ZM10.2639 51.92H7.44238V44.4727H10.2518V51.92H10.2639Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: "UnitHealth",
};
</script>
