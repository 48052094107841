import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async ({ intModulo }) => {
  return AuthLegacyRequest.post(
    "/consultas/controller-tipo-consulta-unidade/combo-box-planos-saude",
    { intModulo },
  )
    .then(({ data }) => {
      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
