<style src="./RgComboboxSector.scss" lang="scss"></style>
<script>
import { RgRemoteComboboxMixin } from "~tokio/primitive";
import ListSectorsByUnithealthId from "./action/ListSectorsByUnithealthId";

export default {
  name: "RgComboboxSector",
  mixins: [RgRemoteComboboxMixin],
  props: {
    unitHealth: {
      default: null,
    },
    permission: {
      type: Number,
      default: null,
    },
    isNecessary: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Setor",
    },
    defaultText: {
      type: String,
      default: "Selecione",
    },
    limit: {
      type: Number,
      default: 1000,
    },
  },
  watch: {
    unitHealth: function () {
      this.getDataFromRemote();
    },
  },
  methods: {
    doSearch() {
      if (this.unitHealth === null) return Promise.resolve([]);

      return ListSectorsByUnithealthId({
        unitHealth: this.unitHealth,
        permission: this.permission,
        limit: this.limit,
      });
    },
    reSearchSector() {
      this.getDataFromRemote();
    },
    formatItem(item) {
      return {
        value: item.set_id,
        text: `${item.set_nome}`,
      };
    },
    blur() {
      if (this.isNecessary) {
        this.validate();
        this.$emit("blur");
      }
    },
  },
};
</script>
