<template lang="html">
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 29.625 29.625"
    style="enable-background: new 0 0 29.625 29.625"
    xml:space="preserve"
  >
    <g>
      <path
        d="M20.134,10.879c-1.121,0-2.139,0.409-2.948,1.063c-0.343-0.269-0.599-0.575-0.716-0.948
      c2.139-0.82,3.664-2.877,3.664-5.303C20.134,2.547,17.585,0,14.441,0C11.298,0,8.75,2.548,8.75,5.691
      c0,2.399,1.491,4.442,3.593,5.28c-1.027,1.86-7.573,2.177-7.573,6.421c0,2.404,0.882,5.094,2.334,7.33
      c-0.214,1.188-0.031,2.305,0.612,3.205c0.782,1.096,2.126,1.697,3.782,1.697l0,0c0.585,0,1.199-0.084,1.824-0.229
      c0.368,0.064,0.739,0.107,1.118,0.107c5.087,0,9.247-6.139,9.633-11.336c0.489-0.742,0.781-1.623,0.781-2.568
      C24.856,12.999,22.737,10.879,20.134,10.879z M20.208,19.098c1.238,1.855-0.252,5.127-3.383,7.363
      c-3.165,2.26-6.798,2.6-8.115,0.758c-1.316-1.842,0.183-5.168,3.349-7.43c1.835-1.312,3.814-1.955,5.422-1.924
      c-0.523-0.611-0.85-1.396-0.85-2.264c0-1.935,1.568-3.502,3.502-3.502s3.502,1.567,3.502,3.502
      C23.637,17.512,22.106,19.057,20.208,19.098z"
      />
      <path
        d="M15.415,21.4c0,0-0.372-2.051-1.538-1.459c-1.166,0.596-0.371,2.961,1.22,4.445c2.757-1.326,3.871-2.916,2.857-3.805
      S15.415,21.4,15.415,21.4z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Mother",
};
</script>
