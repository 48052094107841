<style src="./RgSurgeryEmployeeFunctionsForm.scss" lang="scss" scoped></style>
<template lang="html">
  <div class="surgery-employee-functions-form">
    <RgForm @submit="submit">
      <div class="row">
        <RgInputDebounce
          v-model="form.fnc_nome"
          :rules="{ required: true }"
          class="col-4"
          label="Função"
          placeholder="Digite a função"
          @debounced="doDebounce"
        />
        <RgComboboxSurgeryEmployeeFunctionsGroup
          v-model="form.fnc_id_grupos_cirurgicos"
          :rules="{ required: true }"
          class="col-4"
          label="Grupo Cirúrgico"
        />

        <fieldset class="rg-radio col-2 combobox-row">
          <label class="description--radio">RESPONSAVEL</label>
          <div class="form-requesting--radio">
            <label class="radio">
              <input
                v-model="form.fnc_responsavel"
                type="radio"
                name="fnc_responsavel"
                value="1"
                class="rg-radio"
              />
              <span>Sim</span>
            </label>
            <label class="radio">
              <input
                v-model="form.fnc_responsavel"
                type="radio"
                name="fnc_responsavel"
                value="0"
                class="rg-radio"
              />
              <span>Não</span>
            </label>
          </div>
        </fieldset>
        <fieldset class="rg-radio col-2 combobox-row">
          <label class="description--radio">Avaliador</label>
          <div class="form-requesting--radio">
            <label class="radio">
              <input
                v-model="form.fnc_avaliador"
                type="radio"
                name="fnc_avaliador"
                value="1"
                class="rg-radio"
              />
              <span>Sim</span>
            </label>
            <label class="radio">
              <input
                v-model="form.fnc_avaliador"
                type="radio"
                name="fnc_avaliador"
                value="0"
                class="rg-radio"
              />
              <span>Não</span>
            </label>
          </div>
        </fieldset>
      </div>
    </RgForm>
  </div>
</template>

<script>
import { RgInputDebounce, toast } from "~tokio/primitive";
import { RgForm } from "~tokio/foundation/form";
import SurgeryEmployeeFunctionModel from "$surgery-center/surgery/model/SurgeryEmployeeFunctionModel";
import RgComboboxSurgeryEmployeeFunctionsGroup from "$surgery-center/surgery/component/combobox/rg-combobox-surgery-employee-functions-group/RgComboboxSurgeryEmployeeFunctionsGroup";
export default {
  name: "RgSurgeryEmployeeFunctionsForm",
  components: {
    RgForm,
    RgInputDebounce,
    RgComboboxSurgeryEmployeeFunctionsGroup,
  },
  data: () => {
    return {
      form: {
        fnc_nome: "",
        fnc_responsavel: 0,
        fnc_avaliador: 0,
        fnc_ativo: "",
        fnc_id: undefined,
        fnc_id_grupos_cirurgicos: "",
      },
    };
  },
  methods: {
    doDebounce() {
      this.$emit("search-for", this.form.fnc_nome);
    },
    submit(pErr, pDone) {
      if (pErr) {
        return;
      }
      SurgeryEmployeeFunctionModel.create(this.formatForm())
        .then((pRes) => {
          pDone();
          this.$emit("saved", pRes);
        })
        .catch((pErr) => {
          pDone();
          toast.error(pErr.response.errors[0].msg, "Erro ao Realizar Operação");
        });
    },
    cleanForm() {
      this.form = {
        fnc_nome: "",
        fnc_responsavel: 0,
        fnc_avaliador: 0,
        fnc_ativo: "",
        fnc_id: "",
        fnc_id_grupos_cirurgicos: "",
      };
    },
    formatForm() {
      const form = { ...this.form };
      delete form.fnc_ativo;

      return form;
    },
  },
};
</script>
