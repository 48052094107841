import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import AppointmentExternallySchedule from "$appointment/submodules/schedule/views/appointment-externally-schedule/AppointmentExternallySchedule.vue";
import { OpenMenuModule } from "~common/utils/app/menu";

export default {
  path: "/appointment/schedule/externally-schedule",
  name: "appointment.schedule.externally-schedule",
  components: {
    default: AppointmentExternallySchedule,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Consulta", link: OpenMenuModule("appointment") },
      {
        label: "Agendados Externamente",
        link: "/appointment/schedule/externally-schedule",
      },
    ],
  },
};
