import { render, staticRenderFns } from "./RgEditButton.vue?vue&type=template&id=b6e77214&scoped=true&"
import script from "./RgEditButton.vue?vue&type=script&lang=js&"
export * from "./RgEditButton.vue?vue&type=script&lang=js&"
import style0 from "./RgEditButton.scss?vue&type=style&index=0&id=b6e77214&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b6e77214",
  null
  
)

export default component.exports