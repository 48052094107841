<style src="./RgComboboxSurgeryPortage.scss" lang="scss"></style>
<script>
import { RgRemoteComboboxMixin } from "~tokio/primitive";
import SurgeryPortageModel from "$surgery-center/surgery/model/SurgeryPortageModel";
export default {
  name: "RgComboboxSurgeryPortage",
  mixins: [RgRemoteComboboxMixin],
  data: () => {
    return {
      model: SurgeryPortageModel,
    };
  },
  methods: {
    formatItem(pData) {
      return {
        value: pData.por_id,
        text: pData.por_nome,
      };
    },
  },
};
</script>
