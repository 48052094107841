import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query($bulletinNumber: Int, $bulletinAttended: Boolean, $unitHealth: Int) {
    bulletins(
      bol_numero: $bulletinNumber
      bol_atendido: $bulletinAttended
      uns_id: $unitHealth
    ) {
      rows {
        bol_id
        bol_numero
        sector {
          unit_health {
            uns_id
          }
        }
        patient {
          pac_id
          pac_prontuario_unico
          person {
            pes_mae
            pes_nome
            pes_id
            gender {
              sex_id
              sex_sigla
            }
          }
        }
      }
    }
  }
`;

export default async (variables) => {
  try {
    const { data } = await GraphQLClient.query({ query, variables });
    return data.bulletins.rows;
  } catch (err) {
    const jsonResponse = JSON.parse(JSON.stringify(err));
    return Promise.reject(jsonResponse.graphQLErrors[0].message);
  }
};
