import {
  GraphQLClient,
  gql,
  removeTypename,
} from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation(
    $patientBasicAttention: PatientBasicAttentionInputTypes
    $streetSituation: StreetSituationInputTypes
    $healthSituation: HealthSituationInputTypes
    $existCitizenRegister: ExitCitizenRegisterInputTypes
    $patientDeficient: [PatientDeficientInputTypes]
    $patientChildSupport: PatientChildSupportInputTypes
    $header_form: HeaderInputType
    $transference: TransferenceInputType
  ) {
    SaveIndividualForm(
      patientBasicAttention: $patientBasicAttention
      streetSituation: $streetSituation
      healthSituation: $healthSituation
      existCitizenRegister: $existCitizenRegister
      patientDeficient: $patientDeficient
      patientChildSupport: $patientChildSupport
      hearder_form: $header_form
      transference: $transference
    ) {
      pab_id
    }
  }
`;

export default async ({ commit }, pData) => {
  try {
    const variables = removeTypename(pData);
    const { data } = await GraphQLClient.mutate({ mutation, variables });
    return { errors: null, data };
  } catch (err) {
    if (err.graphQLErrors) {
      try {
        return { errors: JSON.parse(err.graphQLErrors[0].message), data: null };
      } catch (e) {
        return { errors: err.graphQLErrors, data: null };
      }
    }
  }
};
