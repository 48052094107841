import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation ResendEffectivedExamToPep($iep_id: Int!) {
    ResendEffectivedExamToPep(iep_id: $iep_id) {
      success
      message
    }
  }
`;
export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.mutate({
      mutation,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.ResendEffectivedExamToPep;
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors[0].message);
  }
};
