<style src="./RgComboboxUserGroup.scss" lang="scss"></style>
<script>
import { RgRemoteComboboxMixin } from "~tokio/primitive";
import ListUserGroup from "./action/ListUserGroup";

export default {
  name: "RgComboboxUserGroup",
  mixins: [RgRemoteComboboxMixin],
  props: {
    label: {
      type: String,
      default: "Grupos de Usuários",
    },
  },
  data: () => {
    return {};
  },
  methods: {
    doSearch() {
      return ListUserGroup();
    },
    formatItem(item) {
      return {
        value: item.gru_id,
        text: `${item.gru_nome}`,
      };
    },
  },
};
</script>
