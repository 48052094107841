<template>
  <section class="float-button-anchor">
    <div
      v-show="listItem"
      class="list-item"
      :class="{ open: listItem, close: !listItem }"
    >
      <a
        class="link"
        title="Dados Pessoais"
        href="#personal-data"
        @click="closeList"
      >
        <IconPatientBioslab class="svg person" />
      </a>

      <a class="link" title="Endereço" href="#address-data" @click="closeList">
        <IconPlace class="svg place" />
      </a>

      <a
        class="link"
        title="Documentos"
        href="#document-data"
        @click="closeList"
      >
        <IconDocumentPerson class="svg document" />
      </a>

      <a class="link" title="Contatos" href="#contacts-data" @click="closeList">
        <IconTelephone class="svg phone" />
      </a>

      <a class="link" title="Paciente" href="#patient-data" @click="closeList">
        <IconCross class="svg cross" />
      </a>
    </div>
    <button class="mainButton" @click="OpenOrCloseList">
      <IconMenusquares class="svg" />
    </button>
  </section>
</template>

<script>
import {
  IconMenusquares,
  IconPatientBioslab,
  IconTelephone,
  IconPlace,
  IconDocumentPerson,
  IconCross,
} from "~tokio/primitive";

export default {
  name: "FloatButtonAnchor",
  components: {
    IconMenusquares,
    IconPatientBioslab,
    IconTelephone,
    IconPlace,
    IconDocumentPerson,
    IconCross,
  },
  data() {
    return {
      listItem: false,
    };
  },
  mounted() {},
  methods: {
    OpenOrCloseList() {
      this.listItem = !this.listItem;
    },

    closeList() {
      this.listItem = false;
    },
  },
};
</script>
