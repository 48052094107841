import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async (variables) => {
  return AuthLegacyRequest.post(
    "/internacoes/controllerinternacao-unidade-solicitante/buscar",
    variables,
  )
    .then(({ data }) => {
      return data.dados.recordSet;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
