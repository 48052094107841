import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchHealthOccupationByEmployeeAndUnit($uns_id: Int, $fun_id: Int!) {
    healthOccupations(uns_id: $uns_id, fun_id: $fun_id) {
      rows {
        ocp_id
        ocp_nome
        ocp_codigo
      }
    }
  }
`;

export default async (variables) => {
  try {
    const { data } = await GraphQLClient.query({ query, variables });
    return data.healthOccupations.rows;
  } catch (err) {
    return Promise.reject(err.graphQLErrors[0].message);
  }
};
