<style src="./RgSurgeryRequestingClinicCrud.scss" lang="scss" scoped></style>
<template lang="html">
  <div class="">
    <section class="form">
      <RgSurgeryRequestingClinicForm
        @saved="fillList"
        @search-for="searchFor"
      />
    </section>

    <section class="list">
      <RgPaginate
        :page-now="paginate.pageNow"
        :per-page="paginate.perPage"
        :page-count="pageCount"
        @forward-page="forwardPage()"
        @backward-page="backwardPage()"
      />
      <RgSurgeryRequestingClinicList
        :list="paginatedList"
        @select="select"
        @remove="remove"
      />
    </section>
  </div>
</template>

<script>
import RgPaginate from "$surgery-center/foundation/component/rg-paginate/RgPaginate";
import RgPaginateMixin from "$surgery-center/foundation/component/rg-paginate/mixin/RgPaginateMixin";
import RgSurgeryRequestingClinicForm from "./rg-surgery-requesting-clinic-form/RgSurgeryRequestingClinicForm";
import RgSurgeryRequestingClinicList from "./rg-surgery-requesting-clinic-list/RgSurgeryRequestingClinicList";
import SurgeryRequestingClinicianModel from "$surgery-center/surgery/model/SurgeryRequestingClinicianModel";
export default {
  name: "RgSurgeryRequestingClinicCrud",
  components: {
    RgSurgeryRequestingClinicForm,
    RgSurgeryRequestingClinicList,
    RgPaginate,
  },
  mixins: [RgPaginateMixin],
  data: () => {
    return {};
  },
  mounted() {
    this.fillList();
  },
  methods: {
    remove(item) {
      SurgeryRequestingClinicianModel.remove(item.value).then((res) => {
        this.fillList();
      });
    },
    fillList() {
      SurgeryRequestingClinicianModel.search({ inc_ativo: 1 }).then((res) => {
        this.paginate.list = [];
        res.data.forEach((item) => {
          this.paginate.list.push(this.formatItem(item));
        });
      });
    },
    formatItem(item) {
      return {
        text: item.inc_nome,
        value: item.inc_id,
      };
    },
    select() {},
  },
};
</script>
