<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchSubproceduresQueue from "./action/SearchSubproceduresQueue";

export default {
  name: "RgComboboxSubproceduresQueueByUnit",
  mixins: [RgComboboxMixin],
  props: {
    procedureName: {
      type: String,
      require: true,
    },
  },
  data: () => {
    return {};
  },
  watch: {
    procedureName() {
      this.fillData();
    },
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];
      // Não consegui identificar porque as vezes esse parâmetro vem como objeto e não int.
      if (
        typeof this.procedureName === "object" ||
        !this.procedureName ||
        this.procedureName.length <= 0
      ) {
        return;
      }
      const procedures = await SearchSubproceduresQueue(this.procedureName);
      procedures.forEach((item) => {
        this.mData.push({
          value: item.data,
          text: item.label,
        });
      });
    },
  },
};
</script>
