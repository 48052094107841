export default {
  token: "",
  useLegacyBackend: true,
  legacyToken: "",
  clientCode: "",
  user: {},
  route_module_map: {
    register: 1,
    "unit-health": 1,
    person: 1,
    billing: 30,
    "surgery-center": 40,
    exam: 33,
    appointment: 2,
    hospitalization: 14,
    report: null,
    pharmacy: 12,
    "basic-attention": 37,
    emergency: 15,
  },
  unitHealth: {},
  permissions: [],
  preferences: {},
  module_preferences_loaded: {},
  module_permissions_loaded: {},
  loginActive: false,
  test: false,
  permitedUnitHealthList: [],
  lastLogin: {
    clientCode: "",
    userLogin: "",
  },
  privateSector: false,
  cadsus: false,
  phoneRequired: false,
  backendLegacyUrl: null,
  userHealthOccupations: null,
};
