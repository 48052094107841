module.exports = `
inf_id
inf_procedimento_principal
inf_mudanca_procedimento
inf_procedimento_solicitado
inf_codigo_modalidade
inf_codigo_especialidade_leito
inf_codigo_carater_internacao
inf_cid_principal
inf_motivo_encerramento
inf_nome_solicitante
inf_documento_solicitante
inf_documento_tipo_solicitante
inf_nome_responsavel
inf_documento_responsavel
inf_documento_tipo_responsavel
inf_nome_autorizador
inf_documento_autorizador
inf_documento_tipo_autorizador
inf_data_autorizacao
inf_aih_numero_anterior
inf_aih_numero_posterior
inf_cnpj_empregador
inf_cnaer
inf_vinculos_previdencia
inf_cbo
inf_codigo_solicitacao_liberacao
inf_justificativa
`;
