import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  const sendData = {
    arrFiltros: variables.arrFiltros,
  };

  return AuthLegacyRequest.post(
    "/consultas/controller-tipo-consulta-unidade/buscar",
    sendData,
  )
    .then(({ data }) => {
      data.dados.resultado.map((item) => {
        item.tcu_ativo === "0"
          ? (item.tcu_ativo = false)
          : (item.tcu_ativo = true);
      });

      data = HandleResponse(data);
      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
