<template lang="html">
  <div v-if="show" class="modal-confirm-queue-deletion">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <span class="title"> Impressão Transferência de Agendamentos</span>
      </div>

      <div slot="body" class="body">
        <div class="text">
          <div>Deseja imprimir a ficha de transferência?</div>
        </div>
      </div>

      <div slot="footer" class="footer">
        <div class="footer-buttons">
          <RgCancelButton class="cancel" medium @click="close" />

          <RgConfirmButton
            ref="confirmButton"
            large
            class="confirm"
            @click="actionConfirm"
          />
        </div>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { RgBaseModal, RgCancelButton, RgConfirmButton } from "~tokio/primitive";

export default {
  name: "ModalPrintTransfer",
  components: {
    RgBaseModal,
    RgCancelButton,
    RgConfirmButton,
  },
  props: {
    show: Boolean,
  },
  methods: {
    async actionConfirm() {
      this.$emit("confirm", true);
    },

    close() {
      this.$emit("close");
    },
  },
};
</script>
