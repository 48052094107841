<template>
  <section class="new-birth-certificate-document">
    <FormBase
      title="Certidão de Nascimento (Nova)"
      class="form-base-birth-certificate-new"
    >
      <div class="birth-certificate-new">
        <RgInput
          v-model="form.ctn_numero"
          class="registration"
          data-id="matrícula"
          label="Matrícula"
          title="Número da Matrícula"
          :maxlength="32"
        />

        <!-- <div class="attachment">
          <rg-attachment-button
            :class="{ 'disable': disabledAttachment }"
            :disbaled="disabledAttachment"
            class="button"
            @click="clicked"
          />
        </div> -->
      </div>
    </FormBase>
  </section>
</template>

<script>
import FormBase from "~tokio/foundation/form-base/FormBase";
import { RgInput } from "~tokio/primitive";

export default {
  name: "NewBirthCetificateDocument",
  components: {
    FormBase,
    RgInput,
  },
  props: {
    newBirthCertificateData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        ctn_id: 0,
        ctn_id_tipos_documento: 0,
        ctn_numero: "",
      },
    };
  },
  computed: {
    disabledAttachment() {
      const registry = this.form.ctn_numero && this.form.ctn_numero.length >= 5;

      return !registry;
    },
  },
  watch: {
    newBirthCertificateData(pValue) {
      if (pValue) {
        this.mountNewBirthCertificateData(pValue);
      }
    },
  },
  methods: {
    mountNewBirthCertificateData(pData) {
      this.form = {
        ctn_id: pData.ctn_id || 0,
        ctn_id_tipos_documento: pData.ctn_id_tipos_documento || 0,
        ctn_numero: pData.ctn_numero,
      };
    },

    getNewBirthCertificate() {
      return this.form;
    },

    cleanForm() {
      this.form = {
        ctn_id: 0,
        ctn_id_tipos_documento: 0,
        ctn_numero: "",
      };
    },

    clicked() {
      this.$emit("click");
    },
  },
};
</script>
