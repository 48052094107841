<style src="./RgFilterButton.scss" lang="scss" scoped></style>
<template lang="html">
  <div class="struct">
    <div class="item">
      <button class="bodybtn">
        <IconList />
      </button>
    </div>
    <div class="item">
      <input class="inputdata" placeholder="Selecione os Titulos da Tabela." />
    </div>
  </div>
</template>

<script>
import { IconList } from "~tokio/primitive/icon/symbols";

export default {
  name: "RgFilterButton",
  components: {
    IconList,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
