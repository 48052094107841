<style src="./RgInputCep.scss" lang="scss"></style>
<template lang="html">
  <fieldset class="rg-input--component">
    <RgFormBase
      :label="label"
      :required="isRequired"
      title="Código de Endereçamento Postal"
    >
      <div slot="right-label">
        <RgValidationAlert :alert="error" />
      </div>
      <div class="rg-input--base">
        <div class="rg-input--textbox-container">
          <input
            v-model="inputValue"
            v-mask="'#####-###'"
            :data-id="'CEP'"
            :tabindex="tabIndex"
            placeholder="Ex.: 00000-000"
            type="text"
            @blur="blur"
          />
        </div>
      </div>
    </RgFormBase>
  </fieldset>
</template>

<script>
import { RgFormBase } from "~tokio/foundation/container";
import RgValidationAlert from "~tokio/primitive/validation/rg-validation-alert/RgValidationAlert";
import RgInputMixin from "~tokio/primitive/input/mixin/RgInputMixin";
import RgValidatorMixin from "~tokio/primitive/validation/RgValidatorMixin";

import { mask } from "vue-the-mask";

export default {
  name: "RgInputCep",
  components: {
    RgValidationAlert,
    RgFormBase,
  },
  directives: {
    mask,
  },
  mixins: [RgValidatorMixin, RgInputMixin],
  props: {
    label: {
      type: String,
      default: "CEP",
    },
  },
  computed: {
    isRequired() {
      return this.rules && this.rules.required;
    },
  },
  methods: {
    blur() {
      this.$emit("blur", this.value);
      this.validate();
    },
  },
};
</script>
