<template lang="html">
  <svg
    width="40.217mm"
    height="40.217mm"
    version="1.1"
    viewBox="0 0 40.216667 40.216667"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(-84.969 -165.01)">
      <g transform="matrix(.083396 0 0 .083396 84.969 165.01)">
        <path d="m0 447.79h482.24v34.446h-482.24z" />
        <path
          d="m396.09 223.86-24.287-24.354-113.46 113.46v-312.97h-34.446v312.97l-113.5-113.5-24.22 24.354 154.94 155.07z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconPdfBioslab",
};
</script>
