import AuthRequest from "~common/request/AuthRequest";

export default (pData) => {
  const periodMonth = pData.Competence.substr(4, 2);
  const periodYear = pData.Competence.substr(0, 4);
  return AuthRequest.get("billing/report/get-bulletins-sum", {
    month: periodMonth,
    year: periodYear,
    UnitHealthId: pData.UnitHealthId,
  }).then((pResult) => {
    return pResult.data;
  });
};
