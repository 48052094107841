import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

import { OpenMenuModule } from "~common/utils/app/menu";

import AppointmentRegistrationQueueRoute from "$appointment/submodules/schedule/views/queue-schedule/AppointmentRegisterScheduleQueue";

export default {
  path: "/appointment/schedule/queue/schedule",
  name: "appointment.schedule.queue.schedule",
  components: {
    default: AppointmentRegistrationQueueRoute,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Consulta", link: OpenMenuModule("appointment") },
      { label: "Regulação", link: "/appointment/schedule/queue" },
      { label: "Agendar" },
    ],
  },
};
