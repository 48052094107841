import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query Occupation(
    $ocp_id: Int
    $ocp_nome: String
    $ocp_codigo: String
    $uns_id: Int
    $set_id: Int
    $limit: Int
  ) {
    occupation(
      ocp_id: $ocp_id
      ocp_nome: $ocp_nome
      ocp_codigo: $ocp_codigo
      uns_id: $uns_id
      set_id: $set_id
      limit: $limit
    ) {
      count
      rows {
        ocp_id
        ocp_nome
        ocp_codigo
      }
    }
  }
`;

export default async ({ variables }) => {
  const { data } = await GraphQLClient.query({ query, variables }).catch(
    (err) => {
      console.error(err);
    },
  );
  return data.occupation;
};
