<style src="./IndividualSearchFilter.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="individual-search-filter">
    <!-- <form class="form-container" @submit.prevent="searchFilter"> -->
    <div class="filtro">
      <RgRadioExamType v-model="form.examType" class="custom-radio" />

      <RgRadioExamStatus v-model="form.examStatus" class="custom-radio" />

      <div :class="{ disabled: !canSelectSector }" class="row">
        <RgComboboxSector
          v-model="form.sector"
          :unit-health="uns_id"
          :permission="sectorPermission"
          :disabled="!canSelectSector"
          data-id="setor-filtro"
          label="Setor"
          class="row-field"
        />
      </div>

      <div class="row">
        <RgSuggestEmployee
          ref="requestedEmployee"
          v-model="suggestRequestedEmployee"
          :rules="{ forceSelection: startToTypeSuggestRequestedEmployee }"
          data-id="profissional-solicitante"
          data-item="profissional-solicitante"
          class="row-field"
          label="Profissional Solicitante"
          @selected="selectingRequestedEmployee"
        />
      </div>

      <div class="row">
        <RgSuggestEmployee
          ref="employee"
          v-model="suggestEmployee"
          :rules="{ forceSelection: startToTypeSuggestEmployee }"
          :sector="form.sector"
          data-id="profissional-executante"
          data-item="profissional-executante"
          class="row-field"
          label="Profissional Executante"
          @selected="selectingEmployee"
        />
      </div>

      <div class="row mg">
        <RgInput
          v-model="form.patient"
          data-id="paciente"
          data-item="paciente"
          placeholder="Digite o nome do paciente"
          class="row-field"
          label="Nome do Paciente"
        />
      </div>

      <div class="row">
        <RgSuggestProcedure
          ref="Procedure"
          v-model="form.procedureCode"
          data-id="procedimento"
          data-item="procedimento"
          class="row"
          label="Procedimento"
          @selected="selectingProcedure"
        />
      </div>

      <div class="row">
        <div class="col-6">
          <RgInput
            v-model="form.recordNumber"
            data-id="prontuario"
            class="row-field"
            type="number"
            label="Prontuário"
          />
        </div>
        <div class="col-6">
          <RgInput
            v-model="form.bulletinNumber"
            data-id="boletim"
            type="number"
            class="row-field"
            label="Nº Boletim"
          />
        </div>
      </div>
      <div class="row">
        <span class="filter-title">Período de Solicitação</span>
        <div class="col-6">
          <RgInputDate
            v-model="form.requestInitialDate"
            data-id="data-inicio-solicitacao"
            label="Data início"
            placeholder="DD/MM/AAAA"
          />
        </div>

        <div class="col-6">
          <RgInputDate
            v-model="form.requestFinalDate"
            data-id="data-final-solicitacao"
            placeholder="DD/MM/AAAA"
            label="Data final"
          />
        </div>
      </div>

      <div :class="{ disabled: !canSelectEffectivatedPeriod }" class="row">
        <span class="filter-title">Período de Efetivação</span>
        <div class="col-6">
          <RgInputDate
            v-model="form.effectivenessInitialDate"
            :disabled="!canSelectEffectivatedPeriod"
            data-id="data-final-efetivacao"
            placeholder="DD/MM/AAAA"
            label="Data início"
          />
        </div>
        <div class="col-6">
          <RgInputDate
            v-model="form.effectivenessFinalDate"
            :disabled="!canSelectEffectivatedPeriod"
            data-id="data-final-efetivacao"
            placeholder="DD/MM/AAAA"
            label="Data final"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <span class="filter-title">Ordenação</span>
      <div class="col-6">
        <RgComboboxOrderFilter
          v-model="form.orderFilter"
          data-id="data-solicitacao"
          class="row-field"
        />
      </div>
      <div class="col-6">
        <RgComboboxOrderDirection
          v-model="form.orderDirection"
          data-id="direcao"
          class="row-field"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { isEmpty } from "lodash";

import {
  RgRadioExamStatus,
  RgRadioExamType,
  RgComboboxOrderDirection,
  RgComboboxOrderFilter,
} from "../../component";
import {
  RgSuggestProcedure,
  RgInputDate,
  RgInput,
  RgComboboxSector,
  RgSuggestEmployee,
} from "~tokio/primitive";
import EXAM_TYPES from "./../../component/radio/rg-radio-exam-type/GetExamTypes";
import EXAM_STATUS from "./../../component/radio/rg-radio-exam-status/GetExamStatus";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";

export default {
  name: "IndividualSearchFilter",
  components: {
    RgRadioExamStatus,
    RgRadioExamType,
    RgSuggestProcedure,
    RgInputDate,
    RgComboboxSector,
    RgSuggestEmployee,
    RgInput,
    RgComboboxOrderDirection,
    RgComboboxOrderFilter,
  },
  data() {
    return {
      sectorPermission: 247, // permissão para efetivar exames
      uns_id: null,
      suggestEmployee: "",
      suggestRequestedEmployee: "",
      canSelectSector: true,
      form: {
        examType: Object.values(EXAM_TYPES),
        examStatus: [EXAM_STATUS.ABERTO],
        orderFilter: "exe_data_solicitacao",
        orderDirection: "DESC",
        sector: null,
        exe_id_vinculos: null,
        employeeName: null,
        exe_id_vinculos_solicitante: null,
        requestName: null,
        patient: null,
        procedureCode: null,
        procedureName: null,
        recordNumber: null,
        bulletinNumber: null,
        requestInitialDate: null,
        requestFinalDate: null,
        effectivenessInitialDate: null,
        effectivenessFinalDate: null,
      },
    };
  },
  computed: {
    canSelectEffectivatedPeriod() {
      return !this.form.examStatus
        ? false
        : this.form.examStatus.includes(EXAM_STATUS.EFETIVADO);
    },
    startToTypeSuggestEmployee() {
      return !isEmpty(this.suggestEmployee);
    },
    startToTypeSuggestRequestedEmployee() {
      return !isEmpty(this.suggestRequestedEmployee);
    },
  },
  watch: {
    "form.examType"(pValue) {
      const onlyEmergency =
        pValue.length === 1 && pValue[0] === EXAM_TYPES.EMERGENCIA;
      if (!onlyEmergency) {
        this.canSelectSector = true;
      } else {
        this.canSelectSector = false;
        this.form.sector = null;
      }
    },
  },
  mounted() {
    this.$store.commit("Exam/IndividualEffectivation/CLEAN_SELECTED_DATA");
    this.$store.commit("Exam/IndividualEffectivation/CLEAN_LIST");
    this.uns_id = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
  },

  beforeDestroy() {
    const validateIfExist = ValidateIfRouteExistInBreadscrumb(
      "/exam/effectivation/individual",
      this.$route.meta.breadcrumb,
    );

    if (!validateIfExist) {
      this.$store.commit("Exam/IndividualEffectivation/CLEAN_FILTER");
    }
  },
  methods: {
    mountFilter(pFilter) {
      this.$loader.start("Carregando filtro...");

      this.form = {
        ...pFilter,
      };

      if (this.$refs.Procedure && pFilter.procedureCode) {
        this.$refs.Procedure.forceSelection({
          NO_PROCEDIMENTO: pFilter.procedureName,
          CO_PROCEDIMENTO: pFilter.procedureCode,
        });
      }

      if (this.$refs.requestedEmployee && pFilter.exe_id_vinculos_solicitante) {
        this.$refs.requestedEmployee.forceSelection({
          vin_id: pFilter.exe_id_vinculos_solicitante,
          pes_nome: pFilter.requestName,
        });
      }

      if (this.$refs.employee && pFilter.exe_id_vinculos) {
        this.$refs.employee.forceSelection({
          vin_id: pFilter.exe_id_vinculos,
          pes_nome: pFilter.employeeName,
        });
      }

      this.$loader.finish();
      this.$emit("reSearch");
    },

    addNewExamEffectivation() {
      this.$router.push("/exam/effectivation/details");
    },

    selectingProcedure(pProcedure) {
      if (pProcedure && pProcedure.source) {
        const { CO_PROCEDIMENTO, NO_PROCEDIMENTO } = pProcedure.source;
        this.form.procedureCode = CO_PROCEDIMENTO;
        this.form.procedureName = NO_PROCEDIMENTO;
      } else {
        this.form.procedureCode = null;
        this.form.procedureName = null;
      }
    },

    selectingEmployee(pEmployee) {
      if (pEmployee && pEmployee.source) {
        const { vin_id, pes_nome } = pEmployee.source;
        this.form.exe_id_vinculos = vin_id;
        this.form.employeeName = pes_nome;
      } else {
        this.form.exe_id_vinculos = null;
        this.form.employeeName = null;
      }
      this.form.exe_id_vinculos = pEmployee.source.vin_id || null;
      this.sectorLink = pEmployee.source.vus_id || null;
    },
    selectingRequestedEmployee(pEmployee) {
      if (pEmployee && pEmployee.source) {
        const { vin_id, pes_nome } = pEmployee.source;
        this.form.exe_id_vinculos_solicitante = vin_id;
        this.form.requestName = pes_nome;
      } else {
        this.form.exe_id_vinculos_solicitante = null;
        this.form.requestName = null;
      }
    },
    cleanForm() {
      this.suggestEmployee = "";
      this.suggestRequestedEmployee = "";
      this.form = {
        examType: Object.values(EXAM_TYPES),
        examStatus: [EXAM_STATUS.ABERTO],
        orderFilter: "exe_data_solicitacao",
        orderDirection: "DESC",
        sector: null,
        employeeName: null,
        exe_id_vinculos: null,
        requestName: null,
        exe_id_vinculos_solicitante: null,
        patient: null,
        procedureCode: null,
        procedureName: null,
        recordNumber: null,
        bulletinNumber: null,
        requestInitialDate: null,
        requestFinalDate: null,
        effectivenessInitialDate: null,
        effectivenessFinalDate: null,
      };
    },
    getOrderDirection() {
      if (
        this.form.orderFilter &&
        this.form.orderFilter === "exe_data_solicitacao"
      ) {
        return this.form.orderDirection.toLowerCase() === "asc"
          ? "desc"
          : "asc";
      }

      return this.form.orderDirection && this.form.orderDirection.length > 0
        ? this.form.orderDirection
        : null;
    },
    generateFilter() {
      const variables = {
        unitHealth: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        examType:
          this.form.examType && this.form.examType.length > 0
            ? this.form.examType
            : null,
        examStatus:
          this.form.examStatus && this.form.examStatus.length > 0
            ? this.form.examStatus
            : null,
        orderFilter:
          this.form.orderFilter && this.form.orderFilter.length > 0
            ? this.form.orderFilter
            : null,
        orderDirection: this.getOrderDirection(),
        patient:
          this.form.patient && this.form.patient.length > 0
            ? this.form.patient
            : null,
        sector:
          this.form.sector && this.form.sector > 0 ? this.form.sector : null,
        exe_id_vinculos:
          this.form.exe_id_vinculos && this.form.exe_id_vinculos > 0
            ? this.form.exe_id_vinculos
            : null,
        exe_id_vinculos_solicitante:
          this.form.exe_id_vinculos_solicitante &&
          this.form.exe_id_vinculos_solicitante > 0
            ? this.form.exe_id_vinculos_solicitante
            : null,
        procedureCode:
          this.form.procedureCode && this.form.procedureCode.length > 0
            ? this.form.procedureCode
            : null,
        recordNumber:
          this.form.recordNumber && this.form.recordNumber.length > 0
            ? Number(this.form.recordNumber)
            : null,
        bulletinNumber:
          this.form.bulletinNumber && this.form.bulletinNumber.length > 0
            ? Number(this.form.bulletinNumber)
            : null,
        requestInitialDate:
          this.form.requestInitialDate &&
          this.form.requestInitialDate.length > 0
            ? this.$utils.date.BrazilianDateToDatabase(
                this.form.requestInitialDate,
              )
            : null,
        requestFinalDate:
          this.form.requestFinalDate && this.form.requestFinalDate.length > 0
            ? this.$utils.date.BrazilianDateToDatabase(
                this.form.requestFinalDate,
              )
            : null,
        effectivenessInitialDate:
          this.form.effectivenessInitialDate &&
          this.form.effectivenessInitialDate.length > 0
            ? this.$utils.date.BrazilianDateToDatabase(
                this.form.effectivenessInitialDate,
              )
            : null,
        effectivenessFinalDate:
          this.form.effectivenessFinalDate &&
          this.form.effectivenessFinalDate.length > 0
            ? this.$utils.date.BrazilianDateToDatabase(
                this.form.effectivenessFinalDate,
              )
            : null,
      };

      this.$store.commit("Exam/IndividualEffectivation/SET_FILTER", this.form);

      return variables;
    },
  },
};
</script>
