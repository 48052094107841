import { isObject, isString, unset, merge, isArray } from "lodash";
import moment from "moment";
export default class BaseSanitizer {
  constructor() {
    this.ignoreList = [];
  }

  sanitize(pData, ignoreList = []) {
    const sanitizedData = {};
    this.ignoreList = ignoreList;
    const itemsToSanitize = this.getItemsToSanitize(pData);
    for (const item in itemsToSanitize) {
      if (isObject(itemsToSanitize[item])) {
        sanitizedData[item] = this.sanitizeObject(itemsToSanitize[item]);
        continue;
      }
      if (isString(itemsToSanitize[item])) {
        sanitizedData[item] = this.sanitizeString(itemsToSanitize[item]);
        continue;
      }
    }

    const response = merge(pData, sanitizedData);
    return response;
  }

  getItemsToSanitize(pData) {
    let itemsToSanitize = JSON.parse(JSON.stringify(pData));
    let convertBackToObject = false;
    this.ignoreList.forEach((item) => {
      if (!isArray(pData)) {
        itemsToSanitize = [itemsToSanitize];
        convertBackToObject = true;
      }

      for (let i = 0; i < itemsToSanitize.length; i++) {
        const shouldBeIgnored = true;
        if (shouldBeIgnored) {
          unset(itemsToSanitize[i], item);
        }
      }
    });
    return convertBackToObject ? itemsToSanitize[0] : itemsToSanitize;
  }

  sanitizeObject(pObject) {
    for (const item in pObject) {
      if (isObject(pObject[item])) {
        pObject[item] = this.sanitizeObject(pObject[item]);
        continue;
      }
      if (isString(pObject[item])) {
        pObject[item] = this.sanitizeString(pObject[item]);
        continue;
      }
    }
    return pObject;
  }

  sanitizeString(value) {
    const dateRegex = new RegExp("[0-9]{4}-[0-9]{2}-[0-9]{2}");
    if (moment(value, "YYYY-MM-DD").isValid() && dateRegex.test(value)) {
      return moment(value, "YYYY-MM-DD").format("L");
    }
    return this.startCase(value.toLowerCase());
  }

  startCase(value) {
    const valueSplitted = value.split(" ");
    let upperCased = "";
    upperCased = valueSplitted.map((pWord) => {
      if (pWord.length > 0) {
        const firstUpperCase = pWord[0].toUpperCase();
        let restCase = "";
        if (pWord.length > 1) restCase = pWord.substr(1, pWord.length - 1);
        return `${firstUpperCase}${restCase}`;
      }
    });
    return upperCased.join(" ");
  }
}
