<template>
  <div class="history-emergency-details">
    <div class="basic">
      <span class="label"
        >N° Boletim: <strong>{{ getValue("number") }}</strong>
      </span>
      <span class="label"
        >Classificação: <strong>{{ getValue("classification") }}</strong>
      </span>
      <span class="label"
        >Data da Saída: <strong>{{ getValue("out_date") }}</strong>
      </span>
      <span class="label"
        >Motivo Saída: <strong>{{ getValue("out_reason") }}</strong>
      </span>
      <span class="label"
        >Usuário da Saída: <strong>{{ getValue("user") }}</strong>
      </span>
      <span class="label"
        >Local de Destino: <strong>{{ getValue("destination") }}</strong>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: "HistoryEmergencyDetails",
  components: {},
  props: {
    details: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  methods: {
    getValue(pName) {
      const hasValue = this.details && this.details[pName];
      return hasValue ? this.details[pName] : "Não informado";
    },
  },
};
</script>
