<template lang="html">
  <section class="unit-logon">
    <div
      title="Troque a unidade de saúde atual"
      class="unit"
      @click.stop="openUnitHealth"
    >
      <div class="icon-unit">
        <IconUnitHealth class="svg" />
      </div>
      <div class="unit-name">
        <span>
          {{ unitHealth }}
        </span>
      </div>
    </div>

    <div class="border" />
  </section>
</template>

<script>
import { IconUnitHealth } from "~tokio/primitive/";

export default {
  name: "NoticeBoard",
  components: {
    IconUnitHealth,
  },
  data() {
    return {};
  },

  computed: {
    unitHealth() {
      return this.$store.getters["Login/GET_UNIT_HEALTH_NAME"];
    },
  },
  methods: {
    openUnitHealth() {
      this.$router.push({
        name: "accesscontrol.selectUnitHealth",
      });
    },
  },
};
</script>
