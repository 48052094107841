import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (context, payload) => {
  return AuthLegacyRequest.post(
    "exames2/controller-agendamento-consulta/carregar-dados-comprovante-agendamento",
    payload,
  )
    .then(({ data }) => {
      data = HandleResponse(data);
      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
