<style src="./RgRadioList.scss" lang="scss" scoped></style>
<template lang="html">
  <fieldset class="radio-list">
    <RgFormBase :label="label" class="radio-form" :required="isRequired">
      <div class="radio-list-container">
        <label
          v-for="item in getData()"
          :key="item.value"
          :tabIndex="0"
          class="label"
          :style="{
            'border-color': getBorderColor(item),
            'background-color': getBackgroundColor(item),
          }"
          :title="getTitle(item)"
          @keypress="selectByKeyboard"
        >
          <input
            v-model="picked"
            class="input"
            :value="item.value"
            type="radio"
            @click="deSelectIfSame(item.value)"
          />
          <span :style="{ color: getTextColor(item) }" class="text unselect">{{
            item.text
          }}</span>
        </label>
      </div>
      <RgValidationAlert :alert="error" />
    </RgFormBase>
  </fieldset>
</template>

<script>
import { RgFormBase } from "~tokio/foundation/container";
import {
  RgValidatorMixin,
  RgValidationAlert,
} from "~tokio/primitive/validation";

export default {
  name: "RgRadioList",
  components: {
    RgValidationAlert,
    RgFormBase,
  },
  mixins: [RgValidatorMixin],
  props: {
    value: {
      type: [Number, String],
      default: "",
    },
    label: {
      type: String,
      default: "Selecione uma Opção",
    },
    items: {
      Type: Array,
      default: () => {
        return [
          {
            value: 1,
            text: "Opção 1",
            color: "#CCC",
            textColorSelected: "#FFF",
          },
          {
            value: 2,
            text: "Opção 2",
            color: "red",
            textColorSelected: "#FFF",
          },
          {
            value: 3,
            text: "Opção 3",
            color: "green",
            textColorSelected: "#FFF",
          },
        ];
      },
    },
  },
  data() {
    return {
      picked: null,
      anotherRules: {
        correctID(id, errors) {
          if (id === null) {
            return true;
          }
          const values = this.getData().map((item) => item.value);
          const texts = this.getData().map((item) => item.text);
          if (!values.includes(id)) {
            errors.push(
              "Valor Inválido! Selecione um entre: " + texts.join(","),
            );
            return false;
          }
          return true;
        },
      },
    };
  },
  computed: {
    validValue() {
      return this.picked;
    },
    defaultSelectedTextColor() {
      return "#FFF";
    },
    defaultSelectedBackgroundColor() {
      return "#5295E2";
    },
    defaultBackgroundColor() {
      return "#F9F9F9";
    },
    defaultTextColor() {
      return "#5295E2";
    },
    isRequired() {
      return this.rules && this.rules.required;
    },
  },
  watch: {
    picked(newValue) {
      this.validate();
      this.$emit("input", newValue);
    },
    value(newValue) {
      this.picked = newValue;
    },
  },
  mounted() {
    this.picked = this.value;
  },
  methods: {
    deSelectIfSame(value) {
      if (value === this.picked) {
        this.picked = null;
      }
    },
    selectByKeyboard(k) {
      k.preventDefault();
      const validKeys = ["Space", "Enter"];
      if (validKeys.includes(k.code)) {
        /* k.currentTarget.control._value significa exatamente o mesmo valor passado para o input
        a propriedade value converte para string */
        const selected = this.getData().find(
          (i) => i.value === k.currentTarget.control._value,
        );
        if (selected && selected.value !== undefined) {
          this.picked = selected.value;
        }
      }
    },
    getData() {
      return this.items;
    },
    getTextColor(item) {
      if (item.value === this.picked) {
        return item.textColorSelected || this.defaultSelectedTextColor;
      }
      return item.color || this.defaultTextColor;
    },
    getBorderColor(item) {
      if (item.value === this.picked) {
        return item.color || this.defaultSelectedBackgroundColor;
      }
      return item.color || this.defaultTextColor;
    },
    getBackgroundColor(item) {
      if (item.value === this.picked) {
        return item.color || this.defaultSelectedBackgroundColor;
      }
      return this.defaultBackgroundColor;
    },
    getTitle(item) {
      return item.title || item.text;
    },
  },
};
</script>
