import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import IntegrationTokenRegister from "@app/configuration/views/integration-token-register/IntegrationTokenRegister";

export default {
  path: "/configuration/integration-token-register",
  name: "configuration.integration-token-register",
  components: {
    default: IntegrationTokenRegister,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [{ label: "Configuração" }, { label: "Tokens de Integrações" }],
  },
};
