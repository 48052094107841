<template lang="html">
  <svg
    width="23"
    height="18"
    viewBox="0 0 23 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.3645 0H2.63548C1.18166 0 0 1.23303 0 2.75006V15.2499C0 16.767 1.18166 18 2.63548 18H20.3645C21.8183 18 23 16.767 23 15.2499V2.75006C23 1.23303 21.8183 0 20.3645 0V0ZM7.1875 3.99994C8.50813 3.99994 9.58327 5.12201 9.58327 6.50006C9.58327 7.87793 8.50813 9 7.1875 9C5.86687 9 4.79173 7.87793 4.79173 6.50006C4.79173 5.12201 5.86687 3.99994 7.1875 3.99994ZM11.5 13.2501C11.5 13.6641 11.178 14.0001 10.7812 14.0001H3.59375C3.197 14.0001 2.875 13.6641 2.875 13.2501V12.75C2.875 11.233 4.05666 9.99994 5.51048 9.99994H8.86452C10.3183 9.99994 11.5 11.233 11.5 12.75V13.2501ZM19.4062 14.0001H14.1355C13.7387 14.0001 13.4167 13.6641 13.4167 13.2501C13.4167 12.8361 13.7387 12.5001 14.1355 12.5001H19.4062C19.803 12.5001 20.125 12.8361 20.125 13.2501C20.125 13.6641 19.803 14.0001 19.4062 14.0001ZM19.4062 9.99994H14.1355C13.7387 9.99994 13.4167 9.66394 13.4167 9.24994C13.4167 8.83594 13.7387 8.49994 14.1355 8.49994H19.4062C19.803 8.49994 20.125 8.83594 20.125 9.24994C20.125 9.66394 19.803 9.99994 19.4062 9.99994ZM19.4062 6H14.1355C13.7387 6 13.4167 5.664 13.4167 5.25C13.4167 4.836 13.7387 4.5 14.1355 4.5H19.4062C19.803 4.5 20.125 4.836 20.125 5.25C20.125 5.664 19.803 6 19.4062 6Z"
    />
  </svg>
</template>

<script>
export default {
  name: "Card",
};
</script>
