<template lang="html">
  <svg
    enable-background="new 0 0 512 512"
    version="1.1"
    viewBox="0 0 512 512"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(3.8671 5.5089)" fill="#c0cadd">
      <g fill="#c0cadd">
        <g fill="#c0cadd">
          <g fill="#c0cadd">
            <polygon
              points="267.31 61.248 288 81.936 310.62 59.312 251.31 0 192 59.312 214.62 81.936 235.31 61.248 235.31 118.62 267.31 118.62"
            />
            <polygon
              points="395.31 150.62 395.31 182.62 436.69 182.62 416 203.31 438.62 225.94 497.94 166.62 438.62 107.31 416 129.94 436.69 150.62"
            />
            <polygon
              points="64 225.94 86.624 203.31 65.936 182.62 107.31 182.62 107.31 150.62 65.936 150.62 86.624 129.94 64 107.31 4.688 166.62"
            />
            <path
              d="m299.31 198.62c0-26.51-21.49-48-48-48s-48 21.49-48 48v64c-35.346 0-64 28.654-64 64v85.328l48 64v26.672h32v-37.328l-48-64v-74.672c0-17.673 14.327-32 32-32v80h32v-176c0-8.837 7.163-16 16-16s16 7.163 16 16v91.088l128 48v65.136l-32 64v35.776h32v-28.224l32-64v-94.864l-128-48z"
            />
            <rect x="139.31" y="150.62" width="32" height="32" />
            <rect x="331.31" y="150.62" width="32" height="32" />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "Movements",
};
</script>
