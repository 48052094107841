<style src="./RgSuggestUser.scss" lang="scss" scoped></style>
<template>
  <div class="rg-suggest-user">
    <RgSuggest
      ref="rgSuggest"
      v-model="inputValue"
      :label="label"
      :placeholder="placeholder"
      :rules="rules"
      :search-on-focus="searchOnFocus"
      min="0"
    >
      <RgSuggestItem
        v-for="(item, idx) in suggestionList"
        :key="idx"
        :idx="idx"
        :item="item"
        class="user-item"
      >
        <div class="user-row-top">
          <span class="user-name">{{ item.usu_nome }}</span>
        </div>
      </RgSuggestItem>
    </RgSuggest>
  </div>
</template>
<script>
import { RgSuggest, RgSuggestMixin, RgSuggestItem } from "~tokio/primitive";
import { SearchUser } from "./action";
import { isEmpty } from "lodash";

export default {
  name: "RgSuggestUser",
  components: {
    RgSuggest,
    RgSuggestItem,
  },
  mixins: [RgSuggestMixin],
  props: {
    placeholder: {
      type: String,
      default: "Digite o nome do usuário",
    },
  },
  data() {
    return {
      captionRaw: "usu_nome",
    };
  },
  computed: {
    searchOnFocus() {
      return this.inputValue === "" || this.inputValue === null;
    },
  },

  methods: {
    validate() {
      this.$refs.rgSuggest.validate();
    },

    cleanValidate() {
      this.$refs.rgSuggest.cleanValidate();
    },

    doSearch(pSearchTerm, pLimit = null) {
      return SearchUser({ usuNome: pSearchTerm, limit: pLimit });
    },

    forceSelection(pValue) {
      if (pValue && !isEmpty(pValue.usu_nome) && pValue.usu_id > 0) {
        this.$refs.rgSuggest.itemSelected = true;
        if (
          !this.suggestionList ||
          (this.suggestionList && this.suggestionList.length === 0)
        ) {
          this.suggestionList.push({
            usu_nome: pValue.usu_nome,
            usu_id: pValue.usu_id,
            ...pValue,
          });
        }
        this.$refs.rgSuggest.$parent.selectingItemFromSuggestList(pValue);
      }
    },
  },
};
</script>
