import GetPermitedUnitHealths from "$access-control/service/GetPermitedUnitHealths";

export default async ({ commit, state }) => {
  if (state.unit_healths.length > 0) {
    return;
  }
  try {
    const data = await GetPermitedUnitHealths("Atencao_Basica");
    commit("SET_UNIT_HEALTHS", data);
  } catch (err) {
    console.log(err);
    return err;
  }
};
