import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import HospitalizationDetails from "$hospitalization/hospitalizations/view/details/HospitalizationDetails.vue";
import OpenHospitalizationModule from "$hospitalization/common/util/OpenHospitalizationModule.js";

export default {
  path: "/hospitalization/bed/search/new-hospitalization",
  name: "hospitalization.bed.search.new-hospitalization",
  components: {
    default: HospitalizationDetails,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Internação", link: OpenHospitalizationModule },
      {
        label: "Lista de Leitos",
        link: "/hospitalization/bed/search",
      },
      { label: "Cadastrar Internação" },
    ],
  },
};
