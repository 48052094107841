<style src="./ListContentAih.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="list-content">
    <ul v-if="hospitalizationList" class="hospitalization-list">
      <li
        v-for="(hospitalizationBillingAih, idx) in hospitalizationList.rows"
        :key="idx"
        class="item"
        @click="selectHospitalization(hospitalizationBillingAih.iaf_id)"
      >
        <div class="column-numbers">
          <div class="field hospitalization-number">
            <span title="Nº da AIH">
              <IconDocument style="margin-right: 2px" />
              {{ hospitalizationBillingAih.iaf_numero_aih | aih }}
            </span>
          </div>
          <div class="person-name-container">
            <span class="field hospitalization-date" title="Data de Internação">
              <IconCalendar style="margin-right: 9px" />
              {{ hospitalizationBillingAih.iaf_data_internacao | dateBR }}
            </span>
          </div>
          <div class="field hospitalization-medical-records">
            <span title="Prontuário">
              <IconRecords />
              {{ hospitalizationBillingAih.iaf_prontuario | fillZero }}
            </span>
          </div>
        </div>

        <div class="column-person-info">
          <div class="person-name-container">
            <span class="field person-name" title="Nome do Paciente">
              <IconUser />
              {{ isIndigent(hospitalizationBillingAih) }}
            </span>
          </div>
          <div class="person-name-container">
            <span class="field hospitalization-date" title="Data de Saída">
              <IconCalendar />
              {{ hospitalizationBillingAih.iaf_data_saida | dateBR }}
            </span>
          </div>

          <div class="person-info-container">
            <span class="field person-cns" title="Nº CNS">
              <IconCard />
              {{ hospitalizationBillingAih.icf_cns | cns }}
            </span>
            <span class="field person-birth" title="Data de Nascimento">
              <IconCake />
              {{ hasBirthDate(hospitalizationBillingAih) | dateBR }}
            </span>
          </div>
        </div>

        <div
          v-if="openedOrBilled(hospitalizationBillingAih.iaf_faturado)"
          class="col-1 actions"
        >
          <RgRemoveSmall
            class="btn-margin-bottom"
            @confirm-remove="removeAih(hospitalizationBillingAih.iaf_id)"
          />
        </div>
      </li>
    </ul>

    <div v-show="emptyContainer" class="empty-container">
      <IconEmpty class="empty-icon" />
      <span class="empty-description">Nenhum Registro</span>
    </div>
  </section>
</template>

<script>
import {
  IconCalendar,
  IconUser,
  IconRecords,
  IconDocument,
  IconCard,
  IconCake,
  IconEmpty,
} from "~tokio/primitive";
import { RgRemoveSmall } from "~tokio/primitive/button";
import { AlertError } from "../../../service/";
import moment from "moment";

export default {
  name: "ListContentAih",
  components: {
    IconCalendar,
    IconUser,
    IconDocument,
    IconCard,
    IconCake,
    IconEmpty,
    IconRecords,
    RgRemoveSmall,
  },
  filters: {
    cns(pValue) {
      if (!pValue) return "NÃO INFORMADO";
      return pValue.replace(/(\d{3})(\d{4})(\d{4})(\d{4})/gi, "$1 $2 $3 $4");
    },
    dateBR(pValue) {
      if (!pValue || pValue === "0000-00-00") return "NÃO INFORMADO";
      return moment(pValue).format("DD/MM/YYYY");
    },
    aih(pValue) {
      if (!pValue) return "000000000000-0";
      return pValue.replace(/(\d{12})(\d{1})/gi, "$1-$2");
    },
    fillZero(pNumber) {
      if (!pNumber) return "NÃO INFORMADO";
      return `0000000000${pNumber}`.slice(-10);
    },
  },
  props: {
    hospitalizationList: {
      type: Object,
      default: () => [],
    },
  },
  computed: {
    patientDocuments() {
      return {
        ...this.$store.getters[
          "Billing/HospitalizationBilling/GET_PATIENT_DOCUMENTS"
        ],
      };
    },
    emptyContainer() {
      return !this.hospitalizationList || this.hospitalizationList.length <= 0;
    },
  },
  methods: {
    selectHospitalization(iaf_id) {
      this.$emit("selectHospitalization", { iaf_id });
    },
    isIndigent(hospitalizationBillingAih) {
      if (hospitalizationBillingAih.icf_nome) {
        return hospitalizationBillingAih.icf_nome;
      }

      return "PACIENTE NÃO IDENTIFICADO";
    },
    hasBirthDate(hospitalizationBillingAih) {
      if (hospitalizationBillingAih.icf_data_nascimento) {
        return hospitalizationBillingAih.icf_data_nascimento;
      }

      return null;
    },
    documentType(icfTipoDocumento) {
      return this.patientDocuments[icfTipoDocumento];
    },
    removeAih(aihId) {
      const STATUS_EXCLUIDO = 3;
      this.$store
        .dispatch(
          "Billing/HospitalizationBilling/BILL_HOSPITALIZATION_BILLING_AIH",
          {
            hospitalizationBillingAihId: aihId,
            status: STATUS_EXCLUIDO,
          },
        )
        .then((pRes) => {
          AlertError("AIH Excluida com sucesso.", [], "success");
          this.$emit("reloadSearch");
        })
        .catch((pErr) => {
          AlertError("Houve um erro ao excluir a AIH.", [], "error");
        });
    },
    openedOrBilled(iafFaturado) {
      return ["0", "1"].includes(iafFaturado);
    },
  },
};
</script>
