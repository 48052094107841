<style src="./MediumButton.scss" lang="scss" scoped></style>
<template>
  <div class="medium-button">
    <RgButton
      :disabled="disabled"
      :data-id="dataId"
      :permission="permission"
      :title="title"
      class="button"
      :style="{
        background: backgroundColor,
      }"
      @click="clicked"
    >
      <div
        class="label"
        :style="{
          color: fontColor,
        }"
      >
        {{ label }}
      </div>
    </RgButton>
  </div>
</template>
<script>
import RgButton from "~tokio/primitive/button/rg-button/RgButton.vue";
import RgButtonMixin from "~tokio/primitive/button/rg-button/RgButtonMixin.js";

export default {
  name: "MediumButton",
  components: { RgButton },
  mixins: [RgButtonMixin],
  props: {
    fontColor: {
      type: String,
      default: "#fff",
    },
    backgroundColor: {
      type: String,
      default: "#53B1D8",
    },
  },
};
</script>
