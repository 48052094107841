<template lang="html">
  <svg
    width="55"
    height="50"
    viewBox="0 0 55 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.13636 29.5454C0.990909 29.5454 0.840909 29.5182 0.702273 29.4591C0.277273 29.2841 0 28.8682 0 28.4091V10.2272C0 9.76816 0.277273 9.35225 0.702273 9.17725C1.125 9.00225 1.61591 9.0977 1.94091 9.4227L11.0318 18.5136C11.475 18.9568 11.475 19.6772 11.0318 20.1204L1.94091 29.2113C1.72273 29.4295 1.43182 29.5454 1.13636 29.5454V29.5454ZM2.27273 12.9704V25.6659L8.62046 19.3182L2.27273 12.9704Z"
      fill="white"
    />
    <path
      d="M51.136 13.6364H14.7724C12.8928 13.6364 11.3633 12.1068 11.3633 10.2273V3.40909C11.3633 1.52955 12.8928 0 14.7724 0H51.136C53.0155 0 54.5451 1.52955 54.5451 3.40909V10.2273C54.5451 12.1068 53.0155 13.6364 51.136 13.6364ZM14.7724 2.27273C14.1474 2.27273 13.636 2.78182 13.636 3.40909V10.2273C13.636 10.8545 14.1474 11.3636 14.7724 11.3636H51.136C51.761 11.3636 52.2724 10.8545 52.2724 10.2273V3.40909C52.2724 2.78182 51.761 2.27273 51.136 2.27273H14.7724Z"
      fill="white"
    />
    <path
      d="M51.136 50H14.7724C12.8928 50 11.3633 48.4705 11.3633 46.5909V28.4091C11.3633 26.5295 12.8928 25 14.7724 25H51.136C53.0155 25 54.5451 26.5295 54.5451 28.4091V46.5909C54.5451 48.4705 53.0155 50 51.136 50ZM14.7724 27.2727C14.1474 27.2727 13.636 27.7818 13.636 28.4091V46.5909C13.636 47.2182 14.1474 47.7273 14.7724 47.7273H51.136C51.761 47.7273 52.2724 47.2182 52.2724 46.5909V28.4091C52.2724 27.7818 51.761 27.2727 51.136 27.2727H14.7724Z"
      fill="white"
    />
    <path
      d="M32.9528 13.6364C32.3255 13.6364 31.8164 13.1273 31.8164 12.5V1.13636C31.8164 0.509091 32.3255 0 32.9528 0C33.58 0 34.0891 0.509091 34.0891 1.13636V12.5C34.0891 13.1273 33.58 13.6364 32.9528 13.6364Z"
      fill="white"
    />
    <path
      d="M32.9528 50C32.3255 50 31.8164 49.4909 31.8164 48.8636V26.1364C31.8164 25.5091 32.3255 25 32.9528 25C33.58 25 34.0891 25.5091 34.0891 26.1364V48.8636C34.0891 49.4909 33.58 50 32.9528 50Z"
      fill="white"
    />
    <path
      d="M53.4087 38.6364H12.4996C11.8724 38.6364 11.3633 38.1273 11.3633 37.5C11.3633 36.8727 11.8724 36.3636 12.4996 36.3636H53.4087C54.036 36.3636 54.5451 36.8727 54.5451 37.5C54.5451 38.1273 54.036 38.6364 53.4087 38.6364Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: "InsertOnQueue",
};
</script>
