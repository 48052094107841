import SAVE_BIOSLAB_LABORATORY_EXAMS from "./SaveBioslabLaboratoryExams";
import SAVE_BIOSLAB_LABORATORY_PATIENT_EXAMS from "./SaveBioslabLaboratoryPatientExams";
import SAVE_TO_BIOSLAB from "./SaveToBioslab";
import SEARCH_BIOSLAB_LABORATORY_PATIENT_EXAMS from "./SearchBioslabLaboratoryPatientExams";
import SEARCH_BIOSLAB_LABORATORY_EXAM_LIST from "./SearchBioslabLaboratoryExamList";
import GET_SIGNATURE from "./GetSignature";
import GET_CERTIFICATE from "./GetCertificate";

export default {
  SAVE_BIOSLAB_LABORATORY_EXAMS,
  SAVE_BIOSLAB_LABORATORY_PATIENT_EXAMS,
  SAVE_TO_BIOSLAB,
  SEARCH_BIOSLAB_LABORATORY_PATIENT_EXAMS,
  SEARCH_BIOSLAB_LABORATORY_EXAM_LIST,
  GET_SIGNATURE,
  GET_CERTIFICATE,
};
