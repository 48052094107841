export default {
  MOVIMENTACAO: 1,
  INTERNACAO: 2,
  RESERVA: 3,
  TRANSFERENCIA: 4,
  PERMUTA: 5,
  SAIDA: 6,
  EXCLUIR_INTERNACAO: 7,
  EXCLUIR_RESERVA: 8,
  EXCLUIR_SAIDA: 9,
};
