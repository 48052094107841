<template lang="html">
  <section class="patient-info">
    <form class="form" @submit.prevent="saveIntoDatabase">
      <RgValidatorForm ref="validator" class="patient-info-validator">
        <ModuleBox :granted="hasPermission" title="Informações do paciente">
          <div v-if="isImportedBulletinBilling" slot="title">
            <ImportedBillingToBilledButton @confirmed="billImportedBulletin" />
          </div>
          <div class="row">
            <RgSuggestPatient
              v-model="patient.bof_nome"
              :rules="{ required: true, fullName: true, fn: validateName }"
              class="col-5 _margin-bottom-zero"
              label="Nome do paciente"
              placeholder="Informe o nome do paciente"
              @selected="selectingPatient"
            />
            <RgInputDate
              v-model="patient.bof_data_nascimento"
              :rules="inputDateRules"
              class="col-2 birth-field"
              label="Nascimento"
              placeholder=""
            />
            <RgInputSuscard
              v-model="patient.bof_cns"
              class="col-2"
              label="CNS"
              placeholder=""
            />
            <RgRadioGenderBlock v-model="patient.bof_sexo" class="col-3" />
          </div>
          <div class="row">
            <RgTypeaheadNationality
              v-model="patient.bof_cod_nacionalidade"
              :rules="{ required: true }"
              class="col-4"
              label="Nacionalidade"
              placeholder=""
            />
            <RgComboboxRaceCor
              v-model="patient.bof_cod_raca"
              :rules="{ required: true }"
              class="col-4"
              label="Raça/Cor"
              placeholder=""
            />
            <RgTypeaheadEthnicity
              v-model="patient.bof_cod_etnia"
              class="col-4"
              label="Etnia"
              placeholder=""
            />
          </div>

          <div class="row">
            <RgInputPhone
              v-model="patient.bof_ddd_telefone"
              class="col-2"
              label="Telefone"
              placeholder=""
            />
            <RgInputCep
              v-model="patient.bof_cep"
              :rules="{ required: true, fn: validateCepExists }"
              class="col-2"
              label="CEP"
              placeholder=""
              @blur="defineCep"
            />
            <RgTypeaheadCounty
              v-model="patient.bof_cod_municipio"
              :rules="{ required: true }"
              class="col-3"
              label="Municipio de residência"
              placeholder=""
            />
            <RgTypeaheadPublicPlace
              v-model="patient.bof_cod_tipo_logradouro"
              :rules="{ required: true }"
              class="col-2"
              label="Logradouro"
              placeholder=""
            />
            <RgInput
              v-model="patient.bof_endereco"
              :rules="{ required: true, fn: validateSpecialCharacter }"
              class="col-3"
              label="Endereço"
              placeholder=""
            />
          </div>

          <div class="row">
            <RgInputEmail
              v-model="patient.bof_email"
              class="col-4"
              label="E-mail"
              :maxlength="80"
              placeholder=""
            />
            <RgInput
              v-model="patient.bof_endereco_numero"
              :rules="{ required: true, fn: validateSpecialCharacter }"
              class="col-2"
              label="Número"
              placeholder=""
            />
            <RgInput
              v-model="patient.bof_endereco_complemento"
              :rules="{ fn: validateSpecialCharacter }"
              class="col-3"
              label="Complemento"
              placeholder=""
            />
            <RgInput
              v-model="patient.bof_bairro"
              :rules="{ required: true, fn: validateSpecialCharacter }"
              class="col-3"
              label="Bairro"
              placeholder=""
            />
          </div>

          <div class="row">
            <RgSubmit
              ref="btnSave"
              :label="typeOfInteraction"
              @submit="saveIntoDatabase"
            />
          </div>
        </ModuleBox>
      </RgValidatorForm>
    </form>
  </section>
</template>

<script>
import moment from "moment";

import {
  RgSuggestPatient,
  RgInput,
  RgInputDate,
  RgInputSuscard,
  RgInputPhone,
  RgValidatorForm,
  RgSubmit,
  RgInputCep,
  RgInputEmail,
} from "~tokio/primitive";
import ModuleBox from "~tokio/foundation/modulebox/Modulebox";
import { RgRadioGenderBlock } from "$patient/common/components";
import RgTypeaheadNationality from "$billing/common/component/rg-typeahead-nationality/RgTypeaheadNationality";
import RgTypeaheadPublicPlace from "$billing/common/component/rg-typeahead-public-place/RgTypeaheadPublicPlace";
import RgTypeaheadCounty from "$billing/common/component/rg-typeahead-county/RgTypeaheadCounty";
import RgTypeaheadEthnicity from "$billing/common/component/rg-typeahead-ethnicity/RgTypeaheadEthnicity";
import RgComboboxRaceCor from "$billing/common/component/rg-combobox-race-cor/RgComboboxRaceCor";
import GetPatientInfo from "../../action/getPatientInfo";
import GetPatientById from "../../action/getPatientById";
import SaveBulletinIntoDatabase from "../../action/SaveBulletinIntoDatabase";
import CheckCEP from "$billing/billing/action/CheckCEP";
import ImportedBillingToBilledButton from "./imported-billing-to-billed-button/ImportedBillingToBillButton";
import { isEmpty } from "lodash";

export default {
  name: "PatientInfo",
  components: {
    ModuleBox,
    RgInput,
    RgInputDate,
    RgInputSuscard,
    RgRadioGenderBlock,
    RgTypeaheadNationality,
    RgTypeaheadPublicPlace,
    RgTypeaheadCounty,
    RgTypeaheadEthnicity,
    RgComboboxRaceCor,
    RgInputPhone,
    RgInputCep,
    RgSuggestPatient,
    RgValidatorForm,
    RgInputEmail,
    RgSubmit,
    ImportedBillingToBilledButton,
  },
  data() {
    return {
      patientPanelExpanded: false,
      patient: {
        bof_id: null,
        bof_nome: "",
        bof_sexo: "M",
        bof_cns: "",
        bof_data_nascimento: "",
        bof_cod_raca: "99",
        bof_cod_nacionalidade: "",
        bof_cod_etnia: "",
        bof_ddd_telefone: "",
        bof_email: "",
        bof_cep: "",
        bof_cod_municipio: "",
        bof_cod_tipo_logradouro: "",
        bof_endereco: "",
        bof_endereco_numero: "",
        bof_endereco_complemento: "",
        bof_bairro: "",
      },
      patientName: "",
      saveEnabled: true,
    };
  },
  computed: {
    bulletinId() {
      return this.$store.getters["Billing/Bulletin/GET_SELECTED_BULLETIN"];
    },
    typeOfInteraction() {
      return this.hasBofId ? "Atualizar" : "Salvar";
    },
    inputDateRules() {
      return { required: true };
    },
    hasPermission() {
      return this.$Permissions.global.has("faturamento.atendimentosEmergencia");
    },
    hasBofId() {
      return this.$store.getters["Billing/Billing/HAS_BOF_ID"];
    },
    isImportedBulletinBilling() {
      return this.$store.getters["Billing/Billing/GET_BOF_FATURADO"] === 2;
    },
  },
  watch: {
    bulletinId() {
      this.refreshPatientForm();
    },
  },
  mounted() {
    this.refreshPatientForm();
    this.patientName = this.$store.getters["Billing/Billing/GET_PATIENT_NAME"];
  },
  beforeDestroy() {
    const emptyPatient = {
      bof_id: null,
      bof_nome: "",
      bof_sexo: "",
      bof_cns: "",
      bof_data_nascimento: "",
      bof_cod_raca: "",
      bof_cod_nacionalidade: "",
      bof_cod_etnia: "",
      bof_ddd_telefone: "",
      bof_email: "",
      bof_cep: "",
      bof_cod_municipio: "",
      bof_cod_tipo_logradouro: "",
      bof_endereco: "",
      bof_endereco_numero: "",
      bof_endereco_complemento: "",
      bof_bairro: "",
    };
    this.patient = emptyPatient;
    this.$store.commit("Billing/Billing/DESTROY_BILLING");
    this.$store.commit("Billing/Bulletin/DESTROY_BULLETIN");
  },
  methods: {
    selectingPatient(pPatientData) {
      if (
        !pPatientData ||
        !pPatientData.source ||
        !pPatientData.source.pes_id
      ) {
        return false;
      }
      GetPatientById({ id: pPatientData.source.pes_id }).then((patientData) => {
        const filledPatient = {
          bof_nome: patientData.pes_nome,
          bof_sexo: patientData.bof_sexo,
          bof_cns: patientData.bof_cns,
          bof_data_nascimento: moment(
            patientData.pes_nascimento,
            "YYYY-MM-DD",
          ).format("DD/MM/YYYY"),
          bof_cod_raca: patientData.bof_cod_raca,
          bof_cod_nacionalidade: patientData.bof_cod_nacionalidade,
          bof_cod_etnia: "",
          // bof_ddd_telefone é um campo que o backend retorna apenas para facilitar a inserção,
          // quando enviamos de volta para ele , devemos informar bof_tel_ddd e bof_tel
          bof_ddd_telefone: patientData.bof_ddd_telefone,
          bof_email: patientData.bof_email,
          bof_cep: patientData.bof_cep,
          bof_cod_municipio: patientData.bof_cod_municipio,
          bof_cod_tipo_logradouro: patientData.bof_cod_tipo_logradouro,
          bof_endereco: patientData.bof_endereco,
          bof_endereco_numero: patientData.bof_endereco_numero,
          bof_endereco_complemento: patientData.bof_endereco_complemento,
          bof_bairro: patientData.bof_bairro,
        };
        this.patient = filledPatient;
      });
    },
    expandContractPatientPanel() {
      this.patientPanelExpanded = !this.patientPanelExpanded;
    },
    selectPatientInfo() {},
    savePatientInfo() {
      this.$store.commit("Billing/Billing/SET_PATIENT_INFO", this.patient);
    },
    refreshPatientForm() {
      const pBulletinId = this.$store.getters[
        "Billing/Bulletin/GET_SELECTED_BULLETIN"
      ];
      const pBofId = this.$store.getters[
        "Billing/Bulletin/GET_SELECTED_BOF_ID"
      ];
      if (!pBulletinId && !pBofId) return false;
      GetPatientInfo({ bol_id: pBulletinId, bof_id: pBofId }).then(
        (pResult) => {
          pResult.bof_data_nascimento = moment(
            pResult.bof_data_nascimento,
          ).format("DD/MM/YYYY");
          this.patient = Object.assign({}, this.patient, pResult);
          this.savePatientInfo();
          if (!this.hasBofId) {
            this.defineCep(this.patient.bof_cep);
          }
          this.$refs.validator.validate();
        },
      );
    },
    async saveIntoDatabase() {
      const valid = await this.validateForm();
      if (valid) {
        this.saveEnabled = false;
        const data = { ...this.patient };
        const rawTel = data.bof_ddd_telefone
          ? data.bof_ddd_telefone.replace(/[-\s/(/)]/g, "")
          : "";
        //  apenas na hora de salvar na mutation separo os campos para serem enviados de volta ao backend
        data.bof_tel = rawTel ? rawTel.substr(2, rawTel.length - 2) : "";
        data.bof_tel_ddd = rawTel ? rawTel.substr(0, 2) : "";
        this.$store.commit("Billing/Billing/SET_PATIENT_INFO", data);
        return SaveBulletinIntoDatabase()
          .then(() => {
            this.$toaster.success("Cadastro realizado com sucesso");
            return true;
          })
          .catch((err) => {
            this.$toaster.error(err);
          })
          .finally(() => {
            if (this.$refs.btnSave) {
              this.$refs.btnSave.actionDone();
            }
          });
      } else {
        this.$refs.btnSave.fail();
        this.$toaster.error("Falha ao Atualizar dados");
      }
    },
    async validateForm() {
      const p = await this.$refs.validator.validate();
      if (p) {
        return this.validateInfo();
      }
    },
    validateInfo() {
      if (this.patient.bof_cod_nacionalidade === "999") {
        this.$toaster.error("Nacionalidade deve ser informada");
        return false;
      }
      if (this.patient.bof_cod_municipio === "999999") {
        this.$toaster.error("Município deve ser informado");
        return false;
      }
      if (
        this.patient.bof_ddd_telefone &&
        this.patient.bof_ddd_telefone.length < 14
      ) {
        this.$toaster.error("Digite um telefone válido");
        return false;
      }
      return true;
    },
    defineCep(pCep) {
      if (pCep.replace(/[^0-9]/gi, "").length !== 8) {
        return;
      }
      return CheckCEP(pCep).then((pCepData) => {
        if (Object.keys(pCepData).length !== 0) {
          if (pCepData.mun_codigo)
            this.patient.bof_cod_municipio = pCepData.mun_codigo;
          if (pCepData.logradouro)
            this.patient.bof_endereco = pCepData.logradouro;
          if (pCepData.bairro) this.patient.bof_bairro = pCepData.bairro;
          if (pCepData.tlg_codigo)
            this.patient.bof_cod_tipo_logradouro = pCepData.tlg_codigo;
          return true;
        } else {
          this.$toaster.error("CEP não encontrado");
          return false;
        }
      });
    },
    validateCepExists(pCep, pErrors) {
      if (pCep.replace(/[^0-9]/gi, "").length !== 8) {
        return false;
      }
      return CheckCEP(pCep)
        .then((pCepData) => {
          return true;
        })
        .catch((pError) => {
          pErrors.push(`O Cep: ${pCep} não existe!`);
          return false;
        });
    },
    billImportedBulletin() {
      this.patient.bof_faturado = 0;
      this.saveIntoDatabase()
        .then((a) => {
          this.$toaster.success(
            "Boletim importado foi faturado com sucesso e está pronto para ser exportado",
          );
        })
        .catch((pErr) => {
          this.patient.bof_faturado = 2;
          this.$toaster.error("Erro ao Faturar Boletim importado");
        });
    },
    validateName(pData, pErrors, pRequired = true) {
      const patientName = pData;

      const hasData = !isEmpty(patientName);
      if (hasData) {
        const data = patientName.trim();
        const repeatedChar = data
          .toLowerCase()
          .split("")
          .join("")
          .match(/(.)\1+/g);

        if (repeatedChar) {
          const hasRepeatedChar = repeatedChar.filter(
            (item) => item.length > 2,
          );
          if (hasRepeatedChar.length) {
            pErrors.push("Nome inválido.");
            return false;
          }
        }

        if (this.patientId === null) {
          pErrors.push("Cadastro não encontrado. Use a busca de pacientes.");
          return false;
        }

        const format = /[!@#$%^&*()_+\-={};':"\\|,.|0-9]+/;
        if (format.test(data)) {
          pErrors.push("Nome inválido. Remova os caracteres especiais.");
          return false;
        }
      }

      return true;
    },
    validateSpecialCharacter(pData, pErrors) {
      const format = /[^\w\s]/gi;
      if (format.test(pData)) {
        pErrors.push("Valor inválido. Remova os caracteres especiais.");
        return false;
      }

      return true;
    },
  },
};
</script>
