export default {
  rg: { id: 28, mask: "###.###.##-#", govId: 2 },
  cpf: { id: 31, mask: "###.###.###-##", govId: 4 },
  pisPasep: { id: 35, mask: "###.######.##-#", govId: 1 },
  newBirthCertificate: {
    id: 38,
    mask: "######.##.##.####.#.######.###.#######-##",
    govId: 6,
  },
  ignored: { id: 99, mask: "", govId: 5 },
};
