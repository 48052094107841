<template lang="html">
  <div v-show="show" class="modal-import-objective">
    <RgValidatorForm ref="validator">
      <RgBaseModal
        ref="Modal"
        v-shortkey="['esc']"
        :show="show"
        with-close-button
        class="rg-base-modal"
        @close="close"
        @shortkey.native="close"
      >
        <div slot="header" class="header">
          <div class="title">Importar objetivos</div>
        </div>

        <div slot="body" class="body">
          <div class="top-buttons">
            <RgRadioSearchObjective
              v-model="typeTable"
              class="radio-type-table"
            />

            <div v-if="tableUnified" class="search">
              <RgInput
                v-model="search.unified"
                label=""
                placeholder="Busca por Objetivo ou Código"
              />
              <RgSearchButton
                ref="searchUnified"
                class="search-button"
                @submit="searchObjectiveUnified"
              />
            </div>

            <div v-else class="search">
              <RgInput
                v-model="search.tuss"
                label=""
                placeholder="Busca por Objetivo ou Código"
              />
              <RgSearchButton
                ref="searchTUSS"
                class="search-button"
                @submit="searchObjectiveTUSS"
              />
            </div>
          </div>

          <FormBase
            v-if="tableUnified"
            title="Objetivos da Tabela Unificada"
            class="area"
          >
            <div class="table">
              <SmartTable
                ref="tableUnified"
                name="ModalImportObjectiveTable"
                :columns="COLUMN_UNIFIED"
                :body="objectivesUnified"
                :total="Number(objectivesUnified.length)"
                :initial-columns="3"
                :show-pagination="false"
                :removeBtnColumns="true"
                :multSelect="true"
                :hasCheck="true"
                index-column="tuc_id"
                @getMultLines="getValueRowUnified"
              />
            </div>
          </FormBase>

          <FormBase v-else title="Objetivos da TUSS" class="area">
            <div class="table">
              <SmartTable
                ref="tableTuss"
                name="ModalImportObjectiveTUSS"
                :columns="COLUMN_TUSS"
                :body="objectivesTUSS"
                :total="Number(objectivesTUSS.length)"
                :initial-columns="3"
                :show-pagination="false"
                :removeBtnColumns="true"
                :multSelect="true"
                :hasCheck="true"
                index-column="eea_id"
                @getMultLines="getValueRowTUSS"
              />
            </div>
          </FormBase>

          <FormBase title="Dados da Importação" class="area">
            <div class="import-objective">
              <span class="info"
                ><i
                  >Para concluir a importação com sucesso,<strong>
                    é necessário atribuir aos objetivos selecionados uma Unidade
                    de saúde e Convênio.
                  </strong></i
                ></span
              >

              <RgComboboxUnithealth
                ref="unitHealth"
                v-model="form.unitHealth"
                :rules="{ required: true }"
                class="input"
                label="Unidade de Saúde"
              />

              <RgComboboxHealthPlan
                ref="healthInsurance"
                v-model="form.healthInsurance"
                :rules="{ required: true }"
                class="input"
                label="Convênio"
              />
            </div>
          </FormBase>

          <div slot="footer" class="footer">
            <MediumButton label="Importar" @click="importObjective" />
          </div>
        </div>
      </RgBaseModal>
    </RgValidatorForm>
  </div>
</template>

<script>
import {
  RgValidatorForm,
  RgBaseModal,
  RgComboboxUnithealth,
  MediumButton,
  RgInput,
  RgSearchButton,
} from "~tokio/primitive";

import { SmartTable } from "~tokio/foundation";

import TypeTable from "$appointment/submodules/register/component/radio/TypeTable";

import FormBase from "~tokio/foundation/form-base/FormBase";
import RgComboboxHealthPlan from "$appointment/submodules/register/component/combobox/rg-combobox-health-plan/RgComboboxHealthPlan";
import RgRadioSearchObjective from "$appointment/submodules/register/component/radio/RgRadioSearchObjective";

export default {
  name: "ModalImportObjective",
  components: {
    RgValidatorForm,
    RgBaseModal,
    FormBase,
    RgComboboxUnithealth,
    RgComboboxHealthPlan,
    MediumButton,
    SmartTable,
    RgInput,
    RgSearchButton,
    RgRadioSearchObjective,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      typeTable: [TypeTable.UNIFIED],
      objectivesUnified: [],
      objectivesTUSS: [],
      search: {
        unified: null,
        tuss: null,
      },
      form: {
        objectivesUnified: null,
        objectivesTUSS: null,
        unitHealth: null,
        healthInsurance: null,
      },
    };
  },

  computed: {
    tableUnified() {
      return this.typeTable[0] === 1;
    },

    tableTUSS() {
      return this.typeTable[0] === 2;
    },
  },

  watch: {
    show(pValue) {
      if (!pValue) {
        this.close();
      }
    },

    typeTable(pValue, pPreviously) {
      if (pValue !== pPreviously) {
        this.objectivesUnified = [];
        this.objectivesTUSS = [];
        this.search = {
          unified: null,
          tuss: null,
        };

        if (
          pValue &&
          pValue[0] === 1 &&
          this.form.objectivesUnified?.length > 0
        ) {
          this.$refs.tableTuss.cleanMultSelectedRow();
        }

        if (pValue && pValue[0] === 2 && this.form.objectivesTUSS?.length > 0) {
          this.$refs.tableUnified.cleanMultSelectedRow();
        }
      }
    },
  },

  created() {
    this.COLUMN_UNIFIED = [
      { name: "Objetivo", key: "tuc_nome" },
      { name: "Código", key: "tuc_codigo_procedimento" },
      { name: "Custo", key: "tuc_valor_total" },
    ];

    this.COLUMN_TUSS = [
      { name: "Objetivo", key: "tus_nome" },
      { name: "Código", key: "tus_codigo_procedimento" },
      { name: "Custo", key: "tus_custo" },
    ];
  },

  methods: {
    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    async searchObjectiveUnified() {
      try {
        let variables = "";

        const invalidLength =
          !this.search.unified || this.search.unified.length < 3;

        if (invalidLength) {
          this.$toaster.warning(
            "Mínimo de 3 caracteres para realizar a busca.",
          );
          this.$refs.searchUnified.fail();
          return false;
        }

        this.$loader.start("Carregando objetivos...");
        if (this.search.unified && this.isNumber(this.search.unified)) {
          variables = {
            tuc_codigo_procedimento: this.search.unified,
          };
        } else {
          variables = {
            tuc_nome: this.search.unified,
          };
        }

        this.$refs.searchUnified.actionSubmit();
        const objectives = await this.$store.dispatch(
          "Appointment/Register/SEARCH_APPOINTMENT_OBJECTIVE_UNIFIED",
          variables,
        );
        this.$refs.searchUnified.actionDone();

        this.objectivesUnified = objectives;
      } catch (pErr) {
        this.$toaster.error(pErr, "Erro ao pesquisar objetivo.");
        this.$refs.searchUnified.fail();
      } finally {
        this.$loader.finish();
      }
    },

    async searchObjectiveTUSS() {
      try {
        let variables = "";

        const invalidLength = !this.search.tuss || this.search.tuss.length < 3;

        if (invalidLength) {
          this.$toaster.warning(
            "Mínimo de 3 caracteres para realizar a busca.",
          );
          this.$refs.searchTUSS.fail();
          return false;
        }

        if (this.search.tuss && this.isNumber(this.search.tuss)) {
          variables = {
            tus_codigo_procedimento: this.search.tuss,
          };
        } else {
          variables = {
            tus_nome: this.search.tuss,
          };
        }

        this.$refs.searchTUSS.actionSubmit();
        const objectives = await this.$store.dispatch(
          "Appointment/Register/SEARCH_APPOINTMENT_OBJECTIVE_TUSS",
          variables,
        );
        this.$refs.searchTUSS.actionDone();

        this.objectivesTUSS = objectives;
      } catch (pErr) {
        this.$toaster.error(pErr, "Erro ao pesquisar objetivo.");
        this.$refs.searchTUSS.fail();
      }
    },

    getValueRowUnified(pValue) {
      const objectives = [];
      const selectedObjectivesUnified = pValue;

      selectedObjectivesUnified.map((el) => {
        objectives.push(el.tuc_id);
      });

      this.form.objectivesUnified = objectives;
    },

    getValueRowTUSS(pValue) {
      const objectives = [];
      const selectedObjectivesTUSS = pValue;

      selectedObjectivesTUSS.map((el) => {
        objectives.push(el.tus_id);
      });

      this.form.objectivesTUSS = objectives;
    },

    formatData() {
      let variables = {};

      if (this.tableUnified) {
        variables = {
          pls_id: Number(this.form.healthInsurance),
          tuc_procedimentos: this.form.objectivesUnified,
          uns_id: Number(this.form.unitHealth),
        };
      }

      if (this.tableTUSS) {
        variables = {
          pls_id: Number(this.form.healthInsurance),
          tus_procedimentos: this.form.objectivesTUSS,
          uns_id: Number(this.form.unitHealth),
        };
      }

      return variables;
    },

    async importObjective() {
      if (!(await this.isFormValid())) {
        this.$toaster.warning("Verifique os campos.");
        return false;
      }

      if (
        this.form.objectivesUnified?.length < 1 ||
        this.form.objectivesTUSS?.length < 1
      ) {
        this.$toaster.warning("É obrigatório a seleção de um objetivo.");
        return false;
      }

      try {
        this.$loader.start();

        const variables = this.formatData();

        if (this.tableUnified) {
          await this.$store.dispatch(
            "Appointment/Register/EXPORT_APPOINTMENT_OBJECTIVE_UNIFIED",
            variables,
          );
          this.$emit("research");
        }

        if (this.tableTUSS) {
          await this.$store.dispatch(
            "Appointment/Register/EXPORT_APPOINTMENT_OBJECTIVE_TUSS",
            variables,
          );
          this.$emit("research");
        }

        this.close();
        this.$toaster.success("Objetivo importado com sucesso!");
      } catch (pErr) {
        this.$toaster.error(pErr, "Erro ao importar objetivo.");
      } finally {
        this.$loader.finish();
      }
    },

    isNumber(value) {
      const isNumber = /^\d+$/.test(value);
      return isNumber;
    },

    close() {
      if (this.tableUnified && this.form.objectivesUnified?.length > 0) {
        this.$refs.tableUnified.cleanMultSelectedRow();
      }

      if (this.tableTUSS && this.form.objectivesTUSS?.length > 0) {
        this.$refs.tableTuss.cleanMultSelectedRow();
      }

      this.$refs.unitHealth.cleanValidate();
      this.$refs.healthInsurance.cleanValidate();

      this.form.unitHealth = null;
      this.form.healthInsurance = null;

      this.typeTable = [TypeTable.UNIFIED];
      this.$emit("close");
    },
  },
};
</script>
