<style src="./EnableDisableEmployeePeriod.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="enable-disable-employee-period">
    <EnableDisableEmployeeForm
      ref="relation_form"
      :employee-id="employeeId"
      class="form"
      @enable="enbleRealtionhipEmployee"
      @disable="disableRelationshipEmployee"
    />
    <EmployeeDisabledPeriods
      :disabling-list="disablingList"
      :employee-id="employeeId"
      class="list"
      @remove-disabling-relationship="removeDisablingEmployeeRelationshipSector"
    />
  </section>
</template>

<script>
import EnableDisableEmployeeForm from "@/app/employee/common/components/container/enable-disable-employee-period/enable-disable-employee-form/EnableDisableEmployeeForm";
import EmployeeDisabledPeriods from "./employee-disabled-periods/EmployeeDisabledPeriods";
export default {
  name: "EnableDisableEmployeePeriod",
  components: {
    EnableDisableEmployeeForm,
    EmployeeDisabledPeriods,
  },
  props: {
    employeeId: {},
  },
  data: () => {
    return {
      form: {},
      disablingList: [],
    };
  },
  computed: {
    relationshipSectorData() {
      return this.$store.getters["Employee/GET_RELATIONSHIP_SECTOR"];
    },
  },
  watch: {
    relationshipSectorData: function () {
      this.form.vni_id_vinculos_setores = this.relationshipSectorData.vus_id;
      this.filterList(this.form);
    },
  },
  methods: {
    async enbleRealtionhipEmployee(pForm) {
      try {
        const form = { ...pForm };
        form.vni_id_vinculos_setores = this.form.vni_id_vinculos_setores;
        await this.$store.dispatch(
          "Employee/ENABLE_RELATIONSHIP_EMPLOYEE_PERIOD",
          form,
        );
        await this.actionDone();
        this.$toaster.success("Período de Inabilitação criado para o Vínculo");
      } catch (pErr) {
        this.$toaster.error(
          "Falha ao Inabilitar Vínculo setor do Profissional",
          pErr.message,
        );
      }
    },
    async disableRelationshipEmployee(pForm) {
      try {
        const form = { ...pForm };
        form.vni_id_vinculos_setores = this.form.vni_id_vinculos_setores;
        await this.$store.dispatch(
          "Employee/DISABLE_RELATIONSHIP_EMPLOYEE_PERIOD",
          form,
        );
        await this.actionDone();
        this.$toaster.success("Período de Inabilitação criado para o Vínculo");
      } catch (pErr) {
        this.$toaster.error(
          "Falha ao Inabilitar Vínculo setor do Profissional",
          pErr.message,
        );
      }
    },
    async actionDone() {
      await this.searchDisablingRelationships();
      this.$refs.relation_form.cleanForm();
    },
    async searchDisablingRelationships() {
      this.disablingList = await this.$store.dispatch(
        "Employee/SEARCH_DISABLING_EMPLOYEE_RELATIONSHIP_SECTORS",
        {
          criterias: {
            vni_id_vinculos_setores: this.form.vni_id_vinculos_setores,
          },
        },
      );
    },
    async filterList() {
      if (this.form && this.form.vni_id_vinculos_setores) {
        if (this.form.vni_id_vinculos_setores > 0) {
          this.searchDisablingRelationships();
        } else {
          this.disablingList = [];
        }
      }
    },
    removeDisablingEmployeeRelationshipSector(pItem) {
      this.$store
        .dispatch("Employee/REMOVE_DISABLING_RELATIONSHIP_SECTOR", {
          vni_id: pItem.vni_id,
        })
        .then((pRes) => {
          this.$toaster.success("Inabilitação removida com sucesso");
          this.searchDisablingRelationships();
        })
        .catch((pErr) => {
          this.$toaster.error("Falha ao remover inabilitação!", pErr.message);
        });
    },
  },
};
</script>
