<template lang="html">
  <svg
    id="Capa_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 303.6 303.6"
    style="enable-background: new 0 0 303.6 303.6"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M293.6,110c-6.8-6-15.2-9.6-24.8-9.6h-57.6c-4.4,0-8.4-3.6-8.4-8.4V34.4c0-9.6-4-18-10-24.4
			C186.4,3.6,178,0,168.4,0h-34c-9.6,0-18,4-24.4,10c-6.4,6.4-10,14.8-10,24.4V92c0,4.4-3.6,8.4-8.4,8.4H34.4c-9.6,0-18,4-24.4,10
			c-6.4,6.4-10,14.8-10,24.4v34c0,9.6,4,18,10,24.4c6.4,6.4,14.8,10,24.4,10H92c4.4,0,8.4,3.6,8.4,8.4v57.6c0,9.6,4,18,10,24.4
			c6.4,6.4,14.8,10,24.4,10h34c9.6,0,18-4,24.4-10c6.4-6.4,10-14.8,10-24.4v-58c0-4.4,3.6-8.4,8.4-8.4h57.6c9.6,0,18-4,24.4-10
			c6.4-6.4,10-14.8,10-24.4v-34C303.6,124.8,299.6,116.4,293.6,110z"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "Csv",
};
</script>
