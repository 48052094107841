import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

// const arrayExceptions = [
//   "NaoPossuiPermissaoException",
//   "ConsultaEfetivadaException",
// ];

export default async (context, variables) => {
  return AuthLegacyRequest.post(
    "/exames2/controller-exame-agendamento-procedimento/excluir",
    variables,
  )
    .then(({ data }) => {
      return HandleResponse(data);

      // const { dados, trace } = data;
      // if (arrayExceptions.includes(dados)) {
      //   throw new Error(trace);
      // }
      // return trace;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
