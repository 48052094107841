import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query {
    employeeRelationshipTypes {
      rows {
        tpv_id
        tpv_nome
      }
    }
  }
`;

export default async () => {
  try {
    const { data } = await GraphQLClient.query({ query });
    return data.employeeRelationshipTypes.rows;
  } catch (err) {
    return err.graphQLErrors;
  }
};
