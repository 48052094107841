<style src="./RgRadioExamStatus.scss" lang="scss" scoped></style>
<template lang="html">
  <fieldset class="rg-radio-exam-status">
    <span class="label">Status do exame</span>
    <div class="container">
      <div
        :class="{ 'active-open': selectedStatus.includes(EXAM_STATUS.ABERTO) }"
        data-id="aberto"
        class="radio show-open"
        @click="toggleStatus(EXAM_STATUS.ABERTO)"
      >
        Aberto
      </div>
      <div
        :class="{
          'active-effectivate': selectedStatus.includes(EXAM_STATUS.EFETIVADO),
        }"
        data-id="efetivado"
        class="radio show-effectivate"
        @click="toggleStatus(EXAM_STATUS.EFETIVADO)"
      >
        Efetivado
      </div>
      <div
        :class="{
          'active-not-effectivated': selectedStatus.includes(
            EXAM_STATUS.NAO_EFETIVADO,
          ),
        }"
        data-id="nao-efetivado"
        class="radio show-not-effectivated"
        @click="toggleStatus(EXAM_STATUS.NAO_EFETIVADO)"
      >
        Não efetivado
      </div>
    </div>
  </fieldset>
</template>

<script>
import EXAM_STATUS from "./GetExamStatus";

export default {
  name: "RgRadioExamStatus",
  props: {
    value: {
      default: null,
    },
  },
  data() {
    return {
      selectedStatus: [1, 2, 3],
    };
  },
  computed: {
    EXAM_STATUS() {
      return { ...EXAM_STATUS };
    },
  },
  watch: {
    value(pValue) {
      this.selectedStatus = pValue;
    },
  },
  mounted() {
    this.selectedStatus = this.value || [1, 2, 3];
  },
  methods: {
    toggleStatus(pItem) {
      if (this.selectedStatus.includes(pItem)) {
        this.selectedStatus.splice(this.selectedStatus.indexOf(pItem), 1);
      } else {
        this.selectedStatus.push(pItem);
      }
      this.$emit("input", this.selectedStatus);
    },
  },
};
</script>
