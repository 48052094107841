<template lang="html">
  <div v-show="show" class="modal-back-patient-from-queue">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <div class="title">Voltar Paciente para Fila</div>
      </div>

      <div slot="body" class="body"></div>
    </RgBaseModal>
  </div>
</template>

<script>
import { RgBaseModal } from "~tokio/primitive";

export default {
  name: "ModalBackPatientFromQueue",
  components: {
    RgBaseModal,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },

  watch: {
    show(pValue) {
      if (!pValue) {
        this.close();
      } else {
      }
    },
  },

  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
