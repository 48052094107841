<template lang="html">
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.74998 3.43758V0.687507C2.74998 0.307458 3.05744 0 3.43758 0C3.81754 0 4.125 0.307458 4.125 0.687507V3.43758C4.125 3.81754 3.81754 4.125 3.43758 4.125C3.05744 4.12504 2.74998 3.81754 2.74998 3.43758ZM14.4376 4.12504C14.8175 4.12504 15.1252 3.81758 15.1252 3.43763V0.687507C15.1252 0.307458 14.8175 0 14.4376 0C14.0576 0 13.7499 0.307458 13.7499 0.687507V3.43758C13.7499 3.81754 14.0576 4.12504 14.4376 4.12504ZM22 16.4999C22 19.5376 19.5374 22.0001 16.5 22.0001C13.4628 22.0001 11 19.5376 11 16.4999C11 13.4627 13.4627 11 16.5 11C19.5374 11 22 13.4626 22 16.4999ZM5.50006 8.25004H2.74998V11H5.50006V8.25004ZM2.74998 15.1251H5.50006V12.3749H2.74998V15.1251ZM6.87498 11H9.6251V8.25004H6.87498V11ZM6.87498 15.1251H9.6251V12.3749H6.87498V15.1251ZM1.37506 16.3632V6.87498H16.5V9.62505H17.8751V4.26206C17.8751 3.42684 17.2089 2.74998 16.386 2.74998H15.8125V3.43763C15.8125 4.19487 15.1961 4.81259 14.4377 4.81259C13.6791 4.81259 13.0627 4.19487 13.0627 3.43763V2.74998H4.81246V3.43763C4.81246 4.19487 4.19614 4.81259 3.43763 4.81259C2.67893 4.81259 2.06257 4.19487 2.06257 3.43763V2.74998H1.48982C0.666786 2.74998 0 3.42684 0 4.26206V16.3632C0 17.1957 0.666786 17.8752 1.48982 17.8752H9.6251V16.4999H1.48982C1.42752 16.4999 1.37506 16.4356 1.37506 16.3632ZM13.7499 11V8.25004H11.0001V11H13.7499ZM15.9167 13.5833C15.9167 13.2612 16.1778 13 16.5 13C16.8222 13 17.0833 13.2612 17.0833 13.5833V15.9167H19.4167C19.7388 15.9167 20 16.1778 20 16.5C20 16.8222 19.7388 17.0833 19.4167 17.0833H17.0833V19.4167C17.0833 19.7388 16.8222 20 16.5 20C16.1778 20 15.9167 19.7388 15.9167 19.4167V17.0833H13.5833C13.2612 17.0833 13 16.8222 13 16.5C13 16.1778 13.2612 15.9167 13.5833 15.9167H15.9167V13.5833Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: "Schedule",
};
</script>
