<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchHospitalizationSituationBed from "./action/SearchHospitalizationSituationBed";
import BedSituations from "$hospitalization/common/component/util/GetTypeBedValues";

export default {
  name: "RgComboboxSituationBed",
  mixins: [RgComboboxMixin],
  props: {
    label: {
      type: String,
      default: "Situação do Leito",
    },
    dataId: {
      type: String,
      default: "",
    },
    currentSituation: {
      type: String,
      default: null,
    },
    isBedSituationModal: {
      type: Boolean,
      default: false,
    },
    fromHospitalization: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];
      const variables = {};
      if (this.currentSituation) {
        variables.situation = this.currentSituation;
      }
      if (this.fromHospitalization) {
        variables.fromHospitalization = this.fromHospitalization;
      }

      const data = await SearchHospitalizationSituationBed(variables);

      data.forEach((item) => {
        this.mData.push({
          value: item.isl_id,
          text: item.isl_nome,
        });
      });

      if (this.isBedSituationModal || this.fromHospitalization) {
        data.forEach((item) => {
          if (item.isl_id === 2 || item.isl_id === 3) {
            this.mData = [
              { value: BedSituations.RESERVA, text: "RESERVA" },
              { value: BedSituations.OCUPADO, text: "OCUPADO" },
            ];
          }
        });
      }
    },
    blur() {
      this.$emit("blur");
      this.validate();
    },
  },
};
</script>
