<template>
  <div v-show="show" class="modal-view-pending">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <span class="title">
          {{ "Visualizar Pendência" }}
        </span>
      </div>

      <div slot="body" class="body">
        <div class="information">
          <RgTypePatient class="patient-type" />

          <div class="area">
            <span class="data">
              Paciente:
              <strong>
                {{ patient.pes_nome }}
              </strong>
            </span>

            <span class="data">
              Setor:
              <strong>
                {{ "Não informado" }}
              </strong>
            </span>
          </div>

          <div class="area">
            <span class="data">
              Unidade de Saúde:
              <strong>
                {{ patient.unidade_fila }}
              </strong>
            </span>

            <span class="data">
              Local de Atendimento:
              <strong>
                {{ "Não informado" }}
              </strong>
            </span>
          </div>
        </div>

        <FormBase title="Detalhes da Pendência" class="content-form">
          <div class="textarea">
            <RgTextArea
              v-model="form.textPendency"
              disabled
              label="Pendência"
              class="rg-text-area"
            />
          </div>

          <div class="textarea">
            <div class="buttons"></div>

            <RgTextArea
              v-model="form.textResolution"
              disabled
              label="Resolução"
              class="rg-text-area"
            />
          </div>
        </FormBase>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { RgBaseModal, RgTextArea } from "~tokio/primitive";

import FormBase from "~tokio/foundation/form-base/FormBase";
import RgTypePatient from "$hospitalization/common/component/status/rg-type-patient/RgTypePatient.vue";

export default {
  name: "ModalShowPending",
  components: {
    RgBaseModal,
    RgTextArea,
    RgTypePatient,
    FormBase,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    patient: {
      type: Object,
      default: () => {},
    },
    info: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      form: {
        textPendency: "",
        textResolution: "",
      },
    };
  },

  watch: {
    info(pValue) {
      pValue && pValue.fip_motivo_pendencia
        ? (this.form.textPendency = pValue.fip_motivo_pendencia)
        : (this.form.fip_motivo_pendencia = "");
      pValue && pValue.fip_resolucao
        ? (this.form.textResolution = pValue.fip_resolucao)
        : (this.form.textResolution = "");
    },
  },

  methods: {
    close(pActionTaken = false) {
      this.$emit("close", pActionTaken === true);
    },
  },
};
</script>
