<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchPlacePep from "./action/SearchPlacePep";
export default {
  name: "RgComboboxPlacePep",
  mixins: [RgComboboxMixin],
  props: {
    sectorCode: {
      type: String,
      default: "",
    },
  },
  data: () => {
    return {};
  },
  watch: {
    sectorCode(pValue, pPrev) {
      if (pValue || pValue !== pPrev) {
        this.fillData();
      }
    },
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      if (this.sectorCode) {
        const data = await SearchPlacePep({ set_codigo: this.sectorCode });
        this.mData = [];

        data.forEach((item) => {
          this.mData.push({ value: item.data, text: item.label });
        });

        if (this.mData.length === 1) {
          this.$emit("selectOnlyItem", this.mData[0].value);
        }
      }
    },
  },
};
</script>
