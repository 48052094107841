<template>
  <div class="rg-list" :class="{ disable: disabled }">
    <slot />
    <div class="rg-list-footer">
      <RgPagination
        ref="paginator"
        :data-length="countItem"
        :item-per-page="registerPerPage"
        :max-register="maxRegister"
        @pagination="setPagination"
      />
    </div>
  </div>
</template>

<script>
import RgPagination from "~tokio/foundation/pagination/RgPagination";
import { AlertError } from "$exam/common/services";

export default {
  name: "RgList",
  components: {
    RgPagination,
  },
  props: {
    /**
     * @param {*} filters
     * @returns {html: string, count: number}
     */
    searchFunction: {
      type: Function,
    },
    clearFunction: {
      type: Function,
    },
    individualHeight: {
      type: Number,
      default: 0,
    },
    buildFilter: {
      type: Function,
    },
    registerPerPage: {
      type: Number,
      default: 7,
    },
    maxRegister: {
      type: Number,
      default: 15,
    },
    backendLegacy: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    viewPermission: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      countItem: 0,
      pagination: {
        limit: 10,
        offset: 0,
        current: 1,
      },
    };
  },

  computed: {
    hasSearchFunction() {
      return !!this.searchFunction();
    },
  },

  created() {
    this.LEGADO_OBJECT_SEARCH = ["arrFiltros"];
  },

  mounted() {},

  methods: {
    setPagination(pPagination) {
      this.$emit("pagination", pPagination);
      this.pagination = pPagination;
      this.submitForm();
      this.$loader.finish();
    },
    prepareData() {
      const data = {
        ...this.buildFilter(),
      };

      if (this.backendLegacy) {
        const nameObject = Object.keys(data);
        const hasThisObject = this.LEGADO_OBJECT_SEARCH.find((item) => {
          return nameObject.includes(item) ? item : null;
        });

        if (hasThisObject) {
          data[hasThisObject].limiteInicio = this.pagination.offset;
          data[hasThisObject].limiteFim = this.pagination.limit;
        } else {
          data.limiteInicio = this.pagination.offset;
          data.limiteFim = this.pagination.limit;
        }

        return data;
      } else {
        return {
          ...data,
          limit: this.pagination.limit,
          offset: this.pagination.offset,
        };
      }
    },
    resetLimitOffset() {
      this.countItem = 0;
      this.$refs.paginator.resetLimitOffset();
      this.$refs.paginator.loadPagination();
    },
    async submitForm(submitFromButton = false) {
      if (!this.viewPermission) return null;

      try {
        if (submitFromButton) {
          this.$loader.start();

          this.resetLimitOffset();
        }
        const data = this.prepareData();
        const result = await this.searchFunction(data);

        if (result) {
          if (result.count || result.total) {
            this.countItem = result.count || Number(result.total) || 0;
          } else {
            if (result.rows && result.rows.length > 0) {
              this.countItem = result.rows[0].totalRegistros || 0;
            }
          }

          this.updateValues(result);
        }
      } catch (error) {
        if (error.message && typeof error.message === "string") {
          return AlertError(error.message);
        }
        throw error;
      } finally {
        if (submitFromButton) {
          this.$loader.finish();
        }
      }
    },
    updateValues(pResult) {
      const rows = pResult.rows || pResult.data || pResult.resultado;
      this.$emit("input", rows);
      this.$emit("afterSearch", rows);
    },
    searchWhenShow() {
      this.submitForm(true);
    },
  },
};
</script>
