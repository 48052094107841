import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query($employeeId: Int!, $active: Boolean!) {
    employeeRelationshipSectors(fun_id: $employeeId, vus_ativo: $active) {
      rows {
        vus_id
        vus_ativo
        sector {
          unit_health {
            uns_id
            uns_cnes
            uns_nome
          }
          set_id
          set_nome
        }
        employeeRelationship {
          vin_id
          occupationEsus {
            ocp_id
            ocp_codigo
            ocp_nome
          }
        }
      }
      count
    }
  }
`;

export default async (store, pData = {}) => {
  const criterias = pData.criterias || {};
  const variables = {
    employeeId: criterias.employeeId || undefined,
    active: criterias.sectorRelationshipActive || true,
  };

  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.employeeRelationshipSectors.rows;
  } catch (err) {
    return err.graphQLErrors;
  }
};
