<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import VACANCIES from "./enum/VacanciesEnum";
export default {
  name: "RgComboboxVacancies",
  mixins: [RgComboboxMixin],
  props: {
    remaining: {
      default: null,
    },
  },

  data() {
    return {
      mData: [
        { value: VACANCIES.UMA, text: "1 vaga(s)" },
        { value: VACANCIES.DUAS, text: "2 vaga(s)" },
        { value: VACANCIES.TRES, text: "3 vaga(s)" },
        { value: VACANCIES.QUATRO, text: "4 vaga(s)" },
        { value: VACANCIES.CINCO, text: "5 vaga(s)" },
        { value: VACANCIES.SEIS, text: "6 vaga(s)" },
        { value: VACANCIES.SETE, text: "7 vaga(s)" },
        { value: VACANCIES.OITO, text: "8 vaga(s)" },
        { value: VACANCIES.NOVE, text: "9 vaga(s)" },
        { value: VACANCIES.DEZ, text: "10 vaga(s)" },
      ],
    };
  },

  watch: {
    remaining(value) {
      this.fillData();
    },
  },

  mounted() {
    this.fillData();
  },

  methods: {
    async fillData() {
      this.mData = [];
      const max = parseInt(this.remaining);
      let count = 1;
      while (count <= max) {
        let str = `${count} vagas`;
        if (count === 1) {
          str = `${count} vaga`;
        }
        this.mData.push({ value: count.toString(), text: str });
        count++;
      }

      if (this.mData.length === 1) {
        this.$emit("selectOnlyItem", this.mData[0].value);
      }
    },
  },
};
</script>
