import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query ProfessionalRegistration($employeeId: Int) {
    professionalRegistrations(coc_id_funcionarios: $employeeId) {
      rows {
        coc_id
        coc_numero_conselho
        coc_tipo_conselho
        coc_uf_conselho
        coc_codigo_regional
        coc_complemento
      }
    }
  }
`;

export default async (store, { employeeId }) => {
  const variables = {
    employeeId,
  };

  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.professionalRegistrations.rows;
  } catch (err) {
    return err.graphQLErrors;
  }
};
