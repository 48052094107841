module.exports = `
iaf_id
iaf_id_internacoes
iaf_id_internacoes_pacientes_faturamentos
iaf_id_internacoes_faturamentos
iaf_id_internacoes_aih_cid10_secundario
iaf_id_internacoes_aih_procedimentos
iaf_numero_aih
iaf_tipo_aih
iaf_apresentacao
iaf_data_internacao
iaf_data_saida
iaf_orgao_emissor
iaf_prontuario
iaf_faturado
`;
