<template lang="html">
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 480 480"
    style="enable-background: new 0 0 480 480"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M212.001,32c-13.255,0-24,10.745-24,24c0.014,13.249,10.751,23.986,24,24c13.255,0,24-10.745,24-24S225.256,32,212.001,32
			z M212.001,64c-4.418,0-8-3.582-8-8c0.006-4.416,3.584-7.994,8-8c4.418,0,8,3.582,8,8S216.419,64,212.001,64z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M340.001,128h-256c-4.418,0-8,3.582-8,8v176c0,4.418,3.582,8,8,8h256c4.418,0,8-3.582,8-8V136
			C348.001,131.582,344.419,128,340.001,128z M252.001,304h-80v-40.781c0.013-12.818,10.401-23.206,23.219-23.219h33.563
			c12.818,0.013,23.206,10.401,23.219,23.219V304z M188.001,200c0-13.255,10.745-24,24-24s24,10.745,24,24
			c-0.014,13.249-10.751,23.986-24,24C198.746,224,188.001,213.255,188.001,200z M332.001,304h-64v-40.781
			c-0.052-16.579-10.541-31.329-26.184-36.821c14.727-16.467,13.316-41.755-3.151-56.482c-16.467-14.727-41.755-13.316-56.482,3.151
			c-13.579,15.184-13.579,38.147,0,53.331c-15.643,5.493-26.131,20.242-26.183,36.821V304h-64V144h240V304z"
        />
      </g>
    </g>
    <g>
      <g>
        <rect x="76.001" y="336" width="120" height="16" />
      </g>
    </g>
    <g>
      <g>
        <rect x="212.001" y="336" width="48" height="16" />
      </g>
    </g>
    <g>
      <g>
        <rect x="76.001" y="384" width="192" height="16" />
      </g>
    </g>
    <g>
      <g>
        <rect x="276.001" y="336" width="32" height="16" />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M449.657,330.344l-24-24c-3.121-3.124-8.183-3.126-11.307-0.005c-0.211,0.211-0.409,0.433-0.595,0.665l-17.754,22.191V56
			c0-4.418-3.582-8-8-8H268.95l6.926-38.586c0.781-4.349-2.112-8.508-6.462-9.288C268.948,0.042,268.475,0,268.001,0h-112
			C151.582,0,148,3.582,148,8.001c0,0.474,0.042,0.947,0.126,1.413L155.052,48H36.001c-4.418,0-8,3.582-8,8v416c0,4.418,3.582,8,8,8
			h352c4.418,0,8-3.582,8-8v-66.889l54.105-63.943C452.794,337.992,452.598,333.286,449.657,330.344z M258.438,16l-14.359,80
			h-64.156l-14.359-80H258.438z M380.001,464h-336V64h113.922l7.433,41.414c0.684,3.813,4.002,6.587,7.875,6.586h77.539
			c3.874,0.001,7.191-2.773,7.875-6.586L266.078,64h113.922v285.195l-24.664,30.829l-17.68-17.68
			c-3.122-3.124-8.186-3.125-11.309-0.003c-0.001,0.001-0.002,0.002-0.003,0.003l-24,24c-3.124,3.122-3.125,8.186-0.003,11.309
			c0.001,0.001,0.002,0.002,0.003,0.003l48,48c1.5,1.501,3.535,2.344,5.656,2.344l0.332-0.008c2.234-0.093,4.328-1.117,5.773-2.824
			l17.895-21.148V464z M355.509,428.195L319.313,392l12.688-12.688l18.344,18.344c1.615,1.599,3.828,2.445,6.098,2.332
			c2.273-0.126,4.384-1.214,5.805-2.992l58.418-73.02l12.473,12.477L355.509,428.195z"
        />
      </g>
    </g>
    <g>
      <g>
        <rect x="76.001" y="432" width="72" height="16" />
      </g>
    </g>
    <g>
      <g>
        <rect x="164.001" y="432" width="128" height="16" />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "Address",
};
</script>
