<script>
import { RgRemoteComboboxMixin } from "~tokio/primitive";
import ListUnithealth from "./action/ListUnithealth";

export default {
  name: "RgComboboxUnithealth",
  mixins: [RgRemoteComboboxMixin],
  props: {
    label: {
      type: String,
      default: "Unidade de saúde",
    },
    permission: {
      type: String,
      default: "faturamento.atendimentosEmergencia.faturamentoEmergencia",
    },
    defaultText: {
      type: String,
      default: "Selecione",
    },
  },
  data() {
    return {};
  },
  methods: {
    doSearch() {
      return ListUnithealth(this.permission);
    },
    formatItem(item) {
      return {
        value: item.uns_id,
        text: `${item.uns_nome}`,
      };
    },
  },
};
</script>
