import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query($ica_id: Int, $ica_nome: String) {
    hospitalizationServiceCharacter(ica_id: $ica_id, ica_nome: $ica_nome) {
      rows {
        ica_id
        ica_nome
      }
    }
  }
`;

export default async (variables, findOne = false) => {
  const { data } = await GraphQLClient.query({ query, variables }).catch(
    (err) => {
      console.error(err);
    },
  );
  if (!findOne) {
    return data.hospitalizationServiceCharacter.rows;
  } else {
    return data.hospitalizationServiceCharacter.rows[0];
  }
};
