<style src="./EmergencyReport.scss" lang="scss" scoped></style>
<template>
  <div>
    <div class="row">
      <span class="filter-title">Período de Emergências</span>
      <div class="col-6">
        <RgInputDate
          v-model="form.initialDate"
          label="Data início"
          placeholder="Digite a Data Inicial"
          :rules="{ required: true }"
        />
      </div>
      <div class="col-6">
        <RgInputDate
          v-model="form.finalDate"
          label="Data final"
          placeholder="Digite a Data Final"
          :rules="{ required: true }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { RgInputDate } from "~tokio/primitive";
const moment = require("moment");

export default {
  name: "Emergency",
  components: {
    RgInputDate,
  },
  data() {
    return {
      resultTitle: "Período de Emergências",
      form: {
        initialDate: moment().add(-2, "days").format("DD/MM/YYYY"),
        finalDate: moment().format("DD/MM/YYYY"),
      },
    };
  },
  computed: {
    columnTable() {
      return this.COLUMN;
    },

    getNameSmartTable() {
      return "EmergencyReport";
    },
  },
  created() {
    this.COLUMN = [
      { name: "Nome do Paciente", key: "PACIENTE" },
      { name: "Idade", key: "IDADE" },
      { name: "Telefones", key: "TELEFONES" },
      { name: "Endereço", key: "ENDERECO" },
      { name: "Nome da Mãe", key: "NOME_DA_MAE" },
      { name: "Unidade de Saúde", key: "UNIDADE_DE_SAUDE" },
      { name: "Data de Atendimento", key: "DATA_ATENDIMENTO" },
      { name: "Hora de Atendimento", key: "HORA_ATENDIMENTO" },
      { name: "Setor", key: "SETOR" },
      { name: "Usuário", key: "USUARIO" },
    ];
  },
  mounted() {},
  methods: {
    buildFilter() {
      return {
        initialDate: String(this.form.initialDate),
        finalDate: String(this.form.finalDate),
      };
    },
    async doSearch(pData) {
      try {
        return this.$store.dispatch(
          "Billing/HospitalizationBilling/GET_EMERGENCY_REPORTS",
          pData,
        );
      } catch (error) {
        return false;
      }
    },
    clearFilter() {
      this.form = {
        initialDate: "",
        finalDate: "",
      };
    },
  },
};
</script>
