import ScheduleVoucherHTML from "@app/appointment/submodules/schedule/html/ScheduleVoucherHTML";
import ScheduleThermalOneWayHTML from "@app/appointment/submodules/schedule/html/ScheduleThermalOneWayHTML";
import ScheduleThermalTwoWayHTML from "@app/appointment/submodules/schedule/html/ScheduleThermalTwoWayHTML";
import GetDataScheduleVoucher from "@app/appointment/submodules/schedule/store/actions/GetDataScheduleVoucher";
import RegisterScheduleVoucherBatch from "@app/appointment/submodules/schedule/store/actions/RegisterScheduleVoucherBatch";
import Print from "$person/helper/Print.js"; // TODO: TEMPORARY

const registerScheduleVoucherBatch = (pAppointmentId) => {
  try {
    RegisterScheduleVoucherBatch(
      "Appointment/Schedule/REGISTER_SCHEDULE_VOUCHER_BATCH",
      { intIdConsulta: pAppointmentId },
    );
  } catch (pError) {
    console.error(pError);
    throw new Error(pError);
  }
};

const printThermalOneWay = async (pAppointmentId) => {
  const data = await GetDataScheduleVoucher(
    "Appointment/Schedule/GET_DATA_SCHEDULE_VOUCHER",
    { intIdConsulta: pAppointmentId },
  );
  const html = ScheduleThermalOneWayHTML(data);

  Print(html);
  registerScheduleVoucherBatch(pAppointmentId);
};

const printThermalTwoWay = async (pAppointmentId) => {
  const data = await GetDataScheduleVoucher(
    "Appointment/Schedule/GET_DATA_SCHEDULE_VOUCHER",
    { intIdConsulta: pAppointmentId },
  );
  const html = ScheduleThermalTwoWayHTML(data);
  Print(html);
  registerScheduleVoucherBatch(pAppointmentId);
};

const printScheduleVoucher = async (pAppointmentId) => {
  const data = await GetDataScheduleVoucher(
    "Appointment/Schedule/GET_DATA_SCHEDULE_VOUCHER",
    { intIdConsulta: pAppointmentId },
  );

  const html = ScheduleVoucherHTML(data);
  Print(html);
  const scheduleFromQueue = data && data.length > 0 && data[0].agendadoPelaFila;
  if (scheduleFromQueue) {
    registerScheduleVoucherBatch(pAppointmentId);
  }
};

export { printThermalOneWay, printThermalTwoWay, printScheduleVoucher };
