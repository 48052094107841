import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query People($peopleName: String) {
    people(pes_nome: $peopleName) {
      rows {
        pes_id
        pes_nome
        pes_mae
        pes_nascimento
        patient {
          pac_id
        }
        employee {
          fun_id
        }
        cns {
          crs_numero
        }
      }
    }
  }
`;

export default async ({ variables }) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.people.rows;
  } catch (err) {
    return Promise.reject(err.graphQLErrors);
  }
};
