import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation FlowBusyToEmptyOrDisinfectionWithExit(
    $busyToEmpty: FlowBusyToEmptyInputType
  ) {
    FlowBusyToEmptyOrDisinfectionWithExit(busyToEmpty: $busyToEmpty) {
      isa_id
    }
  }
`;
export default async ({ commit }, variables) => {
  try {
    const busyToEmpty = variables;
    variables = { busyToEmpty };

    const { data } = await GraphQLClient.mutate({
      mutation,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.FlowBusyToEmptyOrDisinfectionWithExit;
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors);
  }
};
