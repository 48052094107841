<script>
import { RgTypeaheadMixin } from "~tokio/primitive/selection/mixin";

export default {
  name: "RgRemoteTypeaheadMixin",
  mixins: [RgTypeaheadMixin],
  data() {
    return {
      model: null,
      fields: [],
      filter: () => {
        return {
          value: this.inputValue,
        };
      },
      include: [],
      title: "",
    };
  },
  mounted() {
    if (this.model && this.value) {
      this.valueChanged();
    }
  },
  methods: {
    performSearch() {
      this.doSearch(this.filter())
        .then((response) => {
          this.showWait = false;
          this.fillItems(response);
        })
        .catch((pErr) => {
          this.showWait = false;
          console.error(pErr);
        });
    },
    doSearch(pFilter) {
      throw new Error("you have to implement the search method");
    },
    fillItems(data) {
      this.items = [];
      data.forEach((item) => {
        this.items.push(this.formatItem(item));
      });
    },
    formatItem(item) {
      return item;
    },
    valueChanged() {
      if (!this.value) return false;
      this.doFind(this.value, this.configSearch).then((response) => {
        const item = this.formatItem(response);
        this.select(item);
        this.$emit("valueChange", item);
      });
    },
    doFind(pValue, configSearch) {
      throw new Error("You have to implement the find");
    },
  },
};
</script>
