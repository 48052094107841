import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query HistoricEmployeeRelationship($employeeRelationshipId: Int) {
    employeeRelationshipLog(vnl_id_vinculos: $employeeRelationshipId) {
      rows {
        vnl_id_vinculos
        occupationEsus {
          ocp_codigo
          ocp_nome
        }
        logTypes {
          tlg_nome
        }
        vnl_data
        user {
          usu_nome
        }
      }
    }
  }
`;

export default async (store, { employeeRelationshipId = null }) => {
  const variables = {
    employeeRelationshipId,
  };

  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.employeeRelationshipLog.rows;
  } catch (err) {
    return Promise.reject(err.grapQLErrors);
  }
};
