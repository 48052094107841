import MenuMap from "./MenuMap.json";
import isActive from "./isActive";
import { PermissionHasByGlobal } from "$access-control/model/PermissionManager";

export const GetOperationsFromJson = ({ moduleId, subModuleId }) => {
  // preciso obter apenas onde active é diferente de false
  const subModules = MenuMap[moduleId].subModule.filter(
    (submodule) =>
      submodule.active !== false && PermissionHasByGlobal(submodule.permission),
  );
  // eliminando operaçoes escondidas
  const operationKey = subModules[subModuleId].operation.filter((operation) => {
    return operation.active !== false;
  }); // .operation[operationId]
  const operations = [];
  for (const operation of operationKey) {
    const active = isActive(operation);
    const showOperation = active && PermissionHasByGlobal(operation.permission);
    if (showOperation) {
      operations.push({
        name: operation.name,
        iconName: operation.iconName,
        permission: operation.permission,
        active: active,
        className: operation.className,
        module: MenuMap[moduleId].name,
        link: active === false ? null : operation.link,
      });
    }
  }
  return operations;
};
