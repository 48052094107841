<style src="./RgInput.scss" lang="scss" scoped></style>
<template>
  <fieldset class="rg-input--component">
    <RgFormBase :label="label" :optional="optional" :required="isRequired">
      <div slot="right-label">
        <RgValidationAlert :alert="error" class="rg-input--alert" />
      </div>
      <div class="rg-input--base">
        <div class="rg-input--textbox-container">
          <input
            :id="getId"
            ref="inputFieldId"
            v-model="inputValue"
            autocomplete="off"
            class="rg-input--textbox"
            :data-id="dataId"
            :type="type"
            :class="{ 'on-error': hasErrors }"
            :style="styleExtra"
            :maxlength="maxlength"
            :placeholder="placeholder"
            :tabindex="disabled ? -1 : tabIndex"
            :disabled="disabled"
            :readonly="disabled"
            @blur="validateBlur"
            @focus="checkFocus"
            @change="change"
          />
        </div>
      </div>
    </RgFormBase>
  </fieldset>
</template>

<script>
import isEmpty from "lodash/isEmpty";
import { RgFormBase } from "~tokio/foundation/container";
import { RgValidatorMixin, RgValidationAlert } from "../../validation";
import RgInputMixin from "../mixin/RgInputMixin";

export default {
  name: "RgInput",
  components: {
    RgValidationAlert,
    RgFormBase,
  },
  mixins: [RgValidatorMixin, RgInputMixin],
  props: {
    type: {
      type: String,
      default: "text",
    },
    change: {
      type: Function,
      default: () => {},
    },
    styleExtra: {
      type: String,
      default: "",
    },
    maxlength: {
      type: Number,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    getId() {
      return !isEmpty(this.id) ? this.id : this.dataId;
    },
    isRequired() {
      if (
        (this.rules && this.rules.required) ||
        (this.rules && this.rules.compositeValue)
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    inputValue(pValue) {
      this.$emit("onInput", pValue);
    },
  },
  methods: {
    validateBlur() {
      this.validate();
      this.$emit("blur");
    },
  },
};
</script>
