import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchDailyAppointmentReport(
    $initialDate: String
    $finalDate: String
    $uns_id: Int
    $set_id: Int
    $ocp_id: Int
    $pes_id: Int
    $fromQueue: Int
    $situation: Int
    $mne_id: Int
    $orderby: Int
    $set_nome: String
    $ocp_nome: String
    $pes_nome: String
    $mne_nome: String
    $orderByLabel: String
    $toPrint: Boolean
    $toCsv: Boolean
    $toExcel: Boolean
    $limit: Int
    $offset: Int
  ) {
    SearchDailyAppointmentReport(
      initialDate: $initialDate
      finalDate: $finalDate
      uns_id: $uns_id
      set_id: $set_id
      ocp_id: $ocp_id
      pes_id: $pes_id
      set_nome: $set_nome
      ocp_nome: $ocp_nome
      pes_nome: $pes_nome
      fromQueue: $fromQueue
      situation: $situation
      mne_id: $mne_id
      mne_nome: $mne_nome
      orderby: $orderby
      orderByLabel: $orderByLabel
      toPrint: $toPrint
      toCsv: $toCsv
      toExcel: $toExcel
      limit: $limit
      offset: $offset
    ) {
      html
      count
      csv
      excel
      fileName
      rows {
        DATA_HORA_CONSULTA
        PRONTUARIO_UNICO
        PACIENTE
        SEXO
        IDADE
        CNS_OU_CPF
        PROFISSIONAL_DE_SAUDE
        SETOR
        OCUPACAO
        OBJETIVO
        FILA
        SITUACAO
        MOTIVO_NAO_COMPARECIMENTO
        MARCACAO
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.SearchDailyAppointmentReport;
  } catch (err) {
    return err.graphQLErrors;
  }
};
