import { render, staticRenderFns } from "./RgRadioExamStatus.vue?vue&type=template&id=16d61049&scoped=true&lang=html&"
import script from "./RgRadioExamStatus.vue?vue&type=script&lang=js&"
export * from "./RgRadioExamStatus.vue?vue&type=script&lang=js&"
import style0 from "./RgRadioExamStatus.scss?vue&type=style&index=0&id=16d61049&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16d61049",
  null
  
)

export default component.exports