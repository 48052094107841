<style src="./RecordNumberForm.scss" lang="scss" scoped></style>
<template>
  <section class="record-number-form">
    <div class="row patient-medical-record--codes-container">
      <div
        class="patient-medical-record--codes-unique patient-medical-record--col"
      >
        <span class="patient-medical-record--caption label label-center"
          >PRONTUÁRIO ÚNICO</span
        >
        <div class="patient-medical-record--icon--container">
          <IconClipboard class="patient-medical-record--icon" />
        </div>
        <div class="patient-medical-record--value--container">
          <span class="patient-medical-record--value">{{
            patientRecord ? patientRecord.pac_prontuario_unico : ""
          }}</span>
        </div>
      </div>

      <div
        class="patient-medical-record--codes-unit patient-medical-record--col"
      >
        <span class="patient-medical-record--caption label label-center"
          >PRONTUÁRIO</span
        >
        <div class="patient-medical-record--icon--container">
          <IconClipboard class="patient-medical-record--icon" />
        </div>

        <div class="patient-medical-record--input--container">
          <div class="patient-medical-record--input--input-container">
            <input
              v-model="unitHealthRecordNumber"
              type="number"
              class="patient-medical-record--input"
            />
          </div>

          <div class="patient-medical-record--input--icon-container">
            <RgConfirmItem
              v-if="showAddButton"
              @add-action="generateMedicalRecordNumber()"
            />
            <IconCheckAnim
              v-if="showIconAnimation"
              class="patient-medical-record--input--icon"
            />
            <div :title="errorMessage">
              <IconExclamation
                v-if="showIconExclamationError"
                class="patient-medical-record--input--icon"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  IconClipboard,
  IconCheckAnim,
  IconExclamation,
} from "~tokio/primitive/icon";
import RgConfirmItem from "~tokio/foundation/modulebox/rg-confirm-item/RgConfirmItem";
import { mapGetters } from "vuex";

export default {
  name: "RecordNumberForm",
  components: {
    IconClipboard,
    IconCheckAnim,
    IconExclamation,
    RgConfirmItem,
  },
  data() {
    return {
      unitHealthRecordNumber: "",
      showIconAnimation: false,
      debounce: 500,
      showIconExclamationError: false,
      errorMessage: "",
    };
  },
  computed: {
    ...mapGetters({
      patientRecord: ["Patient/GET_PATIENT_RECORD"],
    }),
    showAddButton() {
      return !(this.showIconAnimation || this.showIconExclamationError);
    },
  },
  watch: {
    patientRecord(pData) {
      this.unitHealthRecordNumber = pData.record_numbers.ppr_numero;
    },
  },
  methods: {
    saveUnitMedicalRecord() {},
    async generateMedicalRecordNumber() {
      if (!this.unitHealthRecordNumber) {
        this.$toaster.error("Insira um número no campo prontuário");
        return;
      }

      const variables = {
        ppr_id_unidades_saude: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        ppr_id_pacientes: this.$store.getters["Patient/GET_BASIC_DATA"].patient
          .pac_id,
        ppr_numero: this.unitHealthRecordNumber,
      };

      try {
        await this.$store.dispatch("Patient/GENERATE_RECORD_NUMBER", variables);
        this.$toaster.success("Número de prontuário da unidade salvo!");
      } catch (pErr) {
        this.$toaster.error(pErr.graphQLErrors[0].message);
      }
    },
  },
};
</script>
