import { GraphQLClient, gql } from "@/common/request/GraphQLClient";
import HandleActionError from "@/common/request/HandleActionError";

const mutation = gql`
  mutation SaveHospitalizationSolicitation(
    $hospitalizationSolicitation: HospitalizationSolicitationInputType
  ) {
    SaveHospitalizationSolicitation(
      hospitalizationSolicitation: $hospitalizationSolicitation
    ) {
      ins_id
      ins_id_usuarios
      ins_id_boletins
      ins_data_saida_emergencia
      ins_hora_saida_emergencia
      ins_data_hora
      ins_justificativa_internacao
      ins_sinais_sintomas
      ins_resultados
      ins_diagnostico_inicial
      ins_id_ocupacoes_cbo
      ins_id_funcionarios
      ins_id_internacoes_caracteres
      ins_id_cid10
      ins_id_internacoes_procedimentos
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const hospitalizationSolicitation = variables;
    variables = { hospitalizationSolicitation };

    const { data } = await GraphQLClient.mutate({
      mutation,
      variables,
      fetchPolicy: "no-cache",
    });

    return data.SaveHospitalizationSolicitation;
  } catch (pError) {
    return Promise.reject(HandleActionError(pError));
  }
};
