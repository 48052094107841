import IconAdd from "./Add";
import IconArrow from "./Arrow";
import IconArrowPointDown from "./ArrowPointDown";
import IconBarCode from "./BarCode";
import IconBed from "./Bed";
import IconBells from "./Bells";
import IconBuilding from "./Building";
import IconCake from "./Cake";
import IconCalendar from "./Calendar";
import IconCard from "./Card";
import IconCardId from "./CardId";
import IconInformation from "./Information";
import IconCheck from "./Check";
import IconClipboard from "./Clipboard";
import IconClock from "./Clock";
import IconCross from "./Cross";
import IconClose from "./Close";
import IconDanger from "./Danger";
import IconDocumentPerson from "./DocumentPerson";
import IconDoc from "./Doc";
import IconDocument from "./Document";
import IconDownArrow from "./DownArrow";
import IconPdfBioslab from "./IconPdfBioslab";
import IconUnitHealth from "./UnitHealth";
import IconEmpty from "./Empty";
import IconEraser from "./Eraser";
import IconLogoff from "./Logoff";
import IconOn from "./On";
import IconInfoHelper from "./InfoHelper.vue";
import IconExclamation from "./Exclamation";
import IconOnlyExclamation from "./OnlyExclamation";
import IconExclamationInverse from "./ExclamationInverse";
import IconExit from "./Exit";
import IconInfoHelperAbout from "./InfoHelperAbout";
import IconCancelExit from "./CancelExit";
import IconExitPerson from "./ExitPerson";
import IconIn from "./In";
import IconOut from "./Out";
import IconStar from "./Star";
import IconExchangeArrow from "./ExchangeArrow.vue";
import IconFileImg from "./FileImg.vue";
import IconTransf from "./Transfer";
import IconFolder from "./Folder";
import IconAlarmBioslab from "./AlarmBioslab";
import IconGarbage from "./Garbage";
import IconGenderFemale from "./GenderFemale";
import IconGenderMale from "./GenderMale";
import IconHistory from "./History";
import IconHospital from "./Hospital";
import IconLeftPointArrow from "./LeftPointArrow";
import IconLeftArrow from "./LeftArrow";
import IconDoubleLeftArrow from "./DoubleLeftArrow";
import IconDownloadArrow from "./DownloadArrow";
import IconDoubleLeftPointArrow from "./DoubleLeftPointArrow";
import IconLock from "./Lock";
import IconBaby from "./Baby";
import IconLocked from "./Locked";
import IconList from "./List";
import IconPerfil from "./Perfil";
import IconAttendanceBioslab from "./AttendanceBioslab";
import IconClockBioslab from "./ClockBioslab";
import IconMail from "./Mail";
import IconMenusquares from "./MenuSquares";
import IconMobile from "./Mobile";
import IconMother from "./Mother";
import IconOpen from "./Open";
import IconPrinter from "./Printer";
import IconEditPerson from "./EditPerson";
import IconTag from "./Tag";
import IconPencil from "./Pencil";
import IconMap from "./Map";
import IconPerson from "./Person";
import IconPatientMan from "./PatientMan.vue";
import IconPatientWoman from "./PatientWoman.vue";
import IconIndigentWoman from "./IndigentWoman.vue";
import IconPhoto from "./Photo";
import IconPlace from "./Place";
import IconPower from "./Power";
import IconQuestion from "./Question";
import IconRecords from "./Records";
import IconRemove from "./Remove";
import IconRightArrow from "./RightArrow";
import IconSad from "./Sad";
import IconPatientBioslab from "./PatientBioslab";
import IconSearch from "./Search";
import IconSector from "./Sector";
import IconUnlock from "./Unlock";
import IconUpArrow from "./UpArrow";
import IconUpload from "./Upload";
import IconUser from "./User";
import IconNurse from "./Nurse";
import IconUserUserConfig from "./UserSolid";
import IconWifiSignal from "./WifiSignal";
import IconEllipsis from "./Ellipsis";
import IconTelephone from "./Telephone";
import IconCsv from "./Csv";
import IconTriangleAlert from "./TriangleAlert";
import IconAddExam from "./AddExam";
import IconDeleteCross from "./DeleteCross";
import IconCleanExam from "./CleanExam";
import IconSearchExam from "./SearchExam";
import IconResultExam from "./ResultExam";
import IconPill from "./Pill";
import IconQrCode from "./QrCode";
import IconIndigent from "./Indigent.vue";
import IconPep from "./Pep";
import IconOpenUp from "./OpenUp";
import IconAttachment from "./Attachment";
import IconReload from "./Reload";
import IconDeadBed from "./DeadBed";
import IconArrowRight from "./ArrowRight";
import IconPleni from "./Pleni";
import IconNewSchedule from "./NewSchedule";
import IconDeleteSchedule from "./DeleteSchedule";
import IconShow from "./Show";
import IconHourglass from "./Hourglass";
import IconRegulate from "./Regulate";
import IconUnregulate from "./Unregulate";
import IconExternSchedule from "./ExternSchedule";
import IconDeletePending from "./DeletePending";
import IconDeletePendingResolution from "./DeletePendingResolution";
import IconResolvePending from "./ResolvePending";
import IconLess from "./Less";
import IconArrowSmall from "./ArrowSmall";
import IconArrowDouble from "./ArrowDouble";
import IconBox from "./Box";
import IconCheckedBox from "./CheckedBox";
import IconWebcam from "./Webcam";
import IconArrowBox from "./ArrowBox";
import IconClean from "./Clean";
import IconCalendarLaboratoryExam from "./CalendarLaboratoryExam";
import IconEffectiveSchedule from "./EffectiveSchedule";
import IconOpenSchedule from "./OpenSchedule";
import IconNotEffectiveSchedule from "./NotEffectiveSchedule";
import IconError from "./Error";
import IconPersonUndefined from "./PersonUndefined";
import IconCircleTable from "./CircleTable";
import IconCloseCircle from "./CloseCircle";
import IconSplit from "./Split";
import IconOff from "./Off";
import IconLightBulbHelper from "./LightBulbHelper";
import IconExclamationHelper from "./ExclamationHelper";
import IconConfigure from "./Configure";
import IconCircleArrow from "./CircleArrow";
import IconIntegration from "./Integration";
import IconUserConfig from "./UserConfig";
import IconModules from "./Modules";
import IconAbout from "./About";
import IconHelp from "./Help";
import IconArrowCircleRight from "./ArrowCircleRight";
import IconMedicHome from "./MedicHome";
import IconUnify from "./Unify";

import IconNotView from "./NotView";
import IconView from "./View";

import IconMakeCall from "./MakeCall";
import IconCallNext from "./CallNext";
import IconEndCall from "./EndCall";
import IconStickRow from "./StickRow";
import IconSynchronize from "./Synchronize";

export {
  IconUserConfig,
  IconIntegration,
  IconSplit,
  IconMakeCall,
  IconCallNext,
  IconEndCall,
  IconStickRow,
  IconPdfBioslab,
  IconAdd,
  IconArrow,
  IconArrowPointDown,
  IconBarCode,
  IconInformation,
  IconBed,
  IconBells,
  IconBuilding,
  IconCake,
  IconCalendar,
  IconExchangeArrow,
  IconCard,
  IconCross,
  IconCardId,
  IconCsv,
  IconCheck,
  IconClipboard,
  IconClock,
  IconClose,
  IconDanger,
  IconDoc,
  IconDownloadArrow,
  IconFileImg,
  IconInfoHelper,
  IconPatientMan,
  IconIndigentWoman,
  IconDocument,
  IconDownArrow,
  IconEmpty,
  IconEraser,
  IconOnlyExclamation,
  IconOpenUp,
  IconExclamation,
  IconExclamationInverse,
  IconExit,
  IconCancelExit,
  IconExitPerson,
  IconTransf,
  IconDeleteCross,
  IconDocumentPerson,
  IconFolder,
  IconGarbage,
  IconGenderFemale,
  IconGenderMale,
  IconIndigent,
  IconHospital,
  IconHistory,
  IconLeftArrow,
  IconLeftPointArrow,
  IconDoubleLeftArrow,
  IconAlarmBioslab,
  IconAttendanceBioslab,
  IconCalendarLaboratoryExam,
  IconList,
  IconClockBioslab,
  IconIn,
  IconOut,
  IconPatientBioslab,
  IconPatientWoman,
  IconLock,
  IconLocked,
  IconMail,
  IconMenusquares,
  IconMobile,
  IconStar,
  IconMother,
  IconNurse,
  IconOpen,
  IconPrinter,
  IconEditPerson,
  IconTag,
  IconPencil,
  IconOn,
  IconMap,
  IconPerson,
  IconPhoto,
  IconPlace,
  IconPower,
  IconQuestion,
  IconRecords,
  IconRemove,
  IconRightArrow,
  IconSad,
  IconSearch,
  IconSector,
  IconUnlock,
  IconUpArrow,
  IconUpload,
  IconUser,
  IconUserUserConfig,
  IconWifiSignal,
  IconEllipsis,
  IconTelephone,
  IconTriangleAlert,
  IconSearchExam,
  IconOff,
  IconAddExam,
  IconCleanExam,
  IconResultExam,
  IconDoubleLeftPointArrow,
  IconPill,
  IconQrCode,
  IconPep,
  IconAttachment,
  IconBaby,
  IconReload,
  IconDeadBed,
  IconArrowRight,
  IconPleni,
  IconNewSchedule,
  IconDeleteSchedule,
  IconShow,
  IconHourglass,
  IconRegulate,
  IconUnregulate,
  IconExternSchedule,
  IconDeletePending,
  IconResolvePending,
  IconDeletePendingResolution,
  IconLess,
  IconArrowSmall,
  IconArrowDouble,
  IconBox,
  IconCheckedBox,
  IconWebcam,
  IconArrowBox,
  IconClean,
  IconEffectiveSchedule,
  IconOpenSchedule,
  IconNotEffectiveSchedule,
  IconError,
  IconPersonUndefined,
  IconCircleTable,
  IconCloseCircle,
  IconLogoff,
  IconInfoHelperAbout,
  IconUnitHealth,
  IconPerfil,
  IconLightBulbHelper,
  IconExclamationHelper,
  IconSynchronize,
  IconConfigure,
  IconCircleArrow,
  IconHelp,
  IconModules,
  IconAbout,
  IconArrowCircleRight,
  IconMedicHome,
  IconUnify,
  IconNotView,
  IconView,
};
