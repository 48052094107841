<style src="./ListContent.scss" lang="scss" scoped></style>
<template lang="html">
  <section>
    <ul>
      <li
        v-for="(procedure, idx) in proceduresList"
        :key="idx"
        :class="{ 'alert-background': getProcedureStatus(idx) }"
        class="item row"
      >
        <div class="col-11">
          <div class="row">
            <div class="col col-2">
              <span title="Data do Atendimento"
                ><span class="list-title">Data do Atendimento</span><br />
                {{ procedure.pdf_data_atendimento | dateBR }}</span
              >
            </div>
            <div class="col col-5">
              <span title="Nome do Profissional"
                ><span class="list-title">Profissional </span><br />{{
                  procedure.pdf_nome_profissional
                }}</span
              >
            </div>
            <div class="col col-4">
              <span title="Código e Nome do CBO do Profissional"
                ><span class="list-title">CBO </span><br />{{
                  procedure.ocp_nome
                }}</span
              >
            </div>
          </div>

          <div class="row">
            <div class="col-2 col">
              <span title="Quantidade do Procedimento"
                ><span class="list-title">Quantidade</span><br />
                {{ procedure.pdf_quantidade }}</span
              >
            </div>
            <div class="col-5 col">
              <span title="Nome do Procedimento"
                ><span class="list-title">Procedimento</span><br />
                {{ procedure.name_procedure }}</span
              >
            </div>
            <div class="col-5 col">
              <span title="Código e Nome do CID"
                ><span class="list-title">CID</span><br />
                {{ procedure.name_cid }}</span
              >
            </div>
          </div>
        </div>

        <div class="col-1 actions">
          <RgEditSmall
            class="btn-margin-bottom"
            @confirm-edit="editProcdureItem(procedure, idx)"
          />
          <RgRemoveSmall
            class="btn-margin-bottom"
            @confirm-remove="removeProcdureItem(procedure.pdf_id)"
          />
          <button
            v-if="getProcedureStatus(idx)"
            type="button"
            class="btn-circle-small-warning"
            @click="showProcedureErrors(idx)"
          >
            <IconDanger />
          </button>
        </div>

        <div v-if="getProcedureStatus(idx)" class="alert-error">
          <IconDanger class="alert-icon" />
        </div>
      </li>

      <li
        v-if="!proceduresList || proceduresList.length === 0"
        class="empty-container"
      >
        <IconEmpty class="empty-list" />
        <span>Nenhum procedimento foi adicionado</span>
      </li>
    </ul>
  </section>
</template>

<script>
import { IconEmpty, IconDanger } from "~tokio/primitive/icon";
import RemoveProcdureItem from "../../action/RemoveProcdureItem";
import { RgRemoveSmall, RgEditSmall } from "~tokio/primitive/button";
import ValidateProcedureList from "../../action/ValidateProcedureList";
import moment from "moment";

export default {
  name: "ListContent",
  components: {
    IconEmpty,
    IconDanger,
    RgRemoveSmall,
    RgEditSmall,
  },
  filters: {
    dateBR(pValue) {
      return moment(pValue, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    Cnpj(pValue) {
      if (!pValue) return "NÃO INFORMADO";
      return pValue.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/gi,
        "$1.$2.$3/$4-$5",
      );
    },
    Cns(pValue) {
      if (!pValue) return "NÃO INFORMADO";
      return pValue.replace(/(\d{3})(\d{4})(\d{4})(\d{4})/gi, "$1 $2 $3 $4");
    },
  },
  data() {
    return {};
  },
  computed: {
    bulletinId() {
      return this.$store.getters["Billing/Bulletin/GET_SELECTED_BULLETIN"];
    },
    proceduresList() {
      return this.$store.getters["Billing/Billing/GET_PROCEDURES_LIST"];
    },
    billingId() {
      return this.$store.getters["Billing/Billing/HAS_BOF_ID"];
    },
  },
  watch: {
    bulletinId() {
      this.refreshProcedureList();
    },
    billingId() {
      this.refreshProcedureList();
    },
    proceduresList(pList) {
      ValidateProcedureList(pList);
    },
  },
  mounted() {
    this.refreshProcedureList();
  },
  methods: {
    refreshProcedureList() {
      const pBofId = this.$store.getters["Billing/Billing/HAS_BOF_ID"];
      if (pBofId) {
        this.$store.dispatch("Billing/Billing/LOAD_PROCEDURE_LIST");
      }
    },
    removeProcdureItem(pPdfId) {
      RemoveProcdureItem(pPdfId).then(() => {
        this.$store.dispatch("Billing/Billing/LOAD_PROCEDURE_LIST");
      });
    },
    editProcdureItem(pProcedure, pIdx) {
      const procedureStatus = this.$store.getters[
        "Billing/Billing/GET_PROCEDURE_STATUS_BY_INDEX"
      ](pIdx);
      if (procedureStatus) {
        this.$toaster.error(
          procedureStatus.result.join("<br><br>"),
          "Erros nesse procedimento:",
        );
      }
      this.$emit("edit-procedure-item", { ...pProcedure });
    },
    getProcedureStatus(pIdx) {
      return this.$store.getters[
        "Billing/Billing/GET_PROCEDURE_STATUS_BY_INDEX"
      ](pIdx);
    },
    showProcedureErrors(pIdx) {
      const procedureStatus = this.$store.getters[
        "Billing/Billing/GET_PROCEDURE_STATUS_BY_INDEX"
      ](pIdx);
      if (procedureStatus) {
        this.$toaster.warning(
          procedureStatus.result.join("<br><br>"),
          "Erros nesse procedimento:",
        );
      }
    },
  },
};
</script>
