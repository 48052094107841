<template lang="html">
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M16.625 4.375V2.40625C16.625 1.07888 15.5461 0 14.2187 0H6.78125C5.45387 0 4.375 1.07888 4.375 2.40625V4.375H16.625Z"
        :fill="color"
      />
      <path
        d="M18.5938 5.25H2.40625C1.07888 5.25 0 6.32888 0 7.65625V13.3438C0 14.6711 1.07888 15.75 2.40625 15.75H3.5V12.0312C3.5 11.1869 4.18687 10.5 5.03125 10.5H15.9688C16.8131 10.5 17.5 11.1869 17.5 12.0312V15.75H18.5938C19.9211 15.75 21 14.6711 21 13.3438V7.65625C21 6.32888 19.9211 5.25 18.5938 5.25Z"
        :fill="color"
      />
      <path
        d="M15.9687 11.375H5.03125C4.669 11.375 4.375 11.669 4.375 12.0312V18.5937C4.375 19.9211 5.45387 21 6.78125 21H14.2187C15.5461 21 16.625 19.9211 16.625 18.5937V12.0312C16.625 11.669 16.331 11.375 15.9687 11.375ZM7.65625 14.4375H8.96875C9.331 14.4375 9.625 14.7315 9.625 15.0937C9.625 15.456 9.331 15.75 8.96875 15.75H7.65625C7.294 15.75 7 15.456 7 15.0937C7 14.7315 7.294 14.4375 7.65625 14.4375ZM12.4687 18.375H7.65625C7.294 18.375 7 18.081 7 17.7187C7 17.3565 7.294 17.0625 7.65625 17.0625H12.4687C12.831 17.0625 13.125 17.3565 13.125 17.7187C13.125 18.081 12.831 18.375 12.4687 18.375Z"
        :fill="color"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="21" height="21" :fill="color" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "Printer",
  props: {
    color: {
      type: String,
      default: "#ffffff",
    },
  },
};
</script>
