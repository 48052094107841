<style src="./RgRadioGenderBlock.scss" lang="scss" scoped></style>
<template>
  <div class="rg-radio-gender-block unselect">
    <RgFormBase
      :label="label"
      :required="isRequired"
      @click.stop.prevent="changeGender"
    >
      <div slot="right-label">
        <RgValidationAlert :alert="error" />
      </div>
      <div class="container">
        <fieldset
          :class="{
            active: isManActive,
            'active-masculine': isManActive,
          }"
          :tabindex="isCheckbox ? null : '0'"
          class="radio-gender radio-masculine"
          @keyup.enter="changeGender('M')"
          @click="changeGender('M')"
        >
          <span class="text">Masculino</span>
        </fieldset>
        <fieldset
          :class="{
            active: isWomanActive,
            'active-feminine': isWomanActive,
          }"
          :tabindex="isCheckbox ? null : '0'"
          class="radio-gender radio-feminine"
          @keyup.enter="changeGender('F')"
          @click="changeGender('F')"
        >
          <span class="text">Feminino</span>
        </fieldset>
      </div>
    </RgFormBase>
  </div>
</template>

<script>
import {
  RgValidatorMixin,
  RgValidationAlert,
} from "~tokio/primitive/validation";
import { RgFormBase } from "~tokio/foundation/container";
export default {
  name: "RgRadioGenderBlock",
  components: {
    RgValidationAlert,
    RgFormBase,
  },
  mixins: [RgValidatorMixin],
  props: {
    label: {
      type: String,
      default: "Sexo",
    },
    value: {
      default: "",
    },
    dataId: {
      type: String,
      default: "genero",
    },
    isCheckbox: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedGender: "",
      radio: [],
    };
  },
  computed: {
    isManActive() {
      if (this.isCheckbox) {
        return this.radio.includes("M");
      } else {
        return this.selectedGender === "M";
      }
    },
    isWomanActive() {
      if (this.isCheckbox) {
        return this.radio.includes("F");
      } else {
        return this.selectedGender === "F";
      }
    },
    validValue() {
      return this.selectedGender;
    },
    isRequired() {
      return this.rules && this.rules.required;
    },
  },
  watch: {
    value(pValue) {
      this.selectedGender = pValue;

      if (this.isCheckbox) {
        this.radio = pValue;
      }
    },
  },
  methods: {
    changeGender(pGender) {
      if (this.isCheckbox) {
        if (!this.radio.includes(pGender)) {
          this.radio.push(pGender);
        } else {
          const index = this.radio.findIndex((item) => item === pGender);
          this.radio.splice(index, 1);
        }

        this.$emit("input", this.radio);
      } else {
        this.selectedGender = pGender;
        this.$emit("input", pGender);
      }
      this.validate();
    },
    clearRadio() {
      this.radio = [];
    },
    cleanValidate() {
      this.error = [];
    },
  },
};
</script>
