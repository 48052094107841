import BaseModel from "~common/model/BaseModel";
import PatientModel from "$surgery-center/patient/model/PatientModel";
import Place from "$surgery-center/unit-health/model/Place";
import Sector from "$surgery-center/unit-health/model/Sector";
import SurgeryRequestingPhysicianModel from "./SurgeryRequestingPhysicianModel";
import { BrazilianDateToDatabase } from "~utils/date";

class SurgeryControlModel extends BaseModel {
  constructor() {
    super();
    this._escapeSanitize = [
      "cci_data_criacao",
      "cci_duracao",
      "cci_hora_inicio",
      "canceled.ccc_observacao",
      "canceled.moc_nome",
      "perform.info.ccf_hora_inicio",
      "perform.info.ccf_hora_entrada",
      "perform.info.ccf_hora_termino",
      "perform.info.ccf_hora_saida",
      "perform.anesthesia.ane_hora_anestesia",
    ];
  }

  defineEndPointURI() {
    return "surgery-center/surgery";
  }

  search(filter = {}) {
    return this._request.post(this._endPointURI, filter).then((response) => {
      response.data = this._sanitizer.sanitize(
        response.data,
        this._escapeSanitize,
      );
      return response;
    });
  }

  createRequest(pFormData) {
    const formData = this._prepareForm(pFormData);
    return this._request
      .post("surgery-center/request-surgery", formData)
      .then((response) => {
        return response.data;
      });
  }

  _prepareForm(pFormData) {
    const formattedData = { ...pFormData };
    formattedData.cci_data_inicio = BrazilianDateToDatabase(
      formattedData.cci_data_inicio,
    );

    return formattedData;
  }

  updateRequest(pFormData) {
    const formData = this._prepareForm(pFormData);
    return this._request
      .put("surgery-center/update-request-surgery", formData)
      .then((response) => {
        return response.data;
      });
  }

  removeSchedulingRequest(patientSurgeryId, pCancelDetails) {
    return this._request.delete(
      `surgery-center/surgery/${patientSurgeryId}`,
      pCancelDetails,
    );
  }

  createSurgerySchedule(pFormData) {
    const formData = this._prepareForm(pFormData);
    return this._request
      .post("surgery-center/schedule-surgery", formData)
      .then((response) => {
        return response.data;
      });
  }

  updateSurgerySchedule(pFormData) {
    const formData = this._prepareForm(pFormData);
    return this._request
      .put("surgery-center/update-schedule-surgery", formData)
      .then((response) => {
        return response.data;
      });
  }

  getSurgeryMap(pFilter) {
    return this._request
      .post("surgery-center/surgery-map", pFilter)
      .then((response) => {
        return response.data;
      });
  }

  effectivateSurgery(pFormData) {
    const formData = this._prepareForm(pFormData);
    return this._request
      .post("surgery-center/perform-surgery", formData)
      .then((response) => {
        return response.data;
      });
  }

  emergencySurgery(pFormData) {
    const formData = this._prepareForm(pFormData);
    return this._request
      .post("surgery-center/perform-emergency-surgery", formData)
      .then((response) => {
        return response.data;
      });
  }

  updateEffectivateSurgery(pFormData) {
    const formData = this._prepareForm(pFormData);
    return this._request
      .put("surgery-center/update-performed-surgery", formData)
      .then((response) => {
        return response.data;
      });
  }

  getCompleteSurgeryInfo(surgeryId, sectorId) {
    return new Promise((resolve, reject) => {
      this.find(surgeryId, { cci_id_setores: sectorId })
        .then((res) => {
          PatientModel.patientRemainingInfo(res.data.pac_id).then(
            (patientInfo) => {
              SurgeryRequestingPhysicianModel.find(
                res.data.cci_id_funcionarios,
              ).then((pDoctor) => {
                pDoctor.data.fun_nome = pDoctor.data.pes_nome;
                delete pDoctor.data.pes_nome;
                Sector.find(res.data.cci_id_setores).then((pSector) => {
                  Place.find(res.data.cci_id_locais_atendimento).then(
                    (pPlace) => {
                      resolve(
                        Object.assign(
                          {},
                          res.data,
                          patientInfo,
                          pDoctor.data,
                          pSector.data,
                          pPlace.data,
                        ),
                      );
                    },
                  );
                });
              });
            },
          );
        })
        .catch((pErr) => {
          reject(pErr);
        });
    });
  }
}

export default new SurgeryControlModel();
