import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

export default async (
  store,
  {
    pesId,
    info,
    mutationName,
    mutationParams,
    mutationArgs,
    mutationReturnField,
  },
) => {
  const variables = mountingVariable({ info, pes_id: pesId });
  const queryString = createQueryString({
    mutationName,
    mutationParams,
    mutationArgs,
    mutationReturnField,
  });
  const mutation = gql`
    ${queryString}
  `;

  try {
    const data = await GraphQLClient.mutate({ mutation, variables });
    const response = handleResponse(data);
    return Promise.resolve(response);
  } catch (e) {
    const response = handleResponse(e);
    return Promise.resolve(response);
  }
};

const mountingVariable = ({ info, pes_id }) =>
  Object.assign({}, info, { pes_id });
const createQueryString = ({
  mutationName,
  mutationParams,
  mutationArgs,
  mutationReturnField,
}) => {
  return `mutation CreateDocument (${mutationParams}) {
    ${mutationName} (${mutationArgs}) {
      ${mutationReturnField}
    }
  }`;
};
const handleResponse = (data) => {
  const jsonResponse = JSON.parse(JSON.stringify(data));
  if (jsonResponse.graphQLErrors && jsonResponse.graphQLErrors.length > 0) {
    return { status: false, message: jsonResponse.graphQLErrors[0].message };
  }
  return { status: true };
};
