<template lang="html">
  <svg
    width="464pt"
    height="464pt"
    version="1.1"
    viewBox="0 -18 464 463"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="m456 249.38c-4.418 0-8 3.5781-8 8v22.117h-432v-101.81c0-4.418-3.582-8-8-8s-8 3.582-8 8v241.18c0 4.418 3.582 8 8 8s8-3.582 8-8v-55.375h432v55.375c0 4.418 3.582 8 8 8s8-3.582 8-8v-161.49c0-4.4219-3.582-8-8-8zm-440 98.117v-52h432v52z"
      />
      <path
        d="m53.883 272.5h19.98c20.469-0.046875 37.055-16.609 37.137-37.074v-4.3477c0-20.402-16.734-36.578-37.137-36.578h-19.98c-7.6328-0.082031-14.98 2.8906-20.406 8.2617-5.4258 5.3672-8.4766 12.684-8.4766 20.316v20.348c-0.039062 16 12.883 29.012 28.883 29.074zm-12.883-49.422c-0.003906-3.3906 1.3594-6.6406 3.7852-9.0078 2.4297-2.3711 5.7109-3.6562 9.0977-3.5703h19.98c11.578 0 21.137 9 21.137 20.578v4.3477c-0.070312 11.633-9.5 21.039-21.137 21.074h-19.98c-7.168-0.054688-12.934-5.9062-12.883-13.074z"
      />
      <path
        d="m132.88 194.5c-2.0625-0.089844-4.0742 0.66797-5.5625 2.0977-1.4922 1.4336-2.3281 3.4141-2.3203 5.4805v62.348c-0.027344 2.1172 0.78516 4.1602 2.2656 5.6758 1.4805 1.5117 3.5 2.375 5.6172 2.3984h284.98c4.4609-0.023438 8.0781-3.6133 8.1367-8.0742v-6.3477c0-35.293-28.848-63.578-64.137-63.578zm276.95 62h-268.84v-46h220.86c25.832-0.23828 47.129 20.184 47.973 46z"
      />
      <path
        d="m123.01 162.5h0.058594c44.738 0.015625 81.016-36.234 81.031-80.969s-36.234-81.016-80.969-81.031h-0.066406c-44.734-0.015625-81.012 36.234-81.027 80.973-0.015625 44.734 36.234 81.012 80.973 81.027zm-45.848-127.2c12.176-12.148 28.703-18.922 45.902-18.805h0.054688c17.246-0.074219 33.805 6.75 45.996 18.945 12.191 12.199 19.004 28.766 18.922 46.008-0.054687 35.871-29.098 64.949-64.969 65.047h-0.046875c-26.305-0.089844-49.973-15.992-60-40.309-10.027-24.316-4.4492-52.277 14.141-70.887z"
      />
      <path
        d="m86.273 99.699 18.121-0.007813-0.015625 18.07c0.003906 2.1094 0.84766 4.1328 2.3477 5.6211 1.5039 1.4844 3.5352 2.3086 5.6445 2.2891l21.32-0.17188h0.007813c2.1016 0.054688 4.1367-0.73047 5.6523-2.1875 1.4961-1.4375 2.3438-3.4219 2.3477-5.5l0.015625-18.184 18.113-0.12891h0.007813c2.1055 0.03125 4.1367-0.78125 5.6406-2.2539 1.5-1.4766 2.3516-3.4922 2.3594-5.5977l0.015624-21.18c0.003907-2.1211-0.83984-4.1562-2.3398-5.6602-1.5-1.5-3.5352-2.3398-5.6602-2.3398h-18.109l0.011718-18.094c0-2.1172-0.84375-4.1445-2.3438-5.6367-1.5-1.4961-3.5312-2.3281-5.6484-2.3203l-21.32 0.082031c-2.1094-0.03125-4.1445 0.78125-5.6562 2.2539-1.4961 1.4648-2.3438 3.4727-2.3438 5.5703l-0.015625 18.141-18.125 0.035156c-2.1094-0.023438-4.1445 0.79688-5.6445 2.2812-1.5039 1.4805-2.3516 3.5-2.3555 5.6133l-0.015625 21.312c-0.003906 4.4141 3.5742 7.9922 7.9883 7.9922zm8.0156-21.25 18.121 0.050781h0.003906c2.125-0.015625 4.1562-0.87109 5.6523-2.3789 1.5-1.4766 2.3477-3.4961 2.3477-5.6016l0.015626-18.02h5.3203l-0.015625 18.016c0 2.1133 0.84766 4.1406 2.3477 5.6328 1.5 1.4883 3.5312 2.3203 5.6484 2.3086l18.117-0.042969v5.2422l-18.121-0.15625h-0.007812c-4.4414 0.0625-8.0078 3.6836-8 8.125l-0.015625 18.246h-5.3164l0.011719-18.109c0-4.418-3.5781-7.9961-7.9961-8h-18.117z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Hospitalization",
};
</script>
