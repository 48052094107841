<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchUnitHealthProcedure from "./action/SearchUnitHealthProcedure";

export default {
  name: "RgComboxUnithealth",
  mixins: [RgComboboxMixin],
  props: {
    procedureName: {
      type: String,
    },
  },
  data: () => {
    return {};
  },
  watch: {
    procedureName() {
      this.fillData();
    },
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];

      const userId = this.$store.getters["Login/GET_USER_ID"];

      if (userId && this.procedureName) {
        const data = await SearchUnitHealthProcedure(
          userId,
          this.procedureName,
        );

        data.forEach((item) => {
          if (item.enabled === "1") {
            this.mData.push({ value: item.data, text: item.label });
          }
        });
      }
    },
  },
};
</script>
