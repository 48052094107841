import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (context, { intIdPessoa }) => {
  return AuthLegacyRequest.post(
    "/exames2/controller-fila-exame/buscar-filas-por-pessoa",
    { intIdPessoa },
  )
    .then(({ data }) => {
      data = HandleResponse(data);
      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
