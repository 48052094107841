<style src="./EnableDisableEmployeeForm.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="enable-disable-employee-form">
    <RgForm ref="form" class="form-base form-container">
      <div class="row">
        <span class="span-height">
          Selecione a data inicial e final do período que deseja habilitar ou
          inabilitar o funcionário, depois selecione o motivo, clique na opção
          desejada e confirme novamente.
        </span>
      </div>
      <div class="row">
        <RgInputDate
          v-model="form.vni_data_inicial"
          :rules="{ required: true }"
          placeholder="00/00/0000"
          class="col-6"
          label="Período Inicial"
        />
        <RgInputDate
          v-model="form.vni_data_final"
          :rules="{ required: true }"
          placeholder="00/00/0000"
          class="col-6"
          label="Período Final"
        />
      </div>
      <div class="row">
        <RgComboboxReasonDisableEmployee
          v-model="form.vni_id_motivos_inabilitar_funcionarios"
          :rules="{ fn: requiredIfDisable }"
          class="col-12"
          label="Motivo"
        />
      </div>
      <div slot="command" class="actions row">
        <RgConfirmationButton type="primary" class="col-4" @confirm="enable"
          >Habilitar</RgConfirmationButton
        >
        <RgConfirmationButton type="danger" class="col-4" @confirm="disable"
          >Inabilitar</RgConfirmationButton
        >
      </div>
    </RgForm>
  </section>
</template>

<script>
import { RgInputDate, RgConfirmationButton } from "~tokio/primitive";
import { RgForm } from "~tokio/foundation/form";
import RgComboboxReasonDisableEmployee from "@/app/employee/common/components/combobox/rg-combobox-reason-disable-employee/RgComboboxReasonDisableEmployee";

export default {
  name: "EnableDisableEmployeeForm",
  components: {
    RgForm,
    RgInputDate,
    RgConfirmationButton,
    RgComboboxReasonDisableEmployee,
  },
  props: {
    employeeId: {},
  },
  data: () => {
    return {
      comboboxSectorRelationshipRules: { required: true },
      form: {
        vni_id_vinculos_setores: "",
        vni_id_motivos_inabilitar_funcionarios: "",
        vni_data_inicial: "",
        vni_data_final: "",
        action: null,
      },
      actions: {
        enable: "ENABLE",
        disable: "DISABLE",
      },
    };
  },
  methods: {
    enable() {
      this.form.action = this.actions.enable;
      if (!this.isValid()) {
        return;
      }
      this.$emit("submit", { ...this.form });
      this.$emit("enable", { ...this.form });
    },
    disable() {
      this.form.action = this.actions.disable;
      if (!this.isValid()) {
        return false;
      }
      this.$emit("submit", { ...this.form });
      this.$emit("disable", { ...this.form });
    },
    isValid() {
      return this.$refs.form.validate();
    },
    requiredIfDisable(pValue, pErrors) {
      if (this.form.action === this.actions.disable && !pValue) {
        pErrors.push("Campo obrigatório quando inabilitar");
        return false;
      }
      return true;
    },
    cleanForm() {
      this.form = {
        vni_id_vinculos_setores: "",
        vni_id_motivos_inabilitar_funcionarios: "",
        vni_data_inicial: "",
        vni_data_final: "",
        action: null,
      };
    },
    cleanFields() {
      this.form = {
        vni_id_vinculos_setores: null,
        vni_is_motivos_inabilitar_funcionarios: null,
        vni_data_inicial: null,
        vni_data_final: null,
        vus_id_viculos: null,
      };
    },
  },
};
</script>
