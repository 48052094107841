<script>
import { RgRemoteComboboxMixin } from "~tokio/primitive";
import ListUnitHealthMappedPep from "./action/ListUnitHealthMappedPep";

export default {
  name: "RgComboboxUnithealthMappedPep",
  mixins: [RgRemoteComboboxMixin],
  props: {
    label: {
      type: String,
      default: "Unidade de Saúde",
    },
    defaultText: {
      type: String,
      default: "Selecione",
    },
    emptyDefaultText: {
      type: String,
      default: "Nenhum Item para Selecionar",
    },
    placeId: {
      default: null,
    },
  },
  data() {
    return {};
  },

  watch: {
    placeId(pValue) {
      const isValidId = pValue && pValue > 0;

      if (isValidId) {
        this.getDataFromRemote();
      }
    },
  },
  methods: {
    doSearch() {
      return ListUnitHealthMappedPep({ placeId: this.placeId });
    },
    formatItem(item) {
      return {
        value: item.uns_id,
        text: `${item.uns_nome}`,
      };
    },
  },
};
</script>
