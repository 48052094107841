<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchBloodType from "./action/SearchBloodType";
export default {
  name: "RgComboboxBloodType",
  mixins: [RgComboboxMixin],
  data: () => {
    return {};
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];
      const data = await SearchBloodType();
      data.forEach((item) => {
        this.mData.push({ value: item.tps_id, text: item.tps_nome });
      });
    },
  },
};
</script>
