<script>
import RgTypeaheadMixin from "./mixin/RgTypeaheadMixin";

export default {
  name: "RgTypeahead",
  mixins: [RgTypeaheadMixin],
  data() {
    return {};
  },
  methods: {
    performSearch() {
      this.data.forEach((item) => {
        if (
          item.text.toLowerCase().indexOf(this.inputValue.toLowerCase()) !== -1
        ) {
          this.items.push(item);
        }
      });
      this.showWait = false;
    },
  },
};
</script>
