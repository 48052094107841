<style src="./SmallButton.scss" lang="scss" scoped></style>
<template>
  <div class="small-button">
    <RgButton
      :disabled="disabled"
      :permission="permission"
      :data-id="dataId"
      :style="{
        background: backgroundColor,
      }"
      :title="title"
      class="button"
      @click="clicked"
    >
      <slot name="icon" class="icon" />
    </RgButton>
  </div>
</template>
<script>
import RgButton from "~tokio/primitive/button/rg-button/RgButton.vue";
import RgButtonMixin from "~tokio/primitive/button/rg-button/RgButtonMixin.js";

export default {
  name: "SmallButton",
  components: { RgButton },
  mixins: [RgButtonMixin],
};
</script>
