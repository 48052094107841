import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async (submoduleId, procedureName) => {
  return AuthLegacyRequest.post(
    "unidadesaude/controller-unidade-saude/combo-box-unidade-saude-por-procedimento",
    {
      smd_id: submoduleId,
      strProcedimento: procedureName,
    },
  )
    .then(({ data }) => {
      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
