import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query searchBioslabLaboratoryObject(
    $lob_id: Int
    $lob_codigo_objeto: String
    $lob_nome_objetos: String
    $lle_codigo_sigtap: String
  ) {
    SearchBioslabLaboratoryObject(
      lob_id: $lob_id
      lob_codigo_objeto: $lob_codigo_objeto
      lob_nome_objetos: $lob_nome_objetos
      lle_codigo_sigtap: $lle_codigo_sigtap
    ) {
      rows {
        lob_id
        lob_codigo_objeto
        lob_nome_objetos
      }
    }
  }
`;

export default async (variables) => {
  const { data } = await GraphQLClient.query({
    query,
    variables,
    fetchPolicy: "no-cache",
  }).catch((err) => {
    throw err;
  });
  return data.SearchBioslabLaboratoryObject.count === 1
    ? data.SearchBioslabLaboratoryObject.rows[0]
    : data.SearchBioslabLaboratoryObject;
};
