<template lang="html">
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 34.988 34.988"
    style="enable-background: new 0 0 34.988 34.988"
    xml:space="preserve"
  >
    <g>
      <path
        d="M8.945,2.921L8.189,2.167c2.887-2.888,7.586-2.889,10.473-0.001l-0.756,0.757C15.435,0.452,11.415,0.452,8.945,2.921z
    M17.355,3.474c-2.168-2.166-5.693-2.166-7.859,0.001l0.757,0.755C12.001,2.48,14.85,2.48,16.6,4.229L17.355,3.474z M11.604,5.58
    c1.004-1.006,2.641-1.006,3.646,0l0.756-0.757c-1.422-1.423-3.736-1.423-5.16,0.001L11.604,5.58z M20.643,15.517l-0.24,0.24
    c-0.535,0.535-0.535,1.402,0.002,1.937l0.105,0.105c0.535,0.536,1.399,0.536,1.936,0.001l1.81-1.808l0.237-0.239
    c0.535-0.535,0.535-1.403,0-1.938l-0.107-0.106c-0.532-0.535-1.399-0.535-1.937,0L20.643,15.517z M24.627,16.366l-1.809,1.809
    c-0.532,0.533-0.532,1.401,0,1.936l0.104,0.106c0.535,0.534,1.401,0.534,1.937,0l0.24-0.241l1.567-1.565l0.237-0.24
    c0.537-0.535,0.535-1.4,0.002-1.938L26.8,16.128c-0.534-0.534-1.399-0.537-1.937-0.001L24.627,16.366z M30.598,25.158l-8.08,8.081
    c-0.674,0.674-1.777,0.674-2.449,0.001l-1.391-1.392l-1.472,1.47c0,0-2.191,2.176-5.16,1.561c-0.023-0.005-0.047-0.008-0.069-0.013
    c-0.129-0.028-0.261-0.066-0.392-0.105c-0.107-0.032-0.219-0.07-0.328-0.11c-0.107-0.04-0.215-0.076-0.324-0.125
    c-0.219-0.095-0.438-0.203-0.661-0.333c-0.065-0.039-0.134-0.087-0.201-0.129c-0.187-0.115-0.375-0.246-0.564-0.39
    c-0.073-0.056-0.147-0.112-0.223-0.173c-0.252-0.203-0.505-0.422-0.76-0.678c-2.861-2.861-2.736-5.41-2.201-6.697
    c0.002-0.004,0.438-1.61,0.967-3.524c0.149-0.544,0.308-1.11,0.463-1.677L4.39,17.561c-0.674-0.673-0.674-1.775,0-2.45l8.08-8.079
    c0.674-0.674,1.777-0.674,2.449,0l4.688,4.686l0.426-0.427c0.535-0.535,1.402-0.533,1.938,0l0.105,0.107
    c0.535,0.534,0.535,1.4,0,1.936l-0.239,0.241l-1.565,1.566l-0.242,0.24c-0.532,0.533-1.396,0.536-1.935,0l-0.104-0.104
    c-0.534-0.536-0.534-1.403,0-1.938l0.238-0.24v-0.001l0.528-0.528l-3.97-3.969l-8.828,8.825l2.168,2.166
    C8.496,18.272,8.826,17.121,9,16.573c0.549-1.739,1.935-1.675,2.594-0.617c0.513,0.815,0.115,2.672-0.151,4.774
    c-0.053,0.415-0.192,1.097-0.354,1.821l8.793,8.792l8.823-8.825l-0.655-0.655l-0.77,0.77c-0.534,0.535-1.4,0.533-1.936,0
    l-0.107-0.107c-0.534-0.533-0.534-1.398,0-1.936l0.24-0.24l1.567-1.566l0.237-0.24c0.535-0.534,1.4-0.535,1.938-0.001l0.104,0.105
    c0.537,0.535,0.535,1.401,0,1.938l-0.237,0.238l0.002,0.002L28.9,21.012l1.697,1.697C31.271,23.382,31.271,24.483,30.598,25.158z
    M8.021,13.544c0.08,0.082,0.215,0.08,0.295-0.001l2.586-2.586c0.082-0.081,0.082-0.214,0-0.295
    c-0.082-0.082-0.215-0.082-0.295-0.001l-2.586,2.586C7.939,13.329,7.939,13.461,8.021,13.544z M25.945,27.362
    c-0.338-0.339-0.887-0.339-1.225,0s-0.336,0.887,0,1.225c0.34,0.339,0.887,0.339,1.225,0.001
    C26.282,28.249,26.282,27.702,25.945,27.362z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "MeuEsus",
};
</script>
