<template lang="html">
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="278.799px"
    height="278.799px"
    viewBox="0 0 278.799 278.799"
    style="enable-background: new 0 0 278.799 278.799"
    xml:space="preserve"
  >
    <g>
      <path
        d="M265.54,0H13.259C5.939,0,0.003,5.936,0.003,13.256v252.287c0,7.32,5.936,13.256,13.256,13.256H265.54
    c7.318,0,13.256-5.936,13.256-13.256V13.255C278.796,5.935,272.86,0,265.54,0z M252.284,252.287H26.515V26.511h225.769V252.287z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "GeometrySquare",
};
</script>
