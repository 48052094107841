<template>
  <div>
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      withCloseButton
      :show="show"
      class="modal-attachments"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <h1 class="title">{{ title }}</h1>
      </div>

      <div slot="body" class="body">
        <fieldset class="full-area field">
          <legend class="title-field">Arquivos</legend>

          <div class="half-area bigger">
            <div v-if="!hasFiles" class="empty">
              <IconEmpty class="icon" />
              <span class="info">Não foram encontrados arquivos.</span>
            </div>

            <div v-else class="file-list">
              <div
                v-for="(item, idx) in files"
                :key="idx"
                class="file"
                :class="{ selected: idx === selectedIndex }"
                @click="setIndex(item, idx)"
              >
                <IconFileImg class="svg" />
                <span class="text bottom"
                  >{{ item.document_attachment.doa_nome }} -
                  {{ item.filename }}</span
                >
              </div>
            </div>
          </div>

          <div v-if="hasFiles" class="half-area field">
            <p class="title-field">Visualização</p>

            <div class="field-area">
              <iframe
                v-if="isPDF"
                :src="selectedData.url"
                class="base"
                frameborder="0"
              />

              <img v-else-if="isIMG" class="base" :src="selectedData.url" />

              <div v-else class="base">
                <span class="text"> Arquivo sem pré-visualização. </span>
              </div>
            </div>

            <div class="subpainel-control">
              <a
                :href="selectedData !== null ? selectedData.url : null"
                :disabled="selectedIndex === null"
                :class="{ disable: selectedIndex === null }"
                target="_blank"
                class="see"
                title="Visualizar"
              >
                <IconShow />
              </a>
              <a
                :disabled="selectedIndex === null"
                :class="{ disable: selectedIndex === null }"
                class="download"
                title="Baixar"
                @click="actionDownload"
              >
                <IconDownloadArrow class="svg" />
              </a>
              <a
                :disabled="selectedIndex === null"
                :class="{ disable: selectedIndex === null }"
                class="remove"
                title="Remover arquivo"
                @click="removeFile(selectedData)"
              >
                <IconDiscard class="svg" />
              </a>
            </div>
          </div>
        </fieldset>
      </div>

      <div slot="footer" class="footer">
        <RgComboboxDocumentAttachmentType
          v-model="documentType"
          label="Tipo de Documento"
          @change="selectDocumentType"
        />
        <div class="control-painel">
          <RgNewButton
            medium
            class="new"
            type="button"
            @click="openModalUpload"
          />

          <RgConfirmButton medium type="button" @click="confirm" />
        </div>
      </div>
    </RgBaseModal>

    <ModalUpload
      :show="modalUpload"
      :title="modalUploadTitle"
      @upload="getUploadedFileInfo"
      @close="closeModalUpload"
    />
  </div>
</template>

<script>
import {
  RgBaseModal,
  RgNewButton,
  RgConfirmButton,
  IconEmpty,
  IconFileImg,
  IconDownloadArrow,
  IconDiscard,
} from "~tokio/primitive";
import { IconShow } from "~tokio/primitive/icon/symbols";

import ModalUpload from "~tokio/primitive/modal/modal-upload/ModalUpload.vue";
import RgComboboxDocumentAttachmentType from "$person/common/components/combobox/rg-combobox-document-attachment-type/RgComboboxDocumentAttachmentType";
import RemoveUploadedFile from "./actions/RemoveUploadedFile";
import DownloadByUrl from "@/common/utils/DownloadByUrl";
import { DeepCopy } from "@/common/utils/object";
import { mapGetters } from "vuex";

export default {
  name: "ModalAttachments",
  components: {
    RgBaseModal,
    IconEmpty,
    IconFileImg,
    ModalUpload,
    IconShow,
    IconDownloadArrow,
    RgNewButton,
    RgConfirmButton,
    IconDiscard,
    RgComboboxDocumentAttachmentType,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Anexos do Paciente",
    },
  },

  data() {
    return {
      tempFiles: [],
      modalUpload: false,
      selectedIndex: null,
      selectedData: null,
      documentType: null,
      selectedDocumentType: null,
    };
  },

  computed: {
    ...mapGetters({
      existsPesId: "Person/Patient/EXISTS_PES_ID",
      pesId: "Person/Patient/GET_PERSON_ID",
      attachments: "Person/Patient/GET_ATTACHMENTS",
    }),
    files() {
      return [].concat(this.attachments).concat(this.tempFiles);
    },
    hasFiles() {
      return this.files.length > 0;
    },
    modalUploadTitle() {
      if (this.selectedDocumentType) {
        return `Upload de Documento (${this.selectedDocumentType.text})`;
      }
      return `Upload de Documento (${"Indefinido"})`;
    },
    isPDF() {
      return (
        this.selectedData !== null &&
        this.selectedData.mimetype &&
        this.selectedData.mimetype.includes("pdf")
      );
    },

    isIMG() {
      return (
        this.selectedData !== null &&
        this.selectedData.mimetype &&
        this.selectedData.mimetype.includes("image")
      );
    },
  },

  watch: {
    files: function () {
      this.$emit("totalChanged", this.files.length);
    },
  },

  methods: {
    actionDownload() {
      const url = this.selectedData !== null ? this.selectedData.url : null;
      const name = `${this.selectedData.document_attachment.doa_nome}_${this.selectedData.filename}`;
      DownloadByUrl(url, name);
    },
    setIndex(pValue, pIndex) {
      this.selectedData = pValue;
      this.selectedIndex = pIndex;
    },

    selectDocumentType(pDocType) {
      this.selectedDocumentType = pDocType[0];
    },

    openModalUpload() {
      if (!this.selectedDocumentType) {
        return this.$toaster.error(
          "É preciso selecionar o tipo de documento antes de envia-lo",
        );
      }
      this.modalUpload = true;
    },

    closeModalUpload() {
      this.modalUpload = false;
    },

    cleanSelectionDocumentType() {
      this.selectedDocumentType = null;
      this.documentType = null;
    },

    getUploadedFileInfo(pValue) {
      const info = {
        ...pValue,
        document_attachment: {
          doa_id: this.selectedDocumentType.value,
          doa_nome: this.selectedDocumentType.text,
        },
      };
      this.cleanSelectionDocumentType();
      this.tempFiles = this.tempFiles.concat(info);
    },

    confirm() {
      // // save files on backend
      if (this.tempFiles.length <= 0) {
        this.$toaster.warning("Nenhum arquivo adicionado!");
      }

      // emitir evento para tela principal, com tempFiles, juntando nomes dos arquivos e seus tipos
      this.$emit("confirmed", DeepCopy(this.tempFiles), this.files.length);
      this.$emit("close");
    },

    removeFile(selectedFile) {
      RemoveUploadedFile(selectedFile.url_remove)
        .then(() => {
          this.$toaster.success(`Arquivo {${selectedFile.filename}} removido!`);
        })
        .catch((pErr) => {
          this.$toaster.error("Falha ao remover o arquivo:" + pErr.message);
        })
        .finally(() => {
          // remove from both if exists
          this.tempFiles = this.tempFiles.filter((item) => {
            return item.filename !== selectedFile.filename;
          });
          // TODO call mutation to update or reload attachments on person
          this.$store.commit("Person/Patient/REMOVE_ATTACHMENT", selectedFile);
          this.selectedIndex = null;
          this.selectedData = null;
          this.cleanSelectionDocumentType();
        });
    },

    close() {
      if (this.tempFiles.length > 0) {
        const proceed = confirm(
          "Deseja Confirmar os anexos que foram enviados nessa tela ?",
        );
        if (proceed) {
          return this.confirm();
        }
        // tentar remover arquivos temporarios que nao foram confirmados
        this.tempFiles.forEach((item) => {
          this.removeFile(item);
        });
        this.tempFiles = [];
        this.selectedIndex = null;
        this.selectedData = null;
        return this.confirm();
      } else {
        this.$emit("close");
      }
    },
  },
};
</script>
