import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query($limit: Int) {
    issuingBodies(limit: $limit) {
      rows {
        org_id
        org_nome
      }
    }
  }
`;

export default async (variables) => {
  const { data } = await GraphQLClient.query({ query, variables }).catch(
    (err) => {
      console.error(err);
    },
  );
  return data.issuingBodies.rows;
};
