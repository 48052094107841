<style src="./SurgeryEffectiveness.scss" lang="scss" scoped></style>
<template lang="html">
  <BaseSurgeryView class="surgery-effectiveness-container">
    <ModuleBox
      id="effectiveness-surgery"
      :title="formTitle"
      :granted="hasSchedulingPermission"
      class="surgery-effectiveness-form"
    >
      <div slot="title">
        <RgButtonPrintSurgery />
      </div>
      <SurgeryEffectivenessForm />
    </ModuleBox>
  </BaseSurgeryView>
</template>

<script>
import ModuleBox from "~tokio/foundation/modulebox/Modulebox";
import SurgeryEffectivenessForm from "$surgery-center/surgery/component/surgery-effectiveness-form/SurgeryEffectivenessForm";
import BaseSurgeryView from "$surgery-center/surgery/view/BaseSurgeryView";
import RgButtonPrintSurgery from "$surgery-center/surgery/component/button/rg-button-print-surgery/RgButtonPrintSurgery";
export default {
  name: "SurgeryEffectiveness",
  components: {
    ModuleBox,
    SurgeryEffectivenessForm,
    BaseSurgeryView,
    RgButtonPrintSurgery,
  },
  computed: {
    formTitle() {
      return "Efetivar Uma Cirurgia";
    },
    hasSchedulingPermission() {
      return this.$Permissions.global.has(
        "centro_cirurgico.cirurgia.efetivarCirurgia",
      );
    },
  },
  mounted() {
    this.$store.dispatch("SurgeryCenter/Surgery/fillFilter", {
      filterKey: "cci_id_status_controles_cirurgicos",
      filterValue: [2],
    });
    this.$store.dispatch("SurgeryCenter/Surgery/filter");
  },
};
</script>
