export default {
  GET_BULLETIN_LIST: (state) => state.bulletinList,
  GET_BULLETIN_BY_ID: (state) => (bulletinIdx) =>
    state.bulletinList[bulletinIdx],
  GET_SELECTED_BULLETIN: (state) => state.selectedBulletinId,
  GET_BULLETIN_STATES: (state) => state.bulletinStatus,
  GET_SELECTED_BOF_ID: (state) => state.bofId,
  GET_SELECTED_BULLETIN_NUMBER: (state) => state.selectedBulletinNumber,
  GET_BULLETIN_SEARCH_FILTER: (state) => state.searchFilter,
};
