<template lang="html">
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 286.054 286.054"
    style="enable-background: new 0 0 286.054 286.054"
    xml:space="preserve"
  >
    <g>
      <path
        d="M 143.02734 0 C 64.040348 0 1.8947806e-14 64.040348 0 143.02734 C 0 222.02334 64.040348 286.05469 143.02734 286.05469 C 222.02334 286.05469 286.05469 222.03234 286.05469 143.02734 C 286.05469 64.040348 222.02234 1.8947806e-14 143.02734 0 z M 143.03516 62.726562 C 153.02916 62.726562 161.03025 68.277031 161.03125 76.707031 L 161.03125 155.9082 C 161.03125 164.3292 153.02916 169.88086 143.03516 169.88086 C 132.79016 169.88086 125.04102 164.5522 125.04102 155.9082 L 125.04102 76.707031 C 125.04102 68.072031 132.79116 62.726562 143.03516 62.726562 z M 143.03516 187.72266 C 152.87716 187.72266 160.87791 195.73103 160.87891 205.58203 C 160.87891 215.41503 152.87716 223.42578 143.03516 223.42578 C 133.19316 223.42578 125.18359 215.41503 125.18359 205.58203 C 125.18359 195.73203 133.19316 187.72266 143.03516 187.72266 z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "ExclamationInverse",
};
</script>
