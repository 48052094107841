import { GraphQLClient, gql } from "@/common/request/GraphQLClient";
import { prepareAihInfo } from "./HospitalizationBillingAihHelper";

const query = gql`
  query GetHospitalizationAihInfoByNumber(
    $aihNumber: String
    $notBillingAihId: Int
  ) {
    hospitalizationBillingAih(
      iaf_numero_aih: $aihNumber
      iaf_id_n: $notBillingAihId
      iaf_numero_aih_exato: true
    ) {
      rows {
        iaf_id
        iaf_id_internacoes
        iaf_numero_aih
        iaf_tipo_aih
        iaf_apresentacao
        iaf_data_internacao
        iaf_data_saida
        iaf_orgao_emissor
        iaf_prontuario
        iaf_faturado
      }
    }
  }
`;
export default async ({ commit }, { aihNumber, notBillingAihId }) => {
  if (!aihNumber) return false;

  const variables = {
    aihNumber: aihNumber.replace(/\D+/g, "").trim(),
  };

  if (notBillingAihId) {
    variables.notBillingAihId = notBillingAihId;
  }

  const { data } = await GraphQLClient.query({
    query,
    variables,
    fetchPolicy: "no-cache",
  });

  if (data.hospitalizationBillingAih.rows.length < 1) return false;
  return prepareAihInfo(data.hospitalizationBillingAih.rows[0]);
};
