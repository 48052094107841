import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (context, { variables, permissions = {} }) => {
  const sendData = {
    arrFormData: variables,
    blnPermissaoNormal: permissions.blnPermissaoNormal || false,
    blnPermissaoReserva: permissions.blnPermissaoReserva || false,
  };
  return AuthLegacyRequest.post(
    "/consultas/controller-agendamento-consulta/agendar",
    sendData,
  )
    .then(({ data }) => {
      const scheduleErrors = {
        PacienteConsultadoHojeAtendenteException:
          "PacienteConsultadoHojeAtendenteException",
        PacienteConsultadoHojeException: "PacienteConsultadoHojeException",
        HorarioIndisponivelException: "HorarioIndisponivelException",
      };
      return HandleResponse(data, { extraExceptionsErrors: scheduleErrors });
    })
    .catch((pErr) => {
      throw pErr;
    });
};
