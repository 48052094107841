import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

import { OpenMenuModule } from "~common/utils/app/menu";

import PatientDetails from "$person/patient/view/patient-details/PatientDetails";

export default {
  path: "/emergency/bulletin/registration/edit-patient",
  name: "emergency.bulletin.registration.edit-patient",
  components: {
    default: PatientDetails,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Emergência", link: OpenMenuModule("emergency") },
      { label: "Boletins", link: "/emergency/bulletin" },
      {
        label: "Cadastrar Boletim",
        link: "/emergency/bulletin/registration",
      },
      { label: "Editar Paciente" },
    ],
  },
};
