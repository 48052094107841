import { isEmpty } from "lodash";

/**
 * @returns { false | {document: String, name: String} }
 */
const GetSplittedInfo = (pValue, pReturnsObject = false) => {
  if (isEmpty(pValue) && !pReturnsObject) return false;

  let document = null;
  let name = null;

  if (!isEmpty(pValue)) {
    document = pValue.replace(/\D+/g, "").trim();
    name = pValue
      .replace(/[^\w\s]/gi, "")
      .replace(/\d+/g, "")
      .replace(/\s+/g, " ")
      .toUpperCase()
      .trim();
  }

  const info = {
    document: !isEmpty(document) ? document : null,
    name: !isEmpty(name) ? name : null,
  };

  return info;
};

export default GetSplittedInfo;
