<script>
import { RgRemoteTypeaheadMixin } from "~tokio/primitive";
import SurgeryProcedureModel from "$surgery-center/surgery/model/SurgeryProcedureModel";
export default {
  name: "RgTypeaheadSurgeryProcedure",
  mixins: [RgRemoteTypeaheadMixin],
  props: {
    label: {
      default: "Procedimento",
    },
    min: {
      default: "2",
    },
  },
  data() {
    return {
      model: SurgeryProcedureModel,
      filter: () => {
        return {
          nameOrCode: this.inputValue,
        };
      },
    };
  },
  methods: {
    formatItem(item) {
      const part1 = item.tuc_codigo_procedimento
        ? item.tuc_codigo_procedimento + " - "
        : "";
      const part2 = item.tuc_nome;
      return {
        value: item.tuc_id,
        text: part1 + part2,
      };
    },
  },
};
</script>
