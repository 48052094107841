<template>
  <Tooltip class="appointment-queue-report-tooltip">
    <IconInfoHelper slot="icon" />
    <div slot="content" class="content">
      <span> O relatório não necessita de filtros para sua geração. </span>
      <p>Informações:</p>
      <ul class="list">
        <li class="item">
          Será gerado uma listagem com a quantidade de pacientes na fila de
          consultas por ocupação;
        </li>
      </ul>
    </div>
  </Tooltip>
</template>

<script>
import { Tooltip, IconInfoHelper } from "~tokio/primitive";
export default {
  name: "AppointmentQueueReport",
  components: { Tooltip, IconInfoHelper },
  data() {
    return {
      resultTitle: "Fila de Consultas",
    };
  },

  computed: {
    printReportCss() {
      return ``;
    },

    getNameSmartTable() {
      return "AppointmentQueueReport";
    },

    columnTable() {
      return this.COLUMN;
    },
  },
  created() {
    this.COLUMN = [
      { name: "Ocupação", key: "OCUPACAO" },
      { name: "Quantidade", key: "QUANTIDADE" },
    ];
  },

  methods: {
    buildFilter() {},

    async doSearch(pData) {
      try {
        return await this.$store.dispatch(
          "Appointment/Report/GET_APPOINTMENT_QUEUE_LIST_REPORT",
          pData,
        );
      } catch (error) {
        return false;
      }
    },

    clearFilter() {},
  },
};
</script>
