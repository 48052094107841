<style src="./DefinePeriod.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="export-billing">
    <RgConfirmModal
      :show="showModal"
      :message="changePeriodMessage"
      style="background-color: red"
      title="Você tem certeza que deseja trocar de Competência?"
      @close="closeModal"
      @confirmed="period"
      @denied="closeModal"
    />
    <div class="form">
      <ModuleBox
        :granted="hasPermission"
        class="module-box-export-billing"
        title="Definir periodo para faturamento internação"
      >
        <div class="row">
          <RgComboboxMonth v-model="month" class="col-4" label="Mês" />
          <RgComboboxYear v-model="year" class="col-3" label="Ano" />
          <button
            type="submit"
            class="btn-gerar-faturamento col-3 btn btn-success"
            @click="confirmPeriodChange"
          >
            Definir Competência
          </button>
        </div>
      </ModuleBox>
    </div>
  </section>
</template>

<script>
import ModuleBox from "~tokio/foundation/modulebox/Modulebox";
import RgComboboxMonth from "../../common/component/rg-combobox-month/RgComboboxMonth";
import RgComboboxYear from "../../common/component/rg-combobox-year/RgComboboxYear";
import RgConfirmModal from "~tokio/primitive/notification/modal/RgConfirmModal";
import moment from "moment";

export default {
  name: "DefinePeriod",
  components: {
    ModuleBox,
    RgComboboxMonth,
    RgComboboxYear,
    RgConfirmModal,
  },
  data: () => {
    return {
      year: "",
      month: "",
      showModal: false,
      changePeriodMessage:
        " Caso a competência informada seja anterior a 04 competências, contando com a atual, os lançamentos só terão fins estatísticos.",
    };
  },
  computed: {
    periodDate() {
      return `${this.year}${this.month}`;
    },
    unitHealthId() {
      return this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    },
    loginInfo() {
      return this.$store.getters["Login/GET_COMPLETE_LOGIN_INFO"];
    },
    hasPermission() {
      return this.$Permissions.global.has(
        "faturamento.atendimentosEmergencia",
        this.$store.getters["Login/GET_UNI_HEALTH_ID"],
      );
    },
  },
  async mounted() {
    // SERVIÇO PARA PEGAR ULTIMA COMPETENCIA SELECIONADA
    const pPeriod = {
      cft_id_unidades_saudes: this.unitHealthId,
      cft_id_usuarios: this.loginInfo.user.usu_id,
    };
    let cmp = await this.$store.dispatch(
      "Billing/PeriodHospitalizationBilling/GET_PERIOD_HOSPITALIZATION_BILLING",
      pPeriod,
    );
    if (!cmp) return;
    cmp = cmp.cft_competencia;
    if (!cmp) return;
    this.year = cmp.substring(0, 4);
    this.month = cmp.substring(4, 6);
  },
  methods: {
    confirmPeriodChange() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    async changePeriod() {
      try {
        this.showModal = false;
        const pPeriod = {
          cft_id_unidades_saudes: this.unitHealthId,
          cft_competencia: this.periodDate,
          cft_id_usuarios: this.loginInfo.user.usu_id,
          cft_data_hora: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        };
        const res = await this.$store.dispatch(
          "Billing/PeriodHospitalizationBilling/CREATE_PERIOD_HOSPITALIZATION_BILLING",
          { pPeriod },
        );
        await this.$store.commit(
          "Billing/PeriodHospitalizationBilling/SET_PERIOD_HOSPITALIZATION_BILLING",
          res,
        );
        this.$toaster.success("Competência alterada com sucesso!");
      } catch (pErr) {
        this.$toaster.error("Erro ao definir competência! " + pErr.message);
      }
      this.showModal = false;
    },
    async period() {
      this.changePeriod();
    },
  },
};
</script>
