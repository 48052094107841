<style src="./ListDocuments.scss" lang="scss" scoped></style>
<template>
  <section class="list-documents scrollstyle">
    <ul class="row">
      <li
        v-for="(documentType, documentIdx) in listDocumentsTypes"
        :key="documentIdx"
        class="col-6 document-cell"
        @click="
          openDocument({
            doc_id: documentType.doc_id,
            doc_nome: documentType.doc_nome,
          })
        "
      >
        <div v-if="documentsActive[documentType.doc_id]" class="icon-locked">
          <IconLock />
        </div>
        <span class="document-title">
          {{ documentType.doc_nome }}
        </span>
      </li>
    </ul>
  </section>
</template>

<script>
import { IconLock } from "~tokio/primitive/icon";
import { mapGetters } from "vuex";
import { toast } from "~tokio/primitive/notification";
export default {
  name: "ListDocuments",
  components: {
    IconLock,
  },
  data() {
    return {
      documentsActive: {
        32: true,
        33: true,
        34: true,
        35: true,
        36: true,
        37: true,
        38: true,
      },
    };
  },
  computed: {
    ...mapGetters({
      listDocumentsTypes: ["Patient/LIST_DOCUMENTS_TYPES"],
    }),
  },
  mounted() {
    this.getDocumentsList();
  },
  methods: {
    getDocumentsList() {
      this.$store.dispatch("Patient/GET_DOCUMENTS_LIST");
    },
    openDocument(documentInfo) {
      if (this.documentsActive[documentInfo.doc_id]) {
        toast.warning(
          `O cadastro de ${documentInfo.doc_nome} ainda não foi implementado`,
        );
        return false;
      }
      this.$emit("open-document", documentInfo);
    },
  },
};
</script>
