<template>
  <div class="appointment-user-productivity-report">
    <div class="grid">
      <div class="selectinput">
        <RgComboboxUnithealth
          v-model="form.uns_id"
          :rules="{ required: true }"
          class="inputitem"
        />
      </div>

      <div class="filter-title">
        <span class="title">Período</span>
      </div>

      <div class="selectinput period">
        <RgInputDate
          v-model="form.initialDate"
          label="Data Inicial"
          :rules="{ required: true }"
          class="inputitem"
          placeholder="dd/mm/aaaa"
        />

        <RgInputDate
          v-model="form.finalDate"
          label="Data Final"
          :rules="{ required: true }"
          :max-date="getMaxDateInitial"
          :min-date="getMinDateInitial"
          class="inputitem"
          placeholder="dd/mm/aaaa"
        />
      </div>

      <div class="selectinput">
        <RgComboboxSector
          v-model="form.set_id"
          :disabled="!hasUnitHealth"
          :unit-health="form.uns_id"
          label="Setor"
          class="inputitem"
        />
      </div>

      <div class="selectinput">
        <RgSuggestCbo
          ref="Cbo"
          v-model="form.ocp_nome"
          :disabled="!hasUnitHealth"
          :unitHealthId="form.uns_id"
          :sectorId="Number(form.set_id)"
          class="inputitem"
          @selected="selectedCbo"
        />
      </div>

      <div class="selectinput">
        <RgSuggestUser
          ref="user"
          v-model="suggestUser"
          :rules="{ required: true }"
          class="inputitem"
          label="Usuário"
          @selected="selectedUser"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  RgInputDate,
  RgComboboxUnithealth,
  RgComboboxSector,
  RgSuggestCbo,
  RgSuggestUser,
} from "~tokio/primitive";
import moment from "moment";

export default {
  name: "AppointmentUserProductivity",
  components: {
    RgInputDate,
    RgComboboxUnithealth,
    RgComboboxSector,
    RgSuggestCbo,
    RgSuggestUser,
  },
  data() {
    return {
      resultTitle: "Relatório Produtividade Usuário",
      suggestUser: "",
      suggestCbo: "",
      legend: "",
      form: {
        uns_id: null,
        uns_nome: null,
        set_id: null,
        set_nome: null,
        ocp_id: null,
        ocp_nome: null,
        usu_id: null,
        usu_nome: null,
        initialDate: moment().add(-30, "days").format("DD/MM/YYYY"),
        finalDate: moment().format("DD/MM/YYYY"),
      },
    };
  },

  computed: {
    columnTable() {
      return this.COLUMN;
    },
    getLegend() {
      return this.legend;
    },
    getNameSmartTable() {
      return "AppointmentUserProductivityReport";
    },
    printReportCss() {
      return (
        "@page {size: landscape;}" +
        ".report-column-1 { white-space: normal !important; }"
      );
    },
    hasUnitHealth() {
      return this.form.uns_id > 0;
    },
    getMaxDateInitial() {
      return this.form.initialDate
        ? new Date(
            moment(this.form.initialDate, "DD/MM/YYYY")
              .add(30, "days")
              .format(),
          )
        : null;
    },
    getMinDateInitial() {
      return this.form.initialDate
        ? new Date(moment(this.form.initialDate, "DD/MM/YYYY").format())
        : null;
    },
  },

  watch: {
    "form.uns_id"(pValue) {
      if (!pValue) {
        this.form.uns_nome = null;
      }

      if (pValue[0]) {
        this.form.uns_nome = pValue[0].text;
      }
    },
    "form.set_id"(pValue, pPrev) {
      if (!pValue) {
        this.form.set_nome = null;
      }

      if (!pValue || pValue !== pPrev) {
        this.form.ocp_id = null;
        this.form.ocp_nome = null;
      }

      if (pValue && typeof pValue === "object") {
        this.form.set_nome = pValue[0].text;
      }
    },
    "form.initialDate"(pValue, pPrev) {
      if (!pValue || pValue !== pPrev) {
        this.form.finalDate = "";
      }

      if (pValue && !this.form.finalDate) {
        this.form.finalDate = moment(pValue, "DD/MM/YYYY")
          .add(30, "days")
          .format("DD/MM/YYYY");
      }
    },
  },

  created() {
    this.COLUMN = [
      { name: "Quantidade Agendamentos", key: "QUANTIDADE" },
      { name: "Data da Marcação", key: "DATA" },
      { name: "Ocupação", key: "OCUPACAO", align: "left" },
      { name: "Profissional de Saúde", key: "PROFISSIONAL", align: "left" },
      { name: "Setor", key: "SETOR", align: "left" },
      { name: "Objetivo da Consulta", key: "OBJETIVO", align: "left" },
    ];
  },

  mounted() {
    this.form.uns_id = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    this.form.uns_nome = this.$store.getters["Login/GET_UNIT_HEALTH_NAME"];
  },

  methods: {
    buildFilter() {
      return { ...this.form };
    },

    async doSearch(pData) {
      try {
        return this.$store.dispatch(
          "Appointment/Report/GET_APPOINTMENT_USER_PRODUCTIVITY_REPORT",
          pData,
        );
      } catch (error) {
        return false;
      }
    },

    mountLegend() {
      this.legend = this.form.uns_nome;
    },

    selectedCbo(pCbo) {
      this.form.ocp_id = pCbo.source?.ocp_id || null;
      this.form.ocp_nome = pCbo.source?.ocp_nome || null;
    },

    selectedUser(pUser) {
      this.form.usu_id = pUser.source?.usu_id || null;
      this.form.usu_nome = pUser.source?.usu_nome || null;
    },

    clearFilter() {
      this.suggestUser = null;
      this.form = {
        uns_id: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        uns_nome: this.$store.getters["Login/GET_UNIT_HEALTH_NAME"],
        set_id: null,
        ocp_id: null,
        ocp_nome: null,
        usu_id: null,
        usu_nome: null,
        initialDate: moment().add(-30, "days").format("DD/MM/YYYY"),
        finalDate: moment().format("DD/MM/YYYY"),
      };

      if (this.$refs.occupation) {
        this.$refs.occupation.cleanValidate();
      }

      if (this.$refs.user) {
        this.$refs.user.cleanValidate();
      }
    },
  },
};
</script>
