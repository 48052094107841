<script>
import { RgRemoteComboboxMixin } from "~tokio/primitive";

export default {
  name: "RgComboboxQueueType",
  mixins: [RgRemoteComboboxMixin],
  props: {
    label: {
      default: "Tipo de Fila",
    },
  },
  data() {
    return {};
  },
  methods: {
    doSearch() {
      return this.$store.dispatch("Queue/GetQueueTypes");
    },
    formatItem(item) {
      return {
        value: item.value,
        text: item.label,
      };
    },
  },
};
</script>
