import CREATE_HOSPITALIZATION_AIH_PROCEDURE from "./CreateHospitalizationAihProcedures";
import DESTROY_AIH_INFO_TABS from "./DestroyAihInfoTabs";
import POPULATE_AIH_INFO_TABS from "./PopulateAihInfoTabs";
import GET_HOSPITALIZATION_AIH_LIST from "./GetHospitalizationAihList";
import GET_PATIENT_INFO from "./GetPatientInfo";
import GET_PROCEDURE_DETAILS from "./GetProcedureDetails";
import GET_PATIENT_INFO_BY_HOSPITALIZATION_OR_AIH from "./GetPatientInfoByHospitalizationOrAih";
import GET_HOSPITALIZATION_AIH_INFO from "./GetHospitalizationAihInfo";
import GET_HOSPITALIZATION_AIH_INFO_BY_NUMBER from "./GetHospitalizationAihInfoByNumber";
import GET_HOSPITALIZATION_BILLING_INFO_TAB from "./GetHospitalizationBillingInfo";
import GET_HOSPITALIZATION_AIH_RELEASE_REQUEST_CODE from "./GetHospitalizationAihReleaseRequestCode";
import GET_HOSPITALIZATION_AIH_PROCEDURES from "./GetHospitalizationAihProcedures";
import GET_UNBILLED_EXITS_REPORT from "./GetUnbilledExitsReport";
import GET_HOMONYMS_REPORT from "./GetHomonymsReport";
import GET_PREVIEW_VALUES_REPORT from "./GetPreviewValuesReport";
import GET_AIH_PER_PATIENT_REPORT from "./GetAihPerPatientReport";
import GET_MAIN_PROCEDURE_REPORT from "./GetMainProcedureReport";
import GET_SECONDARY_PROCEDURE_REPORT from "./GetSecondaryProcedureReport";
import GET_MAIN_DIAGNOSIS_REPORT from "./GetMainDiagnosisReport";
import GET_AIH_PER_SPECIALTY_REPORT from "./GetAihPerSpecialtyReport";
import SET_RETURN_AIH_INFO_TABS from "./SetReturnAihInfoTabs";
import SAVE_HOSPITALIZATION_BILLING_AIH_TABS from "./SaveHospitalizationBillingAihTabs";
import BILL_HOSPITALIZATION_BILLING_AIH from "./BillHospitalizationBillingAih";
import GET_HOSPITALIZATION_BILLING_DIAGNOSTICS_INFO from "./GetHospitalizationAihSecondaryCid";
import DELETE_HOSPITALIZATION_AIH_SECONDARY_CID from "./DeleteHospitalizationAihSecondaryCid";
import DELETE_HOSPITALIZATION_AIH_PROCEDURES from "./DeleteHospitalizationAihProcedures";
import DELETE_HOSPITALIZATION_TEAM from "./DeleteHospitalizationTeam";
import GET_INTERNALIZATION_REPORTS from "./GetInternalizationReport";
import GET_EMERGENCY_REPORTS from "./GetEmergencyReport";

export default {
  BILL_HOSPITALIZATION_BILLING_AIH,
  CREATE_HOSPITALIZATION_AIH_PROCEDURE,
  DESTROY_AIH_INFO_TABS,
  POPULATE_AIH_INFO_TABS,
  GET_HOSPITALIZATION_AIH_LIST,
  GET_PATIENT_INFO,
  GET_INTERNALIZATION_REPORTS,
  GET_EMERGENCY_REPORTS,
  GET_PROCEDURE_DETAILS,
  GET_PATIENT_INFO_BY_HOSPITALIZATION_OR_AIH,
  GET_HOSPITALIZATION_BILLING_INFO_TAB,
  GET_HOSPITALIZATION_AIH_INFO,
  GET_HOSPITALIZATION_AIH_INFO_BY_NUMBER,
  GET_HOSPITALIZATION_AIH_RELEASE_REQUEST_CODE,
  GET_HOSPITALIZATION_AIH_PROCEDURES,
  GET_UNBILLED_EXITS_REPORT,
  GET_HOMONYMS_REPORT,
  GET_PREVIEW_VALUES_REPORT,
  GET_AIH_PER_PATIENT_REPORT,
  GET_MAIN_PROCEDURE_REPORT,
  GET_MAIN_DIAGNOSIS_REPORT,
  GET_AIH_PER_SPECIALTY_REPORT,
  GET_SECONDARY_PROCEDURE_REPORT,
  SET_RETURN_AIH_INFO_TABS,
  SAVE_HOSPITALIZATION_BILLING_AIH_TABS,
  GET_HOSPITALIZATION_BILLING_DIAGNOSTICS_INFO,
  DELETE_HOSPITALIZATION_AIH_SECONDARY_CID,
  DELETE_HOSPITALIZATION_AIH_PROCEDURES,
  DELETE_HOSPITALIZATION_TEAM,
};
