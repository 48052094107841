<template lang="html">
  <svg
    id="svg8"
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns:svg="http://www.w3.org/2000/svg"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    width="82.453529mm"
    height="62.986012mm"
    viewBox="0 0 82.45353 62.986012"
    version="1.1"
    inkscape:version="0.92.3 (2405546, 2018-03-11)"
    sodipodi:docname="code.svg"
  >
    <defs id="defs2" />
    <sodipodi:namedview
      id="base"
      pagecolor="#ffffff"
      bordercolor="#666666"
      borderopacity="1.0"
      inkscape:pageopacity="0.0"
      inkscape:pageshadow="2"
      inkscape:zoom="0.83913688"
      inkscape:cx="89.248721"
      inkscape:cy="-1.921456"
      inkscape:document-units="mm"
      inkscape:current-layer="layer1"
      showgrid="false"
      fit-margin-top="0"
      fit-margin-left="0"
      fit-margin-right="0"
      fit-margin-bottom="0"
      inkscape:window-width="1920"
      inkscape:window-height="1017"
      inkscape:window-x="-8"
      inkscape:window-y="-8"
      inkscape:window-maximized="1"
    />
    <metadata id="metadata5">
      <rdf:RDF>
        <cc:Work rdf:about="">
          <dc:format>image/svg+xml</dc:format>
          <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
          <dc:title />
        </cc:Work>
      </rdf:RDF>
    </metadata>
    <g
      id="layer1"
      inkscape:label="Camada 1"
      inkscape:groupmode="layer"
      transform="translate(-48.458612,-127.85908)"
    >
      <g
        id="g20"
        transform="matrix(0.25064872,0,0,0.25064872,27.244943,98.392847)"
      />
      <g
        id="g198"
        transform="matrix(5.109623,0,0,5.109623,50.665741,120.33245)"
      >
        <g id="g142">
          <path
            id="path140"
            d="M 0,1.803 H 0.897 V 13.47 H 0 Z M 1.446,13.47 H 1.951 V 1.803 H 1.446 Z m 0.869,0 H 2.819 V 1.803 H 2.315 Z m 1.167,0 H 4.586 V 1.803 H 3.482 Z m 1.988,0 H 6.022 V 1.803 H 5.47 Z m 1.408,0 H 9.166 V 1.803 H 6.878 Z m 5.962,0 h 0.752 V 1.803 H 12.84 Z M 14.248,1.803 V 13.47 h 1.025 V 1.803 Z M 10.084,13.47 h 0.492 V 1.803 h -0.492 z m 1.197,0 h 0.492 V 1.803 h -0.492 z"
            style="fill: #030104"
            inkscape:connector-curvature="0"
          />
        </g>
        <g id="g144" />
        <g id="g146" />
        <g id="g148" />
        <g id="g150" />
        <g id="g152" />
        <g id="g154" />
        <g id="g156" />
        <g id="g158" />
        <g id="g160" />
        <g id="g162" />
        <g id="g164" />
        <g id="g166" />
        <g id="g168" />
        <g id="g170" />
        <g id="g172" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconBarCode",
};
</script>
