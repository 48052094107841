<style src="./ModalConfirmSchedule.scss" lang="scss" scoped></style>
<template lang="html">
  <div class="modal-confirm-default">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <h1 class="title">
          Este paciente já possui consultas agendadas para o dia selecionado.
        </h1>
      </div>

      <div slot="body" class="body">
        <div class="question">
          <span class="text">
            Deseja realmente agendar esta consulta para este paciente?
          </span>
        </div>
        <FormBase title="Agendamentos do dia" class="content-history">
          <div class="table">
            <tr class="header">
              <th class="title">Data</th>
              <th class="title">Hora</th>
              <th class="title">Unidade de Saúde</th>
              <th class="title">Profissional</th>
              <th class="title">Ocupação</th>
              <th class="title">Objetivo</th>
              <th class="title">Local de Atendimento</th>
              <th class="title">Setor</th>
              <th class="title">Usuário</th>
              <th class="title">Status</th>
            </tr>

            <tr
              v-for="(item, index) in mutableListHistory"
              :key="index"
              class="body"
            >
              <td class="data">{{ item.hag_data_consulta }}</td>
              <td class="data">{{ item.con_hora }}</td>
              <td class="data">{{ item.uns_nome }}</td>
              <td class="data">{{ item.pes_nome }}</td>
              <td class="data">{{ item.ocp_nome }}</td>
              <td class="data">{{ item.tcu_novo_tipo_consulta }}</td>
              <td class="data">{{ item.lca_nome }}</td>
              <td class="data">{{ item.set_nome }}</td>
              <td class="data">{{ item.usu_nome }}</td>
              <td class="data">{{ status }}</td>
            </tr>
          </div>
        </FormBase>
      </div>

      <div slot="footer" class="footer">
        <RgCancelButton medium @click="getCancel" />
        <MediumButton label="Salvar agendamento" @click="save" />
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { RgBaseModal, RgCancelButton, MediumButton } from "~tokio/primitive";

import FormBase from "~tokio/foundation/form-base/FormBase";

export default {
  name: "ModalConfirmSchedule",
  components: {
    RgBaseModal,
    RgCancelButton,
    MediumButton,
    FormBase,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    patientScheduleId: {
      required: true,
    },
    unitHealthId: {
      required: true,
    },
    scheduleDate: {
      required: true,
    },
  },
  data() {
    return {
      mutableListHistory: null,
      status: null,
    };
  },
  watch: {
    show(pValue) {
      if (pValue) {
        this.searchHistory();
      }
    },
  },
  methods: {
    async searchHistory() {
      this.mutableListHistory = await this.$store.dispatch(
        "Appointment/Schedule/SEARCH_HISTORY_SCHEDULE",
        {
          intIdPacienteConsulta: this.patientScheduleId,
          intIdUnidadeSaude: this.unitHealthId,
          strDataConsulta: this.scheduleDate,
        },
      );

      this.mutableListHistory.forEach((item) => {
        if (item.con_id_efetivacao_consulta === "1") {
          return (this.status = "EM ABERTO");
        }
        if (item.con_id_efetivacao_consulta === "2") {
          return (this.status = "EFETIVADA");
        }
        if (item.con_id_efetivacao_consulta === "3") {
          return (this.status = "NÃO EFETIVADA");
        }
      });
    },

    save() {
      this.$emit("save", 1);
    },

    getCancel() {
      this.$emit("cancel", 1);
    },

    close() {
      this.$emit("close");
    },
  },
};
</script>
