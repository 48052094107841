<template>
  <div class="unit-health-search">
    <RgSearch
      ref="rgsearch"
      v-model="mutableUnitHealth"
      :build-filter="generateFilter"
      :search-function="searchFilter"
      :clear-function="cleanForm"
      :item-height="32"
      :max-register="30"
      :show-footer="hasUnitHealth"
      :result-title="'Unidades de Saúde'"
      @count="getCountValue"
    >
      <div slot="filters" class="unit-health-filter">
        <RgInput
          v-model="form.unit"
          :style-letters="false"
          label="Unidade de Saúde"
          :maxlength="100"
          placeholder="Digite o nome da unidade"
        />
        <RgComboboxUnitType
          v-model="form.unitType"
          class="unit-type"
          label="Tipos de Unidade"
          default-text="Todos"
        />
        <RgInputCnpj
          v-model="form.cnpj"
          label="CNPJ"
          placeholder="00.000.000/0001-00"
        />
        <RgInput
          v-model="form.cnes"
          v-mask="'#######'"
          :maxlength="7"
          label="CNES"
          placeholder="0000000"
        />
      </div>

      <div v-if="!hasUnitHealth" slot="menu-top" class="top-button">
        <RgNewButton large :permission="!canInclude" @click="newUnitHealth" />
      </div>

      <div class="unit-health-result">
        <div class="table">
          <SmartTable
            ref="smartTable"
            name="UnitHealthSearch"
            :body="mutableUnitHealth"
            :columns="COLLUMNS"
            :initial-columns="6"
            :max-register="30"
            :total="total"
            :show-pagination="false"
            :item-per-page="pagination.limit"
            toggle-selected
            remove-btn-columns
            class="smart-table"
            @getLine="getValueRow"
          >
            <div slot="top-buttons" class="top-buttons">
              <RgNewButton
                :permission="!canInclude"
                title="Cadastrar Unidade"
                class="item"
                @click="newUnitHealth"
              />
              <RgEditButton
                :permission="!canEdit"
                title="Editar Unidade"
                class="item"
                :disabled="!hasUnitSelected"
                @click="editUnitHealth"
              />
              <RgLessButton
                :permission="!canRemove"
                title="Excluir Unidade"
                class="item"
                :disabled="!hasUnitSelected"
                @click="openModalRemoveUnitHealth"
              />
            </div>
          </SmartTable>
        </div>
      </div>
    </RgSearch>

    <ModalConfirmDeletion
      v-bind="propsModalDeletion"
      @close="closeModalRemoveUnitHealth"
    >
      <div slot="icon" class="icon">
        <IconError />
      </div>
    </ModalConfirmDeletion>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mask } from "vue-the-mask";

import RgSearch from "~tokio/foundation/rg-search/RgSearch";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";

import RgComboboxUnitType from "$unit-health/common/components/combobox/rg-combobox-unit-type/RgComboboxUnitType";

import {
  RgInput,
  RgInputCnpj,
  RgNewButton,
  RgEditButton,
  RgLessButton,
  IconError,
  ModalConfirmDeletion,
} from "~tokio/primitive";

export default {
  name: "UnitHealthSearch",
  components: {
    RgSearch,
    SmartTable,
    RgInput,
    RgInputCnpj,
    RgComboboxUnitType,
    RgNewButton,
    RgEditButton,
    RgLessButton,
    IconError,
    ModalConfirmDeletion,
  },
  directives: {
    mask,
  },

  data() {
    return {
      mutableUnitHealth: [],
      selectedUnit: null,
      modalRemoveUnitHealth: false,
      form: {
        unit: "",
        unitType: null,
        cnpj: "",
        cnes: "",
      },
      pagination: {
        limit: 20,
        offset: 0,
        current: 1,
      },
      total: 0,
    };
  },

  computed: {
    ...mapGetters({
      unitHealthId: "Login/GET_UNIT_HEALTH_ID",
    }),

    hasUnitHealth() {
      return this.mutableUnitHealth?.length > 0;
    },

    hasUnitSelected() {
      return this.selectedUnit;
    },

    permission() {
      // return {
      //   registerUnitHealth: !!this.$Permissions.global.has(
      //     "cadastro.unidadeSaude.incluir",
      //     this.unitHealthId,
      //   ),
      //   editUnitHealth: !!this.$Permissions.global.has(
      //     "cadastro.unidadeSaude.alterar",
      //     this.unitHealthId,
      //   ),
      //   deleteUnitHealth: !!this.$Permissions.global.has(
      //     "cadastro.unidadeSaude.excluir",
      //     this.unitHealthId,
      //   ),
      //   showUnitHealth: !!this.$Permissions.global.has(
      //     "cadastro.unidadeSaude.exibir",
      //     this.unitHealthId,
      //   ),
      // };
      return false;
    },

    canInclude() {
      return this.permission.registerUnitHealth;
    },

    canEdit() {
      return this.permission.editUnitHealth;
    },

    canRemove() {
      return this.permission.deleteUnitHealth;
    },

    propsModalDeletion() {
      const show = this.modalRemoveUnitHealth;
      const noReason = true;
      const title = "Excluir Unidade de Saúde";
      const message = "A operação não poderá ser desfeita.";
      const msgSuccess = "Unidade de Saúde excluída com sucesso!";
      const btnRemoveTitle = "Excluir";
      const confirm = () => this.removeUnitHealth();

      return {
        show,
        noReason,
        confirm,
        title,
        message,
        msgSuccess,
        btnRemoveTitle,
      };
    },
  },

  created() {
    this.COLLUMNS = [
      { name: "Nome", key: "nome" },
      { name: "CNES", key: "cnes" },
      { name: "Tipo", key: "tipo_unidade" },
      { name: "CNPJ", key: "cnpj" },
      { name: "Estado", key: "estado" },
      { name: "Município", key: "municipio" },
    ];
  },

  mounted() {
    this.fillFilter();
  },

  beforeDestroy() {
    const validateIfExist = ValidateIfRouteExistInBreadscrumb(
      "/unit-health/search",
      this.$route.meta.breadcrumb,
    );

    if (!validateIfExist) {
      this.$store.commit("UnitHealth/RESET_FILTER_UNIT_HEALTH");
    } else {
      this.reSearchTable();
    }
  },

  methods: {
    generateFilter() {
      const variables = {
        arrFiltros: {
          uns_nome: this.form.unit,
          tus_id: Number(this.form.unitType),
          uns_cnpj: this.form.cnpj.replace(/[^\d]+/g, ""),
          uns_cnes: this.form.cnes,
        },
      };

      this.$store.commit(
        "UnitHealth/SET_FILTER_UNIT_HEALTH",
        this.$utils.obj.DeepCopy(this.form),
      );

      return variables;
    },

    async searchFilter(pData) {
      this.$loader.start("Carregando Unidades de Saúde...");
      const result = await this.$store.dispatch(
        "UnitHealth/SEARCH_UNIT_HEALTH",
        pData,
      );

      this.$loader.finish();
      return result;
    },

    async fillFilter() {
      try {
        const existFilterData = this.$store.getters[
          "UnitHealth/GET_FILTER_UNIT_HEALTH"
        ];

        if (existFilterData) {
          this.$loader.start("Carregando Unidades de Saúde...");

          this.form.unit = existFilterData.unit;
          this.form.unitType = existFilterData.unitType;
          this.form.cnpj = existFilterData.cnpj;
          this.form.cnes = existFilterData.cnes;

          await this.$refs.rgsearch.performSearch();
          this.$loader.finish();
        }
      } catch (Err) {
        this.$toaster.warning("Erro ao carregar os dados da navegação", Err);
        this.$loader.finish();
      }
    },

    async reSearchTable() {
      try {
        this.selectedUnit = null;
        await this.$refs.rgsearch.performSearch();

        if (this.$refs.smartTable) {
          this.$refs.smartTable.cleanSelectRow();
        }
      } catch (pErr) {
        console.log(pErr);
      }
    },

    newUnitHealth() {
      this.$router.push({ name: "unit-health.register" });
    },

    editUnitHealth() {
      this.$router.push({ name: "unit-health.register.edit" });
    },

    async removeUnitHealth() {
      try {
        const unitId = this.selectedUnit.uns_id;
        await this.$store.dispatch("UnitHealth/REMOVE_UNIT_HEALTH", {
          intIdUnidade: unitId,
        });

        this.$toaster.success("Unidade de Saúde excluída com sucesso!");
      } catch (pErr) {
        this.$toaster.error(pErr, "Erro ao excluir Unidade de Saúde.");
      } finally {
        this.reSearchTable();
      }
    },

    openModalRemoveUnitHealth() {
      this.modalRemoveUnitHealth = true;
    },

    closeModalRemoveUnitHealth() {
      this.modalRemoveUnitHealth = false;
    },

    getValueRow(pValue) {
      this.selectedUnit = pValue;
      this.$store.commit("UnitHealth/SELECT_UNIT_HEALTH", pValue);
    },

    getCountValue(pValue) {
      this.total = Number(pValue) || 0;
    },

    cleanForm() {
      this.mutableUnitHealth = [];
      this.selectedUnit = null;
      this.form = {
        unit: "",
        unitType: null,
        cnpj: "",
        cnes: "",
      };
      this.pagination = {
        limit: 20,
        offset: 0,
        current: 1,
      };
      this.total = 0;
    },
  },
};
</script>
