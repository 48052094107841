<style src="./ConsolidatedForm.scss" lang="scss" scoped></style>
<template>
  <section class="consolidated-form row">
    <Modulebox class="col-12" title="Adicionais">
      <section
        v-shortkey="['f4']"
        class="consolidated-form-container"
        @shortkey="repeatLastInsert"
      >
        <form v-shortkey="['esc']" class="row" @shortkey="cleanFields">
          <div v-if="billingId" class="editMode">
            <div v-shortkey="['esc']" @shortkey="cancelEditMode" />
          </div>

          <div :class="{ 'disabled-field': billingId }" class="col-6 col-md-5">
            <RgSuggestProcedure
              ref="inputProcedure"
              v-model="form.procedure"
              :disabled="billingId"
              label="Procedimento"
              @selected="selectedProcedure"
            />
          </div>

          <div :class="{ 'disabled-field': billingId }" class="col-6 col-md-5">
            <RgSuggestCbo
              v-model="form.cbo"
              :disabled="billingId"
              label="Ocupação"
              @selected="selectedCbo"
            />
          </div>

          <div
            :class="{ 'disabled-field': !haveAge || billingId }"
            class="col-1 col-md-2"
          >
            <RgInputMasked
              v-model="form.age"
              :disabled="!haveAge || billingId"
              label="Idade"
              mask="###"
            />
          </div>

          <div class="col-1 col-md-2">
            <RgInputNumber v-model="form.quantity" label="Qtde." />
          </div>

          <div class="update-options-container col-2 col-md-5">
            <div
              :class="{ 'disabled-field': !billingId }"
              class="update-options"
            >
              <RgInputNumber
                v-model="incrementQuantity"
                :disabled="!billingId"
                label="Incrementa"
                class="input-increment"
              />

              <div class="total-quantity">
                <span class="title">Total</span>
                <span class="value">{{ quantity }}</span>
              </div>
            </div>
          </div>

          <div class="commands col-2 col-md-3">
            <RgSubmit
              ref="btnSubmit"
              :class="{ 'disabled-field': disableBtnIfEmpty }"
              :disabled="disableBtnIfEmpty"
              label="Salvar"
              wait-label="Salvando"
              @submit="saveConsolidatedRow"
              >{{ operationType }}
            </RgSubmit>

            <div class="edit-small">
              <button
                type="button"
                class="edit-small--trytoedit"
                tabindex="-1"
                @click.stop.prevent="cancelEditMode"
              >
                <IconClose />
              </button>
            </div>
          </div>
        </form>
      </section>
    </Modulebox>
  </section>
</template>

<script>
import Modulebox from "~tokio/foundation/modulebox/Modulebox";
import { RgSuggestCbo } from "$billing/common/component/suggest";
import {
  RgInputMasked,
  RgInputNumber,
  RgSubmit,
  RgSuggestProcedure,
} from "~tokio/primitive";
import { IconClose } from "~tokio/primitive/icon/symbols";

export default {
  name: "ConsolidatedForm",
  components: {
    Modulebox,
    RgSubmit,
    RgSuggestCbo,
    RgSuggestProcedure,
    RgInputMasked,
    RgInputNumber,
    IconClose,
  },
  props: {
    editData: {
      default: null,
    },
  },
  data() {
    return {
      filter: {
        procedureCode: null,
        cboCode: null,
      },
      billingId: null,
      form: {
        cbo: "",
        procedure: "",
        quantity: 1,
        age: 1,
      },
      lastForm: {
        cbo: "",
        procedure: "",
      },
      haveAge: false,
      incrementQuantity: 0,
    };
  },
  computed: {
    quantity() {
      const qt = this.form.quantity.toString().replace(".", "");
      const inc = this.incrementQuantity.toString().replace(".", "");
      return parseInt(qt) + parseInt(inc);
    },
    operationType() {
      return this.billingId ? "Atualizar" : "Salvar";
    },
    isEditMode() {
      const matchList = this.$store.getters[
        "Billing/ConsolidatedBilling/LIST_CONSOLIDATED_BILLING"
      ].filter((item) => {
        const sameProcedure =
          item.fco_codigo_procedimento === this.form.procedure;
        const sameOccupation = item.fco_cbo === this.form.cbo;
        const sameAge = item.fco_idade === this.form.age;
        const withAge = this.haveAge ? sameAge : true;

        return sameProcedure && sameOccupation && withAge;
      });
      return matchList;
    },
    disableBtnIfEmpty() {
      const procedureValidate =
        this.filter.procedureCode && this.filter.procedureCode.length === 10;
      const cboValidate =
        this.filter.cboCode && this.filter.cboCode.length === 6;
      return !(procedureValidate && cboValidate);
    },
  },
  watch: {
    "form.cbo"(pValue) {
      if (!pValue) {
        this.filter.cboCode = null;
        this.searchWithVariables();
      } else {
        if (pValue.length === 6)
          this.selectedCbo({ source: { CO_OCUPACAO: pValue } });
      }
    },
    "form.procedure"(pValue) {
      this.haveAge = false;
      if (!pValue) {
        this.filter.procedureCode = null;
        this.searchWithVariables();
      } else {
        if (pValue.length === 10) this.getProcedureDetails(pValue);
      }
    },
    editData(pValue) {
      this.form.cbo = pValue.fco_cbo || this.form.cbo;
      this.form.procedure =
        pValue.fco_codigo_procedimento || this.form.procedure;
      this.form.age = pValue.fco_idade || this.form.age;

      this.selectedCbo({ source: { CO_OCUPACAO: this.form.cbo } });
      this.selectedProcedure({
        source: { CO_PROCEDIMENTO: this.form.procedure },
      });
    },
    isEditMode(pValue) {
      this.checkEditMode(pValue);
    },
  },
  mounted() {
    this.listAllConsolidatedBilling();
  },
  methods: {
    async saveConsolidatedRow() {
      const variables = {
        consolidatedBilling: {
          fco_id: this.billingId,
          fco_competencia: this.$store.getters["Billing/BDSia/GET_PERIOD_DATE"],
          fco_codigo_procedimento: this.form.procedure,
          fco_id_unidades_saude: this.$store.getters[
            "Login/GET_UNIT_HEALTH_ID"
          ],
          fco_cnes: this.$store.getters["Login/GET_UNIT_HEALTH_CNES"],
          fco_quantidade: this.quantity,
          fco_cbo: this.form.cbo,
          fco_faturado: true,
          fco_idade: this.haveAge ? this.form.age : null,
        },
      };

      this.lastForm.cbo = this.form.cbo;
      this.lastForm.procedure = this.form.procedure;

      try {
        await this.$store.dispatch(
          "Billing/ConsolidatedBilling/SAVE_CONSOLIDATED_BILLING",
          { variables },
        );
        await this.listAllConsolidatedBilling();
        this.cleanFields();
        this.$refs.btnSubmit.actionDone();
        this.$refs.inputProcedure.$el.focus();
      } catch (err) {
        this.$toaster.error(err[0].message);
        this.$refs.btnSubmit.actionDone();
      }
    },
    async listAllConsolidatedBilling(variables = {}) {
      await this.$store.dispatch(
        "Billing/ConsolidatedBilling/LIST_CONSOLIDATED_BILLING",
        variables,
      );
    },
    selectedCbo(pCbo) {
      this.filter.cboCode = pCbo.source.CO_OCUPACAO;
      const variables = {
        cboCode: pCbo.source.CO_OCUPACAO,
        procedureCode: this.filter.procedureCode,
        fco_competencia: this.$store.getters["Billing/BDSia/GET_PERIOD_DATE"],
        fco_id_unidades_saude: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
      };
      this.listAllConsolidatedBilling({ variables });
    },
    async getProcedureDetails(procedureCode) {
      const procedureDetails = await this.$store.dispatch(
        "Billing/ConsolidatedBilling/GET_PROCEDURE_DETAILS",
        { procedureCode },
      );
      const procedureField = {
        source: {
          CO_PROCEDIMENTO: procedureCode,
          details: {
            rows: procedureDetails,
          },
        },
      };

      this.selectedProcedure(procedureField);
    },
    selectedProcedure(pProcedure) {
      this.filter.procedureCode = pProcedure.source.CO_PROCEDIMENTO;
      const variables = {
        cboCode: this.filter.cboCode,
        procedureCode: pProcedure.source.CO_PROCEDIMENTO,
      };
      this.listAllConsolidatedBilling({ variables });
      if (pProcedure.source.details) {
        this.applyProcedureFilters(pProcedure.source.details.rows);
      }
    },
    applyProcedureFilters(procedureDetails = []) {
      if (!procedureDetails) return false;
      const vHaveAge = procedureDetails.filter(
        (item) => item.CO_DETALHE === "012",
      );
      this.haveAge = vHaveAge.length > 0;
    },
    searchWithVariables() {
      const variables = {
        cboCode: this.filter.cboCode,
        procedureCode: this.filter.procedureCode,
      };
      this.$store.dispatch(
        "Billing/ConsolidatedBilling/LIST_CONSOLIDATED_BILLING",
        { variables },
      );
    },
    checkEditMode(matchList) {
      if (matchList.length > 0) {
        this.billingId = matchList[0].fco_id;
        this.form.quantity = matchList[0].fco_quantidade;
      } else {
        this.billingId = null;
      }
    },
    cleanFields() {
      this.filter = {
        procedureCode: null,
        cboCode: null,
      };
      this.billingId = null;
      this.form = {
        cbo: "",
        procedure: "",
        quantity: 1,
        age: 1,
      };
      this.haveAge = false;
      this.incrementQuantity = 0;
    },
    cancelEditMode() {
      this.$refs.inputProcedure.setFocus();
      this.cleanFields();
      this.listAllConsolidatedBilling();
    },
    repeatLastInsert() {
      this.form.procedure = this.lastForm.procedure;
      this.form.cbo = this.lastForm.cbo;
    },
  },
};
</script>
