<template>
  <Modulebox
    :title="isEdition ? `Editar Fila` : `Inserir na Fila`"
    class="appointment-registration-queue"
  >
    <div class="appointment-registration-queue-body">
      <form class="form-container" @submit.stop.prevent="saveForm">
        <RgValidatorForm ref="validator">
          <FormBase title="Paciente" class="module-patient">
            <div class="content-inputs">
              <RgSuggestPatientInformation
                ref="patientInfoSuggest"
                v-model="suggestPatient"
                :rules="{ forceSelection: true, required: true }"
                :disabledOnlySuggest="isEdition"
                :with-patient-module="['appointment']"
                :pac_prontuario_unico="
                  patientInfo && patientInfo.prontuario_unico
                "
                :enabled-patient="false"
                :is-patient="false"
                :extra-data="['withoutAddress']"
                class="patient-input"
                @patientInfo="getPatientData"
              />
            </div>
          </FormBase>

          <FormBase
            title="Dados da Regulação"
            class="module-regulation"
            :class="{ disable: !hasIdPatient }"
          >
            <div class="content-grid">
              <div class="row-1 content-grid">
                <RgInputDate
                  v-model="form.entryDate"
                  :disabled="true"
                  tabIndex="-1"
                  :class="{ disable: true }"
                  label="Data de entrada"
                  placeholder="dd/mm/aaaa"
                />

                <RgInputHour
                  v-model="form.entryHour"
                  :disabled="true"
                  tabIndex="-1"
                  :class="{ disable: true }"
                  label="Hora de entrada"
                />

                <RgComboboxUnitHealthLinkedCbo
                  ref="unitInsertion"
                  v-model="form.originUnit"
                  :disabled="!hasIdPatient"
                  :class="{
                    disable: !hasIdPatient,
                  }"
                  :tabIndex="!hasIdPatient ? '-1' : '0'"
                  :rules="{ required: true }"
                  label="Unidade de Inserção"
                />

                <RgComboboxOccupationSynonymsUnit
                  ref="cbo"
                  v-model="occupation"
                  :healthUnitId="form.originUnit"
                  :rules="{ required: true }"
                  :disabled="!hasIdPatient"
                  :class="{ disable: !hasIdPatient }"
                  :tabIndex="!hasIdPatient ? '-1' : '0'"
                  with-bond
                  placeholder="Informe a ocupação"
                  label="Ocupação"
                  @change="selectingCbo"
                />
              </div>
            </div>
          </FormBase>

          <FormBase
            title="Dados da Solicitação"
            class="module-solicitation"
            :class="{ disable: !hasIdPatient }"
          >
            <div class="content-grid">
              <RgSuggestEmployee
                ref="employee"
                v-model="requestingProfessional"
                label="Profissional Solicitante"
                :disabled="!hasIdPatient || isEdition"
                :class="{ disable: !hasIdPatient || isEdition }"
                :rules="{ required: true }"
                :maxlength="100"
                placeholder="Informe o profissional solicitante"
                @selected="selectingProfessional"
                @clear="form.classCouncil = null"
              />

              <RgInput
                v-model="form.classCouncil"
                label="Conselho de Classe"
                :rules="{ required: !form.requestingProfessionalId }"
                :disabled="disableClassCouncil || isEdition"
                :class="{ disable: disableClassCouncil || isEdition }"
                :maxlength="20"
                placeholder="Informe o conselho de classe"
              />

              <RgComboboxUnithealthLegacy
                ref="unitSolicitation"
                v-model="form.requesterUnit"
                :disabled="!hasIdPatient"
                :class="{ disable: !hasIdPatient }"
                :tabIndex="!hasIdPatient ? '-1' : '0'"
                :rules="{ required: true }"
                :subModuleId="subModuleId"
                label="Unidade de saúde solicitante"
              />

              <RgComboboxPriority
                ref="priority"
                v-model="form.priority"
                :rules="{ required: true }"
                :module-id="2"
                :disabled="!hasIdPatient"
                :tabIndex="!hasIdPatient ? '-1' : '0'"
                :class="{ disable: !hasIdPatient }"
                label="Prioridade"
                class="inputitem"
              />

              <RgInputDate
                ref="date"
                v-model="form.requestDate"
                :rules="{ maxTodayDate: true, required: true }"
                :tabIndex="!hasIdPatient ? '-1' : '0'"
                :disabled="isEdition"
                :class="{ disable: isEdition }"
                :max-date="new Date()"
                label="Data da Solicitação"
                placeholder="dd/mm/aaaa"
              />

              <RgInput
                v-if="isEdition"
                v-model="dataLoadAlter.nomeRegulador"
                label="Autorizado por"
                :disabled="isEdition"
                :tabIndex="!hasIdPatient ? '-1' : '0'"
                :class="{ disable: !hasIdPatient || isEdition }"
              />
            </div>

            <div class="area" :class="{ disable: !hasIdPatient }">
              <div class="cbo-area">
                <p class="label">Ocupações</p>
                <div class="table-area">
                  <div
                    v-for="(queuePerPerson, id) in queuesPerPerson"
                    :key="id"
                    class="item"
                  >
                    {{ queuePerPerson.nome }}
                  </div>
                </div>
              </div>

              <RgTextArea
                v-if="!isEdition"
                ref="textArea"
                v-model="form.observations"
                :maxlength="500"
                :disabled="!hasIdPatient"
                :class="{ disable: !hasIdPatient }"
                label="Observações"
                class="inputbox"
                is-optional
              />
            </div>
          </FormBase>
        </RgValidatorForm>
      </form>
    </div>

    <div class="footer">
      <RgCleanButton
        v-show="!isEdition"
        small
        class="clean"
        title="Limpar"
        @click="showConfirmFieldClearing"
      />

      <RgCancelButton
        v-if="showBackButton"
        ref="cancelButton"
        medium
        class="cancel"
        @click="cancel"
      />

      <RgSaveButton
        medium
        class="save"
        :disabled="!hasIdPatient"
        @click="saveForm(false)"
      />

      <MediumButton
        v-if="!isEdition"
        medium
        class="save"
        label="Salvar e inserir arquivo"
        :disabled="!hasIdPatient"
        @click="saveForm(true)"
      />
    </div>

    <ModalConfirmFieldClearing
      ref="confirmClearing"
      :show="modalConfirmFieldClearing"
      @getYes="confirmCleanForm"
      @getOut="closeConfirmFieldClearing"
    />

    <ModalTypePrintInsertQueue
      :show="modalPrint"
      :filId="fil_id"
      @close="closeModalPrint"
    />

    <ModalPatientImageInRow
      ref="modalPatientImageInRow"
      :show="modalPatientImageInRow"
      :fill-id="fil_id"
      :modId="appointmentModuleId"
      @close="closeModalPatientImageInRow"
    />

    <RgConfirmModal
      :show="modal.showConfirmModal"
      title="Atenção"
      :message="modal.msgModalConfirm"
      @close="handleModal(0)"
      @confirmed="handleModal(1)"
      @denied="handleModal(2)"
    />
  </Modulebox>
</template>
<script>
import {
  RgCleanButton,
  RgSaveButton,
  RgSuggestPatientInformation,
  RgInputDate,
  RgInput,
  RgValidatorForm,
  RgComboboxUnithealthLegacy,
  RgSuggestEmployee,
  RgInputHour,
  RgTextArea,
  RgCancelButton,
  RgComboboxPriority,
  RgConfirmModal,
  MediumButton,
} from "~tokio/primitive";
import { ModalPatientImageInRow } from "~tokio/primitive/modal";

import moment from "moment";
import FormBase from "~tokio/foundation/form-base/FormBase";
import Modulebox from "~tokio/foundation/modulebox/Modulebox";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";

import ModalTypePrintInsertQueue from "$appointment/submodules/schedule/component/modal/modal-type-print-insert-queue/ModalTypePrintInsertQueue";
import RgComboboxUnitHealthLinkedCbo from "$appointment/submodules/schedule/component/combobox/rg-combobox-unithealth-linked-cbo/RgComboboxUnitHealthLinkedCbo";
import RgComboboxOccupationSynonymsUnit from "$appointment/common/component/rg-combobox-occupation-synonyms-unit/RgComboboxOccupationSynonymsUnit";
import ModalConfirmFieldClearing from "~tokio/primitive/modal/modal-confirm-field-clearing/ModalConfirmFieldClearing";
import { mapGetters } from "vuex";
export default {
  name: "AppointmentInsertQueue",
  components: {
    Modulebox,
    FormBase,
    RgValidatorForm,
    ModalConfirmFieldClearing,
    RgSuggestPatientInformation,
    RgInputDate,
    RgInput,
    RgComboboxUnitHealthLinkedCbo,
    RgComboboxUnithealthLegacy,
    RgSuggestEmployee,
    RgComboboxPriority,
    RgInputHour,
    RgSaveButton,
    RgCleanButton,
    RgTextArea,
    RgComboboxOccupationSynonymsUnit,
    RgCancelButton,
    ModalTypePrintInsertQueue,
    RgConfirmModal,
    MediumButton,
    ModalPatientImageInRow,
  },
  data: () => {
    return {
      dataPatientQueue: null,
      appointmentQueueId: null,
      patientInfo: {},
      suggestPatient: null,
      occupation: null,
      requesterUnit: null,
      requestingProfessional: null,
      period: null,
      modalConfirmFieldClearing: false,
      modalPatientImageInRow: false,
      modalPrint: false,
      showBackButton: true,
      dataLoadAlter: null,
      fil_id: 0,
      form: {
        patientId: null,
        entryDate: moment().format("DD/MM/YYYY"),
        entryHour: moment().format("HH:mm:ss"),
        originUnit: null,
        requestingProfessionalId: null,
        requesterUnit: null,
        priority: null,
        requestDate: null,
        observations: null,
        occupationName: null,
        classCouncil: null,
        ocpId: null,
      },
      queuesPerPerson: [],
      personData: null,
      modal: {
        showConfirmModal: false,
        msgModalConfirm: null,
      },
      disableClassCouncil: true,
    };
  },

  computed: {
    ...mapGetters({
      userLogin: "Login/GET_COMPLETE_LOGIN_INFO",
      unitHealthId: "Login/GET_UNIT_HEALTH_ID",
      lastPersonIdSaved: "Person/Patient/GET_LAST_PERSON_ID_SAVE",
    }),

    isRequiredCns() {
      const required = this.$store.getters["Login/GET_PREFERENCES"][
        "tViewAgendamento.rbt_cns_fila_consulta"
      ];
      return required === "1";
    },

    hasIdPatient() {
      return Boolean(
        Number.isInteger(this.patientInfo.pes_id) && this.suggestPatient,
      );
    },

    appointmentModuleId() {
      return this.$store.state.Login.route_module_map.appointment;
    },

    isEdition() {
      return this.personData !== null;
    },

    disableEmployeeFields() {
      return !this.isEdition && !this.form.ocpId;
    },

    isBlockPatientDuplication() {
      return !!Number(
        this.$store.getters["Login/GET_PREFERENCES"][
          "tViewMarcacaoConsulta.flc_bloqueio_inserir_fila"
        ],
      );
    },

    subModuleId() {
      return 96;
    },
  },

  watch: {
    suggestPatient(pValue) {
      if (!pValue) {
        this.patientInfo = {};
      }
    },
  },

  created() {
    this.REASONS_BLOCKED_PATIENT = {
      1: "NÃO INFORMADO",
      2: "INABILITADO",
      3: "DESCARTADO",
      4: "ÓBITO",
      5: "INATIVIDADE",
      6: "PRONTUÁRIO DUPLICADO",
      7: "TRIAGEM",
      8: "MOTIVOS ADMINISTRATIVOS",
    };
  },

  async mounted() {
    if (this.lastPersonIdSaved) {
      this.$refs.patientInfoSuggest.fillPatientById(this.lastPersonIdSaved);
    }

    const fromRegulation =
      this.$route.name === "appointment.schedule.queue-insert";
    if (fromRegulation) {
      this.showBackButton = false;
    }

    this.setInitialData();
    const lineSelected = await this.$store.getters[
      "Appointment/Schedule/GET_SELECTED_EXTERNAL_APPOINTMENT_RESULT"
    ];

    if (lineSelected) {
      this.dataLoadAlter = lineSelected[0];
    }

    if (this.dataLoadAlter) {
      await this.loadDataForEditing();
    }
  },

  beforeDestroy() {
    const validateIfExistInsert = ValidateIfRouteExistInBreadscrumb(
      "/appointment/schedule/queue/insert",
      this.$route.meta.breadcrumb,
    );

    const validateIfExistEdit = ValidateIfRouteExistInBreadscrumb(
      "/appointment/schedule/queue/edit-insert",
      this.$route.meta.breadcrumb,
    );

    if (!validateIfExistEdit && !validateIfExistInsert) {
      this.$store.commit(
        "Appointment/Schedule/UNSELECT_QUEUE_EXTERNAL_APPOINTMENT_DATA",
      );
    }
  },

  methods: {
    closeModalPatientImageInRow() {
      this.modalPatientImageInRow = false;
    },

    async saveForm(insertImageInRow) {
      const validadeForm = await this.isFormValid();
      const alertCnsRequired = this.isRequiredCns;

      if (!validadeForm) {
        this.$toaster.warning("Verifique os campos.");
        return;
      }

      if (alertCnsRequired && !this.patientInfo.crs_numero) {
        this.$toaster.warning(
          "É obrigatório que o paciente tenha CNS para que seja inserido na fila.",
        );
        return;
      }

      const isValidatesOccupation = await this.validateEqualsOccupation();

      if (isValidatesOccupation) {
        this.registerQueue(insertImageInRow);
      }

      return true;
    },

    async registerQueue(insertImageInRow) {
      try {
        this.$loader.start();
        this.fil_id = 0;

        const variables = {
          flc_id_sinonimos_ocupacoes_cbo: this.occupation,
          fil_id_pessoas: this.patientInfo.pes_id,
          fil_id_prioridades_fila: Number(this.form.priority),
          fil_id_unidades_saude: this.form.originUnit,
          fil_data_pedido: this.form.requestDate,
          fil_id_funcionarios: this.form.requestingProfessionalId,
          fil_id_unidades_saude_solicitante: this.form.requesterUnit,
          fio_observacao: this.form.observations,
          fil_id_agendado_por: this.userLogin.user.usu_id,
          flc_nome_sinonimos_ocupacoes: this.form.occupationName,
          fil_conselho_classe: this.form.classCouncil,
        };

        if (!this.form.requestingProfessionalId) {
          delete variables.fil_id_funcionarios;
          variables.fil_funcionario_externo = this.requestingProfessional;
        }

        if (this.isEdition) {
          variables.fil_ordem_regulada = this.dataLoadAlter.fil_ordem_regulada;
          variables.flc_id = this.dataLoadAlter.flc_id;
          await this.$store.dispatch(
            "Appointment/Schedule/EDIT_APPOINTMENT_QUEUE",
            variables,
          );
          this.$router.go(-1);
        } else {
          const data = await this.$store.dispatch(
            "Appointment/Schedule/ADD_APPOINTMENT_QUEUE",
            variables,
          );
          this.fil_id = data;
          this.modalPrint = true;

          if (insertImageInRow) {
            this.modalPatientImageInRow = true;
          }
        }

        this.$toaster.success("Operação realizada com sucesso.");
        this.$store.commit(
          "Appointment/Schedule/UNSELECT_QUEUE_EXTERNAL_APPOINTMENT_DATA",
        );
        this.clearForm();
      } catch (error) {
        this.$toaster.error(error);
      } finally {
        this.$loader.finish();
      }
    },

    validateEqualsOccupation() {
      const validateOccupation = this.queuesPerPerson.every(
        (list, index, array) => {
          return this.queuesPerPerson[index].nome !== this.form.occupationName;
        },
      );

      if (!validateOccupation && !this.isEdition) {
        let msg = `O paciente ${this.patientInfo.pac_nome} já se encontra na fila para ${this.form.occupationName}.`;

        if (this.isBlockPatientDuplication) {
          this.$toaster.warning(msg);
        } else {
          msg += "<BR><BR> Deseja confirmar?";
          this.modal.msgModalConfirm = msg;
          this.modal.showConfirmModal = true;
        }

        return;
      }

      return true;
    },

    formatDataBR(pValue) {
      if (!pValue) {
        return "";
      }

      if (pValue.includes("-")) {
        return this.$utils.date.BrazilianDateFormat(pValue);
      } else {
        return pValue;
      }
    },

    async loadDataForEditing() {
      try {
        this.$loader.start();

        const { flc_id } = this.dataLoadAlter;

        this.dataPatientQueue = await this.$store.dispatch(
          "Appointment/Schedule/GET_DATA_PATIENT_APPOINTMENT_QUEUE",
          { flc_id },
        );

        this.personData = await this.$store.dispatch(
          "Appointment/Schedule/GET_PATIENT_BASIC_INFO",
          { pesId: this.dataPatientQueue.fil_id_pessoas },
        );

        await this.fillPatientInfo(this.personData);

        const patient = this.dataPatientQueue.objPaciente;

        this.$refs.patientInfoSuggest.fillPatientById(Number(patient.pes_id));

        const queueDate = this.dataPatientQueue.fil_data;
        const splittedQueueDate = queueDate.split(" ");
        const hasQueueDate = splittedQueueDate && splittedQueueDate.length > 0;

        this.form.entryDate = hasQueueDate ? splittedQueueDate[0] : null;

        this.form.entryHour = hasQueueDate ? splittedQueueDate[1] : null;

        this.form.originUnit = Number(
          this.dataPatientQueue.fil_id_unidades_saude,
        );

        this.form.occupationName = this.dataPatientQueue.flc_nome_sinonimos_ocupacoes;
        this.occupation = Number(
          this.dataPatientQueue.flc_id_sinonimos_ocupacoes_cbo,
        );

        const hasExternalProfessional =
          this.dataPatientQueue.fil_funcionario_externo &&
          !this.dataPatientQueue.objFuncionario.fun_id;

        if (hasExternalProfessional) {
          this.requestingProfessional = this.dataPatientQueue.fil_funcionario_externo;
        } else {
          const employeeData = this.dataPatientQueue.objFuncionario;
          const employeeRelationships = employeeData.objVinculo.vin_vinculos;

          const hasEmployeeRelationships =
            Array.isArray(employeeRelationships) &&
            employeeRelationships.length > 0;

          if (hasEmployeeRelationships) {
            const vinId = employeeRelationships[0].vin_id;

            this.$refs.employee.forceSelection({
              pes_nome: employeeData.pes_nome,
              vin_id: Number(vinId),
            });
          }

          this.requestingProfessional = this.dataPatientQueue.objFuncionario.pes_nome;
        }

        if (this.dataPatientQueue?.fil_id_funcionarios) {
          this.form.requestingProfessionalId = this.dataPatientQueue?.fil_id_funcionarios;
        }

        this.form.classCouncil = this.dataPatientQueue.fil_conselho_classe;
        this.disableClassCouncil = !!this.dataPatientQueue.fil_conselho_classe;

        this.form.requesterUnit = Number(
          this.dataPatientQueue.fil_id_unidades_saude_solicitante,
        );

        this.form.priority = Number(
          this.dataPatientQueue.fil_id_prioridades_fila,
        );

        this.form.requestDate = this.dataPatientQueue.fil_data_pedido;

        this.form.observations = this.dataPatientQueue.objFilaObservacao.fio_observacao;
      } catch (Err) {
        this.$toaster.error(Err);
      } finally {
        this.$loader.finish();
      }
    },

    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    async getPatientData(pPatient) {
      try {
        const hasPerson = pPatient && pPatient.pes_id > 0;
        const patientBlocked =
          hasPerson &&
          pPatient.patient &&
          pPatient.patient.pac_id_motivo_inabilitacao;

        if (patientBlocked) {
          const patientIdReasonBlocked =
            pPatient.patient.pac_id_motivo_inabilitacao;

          return this.$toaster.warning(
            "Paciente inativo. Motivo: " +
              `${this.REASONS_BLOCKED_PATIENT[patientIdReasonBlocked]}`,
          );
        }

        if (!pPatient.patient && hasPerson) {
          // call action to create patient and fill pPatient.patient with patient data?
          const result = await this.$store.dispatch(
            "Person/Patient/CREATE_PATIENT_FOR_PERSON",
            {
              pes_id: pPatient.pes_id,
              withPatientAppointment: true,
            },
          );
          pPatient.patient = result;
        }

        if (pPatient.patient !== null && hasPerson) {
          this.patientInfo.pac_id =
            pPatient && pPatient.patient ? pPatient.patient.pac_id : null;
          this.patientInfo.pcc_id =
            this.patientInfo.pac_id && pPatient.patient.patient_appointment
              ? pPatient.patient.patient_appointment.pcc_id
              : null;
          this.fillPatientInfo(pPatient);
        } else {
          this.patientInfo = {
            pac_id: "",
            pcc_id: "",
          };
        }
        if (this.patientInfo.pes_id) {
          this.queuesPerPerson = await this.$store.dispatch(
            "Appointment/Schedule/SEARCH_APPOINTMENT_QUEUES_PER_PERSON",
            { intIdPessoa: this.patientInfo.pes_id },
          );
        }
      } catch (error) {
        console.error(error);
      }
    },

    cancel() {
      this.$router.push({ name: "appointment.schedule.queue" });
    },

    selectingCbo(pCbo) {
      const hasCbo = pCbo && pCbo[0];
      if (hasCbo) {
        this.$loader.start();
        this.form.occupationName = pCbo[0].text;
        this.form.ocpId = pCbo[0].ocp_id;
        this.$loader.finish(1000);
      } else {
        this.form.occupationName = "";
        this.form.ocpId = null;
      }
    },

    selectingProfessional(pProfessional) {
      const source = pProfessional && pProfessional.source;
      if (source) {
        if (
          source.employeeRelationship &&
          source.employeeRelationship.vin_id_funcionarios
        ) {
          this.form.requestingProfessionalId =
            source.employeeRelationship.vin_id_funcionarios;
        }
        this.form.classCouncil = source.fun_conselho_classe;
        this.disableClassCouncil = !!source.fun_conselho_classe;
      } else {
        this.form.requestingProfessionalId = null;
      }
    },

    showConfirmFieldClearing() {
      this.modalConfirmFieldClearing = true;
    },

    closeModalPrint() {
      this.modalPrint = false;
      this.fil_id = 0;
    },

    confirmCleanForm() {
      this.clearForm();
      this.modalConfirmFieldClearing = false;
    },

    closeConfirmFieldClearing() {
      this.modalConfirmFieldClearing = false;
    },

    fillPatientInfo(pData) {
      this.patientInfo = {
        pac_id: pData.patient.pac_id,
        pes_id: pData.pes_id,
        pac_nome: pData.pes_nome,
        crs_numero: pData && pData.cns ? pData.cns.crs_numero : null,
        pes_mae: pData && pData.pes_mae ? pData.pes_mae : null,
        cpf: pData && pData.cpf ? pData.cpf.cpf_numero : null,
        prontuario_unico:
          pData && pData.patient ? pData.patient.pac_prontuario_unico : null,
      };
    },

    setInitialData() {
      this.form.originUnit = this.unitHealthId;
      this.form.requesterUnit = this.unitHealthId;
    },

    clearForm() {
      if (this.isEdition) {
        this.form.originUnit = null;
        this.occupation = null;
        this.form.occupationName = null;
        this.form.requesterUnit = null;
        this.form.priority = null;
      } else {
        this.patientInfo = {};
        this.suggestPatient = null;
        this.occupation = null;
        this.requesterUnit = null;
        this.requestingProfessional = null;
        this.classCouncil = null;
        this.period = null;
        this.queuesPerPerson = [];
        this.modalConfirmFieldClearing = false;
        this.form = {
          patientId: null,
          entryDate: moment().format("DD/MM/YYYY"),
          entryHour: moment().format("HH:mm:ss"),
          originUnit: null,
          requestingProfessionalId: null,
          requesterUnit: null,
          priority: null,
          requestDate: null,
          observations: null,
          classCouncil: null,
        };
        this.setInitialData();
        this.$refs.patientInfoSuggest.cleanValidate();
        this.$refs.cbo.cleanValidate();
        this.$refs.employee.cleanValidate();
        this.$refs.date.cleanValidate();
        this.$refs.priority.cleanValidate();
        this.$refs.unitInsertion.cleanValidate();
        this.$refs.unitSolicitation.cleanValidate();
        this.$refs.textArea.resetCharacter();
      }
    },

    async handleModal(option) {
      const isOptionCloseOrDenied = option === 0 || option === 2;
      const isOptionConfirmed = option === 1;

      if (isOptionCloseOrDenied) {
        this.modal.showConfirmModal = false;
      }

      if (isOptionConfirmed) {
        this.modal.showConfirmModal = false;
        await this.registerQueue();
      }
    },
  },
};
</script>
