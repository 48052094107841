<template lang="html">
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3125 15.3806V7.0816C13.3125 5.93124 14.2437 5 15.3941 5C16.5445 5 17.4757 5.93124 17.4757 7.0816V15.3943C17.4757 16.5447 16.5445 17.4759 15.3941 17.4759C14.2437 17.4622 13.3125 16.531 13.3125 15.3806ZM48.6176 17.4622C49.768 17.4622 50.6992 16.531 50.6992 15.3806V7.0816C50.6992 5.93124 49.768 5 48.6176 5C47.4673 5 46.536 5.93124 46.536 7.0816V15.3943C46.536 16.531 47.4673 17.4622 48.6176 17.4622Z"
      fill="white"
    />
    <path
      d="M54.5066 13.3127H52.781V15.3943C52.781 17.6814 50.9186 19.5439 48.6315 19.5439C46.3445 19.5439 44.482 17.6814 44.482 15.3943V13.3127H19.5575V15.3943C19.5575 17.6814 17.6951 19.5439 15.408 19.5439C13.121 19.5439 11.2585 17.6814 11.2585 15.3943V13.3127H9.50558C7.01313 13.3127 5 15.3533 5 17.8731V54.4244C5 56.9443 7.01313 58.9848 9.50558 58.9848H34.074H40.8803H54.4929C56.9853 58.9848 58.9985 56.9306 58.9985 54.4244V36.2241V34.074V17.8731C58.9985 15.3533 56.9853 13.3127 54.5066 13.3127ZM54.849 34.074V36.5391V54.5203C54.849 54.7394 54.6846 54.849 54.5066 54.849H35.4024H34.074H9.50558C9.31385 54.849 9.16321 54.6573 9.16321 54.4381V25.7613H54.849V34.074V34.074Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M32 49C36.4183 49 40 45.4183 40 41C40 36.5817 36.4183 33 32 33C27.5817 33 24 36.5817 24 41C24 45.4183 27.5817 49 32 49ZM32 51C37.5228 51 42 46.5228 42 41C42 35.4772 37.5228 31 32 31C26.4772 31 22 35.4772 22 41C22 46.5228 26.4772 51 32 51Z"
      fill="white"
    />
    <rect x="31" y="37" width="2" height="9" rx="1" fill="white" />
  </svg>
</template>
<script>
export default {
  name: "DisabledPeriods",
};
</script>
