import AuthRequest from "~common/request/AuthRequest";

const RemoveUploadedFile = (url, mod_id = null) => {
  const data = {};
  if (mod_id) {
    data.mod_id = mod_id;
  }
  return AuthRequest.post(url, data);
};

export default RemoveUploadedFile;
