export default {
  RESET_RECENT_OPERATION: (state) => {
    state.recent_operations = [];
    state.item_per_page = null;
  },

  RESET_NEW_FAVORITE: (state) => {
    state.new_favorite = null;
  },

  SET_ITEM_PER_PAGE: (state, pNumber) => {
    state.item_per_page = pNumber;
  },

  SET_NEW_FAVORITE: (state, pData) => {
    state.new_favorite = pData;
  },

  ACTIVE_FAVORITE_MODE: (state) => {
    state.favorite_mode = true;
  },

  DISABLE_FAVORITE_MODE: (state) => {
    state.favorite_mode = false;
  },

  PUSH_RECENT_OPERATION: (state, pData) => {
    if (pData.link) {
      const alreadyhasOperation = state.recent_operations.findIndex(
        (item) => item.link === pData.link,
      );

      if (alreadyhasOperation > -1) {
        state.recent_operations.splice(alreadyhasOperation, 1);
      }

      const totalPerPage = state.item_per_page || 12;

      if (state.recent_operations.length >= totalPerPage) {
        state.recent_operations.pop();
      }

      state.recent_operations.unshift(pData);
    }
  },
};
