<template>
  <fieldset class="rg-input-color">
    <RgFormBase :label="label" :optional="optional" :required="isRequired">
      <div slot="right-label">
        <RgValidationAlert :alert="error" class="rg-input--alert" />
      </div>
      <div class="rg-input--base">
        <div class="rg-input--textbox-container">
          <input
            :id="getId"
            ref="inputFieldId"
            v-model="inputValue"
            list="presets"
            class="rg-input--textbox"
            type="color"
            :data-id="dataId"
            :class="{
              'on-error': hasErrors,
            }"
            :tabindex="disabled ? -1 : tabIndex"
            :disabled="disabled"
            :readonly="disabled"
            @blur="validateBlur"
            @focus="checkFocus"
            @change="change"
          />
          <datalist id="presets">
            <option value="#A40003"></option>
            <option value="#FF4800"></option>
            <option value="#FFD000"></option>
            <option value="#007F5F"></option>
            <option value="#00355E"></option>

            <option value="#D02224"></option>
            <option value="#FF9100"></option>
            <option value="#FFEA00"></option>
            <option value="#55A630"></option>
            <option value="#1A759F"></option>

            <option value="#E66063"></option>
            <option value="#FFB600"></option>
            <option value="#FFFF3F"></option>
            <option value="#D5FF00"></option>
            <option value="#52B69A"></option>
          </datalist>
        </div>
      </div>
    </RgFormBase>
  </fieldset>
</template>

<script>
import { isEmpty } from "lodash";
import { RgFormBase } from "~tokio/foundation/container";
import { RgValidatorMixin, RgValidationAlert } from "../../validation";
import RgInputMixin from "../mixin/RgInputMixin";

export default {
  name: "RgInputColor",
  components: {
    RgValidationAlert,
    RgFormBase,
  },
  mixins: [RgValidatorMixin, RgInputMixin],
  props: {
    change: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {
    getId() {
      return !isEmpty(this.id) ? this.id : this.dataId;
    },
    isRequired() {
      if (
        (this.rules && this.rules.required) ||
        (this.rules && this.rules.compositeValue)
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    inputValue(pValue) {
      this.$emit("onInput", pValue);
    },
  },
  mounted() {},
  methods: {
    validateBlur() {
      this.validate();
      this.$emit("blur");
    },
  },
};
</script>
