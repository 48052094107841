import LIST_EXAMS_BY_FILTER from "./ListExamsByFilter";
import EFFECTTIVATE_EXAMS_BY_ID from "./EffectivateExamsById";
import GET_NOT_EFFECTIVATE_REASONS from "./GetNotEffectivateReasons";
import REMOVE_EXAMS_BY_ID from "./RemoveExamsById";
import GET_QUANTITATIVE_EXAM_REPORT from "./GetQuantitativeExamReport";
import SEARCH_BIOSLAB_EXAM_TAG from "./SearchBioslabExamTags";
import SEARCH_BIOSLAB_PATIENT_EXAM from "./SearchBioslabPatientExam";
import SEARCH_BIOSLAB_TAG_INFO from "./SearchBioslabTagInfo";
import SEARCH_BIOSLAB_PATIENT_TAG from "./SearchBioslabTagPatient";
import COUNT_PRINT from "./CountPrint";

export default {
  LIST_EXAMS_BY_FILTER,
  EFFECTTIVATE_EXAMS_BY_ID,
  SEARCH_BIOSLAB_EXAM_TAG,
  GET_NOT_EFFECTIVATE_REASONS,
  REMOVE_EXAMS_BY_ID,
  GET_QUANTITATIVE_EXAM_REPORT,
  SEARCH_BIOSLAB_PATIENT_EXAM,
  SEARCH_BIOSLAB_TAG_INFO,
  SEARCH_BIOSLAB_PATIENT_TAG,
  COUNT_PRINT,
};
