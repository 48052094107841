import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async (userId, procedureName) => {
  return AuthLegacyRequest.post(
    "exames2/controller-exame-procedimento/combo-box-unidades-com-procedimento",
    {
      intIdUsuario: userId,
      strNomeProcedimento: procedureName,
    },
  )
    .then(({ data }) => {
      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
