export default {
  selected_employee_data: null,
  selected_exam_data: null,
  selected_patient_data: null,
  filter_per_day_data: null,
  filter_per_period_data: null,
  filter_patient_exclude_data: null,
  filter_date: null,
  filter_exam_queue_data: null,
  selected_queue_external_exam_data: null,
  exam_schedule: null,
  selected_patient_list_queue_data: null,
  form_schedule: null,
  filter_schedule_patient_list: null,
  filter_externally_schedule: null,
};
