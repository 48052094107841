<script>
import { RgRemoteComboboxMixin } from "~tokio/primitive";
import SearchRaceCor from "./action/SearchRaceCor";

export default {
  name: "RgComboboxRaceCor",
  mixins: [RgRemoteComboboxMixin],
  props: {
    label: {
      type: String,
      default: "Raça/Cor",
    },
    dataId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {
    doSearch() {
      return SearchRaceCor();
    },
    formatItem(item) {
      return {
        id: item.etn_id,
        value: item.etn_codigo_sisaih,
        text: item.etn_nome,
      };
    },
  },
};
</script>
