import moment from "moment";
import isEmpty from "lodash/isEmpty";

export const FORMATS = {
  BRAZILIAN_DATE_FORMAT: "DD/MM/YYYY",
};

export const BrazilianDateToDatabase = (pDateString) => {
  return !isEmpty(pDateString)
    ? moment(pDateString, "DD/MM/YYYY").format("YYYY-MM-DD")
    : "";
};

export const BrazilianDateFormat = (pDate, pDay = "DD") => {
  return moment(pDate || moment()).format(`${pDay}/MM/YYYY`);
};

export const BrazilianDateTimeFormat = (pDateTime, pDay = "DD") => {
  return moment(pDateTime || moment()).format(`${pDay}/MM/YYYY HH:mm:ss`);
};

export const BrazilianDateNow = (pDay = "DD") => {
  return BrazilianDateFormat(null, pDay);
};

export const BrazilianFistDayCurrentMonth = () => {
  return BrazilianDateFormat(null, "01");
};

export const BrazilianDateToInternational = (pDateString) => {
  return pDateString.split("/").reverse().join("-");
};

/**
 *
 * @param {String} pDateString A data
 * @param {String} pFormat O Formato da Data
 * @returns {Boolean} retorna true se a data for valida
 */
export const IsValid = (pDateString, pFormat, pStrictParsing = false) => {
  const date = moment(pDateString, pFormat, pStrictParsing);
  return date.isValid();
};

/**
 *
 * @param {String} pDateString A Data
 * @param {String} pFormat O Formato da data
 * @param {String} pTargetDate A data Alvo
 * @param {String} pTargetFormat O Formato da data alvo
 * @returns {Boolean} retorna true caso a data seja posterior que a data alvo
 */
export const IsAfter = (pDateString, pFormat, pTargetDate, pTargetFormat) => {
  const date = moment(pDateString, pFormat);
  const target = moment(pTargetDate, pTargetFormat);

  return date.isAfter(target);
};

/**
 *
 * @param {String} pDateString A Data
 * @param {String} pFormat O Formato da data
 * @param {String} pTargetDate A data Alvo
 * @param {String} pTargetFormat O Formato da data alvo
 * @returns {Boolean} retorna true caso a data seja anterior que a data alvo
 */
export const IsBefore = (pDateString, pFormat, pTargetDate, pTargetFormat) => {
  const date = moment(pDateString, pFormat);
  const target = moment(pTargetDate, pTargetFormat);

  return date.isBefore(target);
};

/**
 *
 * @param {String} pDateString Data a ser verificada
 * @param {String} pFormat o formato da data
 * @returns {Boolean} retorna true caso a data seja posterior a data de hoje
 */
export const IsAfterToday = (pDateString, pFormat) => {
  const date = moment(pDateString, pFormat);
  const hoje = moment();

  return date.isAfter(hoje);
};

/**
 *
 * @param {String} pDateString Data a ser verificada
 * @param {String} pFormat o formato da data
 * @returns {Boolean} retorna true caso a data seja anterior a data de hoje
 */
export const IsBeforeToday = (pDateString, pFormat) => {
  const date = moment(pDateString, pFormat);
  const hoje = moment();

  return date.isBefore(hoje);
};
