import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async (context, { arrPendencias, strResolution, pIdModulo }) => {
  const sendData = {
    arrPendencias: arrPendencias,
    StrResolucao: strResolution,
    intIdModulo: pIdModulo,
  };

  return AuthLegacyRequest.post(
    "tabelas/controller-fila-pendencia/cadastrar-resolucao",
    sendData,
  )
    .then(({ data }) => {})
    .catch((pErr) => {
      throw pErr;
    });
};
