<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchHealthPlan from "./action/SearchHealthPlan";

export default {
  name: "RgComboboxHealthPlan",
  mixins: [RgComboboxMixin],
  props: {
    label: {
      default: "Convênio",
    },
    inactive: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {};
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];
      const data = await SearchHealthPlan({
        intIdModulo: 15,
        ExibirConveniosInativos: this.inactive,
      });

      data.forEach((item) => {
        this.mData.push({
          value: item.data,
          text: item.label,
        });
      });
    },
    blur() {
      this.$emit("blur");
      this.validate();
    },
  },
};
</script>
