import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  return AuthLegacyRequest.post(
    "/exames2/controller-exame-escala-agendamento/buscar-profissionais-por-periodo",
    variables,
  )
    .then(({ data }) => {
      data = HandleResponse(data);
      data.dados.data = data.dados.recordSet;

      data.dados.data = data.dados.data.map((item) => {
        const validateDisableRow =
          Number(item.funcionario_inabilitado) === 1 ||
          Number(item.hag_atendimento) === 0;

        if (validateDisableRow) {
          item.disabled = true;
        }
        return item;
      });

      const all = data.dados.data;

      const list = all.filter((el) => {
        return (
          !el.disabled &&
          !(parseInt(el.eea_quantidade_restantes) === 0) &&
          !(parseInt(el.vinculo_procedimento_ativo) === 0) &&
          !(parseInt(el.possuiProcedimento) === 0)
        );
      });

      const processDisabled = all.filter((el) => {
        return parseInt(el.vinculo_procedimento_ativo) === 0;
      });

      const withoutAssociatedProcedure = all.filter((el) => {
        return parseInt(el.possuiProcedimento) === 0;
      });

      const listNoVacancy = all.filter((el) => {
        return !el.disabled && parseInt(el.eea_quantidade_restantes) === 0;
      });

      const listDisabled = all.filter((el) => {
        return el.disabled;
      });

      data.dados.data = [
        ...list,
        ...processDisabled,
        ...withoutAssociatedProcedure,
        ...listNoVacancy,
        ...listDisabled,
      ];

      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
