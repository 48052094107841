<style src="./ModalConfirmFieldClearing.scss" lang="scss" scoped></style>
<template lang="html">
  <div class="modal-confirm-field-clearing">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        Deseja realmente limpar os campos preenchidos?
      </div>

      <div slot="body" class="body"></div>

      <div slot="footer" class="footer">
        <RgCleanButton large class="yes" @click="getYes" />
        <MediumButton label="Continuar o cadastro" @click="getOut" />
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { RgBaseModal, RgCleanButton, MediumButton } from "~tokio/primitive";

export default {
  name: "ModalConfirmFieldClearing",
  components: {
    RgBaseModal,
    RgCleanButton,
    MediumButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default:
        "Deseja realmente limpar todos os campos preenchidos nesse cadastro?",
    },
  },
  methods: {
    getYes() {
      this.$emit("getYes", 1);
      this.close();
    },

    getOut() {
      this.$emit("getOut", 2);
    },

    close() {
      this.$emit("close");
    },
  },
};
</script>
