import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchCustomHospitalizationQueueProcedures(
    $inp_novo_nome_procedimento: String
    $inp_novo_codigo_procedimento: String
    $inp_id_planos_saude: Int
    $inp_id: Int
    $inp_sexo: String
    $uns_id: Int
    $limit: Int
  ) {
    customHospitalizationQueueProcedures(
      inp_novo_nome_procedimento: $inp_novo_nome_procedimento
      inp_novo_codigo_procedimento: $inp_novo_codigo_procedimento
      inp_id_planos_saude: $inp_id_planos_saude
      inp_id: $inp_id
      inp_sexo: $inp_sexo
      uns_id: $uns_id
      limit: $limit
    ) {
      count
      rows {
        inp_id
        inp_novo_nome_procedimento
        inp_novo_codigo_procedimento
        inp_sexo
      }
    }
  }
`;

export default async ({ variables }) => {
  try {
    const result = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    return result.data.customHospitalizationQueueProcedures;
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors);
  }
};
