const mutations = {
  SET_LOCATION_TYPES: (state, location_types) => {
    state.location_types = location_types;
  },

  SET_HOME_SITUATIONS: (state, home_situations) => {
    state.home_situations = home_situations;
  },

  SET_ACCESS_TYPES: (state, access_types) => {
    state.access_types = access_types;
  },

  SET_BUILDING_MATERIAL: (state, building_material) => {
    state.building_material = building_material;
  },

  SET_RURAL_PRODUCTION: (state, rural_production) => {
    state.rural_production = rural_production;
  },

  SET_WATER_SUPPLY: (state, water_supply) => {
    state.water_supply = water_supply;
  },

  SET_BATHROOM_DRAIN: (state, bathroom_drain) => {
    state.bathroom_drain = bathroom_drain;
  },

  SET_WATER_TREATMENT: (state, water_treatment) => {
    state.water_treatment = water_treatment;
  },

  SET_TRASH_DESTINATION: (state, trash_destination) => {
    state.trash_destination = trash_destination;
  },

  SET_ANIMALS_TYPES: (state, animals_types) => {
    state.animals_types = animals_types;
  },

  SET_HOME_TYPES: (state, home_types) => {
    state.home_types = home_types;
  },
};

export default mutations;
