<style src="./RgComboboxSpecialtiesSearchBar.scss" lang="scss"></style>

<script>
import RgRemoteComboboxMixin from "~tokio/primitive/selection/mixin/RgRemoteComboboxMixin";
import SearchSpecialties from "./action/SearchSpecialties";

export default {
  name: "RgComboboxSpecialties",
  mixins: [RgRemoteComboboxMixin],
  props: {
    label: {
      default: "Especialidades",
    },
  },
  data() {
    return {};
  },
  methods: {
    doSearch() {
      return SearchSpecialties();
    },
    formatItem(item) {
      return {
        value: item.esp_id,
        text: `${item.esp_nome}`,
      };
    },
  },
};
</script>
