<template lang="html">
  <svg
    id="Capa_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M122,212v-15c0-24.814-20.186-45-45-45H15c-8.291,0-15,6.709-15,15s6.709,15,15,15h62c8.276,0,15,6.724,15,15
			c0,2.73,0,27.477,0,30c0,72.768,57.695,131.667,129.774,134.473l52.463,33.691l-53.004,34.037
			C214.225,424.692,205.937,422,197,422c-24.814,0-45,20.186-45,45c0,24.814,20.186,45,45,45c19.53,0,36.024-12.578,42.237-30
			h125.526c6.213,17.422,22.707,30,42.237,30c24.814,0,45-20.186,45-45c0-24.814-20.186-45-45-45
			c-8.937,0-17.225,2.692-24.234,7.202l-53.004-34.037l52.463-33.691C454.305,358.667,512,299.768,512,227v-15H122z M325.879,362
			L302,377.335L278.121,362H325.879z M362.742,452H241.258L302,412.993L362.742,452z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M347,0c-8.284,0-15,6.716-15,15v145.789L452.562,40.227C423.95,16.376,387.162,0,347,0z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M473.773,61.438L353.211,182H512v-15C512,126.838,497.624,90.05,473.773,61.438z"
        />
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>
<script>
export default {
  name: "Newborn",
};
</script>
