export default {
  SET_PERSON_ID: (state, pesId) => {
    state.patient.pes_id = pesId;
  },
  SET_PERSONAL_DETAIL: (state, data) => {
    state.patient.personalDetail = { ...data };
  },
  SET_ADDRESS: (state, data) => {
    state.patient.address = { ...data };
  },
  SET_CONTACT: (state, data) => {
    state.patient.contact = { ...data };
  },
  SET_PATIENT_INFO: (state, data) => {
    state.patient.patientInfo = { ...data };
  },
  SET_DOCUMENT: (state, data) => {
    state.patient.document = { ...data };
  },
  SET_ATTACHMENTS: (state, data) => {
    state.patient.attachments = Array.from(data);
  },
  REMOVE_ATTACHMENT: (state, data) => {
    state.patient.attachments = state.patient.attachments.filter((item) => {
      return item.filename !== data.filename;
    });
  },
  SET_NEWBORN: (state, newBorn) => {
    state.patient.newBorn = newBorn;
  },
  DESTROY_PERSON_INFO: (state) => {
    state.patient = {
      pes_id: null,
      newBorn: false,
      personalDetail: {
        pes_nome: null,
        pes_foto: null,
        pes_nascimento: "",
        pes_nome_social: null,
        pes_mae: null,
        pes_pai: null,
        sex_sigla: "",
        pes_id_sexos: 0,
        pes_id_etnias: null,
        pes_id_tipos_sanguineos: null,
        pes_id_estados_civis: null,
        pes_id_nacionalidades: null,
        pes_id_municipios: null,
        pes_id_escolaridades: null,
        pes_id_religiao: null,
        pes_profissao: null,
        pes_id_situacao_trabalho: null,
        isNaturalized: false,
        nationality: null,
        naturality: null,
        foreign: {
          date: "",
          dateArrive: "",
          concierge: null,
          passport: null,
        },
      },
      address: {
        est_id: 0,
        mun_id: 0,
        tlg_id: 0,
        bai_nome: "",
        end_cep: "",
        end_numero: "",
        end_logradouro: "",
        end_complemento: "",
        mun_nome: "",
        est_sigla: "",
        tlg_nome: "",
        end_referencia: "",
      },
      attachments: [],
      document: {
        foreign: {
          est_id: null,
          est_portaria: null,
          est_chegada_brasil: null,
          est_naturalizacao: null,
          est_passaporte: null,
        },
        cns: {
          crs_id: null,
          crs_numero: "",
        },
        cpf: {
          cpf_id: null,
          cpf_numero: "",
        },
        pisPasep: {
          pis_id: null,
          pis_numero: "",
        },
        rg: {
          rgs_id: null,
          rgs_id_orgaos_emissores: null,
          rgs_numero: "",
          rgs_expedicao: "",
          issuingBody: {
            org_id: null,
            org_nome: "",
          },
        },
        otherDocuments: {
          weddingCertificate: {
            ctc_cartorio: null,
            ctc_emissao: "",
            ctc_folha: null,
            ctc_id: null,
            ctc_id_tipos_documento: null,
            ctc_livro: null,
            ctc_termo: null,
          },
          birthCertificate: {
            ctc_id: null,
            ctc_id_tipos_documento: null,
            ctc_cartorio: "",
            ctc_livro: "",
            ctc_folha: "",
            ctc_termo: "",
            ctc_emissao: "",
          },
          newBirthCertificate: {
            ctn_id: null,
            ctn_id_tipos_documento: null,
            ctn_numero: "",
          },
          cnh: {
            cnh_id: null,
            cnh_id_tipos_documento: null,
            cnh_numero: null,
          },
          voterTitle: {
            tit_id: null,
            tit_id_municipios: null,
            tit_id_tipos_documento: null,
            tit_numero: null,
            tit_secao: null,
            tit_zona: null,
          },
          ctps: {
            ctt_id: null,
            ctt_numero: "",
            ctt_serie: "",
            ctt_emissao: "",
            ctt_id_estados: null,
          },
        },
      },
      contact: {
        phones: [],
        email: "",
      },
      patientInfo: {
        pleni: {
          lca_id: null,
          uns_id: null,
          set_id: null,
        },
        uns_id: null,
        pac_id: null,
        recordNumber: null,
        ppr_numero: null,
        uns_nome: "",
        area: "",
        microarea: "",
        team: "",
        responsible: "",
        lastMeetDate: "",
      },
    };
  },
  SET_FILTER_PATIENT: (state, data) => {
    state.filter_patient = data;
  },
  RESET_FILTER_PATIENT: (state) => {
    state.filter_patient = null;
  },
  SET_LAST_PERSON_ID_SAVE(state, pesId) {
    state.last_person_save = pesId;
  },
  DESTROY_LAST_PERSON_ID_SAVE(state) {
    state.last_person_save = null;
  },
  SET_FILTER_UNIFY_PATIENT: (state, data) => {
    state.filter_unify_patient = data;
  },
  RESET_FILTER_UNIFY_PATIENT: (state) => {
    state.filter_unify_patient = null;
  },
  SELECT_PATIENTS_UNIFY: (state, data) => {
    state.selected_patients_unify_data = data;
  },
  UNSELECT_PATIENTS_UNIFY: (state) => {
    state.selected_patients_unify_data = null;
  },
};
