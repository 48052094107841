<template lang="html">
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.28185 5.97747C1.36513 6.01079 1.46171 6.01079 1.54498 5.97082L4.14618 4.78845C4.3127 4.70852 4.38264 4.50868 4.30271 4.34216C4.22612 4.18229 4.03627 4.10901 3.87306 4.18229L2.05122 5.01161C2.9205 2.41041 5.34185 0.671845 8.12955 0.671845C11.0505 0.671845 13.605 2.62358 14.3411 5.41793C14.3877 5.59444 14.5709 5.70104 14.7474 5.65439C14.9239 5.60778 15.0305 5.42458 14.9839 5.24807C14.1712 2.16396 11.3535 0.0057373 8.12952 0.0057373C5.0554 0.0057373 2.38092 1.92416 1.41506 4.79513L0.649032 2.88006C0.592422 2.70685 0.402579 2.61028 0.229378 2.66689C0.0561769 2.7235 -0.0403997 2.91334 0.0162099 3.08654C0.0195508 3.09987 0.0262016 3.11318 0.0328524 3.12651L1.09532 5.79097C1.12864 5.87424 1.19524 5.94419 1.28185 5.97747Z"
      fill="white"
    />
    <path
      d="M15.9798 10.8934C15.9764 10.8868 15.9731 10.8801 15.9731 10.8734L14.9106 8.20899C14.8773 8.12571 14.8107 8.05577 14.7241 8.02249C14.6409 7.98917 14.5443 7.99251 14.461 8.02914L11.8598 9.21151C11.69 9.28145 11.61 9.47794 11.68 9.6478C11.7499 9.81766 11.9464 9.8976 12.1163 9.82766C12.1229 9.82431 12.1296 9.821 12.1362 9.81766L13.9581 8.98835C13.0822 11.5895 10.6575 13.3281 7.8731 13.3281C4.95216 13.3281 2.39761 11.3764 1.66156 8.58203C1.61494 8.40552 1.43175 8.29892 1.25524 8.34556C1.07873 8.39218 0.972127 8.57538 1.01878 8.75189C1.82811 11.8393 4.64913 13.9942 7.87313 13.9942C10.9473 13.9942 13.6184 12.0758 14.5876 9.20483L15.3536 11.1199C15.4169 11.2931 15.6068 11.383 15.7799 11.3197C15.9531 11.2565 16.043 11.0666 15.9798 10.8934Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "IconReload",
};
</script>
