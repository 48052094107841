import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation DeleteProfessionalRegistration($id: Int) {
    DeleteProfessionalRegistration(professionalRegistrationId: $id) {
      coc_id
    }
  }
`;

export default async ({ rootState }, payload) => {
  const variables = { id: payload };
  try {
    const { data } = await GraphQLClient.mutate({
      mutation,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.DeleteProfessionalRegistration;
  } catch (err) {
    return Promise.reject(err.graphQLErrors);
  }
};
