export default {
  SET_SMART_TABLE_COLUMNS: (state, pColumns) => {
    const hasRoute = state.selected_columns.findIndex(
      (item) =>
        item.link === pColumns.link &&
        item.name === pColumns.name &&
        item.user_id === pColumns.user_id &&
        item.uns_id === pColumns.uns_id,
    );

    if (hasRoute !== -1) {
      state.selected_columns[hasRoute] = pColumns;
    } else {
      state.selected_columns.push(pColumns);
    }
  },
  RESET_SMART_TABLE_COLUMNS: (state, pColumns) => {
    state.selected_columns = [];
  },
};
