import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query FoodOrigin {
    SearchFoodOrigin {
      rows {
        oal_id
        oal_origem
        oal_codigo_ab
      }
    }
  }
`;

export default async ({ commit, state }) => {
  if (state.foodOrigin.length > 0) {
    return;
  }
  try {
    const { data } = await GraphQLClient.query({
      query,
      fetchPolicy: "no-cache",
    });
    commit("SET_FOOD_ORIGIN", data.SearchFoodOrigin.rows);
  } catch (err) {
    console.log(err);
    return err.graphQLErrors;
  }
};
