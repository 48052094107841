import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchUnbilledExitsReport(
    $unsId: Int
    $name: String
    $medicalRecord: String
    $hospitalizationNumber: String
    $entryInitialDate: String
    $entryFinalDate: String
    $exitInitialDate: String
    $exitFinalDate: String
    $limit: Int
    $offset: Int
    $toPrint: Boolean
    $toExcel: Boolean
    $toCsv: Boolean
  ) {
    SearchUnbilledExitsReport(
      unsId: $unsId
      name: $name
      medicalRecord: $medicalRecord
      hospitalizationNumber: $hospitalizationNumber
      entryInitialDate: $entryInitialDate
      entryFinalDate: $entryFinalDate
      exitInitialDate: $exitInitialDate
      exitFinalDate: $exitFinalDate
      limit: $limit
      offset: $offset
      toPrint: $toPrint
      toExcel: $toExcel
      toCsv: $toCsv
    ) {
      html
      count
      csv
      excel
      fileName
      rows {
        nomePaciente
        prontuario
        int_numero
        dataEntrada
        dataSaida
      }
    }
  }
`;
export default async ({ commit }, pParameters) => {
  try {
    const variables = {
      ...pParameters,
    };
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    const html =
      !data.SearchUnbilledExitsReport || !data.SearchUnbilledExitsReport.html
        ? ""
        : data.SearchUnbilledExitsReport.html.replace('\\"', '"');
    const count = !data.SearchUnbilledExitsReport
      ? 0
      : data.SearchUnbilledExitsReport.count;
    const csv = !data.SearchUnbilledExitsReport
      ? 0
      : data.SearchUnbilledExitsReport.csv;
    const excel = !data.SearchUnbilledExitsReport
      ? 0
      : data.SearchUnbilledExitsReport.excel;
    const fileName = !data.SearchUnbilledExitsReport
      ? 0
      : data.SearchUnbilledExitsReport.fileName;
    return {
      html,
      count,
      csv,
      excel,
      fileName,
      rows: data.SearchUnbilledExitsReport.rows,
    };
  } catch (error) {
    throw new Error("Houve um problema ao obter dados do servidor");
  }
};
