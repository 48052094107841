<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
export default {
  name: "RgComboboxOrderDirection",
  mixins: [RgComboboxMixin],
  data: () => {
    return {
      mData: [
        { value: "DESC", text: "Maior → Menor" },
        { value: "ASC", text: "Menor → Maior" },
      ],
    };
  },
  mounted() {},
};
</script>
