import AuthRequest from "~common/request/AuthRequest";

export default (pProcedure) => {
  return new Promise((resolve, reject) => {
    AuthRequest.post(
      "/billing/bulletin-billing/update-procedure-employee-billing?pdf_id=" +
        pProcedure.pdf_id,
      pProcedure,
    )
      .then((pResult) => {
        return resolve();
      })
      .catch((pError) => {
        return reject(pError.response);
      });
  });
};
