<template>
  <Modulebox
    :title="isEdition ? `Editar Fila` : `Inserir na Fila`"
    class="exam-registration-queue"
  >
    <div class="exam-registration-queue-body">
      <form class="form-container" @submit.stop.prevent="saveForm">
        <RgValidatorForm ref="validator">
          <FormBase title="Paciente" class="module-patient">
            <div class="content-inputs">
              <RgSuggestPatientInformation
                ref="patientInfoSuggest"
                v-model="suggestPatient"
                :rules="{ forceSelection: true, required: true }"
                :disabledOnlySuggest="isEdition"
                :with-patient-module="['exam2']"
                :pac_prontuario_unico="
                  patientInfo && patientInfo.prontuario_unico
                "
                :enabled-patient="false"
                :is-patient="false"
                :extra-data="['withoutAddress']"
                class="patient-input"
                @patientInfo="getPatientData"
              />
            </div>
          </FormBase>

          <FormBase
            title="Dados da Solicitação"
            class="module-solicitation"
            :class="{
              disable: !hasIdPatient,
            }"
          >
            <div class="content-grid">
              <div class="content-grid row-1">
                <RgInputDate
                  v-model="form.entryDate"
                  :disabled="true"
                  :class="{ disable: true }"
                  label="Data de Entrada"
                  placeholder="dd/mm/aaaa"
                />

                <RgInputHour
                  v-model="form.entryHour"
                  :disabled="true"
                  :class="{ disable: true }"
                  label="Hora de Entrada"
                />

                <RgSuggestEmployee
                  ref="employee"
                  v-model="requestingProfessional"
                  label="Profissional Solicitante"
                  :rules="{ required: true }"
                  :disabled="!hasIdPatient || isEdition"
                  :class="{
                    disable: !hasIdPatient || isEdition,
                  }"
                  :maxlength="100"
                  placeholder="Digite o nome do profissional"
                  @selected="selectingProfessional"
                  @clear="form.classCouncil = null"
                />

                <RgInput
                  ref="classCouncil"
                  v-model="form.classCouncil"
                  label="Conselho de Classe"
                  :rules="{ required: !form.requestingProfessionalId }"
                  :disabled="disableClassCouncil || isEdition"
                  :class="{ disable: disableClassCouncil || isEdition }"
                  :maxlength="20"
                  placeholder="Digite o conselho de classe"
                />
              </div>

              <div class="content-grid row-2">
                <RgComboboxUnithealthQueue
                  ref="unitSolicitation"
                  v-model="form.requesterUnit"
                  :rules="{ required: true }"
                  :disabled="!hasIdPatient || !canChangeProcedure"
                  :class="{
                    disable: !hasIdPatient || !canChangeProcedure,
                  }"
                  :module-id="examModuleId"
                  :hasProcedure="true"
                  label="Unidade de Saúde Solicitante"
                />

                <RgComboboxPriority
                  ref="priority"
                  v-model="form.priority"
                  :rules="{ required: true }"
                  :module-id="33"
                  :disabled="!hasIdPatient"
                  :class="{
                    disable: !hasIdPatient,
                  }"
                  label="Prioridade"
                  class="inputitem"
                />

                <RgInputDate
                  ref="date"
                  v-model="form.requestDate"
                  :rules="{ maxTodayDate: true, required: true }"
                  :disabled="isEdition || !hasIdPatient"
                  :class="{ disable: isEdition || !hasIdPatient }"
                  :max-date="new Date()"
                  label="Data do Pedido"
                  placeholder="dd/mm/aaaa"
                />

                <RgInput
                  v-if="isEdition"
                  v-model="dataLoadAlter.nomeRegulador"
                  label="Autorizado por"
                  :disabled="isEdition"
                  :class="{ disable: !hasIdPatient || isEdition }"
                />
              </div>
            </div>

            <div
              class="area"
              :class="{
                disable: !hasIdPatient,
              }"
            >
              <div class="procedure-area">
                <p class="label">Procedimentos na Fila</p>
                <div
                  v-if="queuesPerPerson && queuesPerPerson.length > 0"
                  class="table-area"
                >
                  <div
                    v-for="(queuePerPerson, id) in queuesPerPerson"
                    :key="id"
                    class="item"
                  >
                    {{ queuePerPerson.nome }}
                  </div>
                </div>
                <div v-else class="empty-subprocedures">
                  <IconEmpty class="empty-icon" />
                  <span>Nenhum procedimento encontrado</span>
                </div>
              </div>

              <RgTextArea
                v-if="!isEdition"
                ref="textArea"
                v-model="form.observations"
                :maxlength="500"
                :disabled="!hasIdPatient"
                :class="{
                  disable: !hasIdPatient,
                }"
                label="Observações"
                class="inputbox"
                is-optional
              />
            </div>
          </FormBase>

          <FormBase
            title="Procedimento"
            :class="{
              disable: !hasIdPatient,
            }"
            class="module-procedure"
          >
            <div class="inputs">
              <RgSelectProceduresQueueByUnit
                ref="proceduresQueueByUnit"
                v-model="procedure"
                :unit-health-id="Number(form.requesterUnit)"
                :alter-procedure="alterProcedure"
                :disabled="!hasIdPatient || !canChangeProcedure"
                :permission="canChangeProcedure"
                :class="{ disable: !hasIdPatient }"
                :rules="{ required: true }"
                placeholder="Digite o nome do procedimento"
                class="inputitem"
                @change="selectingProcedure"
              />
              <RgInputQuantity
                ref="quantity"
                v-model="form.amount"
                label="Quantidade"
                :min="1"
                :max="999"
                :disabled="!hasIdPatient"
                :class="{
                  disable: !hasIdPatient,
                }"
                :rules="{ required: true }"
              />
              <RgSuggestCid
                ref="cid10"
                v-model="cid10"
                label="CID"
                :disabled="!hasIdPatient"
                :class="{
                  disable: !hasIdPatient,
                }"
                @selected="selectingCidCode"
              />
            </div>

            <div v-if="hasSubprocedure" class="filter-subprocedure">
              <RgInput
                v-model="subprocedure"
                :disabled="!mutableSubprocedureList || !canChangeSubProcedure"
                label=""
                placeholder="Busca por subprocedimento"
              />
              <RgSearchButton
                ref="subprocedures"
                v-shortkey="['enter']"
                :disabled="!mutableSubprocedureList || !canChangeSubProcedure"
                @submit="searchSubprocedure"
                @shortkey.native="searchSubprocedure"
              />
            </div>

            <div v-if="hasSubprocedure" class="subprocedures">
              <div
                v-if="
                  mutableSubprocedureList && mutableSubprocedureList.length > 0
                "
                class="list-subprocedures"
              >
                <SmartTable
                  ref="smartTable"
                  name="ExamInsertQueue"
                  :columns="COLUMN"
                  :body="mutableSubprocedureList"
                  :total="Number(mutableSubprocedureList.length)"
                  :initial-columns="1"
                  :show-pagination="false"
                  :disabled="!canChangeSubProcedure"
                  index-column="id"
                  remove-btn-columns
                  @getLine="selectLine"
                />
              </div>

              <div v-else class="empty-subprocedures">
                <span class="title">Subprocedimentos</span>
                <div class="empty-subprocedures">
                  <IconEmpty />
                  <span> Nenhum subprocedimento encontrado</span>
                </div>
              </div>

              <div
                class="selected-subprocedures"
                :class="{ disable: !canChangeSubProcedure }"
              >
                <span class="title">Subprocedimentos Selecionados</span>
                <div
                  v-if="selectedSubprocedure.length > 0"
                  class="list-subprocedures"
                >
                  <div
                    v-for="(item, index) in selectedSubprocedure"
                    :key="index"
                    class="subprocedure"
                  >
                    <span class="item">
                      {{
                        item.esu_nome_exames_procedimentos_filho ||
                        item.subProcedimento
                      }}
                    </span>
                    <button
                      class="remove"
                      @click.stop.prevent="removeSubprocedureList(index)"
                    >
                      x
                    </button>
                  </div>
                </div>
                <div v-else class="empty-subprocedures">
                  <IconEmpty />
                  <span> Nenhum subprocedimento selecionado</span>
                </div>
              </div>
            </div>
          </FormBase>
        </RgValidatorForm>
      </form>
    </div>

    <div class="footer">
      <RgCleanButton
        v-show="!isEdition"
        small
        class="clean"
        title="Limpar"
        @click="showConfirmFieldClearing"
      />

      <RgCancelButton
        v-if="showBackButton"
        ref="cancelButton"
        medium
        class="cancel"
        @click="cancel"
      />

      <RgSaveButton
        medium
        class="save"
        :disabled="!hasIdPatient"
        @click="saveForm(false)"
      />

      <MediumButton
        v-if="!isEdition"
        medium
        class="save"
        label="Salvar e inserir arquivo"
        :disabled="!hasIdPatient"
        @click="saveForm(true)"
      />
    </div>

    <ModalConfirmFieldClearing
      ref="confirmClearing"
      :show="modalConfirmFieldClearing"
      @getYes="confirmCleanForm"
      @getOut="closeConfirmFieldClearing"
    />

    <ModalTypesPrintInsertQueue
      :show="modalPrint"
      :filId="fil_id"
      @close="closeModalPrint"
    />

    <ModalPatientImageInRow
      ref="modalPatientImageInRow"
      :show="modalPatientImageInRow"
      :fill-id="fil_id"
      :modId="examModuleId"
      @close="closeModalPatientImageInRow"
    />
  </Modulebox>
</template>
<script>
import {
  RgCleanButton,
  RgSaveButton,
  RgSuggestPatientInformation,
  RgInputDate,
  RgInput,
  RgValidatorForm,
  RgSuggestEmployee,
  RgSuggestCid,
  RgInputHour,
  RgTextArea,
  RgInputQuantity,
  RgCancelButton,
  RgComboboxPriority,
  RgComboboxUnithealthQueue,
  MediumButton,
  RgSearchButton,
} from "~tokio/primitive";

import moment from "moment";
import { FormBase, Modulebox, SmartTable } from "~tokio/foundation";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";
import { ModalPatientImageInRow } from "~tokio/primitive/modal";

import ModalTypesPrintInsertQueue from "$exam/submodules/schedule/component/modal/modal-types-print-insert-queue/ModalTypesPrintInsertQueue";
import ModalConfirmFieldClearing from "~tokio/primitive/modal/modal-confirm-field-clearing/ModalConfirmFieldClearing";
import RgSelectProceduresQueueByUnit from "$exam/submodules/schedule/component/select/rg-select-procedures-queue-by-unit/RgSelectProceduresQueueByUnit";
import { IconEmpty } from "~tokio/primitive/icon/symbols";

import { mapGetters } from "vuex";
export default {
  name: "ExamInsertQueue",
  components: {
    Modulebox,
    FormBase,
    SmartTable,
    RgValidatorForm,
    ModalConfirmFieldClearing,
    RgSuggestPatientInformation,
    RgInputDate,
    RgInput,
    RgComboboxUnithealthQueue,
    RgSuggestEmployee,
    RgComboboxPriority,
    RgInputHour,
    RgSaveButton,
    RgCleanButton,
    RgTextArea,
    RgInputQuantity,
    RgCancelButton,
    RgSuggestCid,
    ModalTypesPrintInsertQueue,
    RgSelectProceduresQueueByUnit,
    IconEmpty,
    ModalPatientImageInRow,
    MediumButton,
    RgSearchButton,
  },
  data: () => {
    return {
      procedure: null,
      subprocedure: null,
      mutableSubprocedureList: [],
      selectedSubprocedure: [],
      dataPatientQueue: null,
      examQueueId: null,
      patientInfo: {},
      suggestPatient: null,
      requestingProfessional: null,
      period: null,
      modalConfirmFieldClearing: false,
      modalPatientImageInRow: false,
      modalPrint: false,
      showBackButton: true,
      dataLoadAlter: null,
      procedureNameCompare: null,
      fil_id: 0,
      cid10: null,
      alterProcedure: null,
      form: {
        patientId: null,
        entryDate: moment().format("DD/MM/YYYY"),
        entryHour: moment().format("HH:mm:ss"),
        originUnit: null,
        requestingProfessionalId: null,
        requesterUnit: null,
        priority: null,
        requestDate: null,
        observations: null,
        classCouncil: null,
        procedureId: null,
        procedureName: null,
        amount: 1,
        cidCode: null,
      },
      queuesPerPerson: [],
      personData: null,
      disableClassCouncil: true,
    };
  },

  computed: {
    ...mapGetters({
      userLogin: "Login/GET_COMPLETE_LOGIN_INFO",
      unitHealthId: "Login/GET_UNIT_HEALTH_ID",
      lastPersonIdSaved: "Person/Patient/GET_LAST_PERSON_ID_SAVE",
    }),

    hasSubprocedure() {
      return this.procedure;
    },

    examModuleId() {
      return this.$store.state.Login.route_module_map.exam;
    },

    hasIdPatient() {
      return Boolean(
        Number.isInteger(this.patientInfo.pes_id) && this.suggestPatient,
      );
    },

    isEdition() {
      return this.personData !== null;
    },

    subModuleId() {
      return 123;
    },

    canChangeSubProcedure() {
      return (
        !!this.$Permissions.global.has(
          "exame2.filaExame.alterarSubProcedimentos",
        ) ||
        this.canChangeProcedure ||
        !this.isEdition
      );
    },

    canChangeProcedure() {
      return (
        !!this.$Permissions.global.has("exame2.filaExame.mudarFila") ||
        !this.isEdition
      );
    },
  },

  watch: {
    procedure(pValue, pPrev) {
      if (!pValue || (pValue && pPrev && pValue !== pPrev)) {
        this.mutableSubprocedureList = [];
        this.selectedSubprocedure = [];
      }
    },

    subprocedure(pValue, pPrev) {
      if (!pValue || (pValue.length > 0 && pValue !== pPrev)) {
        this.loadSubprocedure(this.form.procedureName);
      }
    },

    suggestPatient(pValue) {
      if (!pValue) {
        this.patientInfo = {};
      }
    },
  },

  created() {
    this.COLUMN = [
      { name: "Subprocedimentos", key: "esu_nome_exames_procedimentos_filho" },
    ];
    this.REASONS_BLOCKED_PATIENT = {
      1: "NÃO INFORMADO",
      2: "INABILITADO",
      3: "DESCARTADO",
      4: "ÓBITO",
      5: "INATIVIDADE",
      6: "PRONTUÁRIO DUPLICADO",
      7: "TRIAGEM",
      8: "MOTIVOS ADMINISTRATIVOS",
    };
  },

  async mounted() {
    if (this.lastPersonIdSaved) {
      this.$refs.patientInfoSuggest.fillPatientById(this.lastPersonIdSaved);
    }

    const fromMenu = this.$route.name === "exam.schedule.queue-insert";
    if (fromMenu) {
      this.showBackButton = false;
    }

    const lineSelected = await this.$store.getters[
      "Exam/Schedule/GET_SELECTED_PATIENT_LIST_QUEUE"
    ];

    if (lineSelected) {
      this.dataLoadAlter = lineSelected[0];
    }

    if (this.dataLoadAlter) {
      await this.loadDataForEditing();
    } else {
      this.setInitialData();
    }
  },

  beforeDestroy() {
    const validateIfExistInsert = ValidateIfRouteExistInBreadscrumb(
      "/exam/schedule/queue/insert",
      this.$route.meta.breadcrumb,
    );
    const validateIfExistEdit = ValidateIfRouteExistInBreadscrumb(
      "/exam/schedule/queue/edit-insert",
      this.$route.meta.breadcrumb,
    );
    if (!validateIfExistEdit && !validateIfExistInsert) {
      this.$store.commit("Exam/Schedule/UNSELECT_PATIENT_LIST_QUEUE_DATA");
    }
  },

  methods: {
    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    validateEqualsProcedure() {
      return this.queuesPerPerson.some((item) => {
        return item.nome === this.procedure;
      });
    },

    async validateSave() {
      const validateForm = await this.isFormValid();

      if (!validateForm) {
        this.$toaster.warning("Verifique os campos");
        return false;
      }

      if (
        this.mutableSubprocedureList.length > 0 &&
        (!this.selectedSubprocedure || this.selectedSubprocedure.length < 1)
      ) {
        this.$toaster.warning(
          "É necessário selecionar pelo menos um subprocedimento.",
        );
        return false;
      }

      if (!this.isEdition) {
        if (await this.validateEqualsProcedure()) {
          this.$toaster.warning(
            `${this.patientInfo.pac_nome} já se encontra na fila para ${this.procedure}.`,
          );
          return false;
        }
      }

      return true;
    },

    async saveForm(insertImageInRow) {
      try {
        if (!(await this.validateSave())) {
          return false;
        } else {
          this.$loader.start();
          this.fil_id = 0;

          const variables = this.formatData();

          if (!this.form.requestingProfessionalId) {
            delete variables.fil_id_funcionarios;
            variables.fil_funcionario_externo = this.requestingProfessional;
          }

          if (this.isEdition) {
            variables.fil_ordem_regulada = this.dataLoadAlter.fil_ordem_regulada;
            variables.fle_id = this.dataLoadAlter.fle_id;
            await this.$store.dispatch(
              "Exam/Schedule/EDIT_EXAM_QUEUE",
              variables,
            );
            this.$router.go(-1);
          } else {
            const data = await this.$store.dispatch(
              "Exam/Schedule/ADD_EXAM_QUEUE",
              variables,
            );

            if (data.hasError && data.mensagem.length > 0) {
              this.$toaster.error(
                data.mensagem,
                "Erro ao inserir paciente na fila",
              );
              return false;
            }

            this.fil_id = data.dados;

            this.modalPrint = true;

            if (insertImageInRow) {
              this.modalPatientImageInRow = true;
            }
          }

          this.$toaster.success("Operação realizada com sucesso.");
          this.clearForm();
        }
      } catch (error) {
        this.$toaster.error(error);
      } finally {
        this.$loader.finish();
      }
    },

    getSubProcedurePayload() {
      const format = [];

      this.selectedSubprocedure.forEach((item) => {
        if (item.esu_nome_exames_procedimentos_filho) {
          format.push(item.esu_nome_exames_procedimentos_filho);
        }
      });

      return format;
    },

    formatData() {
      const variables = {
        fil_id_agendado_por: this.userLogin.user.usu_id,
        fil_id_pessoas: this.patientInfo.pes_id,
        fil_id_prioridades_fila: Number(this.form.priority),
        stp_subProcedimentos: this.getSubProcedurePayload(),
        fil_id_unidades_saude: this.form.originUnit,
        fil_data_pedido: this.form.requestDate,
        fil_id_funcionarios: this.form.requestingProfessionalId,
        fil_id_unidades_saude_solicitante: this.form.requesterUnit,
        fio_observacao: this.form.observations,
        fil_conselho_classe: this.form.classCouncil,
        fle_quantidade: this.form.amount,
        fle_id_cid10: this.form.cidCode || 1,
        fle_id_sinonimos_procedimentos: this.procedureId,
        fle_nome_procedimento: this.form.procedureName,
      };
      return variables;
    },

    async loadSubprocedure(pProcedureName) {
      if (!pProcedureName) {
        this.mutableSubprocedureList = [];
        return;
      }
      this.mutableSubprocedureList = await this.$store.dispatch(
        "Exam/Schedule/GET_SUBPROCEDURE_BY_PROCEDURE",
        {
          stp_novo_nome_procedimento: pProcedureName,
        },
      );
    },

    async loadDataForEditing() {
      try {
        this.$loader.start();
        const { fle_id } = this.dataLoadAlter;

        this.dataPatientQueue = await this.$store.dispatch(
          "Exam/Schedule/GET_DATA_PATIENT_EXAM_QUEUE",
          { fle_id },
        );
        this.personData = await this.$store.dispatch(
          "Exam/Schedule/GET_PATIENT_BASIC_INFO",
          { pesId: parseInt(this.dataPatientQueue.fil_id_pessoas) },
        );

        await this.fillPatientInfo(this.personData);
        const patient = this.dataPatientQueue.objPaciente;
        this.$refs.patientInfoSuggest.fillPatientById(patient.pes_id);

        this.form.requesterUnit = Number(
          this.dataPatientQueue.fil_id_unidades_saude_solicitante,
        );

        this.form.originUnit = this.unitHealthId;

        // PROCEDIMENTO
        this.procedure = this.dataLoadAlter.fle_nome_procedimento;
        this.form.amount = this.dataPatientQueue.fle_quantidade;
        this.form.procedureName = this.dataLoadAlter.fle_nome_procedimento;

        this.dataPatientQueue.subProcedimentos.map((item) => {
          item.esu_nome_exames_procedimentos_filho = item.subProcedimento;
        });

        this.selectedSubprocedure = this.dataPatientQueue.subProcedimentos;

        if (
          this.dataLoadAlter.nome.length > 0 &&
          this.dataLoadAlter.subprocedimentos.length > 0
        ) {
          this.alterProcedure = this.dataLoadAlter.nome;
        } else if (
          this.dataLoadAlter.nome.length > 0 &&
          this.dataLoadAlter.subprocedimentos.length <= 0
        ) {
          this.alterProcedure = this.dataLoadAlter.nome;
        } else {
          this.alterProcedure = this.dataLoadAlter.subprocedimentos;
        }

        const cid = {
          cid_codigo: this.dataPatientQueue.objCid10.cid_codigo,
          cid_nome: this.dataPatientQueue.objCid10.cid_nome,
        };

        this.$refs.cid10.forceSelection(cid);

        this.form.entryDate = this.dataPatientQueue.fil_data.split(" ")[0];
        this.form.entryHour = this.dataPatientQueue.fil_data.split(" ")[1];

        if (this.dataPatientQueue?.fil_id_funcionarios) {
          this.form.requestingProfessionalId = this.dataPatientQueue?.fil_id_funcionarios;
        }

        this.form.classCouncil = this.dataPatientQueue.fil_conselho_classe;
        this.disableClassCouncil = !!this.dataPatientQueue.fil_conselho_classe;

        if (
          this.dataPatientQueue.fil_funcionario_externo &&
          !this.dataPatientQueue.objFuncionario.fun_id
        ) {
          this.requestingProfessional = this.dataPatientQueue.fil_funcionario_externo;
        } else {
          const employeeData = this.dataPatientQueue.objFuncionario;
          const vinId = employeeData.objVinculo?.vin_vinculos
            ? employeeData.objVinculo.vin_vinculos[0]
            : null;
          this.$refs.employee.forceSelection({
            pes_nome: employeeData.pes_nome,
            vin_id: vinId ? Number(vinId) : vinId,
            fun_conselho_classe: this.dataPatientQueue.objFuncionario
              .fun_conselho_classe,
          });
          this.requestingProfessional = this.dataPatientQueue.objFuncionario.pes_nome;
        }

        this.form.priority = Number(
          this.dataPatientQueue.fil_id_prioridades_fila,
        );
        this.form.requestDate = this.dataPatientQueue.fil_data_pedido;
      } catch (Err) {
        this.$toaster.error(Err);
      } finally {
        this.$loader.finish();
      }
    },

    async getPatientData(pPatient) {
      try {
        const hasPerson = pPatient && pPatient.pes_id > 0;
        const patientBlocked =
          hasPerson &&
          pPatient.patient &&
          pPatient.patient.pac_id_motivo_inabilitacao;

        if (patientBlocked) {
          const patientIdReasonBlocked =
            pPatient.patient.pac_id_motivo_inabilitacao;

          return this.$toaster.warning(
            "Paciente inativo. Motivo: " +
              `${this.REASONS_BLOCKED_PATIENT[patientIdReasonBlocked]}`,
          );
        }

        if (!pPatient.patient && hasPerson) {
          // call action to create patient and fill source.patient with patient data?
          const result = await this.$store.dispatch(
            "Person/Patient/CREATE_PATIENT_FOR_PERSON",
            {
              pes_id: pPatient.pes_id,
              withPatientExam: true,
            },
          );
          pPatient.patient = result;
        }

        if (pPatient.patient !== null && hasPerson) {
          this.patientInfo.pac_id =
            pPatient && pPatient.patient ? pPatient.patient.pac_id : null;
          this.patientInfo.pcc_id =
            this.patientInfo.pac_id && pPatient.patient.patient_exam
              ? pPatient.patient.patient_exam.pcc_id
              : null;
          this.fillPatientInfo(pPatient);
        } else {
          this.patientInfo = {
            pac_id: "",
            pcc_id: "",
          };
        }

        if (this.patientInfo.pes_id) {
          this.queuesPerPerson = await this.$store.dispatch(
            "Exam/Schedule/GET_EXAM_QUEUES_PER_PERSON",
            { intIdPessoa: this.patientInfo.pes_id },
          );
        }
      } catch (error) {
        console.error(error);
      }
    },

    selectingProcedure(pValue) {
      if (!pValue) {
        this.mutableSubprocedureList = [];
      } else {
        this.procedureId = pValue.procedure ? pValue.id : null;
        this.form.procedureName = pValue.procedure
          ? pValue.procedure
          : pValue.label;

        this.loadSubprocedure(this.form.procedureName);
      }
    },

    selectLine(pValue) {
      if (pValue) {
        const existSubProcedure = (item) => {
          return (
            item.esu_nome_exames_procedimentos_filho ===
            pValue.esu_nome_exames_procedimentos_filho
          );
        };

        if (!this.selectedSubprocedure.find(existSubProcedure)) {
          this.selectedSubprocedure.push(pValue);
          return;
        }
      }
      this.$toaster.warning("Subprocedimento já selecionado");
    },

    removeSubprocedureList(pIndex) {
      this.selectedSubprocedure.splice(pIndex, 1);
      this.$refs.smartTable.cleanSelectRow();
    },

    searchSubprocedure() {
      const newSubprocedures = [];

      this.$refs.subprocedures.actionSubmit();

      this.mutableSubprocedureList.filter((item) => {
        if (
          item.esu_nome_exames_procedimentos_filho.includes(
            this.subprocedure.toUpperCase(),
          )
        ) {
          newSubprocedures.push(item);
        }
      });

      if (newSubprocedures.length > 0) {
        this.mutableSubprocedureList = newSubprocedures;
      } else {
        this.mutableSubprocedureList = [];
      }

      this.$refs.subprocedures.actionDone();
    },

    fillPatientInfo(pData) {
      this.patientInfo = {
        pac_id: pData.patient.pac_id,
        pes_id: pData.pes_id,
        pac_nome: pData.pes_nome,
        crs_numero: pData && pData.cns ? pData.cns.crs_numero : null,
        pes_mae: pData && pData.pes_mae ? pData.pes_mae : null,
        cpf: pData && pData.cpf ? pData.cpf.cpf_numero : null,
        prontuario_unico:
          pData && pData.patient ? pData.patient.pac_prontuario_unico : null,
      };
    },

    setInitialData() {
      this.form.originUnit = this.unitHealthId;
      this.form.requesterUnit = this.unitHealthId;
    },

    selectingProfessional(pProfessional) {
      const source = pProfessional && pProfessional.source;
      if (source) {
        if (
          source.employeeRelationship &&
          source.employeeRelationship.vin_id_funcionarios
        ) {
          this.form.requestingProfessionalId =
            source.employeeRelationship.vin_id_funcionarios;
        }
        this.form.classCouncil = source.fun_conselho_classe;
        this.disableClassCouncil = !!source.fun_conselho_classe;
      } else {
        this.form.requestingProfessionalId = null;
      }
    },

    selectingCidCode(pCidCode) {
      if (pCidCode.source.cid_id) {
        this.form.cidCode =
          pCidCode && pCidCode.source.cid_id ? pCidCode.source.cid_id : null;
      }
    },

    closeModalPatientImageInRow() {
      this.modalPatientImageInRow = false;
    },

    showConfirmFieldClearing() {
      this.modalConfirmFieldClearing = true;
    },

    closeModalPrint() {
      this.modalPrint = false;
      this.fil_id = 0;
    },

    confirmCleanForm() {
      this.clearForm();
      this.modalConfirmFieldClearing = false;
    },

    closeConfirmFieldClearing() {
      this.modalConfirmFieldClearing = false;
    },

    clearForm() {
      if (this.isEdition) {
        this.form.originUnit = null;
        this.form.requesterUnit = null;
        this.form.priority = null;
      } else {
        this.procedure = null;
        this.patientInfo = {};
        this.suggestPatient = null;
        this.requestingProfessional = null;
        this.period = null;
        this.queuesPerPerson = [];
        this.modalConfirmFieldClearing = false;
        this.cid10 = null;
        this.form = {
          patientId: null,
          entryDate: moment().format("DD/MM/YYYY"),
          entryHour: moment().format("HH:mm:ss"),
          originUnit: null,
          requestingProfessionalId: null,
          requesterUnit: null,
          priority: null,
          requestDate: null,
          observations: null,
          classCouncil: null,
          procedureId: null,
          procedureName: null,
          amount: 1,
          cidCode: null,
        };
        this.cleanFieldsValidation();
        this.subprocedure = null;
        this.mutableSubprocedureList = [];
        this.selectedSubprocedure = [];
        this.setInitialData();
        this.$refs.patientInfoSuggest.cleanPatientInfo();
        this.$refs.textArea.resetCharacter();
      }
    },

    cleanFieldsValidation() {
      const fieldsRefs = [
        "patientInfoSuggest",
        "cid10",
        "employee",
        "date",
        "classCouncil",
        "priority",
        "unitSolicitation",
        "proceduresQueueByUnit",
        "quantity",
      ];

      fieldsRefs.forEach((field) => {
        if (this.$refs[field]) {
          this.$refs[field].cleanValidate();
        }
      });
    },

    cancel() {
      this.$router.push({ name: "exam.schedule.queue" });
    },
  },
};
</script>
