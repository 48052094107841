<style src="./ModalNotEffectiveExam.scss" lang="scss" scoped></style>
<template lang="html">
  <aside class="modal-not-effective-exam">
    <RgBaseModal
      ref="notEffectivatedModal"
      v-shortkey="['esc']"
      :show="show"
      class="rg-alert-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header">
        <h2 class="title-header">Confirmar não efetivação</h2>
      </div>

      <div slot="body">
        <RgComboboxNotEffectivatedReason
          v-model="reasonNotEffectivated"
          label="Motivo: "
          class="motive"
          data-id="motivo"
        />
      </div>

      <div slot="footer" class="confirm-actions-footer">
        <MediumButton
          data-id="cancelar-nao-efetivacao"
          class="confirm-actions-item"
          backgroundColor="#F95354"
          label="Cancelar"
          @click="close"
        />
        <MediumButton
          :disabled="!reasonNotEffectivated"
          data-id="confirmar-nao-efetivacao"
          backgroundColor="#FFB447"
          class="confirm-actions-item"
          :label="
            isNotEffectivated
              ? 'Alterar não efetivação'
              : 'Confirmar não efetivação'
          "
          @click="save"
        />
      </div>
    </RgBaseModal>
  </aside>
</template>

<script>
import { RgBaseModal, MediumButton } from "~tokio/primitive";
import RgComboboxNotEffectivatedReason from "./../../combobox/rg-combobox-not-effectivated-reason/RgComboboxNotEffectivatedReason";
import PrepareExamToSave from "$exam/store/actions/PrepareExamToSave";

const NAO_EFETIVADO = 3;

export default {
  name: "ModalNotEffectiveExam",
  components: {
    RgBaseModal,
    MediumButton,
    RgComboboxNotEffectivatedReason,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    examInfo: {
      type: [Object, Array],
      default: () => {},
      required: true,
    },
    exeIdVinculos: {
      type: Number,
      default: null,
    },
    inBatch: {
      type: Boolean,
      default: false,
    },
    dataId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      reasonNotEffectivated: null,
    };
  },
  computed: {
    isNotEffectivated() {
      return this.examInfo
        ? this.examInfo.exe_id_exames_motivos_nao_efetivacao > 0
        : false;
    },
  },
  watch: {
    reasonNotEffectivated(pValue) {
      this.$emit("input", pValue);
    },
    "examInfo.exe_id_exames_motivos_nao_efetivacao"(pValue) {
      this.reasonNotEffectivated = pValue;
    },
    show(pValue) {
      if (this.inBatch) this.reasonNotEffectivated = null;
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async save() {
      this.$nextTick(async () => {
        try {
          const preparedExamInfo = PrepareExamToSave(
            { ...this.examInfo },
            NAO_EFETIVADO,
            this.exeIdVinculos,
            this.reasonNotEffectivated,
            this.inBatch,
          );
          await this.$store.dispatch(
            "Exam/IndividualEffectivation/EFFECTTIVATE_EXAMS_BY_ID",
            preparedExamInfo,
          );
          this.$emit("notEffectivateExam", {
            reasonNotEffectivated: this.reasonNotEffectivated,
          });
          this.$emit("reSearch");
        } catch (error) {
          this.$emit("notEffectivateExam", false);
        }
      });
    },
  },
};
</script>
