<template>
  <FoundationDisabledPeriods is-appointment />
</template>

<script>
import FoundationDisabledPeriods from "~tokio/foundation/pages/periods/foundation-disabled-periods/FoundationDisabledPeriods.vue";

export default {
  name: "AppointmentDisabledPeriods",
  components: {
    FoundationDisabledPeriods,
  },

  computed: {},

  methods: {},
};
</script>
