<style lang="scss" src="./Call.scss" scoped></style>
<template>
  <div class="rg-alert-modal">
    <!-- :withCloseButton="!isCalling" -->
    <RgBaseModal
      removeSubTitle
      class="rg-alert-base-modal"
      :show="show"
      :size="{ minWidth: 720, minHeight: 260, unit: 'px' }"
    >
      <div slot="header" class="confirm-header">
        <div class="--title">
          <span>{{ title }}</span>
          <div>
            <IconTooltip
              message="Finalizar chamada"
              icon-title="Informação"
              msg-left
              icon-close
              :actionMsg="actionClose"
            />
          </div>
        </div>
      </div>

      <div slot="body" class="confirm-content">
        <span>{{ message }}</span>
      </div>

      <div slot="footer" class="confirm-actions-footer">
        <IconButton
          ref="btnMeet"
          :permission="hasMeetPermission"
          title="Atender"
          @click="actionMeet"
        >
          <div slot="icon"><IconMakeCall /></div>
        </IconButton>

        <IconButton
          v-if="!isCalling"
          ref="btnCallAgain"
          blue
          title="Chamar novamente"
          @click="actionCallAgain"
        >
          <div slot="icon" class="icon-check"><IconCallNext /></div>
        </IconButton>
        <IconButton v-else ref="btnMeet" green :class="{ disable: isCalling }">
          <div slot="title">Chamando</div>
          <div slot="icon">
            <AnimationLoop v-bind="propsAnimationLoopSvg"></AnimationLoop>
          </div>
        </IconButton>

        <IconButton
          ref="btnCallNext"
          red
          title="Chamar o próximo"
          :disabled="disabledCallNext || isCalling"
          @click="actionCallNext"
        >
          <div slot="icon" class="icon-check"><IconEndCall /></div>
        </IconButton>
      </div>
    </RgBaseModal>
  </div>
</template>

<script type="text/javascript">
import RgBaseModal from "~tokio/primitive/notification/modal/RgBaseModal.vue";
import IconButton from "~tokio/primitive/button/rg-icon-button/RgIconButton";

import AnimationLoop from "~tokio/foundation/animation/loop/Loop.vue";

import {
  IconMakeCall,
  IconCallNext,
  IconEndCall,
} from "~tokio/primitive/icon/symbols";

import { IconTooltip } from "~tokio/primitive";

export default {
  name: "ModalCall",

  components: {
    RgBaseModal,
    IconButton,
    IconEndCall,
    IconMakeCall,
    IconCallNext,
    AnimationLoop,
    IconTooltip,
  },

  props: {
    title: {
      type: String,
      default: "Chamando",
    },
    message: {
      type: String,
      default: "",
    },
    show: {
      type: Boolean,
      default: undefined,
      required: true,
    },
    sectorId: {
      required: true,
    },

    isCalling: Boolean,
    disabledCallNext: Boolean,
  },

  computed: {
    propsAnimationLoopSvg() {
      const typeFrame = "icon";
      const frames = [
        "symbols/CallAgainThree",
        "symbols/CallAgainOne",
        "symbols/CallAgainTwo",
      ];

      return { frames, typeFrame };
    },
    propsAnimationLoopText() {
      const typeFrame = "text";
      const frames = ["Chamando.", "Chamando..", "Chamando..."];

      return { frames, typeFrame };
    },
    hasMeetPermission() {
      return this.$Permissions.sector.has(
        "emergencia.buscaPreCadastro.darEntrada",
        this.sectorId || 0,
      );
    },
  },

  methods: {
    actionClose() {
      this.$emit("close");
    },

    actionMeet() {
      this.$emit("meet");
      this.$refs.btnMeet.actionDone();
    },

    actionCallAgain() {
      this.$emit("call-again");
      this.$refs.btnCallAgain.actionDone();
    },

    actionCallNext() {
      this.$emit("call-next");
      this.$refs.btnCallNext.actionDone();
    },
  },
};
</script>
