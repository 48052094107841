import AuthRequest from "~common/request/AuthRequest";
export default (pBlob) => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };

  const formData = new FormData();
  formData.append("file", pBlob);
  return AuthRequest.post(
    "tabelaunificada/import-from-file",
    formData,
    headers,
  );
};
