<template lang="html">
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 58 58"
    style="enable-background: new 0 0 58 58"
    xml:space="preserve"
  >
    <g>
      <path
        d="M22,28h8v-4h4v-8h-4v-4h-8v4h-4v8h4V28z M20,22v-4h4v-4h4v4h4v4h-4v4h-4v-4H20z"
      />
      <path
        d="M21,36c-0.552,0-1,0.447-1,1s0.448,1,1,1h10c0.552,0,1-0.447,1-1s-0.448-1-1-1H21z"
      />
      <path
        d="M35,40H17c-0.552,0-1,0.447-1,1s0.448,1,1,1h18c0.552,0,1-0.447,1-1S35.552,40,35,40z"
      />
      <path
        d="M35,44H17c-0.552,0-1,0.447-1,1s0.448,1,1,1h18c0.552,0,1-0.447,1-1S35.552,44,35,44z"
      />
      <path
        d="M48,0h-2H4v58h44v-6h6V42v-2v-8v-2v-8v-2v-8v-2V0H48z M46,56H6V2h40v8v2v8v2v8v2v8v2v10V56z M52,50h-4v-8h4V50z M52,40h-4
    v-8h4V40z M52,30h-4v-8h4V30z M52,20h-4v-8h4V20z M48,10V2h4v8H48z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Records",
};
</script>
