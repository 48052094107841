<style src="./ModalConfirmRemoveExam.scss" lang="scss" scoped></style>
<template lang="html">
  <aside class="modal-confirm-remove-exam">
    <RgBaseModal
      ref="confirmRemove"
      v-shortkey="['esc']"
      :show="show"
      withCloseButton
      class="rg-alert-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header">
        <h2 class="title">Confirmar exclusão de exame</h2>
      </div>

      <div slot="body">
        <h3 class="sub-title" />
        <RgTextArea
          v-model="reasonRemoveExam"
          data-id="caixa-motivo"
          :rules="{ required: true }"
          :maxlength="500"
          class="input-textarea"
          label="Motivo"
          placeholder="Digite aqui o motivo da exclusão"
        />
      </div>

      <div slot="footer" class="confirm-actions-footer">
        <RgCancelButton class="cancel" medium @click="close" />

        <RgConfirmButton
          ref="confirmButton"
          v-shortkey="['enter']"
          :disabled="!hasReason"
          class="confirm-actions-item"
          large
          @shortkey.native="remove"
          @click="remove"
        />
      </div>
    </RgBaseModal>
  </aside>
</template>

<script>
import { isEmpty } from "lodash";
import {
  RgBaseModal,
  RgTextArea,
  RgCancelButton,
  RgConfirmButton,
} from "~tokio/primitive";

export default {
  name: "ModalConfirmRemoveExam",
  components: {
    RgBaseModal,
    RgTextArea,
    RgCancelButton,
    RgConfirmButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    examId: {
      type: Number,
      default: null,
    },
    itemIndex: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      reasonRemoveExam: null,
    };
  },
  computed: {
    hasReason() {
      return !isEmpty(this.reasonRemoveExam);
    },
  },
  watch: {
    reasonRemoveExam(pValue) {
      this.$emit("input", pValue);
    },
    show(pValue) {
      this.reasonRemoveExam = null;
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    remove() {
      try {
        this.$refs.confirmButton.actionSubmit();
        this.$emit("exam-removed", this.reasonRemoveExam);
        this.$store.dispatch(
          "Exam/IndividualEffectivation/REMOVE_EXAMS_BY_ID",
          {
            examId: this.examId,
            itemIndex: this.itemIndex,
            reason: this.reasonRemoveExam,
          },
        );
        this.$refs.confirmButton.actionDone();
      } catch (pErr) {
        this.$toaster.error(`Erro ao tentar remover. ${pErr}`);
        this.$refs.confirmButton.fail();
      }
    },
  },
};
</script>
