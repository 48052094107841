<template lang="html">
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0755 24.6115H15.65V28.186C15.65 28.669 16.0365 29.0555 16.5195 29.0555H20.9635C21.4465 29.0555 21.833 28.669 21.833 28.186V24.6115H25.4075C25.8905 24.6115 26.277 24.225 26.277 23.742V19.298C26.277 18.815 25.8905 18.4285 25.4075 18.4285H21.833V14.854C21.833 14.371 21.4465 13.9846 20.9635 13.9846H16.5195C16.0365 13.9846 15.65 14.371 15.65 14.854V18.4285H12.0755C11.5925 18.4285 11.2061 18.815 11.2061 19.298V23.742C11.2061 24.225 11.5925 24.6115 12.0755 24.6115ZM12.945 20.1675H16.5195C17.0026 20.1675 17.389 19.7811 17.389 19.298V15.7235H20.094V19.298C20.094 19.7811 20.4805 20.1675 20.9635 20.1675H24.538V22.8725H20.9635C20.4805 22.8725 20.094 23.259 20.094 23.742V27.3165H17.389V23.742C17.389 23.259 17.0026 22.8725 16.5195 22.8725H12.945V20.1675V20.1675Z"
      fill="white"
    />
    <path
      d="M16.5201 31.8571H7.53549C7.05245 31.8571 6.66602 32.2436 6.66602 32.7266V41.7112C6.66602 42.1942 7.05245 42.5806 7.53549 42.5806H16.5201C17.0031 42.5806 17.3895 42.1942 17.3895 41.7112V32.7266C17.3895 32.2436 17.0031 31.8571 16.5201 31.8571ZM15.6506 40.7451H8.50157V33.5961H15.6506V40.7451V40.7451Z"
      fill="white"
    />
    <path
      d="M20.0947 32.7266V41.7112C20.0947 42.1942 20.4812 42.5806 20.9642 42.5806H29.9488C30.4318 42.5806 30.8183 42.1942 30.8183 41.7112V32.7266C30.8183 32.2436 30.4318 31.8571 29.9488 31.8571H20.9642C20.4812 31.8571 20.0947 32.2436 20.0947 32.7266ZM21.8337 33.5961H28.9827V40.7451H21.8337V33.5961Z"
      fill="white"
    />
    <path
      d="M24.6351 57.3617H12.9455V49.7297H24.6351V57.3617ZM3.96094 35.2384V11.1829H25.7944C26.2775 11.1829 26.6639 10.7965 26.6639 10.3135C26.6639 9.83042 26.2775 9.44398 25.7944 9.44398H1.73895V6.73895H35.7451V9.44398H29.5622C29.0791 9.44398 28.6927 9.83042 28.6927 10.3135C28.6927 10.7965 29.0791 11.1829 29.5622 11.1829H33.5231V57.2651H26.3741V49.6331H26.6639C27.4368 49.6331 28.0164 49.0534 28.0164 48.2805C28.0164 47.5077 27.4368 46.928 26.6639 46.928H10.9167C10.1439 46.928 9.56423 47.5077 9.56423 48.2805C9.56423 49.0534 10.1439 49.6331 10.9167 49.6331H11.2066V57.2651H3.96094V39.1027V35.2384ZM35.262 57.3617V29.1521V22.8725V11.1829H36.6146C37.0976 11.1829 37.484 10.7965 37.484 10.3135V5.86947C37.484 5.38643 37.0976 5 36.6146 5H0.869475C0.386433 5 0 5.38643 0 5.86947V10.3135C0 10.7965 0.386433 11.1829 0.869475 11.1829H2.22199V22.8725V29.1521V35.335V39.1994V57.4583L2.3186 58.3278C2.3186 58.8108 2.70503 59.1973 3.18808 59.1973H34.296C34.779 59.1973 35.1654 58.8108 35.1654 58.3278"
      fill="white"
    />
    <path
      d="M55.8165 45.8276H40.1843C39.3245 45.8276 38.6211 46.8207 38.6211 48.0345C38.6211 49.2483 39.3245 50.2414 40.1843 50.2414H55.8165C56.6763 50.2414 57.3797 49.2483 57.3797 48.0345C57.3797 46.8207 56.6763 45.8276 55.8165 45.8276Z"
      fill="white"
    />
    <path
      d="M62.4623 38.1035H40.1592C39.3133 38.1035 38.6211 39.0966 38.6211 40.3104C38.6211 41.5241 39.3133 42.5172 40.1592 42.5172H62.4623C63.3082 42.5172 64.0004 41.5241 64.0004 40.3104C64.0004 39.0966 63.3082 38.1035 62.4623 38.1035Z"
      fill="white"
    />
    <path
      d="M62.4623 30.3793H40.1592C39.3133 30.3793 38.6211 31.3724 38.6211 32.5862C38.6211 33.8 39.3133 34.7931 40.1592 34.7931H62.4623C63.3082 34.7931 64.0004 33.8 64.0004 32.5862C64.0004 31.3724 63.3082 30.3793 62.4623 30.3793Z"
      fill="white"
    />
    <path
      d="M62.4623 22.6552H40.1592C39.3133 22.6552 38.6211 23.6483 38.6211 24.862C38.6211 26.0758 39.3133 27.0689 40.1592 27.0689H62.4623C63.3082 27.0689 64.0004 26.0758 64.0004 24.862C64.0004 23.6483 63.3082 22.6552 62.4623 22.6552Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: "SearchUnitHealth",
};
</script>
