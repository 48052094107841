<template lang="html">
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 88.001 88.001"
    style="enable-background: new 0 0 88.001 88.001"
    xml:space="preserve"
  >
    <g>
      <path
        d="M88.001,33.612H55.938L44,0L32.062,33.612H0L26.159,53.35L16.806,88L44,67.226l27.191,20.775
    l-9.35-34.65L88.001,33.612z M44,57.417L28.937,69.925l6.213-17.814L20.896,40.612l17.605,0.5L44,20.914l5.496,20.198l17.606-0.5
    L52.853,52.11l6.213,17.814L44,57.417z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "GeometryStar",
};
</script>
