<style src="./RgReportMaterialPerSurgery.scss" lang="scss" scoped></style>
<template>
  <div class="report-container row">
    <ModuleBox
      :granted="hasPermission"
      class="report-filter col-4"
      title="Filtro de relatórios"
    >
      <form @submit.prevent="searchReport">
        <div class="row">
          <RgInputDate
            v-model="filter.initialDate"
            :rules="{ required: true }"
            label="Data Inicial"
            class="col-md-6 col-sm-12"
          />
        </div>
        <div class="row">
          <RgInputDate
            v-model="filter.endDate"
            :rules="{ required: true }"
            label="Data Final"
            class="col-md-6 col-sm-12"
          />
        </div>
        <div class="row">
          <RgComboboxUnitHealth
            v-model="filter.unitHealth"
            permission="centro_cirurgico"
            :rules="{ required: true }"
            class="col-12 combobox-row"
            label="Unidade de Saúde"
          />
        </div>
        <div class="row">
          <RgComboboxSector
            v-model="filter.sector"
            :unit-health="filter.uns_id"
            default-text="Todos"
            class="col-12 combobox-row"
            label="Centro Cirúrgico"
          />
        </div>
        <div class="row">
          <RgComboboxPlace
            v-model="filter.place"
            :sector="filter.sector"
            empty-default-text="Selecione um centro cirúrgico"
            default-text="Todos"
            class="col-12 combobox-row"
            label="Sala"
          />
        </div>
        <div class="row">
          <RgTypeaheadSurgeryMaterial
            v-model="filter.material"
            class="col-12"
            label="Material"
            placeholder="Digite o material"
            @select="seletedItem"
          />
        </div>

        <div class="container--print-icon col-12">
          <button type="button" class="btn btn-default" @click="searchReport">
            <span class="button">Gerar Relatório</span>
            <div v-if="searchCalculate" class="rg-spinner rg-spinner__medium" />
          </button>

          <button
            type="button"
            class="btn btn-default"
            @click="printReport('print-report-materials')"
          >
            <span class="button">Imprimir</span>
            <div v-if="printCalculate" class="rg-spinner rg-spinner__medium" />
          </button>
        </div>

        <RgIframe
          v-if="showPrintReport"
          :src="printLink"
          class="iframe-hidden"
        />
      </form>
    </ModuleBox>

    <ModuleBox
      :granted="hasPermission"
      class="report-list-container col-8"
      title="Relatório"
    >
      <ul class="report-list">
        <li v-for="(listItem, idx) in fillData" :key="idx" class="list-item">
          <div class="list-header">
            <div class="header-surgery-info">
              <div class="list-field surgery-info">
                <span class="key">Cirurgia:</span>
                <span class="value">{{ listItem.cci_numero }}</span>
              </div>
              <div class="list-field prontuario-info">
                <span class="key">Prontuário:</span>
                <span class="value">{{ listItem.pac_prontuario_unico }}</span>
              </div>
              <div class="list-field date-info">
                <span class="key">Data:</span>
                <span class="value">{{
                  dateBR(listItem.cci_data_inicio)
                }}</span>
              </div>
            </div>

            <div class="header-patient-info">
              <div class="list-field patient-name">
                <span class="key">Paciente:</span>
                <span class="value">{{ listItem.pes_nome }}</span>
              </div>
              <div class="list-field patient-address">
                <span class="key">Endereço:</span>
                <span class="value"
                  >{{ listItem.end_logradouro }}, {{ listItem.end_numero }} -
                  {{ listItem.bai_nome }} - {{ listItem.mun_nome }}</span
                >
              </div>
            </div>

            <div class="header-place-info">
              <div class="list-field place-place">
                <span class="key">Sala:</span>
                <span class="value">{{ listItem.lca_nome }}</span>
              </div>
              <div class="list-field place-sector">
                <span class="key">Setor:</span>
                <span class="value">{{ listItem.set_nome }}</span>
              </div>
            </div>
          </div>

          <ul class="list-content">
            <li>
              <ul class="list-content-header">
                <li class="column-number">#</li>
                <li class="column-qt">Qt</li>
                <li class="column-material">Material</li>
              </ul>
            </li>
            <li
              v-for="(material, materialIdx) in listItem.materials"
              :key="materialIdx"
              class="{'highlight-material': material.material_id === filter.material}"
            >
              <ul class="list-content-values">
                <li class="column-number">{{ materialIdx + 1 }}</li>
                <li class="column-qt">{{ material.quantity }}</li>
                <li class="column-material">{{ material.material }}</li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <section v-if="fillData === null" class="empty-list">
        <div class="warning--container">
          <IconDocument class="icon-document" />
          <span class="span-warning"
            >Utilize o filtro para efetuar a busca</span
          >
        </div>
      </section>

      <section v-if="fillData && fillData.length < 1" class="empty-list">
        <div class="warning--container">
          <IconEmpty class="icon-empty" />
          <span class="span-warning">Relatório vazio</span>
        </div>
      </section>
    </ModuleBox>
  </div>
</template>
<script>
import ModuleBox from "~tokio/foundation/modulebox/Modulebox";
import {
  toast,
  RgInputDate,
  RgValidatorForm,
  IconEmpty,
  IconDocument,
} from "~tokio/primitive";
import RgIframe from "~tokio/foundation/rg-iframe/RgIframe";
import {
  RgComboboxSector,
  RgComboboxPlace,
} from "$surgery-center/unit-health/component";
import RgTypeaheadSurgeryMaterial from "$surgery-center/surgery/component/combobox/rg-typeahead-surgery-material/RgTypeaheadSurgeryMaterial";
import ReportSearchModel from "$surgery-center/reports/model/ReportSearchModel";
import RgComboboxUnitHealth from "~common/component/tokio/primitive/combobox/rg-combobox-unithealth/RgComboboxUnithealth";

export default {
  name: "RgReportMaterialPerSurgery",
  components: {
    ModuleBox,
    RgInputDate,
    RgIframe,
    RgComboboxSector,
    RgComboboxPlace,
    RgComboboxUnitHealth,
    RgTypeaheadSurgeryMaterial,
    IconEmpty,
    IconDocument,
  },
  extends: { ...RgValidatorForm },
  data() {
    return {
      filter: {
        initialDate: "",
        endDate: "",
        sector: null,
        place: null,
        unitHeath: null,
        material: null,
      },
      showPrintReport: false,
      linkTo: null,
      hashRefresh: "123",
      printCalculate: false,
      searchCalculate: false,
      fillData: null,
    };
  },
  computed: {
    printLink() {
      const formatedFilter = this.formatFilters(this.filter);
      const completeFilter = Object.assign({}, formatedFilter);
      // completeFilter.unitHealth = this.$store.getters['getUnitHealthName']
      const FILTER = JSON.stringify(completeFilter);
      const AUX = `${this.linkTo}?filter=${FILTER}&${this.hashRefresh}`;
      return encodeURI(AUX);
    },
  },
  watch: {
    filter: {
      handler: function () {
        this.showPrintReport = false;
      },
      deep: true,
    },
  },
  mounted() {
    this.filter.initialDate = this.$utils.date.BrazilianDateFormat();
    this.filter.endDate = this.$utils.date.BrazilianDateFormat();
    this.filter.unitHealth = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
  },
  methods: {
    dateBR(pDate) {
      return this.$utils.date.BrazilianDateFormat(Date(pDate));
    },
    seletedItem() {},
    hasPermission() {
      return this.$Permissions.global.has(
        "centro_cirurgico.cirurgia.mapaCirurgico",
      );
    },
    performSearch() {
      return new Promise((resolve, reject) => {
        if (this.validate()) {
          ReportSearchModel.getReportMaterial(this.formatFilters(this.filter))
            .then((pRes) => {
              let listResult = pRes;
              if (typeof pRes === "object")
                listResult = Object.keys(pRes).length > 0 ? pRes : [];
              resolve(listResult);
            })
            .catch((pErr) => {
              reject(new Error("Nenhum Mapa Cirurgico Encontrado"));
              toast.error(
                "Nenhum mapa cirúrgico encontrado para este período",
                "Busca falhou...",
              );
            });
        } else {
          reject(new Error("Campos Inválidos"));
          toast.error(
            "Os campos do filtro precisam estar preenchidos corretamente",
            "Busca falhou...",
          );
        }
      });
    },
    searchReport() {
      this.searchCalculate = true;
      this.performSearch()
        .then((pRes) => {
          this.searchCalculate = false;
          this.fillData = pRes;
        })
        .catch((pErr) => {});
    },
    formatFilters(pFilter) {
      if (pFilter) {
        const newFilters = {
          initialDate: pFilter.initialDate
            ? this.$utils.date.BrazilianDateToDatabase(pFilter.initialDate)
            : "",
          endDate: pFilter.endDate
            ? this.$utils.date.BrazilianDateToDatabase(pFilter.endDate)
            : "",
        };
        newFilters.place = this.filter.place || null;
        return Object.assign({}, this.filter, newFilters);
      }
    },
    printReport(pDoc) {
      this.performSearch()
        .then(() => {
          this.printCalculate = true;
          this.linkTo = pDoc;
          this.hashRefresh = new Date().getTime();
          this.showPrintReport = true;
          setTimeout(() => {
            this.printCalculate = false;
          }, 2000);
        })
        .catch((pError) => {
          this.printCalculate = false;
        });
    },
  },
};
</script>
