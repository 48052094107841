import BaseModel from "~common/model/BaseModel";

class SurgeryRequestingClinicianModel extends BaseModel {
  defineEndPointURI() {
    return "surgery-center/surgery-requesting-clinic";
  }

  getOccupations() {
    return this._request.get(`${this.defineEndPointURI()}-occupations`);
  }

  addOccupation(clinicId, occupationId) {
    return this._request.post(
      `${this.defineEndPointURI()}/${clinicId}/occupations`,
      { ocp_id: occupationId },
    );
  }

  removeOccupation(clinicId, occupationId) {
    return this._request.delete(
      `${this.defineEndPointURI()}/${clinicId}/occupations`,
      { ocp_id: occupationId },
    );
  }
}
export default new SurgeryRequestingClinicianModel();
