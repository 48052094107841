<template lang="html">
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.5312 12.9218V3.39109C10.5312 2.07767 11.6089 1 12.9223 1C14.2358 1 15.3134 2.07767 15.3134 3.39109V12.9218C15.3134 14.2352 14.2358 15.3129 12.9223 15.3129C11.6089 15.3129 10.5312 14.2352 10.5312 12.9218Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M51.0776 15.3129C52.391 15.3129 53.4687 14.2352 53.4687 12.9218V3.39109C53.4687 2.07767 52.391 1 51.0776 1C49.7642 1 48.6865 2.07767 48.6865 3.39109V12.9218C48.6865 14.2352 49.7642 15.3129 51.0776 15.3129Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M63 32.926V15.7842C63 12.8879 60.6763 10.5305 57.8474 10.5305H55.8604V12.9216C55.8604 15.5484 53.7387 17.7038 51.0782 17.7038C48.4514 17.7038 46.296 15.5484 46.296 12.9216V10.5305H17.6703V12.9216C17.6703 15.5484 15.5486 17.7038 12.8881 17.7038C10.2613 17.7038 8.10592 15.5484 8.10592 12.9216V10.5305H6.11896C3.32374 10.5305 1 12.8879 1 15.7842V57.7462C1 60.6424 3.32374 62.9998 6.15263 62.9998H32.9935H34.3743H57.8474C60.7099 62.9998 63 60.6424 63 57.7462V32.926ZM57.8474 58.2176H34.3743H32.9935H6.15263C5.95057 58.2176 5.74851 57.9819 5.74851 57.7462V24.8434H58.2178V34.3741V57.7462C58.2515 58.0156 58.0494 58.2176 57.8474 58.2176Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M39.5255 39.9985C40.4684 40.8741 40.5021 42.2885 39.5592 43.1978C39.5255 43.2315 39.4918 43.2652 39.4244 43.2989L30.1632 52.156C29.1865 53.099 27.57 53.099 26.5597 52.1897C25.5494 51.2804 25.5494 49.7649 26.526 48.8219L34.2045 41.4803L26.4587 34.3744C25.5157 33.4987 25.482 32.0843 26.425 31.175C27.368 30.2994 28.8834 30.2657 29.8264 31.1413L39.5255 39.9985Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: "ExternallySchedule",
};
</script>
