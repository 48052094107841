<style src="./RgSuggestProcedureBioslab.scss" lang="scss" scoped></style>
<template>
  <RgSuggest
    ref="rgSuggest"
    v-model="inputValue"
    :disabled="disabled"
    :data-id="dataId"
    :label="label"
    :placeholder="placeholder"
    :search-on-focus="searchOnFocus"
  >
    <RgSuggestItem
      v-for="(item, idx) in suggestionList"
      :key="idx"
      :data-item="dataItem"
      :idx="idx"
      :item="item"
      class="procedure-item"
    >
      <div class="procedure-row-top">
        <span class="procedure-name">{{ item.lle_procedimentos }}</span>
      </div>
      <div class="procedure-row-info">
        <b>Código Bioslab : </b>{{ item.lle_codigo_bioslab_mne }} -
        <b>Código Sigtap : </b>{{ item.lle_codigo_sigtap }}
      </div>
    </RgSuggestItem>
  </RgSuggest>
</template>
<script>
import {
  RgSuggest,
  RgSuggestItem,
  RgSuggestMixin,
} from "~tokio/primitive/selection";
import { SearchProcedureBioslab } from "./action";

export default {
  name: "RgSuggestProcedureBioslab",
  components: {
    RgSuggest,
    RgSuggestItem,
  },
  mixins: [RgSuggestMixin],
  props: {
    fieldCaption: {
      type: String,
      default: "lle_procedimentos",
    },
    alertEmptyResult: {
      type: Boolean,
      default: false,
    },
    dataItem: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      captionRaw: "lle_procedimentos",
    };
  },

  computed: {
    searchOnFocus() {
      return this.inputValue === "" || this.inputValue === null;
    },
  },

  mounted() {
    this.captionRaw = this.fieldCaption;
  },

  methods: {
    validate() {
      this.$refs.rgSuggest.validate();
    },
    doSearch(pSearchTerm, pLimit = null) {
      const variables = this.defineQuery(pSearchTerm);
      variables.limit = pLimit;

      return this.searchProcedure(variables);
    },
    async searchProcedure(variables) {
      const procedures = await SearchProcedureBioslab({ variables });
      if (this.alertEmptyResult && procedures.length < 1) {
        this.$toaster.warning("Nenhum procedimento encontrado");
      }
      return procedures;
    },

    defineQuery(pSearchTerm) {
      const variables = {
        searchByWord: null,
      };

      variables.searchByWord = pSearchTerm.toString().toUpperCase();

      return variables;
    },
  },
};
</script>
