import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import QuantitativeExamReport from "$exam/submodules/report/view/quantitative-exam-report/QuantitativeExamReport.vue";
import { OpenMenuModule } from "~common/utils/app/menu";

export default {
  path: "/exam/report/quantitative-exam-report",
  name: "exam.report.quantitative-exam-report",
  components: {
    default: QuantitativeExamReport,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Exame", link: OpenMenuModule("exam") },
      {
        label: "Relatório quantitativo de exame",
        link: "/exam/effectivation/individual",
      },
    ],
  },
};
