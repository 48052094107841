import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query {
    surgeryReasons {
      rows {
        cfn_id
        cfn_nome
      }
      count
    }
  }
`;

export default async (pStore) => {
  const { data } = await GraphQLClient.query({ query }).catch((err) => {
    throw err;
  });
  return data.surgeryReasons;
};
