import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async () => {
  return AuthLegacyRequest.post(
    "emergencias/controller-pre-cadastro/combo-box-especializacoes",
  )
    .then(({ data }) => {
      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
