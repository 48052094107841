import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  variables.arrFiltro.mod_id = 2;

  return AuthLegacyRequest.post(
    "/tabelas/controller-agendamento-externo/buscar",
    variables,
  )
    .then(({ data }) => {
      data = HandleResponse(data);

      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
