<style src="./RgPersonSearch.scss" lang="scss" scoped></style>
<template lang="html">
  <div class="rg-person-search">
    <form
      class="rg-person-search--person-form"
      @submit.prevent="personInsertNew"
    >
      <div class="rg-person-search--search-block">
        <IconSearch class="rg-person-search--icon-search" />
        <input
          v-show="isFilterActive('name')"
          v-model="personForm.name"
          v-debounce-directive="defaultDebounce"
          type="search"
          class="rg-person-search--search-input"
          placeholder="Procure por um paciente informando seu nome"
          @debounced="search()"
        />
        <input
          v-show="isFilterActive('mother')"
          v-model="personForm.mother"
          v-debounce-directive="defaultDebounce"
          type="search"
          class="rg-person-search--search-input"
          placeholder="Procure por um paciente informando o nome da mãe"
          @debounced="search()"
        />
        <input
          v-show="isFilterActive('birthday')"
          v-model="personForm.birthday"
          v-mask="'##/##/####'"
          v-debounce-directive="defaultDebounce"
          type="search"
          class="rg-person-search--search-input"
          placeholder="Procure por um paciente informando sua data de nascimento"
          @debounced="search()"
        />
        <input
          v-show="isFilterActive('socialName')"
          v-model="personForm.socialName"
          v-debounce-directive="defaultDebounce"
          type="search"
          class="rg-person-search--search-input"
          placeholder="Procure por um paciente informando seu nome social"
          @debounced="search()"
        />
        <input
          v-show="isFilterActive('cns')"
          v-model="personForm.cns"
          v-mask="'###.####.####.####'"
          v-debounce-directive="defaultDebounce"
          type="search"
          class="rg-person-search--search-input"
          placeholder="Procure por um paciente informando o cartão SUS"
          @debounced="search()"
        />
        <input
          v-show="isFilterActive('rg')"
          v-model="personForm.rg"
          v-debounce-directive="defaultDebounce"
          type="search"
          class="rg-person-search--search-input"
          placeholder="Procure por um paciente informando seu RG"
          @debounced="search()"
        />
        <input
          v-show="isFilterActive('cpf')"
          v-model="personForm.cpf"
          v-mask="'###.###.###-##'"
          v-debounce-directive="defaultDebounce"
          type="search"
          class="rg-person-search--search-input"
          placeholder="Procure por um paciente informando seu CPF"
          @debounced="search()"
        />
        <input
          v-show="isFilterActive('recordNumber')"
          v-model="personForm.recordNumber"
          v-debounce-directive="defaultDebounce"
          type="search"
          class="rg-person-search--search-input"
          placeholder="Procure por um paciente informando seu prontuário único"
          @debounced="search()"
        />
        <input
          v-show="isFilterActive('unitRecordNumber')"
          v-model="personForm.unitRecordNumber"
          v-debounce-directive="defaultDebounce"
          type="search"
          class="rg-person-search--search-input"
          placeholder="Procure por um paciente informando seu prontuário"
          @debounced="search()"
        />
        <!-- <input v-show="isFilterActive('enabledPerson')" type="search" class="rg-person-search--search-input" placeholder="Buscando pessoas desativadas" v-debounce-directive="defaultDebounce" @debounced="search()" v-model="personForm.enabledPerson"/> -->
        <!-- <input v-show="isFilterActive('gender')" type="search" class="rg-person-search--search-input" placeholder="Procure por um paciente informando sexo" v-debounce-directive="defaultDebounce" @debounced="search()" v-model="personForm.gender"/> -->
        <!-- <input v-show="isFilterActive('age')" type="search" class="rg-person-search--search-input" placeholder="Procure por um paciente informando Idade" v-debounce-directive="defaultDebounce" @debounced="search()" v-model="personForm.age"/> -->
        <!-- <input v-show="isFilterActive('state')" type="search" class="rg-person-search--search-input" placeholder="Procure por um paciente informando Estado" v-debounce-directive="defaultDebounce" @debounced="search()" v-model="personForm.state"/> -->
        <!-- <input v-show="isFilterActive('city')" type="search" class="rg-person-search--search-input" placeholder="Procure por um paciente informando Cidade" v-debounce-directive="defaultDebounce" @debounced="search()" v-model="personForm.city"/> -->
        <button
          :disabled="!$Permissions.global.has('cadastro.pessoa.incluir')"
          class="add-button"
        >
          <div
            v-if="!$Permissions.global.has('cadastro.pessoa.incluir')"
            class="permission-locked-button"
          >
            <IconLocked class="icon-lock-button" />
          </div>
          Inserir Paciente
        </button>
      </div>
      <ul class="advanced-search">
        <li>
          <div
            :class="['filter', { active: isFilterActive('name') }]"
            @click.prevent="changeFilter('name')"
          >
            Nome
            <button
              v-if="hasFilter('name')"
              class="clean-filter"
              @click.stop.prevent="removeFilter('name')"
            >
              x
            </button>
          </div>
        </li>
        <li>
          <div
            :class="['filter', { active: isFilterActive('mother') }]"
            @click.prevent="changeFilter('mother')"
          >
            Mãe
            <button
              v-if="hasFilter('mother')"
              class="clean-filter"
              @click.stop.prevent="removeFilter('mother')"
            >
              x
            </button>
          </div>
        </li>
        <li>
          <div
            :class="['filter', { active: isFilterActive('birthday') }]"
            @click.prevent="changeFilter('birthday')"
          >
            Data de Nascimento
            <button
              v-if="hasFilter('birthday')"
              class="clean-filter"
              @click.stop.prevent="removeFilter('birthday')"
            >
              x
            </button>
          </div>
        </li>
        <li>
          <div
            :class="['filter', { active: isFilterActive('socialName') }]"
            @click.prevent="changeFilter('socialName')"
          >
            Nome Social
            <button
              v-if="hasFilter('socialName')"
              class="clean-filter"
              @click.stop.prevent="removeFilter('socialName')"
            >
              x
            </button>
          </div>
        </li>
        <li>
          <div
            :class="['filter', { active: isFilterActive('cns') }]"
            @click.prevent="changeFilter('cns')"
          >
            Cartão SUS
            <button
              v-if="hasFilter('cns')"
              class="clean-filter"
              @click.stop.prevent="removeFilter('cns')"
            >
              x
            </button>
          </div>
        </li>
        <li>
          <div
            :class="['filter', { active: isFilterActive('rg') }]"
            @click.prevent="changeFilter('rg')"
          >
            RG
            <button
              v-if="hasFilter('rg')"
              class="clean-filter"
              @click.stop.prevent="removeFilter('rg')"
            >
              x
            </button>
          </div>
        </li>
        <li>
          <div
            :class="['filter', { active: isFilterActive('cpf') }]"
            @click.prevent="changeFilter('cpf')"
          >
            CPF
            <button
              v-if="hasFilter('cpf')"
              class="clean-filter"
              @click.stop.prevent="removeFilter('cpf')"
            >
              x
            </button>
          </div>
        </li>
        <li>
          <div
            :class="['filter', { active: isFilterActive('recordNumber') }]"
            @click.prevent="changeFilter('recordNumber')"
          >
            Prontuário Unico
            <button
              v-if="hasFilter('recordNumber')"
              class="clean-filter"
              @click.stop.prevent="removeFilter('recordNumber')"
            >
              x
            </button>
          </div>
        </li>
        <li>
          <div
            :class="['filter', { active: isFilterActive('unitRecordNumber') }]"
            @click.prevent="changeFilter('unitRecordNumber')"
          >
            Prontuário
            <button
              v-if="hasFilter('unitRecordNumber')"
              class="clean-filter"
              @click.stop.prevent="removeFilter('unitRecordNumber')"
            >
              x
            </button>
          </div>
        </li>
        <!-- <li>
          <div :class="['filter', { active: isFilterActive('enabledPerson') }]" @click.prevent="changeFilter('enabledPerson')">
            Desativado
            <button v-if="hasFilter('enabledPerson')" @click.stop.prevent="removeFilter('enabledPerson')" class="clean-filter">X</button>
          </div>
        </li> -->
        <!-- <li>
          <div :class="['filter', { active: isFilterActive('gender') }]" @click.prevent="changeFilter('gender')">
            Sexo
            <button v-if="hasFilter('gender')" @click.stop.prevent="removeFilter('gender')" class="clean-filter">X</button>
          </div>
        </li>
        <li>
          <div :class="['filter', { active: isFilterActive('age') }]" @click.prevent="changeFilter('age')">
            Idade
            <button v-if="hasFilter('age')" @click.stop.prevent="removeFilter('age')" class="clean-filter">X</button>
            </div>
        </li>
        <li>
          <div :class="['filter', { active: isFilterActive('state') }]" @click.prevent="changeFilter('state')">
            Estado
            <button v-if="hasFilter('state')" @click.stop.prevent="removeFilter('state')" class="clean-filter">X</button>
          </div>
        </li>
        <li>
          <div :class="['filter', { active: isFilterActive('city') }]" @click.prevent="changeFilter('city')">
            Cidade
            <button v-if="hasFilter('city')" @click.stop.prevent="removeFilter('city')" class="clean-filter">X</button>
          </div>
        </li> -->
      </ul>
    </form>
  </div>
</template>

<script>
import { IconSearch, IconLocked } from "~tokio/primitive/icon";
import { DebounceDirective } from "~tokio/primitive/input/directive/debounce/Debounce";
import { mask } from "vue-the-mask";
export default {
  name: "PersonSearch",
  components: {
    IconSearch,
    IconLocked,
  },
  directives: { debounceDirective: DebounceDirective, mask },
  props: {
    value: {},
  },
  data() {
    return {
      currentFilter: "name",
      personForm: {
        name: "",
        mother: "",
        socialName: "",
        birthday: "",
        cpf: "",
        gender: null,
        rg: "",
        cns: "",
        age: "",
        state: "",
        city: "",
        recordNumber: "",
        unitRecordNumber: "",
        unitHealthId: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        // enabledPerson: false
      },
    };
  },
  computed: {
    defaultDebounce() {
      return 400;
    },
  },
  created() {
    this.personForm.search = this.value;
  },
  mounted() {},
  methods: {
    personInsertNew() {
      this.$emit("person-insert", this.personForm.name);
    },
    search() {
      this.$emit("search", this.personForm);
    },
    changeFilter(filterName) {
      this.currentFilter = filterName;
    },
    hasFilter(pFilter) {
      return this.personForm[pFilter] !== "";
    },
    isFilterActive(pFilter) {
      return pFilter === this.currentFilter;
    },
    removeFilter(pFilter) {
      this.personForm[pFilter] = "";
      this.search();
    },
  },
};
</script>
