<style src="./RgComboboxSurgeryXRay.scss" lang="scss"></style>
<script>
import { RgRemoteComboboxMixin } from "~tokio/primitive";
import SurgeryXRayModel from "$surgery-center/surgery/model/SurgeryXRayModel";
export default {
  name: "RgComboboxSurgeryXRay",
  mixins: [RgRemoteComboboxMixin],
  data: () => {
    return {
      model: SurgeryXRayModel,
    };
  },
  methods: {
    formatItem(pData) {
      return {
        value: pData.rax_id,
        text: pData.rax_nome,
      };
    },
  },
};
</script>
