import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchExamPepIntegration(
    $pes_nome: String
    $type: EnumExamTypes
    $initialDate: String
    $finalDate: String
    $iep_status: Int
  ) {
    SearchExamPepIntegration(
      pes_nome: $pes_nome
      type: $type
      initialDate: $initialDate
      finalDate: $finalDate
      iep_status: $iep_status
    ) {
      rows {
        iep_id
        iep_data_log
        iep_id_exames_efetivados
        iep_id_laboratoriais_pacientes_exames_bioslab
        iep_id_exame_externo_pep
        iep_efetivado
        iep_status
        iep_mensagem_erro
        pes_nome
        tipo
      }
      count
    }
  }
`;

export default async ({ rootState }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    return data?.SearchExamPepIntegration;
  } catch (err) {
    return Promise.reject(err.graphQLErrors);
  }
};
