<style src="./EmployeeDetail.scss" lang="scss" scoped></style>
<template>
  <section class="employee-detail">
    <section v-if="!userExists" class="user-not-exists">
      <IconSad class="icon-sad" />
      <h2>Esse usuário não existe</h2>
    </section>

    <ModalHistoricEmployeeRelationship
      :show="showModalHistoric"
      :relationship-id="employeeRelationshipId"
      @close="closeModalHistoric"
    />

    <section v-if="userExists" class="person-detail--container row">
      <Modulebox
        :granted="hasPermission"
        :disabled="personBasicData.disabled"
        :waiting-mode="personBasicData.waitingMode"
        class="box-module-row col-xs-12 col-8"
        title="Básico"
      >
        <personal-data
          ref="personalData"
          :person-data="personBasicData.form"
          :granted="hasPermission"
          :pes-id="pesId"
          @refresh-route="checkRoute"
          @create="saveNewEmployee"
          @update="updatePerson"
          @remove-employee="removeEmployee"
        />
      </Modulebox>

      <Modulebox
        :disabled="!employeeExists"
        :granted="hasPermission"
        class="box-module-row col-xs-12 col-4"
        title="Funcionário"
      >
        <EmployeeDetails
          :granted="hasPermission"
          :employee-id="employeeId"
          :employee-data="employeeData.form"
          :class-council="classCouncil"
          @save-class-council="saveClassCouncil"
          @edit-class-council="updateClassCouncil"
          @update-class-council="updateClassCouncil"
          @remove-class-council="removeClassCouncil"
        />
      </Modulebox>
    </section>
    <section v-if="userExists" class="person-detail--container row">
      <Modulebox
        :granted="hasPermission"
        :disabled="personAdditionalData.disabled"
        :waiting-mode="personAdditionalData.waitingMode"
        :pes-id="pesId"
        class="box-module-row col-xs-12 col-8"
        title="Informações adicionais"
      >
        <personal-additional-info
          :person-data="personAdditionalData.form"
          :granted="hasPermission"
          :pes-id="pesId"
          @form-state="updateAdditionalInfoState"
        />
      </Modulebox>

      <section class="col-4">
        <div class="column">
          <Modulebox
            :granted="hasPermission"
            :disabled="personalContact.disabled"
            :waiting-mode="personalContact.waitingMode"
            class="row-6"
            title="Contatos"
          >
            <personal-contact
              :pes-id="pesId"
              :person-data="personalContact.form"
            />
          </Modulebox>

          <Modulebox
            :granted="hasPermission"
            :disabled="documents.disabled"
            :waiting-mode="documents.waitingMode"
            :no-padding="true"
            class="row-6 modulebox-documents--nomargin"
            title="Documentos"
          >
            <personal-document :pes-id="pesId" :person-data="documents.form" />
          </Modulebox>
        </div>
      </section>
    </section>

    <section v-if="userExists" class="employee-relationship row row-heigth">
      <Modulebox
        :granted="hasPermission"
        :disabled="documents.disabled"
        :waiting-mode="documents.waitingMode"
        class="box-module-row col-4"
        title="Vínculo Empregatício"
      >
        <EmployeeRelationship
          :relationship-list="relationshipData.relationshipList"
          :employee-id="employeeId"
          @remove-employee-relationship="removeEmployeeRelationship"
          @show-historic="openModalHistoric"
          @relationship-created="relationshipCreated"
          @relationship-updated="relationshipUpdated"
        />
      </Modulebox>
      <Modulebox
        :granted="hasPermission"
        :disabled="!isRelationshipSelected"
        :waiting-mode="documents.waitingMode"
        class="box-module-row col-4"
        title="Vínculo Setor"
      >
        <EmployeeRelationshipSector
          :relationship-list="relationshipData.relationshipList"
          @remove-employee-relationship="removeEmployeeRelationship"
          @relationship-created="relationshipCreated"
          @relationship-updated="relationshipUpdated"
        />
      </Modulebox>
      <Modulebox
        :granted="hasPermission"
        :disabled="!isRelationshipSectorSelected"
        :waiting-mode="documents.waitingMode"
        class="box-module-row col-4"
        title="Habilitar/Inabilitar Funcionário"
      >
        <EnableDisableEmployeePeriod />
      </Modulebox>
    </section>
  </section>
</template>

<script>
import Modulebox from "~tokio/foundation/modulebox/Modulebox";
import { IconSad } from "~tokio/primitive/icon";
// import { PersonalData, PersonalAdditionalInfo, PersonalContact, PersonalDocument } from '$person/common/components'
import {
  EmployeeDetails,
  ModalHistoricEmployeeRelationship,
  EmployeeRelationship,
  EmployeeRelationshipSector,
  EnableDisableEmployeePeriod,
} from "$employee/common/components";
import {
  FlattenData,
  RemoveUnusedFields,
  SanitizeFields,
} from "$person/store/actions/PersonHelper";
import { mapGetters } from "vuex";

export default {
  name: "EmployeeDetail",
  components: {
    Modulebox,
    EmployeeDetails,
    ModalHistoricEmployeeRelationship,
    IconSad,
    EmployeeRelationship,
    EmployeeRelationshipSector,
    EnableDisableEmployeePeriod,
  },
  data() {
    return {
      userExists: true,
      hasPermission: true,
      pesId: null,
      employeeId: null,
      personBasicData: {
        waitingMode: false,
        disabled: false,
        form: {
          pes_id: null,
        },
      },
      personAdditionalData: {
        waitingMode: false,
        disabled: true,
        form: {},
      },
      personalContact: {
        waitingMode: false,
        disabled: true,
        form: {},
      },
      documents: {
        waitingMode: false,
        disabled: true,
        form: {},
      },
      employeeData: {
        waitingMode: false,
        disabled: true,
        form: {},
      },
      DisablingemployeeRelationshipData: {
        waitingMode: false,
        disabled: true,
        form: {},
      },
      classCouncil: [],
      showModalHistoric: false,
      employeeRelationshipId: 0,
      relationshipData: {
        waitingMode: false,
        disabled: true,
        form: {},
        relationshipList: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      isRelationshipSelected: "Employee/IS_RELATIONSHIP_SELECTED",
      isRelationshipSectorSelected: "Employee/IS_RELATIONSHIP_SECTOR_SELECTED",
    }),
    employeeExists() {
      return this.employeeId && this.employeeId > 0;
    },
  },
  watch: {
    $route(to, from) {
      this.checkRoute();
    },
  },
  mounted() {
    this.cleanState();
    this.checkRoute();
  },
  methods: {
    async checkRoute() {
      const employeeId = this.$route.params.employeeId;
      if (employeeId) {
        this.changeModulesStatus({ waitingMode: true, disabled: true });

        const personData = await this.$store.dispatch(
          "Employee/GET_EMPLOYEE_BY_ID",
          { funId: employeeId },
        );
        await this.loadClassCouncilList(employeeId);

        this.pesId = personData.basicInfo.pes_id;
        this.employeeId = employeeId;

        if (!personData) {
          this.userExists = false;
          this.setModulesValues({}, {}, {}, {}, {});
          this.changeModulesStatus({ waitingMode: true, disabled: true });
          return false;
        }

        this.setModulesValues(personData);
        this.changeModulesStatus({ waitingMode: false, disabled: false });

        await this.loadRelationshipList();
      }
    },
    setModulesValues({
      basicInfo,
      additionalInfo,
      phoneInfo,
      meuEsus,
      employeeData,
    }) {
      this.personBasicData.form = basicInfo;
      this.personAdditionalData.form = additionalInfo;
      this.personalContact.form = { phoneInfo, meuEsus };
      this.employeeData.form = employeeData;
    },
    changeModulesStatus({ waitingMode, disabled }) {
      this.personBasicData.waitingMode = waitingMode;
      this.personAdditionalData.waitingMode = waitingMode;
      this.personalContact.waitingMode = waitingMode;
      this.documents.waitingMode = waitingMode;
      this.employeeData.waitingMode = waitingMode;

      this.personBasicData.disabled = disabled;
      this.personAdditionalData.disabled = disabled;
      this.personalContact.disabled = disabled;
      this.documents.disabled = disabled;
      this.employeeData.disabled = disabled;
    },
    async saveNewEmployee(personInfo) {
      this.personBasicData.waitingMode = true;
      this.personBasicData.disabled = true;

      const person = Object.assign({}, personInfo);
      const address = Object.assign({}, personInfo.address);
      delete person.address;

      const flattendedData = FlattenData({ address, person });
      const removedUnusedFields = RemoveUnusedFields(flattendedData);
      const sanitizedFields = SanitizeFields(removedUnusedFields);

      const variables = sanitizedFields;

      try {
        const saveResult = await this.$store.dispatch(
          "Employee/CREATE_EMPLOYEE",
          variables,
        );
        this.$toaster.success("Salvo com sucesso");
        this.jumpEditRouter({ employeeId: saveResult.fun_id });
      } catch (e) {
        this.$toaster.error(e[0].message, "Não foi possível salvar");
      }

      this.$refs.personalData.$refs.formBasic.actionCompleted();
      this.personBasicData.waitingMode = false;
      this.personBasicData.disabled = false;
    },
    async updatePerson(personInfo) {
      this.personBasicData.waitingMode = true;
      this.personBasicData.disabled = true;

      const person = Object.assign({}, personInfo);
      const address = Object.assign({}, personInfo.address);
      delete person.address;

      try {
        await this.$store.dispatch("Patient/UPDATE_PERSON", {
          person,
          address,
        });
        this.$toaster.success("Salvo com sucesso");
      } catch (err) {
        this.$toaster.error(err.message, "Não foi possível salvar");
      }

      this.$refs.personalData.$refs.formBasic.actionCompleted();
      this.personBasicData.waitingMode = false;
      this.personBasicData.disabled = false;
    },
    updateAdditionalInfoState(pFormState) {},
    jumpEditRouter({ employeeId }) {
      this.$router.push({
        name: "employee.editDetail",
        params: { employeeId },
      });
    },
    async saveClassCouncil(councilData) {
      const employeeId = this.employeeId;
      await this.$store.dispatch(
        "Employee/SAVE_PROFESSIONAL_REGISTRATION",
        councilData,
      );
      this.$toaster.success("Salvo com sucesso");
      this.loadClassCouncilList(employeeId);
    },
    async updateClassCouncil(councilData) {
      await this.$store.dispatch(
        "Employee/UPDATE_PROFESSIONAL_REGISTRATION",
        councilData,
      );
      const employeeId = this.employeeId;
      this.loadClassCouncilList(employeeId);
    },
    async removeClassCouncil(councilId) {
      try {
        await this.$store.dispatch(
          "Employee/REMOVE_PROFESSIONAL_REGISTRATION",
          councilId,
        );
        const employeeId = this.employeeId;
        this.loadClassCouncilList(employeeId);
        this.$toaster.success("Conselho de Classe removido com sucesso");
      } catch (pErr) {
        this.$toaster.error(pErr.message, "Não foi possível excluir");
      }
    },
    async loadClassCouncilList(employeeId) {
      const classCouncil = await this.$store.dispatch(
        "Employee/GET_CLASS_COUNCIL",
        { employeeId },
      );
      this.classCouncil = classCouncil;
    },
    async removeEmployee() {
      const employeeId = this.employeeId;
      try {
        await this.$store.dispatch("Employee/REMOVE_EMPLOYEE", { employeeId });
        this.$toaster.success("Colaborador removido com sucesso");
        this.$router.push("/register/employee/search");
      } catch (e) {
        this.$toaster.error(e.message, "Não foi possível excluir");
      }
    },
    openModalHistoric(pId) {
      this.showModalHistoric = true;
      this.employeeRelationshipId = pId;
    },
    closeModalHistoric() {
      this.showModalHistoric = false;
    },
    async removeEmployeeRelationship(pRelationshipId) {
      try {
        await this.$store.dispatch("Employee/REMOVE_EMPLOYEE_RELATIONSHIP", {
          relationshipId: pRelationshipId,
        });
        this.loadRelationshipList();
        this.$toaster.success("Vínculo removido com sucesso");
      } catch (e) {
        this.$toaster.error(e.message, "Não foi possível excluir");
      }
    },
    async loadRelationshipList() {
      const employeeId = this.employeeId;
      if (!employeeId) return;
      const variables = {
        employeeId,
      };
      try {
        const listRelationship = await this.$store.dispatch(
          "Employee/GET_EMPLOYEE_RELATIONSHIP_LIST_BY_EMPLOYEE_ID",
          variables,
        );
        this.relationshipData.relationshipList = listRelationship;
      } catch (e) {
        this.$toaster.error("A listagem dos vínculos não pode ser efetuada");
      }
    },
    relationshipCreated() {
      this.loadRelationshipList();
    },
    relationshipUpdated() {
      this.loadRelationshipList();
    },
    cleanState() {
      this.$store.commit("Employee/RESET_RELATIONSHIP");
      this.$store.commit("Employee/RESET_RELATIONSHIP_SECTOR");
    },
  },
};
</script>
