<template lang="html">
  <svg
    width="16"
    height="19"
    viewBox="0 0 16 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.371841 8.82991C0.867533 9.32182 1.67137 9.32191 2.16715 8.82991L6.73035 4.30137L6.73044 17.7402C6.73044 18.4359 7.29883 19 7.99992 19C8.70101 19 9.26941 18.4359 9.26941 17.7402L9.26932 4.30111L13.8329 8.82975C14.3286 9.32174 15.1324 9.32174 15.6282 8.82975C16.1239 8.33776 16.1239 7.54014 15.6282 7.04814L8.89753 0.369039C8.65946 0.132701 8.33659 1.23739e-06 7.99992 1.20796e-06C7.66325 1.17853e-06 7.3403 0.132785 7.10223 0.369039L0.371759 7.04831C-0.123933 7.5403 -0.123935 8.33792 0.371841 8.82991Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "ArrowSmall",
};
</script>
