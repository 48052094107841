<script>
import RgRemoteTypeaheadMixin from "$billing/common/component/selection/mixin/RgRemoteTypeaheadMixin";
import SearchHospitalizationReasonForClosure from "./action/SearchHospitalizationReasonForClosure";

export default {
  name: "RgTypeaheadHospitalizationReasonForClosure",
  mixins: [RgRemoteTypeaheadMixin],
  props: {
    label: {
      type: String,
      default: "Motivo de Encerramento:",
    },
    min: {
      type: String,
      default: "2",
    },
  },
  data() {
    return {
      filter: () => {
        return {
          searchQuery: this.inputValue,
        };
      },
    };
  },
  methods: {
    doSearch() {
      const searchQuery = this.inputValue;
      if (Number.isInteger(+this.inputValue)) {
        return SearchHospitalizationReasonForClosure({
          ime_codigo: searchQuery,
        });
      } else {
        return SearchHospitalizationReasonForClosure({ ime_nome: searchQuery });
      }
    },
    doFind(pValue) {
      return SearchHospitalizationReasonForClosure(
        { ime_codigo: pValue },
        { findOne: true },
      );
    },
    formatItem(item) {
      return {
        value: item.ime_codigo,
        text: `${item.ime_codigo} - ${item.ime_nome}`,
      };
    },
  },
};
</script>
