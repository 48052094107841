<template lang="html">
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 421.271 421.271"
    style="enable-background: new 0 0 421.271 421.271"
    xml:space="preserve"
  >
    <path
      d="M405.838,258.206c5.522,0,10-4.478,10-10s-4.478-10-10-10H184.293c2.432-11.271-2.186-24.346-16.574-29.994L47.413,160.987
    c-0.001,0-0.001,0-0.001,0c-11.775-4.621-23.196-3.408-31.341,3.324c-7.725,6.384-11.324,16.773-9.394,27.112
    c2.084,11.163,10.319,20.157,22.03,24.058l28.645,9.542v117.81H19.004c-5.522,0-10,4.478-10,10s4.478,10,10,10h386.834
    c5.522,0,10-4.478,10-10s-4.478-10-10-10h-38.351v-84.627H405.838z M35.028,196.507c-6.547-2.181-8.255-6.421-8.69-8.753
    c-0.588-3.147,0.384-6.299,2.475-8.026c1.364-1.128,3.076-1.525,4.812-1.525c2.398,0,4.844,0.76,6.48,1.402l120.306,47.225
    c5.53,2.171,4.684,6.39,4.063,8.103c-1.008,2.778-2.803,3.757-5.328,2.918L35.028,196.507z M347.487,342.833H77.353V231.685
    l75.472,25.14c2.519,0.839,5.032,1.273,7.483,1.332c0.329,0.033,0.662,0.05,1,0.05h186.18V342.833z M43.839,146.121
    c24.176,0,43.844-19.667,43.844-43.841c0-24.175-19.668-43.842-43.844-43.842C19.666,58.438,0,78.105,0,102.28
    C0,126.454,19.666,146.121,43.839,146.121z M43.839,78.438c13.147,0,23.844,10.695,23.844,23.842
    c0,13.146-10.696,23.841-23.844,23.841C30.694,126.121,20,115.426,20,102.28C20,89.134,30.694,78.438,43.839,78.438z
     M421.271,185.771v25.123c0,5.522-4.478,10-10,10H242.692c-1.315,0-2.618-0.26-3.834-0.764L89.992,158.336
    c-0.64-0.248-1.245-0.56-1.811-0.926c-1.266-0.816-2.301-1.892-3.064-3.123c-0.754-1.211-1.254-2.592-1.432-4.063
    c-0.141-1.14-0.086-2.314,0.186-3.475c0.268-1.162,0.737-2.242,1.366-3.205c0.795-1.221,1.83-2.225,3.016-2.976
    c1.244-0.792,2.672-1.314,4.195-1.491c0.663-0.079,1.338-0.089,2.014-0.033l166.935,10.384c5.512,0.343,9.702,5.089,9.359,10.602
    c-0.342,5.512-5.06,9.73-10.602,9.359l-107.468-6.685l91.999,38.188h156.586v-15.123c0-5.522,4.478-10,10-10
    S421.271,180.248,421.271,185.771z"
    />
  </svg>
</template>
<script>
export default {
  name: "HospitalInternment",
};
</script>
