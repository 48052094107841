<style src="./RgComboboxMobility.scss" lang="scss"></style>
<script>
import { RgRemoteComboboxMixin } from "~tokio/primitive";
import SurgeryMobilityModel from "$surgery-center/surgery/model/SurgeryMobilityModel";

export default {
  name: "RgComboboxMobility",
  mixins: [RgRemoteComboboxMixin],
  data: () => {
    return {
      model: SurgeryMobilityModel,
    };
  },
  methods: {
    formatItem(pData) {
      return {
        value: pData.mob_id,
        text: pData.mob_nome,
      };
    },
  },
};
</script>
