import { GraphQLClient, gql } from "@/common/request/GraphQLClient";
import GetPersonById from "./GetPersonById";
import { BrazilianDateToDatabase } from "~utils/date";

const mutation = gql`
  mutation(
    $patient: PersonInputType!
    $address: AddressInputType
    $cns: CnsInputType
    $telephones: TelephoneInputType
  ) {
    CreatePerson(
      person: $patient
      address: $address
      cns: $cns
      telephones: $telephones
    ) {
      pes_nome
      pes_id
    }
  }
`;

export default async ({ commit, rootGetters }, personInfo) => {
  // commit('BASIC_INFO', personInfo)
  const person = Object.assign({}, personInfo);
  const address = Object.assign({}, personInfo.address);
  const cns = Object.assign({}, personInfo.cns);
  const telephones = Object.assign({}, personInfo.telephones);

  delete person.address;
  delete person.cns;
  delete person.telephones;

  address.end_ativo = 1;

  flattenData(address, person, telephones);
  removeUnusedFields(address, person);
  sanitizeFields(address, person);

  if (address.subNeighborhood && address.subNeighborhood.sub_nome) {
    address.sub_nome = address.subNeighborhood.sub_nome;
  } else {
    address.sub_nome = ""; // FIXME: temporário
  }

  // ---Codigo Antigo---
  // delete address.__typename
  // delete address.publicPlaceType

  // delete person.nationality
  // delete person.nome_municipios
  // delete person.pes_id
  // delete person.gender
  // delete person.__typename
  // delete person.patient
  // delete person.pes_etnias
  // delete person.pdf_cns
  // delete person.cns
  // delete person.city
  // delete person.pes_id_municipios
  // delete person.ethnicity
  // delete person.pes_id_etnias
  // delete person.telephones

  //  --------------------

  const variables = { patient: person, address, cns, telephones };

  const { data } = await GraphQLClient.mutate({ mutation, variables }).catch(
    (err) => {
      throw err;
    },
  );
  if (data)
    GetPersonById({ commit, rootGetters }, { pesId: data.CreatePerson.pes_id });
  return data;
};

const flattenData = (address, person) => {
  address.bai_nome = address.neighborhood.bai_nome;
  address.mun_id = address.neighborhood.city.mun_id;
  address.tlg_id = address.publicPlaceType.tlg_id;

  if (address.subNeighborhood && address.subNeighborhood.sub_nome) {
    address.sub_nome = address.subNeighborhood.sub_nome;
  }

  address.zon_id = 4;
  person.pes_id_sexos = person.gender.sex_id;
};

const removeUnusedFields = (address, person) => {
  delete person.patient;

  delete address.neighborhood;
  delete address.subNeighborhood;
  delete address.publicPlaceType;
  delete address.publicPlaceType;
  delete address.__typename;

  delete person.nationality;
  delete person.nome_municipios;
  delete person.pes_id;
  delete person.gender;
  delete person.person;
  delete person.pes_etnias;
  delete person.__typename;
  delete person.pdf_cns;
  delete person.cns;
  delete person.city;
  delete person.pes_id_municipios;
  delete person.ethnicity;
  delete person.pes_id_etnias;
  delete person.telephones;
};

const sanitizeFields = (address, person) => {
  person.pes_nascimento = person.pes_nascimento
    ? BrazilianDateToDatabase(person.pes_nascimento)
    : "0000-00-00";

  address.end_cep = address.end_cep
    ? address.end_cep.replace(/[^\d]/g, "")
    : "00000000";
};
