import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query OccupationSector(
    $ocp_nome: String
    $ocp_codigo: String
    $set_id: Int!
    $activeProfessional: Boolean
    $limit: Int
  ) {
    occupationSector(
      ocp_nome: $ocp_nome
      ocp_codigo: $ocp_codigo
      set_id: $set_id
      activeProfessional: $activeProfessional
      limit: $limit
    ) {
      count
      rows {
        ocp_id
        ocp_codigo
        ocp_nome
        set_id
      }
    }
  }
`;

export default async ({ variables }) => {
  const { data } = await GraphQLClient.query({ query, variables }).catch(
    (err) => {
      console.error(err);
    },
  );
  return data.occupationSector;
};
