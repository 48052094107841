<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import getData from "./action";
export default {
  name: "RgComboboxTransferPlace",
  mixins: [RgComboboxMixin],
  props: {
    set_id: {
      required: true,
    },
  },
  watch: {
    set_id() {
      this.fillData();
    },
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      if (!this.set_id) return Promise.resolve([]);

      this.mData = [];

      const data = await getData({
        set_id: this.set_id,
      });

      data.forEach((item) => {
        this.mData.push({ item: item, value: item.data, text: item.label });
      });
    },
  },
};
</script>
