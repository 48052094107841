const COLORS = {
  red: "#F96B70",
  grey: "#ABABAB",
  green: "#66D48F",
  orange: "#FFA56A",
  blue: "#00AAD4",
};

const GetColorRowSmartTable = (nameColor) => {
  if (COLORS[nameColor]) {
    return COLORS[nameColor];
  }
  console.error("Cor informada não existe! :(");
  return null;
};

export default GetColorRowSmartTable;
