const mutations = {
  SET_OUTCOMES: (state, outcomes) => {
    state.outcomes = outcomes;
  },
  SET_VISIT_REASONS: (state, visit_reasons) => {
    state.visit_reasons = visit_reasons;
  },
  SET_ACTIVE_SEARCH: (state, active_search) => {
    state.active_search = active_search;
  },
  SET_FOLLOWUP: (state, followup) => {
    state.followup = followup;
  },
  SET_ENVIRONMENT_CONTROL: (state, environment_control) => {
    state.environment_control = environment_control;
  },
};

export default mutations;
