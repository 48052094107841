import SEARCH_HOSPITALIZATION_BED_TYPE from "./SearchHospitalizationBedType";
import SEARCH_HOSPITALIZATION from "./SearchHospitalization";
import SEARCH_HOSPITALIZATION_SITUATION_BED from "./SearchHospitalizationSituationBed";
import SEARCH_CUSTOM_HOSPITALIZATION_BED from "./SearchCustomHospitalizationBed";
import SEARCH_CUSTOM_HOSPITALIZATION_DETAILS from "./SearchCustomHospitalizationDetails";
import SEARCH_CUSTOM_HISTORY_HOSPITALIZATION_BED from "./SearchCustomHistoryHospitalizationBed";
import SEARCH_CUSTOM_HISTORY_HOSPITALIZATION_BED_DETAILS from "./SearchCustomHistoryHospitalizationBedDetails";
import FLOW_BUSY_TO_EMPTY_WITH_EXIT from "./FlowBusyToEmptyOrDisinfectionWithExit";
import FLOW_CHANGE from "./FlowChange";
import FLOW_RESERVATION_TO_BUSY from "./FlowReservationToBusy";

export default {
  SEARCH_HOSPITALIZATION,
  SEARCH_HOSPITALIZATION_BED_TYPE,
  SEARCH_HOSPITALIZATION_SITUATION_BED,
  SEARCH_CUSTOM_HOSPITALIZATION_BED,
  SEARCH_CUSTOM_HOSPITALIZATION_DETAILS,
  SEARCH_CUSTOM_HISTORY_HOSPITALIZATION_BED,
  SEARCH_CUSTOM_HISTORY_HOSPITALIZATION_BED_DETAILS,
  FLOW_BUSY_TO_EMPTY_WITH_EXIT,
  FLOW_CHANGE,
  FLOW_RESERVATION_TO_BUSY,
};
