<template>
  <RgSuggest
    ref="suggest"
    v-model="inputValue"
    :label="label"
    :placeholder="placeholder"
    :rules="rules"
    :search-on-focus="searchOnFocus"
    :title="title"
    :disabled="disabled"
  >
    <RgSuggestItem
      v-for="(item, idx) in suggestionList"
      :key="idx"
      :idx="idx"
      :item="item"
      class="rg-suggest-cid _fit-content"
    >
      <div class="items _fit-content">
        <span class="item">{{ formatItem(item) }}</span>
      </div>
    </RgSuggestItem>
  </RgSuggest>
</template>

<script>
import { isEmpty } from "lodash";
import {
  RgSuggest,
  RgSuggestItem,
  RgSuggestMixin,
} from "~tokio/primitive/selection";
import { SearchCid, SearchCidProcedure } from "./action";

export default {
  name: "RgSuggestCid",
  components: {
    RgSuggest,
    RgSuggestItem,
  },
  mixins: [RgSuggestMixin],
  props: {
    label: {
      type: String,
      default: "CID:",
    },
    placeholder: {
      type: String,
      default: "Digite a classificação",
    },
    title: {
      type: String,
      default: "CID",
    },
    patientGender: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    procedureCode: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      captionRaw: "cid_codigo" + " - " + "cid_nome",
      filter: () => {
        return {
          searchQuery: this.inputValue,
        };
      },
    };
  },
  computed: {
    searchOnFocus() {
      return this.inputValue === "" || this.inputValue === null;
    },
  },
  methods: {
    doSearch(searchQuery, pLimit = null) {
      if (this.procedureCode) {
        return SearchCidProcedure({
          cid_nome: searchQuery,
          cid_sexo: this.patientGender,
          co_procedimento: this.procedureCode,
          limit: pLimit,
        });
      } else {
        return SearchCid({
          cid_codigo: searchQuery,
          cid_nome: searchQuery,
          cid_sexo: this.patientGender,
          limit: pLimit,
        });
      }
    },
    formatItem(pItem) {
      if (pItem && pItem.cid_codigo && pItem.cid_nome) {
        return pItem.cid_codigo + " - " + pItem.cid_nome;
      }
    },
    validate() {
      this.$refs.suggest.validate();
    },
    cleanValidate() {
      this.$refs.suggest.cleanValidate();
    },
    forceSelection(pValue) {
      if (pValue && !isEmpty(pValue.cid_nome) && !isEmpty(pValue.cid_codigo)) {
        this.$refs.suggest.itemSelected = true;
        if (
          !this.suggestionList ||
          (this.suggestionList && this.suggestionList.length === 0)
        ) {
          this.suggestionList.push({
            cid_nome: pValue.cid_nome,
            cid_codigo: pValue.cid_codigo,
          });
        }
        this.$refs.suggest.$parent.selectingItemFromSuggestList(pValue);
      }
    },
  },
};
</script>
