<style
  src="./RgContentHospitalizationBillingTeam.scss"
  lang="scss"
  scoped
></style>
<template lang="html">
  <div>
    <RgContentHospitalizationTeam
      :procedure="procedure"
      :required-documents="requiredDocuments"
      @setProfessionalList="setProfissionalList"
    />

    <div class="row">
      <button
        :disabled="!team.professionalTeamList.length > 0"
        type="button"
        class="btn btn-success col-2"
        @click="confirm"
      >
        Confimar
      </button>
      <button class="col-2 btn btn-default" @click.stop.prevent="close">
        Fechar
      </button>
    </div>
  </div>
</template>

<script>
import RgContentHospitalizationTeam from "$billing/hospitalization-billing/common/component/container/content-hospitalization-team/RgContentHospitalizationTeam";
import mask from "vue-the-mask";
import { ValidateTeamMembers } from "../../../../service";

export default {
  name: "RgContentHospitalizationBillingTeam",
  components: {
    RgContentHospitalizationTeam,
  },
  directives: {
    mask,
  },
  props: {
    procedure: {
      default: "",
    },
    requiredDocuments: {},
  },
  data() {
    return {
      team: {
        type: "team",
        professionalTeamList: [],
      },
    };
  },
  mounted() {},
  methods: {
    async confirm(event) {
      if (await this.validateForm()) {
        this.$emit("onFinish", this.team);
      }
    },

    async validateForm() {
      // if (!this.validateTeamMembers()) return false
      return true;
    },

    setProfissionalList(event) {
      this.team.professionalTeamList = event;
    },

    validateTeamMembers() {
      return ValidateTeamMembers(this.tincture.professionalTeamList);
    },

    close() {
      this.$emit("close");
    },
  },
};
</script>
