<template>
  <section class="voter-registration-document">
    <FormBase title="Título de Eleitor" class="form-base-voter-registration">
      <div class="grid">
        <div class="row-1">
          <RgInput
            ref="number"
            v-model="form.tit_numero"
            data-id="número-título"
            label="Número"
            title="Número"
            :rules="{
              required: required,
              fn: validateTitleNumber,
              fn: disableOrEnabledRequired,
            }"
            :maxlength="13"
          />

          <RgInput
            ref="zone"
            v-model="form.tit_zona"
            data-id="zona-título"
            label="Zona"
            title="Zona"
            :rules="{ required: required }"
            :change="disableOrEnabledRequired"
            :maxlength="3"
          />

          <RgInput
            ref="section"
            v-model="form.tit_secao"
            data-id="seção-título"
            label="Seção"
            title="Seção"
            :rules="{ required: required }"
            :change="disableOrEnabledRequired"
            :maxlength="4"
          />
        </div>

        <div class="row-2">
          <RgSuggestState
            ref="state"
            v-model="form.est_sigla"
            data-id="estado-título"
            title="Estado"
            label="Estado"
            :rules="{ required: required, fn: disableOrEnabledRequired }"
            :change="cleanCity"
            @selected="selectingState"
          />

          <RgSuggestCity
            ref="city"
            v-model="form.mun_nome"
            :state="form.est_id"
            :filterByState="true"
            data-id="cidade-título"
            title="Município"
            label="Município"
            placeholder="Informe o município"
            :rules="{ required: required, fn: disableOrEnabledRequired }"
            @selected="selectingCity"
          />
        </div>
      </div>

      <!-- <div class="attachment">
        <rg-attachment-button
          :class="{ 'disable': disabledAttachment }"
          :disbaled="disabledAttachment"
          class="button"
          @click="clicked"
        />
      </div> -->
    </FormBase>
  </section>
</template>

<script>
import { isEmpty } from "lodash";
import { RgInput } from "~tokio/primitive";
import RgSuggestState from "$person/common/components/suggest/rg-suggest-state/RgSuggestState";
import RgSuggestCity from "$person/common/components/suggest/rg-suggest-city/RgSuggestCity";
import FormBase from "~tokio/foundation/form-base/FormBase";

export default {
  name: "VoterRegistrationDocument",
  components: {
    FormBase,
    RgInput,
    RgSuggestState,
    RgSuggestCity,
  },
  props: {
    voterTitleData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        tit_id: 0,
        tit_id_municipios: 0,
        tit_numero: "",
        tit_zona: "",
        tit_secao: "",
        mun_nome: "",
        est_id: 0,
        est_sigla: "",
      },
      required: false,
    };
  },
  computed: {
    disabledAttachment() {
      const number = this.form.tit_numero && this.form.tit_numero.length > 0;
      const zone = this.form.tit_zona && this.form.tit_zona.length > 0;
      const section = this.form.tit_secao && this.form.tit_secao.length > 0;
      const state = this.form.est_id && this.form.est_id !== 0;
      const city =
        this.form.tit_id_municipios && this.form.tit_id_municipios !== 0;

      return !(number && zone && section && state && city);
    },
  },
  watch: {
    voterTitleData(pValue) {
      if (!pValue) {
        this.cleanForm();
      }

      if (!isEmpty(this.voterTitleData)) {
        this.mountVoterTitle(pValue);
      }
    },
    "form.est_sigla"(pValue) {
      this.disableOrEnabledRequired();
    },
  },
  methods: {
    validateTitleNumber(pValue, pErrors) {
      if (pValue) {
        const isValidTitle = pValue && pValue.length < 12;
        if (isValidTitle) {
          pErrors.push("Título de Eleitor inválido.");
          return false;
        }
      }
      return true;
    },
    mountVoterTitle(pData) {
      if (pData) {
        this.form.tit_id = pData.tit_id || 0;
        this.form.tit_id_municipios = pData.tit_id_municipios;
        this.form.tit_numero = pData.tit_numero;
        this.form.tit_zona = pData.tit_zona;
        this.form.tit_secao = pData.tit_secao;

        if (pData.city && pData.city.state) {
          this.$refs.state.forceSelection({
            est_id: pData.city.state.est_id,
            est_sigla: pData.city.state.est_sigla,
          });
        }
        if (pData.city) {
          this.$refs.city.forceSelection({
            mun_id: pData.tit_id_municipios,
            mun_nome: pData.city.mun_nome,
          });
        }
      }
    },

    selectingCity(pValue) {
      const source = pValue && pValue.source;
      this.form.tit_id_municipios = source && source.mun_id;
      this.form.mun_nome = source && source.mun_nome;
    },

    selectingState(pValue) {
      const source = pValue && pValue.source;
      this.form.est_id = source && source.est_id ? source.est_id : 0;
      this.form.tit_id_municipios = 0;
      this.form.mun_nome = "";
    },

    getVoterRegistration() {
      const data = this.$utils.obj.DeepCopy(this.form);
      return data;
    },

    cleanForm() {
      this.form = {
        tit_id: 0,
        tit_id_municipios: 0,
        tit_numero: "",
        tit_zona: "",
        tit_secao: "",
        mun_nome: "",
        est_id: 0,
        est_sigla: "",
      };
      this.cleanValidate();
    },

    clicked() {
      this.$emit("click");
    },

    disableOrEnabledRequired() {
      this.required =
        !!this.form.tit_numero ||
        !!this.form.tit_zona ||
        !!this.form.tit_secao ||
        !!this.form.est_sigla ||
        !!this.form.mun_nome;

      if (!this.required) {
        this.cleanValidate();
      }
    },

    cleanCity() {
      if (!this.form.est_sigla) {
        this.form.est_id = 0;
        this.$refs.city.clearData();
      }
    },

    cleanValidate() {
      this.$refs.number.cleanValidate();
      this.$refs.zone.cleanValidate();
      this.$refs.section.cleanValidate();
      this.$refs.state.cleanValidate();
      this.$refs.city.cleanValidate();
      this.required = false;
    },
  },
};
</script>
