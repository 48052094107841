<style src="./RgSuspend.scss" lang="scss" scoped></style>
<template lang="html">
  <div class="rg-suspend">
    <button
      v-if="selected"
      id="teste"
      type="button"
      class="btn btn-danger"
      @click="doAction()"
    >
      {{ label }}
    </button>
    <div v-if="!granted && selected" class="permission-locked--action-button">
      <IconLocked class="icon-lock--action-button" />
    </div>
  </div>
</template>

<script>
import { IconLocked } from "~tokio/primitive";
export default {
  name: "RgSuspend",
  components: {
    IconLocked,
  },
  props: {
    selected: {
      default: "",
    },
    label: {
      default: "",
    },
    granted: {
      default: true,
    },
  },
  methods: {
    doAction() {
      this.$emit("suspend-action");
    },
  },
};
</script>
