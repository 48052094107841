<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import Search from "./action/Search";
export default {
  name: "RgComboboxTypeScale",
  mixins: [RgComboboxMixin],
  props: {
    uns_id: Number,
    usu_id: Number,
  },

  data: () => {
    return {};
  },

  watch: {
    uns_id(val) {
      if (val) {
        this.fillData();
      }
    },
    usu_id(val) {
      if (val) {
        this.fillData();
      }
    },
  },

  mounted() {
    this.fillData();
  },

  methods: {
    async fillData() {
      if (!this.uns_id || !this.usu_id) {
        return;
      }

      const data = await Search({
        uns_id: this.uns_id,
        usu_id: this.usu_id,
      });

      this.mData = [];
      data.forEach((item) => {
        this.mData.push({ value: item.data, text: item.ees_nome });
      });

      this.$emit("loader", this.mData);
    },

    getList() {
      return this.mData;
    },
  },
};
</script>
