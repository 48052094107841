<template lang="html">
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 450 450"
    style="enable-background: new 0 0 450 450"
    xml:space="preserve"
  >
    <g>
      <path
        d="M369.5,0H107C88.252,0,73,15.252,73,34v382c0,18.748,15.252,34,34,34h262.5c4.142,0,7.5-3.358,7.5-7.5V7.5
      C377,3.358,373.642,0,369.5,0z M107,15h255v367H107c-7.032,0-13.572,2.146-19,5.817V34C88,23.523,96.523,15,107,15z M107,435
      c-10.477,0-19-8.523-19-19s8.523-19,19-19h255v12h-79.5c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5H362v11H107z"
      />
      <path
        d="M218.933,273.063c1.411,1.942,3.667,3.091,6.067,3.091s4.656-1.149,6.067-3.091l51.665-71.09
      c9.983-13.736,14.747-30.623,13.416-47.551s-8.676-32.863-20.683-44.87h0C261.985,96.073,244.063,88.649,225,88.649
      c-19.063,0-36.985,7.424-50.464,20.903c-12.007,12.007-19.352,27.942-20.683,44.87s3.434,33.815,13.416,47.551L218.933,273.063z
      M185.142,120.159c10.646-10.646,24.801-16.51,39.858-16.51c15.056,0,29.211,5.863,39.858,16.51
      c19.728,19.728,22.141,50.427,5.74,72.996L225,255.896l-45.598-62.742C163,170.585,165.414,139.887,185.142,120.159z"
      />
      <path
        d="M225,195.082c19.335,0,35.065-15.73,35.065-35.065c0-19.335-15.73-35.066-35.065-35.066
      c-19.335,0-35.066,15.73-35.066,35.066C189.934,179.352,205.665,195.082,225,195.082z M225,139.951
      c11.064,0,20.065,9.001,20.065,20.066c0,11.064-9.001,20.065-20.065,20.065c-11.064,0-20.066-9.001-20.066-20.065
      C204.934,148.952,213.936,139.951,225,139.951z"
      />
      <path
        d="M267.323,413.629c-2.533-6.003-11.318-6.057-13.85,0c-1.281,3.063-0.31,6.703,2.283,8.759
      c2.408,1.91,5.845,2.116,8.478,0.551C267.397,421.06,268.727,417.029,267.323,413.629
      C267.133,413.179,267.513,414.089,267.323,413.629z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: "Address",
};
</script>
