<style
  src="./RgContentHospitalizationBillingMaterial.scss"
  lang="scss"
  scoped
></style>
<template lang="html">
  <div>
    <form class="form">
      <RgValidatorForm ref="validator">
        <RgTitle title="Materiais" />
        <div class="row">
          <RgInputCnpj
            v-model="material.ima_cnpj_fornecedor"
            :rules="{ required: true }"
            class="col-4"
            label="CNPJ do Fornecedor:"
          />
          <RgInputCnpj
            v-model="material.ima_cnpj_fabricante"
            class="col-4"
            label="CNPJ do Fabricante:"
          />
          <RgInputMasked
            v-model="material.ima_nota_fiscal"
            :rules="{ required: true }"
            class="col-4"
            label="Nota Fiscal:"
            mask="####################"
          />
          <RgInputMasked
            v-model="material.ima_lote"
            class="col-4"
            label="Lote"
            mask="####################"
          />
          <RgInputMasked
            v-model="material.ima_registro_anvisa"
            class="col-4"
            label="Registro do Produto na ANVISA:"
            mask="####################"
          />
          <RgInputMasked
            v-model="material.ima_serie"
            class="col-4"
            label="Nº Série:"
            mask="####################"
          />
        </div>

        <div class="row">
          <button type="button" class="btn btn-success col-2" @click="confirm">
            Confimar
          </button>
          <button class="col-2 btn btn-default" @click.stop.prevent="close">
            Fechar
          </button>
        </div>
      </RgValidatorForm>
    </form>
  </div>
</template>

<script>
import {
  RgInputMasked,
  RgTitle,
  RgInputCnpj,
  RgValidatorForm,
} from "~tokio/primitive";

export default {
  name: "RgContentHospitalizationBillingMaterial",
  components: {
    RgInputMasked,
    RgTitle,
    RgInputCnpj,
    RgValidatorForm,
  },
  props: {
    procedure: {
      default: "",
    },
    requiredDocuments: {},
  },
  data() {
    return {
      professionalCaptionRaw: "pes_nome",
      material: {
        type: "material",
        ima_cnpj_fornecedor: "",
        ima_cnpj_fabricante: "",
        ima_registro_anvisa: "",
        ima_nota_fiscal: "",
        ima_lote: "",
        ima_serie: "",
      },
    };
  },
  mounted() {
    if (this.procedure.additionalData[0]) {
      this.material = this.procedure.additionalData[0];
    }
  },
  methods: {
    async confirm(event) {
      if (await this.validateForm()) {
        this.sanitizeFields();
        this.$emit("onFinish", this.material);
      }
    },

    close() {
      this.$emit("close");
    },

    async validateForm() {
      const valid = await this.$refs.validator.validate();
      return valid;
    },

    sanitizeFields() {
      this.material.ima_cnpj_fornecedor = this.material.ima_cnpj_fornecedor
        ? this.material.ima_cnpj_fornecedor.replace(/[^\d]+/g, "")
        : null;
      this.material.ima_cnpj_fabricante = this.material.ima_cnpj_fabricante
        ? this.material.ima_cnpj_fabricante.replace(/[^\d]+/g, "")
        : null;
    },
  },
};
</script>
