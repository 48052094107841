<template lang="html">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 34 34"
    style="enable-background: new 0 0 34 34"
    xml:space="preserve"
  >
    <g>
      <path
        d="M26.833,13.333V9c0-4.962-4.038-9-9-9h-1.666c-4.962,0-9,4.038-9,9v4.333H4.333V34h25.334V13.333H26.833z M11.167,9   c0-2.757,2.243-5,5-5h1.666c2.757,0,5,2.243,5,5v4.333H11.167V9z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: "Locked",
};
</script>
