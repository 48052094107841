<template>
  <div class="appointment-scale-search">
    <RgSearch
      ref="RgSearch"
      v-model="mutableScales"
      :search-function="searchFilter"
      :clear-function="cleanForm"
      :build-filter="generateFilter"
      :item-height="34.5"
      :maxRegister="40"
      resultTitle="Lista das Escalas"
      backendLegacy
      @afterPerformSearch="afterSearchFilter"
      @count="getCountValue"
    >
      <div slot="filters" class="appointment-scale-search-filter">
        <div class="grid">
          <div class="selectinput">
            <RgComboboxUnithealthLegacy
              v-model="form.unitHealth"
              :rules="{ required: true }"
              :subModuleId="submoduleId"
              class="inputitem"
            />
          </div>

          <div class="selectinput">
            <RgComboboxSectorBondAndPermission
              v-model="form.sector"
              :unit-health-id="form.unitHealth"
              :submodule-id="submoduleId"
              :disabled="!hasUnit"
              default-text="Todos"
              class="inputitem"
            />
          </div>

          <div class="selectinput">
            <RgSuggestCboSector
              ref="Cbo"
              v-model="cbo"
              :disabled="!hasSector"
              :class="{ disable: !hasSector }"
              :sectorId="Number(form.sector)"
              label="Ocupação"
              placeholder="Digite o nome da ocupação"
              class="inputitem"
              activeProfessional
              @selected="selectedCbo"
            />
          </div>
          <div class="selectinput">
            <RgSelectEmployeeBySectorOccupationLegacy
              v-model="form.pes_id"
              :sector-id="Number(form.sector)"
              :occupation-id="Number(form.ocp_id)"
              :disabled="!hasCbo"
              :class="{ disable: !hasCbo }"
              placeholder="Digite o nome do profissional"
              class="inputitem"
              @change="selectedEmployee"
            />
          </div>

          <div class="filter-title">
            <span class="title">Período de Escala</span>
          </div>

          <div class="selectinput">
            <RgComboboxWeekDays
              v-model="form.weekDay"
              class="inputitem"
              default-text="Todos"
            />
          </div>

          <div class="selectinput">
            <RgComboboxAppointmentTypeScale
              v-model="form.scaleType"
              label="Tipo de Escala"
              default-text="Todas"
              class="inputitem"
            />
          </div>

          <div class="selectinput _flex">
            <RgInputHour
              v-model="form.hourInitial"
              label="Horário de Início"
              placeholder="00:00"
              class="inputitem"
            />

            <RgInputHour
              v-model="form.hourFinal"
              label="Horário de Término"
              placeholder="00:00"
              class="inputitem mg"
            />
          </div>

          <div class="filter-title">
            <span class="title">Validade</span>
          </div>

          <div class="selectinput _flex">
            <RgInputDate
              v-model="form.dateInitial"
              :rules="{ fn: verifyExpirationInitialDate }"
              label="Data Inicial"
              placeholder="dd/mm/aaaa"
              class="inputitem"
            />

            <RgInputDate
              v-model="form.dateFinal"
              label="Data Final"
              placeholder="dd/mm/aaaa"
              class="inputitem mg"
            />
          </div>

          <div class="selectinput -mg-b">
            <RgRadioCustom
              v-bind="propsRadioCustom"
              @input="onInputRadioCustom"
            />
          </div>
        </div>
      </div>

      <div v-if="!hasListScale" slot="menu-top" class="top-button">
        <RgNewButton large title="Nova Escala" @click="registerScale" />
      </div>

      <div class="appointment-scale-search-list">
        <SmartTable
          ref="smartTable"
          name="AppointmentScaleSearch"
          :columns="COLUMN_TABLE"
          :body="mutableScales"
          :dynamic-height="getSpaceTable"
          :total="Number(total)"
          :initial-columns="7"
          :show-pagination="false"
          :colorLine="actionColorLine"
          index-column="eag_id"
          circle-indicator
          toggle-selected
          class="smart-table"
          @getLine="selectLine"
        >
          <div slot="legends" class="legends">
            <div class="circle -green" />
            <span class="legend">Na Validade</span>
            <div class="circle -red" />
            <span class="legend">Fora da Validade</span>
            <div class="circle -blue" />
            <span class="legend">Em Breve</span>
          </div>

          <div slot="top-buttons" class="top-buttons">
            <RgNewButton
              class="btns"
              title="Novo"
              :permission="
                hasPermissionCreateScale ||
                hasPermissionCreateScaleTechnicalReserve
              "
              @click="registerScale"
            />

            <RgEditButton
              :disabled="!hasSelectedLine"
              :permission="hasPermissionEditScale"
              title="Editar"
              @click="updateScale"
            />

            <RgLessButton
              :disabled="!hasSelectedLine"
              :permission="hasPermissionDeleteScale"
              title="Remover"
              @click="openModalDeleteScale"
            />

            <RgPrinterButton
              :disabled="!hasSelectedLine"
              title="Imprimir escala de atendimento do profissional"
              @click="printScaleAttendProfessional"
            />

            <RgHistoryButton
              small
              :disabled="!hasSelectedLine"
              :class="{ disabled: !hasSelectedLine }"
              title="Histórico de Escalas"
              @click="openModalHistoryScales"
            />
          </div>
        </SmartTable>
      </div>

      <div
        v-if="total > 0"
        ref="footerSlot"
        slot="menu-bottom"
        class="footer-table"
      >
        <RgInputDate
          v-model="divisionDate"
          label="Data inicial da divisão: "
          placeholder="dd/mm/aaaa"
          inLine
          :disabled="!hasSelectedLine"
        />
        <SmallButton
          backgroundColor="#26789f"
          class="button"
          title="Dividir"
          :disabled="!hasSelectedLine"
          :permission="hasPermissionDivisionScale"
          @click="openModalDivisionScale"
        >
          <div slot="icon" class="icon"><IconSplit /></div>
        </SmallButton>
      </div>
    </RgSearch>

    <ModalHistoryProfessionalScales
      :show="modalHistoryScales"
      :sector-link-id="sectorLinkId"
      :professional-info="professionalInfo"
      @close="closeModalHistoryScales"
    />

    <ModalConfirmDeletion
      v-bind="propsModalConfirmDeletion"
      @reSearch="reSearchTable"
      @close="closeModalDeleteScales"
    >
      <div slot="icon" class="icon">
        <IconError />
      </div>
    </ModalConfirmDeletion>

    <ModalConfirmDefault
      v-bind="propsModalConfirmDefault"
      @getYes="divisionScale"
      @getOut="closeModalDivisionScales"
      @close="closeModalDivisionScales"
    >
      <div slot="body-content" class="content-modal-confirm">
        <div>
          <span>
            Escala 1: de {{ initialDateFirstScale }} a {{ finalDateFirstScale }}
          </span>
        </div>
        <div>
          <span>
            Escala 2: de {{ initialDateSecondScale }} a
            {{ finalDateSecondScale }}
          </span>
        </div>
      </div>
    </ModalConfirmDefault>
  </div>
</template>

<script>
import moment from "moment";
import GetColorRowSmartTable from "~common/utils/GetColorRowSmartTable";
import EmployeeAttendanceScaleHTML from "~tokio/primitive/html/EmployeeAttendanceScaleHTML";
import { RgSearch, SmartTable } from "~tokio/foundation";
import {
  RgComboboxAppointmentTypeScale,
  ModalHistoryProfessionalScales,
} from "$appointment/submodules/register/component";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";
import { IconError, IconSplit } from "~tokio/primitive/icon/symbols";
import { mapGetters } from "vuex";

import {
  RgComboboxUnithealthLegacy,
  RgComboboxSectorBondAndPermission,
  RgSuggestCboSector,
  RgComboboxWeekDays,
  RgInputDate,
  RgInputHour,
  RgRadioCustom,
  SmallButton,
  RgLessButton,
  ModalConfirmDeletion,
  ModalConfirmDefault,
  RgHistoryButton,
  RgPrinterButton,
  RgNewButton,
  RgEditButton,
  AlertError,
  RgSelectEmployeeBySectorOccupationLegacy,
} from "~tokio/primitive";

const FORM = {
  unitHealth: null,
  sector: 0,
  ocp_id: 0,
  pes_id: 0,
  tipoEscala: 0,
  scaleType: 0,
  weekDay: null,
  ocp_nome: "",
  ocp_codigo: "",
  hourInitial: "",
  hourFinal: "",
  dateInitial: "",
  dateFinal: "",
  vus_id: null,
  pes_nome: null,
};

export default {
  name: "AppointmentScaleSearch",
  components: {
    RgSearch,
    SmartTable,
    RgComboboxUnithealthLegacy,
    RgComboboxSectorBondAndPermission,
    RgSuggestCboSector,
    RgComboboxWeekDays,
    RgInputDate,
    RgInputHour,
    RgRadioCustom,
    SmallButton,
    RgComboboxAppointmentTypeScale,
    ModalHistoryProfessionalScales,
    RgLessButton,
    ModalConfirmDeletion,
    ModalConfirmDefault,
    RgHistoryButton,
    RgPrinterButton,
    RgNewButton,
    RgEditButton,
    IconSplit,
    IconError,
    RgSelectEmployeeBySectorOccupationLegacy,
  },

  data() {
    return {
      mutableScales: [],
      tableHeight: null,
      total: 0,
      form: this.$utils.obj.DeepCopy(FORM),
      selectedLine: null,
      divisionDate: null,
      cbo: null,
      modalHistoryScales: false,
      modalDeleteScales: false,
      modalDivisionScales: false,
      sectorLinkId: 0,
      professionalInfo: {},
    };
  },

  computed: {
    ...mapGetters({
      unitHealthId: "Login/GET_UNIT_HEALTH_ID",
    }),

    submoduleId() {
      return 6;
    },

    hasSelectedLine() {
      return this.selectedLine && Object.keys(this.selectedLine).length > 0;
    },

    getSpaceTable() {
      return this.tableHeight;
    },

    hasUnit() {
      return this.unitHealth && this.unitHealth > 0;
    },

    unitHealth() {
      return this.form.unitHealth;
    },

    hasSector() {
      return this.sector && this.sector > 0;
    },

    hasCbo() {
      return this.form.ocp_id && this.form.ocp_id > 0;
    },

    sector() {
      return this.form.sector;
    },

    ocuppation() {
      return this.form.ocp_id;
    },

    propsModalConfirmDeletion() {
      const show = this.modalDeleteScales;
      const confirm = () => this.deleteScaleList();

      const noReason = true;
      const title = "Excluir a escala selecionada";
      const message = "A operação não poderá ser desfeita";
      const msgSuccess = "Escala excluída com sucesso!";
      const btnRemoveTitle = "Excluir Escala";

      return {
        show,
        confirm,
        noReason,
        title,
        message,
        msgSuccess,
        btnRemoveTitle,
      };
    },

    propsModalConfirmDefault() {
      const show = this.modalDivisionScales;
      const title = "Divisão da escala selecionada";
      const subtitle = "O período selecionado será dividido em duas escalas";
      const yesLabel = "Confirmar";
      const noLabel = "Cancelar";
      const buttonsRight = true;

      return {
        show,
        title,
        subtitle,
        yesLabel,
        noLabel,
        buttonsRight,
      };
    },

    propsRadioCustom() {
      const list = [
        { title: "Na validade", green: true, id: 1 },
        { title: "Fora da validade", red: true, id: 2 },
      ];

      const value = this.form.tipoEscala;

      const uniqueKey = "id";

      return { list, uniqueKey, value };
    },

    actionColorLine() {
      return {
        get: (item) => {
          const { eag_data_inicio_escala, eag_data_fim_escala } = item;
          const now = moment(new Date()).format("YYYY-MM-DD");

          const initial = moment(eag_data_inicio_escala, "DD/MM/YYYY").format(
            "YYYY-MM-DD",
          );

          const final = moment(eag_data_fim_escala, "DD/MM/YYYY").format(
            "YYYY-MM-DD",
          );

          if (
            eag_data_inicio_escala !== "N/A" &&
            moment(now).isBefore(initial)
          ) {
            // escalas q data inicio > atual (EM BREVE)
            return GetColorRowSmartTable("blue");
          } else if (
            eag_data_fim_escala !== "N/A" &&
            moment(now).isAfter(final)
          ) {
            // escalas q data final < atual (VENCIDAS)
            return GetColorRowSmartTable("red");
          }
          // não entrando nestas regras significa que está na validade
          return GetColorRowSmartTable("green");
        },
      };
    },

    initialDateFirstScale() {
      return this.selectedLine?.eag_data_inicio_escala;
    },

    finalDateFirstScale() {
      if (!this.selectedLine) return;

      const { eag_data_inicio_escala } = this.selectedLine;

      return eag_data_inicio_escala === this.divisionDate
        ? eag_data_inicio_escala
        : this.divisionDate;
    },

    initialDateSecondScale() {
      return moment(this.finalDateFirstScale, "DD/MM/YYYY")
        .add(1, "days")
        .format("DD/MM/YYYY");
    },

    finalDateSecondScale() {
      return this.selectedLine?.eag_data_fim_escala;
    },

    hasPermissionViewNormal() {
      return (
        !!this.$Permissions.global.has(
          "consulta.escalaAtendimento.exibir",
          this.unitHealthId,
        ) ||
        !!this.$Permissions.global.has(
          "consulta.escalaAtendimento.visualizarNormal",
          this.unitHealthId,
        )
      );
    },

    hasPermissionViewTechnicalReserve() {
      return (
        !!this.$Permissions.global.has(
          "consulta.escalaAtendimento.exibirEscalaReservaTecnica",
          this.unitHealthId,
        ) ||
        !!this.$Permissions.global.has(
          "consulta.escalaAtendimento.visualizarReservaTecnica",
          this.unitHealthId,
        )
      );
    },

    hasPermissionCreateScale() {
      return !!this.$Permissions.global.has(
        "consulta.escalaAtendimento.incluir",
        this.unitHealthId,
      );
    },

    hasPermissionCreateScaleTechnicalReserve() {
      return !!this.$Permissions.global.has(
        "consulta.escalaAtendimento.incluirReservaTecnica",
        this.unitHealthId,
      );
    },

    hasPermissionEditScale() {
      const typeScale = this.selectedLine?.cte_nome;

      const canEditNormalScale =
        typeScale === "NORMAL" && this.hasPermissionEditNormalScale;

      return canEditNormalScale || this.hasPermissionEditTechnicalReserve;
    },

    hasPermissionEditNormalScale() {
      return !!this.$Permissions.global.has(
        "consulta.escalaAtendimento.alterar",
        this.unitHealthId,
      );
    },

    hasPermissionEditTechnicalReserve() {
      return !!this.$Permissions.global.has(
        "consulta.escalaAtendimento.alterarReservaTecnica",
        this.unitHealthId,
      );
    },

    hasPermissionDeleteScale() {
      const typeScale = this.selectedLine?.cte_nome;

      const canDeleteNormalScale =
        typeScale === "NORMAL" && this.hasPermissionDeleteNormal;

      return canDeleteNormalScale || this.hasPermissionDeleteTechnicalReserve;
    },

    hasPermissionDeleteNormal() {
      return !!this.$Permissions.global.has(
        "consulta.escalaAtendimento.excluir",
        this.unitHealthId,
      );
    },

    hasPermissionDeleteTechnicalReserve() {
      return !!this.$Permissions.global.has(
        "consulta.escalaAtendimento.excluirReservaTecnica",
        this.unitHealthId,
      );
    },

    hasPermissionDivisionScale() {
      const typeScale = this.selectedLine?.cte_nome;

      const canDivisionNormalScale =
        typeScale === "NORMAL" && this.hasPermissionDivisionNormal;

      return canDivisionNormalScale || this.hasPermissionDivisionReservaTecnica;
    },

    hasPermissionDivisionNormal() {
      return !!this.$Permissions.global.has(
        "consulta.escalaAtendimento.dividir",
        this.unitHealthId,
      );
    },

    hasPermissionDivisionReservaTecnica() {
      return !!this.$Permissions.global.has(
        "consulta.escalaAtendimento.dividirReservaTecnica",
        this.unitHealthId,
      );
    },

    hasListScale() {
      return this.mutableScales?.length > 0;
    },
  },

  watch: {
    unitHealth(pValue, pPrev) {
      if (!pValue || (pValue && pPrev && pValue !== pPrev)) {
        this.form.sector = null;
        this.form.pes_id = null;
      }
    },

    sector(pValue) {
      if (!pValue || !isNaN(pValue)) {
        this.cbo = null;
      }
    },
  },

  created() {
    this.COLUMN_TABLE = [
      { name: "Tipo", key: "cte_nome" },
      { name: "Setor", key: "set_nome" },
      { name: "Ocupação", key: "ocp_nome" },
      { name: "Profissional", key: "pes_nome" },
      { name: "Dia", key: "dsm_sigla" },
      { name: "Horário de Início", key: "eag_inicio_atendimentos" },
      { name: "Validade", key: "validade" },
      { name: "Horário de Término", key: "eag_final_atendimentos" },
      { name: "Intervalo", key: "eag_intervalo_consultas" },
      { name: "Vagas", key: "eag_quantidade_consultas" },
      { name: "Unidade de Saúde", key: "uns_nome" },
    ];
  },

  mounted() {
    this.form.unitHealth = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];

    this.fillFilter();
  },

  beforeDestroy() {
    const validateIfExist = ValidateIfRouteExistInBreadscrumb(
      "/appointment/register/scale/search",
      this.$route.meta.breadcrumb,
    );

    if (!validateIfExist) {
      this.$store.commit(
        "Appointment/Register/RESET_FILTER_APPOINTMENT_SCALE_LIST",
      );
    }
  },

  methods: {
    async fillFilter() {
      try {
        const existFilterData = this.$store.getters[
          "Appointment/Register/GET_FILTER_APPOINTMENT_SCALE_LIST"
        ];
        if (existFilterData) {
          this.form = existFilterData;

          if (this.$refs.Cbo) {
            this.$refs.Cbo.forceSelection({
              ocp_nome: existFilterData.ocp_nome,
              ocp_codigo: existFilterData.ocp_codigo,
              ocp_id: existFilterData.ocp_id,
            });
          }

          await this.$refs.RgSearch.performSearch();
        }
      } catch (Err) {
        this.$toaster.warning("Erro ao carregar os dados da navegação");
      }
    },

    getDynamicHeight() {
      this.$nextTick(() => {
        if (this.$refs.RgSearch?.$refs.listContent) {
          const footerSlot =
            this.$refs.footerSlot && this.$refs.footerSlot.offsetHeight;
          const searchArea = this.$refs.RgSearch.$refs.listContent.offsetHeight;

          this.tableHeight = searchArea - footerSlot;

          if (this.$refs.smartTable) this.$refs.smartTable.cleanSelectRow();
        }
      });
    },

    registerScale() {
      this.$store.commit(
        "Appointment/Register/RESET_SELECTED_APPOINTMENT_SCALE_LIST_LINE",
      );
      this.$router.push({
        name: "appointment.register.scale.search.register",
      });
    },

    updateScale() {
      try {
        this.$router.push({
          name: "appointment.register.scale.search.update",
        });
      } catch (err) {
        this.$toaster.error(`Erro ao tentar editar. ${err.message}`);
      }
    },

    async printScaleAttendProfessional() {
      try {
        const data = await this.$store.dispatch(
          "Appointment/Register/GET_EMPLOYEE_ATTENDACE_SCALE_DATA",
          {
            intVusId: this.selectedLine.vus_id,
          },
        );

        const clientName = this.$store.getters["Login/GET_CLIENT_NAME"];

        const info = this.$utils.obj.DeepCopy(this.selectedLine);
        info.cli_nome = clientName;
        info.fun_conselho_classe =
          (data && data.length > 0 && data[0].fun_conselho_classe) || "";

        const html = EmployeeAttendanceScaleHTML(info, data);
        this.$utils.print.printHtml(html);
      } catch (error) {
        this.$toaster.error(error.message);
      }
    },

    async deleteScaleList() {
      try {
        this.$loader.start("Excluindo a escala selecionada...");

        const variables = {
          intIdEscalaAgendamento: this.selectedLine.eag_id,
          blnValidarHistoricoInabilitado: true,
        };

        await this.$store.dispatch(
          "Appointment/Register/DELETE_APPOINTMENT_SCALE",
          variables,
        );
        this.$toaster.success("Exclusão realizada com sucesso!");
      } catch (err) {
        this.$toaster.error(err.message);
      } finally {
        this.$loader.finish();
      }
    },

    reSearchTable() {
      this.$refs.RgSearch.submitForm(true);
    },

    openModalDeleteScale() {
      this.modalDeleteScales = true;
    },

    closeModalDeleteScales() {
      this.modalDeleteScales = false;
    },

    openModalDivisionScale() {
      if (!this.validateDivisionDate()) return;
      this.modalDivisionScales = true;
    },

    closeModalDivisionScales() {
      this.modalDivisionScales = false;
    },

    selectLine(pValue) {
      this.selectedLine = pValue;

      const line = this.$utils.obj.DeepCopy(pValue);

      line.eag_data_inicio_escala =
        line.eag_data_inicio_escala === "N/A"
          ? null
          : line.eag_data_inicio_escala;
      line.eag_data_fim_escala =
        line.eag_data_fim_escala === "N/A" ? null : line.eag_data_fim_escala;

      if (line) {
        this.$store.commit(
          "Appointment/Register/SET_SELECTED_APPOINTMENT_SCALE_LIST_LINE",
          line,
        );
      } else {
        this.$store.commit(
          "Appointment/Register/RESET_SELECTED_APPOINTMENT_SCALE_LIST_LINE",
        );
      }
    },

    async divisionScale() {
      try {
        const variables = {
          intIdEscalaAgendamento: this.selectedLine.eag_id,
          strDataUltimoDia: this.divisionDate,
          blnPossuiPermissaoDividir: this.hasPermissionDivisionNormal,
          blnPossuiPermissaoDividirReservaTecnica: this
            .hasPermissionDivisionReservaTecnica,
        };

        await this.$store.dispatch(
          "Appointment/Register/DIVISION_APPOINTMENT_SCALE",
          variables,
        );

        this.$refs.RgSearch.submitForm(false, true);
        this.divisionDate = null;
        this.modalDivisionScales = false;
        this.$toaster.success("Divisão realizada com sucesso!");
      } catch (Err) {
        this.$toaster.error("Não foi possível realizar a divisão da escala.");
      }
    },

    validateDivisionDate() {
      if (!this.divisionDate) {
        this.$toaster.warning("Informe a data inicial da divisão.");
        return false;
      }

      const { eag_data_inicio_escala, eag_data_fim_escala } = this.selectedLine;

      if (eag_data_inicio_escala === eag_data_fim_escala) {
        this.$toaster.warning(
          "A escala selecionada possui validade de apenas um dia e não pode ser dividida.",
        );
        return false;
      }

      const initial = moment(eag_data_inicio_escala, "DD/MM/YYYY");

      const final = moment(eag_data_fim_escala, "DD/MM/YYYY");

      const divisionDate = moment(this.divisionDate, "DD/MM/YYYY");

      if (divisionDate.isBefore(initial)) {
        this.$toaster.warning(
          "A data da divisão não pode ser menor que a data de início da escala.",
        );
        return false;
      }

      if (divisionDate.isSameOrAfter(final)) {
        this.$toaster.warning(
          "A data da divisão não pode ser maior ou igual que a data de término da escala.",
        );
        return false;
      }

      return true;
    },

    onInputRadioCustom(item) {
      if (item) {
        this.form.tipoEscala = item.id;
      } else {
        this.form.tipoEscala = null;
      }
    },

    searchFilter(pData) {
      if (this.hasScaleTypePermission()) {
        return this.$store.dispatch(
          "Appointment/Register/SEARCH_SCALE_LIST",
          pData,
        );
      }
    },

    hasScaleTypePermission() {
      const NORMAL = "1";
      const TECHNICAL_RESERVE = "2";

      if (
        !this.hasPermissionViewNormal &&
        !this.hasPermissionViewTechnicalReserve
      ) {
        this.cleanResult();
        return AlertError("Sem permissão para consulta.");
      }

      if (this.form.scaleType === NORMAL && !this.hasPermissionViewNormal) {
        this.cleanResult();
        return AlertError(
          "Sem permissão para consulta em Tipo de Escala Normal.",
        );
      }
      if (
        this.form.scaleType === TECHNICAL_RESERVE &&
        !this.hasPermissionViewTechnicalReserve
      ) {
        this.cleanResult();
        return AlertError(
          "Sem permissão para consulta em Tipo de Escala Reserva Técnica.",
        );
      }
      return true;
    },

    verifyScalePermission() {
      const NORMAL = "1";
      const TECHNICAL_RESERVE = "2";

      const scaleTypeByPermission = !this.hasPermissionViewTechnicalReserve
        ? Number(NORMAL)
        : !this.hasPermissionViewNormal
        ? Number(TECHNICAL_RESERVE)
        : 0;

      return this.form.scaleType > 0
        ? this.form.scaleType
        : scaleTypeByPermission;
    },

    cleanForm() {
      this.form = this.$utils.obj.DeepCopy(FORM);
      this.form.unitHealth = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
      this.cbo = null;
      this.selectedLine = null;
      this.divisionDate = null;
      this.mutableScales = [];

      if (this.$refs.smartTable) {
        this.$refs.smartTable.cleanSelectRow();
      }

      this.cleanProfessionalInfo();

      this.$store.commit(
        "Appointment/Register/RESET_FILTER_APPOINTMENT_SCALE_LIST",
      );

      this.$store.commit(
        "Appointment/Register/RESET_SELECTED_APPOINTMENT_SCALE_LIST_LINE",
      );
    },

    cleanProfessionalInfo() {
      this.sectorLinkId = 0;
      this.professionalInfo = {};
    },

    cleanResult() {
      this.mutableScales = [];
      this.selectedLine = null;
      this.$store.commit(
        "Appointment/Register/RESET_SELECTED_APPOINTMENT_SCALE_LIST_LINE",
      );
    },

    afterSearchFilter() {
      // GET DYNAMIC HEIGHT
      this.getDynamicHeight();

      this.selectedLine = null;
      this.divisionDate = null;

      if (this.$refs.smartTable) {
        this.$refs.smartTable.cleanSelectRow();
      }
      this.$store.commit(
        "Appointment/Register/RESET_SELECTED_APPOINTMENT_SCALE_LIST_LINE",
      );
    },

    getCountValue(pValue) {
      this.total = Number(pValue) || 0;
    },

    generateFilter() {
      const scaleTypeByPermission = this.verifyScalePermission();

      const variables = {
        arrFormData: {
          cte_id: scaleTypeByPermission,
          dsm_id: this.form.weekDay,
          eag_data_fim_escala: this.form.dateFinal,
          eag_data_inicio_escala: this.form.dateInitial,
          eag_final_atendimentos: this.form.hourFinal,
          eag_inicio_atendimentos: this.form.hourInitial,
          idUnidadeSaude: this.form.unitHealth || 0,
          ocp_id: this.form.ocp_id || 0,
          pes_id: this.form.pes_id,
          set_id: this.form.sector || 0,
          smo_id: 6,
          tipoEscala: this.form.tipoEscala,
          uns_id: this.form.unitHealth || 0,
        },
      };

      this.$store.commit(
        "Appointment/Register/SET_FILTER_APPOINTMENT_SCALE_LIST",
        this.$utils.obj.DeepCopy(this.form),
      );

      return variables;
    },

    selectedCbo(pValue) {
      const hasSuggestion = Object.keys(pValue.source).length > 0;
      if (hasSuggestion) {
        this.$loader.start();
        this.form.ocp_id = pValue.source.ocp_id;
        this.form.ocp_nome = pValue.source.ocp_nome;
        this.form.ocp_codigo = pValue.source.ocp_codigo;
        this.$loader.finish();
      } else {
        this.form.ocp_id = 0;
        this.form.ocp_nome = null;
        this.cbo = null;
        this.form.ocp_codigo = null;
      }
    },

    selectedEmployee(pValue) {
      this.form.vus_id = pValue ? pValue.vus_id : null;
      this.form.pes_nome = pValue ? pValue.label : null;
    },

    openModalHistoryScales() {
      this.sectorLinkId = Number(this.selectedLine?.vus_id);
      this.professionalInfo = {
        pes_nome: this.selectedLine?.pes_nome,
        uns_nome: this.selectedLine?.uns_nome,
        set_nome: this.selectedLine?.set_nome,
        ocp_nome: this.selectedLine?.ocp_nome,
      };

      this.modalHistoryScales = true;
    },

    closeModalHistoryScales() {
      this.modalHistoryScales = false;
      this.cleanProfessionalInfo();
    },

    verifyExpirationInitialDate(pValue, pErrors) {
      const initialDate = moment(this.form.dateInitial, "DD/MM/YYYY");
      const finalDate = moment(this.form.dateFinal, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }
    },
  },
};
</script>
