<template lang="html">
  <fieldset class="rg-checkbox-bed-active">
    <span class="label">Listar por Status</span>
    <div class="container-checkbox unselect">
      <div
        :class="{ '-activestatus _color-white': selectedStatus.includes(1) }"
        class="checkbox -active justify-content-center align-items-center"
        @click="toggleStatus(1)"
      >
        Ativo
      </div>
      <div
        :class="{ '-inactivestatus _color-white': selectedStatus.includes(0) }"
        class="checkbox -inactive justify-content-center align-items-center"
        @click="toggleStatus(0)"
      >
        Inativo
      </div>
    </div>
  </fieldset>
</template>

<script>
export default {
  name: "RgRadioBedActive",
  props: {
    value: {
      default: null,
    },
  },
  data() {
    return {
      selectedStatus: [1, 2],
    };
  },
  watch: {
    value(pValue) {
      this.selectedStatus = pValue;
    },
  },
  mounted() {
    this.selectedStatus = this.value || [0, 1];
  },
  methods: {
    toggleStatus(pItem) {
      if (this.selectedStatus.includes(pItem)) {
        this.selectedStatus.splice(this.selectedStatus.indexOf(pItem), 1);
      } else {
        this.selectedStatus.push(pItem);
      }
      this.$emit("input", this.selectedStatus);
    },
  },
};
</script>
