<template lang="html">
  <svg
    width="44"
    height="48"
    viewBox="0 0 44 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M43.1804 7.72098V15.1718H0V7.72098C0 6.61442 0.614755 5.63081 1.54918 5.1636C1.94263 4.94229 2.40984 4.81934 2.90165 4.81934H40.2788C41.3853 4.81934 42.3689 5.43409 42.8362 6.36852C43.0575 6.76196 43.1804 7.22918 43.1804 7.72098Z"
      fill="#FFA56A"
    />
    <path
      d="M11.5798 4.81934V10.2292C11.5798 10.7702 11.1372 11.1882 10.6208 11.1882H6.16996C5.62897 11.1882 5.21094 10.7456 5.21094 10.2292V4.81934H11.5798Z"
      fill="#FF6E00"
    />
    <path
      d="M36.4157 4.81934V10.2292C36.4157 10.7702 35.9731 11.1882 35.4567 11.1882H31.0059C30.4649 11.1882 30.0469 10.7456 30.0469 10.2292V4.81934H36.4157Z"
      fill="#FF6E00"
    />
    <path
      d="M1.54918 5.16406V15.1723H0V7.72144C0 6.61489 0.614755 5.63128 1.54918 5.16406Z"
      fill="#FF6E00"
    />
    <path
      d="M7.91759 20.9258H12.8848C13.1553 20.9258 13.3766 21.1471 13.3766 21.4176V26.3848C13.3766 26.6553 13.1553 26.8766 12.8848 26.8766H7.91759C7.64709 26.8766 7.42578 26.6553 7.42578 26.3848V21.4176C7.45037 21.1471 7.64709 20.9258 7.91759 20.9258Z"
      fill="#E6E6E6"
    />
    <path
      d="M7.91759 28.3281H12.8848C13.1553 28.3281 13.3766 28.5494 13.3766 28.8199V33.7872C13.3766 34.0576 13.1553 34.279 12.8848 34.279H7.91759C7.64709 34.279 7.42578 34.0576 7.42578 33.7872V28.8199C7.45037 28.5494 7.64709 28.3281 7.91759 28.3281Z"
      fill="#E6E6E6"
    />
    <path
      d="M7.91759 35.7051H12.8848C13.1553 35.7051 13.3766 35.9264 13.3766 36.1969V41.1641C13.3766 41.4346 13.1553 41.6559 12.8848 41.6559H7.91759C7.64709 41.6559 7.42578 41.4346 7.42578 41.1641V36.1969C7.45037 35.9264 7.64709 35.7051 7.91759 35.7051Z"
      fill="#E6E6E6"
    />
    <path
      d="M15.3199 20.9258H20.3855C20.656 20.9258 20.8773 21.1471 20.8773 21.4176V26.3848C20.8773 26.6553 20.656 26.8766 20.3855 26.8766H15.3199C15.0494 26.8766 14.8281 26.6553 14.8281 26.3848V21.4176C14.8281 21.1471 15.0494 20.9258 15.3199 20.9258Z"
      fill="#E6E6E6"
    />
    <path
      d="M15.3199 28.3281H20.3855C20.656 28.3281 20.8773 28.5494 20.8773 28.8199V33.7872C20.8773 34.0576 20.656 34.279 20.3855 34.279H15.3199C15.0494 34.279 14.8281 34.0576 14.8281 33.7872V28.8199C14.8281 28.5494 15.0494 28.3281 15.3199 28.3281Z"
      fill="#E6E6E6"
    />
    <path
      d="M15.3199 35.7051H20.3855C20.656 35.7051 20.8773 35.9264 20.8773 36.1969V41.1641C20.8773 41.4346 20.656 41.6559 20.3855 41.6559H15.3199C15.0494 41.6559 14.8281 41.4346 14.8281 41.1641V36.1969C14.8281 35.9264 15.0494 35.7051 15.3199 35.7051Z"
      fill="#E6E6E6"
    />
    <path
      d="M22.7926 20.9258H27.8582C28.1287 20.9258 28.35 21.1471 28.35 21.4176V26.3848C28.35 26.6553 28.1287 26.8766 27.8582 26.8766H22.7926C22.5221 26.8766 22.3008 26.6553 22.3008 26.3848V21.4176C22.3254 21.1471 22.5221 20.9258 22.7926 20.9258Z"
      fill="#E6E6E6"
    />
    <path
      d="M22.7926 28.3281H27.8582C28.1287 28.3281 28.35 28.5494 28.35 28.8199V33.7872C28.35 34.0576 28.1287 34.279 27.8582 34.279H22.7926C22.5221 34.279 22.3008 34.0576 22.3008 33.7872V28.8199C22.3254 28.5494 22.5221 28.3281 22.7926 28.3281Z"
      fill="#E6E6E6"
    />
    <path
      d="M22.7926 35.7051H27.8582C28.1287 35.7051 28.35 35.9264 28.35 36.1969V41.1641C28.35 41.4346 28.1287 41.6559 27.8582 41.6559H22.7926C22.5221 41.6559 22.3008 41.4346 22.3008 41.1641V36.1969C22.3254 35.9264 22.5221 35.7051 22.7926 35.7051Z"
      fill="#E6E6E6"
    />
    <path
      d="M35.7555 21.4176V26.3848C35.7555 26.6553 35.5342 26.8766 35.2637 26.8766H30.2965C30.026 26.8766 29.8047 26.6553 29.8047 26.3848V21.4176C29.8047 21.1471 30.026 20.9258 30.2965 20.9258H35.2637C35.5342 20.9258 35.7555 21.1471 35.7555 21.4176Z"
      fill="#E6E6E6"
    />
    <path
      d="M30.2965 28.3281H35.2637C35.5342 28.3281 35.7555 28.5494 35.7555 28.8199V33.7872C35.7555 34.0576 35.5342 34.279 35.2637 34.279H30.2965C30.026 34.279 29.8047 34.0576 29.8047 33.7872V28.8199C29.8047 28.5494 30.026 28.3281 30.2965 28.3281Z"
      fill="#E6E6E6"
    />
    <path
      d="M30.2965 35.7051H35.2637C35.5342 35.7051 35.7555 35.9264 35.7555 36.1969V41.1641C35.7555 41.4346 35.5342 41.6559 35.2637 41.6559H30.2965C30.026 41.6559 29.8047 41.4346 29.8047 41.1641V36.1969C29.8047 35.9264 30.026 35.7051 30.2965 35.7051Z"
      fill="#E6E6E6"
    />
    <path
      d="M0 14.4834V45.0982C0 46.6966 1.30328 47.9999 2.90165 47.9999H40.2788C41.8771 47.9999 43.1804 46.6966 43.1804 45.0982V14.4834H0Z"
      fill="#F5F5F5"
    />
    <path
      d="M11.5814 0.959018V8.68034C11.5814 9.22133 11.1388 9.63936 10.6224 9.63936H7.72074C7.17975 9.63936 6.76172 9.19674 6.76172 8.68034V0.959018C6.76172 0.418034 7.20434 0 7.72074 0H10.6224C11.1634 0 11.5814 0.418034 11.5814 0.959018Z"
      fill="#50758D"
    />
    <path d="M7.45312 20.9258H13.3794V26.852H7.45312V20.9258Z" fill="#FFA56A" />
    <path d="M29.8047 20.9258H35.7309V26.852H29.8047V20.9258Z" fill="#FFA56A" />
    <path d="M14.8281 20.9258H20.8773V26.852H14.8281V20.9258Z" fill="#FFA56A" />
    <path
      d="M7.45312 28.3281H13.3794V34.2544H7.45312V28.3281Z"
      fill="#FFA56A"
    />
    <path
      d="M29.8047 28.3281H35.7309V34.2544H29.8047V28.3281Z"
      fill="#CFCFCF"
    />
    <path
      d="M14.8281 28.3281H20.8773V34.2544H14.8281V28.3281Z"
      fill="#FFA56A"
    />
    <path
      d="M7.45312 35.7051H13.3794V41.6313H7.45312V35.7051Z"
      fill="#CFCFCF"
    />
    <path
      d="M29.8047 35.7051H35.7309V41.6313H29.8047V35.7051Z"
      fill="#CFCFCF"
    />
    <path
      d="M14.8281 35.7051H20.8773V41.6313H14.8281V35.7051Z"
      fill="#CFCFCF"
    />
    <path d="M22.3281 20.9258H28.3773V26.852H22.3281V20.9258Z" fill="#FFA56A" />
    <path
      d="M22.3281 28.3281H28.3773V34.2544H22.3281V28.3281Z"
      fill="#FFA56A"
    />
    <path
      d="M22.3281 35.7051H28.3773V41.6313H22.3281V35.7051Z"
      fill="#CFCFCF"
    />
    <path
      d="M36.4173 0.959018V8.68034C36.4173 9.22133 35.9747 9.63936 35.4583 9.63936H32.5567C32.0157 9.63936 31.5977 9.19674 31.5977 8.68034V0.959018C31.5977 0.418034 32.0403 0 32.5567 0H35.4583C35.9993 0 36.4173 0.418034 36.4173 0.959018Z"
      fill="#50758D"
    />
    <path
      d="M0 14.4834V45.0982C0 46.6966 1.30328 47.9999 2.90165 47.9999H3.98361C2.63115 47.754 1.59836 46.5736 1.59836 45.1474V16.5244C1.69672 16.2293 1.99181 16.0326 2.31148 16.0326H43.1804V14.5326V14.4834H0Z"
      fill="#E6E6E6"
    />
    <path
      d="M9.26992 9.63936H7.72074C7.17975 9.63936 6.76172 9.19674 6.76172 8.68034V0.959018C6.76172 0.418034 7.20434 0 7.72074 0H8.3109V8.70493C8.3109 9.22133 8.72894 9.63936 9.26992 9.63936Z"
      fill="#2B597F"
    />
    <path
      d="M34.1059 9.63936H32.5567C32.0157 9.63936 31.5977 9.19674 31.5977 8.68034V0.959018C31.5977 0.418034 32.0403 0 32.5567 0H33.1468V8.70493C33.1468 9.22133 33.5649 9.63936 34.1059 9.63936Z"
      fill="#2B597F"
    />
    <path
      d="M43.1804 7.72098V15.1718H0V7.72098C0 6.61442 0.614752 5.63081 1.54918 5.1636C1.94262 4.94229 2.40984 4.81934 2.90164 4.81934H40.2788C41.3853 4.81934 42.3689 5.43409 42.8362 6.36852C43.0575 6.76196 43.1804 7.22918 43.1804 7.72098Z"
      fill="#66D48F"
    />
    <path
      d="M11.5798 4.81934V10.2292C11.5798 10.7702 11.1372 11.1882 10.6208 11.1882H6.16995C5.62897 11.1882 5.21094 10.7456 5.21094 10.2292V4.81934H11.5798Z"
      fill="#00B98F"
    />
    <path
      d="M36.4157 4.81934V10.2292C36.4157 10.7702 35.9731 11.1882 35.4567 11.1882H31.0059C30.4649 11.1882 30.0469 10.7456 30.0469 10.2292V4.81934H36.4157Z"
      fill="#00B98F"
    />
    <path
      d="M1.54918 5.16406V15.1723H0V7.72144C0 6.61488 0.614752 5.65587 1.54918 5.16406Z"
      fill="#00B98F"
    />
    <path
      d="M7.91759 20.9502H12.8848C13.1553 20.9502 13.3766 21.1715 13.3766 21.442V26.4092C13.3766 26.6797 13.1553 26.901 12.8848 26.901H7.91759C7.64709 26.901 7.42578 26.6797 7.42578 26.4092V21.4174C7.42578 21.1469 7.64709 20.9502 7.91759 20.9502Z"
      fill="#E6E6E6"
    />
    <path
      d="M7.91759 28.3281H12.8848C13.1553 28.3281 13.3766 28.5494 13.3766 28.8199V33.7872C13.3766 34.0576 13.1553 34.279 12.8848 34.279H7.91759C7.64709 34.279 7.42578 34.0576 7.42578 33.7872V28.8199C7.42578 28.5494 7.64709 28.3281 7.91759 28.3281Z"
      fill="#E6E6E6"
    />
    <path
      d="M7.91759 35.7051H12.8848C13.1553 35.7051 13.3766 35.9264 13.3766 36.1969V41.1641C13.3766 41.4346 13.1553 41.6559 12.8848 41.6559H7.91759C7.64709 41.6559 7.42578 41.4346 7.42578 41.1641V36.1969C7.42578 35.9264 7.64709 35.7051 7.91759 35.7051Z"
      fill="#E6E6E6"
    />
    <path
      d="M15.2965 20.9502H20.3621C20.6326 20.9502 20.8539 21.1715 20.8539 21.442V26.4092C20.8539 26.6797 20.6326 26.901 20.3621 26.901H15.2965C15.026 26.901 14.8047 26.6797 14.8047 26.4092V21.4174C14.8293 21.1469 15.0506 20.9502 15.2965 20.9502Z"
      fill="#E6E6E6"
    />
    <path
      d="M15.2965 28.3281H20.3621C20.6326 28.3281 20.8539 28.5494 20.8539 28.8199V33.7872C20.8539 34.0576 20.6326 34.279 20.3621 34.279H15.2965C15.026 34.279 14.8047 34.0576 14.8047 33.7872V28.8199C14.8293 28.5494 15.0506 28.3281 15.2965 28.3281Z"
      fill="#E6E6E6"
    />
    <path
      d="M15.2965 35.7051H20.3621C20.6326 35.7051 20.8539 35.9264 20.8539 36.1969V41.1641C20.8539 41.4346 20.6326 41.6559 20.3621 41.6559H15.2965C15.026 41.6559 14.8047 41.4346 14.8047 41.1641V36.1969C14.8293 35.9264 15.0506 35.7051 15.2965 35.7051Z"
      fill="#E6E6E6"
    />
    <path
      d="M22.7926 20.9502H27.8582C28.1287 20.9502 28.35 21.1715 28.35 21.442V26.4092C28.35 26.6797 28.1287 26.901 27.8582 26.901H22.7926C22.5221 26.901 22.3008 26.6797 22.3008 26.4092V21.4174C22.3008 21.1469 22.5221 20.9502 22.7926 20.9502Z"
      fill="#E6E6E6"
    />
    <path
      d="M22.7926 28.3281H27.8582C28.1287 28.3281 28.35 28.5494 28.35 28.8199V33.7872C28.35 34.0576 28.1287 34.279 27.8582 34.279H22.7926C22.5221 34.279 22.3008 34.0576 22.3008 33.7872V28.8199C22.3008 28.5494 22.5221 28.3281 22.7926 28.3281Z"
      fill="#E6E6E6"
    />
    <path
      d="M22.7926 35.7051H27.8582C28.1287 35.7051 28.35 35.9264 28.35 36.1969V41.1641C28.35 41.4346 28.1287 41.6559 27.8582 41.6559H22.7926C22.5221 41.6559 22.3008 41.4346 22.3008 41.1641V36.1969C22.3008 35.9264 22.5221 35.7051 22.7926 35.7051Z"
      fill="#E6E6E6"
    />
    <path
      d="M35.7282 21.4176V26.3848C35.7282 26.6553 35.5069 26.8766 35.2364 26.8766H30.2691C29.9987 26.8766 29.7773 26.6553 29.7773 26.3848V21.4176C29.7773 21.1471 29.9987 20.9258 30.2691 20.9258H35.2364C35.5315 20.9504 35.7282 21.1471 35.7282 21.4176Z"
      fill="#E6E6E6"
    />
    <path
      d="M30.2965 28.3281H35.2637C35.5342 28.3281 35.7555 28.5494 35.7555 28.8199V33.7872C35.7555 34.0576 35.5342 34.279 35.2637 34.279H30.2965C30.026 34.279 29.8047 34.0576 29.8047 33.7872V28.8199C29.8047 28.5494 30.026 28.3281 30.2965 28.3281Z"
      fill="#E6E6E6"
    />
    <path
      d="M30.2965 35.7051H35.2637C35.5342 35.7051 35.7555 35.9264 35.7555 36.1969V41.1641C35.7555 41.4346 35.5342 41.6559 35.2637 41.6559H30.2965C30.026 41.6559 29.8047 41.4346 29.8047 41.1641V36.1969C29.8047 35.9264 30.026 35.7051 30.2965 35.7051Z"
      fill="#E6E6E6"
    />
    <path
      d="M0 14.4834V45.0982C0 46.6966 1.30328 47.9999 2.90164 47.9999H40.2788C41.8771 47.9999 43.1804 46.6966 43.1804 45.0982V14.4834H0Z"
      fill="#F5F5F5"
    />
    <path
      d="M11.5814 0.959018V8.68034C11.5814 9.22133 11.1388 9.63936 10.6224 9.63936H7.72074C7.17975 9.63936 6.76172 9.19674 6.76172 8.68034V0.959018C6.76172 0.418034 7.20434 0 7.72074 0H10.6224C11.1634 0 11.5814 0.418034 11.5814 0.959018Z"
      fill="#50758D"
    />
    <path d="M7.42578 20.9502H13.352V26.8764H7.42578V20.9502Z" fill="#66D48F" />
    <path
      d="M29.8047 20.9502H35.7309V26.8764H29.8047V20.9502Z"
      fill="#66D48F"
    />
    <path
      d="M14.8281 20.9502H20.8773V26.8764H14.8281V20.9502Z"
      fill="#66D48F"
    />
    <path d="M7.42578 28.3281H13.352V34.2544H7.42578V28.3281Z" fill="#66D48F" />
    <path
      d="M29.8047 28.3281H35.7309V34.2544H29.8047V28.3281Z"
      fill="#CFCFCF"
    />
    <path
      d="M14.8281 28.3281H20.8773V34.2544H14.8281V28.3281Z"
      fill="#66D48F"
    />
    <path d="M7.42578 35.7051H13.352V41.6313H7.42578V35.7051Z" fill="#CFCFCF" />
    <path
      d="M29.8047 35.7051H35.7309V41.6313H29.8047V35.7051Z"
      fill="#CFCFCF"
    />
    <path
      d="M14.8281 35.7051H20.8773V41.6313H14.8281V35.7051Z"
      fill="#CFCFCF"
    />
    <path d="M22.3008 20.9502H28.35V26.8764H22.3008V20.9502Z" fill="#66D48F" />
    <path d="M22.3008 28.3281H28.35V34.2544H22.3008V28.3281Z" fill="#66D48F" />
    <path d="M22.3008 35.7051H28.35V41.6313H22.3008V35.7051Z" fill="#CFCFCF" />
    <path
      d="M36.4173 0.959018V8.68034C36.4173 9.22133 35.9747 9.63936 35.4583 9.63936H32.5567C32.0157 9.63936 31.5977 9.19674 31.5977 8.68034V0.959018C31.5977 0.418034 32.0403 0 32.5567 0H35.4583C35.9747 0 36.4173 0.418034 36.4173 0.959018Z"
      fill="#50758D"
    />
    <path
      d="M0 14.4836V45.0984C0 46.6968 1.30328 48 2.90164 48H3.98362C2.63116 47.7541 1.59836 46.5738 1.59836 45.1476V16.5246C1.69672 16.2295 1.99181 16.0082 2.31148 16.0082H43.1804V14.5082V14.459H0V14.4836Z"
      fill="#E6E6E6"
    />
    <path
      d="M9.26992 9.66395H7.72074C7.17975 9.66395 6.76172 9.22133 6.76172 8.70493V0.959018C6.76172 0.418034 7.20434 0 7.72074 0H8.3109V8.70493C8.3109 9.22133 8.72894 9.66395 9.26992 9.66395Z"
      fill="#2B597F"
    />
    <path
      d="M34.1059 9.66395H32.5567C32.0157 9.66395 31.5977 9.22133 31.5977 8.70493V0.959018C31.5977 0.418034 32.0403 0 32.5567 0H33.1468V8.70493C33.1222 9.22133 33.5649 9.66395 34.1059 9.66395Z"
      fill="#2B597F"
    />
    <path
      d="M43.1804 7.72098V15.1718H0V7.72098C0 6.61442 0.614756 5.63081 1.54918 5.1636C1.94263 4.94229 2.40984 4.81934 2.90165 4.81934H40.2788C41.3853 4.81934 42.3689 5.43409 42.8362 6.36852C43.0575 6.76196 43.1804 7.22918 43.1804 7.72098Z"
      fill="#EDC76A"
    />
    <path
      d="M11.5798 4.81934V10.2292C11.5798 10.7702 11.1372 11.1882 10.6208 11.1882H6.16996C5.62897 11.1882 5.21094 10.7456 5.21094 10.2292V4.81934H11.5798Z"
      fill="#FFB000"
    />
    <path
      d="M36.4157 4.81934V10.2292C36.4157 10.7702 35.9731 11.1882 35.4567 11.1882H31.0059C30.4649 11.1882 30.0469 10.7456 30.0469 10.2292V4.81934H36.4157Z"
      fill="#FFB000"
    />
    <path
      d="M1.54918 5.16406V15.1723H0V7.72144C0 6.61489 0.614756 5.63128 1.54918 5.16406Z"
      fill="#FFB000"
    />
    <path
      d="M7.91759 20.9258H12.8848C13.1553 20.9258 13.3766 21.1471 13.3766 21.4176V26.3848C13.3766 26.6553 13.1553 26.8766 12.8848 26.8766H7.91759C7.64709 26.8766 7.42578 26.6553 7.42578 26.3848V21.4176C7.42578 21.1471 7.64709 20.9258 7.91759 20.9258Z"
      fill="#E6E6E6"
    />
    <path
      d="M7.91759 28.3281H12.8848C13.1553 28.3281 13.3766 28.5494 13.3766 28.8199V33.7872C13.3766 34.0576 13.1553 34.279 12.8848 34.279H7.91759C7.64709 34.279 7.42578 34.0576 7.42578 33.7872V28.8199C7.42578 28.5494 7.64709 28.3281 7.91759 28.3281Z"
      fill="#E6E6E6"
    />
    <path
      d="M7.91759 35.7051H12.8848C13.1553 35.7051 13.3766 35.9264 13.3766 36.1969V41.1641C13.3766 41.4346 13.1553 41.6559 12.8848 41.6559H7.91759C7.64709 41.6559 7.42578 41.4346 7.42578 41.1641V36.1969C7.42578 35.9264 7.64709 35.7051 7.91759 35.7051Z"
      fill="#E6E6E6"
    />
    <path
      d="M15.2965 20.9258H20.3621C20.6326 20.9258 20.8539 21.1471 20.8539 21.4176V26.3848C20.8539 26.6553 20.6326 26.8766 20.3621 26.8766H15.2965C15.026 26.8766 14.8047 26.6553 14.8047 26.3848V21.4176C14.8293 21.1471 15.0506 20.9258 15.2965 20.9258Z"
      fill="#E6E6E6"
    />
    <path
      d="M15.2965 28.3281H20.3621C20.6326 28.3281 20.8539 28.5494 20.8539 28.8199V33.7872C20.8539 34.0576 20.6326 34.279 20.3621 34.279H15.2965C15.026 34.279 14.8047 34.0576 14.8047 33.7872V28.8199C14.8293 28.5494 15.0506 28.3281 15.2965 28.3281Z"
      fill="#E6E6E6"
    />
    <path
      d="M15.2965 35.7051H20.3621C20.6326 35.7051 20.8539 35.9264 20.8539 36.1969V41.1641C20.8539 41.4346 20.6326 41.6559 20.3621 41.6559H15.2965C15.026 41.6559 14.8047 41.4346 14.8047 41.1641V36.1969C14.8293 35.9264 15.0506 35.7051 15.2965 35.7051Z"
      fill="#E6E6E6"
    />
    <path
      d="M22.7926 20.9258H27.8582C28.1287 20.9258 28.35 21.1471 28.35 21.4176V26.3848C28.35 26.6553 28.1287 26.8766 27.8582 26.8766H22.7926C22.5221 26.8766 22.3008 26.6553 22.3008 26.3848V21.4176C22.3008 21.1471 22.5221 20.9258 22.7926 20.9258Z"
      fill="#E6E6E6"
    />
    <path
      d="M22.7926 28.3281H27.8582C28.1287 28.3281 28.35 28.5494 28.35 28.8199V33.7872C28.35 34.0576 28.1287 34.279 27.8582 34.279H22.7926C22.5221 34.279 22.3008 34.0576 22.3008 33.7872V28.8199C22.3008 28.5494 22.5221 28.3281 22.7926 28.3281Z"
      fill="#E6E6E6"
    />
    <path
      d="M22.7926 35.7051H27.8582C28.1287 35.7051 28.35 35.9264 28.35 36.1969V41.1641C28.35 41.4346 28.1287 41.6559 27.8582 41.6559H22.7926C22.5221 41.6559 22.3008 41.4346 22.3008 41.1641V36.1969C22.3008 35.9264 22.5221 35.7051 22.7926 35.7051Z"
      fill="#E6E6E6"
    />
    <path
      d="M35.7555 21.4176V26.3848C35.7555 26.6553 35.5342 26.8766 35.2637 26.8766H30.2965C30.026 26.8766 29.8047 26.6553 29.8047 26.3848V21.4176C29.8047 21.1471 30.026 20.9258 30.2965 20.9258H35.2637C35.5342 20.9258 35.7555 21.1471 35.7555 21.4176Z"
      fill="#E6E6E6"
    />
    <path
      d="M30.2965 28.3281H35.2637C35.5342 28.3281 35.7555 28.5494 35.7555 28.8199V33.7872C35.7555 34.0576 35.5342 34.279 35.2637 34.279H30.2965C30.026 34.279 29.8047 34.0576 29.8047 33.7872V28.8199C29.8047 28.5494 30.026 28.3281 30.2965 28.3281Z"
      fill="#E6E6E6"
    />
    <path
      d="M30.2965 35.7051H35.2637C35.5342 35.7051 35.7555 35.9264 35.7555 36.1969V41.1641C35.7555 41.4346 35.5342 41.6559 35.2637 41.6559H30.2965C30.026 41.6559 29.8047 41.4346 29.8047 41.1641V36.1969C29.8047 35.9264 30.026 35.7051 30.2965 35.7051Z"
      fill="#E6E6E6"
    />
    <path
      d="M0 14.4834V45.0982C0 46.6966 1.30328 47.9999 2.90165 47.9999H40.2788C41.8771 47.9999 43.1804 46.6966 43.1804 45.0982V14.4834H0Z"
      fill="#F5F5F5"
    />
    <path
      d="M11.5814 0.959018V8.68034C11.5814 9.22133 11.1388 9.63936 10.6224 9.63936H7.72074C7.17975 9.63936 6.76172 9.19674 6.76172 8.68034V0.959018C6.76172 0.418034 7.20434 0 7.72074 0H10.6224C11.1634 0 11.5814 0.418034 11.5814 0.959018Z"
      fill="#50758D"
    />
    <path d="M7.42578 20.9258H13.352V26.852H7.42578V20.9258Z" fill="#EDC76A" />
    <path d="M29.8047 20.9258H35.7309V26.852H29.8047V20.9258Z" fill="#EDC76A" />
    <path d="M14.8281 20.9258H20.8773V26.852H14.8281V20.9258Z" fill="#EDC76A" />
    <path d="M7.42578 28.3281H13.352V34.2544H7.42578V28.3281Z" fill="#EDC76A" />
    <path
      d="M29.8047 28.3281H35.7309V34.2544H29.8047V28.3281Z"
      fill="#CFCFCF"
    />
    <path
      d="M14.8281 28.3281H20.8773V34.2544H14.8281V28.3281Z"
      fill="#EDC76A"
    />
    <path d="M7.42578 35.7051H13.352V41.6313H7.42578V35.7051Z" fill="#CFCFCF" />
    <path
      d="M29.8047 35.7051H35.7309V41.6313H29.8047V35.7051Z"
      fill="#CFCFCF"
    />
    <path
      d="M14.8281 35.7051H20.8773V41.6313H14.8281V35.7051Z"
      fill="#CFCFCF"
    />
    <path d="M22.3008 20.9258H28.35V26.852H22.3008V20.9258Z" fill="#EDC76A" />
    <path d="M22.3008 28.3281H28.35V34.2544H22.3008V28.3281Z" fill="#EDC76A" />
    <path d="M22.3008 35.7051H28.35V41.6313H22.3008V35.7051Z" fill="#CFCFCF" />
    <path
      d="M36.4173 0.959018V8.68034C36.4173 9.22133 35.9747 9.63936 35.4583 9.63936H32.5567C32.0157 9.63936 31.5977 9.19674 31.5977 8.68034V0.959018C31.5977 0.418034 32.0403 0 32.5567 0H35.4583C35.9747 0 36.4173 0.418034 36.4173 0.959018Z"
      fill="#50758D"
    />
    <path
      d="M0 14.4836V45.0984C0 46.6968 1.30328 48 2.90165 48H3.98361C2.63115 47.7541 1.59836 46.5738 1.59836 45.1476V16.5246C1.69672 16.2295 1.99181 16.0082 2.31148 16.0082H43.1804V14.5082V14.459H0V14.4836Z"
      fill="#E6E6E6"
    />
    <path
      d="M9.26992 9.63936H7.72074C7.17975 9.63936 6.76172 9.19674 6.76172 8.68034V0.959018C6.76172 0.418034 7.20434 0 7.72074 0H8.3109V8.70493C8.3109 9.22133 8.72894 9.63936 9.26992 9.63936Z"
      fill="#2B597F"
    />
    <path
      d="M34.1059 9.63936H32.5567C32.0157 9.63936 31.5977 9.19674 31.5977 8.68034V0.959018C31.5977 0.418034 32.0403 0 32.5567 0H33.1468V8.70493C33.1222 9.22133 33.5649 9.63936 34.1059 9.63936Z"
      fill="#2B597F"
    />
    <path
      d="M43.2078 7.72098V15.1718H0.0273438V7.72098C0.0273438 6.61442 0.642111 5.63081 1.57654 5.1636C1.96998 4.94229 2.4372 4.81934 2.929 4.81934H40.3061C41.4127 4.81934 42.3963 5.43409 42.8635 6.36852C43.0848 6.78655 43.2078 7.22918 43.2078 7.72098Z"
      fill="#B46CFF"
    />
    <path
      d="M11.6072 4.81934V10.2292C11.6072 10.7702 11.1645 11.1882 10.6481 11.1882H6.19731C5.65632 11.1882 5.23828 10.7456 5.23828 10.2292V4.81934H11.6072Z"
      fill="#7A6CD2"
    />
    <path
      d="M36.4431 4.81934V10.2292C36.4431 10.7702 36.0005 11.1882 35.4841 11.1882H31.0332C30.4923 11.1882 30.0742 10.7456 30.0742 10.2292V4.81934H36.4431Z"
      fill="#7A6CD2"
    />
    <path
      d="M1.54918 5.16406V15.1723H0V7.72144C0.0245902 6.61488 0.639342 5.65587 1.54918 5.16406Z"
      fill="#7A6CD2"
    />
    <path
      d="M7.94493 20.9502H12.9122C13.1826 20.9502 13.404 21.1715 13.404 21.442V26.4092C13.404 26.6797 13.1826 26.901 12.9122 26.901H7.94493C7.67444 26.901 7.45312 26.6797 7.45312 26.4092V21.4174C7.45312 21.1715 7.67444 20.9502 7.94493 20.9502Z"
      fill="#E6E6E6"
    />
    <path
      d="M7.94493 28.3281H12.9122C13.1826 28.3281 13.404 28.5494 13.404 28.8199V33.7872C13.404 34.0576 13.1826 34.279 12.9122 34.279H7.94493C7.67444 34.279 7.45312 34.0576 7.45312 33.7872V28.8199C7.45312 28.5494 7.67444 28.3281 7.94493 28.3281Z"
      fill="#E6E6E6"
    />
    <path
      d="M7.94493 35.7295H12.9122C13.1826 35.7295 13.404 35.9508 13.404 36.2213V41.1885C13.404 41.459 13.1826 41.6803 12.9122 41.6803H7.94493C7.67444 41.6803 7.45312 41.459 7.45312 41.1885V36.2213C7.45312 35.9262 7.67444 35.7295 7.94493 35.7295Z"
      fill="#E6E6E6"
    />
    <path
      d="M15.3199 20.9502H20.3855C20.656 20.9502 20.8773 21.1715 20.8773 21.442V26.4092C20.8773 26.6797 20.656 26.901 20.3855 26.901H15.3199C15.0494 26.901 14.8281 26.6797 14.8281 26.4092V21.4174C14.8527 21.1715 15.0494 20.9502 15.3199 20.9502Z"
      fill="#E6E6E6"
    />
    <path
      d="M15.3199 28.3281H20.3855C20.656 28.3281 20.8773 28.5494 20.8773 28.8199V33.7872C20.8773 34.0576 20.656 34.279 20.3855 34.279H15.3199C15.0494 34.279 14.8281 34.0576 14.8281 33.7872V28.8199C14.8527 28.5494 15.0494 28.3281 15.3199 28.3281Z"
      fill="#E6E6E6"
    />
    <path
      d="M15.3199 35.7295H20.3855C20.656 35.7295 20.8773 35.9508 20.8773 36.2213V41.1885C20.8773 41.459 20.656 41.6803 20.3855 41.6803H15.3199C15.0494 41.6803 14.8281 41.459 14.8281 41.1885V36.2213C14.8527 35.9262 15.0494 35.7295 15.3199 35.7295Z"
      fill="#E6E6E6"
    />
    <path
      d="M22.8199 20.9502H27.8855C28.156 20.9502 28.3773 21.1715 28.3773 21.442V26.4092C28.3773 26.6797 28.156 26.901 27.8855 26.901H22.8199C22.5494 26.901 22.3281 26.6797 22.3281 26.4092V21.4174C22.3281 21.1715 22.5494 20.9502 22.8199 20.9502Z"
      fill="#E6E6E6"
    />
    <path
      d="M22.8199 28.3281H27.8855C28.156 28.3281 28.3773 28.5494 28.3773 28.8199V33.7872C28.3773 34.0576 28.156 34.279 27.8855 34.279H22.8199C22.5494 34.279 22.3281 34.0576 22.3281 33.7872V28.8199C22.3281 28.5494 22.5494 28.3281 22.8199 28.3281Z"
      fill="#E6E6E6"
    />
    <path
      d="M22.8199 35.7295H27.8855C28.156 35.7295 28.3773 35.9508 28.3773 36.2213V41.1885C28.3773 41.459 28.156 41.6803 27.8855 41.6803H22.8199C22.5494 41.6803 22.3281 41.459 22.3281 41.1885V36.2213C22.3281 35.9262 22.5494 35.7295 22.8199 35.7295Z"
      fill="#E6E6E6"
    />
    <path
      d="M35.7555 21.4176V26.3848C35.7555 26.6553 35.5342 26.8766 35.2637 26.8766H30.2965C30.026 26.8766 29.8047 26.6553 29.8047 26.3848V21.4176C29.8047 21.1471 30.026 20.9258 30.2965 20.9258H35.2883C35.5342 20.9504 35.7555 21.1717 35.7555 21.4176Z"
      fill="#E6E6E6"
    />
    <path
      d="M30.316 28.3281H35.2832C35.5537 28.3281 35.7751 28.5494 35.7751 28.8199V33.7872C35.7751 34.0576 35.5537 34.279 35.2832 34.279H30.316C30.0455 34.279 29.8242 34.0576 29.8242 33.7872V28.8199C29.8242 28.5494 30.0455 28.3281 30.316 28.3281Z"
      fill="#E6E6E6"
    />
    <path
      d="M30.316 35.7295H35.2832C35.5537 35.7295 35.7751 35.9508 35.7751 36.2213V41.1885C35.7751 41.459 35.5537 41.6803 35.2832 41.6803H30.316C30.0455 41.6803 29.8242 41.459 29.8242 41.1885V36.2213C29.8242 35.9262 30.0455 35.7295 30.316 35.7295Z"
      fill="#E6E6E6"
    />
    <path
      d="M0.0273438 14.4834V45.0982C0.0273438 46.6966 1.33064 47.9999 2.929 47.9999H40.3061C41.9045 47.9999 43.2078 46.6966 43.2078 45.0982V14.4834H0.0273438Z"
      fill="#F5F5F5"
    />
    <path
      d="M11.6088 0.959018V8.68034C11.6088 9.22133 11.1661 9.63936 10.6497 9.63936H7.74809C7.2071 9.63936 6.78906 9.19674 6.78906 8.68034V0.959018C6.78906 0.418034 7.23169 0 7.74809 0H10.6497C11.1661 0 11.6088 0.442624 11.6088 0.959018Z"
      fill="#50758D"
    />
    <path
      d="M7.45312 20.9502H13.404V26.8764H7.47772V20.9502H7.45312Z"
      fill="#B46CFF"
    />
    <path
      d="M29.8242 20.9502H35.7505V26.8764H29.8242V20.9502Z"
      fill="#B46CFF"
    />
    <path
      d="M14.8516 20.9502H20.9007V26.8764H14.8516V20.9502Z"
      fill="#B46CFF"
    />
    <path
      d="M7.45312 28.3281H13.404V34.2544H7.47772V28.3281H7.45312Z"
      fill="#B46CFF"
    />
    <path
      d="M29.8242 28.3281H35.7505V34.2544H29.8242V28.3281Z"
      fill="#CFCFCF"
    />
    <path
      d="M14.8516 28.3281H20.9007V34.2544H14.8516V28.3281Z"
      fill="#B46CFF"
    />
    <path
      d="M7.45312 35.7295H13.404V41.6557H7.47772V35.7295H7.45312Z"
      fill="#CFCFCF"
    />
    <path
      d="M29.8242 35.7295H35.7505V41.6557H29.8242V35.7295Z"
      fill="#CFCFCF"
    />
    <path
      d="M14.8516 35.7295H20.9007V41.6557H14.8516V35.7295Z"
      fill="#CFCFCF"
    />
    <path
      d="M22.3281 20.9502H28.3773V26.8764H22.3281V20.9502Z"
      fill="#B46CFF"
    />
    <path
      d="M22.3281 28.3281H28.3773V34.2544H22.3281V28.3281Z"
      fill="#B46CFF"
    />
    <path
      d="M22.3281 35.7295H28.3773V41.6557H22.3281V35.7295Z"
      fill="#CFCFCF"
    />
    <path
      d="M36.4447 0.959018V8.68034C36.4447 9.22133 36.0021 9.63936 35.4857 9.63936H32.584C32.043 9.63936 31.625 9.19674 31.625 8.68034V0.959018C31.625 0.418034 32.0676 0 32.584 0H35.4857C36.0021 0 36.4447 0.442624 36.4447 0.959018Z"
      fill="#50758D"
    />
    <path
      d="M0.0273438 14.4834V45.0982C0.0273438 46.6966 1.33064 47.9999 2.929 47.9999H4.01096C2.6585 47.754 1.62571 46.5736 1.62571 45.1474V16.549C1.72407 16.2539 2.01916 16.0326 2.33883 16.0326H43.2078V14.5326V14.4834H0.0273438Z"
      fill="#E6E6E6"
    />
    <path
      d="M9.29727 9.66395H7.74809C7.2071 9.66395 6.78906 9.22133 6.78906 8.70494V0.959018C6.78906 0.418034 7.23169 0 7.74809 0H8.33826V8.70494C8.33826 9.22133 8.75628 9.66395 9.29727 9.66395Z"
      fill="#2B597F"
    />
    <path
      d="M34.1086 9.66395H32.584C32.043 9.66395 31.625 9.22133 31.625 8.70494V0.959018C31.625 0.418034 32.0676 0 32.584 0H33.1742V8.70494C33.1496 9.22133 33.5922 9.66395 34.1086 9.66395Z"
      fill="#2B597F"
    />
    <path
      d="M43.1804 7.72098V15.1718H0V7.72098C0 6.61442 0.614755 5.63081 1.54918 5.1636C1.94263 4.94229 2.40984 4.81934 2.90165 4.81934H40.2788C41.3853 4.81934 42.3689 5.43409 42.8362 6.36852C43.0575 6.76196 43.1804 7.22918 43.1804 7.72098Z"
      fill="#FFA56A"
    />
    <path
      d="M11.5798 4.81934V10.2292C11.5798 10.7702 11.1372 11.1882 10.6208 11.1882H6.16996C5.62897 11.1882 5.21094 10.7456 5.21094 10.2292V4.81934H11.5798Z"
      fill="#FF6E00"
    />
    <path
      d="M36.4157 4.81934V10.2292C36.4157 10.7702 35.9731 11.1882 35.4567 11.1882H31.0059C30.4649 11.1882 30.0469 10.7456 30.0469 10.2292V4.81934H36.4157Z"
      fill="#FF6E00"
    />
    <path
      d="M1.54918 5.16406V15.1723H0V7.72144C0 6.61489 0.614755 5.63128 1.54918 5.16406Z"
      fill="#FF6E00"
    />
    <path
      d="M7.91759 20.9258H12.8848C13.1553 20.9258 13.3766 21.1471 13.3766 21.4176V26.3848C13.3766 26.6553 13.1553 26.8766 12.8848 26.8766H7.91759C7.64709 26.8766 7.42578 26.6553 7.42578 26.3848V21.4176C7.45037 21.1471 7.64709 20.9258 7.91759 20.9258Z"
      fill="#E6E6E6"
    />
    <path
      d="M7.91759 28.3281H12.8848C13.1553 28.3281 13.3766 28.5494 13.3766 28.8199V33.7872C13.3766 34.0576 13.1553 34.279 12.8848 34.279H7.91759C7.64709 34.279 7.42578 34.0576 7.42578 33.7872V28.8199C7.45037 28.5494 7.64709 28.3281 7.91759 28.3281Z"
      fill="#E6E6E6"
    />
    <path
      d="M7.91759 35.7051H12.8848C13.1553 35.7051 13.3766 35.9264 13.3766 36.1969V41.1641C13.3766 41.4346 13.1553 41.6559 12.8848 41.6559H7.91759C7.64709 41.6559 7.42578 41.4346 7.42578 41.1641V36.1969C7.45037 35.9264 7.64709 35.7051 7.91759 35.7051Z"
      fill="#E6E6E6"
    />
    <path
      d="M15.3199 20.9258H20.3855C20.656 20.9258 20.8773 21.1471 20.8773 21.4176V26.3848C20.8773 26.6553 20.656 26.8766 20.3855 26.8766H15.3199C15.0494 26.8766 14.8281 26.6553 14.8281 26.3848V21.4176C14.8281 21.1471 15.0494 20.9258 15.3199 20.9258Z"
      fill="#E6E6E6"
    />
    <path
      d="M15.3199 28.3281H20.3855C20.656 28.3281 20.8773 28.5494 20.8773 28.8199V33.7872C20.8773 34.0576 20.656 34.279 20.3855 34.279H15.3199C15.0494 34.279 14.8281 34.0576 14.8281 33.7872V28.8199C14.8281 28.5494 15.0494 28.3281 15.3199 28.3281Z"
      fill="#E6E6E6"
    />
    <path
      d="M15.3199 35.7051H20.3855C20.656 35.7051 20.8773 35.9264 20.8773 36.1969V41.1641C20.8773 41.4346 20.656 41.6559 20.3855 41.6559H15.3199C15.0494 41.6559 14.8281 41.4346 14.8281 41.1641V36.1969C14.8281 35.9264 15.0494 35.7051 15.3199 35.7051Z"
      fill="#E6E6E6"
    />
    <path
      d="M22.7926 20.9258H27.8582C28.1287 20.9258 28.35 21.1471 28.35 21.4176V26.3848C28.35 26.6553 28.1287 26.8766 27.8582 26.8766H22.7926C22.5221 26.8766 22.3008 26.6553 22.3008 26.3848V21.4176C22.3254 21.1471 22.5221 20.9258 22.7926 20.9258Z"
      fill="#E6E6E6"
    />
    <path
      d="M22.7926 28.3281H27.8582C28.1287 28.3281 28.35 28.5494 28.35 28.8199V33.7872C28.35 34.0576 28.1287 34.279 27.8582 34.279H22.7926C22.5221 34.279 22.3008 34.0576 22.3008 33.7872V28.8199C22.3254 28.5494 22.5221 28.3281 22.7926 28.3281Z"
      fill="#E6E6E6"
    />
    <path
      d="M22.7926 35.7051H27.8582C28.1287 35.7051 28.35 35.9264 28.35 36.1969V41.1641C28.35 41.4346 28.1287 41.6559 27.8582 41.6559H22.7926C22.5221 41.6559 22.3008 41.4346 22.3008 41.1641V36.1969C22.3254 35.9264 22.5221 35.7051 22.7926 35.7051Z"
      fill="#E6E6E6"
    />
    <path
      d="M35.7555 21.4176V26.3848C35.7555 26.6553 35.5342 26.8766 35.2637 26.8766H30.2965C30.026 26.8766 29.8047 26.6553 29.8047 26.3848V21.4176C29.8047 21.1471 30.026 20.9258 30.2965 20.9258H35.2637C35.5342 20.9258 35.7555 21.1471 35.7555 21.4176Z"
      fill="#E6E6E6"
    />
    <path
      d="M30.2965 28.3281H35.2637C35.5342 28.3281 35.7555 28.5494 35.7555 28.8199V33.7872C35.7555 34.0576 35.5342 34.279 35.2637 34.279H30.2965C30.026 34.279 29.8047 34.0576 29.8047 33.7872V28.8199C29.8047 28.5494 30.026 28.3281 30.2965 28.3281Z"
      fill="#E6E6E6"
    />
    <path
      d="M30.2965 35.7051H35.2637C35.5342 35.7051 35.7555 35.9264 35.7555 36.1969V41.1641C35.7555 41.4346 35.5342 41.6559 35.2637 41.6559H30.2965C30.026 41.6559 29.8047 41.4346 29.8047 41.1641V36.1969C29.8047 35.9264 30.026 35.7051 30.2965 35.7051Z"
      fill="#E6E6E6"
    />
    <path
      d="M0 14.4834V45.0982C0 46.6966 1.30328 47.9999 2.90165 47.9999H40.2788C41.8771 47.9999 43.1804 46.6966 43.1804 45.0982V14.4834H0Z"
      fill="#F5F5F5"
    />
    <path
      d="M11.5814 0.959018V8.68034C11.5814 9.22133 11.1388 9.63936 10.6224 9.63936H7.72074C7.17975 9.63936 6.76172 9.19674 6.76172 8.68034V0.959018C6.76172 0.418034 7.20434 0 7.72074 0H10.6224C11.1634 0 11.5814 0.418034 11.5814 0.959018Z"
      fill="#50758D"
    />
    <path d="M7.45312 20.9258H13.3794V26.852H7.45312V20.9258Z" fill="#FFA56A" />
    <path d="M29.8047 20.9258H35.7309V26.852H29.8047V20.9258Z" fill="#FFA56A" />
    <path d="M14.8281 20.9258H20.8773V26.852H14.8281V20.9258Z" fill="#FFA56A" />
    <path
      d="M7.45312 28.3281H13.3794V34.2544H7.45312V28.3281Z"
      fill="#FFA56A"
    />
    <path
      d="M29.8047 28.3281H35.7309V34.2544H29.8047V28.3281Z"
      fill="#CFCFCF"
    />
    <path
      d="M14.8281 28.3281H20.8773V34.2544H14.8281V28.3281Z"
      fill="#FFA56A"
    />
    <path
      d="M7.45312 35.7051H13.3794V41.6313H7.45312V35.7051Z"
      fill="#CFCFCF"
    />
    <path
      d="M29.8047 35.7051H35.7309V41.6313H29.8047V35.7051Z"
      fill="#CFCFCF"
    />
    <path
      d="M14.8281 35.7051H20.8773V41.6313H14.8281V35.7051Z"
      fill="#CFCFCF"
    />
    <path d="M22.3281 20.9258H28.3773V26.852H22.3281V20.9258Z" fill="#FFA56A" />
    <path
      d="M22.3281 28.3281H28.3773V34.2544H22.3281V28.3281Z"
      fill="#FFA56A"
    />
    <path
      d="M22.3281 35.7051H28.3773V41.6313H22.3281V35.7051Z"
      fill="#CFCFCF"
    />
    <path
      d="M36.4173 0.959018V8.68034C36.4173 9.22133 35.9747 9.63936 35.4583 9.63936H32.5567C32.0157 9.63936 31.5977 9.19674 31.5977 8.68034V0.959018C31.5977 0.418034 32.0403 0 32.5567 0H35.4583C35.9993 0 36.4173 0.418034 36.4173 0.959018Z"
      fill="#50758D"
    />
    <path
      d="M0 14.4834V45.0982C0 46.6966 1.30328 47.9999 2.90165 47.9999H3.98361C2.63115 47.754 1.59836 46.5736 1.59836 45.1474V16.5244C1.69672 16.2293 1.99181 16.0326 2.31148 16.0326H43.1804V14.5326V14.4834H0Z"
      fill="#E6E6E6"
    />
    <path
      d="M9.26992 9.63936H7.72074C7.17975 9.63936 6.76172 9.19674 6.76172 8.68034V0.959018C6.76172 0.418034 7.20434 0 7.72074 0H8.3109V8.70493C8.3109 9.22133 8.72894 9.63936 9.26992 9.63936Z"
      fill="#2B597F"
    />
    <path
      d="M34.1059 9.63936H32.5567C32.0157 9.63936 31.5977 9.19674 31.5977 8.68034V0.959018C31.5977 0.418034 32.0403 0 32.5567 0H33.1468V8.70493C33.1468 9.22133 33.5649 9.63936 34.1059 9.63936Z"
      fill="#2B597F"
    />
    <path
      d="M43.1804 7.72098V15.1718H0V7.72098C0 6.61442 0.614752 5.63081 1.54918 5.1636C1.94262 4.94229 2.40984 4.81934 2.90164 4.81934H40.2788C41.3853 4.81934 42.3689 5.43409 42.8362 6.36852C43.0821 6.76196 43.1804 7.22918 43.1804 7.72098Z"
      :fill="color"
    />
    <path
      d="M11.6072 4.81934V10.2292C11.6072 10.7702 11.1645 11.1882 10.6481 11.1882H6.19731C5.65632 11.1882 5.23828 10.7456 5.23828 10.2292V4.81934H11.6072Z"
      fill="#3F6AB3"
    />
    <path
      d="M36.4157 4.81934V10.2292C36.4157 10.7702 35.9731 11.1882 35.4567 11.1882H31.0059C30.4649 11.1882 30.0469 10.7456 30.0469 10.2292V4.81934H36.4157Z"
      fill="#3F6AB3"
    />
    <path
      d="M1.54918 5.16406V15.1723H0V7.72144C0 6.61488 0.639342 5.65587 1.54918 5.16406Z"
      fill="#3F6AB3"
    />
    <path
      d="M7.91759 20.9502H12.8848C13.1553 20.9502 13.3766 21.1715 13.3766 21.442V26.4092C13.3766 26.6797 13.1553 26.901 12.8848 26.901H7.91759C7.64709 26.901 7.42578 26.6797 7.42578 26.4092V21.4174C7.45037 21.1469 7.67168 20.9502 7.91759 20.9502Z"
      fill="#E6E6E6"
    />
    <path
      d="M7.91759 28.3281H12.8848C13.1553 28.3281 13.3766 28.5494 13.3766 28.8199V33.7872C13.3766 34.0576 13.1553 34.279 12.8848 34.279H7.91759C7.64709 34.279 7.42578 34.0576 7.42578 33.7872V28.8199C7.45037 28.5494 7.67168 28.3281 7.91759 28.3281Z"
      fill="#E6E6E6"
    />
    <path
      d="M7.91759 35.7051H12.8848C13.1553 35.7051 13.3766 35.9264 13.3766 36.1969V41.1641C13.3766 41.4346 13.1553 41.6559 12.8848 41.6559H7.91759C7.64709 41.6559 7.42578 41.4346 7.42578 41.1641V36.1969C7.45037 35.9264 7.67168 35.7051 7.91759 35.7051Z"
      fill="#E6E6E6"
    />
    <path
      d="M15.3199 20.9502H20.3855C20.656 20.9502 20.8773 21.1715 20.8773 21.442V26.4092C20.8773 26.6797 20.656 26.901 20.3855 26.901H15.3199C15.0494 26.901 14.8281 26.6797 14.8281 26.4092V21.4174C14.8281 21.1469 15.0494 20.9502 15.3199 20.9502Z"
      fill="#E6E6E6"
    />
    <path
      d="M15.3199 28.3281H20.3855C20.656 28.3281 20.8773 28.5494 20.8773 28.8199V33.7872C20.8773 34.0576 20.656 34.279 20.3855 34.279H15.3199C15.0494 34.279 14.8281 34.0576 14.8281 33.7872V28.8199C14.8281 28.5494 15.0494 28.3281 15.3199 28.3281Z"
      fill="#E6E6E6"
    />
    <path
      d="M15.3199 35.7051H20.3855C20.656 35.7051 20.8773 35.9264 20.8773 36.1969V41.1641C20.8773 41.4346 20.656 41.6559 20.3855 41.6559H15.3199C15.0494 41.6559 14.8281 41.4346 14.8281 41.1641V36.1969C14.8281 35.9264 15.0494 35.7051 15.3199 35.7051Z"
      fill="#E6E6E6"
    />
    <path
      d="M22.8199 20.9502H27.8855C28.156 20.9502 28.3773 21.1715 28.3773 21.442V26.4092C28.3773 26.6797 28.156 26.901 27.8855 26.901H22.8199C22.5494 26.901 22.3281 26.6797 22.3281 26.4092V21.4174C22.3281 21.1469 22.5494 20.9502 22.8199 20.9502Z"
      fill="#E6E6E6"
    />
    <path
      d="M22.8199 28.3281H27.8855C28.156 28.3281 28.3773 28.5494 28.3773 28.8199V33.7872C28.3773 34.0576 28.156 34.279 27.8855 34.279H22.8199C22.5494 34.279 22.3281 34.0576 22.3281 33.7872V28.8199C22.3281 28.5494 22.5494 28.3281 22.8199 28.3281Z"
      fill="#E6E6E6"
    />
    <path
      d="M22.8199 35.7051H27.8855C28.156 35.7051 28.3773 35.9264 28.3773 36.1969V41.1641C28.3773 41.4346 28.156 41.6559 27.8855 41.6559H22.8199C22.5494 41.6559 22.3281 41.4346 22.3281 41.1641V36.1969C22.3281 35.9264 22.5494 35.7051 22.8199 35.7051Z"
      fill="#E6E6E6"
    />
    <path
      d="M35.7555 21.4176V26.3848C35.7555 26.6553 35.5342 26.8766 35.2637 26.8766H30.2965C30.026 26.8766 29.8047 26.6553 29.8047 26.3848V21.4176C29.8047 21.1471 30.026 20.9258 30.2965 20.9258H35.2637C35.5342 20.9504 35.7555 21.1471 35.7555 21.4176Z"
      fill="#E6E6E6"
    />
    <path
      d="M30.2965 28.3281H35.2637C35.5342 28.3281 35.7555 28.5494 35.7555 28.8199V33.7872C35.7555 34.0576 35.5342 34.279 35.2637 34.279H30.2965C30.026 34.279 29.8047 34.0576 29.8047 33.7872V28.8199C29.8293 28.5494 30.026 28.3281 30.2965 28.3281Z"
      fill="#E6E6E6"
    />
    <path
      d="M30.2965 35.7051H35.2637C35.5342 35.7051 35.7555 35.9264 35.7555 36.1969V41.1641C35.7555 41.4346 35.5342 41.6559 35.2637 41.6559H30.2965C30.026 41.6559 29.8047 41.4346 29.8047 41.1641V36.1969C29.8293 35.9264 30.026 35.7051 30.2965 35.7051Z"
      fill="#E6E6E6"
    />
    <path
      d="M0 14.4834V45.0982C0 46.6966 1.30328 47.9999 2.90164 47.9999H40.2788C41.8771 47.9999 43.1804 46.6966 43.1804 45.0982V14.4834H0Z"
      fill="#F5F5F5"
    />
    <path
      d="M11.6088 0.959018V8.68034C11.6088 9.22133 11.1661 9.63936 10.6497 9.63936H7.74809C7.2071 9.63936 6.78906 9.19674 6.78906 8.68034V0.959018C6.78906 0.418034 7.23169 0 7.74809 0H10.6497C11.1661 0 11.6088 0.418034 11.6088 0.959018Z"
      fill="#50758D"
    />
    <path d="M7.45312 20.9502H13.3794V26.8764H7.45312V20.9502Z" :fill="color" />
    <path d="M29.8242 20.9502H35.7505V26.8764H29.8242V20.9502Z" :fill="color" />
    <path d="M14.8281 20.9502H20.8773V26.8764H14.8281V20.9502Z" :fill="color" />
    <path d="M7.45312 28.3281H13.3794V34.2544H7.45312V28.3281Z" :fill="color" />
    <path
      d="M29.8242 28.3281H35.7505V34.2544H29.8242V28.3281Z"
      fill="#CFCFCF"
    />
    <path d="M14.8281 28.3281H20.8773V34.2544H14.8281V28.3281Z" :fill="color" />
    <path
      d="M7.45312 35.7051H13.3794V41.6313H7.45312V35.7051Z"
      fill="#CFCFCF"
    />
    <path
      d="M29.8242 35.7051H35.7505V41.6313H29.8242V35.7051Z"
      fill="#CFCFCF"
    />
    <path
      d="M14.8281 35.7051H20.8773V41.6313H14.8281V35.7051Z"
      fill="#CFCFCF"
    />
    <path d="M22.3281 20.9502H28.3773V26.8764H22.3281V20.9502Z" :fill="color" />
    <path d="M22.3281 28.3281H28.3773V34.2544H22.3281V28.3281Z" :fill="color" />
    <path
      d="M22.3281 35.7051H28.3773V41.6313H22.3281V35.7051Z"
      fill="#CFCFCF"
    />
    <path
      d="M36.4173 0.959018V8.68034C36.4173 9.22133 35.9747 9.63936 35.4583 9.63936H32.5567C32.0157 9.63936 31.5977 9.19674 31.5977 8.68034V0.959018C31.5977 0.418034 32.0403 0 32.5567 0H35.4583C35.9993 0 36.4173 0.418034 36.4173 0.959018Z"
      fill="#50758D"
    />
    <path
      d="M0 14.4836V45.0984C0 46.6968 1.30328 48 2.90164 48H3.98362C2.63116 47.7541 1.59836 46.5738 1.59836 45.1476V16.5246C1.69672 16.2295 1.99181 16.0082 2.31149 16.0082H43.1804V14.5082V14.459H0V14.4836Z"
      fill="#E6E6E6"
    />
    <path
      d="M9.26992 9.66395H7.72074C7.17976 9.66395 6.76172 9.22133 6.76172 8.70493V0.959018C6.76172 0.418034 7.20435 0 7.72074 0H8.3109V8.70493C8.3109 9.22133 8.75353 9.66395 9.26992 9.66395Z"
      fill="#2B597F"
    />
    <path
      d="M34.1059 9.66395H32.5567C32.0157 9.66395 31.5977 9.22133 31.5977 8.70493V0.959018C31.5977 0.418034 32.0403 0 32.5567 0H33.1468V8.70493C33.1468 9.22133 33.5649 9.66395 34.1059 9.66395Z"
      fill="#2B597F"
    />
  </svg>
</template>

<script>
export default {
  name: "CalendarLaboratoryExam",
  props: {
    color: {
      type: String,
    },
  },
};
</script>
