<template lang="html">
  <svg
    id="Capa_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="401.999px"
    height="401.998px"
    viewBox="0 0 401.999 401.998"
    style="enable-background: new 0 0 401.999 401.998"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M237.543,301.499h-73.091c-4.95,0-9.233,1.811-12.851,5.425c-3.615,3.617-5.424,7.897-5.424,12.847v63.953
			c0,4.948,1.809,9.232,5.424,12.854c3.621,3.61,7.9,5.421,12.851,5.421h73.097c4.942,0,9.227-1.811,12.848-5.421
			c3.61-3.621,5.42-7.905,5.42-12.854v-63.953c0-4.949-1.813-9.229-5.427-12.847C246.773,303.307,242.488,301.499,237.543,301.499z"
        />
        <path
          d="M259.383,5.424C255.862,1.812,251.628,0,246.676,0h-91.359c-4.948,0-9.18,1.812-12.703,5.424
			c-3.521,3.617-5.186,7.902-4.996,12.85l7.992,219.265c0.19,4.948,2.139,9.236,5.852,12.847c3.711,3.621,8.041,5.431,12.991,5.431
			h73.097c4.942,0,9.271-1.81,12.991-5.431c3.71-3.61,5.653-7.898,5.852-12.847l7.987-219.265
			C264.578,13.326,262.905,9.045,259.383,5.424z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconOnlyExclamation",
};
</script>
