<style src="./RgContentBreadcrumb.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="breadcrumb">
    <div id="crumbs">
      <ul class="list">
        <li v-for="(step, index) in breadcrumb" :key="index" class="item">
          <a
            v-if="index < breadcrumb.length"
            :class="{
              active: isSelectedStep(step),
              'disable-step': step.disabled,
            }"
            href="#"
            class="label unselect"
            @click="goToLink(step, index)"
          >
            {{ step.name }}
          </a>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: "RgContentBreadcrumb",
  props: {
    breadcrumb: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    isSelectedStep(pStep, pIndex) {
      return pStep.step;
    },
    goToLink(pStep, pIndex) {
      this.$emit("step", pStep, pIndex);
    },
  },
};
</script>
