<style src="./HospitalizationBillingAihInfo.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="hospitalization-billing-aih-info">
    <RgValidatorForm ref="form">
      <div class="row info-container">
        <RgInputMasked
          v-model="aihInfoForm.iaf_numero_aih"
          :mask="['############-#']"
          :rules="{ fn: validateAihNumber }"
          :keyup="aihNumberKeyup"
          class="col-2"
          label="N° da AIH"
          title="Autorização de Internação Hospitalar"
          placeholder="000000000000-0"
        />

        <RgComboboxAihType
          v-model="aihInfoForm.iaf_tipo_aih"
          :rules="{ name: true, fn: validateAihType }"
          label="Tipo"
          class="col-2"
          tab-index="3"
          @input="setDataToState"
        />

        <RgInputMasked
          v-model="aihInfoForm.iaf_apresentacao"
          :mask="['BM/DDDD']"
          :tokens="tokens"
          :rules="{ required: true, fn: validatePeriod }"
          class="col-1"
          label="Apres.:"
          title="Apresentação"
        />
        <RgInputMasked
          v-model="aihInfoForm.iaf_orgao_emissor"
          :mask="['XXXXXXXXXX']"
          :rules="{ fn: validateEmiter }"
          label="Órgão Emissor"
          placeholder="Órgão Emissor"
          class="col-2"
          @blur="setDataToState"
        />

        <RgSuggestUnitRecordNumber
          ref="unitRecordNumber"
          v-model="aihInfoForm.iaf_prontuario"
          :change="setDataToState"
          :rules="{ fn: validateUnitRecord }"
          :caption-raw="recordNumberCaptionRaw"
          class="col-1"
          label="Prontuário da Unidade"
          placeholder="N°"
          @selected="unitRecordNumberSelected"
        />

        <RgInputDate
          ref="hospitaliationDate"
          v-model="aihInfoForm.iaf_data_internacao"
          :change="setDataToState"
          :rules="{ fn: validateHospitalizationDate }"
          :styles="'width: 120px;'"
          class="col-2 birth-field"
          label="Data da Internação"
          placeholder=""
        />

        <RgInputDate
          ref="exitDate"
          v-model="aihInfoForm.iaf_data_saida"
          :change="setDataToState"
          :rules="{ fn: validateHospitalizationDateExits }"
          :styles="'width: 120px;'"
          class="col-1 birth-field"
          label="Data da Saída"
          placeholder=""
        />
      </div>
    </RgValidatorForm>
  </section>
</template>
<script>
import { isEmpty } from "lodash";
import EventBus from "~common/event/bus/EventBus";
import { RgInputDate, RgInputMasked, RgValidatorForm } from "~tokio/primitive";
import RgComboboxAihType from "$billing/hospitalization-billing/common/component/combobox/rg-combobox-aih-type/RgComboboxAihType";
import RgSuggestUnitRecordNumber from "$billing/hospitalization-billing/common/component/suggest/rg-suggest-unit-record-number/RgSuggestUnitRecordNumber";
import {
  ValidateAihNumber,
  ValidatePeriodBilling,
  AlertError,
} from "../../../service/";
import {
  ValidateHospitalizationDate,
  ValidateHospitalizationDateExits,
} from "../../../service/ValidateHospitalizationAndExitDate";

export default {
  name: "HospitalizationBillingAihInfo",
  components: {
    RgValidatorForm,
    RgInputMasked,
    RgInputDate,
    RgComboboxAihType,
    RgSuggestUnitRecordNumber,
  },
  props: {
    validationTabs: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      recordNumberCaptionRaw: "ppr_numero",
      aihNumberTemp: "",
      aihInfoForm: {
        iaf_id: null,
        iaf_id_internacoes: null,
        iaf_apresentacao: null,
        iaf_numero_aih: null,
        iaf_tipo_aih: 1,
        iaf_prontuario: null,
        iaf_data_internacao: "",
        iaf_data_saida: "",
        iaf_orgao_emissor: "",
        iaf_faturado: 0,
      },
      tokens: {
        B: { pattern: /^([0-1])$/ },
        M: { pattern: /^([0-9])$/ },
        D: { pattern: /\d/ },
      },
    };
  },
  computed: {
    getPatientUnitRecordNumber() {
      return this.$store.getters[
        "Billing/HospitalizationBilling/GET_PATIENT_UNIT_RECORD_NUMBER"
      ];
    },
    aihInfoInState() {
      return Object.assign(
        {},
        this.$store.getters[
          "Billing/HospitalizationBilling/GET_HOSPITALIZATION_AIH_INFO"
        ],
      );
    },
  },
  watch: {
    getPatientUnitRecordNumber(pRecordNumber) {
      this.aihInfoForm.iaf_prontuario = pRecordNumber;
      if (!pRecordNumber) {
        AlertError("Paciente sem prontuário.");
      }
    },
    aihInfoInState(pData) {
      this.aihInfoForm = Object.assign({}, this.aihInfoForm, pData);
    },
    validationTabs(pData) {},
  },
  mounted() {
    this.aihInfoForm = this.aihInfoInState;
    // Escutando para atualizar o vuex
    EventBus.$on("updateVuex", this.setDataToState);
    EventBus.$on("resetData", this.resetData);
    this.setIssuingBody();
    this.updatePresentationDate();
  },
  methods: {
    resetData() {
      Object.assign(this.$data, this.$options.data.apply(this));
      this.$nextTick(async () => {
        this.setIssuingBody();
        this.updatePresentationDate();
      });
    },
    setPatientSearched(pValue = true) {
      this.$store.commit(
        "Billing/HospitalizationBilling/SET_PATIENT_SEARCHED",
        pValue,
      );
    },
    async aihNumberKeyup(pData, pPreviousData) {
      // #1575
      const data = pData.target.value;
      if (data !== this.aihNumberTemp) {
        this.aihNumberTemp = data;
        if (isEmpty(data)) {
          this.aihInfoForm.iaf_tipo_aih = 1;
          this.aihInfoForm.iaf_prontuario = null;
          // apaga as informações do contato
          this.$emit("patientUnitRecordNumberSelected", null);
        } else {
          let aihType = 1;
          if (data.length === 14) {
            const previousAih = await this.getPreviousAih();
            if (previousAih) {
              aihType = 5;
              this.aihInfoForm.iaf_prontuario = previousAih.iaf_prontuario;
              this.selectPatientInfo(previousAih);
              this.setPatientSearched();
            }
          }
          this.aihInfoForm.iaf_tipo_aih = aihType;
        }
        this.setDataToState();
      }
    },
    selectPatientInfo(pPreviousAih) {
      const unitHealthId = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
      this.$store.dispatch(
        "Billing/HospitalizationBilling/GET_PATIENT_INFO_BY_HOSPITALIZATION_OR_AIH",
        {
          hospitalizationBillingAihId: pPreviousAih.iaf_id,
          unitHealthId,
          ignoreId: true,
        },
      );
    },
    unitRecordNumberSelected(pValue) {
      let pesId = null;
      if (pValue && pValue.source && pValue.source.pes_id) {
        pesId = pValue.source.pes_id;
        this.setDataToState();
        this.setPatientSearched();
      }

      this.$emit("patientUnitRecordNumberSelected", pesId);
    },
    updatePresentationDate() {
      const cmp = this.$store.getters[
        "Billing/PeriodHospitalizationBilling/GET_PERIOD_HOSPITALIZATION_BILLING"
      ];
      if (!cmp) return;
      let year = parseInt(cmp.substring(0, 4));
      let month = parseInt(cmp.substring(4, 6));

      if (month === 12) {
        month = 1;
        year += 1;
      } else {
        month = month + 1;
      }

      this.aihInfoForm.iaf_apresentacao =
        month.toString().padStart(2, "0") + year.toString();
    },
    setIssuingBody() {
      this.aihInfoForm.iaf_orgao_emissor = this.$store.getters[
        "Login/GET_UNIT_HEALTH_ISSUING_BODY"
      ];
    },
    setDataToState() {
      this.sanitizeFields();
      this.$store.commit(
        "Billing/HospitalizationBilling/SET_HOSPITALIZATION_AIH_INFO",
        this.aihInfoForm,
      );
      if (
        !isEmpty(this.aihInfoForm.iaf_data_internacao) &&
        this.$refs.hospitaliationDate
      )
        this.$refs.hospitaliationDate.validate();
      if (!isEmpty(this.aihInfoForm.iaf_data_saida) && this.$refs.exitDate)
        this.$refs.exitDate.validate();
    },
    sanitizeFields() {
      this.aihInfoForm.iaf_apresentacao = this.aihInfoForm.iaf_apresentacao
        ? this.aihInfoForm.iaf_apresentacao.replace(/[^\d]+/g, "")
        : null;
    },
    tabIsValid(pErr) {
      return this.$refs.form.validate();
    },
    async getPreviousAih() {
      const hospitalizationBillingAihId = await this.$store.getters[
        "Billing/HospitalizationBilling/GET_HOSPITALIZATION_BILLING_AIH_ID"
      ];

      const variables = {
        aihNumber: this.aihInfoForm.iaf_numero_aih,
        notBillingAihId: hospitalizationBillingAihId,
      };

      const previousAih = await this.$store.dispatch(
        "Billing/HospitalizationBilling/GET_HOSPITALIZATION_AIH_INFO_BY_NUMBER",
        variables,
      );

      return previousAih;
    },
    // ============================================== VALIDATIONS
    validateAihNumber(pAih, pErrors) {
      if (this.validationTabs && isEmpty(pAih)) {
        pErrors.push("Campo obrigatório");
        return false;
      }
      return (
        ValidateAihNumber(this.aihInfoForm.iaf_numero_aih, pErrors) &&
        this.validateAihNumberAihTypeAndUnitRecord(pErrors)
      );
    },
    validatePeriod(pAih, pErrors) {
      return ValidatePeriodBilling(this.aihInfoForm.iaf_apresentacao, pErrors);
    },
    validateHospitalizationDate(pAihInfo, pErrors) {
      return ValidateHospitalizationDate(
        this.aihInfoForm.iaf_data_internacao,
        this.aihInfoForm.iaf_data_saida,
        this.validationTabs,
        pErrors,
      );
    },
    validateHospitalizationDateExits(pAihInfo, pErrors) {
      return ValidateHospitalizationDateExits(
        this.aihInfoForm.iaf_data_internacao,
        this.aihInfoForm.iaf_data_saida,
        this.aihInfoForm.iaf_apresentacao,
        this.validationTabs,
        pErrors,
      );
    },
    async validateAihNumberAihTypeAndUnitRecord(pErrors) {
      const previousAih = await this.getPreviousAih();

      if (previousAih.iaf_tipo_aih) {
        if (previousAih.iaf_tipo_aih !== 5) {
          // 5 === Continuidade
          AlertError(
            "Existe uma AIH com o mesmo número informado e o tipo da anterior não é de continuidade.",
            pErrors,
          );
        }
      }

      if (
        !this.validationTabs &&
        (isEmpty(this.aihInfoForm.iaf_numero_aih) ||
          isEmpty(this.aihInfoForm.iaf_tipo_aih) ||
          isEmpty(this.aihInfoForm.iaf_prontuario))
      )
        return true;

      if (!previousAih) return true; // não existe aih com o mesmo número

      if (this.aihInfoForm.iaf_tipo_aih !== 5) {
        // 5 === Continuidade
        return AlertError(
          "Existe uma AIH com o mesmo número informado e o Tipo da AIH não condiz.",
          pErrors,
        );
      }

      return this.validateUnitRecord(null, pErrors, previousAih);
    },
    async validateUnitRecord(pUnitRecord, pErrors, pPreviousAih = null) {
      const previousAih = pPreviousAih || (await this.getPreviousAih());

      if (this.validationTabs && isEmpty(this.aihInfoForm.iaf_prontuario)) {
        pErrors.push("Campo obrigatório");
        return false;
      }

      if (parseInt(this.aihInfoForm.iaf_prontuario) === 0) {
        return AlertError("Prontuário Inválido", pErrors);
      }

      if (
        previousAih &&
        this.aihInfoForm.iaf_prontuario !== previousAih.iaf_prontuario
      ) {
        const unitRecord = !isEmpty(previousAih.iaf_prontuario)
          ? `(Prontuário: ${previousAih.iaf_prontuario})`
          : "";
        return AlertError(
          `Existe uma AIH com o mesmo número informado e o número do prontuário não é igual. ${unitRecord}`,
          pErrors,
        );
      }

      return true;
    },
    validateAihType(pAihType, pErrors) {
      if (this.validationTabs && isEmpty(pAihType)) {
        pErrors.push("Campo obrigatório");
        return false;
      }

      return this.validateAihNumberAihTypeAndUnitRecord(pErrors);
    },
    validateEmiter(pAihType, pErrors) {
      if (this.validationTabs) {
        if (isEmpty(pAihType)) {
          pErrors.push("Campo obrigatório");
          return false;
        }
        if (pAihType.length !== 10)
          return AlertError("Campo deve conter 10 caracteres", pErrors);
        if (pAihType[0] && !pAihType[0].match(/[a-zA-Z]/i))
          return AlertError(
            "Campo está no formato incorreto (Ex: LNNNNNNNNN, sendo L=letra e N=número)",
            pErrors,
          );
      }
      return true;
    },
  },
};
</script>
