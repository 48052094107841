import AuthRequest from "~common/request/AuthRequest";
import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

const refresh = (useLegacyBackend) => {
  AuthRequest.get("/access-control/refresh-token");
  if (useLegacyBackend) {
    AuthLegacyRequest.get("/niveisacesso/renovar-sessao");
  }
};

export default (store, lottery = false) => {
  const useLegacyBackend = !!store.state.legacyToken;
  if (lottery === false) {
    return refresh(useLegacyBackend);
  }
  const seed = Math.floor(Math.random() * 100);
  if (seed > 20) {
    return refresh(useLegacyBackend);
  }
};
