import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async ({
  intIdSetor,
  intIdOcupacao,
  blnConcatSetorFuncionario,
}) => {
  return AuthLegacyRequest.post(
    "funcionarios/controller-atendente/combobox-atendentes-por-setor-ocupacao",
    {
      intIdSetor,
      intIdOcupacao,
      blnConcatSetorFuncionario,
    },
  )
    .then(({ data }) => {
      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
