import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

/*
arrFormData {
  ees_cor = "0x0"
ees_id = 0
ees_nome = "TESTE"
}
*/

export default async (context, variables) => {
  return AuthLegacyRequest.post(
    "exames2/controller-exame-escala/cadastrar",
    variables,
  )
    .then(({ data }) => {
      if (data?.dados) {
        return data.dados;
      }
      return null;
    })
    .catch((pErr) => {
      console.log("error", pErr);
      throw pErr;
    });
};
