import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query accessTypes {
    SearchHomeAccess(limit: 100) {
      rows {
        ado_id
        ado_codigo_ab
        ado_nome_acesso
      }
      count
    }
  }
`;

export default async ({ commit, state }) => {
  if (state.access_types.length > 0) {
    return;
  }
  try {
    const { data } = await GraphQLClient.query({
      query,
      fetchPolicy: "no-cache",
    });
    commit("SET_ACCESS_TYPES", data.SearchHomeAccess.rows);
  } catch (err) {
    console.log(err);
    return err.graphQLErrors;
  }
};
