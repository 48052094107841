import { toast } from "~tokio/primitive";
export default {
  name: "SurgeryAggregateBaseMixin",
  props: {
    value: {
      default: null,
    },
    surgeryId: {
      default: null,
    },
  },
  data() {
    return {
      templateListItems: [],
      lItem: {
        text: "",
        value: "",
      },
    };
  },
  watch: {
    surgeryId(pValue, pOldValue) {
      if (pValue !== pOldValue && pValue > 0) {
        this.fillList();
      } else {
        this.templateListItems = [];
      }
    },
    value(pNewvalue) {
      if (pNewvalue === null || !pNewvalue.length) {
        this.cleanFields();
        this.templateListItems = [];
      }
    },
  },
  methods: {
    seletedItem(e) {
      if (!e) {
        this.lItem.text = "";
        this.lItem.value = "";
      } else {
        this.lItem.text = e.text;
        this.lItem.value = e.value;
      }
    },
    insertItem() {
      if (this.isItensInvalid()) {
        toast.error(
          "Para adicionar um item na lista é preciso selecioná-lo no campo ao lado",
          "Campo vazio",
        );
        return false;
      }
      this.templateListItems.push({ ...this.lItem });
      this.updateModel(this.templateListItems);
      this.cleanFields();
    },
    removeItem(index) {
      this.templateListItems.splice(index, 1);
      this.updateModel(this.templateListItems);
    },
    cleanFields() {
      Object.keys(this.lItem).forEach((key) => {
        this.lItem[key] = "";
      });
    },
    fillList() {
      // throw new Error('Provide a method called fillList')
    },
    updateModel(pItem) {
      // throw new Error('Provide a method called fillList')
    },
    isItensInvalid() {
      const itemValue = this.lItem && this.lItem.value;
      return !itemValue;
    },
  },
};
