<template lang="html">
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 426.667 426.667"
    style="enable-background: new 0 0 426.667 426.667"
    xml:space="preserve"
  >
    <g>
      <path
        d="M213.333,42.667L0,384h426.667L213.333,42.667z M213.333,123.2l136.32,218.133H77.013L213.333,123.2z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "GeometryTriangle",
};
</script>
