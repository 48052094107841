import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query Ethnicity {
    SearchEthnicityBasicAttention {
      rows {
        ens_id
        ens_descricao
        ens_codigo_ab
      }
    }
  }
`;

export default async ({ commit, state }) => {
  if (state.ethnicities.length > 0) {
    return;
  }
  try {
    const { data } = await GraphQLClient.query({
      query,
      fetchPolicy: "no-cache",
    });
    commit("SET_ETHNICITY", data.SearchEthnicityBasicAttention.rows);
  } catch (err) {
    console.log(err);
    return err.graphQLErrors;
  }
};
