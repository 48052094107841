<style src="./RgComboboxContamination.scss" lang="scss"></style>
<script>
import { RgRemoteComboboxMixin } from "~tokio/primitive";
import SurgeryContaminationModel from "$surgery-center/surgery/model/SurgeryContaminationModel";
export default {
  name: "RgComboboxContamination",
  mixins: [RgRemoteComboboxMixin],
  data: () => {
    return {
      model: SurgeryContaminationModel,
    };
  },
  methods: {
    formatItem(pData) {
      return {
        value: pData.cot_id,
        text: pData.cot_nome,
      };
    },
  },
};
</script>
