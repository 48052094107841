import moment from "moment";

/**
 * Adiciona dias DESCONSIDERANDO finais de semana.
 * Ex.: Sexta + 1 = Segunda
 *
 * @param {Moment.Moment} pDate
 * @param {number} pDays
 * @return {Moment.Moment}
 */
const AddWeekdays = (pDate, pDays) => {
  let date = moment(pDate);
  while (pDays > 0) {
    date = date.add(1, "days");
    // decrease "days" only if it's a weekday.
    if (![6, 7].includes(date.isoWeekday())) {
      pDays -= 1;
    }
  }
  return date;
};

export default AddWeekdays;
