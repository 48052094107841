import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query EmployeeComplete(
    $name: String
    $cpf: String
    $cns: String
    $cnsab: String
    $occupationId: Int
    $specialtieId: Int
    $employeeRelationshipTypeId: Int
  ) {
    employeeComplete(
      pes_nome: $name
      cpf: $cpf
      cns: $cns
      fun_crs_numero_ab: $cnsab
      ocp_id: $occupationId
      esp_id: $specialtieId
      tpv_id: $employeeRelationshipTypeId
    ) {
      rows {
        fun_id
        fun_crs_numero_ab
        fun_conselho_classe
        pes_id
        vin_admissao
        vin_ativo
        pes_nome
        vin_id
        ocp_id
        ocp_nome
        esp_id
        esp_nome
        tpv_id
        tpv_nome
        person {
          pes_id
          pes_foto
          pes_nome
          pes_ativo
          pes_nascimento
          address {
            end_logradouro
            neighborhood {
              bai_nome
              city {
                state {
                  est_sigla
                }
              }
            }
          }
          cns {
            crs_numero
          }
          cpf {
            cpf_numero
          }
        }
      }
    }
  }
`;

export default async (
  { commit },
  {
    name = null,
    cpf = null,
    cns = null,
    cnsab = null,
    occupationId = null,
    specialtieId = null,
    employeeRelationshipTypeId = null,
  },
) => {
  const variables = {
    name,
    cpf: cpf ? cpf.replace(/(\.|-)+/g, "") : null,
    cns: cns ? cns.replace(/(\.)+/g, "") : null,
    cnsab: cnsab ? cnsab.replace(/(\.)+/g, "") : null,
    occupationId,
    specialtieId,
    employeeRelationshipTypeId,
  };

  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.employeeComplete.rows;
  } catch (err) {
    return err.graphQLErrors;
  }
};
