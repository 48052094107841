<style src="./RgRadioGenderBlock.scss" lang="scss" scoped></style>
<template>
  <div class="rg-radio-gender-block unselect">
    <span class="label">{{ label }}</span>
    <div class="container">
      <div
        :class="{
          active: selectedGender === 'M',
          'active-masculine': selectedGender === 'M',
        }"
        tabindex="0"
        class="radio-gender radio-masculine"
        @keyup.enter="changeGender('M')"
        @click="changeGender('M')"
      >
        Masculino
      </div>
      <div
        :class="{
          active: selectedGender === 'F',
          'active-feminine': selectedGender === 'F',
        }"
        tabindex="0"
        class="radio-gender radio-feminine"
        @keyup.enter="changeGender('F')"
        @click="changeGender('F')"
      >
        Feminino
      </div>
      <RgValidationAlert
        :alert="error"
        class="rg-input--icon rg-input--icon-error"
      />
    </div>
  </div>
</template>

<script>
import {
  RgValidatorMixin,
  RgValidationAlert,
} from "~tokio/primitive/validation";

export default {
  name: "RgRadioGenderBlock",
  components: {
    RgValidationAlert,
  },
  mixins: [RgValidatorMixin],
  props: {
    label: {
      type: String,
      default: "Sexo",
    },
    value: {
      type: String,
      default: "M",
    },
    dataId: {
      type: String,
      default: "genero",
    },
  },
  data() {
    return {
      selectedGender: "M",
    };
  },
  computed: {
    validValue() {
      return this.selectedGender;
    },
  },
  watch: {
    value(pValue) {
      this.selectedGender = pValue;
    },
  },
  methods: {
    changeGender(pGender) {
      this.selectedGender = pGender;
      this.$emit("input", pGender);
    },
  },
};
</script>
