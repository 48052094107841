import GET_INFO_USER_HELPDESK from "./get-info-user-helpdesk/GetInfoUserHelpdesk";
import SEARCH_CHANGELOG from "./search-changelog/SearchChangelog";
import SEARCH_FAVORITE_OPERATION from "./search-favorite-operation/SearchFavoriteOperation";
import SAVE_FAVORITE_OPERATION from "./save-favorite-operation/SaveFavoriteOperation";
import REMOVE_FAVORITE_OPERATION from "./remove-favorite-operation/RemoveFavoriteOperation";

export default {
  GET_INFO_USER_HELPDESK,
  SEARCH_CHANGELOG,
  SEARCH_FAVORITE_OPERATION,
  SAVE_FAVORITE_OPERATION,
  REMOVE_FAVORITE_OPERATION,
};
