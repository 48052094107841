export default {
  CLEAN_MODULE_APPOINTMENT: (state) => {
    state.Schedule.selected_employee_data = null;
    state.Schedule.selected_appointment_data = null;
    state.Schedule.filter_per_day_data = null;
    state.Schedule.selected_queue_external_appointment_data = null;
    state.Schedule.filter_per_periods_data = null;
    state.Schedule.filter_appointment_queue_data = null;
    state.Schedule.filter_appointment_effectiveness_data = null;

    state.Register.filter_appointment_scale_list = null;
    state.Register.selected_appointment_scale_list_line = null;
  },
};
