<style src="./UserList.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="user-list">
    <div v-if="!isThereUsers" class="no-user-to-show">
      <span>Não foram encontrados resultados para essa busca.</span>
    </div>
    <ul v-else>
      <li
        v-for="(item, indexItem) in mutableUserList"
        :key="indexItem"
        class="item row alinhador"
      >
        <div class="al">
          <RgEditSmall
            title="Editar"
            class="btn-margin-bottom al"
            @confirm-edit="editItem(item)"
          />
        </div>
        <div class="col-5 column-person-info">
          <span class="person-name" title="Nome do Usuário">
            <IconUser />
            {{ item.usu_nome }}
          </span>
          <span title="Login de Usuário">
            <IconLocked />
            {{ item.usu_login }}
          </span>
        </div>
        <div class="col-3 column-person-info">
          <span class="person-name" title="Estado e Municipio">
            <IconPlace />{{ item.city.state.est_sigla }} -
            {{ item.city.mun_nome }}
          </span>
        </div>
        <div class="col-3 column-person-info">
          <RgToggleRadio :value="item.usu_ativo ? 1 : 0" />
        </div>
      </li>
    </ul>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { IconUser, IconLocked, IconPlace } from "~tokio/primitive";
import RgToggleRadio from "~tokio/primitive/radio/rg-toggle-radio/RgToggleRadio";
import RgEditSmall from "~tokio/primitive/button/rg-edit-small/RgEditSmall";

export default {
  name: "UserList",
  components: {
    RgEditSmall,
    RgToggleRadio,
    IconPlace,
    IconLocked,
    IconUser,
  },
  props: {
    pagination: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showUser: false,
      mutableUserList: [],
      checkedStatus: "",
      standByTime: [],
      standByTimeInterval: null,
      itemHeight: 0,
    };
  },
  computed: {
    ...mapGetters({
      usersList: "User/UserSearch/GET_LIST",
    }),
    isThereUsers() {
      return this.mutableUserList && this.mutableUserList.length > 0;
    },
  },
  watch: {
    usersList(pValue) {
      this.mutableUserList = JSON.parse(JSON.stringify(pValue));
    },
  },
  methods: {
    editItem(item) {
      this.$router.push({ name: "user.detail", params: { userData: item } });
    },
  },
};
</script>
