import RgLogin from "../view/RgLogin";
import RgUnitHealthSelector from "../view/unit-health-selector/RgUnitHealthSelector";
import AccessControl from "../view/AccessControl";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

const LoginRoute = {
  path: "/login",
  name: "Login",
  components: {
    default: RgLogin,
  },
};

const SelectUnitHealthRoute = {
  path: "/access-control/select-unit-health",
  name: "accesscontrol.selectUnitHealth",
  components: {
    default: RgUnitHealthSelector,
    header: MainHeader,
  },
};

const AccessControlRoute = {
  path: "/access-control/access",
  name: "accesscontrol.access",
  components: {
    default: AccessControl,
    header: null,
  },
};

export { LoginRoute, AccessControlRoute, SelectUnitHealthRoute };
