import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async ({ blnParaIndigente }) => {
  return AuthLegacyRequest.post(
    "emergencias/controller-boletim/combo-box-tipos-responsavel",
    { blnParaIndigente },
  )
    .then(({ data }) => {
      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
