<template>
  <div
    :disabled="disabled"
    :class="{ disable: disabled }"
    class="rg-dropdown-pep"
  >
    <div class="button">
      <IconPep />
    </div>
    <div
      v-show="actionOptions && actionOptions.length > 0"
      :class="{ 'show-up': showUp, 'show-left': showLeft }"
      class="dropdown"
    >
      <span
        v-for="option in actionOptions"
        :key="generateIdentifier(option)"
        :data-id="generateIdentifier(option)"
        :class="{ 'disable unselect': option.disable }"
        :disabled="option.disable"
        class="item"
        @click="handleClick(item, option)"
      >
        {{ option.label }}
      </span>
    </div>
  </div>
</template>

<script>
import { IconPep } from "~tokio/primitive";

export default {
  name: "RgDropdown",
  components: {
    IconPep,
  },
  props: {
    /**
     * @type {{label: string, action: Function}[]} actionOptions
     */
    actionOptions: {
      type: Array,
      default: () => [],
      required: true,
    },
    item: {
      type: Object,
      default: () => {},
      required: true,
    },
    dataId: {
      type: String,
      default: "",
    },
    itemId: {
      type: Number,
      default: 0,
    },
    showUp: {
      type: Boolean,
      default: false,
    },
    showLeft: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    generateIdentifier(pOption) {
      return !this.itemId || this.itemId === 0
        ? pOption.label
        : `${pOption.label}-${this.itemId}`;
    },
    /**
     * @param {any} pItem
     * @param {{label: string, action: Function}[]} pOption
     */
    handleClick(pItem, pOption) {
      this.$emit(`on-${pOption.label}`, pItem);
      pOption.action(pItem);
    },
  },
};
</script>
