<script>
import { isEmpty } from "lodash";
import RgRemoteTypeaheadMixin from "$billing/common/component/selection/mixin/RgRemoteTypeaheadMixin";
import SearchHospitalizationBedSpecialty from "./action/SearchHospitalizationBedSpecialty";

export default {
  name: "RgTypeaheadHospitalizationBedSpecialty",
  mixins: [RgRemoteTypeaheadMixin],
  props: {
    label: {
      type: String,
      default: "Especificação do Leito:",
    },
    min: {
      type: String,
      default: "2",
    },
  },
  data() {
    return {
      filter: () => {
        return {
          searchQuery: this.inputValue,
        };
      },
    };
  },
  watch: {
    inputValue(pData) {
      if (isEmpty(pData)) this.validate();
    },
  },
  methods: {
    doSearch() {
      const searchQuery = this.inputValue;
      if (Number.isInteger(+this.inputValue)) {
        return SearchHospitalizationBedSpecialty({ ils_codigo: searchQuery });
      } else {
        return SearchHospitalizationBedSpecialty({ ils_nome: searchQuery });
      }
    },
    doFind(pValue) {
      return SearchHospitalizationBedSpecialty(
        { ils_codigo: pValue },
        { findOne: true },
      );
    },
    formatItem(item) {
      return {
        value: item ? item.ils_codigo : "",
        text: item ? `${item.ils_codigo} - ${item.ils_nome}` : "",
      };
    },
  },
};
</script>
