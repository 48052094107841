<template lang="html">
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <path
      style="fill: #e1c3a0"
      d="M406.069,512H105.931c-19.501,0-35.31-15.809-35.31-35.31V70.621c0-19.501,15.809-35.31,35.31-35.31
	h300.138c19.501,0,35.31,15.809,35.31,35.31V476.69C441.379,496.191,425.57,512,406.069,512z"
    />
    <path
      style="fill: #eff2fa"
      d="M406.069,485.517H105.931c-4.875,0-8.828-3.953-8.828-8.828V70.621c0-4.875,3.953-8.828,8.828-8.828
	h300.138c4.875,0,8.828,3.953,8.828,8.828V476.69C414.897,481.565,410.944,485.517,406.069,485.517z"
    />
    <path
      style="fill: #c7cfe2"
      d="M308.966,35.31h-26.483v-8.828C282.483,11.857,270.626,0,256,0s-26.483,11.857-26.483,26.483v8.828
	h-26.483c-9.75,0-17.655,7.904-17.655,17.655v17.655h141.241V52.966C326.621,43.214,318.716,35.31,308.966,35.31z M256,35.31
	c-4.875,0-8.828-3.953-8.828-8.828s3.953-8.828,8.828-8.828s8.828,3.953,8.828,8.828S260.875,35.31,256,35.31z"
    />
    <path
      style="fill: #afb9d2"
      d="M326.621,79.448H185.379c-4.875,0-8.828-3.953-8.828-8.828l0,0c0-4.875,3.953-8.828,8.828-8.828
	h141.241c4.875,0,8.828,3.953,8.828,8.828l0,0C335.448,75.496,331.496,79.448,326.621,79.448z"
    />
    <path
      style="fill: #82889d"
      d="M167.724,203.034c-2.259,0-4.518-0.862-6.241-2.586L135,173.965c-3.448-3.448-3.448-9.035,0-12.483
	c3.448-3.448,9.035-3.448,12.483,0l20.241,20.242l37.897-37.897c3.448-3.448,9.035-3.448,12.483,0c3.448,3.448,3.448,9.035,0,12.483
	l-44.138,44.138C172.242,202.173,169.983,203.034,167.724,203.034z"
    />
    <g style="opacity: 0.97">
      <path
        style="fill: #afb9d2"
        d="M317.793,167.724h-70.621c-4.875,0-8.828-3.953-8.828-8.828l0,0c0-4.875,3.953-8.828,8.828-8.828
		h70.621c4.875,0,8.828,3.953,8.828,8.828l0,0C326.621,163.772,322.668,167.724,317.793,167.724z"
      />
    </g>
    <g style="opacity: 0.97">
      <path
        style="fill: #c7cfe2"
        d="M361.931,203.034H247.172c-4.875,0-8.828-3.953-8.828-8.828l0,0c0-4.875,3.953-8.828,8.828-8.828
		h114.759c4.875,0,8.828,3.953,8.828,8.828l0,0C370.759,199.082,366.806,203.034,361.931,203.034z"
      />
    </g>
    <path
      style="fill: #82889d"
      d="M167.724,414.897c-2.259,0-4.518-0.862-6.241-2.586L135,385.827c-3.448-3.448-3.448-9.035,0-12.483
	c3.448-3.448,9.035-3.448,12.483,0l20.241,20.242l37.897-37.897c3.448-3.448,9.035-3.448,12.483,0c3.448,3.448,3.448,9.035,0,12.483
	l-44.138,44.138C172.242,414.035,169.983,414.897,167.724,414.897z"
    />
    <g style="opacity: 0.97">
      <path
        style="fill: #afb9d2"
        d="M317.793,379.586h-70.621c-4.875,0-8.828-3.953-8.828-8.828l0,0c0-4.875,3.953-8.828,8.828-8.828
		h70.621c4.875,0,8.828,3.953,8.828,8.828l0,0C326.621,375.634,322.668,379.586,317.793,379.586z"
      />
    </g>
    <g style="opacity: 0.97">
      <path
        style="fill: #c7cfe2"
        d="M361.931,414.897H247.172c-4.875,0-8.828-3.953-8.828-8.828l0,0c0-4.875,3.953-8.828,8.828-8.828
		h114.759c4.875,0,8.828,3.953,8.828,8.828l0,0C370.759,410.944,366.806,414.897,361.931,414.897z"
      />
    </g>
    <path
      style="fill: #82889d"
      d="M167.724,308.966c-2.259,0-4.518-0.862-6.241-2.586L135,279.896c-3.448-3.448-3.448-9.035,0-12.483
	c3.448-3.448,9.035-3.448,12.483,0l20.241,20.242l37.897-37.897c3.448-3.448,9.035-3.448,12.483,0c3.448,3.448,3.448,9.035,0,12.483
	l-44.138,44.138C172.242,308.104,169.983,308.966,167.724,308.966z"
    />
    <g style="opacity: 0.97">
      <path
        style="fill: #afb9d2"
        d="M317.793,273.655h-70.621c-4.875,0-8.828-3.953-8.828-8.828l0,0c0-4.875,3.953-8.828,8.828-8.828
		h70.621c4.875,0,8.828,3.953,8.828,8.828l0,0C326.621,269.703,322.668,273.655,317.793,273.655z"
      />
    </g>
    <g style="opacity: 0.97">
      <path
        style="fill: #c7cfe2"
        d="M361.931,308.966H247.172c-4.875,0-8.828-3.953-8.828-8.828l0,0c0-4.875,3.953-8.828,8.828-8.828
		h114.759c4.875,0,8.828,3.953,8.828,8.828l0,0C370.759,305.013,366.806,308.966,361.931,308.966z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: "CheckMark",
};
</script>
