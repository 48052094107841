<template>
  <div class="history-exam-details">
    <div v-if="!hasExternalScheduleInfo" class="basic">
      <span class="label"
        >Profissional: <strong>{{ getValue("employee") }}</strong>
      </span>
      <span class="label"
        >Ocupação: <strong>{{ getValue("occupation") }}</strong>
      </span>
      <span class="label"
        >Procedimento: <strong>{{ getValue("procedures") }}</strong>
      </span>
      <span class="label"
        >Subprocedimento: <strong>{{ getValue("sub_procedures") }}</strong>
      </span>
      <span class="label"
        >Tipo Escala: <strong>{{ getValue("escale") }}</strong>
      </span>
      <span class="label"
        >Status: <strong>{{ getValue("status") }}</strong>
      </span>
    </div>
    <div v-if="hasRegulationInfo" class="regulation">
      <h4 class="title">Regulação</h4>

      <span v-if="hasRegulationInfo" class="label">
        Procedimento: <strong>{{ getValue("procedures") }}</strong>
      </span>
      <span class="label"
        >Prioridade: <strong>{{ getValue("priority") }}</strong>
      </span>
      <span class="label"
        >Unidade Solicitante: <strong>{{ getValue("unit_health") }}</strong>
      </span>
      <span class="label"
        >Solicitante: <strong>{{ getValue("requester") }}</strong>
      </span>
      <span class="label"
        >Inserido em: <strong>{{ getValue("inserted_at") }}</strong>
      </span>

      <span v-if="!hasExternalScheduleInfo" class="label">
        Agendado em: <strong>{{ getValue("scheduled_at") }}</strong>
      </span>
    </div>

    <div v-if="hasExternalScheduleInfo" class="externalschedule">
      <h4 class="title">Agendamento Externo</h4>

      <span class="label"
        >Data: <strong>{{ getValue("external_scheduling_date") }}</strong>
      </span>
      <span class="label"
        >Local: <strong>{{ getValue("place") }}</strong>
      </span>
      <span class="label"
        >Observação: <strong>{{ getValue("observation") }}</strong>
      </span>
      <span class="label"
        >Subprocedimentos: <strong>{{ getValue("observation") }}</strong>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "HistoryExamDetails",
  components: {},
  props: {
    details: {
      type: Object,
      default: () => {},
    },
    hasRegulationInfo: {
      type: Boolean,
      default: false,
    },
    hasExternalScheduleInfo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    getValue(pName) {
      const hasValue = this.details && this.details[pName];
      return hasValue ? this.details[pName] : "Não informado";
    },
  },
};
</script>
