<style src="./PatientInfoPanel.scss" lang="scss" scoped></style>
<template>
  <div class="patient-info-panel">
    <div class="patient-box">
      <div class="patient-name">
        <IconUser /><span>{{ patientInfo.bof_nome }}</span>
      </div>
      <div class="patient-cns">
        <IconCard /><span>{{ patientInfo.bof_cns | Cns }}</span>
        <IconCake /><span>{{ patientInfo.bof_data_nascimento }}</span>
      </div>
    </div>

    <div class="bulletin-number">
      <span class="title">N° Boletim</span>
      <span class="number">{{ bulletinNumber | zeroFill }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { IconUser, IconCard, IconCake } from "~tokio/primitive/icon";

export default {
  name: "PatientInfoPanel",
  components: {
    IconUser,
    IconCard,
    IconCake,
  },
  filters: {
    zeroFill(pValue) {
      return pValue.toString().padStart(4, "0");
    },
    Cns(pValue) {
      if (!pValue) return "CNS NÃO CADASTRADO";
      return pValue.replace(/(\d{3})(\d{4})(\d{4})(\d{4})/gi, "$1 $2 $3 $4");
    },
  },
  computed: {
    ...mapGetters({
      patientInfo: "Billing/Billing/GET_ALL_INFO",
      bulletinNumber: "Billing/Bulletin/GET_SELECTED_BULLETIN_NUMBER",
    }),
  },
};
</script>
