<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import { SearchHospitalizationSocialSecurityBond } from "./action";

export default {
  name: "RgComboboxHospitalizationSocialSecurityBond",
  mixins: [RgComboboxMixin],
  data: () => {
    return {};
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];
      const data = await SearchHospitalizationSocialSecurityBond({});
      data.forEach((item) => {
        this.mData.push({
          value: item.ivp_codigo,
          text: `${item.ivp_codigo} - ${item.ivp_descricao}`,
        });
      });
    },
  },
};
</script>
