import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query($name: String, $id: Int, $limit: Int) {
    reasonsDisableEmployee(limit: $limit, mif_nome: $name, mif_id: $id) {
      rows {
        mif_id
        mif_nome
      }
      count
    }
  }
`;

export default async (store, pData = {}) => {
  const criterias = pData.criterias || {};
  const variables = {
    id: criterias.mif_id || undefined,
    name: criterias.mif_id || undefined,
  };

  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.reasonsDisableEmployee.rows;
  } catch (err) {
    return err.graphQLErrors;
  }
};
