import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query Employee($pesId: Int) {
    employees(fun_id_pessoas: $pesId) {
      rows {
        fun_id
        person {
          pes_id
          pes_nome
        }
      }
    }
  }
`;

export default async (store, { pesId = null }) => {
  const variables = {
    pesId,
  };

  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.employees.rows[0];
  } catch (err) {
    return Promise.reject(err.grapQLErrors);
  }
};
