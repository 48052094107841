import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation($iapId: Int) {
    DeleteHospitalizationAihProcedures(iap_id: $iapId) {
      iap_id
    }
  }
`;

export default async ({ rootState }, pIapId) => {
  const variables = {
    iapId: pIapId,
  };
  try {
    const { data } = await GraphQLClient.mutate({
      mutation,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.DeleteHospitalizationAihProcedures;
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors);
  }
};
