<template lang="html">
  <svg
    width="88"
    height="87"
    viewBox="0 0 88 87"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.69817 48.0291V39.2672C7.69817 35.967 10.385 33.2802 13.6885 33.2768L56.8274 33.2768V21.6213C56.8274 20.1712 57.7015 18.8565 59.0432 18.3009C60.385 17.7452 61.9368 18.0569 62.9634 19.0835L84.9868 41.107C86.3896 42.5097 86.3896 44.7866 84.9868 46.1893L62.9634 68.2127C61.9368 69.2393 60.3884 69.5477 59.0466 68.992C57.7049 68.4363 56.8273 67.1319 56.8307 65.6716L56.8273 54.0195L13.6885 54.0195C10.3986 54.0161 7.70155 51.3462 7.69817 48.0291Z"
    />
  </svg>
</template>

<script>
export default {
  name: "ArrowRight",
};
</script>
