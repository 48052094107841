<template lang="html">
  <svg
    width="7.351mm"
    height="7.351mm"
    version="1.1"
    viewBox="0 0 7.351 7.351"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="matrix(.013359 0 0 .013359 .27811 .25227)">
      <path
        d="m23.504 198.37 174.86-174.86c31.242-31.242 81.898-31.242 113.14 0l174.86 174.86c31.242 31.242 31.242 81.898 0 113.14l-174.86 174.86c-31.242 31.242-81.898 31.242-113.14 0l-174.86-174.86c-31.242-31.242-31.242-81.898 0-113.14z"
        fill="#ffda6b"
      />
      <g fill="#fff">
        <path
          d="m254.93 142.94c8.8359 0 16 7.1641 16 16v128c0 8.8359-7.1641 16-16 16-8.8359 0-16-7.1641-16-16v-128c0-8.8359 7.1641-16 16-16z"
        />
        <path d="m238.93 334.94h32v32h-32z" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconAlarmBioslab",
};
</script>
