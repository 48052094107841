import SEARCH_UNIT_HEALTH from "./unit-health/SearchUnitHealth";
import REGISTER_UNIT_HEALTH from "./unit-health/RegisterUnitHealth";
import EDIT_UNIT_HEALTH from "./unit-health/EditUnitHealth";
import LOAD_UNIT_HEALTH from "./unit-health/LoadUnitHealth";
import REMOVE_UNIT_HEALTH from "./unit-health/RemoveUnitHealth";

import SEARCH_SECTOR from "./sector/SearchSector";
import REGISTER_SECTOR from "./sector/RegisterSector";
import EDIT_SECTOR from "./sector/EditSector";
import LOAD_SECTOR from "./sector/LoadSector";
import REMOVE_SECTOR from "./sector/RemoveSector";

import SEARCH_PLACE from "./place/SearchPlace";
import REGISTER_PLACE from "./place/RegisterPlace";
import EDIT_PLACE from "./place/EditPlace";
import LOAD_PLACE from "./place/LoadPlace";
import REMOVE_PLACE from "./place/RemovePlace";
import MAP_PLACE_PEP from "./place/MapPlacePep";

export default {
  SEARCH_UNIT_HEALTH,
  REGISTER_UNIT_HEALTH,
  EDIT_UNIT_HEALTH,
  LOAD_UNIT_HEALTH,
  REMOVE_UNIT_HEALTH,
  SEARCH_SECTOR,
  REGISTER_SECTOR,
  EDIT_SECTOR,
  LOAD_SECTOR,
  REMOVE_SECTOR,
  SEARCH_PLACE,
  REGISTER_PLACE,
  EDIT_PLACE,
  LOAD_PLACE,
  REMOVE_PLACE,
  MAP_PLACE_PEP,
};
