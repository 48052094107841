import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query homeTypes {
    SearchHomeTypes(limit: 100) {
      rows {
        tdo_id
        tdo_codigo_ab
        tdo_nome_domicilio
      }
      count
    }
  }
`;

export default async ({ commit, state }) => {
  if (state.home_types.length > 0) {
    return;
  }
  try {
    const { data } = await GraphQLClient.query({
      query,
      fetchPolicy: "no-cache",
    });
    commit("SET_HOME_TYPES", data.SearchHomeTypes.rows);
  } catch (err) {
    console.log(err);
    return err.graphQLErrors;
  }
};
