<style src="./RgRadioExamStatusLaboratory.scss" lang="scss" scoped></style>
<template lang="html">
  <fieldset class="rg-radio-exam-status-laboratory">
    <span class="label">Situação do Pedido</span>
    <div class="container">
      <div
        :class="{
          'active-requested': selectedStatus.includes(
            EXAM_STATUS_LABORATORY.SOLICITADO,
          ),
        }"
        data-id="solicitado"
        class="radio show-requested"
        @click="toggleStatus(EXAM_STATUS_LABORATORY.SOLICITADO)"
      >
        Solicitado
      </div>
      <div
        :class="{
          'active-scheduled': selectedStatus.includes(
            EXAM_STATUS_LABORATORY.AGENDADO,
          ),
        }"
        data-id="agendado"
        class="radio show-scheduled"
        @click="toggleStatus(EXAM_STATUS_LABORATORY.AGENDADO)"
      >
        Agendado
      </div>
      <div
        :class="{
          'active-pending': selectedStatus.includes(
            EXAM_STATUS_LABORATORY.PENDENTE,
          ),
        }"
        data-id="pendente"
        class="radio show-pending"
        @click="toggleStatus(EXAM_STATUS_LABORATORY.PENDENTE)"
      >
        Pendente
      </div>
      <div
        :class="{
          'active-partially-attended': selectedStatus.includes(
            EXAM_STATUS_LABORATORY.PARCIALMENTE_ATENDIDO,
          ),
        }"
        data-id="parcialmente-atendido"
        class="radio show-partially-attended"
        @click="toggleStatus(EXAM_STATUS_LABORATORY.PARCIALMENTE_ATENDIDO)"
      >
        Parcialmente Atendido
      </div>
      <div
        :class="{
          'active-attended': selectedStatus.includes(
            EXAM_STATUS_LABORATORY.ATENDIDO,
          ),
        }"
        data-id="atendido"
        class="radio show-attended"
        @click="toggleStatus(EXAM_STATUS_LABORATORY.ATENDIDO)"
      >
        Atendido
      </div>
    </div>
  </fieldset>
</template>

<script>
import EXAM_STATUS_LABORATORY from "./GetExamStatusLaboratory";

export default {
  name: "RgRadioExamStatusLaboratory",
  props: {
    value: {
      default: null,
    },
  },
  data() {
    return {
      selectedStatus: [1, 2, 3, 4, 5],
    };
  },
  computed: {
    EXAM_STATUS_LABORATORY() {
      return { ...EXAM_STATUS_LABORATORY };
    },
  },
  watch: {
    value(pValue) {
      this.selectedStatus = pValue;
    },
  },
  mounted() {
    this.selectedStatus = this.value || [1, 2, 3, 4, 5];
  },
  methods: {
    toggleStatus(pItem) {
      if (this.selectedStatus.includes(pItem)) {
        this.selectedStatus.splice(this.selectedStatus.indexOf(pItem), 1);
      } else {
        this.selectedStatus.push(pItem);
      }
      this.$emit("input", this.selectedStatus);
    },
  },
};
</script>
