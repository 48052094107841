<script>
import { RgComboboxMixin } from "~tokio/primitive";

export default {
  name: "RgComboboxExamSyncType",
  mixins: [RgComboboxMixin],
  props: {
    label: {
      type: String,
      default: "Tipo de Sincronização",
    },
    defaultText: {
      type: String,
      default: "Selecione",
    },
    emptyDefaultText: {
      type: String,
      default: "Nenhum Item para Selecionar",
    },
  },
  data() {
    return {
      mData: [
        { text: "Efetivação de Exames", value: "EFFECTIVED" },
        { text: "Exames Laboratoriais", value: "LABORATORY" },
      ],
    };
  },
};
</script>
