<style src="./RgFormPanelItem.scss" lang="scss" scoped></style>
<template lang="html">
  <li class="rg-form-panel-item" @click="changeStep">
    <div class="step-content">
      <span v-if="index !== ''" class="label-index">{{ index }}</span>
      <span class="panel-label">
        <slot />
      </span>
      <div class="guide-line">
        <div class="line" />
        <div class="icon">
          <slot name="icon" />
        </div>
        <div class="line" />
      </div>
    </div>
  </li>
</template>

<script>
export default {
  name: "RgFormPanel",
  props: {
    index: {
      default: "",
    },
  },
  methods: {
    changeStep() {
      this.$emit("change-step");
    },
  },
};
</script>
