<template>
  <Modulebox
    :granted="hasAllPermissions"
    title="Objetivos de Consulta"
    class="appointment-objective"
  >
    <form
      ref="element"
      class="form-container"
      @submit.stop.prevent="saveObjective"
    >
      <RgValidatorForm ref="validator">
        <FormBase title="Objetivo" class="register-form">
          <div class="form">
            <div class="inputs">
              <RgInput
                ref="objective"
                v-model="form.objective"
                :rules="{ required: true }"
                :disabled="disableOnEditImported"
                :class="{
                  disable: disableOnEditImported,
                }"
                :maxlength="255"
                label="Objetivo"
                placeholder="Digite o nome do novo objetivo"
              />

              <RgComboboxUnithealth
                ref="unitHealth"
                v-model="form.unitHealth"
                :rules="{ required: true }"
                :disabled="this.edit || !this.canInclude"
                :class="{ disable: this.edit || !this.canInclude }"
                permission="consulta.objetivoConsulta"
                label="Unidade de Saúde"
              />

              <RgComboboxHealthPlan
                ref="healthInsurance"
                v-model="form.healthInsurance"
                :rules="{ required: true }"
                :disabled="!canInclude && !disableWhenEditing"
                :class="{ disable: !canInclude && !disableWhenEditing }"
                label="Convênio"
              />

              <RgInput
                ref="code"
                v-model="form.code"
                :rules="{ required: true }"
                :disabled="disableOnEditImported"
                :class="{
                  disable: disableOnEditImported,
                }"
                :maxlength="10"
                label="Código"
                placeholder="000000000000"
              />

              <RgInput
                ref="cost"
                v-model="form.cost"
                :rules="{ required: true, fn: validateCost }"
                :disabled="!canInclude && !disableWhenEditing"
                :class="{ disable: !canInclude && !disableWhenEditing }"
                :maxlength="12"
                label="Custo"
                placeholder="000000,00"
              />
            </div>

            <div class="buttons">
              <RgCleanButton
                v-if="!enableSaveEditButton || !selectedRow.tcu_id"
                :permission="canInclude"
                @click="cleanForm"
              />
              <RgAddButton
                v-if="!enableSaveEditButton || !selectedRow.tcu_id"
                :permission="canInclude"
                large
                @click="saveObjective"
              />
              <RgCancelButton v-if="edit" medium @click="cancelEdit" />
              <RgSaveButton
                v-if="edit"
                ref="save"
                :permission="canEdit"
                large
                @click="saveEditObjective"
              />
            </div>
          </div>

          <div class="separator" />

          <div class="import">
            <span class="info"
              ><strong
                >Incluir objetivos da base de dados existente
                <i>(Tabela Unificada ou TUSS)</i>:</strong
              ></span
            >
            <LargeButton
              v-shortkey="['enter']"
              :label="'Importar'"
              :backgroundColor="'#1E88A9'"
              :permission="canInclude"
              class="import-button"
              @click="openModalImport"
              @shortkey.native="
                {
                }
              "
            >
              <div slot="icon" class="icon">
                <IconDownloadArrow />
              </div>
            </LargeButton>
          </div>
        </FormBase>

        <FormBase title="Objetivos Cadastrados" class="table-form">
          <div class="filter">
            <div class="inputs">
              <RgComboboxUnithealth
                ref="searchUnitHealth"
                v-model="search.unitHealth"
                label="Unidade de Saúde"
                :rules="{ required: true }"
              />
              <RgInput
                v-model="search.objective"
                label="Objetivo"
                placeholder="Digite o nome do objetivo"
              />
              <RgInput
                v-model="search.code"
                label="Código"
                placeholder="0000000000000"
              />
              <div class="buttons">
                <RgCleanButton :permission="canShow" @click="cleanFilter" />
                <RgSearchButton
                  ref="search"
                  :permission="canShow"
                  @submit="searchObjectives"
                />
              </div>
            </div>
            <RgEditButton
              large
              class="edit"
              :class="{
                disabled: disableEditButton || disableWhenEditing,
              }"
              :disabled="disableEditButton || disableWhenEditing"
              :permission="canEdit"
              @click="editObjective"
            />
          </div>

          <div
            v-if="showObjectiveList"
            class="table"
            :disabled="disableWhenEditing"
            :class="{
              disable: disableWhenEditing,
            }"
          >
            <RgTable :columns="COLUMNS" class="rg-table">
              <tr
                v-for="item in objectiveList"
                :key="item.tcu_id"
                slot="rows"
                class="tr"
                :class="{
                  selected: selectedRow.tcu_id === item.tcu_id,
                }"
                @click="selectRow(item)"
              >
                <td class="objective">
                  <span :title="item.tcu_novo_tipo_consulta" class="ellipsis">
                    {{ item.tcu_novo_tipo_consulta }}
                  </span>
                </td>
                <td>
                  {{ item.pls_nome }}
                </td>
                <td>
                  {{ item.tcu_novo_codigo_procedimento }}
                </td>
                <td>
                  {{ item.tcu_novo_custo }}
                </td>
                <td class="toggle">
                  <RgToggleButton
                    v-model="item.tcu_ativo"
                    v-bind="propsToggleButton"
                    class="toggle"
                    :disabled="
                      (item.tcu_ativo && !canInactive) ||
                      (!item.tcu_ativo && !canInclude) ||
                      item.disable
                    "
                    @input="(toogle) => changeToggle(item, toogle)"
                  />
                </td>
              </tr>
            </RgTable>
          </div>

          <div
            v-else-if="
              permission.showObjective &&
              (!objectiveList || objectiveList.length < 1)
            "
            class="empty-result"
          >
            <IconEmpty />
            <span>Não foram encontrados resultados para essa busca</span>
          </div>

          <RgList
            v-show="objectiveList && objectiveList.length > 0"
            ref="rgList"
            v-model="objectiveList"
            :search-function="searchList"
            :build-filter="generateFilter"
            :register-per-page="10"
            :max-register="10"
            :disabled="disableWhenEditing"
            :class="{
              disable: disableWhenEditing,
            }"
            backendLegacy
            class="list"
          >
          </RgList>
        </FormBase>
      </RgValidatorForm>
    </form>

    <ModalImportObjective
      :show="modalImport"
      @research="searchObjectives"
      @close="closeModalImport"
    />
  </Modulebox>
</template>

<script>
import { mapGetters } from "vuex";
import { Modulebox } from "~tokio/foundation";
import {
  RgValidatorForm,
  RgInput,
  RgCleanButton,
  RgAddButton,
  RgSearchButton,
  RgSaveButton,
  RgEditButton,
  RgComboboxUnithealth,
  LargeButton,
  IconDownloadArrow,
  IconEmpty,
  RgToggleButton,
  RgCancelButton,
} from "~tokio/primitive";

import FormBase from "~tokio/foundation/form-base/FormBase";
import RgTable from "~tokio/foundation/rg-table/RgTable";
import RgList from "~tokio/foundation/rg-list/RgList";

import RgComboboxHealthPlan from "$appointment/submodules/register/component/combobox/rg-combobox-health-plan/RgComboboxHealthPlan";
import ModalImportObjective from "$appointment/submodules/register/component/modal/ModalImportObjective";

export default {
  name: "AppointmentObjective",
  components: {
    Modulebox,
    FormBase,
    RgInput,
    RgValidatorForm,
    RgCleanButton,
    RgAddButton,
    RgSearchButton,
    RgSaveButton,
    RgEditButton,
    LargeButton,
    RgComboboxUnithealth,
    RgComboboxHealthPlan,
    ModalImportObjective,
    RgTable,
    RgToggleButton,
    RgCancelButton,
    IconEmpty,
    IconDownloadArrow,
    RgList,
  },

  data() {
    return {
      objectiveList: [],
      selectedRow: {},
      modalImport: false,
      enableSaveEditButton: false,
      edit: false,
      hasObjectives: null,
      form: {
        objective: null,
        unitHealth: null,
        healthInsurance: null,
        code: null,
        cost: null,
      },
      search: {
        unitHealth: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        objective: null,
        code: null,
      },
    };
  },

  computed: {
    ...mapGetters({
      unitHealthId: "Login/GET_UNIT_HEALTH_ID",
    }),

    permission() {
      return {
        addObjective: !!this.$Permissions.global.has(
          "consulta.objetivoConsulta.incluir",
          this.unitHealthId,
        ),
        deleteObjective: !!this.$Permissions.global.has(
          "consulta.objetivoConsulta.excluir",
          this.unitHealthId,
        ),
        showObjective: !!this.$Permissions.global.has(
          "consulta.objetivoConsulta.exibir",
          this.unitHealthId,
        ),
        editObjective: !!this.$Permissions.global.has(
          "consulta.objetivoConsulta.alterar",
          this.unitHealthId,
        ),
      };
    },

    hasAllPermissions() {
      return (
        this.permission.addObjective ||
        this.permission.deleteObjective ||
        this.permission.showObjective ||
        this.permission.editObjective
      );
    },

    canInclude() {
      return this.permission.addObjective;
    },

    canInactive() {
      return this.permission.deleteObjective;
    },

    canEdit() {
      return this.permission.editObjective;
    },

    canShow() {
      return (
        this.permission.addObjective ||
        this.permission.editObjective ||
        this.permission.deleteObjective ||
        this.permission.showObjective
      );
    },

    isImported() {
      return (
        this.selectedRow?.tcu_id_tabelas_unificadas !== "1" &&
        this.selectedRow?.tcu_id_tuss !== "1"
      );
    },

    showObjectiveList() {
      return this.canShow && this.objectiveList.length > 0;
    },

    disableEditButton() {
      return (
        !this.selectedRow ||
        !this.selectedRow.tcu_id ||
        !this.selectedRow.tcu_ativo
      );
    },

    disableWhenEditing() {
      return this.edit;
    },

    disableOnEditImported() {
      return this.edit ? this.isImported : !this.canInclude;
    },

    propsToggleButton() {
      return {
        truthyText: "ATIVO",
        falsyText: "INATIVO",
        width: 30,
        height: 10,
        fontSize: 10,
        center: true,
        externalLabel: true,
        valueSync: true,
      };
    },
  },

  watch: {
    "search.unitHealth"(pValue, pPreviously) {
      if (pValue !== pPreviously) {
        this.cleanForm();
        this.selectedRow = {};
        this.enableSaveEditButton = false;
      }
    },
  },

  async mounted() {
    if (this.canShow) {
      this.searchObjectives();
    }
  },

  created() {
    this.COLUMNS = [
      { name: "Objetivo", key: "tcu_novo_tipo_consulta" },
      { name: "Convênio", key: "pls_nome" },
      { name: "Código", key: "tcu_novo_codigo_procedimento" },
      { name: "Custo", key: "tcu_novo_custo" },
      { name: "Situação", key: "tcu_ativo" },
    ];
  },

  methods: {
    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    selectRow(pItem) {
      if (pItem.tcu_id === this.selectedRow.tcu_id) {
        this.cleanForm();
        this.edit = false;
        this.enableSaveEditButton = false;
        this.selectedRow = {};
        return;
      }

      if (!this.edit) {
        this.selectedRow = pItem;
      }
    },

    formatDataSave() {
      const variables = {
        tcu_id_planos_saude: Number(this.form.healthInsurance),
        tcu_id_unidades_saude: Number(this.form.unitHealth),
        tcu_novo_codigo_procedimento: this.form.code,
        tcu_novo_custo: this.form.cost,
        tcu_novo_tipo_consulta: this.form.objective,
      };
      return variables;
    },

    async saveObjective() {
      try {
        this.$loader.start();
        if (!(await this.isFormValid())) {
          this.$toaster.warning("Verifique os campos.");
          return false;
        }

        const variables = this.formatDataSave();

        await this.$store.dispatch(
          "Appointment/Register/ADD_APPOINTMENT_OBJECTIVE",
          variables,
        );

        this.$toaster.success("Objetivo cadastrado com sucesso!");
        this.cleanForm();
        this.$refs.rgList.submitForm(true);
      } catch (pErr) {
        const error = pErr.toString();

        if (error.includes("Error:")) {
          this.$toaster.error(
            error.substring(6, error.length),
            "Erro ao salvar objetivo.",
          );
        } else {
          this.$toaster.error(pErr, "Erro ao salvar objetivo.");
        }
      } finally {
        this.$loader.finish();
      }
    },

    editObjective() {
      this.setScrollTop();

      this.form = {
        objective: this.selectedRow.tcu_novo_tipo_consulta,
        healthInsurance: this.selectedRow.pls_id,
        unitHealth: this.search.unitHealth,
        code: this.selectedRow.tcu_novo_codigo_procedimento,
        cost: this.selectedRow.tcu_novo_custo.replace(/(\.)/gi, ""),
      };

      this.edit = true;
      this.enableSaveEditButton = true;
    },

    formatDataEdit() {
      const variables = {
        tcu_id: this.selectedRow.tcu_id,
        tcu_id_planos_saude: Number(this.form.healthInsurance),
        tcu_id_tabelas_unificadas: this.selectedRow.tcu_id_tabelas_unificadas,
        tcu_id_tuss: this.selectedRow.tcu_id_tuss,
        tcu_id_unidades_saude: Number(this.form.unitHealth),
        tcu_novo_codigo_procedimento: Number(this.form.code),
        tcu_novo_custo: this.form.cost,
        tcu_novo_tipo_consulta: this.form.objective,
      };
      return variables;
    },

    async saveEditObjective() {
      try {
        if (!(await this.isFormValid())) {
          this.$toaster.warning("Verifique os campos.");
          return false;
        }
        const variables = this.formatDataEdit();

        await this.$store.dispatch(
          "Appointment/Register/EDIT_APPOINTMENT_OBJECTIVE",
          variables,
        );

        this.selectedRow = {};
        this.enableSaveEditButton = false;
        this.cleanForm();
        this.$refs.rgList.submitForm(true);

        this.$toaster.success("Objetivo salvo com sucesso!");
      } catch (pErr) {
        const error = pErr.toString();

        if (error.includes("Error:")) {
          this.$toaster.error(
            error.substring(6, error.length),
            "Erro ao salvar objetivo.",
          );
        } else {
          this.$toaster.error(pErr, "Erro ao salvar objetivo.");
        }
      } finally {
        this.$refs.save.actionDone();
      }
    },

    generateFilter() {
      const variables = {
        arrFiltros: {
          tcu_novo_codigo_procedimento: this.search.code,
          tcu_novo_tipo_consulta: this.search.objective,
          intIdUnidade: this.search.unitHealth,
        },
      };
      return variables;
    },

    searchList(pData) {
      return this.$store.dispatch(
        "Appointment/Register/SEARCH_APPOINTMENT_OBJECTIVE",
        {
          ...pData,
        },
      );
    },

    searchObjectives() {
      if (!this.search.unitHealth) {
        this.$refs.search.actionSubmit();
        this.$toaster.warning("Verifique os campos.");
        this.$refs.search.actionDone();
        return false;
      }
      this.$loader.start("Carregando...");
      this.$refs.rgList.submitForm(true);
      this.$refs.search.actionDone();
      this.$loader.finish();
    },

    formatDataChange(pItem) {
      const variables = {
        tcu_id: pItem.tcu_id,
        tcu_id_planos_saude: Number(pItem.pls_id),
        tcu_id_tabelas_unificadas: pItem.tcu_id_tabelas_unificadas,
        tcu_id_tuss: pItem.tcu_id_tuss,
        tcu_id_unidades_saude: Number(this.search.unitHealth),
        tcu_novo_codigo_procedimento: pItem.tcu_novo_codigo_procedimento,
        tcu_novo_custo: pItem.tcu_novo_custo,
        tcu_novo_tipo_consulta: pItem.tcu_novo_tipo_consulta,
      };
      return variables;
    },

    async changeToggle(pItem, pValue) {
      try {
        const variables = this.formatDataChange(pItem);

        if (!pValue) {
          this.$loader.start("Alterando situação...");
          await this.$store.dispatch(
            "Appointment/Register/INACTIVE_APPOINTMENT_OBJECTIVE",
            variables,
          );
        } else {
          this.$loader.start("Alterando situação...");
          await this.$store.dispatch(
            "Appointment/Register/ACTIVE_APPOINTMENT_OBJECTIVE",
            variables,
          );
        }

        this.$toaster.success("Situação alterada com sucesso!");
        this.$refs.rgList.submitForm(true);
      } catch (Err) {
        this.$toaster.error(Err.message);
      } finally {
        this.$loader.finish();
      }
    },

    validateCost(pValue, pErrors) {
      if (pValue && pValue.length > 0) {
        const regexComma = /^[0-9]+(,[0-9]{1,2})?$/;
        const regexDot = /^[0-9]+(.[0-9]{1,2})?$/;

        const testComma = regexComma.test(pValue);
        const testDot = regexDot.test(pValue);

        if (!testComma && !testDot) {
          pErrors.push("É obrigatório que o campo seja decimal.");
          return false;
        }

        const caracterSplit = pValue.includes(".") ? "." : ",";
        const before = pValue.split(caracterSplit)[1];
        if (before?.length <= 1) {
          this.form.cost = pValue + "0";
        }

        return true;
      }
    },

    openModalImport() {
      this.modalImport = true;
    },

    closeModalImport() {
      this.modalImport = false;
    },

    setScrollTop() {
      const elementContainer = this.$refs.element;
      if (this.$refs.element) {
        elementContainer.scrollTop = 0;
      }
    },

    cancelEdit() {
      this.form.objective = null;
      this.form.unitHealth = null;
      this.form.healthInsurance = null;
      this.form.code = null;
      this.form.cost = null;

      this.edit = false;
      this.enableSaveEditButton = false;
      this.selectedRow = {};
    },

    cleanForm() {
      this.form.objective = null;
      this.form.unitHealth = null;
      this.form.healthInsurance = null;
      this.form.code = null;
      this.form.cost = null;

      this.edit = false;

      this.$refs.objective.cleanValidate();
      this.$refs.unitHealth.cleanValidate();
      this.$refs.healthInsurance.cleanValidate();
      this.$refs.code.cleanValidate();
      this.$refs.cost.cleanValidate();
    },

    cleanFilter() {
      this.search.unitHealth = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
      this.search.objective = null;
      this.search.code = null;

      this.edit = false;
      this.enableSaveEditButton = false;
      this.selectedRow = {};

      this.$refs.searchUnitHealth.cleanValidate();

      this.searchObjectives();
    },
  },
};
</script>
