import IndividualEffectivation from "$exam/submodules/view/individual-effectivation/store";
import Laboratory from "$exam/submodules/view/laboratory/store";
import Schedule from "$exam/submodules/schedule/store";
import Report from "$exam/submodules/report/store";
import Register from "$exam/submodules/register/store";

import getters from "./getters";
import mutations from "./mutations/";
import state from "./state";

export default {
  namespaced: true,
  getters,
  mutations,
  state,
  modules: {
    IndividualEffectivation,
    Laboratory,
    Report,
    Schedule,
    Register,
  },
};
