import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const createMutation = gql`
  mutation(
    $exam: EffectivedExamInputType
    $bioslabLaboratoryExam: BioslabLaboratoryExamInputType
  ) {
    CreateEffectivedExam(
      exam: $exam
      bioslabLaboratoryExam: $bioslabLaboratoryExam
    ) {
      exe_id
      exe_id_pacientes
    }
  }
`;

const updateMutation = gql`
  mutation(
    $examId: Int
    $exam: EffectivedExamInputType
    $bioslabLaboratoryExam: BioslabLaboratoryExamInputType
  ) {
    UpdateEffectivedExamValidated(
      examId: $examId
      exam: $exam
      bioslabLaboratoryExam: $bioslabLaboratoryExam
    ) {
      exe_id
      exe_id_pacientes
    }
  }
`;

export default async (variables) => {
  try {
    const mutation =
      !variables.examId || variables.examId === 0
        ? createMutation
        : updateMutation;
    const { data } = await GraphQLClient.mutate({ mutation, variables });
    return data.CreateEffectivedExam;
  } catch (err) {
    const jsonResponse = JSON.parse(JSON.stringify(err));
    return Promise.reject(jsonResponse.graphQLErrors[0].message);
  }
};
