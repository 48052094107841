<template lang="html">
  <section class="individual-effectivation">
    <RgSearch
      ref="RgSearch"
      v-model="mutableExamList"
      new-url="/exam/effectivation/details"
      :item-height="77"
      :search-function="searchFilter"
      :build-filter="getGenerateFilter"
      :clear-function="clearSearch"
      result-title="Listagem de Exames"
    >
      <div slot="filters">
        <IndividualSearchFilter ref="filter" @reSearch="reSearch" />
      </div>

      <ul class="individual-effectivation-list">
        <li
          v-for="(item, indexItem) in mutableExamList"
          :key="indexItem"
          class="item"
        >
          <div v-show="enableCheckbox" class="multi-action">
            <input
              v-model="checkedItems"
              :disabled="!canEnableCheckbox(item)"
              :value="item.auxiliary_index"
              data-id="checkbox"
              class="checkbox"
              type="checkbox"
            />
          </div>

          <div
            class="list-item"
            data-id="item"
            @click="selectItemToEffectivate(item, indexItem)"
          >
            <div
              :class="
                statusStyle(
                  item.exe_id_exames_efetivacao
                    ? item.exe_id_exames_efetivacao
                    : 1,
                )
              "
              class="status"
            />

            <div class="date" title="Data do exame">
              <span class="month">{{ item.exe_data_exame | getMonth }}</span>
              <span class="day">{{ item.exe_data_exame | getDate }}</span>
              <span class="year">{{ item.exe_data_exame | getYear }}</span>
            </div>

            <div class="codes">
              <div class="row-info" title="Prontuário">
                <label class="label">Prontuário</label>
                <span class="description">{{
                  item.patient.pac_prontuario_unico
                }}</span>
              </div>

              <div v-if="item.bol_numero" class="row-info" title="Boletim">
                <label class="label">Boletim</label>
                <span class="description">{{
                  item.bol_numero ? item.bol_numero : ""
                }}</span>
              </div>
            </div>

            <div class="info-1">
              <span class="procedure-name" title="Procedimento">
                {{
                  item.procedure
                    ? item.procedure.NO_PROCEDIMENTO
                    : item.procedimento
                }}
              </span>
              <span
                v-if="haveRequestedEmployee(item)"
                class="employee"
                title="Solicitante"
              >
                SOLICITANTE:
                {{
                  item.requestEmployeeRelationship
                    ? item.requestEmployeeRelationship.employee.person.pes_nome
                    : ""
                }}
              </span>
            </div>

            <div class="patient-info-data">
              <span class="name" title="Nome do paciente">
                {{ item.patient.person.pes_nome }}
              </span>
              <span v-if="isOpened(item)" title="Tempo em aberto">
                Aberto à {{ standByTime[item.auxiliary_index] }}
              </span>

              <div class="detail-1">
                <span class="detail-value" title="Data de nascimento">
                  <IconCalendar />
                  {{ item.patient.person.pes_nascimento | dateBR }}
                </span>
                <span class="detail-value" title="Número do CNS">
                  <IconCard />
                  {{ item.patient.person.cns.crs_numero | existsItem }}
                </span>
              </div>
            </div>
            <div v-if="true" class="actions">
              <RgLessButton
                :disabled="!item.editavel"
                title="Excluir"
                class="btn-margin-bottom"
                @click="showModalConfirmRemoveExam(item, indexItem)"
              />
              <RgEditButton
                :disabled="!item.editavel"
                title="Editar"
                class="btn-margin-bottom"
                small
                @click="editItem(item)"
              />
            </div>
          </div>
        </li>
      </ul>
    </RgSearch>

    <ModalEffectiveExam
      :show="showEffectivatedExam || showEffectivatedBatch"
      :exam-info="examInfo"
      :in-batch="inBatch"
      data-id="modal-efetivacao"
      @reSearch="reSearch(true)"
      @close="closeEffectivatedExam"
    />

    <ModalNotEffectiveExam
      v-model="reasonNotEffectivatedBatch"
      :exam-info="examInfo"
      :show="showNotEffectivatedBatch"
      :in-batch="inBatch"
      data-id="modal-nao-efetivacao"
      @reSearch="reSearch(true)"
      @close="closeEffectivatedExam"
      @notEffectivateExam="notEffectivateExamBatch"
    />

    <ModalConfirmRemoveExam
      :show="showModalConfirmRemove"
      :exam-id="lastItem.exe_id"
      :item-index="lastIndex"
      data-id="modal-confirma-exclusao"
      @exam-removed="itemRemoved"
      @close="closeModalConfirmRemove"
    />
  </section>
</template>

<script>
import { IndividualSearchFilter } from "./container";
import RgSearch from "~tokio/foundation/rg-search/RgSearch.vue";
import { mapGetters } from "vuex";
import moment from "moment";
import { IconCalendar, IconCard } from "~tokio/primitive/icon";
import { RgLessButton, RgEditButton } from "~tokio/primitive/button";
import {
  ModalEffectiveExam,
  ModalNotEffectiveExam,
  ModalConfirmRemoveExam,
} from "$exam/submodules/view/individual-effectivation/component/modal";

const monthText = [
  "JAN",
  "FEV",
  "MAR",
  "ABR",
  "MAI",
  "JUN",
  "JUL",
  "AGO",
  "SET",
  "OUT",
  "NOV",
  "DEZ",
];
const OPENED = 1;
const EFFECTIVATED = 2;
const NOT_EFFECTIVATED = 3;
export default {
  name: "IndividualEffectivation",
  components: {
    RgSearch,
    IndividualSearchFilter,
    IconCalendar,
    IconCard,
    RgLessButton,
    RgEditButton,
    ModalEffectiveExam,
    ModalNotEffectiveExam,
    ModalConfirmRemoveExam,
  },

  filters: {
    getMonth: (pValue) =>
      moment.isDate(new Date(pValue))
        ? monthText[moment(pValue, "YYYY-MM-DD").format("M") - 1]
        : "",
    getYear: (pValue) =>
      moment.isDate(new Date(pValue))
        ? moment(pValue, "YYYY-MM-DD").format("YYYY")
        : "",
    getDate: (pValue) =>
      moment.isDate(new Date(pValue))
        ? moment(pValue, "YYYY-MM-DD").format("DD")
        : "",
    dateBR: (pValue) =>
      moment.isDate(new Date(pValue))
        ? moment(pValue).format("DD/MM/YYYY")
        : "",
    existsItem: (pValue) =>
      pValue && pValue.length > 0 ? pValue : "NÃO EXISTE",
  },

  data() {
    return {
      enableCheckbox: false,
      showNotEffectivatedBatch: false,
      showEffectivatedBatch: false,
      filterData: {
        uns_id: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        status: "null",
        period: this.$store.getters["Billing/BDSia/GET_PERIOD_DATE"],
      },
      mutableExamList: [],
      inBatch: false,
      showEffectivatedExam: false,
      showModalConfirmRemove: false,
      reasonNotEffectivatedBatch: null,
      lastIndex: 0,
      lastItem: {},
      checkedItems: [],
      effectiveItem: {},
      standByTime: [],
      standByTimeInterval: null,
    };
  },
  computed: {
    ...mapGetters({
      examsList: "Exam/IndividualEffectivation/GET_LIST",
      examsCount: "Exam/IndividualEffectivation/GET_COUNT",
    }),

    hasPermission() {
      return true;
      // return this.$Permissions.global.has('exame2.efetivacao.exibir', this.$store.getters['Login/GET_UNIT_HEALTH_ID'])
    },

    examInfo() {
      return !this.inBatch
        ? this.effectiveItem
        : !this.mutableExamList || this.mutableExamList.length === 0
        ? []
        : this.mutableExamList
            .map((exam, itemIndex) => {
              return { ...exam, itemIndex };
            })
            .filter((exam) => this.checkedItems.includes(exam.auxiliary_index));
    },

    isThereExams() {
      return this.mutableExamList && this.mutableExamList.length > 0;
    },
  },

  watch: {
    mutableExamList() {
      this.updateStandByTime();
    },

    enableCheckbox(pValue) {
      this.inBatch = pValue;
      if (!pValue) this.checkedItems = [];
    },
  },

  beforeDestroy() {
    this.$store.commit("Exam/IndividualEffectivation/RESET_STORE");
  },

  mounted() {
    const pFilter = this.$store.getters[
      "Exam/IndividualEffectivation/GET_FILTER"
    ];

    if (pFilter) this.$refs.filter.mountFilter(pFilter);
  },

  methods: {
    getGenerateFilter() {
      return this.$refs.filter.generateFilter();
    },

    searchFilter(pData) {
      return this.$store.dispatch(
        "Exam/IndividualEffectivation/LIST_EXAMS_BY_FILTER",
        { variables: { ...pData } },
      );
    },

    reSearch(pBoolean = false) {
      this.$refs.RgSearch.submitForm(true, pBoolean);
    },

    closeModals() {
      this.showNotEffectivatedBatch = false;
      this.showEffectivatedBatch = false;
    },

    clearSearch(pValue) {
      this.$refs.filter.cleanForm();
      this.mutableExamList = [];
      this.$store.commit("Exam/IndividualEffectivation/RESET_STORE");
      this.$store.commit("Exam/IndividualEffectivation/CLEAN_FILTER");
    },

    isOpened(pItem) {
      return pItem.exe_id_exames_efetivacao === OPENED;
    },

    calcDurationBetweenDates(pStartDate, pEndDate = null) {
      if (!pEndDate) pEndDate = new Date();

      const diff = moment(pEndDate, "YYYY-MM-DD HH:mm:ss").diff(
        moment(pStartDate, "YYYY-MM-DD HH:mm:ss"),
      );
      const duration = moment.duration(diff);

      const asDays = Math.floor(duration.asDays());
      const asMonth = Math.floor(duration.asMonths());
      const hourMinuteSecond = moment.utc(diff).format(" HH:mm:ss");

      const dayText =
        asDays > 0 ? `${asDays} dia` + (asDays > 1 ? "s" : "") : "";

      const monthText =
        asMonth > 0 ? `${asMonth} mês` + (asMonth > 1 ? "ses" : "") : "";

      return monthText + dayText + hourMinuteSecond;
    },

    updateStandByTime() {
      this.closeStandByTimeInterval();
      this.standByTimeInterval = setInterval(() => {
        if (this.mutableExamList && this.mutableExamList.length > 0) {
          this.mutableExamList.map((exam) => {
            this.standByTime[
              exam.auxiliary_index
            ] = this.calcDurationBetweenDates(exam.exe_data_solicitacao);
          });
          this.standByTime = Object.assign({}, this.standByTime);
        }
      }, 1000);
    },

    closeStandByTimeInterval() {
      if (this.standByTimeInterval) clearInterval(this.standByTimeInterval);
    },

    async closeEffectivatedExam(payload = null) {
      this.showEffectivatedExam = false;
      this.showEffectivatedBatch = false;
      const reasonNotEffectivated =
        payload && payload.reasonNotEffectivated
          ? payload.reasonNotEffectivated
          : null;

      if (reasonNotEffectivated) {
        const result = await this.$store.dispatch(
          "Exam/IndividualEffectivation/GET_NOT_EFFECTIVATE_REASONS",
          reasonNotEffectivated,
        );

        if (this.mutableExamList && this.mutableExamList.length > 0) {
          this.mutableExamList = this.mutableExamList.map((item, index) => {
            if (index === this.lastIndex) {
              item.exe_id_exames_motivos_nao_efetivacao = reasonNotEffectivated;
              item.motivo_nao_efetivacao = result.emn_nome;
            }
            return item;
          });
        }
      }

      this.closeModals();
    },

    selectItemToEffectivate(pItem, pIndexItem) {
      if (!this.inBatch) {
        if (!this.canBeEffectivated(pItem)) {
          this.$toaster.warning("Esse exame já foi efetivado");
          return false;
        } else {
          this.lastIndex = pIndexItem;
          this.effectiveItem = { ...pItem, itemIndex: pIndexItem };
          this.showEffectivatedExam = true;
        }
      }
    },

    statusStyle(pStatus) {
      switch (pStatus) {
        case OPENED:
          return "status--opened";
        case EFFECTIVATED:
          return "status--effectivated";
        case NOT_EFFECTIVATED:
          return "status--non-effectivated";
      }
    },

    showModalConfirmRemoveExam(pItem, pIndex) {
      this.lastIndex = pIndex;
      this.lastItem = pItem;
      this.showModalConfirmRemove = true;
    },

    closeModalConfirmRemove() {
      this.lastIndex = 0;
      this.lastItem = {};
      this.showModalConfirmRemove = false;
    },

    async itemRemoved(success) {
      if (!success) this.$toaster.error("Houve um problema na operação");
      else {
        this.$toaster.success("Exame removido com sucesso");
        this.mutableExamList = this.mutableExamList.filter(
          (item, index) => index !== this.lastIndex,
        );
        this.checkedItems = this.checkedItems.filter(
          (item) => item.auxiliary_index !== this.lastItem.auxiliary_index,
        );
        this.reSearch();
        this.closeModalConfirmRemove();
      }
    },

    editItem(pItem) {
      this.$store.commit(
        "Exam/IndividualEffectivation/SET_SELECTED_DATA",
        pItem,
      );

      this.$router.push({
        name: "exam.effectivation.edit",
      });
    },

    canBeEffectivated(pItem) {
      return pItem.exe_id_exames_efetivacao !== EFFECTIVATED;
    },

    canEnableCheckbox(pItem) {
      return this.enableCheckbox && this.canBeEffectivated(pItem);
    },

    notEffectivateExamBatch(pValue) {
      if (!pValue) {
        this.$toaster.error("Houve um problema na operação");
      } else {
        this.$toaster.success("Não Efetivação cadastrada com sucesso");
        this.showEffectivatedExam = false;
        this.showEffectivatedBatch = false;
        this.closeModals();
      }
    },

    haveRequestedEmployee(pItem) {
      return (
        pItem.requestEmployeeRelationship &&
        pItem.requestEmployeeRelationship.employee.person.pes_nome &&
        pItem.requestEmployeeRelationship.employee.person.pes_nome !== ""
      );
    },
  },
};
</script>
