<style
  src="./RgContentHospitalizationBillingTincture.scss"
  lang="scss"
  scoped
></style>
<template lang="html">
  <div class="rg-content-hospitalization-billing-tincture">
    <div class="team-info">
      <RgContentHospitalizationTeam
        :procedure="procedure"
        :required-documents="requiredDocuments"
        @setProfessionalList="setProfissionalList"
      />
    </div>

    <div class="tincture-info">
      <RgTitle title="Laqueadura" />
      <form class="form">
        <RgValidatorForm ref="validator">
          <div class="row">
            <RgInputMasked
              v-model="tincture.ilv_qtd_filhos"
              :rules="{ required: true }"
              class="col-3"
              label="Quantidade de Filhos: "
              placeholder="0"
              mask="##"
            />

            <RgRadioYesNo
              v-model="tincture.ilv_gestacao_alto_risco"
              :rules="{ required: true }"
              :default-value="false"
              class="rg-radio col-2"
              label="Gestação Alto Risco:"
              tab-index="1"
            />

            <RgComboboxInstructionLevel
              v-model="tincture.ilv_id_internacoes_grau_instrucoes"
              :rules="{ required: true }"
              label="Grau de Instrução:"
              class="col-3"
            />

            <RgComboboxCid
              v-model="tincture.ilv_codigo_cid10"
              :procedure="procedure.iap_codigo_procedimentos"
              :rules="{ required: true }"
              class="col-4"
              label="CID Principal:"
              @input="cidSelected"
            />
          </div>

          <div class="contraceptive-methods-info row">
            <RgComboboxContraceptiveMethod
              v-model="tincture.ilv_id_internacoes_metodos_contraceptivos1"
              :rules="{ required: true }"
              label="Método Contraceptivo 1:"
              class="col-4"
            />
            <RgComboboxContraceptiveMethod
              v-model="tincture.ilv_id_internacoes_metodos_contraceptivos2"
              :rules="{ required: true }"
              label="Método Contraceptivo 2:"
              class="col-4"
            />
            <div class="buttons-tincture col-4">
              <button
                :disabled="!tincture.professionalTeamList.length > 0"
                type="button"
                class="btn btn-success col-6"
                @click="confirm"
              >
                Confimar
              </button>

              <button class="col-6 btn btn-default" @click.stop.prevent="close">
                Fechar
              </button>
            </div>
          </div>
        </RgValidatorForm>
      </form>
    </div>
  </div>
</template>

<script>
import mask from "vue-the-mask";
import { RgValidatorForm, RgTitle, RgInputMasked } from "~tokio/primitive";
import RgRadioYesNo from "$surgery-center/foundation/component/rg-radio-yes-no/RgRadioYesNo";
import RgComboboxInstructionLevel from "../../../combobox/rg-combobox-instruction-level/RgComboboxInstructionLevel";
import RgComboboxContraceptiveMethod from "../../../combobox/rg-combobox-contraceptive-method/RgComboboxContraceptiveMethod";
import RgContentHospitalizationTeam from "$billing/hospitalization-billing/common/component/container/content-hospitalization-team/RgContentHospitalizationTeam";
import { RgComboboxCid } from "$billing/common";
import { ValidateTeamMembers } from "../../../../service";

export default {
  name: "RgContentHospitalizationBillingTincture",
  components: {
    RgValidatorForm,
    RgInputMasked,
    RgComboboxInstructionLevel,
    RgComboboxContraceptiveMethod,
    RgContentHospitalizationTeam,
    RgRadioYesNo,
    RgTitle,
    RgComboboxCid,
  },
  directives: {
    mask,
  },
  props: {
    procedure: {
      type: Object,
      default: () => {},
    },
    requiredDocuments: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      ruleRequiredTrue: true,
      tincture: {
        type: "tincture",
        ilv_qtd_filhos: 0,
        ilv_gestacao_alto_risco: false,
        ilv_id_internacoes_grau_instrucoes: "",
        ilv_id_internacoes_metodos_contraceptivos1: "",
        ilv_id_internacoes_metodos_contraceptivos2: "",
        professionalTeamList: [],
      },
      professionalCaptionRaw: "pes_nome",
    };
  },
  mounted() {
    if (this.procedure.additionalData[0]) {
      this.tincture = this.procedure.additionalData[0];
    }
  },
  methods: {
    async confirm(event) {
      if (await this.validateForm()) {
        this.$emit("onFinish", this.tincture);
      }
    },
    close() {
      this.$emit("close");
    },
    setProfissionalList(event) {
      this.tincture.professionalTeamList = event;
    },
    async validateForm() {
      const valid = await this.$refs.validator.validate();
      // if (!this.validateTeamMembers()) valid = false
      return valid;
    },
    cidSelected(pData) {
      if (pData && pData[0].text) {
        this.tincture.ilv_codigo_cid10 = pData[0].text;
      }
    },
    validateTeamMembers() {
      return ValidateTeamMembers(this.tincture.professionalTeamList);
    },
  },
};
</script>
