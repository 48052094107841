import { render, staticRenderFns } from "./RgTextArea.vue?vue&type=template&id=3d09f5ae&scoped=true&"
import script from "./RgTextArea.vue?vue&type=script&lang=js&"
export * from "./RgTextArea.vue?vue&type=script&lang=js&"
import style0 from "./RgTextArea.scss?vue&type=style&index=0&id=3d09f5ae&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d09f5ae",
  null
  
)

export default component.exports