<style src="./Report.scss" lang="scss" scoped></style>
<template>
  <section class="report">
    <ModuleBox :granted="hasPermission" title="Filtros">
      <form @submit.prevent="submitForm">
        <RgValidatorForm ref="validator">
          <div class="row filter-fields">
            <RgInput
              v-model="form.period"
              class="col-4"
              label="Competência"
              placeholder="AAAAMM"
            />
            <RgComboboxUnithealth
              v-model="form.unitHealth"
              :defaul-value="true"
              class="col-6"
            />
            <button class="button col-2 btn btn-default">Buscar</button>
          </div>
        </RgValidatorForm>
      </form>
    </ModuleBox>

    <section v-if="hasPermission" class="row between-all">
      <div class="col-auto report-card report-card-bulletin">
        <div class="icon-background"><IconAdd /></div>
        <div class="value">{{ total_boletins }}</div>
        <div class="title">Boletins</div>
      </div>
      <div class="col-auto report-card report-card-billed">
        <div class="icon-background"><IconAdd /></div>
        <div class="value">{{ total_boletins_faturado }}</div>
        <div class="title">Boletins faturados</div>
      </div>
      <div class="col-auto report-card report-card-exported">
        <div class="icon-background"><IconAdd /></div>
        <div class="value">{{ total_boletins_exportado }}</div>
        <div class="title">Boletins exportados</div>
      </div>
      <div class="col-auto report-card report-card-procedure">
        <div class="icon-background"><IconAdd /></div>
        <div class="value">{{ total_procedimentos }}</div>
        <div class="title">Procedimentos realizados</div>
      </div>
      <div class="col-auto report-card report-card-employee">
        <div class="icon-background"><IconAdd /></div>
        <div class="value">{{ total_profissional }}</div>
        <div class="title">Profissionais</div>
      </div>
    </section>
  </section>
</template>

<script>
import { IconAdd } from "~tokio/primitive/icon";
import ModuleBox from "~tokio/foundation/modulebox/Modulebox";
import { RgInput, RgValidatorForm } from "~tokio/primitive";
import GetBulletinsSum from "../action/GetBulletinsSum";
import GetBulletinsBillingSum from "../action/GetBulletinsBillingSum";
import GetBulletinsExportedSum from "../action/GetBulletinsExportedSum";
import GetProcedureSum from "../action/GetProcedureSum";
import GetEmployeeSum from "../action/GetEmployeeSum";
import RgComboboxUnithealth from "$billing/common/component/rg-combobox-unithealth/RgComboboxUnithealth";

let refreshTimeout = null;

export default {
  name: "Report",
  components: {
    IconAdd,
    ModuleBox,
    RgInput,
    RgValidatorForm,
    RgComboboxUnithealth,
  },
  data() {
    return {
      form: {
        period: null,
        unitHealth: null,
      },
      total_boletins: "",
      total_boletins_faturado: "",
      total_boletins_exportado: "",
      total_procedimentos: "",
      total_profissional: "",
    };
  },
  computed: {
    periodDate() {
      return this.$store.getters["Billing/BDSia/GET_PERIOD_DATE"];
    },
    unitHealth() {
      return this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    },
    hasPermission() {
      return this.$Permissions.global.has("faturamento.atendimentosEmergencia");
    },
  },
  watch: {
    periodDate(pValue) {
      this.form.period = pValue;
      this.getInfoReport();
    },
    unitHealth(pValue) {
      this.form.unitHealth = pValue;
      this.getInfoReport();
    },
  },
  mounted() {
    this.form.period =
      this.form.Period || this.$store.getters["Billing/BDSia/GET_PERIOD_DATE"];
    this.form.unitHealth =
      this.form.unitHealth || this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    this.getInfoReport();
    this.autoRefresh();
  },
  beforeDestroy() {
    clearInterval(refreshTimeout);
  },
  methods: {
    getInfoReport() {
      const Competence = this.form.period;
      const UnitHealthId = this.form.unitHealth;
      if (!Competence || !UnitHealthId) return false;

      GetBulletinsSum({ Competence, UnitHealthId }).then((pResult) => {
        this.total_boletins = pResult.total_boletins;
      });
      GetBulletinsBillingSum({ Competence, UnitHealthId }).then((pResult) => {
        this.total_boletins_faturado = pResult.total_boletins_faturado;
      });
      GetBulletinsExportedSum({ Competence, UnitHealthId }).then((pResult) => {
        this.total_boletins_exportado = pResult.total_boletins_exportado;
      });
      GetProcedureSum({ Competence, UnitHealthId }).then((pResult) => {
        this.total_procedimentos = pResult.total_procedimentos;
      });
      GetEmployeeSum({ Competence, UnitHealthId }).then((pResult) => {
        this.total_profissional = pResult.total_profissional;
      });
    },
    autoRefresh() {
      refreshTimeout = setInterval(() => {
        this.submitForm();
      }, 60000);
    },
    submitForm() {
      this.getInfoReport();
    },
  },
};
</script>
