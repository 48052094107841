<script>
import { RgRemoteComboboxMixin } from "~tokio/primitive";
import ListUnithealthExamQueue from "./action/ListUnithealthExamQueue";
import ListUnithealthExamProcedureQueue from "./action/ListUnithealthExamProcedureQueue";
import ListUnithealthAppointmentQueue from "./action/ListUnithealthAppointmentQueue";

export default {
  name: "RgComboboxUnithealthQueue",
  mixins: [RgRemoteComboboxMixin],
  props: {
    label: {
      type: String,
      default: "Unidade de Saúde",
    },
    isNecessary: {
      type: Boolean,
      default: false,
    },
    defaultText: {
      type: String,
      default: "Selecione",
    },
    emptyDefaultText: {
      type: String,
      default: "Nenhum Item para Selecionar",
    },
    unitHealthId: {
      default: null,
    },
    moduleId: {
      required: true,
    },
    hasRegularPermission: Boolean,
    hasViewRequestUnitHealthPermission: Boolean,
    hasProcedure: Boolean,
  },
  data() {
    return {};
  },
  methods: {
    doSearch() {
      if (this.moduleId === this.$store.state.Login.route_module_map.exam) {
        if (this.hasProcedure) {
          return ListUnithealthExamProcedureQueue();
        } else {
          return ListUnithealthExamQueue({
            unitHealthId: this.unitHealthId,
            hasRegularPermission: this.hasRegularPermission,
            hasViewRequestUnitHealthPermission: this
              .hasViewRequestUnitHealthPermission,
          });
        }
      }

      if (
        this.moduleId === this.$store.state.Login.route_module_map.appointment
      ) {
        return ListUnithealthAppointmentQueue({
          unitHealthId: this.unitHealthId,
          hasRegularPermission: this.hasRegularPermission,
          hasViewRequestUnitHealthPermission: this
            .hasViewRequestUnitHealthPermission,
        });
      }
    },
    formatItem(item) {
      return {
        value: item.uns_id,
        text: `${item.uns_nome}`,
      };
    },
    blur() {
      if (this.isNecessary) {
        this.validate();
        this.$emit("blur");
      }
    },
  },
};
</script>
