<template lang="html">
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.7069 4.29325C25.3159 3.90225 24.6839 3.90225 24.2929 4.29325L17 11.5862L14.707 9.29319C14.42 9.00719 13.991 8.91919 13.617 9.07619C13.244 9.23019 13 9.59619 13 10.0002V16.0001C13 16.5531 13.448 17.0001 14 17.0001H19.9999C20.4039 17.0001 20.7689 16.7571 20.9239 16.3831C21.0789 16.0091 20.9929 15.5791 20.7069 15.2931L18.4139 13.0001L25.7069 5.70723C26.0978 5.31623 26.0978 4.68424 25.7069 4.29325Z"
      fill="white"
    />
    <path
      d="M21.9998 24.9998H7.99997C6.34598 24.9998 5 23.6538 5 21.9998V7.99997C5 6.34598 6.34598 5 7.99997 5H15.9999C16.5519 5 16.9999 5.44699 16.9999 5.99999C16.9999 6.55298 16.5519 6.99998 15.9999 6.99998H7.99997C7.44897 6.99998 6.99998 7.44797 6.99998 7.99997V21.9998C6.99998 22.5518 7.44897 22.9998 7.99997 22.9998H21.9998C22.5508 22.9998 22.9998 22.5518 22.9998 21.9998V13.9999C22.9998 13.4469 23.4478 12.9999 23.9998 12.9999C24.5518 12.9999 24.9998 13.4469 24.9998 13.9999V21.9998C24.9998 23.6538 23.6538 24.9998 21.9998 24.9998Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "Regulate",
};
</script>
