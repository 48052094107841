export default (pArray) => {
  const obj = {
    ics_cns: 0,
    ics_idade_maior: 0,
    ics_idade_menor: 0,
    ics_qtd: 0,
    ics_telefone: 0,
    ics_tempo_permanencia: 0,
    ics_cbo: 0,
  };

  Object.keys(obj).map((key, index) => {
    if (pArray.filter((item) => item.value === index + 1).length > 0) {
      obj[key] = 1;
    }
  });

  return obj;
};
