<style src="./ModalEffectiveExam.scss" lang="scss" scoped></style>
<template lang="html">
  <aside class="modal-effective-exam">
    <RgValidatorForm ref="validator" class="rg-alert-base-modal">
      <RgBaseModal
        ref="Modal"
        v-shortkey="['esc']"
        :show="show"
        :max-width="40"
        class="rg-alert-base-modal"
        with-close-button
        @close="close"
        @shortkey.native="close"
      >
        <div slot="header" class="confirm-header">
          <h2 class="header">Efetivação de Exame</h2>
        </div>

        <div slot="body" class="confirm-body">
          <div v-if="inBatch">
            <!-- TODO: Adicionar alguma informação para orientar quais serão os items que serão efetivados -->
          </div>
          <div v-else>
            <div class="row">
              <div class="col-6 cell-info">
                <span class="title">Prontuário</span>
                <span class="value">{{
                  examInfo.patient
                    ? examInfo.patient.pac_prontuario_unico
                    : "NÃO INFORMADO"
                }}</span>
              </div>

              <div class="col-6 cell-info">
                <span class="title">Boletim</span>
                <span class="value">{{
                  examInfo.bol_numero ? examInfo.bol_numero : "NÃO INFORMADO"
                }}</span>
              </div>
            </div>

            <div class="row">
              <div class="col-12 cell-info">
                <span class="title">Paciente</span>
                <span class="value">{{ patientName }}</span>
              </div>
            </div>

            <div class="row">
              <div class="col-12 cell-info">
                <span class="title">Procedimento</span>
                <span class="value">{{ procedureName }}</span>
              </div>
              <div class="col-12 cell-info">
                <span class="title">Profissional solicitante</span>
                <span v-if="!canEditRequestedEmployee" class="value">
                  {{
                    form.requestEmployee
                      ? form.requestEmployee
                      : "NÃO INFORMADO"
                  }}
                </span>
                <RgSuggestEmployee
                  v-else
                  ref="EmployeeRequested"
                  v-model="requestEmployeeTyped"
                  class="mb-0 mt-0"
                  label=""
                  @selected="selectingRequestEmployee"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-4 cell-info">
                <span class="title sector-title">Setor</span>
                <span v-if="examInfo.exe_id_setores" class="value">
                  {{ !examInfo.sector ? "" : examInfo.sector.set_nome }}
                </span>
                <div v-else class="value sector-value">
                  <RgComboboxSector
                    ref="Sector"
                    v-model="form.exe_id_setores"
                    :unit-health="unitHealthId"
                    :permission="sectorPermission"
                    data-id="setor-modal"
                    label=""
                    class="row-field mb-0"
                  />
                </div>
              </div>

              <div class="col-8 cell-info">
                <span class="title">CID</span>
                <span v-if="examInfo.cid10" class="value">
                  {{
                    `${examInfo.cid10.cid_codigo} - ${examInfo.cid10.cid_nome}`
                  }}
                </span>
                <div v-else class="value cid-value">
                  <RgSuggestCid
                    ref="mainCid"
                    v-model="mainCid"
                    data-id="cid"
                    data-item="cid"
                    label=""
                    class="row-field mp-0"
                    @selected="selectingCidCode"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 cell-info last-col">
              <span class="title">Profissional executante</span>
              <RgSuggestEmployee
                ref="Employee"
                v-model="form.employee"
                :rules="{ forceSelection: true }"
                :sector="form.exe_id_setores"
                :compatible-with-cod-procedure="procedureCode"
                label=""
                class="row-field mt-0"
                @selected="selectingEmployee"
              />
            </div>
          </div>

          <div
            v-if="!inBatch && isNotEffectivated"
            class="row not-effectivated"
          >
            <div class="col-12 cell-info mt-20 mb-20">
              <span class="title">Motivo da não efetivação</span>
              <span class="value">{{
                examInfo.motivo_nao_efetivacao | uppercase
              }}</span>
            </div>
          </div>
        </div>

        <div slot="footer" class="confirm-actions-footer">
          <MediumButton
            data-id="cancelar-efetivacao"
            label="Cancelar efetivação"
            backgroundColor="#F95354"
            class="confirm-actions-item"
            @click="close"
          />

          <MediumButton
            v-if="!inBatch"
            data-id="nao-efetivacao"
            label="Não efetivação"
            class="confirm-actions-item"
            backgroundColor="#FFB447"
            @click="showNotEffectivated = true"
          />

          <MediumButton
            :disabled="!enableEffectivateButton"
            data-id="efetivar-exame"
            class="confirm-actions-item"
            backgroundColor="#30AF58"
            label="Efetivar"
            @click="effectivateExam"
          />
        </div>
      </RgBaseModal>
    </RgValidatorForm>

    <!-- Motivos não efetivação -->
    <ModalNotEffectiveExam
      v-model="form.reasonNotEffectivated"
      :exam-info="examInfo"
      :show="showNotEffectivated"
      @close="showNotEffectivated = false"
      @notEffectivateExam="notEffectivateExam"
    />
  </aside>
</template>

<script>
import {
  RgBaseModal,
  RgSuggestEmployee,
  RgValidatorForm,
  RgComboboxSector,
  MediumButton,
  RgSuggestCid,
} from "~tokio/primitive";
import ModalNotEffectiveExam from "./../modal-not-effective-exam/ModalNotEffectiveExam";
import PrepareExamToSave from "$exam/store/actions/PrepareExamToSave";

const EFETIVADO = 2;

export default {
  name: "ModalEffectiveExam",
  components: {
    RgBaseModal,
    RgSuggestEmployee,
    RgValidatorForm,
    ModalNotEffectiveExam,
    RgComboboxSector,
    RgSuggestCid,
    MediumButton,
  },
  filters: {
    /** @param {String} value */
    uppercase(value) {
      return !value ? "" : value.toUpperCase();
    },
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    inBatch: {
      type: Boolean,
      default: false,
    },
    examInfo: {
      type: [Object, Array],
      default: () => {},
    },
  },
  data() {
    return {
      sectorPermission: 247,
      unitHealthId: null,
      showNotEffectivated: false,
      mainCid: null,
      requestEmployeeTyped: "",
      canEditRequestedEmployee: false,
      form: {
        employeeVinId: null,
        reasonNotEffectivated: null,
        employee: null,
        exe_id_vinculos: null,
        exe_codigo_cid: null,
        exe_id_setores: null,
      },
    };
  },
  computed: {
    formValid() {
      return this.$refs.validator.validate();
    },
    isNotEffectivated() {
      return this.examInfo.exe_id_exames_motivos_nao_efetivacao > 0;
    },
    patientName() {
      return !this.examInfo.patient
        ? ""
        : this.examInfo.patient.person.pes_nome;
    },
    enableEffectivateButton() {
      return (
        this.form.exe_id_vinculos &&
        this.form.exe_id_vinculos !== 0 &&
        this.form.exe_id_setores !== 0
      );
    },
    procedureName() {
      return this.examInfo.procedure
        ? this.examInfo.procedure.NO_PROCEDIMENTO
        : this.examInfo.procedimento;
    },
    procedureCode() {
      return this.examInfo.procedure
        ? this.examInfo.procedure.CO_PROCEDIMENTO
        : this.examInfo.cod_procedimento;
    },
  },
  watch: {
    show(pValue) {
      this.inBatch ? this.resetEmployee() : this.mountInfo(this.examInfo);
    },
    examInfo(pValue) {
      this.$nextTick(() => this.mountInfo(pValue));
    },
    "form.exe_id_setores"(pValue) {
      this.resetEmployee();
    },
  },
  mounted() {
    this.unitHealthId = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
  },
  methods: {
    mountInfo(pValue) {
      this.mountEmployees(pValue);
      this.mountReason(pValue);
      this.mountSector(pValue);
      this.mountCid(pValue);
    },
    mountEmployees(pValue) {
      if (!pValue.employeeRelationship) {
        this.resetEmployee();
      } else {
        this.form.employee = this.examInfo.employeeRelationship.employee.person.pes_nome;
        this.form.exe_id_vinculos = this.examInfo.employeeRelationship.vin_id;
        if (this.$refs.Employee) {
          this.$refs.Employee.forceSelection({
            pes_nome: this.form.employee,
            vin_id: this.form.exe_id_vinculos,
          });
        }
      }

      if (!pValue.requestEmployeeRelationship) {
        this.form.requestEmployee = null;
        this.form.exe_id_vinculos_solicitante = null;
      } else {
        this.form.requestEmployee = this.examInfo.requestEmployeeRelationship.employee.person.pes_nome;
        this.form.exe_id_vinculos_solicitante = this.examInfo.requestEmployeeRelationship.vin_id;
        if (this.$refs.EmployeeRequested) {
          this.$refs.EmployeeRequested.forceSelection({
            pes_nome: this.form.requestEmployee,
            vin_id: this.form.exe_id_vinculos_solicitante,
          });
        }
      }
    },
    mountReason(pValue) {
      this.form.reasonNotEffectivated =
        pValue.exe_id_exames_motivos_nao_efetivacao <= 0 ? null : pValue;
    },
    mountSector(pValue) {
      this.form.exe_id_setores =
        pValue.exe_id_setores <= 0 ? null : pValue.exe_id_setores;
    },
    mountCid(pValue) {
      console.log(this.procedureCode);
      if (pValue.exe_codigo_cid <= 0 || !pValue.cid10.cid_codigo) {
        this.mainCid = null;
        this.form.exe_codigo_cid = null;
      } else {
        this.form.exe_codigo_cid = pValue.exe_codigo_cid;
        this.mainCid = pValue.cid10.cid_nome;
        this.$nextTick(() => {
          if (this.$refs.mainCid)
            this.$refs.mainCid.forceSelection({
              cid_codigo: pValue.cid10.cid_codigo,
              cid_nome: pValue.cid10.cid_nome,
            });
        });
      }
    },
    resetEmployee() {
      this.form.employee = null;
      this.form.exe_id_vinculos = null;
    },
    async effectivateExam() {
      if (this.formValid) {
        this.$toaster.success("Efetivação cadastrada com sucesso");
        const data = {
          ...this.examInfo,
          ...this.form,
          exe_id_unidades_saude: this.unitHealthId,
        };
        const preparedExamInfo = PrepareExamToSave(
          data,
          EFETIVADO,
          this.form.exe_id_vinculos,
          null,
          this.inBatch,
        );
        await this.$store.dispatch(
          "Exam/IndividualEffectivation/EFFECTTIVATE_EXAMS_BY_ID",
          preparedExamInfo,
        );
        this.$emit("reSearch");
        this.close({ success: true });
      }
    },
    async notEffectivateExam() {
      this.$toaster.success("Não Efetivação cadastrada com sucesso");
      this.$emit("reSearch");
      this.close({ reasonNotEffectivated: this.form.reasonNotEffectivated });
    },
    scrollToTop() {
      if (this.$refs.Modal) this.$refs.Modal.$el.scrollTop = 0;
    },
    close(payload = null) {
      if (payload) {
        const { reasonNotEffectivated = null, success = null } = payload;
        this.$emit("close", { reasonNotEffectivated, success });
      } else {
        const reasonNotEffectivated = null;
        const success = null;
        this.$emit("close", { reasonNotEffectivated, success });
      }
      this.showNotEffectivated = false;

      this.scrollToTop();
    },
    selectingEmployee(pEmployee) {
      this.form.exe_id_vinculos = pEmployee.source.vin_id;
    },
    selectingRequestEmployee(pEmployee) {
      if (pEmployee.source.pes_nome) {
        this.form.requestEmployee = pEmployee.source.pes_nome;
      }
      this.form.exe_id_vinculos_solicitante = pEmployee.source.vin_id;
      this.canEditRequestedEmployee =
        this.examInfo.editavel && !this.form.requestEmployee;
    },
    selectingCidCode(pCidCode) {
      const { cid_codigo, cid_nome } = pCidCode.source;
      this.form.exe_codigo_cid = cid_codigo || null;
      this.mainCid = cid_nome || null;
    },
  },
};
</script>
