<template lang="html">
  <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24.4091 22.2843L16.1392 15.2746L24.4563 7.8685C24.8623 7.53808 24.6404 6.99525 23.9843 6.99997H6.80717C6.6018 7.02484 6.40903 7.11217 6.2549 7.25014C6.11098 7.3777 6.02004 7.55452 6 7.74577V22.8082C6.03119 22.9913 6.12455 23.158 6.26434 23.2802C6.4207 23.4153 6.61553 23.4979 6.82133 23.5163H24.0976C24.2527 23.4989 24.3999 23.4384 24.5224 23.3416C24.6354 23.2514 24.7165 23.1273 24.7537 22.9876C24.7855 22.8493 24.7689 22.7043 24.7065 22.5769C24.6394 22.4451 24.5341 22.3365 24.4044 22.2654L24.4091 22.2843Z"
      fill="#00E8E3"
    />
    <path
      d="M16.1391 15.2747L24.4091 22.2843C25.0369 22.7563 24.8056 23.4596 24.1023 23.5352H6.82129L16.1391 15.2747Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="8.66692"
        y1="17.4696"
        x2="17.1823"
        y2="23.3227"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-opacity="0.57" />
        <stop offset="1" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "Pleni",
};
</script>
