import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query searchCustomHospitalizationSearch(
    $int_id: Int
    $uns_id: Int
    $set_id: [Int]
    $lca_id: Int
    $paciente: String
    $pac_prontuario_unico: Int
    $int_numero: Int
    $out_status: [Int]
    $bed_situation: [Int]
    $int_data_inicio: String
    $int_data_final: String
    $isa_data_inicio: String
    $isa_data_final: String
    $int_id_ocupacoes_cbo: Int
    $int_id_funcionarios: Int
    $int_id_internacoes_tipos_internacao: Int
    $int_id_internacoes_caracteres: Int
    $itl_id: Int
    $inl_extra: Int
    $inl_observacao: Int
    $indigent: Boolean
    $limit: Int
    $offset: Int
  ) {
    CustomHospitalizationSearch(
      int_id: $int_id
      uns_id: $uns_id
      set_id: $set_id
      lca_id: $lca_id
      paciente: $paciente
      pac_prontuario_unico: $pac_prontuario_unico
      int_numero: $int_numero
      out_status: $out_status
      bed_situation: $bed_situation
      int_data_inicio: $int_data_inicio
      int_data_final: $int_data_final
      isa_data_inicio: $isa_data_inicio
      isa_data_final: $isa_data_final
      int_id_ocupacoes_cbo: $int_id_ocupacoes_cbo
      int_id_funcionarios: $int_id_funcionarios
      int_id_internacoes_tipos_internacao: $int_id_internacoes_tipos_internacao
      int_id_internacoes_caracteres: $int_id_internacoes_caracteres
      itl_id: $itl_id
      inl_extra: $inl_extra
      inl_observacao: $inl_observacao
      indigent: $indigent
      limit: $limit
      offset: $offset
    ) {
      count
      rows {
        lca_id
        set_id
        uns_id
        lca_nome
        set_nome
        uns_nome
        paciente
        fun_responsavel_nome
        fun_solicitante_nome
        itl_nome
        int_id
        pes_id
        pes_mae
        pes_pai
        pes_id_sexos
        pes_nascimento
        pac_id
        int_numero
        int_data
        int_hora
        int_id_planos_saude
        inl_id
        inl_nome
        inl_extra
        inl_observacao
        inl_id_internacoes_situacoes_leito
        isa_id
        isa_data
        isl_nome
        isl_id
        pac_prontuario_unico
        cid_nome
        cid_codigo
        ocp_nome
        ocp_codigo
        itl_id
        itl_nome
        iti_nome
        ihs_id
        int_obs
        inp_novo_codigo_procedimento
        inp_novo_nome_procedimento
        int_id_filas_internacao
        int_id_pacientes_internacao
        int_id_funcionarios
        int_id_funcionarios_responsavel
        int_id_internacoes_tipos_internacao
        int_id_internacoes_tipos_responsavel
        int_id_internacoes_procedimentos
        int_id_planos_saude
        int_id_internacoes_modalidade
        int_id_cid10
        int_id_ocupacoes_cbo
        int_responsavel
        ica_nome
        via_id
        via_nome
        int_previsao_alta
        int_id_internacoes_caracteres
        ica_id
        crs_numero
        cpf_numero
        possuiSaida
        enviadoPep
        possuiSaidaPep
        int_id_enderecamento_pep
        int_registro_geral
        ini_id
        ini_id_sexos
        ini_descricao
        ini_data_aproximada
        ppr_numero
        usu_nome
        int_id_visitantes_acompanhantes
        dias_internado
        horas_internado
        minutos_internado
        companionDocuments {
          rows {
            dva_id
            dva_id_visitantes_acompanhantes
            dva_id_documentos
            dva_numero_documentos
            dva_nome
          }
        }
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.CustomHospitalizationSearch;
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors);
  }
};
