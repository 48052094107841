<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchProfessionalSectorProcedure from "./action/SearchProfessionalSectorProcedure";

export default {
  name: "RgComboxUnithealth",
  mixins: [RgComboboxMixin],
  props: {
    sectorId: {
      type: Number,
    },
    procedureName: {
      type: String,
    },
  },
  data: () => {
    return {};
  },
  watch: {
    sectorId() {
      this.fillData();
    },
    procedureName() {
      this.fillData();
    },
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];
      if (this.sectorId && this.procedureName) {
        const data = await SearchProfessionalSectorProcedure(
          this.sectorId,
          this.procedureName,
        );

        data.forEach((item) => {
          this.mData.push({
            value: item.data,
            text: item.label,
            vus_id: item.idVinculoSetor,
            fun_id: item.fun_id,
          });
        });
        if (this.mData.length === 1) {
          this.$emit("selectOnlyItem", this.mData[0]);
        }
      }
    },
  },
};
</script>
