<template lang="html">
  <svg
    width="512pt"
    height="512pt"
    version="1.1"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="#c0cadd">
      <path
        d="m512 194v-102.3h-38v-64.699h-147.04c-18.902-16.801-43.762-27-70.961-27s-52.059 10.199-70.961 27h-147.04v64.699h-38v102.3h27.262v288h-27.262v30h512v-30h-27.262v-288zm-197.5-137c8.3594 9.7617 14.328 21.641 16.969 34.699 1 4.9492 1.5312 10.062 1.5312 15.301 0 5.0312-0.48047 9.9414-1.4219 14.699-3.207 16.621-11.797 31.352-23.867 42.301-0.0625 0.058594-0.12109 0.10938-0.17969 0.16016-13.652 12.328-31.73 19.84-51.531 19.84s-37.879-7.5117-51.531-19.84c-0.058594-0.050781-0.11719-0.10156-0.17969-0.16016-12.07-10.949-20.66-25.68-23.867-42.301-0.94141-4.7578-1.4219-9.668-1.4219-14.699 0-5.2383 0.53125-10.352 1.5312-15.301 2.6406-13.059 8.6094-24.938 16.969-34.699 14.121-16.52 35.109-27 58.5-27s44.379 10.48 58.5 27zm167.5 107h-135.49c7.957-12.578 13.359-26.922 15.469-42.301h120.02zm-38-107v34.699h-82.102c-1.7695-12.379-5.668-24.07-11.32-34.699zm-376 0h93.422c-5.6523 10.629-9.5508 22.32-11.32 34.699h-82.102zm-38 64.699h120.02c2.1094 15.379 7.5117 29.723 15.469 42.301h-135.49zm180 360.3h-90v-206h90zm30 0v-236h-150v236h-32.738v-287.84h136.74c17.5 12.488 38.91 19.84 62 19.84s44.5-7.3516 62-19.84h136.74v287.84z"
      />
      <path d="m274 394h148v-148h-148zm30-118h88v88h-88z" />
      <path
        d="m241 60.621v31.078h-31.078v30h31.078v31.082h30v-31.082h31.078v-30h-31.078v-31.078z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: "PharmacyUnit",
};
</script>
