<style lang="scss" src="./AnticipateService.scss" scoped></style>
<template>
  <div class="rg-alert-modal">
    <RgBaseModal
      class="rg-alert-base-modal"
      :show="show"
      :size="{ minWidth: 720, minHeight: 260, unit: 'px' }"
    >
      <div slot="header" class="confirm-header">
        <div class="--title">
          <span>Antecipar chamada</span>
          <div>
            <IconTooltip
              message="Finalizar chamada"
              icon-title="Informação"
              msg-left
              icon-close
              :actionMsg="actionClose"
            />
          </div>
        </div>
        <span>Antecipar a chamada por força maior</span>
      </div>

      <div slot="body" class="confirm-content">
        <span>{{ name }}</span>

        <RgTextArea
          v-model="reason"
          :rules="{ required: true }"
          :maxlength="255"
          label="Motivo"
        />
      </div>

      <div slot="footer" class="confirm-actions-footer">
        <IconButton
          ref="btnMeet"
          title="Atender"
          :disabled="disabledBtn"
          :permission="hasMeetPermission"
          @click="actionMeet"
        >
          <div slot="icon"><IconMakeCall /></div>
        </IconButton>

        <IconButton
          v-if="!isCalling"
          ref="btnCallAgain"
          blue
          :title="titleBtnCalling"
          :disabled="disabledBtn"
          @click="actionCallAgain"
        >
          <div slot="icon" class="icon-check"><IconCallNext /></div>
        </IconButton>
        <IconButton v-else ref="btnMeet" green :class="{ disable: isCalling }">
          <div slot="title">Chamando</div>
          <div slot="icon">
            <AnimationLoop v-bind="propsAnimationLoopSvg"></AnimationLoop>
          </div>
        </IconButton>
      </div>
    </RgBaseModal>
  </div>
</template>

<script type="text/javascript">
import RgBaseModal from "~tokio/primitive/notification/modal/RgBaseModal.vue";
import IconButton from "~tokio/primitive/button/rg-icon-button/RgIconButton";
import { IconTooltip, RgTextArea } from "~tokio/primitive";
import { IconMakeCall, IconCallNext } from "~tokio/primitive/icon/symbols";
import AnimationLoop from "~tokio/foundation/animation/loop/Loop.vue";

export default {
  name: "ModalCall",

  components: {
    RgBaseModal,
    IconButton,
    IconMakeCall,
    IconCallNext,
    RgTextArea,
    AnimationLoop,
    IconTooltip,
  },

  props: {
    show: {
      type: Boolean,
      default: undefined,
      required: true,
    },

    name: {
      type: String,
      required: true,
    },

    sectorId: {
      required: true,
    },

    isCalling: Boolean,

    meet: Function,
    call: Function,
    close: Function,
  },

  data() {
    return {
      reason: null,
      alreadyCalled: false,
    };
  },

  computed: {
    disabledBtn() {
      if (!this.reason) {
        return true;
      }
      return this.reason.length < 5;
    },

    propsAnimationLoopSvg() {
      const typeFrame = "icon";
      const frames = [
        "symbols/CallAgainThree",
        "symbols/CallAgainOne",
        "symbols/CallAgainTwo",
      ];

      return { frames, typeFrame };
    },
    propsAnimationLoopText() {
      const typeFrame = "text";
      const frames = ["Chamando.", "Chamando..", "Chamando..."];

      return { frames, typeFrame };
    },

    titleBtnCalling() {
      return this.alreadyCalled ? "Chamar novamente" : "Chamar";
    },

    hasMeetPermission() {
      return this.$Permissions.sector.has(
        "emergencia.buscaPreCadastro.darEntrada",
        this.sectorId || 0,
      );
    },
  },

  methods: {
    actionClose() {
      this.close();
    },
    actionMeet() {
      this.$refs.btnMeet.actionDone();
      this.meet({ reason: this.reason });
    },
    actionCallAgain() {
      this.alreadyCalled = true;
      this.$refs.btnCallAgain.actionDone();
      this.call({ reason: this.reason });
    },
  },
};
</script>
