<style src="./Internalization-report.scss" lang="scss" scoped></style>
<template>
  <div>
    <div class="row">
      <span class="filter-title">Período de Internações</span>
      <div class="col-6">
        <RgInputDate
          v-model="form.initialDate"
          label="Data início"
          placeholder="Digite a Data Inicial"
          :rules="{ required: true }"
        />
      </div>
      <div class="col-6">
        <RgInputDate
          v-model="form.finalDate"
          label="Data final"
          placeholder="Digite a Data Final"
          :rules="{ required: true }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { RgInputDate } from "~tokio/primitive";
const moment = require("moment");

export default {
  name: "Emergency",
  components: {
    RgInputDate,
  },
  data() {
    return {
      resultTitle: "Período de Internações",
      form: {
        initialDate: "",
        finalDate: "",
      },
    };
  },
  computed: {
    columnTable() {
      return this.COLUMN;
    },

    getNameSmartTable() {
      return "InternalizationReport";
    },
  },
  created() {
    this.COLUMN = [
      { name: "Nome do Paciente", key: "PACIENTE" },
      { name: "Idade", key: "IDADE" },
      { name: "Telefones", key: "TELEFONES" },
      { name: "Endereço", key: "ENDERECO" },
      { name: "Nome da Mãe", key: "NOME_DA_MAE" },
      { name: "Unidade de Saúde", key: "UNIDADE_DE_SAUDE" },
      { name: "Data do Atendimento", key: "DATA_DA_INTERNACAO" },
      { name: "Data da Saída", key: "DATA_DA_SAIDA" },
      { name: "Nome do Funcionário", key: "FUNCIONARIO" },
      { name: "Ocupação", key: "OCUPACAO" },
    ];
  },
  mounted() {
    this.form.initialDate = moment().add(-2, "days").format("DD/MM/YYYY");
    this.form.finalDate = moment().format("DD/MM/YYYY");
  },
  methods: {
    buildFilter() {
      return {
        initialDate: this.$utils.date.BrazilianDateToDatabase(
          this.form.initialDate,
        ),
        finalDate: this.$utils.date.BrazilianDateToDatabase(
          this.form.finalDate,
        ),
      };
    },
    async doSearch(pData) {
      try {
        return this.$store.dispatch(
          "Billing/HospitalizationBilling/GET_INTERNALIZATION_REPORTS",
          pData,
        );
      } catch (error) {
        return false;
      }
    },
    clearFilter() {
      this.form = {
        initialDate: "",
        finalDate: "",
      };
    },
  },
};
</script>
