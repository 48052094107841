import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import PatientDetails from "$person/patient/view/patient-details/PatientDetails";
import { OpenMenuModule } from "~common/utils/app/menu";

export default {
  path: "/person/patient/details",
  name: "person.patient.details",
  components: {
    default: PatientDetails,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Pacientes", link: OpenMenuModule("register") },
      { label: "Cadastrar Paciente", link: "/person/patient/details" },
    ],
  },
};
