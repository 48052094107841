<style src="./ListContent.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="list-content">
    <ul class="bulletin-list">
      <li
        v-for="(bulletin, idx) in bulletinsList"
        :key="idx"
        class="item"
        @click="selectBulletin(bulletin)"
      >
        <div
          :class="setStatus(bulletin, (type = 'bar'))"
          :title="setStatus(bulletin, (type = 'title'))"
        />

        <div class="column-numbers">
          <div class="field bulletin-number">
            <span title="Nº do Boletim"
              ><IconDocument />{{ bulletin.bol_numero | zeroFill }}</span
            >
          </div>
          <span class="field bulletin-date" title="Data do Atendimento"
            ><IconCalendar />{{ bulletin.bol_data }}</span
          >
        </div>

        <div class="column-person-info">
          <div class="person-name-container">
            <span class="field person-name" title="Nome do Paciente"
              ><IconUser />{{ bulletin.pes_nome }}</span
            >
          </div>

          <div class="person-info-container">
            <span class="field person-cns" title="Nº CNS"
              ><IconCard />{{ bulletin.cns | Cns }}</span
            >
            <span class="field person-birth" title="Data de Nascimento"
              ><IconCake />{{ bulletin.pes_nascimento }}</span
            >
          </div>
        </div>
      </li>
    </ul>

    <div v-show="bulletinsList.length <= 0" class="empty-container">
      <IconEmpty class="empty-icon" />
      <span class="empty-description">Nenhum Registro</span>
    </div>
  </section>
</template>

<script>
import {
  IconCalendar,
  IconUser,
  IconDocument,
  IconCard,
  IconCake,
  IconEmpty,
} from "~tokio/primitive";

export default {
  name: "ListContent",
  components: {
    IconCalendar,
    IconUser,
    IconDocument,
    IconCard,
    IconCake,
    IconEmpty,
  },
  filters: {
    zeroFill(pValue) {
      return pValue.toString().padStart(4, "0");
    },
    Cns(pValue) {
      if (!pValue) return "NÃO INFORMADO";
      return pValue.replace(/(\d{3})(\d{4})(\d{4})(\d{4})/gi, "$1 $2 $3 $4");
    },
  },
  data() {
    return {
      bulletinsList: [],
    };
  },
  computed: {
    bulletins() {
      return this.$store.getters["Billing/Bulletin/GET_BULLETIN_LIST"];
    },
  },
  watch: {
    bulletins(pBulletinsList) {
      this.bulletinsList = pBulletinsList;
    },
  },
  mounted() {
    this.bulletinsList = this.$store.getters[
      "Billing/Bulletin/GET_BULLETIN_LIST"
    ];
  },
  methods: {
    clearList() {
      this.bulletinsList = [];
    },
    selectBulletin({ bol_id, bof_id }) {
      this.$emit("click", { bolId: bol_id, bofId: bof_id });
    },
    setStatus(pItem, pType) {
      const status = ["column-status"];
      const title = [];
      switch (pItem.bof_faturado) {
        case null: {
          status.push("todo");
          title.push("Aberto");
          break;
        }
        case 0: {
          status.push("billing");
          title.push("Faturado");
          break;
        }
        case 1: {
          status.push("billed");
          title.push("Exportado");
          break;
        }
        case 2: {
          status.push("imported");
          title.push("Importado de outro Sistema");
          break;
        }
      }
      return pType === "bar" ? status : title;
    },
  },
};
</script>
