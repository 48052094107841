<style src="./EmployeeSearch.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="employee-search">
    <RgEmployeeSearch @search="search" @employee-insert="employeeInsert" />

    <div class="search-result-list">
      <RgEmployeeCard
        v-for="(searchResultItem, idx) in employeeList"
        :key="idx"
        :employee-data="searchResultItem"
        :list-index="idx"
        class="person-card-item"
        @content-click="personSelected"
      />
    </div>
  </section>
</template>

<script>
import { RgEmployeeSearch, RgEmployeeCard } from "$employee/common/components";

export default {
  name: "EmployeeSearch",
  components: {
    RgEmployeeSearch,
    RgEmployeeCard,
  },
  data() {
    return {
      employeeList: [],
    };
  },
  methods: {
    async search(query) {
      this.employeeList = await this.$store.dispatch(
        "Employee/GET_EMPLOYEE_LIST",
        query,
      );
    },
    employeeInsert(pInsertPerson) {
      this.$router.push({ name: "employee.detail" });
    },
    personSelected(pData) {
      this.$router.push({
        name: "employee.editDetail",
        params: { employeeId: pData.fun_id },
      });
    },
    personTitleSelected() {},
  },
};
</script>
