<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchVacanciesAvailableScale from "./action/SearchVacanciesAvailableScale";

export default {
  name: "RgComboboxVacancies",
  mixins: [RgComboboxMixin],
  props: {
    scheduleId: {
      type: [String, Number],
      default: null,
    },
    examDate: {
      type: [String, Number],
      default: null,
    },
    occupiedVacancies: {
      type: [String, Number],
      default: null,
    },
    edition: {
      type: Boolean,
    },
  },

  data() {
    return {
      mData: [],
    };
  },

  watch: {
    scheduleId() {
      this.fillData();
    },
  },

  mounted() {
    this.fillData();
  },

  methods: {
    async fillData() {
      const max = await this.getData();
      this.mData = [];

      let count = 1;
      while (count <= max) {
        let str = `${count} vagas`;
        if (count === 1) {
          str = `${count} vaga`;
        }
        this.mData.push({ value: count.toString(), text: str });
        count++;
      }

      if (this.mData.length === 1) {
        this.$emit("selectOnlyItem", this.mData[0].value);
      }
    },

    async getData() {
      if (!this.scheduleId || !this.examDate) return 0;
      const data = await SearchVacanciesAvailableScale({
        eea_id: this.scheduleId,
        dataExame: this.examDate,
      });

      return this.edition
        ? parseInt(data) + parseInt(this.occupiedVacancies || 0)
        : parseInt(data);
    },
  },
};
</script>
