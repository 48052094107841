import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  return AuthLegacyRequest.post(
    "consultas/controller-escala-agendamento/buscar",
    variables,
  )
    .then(({ data }) => {
      if (data.dados === "0") {
        return [];
      }
      data = HandleResponse(data);
      data.dados.data = data.dados.recordSet;

      if (data.dados.data) {
        data.dados.data.map((item) => {
          item.validade = `${item.eag_data_inicio_escala} - ${item.eag_data_fim_escala}`;
          item.cte_nome = item.cte_nome.toUpperCase();
        });
      }

      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
