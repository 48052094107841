import SurgeryControlModel from "$surgery-center/surgery/model/SurgeryControlModel";
import Store from "@/store";
export default ({ state, commit }) => {
  // always send the current unit health
  const unitHealth = Store.getters["Login/GET_UNIT_HEALTH_ID"];
  SurgeryControlModel.search({
    ...state.filterListBy,
    uns_id: unitHealth,
  }).then((res) => {
    commit("LIST_ITEMS", res.data);
  });
};
