import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import ReportCentralizer from "$report/view/ReportCentralizer";
import { OpenMenuModule } from "~common/utils/app/menu";

export default {
  path: "/report/centralizer",
  name: "report.centralizer",
  components: {
    default: ReportCentralizer,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Relatórios", link: OpenMenuModule("report") },
      { label: "Centralizador", link: "/report/centralizer" },
    ],
  },
};
