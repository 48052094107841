import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchModules(
    $mod_id: [Int]
    $mod_nome: String
    $mod_descricao: String
  ) {
    modules(
      mod_id: $mod_id
      mod_nome: $mod_nome
      mod_descricao: $mod_descricao
    ) {
      count
      rows {
        mod_id
        mod_nome
        mod_descricao
      }
    }
  }
`;

export default async (variables) => {
  try {
    const { data } = await GraphQLClient.query({ query, variables });
    return data.modules.rows;
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors[0].message);
  }
};
