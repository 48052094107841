import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation SavePatientExchange(
    $originBed: originBedType
    $destinyBed: destinyBedType
  ) {
    SavePatientExchange(originBed: $originBed, destinyBed: $destinyBed) {
      count
      rows {
        itf_id
        int_id
        inl_id
        isl_id
        lca_id
        pci_id
        pac_id
        lca_id
        pes_id
        set_id
        uns_id
      }
    }
  }
`;
export default async ({ commit }, variables) => {
  const { data } = await GraphQLClient.mutation({ mutation, variables });
  return data.SavePatientExchange;
};
