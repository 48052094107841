<template lang="html">
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.2106 14.1735C10.7731 14.3462 10.4248 14.4771 10.1636 14.5678C9.90315 14.6585 9.6002 14.7038 9.25553 14.7038C8.72592 14.7038 8.3136 14.5743 8.02001 14.316C7.72643 14.0576 7.58035 13.7302 7.58035 13.3323C7.58035 13.1776 7.59115 13.0193 7.61273 12.8581C7.63504 12.6969 7.6703 12.5156 7.71851 12.3119L8.26611 10.3777C8.31432 10.1921 8.35606 10.0158 8.38916 9.85171C8.42226 9.68621 8.43809 9.53438 8.43809 9.39622C8.43809 9.15012 8.387 8.97742 8.28554 8.88028C8.18264 8.78314 7.98907 8.73565 7.70052 8.73565C7.55948 8.73565 7.41413 8.75651 7.26518 8.80041C7.11766 8.84574 6.98958 8.88676 6.88452 8.92705L7.02916 8.33124C7.38751 8.18517 7.73074 8.05996 8.05815 7.95634C8.38556 7.85129 8.69498 7.79948 8.9864 7.79948C9.51242 7.79948 9.91826 7.92756 10.2039 8.18085C10.4882 8.43486 10.6314 8.76515 10.6314 9.17099C10.6314 9.25518 10.6213 9.40341 10.6019 9.61497C10.5824 9.82724 10.5457 10.0208 10.4925 10.1985L9.94776 12.127C9.90315 12.2817 9.86357 12.4587 9.82759 12.6566C9.79233 12.8545 9.77506 13.0056 9.77506 13.1071C9.77506 13.3632 9.83191 13.5381 9.94704 13.6309C10.0607 13.7238 10.2601 13.7705 10.5421 13.7705C10.6753 13.7705 10.8242 13.7468 10.9926 13.7007C11.1595 13.6547 11.2804 13.6137 11.3567 13.5784L11.2106 14.1735ZM11.1142 6.34593C10.8602 6.58195 10.5544 6.69996 10.1967 6.69996C9.83982 6.69996 9.53184 6.58195 9.27567 6.34593C9.02094 6.10991 8.89214 5.8228 8.89214 5.48748C8.89214 5.15287 9.02166 4.86504 9.27567 4.62686C9.53184 4.38796 9.83982 4.26923 10.1967 4.26923C10.5544 4.26923 10.8609 4.38796 11.1142 4.62686C11.3682 4.86504 11.4956 5.15287 11.4956 5.48748C11.4956 5.82352 11.3682 6.10991 11.1142 6.34593Z"
      fill="#2B5D8A"
    />
    <circle cx="9.5" cy="9.5" r="8.5" stroke="#2B5D8A" stroke-width="1.5" />
  </svg>
</template>

<script>
export default {
  name: "InfoHelperAbout",
};
</script>
