<style src="./ColorIndication.scss" lang="scss" scoped></style>

<template>
  <section class="color-indication">
    <div
      class="circle"
      :style="{ 'background-color': color, width: size, height: size }"
    />
  </section>
</template>

<script>
export default {
  name: "ColorIndication",

  props: {
    value: String,
    size: {
      type: String,
      default: "16px",
    },
  },

  data() {
    return {
      color: null,
    };
  },

  computed: {},

  watch: {
    value(pValue) {
      this.color = pValue;
    },
  },

  created() {},

  mounted() {
    if (this.value) {
      this.color = this.value;
    }
  },

  methods: {},
};
</script>
