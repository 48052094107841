import Store from "@/store";
import Router from "@/route";
import { toast } from "~tokio/primitive/notification";

const HandleUnauthorized = () => {
  toast.error(
    "Sua sessão possívelmente expirou ou você não tem permissão para executar essa ação.",
    "Por favor, realize o login novamente.",
  );

  Store.dispatch("Login/LOGOFF");
  Router.push("/login");
};

export default HandleUnauthorized;
