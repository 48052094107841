<template lang="html">
  <section class="report-centralizer">
    <RgSearch
      ref="SearchRefs"
      v-model="mutableReport"
      :build-filter="buildFilter"
      :search-function="searchFunction"
      :print-report-css="printReportCss"
      :result-title="resultTitle"
      :is-retractable="true"
      :clear-function="clearFilter"
      :item-height="29"
      :max-register="50"
      filter-title="Relatório"
      showEmptyMessage
      is-report
      @afterPerformSearch="afterSearchFilter"
      @count="getCountValue"
    >
      <div slot="extra" class="modules">
        <RgSelect
          v-show="!isFromSpecificModule"
          v-model="selectedModule"
          :value="selectedModule"
          :placeholder="`Selecione um módulo`"
          :options="MODULES"
          data-id="busca-modulo"
          data-item="resultado-modulo"
          class="modulesItem"
        />

        <RgSelect
          v-model="selectedReport"
          :value="selectedReport"
          :placeholder="`Selecione um relatório`"
          :options="reports"
          data-id="busca-relatorio"
          data-item="resultado-relatorio"
          class="modulesItem"
        />

        <div v-if="selectedReport" class="filter-title">
          <span class="title">Filtros</span>
        </div>
      </div>

      <div slot="filters" class="filters">
        <!-- <keep-alive> -->
        <component :is="component" ref="Report" class="component" />
        <!-- </keep-alive> -->
      </div>

      <div class="report-centralizer-search">
        <SmartTable
          ref="smartTable"
          :name="getNameSmartTable"
          :columns="columnReportTable"
          :body="mutableReport"
          :total="Number(total)"
          :initialColumns="6"
          :showPagination="false"
          :dynamic-height="getSpaceTable"
          :show-select-all-page="false"
          :index-column="indexColumn"
          toggle-selected
          dontSelect
          multSelect
          hasCheck
          class="smart-table"
        >
          <div slot="legends" class="legends">{{ getLegend }}</div>
        </SmartTable>
      </div>
    </RgSearch>
  </section>
</template>

<script>
import { RgSelect, RgFilterButton } from "~tokio/primitive";
import { RgSearch, SmartTable } from "~tokio/foundation";
import { IconUpArrow } from "~tokio/primitive/icon/symbols";
import MODULES_LIST from "./ListModules";

export default {
  name: "ReportCentralizer",
  components: {
    IconUpArrow,
    RgSearch,
    RgSelect,
    RgFilterButton,
    SmartTable,
  },
  data() {
    return {
      mutableReport: [],
      selectedModule: null,
      selectedReport: null,
      filterComponent: null,
      reversed: false,
      isFromSpecificModule: false,
      total: 0,
      dynamicHeight: null,
      indexColumn: "",
      report: {
        resultTitle: "",
        printReportCss: "",
        doSearch: () => {},
        buildFilter: () => {},
        clearFilter: () => {},
      },
    };
  },
  computed: {
    getSpaceTable() {
      return this.dynamicHeight;
    },
    reports() {
      return this.selectedModule ? this.selectedModule : [];
    },
    component() {
      return this.selectedReport ? this.selectedReport : null;
    },
    resultTitle() {
      return this.report ? this.report.resultTitle : "";
    },
    searchFunction() {
      return this.report ? this.report.doSearch : () => {};
    },
    printReportCss() {
      return this.report ? this.report.printReportCss : "";
    },
    columnReportTable() {
      return this.report ? this.report.columnTable : "";
    },
    buildFilter() {
      return this.report ? this.report.buildFilter : () => {};
    },
    clearFilter() {
      return this.report ? this.report.clearFilter : () => {};
    },
    getLegend() {
      return this.report ? this.report.getLegend : "";
    },
    getNameSmartTable() {
      return this.report ? this.report.getNameSmartTable : null;
    },
  },
  watch: {
    selectedModule(pValue) {
      this.$nextTick(() => {
        this.selectedReport = null;
        this.clearFilter();
      });
    },

    selectedReport(pValue) {
      this.$loader.start("Carregando Relatório...");
      this.mutableReport = [];
      this.$nextTick(() => {
        this.report = this.$refs.Report;
        this.$refs.SearchRefs.clearForm();
      });
      this.$loader.finish(1000);
    },

    mutableReport(pValue) {
      if (pValue && pValue.length > 0) {
        this.setIndex(pValue);
      }
    },
  },

  created() {
    this.MODULES = MODULES_LIST;
  },

  mounted() {
    this.preparePage();
  },
  methods: {
    preparePage() {
      try {
        this.$loader.start("Carregando Relatórios...");
        this.isFromSpecificModule = false;
        this.getDynamicHeight();

        const moduleName = this.$route.meta.breadcrumb[0].label;
        const specificModule = this.MODULES.find(
          (item) => item.label === moduleName,
        );

        if (specificModule) {
          this.isFromSpecificModule = true;
          this.selectedModule = specificModule.value;
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.$loader.finish(1000);
      }
    },

    getCountValue(pValue) {
      this.total = Number(pValue) || 0;
    },

    getDynamicHeight() {
      if (this.$refs.SearchRefs && this.$refs.SearchRefs.$refs.listContent) {
        const height =
          this.$refs.SearchRefs.$refs.listContent.offsetHeight - 40;

        this.dynamicHeight = height;
      }
    },

    afterSearchFilter() {
      // GET DYNAMIC HEIGHT
      this.getDynamicHeight();
    },

    setIndex(pValue) {
      const nameObjects = Object.keys(pValue[0]);
      nameObjects.forEach((item) => {
        const hasId = item.indexOf("_id");
        if (hasId !== -1) this.indexColumn = item;
      });
    },
  },
};
</script>
