<style src="./RgComboboxCompanionType.scss" lang="scss"></style>
<script>
import { RgRemoteComboboxMixin } from "~tokio/primitive";
import ListCompanionType from "./action/ListCompanionType";

export default {
  name: "RgComboboxCompanionType",
  mixins: [RgRemoteComboboxMixin],
  props: {
    label: {
      default: "Tipo de Responsável",
    },
  },
  data: () => {
    return {};
  },
  methods: {
    doSearch() {
      return ListCompanionType();
    },
    formatItem(pData) {
      return {
        value: pData.res_id,
        text: pData.res_nome,
      };
    },
  },
};
</script>
