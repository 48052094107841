<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchUnithealth from "./action/SearchUnithealth";

export default {
  name: "RgComboxUnithealth",
  mixins: [RgComboboxMixin],
  props: {
    submoduleId: {
      required: true,
      type: Number,
      default: 0,
    },
    procedureName: {
      type: String,
      default: "",
    },
  },
  data: () => {
    return {};
  },
  watch: {
    procedureName(pValue) {
      this.fillData();
    },
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];
      const data = await SearchUnithealth(this.submoduleId, this.procedureName);

      data.forEach((item) => {
        this.mData.push({ value: item.data, text: item.label });
      });
    },
  },
};
</script>
