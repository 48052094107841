import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation DeleteEmployeeRelationshipSector(
    $employeeRelationshipSectorId: Int!
  ) {
    DeleteEmployeeRelationshipSector(
      employeeRelationshipSectorId: $employeeRelationshipSectorId
    ) {
      vus_id
    }
  }
`;

export default async (store, { employeeRelationshipSectorId }) => {
  if (!employeeRelationshipSectorId)
    Promise.reject(Error("Não foi possível remover"));
  const variables = {
    employeeRelationshipSectorId,
  };

  try {
    const { data } = await GraphQLClient.mutate({ mutation, variables });
    return data.DeleteEmployeeRelationshipSector;
  } catch (err) {
    return Promise.reject(err.graphQLErrors[0]);
  }
};
