<template lang="html">
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.006 14.1784C10.006 14.5307 9.80245 14.8508 9.48387 15.0002C9.36069 15.0584 9.2287 15.0866 9.09757 15.0866C8.88992 15.0866 8.68419 15.0152 8.51802 14.8774L3.55274 10.763H0.908231C0.406716 10.7634 0 10.3565 0 9.85497V6.23644C0 5.73471 0.406716 5.328 0.908231 5.328H3.55296L8.51824 1.21355C8.78953 0.988696 9.16572 0.940759 9.48408 1.09123C9.80245 1.24064 10.0062 1.56094 10.0062 1.91305L10.006 14.1784ZM13.4964 12.7592C13.4743 12.7607 13.453 12.7615 13.4311 12.7615C13.1912 12.7615 12.9599 12.6667 12.789 12.4956L12.6675 12.3737C12.3489 12.0558 12.3115 11.5521 12.5798 11.1905C13.26 10.2735 13.619 9.18642 13.619 8.04603C13.619 6.81943 13.2114 5.66893 12.4401 4.71878C12.1464 4.35764 12.1735 3.83312 12.5026 3.50423L12.6239 3.38277C12.8053 3.20134 13.0484 3.10246 13.3116 3.11815C13.5678 3.13105 13.807 3.25164 13.9696 3.45027C15.0394 4.75941 15.6046 6.34887 15.6046 8.04624C15.6046 9.6271 15.1048 11.1319 14.1589 12.3972C14.0009 12.608 13.7593 12.7405 13.4964 12.7592ZM17.2514 15.566C17.0872 15.7601 16.8497 15.8766 16.5954 15.8873C16.5829 15.8878 16.5702 15.8882 16.5573 15.8882C16.3168 15.8882 16.0859 15.7932 15.915 15.6223L15.7957 15.503C15.4623 15.1698 15.4397 14.6369 15.7428 14.2762C17.2076 12.5341 18.0146 10.3217 18.0146 8.04603C18.0146 5.67904 17.1506 3.4004 15.5824 1.62994C15.2645 1.27052 15.2806 0.726438 15.619 0.386792L15.7381 0.267486C15.9148 0.0899238 16.1444 -0.00788563 16.4077 0.00049804C16.6577 0.00759192 16.8942 0.118084 17.0603 0.305105C18.9559 2.43929 20 5.1887 20 8.04603C20.0004 10.7952 19.0243 13.466 17.2514 15.566Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "CallNext",
};
</script>
