<style src="./RgComboboxConscience.scss" lang="scss"></style>
<script>
import { RgRemoteComboboxMixin } from "~tokio/primitive";
import SurgeryConscienceModel from "$surgery-center/surgery/model/SurgeryConscienceModel";

export default {
  name: "RgComboboxConscience",
  mixins: [RgRemoteComboboxMixin],
  data: () => {
    return {
      model: SurgeryConscienceModel,
    };
  },
  methods: {
    formatItem(pData) {
      return {
        value: pData.cns_id,
        text: pData.cns_nome,
      };
    },
  },
};
</script>
