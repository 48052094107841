import GetCriticalReleaseRequest from "./GetCriticalReleaseRequest.js";

export default (pObject) => {
  const values = [];
  let criticalReleaseRequest = { id: 0, name: "", canBeSelected: null };

  Object.keys(pObject).map((key, index) => {
    if (pObject[key] === 1) {
      criticalReleaseRequest = GetCriticalReleaseRequest().find(
        (item) => `ics_${item.related}` === key,
      );
      values.push({
        value: criticalReleaseRequest.id,
        label: `${criticalReleaseRequest.id} - ${criticalReleaseRequest.name}`,
        canBeSelected: criticalReleaseRequest.canBeSelected,
      });
    }
  });

  return values;
};
