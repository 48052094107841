import AuthRequest from "~common/request/AuthRequest";

export default async (context, body) => {
  try {
    return AuthRequest.post("/basic-attention/export", {
      unitHealth: body.unitHealth,
      dataBegin: body.dataBegin,
      dataEnd: body.dataEnd,
      forms: body.forms,
    });
  } catch (err) {
    throw new Error("Não foi possivél realizar a exportação");
  }
};
