import { render, staticRenderFns } from "./RgDocumentButton.vue?vue&type=template&id=a216be76&scoped=true&lang=html&"
import script from "./RgDocumentButton.vue?vue&type=script&lang=js&"
export * from "./RgDocumentButton.vue?vue&type=script&lang=js&"
import style0 from "./RgDocumentButton.scss?vue&type=style&index=0&id=a216be76&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a216be76",
  null
  
)

export default component.exports