<template lang="html">
  <fieldset class="rg-radio-bed-type">
    <span class="label"> Listar por Leito </span>
    <div class="container unselect">
      <div
        :class="{
          '-activeextra _color-white': selectedStatus.includes(
            BED_STATUS.EXTRAS,
          ),
        }"
        class="radio -extra justify-content-center align-items-center"
        @click="toggleStatus(BED_STATUS.EXTRAS)"
      >
        Extra
      </div>
      <div
        :class="{
          '-activeobservacao _color-white': selectedStatus.includes(
            BED_STATUS.OBSERVACAO,
          ),
        }"
        class="radio -observacao justify-content-center align-items-center"
        @click="toggleStatus(BED_STATUS.OBSERVACAO)"
      >
        Observação
      </div>
    </div>
  </fieldset>
</template>

<script>
import BED_STATUS from "./getBedType";

export default {
  name: "RgRadioBedType",
  props: {
    value: {
      default: null,
    },
  },
  data() {
    return {
      selectedStatus: [1, 2],
    };
  },
  computed: {
    BED_STATUS() {
      return { ...BED_STATUS };
    },
  },
  watch: {
    value(pValue) {
      this.selectedStatus = pValue;
    },
  },
  mounted() {
    this.selectedStatus = this.value || [1, 2];
  },
  methods: {
    toggleStatus(pItem) {
      if (this.selectedStatus.includes(pItem)) {
        this.selectedStatus.splice(this.selectedStatus.indexOf(pItem), 1);
      } else {
        this.selectedStatus.push(pItem);
      }
      this.$emit("input", this.selectedStatus);
    },
  },
};
</script>
