<template lang="html">
  <div class="modal-scale-conflict">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <div class="title">Problemas com Escala</div>
      </div>

      <div slot="body" class="body">
        <div class="description">
          <strong class="title">Possíveis causas: </strong>
          <span class="text">
            Conflitos de validade, datas e/ou horários informados.
          </span>
        </div>

        <FormBase class="form-base" title="Escalas conflitantes">
          <div class="conflicts">
            <div
              v-for="(conflict, index) in mutableConflicts"
              :key="conflict.key"
              class="item"
            >
              <span class="label">
                Unidade de saúde:
                <strong :id="index" class="value">
                  {{ getValue(conflict, "uns_nome") }}
                </strong>
              </span>

              <span class="label">
                Setor:
                <strong :id="index" class="value">
                  {{ getValue(conflict, "set_nome") }}
                </strong>
              </span>

              <span class="label">
                Tipo de escala:
                <strong :id="index" class="value">
                  {{ getValue(conflict, "ees_nome") }}
                </strong>
              </span>

              <span class="label">
                Dia da semana:
                <strong :id="index" class="value">
                  {{ getValue(conflict, "dsm_nome") }}
                </strong>
              </span>

              <span class="label">
                Período Inicial:
                <strong :id="index" class="value">
                  {{ getValue(conflict, "eea_data_inicio_escala") }}
                </strong>
              </span>

              <span class="label">
                Período Final:
                <strong :id="index" class="value">
                  {{ getValue(conflict, "eea_data_fim_escala") }}
                </strong>
              </span>

              <span class="label">
                Início:
                <strong :id="index" class="value">
                  {{ getValue(conflict, "eea_inicio_atendimentos") }}
                </strong>
              </span>

              <span class="label">
                Final:
                <strong :id="index" class="value">
                  {{ getValue(conflict, "eea_final_atendimentos") }}
                </strong>
              </span>
            </div>
          </div>
        </FormBase>

        <FormBase class="form-base" title="Dias conflitantes">
          <div v-if="hasHistory" class="history">
            <div
              v-for="(history, index) in mutableHistory"
              :key="history.key"
              class="item"
            >
              <span class="label">
                Unidade de saúde:
                <strong :id="index" class="value">
                  {{ getValue(history, "uns_nome") }}
                </strong>
              </span>

              <span class="label">
                Setor:
                <strong :id="index" class="value">
                  {{ getValue(history, "set_nome") }}
                </strong>
              </span>

              <span class="label">
                Tipo de escala:
                <strong :id="index" class="value">
                  {{ getValue(history, "ees_nome") }}
                </strong>
              </span>

              <span class="label">
                Dia da semana:
                <strong :id="index" class="value">
                  {{ getValue(history, "dsm_nome") }}
                </strong>
              </span>

              <span class="label">
                Período Inicial:
                <strong :id="index" class="value">
                  {{ getValue(history, "eea_data_inicio_escala") }}
                </strong>
              </span>

              <span class="label">
                Período Final:
                <strong :id="index" class="value">
                  {{ getValue(history, "eea_data_fim_escala") }}
                </strong>
              </span>

              <span class="label">
                Início:
                <strong :id="index" class="value">
                  {{ getValue(history, "eea_inicio_atendimentos") }}
                </strong>
              </span>

              <span class="label">
                Final:
                <strong :id="index" class="value">
                  {{ getValue(history, "eea_final_atendimentos") }}
                </strong>
              </span>
            </div>
          </div>

          <div v-else class="without-conflit-day">
            <IconEmpty />
            <span>Não há dias conflitantes</span>
          </div>
        </FormBase>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import FormBase from "~tokio/foundation/form-base/FormBase";
import { RgBaseModal, IconEmpty } from "~tokio/primitive";

export default {
  name: "ModalScaleConflict",
  components: {
    RgBaseModal,
    FormBase,
    IconEmpty,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    conflicts: {
      type: Array,
      default: () => [],
    },
    history: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      mutableConflicts: [],
      mutableHistory: [],
    };
  },

  computed: {
    hasHistory() {
      return (
        Array.isArray(this.mutableHistory) && this.mutableHistory.length > 0
      );
    },

    hasConflicts() {
      return (
        Array.isArray(this.mutableConflicts) && this.mutableHistory.length > 0
      );
    },
  },

  watch: {
    show(pValue) {
      if (!pValue) {
        this.close();
      }
    },

    conflicts(pValue) {
      this.mutableConflicts = pValue;
    },

    history(pValue) {
      console.log(pValue);
      this.mutableHistory = pValue;
    },
  },

  created() {},

  mounted() {
    this.mutableConflicts = this.conflicts;
    this.mutableHistory = this.history;
  },

  methods: {
    getValue(pItem, pKey) {
      return pItem[pKey] || "Não informado";
    },

    close() {
      this.mutableConflicts = [];
      this.mutableHistory = [];
      this.$emit("close");
    },
  },
};
</script>
