const PermissionsMixin = {
  data() {
    return {
      permissions: {
        alteracaoDadosDia: "exame2.alteracaoDadosDia.exibir",
        agendamentoAlterarDadosDiaReservaTecnica:
          "exames2.agendamento.alterarDadosDiaReservaTecnica",
        agendamentoAlterarDadosDia: "exames2.agendamento.alterarDadosDia",
        agendamentoAlterarAgendamentoDiaReservaTecnica:
          "exames2.agendamento.alterarAgendamentoDiaReservaTecnica",
        agendamentoAlterar: "exames2.agendamento.alterar",
      },
    };
  },
  async mounted() {
    this.isPerDay ? this.getPermissionPerDay() : this.getPermissionPerPeriod();
  },
  methods: {
    getPermissionPerDay() {
      console.warn("pegar permissão exames por dia");
      this.permissions = {
        agendamentoAlterarDadosDiaReservaTecnica: "exames2.",
        agendamentoAlterarDadosDia: "exames2.alteracaoDadosDia.",
        agendamentoAlterarAgendamentoDiaReservaTecnica: "exames2.",
        agendamentoAlterar: "exames2.",
      };
    },
    getPermissionPerPeriod() {
      console.warn("pegar permissão exames por periodo");
      this.permissions = {
        agendamentoAlterarDadosDiaReservaTecnica: "exames2.",
        agendamentoAlterarDadosDia: "exames2.alteracaoDadosDia.",
        agendamentoAlterarAgendamentoDiaReservaTecnica: "exames2.",
        agendamentoAlterar: "exames2.",
      };
    },
  },
};

export default PermissionsMixin;
