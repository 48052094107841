<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchContraceptiveMethods from "./action/SearchContraceptiveMethods";

export default {
  name: "RgComboboxContraceptiveMethod",
  mixins: [RgComboboxMixin],
  data: () => {
    return {};
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];
      const data = await SearchContraceptiveMethods();
      data.forEach((item) => {
        this.mData.push({ value: item.imc_id, text: item.imc_descricao });
      });
    },
  },
};
</script>
