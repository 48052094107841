import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

import PatientDetails from "$person/patient/view/patient-details/PatientDetails";
import { OpenMenuModule } from "~common/utils/app/menu";

export default {
  path: "/person/patient/unify/register/patient-record",
  name: "person.patient.unify.register.patient-record",
  components: {
    default: PatientDetails,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Cadastros", link: OpenMenuModule("register") },
      { label: "Unificação de Pacientes", link: "/person/patient/unify" },
      { label: "Unificar Cadastros", link: "/person/patient/unify/register" },
      {
        label: "Cadastro do Paciente",
        link: "/person/patient/unify/register/patient-record",
      },
    ],
  },
};
