<template lang="html">
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M507.156,121.024l-22.113-22.112c-3.129-3.13-7.29-4.853-11.715-4.853s-8.587,1.723-11.715,4.853l-0.912,0.913V84.974
      c0-18.566-15.105-33.671-33.671-33.671H33.671C15.105,51.304,0,66.408,0,84.974v342.052c0,18.566,15.105,33.671,33.671,33.671
      H427.03c18.566,0,33.671-15.105,33.671-33.671V190.911l46.455-46.455C513.615,137.997,513.615,127.485,507.156,121.024z
      M444.667,427.026c0,9.725-7.912,17.637-17.637,17.637H33.671c-9.725,0-17.637-7.912-17.637-17.637V84.974
      c0-9.725,7.912-17.637,17.637-17.637H427.03c9.725,0,17.637,7.912,17.637,17.637v30.885l-35.274,35.274v-40.505
      c0-3.557-2.344-6.689-5.758-7.692c-3.414-1-7.078,0.365-9.002,3.357l-31.737,49.369l-31.737-49.369
      c-1.923-2.992-5.589-4.357-9.002-3.357c-3.413,1.003-5.758,4.135-5.758,7.692v85.513c0,4.427,3.589,8.017,8.017,8.017
      s8.017-3.589,8.017-8.017v-58.217l23.72,36.899c1.475,2.294,4.016,3.681,6.744,3.681c2.728,0,5.269-1.387,6.744-3.681
      l23.72-36.899v29.242L244.133,316.394h-47.988c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h36.439l-24.443,52.377
      h-80.407c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h85.513c0.001,0,0.001,0,0.002,0
      c1.147,0,2.304-0.246,3.387-0.753l64.135-29.93c0.847-0.396,1.618-0.935,2.279-1.596l36.132-36.132h65.09
      c4.427,0,8.017-3.589,8.017-8.017s-3.589-8.017-8.017-8.017h-49.057l109.45-109.449V427.026z M248.323,336.618l21.128,21.128
      l-39.615,18.487L248.323,336.618z M281.658,347.278l-22.867-22.867l188.884-188.884l22.868,22.868L281.658,347.278z
      M495.819,133.118l-13.939,13.939l-22.868-22.868l13.939-13.939c0.208-0.207,0.547-0.207,0.756,0l22.112,22.111
      C496.026,132.571,496.026,132.91,495.819,133.118z"
        ></path>
      </g>
    </g>
    <g>
      <g>
        <path
          d="M110.632,188.124H67.341v-26.723h34.74c4.427,0,8.017-3.589,8.017-8.017s-3.589-8.017-8.017-8.017h-34.74v-26.723h43.291
      c4.427,0,8.017-3.589,8.017-8.017s-3.589-8.017-8.017-8.017H59.325c-4.427,0-8.017,3.589-8.017,8.017v85.513
      c0,4.427,3.589,8.017,8.017,8.017h51.308c4.427,0,8.017-3.589,8.017-8.017S115.06,188.124,110.632,188.124z"
        ></path>
      </g>
    </g>
    <g>
      <g>
        <path
          d="M210.956,191.133l-30.199-37.749l30.199-37.749c2.765-3.457,2.205-8.502-1.252-11.268
      c-3.457-2.766-8.503-2.205-11.268,1.252l-27.945,34.933l-27.946-34.931c-2.766-3.458-7.813-4.019-11.268-1.252
      c-3.457,2.765-4.018,7.811-1.252,11.268l30.199,37.749l-30.199,37.749c-2.765,3.456-2.204,8.5,1.253,11.266
      c1.478,1.182,3.246,1.757,5.003,1.757c2.352,0,4.682-1.03,6.265-3.009l27.946-34.931l27.946,34.931
      c1.583,1.98,3.913,3.009,6.265,3.009c1.756,0,3.525-0.575,5.003-1.757C213.161,199.636,213.722,194.591,210.956,191.133z"
        ></path>
      </g>
    </g>
    <g>
      <g>
        <path
          d="M306.623,194.57l-15.041-75.202c-1.941-9.709-10.537-16.756-20.44-16.756h-13.174c-9.901,0-18.498,7.047-20.439,16.756
      l-15.041,75.202c-0.868,4.341,1.948,8.564,6.289,9.433c4.341,0.867,8.564-1.948,9.433-6.289l3.842-19.209h45.004l3.842,19.209
      c0.762,3.811,4.11,6.447,7.853,6.447c0.521,0,1.05-0.051,1.581-0.157C304.675,203.134,307.491,198.911,306.623,194.57z
      M245.261,162.47l7.992-39.959c0.447-2.24,2.431-3.866,4.715-3.866h13.174c2.285,0,4.269,1.626,4.717,3.866l7.992,39.959H245.261z
      "
        ></path>
      </g>
    </g>
    <g>
      <g>
        <path
          d="M76.427,230.881c-13.851,0-25.119,11.268-25.119,25.119c0,13.851,11.268,25.119,25.119,25.119
      s25.119-11.268,25.119-25.119C101.547,242.149,90.278,230.881,76.427,230.881z M76.427,265.086c-5.01,0-9.086-4.076-9.086-9.086
      c0-5.01,4.076-9.086,9.086-9.086c5.01,0,9.086,4.076,9.086,9.086C85.513,261.01,81.437,265.086,76.427,265.086z"
        ></path>
      </g>
    </g>
    <g>
      <g>
        <path
          d="M76.427,299.291c-13.851,0-25.119,11.268-25.119,25.119s11.268,25.119,25.119,25.119s25.119-11.268,25.119-25.119
      S90.278,299.291,76.427,299.291z M76.427,333.496c-5.01,0-9.086-4.076-9.086-9.086s4.076-9.086,9.086-9.086
      c5.01,0,9.086,4.076,9.086,9.086S81.437,333.496,76.427,333.496z"
        ></path>
      </g>
    </g>
    <g>
      <g>
        <path
          d="M76.427,367.701c-13.851,0-25.119,11.268-25.119,25.119c0,13.851,11.268,25.119,25.119,25.119
      s25.119-11.268,25.119-25.119C101.547,378.97,90.278,367.701,76.427,367.701z M76.427,401.906c-5.01,0-9.086-4.076-9.086-9.086
      c0-5.01,4.076-9.086,9.086-9.086c5.01,0,9.086,4.076,9.086,9.086C85.513,397.831,81.437,401.906,76.427,401.906z"
        ></path>
      </g>
    </g>
    <g>
      <g>
        <path
          d="M264.556,247.983H127.735c-4.427,0-8.017,3.589-8.017,8.017c0,4.427,3.589,8.017,8.017,8.017h136.821
      c4.427,0,8.017-3.589,8.017-8.017C272.572,251.573,268.983,247.983,264.556,247.983z"
        ></path>
      </g>
    </g>
    <g>
      <g>
        <path
          d="M161.94,316.394h-34.205c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h34.205
      c4.427,0,8.017-3.589,8.017-8.017S166.367,316.394,161.94,316.394z"
        ></path>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "Exam",
};
</script>
