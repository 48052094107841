import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async (unitHealthId, procedureName) => {
  return AuthLegacyRequest.post(
    "unidadesaude/controller-setor/combo-box-setores-por-procedimento",
    {
      uns_id: unitHealthId,
      strProcedimento: procedureName,
    },
  )
    .then(({ data }) => {
      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
