import { GraphQLClient, gql } from "@/common/request/GraphQLClient";
import { BrazilianDateToDatabase } from "~utils/date";

const mutation = gql`
  mutation(
    $vni_id_vinculos_setores: Int!
    $vni_id_motivos_inabilitar_funcionarios: Int!
    $vni_data_inicial: String!
    $vni_data_final: String!
  ) {
    DisableEmployeeRelationshipSector(
      vni_id_vinculos_setores: $vni_id_vinculos_setores
      vni_id_motivos_inabilitar_funcionarios: $vni_id_motivos_inabilitar_funcionarios
      vni_data_inicial: $vni_data_inicial
      vni_data_final: $vni_data_final
    ) {
      vni_id
      vni_data_inicial
      vni_data_final
      vni_id_motivos_inabilitar_funcionarios
    }
  }
`;
/**
 * @param {Object} payload
 * @param {Number} payload.vni_id_vinculos_setores
 * @param {Number} payload.vni_id_motivos_inabilitar_funcionarios
 * @param {String} payload.vni_data_inicial
 * @param {String} payload.vni_data_final
 */
export default async ({ rootState }, payload) => {
  const variables = sanitizeFields({ ...payload });
  try {
    const { data } = await GraphQLClient.mutate({
      mutation,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.DisableEmployeeRelationshipSector;
  } catch (err) {
    return Promise.reject(err.graphQLErrors);
  }
};

const sanitizeFields = (pData) => {
  return {
    vni_id_vinculos_setores: Number(pData.vni_id_vinculos_setores),
    vni_id_motivos_inabilitar_funcionarios: Number(
      pData.vni_id_motivos_inabilitar_funcionarios,
    ),
    vni_data_inicial: BrazilianDateToDatabase(pData.vni_data_inicial),
    vni_data_final: BrazilianDateToDatabase(pData.vni_data_final),
  };
};
