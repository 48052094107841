import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchTeamByEmployeeAB($fab_id: Int) {
    SearchTeam(fab_id: $fab_id) {
      rows {
        equ_id
        equ_id_areas
        equ_codigo_ine
        equ_nome_equipe
        equ_id_tipos_equipe
        area {
          are_id
          are_nome
          are_id_zonas
          are_descricao
          are_id_unidades_saude
          unit_health {
            uns_id
            uns_id_clientes
            uns_id_categoria_unidade_saude
            uns_cnes
            uns_nome
            uns_razao_social
            uns_cnpj
            uns_orgao_emissor
            uns_ativo
          }
        }
      }
    }
  }
`;

export default async ({ commit, state }) => {
  if (!state.logged_employee || !state.logged_employee.fab_id) {
    return;
  }
  try {
    const variables = { fab_id: state.logged_employee.fab_id };
    const { data } = await GraphQLClient.query({
      query,
      fetchPolicy: "no-cache",
      variables,
    });
    commit("SET_TEAM_LINKED", data.SearchTeam.rows);
  } catch (err) {
    console.log(err);
    return err.graphQLErrors;
  }
};
