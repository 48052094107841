<style src="./RgListContact.scss" lang="scss" scoped></style>
<template lang="html">
  <div class="rg-contact-list--component scrollstyle">
    <div v-if="contactList.length === 0" class="rg-contact-list--no-content">
      <div class="rg-contact-list--empty-icon-container">
        <IconEmpty class="rg-contact-list--empty-icon" />
      </div>
      <span class="rg-contact-list--empty-caption">
        Essa pessoa ainda não possui um contato cadastrado
      </span>
    </div>
    <ul class="rg-contact-list--list-container">
      <li
        v-for="(contactItem, contactItemIdx) in contactList"
        :key="contactItemIdx"
      >
        <ul class="rg-contact-list--list">
          <li class="rg-contact-list--list__icon">
            <IconMeuEsus
              v-if="
                myEsusInfo.mes_ddd_celular ===
                `${contactItem.ddd.ddd_codigo}${contactItem.tel_numero}`
              "
              class="rg-contact-list--icon"
            />
            <IconTelephone
              v-if="
                myEsusInfo.mes_ddd_celular !==
                `${contactItem.ddd.ddd_codigo}${contactItem.tel_numero}`
              "
              class="rg-contact-list--icon"
            />
          </li>
          <li class="list--phone-number">
            <span
              >({{ contactItem.ddd.ddd_codigo }})
              {{ contactItem.tel_numero }}</span
            >
          </li>
          <li class="list--message">
            <span>{{ contactItem.tel_recado }}</span>
          </li>
          <ContactOptions
            :contact-info="contactItem"
            :contact-item-index="contactItemIdx"
            @suspend-item="suspendItem"
          />
          <!-- <div class="rg-contact-list--suspend-mode" v-if="suspendModeList[`item${contactItemIdx}`]"></div> -->
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { IconEmpty, IconMeuEsus, IconTelephone } from "~tokio/primitive/icon";
import { mapGetters } from "vuex";
import ContactOptions from "../contact-options/ContactOptions";

export default {
  name: "RgListContact",
  components: {
    IconEmpty,
    IconMeuEsus,
    ContactOptions,
    IconTelephone,
  },
  props: {
    pesId: {
      default: "",
    },
    refresher: {
      default: "",
    },
  },
  data() {
    return {
      contactModel: null,
      commands: {
        remove: {
          suspendMode: false,
        },
      },
      suspendModeList: {},
    };
  },
  computed: {
    ...mapGetters({
      contactList: "Patient/LIST_CONTACTS",
      myEsusInfo: ["Patient/GET_MY_ESUS_DATA"],
    }),
  },
  methods: {
    suspendItem(contactIndex) {
      this.$set(this.suspendModeList, [`item${contactIndex}`], true);
    },
    removeConfirm(phoneId, contactIndex) {
      this.$store.dispatch("Patient/DELETE_TELEPHONE", {
        phoneId,
        contactIndex,
      });
    },
  },
};
</script>
