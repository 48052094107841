import SelectedEmployeeData from "./SelectedEmployeeData";
import SelectedEmployeeEffectivenessData from "./SelectedEmployeeEffectivenessData";
import SelectedAppointmentData from "./SelectedAppointmentData";
import SelectedAppointmenEffectivenesstData from "./SelectedAppointmenEffectivenesstData";
import SelectedQueueExternalAppointmentData from "./SelectedQueueExternalAppointmentData";
import SelectedTypeSchedule from "./SelectedTypeSchedule";
import FilterPerDay from "./FilterPerDay";
import FilterPerPeriods from "./FilterPerPeriods";
import FilterQueue from "./FilterQueue";
import FilterPatientExclude from "./FilterPatientExclude";
import FilterEffectiveness from "./FilterEffectiveness";
import FilterSchedulePatientList from "./FilterSchedulePatientList";
import FilterExternallySchedule from "./FilterExternallySchedule";
import FormAppointment from "./FormAppointment";

export default {
  ...SelectedEmployeeData,
  ...SelectedAppointmenEffectivenesstData,
  ...SelectedEmployeeEffectivenessData,
  ...SelectedAppointmentData,
  ...FilterPerDay,
  ...FilterPerPeriods,
  ...SelectedQueueExternalAppointmentData,
  ...FilterQueue,
  ...SelectedTypeSchedule,
  ...FilterEffectiveness,
  ...FormAppointment,
  ...FilterPatientExclude,
  ...FilterSchedulePatientList,
  ...FilterExternallySchedule,
};
