import { GraphQLClient, gql } from "@/common/request/GraphQLClient";
import Store from "@/store";

const query = gql`
  query searchHospitalizationAih(
    $isAihType: Boolean!
    $pesNome: String
    $intNumero: Int
    $initialDate: String
    $finalDate: String
    $iafNumeroAih: String
    $hospitalizationDate: String
    $exitDate: String
    $authorizationDate: String
    $status: String
    $iafProntuario: String
    $unsId: Int!
    $offset: Int
    $limit: Int
  ) {
    searchHospitalizationAih(
      pes_nome: $pesNome
      int_numero: $intNumero
      initialDate: $initialDate
      finalDate: $finalDate
      unsId: $unsId
      ppr_numero: $iafProntuario
      offset: $offset
      limit: $limit
    ) @skip(if: $isAihType) {
      count
      rows {
        int_id
        int_id_planos_saude
        int_id_pacientes_internacao
        int_id_filas_internacao
        int_id_internacoes_tipos_responsavel
        int_id_internacoes_leitos_tipos_leito_log
        int_id_internacoes_tipos_internacao
        int_id_ocupacoes_cbo
        int_id_internacoes_caracteres
        int_id_funcionarios
        int_id_internacoes_procedimentos
        int_id_cid10
        int_id_internacoes_modalidade
        int_id_funcionarios_responsavel
        int_id_visitantes_acompanhantes
        int_ultima_situacao_internacao
        int_numero
        int_data
        int_hora
        int_previsao_alta
        int_responsavel
        ppr_numero
        isa_data
        patient {
          person {
            pes_nome
            pes_nascimento
            cns {
              crs_numero
            }
          }
        }
        hospitalizationBillingAih {
          iaf_id
          iaf_numero_aih
          iaf_faturado
        }
      }
    }
    searchHospitalizationBillingAih(
      iaf_numero_aih: $iafNumeroAih
      iaf_data_internacao: $hospitalizationDate
      iaf_data_saida: $exitDate
      icf_nome: $pesNome
      inf_data_autorizacao: $authorizationDate
      iaf_faturado: $status
      iaf_prontuario: $iafProntuario
      unsId: $unsId
      offset: $offset
      limit: $limit
    ) @include(if: $isAihType) {
      count
      rows {
        iaf_id
        iaf_id_internacoes
        iaf_id_internacoes_pacientes_faturamentos
        iaf_id_internacoes_faturamentos
        iaf_numero_aih
        iaf_tipo_aih
        iaf_apresentacao
        iaf_data_internacao
        iaf_data_saida
        iaf_orgao_emissor
        iaf_prontuario
        iaf_faturado
        icf_nome
        icf_data_nascimento
        icf_cns
        inf_data_autorizacao
      }
    }
  }
`;

export default async (
  { commit, getters },
  {
    isAihType = true,
    pesNome,
    intNumero,
    initialDate,
    finalDate,
    iafNumeroAih,
    hospitalizationDate,
    exitDate,
    authorizationDate,
    status,
    iafProntuario,
    offset,
    limit,
  },
) => {
  try {
    const unsId = Store.getters["Login/GET_UNIT_HEALTH_ID"];
    const variables = {
      pesNome,
      intNumero: parseInt(intNumero),
      initialDate,
      finalDate,
      iafNumeroAih,
      hospitalizationDate,
      exitDate,
      authorizationDate,
      iafProntuario,
      isAihType,
      status: status || 0,
      unsId,
      offset,
      limit,
    };
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return {
      ...data.searchHospitalizationAih,
      ...data.searchHospitalizationBillingAih,
      isAihType,
    };
  } catch (err) {
    return err.graphQLErrors;
  }
};
