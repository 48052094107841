<template>
  <div>
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      withCloseButton
      :show="show && isReady"
      class="modal-patient-image-in-row"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <h1 class="title">{{ title }}</h1>
      </div>

      <div slot="body" class="body">
        <fieldset class="full-area field">
          <legend class="title-field">Arquivos</legend>

          <div class="half-area bigger">
            <div v-if="!hasFiles" class="empty">
              <IconEmpty class="icon" />
              <span class="info">Não foram encontrados arquivos.</span>
            </div>

            <div v-else class="file-list">
              <div
                v-for="(item, idx) in files"
                :key="idx"
                class="file"
                :class="{ selected: idx === selectedIndex }"
                @click="setIndex(item, idx)"
              >
                <IconFileImg class="svg" />
                <span class="text bottom">{{ item.filename }}</span>
              </div>
            </div>
          </div>

          <div v-if="hasFiles" class="half-area field">
            <p class="title-field">Visualização</p>

            <div class="field-area">
              <iframe
                v-if="isPDF"
                :src="selectedData.url"
                class="base"
                frameborder="0"
              />

              <img v-else-if="isIMG" class="base" :src="selectedData.url" />

              <div v-else class="base">
                <span class="text"> Arquivo sem pré-visualização. </span>
              </div>
            </div>

            <div class="subpainel-control">
              <a
                :href="selectedData !== null ? selectedData.url : null"
                :disabled="selectedIndex === null"
                :class="{ disable: selectedIndex === null }"
                target="_blank"
                class="see"
                title="Visualizar"
              >
                <IconShow />
              </a>
              <a
                :disabled="selectedIndex === null"
                :class="{ disable: selectedIndex === null }"
                class="download"
                title="Baixar"
                @click="actionDownload"
              >
                <IconDownloadArrow class="svg" />
              </a>
              <a
                v-if="!hideButtons"
                :disabled="selectedIndex === null"
                :class="{ disable: selectedIndex === null }"
                class="remove"
                title="Remover arquivo"
                @click="removeFile(selectedData)"
              >
                <IconDiscard class="svg" />
              </a>
            </div>
          </div>
        </fieldset>
      </div>

      <div slot="footer" class="footer">
        <div class="control-painel">
          <RgNewButton
            v-if="!hideButtons"
            medium
            class="new"
            type="button"
            @click="openModalUpload"
          />

          <RgConfirmButton
            v-if="!hideButtons"
            medium
            type="button"
            @click="confirm"
          />
        </div>
      </div>
    </RgBaseModal>

    <ModalUpload
      :show="modalUpload"
      @upload="getUploadedFileInfo"
      @close="closeModalUpload"
    />
  </div>
</template>

<script>
import {
  RgBaseModal,
  RgNewButton,
  RgConfirmButton,
  IconEmpty,
  IconFileImg,
  IconDownloadArrow,
  IconDiscard,
} from "~tokio/primitive";
import { IconShow } from "~tokio/primitive/icon/symbols";

import ModalUpload from "~tokio/primitive/modal/modal-upload/ModalUpload.vue";
import RemoveUploadedFile from "./actions/RemoveUploadedFile";
import SaveNewFiles from "./actions/SaveNewFiles";
import GetQueueFiles from "./actions/GetQueueFiles";

import DownloadByUrl from "~common/utils/DownloadByUrl";

export default {
  name: "ModalPatientImageInRow",
  components: {
    RgBaseModal,
    IconEmpty,
    IconFileImg,
    ModalUpload,
    IconShow,
    IconDownloadArrow,
    RgNewButton,
    RgConfirmButton,
    IconDiscard,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Arquivos do Paciente na Fila",
    },
    fillId: {
      type: Number,
    },
    modId: {
      type: Number,
      required: true,
    },
    hideButtons: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      tempFiles: [],
      savedFiles: [],
      modalUpload: false,
      selectedIndex: null,
      selectedData: null,
      isReady: false,
    };
  },

  computed: {
    files() {
      return [].concat(this.savedFiles).concat(this.tempFiles);
    },

    hasFiles() {
      return this.files.length > 0;
    },

    isPDF() {
      return (
        this.selectedData !== null &&
        this.selectedData.mimetype &&
        this.selectedData.mimetype.includes("pdf")
      );
    },

    isIMG() {
      return (
        this.selectedData !== null &&
        this.selectedData.mimetype &&
        this.selectedData.mimetype.includes("image")
      );
    },
  },

  watch: {
    async show(pValue) {
      this.loadSavedFiles();
    },
  },

  async mounted() {
    this.loadSavedFiles();
  },

  methods: {
    actionDownload() {
      const url = this.selectedData !== null ? this.selectedData.url : null;
      const name = `${this.selectedData.ipf_imagens}`;

      DownloadByUrl(url, name);
    },

    async loadSavedFiles() {
      if (!this.fillId) {
        return;
      }

      return GetQueueFiles(this.fillId).then((res) => {
        this.savedFiles = res.data;
        this.isReady = true;
      });
    },

    setIndex(pValue, pIndex) {
      this.selectedData = pValue;
      this.selectedIndex = pIndex;
    },

    openModalUpload() {
      this.modalUpload = true;
    },

    closeModalUpload() {
      this.modalUpload = false;
    },

    getUploadedFileInfo(pValue) {
      this.tempFiles = this.tempFiles.concat(pValue);
    },

    confirm() {
      if (this.tempFiles.length <= 0) {
        this.close();
        return;
      }

      SaveNewFiles(
        this.fillId,
        this.tempFiles.map((file) => file.filename),
        this.modId,
      )
        .then(async () => {
          this.tempFiles = [];
          await this.loadSavedFiles();
          this.$toaster.success("Arquivos Enviados com sucesso");
          this.$emit("reSearch", true);
          this.close();
        })
        .catch((pErr) => {
          this.$toaster.error("Falha ao Salvar Arquivos");
        });
    },

    removeFile(selectedFile) {
      RemoveUploadedFile(selectedFile.url_remove, this.modId)
        .then(() => {
          this.$toaster.success("Arquivo removido!");
          this.$emit("reSearch", true);
        })
        .catch((pErr) => {
          this.$toaster.error("Falha ao remover o arquivo:" + pErr.message);
        })
        .finally(() => {
          // remove from both if exists
          this.tempFiles = this.tempFiles.filter((item) => {
            return item.filename !== selectedFile.filename;
          });

          this.savedFiles = this.savedFiles.filter((item) => {
            return item.filename !== selectedFile.filename;
          });

          this.selectedIndex = null;
          this.selectedData = null;
        });
    },

    close() {
      if (this.tempFiles.length > 0) {
        const proceed = confirm(
          "Você fez o upload de novos arquivos porém não confirmou o envio deles, se fechar esta tela, perderá os envios.\n\nDeseja Continuar ?",
        );

        if (!proceed) {
          return;
        }
      }

      // tentar remover arquivos temporarios que nao foram confirmados
      this.tempFiles.forEach((item) => {
        this.removeFile(item);
      });

      const hasSavedFiles = this.savedFiles && this.savedFiles.length > 0;

      this.savedFiles = [];
      this.tempFiles = [];
      this.selectedIndex = null;
      this.selectedData = null;
      this.isReady = false;
      this.$emit("close", hasSavedFiles);
    },
  },
};
</script>
