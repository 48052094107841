<style src="./StageColumns.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="stage-columns">
    <div class="command-panel">
      <button
        v-shortkey="['ctrl', 'arrowleft']"
        type="button"
        class="scroll-button"
        @click="stageScroll('next')"
        @shortkey="stageScroll('next')"
      >
        <IconLeftArrow class="arrow-icon" />
      </button>
      <button
        v-shortkey="['ctrl', 'arrowright']"
        type="button"
        class="scroll-button"
        @click="stageScroll('prev')"
        @shortkey="stageScroll('prev')"
      >
        <IconRightArrow class="arrow-icon" />
      </button>
    </div>
    <section class="stage-columns-stages">
      <ul :style="styleScrollPos" class="stage-list-container">
        <li v-for="(stage, idx) in stages" :key="idx" class="stage-list-item">
          {{ stage.lca_nome }}
        </li>
      </ul>
    </section>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { IconLeftArrow, IconRightArrow } from "~tokio/primitive";

export default {
  name: "StageColumns",
  components: {
    IconLeftArrow,
    IconRightArrow,
  },
  props: {
    stages: {
      default: [],
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      styleScrollPos: "SurgeryCenter/Surgery/getStyleScrollPosition",
      scrollPos: "SurgeryCenter/Surgery/getScrollPosition",
      stageWidth: "SurgeryCenter/Surgery/getStageWidth",
    }),
  },
  methods: {
    /*
     * Handle the left position of schedule container
     */
    stageScroll(pDirection) {
      const stageWidth = this.getContainerWidth();
      const defaultStep = 240;
      const timeWidth = 80;
      let step = pDirection === "prev" ? -defaultStep : defaultStep;
      const calcPosition = Math.abs(this.scrollPos + step);
      if (this.scrollPos + step > 0) return;
      const sizeWithTimeWidth = stageWidth.stageInsideWidth + timeWidth;
      if (sizeWithTimeWidth <= stageWidth.stageContainerWidth) {
        step = Math.abs(this.scrollPos);
      } else {
        const pos = stageWidth.stageInsideWidth - calcPosition;
        if (pos <= defaultStep) step = 0;
      }
      this.$store.commit("SurgeryCenter/Surgery/SCROLL_COLUMN_CONTAINER", step);
    },
    getContainerWidth() {
      return {
        stageContainerWidth: this.$parent.$refs.stageContainer.clientWidth,
        stageInsideWidth: this.$parent.$refs.stageInsideContainer.clientWidth,
      };
    },
  },
};
</script>
