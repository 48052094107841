import Store from "@/store";
import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query($unsId: Int, $iafNumeroAih: String, $icfNome: String) {
    searchHospitalizationBillingAih(
      unsId: $unsId
      iaf_numero_aih: $iafNumeroAih
      icf_nome: $icfNome
      iaf_faturado_n: "0"
    ) {
      rows {
        iaf_numero_aih
        icf_nome
      }
    }
  }
`;

export default async (variables, findOne = false) => {
  const unsId = Store.getters["Login/GET_UNIT_HEALTH_ID"];
  variables.unsId = unsId;

  const { data } = await GraphQLClient.query({ query, variables }).catch(
    (err) => {
      console.error(err);
    },
  );

  if (!findOne) {
    return data.searchHospitalizationBillingAih.rows;
  } else {
    return data.searchHospitalizationBillingAih.rows[0];
  }
};
