<style src="./RequestDate.scss" lang="scss" scoped></style>
<template lang="html">
  <li class="request-date">
    <span class="label--day">{{ day }}</span>
    <span class="label--month">{{ month }}</span>
    <span class="label--year">{{ year }}</span>
  </li>
</template>

<script>
import moment from "moment";
export default {
  name: "RequestDate",
  props: {
    date: {
      required: true,
    },
    hours: {
      default: "",
    },
  },
  data: () => {
    return {
      months: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],
    };
  },
  computed: {
    convertedDate() {
      return new Date(moment(this.date, "DD/MM/YYYY"));
    },
    day() {
      return this.convertedDate.getDate();
    },
    month() {
      return this.months[this.convertedDate.getMonth()];
    },
    year() {
      return this.convertedDate.getFullYear();
    },
  },
};
</script>
