import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

import { OpenMenuModule } from "~common/utils/app/menu";

import ExamRegulationFormExternalSchedule from "$exam/submodules/schedule/views/exam-regulation-form-external-schedule/ExamRegulationFormExternalSchedule.vue";

export default {
  path: "/exam/schedule/queue/external-schedule-form",
  name: "exam.schedule.queue.external-schedule-form",
  components: {
    default: ExamRegulationFormExternalSchedule,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Exame", link: OpenMenuModule("exam") },
      { label: "Regulação", link: "/exam/schedule/queue" },
      { label: "Agendar" },
    ],
  },
};
