<script>
export default {
  name: "RgInputMixin",
  props: {
    id: {
      type: String,
      default: "",
    },
    value: {
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "Text",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    rules: {},
    optional: {
      type: String,
      default: "",
    },
    disable: {
      default: undefined,
    },
    tabIndex: {
      type: String,
      default: "0",
    },
    autofocus: {
      default: false,
    },
    dataId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      inputValue: "",
    };
  },
  computed: {
    validValue() {
      return this.inputValue;
    },
  },
  watch: {
    value(pVal, pValOld) {
      this.inputValue = this.value;
    },
    inputValue(pVal, pValOld) {
      this.error = [];
      this.$emit("input", this.inputValue);
    },
  },
  mounted() {
    if (this.autofocus) {
      this.setFocus();
    }
    this.inputValue = this.value;
  },
  methods: {
    setFocus() {
      if ("inputFieldId" in this.$refs) {
        this.$refs.inputFieldId.focus();
      }
    },
    checkFocus() {
      /**
       * When the alert is show, hide them
       */
      if (this.error) this.error = [];
    },
  },
};
</script>
