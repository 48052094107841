<template lang="html">
  <!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <svg
    id="Layer_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M408.515,0H61.968c-4.984,0-9.025,4.041-9.025,9.025v68.009c0,4.984,4.041,9.025,9.025,9.025
			c4.984,0,9.025-4.041,9.025-9.025V18.049h328.498v434.388H70.992V110.726c0-4.984-4.041-9.025-9.025-9.025
			c-4.984,0-9.025,4.041-9.025,9.025v350.736c0,4.984,4.041,9.025,9.025,9.025H94.46v32.489c0,4.984,4.041,9.025,9.025,9.025h24.971
			c4.984,0,9.025-4.041,9.025-9.025c0-4.984-4.041-9.025-9.025-9.025h-15.946v-23.464h296.006c4.984,0,9.025-4.041,9.025-9.025
			V9.025C417.54,4.041,413.499,0,408.515,0z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M450.032,41.513h-13.536c-4.984,0-9.025,4.041-9.025,9.025c0,4.984,4.041,9.025,9.025,9.025h4.511v434.388H166.96
			c-4.984,0-9.025,4.041-9.025,9.025c0,4.984,4.041,9.025,9.025,9.025h283.073c4.984,0,9.025-4.041,9.025-9.025V50.538
			C459.057,45.554,455.016,41.513,450.032,41.513z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M197.562,68.615h-17.436V51.18c0-4.984-4.041-9.025-9.025-9.025h-41.742c-4.984,0-9.025,4.041-9.025,9.025v17.436h-17.436
			c-4.984,0-9.025,4.041-9.025,9.025v41.742c0,4.984,4.041,9.025,9.025,9.025h17.436v17.436c0,4.984,4.041,9.025,9.025,9.025h41.742
			c4.984,0,9.025-4.041,9.025-9.025v-17.436h17.436c4.984,0,9.025-4.041,9.025-9.025V77.64
			C206.586,72.656,202.546,68.615,197.562,68.615z M188.537,110.357h-17.436c-4.984,0-9.025,4.041-9.025,9.025v17.436h-23.693
			v-17.436c0-4.984-4.041-9.025-9.025-9.025h-17.436V86.665h17.436c4.984,0,9.025-4.041,9.025-9.025V60.204h23.693V77.64
			c0,4.984,4.041,9.025,9.025,9.025h17.436V110.357z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M372.415,180.494H98.065c-4.984,0-9.025,4.041-9.025,9.025c0,4.984,4.041,9.025,9.025,9.025h274.35
			c4.984,0,9.025-4.041,9.025-9.025C381.44,184.534,377.399,180.494,372.415,180.494z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M349.558,252.691H120.924c-4.984,0-9.025,4.041-9.025,9.025c0,4.984,4.041,9.025,9.025,9.025h228.632
			c4.984,0,9.025-4.041,9.025-9.025C358.581,256.732,354.542,252.691,349.558,252.691z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M372.417,54.148h-83.023c-4.984,0-9.025,4.041-9.025,9.025s4.041,9.025,9.025,9.025h83.023
			c4.984,0,9.025-4.041,9.025-9.025S377.401,54.148,372.417,54.148z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M372.417,92.653h-83.023c-4.984,0-9.025,4.041-9.025,9.025c0,4.984,4.041,9.025,9.025,9.025h83.023
			c4.984,0,9.025-4.041,9.025-9.025C381.441,96.694,377.401,92.653,372.417,92.653z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M349.558,294.405H120.924c-4.984,0-9.025,4.041-9.025,9.025c0,4.984,4.041,9.025,9.025,9.025h228.632
			c4.985,0,9.026-4.041,9.026-9.025C358.582,298.446,354.542,294.405,349.558,294.405z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M228.024,336.119h-107.1c-4.984,0-9.025,4.041-9.025,9.025c0,4.984,4.041,9.025,9.025,9.025h107.1
			c4.984,0,9.025-4.041,9.025-9.025C237.049,340.159,233.008,336.119,228.024,336.119z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "ResultExam",
};
</script>
