import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import UserDetail from "$user/view/user-detail/UserDetail";

export default {
  path: "/register/user/detail",
  name: "register.user.detail",
  components: {
    default: UserDetail,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Usuário", link: "/register/user/search" },
      { label: "Novo Usuário" },
    ],
  },
};
