import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query {
    shutdownReasons {
      rows {
        mtd_id
        mtd_nome
      }
    }
  }
`;

export default async () => {
  try {
    const { data } = await GraphQLClient.query({ query });
    return data.shutdownReasons.rows;
  } catch (err) {
    return err.graphQLErrors;
  }
};
