<style src="./RgSuggestPerson.scss" lang="scss" scoped></style>
<template>
  <div class="rg-suggest-person">
    <RgSuggest
      v-model="inputValue"
      :label="label"
      :placeholder="placeholder"
      :rules="rules"
      min="2"
    >
      <RgSuggestItem
        v-for="(item, idx) in suggestionList"
        :key="idx"
        :idx="idx"
        :item="item"
        class="person-item"
      >
        <div class="person-row-top">
          <span class="pes-name"><IconUser />{{ item.pes_nome }}</span>
          <span class="pes-mother"><IconGenderFemale />{{ item.pes_mae }}</span>
        </div>
        <div class="person-info">
          <div class="birthday">
            <IconCalendar />
            <span class="pes-birthday">{{ item.pes_nascimento | dateBR }}</span>
          </div>
          <div class="cns">
            <IconCard />
            <span class="pes-cns">{{ item.cns.crs_numero | Cns }}</span>
          </div>
        </div>
      </RgSuggestItem>
    </RgSuggest>
  </div>
</template>
<script>
import { RgSuggest, RgSuggestMixin, RgSuggestItem } from "~tokio/primitive";
import {
  IconCalendar,
  IconGenderFemale,
  IconUser,
  IconCard,
} from "~tokio/primitive/icon";
import { SearchPerson } from "./action";
import moment from "moment";

export default {
  name: "RgSuggestPerson",
  components: {
    RgSuggest,
    RgSuggestItem,
    IconCalendar,
    IconGenderFemale,
    IconCard,
    IconUser,
  },
  filters: {
    Cns(pValue) {
      if (!pValue) return "CNS NÃO CADASTRADO";
      return pValue.replace(/(\d{3})(\d{4})(\d{4})(\d{4})/gi, "$1 $2 $3 $4");
    },
    dateBR(pValue) {
      const isValid = moment(pValue).isValid();
      return isValid
        ? moment(pValue).format("DD/MM/YYYY")
        : "Data não informada";
    },
  },
  mixins: [RgSuggestMixin],
  data() {
    return {
      captionRaw: "pes_nome",
    };
  },
  methods: {
    doSearch(pSearchTerm) {
      const variables = { peopleName: pSearchTerm };
      return SearchPerson({ variables });
    },
  },
};
</script>
