<script>
import { RgRemoteComboboxMixin } from "~tokio/primitive";
import SearchServiceCharacter from "./action/SearchServiceCharacter";

export default {
  name: "RgComboboxAttendaneLevel",
  mixins: [RgRemoteComboboxMixin],
  props: {
    label: {
      default: "Carater de atendimento",
    },
  },
  methods: {
    doSearch() {
      return SearchServiceCharacter();
    },
    formatItem(item) {
      return {
        value: item.ica_id,
        text: item.ica_nome,
      };
    },
  },
};
</script>
