<template>
  <div class="emergency-bulletin-search">
    <RgSearch
      ref="rgsearch"
      v-model="mutableEmergencyBulletin"
      :build-filter="generateFilter"
      :search-function="searchFilter"
      :clear-function="cleanForm"
      :item-height="32"
      :max-register="30"
      :result-title="'Boletins'"
      :show-empty-message="true"
      :showFooter="hasEmergency"
      @submitFromButton="actionCleanSelection"
      @count="getCountValue"
    >
      <div slot="filters" class="emergency-bulletin-search-filter">
        <div class="grid">
          <div class="selectinput">
            <RgComboboxUnithealth
              v-model="form.unit"
              :rules="{ required: true }"
              label="Unidade de Saúde"
              class="inputitem"
              disabled
            />
          </div>

          <div class="selectinput">
            <RgComboboxSectorLegacy
              v-model="form.sector"
              :class="{ 'disable unselect': !hideSector }"
              :disabled="!hideSector"
              :unit-health-id="Number(form.unit)"
              class="inputitem"
              label="Setor"
              :empty-default-text="'Selecione uma Unidade'"
              default-text="Todos"
              :submodule-id="submoduleId"
            />
          </div>

          <div class="filter-title">
            <span class="title">Dados do Paciente</span>
          </div>

          <div class="selectinput">
            <RgInput
              ref="patient"
              v-model="form.patientName"
              class="inputitem"
              placeholder="Digite o nome do paciente"
              label="Paciente"
            />
          </div>

          <div class="selectinput">
            <RgSuggestSmartPerson
              ref="patient"
              v-model="form.specificPatientName"
              :disabled="disableDocumentation"
              :with-patient-module="['emergency']"
              :enabled-patient="false"
              filterOnlyDocument
              @selected="selectingPatient"
            />
          </div>

          <div class="selectinput toggle">
            <span :class="{ active: form.unidentifiedPatient }" class="text"
              >Somente pacientes não identificados</span
            >
            <RgToggleButton
              class="input-toggle"
              :valueSync="true"
              :value="form.unidentifiedPatient"
              @change="handleToggleUnidentifiedPatient"
            />
          </div>

          <div class="selectinput">
            <RgInput
              ref="bulletin"
              v-model="form.bulletin"
              class="inputitem"
              placeholder="Digite o número do boletim"
              label="Boletim"
            />
          </div>

          <div class="filter-title">
            <span class="title">Período</span>
          </div>

          <div class="selectinput">
            <div class="period">
              <RgInputDate
                v-model="form.initialOrderPeriod"
                :rules="{ fn: verifyOrderInitialDate }"
                placeholder="dd/mm/aaaa"
                label="Data Inicial"
                class="date"
              />

              <RgInputDate
                v-model="form.finalOrderPeriod"
                placeholder="dd/mm/aaaa"
                label="Data Final"
                class="date"
              />
            </div>
            <div class="exit">
              <RadioBulletinWithExit v-model="form.exitOptions" />
            </div>
          </div>
        </div>
      </div>

      <div v-if="!hasEmergency" slot="menu-top" class="top-button">
        <RgNewButton
          large
          title="Cadastrar Boletim"
          class="item"
          :permission="canRegister"
          @click="registrationBulletin"
        />
      </div>

      <div v-else class="emergency-bulletin-search">
        <div v-if="hasEmergency" class="content-grid">
          <div v-if="mutableEmergencyBulletin">
            <RgTable :columns="COLUMNS" class="table">
              <div slot="header" class="actions-header">
                <div class="legends">
                  <div class="circle --green" />
                  <span class="legend">Paciente com saída</span>
                </div>

                <div class="top-buttons">
                  <RgNewButton
                    small
                    title="Cadastrar Boletim"
                    class="item"
                    :permission="canRegister"
                    @click="registrationBulletin"
                  />

                  <RgEditButton
                    small
                    title="Editar Boletim"
                    class="item"
                    :permission="canEdit"
                    :disabled="
                      disabledButtonWhenExit ||
                      disabledButtonWhenPatientSentToPep
                    "
                    @click="editRegistrationBulletin"
                  />

                  <RgLessButton
                    :permission="canRemove"
                    :disabled="
                      disabledButtonWhenExit || disabledButtonWhenSentToPep
                    "
                    title="Excluir Boletim"
                    class="item"
                    @click="openModalDeletion"
                  />

                  <RgShowButton
                    :disabled="hasOneSelected"
                    class="item"
                    title="Detalhes do Boletim"
                    @click="openModalBulletinDetails"
                  />

                  <RgDropdownPrinter
                    :disabled="hasOneSelected"
                    :item="bulletinData"
                    :action-options="itemActionOptionsPrinter(bulletinData)"
                    class="item"
                  />
                </div>
              </div>
              <tr
                v-for="(item, index) in mutableEmergencyBulletin"
                slot="rows"
                :key="index"
                :class="{
                  selected: item.bol_id === bulletinData.bol_id,
                }"
                class="tr"
                @click="selectRow(item)"
              >
                <td class="circle-indicator">
                  <ColorIndication
                    v-if="Number(item.saida) !== 0"
                    size="13px"
                    :value="actionColorLine.get(item)"
                    class="circle"
                  />
                </td>
                <td>{{ item.bol_numero }}</td>
                <td class="patient-name">
                  <span class="ellipsis">
                    {{ item.pes_nome }}
                  </span>
                </td>
                <td>{{ item.entrada }}</td>
                <td>{{ item.set_nome }}</td>
                <td>{{ item.enviado }}</td>
                <td>{{ item.usu_nome }}</td>
              </tr>
            </RgTable>
          </div>
        </div>
      </div>

      <div
        v-show="mutableEmergencyBulletin.length >= 1"
        slot="menu-bottom"
        class="bottom-button"
      >
        <div class="actions">
          <SmallButton
            :permission="canRegisterExit"
            :disabled="disableRegisterExit"
            :backgroundColor="'#37c871'"
            :title="'Cadastrar Saída'"
            @click="registerExit"
          >
            <IconExit slot="icon" class="icon" />
          </SmallButton>

          <SmallButton
            :permission="canDeleteRegisterExit"
            :disabled="disableDeleteRegisterExit"
            :backgroundColor="'#f96b70'"
            :title="'Excluir Saída'"
            @click="openModalDeleteRegisterExit"
          >
            <IconCancelExit slot="icon" class="icon" />
          </SmallButton>

          <RgDropdownPep
            :disabled="hasOneSelected"
            :item="bulletinData"
            :action-options="itemActionOptionsPep(bulletinData)"
            show-up
            show-left
            class="button"
          />
        </div>
      </div>
    </RgSearch>

    <ModalDeleteBulletin
      :show="showModalDeletion"
      :maxlength="250"
      :bulletinData="bulletinData"
      @close="closeModalDeletion"
      @reSearch="reSearchTable"
    />

    <ModalBulletinDetails
      :show="showModalDetails"
      :bulletinData="bulletinData"
      @close="actionCloseModalDetails"
    />

    <ModalDeleteRegisterExit
      :show="showModalDeleteRegisterExit"
      :bulletinData="bulletinData"
      @close="closeModalDeleteRegisterExit"
      @reSearch="reSearchTable"
    />

    <ModalPrintEmitBulletin
      :show="showModalEmitBulletin"
      :bulletinData="bulletinData"
      @close="closeModalEmitBulletin"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  RgInputDate,
  RgComboboxUnithealth,
  RgNewButton,
  RgEditButton,
  RgShowButton,
  IconExit,
  IconCancelExit,
  RgInput,
  RgLessButton,
  RgDropdownPrinter,
  RgToggleButton,
  SmallButton,
  RgSuggestSmartPerson,
  RgComboboxSectorLegacy,
  ColorIndication,
} from "~tokio/primitive";

import {
  ModalDeleteBulletin,
  ModalBulletinDetails,
  ModalDeleteRegisterExit,
  ModalPrintEmitBulletin,
} from "$emergency/bulletin/component";

import RgTable from "~tokio/foundation/rg-table/RgTable";
import RgDropdownPep from "$hospitalization/hospitalizations/component/button/rg-dropdown-pep/RgDropdownPep";
import PatientIdentificationHTML from "~tokio/primitive/html/PatientIdentificationHTML";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";
import RgSearch from "~tokio/foundation/rg-search/RgSearch";
import moment from "moment";
import RadioBulletinWithExit from "$emergency/bulletin/component/radio/radio-bulletin-with-exit/RadioBulletinWithExit";

export default {
  name: "EmergencyBulletinSearch",
  components: {
    RgSearch,
    RgInputDate,
    RgComboboxUnithealth,
    RgComboboxSectorLegacy,
    RgNewButton,
    RgEditButton,
    RgInput,
    RgTable,
    RgShowButton,
    RgLessButton,
    ModalDeleteBulletin,
    ModalBulletinDetails,
    ModalDeleteRegisterExit,
    RgDropdownPrinter,
    RgToggleButton,
    RgDropdownPep,
    IconExit,
    IconCancelExit,
    RadioBulletinWithExit,
    SmallButton,
    RgSuggestSmartPerson,
    ModalPrintEmitBulletin,
    ColorIndication,
  },

  data() {
    return {
      mutableEmergencyBulletin: [],
      bulletinData: {},
      header: this.COLUMNS,
      form: {
        unit: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        sector: null,
        patientName: null,
        bulletin: null,
        initialOrderPeriod: moment().subtract(1, "days").format("DD/MM/YYYY"),
        finalOrderPeriod: moment().format("DD/MM/YYYY"),
        unidentifiedPatient: false,
        exitOptions: [],
        specificPatientName: "",
        pesId: null,
      },
      totalItems: 0,
      pagination: {
        limit: 30,
        offset: 0,
        current: 1,
      },
      bolId: null,
      reason: null,
      unidentifiedPatient: null,
      disableRegisterExit: true,
      disableDeleteRegisterExit: true,
      disableDocumentation: false,
      showModalDeletion: false,
      showModalDetails: false,
      showModalDeleteRegisterExit: false,
      showModalEmitBulletin: false,
    };
  },

  computed: {
    ...mapGetters({
      unitHealthId: "Login/GET_UNIT_HEALTH_ID",
    }),

    sectorId() {
      return Number(this.selectedBulletin?.set_id);
    },

    submoduleId() {
      return 34;
    },

    hasOneSelected() {
      return !(this.bulletinData && this.bulletinData.bol_id > 0);
    },

    hasEmergency() {
      return this.mutableEmergencyBulletin.length > 0;
    },

    hideSector() {
      return this.form.unit > 0;
    },

    disabledButtonWhenExit() {
      const bulletinWithExit = this.bulletinData?.saida !== "0";
      return this.hasOneSelected || bulletinWithExit;
    },

    disabledButtonWhenPatientSentToPep() {
      const bulletinPatientSentToPep =
        this.bulletinData?.enviado === "SIM" &&
        this.bulletinData?.pes_id.length > 0;

      return this.hasOneSelected || bulletinPatientSentToPep;
    },

    disabledButtonWhenSentToPep() {
      const bulletinSentToPep = this.bulletinData?.enviado === "SIM";
      return this.hasOneSelected || bulletinSentToPep;
    },

    selectedBulletin() {
      return this.$store.getters["Emergency/Bulletin/GET_SELECTED_BULLETIN"];
    },

    permission() {
      return {
        hasPermissionRegisterBulletin: !!this.$Permissions.global.has(
          "emergencia.boletim.incluirEntrada",
          this.unitHealthId,
        ),
        hasPermissionRetroactiveBulletin: !!this.$Permissions.global.has(
          "emergencia.boletim.incluirEntradaRetroativa",
          this.unitHealthId,
        ),
        hasPermissionEditBulletin: !!this.$Permissions.sector.has(
          "emergencia.boletim.alterarEntrada",
          this.sectorId,
        ),
        hasPermissionRemoveBulletin: !!this.$Permissions.sector.has(
          "emergencia.boletim.excluirEntrada",
          this.sectorId,
        ),
        hasPermissionRegisterExitBulletin: !!this.$Permissions.sector.has(
          "emergencia.boletim.incluirSaida",
          this.sectorId,
        ),
        hasPermissionDeleteRegisterExitBulletin: !!this.$Permissions.sector.has(
          "emergencia.boletim.excluirSaida",
          this.sectorId,
        ),
        hasPermissionEmergencyPEP: !!this.$Permissions.sector.has(
          "emergencia.emergenciaPEP.utilizarEmergenciaPep",
          this.sectorId,
        ),
      };
    },

    canRegister() {
      return (
        this.permission.hasPermissionRegisterBulletin ||
        this.permission.hasPermissionRetroactiveBulletin
      );
    },
    canEdit() {
      return this.permission.hasPermissionEditBulletin;
    },
    canRemove() {
      return this.permission.hasPermissionRemoveBulletin;
    },
    canRegisterExit() {
      return this.permission.hasPermissionRegisterExitBulletin;
    },
    canDeleteRegisterExit() {
      return this.permission.hasPermissionDeleteRegisterExitBulletin;
    },
    canEmergencyPep() {
      return this.permission.hasPermissionEmergencyPEP;
    },

    preferences() {
      return {};
    },

    actionColorLine() {
      return {
        get: (item) => {
          if (parseInt(item.saida) > 0) {
            return "#44AD8E";
          }
        },
      };
    },
  },

  watch: {
    bulletinData(pValue) {
      if (!pValue || !pValue.bol_id) {
        this.disableDeleteRegisterExit = true;
        this.disableRegisterExit = true;
      } else {
        if (pValue && pValue.saida && pValue.saida !== "0") {
          this.disableRegisterExit = true;
          this.disableDeleteRegisterExit = false;
        } else {
          this.disableDeleteRegisterExit = true;
          this.disableRegisterExit = false;
        }
      }
    },

    "form.patientName"(pValue) {
      pValue?.length > 0
        ? (this.disableDocumentation = true)
        : (this.disableDocumentation = false);
    },
  },

  created() {
    this.COLUMNS = [
      { name: "", key: "saida" },
      { name: "Boletim", key: "bol_numero" },
      { name: "Paciente", key: "pes_nome" },
      { name: "Entrada", key: "entrada" },
      { name: "Setor", key: "set_nome" },
      { name: "Enviado PEP", key: "enviado" },
      { name: "Usuário", key: "usu_nome" },
    ];

    this.unidentifiedPatient = "PACIENTE NÃO IDENTIFICADO";
  },

  mounted() {
    this.fillFilter();
    this.reSearchTable();
  },

  beforeDestroy() {
    const validateIfExist = ValidateIfRouteExistInBreadscrumb(
      "/emergency/bulletin",
      this.$route.meta.breadcrumb,
    );

    if (!validateIfExist) {
      this.$store.commit("Emergency/Bulletin/RESET_FILTER_EMERGENCY_BULLETIN");
    }
  },

  methods: {
    async searchFilter(pData) {
      try {
        this.$loader.start("Carregando boletins...");
        return await this.$store.dispatch(
          "Emergency/Bulletin/SEARCH_BULLETINS",
          pData,
        );
      } catch (pErr) {
        this.$toaster.error(pErr);
      } finally {
        this.$loader.finish();
      }
    },

    generateFilter() {
      const variables = {
        arrFormData: {
          bol_id_setores: this.form.sector,
          bol_numero: this.form.bulletin,
          periodoInicial: this.form.initialOrderPeriod,
          periodoFinal: this.form.finalOrderPeriod,
          pes_nome: this.form.patientName,
          pes_id: this.form.pesId,
          smd_id: this.submoduleId,
          uns_id: this.form.unit,
          somenteNaoIdentificados: this.form.unidentifiedPatient,
        },
      };

      const oneSelected = this.form.exitOptions.length === 1;

      if (oneSelected) {
        variables.arrFormData.saida = this.form.exitOptions[0];
      }

      this.$store.commit(
        "Emergency/Bulletin/SET_FILTER_EMERGENCY_BULLETIN",
        this.form,
      );

      return variables;
    },

    async reSearchTable() {
      try {
        await this.$refs.rgsearch.performSearch();
        this.bulletinData = {};
      } catch (pErr) {
        console.log(pErr);
      }
    },

    async selectRow(pItem) {
      if (this.bulletinData.bol_id === pItem.bol_id) {
        this.bulletinData = {};
        this.bolId = null;
      } else {
        this.bolId = Number(pItem.bol_id);
        this.bulletinData = pItem;
      }

      this.$forceUpdate();

      this.$store.commit(
        "Emergency/Bulletin/SELECT_BULLETIN",
        this.bulletinData,
      );
    },

    registrationBulletin() {
      this.$router.push({
        name: "emergency.bulletin.registration",
      });
    },

    editRegistrationBulletin() {
      this.$store.commit(
        "Emergency/Bulletin/SELECT_BULLETIN",
        this.bulletinData,
      );

      this.$router.push({
        name: "emergency.bulletin.edit-registration",
      });
    },

    async fillFilter() {
      try {
        const existFilterData = this.$store.getters[
          "Emergency/Bulletin/GET_FILTER_EMERGENCY_BULLETIN"
        ];

        if (existFilterData) {
          this.$loader.start("Carregando boletins...");

          this.form.unit = existFilterData.unit;
          this.form.sector = existFilterData.sector;
          this.form.patientName = existFilterData.patientName;
          this.form.bulletin = existFilterData.bulletin;
          this.form.initialOrderPeriod = existFilterData.initialOrderPeriod;
          this.form.finalOrderPeriod = existFilterData.finalOrderPeriod;
          this.form.unidentifiedPatient = existFilterData.unidentifiedPatient;

          if (this.$refs.patient && existFilterData.pesId) {
            this.$refs.patient.fillPatientById(existFilterData.pesId);
          }

          this.actionCleanSelection();

          await this.$refs.rgsearch.performSearch();

          this.$loader.finish();
        }
      } catch (Err) {
        this.$toaster.warning("Erro ao carregar os dados da navegação", Err);
        this.$loader.finish();
      }
    },

    handleToggleUnidentifiedPatient(pValue) {
      this.form.unidentifiedPatient = pValue;
    },

    actionCleanSelection() {
      this.bulletinData = {};
    },

    selectingPatient(pValue) {
      if (pValue && pValue.source) {
        const { pes_id } = pValue.source;

        this.form.pesId = pes_id;
      } else {
        this.form.pesId = 0;
        this.form.specificPatientName = "";
      }
    },

    cleanForm() {
      this.bulletinData = {};
      this.mutableEmergencyBulletin = [];
      this.form = {
        unit: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        sector: null,
        patientName: null,
        bulletin: null,
        initialOrderPeriod: moment().subtract(1, "days").format("DD/MM/YYYY"),
        finalOrderPeriod: moment().format("DD/MM/YYYY"),
        unidentifiedPatient: false,
        exitOptions: [],
        specificPatientName: "",
        pesId: null,
      };
    },

    getCountValue(pValue) {
      this.totalItems = Number(pValue) || 0;
    },

    openModalBulletinDetails() {
      this.showModalDetails = true;
    },

    actionCloseModalDetails() {
      this.showModalDetails = false;
    },

    registerExit() {
      this.$store.commit(
        "Emergency/Bulletin/SELECT_BULLETIN",
        this.bulletinData,
      );

      this.$router.push({
        name: "emergency.bulletin.register-exit",
      });
    },

    openModalDeleteRegisterExit() {
      this.showModalDeleteRegisterExit = true;
    },

    closeModalDeleteRegisterExit() {
      this.showModalDeleteRegisterExit = false;
    },

    openModalDeletion() {
      this.showModalDeletion = true;
    },

    closeModalDeletion() {
      this.showModalDeletion = false;
    },

    async patientIdentification() {
      const personData = await this.$store.dispatch(
        "Emergency/Bulletin/BASIC_PATIENT_INFO",
        {
          pesId: this.bulletinData.pes_id,
        },
      );

      const recordNumber = personData.patient.record_numbers;
      const validate =
        recordNumber && recordNumber.rows.length > 0
          ? recordNumber.rows[0].ppr_numero
          : "";

      const html = PatientIdentificationHTML({
        paciente: personData.pes_nome,
        pac_prontuario_unico: personData.patient.pac_prontuario_unico,
        pes_nascimento: this.$utils.date.BrazilianDateFormat(
          personData.pes_nascimento,
        ),
        ppr_numero: validate,
      });

      this.$utils.print.printHtml(html);
    },

    modalEmitBulletin() {
      this.showModalEmitBulletin = true;
    },

    closeModalEmitBulletin() {
      this.showModalEmitBulletin = false;
    },

    sendBulletinPep() {
      this.$loader.start();

      this.$store
        .dispatch(
          "Emergency/Bulletin/SEND_BULLETIN_PEP",
          this.bulletinData.bol_id,
        )
        .then((data) => {
          this.$toaster.success("Boletim enviado para o PEP!");
          this.reSearchTable();
        })
        .catch((err) => {
          const hasIndigent =
            this.bulletinData.pes_nome === "PACIENTE NÃO IDENTIFICADO";

          const emptyValue = /É preciso atualizar os seguintes dados do Paciente/.test(
            err.message,
          );
          if (emptyValue && hasIndigent) {
            const indexMessage = err.message.indexOf("<br><br>É");

            err.message = err.message.slice(0, indexMessage);
          }

          const fillLocal = /Local de atendimento não mapeado:/.test(
            err.message,
          );
          if (fillLocal) {
            const local = `${hasIndigent ? "<br><br>" : ""}${
              this.bulletinData.lca_nome
            } <br/>`;
            err.message = err.message + local;
          }

          this.$toaster.error(
            err.message,
            "Falha ao enviar boletim para o PEP.",
          );
        })
        .finally(() => {
          this.$loader.finish();
        });
    },

    cancelSendBulletinPep() {
      this.$loader.start();
      this.$store
        .dispatch(
          "Emergency/Bulletin/CANCEL_SEND_BULLETIN_PEP",
          this.bulletinData.bol_id,
        )
        .then((data) => {
          this.$toaster.success("Cancelamento do boletim enviado para o PEP.");
          this.reSearchTable();
        })
        .catch((err) => {
          this.$toaster.error(
            err.message,
            "Falha ao enviar cancelamento do boletim para o PEP.",
          );
        })
        .finally(() => {
          this.$loader.finish();
        });
    },

    itemActionOptionsPep(pItem) {
      const alreadyWasInPep = pItem && pItem.enviado === "SIM";

      return [
        {
          label: "Enviar boletim para o PEP",
          action: this.sendBulletinPep,
          disable: alreadyWasInPep || !this.canEmergencyPep,
        },
        {
          label: "Cancelar boletim no PEP",
          action: this.cancelSendBulletinPep,
          disable: !alreadyWasInPep || !this.canEmergencyPep,
        },
      ];
    },

    itemActionOptionsPrinter() {
      return [
        {
          label: "Imprimir identificação do paciente",
          action: this.patientIdentification,
          disable: !(this.bulletinData && this.bulletinData.pes_id),
        },
        {
          label: "Emitir boletim de atendimento médico",
          action: this.modalEmitBulletin,
          disable: !this.bulletinData,
        },
      ];
    },

    setReason(value) {
      this.reason = value;
    },

    verifyOrderInitialDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialOrderPeriod, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalOrderPeriod, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }
    },
  },
};
</script>
