<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchUnitHealthLinkedCbo from "./action/SearchUnitHealthLinkedCbo";

export default {
  name: "RgComboboxUnitHealthLinkedCbo",
  mixins: [RgComboboxMixin],
  data: () => {
    return {};
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];

      const data = await SearchUnitHealthLinkedCbo();

      data.forEach((item) => {
        this.mData.push({ value: item.data, text: item.label });
      });
    },
  },
};
</script>
