import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  return AuthLegacyRequest.post(
    "exames2/controller-exame-escala-agendamento/buscar",
    variables,
  )
    .then(({ data }) => {
      if (data.dados === "0") {
        return [];
      }

      data = HandleResponse(data);

      if (data?.dados?.recordSet) {
        data.dados.recordSet.map((el) => {
          el.validate = `${el.eea_data_inicio_escala} - ${el.eea_data_fim_escala}`;
        });
      }

      data.dados.data = data.dados.recordSet;
      return data.dados;
    })
    .catch((pErr) => {
      console.log("error", pErr);
      throw pErr;
    });
};
