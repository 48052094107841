<style src="./RgSelectDddInputPhone.scss" lang="scss" scoped></style>
<template>
  <div class="rg-suggest-smart-phone">
    <RgFormBase :label="label" :required="isRequired">
      <div slot="right-label">
        <RgValidationAlert :alert="error" class="error rg-input--alert" />
      </div>

      <div class="input-area rg-input--textbox-container">
        <select v-model="dddItem" class="select-ddd">
          <option :value="null">DDD</option>

          <option v-for="(item, idx) in ddds" :key="idx" :value="item">
            {{ item.ddd_codigo }}
          </option>
        </select>

        <input
          v-model="telephone"
          v-mask="['####-####', '#####-####']"
          class="input rg-input--textbox"
          placeholder="00000-0000"
          @blur="validateBlur"
          @change="change"
          @focus="checkFocus"
          @input="$emit('input', $event.target.value)"
        />
      </div>
    </RgFormBase>
  </div>
</template>
<script>
import {
  RgValidatorMixin,
  RgValidationAlert,
} from "~tokio/primitive/validation";
import RgInputMixin from "~tokio/primitive/input/mixin/RgInputMixin";
import { RgFormBase } from "~tokio/foundation/container";
import { mask } from "vue-the-mask";

import { SearchDdd } from "./action";

export default {
  name: "RgSelectDddInputPhone",
  components: {
    RgFormBase,
    RgValidationAlert,
  },

  directives: {
    mask,
  },

  mixins: [RgValidatorMixin, RgInputMixin],

  props: {
    label: {
      type: String,
      default: "Telefone",
    },
    change: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      ddds: null,
      dddItem: null,
      telephone: null,
    };
  },

  computed: {
    validValue() {
      return this.telephone;
    },
    isRequired() {
      return this.rules && this.rules.required;
    },
  },

  watch: {
    dddItem(pValue) {
      if (!pValue) {
        this.telephone = null;
      }
      this.$emit("getDdd", pValue);
    },
  },

  async mounted() {
    this.ddds = await SearchDdd();
  },

  methods: {
    validateBlur() {
      this.validate();
      this.$emit("blur");
    },

    fillValue(pTel, pDdd) {
      this.dddItem = pDdd;
      this.telephone = pTel;
    },

    cleanPhone() {
      this.dddItem = null;
      this.telephone = null;
    },
  },
};
</script>
