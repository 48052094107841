import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  return AuthLegacyRequest.post(
    "exames2/controller-exame-escala-agendamento/dividir",
    variables,
  )
    .then(({ data }) => {
      if (data.dados === "ExameDataInvalidaParaDivisaoException") {
        return false;
      }
      return data.dados;
    })
    .catch((pErr) => {
      console.log("error", pErr);
      throw pErr;
    });
};
