import { GraphQLClient, gql } from "@/common/request/GraphQLClient";
import { BrazilianDateToDatabase } from "~utils/date";

const mutation = gql`
  mutation(
    $personId: Int!
    $person: PersonInputType!
    $address: AddressInputType
  ) {
    UpdatePerson(personId: $personId, person: $person, address: $address) {
      pes_nome
    }
  }
`;

export default ({ commit }, personInfo) => {
  const personId = personInfo.pes_id;
  const person = Object.assign({}, personInfo);
  const address = Object.assign({}, personInfo.address);
  delete person.address;

  address.end_ativo = 1;

  flattenData(address, person);
  removeUnusedFields(address, person);
  sanitizeFields(address, person);

  const variables = {
    personId,
    person,
    address,
  };

  return new Promise((resolve, reject) => {
    GraphQLClient.mutate({ mutation, variables })
      .then((pData) => {
        resolve(pData);
      })
      .catch((err) => {
        switch (err.graphQLErrors[0].message) {
          case "Query was empty": {
            reject(new Error("Nenhuma alteração foi feita"));
            break;
          }
          default: {
            return reject(err);
          }
        }
      });
  });
};

const flattenData = (address, person) => {
  address.bai_nome = address.neighborhood.bai_nome;
  address.mun_id = address.neighborhood.city.mun_id;
  address.tlg_id = address.publicPlaceType.tlg_id;

  if (address.subNeighborhood && address.subNeighborhood.sub_nome) {
    address.sub_nome = address.subNeighborhood.sub_nome;
  }

  address.zon_id = 4;
  person.pes_id_sexos = person.gender.sex_id;
};

const removeUnusedFields = (address, person) => {
  delete person.patient;

  delete address.neighborhood;
  delete address.subNeighborhood;
  delete address.publicPlaceType;
  delete address.__typename;

  delete person.nationality;
  delete person.nome_municipios;
  delete person.pes_id;
  delete person.gender;
  delete person.person;
  delete person.pes_etnias;
  delete person.__typename;
  delete person.pdf_cns;
  delete person.cns;
  delete person.city;
  delete person.pes_id_municipios;
  delete person.ethnicity;
  delete person.pes_id_etnias;
  delete person.telephones;
};

const sanitizeFields = (address, person) => {
  person.pes_nascimento = person.pes_nascimento
    ? BrazilianDateToDatabase(person.pes_nascimento)
    : "0000-00-00";
  address.end_cep = address.end_cep
    ? address.end_cep.replace(/[^\d]/g, "")
    : "00000000";
};
