<template lang="html">
  <div v-if="show" class="modal-simple-history">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <h1 class="title">Histórico do paciente na fila</h1>
      </div>

      <div slot="body" class="body">
        <SmartTable
          ref="smartTable"
          name="ModalSimpleHistory"
          :columns="COLUMN"
          :body="mutableHistoryList.rows"
          :total="Number(mutableHistoryList.count)"
          :initial-columns="5"
          :item-per-page="pagination.limit"
          :max-register="10"
          :show-pagination="true"
          :removeBtnColumns="true"
          index-column="fil_id"
          dontSelect
          @pagination="getPagination"
        />
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { SmartTable } from "~tokio/foundation";
import { RgBaseModal } from "~tokio/primitive";

export default {
  name: "ModalSimpleHistory",
  components: {
    RgBaseModal,
    SmartTable,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      mutableHistoryList: [],
      countHistoryList: 0,
      completedSearch: false,
      pagination: {
        limit: 10,
        offset: 0,
        current: 1,
      },
    };
  },
  computed: {
    hasCountListHistory() {
      return this.mutableHistoryList === undefined
        ? 0
        : this.mutableHistoryList.length > 0;
    },
  },
  watch: {
    show(pValue) {
      pValue ? this.searchHistory() : this.close();
    },
  },
  created() {
    this.COLUMN = [
      { name: "Data", key: "flg_data" },
      { name: "Operação", key: "tlg_nome" },
      { name: "Usuário", key: "usu_nome" },
      { name: "Fila", key: "fila" },
      { name: "Prioridade", key: "pfi_nome" },
    ];
  },
  methods: {
    async search() {
      const variables = this.parseData();
      this.mutableHistoryList = await this.$store.dispatch(
        "Queue/SEARCH_HISTORY_QUEUE",
        {
          ...variables,
        },
      );
    },

    parseData() {
      const variables = {
        fil_id: this.filter.fil_id,
        pes_id: this.filter.pes_id,
        pes_nome: this.filter.pes_nome,
        limiteFim: this.pagination.limit,
        limiteInicio: this.pagination.offset,
      };
      return variables;
    },

    getPagination(pPagination) {
      this.pagination = pPagination;
      this.searchHistory();
    },

    async searchHistory() {
      this.$loader.start("Carregando histórico de busca...");
      await this.search();
      this.$loader.finish();
    },

    cleanValues() {
      this.mutableHistoryList = [];
      this.pagination.limit = 10;
      this.pagination.offset = 0;
      this.pagination.current = 1;
    },

    close() {
      this.cleanValues();
      this.$emit("close");
    },
  },
};
</script>
