import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query BioslabLaboratoryPatientExams(
    $lpe_id_laboratoriais_exames_bioslab: Int
    $withExamData: Boolean!
    $lpe_ativo: String
  ) {
    SearchBioslabLaboratoryPatientExams(
      lpe_id_laboratoriais_exames_bioslab: $lpe_id_laboratoriais_exames_bioslab
      lpe_ativo: $lpe_ativo
      limit: 1000
    ) {
      rows {
        lpe_id
        lpe_id_laboratoriais_exames_bioslab
        lpe_codigo_sigtap
        lpe_codigo_exame_bioslab_mne
        lpe_objeto_analise
        lpe_material_biologico
        lpe_identificador_exame_bioslab
        lpe_situacao
        lpe_mensagem_erro
        lpe_url_resultado
        lpe_data_envio_pep
        lpe_id_externo_pep
        lpe_ativo
        exam @include(if: $withExamData) {
          lle_id
          lle_codigo_bioslab_mne
          lle_procedimentos
          lle_multiplo
          lle_dum
          lle_peso
          lle_altura
          lle_cor
          lle_nacionalidade
          lle_naturalidade
          lle_material
          lle_codigo_sigtap
          lle_ativo
          procedure {
            CO_PROCEDIMENTO
            NO_PROCEDIMENTO
            TP_SEXO
            VL_IDADE_MINIMA
            VL_IDADE_MAXIMA
          }
        }
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  variables.withExamData = variables.withExamData
    ? variables.withExamData
    : false;

  const { data } = await GraphQLClient.query({
    query,
    variables,
    fetchPolicy: "no-cache",
  }).catch((err) => {
    throw err;
  });

  return data.SearchBioslabLaboratoryPatientExams.count
    ? data.SearchBioslabLaboratoryPatientExams.rows
    : data.SearchBioslabLaboratoryPatientExams;
};
