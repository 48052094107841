<style src="./RgListItemRemove.scss" lang="scss" scoped></style>
<template lang="html">
  <li class="personal-documents--list__remove" tabindex="0">
    <div class="personal-documents--remove-container">
      <button class="personal-documents--btn-remove">
        <IconGarbage class="personal-documents--btn-remove--icon" />
      </button>
    </div>

    <div class="personal-documents--remove-confirmation">
      <button
        type="button"
        class="personal-documents--btn-remove-confirm"
        @click.stop.prevent="removeConfirm"
      >
        Excluir
      </button>

      <button
        type="button"
        class="personal-documents--btn-remove-cancel"
        @click.stop.prevent
      >
        Cancelar
      </button>
    </div>
  </li>
</template>
<script>
import { IconGarbage } from "~tokio/primitive/icon";

export default {
  name: "RgListItemRemove",
  components: {
    IconGarbage,
  },
  methods: {
    removeConfirm() {
      this.$emit("remove-action");
    },
  },
};
</script>
