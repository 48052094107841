<style lang="scss" src="./RgCancelModal.scss" scoped></style>
<template>
  <div v-if="show">
    <RgBaseModal :show="show" @close="close">
      <div slot="icon" class="icon">
        <IconDanger class="icon-danger" />
      </div>

      <div slot="header" class="confirm-header">
        <h3>{{ title }}</h3>
      </div>

      <div slot="body" class="confirm-body">
        <h4>{{ message }}</h4>
        <div class="alert--container">
          <RgComboboxCancelReason
            v-model="cancel.ccc_id_motivos_cancelamentos"
            class="combobox-cancel-reason"
            label="Motivo do Cancelamento"
          />
          <RgInput
            v-model="cancel.ccc_observacao"
            label="Observação"
            class="input--obs"
          />
        </div>
      </div>

      <div slot="footer" class="confirm-actions-footer">
        <button
          class="btn btn-confirmed btn-success"
          @click.stop.prevent="yesClicked"
        >
          Sim, tenho certeza
        </button>
        <button
          class="btn btn-denied btn-danger"
          @click.stop.prevent="noClicked"
        >
          Cancelar
        </button>
      </div>
    </RgBaseModal>
  </div>
</template>
<script type="text/javascript">
import { RgBaseModal, IconDanger, RgInput, toast } from "~tokio/primitive";
import RgComboboxCancelReason from "$surgery-center/surgery/component/combobox/rg-combobox-cancel-reason/RgComboboxCancelReason";
import SurgeryControlModel from "$surgery-center/surgery/model/SurgeryControlModel";

export default {
  name: "RgCancelModal",
  components: {
    RgBaseModal,
    IconDanger,
    RgComboboxCancelReason,
    RgInput,
  },
  props: {
    title: {
      type: String,
      default: "Confirm",
    },
    message: {
      type: String,
      default: "Are you sure?",
    },
    show: {
      type: Boolean,
      default: undefined,
    },
    surgeryId: {
      default: "",
    },
    sectorId: {
      required: true,
    },
  },
  data() {
    return {
      cancel: {
        ccc_id_motivos_cancelamentos: "",
        ccc_observacao: "",
      },
    };
  },
  methods: {
    yesClicked: function () {
      const data = { ...this.cancel, cci_id_setores: this.sectorId };
      if (data.ccc_id_motivos_cancelamentos) {
        SurgeryControlModel.removeSchedulingRequest(this.surgeryId, data)
          .then((pRes) => {
            if (pRes) {
              this.$store.dispatch("SurgeryCenter/Surgery/filter");
              this.$emit("confirmed");
              this.cancel.ccc_id_motivos_cancelamentos = "";
              this.cancel.ccc_observacao = "";
              toast.success(
                "O pedido de cancelamento foi efetuado com sucesso",
                "Cancelamento efetuado...",
              );
            }
          })
          .catch((pErr) => {
            console.error(pErr);
          });
      } else {
        toast.error(
          "Para cancelar uma cirurgia é obrigatório informar o seu motivo do cancelamento",
          "Falha ao cancelar...",
        );
      }
    },
    noClicked: function () {
      this.$emit("denied");
      this.cancel.ccc_id_motivos_cancelamentos = "";
      this.cancel.ccc_observacao = "";
    },
    close: function () {
      this.$emit("close");
    },
  },
};
</script>
