<template>
  <section class="wedding-certificate-document">
    <FormBase
      title="Certidão de Casamento"
      class="form-base-wedding-certificate"
    >
      <div class="grid">
        <div class="row-1">
          <RgInput
            ref="cartorio"
            v-model="form.ctc_cartorio"
            :rules="{ required: required }"
            :change="disableOrEnabledRequired"
            class="registry"
            data-id="cartório-certidão-casamento"
            label="Cartório"
            title="Cartório da Certidão de Casamento"
            :maxlength="100"
          />

          <RgInput
            ref="livro"
            v-model="form.ctc_livro"
            :rules="{ required: required }"
            :change="disableOrEnabledRequired"
            class="book"
            data-id="livro-certidão-casamento"
            label="Livro"
            title="Livro da Certidão de Casamento"
            :maxlength="20"
          />

          <RgInput
            ref="folha"
            v-model="form.ctc_folha"
            :rules="{ required: required }"
            :change="disableOrEnabledRequired"
            class="leaf"
            data-id="folha-certidão-casamento"
            label="Folha"
            title="Folha da Certidão de Casamento"
            :maxlength="4"
          />
        </div>

        <div class="row-2">
          <RgInput
            ref="termo"
            v-model="form.ctc_termo"
            :rules="{ required: required }"
            :change="disableOrEnabledRequired"
            class="term"
            data-id="termo-certidão-casamento"
            label="Termo"
            title="Termo da Certidão de Casamento"
            :maxlength="8"
          />

          <RgInputDate
            ref="emissao"
            v-model="form.ctc_emissao"
            :rules="{ required: required }"
            :max-date="new Date()"
            class="dateIssue"
            placeholder="dd/mm/aaaa"
            data-id="data-emissão-certidão-casamento"
            label="Data de Emissão"
            title="Data de Emissão da Certidão de Casamento"
          />
        </div>
      </div>

      <!-- <div class="attachment">
        <rg-attachment-button
          :class="{ 'disable': disabledAttachment }"
          :disbaled="disabledAttachment"
          class="button"
          @click="clicked"
        />
      </div> -->
    </FormBase>
  </section>
</template>

<script>
import { RgInput, RgInputDate } from "~tokio/primitive";
import FormBase from "~tokio/foundation/form-base/FormBase";

export default {
  name: "WeddingCertificateDocument",
  components: {
    FormBase,
    RgInput,
    RgInputDate,
  },
  props: {
    weddingCertificateData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        ctc_id: 0,
        ctc_id_tipos_documento: 0,
        ctc_cartorio: "",
        ctc_livro: "",
        ctc_folha: "",
        ctc_termo: "",
        ctc_emissao: "",
      },
      required: false,
    };
  },
  computed: {
    disabledAttachment() {
      const registry =
        this.form.ctc_cartorio && this.form.ctc_cartorio.length > 0;
      const book = this.form.ctc_livro && this.form.ctc_livro.length > 0;
      const leaf = this.form.ctc_folha && this.form.ctc_folha.length > 0;
      const term = this.form.ctc_termo && this.form.ctc_termo.length > 0;
      const dateIssue =
        this.form.ctc_emissao && this.form.ctc_emissao.length >= 10;

      return !(registry && book && leaf && term && dateIssue);
    },
  },
  watch: {
    weddingCertificateData(pData) {
      this.mountWeddingData(pData);
    },
    "form.ctc_emissao"() {
      this.disableOrEnabledRequired();
    },
  },
  methods: {
    mountWeddingData(pData) {
      if (pData) {
        this.form = {
          ctc_id: pData.ctc_id || 0,
          ctc_id_tipos_documento: pData.ctc_id_tipos_documento || 0,
          ctc_cartorio: pData.ctc_cartorio,
          ctc_livro: pData.ctc_livro,
          ctc_folha: pData.ctc_folha,
          ctc_termo: pData.ctc_termo,
          ctc_emissao: pData.ctc_emissao,
        };
      }
    },

    getWeddingCertificate() {
      return this.form;
    },

    cleanForm() {
      this.form = {
        ctc_id: 0,
        ctc_id_tipos_documento: 0,
        ctc_cartorio: null,
        ctc_livro: "",
        ctc_folha: "",
        ctc_termo: "",
        ctc_emissao: "",
      };
      this.cleanValidate();
    },

    clicked() {
      this.$emit("click");
    },

    disableOrEnabledRequired() {
      this.required =
        !!this.form.ctc_cartorio ||
        !!this.form.ctc_livro ||
        !!this.form.ctc_folha ||
        !!this.form.ctc_termo ||
        !!this.form.ctc_emissao;

      if (!this.required) {
        this.cleanValidate();
      }
    },

    cleanValidate() {
      this.$refs.cartorio.cleanValidate();
      this.$refs.livro.cleanValidate();
      this.$refs.folha.cleanValidate();
      this.$refs.termo.cleanValidate();
      this.$refs.emissao.cleanValidate();
      this.required = false;
    },
  },
};
</script>
