import { GraphQLClient, gql } from "@/common/request/GraphQLClient";
import { BrazilianDateToDatabase } from "~utils/date";

const mutation = gql`
  mutation(
    $personId: Int!
    $person: PersonInputType!
    $address: AddressInputType
    $cns: CnsInputType
    $telephones: TelephoneInputType
  ) {
    UpdatePerson(
      personId: $personId
      person: $person
      address: $address
      cns: $cns
      telephones: $telephones
    ) {
      pes_nome
    }
  }
`;

export default ({ commit }, personInfo) => {
  const personId = personInfo.pes_id;
  const address = Object.assign({}, personInfo.address);
  const cns = Object.assign({}, personInfo.cns);
  const telephones = Object.assign({}, personInfo.telephones);

  const person = Object.assign({}, personInfo);
  delete person.address;
  delete person.cns;
  delete person.telephones;

  address.end_ativo = 1;

  flattenData(address, person, telephones);
  removeUnusedFields(person, telephones);
  sanitizeFields(address, person);

  const variables = {
    personId,
    person,
    address,
    cns,
    telephones,
  };

  return new Promise((resolve, reject) => {
    GraphQLClient.mutate({ mutation, variables })
      .then((pData) => {
        resolve(pData);
      })
      .catch((err) => {
        switch (err.graphQLErrors[0].message) {
          case "Query was empty": {
            reject(new Error("Nenhuma alteração foi feita"));
            break;
          }
          default: {
            return reject(err);
          }
        }
      });
  });
};

const flattenData = (address, person, telephones) => {
  telephones.tel_id_pessoas = person.pes_id;
  address.bai_nome = person.bai_nome;
  address.mun_id = person.mun_id;
  address.end_numero = person.end_numero;
  address.end_complemento = person.end_complemento;
  address.end_logradouro = person.end_logradouro;
  address.tlg_id = person.end_id_tipos_logradouros;
  address.end_cep = person.end_cep;
  address.zon_id = 4;
};

const removeUnusedFields = (person, telephones) => {
  delete telephones.tel_full;
  delete telephones.tel_id_codigo;
  delete person.nationality;
  delete person.nome_municipios;
  delete person.person;
  delete person.pes_etnias;
  delete person.pes_etnias_nomes;
  delete person.pdf_cns;
  delete person.cns;
  delete person.city;
  delete person.ethnicity;
  delete person.end_complemento;
  delete person.end_numero;
  delete person.end_id_bairros;
  delete person.end_id_tipos_logradouros;
  delete person.end_cep;
  delete person.end_logradouro;
  delete person.bai_nome;
  delete person.publicPlaceType;
  delete person.est_sigla;
  delete person.est_id;
  delete person.mun_id;
  delete person.mun_nome;
  delete person.nac_nome;
  delete person.sex_sigla;
  delete person.tlg_id;
  delete person.__typename;
};

const sanitizeFields = (address, person) => {
  person.pes_nascimento = person.pes_nascimento
    ? BrazilianDateToDatabase(person.pes_nascimento)
    : "0000-00-00";

  address.end_cep = address.end_cep
    ? address.end_cep.replace(/[^\d]/g, "")
    : "00000000";
};
