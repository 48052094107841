<style src="./RgToggleRadio.scss" lang="scss" scoped></style>
<template lang="html">
  <fieldset class="rg-input--component">
    <RgFormBase :label="label">
      <div
        :class="{
          'rg-input--base__enable': picked === 1,
          'rg-input--base__disable': picked === 0,
        }"
        class="rg-input--base"
      >
        <div class="rg-input--textbox-container">
          <label>
            <input v-model="picked" :value="1" type="radio" />
            <div
              class="rg-input--span-container rg-input--span-container__enable"
            >
              <span v-show="picked === 1">Habilitar</span>
            </div>
          </label>

          <label>
            <input v-model="picked" :value="0" type="radio" />
            <div
              class="rg-input--span-container rg-input--span-container__disable"
            >
              <span v-show="picked === 0">Inabilitar</span>
            </div>
          </label>
        </div>
      </div>
    </RgFormBase>
  </fieldset>
</template>

<script>
import { RgFormBase } from "~tokio/foundation/container";
export default {
  name: "RgToggleRadio",
  components: {
    RgFormBase,
  },
  props: {
    value: {
      default: 1,
    },
    label: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      picked: null,
    };
  },
  computed: {
    validValue() {
      return this.picked;
    },
  },
  watch: {
    picked(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue) {
      this.picked = newValue;
    },
  },
  mounted() {
    this.picked = this.value;
  },
};
</script>
