<template>
  <div class="tooltip">
    <div class="container">
      <slot name="icon" class="icon" />
      <span class="box">
        <slot name="content" class="content" />
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tooltip",
};
</script>
