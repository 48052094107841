import PermissionLoaderStore from "./store/index";
import PermissionLoaderGlobal from "./PermissionLoaderGlobal";

export const createLoader = (store) => {
  const hasModule = store.hasModule("PermissionLoader"); // Object.keys(store._modulesNamespaceMap).findIndex(i => i.startsWith('PermissionLoader')) >= 0
  if (!hasModule) {
    store.registerModule("PermissionLoader", PermissionLoaderStore);
  }
  return {
    start: (message) => {
      store.commit("PermissionLoader/START_LOADER", message);
    },
    message: (message) => {
      if (!message) return;
      store.commit("PermissionLoader/UPDATE_MESSAGE", message);
    },
    finish: (timeout = 1000, message) => {
      if (message) {
        store.commit("PermissionLoader/UPDATE_MESSAGE", message);
      }
      setTimeout(() => {
        store.commit("PermissionLoader/CLEAN_LOADER", message);
      }, timeout);
    },
  };
};

export default function installed(_Vue, store) {
  const loader = createLoader(store);
  _Vue.component("PermissionLoaderGlobal", PermissionLoaderGlobal);
  _Vue.prototype.$permissionLoader = loader;
  return true;
}
