import AuthRequest from "~common/request/AuthRequest";

export default (pPeriodDate, pUnitHealthId) => {
  return AuthRequest.get(
    "/billing/hospitalization-billing/download-hospitalization-billing",
    {
      responseType: "blob",
      period: pPeriodDate,
      unitHealth: pUnitHealthId,
    },
  );
};
