import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query {
    hospitalizationContraceptiveMethods {
      rows {
        imc_id
        imc_codigo
        imc_descricao
      }
    }
  }
`;

export default async (variables) => {
  const { data } = await GraphQLClient.query({ query, variables }).catch(
    (err) => {
      console.error(err);
    },
  );
  return data.hospitalizationContraceptiveMethods.rows;
};
