const ExamMixinPerDay = {
  data() {
    return {
      perDay: "per-day",
      routePerDay: "/exam/schedule/per-day",
    };
  },
  methods: {
    removeFilterPerDay() {
      this.$store.commit("Exam/Schedule/RESET_FILTER_EXAMS_PER_DAY");
    },
  },
};

export default ExamMixinPerDay;
