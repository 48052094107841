<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchVacanciesAvailableScale from "./action/SearchVacanciesAvailableScale";

export default {
  name: "RgComboxUnithealth",
  mixins: [RgComboboxMixin],
  props: {
    scale: {
      type: Number,
    },
    date: {
      type: String,
    },
  },
  data: () => {
    return { mData: [] };
  },
  watch: {
    scale() {
      this.fillData();
    },
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      if (!this.scale) {
        return;
      }

      const data = await this.getData();
      const max = data.length;

      this.mData = [];

      let count = 1;
      while (count <= max) {
        let str = `${count} vagas`;
        if (count === 1) {
          str = `${count} vaga`;
        }
        this.mData.push({ value: count.toString(), text: str });
        count++;
      }

      if (this.mData.length === 1) {
        this.$emit("selectOnlyItem", this.mData[0].value);
      }
    },
    async getData() {
      const data = await SearchVacanciesAvailableScale({
        intIdEscalaDeAgendamento: this.scale,
        strDataExame: this.date,
      });

      return data;
    },
  },
};
</script>
