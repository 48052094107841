const OPENED = 0;
// const BILED = 1
// const BILLING = 2
// const EXPORTED = 3

export default {
  bulletinList: [],
  selectedBulletinId: null,
  bulletinStatus: OPENED,
  bofId: null,
  selectedBulletinNumber: null,
  searchFilter: {
    initialDate: "",
    finalDate: "",
    pes_nome: "",
    bol_numero: "",
    status: null,
  },
};
