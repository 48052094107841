<template>
  <Modulebox class="col-12" title="Exportar Informações da Fila">
    <ExportQueueInfoForm />
  </Modulebox>
</template>

<script>
import Modulebox from "~tokio/foundation/modulebox/Modulebox";
import ExportQueueInfoForm from "$queue/common/components/export-queue-info-form/ExportQueueInfoForm";
export default {
  name: "ExportQueueInfo",
  components: {
    Modulebox,
    ExportQueueInfoForm,
  },
};
</script>
