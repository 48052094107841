import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation DeleteTelephone($phoneId: Int!) {
    DeleteTelephone(tel_id: $phoneId) {
      tel_id
    }
  }
`;

export default async (store, { phoneId, contactIndex }) => {
  const variables = { phoneId };
  try {
    await GraphQLClient.mutate({ mutation, variables });
    return { contactIndex };
  } catch (err) {
    return Promise.reject(err);
  }
};
