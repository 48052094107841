<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchExternalLocation from "./action/SearchExternalLocation.js";
export default {
  name: "RgComboboxExternalLocation",
  mixins: [RgComboboxMixin],
  props: {
    IdModulo: {
      type: Number,
      required: true,
    },
  },
  data: () => {
    return {};
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      try {
        this.mData = [];
        const data = await SearchExternalLocation({
          intIdModulo: this.IdModulo,
        });
        data.forEach((item) => {
          this.mData.push({ value: item.data, text: item.label });
        });
      } catch (error) {
        this.$toaster.error(error);
      }
    },
  },
};
</script>
