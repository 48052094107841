import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation UpdateEmployeeRelationship(
    $employeeRelationshipId: Int
    $employeeRelationship: EmployeeRelationshipInputType
  ) {
    UpdateEmployeeRelationship(
      employeeRelationshipId: $employeeRelationshipId
      employeeRelationship: $employeeRelationship
    ) {
      vin_id
      vin_carga_horaria
    }
  }
`;

export default async (store, variables) => {
  try {
    const { data } = await GraphQLClient.mutate({ mutation, variables });
    return data.UpdateEmployeeRelationship;
  } catch (err) {
    return Promise.reject(err.graphQLErrors[0]);
  }
};
