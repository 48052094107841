import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import EmployeeSearch from "@/app/employee/view/employee-search/EmployeeSearch";

export default {
  path: "/register/employee/search",
  name: "register.employee.search",
  components: {
    default: EmployeeSearch,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [{ label: "Funcionario" }],
  },
};
