<style src="./InputCns.scss" lang="scss" scoped></style>

<template>
  <fieldset class="rg-input--component">
    <RgInputSuscard v-model="form.info.crs_numero" @blur="validateBlur" />
    <!-- <rg-form-base
      label="Número do CNS"
      class="form-base">
      <div class="rg-input--base">
        <div class="rg-input--textbox-container">
          <rg-validation-alert :alert="error"/>
          <input
            v-mask="'### #### #### ####'"
            v-model="form.info.crs_numero"
            type="text"
            @blur="validateBlur">
        </div>
      </div>
    </rg-form-base> -->
  </fieldset>
</template>

<script>
import {
  RgValidatorMixin,
  RgInputMixin,
  RgInputSuscard,
} from "~tokio/primitive";
import { mask } from "vue-the-mask";
import GetCnsByPersonId from "./actions/GetCnsByPersonId";

export default {
  name: "InputCns",
  components: {
    RgInputSuscard,
  },
  directives: {
    mask,
  },
  mixins: [RgValidatorMixin, RgInputMixin],
  props: {
    documentData: {
      default: null,
    },
  },
  data() {
    return {
      form: {
        info: {
          crs_numero: "",
        },
        mutationName: "AddCnsToPerson",
        mutationParams: "$pes_id: Int, $crs_numero: String",
        mutationArgs: "pes_id: $pes_id, crs_numero: $crs_numero",
        mutationReturnField: "crs_numero",
      },
    };
  },
  watch: {
    "form.info": {
      handler: function () {
        const form = JSON.parse(JSON.stringify(this.form));
        form.info.crs_numero = form.info.crs_numero
          ? form.info.crs_numero.replace(/(\s)/gi, "")
          : "";
        this.$emit("formatted-data", form);
      },
      deep: true,
    },
    documentData(pValue) {
      this.form.info = pValue.info;
    },
  },
  mounted() {
    this.readInfo();
  },
  methods: {
    validateBlur() {
      this.validate();
    },
    async readInfo() {
      const data = await GetCnsByPersonId();
      this.form.info.crs_numero = data.cns.crs_numero;
    },
    clean() {
      this.form.info.crs_numero = "";
    },
  },
};
</script>
