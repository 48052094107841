<template lang="html">
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 97.594 97.594"
    style="enable-background: new 0 0 97.594 97.594"
    xml:space="preserve"
  >
    <g>
      <circle cx="48.194" cy="10.099" r="10.099" />
      <path
        d="M50.708,20.784l-2.515,2.532c0,0-2.474-2.552-2.465-2.556c-0.011,0.004-20.023,6.825-16.406,30.535
		c0.202,2.021,1.708,4.031,3.697,4.031c0.131,0,0.263-0.006,0.396-0.02c2.154-0.216,3.726-2.138,3.511-4.291
		c-0.593-5.93-0.166-10.081,0.931-13.155c0,0,0,10.928,0,13.993c0,3.463,0.717,3.813,0.717,3.813l0.002,37.294
		c0.001,2.559,2.075,4.633,4.634,4.633c2.56,0,4.634-2.074,4.634-4.633L47.842,60.74c0.184,0.009,0.367,0.023,0.552,0.023
		c0.036,0,0.071-0.005,0.107-0.005L48.5,92.96c0,2.56,2.075,4.633,4.634,4.633c2.56,0,4.634-2.073,4.634-4.633l0.001-37.312
		c0,0,0.763-1.023,0.763-3.795c0-2.373,0-4.715,0-4.715c3.321-0.638,8.882-2.425,10.082-7.347
		C69.917,34.442,57.647,22.777,50.708,20.784z M58.53,34.812c1.87,1.756,2.318,2.772,2.421,3.11
		c-0.354,0.367-1.284,0.803-2.421,1.151V34.812z M54.205,50.818h-12.02c-0.583,0-1.057-0.473-1.057-1.057V31.931
		c0-0.583,0.474-1.056,1.057-1.056h2.675v-0.462c0-0.583,0.473-1.057,1.056-1.057h4.559c0.583,0,1.057,0.473,1.057,1.057v0.462
		h2.675c0.583,0,1.057,0.473,1.057,1.056v7.797c-0.932,0.224-1.759,0.333-2.188,0.364c-2.159,0.156-3.784,2.033-3.628,4.192
		c0.148,2.064,1.869,3.639,3.905,3.639c0.096,0,0.189-0.003,0.286-0.01c0.452-0.033,1.008-0.098,1.623-0.196v2.045
		C55.261,50.346,54.788,50.818,54.205,50.818z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: "Acs",
};
</script>
