import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation CreateTelephone($phoneInput: TelephoneInputType!) {
    CreateTelephone(telephone: $phoneInput) {
      tel_id
      tel_numero
      tel_recado
      ddd {
        ddd_id
        ddd_codigo
      }
    }
  }
`;

const queryGetDddId = gql`
  query GetDddId($dddCode: String) {
    ddds(ddd_codigo: $dddCode) {
      rows {
        ddd_id
        ddd_codigo
      }
    }
  }
`;

export default async (
  { commit, state },
  { personId, contactValue, contactMessage },
) => {
  const phoneDddByValue = await getPhoneDddByValue(contactValue);
  const phoneNumber = contactValue.replace(/[^\d]/gi, "").slice(2, 13);

  const variables = {
    phoneInput: {
      tel_id_pessoas: personId,
      tel_id_ddds: phoneDddByValue,
      tel_numero: phoneNumber,
      tel_recado: contactMessage,
    },
  };
  try {
    const { data } = await GraphQLClient.mutate({ mutation, variables });
    return data.CreateTelephone;
  } catch (err) {
    return Promise.reject(err.graphQLErrors);
  }
};

const getPhoneDddByValue = async (contactValue) => {
  const dddCode = contactValue.match(/[(](\d{2})[)]/)[1];
  const variables = { dddCode };
  const query = queryGetDddId;
  const { data } = await GraphQLClient.query({
    query,
    variables,
    fetchPolicy: "no-cache",
  }).catch((err) => {
    console.error(err);
  });
  if (data.ddds.rows && data.ddds.rows.length >= 0)
    return data.ddds.rows[0].ddd_id;
  return false;
};
