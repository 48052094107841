<template>
  <section class="quick-hospitalization-external-schedule-regulation-form">
    <form class="form-container" @submit.stop.prevent="saveForm">
      <RgValidatorForm ref="validator">
        <FormBase title="Paciente" class="form-base">
          <div class="title-area">
            <p class="text-title"></p>
          </div>

          <div class="patient-list">
            <div class="table-grid">
              <div class="tr">
                <p class="th">Pacientes</p>
                <p class="th">Prontuário Único</p>
                <p class="th"></p>
              </div>
              <div class="grid-body">
                <div
                  v-for="(item, index) in form.patientList"
                  :key="index"
                  class="tr"
                >
                  <p class="td">{{ item.pes_nome }}</p>
                  <p class="td">{{ item.pac_prontuario_unico }}</p>
                  <div class="actions">
                    <p
                      class="td"
                      title="Editar Paciente"
                      @click="editPatient(item.pes_id)"
                    >
                      <IconDocumentPerson class="svg icon-document" />
                    </p>

                    <p class="td" title="Histórico do Paciente">
                      <IconHistory class="svg icon-history" />
                    </p>

                    <p
                      v-show="form.patientList && form.patientList.length > 1"
                      class="td"
                      title="Apagar"
                      @click="removePatient(index)"
                    >
                      <IconRemove class="svg icon-remove" />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </FormBase>

        <FormBase title="Agendar Externo" class="form-base">
          <div class="schedule-area">
            <RgComboboxExternalLocation
              v-model="form.aex_id_locais_externos"
              label="Destino"
              :rules="{ required: true }"
              :IdModulo="MODULE_ID"
            />

            <RgTextArea
              ref="textArea"
              v-model="form.aex_observacao"
              :maxlength="500"
              label="Observação"
              class="textarea"
            />
          </div>
        </FormBase>
      </RgValidatorForm>
    </form>
  </section>
</template>

<script>
import FormBase from "~tokio/foundation/form-base/FormBase";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb.js";

import {
  RgValidatorForm,
  RgTextArea,
  RgComboboxExternalLocation,
  IconRemove,
  IconDocumentPerson,
  IconHistory,
} from "~tokio/primitive";

export default {
  name: "QuickHospitalizationExternalScheduleRegulationForm",
  components: {
    FormBase,
    RgValidatorForm,
    RgTextArea,
    RgComboboxExternalLocation,
    IconRemove,
    IconDocumentPerson,
    IconHistory,
  },

  data() {
    return {
      form: {
        aex_id_locais_externos: null,
        aex_observacao: "",
        patientList: [],
      },
    };
  },

  created() {
    this.MODULE_ID = 14;
  },

  async mounted() {
    const existData = await this.$store.getters[
      "Hospitalization/Queue/GET_SELECTED_EXTERNAL_HOSPITALIZATION_RESULT"
    ];

    if (existData) {
      this.form.patientList = existData;
    }
  },

  beforeDestroy() {
    const validateIfExist = ValidateIfRouteExistInBreadscrumb(
      "/hospitalization/queue/external-schedule-form",
      this.$route.meta.breadcrumb,
    );

    if (!validateIfExist) {
      this.$store.commit(
        "Hospitalization/Queue/UNSELECT_QUEUE_EXTERNAL_HOSPITALIZATION_DATA",
      );
    }
  },

  methods: {
    editPatient(pesId) {
      this.$store.commit("Person/Patient/SET_PERSON_ID", pesId);
      this.$router.push({
        name: "appointment.schedule.queue.external-schedule-form.new-patient",
      });
    },

    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    async saveForm() {
      if (!(await this.isFormValid())) {
        this.$toaster.warning("Verifique os Campos");
        return false;
      }

      if (!this.form.patientList) {
        this.$toaster.error("Deve existir pacientes para salvar.");
        return false;
      }

      try {
        this.$loader.start();

        const patientList = [];

        this.form.patientList.map((item, index) => {
          patientList.push({
            fil_id: item.fil_id,
            pac_id: item.pac_id,
            Paciente: item.pes_nome,
            pcc_id: item.pcc_id,
            "Prontuario Unico": item.pac_prontuario_unico,
          });
        });

        const variables = {
          aex_id_locais_externos: this.form.aex_id_locais_externos,
          aex_observacao: this.form.aex_observacao,
          idModulo: this.MODULE_ID,
          pacientes: patientList,
        };

        await this.$store.dispatch(
          "Hospitalization/Queue/SAVE_HOSPITALIZATION_EXTERNAL_SCHEDULE",
          variables,
        );
        this.$toaster.success("Agendado Externamente com Sucesso!");
        this.$router.go(-1);
      } catch (Err) {
        this.$toaster.error("Erro ao salvar o agendamento externo.");
      } finally {
        this.$loader.finish();
      }
    },

    removePatient(index) {
      if (this.form.patientList && this.form.patientList.length > 1) {
        this.form.patientList.splice(index, 1);
        this.$store.commit(
          "Hospitalization/Queue/SELECT_QUEUE_EXTERNAL_HOSPITALIZATION_DATA",
          Object.values(this.form.patientList),
        );
      }
    },
  },
};
</script>
