<template lang="html">
  <svg
    width="24"
    height="19"
    viewBox="0 0 24 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.302122 7.43572C0.704871 7.84996 1.35799 7.85003 1.76081 7.43572L5.46841 3.6222L5.46848 14.9391C5.46848 15.525 5.9303 16 6.49994 16C7.06957 16 7.53139 15.525 7.53139 14.9391L7.53132 3.62199L11.2392 7.43558C11.642 7.84989 12.2951 7.84989 12.6979 7.43558C13.1007 7.02127 13.1007 6.34959 12.6979 5.93528L7.22924 0.310769C7.03581 0.111747 6.77348 4.09334e-07 6.49994 3.85421e-07C6.22639 3.61507e-07 5.96399 0.111818 5.77056 0.310768L0.302054 5.93542C-0.100695 6.34973 -0.100697 7.02141 0.302122 7.43572Z"
      fill="white"
    />
    <path
      d="M23.6979 11.5643C23.2951 11.15 22.642 11.15 22.2392 11.5643L18.5316 15.3778L18.5315 4.06088C18.5315 3.47499 18.0697 3 17.5001 3C16.9304 3 16.4686 3.47499 16.4686 4.06088L16.4687 15.378L12.7608 11.5644C12.358 11.1501 11.7049 11.1501 11.3021 11.5644C10.8993 11.9787 10.8993 12.6504 11.3021 13.0647L16.7708 18.6892C16.9642 18.8883 17.2265 19 17.5001 19C17.7736 19 18.036 18.8882 18.2294 18.6892L23.6979 13.0646C24.1007 12.6503 24.1007 11.9786 23.6979 11.5643Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "ArrowDouble",
};
</script>
