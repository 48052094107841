import AuthRequest from "~common/request/AuthRequest";
import Store from "@/store";

export default (procedureList) => {
  const promiseRequest = [];

  const { bof_cns, bof_sexo } = Store.getters["Billing/Billing/GET_ALL_INFO"];

  for (const procedureItem in procedureList) {
    const params = {
      pdf_id_internacoes_caracteres:
        procedureList[procedureItem].pdf_id_internacoes_caracteres,
      pdf_cod_procedimento: procedureList[procedureItem].pdf_cod_procedimento,
      pdf_cod_cbo: procedureList[procedureItem].pdf_cod_cbo,
      bof_cns,
      bof_sexo,
    };
    promiseRequest.push(doRequest(params, procedureItem));
  }

  Promise.all(promiseRequest).then((requestResult) => {
    fetchProcedureValidationResult(requestResult);
  });
};

const doRequest = async (params, id) => {
  const result = await AuthRequest.get(
    "/billing/bulletin-billing/validate-procedure-employee-billing",
    params,
  ).catch((pResult) => {
    return Promise.reject(pResult.response);
  });

  return {
    status: result.data === true,
    result: result.data === true ? [] : result.data,
    arrayIndex: parseInt(id),
  };
};

const fetchProcedureValidationResult = (promiseResult) => {
  Store.commit("Billing/Billing/RESET_PROCEDURES_STATUS");
  for (const promiseItem in promiseResult) {
    if (!promiseResult[promiseItem].status) {
      Store.commit(
        "Billing/Billing/SET_PROCEDURES_STATUS_BY_ID",
        promiseResult[promiseItem],
      );
    }
  }
};
