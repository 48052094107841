<style src="./SpecialtiesAttendancesCount.scss" lang="scss" scoped></style>
<template>
  <section class="report-container row">
    <div class="row filter-fields">
      <RgComboboxUnithealth v-model="form.uns_id" :rules="{ required: true }" />
    </div>
    <div class="row filter-fields">
      <RgInputDate
        v-model="form.initialDate"
        :rules="{ required: true }"
        class="col-6"
        label="Data inicial"
        placeholder="Informe a data de início"
      />

      <RgInputDate
        v-model="form.finalDate"
        :rules="{ required: true }"
        class="col-6"
        label="Data final"
        placeholder="Informe a data final"
      />
    </div>
    <RgIframe v-if="showPrint" :src="printLink" class="iframe-hidden" />
  </section>
</template>

<script>
import { RgInputDate } from "~tokio/primitive";
import RgIframe from "~tokio/foundation/rg-iframe/RgIframe";
import moment from "moment";
import RgComboboxUnithealth from "$billing/common/component/rg-combobox-unithealth/RgComboboxUnithealth";

export default {
  name: "SpecialtiesAttendancesCount",
  components: {
    RgInputDate,
    RgIframe,
    RgComboboxUnithealth,
  },
  filters: {
    formatDateBR(pDate) {
      return moment(pDate, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
  },
  data() {
    return {
      resultTitle: "Registro de Atendimento por Especialidade",
      form: {
        initialDate: moment().format("DD/MM/YYYY"),
        finalDate: moment().add(2, "days").format("DD/MM/YYYY"),
        uns_id: 0,
      },
      list: {},
      totalGlobal: 0,
      printCalculate: false,
      showPrint: false,
      hashRefresh: "123",
    };
  },
  computed: {
    columnTable() {
      return this.COLUMN;
    },
    getNameSmartTable() {
      return "SpecialtiesAttendancesCount";
    },
  },
  created() {
    this.COLUMN = [
      { name: "Especialização", key: "esp_nome" },
      { name: "Unidade de Saúde", key: "uns_nome" },
      { name: "Setor", key: "set_nome" },
      { name: "Total", key: "total" },
    ];
  },
  mounted() {
    this.form.uns_id = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
  },
  methods: {
    buildFilter() {
      return {
        uns_id: this.form.uns_id,
        initialDate: String(this.form.initialDate),
        finalDate: String(this.form.finalDate),
      };
    },
    printReportCss() {
      return "";
    },
    clearFilter() {
      this.form = {
        initialDate: "",
        finalDate: "",
        uns_id: 0,
      };
    },
    async doSearch(pData) {
      try {
        return this.$store.dispatch(
          "Emergency/Report/GET_ATTENDANCES_COUNT_BY_SPECIALTIES",
          pData,
        );
      } catch (error) {
        return false;
      }
    },
    formatDate(pDate) {
      this.$utils.date.BrazilianDateToDatabase(pDate);
    },
  },
};
</script>
