<style src="./RgSurgeryMaterialTypeCrud.scss" lang="scss" scoped></style>
<template lang="html">
  <div class="rg-surgery-material-type-crud">
    <section class="form">
      <RgSurgeryMaterialTypeForm @saved="fieldSaved" @search-for="searchFor" />
    </section>

    <section class="list">
      <RgPaginate
        :page-now="paginate.pageNow"
        :per-page="paginate.perPage"
        :page-count="pageCount"
        @forward-page="forwardPage()"
        @backward-page="backwardPage()"
      />
      <RgSurgeryMaterialTypeList
        :list="paginatedList"
        @selected="selectItem"
        @remove="remove"
      />
    </section>
  </div>
</template>

<script>
import RgPaginate from "$surgery-center/foundation/component/rg-paginate/RgPaginate";
import RgPaginateMixin from "$surgery-center/foundation/component/rg-paginate/mixin/RgPaginateMixin";
import RgSurgeryMaterialTypeForm from "./rg-surgery-material-type-form/RgSurgeryMaterialTypeForm";
import RgSurgeryMaterialTypeList from "./rg-surgery-material-type-list/RgSurgeryMaterialTypeList";
import SurgeryMaterialModel from "$surgery-center/surgery/model/SurgeryMaterialModel";
export default {
  name: "RgSurgeryMaterialTypeCrud",
  components: {
    RgSurgeryMaterialTypeForm,
    RgSurgeryMaterialTypeList,
    RgPaginate,
  },
  mixins: [RgPaginateMixin],
  data: () => {
    return {};
  },
  mounted() {
    this.fillList();
  },
  methods: {
    remove(item) {
      SurgeryMaterialModel.remove(item.value).then((res) => {
        this.fillList();
      });
    },
    fieldSaved() {
      this.fillList();
    },
    fillList() {
      SurgeryMaterialModel.search({ tmc_ativo: 1 }).then((res) => {
        this.paginate.list = [];
        res.data.forEach((item) => {
          this.paginate.list.push(this.formatItem(item));
        });
      });
    },
    formatItem(item) {
      return {
        text: item.tmc_nome,
        value: item.tmc_id,
      };
    },
  },
};
</script>
