<style src="./SurgeryKits.scss" lang="scss" scoped></style>
<template lang="html">
  <div class="surgery-kits">
    <section class="teams-form">
      <form class="teams-list-form row" @submit.prevent="insertItem">
        <RgComboboxSurgeryKits
          v-model="lItem.value"
          label="Kit Cirúrgico"
          class="col-11"
          @change="pushItem"
        />
        <div class="form-submit col-1">
          <button class="button">
            <IconAdd class="button--icon" />
          </button>
        </div>
      </form>
    </section>
    <section class="form-list">
      <div class="requesting-list">
        <ul>
          <li>
            <ul class="form-list--container-header row">
              <li class="form-list--employee col-7">Kit Cirúrgico</li>
              <li class="form-list--remove col-1" />
            </ul>
          </li>
          <li
            v-for="(item, i) in templateListItems"
            :key="i"
            class="form-list--container-row"
          >
            <ul class="form-list--container-content row">
              <li class="form-list--employee col-11">{{ item.text }}</li>
              <li class="form-list--remove col-1">
                <RgRemoveSmall @confirm-remove="removeItem(i)" />
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
import { IconAdd, RgRemoveSmall } from "~tokio/primitive";
import RgComboboxSurgeryKits from "$surgery-center/surgery/component/combobox/rg-combobox-surgery-kits/RgComboboxSurgeryKits";
import SurgeryKitModel from "$surgery-center/surgery/model/SurgeryKitModel";
import SurgeryAggregateBaseMixin from "$surgery-center/surgery/component/mixin/SurgeryAggregateBaseMixin";
export default {
  name: "SurgeryKits",
  components: {
    IconAdd,
    RgComboboxSurgeryKits,
    RgRemoveSmall,
  },
  extends: SurgeryAggregateBaseMixin,
  data() {
    return {
      form: {
        surgeryKit: "",
      },
    };
  },
  methods: {
    pushItem(e) {
      this.seletedItem(e[0]);
    },
    fillList() {
      SurgeryKitModel.getKitsById(this.surgeryId, this.sectorId).then(
        (pList) => {
          const mapList = pList.map((pListItem) => {
            return {
              text: pListItem.tkc_nome,
              value: pListItem.kic_id_tipos_kits_cirurgicos,
            };
          });

          this.templateListItems = mapList;
          this.updateModel(mapList);
        },
      );
    },
    updateModel(pItem) {
      if (!pItem) return [];
      const arrFiltered = pItem.map((item) => {
        return {
          kic_id_tipos_kits_cirurgicos: item.value,
        };
      });
      this.$emit("input", arrFiltered);
      return arrFiltered;
    },
  },
};
</script>
