import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query OccupationUnifiedTable(
    $occupationName: String
    $occupationCode: String
    $limit: Int
  ) {
    occupationUnifiedTable(
      NO_OCUPACAO: $occupationName
      CO_OCUPACAO: $occupationCode
      limit: $limit
    ) {
      count
      rows {
        NO_OCUPACAO
        CO_OCUPACAO
      }
    }
  }
`;

export default async ({ variables }) => {
  const { data } = await GraphQLClient.query({ query, variables }).catch(
    (err) => {
      console.error(err);
    },
  );
  return data.occupationUnifiedTable;
};
