<style src="./ModalInformationPrint.scss" lang="scss" scoped></style>

<template lang="html">
  <ModalInformation
    ref="ModalInformation"
    class="modal-information-print"
    title="Imprimir listagem"
    :subtitle="`Foram encontrados ${listSize} registros na busca`"
    :show="show"
    @close="close"
  >
    <div slot="body-content" class="content">
      <IconLightBulbHelper class="svg" />
      <div class="message">
        <span class="label">Soluções para impressão da listagem:</span>
        <ul class="list">
          <li class="item">
            A impressão da listagem possui o limite de 1000 registros;
          </li>
          <li class="item">
            Faça uma nova busca com o período reduzido, por exemplo, para não
            ultrapassar o limite.
          </li>
        </ul>
      </div>
    </div>

    <div slot="footer-content" class="footer">
      <MediumButton
        label="Realizar nova Busca"
        :backgroundColor="'#FB7E36'"
        @click="close"
      />
    </div>
  </ModalInformation>
</template>

<script>
import ModalInformation from "~tokio/primitive/modal/modal-information/ModalInformation";
import { IconLightBulbHelper } from "~tokio/primitive/icon/";
import { MediumButton } from "~tokio/primitive/";

export default {
  name: "ModalInformationPrint",
  components: {
    ModalInformation,
    IconLightBulbHelper,
    MediumButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    listSize: {
      type: Number,
      default: 0,
    },
  },

  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
