import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query Occupation($occupationName: String, $occupationCode: String) {
    occupations(NO_OCUPACAO: $occupationName, CO_OCUPACAO: $occupationCode) {
      rows {
        NO_OCUPACAO
        CO_OCUPACAO
      }
    }
  }
`;

export default async ({ variables }) => {
  const { data } = await GraphQLClient.query({ query, variables }).catch(
    (err) => {
      console.error(err);
    },
  );
  return data.occupations.rows;
};
