import { AlertError, GetSplittedInfo } from "./";
import CheckCboProcedure from "../../store/actions/CheckCboProcedure";

/**
 *
 * @param {String} pProcedure
 * @param {String} pCbo
 * @param {String} pCompetence
 * @param {String[]} pErrors []
 */
const ValidateCbo = async (pProcedure, pCbo, pCompetence, pErrors = []) => {
  const splittedCbo = GetSplittedInfo(pCbo);
  const variables = {
    procedure: pProcedure,
    cbo: splittedCbo.document,
    competence: pCompetence,
  };

  const isValid = await CheckCboProcedure(variables);

  return !isValid
    ? AlertError("Procedimento incompatível com o CBO do profissional", pErrors)
    : true;
};

export default ValidateCbo;
