<template lang="html">
  <svg
    width="7.8082mm"
    height="7.8082mm"
    version="1.1"
    viewBox="0 0 7.8081508 7.8081507"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(228.24 -377.23)">
      <g transform="translate(-100.23)">
        <rect
          x="-128.01"
          y="377.23"
          width="7.8082"
          height="7.8082"
          ry="3.9041"
          fill="#93A7AC"
          style="paint-order: markers stroke fill"
        />
        <g transform="matrix(.010173 0 0 .010173 -126.33 379.02)" fill="white">
          <path
            d="m241.43 88.832c0 48.988-39.715 88.703-88.703 88.703s-88.703-39.715-88.703-88.703c0-48.988 39.715-88.703 88.703-88.703s88.703 39.715 88.703 88.703z"
          />
          <path d="m382.52 248.16 16.191-16.195 37.547 37.547-16.191 16.195z" />
          <path d="m275.76 354.8 96.801-96.801l37.547 37.547-96.801 96.801z" />
          <path d="m253.3 414.92 47.098-15.699-31.398-31.402z" />
          <path
            d="m259.3 352.52 0.30078-0.59765c0-0.10156 0-0.10156 0.0977-0.19922 0.22656-0.51172 0.53125-0.98438 0.90234-1.4023 0-0.0977 0.0977-0.0977 0.0977-0.19922l0.3007-0.30078 29.102-29.098c-25.809-52.273-79.008-85.402-137.3-85.5-82 0-149.2 64.898-152.8 146h249.8l9.5977-28.703z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "EditPerson",
};
</script>
