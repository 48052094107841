<style src="./RgSuggestItem.scss" lang="scss" scoped></style>
<template>
  <li :class="{ selected: iAmSelected }" @click="chooseItem">
    <slot />
  </li>
</template>

<script>
export default {
  name: "RgSuggestItem",
  props: {
    item: {
      default: null,
    },
    idx: null,
  },
  data() {
    return {};
  },
  computed: {
    iAmSelected() {
      const selectedIdx = this.$parent.$parent.$parent.selectedItemIdx;
      return selectedIdx === this.idx;
    },
  },
  methods: {
    chooseItem() {
      this.$parent.$parent.itemSelected = true;
      this.$parent.$parent.validate();
      this.$parent.$parent.$parent.selectingItemFromSuggestList(this.item);
      this.$emit("itemSelected");
    },
  },
};
</script>
