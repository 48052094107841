<style src="./StatusFilter.scss" lang="scss" scoped></style>
<template lang="html">
  <div class="status-filter">
    <FilterBaseContainer :close-button="false" @close="closeFilter()">
      <ul class="filter-container">
        <li
          :class="{ 'filter-item--open--active': filterOpenClass }"
          class="filter-item filter-item--open"
          @click="toggleOption('open')"
        >
          Aberto
        </li>
        <li
          :class="{ 'filter-item--scheduled--active': filterScheduledClass }"
          class="filter-item filter-item--scheduled"
          @click="toggleOption('scheduled')"
        >
          Agendado
        </li>
        <li
          :class="{ 'filter-item--canceled--active': filterCanceledClass }"
          class="filter-item filter-item--canceled"
          @click="toggleOption('canceled')"
        >
          Cancelado
        </li>
        <li
          :class="{
            'filter-item--effectivated--active': filterEffectivatedClass,
          }"
          class="filter-item filter-item--effectivated"
          @click="toggleOption('effectivated')"
        >
          Efetivado
        </li>
        <li
          :class="{ 'filter-item--suspended--active': filterSuspendedClass }"
          class="filter-item filter-item--suspended"
          @click="toggleOption('suspended')"
        >
          Suspensa
        </li>
      </ul>
    </FilterBaseContainer>
  </div>
</template>

<script>
import FilterBaseContainer from "../filter-base-container/FilterBaseContainer";
import FilterBaseContainerMixin from "../filter-base-container/FilterBaseContainerMixin";

export default {
  name: "StatusFilter",
  components: {
    FilterBaseContainer,
  },
  mixins: [FilterBaseContainerMixin],
  data() {
    return {
      filterOptions: {
        open: { id: 1, caption: "ABERTO" },
        scheduled: { id: 2, caption: "AGENDADO" },
        canceled: { id: 3, caption: "CANCELADO" },
        effectivated: { id: 4, caption: "EFETIVADO" },
        suspended: { id: 5, caption: "SUSPENSA" },
      },
      statusFilter: [1, 2, 3, 4, 5],
    };
  },
  computed: {
    filterOpenClass() {
      return this.statusFilter.includes(1);
    },
    filterScheduledClass() {
      return this.statusFilter.includes(2);
    },
    filterCanceledClass() {
      return this.statusFilter.includes(3);
    },
    filterEffectivatedClass() {
      return this.statusFilter.includes(4);
    },
    filterSuspendedClass() {
      return this.statusFilter.includes(5);
    },
  },
  watch: {
    statusFilter(pValue) {
      this.filterValue = pValue;
      this.changeFilter("cci_id_status_controles_cirurgicos");
    },
    filterValue(pValue) {
      this.statusFilter = pValue;
    },
  },
  mounted() {
    this.filterValue = this.$store.getters["SurgeryCenter/Surgery/filterValue"](
      "cci_id_status_controles_cirurgicos",
    );
  },
  methods: {
    toggleOption(pFilter) {
      const hasInFilter = this.statusFilter.includes(
        this.filterOptions[pFilter].id,
      );
      const countFilters = this.statusFilter.length > 1;
      if (hasInFilter && countFilters) {
        this.removeFilterFromList(pFilter);
      } else {
        this.addFilterToList(pFilter);
      }
    },
    removeFilterFromList(pFilter) {
      const indexOfFilter = this.statusFilter.indexOf(
        this.filterOptions[pFilter].id,
      );
      const tmpFilterOpen = Array.from(this.statusFilter);
      if (indexOfFilter > -1) tmpFilterOpen.splice(indexOfFilter, 1);
      this.statusFilter = tmpFilterOpen;
    },
    addFilterToList(pFilter) {
      this.statusFilter.push(this.filterOptions[pFilter].id);
    },
  },
};
</script>
