<template>
  <MedicalLoader
    :to-close="medicalLoaderLoading"
    :titleLoader="medicalLoaderTitle"
  />
</template>
<script>
import MedicalLoader from "./MedicalLoader";
export default {
  name: "MedicalLoaderGlobal",
  components: {
    MedicalLoader,
  },
  computed: {
    medicalLoaderLoading() {
      return this.$store.state.MedicalLoader.loading;
    },
    medicalLoaderTitle() {
      return this.$store.state.MedicalLoader.message;
    },
  },
};
</script>
