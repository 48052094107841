import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import HospitalizationBedSearchRoute from "$hospitalization/bed/views/bed-search/HospitalizationBedSearch.vue";
import OpenHospitalizationModule from "$hospitalization/common/util/OpenHospitalizationModule.js";

export default {
  path: "/hospitalization/bed/search",
  name: "hospitalization.bed.search",
  components: {
    default: HospitalizationBedSearchRoute,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Internação", link: OpenHospitalizationModule },
      { label: "Lista de Leitos" },
    ],
  },
};
