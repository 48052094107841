<template lang="html">
  <svg
    width="4.7563mm"
    height="4.8135mm"
    version="1.1"
    viewBox="0 0 4.7563 4.8135"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <linearGradient
        id="linearGradient6851"
        x1="33684"
        x2="33357"
        y1="15473"
        y2="13840"
        gradientUnits="userSpaceOnUse"
        xlink:href="#id4"
      />
      <linearGradient
        id="id4"
        x1="33562"
        x2="33455"
        y1="14767"
        y2="14333"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#368DA3" offset="0" />
        <stop stop-color="#5FBCD3" offset="1" />
      </linearGradient>
      <linearGradient
        id="linearGradient6853"
        x1="33562"
        x2="33455"
        y1="14767"
        y2="14333"
        gradientUnits="userSpaceOnUse"
        xlink:href="#id4"
      />
      <linearGradient
        id="linearGradient6855"
        x1="33131"
        x2="34309"
        y1="15729"
        y2="14933"
        gradientUnits="userSpaceOnUse"
        xlink:href="#id4"
      />
    </defs>
    <g transform="translate(-87.546 -137.59)">
      <g
        transform="matrix(.0024068 0 0 .0024068 9.2479 104.3)"
        clip-rule="evenodd"
        fill-rule="evenodd"
        image-rendering="optimizeQuality"
        shape-rendering="geometricPrecision"
      >
        <path
          class="fil5"
          d="m33748 14993c-71.81 38.54-151.42 57.85-238.82 57.94h-0.56-219.35v322.61c0 32.33-5.94 62.69-17.78 91.07-2.6 6.21-5.47 12.3-8.63 18.28h605.77c1.04 0 2.08 0 3.12-0.01 83.78-0.41 163.76-17.19 237.01-47.36 234.79-96.73 400.42-331.06 400.42-604.78 0-552.3-447.72-1e3 -1e3 -1e3 -401.98 0-748.56 237.17-907.44 579.22-14.39 30.98-27.24 62.82-38.45 95.43-19.71 62.71-30.34 129.54-30.34 198.9 0 43.26 4.14 85.54 12.03 126.46 36.99 191.66 156.44 353.21 319.19 444.76 43.22 24.3 89.49 43.68 138.07 57.37v-781.54c0-68.75 12.59-133.01 37.76-192.71 25.06-59.46 62.38-113.55 111.93-162.28 49.51-48.68 104.11-85.31 163.78-109.86 9.52-3.92 19.14-7.53 28.88-10.82 51.59-17.46 106.27-26.18 164.03-26.18h0.56c68.45 0.07 132.62 12.4 192.49 37 52.4 21.52 100.95 52.34 145.65 92.46 6.26 5.62 12.45 11.42 18.56 17.4 49.71 48.68 87.17 102.78 112.35 162.28 25.26 59.68 37.9 123.93 37.9 192.71 0 63.92-10.87 123.83-32.6 179.73-1.66 4.26-3.38 8.49-5.16 12.71-12.87 30.42-28.97 59.41-48.3 86.97-18.36 26.17-39.64 51.06-63.82 74.64-36.74 35.81-76.16 65.02-118.25 87.61z"
          fill="url(#linearGradient6851)"
        />
        <path
          class="fil6"
          d="m33711 14637c9.11-22.56 14.27-47.1 15.48-73.61 0.18-4.03 0.27-8.11 0.27-12.23 0-31.28-5.25-60-15.75-86.13-10.57-26.3-26.59-50.65-48.03-73.04-21.26-22.2-44.64-38.74-70.11-49.61-25.18-10.75-53.1-16.15-83.77-16.22h-0.56c-18.7 0-36.38 1.98-53.04 5.94-10.86 2.59-21.29 6.01-31.29 10.28-25.46 10.87-48.84 27.41-70.11 49.61-21.44 22.39-37.46 46.74-48.02 73.04-10.34 25.71-15.59 53.92-15.75 84.61-0.01 0.51-0.01 1.01-0.01 1.52v222.74h218.22 0.56c30.88-0.07 58.87-5.38 83.96-15.94 25.43-10.7 48.82-27.03 70.11-48.96 21.38-22.01 37.33-46.01 47.84-72z"
          fill="url(#linearGradient6853)"
        />
        <path
          class="fil7"
          d="m33527 15830c544.04-9.54 982.15-453.45 982.15-999.76 0 273.72-165.63 508.05-400.42 604.78-73.25 30.17-153.23 46.95-237.01 47.37h-939.53c19.91 37.67 43.4 73.16 70.02 106.01 6.37 7.85 12.91 15.56 19.63 23.11 118.53 133.17 290.81 217.42 482.81 218.72h8.98z"
          fill="url(#linearGradient6855)"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "Pep",
};
</script>
