<template lang="html">
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M125.614,201.836c-33.182,0-60.178,26.995-60.178,60.178c0,33.183,26.996,60.178,60.178,60.178
          s60.178-26.995,60.178-60.178C185.792,228.831,158.797,201.836,125.614,201.836z M125.614,292.104
          c-16.592,0-30.091-13.498-30.091-30.091c0-16.592,13.498-30.091,30.091-30.091c16.592,0,30.091,13.498,30.091,30.091
          C155.705,278.606,142.207,292.104,125.614,292.104z"
        />
      </g>
    </g>
    <g>
      <g>
        <rect x="267.05" y="217.2" width="60.17" height="30.09" />
      </g>
    </g>
    <g>
      <g>
        <rect x="357.31" y="217.2" width="60.17" height="30.09" />
      </g>
    </g>
    <g>
      <g>
        <rect x="267.05" y="277.38" width="60.17" height="30.09" />
      </g>
    </g>
    <g>
      <g>
        <rect x="357.31" y="277.38" width="60.17" height="30.09" />
      </g>
    </g>
    <g>
      <g>
        <rect x="267.05" y="98.23" width="60.17" height="30.09" />
      </g>
    </g>
    <g>
      <g>
        <rect x="357.31" y="98.23" width="60.17" height="30.09" />
      </g>
    </g>
    <g>
      <g>
        <rect x="267.05" y="158.4" width="60.17" height="30.09" />
      </g>
    </g>
    <g>
      <g>
        <rect x="357.31" y="158.4" width="60.17" height="30.09" />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M451.587,30.14V0H230.934v30.293h-61.18v114.374c-13.737-5.184-28.61-8.029-44.139-8.029
          c-69.133,0-125.377,56.244-125.377,125.377c0,28.318,18.695,74.747,57.153,141.938c27.554,48.137,55.502,89.337,55.781,89.748
          L125.613,512l12.441-18.299c0.224-0.331,18.387-27.106,39.838-62.651H511.76V30.344h0.001L451.587,30.14z M125.614,458.013
          c-34.842-53.693-95.289-155.3-95.289-195.999c0-52.543,42.746-95.289,95.289-95.289c52.544,0,95.29,42.746,95.29,95.289
          C220.904,302.713,160.458,404.32,125.614,458.013z M481.675,400.962H357.313v-62.186h-30.087v62.186H195.538
          c37.3-65.572,55.454-111.053,55.454-138.948c0-41.376-20.152-78.129-51.151-100.977V60.381h61.18V30.088h160.48v30.037
          l60.174,0.205V400.962z"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "ExternalPlace",
};
</script>
