<style src="./SpecialtiesAttendances.scss" lang="scss" scoped></style>
<template>
  <section class="report-container row">
    <RgValidatorForm ref="validator" class="rg-validator">
      <div class="row filter-fields">
        <RgComboboxUnithealth
          v-model="form.uns_id"
          :rules="{ required: true }"
        />
      </div>
      <div class="row filter-fields">
        <RgInputDate
          v-model="form.initialDate"
          :rules="{ required: true }"
          class="col-6"
          label="Data inicial"
          placeholder="Informe a data de início"
        />

        <RgInputDate
          v-model="form.finalDate"
          :rules="{ required: true }"
          class="col-6"
          label="Data final"
          placeholder="Informe a data final"
        />
      </div>

      <div class="row filter-fields">
        <label class="radio">
          <input
            v-model="form.closed"
            :value="true"
            type="radio"
            class="rg-radio"
            name="specialties-bam-status"
          />
          <span>Com Saída</span>
        </label>
        <label class="radio">
          <input
            v-model="form.closed"
            :value="false"
            type="radio"
            class="rg-radio"
            name="specialties-bam-status"
          />
          <span>Sem Saída</span>
        </label>
      </div>
    </RgValidatorForm>

    <RgIframe v-if="showPrint" :src="printLink" class="iframe-hidden" />
  </section>
</template>

<script>
import { RgInputDate, RgValidatorForm } from "~tokio/primitive";
import RgIframe from "~tokio/foundation/rg-iframe/RgIframe";
import moment from "moment";
import RgComboboxUnithealth from "$billing/common/component/rg-combobox-unithealth/RgComboboxUnithealth";

export default {
  name: "SpecialtiesAttendances",
  components: {
    RgValidatorForm,
    RgInputDate,
    RgIframe,
    RgComboboxUnithealth,
  },
  filters: {
    formatDateBR(pDate) {
      return moment(pDate, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
  },
  data() {
    return {
      resultTitle: "Entrada e Saída de Boletim por Especialidade",
      form: {
        initialDate: moment().format("DD/MM/YYYY"),
        finalDate: moment().add(2, "days").format("DD/MM/YYYY"),
        uns_id: 0,
        closed: true,
      },
      list: {
        opened: [],
        closed: [],
        total: 0,
        totalClosed: 0,
        totalOpened: 0,
      },
      printCalculate: false,
      showPrint: false,
      hashRefresh: "123",
      linkTo: "/emergency/print/specialties-attendances",
      pagination: {
        limit: 10,
        offset: 0,
        total: 0,
        current: 1,
      },
    };
  },
  computed: {
    printLink() {
      const completeFilter = this.buildFilter();
      completeFilter.form.closed = this.form.closed;
      delete completeFilter.limit;
      delete completeFilter.offset;
      const FILTER = JSON.stringify(completeFilter);
      const AUX = `${this.linkTo}?filter=${FILTER}&${this.hashRefresh}`;
      return encodeURI(AUX);
    },
    columnTable() {
      return this.COLUMN;
    },
    getNameSmartTable() {
      return "SpecialtiesAttendances";
    },
  },
  created() {
    this.COLUMN = [
      { name: "BAM", key: "bol_numero" },
      { name: "Nome do Paciente", key: "pes_nome" },
      { name: "Data de Atendimento", key: "bol_data" },
      { name: "Especialidade", key: "esp_nome" },
    ];
  },
  mounted() {
    this.form.uns_id = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    // this.form.initialDate = moment().format("DD-MM-YYYY");
    // this.form.finalDate = moment().add(2, "days").format("DD-MM-YYYY");
  },
  methods: {
    buildFilter() {
      const data = {
        uns_id: this.form.uns_id,
        initialDate: String(this.form.initialDate),
        finalDate: String(this.form.finalDate),
        closed: this.form.closed,
      };
      return data;
    },
    printReportCss() {
      return (
        ".report-column-1-print { width: 200px; }" +
        ".report-column-2-print { width: 100px; }" +
        ".report-column-3-print { width: 200px; }" +
        ".report-column-4-print { width: 130px; }" +
        ".report-column-5-print { width: 130px; }" +
        ".report-column-6-print { width: 130px; }" +
        "tbody tr:nth-of-type(odd) { background-color: rgba(0,0,0,.05); }"
      );
    },
    clearFilter() {
      this.form = {
        unsId: 0,
        presentation: "",
        medicalRecord: "",
        name: "",
      };
    },
    async doSearch(pData) {
      try {
        return this.$store.dispatch(
          "Emergency/Report/GET_ATTENDANCES_SPECIALTIES",
          pData,
        );
      } catch (error) {
        return false;
      }
    },
    formatDate(pDate) {
      this.$utils.date.BrazilianDateToDatabase(pDate);
    },
  },
};
</script>
