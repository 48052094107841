<template lang="html">
  <svg
    width="378"
    height="307"
    viewBox="0 0 378 307"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M377.1 154.2C372.8 160.3 368.7 166.5 364.3 172.4C340.4 204.7 311.5 231.3 275.9 250.3C241.9 268.4 205.6 275.6 167.2 271.2C149.8 269.2 133.1 264.6 117 257.7C116.8 256.3 118 255.8 118.8 255.1C128.1 245.8 137.4 236.6 146.6 227.2C148.4 225.4 149.7 225 152.1 226.2C176.6 238.1 201.4 238.5 225.6 226C252 212.3 266.8 190.1 269.8 160.4C271.4 145 268.3 130.4 261.3 116.7C260.4 114.9 260.2 113.7 261.9 112C274.6 99.4996 287.2 86.8996 299.7 74.2996C301.4 72.4996 302.5 72.8996 304.1 74.0996C332.7 95.3996 356.8 121 375.9 151.2C376.3 151.8 376.4 152.6 377.3 152.8C377.1 153.2 377.1 153.7 377.1 154.2Z"
      fill="#707070"
    />
    <path
      d="M160.8 213.6C190.1 184.3 219.4 155 248.7 125.8C259.3 145.2 257.9 177.4 236.6 199.5C215.4 221.5 183.1 225.1 160.8 213.6Z"
      fill="#707070"
    />
    <path
      d="M341 8.59963C338.5 6.39963 336.1 4.09963 334 1.59963C332.1 -0.600369 331.1 -0.200369 329.2 1.69963C312.3 18.7996 295.1 35.6996 278.2 52.7996C275.7 55.3996 273.9 55.8996 270.5 54.1996C244.8 41.3996 217.5 34.5996 196.9 34.5996C162.8 34.1996 133 40.9996 108.5 52.9996C64.1999 74.6996 29.9999 107.7 2.79987 148.5C0.299871 152.3 0.399871 154.8 2.89987 158.5C24.7999 191.5 51.7999 219.5 85.2999 241C87.7999 242.6 87.7999 243.3 85.6999 245.3C71.1999 259.6 56.7999 274.1 42.3999 288.5C39.7999 291.1 35.3999 293.7 35.2999 296.5C35.1999 299.6 40.0999 301.8 42.3999 304.7C44.4999 307.4 45.8999 307.5 48.4999 304.9C143.6 209.7 238.7 114.5 333.9 19.3996C336.2 17.0996 338.5 14.5996 341 12.3996C342.4 10.8996 342.6 9.99963 341 8.59963ZM231.1 99.7996C229.8 101.2 229 100.6 227.8 99.7996C214.7 90.2996 200.1 86.1996 183.9 86.9996C157 88.2996 131.3 109 124.4 135.2C119 155.9 122.2 175 134.7 192.5C135.8 194.1 136 195 134.5 196.4C131.5 199.2 128.8 202.2 126 205.1C102.4 179 98.2999 132.2 127.5 99.3996C157.9 65.1996 208.2 63.5996 240.4 90.8996C237.3 93.8996 234.1 96.6996 231.1 99.7996Z"
      fill="#707070"
    />
  </svg>
</template>

<script>
export default {
  name: "NotView",
};
</script>
