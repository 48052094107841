<style src="./AddNewDocument.scss" lang="scss" scoped></style>
<template>
  <RgValidatorForm ref="formValidate" class="add-new-document">
    <form>
      <div class="document-fields">
        <component
          :is="documentType"
          ref="document"
          class="component"
          :pes-id="pesId"
          :document-data="documentData"
          @formatted-data="refreshFormattedData"
        />
      </div>
      <div class="command-bar">
        <button
          type="button"
          class="btn btn-small btn-clean"
          @click="cancelAddDocument"
        >
          Voltar
        </button>

        <button
          type="button"
          title="Apagar Documento"
          class="btn btn-small btn-danger"
          @click="removeDocument"
        >
          Apagar
        </button>

        <button
          :class="{ disable: validateBlockSave }"
          :disabled="validateBlockSave"
          type="button"
          class="btn btn-small btn-success"
          @click="insertDocument"
        >
          Salvar
        </button>
      </div>
    </form>
  </RgValidatorForm>
</template>

<script>
import RgComboboxDocumentType from "$patient/common/components/combobox/rg-combobox-document-type/RgComboboxDocumentType";
import { RgValidatorForm } from "~tokio/primitive";
import * as DocumentComponent from "$patient/common/components/document-types";

export default {
  name: "AddNewDocument",
  components: {
    RgComboboxDocumentType,
    RgValidatorForm,
    ...DocumentComponent,
  },
  props: {
    pesId: null,
    documentInfo: {
      default: null,
    },
  },
  data() {
    return {
      documentType: null,
      componentsById: {
        28: { componentName: "InputRg" },
        31: { componentName: "InputCpf" },
        41: { componentName: "InputCns" },
        42: { componentName: "InputCnh" },
      },
      documentData: null,
      formattedData: null,
    };
  },
  computed: {
    validateBlockSave() {
      const rg = this.documentType === "InputRg";
      const cnh = this.documentType === "InputCnh";
      const cpf = this.documentType === "InputCpf";
      const cns = this.documentType === "InputCns";
      if (cnh) {
        const cnhLength =
          this.formattedData &&
          this.formattedData.info.cnh_numero.length === 11;
        return !cnhLength;
      } else if (cpf) {
        const cpfLength =
          this.formattedData &&
          this.formattedData.info.cpf_numero.length === 11;
        return !cpfLength;
      } else if (cns) {
        const cnsLength =
          this.formattedData &&
          this.formattedData.info.crs_numero.length === 15;
        return !cnsLength;
      } else if (rg) {
        const rgLength =
          this.formattedData &&
          this.formattedData.info.rg.rgs_numero.length === 9;
        return !(
          rgLength &&
          this.formattedData.info.rg.rgs_id_orgaos_emissores &&
          this.formattedData.info.rg.rgs_expedicao
        );
      } else {
        return true;
      }
    },
  },
  mounted() {
    this.documentType = this.componentsById[
      this.documentInfo.doc_id
    ].componentName;
  },
  methods: {
    cancelAddDocument() {
      this.$emit("back-to-list");
    },
    refreshFormattedData(pData) {
      this.formattedData = pData;
    },
    async insertDocument() {
      const isValid = await this.$refs.formValidate.validate();
      if (!isValid) return false;
      const newDocumentStored = await this.$store.dispatch(
        "Patient/ADD_NEW_DOCUMENT",
        this.formattedData,
      );
      if (!newDocumentStored.status) {
        this.$toaster.error(
          newDocumentStored.message,
          "Documento não adicionado",
        );
        this.$toaster.warning("Para alterar, apague e digite novamente.");
      } else {
        this.$toaster.success("Documento inserido com sucesso");
      }
    },
    async removeDocument() {
      try {
        await this.$store.dispatch("Patient/DELETE_DOCUMENT", {
          pes_id: this.pesId,
          doc_id: this.documentInfo.doc_id,
        });
        this.$refs.document.clean();
        this.$toaster.success("Documento Removido com Sucesso!");
      } catch (pErr) {
        this.$toaster.error("Erro ao apagar esse documento");
      }
    },
  },
};
</script>
