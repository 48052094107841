<template lang="html">
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5 6.99382e-07C18.3284 7.35594e-07 19 0.671574 19 1.5C19 2.32843 18.3284 3 17.5 3L1.5 3C0.671573 3 -3.62117e-08 2.32843 0 1.5C3.62117e-08 0.671573 0.671573 -3.62117e-08 1.5 0L17.5 6.99382e-07Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "IconLess",
};
</script>
