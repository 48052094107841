<template lang="html">
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M55.3293 8.67028C54.4356 7.77657 52.9911 7.77657 52.0974 8.67028L35.4279 25.3398L30.1868 20.0987C29.5308 19.445 28.5503 19.2438 27.6954 19.6027C26.8429 19.9547 26.2852 20.7913 26.2852 21.7147V35.4288C26.2852 36.6928 27.3091 37.7145 28.5708 37.7145H42.2849C43.2084 37.7145 44.0426 37.159 44.3969 36.3042C44.7512 35.4493 44.5546 34.4665 43.9009 33.8128L38.6598 28.5717L55.3293 11.9022C56.223 11.0085 56.223 9.56398 55.3293 8.67028Z"
      fill="white"
    />
    <path
      d="M46.8566 55.9998H14.8571C11.0765 55.9998 8 52.9233 8 49.1428V17.1432C8 13.3627 11.0765 10.2861 14.8571 10.2861H33.1425C34.4042 10.2861 35.4282 11.3078 35.4282 12.5718C35.4282 13.8358 34.4042 14.8575 33.1425 14.8575H14.8571C13.5976 14.8575 12.5714 15.8815 12.5714 17.1432V49.1428C12.5714 50.4045 13.5976 51.4284 14.8571 51.4284H46.8566C48.116 51.4284 49.1423 50.4045 49.1423 49.1428V30.8573C49.1423 29.5933 50.1663 28.5716 51.428 28.5716C52.6897 28.5716 53.7137 29.5933 53.7137 30.8573V49.1428C53.7137 52.9233 50.6371 55.9998 46.8566 55.9998Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: "Regulation",
};
</script>
