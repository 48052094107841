<template lang="html">
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.88247 0C5.33018 0 4.88247 0.447716 4.88247 1V23.5294H0.470703V25H5.88247H8.58625H16.9196H19.6232H24.0001V23.5294H20.6232V1C20.6232 0.447715 20.1755 0 19.6232 0H5.88247ZM16.9196 23.5294V19.5185C16.9196 18.9662 16.4719 18.5185 15.9196 18.5185H9.58625C9.03397 18.5185 8.58625 18.9662 8.58625 19.5185V23.5294H16.9196ZM11.3641 2.77778H14.1418V5.55556L16.9196 5.55556V8.33334H14.1418V11.1111H11.3641V8.33334H8.58625L8.58625 5.55556H11.3641V2.77778Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "UnitHealth",
};
</script>
