import EditSurgery from "./EditSurgery";
import FillFilter from "./FillFilter";
import Filter from "./Filter";
import LoadStages from "./LoadStages";
import RefreshCalendar from "./RefreshCalendar";
import ReloadCalendar from "./ReloadCalendar";
import SetStageWidth from "./SetStageWidth";
import CheckSurgeryConflict from "./CheckSurgeryConflict";
import GetSurgerySuspendedReasons from "./GetSurgerySuspendedReasons";
import GetSurgeryReasons from "./GetSurgeryReasons";
import SuspendSurgery from "./SuspendSurgery";
import LoadMoreResults from "./LoadMoreResults";

import RequestSurgery from "./request/RequestSurgery";
import UpdateRequestSurgery from "./request/UpdateRequestSurgery";

import RescheduleSurgery from "./schedule/RescheduleSurgery";
import ScheduleSurgery from "./schedule/ScheduleSurgery";
import UpdateScheduleSurgery from "./schedule/UpdateScheduleSurgery";
import SearchPatientSurgeries from "./SearchPatientSurgeries";

export default {
  editSurgery: EditSurgery,
  fillFilter: FillFilter,
  filter: Filter,
  loadStages: LoadStages,
  refreshCalendar: RefreshCalendar,
  reloadCalendar: ReloadCalendar,
  setStageWidth: SetStageWidth,
  CHECK_SURGERY_CONFLICT: CheckSurgeryConflict,
  GET_SURGERY_SUSPENDED_REASONS: GetSurgerySuspendedReasons,
  SUSPEND_SURGERY: SuspendSurgery,
  LOAD_MORE_RESULTS: LoadMoreResults,
  REQUEST_SURGERY: RequestSurgery,
  UPDATE_REQUEST_SURGERY: UpdateRequestSurgery,
  SCHEDULE_SURGERY: ScheduleSurgery,
  UPDATE_SCHEDULE_SURGERY: UpdateScheduleSurgery,
  RE_SCHEDULE_SURGERY: RescheduleSurgery,
  SEARCH_PATIENT_SURGERIES: SearchPatientSurgeries,
  GET_SURGERY_REASONS: GetSurgeryReasons,
};
