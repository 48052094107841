<script>
import moment from "moment";
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchScheduleBasedVacancies from "./action/SearchScheduleBasedVacancies";
export default {
  name: "RgComboboxHour",
  mixins: [RgComboboxMixin],
  props: {
    hours: {
      type: Object,
    },
    interval: {
      type: Number,
    },
    hasVacancies: {
      type: Boolean,
      default: false,
    },
    scaleSchedule: {
      type: Number,
      required: true,
    },
    historySchedule: {
      type: Number,
      required: true,
    },
    dateExam: {
      type: String,
      required: true,
    },
    occupiedPlaces: {
      type: Number,
      required: true,
    },
    eapId: {
      type: Number,
    },
    hoursSelected: Array,
  },
  data: () => {
    return {};
  },
  watch: {
    scaleSchedule() {
      this.fillData();
    },
    historySchedule() {
      this.fillData();
    },
    occupiedPlaces() {
      this.fillData();
    },
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      if (this.hasVacancies) {
        if (
          (!this.historySchedule && this.historySchedule !== 0) ||
          !this.scaleSchedule ||
          !this.occupiedPlaces
        ) {
          return;
        }

        const data = await SearchScheduleBasedVacancies({
          intIdEscalaDeAgendamento: this.scaleSchedule,
          intIdHistoricoAgendamento: this.historySchedule,
          strDataExame: this.dateExam,
          intVagasOcupadas: this.occupiedPlaces,
          intEapId: this.eapId,
        });

        this.mData = [];

        data.forEach((item) => {
          this.mData.push({ value: item.data, text: item.label });
        });
      } else {
        const begin = moment(this.hours.begin, "HH:mm");
        const end = moment(this.hours.end, "HH:mm");
        let add = begin;

        this.mData = [];

        while (end.diff(add, "minutes") > 0) {
          const strAdd = add.format("HH:mm");
          if (!this.hoursSelected.includes(strAdd)) {
            this.mData.push({ value: strAdd, text: strAdd });
          }
          add = moment(add).add(this.interval, "minutes");
        }
      }

      if (this.mData.length === 1) {
        this.$emit("selectOnlyItem", this.mData[0].value);
      }
    },
  },
};
</script>
