<template lang="html">
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.4299 7.96658C16.0446 7.58125 15.4197 7.58119 15.0343 7.96658L11.4871 11.5139L11.487 0.986842C11.487 0.441842 11.0452 0 10.5002 0C9.95517 0 9.51332 0.441842 9.51332 0.986842L9.51339 11.5141L5.96589 7.96671C5.58056 7.58132 4.95569 7.58132 4.5703 7.96671C4.1849 8.35211 4.1849 8.97691 4.5703 9.3623L9.8024 14.5943C9.98747 14.7794 10.2385 14.8834 10.5002 14.8834C10.7619 14.8834 11.0129 14.7793 11.198 14.5943L16.43 9.36217C16.8153 8.97678 16.8153 8.35198 16.4299 7.96658Z"
      fill="white"
    />
    <path
      d="M20.0132 19.0264H0.986842C0.441842 19.0264 0 19.4682 0 20.0132C0 20.5582 0.441842 21.0001 0.986842 21.0001H20.0132C20.5582 21.0001 21 20.5582 21 20.0132C21 19.4681 20.5582 19.0264 20.0132 19.0264Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: "DownloadArrow",
};
</script>
