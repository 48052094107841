import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation CountPrint($patientTag: Int, $examsTag: [Int]) {
    CountPrint(patientTag: $patientTag, examsTag: $examsTag)
  }
`;

export default async ({ commit }, { patientTag, examsTag }) => {
  const variables = {
    patientTag,
    examsTag,
  };

  try {
    const { data } = await GraphQLClient.mutate({
      mutation,
      variables,
      fetchPolicy: "no-cache",
    });

    return data.CountPrint;
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors);
  }
};
