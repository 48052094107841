import LocalStorage from "~common/local-storage/LocalStorage";

const ScheduleVoucherHTML = (pValue) => {
  const logo = LocalStorage.getObject("client-logo");
  const html = `
  <!DOCTYPE html>
  <html>
  
  <head>
      <meta charset="utf-8">
      <title>Nova Impressão (Normal) de Agendamento</title>
      <style type="text/css">
          body {
              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
          }
  
          page {
              background: white;
              display: block;
              margin: 0 auto;
              margin-bottom: 0.5cm;
          }
  
          page[size="A4"] {
              width: 21cm;
              height: 29.7cm;
          }
  
          h1 {
              text-align: center;
              text-transform: uppercase;
              font-size: 14pt;
          }
  
          h2 {
              margin: 33px 0 0 0;
              font-size: 12pt;
          }
  
          h3 {
              text-transform: uppercase;
              font-size: 11pt;
          }
  
          hr {
              width: 70%;
              display: block;
              height: 1px;
              border: 0;
              border-top: 1px solid #666;
          }
  
          p,
          label,
          span {
              font-size: 11pt;
          }
  
          .meia-folha {
              width: 47%;
              float: left;
              border: 0;
          }
  
          .meia-folha:first-child {
              padding: 0 45px 0 0;
          }
  
          .logo-cliente {
              width: 65px;
              margin: 0 20px 0 0;
              float: left;
          }
  
          .info-unidade {
              margin: 40px 0 22px 0;
          }
  
          .info-agendamento {
              margin: 20px 0 0 0;
          }
  
          .info-agendamento div {
              margin: 0 0 6px 0;
          }
  
          .info-agendamento label {
              width: 135px;
              display: inline-block;
              font-weight: 700;
          }
  
          .referencia {
              text-transform: uppercase;
              text-align: center;
          }
  
          .exibir-info {
              display: ${pValue.exibir_info};
          }
  
          @media print {
  
              body,
              page {
                  margin: 0;
                  box-shadow: 0;
              }
  
          }
      </style>
  </head>
  
  <body>
  
      <page size="A4">
          <div class="meia-folha">
              <div>
                  <h1>Via do paciente</h1>
                  <img class="logo-cliente" src="${logo}">
                  <h2>${pValue.cli_nome}</h2>
              </div>
  
              <div class="info-unidade">
                  <h3>${pValue.uns_nome}</h3>
                  <p>${pValue.tlg_nome} ${pValue.end_logradouro} ${pValue.end_numero} ${pValue.end_complemento} ${pValue.bai_nome} - ${pValue.mun_nome}</p>
              </div>
  
              <hr>
  
              <div class="info-agendamento">
                  <p class="referencia">${pValue.end_referencia}</p>
  
                  <div><label>Data:</label> <span>${pValue.eha_data_exame} às ${pValue.eap_hora}</span></div>
                  <div><label>Paciente:</label> <span>${pValue.pes_nome}</span></div>
                  <div><label>Nome Social:</label> <span>${pValue.pes_nome_social}</span></div>
                  <div><label>Prontuário:</label> <span>${pValue.ppr_numero}</span></div>
                  <div><label>Prontuário Único:</label> <span>${pValue.pac_prontuario_unico}</span></div>
                  <div><label>Cartão SUS:</label> <span>${pValue.crs_numero}</span></div>
                  <div><label>Setor:</label> <span>${pValue.set_nome}</span></div>
                  <div><label>Local:</label> <span>${pValue.lca_nome}</span></div>
                  <div class="exibir-info"><label>Procedimento:</label> <span>${pValue.stp_novo_nome_procedimento}</span></div>
                  <div class="exibir-info"><label>Modo de Preparo:</label> <span>${pValue.stp_recomendacao}</span></div>
                  <div class="exibir-info"><label>Sub Procedimento:</label> <span>${pValue.subprocedimentos}</span></div>
                  <div><label>Profissional:</label> <span>${pValue.atendente}</span></div>
                  <div><label>Ocupação:</label> <span>${pValue.ocp_nome}</span></div>
                  <div style="display:${pValue.exibir_senha_efetivacao}"><label>Senha Efetivação:</label>
                      <span>${pValue.eap_senha_efetivacao}</span></div>
  
                  <div><label style="width: 200px;">${pValue.agendadoPelaFila}</label></div>
                  <div><label>${pValue.lblNomeRegulador}</label> <span>${pValue.nomeRegulador}</span></div>
  
                  <div><label>Observações:</label> <span>${pValue.observacoes}</span></div>
              </div>
          </div>
  
  
          <div class="meia-folha">
              <div>
                  <h1>Via do ambulatório</h1>
                  <img class="logo-cliente" src=${logo}>
                  <h2>${pValue.cli_nome}</h2>
              </div>
  
              <div class="info-unidade">
                  <h3>${pValue.uns_nome}</h3>
                  <p>${pValue.tlg_nome} ${pValue.end_logradouro} ${pValue.end_numero} ${pValue.bai_nome} - ${pValue.mun_nome}</p>
              </div>
  
              <hr>
  
              <div class="info-agendamento">
                  <p class="referencia">${pValue.end_referencia}</p>
  
                  <div><label>Data:</label> <span>${pValue.eha_data_exame} às ${pValue.eap_hora}</span></div>
                  <div><label>Paciente:</label> <span>${pValue.pes_nome}</span></div>
                  <div><label>Nome Social:</label> <span>${pValue.pes_nome_social}</span></div>
                  <div><label>Prontuário:</label> <span>${pValue.ppr_numero}</span></div>
                  <div><label>Prontuário Único:</label> <span>${pValue.pac_prontuario_unico}</span></div>
                  <div><label>Cartão SUS:</label> <span>${pValue.crs_numero}</span></div>
                  <div><label>Setor:</label> <span>${pValue.set_nome}</span></div>
                  <div><label>Local:</label> <span>${pValue.lca_nome}</span></div>
                  <div class="exibir-info"><label>Procedimento:</label> <span>${pValue.stp_novo_nome_procedimento}</span></div>
                  <div class="exibir-info"><label>Modo de Preparo:</label> <span>${pValue.stp_recomendacao}</span></div>
                  <div class="exibir-info"><label>Sub Procedimento:</label> <span>${pValue.subprocedimentos}</span></div>
                  <div><label>Profissional:</label> <span>${pValue.atendente}</span></div>
                  <div><label>Ocupação:</label> <span>${pValue.ocp_nome}</span></div>
                  <div style="display:${pValue.exibir_senha_efetivacao}"><label>Senha Efetivação:</label>
                      <span>${pValue.eap_senha_efetivacao}</span></div>
  
                  <div><label style="width: 200px;">${pValue.agendadoPelaFila}</label></div>
                  <div><label>${pValue.lblNomeRegulador}</label> <span>${pValue.nomeRegulador}</span></div>
  
                  <div><label>Observações:</label> <span>${pValue.observacoes}</span></div>
              </div>
          </div>
      </page>
  
  </body>
  
  </html>
    `;

  return html;
};

export default ScheduleVoucherHTML;
