<style src="./RgEmployeeCard.scss" lang="scss" scoped></style>
<template lang="html">
  <div class="rg-employee-card" @click="contentClick">
    <div
      :class="{
        'rg-employee-card--status-bar__danger':
          employeeData.person && employeeData.person.pes_ativo === false,
      }"
      class="rg-employee-card--status-bar"
    />
    <div class="avatar-container">
      <img
        v-if="!employeeData.person.pes_foto"
        class="avatar"
        src="/static/person-image/photo-default.png"
        alt=""
      />
      <img
        v-if="employeeData.person.pes_foto"
        :src="avatar"
        class="avatar"
        alt=""
      />
    </div>

    <div class="rg-employee-card--container-data">
      <div class="rg-employee-card--content">
        <div class="rg-employee-card--title-container">
          <span class="rg-employee-card--title">{{
            employeeData.person.pes_nome
          }}</span>
        </div>
      </div>
      <div class="rg-employee-card--detail">
        <IconPlace class="rg-employee-card--icon rg-employee-card--place" />
        <span class="rg-employee-card--label"
          >{{ employeeData.person.address.end_logradouro }},
          {{ employeeData.person.address.neighborhood.bai_nome }} -
          {{
            employeeData.person.address.neighborhood.city.state.est_sigla
          }}</span
        >
      </div>

      <div class="codes-container">
        <div class="rg-employee-card--detail">
          <IconCard class="rg-employee-card--icon" />
          <span class="rg-employee-card--title">CNS</span>
          <span class="rg-employee-card--label">{{
            employeeData.person.cns.crs_numero | NotInformed
          }}</span>
        </div>

        <div class="rg-employee-card--detail">
          <IconCard class="rg-employee-card--icon" />
          <span class="rg-employee-card--title">CPF</span>
          <span class="rg-employee-card--label">{{
            employeeData.person.cpf.cpf_numero | NotInformed
          }}</span>
        </div>

        <div class="rg-employee-card--detail">
          <IconCalendar class="rg-employee-card--icon" />
          <span class="rg-employee-card--label">{{
            employeeData.person.pes_nascimento | DateBR
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { IconCalendar, IconPlace, IconCard } from "~tokio/primitive/icon";
import moment from "moment";

export default {
  name: "RgEmployeeCard",
  components: {
    IconCalendar,
    IconPlace,
    IconCard,
  },
  filters: {
    DateBR: (pDate) => {
      return moment(pDate).format("DD/MM/YYYY");
    },
    NotInformed: (pValue) => {
      return pValue && pValue.trim().length === 0 ? "Não Informado" : pValue;
    },
  },
  props: {
    employeeData: {
      default() {
        return {};
      },
    },
    listIndex: {
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      panelState: true,
      avatar: null,
    };
  },
  methods: {
    contentClick() {
      this.$emit("content-click", this.employeeData);
    },
  },
};
</script>
