<template lang="html">
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 511.998 511.998"
    style="enable-background: new 0 0 511.998 511.998"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M396.8,236.586h-93.175l-22.673-68.053c-1.775-5.325-6.929-9.523-12.424-8.747c-5.623,0.128-10.496,3.874-12.023,9.276
      l-37.35,130.697l-40.252-181.146c-1.229-5.478-5.871-9.523-11.477-9.975c-5.572-0.375-10.829,2.773-12.902,7.996l-48,119.953H12.8
      c-7.074,0-12.8,5.726-12.8,12.8c0,7.074,5.726,12.8,12.8,12.8h102.4c5.222,0.008,9.95-3.174,11.878-8.047l35.823-89.523
      l42.197,189.952c1.271,5.726,6.272,9.847,12.126,10.027c0.128,0,0.247,0,0.375,0c5.7,0,10.726-3.772,12.297-9.276l39.851-139.426
      l12.501,37.547c1.749,5.222,6.647,8.747,12.151,8.747h102.4c7.074,0,12.8-5.726,12.8-12.8
      C409.6,242.311,403.874,236.586,396.8,236.586z"
        ></path>
      </g>
    </g>
    <g>
      <g>
        <path
          d="M467.012,64.374C437.018,34.38,397.705,19.387,358.4,19.387c-36.779,0-73.259,13.662-102.4,39.919
      c-29.15-26.257-65.621-39.919-102.4-39.919c-39.313,0-78.618,14.993-108.612,44.988C5.214,104.157-7.595,160.187,5.385,211.011
      h26.624c-13.653-43.972-3.678-93.773,31.078-128.529c24.175-24.175,56.32-37.487,90.513-37.487
      c31.206,0,60.399,11.563,83.695,31.889L256,94.369l18.714-17.493c23.296-20.318,52.489-31.889,83.686-31.889
      c34.193,0,66.33,13.312,90.513,37.487c49.911,49.903,49.903,131.115,0,181.018L256,456.404L87.407,287.811H51.2l204.8,204.8
      l211.012-211.012C526.993,221.61,526.993,124.364,467.012,64.374z"
        ></path>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "MedicalAppointment",
};
</script>
