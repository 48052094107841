<template>
  <section class="person-document">
    <FormBase title="Documentos" class="form-base-document">
      <RgValidatorForm ref="validator" class="grid-document">
        <div class="row-1">
          <div class="cns">
            <RgInputCns
              v-model="form.cns.crs_numero"
              :requiredValue="cnsIsRequired"
              :class="{ disable: cantEdit }"
              :disabled="cantEdit"
            />
          </div>

          <div class="pis">
            <RgInputPis
              v-model="form.pisPasep.pis_numero"
              :class="{ disable: cantEdit }"
              :disabled="cantEdit"
            />
          </div>

          <div class="cpf">
            <RgInputCpf
              ref="cpf"
              v-model="form.cpf.cpf_numero"
              :rules="{ required: cpfIsRequired }"
              :class="{ disable: cantEdit }"
              :disabled="cantEdit"
              @onPaste="onPaste"
            />

            <RgToggleButton
              :value="receiveNotification"
              :valueSync="true"
              :class="{ disable: disabledToggleApp }"
              :disabled="disabledToggleApp"
              :description="textToggleApp"
              @change="handleAppNotification"
            />
          </div>

          <div class="dnv">
            <RgInput
              v-show="hasLinkNewborn"
              v-model="form.dnv"
              :class="{ disable: cantEdit && !hasLinkNewborn }"
              :disabled="cantEdit && !hasLinkNewborn"
              label="DNV"
              title="Declaração de Nascido Vivo"
            />

            <RgAttachmentButton
              v-show="hasLinkNewborn"
              :class="{
                disable: (disabledAttachmentDnv || cantEdit) && !hasLinkNewborn,
              }"
              :disabled="(disabledAttachmentDnv || cantEdit) && !hasLinkNewborn"
              class="btn-attachment"
            />
          </div>
        </div>

        <div class="row-2">
          <h4 class="">- Identidade</h4>
        </div>

        <div class="row-3">
          <RgInput
            ref="rgNumber"
            v-model="form.rg.rgs_numero"
            :class="{ disable: cantEdit }"
            :rules="{ required: required }"
            :change="disableOrEnabledRequired"
            :disabled="cantEdit"
            :maxlength="20"
            label="Número"
          />

          <RgComboboxIssuingBody
            ref="rgIssuingBody"
            v-model="form.rg.rgs_id_orgaos_emissores"
            :class="{ disable: cantEdit }"
            :disabled="cantEdit"
            :rules="{ required: required }"
            label="Órgão Emissor"
            @change="disableOrEnabledRequired"
            @blur="disableOrEnabledRequired"
          />

          <div class="identity">
            <RgInputDate
              ref="rgEmissionDate"
              v-model="form.rg.rgs_expedicao"
              :class="{ disable: cantEdit }"
              :rules="{ required: required, fn: verifyDateEntry }"
              :disabled="cantEdit"
              :max-date="new Date()"
              label="Data da Emissão"
              placeholder="dd/mm/aaaa"
            />
          </div>

          <div class="other-documents">
            <LargeButton
              :label="'Outros documentos'"
              :backgroundColor="'#3771c8'"
              @click="showModalOtherDocuments"
            >
              <IconAddExam slot="icon" class="icon" />
            </LargeButton>
          </div>
          <div class="attachments">
            <LargeButton
              :label="attachmentButtonText"
              :backgroundColor="'#5e5e5e'"
              @click="showModalAttachments"
            >
              <IconAddExam slot="icon" class="icon" />
            </LargeButton>
          </div>
        </div>
      </RgValidatorForm>

      <ModalOtherDocuments
        ref="modalOtherDocuments"
        :cant-edit-modal="cantEdit"
        :is-update="isUpdate"
        :show="modalOtherDocuments"
        :birthDate="birthDate"
        @close="closeModalOtherDocuments"
      />
      <ModalAttachments
        :show="modalAttachments"
        @confirmed="saveAttachmentsIntoMemory"
        @close="closeModalAttachments"
        @totalChanged="updateTotalFiles"
      />
    </FormBase>
  </section>
</template>

<script>
import moment from "moment";
import CheckIfFieldsAreFilled from "~utils/CheckIfFieldsAreFilled";
import { mapGetters } from "vuex";
import { AlertError } from "~tokio/primitive/notification";
import {
  RgInput,
  RgInputDate,
  RgValidatorForm,
  RgAttachmentButton,
  RgToggleButton,
  LargeButton,
  IconAddExam,
} from "~tokio/primitive";
import FormBase from "~tokio/foundation/form-base/FormBase";

import RgInputCns from "$person/common/components/input/rg-input-cns/RgInputCns";
import RgInputCpf from "$person/common/components/input/rg-input-cpf/RgInputCpf";
import RgInputPis from "$person/common/components/input/rg-input-pis/RgInputPis";
import RgComboboxIssuingBody from "$person/common/components/combobox/rg-combobox-issuing-body/RgComboboxIssuingBody";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";

import ModalOtherDocuments from "$person/common/components/modal/modal-other-documents/ModalOtherDocuments";
import ModalAttachments from "$person/common/components/modal/modal-attachments/ModalAttachments";

export default {
  name: "PersonDocument",
  components: {
    FormBase,
    RgValidatorForm,
    IconAddExam,
    RgInput,
    RgInputDate,
    RgInputCns,
    RgAttachmentButton,
    RgInputCpf,
    RgInputPis,
    RgComboboxIssuingBody,
    ModalOtherDocuments,
    RgToggleButton,
    ModalAttachments,
    LargeButton,
  },

  props: {
    cantEdit: {
      type: Boolean,
      default: false,
    },
    hasLinkNewborn: {
      type: Boolean,
      default: false,
    },
    isUpdate: {
      type: Boolean,
      default: false,
    },
    birthDate: {
      type: Date,
    },
  },

  data() {
    return {
      modalOtherDocuments: false,
      modalAttachments: false,
      disabledAttachmentCns: true,
      disabledAttachmentCpf: true,
      disabledAttachmentPis: true,
      disabledAttachmentDnv: true,
      disabledToggleApp: true,
      new_attachments: [],
      attach_total_quantity: 0,
      form: {
        cns: {
          crs_id: 0,
          crs_numero: "",
        },
        cpf: {
          cpf_id: 0,
          cpf_numero: "",
        },
        pisPasep: {
          pis_id: 0,
          pis_numero: "",
        },
        rg: {
          rgs_id: 0,
          rgs_id_orgaos_emissores: 0,
          rgs_numero: "",
          rgs_expedicao: "",
        },
      },
      notification: {
        nop_id: null,
        cpf_numero: null,
      },
      receiveNotification: false,
      required: false,
    };
  },

  computed: {
    ...mapGetters({
      existsPesId: "Person/Patient/EXISTS_PES_ID",
      documentData: "Person/Patient/GET_DOCUMENT",
      contactData: "Person/Patient/GET_CONTACT",
      isNewBorn: "Person/Patient/IS_NEWBORN",
      isPrivateSector: "Login/GET_PRIVATE_SECTOR",
      preferences: "Login/GET_DOCUMENT_PREFERENCES",
    }),

    attachmentButtonText() {
      let text = "Anexos";

      if (this.attach_total_quantity > 0) {
        text = text.concat(` (${this.attach_total_quantity})`);
      }
      return text;
    },

    cpfIsRequired() {
      if (this.isPrivateSector) {
        return true;
      }

      const hasPreferences = this.validatePreferences("requiredCPF");

      const isRequired = hasPreferences
        ? !!this.preferences.requiredCPF
        : false;

      return isRequired;
    },

    cnsIsRequired() {
      const isFromBioslabDetails = ValidateIfRouteExistInBreadscrumb(
        "/exam/laboratory/details",
        this.$route.meta.breadcrumb,
      );

      if (isFromBioslabDetails) {
        return true;
      }

      const hasPreferences = this.validatePreferences("requiredCNS");

      return hasPreferences ? !!this.preferences.requiredCNS : false;
    },

    textToggleApp() {
      return this.isPrivateSector
        ? "Receber notificações pelo Pleni ?"
        : "Receber notificações pelo Meu Esus ?";
    },

    disabledAttachmentIdentity() {
      const number =
        this.form.rg.rgs_numero && this.form.rg.rgs_numero.length > 0;
      const issuingBody = this.form.rg.rgs_id_orgaos_emissores;
      const issueDate =
        this.form.rg.rgs_expedicao && this.form.rg.rgs_expedicao.length >= 8;

      return !(number && issuingBody && issueDate);
    },
  },

  watch: {
    "form.cns.crs_numero"(pValue) {
      if (pValue) {
        const isValidCns = pValue.length >= 15;

        isValidCns
          ? (this.disabledAttachmentCns = false)
          : (this.disabledAttachmentCns = true);
      }
    },

    async "form.cpf.cpf_numero"(pValue) {
      if (pValue) {
        const cleanValue = this.removeDashesAndDots(pValue);
        const isCorrectLength = cleanValue.length >= 11;

        if (isCorrectLength) {
          const isCpfValid = await this.$refs.cpf.validate();

          this.disabledAttachmentCpf = !isCpfValid;
          this.disabledToggleApp = !isCpfValid;
        } else {
          this.disabledAttachmentCpf = true;
          this.disabledToggleApp = true;
          this.receiveNotification = false;
          this.handleAppNotification(false);
        }
      } else {
        this.receiveNotification = false;
        this.handleAppNotification(false);
      }
    },

    "form.pisPasep.pis_numero"(pValue) {
      if (pValue) {
        const isValidPis = pValue.length >= 14;

        isValidPis
          ? (this.disabledAttachmentPis = false)
          : (this.disabledAttachmentPis = true);
      }
    },

    "form.dnv"(pValue) {
      if (pValue) {
        const isValidBornAlive = pValue.length >= 14;

        isValidBornAlive
          ? (this.disabledAttachmentDnv = false)
          : (this.disabledAttachmentDnv = true);
      }
    },

    "form.rg.rgs_expedicao"() {
      this.disableOrEnabledRequired();
    },

    documentData(pValue) {
      const isToLoadData = !this.isNewBorn && pValue;
      if (isToLoadData) {
        this.mountData(pValue);
      }
    },

    contactData(pValue) {
      if (pValue) {
        this.setNotification(pValue);
      }
    },
  },

  methods: {
    onPaste() {
      this.cleanNotification();
    },

    updateTotalFiles(total) {
      this.attach_total_quantity = total;
    },

    validatePreferences(Property) {
      return (
        this.preferences &&
        Object.keys(this.preferences).length > 0 &&
        this.preferences[Property] !== undefined
      );
    },

    saveAttachmentsIntoMemory(attachments) {
      this.new_attachments = attachments;
    },

    handleAppNotification(pValue) {
      let cpfNumber = this.notification.cpf_numero;

      if (pValue) {
        const cpf = this.form.cpf;
        const hasNumber = cpf.cpf_numero && cpf.cpf_numero.length > 0;
        const isToNotificate = pValue && hasNumber;
        cpfNumber = isToNotificate ? cpf.cpf_numero : null;

        this.notification.cpf_numero = cpfNumber;
      } else {
        cpfNumber = null;
        this.cleanNotification();
      }

      this.receiveNotification = pValue;
    },

    async validateAllDocuments() {
      const rg = Object.assign({}, this.form.rg);
      delete rg.rgs_id;

      if (!CheckIfFieldsAreFilled(rg)) {
        return AlertError("Informe todos os campos da Identidade.");
      }

      const isValidOtherDocumentsBirthDate = await this.$refs.modalOtherDocuments.validateDates();
      if (!isValidOtherDocumentsBirthDate) return false;

      return true;
    },

    mountData(pData) {
      if (pData) {
        const form = { ...this.form };

        const updatedForm = {
          cns: {
            crs_id: pData.cns.crs_id || 0,
            crs_numero: pData.cns.crs_numero || "",
          },
          cpf: {
            cpf_id: pData.cpf.cpf_id || 0,
            cpf_numero: pData.cpf.cpf_numero || "",
          },
          pisPasep: {
            pis_id: pData.pisPasep.pis_id || 0,
            pis_numero: pData.pisPasep.pis_numero || "",
          },
          rg: {
            rgs_id: pData.rg.rgs_id || 0,
            rgs_numero: pData.rg.rgs_numero || "",
            rgs_expedicao: pData.rg.rgs_expedicao || "",
            rgs_id_orgaos_emissores: pData.rg.rgs_id_orgaos_emissores || 0,
          },
        };

        if (updatedForm.cpf && updatedForm.cpf.cpf_numero) {
          this.notification = {
            cpf_numero: updatedForm.cpf.cpf_numero,
          };
        }

        this.form = Object.assign(form, updatedForm);
      }
    },

    setNotification(pData) {
      if (pData && pData.notification) {
        pData.notification.rows.forEach((item) => {
          const hasValidValue = item.nop_cpf !== null;
          const sameValue = item.nop_cpf === this.notification.cpf_numero;

          if (hasValidValue && sameValue) {
            this.notification.nop_id = item.nop_id;
            this.receiveNotification = true;
          }
        });
      }
      this.handleAppNotification(this.receiveNotification);
    },

    getFilledDocuments(pDocuments) {
      const documents = {};

      Object.keys(pDocuments).forEach((key) => {
        const document = pDocuments[key];
        let documentValid = false;

        if (this.isUpdate) {
          documentValid = Object.values(document).some((field) => field);
        } else {
          documentValid = Object.values(document).every((field) => {
            const fieldFilled = field !== null && field !== "";
            return fieldFilled;
          });
        }

        if (documentValid) {
          documents[key] = pDocuments[key];
        }
      });

      return documents;
    },

    /**
     * @returns {Array} um array de objetos com {filename, documentType}
     */
    getNewAttachments() {
      return this.new_attachments.map((item) => {
        return {
          filename: item.filename,
          document_type: item.document_attachment.doa_id,
        };
      });
    },

    async getDocumentForm() {
      const validation = await this.$refs.validator.validate();

      if (!validation) {
        return AlertError("Verifique os Documentos.");
      }

      const documentsForm = this.$utils.obj.DeepCopy(this.form);
      const otherDocuments = this.$utils.obj.DeepCopy(
        this.$refs.modalOtherDocuments.getObjectOtherDocuments(),
      );

      const allDocuments = { ...documentsForm, ...otherDocuments };

      allDocuments.cnh.cnh_numero = this.removeDashesAndDots(
        allDocuments.cnh.cnh_numero,
      );
      allDocuments.cns.crs_numero = this.removeDashesAndDots(
        allDocuments.cns.crs_numero,
      );
      allDocuments.pisPasep.pis_numero = this.removeDashesAndDots(
        allDocuments.pisPasep.pis_numero,
      );
      allDocuments.cpf.cpf_numero = this.removeDashesAndDots(
        allDocuments.cpf.cpf_numero,
      );
      allDocuments.rg.rgs_numero = this.removeDashesAndDots(
        allDocuments.rg.rgs_numero,
      );
      allDocuments.rg.rgs_id_orgaos_emissores = Number(
        allDocuments.rg.rgs_id_orgaos_emissores,
      );
      allDocuments.rg.rgs_expedicao = this.$utils.date.BrazilianDateToDatabase(
        allDocuments.rg.rgs_expedicao,
      );
      allDocuments.birthCertificate.ctc_emissao = this.$utils.date.BrazilianDateToDatabase(
        allDocuments.birthCertificate.ctc_emissao,
      );
      allDocuments.weddingCertificate.ctc_emissao = this.$utils.date.BrazilianDateToDatabase(
        allDocuments.weddingCertificate.ctc_emissao,
      );
      allDocuments.ctps.ctt_emissao = this.$utils.date.BrazilianDateToDatabase(
        allDocuments.ctps.ctt_emissao,
      );
      const filledDocuments = this.getFilledDocuments(allDocuments);
      return filledDocuments;
    },

    getNotification() {
      const isNotEmpty =
        this.notification.cpf_numero && this.notification.cpf_numero.length > 0;

      if (isNotEmpty) {
        return this.notification;
      }

      return {};
    },

    loadFromCadsus(pData) {
      if (!pData) return;
      this.cleanForm();
      if (pData.Cartoes && pData.Cartoes.CNS) {
        if (!Array.isArray(pData.Cartoes.CNS)) {
          this.form.cns.crs_numero = pData.Cartoes.CNS.numeroCNS;
        } else {
          for (const cns of pData.Cartoes.CNS) {
            if (cns.tipoCartao === "D") {
              this.form.cns.crs_numero = cns.numeroCNS;
              break;
            }
          }
        }
      }

      if (pData.CPF) {
        this.form.cpf.cpf_numero = pData.CPF.numeroCPF;
      }

      if (pData.Documentos) {
        if (pData.Documentos.Identidade) {
          this.form.rg.rgs_numero =
            pData.Documentos.Identidade.numeroIdentidade;
          this.form.rg.rgs_id_orgaos_emissores =
            pData.Documentos.Identidade.OrgaoEmissor.orgId || 0;
          this.form.rg.rgs_expedicao =
            pData.Documentos.Identidade.dataExpedicao;
        }
        if (pData.Documentos.NIS) {
          this.form.pisPasep.pis_numero = pData.Documentos.NIS.numeroDocumento;
        }
      }
    },

    verifyDateEntry(pValue, pErrors) {
      if (!pValue) return true;
      const dateEntry = this.form.rg.rgs_expedicao;
      const dateConverted = moment(dateEntry, "DD/MM/YYYY HH:mm");

      if (!dateConverted.isValid()) {
        return false;
      }

      const dateFormat = dateConverted.format("YYYY-MM-DD HH:mm");

      if (dateFormat > moment().format("YYYY-MM-DD HH:mm")) {
        pErrors.push("Data da Emissão não pode ser maior que a data atual");
        return false;
      }
      return true;
    },

    cleanForm() {
      this.form = {
        cns: {
          crs_id: 0,
          crs_numero: "",
        },
        cpf: {
          cpf_id: 0,
          cpf_numero: "",
        },
        pisPasep: {
          pis_id: 0,
          pis_numero: "",
        },
        rg: {
          rgs_id: 0,
          rgs_id_orgaos_emissores: 0,
          rgs_numero: "",
          rgs_expedicao: "",
        },
      };

      this.$refs.cpf.cleanValidate();
      this.cleanValidate();
    },

    cleanNotification() {
      this.receiveNotification = false;

      this.notification.cpf_numero = null;
    },

    showModalOtherDocuments() {
      this.modalOtherDocuments = true;
    },

    closeModalOtherDocuments() {
      this.modalOtherDocuments = false;
    },

    showModalAttachments() {
      this.modalAttachments = true;
    },

    closeModalAttachments() {
      this.modalAttachments = false;
    },

    disableOrEnabledRequired() {
      this.required =
        !!this.form.rg.rgs_numero ||
        !!this.form.rg.rgs_id_orgaos_emissores ||
        !!this.form.rg.rgs_expedicao.length;

      if (!this.required) {
        this.form.rg.rgs_id = 0;
        this.cleanValidate();
      }
    },

    cleanValidate() {
      this.$refs.rgNumber.cleanValidate();
      this.$refs.rgEmissionDate.cleanValidate();
      this.$refs.rgIssuingBody.cleanValidate();
      this.required = false;
    },

    removeDashesAndDots(pText) {
      return pText ? pText.replace(/[^\d]/g, "") : null;
    },
  },
};
</script>
