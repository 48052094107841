<script>
import RgComboboxMixin from "~tokio/primitive/selection/mixin/RgComboboxMixin";
export default {
  name: "RgComboboxTypeOfPatientSearch",
  mixins: [RgComboboxMixin],
  props: {
    showDefaultOption: {
      default: false,
    },
    defaultValue: {
      default: 1,
    },
  },
  data: () => {
    return {
      mData: [
        { value: 1, text: "Nome" },
        { value: 2, text: "Prontuário Único" },
        { value: 3, text: "Prontuário" },
        { value: 4, text: "Nº da Internação" },
      ],
    };
  },
};
</script>
