<template>
  <button
    :data-id="dataId"
    :disabled="permission ? disabled : true"
    :class="{
      disable: permission ? disabled : true,
      disablebtn: permission ? disabled : true,
      nopermission: !permission,
    }"
    :style="{
      background: backgroundColor,
    }"
    class="rg-button"
    :title="permission ? title : 'Usuário sem permissão.'"
    @click.stop.prevent="clicked"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: "RgButton",
  components: {},
  props: {
    dataId: {
      type: String,
      default: "",
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
      default: "",
    },

    label: {
      type: String,
      default: "",
    },

    permission: {
      type: Boolean,
      default: true,
    },

    backgroundColor: {
      type: String,
      default: "",
    },
  },

  methods: {
    clicked() {
      if (this.disabled || !this.permission) {
        return;
      }

      this.$emit("click");
    },
  },
};
</script>
