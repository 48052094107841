<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchDocumentAihType from "./action/SearchDocumentAihType";

export default {
  name: "RgComboboxAihDocumentType",
  mixins: [RgComboboxMixin],
  data: () => {
    return {};
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];
      const data = await SearchDocumentAihType();
      data.forEach((item) => {
        this.mData.push({ value: item.ind_id, text: item.ind_descricao });
      });
    },
  },
};
</script>
