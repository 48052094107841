<template>
  <ModuleBox :title="'Cadastrar Saída'" class="emergency-register-exit">
    <form class="form-container" @submit.stop.prevent="saveForm">
      <RgValidatorForm ref="validator">
        <div class="content-form">
          <div class="informations">
            <RgTypePatient
              :pac-id="Number(isPatient)"
              :sex="isPatient ? patientGender : null"
              class="patient-type"
            />

            <div class="area">
              <span class="data">
                Paciente:
                <strong>
                  {{ patientData.pes_nome }}
                </strong>
              </span>

              <span class="data">
                Boletim:
                <strong>
                  {{ patientData.bol_numero }}
                </strong>
              </span>
            </div>

            <div class="area">
              <span class="data">
                Unidade de Saúde:
                <strong>
                  {{ patientData.uns_nome }}
                </strong>
              </span>

              <span class="data">
                Setor:
                <strong>
                  {{ patientData.set_nome }}
                </strong>
              </span>
            </div>

            <div class="area">
              <span class="data">
                Local de Atendimento:
                <strong>
                  {{ patientData.lca_nome }}
                </strong>
              </span>
            </div>
          </div>

          <FormBase title="Saída" class="grid-form">
            <div class="register-inputs">
              <RgInputDate
                v-model="form.date"
                :rules="{ required: true, fn: validateDate }"
                :class="{ 'disable unselect': bulletinSentPep }"
                :disabled="bulletinSentPep"
                placeholder="dd/mm/aaaa"
              />
              <RgInputHour
                v-model="form.hour"
                v-mask="'##:##'"
                :rules="{ required: true, fn: validateHour }"
                :class="{ 'disable unselect': bulletinSentPep }"
                :disabled="bulletinSentPep"
                placeholder="hh:mm"
              />
              <RgComboboxTypeExit
                ref="typeExit"
                v-model="form.type"
                :rules="{ required: true }"
              />
              <RgComboboxDestinationPlaceExit
                ref="destinationPlaceExit"
                v-model="form.destination"
                :rules="{ required: destinationRequired }"
              />
            </div>

            <div class="texts-area">
              <RgTextArea
                v-model="form.observation"
                class="area"
                label="Observações"
                :maxlength="255"
              />
            </div>
          </FormBase>

          <FormBase v-if="showCauseDeath" title="Óbito" class="grid-form">
            <RgTextArea
              v-model="form.causeDeath"
              :rules="{ required: true }"
              class="area"
              label="Causa do Óbito"
              :maxlength="255"
            />
          </FormBase>

          <FormBase
            v-if="showHospitalizationRequest"
            title="Solicitação de Internação"
            class="grid-form"
          >
            <RgTextArea
              v-model="hospitalization.justification"
              :rules="{ required: true }"
              class="area"
              label="Justificativa da Internação"
              :maxlength="500"
            />
            <RgTextArea
              v-model="hospitalization.conditions"
              class="area"
              label="Condições que Justificam a Internação"
              :maxlength="500"
            />
            <RgTextArea
              v-model="hospitalization.diagnosticTests"
              class="area"
              label="Principais Resultados de Provas Diagnósticas (Resultados de Exames Realizados)"
              :maxlength="500"
            />
            <RgTextArea
              v-model="hospitalization.initialDiagnosis"
              class="area"
              label="Diagnóstico Inicial"
              :maxlength="500"
            />

            <div class="hospitalization-inputs">
              <RgSuggestCbo
                ref="cbo"
                v-model="suggestOccupation"
                :employeeId="professionalId"
                label="Ocupação"
                @selected="selectingOccupation"
              />

              <RgSuggestEmployee
                ref="professional"
                v-model="suggestEmploye"
                :ocuppationId="occupationId"
                label="Profissional Solicitante"
                @selected="selectingProfessional"
              />

              <RgComboboxHospitalizationCharacter
                v-model="hospitalization.character"
              />

              <RgSuggestHospitalizationQueueProcedures
                v-model="suggestProcedure"
                label="Procedimento Solicitado"
                class="small-suggest"
                :rules="{ forceSelection: true }"
                @selected="selectingProcedure"
              />

              <RgSuggestCid
                ref="cid"
                v-model="suggestCid"
                label="CID"
                class="small-suggest"
                :rules="{ forceSelection: true }"
                @selected="selectingCid"
              />
            </div>
          </FormBase>
        </div>
      </RgValidatorForm>
    </form>

    <div slot="footer" class="footer">
      <RgCleanButton small @click="openModalConfirmFieldClearing" />
      <RgCancelButton medium @click="goBack" />
      <RgSaveButton ref="save" medium @click="saveForm" />
    </div>

    <ModalConfirmFieldClearing
      ref="confirmClearing"
      :show="modalConfirmFieldClearing"
      @getYes="cleanForm"
      @getOut="closeModalConfirmFieldClearing"
    />
  </ModuleBox>
</template>

<script>
import moment from "moment";
import ModuleBox from "~tokio/foundation/modulebox/Modulebox";
import FormBase from "~tokio/foundation/form-base/FormBase";
import RgTypePatient from "$hospitalization/common/component/status/rg-type-patient/RgTypePatient";
import RgSuggestHospitalizationQueueProcedures from "$hospitalization/common/component/suggest/rg-suggest-hospitalization-queue-procedures/RgSuggestHospitalizationQueueProcedures";
import { mask } from "vue-the-mask";
import {
  RgValidatorForm,
  RgTextArea,
  RgInputDate,
  RgInputHour,
  RgCleanButton,
  RgCancelButton,
  RgSaveButton,
  ModalConfirmFieldClearing,
  RgSuggestCid,
  RgSuggestCbo,
  RgSuggestEmployee,
} from "~tokio/primitive";
import {
  RgComboboxTypeExit,
  RgComboboxDestinationPlaceExit,
  RgComboboxHospitalizationCharacter,
} from "$emergency/bulletin/component";

export default {
  name: "ModalRegisterExit",
  components: {
    RgValidatorForm,
    FormBase,
    RgTextArea,
    RgInputDate,
    RgInputHour,
    RgComboboxTypeExit,
    RgComboboxDestinationPlaceExit,
    RgComboboxHospitalizationCharacter,
    RgSuggestCid,
    RgSuggestHospitalizationQueueProcedures,
    RgTypePatient,
    ModalConfirmFieldClearing,
    ModuleBox,
    RgCleanButton,
    RgCancelButton,
    RgSaveButton,
    RgSuggestCbo,
    RgSuggestEmployee,
  },
  directives: { mask },

  data() {
    return {
      form: {
        date: moment().format("DD/MM/YYYY"),
        hour: moment().format("HH:mm:ss"),
        type: null,
        destination: 0,
        observation: null,
        causeDeath: null,
      },
      hospitalization: {
        justification: null,
        conditions: null,
        diagnosticTests: null,
        initialDiagnosis: null,
        occupation: null,
        professional: null,
        character: null,
        cid: null,
        procedure: null,
      },
      suggestProcedure: null,
      suggestCid: null,
      suggestOccupation: null,
      suggestEmploye: null,
      professionalId: null,
      occupationId: null,
      occupationCode: null,
      procedureCode: null,
      patientData: {},
      patientGender: null,
      modalConfirmFieldClearing: false,
      bulletinSentPep: false,
    };
  },

  computed: {
    showCauseDeath() {
      return this.form.type === "4";
    },
    showHospitalizationRequest() {
      return this.form.type === "3";
    },
    isPatient() {
      return this.patientData && this.patientData.pac_id
        ? this.patientData.pac_id
        : 0;
    },
    destinationRequired() {
      return this.form.type === "3" || this.form.type === "5";
    },
  },

  mounted() {
    const bulletin = this.$store.getters[
      "Emergency/Bulletin/GET_SELECTED_BULLETIN"
    ];

    if (bulletin) {
      this.bulletinData = bulletin;
      this.objectPatient(this.bulletinData);
      this.bulletinSentPep = this.bulletinData.enviado === "SIM";
    }
  },

  methods: {
    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    formatData() {
      let variables = {};
      variables = {
        des_paciente: this.bulletinData.pes_nome,
        des_id_boletins: this.bulletinData.bol_id,
        des_data: this.form.date,
        des_hora: moment(this.form.hour, "HH:mm:ss").format("HH:mm"),
        des_id_destinos_grupos: this.form.type,
        des_id_locais_destino: this.form.destination,
        des_obs: this.form.observation,
        des_causa_obito: this.form.causeDeath,
      };
      return variables;
    },

    formatDataHospitalization() {
      const date = moment(this.form.date, "DD/MM/YYYY").format("YYYY-MM-DD");
      const hour = moment(this.form.hour, "HH:mm:ss").format("HH:mm:ss");
      const dateAndHour = date + " " + hour;

      let variables = {};
      variables = {
        ins_id_usuarios: this.$store.getters["Login/GET_USER_ID"],
        ins_id_boletins: Number(this.bulletinData.bol_id),
        ins_data_saida_emergencia: date,
        ins_hora_saida_emergencia: hour,
        ins_data_hora: dateAndHour,
        ins_justificativa_internacao: this.hospitalization.justification,
        ins_sinais_sintomas: this.hospitalization.conditions || null,
        ins_resultados: this.hospitalization.diagnosticTests || null,
        ins_diagnostico_inicial: this.hospitalization.initialDiagnosis || null,
        ins_id_ocupacoes_cbo: Number(this.hospitalization.occupation) || null,
        ins_id_funcionarios: Number(this.hospitalization.professional) || null,
        ins_id_internacoes_caracteres:
          Number(this.hospitalization.character) || null,
        ins_id_cid10: this.hospitalization.cid || null,
        ins_id_internacoes_procedimentos:
          this.hospitalization.procedure || null,
      };
      return variables;
    },

    objectPatient(pBulletin) {
      if (pBulletin) {
        this.patientData = {
          bol_numero: pBulletin.bol_numero,
          lca_nome: pBulletin.lca_nome,
          pac_id: pBulletin.pac_id,
          pce_id: pBulletin.pce_id,
          pep_sexo: pBulletin.pep_sexo,
          pes_nome: pBulletin.pes_nome,
          set_nome: pBulletin.set_nome,
          uns_id: pBulletin.uns_id,
          uns_nome: pBulletin.uns_nome,
        };

        this.patientData.pep_sexo === "M"
          ? (this.patientGender = 1)
          : (this.patientGender = 0);
      }
    },

    async saveForm() {
      if (!(await this.isFormValid())) {
        this.$toaster.warning("Verifique os campos.");
        return false;
      }

      try {
        this.$loader.start();

        const variables = this.formatData();
        const variablesHospitalization = this.formatDataHospitalization();

        await this.$store.dispatch(
          "Emergency/Bulletin/REGISTER_EXIT",
          variables,
        );

        if (this.showHospitalizationRequest) {
          await this.$store.dispatch(
            "Emergency/Bulletin/SAVE_HOSPITALIZATION_SOLICITATION",
            variablesHospitalization,
          );
        }

        this.$toaster.success("Cadastro de saída realizado com sucesso!");
        this.$router.go(-1);
      } catch (pErr) {
        const error = pErr.toString();

        if (error.includes("Error:")) {
          this.$toaster.error(
            error.substring(6, error.length),
            "Erro ao salvar cadastro de saída.",
          );
        } else {
          this.$toaster.error(pErr, "Erro ao salvar cadastro de saída.");
        }
      } finally {
        this.$loader.finish();
        this.$refs.save.actionDone();
      }
    },

    validateDate(pValue, pErrors) {
      const bulletinHour = this.bulletinData.bol_hora.substring(0, 5);
      const dateIsNotValid = pValue < this.bulletinData.bol_data;

      if (this.bulletinData.bol_data && pValue && dateIsNotValid) {
        pErrors.push(
          "A data e hora da saída não pode ser retroativa à data e hora do boletim: " +
            this.bulletinData.bol_data +
            " " +
            bulletinHour +
            " ",
        );
        return false;
      }
    },

    validateHour(pValue, pErrors) {
      const bulletinHour = this.bulletinData.bol_hora.substring(0, 5);
      const equalDate = this.form.date === this.bulletinData.bol_data;
      const equalHour = pValue === bulletinHour;
      const hourIsNotValid = !equalHour && pValue < bulletinHour;

      if (pValue && equalDate && hourIsNotValid) {
        pErrors.push(
          "A data e hora da saída não pode ser retroativa à data e hora do boletim: " +
            this.bulletinData.bol_data +
            " " +
            bulletinHour +
            " ",
        );
        return false;
      }
    },

    selectingCid(pCid) {
      this.hospitalization.cid = pCid?.source.cid_id;
    },

    selectingOccupation(pOccupation) {
      this.hospitalization.occupation = pOccupation?.source.ocp_id;

      this.occupationId = pOccupation?.source.ocp_id;
    },

    selectingProfessional(pProfessional) {
      this.hospitalization.professional =
        pProfessional?.source?.employeeRelationship?.vin_id_funcionarios;

      this.professionalId =
        pProfessional?.source?.employeeRelationship?.vin_id_funcionarios;
    },

    selectingProcedure(pProcedure) {
      this.procedureCode = pProcedure?.source?.inp_novo_codigo_procedimento;
      this.hospitalization.procedure = pProcedure?.source?.inp_id;
    },

    openModalConfirmFieldClearing() {
      this.modalConfirmFieldClearing = true;
    },

    closeModalConfirmFieldClearing() {
      this.modalConfirmFieldClearing = false;
    },

    cleanForm() {
      this.form = {
        date: moment().format("DD/MM/YYYY"),
        hour: moment().format("HH:mm:ss"),
        type: null,
        destination: 0,
        observation: null,
        causeDeath: null,
      };
      this.hospitalization = {
        justification: null,
        conditions: null,
        diagnosticTests: null,
        initialDiagnosis: null,
        occupation: null,
        professional: null,
        character: null,
      };
      this.suggestCid = null;
      this.suggestProcedure = null;
      this.modalConfirmFieldClearing = false;

      this.$refs.typeExit.cleanValidate();
      this.$refs.destinationPlaceExit.cleanValidate();
    },

    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
