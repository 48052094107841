import LIST_CONSOLIDATED_BILLING from "./ListConsolidatedBilling";
import SAVE_CONSOLIDATED_BILLING from "./SaveConsolidatedBilling";
import REMOVE_CONSOLIDATED_BILLING from "./RemoveConsolidatedBilling";
import GET_PROCEDURE_DETAILS from "./GetProcedureDetails";

export default {
  LIST_CONSOLIDATED_BILLING,
  SAVE_CONSOLIDATED_BILLING,
  REMOVE_CONSOLIDATED_BILLING,
  GET_PROCEDURE_DETAILS,
};
