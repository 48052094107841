import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query Procedure(
    $procedureName: String
    $procedureCode: String
    $periodDate: String
    $sectorLink: Int
    $isLaboratory: Boolean
    $limit: Int
  ) {
    procedures(
      NO_PROCEDIMENTO: $procedureName
      CO_PROCEDIMENTO: $procedureCode
      DT_COMPETENCIA: $periodDate
      sectorLink: $sectorLink
      isLaboratory: $isLaboratory
      limit: $limit
    ) {
      count
      rows {
        CO_PROCEDIMENTO
        NO_PROCEDIMENTO
        VL_IDADE_MINIMA
        VL_IDADE_MAXIMA
        details {
          rows {
            CO_DETALHE
          }
        }
      }
    }
  }
`;

export default async ({ variables }) => {
  const result = await GraphQLClient.query({ query, variables }).catch(
    (err) => {
      console.error(err);
    },
  );
  return result ? result.data.procedures : [];
};
