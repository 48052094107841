import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query LaborMarket {
    SearchLaborMarket {
      rows {
        smt_id
        smt_codigo_ab
        smt_nome
      }
    }
  }
`;

export default async ({ commit, state }) => {
  if (state.laborMarket.length > 0) {
    return;
  }
  try {
    const { data } = await GraphQLClient.query({
      query,
      fetchPolicy: "no-cache",
    });
    commit("SET_LABOR_MARKET", data.SearchLaborMarket.rows);
  } catch (err) {
    console.log(err);
    return err.graphQLErrors;
  }
};
