const mutations = {
  SET_BREED: (state, breed) => {
    state.breed = breed;
  },
  SET_ETHNICITY: (state, ethnicities) => {
    state.ethnicities = ethnicities;
  },
  SET_GENRE: (state, genres) => {
    state.genre = genres;
  },
  SET_SEXUAL_ORIENTATION: (state, orientation) => {
    state.sexualOrientation = orientation;
  },
  SET_DEGREE_KINSHIP: (state, kinship) => {
    state.degreeKinship = kinship;
  },
  SET_OCCUPATIONS: (state, occupations) => {
    state.occupations = occupations;
  },
  SET_SCHOOLING_AB: (state, schoolingAb) => {
    state.schoolingAb = schoolingAb;
  },
  SET_LABOR_MARKET: (state, laborMarket) => {
    state.laborMarket = laborMarket;
  },
  SET_CHILD_SUPPORT: (state, childSupport) => {
    state.childSupport = childSupport;
  },
  SET_DEFICIENCY: (state, deficiency) => {
    state.deficiency = deficiency;
  },
  SET_REASON_OUT_REGISTER: (state, reasonOut) => {
    state.reasonOut = reasonOut;
  },
  SET_WEIGHT: (state, weights) => {
    state.weight = weights;
  },
  SET_HEART_DISEASE: (state, heartDisease) => {
    state.heartDisease = heartDisease;
  },
  SET_KIDNEY_DISEASE: (state, kidneyDisease) => {
    state.kidneyDisease = kidneyDisease;
  },
  SET_RESPIRATORY_DISEASE: (state, respiratoryDisease) => {
    state.respiratoryDisease = respiratoryDisease;
  },
  SET_STREET_TIME: (state, streetTime) => {
    state.streetTime = streetTime;
  },
  SET_FOOD_DAY: (state, foodDay) => {
    state.foodDay = foodDay;
  },
  SET_HYGIENE: (state, personalHygiene) => {
    state.personalHygiene = personalHygiene;
  },
  SET_FOOD_ORIGIN: (state, foodOrigin) => {
    state.foodOrigin = foodOrigin;
  },
};

export default mutations;
