<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchEthnicity from "./action/SearchEthnicity";

export default {
  name: "RgComboboxEthnicity",
  mixins: [RgComboboxMixin],
  data: () => {
    return {};
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];
      const data = await SearchEthnicity();
      data.forEach((item) => {
        this.mData.push({ value: item.etn_id, text: item.etn_raca_ab });
      });
    },
    getMData() {
      return this.mData;
    },
  },
};
</script>
