import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async ({
  intIdSetor,
  intVusId,
  strData,
  strNomeProcedimento,
}) => {
  return AuthLegacyRequest.post(
    "exames2/controller-exame-historico-agendamento/combo-box-escalas-com-procedimento",
    { intIdSetor, intVusId, strData, strNomeProcedimento },
  )
    .then(({ data }) => {
      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
