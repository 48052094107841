<script>
import { RgRemoteComboboxMixin } from "~tokio/primitive";
import SearchScaleType from "./action/SearchScaleType";

export default {
  name: "RgComboboxScaleType",
  mixins: [RgRemoteComboboxMixin],
  props: {
    unitHealthId: {
      default: null,
    },
    permission: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      userId: this.$store.getters["Login/GET_USER_ID"],
    };
  },
  watch: {
    unitHealthId: function () {
      this.getDataFromRemote();
    },
  },
  methods: {
    doSearch() {
      if (this.unitHealthId === null) return Promise.resolve([]);
      if (this.userId === null) return Promise.resolve([]);

      return SearchScaleType({
        uns_id: this.unitHealthId,
        usu_id: this.userId,
      });
    },
    formatItem(item) {
      return {
        value: item.data,
        text: `${item.ees_nome}`,
      };
    },
    blur() {
      if (this.isNecessary) {
        this.validate();
        this.$emit("blur");
      }
    },
  },
};
</script>
