<template>
  <Main is-per-day />
</template>

<script>
import Main from "$exam/submodules/schedule/component/main/Main";

export default {
  name: "ExamSchedulePerDay",
  components: {
    Main,
  },
};
</script>
