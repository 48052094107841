import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query Shift {
    SearchShift {
      rows {
        tur_id
        tur_descricao
      }
    }
  }
`;

export default async ({ commit, state }) => {
  if (state.shifts.length > 0) {
    return;
  }
  try {
    const { data } = await GraphQLClient.query({
      query,
      fetchPolicy: "no-cache",
    });
    commit("SET_SHIFTS", data.SearchShift.rows);
  } catch (err) {
    console.log(err);
    return err.graphQLErrors;
  }
};
