const ConsultationAuthorizationHTML = (pData) => {
  const html = `
    <!DOCTYPE HTML>
      <html lang="pt-br">
      <head>
        <meta charset="UTF-8">
        <title>AUTORIZAÇÃO DE CONSULTA/EXAME RESUMIDA</title>

        <style type="text/css">
          @media print{
            body{ background-color:xFFFFFF; background-image:none; color:x000000 }
            #ad{ display:none;}
            #leftbar{ display:none;}
            #contentarea{ width:100%;}
            @page { margin: 7mm 5mm 7mm 5mm; }
            .page-break { page-break-before: always;}
          }

          body{
              font-size: 9px;
              font-family: Arial, sans-serif;
          }

          .brd{
              border: 1px solid;
              overflow: hidden;
              padding:2px;
              font-weight:bold;
              height:10px;
            }

          td{font-size: 9px;}

          .w99{width: 99%;}
          .w80{width: 80%;}
          .w70{width: 60%;}
          .w60{width: 70%;}
          .w75{width: 75%;}
          .w55{width: 55%;}
          .w50{width: 50%;}
          .w40{width: 40%;}
          .w29{width: 29%;}
          .w24{width: 24%;}
          .w25{width: 25%;}
          .w20{width: 20%;}
          .w19{width: 19%;}
          .w16{width: 16%;}
          .w10{width: 10%;}
          .w5 {width: 5% ;}

          header{
            /*border: 1px solid black;*/
            display: inline-block;
            width: 100%;
          }

          .logoCliente{
            float: left;
            width:  100px;
            height: 70px;
          }

          .logoEsus{
            float: right;
            width:  100px;
            height: 70px;
          }

          article{
            /*border: 1px solid black;*/
            border-radius: 5px;
            width: 100%;
            height: 100%;
            display: inline-block;
            margin-top: 2px;
            margin-bottom: 2px;
          }

          .titulo{
            text-align: center;
          }

          table {
            border: 0px auto;
            width: 100%;
            margin-top: 0px;

          }

          h1, h3 {
            margin-top: 0;
            margin-bottom: 0;
          }

          h2, h4, h5{
            margin-top: 0;
            margin-bottom: 0;
          }

          label{font-size: 9px;}

          fieldset{
            border-radius: 5px;
            overflow: hidden;
            padding:2px;
            font-weight:bold;
            width: 100%;
            display: inline-block;
            height: 100%;
            margin-top: 1px;
            margin-bottom: 1px;
          }
        </style>
      </head>

      <body>
        <header>
          <table>
            <tr>
              <td class="titulo"><h3>SER - SISTEMA ESTADUAL DE REGULAÇÃO <br> AUTORIZAÇÃO DE CONSULTA/EXAME RESUMIDA</h3></td>
            </tr>
          </table>
        </header>

        <article>
          <fieldset class="brd"style="height:100%;">
          <div>
            <table width="100%">
              <tr>
                <td>
                  <label>Unidade Solicitante</label>
                </td>

                <td>
                  <label>CNES</label>
                </td>
              </tr>
              <tr>
                <td class="brd w70">${pData.uns_nome}</td>

                <td class="brd w20">${pData.uns_cnes || ""}</td>
              </tr>
            </table>
            <table>
              <tr>
                <td>
                  <label style="width:15%;">Tipo de Recurso</label>
                </td>

                <td>
                  <label>Recurso</label>
                </td>
              </tr>
              <tr>
                <td class="brd w20"><!--{tipo_recurso}--></td>

                <td class="brd w70"><!--{recurso}--></td>
              </tr>
            </table>
          </div>
          </fieldset>
        </article>

        <article>
          <fieldset class="brd"style="height:100%;">
            <legend align="center"><h5 class="titulo">DADOS DA UNIDADE EXECUTANTE</h5></legend>
              <div>
                <table width="100%">
                  <tr>
                    <td>
                      <label>Unidade Executante</label>
                    </td>

                    <td>
                      <label>CNES</label>
                    </td>
                  </tr>
                  <tr>
                    <td class="brd w70"><!--{uns_executante}--></td>

                    <td class="brd w20"><!--{uns_cnes_executante}--></td>
                  </tr>
                </table>
                <table width="100%">
                  <tr>
                    <td>
                      <label>Logradouro</label>
                    </td>
                    <td>
                      <label>Nº</label>
                    </td>
                    <td>
                      <label>Complemento</label>
                    </td>
                    <td>
                      <label>Bairro</label>
                    </td>
                  </tr>
                  <tr>
                    <td class="brd w55"></td>
                    <td class="brd w5"></td>
                    <td class="brd w20"></td>
                    <td class="brd w20"></td>
                  </tr>
                  <tr>
                    <td>
                      <label>Municipio</label>
                    </td>
                    <td>
                      <label>UF</label>
                    </td>
                    <td>
                      <label>Cep</label>
                    </td>
                    <td>
                      <label>Telefone de Contato</label>
                    </td>
                  </tr>
                  <tr>
                    <td class="brd w55"></td>
                    <td class="brd w5"></td>
                    <td class="brd w20"></td>
                    <td class="brd w20"></td>
                  </tr>
                </table>
              </div>

              <div>
                <table width="100%">
                  <tr>
                    <td>
                      <label>Nome</label>
                    <td>
                      <label>Nº Cartão SUS/Identificação Civil</label>
                    </td>
                  </tr>
                  <tr>
                    <td class="brd w75"><!--{pes_nome}--></td>
                    <td class="brd w25"><!--{cartao_sus}--></td>
                  </tr>
                </table>
              </div>
              <div>
                <table width="100%">
                  <tr>
                    <td>
                      <label>Endereço</label>
                    <td>
                      <label>Município</label>
                    </td>
                    <td>
                      <label>UF</label>
                    </td>
                  </tr>
                  <tr>
                    <td class="brd w70"><!--{end_logradouro}--></td>
                    <td class="brd w25"><!--{municipio}--></td>
                    <td class="brd w5"><!--{UF}--></td>
                  </tr>
                </table>
              </div>

              <div>
                <h5><p>DADOS DO AGENDAMENTO</p></h5>
                <table>
                  <tr>
                    <td>
                      <label>Data do Agendamento</label>
                    </td>
                    <td>
                      <label>Chave de Autorização</label>
                    </td>
                  </tr>
                  <tr>
                    <td class="brd">${pData.data_agendada}</td>
                    <td class="brd"><!--{chave_aut}--></td>
                  </tr>
                </table>
              </div>
              <div>
                <table>
                  <tr>
                    <td>
                      <label>Profissional</label>
                    </td>
                  </tr>
                  <tr>
                    <td class="brd"><!--{profissional}--></td>
                  </tr>
                </table>
                </div>
              <div>
                <table>
                  <tr>
                    <td>
                      <label>Observação</label>
                    </td>
                  </tr>
                  <tr>
                    <td class="brd" style="height:30px;"></td>
                  </tr>
                </table>
              </div>
          </fieldset>
        </article>

        <article>
          <fieldset class="brd" style="height:100%;">
            <legend align="center"><h5 class="titulo">DADOS DO PACIENTE</h5></legend>
              <div>
                <table width="100%">
                  <tr>
                    <td>
                      <label>Nome do Paciente</label>
                    </td>
                    <td>
                      <label>Nº Prontuário</label>
                    </td>
                  </tr>
                  <tr>
                    <td class="brd w80">${pData.pac_nome}</td>
                    <td class="brd w20">${pData.ppr_numero}</td>
                  </tr>
                </table>
              </div>

              <div>
                <table width="100%">
                  <tr>
                    <td>
                      <label>Cartão Nacional de Saúde (CNS)</label>
                    <td>
                      <label>Data de Nascimento</label>
                    </td>
                    <td>
                      <label>Sexo</label>
                    </td>
                  </tr>
                  <tr>
                    <td class="brd w40">${pData.cartao_sus}</td>
                    <td class="brd w40">${pData.pac_nascimento}</td>
                    <td class="brd w20">${pData.pac_sexo}</td>
                  </tr>
                </table>
              </div>
              <div>
                <table width="100%">
                  <tr>
                    <td>
                      <label>Nome da Mãe ou Responsável</label>
                    <td>
                      <label>Telefone de Contato</label>
                    </td>
                  </tr>
                  <tr>
                    <td class="brd w80">${pData.pac_responsavel}</td>
                    <td class="brd w20">${pData.pac_tel}</td>
                  </tr>
                </table>
              </div>
          </fieldset>
        </article>

        <article>
          <fieldset class="brd" style="height:100%;">
            <legend align="center"><h5 class="titulo">IDENTIFICAÇÃO DO SOLICITANTE</h5></legend>
          <div>
            <table width="100%">
              <tr>
                <td>
                  <label>Médico Solicitante</td>
                <td>
                  <label>Nº Documento CPF</label>
                </td>
              </tr>
              <tr>
                <td class="brd">${pData.nomeFuncionarioSolicitante}</td>
                <td class="brd"><!--{medico_cpf}--></td>
              </tr>
            </table>
          </div>
          <div>
            <table width="100%">
              <tr>
                <td>
                  <label>Escpecialidade</label>
                </td>
              </tr>
              <tr>
                <td class="brd">${pData.ocp_nome}</td>
              </tr>
            </table>
          </div>
          </fieldset>
        </article>

        <article>
          <fieldset class="brd" style="height:100%;">
            <legend align="center"><h5 class="titulo">RECURSO SOLICITADO / AGENDADO</h5></legend>
          <div>
            <table width="100%">
              <tr>
                <td>
                  <label>Tipo</td>
                <td>
                  <label>Recurso Solicitado</label>
                </td>
              </tr>
              <tr>
                <td class="brd w20"><!--{tipo_recurso_solicitado}--></td>
                <td class="brd w80"><!--{recurso_solicitado}--></td>
              </tr>
              <tr>
                <td>
                  <label>Tipo</td>
                <td>
                  <label>Recurso Agendado</label>
                </td>
              </tr>
              <tr>
                <td class="brd w20"><!--{tipo_recurso_agendado}--></td>
                <td class="brd w80"><!--{recurso_agendado}--></td>
              </tr>
            </table>
          </div>
          </fieldset>
        </article>

        <article>
          <fieldset class="brd" style="height:50px;">
            <legend align="center"><h4 class="titulo">OBSERVAÇÃO</h4></legend>
          </fieldset>
        </article>

        <article>
          <fieldset class="brd" style="height:100%;">
            <legend align="center"><h5 class="titulo">AUTORIZAÇÃO</h5></legend>
            <div>
              <table width="100%">
                <tr>
                  <td>
                    <label>Nome do Profissional Autorizador
                  </td>
                </tr>
                <tr>
                  <td class="brd"><!--{nome_autorizador}--></td>
                </tr>
              </table>
              <table width="100%">
                <tr>
                  <td>
                    <label>Nº Documento CPF do Profissional Autorizador</label>
                  </td>
                  <td>
                    <label>Data da Autorização</label>
                  </td>
                  <td>
                    <label>Nº da Solicitação</label>
                  </td>
                </tr>
                <tr>
                  <td class="brd"><!--{cpf_autorizador}--></td>
                  <td class="brd"><!--{data_autorizacao}--></td>
                  <td class="brd"><!--{num_solicitacao_autorizacao}--></td>
                </tr>
              </table>
            </div>
          </fieldset>
        </article>

        <div>
          <table width="100%">
            <tr>
              <td>1º via</td>
              <td align="right"><!--{data_hora_doc}--></td>
            </tr>
          </table>
        </div>

      </body>
</html>
      `;

  return html;
};

export default ConsultationAuthorizationHTML;
