const Genders = {
  MASCULINO: 1,
  FEMININO: 2,
  INVALIDO: 3,
};

const Initials = {
  M: 1,
  F: 2,
  X: 3,
};

const GetInitialsById = (pId) => {
  const flipped = Object.entries(Initials).reduce(
    (obj, [key, value]) => ({ ...obj, [value]: key }),
    {},
  );

  return flipped[pId] || null;
};

const GetIdByInitials = (pInitial) => {
  if (pInitial && pInitial.length > 0) {
    const genders = Initials[pInitial];
    return genders || null;
  }
};

export { Genders, GetInitialsById, GetIdByInitials };

export default { ...Genders };
