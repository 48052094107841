import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation($bioslabLaboratoryExam: BioslabLaboratoryExamInputType) {
    SaveBioslabLaboratoryExam(bioslabLaboratoryExam: $bioslabLaboratoryExam) {
      leb_id
    }
  }
`;

export default async ({ commit }, variables) => {
  const { data } = await GraphQLClient.mutate({ mutation, variables }).catch(
    (err) => {
      throw err;
    },
  );
  return data;
};
