<template>
  <section class="foundation-externally-schedule">
    <RgSearch
      ref="RgSearch"
      v-model="mutableList"
      :build-filter="generateFilter"
      :clearFunction="actionCleanForm"
      :max-register="40"
      :item-height="29.5"
      :search-function="actionSearchFilter"
      class="search-container-area"
      resultTitle="Pacientes Agendados Externamente"
      @afterPerformSearch="actionAfterSearchFilter"
      @count="getCountValue"
    >
      <div slot="filters" class="foundation-externally-schedule-filter">
        <div class="grid">
          <div class="filter-title">
            <span class="title">Dados do Pacientes</span>
          </div>

          <div class="selectinput">
            <RgInput
              v-model="form.patientName"
              :disabled="!!form.pac_id"
              :class="{ disable: !!form.pac_id }"
              class="inputitem"
              placeholder="Digite o nome do paciente"
              label="Paciente"
            />
          </div>

          <div class="selectinput">
            <RgSuggestSmartPerson
              ref="patient"
              v-model="form.specificPatient"
              :disabled="form.patientName.length > 0"
              :with-patient-module="permissionSuggest"
              :enabled-patient="false"
              filterOnlyDocument
              class="inputitem"
              @selected="selectingPatient"
            />
          </div>

          <div class="filter-title">
            <span class="title">Dados da Regulação</span>
          </div>

          <div class="selectinput">
            <RgComboboxUnithealth
              v-model="form.requestingUnit"
              :default-text="'Todas'"
              label="Unidade Solicitante"
              class="inputitem"
            />
          </div>

          <div class="selectinput">
            <RgComboboxExternalSchedulingProcedures
              v-if="!isAppointment"
              v-model="form.procedure"
              class="inputitem"
              default-text="Todos"
              label="Procedimento"
            />

            <RgComboboxOccupationScheduleExternally
              v-else
              v-model="form.soc_id"
              default-text="Todas"
              label="Ocupação"
            />
          </div>

          <div class="filter-title">
            <span class="title">Dados do Agendamento</span>
          </div>

          <div class="selectinput">
            <div class="period">
              <RgInputDate
                v-model="form.initialDate"
                placeholder="dd/mm/aaaa"
                label="Data Inicial"
                class="date"
                :rules="{ fn: validateDate }"
              />

              <RgInputDate
                v-model="form.finalDate"
                placeholder="dd/mm/aaaa"
                label="Data Final"
                class="date"
                :rules="{ fn: validateDate }"
              />
            </div>

            <div class="selectinput">
              <RgComboboxExternalLocation
                v-model="form.aex_id"
                label="Destino"
                class="inputitem"
                default-text="Todos"
                :id-modulo="moduleId"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="foundation-externally-schedule-search">
        <SmartTable
          ref="smartTable"
          :name="
            isAppointment
              ? 'AppointmentFoundationExternallySchedule'
              : 'ExamFoundationExternallySchedule'
          "
          :columns="isAppointment ? COLUMN_APPOINTMENT : COLUMN_EXAM"
          :dynamic-height="getSpaceTable"
          :body="mutableList"
          :total="Number(totalList)"
          :initial-columns="5"
          :show-pagination="false"
          toggle-selected
          class="smart-table"
          @getLine="selectRowData"
        >
          <div slot="top-buttons" class="top-buttons">
            <RgEditButton
              :disabled="!hasSelectedLine"
              title="Editar Agendamento Externo"
              @click="openModalEditExternallySchedule"
            />
            <RgLessButton
              :disabled="!hasSelectedLine"
              title="Excluir Agendamento Externo"
              @click="openModalDeleteExternalSchedule"
            />
            <RgPersonDropdown
              :disabled="!hasSelectedLine"
              :item="selectedRow || {}"
              :action-options="itemActionOptionsPerson(selectedRow)"
              class="dropdown-top"
            />
            <RgPrinterButton
              title="Lista de Pacientes Agendados"
              @click="printPatientsList"
            />
          </div>
        </SmartTable>
      </div>
    </RgSearch>

    <ModalEditExternallySchedule
      :show="modalEditExternallySchedule"
      :externallyScheduleData="selectedRow"
      :moduleId="moduleId"
      @reSearch="reSearchTable"
      @close="closeModalEditExternallySchedule"
    />

    <ModalConfirmDeletion
      v-bind="propsModalDeleteExternalSchedule"
      @close="closeModalDeleteExternalSchedule"
      @reSearch="reSearchTable"
    />

    <ModalPatientImageInRow
      :show="modalPatientImageInRow"
      :modId="moduleId"
      :fill-id="selectedRow && Number(selectedRow.fil_id)"
      hide-buttons
      @close="closeModalPatientImageInRow"
    />

    <ModalInformationPrint
      :show="modalInformationPrint"
      :list-size="totalList"
      @close="closeModalInformationPrint"
    />
  </section>
</template>

<script>
import RgSearch from "~tokio/foundation/rg-search/RgSearch";
import {
  RgInputDate,
  RgInput,
  RgEditButton,
  RgLessButton,
  RgPersonDropdown,
  RgPrinterButton,
} from "~tokio/primitive";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";

import RgSuggestSmartPerson from "~tokio/primitive/suggest/rg-suggest-smart-person/RgSuggestSmartPerson.vue";
import RgComboboxExternalSchedulingProcedures from "$exam/common/component/rg-combobox-external-scheduling-procedures/RgComboboxExternalSchedulingProcedures";
import RgComboboxOccupationScheduleExternally from "$appointment/common/component/rg-combobox-occupation-schedule-externally/RgComboboxOccupationScheduleExternally.vue";
import ModalEditExternallySchedule from "~tokio/foundation/pages/patient/foundation-externally-schedule/components/modal/modal-edit-externally-schedule/ModalEditExternallySchedule";
import {
  ModalConfirmDeletion,
  ModalPatientImageInRow,
  ModalInformationPrint,
} from "~tokio/primitive/modal";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";
import {
  RgComboboxUnithealth,
  RgComboboxExternalLocation,
} from "~tokio/primitive/combobox";
import moment from "moment";

const FORM_BASE = {
  patientName: "",
  specificPatient: null,
  specificPatientName: "",
  pes_id: null,
  pac_id: null,
  uns_id: null,
  soc_id: null,
  procedure: "",
  initialDate: "",
  finalDate: "",
  aex_id: null,
  requestingUnit: null,
};

export default {
  name: "FoundationExternallySchedule",

  components: {
    RgSearch,
    RgInputDate,
    RgInput,
    RgSuggestSmartPerson,
    RgComboboxUnithealth,
    RgComboboxExternalLocation,
    RgComboboxOccupationScheduleExternally,
    RgComboboxExternalSchedulingProcedures,
    ModalEditExternallySchedule,
    ModalConfirmDeletion,
    ModalPatientImageInRow,
    ModalInformationPrint,
    SmartTable,
    RgEditButton,
    RgLessButton,
    RgPersonDropdown,
    RgPrinterButton,
  },

  props: {
    // Verify if is from Appointment or Exam
    isAppointment: {
      type: Boolean,
      default: false,
    },
    printHtml: Object,
  },

  data() {
    return {
      form: this.$utils.obj.DeepCopy(FORM_BASE),
      mutableList: [],
      tableHeight: null,
      totalList: 0,
      selectedRow: null,
      modalDeleteExternalSchedule: false,
      modalPatientImageInRow: false,
      modalEditExternallySchedule: false,
      modalInformationPrint: false,
    };
  },

  computed: {
    hasSelectedLine() {
      return this.selectedRow;
    },

    permissionSuggest() {
      return this.isAppointment ? ["appointment"] : ["exame2"];
    },

    propsModalDeleteExternalSchedule() {
      const title = "Excluir Agendamento Externo";
      const message = "A operação não poderá ser desfeita";
      const bodyText = this.selectedRow && this.selectedRow.pes_nome;
      const show = this.modalDeleteExternalSchedule;
      const noReason = true;
      const confirm = () => this.deleteExternalSchedule();

      return {
        show,
        confirm,
        title,
        message,
        bodyText,
        noReason,
      };
    },

    getSpaceTable() {
      return this.tableHeight;
    },

    moduleId() {
      return this.isAppointment
        ? this.$store.state.Login.route_module_map.appointment
        : this.$store.state.Login.route_module_map.exam;
    },
  },

  watch: {
    "form.pac_id"() {
      this.form.patientName = "";
    },

    "form.patientName"(pValue) {
      this.form.pac_id = 0;
      this.form.specificPatientName = "";
      this.form.specificPatient = null;
    },
  },

  created() {
    this.COLUMN_EXAM = [
      { name: "Paciente", key: "pes_nome", align: "left" },
      { name: "Telefone", key: "tel_num", align: "left" },
      { name: "Data", key: "aex_data" },
      { name: "Procedimento", key: "fle_nome_procedimento", align: "left" },
      { name: "Subprocedimento", key: "subProcedimento", align: "left" },
      { name: "Destino", key: "lex_nome", align: "left" },
      { name: "Usuário", key: "usu_nome", align: "left" },
    ];
    this.COLUMN_APPOINTMENT = [
      { name: "Paciente", key: "pes_nome", align: "left" },
      { name: "Telefone", key: "tel_num", align: "left" },
      { name: "Data", key: "aex_data" },
      { name: "Ocupação", key: "flc_nome_ocupacao", align: "left" },
      { name: "Destino", key: "lex_nome", align: "left" },
      { name: "Usuário", key: "usu_nome", align: "left" },
    ];
  },

  beforeDestroy() {
    const pathName = this.isAppointment ? "appointment" : "exam";
    const validateIfExist = ValidateIfRouteExistInBreadscrumb(
      `/${pathName}/schedule/externally-schedule`,
      this.$route.meta.breadcrumb,
    );

    if (!validateIfExist) {
      const moduleName = this.isAppointment ? "Appointment" : "Exam";
      this.$store.commit(
        `${moduleName}/Schedule/RESET_FILTER_EXTERNALLY_SCHEDULE`,
      );
    }
  },

  mounted() {
    this.fillFilter();
    this.handleAfterSearchFilter();
  },

  methods: {
    async fillFilter() {
      try {
        const moduleName = this.isAppointment ? "Appointment" : "Exam";

        const existFilterData = this.$store.getters[
          `${moduleName}/Schedule/GET_FILTER_EXTERNALLY_SCHEDULE`
        ];

        if (existFilterData) {
          this.$loader.start("Carregando dados da última busca...");

          this.form = {
            ...this.form,
            ...existFilterData,
          };

          if (this.$refs.patient && existFilterData.pes_id) {
            this.$refs.patient.fillPatientById(existFilterData.pes_id);
          } else {
            this.$nextTick(() => {
              this.form.patientName = existFilterData.patientName;
            });
          }

          if (this.$refs.SmartTable) {
            this.$refs.SmartTable.cleanSelectRow();
          }
          await this.$refs.RgSearch.performSearch();
          this.$loader.finish();
        }
      } catch (Err) {
        this.$toaster.warning("Erro ao carregar os dados da navegação", Err);
        this.$loader.finish();
      }
    },

    actionAfterSearchFilter() {
      this.handleAfterSearchFilter();
    },

    generateFilter() {
      const variables = {
        arrFiltro: {
          aex_data: 0,
          aex_id: 0,
          itl_id: 0,
          lex_id: this.form.aex_id,
          lex_nome: null,
          pac_nome: this.form.specificPatientName || this.form.patientName,
          periodoFinal: this.form.finalDate,
          periodoInicial: this.form.initialDate,
          soc_id: this.form.soc_id,
          stp_nome: this.form.procedure,
          usu_nome: null,
          mod_id: this.moduleId,
          id_unidade_solicitante: this.form.requestingUnit,
        },
      };

      const moduleName = this.isAppointment ? "Appointment" : "Exam";

      this.$store.commit(
        `${moduleName}/Schedule/SET_FILTER_EXTERNALLY_SCHEDULE`,
        this.form,
      );

      return variables;
    },

    getCountValue(pValue) {
      this.totalList = Number(pValue) || 0;
    },

    selectRowData(data) {
      this.selectedRow = data;
    },

    openModalEditExternallySchedule() {
      this.modalEditExternallySchedule = true;
    },

    closeModalEditExternallySchedule() {
      this.modalEditExternallySchedule = false;
    },

    openModalPatientImageInRow() {
      this.modalPatientImageInRow = true;
    },

    closeModalPatientImageInRow() {
      this.modalPatientImageInRow = false;
    },

    reSearchTable() {
      this.$refs.RgSearch.submitForm(true);
    },

    actionSearchFilter(pData) {
      try {
        this.$loader.start("Buscando...");
        const modules = this.isAppointment ? "Appointment" : "Exam";
        const path = `${modules}/Schedule/SEARCH_EXTERNALLY_SCHEDULE`;

        return this.$store.dispatch(path, pData);
      } catch (e) {
        this.$toaster.warning(e.message);
      } finally {
        this.$loader.finish();
      }
    },

    async printPatientsList() {
      try {
        if (this.totalList > 1000) {
          this.modalInformationPrint = true;
          return;
        }

        const clientName = this.$store.getters["Login/GET_CLIENT_NAME"];

        this.$loader.start("Preparando impressão...");
        const html = await this.printHtml.patientsList(
          this.generateFilter(),
          clientName,
        );
        this.$utils.print.printHtml(html);
      } catch (error) {
        this.$toaster.error(error.message);
      } finally {
        this.$loader.finish();
      }
    },

    getPayloadFilter(filter) {
      return {
        aex_data: 0,
        aex_id: 0,
        itl_id: 0,
        lex_id: this.form.aex_id,
        lex_nome: null,
        pac_nome: this.form.specificPatientName || this.form.patientName,
        periodoFinal: this.form.finalDate,
        periodoInicial: this.form.initialDate,
        soc_id: this.form.soc_id,
        stp_nome: this.form.procedure,
        usu_nome: null,
        mod_id: this.moduleId,
      };
    },

    async deleteExternalSchedule() {
      try {
        this.$loader.start();

        const variables = {
          intIdAgendamento: this.selectedRow.aex_id,
          idModulo: this.moduleId,
        };

        const modules = this.isAppointment ? "Appointment" : "Exam";
        const path = `${modules}/Schedule/REMOVE_EXTERNALLY_SCHEDULE`;

        await this.$store.dispatch(path, variables);

        this.$toaster.success("Agendamento externo excluído com sucesso!");
      } catch (pErr) {
        const error = pErr.toString();

        if (error.includes("Error:")) {
          this.$toaster.error(error.substring(6, error.length));
        } else {
          this.$toaster.error(pErr, "Erro ao excluir o agendamento externo");
        }
      } finally {
        this.$loader.finish();
      }
    },

    observationQueue() {
      const patientData = this.selectedRow;
      const appointmentsData = this.selectedRow;

      if (this.isAppointment) {
        this.$router.push({
          name:
            "appointment.schedule.externally-schedule.patient-observation-queue",
          params: { appointmentsData },
        });
      } else {
        this.$router.push({
          name: "exam.schedule.externally-schedule.patient-observation-queue",
          params: { patientData },
        });
      }
    },

    itemActionOptionsPerson(pItem) {
      return [
        {
          label: "Exibir observações do paciente na fila de regulação",
          action: this.observationQueue,
        },
        {
          label: "Exibir imagens do paciente na fila de regulação",
          action: this.openModalPatientImageInRow,
        },
      ];
    },

    openModalDeleteExternalSchedule() {
      this.modalDeleteExternalSchedule = true;
    },

    closeModalDeleteExternalSchedule() {
      this.modalDeleteExternalSchedule = false;
    },

    closeModalInformationPrint() {
      this.modalInformationPrint = false;
    },

    actionCleanForm() {
      this.mutableList = [];
      this.totalList = 0;
      this.form = this.$utils.obj.DeepCopy(FORM_BASE);
      if (this.$refs.SmartTable) {
        this.$refs.SmartTable.cleanSelectRow();
      }
      this.selectedRow = null;
      const modules = this.isAppointment ? "Appointment" : "Exam";
      this.$store.commit(
        `${modules}/Schedule/RESET_FILTER_EXTERNALLY_SCHEDULE`,
      );
    },

    validateDate(value, errors) {
      if (this.form.initialDate && this.form.finalDate) {
        const initial = moment(this.form.initialDate, "DD/MM/YYYY");
        const final = moment(this.form.finalDate, "DD/MM/YYYY");

        const isValidInitialValidity = moment(initial).isSameOrBefore(final);
        if (!isValidInitialValidity) {
          errors.push("A data inicial não pode ser maior que a data final.");
          return false;
        }
      }
      return true;
    },

    handleAfterSearchFilter() {
      // GET DYNAMIC HEIGHST
      if (this.$refs.RgSearch?.$refs.listContent) {
        const searchArea = this.$refs.RgSearch.$refs.listContent.offsetHeight;
        this.tableHeight = searchArea - 40;

        if (this.$refs.smartTable) this.$refs.smartTable.cleanSelectRow();
        this.selectedLine = null;
        this.selectedRow = null;
      }
    },

    selectingPatient(pValue) {
      if (pValue && pValue.source && pValue.source.patient) {
        const { pes_id, pes_nome } = pValue.source;
        const { pac_id } = pValue.source.patient;
        this.form.pac_id = pac_id;
        this.form.pes_id = pes_id;
        this.form.specificPatientName = pes_nome;
      } else {
        this.form.pes_id = 0;
        this.form.pac_id = 0;
        this.form.specificPatientName = "";
      }
    },
  },
};
</script>
