<template lang="html">
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M310.7,260.177l-38.202-14.735l-21.927-21.926c-1.426-1.426-3.292-2.158-5.173-2.191v-17.918
          c10.377-11.025,17.75-25.021,20.668-40.801c0.006-0.007,0.013-0.015,0.02-0.021c1.91,0.113,3.838-0.027,5.744-0.417
          c10.306-2.11,17.787-11.678,17.787-22.751v-5.743c0-6.491-2.668-12.44-6.996-16.653c7.033-37.871,1.355-68.717-16.492-89.326
          C248.923,7.826,221.183-1.664,185.905,0.239c-32.856,1.776-53.958,16.993-60.433,22.358c-9.112,1.184-35.099,7.751-35.099,44.089
          c0,20.121,3.269,36.855,5.565,46.131c-7.733,3.615-12.934,11.686-12.934,20.942v5.657c0,11.073,7.48,20.641,17.788,22.751
          c1.904,0.39,3.835,0.53,5.743,0.417c0.007,0.007,0.014,0.015,0.02,0.022c2.918,15.779,10.291,29.775,20.668,40.8v17.918
          c-1.881,0.033-3.747,0.765-5.173,2.191l-21.926,21.926l-42.969,16.574c-20.664,7.97-33.951,28.193-33.065,50.323l6.103,152.379
          c0.162,4.037,3.485,7.2,7.489,7.2c0.102,0,0.204-0.002,0.306-0.006c4.139-0.166,7.359-3.655,7.194-7.794L39.079,311.74
          c-0.162-4.05,0.349-8.009,1.442-11.754l24.362,24.362c6.935,6.935,10.754,16.156,10.754,25.963v114.108c0,4.142,3.358,7.5,7.5,7.5
          s7.5-3.358,7.5-7.5V350.311c0-13.814-5.379-26.802-15.148-36.57L48,286.252c3.852-4.455,8.797-8.019,14.554-10.24l40.091-15.464
          l30.606,45.908c3.71,5.566,9.656,9.136,16.313,9.795c0.741,0.073,1.48,0.109,2.216,0.109c4.091,0,8.051-1.145,11.505-3.247
          l-6.573,150.979c-0.18,4.138,3.028,7.639,7.167,7.819c0.111,0.005,0.222,0.007,0.332,0.007c3.992,0,7.312-3.146,7.487-7.174
          l6.095-139.989h17.043l6.095,139.989c0.175,4.027,3.495,7.174,7.487,7.174c0.11,0,0.221-0.002,0.332-0.007
          c4.138-0.18,7.347-3.681,7.167-7.819l-6.573-150.979c3.454,2.102,7.413,3.247,11.504,3.247c0.735,0,1.475-0.036,2.215-0.109
          c6.651-0.657,12.597-4.228,16.308-9.794l30.606-45.908l35.323,13.625c0.888,0.342,1.8,0.504,2.698,0.504
          c3.01,0,5.851-1.826,6.999-4.803C316.489,266.01,314.564,261.669,310.7,260.177z M121.289,159.797
          c-1.356-7.377-8.192-12.728-15.553-12.192c-0.632,0.045-1.284,0.001-1.937-0.132c-3.304-0.676-5.795-4.14-5.795-8.056v-5.657
          c0-3.832,2.579-7.286,5.871-7.864c2.771-0.486,5.424,0.532,7.106,2.725c1.397,1.821,3.259,3.123,5.39,3.767
          c3.113,0.939,6.549,0.388,9.19-1.477c6.096-4.303,11.263-9.761,15.356-16.221c2.217-3.499,1.178-8.133-2.32-10.35
          c-3.501-2.219-8.133-1.178-10.35,2.32c-2.334,3.683-5.126,6.919-8.329,9.656c-2.552-2.248-5.568-3.857-8.808-4.744
          c-2.009-7.565-5.737-24.366-5.737-44.887c0-12.215,3.786-20.805,11.254-25.528c5.813-3.678,11.951-3.819,11.953-3.82
          c1.918,0,3.784-0.755,5.177-2.073c0.192-0.183,19.642-18.247,52.957-20.047c30.478-1.651,54.017,6.063,68.074,22.296
          c14.24,16.445,18.933,41.712,13.692,73.323c-2.254-0.105-4.753,0.059-7.238,0.495l-7.516-18.051
          c-1.439-3.456-5.208-5.321-8.828-4.371c-0.732,0.192-73.627,18.791-104.363-19.472c-2.593-3.229-7.314-3.745-10.544-1.15
          c-3.229,2.594-3.745,7.314-1.15,10.544c31.177,38.815,93.263,30.113,113.46,26.035l5.842,14.03
          c2.389,5.736,8.499,8.869,14.526,7.449c2.363-0.557,4.934-0.658,6.056-0.435c3.358,0.668,5.89,4.005,5.89,7.763v5.743
          c0,3.917-2.492,7.38-5.794,8.056c-0.655,0.134-1.305,0.178-1.938,0.132c-7.358-0.538-14.198,4.815-15.552,12.193
          c-5.764,31.378-33.109,54.152-65.021,54.152C154.397,213.949,127.052,191.175,121.289,159.797z M156.872,299.24
          c-1.541,1.54-3.668,2.301-5.834,2.085c-2.167-0.214-4.102-1.376-5.31-3.188l-30.834-46.252l12.8-12.8l47.644,41.688
          L156.872,299.24z M178.441,309.756l0.495-11.368l7.373-7.374l7.374,7.374l0.495,11.368H178.441z M186.309,270.442l-44.088-38.577
          v-15.947c12.772,8.276,27.951,13.032,44.088,13.032s31.316-4.756,44.087-13.032v15.947L186.309,270.442z M226.892,298.136
          c-1.209,1.813-3.144,2.975-5.311,3.189c-1.274,0.129-3.724,0.022-5.832-2.085l-18.466-18.467l47.643-41.688l12.8,12.8
          L226.892,298.136z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M487.951,352.193c0-17.396-14.152-31.548-31.548-31.548H407.79v-16.548c0-12.976-10.557-23.532-23.532-23.532H336.16
          c-12.976,0-23.532,10.557-23.532,23.532v16.548h-48.613c-17.396,0-31.548,14.152-31.548,31.548v22.267
          c0,8.209,2.764,16.092,8.016,23.382v83.424c0,16.946,13.787,30.733,30.733,30.733h177.987c16.946,0,30.733-13.787,30.733-30.733
          v-83.424c5.252-7.291,8.016-15.174,8.016-23.383L487.951,352.193L487.951,352.193z M327.627,304.096L327.627,304.096
          c0.001-4.705,3.829-8.532,8.533-8.532h48.097c4.705,0,8.532,3.828,8.532,8.532v16.548h-65.162V304.096z M472.95,374.461
          c0,12.874-12.182,25.641-33.097,35.178v-1.332c0-4.142-3.358-7.5-7.5-7.5h-32.064c-4.142,0-7.5,3.358-7.5,7.5v24.048
          c0,12.976,10.557,23.532,23.532,23.532s23.532-10.557,23.532-23.532v-6.414c9.738-3.896,18.145-8.43,25.081-13.467v68.793
          c0.001,8.675-7.056,15.732-15.732,15.732H271.216c-8.675,0-15.733-7.058-15.733-15.733v-68.793
          c6.936,5.037,15.342,9.571,25.081,13.468v6.414c0,12.976,10.557,23.532,23.532,23.532c11.145,0,20.499-7.791,22.918-18.211
          c10.769,1.439,21.895,2.179,33.195,2.179c2.74,0,5.517-0.044,8.253-0.131c4.14-0.131,7.39-3.594,7.258-7.734
          c-0.131-4.14-3.564-7.401-7.734-7.258c-2.579,0.082-5.195,0.123-7.778,0.123c-11.119,0-22.046-0.757-32.581-2.232v-14.316
          c0-4.142-3.358-7.5-7.5-7.5h-32.064c-4.142,0-7.5,3.358-7.5,7.5v1.332c-20.915-9.537-33.097-22.305-33.097-35.178v-22.267
          c0-9.125,7.423-16.548,16.548-16.548h192.388c9.125,0,16.548,7.423,16.548,16.548V374.461z M424.855,415.806v16.548
          c0,4.705-3.828,8.532-8.532,8.532s-8.532-3.828-8.532-8.532v-16.548H424.855z M295.563,432.354v-16.548h17.064v16.548
          c0,4.705-3.828,8.532-8.532,8.532S295.563,437.059,295.563,432.354z"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "Employee",
};
</script>
