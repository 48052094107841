<template lang="html">
  <svg
    width="32"
    height="53"
    viewBox="0 0 32 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.7259 36.6512C10.7259 33.3268 11.1284 30.6792 11.9334 28.7083C12.7384 26.7375 14.2064 24.8022 16.3374 22.9026C18.492 20.9792 19.9245 19.4238 20.6349 18.2366C21.3452 17.0255 21.7003 15.7552 21.7003 14.4254C21.7003 10.4124 19.8535 8.40591 16.1598 8.40591C14.4077 8.40591 12.9989 8.95206 11.9334 10.0444C10.8916 11.1129 10.347 12.597 10.2997 14.4966H0C0.0473548 9.96125 1.50351 6.41129 4.36848 3.84677C7.25712 1.28226 11.1876 0 16.1598 0C21.1794 0 25.0744 1.22289 27.8446 3.66868C30.6149 6.09073 32 9.52195 32 13.9624C32 15.9807 31.5501 17.8923 30.6504 19.6969C29.7506 21.4778 28.1761 23.4606 25.9267 25.6452L23.0499 28.3878C21.2505 30.1212 20.2205 32.1514 19.96 34.4785L19.818 36.6512H10.7259ZM9.69589 47.586C9.69589 45.9951 10.2286 44.6891 11.2941 43.668C12.3833 42.6232 13.7684 42.1008 15.4495 42.1008C17.1306 42.1008 18.5039 42.6232 19.5694 43.668C20.6585 44.6891 21.2031 45.9951 21.2031 47.586C21.2031 49.1532 20.6704 50.4474 19.6049 51.4684C18.5631 52.4895 17.178 53 15.4495 53C13.7211 53 12.3241 52.4895 11.2586 51.4684C10.2168 50.4474 9.69589 49.1532 9.69589 47.586Z"
      fill="#ABABAB"
    />
  </svg>
</template>

<script>
export default {
  name: "PersonUndefined",
};
</script>
