<style lang="scss" scoped></style>

<template>
  <div class="admission-filter">
    <div class="grid">
      <!-- UNIDADE DE SAÚDE -->
      <div class="selectinput">
        <RgComboboxUnithealth
          v-model="form.selectUnity"
          class="inputitem"
          :default-text="'Todas'"
        />
      </div>

      <!-- SETOR -->
      <div class="selectinput">
        <RgComboboxSector
          v-model="form.selectedSector"
          :disabled="disableSector"
          :class="{ 'disable unselect': disableSector }"
          :permission="SECTOR_PERMISSION"
          :unit-health="form.selectUnity"
          :default-text="validateSectorOptions"
          class="inputitem"
        />
      </div>

      <!-- LOCAL DE ATENDIMENTO -->
      <div class="selectinput">
        <RgComboboxPlace
          v-model="form.selectedLocale"
          :disabled="disableLocale"
          :class="{ 'disable unselect': disableLocale }"
          :sector="form.selectedSector"
          :default-text="'Todos'"
          :empty-default-text="'Nenhum local disponível'"
          class="inputitem"
        />
      </div>

      <!-- PERÍODO DE ENTRADA -->
      <div class="selectinput">
        <span class="filter-subtitle">Período de Entrada</span>
        <div class="status">
          <div class="date">
            <RgInputDate
              v-model="form.entryInitialDate"
              label="Data Inicial"
              placeholder="Informe a data inicial"
              class="date"
            />
            <RgInputDate
              v-model="form.entryFinalDate"
              label="Data Final"
              placeholder="Informe a data final"
              class="date"
            />
          </div>
        </div>
      </div>

      <!-- TIPO DE LEITO -->
      <div class="selectinput">
        <RgSuggestTypeBed
          v-model="form.bedType"
          class="inputitem"
          @selected="selectingBedInfo"
        />
      </div>

      <!-- SEXO -->
      <div class="selectinput">
        <RgRadioGenderBlock
          ref="gender"
          v-model="form.genders"
          class="inputitem"
          is-checkbox
          label="Sexo"
        />
      </div>

      <!-- IDADE -->
      <div class="selectinput">
        <RgInputNumber v-model="form.age" label="Idade" class="inputitem" />
      </div>

      <!-- CID PRINCIPAL -->
      <div class="selectinput">
        <RgSuggestCid
          ref="mainCid"
          v-model="form.mainCid"
          label="CID Principal"
          class="inputitem"
          @selected="selectingCidCode"
        />
      </div>

      <!-- PROCEDIMENTO DA INTERNAÇÃO -->
      <div class="selectinput">
        <RgSuggestProcedure
          ref="inputProcedure"
          v-model="form.procedure"
          label="Procedimento"
          class="inputitem"
        />
      </div>

      <!-- TIPOS DE INTERNAÇÃO -->
      <div class="selectinput">
        <RgComboboxHospitalizationType
          v-model="form.hospitalizationType"
          class="inputitem"
        />
      </div>

      <!-- CARÁTER DE INTERNAÇÃO -->
      <div class="selectinput">
        <RgComboboxHospitalizationServiceCharacter
          v-model="form.hospitalizationServiceCharacter"
          label="Carater de Internação:"
          class="inputitem"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  RgComboboxUnithealth,
  RgComboboxSector,
  RgComboboxPlace,
  RgInputDate,
  RgInputNumber,
  RgSuggestCid,
  RgSuggestProcedure,
} from "~tokio/primitive";
import {
  RgSuggestTypeBed,
  RgComboboxHospitalizationType,
  RgComboboxHospitalizationServiceCharacter,
} from "$hospitalization/common/component";

import RgRadioGenderBlock from "$person/common/components/radio/rg-radio-gender-block/RgRadioGenderBlock";

export default {
  name: "Admission",
  components: {
    RgComboboxUnithealth,
    RgComboboxSector,
    RgComboboxPlace,
    RgSuggestTypeBed,
    RgInputDate,
    RgRadioGenderBlock,
    RgInputNumber,
    RgSuggestCid,
    RgSuggestProcedure,
    RgComboboxHospitalizationType,
    RgComboboxHospitalizationServiceCharacter,
  },
  data() {
    return {
      resultTitle: "Entradas Internação",
      form: {
        selectUnity: 0,
        selectedSector: null,
        selectedLocale: null,
        entryInitialDate: null,
        entryFinalDate: null,
        bedType: null,
        bedTypeId: null,
        genders: null,
        age: null,
        mainCid: null,
        mainCidId: null,
        procedure: null,
        hospitalizationType: null,
        hospitalizationServiceCharacter: null,
      },
    };
  },
  computed: {
    printReportCss() {
      return ``;
    },
    getNameSmartTable() {
      return "Admission";
    },
    columnTable() {
      return this.COLUMN;
    },
    disableSector() {
      return !this.form.selectUnity;
    },
    disableLocale() {
      return !this.form.selectedSector;
    },
    validateSectorOptions() {
      return this.form.selectUnity !== false
        ? "Todos"
        : "Nenhum setor disponível";
    },
    validateBedOptions() {
      return this.form.selectedLocale
        ? "Informe o leito"
        : "Nenhum leito disponível";
    },
    localId() {
      const isValidLocalId = this.form.selectedLocale > 0;
      return isValidLocalId ? this.form.selectedLocale : 0;
    },
  },
  watch: {
    "form.selectUnity"(pValue, pPrev) {
      this.form.bedType = null;

      if (!pValue || pValue !== pPrev) {
        this.form.selectedSector = null;
        this.form.selectedLocale = null;
      }
    },
    "form.selectedSector"(pValue, pPrev) {
      this.form.bedType = null;
      if (!pValue || pValue !== pPrev) {
        this.form.selectedLocale = null;
      }

      if (this.form.selectUnity === false) {
        this.form.selectedSector = false;
      }
    },
    "form.selectedLocale"(pValue, pPrev) {
      this.form.bedType = null;
      if (!pValue || pValue !== pPrev) {
      }
    },
  },
  mounted() {
    this.form.selectUnity = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
  },
  created() {
    this.SECTOR_PERMISSION = 351;
    this.COLUMN = [
      { name: "Data", key: "int_data" },
      { name: "Hora entrada", key: "int_hora" },
      { name: "Prontuário", key: "pac_prontuario_unico" },
      { name: "Paciente", key: "nomePaciente" },
      { name: "Sexo", key: "sex_nome" },
      { name: "Idade", key: "idade" },
      { name: "Setor", key: "set_nome" },
      { name: "Local atendimento", key: "lca_nome" },
      { name: "Tipo de Leito", key: "itl_nome" },
      { name: "Leito", key: "inl_nome" },
      { name: "Profissional solicitante", key: "nomeFuncionario" },
      { name: "Ocupação", key: "ocp_nome" },
      { name: "Cid 10 internação", key: "cid_nome" },
      {
        name: "Procedimento solicitado",
        key: "inp_novo_nome_procedimento",
      },
      { name: "Tipo de internação", key: "iti_nome" },
      { name: "Caráter internação", key: "ica_nome" },
      { name: "Tempo internação", key: "tempoInternado" },
    ];
  },
  methods: {
    buildFilter() {
      const genders = this.prepareGender();

      const payLoad = {
        uns_id: this.form.selectUnity || null,
        sectorId: this.form.selectedSector || null,
        localeId: this.form.selectedLocale || null,
        entryInitialDate: this.form.entryInitialDate
          ? this.formatDate(this.form.entryInitialDate)
          : null,
        entryFinalDate: this.form.entryFinalDate
          ? this.formatDate(this.form.entryFinalDate)
          : null,
        bedTypeId: this.form.bedTypeId || null,
        gender: genders,
        age: Number(this.form.age) || null,
        cidId: this.form.mainCidId || null,
        procedureCode: this.form.procedure || null,
        hospitalizationTypeId: this.form.hospitalizationType || null,
        hospitalizationServiceCharacterId:
          this.form.hospitalizationServiceCharacter || null,
      };
      return payLoad;
    },
    async doSearch(pData) {
      try {
        return await this.$store.dispatch(
          "Hospitalization/Report/GET_ADMISSION_REPORT",
          pData,
        );
      } catch (error) {
        return false;
      }
    },
    clearFilter() {
      this.form = {
        selectUnity: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        selectedSector: null,
        selectedLocale: null,
        bedType: null,
        bedTypeId: null,
        entryInitialDate: null,
        entryFinalDate: null,
        genders: null,
        age: null,
        mainCid: null,
        mainCidId: null,
        procedure: null,
        hospitalizationType: null,
        hospitalizationServiceCharacter: null,
      };
    },
    formatDate(pDate) {
      return this.$utils.date.BrazilianDateToDatabase(pDate);
    },
    selectingCidCode(pCid) {
      const { source } = pCid;
      this.form.mainCidId = source.cid_id;
    },
    selectingBedInfo(pBedType) {
      const { source } = pBedType;
      this.form.bedTypeId = source.itl_id;
    },
    prepareGender() {
      const hasValue = this.form.genders !== "";

      return hasValue
        ? this.$utils.app.Genders.GetIdByInitials(this.form.genders)
        : null;
    },
  },
};
</script>
