<template lang="html">
  <fieldset class="rg-radio-date-type">
    <span class="label">Tipo de Data</span>
    <div class="container unselect">
      <div
        :class="{ 'active-entry': selectedStatus.includes(DATE_TYPE.ENTRADA) }"
        class="radio entry"
        @click="toggleStatus(DATE_TYPE.ENTRADA)"
      >
        Data de Entrada
      </div>
      <div
        :class="{ 'active-exit': selectedStatus.includes(DATE_TYPE.SAIDA) }"
        class="radio exit"
        @click="toggleStatus(DATE_TYPE.SAIDA)"
      >
        Data de Saída
      </div>
    </div>
  </fieldset>
</template>

<script>
import DATE_TYPE from "./getDateType";

export default {
  name: "RgRadioDateType",
  props: {
    value: {
      default: null,
    },
  },
  data() {
    return {
      selectedStatus: [],
    };
  },
  computed: {
    DATE_TYPE() {
      return { ...DATE_TYPE };
    },
  },
  watch: {
    value(pValue) {
      this.selectedStatus = pValue;
    },
  },
  mounted() {
    this.selectedStatus = this.value || [1, 2];
  },
  methods: {
    toggleStatus(pItem) {
      this.selectedStatus = [];
      this.selectedStatus.push(pItem);
      this.$emit("input", this.selectedStatus);
    },
  },
};
</script>
