import Store from "@/store";

let mappedObject = {};

const convertPathToObject = (pPath, pSector, pPermissions) => {
  let pathResult = false;
  const pathSplitted = `${pSector}.${pPath}`.split(".");
  pathSplitted.reduce((o, i) => {
    if (o && o[i]) {
      pathResult = true;
      return o[i];
    } else {
      pathResult = false;
      return false;
    }
  }, pPermissions);
  return pathResult;
};

const hasBySector = (pPath, pSector = null) => {
  let hasPermission = false;
  const permissionSectors = removeUnitHealthFromTree(mappedObject);
  if (pSector === null) {
    hasPermission = hasByGlobal(pPath);
  } else {
    hasPermission = convertPathToObject(pPath, pSector, permissionSectors);
  }
  return hasPermission;
};

const hasByGlobal = (
  pPath,
  pUnitHealth = Store.getters["Login/GET_UNIT_HEALTH_ID"],
) => {
  let hasPermission = false;

  if (pUnitHealth && mappedObject[pUnitHealth]) {
    const permissionSectors = mappedObject[pUnitHealth];
    for (const mappedSector in permissionSectors) {
      let resultSearch = false;
      if (Array.isArray(pPath)) {
        pPath.map((item) => {
          resultSearch =
            resultSearch ||
            convertPathToObject(item, mappedSector, permissionSectors);
        });
      } else {
        resultSearch = convertPathToObject(
          pPath,
          mappedSector,
          permissionSectors,
        );
      }
      hasPermission = hasPermission ? true : resultSearch === true;
    }
  }
  return hasPermission;
};

const removeUnitHealthFromTree = (mappedObject) => {
  let sectors = {};
  Object.keys(mappedObject).forEach((key) => {
    sectors = {
      ...sectors,
      ...mappedObject[key],
    };
  });
  return sectors;
};

const setPermissionsMap = (pPermissionMap) => {
  mappedObject = pPermissionMap;
};

const _createMap = (_Vue) => {
  const mappedMethods = {
    global: {
      has: hasByGlobal,
    },
    sector: {
      has: hasBySector,
    },
    getPermissions: () => {
      return { ...mappedObject };
    },
    setPermissionsMap,
  };
  _Vue.prototype.$Permissions = mappedMethods;
};

// Testing object
let testExport = {};
if (process.env.NODE_ENV === "testing") {
  testExport = {
    convertPathToObject,
    hasBySector,
    hasByGlobal,
    setPermissionsMap,
  };
}

const vueInstalled = (_Vue) => {
  _createMap(_Vue);
};
export { setPermissionsMap };
export const PermissionUnitTests = { ...testExport };
export const PermissionHasByGlobal = hasByGlobal;
export default function installed(_Vue) {
  vueInstalled(_Vue);
  return true;
}
