import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  const sendData = {
    arrFormData: variables,
  };
  return AuthLegacyRequest.post(
    "tabelas/controller-tabela-unificada/exportar-para-tipo-consulta-unidade",
    sendData,
  )
    .then(({ data }) => {})
    .catch((pErr) => {
      throw pErr;
    });
};
