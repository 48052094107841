import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation RemovePersonDocument($pes_id: Int!, $doc_id: Int!) {
    RemovePersonDocument(pes_id: $pes_id, doc_id: $doc_id) {
      pes_id
    }
  }
`;

export default async ({ commit }, { pes_id, doc_id }) => {
  const variables = { pes_id, doc_id };
  try {
    const { data } = await GraphQLClient.mutate({ mutation, variables });
    return data.RemovePersonDocument;
  } catch (err) {
    const jsonResponse = JSON.parse(JSON.stringify(err));
    return Promise.reject(jsonResponse.graphQLErrors[0].message);
  }
};
