<style src="./RgSurgeryRequestingClinicList.scss" lang="scss" scoped></style>
<template lang="html">
  <div>
    <section class="form-list">
      <div class="requesting-list">
        <ul>
          <li>
            <ul class="form-list--container-header row">
              <li class="form-list--employee col-7">Clínica</li>
              <li class="form-list--remove col-1" />
            </ul>
          </li>
          <li
            v-for="(item, i) in list"
            :key="i"
            class="form-list--container-row"
            @click="selectItem(item)"
          >
            <ul class="form-list--container-content row">
              <li class="form-list--employee col-11">{{ item.text }}</li>
              <li class="form-list--remove col-1">
                <RgRemoveSmall @confirm-remove="removeItem(item)" />
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
import { RgRemoveSmall } from "~tokio/primitive";
export default {
  name: "RgSurgeryRequestingClinicList",
  components: { RgRemoveSmall },
  props: {
    list: {
      default: "",
    },
  },
  mounted() {},
  methods: {
    removeItem(item) {
      this.$emit("remove", item);
    },
    selectItem(item) {
      this.$emit("selected", item);
    },
  },
};
</script>
