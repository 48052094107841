import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  const sendData = {
    arrFila: variables.arrQueue,
    strMotivo: variables.reason,
    strAcao: "EXCLUIR",
  };

  return AuthLegacyRequest.post(
    "/consultas/controller-fila-consulta/inativar-em-lote",
    sendData,
  )
    .then(({ data }) => {
      return data;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
