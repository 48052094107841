<style
  src="./RgContentHospitalizationBillingNeonatal.scss"
  lang="scss"
  scoped
></style>
<template lang="html">
  <div>
    <form class="form">
      <RgValidatorForm ref="validator">
        <RgTitle title="Neonatal" />
        <div class="row">
          <RgInputMasked
            v-model="neonatal.inn_numero_meses_gestacao"
            :rules="{ required: true }"
            class="col-2"
            label="Mês Gestacional:"
            mask="#"
          />
          <RgInputMasked
            v-model="neonatal.inn_peso_nascer"
            :rules="{ required: true }"
            class="col-2"
            label="Peso ao Nascer (g):"
            mask="####"
          />
          <RgComboboxNeonatalExits
            v-model="neonatal.inn_id_internacoes_saidas_neonatal"
            :rules="{ required: true }"
            label="Motivo de Saída:"
            class="col-4"
          />
        </div>

        <div class="row">
          <button type="button" class="btn btn-success col-2" @click="confirm">
            Confimar
          </button>
          <button class="col-2 btn btn-default" @click.stop.prevent="close">
            Fechar
          </button>
        </div>
      </RgValidatorForm>
    </form>
  </div>
</template>

<script>
import { RgValidatorForm, RgTitle, RgInputMasked } from "~tokio/primitive";
import RgComboboxNeonatalExits from "$billing/hospitalization-billing/common/component/combobox/rg-combobox-neonatal-exits/RgComboboxNeonatalExits";

import mask from "vue-the-mask";

export default {
  name: "RgContentHospitalizationBillingNeonatal",
  components: {
    RgValidatorForm,
    RgTitle,
    RgInputMasked,
    RgComboboxNeonatalExits,
  },
  directives: {
    mask,
  },
  props: {
    procedure: {
      default: "",
    },
    requiredDocuments: {},
  },
  data() {
    return {
      neonatal: {
        type: "neonatal",
        inn_id_internacoes_saidas_neonatal: "",
        inn_numero_meses_gestacao: "",
        inn_peso_nascer: "",
      },
    };
  },
  mounted() {
    if (this.procedure.additionalData[0]) {
      this.neonatal = this.procedure.additionalData[0];
    }
  },
  methods: {
    async confirm(event) {
      if (await this.validateForm()) {
        this.$emit("onFinish", this.neonatal);
      }
    },

    close() {
      this.$emit("close");
    },

    async validateForm() {
      const valid = await this.$refs.validator.validate();
      return valid;
    },
  },
};
</script>
