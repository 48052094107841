<template lang="html">
  <svg
    width="16"
    height="21"
    viewBox="0 0 16 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.875 4.2242V2.38161C15.3955 2.07814 15.75 1.52 15.75 0.874986V0.437514C15.75 0.195686 15.5543 0 15.3125 0H0.437514C0.195686 0 0 0.195686 0 0.437514V0.875027C0 1.52 0.354457 2.07814 0.874986 2.38165V4.2242C0.874986 5.95797 1.61413 7.61738 2.90272 8.77693L4.8172 10.5L2.90272 12.2231C1.61413 13.3826 0.874986 15.042 0.874986 16.7758V18.6183C0.354457 18.9219 0 19.48 0 20.125V20.5625C0 20.8043 0.195686 21 0.437514 21H15.3125C15.5544 21 15.75 20.8043 15.75 20.5625V20.125C15.75 19.48 15.3956 18.9219 14.8751 18.6183V16.7758C14.8751 15.042 14.1359 13.3826 12.8473 12.2231L10.9328 10.5L12.8473 8.77693C14.1359 7.61738 14.875 5.95793 14.875 4.2242ZM11.6767 7.47637L9.03968 9.84974C8.85511 10.0155 8.74999 10.2518 8.74999 10.5C8.74999 10.7482 8.85511 10.9845 9.03968 11.1503L11.6767 13.5236C12.5969 14.3521 13.125 15.5377 13.125 16.7758V18.375H12.031L8.2249 13.3002C8.05998 13.0797 7.68998 13.0797 7.52505 13.3002L3.71897 18.375H2.625V16.7758C2.625 15.5376 3.15308 14.352 4.07338 13.5236L6.71036 11.1502C6.89493 10.9844 7.00005 10.7482 7.00005 10.5C7.00005 10.2517 6.89493 10.0154 6.71036 9.8497L4.07338 7.47633C3.15308 6.64794 2.625 5.46234 2.625 4.2242V2.625H13.125V4.2242C13.125 5.46234 12.5969 6.64794 11.6767 7.47637Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "Hourglass",
};
</script>
