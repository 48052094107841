<template lang="html">
  <svg
    width="70"
    height="50"
    viewBox="0 0 70 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M48.2041 13.6365H30.0244C28.1445 13.6365 26.6152 12.1069 26.6152 10.2273V3.40918C26.6152 1.52954 28.1445 0 30.0244 0H48.2041H66.3877C68.2676 0 69.7969 1.52954 69.7969 3.40918V10.2273C69.7969 12.1069 68.2676 13.6365 66.3877 13.6365H48.2041ZM47.0674 11.3635H30.0244C29.3994 11.3635 28.8877 10.8545 28.8877 10.2273V3.40918C28.8877 2.78174 29.3994 2.27271 30.0244 2.27271H47.0674V11.3635ZM49.3398 11.3635H66.3877C67.0127 11.3635 67.5244 10.8545 67.5244 10.2273V3.40918C67.5244 2.78174 67.0127 2.27271 66.3877 2.27271H49.3398V11.3635ZM69.7969 46.5908C69.7969 48.4705 68.2676 50 66.3877 50H48.2041H30.0244C28.1445 50 26.6152 48.4705 26.6152 46.5908V37.5V28.4092C26.6152 26.5295 28.1445 25 30.0244 25H48.2041H66.3877C68.2676 25 69.7969 26.5295 69.7969 28.4092V37.5V46.5908ZM67.5244 36.3638V28.4092C67.5244 27.7817 67.0127 27.2727 66.3877 27.2727H49.3398V36.3638H67.5244ZM49.3398 38.6365H67.5244V46.5908C67.5244 47.2183 67.0127 47.7273 66.3877 47.7273H49.3398V38.6365ZM47.0674 36.3638V27.2727H30.0244C29.3994 27.2727 28.8877 27.7817 28.8877 28.4092V36.3638H47.0674ZM28.8877 38.6365H47.0674V47.7273H30.0244C29.8184 47.7273 29.625 47.6721 29.458 47.5754C29.335 47.5044 29.2266 47.4109 29.1377 47.3008C28.9814 47.1062 28.8877 46.8594 28.8877 46.5908V38.6365ZM12.75 9.25C7.08887 9.25 2.5 13.8391 2.5 19.5C2.5 25.1609 7.08887 29.75 12.75 29.75C18.4111 29.75 23 25.1609 23 19.5C23 13.8391 18.4111 9.25 12.75 9.25ZM0 19.5C0 12.4583 5.70801 6.75 12.75 6.75C19.792 6.75 25.5 12.4583 25.5 19.5C25.5 26.5417 19.792 32.25 12.75 32.25C5.70801 32.25 0 26.5417 0 19.5ZM6.44824 18.7556C6.32227 18.9749 6.25 19.229 6.25 19.5C6.25 20.3284 6.92188 21 7.75 21H17.75C18.5781 21 19.25 20.3284 19.25 19.5C19.25 18.6716 18.5781 18 17.75 18H7.75C7.19238 18 6.70605 18.3042 6.44824 18.7556Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: "ExcludeQueue",
};
</script>
