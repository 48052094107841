<template lang="html">
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g>
        <g>
          <path
            d="M444.875,109.792L338.208,3.125c-2-2-4.708-3.125-7.542-3.125h-224C83.146,0,64,19.135,64,42.667v426.667
				C64,492.865,83.146,512,106.667,512h298.667C428.854,512,448,492.865,448,469.333v-352
				C448,114.5,446.875,111.792,444.875,109.792z M341.333,36.417l70.25,70.25h-48.917c-11.771,0-21.333-9.573-21.333-21.333V36.417z
				M426.667,469.333c0,11.76-9.563,21.333-21.333,21.333H106.667c-11.771,0-21.333-9.573-21.333-21.333V42.667
				c0-11.76,9.563-21.333,21.333-21.333H320v64C320,108.865,339.146,128,362.667,128h64V469.333z"
          />
          <path
            d="M373.333,298.667H138.667c-5.896,0-10.667,4.771-10.667,10.667c0,5.896,4.771,10.667,10.667,10.667h234.667
				c5.896,0,10.667-4.771,10.667-10.667C384,303.438,379.229,298.667,373.333,298.667z"
          />
          <path
            d="M373.333,234.667H138.667c-5.896,0-10.667,4.771-10.667,10.667c0,5.896,4.771,10.667,10.667,10.667h234.667
				c5.896,0,10.667-4.771,10.667-10.667C384,239.438,379.229,234.667,373.333,234.667z"
          />
          <path
            d="M373.333,362.667H138.667c-5.896,0-10.667,4.771-10.667,10.667c0,5.896,4.771,10.667,10.667,10.667h234.667
				c5.896,0,10.667-4.771,10.667-10.667C384,367.438,379.229,362.667,373.333,362.667z"
          />
          <path
            d="M266.667,426.667h-128c-5.896,0-10.667,4.771-10.667,10.667c0,5.896,4.771,10.667,10.667,10.667h128
				c5.896,0,10.667-4.771,10.667-10.667C277.333,431.438,272.563,426.667,266.667,426.667z"
          />
          <path
            d="M234.667,181.333c0,5.896,4.771,10.667,10.667,10.667h128c5.896,0,10.667-4.771,10.667-10.667
				c0-5.896-4.771-10.667-10.667-10.667h-128C239.438,170.667,234.667,175.438,234.667,181.333z"
          />
          <path
            d="M160,170.667h-21.333c-5.896,0-10.667,4.771-10.667,10.667c0,5.896,4.771,10.667,10.667,10.667h10.667
				c0,5.896,4.771,10.667,10.667,10.667s10.667-4.771,10.667-10.667v-1.965C183.056,185.617,192,173.888,192,160
				c0-17.646-14.354-32-32-32c-5.875,0-10.667-4.781-10.667-10.667c0-5.885,4.792-10.667,10.667-10.667h21.333
				c5.896,0,10.667-4.771,10.667-10.667s-4.771-10.667-10.667-10.667h-10.667c0-5.896-4.771-10.667-10.667-10.667
				s-10.667,4.771-10.667,10.667v1.965C136.944,91.716,128,103.445,128,117.333c0,17.646,14.354,32,32,32
				c5.875,0,10.667,4.781,10.667,10.667S165.875,170.667,160,170.667z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "Billing",
};
</script>
