import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

/*
  intExameEscala => required|numeric
*/

export default async (context, variables) => {
  return AuthLegacyRequest.post(
    "exames2/controller-exame-escala/carregar",
    variables,
  )
    .then(({ data }) => {
      if (data?.dados) {
        return data.dados;
      }
      return null;
    })
    .catch((pErr) => {
      console.log("error", pErr);
      throw pErr;
    });
};
