<style src="./RgSuggestNationality.scss" lang="scss" scoped></style>
<template>
  <div class="rg-suggest-nationality">
    <RgSuggest
      ref="rgSuggest"
      v-model="inputValue"
      :data-id="dataId"
      :label="label"
      :placeholder="placeholder"
      :rules="rules"
      min="2"
    >
      <RgSuggestItem
        v-for="(item, idx) in suggestionList"
        :key="idx"
        :data-item="dataId"
        :idx="idx"
        :item="item"
        class="nat-item"
      >
        <div class="nat-row-top">
          <span class="nat-name">{{ item.nac_nome }}</span>
        </div>
      </RgSuggestItem>
    </RgSuggest>
  </div>
</template>
<script>
import { RgSuggest, RgSuggestMixin, RgSuggestItem } from "~tokio/primitive";
import { SearchNationality } from "./action";

export default {
  name: "RgSuggestNationality",
  components: {
    RgSuggest,
    RgSuggestItem,
  },
  mixins: [RgSuggestMixin],
  data() {
    return {
      captionRaw: "nac_nome",
    };
  },
  methods: {
    doSearch(pSearchTerm) {
      return SearchNationality({ nacNome: pSearchTerm });
    },
  },
};
</script>
