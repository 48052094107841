import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation($period: PeriodHospitalizationBillingType) {
    CreatePeriodHospitalizationBilling(period: $period) {
      cft_id
      cft_id_unidades_saudes
      cft_id_usuarios
      cft_competencia
      cft_data_hora
    }
  }
`;

export default async ({ rootState }, { pPeriod }) => {
  const variables = {
    period: pPeriod,
  };
  try {
    const { data } = await GraphQLClient.mutate({
      mutation,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.CreatePeriodHospitalizationBilling;
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors);
  }
};
