import IconFacebook from "./Facebook";
import IconGooglePlus from "./GooglePlus";
import IconLinkedin from "./Linkedin";
import IconMeuEsus from "./MeuEsus";
import IconPdf from "./Pdf";
import IconTwitter from "./Twitter";

export {
  IconFacebook,
  IconGooglePlus,
  IconLinkedin,
  IconMeuEsus,
  IconPdf,
  IconTwitter,
};
