module.exports = `
pes_id
pes_nome
pes_nascimento
pes_mae
cns {
  crs_numero
}
pisPasep {
  pis_numero
}
cpf {
  cpf_numero
}
rg {
  rgs_numero
}
newBirthCertificate {
  ctn_numero
}
gender {
  sex_sigla
}
nationality {
  nac_codigo
}
address {
  publicPlaceType {
    tlg_codigo
  }
  end_logradouro
  end_numero
  end_complemento
  end_cep
  neighborhood {
    bai_nome
    city {
      mun_codigo
      state {
        est_sigla
      }
    }
  }
}
telephones {
  rows {
    tel_numero
    ddd {
      ddd_codigo
    }
  }
}
ethnicity {
  etn_codigo_sisaih
}
`;
