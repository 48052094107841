module.exports = {
  checkPhone(pValue, pErrors) {
    const format = /^\(\d{2}\) \d{4,5}-\d{4}$/gi;

    if (!format.test(pValue)) {
      pErrors.push("Telefone inválido");
      return false;
    }
    return true;
  },
};
