import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchAdmissionReport(
    $uns_id: Int
    $sectorId: Int
    $localeId: Int
    $entryInitialDate: String
    $entryFinalDate: String
    $bedTypeId: Int
    $gender: Int
    $age: Int
    $cidId: Int
    $procedureCode: String
    $hospitalizationTypeId: Int
    $hospitalizationServiceCharacterId: Int
    $toPrint: Boolean
    $toCsv: Boolean
    $toExcel: Boolean
    $limit: Int
    $offset: Int
  ) {
    SearchAdmissionReport(
      uns_id: $uns_id
      sectorId: $sectorId
      localeId: $localeId
      entryInitialDate: $entryInitialDate
      entryFinalDate: $entryFinalDate
      bedTypeId: $bedTypeId
      gender: $gender
      age: $age
      cidId: $cidId
      procedureCode: $procedureCode
      hospitalizationTypeId: $hospitalizationTypeId
      hospitalizationServiceCharacterId: $hospitalizationServiceCharacterId
      toPrint: $toPrint
      toCsv: $toCsv
      toExcel: $toExcel
      limit: $limit
      offset: $offset
    ) {
      html
      count
      csv
      excel
      fileName
      rows {
        int_data
        int_hora
        pac_prontuario_unico
        nomePaciente
        sex_nome
        idade
        set_nome
        lca_nome
        nomeFuncionario
        inl_nome
        itl_nome
        ocp_nome
        inp_novo_nome_procedimento
        iti_nome
        ica_nome
        cid_nome
        tempoInternado
      }
    }
  }
`;

export default async (
  { commit },
  {
    uns_id,
    sectorId,
    localeId,
    entryInitialDate,
    entryFinalDate,
    bedTypeId,
    gender,
    age,
    cidId,
    procedureCode,
    hospitalizationTypeId,
    hospitalizationServiceCharacterId,
    toPrint,
    toCsv,
    toExcel,
    limit,
    offset,
  },
) => {
  try {
    const variables = {
      uns_id,
      sectorId,
      localeId,
      entryInitialDate,
      entryFinalDate,
      bedTypeId,
      gender,
      age,
      cidId,
      procedureCode,
      hospitalizationTypeId,
      hospitalizationServiceCharacterId,
      toPrint,
      toCsv,
      toExcel,
      limit,
      offset,
    };
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.SearchAdmissionReport;
  } catch (err) {
    return err.graphQLErrors;
  }
};
