<style src="./ModuleList.scss" lang="scss" scoped></style>
<template>
  <section class="module-list">
    <div
      v-for="(module, idx) in filteredModules"
      :key="idx"
      :class="[
        { 'menu-button--selected': idx === selectedModuleId },
        `menu-module-${module.name.toLowerCase().replace(' ', '-')}`,
      ]"
      :title="
        module.active === false
          ? 'Não implementado ou desativado temporariamente'
          : module.name
      "
      :data-modulename="module.className"
      class="menu-button menu-button--large-size"
      @click="selectingItem(idx)"
    >
      <component :is="module.iconName" class="icon" />
      <span class="module-name">
        {{ module.name }}
      </span>

      <div v-if="!module.link" class="have-children">
        <IconEllipsis class="symbol" />
      </div>
    </div>
  </section>
</template>

<script>
import { GetModulesFromJson } from "../menu-map/GetModulesFromJson";
import * as Icons from "~tokio/primitive/icon/modules";
import { IconEllipsis } from "~tokio/primitive";

export default {
  name: "ModuleList",
  components: {
    ...Icons,
    IconEllipsis,
  },
  data() {
    return {
      modules: [],
    };
  },
  computed: {
    selected() {
      return this.$store.state.MainMenu.selectedModule;
    },
    selectedModuleId() {
      return this.selected ? this.selected.id : null;
    },
    filteredModules() {
      const filteredModules = {};
      Object.keys(this.modules).forEach((key) => {
        const item = this.modules[key];
        if (item.active !== false && this.hasPermission(item.permission))
          filteredModules[key] = item;
      });
      return filteredModules;
    },
  },
  mounted() {
    this.loadModules();
  },
  methods: {
    loadModules() {
      try {
        const Modules = GetModulesFromJson();
        this.modules = Modules;
      } catch (error) {
        this.$toaster.error(
          "Falha ao carregar os modulos no Menu! sua pagina será recarregada em instantes, caso o problema persista, procure o suporte",
        );
        setTimeout(() => {
          this.$emit("error", error);
          window.location.reload();
        }, 5000);
      }
    },
    selectingItem(pItemIdx) {
      this.$emit("change", { id: pItemIdx, ...this.modules[pItemIdx] });
    },
    hasPermission(pPermission) {
      return this.$Permissions.global.has(pPermission);
    },
  },
};
</script>
