<style src="./UserDetail.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="user-details">
    <Modulebox
      :granted="hasPermission"
      class="module-filter"
      title="Novo Usuário"
    >
      <form @submit.prevent="saveUser">
        <RgValidatorForm ref="validator" class="row">
          <!-- TODO: Colocar campos/componentes -->
          <div class="col-12">
            <div class="row">
              <div class="col-4">
                <RgComboboxUnithealth
                  v-model="form.usu_id_unidades_saude_padrao"
                  label="Unidade de Saúde Padrão"
                />
              </div>
              <div class="col-4">
                <RgComboboxSector
                  ref="Sector"
                  v-model="form.usu_id_setor_padrao"
                  :rules="{ required: true }"
                  :unit-health="form.usu_id_unidades_saude_padrao"
                  label="Setor"
                />
              </div>
              <div class="col-4">
                <RgComboboxUserGroup
                  v-model="form.usu_id_grupos_usuario"
                  :rules="{ required: true }"
                  label="Grupo"
                />
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col-12">
                <RgSuggestCity
                  ref="mainCity"
                  v-model="citySuggest"
                  :rules="{ required: true }"
                  label="Cidade:"
                  @selected="citySelected"
                />
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col-4">
                <RgInput
                  v-model="form.usu_nome"
                  :rules="{ required: true }"
                  label="Nome"
                />
              </div>
              <div class="col-4">
                <RgInputPassword
                  ref="Password"
                  v-model="form.usu_senha"
                  :is-auto-complete="true"
                  :eye-style="{ 'margin-right': '20px' }"
                  :rules="{ fn: validatePassword }"
                  label="Senha"
                />
              </div>
              <div class="col-4">
                <RgInputPassword
                  ref="ConfirmPassword"
                  v-model="form.confirmPassword"
                  :is-auto-complete="true"
                  :eye-style="{ 'margin-right': '20px' }"
                  :rules="{ fn: validateConfirmPassword }"
                  label="Confirmação de senha"
                />
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col-4">
                <RgInput
                  v-model="form.usu_login"
                  :rules="{ required: true }"
                  label="Login"
                />
              </div>
              <div class="col-4">
                <RgInput
                  v-model="form.usu_token_pep"
                  label="Token de acesso ao sistemas PEP"
                />
              </div>
              <div class="col-4">
                <RgToggleRadio
                  v-model="form.usu_ativo"
                  label="Acesso ao Sistema"
                />
              </div>
            </div>
          </div>
          <div class="col-12 commands">
            <button
              :disabled="!canSave"
              class="btn btn-primary"
              @click="saveUser"
            >
              Salvar
            </button>
            <button type="button" class="btn btn-clean" @click="cleanForm">
              Limpar formulário
            </button>
          </div>
        </RgValidatorForm>
      </form>
    </Modulebox>
  </section>
</template>

<script>
import { isEmpty } from "lodash";
import Modulebox from "~tokio/foundation/modulebox/Modulebox";
import {
  RgInput,
  RgValidatorForm,
  RgComboboxSector,
  RgInputPassword,
  RgToggleRadio,
} from "~tokio/primitive";
import RgComboboxUnithealth from "$billing/common/component/rg-combobox-unithealth/RgComboboxUnithealth";
import RgComboboxUserGroup from "./../../common/rg-combobox-user-group/RgComboboxUserGroup";
import RgSuggestCity from "$patient/common/components/suggest/rg-suggest-city/RgSuggestCity";

export default {
  name: "UserDetail",
  components: {
    Modulebox,
    RgInput,
    RgValidatorForm,
    RgComboboxSector,
    RgInputPassword,
    RgComboboxUnithealth,
    RgComboboxUserGroup,
    RgSuggestCity,
    RgToggleRadio,
  },
  data() {
    return {
      disablingToSave: false,
      saving: false,
      citySuggest: "",
      form: {
        usu_id: 0,
        usu_id_unidades_saude_padrao: null,
        usu_id_setor_padrao: null,
        usu_senha: "",
        confirmPassword: "",
        usu_id_grupos_usuario: 0,
        usu_id_municipios: 0,
        usu_nome: "",
        usu_login: "",
        usu_token_pep: "",
        usu_senha_bi: "abcde",
        usu_ativo: 0,
        usu_id_clientes: 0,
      },
    };
  },
  computed: {
    hasPermission() {
      return true;
    },
    canSave() {
      return !this.saving;
    },
  },
  mounted() {
    const userData = this.$route.params.userData;
    if (userData) {
      this.$nextTick(() => {
        this.mountUser(userData);
        if (this.$refs.mainCity)
          this.$refs.mainCity.loadCity(this.form.usu_id_municipios);
      });
    } else {
      this.form.usu_id_unidades_saude_padrao = this.$store.getters[
        "Login/GET_UNIT_HEALTH_ID"
      ];
      this.form.usu_id_clientes = this.$store.getters[
        "Login/GET_USER_ID_CLIENT"
      ];
    }
  },
  methods: {
    mountUser(pUserData) {
      this.form.usu_id = pUserData.usu_id;
      this.form.usu_id_unidades_saude_padrao =
        pUserData.usu_id_unidades_saude_padrao;
      this.form.usu_id_setor_padrao = pUserData.usu_id_setor_padrao;
      this.form.usu_nome = pUserData.usu_nome;
      this.form.usu_login = pUserData.usu_login;
      this.form.usu_id_grupos_usuario = pUserData.usu_id_grupos_usuario;
      this.form.usu_token_pep = pUserData.usu_token_pep;
      this.form.usu_id_municipios = pUserData.usu_id_municipios;
      this.form.usu_ativo = pUserData.usu_ativo;
    },
    async saveUser() {
      if (!this.saving) {
        const { confirmPassword, ...data } = this.form;
        const validation = await this.$refs.validator.validate();
        if (!validation) return false;
        this.saving = true;
        try {
          await this.$store.dispatch("User/UserDetail/SAVE_USER_DETAILS", data);
          this.$toaster.success("Salvo com sucesso!");
          this.cleanForm();
        } catch (error) {
          this.$toaster.error(error[0].message);
        }
        this.saving = false;
      }
    },
    cleanForm() {
      this.form = {
        usu_id: 0,
        usu_id_setor_padrao: null,
        usu_id_unidades_saude_padrao: null,
        usu_senha: "",
        confirmPassword: "",
        usu_nome: "",
        usu_login: "",
        usu_id_grupos_usuario: 0,
        usu_token_pep: "",
        usu_id_municipios: 0,
        usu_senha_bi: "",
        usu_ativo: 0,
      };
    },
    citySelected(pValue) {
      this.form.usu_id_municipios =
        pValue.source && pValue.source.mun_id ? pValue.source.mun_id : 0;
    },
    validatePasswords(pValue, pErrors) {
      if (isEmpty(pValue)) {
        pErrors.push("Campo obrigatório");
        return false;
      }

      if (
        !isEmpty(this.form.confirmPassword) &&
        !isEmpty(this.form.usu_senha) &&
        this.form.confirmPassword !== this.form.usu_senha
      ) {
        pErrors.push("Senhas não conferem");
        return false;
      }

      return true;
    },
    validatePassword(pValue, pErrors) {
      const valid = this.validatePasswords(pValue, pErrors);
      this.$refs.ConfirmPassword.validate();
      return valid;
    },
    validateConfirmPassword(pValue, pErrors) {
      const valid = this.validatePasswords(pValue, pErrors);
      this.$refs.Password.validate();
      return valid;
    },
    async isFormValid() {
      const isValid = this.$refs.validator
        ? await this.$refs.validator.validate()
        : false;
      return isValid;
    },
  },
};
</script>
