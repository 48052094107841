<script>
import { RgRemoteComboboxMixin } from "~tokio/primitive";
import SearchService from "./action/SearchService";

export default {
  name: "RgComboboxService",
  mixins: [RgRemoteComboboxMixin],
  props: {
    label: {
      default: "Serviço",
    },
    procedure: {
      default: null,
    },
  },
  watch: {
    procedure: function (pNewvalue) {
      this.getDataFromRemote();
    },
  },
  methods: {
    doSearch() {
      if (this.procedure) {
        return SearchService({ procedureCod: this.procedure });
      }
      return Promise.resolve([]);
    },
    formatItem(item) {
      return {
        value: item.CO_SERVICO,
        text: `${item.CO_SERVICO} - ${item.NO_SERVICO}`,
      };
    },
  },
};
</script>
