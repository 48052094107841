<style src="./RgComboboxSurgeryEmployee.scss" lang="scss"></style>
<script>
import { RgRemoteComboboxMixin } from "~tokio/primitive";
import SurgeryEmployeeModel from "$surgery-center/surgery/model/SurgeryEmployeeModel";
export default {
  name: "RgComboboxSurgeryEmployee",
  mixins: [RgRemoteComboboxMixin],
  props: {
    label: {
      default: "Profissional",
    },
    sectorId: {
      default: false,
      required: true,
    },
  },
  data: () => {
    return {
      model: SurgeryEmployeeModel,
      cache: false,
    };
  },
  computed: {
    filter() {
      if (!this.sectorId) {
        return {
          set_id: null,
        };
      } else {
        return {
          set_id: this.sectorId,
        };
      }
    },
  },
  methods: {
    formatItem(item) {
      const formatted = {
        value: item.vus_id,
        text: item.pes_nome + "  -  " + item.ocp_nome,
        pes_nome: item.pes_nome,
        fun_id: item.fun_id,
        ocp_nome: item.ocp_nome,
        ocp_id: item.ocp_id,
        ocp_codigo: item.ocp_codigo,
      };
      return formatted;
    },
  },
};
</script>
