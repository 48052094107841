import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchHospitalizationSituationBed(
    $isl_id: Int
    $isl_nome: String
    $isl_cor: String
  ) {
    hospitalizationSituationBed(
      isl_id: $isl_id
      isl_nome: $isl_nome
      isl_cor: $isl_cor
    ) {
      rows {
        isl_id
        isl_nome
        isl_cor
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({ query, variables });
    return data.hospitalizationSituationBed.rows;
  } catch (err) {
    const jsonResponse = JSON.parse(JSON.stringify(err));
    return Promise.reject(jsonResponse.graphQLErrors[0].message);
  }
};
