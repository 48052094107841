import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async () => {
  return AuthLegacyRequest.post(
    "/tabelas/controller-agendamento-externo/combo-box-solicitantes-sinonimo-agendamentos-externos",
  )
    .then(({ data }) => {
      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
