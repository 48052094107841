<template lang="html">
  <Modulebox
    :granted="hasPermission"
    class="exam-details-effectivation"
    :title="form.exe_id ? 'Editar Exame' : 'Novo Exame'"
  >
    <div class="exam-details-effectivation-body">
      <form class="form-container">
        <RgValidatorForm ref="validator">
          <FormBase title="Paciente">
            <div class="grid grid-row-1">
              <RgInput
                v-model="bulletinNumber"
                :class="{
                  disable: hospitalizationNumber.length > 0,
                }"
                :disabled="hospitalizationNumber.length > 0"
                data-id="boletim"
                type="number"
                label="Boletim"
                @blur="searchBulettin"
              />

              <RgInput
                v-model="hospitalizationNumber"
                :class="{ disable: bulletinNumber.length > 0 }"
                :disabled="bulletinNumber.length > 0"
                data-id="internacao"
                type="number"
                label="Nº Internação"
                @blur="searchHospitalization"
              />

              <RgInput
                v-model="recordNumber"
                data-id="prontuario"
                type="number"
                label="Prontuário"
                @blur="searchRecordNumber"
              />

              <RgSuggestPatient
                ref="Patient"
                v-model="suggestPatient"
                :rules="{ forceSelection: true }"
                data-id="paciente"
                data-item="paciente"
                class="row-field"
                label="Paciente"
                @selected="selectingPatient"
              />

              <RgPersonCardButton
                v-if="form.exe_id_pacientes"
                class="person"
                title="Editar Paciente"
                small
                @click="editPatient"
              />

              <RgNewButton
                v-else
                class="person"
                title="Cadastro de Paciente"
                large
                @click="newPatient"
              />
            </div>
          </FormBase>

          <FormBase title="Exame" class="formbase">
            <div class="grid grid-row-2">
              <RgInputDate
                v-model="form.exe_data_exame"
                data-id="data-exame"
                label="Data do Exame"
              />

              <RgSuggestEmployee
                ref="Requester"
                v-model="suggestRequestEmployee"
                :rules="{ forceSelection: true }"
                data-id="profissional-solicitante"
                data-item="profissional-solicitante"
                class="row-field"
                label="Profissional Solicitante"
                @selected="selectingRequestEmployee"
              />
              <RgComboboxSector
                ref="Sector"
                v-model="form.exe_id_setores"
                :unit-health="form.exe_id_unidades_saude"
                :permission="sectorPermission"
                :rules="{ required: true }"
                data-id="setor"
                label="Setor"
                class="row-field"
              />

              <RgSuggestProcedure
                ref="Procedure"
                v-model="suggestProcedure"
                :rules="{ fn: validateProcedure }"
                :sector-link="sectorLink"
                :sector-link-required="false"
                :alert-empty-result="true"
                :disabled="!hasSector"
                :class="{ disable: !hasSector }"
                data-id="procedimento"
                data-item="procedimento"
                label="Procedimento"
                field-caption="NO_PROCEDIMENTO"
                @selected="selectingProcedure"
              />
            </div>

            <div class="grid grid-row-3">
              <RgSuggestEmployee
                ref="Employee"
                v-model="suggestEmployee"
                :rules="{ fn: validateEmployee }"
                :sector="form.exe_id_setores"
                :sector-required="true"
                :compatible-with-cod-procedure="form.exe_codigo_procedimento"
                :compatible-with-cod-procedure-required="true"
                :disabled="!hasProcedure"
                :class="{ disable: !hasProcedure }"
                data-id="profissional-executante"
                data-item="profissional-executante"
                class="row-field"
                label="Profissional Executante"
                @selected="selectingEmployee"
              />
              <RgSuggestCid
                ref="mainCid"
                v-model="mainCid"
                :procedureCode="form.exe_codigo_procedimento"
                data-id="cid"
                label="CID:"
                @selected="selectingCidCode"
              />
            </div>

            <div class="grid grid-row-4">
              <RgTextArea
                v-model="form.exe_observacao"
                :maxlength="500"
                data-id="observacao"
                class="textarea"
                label="Observação"
              />
            </div>
          </FormBase>
        </RgValidatorForm>
      </form>
    </div>

    <div class="exam-details-effectivation-footer">
      <RgCleanButton
        type="button"
        data-id="limpar"
        title="Limpar"
        class="button-footer"
        @click="cleanForm"
      />
      <MediumButton
        :disabled="disableButtons.solicitation"
        data-id="salvar-solicitacao"
        class="button-footer"
        label="Salvar como Solicitação"
        @click="saveSolicitationEffectivation"
      />
      <MediumButton
        :disabled="disableButtons.effectiveness"
        data-id="salvar-efetivado"
        backgroundColor="#30AF58"
        type="button"
        class="button-footer"
        label="Salvar como Efetivado"
        @click="saveConfirmedEffectivation"
      />
    </div>
  </Modulebox>
</template>

<script>
import { isEmpty } from "lodash";
import Modulebox from "~tokio/foundation/modulebox/Modulebox";
import {
  RgSuggestProcedure,
  RgSuggestPatient,
  RgSuggestEmployee,
  RgInput,
  RgValidatorForm,
  RgComboboxSector,
  RgInputDate,
  RgCleanButton,
  MediumButton,
  RgTextArea,
  RgPersonCardButton,
  RgNewButton,
  RgSuggestCid,
} from "~tokio/primitive";
import FormBase from "~tokio/foundation/form-base/FormBase";
import {
  SaveIndividualEffectivationDetails,
  SearchPatientByRecordNumber,
  SearchPatientByBulletin,
  SearchPatientByHospitalizationNumber,
} from "./action";
import PrepareExamToSave from "$exam/store/actions/PrepareExamToSave";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";

export default {
  name: "IndividualEffectivationDetails",
  components: {
    Modulebox,
    RgSuggestProcedure,
    RgSuggestPatient,
    RgSuggestEmployee,
    RgInput,
    RgComboboxSector,
    RgInputDate,
    RgValidatorForm,
    RgSuggestCid,
    RgCleanButton,
    MediumButton,
    RgTextArea,
    FormBase,
    RgPersonCardButton,
    RgNewButton,
  },
  data() {
    return {
      disablingToSave: false,
      suggestPatient: "",
      suggestEmployee: "",
      suggestRequestEmployee: "",
      suggestProcedure: "",
      recordNumber: "",
      bulletinNumber: "",
      hospitalizationNumber: "",
      sectorPermission: 247,
      sectorLink: null,
      mainCid: null,
      forceEmployeeSelection: false,
      form: {
        exe_id: 0,
        exe_id_unidades_saude: null,
        exe_id_pacientes: null,
        exe_id_vinculos: null,
        exe_id_vinculos_solicitante: null,
        pes_id: null,
        exe_id_boletins: null,
        exe_id_internacoes: null,
        exe_codigo_procedimento: null,
        exe_codigo_cid: null,
        exe_id_setores: null,
        exe_data_exame: null,
        exe_observacao: "",
      },
    };
  },
  computed: {
    disableButtons() {
      const solicitation = !!this.form.exe_id_vinculos_solicitante;
      const effectiveness = !!this.form.exe_id_vinculos;
      const buttonDisabled = {
        solicitation: false, // !solicitation || (solicitation && effectiveness),
        effectiveness: !effectiveness || !solicitation,
      };

      return buttonDisabled;
    },
    hasPermission() {
      return true;
      // return this.$Permissions.global.has('exame2.efetivacao.exibir', this.$store.getters['Login/GET_UNI_HEALTH_ID'])
    },
    hasSector() {
      return this.form.exe_id_setores > 0;
    },
    hasProcedure() {
      return (
        this.form.exe_codigo_procedimento &&
        this.form.exe_codigo_procedimento.length > 0
      );
    },
  },
  watch: {
    suggestPatient(pValue) {
      if (isEmpty(pValue)) this.recordNumber = "";
    },
    suggestProcedure(pValue) {
      this.resetEmployee();
      this.mainCid = null;
    },
    suggestEmployee(pValue) {
      if (this.pValue && this.pValue.length > 0)
        this.$refs.Employee.validate(pValue);
    },
    "form.exe_id_setores"(pValue, pPrev) {
      if (pValue && pPrev) {
        this.suggestProcedure = "";
        this.form.exe_codigo_procedimento = null;
        this.$nextTick(() => {
          this.resetEmployee();
        });
        this.mainCid = null;
      }
      if (this.$refs.Procedure) this.$refs.Procedure.validate();
    },
  },
  mounted() {
    const pData = this.$store.getters[
      "Exam/IndividualEffectivation/GET_SELECTED_DATA"
    ];

    this.mountExam(pData);
  },

  beforeDestroy() {
    const validateIfExist = ValidateIfRouteExistInBreadscrumb(
      "/exam/effectivation/edit",
      this.$route.meta.breadcrumb,
    );

    if (!validateIfExist) {
      this.$store.commit("Exam/IndividualEffectivation/CLEAN_SELECTED_DATA");
    }
  },

  methods: {
    editPatient() {
      const patientId = this.form.pes_id;
      this.$store.commit("Person/Patient/SET_PERSON_ID", patientId);
      this.$store.commit("Person/Patient/SET_LAST_PERSON_ID_SAVE", patientId);

      const routeName = this.$route.name;

      this.$router.push({
        name: `${routeName}.edit-patient`,
      });
    },

    newPatient() {
      const routeName = this.$route.name;

      this.$router.push({
        name: `${routeName}.new-patient`,
      });
    },

    mountExam(pData) {
      this.disablingToSave = false;
      this.form.exe_id_unidades_saude = this.$store.getters[
        "Login/GET_UNIT_HEALTH_ID"
      ];
      if (!pData) {
        this.form.exe_data_exame = this.$utils.date.BrazilianDateFormat();
      } else {
        this.$loader.start("Carregando...");
        if (pData.patient) {
          this.recordNumber = pData.patient.pac_prontuario_unico;
          const suggestPatient = pData.patient.person.pes_nome;
          this.$refs.Patient.forceSelection({
            pes_nome: suggestPatient,
            patient: {
              pac_id: pData.patient.pac_id,
            },
          });
        }

        if (pData.requestEmployeeRelationship) {
          const suggestRequestEmployee =
            pData.requestEmployeeRelationship.employee.person.pes_nome;
          this.$refs.Requester.forceSelection({
            pes_nome: suggestRequestEmployee,
            vin_id: pData.exe_id_vinculos_solicitante,
          });
        }

        if (pData.procedure) {
          this.$refs.Procedure.forceSelection({
            NO_PROCEDIMENTO: pData.procedure.NO_PROCEDIMENTO,
            CO_PROCEDIMENTO: pData.procedure.CO_PROCEDIMENTO,
          });
        }

        this.suggestEmployee = pData.employeeRelationship
          ? pData.employeeRelationship.employee.person.pes_nome
          : "";

        this.suggestProcedure = !isEmpty(pData.procedimento)
          ? pData.procedimento
          : pData.procedure
          ? pData.procedure.NO_PROCEDIMENTO
          : "";

        this.bulletinNumber = pData.bol_numero ? pData.bol_numero : "";
        this.hospitalizationNumber = pData.int_numero ? pData.int_numero : "";
        this.form = {
          exe_id: pData.exe_id,
          pes_id: pData.patient.person.pes_id,
          exe_id_pacientes: pData.exe_id_pacientes,
          exe_id_vinculos: pData.exe_id_vinculos,
          exe_id_vinculos_solicitante: pData.exe_id_vinculos_solicitante,
          exe_id_boletins: pData.exe_id_boletins,
          exe_id_internacoes: pData.exe_id_internacoes,
          exe_id_setores: pData.exe_id_setores,
          exe_observacao: !isEmpty(pData.exe_observacao)
            ? pData.exe_observacao
            : "",
          exe_data_exame: this.$utils.date.BrazilianDateFormat(
            pData.exe_data_exame,
          ),
          exe_codigo_cid: !isEmpty(pData.exe_codigo_cid)
            ? pData.exe_codigo_cid
            : null,
          exe_id_unidades_saude:
            pData.exe_id_unidades_saude > 0
              ? pData.exe_id_unidades_saude
              : this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
          exe_codigo_procedimento: !isEmpty(pData.cod_procedimento)
            ? pData.cod_procedimento
            : pData.procedure
            ? pData.procedure.CO_PROCEDIMENTO
            : null,
        };

        this.$nextTick(() => {
          if (pData.employeeRelationship) {
            this.$refs.Employee.forceSelection({
              pes_nome: pData.employeeRelationship.employee.person.pes_nome,
              vin_id: pData.employeeRelationship.vin_id,
            });
          }

          if (pData.cid10 && this.$refs.mainCid) {
            this.$refs.mainCid.forceSelection({
              cid_nome: pData.cid10.cid_nome,
              cid_codigo: pData.cid10.cid_codigo,
            });
          }
        });
        this.$loader.finish();
      }
    },
    async saveIndividualEffectivationDetails({ status }) {
      const validation = await this.$refs.validator.validate();
      if (!validation) return false;

      this.disablingToSave = true;
      const preparedExamInfo = PrepareExamToSave(
        this.form,
        status,
        this.form.exe_id_vinculos,
      );
      try {
        this.$loader.start("Carregando...");
        await SaveIndividualEffectivationDetails(preparedExamInfo);
        this.$toaster.success("Salvo com sucesso");
        // this.cleanForm();
      } catch (e) {
        this.$toaster.error(e, "Não foi possível salvar");
      } finally {
        this.$loader.finish();
      }
      this.disablingToSave = false;
    },
    async saveConfirmedEffectivation() {
      await this.saveIndividualEffectivationDetails({ status: 2 });
    },
    async saveSolicitationEffectivation() {
      await this.saveIndividualEffectivationDetails({ status: 1 });
    },
    selectingPatient(pPatient) {
      this.form.exe_id_pacientes =
        pPatient.source && pPatient.source.patient
          ? pPatient.source.patient.pac_id
          : null;
      this.form.pes_id = pPatient.source ? pPatient.source.pes_id : null;
    },
    selectingEmployee(pEmployee) {
      this.form.exe_id_vinculos = pEmployee.source.vin_id || null;
      this.sectorLink = pEmployee.source.vus_id || null;
    },
    selectingRequestEmployee(pRequestEmployee) {
      this.form.exe_id_vinculos_solicitante = pRequestEmployee.source.vin_id;
    },
    selectingProcedure(pRequestEmployee) {
      this.form.exe_codigo_procedimento =
        pRequestEmployee.source.CO_PROCEDIMENTO;
      // this.$refs.mainCid.reset();
    },
    selectingCidCode(pCidCode) {
      const { cid_codigo, cid_nome } = pCidCode.source;
      this.form.exe_codigo_cid = cid_codigo || null;
      this.mainCid = cid_nome || null;
    },
    cleanForm() {
      this.form = {
        exe_id_pacientes: null,
        exe_id_vinculos: null,
        exe_id_vinculos_solicitante: null,
        exe_id_boletins: null,
        exe_id_internacoes: null,
        exe_codigo_procedimento: null,
        exe_codigo_cid: null,
        exe_id_setores: null,
        exe_observacao: "",
        exe_data_exame: null,
        exe_id_unidades_saude: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
      };
      this.suggestPatient = "";
      this.suggestEmployee = "";
      this.suggestRequestEmployee = "";
      this.suggestProcedure = "";
      this.recordNumber = "";
      this.bulletinNumber = "";
      this.hospitalizationNumber = "";
      this.sectorLink = null;
      this.mainCid = null;
      this.forceEmployeeSelection = false;
      // this.$refs.mainCid.reset();
    },
    async searchBulettin() {
      if (this.bulletinNumber.length <= 0) {
        this.cleanPatient();
        return;
      }
      const variables = {
        bulletinNumber: this.bulletinNumber,
        bulletinAttended: true,
        unitHealth: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
      };

      try {
        const bulletinInfo = await SearchPatientByBulletin(variables);
        if (bulletinInfo && bulletinInfo.length > 0) {
          this.form.exe_id_boletins = bulletinInfo[0].bol_id;
          this.form.exe_id_pacientes = bulletinInfo[0].patient
            ? bulletinInfo[0].patient.pac_id
            : null;
          this.suggestPatient =
            bulletinInfo[0].patient && bulletinInfo[0].patient.person
              ? bulletinInfo[0].patient.person.pes_nome
              : "Paciente não identificado";
          this.form.pes_id =
            bulletinInfo[0].patient && bulletinInfo[0].patient.person
              ? bulletinInfo[0].patient.person.pes_id
              : null;
          this.recordNumber = bulletinInfo[0].patient.pac_prontuario_unico;
        } else {
          this.cleanPatient();
          this.$toaster.error("O boletim informado não foi encontrado");
        }
      } catch (err) {
        this.cleanPatient();
        console.error(err);
      }
    },
    async searchRecordNumber() {
      if (!this.recordNumber || this.recordNumber.length <= 0) {
        this.cleanPatient();
        return;
      }

      const variables = {
        recordNumber: this.recordNumber,
        unitHealth: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
      };

      try {
        const patientInfo = await SearchPatientByRecordNumber(variables);
        if (patientInfo && patientInfo.length === 1) {
          this.$refs.Patient.forceSelection(patientInfo[0]);

          this.form.exe_id_pacientes = patientInfo[0].patient.pac_id;
          this.form.pes_id = patientInfo[0].pes_id;
          this.suggestPatient = patientInfo[0].pes_nome;
        } else {
          this.cleanPatient();
          this.$toaster.error("O prontuário informado não foi encontrado");
        }
      } catch (err) {
        this.cleanPatient();
        console.error(err);
      }
    },
    async searchHospitalization() {
      if (this.hospitalizationNumber.length <= 0) {
        this.cleanPatient();
        return;
      }

      const variables = {
        hospitalizationNumber: this.hospitalizationNumber,
        unitHealth: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
      };

      try {
        const hospitalizationInfo = await SearchPatientByHospitalizationNumber(
          variables,
        );
        if (hospitalizationInfo && hospitalizationInfo.length === 1) {
          this.form.exe_id_internacoes = hospitalizationInfo[0].int_id;
          this.form.exe_id_pacientes = hospitalizationInfo[0].patient.pac_id;
          this.suggestPatient = hospitalizationInfo[0].patient.person.pes_nome;
          this.form.pes_id = hospitalizationInfo[0].patient.person.pes_id;

          this.recordNumber =
            hospitalizationInfo[0].patient.pac_prontuario_unico;
        } else {
          this.cleanPatient();
          this.$toaster.error("A Internação informada não foi encontrada");
        }
      } catch (err) {
        this.cleanPatient();
        console.error(err);
      }
    },
    cleanPatient() {
      this.form.exe_id_pacientes = null;
      this.suggestPatient = "";
    },
    validateEmployee(pItem, pErrors) {
      if (!pItem || pItem.length === 0) return true;

      const errors = [];

      if (!this.form.exe_id_setores || this.form.exe_id_setores <= 0) {
        errors.push("Setor");
      }

      if (
        !this.form.exe_codigo_procedimento ||
        this.form.exe_codigo_procedimento <= 0
      ) {
        errors.push("Procedimento");
      }

      if (
        errors.length === 0 &&
        this.$refs.Employee &&
        this.$refs.Employee.noItemSelected
      ) {
        errors.push("Profissional");
      }

      if (errors.length <= 0) return true;
      else {
        const errorsJoined = `${errors.join(" e ")} não selecionado${
          errors.length > 1 ? "s" : ""
        }`;
        pErrors.push(errorsJoined);
        return false;
      }
    },
    validateProcedure(pItem, pErrors) {
      if (this.validateEmployee({}, pErrors) && isEmpty(pItem)) {
        pErrors.push("Campo obrigatório");
      }

      return pErrors.length <= 0;
    },
    resetEmployee() {
      this.suggestEmployee = "";
      this.form.exe_id_vinculos = null;
      this.sectorLink = null;
      this.$refs.Employee.validate();
    },
  },
};
</script>
