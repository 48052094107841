<template lang="html">
  <div class="wrapper">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 98.5 98.5"
      enable-background="new 0 0 98.5 98.5"
      xml:space="preserve"
    >
      <path
        class="checkmark"
        fill="none"
        stroke-width="8"
        stroke-miterlimit="10"
        d="M81.7,17.8C73.5,9.3,62,4,49.2,4
    C24.3,4,4,24.3,4,49.2s20.3,45.2,45.2,45.2s45.2-20.3,45.2-45.2c0-8.6-2.4-16.6-6.5-23.4l0,0L45.6,68.2L24.7,47.3"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "CheckAnim",
};
</script>

<style media="screen" lang="scss">
@import "src/styles/1.settings/colors.scss";

.checkmark {
  stroke: $success;
  stroke-dashoffset: 745.74853515625;
  stroke-dasharray: 745.74853515625;
  animation: dash 1s ease-out forwards 1;
}

@keyframes dash {
  0% {
    stroke-dashoffset: 745.74853515625;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
</style>
