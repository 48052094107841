<style src="./RgSuggestUser.scss" lang="scss" scoped></style>
<template>
  <div class="rg-suggest-user">
    <RgSuggest
      v-model="inputValue"
      :label="label"
      :placeholder="placeholder"
      :rules="rules"
      min="2"
    >
      <RgSuggestItem
        v-for="(item, idx) in suggestionList"
        :key="idx"
        :idx="idx"
        :item="item"
        class="user-item"
      >
        <div class="user-row-top">
          <span class="user-name">{{ item.usu_nome }}</span>
        </div>
      </RgSuggestItem>
    </RgSuggest>
  </div>
</template>
<script>
import { RgSuggest, RgSuggestMixin, RgSuggestItem } from "~tokio/primitive";
import { SearchUser } from "./action";

export default {
  name: "RgSuggestUser",
  components: {
    RgSuggest,
    RgSuggestItem,
  },
  mixins: [RgSuggestMixin],
  data() {
    return {
      captionRaw: "usu_nome",
    };
  },
  methods: {
    doSearch(pSearchTerm) {
      return SearchUser({ usuNome: pSearchTerm });
    },
  },
};
</script>
