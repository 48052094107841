export default {
  GET_SELECTED_EMPLOYEE: (state) => state.selected_employee_data,
  GET_SELECTED_EXAM: (state) => state.selected_exam_data,
  GET_FILTER_EXAM_PER_DAY: (state) => state.filter_per_day_data,
  GET_FILTER_EXAM_PER_PERIOD: (state) => state.filter_per_period_data,
  GET_FILTER_DATE: (state) => state.filter_date,
  GET_EXAM_SCHEDULE: (state) => state.exam_schedule,
  GET_FILTER_EXAM_QUEUE: (state) => state.filter_exam_queue_data,
  GET_SELECTED_PATIENT_LIST_QUEUE: (state) =>
    state.selected_patient_list_queue_data,
  GET_FORM_SCHEDULE: (state) => state.form_schedule,
  GET_FILTER_PATIENT_EXCLUDE: (state) => state.filter_patient_exclude_data,
  GET_FILTER_SCHEDULE_PATIENT_LIST: (state) =>
    state.filter_schedule_patient_list,
  GET_FILTER_EXTERNALLY_SCHEDULE: (state) => state.filter_externally_schedule,
};
