<template lang="html">
  <div class="title-bar">
    <div class="progress-bar">
      <div :style="{ width: percentualBar }" class="progress-bar--background" />
      <div :class="{ waiting: waitingMode }" />
    </div>

    <div class="title-content">
      <span class="title">
        {{ caption }}
      </span>
      <span class="title-info">
        <slot name="info" />
      </span>
      <span class="title-actions">
        <slot name="actions" />
      </span>
      <div
        v-if="hasBackBtn"
        class="title-close"
        title="Voltar"
        @click="actClose"
      >
        <IconLeftArrow class="icon" />
      </div>
    </div>
  </div>
</template>

<script>
import { IconLeftArrow } from "~tokio/primitive/icon/symbols";

export default {
  name: "TitleBar",
  components: {
    IconLeftArrow,
  },
  props: {
    caption: {
      default: "",
    },
    waitingMode: {
      default: false,
    },
    percentual: {
      default: 0,
    },
    hasBackBtn: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    percentualBar() {
      return this.percentual + "%";
    },
  },
  methods: {
    actClose() {
      this.$router.go(-1);
    },
  },
};
</script>
