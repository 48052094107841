import GET_PERSON_LIST from "./GetPersonList";
import GET_PERSON_BY_ID from "./GetPersonById";
import SAVE_PERSON from "./SavePerson";
import SAVE_QUICK_PERSON from "./SaveQuickPerson";
import UPDATE_PERSON from "./UpdatePerson";
import UPDATE_QUICK_PERSON from "./UpdateQuickPerson";
import UPDATE_MY_ESUS from "./UpdateMyEsus";
import ADD_PERSON_PHONE from "./AddPersonPhone";
import UPDATE_ADDITIONAL_INFO from "./UpdateAdditionalInfo";
import DELETE_TELEPHONE from "./DeleteTelephone";
import DELETE_DOCUMENT from "./DeleteDocument";
import TOGGLE_PERSON_STATUS_BY_PES_ID from "./TogglePersonStatusByPesId";
import GET_DOCUMENTS_LIST from "./GetDocumentsList";
import ADD_NEW_DOCUMENT from "./AddNewDocument";
import GENERATE_RECORD_NUMBER from "./GenerateRecordNumber";
import CREATE_NEW_PATIENT from "./CreateNewPatient";
import SEARCH_PATIENT_HISTORY from "./SearchPatientHistory";
import SEARCH_PATIENT_HISTORY_DETAILS from "./SearchPatientHistoryDetails";

export default {
  GET_PERSON_LIST,
  GET_PERSON_BY_ID,
  SAVE_PERSON,
  SAVE_QUICK_PERSON,
  UPDATE_PERSON,
  UPDATE_QUICK_PERSON,
  ADD_PERSON_PHONE,
  UPDATE_ADDITIONAL_INFO,
  DELETE_TELEPHONE,
  TOGGLE_PERSON_STATUS_BY_PES_ID,
  GET_DOCUMENTS_LIST,
  ADD_NEW_DOCUMENT,
  DELETE_DOCUMENT,
  GENERATE_RECORD_NUMBER,
  CREATE_NEW_PATIENT,
  UPDATE_MY_ESUS,
  SEARCH_PATIENT_HISTORY,
  SEARCH_PATIENT_HISTORY_DETAILS,
};
