import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query {
    usersGroup {
      rows {
        gru_id
        gru_nome
        gru_descricao
        gru_peso
        gru_ativo
      }
    }
  }
`;
export default async () => {
  const variables = {};

  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    return data.usersGroup.rows;
  } catch (err) {
    return err.graphQLErrors;
  }
};
