import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async (occupationId) => {
  return AuthLegacyRequest.post(
    "funcionarios/controller-funcionario/combobox-profissional-por-ocupacao",
    { intIdOcp: occupationId },
  )
    .then(({ data }) => {
      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
