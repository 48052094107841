<style src="./ModalShowError.scss" lang="scss" scoped></style>
<template lang="html">
  <div class="modal-show-error">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      withCloseButton
      :show="show"
      class="rg-alert-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="confirm-header">
        <h2 class="title">Erros retornados:</h2>
      </div>
      <div slot="body" class="confirm-body">
        <div v-if="haveError" class="internal-body">
          <div
            v-for="(item, index) in errors"
            :key="index"
            class="items-errors"
          >
            <div class="icon-error"><IconTriangleAlert /></div>
            <div class="type-error">{{ item.typeErr }}</div>
            <div v-if="item.nome" class="name-procedure" title="Procedimento">
              PROCEDIMENTO: {{ item.nome }}
            </div>
            <div class="error" title="Erro" style="white-space: pre-line">
              {{ item.lpe_mensagem_erro || item.leb_mensagem_erro }}
            </div>
          </div>
        </div>

        <div v-else class="empty-values">
          <IconTriangleAlert />
          <span>NÃO ENCONTRAMOS NENHUM ERRO</span>
        </div>
      </div>

      <div>
        <div class="row" />
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { RgBaseModal } from "~tokio/primitive";
import { IconTriangleAlert } from "~tokio/primitive/icon/symbols";

export default {
  name: "ModalShowError",
  components: {
    RgBaseModal,
    IconTriangleAlert,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    procedures: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      errors: [],
      modalSize: {
        maxWidth: 38,
        minWidth: 38,
        maxHeight: 32,
        minHeight: 32,
      },
    };
  },
  computed: {
    haveError() {
      return this.errors.some(
        (item) => item.lpe_mensagem_erro || item.leb_mensagem_erro,
      );
    },
  },
  watch: {
    procedures(pValue) {
      if (pValue) {
        this.errors = [];
        pValue.forEach((element) => {
          if (element.lpe_mensagem_erro) {
            element.typeErr = "Erro no Procedimento";
            this.errors.push(element);
          }
          if (element.leb_mensagem_erro) {
            element.typeErr = "Erro no Pedido";
            this.errors.push(element);
          }
        });
      }
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
