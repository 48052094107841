<style src="./RgReportTeamProductivity.scss" lang="scss" scoped></style>
<template>
  <div class="report-container row">
    <ModuleBox
      :granted="hasPermission"
      class="report-filter col-4"
      title="Filtro de relatórios"
    >
      <form @submit.prevent="searchReport">
        <div class="row">
          <RgInputDate
            v-model="filter.initialDate"
            :rules="{ required: true }"
            label="Data Inicial"
            class="col-md-6 col-sm-12"
          />
        </div>
        <div class="row">
          <RgInputDate
            v-model="filter.endDate"
            :rules="{ required: true }"
            label="Data Final"
            class="col-md-6 col-sm-12"
          />
        </div>
        <div class="row">
          <RgComboboxUnitHealth
            v-model="filter.unitHealth"
            permission="centro_cirurgico"
            :rules="{ required: true }"
            class="col-12 combobox-row"
            label="Unidade de Saúde"
          />
        </div>
        <div class="row">
          <RgComboboxSector
            v-model="filter.sector"
            :unit-health="filter.uns_id"
            default-text="Todos"
            class="col-12 combobox-row"
            label="Centro Cirúrgico"
          />
        </div>
        <div class="row">
          <RgComboboxPlace
            v-model="filter.place"
            :sector="filter.sector"
            empty-default-text="Selecione um centro cirúrgico"
            default-text="Todos"
            class="col-12 combobox-row"
            label="Sala"
          />
        </div>

        <div class="row">
          <RgComboboxEmployeeFunction
            v-model="filter.function"
            :disabled="!filter.sector"
            :sector="filter.sector"
            empty-default-text="Selecione um centro cirúrgico"
            class="col-12"
            label="Função"
          />
        </div>
        <div class="row">
          <RgComboboxSurgeryEmployee
            v-model="filter.employee"
            :disabled="!filter.sector"
            :sector-id="filter.sector"
            empty-default-text="Selecione um centro cirúrgico"
            class="col-12"
          />
        </div>

        <div class="container--print-icon col-12">
          <button type="button" class="btn btn-default" @click="searchReport">
            <span class="button">Gerar Relatório</span>
            <div v-if="searchCalculate" class="rg-spinner rg-spinner__medium" />
          </button>

          <button
            type="button"
            class="btn btn-default"
            @click="printReport('print-report-team-productivity')"
          >
            <span class="button">Imprimir</span>
            <div v-if="printCalculate" class="rg-spinner rg-spinner__medium" />
          </button>
        </div>

        <RgIframe
          v-if="showPrintReport"
          :src="printLink"
          class="iframe-hidden"
        />
      </form>
    </ModuleBox>

    <ModuleBox
      :granted="hasPermission"
      class="report-list-container col-8"
      title="Relatório"
    >
      <ul class="report-list">
        <li v-if="fillData && fillData.length > 0" class="list-item">
          <ul class="list-content">
            <li>
              <ul class="list-content-header">
                <li class="column-number">#</li>
                <li class="column-qt">Qt</li>
                <li class="column-material">Profissional / Função</li>
              </ul>
            </li>
            <li v-for="(item, itemIdx) in fillData" :key="itemIdx">
              <ul class="list-content-values">
                <li class="column-number">{{ itemIdx + 1 }}</li>
                <li class="column-qt">{{ item.quantity }}</li>
                <li class="column-material">
                  {{ item.pes_nome }} - {{ item.fnc_nome }}
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <section v-if="fillData === null" class="empty-list">
        <div class="warning--container">
          <IconDocument class="icon-document" />
          <span class="span-warning"
            >Utilize o filtro para efetuar a busca</span
          >
        </div>
      </section>

      <section v-if="fillData && fillData.length < 1" class="empty-list">
        <div class="warning--container">
          <IconEmpty class="icon-empty" />
          <span class="span-warning">Relatório vazio</span>
        </div>
      </section>
    </ModuleBox>
  </div>
</template>
<script>
import ModuleBox from "~tokio/foundation/modulebox/Modulebox";
import {
  toast,
  RgInputDate,
  RgValidatorForm,
  IconEmpty,
  IconDocument,
} from "~tokio/primitive";
import RgIframe from "~tokio/foundation/rg-iframe/RgIframe";
import {
  RgComboboxSector,
  RgComboboxPlace,
} from "$surgery-center/unit-health/component";
import ReportSearchModel from "$surgery-center/reports/model/ReportSearchModel";

import RgComboboxSurgeryEmployee from "$surgery-center/surgery/component/combobox/rg-combobox-surgery-employee/RgComboboxSurgeryEmployee";
import RgComboboxEmployeeFunction from "$surgery-center/surgery/component/combobox/rg-combobox-employee-function/RgComboboxEmployeeFunction";
import RgComboboxUnitHealth from "~common/component/tokio/primitive/combobox/rg-combobox-unithealth/RgComboboxUnithealth";
export default {
  name: "RgReportTeamProductivity",
  components: {
    ModuleBox,
    RgInputDate,
    IconDocument,
    RgIframe,
    RgComboboxSector,
    RgComboboxPlace,
    RgComboboxUnitHealth,
    IconEmpty,
    RgComboboxEmployeeFunction,
    RgComboboxSurgeryEmployee,
  },
  extends: { ...RgValidatorForm },
  data() {
    return {
      filter: {
        initialDate: "",
        endDate: "",
        sector: null,
        place: null,
        unitHeath: null,
        employeeFunction: null,
        teamMember: null,
      },
      showPrintReport: false,
      linkTo: null,
      hashRefresh: "123",
      printCalculate: false,
      searchCalculate: false,
      fillData: null,
    };
  },
  computed: {
    printLink() {
      const formatedFilter = this.formatFilters(this.filter);
      const completeFilter = Object.assign({}, formatedFilter);
      const FILTER = JSON.stringify(completeFilter);
      const AUX = `${this.linkTo}?filter=${FILTER}&${this.hashRefresh}`;
      return encodeURI(AUX);
    },
  },
  watch: {
    filter: {
      handler: function () {
        this.showPrintReport = false;
      },
      deep: true,
    },
  },
  mounted() {
    this.filter.initialDate = this.$utils.date.BrazilianDateFormat();
    this.filter.endDate = this.$utils.date.BrazilianDateFormat();
    this.filter.unitHealth = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
  },
  methods: {
    seletedItem() {},
    hasPermission() {
      return this.$Permissions.global.has(
        "centro_cirurgico.cirurgia.mapaCirurgico",
      );
    },
    performSearch() {
      return new Promise((resolve, reject) => {
        if (this.validate()) {
          ReportSearchModel.getReportEmployeeProductivity(
            this.formatFilters(this.filter),
          )
            .then((pRes) => {
              let listResult = pRes;
              if (typeof pRes === "object")
                listResult = Object.keys(pRes).length > 0 ? pRes : [];
              resolve(listResult);
            })
            .catch((pErr) => {
              reject(new Error("Nenhum Mapa Cirurgico Encontrado"));
              toast.error(
                "Nenhum mapa cirúrgico encontrado para este período",
                "Busca falhou...",
              );
            });
        } else {
          reject(new Error("Campos Inválidos"));
          toast.error(
            "Os campos do filtro precisam estar preenchidos corretamente",
            "Busca falhou...",
          );
        }
      });
    },
    searchReport() {
      this.searchCalculate = true;
      this.performSearch()
        .then((pRes) => {
          this.searchCalculate = false;
          this.fillData = pRes;
        })
        .catch((pErr) => {});
    },
    formatFilters(pFilter) {
      if (pFilter) {
        const newFilters = {
          initialDate: pFilter.initialDate
            ? this.$utils.date.BrazilianDateToDatabase(pFilter.initialDate)
            : "",
          endDate: pFilter.endDate
            ? this.$utils.date.BrazilianDateToDatabase(pFilter.endDate)
            : "",
        };
        newFilters.place = this.filter.place || null;
        return Object.assign({}, this.filter, newFilters);
      }
    },
    printReport(pDoc) {
      this.performSearch()
        .then(() => {
          this.printCalculate = true;
          this.linkTo = pDoc;
          this.hashRefresh = new Date().getTime();
          this.showPrintReport = true;
          setTimeout(() => {
            this.printCalculate = false;
          }, 2000);
        })
        .catch((pError) => {
          this.printCalculate = false;
        });
    },
  },
};
</script>
