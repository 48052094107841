import AuthRequest from "~common/request/AuthRequest";

const ReportMaterial = {};

ReportMaterial.getReportMaterial = (pFilter) => {
  return AuthRequest.get(
    "/surgery-center/reports/materials-by-patient",
    pFilter,
  ).then((response) => {
    return response.data;
  });
};

ReportMaterial.getReportEmployeeProductivity = (pFilter) => {
  return AuthRequest.get(
    "/surgery-center/reports/employee-productivity",
    pFilter,
  ).then((response) => {
    return response.data;
  });
};

export default ReportMaterial;
