import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation SoftDeleteIntegrations($id: Int) {
    SoftDeleteIntegrations(id: $id) {
      itg_id
      itg_ativo
    }
  }
`;
export default async ({ commit }, variables) => {
  try {
    const id = variables;
    variables = { id };

    const { data } = await GraphQLClient.mutate({
      mutation,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.SoftDeleteIntegrations;
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors[0].message);
  }
};
