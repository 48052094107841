import Request from "./index";
import Store from "@/store";
import HandleUnauthorized from "./HandleUnauthorized";

const request = new Request(window.esusng.serverConfig);

request.getHeaders = (headers) => {
  headers.authorization = Store.getters["Login/GET_TOKEN"];
  headers.unithealth = Store.getters["Login/GET_UNIT_HEALTH_ID"];
  headers["Content-Type"] = headers["Content-Type"] || "application/json";
  return headers;
};

request.onunauthorized = () => {
  HandleUnauthorized();
};

export default request;
