import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchMicroarea($areaTeam: Int) {
    SearchMicroarea(mic_id_areas: $areaTeam) {
      count
      rows {
        mic_id
        mic_id_areas
        mic_codigo
        mic_descricao
      }
    }
  }
`;

export default async (context, areaTeamId) => {
  try {
    const variables = {
      areaTeam: areaTeamId,
    };
    const { data } = await GraphQLClient.query({
      query,
      fetchPolicy: "no-cache",
      variables,
    });
    return data;
  } catch (err) {
    console.log(err);
    return err.graphQLErrors;
  }
};
