<template lang="html">
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 493.348 493.349"
    style="enable-background: new 0 0 493.348 493.349"
    xml:space="preserve"
  >
    <g>
      <path
        d="M354.034,112.488L252.676,2.853C250.771,0.95,248.487,0,245.82,0c-2.478,0-4.665,0.95-6.567,2.853l-99.927,109.636
    c-2.475,3.049-2.952,6.377-1.431,9.994c1.524,3.616,4.283,5.424,8.28,5.424h63.954v356.315c0,2.663,0.855,4.853,2.57,6.564
    c1.713,1.707,3.899,2.562,6.567,2.562h54.816c2.669,0,4.859-0.855,6.563-2.562c1.711-1.712,2.573-3.901,2.573-6.564V127.907h63.954
    c3.806,0,6.563-1.809,8.274-5.424C356.976,118.862,356.498,115.534,354.034,112.488z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: "UpArrow",
};
</script>
