import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import ExamFormSchedule from "$exam/submodules/schedule/views/exam-form-schedule/ExamFormSchedule.vue";
import { OpenMenuModule } from "~common/utils/app/menu";

export default {
  path: "/exam/schedule/per-day/edit/form",
  name: "exam.schedule.per-day.edit.form",
  components: {
    default: ExamFormSchedule,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Exame", link: OpenMenuModule("exam") },
      { label: "Agendamentos por Dia", link: "/exam/schedule/per-day" },
      { label: "Editar" },
    ],
  },
};
