<template>
  <RgSuggest
    ref="suggest"
    v-model="inputValue"
    :label="label"
    :search-on-focus="searchOnFocus"
    :placeholder="placeholder"
    :rules="rules"
    :title="title"
    min="2"
  >
    <RgSuggestItem
      v-for="(item, idx) in suggestionList"
      :key="idx"
      :idx="idx"
      :item="item"
      class="rg-suggest-hospitalization-destination-place _fit-content"
    >
      <div class="items _fit-content">
        <span class="item">{{ formatItem(item) }}</span>
      </div>
    </RgSuggestItem>
  </RgSuggest>
</template>

<script>
import {
  RgSuggest,
  RgSuggestItem,
  RgSuggestMixin,
} from "~tokio/primitive/selection";
import SearchHospitalizationDestinationPlace from "./action/SearchHospitalizationDestinationPlace";

export default {
  name: "RgSuggestHospitalizationDestinationPlace",
  components: {
    RgSuggest,
    RgSuggestItem,
  },
  mixins: [RgSuggestMixin],
  props: {
    label: {
      type: String,
      default: "Local de Destino",
    },
    min: {
      type: String,
      default: "1",
    },
    placeholder: {
      type: String,
      default: "Digite o local de destino",
    },
    title: {
      type: String,
      default: "Local de Destino",
    },
  },
  data() {
    return {
      captionRaw: "ild_nome",
      filter: () => {
        return {
          searchQuery: this.inputValue,
        };
      },
    };
  },
  computed: {
    searchOnFocus() {
      return this.inputValue === "" || this.inputValue === null;
    },
  },
  methods: {
    async doSearch(searchQuery, pLimit = null) {
      const destinationPlace = await SearchHospitalizationDestinationPlace({
        ild_nome: searchQuery,
        limit: pLimit,
      });

      const OrderedDestinatioPlace = destinationPlace.rows;

      OrderedDestinatioPlace.forEach(function (item, i) {
        if (item.ild_nome === "NÃO INFORMADO") {
          OrderedDestinatioPlace.splice(i, 1);
          OrderedDestinatioPlace.unshift(item);
        }
      });

      return OrderedDestinatioPlace;
    },
    formatItem(pItem) {
      if (pItem && pItem.ild_nome) {
        return pItem.ild_nome;
      }
    },
    validate() {
      this.$refs.suggest.validate();
    },
    cleanValidate() {
      this.$refs.suggest.cleanValidate();
    },
  },
};
</script>
