import MenuMap from "./MenuMap.json";

const GetOperationsPermissions = () => {
  let permissions = [];
  for (const module in MenuMap) {
    MenuMap[module].subModule.map((subModule) => {
      if (subModule.operation) {
        subModule.operation.map((operation) => {
          if (Array.isArray(operation.permission)) {
            operation.permission.map((perm) => {
              permissions.push(perm);
            });
          } else {
            permissions.push(operation.permission);
          }
        });
      } else {
        permissions.push(subModule.permission);
      }
    });
  }
  // sanitize
  permissions = Array.from(
    new Set(
      permissions.filter((permission) => {
        if (typeof permission !== "string") {
          return false;
        }
        return permission.split(".").length >= 2;
      }),
    ),
  );
  return permissions;
};

export default GetOperationsPermissions;
