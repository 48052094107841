import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query VisitReason {
    SearchVisitReasons(limit: 100) {
      rows {
        mvi_id
        mvi_codigo_ab
        mvi_motivo_visita
      }
      count
    }
  }
`;

export default async ({ commit, state }) => {
  if (state.visit_reasons.length > 0) {
    return;
  }
  try {
    const { data } = await GraphQLClient.query({
      query,
      fetchPolicy: "no-cache",
    });
    commit("SET_VISIT_REASONS", data.SearchVisitReasons.rows);
  } catch (err) {
    console.log(err);
    return err.graphQLErrors;
  }
};
