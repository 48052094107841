<template lang="html">
  <svg
    enable-background="new 0 0 512 512"
    version="1.1"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="matrix(3.2728 0 0 3.2728 -581.84 -147.74)">
      <path
        d="m256 191c8.284 0 15-6.716 15-15v-48c0-8.284-6.716-15-15-15s-15 6.716-15 15v48c0 8.284 6.716 15 15 15z"
      />
      <circle cx="256" cy="80.001" r="15" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Information",
};
</script>
