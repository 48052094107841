<style src="./RgComboboxSectorLegacy.scss" lang="scss"></style>
<script>
import { RgRemoteComboboxMixin } from "~tokio/primitive";
import ListSectorsLegacy from "./action/ListSectorsLegacy";

export default {
  name: "RgComboboxSectorLegacy",
  mixins: [RgRemoteComboboxMixin],
  props: {
    unitHealthId: {
      required: true,
      default: 0,
    },
    submoduleId: {
      required: true,
      type: Number,
      default: 0,
    },
    operationId: {
      type: Number,
      default: 0,
    },
    sectorsActive: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Setor",
    },
    defaultText: {
      type: String,
      default: "Selecione",
    },
  },
  watch: {
    unitHealthId(pValue, pPreviously) {
      const isDiferentValue = pValue !== pPreviously;

      if (isDiferentValue) {
        this.getDataFromRemote();
      }
    },
  },
  methods: {
    doSearch() {
      const hasUnitHealthId = this.unitHealthId > 0;

      if (!hasUnitHealthId) return Promise.resolve([]);

      const onlyActive = this.sectorsActive ? 1 : 0;

      return ListSectorsLegacy({
        uns_id: this.unitHealthId,
        intIdSubModulo: this.submoduleId,
        blnSomenteAtivo: onlyActive,
        intIdOperacao: this.operationId,
      });
    },
    formatItem(item) {
      return {
        value: item.data,
        text: `${item.label}`,
      };
    },
    blur() {
      if (this.isNecessary) {
        this.validate();
        this.$emit("blur");
      }
    },
  },
};
</script>
