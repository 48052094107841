<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchSectorPerUnitPep from "./action/SearchSectorPerUnitPep";

export default {
  name: "RgComboboxSectorPerUnitPep",
  mixins: [RgComboboxMixin],
  props: {
    label: {
      default: "Setor",
    },
    unitHealth: {
      type: Number,
    },
    sectorType: {
      type: String,
    },
    operationId: {
      type: Array,
      default: null,
    },
  },
  data: () => {
    return {};
  },
  watch: {
    unitHealth() {
      this.fillData();
    },
    sectorType() {
      this.fillData();
    },
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];
      const data = await SearchSectorPerUnitPep({
        intIdUnidade: this.unitHealth,
        tipoSetor: this.sectorType,
        arrIdOperacoes: this.operationId,
      });

      data.forEach((item) => {
        this.mData.push({
          value: item.data,
          text: item.label,
        });
      });
    },
    blur() {
      this.$emit("blur");
      this.validate();
    },
  },
};
</script>
