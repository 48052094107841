<script>
import RgRemoteTypeaheadMixin from "$billing/common/component/selection/mixin/RgRemoteTypeaheadMixin";
import { SearchProcedure } from "./action";

export default {
  name: "RgTypeaheadProcedure",
  mixins: [RgRemoteTypeaheadMixin],
  props: {
    label: {
      type: String,
      default: "Procedimento",
    },
    min: {
      type: String,
      default: "2",
    },
    type: {
      type: String,
      default: "AIH",
    },
  },
  data() {
    return {
      filter: () => {
        return {
          searchQuery: this.inputValue,
        };
      },
    };
  },
  mounted() {
    if (this.model || this.value) {
      this.valueChanged();
    }
  },
  methods: {
    doSearch() {
      const searchQuery = this.inputValue;
      const type = this.type;
      if (Number.isInteger(+this.inputValue)) {
        return SearchProcedure({ searchQuery, type });
      } else {
        return SearchProcedure({ searchQuery, type });
      }
    },
    doFind(pValue) {
      const searchQuery = pValue;
      const type = this.type;
      return SearchProcedure({ searchQuery, type }, { findOne: true });
    },
    formatItem(item) {
      if (!item) return {};
      return {
        vl_idade_minima: item.VL_IDADE_MINIMA,
        vl_idade_maxima: item.VL_IDADE_MAXIMA,
        tp_sexo: item.TP_SEXO,
        qt_maxima_execucao: item.QT_MAXIMA_EXECUCAO,
        co_registro: item.CO_REGISTRO,
        qt_pontos: item.QT_PONTOS,
        vl_sa: item.VL_SA,
        vl_sp: item.VL_SP,
        vl_sh: item.VL_SH,
        value: item.CO_PROCEDIMENTO,
        text: `${item.CO_PROCEDIMENTO} - ${item.NO_PROCEDIMENTO}`,
      };
    },
  },
};
</script>
