import AuthRequest from "~common/request/AuthRequest";

/**
 *
 * @param {Array} filesnames um Array com os nomes dos arquivos enviados
 */
const GetQueueFiles = (pFillID) => {
  return AuthRequest.get("/queue/queue-files/get-files", {
    fil_id: pFillID,
  });
};

export default GetQueueFiles;
