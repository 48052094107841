import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchBioslabLaboratoryExamList(
    $lle_codigo_sigtap: String
    $lle_codigo_bioslab_mne: String
    $lle_material: String
  ) {
    SearchBioslabLaboratoryExamList(
      lle_codigo_sigtap: $lle_codigo_sigtap
      lle_codigo_bioslab_mne: $lle_codigo_bioslab_mne
      lle_material: $lle_material
    ) {
      count
      rows {
        lle_id
        lle_codigo_bioslab_mne
        lle_procedimentos
        lle_multiplo
        lle_dum
        lle_peso
        lle_altura
        lle_cor
        lle_nacionalidade
        lle_naturalidade
        lle_material
        lle_codigo_sigtap
        lle_ativo
      }
    }
  }
`;
export default async ({ commit }, variables) => {
  const { data } = await GraphQLClient.query({
    query,
    variables,
    fetchPolicy: "no-cache",
  }).catch((err) => {
    throw err;
  });
  return data.SearchBioslabLaboratoryExamList
    ? data.SearchBioslabLaboratoryExamList.rows
    : data.SearchBioslabLaboratoryExamList;
};
