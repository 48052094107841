<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchHoursEmployerSchedule from "./action/SearchHoursEmployerSchedule";
import moment from "moment";

export default {
  name: "RgComboboxHoursEmployerSchedule",
  mixins: [RgComboboxMixin],
  props: {
    scheduleScaleId: {
      required: true,
    },
    scheduleHistoryId: {
      required: true,
    },
    initialHour: {
      required: true,
    },
    appointmentDate: {
      required: true,
    },
    alterHour: {
      required: false,
    },
    noLoader: Boolean,
  },
  data: () => {
    return {
      mData: [],
    };
  },
  watch: {
    appointmentDate(pValue) {
      if (pValue) {
        this.fillData();
      }
    },
    scheduleScaleId(pValue) {
      if (pValue) {
        this.fillData();
      }
    },
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      if (
        this.appointmentDate === "" ||
        this.scheduleScaleId === null ||
        this.scheduleHistoryId === null ||
        !this.initialHour
      )
        return Promise.resolve([]);

      if (!this.noLoader) {
        this.$loader.start();
      }

      let data = [];
      this.mData = [];
      this.disableLoading = true;

      const initialHour = moment(this.initialHour, "HH:mm:ss").format("HH:mm");

      data = await SearchHoursEmployerSchedule({
        intIdEscalaDeAgendamento: this.scheduleScaleId,
        intIdHistoricoAgendamento: this.scheduleHistoryId,
        strHoraInicial: initialHour,
        strDataConsulta: this.appointmentDate,
        strHoraAlteracao: this.alterHour || null,
      });

      if (this.alterHour) {
        data.unshift({
          data: this.alterHour,
          label: this.alterHour,
          hora: this.alterHour,
        });
      }

      data.sort((a, b) => {
        return a.data.localeCompare(b.data);
      });

      this.mData = [];
      data.forEach((item) => {
        this.mData.push({ value: item.data, text: item.label });
      });

      if (!this.noLoader) {
        this.$loader.finish();
      }
      this.$emit("finishLoader");
      this.disableLoading = false;
    },
  },
};
</script>
