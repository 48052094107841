import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation UpdateEmployee($employeeId: Int, $employee: EmployeeInputType) {
    UpdateEmployee(employeeId: $employeeId, employee: $employee) {
      fun_id
    }
  }
`;

export default async ({ rootState }, payload) => {
  const variables = { ...payload };
  variables.employee.fun_crs_numero_ab = sanitizeFields(
    variables.employee.fun_crs_numero_ab,
  );

  try {
    const { data } = await GraphQLClient.mutate({
      mutation,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.UpdateEmployee;
  } catch (err) {
    return Promise.reject(err.graphQLErrors);
  }
};

const sanitizeFields = (pData) => {
  return pData.replace(/(\s)/gi, "");
};
