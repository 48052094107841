import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchPatientHistoryDetails($id: Int, $id_modulo: Int, $id_fila: Int) {
    patientHistoryDetails(id: $id, id_modulo: $id_modulo, id_fila: $id_fila) {
      rows {
        type
        occupation
        employee
        objective
        status
        priority
        unit_health
        requester
        inserted_at
        scheduled_at
        external_scheduling_id
        external_scheduling_date
        place
        observation
        procedures
        sub_procedures
        escale
        number
        bed_type
        cid
        out_date
        out_reason
        classification
        user
        destination
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.patientHistoryDetails.rows;
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors);
  }
};
