import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query($unitRecordNumber: String!, $unitHealthId: Int!) {
    unitPatientRecordNumber(
      ppr_numero: $unitRecordNumber
      ppr_id_unidades_saude: $unitHealthId
    ) {
      rows {
        pac_id
        pes_id
        pes_nome
        ppr_id
        ppr_id_unidades_saude
        ppr_id_pacientes
        ppr_numero
        ppr_adscrito
        ppr_ativo
      }
    }
  }
`;

export default async ({ variables }) => {
  const { data } = await GraphQLClient.query({ query, variables }).catch(
    (err) => {
      console.error(err);
    },
  );
  return data.unitPatientRecordNumber.rows;
};
