<style src="./AccessControl.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="access-control">
    <div class="access-control--logo">
      <img
        src="/static/images/logo-esus+-sistemas.png"
        alt="logo rg esus"
        width="200px"
      />
    </div>
    <div class="access-control--progress-bar">
      <div class="access-control--progress-bar-runner runnerA" />
      <div class="access-control--progress-bar-runner runnerB" />
    </div>
  </section>
</template>

<script>
import queryString from "query-string";

export default {
  name: "AccessControl",
  mounted() {
    this.loginUser(window.location.search);
  },
  methods: {
    loginUser(pUrl) {
      const urlInfo = this.getInfoFromURL(pUrl);
      this.$store
        .dispatch("Login/LOGIN_WITH_TOKEN", urlInfo.token)
        .then(() => {
          this.$router.push(urlInfo.link);
        })
        .catch(() => {
          this.$toaster.error("Erro ao Logar Diretamente");
        });
    },
    getInfoFromURL(pUrl) {
      const info = queryString.parse(unescape(pUrl));
      const unitHealthId = Number(info.uns_id) || undefined;
      return {
        token: info.token,
        unitHealthId,
        link: info.module || "/home",
      };
    },
  },
};
</script>
