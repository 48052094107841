import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (context, intIdFes) => {
  return AuthLegacyRequest.post(
    "exames2/controller-exame-procedimento/buscar-unidades-realizam-sub-procedimento",
    {
      intIdFes,
    },
  )
    .then(({ data }) => {
      data = HandleResponse(data);
      return data.dados.recordSet;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
