<template lang="html">
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="13.5468"
      cy="13.5468"
      r="11.25"
      transform="rotate(0.255816 13.5468 13.5468)"
      stroke="white"
      stroke-width="1.5"
    />
    <path
      d="M11.9994 7.99805L11.9994 7.99807C11.7617 8.25965 11.7596 8.6758 11.9942 8.93992L11.9943 8.93994L15.9766 13.4207L15.9768 13.4209C16.0415 13.4936 16.0411 13.622 15.9753 13.6947L15.9751 13.6949L11.9532 18.1396C11.9532 18.1396 11.9532 18.1396 11.9532 18.1396C11.7161 18.4016 11.7146 18.8178 11.9499 19.0815L11.9499 19.0815C12.1991 19.3607 12.6168 19.362 12.8679 19.0846L12.8679 19.0846L16.8893 14.6403C17.1618 14.3398 17.2952 13.949 17.297 13.5634C17.2987 13.178 17.1688 12.7861 16.8992 12.4835L12.9173 8.00317L12.7312 8.16858L12.9173 8.00317C12.6688 7.7235 12.2511 7.7211 11.9994 7.99805Z"
      fill="white"
      stroke="white"
      stroke-width="0.5"
    />
  </svg>
</template>

<script>
export default {
  name: "CircleArrow",
};
</script>
