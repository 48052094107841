<template lang="html">
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      y="19"
      width="19"
      height="19"
      rx="5"
      transform="rotate(-90 0 19)"
      fill="#0088aa"
    />
    <path
      d="M9.78477 2.36627C9.20447 2.36627 8.73548 2.83527 8.73548 3.41557L8.73548 9.29984L5.33231 9.29984C4.9071 9.30058 4.52345 9.55363 4.36242 9.94767C4.19991 10.3387 4.29044 10.7914 4.59024 11.0912L9.0427 15.5437C9.45307 15.954 10.1172 15.9533 10.5268 15.5437L14.9793 11.0912C15.2791 10.7914 15.3696 10.3402 15.2071 9.94767C15.0446 9.55511 14.6617 9.29984 14.2372 9.29984L10.8341 9.29984L10.8341 3.41557C10.8341 2.83527 10.3651 2.36627 9.78477 2.36627Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "ArrowBox",
};
</script>
