<style src="./RgSuggestOccupationEsusSearchBar.scss" lang="scss"></style>
<template lang="html">
  <RgSuggest
    v-model="inputValue"
    :placeholder="placeholder"
    :rules="rules"
    :show-icon="showIcon"
    min="2"
    class="rg-suggest-occupation-esus-search-bar"
  >
    <RgSuggestItem
      v-for="(item, idx) in suggestionList"
      :key="idx"
      :idx="idx"
      :item="item"
      class="employee-item"
    >
      <div class="employee-row-top">
        <span class="employee-name">{{ item.ocp_nome }}</span>
      </div>
    </RgSuggestItem>
  </RgSuggest>
</template>

<script>
import {
  RgSuggest,
  RgSuggestItem,
  RgSuggestMixin,
} from "~tokio/primitive/selection";
import SearchOccupationEsus from "./action/SearchOccupationEsus";

export default {
  name: "RgSuggestOccupationEsus",
  components: {
    RgSuggest,
    RgSuggestItem,
  },
  mixins: [RgSuggestMixin],
  props: {
    showIcon: {
      default: true,
    },
  },
  data() {
    return {
      captionRaw: "ocp_nome",
    };
  },
  methods: {
    doSearch(pSearchTerm) {
      return SearchOccupationEsus({
        variables: { occupationName: pSearchTerm },
      });
    },
  },
};
</script>
