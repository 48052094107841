import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import AppointmentDisabledPeriods from "$appointment/submodules/schedule/views/appointment-disabled-periods/AppointmentDisabledPeriods.vue";
import { OpenMenuModule } from "~common/utils/app/menu";

export default {
  path: "/appointment/schedule/disabled-periods",
  name: "appointment.schedule.disabled-periods",
  components: {
    default: AppointmentDisabledPeriods,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Consulta", link: OpenMenuModule("appointment") },
      {
        label: "Períodos Inabilitados",
        link: "/appointment/schedule/disabled-periods",
      },
    ],
  },
};
