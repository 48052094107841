<style src="./RgComboboxHospitalizationPlace.scss" lang="scss"></style>
<script>
import { isNumber } from "lodash";
import { RgRemoteComboboxMixin } from "~tokio/primitive";
import GetPlaceByBedType from "./action/GetPlaceByBedType";

export default {
  name: "RgComboboxHospitalizationPlace",
  mixins: [RgRemoteComboboxMixin],
  props: {
    label: {
      default: "Local de Atendimento",
    },
    sector: {
      default: false,
      required: true,
    },
    bedTypeId: {
      type: Number,
      default: 0,
    },
    inlId: {
      type: Number,
      default: 0,
    },
    fromMapBed: {
      type: Boolean,
      default: false,
    },
    currentSituation: {
      type: String,
      default: "VAZIO",
    },
  },
  data: () => {
    return {};
  },
  computed: {
    filter() {
      if (!isNumber(this.sector)) {
        this.resetCombobox();
        return null;
      } else {
        return {
          set_id: this.sector,
        };
      }
    },
  },
  watch: {
    inlId(pValue, pPreviously) {
      const isDiferentValue = pValue !== pPreviously;
      if (isDiferentValue && this.inlId && this.fromMapBed) {
        this.getDataFromRemote();
      }
    },
  },
  methods: {
    async doSearch() {
      return GetPlaceByBedType({
        set_id: this.sector,
        itl_id: this.bedTypeId,
        inl_id: this.inlId,
        situation: this.currentSituation,
      });
    },
    formatItem(pData) {
      return {
        value: pData.lca_id,
        text: `${pData.lca_nome}`,
      };
    },
    blur() {
      if (this.isNecessary) {
        this.validate();
        this.$emit("blur");
      }
    },
  },
};
</script>
