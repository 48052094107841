import { AlertError } from "./";

const ValidateTeamMembers = (pProfessionalTeamList) => {
  const hasAnesthetist = pProfessionalTeamList.filter(
    (prof) => prof.ieq_id_internacoes_funcoes_profissionais === 6,
  );
  const hasSurgeon = pProfessionalTeamList.filter(
    (prof) => prof.ieq_id_internacoes_funcoes_profissionais === 1,
  );
  if (hasAnesthetist.length && hasSurgeon.length) return true;
  return AlertError(
    "Necessário ao menos um cirurgião e um anestesista na equipe.",
  );
};

export default ValidateTeamMembers;
