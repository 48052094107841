<style src="./ModalDefaultProcedure.scss" lang="scss" scoped></style>
<template>
  <section
    v-shortkey="['esc']"
    class="modal-default-procedure"
    @shortkey="close"
    @click.self="close"
  >
    <div class="box-container">
      <div class="title-bar">
        <span>Procedimento padrão</span>
      </div>

      <div class="container">
        <span class="describe"
          >Informe a data do atendimento e selecione quais procedimentos deseja
          adicionar.</span
        >

        <form class="form-standard" @submit.prevent="insertProcedures">
          <div class="row">
            <div class="col-3 hide-md">
              <div class="illustration-container">
                <IconCheckMark class="illustration" />
              </div>
            </div>

            <div class="col-9 col-md-12">
              <div class="date-container">
                <RgInputDateDay
                  ref="inputDate"
                  v-model="pdf_data_atendimento"
                  :autofocus="true"
                  :rules="{ required: true }"
                  class="date-fields"
                  label="Data do atendimento"
                />
              </div>

              <div class="fields-container">
                <label v-shortkey="['ctrl', '1']" @shortkey="selectProcedure1">
                  <input
                    v-model="defaultProcedure"
                    class="checkbox"
                    type="checkbox"
                    value="0301060118"
                  />
                  <div class="quad-select">
                    <IconCheck />
                  </div>
                  <div class="info">
                    <span class="text"
                      >ACOLHIMENTO COM CLASSIFICAÇÃO DE RISCO</span
                    >
                    <span class="code">0301060118</span>
                  </div>
                </label>
                <label v-shortkey="['ctrl', '2']" @shortkey="selectProcedure2">
                  <input
                    v-model="defaultProcedure"
                    class="checkbox"
                    type="checkbox"
                    value="0301060096"
                  />
                  <div class="quad-select">
                    <IconCheck />
                  </div>
                  <div class="info">
                    <span class="text"
                      >ATENDIMENTO MEDICO EM UNIDADE DE PRONTO ATENDIMENTO</span
                    >
                    <span class="code">0301060096</span>
                  </div>
                </label>
                <label v-shortkey="['ctrl', '3']" @shortkey="selectProcedure3">
                  <input
                    v-model="defaultProcedure"
                    class="checkbox"
                    type="checkbox"
                    value="0301060029"
                  />
                  <div class="quad-select">
                    <IconCheck />
                  </div>
                  <div class="info">
                    <span class="text"
                      >ATENDIMENTO DE URGENCIA C/ OBSERVACAO ATE 24 HORAS EM
                      ATENCAO ESPECIALIZADA</span
                    >
                    <span class="code">0301060029</span>
                  </div>
                </label>
                <label v-shortkey="['ctrl', '4']" @shortkey="selectProcedure4">
                  <input
                    v-model="defaultProcedure"
                    class="checkbox"
                    type="checkbox"
                    value="0301060100"
                  />
                  <div class="quad-select">
                    <IconCheck />
                  </div>
                  <div class="info">
                    <span class="text"
                      >ATENDIMENTO ORTOPÉDICO COM IMOBILIZAÇÃO PROVISÓRIA</span
                    >
                    <span class="code">0301060100</span>
                  </div>
                </label>
                <label v-shortkey="['ctrl', '5']" @shortkey="selectProcedure5">
                  <input
                    v-model="defaultProcedure"
                    class="checkbox"
                    type="checkbox"
                    value="0301010048"
                  />
                  <div class="quad-select">
                    <IconCheck />
                  </div>
                  <div class="info">
                    <span class="text"
                      >CONSULTA DE PROFISSIONAIS DE NIVEL SUPERIOR NA ATENÇÃO
                      ESPECIALIZADA (EXCETO MÉDICO)</span
                    >
                    <span class="code">0301010048</span>
                  </div>
                </label>
              </div>
            </div>
          </div>

          <div class="commands row end-all">
            <button
              v-shortkey="['enter']"
              class="btn btn-default"
              autofocus
              @shortkey="insertProcedures"
            >
              Inserir procedimentos
            </button>
            <button class="btn btn-clean" type="button" @click="close">
              Fechar
            </button>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import { IconCheck, IconCheckMark } from "~tokio/primitive";
import RgInputDateDay from "$billing/common/component/rg-input-date-day/RgInputDateDay";
import moment from "moment";

export default {
  name: "ModalDefaultProcedure",
  components: {
    IconCheck,
    IconCheckMark,
    RgInputDateDay,
  },
  props: {
    attendanceDate: {
      default: "",
    },
  },
  data() {
    return {
      defaultProcedure: [],
      pdf_data_atendimento: "",
    };
  },
  mounted() {
    if (this.attendanceDate) this.pdf_data_atendimento = this.attendanceDate;
  },
  methods: {
    insertProcedures() {
      if (this.validateForm()) {
        this.$emit("insert-default-procedure", {
          procedures: this.defaultProcedure,
          attendanceDate: this.pdf_data_atendimento,
        });
      }
    },
    validateForm() {
      const DateConverted = moment(this.pdf_data_atendimento, "DD/MM/YYYY");
      if (!DateConverted.isValid()) {
        this.$toaster.error("Data de atendimento inválida");
        return false;
      }
      const DateFormat = DateConverted.format("YYYY-MM-DD");
      const Competence = this.$store.getters[
        "Billing/BDSia/GET_PERIOD_DATE"
      ].replace(/(\d{4})(\d{2})/gi, "$1-$2");
      const AttendanceDate = moment(
        this.pdf_data_atendimento,
        "DD/MM/YYYY",
      ).format("YYYY-MM");
      if (
        moment(Competence).diff(AttendanceDate, "months") < 0 ||
        moment(Competence).diff(AttendanceDate, "months") > 3
      ) {
        this.$toaster.error(
          "Competência atual não abrange a data de atendimento informada",
        );
        return false;
      }
      if (DateFormat > moment().format("YYYY-MM-DD")) {
        this.$toaster.error(
          "Data de atendimento não pode ser maior que a data atual",
        );
        return false;
      }
      return true;
    },
    close() {
      this.$emit("close");
    },
    selectProcedure1() {
      this.toggleProcedure("0301060118");
    },
    selectProcedure2() {
      this.toggleProcedure("0301060096");
    },
    selectProcedure3() {
      this.toggleProcedure("0301060029");
    },
    selectProcedure4() {
      this.toggleProcedure("0301060100");
    },
    selectProcedure5() {
      this.toggleProcedure("0301010048");
    },
    toggleProcedure(pProcedure) {
      const hasProcedure = this.defaultProcedure.indexOf(pProcedure);
      if (hasProcedure > -1) {
        this.defaultProcedure.splice(hasProcedure, 1);
      } else {
        this.defaultProcedure.push(pProcedure);
      }
    },
  },
};
</script>
