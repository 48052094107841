import GetOutcomes from "./GetOutcomes";
import GetVisitReasons from "./GetVisitReasons";
import GetActiveSearch from "./GetActiveSearch";
import GetFollowup from "./GetFollowup";
import GetEnvironmentControl from "./GetEnvironmentControl";
import SaveHomeVisitForm from "./SaveHomeVisitForm";
import SearchHomeVisitForm from "./SearchHomeVisitForm";
import GetHomeVisitForm from "./GetHomeVisitForm";

export default {
  GetOutcomes,
  GetVisitReasons,
  GetActiveSearch,
  GetFollowup,
  GetEnvironmentControl,
  SaveHomeVisitForm,
  SearchHomeVisitForm,
  GetHomeVisitForm,
};
