module.exports = {
  checkDigit(pPis, pError) {
    const pis = pPis.replace(/[^\d]+/g, "");

    if (pis.length === 0) {
      return true;
    }

    if (pis.length > 0) {
      if (
        pis === "00000000000" ||
        pis === "11111111111" ||
        pis === "22222222222" ||
        pis === "33333333333" ||
        pis === "44444444444" ||
        pis === "55555555555" ||
        pis === "66666666666" ||
        pis === "77777777777" ||
        pis === "88888888888" ||
        pis === "99999999999"
      ) {
        pError.push("PIS inválido");
        return false;
      }
    }

    const baseMultiplier = "3298765432";
    let total = 0;
    let rest = 0;
    let multiplying = 0;
    let multiplier = 0;
    let finger = 99;

    for (let i = 0; i < 10; i++) {
      multiplying = parseInt(pis.substring(i, i + 1));
      multiplier = parseInt(baseMultiplier.substring(i, i + 1));
      total += multiplying * multiplier;
    }

    rest = 11 - (total % 11);
    rest = rest === 10 || rest === 11 ? 0 : rest;

    finger = parseInt("" + pis.charAt(10));

    const result = rest === finger;

    if (!result) {
      pError.push("PIS inválido");
    }

    return rest === finger;
  },
};
