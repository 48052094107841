import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query EmployeeRelationship($employeeId: Int) {
    employees(fun_id: $employeeId) {
      rows {
        employeeRelationship {
          rows {
            vin_carga_horaria
            vin_admissao
            vin_id
            vin_ativo
            specialization {
              esp_id
              esp_nome
            }
            employeeRelationshipType {
              tpv_id
              tpv_nome
            }
            occupationEsus {
              ocp_id
              ocp_nome
            }
          }
        }
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.employees.rows[0].employeeRelationship.rows;
  } catch (err) {
    return err.graphQLErrors;
  }
};
