<template lang="html">
  <div class="rg-validator-form">
    <slot />
  </div>
</template>

<script>
export default {
  name: "RgValidatorForm",
  props: {
    ignoreParentValidation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      error: [],
      isValidatorForm: true,
    };
  },
  methods: {
    async validate() {
      const acumError = [];
      const runChildrens = async (pChildren) => {
        if (!pChildren) {
          return;
        }
        let v = null;
        for (let i = 0; i < pChildren.length; i++) {
          v = pChildren[i];
          if (v.valid) {
            const result = await v.validate();
            if (!result) {
              acumError.push({
                input: v.label,
                error: v.getError(),
              });
            }
          }
          if (v.isValidatorForm === true) {
            if (v.ignoreParentValidation === false) {
              await runChildrens(v.$children);
            }
          } else {
            await runChildrens(v.$children);
          }
        }
      };
      await runChildrens(this.$children);
      if (acumError.length > 0) {
        this.error = acumError;
        return Promise.resolve(false);
      } else {
        this.error = [];
        return Promise.resolve(true);
      }
    },
    cleanValidate() {
      this.error = [];
      this.$children.forEach((cmp) => {
        if (cmp.valid) {
          cmp.cleanValidate();
        }
      });
    },
    getError() {
      return this.error;
    },
  },
};
</script>
