import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchHospitalizationAihSecondaryCid($iafId: Int) {
    SearchHospitalizationAihSecondaryCid(iaf_id: $iafId) {
      iac_id
      iac_id_cid10
      iac_codigo_cid10
      iac_id_internacoes_classificacao_diagnostico
      description
    }
  }
`;

export default async ({ commit }, { iaf_id }) => {
  try {
    const variables = {
      iafId: iaf_id,
    };
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.SearchHospitalizationAihSecondaryCid || [];
  } catch (err) {
    return err.graphQLErrors;
  }
};
