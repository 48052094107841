import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  return AuthLegacyRequest.post(
    "exames2/controller-exame-agendamento-procedimento/obter-comprovante-transferencias",
    variables,
  )
    .then(({ data }) => {
      console.log("print", data);
      if (data.dados === "0") {
        return [];
      }
      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
