<style src="./EmployeeRelationship.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="employee-relationship">
    <RgForm ref="formDetails" class="relationship-form" action-label="Salvar">
      <div class="row">
        <RgSuggestOccupationEsus
          v-model="suggests.occupation"
          class="col-12"
          label="Ocupação"
          @selected="selectOccupation"
        />

        <RgComboboxEmployeeRelationshipType
          v-model="form.vin_id_tipos_vinculo"
          class="col-6"
          label="Tipo de Vínculo"
        />

        <RgComboboxSpecialties
          v-model="form.vin_id_especializacoes"
          class="col-6"
          label="Especialidade"
        />

        <RgInputNumber
          v-model="form.vin_carga_horaria"
          class="col-6"
          label="Carga horaria"
        />

        <RgInputDate
          v-model="form.vin_admissao"
          class="col-6"
          label="Data de Admissão"
        />
      </div>

      <div slot="command" class="commands">
        <button
          v-if="!editMode"
          type="button"
          class="btn-small btn-success"
          @click="createEmployeeRelationship"
        >
          Salvar Novo
        </button>
        <button
          v-if="editMode"
          type="button"
          class="btn-small btn-warning btn-space"
          @click="cancelEditRelationship"
        >
          Cancelar Edição
        </button>
        <button
          v-if="editMode"
          type="button"
          class="btn-small btn-primary"
          @click="updateRelationship"
        >
          Atualizar
        </button>
      </div>
    </RgForm>

    <span class="form-title">Lista de Vínculos Empregatícios</span>
    <ul class="relationship-list-container">
      <li
        v-for="(listItem, listIdx) in relationshipList"
        :key="listIdx"
        :class="{ 'list-actived': currentItemActived === listIdx }"
        class="relationship-list-item"
        @click="editRelationship(listItem, listIdx)"
      >
        <div
          :class="{
            'employee-status__active': listItem.vin_ativo,
            'employee-status__inactive': !listItem.vin_ativo,
          }"
          class="employee-status"
        />
        <div class="description">
          <span class="occupation-esus">{{
            listItem.occupationEsus.ocp_nome
          }}</span>
          <span class="specialization">{{
            listItem.specialization.esp_nome
          }}</span>
        </div>
        <div class="relationship-type">
          <span class="employeeRelationshipType">{{
            listItem.employeeRelationshipType.tpv_nome
          }}</span>
        </div>
        <div class="commands">
          <PopupRelationshipOptions
            :active-status="listItem.vin_ativo"
            @remove-relationship="removerRelationship(listItem)"
            @show-history="showHistory(listItem)"
            @activate-relationship="activateRelationship(listItem)"
            @deactivate-relationship="deactivateRelationship"
          />
        </div>
      </li>
    </ul>
  </section>
</template>

<script>
import { RgInputNumber, RgInputDate } from "~tokio/primitive";
import { RgForm } from "~tokio/foundation/form";
import RgComboboxEmployeeRelationshipType from "$employee/common/components/container/combobox/rg-combobox-employee-relationship-type/RgComboboxEmployeeRelationshipType";
import RgComboboxSpecialties from "$employee/common/components/container/combobox/rg-combobox-specialties/RgComboboxSpecialties";
import RgSuggestOccupationEsus from "$employee/common/components/suggest/rg-suggest-occupation-esus/RgSuggestOccupationEsus";
import PopupRelationshipOptions from "$employee/common/components/popup/relationship-options/RelationshipOptions";
import moment from "moment";

export default {
  name: "EmployeeRelationship",
  components: {
    RgComboboxEmployeeRelationshipType,
    RgComboboxSpecialties,
    RgInputNumber,
    RgSuggestOccupationEsus,
    RgInputDate,
    RgForm,
    PopupRelationshipOptions,
  },
  props: {
    employeeId: null,
    relationshipList: null,
  },
  data() {
    return {
      editMode: false,
      currentItemActived: null,
      suggests: {
        occupation: null,
      },
      selectedRelationshipId: null,
      form: {
        vin_id_especializacoes: null,
        vin_id_tipos_vinculo: null,
        vin_carga_horaria: null,
        vin_id_ocupacoes_cbo: null,
        vin_admissao: null,
      },
    };
  },
  methods: {
    async createEmployeeRelationship() {
      const variables = {
        employeeRelationship: {
          ...this.form,
          vin_unidade_padrao: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
          vin_id_funcionarios: this.employeeId,
        },
      };
      variables.employeeRelationship.vin_admissao = this.$utils.date.BrazilianDateToDatabase(
        this.form.vin_admissao,
      );
      try {
        await this.$store.dispatch(
          "Employee/CREATE_EMPLOYEE_RELATIONSHIP",
          variables,
        );
        this.cleanFields();
        this.$toaster.success("Vínculo salvo com sucesso!");
        this.$emit("relationship-created");
      } catch (e) {
        this.$toaster.error("O vínculo não pode ser salvo");
      }
    },
    selectOccupation(pData) {
      this.form.vin_id_ocupacoes_cbo = pData.source.ocp_id;
    },
    removerRelationship(pListItem) {
      this.$emit("remove-employee-relationship", pListItem.vin_id);
    },
    showHistory(pListItem) {
      this.$emit("show-historic", pListItem.vin_id);
    },
    cleanFields() {
      this.suggests.occupation = null;
      this.form = {
        vin_id_especializacoes: null,
        vin_id_tipos_vinculo: null,
        vin_carga_horaria: null,
        vin_id_ocupacoes_cbo: null,
        vin_admissao: null,
      };
    },
    cancelEditRelationship() {
      this.currentItemActived = null;
      this.cleanFields();
      this.editMode = false;
      this.selectedRelationshipId = null;
      this.$store.commit("Employee/RESET_RELATIONSHIP");
    },
    editRelationship(pItem, listIdx) {
      this.currentItemActived = listIdx;
      this.editMode = true;
      this.selectedRelationshipId = pItem.vin_id;
      this.suggests.occupation = pItem.occupationEsus.ocp_nome;
      this.form.vin_id_especializacoes = pItem.specialization.esp_id;
      this.form.vin_id_tipos_vinculo = pItem.employeeRelationshipType.tpv_id;
      this.form.vin_carga_horaria = pItem.vin_carga_horaria;
      this.form.vin_id_ocupacoes_cbo = pItem.occupationEsus.ocp_id;
      this.form.vin_admissao = moment(pItem.vin_admissao).format("DD/MM/YYYY");
      this.$store.commit("Employee/SET_RELATIONSHIP", {
        ...this.form,
        vin_id: pItem.vin_id,
      });
    },
    async updateRelationship() {
      const variables = {
        employeeRelationshipId: this.selectedRelationshipId,
        employeeRelationship: {
          vin_id_especializacoes: this.form.vin_id_especializacoes,
          vin_id_tipos_vinculo: this.form.vin_id_tipos_vinculo,
          vin_id_ocupacoes_cbo: this.form.vin_id_ocupacoes_cbo,
          vin_carga_horaria: this.form.vin_carga_horaria,
          vin_admissao: this.$utils.date.BrazilianDateToDatabase(
            this.form.vin_admissao,
          ),
        },
      };

      try {
        await this.$store.dispatch(
          "Employee/UPDATE_EMPLOYEE_RELATIONSHIP_BY_ID",
          variables,
        );
        this.$toaster.success("Vínculo atualizado com sucesso!");
        this.$emit("relationship-updated");
        this.cancelEditRelationship();
      } catch (e) {
        this.$toaster.error(e.message, "O vínculo não pode ser atualizado");
      }
    },
    async activateRelationship({ vin_id }) {
      const variables = {
        vin_id: this.selectedRelationshipId,
        status: true,
      };
      try {
        await this.$store.dispatch(
          "Employee/ENABLE_DISABLE_RELATIONSHIP",
          variables,
        );
        this.$toaster.success("Vínculo ativado com sucesso!");
        this.$emit("relationship-updated");
        this.cancelEditRelationship();
      } catch (e) {
        this.$toaster.error("O vínculo não pode ser ativado");
      }
    },
    async deactivateRelationship(pData) {
      const variables = {
        vin_id: this.selectedRelationshipId,
        status: false,
        shutdownReason: {
          dlv_id_vinculos: this.selectedRelationshipId,
          dlv_id_motivos_desligamento: pData.dlv_id_motivos_desligamento,
          dlv_codigo: pData.dlv_codigo,
          dlv_desligamento: pData.dlv_desligamento,
        },
      };
      try {
        await this.$store.dispatch(
          "Employee/ENABLE_DISABLE_RELATIONSHIP",
          variables,
        );
        this.$toaster.success("Vínculo desativado com sucesso!");
        this.$emit("relationship-updated");
        this.cancelEditRelationship();
      } catch (e) {
        this.$toaster.error(e[0].message, "Falha ao inativar vinculo");
      }
    },
  },
};
</script>
