<template>
  <aside class="modal-history-transfer">
    <RgBaseModal
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <h1 class="title">Histórico de Transferência</h1>
      </div>

      <div slot="body" class="body">
        <div class="info">
          <span class="text">
            Paciente:
            <strong>
              {{ hospitalizationExtra ? hospitalizationExtra.pes_nome : "" }}
            </strong>
          </span>

          <span class="text">
            Pront.Único:
            <strong>
              {{
                hospitalizationExtra
                  ? hospitalizationExtra.pac_prontuario_unico
                  : ""
              }}
            </strong>
          </span>

          <span class="text">
            Núm.Internação:
            <strong>
              {{ hospitalizationExtra ? hospitalizationExtra.int_numero : "" }}
            </strong>
          </span>

          <span class="text">
            Tipo de Leito:
            <strong>
              {{ hospitalizationExtra ? hospitalizationExtra.itl_nome : "" }}
            </strong>
          </span>
        </div>

        <div class="search">
          <RgInput
            v-model="form.searchAll"
            class="input -large"
            label="Busque aqui"
            placeholder="Digite a busca"
          />

          <RgInputDate
            ref="validatorDateInitial"
            v-model="form.initialDate"
            class="input -small"
            label="Início da Transferência"
            placeholder="dd/mm/aaaa"
          />

          <RgInputDate
            ref="validatorDate"
            v-model="form.finalDate"
            class="input -small"
            label="Final da Transferência"
            placeholder="dd/mm/aaaa"
          />

          <div class="filter unselect">
            <RgCleanButton
              small
              data-id="limpar"
              class="clean"
              title="Limpar"
              type="button"
              @click="cleanSearchForm"
            />

            <RgSearchButton
              ref="btnSubmit"
              small
              data-id="buscar"
              label="Buscar"
              title="Buscar"
              @submit="searchHistory(true)"
            />
          </div>
        </div>

        <SmartTable
          ref="smartTable"
          name="ModalHistoryTransfer"
          :initial-columns="5"
          :max-register="5"
          :item-per-page="pagination.limit"
          :columns="COLUMNS"
          :body="items"
          :total="totalItems"
          @pagination="getPagination"
        />
      </div>
    </RgBaseModal>
  </aside>
</template>

<script>
import { RgBaseModal, RgInput, RgInputDate } from "~tokio/primitive";
import RgSearchButton from "~tokio/primitive/button/rg-search-button/RgSearchButton";
import RgCleanButton from "~tokio/primitive/button/rg-clean-button/RgCleanButton";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";
import moment from "moment";

export default {
  name: "ModalHistoryTransfer",
  components: {
    RgBaseModal,
    RgInput,
    RgInputDate,
    RgSearchButton,
    RgCleanButton,
    SmartTable,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    hospitalizationExtra: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        searchAll: "",
        initialDate: "",
        finalDate: moment().format("DD/MM/YYYY"),
      },
      pagination: {
        limit: 5,
        offset: 0,
        current: 1,
      },
      items: [],
      totalItems: 0,
    };
  },
  computed: {},
  watch: {
    hospitalizationExtra(pItem) {
      if (pItem) {
        this.searchHistory(true);
      } else {
        this.close();
      }
    },
  },
  created() {
    this.COLUMNS = [
      { name: "Nome do Paciente", key: "pes_nome" },
      { name: "Prontuário", key: "pac_prontuario_unico" },
      { name: "Nome do Usuário", key: "usu_nome" },
      { name: "Número de Internação", key: "int_numero" },
      { name: "Nome do Leito (Origem)", key: "inl_nome_origem" },
      { name: "Unidade de Saúde (Origem)", key: "uns_nome_origem" },
      { name: "Setor (Origem)", key: "set_nome_origem" },
      { name: "Local de Atendimento (Origem)", key: "lca_nome_origem" },
      { name: "Situação do Leito (Origem)", key: "isl_nome_origem" },
      { name: "Nome do Leito (Destino)", key: "inl_nome_destino" },
      { name: "Unidade de Saúde (Destino)", key: "uns_nome_destino" },
      { name: "Setor (Destino)", key: "set_nome_destino" },
      { name: "Local de Atendimento (Destino)", key: "lca_nome_destino" },
      { name: "Situação do Leito (Destino)", key: "isl_nome_destino" },
      { name: "Tipo do Leito", key: "itl_nome" },
      { name: "Data e Hora", key: "data_hora" },
    ];
  },
  methods: {
    async searchHistory(submitFromButton = false) {
      try {
        this.$refs.btnSubmit.actionSubmit();
        const hasPciId =
          this.hospitalizationExtra && this.hospitalizationExtra.pci_id > 0;
        if (hasPciId && this.show) {
          if (submitFromButton) {
            this.$refs.smartTable.resetLimitOffset();
          }
          const { rows, count } = await this.$store.dispatch(
            "Hospitalization/Hospitalizations/SEARCH_CUSTOM_HISTORY_TRANSFER",
            {
              pci_id: this.hospitalizationExtra.pci_id,
              initialDate: this.$utils.date.BrazilianDateToDatabase(
                this.form.initialDate,
              ),
              finalDate: this.$utils.date.BrazilianDateToDatabase(
                this.form.finalDate,
              ),
              searchAll: this.form.searchAll,
              limit: this.pagination.limit,
              offset: this.pagination.offset,
            },
          );

          this.items = rows;
          this.totalItems = count;

          this.$refs.btnSubmit.actionDone();
        } else {
          if (this.show) throw new Error("Não existe pci_id");
        }
      } catch (pErr) {
        this.$refs.btnSubmit.fail();
        console.log("pErr", pErr);
      }
    },

    getPagination(pPagination) {
      this.pagination = pPagination;
      this.searchHistory();
    },

    cleanSearchForm() {
      this.clear();
      this.form.searchAll = "";
      this.form.initialDate = "";
      this.form.finalDate = moment().format("DD/MM/YYYY");
      this.$refs.validatorDate.cleanValidate();
      this.$refs.validatorDateInitial.cleanValidate();
      this.searchHistory();
    },

    clear() {
      this.items = [];
      this.totalItems = 0;
      this.pagination.limit = 5;
      this.pagination.offset = 0;
      this.pagination.current = 1;
    },

    close() {
      this.clear();
      this.$emit("close");
    },
  },
};
</script>
