<template lang="html">
  <svg
    width="512"
    height="512"
    version="1.1"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        id="path-1"
        d="m0.90237 0.19526c0.19526 0.19526 0.19526 0.51184 0 0.70711s-0.51184 0.19526-0.70711 0-0.19526-0.51184 0-0.70711 0.51184-0.19526 0.70711 0"
      />
    </defs>
    <use transform="translate(9.967 12.951)" xlink:href="#path-1" />
    <use transform="translate(7.967 14.951)" xlink:href="#path-1" />
    <path
      transform="translate(10.918 13.902)"
      d="m1.8047 0.39052c0.39052 0.39052 0.39052 1.0237 0 1.4142-0.39052 0.39052-1.0237 0.39052-1.4142 0-0.39052-0.39052-0.39052-1.0237 0-1.4142 0.39052-0.39052 1.0237-0.39052 1.4142 0"
    />
    <path
      transform="translate(9.561)"
      d="m4.59253 0c-.553711 0-1.0752.244141-1.43018.669922l-3.16235 3.79462c.233032.107178.457825.233765.669189.384582.595642.425293 1.0329.982178 1.3338 1.5954l3.78192-3.15204c.425781-.35498.669922-.876465.669922-1.43018-.0000002-1.02686-.835449-1.8623-1.8623-1.8623z"
    />
    <path
      transform="translate(-.016 4.75)"
      d="m9.6655 0.91357v-4.882e-4c-1.2803-0.91309-3.0566-0.81104-4.2222 0.24072-0.72803 0.65723-2.9429 2.6567-5.0181 3.1079l-0.42529 0.092773s0.031738 1.396 0.45483 2.0256c0.82867-0.09259 1.4995-0.26398 1.7299-0.49432-0.50928 0.60236-0.89667 1.0379-1.1968 1.3637 0.24683 0.3299 0.55078 0.64307 0.89221 0.94507 1.2242-0.52087 2.3162-1.4211 2.6724-1.7774-0.71802 1.1967-1.3019 2.0955-1.609 2.5585 0.68323-0.39752 1.5159-0.91418 2.2952-1.4929-0.29132 0.29132-0.67346 1.2065-0.98254 2.2517 0.3446 0.16626 1.3135 0.51459 1.3135 0.51459 1.4878-0.11328 4.0718-3.6182 4.9419-4.959 0.93799-1.4448 0.56641-3.3677-0.84619-4.3765z"
    />
  </svg>
</template>
<script>
export default {
  name: "IconClean",
};
</script>
