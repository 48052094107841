import Bed from "$hospitalization/bed/store";
import Hospitalizations from "$hospitalization/hospitalizations/store";
import actions from "./actions";
import Report from "$hospitalization/report/store";
import Queue from "$hospitalization/hospitalizations/queue/store";

export default {
  namespaced: true,
  actions,
  modules: {
    Bed,
    Hospitalizations,
    Report,
    Queue,
  },
};
