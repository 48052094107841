<template>
  <Modulebox
    :title="getTitle"
    class="appointment-schedule-form-per-day"
    :granted="hasPermission"
  >
    <div v-if="existRoutePerPeriods" slot="info">
      <button
        v-show="typeSchedule"
        class="button-schedule -color1"
        :disabled="!hasPermissionMultipleSchedule"
        :class="{ disable: !hasPermissionMultipleSchedule }"
        @click="changeTypeOfSchedule"
      >
        Agendamento Único
      </button>
      <button
        v-show="!typeSchedule && !selectedAppointmentData"
        class="button-schedule -color2"
        :disabled="!hasPermissionMultipleSchedule"
        :class="{ disable: !hasPermissionMultipleSchedule }"
        @click="changeTypeOfSchedule"
      >
        Múltiplo Agendamento
      </button>
    </div>

    <QuickAppointmentScheduleForm
      ref="form"
      class="appointment-schedule-form-per-day-form"
      :typeSchedule="typeSchedule"
      :employeeData="employeeData"
      :appointmentData="appointmentData"
      @saveWithAvailableHour="saveForm"
      @canSave="onCanSave"
      @saveFormByModalConfirmSchedule="onSaveFormByModalConfirmSchedule"
    />

    <div class="footer">
      <RgCleanButton
        v-show="!appointmentData.con_id"
        small
        type="button"
        title="Limpar"
        @click="confirmCleanForm"
      />

      <RgCancelButton
        ref="cancelButton"
        medium
        class="cancel"
        @click="cancel"
      />

      <RgSaveButton
        ref="saveButton"
        medium
        class="save"
        type="button"
        :disabled="!canSave"
        @click="saveForm"
      />
    </div>

    <ModalConfirmFieldClearing
      ref="confirmClearing"
      :show="modalConfirmFieldClearing"
      @getYes="getCleanFormMethod"
      @getOut="closeModalConfirmFieldClearing"
    />
  </Modulebox>
</template>
<script>
import {
  RgCleanButton,
  RgSaveButton,
  ModalConfirmFieldClearing,
  RgCancelButton,
} from "~tokio/primitive";
import Modulebox from "~tokio/foundation/modulebox/Modulebox";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb.js";
import QuickAppointmentScheduleForm from "$appointment/common/component/quick-appointment-schedule-form/QuickAppointmentScheduleForm";
import { mapGetters } from "vuex";

export default {
  name: "AppointmentFormSchedule",
  components: {
    Modulebox,
    QuickAppointmentScheduleForm,
    RgSaveButton,
    RgCleanButton,
    RgCancelButton,
    ModalConfirmFieldClearing,
  },
  data: () => {
    return {
      appointment_id: 2,
      scheduleLoadInfo: null,
      typeSchedule: false,
      modalConfirmFieldClearing: false,
      canSave: false,
    };
  },
  computed: {
    ...mapGetters({
      hasTypeSelected: "Appointment/Schedule/GET_TYPE_SCHEDULE",
    }),

    isEditing() {
      return this.appointmentData != null && this.appointmentData.con_id > 0;
    },
    employeeData() {
      return this.$store.state.Appointment.Schedule.selected_employee_data;
    },
    appointmentData() {
      return Object.assign(
        {},
        this.selectedAppointmentData,
        this.scheduleLoadInfo,
      );
    },
    selectedAppointmentData() {
      return this.$store.state.Appointment.Schedule.selected_appointment_data;
    },
    hasPermission() {
      return this.$Permissions.global.has("consulta.agendamento");
    },
    existRoutePerPeriods() {
      return this.$route.name === "appointment.schedule.per-periods.form";
    },
    getTitle() {
      if (this.isEditing) {
        return "Editar o Agendamento de Consulta";
      } else if (this.existRoutePerPeriods) {
        return this.typeSchedule
          ? "Agendamento Múltiplo de Consultas"
          : "Agendamento Único de Consulta";
      } else {
        return "Agendamento de Consulta";
      }
    },
    hasPermissionMultipleSchedule() {
      return this.$Permissions.sector.has(
        "consulta.agendamentoCompleto.multiplosAgendamentos",
        this.employeeData.set_id,
      );
    },
  },

  beforeDestroyed() {
    const existThisRouteFromSchedulePerDay = ValidateIfRouteExistInBreadscrumb(
      "/appointment/schedule/form",
      this.$route.meta.breadcrumb,
    );

    const existThisRouteFromSchedulePerPeriods = ValidateIfRouteExistInBreadscrumb(
      "/appointment/schedule/per-periods/form",
      this.$route.meta.breadcrumb,
    );

    if (
      !existThisRouteFromSchedulePerDay &&
      !existThisRouteFromSchedulePerPeriods
    ) {
      this.removeSelectedData();
      this.$refs.form.cleanForm();
      this.typeSchedule = false;
      this.$store.commit("Appointment/Schedule/CLEAN_FORM_APPOINTMENT");
    }
  },

  mounted() {
    const existThisRouteFromSchedulePerDay = ValidateIfRouteExistInBreadscrumb(
      "/appointment/schedule/form",
      this.$route.meta.breadcrumb,
    );

    if (!existThisRouteFromSchedulePerDay && this.hasTypeSelected !== null) {
      this.typeSchedule = this.hasTypeSelected;
    }

    if (this.selectedAppointmentData) {
      // Complementa os dados do agendamento carregando mais informaçoes necessarias que serão concatenadas apenas
      // nesta view no computed: appointmentData
      this.$store
        .dispatch("Appointment/Schedule/SCHEDULE_LOAD", {
          intIdConsulta: this.appointmentData.con_id,
        })
        .then((data) => {
          if (data.con_hora && data.con_hora.length > 5) {
            data.con_hora = data.con_hora.substring(0, 5);
          }
          this.scheduleLoadInfo = data;
        })
        .catch((pErr) => {
          console.log(pErr);
        });
    }
  },
  methods: {
    onCanSave(val) {
      this.canSave = val;
    },

    cancel() {
      const existThisRouteFromSchedulePerDay = ValidateIfRouteExistInBreadscrumb(
        "/appointment/schedule/per-day",
        this.$route.meta.breadcrumb,
      );

      const existThisRouteFromSchedulePerPeriods = ValidateIfRouteExistInBreadscrumb(
        "/appointment/schedule/per-periods",
        this.$route.meta.breadcrumb,
      );

      if (existThisRouteFromSchedulePerDay) {
        this.$router.push({ name: "appointment.schedule.per-day" });
      }

      if (existThisRouteFromSchedulePerPeriods) {
        this.typeSchedule = false;

        this.$router.push({ name: "appointment.schedule.per-periods" });
      }
    },

    getCleanFormMethod() {
      this.$refs.form.cleanForm();
      this.modalConfirmFieldClearing = false;
    },

    confirmCleanForm() {
      this.modalConfirmFieldClearing = true;
    },

    closeModalConfirmFieldClearing() {
      this.modalConfirmFieldClearing = false;
    },

    changeTypeOfSchedule() {
      if (!this.hasPermissionMultipleSchedule) {
        this.$toaster.error("Usuário sem permissão para Múltiplo Agendamento.");
        return;
      }

      this.typeSchedule = !this.typeSchedule;
      this.$store.commit(
        "Appointment/Schedule/SELECT_TYPE_SCHEDULE",
        this.typeSchedule,
      );
    },

    removeSelectedData() {
      // this.$store.commit("Appointment/Schedule/UNSELECT_EMPLOYEE_DATA");
      this.$store.commit("Appointment/Schedule/UNSELECT_APPOINTMENT_DATA");
    },

    onSaveFormByModalConfirmSchedule(result) {
      this.pushRouter(result);
    },

    async saveForm() {
      try {
        const result = await this.$refs.form.saveForm();
        this.pushRouter(result);
      } catch (err) {
        console.log("err", err);
      }
    },

    pushRouter(result) {
      const existThisRouteFromSchedulePerPeriods = ValidateIfRouteExistInBreadscrumb(
        "/appointment/schedule/per-periods",
        this.$route.meta.breadcrumb,
      );
      if (result !== false) {
        this.removeSelectedData();
        if (typeof result === "number") {
          const params = {
            isAppontmentSchedule: true,
            appointmentId: result,
          };

          existThisRouteFromSchedulePerPeriods
            ? this.$router.push({
                name: "appointment.schedule.per-periods",
                params,
              })
            : this.$router.push({
                name: "appointment.schedule.per-day",
                params,
              });
        } else {
          existThisRouteFromSchedulePerPeriods
            ? this.$router.push({
                name: "appointment.schedule.per-periods",
              })
            : this.$router.push({
                name: "appointment.schedule.per-day",
              });
        }
      }
    },
  },
};
</script>
