import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  return AuthLegacyRequest.post(
    "exames2/controller-exame-escala-agendamento/carregar-dias-atendente",
    variables,
  )
    .then(({ data }) => {
      const snap = data.dados;
      if (snap === "Erro ao carregar os dias do atendente") {
        throw snap;
      }
      return snap;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
