/*
 * Recebe um objeto e verifica se os campos estão preenchidos
 * Caso pelo menos 1 campo esteja preenchido todos devem estar
 * @param pValue {Object} Objeto de documento
 */
const CheckIfFieldsAreFilled = (pValue) => {
  const values = Object.values(pValue);

  const fieldsFilled = values.filter((field) => field);
  const hasOneFilled = fieldsFilled.length > 0;

  if (hasOneFilled) {
    const allFilled = values.every((field) => field);

    return allFilled;
  }

  return true;
};

export { CheckIfFieldsAreFilled };
export default CheckIfFieldsAreFilled;
