import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import PersonDetail from "$patient/view/person-detail/PersonDetail";

export default {
  path: "/register/patient/detail",
  name: "register.patient.detail",
  components: {
    default: PersonDetail,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Pacientes", link: "/register/patient/search" },
      { label: "Detalhes" },
    ],
  },
};
