<template>
  <section class="person-contacts">
    <FormBase title="Contatos" class="form-base-contacts">
      <div class="grid-contacts">
        <div class="row-1">
          <div class="contact">
            <RgInput
              ref="contacts"
              v-model="contacts"
              :class="{ disable: cantEdit }"
              :disabled="cantEdit"
              :rules="{ required: required }"
              :change="disableOrEnabledRequired"
              :maxlength="20"
              label="Contato"
              placeholder="Informe o nome do contato"
            />
          </div>

          <RgSelectDddInputPhone
            ref="phone"
            v-model="tel"
            :class="{ disable: cantEdit }"
            :rules="{
              required: required,
              fn: validatePhone,
            }"
            :disabled="cantEdit"
            :change="disableOrEnabledRequired"
            @getDdd="getDddData"
          />

          <SmallButton
            :class="{ disable: existPhoneData }"
            :disabled="existPhoneData"
            :backgroundColor="'#1E88A9'"
            class="btnPlus"
            title="Confirmar"
            @click="savePhone"
          >
            <IconCheck slot="icon" class="icon" />
          </SmallButton>

          <SmallButton
            :backgroundColor="'#1E88A9'"
            class="btnClean"
            title="Limpar"
            @click="cleanContact"
          >
            <IconClean slot="icon" class="icon" />
          </SmallButton>

          <RgToggleButton
            class="receiveNotification"
            description="Receber notificações por SMS ?"
            :class="{ disable: !isPhoneNumber }"
            :disabled="!isPhoneNumber"
            :value="receiveNotification"
            :valueSync="true"
            @change="handleReceiveNotification"
          />

          <RgInputEmail
            ref="email"
            v-model="email"
            class="mail"
            label="E-mail"
            placeholder="Informe o e-mail"
            :class="{ disable: cantEdit }"
            :disabled="cantEdit"
            :rules="{ required: isPrivateSector }"
            :maxlength="75"
          />
        </div>
        <div class="phone-list">
          <div v-for="(item, idx) in phones" :key="idx" class="line">
            <span>
              Contato:
              <strong> {{ item.contact }} </strong>
            </span>

            <span>
              Tel.:
              <strong> {{ `(${item.ddd.ddd_codigo}) ${item.tel}` }} </strong>
            </span>

            <div class="buttons">
              <span v-if="item.notification" class="sms"> SMS </span>

              <button
                :class="{ disable: cantEdit }"
                :disabled="cantEdit"
                class="edit"
                title="Editar"
                @click.stop.prevent="editContact(item, idx)"
              >
                <IconPencil class="svg" />
              </button>

              <button
                :class="{ disable: cantEdit }"
                :disabled="cantEdit"
                class="remove"
                title="Apagar"
                @click.stop.prevent="deleteContact(idx)"
              >
                <IconClose class="svg" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </FormBase>
  </section>
</template>

<script>
import { toast, AlertError } from "~tokio/primitive/notification";
import { mapGetters } from "vuex";
import { IconCheck, IconPencil } from "~tokio/primitive/icon/symbols";
import {
  RgInput,
  RgInputEmail,
  IconClose,
  RgToggleButton,
  IconClean,
  SmallButton,
} from "~tokio/primitive";
import FormBase from "~tokio/foundation/form-base/FormBase";
import RgSelectDddInputPhone from "$person/common/components/input/rg-select-ddd-input-phone/RgSelectDddInputPhone";

export default {
  name: "PersonContacts",
  components: {
    FormBase,
    RgInput,
    RgInputEmail,
    IconCheck,
    IconPencil,
    IconClose,
    RgToggleButton,
    RgSelectDddInputPhone,
    IconClean,
    SmallButton,
  },
  props: {
    cantEdit: {
      type: Boolean,
      default: false,
    },
    requiredOne: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isPrivateSector: false,
      contacts: "",
      notificationId: null,
      tel: null,
      ddd: null,
      phones: [],
      existIndexPhone: null,
      notifications: [],
      receiveNotification: false,
      email: null,
      required: false,
      hasSms: false,
    };
  },
  computed: {
    ...mapGetters({
      existsPesId: "Person/Patient/EXISTS_PES_ID",
      contactData: "Person/Patient/GET_CONTACT",
    }),

    existPhoneData() {
      const existContact = this.contacts && this.contacts.length > 2;
      const existTelNumber = this.tel && this.tel.length > 8;
      return !(existContact && this.ddd && existTelNumber);
    },

    isPhoneNumber() {
      const number = this.removeDashesAndDots(this.tel);
      const hasNineDigits = number && number.length === 9;

      const firstNumberIsNine = hasNineDigits
        ? Number(number.substring(0, 1)) === 9
        : false;

      return hasNineDigits && firstNumberIsNine;
    },
  },

  watch: {
    contactData(pValue) {
      if (pValue) {
        this.mountData(pValue);
      }
    },
    tel(pValue, pPreviously) {
      const isDifferent = pValue !== pPreviously;
      const isToDisableNotification = isDifferent && !this.isPhoneNumber;

      if (isToDisableNotification) {
        this.receiveNotification = false;
      }
    },
  },

  mounted() {
    this.isPrivateSector = this.$store.getters["Login/GET_PRIVATE_SECTOR"];
    this.required = this.isPrivateSector;
  },

  methods: {
    mountData(pValue) {
      this.phones = [];
      const telephones = pValue.telephones ? pValue.telephones.rows : [];
      const notifications = pValue.notification ? pValue.notification.rows : [];

      this.setTelephoneAndNotification(telephones, notifications);
      this.email = pValue.emails;
      this.VerifiySmsToSetRequired();
    },

    setTelephoneAndNotification(telephones, notifications) {
      if (
        telephones &&
        telephones.length > 0 &&
        (!notifications || (notifications && notifications.length === 0))
      ) {
        telephones.forEach((item) => {
          const number = item.tel_numero.replace(/-/g, "");
          const formatNumber = number.replace(/^(\d{4,5})(\d{4}$)/gi, "$1-$2");

          this.phones.push({
            id: item.tel_id,
            contact: this.$utils.str.RemoveExtraSpaces(item.tel_recado),
            tel: formatNumber,
            ddd: item.ddd,
          });
        });
      }

      if (notifications && notifications.length > 0 && !telephones) {
        notifications.forEach((item) => {
          if (item.nop_celular) {
            const number = item.nop_celular.replace(/-/g, "");
            const formatNumber = number.replace(
              /^(\d{4,5})(\d{4}$)/gi,
              "$1-$2",
            );

            this.phones.push({
              id: item.tel_id,
              contact: "",
              tel: formatNumber,
              ddd: item.ddd,
            });
          }
        });
      }

      if (
        telephones &&
        telephones.length > 0 &&
        notifications &&
        notifications.length > 0
      ) {
        telephones.forEach((pTelephone) => {
          const number = pTelephone.tel_numero.replace(/-/g, "");
          const formatNumber = number.replace(/^(\d{4,5})(\d{4}$)/gi, "$1-$2");

          const phone = {
            id: pTelephone.tel_id,
            contact: this.$utils.str.RemoveExtraSpaces(pTelephone.tel_recado),
            tel: formatNumber,
            ddd: pTelephone.ddd,
            notification: false,
          };

          notifications.forEach((pNotfications) => {
            if (
              pTelephone.tel_numero === pNotfications.nop_celular &&
              pTelephone.ddd.ddd_codigo === pNotfications.ddd.ddd_codigo
            ) {
              phone.notification = true;
              phone.nop_id = pNotfications.nop_id;
            }
          });
          this.phones.push(phone);
        });
      }
    },

    validatePhone(pValue, pErrors) {
      if (!pValue) {
        this.$refs.phone.cleanPhone();
        return true;
      }
      if (pValue && pValue.length < 9) {
        pErrors.push("Digite todos os números do telefone.");
        return false;
      } else {
        if (!this.ddd) {
          pErrors.push("Selecione um DDD.");
          return false;
        }
      }
      return true;
    },

    validateRequiredPhone() {
      if (this.requiredOne && !this.phones.length > 0) {
        return AlertError(
          "Obrigatório o preenchimento de ao menos um telefone",
        );
      }
      if (!this.hasPhoneToReceiveSms()) {
        return AlertError(
          "Obrigatório fornecer um celular com recebimento de SMS",
        );
      }

      return true;
    },

    hasPhoneToReceiveSms() {
      if (!this.isPrivateSector) return true;

      const hasPhoneToReceiveSms = this.phones.some(
        (phone) => phone.notification,
      );

      return hasPhoneToReceiveSms;
    },

    getDddData(pValue) {
      this.ddd = pValue;
    },

    removeDashesAndDots(pText) {
      return pText ? pText.replace(/[^\d]/g, "") : null;
    },

    getContactForm() {
      let telephones = [];

      telephones = this.phones.map((phone) => {
        return {
          tel_id: phone.id,
          tel_recado: phone.contact,
          tel_numero: this.removeDashesAndDots(phone.tel),
          tel_id_ddds: phone.ddd.ddd_id,
          tel_ddd: Number(phone.ddd.ddd_codigo),
          tel_notification: phone.notification,
          nop_id: phone.nop_id,
        };
      });

      this.email = this.$utils.str.RemoveExtraSpaces(this.email);

      return {
        email: this.email ? this.email.toLowerCase() : null,
        phones: telephones,
      };
    },

    savePhone() {
      const existNumber = this.phones.some((phone, index) => {
        const equalTel = phone.tel === this.tel;
        const equalDdd = this.ddd.ddd_codigo === phone.ddd.ddd_codigo;
        const isAnotherPhone = index !== this.existIndexPhone;

        return equalTel && equalDdd && isAnotherPhone;
      });

      const hasToReceiveSMS = this.phones.some((phone, index) => {
        const isAnotherPhone = index !== this.existIndexPhone;
        return phone.notification && isAnotherPhone && this.receiveNotification;
      });

      if (existNumber) {
        toast.error("Esse número de telefone já foi cadastrado.");
        return;
      }

      if (hasToReceiveSMS) {
        toast.error("Já existe um celular cadastrado para receber SMS");
        return;
      }

      const isAddContact = this.existIndexPhone === null;

      const telephone = {
        contact: this.contacts,
        tel: this.tel,
        ddd: this.ddd,
        notification: this.receiveNotification,
        nop_id: this.notificationId,
      };

      if (isAddContact) {
        this.phones.push(telephone);
      } else {
        telephone.notification = this.receiveNotification;
        this.phones[this.existIndexPhone] = telephone;
      }

      this.cleanContact();
    },

    cleanContact() {
      this.cleanPhoneForm();
      this.cleanReceivedNotification();
      this.cleanValidate();
      this.VerifiySmsToSetRequired();
    },

    editContact(pPhone, pIndex) {
      this.contacts = pPhone.contact;
      this.tel = pPhone.tel;
      this.ddd = pPhone.ddd;
      this.existIndexPhone = pIndex;
      this.receiveNotification = pPhone.notification;
      this.notificationId = this.phones[pIndex].nop_id;

      this.$refs.phone.fillValue(pPhone.tel, pPhone.ddd);
    },

    deleteContact(pIndex) {
      this.phones.splice(pIndex, 1);
      this.VerifiySmsToSetRequired();
    },

    handleReceiveNotification(pValue) {
      this.receiveNotification = this.isPhoneNumber ? pValue : false;
    },

    cleanPhoneForm() {
      this.contacts = "";
      this.tel = null;
      this.ddd = null;
      this.existIndexPhone = null;
      this.notificationId = null;
      this.$refs.phone.cleanPhone();
    },

    cleanReceivedNotification() {
      this.receiveNotification = false;
      this.$refs.phone.cleanPhone();
    },

    cleanEmailForm() {
      this.email = "";
      this.$refs.email.cleanValidate();
    },

    cleanForm() {
      this.cleanEmailForm();
      this.cleanPhoneForm();
      this.cleanReceivedNotification();
      this.cleanValidate();
      this.phones = [];
      this.email = "";
      this.disableOrEnabledRequired();
    },

    disableOrEnabledRequired() {
      this.required =
        (this.isPrivateSector && !this.hasSms) || !!this.contacts || !!this.tel;

      if (!this.required) {
        this.cleanValidate();
      }
    },

    VerifiySmsToSetRequired() {
      this.hasSms = this.hasPhoneToReceiveSms();
      this.required = !this.hasSms;
    },

    loadFromCadsus(pData) {
      if (!pData) return;
      this.cleanForm();
      if (pData.Emails) {
        if (
          !Array.isArray(pData.Emails) &&
          pData.Emails.Email &&
          pData.Emails.Email.validado
        ) {
          this.email = pData.Emails.Email.descricaoEmail.toLowerCase();
        } else {
          for (const email of pData.Emails) {
            if (email.tipoEmail === "P" && email.validado) {
              this.email = email.descricaoEmail.toLowerCase();
              break;
            }
          }
        }
      }
    },

    cleanValidate() {
      this.$refs.contacts.cleanValidate();
      this.$refs.phone.cleanValidate();
      this.required = false;
    },
  },
};
</script>
