import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query printPatientToken($pes_id: Int!, $uns_id: Int!, $uns_nome: String!) {
    printPatientToken(pes_id: $pes_id, uns_id: $uns_id, uns_nome: $uns_nome) {
      html
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    return data.printPatientToken.html;
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors);
  }
};
