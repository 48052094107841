<style src="./UserProfile.scss" lang="scss" scoped></style>
<template lang="html">
  <section ref="elmUserProfile" class="user-profile" tabindex="0">
    <div class="user-profile--profile-photo">
      <img :src="avatar" />
    </div>
    <div class="user-profile--menu">
      <span class="user-profile--name">{{ username }}</span>
      <span class="user-profile--occupation">{{ occupation }}</span>

      <span
        title="Troque a Unidade de saúde Atual"
        class="unit-health-selector"
        @click.stop="openUnitHealth"
      >
        <span class="user-profile--health-unit-title">Unidade de Saúde:</span>
        <span class="user-profile--health-unit-name">{{ unitHealth }}</span>
      </span>
      <span
        title="Troque a Competência Atual"
        class="unit-health-selector"
        @click.stop="openPeriodChanger"
      >
        <span class="user-profile--health-unit-title"
          >Competência Ambulatorial:
        </span>
        <span class="user-profile--health-unit-name">{{ currentPeriod }}</span>
      </span>
      <span
        title="Troque a Competência Atual"
        class="unit-health-selector"
        @click.stop="openHospitalizationPeriodChanger"
      >
        <span class="user-profile--health-unit-title"
          >Apresentação da AIH:
        </span>
        <span class="user-profile--health-unit-name">{{
          currentHospitalizationPeriod
        }}</span>
      </span>
      <span
        title="Importe a Tabela Unificada"
        class="change-unified-table unit-health-selector"
        @click.stop="openUnifiedTableImporter"
      >
        <span class="user-profile--health-unit-title"
          >Versão Tabela Unificada:
        </span>
        <span class="user-profile--health-unit-name">{{
          currentUnifiedTableVersion
        }}</span>
      </span>
      <ul class="user-profile--menu-item">
        <li
          class="user-profile--action-about"
          title="Sobre o Sistema"
          @click.stop="about"
        >
          <div class="user-profile--about-icon">
            <IconFile />
          </div>
          Sobre o Sistema
        </li>
        <li
          class="user-profile--action-logoff"
          title="Sair do sistema"
          @click.stop="logoff"
        >
          <div class="user-profile--power-icon">
            <IconPower />
          </div>
          Sair do Sistema
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import { IconPower, IconFile } from "~tokio/primitive/";
import GetCurrentPeriod from "$billing/bdsia/action/GetCurrentPeriod";

export default {
  name: "UserProfile",
  components: {
    IconPower,
    IconFile,
  },
  data() {
    return {
      occupation: "",
    };
  },
  computed: {
    username() {
      return this.$store.getters["Login/GET_USER_NAME"];
    },
    avatar() {
      return "/static/person-image/photo-default.png";
    },
    unitHealth() {
      return this.$store.getters["Login/GET_UNIT_HEALTH_NAME"];
    },
    currentPeriod() {
      return this.$store.getters["Billing/BDSia/GET_PERIOD_DATE"];
    },
    currentHospitalizationPeriod() {
      const cmp = this.$store.getters[
        "Billing/PeriodHospitalizationBilling/GET_PERIOD_HOSPITALIZATION_BILLING"
      ];
      if (!cmp) return;
      let year = parseInt(cmp.substring(0, 4));
      let month = parseInt(cmp.substring(4, 6));

      if (month === 12) {
        month = 1;
        year += 1;
      } else {
        month = month + 1;
      }

      return month.toString().padStart(2, "0") + year.toString();
    },
    currentUnifiedTableVersion() {
      return this.$store.getters["Billing/BDSia/GET_UNIFIED_TABLE_VERSION"];
    },
    unitHealthId() {
      return this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    },
    loginInfo() {
      return this.$store.getters["Login/GET_COMPLETE_LOGIN_INFO"];
    },
  },
  mounted() {
    GetCurrentPeriod().then(() => {});
    const pPeriod = {
      cft_id_unidades_saudes: this.unitHealthId,
      cft_id_usuarios: this.loginInfo.user.usu_id,
    };
    this.$store
      .dispatch(
        "Billing/PeriodHospitalizationBilling/GET_PERIOD_HOSPITALIZATION_BILLING",
        pPeriod,
      )
      .then((cmp) => {
        this.$store.commit(
          "Billing/PeriodHospitalizationBilling/SET_PERIOD_HOSPITALIZATION_BILLING",
          cmp,
        );
      });
  },
  methods: {
    logoff() {
      this.$store.dispatch("Login/LOGOFF");
      this.$router.push({ name: "Login" });
    },
    openUnitHealth() {
      this.$router.push({
        name: "accesscontrol.selectUnitHealth",
      });
    },
    about() {
      this.$router.push({
        name: "aboutsoftware",
      });
    },
    openPeriodChanger() {
      this.$refs.elmUserProfile.blur();
      this.$router.push({
        name: "billing.definePeriod",
      });
    },
    openHospitalizationPeriodChanger() {
      this.$refs.elmUserProfile.blur();
      this.$router.push({
        name: "billing.defineHospitalizationPeriod",
      });
    },
    openUnifiedTableImporter() {
      this.$refs.elmUserProfile.blur();
      this.$router.push({
        name: "tabelaunificada.import",
      });
    },
  },
};
</script>
