import AuthRequest from "~common/request/AuthRequest";

export default async (pCep) => {
  return new Promise((resolve, reject) => {
    const cepRaw = pCep.replace(/-/g, "");
    AuthRequest.get("address/cep/search-cep", { cep: cepRaw })
      .then((result) => {
        resolve(result.data);
      })
      .catch((pError) => {
        reject(pError.response.errors);
      });
  });
};
