<template lang="html">
  <svg
    id="Capa_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 45.334 45.334"
    style="enable-background: new 0 0 45.334 45.334"
    xml:space="preserve"
  >
    <g>
      <path
        d="M43.576,35.092L31.152,22.668l12.424-12.424c2.343-2.343,2.343-6.142,0-8.485c-2.343-2.343-6.142-2.343-8.485,0
		L22.666,14.183L10.242,1.758c-2.344-2.343-6.143-2.343-8.485,0c-2.343,2.344-2.343,6.143,0,8.485l12.425,12.424L1.757,35.091
		c-2.343,2.344-2.343,6.143,0,8.485C2.93,44.748,4.464,45.333,6,45.333c1.535,0,3.071-0.586,4.243-1.757l12.423-12.423
		l12.425,12.424c1.173,1.171,2.707,1.757,4.243,1.757s3.071-0.586,4.243-1.757C45.919,41.234,45.919,37.436,43.576,35.092z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: "DeleteCross",
};
</script>
