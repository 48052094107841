<template lang="html">
  <svg
    width="209"
    height="189"
    viewBox="0 0 209 189"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M40.4517 33.7097H30.3388L3.37109 104.5H40.4517V33.7097Z"
      fill="#E0E0E2"
    />
    <path
      d="M168.548 33.7097V104.5H205.629L178.661 33.7097H168.548Z"
      fill="#E0E0E2"
    />
    <path
      d="M151.694 104.5C151.694 130.564 130.564 151.694 104.5 151.694C78.436 151.694 57.3066 130.564 57.3066 104.5H3.37109V185.403H205.629V104.5H151.694Z"
      fill="transparent"
    />
    <path
      d="M208.781 103.3L181.813 32.5098C181.315 31.2012 180.06 30.3387 178.661 30.3387H171.919V16.8549C171.919 14.9933 170.41 13.4839 168.548 13.4839H40.4516C38.59 13.4839 37.0806 14.9933 37.0806 16.8549V30.3387H30.3387C28.9396 30.3387 27.6854 31.2012 27.1867 32.5098L0.218915 103.3C0.0954667 103.647 0.0296276 104.011 0.0230437 104.378C0.0230437 104.423 0 104.456 0 104.5V185.403C0 187.265 1.50936 188.774 3.37097 188.774H205.629C207.491 188.774 209 187.265 209 185.403V104.5C209 104.456 208.977 104.423 208.977 104.378C208.97 104.011 208.905 103.647 208.781 103.3ZM176.339 37.0807L200.737 101.129H171.919V37.0807H176.339ZM43.8226 20.2258H165.177V101.129H151.694C149.832 101.129 148.323 102.638 148.323 104.5C148.323 128.703 128.702 148.323 104.5 148.323C80.2975 148.323 60.6774 128.703 60.6774 104.5C60.6774 102.638 59.1681 101.129 57.3064 101.129H43.8226V20.2258ZM32.6612 37.0807H37.0806V101.129H8.26282L32.6612 37.0807ZM202.258 182.032H6.74194V107.871H54.0474C55.8119 134.437 77.8746 155.083 104.5 155.083C131.125 155.083 153.188 134.437 154.953 107.871H202.258V182.032Z"
    />
    <path
      d="M37.0806 3.37097C37.0806 5.23257 38.5899 6.74193 40.4515 6.74193H168.548C170.41 6.74193 171.919 5.23257 171.919 3.37097C171.919 1.50936 170.41 0 168.548 0H40.4515C38.5899 0 37.0806 1.50936 37.0806 3.37097Z"
    />
    <path
      d="M23.5968 168.548H20.2258V158.435C20.2258 156.574 18.7165 155.065 16.8549 155.065C14.9933 155.065 13.4839 156.574 13.4839 158.435V171.919C13.4839 173.781 14.9933 175.29 16.8549 175.29H23.5968C25.4584 175.29 26.9678 173.781 26.9678 171.919C26.9678 170.058 25.4584 168.548 23.5968 168.548Z"
    />
    <path
      d="M43.8224 168.548H37.0804C35.2188 168.548 33.7095 170.058 33.7095 171.919C33.7095 173.781 35.2188 175.29 37.0804 175.29H43.8224C45.684 175.29 47.1933 173.781 47.1933 171.919C47.1933 170.058 45.684 168.548 43.8224 168.548Z"
    />
    <path
      d="M107.198 42.6699C104.089 42.6638 101.41 43.7234 99.4431 45.7419C97.603 47.63 96.5603 50.2455 96.5075 53.1066L96.507 53.1335L96.6848 91.1373C96.6909 94.9283 99.4467 97.6851 103.237 97.6924C105.076 97.696 106.753 97.0329 107.961 95.8251C107.961 95.825 107.961 95.8247 107.961 95.8246C109.184 94.602 109.854 92.9171 109.85 91.0686L109.663 64.7376L105.004 64.8043L105.191 91.1237C105.192 91.7063 105.01 92.2099 104.678 92.542C104.351 92.8688 103.881 93.034 103.28 93.0329C101.536 93.0295 101.345 91.6774 101.344 91.0893L101.167 53.1341C101.243 49.6001 103.591 47.3228 107.156 47.3297C111.385 47.3378 113.283 50.4141 113.288 53.4593L113.834 97.0057C113.837 99.9643 112.784 102.649 110.867 104.567C110.866 104.567 110.866 104.568 110.866 104.568C108.949 106.485 106.322 107.495 103.269 107.489C96.1232 107.476 92.9148 102.275 92.9044 97.1256L93.0248 64.6581L88.3653 64.6744L88.2447 97.1491L88.2447 97.1615C88.2514 101.256 89.6744 105.004 92.2512 107.716C94.999 110.607 98.7942 112.14 103.227 112.149C107.49 112.157 111.369 110.631 114.149 107.851C114.149 107.851 114.15 107.85 114.15 107.849C116.96 105.039 118.503 101.168 118.493 96.9505L117.948 53.404C117.931 47.1952 113.412 42.6818 107.198 42.6699Z"
    />
  </svg>
</template>

<script>
export default {
  name: "Upload",
};
</script>
