<style src="./UserPanel.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="user-panel">
    <UnitLogon />
    <!-- <NoticeBoard /> -->
    <UserProfile />
  </section>
</template>

<script>
// import NoticeBoard from "./notice-board/NoticeBoard";
import UnitLogon from "./unit-logon/UnitLogon";
import UserProfile from "./user-profile/UserProfile";

export default {
  name: "UserPanel",
  components: {
    // NoticeBoard,
    UnitLogon,
    UserProfile,
  },
  data() {
    return {};
  },
};
</script>
