import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query FindHeaderByID($cfi_id: Int) {
    FindHeaderById(cfi_id: $cfi_id) {
      cfi_id
      cfi_cartao_sus
      cfi_cnes
      cfi_codigo_ine
      cfi_id_funcionarios_atencao_basica
      basic_attention_employee {
        employee {
          person {
            pes_nome
          }
          fun_crs_numero_ab
        }
      }
      team {
        area {
          unit_health {
            uns_id
          }
        }
      }
    }
  }
`;

export default async (context, cfi_id) => {
  try {
    const variables = {
      cfi_id,
    };
    const { data } = await GraphQLClient.query({
      query,
      fetchPolicy: "no-cache",
      variables,
    });
    return data.FindHeaderById;
  } catch (err) {
    console.log(err);
    return err.graphQLErrors;
  }
};
