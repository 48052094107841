export default {
  employeeList: [],
  relationship: {
    vin_id: null,
    vin_id_especializacoes: null,
    vin_id_tipos_vinculo: null,
    vin_carga_horaria: null,
    vin_id_ocupacoes_cbo: null,
    vin_admissao: null,
  },
  relationshipSector: {
    vus_id: null,
    vus_id_setores: null,
    vus_carga_horaria_setores: null,
    vus_responsavel: null,
    vus_ativo: null,
    vus_id_vinculos: null,
  },
};
