<style src="./ScheduleList.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="schedule-list">
    <ScheduleFilter />
    <ScheduleListResult :granted="granted" />
  </section>
</template>

<script>
import ScheduleListResult from "./schedule-list-result/ScheduleListResult";
import ScheduleFilter from "./schedule-filter/ScheduleFilter";

export default {
  name: "ScheduleList",
  components: {
    ScheduleListResult,
    ScheduleFilter,
  },
  props: {
    granted: {
      default: true,
    },
  },
  data: () => {
    return {};
  },
  mounted() {
    this.$store.dispatch("SurgeryCenter/Surgery/filter");
  },
};
</script>
