import Store from "@/store";
import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query GetProcedures(
    $searchQuery: String!
    $period: String!
    $type: ProcedureRegisterType
  ) {
    proceduresBilling(
      searchQuery: $searchQuery
      DT_COMPETENCIA: $period
      type: $type
    ) {
      count
      rows {
        NO_PROCEDIMENTO
        CO_PROCEDIMENTO
        CO_REGISTRO
        QT_MAXIMA_EXECUCAO
        TP_SEXO
        VL_IDADE_MINIMA
        VL_IDADE_MAXIMA
        DT_COMPETENCIA
        QT_PONTOS
        VL_SH
        VL_SA
        VL_SP
      }
    }
  }
`;

export default async (variables, findOne = false) => {
  variables.period = Store.getters["Billing/BDSia/GET_PERIOD_DATE"];

  const { data } = await GraphQLClient.query({ query, variables }).catch(
    (err) => {
      console.error(err);
    },
  );
  if (!findOne) {
    return data.proceduresBilling.rows;
  } else {
    return data.proceduresBilling.rows[0];
  }
};
