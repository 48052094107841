<style src="./PersonalData.scss" lang="scss" scoped></style>
<template lang="html">
  <RgForm ref="formBasic" :action-label="buttonLabel.create" @submit="create">
    <section class="personal-data">
      <div class="row">
        <div class="col-1 col-md-2">
          <div
            :class="{
              'rg-person-profile--avatar-enabled': existsPesId !== null,
            }"
            class="rg-person-profile--avatar-container"
          >
            <img :src="avatar" class="avatar" alt="Foto" />

            <div
              v-if="
                $Permissions.global.has('cadastro.pessoa.alterarExcluirFoto')
              "
              class="rg-person-profile--remove-photo"
            >
              <RgRemoveSmall
                v-if="avatar !== '/static/person-image/photo-default.png'"
                class="rg-person-profile--remove-profile-icon"
                @confirm-remove="confirmRemove()"
              />
            </div>

            <div
              v-if="$Permissions.global.has('cadastro.pessoa.incluirFoto')"
              class="rg-person-profile--show-person-photo"
              @click="showPersonPhoto"
            />
          </div>
        </div>

        <div class="col-6 col-md-10">
          <RgInput
            v-model="personForm.pes_nome"
            :rules="{ name: true, required: true }"
            label="Nome"
            placeholder="Ex. Antonio Augusto de Souza"
          />
        </div>
        <div class="col-3 col-md-8">
          <RgInputBirthday
            v-model="personForm.pes_nascimento"
            :rules="{ required: true }"
            label="Nascimento"
            placeholder="dd/mm/aaaa"
          />
        </div>
        <div class="col-2 col-md-4">
          <RgRadioGender
            v-model="personForm.gender.sex_id"
            :rules="{ required: true }"
            :tip="personForm.pes_nome"
            label=""
          />
        </div>
      </div>

      <div class="row">
        <div class="col-6 col-xs-12">
          <RgInput
            v-model="personForm.pes_nome_social"
            label="Nome Social"
            placeholder="Ex. Ana Maria Sterhn"
          />
        </div>
        <div class="col-6 col-xs-12">
          <RgInput
            v-model="personForm.pes_mae"
            :rules="{ name: true, required: true }"
            label="Nome da Mãe"
            placeholder="Ex. Angelina de Souza"
          />
        </div>
      </div>

      <span class="subtitle">Endereço</span>

      <div class="row">
        <div class="col-3 col-md-3 col-xs-12">
          <RgInputCep
            v-model="personForm.address.end_cep"
            :rules="{ required: true, fn: validateCepExists }"
            label="CEP"
            placeholder=""
            @blur="fillAddress"
          />
        </div>
        <div class="col-5 col-md-5 col-xs-12">
          <RgSuggestCity
            v-model="address.cityName"
            :rules="{ required: true }"
            label="Cidade"
            placeholder="Informe o nome da cidade"
            @selected="selectingCity"
          />
        </div>
        <div class="col-4 col-md-4 col-xs-12">
          <RgInput
            v-model="personForm.address.neighborhood.bai_nome"
            :rules="{ required: true }"
            label="Bairro"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-3 col-md-3 col-xs-12">
          <RgSuggestPlace
            v-model="address.publicPlaceType"
            :rules="{ required: true }"
            label="Logradouro"
            placeholder="Informe o tipo do Logradouro"
            @selected="selectingPlace"
          />
        </div>
        <div class="col-7 col-md-7 col-xs-12">
          <RgInput
            v-model="personForm.address.end_logradouro"
            :rules="{ required: true }"
            label="Endereço"
            placeholder="Nome da rua. Ex. Atlântica"
          />
        </div>
        <div class="col-2 col-md-2 col-xs-12">
          <RgInput
            ref="numberField"
            v-model="personForm.address.end_numero"
            :rules="{ required: true, addressNumber: true }"
            label="Nº"
            placeholder="Ex. 1080"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-3 col-md-3 col-xs-12">
          <RgInput
            v-model="personForm.address.end_complemento"
            label="Complemento"
            placeholder="Ex. Fundos"
          />
        </div>
        <div class="col-6 col-md-6 col-xs-12">
          <RgInput
            v-model="personForm.address.subNeighborhood.sub_nome"
            label="Sub-bairro"
          />
        </div>
        <div class="col-3 col-md-3 col-xs-12">
          <RgInput
            v-model="personForm.address.end_referencia"
            label="Referência"
          />
        </div>
      </div>

      <rg-person-photo
        v-if="personPhotoState"
        @uploadPhoto="uploadPhoto"
        @close="closePersonPhoto"
      />
    </section>
  </RgForm>
</template>

<script>
import {
  RgInput,
  RgInputBirthday,
  RgRemoveSmall,
  RgInputCep,
} from "~tokio/primitive";
import { RgForm } from "~tokio/foundation/form";
import RgRadioGender from "$person/common/components/radio/rg-radio-gender/RgRadioGender";
import RgSuggestCity from "$patient/common/components/suggest/rg-suggest-city/RgSuggestCity";
import RgSuggestPlace from "$patient/common/components/suggest/rg-suggest-place/RgSuggestPlace";
import CheckCEP from "$billing/billing/action/CheckCEP";
import { mapGetters } from "vuex";

export default {
  name: "PersonalData",
  components: {
    RgRemoveSmall,
    RgInput,
    RgInputBirthday,
    RgInputCep,
    RgRadioGender,
    RgSuggestCity,
    RgSuggestPlace,
    RgForm,
  },
  data() {
    return {
      avatar: "/static/person-image/photo-default.png",
      showModal: false,
      personModel: null,
      personPhotoState: false,
      buttonLabel: {
        create: "Confirmar",
        update: "Atualizar",
      },
      personForm: {
        pes_nome: "",
        pes_nome_social: "",
        pes_mae: "",
        pes_nascimento: "",
        pes_foto: "",
        pes_id_usuarios: null,
        pes_ativo: null,
        gender: {
          sex_id: 1,
        },
        address: {
          end_cep: "",
          end_logradouro: "",
          end_numero: "",
          end_complemento: "",
          end_referencia: "",
          publicPlaceType: {
            tlg_id: "",
          },
          subNeighborhood: {
            sub_nome: "",
          },
          neighborhood: {
            bai_nome: "",
            city: {
              mun_id: "",
              state: {
                est_id: "",
              },
            },
          },
        },
      },
      address: {
        cityName: "",
        publicPlaceType: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      existsPesId: "Patient/EXISTS_PES_ID",
    }),
    action: function () {
      return this.existsPesId ? "Patient/UPDATE_PERSON" : "Patient/SAVE_PERSON";
    },
    handlePerson: function () {
      return this.personForm.pes_ativo ? "Desativar" : "Ativar";
    },
    modalMessage: function () {
      return `Gostaria de ${this.handlePerson} a pessoa ${this.personForm.pes_nome} ?`;
    },
  },
  mounted() {
    if (this.existsPesId) {
      const pesId = this.$store.getters["Patient/GET_PERSON_ID"];
      const isNewBorn = this.$store.getters["Patient/IS_NEWBORN"] || false;
      this.loadPersonInformation(pesId, isNewBorn);
    }
  },
  methods: {
    selectingCity(pSelection) {
      this.personForm.address.neighborhood.city.mun_id =
        pSelection.source.mun_id;
      this.personForm.address.neighborhood.city.state.est_id =
        pSelection.source.state.est_id;
    },
    selectingPlace(pSelection) {
      this.personForm.address.end_id_tipos_logradouros =
        pSelection.source.tlg_id;
    },
    async create(pError) {
      if (pError) return false;
      this.$store
        .dispatch(this.action, this.personForm)
        .then((pValue) => {
          this.$toaster.success("Cadastro atualizado com sucesso!");
          if (pValue.CreatePerson) {
            const isNewBorn =
              this.$store.getters["Patient/IS_NEWBORN"] || false;
            this.loadPersonInformation(pValue.CreatePerson.pes_id, isNewBorn);
          }
          this.$refs.formBasic.actionCompleted();
        })
        .catch((pErr) => {
          this.$toaster.error(pErr.message, "Não foi possível atualizar");
          this.$refs.formBasic.actionCompleted();
        });
    },
    fillAddress() {
      console.log("entroou");
      const pCep = this.personForm.address.end_cep;
      if (pCep.replace(/[^0-9]/gi, "").length !== 8) {
        return;
      }
      return CheckCEP(pCep)
        .then((pCepData) => {
          if (Object.keys(pCepData).length !== 0) {
            this.personForm.address.end_referencia = "";
            this.personForm.address.end_complemento = "";
            this.personForm.address.end_numero = "";
            this.personForm.address.subNeighborhood.sub_nome = "";
            this.personForm.address.neighborhood.city.mun_id = pCepData.mun_id;
            this.address.cityName = pCepData.mun_nome;
            this.personForm.address.publicPlaceType.tlg_id = pCepData.tlg_id;
            this.address.publicPlaceType = pCepData.tlg_nome;
            this.personForm.address.end_logradouro = pCepData.logradouro;
            this.personForm.address.neighborhood.bai_nome = pCepData.bairro;
            return true;
          } else {
            this.$toaster.error("CEP não encontrado");
            return false;
          }
        })
        .catch((pErr) => {
          return false;
        });
    },
    validateCepExists(pCep, pErrors) {
      if (pCep.replace(/[^0-9]/gi, "").length !== 8) {
        return false;
      }
      return CheckCEP(pCep)
        .then((pCepData) => {
          return true;
        })
        .catch((pError) => {
          pErrors.push(`O Cep: ${pCep} não existe!`);
          return false;
        });
    },
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    togglePersonStatus() {
      const status = !this.personForm.pes_ativo;
      this.$store
        .dispatch("Patient/TOGGLE_PERSON_STATUS_BY_PES_ID", {
          personId: this.pes_id,
          status,
        })
        .then(({ pes_ativo }) => {
          this.personForm.pes_ativo = pes_ativo;
          this.$toaster.success("Status Atualizado.");
        })
        .catch((pErr) => {
          this.$toaster.error(pErr);
        });
      this.closeModal();
    },
    showPersonPhoto() {
      // if (this.pesId !== null) this.personPhotoState = true
    },
    closePersonPhoto() {
      // this.personPhotoState = false
    },
    uploadPhoto(pFile) {
      // this.personModel.uploadPhoto(this.pesId, pFile).then((response) => {
      //   if (response.data.updated.pes_foto) {
      //     this.personForm.pes_foto = response.data.updated.pes_foto
      //   }
      //   this.personPhotoState = false
      // }).catch((perr) => {
      // })
    },
    confirmRemove() {
      // this.personModel.removePhoto(this.pesId)
      //   .then(() => {
      //     this.avatar = '/static/person-image/photo-default.png'
      //   })
    },
    loadPersonInformation(pPersonId, isNewBorn = false) {
      return this.$store
        .dispatch("Patient/GET_PERSON_BY_ID", { pesId: pPersonId })
        .then(() => {
          const basicInfo = this.$store.getters["Patient/GET_BASIC_DATA"];
          this.address.publicPlaceType =
            basicInfo.address.publicPlaceType.tlg_nome;
          this.address.cityName = basicInfo.address.neighborhood.city.mun_nome;
          this.personForm = Object.assign({}, basicInfo);
          this.personForm.pes_nascimento = this.personForm.pes_nascimento
            .split("-")
            .reverse()
            .join("/");
          if (isNewBorn) {
            this.personForm.pes_mae = basicInfo.pes_nome;
            this.personForm.pes_nome = `FILHO DE(A) ${basicInfo.pes_nome}`;
            this.personForm.pes_nascimento = "";
            this.personForm.pes_nome_social = "";
            this.$store.commit("Patient/SET_PERSON_ID", null);
            this.$store.commit("Patient/SET_NEWBORN", false);
          }
          return this.personForm;
        })
        .catch((pErr) => {
          this.$toaster.error("Falha ao Carregar as informações da pessoa");
        });
    },
  },
};
</script>
