import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchDailyEmergencyReport(
    $limit: Int
    $offset: Int
    $initialDate: String
    $finalDate: String
    $toCsv: Boolean
    $toPrint: Boolean
    $toExcel: Boolean
  ) {
    SearchDailyEmergencyReport(
      limit: $limit
      offset: $offset
      initialDate: $initialDate
      finalDate: $finalDate
      toCsv: $toCsv
      toPrint: $toPrint
      toExcel: $toExcel
    ) {
      html
      count
      csv
      excel
      fileName
      rows {
        PACIENTE
        IDADE
        TELEFONES
        ENDERECO
        NOME_DA_MAE
        UNIDADE_DE_SAUDE
        DATA_ATENDIMENTO
        HORA_ATENDIMENTO
        SETOR
        USUARIO
      }
    }
  }
`;

export default async (
  { commit },
  { initialDate, finalDate, toCsv, toPrint, toExcel, limit, offset },
) => {
  try {
    const variables = {
      toCsv,
      toPrint,
      initialDate,
      finalDate,
      toExcel,
      limit,
      offset,
    };
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.SearchDailyEmergencyReport;
  } catch (err) {
    return err.graphQLErrors;
  }
};
