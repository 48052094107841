import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (context, { intIdHistoricoAgendamento }) => {
  return AuthLegacyRequest.post(
    "consultas/controller-marcacao-consulta/carregar-ficha-controle-ambulatorial",
    { intIdHistoricoAgendamento },
  )
    .then(({ data }) => {
      data = HandleResponse(data);
      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
