<style
  src="./ModalHistoricEmployeeRelationship.scss"
  lang="scss"
  scoped
></style>
<template>
  <div v-if="show" class="modal-historic-employee-relationship">
    <RgBaseModal :show="show" class="base-modal-class" @close="close">
      <div slot="header" class="title-modal">
        <h3>Histórico de Vínculo Empregatício</h3>
      </div>
      <div slot="body" class="content-modal">
        <RgListHistoricEmployeeRelationship :historic-list="list" />
      </div>

      <div slot="footer" class="footer-modal">
        <button class="btn btn-default" @click.stop.prevent="close">
          Fechar
        </button>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import RgListHistoricEmployeeRelationship from "./rg-list-historic-employee-relationship/RgListHistoricEmployeeRelationship";
import { RgBaseModal } from "~tokio/primitive/notification";

export default {
  name: "ModalHistoricEmployeeRelationship",
  components: {
    RgListHistoricEmployeeRelationship,
    RgBaseModal,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    relationshipId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      list: [],
    };
  },
  watch: {
    relationshipId() {
      this.fillHistoric();
    },
  },
  mounted() {
    this.fillHistoric();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async fillHistoric() {
      if (this.relationshipId !== 0) {
        this.list = await this.$store.dispatch(
          "Employee/GET_HISTORIC_EMPLOYEE_RELATIONSHIP",
          { employeeRelationshipId: this.relationshipId },
        );
      }
    },
  },
};
</script>
