<template lang="html">
  <svg
    width="48"
    height="58"
    viewBox="0 0 48 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M43.2386 20.7395L46.0084 17.9697C46.4508 17.5273 46.4508 16.81 46.0084 16.3677L42.484 12.8433C42.0415 12.4009 41.3245 12.4009 40.8819 12.8433L38.112 15.6131C35.4598 13.6735 32.3876 12.2759 29.0586 11.5872V8.60938H30.6445C31.2701 8.60938 31.7773 8.10221 31.7773 7.47656V1.13281C31.7773 0.50716 31.2701 0 30.6445 0H17.957C17.3315 0 16.8242 0.50716 16.8242 1.13281V7.47656C16.8242 8.10221 17.3315 8.60938 17.957 8.60938H19.543V11.5873C16.8486 12.1434 14.2662 13.1705 11.9142 14.6365C11.3831 14.9674 11.221 15.6661 11.552 16.1971C11.8829 16.7279 12.5815 16.8902 13.1124 16.5593C16.4632 14.471 20.332 13.3672 24.3008 13.3672C35.9814 13.3672 45.4844 22.8701 45.4844 34.5508C45.4844 46.2314 35.9814 55.7344 24.3008 55.7344C12.6201 55.7344 3.11719 46.2314 3.11719 34.5508C3.11719 30.582 4.221 26.7131 6.30923 23.3627C6.64023 22.8317 6.47802 22.133 5.94707 21.8021C5.41612 21.4712 4.71751 21.6334 4.38662 22.1643C2.07387 25.8747 0.851562 30.1578 0.851562 34.5508C0.851562 47.4807 11.3707 58 24.3008 58C37.2308 58 47.75 47.4807 47.75 34.5508C47.75 29.391 46.0736 24.6162 43.2386 20.7395ZM41.6829 15.2462L43.6054 17.1686L41.8066 18.9674C41.2031 18.2903 40.5613 17.6483 39.8842 17.045L41.6829 15.2462ZM19.0898 2.26562H29.5117V6.34375H19.0898V2.26562ZM21.8086 11.2336V8.60938H26.793V11.2341C25.9738 11.1472 25.1426 11.1016 24.3008 11.1016C23.465 11.1016 22.6333 11.1461 21.8086 11.2336Z"
      fill="white"
    />
    <path
      d="M24.3008 53.1289C34.5448 53.1289 42.8789 44.7948 42.8789 34.5508C42.8789 24.3068 34.5448 15.9727 24.3008 15.9727C14.0568 15.9727 5.72266 24.3068 5.72266 34.5508C5.72266 44.7948 14.0568 53.1289 24.3008 53.1289ZM24.3008 18.2383C33.2955 18.2383 40.6133 25.556 40.6133 34.5508C40.6133 43.5455 33.2955 50.8633 24.3008 50.8633C15.306 50.8633 7.98828 43.5455 7.98828 34.5508C7.98828 25.556 15.306 18.2383 24.3008 18.2383Z"
      fill="white"
    />
    <path
      d="M24.3008 22.7524C24.5987 22.7524 24.891 22.6312 25.1016 22.4205C25.3123 22.2098 25.4336 21.9176 25.4336 21.6196C25.4336 21.3217 25.3124 21.0294 25.1016 20.8187C24.891 20.608 24.5987 20.4868 24.3008 20.4868C24.0029 20.4868 23.7106 20.608 23.4999 20.8187C23.2892 21.0294 23.168 21.3217 23.168 21.6196C23.168 21.9176 23.2891 22.2098 23.4999 22.4205C23.7107 22.6312 24.0029 22.7524 24.3008 22.7524Z"
      fill="white"
    />
    <path
      d="M24.3008 46.3491C24.0029 46.3491 23.7106 46.4703 23.4999 46.681C23.2892 46.8917 23.168 47.184 23.168 47.4819C23.168 47.7799 23.2891 48.0721 23.4999 48.2828C23.7106 48.4935 24.0029 48.6147 24.3008 48.6147C24.5987 48.6147 24.891 48.4935 25.1016 48.2828C25.3123 48.0721 25.4336 47.7799 25.4336 47.4819C25.4336 47.184 25.3124 46.8917 25.1016 46.681C24.891 46.4703 24.5987 46.3491 24.3008 46.3491Z"
      fill="white"
    />
    <path
      d="M37.2314 35.6836C37.5295 35.6836 37.8216 35.5624 38.0323 35.3517C38.243 35.141 38.3643 34.8487 38.3643 34.5508C38.3643 34.2529 38.2432 33.9606 38.0323 33.7499C37.8216 33.5392 37.5295 33.418 37.2314 33.418C36.9335 33.418 36.6412 33.5392 36.4307 33.7499C36.22 33.9606 36.0986 34.2529 36.0986 34.5508C36.0986 34.8487 36.2198 35.141 36.4307 35.3517C36.6412 35.5624 36.9336 35.6836 37.2314 35.6836Z"
      fill="white"
    />
    <path
      d="M10.5688 35.3517C10.7794 35.5624 11.0717 35.6836 11.3696 35.6836C11.6676 35.6836 11.9598 35.5624 12.1705 35.3517C12.3812 35.141 12.5024 34.8487 12.5024 34.5508C12.5024 34.2529 12.3813 33.9606 12.1705 33.7499C11.9598 33.5392 11.6676 33.418 11.3696 33.418C11.0717 33.418 10.7794 33.5392 10.5688 33.7499C10.3581 33.9606 10.2368 34.2529 10.2368 34.5508C10.2369 34.8487 10.3581 35.141 10.5688 35.3517Z"
      fill="white"
    />
    <path
      d="M24.3008 38.1759C26.2996 38.1759 27.9258 36.5497 27.9258 34.5509C27.9258 33.9636 27.7845 33.4092 27.5356 32.918L34.2334 26.2203C34.6758 25.7779 34.6758 25.0606 34.2334 24.6181C33.7909 24.1758 33.0738 24.1758 32.6313 24.6181L25.9335 31.3159C25.4424 31.0671 24.888 30.9259 24.3008 30.9259C22.3019 30.9259 20.6758 32.552 20.6758 34.5509C20.6758 36.5497 22.3019 38.1759 24.3008 38.1759ZM24.3008 33.1915C25.0504 33.1915 25.6602 33.8013 25.6602 34.5509C25.6602 35.3005 25.0504 35.9103 24.3008 35.9103C23.5512 35.9103 22.9414 35.3005 22.9414 34.5509C22.9414 33.8013 23.5512 33.1915 24.3008 33.1915Z"
      fill="white"
    />
    <path
      d="M8.53564 19.9182C8.83357 19.9182 9.12584 19.797 9.33643 19.5863C9.54713 19.3756 9.66846 19.0833 9.66846 18.7854C9.66846 18.4875 9.54725 18.1952 9.33643 17.9845C9.12584 17.7738 8.83357 17.6526 8.53564 17.6526C8.23771 17.6526 7.94545 17.7738 7.73475 17.9845C7.52404 18.1952 7.40283 18.4875 7.40283 18.7854C7.40283 19.0833 7.52393 19.3756 7.73475 19.5863C7.94556 19.797 8.23771 19.9182 8.53564 19.9182Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: "ScaleRegister",
};
</script>
