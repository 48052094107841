<template lang="html">
  <aside class="modal-other-documents">
    <RgBaseModal
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <div class="modal-title">
          <h1 class="title">Outros Documentos</h1>
        </div>
      </div>

      <div ref="body" slot="body" class="body medium-scroll">
        <form class="form" @submit.prevent>
          <RgValidatorForm ref="validator">
            <CnhDocument
              ref="cnh"
              :cnh-data="form.cnh"
              :class="{ disable: cantEditModal }"
              :disabled="cantEditModal"
              class="mg-form"
            />

            <WeddingCertificateDocument
              ref="weddingCertificate"
              :wedding-certificate-data="form.weddingCertificate"
              :class="{ disable: cantEditModal }"
              :disabled="cantEditModal"
              class="mg-form"
            />

            <BirthCertificateDocument
              ref="birthCertificate"
              :birth-certificate-data="form.birthCertificate"
              :class="{ disable: cantEditModal }"
              :disabled="cantEditModal"
              class="mg-form"
            />

            <NewBirthCertificateDocument
              ref="newBirthCertificate"
              :new-birth-certificate-data="form.newBirthCertificate"
              :class="{ disable: cantEditModal }"
              :disabled="cantEditModal"
              class="mg-form"
            />

            <CtpsDocument
              ref="ctps"
              :ctps-data="form.ctps"
              :class="{ disable: cantEditModal }"
              :disabled="cantEditModal"
              class="mg-form"
            />

            <VoterRegistrationDocument
              ref="voterRegistration"
              :voter-title-data="form.voterTitle"
              :class="{ disable: cantEditModal }"
              :disabled="cantEditModal"
              class="mg-form"
            />
          </RgValidatorForm>
        </form>
      </div>

      <div slot="footer" class="footer unselect">
        <RgCleanButton
          v-show="!cantEditModal && !isUpdate"
          small
          class="clean"
          @click="cleanDocuments"
        />

        <RgConfirmButton medium class="save" @click="confirm" />
      </div>
    </RgBaseModal>
  </aside>
</template>
<script>
import moment from "moment";
import { AlertError } from "~tokio/primitive/notification";
import { mapGetters } from "vuex";
import {
  RgBaseModal,
  RgValidatorForm,
  RgConfirmButton,
  RgCleanButton,
} from "~tokio/primitive";

import CnhDocument from "$person/common/components/container/cnh-document/CnhDocument";
import WeddingCertificateDocument from "$person/common/components/container/weeding-certificate-document/WeddingCertificateDocument";
import BirthCertificateDocument from "$person/common/components/container/birth-certificate-document/BirthCertificateDocument";
import NewBirthCertificateDocument from "$person/common/components/container/new-birth-certificarte-document/NewBirthCertificateDocument";
import CtpsDocument from "$person/common/components/container/ctps-document/CtpsDocument";
import VoterRegistrationDocument from "$person/common/components/container/voter-registration-document/VoterRegistrationDocument";

export default {
  name: "ModalOtherDocuments",
  components: {
    RgBaseModal,
    RgValidatorForm,
    CnhDocument,
    WeddingCertificateDocument,
    BirthCertificateDocument,
    NewBirthCertificateDocument,
    CtpsDocument,
    VoterRegistrationDocument,
    RgConfirmButton,
    RgCleanButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    cantEditModal: {
      type: Boolean,
      default: false,
    },
    isUpdate: {
      type: Boolean,
      default: false,
    },
    birthDate: {
      type: Date,
    },
  },
  data() {
    return {
      isValid: false,
      errors: [],
      form: {
        cnh: null,
        newBirthCertificate: null,
        ctps: null,
        voterTitle: null,
        birthCertificate: null,
        weddingCertificate: null,
      },
    };
  },

  computed: {
    ...mapGetters({
      existsPesId: "Person/Patient/EXISTS_PES_ID",
      documentData: "Person/Patient/GET_DOCUMENT",
      isNewBorn: "Person/Patient/IS_NEWBORN",
    }),
  },

  watch: {
    documentData(pValue) {
      const isToLoadData = !this.isNewBorn && pValue;

      if (isToLoadData) {
        this.mountData(pValue.otherDocuments);
      }
    },
  },

  methods: {
    mountDocumentsToValidate() {
      const voterTitle = this.$refs.voterRegistration.getVoterRegistration();
      if (voterTitle.tit_id === 0) {
        delete voterTitle.tit_id;
      }

      const weddingCertificate = this.$refs.weddingCertificate.getWeddingCertificate();
      if (weddingCertificate.ctc_id === 0) {
        delete weddingCertificate.ctc_id;
        delete weddingCertificate.ctc_id_tipos_documento;
      }

      const birthCertificate = this.$refs.birthCertificate.getBirthCertificate();
      if (birthCertificate.ctc_id === 0) {
        delete birthCertificate.ctc_id;
      }
      delete birthCertificate.ctc_id_tipos_documento;

      const ctps = this.$refs.ctps.getCtps();
      if (ctps.ctt_id === 0) {
        delete ctps.ctt_id;
      }

      return [
        { fields: voterTitle, name: "Título de Eleitor" },
        { fields: weddingCertificate, name: "Certidão de Casamento" },
        { fields: birthCertificate, name: "Certidão de Nascimento" },
        { fields: ctps, name: "Carteira de Trabalho" },
      ];
    },

    mountData(pData) {
      if (pData) {
        this.form.cnh = pData.cnh;
        this.form.newBirthCertificate = pData.newBirthCertificate;
        this.form.ctps = pData.ctps;
        this.form.voterTitle = pData.voterTitle;
        this.form.weddingCertificate = pData.weddingCertificate;
        this.form.birthCertificate = pData.birthCertificate;
      }
    },

    getObjectOtherDocuments() {
      const Cnh = this.$refs.cnh.getCnhForm();
      const WeddingCertificate = this.$refs.weddingCertificate.getWeddingCertificate();
      const BirthCertificate = this.$refs.birthCertificate.getBirthCertificate();
      const NewBirthCertificate = this.$refs.newBirthCertificate.getNewBirthCertificate();
      const Ctps = this.$refs.ctps.getCtps();
      const VoterRegistration = this.$refs.voterRegistration.getVoterRegistration();

      delete VoterRegistration.est_id;
      delete VoterRegistration.est_sigla;
      delete VoterRegistration.mun_nome;

      return {
        cnh: Cnh,
        weddingCertificate: WeddingCertificate,
        birthCertificate: BirthCertificate,
        newBirthCertificate: NewBirthCertificate,
        ctps: Ctps,
        voterRegistration: VoterRegistration,
      };
    },

    cleanDocuments() {
      this.$refs.cnh.cleanForm();
      this.$refs.weddingCertificate.cleanForm();
      this.$refs.birthCertificate.cleanForm();
      this.$refs.newBirthCertificate.cleanForm();
      this.$refs.ctps.cleanForm();
      this.$refs.voterRegistration.cleanForm();
    },

    async confirm() {
      try {
        this.isValid = await this.$refs.validator.validate();
        if (!this.isValid) {
          const error = await this.$refs.validator.getError();
          this.errors.push(error);
          return this.$toaster.warning("Confira os documentos");
        }

        this.isValid = await this.validateDates();
        if (!this.isValid) return false;

        this.$toaster.success("Dados confirmados com sucesso!");
        this.close();
      } catch (err) {
        console.log("--->", err);
      }
    },

    async validateDates() {
      let hasDateValid = true;

      const documents = this.mountDocumentsToValidate();
      documents.forEach((document) => {
        const areDateBefore = this.checkIfDatesAreBeforeBirthDate(
          document.fields,
        );

        if (areDateBefore) {
          hasDateValid = false;
          AlertError(
            `Data de Emissão da ${document.name} não pode ser menor que a data de nascimento.`,
          );
        }
      });

      return hasDateValid;
    },

    checkIfDatesAreBeforeBirthDate(pValue) {
      const formatedBirthDate = moment(this.birthDate, "DD/MM/YYYY");
      const values = Object.values(pValue);

      const fields = values.filter(
        (field) =>
          moment(field, "DD/MM/YYYY", true).isValid() &&
          moment(field, "DD/MM/YYYY", true).isBefore(formatedBirthDate),
      );

      const hasOneDateBefore = fields.length > 0;
      return hasOneDateBefore;
    },

    setScrollTopZero() {
      const elementContainer = this.$refs.body;
      elementContainer.scrollTop = 0;
    },

    close() {
      this.$emit("close");
      this.setScrollTopZero();
    },
  },
};
</script>
