<style src="./RgRemove.scss" lang="scss" scoped></style>
<template lang="html">
  <div :class="{ remove__expanded: expandConfirmation }" class="remove">
    <button
      v-show="removeState"
      type="button"
      class="btn remove--trytoremove"
      @click.stop.prevent="tryToRemove()"
    >
      <IconGarbage />
    </button>

    <button
      v-show="!removeState"
      ref="button-confirm"
      type="button"
      class="btn remove--confirm-remove"
      @blur="lostFocus"
      @click.stop.prevent="confirmRemove()"
    >
      <IconGarbage />
      <span>Remover</span>
    </button>
  </div>
</template>

<script>
import { IconGarbage } from "~tokio/primitive/icon/symbols";

export default {
  name: "RgRemove",
  components: {
    IconGarbage,
  },
  props: {},
  data() {
    return {
      removeState: true,
      expandConfirmation: false,
    };
  },
  methods: {
    tryToRemove() {
      this.removeState = false;
      this.expandConfirmation = true;
      this.$nextTick(() => {
        this.$refs["button-confirm"].focus();
      });
    },
    confirmRemove() {
      this.expandConfirmation = false;
      this.removeState = true;
      this.$emit("confirm-remove");
    },
    lostFocus() {
      this.expandConfirmation = false;
      this.removeState = true;
    },
  },
};
</script>
