<style
  src="./RgListHistoricEmployeeRelationship.scss"
  lang="scss"
  scoped
></style>
<template lang="html">
  <div class="rg-historic-list--component">
    <div
      v-if="historicList && historicList.length === 0"
      class="rg-historic-list--no-content"
    >
      <div class="rg-historic-list--empty-icon-container">
        <IconEmpty class="rg-historic-list--empty-icon" />
      </div>
      <span class="rg-historic-list--empty-caption">
        Não foi possível carregar o histórico desse vínculo.
      </span>
    </div>

    <ul v-else class="rg-historic-list--header">
      <li>
        <ul class="rg-historic-list--header-container row">
          <li class="col-2">Código</li>
          <li class="col-3">Ocupação</li>
          <li class="col-2">Data / Hora</li>
          <li class="col-2">Operação</li>
          <li class="col-3">Usuário</li>
        </ul>
      </li>
    </ul>
    <ul class="rg-historic-list--container scrollstyle">
      <li
        v-for="(historicItem, historicItemIdx) in historicList"
        :key="historicItemIdx"
        class="rg-historic-list--container-content"
      >
        <ul class="row">
          <li class="col-2 list--occupation-code">
            {{ historicItem.occupationEsus.ocp_codigo }}
          </li>
          <li class="col-3 list--occupation-name">
            {{ historicItem.occupationEsus.ocp_nome }}
          </li>
          <li class="col-2 list--date-time">
            {{ historicItem.vnl_data | dateBR }}
          </li>
          <li class="col-2 list--operation-name">
            {{ historicItem.logTypes.tlg_nome }}
          </li>
          <li class="col-3 list--user-name">
            {{ historicItem.user.usu_nome }}
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import moment from "moment";
import { IconEmpty } from "~tokio/primitive/icon";

export default {
  name: "RgListHistoricEmployeeRelationship",
  components: {
    IconEmpty,
  },
  filters: {
    dateBR(pValue) {
      return moment(pValue).format("DD/MM/YYYY HH:mm");
    },
  },
  props: {
    historicList: {
      default: () => {
        return [];
      },
    },
  },
};
</script>
