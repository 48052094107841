<template lang="html">
  <svg
    width="18"
    height="14"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.89254 13.7303C6.72072 13.9034 6.48629 14 6.24278 14C5.99927 14 5.76485 13.9034 5.59303 13.7303L0.403889 8.53168C-0.13463 7.99227 -0.13463 7.11758 0.403889 6.57917L1.05365 5.92817C1.59233 5.38875 2.46457 5.38875 3.00309 5.92817L6.24278 9.17342L14.9969 0.404561C15.5356 -0.134854 16.4087 -0.134854 16.9464 0.404561L17.5961 1.05557C18.1346 1.59498 18.1346 2.46951 17.5961 3.00808L6.89254 13.7303Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "MakeCall",
};
</script>
