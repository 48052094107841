import { toast } from "~tokio/primitive/notification";

const SUCCESS_MSG = "Copiado para área de transferência.";
const ERROR_MSG = "Não foi possível copiar para área de transferência.";

/**
 *
 */
export const CopyToClipboard = async (
  pText,
  pSuccessMessage,
  pErrorMessage,
) => {
  try {
    await navigator.clipboard.writeText(pText);
    toast.success(pSuccessMessage || SUCCESS_MSG);
  } catch (pErr) {
    console.log("pError ", pErr);
    toast.error(pErrorMessage || ERROR_MSG);
  }
};
