export default async ({ dispatch, getters }, unitHealthId) => {
  const hospitalizationId = getters.GET_HOSPITALIZATION_ID;
  const hospitalizationBillingAihId =
    getters.GET_HOSPITALIZATION_BILLING_AIH_ID;

  if (!hospitalizationId && !hospitalizationBillingAihId) return;

  // HospitalizationBillingAihInfo
  dispatch("GET_HOSPITALIZATION_AIH_INFO", {
    hospitalizationBillingAihId,
  });

  // HospitalizationBillingPatientInfo
  dispatch("GET_PATIENT_INFO_BY_HOSPITALIZATION_OR_AIH", {
    hospitalizationBillingAihId,
    hospitalizationId,
    unitHealthId,
  });

  // HospitalizationBillingHospitalizationInfo
  dispatch("GET_HOSPITALIZATION_BILLING_INFO_TAB", {
    hospitalizationId,
    hospitalizationBillingAihId,
  });
};
