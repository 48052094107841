<style src="./CalendarTopDate.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="calendar-top-date">
    <div class="date-step">
      <button
        v-shortkey="['ctrl', 'alt', 'd']"
        type="button"
        class="btn-date-step"
        @click="stepPrevious"
        @shortkey="stepPrevious"
      >
        <IconLeftArrow class="icon-arrow" />
      </button>
    </div>

    <div class="date-identify">
      <select v-model="calendar.date" class="selection date">
        <option
          v-for="(selectionDate, idx) in selection.date"
          :key="idx"
          :value="selectionDate"
        >
          {{ selectionDate }}
        </option>
      </select>

      <select v-model="calendar.month" class="selection month">
        <option
          v-for="(selectionMonth, idx) in selection.month"
          :key="idx"
          :value="selectionMonth"
        >
          {{ selectionMonth }}
        </option>
      </select>

      <select v-model="calendar.year" class="selection year">
        <option
          v-for="(selectionYear, idx) in selection.year"
          :key="idx"
          :value="selectionYear"
        >
          {{ selectionYear }}
        </option>
      </select>
    </div>

    <div class="date-step">
      <button
        v-shortkey="['ctrl', 'd']"
        type="button"
        class="btn-date-step"
        @click="stepForward"
        @shortkey="stepForward"
      >
        <IconRightArrow class="icon-arrow" />
      </button>
    </div>
  </section>
</template>

<script>
import { IconLeftArrow, IconRightArrow } from "~tokio/primitive";
const startYear = 2017;

export default {
  name: "CalendarTopDate",
  components: {
    IconLeftArrow,
    IconRightArrow,
  },
  data() {
    return {
      calendar: {
        year: null,
        month: null,
        date: null,
      },
      selection: {
        month: [
          "JANEIRO",
          "FEVEREIRO",
          "MARÇO",
          "ABRIL",
          "MAIO",
          "JUNHO",
          "JULHO",
          "AGOSTO",
          "SETEMBRO",
          "OUTUBRO",
          "NOVEMBRO",
          "DEZEMBRO",
        ],
        years: [],
        date: [],
      },
    };
  },
  watch: {
    calendar: {
      handler(newValue) {
        const cYear = this.calendar.year;
        const cMonth = this.selection.month.indexOf(this.calendar.month) + 1;
        const cDate = this.calendar.date;
        const dateConverted = this.convertToDate(cYear, cMonth, cDate);
        this.fillListDate(dateConverted);
        this.$emit("input", dateConverted);
      },
      deep: true,
    },
  },
  mounted() {
    const today = new Date();
    const calendarDefaultDate = this.$store.getters[
      "SurgeryCenter/Surgery/changeCalendarDate"
    ];
    if (!calendarDefaultDate) {
      this.$emit("input", today);
      this.outputListDate(today);
    }
  },
  methods: {
    outputListDate(pToday) {
      this.fillListYear(pToday);
      this.fillCalendarDate(pToday);
      this.fillListDate(pToday);
    },
    fillListYear(pToday) {
      const actualYear = pToday.getFullYear() + 4;
      const years = [];
      for (
        let yearOfOperation = startYear;
        yearOfOperation <= actualYear;
        yearOfOperation++
      ) {
        years.push(yearOfOperation);
      }
      this.selection.year = years;
    },
    fillListDate(pToday) {
      const actualMonth = pToday.getMonth() + 1;
      const totalDate = new Date(this.calendar.year, actualMonth, 0).getDate();
      const days = [];
      for (let dayOfMonth = 1; dayOfMonth < totalDate + 1; dayOfMonth++) {
        days.push(dayOfMonth);
      }
      this.selection.date = days;
    },
    fillCalendarDate(pDate) {
      const actualYear = pDate.getFullYear();
      if (!this.selection.year.includes(actualYear)) return false;
      const actualMonth = pDate.getMonth() + 1;
      const actualDate = pDate.getDate();
      this.calendar.year = actualYear;
      this.calendar.month = this.selection.month[actualMonth - 1];
      this.calendar.date = actualDate;
    },
    stepForward() {
      this.stepDate("date", "forward");
    },
    stepPrevious() {
      this.stepDate("date", "previous");
    },
    stepDate(pType, pDirection) {
      const directionValue = pDirection === "previous" ? -1 : 1;
      const cYear = this.calendar.year;
      const cMonth = this.selection.month.indexOf(this.calendar.month) + 1;
      const cDate = this.calendar.date;
      const actualDate = this.convertToDate(cYear, cMonth, cDate);
      switch (pType) {
        case "date":
          actualDate.setDate(cDate + directionValue);
          break;

        case "month":
          actualDate.setMonth(cMonth - 1 + directionValue);
          break;

        case "year":
          actualDate.setYear(cYear + directionValue);
          break;

        default:
          break;
      }
      this.fillCalendarDate(new Date(actualDate));
    },
    convertToDate(pYear, pMonth, pDate) {
      return new Date(pYear + "-" + pMonth + "-" + pDate + " 00:00:00");
    },
  },
};
</script>
