import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (
  context,
  {
    intIdSetor,
    intIdOcupacao,
    intIdPessoa,
    intIdUnidadeSaude,
    strDataInicial,
    strDataFinal,
  },
) => {
  return AuthLegacyRequest.post(
    "consultas/controller-agendamento-consulta/buscar-dias-da-semana-por-periodo",
    {
      intIdSetor,
      intIdOcupacao,
      intIdPessoa,
      intIdUnidadeSaude,
      strDataInicial,
      strDataFinal,
    },
  )
    .then(({ data }) => {
      data = HandleResponse(data);
      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
