import { isEmpty } from "lodash";
import { IsCidPattern } from ".";

const GetCidCode = (pValue) => {
  return isEmpty(pValue) || IsCidPattern(pValue) || pValue.indexOf("-") === -1
    ? pValue
    : pValue.split(" - ")[0];
};

export default GetCidCode;
