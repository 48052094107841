<template lang="html">
  <div class="modal-effectiveness-not-done">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <h1 class="title">Atenção</h1>
      </div>

      <div slot="body" class="body">
        <span class="description">
          Uma ou mais consultas não foram alteradas. Veja os detalhes:
        </span>

        <FormBase class="form-base" title="Registros selecionados">
          <table class="table">
            <div class="header">
              <tr class="tr">
                <th>Hora</th>
                <th>Paciente</th>
                <th>Motivo</th>
              </tr>
            </div>

            <div class="body">
              <tr
                v-for="(
                  effectivenessNotDone, index
                ) in mutableEffectivenessNotDone"
                :key="effectivenessNotDone.key"
                class="body-grid"
                @click="selectLine(item, index)"
              >
                <td>{{ effectivenessNotDone.consulta.con_hora }}</td>
                <td :title="effectivenessNotDone.consulta.pes_nome">
                  {{ effectivenessNotDone.consulta.pes_nome }}
                </td>
                <td :title="effectivenessNotDone.motivo">
                  {{ effectivenessNotDone.motivo }}
                </td>
              </tr>
            </div>
          </table>
        </FormBase>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { RgBaseModal } from "~tokio/primitive";
import FormBase from "~tokio/foundation/form-base/FormBase";

export default {
  name: "ModalEffectivenessNotDone",
  components: {
    RgBaseModal,
    FormBase,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    EffectivenessNotDone: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      mutableEffectivenessNotDone: [],
    };
  },

  computed: {
    hasEffectivenessNotDone() {
      return (
        Array.isArray(this.mutableEffectivenessNotDone) &&
        this.mutableEffectivenessNotDone.length > 0
      );
    },
  },

  watch: {
    show(pValue) {
      if (!pValue) {
        this.close();
      }
    },

    EffectivenessNotDone(pValue) {
      this.mutableEffectivenessNotDone = pValue;
    },
  },

  mounted() {
    this.mutableEffectivenessNotDone = this.EffectivenessNotDone;
  },

  methods: {
    close() {
      this.mutableEffectivenessNotDone = [];
      this.$emit("close");
    },
  },
};
</script>
