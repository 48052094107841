<style src="./UserPanel.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="user-panel">
    <NoticeBoard />
    <UserProfile />
  </section>
</template>

<script>
import NoticeBoard from "./notice-board/NoticeBoard";
import UserProfile from "./user-profile/UserProfile";

export default {
  name: "UserPanel",
  components: {
    NoticeBoard,
    UserProfile,
  },
  data() {
    return {};
  },
};
</script>
