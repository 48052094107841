import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

const arrayExceptions = [
  "NaoPossuiPermissaoException",
  "ConsultaEfetivadaException",
];

export default async (context, variables) => {
  return AuthLegacyRequest.post(
    "/consultas/controller-agendamento-consulta/excluir",
    variables,
  )
    .then(({ data }) => {
      const { dados, trace } = data;

      if (arrayExceptions.includes(dados)) {
        throw new Error(trace);
      }

      return true;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
