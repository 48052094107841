import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation($id: Int!) {
    DeleteDisablingEmployeeRelationshipSector(vni_id: $id) {
      vni_id
    }
  }
`;

export default async ({ rootState }, payload) => {
  const variables = {
    id: payload.vni_id,
  };

  try {
    const { data } = await GraphQLClient.mutate({
      mutation,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.DeleteDisablingEmployeeRelationshipSector;
  } catch (err) {
    return Promise.reject(err.graphQLErrors);
  }
};
