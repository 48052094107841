import SEARCH_CUSTOM_PATIENT_TRANSFER from "./SearchCustomPatientTransfer";
import SEARCH_CUSTOM_PATIENT_EXCHANGE from "./SearchCustomPatientExchange";
import PATIENT_TRANSFER from "./PatientTransfer";
import PATIENT_EXCHANGE from "./PatientExchange";
import SAVE_PATIENT_EXCHANGE from "./SavePatientExchange";
import SEND_HOSPITALIZATION_TO_PEP from "./SendHospitalizationToPep";
import CANCEL_SEND_HOSPITALIZATION_TO_PEP from "./CancelSendHospitalizationToPep";
import DELETE_HOSPITALIZATION_EXIT from "./DeleteHospitalizationExit";

export default {
  SEARCH_CUSTOM_PATIENT_TRANSFER,
  SEARCH_CUSTOM_PATIENT_EXCHANGE,
  PATIENT_TRANSFER,
  PATIENT_EXCHANGE,
  SAVE_PATIENT_EXCHANGE,
  SEND_HOSPITALIZATION_TO_PEP,
  CANCEL_SEND_HOSPITALIZATION_TO_PEP,
  DELETE_HOSPITALIZATION_EXIT,
};
