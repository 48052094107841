<template>
  <RgForm
    action-label="Exportar"
    action-label-wait="Exportando"
    action-label-done="Exportado!"
    @submit="exportar"
  >
    <div class="row">
      <RgInputDate
        v-model="form.initialDate"
        label="Data Inicial"
        class="col-2"
      />
      <RgInputDate v-model="form.finalDate" label="Data Final" class="col-2" />
      <RgComboboxQueueType
        v-model="form.queueType"
        :default-value="0"
        default-text="Todos"
        class="col-4"
      />
      <RgComboboxQueueStatus
        v-model="form.queueStatus"
        :default-value="0"
        default-text="Todos"
        class="col-4"
      />
    </div>
  </RgForm>
</template>

<script>
import { RgInputDate } from "~tokio/primitive";
import { RgForm } from "~tokio/foundation/form";
import RgComboboxQueueType from "$queue/common/components/rg-combobox-queue-type/RgComboboxQueueType";
import RgComboboxQueueStatus from "$queue/common/components/rg-combobox-queue-status/RgComboboxQueueStatus";

export default {
  name: "ExportQueueInfoForm",
  components: {
    RgForm,
    RgInputDate,
    RgComboboxQueueType,
    RgComboboxQueueStatus,
  },
  data: () => {
    return {
      form: {
        initialDate: "",
        finalDate: "",
        queueType: 0,
        queueStatus: 0,
      },
    };
  },
  mounted() {
    this.form.finalDate = this.$utils.date.BrazilianDateFormat();
  },
  methods: {
    exportar(pErr, pDone) {
      if (pErr) {
        return pDone();
      }
      this.$store
        .dispatch("Queue/ExportQueueInfo", { data: { ...this.form } })
        .then((result) => {
          if (result.data && result.data.status === false) {
            this.$toaster.error(result.data.msg);
            pDone();
          } else {
            const url = window.URL.createObjectURL(
              new Blob([result.data.file]),
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", result.data.filename);
            document.body.appendChild(link);
            link.click();
            pDone();
          }
        })
        .catch((pErr) => {
          this.$toaster.error("Falha ao Gerar arquivo de Exportação");
          pDone();
        });
    },
  },
};
</script>
