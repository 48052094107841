import Fuse from "fuse.js";
import BuildMenuSearchStructure from "./BuildMenuSearchStructure";

const options = {
  keys: ["tags", "name"],
  threshold: 0.39,
};

let menuMap = false;
let fuse = null;
export default (pSearchText) => {
  if (!menuMap) {
    menuMap = BuildMenuSearchStructure();
    fuse = new Fuse(menuMap, options);
  }

  const searchSplitted = pSearchText.split(" ");

  let keepedMap = [];
  searchSplitted.map((item, idx) => {
    const fusedSearch = fuse.search(item);
    if (item.length === 0) return keepedMap;
    if (idx === 0) {
      keepedMap = fusedSearch;
    } else {
      const keepedMapReduced = keepedMap.filter((item) => {
        for (const innerResult in fusedSearch) {
          if (item.id === fusedSearch[innerResult].id) return true;
        }
        return false;
      });
      keepedMap = keepedMapReduced;
    }
  });
  return keepedMap;
};
