<style src="./SurgeryInfo.scss" lang="scss" scoped></style>
<template lang="html">
  <PrintFrameInfo label="Informações da Cirurgia">
    <div class="row">
      <pre />
      <div class="col-6 frame-fieldset">
        <span class="fieldset-label">Clínica Solicitante</span>
        <span class="fieldset-value">{{ surgeryData.inc_nome }}</span>
      </div>

      <div class="col-6 frame-fieldset">
        <span class="fieldset-label">Médico Solicitante</span>
        <span class="fieldset-value">{{ surgeryData.fun_nome }}</span>
      </div>
    </div>

    <div class="row">
      <div class="col-6 frame-fieldset">
        <span class="fieldset-label">Centro Cirurgico</span>
        <span class="fieldset-value">{{ surgeryData.set_nome }}</span>
      </div>

      <div class="col-6 frame-fieldset">
        <span class="fieldset-label">Sala</span>
        <span class="fieldset-value">{{ surgeryData.lca_nome }}</span>
      </div>
    </div>

    <div class="row">
      <div class="col-6 frame-fieldset">
        <span class="fieldset-label">Tipo de Anestesia</span>
        <span class="fieldset-value">{{ surgeryData.cta_nome }}</span>
      </div>

      <div class="col-6 frame-fieldset">
        <span class="fieldset-label">Raio-X</span>
        <span class="fieldset-value">{{ surgeryData.rax_nome }}</span>
      </div>
    </div>

    <div class="row">
      <div class="col-6 frame-fieldset">
        <span class="fieldset-label">CTI</span>
        <span class="fieldset-value">{{
          surgeryData.cci_cti ? "SIM" : "NÃO"
        }}</span>
      </div>

      <div class="col-6 frame-fieldset">
        <span class="fieldset-label">Tipo de Cirurgia</span>
        <span class="fieldset-value">{{ surgeryData.tcr_nome }}</span>
      </div>
    </div>

    <div class="row">
      <div class="col-6 frame-fieldset">
        <span class="fieldset-label">Reserva de Sangue</span>
        <span class="fieldset-value">{{
          surgeryData.cci_reserva_sangue ? "SIM" : "NÃO"
        }}</span>
      </div>

      <div class="col-6 frame-fieldset">
        <span class="fieldset-label">Tipo de Procedimento</span>
        <span class="fieldset-value">{{ surgeryData.tpp_nome }}</span>
      </div>
    </div>
  </PrintFrameInfo>
</template>

<script>
import PrintFrameInfo from "../print-frame-info/PrintFrameInfo";
export default {
  name: "SurgeryInfo",
  components: {
    PrintFrameInfo,
  },
  props: {
    surgeryData: {
      default() {},
    },
  },
};
</script>
