import BaseModel from "~common/model/BaseModel";

class SurgeryMaterialModel extends BaseModel {
  defineEndPointURI() {
    return "surgery-center/surgery-material-type";
  }

  getMaterialsById(pSurgeryId) {
    return new Promise((resolve, reject) => {
      this._request
        .get("/surgery-center/surgery-material", {
          cmc_id_controles_cirurgicos: pSurgeryId,
          // set_id: pSectorId
        })
        .then((pData) => {
          resolve(pData.data || null);
        });
    });
  }
}
export default new SurgeryMaterialModel();
