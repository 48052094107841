<template>
  <section class="quick-patient-details">
    <RgValidatorForm ref="validatorForm" class="content">
      <!-- <FloatButtonAnchorPerson v-show="showAnvilButton" /> -->
      <PersonPersonalData
        id="personal-data"
        ref="personal"
        class="mg-form"
        @loadFromCadsus="loadFromCadsus"
        @birthDateChanged="birthDate = $event"
      />

      <PersonAddress id="address-data" ref="address" class="mg-form" />

      <PersonDocument
        id="document-data"
        ref="document"
        class="mg-form"
        :is-update="this.existsPesId"
        :birthDate="birthDate"
      />

      <PersonContacts
        id="contacts-data"
        ref="contacts"
        class="mg-form"
        :requiredOne="requiredOnePhone"
      />

      <PatientForm
        v-if="!hasLinkNewborn"
        id="patient-data"
        ref="patient"
        class="mg-form"
      />
    </RgValidatorForm>
  </section>
</template>

<script>
import { DataDBFormatter } from "$person/helper/PersonDataFormatter";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";

import { RgValidatorForm } from "~tokio/primitive";

import {
  PersonPersonalData,
  PersonAddress,
  PersonDocument,
  PersonContacts,
  // FloatButtonAnchorPerson,
} from "$person/common/components/";

import { PatientForm } from "$person/patient/component/";
import { toast, AlertError } from "~tokio/primitive/notification";

import { mapGetters } from "vuex";

export default {
  name: "QuickPatientDetails",
  components: {
    RgValidatorForm,
    PersonPersonalData,
    PersonDocument,
    PersonAddress,
    PersonContacts,
    PatientForm,
    // FloatButtonAnchorPerson,
  },
  props: {
    hasLinkNewborn: {
      type: Boolean,
      default: false,
    },
    showAnvilButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalConfirmFieldClearing: false,
      birthDate: null,
    };
  },
  computed: {
    ...mapGetters({
      existsPesId: "Person/Patient/EXISTS_PES_ID",
      isNewBorn: "Person/Patient/IS_NEWBORN",
      attachments: "Person/Patient/GET_ATTACHMENTS",
    }),
    requiredOnePhone() {
      const validatePersonPhone = this.$store.getters[
        "Login/GET_PHONE_REQUIRED"
      ];

      return validatePersonPhone || this.fromBioslab;
    },

    fromBioslab() {
      const routeExist = ValidateIfRouteExistInBreadscrumb(
        "/exam/laboratory/details",
        this.$route.meta.breadcrumb,
      );

      return routeExist && routeExist instanceof Object;
    },
  },
  created() {
    this.FORM_ERROR = 1;
    this.DOCUMENT_ERROR = 2;
    this.TELEPHONE_ERROR = 3;
  },
  beforeDestroy() {
    this.destroyPersonInfo();
  },

  async mounted() {
    const pData = this.$route.query;
    if (pData && pData.newPatient) {
      this.destroyPersonInfo();
    }
    await this.setPatientInfo();
  },

  methods: {
    goBack() {
      const routerValid = ValidateIfRouteExistInBreadscrumb(
        "/person/patient/details",
        this.$route.meta.breadcrumb,
      );

      const isHospitalizationSearchBed =
        this.$route.name ===
          "hospitalization.bed.search.hospitalization.new-patient" ||
        this.$route.name ===
          "hospitalization.bed.search.hospitalization.edit-patient";

      if (isHospitalizationSearchBed) {
        this.$router.push({
          name: "hospitalization.bed.search.new-hospitalization",
          params: {
            form: this.$route.params.form,
            fromMapBed: true,
            operation: this.$route.params.operation,
          },
        });
      } else {
        routerValid
          ? this.$router.push("/person/patient/search")
          : this.$router.go(-1);
      }
    },

    loadFromCadsus(pData) {
      this.$refs.address.loadFromCadsus(pData);
      this.$refs.document.loadFromCadsus(pData);
      this.$refs.contacts.loadFromCadsus(pData);
    },

    async setPatientInfo() {
      try {
        if (this.existsPesId) {
          this.$loader.start();
          const pPesId = this.$store.getters["Person/Patient/GET_PERSON_ID"];
          await this.$store.dispatch("Person/Patient/GET_PATIENT_BY_ID", {
            pesId: pPesId,
          });
          this.$loader.finish();
        }
      } catch (error) {
        this.$loader.finish();
        console.log(error);
        this.$toaster.error("Erro ao carregar os dados do paciente.");
      }
    },

    async savePatient() {
      try {
        this.$loader.start("Salvando dados");
        const isValid = await this.$refs.validatorForm.validate();
        const validateDocuments = await this.$refs.document.validateAllDocuments();
        const validateTelefone = await this.$refs.contacts.validateRequiredPhone();

        if (!isValid) {
          throw new Error(this.FORM_ERROR);
        }

        if (!validateDocuments) {
          throw new Error(this.DOCUMENT_ERROR);
        }

        if (!validateTelefone) {
          throw new Error(this.TELEPHONE_ERROR);
        }

        const person = this.$refs.personal.getPersonalForm();
        const address = this.$refs.address.getAddressForm();
        const contacts = this.$refs.contacts.getContactForm();
        const documents = await this.$refs.document.getDocumentForm();
        const appNotification = this.$refs.document.getNotification();
        const attachments = this.$refs.document.getNewAttachments();
        const patient = this.$refs.patient.getPatientForm();

        const personData = {
          person: person.personForm,
          foreign: person.personForeignForm,
          address: address,
          attachments: attachments,
          documents: documents,
          contact: contacts,
          appNotification: appNotification,
          patient: patient,
        };

        const variables = DataDBFormatter(personData);

        const isCreate = !this.existsPesId || this.isNewBorn;
        var idPerson = 0;

        if (isCreate) {
          const personCreated = await this.$store.dispatch(
            "Person/Patient/CREATE_PATIENT",
            { ...variables },
          );

          idPerson = personCreated.rows.pes_id;

          this.toasterManager(personCreated);
        } else {
          variables.personId = person.personForm.pes_id;
          idPerson = variables.personId;

          const personUpdated = await this.$store.dispatch(
            "Person/Patient/UPDATE_PATIENT",
            { ...variables },
          );

          this.toasterManager(personUpdated);
        }

        // Salvando na store apenas para rotas que não vieram diretamento do menu > pacientes
        if (idPerson && this.$route.meta.breadcrumb[0].label !== "Pacientes") {
          this.$store.commit(
            "Person/Patient/SET_LAST_PERSON_ID_SAVE",
            idPerson,
          );
        }
      } catch (error) {
        this.$emit("fail");

        if (Number(error.message) === this.FORM_ERROR) {
          AlertError("Verifique os campos");
        } else if (Number(error.message) === this.DOCUMENT_ERROR) {
          AlertError("Verifique os outros documentos");
        } else if (Number(error.message) === this.TELEPHONE_ERROR) {
          AlertError("Verifique os telefones");
        } else if (this.existsPesId) {
          toast.error(error, "Erro ao atualizar: ");
        } else {
          toast.error(error);
        }
      } finally {
        this.$loader.finish();
      }
    },

    destroyPersonInfo() {
      this.$store.commit("Person/Patient/DESTROY_PERSON_INFO");
    },

    toasterManager(pData) {
      if (pData.success) {
        toast.success(`${pData.message}`);
        // ir para busca
      } else {
        toast.warning(`${pData.message}`);
      }

      this.goBack();
    },

    clean() {
      this.$refs.personal.cleanForm();
      this.$refs.address.cleanForm();
      this.$refs.contacts.cleanForm();
      this.$refs.document.cleanForm();
      this.$refs.document.cleanNotification();
      this.$refs.patient.cleanForm();
      this.$store.commit("Person/Patient/DESTROY_PERSON_INFO");
    },
  },
};
</script>
