<style src="./RgComboboxSectorMappedPep.scss" lang="scss"></style>
<script>
import { RgRemoteComboboxMixin } from "~tokio/primitive";
import ListSectorsMappedPep from "./action/ListSectorsMappedPep";

export default {
  name: "RgComboboxSectorMappedPep",
  mixins: [RgRemoteComboboxMixin],
  props: {
    label: {
      type: String,
      default: "Setor",
    },
    unitHealthId: {
      default: null,
    },
    placeId: {
      type: Number,
      default: null,
    },
  },
  watch: {
    unitHealthId: function () {
      this.getDataFromRemote();
    },
  },
  methods: {
    doSearch() {
      if (this.unitHealthId === null) return Promise.resolve([]);

      return ListSectorsMappedPep({
        unitHealthId: this.unitHealthId,
        placeId: this.placeId,
      });
    },
    formatItem(item) {
      return {
        value: item.set_id,
        text: `${item.set_nome}`,
      };
    },
  },
};
</script>
