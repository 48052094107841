<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import Search from "./action";
export default {
  name: "RgComboboxSectorPanel",
  mixins: [RgComboboxMixin],
  props: {
    unsId: {
      type: Number,
      required: true,
    },
  },
  watch: {
    sectorId() {
      this.fillData();
    },
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];

      this.disableLoading = true;
      const data = await Search({
        uns_id: this.unsId,
      });

      data.forEach((item) => {
        this.mData.push({ value: item.data, text: item.label });
      });
      this.disableLoading = false;
    },
  },
};
</script>
