import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation CreateEmployeeRelationshipSector(
    $employeeRelationshipSector: EmployeeRelationshipSectorInputType
  ) {
    CreateEmployeeRelationshipSector(
      employeeRelationshipSector: $employeeRelationshipSector
    ) {
      vus_id
    }
  }
`;

export default async ({ rootState }, variables) => {
  try {
    const { data } = await GraphQLClient.mutate({
      mutation,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.CreateEmployeeRelationshipSector;
  } catch (err) {
    return Promise.reject(err.graphQLErrors);
  }
};
