<template lang="html">
  <aside class="modal-search-cad-sus">
    <RgBaseModal
      ref="modalSearchCadSus"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <RgValidatorForm ref="validator">
        <div class="container">
          <div class="filter">
            <div slot="header" class="header">
              <span class="title">Filtros</span>
            </div>

            <div slot="body" class="body">
              <RgInput
                ref="name"
                v-model="form.pes_nome"
                :disabled="nameValidation"
                :class="{ disable: nameValidation }"
                class="data"
                placeholder="Informe o nome"
                label="Nome"
              />

              <RgInputDate
                ref="dateOfBirth"
                v-model="form.pes_nascimento"
                :disabled="dateOfBirthValidation"
                :class="{ disable: dateOfBirthValidation }"
                :rules="{ required: hasName }"
                class="data"
                placeholder="dd/mm/aaaa"
                label="Data de Nascimento"
              />

              <RgInput
                ref="mothersName"
                v-model="form.pes_mae"
                :disabled="mothersNameValidation"
                :class="{ disable: mothersNameValidation }"
                class="data"
                placeholder="Informe o nome completo da mãe"
                label="Nome da Mãe"
              />

              <RgInputCns
                ref="cns"
                v-model="form.crs_numero"
                class="cns"
                :disabled="cnsValidation"
                :class="{ disable: cnsValidation }"
              />

              <RgInputCpf
                ref="cpf"
                v-model="form.cpf_numero"
                class="cpf"
                :disabled="cpfValidation"
                :class="{ disable: cpfValidation }"
                @blur="focusName"
              />
            </div>

            <div slot="footer" class="footer">
              <div class="action">
                <RgCleanButton
                  small
                  class="clean"
                  data-id="limpar-filtro"
                  title="Limpar"
                  @click="cleanForm"
                />

                <RgSearchButton
                  ref="btnSubmit"
                  v-shortkey="['enter']"
                  type="button"
                  data-id="buscar"
                  class="search"
                  title="Buscar"
                  :class="{
                    'disable unselect': noInputValues,
                  }"
                  :disabled="noInputValues"
                  @shortkey.native="searchCadsus"
                  @submit="searchCadsus"
                />
              </div>
            </div>
          </div>

          <div class="result">
            <div slot="header" class="header">
              <span class="title">Busca de Paciente CADSUS</span>
            </div>

            <div slot="body" class="body">
              <div v-show="!mutableHistoryList" class="no-result">
                <IconEmpty class="empty-icon" />
                <span class="info"
                  >Não foram encontrados resultados para essa busca.</span
                >
              </div>

              <RgList
                v-show="mutableHistoryList"
                ref="rgList"
                v-model="mutableHistoryList"
                :search-function="searchFunction"
                :build-filter="generateFilter"
                :backend-legacy="true"
                :show-total="true"
                :register-per-page="10"
                :max-register="10"
              >
                <div class="modal-search-cad-sus-result">
                  <div
                    v-for="(item, index) in mutableHistoryList"
                    :key="index"
                    class="card"
                    @click="getCadSusDetail(item, index)"
                  >
                    <RgConfirmButton small @click="selectPerson(item)" />

                    <div class="data name">
                      Nome:
                      <strong>
                        {{
                          item && item.NomeCompleto
                            ? item.NomeCompleto.Nome
                            : ""
                        }}
                      </strong>
                    </div>

                    <div class="data mother">
                      Nome da Mãe:
                      <strong>
                        {{ item && item.Mae ? item.Mae.Nome : "" }}
                      </strong>
                    </div>

                    <div class="data birthDate">
                      Data de Nascimento:
                      <strong>
                        {{ item ? item.dataNascimento : "" }}
                      </strong>
                    </div>

                    <div class="data cns">
                      CNS:
                      <strong>
                        {{ getCNS(item) }}
                      </strong>
                    </div>
                  </div>
                </div>
              </RgList>
            </div>
          </div>
        </div>
      </RgValidatorForm>
      <!-- END Results -->

      <!-- </rg-base-modal> -->
    </RgBaseModal>
  </aside>
</template>

<script>
import { RgList } from "~tokio/foundation";
import {
  RgBaseModal,
  RgInput,
  RgInputDate,
  RgSearchButton,
  RgCleanButton,
  RgConfirmButton,
  RgValidatorForm,
} from "~tokio/primitive";
import RgInputCns from "$person/common/components/input/rg-input-cns/RgInputCns";
import RgInputCpf from "$person/common/components/input/rg-input-cpf/RgInputCpf";
import { IconEmpty } from "~tokio/primitive/icon/symbols";

export default {
  name: "ModalSearchCadSus",
  components: {
    IconEmpty,
    RgBaseModal,
    RgList,
    RgInput,
    RgInputDate,
    RgInputCns,
    RgInputCpf,
    RgSearchButton,
    RgCleanButton,
    RgConfirmButton,
    RgValidatorForm,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mutableHistoryList: null,
      activeRow: false,
      form: {
        pes_nome: "",
        pes_nascimento: "",
        pes_mae: "",
        cpf_numero: "",
        crs_numero: "",
      },
      required: false,
    };
  },
  computed: {
    hasName() {
      return !!this.form.pes_nome;
    },

    hasBirthOfDate() {
      return !!this.form.pes_nascimento;
    },

    hasMothersName() {
      return !!this.form.pes_mae;
    },

    hasCns() {
      return !!this.form.crs_numero;
    },

    hasCpf() {
      return !!this.form.cpf_numero;
    },

    nameValidation() {
      return this.hasCns || this.hasCpf || this.hasMothersName;
    },

    dateOfBirthValidation() {
      return !this.hasName;
    },

    mothersNameValidation() {
      return this.hasName || this.hasBirthOfDate || this.hasCns || this.hasCpf;
    },

    cnsValidation() {
      return this.disableDocuments || this.hasCpf;
    },

    cpfValidation() {
      return this.disableDocuments || this.hasCns;
    },

    disableDocuments() {
      return (
        (this.form.pes_nome || this.form.pes_nascimento || this.form.pes_mae)
          .length > 0
      );
    },

    noInputValues() {
      if (!this.hasCns && !this.hasCpf) {
        return !this.hasName && !this.hasBirthOfDate && !this.hasMothersName;
      } else {
        return false;
      }
    },
  },
  watch: {
    "form.pes_nome"(pValue) {
      if (!pValue) {
        this.form.pes_nascimento = "";
        this.cleanValidate();
      }
    },
  },
  mounted() {
    this.focusName();
  },
  beforeDestroy() {
    this.cleanForm();
  },
  methods: {
    focusName() {
      if (this.$refs.name) {
        this.$refs.name.setFocus();
      }
    },
    async searchFunction(pData) {
      try {
        if (!(await this.isFormValid())) {
          this.$toaster.warning("Verifique os campos");
          return false;
        }
        this.$loader.start();
        delete pData.offset;

        const rows = await this.$store.dispatch("Person/SEARCH_CADSUS", pData);

        if (rows && rows.length > 0) {
          const patientList = {
            rows: rows,
          };
          return patientList;
        }
        return null;
      } catch (pErr) {
        this.mutableHistoryList = null;
        this.$toaster.warning(
          "Não foi possível realizar a busca com os dados informados ou nenhum resultado foi encontrado.",
        );
      } finally {
        this.$loader.finish();
      }
    },
    generateFilter() {
      const cpf = this.form.cpf_numero
        ? this.form.cpf_numero.replace(/[^\d]+/g, "")
        : "";
      const crs = this.form.crs_numero
        ? this.form.crs_numero.replace(/[^\d]+/g, "")
        : "";

      const tipoPesquisa = this.form.pes_mae ? "IDENTICA" : "APROXIMADA";

      const arrfiltros = {
        cpf_numero: cpf,
        crs_numero: crs,
        nome: this.form.pes_nome ? this.form.pes_nome : "",
        nomeMae: this.form.pes_mae ? this.form.pes_mae : "",
        data_nascimento: this.form.pes_nascimento
          ? this.form.pes_nascimento
          : "",
        tipoPesquisa: tipoPesquisa,
      };

      return arrfiltros;
    },

    searchCadsus() {
      try {
        this.$refs.btnSubmit.actionSubmit();
        this.$refs.rgList.submitForm(true);
        this.$refs.btnSubmit.actionDone();
      } catch (pErr) {
        if (this.$refs.btnSubmit) {
          this.$refs.btnSubmit.fail();
        }
      }
    },

    isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    getCadSusDetail(pItem, pIndex) {
      this.activeRow = pIndex;
    },

    getCNS(pData) {
      if (!pData) return;
      if (pData.CNS) {
        return pData.CNS.numeroCNS;
      }
      if (pData.Cartoes && pData.Cartoes.CNS) {
        if (!Array.isArray(pData.Cartoes.CNS)) {
          return pData.Cartoes.CNS.numeroCNS;
        } else {
          for (const cns of pData.Cartoes.CNS) {
            if (this.form.crs_numero === cns.numeroCNS) {
              return cns.numeroCNS;
            }
          }
        }
      }
    },

    async selectPerson(item) {
      if (this.hasCns) {
        console.log("cadsus: ", item);
        this.$emit("close", item);
      } else {
        const arrfiltros = {
          cpf_numero: "",
          crs_numero: item.CNS.numeroCNS,
          nome: "",
          nomeMae: "",
          data_nascimento: "",
          tipoPesquisa: "APROXIMADA",
        };
        const result = await this.searchFunction(arrfiltros);
        console.log("cadsus: ", result.rows[0]);
        this.$emit("close", result.rows[0]);
      }
      this.cleanForm();
    },

    close() {
      this.$refs.rgList.resetLimitOffset();
      this.$emit("close");
    },

    cleanForm() {
      this.form = {
        pes_nome: "",
        pes_nascimento: "",
        pes_mae: "",
        cpf_numero: "",
        crs_numero: "",
      };
      this.activeRow = null;
      this.mutableHistoryList = null;
      this.$refs.rgList.resetLimitOffset();
    },

    cleanValidate() {
      this.$refs.dateOfBirth.cleanValidate();
    },
  },
};
</script>
