<style
  src="./HospitalizationBillingProcedureInfo.scss"
  lang="scss"
  scoped
></style>
<template lang="html">
  <section class="hospitalization-billing-procedure-info">
    <RgConfirmModal
      :show="confirmDelete"
      :message="deleteModalMsg"
      style="background-color: red"
      title="Confirmação"
      @close="handleRemoveLine"
      @confirmed="removeLine"
      @denied="handleRemoveLine"
    />
    <form class="form">
      <RgValidatorForm ref="validator">
        <div class="row">
          <RgInputMasked
            v-model="procedureForm.iap_competencia"
            :rules="{ fn: validateCompetence }"
            class="col-2"
            label="CMPT:"
            mask="##/####"
            title="Competência"
          />
          <RgTypeaheadProcedure
            v-model="procedureForm.iap_codigo_procedimentos"
            :disabled="editMode"
            :rules="{ required: true }"
            :class="{ disabled: editMode, 'col-3': 'col-3' }"
            label="Procedimento:"
            placeholder="Digite o procedimento"
            @valueChange="validateProcedure"
          />
          <RgInputMasked
            v-model="procedureForm.iap_quantidade"
            :rules="{ fn: validateDays }"
            class="col-2"
            label="Quant./Dias:"
            mask="###"
          />
          <RgComboboxService
            v-model="procedureForm.iap_codigo_servico"
            :disabled="!hasServiceAndClassification"
            :procedure="procedureForm.iap_codigo_procedimentos"
            :class="{
              disabled: !hasServiceAndClassification,
              'col-2': 'col-2',
            }"
            label="Serviço:"
            placeholder="Código do serviço"
          />

          <RgComboboxClassification
            v-model="procedureForm.iap_codigo_classificacao"
            :disabled="!hasServiceAndClassification"
            :procedure="procedureForm.iap_codigo_procedimentos"
            :service="procedureForm.iap_codigo_servico"
            :class="{
              disabled: !hasServiceAndClassification,
              'col-2': 'col-2',
            }"
            label="Classificação:"
            placeholder="Código da classificação"
          />
        </div>
        <RgTitle class="subtitles" title="Executante" />
        <div class="row">
          <RgSuggestEmployee
            v-model="procedureForm.iap_nome_executante"
            :rules="{ required: requiredCPF }"
            :disabled="hasAdditionalData || !requiredCPF"
            :caption-raw="professionalCaptionRaw"
            :class="{
              disabled: hasAdditionalData || !requiredCPF,
              'col-4': 'col-4',
            }"
            placeholder="Nº CNS - Nome do Profissional"
            label="Profissional"
            @selected="professionalSelected"
          />
          <RgTypeaheadCbo
            v-model="procedureForm.iap_cbo"
            :rules="{ fn: validateCbo }"
            :disabled="hasAdditionalData || !requiredCBO"
            :class="{
              disabled: hasAdditionalData || !requiredCBO,
              'col-3': 'col-3',
            }"
            label="CBO:"
            placeholder="Código do CBO"
          />
          <RgInputMasked
            v-model="procedureForm.iap_cnes"
            :disabled="hasAdditionalData"
            :rules="{ required: requiredCNES }"
            :class="{ disabled: hasAdditionalData, 'col-3': 'col-3' }"
            label="CNES:"
            placeholder=""
            mask="#######"
            title="Cadastro Nacional de Estabelecimentos de Saúde"
          />
          <RgInputCnpj
            v-model="procedureForm.iap_cnpj"
            :disabled="hasAdditionalData"
            :class="{ disabled: hasAdditionalData, 'col-2': 'col-2' }"
            label="CNPJ:"
          />
        </div>
        <RgTitle
          class="subtitles"
          title="Apurar valor para"
          text-align="center"
        />
        <div class="container">
          <div class="row">
            <RgInputMasked
              v-model="procedureForm.iap_numero_documento"
              :rules="{ required: !requiredExec, fn: validateDocumentNumber }"
              :disabled="requiredExec"
              class="col-6"
              label="Número do Documento:"
              placeholder="Informe o número do documento"
              mask="###############"
            />
            <RgComboboxAihDocumentType
              v-model="procedureForm.iap_id_internacoes_documentos_aih"
              :rules="{ required: !requiredExec }"
              :disabled="requiredExec"
              class="col-6"
              label="Tipo de Documento:"
              @input="documentSelected"
            />
            <button
              type="button"
              class="alignB btn-small btn btn-danger align-3-btn col-4"
              @click="clearList"
            >
              <span v-if="editMode">Cancelar Edição</span>
              <span v-else><Garbage class="svg" />Limpar</span>
            </button>
            <button
              :disabled="adding || !canSave"
              type="button"
              class="alignB btn-small btn btn-primary align-3-btn col-4"
              @click="add"
            >
              <span v-if="editMode">Alterar</span>
              <span v-else>Adicionar Linha</span>
            </button>
            <button
              v-if="!editMode"
              :disabled="!canSave || !hasDataToSave"
              type="button"
              class="alignB btn-small btn btn-success item align-3-btn col-4"
              @click="save"
            >
              Salvar
            </button>
          </div>
        </div>

        <ModalHospitalizationBillingChildbirth
          :show="modals.childbirthModal"
          :procedure="procedureForm"
          :required-documents="requiredDocuments"
          @onFinish="addFromModal"
          @close="closeModals"
        />

        <ModalHospitalizationBillingTincture
          :show="modals.tinctureModal"
          :procedure="procedureForm"
          :required-documents="requiredDocuments"
          @onFinish="addFromModal"
          @close="closeModals"
        />

        <ModalHospitalizationBillingCivil
          :show="modals.civilModal"
          :procedure="procedureForm"
          :required-documents="requiredDocuments"
          :births="totalOfBirths"
          @onFinish="addFromModal"
          @close="closeModals"
        />

        <ModalHospitalizationBillingVasectomy
          :show="modals.vasectomyModal"
          :procedure="procedureForm"
          :required-documents="requiredDocuments"
          @onFinish="addFromModal"
          @close="closeModals"
        />

        <ModalHospitalizationBillingNeonatal
          :show="modals.neonatalModal"
          :procedure="procedureForm"
          :required-documents="requiredDocuments"
          @onFinish="addFromModal"
          @close="closeModals"
        />

        <ModalHospitalizationBillingMaterial
          :show="modals.materialModal"
          :procedure="procedureForm"
          :required-documents="requiredDocuments"
          @onFinish="addFromModal"
          @close="closeModals"
        />

        <ModalHospitalizationBillingWorkAccident
          :show="modals.workAccidentModal"
          :procedure="procedureForm"
          :required-documents="requiredDocuments"
          @onFinish="addFromModal"
          @close="closeModals"
        />

        <ModalHospitalizationBillingTeam
          :show="modals.teamModal"
          :procedure="procedureForm"
          :required-documents="requiredDocuments"
          @onFinish="addFromModal"
          @close="closeModals"
        />

        <ModalHospBillChildbirthTincture
          :show="modals.childbirthTinctureModal"
          :procedure="procedureForm"
          :required-documents="requiredDocuments"
          @onFinish="addFromModal"
          @close="closeModals"
        />

        <div class="row">
          <RgTitle
            class="subtitles"
            title="Lista de Procedimentos:"
            text-align="center"
          />
          <table>
            <tr>
              <th>Linha:</th>
              <th>Procedimento:</th>
              <th title="Quantidade">Qtd:</th>
              <th>Documento:</th>
              <th title="Classificação Brasileira de Ocupações">CBO:</th>
              <th title="Cadastro Nacional de Estabelecimentos de Saúde">
                CNES:
              </th>
              <th title="Cadastro Nacional da Pessoa Jurídica">CNPJ:</th>
              <th>Apurar Valor Para:</th>
              <th title="Competência">CMPT</th>
              <th>Dados Adicionais</th>
            </tr>
            <tr
              v-for="(procedureItem, procedureItemIdx) in procedureList"
              :key="procedureItemIdx"
            >
              <td>{{ procedureItemIdx + 1 }}</td>
              <td>{{ getProcedureName(procedureItemIdx) }}</td>
              <td>{{ procedureItem.iap_quantidade }}</td>
              <td>{{ getCNSLabel(procedureItem.iap_documento_executante) }}</td>
              <td>{{ procedureItem.iap_cbo }}</td>
              <td>{{ procedureItem.iap_cnes }}</td>
              <td>{{ procedureItem.iap_cnpj }}</td>
              <td>{{ procedureItem.iap_numero_documento }}</td>
              <td>{{ procedureItem.iap_competencia }}</td>
              <td>
                {{ procedureItem.additionalData.length > 0 ? "Sim" : "Não" }}
              </td>
              <td @click="editLine(procedureItemIdx)">
                <button class="btn btn-small btn-primary">Editar</button>
              </td>
              <td @click="handleRemoveLine(procedureItemIdx, procedureItem)">
                <button class="btn btn-small btn-danger">Remover</button>
              </td>
            </tr>
          </table>
        </div>
      </RgValidatorForm>
    </form>
  </section>
</template>
<script>
import moment from "moment";
import { isEmpty } from "lodash";
import mask from "vue-the-mask";
import Garbage from "~tokio/primitive/icon/symbols/Garbage";
import EventBus from "~common/event/bus/EventBus";
import {
  RgInputMasked,
  RgTitle,
  RgInputCnpj,
  RgValidatorForm,
} from "~tokio/primitive";
import { RgTypeaheadProcedure, RgSuggestEmployee } from "$billing/common";
import RgComboboxAihDocumentType from "$billing/hospitalization-billing/common/component/combobox/rg-combobox-aih-document-type/RgComboboxAihDocumentType";
import RgComboboxService from "$billing/common/component/rg-combobox-service/RgComboboxService";
import RgComboboxClassification from "$billing/common/component/rg-combobox-classification/RgComboboxClassification";
import RgTypeaheadCbo from "$billing/common/component/rg-typeahead-cbo/RgTypeaheadCbo";
import ModalHospitalizationBillingChildbirth from "$billing/hospitalization-billing/common/component/container/modal-hospitalization-billing-childbirth/ModalHospitalizationBillingChildbirth";
import ModalHospBillChildbirthTincture from "$billing/hospitalization-billing/common/component/container/modal-hosp-bill-childbirth-tincture/ModalHospBillChildbirthTincture";
import ModalHospitalizationBillingTincture from "$billing/hospitalization-billing/common/component/container/modal-hospitalization-billing-tincture/ModalHospitalizationBillingTincture";
import ModalHospitalizationBillingVasectomy from "$billing/hospitalization-billing/common/component/container/modal-hospitalization-billing-vasectomy/ModalHospitalizationBillingVasectomy";
import ModalHospitalizationBillingMaterial from "$billing/hospitalization-billing/common/component/container/modal-hospitalization-billing-material/ModalHospitalizationBillingMaterial";
import ModalHospitalizationBillingCivil from "$billing/hospitalization-billing/common/component/container/modal-hospitalization-billing-civil/ModalHospitalizationBillingCivil";
import ModalHospitalizationBillingNeonatal from "$billing/hospitalization-billing/common/component/container/modal-hospitalization-billing-neonatal/ModalHospitalizationBillingNeonatal";
import ModalHospitalizationBillingWorkAccident from "$billing/hospitalization-billing/common/component/container/modal-hospitalization-billing-work-accident/ModalHospitalizationBillingWorkAccident";
import ModalHospitalizationBillingTeam from "$billing/hospitalization-billing/common/component/container/modal-hospitalization-billing-team/ModalHospitalizationBillingTeam";
import ProcedureAdditionalDataVerification from "$billing/hospitalization-billing/common/service/ProcedureAdditionalDataVerification";
import RgConfirmModal from "~tokio/primitive/notification/modal/RgConfirmModal";
import SearchDocumentType from "$billing/hospitalization-billing/common/component/combobox/rg-combobox-aih-document-type/action/SearchDocumentAihType";
import DocumentValid from "./DocumentValid";
import {
  AlertError,
  ValidateCbo,
  SpecialProcedures,
  ExecuteActionAvoidingConcurrency,
} from "../../../service/";
import SearchProcedure from "$billing/common/component/rg-typeahead-procedure/action/SearchProcedure";

export default {
  name: "HospitalizationBillingProcedureInfo",
  components: {
    Garbage,
    RgConfirmModal,
    RgValidatorForm,
    RgInputMasked,
    RgTitle,
    RgInputCnpj,
    RgTypeaheadProcedure,
    RgComboboxAihDocumentType,
    RgComboboxService,
    RgComboboxClassification,
    RgTypeaheadCbo,
    RgSuggestEmployee,
    ModalHospitalizationBillingChildbirth,
    ModalHospBillChildbirthTincture,
    ModalHospitalizationBillingTincture,
    ModalHospitalizationBillingMaterial,
    ModalHospitalizationBillingCivil,
    ModalHospitalizationBillingVasectomy,
    ModalHospitalizationBillingNeonatal,
    ModalHospitalizationBillingWorkAccident,
    ModalHospitalizationBillingTeam,
  },
  directives: {
    mask,
  },
  props: {
    stepProgress: {
      type: Number,
      default: 0,
    },
    savingFather: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      saving: false,
      adding: false,
      professionalCaptionRaw: "crs_numero - pes_nome",
      modals: {
        childbirthModal: false,
        tinctureModal: false,
        materialModal: false,
        civilModal: false,
        vasectomyModal: false,
        neonatalModal: false,
        workAccidentModal: false,
        childbirthTinctureModal: false,
        teamModal: false,
      },
      editMode: false,
      editIdx: "",
      deleteIdx: "",
      deleteProc: "",
      deleteModalMsg: "Você tem certeza que deseja remover o procedimento?",
      hasAdditionalData: false,
      procedureList: [],
      procedureForm: {
        iap_cbo: "",
        iap_codigo_procedimentos: "",
        iap_competencia: "",
        additionalData: [],
      },
      selectedProcedureDetails: [],
      confirmDelete: false,
      documentsTypes: [],
      totalOfBirths: 0,
      requiredDocuments: {},
      hasServiceAndClassification: false,
      procedureListNames: [],
    };
  },
  computed: {
    competence() {
      // TODO: get aih competence or the current code
      return this.$store.getters[
        "Billing/PeriodHospitalizationBilling/GET_PERIOD_HOSPITALIZATION_BILLING"
      ];
    },
    hospitalizationAihInfoState() {
      return this.$store.getters[
        "Billing/HospitalizationBilling/GET_HOSPITALIZATION_AIH_INFO"
      ];
    },
    hospitalizationInfoInState() {
      return this.$store.getters[
        "Billing/HospitalizationBilling/GET_HOSPITALIZATION_BILLING_INFO_TAB"
      ];
    },
    hospitalizationProcedures() {
      return this.$store.getters[
        "Billing/HospitalizationBilling/GET_HOSPITALIZATION_AIH_PROCEDURES"
      ];
    },
    hospitalizationPatientInfo() {
      return this.$store.getters[
        "Billing/HospitalizationBilling/GET_PATIENT_INFO_TAB"
      ];
    },
    hospitalizationAihReleaseRequestCode() {
      return this.$store.getters[
        "Billing/HospitalizationBilling/GET_HOSPITALIZATION_AIH_RELEASE_REQUEST_CODE"
      ];
    },
    unitHealthCnes() {
      return this.$store.getters["Login/GET_UNIT_HEALTH_CNES"];
    },
    requiredCPF() {
      if (!this.requiredDocuments.CPF || this.hasAdditionalData) return false;
      return this.requiredDocuments.CPF.required;
    },
    requiredCBO() {
      if (!this.requiredDocuments.CBO || this.hasAdditionalData) return false;
      return this.requiredDocuments.CBO.required;
    },
    requiredCNES() {
      if (!this.requiredDocuments.CNES || this.hasAdditionalData) return false;
      return this.requiredDocuments.CNES.required;
    },
    requiredExec() {
      if (!this.procedureForm.iap_codigo_procedimentos) return true;
      // Procedimentos com Materiais
      if (this.procedureForm.iap_codigo_procedimentos.substring(0, 2) === "07")
        return false;

      return this.hasAdditionalData;
    },
    hasDataToSave() {
      return this.procedureList && this.procedureList.length > 0;
    },
    canSave() {
      return !this.savingFather && !this.saving;
    },
  },
  watch: {
    async stepProgress() {
      if (this.procedureList) {
        if (this.stepProgress === 4 && this.procedureList.length < 1) {
          await this.setMainProcedure();
        }
        if (this.stepProgress === 4) {
          await this.updateCompetence();
        }
      } else if (this.stepProgress === 4) {
        await this.setMainProcedure();
        await this.updateCompetence();
      }
    },
    hospitalizationInfoInState() {
      // this.procedureForm.iap_codigo_procedimentos = this.hospitalizationInfoInState.inf_procedimento_principal
    },
    hospitalizationProcedures() {
      this.procedureList = [];
      this.procedureList = JSON.parse(
        JSON.stringify(this.hospitalizationProcedures),
      );
      // this.resetForm()
    },
    hospitalizationPatientInfo() {},
    hasAdditionalData() {},
    hospitalizationAihInfoState() {},
    savingFather(pData) {
      this.saving = pData;
    },
  },
  async beforeMount() {
    this.documentsTypes = await SearchDocumentType();
  },
  async mounted() {
    // Escutando para atualizar o vuex
    EventBus.$on("updateVuex", this.setDataToState);
    EventBus.$on("resetData", this.resetForm);
    await this.loadData();
    await this.updateCompetence();
    await this.setMainProcedure();
    await this.setProcedureListNames();
  },
  methods: {
    async setMainProcedure() {
      const mainProcedure = this.hospitalizationInfoInState
        .inf_procedimento_principal;
      this.procedureForm.iap_codigo_procedimentos = !SpecialProcedures.includes(
        mainProcedure,
      )
        ? mainProcedure
        : "";
    },
    async updateCompetence() {
      const cmp = moment(
        this.hospitalizationAihInfoState.iaf_data_internacao,
        "DD/MM/YYYY",
      ).format("MM/YYYY");
      if (!cmp) return;
      this.procedureForm.iap_competencia = cmp;
    },
    async save() {
      this.$nextTick(async () => {
        if (this.canSave && this.validateSpecialProceduresToSave()) {
          this.saving = true;
          this.$emit("saveInfoTabs", () => {});
          return true;
        }
      });
      return false;
    },
    hasTheSameItem(pItem) {
      return this.procedureList.some((procedure) => {
        return (
          procedure.iap_cbo === pItem.iap_cbo &&
          procedure.iap_codigo_procedimentos ===
            pItem.iap_codigo_procedimentos &&
          parseInt(procedure.iap_quantidade) ===
            parseInt(pItem.iap_quantidade) &&
          procedure.iap_competencia.replace("/", "") ===
            pItem.iap_competencia.replace("/", "") &&
          procedure.iap_documento_executante === pItem.iap_documento_executante
        );
      });
    },
    async add(pEvent) {
      const previousStatus = this.adding;
      this.adding = true;
      ExecuteActionAvoidingConcurrency(
        async () => {
          if (
            !previousStatus &&
            this.canSave &&
            (await this.validateProcedureToAdd())
          ) {
            this.adding = true;
            if (!(await this.validateForm())) {
              AlertError("Verifique os dados preenchidos.");
            } else if (this.hasTheSameItem(this.procedureForm)) {
              this.resetForm();
              AlertError("Procedimento já adicionado anteriormente.");
            } else {
              if (this.editMode) {
                this.procedureList[this.editIdx] = JSON.parse(
                  JSON.stringify(this.procedureForm),
                );
                this.editMode = false;
                this.sanitizeFields();
                this.closeModals();
                this.resetForm();
              } else {
                if (!this.hasAdditionalData) {
                  this.procedureList.push(
                    JSON.parse(JSON.stringify(this.procedureForm)),
                  );
                  this.sanitizeFields();
                  this.resetForm();
                } else {
                  this.verifyModalToOpen();
                }
                this.setProcedureListNames();
              }
            }
            this.adding = false;
          }
        },
        this.$nextTick,
        300,
        600,
      );
    },
    addFromModal(pEvent) {
      const additionalData = JSON.parse(JSON.stringify(pEvent));
      if (!this.editMode) {
        const item = JSON.parse(JSON.stringify(this.procedureForm));
        if (additionalData.length >= 1) {
          item.additionalData = additionalData;
        } else {
          item.additionalData.push(additionalData);
        }
        this.procedureList.push(item);
      } else {
        this.procedureList[this.editIdx] = JSON.parse(
          JSON.stringify(this.procedureForm),
        );
        this.editMode = false;
      }
      this.sanitizeFields();
      this.closeModals();
      this.resetForm();
      this.setProcedureListNames();
    },
    sanitizeFields() {
      for (let index = 0; index < this.procedureList.length; index++) {
        const item = this.procedureList[index];
        item.iap_competencia = item.iap_competencia
          ? item.iap_competencia.replace(/[^\d]+/g, "")
          : null;
        item.iap_cnpj = item.iap_cnpj
          ? item.iap_cnpj.replace(/[^\d]+/g, "")
          : null;
        item.iap_cnes = item.iap_cnes
          ? item.iap_cnes.replace(/[^\d]+/g, "")
          : null;
        item.iap_codigo_classificacao =
          item.iap_codigo_classificacao === false
            ? null
            : item.iap_codigo_classificacao;
        item.iap_codigo_servico =
          item.iap_codigo_servico === false ? null : item.iap_codigo_servico;
      }
    },
    async loadData() {
      const iafId = this.$store.getters[
        "Billing/HospitalizationBilling/GET_HOSPITALIZATION_BILLING_AIH_ID"
      ];
      const savedProcedures = await this.$store.dispatch(
        "Billing/HospitalizationBilling/GET_HOSPITALIZATION_AIH_PROCEDURES",
        { iaf_id: iafId },
      );
      const omitTypename = (key, value) =>
        key === "__typename" ? undefined : value;
      const newPayload = JSON.parse(
        JSON.stringify(savedProcedures),
        omitTypename,
      );
      this.$store.commit(
        "Billing/HospitalizationBilling/SET_HOSPITALIZATION_AIH_PROCEDURES",
        newPayload,
      );
      this.procedureForm.iap_cnes = this.unitHealthCnes;
    },
    clearList(pEvent) {
      this.closeModals();
      this.resetForm();
      this.editMode = false;
    },
    professionalSelected(pData) {
      if (pData && pData.source) {
        this.procedureForm.iap_cbo = pData.source.ocp_codigo;
        this.procedureForm.iap_documento_tipo_executante = 2; // cns
        this.procedureForm.iap_documento_executante = pData.source.crs_numero;
      }
    },
    async documentSelected(pData) {
      if (pData[0]) {
        // Cnes Prop.
        if (pData[0].value === 4) {
          this.procedureForm.iap_numero_documento = await this.$store.getters[
            "Login/GET_UNIT_HEALTH_CNES"
          ];
        } else {
          this.procedureForm.iap_numero_documento = "";
        }
      }
    },
    editLine(pIdx) {
      this.editMode = true;
      this.editIdx = pIdx;
      this.procedureForm = JSON.parse(JSON.stringify(this.procedureList[pIdx]));
      this.verifyAdditionalData();
      this.verifyModalToOpen();
    },
    async removeLine(pData) {
      this.confirmDelete = false;
      if (this.deleteProc.iap_id) {
        await this.$store.dispatch(
          "Billing/HospitalizationBilling/DELETE_HOSPITALIZATION_AIH_PROCEDURES",
          this.deleteProc.iap_id,
        );
      }
      this.procedureList.splice(this.deleteIdx, 1);
      if (this.deleteIdx === 0) {
        AlertError(
          "O procedimento com registro AIH principal foi removido, para realizar o faturamento é necessário ao menos um procedimento de registro AIH principal.",
        );
      }
    },
    async validateForm() {
      const valid = await this.$refs.validator.validate();
      return valid;
    },
    validateDocumentNumber(pValue, pErrors) {
      if (!this.hasAdditionalData && isEmpty(pValue)) {
        pErrors.push("Campo obrigatório");
        return false;
      }

      if (!isEmpty(pValue)) {
        const valid = DocumentValid(
          pValue,
          this.procedureForm.iap_id_internacoes_documentos_aih,
          pErrors,
        );

        if (!valid) AlertError(pErrors[0]);

        return valid;
      }

      return true;
    },
    validateCbo(pCbo, pErrors) {
      if (!this.requiredCBO) return true;

      return isEmpty(pCbo)
        ? AlertError("Campo obrigatório", pErrors)
        : ValidateCbo(
            this.procedureForm.iap_codigo_procedimentos,
            pCbo,
            this.procedureForm.iap_competencia,
            pErrors,
          );
    },
    tabIsValid() {
      if (!this.procedureList) return false;

      if (this.procedureList.length < 1) {
        return AlertError(
          "Nenhum procedimento foi adicionado na aba de procedimentos.",
        );
      }

      if (
        SpecialProcedures.includes(
          this.hospitalizationInfoInState.inf_procedimento_principal,
        ) &&
        this.procedureList[0].procedure.CO_REGISTRO !== "03"
      ) {
        return AlertError(
          "Procedimento deve ser do tipo de registro AIH principal.",
        );
      }

      if (
        this.procedureList[0].iap_codigo_procedimentos !==
        this.hospitalizationInfoInState.inf_procedimento_principal
      ) {
        return AlertError(
          "O procedimento principal deve constar na primeira linha.",
        );
      }
      return true;
    },
    getDocumentName(pId) {
      if (this.documentsTypes) {
        const item = this.documentsTypes.filter((item) => item.ind_id === pId);
        if (item[0]) return item[0].ind_descricao;
      }
      return "";
    },
    getCNSLabel(pCrs) {
      if (pCrs) {
        return "CNS - " + pCrs;
      }
    },
    verifyAdditionalData() {
      const additionalData = ProcedureAdditionalDataVerification.getAdditionalData(
        this.procedureForm.iap_codigo_procedimentos,
      );

      if (!additionalData) {
        this.hasAdditionalData = false;
        return;
      }

      if (additionalData.hasChildbirth) {
        this.hasAdditionalData = true;
        return;
      }

      if (additionalData.hasTincture) {
        this.hasAdditionalData = true;
        return;
      }

      if (additionalData.hasChildbirthTincture) {
        this.hasAdditionalData = true;
        return;
      }

      if (additionalData.hasMaterial) {
        this.hasAdditionalData = true;
        return;
      }

      if (additionalData.hasCivilRegistry) {
        this.hasAdditionalData = true;
        return;
      }

      if (additionalData.hasVasectomy) {
        this.hasAdditionalData = true;
        return;
      }

      if (additionalData.hasNeonatal) {
        this.hasAdditionalData = true;
        return;
      }

      if (additionalData.hasWorkAccident) {
        this.hasAdditionalData = true;
        return;
      }

      if (additionalData.hasTeam) {
        this.hasAdditionalData = true;
        return;
      }

      this.hasAdditionalData = false;
    },
    verifyModalToOpen() {
      const additionalData = ProcedureAdditionalDataVerification.getAdditionalData(
        this.procedureForm.iap_codigo_procedimentos,
      );

      if (!additionalData) return false;

      if (additionalData.hasChildbirth) {
        this.modals.childbirthModal = true;
        return this.modals.childbirthModal;
      }

      if (additionalData.hasTincture) {
        this.modals.tinctureModal = true;
        return this.modals.tinctureModal;
      }

      if (additionalData.hasChildbirthTincture) {
        this.modals.childbirthTinctureModal = true;
        return this.modals.childbirthTinctureModal;
      }

      if (additionalData.hasMaterial) {
        this.modals.materialModal = true;
        return this.modals.materialModal;
      }

      if (additionalData.hasCivilRegistry) {
        if (this.verifyHasChildBirth()) {
          this.modals.civilModal = true;
          return this.modals.civilModal;
        }
      }

      if (additionalData.hasVasectomy) {
        this.modals.vasectomyModal = true;
        return this.modals.vasectomyModal;
      }

      if (additionalData.hasNeonatal) {
        this.modals.neonatalModal = true;
        return this.modals.neonatalModal;
      }

      if (additionalData.hasWorkAccident) {
        this.modals.workAccidentModal = true;
        return this.modals.workAccidentModal;
      }

      if (additionalData.hasTeam) {
        this.modals.teamModal = true;
        return this.modals.teamModal;
      }

      return false;
    },
    closeModals() {
      this.modals.childbirthModal = false;
      this.modals.childbirthTinctureModal = false;
      this.modals.tinctureModal = false;
      this.modals.civilModal = false;
      this.modals.vasectomyModal = false;
      this.modals.neonatalModal = false;
      this.modals.materialModal = false;
      this.modals.workAccidentModal = false;
      this.modals.teamModal = false;
    },
    resetForm() {
      delete this.procedureForm.iap_id;
      delete this.procedureForm.iap_id_funcionarios;
      delete this.procedureForm.iap_codigo_servico;
      delete this.procedureForm.iap_codigo_classificacao;
      delete this.procedureForm.iap_id_internacoes_documentos_aih;
      delete this.procedureForm.iap_cnpj;
      delete this.procedureForm.iap_documento_tipo_executante;
      delete this.procedureForm.iap_numero_documento;
      delete this.procedureForm.iap_nome_executante;
      delete this.procedureForm.iap_documento_executante;
      this.procedureForm.iap_codigo_procedimentos = "";
      this.procedureForm.iap_quantidade = "";
      this.procedureForm.iap_cbo = "";
      this.procedureForm.additionalData = [];
      this.procedureForm.iap_cnes = this.unitHealthCnes;
      this.editMode = false;
    },
    setDataToState(pEvent) {
      this.$store.commit(
        "Billing/HospitalizationBilling/SET_HOSPITALIZATION_AIH_PROCEDURES",
        JSON.parse(JSON.stringify(this.procedureList)),
      );
    },
    async setProcedureDetails(pEvent) {
      const cmp = this.$store.getters[
        "Billing/PeriodHospitalizationBilling/GET_PERIOD_HOSPITALIZATION_BILLING"
      ];
      const data = await this.$store.dispatch(
        "Billing/HospitalizationBilling/GET_PROCEDURE_DETAILS",
        {
          CO_PROCEDIMENTO: this.procedureForm.iap_codigo_procedimentos,
          DT_COMPETENCIA: cmp,
        },
      );
      this.selectedProcedureDetails = JSON.parse(JSON.stringify(data));
      this.selectedProcedureDetails.maxQuantity = parseInt(
        pEvent.qt_maxima_execucao,
      );
      this.procedureForm.procedure = { CO_REGISTRO: pEvent.co_registro };
      this.verifyHasServiceAndClassification(pEvent.co_registro);
    },
    verifyHasServiceAndClassification(pRegisterType) {
      this.hasServiceAndClassification = false;
      if (
        SpecialProcedures.includes(
          this.procedureForm.iap_codigo_procedimentos,
        ) ||
        pRegisterType === "03" ||
        pRegisterType === "04"
      ) {
        this.hasServiceAndClassification = true;
      }
    },
    validateProcedureToAdd() {
      if (!this.procedureList) this.procedureList = [];

      const mainProcedure = this.hospitalizationInfoInState
        .inf_procedimento_principal;
      if (
        this.procedureList.length === 0 &&
        !SpecialProcedures.includes(mainProcedure) &&
        this.procedureForm.iap_codigo_procedimentos !== mainProcedure
      ) {
        return AlertError(
          "O procedimento principal deve constar na primeira linha.",
        );
      }
      return true;
    },
    validateProcedure(pEvent) {
      this.setProcedureDetails(pEvent);
      this.validateProcedureSelected(pEvent);
      this.validateMainProcedure(pEvent);
      this.validateProcedureSex(pEvent);
      this.validateProcedureAge(pEvent);
      this.verifyAdditionalData();
      this.getRequiredDocuments(pEvent);
    },
    async validateMainProcedure(pEvent) {
      const hasSpecialProcedure = SpecialProcedures.includes(
        this.hospitalizationInfoInState.inf_procedimento_principal,
      );
      if (this.editMode || !this.procedureList || hasSpecialProcedure)
        return true;
      if (this.procedureList.length >= 1) {
        const hasMainProcedureInCompetence = await this.hasMainProcedureInCompetence(
          this.procedureForm.iap_competencia.replace(/[^\d]+/g, ""),
        );
        if (pEvent.co_registro === "03" && hasMainProcedureInCompetence) {
          this.procedureForm.iap_codigo_procedimentos = "";
          return AlertError(
            "Não é possível adicionar mais de um procedimento principal na mesma competencia.",
          );
        }
      }
      return true;
    },
    async hasMainProcedureInCompetence(pCmp) {
      if (!this.procedureList) return false;
      const has = this.procedureList.filter(
        (item) =>
          item.iap_competencia === pCmp && item.procedure.CO_REGISTRO === "03",
      );
      return has.length > 0;
    },
    validateProcedureSelected(pItem, pWithAlert = true) {
      if (!pItem) return;
      const value = !isNaN(pItem) ? pItem : pItem.value;
      if (SpecialProcedures.includes(value)) {
        if (pWithAlert) AlertError("Procedimento inválido. ");
        this.procedureForm.iap_codigo_procedimentos = "";
        return false;
      }
      return true;
    },
    validateProcedureSex(pItem) {
      if (!pItem) return;
      if (pItem.tp_sexo !== this.hospitalizationPatientInfo.icf_sexo) {
        if (pItem.tp_sexo !== "I" && pItem.tp_sexo !== "N") {
          this.procedureForm.iap_codigo_procedimentos = "";
          return AlertError(
            "Procedimento incompatível com o sexo do paciente. ",
          );
        }
      }
      return true;
    },
    validateProcedureAge(pItem) {
      if (!pItem || !this.hospitalizationPatientInfo.icf_data_nascimento)
        return;
      if (pItem.vl_idade_minima === "9999" || pItem.vl_idade_maxima === "9999")
        return;

      const minAge = (pItem.vl_idade_minima / 12).toFixed(1);
      const maxAge = (pItem.vl_idade_maxima / 12).toFixed(1);
      const patienteAge = moment(
        this.hospitalizationPatientInfo.icf_data_nascimento,
        "DD/MM/YYYY",
      );
      const diffDuration = moment.duration(moment().diff(patienteAge));
      const age = diffDuration.years() + "." + diffDuration.months();

      if (
        parseFloat(age) > parseFloat(maxAge) &&
        this.hospitalizationAihReleaseRequestCode.ics_idade_maior !== 1
      ) {
        this.procedureForm.iap_codigo_procedimentos = "";
        return AlertError(
          "Procedimento incompatível com a idade do paciente. ",
        );
      }

      if (
        parseFloat(age) < parseFloat(minAge) &&
        this.hospitalizationAihReleaseRequestCode.ics_idade_menor !== 1
      ) {
        this.procedureForm.iap_codigo_procedimentos = "";
        return AlertError(
          "Procedimento incompatível com a idade do paciente. ",
        );
      }

      return true;
    },
    verifyHasChildBirth() {
      let has = false;
      for (let index = 0; index < this.procedureList.length; index++) {
        const element = this.procedureList[index];
        if (element.additionalData && element.additionalData.length > 0) {
          if (
            element.additionalData[0].type === "childbirth" ||
            element.additionalData[0].type === "childbirth-tincture"
          ) {
            this.totalOfBirths =
              element.additionalData[0].ipa_qtd_nascidos_vivos;
            has = this.totalOfBirths > 0;
          }
        }
      }
      if (!has)
        AlertError(
          "Para adicionar um registro civil é necessário ter ocorrido um parto.",
        );
      return has;
    },
    getTotalQuantityFromProcedure(pCodigoProcedimento, pValue) {
      const addedProcedure = (this.procedureList || []).find(
        (procedure) =>
          procedure.iap_codigo_procedimentos === pCodigoProcedimento,
      );

      return !addedProcedure
        ? parseInt(pValue)
        : addedProcedure.iap_quantidade + parseInt(pValue);
    },
    validateDays(pValue, pErrors) {
      if (isEmpty(pValue)) return AlertError("Campo obrigatório", pErrors);
      if (parseInt(pValue) <= 0)
        return AlertError("Valor deve ser maior do que 0", pErrors);

      if (this.selectedProcedureDetails) {
        const quantity = this.getTotalQuantityFromProcedure(
          this.procedureForm.iap_codigo_procedimentos,
          pValue,
        );
        const { maxQuantity } = this.selectedProcedureDetails;

        if (quantity > maxQuantity)
          return AlertError("Quantidade inválida para o procedimento", pErrors);
      }

      return true;
    },
    validateCompetence(pValue, pErrors) {
      if (isEmpty(pValue)) return AlertError("Campo obrigatório", pErrors);
      if (pValue.length < 7) return AlertError("Comptência inválida", pErrors);
      return true;
    },
    validateSpecialProceduresToSave() {
      if (
        SpecialProcedures.includes(
          this.hospitalizationInfoInState.inf_procedimento_principal,
        )
      ) {
        const mainProceduresList = this.procedureList.filter(
          (item) => item.procedure.CO_REGISTRO === "03",
        );

        if (mainProceduresList.length < 1) {
          return AlertError(
            "Quantidade de procedimentos principais deve ser maior ou igual a 1.",
          );
        }
      }
      return true;
    },
    validateProfessional(pEvent) {
      if (this.requiredCPF && !this.procedureForm.iap_documento_executante) {
        return AlertError("Necessário selecionar um profissional");
      }
      return true;
    },
    getRequiredDocuments(pProcedure) {
      this.requiredDocuments = ProcedureAdditionalDataVerification.getRequiredDocuments(
        pProcedure,
      );
    },
    getProcedureName(pIndex) {
      return this.procedureListNames[pIndex];
    },
    async setProcedureListNames() {
      this.procedureListNames = [];
      for (let i = 0; i < this.procedureList.length; i++) {
        const data = await SearchProcedure(
          {
            searchQuery: this.procedureList[i].iap_codigo_procedimentos,
            type: "AIH",
          },
          { findOne: true },
        );
        this.procedureListNames.push(
          data.CO_PROCEDIMENTO + " - " + data.NO_PROCEDIMENTO,
        );
      }
    },
    handleRemoveLine(pIdx, pDeleteProc) {
      this.clearList();
      this.confirmDelete = !this.confirmDelete;
      if (this.confirmDelete) {
        this.deleteIdx = pIdx;
        this.deleteProc = pDeleteProc;
      } else {
        this.deleteIdx = "";
        this.deleteProc = "";
      }
    },
  },
};
</script>
