<style
  src="./RgComboboxEmployeeRelationshipTypeSearchBar.scss"
  lang="scss"
></style>
<script>
import RgRemoteComboboxMixin from "~tokio/primitive/selection/mixin/RgRemoteComboboxMixin";
import SearchEmployeeRelationshipType from "./action/SearchEmployeeRelationshipType";

export default {
  name: "RgComboboxEmployeeRelationshipType",
  mixins: [RgRemoteComboboxMixin],
  props: {
    label: {
      default: "Tipo de Vinculo",
    },
  },
  data() {
    return {};
  },
  methods: {
    doSearch() {
      return SearchEmployeeRelationshipType();
    },
    formatItem(item) {
      return {
        value: item.tpv_id,
        text: `${item.tpv_nome}`,
      };
    },
  },
};
</script>
