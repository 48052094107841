import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import PatientSearch from "$person/patient/view/patient-search/PatientSearch";
import { OpenMenuModule } from "~common/utils/app/menu";

export default {
  path: "/person/patient/search",
  name: "person.patient.search",
  components: {
    default: PatientSearch,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Pacientes", link: OpenMenuModule("register") },
      { label: "Pacientes", link: "/person/patient/search" },
    ],
  },
};
