<template lang="html">
  <svg
    id="Layer"
    enable-background="new 0 0 64 64"
    height="512"
    viewBox="0 0 64 64"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m23.414 21.414 6.586-6.586v29.172c0 1.104.896 2 2 2s2-.896 2-2v-29.172l6.586 6.586c.39.391.902.586 1.414.586s1.024-.195 1.414-.586c.781-.781.781-2.047 0-2.828l-10-10c-.78-.781-2.048-.781-2.828 0l-10 10c-.781.781-.781 2.047 0 2.828.78.781 2.048.781 2.828 0z"
    />
    <path
      d="m50 40c-1.104 0-2 .896-2 2v8c0 1.103-.897 2-2 2h-28c-1.103 0-2-.897-2-2v-8c0-1.104-.896-2-2-2s-2 .896-2 2v8c0 3.309 2.691 6 6 6h28c3.309 0 6-2.691 6-6v-8c0-1.104-.896-2-2-2z"
    />
  </svg>
</template>

<script>
export default {
  name: "OpenUp",
};
</script>
