import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation DeleteHospitalizationTeam($ieqId: Int!) {
    DeleteHospitalizationTeam(ieq_id: $ieqId) {
      ieq_id
    }
  }
`;

export default async ({ rootState }, pIeqId) => {
  const variables = {
    ieqId: pIeqId,
  };
  try {
    const { data } = await GraphQLClient.mutate({
      mutation,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.DeleteHospitalizationTeam;
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors);
  }
};
