<template lang="html">
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M157.662,102.614c-4.427,0-8.017,3.589-8.017,8.017c0,9.725-7.912,17.637-17.637,17.637s-17.637-7.912-17.637-17.637
          s7.912-17.637,17.637-17.637c4.427,0,8.017-3.589,8.017-8.017s-3.589-8.017-8.017-8.017c-18.566,0-33.67,15.105-33.67,33.67
          s15.105,33.67,33.67,33.67s33.67-15.105,33.67-33.67C165.679,106.203,162.089,102.614,157.662,102.614z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M157.662,196.676c-4.427,0-8.017,3.589-8.017,8.017c0,9.725-7.912,17.637-17.637,17.637s-17.637-7.912-17.637-17.637
          s7.912-17.637,17.637-17.637c4.427,0,8.017-3.589,8.017-8.017s-3.589-8.017-8.017-8.017c-18.566,0-33.67,15.105-33.67,33.67
          s15.105,33.67,33.67,33.67s33.67-15.105,33.67-33.67C165.679,200.266,162.089,196.676,157.662,196.676z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M251.724,213.779h-59.858c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h59.858
          c4.427,0,8.017-3.589,8.017-8.017S256.152,213.779,251.724,213.779z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M251.724,179.574h-59.858c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h59.858
          c4.427,0,8.017-3.589,8.017-8.017S256.152,179.574,251.724,179.574z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M234.622,307.841h-42.756c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h42.756
          c4.427,0,8.017-3.589,8.017-8.017S239.049,307.841,234.622,307.841z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M251.724,273.637h-59.858c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h59.858
          c4.427,0,8.017-3.589,8.017-8.017S256.152,273.637,251.724,273.637z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M328.685,119.716H191.866c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h136.818
          c4.427,0,8.017-3.589,8.017-8.017S333.112,119.716,328.685,119.716z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M294.48,85.511H191.866c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017H294.48
          c4.427,0,8.017-3.589,8.017-8.017S298.908,85.511,294.48,85.511z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M157.662,290.739c-4.427,0-8.017,3.589-8.017,8.017c0,9.725-7.912,17.637-17.637,17.637s-17.637-7.912-17.637-17.637
          s7.912-17.637,17.637-17.637c4.427,0,8.017-3.589,8.017-8.017s-3.589-8.017-8.017-8.017c-18.566,0-33.67,15.105-33.67,33.67
          s15.105,33.67,33.67,33.67s33.67-15.105,33.67-33.67C165.679,294.328,162.089,290.739,157.662,290.739z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M362.889,0H72.15C58.3,0,47.031,11.268,47.031,25.119v359.148c0,13.851,11.268,25.119,25.119,25.119h145.37
          c4.427,0,8.017-3.589,8.017-8.017c0-4.427-3.589-8.017-8.017-8.017H72.15c-5.01,0-9.086-4.076-9.086-9.086V25.119
          c0-5.01,4.076-9.086,9.086-9.086h290.739c5.01,0,9.086,4.076,9.086,9.086v265.087c0,4.427,3.589,8.017,8.017,8.017
          c4.427,0,8.017-3.589,8.017-8.017V25.119C388.008,11.268,376.74,0,362.889,0z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M438.578,325.094c-7.451-0.743-14.898,1.369-20.792,5.844c-4.695-7.878-12.701-13.467-21.964-14.395
          c-7.453-0.742-14.899,1.37-20.792,5.844c-4.695-7.878-12.701-13.467-21.964-14.395c-5.69-0.568-11.372,0.528-16.365,3.069V208.969
          c0-8.289-3.526-16.235-9.677-21.8c-6.145-5.56-14.426-8.274-22.721-7.444c-14.799,1.482-26.391,14.863-26.391,30.464v102.35
          l-23.566,23.566c-12.523,12.523-17.578,30.291-13.521,47.531l17.891,76.037c7.249,30.811,34.418,52.329,66.07,52.329h72.307
          c37.426,0,67.875-30.448,67.875-67.875v-88.567C464.969,339.957,453.377,326.576,438.578,325.094z M448.935,444.125
          c0,28.585-23.256,51.841-51.841,51.841h-72.307c-24.175,0-44.927-16.435-50.464-39.968l-17.891-76.037
          c-2.776-11.795,0.683-23.953,9.251-32.521l12.229-12.229v27.678c0,4.427,3.589,8.017,8.017,8.017s8.017-3.589,8.017-8.017V210.188
          c0-7.465,5.251-13.839,11.956-14.509c3.851-0.387,7.534,0.815,10.366,3.379c2.797,2.531,4.401,6.144,4.401,9.912v141.094
          c0,4.427,3.589,8.017,8.017,8.017s8.017-3.589,8.017-8.017v-12.827c0-3.768,1.603-7.381,4.401-9.912
          c2.834-2.564,6.515-3.767,10.366-3.379c6.704,0.671,11.956,7.045,11.956,14.51v20.157c0,4.427,3.589,8.017,8.017,8.017
          c4.427,0,8.017-3.589,8.017-8.017v-12.827c0-3.768,1.603-7.381,4.401-9.912c2.834-2.564,6.516-3.766,10.366-3.379
          c6.704,0.671,11.956,7.045,11.956,14.51v20.158c0,4.427,3.589,8.017,8.017,8.017c4.427,0,8.017-3.589,8.017-8.017v-12.827
          c0-3.768,1.603-7.381,4.401-9.912c2.834-2.563,6.513-3.767,10.366-3.378c6.704,0.67,11.956,7.044,11.956,14.509V444.125z"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "Address",
};
</script>
