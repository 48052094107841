import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import IntegrationServices from "@app/configuration/views/Integration-services/IntegrationServices";

export default {
  path: "/configuration/integration-services",
  name: "configuration.integration-services",
  components: {
    default: IntegrationServices,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [{ label: "Configuração" }, { label: "Lista de serviços" }],
  },
};
