<template>
  <RgSuggest
    ref="rgSuggest"
    v-model="inputValue"
    :label="label"
    :placeholder="placeholder"
    :rules="rules"
    :title="this.inputValue ? this.inputValue : title"
    :search-on-focus="searchOnFocus"
    :disabled="disabled"
  >
    <RgSuggestItem
      v-for="(item, idx) in suggestionList"
      :key="idx"
      :idx="idx"
      :item="item"
      class="rg-suggest-hospitalization-bed _fit-content"
    >
      <div class="hospitalization-bed-info">
        <span class="bed">
          {{ formatItem(item) }}
        </span>
      </div>
    </RgSuggestItem>
  </RgSuggest>
</template>

<script>
import {
  RgSuggest,
  RgSuggestItem,
  RgSuggestMixin,
} from "~tokio/primitive/selection";
import SearchHospitalizationBed from "./action/SearchHospitalizationBed";
import { isEmpty } from "lodash";

export default {
  name: "RgSuggestHospitalizationBed",
  components: {
    RgSuggest,
    RgSuggestItem,
  },
  mixins: [RgSuggestMixin],
  props: {
    label: {
      type: String,
      default: "Leito",
    },
    placeholder: {
      type: String,
      default: "Informe o leito",
    },
    title: {
      type: String,
      default: "Leito",
    },
    emptyBed: {
      type: Boolean,
      default: false,
    },
    bedId: {
      type: Number,
      default: 0,
    },
    localId: {
      default: 0,
      required: true,
    },
    bedType: {
      default: 0,
      required: false,
    },
    includeActualBedInSearch: {
      type: Boolean,
      default: false,
    },
    excludeActualBedInSearch: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Number,
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      captionRaw: "inl_nome",
      filter: () => {
        return {
          searchQuery: this.inputValue,
        };
      },
    };
  },
  computed: {
    searchOnFocus() {
      return this.inputValue === "" || this.inputValue === null;
    },
  },
  methods: {
    doSearch(searchQuery, pLimit = null) {
      const variables = {
        inl_nome: searchQuery,
        inl_id_locais_atendimento: this.localId,
        limit: pLimit,
      };

      if (this.includeActualBedInSearch && this.bedId) {
        variables.actualBed = this.includeActualBedInSearch;
      }

      if (this.excludeActualBedInSearch && this.bedId) {
        variables.exceptBedCurrent = this.excludeActualBedInSearch;
      }

      if (this.bedId) {
        variables.inl_id = this.bedId;
      }

      if (this.emptyBed) {
        variables.inl_id_internacoes_situacoes_leito = 1;
      }

      if (this.bedType) {
        variables.ilt_id_internacoes_tipos_leito = this.bedType;
      }

      if (this.isActive) {
        variables.inl_habilitado = this.isActive;
      }

      return SearchHospitalizationBed(variables);
    },
    formatItem(pItem) {
      if (pItem && pItem.inl_nome) {
        return pItem.inl_nome;
      }
    },
    validate() {
      this.$refs.rgSuggest.validate();
    },
    cleanValidate() {
      this.$refs.rgSuggest.cleanValidate();
    },
    async forceSelection(pValue) {
      if (isEmpty(pValue)) return;

      if (pValue !== this.inputValue) this.inputValue = pValue;

      const result = await this.doSearch(pValue);
      if (result.rows && Array.isArray(result.rows) && result.rows.length > 0) {
        this.$refs.rgSuggest.itemSelected = true;
        if (result.rows.length === 1)
          this.selectingItemFromSuggestList(result.rows[0]);
        else {
          const selectedItem = result.rows.find(
            (item) => item.inl_nome === pValue,
          );
          this.selectingItemFromSuggestList(selectedItem);
        }
      }
    },

    clearData() {
      this.suggestionList = null;
    },
  },
};
</script>
