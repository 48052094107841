<template>
  <div v-if="show">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      withCloseButton
      :show="show"
      class="modal-advanced-patient-search"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="modal-advanced-patient-search-header">
        <h1 class="title">Filtros</h1>

        <h1 class="title">{{ title }}</h1>
      </div>

      <div slot="body" class="modal-advanced-patient-search-body">
        <div class="filter">
          <RgValidatorForm ref="validator" class="grid">
            <div class="selectinput">
              <RgInput
                v-model="form.pes_nome"
                label="Paciente"
                placeholder="Digite o nome do paciente"
              />
            </div>

            <div class="selectinput">
              <RgInputDate
                v-model="form.pes_nascimento"
                label="Data de Nascimento"
                placeholder="dd/mm/aaaa"
              />
            </div>

            <div class="selectinput">
              <RgInput
                v-model="form.pes_mae"
                label="Nome da Mãe"
                placeholder="Digite o nome da mãe do paciente"
              />
            </div>
          </RgValidatorForm>
          <div class="footer-filter">
            <RgCleanButton type="button" class="space" @click="cleanForm" />
            <RgSearchButton
              ref="btnSubmit"
              v-shortkey="['enter']"
              type="button"
              data-id="buscar"
              title="Buscar"
              @shortkey.native="searchByClick"
              @submit="searchByClick"
            />
          </div>
        </div>

        <div ref="searchArea" class="search">
          <div v-show="mutableData.length === 0" class="no-result">
            <IconEmpty class="empty-icon" />
            <span class="info"
              >Não foram encontrados resultados para essa busca.</span
            >
          </div>

          <RgList
            v-show="mutableData.length > 0"
            ref="rgList"
            v-model="mutableData"
            class="list"
            :search-function="searchFunction"
            :build-filter="generateFilter"
            :show-total="true"
            :register-per-page="itemPerPage"
            :max-register="34"
          >
            <RgTable ref="rgTable" :columns="COLUMNS" class="rg-table">
              <tr
                v-for="item in mutableData"
                :key="item.pes_id"
                slot="rows"
                class="tr"
                :class="{
                  selected: selectRow && item.pes_id === selectRow.pes_id,
                }"
                @click="setSelectRow(item)"
              >
                <td>{{ item.pes_nome }}</td>
                <td>
                  {{ $utils.date.BrazilianDateFormat(item.pes_nascimento) }}
                </td>
                <td>{{ item.pes_mae }}</td>
              </tr>
            </RgTable>
          </RgList>

          <div class="footer-paginate">
            <MediumButton
              v-show="mutableData.length > 0"
              :disabled="disableSelectButton"
              label="Selecionar"
              @click="emitSelection"
            />
          </div>
        </div>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import {
  RgBaseModal,
  RgInput,
  RgInputDate,
  IconEmpty,
  RgValidatorForm,
  MediumButton,
} from "~tokio/primitive";
import { RgCleanButton, RgSearchButton } from "~tokio/primitive/button";
import { RgList, RgTable } from "~tokio/foundation";

const FORM = {
  pes_nome: "",
  pes_mae: "",
  pes_nascimento: "",
};

export default {
  name: "ModalPatientImageInRow",
  components: {
    RgBaseModal,
    RgList,
    RgTable,
    RgInput,
    RgInputDate,
    RgCleanButton,
    RgSearchButton,
    IconEmpty,
    RgValidatorForm,
    MediumButton,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Busca Avançada de Pacientes",
    },
    withPatientModule: {
      type: Array,
      default: function () {
        return [];
      },
    },
    extraData: {
      type: Array,
      default: function () {
        return [];
      },
    },
    enabledPatient: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      mutableData: [],
      itemPerPage: 14,
      submitClicked: false,
      form: this.$utils.obj.DeepCopy(FORM),
      selectRow: null,
    };
  },

  computed: {
    withPatientAppointment() {
      return this.withPatientModule.includes("appointment");
    },
    withPatientExam() {
      return this.withPatientModule.includes("exam2");
    },
    withPatientHospitalization() {
      return this.withPatientModule.includes("hospitalization");
    },
    withPatientEmergency() {
      return this.withPatientModule.includes("emergency");
    },
    withTelephone() {
      return this.extraData.includes("telephone");
    },
    withEthnicity() {
      return this.extraData.includes("ethnicity");
    },
    withNaturality() {
      return this.extraData.includes("naturality");
    },
    withNationality() {
      return this.extraData.includes("nationality");
    },
    withoutAddress() {
      return this.extraData.includes("withoutAddress");
    },
    disableSelectButton() {
      return !this.selectRow || !this.selectRow.pes_id;
    },
  },

  watch: {
    show(pValue) {
      if (pValue) {
        this.calculateItemPerPage();
      }
    },
  },

  created() {
    this.COLUMNS = [
      { name: "Paciente", key: "pes_nome" },
      { name: "Data de Nascimento", key: "pes_nascimento" },
      { name: "Nome da Mãe", key: "pes_mae" },
    ];

    this.ITEM_SIZE = 26.5;
    this.RGPAGINATOR = 42;
    this.TABLE_HEADER = 26;
  },

  methods: {
    calculateItemPerPage() {
      this.$nextTick(() => {
        if (this.$refs.searchArea) {
          const searchAreaHeight = this.$refs.searchArea.offsetHeight;

          this.itemPerPage = Math.floor(
            (searchAreaHeight - this.RGPAGINATOR - this.TABLE_HEADER) /
              this.ITEM_SIZE,
          );
        }
      });
    },

    searchByClick() {
      this.calculateItemPerPage();
      this.submitClicked = true;
      this.$refs.rgList.submitForm(true);
    },

    async searchFunction(pData) {
      try {
        if (this.submitClicked === true) {
          this.$refs.btnSubmit.actionSubmit();
          if (!(await this.isFormValid())) {
            this.$toaster.warning("Verifique os campos");
            this.$refs.btnSubmit.fail();
            return false;
          }
          this.$loader.start("Carregando...");

          const data = await this.$store.dispatch(
            "Person/Patient/SEARCH_PERSON",
            pData,
          );

          this.$nextTick(() => {
            if (this.$refs.rgTable && this.$refs.rgTable.$refs.tbody) {
              this.$refs.rgTable.$refs.tbody.scrollTop = 0;
            }
          });

          this.$refs.btnSubmit.actionDone();

          return data;
        }
      } catch (pErr) {
        this.mutableData = [];
        this.$toaster.warning(
          "Não foi possível realizar a busca com os dados informados ou nenhum resultado foi encontrado.",
        );
        if (this.$refs.btnSubmit) {
          this.$refs.btnSubmit.fail();
        }
      } finally {
        this.$loader.finish();
      }
    },

    generateFilter() {
      const { pes_nascimento, pes_mae, pes_nome } = this.form;
      return {
        peopleName: pes_nome,
        birthDate: pes_nascimento
          ? this.$utils.date.BrazilianDateToDatabase(pes_nascimento)
          : "",
        pes_mae,
        withPatientAppointment: this.withPatientAppointment,
        withPatientExam: this.withPatientExam,
        withPatientHospitalization: this.withPatientHospitalization,
        withPatientEmergency: this.withPatientEmergency,
        withTelephone: this.withTelephone,
        withEthnicity: this.withEthnicity,
        withNaturality: this.withNaturality,
        withNationality: this.withNationality,
        withoutAddress: this.withoutAddress,
        isSearchable: this.enabledPatient,
      };
    },

    isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    setSelectRow(pData) {
      if (this.selectRow && this.selectRow.pes_id === pData.pes_id) {
        this.selectRow = null;
        return;
      }

      this.selectRow = pData;
    },

    emitSelection() {
      if (this.selectRow && this.selectRow.pes_id) {
        this.$emit("selected", this.selectRow);
        this.close();
      }
    },

    cleanForm() {
      this.mutableData = [];
      this.selectRow = null;
      this.form = this.$utils.obj.DeepCopy(FORM);
    },

    close() {
      this.submitClicked = false;
      this.$emit("close");
      this.cleanForm();
    },
  },
};
</script>
