import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query {
    queue_status {
      label
      value
    }
  }
`;

export default async ({ commit }) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      fetchPolicy: "no-cache",
    });
    return data.queue_status;
  } catch (err) {
    return err.graphQLErrors;
  }
};
