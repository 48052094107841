import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchCid($cid_codigo: String) {
    cid(cid_codigo: $cid_codigo) {
      count
      rows {
        cid_id
        cid_nome
        cid_sexo
        cid_codigo
      }
    }
  }
`;

export default async (variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    return data.cid.rows[0];
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors);
  }
};
