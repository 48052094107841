import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query searchCustomPatientExchange(
    $int_id: Int # Id da internação de origem
    $uns_id: Int # Id da Unidade de Saúde
    $set_id: [Int] # Id do Setor
    $lca_id: Int # Id do Local de Atendimento
    $itl_id: Int # Id do Tipo de Leito
    $int_numero: Int # Número da Internação
    $paciente: String # Nome do Paciente
    $limit: Int
    $offset: Int
  ) {
    customPatientExchange(
      int_id: $int_id
      uns_id: $uns_id
      set_id: $set_id
      lca_id: $lca_id
      itl_id: $itl_id
      int_numero: $int_numero
      paciente: $paciente
      limit: $limit
      offset: $offset
    ) {
      count
      rows {
        int_id
        isl_id
        ihs_observacao
        ils_nome
        int_numero
        ocp_nome
        int_hora
        int_data
        iti_nome
        uns_id
        uns_nome
        itl_id
        set_id
        set_nome
        lca_id
        lca_nome
        inl_id
        inl_nome
        pes_id
        pac_id
        pac_prontuario_unico
        usu_nome
        inl_extra
        inl_observacao
        itl_nome
        tipos_do_leito
        paciente
        crs_numero
        leito_extra
        leito_observacao
        inl_id_internacoes_situacoes_leito
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    return data.customPatientExchange;
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors);
  }
};
