<style src="./RgImportButton.scss" lang="scss" scoped></style>
<template>
  <div class="import-button">
    <button
      :title="title"
      :data-id="dataId"
      :class="{
        'import-button --small': small,
        'import-button --medium': medium,
        'import-button --large': large,
      }"
      class="import"
      @click.stop.prevent="clicked"
    >
      <div v-show="!small" class="text">
        <span>{{ label }}</span>
      </div>

      <div v-show="large" class="separator" />

      <div v-show="!medium" class="icon">
        <IconImport />
      </div>
    </button>
  </div>
</template>

<script>
import { IconImport } from "~tokio/primitive/icon/modules";

export default {
  name: "RgImportButton",
  components: {
    IconImport,
  },
  props: {
    dataId: {
      type: String,
      default: "importar",
    },
    label: {
      type: String,
      default: "Importar",
    },
    title: {
      type: String,
      default: "Importar",
    },
    small: {
      type: Boolean,
      default: true,
    },
    medium: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clicked() {
      this.$emit("click");
    },
  },
};
</script>
