<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchPriorityAb from "./action/SearchPriorityAb";

export default {
  name: "RgComboboxPrioridadeAb",
  mixins: [RgComboboxMixin],
  data: () => {
    return {};
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];

      const data = await SearchPriorityAb();

      if (Array.isArray(data)) {
        data.forEach((item) => {
          this.mData.push({ value: item.data, text: item.label });
        });
      }
    },
  },
};
</script>
