<template lang="html">
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 425 425"
    style="enable-background: new 0 0 425 425"
    xml:space="preserve"
  >
    <g>
      <path
        d="M293.333,45V20h-30v25H161.667V20h-30v25H0v360h425V45H293.333z M131.667,75v25h30V75h101.667v20h30V75H395v50H30V75
    H131.667z M30,375V155h365v220H30z"
      />
      <rect x="97.5" y="285" width="50" height="50" />
      <rect x="187.5" y="285" width="50" height="50" />
      <rect x="277.5" y="285" width="50" height="50" />
      <rect x="187.5" y="195" width="50" height="50" />
      <rect x="277.5" y="195" width="50" height="50" />
      <rect x="97.5" y="195" width="50" height="50" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Calendar",
};
</script>
