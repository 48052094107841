import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import AppointmentFormSchedule from "$appointment/submodules/schedule/views/appointment-form-schedule/AppointmentFormSchedule.vue";
import { OpenMenuModule } from "~common/utils/app/menu";

export default {
  path: "/appointment/schedule/per-periods/form",
  name: "appointment.schedule.per-periods.form",
  components: {
    default: AppointmentFormSchedule,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Consulta", link: OpenMenuModule("appointment") },
      {
        label: "Agendamentos por Período",
        link: "/appointment/schedule/per-periods",
      },
      {
        label: "Agendar",
        link: "/appointment/schedule/per-periods/form",
      },
    ],
  },
};
