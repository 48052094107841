<template lang="html">
  <svg
    width="79"
    height="30"
    viewBox="0 0 79 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.4414 14.9999C26.4414 15.8296 27.1033 16.4997 27.9229 16.4997H36.9544V21.364C36.9551 21.9715 37.3128 22.5199 37.8691 22.7506C38.4213 22.9826 39.0603 22.8532 39.4833 22.425L45.7698 16.0609C46.3491 15.4745 46.3484 14.5253 45.7698 13.9396L39.4833 7.5755C39.0603 7.14729 38.4227 7.01721 37.8684 7.24995C37.3142 7.48198 36.9537 8.02972 36.9537 8.63653V13.5008L27.9229 13.5001C27.1033 13.5008 26.4414 14.1709 26.4414 14.9999Z"
      fill="white"
    />
    <path
      d="M74.0844 0H65.1939H53.3398C50.8886 0 48.8945 2.0187 48.8945 4.50007V16.4998V25.4999C48.8945 27.9813 50.8886 30 53.3398 30H62.2302H74.0844C76.5355 30 78.5296 27.9813 78.5296 25.4999V13.5002V4.50007C78.5296 2.01941 76.5348 0 74.0844 0Z"
      fill="white"
    />
    <path
      d="M28.1529 20.817C27.3347 20.817 26.6714 21.4885 26.6714 22.3168V25.4999C26.6714 26.3282 26.006 27.0004 25.1899 27.0004H16.3015C16.3008 27.0004 16.2994 27.0004 16.2987 27.0004H4.44523C4.13823 27.0004 3.85346 26.9055 3.61731 26.7438C3.60828 26.7374 3.59994 26.7304 3.59091 26.7241C3.56105 26.703 3.53118 26.6812 3.5034 26.658C3.48742 26.6446 3.47284 26.6299 3.45825 26.6158C3.43811 26.5975 3.41727 26.5799 3.39852 26.561C3.37976 26.542 3.3624 26.5209 3.34434 26.5005C3.33045 26.485 3.31586 26.4703 3.30267 26.4548C3.27975 26.4267 3.25822 26.3964 3.23738 26.3662C3.23113 26.3571 3.22418 26.3486 3.21793 26.3395C3.05818 26.0997 2.96441 25.8114 2.96441 25.5013V4.50007C2.96441 4.18928 3.05818 3.901 3.21793 3.66193C3.22418 3.65279 3.23113 3.64435 3.23738 3.63521C3.25822 3.60498 3.27975 3.57474 3.30267 3.54662C3.31586 3.53045 3.33045 3.51568 3.34434 3.50091C3.3624 3.48052 3.37976 3.45943 3.39852 3.44044C3.41727 3.42146 3.43811 3.40388 3.45825 3.3856C3.47353 3.37154 3.48812 3.35677 3.5034 3.34341C3.53118 3.32021 3.56105 3.29841 3.59091 3.27732C3.59994 3.27099 3.60828 3.26396 3.61731 3.25763C3.85415 3.09591 4.13893 3.00098 4.44523 3.00098H25.1892C26.0074 3.00028 26.6714 3.67389 26.6714 4.50007V7.68387C26.6714 8.51216 27.3347 9.18366 28.1529 9.18366C28.9711 9.18366 29.6344 8.51216 29.6344 7.68387V4.50007C29.6344 2.01448 27.6445 0 25.1892 0H4.44523C3.22001 0 2.10871 0.504852 1.3037 1.31979C0.498699 2.13472 0 3.25974 0 4.50007V25.4999C0 26.7403 0.498699 27.8653 1.3037 28.6802C2.10871 29.4951 3.22001 30 4.44523 30H16.2987C16.2994 30 16.3008 30 16.3015 30H25.1892C27.6445 30 29.6344 27.9855 29.6344 25.4999V22.3168C29.6351 21.4885 28.9718 20.817 28.1529 20.817Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: "Transfer",
};
</script>
