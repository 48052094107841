<template lang="html">
  <div v-if="show" class="modal-confirm-queue-deletion">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <RgSearch
        ref="rgsearch"
        v-model="mutableEmployeeAgenda"
        :search-function="searchFilter"
        :clear-function="cleanForm"
        :build-filter="generateFilter"
        :show-footer="false"
        :show-empty-message="false"
        :showTitle="false"
        :disabledShorkey="true"
        @afterPerformSearch="afterSearchFilter"
        @count="getCountValue"
      ></RgSearch>
    </RgBaseModal>
  </div>
</template>

<script>
import { RgBaseModal } from "~tokio/primitive";

export default {
  name: "ModalTransfHistory",
  components: {
    RgBaseModal,
  },
  props: {
    show: Boolean,
  },
  methods: {
    async actionConfirm() {
      this.$emit("confirm", true);
    },

    close() {
      this.$emit("close");
    },
  },
};
</script>
