export default {
  EMPLOYEE_LIST: (state, { list }) => {
    state.employeeList = list;
  },
  RESET_RELATIONSHIP: (state) => {
    state.relationship = {
      vin_id: null,
      vin_id_especializacoes: null,
      vin_id_tipos_vinculo: null,
      vin_carga_horaria: null,
      vin_id_ocupacoes_cbo: null,
      vin_admissao: null,
    };
  },
  RESET_RELATIONSHIP_SECTOR: (state) => {
    state.relationshipSector = {
      vus_id: null,
      vus_id_setores: null,
      vus_carga_horaria_setores: null,
      vus_responsavel: null,
      vus_ativo: null,
      vus_id_vinculos: null,
    };
  },
  SET_RELATIONSHIP: (state, relationship) => {
    state.relationship = relationship;
  },
  SET_RELATIONSHIP_SECTOR: (state, relationshipSector) => {
    state.relationshipSector = relationshipSector;
  },
};
