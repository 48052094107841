import MenuMap from "./MenuMap.json";
import isActive from "./isActive";

export const GetModulesFromJson = () => {
  const modulesMap = {};

  if (!MenuMap || Object.keys(MenuMap).length <= 0) {
    throw new Error("Falha ao carregar Modulos do Menu!");
  }

  for (const modules in MenuMap) {
    const module = MenuMap[modules];
    const active = isActive(module);

    modulesMap[modules] = {
      name: module.name,
      iconName: module.iconName,
      permission: module.permission,
      active: active,
      className: module.className,
      link: active === false ? null : module.link,
    };
  }

  return modulesMap;
};
