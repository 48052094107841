/*
 * Recebe uma String com o link do breadcrumb, e verificar se existe essa rota no breadcrumb
 * Caso exista, retorna a rota
 * @param pLink {String} String do link
 * @param pBreadcrumb {Array} Array do pBreadcrumb
 */
const ValidateIfRouteExistInBreadscrumb = (pLink, pBreadcrumb) => {
  if (!pBreadcrumb) {
    return false;
  }
  const existData = pBreadcrumb.find((item) => {
    if (typeof item.link === "string") {
      if (item.link === pLink) {
        return true;
      }
    }
    return false;
  });

  return !!existData;
};

export { ValidateIfRouteExistInBreadscrumb };
export default ValidateIfRouteExistInBreadscrumb;
