import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (context, { intIdBoletim, strMotivo }) => {
  try {
    const { data } = await AuthLegacyRequest.post(
      "/emergencias/controller-boletim/inativar",
      {
        intIdBoletim,
        strMotivo,
      },
    );

    return HandleResponse(data);
  } catch (pError) {
    return Promise.reject(pError);
  }
};
