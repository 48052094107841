<template>
  <Modulebox
    title="Agendar Externo"
    class="appointment-regulation-form-external-schedule"
  >
    <QuickExamExternalScheduleRegulationForm
      ref="form"
      class="appointment-regulation-form-external-schedule-form"
      @disabledBtnSave="onDisabledBtnSave"
    />

    <div class="footer">
      <RgCancelButton ref="cancelButton" medium class="save" @click="cancel" />

      <RgSaveButton
        ref="saveButton"
        medium
        class="save"
        type="button"
        :disabled="disabledBtnSave"
        @click="saveForm"
      />
    </div>
  </Modulebox>
</template>

<script>
import QuickExamExternalScheduleRegulationForm from "$exam/common/component/quick-exam-external-schedule-regulation-form/QuickExamExternalScheduleRegulationForm";
import { RgSaveButton, RgCancelButton } from "~tokio/primitive";
import { Modulebox } from "~tokio/foundation";

export default {
  name: "ExamRegulationFormExternalSchedule",
  components: {
    RgSaveButton,
    Modulebox,
    QuickExamExternalScheduleRegulationForm,
    RgCancelButton,
  },

  data() {
    return {
      disabledBtnSave: true,
    };
  },

  methods: {
    cancel() {
      this.$router.push({ name: "exam.schedule.queue" });
    },

    async saveForm() {
      try {
        this.$refs.saveButton.actionSubmit();
        await this.$refs.form.saveForm();
        this.$refs.saveButton.actionDone();
      } catch (Err) {
        this.$refs.saveButton.fail();
      }
    },

    onDisabledBtnSave(value) {
      this.disabledBtnSave = value;
    },
  },
};
</script>
