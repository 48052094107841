import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import ImportTabelaUnificada from "$tabela-unificada/view/import-tabela-unificada/ImportTabelaUnificada";
export default {
  path: "/register/configuration/import-tabelaunificada",
  name: "register.tabelaunificada.import",
  components: {
    default: ImportTabelaUnificada,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "TabelaUnificada", link: "/tabelaunificada" },
      { label: "Importar Arquivo Tabela Unificada" },
    ],
  },
};
