import {
  GraphQLClient,
  GenerateParams,
  gql,
} from "@/common/request/GraphQLClient";
import HandleActionError from "@/common/request/HandleActionError";

const { variables, parameters } = GenerateParams({
  est_sigla: "String",
  est_sigla_like: "Boolean",
  limit: "Int",
  orderField: "String",
});

const query = gql`
query States (${variables}) {
  states (${parameters}) {
    rows {
      est_id
      est_sigla
    }
  }
}`;

export default async (pVariables) => {
  const variables = {
    est_sigla: pVariables.ufSigla || pVariables.est_sigla,
    est_sigla_like:
      pVariables.ufSiglaLike || pVariables.est_sigla_like || false,
    limit: pVariables.limit || 27,
  };

  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
    });

    return data.states.rows;
  } catch (error) {
    return Promise.reject(HandleActionError(error));
  }
};
