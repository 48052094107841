import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query ActiveSearch {
    SearchActiveSearch(limit: 100) {
      rows {
        bat_id
        bat_codigo_ab
        bat_nome
      }
      count
    }
  }
`;

export default async ({ commit, state }) => {
  if (state.active_search.length > 0) {
    return;
  }
  try {
    const { data } = await GraphQLClient.query({
      query,
      fetchPolicy: "no-cache",
    });
    commit("SET_ACTIVE_SEARCH", data.SearchActiveSearch.rows);
  } catch (err) {
    console.log(err);
    return err.graphQLErrors;
  }
};
