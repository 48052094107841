<style src="./RgRadioUnique.scss" lang="scss" scoped></style>
<template>
  <fieldset class="rg-radio-unique">
    <label :class="{ active: mutableValue }" class="radio-unique">
      <span class="text">{{ label }}</span>
      <input v-model="mutableValue" class="checkbox" type="checkbox" />
    </label>
  </fieldset>
</template>

<script>
export default {
  name: "RgToggleRadio",
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: Boolean,
      default: false,
    },
    dataId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      mutableValue: false,
    };
  },
  watch: {
    value(pValue) {
      this.mutableValue = pValue;
      this.$emit("input", pValue);
    },
    mutableValue(pValue) {
      this.$emit("input", pValue);
    },
  },
  mounted() {
    this.mutableValue = this.value;
  },
};
</script>
