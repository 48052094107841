<template>
  <div class="hospitalization-schedule-queue">
    <RgSearch
      ref="rgsearch"
      v-model="mutableHospitalizationQueue"
      :build-filter="generateFilter"
      :search-function="searchFilter"
      :clear-function="cleanForm"
      :item-height="40.6"
      :max-register="30"
      :show-title="true"
      :show-empty-message="true"
      :showFooter="hasHospitalizationQueue"
      :result-title="'Regulação'"
      @viewClickPagination="viewClickPagination"
    >
      <div slot="filters" class="hospitalization-schedule-queue-filter">
        <div class="grid">
          <div class="selectinput">
            <RgSuggestTypeBed
              v-model="form.bedType"
              :rules="{ forceSelection: true }"
              class="inputitem"
              @selected="selectingBedType"
            />
          </div>
          <div class="selectinput">
            <RgComboboxUnithealth
              v-model="form.unit"
              :default-text="'Todas'"
              permission="internacao.filaInternacao"
              label="Unidade Solicitante"
              class="inputitem"
            />
          </div>

          <div class="filter-title">
            <span class="title">Dados do Paciente</span>
          </div>

          <div class="selectinput">
            <RgInput
              ref="patient"
              v-model="form.patientName"
              :disabled="disableNameInput"
              :class="{ disable: disableNameInput }"
              class="inputitem"
              placeholder="Informe o nome do paciente"
              label="Paciente"
            />
          </div>
          <div class="selectinput">
            <RgSuggestSmartPerson
              ref="patient"
              v-model="form.document"
              :disabled="disableDocumentInput"
              :class="{ disable: disableDocumentInput }"
              :with-patient-module="['hospitalization']"
              filterOnlyDocument
              @selected="selectingPatient"
            />
          </div>
          <div class="selectinput">
            <RgComboboxPriority
              v-model="form.priority"
              :module-id="hospitalizationModuleId"
              label="Prioridade"
              default-text="Todas"
              class="inputitem"
            />
          </div>

          <div class="selectinput">
            <RgComboboxSituation
              v-model="form.situation"
              :default-text="'Todas'"
              label="Situação"
              class="inputitem"
            />
          </div>

          <div class="selectinput">
            <RgSuggestState
              ref="state"
              v-model="form.est_sigla"
              label="Estado"
              class="inputitem state"
              @selected="selectingState"
            />
          </div>

          <div class="selectinput">
            <RgSuggestCity
              v-model="form.mun_nome"
              :class="{ disable: disableCounty }"
              :disabled="disableCounty"
              :state="form.est_id"
              label="Município"
              placeholder="Informe o município"
              class="inputitem city"
              @selected="selectingMunicipality"
            />
          </div>
          <div class="selectinput">
            <RgSuggestProcedures
              ref="mainProcedure"
              v-model="form.procedure"
              data-id="procedimento"
              data-item="procedimento-item"
              placeholder="Informe o Procedimento"
              label="Procedimento"
              @selected="selectingProcedure"
            />
          </div>
          <div class="selectinput">
            <RgSuggestCid
              ref="mainCid"
              v-model="form.cid"
              data-id="CID"
              label="CID"
              class="cid"
              @selected="selectingCidCode"
            />
          </div>
          <div class="filter-title">
            <span class="title">Período do Pedido</span>
          </div>

          <div class="selectinput">
            <div class="period">
              <RgInputDate
                v-model="form.initialOrderPeriod"
                :rules="{ fn: verifyOrderInitialDate }"
                placeholder="dd/mm/aaaa"
                label="Data Inicial"
                class="date"
              />

              <RgInputDate
                v-model="form.finalOrderPeriod"
                placeholder="dd/mm/aaaa"
                label="Data Final"
                class="date"
              />
            </div>
          </div>

          <div class="filter-title">
            <span class="title">Período de Inclusão na Fila</span>
          </div>

          <div class="selectinput">
            <div class="period">
              <RgInputDate
                v-model="form.initialInclusionDate"
                :rules="{ fn: verifyInclusionInitialDate }"
                placeholder="dd/mm/aaaa"
                label="Data Inicial"
                class="date"
              />

              <RgInputDate
                v-model="form.finalInclusionDate"
                placeholder="dd/mm/aaaa"
                label="Data Final"
                class="date"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="mutableHospitalizationQueue.length <= 0"
        slot="menu-top"
        class="top-button"
      >
        <RgNewButton
          large
          title="Inserir na fila"
          class="item"
          @click="registrationQueue"
        />
      </div>

      <div class="menu-top">
        <div v-show="hasHospitalizationQueue" class="top-btn">
          <div v-if="!equalListSize" class="select-btn" @click="selectAllList">
            <IconBox />
          </div>

          <div v-if="equalListSize" class="select-btn" @click="unselectAllList">
            <IconCheckedBox />
          </div>

          <div
            class="select-btn reverse-select"
            title="Inverter seleção"
            @click="reverseListSelection"
          >
            <IconArrowSmall />
          </div>

          <p class="text">
            Linhas Selecionadas:
            {{ selectedRows.length }}
          </p>

          <div
            class="position-btn"
            :disabled="!hasOnlyOneLineSelected"
            :class="{ disable: !hasOnlyOneLineSelected }"
          >
            <input
              v-model="queueAmountAdvance"
              class="field"
              maxlength="3"
              title="Informe o tamanho de intervalo para o passo de subida na fila"
            />
            <button class="arrow-btn" @click="advancePatientInQueue">
              <IconArrowSmall class="svg" />
            </button>

            <input
              v-model="queueAmountBack"
              class="field"
              maxlength="3"
              title="Informe o tamanho de intervalo para o passo de descida na fila"
            />
            <button class="arrow-btn bottom" @click="backPatientInQueue">
              <IconArrowSmall class="svg" />
            </button>

            <input
              v-model="queuePosition"
              class="field large"
              maxlength="3"
              title="Informe o posição deseja para mover o registro na fila"
            />
            <button class="arrow-btn double" @click="movePatientInQueue">
              <IconArrowDouble class="svg" />
            </button>
          </div>
        </div>

        <div class="more-buttons">
          <RgNewButton
            small
            title="Inserir na fila"
            class="insert-button"
            @click="registrationQueue"
          />

          <RgLessButton
            :disabled="!enableButtonForSelectedLines"
            title="Excluir Paciente da Fila"
            @click="openModalConfirmQueueDeletion"
          />

          <RgEditButton
            :class="{ disable: !hasOnlyOneLineSelected }"
            :disabled="!hasOnlyOneLineSelected"
            title="Editar Fila"
            @click="alterQueue"
          />

          <RgShowButton
            :disabled="!hasOnlyOneLineSelected"
            title="Visualizar paciente na fila"
            @click="openModalPatientImageInRow"
          />

          <RgPrinterButton
            :disabled="!hasOnlyOneLineSelected"
            :class="{ disable: !hasOnlyOneLineSelected }"
            title="Imprimir o comprovante de paciente na fila"
            @click="printProofInsertionQueue"
          />

          <RgDropdown
            :disabled="!hasOnlyOneLineSelected"
            :class="{ disable: !hasOnlyOneLineSelected }"
            :item="listSelected[0] || {}"
            :action-options="optionsDropdownMore(listSelected)"
            class="dropdown-more"
          />

          <div
            v-if="
              mutableHospitalizationQueue &&
              mutableHospitalizationQueue.length > 0
            "
            class="dropdown-button box-scroll"
          >
            <div class="button">
              <span class="text unselect">Colunas</span>
              <IconArrow class="svg" />
            </div>

            <div class="dropdown dropdown-absolute">
              <span class="label unselect">Colunas Exibidas</span>

              <label
                v-for="(col, index) in activeHeader"
                :key="col.key"
                :class="{ 'disable unselect': hasOnlyOneActiveHeader }"
                :disabled="hasOnlyOneActiveHeader"
                class="item actives unselect"
              >
                {{ col.name }}
                <input
                  :id="index"
                  :checked="col.active"
                  class="checkbox"
                  type="checkbox"
                  @change="toogleStatus(col, col.key)"
                />
              </label>

              <span class="label unselect">Colunas Não Exibidas</span>

              <label
                v-for="(col, index) in inactiveHeader"
                :key="col.key"
                class="item inactives unselect"
              >
                {{ col.name }}
                <input
                  :id="index"
                  :checked="col.active"
                  class="checkbox"
                  type="checkbox"
                  @change="toogleStatus(col, col.key)"
                />
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="hospitalization-schedule-queue-result">
        <div class="content-table">
          <table class="table">
            <tr>
              <th class="title-table" />
              <th
                v-for="(col, index) in header"
                v-show="col.active"
                :key="index"
                class="title-table"
              >
                {{ col.name }}
              </th>
            </tr>

            <tr
              v-for="(item, index) in mutableHospitalizationQueue"
              :key="index"
              :class="{ selected: selectedRows.includes(item.fil_id) }"
              class="tr row-content"
              @click="getValueRow(item, index)"
            >
              <td class="icon-check">
                <div v-if="selectedRows.includes(item.fil_id)" class="check">
                  <IconCheck />
                </div>
              </td>

              <td
                v-for="(col, index) in header"
                v-show="col.active"
                :key="index"
                :class="{ priority: item[col.key] === item.pfi_nome }"
                :style="{
                  'background-color':
                    item[col.key] === item.pfi_nome
                      ? priorityColor(item)
                      : false,
                }"
                class="result"
              >
                {{ item[col.key] || "-" }}
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div
        v-show="hasHospitalizationQueue"
        slot="menu-bottom"
        class="painel-button"
      >
        <div class="others-btn">
          <RgHourglassButton
            :disabled="!hasOnlyOneLineSelected"
            :class="{ disable: !hasOnlyOneLineSelected }"
            small
            title="Pendência"
            class="bottom"
            @click="pendenciesQueue"
          />

          <RgRegulateButton
            small
            title="Regular"
            class="bottom regulate"
            :disabled="!enableButtonForSelectedLines || disabledRegulateButton"
            @click="regulate"
          />

          <RgUnregulateButton
            small
            title="Desregular"
            class="bottom unregulate"
            :disabled="
              !enableButtonForSelectedLines || disabledUnregulateButton
            "
            @click="unRegulate"
          />

          <RgNewScheduleButton
            :disabled="!hasOnlyOneLineSelected"
            :class="{ disable: !hasOnlyOneLineSelected }"
            small
            title="Internar"
            class="bottom"
            @click="scheduleRegulationQueue"
          />

          <RgExternScheduleButton
            :disabled="!enableButtonForSelectedLines"
            small
            title="Internar externo"
            class="bottom"
            @click="externalSchedule"
          />
        </div>
      </div>
    </RgSearch>

    <ModalConfirmDeletion
      v-bind="propsModalConfirmDeletion"
      @close="closeModalConfirmQueueDeletion"
      @reSearch="fillFilter"
    />

    <ModalPatientImageInRow
      ref="modalPatientImageInRow"
      :show="modalPatientImageInRow"
      :fill-id="Number(selectedRows[0])"
      :modId="hospitalizationModuleId"
      @close="closeModalPatientImageInRow"
    />

    <ModalTypesPrint
      ref="modalTypePrint"
      :show="modalTypesPrint"
      :hospitalization-id="hospitalizationIdToPrint"
      @close="closeModalTypesPrint"
    />

    <ModalTypePrintInsertQueue
      :show="modalPrint"
      :filId="filId || 0"
      @close="closeModalPrint"
    />

    <ModalSimpleHistory
      :show="showModalSimpleHistory"
      :filter="filterHistoryQueue"
      @close="showModalSimpleHistory = false"
    />
  </div>
</template>

<script>
import {
  RgInputDate,
  RgComboboxUnithealth,
  RgNewScheduleButton,
  RgDropdown,
  RgHourglassButton,
  RgRegulateButton,
  RgUnregulateButton,
  RgExternScheduleButton,
  IconCheck,
  RgNewButton,
  RgEditButton,
  RgPrinterButton,
  RgShowButton,
  RgInput,
  IconArrow,
  IconCheckedBox,
  IconArrowSmall,
  IconArrowDouble,
  IconBox,
  RgLessButton,
  RgComboboxSituation,
  RgComboboxPriority,
  RgSuggestSmartPerson,
  RgSuggestCid,
} from "~tokio/primitive";

import RgSuggestTypeBed from "$hospitalization/common/component/suggest/rg-suggest-type-bed/RgSuggestTypeBed";
import RgSuggestState from "$person/common/components/suggest/rg-suggest-state/RgSuggestState";
import RgSuggestProcedures from "$hospitalization/hospitalizations/queue/component/suggest/procedures/RgSuggestProcedures";
import ModalTypePrintInsertQueue from "$hospitalization/hospitalizations/queue/component/modal/modal-type-print-insert-queue/ModalTypePrintInsertQueue";

import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";
import {
  ModalPatientImageInRow,
  ModalConfirmDeletion,
} from "~tokio/primitive/modal";
import { RgSuggestCity } from "$patient/common/components";
import RgSearch from "~tokio/foundation/rg-search/RgSearch";
import { AlertError } from "~tokio/primitive/notification";
import ModalTypesPrint from "$hospitalization/hospitalizations/queue/component/modal/modal-types-print/ModalTypesPrint";
import ModalSimpleHistory from "$queue/common/components/modal/modal-simple-history/ModalSimpleHistory";

import {
  printThermalOneWay,
  printThermalTwoWay,
  printScheduleVoucher,
} from "$appointment/common/utils/printsScheduleVoucherAppointment";

import moment from "moment";

export default {
  name: "HospitalizationQueue",
  components: {
    RgSearch,
    RgInputDate,
    RgSuggestState,
    RgSuggestCity,
    RgComboboxUnithealth,
    RgComboboxPriority,
    RgComboboxSituation,
    RgNewScheduleButton,
    RgHourglassButton,
    RgRegulateButton,
    RgUnregulateButton,
    RgExternScheduleButton,
    IconCheck,
    IconArrow,
    IconArrowSmall,
    IconBox,
    IconArrowDouble,
    IconCheckedBox,
    RgNewButton,
    RgEditButton,
    RgShowButton,
    RgInput,
    RgPrinterButton,
    RgLessButton,
    ModalPatientImageInRow,
    ModalConfirmDeletion,
    RgDropdown,
    ModalTypesPrint,
    ModalTypePrintInsertQueue,
    RgSuggestTypeBed,
    RgSuggestProcedures,
    RgSuggestCid,
    RgSuggestSmartPerson,
    ModalSimpleHistory,
  },

  data() {
    return {
      selectedRows: [],
      listSelected: [],
      mutableHospitalizationQueue: [],
      queueData: {},
      header: this.COLLUMNS,
      form: {
        bedTypeId: null,
        bedType: null,
        unit: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        pes_id: null,
        patientName: null,
        document: null,
        priority: null,
        situation: null,
        est_id: null,
        est_sigla: null,
        mun_id: null,
        mun_nome: null,
        inp_novo_codigo_procedimento: null,
        cid_id: null,
        initialOrderPeriod: null,
        finalOrderPeriod: null,
        initialInclusionDate: null,
        finalInclusionDate: null,
        ocp_codigo: null,
        soc_nome: null,
        suggestPatient: null,
      },
      filId: null,
      modalConfirmQueueDeletion: false,
      modalPatientImageInRow: false,
      disabledRegulateButton: false,
      disabledUnregulateButton: false,
      modalPrint: false,
      modalTypesPrint: false,
      disableNameInput: false,
      disableDocumentInput: false,
      hospitalizationIdToPrint: null,
      queueAmountAdvance: 1,
      queueAmountBack: 1,
      queuePosition: 1,
      showModalSimpleHistory: false,
    };
  },

  computed: {
    propsModalConfirmDeletion() {
      const maxlength = 250;
      const title = "Excluir Paciente(s) da Fila";
      const subtitle = "A operação não poderá ser desfeita";
      const show = this.modalConfirmQueueDeletion;
      const confirm = (reason) => this.deleteQueue(reason);

      return { show, confirm, maxlength, title, subtitle };
    },

    disabledByModal() {
      return (
        this.modalConfirmQueueDeletion ||
        this.modalPatientImageInRow ||
        this.modalTypesPrint ||
        this.modalPrint
      );
    },

    activeHeader() {
      const isHeaderFilled = this.header && this.header.length > 0;
      if (isHeaderFilled) {
        return this.header.filter((item) => item.active);
      }
      return false;
    },

    hospitalizationModuleId() {
      return this.$store.state.Login.route_module_map.hospitalization;
    },

    inactiveHeader() {
      const isHeaderFilled = this.header && this.header.length > 0;
      if (isHeaderFilled) {
        return this.header.filter((item) => !item.active);
      }
      return false;
    },

    hasOnlyOneActiveHeader() {
      return this.activeHeader.length === 1;
    },

    enableButtonForSelectedLines() {
      return this.selectedRows.length > 0;
    },

    hasOnlyOneLineSelected() {
      return this.selectedRows.length === 1;
    },

    hasHospitalizationQueue() {
      return this.mutableHospitalizationQueue.length > 0;
    },

    disableCounty() {
      return !this.form.est_id;
    },

    printOptionsPreference() {
      return this.$store.getters["Login/GET_PREFERENCES"][
        "tViewBuscaFilaInternacao.opcoesImpressao"
      ];
    },

    equalListSize() {
      return (
        this.listSelected.length === this.mutableHospitalizationQueue.length
      );
    },

    hasPermissionScheduleExternally() {
      return this.$Permissions.global.has(
        "internacao.filaInternacao.agendarExternamente",
        this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
      );
    },

    filterHistoryQueue() {
      if (this.listSelected[0] === undefined) {
        return {};
      }
      const { fil_id, pes_id, pes_nome } = this.listSelected[0];
      return { fil_id, pes_id, pes_nome };
    },
  },

  watch: {
    "form.est_id"(pValue, pPrev) {
      if (!pValue || pValue !== pPrev) {
        this.form.mun_id = null;
        this.form.mun_nome = "";
      }
    },

    "form.patientName"(pValue) {
      if (pValue && pValue.length > 0) {
        this.form.document = null;
        this.form.pes_id = null;
        this.disableDocumentInput = true;
      } else {
        this.disableDocumentInput = false;
      }
    },

    "form.document"(pValue) {
      if (pValue && pValue.length > 0) {
        this.disableNameInput = true;
        this.form.patientName = null;
      } else {
        this.disableNameInput = false;
      }
    },

    listSelected(pValue) {
      // console.log("listSelected:", pValue);
      const isRegulate = pValue.every((item) => {
        return item.fnp_ordem_regulada !== "0";
      });

      const isUnregulate = pValue.every((item) => {
        return item.fnp_ordem_regulada === "0";
      });

      if (isRegulate && !isUnregulate) {
        this.disabledRegulateButton = true;
        this.disabledUnregulateButton = false;
      }

      if (!isRegulate && isUnregulate) {
        this.disabledRegulateButton = false;
        this.disabledUnregulateButton = true;
      }

      if (!isRegulate && !isUnregulate) {
        this.disabledRegulateButton = true;
        this.disabledUnregulateButton = true;
      }
    },
  },

  created() {
    this.COLLUMNS = [
      { name: "Ordem", key: "ordem_regulada" },
      { name: "Pendência", key: "pendencia" },
      { name: "Prioridade", key: "pfi_nome" },
      { name: "Paciente", key: "pes_nome" },
      { name: "Telefone", key: "telefone" },
      { name: "Tipo de Leito", key: "nome" },
      { name: "Idade", key: "idade" },
      { name: "Município", key: "mun_nome" },
      { name: "Anexo", key: "anexo" },
      { name: "Data na Fila", key: "fil_data" },
      { name: "Data do Pedido", key: "fil_data_pedido" },
      { name: "Unidade de Saúde Fila", key: "unidade_fila" },
      { name: "Unidade de Saúde Solicitante", key: "uns_solicitante" },
      { name: "Usuário", key: "usu_nome" },
    ];
  },

  mounted() {
    this.$store.commit("Person/Patient/DESTROY_LAST_PERSON_ID_SAVE");
    this.$store.commit(
      "Hospitalization/Queue/UNSELECT_QUEUE_EXTERNAL_HOSPITALIZATION_DATA",
    );

    const isHospitalizationSchedule = this.$route.params
      .isHospitalizationSchedule;
    if (isHospitalizationSchedule) {
      this.viewAppointmentScheduleVoucher(this.$route.params.appointmentId);
    }
    this.mountTableHeader(this.COLLUMNS, 6);
    this.fillFilter();
  },

  beforeDestroy() {
    const validateIfExist = ValidateIfRouteExistInBreadscrumb(
      "/hospitalization/queue",
      this.$route.meta.breadcrumb,
    );

    if (!validateIfExist) {
      this.$store.commit(
        "Hospitalization/Queue/RESET_FILTER_HOSPITALIZATION_QUEUE",
      );
    }
  },

  methods: {
    mountTableHeader(ArrColumns, initialColumns = 1) {
      const header = ArrColumns.map((item, idx) => {
        const active = idx < initialColumns;
        return {
          name: item.name,
          key: item.key,
          active,
        };
      });

      this.header = header;
      return header;
    },

    searchFilter(pData) {
      this.clearRowsSelected();

      return this.$store.dispatch(
        "Hospitalization/Queue/SEARCH_HOSPITALIZATION_QUEUE",
        pData,
      );
    },

    generateFilter() {
      const variables = {
        arrFormData: {
          itl_id_internacoes_tipos_leito: this.form.bedTypeId,
          uns_id: this.form.unit,
          pes_nome: this.form.patientName,
          pes_id: this.form.pes_id,
          pfi_id: this.form.priority,
          situacao: this.form.situation,
          est_id: this.form.est_id,
          mun_id: this.form.mun_id,
          flt_id_internacoes_procedimentos_1: this.form
            .inp_novo_codigo_procedimento,
          flt_id_cid10_1: this.form.cid_id,
          periodoInicial: this.form.initialOrderPeriod,
          periodoInicialFila: this.form.initialInclusionDate,
          periodoFinal: this.form.finalOrderPeriod,
          periodoFinalFila: this.form.finalInclusionDate,
          permissaoRegular: 1,
          permissaoUnidadeSolicitante: 1,
          somenteRegulados: false,
          uns_atual: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        },
      };

      this.$store.commit(
        "Hospitalization/Queue/SET_FILTER_HOSPITALIZATION_QUEUE",
        this.form,
      );

      return variables;
    },

    async getValueRow(pItem) {
      if (this.selectedRows.includes(pItem.fil_id)) {
        this.selectedRows.splice(this.selectedRows.indexOf(pItem.fil_id), 1);
        this.listSelected = this.listSelected.filter(function (el) {
          return el.fil_id !== pItem.fil_id;
        });
        this.filId = Number(pItem.fil_id) || null;
      } else {
        this.selectedRows.push(pItem.fil_id);
        this.listSelected.push(pItem);
        this.filId = Number(pItem.fil_id) || null;
      }
    },

    validateSchedule() {
      const equalsBedType = this.listSelected.every((item) => {
        return (
          this.listSelected[0].flt_id_internacoes_tipos_leito ===
          item.flt_id_internacoes_tipos_leito
        );
      });

      if (!equalsBedType) {
        throw new Error(
          "O tipo de Leito dos pacientes selecionados precisam ser iguais.",
        );
      }

      const isRegulate = this.listSelected.every((item) => {
        return item.fnp_ordem_regulada !== "0";
      });

      if (!isRegulate) {
        throw new Error("Só é possível agendar para pacientes regulados.");
      }

      return equalsBedType && isRegulate;
    },

    selectAllList() {
      this.mutableHospitalizationQueue.forEach((item) => {
        if (!this.selectedRows.includes(item.fil_id)) {
          this.selectedRows.push(item.fil_id);
          this.listSelected.push(item);
        }
      });
    },

    unselectAllList() {
      this.mutableHospitalizationQueue.forEach((item) => {
        if (this.selectedRows.includes(item.fil_id)) {
          this.selectedRows.splice(this.selectedRows.indexOf(item.fil_id), 1);
          this.listSelected.splice(this.listSelected.indexOf(item), 1);
        }
      });
    },

    reverseListSelection() {
      this.mutableHospitalizationQueue.forEach((item) => {
        if (this.selectedRows.includes(item.fil_id)) {
          this.selectedRows.splice(this.selectedRows.indexOf(item.fil_id), 1);
          this.listSelected.splice(this.listSelected.indexOf(item), 1);
        } else {
          this.selectedRows.push(item.fil_id);
          this.listSelected.push(item);
        }
      });
    },

    viewClickPagination(pValue) {
      if (pValue) {
        this.unselectAllList();
      }
    },

    toogleStatus(pColumn, pKey) {
      pColumn.active = !pColumn.active;

      const index = this.getHeaderIndex(pKey);
      const activesLength = this.getActiveColumnsLength();
      const maxReached = activesLength > this.MAX_COLUMNS;
      const middle = activesLength / 2;
      const currentIndex = index + 1;

      if (maxReached) {
        const idx =
          currentIndex > middle
            ? this.getFirsActiveColumnIndex()
            : this.getLastActiveColumnIndex();

        this.header[idx].active = false;
      }
    },

    getHeaderIndex(pKey) {
      return this.header.findIndex((item) => item.key === pKey);
    },

    getActiveColumnsLength() {
      return this.getActiveColumnsIndex().length;
    },

    getActiveColumnsIndex() {
      const actives = [];
      this.header.forEach((item, index) => {
        if (item.active) actives.push(index);
      });
      return actives;
    },

    getLastActiveColumnIndex() {
      const activeColumns = this.getActiveColumnsIndex();
      return activeColumns[activeColumns.length - 1];
    },

    getFirsActiveColumnIndex() {
      return this.getActiveColumnsIndex()[0];
    },

    pendenciesQueue() {
      if (this.listSelected && this.listSelected.length > 1) {
        this.$toaster.warning(
          "Para essa operação só é permitida a seleção de um único paciente",
        );
        return;
      }
      this.$store.commit(
        "Hospitalization/Queue/SELECT_QUEUE_EXTERNAL_HOSPITALIZATION_DATA",
        Object.values(this.listSelected),
      );
      this.$router.push({ name: "hospitalization.queue.pendencies" });
    },

    registrationQueue() {
      this.$router.push({
        name: "hospitalization.queue.insert",
        params: { fromRegulation: true },
      });
    },

    alterQueue() {
      if (this.listSelected && this.listSelected.length > 1) {
        this.$toaster.warning(
          "Para essa operação só é permitida a seleção de um único paciente",
        );
        return;
      }
      this.$store.commit(
        "Hospitalization/Queue/SELECT_QUEUE_EXTERNAL_HOSPITALIZATION_DATA",
        Object.values(this.listSelected),
      );
      this.$router.push({ name: "hospitalization.queue.edit-insert" });
    },

    async deleteQueue(reason) {
      try {
        const arrQueue = this.listSelected.map((ele) => {
          return ele.fil_id;
        });

        const variables = {
          arrQueue: arrQueue,
          reason: reason,
        };

        await this.$store.dispatch(
          "Hospitalization/Queue/REMOVE_HOSPITALIZATION_QUEUE",
          variables,
        );

        this.$toaster.success("Paciente(s) removido(s) da fila com sucesso!");
      } catch (pErr) {
        this.$toaster.error(pErr, "Erro ao tentar remover o paciente da fila");
      } finally {
        this.$loader.finish();
      }
    },

    scheduleRegulationQueue() {
      try {
        AlertError("Não implementado");
        // if (this.validateSchedule()) {
        //   this.$store.commit(
        //     "Hospitalization/Queue/SELECT_QUEUE_EXTERNAL_HOSPITALIZATION_DATA",
        //     Object.values(this.listSelected),
        //   );
        //   this.$router.push({
        //     name: "hospitalization.queue.schedule",
        //   });
        //   return;
        // }
        // return;
      } catch (Err) {
        AlertError(Err.message || Err);
      }
    },

    externalSchedule() {
      return AlertError("Não implementado");
      // if (!this.hasPermissionScheduleExternally) {
      //   this.$toaster.warning(
      //     "Usuário sem permissão para agendar externamente.",
      //   );
      //   return;
      // }
      // this.$store.commit(
      //   "Hospitalization/Queue/SELECT_QUEUE_EXTERNAL_HOSPITALIZATION_DATA",
      //   Object.values(this.listSelected),
      // );

      // this.$router.push({
      //   name: "hospitalization.queue.external-schedule-form",
      // });
    },

    priorityColor(pValue) {
      return pValue.pfi_cor.replace("0x", "#");
    },

    selectingBedType(pBedType) {
      const source = pBedType && pBedType.source;
      if (source) {
        this.form.bedTypeId = source.itl_id;
      } else {
        this.form.bedTypeId = null;
      }
    },
    async selectingPatient(pPatient) {
      const source = pPatient.source ? pPatient.source : null;
      const hasPerson = source && source.pes_id > 0;

      if (hasPerson) {
        this.form.pes_id = source.pes_id;
      } else {
        this.form.pes_id = null;
      }
    },

    selectingProcedure(pProcedure) {
      const source = pProcedure.source;
      this.form.inp_novo_codigo_procedimento = source
        ? source.inp_novo_codigo_procedimento
        : null;
    },

    selectingCidCode(pCid) {
      const source = pCid && pCid.source;
      this.form.cid_id = source ? source.cid_id : null;
    },

    selectingState(pValue) {
      if (pValue && pValue.source) {
        this.form.est_id = pValue.source.est_id;
      } else {
        this.form.est_id = null;
      }
    },

    selectingMunicipality(pValue) {
      if (pValue && pValue.source) {
        this.form.mun_id = pValue.source.mun_id;
      } else {
        this.form.mun_id = null;
      }
    },

    async fillFilter() {
      try {
        const existFilterData = this.$store.getters[
          "Hospitalization/Queue/GET_FILTER_HOSPITALIZATION_QUEUE"
        ];

        if (existFilterData) {
          this.$loader.start("Carregando dados da busca de fila");
          await this.$refs.state.forceSelection({
            est_id: existFilterData.est_id,
            est_sigla: existFilterData.est_sigla,
          });

          if (this.$refs.patient && existFilterData.pes_id)
            this.$refs.patient.fillPatientById(existFilterData.pes_id);

          this.form = existFilterData;

          this.clearRowsSelected();
          await this.$refs.rgsearch.performSearch();
          this.$loader.finish();
        }
      } catch (Err) {
        this.$toaster.warning("Erro ao carregar os dados da navegação", Err);
        this.$loader.finish();
      }
    },

    async advancePatientInQueue() {
      try {
        this.$loader.start();
        if (!this.queueAmountAdvance) {
          this.$toaster.warning("Informe a posição na fila.");
          return;
        }

        await this.$store.dispatch(
          "Hospitalization/Queue/ADVANCE_PATIENT_QUEUE",
          {
            intIdFila: this.listSelected[0].fil_id,
            intQtdPosicoes: this.queueAmountAdvance,
          },
        );

        await this.$refs.rgsearch.performSearch();
        this.queueAmountAdvance = 1;
        this.$toaster.success("Operação realizada com sucesso!");
      } catch (Err) {
        this.$toaster.error(Err.message || Err);
      } finally {
        this.$loader.finish();
      }
    },

    async backPatientInQueue() {
      try {
        this.$loader.start();
        if (!this.queueAmountBack) {
          this.$toaster.warning("Informe a posição na fila.");
          return;
        }

        await this.$store.dispatch("Hospitalization/Queue/BACK_PATIENT_QUEUE", {
          intIdFila: this.listSelected[0].fil_id,
          intQtdPosicoes: this.queueAmountBack,
        });

        await this.$refs.rgsearch.performSearch();
        this.queueAmountBack = 1;
        this.$toaster.success("Operação realizada com sucesso!");
      } catch (Err) {
        this.$toaster.error(Err.message || Err);
      } finally {
        this.$loader.finish();
      }
    },

    async movePatientInQueue() {
      try {
        this.$loader.start();
        const isRegulate = this.listSelected.every((item) => {
          return item.fnp_ordem_regulada !== "0";
        });

        if (!isRegulate) {
          throw new Error(
            "Não é possível ordernar um paciente que não esteja regulado.",
          );
        }
        await this.$store.dispatch("Hospitalization/Queue/MOVE_PATIENT_QUEUE", {
          intIdFila: this.selectedRows[0],
          intPosicao: this.queuePosition,
        });
        await this.$refs.rgsearch.performSearch();
        this.queuePosition = 1;
        this.$toaster.success("Operação realizada com sucesso!");
      } catch (Err) {
        this.$toaster.error(Err.message || Err);
      } finally {
        this.$loader.finish();
      }
    },

    async regulate() {
      try {
        this.$loader.start();
        this.hasAnyPendency();

        const arrQueues = this.listSelected.map((ele) => {
          return ele.fil_id;
        });

        await this.$store.dispatch(
          "Hospitalization/Queue/REGULATION_HOSPITALIZATION_QUEUE",
          arrQueues,
        );

        this.clearRowsSelected();
        await this.$refs.rgsearch.performSearch();
        this.$toaster.success("Paciente(s) regulado(s) com sucesso.");
      } catch (Err) {
        this.$toaster.error(Err.message || Err);
      } finally {
        this.$loader.finish();
      }
    },

    async unRegulate() {
      try {
        this.$loader.start();
        const arrQueues = this.listSelected.map((ele) => {
          return ele.fil_id;
        });

        await this.$store.dispatch(
          "Hospitalization/Queue/UNREGULATE_HOSPITALIZATION_QUEUE",
          arrQueues,
        );

        this.clearRowsSelected();
        await this.$refs.rgsearch.performSearch();
        this.$toaster.success("Paciente(s) desregulado(s) com sucesso.");
      } catch (Err) {
        this.$toaster.error(Err.message || Err);
      } finally {
        this.$loader.finish();
      }
    },

    printProofInsertionQueue() {
      this.modalPrint = true;
    },

    optionsDropdownMore(pItem) {
      return [
        {
          label: "Observações do paciente na fila",
          action: this.observationPatient,
          disable: !this.hasOnlyOneLineSelected,
        },
        {
          label: "Histórico do paciente na fila",
          action: this.patientHistoryQueue,
        },
        {
          label: "Histórico de ocupação do paciente",
          action: this.patientOccupationHistory,
        },
      ];
    },

    observationPatient() {
      if (this.filId) {
        const hospitalizationsData = this.listSelected[0];

        this.$router.push({
          name: "hospitalization.queue.patient-observation-queue",
          params: { hospitalizationsData },
        });
      } else {
        this.$toaster.warning(
          "Este paciente não foi cadastrado pela fila de regulação.",
        );
      }
    },

    patientImagesQueue() {
      this.$toaster.warning("Não implementado");
    },

    patientHistoryQueue() {
      this.showModalSimpleHistory = true;
    },

    patientOccupationHistory() {
      this.$toaster.warning("Não implementado");
    },

    openModalConfirmQueueDeletion() {
      this.modalConfirmQueueDeletion = true;
    },

    closeModalConfirmQueueDeletion() {
      this.modalConfirmQueueDeletion = false;
      this.clearRowsSelected();
    },

    openModalPatientImageInRow() {
      this.modalPatientImageInRow = true;
    },

    closeModalPatientImageInRow(hasSavedFiles) {
      this.modalPatientImageInRow = false;
      this.mutableHospitalizationQueue.forEach((item) => {
        if (item.fil_id === this.selectedRows[0]) {
          item.anexo = hasSavedFiles === true ? "SIM" : "NÃO";
        }
      });
      this.clearRowsSelected();
      this.selectedRows = [];
    },

    openModalTypesPrint() {
      this.modalTypesPrint = true;
    },

    closeModalTypesPrint() {
      this.modalTypesPrint = false;
    },

    clearRowsSelected() {
      this.listSelected = [];
      this.selectedRows = [];
    },

    closeModalPrint() {
      this.modalPrint = false;
    },

    cleanForm() {
      this.selectedRows = [];
      this.listSelected = [];
      this.mutableHospitalizationQueue = [];
      this.cbo = null;
      this.form = {
        bedType: null,
        unit: null,
        patientName: null,
        document: null,
        priority: null,
        situation: null,
        est_id: null,
        est_sigla: null,
        mun_id: null,
        mun_nome: null,
        inp_novo_codigo_procedimento: null,
        cid_id: null,
        initialOrderPeriod: null,
        finalOrderPeriod: null,
        initialInclusionDate: null,
        finalInclusionDate: null,
      };
      this.queueAmountAdvance = 1;
      this.queueAmountBack = 1;
      this.queuePosition = 1;
    },

    async viewAppointmentScheduleVoucher(hospitalizationID) {
      this.hospitalizationIdToPrint = hospitalizationID;
      switch (this.printOptionsPreference) {
        case "2": // Impressão Normal
          this.openModalTypesPrint();
          break;
        case "6": // Impressão Normal
          await printScheduleVoucher(hospitalizationID);
          break;
        case "7": // Impressora Térmica 1 via
          await printThermalOneWay(hospitalizationID);
          break;
        case "8": // Impressora Térmica 2 vias
          await printThermalTwoWay(hospitalizationID);
          break;
        default:
          break;
      }
    },

    hasAnyPendency() {
      const hasPendency = (element) =>
        !!element.fil_pendencia && element.fil_pendencia !== "0";

      const isPendency = this.listSelected.find(hasPendency);
      if (isPendency) {
        throw new Error("Pacientes com pendências não podem ser regulados.");
      }
    },

    verifyOrderInitialDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialOrderPeriod, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalOrderPeriod, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }
    },

    verifyInclusionInitialDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialInclusionDate, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalInclusionDate, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }
    },
  },
};
</script>
