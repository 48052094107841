import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query StreetTime {
    SearchStreetTime {
      rows {
        tru_id
        tru_codigo_ab
        tru_descricao
      }
    }
  }
`;

export default async ({ commit, state }) => {
  if (state.streetTime.length > 0) {
    return;
  }
  try {
    const { data } = await GraphQLClient.query({
      query,
      fetchPolicy: "no-cache",
    });
    commit("SET_STREET_TIME", data.SearchStreetTime.rows);
  } catch (err) {
    console.log(err);
    return err.graphQLErrors;
  }
};
