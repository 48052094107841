<template>
  <RgSuggest
    ref="rgSuggest"
    v-model="inputValue"
    :data-id="dataId"
    :label="label"
    :placeholder="placeholder"
    :search-on-focus="searchOnFocus"
    :rules="rules"
    :disabled="disabled"
    min="2"
  >
    <RgSuggestItem
      v-for="(item, idx) in suggestionList"
      :key="idx"
      :data-item="dataItem"
      :idx="idx"
      :item="item"
      class="rg-suggest-hospitalization-procedure"
    >
      <div class="procedure-row-top">
        <span class="procedure-name">
          {{ item.inp_novo_codigo_procedimento }}
        </span>
      </div>
      <div class="procedure-row-info">
        {{ item.inp_novo_nome_procedimento }}
      </div>
    </RgSuggestItem>
  </RgSuggest>
</template>
<script>
import { isEmpty } from "lodash";
import {
  RgSuggest,
  RgSuggestItem,
  RgSuggestMixin,
} from "~tokio/primitive/selection";
import SearchHospitalizationProcedure from "./action/SearchHospitalizationProcedure";
import SearchHospitalizationProcedureCid from "./action/SearchHospitalizationProcedureCid";
export default {
  name: "RgSuggestHospitalizationProcedureCid",
  components: {
    RgSuggest,
    RgSuggestItem,
  },
  mixins: [RgSuggestMixin],
  props: {
    alertEmptyResult: {
      type: Boolean,
      default: false,
    },
    dataItem: {
      type: String,
      default: "",
    },
    unitId: {
      default: null,
    },
    patientGender: {
      type: String,
      default: "",
    },
    healthPlan: {
      type: Number,
      default: null,
    },
    cidCode: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      captionRaw: "inp_novo_codigo_procedimento - inp_novo_nome_procedimento",
    };
  },
  computed: {
    searchOnFocus() {
      return this.inputValue === "" || this.inputValue === null;
    },
  },
  methods: {
    validate() {
      if (this.$refs.rgSuggest) this.$refs.rgSuggest.validate();
    },

    cleanValidate() {
      if (this.$refs.rgSuggest) this.$refs.rgSuggest.cleanValidate();
    },

    doSearch(searchQuery, pLimit = null) {
      const variables = this.defineQuery(searchQuery, pLimit);
      return this.searchProcedure(variables);
    },

    async searchProcedure(variables) {
      let procedures;

      if (this.cidCode) {
        procedures = await SearchHospitalizationProcedureCid({
          variables,
        });
      } else {
        procedures = await SearchHospitalizationProcedure({
          variables,
        });
      }

      if (this.alertEmptyResult && procedures.count === 0) {
        this.$toaster.warning("Nenhum procedimento encontrado");
      }

      return procedures;
    },

    defineQuery(pSearchTerm, pLimit) {
      const variables = {
        inp_novo_nome_procedimento: null,
        inp_novo_codigo_procedimento: null,
        inp_sexo: this.patientGender,
        inp_id_planos_saude: this.healthPlan,
        uns_id: this.unitId || null,
        cid_codigo: this.cidCode || null,
        limit: pLimit,
      };

      const query = pSearchTerm ? pSearchTerm.toString().trim() : null;
      const isSearchByCode = !isNaN(query);

      if (isSearchByCode) {
        variables.inp_novo_codigo_procedimento = query;
      } else {
        variables.inp_novo_nome_procedimento = query;
      }

      return variables;
    },

    forceSelection(pValue) {
      const hasData = !isEmpty(pValue);
      const isValidData =
        hasData && pValue.inp_novo_nome_procedimento && pValue.inp_id > 0;

      if (hasData && isValidData) {
        this.$refs.rgSuggest.itemSelected = true;
        if (
          !this.suggestionList ||
          (this.suggestionList && this.suggestionList.length === 0)
        ) {
          this.suggestionList.push({
            inp_id: pValue.inp_id,
            inp_novo_codigo_procedimento: pValue.inp_novo_codigo_procedimento,
            inp_novo_nome_procedimento: pValue.inp_novo_nome_procedimento,
          });
        }

        this.$refs.rgSuggest.$parent.selectingItemFromSuggestList(pValue);
      }
    },
  },
};
</script>
