<style src="./BaseSurgeryView.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="base-surgery-view-container">
    <section :class="expandListContainerStyle" class="expandable-container">
      <div class="toggle-list">
        <div
          :class="expandListButtonStyle"
          class="toggle-button"
          @click="toggleExpandList"
        >
          <IconLeftArrow
            :class="{ 'rotate-arrow': !toggleExpandListState }"
            class="symbol"
          />
        </div>
      </div>
      <ModuleBox
        v-show="toggleExpandListState"
        :granted="hasPermissionList"
        class="scheduling-list"
        title="Lista de Pedidos"
      >
        <ScheduleList :granted="hasPermissionList" />
      </ModuleBox>
    </section>
    <section class="main-surgery-content">
      <ModuleBox
        v-show="isSurgeryCanceled || isSurgerySuspended"
        :title="surgerySuspendedCancelledTitle"
        class="cancelled-surgery"
      >
        <SurgerySuspendedCancelledInfo :info="canceledSuspendedInfo" />
      </ModuleBox>
      <slot />
    </section>
  </section>
</template>

<script>
import ModuleBox from "~tokio/foundation/modulebox/Modulebox";
import ScheduleList from "$surgery-center/surgery/component/scheduling-list/ScheduleList";
import { IconLeftArrow } from "~tokio/primitive";
import SurgerySuspendedCancelledInfo from "$surgery-center/surgery/component/surgery-suspended-cancelled-info/SurgerySuspendedCancelledInfo";
export default {
  name: "BaseSurgeryView",
  components: {
    ModuleBox,
    ScheduleList,
    IconLeftArrow,
    SurgerySuspendedCancelledInfo,
  },
  data() {
    return {
      toggleExpandListState: true,
    };
  },
  computed: {
    expandListContainerStyle() {
      const expandedStyle = !this.toggleExpandListState
        ? "expandable-container--compressed"
        : "expandable-container--expanded";
      return expandedStyle;
    },
    expandListButtonStyle() {
      const expandedStyle = !this.toggleExpandListState
        ? "toggle-button--compressed"
        : "";
      return expandedStyle;
    },
    hasPermissionList() {
      return this.$Permissions.global.has("centro_cirurgico.cirurgia.exibir");
    },
    hasSurgeryPermission() {
      const ITEM_SELECTED = this.$store.getters[
        "SurgeryCenter/Surgery/getSelectedSurgeryStatus"
      ];
      if (ITEM_SELECTED === 2) {
        return this.$Permissions.global.has(
          "centro_cirurgico.cirurgia.alterarAgendamento",
        );
      } else {
        return this.$Permissions.global.has(
          "centro_cirurgico.cirurgia.cadastrarAgendamento",
        );
      }
    },
    isSurgeryCanceled() {
      return (
        this.selectedItem &&
        this.selectedItem.cci_id_status_controles_cirurgicos === 3
      );
    },
    isSurgerySuspended() {
      return (
        this.selectedItem &&
        this.selectedItem.cci_id_status_controles_cirurgicos === 5
      );
    },
    selectedItem() {
      return this.$store.state.SurgeryCenter.Surgery.selectedItem;
    },
    surgerySuspendedCancelledTitle() {
      return this.isSurgeryCanceled
        ? "Cirurgia Cancelada"
        : "Cirurgia Suspensa";
    },
    canceledSuspendedInfo() {
      if (this.isSurgeryCanceled) {
        return {
          reason: this.selectedItem.canceled
            ? this.selectedItem.canceled.moc_nome
            : "",
          observation: this.selectedItem.canceled
            ? this.selectedItem.canceled.ccc_observacao
            : "",
        };
      } else if (this.isSurgerySuspended) {
        return {
          reason: this.selectedItem.suspended
            ? this.selectedItem.suspended.msu_nome
            : "",
          observation: this.selectedItem.suspended
            ? this.selectedItem.suspended.ccu_observacao
            : "",
        };
      }
      return false;
    },
  },
  methods: {
    toggleExpandList() {
      this.toggleExpandListState = !this.toggleExpandListState;
    },
  },
};
</script>
