<template lang="html">
  <svg
    enable-background="new 0 0 510 510"
    version="1.1"
    viewBox="0 0 510 510"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="history">
      <path
        d="m267.75 12.75c-89.25 0-168.3 48.45-209.1 122.4l-58.65-58.65v165.75h165.75l-71.4-71.4c33.15-63.75 96.9-107.1 173.4-107.1 104.55 0 191.25 86.7 191.25 191.25s-86.7 191.25-191.25 191.25c-84.15 0-153-53.55-181.05-127.5h-53.55c28.05 102 122.4 178.5 234.6 178.5 135.15 0 242.25-109.65 242.25-242.25s-109.65-242.25-242.25-242.25zm-38.25 127.5v130.05l119.85 71.4 20.4-33.15-102-61.2v-107.1h-38.25z"
        enable-background="new"
        opacity=".9"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "History",
};
</script>
