<style
  src="./RgComboboxHospitalizationServiceCharacter.scss"
  lang="scss"
></style>
<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchHospitalizationServiceCharacter from "./action/SearchHospitalizationServiceCharacter";

export default {
  name: "RgComboboxHospitalizationServiceCharacter",
  mixins: [RgComboboxMixin],
  props: {
    label: {
      type: String,
      default: "Caráter de Internação",
    },
    defaultText: {
      type: String,
      default: "Selecione",
    },
    emptyDefaultText: {
      type: String,
      default: "Nenhum Item para Selecionar",
    },
  },
  data: () => {
    return {};
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];
      const data = await SearchHospitalizationServiceCharacter({});
      data.forEach((item) => {
        this.mData.push({
          value: item.ica_id,
          text: item.ica_nome,
        });
      });
    },
    blur() {
      this.$emit("blur");
      this.validate();
    },
  },
};
</script>
