<style src="./RgRadioUserStatus.scss" lang="scss" scoped></style>
<template lang="html">
  <fieldset class="rg-radio-exam-type">
    <div class="container">
      <div
        :class="{ 'active-user': selectedStatus === USER_STATUS.ATIVO }"
        class="radio show-active-user"
        @click="toggleStatus(USER_STATUS.ATIVO)"
      >
        ATIVO
      </div>
      <div
        :class="{ 'unactive-user': selectedStatus === USER_STATUS.INATIVO }"
        class="radio show-unactive-user"
        @click="toggleStatus(USER_STATUS.INATIVO)"
      >
        INATIVO
      </div>
    </div>
  </fieldset>
</template>

<script>
import USER_STATUS from "./GetUserStatus";

export default {
  name: "RgRadioUserStatus",
  props: {
    value: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      selectedStatus: 1,
    };
  },
  computed: {
    USER_STATUS() {
      return { ...USER_STATUS };
    },
  },
  watch: {
    value(pValue) {
      this.selectedStatus = pValue;
    },
  },
  mounted() {
    this.selectedStatus = this.value || Object.values(USER_STATUS);
  },
  methods: {
    toggleStatus(pItem) {
      this.selectedStatus = pItem;
      this.$emit("input", this.selectedStatus);
    },
  },
};
</script>
