<template>
  <Modulebox
    :granted="hasPermission"
    :title="individualOrLot"
    class="appointment-register-schedule-queue"
  >
    <form class="form-container" @submit.stop.prevent="saveForm">
      <RgValidatorForm ref="validator">
        <div class="body">
          <FormBase :title="patientOrPatients" class="content-form">
            <div v-if="mutableListPatient" class="table">
              <div class="table-grid">
                <div class="tr">
                  <p class="th">Pacientes</p>
                  <p class="th">Prontuário Único</p>
                  <p class="th"></p>
                </div>
                <div class="grid-body">
                  <div
                    v-for="(item, index) in mutableListPatient"
                    :key="index"
                    class="tr"
                  >
                    <p class="td">{{ item.pes_nome }}</p>
                    <p class="td">{{ item.pac_prontuario_unico }}</p>
                    <div class="actions">
                      <p
                        class="td"
                        title="Editar Paciente"
                        @click="editPatient(item.pes_id)"
                      >
                        <IconDocumentPerson class="svg icon-document" />
                      </p>

                      <p
                        class="td"
                        title="Histórico do Paciente"
                        @click="historyPatient"
                      >
                        <IconHistory class="svg icon-history" />
                      </p>

                      <p
                        class="td"
                        title="Imprimir Carteirinha"
                        @click="patientPrintCard(item.pes_id)"
                      >
                        <IconPrinter class="svg icon-printer" />
                      </p>

                      <p
                        v-show="
                          mutableListPatient && mutableListPatient.length > 1
                        "
                        class="td"
                        title="Apagar"
                        @click="removePatientToList(index)"
                      >
                        <IconRemove class="svg icon-remove" />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </FormBase>

          <FormBase title="Profissional" class="module-employee">
            <div class="content-grid">
              <div class="row-1 content-grid">
                <RgSuggestCbo
                  ref="cbo"
                  v-model="occupation"
                  :disabled="true"
                  :class="{ disable: true }"
                  placeholder="Informe a ocupação"
                />

                <RgComboboxUnithealth
                  ref="unit"
                  v-model="form.unitHealth"
                  :rules="{ required: true }"
                  :permission="'consulta.filaConsulta.agendar'"
                  label="Unidade de saúde"
                />

                <RgComboboxSector
                  ref="sector"
                  v-model="form.sector"
                  :rules="{ required: true }"
                  :disabled="!form.unitHealth"
                  :class="{ disable: !form.unitHealth }"
                  :unit-health="form.unitHealth"
                  default-text="Selecione"
                  class="inputitem"
                />
              </div>

              <div class="row-2 content-grid">
                <RgComboboxPlaces
                  ref="locale"
                  v-model="form.locale"
                  :disabled="!form.sector"
                  :class="{ disable: !form.sector }"
                  :rules="{ required: true }"
                  :sector-id="form.sector"
                  label="Local de Atendimento"
                />

                <RgComboboxEmployeeSectorOccupation
                  ref="employee"
                  v-model="form.responsibleProfessional"
                  :disabled="!form.sector"
                  :class="{ disable: !form.sector }"
                  :rules="{ required: true }"
                  :sector-id="form.sector"
                  :occupation-id="form.occupationId"
                  label="Profissional"
                />

                <RgInputDate
                  v-model="form.date"
                  :disabled="!form.responsibleProfessional"
                  :class="{ disable: !form.responsibleProfessional }"
                  :limit-date="datesEnable"
                  label="Data"
                  placeholder="DD/MM/AAAA"
                />

                <RgComboboxScale
                  ref="scale"
                  v-model="form.scale"
                  :disabled="!form.date"
                  :class="{ disable: !form.date }"
                  :rules="{ required: true }"
                  :sector-id="form.sector"
                  :occupation-id="form.occupationId"
                  :person-id="form.responsibleProfessional"
                  :unit-id="form.unitHealth"
                  :date="form.date"
                  label="Escala"
                  @change="changeScale"
                />

                <RgInputHour
                  v-if="!blockedAppointmentSchedulingTime"
                  ref="hour"
                  v-model="form.hour"
                  :rules="{ required: !blockedAppointmentSchedulingTime }"
                  placeholder="10:00"
                  label="Hora *"
                />

                <RgComboboxHoursEmployerSchedule
                  v-if="blockedAppointmentSchedulingTime"
                  ref="comboHour"
                  v-model="form.hour"
                  :disabled="!form.date || !form.scale"
                  :class="{ disable: !form.date || !form.scale }"
                  :schedule-scale-id="form.scale"
                  :schedule-history-id="periodsDate.hag_id"
                  :initial-hour="periodsDate.eag_inicio_atendimentos"
                  :appointment-date="getDateSchedule"
                  :rules="{ required: blockedAppointmentSchedulingTime }"
                  label="Horário"
                />
              </div>

              <div class="row-2 content-grid">
                <RgComboboxHealthPlans
                  ref="healthPlan"
                  v-model="form.healthPlan"
                  :health-unit-id="form.unitHealth"
                  :rules="{ required: true }"
                  label="Convênio"
                />

                <RgComboboxAppointmentObjective
                  ref="objective"
                  v-model="form.objective"
                  :rules="{ required: true }"
                  :health-unit-id="form.unitHealth"
                  :health-plans-id="form.healthPlan"
                  :active="1"
                  :disabled="!form.healthPlan"
                  :class="{ disable: !form.healthPlan }"
                  label="Objetivo de Consulta"
                />
              </div>
            </div>

            <!-- <RgTextArea
              ref="textArea"
              v-model="form.observations"
              :maxlength="500"
              label="Observações"
              class="text-area"
              is-optional
            /> -->
          </FormBase>
        </div>
      </RgValidatorForm>
    </form>

    <div slot="footer" class="footer">
      <div class="actions">
        <RgCleanButton
          small
          title="Salvar"
          class="buttons"
          @click="showConfirmFieldClearing"
        />

        <RgCancelButton
          ref="cancelButton"
          medium
          class="buttons"
          @click="cancel"
        />

        <RgSaveButton
          ref="save"
          large
          title="Salvar"
          class="buttons"
          @click="saveForm"
        />
      </div>
    </div>

    <ModalConfirmFieldClearing
      ref="confirmClearing"
      :show="modalConfirmFieldClearing"
      @getYes="confirmCleanForm"
      @getOut="closeConfirmFieldClearing"
    />

    <ModalConfirmDefault
      :show="unavailableHour.show"
      :message="unavailableHour.message"
      title="Horário Indisponível"
      yes-label="Sim"
      no-label="Não"
      @getYes="changeToAvailableHourAndSave"
      @getOut="closeModalUnavailableHour"
    />
  </Modulebox>
</template>

<script>
import {
  RgComboboxUnithealth,
  RgSaveButton,
  RgCleanButton,
  RgSuggestCbo,
  RgValidatorForm,
  RgInputDate,
  RgComboboxSector,
  IconRemove,
  RgComboboxHealthPlans,
  ModalConfirmDefault,
  IconHistory,
  IconDocumentPerson,
  IconPrinter,
  RgCancelButton,
  RgInputHour,
} from "~tokio/primitive";

import { Modulebox } from "~tokio/foundation";

import moment from "moment";
import FormBase from "~tokio/foundation/form-base/FormBase";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";
import ModalConfirmFieldClearing from "~tokio/primitive/modal/modal-confirm-field-clearing/ModalConfirmFieldClearing";

import RgComboboxPlaces from "$appointment/common/component/rg-combobox-places/RgComboboxPlaces";
import RgComboboxAppointmentObjective from "$appointment/common/component/rg-combobox-appointment-objective/RgComboboxAppointmentObjective";
import RgComboboxScale from "$appointment/common/component/rg-combobox-scale/RgComboboxScale";
import RgComboboxEmployeeSectorOccupation from "$appointment/common/component/rg-combobox-employee/RgComboboxEmployeeSectorOccupation";
import RgComboboxHoursEmployerSchedule from "$appointment/common/component/rg-combobox-hours-employer-schedule/RgComboboxHoursEmployerSchedule";

export default {
  name: "HospitalizationRegisterScheduleQueue",
  components: {
    RgComboboxUnithealth,
    RgSaveButton,
    Modulebox,
    FormBase,
    RgCleanButton,
    RgSuggestCbo,
    RgComboboxPlaces,
    RgComboboxHealthPlans,
    RgValidatorForm,
    RgInputDate,
    RgComboboxSector,
    RgComboboxAppointmentObjective,
    RgComboboxScale,
    ModalConfirmFieldClearing,
    RgComboboxEmployeeSectorOccupation,
    RgComboboxHoursEmployerSchedule,
    IconRemove,
    ModalConfirmDefault,
    IconHistory,
    IconDocumentPerson,
    IconPrinter,
    RgCancelButton,
    RgInputHour,
  },

  data() {
    return {
      mutableListPatient: [],
      modalConfirmFieldClearing: false,
      datesEnable: [],
      periodsDate: [],
      getDateSchedule: null,
      getNumberScale: null,
      occupation: null,
      requestProfessional: null,
      activeRow: false,
      personId: null,
      buttonEditPatient: false,
      form: {
        occupationId: null,
        unitHealth: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        sector: null,
        responsibleProfessional: null,
        date: null,
        hour: null,
        scale: null,
        locale: null,
        healthPlan: null,
        objective: null,
        observations: null,
      },
      unavailableHour: {
        show: false,
        message: "",
        nextAvailableHour: "",
      },
      selectedScale: null,
    };
  },

  computed: {
    preference() {
      return this.$store.getters["Login/GET_PREFERENCES"][
        "tViewMarcacaoConsulta.con_bloquear_horario_agendamento"
      ];
    },

    blockedAppointmentSchedulingTime() {
      return this.preference === "1";
    },

    hasPermission() {
      return this.$Permissions.global.has("consulta.filaConsulta");
    },

    hasLot() {
      return this.mutableListPatient && this.mutableListPatient.length >= 1;
    },

    disableEditPatient() {
      if (this.mutableListPatient.length === 1) {
        return false;
      }

      if (this.buttonEditPatient) {
        return false;
      }

      return true;
    },

    individualOrLot() {
      return this.mutableListPatient && this.mutableListPatient.length > 1
        ? "Agendamento em Lote"
        : "Agendamento Individual";
    },

    patientOrPatients() {
      return this.mutableListPatient && this.mutableListPatient.length > 1
        ? "Pacientes"
        : "Paciente";
    },

    manyPatients() {
      return this.mutableListPatient && this.mutableListPatient.length > 1;
    },
  },

  watch: {
    "form.unitHealth"(pValue) {
      if (!pValue) {
        this.form.sector = null;
      }
    },

    "form.sector"(pValue) {
      this.form.responsibleProfessional = null;
      this.form.locale = null;
      this.form.date = null;
      this.form.hour = null;
      this.form.scale = null;
      this.$refs.employee.cleanValidate();
      this.$refs.scale.cleanValidate();
    },

    "form.responsibleProfessional"(pProfessional) {
      this.setDaysPossible();
    },

    "form.scale"(pScale) {
      if (pScale) this.getNumberScale = Number(pScale[0].value);
    },

    "form.date"(pDate) {
      if (pDate && pDate.length === 10) {
        this.dateIsValid = true;

        this.getDateSchedule = this.form.date
          ? this.form.date.replaceAll("-", "/")
          : "";

        this.periodsDate.eag_id = Number(
          this.getAttributeDayByDate(pDate, "eag_id"),
        );
        this.periodsDate.hag_id = Number(
          this.getAttributeDayByDate(pDate, "hag_id"),
        );
        this.periodsDate.eag_quantidade_marcadas = this.getAttributeDayByDate(
          this.form.date,
          "eag_quantidade_marcadas",
        );
        this.periodsDate.eag_inicio_atendimentos = this.getAttributeDayByDate(
          this.form.date,
          "eag_inicio_atendimentos",
        );
        this.periodsDate.eag_id_dias_semana = this.getAttributeDayByDate(
          this.form.date,
          "eag_id_dias_semana",
        );
        this.periodsDate.eag_intervalo_consultas = this.getAttributeDayByDate(
          this.form.date,
          "eag_intervalo_consultas",
        );
        this.periodsDate.eag_quantidade_consultas = this.getAttributeDayByDate(
          this.form.date,
          "eag_quantidade_consultas",
        );
      }

      if (!pDate) {
        this.form.hour = false;
      }
    },
  },

  async mounted() {
    const existData = await this.$store.getters[
      "Appointment/Queue/GET_SELECTED_EXTERNAL_APPOINTMENT_RESULT"
    ];

    if (existData) {
      this.mutableListPatient = existData;
      this.selectingCbo();
    }
  },

  beforeDestroy() {
    const validateIfExist = ValidateIfRouteExistInBreadscrumb(
      "/hospitalization/queue/schedule",
      this.$route.meta.breadcrumb,
    );

    if (!validateIfExist) {
      this.$store.commit(
        "Hospitalization/Queue/UNSELECT_QUEUE_EXTERNAL_HOSPITALIZATION_DATA",
      );
    }
  },

  methods: {
    changeScale(scale) {
      if (scale.length > 0) {
        this.selectedScale = scale[0].item;
      }
    },

    editPatient(pesId) {
      this.$store.commit("Person/Patient/SET_PERSON_ID", Number(pesId));
      this.$router.push({
        name: "appointment.schedule.queue.schedule.edit-patient",
      });
    },

    cancel() {
      this.$router.go(-1);
    },

    async saveForm() {
      try {
        if (!(await this.isFormValid())) {
          this.$toaster.warning("Verifique os campos");
          return false;
        }

        this.$loader.start();

        const variables = this.formatData();
        const permissions = {
          blnPermissaoNormal: this.$Permissions.sector.has(
            "consulta.filaConsulta.agendar",
          ),
          blnPermissaoReserva: this.$Permissions.sector.has(
            "consulta.filaConsulta.agendarNaFilaReservaTecnica",
          ),
        };

        const result = await this.$store.dispatch(
          "Hospitalization/Queue/SAVE_REGISTER_SCHEDULE_QUEUE_LOT",
          { variables, permissions },
        );

        this.$toaster.success("Agendamento realizado com sucesso!");

        const params = {
          isAppontmentSchedule: true,
          appointmentId: result.dados[0],
        };

        this.$router.push({
          name: "appointment.schedule.queue",
          params,
        });
        return true;
      } catch (pErr) {
        if (pErr.message === "HorarioIndisponivelException") {
          if (pErr.esus_response && pErr.esus_response.trace) {
            this.unavailableHour.nextAvailableHour = pErr.esus_response.trace;
            this.unavailableHour.show = true;
            this.unavailableHour.message = `O horário não está disponível. Agendar para ${this.unavailableHour.nextAvailableHour}?`;
            return false;
          }
        }
        this.$toaster.error(pErr.message, "Falha ao salvar agendamento.");
      } finally {
        this.$refs.save.actionDone();
        this.$loader.finish();
      }
    },

    formatData() {
      const patients = [];

      this.mutableListPatient.map((item, index) => {
        patients.push({
          blnAlertarPacienteConsultadoHoje: true,
          con_hora: this.form.hour,
          con_id_filas_consultas: Number(item.flc_id),
          con_id_locais_atendimento: Number(this.form.locale),
          con_id_pacientes_consultas: Number(item.pcc_id),
          con_id_planos_saude: Number(this.form.healthPlan),
          con_id_tipos_consultas_unidade: Number(this.form.objective),
          eag_quantidade_marcadas: this.periodsDate.eag_quantidade_marcadas,
          hag_data_consulta: this.form.date.replaceAll("-", "/"),
          hag_hora_inicio_consulta_funcionario: this.form.hour,
          hag_id_dias_semana: Number(this.periodsDate.eag_id_dias_semana),
          hag_id_escalas_agendamentos: Number(this.periodsDate.eag_id),
          set_id: Number(this.form.sector),
          tcu_id_unidades_saude: Number(this.form.unitHealth),
          tipo_agendamento: 0,
          hag_quantidade_consultas: this.selectedScale.eag_quantidade_consultas,
          hag_intervalo_consultas: this.selectedScale.eag_intervalo_consultas,
          hag_id: this.selectedScale.hag_id,
        });
      });

      return patients;
    },

    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    async setDaysPossible() {
      try {
        if (!Number(this.form.responsibleProfessional)) {
          return;
        }

        this.$loader.start("Carregando datas...");
        const pData = {
          intIdSetor: this.form.sector,
          intIdOcupacao: this.form.occupationId,
          intIdPessoa: Number(this.form.responsibleProfessional),
          intIdUnidadeSaude: this.form.unitHealth,
          strDataInicial: moment().startOf("year").format("MM/DD/YYYY"),
          strDataFinal: moment()
            .startOf("year")
            .add("years", 1)
            .format("MM/DD/YYYY"),
        };

        this.periodsDate = await this.$store.dispatch(
          "Hospitalization/Queue/GET_DAY_ON_WEEK_FOR_PERIODS",
          pData,
        );

        this.periodsDate = this.periodsDate.map((item) => {
          return {
            eag_inicio_atendimentos: item.eag_inicio_atendimentos.substr(0, 5),
            data: item.data,
            eag_id: item.eag_id,
            hag_id: item.hag_id,
            eag_id_dias_semana: item.eag_id_dias_semana,
            eag_quantidade_consultas: item.eag_quantidade_consultas,
            eag_intervalo_consultas: item.eag_intervalo_consultas,
            eag_quantidade_marcadas: item.eag_quantidade_marcadas,
          };
        });

        this.datesEnable = this.periodsDate.map((item) =>
          moment(item.data, "DD-MM-YYYY").format("YYYY-MM-DD"),
        );
      } catch (Err) {
        this.periodsDate = [];
        this.datesEnable = [];
        this.$toaster.error(Err, "Erro na busca pelas datas.");
      } finally {
        this.$loader.finish(2000);
      }
    },

    getAttributeDayByDate(pDate, pAtrr) {
      pDate = pDate.replaceAll("-", "/");
      if (this.periodsDate.length <= 0) return;
      return this.periodsDate.find((item) => item.data === pDate)[pAtrr];
    },

    async patientPrintCard(pesId) {
      try {
        const hasPersonId = pesId;

        if (hasPersonId) {
          const html = await this.$store.dispatch(
            "Person/Patient/PRINT_PATIENT_CARD",
            { pes_id: Number(pesId) },
          );

          this.$utils.print.printHtml(html);
        }
      } catch (Err) {
        this.$toaster.error(
          "Erro ao gerar a impressão da carteirinha do paciente",
        );
      }
    },

    historyPatient() {
      this.$toaster.warning("Não implementado");
    },

    removePatientToList(index) {
      if (this.mutableListPatient && this.mutableListPatient.length > 1) {
        this.mutableListPatient.splice(index, 1);
      }
    },

    selectLine(pValue, pIndex) {
      this.activeRow = pIndex;
      this.personId = pValue.pes_id;

      this.buttonEditPatient = true;
    },

    selectingCbo() {
      this.occupation = this.mutableListPatient[0].nome;
      this.form.occupationId = Number(this.mutableListPatient[0].ocp_id);
    },

    async changeToAvailableHourAndSave() {
      try {
        this.form.hour = this.unavailableHour.nextAvailableHour;
      } catch (err) {}
      this.closeModalUnavailableHour();
    },

    closeModalUnavailableHour() {
      this.unavailableHour.show = false;
      this.unavailableHour.message = "";
      this.unavailableHour.nextAvailableHour = "";
    },

    showConfirmFieldClearing() {
      this.modalConfirmFieldClearing = true;
    },

    confirmCleanForm() {
      this.clearForm();
      this.modalConfirmFieldClearing = false;
    },

    closeConfirmFieldClearing() {
      this.modalConfirmFieldClearing = false;
    },

    clearForm() {
      this.requestProfessional = null;
      this.activeRow = false;
      this.form.unitHealth = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
      this.form.sector = null;
      this.form.responsibleProfessionalId = null;
      this.form.date = null;
      this.form.hour = null;
      this.form.locale = null;
      this.form.healthPlan = null;
      this.form.objective = null;
      this.form.observations = null;

      // this.$refs.textArea.resetCharacter();

      this.$refs.unit.cleanValidate();
      this.$refs.sector.cleanValidate();
      this.$refs.locale.cleanValidate();
      this.$refs.employee.cleanValidate();
      if (this.$refs.hour) this.$refs.hour.cleanValidate();
      if (this.$refs.comboHour) this.$refs.comboHour.cleanValidate();
      this.$refs.healthPlan.cleanValidate();
      this.$refs.objective.cleanValidate();
      this.$refs.scale.cleanValidate();
    },
  },
};
</script>
