<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchRgProfessionalSectorOccupationScaleActive from "./action/SearchRgProfessionalSectorOccupationScaleActive";
export default {
  name: "RgComboboxProfessionalSectorOccupationScaleActive",
  mixins: [RgComboboxMixin],
  props: {
    sectorId: {
      required: true,
    },
    ocuppationId: {
      required: false,
    },
  },
  data: () => {
    return {};
  },
  watch: {
    sectorId() {
      this.fillData();
    },
    ocuppationId() {
      this.fillData();
    },
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];
      if (!this.sectorId) {
        return;
      }
      const data = await SearchRgProfessionalSectorOccupationScaleActive({
        intIdSetor: this.sectorId,
        intIdOcupacao: this.ocuppationId || 0,
        blnConcatOcupacao: false,
      });
      data.forEach((item) => {
        this.mData.push({
          value: item.data,
          text: item.label.split("-")[0],
          ocp_id: Number(item.ocp_id),
        });
      });
    },
  },
};
</script>
