<style src="./PersonFilter.scss" lang="scss" scoped></style>
<template lang="html">
  <div class="sector-filter">
    <FilterBaseContainer :filter-key="filterKey" @close="closeFilter()">
      <RgInputDebounce
        v-model="filterValue"
        label="Busca por pessoa, número da cirurgia ou Prontuário"
        placeholder="Nome do paciente, Médico, nº da cirurgia ou nº do Prontuário"
        @debounced="changeFilter('main')"
      />
    </FilterBaseContainer>
  </div>
</template>

<script>
import { RgInputDebounce } from "~tokio/primitive";
import FilterBaseContainer from "../filter-base-container/FilterBaseContainer";
import FilterBaseContainerMixin from "../filter-base-container/FilterBaseContainerMixin";

export default {
  name: "PersonFilter",
  components: {
    RgInputDebounce,
    FilterBaseContainer,
  },
  mixins: [FilterBaseContainerMixin],
  mounted() {
    this.filterKey = "main";
    this.filterValue = this.$store.getters["SurgeryCenter/Surgery/filterValue"](
      this.filterKey,
    );
  },
};
</script>
