<template lang="html">
  <svg
    enable-background="new 0 0 512 512"
    version="1.1"
    viewBox="0 0 512 512"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="#c0cadd">
      <g fill="#c0cadd">
        <path
          d="m491.73 112.97-232.47-112.23c-2.061-0.994-4.461-0.994-6.521 0l-232.47 112.23c-2.592 1.251-4.239 3.876-4.239 6.754v272.55c0 2.878 1.647 5.503 4.239 6.754l232.47 112.23c1.03 0.497 2.146 0.746 3.261 0.746s2.23-0.249 3.261-0.746l232.47-112.23c2.592-1.251 4.239-3.876 4.239-6.754v-272.55c0-2.88-1.648-5.503-4.239-6.755zm-235.73-97.143 215.22 103.9-62.387 30.118c-0.395-0.301-0.812-0.579-1.27-0.8l-213.76-103.19 62.195-30.025zm-79.133 38.505 214.9 103.75-44.015 21.249-214.82-103.7 43.926-21.291zm219.93 117.97v78.546l-41.113 19.848v-78.546l41.113-19.848zm84.169 215.26-217.47 104.98v-255.89l51.873-25.042c3.73-1.801 5.294-6.284 3.493-10.015-1.801-3.729-6.284-5.295-10.015-3.493l-52.851 25.515-20.796-10.04c-3.731-1.803-8.214-0.237-10.015 3.493s-0.237 8.214 3.493 10.015l19.818 9.567v255.89l-217.47-104.98v-255.89l165.6 79.945c1.051 0.508 2.162 0.748 3.255 0.748 2.788 0 5.466-1.562 6.759-4.241 1.801-3.73 0.237-8.214-3.493-10.015l-162.37-78.386 74.505-35.968 225.29 108.76c0.033 0.046 0.07 0.087 0.104 0.132v89.999c0 2.581 1.327 4.98 3.513 6.353 1.214 0.762 2.599 1.147 3.988 1.147 1.112 0 2.227-0.247 3.26-0.746l56.113-27.089c2.592-1.251 4.239-3.875 4.239-6.754v-90.495l69.169-33.392v255.89z"
          fill="#c0cadd"
        />
      </g>
      <g fill="#c0cadd">
        <path
          d="m92.926 358.48-34.115-16.469c-3.732-1.803-8.214-0.237-10.015 3.493s-0.237 8.214 3.493 10.015l34.115 16.469c1.051 0.508 2.162 0.748 3.255 0.748 2.788 0 5.466-1.562 6.759-4.241 1.802-3.731 0.238-8.213-3.492-10.015z"
          fill="#c0cadd"
        />
      </g>
      <g fill="#c0cadd">
        <path
          d="m124.32 338.04-65.465-31.604c-3.731-1.801-8.214-0.237-10.015 3.494-1.8 3.73-0.236 8.214 3.494 10.015l65.465 31.604c1.051 0.507 2.162 0.748 3.255 0.748 2.788 0 5.466-1.562 6.759-4.241 1.801-3.732 0.237-8.216-3.493-10.016z"
          fill="#c0cadd"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "Product",
};
</script>
