import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation DeleteTelephone($phoneId: Int!) {
    DeleteTelephone(tel_id: $phoneId) {
      tel_id
    }
  }
`;

export default async ({ commit, state }, { phoneId, contactIndex }) => {
  const variables = { phoneId };
  try {
    await GraphQLClient.mutate({ mutation, variables });
    commit("REMOVE_TELEPHONE_FROM_LIST", { contactIndex });
    return Promise.resolve({ contactIndex });
  } catch (err) {
    return Promise.reject(err);
  }
};
