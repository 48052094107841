<style src="./PrintReportMaterials.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="print-report">
    <PrintHeader
      :unit-health-info="unitHealthInfo"
      title="Relatório de Materiais por Cirurgia"
    />
    <ul class="report-list">
      <li v-for="(listItem, idx) in fillData" :key="idx" class="list-item">
        <div class="list-header">
          <div class="header-surgery-info">
            <div class="list-field surgery-info">
              <span class="key">Cirurgia:</span>
              <span class="value">{{ listItem.cci_numero }}</span>
            </div>
            <div class="list-field prontuario-info">
              <span class="key">Prontuário:</span>
              <span class="value">{{ listItem.pac_prontuario_unico }}</span>
            </div>
            <div class="list-field date-info">
              <span class="key">Data:</span>
              <span class="value">{{ dateBR(listItem.cci_data_inicio) }}</span>
            </div>
          </div>

          <div class="header-patient-info">
            <div class="list-field patient-name">
              <span class="key">Paciente:</span>
              <span class="value">{{ listItem.pes_nome }}</span>
            </div>
            <div class="list-field patient-address">
              <span class="key">Endereço:</span>
              <span class="value"
                >{{ listItem.end_logradouro }}, {{ listItem.end_numero }} -
                {{ listItem.bai_nome }} - {{ listItem.mun_nome }}</span
              >
            </div>
          </div>

          <div class="header-place-info">
            <div class="list-field place-place">
              <span class="key">Sala:</span>
              <span class="value">{{ listItem.lca_nome }}</span>
            </div>
            <div class="list-field place-sector">
              <span class="key">Setor:</span>
              <span class="value">{{ listItem.set_nome }}</span>
            </div>
          </div>
        </div>

        <ul class="list-content">
          <li>
            <ul class="list-content-header">
              <li class="column-number">#</li>
              <li class="column-qt">Qt</li>
              <li class="column-material">Material</li>
            </ul>
          </li>
          <li
            v-for="(material, materialIdx) in listItem.materials"
            :key="materialIdx"
            class="{'highlight-material': material.material_id === filter.material}"
          >
            <ul class="list-content-values">
              <li class="column-number">{{ materialIdx + 1 }}</li>
              <li class="column-qt">{{ material.quantity }}</li>
              <li class="column-material">{{ material.material }}</li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </section>
</template>

<script>
import LocalStorage from "~common/local-storage/LocalStorage";
import ReportSearchModel from "$surgery-center/reports/model/ReportSearchModel";
import UnitHealthInfoModel from "$surgery-center/unit-health/model/UnitHealthInfo";
import PrintHeader from "$surgery-center/reports/component/print-header/PrintHeader";

export default {
  name: "PrintReportMaterial",
  components: {
    PrintHeader,
  },
  data() {
    return {
      filter: {},
      fillData: [],
      unitHealthInfo: {
        name: "",
        city: "",
        state: "",
        address: "",
        number: "",
      },
      headerInfo: {
        name: "",
        city: "",
        state: "",
        address: "",
        number: "",
      },
    };
  },
  mounted() {
    const auxData = this.getParams(window.location.search);
    this.incomingData = JSON.parse(auxData);
    this.$nextTick(() => {
      this.performSearch(this.incomingData);
    });
  },
  methods: {
    dateBR(pDate) {
      return this.$utils.date.BrazilianDateFormat(Date(pDate));
    },
    performSearch(pFilters) {
      ReportSearchModel.getReportMaterial(pFilters)
        .then((pRes) => this.fillReportData(pRes))
        .then(() => this.getUnitHealthData())
        .then(() => {
          setTimeout(() => {
            window.print();
          }, 500);
        })
        .catch((pErr) => {
          // reject(new Error('Nenhum Mapa Cirurgico Encontrado'))
        });
    },
    getParams(pParams) {
      const AUX = decodeURIComponent(pParams);
      const firstSplit = AUX.toString().split(/=|&/gi);
      const paramsSplited = firstSplit[1].split(",");
      return paramsSplited;
    },
    fillReportData(pData) {
      this.fillData = pData;
      return Promise.resolve();
    },
    getUnitHealthData() {
      const unitHealthId = LocalStorage.getObject("current-unit-health");
      return new Promise((resolve, reject) => {
        UnitHealthInfoModel.showHeaderInfo(unitHealthId).then((res) => {
          this.unitHealthInfo.name = res.data.uns_nome;
          this.unitHealthInfo.city = res.data.mun_nome;
          this.unitHealthInfo.state = res.data.est_sigla;
          this.unitHealthInfo.address = res.data.ebd_logradouro;
          this.unitHealthInfo.number = res.data.end_numero;
          resolve();
        });
      });
    },
  },
};
</script>
