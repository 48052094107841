<template lang="html">
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M50 26.7821C49.7487 27.4986 49.2386 27.7626 48.4847 27.755C45.6064 27.7324 42.7356 27.7475 39.8573 27.7475C39.7507 27.7475 39.6365 27.7475 39.5222 27.7475C39.5222 27.8681 39.6136 27.8983 39.6745 27.9587C40.6873 28.9693 41.7077 29.9724 42.7128 30.9907C43.2991 31.579 43.261 32.3935 42.6519 32.8536C42.2102 33.1855 41.6315 33.193 41.1899 32.8536C41.0833 32.7782 40.9919 32.6801 40.9005 32.5896C39.7583 31.4507 38.6161 30.3194 37.4739 29.1805C35.852 27.5665 35.8596 25.4018 37.4891 23.7953C38.6542 22.6489 39.8192 21.5024 40.9766 20.356C41.3421 19.994 41.7533 19.8281 42.2711 19.9789C43.0707 20.2203 43.3981 21.148 42.9032 21.8192C42.8118 21.9399 42.7052 22.0455 42.5986 22.1511C41.5477 23.1919 40.4893 24.2328 39.4309 25.2736C39.4233 25.2811 39.4309 25.2887 39.4156 25.3339C39.5222 25.3339 39.6289 25.3339 39.7355 25.3339C42.6519 25.3339 45.5683 25.349 48.4847 25.3264C49.2386 25.3188 49.7487 25.5828 50 26.2993C50 26.4577 50 26.6237 50 26.7821Z"
      fill="white"
    />
    <path
      d="M10.4799 27.7474C10.3733 27.7474 10.2591 27.7474 10.1525 27.7474C7.21321 27.7474 4.28157 27.7474 1.34231 27.7474C0.801665 27.7474 0.375244 27.5513 0.12396 27.061C-0.249159 26.3143 0.261024 25.4319 1.10625 25.3489C1.22809 25.3338 1.34992 25.3414 1.47176 25.3414C4.41102 25.3414 7.34266 25.3414 10.2819 25.3414C10.3809 25.3414 10.4799 25.3414 10.6627 25.3414C10.5332 25.2207 10.4494 25.1453 10.3733 25.0774C9.35293 24.0743 8.33257 23.0712 7.3122 22.068C6.77917 21.5401 6.72587 20.8839 7.15229 20.371C7.56349 19.8808 8.27165 19.7903 8.78945 20.1598C8.89605 20.2353 8.99504 20.3258 9.08642 20.4163C10.2438 21.5552 11.4013 22.694 12.5587 23.8329C14.1426 25.4017 14.1502 27.5815 12.5739 29.1578C11.4013 30.3268 10.2286 31.4884 9.05596 32.6574C8.69046 33.027 8.26403 33.208 7.73862 33.0572C6.95431 32.8234 6.61927 31.9032 7.09138 31.2395C7.17514 31.1188 7.28174 31.0057 7.39596 30.9001C8.43917 29.8743 9.48238 28.8561 10.5256 27.8304C10.5027 27.7926 10.4951 27.77 10.4799 27.7474Z"
      fill="white"
    />
    <path
      d="M25.3283 8.42401C21.0413 8.42401 17.5537 11.8784 17.5537 16.1247C17.5537 18.327 18.4903 20.3182 19.998 21.7211C20.0285 21.7512 20.059 21.7814 20.0894 21.804C21.4753 23.0561 23.3104 23.8254 25.3359 23.8254C27.3538 23.8254 29.1965 23.0561 30.5824 21.804C30.6129 21.7814 30.6433 21.7512 30.6738 21.7211C32.1739 20.3182 33.1181 18.327 33.1181 16.1247C33.0952 11.8784 29.6077 8.42401 25.3283 8.42401ZM25.3283 22.151C23.9501 22.151 22.6784 21.6909 21.6504 20.9216C22.3967 19.6168 23.7825 18.8022 25.3283 18.8022C26.8741 18.8022 28.2599 19.6168 29.0062 20.9216C27.9782 21.6985 26.7066 22.151 25.3283 22.151ZM23.9729 15.7928V15.2498C23.9729 14.5106 24.5821 13.9073 25.3283 13.9073C26.0745 13.9073 26.6837 14.5106 26.6837 15.2498V15.7928C26.6837 16.532 26.0745 17.1354 25.3283 17.1354C24.5821 17.1278 23.9729 16.532 23.9729 15.7928ZM30.2169 19.6997C29.5925 18.7871 28.7321 18.0706 27.7269 17.6332C28.1229 17.1203 28.3589 16.4867 28.3589 15.7928V15.2498C28.3589 13.5905 26.9959 12.2329 25.3131 12.2329C23.6378 12.2329 22.2672 13.5829 22.2672 15.2498V15.7928C22.2672 16.4867 22.5033 17.1203 22.8992 17.6332C21.8941 18.0706 21.0336 18.7871 20.4092 19.6997C19.663 18.6966 19.2213 17.4672 19.2213 16.1247C19.2213 12.7985 21.9474 10.0984 25.3055 10.0984C28.6635 10.0984 31.3896 12.7985 31.3896 16.1247C31.4124 17.4597 30.9631 18.6966 30.2169 19.6997Z"
      fill="white"
    />
    <path
      d="M29.8895 40.5692H25.3283C24.8638 40.5692 24.4831 40.9463 24.4831 41.4064C24.4831 41.8665 24.8638 42.2436 25.3283 42.2436H29.8895C30.354 42.2436 30.7347 41.8665 30.7347 41.4064C30.7347 40.9388 30.354 40.5692 29.8895 40.5692Z"
      fill="white"
    />
    <path
      d="M29.8895 35.8779H17.8887C17.4243 35.8779 17.0435 36.255 17.0435 36.7151C17.0435 37.1752 17.4243 37.5523 17.8887 37.5523H29.8895C30.354 37.5523 30.7347 37.1752 30.7347 36.7151C30.7347 36.255 30.354 35.8779 29.8895 35.8779Z"
      fill="white"
    />
    <path
      d="M29.8895 31.1942H17.8887C17.4243 31.1942 17.0435 31.5713 17.0435 32.0314C17.0435 32.4915 17.4243 32.8686 17.8887 32.8686H29.8895C30.354 32.8686 30.7347 32.4915 30.7347 32.0314C30.7347 31.5713 30.354 31.1942 29.8895 31.1942Z"
      fill="white"
    />
    <path
      d="M29.8895 26.5029H17.8887C17.4243 26.5029 17.0435 26.88 17.0435 27.3401C17.0435 27.8001 17.4243 28.1773 17.8887 28.1773H29.8895C30.354 28.1773 30.7347 27.8001 30.7347 27.3401C30.7347 26.88 30.354 26.5029 29.8895 26.5029Z"
      fill="white"
    />
    <path
      d="M41.2964 38.0804V44.25C41.2964 44.7101 40.9157 45.0872 40.4512 45.0872H9.68793C9.22344 45.0872 8.84271 44.7101 8.84271 44.25V36.5871C8.84271 36.127 8.46197 35.7499 7.99748 35.7499C7.53299 35.7499 7.15225 36.127 7.15225 36.5871V44.25C7.15225 45.6378 8.28684 46.7616 9.68793 46.7616H40.4512C41.8523 46.7616 42.9868 45.6378 42.9868 44.25V38.0804V36.5871C42.9868 36.127 42.6061 35.7499 42.1416 35.7499C41.6771 35.7499 41.2964 36.127 41.2964 36.5871V38.0804Z"
      fill="white"
    />
    <path
      d="M40.4515 3.90625H9.68822C8.29474 3.90625 7.16016 5.03005 7.16016 6.41783V16.3586C7.16016 16.8187 7.54089 17.1958 8.00538 17.1958C8.46988 17.1958 8.85061 16.8187 8.85061 16.3586V6.41783C8.85061 5.95775 9.23135 5.58064 9.69584 5.58064H40.4591C40.9236 5.58064 41.3043 5.95775 41.3043 6.41783V15.5214V16.3586C41.3043 16.8187 41.685 17.1958 42.1495 17.1958C42.614 17.1958 42.9948 16.8187 42.9948 16.3586V15.5214V6.41783C42.9871 5.03005 41.8526 3.90625 40.4515 3.90625Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: "UnifiyPatient",
};
</script>
