<style src="./ImportTabelaUnificada.scss" lang="scss" scoped></style>
<template>
  <section class="import-tabela-unificada">
    <ModuleBox class="module-box" title="Importar Arquivo Tabela Unificada">
      <RgForm
        action-label-wait="Enviando..."
        action-label="Enviar"
        class="import-tabela-unificada-box"
        @submit="submitFile"
      >
        <label>
          <span class="send-file">Escolher Arquivo</span>

          <input
            id="tabelaunificada-fileupload"
            type="file"
            accept=".zip"
            @change="changeFile"
          />

          <div class="fileinfo">
            <div class="fileinfo-item">
              <span>Nome do Arquivo:</span> {{ fileInfo.fileName }}
            </div>
            <div class="fileinfo-item">
              <span>Tamanho do Arquivo:</span> {{ fileInfo.fileSize }}
            </div>
          </div>
        </label>
      </RgForm>
    </ModuleBox>
  </section>
</template>
<script>
import ModuleBox from "~tokio/foundation/modulebox/Modulebox";
import RgForm from "~tokio/foundation/form/RgForm";
import SendFile from "./actions/SendFile";
export default {
  name: "ImportTabelaUnificada",
  components: {
    ModuleBox,
    RgForm,
  },
  data: () => {
    return {
      fileInfo: {
        fileName: "",
        fileSize: "",
      },
      file: null,
    };
  },
  methods: {
    changeFile() {
      const file = this.grabFileFromDOM();
      this.fileInfo.fileName = file.name;
      this.fileInfo.fileSize = `${Math.round(file.size / 1024)} KB`;
      this.file = file;
    },
    grabFileFromDOM() {
      return document.getElementById("tabelaunificada-fileupload").files[0];
    },
    submitFile(pErrors, pDone) {
      if (!this.file) {
        this.$toaster.error("Nenhum arquivo selecionado");
        pDone();
      }
      this.sendFile(this.file)
        .then(() => {
          this.$toaster.success("Arquivo enviado com sucesso");
          pDone();
        })
        .catch((pErr) => {
          this.$toaster.error("Falha ao enviar o arquivo, tente novamente!");
          pDone();
        });
    },
    sendFile(pFile) {
      return SendFile(pFile);
    },
  },
};
</script>
