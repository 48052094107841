<script>
import { RgRemoteComboboxMixin } from "~tokio/primitive";
import SearchClassification from "./action/SearchClassification";

export default {
  name: "RgComboboxClassification",
  mixins: [RgRemoteComboboxMixin],
  props: {
    label: {
      default: "Classificação",
    },
    service: {
      default: null,
    },
    procedure: {
      default: null,
    },
  },
  data() {
    return {};
  },
  watch: {
    service: function (pNewvalue) {
      this.getDataFromRemote();
    },
    procedure: function (pNewvalue) {
      this.getDataFromRemote();
    },
  },
  methods: {
    doSearch() {
      if (this.service && this.procedure) {
        return SearchClassification({
          serviceCod: this.service,
          procedureCod: this.procedure,
        });
      }
      return Promise.resolve([]);
    },
    formatItem(item) {
      return {
        value: item.CO_CLASSIFICACAO,
        text: `${item.CO_CLASSIFICACAO} - ${item.NO_CLASSIFICACAO}`,
      };
    },
  },
};
</script>
