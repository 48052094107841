<style src="./RgComboboxPrinters.scss" lang="scss"></style>
<script>
import { RgComboboxMixin } from "~tokio/primitive";
export default {
  name: "RgComboboxPrinters",
  mixins: [RgComboboxMixin],
  props: {
    label: {
      type: String,
      default: "Selecione a Impressora",
    },

    printers: {
      type: Array,
      default: () => [],
    },
  },

  data: () => {
    return {
      mData: [],
    };
  },
  watch: {
    printers(pValue) {
      this.mData = pValue;
    },
  },
};
</script>
