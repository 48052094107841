<style src="./MenuSearch.scss" lang="scss" scoped></style>
<template>
  <section
    :class="{ 'rg-unit-health-search__focus': focusContainer }"
    class="menu-search"
  >
    <form class="rg-unit-health-search--unit-form">
      <div class="rg-unit-health-search--search">
        <IconSearch class="rg-unit-health-search--icon-search" />
        <input
          ref="inputSearch"
          v-model="searchText"
          :data-id="dataId"
          type="searchText"
          class="rg-unit-health-search--search-input"
          placeholder="Busque aqui..."
          autofocus
          @focus="changeFocus(true)"
          @blur="changeFocus(false)"
        />
      </div>
    </form>
  </section>
</template>

<script>
import GetMenuSearchFuse from "../menu-map/GetMenuSearchFuse";
import { IconSearch } from "~tokio/primitive/icon";

export default {
  name: "MenuSearch",
  components: {
    IconSearch,
  },
  props: {
    dataId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      searchText: "",
      focusContainer: false,
    };
  },
  watch: {
    searchText(pValue) {
      let searchResult = GetMenuSearchFuse(pValue);
      if (searchResult.length === 0) {
        searchResult = false;
      }
      this.$emit("fuse-search", searchResult);
    },
  },
  mounted() {
    this.$refs.inputSearch.focus();
  },
  methods: {
    changeFocus(pState) {
      this.focusContainer = pState;
    },
  },
};
</script>
