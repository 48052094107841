<template lang="html">
  <div class="modal-information">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <div class="title">{{ title }}</div>
        <div class="subtitle">{{ subtitle }}</div>
      </div>

      <div slot="body" class="body">
        <slot name="body-content" />
      </div>

      <div slot="footer" class="footer">
        <slot v-if="hasBodySlot" name="footer-content" />
        <span v-else class="message">
          {{ message }}
        </span>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { RgBaseModal } from "~tokio/primitive";

export default {
  name: "ModalInformation",
  components: {
    RgBaseModal,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
  },
  computed: {
    hasBodySlot() {
      return !!this.$slots["body-content"];
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
