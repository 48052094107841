<template lang="html">
  <fieldset class="rg-radio-bed-situation">
    <span class="label"> Situação do Leito </span>
    <div class="container unselect">
      <div
        :class="{
          'active-reservation': selectedStatus.includes(BED_SITUATION.RESERVA),
        }"
        class="radio reservation"
        @click="toggleStatus(BED_SITUATION.RESERVA)"
      >
        Reserva
      </div>
      <div
        :class="{
          'active-occupied': selectedStatus.includes(BED_SITUATION.OCUPADO),
        }"
        class="radio occupied"
        @click="toggleStatus(BED_SITUATION.OCUPADO)"
      >
        Ocupado
      </div>
    </div>
  </fieldset>
</template>

<script>
import BED_SITUATION from "./HospitalizationBedSituation";

export default {
  name: "RgRadioBedSituation",
  props: {
    value: {
      default: null,
    },
  },
  data() {
    return {
      selectedStatus: [],
    };
  },
  computed: {
    BED_SITUATION() {
      return {
        ...BED_SITUATION,
      };
    },
  },
  watch: {
    value(pValue) {
      this.selectedStatus = pValue;
    },
  },
  mounted() {
    this.selectedStatus = this.value || [];
  },
  methods: {
    toggleStatus(pItem) {
      if (this.selectedStatus.includes(pItem)) {
        this.selectedStatus.splice(this.selectedStatus.indexOf(pItem), 1);
      } else {
        this.selectedStatus.push(pItem);
      }
      this.$emit("input", this.selectedStatus);
    },
  },
};
</script>
