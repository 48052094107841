import MenuMap from "./MenuMap.json";
import { PermissionHasByGlobal } from "$access-control/model/PermissionManager";

export default () => {
  const linksExtracted = extractLinks(MenuMap);
  return linksExtracted;
};

let idx = 0;
const extractLinks = (pMenuList) => {
  const result = [];
  for (const item in pMenuList) {
    const moduleName = pMenuList[item].name;
    pMenuList[item].module = moduleName;
    if (
      PermissionHasByGlobal(pMenuList[item].permission) &&
      pMenuList[item].link &&
      pMenuList[item].active !== false
    ) {
      pMenuList[item].id = idx;
      result.push(pMenuList[item]);
      idx++;
    }
    if (pMenuList[item].subModule && pMenuList[item].active !== false) {
      const subModuleLinks = getSubModuleLinks(
        pMenuList[item].subModule,
        pMenuList[item].name,
      );
      if (subModuleLinks.length > 0) result.push(...subModuleLinks);
    }
  }
  return result;
};

const getSubModuleLinks = (pSubModule, moduleName) => {
  const result = [];
  for (const item in pSubModule) {
    pSubModule[item].module = moduleName;
    if (
      PermissionHasByGlobal(pSubModule[item].permission) &&
      pSubModule[item].link &&
      pSubModule[item].link !== false
    ) {
      pSubModule[item].id = idx;
      result.push(pSubModule[item]);
      idx++;
    }
    if (pSubModule[item].operation) {
      const operationLinks = getOperationLinks(
        pSubModule[item].operation,
        moduleName,
      );
      if (operationLinks.length > 0) result.push(...operationLinks);
    }
  }
  return result;
};

const getOperationLinks = (pOperation, moduleName) => {
  const result = [];
  for (const item in pOperation) {
    pOperation[item].module = moduleName;
    if (
      PermissionHasByGlobal(pOperation[item].permission) &&
      pOperation[item].link
    ) {
      pOperation[item].id = idx;
      result.push(pOperation[item]);
      idx++;
    }
  }
  return result;
};
