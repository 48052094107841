<style
  src="./RgContentHospitalizationBillingCivil.scss"
  lang="scss"
  scoped
></style>
<template lang="html">
  <section class="hospitalization-billing-civil">
    <div>
      <RgTitle title="Registro Civil:" />
      <form class="form">
        <RgValidatorForm ref="validator">
          <div class="row">
            <RgInputMasked
              v-model="civilRegister.irc_numero_declaracao"
              :rules="{ required: true }"
              class="col-6"
              label="Nº Declaração:"
              mask="###########"
            />
            <RgInput
              v-model="civilRegister.irc_nome_recem_nato"
              :rules="{ required: true, validateName }"
              class="col-6"
              label="Nome do Recém Nato:"
            />
          </div>
          <div class="row">
            <RgInputDate
              v-model="civilRegister.irc_data_emissao"
              :rules="{ required: true }"
              class="col-4"
              label="Data Emissão:"
              placeholder="00/00/0000"
            />
            <RgInput
              v-model="civilRegister.irc_razao_social_cartorio"
              :rules="{ required: true, validateRegistryName }"
              class="col-6"
              label="Razão Social do Cartório:"
            />
          </div>
          <div class="row">
            <RgInputMasked
              v-model="civilRegister.irc_livro"
              :rules="{ required: true, fn: validateLength.bind(this, 8) }"
              class="col-6"
              label="Livro:"
              mask="########"
            />
            <RgInputMasked
              v-model="civilRegister.irc_folha"
              :rules="{ required: true, fn: validateLength.bind(this, 4) }"
              class="col-2"
              label="Folha:"
              mask="####"
            />
            <RgInputMasked
              v-model="civilRegister.irc_termo"
              :rules="{ required: true, fn: validateLength.bind(this, 8) }"
              class="col-2"
              label="Termo:"
              mask="########"
            />
          </div>
          <div class="row">
            <RgInputMasked
              v-model="civilRegister.irc_matricula_certidao_nascimento"
              :rules="{ required: true, fn: validateLength.bind(this, 32) }"
              class="col-6"
              label="Matrícula:"
              mask="################################"
            />
            <button type="button" class="btn btn-success col-4" @click="add">
              Adicionar Registro Civil
            </button>
          </div>
          <div class="row">
            <RgTitle title="Lista de Procedimentos" text-align="center" />
            <table>
              <tr>
                <th>Nº Declaração:</th>
                <th>Nome do Recém Nato:</th>
                <th>Razão Social do Cartório:</th>
                <th>Livro:</th>
                <th>Folha:</th>
                <th>Termo:</th>
                <th>Matrícula:</th>
                <th>Data da Emissão:</th>
                <th />
              </tr>
              <tr v-for="(item, itemIdx) in civilRegisterList" :key="itemIdx">
                <td>{{ item.irc_numero_declaracao }}</td>
                <td>{{ item.irc_nome_recem_nato }}</td>
                <td>{{ item.irc_razao_social_cartorio }}</td>
                <td>{{ item.irc_livro }}</td>
                <td>{{ item.irc_folha }}</td>
                <td>{{ item.irc_termo }}</td>
                <td>{{ item.irc_matricula_certidao_nascimento }}</td>
                <td>{{ item.irc_data_emissao }}</td>
              </tr>
            </table>
          </div>
          <div class="row">
            <button
              :disabled="!civilRegisterList.length > 0"
              type="button"
              class="btn btn-success col-2"
              @click="confirm"
            >
              Confimar
            </button>
            <button class="col-2 btn btn-default" @click.stop.prevent="close">
              Fechar
            </button>
          </div>
        </RgValidatorForm>
      </form>
    </div>
  </section>
</template>

<script>
import {
  RgValidatorForm,
  RgTitle,
  RgInput,
  RgInputMasked,
  RgInputDate,
} from "~tokio/primitive";
import mask from "vue-the-mask";
import { AlertError } from "../../../../service";

export default {
  name: "RgContentHospitalizationBillingCivil",
  components: {
    RgValidatorForm,
    RgTitle,
    RgInput,
    RgInputMasked,
    RgInputDate,
  },
  directives: {
    mask,
  },
  props: {
    procedure: {
      default: "",
    },
    requiredDocuments: {},
    births: {
      default: 0,
    },
  },
  data() {
    return {
      professionalCaptionRaw: "pes_nome",
      civilRegisterList: [],
      civilRegister: {
        type: "civilRegister",
        irc_numero: "",
        irc_numero_declaracao: "",
        irc_nome_recem_nato: "",
        irc_data_emissao: "",
        irc_razao_social_cartorio: "",
        irc_livro: "",
        irc_folha: "",
        irc_termo: "",
        irc_matricula_certidao_nascimento: "",
      },
    };
  },
  watch: {
    "civilRegister.irc_nome_recem_nato"(pValue) {
      if (!pValue) return;
      this.civilRegister.irc_nome_recem_nato = pValue.toUpperCase();
    },
    "civilRegister.irc_razao_social_cartorio"(pValue) {
      if (!pValue) return;
      this.civilRegister.irc_razao_social_cartorio = pValue.toUpperCase();
    },
  },
  mounted() {
    if (this.procedure.additionalData) {
      this.civilRegisterList = this.procedure.additionalData;
    }
    this.civilRegister.irc_data_emissao = this.$utils.date.BrazilianDateFormat();
  },
  methods: {
    async add(event) {
      if (await this.validateForm()) {
        const item = Object.assign({}, this.civilRegister);
        item.irc_numero = this.getRegisterNumber();
        item.irc_data_emissao = item.irc_data_emissao
          .split("/")
          .reverse()
          .join("-");
        this.civilRegisterList.push(item);
        this.resetForm();
      }
    },
    confirm(event) {
      if (this.civilRegisterList.length < this.births) {
        AlertError("Necessário o registro de " + this.births + " nascimentos.");
        return;
      }
      this.$emit("onFinish", this.civilRegisterList);
    },
    close() {
      this.$emit("close");
    },
    resetForm() {
      this.civilRegister.irc_numero = "";
      this.civilRegister.irc_numero_declaracao = "";
      this.civilRegister.irc_nome_recem_nato = "";
      this.civilRegister.irc_razao_social_cartorio = "";
      this.civilRegister.irc_livro = "";
      this.civilRegister.irc_folha = "";
      this.civilRegister.irc_termo = "";
      this.civilRegister.irc_matricula_certidao_nascimento = "";
    },
    removeLine(idx) {
      this.civilRegisterList.splice(idx, 1);
    },
    editLine(idx) {
      this.civilRegister = this.civilRegisterList[idx];
    },
    async validateForm() {
      const valid = await this.$refs.validator.validate();
      return valid;
    },
    validateLength(length, pValue) {
      if (pValue) {
        if (pValue.length < length) {
          AlertError("O campo deve conter " + length + " caracteres.");
          return false;
        }
        return true;
      }
      return false;
    },
    getRegisterNumber() {
      if (this.civilRegisterList.length > 0) {
        return this.civilRegisterList[0].irc_numero;
      }
      return "";
    },
    validateRegistryName(pValue) {
      if (pValue) {
        if (pValue.length > 20) {
          AlertError("Razão Social deve conter no máximo 20 caracteres");
          return false;
        }
        return true;
      }
      return false;
    },
    validateName(pValue) {
      if (pValue) {
        if (pValue.length > 70) {
          AlertError("Nome deve conter no máximo 70 caracteres");
          return false;
        }
        return true;
      }
      return false;
    },
  },
};
</script>
