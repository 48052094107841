<script>
import Vue from "vue";
export default {
  name: "RgIframe",
  beforeUpdate() {
    this.iApp.children = Object.freeze(this.$slots.default);
  },
  methods: {
    renderChildren() {
      const children = this.$slots.default;
      const el = document.createElement("DIV");
      const iApp = new Vue({
        name: "IApp",
        data: { children: Object.freeze(children) },
        render(h) {
          return h("div", this.children);
        },
      });
      iApp.$mount(el);
      this.iApp = iApp;
    },
  },
  render(h) {
    return h("iframe", {
      on: { load: this.renderChildren },
    });
  },
};
</script>
