export default {
  location_types: [],
  home_situations: [],
  access_types: [],
  building_material: [],
  rural_production: [],
  water_supply: [],
  bathroom_drain: [],
  water_treatment: [],
  trash_destination: [],
  animals_types: [],
  home_types: [],
};
