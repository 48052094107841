import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation(
    $hospitalizationAihProcedures: [HospitalizationAihProceduresInputType!]
  ) {
    CreateHospitalizationAihProcedures(
      hospitalizationAihProcedures: $hospitalizationAihProcedures
    ) {
      item
    }
  }
`;

export default async ({ rootState }, { pAihProcedures }) => {
  const variables = {
    hospitalizationAihProcedures: pAihProcedures,
  };
  try {
    const { data } = await GraphQLClient.mutate({
      mutation,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.CreateHospitalizationAihProcedures;
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors);
  }
};
