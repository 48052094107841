<template lang="html">
  <svg
    id="Layer_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 503.422 503.422"
    style="enable-background: new 0 0 503.422 503.422"
    xml:space="preserve"
  >
    <g>
      <path
        d="M356.647,28.199C304.97-1.879,257.536,0.048,256.356,0.109c-3.957-0.182-92.932-3.393-118.741,43.615
        c-10.7,0.963-33.627,5.571-49.195,26.884c-20.966,28.715-20.003,75.715,2.829,139.672l-0.148,51.139
        c0,59.271,5.823,119.643,48.05,159.744c6.725,6.387,13.971,12.097,21.374,17.469v56.112c0,4.799,3.879,8.678,8.678,8.678
        c4.79,0,8.678-3.879,8.678-8.678v-43.937c0.998,0.668,1.996,1.336,2.994,1.996l8.018,5.398c3.289,2.23,6.5,4.599,9.702,6.968
        c13.893,10.275,28.255,20.897,48.466,20.897c0.043,0,0.078-0.009,0.122-0.009s0.078,0.009,0.121,0.009
        c20.211,0,34.573-10.622,48.466-20.897c3.202-2.369,6.413-4.738,9.702-6.968l8.339-5.597c0.963-0.651,1.944-1.319,2.916-1.97
        v44.11c0,4.799,3.879,8.678,8.678,8.678c4.79,0,8.678-3.879,8.678-8.678v-56.112c7.394-5.372,14.64-11.082,21.374-17.469
        c42.435-40.292,48.284-101.055,48.284-160.725v-32.161c15.273-19.994,24.038-42.8,25.938-67.896
        C433.629,108.201,404.966,56.324,356.647,28.199z M386.387,225.415v35.024c0,55.799-5.198,112.354-42.878,148.124
        c-6.908,6.561-14.614,12.462-22.519,18.076c-0.2,0.113-0.417,0.174-0.599,0.304c-5.45,3.87-11.021,7.593-16.584,11.299
        l-8.322,5.589c-3.48,2.36-6.882,4.877-10.266,7.385c-12.141,8.973-23.604,17.434-38.036,17.486
        c-14.431-0.052-25.895-8.513-38.036-17.486c-3.384-2.508-6.786-5.025-10.266-7.385l-8.444-5.667
        c-13.564-9.051-27.579-18.432-39.337-29.601c-39.346-37.341-42.644-95.527-42.644-147.118l0.156-53.031v-0.009l0.078-26.069
        c0-5.866,1.146-11.55,3.402-16.896c0.876-2.091,2.005-4.018,3.237-5.849c0.365-0.538,0.764-1.033,1.146-1.553
        c0.989-1.284,2.039-2.508,3.185-3.645c0.443-0.443,0.885-0.911,1.362-1.336c1.536-1.371,3.15-2.655,4.894-3.758
        c0.104-0.052,0.182-0.13,0.278-0.191c1.866-1.163,3.87-2.117,5.927-2.951c0.555-0.217,1.128-0.399,1.701-0.599
        c1.579-0.564,3.202-1.015,4.86-1.38c0.642-0.148,1.284-0.295,1.944-0.399c1.97-0.33,3.983-0.547,6.023-0.599
        c0.278,0,0.555-0.052,0.824-0.052c1.77-0.009,3.575,0.226,5.372,0.434c0.781,0.095,1.553,0.069,2.326,0.208
        c2.577,0.434,5.155,1.111,7.706,2.031c13.243,4.79,38.669,10.5,84.662,10.5s71.42-5.71,84.662-10.5
        c20.688-7.463,42.973,1.145,50.775,19.638c2.265,5.346,3.41,11.03,3.41,16.896V225.415z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "GenderMale",
};
</script>
