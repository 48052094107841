<template lang="html">
  <svg
    width="28"
    height="21"
    viewBox="0 0 28 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.875 4.2242V2.38161C15.3955 2.07814 15.75 1.52 15.75 0.874986V0.437514C15.75 0.195686 15.5543 0 15.3125 0H0.437514C0.195686 0 0 0.195686 0 0.437514V0.875027C0 1.52 0.354457 2.07814 0.874986 2.38165V4.2242C0.874986 5.95797 1.61413 7.61738 2.90272 8.77693L4.8172 10.5L2.90272 12.2231C1.61413 13.3826 0.874986 15.042 0.874986 16.7758V18.6183C0.354457 18.9219 0 19.48 0 20.125V20.5625C0 20.8043 0.195686 21 0.437514 21H15.3125C15.5544 21 15.75 20.8043 15.75 20.5625V20.125C15.75 19.48 15.3956 18.9219 14.8751 18.6183V16.7758C14.8751 15.042 14.1359 13.3826 12.8473 12.2231L10.9328 10.5L12.8473 8.77693C14.1359 7.61738 14.875 5.95793 14.875 4.2242ZM11.6767 7.47637L9.03968 9.84974C8.85511 10.0155 8.74999 10.2518 8.74999 10.5C8.74999 10.7482 8.85511 10.9845 9.03968 11.1503L11.6767 13.5236C12.5969 14.3521 13.125 15.5377 13.125 16.7758V18.375H12.031L8.22491 13.3002C8.05998 13.0797 7.68998 13.0797 7.52505 13.3002L3.71897 18.375H2.625V16.7758C2.625 15.5376 3.15308 14.352 4.07338 13.5236L6.71036 11.1502C6.89493 10.9844 7.00005 10.7482 7.00005 10.5C7.00005 10.2517 6.89493 10.0154 6.71036 9.8497L4.07338 7.47633C3.15308 6.64794 2.625 5.46234 2.625 4.2242V2.625H13.125V4.2242C13.125 5.46234 12.5969 6.64794 11.6767 7.47637Z"
      fill="white"
    />
    <path
      d="M10.8908 6.125H4.85932C4.68628 6.125 4.52948 6.22713 4.45942 6.3852C4.38937 6.54373 4.41882 6.7283 4.53546 6.85647L7.58174 9.6669C7.66504 9.74254 7.77016 9.78011 7.87524 9.78011C7.98033 9.78011 8.08545 9.7425 8.16875 9.6669L11.2146 6.85647C11.3312 6.7283 11.3607 6.54373 11.2906 6.3852C11.2206 6.22713 11.0638 6.125 10.8908 6.125Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23 21C25.7614 21 28 18.7614 28 16C28 13.2386 25.7614 11 23 11C20.2386 11 18 13.2386 18 16C18 18.7614 20.2386 21 23 21ZM22.0809 18.5742C22.1621 18.5742 22.2402 18.5397 22.2975 18.4779L25.5 15.0742C25.6795 14.8819 25.6795 14.5695 25.5 14.3769L25.2834 14.1444C25.1042 13.9517 24.8132 13.9517 24.6336 14.1444L22.0809 16.8504L21.001 15.6914C20.8215 15.4988 20.5308 15.4988 20.3512 15.6914L20.1346 15.9239C19.9551 16.1162 19.9551 16.4286 20.1346 16.6212L21.8643 18.4779C21.9216 18.5397 21.9998 18.5742 22.0809 18.5742Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "ResolvePending",
};
</script>
