<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchExamReason from "./action/SearchExamReason";
import SearchAppointmentReason from "./action/SearchAppointmentReason";

export default {
  name: "RgComboboxReason",
  mixins: [RgComboboxMixin],
  props: {
    label: {
      default: "Motivo",
    },
    isAppointment: {
      required: true,
    },
  },
  data: () => {
    return {};
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];

      const searchReason = this.isAppointment
        ? SearchAppointmentReason()
        : SearchExamReason();

      const data = await searchReason;

      data.forEach((item) => {
        this.mData.push({
          value: item.data,
          text: item.label,
        });
      });
    },
    blur() {
      this.$emit("blur");
      this.validate();
    },
  },
};
</script>
