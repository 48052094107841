import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query query($limit: Int) {
    hospitalizationModality(limit: $limit) {
      rows {
        imo_id
        imo_nome
        imo_codigo
      }
    }
  }
`;

export default async (variables, hospitalization = true) => {
  const { data } = await GraphQLClient.query({ query, variables }).catch(
    (err) => {
      console.error(err);
    },
  );

  if (hospitalization) {
    return data.hospitalizationModality.rows.filter(
      (item) => item.imo_codigo !== 1,
    );
  } else {
    return data.hospitalizationModality.rows;
  }
};
