<style
  src="./RgContentHospitalizationBillingWorkAccident.scss"
  lang="scss"
  scoped
></style>
<template lang="html">
  <div>
    <form class="form">
      <RgValidatorForm ref="validator">
        <RgTitle title="Acidente de Trabalho" />
        <div class="row">
          <RgInputCnpj
            v-model="workAccident.iav_cnpj_empresa"
            :rules="{ required: true }"
            class="col-6"
            label="CNPJ da Empresa:"
          />
          <RgComboboxHospitalizationSocialSecurityBond
            v-model="workAccident.iav_id_internacoes_vinculos_previdencia"
            :rules="{ required: true }"
            class="col-6"
            label="Vínculo da previdência:"
          />
        </div>

        <div class="row">
          <RgTypeaheadHospitalizationCnaer
            v-model="workAccident.iav_id_internacoes_cnaer"
            :rules="{ required: true }"
            class="col-6"
            label="Atividade Econômica:"
          />
          <RgSuggestCbo
            v-model="workAccident.iav_codigo_ocupacoes_cbo"
            :rules="{ fn: validateCbo }"
            class="col-6"
            label="CBO:"
          />
        </div>

        <div class="row">
          <button type="button" class="btn btn-success col-2" @click="confirm">
            Confimar
          </button>
          <button class="col-2 btn btn-default" @click.stop.prevent="close">
            Fechar
          </button>
        </div>
      </RgValidatorForm>
    </form>
  </div>
</template>

<script>
import { isEmpty } from "lodash";
import { RgTitle, RgInputCnpj, RgValidatorForm } from "~tokio/primitive";
import RgComboboxHospitalizationSocialSecurityBond from "../../../combobox/rg-combobox-hospitalization-social-security-bond/RgComboboxHospitalizationSocialSecurityBond";
import RgTypeaheadHospitalizationCnaer from "../../../suggest/rg-typeahead-hospitalization-cnaer/RgTypeaheadHospitalizationCnaer";
import { RgSuggestCbo } from "$billing/common";
import { AlertError, ValidateCbo } from "../../../../service";
export default {
  name: "RgContentHospitalizationBillingWorkAccident",
  components: {
    RgTitle,
    RgInputCnpj,
    RgValidatorForm,
    RgComboboxHospitalizationSocialSecurityBond,
    RgTypeaheadHospitalizationCnaer,
    RgSuggestCbo,
  },
  props: {
    procedure: {
      type: Object,
      default: () => {},
    },
    requiredDocuments: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      workAccident: {
        type: "workAccident",
        iav_codigo_ocupacoes_cbo: "",
        iav_id_internacoes_vinculos_previdencia: "",
        iav_id_internacoes_cnaer: "",
        iav_cnpj_empresa: "",
      },
    };
  },
  mounted() {
    if (this.procedure.additionalData[0]) {
      this.workAccident = this.procedure.additionalData[0];
    }
  },
  methods: {
    async confirm(event) {
      if (await this.validateForm()) {
        this.sanitizeFields();
        this.$emit("onFinish", this.workAccident);
      }
    },
    close() {
      this.$emit("close");
    },
    validateCbo(pCbo, pErrors) {
      return isEmpty(pCbo)
        ? AlertError("Campo obrigatório", pErrors)
        : ValidateCbo(
            this.procedure.iap_codigo_procedimentos,
            pCbo,
            this.procedure.iap_competencia,
            pErrors,
          );
    },
    async validateForm() {
      const valid = await this.$refs.validator.validate();
      return valid;
    },
    sanitizeFields() {
      this.workAccident.iav_cnpj_empresa = this.workAccident.iav_cnpj_empresa
        ? this.workAccident.iav_cnpj_empresa.replace(/[^\d]+/g, "")
        : null;
    },
  },
};
</script>
