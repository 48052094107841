<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import ListEmployeesBySectorOccupationLegacy from "./action/ListEmployeesBySectorOccupationLegacy";

export default {
  name: "RgComboboxEmployeeBySectorOccupationLegacy",
  mixins: [RgComboboxMixin],
  props: {
    label: {
      default: "Profissional",
    },

    occupationId: {
      type: Number,
      required: true,
    },

    sectorId: {
      type: Number,
      required: true,
    },

    concatSectorEmployee: {
      type: Boolean,
    },
  },

  watch: {
    occupationId(val) {
      this.fillData();
    },

    sectorId(val) {
      this.fillData();
    },
  },

  mounted() {
    this.fillData();
  },

  methods: {
    async fillData() {
      this.mData = [];

      if (!this.occupationId || !this.sectorId) {
        return;
      }

      const variables = {
        intIdSetor: this.sectorId,
        intIdOcupacao: this.occupationId,
        blnConcatSetorFuncionario: this.concatSectorEmployee,
      };

      const data = await ListEmployeesBySectorOccupationLegacy(variables);

      data.forEach((item) => {
        this.mData.push({
          value: item.data,
          text: item.label,
        });
      });
    },

    blur() {
      this.$emit("blur");
      this.validate();
    },
  },
};
</script>
