import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  return AuthLegacyRequest.post(
    "/internacoes/controller-fila-internacao/buscar",
    variables,
  )
    .then(({ data }) => {
      data = HandleResponse(data);
      console.log(data);
      data.dados = {
        data: data.dados[0].map(replaceReguledOrder),
        total: Number(data.dados[1]),
      };

      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};

const replaceReguledOrder = (pItem) => {
  pItem.ordem_regulada =
    pItem.ordem_regulada === "100000" ? "EM ESPERA" : pItem.ordem_regulada;
  return pItem;
};
