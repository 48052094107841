<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchProceduresLinked from "./action/SearchProceduresLinked";

export default {
  name: "RgComboboxProceduresLinked",
  mixins: [RgComboboxMixin],
  props: {
    submoduleId: {
      required: true,
    },
  },
  data: () => {
    return {};
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];
      const data = await SearchProceduresLinked(this.submoduleId);
      data.forEach((item) => {
        this.mData.push({ value: item.data, text: item.label });
      });
    },
  },
};
</script>
