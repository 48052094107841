import AuthRequest from "~common/request/AuthRequest";

export default (id = false) => {
  if (!id) return Promise.reject(new Error("need to provdie ID"));
  return AuthRequest.get("billing/bulletin/find-bulletin-by-id", { bol_id: id })
    .then((pRes) => {
      return pRes.data;
    })

    .catch((pError) => {
      return Promise.reject(Error(pError));
    });
};
