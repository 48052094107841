<style src="./UnithealthFilter.scss" lang="scss" scoped></style>
<template lang="html">
  <div class="unithealth-filter">
    <FilterBaseContainer @close="closeFilter()">
      <RgInputDebounce
        v-model="filterValue"
        label="Buscar por unidade de saúde"
        placeholder="Definir componente"
        @debounced="changeFilter('unithealth')"
      />
    </FilterBaseContainer>
  </div>
</template>

<script>
import { RgInputDebounce } from "~tokio/primitive";
import FilterBaseContainer from "../filter-base-container/FilterBaseContainer";
import FilterBaseContainerMixin from "../filter-base-container/FilterBaseContainerMixin";

export default {
  name: "UnithealthFilter",
  components: {
    RgInputDebounce,
    FilterBaseContainer,
  },
  mixins: [FilterBaseContainerMixin],
  mounted() {
    this.filterValue = this.$store.getters["SurgeryCenter/Surgery/filterValue"](
      "uns_id",
    );
  },
};
</script>
