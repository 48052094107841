import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  const sendData = {
    arrIdFila: variables,
  };
  return AuthLegacyRequest.post(
    "/internacoes/controller-fila-internacao/cancelar-regulacao-em-lote",
    sendData,
  )
    .then(({ data }) => {
      return data;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
