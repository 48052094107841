import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation($patientStatus: MyEsusPersonStatusInputType!) {
    SaveMyEsusPersonStatus(personStatus: $patientStatus) {
      mes_id
      mes_ddd_celular
      mes_status
    }
  }
`;

export default async ({ commit }, myEsusInfo) => {
  const variables = myEsusInfo;
  const { data } = await GraphQLClient.mutate({ mutation, variables }).catch(
    (err) => {
      throw err;
    },
  );
  commit("SET_MY_ESUS", data.SaveMyEsusPersonStatus);
  return data;
};
