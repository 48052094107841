import BaseModel from "~common/model/BaseModel";

class SurgeryStatusModel extends BaseModel {
  get OPEN() {
    return 1;
  }

  get SCHEDULED() {
    return 2;
  }

  get CANCELD() {
    return 3;
  }

  get COMPLETED() {
    return 4;
  }

  get SUSPENDED() {
    return 5;
  }

  defineEndPointURI() {
    return "surgery-center/surgeries";
  }

  getSurgeryStatusDescription(surgeryStatusId) {
    switch (surgeryStatusId) {
      case 1: {
        return "Em Aberto";
      }
      case 2: {
        return "Agendada";
      }
      case 3: {
        return "Cancelada";
      }
      case 4: {
        return "Efetivada";
      }
      case 5: {
        return "Suspensa";
      }
    }
  }
}
export default new SurgeryStatusModel();
