<template lang="html">
  <div class="main-page">
    <section class="main-page--main-content">
      <router-view name="header" />
      <section class="main-content">
        <router-view :key="$route.path" />
      </section>
    </section>

    <router-view name="rootModal" />
    <MedicalLoaderGlobal />
    <PermissionLoaderGlobal />
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {
      beforeUnloadTime: 0,
      gapTime: 0,
    };
  },

  computed: {
    isLoginPage() {
      return window.location.pathname.includes("/login");
    },
  },

  async created() {
    this.$store.dispatch("Login/LOAD_LOGIN_INFO");
  },

  mounted() {
    window.addEventListener("beforeunload", (e) => this.beforeunloadHandler(e));
    window.addEventListener("unload", (e) => this.unloadHandler(e));
  },

  destroyed() {
    window.removeEventListener("beforeunload", (e) =>
      this.beforeunloadHandler(e),
    );

    window.removeEventListener("unload", (e) => this.unloadHandler(e));
  },

  methods: {
    beforeunloadHandler() {
      this.beforeUnloadTime = new Date().getTime();
    },

    async unloadHandler(event) {
      this.gapTime = new Date().getTime() - this.beforeUnloadTime;

      if (this.gapTime <= 5) {
        if (!this.isLoginPage) {
          await this.logoff();
        }
      }
    },

    async logoff() {
      await this.$store.dispatch("Login/LOGOFF");
      this.$router.push({ name: "Login" });
    },
  },
};
</script>
