<template>
  <FoundationExternallySchedule :is-appointment="true" :printHtml="printHtml" />
</template>

<script>
import { FoundationExternallySchedule } from "~tokio/foundation/pages";
import PatientListHTML from "$appointment/submodules/schedule/html/appointment-externally-schedule/PatientListHTML";

export default {
  name: "AppointmentExternallySchedule",
  components: {
    FoundationExternallySchedule,
  },

  computed: {
    printHtml() {
      return {
        patientsList: (payload, info) => this.actPatientListHTML(payload, info),
      };
    },
  },

  methods: {
    async actPatientListHTML(payload, info) {
      const snapPrint = await this.$store.dispatch(
        "Appointment/Schedule/SEARCH_EXTERNALLY_SCHEDULE",
        payload,
      );

      const obj = {
        ...snapPrint,
        ...info,
      };
      return PatientListHTML(obj);
    },
  },
};
</script>
