import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query searchcustomHistoryHospitalizationBed(
    $inl_id: Int!
    $filter: String
    $initialExchangeDate: String
    $finalExchangeDate: String
    $initialRegistrationDate: String
    $finalRegistrationDate: String
    $limit: Int
    $offset: Int
  ) {
    customHistoryHospitalizationBed(
      inl_id: $inl_id
      filter: $filter
      initialExchangeDate: $initialExchangeDate
      finalExchangeDate: $finalExchangeDate
      initialRegistrationDate: $initialRegistrationDate
      finalRegistrationDate: $finalRegistrationDate
      limit: $limit
      offset: $offset
    ) {
      count
      rows {
        ihs_id
        inl_nome
        situacao_origem
        cor_origem
        situacao_destino
        cor_destino
        usu_nome
        data_troca
        hora_troca
        ihs_observacao
        data_registro
        hora_registro
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    return data.customHistoryHospitalizationBed;
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors);
  }
};
