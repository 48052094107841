import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import PatientDetails from "$person/patient/view/patient-details/PatientDetails";
import OpenHospitalizationModule from "$hospitalization/common/util/OpenHospitalizationModule.js";

export default {
  path: "/hospitalization/bed/search/new-hospitalization/edit-patient",
  name: "hospitalization.bed.search.new-hospitalization.edit-patient",
  components: {
    default: PatientDetails,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Internação", link: OpenHospitalizationModule },
      {
        label: "Lista de Leitos",
        link: "/hospitalization/bed/search",
      },
      {
        label: "Cadastrar Internação",
        link: "/hospitalization/bed/search/new-hospitalization",
      },
      { label: "Editar Paciente" },
    ],
  },
};
