export default {
  selected_employee_data: null,
  selected_exam_data: null,
  selected_patient_data: null,
  filter_per_day_data: null,
  filter_per_period_data: null,
  filter_date: null,
  filter_exam_queue_data: null,
  selected_queue_external_exam_data: null,
  exam_schedule: null,
  selected_patient_list_queue_data: null,
  form_schedule: null,
  filter_form_type_scale: null,
  selected_row_list_service_scale: null,
};
