<template lang="html">
  <div v-show="show" class="modal-exam-details">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <div class="title">Detalhes</div>
      </div>

      <div slot="body" class="body">
        <FormBase title="Paciente" class="area">
          <div class="patient-basic-info">
            <span
              >Nome: <b>{{ examData.pes_nome || "" }}</b></span
            >
            <span
              >CNS: <b>{{ examData.crs_numero || "" }}</b></span
            >
            <span
              >Nascimento: <b>{{ examData.pes_nascimento || "" }}</b></span
            >
            <span
              >Idade:
              <b>
                {{
                  examData.pes_idade && examData.pes_idade.years
                    ? examData.pes_idade.years
                    : ""
                }}
              </b>
            </span>
            <span
              >Prontuário da Unidade:
              <b>{{ examData.ppr_numero || "" }}</b></span
            >
            <span
              >Prontuário Único:
              <b>{{ examData.pac_prontuario_unico || "" }}</b></span
            >
            <span class="unique-in-row"
              >Mãe: <b>{{ examData.pes_mae || "" }}</b></span
            >
          </div>
        </FormBase>

        <FormBase title="Procedimento" class="area">
          <div class="exam-info">
            <span
              >Procedimento: <b>{{ examData.procedure || "" }}</b></span
            >
            <span class="max-height"
              >Subprocedimento:
              <b>{{ examData.subProcedimentos || "" }}</b></span
            >
          </div>
        </FormBase>

        <FormBase title="Meu Esus" class="area">
          <div class="app-info">
            <span
              >Status:
              <b>{{
                examData && examData.meuEsus ? examData.meuEsus[0].sme_nome : ""
              }}</b></span
            >
            <span
              >Motivo:
              <b>{{
                examData && examData.meuEsus
                  ? examData.meuEsus[0].justificativa
                  : " - "
              }}</b></span
            >
          </div>
        </FormBase>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import { RgBaseModal } from "~tokio/primitive";
import FormBase from "~tokio/foundation/form-base/FormBase";

export default {
  name: "ModalExamDetails",
  components: {
    RgBaseModal,
    FormBase,
  },
  directives: { mask },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    procedure: {
      type: String,
      default: "",
    },
    examId: { default: 0 },
    pxeId: { default: 0 },
    unityHealth: { default: 0 },
  },
  data() {
    return {
      examData: {},
    };
  },

  watch: {
    show(pValue) {
      if (!pValue) {
        this.close();
      } else {
        this.getConsultationData();
      }
    },
  },

  methods: {
    async getConsultationData() {
      try {
        if (!this.examId) {
          throw new Error("Erro ao receber dados do exame");
        }

        if (!this.pxeId || !this.unityHealth) {
          throw new Error("Erro ao receber dados do paciente e da unidade");
        }

        this.$loader.start();

        const variables = {
          uns_id: this.unityHealth,
          pxe_id: this.pxeId,
          exa_id: this.examId,
        };

        this.examData = await this.$store.dispatch(
          "Exam/Schedule/GET_EXAM_DETAILS",
          variables,
        );

        this.examData.procedure = this.procedure;
      } catch (Err) {
        this.$toaster.error(Err);
      } finally {
        this.$loader.finish();
      }
    },

    close() {
      this.examData = {};
      this.$emit("close");
    },
  },
};
</script>
