<style src="./HourGuide.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="hour-guide">
    <div v-for="(hourItem, i) in hourGuide" :key="i" class="hour-block">
      <div class="hour-item">
        <span class="hour-item-caption">
          {{ hourItem | zerofill }}
        </span>
      </div>
      <ul class="hour-step">
        <li v-for="(minuteStep, idx) in minuteSteps" :key="idx">
          {{ minuteStep | zerofill }}
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: "HourGuide",
  filters: {
    zerofill: (pValue) => ("00" + pValue).slice(-2),
  },
  data() {
    return {
      hourGuide: [
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
      ],
      minuteSteps: [0, 15, 30, 45],
    };
  },
};
</script>
