import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query printPatientCard($pes_id: Int!) {
    printPatientCard(pes_id: $pes_id) {
      html
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    return data.printPatientCard.html;
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors);
  }
};
