<style src="./RgFormPanel.scss" lang="scss" scoped></style>
<template lang="html">
  <div class="rg-form-panel">
    <ul>
      <slot />
    </ul>
  </div>
</template>

<script>
export default {
  name: "RgFormPanel",
};
</script>
