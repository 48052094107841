export default {
  STORE_CONSOLIDATED_LIST: (state, { consolidatedList }) => {
    state.consolidatedList = consolidatedList;
  },
  STORE_NEW_CONSOLIDATED_ITEM: (state, consolidatedItem) => {
    const newList = [...state.consolidatedList];
    newList.push(consolidatedItem);
    state.consolidatedList = newList;
  },
  UPDATE_CONSOLIDATED_ITEM: (state, consolidatedItem) => {
    const newList = state.consolidatedList.map((item) => {
      const computedItem = { ...item };
      if (computedItem.fco_id === consolidatedItem.fco_id)
        computedItem.fco_quantidade = consolidatedItem.fco_quantidade;
      return computedItem;
    });
    state.consolidatedList = newList;
  },
  REMOVE_CONSOLIDATED_ITEM: (state, consolidatedItem) => {
    const newList = state.consolidatedList.filter(
      (item) => item.fco_id !== consolidatedItem,
    );
    state.consolidatedList = newList;
  },
};
