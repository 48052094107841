<style src="./MedicalLoader.scss" lang="scss" scoped></style>
<template>
  <div v-show="toClose" class="background-loader">
    <div class="indicator">
      <!-- Loading -->
      <div
        v-if="status === 0"
        :class="{ 'align-loading': status === 0 }"
        class="content-body"
      >
        <svg width="16px" height="12px">
          <polyline id="back" points="1 6 4 6 6 11 10 1 12 6 15 6" />
          <polyline id="front" points="1 6 4 6 6 11 10 1 12 6 15 6" />
        </svg>
        <span class="edit-text">{{ titleLoader }}</span>
      </div>
      <!-- Success -->
      <div v-else-if="status === 1" class="content-body">
        <div
          data-id="fechar-modal-loader"
          class="button-close unselected"
          type="button"
          @click.stop.prevent="close"
        >
          <span class="button-x">x</span>
        </div>
        <IconCheck class="svg" />
        <span class="edit-text">{{ titleSuccess }}</span>
      </div>
      <!-- Failed -->
      <div v-else class="content-body">
        <div
          data-id="fechar-modal-loader"
          class="button-close unselected"
          type="button"
          @click.stop.prevent="close"
        >
          <span class="button-x">x</span>
        </div>
        <IconOnlyExclamation class="svg" />
        <span class="edit-text">{{ titleFailed }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { IconCheck, IconOnlyExclamation } from "~tokio/primitive/icon/symbols";

export default {
  name: "MedicalLoader",
  components: {
    IconCheck,
    IconOnlyExclamation,
  },
  props: {
    titleSuccess: {
      type: String,
      default: "",
    },
    titleLoader: {
      type: String,
      default: "",
    },
    titleFailed: {
      type: String,
      default: "",
    },
    status: {
      type: Number,
      default: 0,
    },
    toClose: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  watch: {
    status(pValue) {
      this.status = pValue;
    },
  },
  methods: {
    close() {
      this.$emit("close", false);
    },
  },
};
</script>
