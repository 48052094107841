<style src="./RgConfirmationButton.scss" lang="scss" scoped></style>
<template lang="html">
  <div :class="expandedClasses" class="confirmation-button">
    <button
      v-show="confirmationState"
      :class="firstClickedClasses"
      type="button"
      @click.stop.prevent="firstClicked()"
    >
      <slot>Confirma</slot>
    </button>

    <button
      v-show="!confirmationState"
      ref="button-confirm"
      type="button"
      class="confirm"
      @blur="lostFocus"
      @click.stop.prevent="confirm()"
    >
      {{ confirmationText }}
    </button>
  </div>
</template>

<script>
export default {
  name: "RgConfirmationButton",
  props: {
    confirmationText: {
      default: "Tem certeza?",
    },
    type: {
      default: "primary",
    },
  },
  data() {
    return {
      confirmationState: true,
      expandConfirmation: false,
      expectedTypes: ["primary", "danger", "warning", "success", "info"],
    };
  },
  computed: {
    usedType() {
      return this.isInvalidType() ? "primary" : this.type;
    },
    firstClickedClasses() {
      return ["firstClicked", `firstClicked-${this.usedType}`];
    },
    expandedClasses() {
      if (this.expandConfirmation) {
        return ["confirm-expanded", `confirm-expanded-${this.usedType}`];
      }
      return [`confirmation-button-${this.usedType}`];
    },
  },
  mounted() {
    if (this.isInvalidType() && process.env.NODE_ENV !== "test") {
      console.warn(
        `Invalid type passed to ${
          this.$options.name
        } use on of this list: ' ${this.expectedTypes.join(", ")}.`,
      );
    }
  },
  methods: {
    firstClicked() {
      this.confirmationState = false;
      this.expandConfirmation = true;
      this.$nextTick(() => {
        this.$refs["button-confirm"].focus();
      });
    },
    confirm() {
      this.expandConfirmation = false;
      this.confirmationState = true;
      this.$emit("confirm");
    },
    lostFocus() {
      this.expandConfirmation = false;
      this.confirmationState = true;
    },
    isInvalidType() {
      return this.expectedTypes.includes(this.type) === false;
    },
  },
};
</script>
