import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchCustomHospitalizationBed(
    $itl_id: Int
    $uns_id: Int
    $lca_id: Int
    $set_id: [Int]
    $inl_id: Int
    $inl_nome: String
    $inl_habilitado: [Int]
    $inl_extra: Int
    $inl_observacao: Int
    $inl_id_internacoes_situacoes_leito: [Int]
    $limit: Int
    $offset: Int
  ) {
    customHospitalizationBed(
      itl_id: $itl_id
      uns_id: $uns_id
      lca_id: $lca_id
      set_id: $set_id
      inl_id: $inl_id
      inl_nome: $inl_nome
      inl_id_internacoes_situacoes_leito: $inl_id_internacoes_situacoes_leito
      inl_habilitado: $inl_habilitado
      inl_extra: $inl_extra
      inl_observacao: $inl_observacao
      limit: $limit
      offset: $offset
    ) {
      count
      rows {
        id
        int_id
        int_numero
        int_responsavel
        int_id_planos_saude
        int_id_funcionarios_responsavel
        itl_id
        itl_nome
        lca_id
        set_id
        uns_id
        inl_nome
        inl_id_internacoes_situacoes_leito
        isl_id
        isl_cor
        isl_nome
        lca_nome
        set_nome
        uns_nome
        inl_extra
        inl_observacao
        inl_habilitado
        ids_tipos_do_leito
        tipos_do_leito
        nome_paciente
        clinica_atual
        dias_internado
        horas_internado
        minutos_internado
        via_nome
        ini_id
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    return data.customHospitalizationBed;
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors);
  }
};
