import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async (intIdSetor) => {
  return AuthLegacyRequest.post(
    "/unidadesaude/controller-unidade-saude/combo-box-locais-de-atendimento-por-setor",
    intIdSetor,
  )
    .then(({ data }) => {
      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
