<template>
  <div class="rg-pagination unselect">
    <div class="filter-registers unselected">
      <button
        type="button"
        data-id="menos"
        class="btnsfilters"
        :class="{ disable: disableLimitLess }"
        :disabled="disableLimitLess"
        @click="decreaseLimit"
      >
        -
      </button>
      <input
        v-model="pagination.limit"
        class="inputfilter unselect"
        min="1"
        max="20"
        title="Registros por Página"
        disabled
        readonly
      />
      <button
        type="button"
        data-id="mais"
        class="btnsfilters"
        :class="{ disable: disableLimitPlus }"
        :disabled="disableLimitPlus"
        @click="increaseLimit"
      >
        +
      </button>
    </div>

    <div class="pagination-selector">
      <RgPaginationButton
        :disabled="hasPrevious"
        :double="true"
        :rotate="true"
        :class="{ disable: hasPrevious }"
        data-id="inicio"
        title="Início"
        @click="pageInitial"
      />
      <RgPaginationButton
        :disabled="hasPrevious"
        :class="{ disable: hasPrevious }"
        data-id="anterior"
        title="Anterior"
        class="left"
        @click="pagePrevious"
      />

      <div
        v-for="(item, index) in numberOfPages"
        :key="index"
        class="body-pagination"
      >
        <div
          v-if="currentNumber(item)"
          :class="{
            current: currentNumber(item),
            last:
              item === numberOfPages && Math.abs(item - pagination.current) > 2,
            first: item === 1 && Math.abs(item - pagination.current) > 2,
          }"
          class="item-pagination"
        >
          <span title="Página Atual">{{ item }}</span>
          <span title="Total de Páginas">/ {{ numberOfPages }}</span>
        </div>
      </div>

      <RgPaginationButton
        :disabled="hasNext"
        :class="{ disable: hasNext }"
        data-id="proxima"
        title="Próximo"
        :rotate="true"
        @click="pageNext"
      />
      <RgPaginationButton
        :disabled="hasNext"
        :double="true"
        :class="{ disable: hasNext }"
        data-id="final"
        title="Final"
        @click="pageFinal"
      />
    </div>

    <div class="list-pagination">Total de Registros: {{ dataLength }}</div>
  </div>
</template>

<script>
import RgPaginationButton from "~tokio/primitive/button/rg-pagination-button/RgPaginationButton";

export default {
  name: "SmartTable",
  components: {
    RgPaginationButton,
  },
  props: {
    dataLength: {
      type: Number,
      default: 0,
    },
    itemPerPage: {
      type: Number,
    },
    maxRegister: {
      type: Number,
      default: 15,
    },
  },
  data() {
    return {
      countItem: 0,
      pagination: {
        limit: 10,
        offset: 0,
        current: 1,
      },
    };
  },
  computed: {
    numberOfPages() {
      if (!this.dataLength || !this.pagination.limit) return 1;
      return Math.ceil(this.dataLength / this.pagination.limit);
    },
    currentPage() {
      return this.numberOfPages === 0 ? 0 : this.pagination.current;
    },
    hasNext() {
      return (
        this.dataLength <= this.pagination.offset + this.pagination.limit ||
        this.pagination.current === this.numberOfPages
      );
    },
    hasPrevious() {
      return !this.pagination.offset;
    },
    disableLimitPlus() {
      return (
        this.pagination.limit >= this.maxRegister ||
        (this.hasNext && this.numberOfPages === 1)
      );
    },
    disableLimitLess() {
      return this.pagination.limit < 2;
    },
  },

  watch: {
    itemPerPage(pValue) {
      this.setLimit();
      this.loadPagination();
    },

    dataLength(pValue) {
      if (pValue && pValue < this.pagination.limit)
        this.pagination.limit = pValue;
    },
  },

  mounted() {
    this.resetLimitOffset();
    this.$nextTick(() => {
      this.setLimit();
      this.loadPagination();
    });
  },
  methods: {
    increaseLimit() {
      if (this.pagination.limit < this.maxRegister) {
        this.pagination.limit = this.pagination.limit + 1;
        if (this.dataLength !== 0) {
          this.setPage(this.pagination.current);
        }
      }
    },
    decreaseLimit() {
      if (this.pagination.limit > 1) {
        this.pagination.limit = this.pagination.limit - 1;
        if (this.dataLength !== 0) {
          this.setPage(this.pagination.current);
        }
      }
    },
    setPage(pValue) {
      this.pagination.offset = (pValue - 1) * this.pagination.limit;
      this.pagination.current = pValue;

      if (this.pagination.current > this.numberOfPages) {
        this.pagination.offset =
          (this.numberOfPages - 1) * this.pagination.limit;
        this.pagination.current = this.numberOfPages;
      }
      this.loadPagination();
    },
    pageInitial() {
      if (this.hasPrevious) {
        return;
      }
      this.pagination.offset = 0;
      this.pagination.current = 1;
      this.loadPagination();
    },

    pageFinal() {
      if (this.hasNext) {
        return;
      }
      this.pagination.offset = (this.numberOfPages - 1) * this.pagination.limit;
      this.pagination.current = this.numberOfPages;
      this.loadPagination();
    },

    pagePrevious() {
      if (this.hasPrevious) {
        return;
      }
      if (this.pagination.offset <= 0) {
        this.pagination.offset = 0;
      } else {
        this.pagination.offset -= this.pagination.limit;
        this.pagination.current -= 1;
      }
      this.loadPagination();
    },

    pageNext() {
      if (this.hasNext) {
        return;
      }
      this.pagination.offset += this.pagination.limit;
      this.pagination.current += 1;
      this.loadPagination();
    },

    currentNumber(item) {
      return this.pagination.current === item;
    },

    loadPagination() {
      this.$emit("pagination", this.pagination);
    },

    setLimit() {
      this.pagination.limit = this.itemPerPage;
    },

    resetLimitOffset() {
      this.pagination.offset = 0;
      this.pagination.current = 1;
      this.setLimit();
    },
  },
};
</script>
