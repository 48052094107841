<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchHealthPlans from "./action/SearchHealthPlans";
export default {
  name: "RgComboboxHealthPlans",
  mixins: [RgComboboxMixin],
  props: {
    healthUnitId: {
      required: true,
    },
  },
  data: () => {
    return {};
  },
  watch: {
    healthUnitId() {
      this.fillData();
    },
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];
      const data = await SearchHealthPlans({ intIdUnidade: this.healthUnitId });
      data.forEach((item) => {
        this.mData.push({ value: item.data, text: item.pls_nome });
      });
    },
  },
};
</script>
