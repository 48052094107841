<style src="./RgInputMetricValues.scss" lang="scss" scoped></style>
<template>
  <fieldset class="rg-input-metric-values--component">
    <RgFormBase :label="label" :optional="optional">
      <div class="struct">
        <input
          :id="getId"
          ref="inputFieldId"
          v-model="inputValue"
          :data-id="dataId"
          :type="type"
          :class="{ 'on-error': error.length > 0 }"
          :placeholder="placeholder"
          :tabindex="disabled ? -1 : tabIndex"
          :disabled="disabled"
          class="input-values"
          @blur="validateBlur"
          @focus="checkFocus"
          @change="change"
        />
        <div class="metric">
          <span>{{ metrics }}</span>
        </div>
        <RgValidationAlert :alert="error" class="rg-input--alert" />
      </div>
    </RgFormBase>
  </fieldset>
</template>

<script>
import isEmpty from "lodash/isEmpty";
import { RgFormBase } from "~tokio/foundation/container";
import { RgValidatorMixin, RgValidationAlert } from "../../validation";
import RgInputMixin from "../mixin/RgInputMixin";

export default {
  name: "RgInputMetricValues",
  components: {
    RgValidationAlert,
    RgFormBase,
  },
  mixins: [RgValidatorMixin, RgInputMixin],
  props: {
    type: {
      type: String,
      default: "text",
    },
    change: {
      type: Function,
      default: () => {},
    },
    metrics: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {
    getId() {
      return !isEmpty(this.id) ? this.id : this.dataId;
    },
  },
  methods: {
    validateBlur() {
      this.validate();
      this.$emit("blur");
    },
  },
};
</script>
