<style src="./PatientHeader.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="patient-header">
    <span class="people-name">{{ patientData.pes_nome }}</span>
    <span class="surgery-status">{{ surgeryStatus }}</span>
  </section>
</template>

<script>
import SurgeryStatus from "$surgery-center/surgery/model/SurgeryStatusModel";
export default {
  name: "PatientHeader",
  props: {
    patientData: {
      default() {},
    },
  },
  computed: {
    surgeryStatus() {
      if (this.patientData.cci_id_status_controles_cirurgicos) {
        return SurgeryStatus.getSurgeryStatusDescription(
          this.patientData.cci_id_status_controles_cirurgicos,
        );
      }
      return false;
    },
  },
};
</script>
