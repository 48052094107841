import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query(
    $ime_id: Int
    $ime_codigo: String
    $ime_nome: String
    $order: String
    $limit: Int
  ) {
    hospitalizationReasonForClosure(
      ime_id: $ime_id
      ime_codigo: $ime_codigo
      ime_nome: $ime_nome
      orderField: $order
      limit: $limit
    ) {
      rows {
        ime_id
        ime_nome
        ime_codigo
      }
    }
  }
`;

export default async (variables, findOne = false) => {
  // Filtros
  variables.order = "ime_codigo";
  variables.limit = 30;

  const { data } = await GraphQLClient.query({ query, variables }).catch(
    (err) => {
      console.error(err);
    },
  );
  if (!findOne) {
    return data.hospitalizationReasonForClosure.rows;
  } else {
    return data.hospitalizationReasonForClosure.rows[0];
  }
};
