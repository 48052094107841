import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation SaveConsolidatedBilling(
    $consolidatedBilling: ConsolidatedBillingInputType
  ) {
    SaveConsolidatedBilling(consolidatedBilling: $consolidatedBilling) {
      fco_id
      fco_codigo_procedimento
      fco_cbo
      fco_quantidade
      fco_idade
      procedure {
        NO_PROCEDIMENTO
      }
      occupation {
        NO_OCUPACAO
      }
    }
  }
`;

export default async ({ commit, dispatch }, { variables = {} }) => {
  const { data = null } = await GraphQLClient.mutate({
    mutation,
    variables,
  }).catch((pErr) => {
    throw new Error(pErr.graphQLErrors);
  });
  dispatch("LIST_CONSOLIDATED_BILLING", { variables: null });
  // if (variables.consolidatedBilling.fco_id) {
  //   commit('UPDATE_CONSOLIDATED_ITEM', data.SaveConsolidatedBilling)
  // } else {
  //   commit('STORE_NEW_CONSOLIDATED_ITEM', data.SaveConsolidatedBilling)
  // }
  return data;
};
