import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query People(
    $name: String
    $mother: String
    $birthday: String
    $socialName: String
    $cns: String
    $cpf: String
    $rg: String
    $gender: Int
    $age: String
    $state: String
    $city: String
    $recordNumber: String
    $unitRecordNumber: String
    $unitHealthId: Int
  ) {
    people(
      pes_nome: $name
      pes_mae: $mother
      pes_nome_social: $socialName
      pes_nascimento: $birthday
      cpf: $cpf
      cns: $cns
      rg: $rg
      pes_id_sexos: $gender
      age: $age
      state: $state
      city: $city
      pac_prontuario_unico: $recordNumber
      ppr_numero: $unitRecordNumber
      uns_id: $unitHealthId
    ) {
      rows {
        pes_id
        pes_nome
        pes_foto
        pes_nome_social
        pes_nascimento
        pes_mae
        pes_id_locais_atendimento_pleni
        pes_ativo

        gender {
          sex_id
        }
        address {
          end_logradouro
          neighborhood {
            bai_nome
            city {
              state {
                est_sigla
              }
            }
          }
        }
      }
    }
  }
`;
const formatData = (pFilters) => {
  const filters = { ...pFilters };
  if (pFilters.birthday && pFilters.birthday.length === 10) {
    filters.birthday = pFilters.birthday.split("/").reverse().join("-");
  }
  if (pFilters.cpf && pFilters.cpf.length > 0) {
    filters.cpf = pFilters.cpf.replace(/\D/g, "");
  }

  if (pFilters.cns && pFilters.cns.length > 0) {
    filters.cns = pFilters.cns.replace(/\D/g, "");
  }

  if (pFilters.rg && pFilters.rg.length > 0) {
    filters.rg = pFilters.rg.replace(/\D/g, "");
  }
  return filters;
};

const documentsNotCompleted = (variables) => {
  if (variables.cpf && variables.cpf.length !== 11) {
    return true;
  }

  if (variables.cns && variables.cns.length !== 15) {
    return true;
  }
  return false;
};

export default async ({ commit }, filters = {}) => {
  const variables = formatData(filters);

  if (documentsNotCompleted(variables)) {
    return [];
  }

  const { data } = await GraphQLClient.query({
    query,
    variables,
    options: { fetchPolicy: "network-only" },
  }).catch((err) => {
    console.error(err);
  });
  commit("PERSON_LIST", { list: data.people.rows });
  return data.people.rows;
};
