<style src="./RgComboboxSurgeryAnesthesia.scss" lang="scss"></style>
<script>
import { RgRemoteComboboxMixin } from "~tokio/primitive";
import SurgeryAnesthesiaModel from "$surgery-center/surgery/model/SurgeryAnesthesiaModel";
export default {
  name: "RgComboboxSurgeryAnesthesia",
  mixins: [RgRemoteComboboxMixin],
  data: () => {
    return {
      model: SurgeryAnesthesiaModel,
    };
  },
  methods: {
    formatItem(pData) {
      return {
        value: pData.cta_id,
        text: pData.cta_nome,
      };
    },
  },
};
</script>
