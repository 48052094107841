<style src="./RgComboboxSector.scss" lang="scss"></style>
<script>
import { RgRemoteComboboxMixin } from "~tokio/primitive";
import Sector from "$surgery-center/unit-health/model/Sector";
export default {
  name: "RgComboboxSector",
  mixins: [RgRemoteComboboxMixin],
  props: {
    unitHealth: {
      default: false,
    },
  },
  data: () => {
    return {
      model: Sector,
      method: "listPermited",
      cache: false,
    };
  },
  computed: {
    filter() {
      if (!this.unitHealth) {
        return this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
      } else {
        return this.unitHealth;
      }
    },
  },
  methods: {
    formatItem(pData) {
      return {
        value: pData.set_id,
        text: pData.set_nome,
      };
    },
  },
};
</script>
