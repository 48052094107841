<style src="./RgSuggestEmployee.scss" lang="scss" scoped></style>
<template>
  <RgSuggest
    ref="rgSuggest"
    v-model="inputValue"
    :data-id="dataId"
    :label="label"
    :title="title"
    :placeholder="placeholder"
    :search-on-focus="searchOnFocus"
    :rules="rules"
    :disabled="disabled"
    :maxlength="maxlength"
    min="0"
    @clear="clear"
  >
    <RgSuggestItem
      v-for="(item, idx) in suggestionList"
      :key="idx"
      :data-item="dataItem"
      :idx="idx"
      :item="item"
      class="employee-item"
    >
      <div class="employee-row-top">
        <span class="employee-name">{{ item.pes_nome }}</span>
      </div>
    </RgSuggestItem>
  </RgSuggest>
</template>
<script>
import { isEmpty } from "lodash";
import {
  RgSuggest,
  RgSuggestItem,
  RgSuggestMixin,
} from "~tokio/primitive/selection";
import { SearchEmployee } from "./action";

export default {
  name: "RgSuggestEmployee",
  components: {
    RgSuggest,
    RgSuggestItem,
  },
  mixins: [RgSuggestMixin],
  props: {
    sector: {
      type: [Number, Array, Boolean],
      default: null,
    },
    ocuppationId: {
      type: Number,
      default: null,
    },
    unitHealthId: {
      type: Number,
      default: null,
    },
    sectorRequired: {
      type: Boolean,
      default: false,
    },
    vusResponsavel: {
      type: Boolean,
      default: true,
    },
    filterByActiveOrInactive: {
      type: Boolean,
      default: true,
    },
    compatibleWithCodProcedure: {
      type: String,
      default: null,
    },
    dataItem: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "Profissional Solicitante",
    },
    placeholder: {
      type: String,
      default: "Digite o nome do profissional",
    },
    title: {
      type: String,
      default: "Profissional",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    maxlength: {
      type: Number,
    },
    notNecessaryBond: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      captionRaw: "pes_nome",
    };
  },
  computed: {
    noItemSelected() {
      return !isEmpty(this.inputValue) && !this.$refs.rgSuggest.itemSelected;
    },
    searchOnFocus() {
      return this.inputValue === "" || this.inputValue === null;
    },
  },
  methods: {
    validate() {
      this.$refs.rgSuggest.validate();
    },

    cleanValidate() {
      this.$refs.rgSuggest.cleanValidate();
    },

    doSearch(pSearchTerm, pLimit = null) {
      if (
        !this.sectorRequired ||
        (this.sectorRequired && this.sector && this.sector > 0)
      ) {
        return SearchEmployee({
          variables: {
            pesName: pSearchTerm || "",
            sector: this.sector,
            ocp_id: this.ocuppationId,
            filterByActiveOrInactive: this.filterByActiveOrInactive,
            compatibleWithCodProcedure: this.compatibleWithCodProcedure,
            uns_id: this.unitHealthId,
            vusResponsavel: this.vusResponsavel,
            limit: pLimit,
          },
        });
      }
    },

    forceSelection(pValue) {
      if (pValue && !isEmpty(pValue.pes_nome) && pValue.vin_id > 0) {
        this.$refs.rgSuggest.itemSelected = true;
        if (
          !this.suggestionList ||
          (this.suggestionList && this.suggestionList.length === 0)
        ) {
          this.suggestionList.push({
            pes_nome: pValue.pes_nome,
            vin_id: pValue.vin_id,
            ...pValue,
          });
        }
        this.$refs.rgSuggest.$parent.selectingItemFromSuggestList(pValue);
      }

      if (this.notNecessaryBond && this.notNecessaryBond === true) {
        this.$refs.rgSuggest.itemSelected = true;
        if (
          !this.suggestionList ||
          (this.suggestionList && this.suggestionList.length === 0)
        ) {
          this.suggestionList.push({
            pes_nome: pValue.pes_nome,
            pes_id: pValue.pes_id,
            ...pValue,
          });
        }
        this.$refs.rgSuggest.$parent.selectingItemFromSuggestList(pValue);
      }
    },

    clear() {
      this.$emit("clear");
    },
  },
};
</script>
