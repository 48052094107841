import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

import { OpenMenuModule } from "~common/utils/app/menu";

import ExamQueuePendencies from "$exam/submodules/schedule/views/queue-pendencies/ExamQueuePendencies";

export default {
  path: "/exam/schedule/queue/pendencies",
  name: "exam.schedule.queue.pendencies",
  components: {
    default: ExamQueuePendencies,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Exame", link: OpenMenuModule("exam") },
      { label: "Regulação", link: "/exam/schedule/queue" },
      { label: "Pendência" },
    ],
  },
};
