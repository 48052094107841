export default {
  // personList: [],
  // newBorn: false,
  // basicData: {
  //   pes_id: null,
  //   pes_nome: '',
  //   pes_nome_social: '',
  //   pes_id_sexos: null,
  //   pes_mae: '',
  //   pes_nascimento: '',
  //   pes_foto: '',
  //   pes_ativo: null,
  //   address: {
  //     end_cep: '',
  //     end_logradouro: '',
  //     end_numero: '',
  //     end_complemento: '',
  //     end_referencia: '',
  //     publicPlaceType: {
  //       tlg_id: null
  //     },
  //     subNeighborhood: {
  //       sub_nome: ''
  //     },
  //     neighborhood: {
  //       bai_nome: '',
  //       city: {
  //         mun_id: null,
  //         state: {
  //           est_id: null
  //         }
  //       }
  //     }
  //   }
  // },
  // phoneData: [],
  // additionalInfo: {
  //   nationality: null,
  //   city: null,
  //   user: '',
  //   pes_pai: '',
  //   pes_id_religiao: null,
  //   pes_id_tipos_sanguineos: null,
  //   pes_id_estados_civis: null,
  //   pes_id_etnias: null,
  //   pes_id_nacionalidades: null,
  //   pes_id_municipios: null,
  //   pes_id_escolaridades: null,
  //   pes_id_situacao_trabalho: null,
  //   pes_profissao: '',
  //   pes_obs: '',
  //   pes_id_usuarios: null
  // },
  // documents: {
  //   documentsType: []
  // }
};
