<style lang="scss" src="./RgAddConditionedModal.scss" scoped></style>
<template>
  <div v-if="show" class="rg-add-conditioned-modal">
    <RgBaseModal :show="show" @close="noClicked">
      <div slot="icon" class="icon">
        <IconDanger class="icon-danger" />
      </div>

      <div slot="header" class="confirm-header">
        <h3>Conflito na agenda cirúrgica</h3>
      </div>
      <div slot="body" class="confirm-body">
        <p>
          O sistema identificou que há uma cirurgia marcada para o mesmo
          <b>dia</b>, <b>hora</b> e <b>sala</b>.
        </p>

        <div class="surgery-data">
          <ul class="surgery-list-container">
            <li class="surgery-list-header">
              <ul class="list-header">
                <li class="list-header list--code">Nº Cirurgia</li>
                <li class="list-header list--date">Data</li>
                <li class="list-header list--hour">Hora</li>
                <li class="list-header list--duration">Duração</li>
                <li class="list-header list--patient">Paciente</li>
                <li class="list-header list--doc">Médico</li>
                <li class="list-header list--sector">Clínica</li>
              </ul>
            </li>
            <li
              v-for="(surgerieDetail, i) in [surgeriesDetails]"
              :key="i"
              class="surgery-list-item"
            >
              <ul class="list-data">
                <li :title="surgerieDetail.cci_id" class="list-item list--code">
                  {{ surgerieDetail.cci_numero }}
                </li>
                <li
                  :title="convertDate(surgerieDetail.cci_data_inicio)"
                  class="list-item list--date"
                >
                  {{ convertDate(surgerieDetail.cci_data_inicio) }}
                </li>
                <li
                  :title="convertTime(surgerieDetail.cci_hora_inicio)"
                  class="list-item list--hour"
                >
                  {{ convertTime(surgerieDetail.cci_hora_inicio) }}
                </li>
                <li
                  :title="convertTime(surgerieDetail.cci_duracao)"
                  class="list-item list--duration"
                >
                  {{ convertTime(surgerieDetail.cci_duracao) }}
                </li>
                <li
                  :title="surgerieDetail.patient"
                  class="list-item list--patient"
                >
                  {{ surgerieDetail.patient }}
                </li>
                <li
                  :title="surgerieDetail.requesting_doctor"
                  class="list-item list--doc"
                >
                  {{ surgerieDetail.requesting_doctor }}
                </li>
                <li
                  :title="surgerieDetail.requesting_clinic"
                  class="list-item list--sector"
                >
                  {{ surgerieDetail.requesting_clinic }}
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <p class="ask">
          Deseja tornar sua cirurgia <b>condicionada</b> a mencionada acima?
        </p>
      </div>

      <div slot="footer" class="confirm-actions-footer">
        <button
          class="btn btn-confirmed btn-success"
          @click.stop.prevent="yesClicked"
        >
          Sim, desejo condicionar
        </button>
        <button
          class="btn btn-denied btn-danger"
          @click.stop.prevent="noClicked"
        >
          Não desejo condicionar
        </button>
      </div>
    </RgBaseModal>
  </div>
</template>

<script type="text/javascript">
import { RgBaseModal, IconDanger } from "~tokio/primitive";
import moment from "moment";

export default {
  name: "RgAddConditionedModal",
  components: {
    RgBaseModal,
    IconDanger,
  },
  props: {
    show: {
      type: Boolean,
      default: undefined,
    },
    surgeriesDetails: {
      default() {
        return {
          cci_data_inicio: null,
          cci_hora_inicio: null,
          cci_duracao: null,
          lca_id_locais_atendimento: null,
        };
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    yesClicked: function () {
      this.$emit("choose", { status: "CONDITIONED" });
    },
    noClicked: function () {
      this.$emit("choose", { status: "ROTINE" });
    },
    convertDate: (pDate) => moment(pDate, "YYYY-MM-DD").format("DD/MM/YYYY"),
    convertTime: (pTime) => moment(pTime, "HH:mm:ss").format("HH:mm"),
  },
};
</script>
