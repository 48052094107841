export default {
  examInfo: {
    leb_id: null,
    leb_id_profissional_solicitante: null,
    leb_id_pacientes: null,
    leb_data_agendamento: null,
    leb_data_solicitacao: null,
    leb_hora_agendamento: null,
    leb_profissional_solicitante: null,
    leb_sigla_conselho: null,
    leb_numero_conselho: null,
    leb_cpf_profissional: null,
    leb_uf_profissional: null,
    leb_nome_paciente: null,
    leb_sexo_paciente: null,
    leb_nascimento_paciente: null,
    leb_endereco_paciente: null,
    leb_bairro_paciente: null,
    leb_municipio_paciente: null,
    leb_uf_paciente: null,
    leb_cep_paciente: null,
    leb_telefone_paciente: null,
    leb_cor_paciente: null,
    leb_nacionalidade_paciente: null,
    leb_naturalidade_paciente: null,
    leb_peso: null,
    leb_altura: null,
    leb_menstruada: null,
    leb_informacoes_menstruacao: null,
    leb_medicamentos: null,
    leb_cid: null,
    leb_id_laboratoriais_status_pedido: null,
    leb_codigo_solicitacao: null,
    leb_motivo_nao_autorizacao: null,
    bol_numero: null,
    leb_prontuario: null,
    pac_prontuario_unico: null,
    patient: {
      pac_id: null,
    },
    person: {
      pes_id: null,
    },
  },
};
