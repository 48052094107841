// import LocalStorage from "~common/local-storage/LocalStorage";

const AttendedBulletinBackPageHTML = (pData) => {
  console.log("pData:", pData);

  const html = `
  <!DOCTYPE html>
  <html>
  <head>
    <meta charset="utf-8" />
    <title>Esus</title>
    <style type="text/css">
      h1 {
        display: flex;
        justify-content: center;
        font-weight: normal;
      }

      body {
        width: 210mm;
        height: 276mm;
        font-size: 12px;
        border: 1px solid grey;
        font-family: Arial, Helvetica, sans-serif;
      }

      section {
        margin: 1rem;
      }

      .box-small {
        min-height: 50px;
        padding: 5px;
        border: 1px solid grey;
      }

      .box-medium {
        min-height: 110px;
        padding: 5px;
        border: 1px solid grey;
      }

      .box-small, .box-medium, span {
        width: 198mm;
        word-wrap: break-word;
      }

      .simple-line {
        display: flex;
        flex-direction: row;
        gap: 10px;
      }

      .simple-line > span {
        border: 1px solid grey;
        width: 365px;
        padding: 4px;
      }

      .block {
        display: flex;
        gap: 10px;
      }

      .block > .first-column > .date {
        display: flex;
        gap: 5px;
        width: 376px;
      }

      .block > .first-column > .date > span {
        border: 1px solid grey;
        padding: 4px;
        height: 12px;
      }

      .block > .second-column {
        display: flex;
        width: 376px;
        margin-right: 50px;
      }

      .block > .second-column > span {
        display: flex;
        height: 200px;
        border: 1px solid grey;
        padding: 4px;
      }

      .first-column-checkboxs, .second-column-checkboxs {
        width: 186px;
        margin-top: 1rem;
      }

      .first-column-checkboxs > div {
        display: flex;
        align-items: center;
      }

      .columns {
        display: flex;
        gap: 5px;
      }

      .second-column-checkboxs > div {
        display: flex;
        align-items: center;
      }

      .checkbox {
        padding: 4px;
        margin-right: 10px;
        margin-bottom: 5px;
        border: 1px solid black;
        width: 10px;
        height: 10px;
      }

      .to-sign {
        display: flex;
        text-align: center;
        gap: 15px;
        top: 85px;
        position: relative
      }

      .to-sign-border {
        border-top: 1px solid grey;
      }
    </style>
  </head>

  <body>
    <h1> TRATAMENTO </h1>

    <section class="box-small">
      <label>EXAMES SOLICITADOS: </label>
      <span></span>
    </section>

    <section class="box-small">
      <label>DIAGNÓSTICO PROVISÓRIO: </label>
      <span></span>
    </section>

    <section class="box-medium">
      <label>PRESCRIÇÃO INICIAL: </label>
      <span></span>
    </section>

    <section class="box-medium">
      <label>EVOLUÇÃO DA PRESCRIÇÃO: </label>
      <span></span>
    </section>

    <section>
      <div class="simple-line">
        <span>DESTINO:</span>
        <span>ENCAMINHAMENTO:</span>
      </div>
    </section>

    <section class="block">
      <div class="first-column">
        <div class="date">
          <span>DATA:</span>
          <span>HORA:</span>
        </div>

        <div class="columns">
          <div class="first-column-checkboxs">
          <div>
            <span class="checkbox"></span> <label> ALTA HOSPITALAR </label>
          </div>
          <div>
            <span class="checkbox"></span> <label> ALTA MÉDICA </label>
          </div>
          <div>
            <span class="checkbox"></span> <label> ALTA À REVELIA </label>
          </div>
          <div>
            <span class="checkbox"></span> <label> ALTA SOCIAL </label>
          </div>
          <div>
            <span class="checkbox"></span> <label> ENCAMINHAMENTO </label>
          </div>
        </div>

        <div class="second-column-checkboxs">
          <div>
            <span class="checkbox"></span> <label>INTERNAÇÃO</label>
          </div>
          <div>
            <span class="checkbox"></span> <label>ÓBITO</label>
          </div>
          <div>
            <span class="checkbox"></span> <label>TRANSFERÊNCIA</label>
          </div>
          <div>
            <span class="checkbox"></span> <label>DESISTÊNCIA</label>
          </div>
        </div>
        </div>
      </div>
      <div class="second-column">
        <span>OBSERVAÇÃO DE SAÍDA:</span>
      </div>
    </section>

    <section class="box-medium">
      <label>PARECER DO ESPECIALISTA: </label>
      <span></span>

      <div class="to-sign">
      <span class="to-sign-border">
        Médico Responsável - Assinatura / Carimbo
      </span>

      <span class="to-sign-border">
        Chefe de Equipe - Assinatura / Carimbo
      </span>
      </div>
    </section>

    <section class="box-medium">
      <label>OBSERVAÇÕES DA ENFERMAGEM: </label>
      <span></span>

      <div class="to-sign">
      <span class="to-sign-border">
        Responsável - Assinatura / Carimbo
      </span>

      <span class="to-sign-border">
        Chefe de Equipe - Assinatura / Carimbo
      </span>
      </div>
    </section>
  </body>
</html>
    `;
  return html;
};

export default AttendedBulletinBackPageHTML;
