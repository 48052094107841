<style src="./RgSurgeryClinicOccupationForm.scss" lang="scss" scoped></style>
<template lang="html">
  <div class="surgery-kit-type-form">
    <RgForm @submit="submit">
      <div class="row">
        <RgComboboxRequestingClinician
          v-model="form.inc_id"
          :rules="{ required: true }"
          class="col-5"
          @change="filter"
        />
        <RgTypeaheadOccupation
          v-model="form.ocp_id"
          :rules="{ required: true }"
          class="col-7"
          label="Ocupação"
          placeholder="Digite a ocupação"
        />
      </div>
    </RgForm>
  </div>
</template>

<script>
import { toast } from "~tokio/primitive";
import { RgForm } from "~tokio/foundation/form";
import RgTypeaheadOccupation from "$surgery-center/employee/component/rg-typeahead-occupation/RgTypeaheadOccupation";
import RgComboboxRequestingClinician from "$surgery-center/surgery/component/combobox/rg-combobox-requesting-clinician/RgComboboxRequestingClinician";
import SurgeryRequestingClinicianModel from "$surgery-center/surgery/model/SurgeryRequestingClinicianModel";
export default {
  name: "RgSurgeryClinicOccupationForm",
  components: {
    RgForm,
    RgTypeaheadOccupation,
    RgComboboxRequestingClinician,
  },
  data: () => {
    return {
      form: {
        inc_id: "",
        ocp_id: "",
      },
    };
  },
  methods: {
    filter(pItem) {
      this.$emit("search-for", pItem[0] ? pItem[0].text : "");
    },
    submit(pErr, pDone) {
      if (pErr) {
        return;
      }
      SurgeryRequestingClinicianModel.addOccupation(
        this.form.inc_id,
        this.form.ocp_id,
      )
        .then((pRes) => {
          pDone();
          this.$emit("saved", pRes);
        })
        .catch((pErr) => {
          pDone();
          toast.error(pErr.response.errors[0].msg, "Erro ao Realizar Operação");
        });
    },
    update(pAction) {},
    cleanForm() {
      this.form = {
        inc_id: "",
        ocp_id: "",
      };
    },
  },
};
</script>
