<template lang="html">
  <svg
    id="Layer_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 330 330"
    style="enable-background: new 0 0 330 330"
    xml:space="preserve"
  >
    <path
      id="XMLID_227_"
      d="M315,204h-45v-78.604c0.003-0.133,0.02-0.263,0.02-0.396c0-3.606-1.287-6.903-3.407-9.49
	c-0.021-0.026-0.042-0.053-0.064-0.079c-0.276-0.332-0.567-0.65-0.871-0.958c-0.044-0.044-0.087-0.089-0.131-0.133
	c-0.133-0.131-0.255-0.272-0.393-0.398L155.609,4.396c-0.004-0.004-0.01-0.009-0.015-0.014c-0.307-0.307-0.628-0.593-0.956-0.869
	c-0.104-0.087-0.211-0.168-0.316-0.253c-0.241-0.191-0.485-0.375-0.736-0.55c-0.124-0.087-0.249-0.173-0.376-0.256
	c-0.264-0.173-0.534-0.335-0.807-0.49c-0.11-0.063-0.217-0.131-0.33-0.191c-0.364-0.194-0.734-0.372-1.111-0.535
	c-0.152-0.066-0.31-0.124-0.465-0.185c-0.25-0.099-0.502-0.192-0.757-0.277c-0.175-0.058-0.35-0.114-0.527-0.166
	c-0.289-0.084-0.581-0.158-0.875-0.225c-0.131-0.029-0.259-0.066-0.392-0.093c-0.42-0.084-0.844-0.146-1.27-0.193
	c-0.13-0.015-0.262-0.023-0.394-0.035c-0.352-0.031-0.705-0.048-1.06-0.054C145.148,0.01,145.076,0,145,0H15C6.716,0,0,6.716,0,15
	v300c0,8.284,6.716,15,15,15h240c8.284,0,15-6.716,15-15v-81h45c8.284,0,15-6.716,15-15C330,210.716,323.284,204,315,204z
	M160,51.213L218.787,110H160V51.213z M240,300H30V30h100v95c0,8.284,6.716,15,15,15h95v63.999l-58.786-0.001l11.893-11.892
	c5.858-5.858,5.858-15.355,0-21.213c-5.858-5.857-15.355-5.858-21.213-0.001l-37.5,37.499c-0.056,0.056-0.104,0.118-0.158,0.175
	c-0.285,0.294-0.564,0.593-0.823,0.909c-0.095,0.115-0.176,0.24-0.268,0.357c-0.209,0.27-0.419,0.539-0.609,0.823
	c-0.067,0.1-0.122,0.206-0.187,0.307c-0.199,0.313-0.395,0.627-0.572,0.955c-0.039,0.073-0.07,0.15-0.108,0.224
	c-0.186,0.362-0.365,0.728-0.522,1.105c-0.02,0.049-0.035,0.101-0.055,0.15c-0.162,0.402-0.313,0.81-0.44,1.229
	c-0.017,0.055-0.027,0.111-0.043,0.166c-0.12,0.412-0.23,0.828-0.315,1.253c-0.025,0.124-0.036,0.251-0.057,0.376
	c-0.062,0.357-0.125,0.714-0.161,1.08c-0.05,0.496-0.076,0.995-0.076,1.497c0,0.503,0.026,1.002,0.076,1.498
	c0.036,0.365,0.099,0.722,0.161,1.08c0.021,0.124,0.032,0.251,0.057,0.374c0.086,0.431,0.197,0.852,0.319,1.268
	c0.014,0.049,0.023,0.101,0.039,0.15c0.128,0.421,0.279,0.832,0.442,1.237c0.019,0.047,0.033,0.096,0.053,0.143
	c0.159,0.382,0.339,0.752,0.528,1.118c0.036,0.069,0.065,0.142,0.102,0.211c0.178,0.333,0.377,0.652,0.58,0.969
	c0.061,0.097,0.114,0.198,0.178,0.293c0.197,0.295,0.415,0.575,0.632,0.855c0.083,0.107,0.158,0.221,0.244,0.326
	c0.288,0.35,0.594,0.685,0.912,1.007c0.024,0.025,0.046,0.053,0.071,0.078l37.5,37.501c2.93,2.929,6.768,4.394,10.607,4.394
	c3.838,0,7.678-1.465,10.606-4.393c5.858-5.858,5.858-15.355,0-21.213l-11.894-11.895L240,233.999V300z"
    />
  </svg>
</template>

<script>
export default {
  name: "Import",
};
</script>
