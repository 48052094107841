import { GraphQLClient, gql } from "@/common/request/GraphQLClient";
import { prepareAihInfo } from "./HospitalizationBillingAihHelper";

const query = gql`
  query GetPatientHospitalizationAihInfo($hospitalizationBillingAihId: Int) {
    hospitalizationBillingAih(iaf_id: $hospitalizationBillingAihId) {
      rows {
        iaf_id
        iaf_id_internacoes
        iaf_numero_aih
        iaf_tipo_aih
        iaf_apresentacao
        iaf_data_internacao
        iaf_data_saida
        iaf_orgao_emissor
        iaf_prontuario
        iaf_faturado
      }
    }
  }
`;
export default async ({ commit }, { hospitalizationBillingAihId }) => {
  if (!hospitalizationBillingAihId) return false;

  const variables = {
    hospitalizationBillingAihId,
  };

  const { data } = await GraphQLClient.query({
    query,
    variables,
    fetchPolicy: "no-cache",
  });

  if (data.hospitalizationBillingAih.rows.length < 1) return false;
  commit(
    "SET_HOSPITALIZATION_AIH_INFO",
    prepareAihInfo(data.hospitalizationBillingAih.rows[0]),
  );
  return true;
};
