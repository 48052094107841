<script>
import { RgRemoteTypeaheadMixin } from "~tokio/primitive";
import PatientModel from "$surgery-center/patient/model/PatientModel";
export default {
  name: "RgTypeaheadPatient",
  mixins: [RgRemoteTypeaheadMixin],
  props: {
    label: {
      default: "Paciente",
    },
    min: {
      default: "2",
    },
    placeholder: {
      default: "",
    },
    invalidMessage: {
      default: "Nenhum paciente selecionado",
    },
    typeOfSearch: {
      default: 1,
    },
  },
  data() {
    return {
      model: PatientModel,
      filter: () => {
        return {
          pes_nome: this.inputValue,
          type_of_search: this.typeOfSearch,
        };
      },
    };
  },
  methods: {
    formatItem(item) {
      item.value = item.pac_id;
      item.text = item.person.pes_nome;
      return item;
    },
  },
};
</script>
