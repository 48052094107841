<style src="./RgSearch.scss" lang="scss" scoped></style>
<template>
  <div class="report-container">
    <ModuleBox
      ref="filterbox"
      :granted="hasPermission"
      :disabled="disabled"
      :title="filterTitle"
      :class="{ 'module-box--filter wrap': isRetractable }"
      class="filter-box"
      collapse
    >
      <slot name="extra" />
      <!-- SLOT: Before Filters -->

      <form class="form-container small-scroll" @submit.prevent="submitForm">
        <RgValidatorForm ref="validator" class="rg-validator">
          <slot name="filters" />
          <!-- SLOT: Filters -->
        </RgValidatorForm>
      </form>

      <!-- FILTER ACTIONS -->
      <div class="filter-actions">
        <RgCleanButton
          small
          data-id="limpar"
          class="clean"
          title="Limpar"
          :disabled="disabled"
          @click="clearForm"
        />

        <RgSearchButton
          ref="btnSubmit"
          v-shortkey="{
            enter: hasSearchFunction && !disabled ? ['enter'] : [''],
          }"
          :disabled="!hasSearchFunction || disabled"
          small
          data-id="buscar"
          class="search"
          label="Buscar"
          title="Buscar"
          @shortkey.native="submitForm"
          @submit="submitForm"
        />
      </div>
    </ModuleBox>

    <ModuleBox
      ref="contentList"
      :granted="hasPermission"
      :disabled="disabled"
      :title="resultTitle"
      :showTitle="showTitle"
      :class="{
        'oclusion margin-50': !withoutOclusion && isRetractable,
        'margin-left': isRetractable,
      }"
      class="module-box--report"
    >
      <div slot="title" class="title">
        <div
          v-if="isHelper"
          class="helper title-space"
          @click="showModalHelper"
        >
          <IconInfoHelper title="Ajuda" />
        </div>

        <slot name="menu-top" />

        <!-- <RgExcelButton
          v-if="isReport"
          ref="btnExcel"
          large
          :class="{ disabled: isWaitingResult }"
          :disabled="!isFilterFormValid || isWaitingResult"
          data-id="Excel"
          title="Excel"
          class="space title-space"
          @click="downloadExcelReport"
        />

        <RgCsvButton
          v-if="isReport"
          ref="btnCsv"
          :class="{ disabled: isWaitingResult }"
          :disabled="!isFilterFormValid || isWaitingResult"
          large
          data-id="CSV"
          title="Exportar CSV"
          class="csv title-space"
          @click="downloadCsvReport"
        /> -->

        <DropdownButton
          v-if="isReport"
          ref="btnExcel"
          class="space title-space"
          :disabled="!isFilterFormValid || isWaitingResult || countItem < 1"
          data-id="Excel"
          separator
          fixed
          large
          label="Exportar"
          :action-options="itemActionExport()"
          background-color="#1e88a9"
        >
          <IconDownloadArrow slot="icon" />
        </DropdownButton>

        <RgPrinterButton
          v-if="isReport"
          :disabled="!isFilterFormValid || isWaitingResult || countItem < 1"
          large
          data-id="imprimir"
          title="Imprimir"
          class="print title-space"
          @click="printReport"
        />

        <RgNewButton
          v-if="canShowNewButton"
          large
          :title="title"
          data-id="novo"
          class="new title-space"
          @click="goToNew"
        />
      </div>

      <div
        v-show="!countItem && showEmptyMessage"
        ref="listContent"
        class="no-result"
      >
        <IconEmpty />
        <span class="info">
          Não foram encontrados resultados para essa busca
        </span>
      </div>

      <div
        v-if="countItem > 0 || !showEmptyMessage"
        ref="listContent"
        class="list-content"
      >
        <slot /><!-- SLOT: Content -->
      </div>

      <div slot="buttons-bottom" class="buttons-bottom">
        <slot name="menu-bottom" />
      </div>

      <div v-if="showFooter" slot="footer" class="list-footer">
        <!-- SLOT: Footer -->

        <div v-if="!hiddenRegisters" class="filter-registers unselect">
          <button
            :class="{ disable: disableDecreaseLimit }"
            :disabled="disableDecreaseLimit"
            type="button"
            data-id="menos"
            class="btns-filters"
            @click="decreaseLimit"
          >
            -
          </button>

          <input
            v-model="pagination.limit"
            class="input-filter"
            min="1"
            max="20"
            title="Registros por Página"
            disabled
          />

          <button
            :class="{ disable: disableIncreaseLimit }"
            :disabled="disableIncreaseLimit"
            type="button"
            data-id="mais"
            class="btns-filters"
            @click="increaseLimit"
          >
            +
          </button>
        </div>

        <div v-else class="list-pagination">
          Total de Páginas: {{ numberOfPages }}
        </div>

        <div class="btn-next-pre">
          <RgPaginationButton
            :disabled="hasPrevious"
            :double="true"
            :rotate="true"
            :class="{ 'disabled-field no-event': hasPrevious }"
            data-id="inicio"
            title="Início"
            @click="pageInitial"
          />

          <RgPaginationButton
            :disabled="hasPrevious"
            :class="{ 'disabled-field no-event': hasPrevious }"
            data-id="anterior"
            title="Anterior"
            class="pagination-left"
            @click="pagePrevious"
          />

          <div
            v-for="(item, index) in numberOfPages"
            :key="index"
            class="body-pagination"
          >
            <div
              v-if="currentNumber(item)"
              :class="{
                current: currentNumber(item),
                last:
                  item === numberOfPages &&
                  Math.abs(item - pagination.current) > 2,
                first: item === 1 && Math.abs(item - pagination.current) > 2,
              }"
              class="item-pagination"
            >
              <span title="Página Atual">{{ item }}</span>
              <span title="Total de Páginas">/ {{ numberOfPages }}</span>
            </div>
          </div>

          <RgPaginationButton
            :disabled="hasNext"
            :class="{ 'disabled-field no-event': hasNext }"
            :rotate="true"
            data-id="proxima"
            title="Próximo"
            @click="pageNext"
          />

          <RgPaginationButton
            :disabled="hasNext"
            :double="true"
            :class="{ 'disabled-field no-event': hasNext }"
            data-id="final"
            title="Final"
            @click="pageFinal"
          />
        </div>

        <div class="list-pagination">
          Registros encontrados: {{ pagination.total }}
        </div>
      </div>
    </ModuleBox>
    <!-- MODAL HELPER -->

    <RgBaseModal
      v-if="isHelper"
      ref="Modal"
      v-shortkey="['esc']"
      :show="showHelper"
      class="rg-alert-base-modal"
    >
      <div slot="header" class="history-header">
        <span class="title">
          <slot name="title-header" />
        </span>

        <div
          slot="header"
          data-id="fechar-modal-etiqueta"
          class="button-close unselect"
          @click.stop.prevent="closeHelper"
        >
          X
        </div>
      </div>

      <div slot="body">
        <slot name="body-helper" />
      </div>

      <div slot="footer">
        <slot name="footer-helper" />
      </div>
    </RgBaseModal>
    <!-- END MODAL HELPER -->
  </div>
</template>

<script>
import { isEmpty } from "lodash";
import moment from "moment";
import ModuleBox from "~tokio/foundation/modulebox/Modulebox";
import {
  RgValidatorForm,
  RgPrinterButton,
  // RgCsvButton,
  // RgExcelButton,
  IconInfoHelper,
  RgBaseModal,
  DropdownButton,
} from "~tokio/primitive";
import { AlertError } from "$exam/common/services";
import { PrintReport, DownloadFile } from "./helpers";

import { IconEmpty, IconDownloadArrow } from "~tokio/primitive/icon/symbols";

import {
  RgCleanButton,
  RgNewButton,
  RgSearchButton,
  RgPaginationButton,
} from "~tokio/primitive/button";

export default {
  name: "RgSearch",
  components: {
    // RgCsvButton,
    // RgExcelButton,
    ModuleBox,
    RgValidatorForm,
    RgPrinterButton,
    RgNewButton,
    IconInfoHelper,
    RgCleanButton,
    RgBaseModal,
    RgSearchButton,
    RgPaginationButton,
    IconEmpty,
    DropdownButton,
    IconDownloadArrow,
  },

  filters: {
    formatDateBR: (pDate) => moment(pDate, "YYYY-MM-DD").format("DD/MM/YYYY"),
  },

  props: {
    /**
     * @param {*} filters
     * @returns {html: string, count: number}
     */
    searchFunction: {
      type: Function,
      required: true,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    clearFunction: {
      type: Function,
      required: true,
    },
    itemHeight: {
      type: Number,
      default: 0,
    },
    searchAreaHeight: {
      type: Number,
      default: 0,
    },
    itemWidth: {
      type: Number,
      default: 0,
    },
    individualHeight: {
      type: Number,
      default: 0,
    },
    filterTitle: {
      type: String,
      default: "Filtros",
    },
    permission: {
      type: String,
      default: "",
    },
    resultTitle: {
      type: String,
    },
    buildFilter: {
      type: Function,
      required: true,
    },
    isReport: {
      type: Boolean,
      default: false,
    },
    infiniteLimit: {
      type: Boolean,
      default: false,
    },
    printReportCss: {
      default: "",
    },
    newUrl: {
      type: String,
      default: "",
    },
    showNewButton: {
      type: Boolean,
      default: false,
    },
    csvReport: {
      type: Function,
      default: () => {},
    },
    isRetractable: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    maxRegister: {
      type: Number,
      default: 15,
    },
    hiddenRegisters: {
      type: Boolean,
      default: false,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    isHelper: {
      type: Boolean,
      default: false,
    },
    showEmptyMessage: {
      type: Boolean,
      default: true,
    },
    titleLoader: {
      type: String,
      default: "Aguarde...",
    },
    disabledShorkey: Boolean,
    disabled: Boolean,
  },

  data() {
    return {
      countItem: 0,
      isWaitingResult: false,
      withoutOclusion: false,
      showHelper: false,
      pagination: {
        limit: 10,
        offset: 0,
        total: 0,
        current: 1,
      },
      html: "",
    };
  },

  computed: {
    hasNext() {
      return (
        this.pagination.total <= this.pagination.offset + this.pagination.limit
      );
    },
    hasPrevious() {
      return !this.pagination.offset;
    },
    numberOfPages() {
      if (!this.pagination.total || !this.pagination.limit) return 1;
      return Math.ceil(this.pagination.total / this.pagination.limit);
    },
    currentPage() {
      return this.numberOfPages === 0 ? 0 : this.pagination.current;
    },
    canShowNewButton() {
      return !this.isReport && (!isEmpty(this.newUrl) || this.showNewButton);
    },
    hasSearchFunction() {
      return !!this.searchFunction;
    },
    disableIncreaseLimit() {
      return this.disabled || this.pagination.limit >= this.pagination.total;
    },
    disableDecreaseLimit() {
      return (
        this.disabled ||
        this.pagination.total === 0 ||
        this.pagination.limit === 1
      );
    },
    hasPermission() {
      if (this.permission) {
        return this.$Permissions.global.has(this.permission);
      } else {
        return true;
      }
    },
  },

  watch: {
    numberOfPages(pValue) {
      if (this.pagination.current > pValue) {
        this.submitForm(true);
      }
    },
  },

  mounted() {
    this.setLimit();
    this.resetLimitOffset();
    this.watchModulebox();
  },

  methods: {
    increaseLimit() {
      if (this.pagination.limit < this.maxRegister) {
        this.pagination.limit = this.pagination.limit + 1;

        if (this.countItem !== 0) {
          if (this.pagination.current > this.numberOfPages) {
            this.pageFinal();
          }
          this.setPage(this.pagination.current);
        }
      }
    },
    decreaseLimit() {
      if (this.pagination.limit > 1) {
        this.pagination.limit = this.pagination.limit - 1;
        if (this.countItem !== 0) {
          this.setPage(this.pagination.current);
        }
      }
    },
    currentNumber(item) {
      return this.pagination.current === item;
    },
    filterPagination(item) {
      // other way to filter the pagination
      return (
        Math.abs(this.pagination.current - item) < 2 ||
        item === 1 ||
        item === this.numberOfPages
      );
    },
    watchModulebox() {
      this.$watch(
        () => this.$refs.filterbox.reversed,
        (pValue) => {
          this.withoutOclusion = pValue;
        },
      );
    },
    isFilterFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },
    formatDate(pDate) {
      this.$utils.date.BrazilianDateToDatabase(pDate);
    },
    prepareData(pToPrint = false, pToCsv = false, pToExcel = false) {
      const filterData = this.buildFilter();

      const data = {
        ...filterData,
        toPrint: pToPrint,
        toCsv: pToCsv,
        toExcel: pToExcel,
      };

      if (this.infiniteLimit) {
        this.pagination.limit = 99999;
      }

      if (data.arrFormData) {
        data.arrFormData.limiteInicio = this.pagination.offset;
        data.arrFormData.limiteFim = this.pagination.limit;
        return data;
      }

      if (data.arrFiltros) {
        data.arrFiltros.limiteInicio = this.pagination.offset;
        data.arrFiltros.limiteFim = this.pagination.limit;
        return data;
      }

      if (data.arrFiltro) {
        data.arrFiltro.limiteInicio = this.pagination.offset;
        data.arrFiltro.limiteFim = this.pagination.limit;
        return data;
      }

      return pToPrint || pToCsv || pToExcel
        ? data
        : {
            ...data,
            limit: this.pagination.limit,
            offset: this.pagination.offset,
          };
    },
    clearForm() {
      this.clearFunction();
      // this.isFilterFormValid()
      this.clearPagination();
      this.html = "";
      this.$refs.validator.cleanValidate();
      this.$emit("clear", true);
    },
    resetLimitOffset() {
      this.setLimit();
      this.pagination.offset = 0;
      this.pagination.current = 1;
    },
    async submitForm(submitFromButton = true, reSearch = false) {
      if (this.disabledShorkey) {
        return;
      }
      try {
        const isFormValid = await this.isFilterFormValid();
        if (!isFormValid || !this.hasSearchFunction) {
          throw new Error("Verifique os campos e tente novamente.");
        } else {
          this.$refs.btnSubmit.actionSubmit();

          if (submitFromButton) {
            this.resetLimitOffset();
            this.$emit("submitFromButton");
          } else if (reSearch) this.setLimit();
          /**
           * #BALBI: Extrai o metodo performSearch para ser executado programaticamente utilizando o $ref
           * ex: assim que a tela carrega quero chamar a função de busca, com o submitForm, ele chama a validação
           * e executa outras regras que não permitirão que a busca seja feita, um dos problemas que inviabilizam é
           * a validação de campos com required, que sao executadas antes mesmo do preenchimento do formulario
           *
           * #ARTHUR: passei como parametro o submitFromButton, para saber quando a busca foi disparada pelo botão
           * e tratar casos especificos de quando a busca vem dele
           *
           */
          this.$emit("beforePerformSearch", true);
          await this.performSearch(submitFromButton);
          if (submitFromButton && this.isRetractable)
            this.$refs.filterbox.toggleCollapse();
        }
      } catch (error) {
        AlertError(error.message);
        this.$refs.btnSubmit.fail();
      }
    },
    async performSearch(submitFromButton = false) {
      const data = this.prepareData();
      const result = await this.searchFunction(data);
      if (!result) {
        this.$refs.btnSubmit.actionDone();
        return;
      }
      this.countItem = result.rows ? result.count : Number(result.total);
      this.updateValues(result);
      this.$emit("afterPerformSearch", submitFromButton);
    },
    updateValues(pResult) {
      if (this.isReport) {
        this.html = pResult.html;
        this.pagination.total = Number(pResult.count);
        this.countItem = Number(pResult.count);
      }

      const rows = pResult.rows
        ? pResult.rows
        : pResult.data || pResult.recordSet;

      this.$emit("input", rows);
      this.$emit("afterSearch", rows);
      this.$emit("count", pResult.total || pResult.count);

      this.pagination.total =
        Number(pResult.count) >= 0
          ? Number(pResult.count)
          : Number(pResult.total);
      if (
        this.pagination.total > 0 &&
        this.pagination.total <= this.pagination.limit
      ) {
        this.pagination.limit = this.pagination.total;
      }
      this.$refs.btnSubmit.actionDone();
    },
    pageInitial() {
      this.pagination.offset = 0;
      this.pagination.current = 1;
      this.submitForm(false);
      this.$emit("viewClickPagination", true);
    },
    pageFinal() {
      this.pagination.offset = (this.numberOfPages - 1) * this.pagination.limit;
      this.pagination.current = this.numberOfPages;
      this.submitForm(false);
      this.$emit("viewClickPagination", true);
    },
    pagePrevious() {
      if (this.pagination.offset <= 0) {
        this.pagination.offset = 0;
      } else {
        this.pagination.offset -= this.pagination.limit;
        this.pagination.current -= 1;
      }
      this.submitForm(false);
      this.$emit("viewClickPagination", true);
    },
    pageNext() {
      this.pagination.offset += this.pagination.limit;
      this.pagination.current += 1;
      this.submitForm(false);
      this.$emit("viewClickPagination", true);
    },
    async printReport() {
      try {
        if (this.isWaitingResult) {
          return;
        }
        this.isWaitingResult = true;
        // if (this.pagination.total === 0)
        //   throw new Error("Nenhum registro a ser impresso.");
        const data = this.prepareData(true);
        const result = await this.searchFunction(data);

        PrintReport(result, this.printReportCss);
      } catch (error) {
        AlertError("Não foi possível gerar a impressão.");
      }
      this.isWaitingResult = false;
    },
    async downloadCsvReport() {
      try {
        this.$refs.btnExcel.setWaiting();
        if (this.isWaitingResult) {
          this.$refs.btnExcel.setStartOrFinish();
          return;
        }
        this.isWaitingResult = true;
        // if (this.pagination.total === 0)
        //   throw new Error("Nenhum registro a ser gerado.");
        const data = this.prepareData(false, true);
        const result = await this.searchFunction(data);
        DownloadFile(result.csv, "csv", result.fileName);
      } catch (error) {
        AlertError(error.message);
      }
      this.isWaitingResult = false;
      this.$refs.btnExcel.setStartOrFinish();
    },
    async downloadExcelReport() {
      try {
        this.$refs.btnExcel.setWaiting();
        if (this.isWaitingResult) {
          this.$refs.btnExcel.setStartOrFinish();
          return;
        }
        this.isWaitingResult = true;
        if (this.pagination.total === 0)
          throw new Error("Nenhum registro a ser gerado.");
        const data = this.prepareData(false, false, true);
        const result = await this.searchFunction(data);

        DownloadFile(result.excel, "xlsx", result.fileName);
      } catch (error) {
        AlertError(error.message);
      }
      this.isWaitingResult = false;
      this.$refs.btnExcel.setStartOrFinish();
    },

    itemActionExport() {
      return [
        {
          label: 'Arquivo no Formato ".XLSX"',
          action: this.downloadExcelReport,
          disable: !this.isFilterFormValid || this.isWaitingResult,
        },
        {
          label: 'Arquivo no Formato ".CSV"',
          action: this.downloadCsvReport,
          disable: !this.isFilterFormValid || this.isWaitingResult,
        },
      ];
    },
    goToNew() {
      this.$router.push(this.newUrl);
    },
    // moduleBoxContentClick(pValue) {
    //   if (this.isRetractable) this.$refs.filterbox.toggleCollapse(null, true);
    // },
    clearPagination() {
      this.countItem = 0;
      this.pagination.offset = 0;
      this.pagination.total = 0;
      this.pagination.current = 1;
      this.setLimit();
    },
    setPage(pValue) {
      this.pagination.offset = (pValue - 1) * this.pagination.limit;
      this.pagination.current = pValue;
      this.submitForm(false);
    },
    setLimit() {
      const hasItemWidth = this.itemWidth && this.itemWidth > 0;
      const hasSearchAreaHeight =
        this.searchAreaHeight && this.searchAreaHeight > 0;
      const contentHeight = hasSearchAreaHeight
        ? this.searchAreaHeight
        : this.$refs.contentList.getContentListHeight();
      const contentWidth = this.$refs.contentList.getContentListWidth();

      if (hasItemWidth) {
        const containerArea = contentHeight * contentWidth;
        const itemArea = this.itemHeight * this.itemWidth;

        this.pagination.limit = Math.floor(containerArea / itemArea);
      } else {
        this.pagination.limit = Math.floor(contentHeight / this.itemHeight);
      }
    },
    showModalHelper() {
      this.showHelper = true;
    },
    closeHelper() {
      this.showHelper = false;
    },
  },
};
</script>
