import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import state from "./state";
import createdPersistedState from "vuex-persistedstate";

const vuex = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

if (process.env.NODE_ENV !== "test") vuex.plugins = [createdPersistedState()];

export default vuex;
