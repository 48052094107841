<style src="./RgInputPis.scss" lang="scss"></style>
<template lang="html">
  <fieldset class="rg-input--component">
    <RgFormBase :label="label" :required="isRequired">
      <div slot="right-label">
        <RgValidationAlert :alert="error" />
      </div>
      <div class="rg-input--base">
        <div class="rg-input--textbox-container">
          <input
            v-model="inputValue"
            v-mask="'###.#####.##-#'"
            :placeholder="placeholder"
            :tabindex="tabIndex"
            type="text"
            @blur="validateBlur"
          />
        </div>
      </div>
    </RgFormBase>
  </fieldset>
</template>

<script>
import { mask } from "vue-the-mask";
import { RgFormBase } from "~tokio/foundation/container";
import {
  RgInputMixin,
  RgValidatorMixin,
  RgValidationAlert,
} from "~tokio/primitive";

import Pis from "./utils/RgInputPisHelper";

export default {
  name: "RgInputPis",
  components: {
    RgValidationAlert,
    RgFormBase,
  },
  directives: {
    mask,
  },
  mixins: [RgValidatorMixin, RgInputMixin],
  props: {
    label: {
      type: String,
      default: "PIS",
    },
    placeholder: {
      type: String,
      default: "000.00000.00-0",
    },
  },
  data() {
    return {
      anotherRules: {
        checkCpf: Pis.checkDigit,
      },
    };
  },
  computed: {
    isRequired() {
      return this.rules && this.rules.required;
    },
  },
  methods: {
    validateBlur() {
      this.validate();
    },
  },
};
</script>
