<style src="./RgRemoveSmall.scss" lang="scss" scoped></style>
<template lang="html">
  <div
    :class="{
      'remove-small__expanded': expandConfirmation,
      disabled: disabled,
    }"
    class="remove-small"
  >
    <button
      v-show="removeState"
      :disabled="disabled"
      data-id="icone-excluir"
      type="button"
      class="remove-small--trytoremove"
      @click.stop.prevent="tryToRemove()"
    >
      <IconGarbage />
    </button>

    <button
      v-show="!removeState"
      ref="button-confirm"
      :disabled="disabled"
      data-id="excluir-confirmar"
      type="button"
      class="remove-small--confirm-remove"
      @blur="lostFocus"
      @click.stop.prevent="confirmRemove()"
    >
      <IconGarbage />
      <span>Remover</span>
    </button>
  </div>
</template>

<script>
import { IconGarbage } from "~tokio/primitive/icon/symbols";

export default {
  name: "RgRemoveSmall",
  components: {
    IconGarbage,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    dataId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      removeState: true,
      expandConfirmation: false,
    };
  },
  methods: {
    tryToRemove() {
      this.removeState = false;
      this.expandConfirmation = true;
      this.$nextTick(() => {
        this.$refs["button-confirm"].focus();
      });
    },
    confirmRemove() {
      this.expandConfirmation = false;
      this.removeState = true;
      this.$emit("confirm-remove");
    },
    lostFocus() {
      this.expandConfirmation = false;
      this.removeState = true;
    },
  },
};
</script>
