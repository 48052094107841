import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query {
    notificationHomologation {
      rows {
        noh_nome
        noh_id
        noh_cpf
        noh_celular
        noh_criado_em
        noh_alterado_em
      }
      count
    }
  }
`;

export default async ({ rootState }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data?.notificationHomologation?.rows;
  } catch (err) {
    return Promise.reject(err.graphQLErrors);
  }
};
