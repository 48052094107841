import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query($ils_id: Int, $ils_codigo: String, $ils_nome: String) {
    hospitalizationBedSpecialty(
      ils_id: $ils_id
      ils_codigo: $ils_codigo
      ils_nome: $ils_nome
    ) {
      rows {
        ils_id
        ils_nome
        ils_codigo
      }
    }
  }
`;

export default async (variables, findOne = false) => {
  const { data } = await GraphQLClient.query({ query, variables }).catch(
    (err) => {
      console.error(err);
    },
  );
  if (!findOne) {
    return data.hospitalizationBedSpecialty.rows;
  } else {
    return data.hospitalizationBedSpecialty.rows[0];
  }
};
