<style src="./RgRadioExamType.scss" lang="scss" scoped></style>
<template lang="html">
  <fieldset class="rg-radio-exam-type">
    <span class="label">Origem do exame</span>
    <div class="container">
      <div
        :class="{
          'active-hospitalization': selectedStatus.includes(
            EXAM_TYPES.INTERNACAO,
          ),
        }"
        data-id="internacao"
        class="radio show-hospitalization"
        @click="toggleStatus(EXAM_TYPES.INTERNACAO)"
      >
        Internação
      </div>
      <div
        :class="{
          'active-emergency': selectedStatus.includes(EXAM_TYPES.EMERGENCIA),
        }"
        data-id="emergencia"
        class="radio show-emergency"
        @click="toggleStatus(EXAM_TYPES.EMERGENCIA)"
      >
        Emergência
      </div>
      <div
        :class="{
          'active-scheduled': selectedStatus.includes(EXAM_TYPES.AGENDADO),
        }"
        data-id="agendado"
        class="radio show-scheduled"
        @click="toggleStatus(EXAM_TYPES.AGENDADO)"
      >
        Agendado
      </div>
    </div>
  </fieldset>
</template>

<script>
import EXAM_TYPES from "./GetExamTypes";

export default {
  name: "RgRadioExamType",
  props: {
    value: {
      default: null,
    },
  },
  data() {
    return {
      selectedStatus: Object.values(EXAM_TYPES),
    };
  },
  computed: {
    EXAM_TYPES() {
      return { ...EXAM_TYPES };
    },
  },
  watch: {
    value(pValue) {
      this.selectedStatus = pValue;
    },
  },
  mounted() {
    this.selectedStatus = this.value || Object.values(EXAM_TYPES);
  },
  methods: {
    toggleStatus(pItem) {
      if (this.selectedStatus.includes(pItem)) {
        this.selectedStatus.splice(this.selectedStatus.indexOf(pItem), 1);
      } else {
        this.selectedStatus.push(pItem);
      }
      this.$emit("input", this.selectedStatus);
    },
  },
};
</script>
