<style src="./BulletinList.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="bulletin-list">
    <ListContent ref="listCnt" @click="billingId" />
  </section>
</template>

<script>
import ListContent from "../list-content/ListContent";
export default {
  name: "BulletinList",
  components: {
    ListContent,
  },
  data() {
    return {};
  },
  computed: {
    hasPermission() {
      return this.$Permissions.global.has(
        "faturamento.atendimentosEmergencia",
        this.$store.getters["Login/GET_UNI_HEALTH_ID"],
      );
    },
  },
  methods: {
    billingId({ bolId, bofId }) {
      if (bolId) {
        const BulletinNumber = this.$store.getters[
          "Billing/Bulletin/GET_BULLETIN_LIST"
        ].filter((item) => item.bol_id === bolId)[0].bol_numero;
        this.$store.commit(
          "Billing/Bulletin/SELECT_BULLETIN_NUMBER",
          BulletinNumber,
        );
        this.$store.dispatch(
          "Billing/Bulletin/SELECT_BULLETIN_TO_BILLING",
          bolId,
        );
      } else if (bofId) {
        this.$store.commit("Billing/Bulletin/SELECT_BOF_ID", bofId);
      }
      this.$router.push("/billing/billing");
    },
    clearContentList() {
      this.$refs.listCnt.clearList();
    },
  },
};
</script>
