<template lang="html">
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 480 480"
    style="enable-background: new 0 0 480 480"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M416.004,32c-0.001,0-0.003,0-0.004,0H312V8c0.001-4.417-3.579-7.999-7.996-8c-0.001,0-0.003,0-0.004,0H176
        c-4.417-0.001-7.999,3.579-8,7.996c0,0.001,0,0.003,0,0.004v24H64c-4.417-0.001-7.999,3.579-8,7.996c0,0.001,0,0.003,0,0.004v432
        c-0.001,4.417,3.579,7.999,7.996,8c0.001,0,0.003,0,0.004,0h352c4.417,0.001,7.999-3.579,8-7.996c0-0.001,0-0.003,0-0.004V40
        C424.001,35.583,420.421,32.001,416.004,32z M184,16h112v48H184V16z M408,464H72V48h96v24c-0.001,4.417,3.579,7.999,7.996,8
        c0.001,0,0.003,0,0.004,0h128c4.417,0.001,7.999-3.579,8-7.996c0-0.001,0-0.003,0-0.004V48h96V464z"
        />
      </g>
    </g>
    <g>
      <g>
        <rect x="88" y="368" width="176" height="16" />
      </g>
    </g>
    <g>
      <g>
        <rect x="280" y="368" width="112" height="16" />
      </g>
    </g>
    <g>
      <g>
        <rect x="88" y="400" width="304" height="16" />
      </g>
    </g>
    <g>
      <g>
        <rect x="88" y="432" width="128" height="16" />
      </g>
    </g>
    <g>
      <g>
        <rect x="232" y="432" width="160" height="16" />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M144.004,232c-0.001,0-0.003,0-0.004,0H96c-4.417-0.001-7.999,3.579-8,7.996c0,0.001,0,0.003,0,0.004v104
        c-0.001,4.417,3.579,7.999,7.996,8c0.001,0,0.003,0,0.004,0h48c4.417,0.001,7.999-3.579,8-7.996c0-0.001,0-0.003,0-0.004V240
        C152.001,235.583,148.421,232.001,144.004,232z M136,336h-32v-88h32V336z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M224.004,200c-0.001,0-0.003,0-0.004,0h-48c-4.417-0.001-7.999,3.579-8,7.996c0,0.001,0,0.003,0,0.004v136
        c-0.001,4.417,3.579,7.999,7.996,8c0.001,0,0.003,0,0.004,0h48c4.417,0.001,7.999-3.579,8-7.996c0-0.001,0-0.003,0-0.004V208
        C232.001,203.583,228.421,200.001,224.004,200z M216,336h-32V216h32V336z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M304.004,168c-0.001,0-0.003,0-0.004,0h-48c-4.417-0.001-7.999,3.579-8,7.996c0,0.001,0,0.003,0,0.004v168
        c-0.001,4.417,3.579,7.999,7.996,8c0.001,0,0.003,0,0.004,0h48c4.417,0.001,7.999-3.579,8-7.996c0-0.001,0-0.003,0-0.004V176
        C312.001,171.583,308.421,168.001,304.004,168z M296,336h-32V184h32V336z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M384.004,136c-0.001,0-0.003,0-0.004,0h-48c-4.417-0.001-7.999,3.579-8,7.996c0,0.001,0,0.003,0,0.004v200
        c-0.001,4.417,3.579,7.999,7.996,8c0.001,0,0.003,0,0.004,0h48c4.417,0.001,7.999-3.579,8-7.996c0-0.001,0-0.003,0-0.004V144
        C392.001,139.583,388.421,136.001,384.004,136z M376,336h-32V152h32V336z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M382.43,80.156l-80,16c-1.024,0.205-1.998,0.609-2.867,1.188L229.578,144H160c-1.499,0-2.968,0.422-4.238,1.219l-64,40
        l8.477,13.563L162.293,160H232c1.579-0.001,3.123-0.468,4.438-1.344l70.684-47.125l78.449-15.688L382.43,80.156z"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "Report",
};
</script>
