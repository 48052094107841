import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (
  context,
  { intIdPacienteConsulta, intIdUnidadeSaude },
) => {
  return AuthLegacyRequest.post(
    "consultas/controller-agendamento-consulta/carregar-ficha-acompanhamento-paciente",
    { intIdPacienteConsulta, intIdUnidadeSaude },
  )
    .then(({ data }) => {
      const scheduleErrors = {
        PacienteConsultaException: `Erro ao carregar a ficha de acompanhamento do paciente!`,
      };

      return HandleResponse(data, {
        extraExceptionsErrors: scheduleErrors,
      });
    })
    .catch((pErr) => {
      throw pErr;
    });
};
