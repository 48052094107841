<style src="./RgInputDebounce.scss" lang="scss"></style>
<template lang="html">
  <fieldset class="rg-input--component">
    <RgFormBase :label="label">
      <div class="rg-input--base">
        <div class="rg-input--textbox-container">
          <RgValidationAlert :alert="error" />
          <input
            v-model="inputValue"
            v-debounce-directive="debounce"
            :placeholder="placeholder"
            type="text"
            @debounced="dispatchModel()"
            @blur="validateBlur"
          />
        </div>
      </div>
    </RgFormBase>
  </fieldset>
</template>
<script>
import { RgFormBase } from "~tokio/foundation/container";
import { RgValidatorMixin, RgValidationAlert } from "../../validation";
import { DebounceDirective } from "../directive/debounce/Debounce";
import RgInputMixin from "../mixin/RgInputMixin";

export default {
  name: "RgInputDebounce",
  components: {
    RgValidationAlert,
    RgFormBase,
  },
  directives: { debounceDirective: DebounceDirective },
  mixins: [RgValidatorMixin, RgInputMixin],
  props: {
    debounce: {
      type: String,
      default: "200",
    },
  },
  data() {
    return {};
  },
  methods: {
    dispatchModel() {
      // this.$emit('input', this.inputValue)
      this.$emit("debounced", this.inputValue);
    },
    validateBlur() {
      this.validate();
    },
  },
};
</script>
