<style src="./RgInputRg.scss" lang="scss"></style>
<template lang="html">
  <fieldset class="rg-input--component">
    <RgFormBase :label="label">
      <div class="rg-input--base">
        <div class="rg-input--textbox-container">
          <RgValidationAlert :alert="error" />
          <input
            v-model="inputValue"
            v-mask="'##.###.###-#'"
            placeholder="00.000.000-0"
            type="text"
            @blur="validateBlur"
          />
        </div>
      </div>
    </RgFormBase>
  </fieldset>
</template>

<script>
import { RgFormBase } from "~tokio/foundation/container";
import { RgValidatorMixin, RgValidationAlert } from "../../validation";
import RgInputMixin from "../mixin/RgInputMixin";
import { mask } from "vue-the-mask";

export default {
  name: "RgInputRg",
  components: {
    RgValidationAlert,
    RgFormBase,
  },
  directives: {
    mask,
  },
  mixins: [RgValidatorMixin, RgInputMixin],
  props: {
    label: {
      default: "Número RG",
    },
  },
  data() {
    return {};
  },
  methods: {
    validateBlur() {
      this.validate();
    },
  },
};
</script>
