<style
  src="./RgRadioHospitalizationFilterStatusOption.scss"
  lang="scss"
  scoped
></style>
<template>
  <div class="rg-radio-hospitalization-filter-status-option">
    <span class="label">{{ label }} da {{ type }}</span>
    <div v-if="!aih" class="container">
      <div
        :class="{ 'active-open': selectedStatus.includes('0') }"
        class="radio show-open"
        @click="addStatus('0')"
      >
        Aberto
      </div>
      <div
        :class="{ 'active-billed': selectedStatus.includes('1') }"
        class="radio show-billed"
        @click="addStatus('1')"
      >
        Faturado
      </div>
    </div>
    <div v-else class="container">
      <div
        :class="{ 'active-open': selectedStatus.includes('0') }"
        class="radio show-open"
        @click="addStatus('0')"
      >
        Aberto
      </div>
      <div
        :class="{ 'active-billed': selectedStatus.includes('1') }"
        class="radio show-billed"
        @click="addStatus('1')"
      >
        Faturado
      </div>
      <div
        :class="{ 'active-exported': selectedStatus.includes('2') }"
        class="radio show-exported"
        @click="addStatus('2')"
      >
        Exportado
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RgRadioHospitalizationFilterStatusOption",
  props: {
    label: {
      default: "Status",
    },
    aih: {
      type: Boolean,
      default: false,
    },
    value: {
      default: function () {
        return "0";
      },
    },
  },
  data() {
    return {
      selectedStatus: "0",
    };
  },
  computed: {
    type() {
      return this.aih ? "AIH" : "Internação";
    },
  },
  watch: {
    value(pValue) {
      this.selectedStatus = pValue;
    },
  },
  methods: {
    addStatus(pItem) {
      this.selectedStatus = pItem;
      this.$emit("input", this.selectedStatus);
    },
  },
};
</script>
