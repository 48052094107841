import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query History($pes_id: Int, $limit: Int, $offset: Int, $searchData: String) {
    HistoryPatient(
      pes_id: $pes_id
      limit: $limit
      offset: $offset
      searchData: $searchData
    ) {
      rows {
        pes_nascimento
        pes_nome
        pes_email
        pes_id
        pes_id_usuarios
        pes_mae
        pes_nome_social
        pes_obs
        pes_pai
        pes_profissao
        psl_data_log
        usu_nome
        tlp_nome
        uns_nome
        gender
        address
        telephones
        gender
        religion
        maritalStatus
        ethnicity
        nationality
        scholarity
        jobStatus
        cnh
        cns
        cpf
        rg
        pisPasep
        weddingCertificate
        birthCertificate
        newBirthCertificate
        ctps
        voterTitle
        foreign
        bloodType
        city
        user
      }
      count
    }
  }
`;
const formatData = (pFilters) => {
  const filters = { ...pFilters };
  return filters;
};

export default async ({ commit }, filters = {}) => {
  try {
    const variables = formatData(filters);

    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "network-only",
    });

    return data.HistoryPatient;
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors[0].message);
  }
};
