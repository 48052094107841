<template lang="html">
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 511.999 511.999"
    style="enable-background: new 0 0 511.999 511.999"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M128.314,318.534c-4.043-3.763-10.37-3.536-14.133,0.506l-0.085,0.092c-3.763,4.042-3.536,10.37,0.506,14.133
      c1.926,1.793,4.372,2.68,6.811,2.68c2.68,0,5.353-1.071,7.322-3.186l0.085-0.092C132.583,328.625,132.356,322.297,128.314,318.534
      z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M211.613,235.746c-3.906-3.905-10.237-3.904-14.142,0l-59.737,59.736c-3.905,3.905-3.905,10.237,0,14.142
      c1.953,1.953,4.511,2.929,7.071,2.929c2.559,0,5.119-0.976,7.071-2.929l59.737-59.736
      C215.518,245.983,215.518,239.651,211.613,235.746z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M509.072,85.584L426.413,2.928c-3.905-3.905-10.237-3.904-14.142,0L385.02,30.179c-3.905,3.905-3.905,10.237,0,14.142
      l13.751,13.751l-65.719,65.719l-45.617-45.618c-1.875-1.875-4.419-2.929-7.071-2.929c-2.652,0-5.196,1.054-7.071,2.929
      l-22.605,22.605c-3.905,3.905-3.905,10.237,0,14.142l9.768,9.768L64.428,320.717c-3.905,3.905-3.905,10.237,0,14.143
      l29.089,29.089l-8.143,48.536L2.93,494.928c-3.905,3.905-3.905,10.237,0,14.143c1.952,1.952,4.512,2.928,7.071,2.928
      s5.119-0.976,7.071-2.929l82.51-82.51l47.709-8.838l29.848,29.848c1.953,1.953,4.512,2.929,7.071,2.929
      c2.559,0,5.119-0.977,7.071-2.929l196.028-196.026l9.768,9.768c1.953,1.953,4.512,2.929,7.071,2.929
      c2.559,0,5.119-0.977,7.071-2.929l22.605-22.605c1.875-1.875,2.929-4.419,2.929-7.071c0-2.652-1.054-5.196-2.929-7.071
      l-45.619-45.62l65.719-65.719l13.75,13.751c1.953,1.953,4.512,2.929,7.071,2.929c2.559,0,5.119-0.977,7.071-2.929l27.253-27.252
      c1.875-1.875,2.929-4.419,2.929-7.071C511.998,90.002,510.947,87.459,509.072,85.584z M106.933,404.858l3.95-23.544l19.247,19.247
      L106.933,404.858z M284.509,326.062l-21.446-21.447c-3.906-3.906-10.237-3.904-14.142,0c-3.905,3.905-3.906,10.237,0,14.142
      l21.446,21.447l-20.122,20.122l-49.284-49.285c-3.906-3.905-10.237-3.905-14.142,0c-3.905,3.905-3.906,10.237,0,14.142
      l49.284,49.285l-20.07,20.07l-21.446-21.446c-3.905-3.905-10.237-3.905-14.143,0c-3.906,3.905-3.905,10.237,0,14.143
      l21.446,21.446l-17.679,17.677l-26.479-26.479c-0.006-0.006-0.01-0.013-0.016-0.019l-46.413-46.412
      c-0.004-0.004-0.008-0.006-0.011-0.01l-25.65-25.65L206.4,207.029l98.571,98.571L284.509,326.062z M353.377,257.193
      l-21.447-21.447c-3.905-3.904-10.237-3.904-14.143,0c-3.905,3.905-3.905,10.237,0,14.143l21.447,21.447l-20.121,20.121
      l-98.571-98.571l54.054-54.055l98.571,98.572L353.377,257.193z M412.613,231.637l-8.463,8.463L271.901,107.849l8.463-8.463
      l72.88,72.881l13.749,13.75c0.003,0.003,0.007,0.006,0.011,0.01L412.613,231.637z M374.064,164.804l-1.75-1.75l-25.118-25.12
      l65.716-65.716l25.862,25.858l1.01,1.01L374.064,164.804z M474.747,105.765l-13.739-13.739c-0.004-0.004-0.008-0.009-0.012-0.013
      l-8.738-8.737L406.233,37.25l13.109-13.109l68.516,68.514L474.747,105.765z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "Syringe2",
};
</script>
