import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

export default async ({ commit }, { personId, status }) => {
  const variables = { personId, status };

  return new Promise((resolve, reject) => {
    const mutationName = status ? "EnablePerson" : "DisablePerson";
    const mutation = mutationBuild(mutationName);

    GraphQLClient.mutate({ mutation, variables })
      .then((pRes) => {
        commit("TOGGLE_PERSON_STATUS_BY_PES_ID", { personId, status });
        const result = pRes.data[mutationName];
        resolve(result);
      })
      .catch((pErr) => {
        reject(pErr.graphQLErrors);
      });
  });
};

const mutationBuild = (mutationName) => {
  return gql`mutation($patientId: Int) {
    ${mutationName}(pes_id: $patientId) {
      pes_id
      pes_ativo
    }
  }`;
};
