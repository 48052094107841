import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async ({
  uns_id,
  intIdSubModulo,
  blnSomenteAtivo,
  intIdOperacao,
}) => {
  return AuthLegacyRequest.post(
    "unidadesaude/controller-setor/combo-box-setores-por-usuario-logado",
    { uns_id, intIdSubModulo, blnSomenteAtivo, intIdOperacao },
  )
    .then(({ data }) => {
      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
