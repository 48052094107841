import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query OccupationName($occupationName: String) {
    occupationsEsus(ocp_nome: $occupationName) {
      rows {
        ocp_id
        ocp_nome
      }
    }
  }
`;

export default async ({ variables }) => {
  const { data } = await GraphQLClient.query({ query, variables }).catch(
    (err) => {
      console.error(err);
    },
  );
  return data.occupationsEsus.rows;
};
