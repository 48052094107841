<template lang="html">
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8" cy="8" r="7.5" :fill="color" stroke="white" />
  </svg>
</template>

<script>
export default {
  name: "CircleTable",
  props: {
    color: {
      type: String,
      default: "",
    },
  },
};
</script>
