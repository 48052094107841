<style src="./SurgeryDate.scss" lang="scss" scoped></style>
<template lang="html">
  <PrintFrameInfo label="Data Prevista para Cirurgia">
    <div class="row">
      <div class="col-4 frame-fieldset">
        <span class="fieldset-label">Data</span>
        <span class="fieldset-value">{{
          surgeryData.cci_data_inicio | formatDate
        }}</span>
      </div>

      <div class="col-4 frame-fieldset">
        <span class="fieldset-label">Hora </span>
        <span class="fieldset-value">{{
          surgeryData.cci_hora_inicio | formatHour
        }}</span>
      </div>

      <div class="col-4 frame-fieldset">
        <span class="fieldset-label">Duração</span>
        <span class="fieldset-value">{{
          surgeryData.cci_duracao | formatHour
        }}</span>
      </div>
    </div>
  </PrintFrameInfo>
</template>

<script>
import PrintFrameInfo from "../print-frame-info/PrintFrameInfo";
export default {
  name: "SurgeryDate",
  components: {
    PrintFrameInfo,
  },
  filters: {
    formatHour(pValue) {
      if (pValue && pValue.length === 8) return pValue.substr(0, 5);
    },
    formatDate(pValue) {
      return new Date(pValue).toLocaleDateString();
    },
  },
  props: {
    surgeryData: {
      default() {},
    },
  },
};
</script>
