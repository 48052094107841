<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchTypeTime from "./action/SearchTypeTime";
export default {
  name: "RgComboboxTypeScale",
  mixins: [RgComboboxMixin],
  props: {
    unitHealthId: Number,
    userId: Number,
  },

  data: () => {
    return {};
  },

  watch: {
    unitHealthId(pValue) {
      if (pValue) {
        this.fillData();
      }
    },
    userId(pValue) {
      if (pValue) {
        this.fillData();
      }
    },
  },

  mounted() {
    this.fillData();
  },

  methods: {
    async fillData() {
      if (!this.unitHealthId || !this.userId) {
        return;
      }

      const data = await SearchTypeTime({
        uns_id: this.unitHealthId,
        usu_id: this.userId,
      });

      this.mData = [];

      data.forEach((item) => {
        this.mData.push({ value: item.data, text: item.label });
      });
    },

    getList() {
      return this.mData;
    },
  },
};
</script>
