<template>
  <div class="appointment-schedule-per-day">
    <RgSearch
      ref="rgsearch"
      v-model="mutableappointmentPerDay"
      :search-function="searchFilter"
      :clear-function="cleanForm"
      :build-filter="generateFilter"
      :show-footer="false"
      :show-empty-message="false"
      :showTitle="false"
      :infiniteLimit="true"
      :disabledShorkey="modalConfirmSchedulingDeletion"
      @afterPerformSearch="afterSearchFilter"
      @count="getCountValue"
    >
      <div slot="filters" class="appointment-schedule-per-day-filter">
        <div class="grid">
          <div class="selectinput">
            <RgComboboxUnithealth
              v-model="form.selectUnity"
              :rules="{ required: true }"
              permission="consulta.agendamento"
              class="inputitem"
              disabled
            />
          </div>

          <div class="selectinput">
            <RgComboboxSectorBondAndPermission
              v-model="form.selectedSector"
              :rules="{ required: true }"
              :disabled="!hideSector"
              :unit-health-id="form.selectUnity"
              :submodule-id="subModuleId"
              class="inputitem"
            />
          </div>

          <div class="selectinput">
            <RgInputDate
              ref="date"
              v-model="form.date"
              :rules="{ required: true }"
              placeholder="dd/mm/aaaa"
              data-id="data"
              label="Data"
              class="inputitem"
            />
          </div>
        </div>
      </div>

      <div
        v-if="mutableappointmentPerDay.length > 0"
        class="appointment-schedule-per-day-search"
      >
        <div class="content-grid">
          <legend class="legend">Profissionais</legend>

          <div class="table-one">
            <SmartTable
              ref="smartTableEmployee"
              name="AppointmentSchedulePerDayEmployee"
              :columns="COLUMN_TABLE_ONE"
              :body="mutableappointmentPerDay"
              :total="Number(totalAgenda)"
              :initial-columns="7"
              :show-pagination="false"
              :colorLine="actionColorLine"
              index-column="eag_id"
              toggleSelected
              @getLine="selectEmployeeData"
            >
              <div slot="legends" class="legends">
                <div class="circle --grey" />
                <span class="legend">Sem vagas disponíveis</span>
                <div class="circle --red" />
                <span class="legend">Inabilitado no dia</span>
              </div>
            </SmartTable>
          </div>

          <div class="painel-button">
            <RgNewScheduleButton
              :disabled="!canSchedulePatient"
              title="Agendar paciente"
              class="buttons-top"
              @click="scheduleAction"
            />
            <RgEditButton
              :disabled="!scheduleSelected"
              title="Editar escala de atendimento"
              class="buttons-top"
              @click="openModalChangeProfessionalScale"
            />
            <RgPrinterButton
              :disabled="!scheduleSelected || disablePrint"
              title="Imprimir ficha de controle ambulatorial"
              class="buttons-top"
              @click="printOutpatientConsultationControl"
            />
          </div>

          <div class="separator" />

          <legend class="legend">Pacientes Agendados para o Dia</legend>

          <div class="table-two">
            <SmartTable
              ref="smartTableSchedule"
              name="AppointmentSchedulePerDayAppointment"
              :columns="COLUMN_TABLE_TWO"
              :body="mutableAppointmentSchedule.data"
              :total="Number(mutableAppointmentSchedule.total)"
              :show-pagination="false"
              toggleSelected
              @getLine="getAppointmentsRow"
            />
          </div>

          <div class="painel-button">
            <RgEditButton
              :disabled="appointmentsSelected"
              class="buttons-top"
              title="Editar o agendamento"
              @click="editScheduledAppointment"
            />

            <RgShowButton
              :disabled="appointmentsSelected"
              class="buttons-top"
              title="Detalhes"
              @click="openModalAppointmentDetails"
            />

            <RgDeleteScheduleButton
              :disabled="appointmentsSelected"
              title="Excluir agendamento"
              class="buttons-top"
              @click="openModalConfirmSchedulingDeletion"
            />

            <RgPersonCardButton
              :disabled="appointmentsSelected"
              title="Editar paciente"
              class="buttons-top"
              @click="patientRecord"
            />

            <RgPersonDropdown
              :disabled="appointmentsSelected"
              :item="appointmentsData || {}"
              :action-options="itemActionOptionsPerson(appointmentsData)"
              show-up
              show-left
              class="dropdown-top"
            />

            <RgDropdownPrinter
              :disabled="appointmentsSelected"
              :item="appointmentsData || {}"
              :action-options="itemActionOptionsPrinter(appointmentsData)"
              show-up
              show-left
              class="dropdown-top"
            />

            <RgDropdownPep
              :disabled="appointmentsSelected"
              :item="appointmentsData || {}"
              :action-options="itemActionOptionsPep(appointmentsData)"
              show-up
              show-left
              class="dropdown-top"
            />
          </div>
        </div>
      </div>

      <div v-else class="no-result">
        <IconEmpty />
        <span class="message">
          Não foram encontrados resultados para essa busca
        </span>
      </div>
    </RgSearch>

    <ModalChangeProfessionalScale
      v-if="modalChangeProfessionalScale"
      ref="modalChangeProfessionalScale"
      :show="modalChangeProfessionalScale"
      :data="employeeData || {}"
      :technical-scale-permission="technicalScalePermission"
      :normal-scale-permission="normalScalePermission"
      @reSearch="researchFirstTable"
      @close="closeModalChangeProfessionalScale"
      @error="handleErrorChangeProfessionalScale"
    />

    <ModalConfirmDeletion
      v-bind="propsModalConfirmDeletion"
      @close="closeModalConfirmSchedulingDeletion"
      @reSearch="researchFirstTable"
    />

    <ModalPatientImageInRow
      ref="modalPatientImageInRow"
      :show="modalPatientImageInRow"
      :fill-id="filId"
      :modId="appointmentModuleId"
      hideButtons
      @close="closeModalPatientImageInRow"
    />

    <ModalTypesPrint
      ref="modalTypePrint"
      :show="modalTypesPrint"
      :appointment-id="appointmentIdToPrint"
      @close="closeModalTypesPrint"
    />

    <ModalAppointmentDetails
      :show="modalAppointmentDetails"
      :pcc-id="appointmentsData ? appointmentsData.pcc_id : 0"
      :appointment-id="appointmentsData ? appointmentsData.con_id : 0"
      :unity-health="
        appointmentsData ? appointmentsData.tcu_id_unidades_saude : 0
      "
      @close="closeModalAppointmentDetails"
    />

    <ModalScaleConflict
      ref="modalScaleConflict"
      :show="showModalScaleConflict"
      :conflicts="scaleConflicts"
      :history="scaleHistory"
      @close="actionCloseModalScaleConflict"
    />
  </div>
</template>

<script>
import {
  RgComboboxUnithealth,
  RgComboboxSectorBondAndPermission,
  RgInputDate,
  RgPrinterButton,
  RgEditButton,
  RgNewScheduleButton,
  RgDeleteScheduleButton,
  RgPersonCardButton,
  RgPersonDropdown,
  RgShowButton,
  IconEmpty,
  RgDropdownPrinter,
} from "~tokio/primitive";

import RgSearch from "~tokio/foundation/rg-search/RgSearch";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";

import {
  ModalAppointmentDetails,
  ModalChangeProfessionalScale,
  ModalTypesPrint,
  ModalScaleConflict,
} from "$appointment/submodules/schedule/component/";

import {
  ModalPatientImageInRow,
  ModalConfirmDeletion,
} from "~tokio/primitive/modal";
import RgDropdownPep from "$hospitalization/hospitalizations/component/button/rg-dropdown-pep/RgDropdownPep";
import {
  printThermalOneWay,
  printThermalTwoWay,
  printScheduleVoucher,
} from "$appointment/common/utils/printsScheduleVoucherAppointment";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb.js";
import moment from "moment";
import ScheduleHealthcareBulletinHTML from "~tokio/primitive/html/ScheduleHealthcareBulletinHTML.js";
import ScheduleOutpatientConsultationControlHTML from "$appointment/submodules/schedule/html/ScheduleOutpatientConsultationControlHTML";
import SchedulePatientFollowUpSheetHTML from "$appointment/submodules/schedule/html/SchedulePatientFollowUpSheetHTML";
import { AlertError } from "~tokio/primitive/notification";
import GetColorRowSmartTable from "~common/utils/GetColorRowSmartTable";

export default {
  name: "AppointmentSchedulePerDay",
  components: {
    RgSearch,
    RgComboboxUnithealth,
    RgComboboxSectorBondAndPermission,
    RgInputDate,
    SmartTable,
    ModalChangeProfessionalScale,
    RgPrinterButton,
    RgEditButton,
    RgNewScheduleButton,
    RgDropdownPep,
    RgDropdownPrinter,
    RgDeleteScheduleButton,
    RgPersonCardButton,
    RgPersonDropdown,
    ModalConfirmDeletion,
    ModalPatientImageInRow,
    ModalTypesPrint,
    RgShowButton,
    ModalAppointmentDetails,
    ModalScaleConflict,
    IconEmpty,
  },
  data() {
    return {
      mutableappointmentPerDay: [],
      employeeData: null,
      appointmentsData: null,
      mutableAppointmentSchedule: {
        data: [],
        total: 0,
      },
      form: {
        selectUnity: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        selectedSector: null,
        date: moment().format("DD/MM/YYYY"),
      },
      totalAgenda: 0,
      filId: null,
      items: [],
      disablePrint: false,
      modalChangeProfessionalScale: false,
      modalConfirmSchedulingDeletion: false,
      modalPatientImageInRow: false,
      modalObservationPatientRegulationQueue: false,
      modalAppointmentDetails: false,
      payloadAppointmentSchedule: {},
      modalTypesPrint: false,
      technicalScalePermission: 1,
      normalScalePermission: 1,
      appointmentId: [],
      showModalScaleConflict: false,
      scaleConflicts: [],
      scaleHistory: [],
    };
  },

  computed: {
    propsModalConfirmDeletion() {
      const maxlength = 250;
      const subtitle = "A operação não poderá ser desfeita";
      const show = this.modalConfirmSchedulingDeletion;
      const confirm = (reason) => this.deleteScheduling(reason);

      return { show, confirm, maxlength, subtitle };
    },

    subModuleId() {
      return 5;
    },

    actionColorLine() {
      return {
        get: (item) => {
          if (
            parseInt(item.funcionario_inabilitado) === 1 ||
            parseInt(item.hag_atendimento) === 0
          ) {
            return GetColorRowSmartTable("red");
          }
          if (parseInt(item.eag_quantidade_restantes) === 0) {
            return GetColorRowSmartTable("grey");
          }
        },
      };
    },

    appointmentModuleId() {
      return this.$store.state.Login.route_module_map.appointment;
    },

    hideSector() {
      return this.form.selectUnity > 0;
    },

    scheduleSelected() {
      return this.employeeData !== null;
    },

    hasFreeSchedule() {
      return (
        this.employeeData &&
        Number(this.employeeData.eag_quantidade_restantes) > 0
      );
    },

    appointmentsSelected() {
      return !this.employeeData || !this.appointmentsData;
    },

    appointmentIdToRemove() {
      return this.appointmentsData && this.appointmentsData.con_id
        ? Number(this.appointmentsData.con_id)
        : 0;
    },

    canSchedulePatient() {
      return this.scheduleSelected && this.hasPermissionsToSchedule();
    },

    selectedEmployee() {
      return this.$store.getters["Appointment/Schedule/GET_SELECTED_EMPLOYEE"];
    },

    hasPermissionSendAppointmentPep() {
      return !!this.$Permissions.sector.has(
        "consulta.integracaoPep.enviarAtendimento",
        this.employeeData.set_id,
      );
    },

    hasPermissionCancelAppointmentPep() {
      return !!this.$Permissions.sector.has(
        "consulta.integracaoPep.enviarCancelamentoAtendimento",
        this.employeeData.set_id,
      );
    },

    appointmentIdToPrint: {
      get: function () {
        return this.appointmentId;
      },
      set: function (newValue) {
        this.appointmentId = [];
        this.appointmentId.push(Number(newValue));
      },
    },

    preferences() {
      const preferences = {
        blockedRetroactiveSendingToPep: !!Number(
          this.$store.getters["Login/GET_PREFERENCES"][
            "tViewMarcacaoConsulta.con_bloquear_envio_retroativo_pep"
          ],
        ),
        printOptionsPreference: this.$store.getters["Login/GET_PREFERENCES"][
          "tViewBuscaAgendamentoConsultaDia.opcoesImpressao"
        ],
      };

      return preferences;
    },
  },

  mounted() {
    const isAppontmentSchedule = this.$route.params.isAppontmentSchedule;
    if (isAppontmentSchedule) {
      this.viewAppointmentScheduleVoucher(this.$route.params.appointmentId);
    }
    this.fillFilter();
  },

  beforeCreate() {
    this.COLUMN_TABLE_ONE = [
      { name: "Profissional", key: "pes_nome" },
      { name: "Ocupação", key: "ocp_nome" },
      { name: "Início", key: "eag_inicio_atendimentos" },
      { name: "Qtd. de Horários", key: "eag_quantidade_consultas" },
      { name: "Qtd. Ocupada", key: "eag_quantidade_marcadas" },
      { name: "Qtd. Livre", key: "eag_quantidade_restantes" },
      { name: "Tipo de Escala", key: "type" },
    ];

    this.COLUMN_TABLE_TWO = [
      { name: "Hora", key: "con_hora" },
      { name: "Paciente", key: "pes_nome" },
      { name: "Objetivo", key: "tcu_novo_tipo_consulta" },
      { name: "Status Meu Esus", key: "sme_nome" },
      { name: "Enviado PEP", key: "enviado" },
    ];
  },

  created() {
    this.PERMISSION_APPOINTMENT_SCHEDULE_PER_DAY_SHOWS = 20;
  },

  destroyed() {
    const existThisRouteSchedulePerDays = ValidateIfRouteExistInBreadscrumb(
      "/appointment/schedule/per-day",
      this.$route.meta.breadcrumb,
    );

    if (!existThisRouteSchedulePerDays) {
      this.$store.commit(
        "Appointment/Schedule/RESET_FILTER_APPOINTMENT_PER_DAY",
      );
      this.$store.commit("Appointment/Schedule/UNSELECT_APPOINTMENT_DATA");
      this.$store.commit("Appointment/Schedule/UNSELECT_EMPLOYEE_DATA");
    }
  },
  methods: {
    async fillFilter() {
      try {
        const existFilterData = this.$store.getters[
          "Appointment/Schedule/GET_FILTER_APPOINTMENT_PER_DAY"
        ];

        if (existFilterData) {
          this.form.date = existFilterData.date;
          this.form.selectUnity = existFilterData.selectUnity;
          this.form.selectedSector = existFilterData.selectedSector;
          await this.$refs.rgsearch.performSearch();
          if (this.selectedEmployee) {
            this.$refs.smartTableEmployee.selectRow(this.selectedEmployee);
            this.selectEmployeeData(this.selectedEmployee, true);
          }
        }
      } catch (Err) {
        this.$toaster.warning("Erro ao carregar os dados da navegação");
      } finally {
        this.$loader.finish();
      }
    },

    hasPermissionsToSchedule(selectedData) {
      const today = moment({ h: 0, m: 0, s: 0 });
      const scheduleDate = moment(
        this.employeeData.data_consulta,
        "DD/MM/YYYY",
      );
      const isReservaTecnica = this.employeeData.cte_id === "2";
      if (scheduleDate < today) {
        if (isReservaTecnica) {
          return this.$Permissions.sector.has(
            "consulta.agendamento.agendamentoRetroativoReservaTecnica",
            this.employeeData.set_id,
          );
        } else {
          return this.$Permissions.sector.has(
            "consulta.agendamento.AgendamentoRetroativo",
            this.employeeData.set_id,
          );
        }
      }
      // agendamento futuro
      if (isReservaTecnica) {
        return this.$Permissions.sector.has(
          "consulta.agendamento.agendamentoFuturoReservaTecnica",
          this.employeeData.set_id,
        );
      } else {
        return this.$Permissions.sector.has(
          "consulta.agendamento.AgendamentoFuturo",
          this.employeeData.set_id,
        );
      }
    },

    hasPermissionsToEditSchedule() {
      const isReservaTecnica = this.employeeData.cte_id === "2";
      if (isReservaTecnica) {
        return this.$Permissions.sector.has(
          "consulta.agendamento.alterarAgendamentoDiaReservaTecnica",
          this.employeeData.set_id,
        );
      } else {
        return this.$Permissions.sector.has(
          "consulta.agendamento.alterar",
          this.employeeData.set_id,
        );
      }
    },

    getCountValue(pValue) {
      this.totalAgenda = Number(pValue) || 0;
    },

    scheduleAction() {
      const hasValue = this.mutableappointmentPerDay.find(
        (item) => item.eag_id === this.employeeData.eag_id,
      );

      if (hasValue) {
        this.employeeData = hasValue;

        this.$store.commit(
          "Appointment/Schedule/SELECT_EMPLOYEE_DATA",
          this.employeeData,
        );
      }

      if (!this.hasFreeSchedule) {
        return this.$toaster.warning(
          "Profissional não tem mais vagas disponíveis!",
          "Sem vagas",
        );
      }

      this.$store.commit("Appointment/Schedule/UNSELECT_APPOINTMENT_DATA");
      this.$router.push({ name: "appointment.schedule.form" });
    },

    async printOutpatientConsultationControl() {
      const client = this.$store.getters["Login/GET_CLIENT_CODE"];

      const employeeData = { client, ...this.employeeData };

      const data = await this.$store.dispatch(
        "Appointment/Schedule/GET_OUT_PATIENT_CONSULTATION_CONTROL",
        { intIdHistoricoAgendamento: this.employeeData.hag_id },
      );

      const html = ScheduleOutpatientConsultationControlHTML(
        employeeData,
        data,
      );

      this.$utils.print.printHtml(html);
    },

    editScheduledAppointment() {
      if (!this.hasPermissionsToEditSchedule()) {
        this.$toaster.error(
          "Usuário sem permissão para a edição de Consulta.",
          "Usuário Sem Permissão!",
        );

        return;
      }
      this.$router.push({ name: "appointment.schedule.form" });
    },

    async deleteScheduling(reason) {
      try {
        const { con_id, set_id, hag_data_consulta } = this.appointmentsData;

        const {
          normalPermission,
          technicalPermission,
        } = this.getPermissionOperation(hag_data_consulta, set_id);

        const variables = {
          intIdConsulta: con_id,
          strMotivo: reason,
          blnPermissaoNormal: normalPermission,
          blnPermissaoReserva: technicalPermission,
        };

        await this.$store.dispatch(
          "Appointment/Schedule/REMOVE_APPOINTMENT",
          variables,
        );

        this.$store.commit("Appointment/Schedule/UNSELECT_APPOINTMENT_DATA");

        this.clearSelectionSchedule();

        this.$toaster.success("Agendamento removido com sucesso!");
      } catch (pErr) {
        this.$toaster.error(pErr, "Erro ao tentar remover o agendamento");
      } finally {
        this.$loader.finish();
      }
    },

    getPermissionOperation(pAppointmentDate, pSectorId) {
      const appointmentDate = moment(pAppointmentDate, "DD/MM/YYYY");
      const dateNowStr = moment().format("DD/MM/YYYY");
      const dateNow = moment(dateNowStr, "DD/MM/YYYY");

      let normal = "exclusaoRetroativa";
      let technical = "exclusaoRetroativaReservaTecnica";

      // Hoje ou Maior (AGENDAMENTO FUTURO)
      if (appointmentDate.diff(dateNow) >= 0) {
        normal = "exclusaoFutura";
        technical = "exclusaoFuturaReservaTecnica";
      }

      const subModuleOperationNormal = `consulta.agendamento.${normal}`;
      const subModuleOperationTechnical = `consulta.agendamento.${technical}`;

      // if (this.type === "period") {
      //   subModuleOperationNormal = "consulta.agendamentoCompleto.excluir";
      //   subModuleOperationTechnical =
      //     "consulta.agendamentoCompleto.exclusaoAgendamentoPeriodoReservaTecnica";
      // }

      const normalPermission = this.$Permissions.sector.has(
        subModuleOperationNormal,
        pSectorId,
      );
      const technicalPermission = this.$Permissions.sector.has(
        subModuleOperationTechnical,
        pSectorId,
      );

      return { normalPermission, technicalPermission };
    },

    patientRecord() {
      const patientId = this.appointmentsData.pes_id;
      this.$store.commit("Person/Patient/SET_PERSON_ID", Number(patientId));
      this.$router.push({
        name: "appointment.schedule.per-day.editpatient",
      });
    },

    selectEmployeeData(pValue, reSearch = false) {
      if (pValue) {
        if (!reSearch) {
          this.$store.commit(
            "Appointment/Schedule/SELECT_EMPLOYEE_DATA",
            pValue,
          );
          this.employeeData = pValue;
        } else {
          const index = this.mutableappointmentPerDay.findIndex((row) => {
            return row.eag_id === pValue.eag_id;
          });

          this.$store.commit(
            "Appointment/Schedule/SELECT_EMPLOYEE_DATA",
            this.mutableappointmentPerDay[index],
          );

          this.employeeData = this.mutableappointmentPerDay[index];
        }

        this.payloadAppointmentSchedule = this.generateFilterAppointmentSchedule(
          this.employeeData.hag_id,
        );
        this.disablePrint = Number(pValue.hag_id) === 0;

        this.searchFilterAppointmentSchedule();
      } else {
        this.$store.commit("Appointment/Schedule/UNSELECT_APPOINTMENT_DATA");
        this.$refs.smartTableSchedule.cleanSelectRow();
        this.mutableAppointmentSchedule = {
          data: [],
          total: 0,
        };
        this.employeeData = null;
        this.appointmentsData = null;
      }
    },

    getAppointmentsRow(pValue) {
      this.$store.commit(
        "Appointment/Schedule/SELECT_APPOINTMENT_DATA",
        pValue,
      );

      this.appointmentsData = pValue;
      this.filId = Number(pValue.fil_id) || null;
    },

    generateFilterAppointmentSchedule(idHistorySchedule) {
      const variables = {
        arrFiltro: {
          intIdAgendamento: 0,
          intIdHistoricoAgendamento: Number(idHistorySchedule),
          intIdUnidade: this.form.selectUnity,
          strDataConsulta: this.form.date,
          limiteFim: 1000000,
          limiteInicio: 0,
        },
      };
      return variables;
    },

    async researchFirstTable() {
      await this.$refs.rgsearch.performSearch();
      if (this.selectedEmployee) {
        this.$refs.smartTableEmployee.selectRow(this.selectedEmployee);
        this.selectEmployeeData(this.selectedEmployee, true);
      }
    },

    async searchFilterAppointmentSchedule() {
      try {
        this.$loader.start();

        this.mutableAppointmentSchedule = await this.$store.dispatch(
          "Appointment/Schedule/SEARCH_APPOINTMENT_SCHEDULE",
          this.payloadAppointmentSchedule,
        );
      } catch (Err) {
        this.$toaster.error(Err);
      } finally {
        this.$loader.finish();
      }
    },

    generateFilter() {
      const variables = {
        arrFiltro: {
          blnAtendComConsultasMarcadas: false,
          blnHagAtendimento: false,
          intIdSetor: this.form.selectedSector,
          intIdUnidadeSaude: this.form.selectUnity,
          limiteFim: 1000000,
          limiteInicio: 0,
          strData: this.form.date,
        },
      };

      this.$store.commit(
        "Appointment/Schedule/SET_FILTER_APPOINTMENT_PER_DAY",
        this.form,
      );

      return variables;
    },

    searchFilter(pData) {
      this.$loader.start("Carregando dados da busca de consulta");
      const data = this.$store.dispatch(
        "Appointment/Schedule/SEARCH_EMPLOYEE_AGENDA",
        pData,
      );
      this.$loader.finish();
      return data;
    },

    afterSearchFilter(submitFromButton) {
      if (this.$refs.smartTableSchedule) {
        this.$refs.smartTableSchedule.cleanSelectRow();
      }
      if (this.$refs.smartTableEmployee) {
        this.$refs.smartTableEmployee.cleanSelectRow();
      }
      this.mutableAppointmentSchedule = {
        data: [],
        total: 0,
      };
      this.employeeData = null;

      if (submitFromButton && this.$refs.smartTableEmployee) {
        this.$refs.smartTableEmployee.setScrollTopZero();
      }
    },

    observationPatient() {
      if (this.filId) {
        const appointmentsData = this.appointmentsData;
        this.$router.push({
          name: "appointment.schedule.per-day.patient-observation-queue",
          params: { appointmentsData },
        });
      } else {
        this.$toaster.warning(
          "Este paciente não foi cadastrado pela fila de regulação.",
        );
      }
    },

    itemActionOptionsPerson(pItem) {
      return [
        {
          label: "Exibir observações do paciente na fila de regulação",
          action: this.observationPatient,
        },
        {
          label: "Exibir imagens do paciente na fila de regulação",
          action: this.openModalPatientImageInRow,
        },
      ];
    },

    itemActionOptionsPrinter(appointmentsData) {
      return [
        {
          label: "Imprimir comprovante de agendamento",
          action: () => {
            this.appointmentIdToPrint = appointmentsData.con_id;
            this.openModalTypesPrint();
          },
          disable: false,
        },
        {
          label: "Imprimir ficha de acompanhamento do paciente",
          action: this.printPatientFollowUpSheet,
          disable: false,
        },
        {
          label: "Imprimir boletim de atendimento médico",
          action: this.printHealthcareBulletin,
          disable: false,
        },
      ];
    },

    itemActionOptionsPep(pItem) {
      const alreadyWasInPep = pItem && pItem.enviado === "SIM";
      return [
        {
          label: "Enviar atendimento para o PEP",
          action: this.sendAppointmentPep,
          disable: alreadyWasInPep,
        },
        {
          label: "Cancelar atendimento no PEP",
          action: this.cancelAppointmentonPep,
        },
      ];
    },

    async printHealthcareBulletin() {
      const data = await this.$store.dispatch(
        "Appointment/Schedule/GET_HEALTHCARE_BULLETIN",
        { intIdConsulta: this.appointmentsData.con_id },
      );
      const html = ScheduleHealthcareBulletinHTML(data);

      this.$utils.print.printHtml(html);
    },

    async printPatientFollowUpSheet() {
      try {
        const data = await this.$store.dispatch(
          "Appointment/Schedule/GET_PATIENT_FOLLOW_UP_SHEET",
          {
            intIdPacienteConsulta: this.appointmentsData.pcc_id,
            intIdUnidadeSaude: this.form.selectUnity,
          },
        );

        const html = SchedulePatientFollowUpSheetHTML(data);
        this.$utils.print.printHtml(html);
      } catch (error) {
        return AlertError(error.message, [], "error");
      }
    },

    sendAppointmentPep() {
      if (!this.validBlockedRetroactiveSendingToPep()) {
        this.$toaster.error(
          "Não é possível enviar um agendamento retroativo para o Pep.",
        );
        return;
      }
      if (!this.hasPermissionSendAppointmentPep) {
        this.$toaster.error("Usuário sem permissão para enviar atendimento.");
        return;
      }
      this.$loader.start();
      this.$store
        .dispatch(
          "Appointment/Schedule/SEND_APPOINTMENT_PEP",
          this.appointmentsData.con_id,
        )
        .then((data) => {
          this.$toaster.success("Atendimento enviado para o PEP");
          this.fillFilter();
        })
        .catch((err) => {
          this.$toaster.error(
            err.message,
            "Falha ao enviar atendimento para o PEP",
          );
        })
        .finally(() => {
          this.$loader.finish();
        });
    },

    cancelAppointmentonPep() {
      if (!this.hasPermissionCancelAppointmentPep) {
        this.$toaster.error("Usuário sem permissão para cancelar atendimento.");
        return;
      }
      this.$loader.start();
      this.$store
        .dispatch(
          "Appointment/Schedule/CANCEL_APPOINTMENT_PEP",
          this.appointmentsData.con_id,
        )
        .then((data) => {
          this.$toaster.success(
            "Cancelamento do Atendimento enviado para o PEP",
          );
          this.fillFilter();
        })
        .catch((err) => {
          this.$toaster.error(
            err.message,
            "Falha ao enviar cancelamento do atendimento para o PEP",
          );
        })
        .finally(() => {
          this.$loader.finish();
        });
    },

    openModalChangeProfessionalScale() {
      this.technicalScalePermission = this.$Permissions.sector.has(
        "consulta.agendamento.alterarDadosDiaReservaTecnica",
        this.employeeData.set_id,
      )
        ? 1
        : 0;
      this.normalScalePermission = this.$Permissions.sector.has(
        "consulta.agendamento.alterarDadosDia",
        this.employeeData.set_id,
      )
        ? 1
        : 0;

      if (!this.normalScalePermission) {
        this.$toaster.warning(
          "Sem permissão para acessar a escala do profissional.",
          "Usuário sem Permissão.",
        );
        return;
      }
      this.modalChangeProfessionalScale = true;
    },

    closeModalChangeProfessionalScale() {
      this.modalChangeProfessionalScale = false;
    },

    openModalConfirmSchedulingDeletion() {
      const { enviado } = this.appointmentsData;
      if (enviado === "SIM") {
        this.$toaster.error(
          "A consulta foi enviada para o Pep e não pode ser excluída. Cancele o atendimento para excluir a Consulta.",
        );
        return;
      }
      this.modalConfirmSchedulingDeletion = true;
    },

    closeModalConfirmSchedulingDeletion() {
      this.modalConfirmSchedulingDeletion = false;
    },

    openModalPatientImageInRow() {
      if (this.filId) {
        this.modalPatientImageInRow = true;
      } else {
        AlertError("Esse paciente não foi cadastrado pela fila de regulação.");
      }
    },

    closeModalPatientImageInRow() {
      this.modalPatientImageInRow = false;
    },

    openModalTypesPrint() {
      this.modalTypesPrint = true;
    },

    closeModalTypesPrint() {
      this.modalTypesPrint = false;
    },

    openModalAppointmentDetails() {
      if (!this.hasPermissionsToEditSchedule()) {
        this.$toaster.error(
          "Usuário sem permissão para ver os detalhes da consulta.",
          "Usuário Sem Permissão!",
        );
        return;
      }
      this.modalAppointmentDetails = true;
    },

    closeModalAppointmentDetails() {
      this.modalAppointmentDetails = false;
    },

    cleanForm() {
      this.form.selectUnity = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
      this.form.selectedSector = null;
      this.form.date = moment().format("DD/MM/YYYY");
      this.employeeData = null;
      this.filId = null;
      this.appointmentsData = null;
      this.mutableAppointmentSchedule = {
        data: [],
        total: 0,
      };
      this.mutableappointmentPerDay = [];
    },

    async viewAppointmentScheduleVoucher(appointmentID) {
      this.appointmentIdToPrint = appointmentID;
      switch (this.preferences.printOptionsPreference) {
        case "2": // Opções de Impressão
          this.openModalTypesPrint();
          break;
        case "6": // Impressão Normal
          await printScheduleVoucher(this.appointmentIdToPrint);
          break;
        case "7": // Impressora Térmica 1 via
          await printThermalOneWay(this.appointmentIdToPrint);
          break;
        case "8": // Impressora Térmica 2 vias
          await printThermalTwoWay(this.appointmentIdToPrint);
          break;
        default:
          break;
      }
    },

    clearSelectionSchedule() {
      this.appointmentsData = null;
      this.$refs.smartTableSchedule.cleanSelectRow();
    },

    validBlockedRetroactiveSendingToPep() {
      const today = moment({ h: 0, m: 0, s: 0 });
      const scheduleDate = moment(
        this.employeeData.data_consulta,
        "DD/MM/YYYY",
      );

      if (this.preferences.blockedRetroactiveSendingToPep) {
        return !(scheduleDate < today);
      }

      return true;
    },

    handleErrorChangeProfessionalScale(pData) {
      const CONFLICT_SCALE_EXCEPTION =
        "ConflitoAlteracaoDePeriodoHorarioException";

      const isConflictScaleError = pData.exception === CONFLICT_SCALE_EXCEPTION;

      if (isConflictScaleError) {
        const exceptionDetails = Object.assign({}, pData.detalhes_exececao);
        const hasConflictsData = exceptionDetails.escalas?.length > 0;
        const hasHistoryData = exceptionDetails.historicos?.length > 0;

        this.scaleConflicts = hasConflictsData ? exceptionDetails.escalas : [];

        this.scaleHistory = hasHistoryData ? exceptionDetails.historicos : [];

        this.actionOpenModalScaleConflict();
      } else {
        this.$toaster.error(pData.mensagem);
      }
    },

    actionCloseModalScaleConflict() {
      this.showModalScaleConflict = false;
    },

    actionOpenModalScaleConflict() {
      this.showModalScaleConflict = true;
    },
  },
};
</script>
