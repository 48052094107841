import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

import moment from "moment";

const sortDate = (key) => {
  return (a, b) =>
    moment(b[key], "DD/MM/YYYY HH:mm") - moment(a[key], "DD/MM/YYYY HH:mm");
};

export default async (context, variables) => {
  return AuthLegacyRequest.post(
    "exames2/controller-exame-escala-agendamento/buscar-historico-agendamentos",
    variables,
  )
    .then(({ data }) => {
      if (data.dados === "0") {
        return [];
      }

      data = HandleResponse(data);

      const historyScaleList = data.dados.map((item) => {
        item.validade = `${item.eel_data_inicio_escala} - ${item.eel_data_fim_escala}`;
        return item;
      });

      const sortHistoryScaleList = historyScaleList.sort(sortDate("eel_data"));

      return sortHistoryScaleList;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
