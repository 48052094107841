<script>
import { RgComboboxMixin } from "~tokio/primitive";

export default {
  name: "RgComboboxWeekDays",
  mixins: [RgComboboxMixin],
  props: {
    label: {
      type: String,
      default: "Dia da Semana",
    },
    defaultText: {
      type: String,
      default: "Selecione",
    },
    emptyDefaultText: {
      type: String,
      default: "Nenhum Item para Selecionar",
    },
  },
  data() {
    return {
      mData: [
        { text: "Domingo", value: 1 },
        { text: "Segunda-Feira", value: 2 },
        { text: "Terça-Feira", value: 3 },
        { text: "Quarta-Feira", value: 4 },
        { text: "Quinta-Feira", value: 5 },
        { text: "Sexta-Feira", value: 6 },
        { text: "Sábado", value: 7 },
      ],
    };
  },
};
</script>
