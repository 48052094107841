<template lang="html">
  <svg
    width="21"
    height="25"
    viewBox="0 0 21 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.20105 15.7H12.7989C16.9545 15.7 20.3 18.9759 20.3 22.9569V24.3H0.7V22.9569C0.7 18.9759 4.04546 15.7 8.20105 15.7Z"
      fill="white"
      stroke="white"
      stroke-width="1.4"
    />
    <path
      d="M4.86113 6.34649C4.86113 3.23361 7.39471 0.7 10.5076 0.7C13.6204 0.7 16.154 3.23361 16.154 6.34644C16.154 9.45932 13.6204 11.9929 10.5076 11.9929C7.3947 11.9929 4.86113 9.45932 4.86113 6.34649Z"
      fill="white"
      stroke="white"
      stroke-width="1.4"
    />
  </svg>
</template>

<script>
export default {
  name: "Perfil",
};
</script>
