<template lang="html">
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="5" width="3" height="13" rx="1.5" />
    <rect
      x="13"
      y="5"
      width="3"
      height="13"
      rx="1.5"
      transform="rotate(90 13 5)"
    />
  </svg>
</template>
<script>
export default {
  name: "AddExam",
};
</script>
