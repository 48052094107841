import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

const arrayExceptions = [
  "PacienteConsultadoHojeAtendenteException",
  "HorarioIndisponivelException",
];

export default async (context, variables) => {
  const sendData = {
    collectionFormData: variables,
    tipoAgendamento: "AGENDAMENTOPORREGULACAO",
    blnExibirAvisos: true,
  };
  return AuthLegacyRequest.post(
    "exames2/controller-exame-agendamento-procedimento/agendar-pela-fila-lote",
    sendData,
  )
    .then(({ data }) => {
      const response = {};
      const { dados, detalhes_exececao, trace } = data;

      let exceptionName = "";
      let hasError = false;

      const dataIsNotNumber = isNaN(dados);

      if (dados.includes("exception")) {
        hasError = true;
        exceptionName = trace;
      }

      if (dataIsNotNumber) {
        hasError = true;
        exceptionName = arrayExceptions.includes(dados)
          ? dados
          : "Generic Exception";
      }

      response.hasError = hasError;
      response.dados = dados;
      response.detalhes_exececao = detalhes_exececao;
      response.mensagem = trace;
      response.exception = exceptionName;

      return response;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
