<style lang="scss" src="./RgAlertModal.scss" scoped></style>
<template>
  <div class="rg-alert-modal">
    <RgBaseModal :show="show" class="rg-alert-base-modal" @close="close">
      <div slot="header" class="confirm-header">
        <h4>{{ title }}</h4>
      </div>

      <div slot="body">
        <h3>{{ message }}</h3>
      </div>

      <div slot="footer" class="confirm-actions-footer">
        <button class="confirm-actions-item btn btn-success" @click="close">
          Ok
        </button>
      </div>
    </RgBaseModal>
  </div>
</template>

<script type="text/javascript">
import RgBaseModal from "./RgBaseModal";
export default {
  name: "RgAlertModal",
  components: {
    RgBaseModal,
  },
  props: {
    title: {
      type: String,
      default: "Are you Sure?",
    },
    message: {
      type: String,
      default: "",
    },
    show: {
      type: Boolean,
      default: undefined,
      required: true,
    },
  },
  methods: {
    close: function () {
      this.$emit("close");
    },
  },
};
</script>
