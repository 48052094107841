<style src="./PersonDetail.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="person-detail">
    <section class="person-detail--container row">
      <Modulebox
        :granted="hasPermission"
        class="box-module-row col-xs-12 col-8"
        title="Básico"
      >
        <PersonalData
          :pes-id="patientData.pac_id_pessoas"
          :granted="hasPermission"
        />
      </Modulebox>

      <div class="col-4">
        <div class="column">
          <Modulebox
            :disabled="!existsPesId"
            :granted="hasPermission"
            class="row-6"
            title="Contatos"
          >
            <PersonalContact :pes-id="patientData.pac_id_pessoas" />
          </Modulebox>

          <Modulebox
            :disabled="!existsPesId"
            :granted="hasPermission"
            class="row-6"
            title="Prontuário"
          >
            <PatientMedicalRecord :pes-id="patientData.pac_id_pessoas" />
          </Modulebox>
        </div>
      </div>
    </section>

    <section class="person-detail--container row">
      <Modulebox
        :disabled="!existsPesId"
        :granted="hasPermission"
        class="box-module-row col-8 col-xs-12"
        title="Adicionais"
      >
        <PersonalAdditionalInfo :pes-id="patientData.pac_id_pessoas" />
      </Modulebox>

      <Modulebox
        :disabled="!existsPesId"
        :granted="hasPermission"
        class="col-4 docs"
        title="Documentos"
      >
        <PersonalDocument
          :pes-id="patientData.pac_id_pessoas"
          class="personal-document"
        />
      </Modulebox>
    </section>
  </section>
</template>

<script>
import Modulebox from "~tokio/foundation/modulebox/Modulebox";
import {
  PersonalData,
  PersonalAdditionalInfo,
  PersonalContact,
  PersonalDocument,
  PatientMedicalRecord,
} from "$patient/common/components";

import { mapGetters } from "vuex";

export default {
  name: "PersonDetail",
  components: {
    Modulebox,
    PersonalData,
    PersonalContact,
    PersonalAdditionalInfo,
    PersonalDocument,
    PatientMedicalRecord,
  },
  data() {
    return {
      patientData: {
        pac_id_pessoas: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      existsPesId: "Patient/EXISTS_PES_ID",
    }),
    hasPermission() {
      if (this.existsPesId) {
        return this.$Permissions.global.has("cadastro.pessoa.alterar");
      } else {
        return this.$Permissions.global.has("cadastro.pessoa.incluir");
      }
    },
  },
  mounted() {
    const patientHospitalization = this.$route.params.patient || null;

    if (patientHospitalization) {
      console.log("veio da internação");
      this.patientData.pac_id_pessoas = patientHospitalization;
    } else {
      console.log("modulo de paciente/pessoa");
      this.patientData.pac_id_pessoas = this.$store.getters[
        "Patient/GET_PERSON_ID"
      ];
    }
  },
  beforeDestroy() {
    this.$store.commit("Patient/DESTROY_PERSON_INFO");
  },
};
</script>
