import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchIntegrations($itg_ativo: Int) {
    SearchIntegrations(itg_ativo: $itg_ativo) {
      rows {
        itg_id
        itg_id_usuarios
        itg_nome
        itg_token
        itg_ativo
        itg_validade
        itg_criado_em
        itg_alterado_em
        user {
          usu_nome
        }
      }
      count
    }
  }
`;

export default async ({ rootState }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    return data?.SearchIntegrations?.rows;
  } catch (err) {
    return Promise.reject(err.graphQLErrors);
  }
};
