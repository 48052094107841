<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import HealtPlan from "./action/HealthPlan";
export default {
  name: "RgComboboxHealthPlan",
  mixins: [RgComboboxMixin],
  props: {
    variable: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {};
  },
  watch: {
    variable() {
      this.fillData();
    },
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];
      const data = await HealtPlan(this.variable);
      data.forEach((item) => {
        if (parseInt(item.totalProcVinculados) > 0) {
          this.mData.push({ value: item.data, text: item.pls_nome });
        }
      });

      if (this.mData.length === 1) {
        this.$emit("selectOnlyItem", this.mData[0].value);
      }
    },
  },
};
</script>
