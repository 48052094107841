import Request from "~common/request";
import BaseSanitizer from "./BaseSanitizer";
import Store from "@/store";
export default class BaseModel {
  constructor() {
    this._endPointURI = this.defineEndPointURI();
    this._request = this.defineRequest();
    this._sanitizer = this.defineSanitizer();
    this._escapeSanitize = [];
  }

  isModel() {
    return true;
  }

  getSearchUrl() {
    return this._endPointURI;
  }

  getFindUrl() {
    return this._endPointURI;
  }

  defineEndPointURI() {
    throw new Error("You need to provide a URI to the Model");
  }

  defineRequest() {
    const request = new Request(window.esusng.serverConfig);

    request.getHeaders = (headers) => {
      headers.authorization = Store.getters["Login/GET_TOKEN"];
      headers.unithealth = Store.getters["Login/GET_UNIT_HEALTH_ID"];
      headers["Content-Type"] = "application/json";
      return headers;
    };

    request.onunauthorized = () => {
      // LoginManager.getInstance().expireLogin()
    };
    return request;
  }

  defineSanitizer() {
    return new BaseSanitizer();
  }

  find(id, filter = {}) {
    if (!id) return Promise.reject(new Error("Id is nedded"));
    const findURL = this._endPointURI + "/" + id;
    return this._request.get(findURL, filter).then((response) => {
      response.data = this._sanitizer.sanitize(
        response.data,
        this._escapeSanitize,
      );
      return response;
    });
  }

  update(id, formData) {
    return this._request
      .put(`${this._endPointURI}/${id}`, formData)
      .then((response) => {
        response.data = this._sanitizer.sanitize(
          response.data,
          this._escapeSanitize,
        );
        return response;
      });
  }

  remove(id) {
    return this._request
      .delete(`${this._endPointURI}/${id}`)
      .then((response) => {
        response.data = this._sanitizer.sanitize(
          response.data,
          this._escapeSanitize,
        );
        return response;
      });
  }

  create(formData) {
    return this._request.post(this._endPointURI, formData).then((response) => {
      response.data = this._sanitizer.sanitize(
        response.data,
        this._escapeSanitize,
      );
      return response;
    });
  }

  search(filter = {}) {
    return this._request.get(this._endPointURI, filter).then((response) => {
      response.data = this._sanitizer.sanitize(
        response.data,
        this._escapeSanitize,
      );
      return response;
    });
  }
}
