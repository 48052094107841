<style src="./RgComboboxCancelReason.scss" lang="scss"></style>
<script>
import { RgRemoteComboboxMixin } from "~tokio/primitive";
import SurgeryCancelReasonModel from "$surgery-center/surgery/model/SurgeryCancelReasonModel";
export default {
  name: "RgComboboxCancelReason",
  mixins: [RgRemoteComboboxMixin],
  data: () => {
    return {
      model: SurgeryCancelReasonModel,
    };
  },
  methods: {
    formatItem(pData) {
      return {
        value: pData.moc_id,
        text: pData.moc_nome,
      };
    },
  },
};
</script>
