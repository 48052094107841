const ReasonsBlockedPatient = {
  1: "NÃO INFORMADO",
  2: "INABILITADO",
  3: "DESCARTADO",
  4: "ÓBITO",
  5: "INATIVIDADE",
  6: "PRONTUÁRIO DUPLICADO",
  7: "TRIAGEM",
};

export default ReasonsBlockedPatient;
