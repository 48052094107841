import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import PersonSearch from "$patient/view/person-search/PersonSearch";

export default {
  path: "/register/patient/search",
  name: "register.patient.search",
  props: true,
  components: {
    default: PersonSearch,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [{ label: "Pacientes" }],
  },
};
