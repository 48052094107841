var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fieldset',{staticClass:"rg-situation-bed"},[_c('span',{staticClass:"label"},[_vm._v(" Situação do Leito ")]),_c('div',{staticClass:"container unselect"},[_c('div',{staticClass:"radio -vazio",class:{
        '-active-vazio': _vm.selectedStatus.includes(_vm.BED_SITUATIONS.VAZIO),
      },on:{"click":function($event){return _vm.toggleStatus(_vm.BED_SITUATIONS.VAZIO)}}},[_vm._v(" Vazio ")]),_c('div',{staticClass:"radio -reserva",class:{
        '-active-reserva': _vm.selectedStatus.includes(_vm.BED_SITUATIONS.RESERVA),
      },on:{"click":function($event){return _vm.toggleStatus(_vm.BED_SITUATIONS.RESERVA)}}},[_vm._v(" Reserva ")]),_c('div',{staticClass:"radio -ocupado",class:{
        '-active-ocupado': _vm.selectedStatus.includes(_vm.BED_SITUATIONS.OCUPADO),
      },on:{"click":function($event){return _vm.toggleStatus(_vm.BED_SITUATIONS.OCUPADO)}}},[_vm._v(" Ocupado ")]),_c('div',{staticClass:"radio -desinfeccao",class:{
        '-active-desinfeccao': _vm.selectedStatus.includes(
          _vm.BED_SITUATIONS.DESINFECCAO
        ),
      },on:{"click":function($event){return _vm.toggleStatus(_vm.BED_SITUATIONS.DESINFECCAO)}}},[_vm._v(" Desinfecção ")]),_c('div',{staticClass:"radio -manutencao",class:{
        '-active-manutencao': _vm.selectedStatus.includes(
          _vm.BED_SITUATIONS.MANUTENCAO
        ),
      },on:{"click":function($event){return _vm.toggleStatus(_vm.BED_SITUATIONS.MANUTENCAO)}}},[_vm._v(" Manutenção ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }