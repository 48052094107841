<style src="./ExportHospitalizationBilling.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="export-hospitalization-billing">
    <RgConfirmModal
      :show="showModal"
      :message="confirmMessage"
      title="Gerar Arquivo SISAIH"
      yes-label="OK, entendi"
      @confirmed="generateBillingFile"
      @denied="closeModal"
      @close="closeModal"
    />
    <div class="form">
      <ModuleBox
        :granted="hasPermission"
        class="module-box-export-hospitalization-billing"
        title="Exportação de faturamento de internação"
      >
        <div class="row">
          <RgComboboxMonth v-model="month" class="col-4" label="Apresentação" />
          <RgComboboxYear v-model="year" class="col-3" label="Ano" />
          <RgSubmit
            ref="btnSubmit"
            label="Gerar Faturamento"
            wait-label="Gerando..."
            class="btn-gerar-faturamento col-3"
            @submit="confirmGenerate"
          >
            Gerar Faturamento de internação
          </RgSubmit>
        </div>
      </ModuleBox>
    </div>
  </section>
</template>

<script>
import ModuleBox from "~tokio/foundation/modulebox/Modulebox";
import RgComboboxMonth from "../../common/component/rg-combobox-month/RgComboboxMonth";
import RgComboboxYear from "../../common/component/rg-combobox-year/RgComboboxYear";
import DownloadHospitalizationBillingFile from "../actions/DownloadHospitalizationBillingFile";
import { RgSubmit } from "~tokio/primitive/button";
import RgConfirmModal from "~tokio/primitive/notification/modal/RgConfirmModal";
export default {
  name: "ExportHospitalizationBilling",
  components: {
    ModuleBox,
    RgComboboxMonth,
    RgComboboxYear,
    RgConfirmModal,
    RgSubmit,
  },
  data: () => {
    return {
      year: "",
      month: "",
      showModal: false,
      confirmMessage:
        "O tempo estimado para gerar o arquivo de produção da sua unidade será relativo a quantidade AIH's faturadas, podendo levar alguns minutos para concluir a exportação.",
    };
  },
  computed: {
    periodDate() {
      return `${this.month}${this.year}`;
    },
    hasPermission() {
      return this.$Permissions.global.has(
        "faturamento.faturar.faturar",
        this.$store.getters["Login/GET_UNI_HEALTH_ID"],
      );
    },
  },
  mounted() {
    const cmp = this.$store.getters[
      "Billing/PeriodHospitalizationBilling/GET_PERIOD_HOSPITALIZATION_BILLING"
    ];
    this.year = cmp.substring(0, 4);
    this.month = cmp.substring(4, 6);
  },
  methods: {
    confirmGenerate() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.$refs.btnSubmit.fail();
    },
    generateBillingFile() {
      const period = this.periodDate;
      const unitHealthId = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
      DownloadHospitalizationBillingFile(period, unitHealthId)
        .then((result) => {
          if (result.data && result.data.status === false) {
            this.$toaster.error(result.data.msg);
            this.$refs.btnSubmit.actionDone();
          } else {
            const url = window.URL.createObjectURL(
              new Blob([result.data.file]),
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", result.data.filename);
            document.body.appendChild(link);
            link.click();
            this.$refs.btnSubmit.actionDone();
          }
        })
        .catch((pErr) => {
          this.toaster.error("Falha ao Gerar arquivo de Exportação");
          this.$refs.btnSubmit.actionDone();
        });
      this.showModal = false;
    },
  },
};
</script>
