<template lang="html">
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512.001 512.001"
    style="enable-background: new 0 0 512.001 512.001"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M368.484,480.21c-5.53,0-10,4.47-10,10c0,5.52,4.47,10,10,10c5.52,0,10-4.48,10-10
        C378.484,484.68,374.004,480.21,368.484,480.21z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M257.479,321.203c-5.53,0-10,4.47-10,10c0,5.52,4.47,10,10,10c5.52,0,10-4.48,10-10
        C267.479,325.674,262.999,321.203,257.479,321.203z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M222.478,286.202c-5.53,0-10,4.47-10,10c0,5.52,4.47,10,10,10c5.52,0,10-4.48,10-10
        C232.478,290.672,227.998,286.202,222.478,286.202z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M185.476,250.2c-5.53,0-10,4.47-10,10c0,5.52,4.47,10,10,10c5.52,0,10-4.48,10-10
        C195.476,254.671,190.996,250.2,185.476,250.2z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M503.231,147.854L375.285,19.908c-11.693-11.691-30.725-11.693-42.372-0.062l-230.55,226.51L8.787,338.303
        C3.121,343.969,0,351.503,0,359.515s3.12,15.546,8.786,21.212l99.665,99.664c13.657,13.657,31.592,20.478,49.523,20.478
        c3.286,0,6.571-0.234,9.83-0.693c0.222,0.015,0.444,0.034,0.67,0.034H268.48c5.523,0,10-4.477,10-10s-4.477-10-10-10h-60.846
        l64.658-63.413l193.067-189.363c0.931-0.467,1.807-1.082,2.585-1.858c0.738-0.737,1.335-1.56,1.795-2.437l33.423-32.782
        c0.024-0.024,0.049-0.048,0.073-0.072C514.924,178.58,514.922,159.546,503.231,147.854z M193.305,466.249
        c-19.496,19.495-51.216,19.495-70.711,0L22.93,366.584c-1.889-1.889-2.929-4.399-2.929-7.069s1.04-5.18,2.866-7.007l86.437-84.924
        l141.782,141.993L193.305,466.249z M265.365,395.572L123.571,253.566L304.267,76.032l142.057,142.056L265.365,395.572z
        M489.11,176.123l-28.507,27.959L318.535,62.013l28.455-27.957c1.954-1.952,4.518-2.927,7.082-2.927
        c2.561,0,5.122,0.974,7.07,2.922l127.945,127.945C492.977,165.887,492.984,172.215,489.11,176.123z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M397.431,197.487c-3.794-4.013-10.124-4.191-14.138-0.396l-104.334,98.654c-4.013,3.794-4.19,10.124-0.396,14.138
        c1.967,2.08,4.614,3.129,7.268,3.129c2.465,0,4.936-0.906,6.869-2.734l104.334-98.654
        C401.048,207.83,401.225,201.5,397.431,197.487z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M326.047,126.103c-3.795-4.013-10.125-4.188-14.138-0.394l-104.834,99.154c-4.012,3.795-4.188,10.124-0.394,14.138
        c1.967,2.08,4.614,3.128,7.267,3.128c2.466,0.001,4.937-0.906,6.87-2.734l104.835-99.154
        C329.665,136.445,329.841,130.116,326.047,126.103z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M362.43,162.486c-3.794-4.013-10.124-4.19-14.138-0.396l-104.334,98.654c-4.013,3.794-4.19,10.124-0.396,14.138
        c1.967,2.08,4.614,3.129,7.268,3.129c2.466,0.001,4.936-0.905,6.869-2.733l104.334-98.654
        C366.047,172.829,366.224,166.499,362.43,162.486z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M328.482,480.21h-20.001c-5.523,0-10,4.477-10,10s4.477,10,10,10h20.001c5.523,0,10-4.477,10-10
        S334.005,480.21,328.482,480.21z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "Eraser",
};
</script>
