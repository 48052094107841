<template lang="html">
  <svg
    id="Capa_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 480.007 480.007"
    style="enable-background: new 0 0 480.007 480.007"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M479.426,267.581c-3.547-24.574-22.852-43.879-47.426-47.426v-14.544c0-40.128-30.504-72.8-68-72.8
			c-36.416,0-66.904,31.68-68,70.624h0.464l-0.464,0.08v157.656c0,50.4-37.68,91.328-84,91.328s-84-40.984-84-91.328v-13.656
			c11.6,0,24-8.408,24-32v-20.12c51.344-13.128,88-57.216,88-107.2v-16.68h-16l-0.336-104.12
			c-0.116-19.273-13.946-35.729-32.912-39.16c-4.091-12.533-17.568-19.377-30.102-15.285c-12.533,4.091-19.377,17.568-15.285,30.102
			c4.091,12.533,17.568,19.377,30.102,15.285c6.711-2.191,12.108-7.24,14.741-13.79c10.249,2.916,17.349,12.24,17.432,22.896
			L208,171.515h-16v16.912c-0.638,27.827-19.294,52.009-46.048,59.688c-14.315,4.542-29.685,4.542-44,0
			C75.229,240.41,56.615,216.232,56,188.427v-16.912H32v-104c0.046-10.813,7.318-20.261,17.76-23.072
			c4.869,12.395,18.864,18.495,31.259,13.626S99.514,39.204,94.645,26.81S75.78,8.315,63.386,13.184
			c-6.716,2.639-11.898,8.146-14.122,15.011C30.091,31.471,16.053,48.064,16,67.515v104H0v16.64c0,50.04,36.68,94.144,88,107.24
			v20.12c0,23.592,12.4,32,24,32v13.656c0,59.2,44.856,107.328,100,107.328s100-48.184,100-107.328V203.899
			c0.8-30.36,24.144-55.064,52-55.064c28.672,0,52,25.472,52,56.8v14.52c-30.611,4.418-51.844,32.815-47.426,63.426
			s32.815,51.844,63.426,47.426S483.844,298.191,479.426,267.581z M168,43.515c-4.418,0-8-3.582-8-8s3.582-8,8-8s8,3.582,8,8
			S172.418,43.515,168,43.515z M72,27.515c4.418,0,8,3.582,8,8s-3.582,8-8,8s-8-3.582-8-8S67.582,27.515,72,27.515z M16,188.155
			v-0.64h24v0.912c0.6,34.771,23.733,65.116,57.104,74.904c17.487,5.537,36.257,5.537,53.744,0
			c33.387-9.775,56.541-40.121,57.152-74.904v-0.912h16v0.64c0,44.504-34.304,83.664-81.6,93.136
			c-11.106,2.224-22.482,2.782-33.752,1.656c-3.71-0.36-7.398-0.918-11.048-1.672C50.304,271.819,16,232.659,16,188.155z
      M112,331.515c-7.2,0-8-11.2-8-16v-16.976c0.64,0.08,1.296,0.112,1.944,0.176c0.8,0.088,1.6,0.152,2.4,0.224
			c3.784,0.336,7.6,0.56,11.448,0.568h0.4c3.84,0,7.648-0.232,11.408-0.568c0.8-0.072,1.648-0.136,2.472-0.224
			c0.632-0.072,1.288-0.096,1.92-0.176v16.976c0,4.8-0.8,16-8,16H112z M424,315.515c-22.091,0-40-17.909-40-40
			c0-22.091,17.909-40,40-40c22.091,0,40,17.909,40,40C463.974,297.596,446.08,315.489,424,315.515z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M424,251.515c-13.255,0-24,10.745-24,24s10.745,24,24,24s24-10.745,24-24S437.255,251.515,424,251.515z M424,283.515
			c-4.418,0-8-3.582-8-8s3.582-8,8-8s8,3.582,8,8S428.418,283.515,424,283.515z"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "Appointment",
};
</script>
