<style src="./Loop.scss" lang="scss" scoped></style>
<template>
  <section class="rg-animation-loop">
    <span v-if="typeFrame === 'text'">{{ frame }}</span>
    <div v-else-if="typeFrame === 'icon'">
      <component :is="frame" />
    </div>
  </section>
</template>
<script>
import Vue from "vue";

export default {
  name: "RgAnimationLoop",
  components: {},

  props: {
    typeFrame: {
      type: String,
      default: "icon",
    },
    frames: {
      type: Array,
      default: null,
    },
    speed: {
      type: Number,
      default: 1000,
    },
  },

  data() {
    return {
      frameCounter: 0,
      frame: null,

      timeout: null,
    };
  },

  mounted() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    switch (this.typeFrame) {
      case "text":
        this.getItem();
        this.textAnimation();
        break;
      case "icon":
        this.getItem();
        this.iconAnimation();
        break;
      default:
        console.error("typeFrame não reconhecido -> ", this.typeFrame);
        break;
    }
  },

  beforeDestroy() {
    clearTimeout(this.timeout);
  },

  methods: {
    /*
     * TEXTO ANIMAÇÂO
     */
    textAnimation() {
      this.timeout = setTimeout(() => {
        this.getItem();
        this.textAnimation();
      }, this.speed);
    },

    setTextFrame() {
      this.frame = this.frames[this.frameCounter];
    },

    /*
     * icon ANIMAÇÂO
     */
    iconAnimation() {
      this.timeout = setTimeout(() => {
        this.getItem();
        this.iconAnimation();
      }, this.speed);
    },

    setIconFrame() {
      this.frame = Vue.component(`icon-${this.frameCounter}`, () => {
        return import(
          `~tokio/primitive/icon/${this.frames[this.frameCounter]}`
        );
      });
      return null;
    },

    /*
     * OUTROS
     */
    getItem() {
      switch (this.typeFrame) {
        case "text":
          this.setTextFrame();
          break;
        case "icon":
          this.setIconFrame();
          break;
      }
      this.frameCounter++;
      if (this.frameCounter > this.frames.length - 1) {
        this.frameCounter = 0;
      }
    },
  },
};
</script>
