<template lang="html">
  <svg
    width="18mm"
    height="18mm"
    version="1.1"
    viewBox="0 0 119.52267 108.74957"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(44.642 -74.804)">
      <g transform="matrix(.26458 0 0 .26458 -44.642 69.417)">
        <path
          d="m446.32 367.38-183.47-325.69c-15.644-28.444-58.311-28.444-73.956 0l-183.47 325.69c-15.644 28.444 4.267 64 36.978 64h365.51c34.133-1.422 54.044-35.556 38.4-64z"
          fill="#e24c4b"
        />
        <path d="m225.88 63.025 183.47 325.69h-366.93z" fill="#FFFFFF" />
        <g fill="#3f4448">
          <path
            d="m196.01 212.36 11.378 75.378c1.422 8.533 8.533 15.644 18.489 15.644 8.533 0 17.067-7.111 18.489-15.644l11.378-75.378c2.844-18.489-11.378-34.133-29.867-34.133-18.49-1e-3 -31.29 15.644-29.867 34.133z"
          />
          <circle cx="225.88" cy="336.09" r="17.067" />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "TriangleAlert",
};
</script>
