import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query EmployeeAB($unitHealh: Int, $formType: Int, $employee_id: Int) {
    SearchTeamEmployeeForHeader(
      uns_id: $unitHealh
      formType: $formType
      employee_id: $employee_id
    ) {
      count
      rows {
        fun_crs_numero_ab
        ocp_nome
        ocp_codigo
        uns_cnes
        equ_codigo_ine
        pes_nome
        equ_nome_equipe
        are_id
        are_nome
        are_descricao
        fab_id
        equ_id
        epr_id
      }
    }
  }
`;

export default async (context, { uns_id, formType, employee_id }) => {
  try {
    const variables = {
      unitHealh: uns_id,
      formType,
      employee_id,
    };
    const { data } = await GraphQLClient.query({
      query,
      fetchPolicy: "no-cache",
      variables,
    });
    return data;
  } catch (err) {
    console.log(err);
    return err.graphQLErrors;
  }
};
