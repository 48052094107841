<template lang="html">
  <div>
    <!-- CADASTRO DE SAÍDA-->
    <div v-if="hospitalizationExit">
      <ModalHospitalizationExit
        :show="changeTo"
        :status="statusbed"
        :operation="operation"
        :hospitalization-extra="hospitalizationExtraData"
        :bed-id="bedId"
        @reSearch="reSearchAfterChangeFlow"
        @close="closeChange"
      />
    </div>

    <!-- MODAL COM MOTIVO -->
    <div v-show="hospitalizationReason || confirmRemoveReservation">
      <ModalHospitalizationReason
        :show="changeTo || confirmRemoveReservation"
        :status="statusbed || 2"
        :operation="operation || 1"
        :bed-id="bedId"
        :hospitalization="hospitalizationData"
        :hospitalization-id="hospitalizationId"
        :indigentId="hospitalizationIndigentId"
        :title="confirmRemoveReservation ? 'Excluir Reserva' : null"
        @reSearch="reSearchAfterChangeFlow"
        @close="closeChange"
      />
    </div>

    <!-- EFETIVAR INTERNAÇÃO -->
    <div v-show="confirmHospitalization">
      <ModalConfirmHospitalization
        :show="changeTo"
        :status="statusbed || 2"
        :operation="operation || 1"
        :hospitalization-id="hospitalizationId"
        :hospitalization="hospitalizationData"
        @reSearch="reSearchAfterChangeFlow"
        @close="closeChange"
      />
    </div>
  </div>
</template>
<script>
import TYPEBED from "$hospitalization/common/component/util/GetTypeBedValues";
import ModalHospitalizationReason from "$hospitalization/common/component/modal/modal-hospitalization-reason/ModalHospitalizationReason";
import ModalHospitalizationExit from "$hospitalization/common/component/modal/modal-hospitalization-exit/ModalHospitalizationExit.vue";
import ModalConfirmHospitalization from "$hospitalization/common/component/modal/modal-confirm-hospitalization/ModalConfirmHospitalization";
export default {
  name: "ModalChangeBedType",
  components: {
    ModalHospitalizationReason,
    ModalHospitalizationExit,
    ModalConfirmHospitalization,
  },
  props: {
    status: {
      type: Number,
      default: 0,
    },
    operation: {
      type: Number,
      default: 0,
    },
    hospitalization: {
      type: Object,
      default: () => {},
    },
    hospitalizationExtra: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      bedId: null,
      hospitalizationId: null,
      hospitalizationIndigentId: null,
      hospitalizationData: null,
      hospitalizationExtraData: null,
      hospitalizationReasonSubModal: false,
      statusbed: 0,
      changeTo: false,
    };
  },
  computed: {
    operationMaintenance() {
      return this.operation === TYPEBED.MANUTENCAO;
    },

    operationDesinfection() {
      return this.operation === TYPEBED.DESINFECCAO;
    },

    operationBusy() {
      return this.operation === TYPEBED.OCUPADO;
    },

    operationEmpty() {
      return this.operation === TYPEBED.VAZIO;
    },

    operationReservation() {
      return this.operation === TYPEBED.RESERVA;
    },

    statusMaintenance() {
      return this.status === TYPEBED.MANUTENCAO;
    },

    statusDesinfection() {
      return this.status === TYPEBED.DESINFECCAO;
    },

    statusBusy() {
      return this.status === TYPEBED.OCUPADO;
    },

    statusEmpty() {
      return this.status === TYPEBED.VAZIO;
    },

    statusReservation() {
      return this.status === TYPEBED.RESERVA;
    },

    hospitalizationExit() {
      return this.statusBusy && this.operationDesinfection;
    },

    hospitalizationReason() {
      return (
        (this.statusEmpty &&
          (this.operationDesinfection || this.operationMaintenance)) ||
        (this.statusDesinfection &&
          (this.operationEmpty || this.operationMaintenance)) ||
        (this.statusMaintenance && this.operationDesinfection)
      );
    },

    confirmHospitalization() {
      return this.statusReservation && this.operationBusy;
    },

    confirmRemoveReservation() {
      return this.statusReservation && this.operationEmpty;
    },
  },
  watch: {
    operation(pValue) {
      this.changeTo = true;
    },

    status(pValue) {
      this.$nextTick(async () => {
        this.statusbed = pValue;
      });
    },

    hospitalization(pValue) {
      if (pValue) {
        this.$nextTick(async () => {
          this.hospitalizationData = pValue;
          this.hospitalizationId = pValue.int_id;
          this.bedId = pValue.id;
          this.hospitalizationIndigentId = pValue.ini_id;
        });
      }
    },

    hospitalizationExtra(pValue) {
      if (pValue) {
        this.hospitalizationExtraData = pValue;
      }
    },
  },
  methods: {
    reSearchAfterChangeFlow(pValue) {
      if (pValue) {
        this.$parent.reSearch();
      }
    },

    cleanStatus() {
      this.statusbed = 0;
    },

    closeChange(pActionTaken = false) {
      this.hospitalizationData = null;
      this.hospitalizationExtraData = null;
      this.bedId = null;
      this.hospitalizationId = null;
      this.hospitalizationReasonSubModal = false;
      this.$emit("close", (pActionTaken = true));
      this.$emit("reSearch");
    },
  },
};
</script>
