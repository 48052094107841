<template lang="html">
  <svg
    width="417"
    viewBox="0 0 417 228"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M144.451 33.7097H134.339L107.371 104.5H144.451V33.7097Z"
      fill="#E0E0E2"
    />
    <path
      d="M272.548 33.7097V104.5H309.629L282.661 33.7097H272.548Z"
      fill="#E0E0E2"
    />
    <path
      d="M255.693 104.5C255.693 130.564 234.564 151.694 208.5 151.694C182.436 151.694 161.306 130.564 161.306 104.5H107.371V185.403H309.629V104.5H255.693Z"
      fill="white"
    />
    <path
      d="M312.781 103.3L285.813 32.5098C285.315 31.2012 284.06 30.3387 282.661 30.3387H275.919V16.8548C275.919 14.9932 274.41 13.4839 272.548 13.4839H144.452C142.59 13.4839 141.081 14.9932 141.081 16.8548V30.3387H134.339C132.94 30.3387 131.685 31.2012 131.187 32.5098L104.219 103.3C104.095 103.647 104.03 104.011 104.023 104.378C104.023 104.423 104 104.456 104 104.5V185.403C104 187.265 105.509 188.774 107.371 188.774H309.629C311.491 188.774 313 187.265 313 185.403V104.5C313 104.456 312.977 104.423 312.977 104.378C312.97 104.011 312.905 103.647 312.781 103.3ZM280.339 37.0806L304.737 101.129H275.919V37.0806H280.339ZM147.823 20.2258H269.177V101.129H255.694C253.832 101.129 252.323 102.638 252.323 104.5C252.323 128.702 232.702 148.323 208.5 148.323C184.298 148.323 164.677 128.702 164.677 104.5C164.677 102.638 163.168 101.129 161.306 101.129H147.823V20.2258ZM136.661 37.0806H141.081V101.129H112.263L136.661 37.0806ZM306.258 182.032H110.742V107.871H158.047C159.812 134.437 181.875 155.083 208.5 155.083C235.125 155.083 257.188 134.437 258.953 107.871H306.258V182.032Z"
      fill="#6F8894"
    />
    <path
      d="M141.081 3.37097C141.081 5.23257 142.59 6.74193 144.452 6.74193H272.548C274.41 6.74193 275.919 5.23257 275.919 3.37097C275.919 1.50936 274.41 0 272.548 0H144.452C142.59 0 141.081 1.50936 141.081 3.37097Z"
      fill="#6F8894"
    />
    <path
      d="M127.597 168.548H124.226V158.435C124.226 156.574 122.716 155.065 120.855 155.065C118.993 155.065 117.484 156.574 117.484 158.435V171.919C117.484 173.781 118.993 175.29 120.855 175.29H127.597C129.458 175.29 130.968 173.781 130.968 171.919C130.968 170.058 129.458 168.548 127.597 168.548Z"
      fill="#6F8894"
    />
    <path
      d="M147.823 168.548H141.081C139.219 168.548 137.71 170.058 137.71 171.919C137.71 173.781 139.219 175.29 141.081 175.29H147.823C149.684 175.29 151.194 173.781 151.194 171.919C151.194 170.058 149.684 168.548 147.823 168.548Z"
      fill="#6F8894"
    />
    <path
      d="M200.359 91.0469C200.359 86.6719 200.891 83.1875 201.953 80.5938C203.016 78 204.953 75.4531 207.766 72.9531C210.609 70.4219 212.5 68.375 213.438 66.8125C214.375 65.2188 214.844 63.5469 214.844 61.7969C214.844 56.5156 212.406 53.875 207.531 53.875C205.219 53.875 203.359 54.5938 201.953 56.0312C200.578 57.4375 199.859 59.3906 199.797 61.8906H186.203C186.266 55.9219 188.188 51.25 191.969 47.875C195.781 44.5 200.969 42.8125 207.531 42.8125C214.156 42.8125 219.297 44.4219 222.953 47.6406C226.609 50.8281 228.438 55.3438 228.438 61.1875C228.438 63.8438 227.844 66.3594 226.656 68.7344C225.469 71.0781 223.391 73.6875 220.422 76.5625L216.625 80.1719C214.25 82.4531 212.891 85.125 212.547 88.1875L212.359 91.0469H200.359ZM199 105.438C199 103.344 199.703 101.625 201.109 100.281C202.547 98.9062 204.375 98.2188 206.594 98.2188C208.812 98.2188 210.625 98.9062 212.031 100.281C213.469 101.625 214.188 103.344 214.188 105.438C214.188 107.5 213.484 109.203 212.078 110.547C210.703 111.891 208.875 112.562 206.594 112.562C204.312 112.562 202.469 111.891 201.062 110.547C199.688 109.203 199 107.5 199 105.438Z"
      fill="#6F8894"
    />
  </svg>
</template>

<script>
export default {
  name: "Empty",
};
</script>
