<template lang="html">
  <aside class="modal-patient-history">
    <RgBaseModal
      ref="patientDetails"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <span class="title">Histórico do Paciente</span>
      </div>

      <div slot="body" class="body">
        <div class="information">
          <span class="text">
            Paciente:
            <strong>{{
              patientData.pac_nome ? patientData.pac_nome : "Não Informado"
            }}</strong>
          </span>
        </div>

        <div class="search">
          <RgComboboxModules
            v-model="form.selectedModule"
            default-text="Todos"
          />

          <RgInputDate
            ref="validatorDateInitial"
            v-model="form.initialDate"
            class="input -small"
            label="Data Inicial"
            placeholder="dd/mm/aaaa"
          />

          <RgInputDate
            ref="validatorDate"
            v-model="form.finalDate"
            class="input -small"
            label="Data Final"
            placeholder="dd/mm/aaaa"
          />

          <div class="input -medium radio-component">
            <label class="text">Regulação</label>

            <div class="radio">
              <div class="radio unselect">
                <input
                  v-model="form.regulation"
                  type="radio"
                  class="-pointer"
                  name="regulation"
                  value="1"
                />
                <label class="unselect">Sim</label>
              </div>

              <div class="radio unselect">
                <input
                  v-model="form.regulation"
                  type="radio"
                  class="-pointer"
                  name="regulation"
                  value="0"
                />
                <label class="unselect">Não</label>
              </div>

              <div class="radio unselect">
                <input
                  v-model="form.regulation"
                  type="radio"
                  class="-pointer"
                  name="regulation"
                  value="2"
                  checked
                />
                <label class="unselect">Ambos</label>
              </div>
            </div>
          </div>

          <RgInput
            v-model="form.searchAll"
            class="input -large"
            label="Busque aqui"
            placeholder="Digite a busca"
          />

          <div class="buttons-filter unselect">
            <RgCleanButton
              small
              data-id="limpar"
              class="clean"
              title="Limpar"
              type="button"
              @click="cleanSearchForm"
            />
            <RgSearchButton
              ref="btnSubmit"
              v-shortkey="['enter']"
              :class="{ 'disable unselect': isTherePatient }"
              :disabled="isTherePatient"
              small
              data-id="buscar"
              class="btn search"
              label="Buscar"
              title="Buscar"
              @submit="searchHistory"
              @shortkey.native="searchHistory"
            />
          </div>
        </div>

        <RgList
          ref="rgList"
          v-model="mutableHistoryList"
          :search-function="search"
          :build-filter="generateFilter"
          :show-total="false"
          :register-per-page="8"
          :max-register="8"
          class="font-size"
        >
          <div v-show="!hasCountListHistory" class="no-result">
            <IconEmpty class="svg" />
            <span class="info"
              >Não foram encontrados resultados para essa busca</span
            >
          </div>

          <div class="data-history">
            <table v-show="hasCountListHistory" class="table-info">
              <tr class="tableRow titleheader">
                <th class="tableTitle">Módulo</th>
                <th class="tableTitle">Unidade de Saúde</th>
                <th class="tableTitle">Setor</th>
                <th class="tableTitle">Data</th>
                <th class="tableTitle">Hora</th>
                <th class="tableTitle">Usuário</th>
                <th class="tableTitle">Regulação</th>
              </tr>

              <tr
                v-for="(item, index) in mutableHistoryList"
                :key="index"
                :class="{ active: index === activeRow }"
                class="tableRow content"
                v-on:click="selectLine(item, index)"
                @click="getHistoryPatientDetails(item, index)"
              >
                <td class="tableData limit-text-120">
                  {{ item.nome_modulo }}
                </td>

                <td class="tableData limit-text-120">
                  {{ item.uns_nome }}
                </td>

                <td class="tableData limit-text-120">
                  {{ item.set_nome }}
                </td>

                <td class="tableData">{{ item.data }}</td>

                <td class="tableData">{{ item.hora }}</td>

                <td class="tableData limit-text-120">
                  {{ item.usu_nome }}
                </td>

                <td class="tableData">
                  {{ item && item.regulacao === 0 ? "NÃO" : "SIM" }}
                </td>

                <td class="tableData arrow-body">
                  <div class="arrow" />
                </td>
              </tr>
            </table>

            <div
              v-show="hasCountListHistory && activeRow !== false"
              :class="{ 'disable-component': false }"
              class="modal-patient-history-extrainfo"
            >
              <span class="infotitle">Detalhes</span>

              <ExtraDetailsPatientHistory
                :info-details="selectedData"
                :extra-details="extraDetails"
              />
            </div>
          </div>
        </RgList>
      </div>
    </RgBaseModal>
  </aside>
</template>

<script>
import { RgBaseModal, RgInput, RgInputDate } from "~tokio/primitive";
import ExtraDetailsPatientHistory from "../../extra-details-patient-history/ExtraDetailsPatientHistory.vue";
import RgComboboxModules from "$hospitalization/common/component/combobox/rg-combobox-modules/RgComboboxModules";
import RgSearchButton from "~tokio/primitive/button/rg-search-button/RgSearchButton";
import RgCleanButton from "~tokio/primitive/button/rg-clean-button/RgCleanButton";
import RgList from "~tokio/foundation/rg-list/RgList.vue";
import { IconEmpty } from "~tokio/primitive/icon/symbols";
import moment from "moment";

export default {
  name: "ModalPatientHistory",
  components: {
    RgBaseModal,
    RgInput,
    RgInputDate,
    RgSearchButton,
    RgCleanButton,
    RgList,
    RgComboboxModules,
    ExtraDetailsPatientHistory,
    IconEmpty,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    patientData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      mutableHistoryList: null,
      activeRow: false,
      modules: null,
      countHistoryList: 0,
      selectedData: {},
      isEmpty: false,
      extraDetails: null,
      form: {
        selectedModule: null,
        initialDate: "",
        finalDate: moment().format("DD/MM/YYYY"),
        regulation: 2,
        searchAll: "",
      },
    };
  },
  computed: {
    isTherePatient() {
      return !this.patientData.pac_id;
    },

    hasCountListHistory() {
      return this.countHistoryList > 0;
    },
  },
  watch: {
    show(pValue) {
      if (pValue) {
        this.searchHistory();
      } else {
        this.close();
      }
    },

    async mutableHistoryList(pValue, pIndex) {
      if (pValue) {
        this.countHistoryList = pValue.length;

        if (pValue.length > 0) {
          const first = pValue[0];
          await this.getHistoryPatientDetails(first, 0);
        }
      }
    },
  },
  methods: {
    search(pData) {
      const hasPersonId = pData && pData.pes_id > 0;

      if (hasPersonId) {
        return this.$store.dispatch("Patient/SEARCH_PATIENT_HISTORY", {
          ...pData,
        });
      }
    },

    generateFilter() {
      const variables = {
        id_modulo: this.form.selectedModule || null,
        pes_id: this.patientData.pes_id,
        initialDate:
          this.$utils.date.BrazilianDateToDatabase(this.form.initialDate) ||
          null,
        finalDate:
          this.$utils.date.BrazilianDateToDatabase(this.form.finalDate) ||
          moment().format("YYYY-MM-DD"),
        regulacao: parseInt(this.form.regulation),
        searchAll: this.form.searchAll || null,
      };
      return variables;
    },

    async searchHistory() {
      try {
        if (this.patientData.pes_id) {
          this.$refs.btnSubmit.actionSubmit();
          await this.$refs.rgList.submitForm(true);
          this.$refs.btnSubmit.actionDone();
        } else {
          // throw new Error('Paciente não existe ou não foi informado')
        }
      } catch (pErr) {
        console.log(pErr);
        // this.$toaster.error('Erro ao Buscar Histórico', '', { preventDuplicates: true })
        this.$refs.btnSubmit.fail();
      }
    },

    async getHistoryPatientDetails(item, index) {
      try {
        this.selectedData = item;
        const details = await this.$store.dispatch(
          "Patient/SEARCH_PATIENT_HISTORY_DETAILS",
          {
            id: this.selectedData.id,
            id_modulo: this.selectedData.id_modulo,
            id_fila: this.selectedData.id_fila,
          },
        );
        this.extraDetails = details[0] || null;
      } catch (pErr) {
        console.log("pErr", pErr);
      }
    },

    selectLine(pValue, pIndex) {
      this.activeRow = pIndex;
    },

    cleanSearchForm() {
      this.form.initialDate = "";
      this.form.finalDate = moment().format("DD/MM/YYYY");
      this.form.searchAll = "";
      this.form.regulation = 2;
      this.form.selectedModule = null;
      this.$refs.validatorDate.cleanValidate();
      this.$refs.validatorDateInitial.cleanValidate();
      this.activeRow = false;
      this.searchHistory();
    },

    close() {
      this.cleanSearchForm();
      this.$refs.rgList.resetLimitOffset();
      this.$emit("close");
    },
  },
};
</script>
