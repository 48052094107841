<template>
  <svg
    id="Capa_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M455.41,281.927c-3.384-4.39-9.687-5.206-14.08-1.821c-4.392,3.385-5.207,9.689-1.822,14.08
			c10.321,13.388,15.776,29.425,15.776,46.376c0,5.544,4.495,10.039,10.039,10.039s10.039-4.496,10.039-10.039
			C475.362,319.135,468.463,298.859,455.41,281.927z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M421.676,214.571c20.383-13.757,33.813-37.064,33.813-63.449c0-42.187-34.322-76.509-76.509-76.509
			c-42.187,0-76.509,34.322-76.509,76.509c0,26.373,13.417,49.671,33.785,63.431c-14.838,5.023-28.73,12.672-41.064,22.701
			c-10.436-10.037-24.442-15.818-39.191-15.818c-14.748,0-28.754,5.783-39.184,15.818c-12.338-10.029-26.232-17.678-41.072-22.702
			c20.368-13.76,33.785-37.058,33.785-63.432c0-42.187-34.322-76.509-76.509-76.509c-42.182,0-76.499,34.322-76.499,76.509
			c0,26.385,13.427,49.691,33.807,63.449C37.941,232.37,0.127,281.994,0.014,340.301C0.011,340.39,0,340.474,0,340.562
			c0,15.413,8.758,38.16,50.484,59c29.732,14.85,70.59,26.013,118.158,32.28c27.902,3.68,57.294,5.547,87.359,5.547
			c30.072,0,59.467-1.866,87.368-5.547c47.564-6.267,88.419-17.43,118.15-32.28c41.723-20.841,50.481-43.587,50.481-59
			C512,282.143,474.145,232.399,421.676,214.571z M322.55,151.122c0-31.116,25.315-56.43,56.43-56.43s56.43,25.315,56.43,56.43
			c0,31.111-25.315,56.42-56.43,56.42S322.55,182.233,322.55,151.122z M226.046,257.199c6.821-9.82,18.017-15.684,29.954-15.684
			c11.943,0,23.143,5.864,29.963,15.684c0.001,0.003,0.003,0.005,0.004,0.007c4.256,6.119,6.504,13.304,6.504,20.781
			c0,20.111-16.361,36.472-36.472,36.472c-20.106,0-36.462-16.362-36.462-36.473C219.537,270.498,221.784,263.315,226.046,257.199z
      M76.599,151.122c0-31.116,25.31-56.43,56.42-56.43c31.116,0,56.43,25.315,56.43,56.43c0,31.111-25.315,56.42-56.43,56.42
			C101.909,207.542,76.599,182.233,76.599,151.122z M159.919,410.323c-40.485-6.165-75.045-16.028-100.462-28.723
			c-25.32-12.645-39.292-27.173-39.373-40.918c0-0.04,0.006-0.079,0.006-0.119c-0.001-62.276,50.66-112.942,112.93-112.942
			c26.492,0,51.767,9.152,71.993,25.915c-3.647,7.579-5.553,15.888-5.553,24.452c0,17.477,7.971,33.126,20.465,43.508
			C184.834,335.763,160.01,370.191,159.919,410.323z M332.017,413.01c-24.419,2.849-49.941,4.302-76.017,4.302
			c-26.07,0-51.588-1.453-76.007-4.302v-2.465c0-41.911,34.096-76.007,76.007-76.007c41.916,0,76.017,34.096,76.017,76.007V413.01z
      M452.546,381.599c-25.415,12.696-59.971,22.558-100.452,28.723c-0.091-40.132-24.917-74.558-60.011-88.827
			c12.497-10.382,20.47-26.031,20.47-43.509c0-8.554-1.906-16.865-5.557-24.451c20.217-16.764,45.489-25.916,71.986-25.916
			c62.275,0.001,112.94,50.667,112.94,112.942C491.922,354.343,477.938,368.917,452.546,381.599z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M425.328,258.344c-12.919,0-12.942,20.078,0,20.078C438.248,278.422,438.269,258.344,425.328,258.344z"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "Family",
};
</script>
