import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

const arrayExceptions = [
  "NaoPossuiPermissaoException",
  "QuantidadeConsultaAlteracaoInvalidaException",
  "HorarioFinalInvalidoException",
  "HorarioInicialInvalidoException",
];

export default async (context, variables) => {
  return AuthLegacyRequest.post(
    "exames2/controller-exame-historico-agendamento/obter-dados-escala",
    variables,
  )
    .then(({ data }) => {
      const { dados, trace } = data;

      if (arrayExceptions.includes(dados)) {
        throw new Error(trace);
      }

      return dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
