const BedSituations = {
  VAZIO: 1,
  RESERVA: 2,
  OCUPADO: 3,
  DESINFECCAO: 4,
  MANUTENCAO: 5,
};

const GetGQLEnum = (pValue) => {
  const flipped = Object.entries(BedSituations).reduce(
    (obj, [key, value]) => ({ ...obj, [value]: key }),
    {},
  );

  return flipped[pValue];
};

export { BedSituations, GetGQLEnum };

export default {
  ...BedSituations,
};
