import Request from "~common/request";
import GetOperationsPermissions from "~tokio/foundation/main-header/main-menu/menu-map/GetOperationsPermissions";

const getExtraPermissions = ["cadastro.pessoa.alterar"];

export default async (credentials, useLegacyBackend) => {
  const request = new Request(window.esusng.serverConfig);
  const requestLegacy = new Request(window.esusng.legacyServerConfig);
  try {
    const namespaces = GetOperationsPermissions().concat(getExtraPermissions);
    const loginResponse = await request.post(
      "/access-control/login-light",
      Object.assign({ namespaces }, credentials),
    );
    let legacyInfo = {
      token: null,
    };
    if (useLegacyBackend === true) {
      legacyInfo = await requestLegacy
        .post("/niveisacesso/login-sem-permissoes", {
          usuario: credentials.username,
          senha: credentials.password,
          cliente: credentials.client,
        })
        .then((response) => {
          return response.data;
        });
    }

    return {
      data: loginResponse.data,
      legacy: legacyInfo,
    };
  } catch (err) {
    throw new Error("Falha ao Realizar Login");
  }
};
