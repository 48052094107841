import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchPatientHospitalized($patientId: Int!) {
    patientHospitalized(patientId: $patientId)
  }
`;
export default async (variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    return data.patientHospitalized;
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors);
  }
};
