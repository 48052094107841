import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query GetPrintPendencyQueue($fipId: [Int]!) {
    GetPrintPendencyQueue(fip_id: $fipId) {
      html
    }
  }
`;

export default async (context, { fipId = [] }) => {
  const variables = {
    fipId,
  };
  const { data } = await GraphQLClient.query({
    query,
    variables,
    fetchPolicy: "no-cache",
  }).catch((err) => {
    console.error(err);
  });

  return data.GetPrintPendencyQueue.html;
};
