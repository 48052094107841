<style src="./RgFormBase.scss" lang="scss" scoped></style>
<template lang="html">
  <div class="rg-form-base">
    <div class="rg-form-base--label-container" :class="{ 'in-line': inLine }">
      <div v-if="label.length > 0" class="rg-form-base--label">
        <span
          v-if="hasLabel"
          class="rg-form-base--caption"
          :class="{ 'in-line': inLine }"
        >
          {{ label }}
          <slot name="right-label" />
          <span
            v-if="required"
            class="required-field unselect"
            title="Campo obrigatório"
            >*</span
          >
        </span>
        <span v-if="optional" class="rg-form-base--optional">
          ( OPCIONAL )
        </span>
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "RgFormBase",
  props: {
    label: {
      type: String,
      default: "",
    },
    optional: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    inLine: Boolean,
    hasLabel: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
};
</script>
