import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  /*
    PAYLOAD DO METODO

    [fil_id_prioridades_fila] => 320
    [flc_nome_sinonimos_ocupacoes] => DERMATOLOGISTA
    [fil_id_unidades_saude] => 2
    [fil_id_pessoas] => 242072
    [fil_data_pedido] => 03/12/2020
    [fil_id_funcionarios] => 1039
    [flc_id_sinonimos_ocupacoes_cbo] => 33
    [fil_id_unidades_saude_solicitante] => 2
    [fio_observacao] =>
    [fil_id_agendado_por] => 407
    [fil_conselho_classe] =>
  */
  const sendData = {
    arrFormData: variables,
  };
  return AuthLegacyRequest.post(
    "/consultas/controller-fila-consulta/adicionar",
    sendData,
  )
    .then(({ data }) => {
      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
