<template lang="html">
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.4238 1.42853C6.68571 1.42853 2.85714 5.26661 2.85714 9.99996H0L3.70951 13.7095L3.77616 13.8475L7.61906 9.99996H4.76192C4.76192 6.31902 7.74763 3.3333 11.4286 3.3333C15.1095 3.3333 18.0952 6.31902 18.0952 9.99996C18.0952 13.6809 15.1095 16.6666 11.4286 16.6666C9.58571 16.6666 7.9238 15.9142 6.71906 14.7095L5.37143 16.0571C6.91906 17.6095 9.05714 18.5714 11.4238 18.5714C16.1619 18.5714 20 14.7333 20 9.99996C20 5.26661 16.1619 1.42853 11.4238 1.42853Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "Synchronize",
};
</script>
