import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query bathroomDrain {
    SearchBathroomDrain(limit: 100) {
      rows {
        eba_id
        eba_codigo_ab
        eba_nome_escoamento
      }
      count
    }
  }
`;

export default async ({ commit, state }) => {
  if (state.bathroom_drain.length > 0) {
    return;
  }
  try {
    const { data } = await GraphQLClient.query({
      query,
      fetchPolicy: "no-cache",
    });
    commit("SET_BATHROOM_DRAIN", data.SearchBathroomDrain.rows);
  } catch (err) {
    console.log(err);
    return err.graphQLErrors;
  }
};
