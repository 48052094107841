<script>
import { RgRemoteComboboxMixin } from "~tokio/primitive";
import SearchHospitalizationTypeHospitalization from "./action/SearchHospitalizationTypeHospitalization";

export default {
  name: "RgComboboxHospitalizationType",
  mixins: [RgRemoteComboboxMixin],
  props: {
    label: {
      type: String,
      default: "Tipo de Internação",
    },
    dataId: {
      type: String,
      default: "",
    },
    defaultText: {
      type: String,
      default: "Selecione",
    },
    emptyDefaultText: {
      type: String,
      default: "Nenhum Item para Selecionar",
    },
  },
  data() {
    return {};
  },
  methods: {
    doSearch() {
      return SearchHospitalizationTypeHospitalization({});
    },
    formatItem(item) {
      return {
        value: item.iti_id,
        text: item.iti_nome,
      };
    },
  },
};
</script>
