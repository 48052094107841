import LocalStorage from "~common/local-storage/LocalStorage";

const ScheduleOutpatientConsultationControl = (employeeData, snap) => {
  const logo = LocalStorage.getObject("client-logo");
  const texto = "FICHA DE CONTROLE AMBULATORIAL";

  let dadosTabela = "";
  let number = 1;
  let unithealth = "";

  if (snap[0].uns_nome) {
    unithealth += `<div><label>Unidade de Saúde: </label><span>${snap[0].uns_nome}</span> </div>`;
  } else {
    unithealth += `<div><label>Unidade de Saúde: </label><span>${snap[0].unithealth}</span> </div>`;
  }

  snap.forEach((item) => {
    "<tr>";
    dadosTabela += "<td>" + number++ + "</td>";
    dadosTabela += "<td>" + item.eha_data_exame + "</td>";
    dadosTabela += "<td>" + item.eap_hora + "</td>";
    dadosTabela += "<td>" + item.eap_vagas_ocupadas + "</td>";
    dadosTabela += "<td>" + item.pes_nome + "</td>";
    dadosTabela += "<td>" + item.stp_novo_nome_procedimento + "</td>";
    dadosTabela += "<td>" + item.subProcedimento + "</td></tr>";
  });

  const html = `
  <!DOCTYPE html>
  <html>
  <head>
    <meta charset="utf-8">
    <title>Esus</title>
    <style type="text/css">
      body {
        font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
        /*background: rgb(204,204,204);*/
      }

      h1 {
        font-size: 12pt;
        text-transform: uppercase;
        text-align: center;
      }

      h2 {
        font-size: 11pt;
        font-style: italic;
        text-transform: uppercase;
      }

      h3 {
        font-size: 10pt;
        text-align: center;
        text-transform: uppercase;
      }

      hr {
        margin: 30px 0;
      }

      label,
      span,
      p {
        font-size: 10pt;
      }

      label {
        font-weight: 700;
      }

      table {
        width: 100%;
        font-size: 11px;
        font-weight: none;
        top: 10%;
      }

      tr {
        border: 1px solid grey;
      }

      th {
        border: 1px solid grey;
      }

      td {
        border: 1px solid grey;
      }

      page {
        background: white;
        display: block;
        margin: 0 auto;
        margin-bottom: 0.5cm;
        /*box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);*/
      }

      page[size="A4"] {
        width: 21cm;
        height: 29.7cm;
      }

      .logo-cliente {
        width: 70px;
        display: block;
        margin: 0 90%;
        padding-top: 0.5cm;
      }

      .info-fila div {
        margin-bottom: 6px;
      }

      .info-fila-observacao {
        border-top: 1px solid #888;
        padding: 5pt 0 0 0;
      }

      .ocultar {
        display: none;
      }

      .mostrar {
        display: block;
      }
    </style>
  </head>

  <body>
    <page size="A4">
      <div>
        <h1>${employeeData.client}</h1>
        <img class="logo-cliente" src="${logo}">
      </div>

      <div class="info-fila">
        <h3>${texto}</h3>
        ${unithealth}
        <div><label>Data: </label> <span>${
          employeeData.data_exame
            ? employeeData.data_exame
            : employeeData.DataExame
        }</span> </div>
        <div><label>Profissional: </label> <span>${
          employeeData.pes_nome ? employeeData.pes_nome : employeeData.fun_nome
        }</span> </div>
      </div>

      <table class="table">
        <tr class="header">
          <th> Nº </th>
          <th>Data</th>
          <th>Hora</th>
          <th>Vagas Ocupadas</th>
		      <th>Paciente</th>
          <th>Procedimento</th>
          <th>SubProcedimentos</th>
        </tr>

        <div class="table">
          ${dadosTabela}
        <div>
      </table>
    </page>
  </body>

  </html>
    `;
  return html;
};

export default ScheduleOutpatientConsultationControl;
