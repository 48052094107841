import MedicalLoaderStore from "./store/index";
import MedicalLoaderGlobal from "./MedicalLoaderGlobal";

export const createLoader = (store) => {
  const hasModule = store.hasModule("MedicalLoader"); // Object.keys(store._modulesNamespaceMap).findIndex(i => i.startsWith('MedicalLoader')) >= 0
  if (!hasModule) {
    store.registerModule("MedicalLoader", MedicalLoaderStore);
  }
  return {
    start: (message) => {
      store.commit("MedicalLoader/START_LOADER", message);
    },
    message: (message) => {
      if (!message) return;
      store.commit("MedicalLoader/UPDATE_MESSAGE", message);
    },
    finish: (timeout = 1000, message) => {
      if (message) {
        store.commit("MedicalLoader/UPDATE_MESSAGE", message);
      }
      setTimeout(() => {
        store.commit("MedicalLoader/CLEAN_LOADER", message);
      }, timeout);
    },
  };
};

export default function installed(_Vue, store) {
  const loader = createLoader(store);
  _Vue.component("MedicalLoaderGlobal", MedicalLoaderGlobal);
  _Vue.prototype.$loader = loader;
  return true;
}
