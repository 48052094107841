<template>
  <section class="birth-certificate-document">
    <FormBase
      title="Certidão de Nascimento"
      class="form-base-birth-certificate"
    >
      <div class="birth-certificate">
        <div class="grid">
          <div class="row-1">
            <RgInput
              ref="registry"
              v-model="form.ctc_cartorio"
              class="registry"
              data-id="número-certidão-nascimento"
              label="Cartório"
              title="Número da Certidão de Nascimento"
              :rules="{ required: required }"
              :change="disableOrEnabledRequired"
              :maxlength="100"
            />

            <RgInput
              ref="book"
              v-model="form.ctc_livro"
              class="book"
              data-id="livro-certidão-nascimento"
              label="Livro"
              title="Número da Certidão de Nascimento"
              :rules="{ required: required }"
              :change="disableOrEnabledRequired"
              :maxlength="20"
            />

            <RgInput
              ref="leaf"
              v-model="form.ctc_folha"
              class="leaf"
              data-id="folha-certidão-nascimento"
              label="Folha"
              title="Número da Certidão de Nascimento"
              :rules="{ required: required }"
              :change="disableOrEnabledRequired"
              :maxlength="4"
            />
          </div>

          <div class="row-2">
            <RgInput
              ref="term"
              v-model="form.ctc_termo"
              class="term"
              data-id="termo-certidão-nascimento"
              label="Termo"
              title="Número da Certidão de Nascimento"
              :rules="{ required: required }"
              :change="disableOrEnabledRequired"
              :maxlength="8"
            />

            <RgInputDate
              ref="dateIssue"
              v-model="form.ctc_emissao"
              class="dateIssue"
              data-id="data-emissão-certidão-nascimento"
              label="Data de Emissão"
              placeholder="dd/mm/aaaa"
              title="Número da Certidão de Nascimento"
              :rules="{ required: required }"
              :max-date="new Date()"
            />
          </div>
        </div>

        <!-- <div class="attachment">
          <rg-attachment-button
            :class="{ 'disable': disabledAttachment }"
            :disbaled="disabledAttachment"
            class="button"
            @click="clicked"
          />
        </div> -->
      </div>
    </FormBase>
  </section>
</template>

<script>
import FormBase from "~tokio/foundation/form-base/FormBase";
import { RgInput, RgInputDate } from "~tokio/primitive";

export default {
  name: "BirthCertifcateDocument",
  components: {
    FormBase,
    RgInput,
    RgInputDate,
  },
  props: {
    birthCertificateData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        ctc_id: 0,
        ctc_id_tipos_documento: 0,
        ctc_cartorio: "",
        ctc_livro: "",
        ctc_folha: "",
        ctc_termo: "",
        ctc_emissao: "",
      },
      required: false,
    };
  },
  computed: {
    disabledAttachment() {
      const registry =
        this.form.ctc_cartorio && this.form.ctc_cartorio.length > 0;
      const book = this.form.ctc_livro && this.form.ctc_livro.length > 0;
      const leaf = this.form.ctc_folha && this.form.ctc_folha.length > 0;
      const term = this.form.ctc_termo && this.form.ctc_termo.length > 0;
      const dateIssue =
        this.form.ctc_emissao && this.form.ctc_emissao.length >= 10;

      return !(registry && book && leaf && term && dateIssue);
    },
  },
  watch: {
    birthCertificateData(pValue) {
      if (pValue) {
        this.mountBirthCertificate(pValue);
      }
    },
    "form.ctc_emissao"() {
      this.disableOrEnabledRequired();
    },
  },
  methods: {
    mountBirthCertificate(pData) {
      this.form = {
        ctc_id: pData.ctc_id || 0,
        ctc_id_tipos_documento: pData.ctc_id_tipos_documento || 0,
        ctc_cartorio: pData.ctc_cartorio,
        ctc_livro: pData.ctc_livro,
        ctc_folha: pData.ctc_folha,
        ctc_termo: pData.ctc_termo,
        ctc_emissao: pData.ctc_emissao,
      };
    },

    getBirthCertificate() {
      return this.form;
    },

    cleanForm() {
      this.form = {
        ctc_id: 0,
        ctc_id_tipos_documento: 0,
        ctc_cartorio: "",
        ctc_livro: "",
        ctc_folha: "",
        ctc_termo: "",
        ctc_emissao: "",
      };
      this.cleanValidate();
    },

    clicked() {
      this.$emit("click");
    },

    disableOrEnabledRequired() {
      this.required =
        !!this.form.ctc_cartorio ||
        !!this.form.ctc_livro ||
        !!this.form.ctc_folha ||
        !!this.form.ctc_termo ||
        !!this.form.ctc_emissao;

      if (!this.required) {
        this.cleanValidate();
      }
    },

    cleanValidate() {
      this.$refs.registry.cleanValidate();
      this.$refs.book.cleanValidate();
      this.$refs.leaf.cleanValidate();
      this.$refs.term.cleanValidate();
      this.$refs.dateIssue.cleanValidate();
      this.required = false;
    },
  },
};
</script>
