<template lang="html">
  <svg
    width="76"
    height="76"
    viewBox="0 0 76 76"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 22.038C18 19.8079 19.8079 18 22.038 18H32.1329C34.3631 18 36.1709 19.8079 36.1709 22.038V32.1329C36.1709 34.3631 34.3631 36.1709 32.1329 36.1709H22.038C19.8079 36.1709 18 34.3631 18 32.1329V22.038Z"
      fill="white"
    />
    <path
      d="M18 43.9363C18 41.7062 19.8079 39.8983 22.038 39.8983H32.1329C34.3631 39.8983 36.1709 41.7062 36.1709 43.9363V54.0313C36.1709 56.2614 34.3631 58.0692 32.1329 58.0692H22.038C19.8079 58.0692 18 56.2614 18 54.0313V43.9363Z"
      fill="white"
    />
    <path
      d="M40.2087 22.038C40.2087 19.8079 42.0166 18 44.2467 18H54.3417C56.5718 18 58.3797 19.8079 58.3797 22.038V32.1329C58.3797 34.3631 56.5718 36.1709 54.3417 36.1709H44.2467C42.0166 36.1709 40.2087 34.3631 40.2087 32.1329V22.038Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M54.3417 42.2279H44.2467C43.3032 42.2279 42.5383 42.9928 42.5383 43.9363V54.0313C42.5383 54.9748 43.3032 55.7396 44.2467 55.7396H54.3417C55.2852 55.7396 56.0501 54.9748 56.0501 54.0313V43.9363C56.0501 42.9928 55.2852 42.2279 54.3417 42.2279ZM44.2467 39.8983C42.0166 39.8983 40.2087 41.7062 40.2087 43.9363V54.0313C40.2087 56.2614 42.0166 58.0692 44.2467 58.0692H54.3417C56.5718 58.0692 58.3797 56.2614 58.3797 54.0313V43.9363C58.3797 41.7062 56.5718 39.8983 54.3417 39.8983H44.2467Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "Modules",
};
</script>
