<script>
import { RgRemoteComboboxMixin } from "~tokio/primitive";
import SearchProceduresFilter from "./action/SearchProceduresFilter";

export default {
  name: "RgComboboxProceduresFilter",
  mixins: [RgRemoteComboboxMixin],
  props: {
    variable: Object,
  },
  data() {
    return {
      userId: this.$store.getters["Login/GET_USER_ID"],
    };
  },
  watch: {
    variable(value) {
      this.getData();
    },
  },
  methods: {
    doSearch() {
      if (this.variable.intIdSetor === null) return Promise.resolve([]);

      return SearchProceduresFilter(this.variable);
    },
    formatItem(item) {
      return {
        value: item.data,
        text: `${item.stp_novo_nome_procedimento}`,
        item: item,
      };
    },
    blur() {
      if (this.isNecessary) {
        this.validate();
        this.$emit("blur");
      }
    },
  },
};
</script>
