<style src="./SurgeryDetails.scss" lang="scss" scoped></style>
<template lang="html">
  <li class="surgery-details">
    <div class="detail-main">
      <span class="label--patient">{{ data.patient }}</span>
    </div>
    <div class="detail-description">
      <span class="label--doctor">Dr(a) {{ data.requesting_doctor }}</span>
      <span class="label--sector">{{ data.requesting_clinic }}</span>
    </div>
    <div class="detail-footer">
      <div class="footer-surgery-code">
        <span class="label--surgery-code">Nº Cirurgia</span>
        <span class="value--surgery-code">{{ data.cci_numero }}</span>
      </div>
      <div v-if="data.cci_reserva_sangue" class="footer-blood">
        <IconBlood class="icon--blood" />
        <span class="label--blood">RS</span>
      </div>
      <div v-if="data.cci_cti" class="footer-cti">
        <IconCti class="icon--cti" />
        <span class="label--cti">CTI</span>
      </div>
      <div v-if="data.cci_id_raios_x" class="footer-x-ray">
        <IconXray class="icon--x-ray" />
        <span class="label--x-ray">Raio-X</span>
      </div>
    </div>
    <div class="detail-createdat">
      <span class="label--surgery-createdat">Criado em</span>
      <span class="value--surgery-createdat">{{
        data.cci_data_criacao | createdAt
      }}</span>
    </div>
  </li>
</template>

<script>
import { IconCti, IconBlood, IconXray } from "~tokio/primitive";
import moment from "moment";

export default {
  name: "SurgeryDetails",
  components: {
    IconBlood,
    IconCti,
    IconXray,
  },
  filters: {
    createdAt(pValue) {
      return moment(pValue).format("DD/MM/YYYY HH:mm");
    },
  },
  props: {
    data: {
      required: true,
      type: Object,
    },
  },
};
</script>
