import { QZRawPrint } from "~common/utils/QZTray";

const PrintTag = async (pData, pPrinter) => {
  try {
    if (!pPrinter) {
      throw new Error("Uma impressora deve ser especificada para a impressão.");
    }

    return QZRawPrint(pData, pPrinter);
  } catch (error) {
    console.log("PrintTag", error);
    throw new Error(error);
  }
};

export default PrintTag;
