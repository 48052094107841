import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query AreaTeam($unitHealh: String) {
    SearchArea(are_id_unidades_saude: $unitHealh) {
      rows {
        teams {
          rows {
            equ_id
            equ_id_tipos_equipe
            equ_id_areas
            equ_codigo_ine
            equ_nome_equipe
          }
          count
        }
        are_id
        are_id_zonas
        are_id_unidades_saude
        are_nome
        are_descricao
      }
      count
    }
  }
`;

export default async (context, unitHealthId) => {
  try {
    const variables = {
      unitHealh: unitHealthId,
    };
    const { data } = await GraphQLClient.query({
      query,
      fetchPolicy: "no-cache",
      variables,
    });
    return data;
  } catch (err) {
    console.log(err);
    return err.graphQLErrors;
  }
};
