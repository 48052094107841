import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query People($pesId: Int) {
    people(pes_id: $pesId) {
      rows {
        cpf {
          cpf_numero
        }
      }
    }
  }
`;

export default async ({ pesId }) => {
  if (!pesId) return Promise.reject(Error("pesId não informado"));
  const variables = {
    pesId,
  };

  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.people.rows[0];
  } catch (err) {
    return Promise.reject(err.graphQLErrors);
  }
};
