<template lang="html">
  <svg
    width="7.351mm"
    height="7.351mm"
    version="1.1"
    viewBox="0 0 7.351 7.351"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="matrix(.014105 0 0 .014105 .06453 .06453)">
      <path
        d="m256 0c-141.38 0-256 114.62-256 256s114.62 256 256 256 256-114.62 256-256-114.62-256-256-256zm0 467c-116.35 0-211-94.654-211-211s94.654-211 211-211 211 94.654 211 211-94.654 211-211 211z"
      />
      <path
        d="m256 75c-99.804 0-181 81.196-181 181s81.196 181 181 181 181-81.196 181-181-81.196-181-181-181zm15 187.21-100.56 100.56-21.213-21.213 91.773-91.773v-129.79h30z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconClockBioslab",
};
</script>
