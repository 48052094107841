var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modulebox',{staticClass:"appointment-objective",attrs:{"granted":_vm.hasAllPermissions,"title":"Objetivos de Consulta"}},[_c('form',{ref:"element",staticClass:"form-container",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.saveObjective($event)}}},[_c('RgValidatorForm',{ref:"validator"},[_c('FormBase',{staticClass:"register-form",attrs:{"title":"Objetivo"}},[_c('div',{staticClass:"form"},[_c('div',{staticClass:"inputs"},[_c('RgInput',{ref:"objective",class:{
                disable: _vm.disableOnEditImported,
              },attrs:{"rules":{ required: true },"disabled":_vm.disableOnEditImported,"maxlength":255,"label":"Objetivo","placeholder":"Digite o nome do novo objetivo"},model:{value:(_vm.form.objective),callback:function ($$v) {_vm.$set(_vm.form, "objective", $$v)},expression:"form.objective"}}),_c('RgComboboxUnithealth',{ref:"unitHealth",class:{ disable: this.edit || !this.canInclude },attrs:{"rules":{ required: true },"disabled":this.edit || !this.canInclude,"permission":"consulta.objetivoConsulta","label":"Unidade de Saúde"},model:{value:(_vm.form.unitHealth),callback:function ($$v) {_vm.$set(_vm.form, "unitHealth", $$v)},expression:"form.unitHealth"}}),_c('RgComboboxHealthPlan',{ref:"healthInsurance",class:{ disable: !_vm.canInclude && !_vm.disableWhenEditing },attrs:{"rules":{ required: true },"disabled":!_vm.canInclude && !_vm.disableWhenEditing,"label":"Convênio"},model:{value:(_vm.form.healthInsurance),callback:function ($$v) {_vm.$set(_vm.form, "healthInsurance", $$v)},expression:"form.healthInsurance"}}),_c('RgInput',{ref:"code",class:{
                disable: _vm.disableOnEditImported,
              },attrs:{"rules":{ required: true },"disabled":_vm.disableOnEditImported,"maxlength":10,"label":"Código","placeholder":"000000000000"},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}}),_c('RgInput',{ref:"cost",class:{ disable: !_vm.canInclude && !_vm.disableWhenEditing },attrs:{"rules":{ required: true, fn: _vm.validateCost },"disabled":!_vm.canInclude && !_vm.disableWhenEditing,"maxlength":12,"label":"Custo","placeholder":"000000,00"},model:{value:(_vm.form.cost),callback:function ($$v) {_vm.$set(_vm.form, "cost", $$v)},expression:"form.cost"}})],1),_c('div',{staticClass:"buttons"},[(!_vm.enableSaveEditButton || !_vm.selectedRow.tcu_id)?_c('RgCleanButton',{attrs:{"permission":_vm.canInclude},on:{"click":_vm.cleanForm}}):_vm._e(),(!_vm.enableSaveEditButton || !_vm.selectedRow.tcu_id)?_c('RgAddButton',{attrs:{"permission":_vm.canInclude,"large":""},on:{"click":_vm.saveObjective}}):_vm._e(),(_vm.edit)?_c('RgCancelButton',{attrs:{"medium":""},on:{"click":_vm.cancelEdit}}):_vm._e(),(_vm.edit)?_c('RgSaveButton',{ref:"save",attrs:{"permission":_vm.canEdit,"large":""},on:{"click":_vm.saveEditObjective}}):_vm._e()],1)]),_c('div',{staticClass:"separator"}),_c('div',{staticClass:"import"},[_c('span',{staticClass:"info"},[_c('strong',[_vm._v("Incluir objetivos da base de dados existente "),_c('i',[_vm._v("(Tabela Unificada ou TUSS)")]),_vm._v(":")])]),_c('LargeButton',{directives:[{name:"shortkey",rawName:"v-shortkey",value:(['enter']),expression:"['enter']"}],staticClass:"import-button",attrs:{"label":'Importar',"backgroundColor":'#1E88A9',"permission":_vm.canInclude},on:{"click":_vm.openModalImport},nativeOn:{"shortkey":function($event){{
              }}}},[_c('div',{staticClass:"icon",attrs:{"slot":"icon"},slot:"icon"},[_c('IconDownloadArrow')],1)])],1)]),_c('FormBase',{staticClass:"table-form",attrs:{"title":"Objetivos Cadastrados"}},[_c('div',{staticClass:"filter"},[_c('div',{staticClass:"inputs"},[_c('RgComboboxUnithealth',{ref:"searchUnitHealth",attrs:{"label":"Unidade de Saúde","rules":{ required: true }},model:{value:(_vm.search.unitHealth),callback:function ($$v) {_vm.$set(_vm.search, "unitHealth", $$v)},expression:"search.unitHealth"}}),_c('RgInput',{attrs:{"label":"Objetivo","placeholder":"Digite o nome do objetivo"},model:{value:(_vm.search.objective),callback:function ($$v) {_vm.$set(_vm.search, "objective", $$v)},expression:"search.objective"}}),_c('RgInput',{attrs:{"label":"Código","placeholder":"0000000000000"},model:{value:(_vm.search.code),callback:function ($$v) {_vm.$set(_vm.search, "code", $$v)},expression:"search.code"}}),_c('div',{staticClass:"buttons"},[_c('RgCleanButton',{attrs:{"permission":_vm.canShow},on:{"click":_vm.cleanFilter}}),_c('RgSearchButton',{ref:"search",attrs:{"permission":_vm.canShow},on:{"submit":_vm.searchObjectives}})],1)],1),_c('RgEditButton',{staticClass:"edit",class:{
              disabled: _vm.disableEditButton || _vm.disableWhenEditing,
            },attrs:{"large":"","disabled":_vm.disableEditButton || _vm.disableWhenEditing,"permission":_vm.canEdit},on:{"click":_vm.editObjective}})],1),(_vm.showObjectiveList)?_c('div',{staticClass:"table",class:{
            disable: _vm.disableWhenEditing,
          },attrs:{"disabled":_vm.disableWhenEditing}},[_c('RgTable',{staticClass:"rg-table",attrs:{"columns":_vm.COLUMNS}},_vm._l((_vm.objectiveList),function(item){return _c('tr',{key:item.tcu_id,staticClass:"tr",class:{
                selected: _vm.selectedRow.tcu_id === item.tcu_id,
              },attrs:{"slot":"rows"},on:{"click":function($event){return _vm.selectRow(item)}},slot:"rows"},[_c('td',{staticClass:"objective"},[_c('span',{staticClass:"ellipsis",attrs:{"title":item.tcu_novo_tipo_consulta}},[_vm._v(" "+_vm._s(item.tcu_novo_tipo_consulta)+" ")])]),_c('td',[_vm._v(" "+_vm._s(item.pls_nome)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.tcu_novo_codigo_procedimento)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.tcu_novo_custo)+" ")]),_c('td',{staticClass:"toggle"},[_c('RgToggleButton',_vm._b({staticClass:"toggle",attrs:{"disabled":(item.tcu_ativo && !_vm.canInactive) ||
                    (!item.tcu_ativo && !_vm.canInclude) ||
                    item.disable},on:{"input":function (toogle) { return _vm.changeToggle(item, toogle); }},model:{value:(item.tcu_ativo),callback:function ($$v) {_vm.$set(item, "tcu_ativo", $$v)},expression:"item.tcu_ativo"}},'RgToggleButton',_vm.propsToggleButton,false))],1)])}),0)],1):(
            _vm.permission.showObjective &&
            (!_vm.objectiveList || _vm.objectiveList.length < 1)
          )?_c('div',{staticClass:"empty-result"},[_c('IconEmpty'),_c('span',[_vm._v("Não foram encontrados resultados para essa busca")])],1):_vm._e(),_c('RgList',{directives:[{name:"show",rawName:"v-show",value:(_vm.objectiveList && _vm.objectiveList.length > 0),expression:"objectiveList && objectiveList.length > 0"}],ref:"rgList",staticClass:"list",class:{
            disable: _vm.disableWhenEditing,
          },attrs:{"search-function":_vm.searchList,"build-filter":_vm.generateFilter,"register-per-page":10,"max-register":10,"disabled":_vm.disableWhenEditing,"backendLegacy":""},model:{value:(_vm.objectiveList),callback:function ($$v) {_vm.objectiveList=$$v},expression:"objectiveList"}})],1)],1)],1),_c('ModalImportObjective',{attrs:{"show":_vm.modalImport},on:{"research":_vm.searchObjectives,"close":_vm.closeModalImport}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }