<template>
  <div>
    <div class="row filter-fields">
      <RgComboboxUnithealth v-model="form.unsId" />
    </div>
    <div class="row filter-fields">
      <RgInputMasked
        v-model="form.presentation"
        :mask="['BM/DDDD']"
        :tokens="tokens"
        :rules="{ fn: validatePeriod }"
        label="Apres.:"
        class="col-12"
      />
    </div>
  </div>
</template>

<script>
import { RgInputMasked } from "~tokio/primitive";
import RgComboboxUnithealth from "$billing/common/component/rg-combobox-unithealth/RgComboboxUnithealth";
import {
  ValidatePeriodBilling,
  MountPresentation,
} from "../../../common/service/";

export default {
  name: "PreviewValues",
  components: {
    RgInputMasked,
    RgComboboxUnithealth,
  },
  data() {
    return {
      resultTitle: "Relatório de Prévia Valor",
      form: {
        unsId: 0,
        status: "0",
      },
      tokens: {
        B: { pattern: /^([0-1])$/ },
        M: { pattern: /^([0-9])$/ },
        D: { pattern: /\d/ },
      },
    };
  },
  computed: {
    columnTable() {
      return this.COLUMN;
    },
    getNameSmartTable() {
      return "PreviewValues";
    },
  },
  watch: {
    "form.name"() {
      if (this.form.name) {
        this.form.name = this.form.name.toUpperCase();
      }
    },
  },
  created() {
    this.COLUMN = [
      { name: "Apresentação", key: "iaf_apresentacao" },
      { name: "Qtd de AIH", key: "quantidade_aih" },
      { name: "Total Serviço Hospitalar", key: "total_servico_hospitalar" },
      {
        name: "Total Serviço Profissional",
        key: "total_servico_profissional",
      },
      { name: "Total", key: "total" },
    ];
  },
  mounted() {
    this.form.unsId = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    this.updatePresentationDate();
  },
  methods: {
    buildFilter() {
      return {
        unsId: this.form.unsId ? this.form.unsId : null,
        presentation: this.form.presentation
          ? this.form.presentation.replace("/", "")
          : null,
        status: this.form.status,
      };
    },
    async doSearch(pData) {
      try {
        return this.$store.dispatch(
          "Billing/HospitalizationBilling/GET_PREVIEW_VALUES_REPORT",
          pData,
        );
      } catch (error) {
        return false;
      }
    },
    updatePresentationDate() {
      const cmp = this.$store.getters[
        "Billing/PeriodHospitalizationBilling/GET_PERIOD_HOSPITALIZATION_BILLING"
      ];
      if (!cmp) return;
      this.form.presentation = MountPresentation(cmp);
    },
    validatePeriod(pAih, pErrors) {
      return ValidatePeriodBilling(this.form.presentation, pErrors);
    },
    clearFilter() {
      this.form = {
        unsId: 0,
        status: "0",
      };
    },
  },
};
</script>
