<style src="./EmployeeDetails.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="employee-details">
    <RgForm ref="formDetails" action-label="Salvar" class="form-container">
      <div class="row">
        <div class="col-6">
          <RgInput
            v-model="form.fun_conselho_classe"
            label="Conselho de Classe"
          />
        </div>
        <div class="col-6">
          <RgInputSuscard
            v-model="form.fun_crs_numero_ab"
            label="CNS Atenção Básica"
            mask="### #### #### ####"
          />
        </div>
      </div>

      <div slot="command" class="commands">
        <button class="btn-small btn-primary" @click="saveDetails">
          Salvar
        </button>
      </div>
    </RgForm>

    <span class="form-title">Conselhos de Classe</span>
    <RgForm
      ref="formDetails"
      action-label="Salvar"
      class="form-container"
      @submit="saveClassCouncil"
    >
      <div class="row">
        <div class="col-5">
          <RgInput
            v-model="formClassCouncil.councilType"
            label="Tipo do Conselho"
          />
        </div>
        <div class="col-5">
          <RgInput
            v-model="formClassCouncil.councilNumber"
            label="Número do Conselho"
          />
        </div>
        <div class="col-2">
          <RgSuggestState v-model="formClassCouncil.uf" />
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <RgInput
            v-model="formClassCouncil.regionalCode"
            label="Código Regional"
          />
        </div>
        <div class="col-8">
          <RgInput v-model="formClassCouncil.complement" label="Complemento" />
        </div>
      </div>

      <div slot="command" class="commands">
        <button
          v-if="!editMode"
          class="btn-small btn-primary"
          @click="saveClassCouncil"
        >
          Salvar
        </button>
        <div v-if="editMode" class="edit-mode">
          <button
            class="btn-small btn-default button-space"
            @click="cancelEditClassCouncil"
          >
            Cancelar Edição
          </button>
          <button
            class="btn-small btn-warning button-space"
            @click="saveEditClassCouncil"
          >
            Salvar Edição
          </button>
          <RgRemoveSmall
            class="rg-person-profile--remove-profile-icon button-space"
            @confirm-remove="confirmRemove()"
          />
        </div>
      </div>
    </RgForm>

    <span class="form-title">Lista de Conselhos de Classe</span>
    <section class="list-card">
      <ul class="list-card-container">
        <li
          v-for="(item, itemIdx) in classCouncil"
          :key="itemIdx"
          class="card-item"
          @click="editClassCouncil(item)"
        >
          <div class="row-line">
            <div class="field">
              <span class="title">Tipo de Conselho</span>
              <span class="value">{{ item.coc_tipo_conselho }}</span>
            </div>
            <div class="field">
              <span class="title">Número do Conselho</span>
              <span class="value">{{ item.coc_numero_conselho }}</span>
            </div>
          </div>

          <div class="row-line">
            <div class="field uf">
              <span class="title">UF</span>
              <span class="value">{{ item.coc_uf_conselho }}</span>
            </div>
            <div class="field cod-reg">
              <span class="title">Cód. Reg.</span>
              <span class="value">{{ item.coc_codigo_regional }}</span>
            </div>
            <div class="field">
              <span class="title">Complemento</span>
              <span class="value">{{ item.coc_complemento }}</span>
            </div>
          </div>
        </li>
      </ul>
    </section>
  </section>
</template>

<script>
import { RgInput, RgRemoveSmall } from "~tokio/primitive";
import { RgForm } from "~tokio/foundation/form";
import RgSuggestState from "$patient/common/components/suggest/rg-suggest-state/RgSuggestState";
import RgInputSuscard from "$hospitalization-billing/common/component/input/rg-input-suscard/RgInputSuscard";

export default {
  name: "EmployeeDetails",
  components: {
    RgForm,
    RgInput,
    RgRemoveSmall,
    RgSuggestState,
    RgInputSuscard,
  },
  props: {
    employeeId: {
      default: null,
    },
    employeeData: {
      default: null,
    },
    classCouncil: {
      default: null,
    },
  },
  data() {
    return {
      formClassCouncil: {
        councilType: null,
        councilNumber: null,
        uf: null,
        regionalCode: null,
        complement: null,
      },
      form: {
        fun_conselho_classe: null,
        fun_crs_numero_ab: null,
      },
      editMode: false,
      editClassCouncilId: null,
    };
  },
  watch: {
    employeeData(pData) {
      this.form.fun_conselho_classe = pData.fun_conselho_classe;
      this.form.fun_crs_numero_ab = pData.fun_crs_numero_ab;
    },
  },
  methods: {
    async saveDetails() {
      const payload = {
        employeeId: this.employeeId,
        employee: {
          ...this.form,
        },
      };

      try {
        await this.$store.dispatch("Employee/SAVE_CLASS_COUNCIL", payload);
        this.$toaster.success("Salvo com sucesso");
      } catch (err) {
        this.$toaster.error("Não foi possível salvar");
      }
    },
    editClassCouncil(pData) {
      this.editMode = true;
      this.formClassCouncil.councilType = pData.coc_tipo_conselho;
      this.formClassCouncil.councilNumber = pData.coc_numero_conselho;
      this.formClassCouncil.uf = pData.coc_uf_conselho;
      this.formClassCouncil.regionalCode = pData.coc_codigo_regional;
      this.formClassCouncil.complement = pData.coc_complemento;
      this.editClassCouncilId = pData.coc_id;
    },
    saveClassCouncil() {
      const councilData = this.defineCouncilData();
      const payload = { councilData };
      this.$emit("save-class-council", payload);
      this.cleanFormClassCouncil();
    },
    saveEditClassCouncil() {
      const classCouncilId = this.editClassCouncilId;
      const councilData = this.defineCouncilData();
      const payload = { classCouncilId, councilData };
      this.$emit("edit-class-council", payload);
      this.cleanFormClassCouncil();
      this.$toaster.success("Conselho de Classe alterado com sucesso");
    },
    confirmRemove() {
      const classCouncilId = this.editClassCouncilId;
      this.$emit("remove-class-council", classCouncilId);

      this.cleanFormClassCouncil();
    },

    cleanFormClassCouncil() {
      this.formClassCouncil.councilType = null;
      this.formClassCouncil.councilNumber = null;
      this.formClassCouncil.uf = null;
      this.formClassCouncil.regionalCode = null;
      this.formClassCouncil.complement = null;
    },
    cancelEditClassCouncil() {
      this.editMode = false;
      this.editClaccCouncilId = null;
      this.cleanFormClassCouncil();
    },
    updateClassCouncil() {
      const councilData = this.defineCouncilData();
      const payload = {
        employeeId: this.employeeId,
        councilData,
      };

      this.$emit("update-class-council", payload);
    },
    defineCouncilData() {
      return {
        coc_id_funcionarios: parseInt(this.employeeId),
        coc_numero_conselho: this.formClassCouncil.councilNumber,
        coc_uf_conselho: this.formClassCouncil.uf,
        coc_codigo_regional: this.formClassCouncil.regionalCode,
        coc_complemento: this.formClassCouncil.complement,
        coc_tipo_conselho: this.formClassCouncil.councilType,
      };
    },
  },
};
</script>
