import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  const sendData = {
    arrFormData: variables,
  };
  return AuthLegacyRequest.post(
    "globais/controller-comprovante-fila/obter-dados-comprovante-fila",
    sendData,
  )
    .then(({ data }) => {
      return data;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
