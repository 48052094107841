<style lang="scss" src="./RgBaseModal.scss" scoped></style>
<template>
  <div>
    <transition name="base-modal">
      <div v-show="show" class="base-modal-mask">
        <div class="base-modal-wrapper">
          <div
            :class="{ rounded: rounded }"
            :style="baseModalStyle"
            class="base-modal-container"
          >
            <div class="icon">
              <slot name="icon" />
            </div>

            <div
              class="base-modal-header"
              :class="{ headerflex: withCloseButton }"
            >
              <slot name="header" />
              <div
                v-if="withCloseButton"
                class="button-close unselect"
                @click.stop.prevent="close"
              >
                X
              </div>
            </div>

            <div class="base-modal-body">
              <slot name="body">
                <slot />
              </slot>
              <div v-if="removeSubtitle" class="base-modal-sub-title">
                <slot name="sub-title" />
              </div>
            </div>

            <div class="base-modal-footer">
              <slot name="footer" />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script type="text/javascript">
export default {
  name: "RgBaseModal",
  props: {
    rounded: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: undefined,
      required: true,
    },
    sizeUnit: {
      type: String,
      default: "vw",
    },
    maxWidth: {
      type: Number,
      default: 90,
    },
    minWidth: {
      type: Number,
      default: 40,
    },
    maxHeight: {
      type: Number,
      default: 40,
    },
    minHeight: {
      type: Number,
      default: 32,
    },
    overflowY: {
      type: String,
      default: "initial",
    },
    overflowYScroll: {
      type: Boolean,
      default: false,
    },
    withCloseButton: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Object,
      default: null,
    },
    removeSubtitle: Boolean,
  },
  computed: {
    baseModalStyle() {
      const unit = this.size && this.size.unit ? this.size.unit : this.sizeUnit;

      return {
        "max-width":
          this.size && this.size.maxWidth
            ? this.size.maxWidth + unit
            : this.maxWidth,
        "min-width":
          this.size && this.size.minWidth
            ? this.size.minWidth + unit
            : this.minWidth,
        "max-height":
          this.size && this.size.maxHeight
            ? this.size.maxHeight + unit
            : this.maxHeight,
        "min-height":
          this.size && this.size.minHeight
            ? this.size.minHeight + unit
            : this.minHeight,
        "overflow-y": this.overflowYScroll ? "scroll" : this.overflowY,
      };
    },
  },
  methods: {
    close: function () {
      this.$emit("close");
    },
  },
};
</script>
