<style
  src="./HospitalizationBillingDiagnosticInfo.scss"
  lang="scss"
  scoped
></style>
<template lang="html">
  <section class="hospitalization-billing-diagnostic-info">
    <RgForm
      ref="form"
      v-shortkey="['ctrl', 's']"
      :action-external-disabled-status="!canSave"
      :show-submit="false"
      :show-next-button="true"
      class="form"
      @submit="save"
      @shortkey.native="save"
      @nextClicked="increaseStepProgress"
    >
      <RgConfirmModal
        :show="confirmDelete"
        :message="deleteModalMsg"
        style="background-color: red"
        title="Você tem certeza que deseja remover o CID ?"
        @close="handleRemoveLine"
        @confirmed="removeLine"
        @denied="handleRemoveLine"
      />
      <RgValidatorForm ref="validator">
        <div class="row">
          <RgTitle
            class="subtitles"
            text-align="center"
            title="CID Secundário de Diagnóstico"
          />
          <div class="row initialGroup">
            <RgSuggestCid
              ref="mainCid"
              v-model="mainCid"
              :procedure="hospitalizationInfoInState.inf_procedimento_principal"
              :disabled="true"
              class="col-6"
              label="CID Principal:"
            />
            <RgSuggestCid
              ref="secondaryCid"
              v-model="diagnosticInfoForm.description"
              :disabled="!mainCid || mainCid === ''"
              :procedure="hospitalizationInfoInState.inf_procedimento_principal"
              :main-cid="hospitalizationInfoInState.inf_cid_principal"
              :load-all-except-external-violence="true"
              class="col-6"
              label="CID Secundário:"
              @cid-code="secundaryCidCode"
              @cid-selected="(value) => (secundaryCidSelected = value)"
            />
            <fieldset class="rg-radio col-4">
              <div class="align col-3 col-md-5">
                <label class="description--radio">Características:</label>
              </div>
              <div>
                <label class="radio">
                  <input
                    v-model="
                      diagnosticInfoForm.iac_id_internacoes_classificacao_diagnostico
                    "
                    type="radio"
                    name="caracteristica-type"
                    value="1"
                    class="rg-radio"
                  />
                  <span>Preexistente</span>
                </label>
                <label class="radio">
                  <input
                    v-model="
                      diagnosticInfoForm.iac_id_internacoes_classificacao_diagnostico
                    "
                    type="radio"
                    name="caracteristica-type"
                    value="2"
                    class="rg-radio"
                  />
                  <span>Adquirido</span>
                </label>
              </div>
            </fieldset>
            <div class="commands col-8 col-md-3">
              <div class="alignb action-button">
                <button
                  :disabled="!canAdd"
                  type="button"
                  class="btn btn-success"
                  @click="add"
                >
                  Adicionar
                </button>
                <button
                  :disabled="!canSave"
                  type="button"
                  class="btn btn-success"
                  @click="save"
                >
                  Salvar
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <RgTitle
            class="subtitles"
            title="Lista de CID Secundário de Diagnósticos"
            text-align="center"
          />
          <div v-if="cidList.length === 0" class="aligndiv">
            <span>Nenhum CID secundário adicionado.</span>
          </div>
          <div v-else class="row">
            <ul
              v-for="(cid, cidIdx) in cidList"
              :key="cidIdx"
              class="group row"
            >
              <li class="item">
                <div class="title_li">CID:</div>
                <span class="item_li">{{ cid.iac_codigo_cid10 }}</span>
              </li>
              <li class="item">
                <div class="title_li">Descrição:</div>
                <span class="item_li">{{ cid.description }}</span>
              </li>
              <li class="item">
                <div class="title_li">Classificação:</div>
                <span class="item_li">{{
                  getClassificationName(
                    cid.iac_id_internacoes_classificacao_diagnostico,
                  )
                }}</span>
              </li>
              <li class="item">
                <button
                  type="button"
                  class="tamanho btn btn-small btn-danger"
                  @click="handleRemoveLine(cidIdx, cid)"
                >
                  Deletar
                </button>
              </li>
            </ul>
          </div>
        </div>
      </RgValidatorForm>
    </RgForm>
  </section>
</template>
<script>
import { RgForm } from "~tokio/foundation/form";
import { isEmpty } from "lodash";
import { RgTitle, RgValidatorForm } from "~tokio/primitive";
import RgConfirmModal from "~tokio/primitive/notification/modal/RgConfirmModal";
import EventBus from "~common/event/bus/EventBus";
import RgSuggestCid from "../../suggest/rg-suggest-cid/RgSuggestCid";
import {
  GetCidCode,
  IsCidExternalViolence,
} from "../../suggest/rg-suggest-cid/helpers";
import {
  AlertError,
  ExecuteActionAvoidingConcurrency,
} from "../../../service/";
import mask from "vue-the-mask";

export default {
  name: "HospitalizationBillingDiagnosticInfo",
  components: {
    RgConfirmModal,
    RgForm,
    RgTitle,
    RgValidatorForm,
    RgSuggestCid,
  },
  directives: {
    mask,
  },
  props: {
    validationTabs: {
      type: Boolean,
      default: false,
    },
    savingFather: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      saving: false,
      adding: false,
      cidList: [],
      secundaryCidSelected: false,
      mainCid: "",
      hospitalizationInfoTab: {},
      deleteIdx: "",
      deleteItem: "",
      deleteModalMsg: "",
      confirmDelete: false,
      diagnosticInfoForm: {
        iac_id_internacoes_classificacao_diagnostico: "1",
        description: "",
        iac_codigo_cid10: "",
      },
    };
  },
  computed: {
    hasDataToSave() {
      return !this.cidList ? false : this.cidList.length > 0;
    },
    hospitalizationInfoInState() {
      return this.$store.getters[
        "Billing/HospitalizationBilling/GET_HOSPITALIZATION_BILLING_INFO_TAB"
      ];
    },
    hospitalizationBillingDiagnosticsInfo() {
      return this.$store.getters[
        "Billing/HospitalizationBilling/GET_HOSPITALIZATION_BILLING_DIAGNOSTICS_INFO"
      ];
    },
    canSave() {
      return (
        this.tabIsValid() && !this.savingFather && !this.saving && !this.adding
      );
    },
    canAdd() {
      return !this.adding && this.secundaryCidSelected;
    },
  },
  watch: {
    hospitalizationInfoInState(pValue) {
      let mainCidCode = GetCidCode(this.mainCid);
      if (pValue.inf_cid_principal !== mainCidCode) {
        this.$nextTick(() =>
          this.$refs.mainCid.loadCid(pValue.inf_cid_principal),
        );

        if (!isEmpty(mainCidCode) && this.cidList && this.cidList.length > 0) {
          AlertError(
            "Cid principal alterado. Revise os itens da lista de Cid Secundários",
            [],
            "warning",
            true,
          );
        }

        mainCidCode = pValue.inf_cid_principal;
      }
    },
    hospitalizationBillingDiagnosticsInfo() {
      this.cidList = this.hospitalizationBillingDiagnosticsInfo || [];
    },
    savingFather(pData) {
      this.saving = pData;
    },
  },
  mounted() {
    // Escutando para atualizar o vuex
    EventBus.$on("updateVuex", this.setDataToState);
    EventBus.$on("resetData", this.resetData);
    this.loadData();
  },
  methods: {
    async save() {
      this.$nextTick(async () => {
        if (this.canSave) {
          this.saving = true;
          this.$emit("saveInfoTabs", () => {});
          this.$emit(
            "createHospitalizatioBillingDiagnosticInfo",
            JSON.parse(JSON.stringify(this.cidList)),
          );
          this.resetSecondaryCid();
          return true;
        }
      });
      return false;
    },
    increaseStepProgress() {
      this.$emit("increaseStepProgress", 1);
    },
    resetData() {
      this.saving = false;
      this.adding = false;
      this.cidList = [];
      this.secundaryCidSelected = false;
      this.mainCid = "";
      this.hospitalizationInfoTab = {};
      this.deleteIdx = "";
      this.deleteItem = "";
      this.deleteModalMsg = "";
      this.confirmDelete = false;
      this.diagnosticInfoForm = {
        iac_id_internacoes_classificacao_diagnostico: "1",
        description: "",
        iac_codigo_cid10: "",
      };
    },
    resetSecondaryCid() {
      this.diagnosticInfoForm.iac_id_internacoes_classificacao_diagnostico =
        "1";
      this.diagnosticInfoForm.description = "";
      this.diagnosticInfoForm.iac_codigo_cid10 = "";
      this.secundaryCidSelected = false;
    },
    async add(event) {
      const previousStatus = this.adding;
      this.adding = true;
      return ExecuteActionAvoidingConcurrency(async () => {
        if (!previousStatus) {
          const formValid = await this.validateForm();
          if (formValid) {
            let { description, ...others } = this.diagnosticInfoForm;
            if (this.diagnosticInfoForm.description.indexOf(" - ") !== -1) {
              description = this.diagnosticInfoForm.description.split(" - ")[1];
            }
            this.cidList = (this.cidList || []).push({
              description,
              ...others,
            });
            this.resetSecondaryCid();
          }
          this.adding = false;
        }
      }, this.$nextTick);
    },
    secundaryCidCode(pValue) {
      this.diagnosticInfoForm.iac_codigo_cid10 = pValue;
    },
    async loadData() {
      const iafId = this.$store.getters[
        "Billing/HospitalizationBilling/GET_HOSPITALIZATION_BILLING_AIH_ID"
      ];
      const savedAihSecondaryCid = await this.$store.dispatch(
        "Billing/HospitalizationBilling/GET_HOSPITALIZATION_BILLING_DIAGNOSTICS_INFO",
        { iaf_id: iafId },
      );
      const omitTypename = (key, value) =>
        key === "__typename" ? undefined : value;
      const newPayload = JSON.parse(
        JSON.stringify(savedAihSecondaryCid),
        omitTypename,
      );
      this.$store.commit(
        "Billing/HospitalizationBilling/SET_HOSPITALIZATION_BILLING_DIAGNOSTICS_INFO",
        newPayload,
      );
    },
    async removeLine() {
      this.confirmDelete = false;
      if (this.deleteItem && this.deleteItem.iac_id) {
        await this.$store.dispatch(
          "Billing/HospitalizationBilling/DELETE_HOSPITALIZATION_AIH_SECONDARY_CID",
          this.deleteItem.iac_id,
        );
      }

      if (this.cidList && this.cidList.length > 0) {
        this.cidList.splice(this.deleteIdx, 1);
      }
    },
    async validateForm() {
      const valid = await this.$refs.validator.validate();
      return (
        this.validateEmptyCid() &&
        this.validateCidLength() &&
        this.validateDuplicateEntry() &&
        valid
      );
    },
    validateEmptyCid() {
      if (isEmpty(this.diagnosticInfoForm.description)) {
        return AlertError("Escolhe um CID Secundário.");
      }
      return true;
    },
    validateCidLength() {
      if (this.cidList && this.cidList.length >= 9) {
        return AlertError("Limite máximo de 9 diagnósticos atingido.");
      }
      return true;
    },
    validateDuplicateEntry() {
      if (this.cidList && this.cidList.length > 0) {
        const exist = this.cidList.filter(
          (item) =>
            item.iac_codigo_cid10 === this.diagnosticInfoForm.iac_codigo_cid10,
        );

        if (exist && exist.length > 0) {
          return AlertError("Diagnóstico já incluído");
        }
      }
      return true;
    },
    tabIsValid() {
      if (
        this.validationTabs &&
        IsCidExternalViolence(this.mainCid) &&
        !this.hasDataToSave
      ) {
        return AlertError("Escolha ao menos um CID Secundário");
      }

      if (
        this.hospitalizationInfoInState.inf_cnpj_empregador ||
        this.hospitalizationInfoInState.inf_vinculos_previdencia
      ) {
        return this.cidList.length > 0;
      }
      return true;
    },
    getClassificationName(value) {
      const val = String(value);
      if (val === "1") {
        return "PREEXISTENTE";
      } else if (val === "2") {
        return "ADQUIRIDO";
      }
    },
    setDataToState() {
      this.$store.commit(
        "Billing/HospitalizationBilling/SET_HOSPITALIZATION_BILLING_DIAGNOSTICS_INFO",
        JSON.parse(JSON.stringify(this.cidList)),
      );
    },
    handleRemoveLine(idx, deleteItem) {
      this.confirmDelete = !this.confirmDelete;
      if (this.confirmDelete) {
        this.deleteIdx = idx;
        this.deleteItem = deleteItem;
      } else {
        this.deleteIdx = "";
        this.deleteItem = "";
      }
    },
  },
};
</script>
