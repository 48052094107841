<template lang="html">
  <svg
    width="14.612mm"
    height="17.852mm"
    version="1.1"
    viewBox="0 0 14.612289 17.852427"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(209.9 -408.27)">
      <g transform="matrix(.025394 0 0 .025394 -209.84 405.04)">
        <g transform="matrix(.73414 0 0 .73414 -66.203 127.29)" fill="#ffffff">
          <path
            d="m817.9 108.4h-28v687.9c0 45.699-37.2 82.898-82.899 82.898h-509.3v25.5c0 29.201 23.7 52.9 52.9 52.9h567.2c29.2 0 52.899-23.699 52.899-52.9v-743.4c1e-3 -29.2-23.599-52.9-52.799-52.9z"
          />
          <path
            d="m423.3 849.2h283.6c29.2 0 52.9-23.699 52.9-52.898v-743.4c0-29.2-23.7-52.9-52.9-52.9h-377.9v17.5c0.199 1.8 0.3 3.7 0.3 5.6v144.9c0 41.1-33.4 74.5-74.5 74.5h-144.9c-1.5 0-3.1-0.1-4.6-0.2h-18.4v554c0 29.199 23.7 52.898 52.9 52.898h58zm10.7-179.8h-184.9c-13.8 0-25-11.201-25-25 0-13.801 11.2-25 25-25h185c13.8 0 25 11.199 25 25 0 13.799-11.3 25-25.1 25zm185-127.6h-369.9c-13.8 0-25-11.201-25-25 0-13.801 11.2-25 25-25h369.9c13.8 0 25 11.199 25 25 0 13.799-11.2 25-25 25zm-369.9-185.5h369.9c13.8 0 25 11.2 25 25s-11.2 25-25 25h-369.9c-13.8 0-25-11.2-25-25s11.2-25 25-25z"
          />
          <path
            d="m109.9 212.5h144.9 0.4c24.2-0.2 43.8-19.8 44-44v-0.4-145c0-13.4-11-22.3-22.399-22.3-5.5 0-11 2-15.6 6.6l-167.1 167.1c-14 14-4.1 38 15.8 38z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "Csv",
};
</script>
