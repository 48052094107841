import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query(
    $ics_tempo_permanencia: Int!
    $ics_qtd: Int!
    $ics_telefone: Int!
    $ics_cns: Int!
    $ics_idade_menor: Int!
    $ics_idade_maior: Int!
  ) {
    hospitalizationAihReleaseRequestCode(
      ics_tempo_permanencia: $ics_tempo_permanencia
      ics_qtd: $ics_qtd
      ics_telefone: $ics_telefone
      ics_cns: $ics_cns
      ics_idade_menor: $ics_idade_menor
      ics_idade_maior: $ics_idade_maior
    ) {
      ics_codigo
      ics_tempo_permanencia
      ics_qtd
      ics_telefone
      ics_cns
      ics_idade_menor
      ics_idade_maior
    }
  }
`;

export default async (
  { commit },
  {
    ics_tempo_permanencia,
    ics_qtd,
    ics_telefone,
    ics_cns,
    ics_idade_menor,
    ics_idade_maior,
  },
) => {
  const variables = {
    ics_tempo_permanencia,
    ics_qtd,
    ics_telefone,
    ics_cns,
    ics_idade_menor,
    ics_idade_maior,
  };
  const { data } = await GraphQLClient.query({
    query,
    variables,
    fetchPolicy: "no-cache",
  });

  if (!data.hospitalizationAihReleaseRequestCode) return false;

  commit(
    "SET_HOSPITALIZATION_AIH_RELEASE_REQUEST_CODE",
    data.hospitalizationAihReleaseRequestCode,
  );
  return true;
};
