export default {
  name: "RgButtonMixin",
  props: {
    dataId: {
      type: String,
      default: "",
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
      default: "",
    },

    label: {
      type: String,
      default: "",
    },

    permission: {
      type: Boolean,
      default: true,
    },

    backgroundColor: {
      type: String,
      default: "",
    },

    small: {
      type: Boolean,
      default: true,
    },

    medium: {
      type: Boolean,
      default: false,
    },

    large: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    clicked() {
      this.$emit("click");
    },
  },
};
