<script>
import { RgRemoteComboboxMixin } from "~tokio/primitive";
import SearcRelationshipRemoveReason from "./action/SearchRelationshipRemoveReason";
export default {
  name: "RgComboboxRalationshipRemoveReason",
  mixins: [RgRemoteComboboxMixin],
  props: {
    label: {
      default: "Motivo",
    },
  },
  data() {
    return {};
  },
  methods: {
    doSearch() {
      return SearcRelationshipRemoveReason();
    },
    formatItem(item) {
      return {
        value: item.mtd_id,
        text: `${item.mtd_nome}`,
      };
    },
  },
};
</script>
