import BaseModel from "~common/model/BaseModel";

class SurgeryEquipmentModel extends BaseModel {
  defineEndPointURI() {
    return "surgery-center/surgery-equipment-type";
  }

  getEquipmentsById(pSurgeryId) {
    return new Promise((resolve, reject) => {
      this._request
        .get("/surgery-center/surgery-equipment", {
          cec_id_controles_cirurgicos: pSurgeryId,
        })
        .then((pData) => {
          resolve(pData.data || null);
        });
    });
  }
}
export default new SurgeryEquipmentModel();
