<template lang="html">
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.565 19.3017C5.32264 19.3017 1 15.071 1 9.73667C1 6.14979 3.02337 2.83883 6.24236 1.18335C6.70221 0.999406 7.16207 1.18335 7.43798 1.55123C7.62192 2.01109 7.43798 2.47094 7.0701 2.74686C4.40293 4.12643 2.74745 6.79359 2.74745 9.73667C2.74745 14.0593 6.24236 17.5542 10.565 17.5542C14.8876 17.5542 18.3825 14.0593 18.3825 9.73667C18.3825 6.79359 16.7271 4.12643 14.0599 2.74686C13.6 2.56292 13.4161 2.01109 13.692 1.55123C13.876 1.09138 14.4278 0.907434 14.8876 1.18335C18.1066 2.83883 20.13 6.05782 20.13 9.73667C20.13 15.071 15.8993 19.3017 10.565 19.3017Z"
      fill="white"
      stroke="white"
      stroke-width="0.3"
    />
    <path
      d="M10.5662 1C11.118 1 11.4859 1.36788 11.4859 1.91971V8.35769C11.4859 8.90952 11.118 9.2774 10.5662 9.2774C10.0144 9.2774 9.64648 8.90952 9.64648 8.35769V1.91971C9.64648 1.36788 10.0144 1 10.5662 1Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "On",
};
</script>
