<style src="./RgInputCodeIne.scss" lang="scss"></style>
<template lang="html">
  <fieldset class="rg-input--component">
    <RgFormBase :label="label">
      <div class="rg-input--base">
        <div class="rg-input--textbox-container">
          <RgValidationAlert :alert="error" />
          <input
            v-model="inputValue"
            v-mask="'##########'"
            type="text"
            placeholder="Informe o código INE"
          />
        </div>
      </div>
    </RgFormBase>
  </fieldset>
</template>

<script>
import { RgFormBase } from "~tokio/foundation/container";
import RgValidationAlert from "~tokio/primitive/validation/rg-validation-alert/RgValidationAlert";
import RgInputMixin from "~tokio/primitive/input/mixin/RgInputMixin";
import RgValidatorMixin from "~tokio/primitive/validation/RgValidatorMixin";
import { mask } from "vue-the-mask";

export default {
  name: "RgInputCodeIne",
  components: {
    RgValidationAlert,
    RgFormBase,
  },
  directives: {
    mask,
  },
  mixins: [RgValidatorMixin, RgInputMixin],
  props: {
    label: {
      default: "Código INE",
    },
  },
};
</script>
