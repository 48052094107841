import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation(
    $examInfo: BioslabInfoExamInputType
    $procedureInfo: [BioslabInfoProceduresInputType]
  ) {
    SaveToBioslab(examInfo: $examInfo, procedureInfo: $procedureInfo) {
      leb_id
      leb_id_laboratoriais_status_pedido
    }
  }
`;

export default async ({ commit }, variables) => {
  const { data } = await GraphQLClient.mutate({ mutation, variables }).catch(
    (err) => {
      throw err;
    },
  );
  return data;
};
