import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  return AuthLegacyRequest.post(
    "rgpainel/controller-rg-painel/chamar",
    variables,
  )
    .then(({ data }) => {
      if (data?.dados) {
        return data.dados;
      }
      return null;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
