<style src="./RgSuggestNationality.scss" lang="scss" scoped></style>
<template>
  <div class="rg-suggest-nationality">
    <RgSuggest
      ref="rgSuggest"
      v-model="inputValue"
      :data-id="dataId"
      :label="label"
      :placeholder="placeholder"
      :disabled="disabled"
      :rules="rules"
      min="1"
      :search-on-focus="searchOnFocus"
    >
      <RgSuggestItem
        v-for="(item, idx) in suggestionList"
        :key="idx"
        :data-item="dataId"
        :idx="idx"
        :item="item"
        class="nat-item"
      >
        <div class="nat-row-top">
          <span class="nat-name">{{ item.nac_nome }}</span>
        </div>
      </RgSuggestItem>
    </RgSuggest>
  </div>
</template>
<script>
import { RgSuggest, RgSuggestMixin, RgSuggestItem } from "~tokio/primitive";
import { SearchNationality } from "./action";

export default {
  name: "RgSuggestNationality",
  components: {
    RgSuggest,
    RgSuggestItem,
  },
  mixins: [RgSuggestMixin],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      captionRaw: "nac_nome",
    };
  },
  computed: {
    searchOnFocus() {
      return this.inputValue === "" || this.inputValue === null;
    },
  },
  methods: {
    doSearch(pSearchTerm, pLimit = null) {
      return SearchNationality({ nacNome: pSearchTerm, limit: pLimit });
    },
    validate() {
      this.$refs.rgSuggest.validate();
    },
    cleanValidate() {
      this.$refs.rgSuggest.cleanValidate();
    },
  },
};
</script>
