import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation($lebId: Int, $unsId: Int) {
    BioslabSendRequestServiceXML(leb_id: $lebId, uns_id: $unsId)
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.mutate({ mutation, variables });
    return data.BioslabSendRequestServiceXML;
  } catch (err) {
    const jsonResponse = JSON.parse(JSON.stringify(err));
    return Promise.reject(jsonResponse.graphQLErrors[0].message);
  }
};
