import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query BioslabLaboratoryExamList($lle_id: Int, $lle_codigo_sigtap: String) {
    SearchBioslabLaboratoryExamList(
      lle_id: $lle_id
      lle_codigo_sigtap: $lle_codigo_sigtap
    ) {
      count
      rows {
        lle_id
        lle_codigo_bioslab_mne
        lle_procedimentos
        lle_multiplo
        lle_dum
        lle_peso
        lle_altura
        lle_cor
        lle_nacionalidade
        lle_naturalidade
        lle_material
        lle_codigo_sigtap
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  const { data } = await GraphQLClient.query({
    query,
    variables,
    fetchPolicy: "no-cache",
  }).catch((err) => {
    throw err;
  });
  return data.SearchBioslabLaboratoryExamList.count === 1
    ? data.SearchBioslabLaboratoryExamList.rows[0]
    : data.SearchBioslabLaboratoryExamList;
};
