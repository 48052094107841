<template>
  <RgInputMasked
    ref="inputMasked"
    v-model="inputValue"
    :rules="{ fn: cnsRules, required: requiredValue }"
    :with-mask="false"
    :mask="mask"
    :label="label"
    :keyup="keyup"
    placeholder="000 0000 0000 0000"
    @blur.native="validateBlur"
  />
</template>

<script>
import { RgInputMasked, RgInputMixin } from "~tokio/primitive/input";
import RgInputSuscardHelper from "~tokio/primitive/input/rg-input-suscard/RgInputSuscardHelper";

export default {
  name: "RgInputSuscard",
  components: {
    RgInputMasked,
  },
  mixins: [RgInputMixin],
  props: {
    label: {
      type: String,
      default: "Cartão SUS:",
    },
    value: {
      type: [String, Number],
      default: "",
    },
    keyup: {
      type: Function,
      default: () => {},
    },
    anotherRules: {
      type: Function,
      default: (pValue, pErrors) => true,
    },
    requiredValue: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mask: "### #### #### ####",
    };
  },
  methods: {
    cnsRules(pValue, pErrors) {
      return (
        this.anotherRules(pValue, pErrors) &&
        RgInputSuscardHelper.checkDigit(pValue, pErrors)
      );
    },
    validate() {
      this.$refs.inputMasked.validate();
    },
  },
};
</script>
