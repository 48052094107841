<template>
  <RgBaseModal
    ref="Modal"
    v-shortkey="['esc']"
    :show="show"
    class="modal-webcam"
    with-close-button
    @close="close"
    @shortkey.native="close"
  >
    <div slot="header" class="header">
      <h1 class="title">{{ title }}</h1>
    </div>
    <div slot="body" class="body">
      <FormBase title="Foto">
        <div class="webcam-container">
          <label class="input">
            Câmera:
            <select v-model="selectedCamera" class="select">
              <option
                v-for="camera in cameras"
                :key="camera.deviceId"
                :value="camera.deviceId"
              >
                {{ camera.label }}
              </option>
            </select>
          </label>

          <video
            v-show="!existFile"
            ref="webcam"
            class="webcam"
            autoplay
            playsinline
          />

          <canvas v-show="existFile" ref="canvas" class="webcam"></canvas>
          <div class="actions">
            <button
              class="button -remove"
              type="button"
              label="Remover foto"
              :class="{ disable: !existFile }"
              :disabled="!existFile"
              @click="confirmRemovePhoto"
            >
              <IconAdd class="svg" />
            </button>

            <button
              class="button -photo"
              type="button"
              label="Tirar foto"
              :class="{ disable: existFile }"
              :disabled="existFile"
              @click="snap"
            >
              <IconPhoto class="svg" />
            </button>
          </div>
        </div>
      </FormBase>
    </div>

    <div slot="footer" class="footer">
      <div class="control-painel">
        <RgCancelButton medium class="btn-margim" @click="close" />

        <RgConfirmButton
          medium
          type="button"
          :disabled="!file"
          @click="confirmUpload"
        />
      </div>
    </div>
  </RgBaseModal>
</template>

<script>
import {
  RgBaseModal,
  RgConfirmButton,
  RgCancelButton,
  IconPhoto,
  IconAdd,
} from "~tokio/primitive";

import { FormBase } from "~tokio/foundation";
import Webcam from "webcam-easy";
// import { IconUpload } from "~tokio/primitive/icon/symbols";
import SendFile from "./actions/SendFile";
let webcam = null;

export default {
  name: "ModalWebcam",

  components: {
    RgBaseModal,
    RgConfirmButton,
    RgCancelButton,
    FormBase,
    IconPhoto,
    IconAdd,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Tirar uma Foto pela Camera",
    },
  },

  data() {
    return {
      cameras: [],
      selectedCamera: null,
      dataupload: null,
      file: null,
    };
  },

  computed: {
    existFile() {
      return this.file !== null;
    },
  },

  watch: {
    selectedCamera(pValue) {
      if (this.show) {
        webcam.stop();
        webcam._selectedDeviceId = pValue;
        webcam.start();
      }
    },

    show(pValue) {
      if (pValue) {
        webcam
          .start()
          .then((result) => {
            if (webcam.webcamList.length <= 0) {
              webcam.stop();
              this.$emit("close");
              this.$toaster.error(
                "Verifique as Configuraçoes do seu Computador",
                "Nenhuma webcam encontrada",
              );
            }
            this.cameras = webcam.webcamList;
            this.selectedCamera = this.cameras[0].deviceId;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        webcam.stop();
      }
    },
  },
  mounted() {
    webcam = new Webcam(this.$refs.webcam, "user", this.$refs.canvas);
  },
  methods: {
    confirmRemovePhoto() {
      this.file = null;
    },

    snap() {
      const data = webcam.snap();
      this.file = data;
      console.log(this.existFile);
    },
    confirmUpload() {
      // send to backend uploads and emit filename
      this.$loader.start("Enviando Arquivo...");
      SendFile(this.file)
        .then((result) => {
          this.$loader.finish();
          this.$emit("upload", result.data.fileInfo);
          this.$emit("close");
          this.file = null;
          this.$refs.canvas
            .getContext("2d")
            .clearRect(0, 0, this.$refs.canvas.width, this.$refs.canvas.height);
        })
        .catch((err) => {
          this.file = null;
          this.$loader.finish();
          this.$toaster.error("Falha ao enviar o arquivo:" + err.message);
        });
    },

    uploadImage(pFile) {
      this.file = this.$refs.file.files[0];
    },

    close() {
      this.dataupload = null;
      this.confirmRemovePhoto();
      this.$emit("close");
    },
  },
};
</script>
