<style src="./RgComboboxSurgeryClass.scss" lang="scss"></style>
<script>
import { RgRemoteComboboxMixin } from "~tokio/primitive";
import SurgeryClassModel from "$surgery-center/surgery/model/SurgeryClassModel";
export default {
  name: "RgComboboxSurgeryClass",
  mixins: [RgRemoteComboboxMixin],
  data: () => {
    return {
      model: SurgeryClassModel,
    };
  },
  methods: {
    formatItem(pData) {
      return {
        value: pData.cla_id,
        text: pData.cla_nome,
      };
    },
  },
};
</script>
