<style src="./RgInputBirthday.scss" lang="scss"></style>
<script>
import RgInputDateMixin from "../../input/mixin/RgInputDateMixin";
export default {
  name: "RgInputBirthday",
  mixins: [RgInputDateMixin],
  props: {
    label: {
      type: String,
      default: "Nascimento",
    },
    placeholder: {
      default: "",
    },
  },
  data() {
    return {
      anotherRules: {
        maxToday(pData, pError) {
          if (!this.$utils.date.isValid(pData, "DD/MM/YYYY")) {
            return true;
          }
          if (this.$utils.date.IsAfterToday(pData, "DD/MM/YYYY")) {
            pError.push(`Data deve ser menor ou igual o dia ${pData}`);
            return false;
          }
        },
      },
    };
  },
};
</script>
