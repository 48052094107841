<style src="./SurgeryEffectivenessForm.scss" lang="scss" scoped></style>
<template lang="html">
  <RgForm
    :show-submit="!hideButtonForm"
    :action-label="actionLabel"
    @submit="submit"
  >
    <section class="surgery-effectiveness-form-content">
      <section class="patient--info">
        <SchedulingPatientInfo
          v-model="form.pac_id"
          :pac-id="form.pac_id"
          :surgery="!isEmergency"
        />
      </section>

      <RgFormPanel>
        <RgFormPanelItem
          :class="{
            active: stepProgress === 1,
            validated: !validation.basicInfo,
          }"
          @change-step="changeStep(1)"
          ><IconDocument slot="icon" />Informações básicas</RgFormPanelItem
        >
        <RgFormPanelItem
          :class="{
            active: stepProgress === 2,
            validated: !validation.additionalInfo,
          }"
          @change-step="changeStep(2)"
          ><IconClipboard slot="icon" />Informações Adicionais</RgFormPanelItem
        >
        <RgFormPanelItem
          :class="{
            active: stepProgress === 3,
            validated: !validation.anesthesia,
          }"
          @change-step="changeStep(3)"
          ><IconSyringe slot="icon" />Anestesia</RgFormPanelItem
        >
        <RgFormPanelItem
          :class="{
            active: stepProgress === 4,
            validated: !validation.postAnesthesia,
          }"
          @change-step="changeStep(4)"
          ><IconSyringe2 slot="icon" />Pós Anestésico</RgFormPanelItem
        >
        <RgFormPanelItem
          :class="{
            active: stepProgress === 5,
            validated: !validation.exitReason,
          }"
          @change-step="changeStep(5)"
          ><IconExitPerson slot="icon" />Saída</RgFormPanelItem
        >
      </RgFormPanel>
      <EffectivenessBasicInfo
        v-show="stepProgress === 1"
        v-model="formSteps.basicInfo"
        :patient-id="form.pac_id"
        :surgery-id="form.cci_id"
        @valid="basicInfoValidate"
      />
      <EffectivenessAdditionalInfo
        v-show="stepProgress === 2"
        v-model="formSteps.additionalInfo"
        @valid="additionalInfoValidate"
      />
      <EffectivenessAnesthesia
        v-show="stepProgress === 3"
        v-model="formSteps.anesthesia"
        @valid="anesthesiaValidate"
      />
      <EffectivenessPostAnesthesia
        v-show="stepProgress === 4"
        v-model="formSteps.postAnesthesias"
        @valid="postAnesthesiaValidate"
      />
      <EffectivenessExitReason
        v-show="stepProgress === 5"
        v-model="formSteps.exitReason"
        @valid="exitReasonValidate"
      />
    </section>
  </RgForm>
</template>
<script>
import {
  IconDocument,
  IconClipboard,
  IconSyringe,
  IconSyringe2,
  IconExitPerson,
} from "~tokio/primitive";
import SchedulingPatientInfo from "$surgery-center/surgery/component/scheduling-patient-info/SchedulingPatientInfo";
import SurgeryFormMixin from "$surgery-center/surgery/component/mixin/SurgeryFormMixin";
import {
  RgFormPanel,
  RgFormPanelItem,
} from "$surgery-center/foundation/component/rg-form-panel";
import { RgForm } from "~tokio/foundation/form";

import EffectivenessBasicInfo from "./effectiveness-basic-info/EffectivenessBasicInfo";
import EffectivenessAdditionalInfo from "./effectiveness-additional-info/EffectivenessAdditionalInfo";
import EffectivenessAnesthesia from "./effectiveness-anesthesia/EffectivenessAnesthesia";
import EffectivenessPostAnesthesia from "./effectiveness-post-anesthesia/EffectivenessPostAnesthesia";
import EffectivenessExitReason from "./effectiveness-exit-reason/EffectivenessExitReason";
import { SurgeryPerformHelper } from "./SurgeryEffectivenessFormHelper";
import SurgeryControlModel from "$surgery-center/surgery/model/SurgeryControlModel";

export default {
  name: "SurgeryEffectivenessForm",
  components: {
    IconDocument,
    IconClipboard,
    IconSyringe,
    IconSyringe2,
    IconExitPerson,
    SchedulingPatientInfo,
    RgForm,
    RgFormPanel,
    RgFormPanelItem,
    EffectivenessBasicInfo,
    EffectivenessAdditionalInfo,
    EffectivenessAnesthesia,
    EffectivenessPostAnesthesia,
    EffectivenessExitReason,
  },
  extends: SurgeryFormMixin,
  data() {
    return {
      isEmergency: {
        default: true,
        type: Boolean,
      },
      form: {
        isEmergency: false,
        employees: [],
        materials: [],
        kits: [],
        equipments: [],
        perform: {},
      },
      formSteps: {},
      stepProgress: 1,
      validation: {
        basicInfo: true,
        additionalInfo: true,
        anesthesia: true,
        postAnesthesia: true,
        exitReason: true,
      },
      actionLabel: "Cadastrar Cirurgia de Emergência",
    };
  },
  computed: {
    surgeryState() {
      return 4;
    },
    hideButtonForm() {
      return (
        this.isSurgeryCanceled ||
        this.isSurgeryRequested ||
        this.isSurgerySuspended
      );
    },
  },
  watch: {
    selectedItem: function (pVal) {
      if (!pVal) {
        this.cleanFields();
        this.isEmergency = true;
        this.actionLabel = "Cadastrar Cirurgia de Emergência";
      } else if (!this.isUpdating) {
        this.cleanFields();
        this.isEmergency = false;
        this.actionLabel = "Efetivar Cirurgia";
        this.computePerformedInformations();
      } else {
        this.cleanFields();
        this.isEmergency = false;
        this.actionLabel = "Atualizar Cirurgia";
        this.computePerformedInformations();
      }
    },
  },
  beforeMount() {
    this.formSteps = { ...SurgeryPerformHelper.defineFormSteps };
  },
  mounted() {
    this.SurgeryControlModel = SurgeryControlModel;
  },
  methods: {
    changeStep(pStepIndex) {
      this.stepProgress = pStepIndex;
    },
    submit(pErr, pDone) {
      if (pErr) {
        return;
      }
      pDone();
      if (this.isEmergency) {
        this.createEmergencySurgery(pDone);
      } else if (this.isUpdating) {
        this.update(pDone);
      } else {
        this.createEffectivateSurgery(pDone);
      }
    },
    createEffectivateSurgery(pDone) {
      this.SurgeryControlModel.effectivateSurgery(
        this.formatEffectivenessForm(this.form),
      )
        .then((pRes) => {
          this.finishRequest(
            pDone,
            "A cirurgia foi efetivada com sucesso!",
            "Efetivação de Cirurgia",
          );
        })
        .catch((pErr) => {
          this.warningRequest(pDone, "Erro ao efetivar cirurgia");
        });
    },
    createEmergencySurgery(pDone) {
      const effectivenessForm = this.formatEffectivenessForm(this.form);
      const formatedEffectivenessForm = {
        ...effectivenessForm,
        pac_id: this.form.pac_id,
      };
      this.SurgeryControlModel.emergencySurgery(formatedEffectivenessForm)
        .then((pRes) => {
          this.finishRequest(
            pDone,
            "Cirurgia de emergência cadastrada com sucesso!",
            "Cadastro de Cirurgia de Emergência",
          );
        })
        .catch((pErr) => {
          this.warningRequest(
            pDone,
            "Erro ao cadastrar cirurgia de emergência",
          );
        });
    },
    update(pDone) {
      this.SurgeryControlModel.updateEffectivateSurgery(
        this.formatEffectivenessForm(),
      )
        .then((pRes) => {
          this.finishRequest(
            pDone,
            "A cirurgia foi atualizada com sucesso!",
            "Efetivação de Cirurgia",
          );
        })
        .catch((pErr) => {
          this.warningRequest(pDone, "Erro ao atualizar efetivação");
        });
    },
    formatEffectivenessForm() {
      return SurgeryPerformHelper.setFormSteps(
        this.form.cci_id,
        this.form.cci_id_pacientes_cirurgicos,
        this.formSteps,
      );
    },
    computePerformedInformations() {
      this.cleanFields();
      SurgeryPerformHelper.fillBasicInfo(this.form, this.formSteps);
      SurgeryPerformHelper.fillAdditionalInfo(this.form, this.formSteps);
      SurgeryPerformHelper.fillAnesthesia(this.form, this.formSteps);
      SurgeryPerformHelper.fillExitReason(this.form, this.formSteps);
      SurgeryPerformHelper.fillPostAnesthesias(this.form, this.formSteps);
    },
    basicInfoValidate(pValid) {
      this.validation.basicInfo = !(pValid || false);
    },
    additionalInfoValidate(pValid) {
      this.validation.additionalInfo = !(pValid || false);
    },
    anesthesiaValidate(pValid) {
      this.validation.anesthesia = !(pValid || false);
    },
    postAnesthesiaValidate(pValid) {
      this.validation.postAnesthesia = !(pValid || false);
    },
    exitReasonValidate(pValid) {
      this.validation.exitReason = !(pValid || false);
    },
    cleanFields() {
      this.formSteps = { ...SurgeryPerformHelper.defineFormSteps };
    },
    finishRequest(pDone, pTitleMessage, pDescriptionMessage, pErrorMessage) {
      this.$store.dispatch("SurgeryCenter/Surgery/filter");
      pDone();
      this.cleanSelection();
      this.toast.success(pDescriptionMessage, pTitleMessage);
    },
    warningRequest(pDone, pDescription) {
      pDone();
      this.toast.error(pDescription);
    },
  },
};
</script>
