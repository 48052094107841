import { GraphQLClient, gql } from "@/common/request/GraphQLClient";
import { BrazilianDateToDatabase } from "~utils/date";

const mutation = gql`
  mutation(
    $id: Int
    $status: Boolean
    $unit: Int
    $shut: EmployeeRelationshipShutdownReasonInputType
  ) {
    EnableDisableEmployeeRelationship(
      employeeRelationshipId: $id
      employeeRelationshipStatus: $status
      unitHealthId: $unit
      shutdownReason: $shut
    ) {
      vin_id
      vin_ativo
    }
  }
`;

/**
 * @param {Object} payload
 * @param {Number} payload.vin_id
 * @param {Boolean} payload.status
 * @param {Number} payload.uns_id
 * @param {Object} payload.shutdownReason
 * @param {Number} payload.shutdownReason.dlv_id_vinculos
 * @param {Number} payload.shutdownReason.dlv_id_motivos_desligamento
 * @param {Number} payload.shutdownReason.dlv_codigo
 * @param {Number} payload.shutdownReason.dlv_desligamento
 */
export default async (store, payload) => {
  const variables = sanitizeFields(store, { ...payload });
  try {
    const { data } = await GraphQLClient.mutate({
      mutation,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.EnableDisableEmployeeRelationship;
  } catch (err) {
    return Promise.reject(err.graphQLErrors);
  }
};

const sanitizeFields = (store, pData) => {
  const variables = {
    id: pData.vin_id,
    status: pData.status,
    unit: pData.uns_id || store.rootGetters["Login/GET_UNIT_HEALTH_ID"],
    shut: {},
  };
  if (variables.status === false) {
    variables.shut = {
      dlv_id_vinculos: pData.shutdownReason.dlv_id_vinculos,
      dlv_id_motivos_desligamento:
        pData.shutdownReason.dlv_id_motivos_desligamento,
      dlv_codigo: pData.shutdownReason.dlv_codigo,
      dlv_desligamento: BrazilianDateToDatabase(
        pData.shutdownReason.dlv_desligamento,
      ),
    };
  }
  return variables;
};
