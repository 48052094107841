<style src="./RgComboboxRequestingClinician.scss" lang="scss"></style>
<script>
import { RgRemoteComboboxMixin } from "~tokio/primitive";
import SurgeryRequestingClinicianModel from "$surgery-center/surgery/model/SurgeryRequestingClinicianModel";
export default {
  name: "RgComboboxRequestingClinician",
  mixins: [RgRemoteComboboxMixin],
  props: {
    label: {
      default: "Clínica Solicitante",
    },
  },
  data: () => {
    return {
      model: SurgeryRequestingClinicianModel,
      cache: false,
    };
  },
  methods: {
    formatItem(pData) {
      return {
        value: pData.inc_id,
        text: pData.inc_nome,
      };
    },
  },
};
</script>
