import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query homeLocationTypes {
    SearchHomeLocalization(limit: 100) {
      rows {
        lmo_id
        lmo_codigo_ab
        lmo_nome_localizacao
      }
      count
    }
  }
`;

export default async ({ commit, state }) => {
  if (state.location_types.length > 0) {
    return;
  }
  try {
    const { data } = await GraphQLClient.query({
      query,
      fetchPolicy: "no-cache",
    });
    commit("SET_LOCATION_TYPES", data.SearchHomeLocalization.rows);
  } catch (err) {
    console.log(err);
    return err.graphQLErrors;
  }
};
