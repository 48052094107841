<style src="./RgShowTicketsButton.scss" lang="scss" scoped></style>
<template lang="html">
  <div class="ticket-small" @click.stop.prevent="showTags()">
    <button
      :data-id="dataId"
      :disabled="disabled"
      type="button"
      class="ticket-small-btn"
    >
      <IconTag />
      <span>Etiquetas</span>
    </button>
  </div>
</template>

<script>
import { IconTag } from "~tokio/primitive/icon/symbols";

export default {
  name: "RgShowTicketsButton",
  components: {
    IconTag,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    dataId: {
      type: String,
      default: "",
    },
  },
  methods: {
    showTags() {
      if (!this.disabled) {
        this.$emit("show-tags");
      }
    },
  },
};
</script>
