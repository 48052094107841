<style lang="scss" src="./ScaleConflict.scss" scoped></style>
<template>
  <div class="rg-alert-modal">
    <RgBaseModal
      withCloseButton
      removeSubTitle
      :show="show"
      :size="{ minWidth: 700, minHeight: 260, unit: 'px' }"
      class="rg-alert-base-modal"
      @close="close"
    >
      <div slot="header" class="confirm-header">
        <span>Não foi possível cadastrar a escala</span>
      </div>

      <div slot="body" class="confirm-content">
        <span class="msg">Outras escalas possuem dados semelhantes</span>
        <div class="grid">
          <GridServiceScale
            ref="gridServiceScale"
            has-max-register
            hide-all-buttons
            modalScaleConflict
            :initial-columns="8"
            :total="total"
            :body="list"
            :columns="COLUMNS"
          />
        </div>
      </div>

      <div slot="footer" class="confirm-actions-footer">
        <MediumButton
          backgroundColor="#53B1D8"
          label="Retomar o Cadastro"
          @click="confirm"
        >
        </MediumButton>
      </div>
    </RgBaseModal>
  </div>
</template>

<script type="text/javascript">
import { MediumButton } from "~tokio/primitive";
import RgBaseModal from "~tokio/primitive/notification/modal/RgBaseModal.vue";
import GridServiceScale from "../../../common/grid/service-scale/GridServiceScale";

export default {
  name: "ScaleConflict",

  components: {
    RgBaseModal,
    MediumButton,
    GridServiceScale,
  },

  props: {
    show: {
      type: Boolean,
      default: undefined,
      required: true,
    },
    conflicts: {
      type: Array,
      default: () => [],
      required: true,
    },
  },

  data() {
    return {
      total: 0,
      list: [],
    };
  },

  computed: {},

  watch: {
    conflicts(pValue) {
      if (pValue.length > 0) {
        this.total = pValue.length;
      }
    },
    show(pValue) {
      if (pValue) {
        this.prepareList();
      }
    },
  },

  mounted() {
    this.prepareList();
  },

  created() {
    this.COLUMNS = [
      { name: "Conflito", key: "conflict_text" },
      { name: "Tipo", key: "eti_nome" },
      { name: "Unidade de Saúde", key: "uns_nome" },
      { name: "Setor", key: "set_nome" },
      { name: "Dia", key: "dsm_nome" },
      { name: "Horário de Início", key: "eea_inicio_atendimentos" },
      { name: "Horário de Término", key: "eea_final_atendimentos" },
      { name: "Validade", key: "validade" },
    ];
  },

  methods: {
    prepareList() {
      this.list = this.conflicts.map((item) => {
        return {
          ...item,
          validade: `${item.eea_data_inicio_escala} - ${item.eea_data_fim_escala}`,
          eti_nome: `${item.ees_nome.toUpperCase()} - ${item.eti_nome.toUpperCase()}`,
          conflict_text: item.eha_data_exame || "Escala",
          eea_inicio_atendimentos:
            item.eha_hora_inicio_atendimentos || item.eea_inicio_atendimentos,
          eea_final_atendimentos:
            item.eha_hora_final_atendimentos || item.eea_final_atendimentos,
        };
      });
    },

    confirm() {
      this.$emit("confirm");
    },

    close() {
      this.$emit("close");
    },
  },
};
</script>
