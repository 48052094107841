<style src="./RgPersonCardButton.scss" lang="scss" scoped></style>
<template>
  <div class="rg-person-card-button">
    <RgButton
      :data-id="dataId"
      :permission="permission"
      :class="{
        'rg-person-card-button -small': small,
        'rg-person-card-button -medium': medium,
        'rg-person-card-button -large': large,
      }"
      :title="title"
      :disabled="disabled"
      class="button"
      @click="clicked"
    >
      <div v-show="medium || large" class="text">
        <span>Novo</span>
      </div>

      <div v-show="large" class="separator"></div>

      <div v-show="!medium" class="icon">
        <IconCard />
      </div>
    </RgButton>
  </div>
</template>
<script>
import { IconCard } from "~tokio/primitive/icon/symbols";
import RgButton from "~tokio/primitive/button/rg-button/RgButton.vue";
import RgButtonMixin from "~tokio/primitive/button/rg-button/RgButtonMixin.js";

export default {
  name: "RgPersonCardButton",
  components: {
    IconCard,
    RgButton,
  },
  mixins: [RgButtonMixin],
  props: {
    title: {
      type: String,
      default: "Novo",
    },
  },
};
</script>
