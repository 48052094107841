import AuthRequest from "~common/request/AuthRequest";

export default (pData) => {
  const url = pData.bof_id
    ? "/billing/bulletin-billing/find-bulletin-billing-by-id"
    : "/billing/bulletin/search-patient-by-bulletin";
  const payload = pData.bof_id
    ? { bof_id: pData.bof_id }
    : { bol_id: pData.bol_id };
  return AuthRequest.get(url, payload).then((pResult) => {
    return pResult.data;
  });
};
