import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation($personId: Int!, $person: PersonInputType!) {
    UpdatePerson(personId: $personId, person: $person) {
      pes_nome
    }
  }
`;

export default async (store, personAdditionalInfo) => {
  const personId = personAdditionalInfo.pes_id;
  const person = Object.assign({}, personAdditionalInfo);

  delete person.nationality;
  delete person.city;
  delete person.user;
  delete person.pes_id;

  const variables = { personId, person };

  try {
    const result = await GraphQLClient.mutate({ mutation, variables });
    return result.data;
  } catch (err) {
    return Promise.reject(err.graphQLErrors);
  }
};
