import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchHospitalizationBedType(
    $itl_nome: String
    $inl_id: Int
    $limit: Int
    $ids_tipos_do_leito: [Int]
  ) {
    hospitalizationBedType(
      itl_nome: $itl_nome
      inl_id: $inl_id
      limit: $limit
      ids_tipos_do_leito: $ids_tipos_do_leito
    ) {
      count
      rows {
        itl_id
        itl_id_clientes
        itl_nome
      }
    }
  }
`;

export default async (variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    return data.hospitalizationBedType;
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors);
  }
};
