<style src="./ConsolidatedBilling.scss" lang="scss" scoped></style>
<template>
  <section class="consolidated-billing">
    <ConsolidatedForm :edit-data="editBillingData" />
    <ConsolidatedList @edit-data="editBilling" />
  </section>
</template>

<script>
import {
  ConsolidatedForm,
  ConsolidatedList,
} from "$billing/consolidated-billing/component";

export default {
  name: "ConsolidatedBilling",
  components: {
    ConsolidatedForm,
    ConsolidatedList,
  },
  data() {
    return {
      editBillingData: {},
    };
  },
  methods: {
    editBilling(pForm) {
      this.editBillingData = pForm;
    },
  },
};
</script>
