<style src="./RelationshipOptions.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="relationship-options">
    <button type="button" class="button-open" @click.stop="openOptions">
      <IconEllipsis />
    </button>

    <RelationshipRemoveReasonModal
      :show="removeModal"
      @close-modal="closeRelationshipRemoveReason"
      @deactivate-relationship="relationshipDeactivate"
    />
    <div v-if="panelOpened">
      <ul v-click-outside="closePanel" class="options-container">
        <li class="item" @click="showHistory">Visualizar Histórico</li>
        <li v-show="!activeStatus" class="item" @click="activateRelationship">
          Ativar Vinculo
        </li>
        <li v-show="activeStatus" class="item" @click="deactivateRelationship">
          Inativar Vinculo
        </li>
        <li class="remove-bottom" @click="removeRelationshio">
          <IconGarbage class="item-icon" />
          Remover Vinculo
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import { IconEllipsis, IconGarbage } from "~tokio/primitive/icon";
import ClickOutside from "vue-click-outside";
import RelationshipRemoveReasonModal from "@/app/employee/common/components/container/relationship-remove-reason-modal/RelationshipRemoveReasonModal";

export default {
  name: "RelationshipOptions",
  components: {
    IconEllipsis,
    IconGarbage,
    RelationshipRemoveReasonModal,
  },
  directives: {
    ClickOutside,
  },
  props: {
    activeStatus: {
      default: true,
    },
  },
  data() {
    return {
      panelOpened: false,
      removeModal: false,
    };
  },
  methods: {
    closePanel() {
      this.panelOpened = false;
    },
    openOptions() {
      this.panelOpened = true;
    },
    showHistory() {
      this.$emit("show-history");
      this.closePanel();
    },
    removeRelationshio() {
      this.$emit("remove-relationship");
      this.closePanel();
    },
    activateRelationship() {
      this.$emit("activate-relationship");
      this.closePanel();
    },
    deactivateRelationship() {
      this.removeModal = true;
      this.closePanel();
    },
    relationshipDeactivate(pData) {
      this.$emit("deactivate-relationship", pData);
      this.closePanel();
      this.closeRelationshipRemoveReason();
    },
    closeRelationshipRemoveReason() {
      this.removeModal = false;
    },
  },
};
</script>
