import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query People($pes_id: Int) {
    people(pes_id: $pes_id) {
      rows {
        pes_id
        pes_nome
        pes_mae
        pes_pai
        pes_nascimento
        ethnicity {
          etn_id
          etn_nome
          etn_codigo_sisaih
        }
        gender {
          sex_id
          sex_sigla
        }
        telephones {
          rows {
            tel_id
            tel_id_pessoas
            tel_id_ddds
            tel_numero
          }
        }
        patient {
          pac_id
          pac_prontuario_unico
          pac_ativo
        }
        cns {
          crs_numero
        }
        nationality {
          nac_id
          nac_nome
        }
        city {
          mun_id
          mun_nome
          state {
            est_id
            est_sigla
            est_nome
          }
        }
        address {
          end_logradouro
          end_cep
          end_numero
          end_complemento
          neighborhood {
            bai_id
            bai_nome
          }
          publicPlaceType {
            tlg_id
            tlg_nome
          }
        }
      }
    }
  }
`;

export default async (variables) => {
  try {
    const { data } = await GraphQLClient.query({ query, variables });
    return data.people.rows[0];
  } catch (err) {
    const jsonResponse = JSON.parse(JSON.stringify(err));
    return Promise.reject(jsonResponse.graphQLErrors[0].message);
  }
};
