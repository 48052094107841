import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query($lip_id_laboratoriais_exames_bioslab: Int!) {
    SearchBioslabPatientTags(
      lip_id_laboratoriais_exames_bioslab: $lip_id_laboratoriais_exames_bioslab
    ) {
      rows {
        lip_id
        lip_codigo_etiqueta
        lip_impressao
        lip_impresso
      }
    }
  }
`;

export default async ({ commit }, { lip_id_laboratoriais_exames_bioslab }) => {
  const variables = {
    lip_id_laboratoriais_exames_bioslab,
  };
  const { data } = await GraphQLClient.query({
    query,
    variables,
    fetchPolicy: "no-cache",
  }).catch((err) => {
    throw err;
  });
  return data.SearchBioslabPatientTags.rows;
};
