<template lang="html">
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 461.833 461.833"
    style="enable-background: new 0 0 461.833 461.833"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M442,444.833V144.217c0-4.817-1.983-9.35-5.383-12.467L299.767,4.533C296.65,1.7,292.4,0,288.15,0H76.5
          c-9.35,0-17,7.65-17,17v189.833c0,9.35,7.65,17,17,17s17-7.65,17-17V34h172.833v130.333c0,9.35,7.65,17,17,17h121.833
          c0.85,0,1.983,0,2.833-0.283v246.783H93.5v-68c0-9.35-7.65-17-17-17s-17,7.65-17,17v85c0,9.35,7.65,17,17,17H425
          C434.35,461.833,442,454.183,442,444.833z M300.333,51.567l102.85,95.767h-102.85V51.567z"
        />
        <path
          d="M19.833,280.5c0,9.35,7.65,17,17,17h177.083l-54.683,48.733c-7.083,6.233-7.65,17-1.417,24.083
          c3.4,3.683,7.933,5.667,12.75,5.667c3.967,0,7.933-1.417,11.333-4.25l88.4-78.767c3.683-3.117,5.667-7.933,5.667-12.75
          s-1.983-9.35-5.667-12.75L181.9,188.7c-7.083-6.233-17.85-5.667-24.083,1.417c-6.233,7.083-5.667,17.85,1.417,24.083l55.533,49.3
          H36.833C27.483,263.5,19.833,271.15,19.833,280.5z"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "Export",
};
</script>
