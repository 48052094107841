export const FormatCNPJ = (pCNPJ) => {
  return pCNPJ.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/gi,
    "$1.$2.$3/$4-$5",
  );
};

export const FormatCPF = (pCPF) => {
  return pCPF.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/gi, "$1.$2.$3-$4");
};

export const verifyCpfNumber = (pCPF) => {
  const cpf = pCPF.replace(/[^\d]+/g, "");

  if (cpf.length === 0) {
    return false;
  }

  if (
    cpf.length !== 11 ||
    cpf === "00000000000" ||
    cpf === "11111111111" ||
    cpf === "22222222222" ||
    cpf === "33333333333" ||
    cpf === "44444444444" ||
    cpf === "55555555555" ||
    cpf === "66666666666" ||
    cpf === "77777777777" ||
    cpf === "88888888888" ||
    cpf === "99999999999"
  ) {
    return false;
  }

  let add = 0;
  for (let i = 0; i < 9; i++) {
    add += parseInt(cpf.charAt(i)) * (10 - i);
  }

  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(9))) {
    return false;
  }

  add = 0;

  for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);

  if (rev === 10 || rev === 11) rev = 0;

  if (rev !== parseInt(cpf.charAt(10))) {
    return false;
  }

  return true;
};

export const verifyCnsNumber = (pCns) => {
  if (!pCns) return true;
  const cns = pCns.replace(/[^\d]+/g, "");

  const valid =
    (validateCns(cns) || validateTemporaryCns(cns)) && validateZeros(cns);
  if (!valid) return false;

  return valid;
};

/**
 * @param {string} pCns
 */
const validateCns = (pCns) => {
  if (pCns.length !== 15) return false;

  const pis = pCns.substring(0, 11);
  let sum =
    Number(pis.substring(0, 1)) * 15 +
    Number(pis.substring(1, 2)) * 14 +
    Number(pis.substring(2, 3)) * 13 +
    Number(pis.substring(3, 4)) * 12 +
    Number(pis.substring(4, 5)) * 11 +
    Number(pis.substring(5, 6)) * 10 +
    Number(pis.substring(6, 7)) * 9 +
    Number(pis.substring(7, 8)) * 8 +
    Number(pis.substring(8, 9)) * 7 +
    Number(pis.substring(9, 10)) * 6 +
    Number(pis.substring(10, 11)) * 5;

  let rest = sum % 11;
  let dv = 11 - rest;
  if (dv === 11) dv = 0;

  let result = "";
  if (dv === 10) {
    sum =
      Number(pis.substring(0, 1)) * 15 +
      Number(pis.substring(1, 2)) * 14 +
      Number(pis.substring(2, 3)) * 13 +
      Number(pis.substring(3, 4)) * 12 +
      Number(pis.substring(4, 5)) * 11 +
      Number(pis.substring(5, 6)) * 10 +
      Number(pis.substring(6, 7)) * 9 +
      Number(pis.substring(7, 8)) * 8 +
      Number(pis.substring(8, 9)) * 7 +
      Number(pis.substring(9, 10)) * 6 +
      Number(pis.substring(10, 11)) * 5 +
      2;
    rest = sum % 11;
    dv = 11 - rest;
    result = pis + "001" + String(dv);
  } else {
    result = pis + "000" + String(dv);
  }

  return pCns === result;
};

/**
 * @param {string} pCns
 */
const validateTemporaryCns = (pCns) => {
  const pis = pCns.substring(0, 15);

  if (pis === "" || !["7", "8", "9"].includes(pCns.substring(0, 1)))
    return false;

  const sum =
    parseInt(pis.substring(0, 1), 10) * 15 +
    parseInt(pis.substring(1, 2), 10) * 14 +
    parseInt(pis.substring(2, 3), 10) * 13 +
    parseInt(pis.substring(3, 4), 10) * 12 +
    parseInt(pis.substring(4, 5), 10) * 11 +
    parseInt(pis.substring(5, 6), 10) * 10 +
    parseInt(pis.substring(6, 7), 10) * 9 +
    parseInt(pis.substring(7, 8), 10) * 8 +
    parseInt(pis.substring(8, 9), 10) * 7 +
    parseInt(pis.substring(9, 10), 10) * 6 +
    parseInt(pis.substring(10, 11), 10) * 5 +
    parseInt(pis.substring(11, 12), 10) * 4 +
    parseInt(pis.substring(12, 13), 10) * 3 +
    parseInt(pis.substring(13, 14), 10) * 2 +
    parseInt(pis.substring(14, 15), 10) * 1;

  return sum % 11 === 0;
};

const validateZeros = (pCns) => {
  return pCns !== "000000000000000";
};
