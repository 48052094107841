<template>
  <Modulebox
    :granted="hasPermission"
    title="Cadastrar Pré-Atendimento"
    class="emergency-pre-registration"
  >
    <div class="emergency-pre-registration-body">
      <form class="form-container" @submit.stop.prevent="insertPreRegistration">
        <RgValidatorForm ref="validator">
          <FormBase title="Paciente" class="grid-patient">
            <RgSuggestPatientInformation
              ref="patientInfoSuggest"
              v-model="patientName"
              permit-free-text
              :remove-filter="FILTER_REMOVE"
              :rules="{ forceSelection: false, required: true }"
              :show-buttons="false"
              :with-patient-module="['emergency']"
              :extra-data="['withoutAddress']"
              :pac_prontuario_unico="
                patientInfo && patientInfo.prontuario_unico
                  ? patientInfo.prontuario_unico
                  : null
              "
              @patientInfo="getPatientData"
            />
          </FormBase>

          <FormBase title="Dados do Pré-Atendimento" class="grid-pre-service">
            <div class="pre-registration">
              <div class="first-row">
                <RgComboboxUnithealth
                  ref="unitHealth"
                  v-model="form.unit"
                  default-text="Selecione uma Unidade"
                  :rules="{ required: true }"
                  :disabled="true"
                  :class="{ disable: true }"
                  label="Unidade de Saúde"
                  class="unithealth"
                />

                <RgComboboxSectorLegacy
                  ref="sector"
                  v-model="form.sector"
                  :rules="{ required: true }"
                  :unit-health-id="Number(form.unit)"
                  class="sector"
                  label="Setor"
                  :disabled="disableAll"
                  :class="{ disable: disableAll }"
                  :empty-default-text="'Selecione uma Unidade'"
                  default-text="Selecione"
                  :submodule-id="submoduleId"
                  :operation-id="operationId"
                />

                <RgComboboxSpecialization
                  ref="ComboboxSpecialization"
                  v-model="form.specialization"
                  class="specialization"
                  :disabled="disableAll"
                  :class="{ disable: disableAll }"
                />
              </div>

              <div class="second-row">
                <RgInputMasked
                  ref="systolic"
                  v-model="form.systolic"
                  class="systolic"
                  label="Pressão Arterial Sistólica"
                  mask="###"
                  :typeMask="'mmHg'"
                  placeholder="Digite o valor aferido"
                  :rules="{
                    required: bloodPressureRequired,
                    fn: validateSystolic,
                  }"
                  :disabled="disableAll"
                  :class="{ disable: disableAll }"
                />

                <RgInputMasked
                  ref="diastolic"
                  v-model="form.diastolic"
                  label="Pressão Arterial Diastólica"
                  class="diastolic"
                  mask="###"
                  :typeMask="'mmHg'"
                  placeholder="Digite o valor aferido"
                  :rules="{
                    required: bloodPressureRequired,
                    fn: validateDiastolic,
                  }"
                  :disabled="disableAll"
                  :class="{ disable: disableAll }"
                />

                <RgInputMasked
                  v-model="form.glucose"
                  class="glucose"
                  label="Glicemia Capilar"
                  placeholder="Digite o valor aferido"
                  :rules="{
                    required: requiredFields,
                    fn: validateGlucose,
                  }"
                  :typeMask="' mg/dL'"
                  :maxlength="3"
                  :disabled="disableAll"
                  :class="{ disable: disableAll }"
                />

                <RgInputCheckbox
                  class="insuline"
                  label="Antes da Insulina"
                  :value="form.checkGlucose"
                  :disabled="disableGlucose || disableAll"
                  :class="{ disable: disableGlucose || disableAll }"
                  @change-value="onChangeInsulina"
                />

                <RgInputMasked
                  v-model="form.temperature"
                  class="temperature"
                  label="Temperatura"
                  placeholder="Digite o valor aferido"
                  :typeMask="'°C'"
                  :rules="{
                    required: requiredFields,
                    fn: validateTemperature,
                  }"
                  mask="##,#"
                  :disabled="disableAll"
                  :class="{ disable: disableAll }"
                />

                <RgInputCheckbox
                  class="priority"
                  label="Prioridade"
                  :value="form.priority"
                  :disabled="disableAll"
                  :class="{ disable: disableAll }"
                  @change-value="onChangePriority"
                />
              </div>
            </div>

            <hr class="separator" />

            <div class="professional-information">
              <RgInput
                v-model="form.professional"
                label="Profissional Responsável"
                class="disable unselect"
                :disabled="true"
              />

              <RgComboboxHealthOccupation
                v-model="form.occupation"
                :rules="{ required: true }"
                label="Ocupação"
                :employee="form.professionalId"
                :unit-health="Number(form.unit)"
                :disabled="!form.professional"
                :class="{
                  'disable unselect': !form.professional || disableAll,
                }"
                @selectOnlyItem="selectOnlyItemHealthOccupation"
              />
            </div>
          </FormBase>
        </RgValidatorForm>
      </form>
    </div>

    <div class="footer">
      <RgCleanButton
        small
        class="clean"
        title="Limpar"
        @click="confirmCleanForm"
      />

      <RgCancelButton
        ref="cancelButton"
        medium
        class="cancel"
        @click="actionCancel"
      />

      <RgSaveButton
        ref="btnInsertPreRegistration"
        medium
        class="save"
        :disabled="disableAll"
        :class="{ disable: disableAll }"
        @click="savePreRegistration"
      />
    </div>

    <ModalConfirmFieldClearing
      :show="modalConfirmFieldClearing"
      @getYes="getCleanFormMethod"
      @getOut="closeConfirmFieldClearing"
    />
  </Modulebox>
</template>

<script>
import {
  RgSuggestPatientInformation,
  RgComboboxHealthOccupation,
  RgCleanButton,
  RgSaveButton,
  RgValidatorForm,
  RgCancelButton,
  RgInputCheckbox,
  RgInput,
  RgComboboxUnithealth,
  RgInputMasked,
  RgComboboxSectorLegacy,
  ModalConfirmFieldClearing,
} from "~tokio/primitive";

import FormBase from "~tokio/foundation/form-base/FormBase";
import Modulebox from "~tokio/foundation/modulebox/Modulebox";
import RgComboboxSpecialization from "$emergency/bulletin/component/combobox/rg-combobox-specialization/RgComboboxSpecialization";

const FORM_BASE = {
  unit: "",
  sector: "",
  systolic: "",
  diastolic: "",
  glucose: "",
  checkGlucose: false,
  temperature: "",
  priority: false,
  specialization: "",
  professionalId: null,
  professional: null,
  occupation: null,
};

export default {
  name: "NewPreRegistration",

  components: {
    RgSuggestPatientInformation,
    RgComboboxHealthOccupation,
    RgInputMasked,
    Modulebox,
    FormBase,
    RgValidatorForm,
    RgSaveButton,
    RgCleanButton,
    RgCancelButton,
    RgInputCheckbox,
    RgInput,
    RgComboboxUnithealth,
    RgComboboxSectorLegacy,
    RgComboboxSpecialization,
    ModalConfirmFieldClearing,
  },

  data() {
    return {
      patientId: null,
      patientName: null,
      form: this.$utils.obj.DeepCopy(FORM_BASE),
      modalConfirmFieldClearing: false,
      patientInfo: { prontuario_unico: null },
      haveActivePatient: false,
      haveDeactivatedPatient: false,
      hasPatientName: false,
      userHealthOccupations: null,
    };
  },

  computed: {
    submoduleId() {
      return 167;
    },

    operationId() {
      return 467;
    },

    hasPermission() {
      return !!this.$Permissions.global.has(
        "emergencia.buscaPreCadastro.incluir",
        this.form.unit,
      );
    },

    bloodPressureRequired() {
      return (
        this.form.systolic !== "" ||
        this.form.diastolic !== "" ||
        (!this.form.glucose && !this.form.temperature)
      );
    },

    requiredFields() {
      return (
        !this.form.systolic &&
        !this.form.diastolic &&
        !this.form.glucose &&
        !this.form.temperature
      );
    },

    disableGlucose() {
      return this.form.glucose?.length < 2;
    },

    disableAll() {
      if (this.haveDeactivatedPatient) {
        return true;
      }
      return !!(!this.haveActivePatient && !this.hasPatientName);
    },
  },

  watch: {
    "form.glucose"(pValue) {
      if (!pValue || pValue.length < 2) {
        this.form.checkGlucose = false;
      }
    },

    patientName(value) {
      this.hasPatientName = false;
      if (value?.length >= 3) {
        this.hasPatientName = true;
      }
    },
  },

  async mounted() {
    await this.handleMounted();
    await this.handleProfessionalInfo();
  },

  created() {
    this.FILTER_REMOVE = [
      { label: "Prontuário Único", col: "recordNumber" },
      { label: "CPF", col: "cpf" },
      { label: "CNS", col: "cns" },
      { label: "Data de Nascimento", col: "birthDate" },
    ];
    this.REASONS_BLOCKED_PATIENT = {
      1: "NÃO INFORMADO",
      2: "INABILITADO",
      3: "DESCARTADO",
      4: "ÓBITO",
      5: "INATIVIDADE",
      6: "PRONTUÁRIO DUPLICADO",
      7: "TRIAGEM",
      8: "MOTIVOS ADMINISTRATIVOS",
    };
  },

  methods: {
    async handleMounted() {
      const filterSector = this.$store.getters[
        "Emergency/PreRegistration/GET_FILTER_EMERGENCY_PRE_REGISTER"
      ]?.sector;

      if (filterSector) {
        this.form.sector = filterSector;
      }

      this.form.unit = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    },

    async handleProfessionalInfo() {
      this.userHealthOccupations = await this.$store.getters[
        "Login/GET_USER_HEALTH_OCCUPATIONS"
      ];

      this.form.professional = this.userHealthOccupations?.pes_nome;
      this.form.professionalId = this.userHealthOccupations?.fun_id;
    },

    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    onChangeInsulina(value) {
      this.form.checkGlucose = value;
    },

    onChangePriority(value) {
      this.form.priority = value;
    },

    async savePreRegistration() {
      try {
        const isInvalid = !(await this.isFormValid());

        if (isInvalid) {
          this.$toaster.warning("Verifique os campos");
          this.$refs.btnInsertPreRegistration.actionDone();

          return;
        }

        this.$refs.btnInsertPreRegistration.actionSubmit();

        const obj = {
          arrFormData: {
            prc_glicemia_capilar: this.form.glucose,
            prc_id: 0,
            prc_id_especializacoes: this.form.specialization,
            prc_id_setor: this.form.sector,
            prc_insulina: this.form.checkGlucose ? 1 : 0,
            prc_nome: this.patientName.toUpperCase(),
            prc_pressao_arterial_max: this.form.systolic,
            prc_pressao_arterial_min: this.form.diastolic,
            prc_prioridade: this.form.priority,
            prc_risco: 0,
            prc_temperatura: this.form.temperature,
            prc_id_pessoas: this.patientId,
            prc_codigo_cbo: this.form.occupation,
            prc_id_funcionarios: this.form.professionalId,
          },
        };

        const action = "Emergency/PreRegistration/INSERT_PRE_REGISTRATION";

        await this.$store.dispatch(action, obj);

        const message = "Cadastro realizado com sucesso";

        this.$toaster.success(message);

        this.actionCancel();
      } catch (e) {
        this.$toaster.error("Erro ao salvar");
      } finally {
        this.$refs.btnInsertPreRegistration.actionDone();
      }
    },

    actionCancel() {
      const routeName = "emergency.pre-registration";
      this.$router.push({ name: routeName });
    },

    getCleanFormMethod() {
      this.clearForm();
      this.modalConfirmFieldClearing = false;
    },

    confirmCleanForm() {
      this.modalConfirmFieldClearing = true;
    },

    closeConfirmFieldClearing() {
      this.modalConfirmFieldClearing = false;
    },

    clearForm() {
      this.patientId = null;
      this.patientName = null;
      this.form.professional = null;
      this.patientInfo.prontuario_unico = null;
      this.$refs.patientInfoSuggest.cleanValidate();
      this.$refs.patientInfoSuggest.cleanPatient();
      this.$refs.unitHealth.cleanValidate();
      this.$refs.sector.cleanValidate();
      this.$refs.systolic.cleanValidate();
      this.$refs.diastolic.cleanValidate();
      this.form = this.$utils.obj.DeepCopy(FORM_BASE);
      this.form.unit = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
      this.form.professional = this.userHealthOccupations?.pes_nome;
      this.form.professionalId = this.userHealthOccupations?.fun_id;
    },

    async getPatientData(pValue) {
      this.patientId =
        pValue && Number(pValue.pes_id) > 0 ? Number(pValue.pes_id) : null;

      this.patientName = pValue && pValue.pes_nome ? pValue.pes_nome : null;

      this.patientInfo.prontuario_unico = null;

      this.haveDeactivatedPatient = false;
      const patientIdReasonBlocked =
        pValue?.patient?.pac_id_motivo_inabilitacao;
      if (patientIdReasonBlocked) {
        this.haveDeactivatedPatient = true;
        return this.$toaster.warning(
          "Paciente inativo. Motivo: " +
            `${this.REASONS_BLOCKED_PATIENT[patientIdReasonBlocked]}`,
        );
      }

      const patient = pValue.patient;

      if (!patient && this.patientId) {
        const newPatient = await this.$store.dispatch(
          "Person/Patient/CREATE_PATIENT_FOR_PERSON",
          {
            pes_id: pValue.pes_id,
            withPatientEmergency: true,
          },
        );

        pValue.patient = newPatient;
        pValue.prontuario_unico = newPatient?.pac_prontuario_unico;
        this.patientInfo = pValue;
      }

      const hasPerson = !!(patient && this.patientId);
      this.haveActivePatient = hasPerson;
    },

    validateTemperature(pValue, pErrors) {
      if (!pValue) {
        return true;
      }

      const number = Number(pValue.replace(",", "."));

      const invalidLength = pValue.length < 2;
      const invalidMinRange = number < 20.0;
      const invalidMaxRange = number >= 45.1;

      if (invalidLength) {
        pErrors.push("Temperatura deve ter no mínimo dois dígitos");
        return false;
      } else {
        if (invalidMinRange) {
          pErrors.push("Temperatura mínima permitida é 20.");
          return false;
        }

        if (invalidMaxRange) {
          pErrors.push("Temperatura máxima permitida 45");
          return false;
        }
      }

      return true;
    },

    validateGlucose(pValue, pErrors) {
      if (!pValue) {
        return true;
      }

      const invalidLength = pValue.length < 2;

      if (pValue && pValue.length > 0) {
        if (invalidLength) {
          pErrors.push("Glicemia capilar deve ter no mínimo dois dígitos.");
          return false;
        }

        const regex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>?]+/;
        if (regex.test(pValue)) {
          pErrors.push(
            "Glicemia capilar não pode conter caracteres especiais.",
          );
          return false;
        }
      }

      return true;
    },

    validateSystolic(pValue, pErrors) {
      const isWithoutPressure = !this.form.systolic && !this.form.diastolic;

      if (isWithoutPressure) {
        return true;
      }

      if (pValue && pValue.length > 0) {
        if (this.checkOnlyNumberValue(pValue)) {
          pErrors.push(
            `A pressão arterial sistólica deve possuir somente números`,
          );
          return false;
        }

        if (this.checkZeroInFirstPosition(pValue)) {
          pErrors.push(`A pressão arterial sistólica não pode iniciar com 0`);
          return false;
        }

        const invalidValueRange = pValue.length < 2 || pValue.length > 3;

        if (invalidValueRange) {
          pErrors.push(
            `A pressão arterial sistólica deve ter no mínimo 2 e máximo 3 dígitos.`,
          );
          return false;
        }

        const diastolicBiggerThanSystolic =
          Number(this.form.systolic) > 0 &&
          Number(this.form.diastolic) > Number(this.form.systolic);

        if (diastolicBiggerThanSystolic) {
          pErrors.push(
            "A pressão arterial sistólica deve ser maior do que a diastólica.",
          );

          return false;
        }

        const equalPressure = this.form.systolic === this.form.diastolic;

        if (equalPressure) {
          pErrors.push(
            "A pressão arterial sistólica não pode ser idêntica à diastólica.",
          );

          return false;
        }
      }

      return true;
    },

    validateDiastolic(pValue, pErrors) {
      const isWithoutPressure = !this.form.systolic && !this.form.diastolic;

      if (isWithoutPressure) {
        return true;
      }

      if (pValue && pValue.length > 0) {
        if (this.checkOnlyNumberValue(pValue)) {
          pErrors.push(
            `A pressão arterial diastólica deve possuir somente números`,
          );
          return false;
        }

        if (this.checkZeroInFirstPosition(pValue)) {
          pErrors.push(`A pressão arterial diastólica não pode iniciar com 0`);
          return false;
        }

        const invalidValueRange = pValue.length < 2 || pValue.length > 3;

        if (invalidValueRange) {
          pErrors.push(
            `A pressão arterial diastólica deve ter no mínimo 2 e máximo 3 dígitos.`,
          );
          return false;
        }

        const systolicLessThanDiastolic =
          Number(this.form.diastolic) > 0 &&
          Number(this.form.systolic) < Number(this.form.diastolic);

        if (systolicLessThanDiastolic) {
          pErrors.push(
            "A pressão arterial diastólica deve ser menor do que a sistólica.",
          );

          return false;
        }

        const equalPressure = this.form.systolic === this.form.diastolic;

        if (equalPressure) {
          pErrors.push(
            "A pressão arterial sistólica não pode ser idêntica à diastólica.",
          );

          return false;
        }
      }

      return true;
    },

    checkZeroInFirstPosition(value) {
      return value[0] && parseInt(value[0]) === 0;
    },

    checkOnlyNumberValue(value) {
      const isNumber = /^\d+$/.test(value);
      return !isNumber;
    },

    selectOnlyItemHealthOccupation(value) {
      this.form.occupation = value;
    },
  },
};
</script>
