<style src="./PatientMedicalRecord.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="patient-medical-record">
    <NoRecord v-if="!patientRecord.pac_prontuario_unico" />
    <RecordNumberForm v-if="patientRecord.pac_prontuario_unico" />
  </section>
</template>

<script>
import NoRecord from "./no-record/NoRecord";
import RecordNumberForm from "./record-number-form/RecordNumberForm";
import { mapGetters } from "vuex";

export default {
  name: "PatientMedicalRecord",
  components: {
    NoRecord,
    RecordNumberForm,
  },
  computed: {
    ...mapGetters({
      patientRecord: ["Patient/GET_PATIENT_RECORD"],
    }),
  },
};
</script>
