<style src="./RgComboboxSurgeryRequestingPhysician.scss" lang="scss"></style>
<script>
import { RgRemoteComboboxMixin } from "~tokio/primitive";
import SurgeryRequestingPhysicianModel from "$surgery-center/surgery/model/SurgeryRequestingPhysicianModel";
export default {
  name: "RgComboboxSurgeryRequestingPhysician",
  mixins: [RgRemoteComboboxMixin],
  props: {
    label: {
      default: "Médico Solicitante",
    },
    emptyDefaultText: {
      default: "Selecione uma clínica solicitante",
    },
    clinician: {
      required: true,
    },
  },
  data: () => {
    return {
      model: SurgeryRequestingPhysicianModel,
      cache: false,
    };
  },
  computed: {
    filter() {
      return {
        inc_id: this.clinician,
      };
    },
  },
  methods: {
    formatItem(pData) {
      return {
        value: pData.fun_id,
        text: pData.pes_nome,
      };
    },
  },
};
</script>
