import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query searchHospitalizationBed(
    $inl_id: Int
    $inl_nome: String
    $inl_id_locais_atendimento: Int
    $inl_id_internacoes_situacoes_leito: Int
    $ilt_id_internacoes_tipos_leito: Int
    $actualBed: Boolean
    $exceptBedCurrent: Boolean
    $inl_habilitado: Int
    $limit: Int
  ) {
    hospitalizationBed(
      inl_id: $inl_id
      inl_nome: $inl_nome
      inl_id_locais_atendimento: $inl_id_locais_atendimento
      inl_id_internacoes_situacoes_leito: $inl_id_internacoes_situacoes_leito
      ilt_id_internacoes_tipos_leito: $ilt_id_internacoes_tipos_leito
      actualBed: $actualBed
      exceptBedCurrent: $exceptBedCurrent
      inl_habilitado: $inl_habilitado
      limit: $limit
    ) {
      count
      rows {
        inl_id
        inl_nome
        inl_id_internacoes_situacoes_leito
      }
    }
  }
`;

export default async (variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    return data.hospitalizationBed;
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors);
  }
};
