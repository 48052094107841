import MainDiagnosis from "$hospitalization-billing/views/report/main-diagnosis/MainDiagnosis";
import AihPerSpecialty from "$hospitalization-billing/views/report/aih-per-specialty/AihPerSpecialty.vue";
import AihPerPacient from "$hospitalization-billing/views/report/aih-per-patient/AihPerPatient.vue";
import SecondaryProcedure from "$hospitalization-billing/views/report/secondary-procedure/SecondaryProcedure.vue";
import MainProcedure from "$hospitalization-billing/views/report/main-procedure/MainProcedure.vue";
import Homonyms from "$hospitalization-billing/views/report/homonyms/Homonyms.vue";
import UnbilliedExits from "$hospitalization-billing/views/report/unbillied-exits/UnbilliedExits.vue";
import PreviewValues from "$hospitalization-billing/views/report/preview-values/PreviewValues.vue";
import EmergencyReport from "$hospitalization-billing/views/report/daily/emergency-report/EmergencyReport.vue";
import InternalizationReport from "$hospitalization-billing/views/report/daily/internalization-report/InternalizationReport.vue";

import Admission from "$hospitalization/report/view/admission/Admission.vue";

import SpecialtiesAttendancesCount from "$emergency/view/report/specialties-attendances-count/SpecialtiesAttendancesCount.vue";
import SpecialtiesAttendances from "$emergency/view/report/specialties-attendances/SpecialtiesAttendances.vue";
import SpecialtiesAttendancesByDay from "$emergency/view/report/specialties-attendances-by-day/SpecialtiesAttendancesByDay.vue";

import QuantitativeExamReport from "$exam/submodules/report/view/quantitative-exam-report/QuantitativeExamReport.vue";
import ExamQueueReport from "$exam/submodules/report/view/exam-queue-report/ExamQueueReport";

import AppointmentDailyReport from "$appointment/submodules/report/views/appointment-daily-report/AppointmentDailyReport.vue";
import AppointmentPeriodReport from "$appointment/submodules/report/views/appointment-period-report/AppointmentPeriodReport.vue";
import AppointmentDisabledPeriodsReport from "$appointment/submodules/report/views/appointment-disabled-periods-report/AppointmentDisabledPeriodsReport.vue";
import AppointmentHistoryScheduleReport from "$appointment/submodules/report/views/appointment-history-schedule-report/AppointmentHistoryScheduleReport.vue";
import AppointmentExcludeScaleSchedule from "$appointment/submodules/report/views/appointment-exclude-scale-schedule/AppointmentExcludeScaleSchedule.vue";
import AppointmentExcludedReport from "$appointment/submodules/report/views/appointment-excluded-report/AppointmentExcludedReport.vue";
import AppointmentUserProductivityReport from "$appointment/submodules/report/views/appointment-user-productivity-report/AppointmentUserProductivityReport.vue";
// import AppointmentProfessionalProductivityReport from "$appointment/submodules/report/views/appointment-professional-productivity-report/AppointmentProfessionalProductivityReport.vue";
// import AppointmentOccupationdReport from "$appointment/submodules/report/views/appointment-occupation-report/AppointmentOccupationReport.vue";
import AppointmentQueueReport from "$appointment/submodules/report/views/appointment-queue-report/AppointmentQueueReport.vue";

const MODULES = [
  {
    label: "Consulta",
    active: true,
    value: [
      {
        value: AppointmentExcludedReport,
        label: "Agendamentos Excluídos",
      },
      {
        value: AppointmentDailyReport,
        label: "Consultas por Dia",
      },
      // {
      //   value: AppointmentOccupationdReport,
      //   label: "Consultas por Ocupação ",
      // },
      {
        value: AppointmentExcludeScaleSchedule,
        label: "Escalas Excluídas",
      },
      {
        value: AppointmentDisabledPeriodsReport,
        label: "Períodos Inabilitados",
      },
      {
        value: AppointmentQueueReport,
        label: "Fila de Consulta",
      },
      {
        value: AppointmentHistoryScheduleReport,
        label: "Histórico de Agendamentos do Paciente",
      },
      {
        value: AppointmentUserProductivityReport,
        label: "Produtividade Usuário",
      },
      // {
      //   value: AppointmentProfessionalProductivityReport,
      //   label: "Produtividade Profissional de Saúde",
      // },
      {
        value: AppointmentPeriodReport,
        label: "Relatório Diário",
      },
    ],
  },
  {
    label: "Exame",
    active: true,
    value: [
      {
        value: ExamQueueReport,
        label: "Filas de Exames",
      },
      {
        active: false,
        value: QuantitativeExamReport,
        label: "Relatório Quantitativo de Exame",
      },
    ],
  },
  {
    label: "Emergência",
    active: true,
    value: [
      {
        value: SpecialtiesAttendances,
        label: "Entrada e Saída de Boletim por Especialidade",
      },
      {
        value: SpecialtiesAttendancesCount,
        label: "Registro de Atendimentos por Especialidade",
      },
      {
        value: SpecialtiesAttendancesByDay,
        label: "Registro Diario de Emergência por Especialidade",
      },
      {
        value: EmergencyReport,
        label: "Relatório Diário",
      },
    ],
  },
  {
    label: "Faturamento",
    active: true,
    value: [
      {
        value: AihPerSpecialty,
        label: "AIH por Especialidade",
      },
      {
        value: MainDiagnosis,
        label: "AIH's por Diagnóstico Principal",
      },
      {
        value: AihPerPacient,
        label: "AIH por Paciente",
      },
      {
        value: SecondaryProcedure,
        label: "AIH's por Procedimento Especial e Secundário",
      },
      {
        value: MainProcedure,
        label: "AIH's por Procedimento Principal",
      },
      {
        value: Homonyms,
        label: "Homônimos",
      },
      {
        value: UnbilliedExits,
        label: "Internação Saídas Não Faturadas",
      },
      {
        value: PreviewValues,
        label: "Prévia Valor",
      },
    ],
  },
  {
    label: "Internação",
    active: true,
    value: [
      {
        value: Admission,
        label: "Entradas",
      },
      {
        value: InternalizationReport,
        label: "Relatório Diário",
      },
    ],
  },
];

export default MODULES.filter((module) => module.active !== false).map(
  (module) => {
    return !module.value || module.value.length === 0
      ? module
      : {
          ...module,
          value: module.value.filter((report) => report.active !== false),
        };
  },
);
