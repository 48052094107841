import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query Ddds($ddd_codigo: String) {
    ddds(ddd_codigo: $ddd_codigo) {
      rows {
        ddd_id
        ddd_codigo
      }
    }
  }
`;

export default async ({ variables }) => {
  const { data } = await GraphQLClient.query({
    query,
    variables,
    fetchPolicy: "no-cache",
  }).catch((err) => {
    console.error(err);
  });

  return data.ddds.rows;
};
