<style src="./InputRg.scss" lang="scss" scoped></style>

<template>
  <fieldset class="input-rg">
    <RgInputRg v-model="form.info.rg.rgs_numero" @blur="validateBlur" />
    <RgComboboxIssuingBody
      v-model="form.info.rg.rgs_id_orgaos_emissores"
      label="Órgão Emissor"
      class="form-base"
    />
    <RgInputDate
      v-model="form.info.rg.rgs_expedicao"
      v-mask="'##/##/####'"
      label="Data de Expedição"
      @blur="validateBlur"
    />
  </fieldset>
</template>

<script>
import {
  RgValidatorMixin,
  RgInputMixin,
  RgInputRg,
  RgInputDate,
} from "~tokio/primitive";
import RgComboboxIssuingBody from "$patient/common/components/combobox/rg-combobox-issuing-body/RgComboboxIssuingBody";
import { mask } from "vue-the-mask";
import GetRgByPersonId from "./actions/GetRgByPersonId";
import moment from "moment";

export default {
  name: "InputRg",
  components: {
    RgComboboxIssuingBody,
    RgInputRg,
    RgInputDate,
  },
  directives: {
    mask,
  },
  mixins: [RgValidatorMixin, RgInputMixin],
  props: {
    pesId: null,
    documentData: {
      default: null,
    },
  },
  data() {
    return {
      form: {
        info: {
          rg: {
            rgs_numero: "",
            rgs_expedicao: "",
            rgs_id_orgaos_emissores: 1,
          },
        },
        mutationName: "AddRgToPerson",
        mutationParams: "$pes_id: Int, $rg: RgInputType",
        mutationArgs: "pes_id: $pes_id, rg: $rg",
        mutationReturnField: "rgs_id",
      },
    };
  },
  watch: {
    "form.info": {
      handler: function () {
        const form = JSON.parse(JSON.stringify(this.form));
        form.info.rg.rgs_expedicao = this.$utils.date.BrazilianDateToDatabase(
          form.info.rg.rgs_expedicao,
        );
        form.info.rg.rgs_numero = form.info.rg.rgs_numero
          ? form.info.rg.rgs_numero.replace(/(\.|-)/gi, "")
          : "";
        this.$emit("formatted-data", form);
      },
      deep: true,
    },
    documentData(pValue) {
      this.form.info.rg = pValue.info.rg;
    },
  },
  mounted() {
    this.readInfo();
  },
  methods: {
    validateBlur() {
      this.validate();
    },
    async readInfo() {
      const data = await GetRgByPersonId({ pesId: this.pesId });
      if (data && data.rg) {
        this.form.info.rg.rgs_numero = data.rg.rgs_numero;
        this.form.info.rg.rgs_expedicao = moment(data.rg.rgs_expedicao).format(
          "DD/MM/YYYY",
        );
        this.form.info.rg.rgs_id_orgaos_emissores =
          data.rg.rgs_id_orgaos_emissores;
      }
    },
  },
};
</script>
