/*
 * Recebe uma String, e remove os espaços extras
 * @param pValue {String} String
 */
export const RemoveExtraSpaces = (pValue) => {
  if (!pValue) {
    return null;
  }

  return pValue.replace(/\s{2,}/g, " ").trim();
};
