export default {
  GET_SELECTED_EMPLOYEE: (state) => state.selected_employee_data,
  GET_SELECTED_EXAM: (state) => state.selected_exam_data,
  GET_FILTER_EXAM_PER_DAY: (state) => state.filter_per_day_data,
  GET_FILTER_EXAM_PER_PERIOD: (state) => state.filter_per_period_data,
  GET_FILTER_DATE: (state) => state.filter_date,
  GET_EXAM_SCHEDULE: (state) => state.exam_schedule,
  GET_FILTER_EXAM_QUEUE: (state) => state.filter_exam_queue_data,
  GET_SELECTED_PATIENT_LIST_QUEUE: (state) =>
    state.selected_patient_list_queue_data,
  GET_FORM_SCHEDULE: (state) => state.form_schedule,
  GET_FILTER_TYPE_SCALE: (state) => state.filter_form_type_scale,
  GET_SELECT_ROW_LIST_SERVICE_SCALE: (state) =>
    state.selected_row_list_service_scale,
};
