<style src="./UserSearch.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="user-search">
    <div class="filter-panel">
      <Modulebox
        :granted="hasPermission"
        class="module-box"
        title="Filtro de busca"
      >
        <UserFilter @submit-filter="submitFilter" @clear-search="clearSearch" />
      </Modulebox>
    </div>

    <div class="content">
      <Modulebox
        ref="ContentModuleBox"
        :granted="hasPermission"
        title="Listagem"
        class="individual-box"
      >
        <div ref="titleHeight" slot="title">
          <div slot="actions" class="user-search-list-action">
            <button
              :disabled="offsetZero"
              class="btn btn-small btn-primary"
              @click="anterior"
            >
              Anterior
            </button>
            <button
              :disabled="contentLessThanOffset"
              class="btn btn-small btn-primary"
              @click="proxima"
            >
              Próxima
            </button>
          </div>
        </div>
        <UserList ref="individualHeight" :pagination="pagination" />
        <div ref="footerHeight" slot="footer" class="list-footer">
          <div class="list-size">Total de Registros: {{ listSize }}</div>
          <div class="list-pagination">
            Página: {{ currentPage }}/{{ numberOfPages }}
          </div>
        </div>
      </Modulebox>
    </div>
  </section>
</template>

<script>
import Modulebox from "~tokio/foundation/modulebox/Modulebox";
import { mapGetters } from "vuex";
import { UserFilter, UserList } from "./container";

export default {
  name: "UserSearch",
  components: {
    Modulebox,
    UserFilter,
    UserList,
  },
  data() {
    return {
      filterData: {
        uns_id: 0,
        status: "null",
      },
      pagination: {
        limit: 6,
        offset: 0,
        current: 1,
        total: 0,
      },
      titleFooterHeight: 0,
      footerHeight: 0,
      titleHeight: 0,
    };
  },
  computed: {
    ...mapGetters({
      usersList: "User/UserSearch/GET_LIST",
      usersCount: "User/UserSearch/GET_COUNT",
    }),
    hasPermission() {
      return true;
    },
    numberOfPages() {
      return Math.ceil(this.listSize / this.pagination.limit);
    },
    currentPage() {
      return this.listSize === 0 ? 0 : this.pagination.current;
    },
    offsetZero() {
      return this.pagination.offset === 0;
    },
    listSize() {
      return this.pagination.total ? this.pagination.total : 0;
    },
    contentLessThanOffset() {
      return this.pagination.limit * this.pagination.current >= this.listSize;
    },
    enableChangeMode() {
      return this.usersCount && this.usersCount > 0;
    },
    contentModuleBox() {
      return this.$refs.ContentModuleBox.$el.clientHeight;
    },
  },
  mounted() {
    this.titleHeight = this.$refs.titleHeight.clientHeight;
    this.footerHeight = this.$refs.footerHeight.clientHeight;
    this.filterData.uns_id = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    this.calcLimit();
  },
  beforeDestroy() {
    this.$store.commit("User/UserSearch/RESET_STORE");
  },
  methods: {
    proxima() {
      this.pagination.offset += this.pagination.limit;
      this.pagination.current += 1;
      this.search();
    },
    anterior() {
      if (this.pagination.offset <= 0) {
        this.pagination.offset = 0;
      } else {
        this.pagination.offset -= this.pagination.limit;
        this.pagination.current -= 1;
      }
      this.search();
    },
    calcLimit() {
      // TODO: Calcular altura
      this.titleFooterHeight = this.footerHeight + this.titleHeight;
      const itemHeigth = 70;
      const individualHeight = this.contentModuleBox - this.titleFooterHeight;
      this.pagination.limit = Math.floor(individualHeight / itemHeigth);
    },
    submitFilter(pFilterData) {
      this.pagination.offset = 0;
      this.pagination.current = 1;
      this.filterData = pFilterData;
      this.search();
    },
    async search() {
      await this.$store.dispatch("User/UserSearch/LIST_USER_BY_FILTER", {
        variables: { ...this.filterData, ...this.pagination },
      });
      this.pagination.total = this.usersCount;
    },
    clearSearch(pValue) {
      this.pagination = {
        limit: 6,
        offset: 0,
        current: 1,
        total: 0,
      };
      this.$store.commit("User/UserSearch/RESET_STORE");
    },
  },
};
</script>
