<style src="./ListContent.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="list-content">
    <ul v-if="hospitalizationList" class="hospitalization-list">
      <li
        v-for="(hospitalizationAih, idx) in hospitalizationList.rows"
        :key="idx"
        class="item"
        @click="selectHospitalization(hospitalizationAih)"
      >
        <div class="column-numbers">
          <div class="field hospitalization-number">
            <span title="Nº da Internação">
              <IconDocument style="margin-right: 2px" />
              {{ hospitalizationAih.int_numero }}
            </span>
          </div>
          <span class="field hospitalization-date" title="Data da Internação">
            <IconCalendar style="margin-right: 9px" />
            {{ hospitalizationAih.int_data | dateBR }}
          </span>
          <div class="field hospitalization-medical-records">
            <span title="Prontuário">
              <IconRecords />
              {{ hospitalizationAih.ppr_numero | fillZero }}
            </span>
          </div>
        </div>

        <div class="column-person-info">
          <div class="person-name-container">
            <span class="field person-name" title="Nome do Paciente">
              <IconUser />
              {{ isIndigent(hospitalizationAih) }}
            </span>
          </div>
          <div class="person-name-container">
            <span class="field hospitalization-date" title="Data de Saída">
              <IconCalendar />
              {{ hospitalizationAih.isa_data | dateBR }}
            </span>
          </div>

          <div class="person-info-container">
            <span class="field person-cns" title="Nº CNS">
              <IconCard />
              {{ hospitalizationAih | Cns }}
            </span>
            <span class="field">
              <div class="person-birth" title="Data de Nascimento">
                <IconCake />
                {{ hasBirthDate(hospitalizationAih) | dateBR }}
              </div>

              <div
                v-if="getPreviousAihNumber(hospitalizationAih)"
                class="ml-3"
                title="Nº Anterior da AIH"
              >
                <IconLeftArrow style="margin-right: -15px" />
                <IconDocument />
                {{ getPreviousAihNumber(hospitalizationAih) | aih }}
              </div>

              <div
                v-if="getAihNumber(hospitalizationAih)"
                class="ml-3"
                title="Nº AIH"
              >
                <IconDocument />
                {{ getAihNumber(hospitalizationAih) | aih }}
              </div>
            </span>
          </div>
        </div>
      </li>
    </ul>

    <div
      v-show="!hospitalizationList || hospitalizationList.length <= 0"
      class="empty-container"
    >
      <IconEmpty class="empty-icon" />
      <span class="empty-description">Nenhum Registro</span>
    </div>
  </section>
</template>

<script>
import {
  IconCalendar,
  IconUser,
  IconDocument,
  IconRecords,
  IconCard,
  IconCake,
  IconEmpty,
  IconLeftArrow,
} from "~tokio/primitive";
import moment from "moment";

export default {
  name: "ListContent",
  components: {
    IconCalendar,
    IconUser,
    IconDocument,
    IconCard,
    IconCake,
    IconEmpty,
    IconLeftArrow,
    IconRecords,
  },
  filters: {
    zeroFill(pValue) {
      return pValue.toString().padStart(4, "0");
    },
    Cns(pValue) {
      if (!pValue.patient) return "NÃO INFORMADO";
      return pValue.patient.person.cns.crs_numero
        ? pValue.patient.person.cns.crs_numero.replace(
            /(\d{3})(\d{4})(\d{4})(\d{4})/gi,
            "$1 $2 $3 $4",
          )
        : "NÃO INFORMADO";
    },
    dateBR(pValue) {
      if (!pValue) return "NÃO INFORMADO";
      return moment(pValue).format("DD/MM/YYYY");
    },
    aih(pValue) {
      if (!pValue) return "000000000000-0";
      return pValue.replace(/(\d{12})(\d{1})/gi, "$1-$2");
    },
    fillZero(pNumber) {
      if (!pNumber) return "NÃO INFORMADO";
      return `0000000000${pNumber}`.slice(-10);
    },
  },
  props: {
    hospitalizationList: {
      default: () => [],
    },
  },
  methods: {
    selectHospitalization(hospitalizationAih) {
      const args = {};

      if (hospitalizationAih.hospitalizationBillingAih) {
        if (hospitalizationAih.hospitalizationBillingAih.iaf_faturado > 0) {
          args.inf_aih_numero_anterior =
            hospitalizationAih.hospitalizationBillingAih.iaf_id;
        } else {
          args.iaf_id = hospitalizationAih.hospitalizationBillingAih.iaf_id;
        }
      }

      if (!args.iaf_id) {
        args.int_id = hospitalizationAih.int_id;
      }

      this.$emit("selectHospitalization", args);
    },
    /**
     * @returns { string|false }
     */
    getAihNumber(hospitalizationAih) {
      if (
        !hospitalizationAih.hospitalizationBillingAih ||
        hospitalizationAih.hospitalizationBillingAih.iaf_faturado > 0
      )
        return false;

      return hospitalizationAih.hospitalizationBillingAih.iaf_numero_aih;
    },
    /**
     * @returns { string|false }
     */
    getPreviousAihNumber(hospitalizationAih) {
      if (
        !hospitalizationAih.hospitalizationBillingAih ||
        hospitalizationAih.hospitalizationBillingAih.iaf_faturado === 0
      )
        return false;

      return hospitalizationAih.hospitalizationBillingAih.iaf_numero_aih;
    },
    isIndigent(hospitalizationAih) {
      if (hospitalizationAih.patient && hospitalizationAih.patient.person) {
        return hospitalizationAih.patient.person.pes_nome;
      }

      return "PACIENTE NÃO IDENTIFICADO";
    },
    hasBirthDate(hospitalizationAih) {
      if (hospitalizationAih.patient && hospitalizationAih.patient.person) {
        return hospitalizationAih.patient.person.pes_nascimento;
      }

      return null;
    },
  },
};
</script>
