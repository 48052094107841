<style src="./SchedulingPatientInfo.scss" lang="scss" scoped></style>
<template lang="html">
  <div class="patient-info--container">
    <div class="patient-static-data--container row space-between">
      <div class="patient-search--input col-2">
        <RgComboboxTypeOfPatientSearch
          v-model="typeOfSearch"
          label="Tipo de Busca"
        />
      </div>
      <div class="patient-search--input col-10">
        <RgTypeaheadPatient
          v-model="patientValue"
          :rules="{ required: true }"
          :type-of-search="typeOfSearch"
          :min="minToSearch"
          :label="label"
          :placeholder="placeholder"
          :disabled="surgery"
          class="patient-typeahead"
          @select="selectPatient"
        >
          <template slot="item" slot-scope="props">
            <li class="rg-patient-search">
              <div class="patient-search-avatar">
                <img
                  src="/static/person-image/photo-default2.png"
                  class="patient-avatar-image"
                  alt="Foto do paciente"
                />
              </div>
              <span class="patient-search--content">
                <span class="name">{{ props.item.text }}</span>
                <div class="patient-search-details">
                  <div class="patient-search-details-container mother">
                    <span class="title">Mãe:</span>
                    <span class="value">{{ props.item.pes_mae }}</span>
                  </div>
                  <div class="patient-search-details-container">
                    <span class="title">Nascimento:</span>
                    <span class="value">{{ props.item.pes_nascimento }}</span>
                  </div>
                </div>
              </span>
            </li>
          </template>
        </RgTypeaheadPatient>
      </div>
    </div>

    <div
      :class="{ 'patient-info--invisible': !patientValue }"
      class="patient-info--content row"
    >
      <div class="col-4">
        <div class="cell-container">
          <div class="icon-container">
            <IconDocument class="icon" />
          </div>
          <span class="title">N° Cirurgia</span>
          <span :title="surgeryNumberInfo" class="value">{{
            surgeryNumberInfo
          }}</span>
        </div>

        <div class="cell-container pointer-cursor" @click="togglingBirthdate">
          <div class="icon-container">
            <IconCalendar class="icon" />
          </div>
          <span v-show="toggleBirthdate" class="title pointer-cursor"
            >Idade:</span
          >
          <span v-show="!toggleBirthdate" class="title pointer-cursor"
            >Nascimento:</span
          >
          <span
            v-show="!toggleBirthdate"
            :title="patient.person.pes_nascimento"
            class="value pointer-cursor"
            >{{ patient.person.pes_nascimento }}</span
          >
          <span
            v-show="toggleBirthdate"
            :title="patient.person.pes_nascimento"
            class="value pointer-cursor"
            >{{ patient.person.pes_nascimento | filterAge }}</span
          >
        </div>
        <div class="cell-container">
          <div class="icon-container">
            <IconMother class="icon" />
          </div>
          <span class="title">Mãe:</span>
          <span :title="patient.person.pes_mae" class="value">{{
            patient.person.pes_mae
          }}</span>
        </div>
      </div>

      <div class="col-4">
        <div class="cell-container">
          <div class="icon-container">
            <IconSector class="icon" />
          </div>
          <span class="title">Setor</span>
          <span :title="patient.bed" class="value">{{
            patient.bed_sector
          }}</span>
        </div>
        <div class="cell-container">
          <div class="icon-container">
            <IconHospitalInternment class="icon" />
          </div>
          <span class="title">Leito</span>
          <span :title="formatBedTitle" class="value">{{ patient.bed }}</span>
        </div>
        <div class="cell-container">
          <div class="icon-container">
            <IconDocument class="icon" />
          </div>
          <span class="title">Nº Internação</span>
          <span :title="patient.bed" class="value">{{
            patient.bed_number
          }}</span>
        </div>
      </div>
      <div class="col-4">
        <div class="cell-container">
          <div class="icon-container">
            <IconPlace class="icon" />
          </div>
          <span class="title" title="Local de Atendimento">Local:</span>
          <span :title="patient.bed_place" class="value">{{
            patient.bed_place
          }}</span>
        </div>
        <div
          class="cell-container pointer-cursor"
          @click="togglingRecordNumber"
        >
          <div class="icon-container">
            <IconRecords class="icon" />
          </div>
          <span v-show="toggleRecordNumber" class="title pointer-cursor"
            >Prontuário:</span
          >
          <span v-show="!toggleRecordNumber" class="title pointer-cursor"
            >Prontuário Único:</span
          >
          <span
            v-show="!toggleRecordNumber"
            :title="patient.pac_prontuario_unico"
            class="value pointer-cursor"
            >{{ patient.pac_prontuario_unico | fillRecordNumber }}</span
          >
          <span
            v-show="toggleRecordNumber"
            :title="patient.unitRecordNumber"
            class="value pointer-cursor"
            >{{ patient.unitRecordNumber | fillRecordNumber }}</span
          >
        </div>

        <div class="cell-container">
          <div class="icon-container">
            <IconCard class="icon" />
          </div>
          <span class="title">CNS</span>
          <span :title="patient.cns" class="value">{{ patient.cns }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  IconCalendar,
  IconMother,
  IconHospitalInternment,
  IconDocument,
  IconRecords,
  IconCard,
  IconSector,
  IconPlace,
} from "~tokio/primitive";
import PatientModel from "$surgery-center/patient/model/PatientModel";
import RgTypeaheadPatient from "$surgery-center/patient/component/rg-typeahead-patient/RgTypeaheadPatient";
import RgComboboxTypeOfPatientSearch from "./rg-combobox-type-of-search/RgComboboxTypeOfPatientSearch";
import moment from "moment";

export default {
  name: "SchedulingPatientInfo",
  components: {
    RgTypeaheadPatient,
    IconCalendar,
    IconMother,
    IconHospitalInternment,
    IconDocument,
    IconRecords,
    IconCard,
    IconSector,
    IconPlace,
    RgComboboxTypeOfPatientSearch,
  },
  filters: {
    filterAge(pAge) {
      if (!pAge) return "";
      const rawDateNow = new Date();
      const birthDate = moment(pAge, "DD/MM/YYYY");
      const dateNow = moment(rawDateNow);
      const ageYears = dateNow.diff(birthDate, "year");
      birthDate.add(ageYears, "years");
      const ageMonths = dateNow.diff(birthDate, "months");
      birthDate.add(ageMonths, "months");
      const ageDays = dateNow.diff(birthDate, "days");
      const returnResult = `${ageYears}a ${ageMonths}m ${ageDays}d`;

      return returnResult;
    },
    fillRecordNumber(pRecordNumber) {
      if (pRecordNumber) {
        return ("0000000000" + pRecordNumber).slice(-10);
      } else {
        return "Não informado";
      }
    },
  },
  props: {
    value: {},
    label: {
      type: String,
    },
    pacId: {
      default: "",
    },
    surgery: {
      default: false,
    },
    surgeryCode: {
      default: null,
    },
  },
  data() {
    return {
      typeOfSearch: 1,
      patientValue: "",
      patientModel: null,
      patient: {
        person: {
          pes_nacimento: "",
          pes_mae: "",
        },
        cns: "",
        unitRecordNumber: "",
        pac_prontuario_unico: "",
        bed: "",
      },
      toggleBirthdate: true,
      toggleRecordNumber: true,
    };
  },
  computed: {
    placeholder() {
      return this.typeOfSearch === 1
        ? "Digite o nome do paciente, nome da mãe ou data de nascimento"
        : "Digite o Filtro";
    },
    surgeryNumberInfo() {
      return this.$store.getters[
        "SurgeryCenter/Surgery/getSelectedSurgeryCode"
      ];
    },
    minToSearch() {
      return this.typeOfSearch === 1 ? "2" : "1";
    },
    formatBedTitle() {
      return `Leito - Local de Atendimento: ${this.patient.bed}`;
    },
  },
  watch: {
    typeOfSearch: function () {
      this.patientValue = 0;
      this.cleanInfo();
    },
    pacId(pVal, pOldVal) {
      if (pVal !== pOldVal) {
        this.patientValue = pVal;
        this.cleanInfo();
      }
    },
    patientValue(pVal) {
      if (pVal === null) {
        this.cleanInfo();
      }
    },
  },
  mounted() {
    this.patientModel = PatientModel;
    this.patientValue = this.value;
  },
  methods: {
    cleanInfo() {
      this.patient = {
        person: {
          pes_nacimento: "",
          pes_mae: "",
        },
        cns: "",
        pac_prontuario_unico: "",
        unitRecordNumber: "",
        bed: "",
        bed_sector: "",
        bed_number: "",
      };
    },
    togglingBirthdate() {
      this.toggleBirthdate = !this.toggleBirthdate;
    },
    togglingRecordNumber() {
      this.toggleRecordNumber = !this.toggleRecordNumber;
    },
    checkValidSelection(pPatient) {},
    selectPatient(pPatient) {
      if (pPatient) {
        this.patientRemainingInfo(pPatient).then(() => {
          this.$emit("input", this.patientValue);
          this.$emit("select", this.patientValue);
        });
      }
    },
    patientRemainingInfo(pPatient) {
      if (pPatient) {
        return this.patientModel
          .recoverRemainingInfo(pPatient.pac_id)
          .then((response) => {
            const patientTmp = pPatient;
            const emptyMessage = "Não informado";
            patientTmp.cns = response.data.crs_numero || emptyMessage;
            patientTmp.bed = response.data.inl_nome
              ? response.data.inl_nome + " - " + response.data.itl_nome
              : emptyMessage;
            patientTmp.bed_sector = response.data.inl_nome
              ? response.data.leito_set_nome
              : emptyMessage;
            patientTmp.bed_number = response.data.int_numero
              ? response.data.int_numero
              : emptyMessage;
            patientTmp.bed_place = response.data.leito_lca_nome
              ? response.data.leito_lca_nome
              : emptyMessage;
            const unitRecordNumber =
              response.data && response.data.unitRecordNumber
                ? response.data.unitRecordNumber
                : emptyMessage;
            patientTmp.unitRecordNumber = unitRecordNumber;

            this.patient = patientTmp;
          })
          .catch((pErr) => {
            console.error(pErr);
          });
      }
    },
  },
};
</script>
