export default async ({ commit, dispatch }, pInfo) => {
  const aihBilling = pInfo.hospitalizationBillingAih;

  if (aihBilling && aihBilling.iaf_id) {
    commit("SET_HOSPITALIZATION_BILLING_AIH_ID", aihBilling.iaf_id);
  }

  const patient = pInfo.hospitalizationPatientTab;

  if (patient && patient.icf_id) {
    commit("SET_PATIENT_INFO_TAB_ID", patient.icf_id);
  }

  const hospitalizationBilling = pInfo.hospitalizationBillingTab;

  if (hospitalizationBilling && hospitalizationBilling.inf_id) {
    commit("SET_BILLING_INFO_TAB_ID", hospitalizationBilling.inf_id);
  }

  const hospitalizationProcedures = pInfo.hospitalizationProceduresTab;

  if (hospitalizationProcedures) {
    if (hospitalizationProcedures.length && aihBilling.iaf_id) {
      const savedProcedures = await dispatch(
        "GET_HOSPITALIZATION_AIH_PROCEDURES",
        { iaf_id: aihBilling.iaf_id },
      );
      const omitTypename = (key, value) =>
        key === "__typename" ? undefined : value;
      const newPayload = JSON.parse(
        JSON.stringify(savedProcedures),
        omitTypename,
      );
      commit("SET_HOSPITALIZATION_AIH_PROCEDURES", newPayload);
    }
  }

  const hospitalizationAihSecondaryCid = pInfo.hospitalizationCidTab;

  if (hospitalizationAihSecondaryCid) {
    if (hospitalizationAihSecondaryCid.length && aihBilling.iaf_id) {
      const savedAihSecondaryCid = await dispatch(
        "GET_HOSPITALIZATION_BILLING_DIAGNOSTICS_INFO",
        { iaf_id: aihBilling.iaf_id },
      );
      const omitTypename = (key, value) =>
        key === "__typename" ? undefined : value;
      const newPayload = JSON.parse(
        JSON.stringify(savedAihSecondaryCid),
        omitTypename,
      );
      commit("SET_HOSPITALIZATION_BILLING_DIAGNOSTICS_INFO", newPayload);
    }
  }
};
