<template lang="html">
  <fieldset class="rg-radio-document">
    <span class="label"> Tipo de Documento </span>
    <div class="container unselect">
      <div
        :class="{ '-active': selectedStatus.includes(CPF) }"
        class="radio -not-active"
        @click="toggleStatus(CPF)"
      >
        CPF
      </div>
      <div
        :class="{ '-active': selectedStatus.includes(CNS) }"
        class="radio -not-active"
        @click="toggleStatus(CNS)"
      >
        CNS
      </div>
      <div
        :class="{ '-active': selectedStatus.includes(PRONTUARIO_UNICO) }"
        class="radio -not-active"
        @click="toggleStatus(PRONTUARIO_UNICO)"
      >
        Prontuário Único
      </div>
      <div
        :class="{ '-active': selectedStatus.includes(PRONTUARIO_UNIDADE) }"
        class="radio -not-active"
        @click="toggleStatus(PRONTUARIO_UNIDADE)"
      >
        Prontuário da Unidade
      </div>
    </div>
  </fieldset>
</template>

<script>
export default {
  name: "RgRadioBedType",
  props: {
    value: {
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedStatus: [],
    };
  },
  watch: {
    value(pValue) {
      this.selectedStatus = pValue;
    },
  },
  beforeCreate() {
    this.CPF = 1;
    this.CNS = 2;
    this.PRONTUARIO_UNICO = 3;
    this.PRONTUARIO_UNIDADE = 4;
  },
  mounted() {
    this.selectedStatus = this.value;
  },
  methods: {
    toggleStatus(pItem) {
      if (this.selectedStatus && this.selectedStatus.includes(pItem)) {
        this.selectedStatus.splice(this.selectedStatus.indexOf(pItem), 1);
      } else {
        this.selectedStatus = [pItem];
      }
      this.$emit("input", this.selectedStatus);
    },
  },
};
</script>
