<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchOccupationScheduleExternally from "./action/SearchOccupationScheduleExternally";
export default {
  name: "RgComboboxOccupationScheduleExternally",
  mixins: [RgComboboxMixin],
  props: {},
  data: () => {
    return {};
  },
  watch: {},
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];

      this.disableLoading = true;
      const data = await SearchOccupationScheduleExternally();
      data.forEach((item) => {
        this.mData.push({ value: item.data, text: item.soc_nome });
      });
      this.disableLoading = false;
    },
  },
};
</script>
