import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (context, arrFormData) => {
  return AuthLegacyRequest.post("/emergencias/controller-boletim/alterar", {
    arrFormData,
  })
    .then(({ data }) => {
      return HandleResponse(data);
    })
    .catch((pErr) => {
      throw pErr;
    });
};
