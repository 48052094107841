<style src="./RgSuggestCbo.scss" lang="scss" scoped></style>
<template>
  <RgSuggest
    v-model="inputValue"
    :label="label"
    :placeholder="placeholder"
    min="2"
  >
    <RgSuggestItem
      v-for="(item, idx) in suggestionList"
      :key="idx"
      :idx="idx"
      :item="item"
      class="cbo-item"
    >
      <div class="cbo-row-info">
        {{ item.CO_OCUPACAO }}
      </div>
      <div class="cbo-row-top">
        <span class="cbo-name">{{ item.NO_OCUPACAO }}</span>
      </div>
    </RgSuggestItem>
  </RgSuggest>
</template>
<script>
import { isEmpty } from "lodash";
import {
  RgSuggest,
  RgSuggestItem,
  RgSuggestMixin,
} from "~tokio/primitive/selection";
import { SearchCbo } from "./action";

export default {
  name: "RgSuggestCbo",
  components: {
    RgSuggest,
    RgSuggestItem,
  },
  mixins: [RgSuggestMixin],
  data() {
    return {
      captionRaw: "CO_OCUPACAO",
    };
  },
  methods: {
    doSearch(pSearchTerm) {
      const variables = this.defineQuery(pSearchTerm);
      return SearchCbo({ variables });
    },
    forceSelection(pValue) {
      if (pValue && !isEmpty(pValue.pes_nome) && pValue.vin_id > 0) {
        this.$refs.innerSuggest.itemSelected = true;
        if (
          !this.suggestionList ||
          (this.suggestionList && this.suggestionList.length === 0)
        ) {
          this.suggestionList.push({
            pes_nome: pValue.pes_nome,
            vin_id: pValue.vin_id,
          });
        }
        this.$refs.innerSuggest.$parent.selectingItemFromSuggestList(pValue);
        this.$refs.innerSuggest.validate();
      }
    },
  },
};
</script>
