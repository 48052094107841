import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  const sendData = {
    arrFormData: variables,
  };
  return AuthLegacyRequest.post(
    "/internacoes/controller-fila-internacao/alterar",
    sendData,
  )
    .then(({ data }) => {})
    .catch((pErr) => {
      throw pErr;
    });
};
