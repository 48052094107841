<template lang="html">
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.9658 14.9575V6.99147C8.9658 5.8906 9.8564 5 10.9575 5C12.0582 5 12.9488 5.8906 12.9488 6.99147V14.9575C12.9488 16.0581 12.0582 16.9487 10.9575 16.9487C9.8564 16.9488 8.9658 16.0581 8.9658 14.9575ZM42.8211 16.9488C43.9215 16.9488 44.8126 16.0582 44.8126 14.9576V6.99147C44.8126 5.8906 43.9215 5 42.8211 5C41.7203 5 40.829 5.8906 40.829 6.99147V14.9575C40.829 16.0581 41.7203 16.9488 42.8211 16.9488ZM16.9319 28.8976H8.9658V36.8633H16.9319V28.8976ZM8.9658 48.8122H16.9319V40.846H8.9658V48.8122ZM20.9146 36.8633H28.8808V28.8976H20.9146V36.8633ZM20.9146 48.8122H28.8808V40.846H20.9146V48.8122ZM4.9831 52.3986V24.9145H48.7952V36.4877H52.7783V17.3457C52.7783 14.9264 50.8487 12.9658 48.4647 12.9658H46.8037V14.9576C46.8037 17.1511 45.018 18.9404 42.8213 18.9404C40.6238 18.9404 38.8383 17.1511 38.8383 14.9576V12.9658H14.9401V14.9576C14.9401 17.1511 13.1548 18.9404 10.9577 18.9404C8.75999 18.9404 6.97458 17.1511 6.97458 14.9576V12.9658H5.31551C2.93146 12.9658 1 14.9264 1 17.3457V52.3987C1 54.8101 2.93146 56.7783 5.31551 56.7783H28.8808V52.7947H5.31551C5.13505 52.7946 4.9831 52.6082 4.9831 52.3986ZM40.829 36.8633V28.8976H32.8636V36.8633H40.829Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M34.9345 40.6509C33.8915 40.6509 33.0435 41.5633 33.0435 42.6855V56.4991C33.0435 57.6213 33.8915 58.5337 34.9345 58.5337H48.5162L48.5193 58.5337L48.5232 58.5337L48.5273 58.5337H62.109C63.1519 58.5337 64 57.6213 64 56.4991V42.6855C64 41.5633 63.1519 40.6509 62.109 40.6509H34.9345ZM34.8257 42.6855C34.8257 42.6215 34.875 42.5684 34.9345 42.5684H47.6302V46.03H34.8257V42.6855ZM49.4124 46.03V42.5684H62.109C62.1684 42.5684 62.2178 42.6215 62.2178 42.6855V46.03H49.4124ZM34.8257 51.237V47.9475H47.6302V51.237H34.8257ZM49.4124 51.237V47.9475H62.2178V51.237H49.4124ZM34.8257 56.4991V53.1545H47.6302V56.6162H34.9345C34.875 56.6162 34.8257 56.5631 34.8257 56.4991ZM49.4124 56.6162V53.1545H62.2178V56.4991C62.2178 56.5631 62.1684 56.6162 62.109 56.6162H49.4124Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "ScheduleQueue",
};
</script>
