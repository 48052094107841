import SelectedEmployeeData from "./SelectedEmployeeData";
import SelectPatientData from "./SelectPatientData";
import FilterPerDay from "./FilterPerDay";
import FilterPerPeriod from "./FilterPerPeriod";
import FilterDate from "./FilterDate";
import FilterQueue from "./FilterQueue";
import FilterPatientExclude from "./FilterPatientExclude";
import FilterSchedulePatientList from "./FilterSchedulePatientList";
import FilterExternallySchedule from "./FilterExternallySchedule";
import ExamSchedule from "./ExamSchedule";
import SelectedPatientListQueue from "./SelectedPatientListQueue";
import SelectedQueueExternalExamData from "./SelectedQueueExternalExamData";
import FormSchedule from "./FormSchedule";

export default {
  ...SelectedEmployeeData,
  ...SelectPatientData,
  ...FilterPerDay,
  ...FilterPerPeriod,
  ...FilterDate,
  ...FilterPatientExclude,
  ...FilterQueue,
  ...ExamSchedule,
  ...SelectedPatientListQueue,
  ...SelectedQueueExternalExamData,
  ...FormSchedule,
  ...FilterSchedulePatientList,
  ...FilterExternallySchedule,
};
