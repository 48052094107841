<template lang="html">
  <svg
    width="10.287mm"
    height="10.287mm"
    version="1.1"
    viewBox="0 0 10.287 10.287"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(-34.896 -159.06)">
      <path
        d="m42.135 159.06c-0.80433 0-1.5663 0.31749-2.1378 0.88899l-4.2121 4.2121c-0.5715 0.5715-0.88899 1.3335-0.88899 2.1378 0 1.6933 1.3547 3.048 3.048 3.048 0.80433 0 1.5663-0.3175 2.1378-0.88899l4.2121-4.2121c0.5715-0.5715 0.88899-1.3335 0.88899-2.1378 0-1.6933-1.3547-3.048-3.048-3.048zm-3.9581 4.6778-1.397 1.4182c-0.3175 0.29633-0.48683 0.71966-0.48683 1.143 0 0.14817-0.10583 0.23283-0.23283 0.23283s-0.23283-0.0847-0.23283-0.23283c0-0.55033 0.23283-1.0795 0.61383-1.4816l1.4182-1.397c0.08467-0.0847 0.23283-0.0847 0.3175 0 0.10583 0.0847 0.10583 0.23283 0 0.31749zm5.461-0.14816-1.9473 1.9473-2.9633-2.9845 1.9262-1.9473c0.40216-0.381 0.93133-0.61383 1.4817-0.61383 1.1642 0 2.1167 0.95249 2.1167 2.1166 0 0.55033-0.21166 1.0795-0.61383 1.4817z"
        fill="#808080"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconPill",
};
</script>
