/**
 * Executa determinada função em um tempo aleatório entre o mínimo e máximo informado
 * @param {Function} pFunction
 * @param {Function} pNextTick
 * @param {Number} pMin
 * @param {Number} pMax
 */
const ExecuteActionAvoidingConcurrency = async (
  pFunction,
  pNextTick,
  pMin = 1000,
  pMax = 3000,
) => {
  const timeout =
    Math.floor(Math.random() * (Math.floor(pMax) - Math.ceil(pMin) + 1)) +
    Math.ceil(pMin);

  try {
    setTimeout(async () => {
      if (!pNextTick) return await pFunction();
      else {
        pNextTick(async () => {
          return await pFunction();
        });
      }
    }, timeout);
  } catch (error) {
    throw new Error("Falha ao Executar!");
  }
};

export default ExecuteActionAvoidingConcurrency;
