import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation($recordNumber: RecordNumberInputType) {
    SaveRecordNumber(recordNumber: $recordNumber) {
      ppr_numero
    }
  }
`;

export default async (
  { commit },
  { ppr_id_unidades_saude, ppr_id_pacientes, ppr_numero },
) => {
  const variables = {
    recordNumber: {
      ppr_id_unidades_saude,
      ppr_id_pacientes,
      ppr_numero,
    },
  };
  return GraphQLClient.mutate({ mutation, variables });
};
