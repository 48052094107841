import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation($noh_nome: String, $noh_cpf: String, $noh_celular: String) {
    CreateNotificationHomologation(
      noh_nome: $noh_nome
      noh_cpf: $noh_cpf
      noh_celular: $noh_celular
    ) {
      noh_cpf
    }
  }
`;

export default async ({ rootState }, variables) => {
  try {
    const { data } = await GraphQLClient.mutate({
      mutation,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.CreateNotificationHomologation;
  } catch (err) {
    return Promise.reject(err.graphQLErrors);
  }
};
