<script>
import { RgRemoteComboboxMixin } from "~tokio/primitive";
import moment from "moment";
import SurgeryStatusModel from "$surgery-center/surgery/model/SurgeryStatusModel";
export default {
  name: "RgComboboxPatientSurgeries",
  mixins: [RgRemoteComboboxMixin],
  props: {
    label: {
      default: "Cirurgias Anteriores",
    },
    patient: {
      default: null,
    },
    surgery: {
      default: null,
    },
  },
  data() {
    return {};
  },
  watch: {
    patient: function (pNewvalue) {
      this.getDataFromRemote();
    },
    surgery: function (pNewvalue) {
      this.getDataFromRemote();
    },
  },
  methods: {
    async doSearch() {
      if (this.patient) {
        let data = await this.$store.dispatch(
          "SurgeryCenter/Surgery/SEARCH_PATIENT_SURGERIES",
          {
            patientId: this.patient,
            status: 4,
          },
        );
        data = data.filter((item) => {
          return item.cci_id !== this.surgery;
        });
        return data;
      }
      return [];
    },
    formatItem(item) {
      const status = SurgeryStatusModel.getSurgeryStatusDescription(
        item.cci_id_status_controles_cirurgicos,
      );
      return {
        value: item.cci_id,
        text: `${item.cci_numero}
          - ${moment(item.cci_data_inicio).format("DD/MM/YYYY")}
          - ${item.requesting_clinic.inc_nome}
          - ${item.surgery_performed.contamination.cot_nome}
          - ${status}`,
      };
    },
  },
};
</script>
