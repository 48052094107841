<template lang="html">
  <fieldset class="radio-bulletin-with-exit">
    <span class="label">Listar Boletins</span>
    <div class="container unselect">
      <div
        :class="{ active: selectedStatus.includes(OUT_INFO.SEM_SAIDA) }"
        class="radio"
        @click="toggleStatus(OUT_INFO.SEM_SAIDA)"
      >
        Sem Saída
      </div>
      <div
        :class="{ active: selectedStatus.includes(OUT_INFO.COM_SAIDA) }"
        class="radio"
        @click="toggleStatus(OUT_INFO.COM_SAIDA)"
      >
        Com Saída
      </div>
    </div>
  </fieldset>
</template>

<script>
import OUT_INFO from "./OutInfo";

export default {
  name: "RadioBulletinWithExit",
  props: {
    value: {
      default: null,
    },
  },
  data() {
    return {
      selectedStatus: [],
    };
  },
  computed: {
    OUT_INFO() {
      return { ...OUT_INFO };
    },
  },
  watch: {
    value(pValue) {
      this.selectedStatus = pValue;
    },
  },
  mounted() {
    this.selectedStatus = this.value || [];
  },
  methods: {
    toggleStatus(pItem) {
      if (this.selectedStatus.includes(pItem)) {
        this.selectedStatus.splice(this.selectedStatus.indexOf(pItem), 1);
      } else {
        this.selectedStatus.push(pItem);
      }

      this.$emit("input", this.selectedStatus);
    },
  },
};
</script>
