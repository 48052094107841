import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (context, { intIdBoletim }) => {
  return AuthLegacyRequest.post(
    "emergencias/controller-destino/carregar-resumo-por-boletim",
    {
      intIdBoletim,
    },
  )
    .then(({ data }) => {
      return HandleResponse(data);
    })
    .catch((pErr) => {
      throw pErr;
    });
};
