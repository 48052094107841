import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  return AuthLegacyRequest.post(
    "unidadesaude/controller-unidade-saude/buscar",
    variables,
  )
    .then(({ data }) => {
      data.dados.data = HandleResponse(data);
      data.dados.data = data.dados.recordSet;

      data.dados.data.map((item) => {
        item.cnpj = item.cnpj.replace(
          /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
          "$1.$2.$3/$4-$5",
        );
      });

      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
