<template lang="html">
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.9959 10.1927C19.8344 10.6574 19.5067 10.8286 19.0225 10.8237C17.1735 10.8091 15.3295 10.8188 13.4805 10.8188C13.412 10.8188 13.3387 10.8188 13.2653 10.8188C13.2653 10.8971 13.324 10.9167 13.3631 10.9558C14.0137 11.6112 14.6691 12.2618 15.3148 12.9221C15.6914 13.3037 15.667 13.8319 15.2756 14.1303C14.9919 14.3455 14.6202 14.3504 14.3365 14.1303C14.268 14.0814 14.2093 14.0178 14.1506 13.9591C13.4169 13.2205 12.6832 12.4868 11.9495 11.7482C10.9076 10.7014 10.9125 9.29761 11.9593 8.25574C12.7077 7.51225 13.456 6.76876 14.1995 6.02527C14.4343 5.79048 14.6985 5.68287 15.0311 5.7807C15.5447 5.93722 15.755 6.53886 15.4371 6.9742C15.3784 7.05246 15.3099 7.12094 15.2414 7.18942C14.5664 7.86443 13.8865 8.53945 13.2066 9.21446C13.2017 9.21935 13.2066 9.22424 13.1968 9.25359C13.2653 9.25359 13.3338 9.25359 13.4022 9.25359C15.2756 9.25359 17.1491 9.26337 19.0225 9.2487C19.5067 9.24381 19.8344 9.41501 19.9959 9.87969C19.9959 9.9873 19.9959 10.09 19.9959 10.1927Z"
      fill="white"
    />
    <path
      d="M19.9959 6.25027C19.898 6.50951 19.7611 6.72474 19.4872 6.82746C18.9785 7.01333 18.4453 6.65137 18.4355 6.09375C18.4257 5.52145 18.4306 4.94916 18.4306 4.37686C18.4306 3.72631 18.4306 3.07575 18.4306 2.42519C18.4306 1.88225 18.1127 1.56431 17.5697 1.56431C16.3322 1.56431 15.0996 1.56431 13.8621 1.56431C13.5441 1.56431 13.2947 1.43224 13.143 1.14854C13.0012 0.884403 13.0158 0.620267 13.1675 0.370806C13.3093 0.14091 13.5294 0.00884201 13.7985 0.00884201C15.1338 0.00395061 16.4643 -0.0107236 17.7996 0.0137334C18.866 0.0381904 19.7953 0.903969 19.9567 1.95562C19.9616 1.98497 19.9519 2.0241 19.9959 2.03388C19.9959 3.43771 19.9959 4.84644 19.9959 6.25027Z"
      fill="white"
    />
    <path
      d="M1.99549 19.9951C1.95147 19.9217 1.86832 19.9462 1.80473 19.9315C0.748187 19.6625 0.0193677 18.7674 0.00958487 17.6864C-0.000197931 16.4488 0.00469347 15.2162 0.00958487 13.9787C0.00958487 13.514 0.3422 13.1716 0.782426 13.1667C1.22754 13.1618 1.56994 13.5091 1.56994 13.9836C1.57483 15.1673 1.56994 16.351 1.56994 17.5347C1.56994 18.1315 1.87321 18.4348 2.46996 18.4348C3.69281 18.4348 4.91566 18.4299 6.13851 18.4348C6.72059 18.4348 7.10212 18.963 6.91135 19.4864C6.80863 19.7603 6.59341 19.9022 6.33417 19.9951C4.88631 19.9951 3.44335 19.9951 1.99549 19.9951Z"
      fill="white"
    />
    <path
      d="M13.6713 19.9951C13.4218 19.9022 13.2066 19.7652 13.1039 19.506C12.9033 18.9973 13.2653 18.4494 13.8278 18.4396C14.3561 18.4299 14.8795 18.4348 15.4077 18.4348C16.1317 18.4348 16.8507 18.4348 17.5746 18.4348C18.1176 18.4348 18.4355 18.1168 18.4355 17.5739C18.4355 16.3755 18.4306 15.182 18.4355 13.9836C18.4355 13.4015 18.9638 13.02 19.4872 13.2156C19.7611 13.3183 19.9029 13.5336 19.9959 13.7928C19.9959 15.1966 19.9959 16.6054 19.9959 18.0092C19.8834 18.875 19.3942 19.4571 18.6361 19.8386C18.411 19.9511 18.1665 19.9658 17.9268 20C16.5083 19.9951 15.0898 19.9951 13.6713 19.9951Z"
      fill="white"
    />
    <path
      d="M6.73529 10.8188C6.66681 10.8188 6.59344 10.8188 6.52496 10.8188C4.63688 10.8188 2.75369 10.8188 0.865609 10.8188C0.518319 10.8188 0.244401 10.6917 0.0829846 10.3737C-0.156694 9.88947 0.17103 9.31718 0.713975 9.26337C0.792238 9.25359 0.8705 9.25848 0.948763 9.25848C2.83684 9.25848 4.72003 9.25848 6.60811 9.25848C6.6717 9.25848 6.73529 9.25848 6.85268 9.25848C6.76953 9.18022 6.71572 9.13131 6.66681 9.08728C6.01136 8.43673 5.35592 7.78617 4.70047 7.13561C4.35807 6.79322 4.32383 6.36767 4.59775 6.03505C4.86188 5.71711 5.31678 5.65841 5.6494 5.89809C5.71788 5.947 5.78147 6.0057 5.84016 6.0644C6.58366 6.803 7.32715 7.5416 8.07064 8.2802C9.08805 9.29761 9.09295 10.7112 8.08043 11.7335C7.32715 12.4917 6.57387 13.245 5.8206 14.0031C5.58581 14.2428 5.31189 14.3602 4.97439 14.2624C4.47057 14.1108 4.25535 13.514 4.55862 13.0836C4.61242 13.0053 4.6809 12.9319 4.75427 12.8634C5.42439 12.1982 6.09452 11.5379 6.76464 10.8726C6.75486 10.8482 6.74507 10.8335 6.73529 10.8188Z"
      fill="white"
    />
    <path
      d="M4.19175 0.00395161C4.85698 0.00395161 5.51732 -0.000939796 6.18255 0.00395161C6.5494 0.00884301 6.82821 0.238739 6.92604 0.581137C7.01409 0.903969 6.88202 1.25126 6.60321 1.43224C6.44668 1.53496 6.27059 1.56431 6.07983 1.56431C4.87654 1.56431 3.67326 1.56431 2.46997 1.56431C1.87322 1.56431 1.56996 1.86758 1.56996 2.46433C1.56996 3.66272 1.57485 4.85622 1.56996 6.05462C1.56996 6.62691 1.08082 6.99866 0.557436 6.83724C0.244386 6.73941 0.0144899 6.46549 0.0144899 6.12799C0.00470713 4.8122 -0.0148585 3.49641 0.0193813 2.18552C0.0536212 1.01647 1.07103 0.0430828 2.24008 0.00884301C2.89063 -0.0058312 3.54119 0.00884301 4.19175 0.00395161Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: "Unifiy",
};
</script>
