import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query($procedureCode: String) {
    procedures(CO_PROCEDIMENTO: $procedureCode) {
      rows {
        details {
          rows {
            NO_DETALHE
            CO_DETALHE
          }
        }
      }
    }
  }
`;

export default async ({ commit }, { procedureCode }) => {
  const variables = {
    procedureCode,
  };

  try {
    const { data } = await GraphQLClient.query({ query, variables });
    return data.procedures.rows[0]
      ? data.procedures.rows[0].details.rows
      : false;
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors);
  }
};
