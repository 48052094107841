import LocalStorage from "~common/local-storage/LocalStorage";
import moment from "moment";

const SchedulePatientFollowUpSheetHTML = (pData) => {
  const logo = LocalStorage.getObject("client-logo");
  const emissao = moment().format("DD/MM/YYYY");
  const html = `
  <!DOCTYPE html>
  <html>

  <head>
      <meta charset="utf-8">
      <title>Esus</title>
      <style type="text/css">

      h1
      {
        font-size: 18px;
      }

      .cabecalho {
        width: 100%;
        float: left;
        overflow: hidden;
        table-layout: fixed;
        display: block;
        word-wrap: break-word;
      }

      .brasao
      {
        max-width:  150px;
        max-height: 100px;
        margin-right: 1%;
        float: left;
        width:  150px;
        height: 100px;
      }

      .tabela
      {
        width: 100%;
        border-collapse: collapse
      }

      .tabela-extra
      {
        width: 100%;
        border-collapse: collapse
      }

      .tabela tr
      {
        border-bottom: 1px solid black;
      }

      .tabela-extra tr
      {
        border: 1px solid black;
        border-left: 1px solid black;
        height: 25px;
      }

      .tabela-extra td
      {
        border-right: 1px solid black;
        border-left: 1px solid black;
      }

      .titulo
      {
        width: 100%;
        text-align: center;
      }

      .emissao {
        float: right;
      }
    </style>
  </head>

  <body>

    <div class="cabecalho">
      <img src="${logo}" class="brasao">

      <div class="informacoes">
        <span>${pData.dados.cli_nome || ""}</span>
        <span class="emissao">Emissão em: ${emissao}</span>
        <p>${pData.dados.uns_nome}</p>
      </div>
    </div>

    <br>
    <br>
    <br>
    <br>
    <br>

    <h1 class="titulo"> FICHA DE ACOMPANHAMENTO DO PACIENTE </h1>

    <div class="informacoes">
      <table class="tabela" width="100%" cellpadding="4" cellspacing="0">
        <tr>
          <td><b>NOME</b></td>
          <td colspan="7">${pData.dados.pes_nome}</td>
        </tr>

        <tr>
          <td><b>NASCIMENTO</b></td>
          <td>${pData.dados.pes_nascimento}</td>
          <td colspan="4">${pData.dados.pes_idade.years} <b>ANO(S)</b> ${
    pData.dados.pes_idade.months
  } <b>MES(ES)</b> ${pData.dados.pes_idade.days} <b>DIA(S)</b></td>
          <td><b>SEXO</b></td>
          <td>${pData.dados.sex_sigla}</td>
        </tr>

        <tr>
          <td><b>NATURALIDADE</b></td>
          <td colspan="3">${pData.dados.nac_nome}</td>
          <td><b>ESTADO CIVIL</b></td>
          <td colspan="4"> ${pData.dados.ecv_nome}</td>
        </tr>

        <tr>
          <td><b>PRONTUÁRIO</b></td>
          <td colspan="1"> ${pData.dados.ppr_numero}</td>
          <td colspan="2"><b>PRONTUÁRIO ÚNICO</b></td>
          <td colspan="1">${pData.dados.pac_prontuario_unico}</td>
          <td><b>CNS</b></td>
          <td colspan="2">${pData.dados.crs_numero}</td>
        </tr>

        <tr>
          <td><b>ENDEREÇO</b></td>
          <td colspan="7">${pData.dados.end_logradouro}</td>
        </tr>

        <tr>
          <td colspan="1"><b>NÚMERO</b></td>
          <td colspan="1">${pData.dados.end_numero}</td>
          <td colspan="1"><b>COMPLEMENTO</b></td>
          <td colspan="2">${pData.dados.end_complemento}</td>
          <td colspan="1"><b>CEP</b></td>
          <td colspan="2">${pData.dados.end_cep}</td>
        </tr>

        <tr>
          <td><b>BAIRRO</b></td>
          <td colspan="3">${pData.dados.bai_nome}</td>
          <td><b>SUB BAIRRO</b></td>
          <td colspan="3">${pData.dados.sub_nome}</td>
        </tr>

        <tr>
          <td><b>MUNICÍPIO</b></td>
          <td colspan="3">${pData.dados.mun_nome}</td>
          <td><b>REFERÊNCIA</b></td>
          <td colspan="3">${pData.dados.end_referencia}</td>
        </tr>

        <tr>
          <td><b>NOME DA MÃE</b></td>
          <td colspan="7">${pData.dados.pes_mae}</td>
        </tr>

        <tr>
          <td><b>NOME DO PAI</b></td>
          <td colspan="7">${pData.dados.pes_pai}</td>
        </tr>
      </table>
    </div>

    <br>
    <br>

    <div>
      <table class="tabela-extra">
        <tr>
          <th width="20%"> Data </th>
          <th> Descrição </th>
        </tr>
        <tr>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
        </tr>
      </table>
    </div>
  </body>

  </html>
    `;
  return html;
};

export default SchedulePatientFollowUpSheetHTML;
