import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

import { OpenMenuModule } from "~common/utils/app/menu";

import HospitalizationQueuePendecies from "$hospitalization/hospitalizations/queue/views/queue-pendencies/HospitalizationQueuePendencies";

export default {
  path: "/hospitalization/queue/pendencies",
  name: "hospitalization.queue.pendencies",
  components: {
    default: HospitalizationQueuePendecies,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Internação", link: OpenMenuModule("hospitalization") },
      { label: "Regulação", link: "/hospitalization/queue" },
      { label: "Pendência" },
    ],
  },
};
