<style src="./OperationList.scss" lang="scss" scoped></style>
<template>
  <section class="module-list">
    <div
      v-for="(operation, idx) in filteredOperations"
      :key="idx"
      :class="{
        'menu-button--selected': idx === selectedOperationId,
        'inactive-button': operation.active === false,
      }"
      :title="
        operation.active === false
          ? 'Não implementado ou desativado temporariamente'
          : operation.name
      "
      :data-operationname="operation.className"
      class="menu-button menu-button--medium-size"
      @click="selectingItem(idx)"
    >
      <component :is="operation.iconName" class="icon" />
      <span class="module-name">
        {{ operation.name }}
      </span>
    </div>
  </section>
</template>

<script>
import { GetOperationsFromJson } from "../menu-map/GetOperationsFromJson";
import * as Icons from "~tokio/primitive/icon/modules";
import { IconEllipsis } from "~tokio/primitive";

export default {
  name: "OperationList",
  components: {
    ...Icons,
    IconEllipsis,
  },

  data() {
    return {
      operations: [],
    };
  },
  computed: {
    selected() {
      return this.$store.state.MainMenu.selectedOperation;
    },
    selectedModule() {
      return this.$store.state.MainMenu.selectedModule;
    },
    selectedSubModule() {
      return this.$store.state.MainMenu.selectedSubModule;
    },
    selectedOperationId() {
      return this.selected ? this.selected.id : null;
    },
    filteredOperations() {
      return this.operations.filter((item) => {
        return item.active !== false && this.hasPermission(item.permission);
      });
    },
    isFavoriteMode() {
      return this.$store.state.Home.favorite_mode;
    },
  },
  watch: {
    selectedSubModule() {
      this.operations = [];
      if (this.selectedModule && this.selectedSubModule)
        this.loadOperations({
          moduleId: this.selectedModule.id,
          subModuleId: this.selectedSubModule.id,
        });
    },

    selectedModule(pModule) {
      this.operations = [];
    },
  },

  methods: {
    loadOperations({ moduleId, subModuleId }) {
      const operations = GetOperationsFromJson({ moduleId, subModuleId });
      this.operations = operations;
    },
    selectingItem(pOperationId) {
      if (!this.isFavoriteMode) {
        this.$emit("change", pOperationId);

        if (this.operations[pOperationId].link) {
          this.$emit("open-link", this.operations[pOperationId].link);
        }

        this.$store.commit(
          "Home/PUSH_RECENT_OPERATION",
          this.operations[pOperationId],
        );
      } else {
        this.$store.commit(
          "Home/SET_NEW_FAVORITE",
          this.operations[pOperationId],
        );
        this.$parent.closeMenu();
      }
    },
    hasPermission(pPermission) {
      if (Array.isArray(pPermission)) {
        let permited = false;
        pPermission.forEach((pItem) => {
          if (this.$Permissions.global.has(pItem)) {
            permited = true;
          }
        });
        return permited;
      }
      return this.$Permissions.global.has(pPermission);
    },
  },
};
</script>
