import IconDrugstore from "./Drugstore";
import IconExam from "./Exam";
import IconMedicalAppointment from "./MedicalAppointment";
import IconHospitalInternment from "./HospitalInternment";
import IconHospitalEmergency from "./HospitalEmergency";
import IconHospitalization from "./Hospitalization";
import IconHospitalizationTwo from "./HospitalizationTwo";
import IconHospitalizationOne from "./HospitalizationOne";
import IconPersonModule from "./Person";
import IconPerday from "./Perday";
import IconFile from "./File";
import IconHome from "./Home";
import IconForperiod from "./Forperiod";
import IconForRegulation from "./ForRegulation";
import IconRegisterModule from "./Register";
import IconRegisterPerson from "./RegisterPerson";
import IconSearchPerson from "./SearchPerson";
import IconPriority from "./Priority";
import IconScaleTypes from "./ScaleTypes";
import IconObjective from "./Objective";
import IconScaleRegister from "./ScaleRegister";
import IconHoliday from "./Holiday";
import IconSettings from "./Settings";
import IconSchedule from "./Schedule";
import IconExternalPlace from "./ExternalPlace";
import IconAgreement from "./Agreement";
import IconAddress from "./Address";
import IconBillingModule from "./Billing";
import IconSurgeryCenterModule from "./SurgeryCenter";
import IconAppointment from "./Appointment";
import IconReport from "./Report";
import IconDisabledPeriods from "./DisabledPeriods";
import IconSurgery from "./Surgery";
import IconExcludeQueue from "./ExcludeQueue";
import IconBulletin from "./Bulletin";
import IconExport from "./Export";
import IconOrder from "./Order";
import IconEffectiveness from "./Effectiveness";
import IconSurgeryMap from "./SurgeryMap";
import IconRegulationPool from "./RegulationPool";
import IconImport from "./Import";
import IconEmployee from "./Employee";
import IconPharmacyModule from "./Pharmacy";
import IconMovements from "./Movements";
import IconEntry from "./Entry";
import IconRequest from "./Request";
import IconChargeback from "./Chargeback";
import IconDiscard from "./Discard";
import IconPharmacyUnit from "./PharmacyUnit";
import IconPresentationType from "./PresentationType";
import IconProductClass from "./ProductClass";
import IconProvider from "./Provider";
import IconProduct from "./Product";
import IconInsertOnQueue from "./InsertOnQueue";
import IconTransfer from "./Transfer";
import IconScheduleEffectiveness from "./ScheduleEffectiveness";
import IconEmergency from "./Emergency";
import IconEmergencies from "./Emergencies";
import IconEmergencyBulletin from "./EmergencyBulletin";
import IconEmergencyPreRegistration from "./PreRegistration";
import IconListServiceScale from "./ListServiceScale";
import IconScheduleQueue from "./ScheduleQueue";
import IconLabExam from "./LabExam";
import IconRegulation from "./Regulation";
import IconUnitHealthModule from "./UnitHealth";
import IconSearchUnitHealth from "./SearchUnitHealth";
import IconRegisterUnitHealth from "./RegisterUnitHealth";
import IconUnifyPatient from "./UnifyPatient";
import IconExternallySchedule from "./ExternallySchedule";

export {
  IconScheduleQueue,
  IconListServiceScale,
  IconEmergencyPreRegistration,
  IconMedicalAppointment,
  IconExam,
  IconHospitalization,
  IconHospitalizationOne,
  IconHospitalizationTwo,
  IconHospitalInternment,
  IconHospitalEmergency,
  IconDrugstore,
  IconPersonModule,
  IconRegisterModule,
  IconRegisterPerson,
  IconSearchPerson,
  IconHoliday,
  IconSettings,
  IconFile,
  IconHome,
  IconExternalPlace,
  IconAgreement,
  IconAddress,
  IconBillingModule,
  IconSurgeryCenterModule,
  IconReport,
  IconSurgery,
  IconSchedule,
  IconExcludeQueue,
  IconAppointment,
  IconBulletin,
  IconExport,
  IconOrder,
  IconEffectiveness,
  IconSurgeryMap,
  IconRegulationPool,
  IconPerday,
  IconForperiod,
  IconForRegulation,
  IconImport,
  IconEmployee,
  IconPharmacyModule,
  IconMovements,
  IconEntry,
  IconPriority,
  IconScaleTypes,
  IconRequest,
  IconObjective,
  IconScaleRegister,
  IconChargeback,
  IconDiscard,
  IconPharmacyUnit,
  IconPresentationType,
  IconProduct,
  IconProductClass,
  IconProvider,
  IconInsertOnQueue,
  IconTransfer,
  IconScheduleEffectiveness,
  IconEmergency,
  IconEmergencies,
  IconDisabledPeriods,
  IconEmergencyBulletin,
  IconLabExam,
  IconRegulation,
  IconUnitHealthModule,
  IconSearchUnitHealth,
  IconRegisterUnitHealth,
  IconUnifyPatient,
  IconExternallySchedule,
};
