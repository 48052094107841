import { render, staticRenderFns } from "./RelationshipRemoveReasonModal.vue?vue&type=template&id=36977929&scoped=true&"
import script from "./RelationshipRemoveReasonModal.vue?vue&type=script&lang=js&"
export * from "./RelationshipRemoveReasonModal.vue?vue&type=script&lang=js&"
import style0 from "./RelationshipRemoveReasonModal.scss?vue&type=style&index=0&id=36977929&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36977929",
  null
  
)

export default component.exports