import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation($personId: Int!, $person: PersonInputType!) {
    UpdatePerson(personId: $personId, person: $person) {
      pes_nome
    }
  }
`;

export default ({ commit, state }, personAdditionalInfo) => {
  // commit('BASIC_INFO', personAdditionalInfo)
  const personId = state.basicData.pes_id;
  const person = Object.assign({}, personAdditionalInfo);

  delete person.nationality;
  delete person.city;
  delete person.user;

  const variables = { personId, person };
  return new Promise((resolve, reject) => {
    GraphQLClient.mutate({ mutation, variables })
      .then((pRes) => {
        resolve(pRes.data);
      })
      .catch((pErr) => {
        reject(pErr.graphQLErrors);
      });
  });
};
