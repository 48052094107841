import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async (variable) => {
  // return AuthLegacyRequest.post("exames2/controller-exame-escala/buscar", {
  return AuthLegacyRequest.post(
    "exames2/controller-exame-escala/carregar-combo-box-escalas-com-permissao",
    variable,
  )
    .then(({ data }) => {
      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
