const ValidateCellphone = (pValue, pErrors) => {
  const format = /^\(\d{2}\) \d{5}-\d{4}$/gi;
  if (!format.test(pValue)) {
    pErrors.push("Celular inválido");
    return false;
  }
  return true;
};

const ValidatePhone = (pValue, pErrors) => {
  const format = /^\(\d{2}\) \d{4}-\d{4}$/gi;
  if (!format.test(pValue)) {
    pErrors.push("Telefone inválido");
    return false;
  }
  return true;
};

const ValidateFax = (pValue, pErrors) => {
  const format = /^\(\d{2}\) \d{4}-\d{4}$/gi;
  if (!format.test(pValue)) {
    pErrors.push("Fax inválido");
    return false;
  }
  return true;
};

export { ValidateCellphone, ValidatePhone, ValidateFax };
