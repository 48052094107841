import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchResponsibleType {
    responsibleType {
      rows {
        res_id
        res_nome
      }
    }
  }
`;

export default async (variables) => {
  try {
    const { data } = await GraphQLClient.query({ query, variables });
    return data.responsibleType.rows;
  } catch (err) {
    return Promise.reject(err.graphQLErrors[0].message);
  }
};
