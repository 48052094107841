import { render, staticRenderFns } from "./RgContentHospitalizationBillingMaterial.vue?vue&type=template&id=e3553d56&scoped=true&lang=html&"
import script from "./RgContentHospitalizationBillingMaterial.vue?vue&type=script&lang=js&"
export * from "./RgContentHospitalizationBillingMaterial.vue?vue&type=script&lang=js&"
import style0 from "./RgContentHospitalizationBillingMaterial.scss?vue&type=style&index=0&id=e3553d56&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e3553d56",
  null
  
)

export default component.exports