export default {
  GET_SMART_TABLE_COLUMNS: (state) => (link, usu_id, uns_id, name) => {
    return state.selected_columns.find(
      (item) =>
        link === item.link &&
        usu_id === item.usu_id &&
        uns_id === item.uns_id &&
        name === item.name,
    );
  },
};
