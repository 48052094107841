<template lang="html">
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 498.044 498.044"
    style="enable-background: new 0 0 498.044 498.044"
    xml:space="preserve"
  >
    <g id="XMLID_203_">
      <path
        id="XMLID_206_"
        d="M455.379,293.335l-61.145-61.131c-1.694-1.673-3.957-2.614-6.322-2.614c-2.365,0-4.636,0.94-6.314,2.614
    l-10.659,10.664c-3.478,3.491-3.478,9.139,0,12.628l44.157,44.152l-44.157,44.183c-3.478,3.491-3.489,9.138,0,12.628l10.659,10.667
    c1.679,1.674,3.949,2.614,6.314,2.614c2.365,0,4.628-0.94,6.322-2.614l61.145-61.164c1.684-1.673,2.622-3.949,2.622-6.314
    C458.001,297.283,457.062,295.007,455.379,293.335z"
      />
      <path
        id="XMLID_205_"
        d="M301.845,176.018l-38.031,14.463l-28.348-37.724v-5.745c0-22.891-18.564-41.455-41.457-41.455h-17.716
    c-13.731,0-25.829,6.764-33.376,17.067L90.791,149.04c-4.033,2.052-7.283,5.374-9.248,9.452l-30.211,62.884
    c-4.918,10.256-0.596,22.555,9.655,27.487c2.858,1.374,5.905,2.034,8.892,2.034c7.674,0,15.039-4.304,18.581-11.68l27.188-56.595
    l19.197-9.724v123.783l4.949,61.502l-85.487,46.211c-13.206,7.141-18.132,23.63-10.983,36.838
    c4.9,9.098,14.269,14.261,23.916,14.261c4.382,0,8.805-1.055,12.907-3.273l101.583-54.915c9.735-5.26,15.32-15.901,14.1-26.898
    l-4.604-41.659l29.471,53.064v89.053c0,15.007,12.184,27.178,27.191,27.178c15.009,0,27.181-12.17,27.181-27.178v-96.098
    c0-4.618-1.188-9.163-3.426-13.199l-36.177-64.889v-75.378l4.729,6.298c3.977,5.294,10.116,8.229,16.456,8.229
    c2.467,0,4.941-0.434,7.324-1.342l52.502-19.973c10.636-4.047,15.978-15.943,11.92-26.567
    C324.367,177.322,312.488,171.941,301.845,176.018z"
      />
      <path
        id="XMLID_204_"
        d="M185.16,96.29c26.578,0,48.137-21.548,48.137-48.143C233.297,21.548,211.738,0,185.16,0
    c-26.605,0-48.164,21.548-48.164,48.148C136.996,74.743,158.554,96.29,185.16,96.29z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "ExitPerson",
};
</script>
