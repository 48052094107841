<style src="./PersonalDocument.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="personal-document">
    <ListDocuments v-if="viewStep === 0" @open-document="openDocument" />

    <AddNewDocument
      v-if="viewStep === 1"
      :pes-id="pesId"
      :document-info="documentInfo"
      @back-to-list="backToList"
    />
  </section>
</template>

<script>
import ListDocuments from "./list-documents/ListDocuments";
import AddNewDocument from "./add-new-document/AddNewDocument";

export default {
  name: "PersonalDocument",
  components: {
    ListDocuments,
    AddNewDocument,
  },
  props: {
    pesId: {
      default: null,
    },
    personData: {
      default: null,
    },
  },
  data() {
    return {
      viewStep: 0,
      documentInfo: {},
    };
  },
  methods: {
    openDocument(pDocumentInfo) {
      this.documentInfo = pDocumentInfo;
      this.viewStep = 1;
    },
    backToList() {
      this.documentInfo = {};
      this.viewStep = 0;
    },
  },
};
</script>
