<style src="./RgPrinter.scss" lang="scss" scoped></style>
<template lang="html">
  <div class="body-btn" @click="clicked">
    <div class="icon-txt">
      <span>Imprimir</span>
    </div>
    <div class="icon-btn">
      <IconPrinter v-if="!statusWaiting" />
      <div v-else class="spinner"></div>
    </div>
  </div>
</template>

<script>
import { IconPrinter } from "~tokio/primitive/icon/symbols";

export default {
  name: "RgPrinterButton",
  components: {
    IconPrinter,
  },
  props: {
    dataId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      statusWaiting: false,
    };
  },
  methods: {
    clicked() {
      this.$emit("click");
    },
    setWaiting() {
      this.statusWaiting = true;
    },
    setStartOrFinish() {
      this.statusWaiting = false;
    },
  },
};
</script>
