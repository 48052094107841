import LocalStorage from "~common/local-storage/LocalStorage";
import Logoff from "../../service/Logoff";
import Login from "../../service/Login";
import GetUnitHealth from "../../service/GetUnitHealth";
import GetPermitedUnitHealths from "../../service/GetPermitedUnitHealths";
import GetClientLogo from "../../service/GetClientLogo";
import { setPermissionsMap } from "@/app/access-control/model/PermissionManager";
import LoginWithToken from "../../service/LoginWithToken";
import LOAD_PREFERENCES_BY_MODULES from "./LoadPreferencesByModules";
import LOAD_PERMISSIONS_BY_MODULES_AND_UNIT from "./LoadPermissionsByModuleAndUnit";
import LOAD_PERMISSIONS_BY_UNIT from "./LoadPermissionsByUnit";
import REFRESH_TOKENS from "./RefreshTokens";

export default {
  LOAD_LOGIN_INFO({ state, commit, getters }) {
    if (state.token) {
      setPermissionsMap(state.permissions);
    }
  },
  LOGOFF({ commit }) {
    Logoff();
    LocalStorage.removeItem("client-logo");
    commit("BasicAttention/LOGOFF", null, { root: true });
    commit("Appointment/CLEAN_MODULE_APPOINTMENT", null, { root: true });
    commit("Exam/CLEAN_MODULE_EXAM", null, { root: true });
    commit("Emergency/CLEAN_MODULE_EMERGENCY", null, { root: true });
    commit("UnitHealth/CLEAN_SUB_MODULE_UNIT_HEALTH", null, { root: true });
    commit("Home/RESET_RECENT_OPERATION", null, { root: true });
    commit("User/RESET_SMART_TABLE_COLUMNS", null, { root: true });
    commit("LOGOFF");
  },
  LOGIN_WITH_TOKEN({ commit, dispatch }, token) {
    return LoginWithToken(token)
      .then((response) => {
        const pPayload = response.data;
        commit("SET_TOKEN", pPayload.token);
        GetClientLogo(pPayload.loginInfo.client);
        commit("LOGIN", pPayload);
        return dispatch(
          "SET_CURRENT_UNIT_HEALTH",
          pPayload.user.usu_id_unidades_saude_padrao,
        );
      })
      .catch((pErr) => {
        LocalStorage.removeItem("client-logo");
        commit("LOGOFF");
      });
  },
  LOGIN({ state, commit, dispatch }, credentials) {
    return new Promise((resolve, reject) => {
      Login(credentials, state.useLegacyBackend)
        .then((response) => {
          const pPayload = response.data;

          commit("SET_TOKEN", pPayload.token);
          commit("SET_LEGACY_TOKEN", response.legacy.token);
          GetClientLogo(pPayload.loginInfo.client);
          commit("LOGIN", pPayload);
          commit("SET_PRIVATE_SECTOR", !!pPayload.privateSector);
          commit("SET_CADSUS", !!pPayload.cadsus);
          commit("SET_PHONE_REQUIRED", !!pPayload.phoneRequired);
          commit("SET_BACKEND_LEGACY_URL", pPayload.loginInfo.backendLegacyUrl);
          commit("SET_USER_HEALTH_OCCUPATIONS", pPayload.userHealthOccupations);

          commit("BasicAttention/LOGOFF", null, { root: true });
          commit("Appointment/CLEAN_MODULE_APPOINTMENT", null, { root: true });
          commit("Exam/CLEAN_MODULE_EXAM", null, { root: true });
          commit("Emergency/CLEAN_MODULE_EMERGENCY", null, { root: true });
          commit("UnitHealth/CLEAN_SUB_MODULE_UNIT_HEALTH", null, {
            root: true,
          });
          commit("Home/RESET_RECENT_OPERATION", null, { root: true });
          commit("User/RESET_SMART_TABLE_COLUMNS", null, { root: true });

          dispatch(
            "SET_CURRENT_UNIT_HEALTH",
            pPayload.user.usu_id_unidades_saude_padrao,
          );

          resolve();
        })
        .catch((pErr) => {
          LocalStorage.removeItem("client-logo");
          commit("LOGOFF");
          reject(new Error(pErr));
        });
    });
  },
  FILL_PERMITED_UNIT_HEALTH_LIST(context) {
    GetPermitedUnitHealths().then((result) => {
      const unitHealthList = [];
      result.map((item) => {
        unitHealthList.push({
          value: item.uns_id,
          text: item.uns_nome.toUpperCase(),
        });
      });
      context.commit("SET_PERMITED_UNIT_HEALTH_LIST", unitHealthList);
    });
  },
  SET_CURRENT_UNIT_HEALTH({ state, commit, getters }, pUnitHealth) {
    const unitHealthId = pUnitHealth || getters.GET_UNIT_HEALTH_ID;
    return GetUnitHealth(unitHealthId).then((pResult) => {
      commit("DEFINE_UNIT_HEALTH", pResult.data);
      setPermissionsMap(state.permissions);
      return true;
    });
  },
  LOAD_PREFERENCES_BY_MODULES,
  LOAD_PERMISSIONS_BY_MODULES_AND_UNIT,
  LOAD_PERMISSIONS_BY_UNIT,
  REFRESH_TOKENS,
};
