<template>
  <div class="appointment-schedule-per-periods">
    <RgSearch
      ref="rgsearch"
      v-model="mutableEmployeeAgenda"
      :search-function="searchFilter"
      :clear-function="cleanForm"
      :build-filter="generateFilter"
      :show-footer="false"
      :show-empty-message="false"
      :showTitle="false"
      :disabledShorkey="modalConfirmSchedulingDeletion"
      @afterPerformSearch="afterSearchFilter"
      @count="getCountValue"
    >
      <div slot="filters" class="appointment-schedule-per-periods-filter">
        <div class="grid">
          <div class="selectinput">
            <RgComboboxUnithealth
              v-model="form.selectUnity"
              :rules="{ required: true }"
              permission="consulta.agendamentoCompleto"
              class="inputitem"
              disabled
            />
          </div>
          <div class="selectinput">
            <RgComboboxSectorBondAndPermission
              ref="sector"
              v-model="form.sector"
              :rules="{ required: true }"
              :unit-health-id="form.selectUnity"
              :submodule-id="subModuleId"
              class="inputitem"
            />
          </div>

          <div class="selectinput">
            <RgSuggestCboSector
              ref="Cbo"
              v-model="form.cbo"
              :disabled="!hasSector"
              :class="{ disable: !hasSector }"
              :sectorId="Number(form.sector)"
              label="Ocupação"
              class="inputitem"
              activeProfessional
              @selected="selectedCbo"
            />
          </div>

          <div class="selectinput">
            <RgComboboxProfessionalSectorOccupationScaleActive
              ref="RgComboboxProfessionalSectorOccupationScaleActive"
              v-model="form.employee"
              :sectorId="Number(form.sector)"
              :ocuppation-id="form.ocp_id"
              default-text="Todos"
              class="inputitem"
              label="Profissional"
              :disabled="!hasSector"
              :class="{ disable: !hasSector }"
            />
          </div>

          <div class="periods">
            Hoje +
            <RgInput
              v-model="form.initialDay"
              :disabled="true"
              label=""
              class="days mg"
              type="number"
            />
            {{ form.lbl_tipo_periodo_inicial }}
          </div>

          <div class="periods">
            <RgInput
              v-model="form.finalDay"
              :disabled="true"
              label=""
              class="days"
              type="number"
            />
            {{ form.lbl_tipo_periodo_final }}. A partir de
            <RgInput
              v-model="form.dayEarlySchedule"
              label=""
              class="days mg"
              type="number"
            />
            dias.
          </div>
        </div>
      </div>

      <div
        v-if="mutableEmployeeAgenda.length"
        class="appointment-schedule-per-periods-result"
      >
        <div class="content-grid">
          <legend class="legend">Profissionais</legend>

          <div class="table-one">
            <SmartTable
              ref="smartTableAgenda"
              name="AppointmentSchedulePerPeriodsEmployee"
              :columns="COLUMN_TABLE_ONE"
              :body="mutableEmployeeAgenda"
              :total="mutableEmployeeAgenda.length"
              :show-pagination="false"
              :initialColumns="INITIAL_COLUMNS"
              :colorLine="actionColorLine"
              index-column="eag_id"
              second-index-column="DataConsulta"
              toggleSelected
              @getLine="selectEmployeeData"
            >
              <div slot="legends" class="legends">
                <div class="circle --grey" />
                <span class="legend">Sem vagas disponíveis</span>
                <div class="circle --red" />
                <span class="legend">Inabilitado no dia</span>
              </div>
            </SmartTable>
          </div>

          <div class="painel-button">
            <RgNewScheduleButton
              :disabled="!scheduleSelected"
              class="buttons-bottom"
              title="Agendar paciente"
              @click="scheduleAction"
            />

            <RgEditButton
              :disabled="!scheduleSelected"
              class="buttons-bottom"
              title="Editar escala de atendimento"
              @click="openModalChangeProfessionalScale"
            />

            <RgPrinterButton
              :disabled="!scheduleSelected || disablePrint"
              title="Imprimir ficha de controle ambulatorial"
              class="buttons-bottom"
              @click="printOutpatientConsultationControl"
            />
          </div>

          <div class="separator" />

          <legend class="legend">Pacientes Agendados para o Dia</legend>

          <div class="table-two">
            <SmartTable
              ref="smartTableEmployee"
              name="AppointmentSchedulePerPeriodsAppointment"
              :columns="COLUMN_TABLE_TWO"
              :body="mutableAppointmentSchedule.data"
              :total="Number(mutableAppointmentSchedule.total)"
              :show-pagination="false"
              @getLine="getAppointmentsRow"
            />
          </div>

          <div class="painel-button">
            <RgEditButton
              :disabled="appointmentsSelected"
              title="Editar agendamento"
              class="buttons-bottom"
              @click="editScheduledAppointment"
            />

            <RgShowButton
              :disabled="appointmentsSelected"
              class="buttons-bottom"
              title="Detalhes"
              @click="openModalAppointmentDetails"
            />

            <RgDeleteScheduleButton
              :disabled="appointmentsSelected"
              title="Excluir agendamento"
              class="buttons-bottom"
              @click="openModalConfirmSchedulingDeletion"
            />

            <RgPersonCardButton
              :disabled="appointmentsSelected"
              title="Editar Paciente"
              class="buttons-bottom"
              @click="patientRecord"
            />

            <RgPersonDropdown
              :item="appointmentsData || {}"
              :disabled="appointmentsSelected"
              :action-options="itemActionOptionsPerson(appointmentsData)"
              show-up
              show-left
              class="dropdown-bottom"
            />

            <RgDropdownPrinter
              :disabled="appointmentsSelected"
              :item="appointmentsData || {}"
              :action-options="itemActionOptionsPrinter(appointmentsData)"
              show-up
              show-left
              class="dropdown-bottom"
            />

            <RgDropdownPep
              :disabled="appointmentsSelected"
              :item="appointmentsData || {}"
              :action-options="itemActionOptionsPep(appointmentsData)"
              show-up
              show-left
              class="dropdown-bottom"
            />
          </div>
        </div>
      </div>

      <div v-else class="no-result">
        <IconEmpty />
        <span class="message">
          Não foram encontrados resultados para essa busca
        </span>
      </div>
    </RgSearch>

    <ModalChangeProfessionalScale
      v-if="modalChangeProfessionalScale"
      ref="modalChangeProfessionalScale"
      :data="employeeAgendaData || {}"
      :show="modalChangeProfessionalScale"
      :technical-scale-permission="technicalScalePermission"
      :normal-scale-permission="normalScalePermission"
      @reSearch="researchFirstTable"
      @close="closeModalChangeProfessionalScale"
      @error="handleErrorChangeProfessionalScale"
    />

    <ModalConfirmDeletion
      v-bind="propsModalConfirmDeletion"
      @close="closeModalConfirmSchedulingDeletion"
      @reSearch="researchFirstTable"
    />

    <ModalPatientImageInRow
      ref="modalPatientImageInRow"
      :show="modalPatientImageInRow"
      :modId="appointmentModuleId"
      :fill-id="filId"
      hideButtons
      @close="closeModalPatientImageInRow"
    />

    <ModalTypesPrint
      ref="modalTypePrint"
      :show="modalTypesPrint"
      :appointment-id="appointmentIdToPrint"
      @close="closeModalTypesPrint"
    />

    <ModalAppointmentDetails
      :show="modalAppointmentDetails"
      :pcc-id="appointmentsData ? appointmentsData.pcc_id : 0"
      :appointment-id="appointmentsData ? appointmentsData.con_id : 0"
      :unity-health="
        appointmentsData ? appointmentsData.tcu_id_unidades_saude : 0
      "
      @close="closeModalAppointmentDetails"
    />

    <ModalScaleConflict
      ref="modalScaleConflict"
      :show="showModalScaleConflict"
      :conflicts="scaleConflicts"
      :history="scaleHistory"
      @close="actionCloseModalScaleConflict"
    />
  </div>
</template>

<script>
import {
  RgComboboxUnithealth,
  RgComboboxSectorBondAndPermission,
  RgSuggestCboSector,
  RgEditButton,
  RgNewScheduleButton,
  RgDeleteScheduleButton,
  RgPersonCardButton,
  RgInput,
  RgPersonDropdown,
  RgPrinterButton,
  RgShowButton,
  IconEmpty,
  RgDropdownPrinter,
} from "~tokio/primitive";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb.js";

import {
  ModalPatientImageInRow,
  ModalConfirmDeletion,
} from "~tokio/primitive/modal";
import moment from "moment";
import RgSearch from "~tokio/foundation/rg-search/RgSearch";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";
import RgDropdownPep from "$hospitalization/hospitalizations/component/button/rg-dropdown-pep/RgDropdownPep";

import {
  ModalChangeProfessionalScale,
  ModalTypesPrint,
  ModalAppointmentDetails,
  ModalScaleConflict,
} from "$appointment/submodules/schedule/component/";

import ScheduleOutpatientConsultationControlHTML from "$appointment/submodules/schedule/html/ScheduleOutpatientConsultationControlHTML";
import ScheduleHealthcareBulletinHTML from "~tokio/primitive/html/ScheduleHealthcareBulletinHTML.js";
import SchedulePatientFollowUpSheetHTML from "$appointment/submodules/schedule/html/SchedulePatientFollowUpSheetHTML";
import {
  printThermalOneWay,
  printThermalTwoWay,
  printScheduleVoucher,
} from "$appointment/common/utils/printsScheduleVoucherAppointment";
import GetColorRowSmartTable from "~common/utils/GetColorRowSmartTable";
import RgComboboxProfessionalSectorOccupationScaleActive from "$appointment/common/component/rg-combobox-professional-sector-occupation-scale-active/RgComboboxProfessionalSectorOccupationScaleActive";

export default {
  name: "AppointmentSchedulePerPeriods",
  components: {
    RgSearch,
    RgComboboxUnithealth,
    RgComboboxSectorBondAndPermission,
    RgSuggestCboSector,
    SmartTable,
    RgEditButton,
    RgNewScheduleButton,
    RgDeleteScheduleButton,
    RgPersonCardButton,
    RgPersonDropdown,
    RgDropdownPrinter,
    RgDropdownPep,
    ModalChangeProfessionalScale,
    ModalConfirmDeletion,
    RgInput,
    RgPrinterButton,
    ModalPatientImageInRow,
    ModalTypesPrint,
    RgShowButton,
    ModalAppointmentDetails,
    RgComboboxProfessionalSectorOccupationScaleActive,
    ModalScaleConflict,
    IconEmpty,
  },

  data() {
    return {
      form: {
        sector: null,
        ocp_id: null,
        cbo: null,
        employee: null,
        pes_id: null,
        initialDay: null,
        finalDay: null,
        dayEarlySchedule: null,
        lbl_tipo_periodo_final: "",
        lbl_tipo_periodo_inicial: "",
        vin_id: null,
        fun_id: 0,
        tipoPeriodoInicial: null,
        tipoPeriodoFinal: null,
        selectUnity: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
      },
      mutableAppointmentSchedule: {
        data: [],
        total: 0,
      },
      totalAgenda: 0,
      sectorInVuex: null,
      items: [],
      mutableEmployeeAgenda: [],
      modalChangeProfessionalScale: false,
      modalConfirmSchedulingDeletion: false,
      modalPatientImageInRow: false,
      modalTypesPrint: false,
      modalAppointmentDetails: false,
      payloadAppointmentSchedule: {},
      employeeAgendaData: null,
      appointmentsData: null,
      filId: null,
      disablePrint: false,
      technicalScalePermission: 1,
      normalScalePermission: 1,
      disablePrinting: false,
      appointmentId: [],
      showModalScaleConflict: false,
      scaleConflicts: [],
      scaleHistory: [],
    };
  },

  computed: {
    propsModalConfirmDeletion() {
      const maxlength = 250;
      const subtitle = "A operação não poderá ser desfeita";
      const show = this.modalConfirmSchedulingDeletion;
      const confirm = (reason) => this.deleteScheduling(reason);

      return { show, confirm, maxlength, subtitle };
    },

    subModuleId() {
      return 42;
    },

    actionColorLine() {
      return {
        get: (item) => {
          if (
            parseInt(item.funcionario_inabilitado) === 1 ||
            parseInt(item.hag_atendimento) === 0
          ) {
            return GetColorRowSmartTable("red");
          }
          if (parseInt(item.eag_quantidade_restantes) === 0) {
            return GetColorRowSmartTable("grey");
          }
        },
      };
    },

    appointmentModuleId() {
      return this.$store.state.Login.route_module_map.appointment;
    },

    scheduleSelected() {
      return this.employeeAgendaData !== null;
    },

    appointmentsSelected() {
      return !this.employeeAgendaData || !this.appointmentsData;
    },

    canSchedulePatient() {
      return this.scheduleSelected;
    },

    hasFreeSchedule() {
      return (
        this.employeeAgendaData &&
        Number(this.employeeAgendaData.eag_quantidade_restantes) > 0
      );
    },

    hasSector() {
      return this.form.sector;
    },

    selectedEmployee() {
      return this.$store.getters["Appointment/Schedule/GET_SELECTED_EMPLOYEE"];
    },

    existFilterData() {
      return this.$store.getters[
        "Appointment/Schedule/GET_FILTER_APPOINTMENT_PER_PERIODS"
      ];
    },

    hasPermissionSendAppointmentPep() {
      return !!this.$Permissions.sector.has(
        "consulta.integracaoPep.enviarAtendimento",
        this.employeeAgendaData.set_id,
      );
    },

    hasPermissionCancelAppointmentPep() {
      return !!this.$Permissions.sector.has(
        "consulta.integracaoPep.enviarCancelamentoAtendimento",
        this.employeeAgendaData.set_id,
      );
    },

    appointmentIdToPrint: {
      get: function () {
        return this.appointmentId;
      },
      set: function (newValue) {
        this.appointmentId = [];
        this.appointmentId.push(Number(newValue));
      },
    },

    preferences() {
      const preferences = {
        blockedRetroactiveSendingToPep: !!Number(
          this.$store.getters["Login/GET_PREFERENCES"][
            "tViewMarcacaoConsulta.con_bloquear_envio_retroativo_pep"
          ],
        ),
        printOptionsPreference: this.$store.getters["Login/GET_PREFERENCES"][
          "tViewBuscaAgendamentoConsultaDia.opcoesImpressao"
        ],
      };

      return preferences;
    },
  },

  watch: {
    async "form.sector"(pValue) {
      const sectorId = Number(pValue);
      const isValidSector = Number.isInteger(sectorId);
      const invalidValue = pValue === false;

      if (isValidSector) {
        const isDifferentSector = this.sectorInVuex !== pValue;

        if (isDifferentSector) {
          this.sectorInVuex = null;
          const periodsAndDaysInfo = await this.$store.dispatch(
            "Appointment/Schedule/GET_DAYS_AND_PERIODS_INFO_FROM_SECTOR",
            { intIdSetores: pValue },
          );

          this.mountFilter(periodsAndDaysInfo);
        }
      } else if (invalidValue) {
        this.defaultFilter();
        this.form.ocp_id = null;
        this.form.cbo = null;
        this.form.employee = null;
      }
    },
  },

  mounted() {
    const isAppontmentSchedule = this.$route.params.isAppontmentSchedule;
    if (isAppontmentSchedule) {
      this.appointmentId = this.$route.params.appointmentId;
      this.viewAppointmentScheduleVoucher(this.appointmentId);
    }
    this.fillFilter();
  },

  beforeCreate() {
    this.COLUMN_TABLE_ONE = [
      { name: "Profissional", key: "fun_nome" },
      { name: "Ocupação", key: "ocp_nome" },
      { name: "Data", key: "DataConsulta" },
      { name: "Dia da Semana", key: "dsm_nome" },
      { name: "Início", key: "inicio" },
      { name: "Qtd. de Horários", key: "eag_quantidade_consultas" },
      { name: "Qtd. Ocupada", key: "eag_quantidade_marcadas" },
      { name: "Qtd. Livre", key: "eag_quantidade_restantes" },
      { name: "Tipo de Escala", key: "type" },
    ];

    this.COLUMN_TABLE_TWO = [
      { name: "Hora", key: "con_hora" },
      { name: "Paciente", key: "pes_nome" },
      { name: "Objetivo", key: "tcu_novo_tipo_consulta" },
      { name: "Status Meu Esus", key: "sme_nome" },
      { name: "Enviado PEP", key: "enviado" },
    ];
  },

  created() {
    this.INITIAL_COLUMNS = 8;
    this.form.selectUnity = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    this.PERMISSION_APPOINTMENT_SCHEDULE_PER_PERIOD_SHOWS = 116;
  },

  destroyed() {
    const existThisRouteSchedulePerPeriods = ValidateIfRouteExistInBreadscrumb(
      "/appointment/schedule/per-periods",
      this.$route.meta.breadcrumb,
    );

    if (!existThisRouteSchedulePerPeriods) {
      this.$store.commit(
        "Appointment/Schedule/RESET_FILTER_APPOINTMENT_PER_PERIODS",
      );
      this.$store.commit("Appointment/Schedule/UNSELECT_APPOINTMENT_DATA");
      this.$store.commit("Appointment/Schedule/UNSELECT_EMPLOYEE_DATA");
    }
  },

  methods: {
    hasPermissionsToEditSchedule() {
      const isReservaTecnica = this.employeeAgendaData.cte_id === "2";
      if (isReservaTecnica) {
        return this.$Permissions.sector.has(
          "consulta.agendamentoCompleto.alterarAgendamentoPeriodoReservaTecnica",
          this.employeeAgendaData.set_id,
        );
      } else {
        return this.$Permissions.sector.has(
          "consulta.agendamentoCompleto.alterar",
          this.employeeAgendaData.set_id,
        );
      }
    },

    hasPermissionsToSchedule() {
      const isReservaTecnica = this.employeeAgendaData.cte_id === "2";
      if (isReservaTecnica) {
        return this.$Permissions.sector.has(
          "consulta.agendamentoCompleto.agendarReservaTecnica",
          this.employeeAgendaData.set_id,
        );
      } else {
        return this.$Permissions.sector.has(
          "consulta.agendamentoCompleto.incluir",
          this.employeeAgendaData.set_id,
        );
      }
    },

    hasPermissionsToDeleteSchedule() {
      const isReservaTecnica = this.employeeAgendaData.cte_id === "2";
      if (isReservaTecnica) {
        return this.$Permissions.sector.has(
          "consulta.agendamentoCompleto.exclusaoAgendamentoPeriodoReservaTecnica",
          this.employeeAgendaData.set_id,
        );
      } else {
        return this.$Permissions.sector.has(
          "consulta.agendamentoCompleto.excluir",
          this.employeeAgendaData.set_id,
        );
      }
    },

    mountFilter(pValue) {
      this.form.finalDay = Number(pValue.marcacao.vsi_quantidade);

      if (this.form.sector) {
        this.form.initialDay = Number(pValue.carencia.vsi_quantidade);
      } else {
        const DEFAULT_DIAS_INICIAL = 3;
        this.form.initialDay = DEFAULT_DIAS_INICIAL;
      }

      if (Number(pValue.marcacao.vsi_id_intervalo_periodo) === 1) {
        this.form.lbl_tipo_periodo_final = "dias";
      } else {
        this.form.lbl_tipo_periodo_final = "semanas";
      }

      if (Number(pValue.carencia.vsi_id_intervalo_periodo) === 1) {
        this.form.lbl_tipo_periodo_inicial = "dias até hoje + Período de";
      } else {
        this.form.lbl_tipo_periodo_inicial = "semanas até hoje + Período de";
      }

      this.form.tipoPeriodoInicial = Number(
        pValue.carencia.vsi_id_intervalo_periodo,
      );
      this.form.tipoPeriodoFinal = Number(
        pValue.marcacao.vsi_id_intervalo_periodo,
      );
    },

    defaultFilter() {
      this.form.lbl_tipo_periodo_inicial = "dias até hoje + Período de";
      this.form.lbl_tipo_periodo_final = "dias";
      this.form.initialDay = 0;
      this.form.finalDay = 30;
    },

    async searchFilter(pData) {
      try {
        const hasCboSelected =
          this.form.cbo && this.form.cbo.length > 0 && !this.form.ocp_id;

        const hasEmployeeSelected =
          this.form.employee &&
          this.form.employee.length > 0 &&
          !this.form.employee;

        if (hasCboSelected) {
          this.$toaster.warning("A seleção de uma ocupação é obrigatória.");
          return false;
        }

        if (hasEmployeeSelected) {
          this.$toaster.warning("A seleção de um profissional é obrigatória.");
          return false;
        }

        this.$loader.start("Carregando dados da busca de consulta");
        const data = await this.$store.dispatch(
          "Appointment/Schedule/SEARCH_EMPLOYEE_AGENDA_PER_PERIODS",
          pData,
        );
        this.$loader.finish();
        return data;
      } catch (Err) {
        this.$toaster.warning(Err);
      } finally {
        this.$loader.finish();
      }
    },

    scheduleAction() {
      if (!this.hasPermissionsToSchedule()) {
        this.$toaster.error(
          "Você não possui permissão para cadastrar um agendamento.",
          "Usuário Sem Permissão!",
        );
        return;
      }
      const hasValue = this.mutableEmployeeAgenda.find(
        (item) =>
          item.eag_id === this.employeeAgendaData.eag_id &&
          item.DataConsulta === this.employeeAgendaData.DataConsulta,
      );

      if (hasValue) {
        this.employeeAgendaData = hasValue;

        this.$store.commit(
          "Appointment/Schedule/SELECT_EMPLOYEE_DATA",
          this.employeeAgendaData,
        );
      }

      if (!this.hasFreeSchedule) {
        return this.$toaster.warning(
          "Profissional não tem mais vagas disponíveis!",
          "Sem vagas",
        );
      }
      this.$store.commit("Appointment/Schedule/DESTROY_TYPE_SCHEDULE");

      this.$store.commit("Appointment/Schedule/UNSELECT_APPOINTMENT_DATA");

      this.$router.push({ name: "appointment.schedule.per-periods.form" });
    },

    editScheduledAppointment() {
      if (!this.hasPermissionsToEditSchedule()) {
        this.$toaster.error(
          "Usuário sem permissão para a edição de Consulta.",
          "Usuário Sem Permissão!",
        );
        return;
      }
      this.$store.commit("Appointment/Schedule/DESTROY_TYPE_SCHEDULE");

      this.$router.push({ name: "appointment.schedule.per-periods.edit.form" });
    },

    async deleteScheduling(reason) {
      try {
        const { con_id, set_id } = this.appointmentsData;

        const {
          normalPermission,
          technicalPermission,
        } = this.getPermissionOperation(set_id);

        const variables = {
          intIdConsulta: con_id,
          strMotivo: reason,
          blnPermissaoNormal: normalPermission,
          blnPermissaoReserva: technicalPermission,
        };

        await this.$store.dispatch(
          "Appointment/Schedule/REMOVE_APPOINTMENT",
          variables,
        );

        this.$store.commit("Appointment/Schedule/UNSELECT_APPOINTMENT_DATA");

        this.clearSelectionSchedule();

        this.$toaster.success("Agendamento removido com sucesso!");
      } catch (pErr) {
        this.$toaster.error(pErr, "Erro ao tentar remover o agendamento");
      } finally {
        this.$loader.finish();
      }
    },

    getPermissionOperation(pSectorId) {
      const subModuleOperationNormal = "consulta.agendamentoCompleto.excluir";
      const subModuleOperationTechnical =
        "consulta.agendamentoCompleto.exclusaoAgendamentoPeriodoReservaTecnica";

      const normalPermission = this.$Permissions.sector.has(
        subModuleOperationNormal,
        pSectorId,
      );

      const technicalPermission = this.$Permissions.sector.has(
        subModuleOperationTechnical,
        pSectorId,
      );

      return { normalPermission, technicalPermission };
    },

    patientRecord() {
      const patientId = this.appointmentsData.pes_id;
      this.$store.commit("Person/Patient/SET_PERSON_ID", Number(patientId));

      this.$router.push({
        name: "appointment.schedule.per-period.edit-patient",
      });
    },

    getCountValue(pValue) {
      this.totalAgenda = Number(pValue) || 0;
    },

    selectEmployeeData(pData, reSearch = false) {
      if (pData) {
        if (!reSearch) {
          this.$store.commit(
            "Appointment/Schedule/SELECT_EMPLOYEE_DATA",
            pData,
          );
          this.employeeAgendaData = pData;
        } else {
          const index = this.mutableEmployeeAgenda.findIndex((row) => {
            const compareId = row.eag_id === pData.eag_id;
            const compareDate = row.DataConsulta === pData.DataConsulta;

            return compareId && compareDate;
          });

          if (index !== -1) {
            this.$store.commit(
              "Appointment/Schedule/SELECT_EMPLOYEE_DATA",
              this.mutableEmployeeAgenda[index],
            );

            this.employeeAgendaData = this.mutableEmployeeAgenda[index];
          } else {
            this.$store.commit(
              "Appointment/Schedule/SELECT_EMPLOYEE_DATA",
              pData,
            );
            this.employeeAgendaData = pData;
          }
        }

        this.disablePrint = Number(pData.eag_quantidade_marcadas) < 1;

        this.payloadAppointmentSchedule = this.generateFilterAppointmentSchedule(
          this.employeeAgendaData.hag_id,
          this.employeeAgendaData.DataConsulta,
        );

        this.searchFilterAppointmentSchedule();
      } else {
        this.$store.commit("Appointment/Schedule/UNSELECT_APPOINTMENT_DATA");
        this.employeeAgendaData = null;
        this.appointmentsData = null;
        this.$refs.smartTableEmployee.cleanSelectRow();
        this.mutableAppointmentSchedule = {
          data: [],
          total: 0,
        };
      }
    },

    getAppointmentsRow(pData) {
      this.$store.commit("Appointment/Schedule/SELECT_APPOINTMENT_DATA", pData);
      this.appointmentsData = pData;
      this.filId = Number(pData.fil_id) || null;
    },

    generateFilterAppointmentSchedule(idHistorySchedule, dataConsulta) {
      const variables = {
        arrFiltro: {
          intIdAgendamento: 0,
          intIdHistoricoAgendamento: Number(idHistorySchedule),
          intIdUnidade: this.form.selectUnity,
          strDataConsulta: dataConsulta,
          limiteFim: 1000000,
          limiteInicio: 0,
        },
      };

      return variables;
    },

    async searchFilterAppointmentSchedule() {
      try {
        this.$loader.start();
        this.mutableAppointmentSchedule = await this.$store.dispatch(
          "Appointment/Schedule/SEARCH_APPOINTMENT_SCHEDULE",
          this.payloadAppointmentSchedule,
        );
      } catch (Err) {
        this.$toaster.error(Err);
      } finally {
        this.$loader.finish();
      }
    },

    async researchFirstTable() {
      try {
        await this.$refs.rgsearch.performSearch();
        if (this.selectedEmployee) {
          this.$refs.smartTableEmployee.selectRow(this.selectedEmployee);
          this.selectEmployeeData(this.selectedEmployee, true);
        }
      } catch (pErr) {
        console.log(pErr);
      }
    },

    generateFilter() {
      const variables = {
        intSet_id: this.form.sector || 0,
        intOcp_id: this.form.ocp_id || 0,
        intPes_id: this.form.employee || 0,
        intUns_id: this.form.selectUnity,
        strHag_data_consulta: moment().format("DD/MM/YYYY"),
        intDiasInicial: this.form.initialDay,
        intDiasFinal: this.form.finalDay,
        intTipoPeriodoInicial: this.form.tipoPeriodoInicial || 1,
        intTipoPeriodoFinal: this.form.tipoPeriodoFinal || 1,
        intDiasProximoAgendamento: Number(this.form.dayEarlySchedule),
      };

      this.$store.commit(
        "Appointment/Schedule/SET_FILTER_APPOINTMENT_PER_PERIODS",
        this.form,
      );

      return variables;
    },

    selectedCbo(pValue) {
      const hasSuggestion = Object.keys(pValue.source).length > 0;
      if (hasSuggestion) {
        this.$loader.start();
        this.form.ocp_id = pValue.source.ocp_id;
        this.$loader.finish();
      } else {
        this.form.ocp_id = 0;
      }
    },

    // selectingEmployee(pEmployee) {
    //   const hasSuggestion = Object.keys(pEmployee.source).length > 0;
    //   if (hasSuggestion) {
    //     this.$loader.start();
    //     const data = pEmployee.source;
    //     this.form.pes_id = data.pes_id;
    //     this.form.vin_id = data.vin_id;
    //     this.form.employee = data.pes_nome;
    //     this.form.fun_id =
    //       data.employeeRelationship && data.employeeRelationship.employee
    //         ? data.employeeRelationship.employee.fun_id
    //         : 0;
    //     this.$loader.finish();
    //   } else {
    //     this.form.fun_id = 0;
    //     this.form.pes_id = 0;
    //     this.form.vin_id = 0;
    //   }
    // },

    openModalPatientImageInRow() {
      if (this.filId) {
        this.modalPatientImageInRow = true;
      } else {
        this.$toaster.warning(
          "Esse paciente não foi cadastrado pela fila de regulação.",
        );
      }
    },

    closeModalPatientImageInRow() {
      this.modalPatientImageInRow = false;
    },

    observationPatient() {
      if (this.filId) {
        const appointmentsData = this.appointmentsData;
        this.$router.push({
          name: "appointment.schedule.per-periods.patient-observation-queue",
          params: { appointmentsData },
        });
      } else {
        this.$toaster.warning(
          "Este paciente não foi cadastrado pela fila de regulação.",
        );
      }
    },

    itemActionOptionsPerson(pItem) {
      return [
        {
          label: "Exibir observações do paciente na fila de regulação",
          action: this.observationPatient,
        },
        {
          label: "Exibir imagens do paciente na fila de regulação",
          action: this.openModalPatientImageInRow,
        },
      ];
    },

    itemActionOptionsPrinter(appointmentsData) {
      return [
        {
          label: "Imprimir comprovante de agendamento",
          action: () => {
            this.appointmentIdToPrint = appointmentsData.con_id;
            this.openModalTypesPrint();
          },
          disable: false,
        },
        {
          label: "Imprimir ficha de acompanhamento do paciente",
          action: this.printPatientFollowUpSheet,
          disable: false,
        },
        {
          label: "Imprimir boletim de atendimento médico",
          action: this.printHealthcareBulletin,
          disable: false,
        },
      ];
    },

    itemActionOptionsPep(pItem) {
      const alreadyWasInPep = pItem && pItem.enviado === "SIM";
      return [
        {
          label: "Enviar atendimento para o PEP",
          action: this.sendAppointmentPep,
          disable: alreadyWasInPep,
        },
        {
          label: "Cancelar atendimento no PEP",
          action: this.cancelAppointmentonPep,
        },
      ];
    },

    sendAppointmentPep() {
      if (!this.validBlockedRetroactiveSendingToPep()) {
        this.$toaster.error(
          "Não é possível enviar um agendamento retroativo para o Pep.",
        );
        return;
      }
      if (!this.hasPermissionSendAppointmentPep) {
        this.$toaster.error("Usuário sem permissão para enviar atendimento.");
        return;
      }

      this.$loader.start();

      this.$store
        .dispatch(
          "Appointment/Schedule/SEND_APPOINTMENT_PEP",
          this.appointmentsData.con_id,
        )
        .then((data) => {
          this.$toaster.success("Atendimento enviado para o PEP");
          // this.fillFilter()
          // Fiz dessa maneira pois o fillFilter não funcinou aqui.
          // ... nesse caso não vejo necessidade de carregar o grip pai também.
          this.searchFilterAppointmentSchedule();
        })
        .catch((err) => {
          this.$toaster.error(
            err.message,
            "Falha ao enviar atendimento para o PEP",
          );
        })
        .finally(() => {
          this.$loader.finish();
        });
    },

    cancelAppointmentonPep() {
      if (!this.hasPermissionCancelAppointmentPep) {
        this.$toaster.error("Usuário sem permissão para cancelar atendimento.");
        return;
      }

      this.$loader.start();

      this.$store
        .dispatch(
          "Appointment/Schedule/CANCEL_APPOINTMENT_PEP",
          this.appointmentsData.con_id,
        )
        .then((data) => {
          this.$toaster.success(
            "Cancelamento do Atendimento enviado para o PEP",
          );
          // this.fillFilter()
          // Fiz dessa maneira pois o fillFilter não funcinou aqui.
          // ... nesse caso não vejo necessidade de carregar o grip pai também.
          this.searchFilterAppointmentSchedule();
        })
        .catch((err) => {
          this.$toaster.error(
            err.message,
            "Falha ao enviar cancelamento do atendimento para o PEP",
          );
        })
        .finally(() => {
          this.$loader.finish();
        });
    },

    async printPatientFollowUpSheet() {
      const data = await this.$store.dispatch(
        "Appointment/Schedule/GET_PATIENT_FOLLOW_UP_SHEET",
        {
          intIdPacienteConsulta: this.appointmentsData.pcc_id,
          intIdUnidadeSaude: this.form.selectUnity,
        },
      );

      const html = SchedulePatientFollowUpSheetHTML(data);

      this.$utils.print.printHtml(html);
    },

    async printOutpatientConsultationControl() {
      const client = this.$store.getters["Login/GET_CLIENT_CODE"];
      const unithealth = this.$store.getters["Login/GET_UNIT_HEALTH_NAME"];
      const employeeAgendaData = {
        client,
        unithealth,
        ...this.employeeAgendaData,
      };
      const data = await this.$store.dispatch(
        "Appointment/Schedule/GET_OUT_PATIENT_CONSULTATION_CONTROL",
        { intIdHistoricoAgendamento: this.employeeAgendaData.hag_id },
      );
      const html = ScheduleOutpatientConsultationControlHTML(
        employeeAgendaData,
        data,
      );

      this.$utils.print.printHtml(html);
    },

    async printHealthcareBulletin() {
      const data = await this.$store.dispatch(
        "Appointment/Schedule/GET_HEALTHCARE_BULLETIN",
        { intIdConsulta: this.appointmentsData.con_id },
      );
      const html = ScheduleHealthcareBulletinHTML(data);

      this.$utils.print.printHtml(html);
    },

    openModalTypesPrint() {
      this.modalTypesPrint = true;
    },

    closeModalTypesPrint() {
      this.modalTypesPrint = false;
    },

    openModalChangeProfessionalScale() {
      this.technicalScalePermission = this.$Permissions.sector.has(
        "consulta.agendamentoCompleto.alterarDadosDiaAgendamentoPeriodoReservaTecnica",
        this.employeeAgendaData.set_id,
      )
        ? 1
        : 0;

      this.normalScalePermission = this.$Permissions.sector.has(
        "consulta.agendamentoCompleto.alterarDadosDia",
        this.employeeAgendaData.set_id,
      )
        ? 1
        : 0;

      if (!this.normalScalePermission) {
        this.$toaster.error(
          "Sem permissão para acessar a escala do profissional.",
          "Usuário sem Permissão.",
        );
        return;
      }

      this.modalChangeProfessionalScale = true;
    },

    closeModalChangeProfessionalScale() {
      this.modalChangeProfessionalScale = false;
    },

    openModalConfirmSchedulingDeletion() {
      if (!this.hasPermissionsToDeleteSchedule()) {
        this.$toaster.error(
          "Sem permissão para excluir um agendamento.",
          "Usuário sem Permissão.",
        );
        return;
      }
      const { enviado } = this.appointmentsData;
      if (enviado === "SIM") {
        this.$toaster.error(
          "A consulta foi enviada para o Pep e não pode ser excluída. Cancele o atendimento para excluir a Consulta.",
        );
        return;
      }
      this.modalConfirmSchedulingDeletion = true;
    },

    closeModalConfirmSchedulingDeletion() {
      this.modalConfirmSchedulingDeletion = false;
    },

    openModalAppointmentDetails() {
      if (!this.hasPermissionsToEditSchedule()) {
        this.$toaster.error(
          "Usuário sem permissão para ver os detalhes da consulta.",
          "Usuário Sem Permissão!",
        );
        return;
      }
      this.modalAppointmentDetails = true;
    },

    closeModalAppointmentDetails() {
      this.modalAppointmentDetails = false;
    },

    cleanForm() {
      this.form.selectUnity = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
      this.form.sector = null;
      this.form.ocp_id = null;
      this.form.cbo = null;
      this.form.employee = null;
      this.form.pes_id = null;
      this.form.vin_id = null;
      this.form.fun_id = null;
      this.sectorInVuex = null;
      this.cbo = null;
      this.filId = null;
      this.appointmentsData = null;
      this.employeeAgendaData = null;
      this.form.tipoPeriodoInicial = null;
      this.form.tipoPeriodoFinal = null;
      this.modalPatientImageInRow = false;
      this.modalTypesPrint = false;
      this.totalAgenda = 0;
      this.mutableEmployeeAgenda = [];
      // #BALBI caso esusng/esusng#3303
      this.form.sector = null;
      this.form.dayEarlySchedule = null;
      this.mutableAppointmentSchedule = {
        data: [],
        total: 0,
      };
      this.$store.commit(
        "Appointment/Schedule/RESET_FILTER_APPOINTMENT_PER_PERIODS",
      );
      this.$store.commit("Appointment/Schedule/UNSELECT_APPOINTMENT_DATA");
      this.defaultFilter();
    },

    async viewAppointmentScheduleVoucher(appointmentID) {
      this.appointmentIdToPrint = appointmentID;
      switch (this.preferences.printOptionsPreference) {
        case "2": // Opções de Impressão
          this.openModalTypesPrint();
          break;
        case "6": // Impressão Normal
          await printScheduleVoucher(this.appointmentIdToPrint);
          break;
        case "7": // Impressora Térmica 1 via
          await printThermalOneWay(this.appointmentIdToPrint);
          break;
        case "8": // Impressora Térmica 2 vias
          await printThermalTwoWay(this.appointmentIdToPrint);
          break;
        default:
          break;
      }
    },

    afterSearchFilter(submitFromButton) {
      if (this.$refs.smartTableAgenda) {
        this.$refs.smartTableAgenda.cleanSelectRow();
      }
      if (this.$refs.smartTableEmployee) {
        this.$refs.smartTableEmployee.cleanSelectRow();
      }
      this.mutableAppointmentSchedule = {
        data: [],
        total: 0,
      };
      this.employeeAgendaData = null;

      if (submitFromButton && this.$refs.smartTableAgenda) {
        this.$refs.smartTableAgenda.setScrollTopZero();
      }
    },

    async fillFilter() {
      try {
        if (this.existFilterData) {
          if (this.existFilterData.pes_id) {
            this.$refs.responsible.forceSelection({
              pes_nome: this.existFilterData.employee,
              vin_id: this.existFilterData.vin_id,
            });
          }

          if (this.existFilterData.cbo) {
            const cboCodeAndName = this.existFilterData.cbo.split(" - ");

            this.$refs.Cbo.forceSelection({
              ocp_codigo: cboCodeAndName[0],
              ocp_nome: cboCodeAndName[1],
            });
          }

          const form = { ...this.form };

          this.form = Object.assign(form, {
            sector: this.existFilterData.sector,
            cbo: this.existFilterData.cbo,
            ocp_id: this.existFilterData.ocp_id,
            employee: this.existFilterData.employee,
            pes_id: this.existFilterData.pes_id,
            initialDay: this.existFilterData.initialDay,
            finalDay: this.existFilterData.finalDay,
            dayEarlySchedule: this.existFilterData.dayEarlySchedule,
            lbl_tipo_periodo_final: this.existFilterData.lbl_tipo_periodo_final,
            lbl_tipo_periodo_inicial: this.existFilterData
              .lbl_tipo_periodo_inicial,
            vin_id: this.existFilterData.vin_id,
            tipoPeriodoInicial: this.existFilterData.tipoPeriodoInicial,
            tipoPeriodoFinal: this.existFilterData.tipoPeriodoFinal,
            selectUnity: this.existFilterData.selectUnity,
          });

          this.sectorInVuex = this.existFilterData.sector;

          await this.$refs.rgsearch.performSearch();

          if (this.selectedEmployee) {
            this.$refs.smartTableAgenda.selectRow(this.selectedEmployee);
            this.selectEmployeeData(this.selectedEmployee, true);
          }
        } else {
          this.defaultFilter();
        }
      } catch (err) {
        this.$toaster.warning("Erro ao carregar os dados da navegação");
      } finally {
        // this.$loader.finish();
      }
    },

    clearSelectionSchedule() {
      this.appointmentsData = null;
      this.$refs.smartTableEmployee.cleanSelectRow();
    },

    validBlockedRetroactiveSendingToPep() {
      const today = moment({ h: 0, m: 0, s: 0 });
      const scheduleDate = moment(
        this.employeeAgendaData.DataConsulta,
        "DD/MM/YYYY",
      );

      if (this.preferences.blockedRetroactiveSendingToPep) {
        return !(scheduleDate < today);
      }

      return true;
    },

    handleErrorChangeProfessionalScale(pData) {
      const CONFLICT_SCALE_EXCEPTION =
        "ConflitoAlteracaoDePeriodoHorarioException";

      const isConflictScaleError = pData.exception === CONFLICT_SCALE_EXCEPTION;

      if (isConflictScaleError) {
        const exceptionDetails = Object.assign({}, pData.detalhes_exececao);
        const hasConflictsData = exceptionDetails.escalas?.length > 0;
        const hasHistoryData = exceptionDetails.historicos?.length > 0;

        this.scaleConflicts = hasConflictsData ? exceptionDetails.escalas : [];

        this.scaleHistory = hasHistoryData ? exceptionDetails.historicos : [];

        this.actionOpenModalScaleConflict();
      } else {
        this.$toaster.error(pData.mensagem);
      }
    },

    actionCloseModalScaleConflict() {
      this.showModalScaleConflict = false;
    },

    actionOpenModalScaleConflict() {
      this.showModalScaleConflict = true;
    },
  },
};
</script>
