<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchExternalSchedulingProcedures from "./action/SearchExternalSchedulingProcedures";
export default {
  name: "RgComboboxExternalSchedulingProcedures",
  mixins: [RgComboboxMixin],
  data: () => {
    return {};
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];

      const data = await SearchExternalSchedulingProcedures();

      data.forEach((item) => {
        this.mData.push({
          value: item.fle_nome_procedimento,
          text: item.label,
        });
      });
    },
  },
};
</script>
