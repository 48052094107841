<style src="./MainMenu.scss" lang="scss" scoped></style>
<template lang="html">
  <section data-id="menu" class="main-menu">
    <IconMenusquares
      v-shortkey="['f10']"
      class="menu-icon"
      @click.stop.prevent.native="openMenu"
      @shortkey.native="openMenu"
    />
    <section v-if="menuOpened" class="big-menu">
      <div class="header">
        <IconClose
          v-shortkey.native="['esc']"
          class="menu-icon"
          @click.stop.prevent.native="closeMenu"
          @shortkey.native="closeMenu"
        />
        <div class="menu-search-container">
          <MenuSearch data-id="busca" @fuse-search="searchWithFuse" />
        </div>
      </div>
      <div v-show="!fuseSearch" class="menu-content">
        <div class="column-modules">
          <span class="title">Módulos</span>
          <ModuleList
            @error="closeMenu"
            @change="setModule"
            @open-link="openLink"
          />
        </div>
        <div class="column-content">
          <div class="row-submodules">
            <span class="title">Submódulos</span>
            <SubmoduleList @change="setSubModule" @open-link="openLink" />
          </div>
          <div class="row-operation">
            <span class="title">Operações</span>
            <OperationList @change="setOperation" @open-link="openLink" />
          </div>
        </div>
      </div>
      <div v-show="fuseSearch" class="menu-content">
        <SearchResult :result-list="fuseSearch" @open-link="openLink" />
      </div>
    </section>
  </section>
</template>

<script>
import { IconMenusquares, IconClose } from "~tokio/primitive/icon";
import MenuSearch from "./menu-search/MenuSearch";
import ModuleList from "./module-list/ModuleList";
import SubmoduleList from "./submodule-list/SubmoduleList";
import OperationList from "./operation-list/OperationList";
import SearchResult from "./search-result/SearchResult";
import ClickOutside from "vue-click-outside";

export default {
  name: "MainMenu",
  components: {
    IconMenusquares,
    IconClose,
    MenuSearch,
    ModuleList,
    SubmoduleList,
    OperationList,
    SearchResult,
  },
  directives: {
    ClickOutside,
  },
  data() {
    return {};
  },
  computed: {
    // change to mapState or MapGetters
    menuOpened() {
      return this.$store.state.MainMenu.menuOpened;
    },
    selectedModule() {
      return this.$store.state.MainMenu.selectedModule;
    },
    selectedSubModule() {
      return this.$store.state.MainMenu.selectedSubModule;
    },
    operation() {
      return this.$store.state.MainMenu.selectedOperation;
    },
    fuseSearch() {
      return this.$store.state.MainMenu.fuseSearch;
    },
  },
  methods: {
    openLink(pLink) {
      if (this.$route.path !== pLink) {
        this.$router.push(pLink);
      } else {
        this.$router.push("/");
        this.$nextTick(() => {
          this.$router.push(pLink);
        });
      }
      this.closeMenu();
    },
    openMenu() {
      this.$store.commit("MainMenu/SET_MENU_OPENED", !this.menuOpened);
    },
    closeMenu() {
      // change to action or multiple mutations ?
      this.$store.commit("Home/DISABLE_FAVORITE_MODE");
      this.$store.commit("MainMenu/CLEAN_MENU");
      this.$store.commit("MainMenu/SET_MENU_OPENED", false);
    },
    searchWithFuse(pFuseSearch) {
      // mutate fuse Sarch
      this.$store.commit("MainMenu/SET_FUSE_SEARCH", pFuseSearch);
    },
    setModule(pModule) {
      this.$store.commit("MainMenu/SET_MODULE", pModule);
      this.$store.commit("MainMenu/SET_SUBMODULE", null);
    },
    setSubModule(pSubModule) {
      this.$store.commit("MainMenu/SET_SUBMODULE", pSubModule);
      this.$store.commit("MainMenu/SET_OPERATION", null);
    },
    setOperation(pOperation) {
      this.$store.commit("MainMenu/SET_OPERATION", pOperation);
    },
  },
};
</script>
