<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchProceduresProfessionalAgreements from "./action/SearchProceduresProfessionalAgreements";

export default {
  name: "RgComboxUnithealth",
  mixins: [RgComboboxMixin],
  props: {
    sectorId: {
      type: Number,
    },
    bondId: {
      type: Number,
    },
    procedureName: {
      type: String,
    },
  },
  watch: {
    bondId() {
      this.fillData();
    },
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];

      if (this.sectorId && this.bondId && this.procedureName) {
        const data = await SearchProceduresProfessionalAgreements({
          intIdSetor: this.sectorId,
          intVusId: this.bondId,
          strNomeProcedimento: this.procedureName,
        });

        data.forEach((item) => {
          if (item.enabled === "1") {
            this.mData.push({
              value: item.data,
              text: item.label,
            });
          }

          if (this.mData.length === 1) {
            this.$emit("selectItem", this.mData[0].value);
          }
        });
      }
    },
  },
};
</script>
