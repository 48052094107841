import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";
export default async (context, { intIdUnidadeSaude, intIdSetor }) => {
  return AuthLegacyRequest.post(
    "exames2/controller-exame-periodo-carencia/listar-exame-carencia-periodo-agendamento",
    {
      intIdUnidadeSaude: intIdUnidadeSaude,
      intIdSetor: intIdSetor,
    },
  )
    .then(({ data }) => {
      data = HandleResponse(data);
      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
