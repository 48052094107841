import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

import { OpenMenuModule } from "~common/utils/app/menu";

import EmergencyBulletinRegistration from "$emergency/bulletin/views/registration/EmergencyBulletinRegistration.vue";
export default {
  path: "/emergency/pre-registration/bulletin",
  name: "emergency.pre-registration.bulletin",
  components: {
    default: EmergencyBulletinRegistration,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Emergência", link: OpenMenuModule("emergency") },
      { label: "Pré-Atendimento", link: "/emergency/pre-registration" },
      {
        label: "Cadastrar Boletim",
        link: "/emergency/pre-registration/bulletin",
      },
    ],
  },
};
