import BaseModel from "~common/model/BaseModel";

class SurgeryKitModel extends BaseModel {
  defineEndPointURI() {
    return "surgery-center/surgery-kit-type";
  }

  getKitsById(pSurgeryId, pSectorId) {
    return new Promise((resolve, reject) => {
      this._request
        .get("/surgery-center/surgery-kit", {
          kic_id_controles_cirurgicos: pSurgeryId,
          set_id: pSectorId,
        })
        .then((pData) => {
          resolve(pData.data || null);
        });
    });
  }
}
export default new SurgeryKitModel();
