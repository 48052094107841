<template>
  <section class="icon-tooltip">
    <div class="ticon-container" :title="titleIcon">
      <div
        v-if="msgLeft"
        class="ticon-message"
        :class="{ 'show-border-right': isShowMsg && msgLeft }"
        @click="actMsg"
      >
        <span
          v-if="isShowMsg"
          :class="{ 'without-auto': maxLengthText }"
          :title="titleMessage"
        >
          {{ message }}
        </span>
      </div>

      <div class="ticon-icon" @click="showOrHideMsg">
        <IconInfoHelper v-if="!iconClose" iconClose class="icon" />
        <div v-if="iconClose" class="icon">
          <IconCloseCircle />
        </div>
      </div>

      <div
        v-if="!msgLeft"
        class="ticon-message"
        :class="{ 'show-border': isShowMsg }"
        @click="actMsg"
      >
        <span
          v-if="isShowMsg"
          :class="{ 'without-auto': maxLengthText }"
          :title="titleMessage"
        >
          {{ message }}
        </span>
      </div>
    </div>
  </section>
</template>

<script>
import { IconInfoHelper, IconCloseCircle } from "~tokio/primitive";
export default {
  name: "IconTooltip",

  components: {
    IconInfoHelper,
    IconCloseCircle,
  },

  props: {
    message: {
      type: String,
      required: true,
    },
    maxLengthText: {
      type: Number,
      default: null,
    },
    iconTitle: {
      type: String,
      default: null,
    },
    actionMsg: {
      type: Function,
      default: null,
    },
    startOpen: Boolean,
    msgLeft: Boolean,
    iconClose: Boolean,
  },

  data() {
    return {
      isShowMsg: false,
    };
  },

  computed: {
    titleMessage() {
      if (this.maxLengthText) {
        return this.message;
      }
      return "";
    },

    titleIcon() {
      if (!this.isShowMsg && this.iconTitle) {
        return this.iconTitle;
      }
      return "";
    },
  },

  mounted() {
    if (this.startOpen) {
      this.showMsg();
    }
  },

  methods: {
    showOrHideMsg() {
      this.isShowMsg = !this.isShowMsg;
    },
    showMsg() {
      this.isShowMsg = true;
    },
    hideMsg() {
      this.isShowMsg = false;
    },

    actMsg() {
      if (this.actionMsg !== null) {
        this.actionMsg();
      }
    },
  },
};
</script>
