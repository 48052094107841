<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchUnithealthExternal from "./action/SearchUnithealthExternal";
export default {
  name: "RgComboboxHealthPlans",
  mixins: [RgComboboxMixin],
  data: () => {
    return {};
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      try {
        this.mData = [];
        const data = await SearchUnithealthExternal({
          arrFiltro: {
            ius_nome: null,
          },
        });
        data.forEach((item) => {
          this.mData.push({ value: item.ius_id, text: item.ius_nome });
        });
      } catch (error) {
        this.$toaster.error(error);
      }
    },
  },
};
</script>
