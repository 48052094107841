<template lang="html">
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="533.333px"
    height="533.333px"
    viewBox="0 0 533.333 533.333"
    style="enable-background: new 0 0 533.333 533.333"
    xml:space="preserve"
  >
    <g>
      <path
        d="M266.667,0C119.391,0,0,119.391,0,266.667c0,147.275,119.391,266.666,266.667,266.666
    c147.275,0,266.667-119.391,266.667-266.666C533.333,119.391,413.942,0,266.667,0z M266.667,466.667c-110.458,0-200-89.543-200-200
    c0-110.458,89.542-200,200-200c110.457,0,200,89.543,200,200C466.667,377.124,377.124,466.667,266.667,466.667z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "GeometryCircle",
};
</script>
