<template>
  <section class="unify-patient">
    <RgSearch
      ref="RgSearch"
      v-model="mutablePatientList"
      :build-filter="generateFilter"
      :search-function="searchFilter"
      :clear-function="cleanForm"
      :item-height="ITEM_HEIGHT"
      :searchAreaHeight="tableHeight"
      :show-footer="false"
      :showEmptyMessage="false"
      result-title="Unificação de Pacientes"
      @afterPerformSearch="afterSearchFilter"
      @count="getCountValue"
    >
      <div slot="filters" class="unify-patient-filter">
        <Tooltip class="tooltip">
          <IconInfoHelper slot="icon" />
          <div slot="content" class="content">
            <div class="title">
              <span>A busca de cadastros utiliza filtros exatos.</span>
              <p>Soluções:</p>
              <ul class="list">
                <li class="item">
                  É possível fazer mais de uma busca para a mesma unificação;
                </li>
                <li class="item">
                  Todos os cadastros contidos na lista branca abaixo serão
                  mantidos;
                </li>
              </ul>
              <p>Exemplo:</p>
              <ul class="list">
                <li class="item">
                  Ao realizar uma busca por “Thiago” selecione os cadastros
                  duplicados;
                </li>
                <li class="item">
                  Se o mesmo paciente tiver cadastro duplicado com o nome
                  “Tiago”, realize uma nova busca e adicione os novos
                  resultados;
                </li>
              </ul>
            </div>
          </div>
        </Tooltip>
        <div class="inputs">
          <RgInput
            v-model="form.patientName"
            :rules="{ fn: validateName }"
            label="Nome do Paciente"
            placeholder="Digite o nome do paciente"
            :maxlength="100"
          />
          <RgInputDate
            v-model="form.birthDate"
            class="date"
            label="Data de Nascimento"
          />
          <RgInput
            v-model="form.mothersName"
            :rules="{ fn: validateName }"
            label="Nome da Mãe"
            placeholder="Digite o nome da mãe"
            :maxlength="100"
          />
          <RgInputMasked
            v-model="form.recordNumber"
            label="Prontuário Único"
            placeholder="0000000000"
            mask="##########"
            type="number"
          />
        </div>
      </div>
      <div class="unify-patient-search">
        <Tooltip>
          <IconLightBulbHelper slot="icon" />
          <div slot="content" class="content">
            <span class="title">
              Para realizar a unificação, selecione os cadastros duplicados
            </span>
          </div>
        </Tooltip>

        <div class="content-grid">
          <div ref="table" class="table">
            <SmartTable
              ref="smartTable"
              name="UnifyPatientSearch"
              :columns="COLUMNS"
              :body="mutablePatientList"
              :total="Number(total)"
              :initial-columns="3"
              :max-register="30"
              :item-per-page="pagination.limit"
              :show-pagination="true"
              toggle-selected
              remove-btn-columns
              @pagination="setPagination"
              @getLine="selectLine"
            />
          </div>

          <div class="selected-patient">
            <div class="top-grid">
              <span class="title">Pacientes para Unificação</span>
              <div class="button">
                <LargeButton
                  v-if="true"
                  label="Unificar Cadastro"
                  backgroundColor="#1E88A9"
                  :disabled="disableUnifyPatient"
                  @click="unifyPatient"
                >
                  <div slot="icon" class="icon">
                    <IconUnify />
                  </div>
                </LargeButton>
              </div>
            </div>
            <div class="patient-grid">
              <div
                v-for="(item, index) in selectedPatient"
                :key="index"
                class="patients"
              >
                <span> {{ item.pes_nome }}</span>
                <span> {{ item.pes_nascimento }} </span>
                <span> {{ item.pes_mae }}</span>
                <button class="remove" @click="removePatientList(index)">
                  x
                </button>
              </div>
            </div>
            <span class="total"
              >Pacientes selecionados: {{ selectedPatient.length }}</span
            >
          </div>
        </div>
      </div>
    </RgSearch>
  </section>
</template>

<script>
import { RgSearch, SmartTable } from "~tokio/foundation";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";
import {
  RgInput,
  RgInputDate,
  Tooltip,
  IconUnify,
  IconLightBulbHelper,
  IconInfoHelper,
  LargeButton,
  RgInputMasked,
} from "~tokio/primitive";

const FILTER = {
  patientName: null,
  birthDate: null,
  mothersName: null,
  recordNumber: null,
};

export default {
  name: "UnifyPatient",

  components: {
    RgSearch,
    SmartTable,
    RgInput,
    RgInputDate,
    Tooltip,
    IconUnify,
    IconLightBulbHelper,
    IconInfoHelper,
    LargeButton,
    RgInputMasked,
  },

  data() {
    return {
      mutablePatientList: [],
      selectedPatient: [],
      total: 0,
      tableHeight: 0,
      form: {
        patientName: "",
        birthDate: null,
        mothersName: "",
        recordNumber: null,
      },
      pagination: {
        limit: 10,
        offset: 0,
        current: 1,
      },
    };
  },

  computed: {
    disableUnifyPatient() {
      return this.selectedPatient.length <= 1;
    },

    hasFilter() {
      return (
        this.form.patientName ||
        this.form.birthDate ||
        this.form.mothersName ||
        this.form.recordNumber
      );
    },

    hasPatientSelected() {
      return this.selectedPatient.length > 0;
    },
  },

  created() {
    this.COLUMNS = [
      { name: "Nome do Paciente", key: "pes_nome", align: "left" },
      { name: "Data de Nascimento", key: "pes_nascimento" },
      { name: "Nome da Mãe", key: "pes_mae", align: "left" },
    ];
    this.ITEM_HEIGHT = 32.1;
  },

  mounted() {
    this.fillFilter();

    this.tableHeight = this.$refs.table.offsetHeight;

    this.cleanPagination();
  },

  beforeDestroy() {
    const validateIfExist = ValidateIfRouteExistInBreadscrumb(
      "/person/patient/unify",
      this.$route.meta.breadcrumb,
    );

    if (!validateIfExist) {
      this.$store.commit("Person/Patient/RESET_FILTER_UNIFY_PATIENT");
      this.$store.commit("Person/Patient/UNSELECT_PATIENTS_UNIFY");
    }
  },

  methods: {
    generateFilter() {
      this.cleanPagination();
      return this.getFilterVariables();
    },

    searchFilter(pData, submitFromButton = true) {
      if (!this.hasFilter && submitFromButton) {
        this.$toaster.warning("Preencha algum filtro para realizar a busca");
        return false;
      }

      if (!this.hasFilter) return;

      try {
        this.$loader.start();

        return this.$store.dispatch(
          "Person/Patient/GET_PATIENT_LIST_UNIFY",
          pData,
        );
      } catch (pErr) {
        this.$toaster.error(pErr);
      } finally {
        this.$loader.finish();
      }
    },

    afterSearchFilter() {
      if (this.$refs.smartTable) {
        this.$refs.smartTable.cleanSelectRow();
        this.$refs.smartTable.setScrollTopZero();
      }
    },

    getCountValue(pValue) {
      this.total = Number(pValue) || 0;
    },

    selectLine(pValue) {
      if (pValue) {
        const existPatient = (patient) => patient.pes_id === pValue.pes_id;
        if (!this.selectedPatient.find(existPatient)) {
          this.selectedPatient.push(pValue);
          return;
        }
      }
      this.$toaster.warning("Paciente já selecionado");
    },

    removePatientList(pIndex) {
      this.selectedPatient.splice(pIndex, 1);
      this.$refs.smartTable.cleanSelectRow();
    },

    unifyPatient() {
      this.$store.commit(
        "Person/Patient/SELECT_PATIENTS_UNIFY",
        this.selectedPatient,
      );
      this.$router.push({ name: "person.patient.unify.register" });
    },

    async setPagination(pPagination) {
      this.pagination = pPagination;

      if (!this.hasFilter) return;

      const variables = this.getFilterVariables();
      const { rows, count } = await this.searchFilter(variables, false);

      this.mutablePatientList = rows;
      this.total = count;
    },

    getFilterVariables() {
      this.$store.commit(
        "Person/Patient/SET_FILTER_UNIFY_PATIENT",
        this.$utils.obj.DeepCopy(this.form),
      );

      return {
        name: this.form.patientName
          ? this.$utils.str.RemoveExtraSpaces(this.form.patientName)
          : null,
        birthday: this.form.birthDate
          ? this.$utils.date.BrazilianDateToDatabase(this.form.birthDate)
          : null,
        mother: this.form.mothersName
          ? this.$utils.str.RemoveExtraSpaces(this.form.mothersName)
          : null,

        recordNumber: this.form.recordNumber ? this.form.recordNumber : null,
        active: true,
        notUnified: true,
        limit: this.pagination.limit,
        offset: this.pagination.offset,
      };
    },

    async fillFilter() {
      try {
        const existFilterData = this.$store.getters[
          "Person/Patient/GET_FILTER_UNIFY_PATIENT"
        ];

        if (existFilterData) {
          this.form.patientName = existFilterData.patientName;
          this.form.birthDate = existFilterData.birthDate;
          this.form.mothersName = existFilterData.mothersName;
          this.form.recordNumber = existFilterData.recordNumber;
          await this.$refs.RgSearch.performSearch();
        }
      } catch (Err) {
        this.$toaster.warning("Erro ao carregar os dados da navegação");
      } finally {
        this.$loader.finish();
      }
    },

    validateName(pValue, pErrors) {
      if (pValue && pValue.length < 3) {
        pErrors.push("Digite ao menos 3 caracteres");
        return false;
      }
    },

    cleanForm() {
      this.form = this.$utils.obj.DeepCopy(FILTER);
      this.mutablePatientList = [];
      this.total = 0;
    },

    cleanPagination() {
      this.pagination.limit =
        Math.floor(this.tableHeight / this.ITEM_HEIGHT) || 10;
      this.pagination.offset = 0;
      this.pagination.current = 1;
    },
  },
};
</script>
