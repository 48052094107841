<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchDestinationPlaceExit from "./action/SearchDestinationPlaceExit";

export default {
  name: "RgComboboxDestinationPlaceExit",
  mixins: [RgComboboxMixin],
  props: {
    label: {
      default: "Local de Destino",
    },
  },
  data: () => {
    return {};
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];
      const data = await SearchDestinationPlaceExit();

      data.forEach((item) => {
        this.mData.push({
          value: item.data,
          text: item.label,
        });
      });
    },
    blur() {
      this.$emit("blur");
      this.validate();
    },
  },
};
</script>
