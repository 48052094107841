import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query {
    documents_attachments {
      rows {
        doa_id
        doa_codigo
        doa_nome
        doa_ativo
      }
      count
    }
  }
`;

export default async (variables) => {
  try {
    const { data } = await GraphQLClient.query({ query, variables });
    return data.documents_attachments.rows;
  } catch (err) {
    throw new Error("Falha ao obter os Tipos de Documento");
  }
};
