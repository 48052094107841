import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query CheckBedTypeProcedure(
    $procedure: String!
    $competence: String!
    $bedType: String!
  ) {
    checkBedTypeProcedure(
      CO_PROCEDIMENTO: $procedure
      DT_COMPETENCIA: $competence
      CO_TIPO_LEITO: $bedType
    )
  }
`;

export default async ({ procedure, competence, bedType }) => {
  if (!procedure || !competence || !bedType) return true;

  const variables = {
    procedure,
    competence,
    bedType: ("" + bedType).padStart(2, "0"),
  };

  const { data } = await GraphQLClient.query({ query, variables }).catch(
    (err) => {
      console.error(err);
    },
  );

  return data.checkBedTypeProcedure;
};
