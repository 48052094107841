<style src="./RgEmployeeSearch.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="rg-employee-search">
    <form
      class="rg-person-search--person-form"
      @submit.prevent="employeeInsertNew"
    >
      <div class="rg-person-search--search-block">
        <IconSearch class="rg-person-search--icon-search" />
        <input
          v-show="showFilter('name')"
          v-model="employeeForm.name"
          v-debounce-directive="200"
          type="search"
          class="rg-person-search--search-input"
          placeholder="Procure por um funcionário, informando nome"
          @debounced="search()"
        />
        <RgSuggestOccupationEsusSearchBar
          v-show="showFilter('occupationId')"
          v-model="suggests.occupation"
          :show-icon="false"
          placeholder="Procure por um Funcionário pela Ocupação"
          @selected="selectOccupation"
        />

        <RgComboboxSpecialtiesSearchBar
          v-show="showFilter('specialtieId')"
          v-model="employeeForm.specialtieId"
          label=""
          placeholder="Procure por um Funcionário pela Especialidade"
          @change="selectSpecialties"
        />

        <RgComboboxEmployeeRelationshipTypeSearchBar
          v-show="showFilter('employeeRelationshipTypeId')"
          v-model="employeeForm.employeeRelationshipTypeId"
          label=""
          placeholder="Procure por um Funcionário por Tipo de Vínculo"
          @change="selectEmployeeRelationshipId"
        />

        <input
          v-show="showFilter('cns')"
          v-model="employeeForm.cns"
          v-mask="'###.####.####.####'"
          v-debounce-directive="200"
          type="search"
          class="rg-person-search--search-input"
          placeholder="Procure por um funcionário, informando Cartão SUS"
          @debounced="search()"
        />
        <input
          v-show="showFilter('cnsab')"
          v-model="employeeForm.cnsab"
          v-mask="'###.####.####.####'"
          v-debounce-directive="200"
          type="search"
          class="rg-person-search--search-input"
          placeholder="Procure por um funcionário, informando Cartão SUS"
          @debounced="search()"
        />
        <input
          v-show="showFilter('cpf')"
          v-model="employeeForm.cpf"
          v-mask="'###.###.###-##'"
          v-debounce-directive="200"
          type="search"
          class="rg-person-search--search-input"
          placeholder="Procure por um funcionário, informando CPF"
          @debounced="search()"
        />
        <button
          :disabled="!$Permissions.global.has('cadastro.pessoa.incluir')"
          class="add-button"
        >
          <div
            v-if="!$Permissions.global.has('cadastro.pessoa.incluir')"
            class="permission-locked-button"
          >
            <IconLocked class="icon-lock-button" />
          </div>
          Inserir Funcionário
        </button>
      </div>
      <ul class="advanced-search">
        <li>
          <div
            :class="[
              'filter',
              { active: showFilter('name') || isFilterActive('name') },
            ]"
            @click.prevent="changeFilter('name')"
          >
            Nome
            <button
              v-if="hasFilter('name')"
              class="clean-filter"
              @click.stop.prevent="removeFilter('name')"
            >
              x
            </button>
          </div>
        </li>
        <li>
          <div
            :class="[
              'filter',
              { active: showFilter('cns') || isFilterActive('cns') },
            ]"
            @click.prevent="changeFilter('cns')"
          >
            CNS
            <button
              v-if="hasFilter('cns')"
              class="clean-filter"
              @click.stop.prevent="removeFilter('cns')"
            >
              x
            </button>
          </div>
        </li>
        <li>
          <div
            :class="[
              'filter',
              { active: showFilter('cnsab') || isFilterActive('cnsab') },
            ]"
            @click.prevent="changeFilter('cnsab')"
          >
            CNS AB
            <button
              v-if="hasFilter('cnsab')"
              class="clean-filter"
              @click.stop.prevent="removeFilter('cnsab')"
            >
              x
            </button>
          </div>
        </li>
        <li>
          <div
            :class="[
              'filter',
              { active: showFilter('cpf') || isFilterActive('cpf') },
            ]"
            @click.prevent="changeFilter('cpf')"
          >
            CPF
            <button
              v-if="hasFilter('cpf')"
              class="clean-filter"
              @click.stop.prevent="removeFilter('cpf')"
            >
              x
            </button>
          </div>
        </li>
        <li>
          <div
            :class="[
              'filter',
              {
                active:
                  showFilter('occupationId') || isFilterActive('occupationId'),
              },
            ]"
            @click.prevent="changeFilter('occupationId')"
          >
            Ocupação
            <button
              v-if="hasFilter('occupationId')"
              class="clean-filter"
              @click.stop.prevent="removeFilter('occupationId')"
            >
              x
            </button>
          </div>
        </li>
        <li>
          <div
            :class="[
              'filter',
              {
                active:
                  showFilter('specialtieId') || isFilterActive('specialtieId'),
              },
            ]"
            @click.prevent="changeFilter('specialtieId')"
          >
            Especialidade
            <button
              v-if="hasFilter('specialtieId')"
              class="clean-filter"
              @click.stop.prevent="removeFilter('specialtieId')"
            >
              x
            </button>
          </div>
        </li>
        <li>
          <div
            :class="[
              'filter',
              {
                active:
                  showFilter('employeeRelationshipTypeId') ||
                  isFilterActive('employeeRelationshipTypeId'),
              },
            ]"
            @click.prevent="changeFilter('employeeRelationshipTypeId')"
          >
            Tipo de Vínculo
            <button
              v-if="hasFilter('employeeRelationshipTypeId')"
              class="clean-filter"
              @click.stop.prevent="removeFilter('employeeRelationshipTypeId')"
            >
              x
            </button>
          </div>
        </li>
      </ul>
    </form>
  </section>
</template>

<script>
import { IconSearch, IconLocked } from "~tokio/primitive/icon";
import { DebounceDirective } from "~tokio/primitive/input/directive/debounce/Debounce";
import RgComboboxSpecialtiesSearchBar from "$employee/common/components/container/combobox/rg-combobox-specialties-search-bar/RgComboboxSpecialtiesSearchBar";
import RgComboboxEmployeeRelationshipTypeSearchBar from "$employee/common/components/container/combobox/rg-combobox-employee-relationship-type-search-bar/RgComboboxEmployeeRelationshipTypeSearchBar";
import RgSuggestOccupationEsusSearchBar from "$employee/common/components/suggest/rg-suggest-occupation-esus-search-bar/RgSuggestOccupationEsusSearchBar";
import { mask } from "vue-the-mask";

export default {
  name: "RgEmployeeSearch",
  components: {
    IconSearch,
    IconLocked,
    RgSuggestOccupationEsusSearchBar,
    RgComboboxSpecialtiesSearchBar,
    RgComboboxEmployeeRelationshipTypeSearchBar,
  },
  directives: { debounceDirective: DebounceDirective, mask },
  props: {
    value: {},
  },
  data() {
    return {
      suggests: {
        occupation: null,
      },
      filters: ["name"],
      currentFilter: "name",
      employeeForm: {
        name: null,
        cns: null,
        cnsab: null,
        cpf: null,
        occupationId: null,
        specialtieId: null,
        employeeRelationshipTypeId: null,
        unitHealthId: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
      },
    };
  },
  methods: {
    employeeInsertNew() {
      this.$emit("employee-insert", this.employeeForm.name);
    },
    search() {
      this.$emit("search", this.employeeForm);
    },
    changeFilter(filterName) {
      this.currentFilter = filterName;
    },
    hasFilter(pFilter) {
      return this.employeeForm[pFilter] !== "";
    },
    showFilter(pFilter) {
      return this.currentFilter === pFilter;
    },

    isFilterActive(pFilter) {
      return this.filters.includes(pFilter);
    },
    removeFilter(pFilter) {
      if (this.employeeForm[pFilter] !== null) {
        this.employeeForm[pFilter] = null;
        if (pFilter === "occupationId") {
          this.suggests.occupation = null;
        }
        this.filters.splice(this.filters.indexOf(pFilter), 1);
        this.search();
      }
    },
    selectOccupation(pData) {
      this.employeeForm.occupationId = pData.source.ocp_id;
      const idx = this.filters.indexOf("occupationId");
      if (idx === -1) {
        this.filters.push("occupationId");
      }
      this.search();
    },
    selectSpecialties(pData) {
      this.employeeForm.specialtieId = pData[0].value;

      const idx = this.filters.indexOf("specialtieId");

      if (idx === -1) {
        this.filters.push("specialtieId");
      }

      this.search();
    },
    selectEmployeeRelationshipId(pData) {
      this.employeeForm.employeeRelationshipTypeId = pData[0].value;

      const idx = this.filters.indexOf("employeeRelationshipTypeId");

      if (idx === -1) {
        this.filters.push("employeeRelationshipTypeId");
      }

      this.search();
    },
  },
};
</script>
