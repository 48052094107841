import Common from "$basic-attention/common/store";
import HomeVisitForm from "$basic-attention/home-visit-form/store";
import HomeRegisterForm from "$basic-attention/home-register-form/store";
import IndividualRegisterForm from "$basic-attention/individual-register/store";
import TransferenceForm from "$basic-attention/transference-form/store";
import ExportSisab from "$basic-attention/export-sisab/store";
import mutations from "./mutations/index";

export default {
  namespaced: true,
  mutations,
  modules: {
    Common,
    HomeVisitForm,
    HomeRegisterForm,
    IndividualRegisterForm,
    TransferenceForm,
    ExportSisab,
  },
};
