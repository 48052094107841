<style src="./RgPdfButton.scss" lang="scss" scoped></style>
<template lang="html">
  <div class="rg-pdf-button">
    <a
      :data-id="dataId"
      :disabled="disabled"
      :title="title"
      :href="sendUrl"
      :class="{
        'rg-pdf-button -small': small,
        'rg-pdf-button -medium': medium,
        'rg-pdf-button -large': large,
        disable: disabled,
      }"
      class="button pdf"
      target="_blank"
    >
      <div class="icon">
        <IconPdfBioslab />
      </div>
    </a>
  </div>
</template>

<script>
import { IconPdfBioslab } from "~tokio/primitive/icon/symbols";

export default {
  name: "RgPdfButton",
  components: {
    IconPdfBioslab,
  },
  props: {
    dataId: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    openUrl: {
      type: Boolean,
      default: false,
    },
    sendUrl: {
      type: String,
      default: "",
    },
    small: {
      type: Boolean,
      default: false,
    },
    medium: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
