<template>
  <div class="quick-hospitalization-details row">
    <form class="modules" @submit.stop.prevent="saveForm">
      <RgValidatorForm ref="validator">
        <FormBase
          :title="isPatientIndigent ? 'Paciente Não Identificado' : 'Paciente'"
          class="form-base"
        >
          <input
            v-model="isPatientIndigent"
            :class="{ disable: blockChangeTypePatient }"
            :disabled="blockChangeTypePatient"
            class="checkboxTypePatient"
            type="checkbox"
            value="1"
            @click="changePatientType"
          />
          <label
            :class="{ disable: blockChangeTypePatient }"
            :disabled="blockChangeTypePatient"
            for="label"
          >
            Paciente não identificado
          </label>
          <br />

          <div v-if="!isPatientIndigent" class="module-patient">
            <div class="content">
              <RgSuggestPatientInformation
                ref="patient"
                v-model="suggestPatient"
                :tabindex="notPatient"
                :class="{ 'disable unselect': disabledPatient }"
                :disabled="disabledPatient"
                :rules="{
                  required: true,
                  forceSelection: true,
                  fn: validateHospitalizationExists,
                }"
                :extra-data="['withoutAddress']"
                class="inputpatient"
                @patientInfo="selectingPatient"
              />
            </div>
          </div>
          <div v-else class="module-indigent">
            <div class="content">
              <RgRadioGenderBlock
                ref="gender"
                v-model="form.indigent.ini_id_sexos"
                :rules="{ required: isPatientIndigent }"
                label="Sexo"
                class="radiogender"
              />

              <RgInputMasked
                ref="age"
                v-model="indigentAge"
                mask="###"
                :rules="{ required: isPatientIndigent }"
                label="Idade Aproximada"
                placeholder="Digite os anos"
                class="indigentinput"
              />

              <RgInput
                ref="description"
                v-model="form.indigent.ini_descricao"
                :rules="{ required: isPatientIndigent }"
                :maxlength="45"
                label="Descrição"
                placeholder="Digite a descrição"
                type="text"
                class="indigentdesc"
              />
            </div>
          </div>
        </FormBase>

        <div
          :class="{ 'disable unselect': disableOrEnableCompanionModule }"
          :disabled="disableOrEnableCompanionModule"
          class="module-companion"
        >
          <FormBase class="form-base" title="Acompanhante">
            <div class="content">
              <div class="halfmodule">
                <RgComboboxCompanionType
                  v-model="
                    form.hospitalization.int_id_internacoes_tipos_responsavel
                  "
                  :class="{
                    'disable unselect': disableOrEnableCompanionModule,
                  }"
                  :disabled="disableOrEnableCompanionModule"
                  label="Tipo de Acompanhante"
                  class="typecompanion"
                />

                <RgInput
                  ref="companionName"
                  v-model="form.companion.via_nome"
                  :rules="{ required: !existTypeCompanion }"
                  :class="{
                    'disable unselect': auxInfo.patient.disabledCompanionName,
                  }"
                  :disabled="auxInfo.patient.disabledCompanionName"
                  :maxlength="100"
                  label="Nome do Acompanhante"
                  placeholder="Digite o nome do acompanhante"
                  class="companioninput"
                />
              </div>

              <div class="module-document">
                <div class="line">
                  <h3 class="titleinfo unselect">Documentos</h3>
                  <div
                    :title="
                      showDocInfo
                        ? 'Mostrar Documentos'
                        : 'Minimizar Documentos'
                    "
                    class="showhide"
                    @click="showDocumentInfo"
                  >
                    <IconArrow
                      :class="{ rotate: !showDocInfo }"
                      class="minimize"
                    />
                  </div>
                </div>

                <div class="documentation">
                  <div class="half-width">
                    <RgComboboxDocumentType
                      v-model="documentType"
                      :class="{
                        'disable unselect':
                          existTypeCompanion || disableOrEnableCompanionModule,
                      }"
                      :disabled="
                        existTypeCompanion || disableOrEnableCompanionModule
                      "
                      label="Tipo de Documento"
                      class="-margin-right-large documentlist"
                      @change="setDocumentType"
                    />

                    <div class="group">
                      <RgDocumentInput
                        ref="documentInput"
                        v-model="dva_numero_documentos"
                        :value-edit="dva_numero_documentos"
                        :class="{ 'disable unselect': disableOrEnableDocument }"
                        :disabled="disableOrEnableDocument"
                        :document-type-id="documentType"
                        class="-margin-right-large document-input"
                        @enable="disableOrEnableDocumentAdd"
                      />

                      <button
                        :class="{ 'disable unselect': !validateAddBtn }"
                        :disabled="!validateAddBtn"
                        data-id="novo"
                        class="btnbody add"
                        type="button"
                        @click="documentListAddItem"
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div class="areatable">
                    <table
                      v-if="form.companion.documents.length > 0"
                      class="table"
                    >
                      <tr class="tr">
                        <th class="th">Tipo de Documento</th>
                        <th class="th">Número do Documento</th>
                        <th class="th">Ações</th>
                      </tr>
                      <tr
                        v-for="(item, indexItem) in form.companion.documents"
                        :key="indexItem"
                        class="tr"
                      >
                        <td class="td">{{ item.dva_nome }}</td>
                        <td class="td">{{ item.dva_numero_documentos }}</td>
                        <td class="td">
                          <div class="btns">
                            <div
                              :class="{
                                'disable unselect': disableOrEnableDocuments,
                              }"
                              :disabled="disableOrEnableDocuments"
                              title="Editar"
                              class="iconbase"
                              type="button"
                              @click="documentListUpdateItem(indexItem)"
                            >
                              <IconPencil class="iconatt" />
                            </div>

                            <div
                              :class="{
                                'disable unselect': disableOrEnableDocuments,
                              }"
                              :disabled="disableOrEnableDocuments"
                              title="Apagar"
                              class="iconbase"
                              type="button"
                              @click="documentListRemoveItem(indexItem)"
                            >
                              <IconGarbage class="iconremove" />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                    <div v-else class="empty">
                      <IconEmpty class="svg" />
                      <p>Não há documentos cadastrados</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </FormBase>
        </div>

        <div
          :class="{ 'disable unselect': disableOrEnableModule }"
          :disabled="disableOrEnableModule"
          class="module-hospitalization"
        >
          <FormBase class="form-base" title="Internação">
            <div class="content">
              <div class="setflex">
                <RgInputDate
                  ref="inputDateEntry"
                  v-model="form.hospitalization.int_data"
                  :rules="{ required: true, fn: verifyDateEntry }"
                  :class="{
                    'disable unselect': disableOrEnableModule || blockUpdate,
                  }"
                  :disabled="disableOrEnableModule || blockUpdate"
                  :max-date="new Date()"
                  placeholder="dd/mm/aaaa"
                  label="Data de Entrada"
                  class="dates -margin-right-small"
                />

                <RgInputHour
                  v-model="form.hospitalization.int_hora"
                  :rules="{ required: true, fn: verifyHourEntry }"
                  :class="{
                    'disable unselect': disableOrEnableModule || blockUpdate,
                  }"
                  :disabled="disableOrEnableModule || blockUpdate"
                  label="Hora de Entrada"
                  placeholder="HH:MM"
                  class="hours -margin-right-small"
                />

                <RgInputDate
                  ref="inputDateDischarge"
                  v-model="form.hospitalization.int_previsao_alta"
                  :rules="{ fn: verifyDateDischarge }"
                  :disabled="disableOrEnableModule"
                  :min-date="new Date()"
                  placeholder="dd/mm/aaaa"
                  label="Previsão de alta"
                  class="dates -margin-right-small"
                />

                <RgComboboxSituationBed
                  ref="situationBed"
                  v-model="form.hospitalization.int_ultima_situacao_internacao"
                  :class="{
                    'disable unselect':
                      disableOrEnableModule || fromMapBed || isNotReservation,
                  }"
                  :disabled="
                    disableOrEnableModule || fromMapBed || isNotReservation
                  "
                  :rules="{ required: true }"
                  :fromHospitalization="!fromMapBed"
                  class="situation -margin-right-small"
                />

                <RgSuggestTypeBed
                  ref="typeBed"
                  v-model="selectedTypeBed"
                  :rules="{ required: true, forceSelection: true }"
                  :class="{
                    'disable unselect': disableOrEnableModule || blockUpdate,
                  }"
                  :disabled="disableOrEnableModule || blockUpdate"
                  :inlId="form.hospitalization.inl_id"
                  :typeBedIds="form.hospitalization.ids_tipos_do_leito"
                  class="type-bed"
                  @selected="selectingBedType"
                />
              </div>

              <div class="setflex">
                <RgComboboxHospitalizationUnithealth
                  ref="unitHealth"
                  v-model="uns_id"
                  :class="{
                    'disable unselect':
                      disableOrEnableModule ||
                      disableUnitySectorLocale ||
                      blockUpdate ||
                      fromMapBed,
                  }"
                  :disabled="
                    disableOrEnableModule ||
                    disableUnitySectorLocale ||
                    blockUpdate ||
                    fromMapBed
                  "
                  :rules="{ required: true }"
                  :from-map-bed="fromMapBed"
                  :bed-type-id="form.hospitalization.itl_id"
                  :inl-id="form.hospitalization.inl_id"
                  :current-situation="getSituationBed"
                  :permission="SECTOR_PERMISSION"
                  :uns-id="unitHealthId"
                  class="unity -margin-right-small"
                />

                <RgComboboxHospitalizationSector
                  ref="sector"
                  v-model="set_id"
                  :disabled="
                    disableOrEnableModule ||
                    disableOrEnableSector ||
                    blockUpdate ||
                    fromMapBed ||
                    disableUnitySectorLocale
                  "
                  :class="{
                    'disable unselect':
                      disableOrEnableModule ||
                      disableOrEnableSector ||
                      blockUpdate ||
                      fromMapBed ||
                      disableUnitySectorLocale,
                  }"
                  :unit-health="uns_id"
                  :rules="{ required: true }"
                  :from-map-bed="fromMapBed"
                  :bed-type-id="form.hospitalization.itl_id"
                  :inl-id="form.hospitalization.inl_id"
                  :current-situation="getSituationBed"
                  :permission="SECTOR_PERMISSION"
                  class="sector -margin-right-small"
                />

                <RgComboboxHospitalizationPlace
                  ref="locale"
                  v-model="lca_id"
                  :disabled="
                    disableOrEnableModule ||
                    disableOrEnablePlace ||
                    blockUpdate ||
                    fromMapBed ||
                    disableUnitySectorLocale
                  "
                  :class="{
                    'disable unselect':
                      disableOrEnableModule ||
                      disableOrEnablePlace ||
                      blockUpdate ||
                      fromMapBed ||
                      disableUnitySectorLocale,
                  }"
                  :sector="set_id"
                  :rules="{ required: true }"
                  :from-map-bed="fromMapBed"
                  :bed-type-id="form.hospitalization.itl_id"
                  :inl-id="form.hospitalization.inl_id"
                  :current-situation="getSituationBed"
                  class="place -margin-right-small"
                />

                <RgSuggestHospitalizationBed
                  ref="bed"
                  v-model="bed"
                  :local-id="localId"
                  :rules="{ required: true, forceSelection: true }"
                  :class="{
                    'disable unselect':
                      disableOrEnableModule ||
                      disableOrEnableBed ||
                      blockUpdate ||
                      fromMapBed ||
                      disabledBed,
                  }"
                  :disabled="
                    disableOrEnableModule ||
                    disableOrEnableBed ||
                    blockUpdate ||
                    fromMapBed ||
                    disabledBed
                  "
                  :empty-bed="!blockUpdate"
                  :bedType="bedType"
                  class="bed -margin-right-small"
                  @selected="selectingHospitalizationBed"
                />

                <RgSuggestEmployee
                  ref="responsible"
                  v-model="responsibleEmployee"
                  :rules="{ required: true, forceSelection: true }"
                  :sector-required="true"
                  :sector="set_id"
                  :class="{
                    'disable unselect':
                      disableOrEnableEmployeesResponsible || blockUpdate,
                  }"
                  :disabled="disableOrEnableEmployeesResponsible || blockUpdate"
                  data-id="profissional-responasvel"
                  data-item="profissional-responasvel"
                  placeholder="Digite o nome do profissional responsável"
                  label="Profissional Responsável pelo Setor"
                  class="responsible-employee"
                  @selected="selectingResponsibleEmployee"
                />
              </div>

              <div class="setflex">
                <RgComboboxHospitalizationModality
                  ref="modality"
                  v-model="form.hospitalization.int_id_internacoes_modalidade"
                  :rules="{ required: true }"
                  :disabled="disableOrEnableModule"
                  class="modality -margin-right-small"
                  label="Modalidade:"
                />

                <RgComboboxHospitalizationServiceCharacter
                  ref="character"
                  v-model="form.hospitalization.int_id_internacoes_caracteres"
                  :disabled="disableOrEnableModule"
                  :rules="{ required: true }"
                  class="character -margin-right-small"
                />

                <RgComboboxHealthPlan
                  ref="plan"
                  v-model="form.hospitalization.int_id_planos_saude"
                  :class="{
                    'disable unselect': disableOrEnableModule,
                  }"
                  :disabled="disableOrEnableModule"
                  :rules="{ required: true }"
                  :modId="getHealthPlanModule"
                  :unitHealth="uns_id"
                  class="health-plan -margin-right-small"
                  label="Convênio"
                  @change="cleanProcedure"
                />
                <RgComboboxHospitalizationType
                  v-model="
                    form.hospitalization.int_id_internacoes_tipos_internacao
                  "
                  :disabled="disableOrEnableModule"
                  class="hospitalization-type"
                />
              </div>
              <div class="setflex">
                <RgSuggestHospitalizationProcedureCid
                  ref="procedures"
                  v-model="suggestHospitalizationProcedures"
                  :unit-id="uns_id"
                  :patient-gender="gender"
                  :health-plan="healthPlan"
                  :cid-code="cidCode"
                  :rules="{ required: true, forceSelection: true }"
                  :class="{
                    'disable unselect':
                      disableOrEnableModule || disableOrEnableProcedureCid,
                  }"
                  :disabled="
                    disableOrEnableModule || disableOrEnableProcedureCid
                  "
                  data-id="procedimento"
                  data-item="procedimento-item"
                  placeholder="Digite o procedimento"
                  label="Procedimento Solicitado"
                  class="procedure-requester -margin-right-small"
                  @selected="selectingProcedure"
                />

                <RgSuggestCid
                  ref="mainCid"
                  v-model="mainCid"
                  :rules="{ required: true, forceSelection: true }"
                  :disabled="
                    (disableOrEnableModule || disableOrEnableProcedureCid) &&
                    validateFieldsPreference
                  "
                  :class="{
                    'disable unselect':
                      (disableOrEnableModule || disableOrEnableProcedureCid) &&
                      validateFieldsPreference,
                  }"
                  :patient-gender="gender"
                  :procedureCode="procedureCode"
                  data-id="CID"
                  label="CID Principal"
                  class="cid"
                  @selected="selectingCidCode"
                />
              </div>
              <div class="setflex">
                <RgSuggestCboProcedure
                  ref="cbo"
                  v-model="cboName"
                  :class="{
                    'disable unselect':
                      (disableOrEnableModule || !hasProcedure) &&
                      validateFieldsPreference,
                  }"
                  :disabled="
                    (disableOrEnableModule || !hasProcedure) &&
                    validateFieldsPreference
                  "
                  :rules="{ required: true, forceSelection: true }"
                  :procedureCode="procedureCode"
                  label="Ocupação"
                  class="occupation -margin-right-small"
                  placeholder="Digite a ocupação"
                  @selected="selectingCbo"
                />
                <RgSuggestEmployee
                  ref="requester"
                  v-model="requesterEmployee"
                  :rules="{ required: true, forceSelection: true }"
                  :class="{
                    'disable unselect':
                      disableOrEnableModule || disableOrEnableEmployeesRequest,
                  }"
                  :disabled="
                    disableOrEnableModule || disableOrEnableEmployeesRequest
                  "
                  :ocuppation-id="form.hospitalization.int_id_ocupacoes_cbo"
                  data-id="profissional-solicitante"
                  data-item="profissional-solicitante"
                  placeholder="Digite o nome do profissional solicitante"
                  label="Profissional Solicitante"
                  class="requester-employee"
                  @selected="selectingRequestEmployee"
                />
              </div>

              <div class="setflex">
                <RgTextArea
                  ref="textArea"
                  v-model="form.hospitalization.int_obs"
                  :disabled="disableOrEnableModule"
                  :maxlength="500"
                  label="Observação"
                  class="textarea"
                />
              </div>
            </div>
          </FormBase>
        </div>
      </RgValidatorForm>
    </form>

    <ModalConfirmFieldClearing
      ref="confirmClearing"
      :show="modalConfirmFieldClearing"
      @getYes="getCleanFormMethod"
      @getOut="closeModalConfirmFieldClearing"
    />
  </div>
</template>

<script>
import moment from "moment";
import { isNumber } from "lodash";
import FormBase from "~tokio/foundation/form-base/FormBase";
import {
  GetGQLEnum,
  BedSituations,
} from "$hospitalization/common/component/util/GetTypeBedValues";
import HealthPlanModule from "$hospitalization/common/component/util/HealthPlanModuleValues";

import { AlertError, toast } from "~tokio/primitive/notification";
import RgTextArea from "~tokio/primitive/input/rg-text-area/RgTextArea";
import {
  RgSuggestPatientInformation,
  RgInput,
  RgInputMasked,
  RgValidatorForm,
  RgSuggestEmployee,
  RgInputDate,
  RgInputHour,
  IconGarbage,
  IconEmpty,
  IconPencil,
  IconArrow,
  ModalConfirmFieldClearing,
  RgSuggestCid,
} from "~tokio/primitive";
import { RgComboboxHealthPlan } from "$patient/common/components";
import RgRadioGenderBlock from "$person/common/components/radio/rg-radio-gender-block/RgRadioGenderBlock";
import RgComboboxHospitalizationModality from "$billing/hospitalization-billing/common/component/combobox/rg-combobox-hospitalization-modality/RgComboboxHospitalizationModality";
import {
  RgSuggestHospitalizationProcedureCid,
  RgComboboxHospitalizationServiceCharacter,
  RgComboboxCompanionType,
  RgComboboxSituationBed,
  RgComboboxDocumentType,
  RgSuggestTypeBed,
  RgDocumentInput,
  RgComboboxHospitalizationType,
  RgSuggestHospitalizationBed,
  RgComboboxHospitalizationUnithealth,
  RgComboboxHospitalizationSector,
  RgComboboxHospitalizationPlace,
} from "../../../common/component";

import RgSuggestCboProcedure from "$hospitalization/common/component/suggest/rg-suggest-cbo-procedure/RgSuggestCboProcedure";
import {
  mountPatientInfo,
  mountProcedureInfo,
  mountEmployeeInfo,
  mountHospitalization,
  mountCompanionInfo,
  mountIndigent,
} from "./helpers/PrepareData";
import ReasonsBlockedPatient from "./helpers/ReasonsBlockedPatient";
import TypeBedOperationEnum from "$hospitalization/common/component/util/TypeBedOperationEnum";
import SearchPatientHospitalized from "../../store/actions/SearchPatientHospitalized";
import { mapGetters } from "vuex";
export default {
  name: "HospitalizationDetails",
  components: {
    RgSuggestPatientInformation,
    RgInput,
    RgInputMasked,
    FormBase,
    RgComboboxCompanionType,
    RgComboboxSituationBed,
    RgComboboxDocumentType,
    RgComboboxHospitalizationPlace,
    RgComboboxHospitalizationUnithealth,
    RgComboboxHospitalizationSector,
    RgComboboxHospitalizationServiceCharacter,
    RgComboboxHospitalizationType,
    RgInputHour,
    RgInputDate,
    RgSuggestHospitalizationBed,
    RgComboboxHospitalizationModality,
    RgRadioGenderBlock,
    RgComboboxHealthPlan,
    IconGarbage,
    IconPencil,
    RgSuggestCboProcedure,
    RgSuggestEmployee,
    RgSuggestCid,
    RgTextArea,
    IconEmpty,
    RgDocumentInput,
    RgSuggestHospitalizationProcedureCid,
    RgValidatorForm,
    RgSuggestTypeBed,
    IconArrow,
    ModalConfirmFieldClearing,
  },
  props: {
    hospitalizationId: {
      type: Number,
      default: 0,
    },
    hospitalization: {
      type: Object,
      default: () => {},
    },
    operation: {
      type: Number,
      default: 0,
    },
    status: {
      type: Number,
      default: 0,
    },
    fromMapBed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        patient: {
          pac_id: null,
        },
        hospitalization: {
          int_id: null,
          int_id_planos_saude: null,
          int_id_pacientes_internacao: null,
          int_id_filas_internacao: null,
          int_id_internacoes_tipos_responsavel: null,
          int_id_internacoes_tipos_internacao: null,
          int_id_ocupacoes_cbo: null,
          int_id_funcionarios_responsavel: null,
          int_id_funcionarios: null,
          int_id_internacoes_modalidade: null,
          int_id_cid10: null,
          int_id_internacoes_procedimentos: null,
          int_id_internacoes_caracteres: null,
          int_previsao_alta: null,
          int_data: moment().format("DD/MM/YYYY"),
          int_hora: moment().format("HH:mm"),
          int_ultima_situacao_internacao: null,
          int_obs: null,
          inl_id: null,
          itl_id: 0,
        },
        companion: {
          via_id: null,
          via_nome: null,
          documents: [],
        },
        indigent: {
          ini_id: null,
          ini_descricao: null,
          ini_id_sexos: null,
          ini_data_aproximada: null,
        },
        is_indigent: false,
        ihs_id: null,
      },
      auxInfo: {
        patient: {
          pes_mae: null,
          pes_pai: null,
          disabledCompanionName: false,
        },
      },
      patientInfo: {},
      patientCurrentGender: null,
      indigentAge: null,
      mainCid: null,
      cid_codigo: null,
      selectedTypeBed: null,
      requesterEmployee: null,
      responsibleEmployee: null,
      suggestPatient: null,
      suggestHospitalizationProcedures: null,
      cboCode: null,
      cboName: null,
      documentTypeSelected: {
        dva_id_documentos: null,
        dva_nome: null,
      },
      isl_id: 0,
      bed: null,
      lastStatusBed: null,
      documentType: null,
      dva_numero_documentos: null,
      editMode: false,
      editIndex: null,
      disableModule: false,
      disableLocale: false,
      validateAddBtn: false,
      showModalHistoryPatient: false,
      showDocInfo: false,
      disabledPatient: false,
      cns_required: false,
      procedure: {
        vl_idade_minima: "",
        vl_idade_maxima: "",
      },
      isPatientIndigent: false,
      blockChangeTypePatient: false,
      modalConfirmFieldClearing: false,
      modalConfirmDefault: false,
      patientIsHospitalized: false,
      uns_id: 0,
      set_id: null,
      lca_id: null,
      inp_novo_codigo_procedimento: null,
      isNotReservation: false,
      procedureHasCid: 0,
    };
  },
  computed: {
    ...mapGetters({
      lastPersonIdSaved: "Person/Patient/GET_LAST_PERSON_ID_SAVE",
    }),
    unitHealthId() {
      return this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    },
    existTypeCompanion() {
      return !(
        this.form.hospitalization.int_id_internacoes_tipos_responsavel &&
        this.form.hospitalization.int_id_internacoes_tipos_responsavel > 0
      );
    },
    localId() {
      const isValidLocalId = this.lca_id > 0;
      return isValidLocalId ? this.lca_id : 0;
    },
    bedType() {
      return this.form.hospitalization.itl_id;
    },
    disableOrEnableModule() {
      const isPatient =
        this.patientInfo.pes_id &&
        this.suggestPatient &&
        !this.isPatientIndigent;
      const isIndigent = this.isPatientIndigent;
      return !(isPatient || isIndigent);
    },
    disableOrEnableCompanionModule() {
      const isPatient =
        this.patientInfo.pes_id &&
        this.suggestPatient &&
        !this.isPatientIndigent;
      return !isPatient;
    },
    hasUnitHealth() {
      return this.uns_id;
    },
    hasHealthPlan() {
      const healthPlan = this.form.hospitalization.int_id_planos_saude;
      return healthPlan && isNumber(healthPlan);
    },
    hasProcedure() {
      return this.suggestHospitalizationProcedures;
    },
    gender() {
      return this.form.indigent.ini_id_sexos || this.patientInfo.sex_sigla;
    },
    healthPlan() {
      return this.form.hospitalization.int_id_planos_saude
        ? Number(this.form.hospitalization.int_id_planos_saude)
        : null;
    },
    hasGender() {
      return this.gender ? this.gender : false;
    },
    disableOrEnableSector() {
      return !this.uns_id;
    },
    disableOrEnablePlace() {
      return !this.set_id;
    },
    disableOrEnableBed() {
      return !this.lca_id;
    },
    disableOrEnableDocument() {
      return !this.documentType;
    },
    blockUpdate() {
      return (
        this.form.hospitalization.int_id && this.form.hospitalization.int_id > 0
      );
    },
    notPatient() {
      if (!this.form.indigent.ini_id) {
        return (
          this.form.hospitalization &&
          (!this.form.hospitalization.int_id || !this.form.patient.pac_id)
        );
      }
      return false;
    },
    disableOrEnableDocuments() {
      return this.form.indigent.ini_id;
    },
    formatDate() {
      return moment(this.patientInfo.pes_nascimento).format("DD/MM/YYYY");
    },
    disableOrEnableProcedureCid() {
      return !this.hasUnitHealth || !this.hasGender || !this.hasHealthPlan;
    },
    disableOrEnableWithCbo() {
      return !(this.form.hospitalization.int_id_ocupacoes_cbo && this.cboCode);
    },
    disableOrEnableEmployeesRequest() {
      return !(this.form.hospitalization.int_id_ocupacoes_cbo && this.cboCode);
    },
    disableOrEnableEmployeesResponsible() {
      return !this.set_id;
    },
    getSituationBed() {
      const situation = this.status ? this.status : this.isl_id;
      return GetGQLEnum(situation);
    },
    getHealthPlanModule() {
      return HealthPlanModule.INTERNACAO;
    },
    disabledModalPatientHistory() {
      return !this.form.patient.pac_id;
    },
    disabledBed() {
      return !(this.uns_id && this.set_id && this.lca_id);
    },
    disableUnitySectorLocale() {
      if (!this.fromMapBed) {
        return !this.form.hospitalization.itl_id;
      }
      return false;
    },
    hasPersonSelected() {
      return !!this.patientInfo.pes_id;
    },
    procedureCode() {
      return this.procedureHasCid > 0 && this.validateFieldsPreference
        ? this.inp_novo_codigo_procedimento
        : null;
    },
    cidCode() {
      return this.cid_codigo && this.validateFieldsPreference
        ? this.cid_codigo
        : null;
    },
    validateFieldsPreference() {
      const validatePreference = this.$store.getters["Login/GET_PREFERENCES"][
        "tViewCadastroInternacoes.rbt_validar_dados_internacao"
      ];
      return Boolean(Number(validatePreference)) || false;
    },
  },
  watch: {
    set_id(pValue, pPrev) {
      if (
        ((pPrev !== null && pValue !== pPrev) || !pValue) &&
        !this.form.hospitalization.int_id &&
        !this.fromMapBed
      ) {
        this.lca_id = null;
        this.requesterEmployee = "";
        this.lastStatusBed = null;
        this.bed = null;
        this.form.hospitalization.inl_id = null;
        this.form.hospitalization.int_id_funcionarios = null;
        this.responsibleEmployee = "";
        this.form.hospitalization.int_id_funcionarios_responsavel = null;
      }
    },

    "form.patient.pac_id"(pValue) {
      if (!pValue) {
        this.form.companion.via_id = null;
        this.form.companion.via_nome = "";
        this.form.companion.documents = [];
        this.form.hospitalization.int_id_internacoes_tipos_responsavel = null;
      }
    },

    isPatientIndigent(pValue) {
      if (pValue) {
        this.cleanPerson();
      } else {
        this.cleanIndigent();
      }

      this.form.is_indigent = pValue;
      this.showDocInfo = !pValue;
    },

    uns_id(pValue, pPrev) {
      if (
        (!pValue || (pPrev && pValue !== pPrev)) &&
        !this.form.hospitalization.int_id &&
        !this.fromMapBed
      ) {
        this.set_id = null;
        this.lca_id = null;
        this.lastStatusBed = null;
        this.bed = null;
        this.form.hospitalization.inl_id = null;
        this.cleanProcedure();
      }
    },

    lca_id(pValue, pPrev) {
      if (
        (!pValue || (pPrev && pValue !== pPrev)) &&
        !this.form.hospitalization.int_id &&
        !this.fromMapBed
      ) {
        this.bed = null;
        this.lastStatusBed = null;
        this.form.hospitalization.inl_id = null;
      }
    },

    suggestHospitalizationProcedures(pValue) {
      if (!pValue) {
        this.cleanProcedureRelatedInfo();
      }
    },

    "form.hospitalization.int_id_internacoes_tipos_responsavel"(pValue, pPrev) {
      if (!pValue) {
        this.$nextTick(async () => {
          this.auxInfo.patient.disabledCompanionName = true;
          this.form.hospitalization.int_id_internacoes_tipos_responsavel = null;
          this.$refs.companionName.cleanValidate();
        });
      }
      if (!pValue || (pPrev && pValue !== pPrev)) {
        this.form.companion.documents = [];
        this.form.companion.via_id = null;
        this.form.companion.via_nome = "";
        this.cleanDocumentInfo();
      }

      const patientHasMother = this.auxInfo.patient.pes_mae;
      const patientHasFather = this.auxInfo.patient.pes_pai;
      if (pValue === 1 && patientHasMother) {
        this.form.companion.via_nome = this.auxInfo.patient.pes_mae;
        this.auxInfo.patient.disabledCompanionName = true;
      } else if (pValue === 2 && patientHasFather) {
        this.form.companion.via_nome = this.auxInfo.patient.pes_pai;
        this.auxInfo.patient.disabledCompanionName = true;
      } else {
        this.auxInfo.patient.disabledCompanionName = false;
      }
    },

    "form.indigent.ini_id_sexos"(pValue) {
      if (this.isPatientIndigent && this.patientCurrentGender !== pValue) {
        this.cleanProcedure();
      }
      if (pValue) {
        this.patientCurrentGender = pValue;
      }
    },

    "form.hospitalization.int_id_ocupacoes_cbo"(pValue) {
      if (!pValue) {
        this.requesterEmployee = "";
        this.form.hospitalization.int_id_funcionarios = null;
        this.$refs.requester.cleanValidate();
      }
    },

    "form.hospitalization.int_previsao_alta"(pValue) {
      if (!pValue) {
        this.form.hospitalization.int_previsao_alta = null;
      }
    },

    indigentAge(pValue) {
      const isValidAge = pValue > 0;
      let date = null;

      if (isValidAge) {
        const actualYear = moment().format("YYYY");
        const bornYear = parseInt(actualYear) - parseInt(pValue);
        date = `01/01/${bornYear}`;
      }

      this.form.indigent.ini_data_aproximada = date;
    },

    async hospitalizationId(pValue) {
      if (pValue && this.status !== 1) {
        const pData = await this.$store.dispatch(
          "Hospitalization/Hospitalizations/SEARCH_HOSPITALIZATION",
          { int_id: pValue },
        );

        this.setData(pData.rows[0]);
      }
    },
  },
  created() {
    this.SECTOR_PERMISSION = this.fromMapBed ? 351 : 302;
  },
  mounted() {
    let pData = this.$route.params.form;

    if (!pData) {
      pData = this.$store.getters[
        "Hospitalization/Hospitalizations/GET_HOSPITALIZATION_INFO"
      ];
    }

    this.setData(pData);
  },
  beforeDestroy() {
    this.resetHospitalizationInfo();
  },
  methods: {
    async setData(pData) {
      this.resetHospitalizationInfo();
      try {
        this.$loader.start();
        const canMountData = (pData && pData.int_id) || false;

        if (canMountData || this.fromMapBed) {
          this.$store.commit(
            "Hospitalization/Hospitalizations/SET_HOSPITALIZATION_INFO",
            { ...pData },
          );

          this.isl_id = pData.isl_id;
          this.form.hospitalization.inl_id = pData.inl_id;
          this.form.hospitalization.itl_id = pData.itl_id;
          this.selectedTypeBed = pData.itl_nome;
          this.$refs.typeBed.forceSelection(pData);
          this.uns_id = pData.uns_id;
          this.set_id = pData.set_id;
          this.lca_id = pData.lca_id;

          this.isPatientIndigent = false;
          this.form.hospitalization = mountHospitalization(pData);

          this.isNotReservation =
            this.form.hospitalization.int_id &&
            this.form.hospitalization.int_ultima_situacao_internacao !==
              BedSituations.RESERVA;

          const indigent = mountIndigent(pData);
          if (canMountData || !this.fromMapBed) {
            const patient = mountPatientInfo(pData);
            if (indigent.ini_id || !patient) {
              this.isPatientIndigent = true;
              this.form.indigent = indigent.info;
              this.form.indigent.ini_id_sexos = indigent.info.ini_id_sexos;
              this.patientCurrentGender = indigent.info.ini_id_sexos;
            } else if (patient.pes_id) {
              this.$refs.patient.fillPatientById(patient.pes_id);
              this.disabledPatient = true;
              this.isPatientIndigent = false;
              this.blockChangeTypePatient = true;
            }
          }

          this.form.companion = mountCompanionInfo(pData);

          const procedure = mountProcedureInfo(pData);

          this.suggestHospitalizationProcedures =
            pData.inp_novo_nome_procedimento;

          await this.$refs.procedures.forceSelection(procedure);

          this.mainCid = pData.cid_nome;

          const cid = {
            cid_codigo: pData.cid_codigo,
            cid_nome: pData.cid_nome,
          };

          await this.$refs.mainCid.forceSelection(cid);

          this.form.hospitalization.int_id_cid10 = pData.int_id_cid10;

          const employeeRequesterInfo = await this.getEmployeeInfo(
            pData.int_id_funcionarios,
          );
          const employeeRequester = mountEmployeeInfo(
            pData,
            employeeRequesterInfo,
            1,
          );
          if (this.$refs.requester) {
            this.$refs.requester.forceSelection(employeeRequester);
          }

          const employeeResponsibleInfo = await this.getEmployeeInfo(
            pData.int_id_funcionarios_responsavel,
          );
          const employeeResponsible = mountEmployeeInfo(
            pData,
            employeeResponsibleInfo,
            2,
          );
          if (this.$refs.responsible) {
            this.$refs.responsible.forceSelection(employeeResponsible);
          }
          if (pData.ocp_codigo && pData.ocp_nome) {
            this.cboCode = pData.ocp_codigo;
            this.cboName = pData.ocp_codigo + " - " + pData.ocp_nome;
          }

          const cbo = {
            ocp_codigo: pData.ocp_codigo,
            ocp_nome: pData.ocp_nome,
          };
          if (this.$refs.cbo) {
            this.$refs.cbo.forceSelection(cbo);
          }
          this.form.hospitalization.int_id_ocupacoes_cbo =
            pData.int_id_ocupacoes_cbo;

          this.bed = pData.inl_nome;
          if (this.$refs.bed) {
            this.$refs.bed.forceSelection(pData.inl_nome);
          }
          if (this.$refs.gender) {
            this.$refs.gender.selectedGender = this.form.indigent.ini_id_sexos;
          }

          if (this.operation) {
            this.form.hospitalization.int_ultima_situacao_internacao = this.operation;
          }

          this.indigentAge = indigent && indigent.age;
        }

        if (this.lastPersonIdSaved) {
          this.setPatientFromRegister();
        }
      } catch (Err) {
        console.log(Err);
      } finally {
        this.$loader.finish();
      }
    },
    async setPatientFromRegister() {
      await this.$refs.patient.fillPatientById(this.lastPersonIdSaved);
      this.isPatientIndigent = false;
    },
    formatDataBR(pValue) {
      if (!pValue) return "";

      return this.$utils.date.BrazilianDateFormat(pValue);
    },
    async getEmployeeInfo(pFunId) {
      const hasEmployeeId = pFunId > 0;
      if (hasEmployeeId) {
        const extraInfoEmployee = await this.$store.dispatch(
          "Employee/GET_EMPLOYEE_RELATIONSHIP_LIST_BY_EMPLOYEE_ID",
          {
            criterias: {
              employeeId: Number(pFunId),
            },
          },
        );

        return extraInfoEmployee[0];
      }
    },

    isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    formatData() {
      const data = this.$utils.obj.DeepCopy(this.$data);

      data.form.uns_id = data.uns_id;
      data.form.set_id = data.set_id;
      data.form.lca_id = data.lca_id;

      if (data.form.hospitalization.int_previsao_alta) {
        data.form.hospitalization.int_previsao_alta = this.$utils.date.BrazilianDateToDatabase(
          data.form.hospitalization.int_previsao_alta,
        );
      }
      data.form.hospitalization.int_data = this.$utils.date.BrazilianDateToDatabase(
        data.form.hospitalization.int_data,
      );

      data.form.indigent.ini_data_aproximada = this.$utils.date.BrazilianDateToDatabase(
        data.form.indigent.ini_data_aproximada,
      );

      data.form.indigent.ini_descricao = this.$utils.str.RemoveExtraSpaces(
        data.form.indigent.ini_descricao,
      );

      if (!data.form.companion.via_id) {
        data.form.companion.via_nome = this.$utils.str.RemoveExtraSpaces(
          data.form.companion.via_nome,
        );
      } else {
        data.form.companion.via_nome = null;
      }

      data.form.hospitalization.int_obs = this.$utils.str.RemoveExtraSpaces(
        data.form.hospitalization.int_obs,
      );

      data.form.indigent.ini_id_sexos =
        data.form.indigent.ini_id_sexos === "M" ? 1 : 2;

      if (
        this.form.hospitalization.int_ultima_situacao_internacao ===
        BedSituations.RESERVA
      ) {
        data.form.hospitalization.ihs_tipo_operacao =
          TypeBedOperationEnum.RESERVA;
      } else {
        data.form.hospitalization.ihs_tipo_operacao =
          TypeBedOperationEnum.INTERNACAO;
      }

      data.form.pes_id = this.patientInfo.pes_id;

      delete data.form.hospitalization.ids_tipos_do_leito;
      delete data.form.companion.via_id;

      return data;
    },

    async saveForm(pError) {
      if (!(await this.isFormValid())) {
        return AlertError("Verifique os Campos");
      } else if (
        !this.form.hospitalization.int_id &&
        this.lastStatusBed === 3
      ) {
        return AlertError(
          "Por favor selecione outro leito para internar o paciente. Situação do leito: OCUPADO",
        );
      } else if (
        !this.isPatientIndigent &&
        this.form.patient.pac_id &&
        !this.form.hospitalization.int_id &&
        (await this.searchPatientHospitalized({
          patientId: this.form.patient.pac_id,
        }))
      ) {
        return AlertError("Esse paciente encontra-se internado!");
      } else {
        try {
          this.$loader.start("Salvando dados");
          const dataForm = this.formatData();
          await this.$store.dispatch(
            "Hospitalization/Hospitalizations/SAVE_HOSPITALIZATION",
            dataForm.form,
          );
          toast.success("Internação salva com sucesso!");
          this.$emit("close");
        } catch (pErr) {
          console.log("pErr", pErr);
          return AlertError("Falha ao salvar internação.");
        } finally {
          this.$loader.finish();
        }
      }
    },

    selectingBedType(pBedType) {
      if (pBedType && pBedType.source && pBedType.source.itl_id) {
        this.form.hospitalization.itl_id =
          pBedType.source && pBedType.source.itl_id
            ? pBedType.source.itl_id
            : 0;
      } else {
        if (!this.fromMapBed) {
          this.uns_id = null;
          this.set_id = null;
          this.lca_id = null;
        }
        this.form.hospitalization.itl_id = null;
      }
    },

    selectingHospitalizationBed(pBed) {
      if (pBed && pBed.source && pBed.source.inl_id) {
        this.form.hospitalization.inl_id =
          pBed.source && pBed.source.inl_id ? pBed.source.inl_id : null;

        this.lastStatusBed =
          pBed.source && pBed.source.inl_id_internacoes_situacoes_leito
            ? pBed.source.inl_id_internacoes_situacoes_leito
            : null;
      } else {
        this.$refs.bed.doSearch();
        this.bed = "";
        this.form.hospitalization.inl_id = null;
        this.lastStatusBed = null;
      }
    },

    async selectingPatient(pPatient) {
      const hasPerson = pPatient && pPatient.pes_id > 0;
      const patient = pPatient && pPatient.patient;
      const hasPatient = patient && patient.pac_id > 0;
      const patientId = hasPatient && patient.pac_id;
      const patientBlocked = patient && patient.pac_id_motivo_inabilitacao;

      if (patientBlocked) {
        const patientIdReasonBlocked = patient.pac_id_motivo_inabilitacao;

        this.$emit("blocked", true);

        return AlertError(
          "Paciente inativo. Motivo " +
            `${ReasonsBlockedPatient[patientIdReasonBlocked]}`,
        );
      }

      this.$emit("blocked", false);

      if (hasPerson) {
        this.auxInfo.patient.pes_mae =
          pPatient && pPatient.pes_mae ? pPatient.pes_mae : "";

        this.auxInfo.patient.pes_pai =
          pPatient && pPatient.pes_pai ? pPatient.pes_pai : "";

        this.form.patient.pac_id = patient ? patient.pac_id : null;

        const gender =
          pPatient && pPatient.gender
            ? pPatient.gender.sex_sigla
            : pPatient.sex_sigla
            ? pPatient.sex_sigla
            : null;

        this.patientInfo = {
          pac_id: this.form.patient.pac_id,
          pes_id: pPatient.pes_id,
          pac_nome: pPatient.pes_nome,
          sex_sigla: gender,
          pes_nascimento:
            pPatient && pPatient.pes_nascimento
              ? pPatient.pes_nascimento
              : null,
          crs_numero: pPatient && pPatient.cns ? pPatient.cns.crs_numero : null,
          pes_mae: pPatient && pPatient.pes_mae ? pPatient.pes_mae : null,
          cpf: pPatient && pPatient.cpf ? pPatient.cpf.cpf_numero : null,
          prontuario_unico:
            pPatient && patient ? patient.pac_prontuario_unico : null,
        };

        if (
          this.patientCurrentGender &&
          this.patientCurrentGender !== this.patientInfo.sex_sigla
        ) {
          this.cleanProcedure();
        }
        this.patientCurrentGender = this.patientInfo.sex_sigla;

        if (
          hasPatient &&
          !this.form.hospitalization.int_id &&
          !this.disabledPatient &&
          (await this.searchPatientHospitalized({ patientId }))
        ) {
          return AlertError("Esse paciente encontra-se internado!");
        }
      } else {
        this.patientInfo = {};
        this.suggestPatient = null;
        this.form.patient.pac_id = null;
        this.auxInfo.patient.pes_pai = "";
        this.auxInfo.patient.pes_mae = "";
        this.cleanDocumentInfo();
        this.form.companion.documents = [];
        this.form.companion.via_nome = "";
        this.form.hospitalization.int_id_internacoes_tipos_responsavel = null;
        this.patientIsHospitalized = false;
      }
    },

    async searchPatientHospitalized(pValue) {
      if (pValue) {
        const inPatient = await SearchPatientHospitalized(pValue);
        this.patientIsHospitalized = inPatient;
        return inPatient;
      }
      return false;
    },

    selectingResponsibleEmployee(pEmployee) {
      const hasSuggestion =
        pEmployee &&
        pEmployee.source &&
        Object.keys(pEmployee.source).length > 0;
      if (hasSuggestion) {
        const data = pEmployee.source.employeeRelationship;

        this.form.hospitalization.int_id_funcionarios_responsavel =
          data && data.vin_id_funcionarios ? data.vin_id_funcionarios : null;
      }
    },

    selectingRequestEmployee(pEmployee) {
      const hasSuggestion =
        pEmployee &&
        pEmployee.source &&
        Object.keys(pEmployee.source).length > 0;
      if (hasSuggestion) {
        const data = pEmployee.source.employeeRelationship;

        this.form.hospitalization.int_id_funcionarios =
          data && data.vin_id_funcionarios ? data.vin_id_funcionarios : null;
      }
    },

    selectingCidCode(pCid) {
      const source = pCid && pCid.source;
      this.form.hospitalization.int_id_cid10 = source.cid_id
        ? source.cid_id
        : null;
      this.mainCid = source.cid_nome ? source.cid_nome : null;
      this.cid_codigo = source.cid_codigo ? source.cid_codigo : null;
    },

    selectingCbo(pValue) {
      if (pValue.source) {
        this.form.hospitalization.int_id_ocupacoes_cbo = pValue.source.ocp_id;
        this.cboCode = pValue.source.ocp_codigo;
        this.cboName =
          pValue.source.ocp_codigo + " - " + pValue.source.ocp_nome;
      } else {
        this.form.hospitalization.int_id_ocupacoes_cbo = null;
        this.cboCode = "";
        this.cboName = "";
      }
    },

    selectingProcedure(pProcedure) {
      const source = pProcedure.source;
      this.form.hospitalization.int_id_internacoes_procedimentos = source
        ? source.inp_id
        : null;

      this.inp_novo_codigo_procedimento = source
        ? source.inp_novo_codigo_procedimento
        : null;

      this.procedureHasCid = source ? source.total_procedimento_cid : 0;

      if (source && source.procedure) {
        this.procedure = {
          vl_idade_minima: source.procedure.VL_IDADE_MINIMA,
          vl_idade_maxima: source.procedure.VL_IDADE_MAXIMA,
        };
      }

      if (source && source.cns_required) {
        this.cns_required = source.cns_required;
      }
    },

    setDocumentType(pValue) {
      if (pValue && pValue.length > 0) {
        this.documentTypeSelected = {
          dva_id_documentos: pValue[0].value,
          dva_nome: pValue[0].text,
        };
      }
      this.$refs.documentInput.clearInput();
    },

    disableOrEnableDocumentAdd(validation) {
      if (!validation) {
        this.validateAddBtn = false;
        return;
      }

      if (this.dva_numero_documentos !== "") {
        const isValidRg =
          this.documentType === 28 && this.dva_numero_documentos.length === 12;
        const isValidCnh =
          this.documentType === 42 && this.dva_numero_documentos.length === 12;
        const isValidCpf =
          this.documentType === 31 && this.dva_numero_documentos.length === 14;
        const isValidSus =
          this.documentType === 41 && this.dva_numero_documentos.length === 15;

        if (isValidRg || isValidCnh || isValidCpf || isValidSus) {
          this.validateAddBtn = true;
        } else {
          this.validateAddBtn = false;
        }
      } else {
        this.validateAddBtn = false;
      }
    },

    documentListRemoveItem(pValue) {
      this.form.companion.documents.splice(pValue, 1);
      this.closeModalConfirmDefault();
    },

    documentListAddItem() {
      const item = {
        dva_nome: this.documentTypeSelected.dva_nome,
        dva_id_documentos: this.documentTypeSelected.dva_id_documentos,
        dva_numero_documentos: this.dva_numero_documentos,
      };

      const arr_id = [];
      this.form.companion.documents.forEach((i) => {
        arr_id.push(i.dva_id_documentos);
      });

      if (!arr_id.includes(item.dva_id_documentos)) {
        // Create
        this.form.companion.documents.push(item);
      } else {
        // Update
        this.form.companion.documents.forEach((i, index) => {
          if (this.documentType === i.dva_id_documentos) {
            this.form.companion.documents[index].dva_numero_documentos =
              item.dva_numero_documentos;
          }
        });
      }
      this.cleanDocumentInfo();
    },

    documentListUpdateItem(index) {
      this.documentTypeSelected = {
        dva_id_documentos: this.form.companion.documents[index]
          .dva_id_documentos,
        dva_nome: this.form.companion.documents[index].dva_nome,
      };
      this.documentType = this.form.companion.documents[
        index
      ].dva_id_documentos;
      this.dva_numero_documentos = this.form.companion.documents[
        index
      ].dva_numero_documentos;
    },

    openModalConfirmDefault() {
      this.modalConfirmDefault = true;
    },

    closeModalConfirmDefault() {
      this.modalConfirmDefault = false;
    },

    confirmCleanForm() {
      this.modalConfirmFieldClearing = true;
    },

    getCleanFormMethod() {
      this.cleanFormInput();
      this.modalConfirmFieldClearing = false;
    },

    closeModalConfirmFieldClearing() {
      this.modalConfirmFieldClearing = false;
    },

    cleanForm() {
      this.form.patient.pac_id = null;
      this.isPatientIndigent = false;
      this.$emit("blocked", false);

      const ignoreKeys = [
        "int_ultima_situacao_internacao",
        "ids_tipos_do_leito",
        "inl_id",
      ];

      for (var key in this.form.hospitalization) {
        if (this.fromMapBed && ignoreKeys.includes(key)) {
          continue;
        }
        this.form.hospitalization[key] = null;
      }
    },

    cleanPerson() {
      this.form.patient.pac_id = null;
      this.suggestPatient = null;
      this.patientIsHospitalized = false;
      this.disabledPatient = false;
    },

    cleanIndigent() {
      this.form.indigent.ini_descricao = null;
      this.form.indigent.ini_id_sexos = "";
      this.indigentAge = null;
      this.form.indigent.ini_data_aproximada = null;
    },

    cleanInput() {
      this.cleanPerson();
      this.cleanIndigent();
      this.form.hospitalization.int_data = moment().format("DD/MM/YYYY");
      this.form.hospitalization.int_hora = moment().format("HH:mm");
      this.mainCid = null;

      if (!this.fromMapBed) {
        this.uns_id = null;
        this.set_id = null;
        this.lca_id = null;
        this.bed = null;
      }

      this.selectedTypeBed = null;
      this.requesterEmployee = null;
      this.responsibleEmployee = null;
      this.suggestPatient = null;
      this.suggestHospitalizationProcedures = null;
      this.documentTypeSelected = null;
      this.cboCode = "";
      this.cboName = "";
      this.documentType = "";
      this.document = "";
      this.showDocInfo = true;
      this.auxInfo.patient.pes_mae = "";
      this.auxInfo.patient.pes_pai = "";
      this.auxInfo.patient.disabledCompanionName = true;
      this.showModalHistoryPatient = false;
      this.patientInfo = {};

      this.$refs.textArea.resetCharacter();

      this.$refs.bed.cleanValidate();

      if (this.isPatientIndigent) {
        this.$refs.age.cleanValidate();
        this.$refs.description.cleanValidate();
      } else {
        if (this.$refs.patient) {
          this.$refs.patient.cleanValidate();
        }
      }

      this.$refs.typeBed.cleanValidate();
      this.$refs.responsible.cleanValidate();
      this.$refs.procedures.cleanValidate();
      this.$refs.requester.cleanValidate();
      this.$refs.mainCid.cleanValidate();
      this.$refs.inputDateDischarge.cleanValidate();
      this.$refs.inputDateEntry.cleanValidate();
      this.$refs.unitHealth.cleanValidate();
      this.$refs.sector.cleanValidate();
      this.$refs.locale.cleanValidate();
      this.$refs.plan.cleanValidate();
      this.$refs.situationBed.cleanValidate();
      this.$refs.cbo.cleanValidate();
      this.$refs.character.cleanValidate();
      this.$refs.modality.cleanValidate();

      this.$refs.validator.cleanValidate();
    },

    cleanFormInput() {
      this.cleanForm();
      this.cleanInput();
    },

    cleanDocumentInfo() {
      this.dva_numero_documentos = "";
      this.documentType = "";
      this.validateAddBtn = false;
      this.$refs.documentInput.clearInput();
    },

    cleanProcedureRelatedInfo() {
      this.form.hospitalization.int_id_cid10 = null;
      this.mainCid = "";
      this.$refs.mainCid.cleanValidate();

      this.form.hospitalization.int_id_ocupacoes_cbo = null;
      this.cboCode = "";
      this.cboName = "";
      this.$refs.cbo.cleanValidate();

      this.requesterEmployee = "";
      this.form.hospitalization.int_id_funcionarios = null;
      this.$refs.requester.cleanValidate();
    },

    cleanProcedure() {
      this.form.hospitalization.int_id_internacoes_procedimentos = null;
      this.suggestHospitalizationProcedures = "";
      this.$refs.procedures.cleanValidate();
    },

    patientRecord() {
      const patientId = this.patientInfo.pes_id;

      this.$store.commit("Person/Patient/SET_PERSON_ID", patientId);
      this.$store.commit("Person/Patient/SET_LAST_PERSON_ID_SAVE", patientId);

      const validateIfExistFromCreateHospitalization =
        this.$route.name === "hospitalization.hospitalizations.search.details";

      const validateIfExistFromEditHospitalization =
        this.$route.name === "hospitalization.hospitalizations.edit-details";

      const validateIfExistFromHospitalizationMenu =
        this.$route.name === "hospitalization.hospitalizations.details";

      const validateIfExistFromMapBed =
        this.$route.name === "hospitalization.bed.search.new-hospitalization";

      if (validateIfExistFromCreateHospitalization) {
        this.$router.push({
          name: "hospitalization.hospitalizations.search.details.edit-patient",
        });
      } else if (validateIfExistFromEditHospitalization) {
        this.$router.push({
          name: "hospitalization.hospitalizations.edit-details.edit-patient",
        });
      } else if (validateIfExistFromHospitalizationMenu) {
        this.$router.push({
          name: "hospitalization.hospitalizations.details.edit-patient",
        });
      } else if (validateIfExistFromMapBed) {
        this.$router.push({
          name: "hospitalization.bed.search.hospitalization.edit-patient",
          params: {
            form: this.$route.params.form,
            fromMapBed: this.$route.params.fromMapBed,
            operation: this.$route.params.operation,
          },
        });
      }
    },

    openPersonDetails() {
      const isHospitalizationSearchDetails =
        this.$route.name === "hospitalization.hospitalizations.search.details";

      const isHospitalizationSearchBed =
        this.$route.name === "hospitalization.bed.search.new-hospitalization";

      if (isHospitalizationSearchDetails) {
        this.$router.push({
          name: "hospitalization.hospitalizations.search.details.new-patient",
        });
      } else if (isHospitalizationSearchBed) {
        this.$router.push({
          name: "hospitalization.bed.search.hospitalization.new-patient",
          params: {
            form: this.$route.params.form,
            fromMapBed: this.$route.params.fromMapBed,
            operation: this.$route.params.operation,
          },
        });
      } else {
        this.$router.push({
          name: "hospitalization.hospitalizations.details.new-patient",
        });
      }
    },

    showDocumentInfo() {
      this.showDocInfo = !this.showDocInfo;
    },

    showModalHistory() {
      this.showModalHistoryPatient = true;
    },

    closeModalHistoryPatient() {
      this.showModalHistoryPatient = false;
    },

    validateHospitalizationExists(pValue, pErrors) {
      if (this.patientIsHospitalized) {
        pErrors.push("Esse paciente encontra-se internado!");
        return false;
      }
    },

    verifyDateEntry(pValue, pErrors) {
      const dateEntry = this.form.hospitalization.int_data;
      const hourEntry = this.form.hospitalization.int_hora;
      const dateConverted = moment(
        dateEntry + " " + hourEntry,
        "DD/MM/YYYY HH:mm",
      );

      if (!dateConverted.isValid()) {
        return false;
      }

      const dateFormat = dateConverted.format("YYYY-MM-DD HH:mm");

      if (dateFormat > moment().format("YYYY-MM-DD HH:mm")) {
        pErrors.push("Data de Entrada não pode ser maior que a data atual");
        return false;
      }
      return true;
    },

    verifyHourEntry(pValue, pErrors) {
      const dateEntry = this.form.hospitalization.int_data;
      const hourEntry = this.form.hospitalization.int_hora;
      const dateConverted = moment(
        dateEntry + " " + hourEntry,
        "DD/MM/YYYY HH:mm",
      );

      if (!dateConverted.isValid()) {
        return false;
      }

      const dateFormat = dateConverted.format("YYYY-MM-DD HH:mm");

      if (dateFormat > moment().format("YYYY-MM-DD HH:mm")) {
        pErrors.push("Hora de Entrada não pode ser maior que a hora atual");
        return false;
      }
      return true;
    },

    verifyDateDischarge(pValue, pErrors) {
      const dateEntry = moment(
        this.form.hospitalization.int_data,
        "DD-MM-YYYY",
      );
      const dateDischarge = moment(
        this.form.hospitalization.int_previsao_alta,
        "DD-MM-YYYY",
      );

      const dateDischargeIsNotValid = dateDischarge.isBefore(dateEntry);

      if (dateDischargeIsNotValid) {
        pErrors.push(
          "Data Previsão de alta não pode ser menor que a Data de Entrada",
        );
        return false;
      }

      return true;
    },

    resetHospitalizationInfo() {
      this.$store.commit(
        "Hospitalization/Hospitalizations/RESET_HOSPITALIZATION_INFO",
      );
      this.cleanFormInput();
    },

    changePatientType() {
      this.isPatientIndigent = !this.isPatientIndigent;
      this.$emit("blocked", false);
    },

    validateNumberCharacters(pValue, pErrors) {
      if (pValue && pValue.length > 3) {
        pErrors.push("Idade Aproximada deve ter no máximo 3 dígitos");
        return false;
      }
      return true;
    },
  },
};
</script>
