import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query {
    documents(doc_id: [28, 31, 41, 42]) {
      rows {
        doc_id
        doc_nome
      }
    }
  }
`;

export default async (variables) => {
  const { data } = await GraphQLClient.query({ query, variables }).catch(
    (err) => {
      console.error(err);
    },
  );
  return data.documents.rows;
};
