<style src="./RgComboboxPlace.scss" lang="scss"></style>
<script>
import { isNumber } from "lodash";
import { RgRemoteComboboxMixin } from "~tokio/primitive";
import Place from "$surgery-center/unit-health/model/Place";
export default {
  name: "RgComboboxPlace",
  mixins: [RgRemoteComboboxMixin],
  props: {
    sector: {
      default: false,
      required: true,
    },
    defaultText: {
      type: String,
      default: "Selecione",
    },
    emptyDefaultText: {
      type: String,
      default: "Nenhum Item para Selecionar",
    },
  },
  data: () => {
    return {
      model: Place,
      cache: false,
    };
  },
  computed: {
    filter() {
      if (!isNumber(this.sector)) {
        this.resetCombobox();
        return null;
      } else {
        return {
          set_id: this.sector,
        };
      }
    },
  },
  methods: {
    formatItem(pData) {
      return {
        value: pData.lca_id,
        text: pData.lca_nome,
      };
    },
  },
};
</script>
