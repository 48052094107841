<template lang="html">
  <fieldset class="rg-situation-bed">
    <span class="label"> Situação do Leito </span>
    <div class="container unselect">
      <div
        :class="{
          '-active-vazio': selectedStatus.includes(BED_SITUATIONS.VAZIO),
        }"
        class="radio -vazio"
        @click="toggleStatus(BED_SITUATIONS.VAZIO)"
      >
        Vazio
      </div>
      <div
        :class="{
          '-active-reserva': selectedStatus.includes(BED_SITUATIONS.RESERVA),
        }"
        class="radio -reserva"
        @click="toggleStatus(BED_SITUATIONS.RESERVA)"
      >
        Reserva
      </div>
      <div
        :class="{
          '-active-ocupado': selectedStatus.includes(BED_SITUATIONS.OCUPADO),
        }"
        class="radio -ocupado"
        @click="toggleStatus(BED_SITUATIONS.OCUPADO)"
      >
        Ocupado
      </div>
      <div
        :class="{
          '-active-desinfeccao': selectedStatus.includes(
            BED_SITUATIONS.DESINFECCAO,
          ),
        }"
        class="radio -desinfeccao"
        @click="toggleStatus(BED_SITUATIONS.DESINFECCAO)"
      >
        Desinfecção
      </div>
      <div
        :class="{
          '-active-manutencao': selectedStatus.includes(
            BED_SITUATIONS.MANUTENCAO,
          ),
        }"
        class="radio -manutencao"
        @click="toggleStatus(BED_SITUATIONS.MANUTENCAO)"
      >
        Manutenção
      </div>
    </div>
  </fieldset>
</template>

<script>
import BED_SITUATIONS from "$hospitalization/common/component/util/GetTypeBedValues";

export default {
  name: "RgRadioBedType",
  props: {
    value: {
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedStatus: Object.values(BED_SITUATIONS),
    };
  },
  computed: {
    BED_SITUATIONS() {
      return { ...BED_SITUATIONS };
    },
  },
  watch: {
    value(pValue) {
      this.selectedStatus = pValue;
    },
  },
  mounted() {
    this.selectedStatus = this.value || Object.values(BED_SITUATIONS);
  },
  methods: {
    toggleStatus(pItem) {
      if (this.selectedStatus.includes(pItem)) {
        this.selectedStatus.splice(this.selectedStatus.indexOf(pItem), 1);
      } else {
        this.selectedStatus.push(pItem);
      }
      this.$emit("input", this.selectedStatus);
    },
  },
};
</script>
