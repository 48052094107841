<style src="./RgRadioCustom.scss" lang="scss" scoped></style>

<template lang="html">
  <div class="rg-radio-custom">
    <span class="label">{{ label }}</span>
    <section
      class="radio-custom"
      :style="{ gridTemplateColumns: `repeat(${listSize}, ${colWidth}fr)` }"
      :class="{ reorganize }"
    >
      <div
        v-for="(el, idx) in list"
        :key="`rc-${idx}`"
        class="radio-container unselect"
        :class="{
          '--green': el.green,
          '--red': el.red,
          active: el.active,
          mg: reorganize,
        }"
        @click="onClickContainer(el, idx)"
      >
        {{ el.title }}
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "RgRadioCustom",
  components: {},

  props: {
    list: {
      type: Array,
      required: true,
    },
    uniqueKey: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
    },
    reorganize: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
  },

  data() {
    return {
      listSize: 0,
      colWidth: 0,
      selectedItem: null,
    };
  },

  computed: {},

  watch: {
    value(val) {
      this.loadData();
    },
  },

  mounted() {
    this.listSize = this.list.length;
    this.colWidth = parseInt(100 / this.listSize);

    let count = 0;
    this.list.map((el) => {
      if (!el.title) {
        console.error(
          `RadioCustom - list na posição ${count} não contêm chave title`,
        );
      }
      count++;
    });

    this.loadData();
  },

  methods: {
    loadData() {
      if (this.value !== null) {
        this.list.map((el) => {
          el.active = false;
          if (el.id === this.value) {
            el.active = true;
          }
        });
      } else {
        this.list.map((el) => {
          el.active = false;
        });
        this.$forceUpdate();
      }
    },

    onClickContainer(item, idx) {
      item.active = !item.active;
      this.list.map((el) => {
        if (el.active && el[this.uniqueKey] !== item[this.uniqueKey]) {
          el.active = false;
        }
      });

      if (item.active) {
        this.$emit("input", item);
      } else {
        this.$emit("input", null);
      }
      this.$forceUpdate();
    },

    forceUpdate() {
      this.$forceUpdate();
    },
  },
};
</script>
