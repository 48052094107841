<template lang="html">
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      r="11.25"
      transform="matrix(0.999993 0.00373628 0.00373628 -0.999993 12.5392 12.5392)"
      stroke="#ABABAB"
      stroke-width="1.5"
    />
    <path
      d="M10.9459 18.075L10.9459 18.075C10.7104 17.8115 10.7117 17.3953 10.9485 17.1331L10.9485 17.1331L14.9675 12.6852L14.9677 12.685C15.033 12.6128 15.0337 12.4844 14.9684 12.4112L14.9682 12.411L10.9829 7.93345C10.9829 7.93345 10.9829 7.93345 10.9829 7.93345C10.748 7.66949 10.7499 7.25333 10.9873 6.99158L10.9873 6.99156C11.2388 6.71442 11.6565 6.71651 11.9053 6.996L11.9053 6.99601L15.8902 11.4731C15.8902 11.4731 15.8902 11.4731 15.8902 11.4731C16.1602 11.7759 16.2904 12.1677 16.289 12.5533C16.2875 12.9386 16.1545 13.3294 15.8825 13.6298C15.8825 13.6299 15.8824 13.6299 15.8824 13.63L11.8639 18.0774L11.6784 17.9098L11.8639 18.0774C11.613 18.3551 11.1953 18.354 10.9459 18.075Z"
      fill="#ABABAB"
      stroke="#ABABAB"
      stroke-width="0.5"
    />
  </svg>
</template>

<script>
export default {
  name: "ArrowCircleRight",
};
</script>
