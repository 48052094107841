<style src="./NotificationOnHomologation.scss" lang="scss"></style>
<template>
  <Modulebox
    class="notification-on-homologation"
    title="Notificações em Homologação"
  >
    <NotificationOnHomologationForm @submit="createNotification" />

    <NotificationOnHomologationList :list="list" @remove="removeNotification" />
  </Modulebox>
</template>

<script>
import Modulebox from "~tokio/foundation/modulebox/Modulebox";
import NotificationOnHomologationList from "../components/notification-on-homologation-list/NotificationOnHomologationList";
import NotificationOnHomologationForm from "../components/notification-on-homologation-form/NotificationOnHomologationForm";

export default {
  name: "NotificationOnHomologation",
  components: {
    Modulebox,
    NotificationOnHomologationList,
    NotificationOnHomologationForm,
  },
  data: () => {
    return {
      list: [],
    };
  },
  mounted() {
    this.fetchNotifications();
  },
  methods: {
    async fetchNotifications() {
      try {
        const notificationList = await this.$store.dispatch(
          "Notifications/SEARCH_NOTIFICATION_HOMOLOGATION",
        );

        this.list = notificationList;
      } catch (pErr) {
        this.$toaster.error(pErr);
      }
    },
    async removeNotification(pItem) {
      try {
        await this.$store.dispatch(
          "Notifications/REMOVE_NOTIFICATION_HOMOLOGATION",
          pItem,
        );

        this.fetchNotifications();
        this.$toaster.success("Removido com sucesso!");
      } catch (pErr) {
        this.$toaster.error("Falha ao remover cpf/telefone", pErr.message);
      }
    },
    async createNotification(pNotification) {
      try {
        await this.$store.dispatch(
          "Notifications/CREATE_NOTIFICATION_HOMOLOGATION",
          pNotification,
        );

        this.fetchNotifications();
        this.$toaster.success("Salvo com sucesso!");
      } catch (pErr) {
        this.$toaster.error("Falha ao gravar cpf/telefone", pErr.message);
      }
    },
  },
};
</script>
