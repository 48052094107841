<style src="./RgSurgeryEquipmentTypeCrud.scss" lang="scss" scoped></style>
<template lang="html">
  <div class="">
    <section class="form">
      <RgSurgeryEquipmentTypeForm @saved="fillList" @search-for="searchFor" />
    </section>

    <section class="list">
      <RgPaginate
        :page-now="paginate.pageNow"
        :per-page="paginate.perPage"
        :page-count="pageCount"
        @forward-page="forwardPage()"
        @backward-page="backwardPage()"
      />
      <RgSurgeryEquipmentTypeList
        :list="paginatedList"
        @select="select"
        @remove="remove"
      />
    </section>
  </div>
</template>

<script>
import RgPaginate from "$surgery-center/foundation/component/rg-paginate/RgPaginate";
import RgPaginateMixin from "$surgery-center/foundation/component/rg-paginate/mixin/RgPaginateMixin";
import RgSurgeryEquipmentTypeForm from "./rg-surgery-equipment-type-form/RgSurgeryEquipmentTypeForm";
import RgSurgeryEquipmentTypeList from "./rg-surgery-equipment-type-list/RgSurgeryEquipmentTypeList";
import SurgeryEquipmentModel from "$surgery-center/surgery/model/SurgeryEquipmentModel";
export default {
  name: "RgSurgeryEquipmentTypeCrud",
  components: {
    RgSurgeryEquipmentTypeForm,
    RgSurgeryEquipmentTypeList,
    RgPaginate,
  },
  mixins: [RgPaginateMixin],
  data: () => {
    return {};
  },
  mounted() {
    this.fillList();
  },
  methods: {
    remove(item) {
      SurgeryEquipmentModel.remove(item.value).then((res) => {
        this.fillList();
      });
    },
    fillList() {
      SurgeryEquipmentModel.search({ tie_ativo: 1 }).then((res) => {
        this.paginate.list = [];
        res.data.forEach((item) => {
          this.paginate.list.push(this.formatItem(item));
        });
      });
    },
    formatItem(item) {
      return {
        text: item.tie_nome,
        value: item.tie_id,
      };
    },
    select() {},
  },
};
</script>
