<style src="./ModalBillingSearch.scss" lang="scss" scoped></style>
<template>
  <div
    v-shortkey="['alt', 'b']"
    class="modal-billing-search"
    @shortkey="showModal"
  >
    <div
      v-if="modalIsShowing"
      tabindex="0"
      class="modal-container"
      @keydown.esc="closeModal"
    >
      <form class="form" @submit.prevent="submitSearch">
        <div class="form-search">
          <RgInput
            v-model="form.bol_numero"
            :autofocus="true"
            label="Buscar por número de boletim"
            placeholder="Digite aqui o número"
          />

          <button class="btn btn-success">Buscar</button>
        </div>

        <div v-if="showListOfBulletins" class="search-content">
          <span class="alert"
            >Existem mais de um boletim com esse número. Selecione o desejado na
            listagem abaixo.</span
          >
          <ListBulletin @click="loadBulleting" />
        </div>
        <span @click="closeModal">
          <IconClose class="close-modal" />
        </span>
      </form>
    </div>
  </div>
</template>

<script>
import { RgInput } from "~tokio/primitive";
import ListBulletin from "$billing/bulletin/component/list-content/ListContent";
import searchBulletinBilling from "$billing/bulletin/action/searchBulletinBilling";
import { IconClose } from "~tokio/primitive/icon";
export default {
  name: "ModalBillingSearch",
  components: {
    RgInput,
    ListBulletin,
    IconClose,
  },
  data() {
    return {
      billings: [],
      form: {
        bol_numero: "",
      },
      modalIsShowing: false,
      showListOfBulletins: false,
    };
  },
  methods: {
    showModal() {
      this.form.bol_numero = "";
      this.showListOfBulletins = false;
      this.$store.commit("Billing/Bulletin/SET_BULLETIN_LIST", []);
      this.modalIsShowing = true;
    },
    closeModal() {
      this.modalIsShowing = false;
    },
    async submitSearch() {
      const bolNumber = this.form.bol_numero;
      if (!bolNumber) return;
      const unitHealth = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
      const searchFilter = {
        bol_numero: bolNumber,
        uns_id: unitHealth,
        period: this.$store.getters["Billing/BDSia/GET_PERIOD_DATE"],
      };
      const listOfBulletins = await searchBulletinBilling(searchFilter);
      if (listOfBulletins.data.length === 0) {
        this.$toaster.error("Nenhum boletim encontrado");
        return;
      }
      this.$store.commit(
        "Billing/Bulletin/SET_BULLETIN_LIST",
        listOfBulletins.data,
      );
      if (listOfBulletins.data.length > 1) {
        this.showListOfBulletins = true;
      } else {
        this.loadBulleting({
          bolId: listOfBulletins.data[0].bol_id,
          bof_id: listOfBulletins.data[0].bof_id,
        });
      }
    },
    loadBulleting({ bolId, bofId }) {
      if (bolId) {
        const BulletinNumber = this.$store.getters[
          "Billing/Bulletin/GET_BULLETIN_LIST"
        ].filter((item) => item.bol_id === bolId)[0].bol_numero;
        this.$store.commit(
          "Billing/Bulletin/SELECT_BULLETIN_NUMBER",
          BulletinNumber,
        );
        this.$store.dispatch(
          "Billing/Bulletin/SELECT_BULLETIN_TO_BILLING",
          bolId,
        );
      } else if (bofId) {
        this.$store.commit("Billing/Bulletin/SELECT_BOF_ID", bofId);
      }
      this.closeModal();
    },
  },
};
</script>
