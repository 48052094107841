<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import GetSubprocedureByProcedure from "./action/GetSubprocedureByProcedure";
export default {
  name: "RgComboboxSubprocedure",
  mixins: [RgComboboxMixin],
  props: {
    healthUnitId: {
      required: true,
    },
    procedureName: {
      type: String,
      required: true,
    },
  },
  data: () => {
    return {};
  },
  watch: {
    healthUnitId() {
      this.fillData();
    },
    procedureName() {
      this.fillData();
    },
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];
      if (!this.healthUnitId || !this.procedureName) {
        return;
      }

      const data = await GetSubprocedureByProcedure({
        intIdUnidade: this.healthUnitId,
        nomeProcedimento: this.procedureName,
      });

      data.forEach((item) => {
        this.mData.push({ value: item.data, text: item.label });
      });
    },
  },
};
</script>
