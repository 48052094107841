<style src="./DateFilter.scss" lang="scss" scoped></style>
<template lang="html">
  <div class="date-filter">
    <FilterBaseContainer :filter-key="filterKey" @close="closeFilter()">
      <RgInputDate
        v-model="filterDateValue"
        :placeholder="todayDate"
        label="Buscar por data"
      />
    </FilterBaseContainer>
  </div>
</template>

<script>
import moment from "moment";
import { RgInputDate } from "~tokio/primitive";
import FilterBaseContainer from "../filter-base-container/FilterBaseContainer";
import FilterBaseContainerMixin from "../filter-base-container/FilterBaseContainerMixin";

export default {
  name: "DateFilter",
  components: {
    RgInputDate,
    FilterBaseContainer,
  },
  mixins: [FilterBaseContainerMixin],
  data() {
    return {
      todayDate: "",
      filterDateValue: "",
    };
  },
  watch: {
    filterDateValue(pValue) {
      if (pValue && moment(pValue, "DD/MM/YYYY", true).isValid()) {
        this.changeDateFilter(pValue);
      } else {
        this.removeDateFilter();
      }
    },
  },
  mounted() {
    this.filterKey = "cci_data_inicio";
    this.todayDate = this.$utils.date.BrazilianDateFormat();
    const getDateFromStore = this.$store.getters[
      "SurgeryCenter/Surgery/filterValue"
    ](this.filterKey);
    if (getDateFromStore)
      this.filterDateValue = moment(getDateFromStore).format("DD/MM/YYYY");
  },
  methods: {
    removeDateFilter() {
      this.filterValue = "";
      this.$nextTick(() => {
        this.changeFilter(this.filterKey);
      });
    },
    changeDateFilter(pValue) {
      const dateConverted = moment(
        this.filterDateValue,
        "DD/MM/YYYY",
        true,
      ).format("YYYY-MM-DD");
      if (dateConverted !== "Invalid date") {
        this.filterValue = dateConverted;
        this.$nextTick(() => {
          this.changeFilter(this.filterKey);
        });
      }
    },
  },
};
</script>
