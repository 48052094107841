<template lang="html">
  <svg
    data-name="Layer 1"
    version="1.1"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="#c0cadd">
      <path
        d="M472.916,224H448.007a24.534,24.534,0,0,0-23.417-18H398V140.976a6.86,6.86,0,0,0-3.346-6.062L207.077,26.572a6.927,6.927,0,0,0-6.962,0L12.48,134.914A6.981,6.981,0,0,0,9,140.976V357.661a7,7,0,0,0,3.5,6.062L200.154,472.065a7,7,0,0,0,3.5.938,7.361,7.361,0,0,0,3.6-.938L306,415.108v41.174A29.642,29.642,0,0,0,335.891,486H472.916A29.807,29.807,0,0,0,503,456.282v-202.1A30.2,30.2,0,0,0,472.916,224Zm-48.077-4A10.161,10.161,0,0,1,435,230.161v.678A10.161,10.161,0,0,1,424.839,241H384.161A10.161,10.161,0,0,1,374,230.839v-.678A10.161,10.161,0,0,1,384.161,220ZM203.654,40.717l77.974,45.018L107.986,185.987,30.013,140.969ZM197,453.878,23,353.619V153.085L197,253.344Zm6.654-212.658-81.668-47.151L295.628,93.818,377.3,140.969ZM306,254.182V398.943l-95,54.935V253.344L384,153.085V206h.217A24.533,24.533,0,0,0,360.8,224H335.891A30.037,30.037,0,0,0,306,254.182Zm183,202.1A15.793,15.793,0,0,1,472.916,472H335.891A15.628,15.628,0,0,1,320,456.282v-202.1A16.022,16.022,0,0,1,335.891,238h25.182a23.944,23.944,0,0,0,23.144,17H424.59a23.942,23.942,0,0,0,23.143-17h25.183A16.186,16.186,0,0,1,489,254.182Z"
      />
      <path
        d="M343.949,325h7.327a7,7,0,1,0,0-14H351V292h19.307a6.739,6.739,0,0,0,6.655,4.727A7.019,7.019,0,0,0,384,289.743v-4.71A7.093,7.093,0,0,0,376.924,278H343.949A6.985,6.985,0,0,0,337,285.033v32.975A6.95,6.95,0,0,0,343.949,325Z"
      />
      <path
        d="m344 389h33a7 7 0 0 0 7-7v-33a7 7 0 0 0-7-7h-33a7 7 0 0 0-7 7v33a7 7 0 0 0 7 7zm7-33h19v19h-19z"
      />
      <path
        d="M351.277,439H351V420h18.929a7.037,7.037,0,0,0,14.071.014v-6.745A7.3,7.3,0,0,0,376.924,406H343.949A7.191,7.191,0,0,0,337,413.269v32.975A6.752,6.752,0,0,0,343.949,453h7.328a7,7,0,1,0,0-14Z"
      />
      <path
        d="m393.04 286.59-20.5 20.5-6.236-6.237a7 7 0 1 0-9.9 9.9l11.187 11.186a7 7 0 0 0 9.9 0l25.452-25.452a7 7 0 0 0-9.9-9.9z"
      />
      <path
        d="m393.04 415.84-20.5 20.5-6.236-6.237a7 7 0 1 0-9.9 9.9l11.187 11.186a7 7 0 0 0 9.9 0l25.452-25.452a7 7 0 0 0-9.9-9.9z"
      />
      <path d="m464.86 295h-43.966a7 7 0 0 0 0 14h43.966a7 7 0 0 0 0-14z" />
      <path d="m464.86 359h-43.966a7 7 0 0 0 0 14h43.966a7 7 0 0 0 0-14z" />
      <path d="m464.86 423h-43.966a7 7 0 0 0 0 14h43.966a7 7 0 0 0 0-14z" />
    </g>
  </svg>
</template>
<script>
export default {
  name: "ProductClass",
};
</script>
