import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async (context, pVariables) => {
  const sendData = pVariables;
  return AuthLegacyRequest.post(
    "/consultas/controller-marcacao-consulta/verificar-profissional-possui-procedimentos-padrao",
    sendData,
  )
    .then(({ data }) => {
      return data;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
