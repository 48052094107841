import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  return AuthLegacyRequest.post(
    "/consultas/controller-marcacao-consulta/buscar-atendentes-nova-busca",
    variables,
  )
    .then(({ data }) => {
      data = HandleResponse(data);

      data.dados.data = data.dados.recordSet;

      data.dados.data = data.dados.data.map((item) => {
        if (Number(item.cte_cor) > 0) {
          item.type = "Reserva Técnica";
        } else if (Number(item.cte_cor) <= 0) {
          item.type = "Normal";
        }

        const validateDisableRow =
          Number(item.funcionario_inabilitado) === 1 ||
          Number(item.hag_atendimento) === 0;
        if (validateDisableRow) {
          item.disabled = true;
        }

        return item;
      });

      const all = data.dados.data;

      const list = all.filter((el) => {
        return !el.disabled && !(parseInt(el.eag_quantidade_restantes) === 0);
      });

      const listNoVacancy = all.filter((el) => {
        return !el.disabled && parseInt(el.eag_quantidade_restantes) === 0;
      });

      const listDisabled = all.filter((el) => {
        return el.disabled;
      });

      data.dados.data = [...list, ...listNoVacancy, ...listDisabled];

      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
