<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchHealthPlansWithObjective from "./action/SearchHealthPlansWithObjective";
export default {
  name: "RgComboboxHealthPlansWithObjective",
  mixins: [RgComboboxMixin],
  props: {
    moduleId: {
      required: true,
    },
  },
  data: () => {
    return {};
  },
  watch: {
    healthUnitId() {
      this.fillData();
    },
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];
      const data = await SearchHealthPlansWithObjective({
        intModulo: this.moduleId,
      });
      data.forEach((item) => {
        this.mData.push({ value: item.data, text: item.pls_nome });
      });
    },
  },
};
</script>
