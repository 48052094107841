import AuthRequest from "~common/request/AuthRequest";

export default (pData) => {
  return AuthRequest.get(
    "billing/report/get-bulletins-billing-sum",
    pData,
  ).then((pResult) => {
    return pResult.data;
  });
};
