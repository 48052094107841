import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query(
    $procedureCode: String
    $cboCode: String
    $period: String
    $fco_id_unidades_saude: Int
  ) {
    consolidatedBillings(
      fco_codigo_procedimento: $procedureCode
      fco_id_unidades_saude: $fco_id_unidades_saude
      fco_cbo: $cboCode
      fco_competencia: $period
    ) {
      rows {
        fco_id
        fco_codigo_procedimento
        fco_cbo
        fco_quantidade
        fco_idade
        procedure {
          NO_PROCEDIMENTO
        }
        occupation {
          NO_OCUPACAO
        }
      }
    }
  }
`;

export default ({ commit, rootGetters }, variables) => {
  variables = variables || {};
  variables.period = rootGetters["Billing/BDSia/GET_PERIOD_DATE"];
  variables.fco_id_unidades_saude = rootGetters["Login/GET_UNIT_HEALTH_ID"];
  return new Promise((resolve, reject) => {
    GraphQLClient.query({ query, variables, fetchPolicy: "no-cache" })
      .then((pRes) => {
        const data = pRes.data.consolidatedBillings
          ? pRes.data.consolidatedBillings.rows
          : [];
        commit("STORE_CONSOLIDATED_LIST", { consolidatedList: data });
        resolve(pRes.data);
      })
      .catch((pErr) => {
        reject(pErr.graphQLErrors);
      });
  });
};
