import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation FlowChange($flow: FlowChangeInputType) {
    FlowChange(flow: $flow) {
      inl_id
    }
  }
`;
export default async ({ commit }, variables) => {
  try {
    const flow = variables;
    variables = { flow };

    const { data } = await GraphQLClient.mutate({
      mutation,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.FlowChange;
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors[0].message);
  }
};
