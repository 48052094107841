<script>
import { RgRemoteTypeaheadMixin } from "~tokio/primitive";
import SurgeryMaterialModel from "$surgery-center/surgery/model/SurgeryMaterialModel";
export default {
  name: "RgTypeaheadSurgeryMaterial",
  mixins: [RgRemoteTypeaheadMixin],
  props: {
    label: {
      default: "Material",
    },
    min: {
      default: "2",
    },
  },
  data() {
    return {
      model: SurgeryMaterialModel,
      filter: () => {
        return {
          tmc_nome: this.inputValue,
        };
      },
    };
  },
  methods: {
    formatItem(item) {
      return {
        value: item.tmc_id,
        text: item.tmc_nome,
      };
    },
  },
};
</script>
