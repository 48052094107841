import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query($cns: String, $pesNome: String) {
    hospitalizationEmployeeAuthorizer(
      fun_crs_numero_ab: $cns
      pes_nome: $pesNome
    ) {
      rows {
        ifa_id
        ifa_id_funcionarios
        fun_crs_numero_ab
        pes_nome
        ocp_nome
        equ_codigo_ine
      }
    }
  }
`;

export default async (variables, findOne = false) => {
  const { data } = await GraphQLClient.query({ query, variables }).catch(
    (err) => {
      console.error(err);
    },
  );

  const dataMapped = data.hospitalizationEmployeeAuthorizer.rows.map((item) => {
    return {
      pes_nome: item.pes_nome,
      ocp_nome: item.ocp_nome,
      crs_numero: item.fun_crs_numero_ab,
      equ_codigo_ine: item.equ_codigo_ine,
    };
  });

  if (!findOne) {
    return dataMapped;
  } else {
    return dataMapped[0];
  }
};
