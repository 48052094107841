<template lang="html">
  <div
    v-if="show"
    v-shortkey="['enter']"
    class="modal-bulletin-details"
    @shortkey.native="
      {
      }
    "
  >
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <div class="title">Detalhes do Boletim</div>
      </div>

      <div slot="body" class="body">
        <div class="informations">
          <RgTypePatient
            :pac-id="Number(isPatient)"
            :sex="isPatient ? patientGender : null"
            class="patient-type"
          />

          <div class="area">
            <span class="data">
              Paciente:
              <strong>
                {{ getValue(bulletinData, "pes_nome") }}
              </strong>
            </span>

            <span class="data">
              Boletim:
              <strong>
                {{ getValue(bulletinData, "bol_numero") }}
              </strong>
            </span>
          </div>

          <div class="area">
            <span class="data">
              Unidade de Saúde:
              <strong>
                {{ getValue(bulletinData, "uns_nome") }}
              </strong>
            </span>

            <span class="data">
              Setor:
              <strong>
                {{ getValue(bulletinData, "set_nome") }}
              </strong>
            </span>
          </div>

          <div class="area">
            <span class="data">
              Local de Atendimento:
              <strong>
                {{ getValue(bulletinData, "lca_nome") }}
              </strong>
            </span>
          </div>
        </div>
        <FormBase title="Boletim" class="area">
          <div class="bulletin-info">
            <div class="content-info">
              <span>
                Data e Hora: <b>{{ formatData() }}</b>
              </span>

              <span>
                CNS:
                <b>{{
                  personData && personData.cns ? personData.cns.crs_numero : "-"
                }}</b>
              </span>

              <span>
                PEP: <b>{{ bulletinData ? bulletinData.enviado : "-" }}</b>
              </span>
            </div>

            <div class="content-info">
              <span>
                Prontuário:
                <b>{{
                  personData && personData.patient
                    ? personData.patient.pac_prontuario_unico
                    : "-"
                }}</b>
              </span>

              <span class="ellipsis">
                Responsável:
                <b>{{ bulletinData ? bulletinData.bol_responsavel : "-" }}</b>
              </span>

              <span class="ellipsis">
                Usuário: <b>{{ bulletinData ? bulletinData.usu_nome : "-" }}</b>
              </span>
            </div>

            <div class="content-others-info">
              <div>
                <span>
                  Observação:
                  <b>
                    {{ bulletinData ? bulletinData.bol_observacao : "-" }}
                  </b>
                </span>
              </div>

              <div class="patients-situation">
                <span>Situação do paciente </span>
                <div class="situation">
                  {{ situationText }}
                </div>
              </div>
            </div>
          </div>
        </FormBase>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import { RgBaseModal } from "~tokio/primitive";
import FormBase from "~tokio/foundation/form-base/FormBase";

import RgTypePatient from "$hospitalization/common/component/status/rg-type-patient/RgTypePatient";

export default {
  name: "ModalBulletinDetails",
  components: {
    RgBaseModal,
    FormBase,
    RgTypePatient,
  },
  directives: { mask },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    bulletinData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      personData: null,
      patientGender: null,
    };
  },

  computed: {
    isPatient() {
      return this.bulletinData && this.bulletinData.pac_id
        ? this.bulletinData.pac_id
        : 0;
    },

    situationText() {
      return this.bulletinData?.saida === "0" ? "Sem saída" : "Com saída";
    },
  },

  watch: {
    show(pValue) {
      if (!pValue) {
        this.close();
      } else {
        this.getPersonData();
      }
    },
  },

  methods: {
    getValue(pItem, pKey) {
      const value = pItem && pItem[pKey] ? pItem[pKey] : "Não informado";
      return value;
    },

    async getPersonData() {
      try {
        if (this.bulletinData.pes_id) {
          this.bulletinData.pep_sexo === "M"
            ? (this.patientGender = 1)
            : (this.patientGender = 0);

          this.$loader.start("Carregando os detalhes do boletim...");
          this.personData = await this.$store.dispatch(
            "Emergency/Bulletin/BASIC_PATIENT_INFO",
            {
              pesId: this.bulletinData.pes_id,
            },
          );

          this.$loader.finish();
        }
      } catch (Err) {
        console.log(Err);
      }
    },
    formatData() {
      const hasHour =
        this.bulletinData?.bol_hora && this.bulletinData?.bol_hora?.length > 0;

      if (hasHour) {
        const hour = this.bulletinData.bol_hora.substring(0, 5);

        return `${this.bulletinData.bol_data} ` + ` ${hour}`;
      }
    },
    close() {
      this.$emit("close");
      this.personData = null;
    },
  },
};
</script>
