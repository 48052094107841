import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query Place($tlgNome: String) {
    publicPlaceType(tlg_nome: $tlgNome) {
      rows {
        tlg_id
        tlg_nome
      }
    }
  }
`;

export default async (variables) => {
  const { data } = await GraphQLClient.query({ query, variables }).catch(
    (err) => {
      console.error(err);
    },
  );
  return data.publicPlaceType.rows;
};
