import SEARCH_PRE_REGISTRATION from "./SearchPreRegistration";
import INSERT_PRE_REGISTRATION from "./InsertPreRegistration";
import EDIT_PRE_REGISTRATION from "./EditPreRegistration";

import CALL from "./Call";
import END_CALL from "./EndCall";
import CLEAN_CALL from "./CleanCall";
import REMOVE from "./Remove";

export default {
  SEARCH_PRE_REGISTRATION,
  INSERT_PRE_REGISTRATION,
  CALL,
  END_CALL,
  CLEAN_CALL,
  REMOVE,
  EDIT_PRE_REGISTRATION,
};
