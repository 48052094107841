import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async (procedureName) => {
  return AuthLegacyRequest.post(
    "exames2/controller-fila-exame/combo-box-filas-sub-procedimentos",
    { nomeProcedimento: procedureName },
  )
    .then(({ data }) => {
      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
