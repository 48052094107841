import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query GetSectorByBedType(
    $uns_id: Int!
    $itl_id: Int
    $inl_id: Int
    $situation: SituationBedTypeEnum
    $permission: Int
  ) {
    sectorByBedType(
      uns_id: $uns_id
      itl_id: $itl_id
      inl_id: $inl_id
      situation: $situation
      permission: $permission
    ) {
      rows {
        set_id
        set_nome
        set_responsavel
        set_ativo
      }
    }
  }
`;
export default async (variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    return data.sectorByBedType.rows;
  } catch (err) {
    return err.graphQLErrors;
  }
};
