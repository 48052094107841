<template lang="html">
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M26 13C26 20.1797 20.1797 26 13 26C5.8203 26 0 20.1797 0 13C0 5.8203 5.8203 0 13 0C20.1797 0 26 5.8203 26 13ZM13.0135 5C9.99968 5 9.90061 6.81481 9.99968 7.42419C10.1686 8.46303 10.3374 9.43618 10.5019 10.384L10.502 10.3843C10.8245 12.2428 11.1301 14.0041 11.3858 15.9728C11.3859 16.6965 12.1308 17 13.0135 17C13.6756 17 14.6135 16.6965 14.6135 15.9728C14.9997 13 15.4997 10 15.9997 7.42419C16.131 6.74782 15.9997 5 13.0135 5ZM11 21C11 19.909 11.8628 19 13.0131 19C14.0848 19 15 19.909 15 21C15 22.0649 14.0849 23 13.0131 23C11.8627 23 11 22.0649 11 21Z"
      fill="#2B5D8A"
    />
  </svg>
</template>

<script>
export default {
  name: "ExclamationHelper",
};
</script>
