import AuthRequest from "~common/request/AuthRequest";

/**
 *
 * @param {Array} filesnames um Array com os nomes dos arquivos enviados
 */
const SaveNewFiles = (pFillID, pFilesnames, modId) => {
  return AuthRequest.post("/queue/queue-files/save", {
    fil_id: pFillID,
    mod_id: modId,
    filenames: pFilesnames,
  });
};

export default SaveNewFiles;
