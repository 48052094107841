import MainHeader from "~tokio/foundation/main-header/MainHeader";
import RootModal from "~tokio/foundation/root-modal/RootModal";

import SchedulingRequest from "$surgery-center/surgery/view/scheduling-request/SchedulingRequest";
import SurgeryScheduling from "$surgery-center/surgery/view/surgery-scheduling/SurgeryScheduling";
import SurgeryEffectiveness from "$surgery-center/surgery/view/surgery-effectiveness/SurgeryEffectiveness";
import SurgeryMap from "$surgery-center/surgery/view/surgery-map/SurgeryMap";
import Material from "$surgery-center/surgery/view/material/Material";
import PrinSurgery from "$surgery-center/surgery/component/print-surgery/PrintSurgery";
import BaseCrudsView from "$surgery-center/surgery/view/cruds/BaseCrudsView";
import RgReport from "$surgery-center/reports/view/RgReport";

import PrintMap from "$surgery-center/surgery/component/surgery-map-form/print-map/PrintMap";
import PrintReportMaterials from "$surgery-center/reports/view/rg-report-material-per-surgery/print-report/PrintReportMaterials";
import PrintReportTeamProductivity from "$surgery-center/reports/view/rg-report-team-productivity/print-report/PrintReportTeamProductivity";

const requestRoute = {
  path: "/surgery-center/request",
  name: "request",
  components: {
    default: SchedulingRequest,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [{ label: "Pedido", link: "/request" }],
  },
};

const surgerySchedulingRoute = {
  path: "/surgery-center/schedule",
  name: "schedule",
  components: {
    default: SurgeryScheduling,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [{ label: "Agendamento", link: "/schedule" }],
  },
};

const surgeryMapRoute = {
  path: "/surgery-center/surgery-map",
  name: "surgery-map",
  components: {
    default: SurgeryMap,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [{ label: "Mapa Cirúrgico", link: "/surgery-map" }],
  },
};

const materialRoute = {
  path: "/surgery-center/material",
  name: "material",
  components: {
    default: Material,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [{ label: "Material", link: "/material" }],
  },
};

const printMapRoute = {
  path: "/surgery-center/print-map",
  name: "print-map",
  components: {
    default: PrintMap,
  },
};

const printSurgeryRoute = {
  path: "/surgery-center/print-surgery",
  name: "print-surgery",
  components: {
    default: PrinSurgery,
  },
};

const surgeryEffectivenessRoute = {
  path: "/surgery-center/efectiviness",
  name: "efectiviness",
  components: {
    default: SurgeryEffectiveness,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [{ label: "Efetivação de Cirurgia", link: "/efectiviness" }],
  },
};

const crudsRoute = {
  path: "/surgery-center/cruds",
  name: "cruds",
  components: {
    default: BaseCrudsView,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [{ label: "Cadastros", link: "/cadastros" }],
  },
};

const reportRoute = {
  path: "/surgery-center/reports",
  name: "reports",
  components: {
    default: RgReport,
    header: MainHeader,
    rootModal: RootModal,
  },
};

const printMaterialReportRoute = {
  path: "/surgery-center/print-report-materials",
  name: "print-report-materials",
  components: {
    default: PrintReportMaterials,
  },
};

const printTeamProductivityReport = {
  path: "/surgery-center/print-report-team-productivity",
  name: "print-report-team-productivity",
  components: {
    default: PrintReportTeamProductivity,
  },
};

export default {
  requestRoute,
  surgerySchedulingRoute,
  surgeryMapRoute,
  materialRoute,
  printMapRoute,
  printSurgeryRoute,
  surgeryEffectivenessRoute,
  crudsRoute,
  reportRoute,
  printMaterialReportRoute,
  printTeamProductivityReport,
};
