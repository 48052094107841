import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation($surgery: SurgeryRequestInputType) {
    UpdateRequestSurgery(surgery: $surgery) {
      cci_id
    }
  }
`;

export default (pStore, pSurgery) => {
  const variables = {
    surgery: sanitizeFields(pSurgery),
  };
  return new Promise((resolve, reject) => {
    GraphQLClient.mutate({ mutation, variables })
      .then((result) => {
        pStore.commit("CLEAN_SELECTION");
        return resolve(result.data);
      })
      .catch((err) => {
        return reject(err.graphQLErrors);
      });
  });
};

const sanitizeFields = (pForm) => {
  const data = { ...pForm };
  delete data.cci_retorno_infeccao;

  return data;
};
