<style src="./ModalConfirmDefault.scss" lang="scss" scoped></style>
<template lang="html">
  <div class="modal-confirm-default">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <h1 class="title">{{ title }}</h1>
        <span class="subtitle-text">{{ subtitle }}</span>
      </div>

      <div slot="body" class="body">
        <div class="text">
          <span>{{ message }}</span>
        </div>

        <div>
          <slot name="body-content" />
        </div>
      </div>

      <div
        slot="footer"
        class="footer"
        :class="{ setright: !showNoButton || buttonsRight }"
      >
        <MediumButton
          v-if="showNoButton"
          :label="noLabel"
          :backgroundColor="'#fd9e47'"
          @click="getOut"
        />

        <MediumButton
          :backgroundColor="'#53b1d8'"
          :label="yesLabel"
          @click="getYes"
        />
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { RgBaseModal, MediumButton } from "~tokio/primitive";

export default {
  name: "ModalConfirmDefault",
  components: {
    RgBaseModal,
    MediumButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    showNoButton: {
      type: Boolean,
      default: true,
    },
    buttonsRight: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
    yesLabel: {
      type: String,
      default: "",
    },
    noLabel: {
      type: String,
      default: "",
    },
  },
  methods: {
    getYes() {
      this.$emit("getYes", 1);
    },

    getOut() {
      this.$emit("getOut", 2);
    },

    close() {
      this.$emit("close");
    },
  },
};
</script>
