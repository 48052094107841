import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async () => {
  return AuthLegacyRequest.post(
    "consultas/controller-fila-consulta/combo-box-filas-unidade",
  )
    .then(({ data }) => {
      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
