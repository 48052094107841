<template lang="html">
  <svg
    id="Capa_1"
    enable-background="new 0 0 551.13 551.13"
    height="512"
    viewBox="0 0 551.13 551.13"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m499.462 0h-378.902c-9.52 0-17.223 7.703-17.223 17.223v51.668h34.446v-34.445h344.456v482.239h-344.456v-34.446h-34.446v51.668c0 9.52 7.703 17.223 17.223 17.223h378.902c9.52 0 17.223-7.703 17.223-17.223v-516.684c0-9.52-7.704-17.223-17.223-17.223z"
    />
    <path
      d="m204.588 366.725 24.354 24.354 115.514-115.514-115.514-115.514-24.354 24.354 73.937 73.937h-244.079v34.446h244.08z"
    />
  </svg>
</template>

<script>
export default {
  name: "In",
};
</script>
