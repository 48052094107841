import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (context, { variables, permissions = {} }) => {
  const sendData = {
    ...variables,
    blnPermissaoNormal: permissions.blnPermissaoNormal || false,
    blnPermissaoReserva: permissions.blnPermissaoReserva || false,
  };
  return AuthLegacyRequest.post(
    "exames2/controller-exame-agendamento-procedimento/agendar",
    sendData,
  )
    .then(({ data }) => {
      const scheduleErrors = {
        PacienteConsultadoHojeAtendenteException:
          "O Paciente já tem uma consulta marcada para este dia!",
        // será tratado na tela esse tipo de erro
        HorarioIndisponivelException: "HorarioIndisponivelException",
      };
      return HandleResponse(data, { extraExceptionsErrors: scheduleErrors });
    })
    .catch((pErr) => {
      throw pErr;
    });
};
