import GetAppointmentReport from "./GetAppointmentReport";
import GetAppointmentPeriodReport from "./GetAppointmentPeriodReport";
import GetAppointmentDisabledPeriodsReport from "./GetAppointmentDisabledPeriodsReport";
import GetAppointmentHistoryScheduleReport from "./GetAppointmentHistoryScheduleReport";
import GetAppointmentExcludedReport from "./GetAppointmentExcludedReport";
import GetAppointmentSearchScaleExcludedReport from "./GetAppointmentSearchScaleExcludedReport";
import GetAppointmentUserProductivityReport from "./GetAppointmentUserProductivityReport";
import GetAppointmentOccupationReport from "./GetAppointmentOccupationReport";
import GetAppointmentProfessionalProductivityReport from "./GetAppointmentProfessionalProductivityReport";
import GetAppointmentQueueListReport from "./GetAppointmentQueueListReport";

export default {
  GET_APPOINTMENT_REPORT: GetAppointmentReport,
  GET_APPOINTMENT_PERIOD_REPORT: GetAppointmentPeriodReport,
  GET_APPOINTMENT_DISABLED_PERIODS_REPORT: GetAppointmentDisabledPeriodsReport,
  GET_APPOINTMENT_HISTORY_SCHEDULE_REPORT: GetAppointmentHistoryScheduleReport,
  GET_APPOINTMENT_EXCLUDED_REPORT: GetAppointmentExcludedReport,
  GET_APPOINTMENT_SEARCH_SCALE_EXCLUDED_REPORT: GetAppointmentSearchScaleExcludedReport,
  GET_APPOINTMENT_USER_PRODUCTIVITY_REPORT: GetAppointmentUserProductivityReport,
  GET_APPOINTMENT_OCCUPATION_REPORT: GetAppointmentOccupationReport,
  GET_APPOINTMENT_PROFESSIONAL_PRODUCTIVITY_REPORT: GetAppointmentProfessionalProductivityReport,
  GET_APPOINTMENT_QUEUE_LIST_REPORT: GetAppointmentQueueListReport,
};
