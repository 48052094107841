<style src="./RgRadioGender.scss" lang="scss" scoped></style>
<template lang="html">
  <fieldset class="rg-input--component">
    <RgFormBase label=" ">
      <div slot="right-label">
        <RgValidationAlert :alert="error" />
      </div>
      <div
        :class="{
          'rg-input--base__male': picked === 1,
          'rg-input--base__female': picked === 2,
        }"
        class="rg-input--base"
      >
        <div class="rg-input--textbox-container">
          <label>
            <input v-model="picked" :value="1" type="radio" />
            <div
              :data-id="dataId"
              class="rg-input--span-container rg-input--span-container__male"
            >
              <span v-show="picked === 1">Masculino</span>
              <IconGenderFemale
                v-show="picked !== 1"
                class="rg-input--icon rg-input--icon__female"
              />
            </div>
          </label>

          <label>
            <input v-model="picked" :value="2" type="radio" />
            <div
              :data-id="dataId"
              class="rg-input--span-container rg-input--span-container__female"
            >
              <span v-show="picked === 2">Feminino</span>
              <IconGenderMale
                v-show="picked !== 2"
                class="rg-input--icon rg-input--icon__male"
              />
            </div>
          </label>
        </div>
      </div>
    </RgFormBase>
  </fieldset>
</template>

<script>
import { RgFormBase } from "~tokio/foundation/container";
import {
  RgValidatorMixin,
  RgValidationAlert,
} from "~tokio/primitive/validation";
import { IconGenderMale, IconGenderFemale } from "~tokio/primitive/icon";

export default {
  name: "RgRadioGender",
  components: {
    RgValidationAlert,
    RgFormBase,
    IconGenderMale,
    IconGenderFemale,
  },
  mixins: [RgValidatorMixin],
  props: {
    value: {
      type: [Number, String],
      default: "",
    },
    label: {
      type: String,
      default: "Sexo",
    },
    tip: {
      type: String,
      default: "",
    },
    dataId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      picked: null,
    };
  },
  computed: {
    validValue() {
      return this.picked;
    },
  },
  watch: {
    picked(newValue) {
      this.validate();
      this.$emit("input", newValue);
    },
    value(newValue) {
      this.picked = newValue;
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.tip && this.tip.length > 1) {
        this.selectGender(this.tip);
      } else {
        this.picked = 1;
      }
    });
  },
  methods: {
    selectGender(tip) {
      let vGender = null;
      const firstName = this.tip.split(" ")[0];
      vGender = this.checkGender(firstName.substr(-1), false);
      if (!vGender)
        vGender = this.checkGender(
          firstName.substr(-2, 1),
          firstName.substr(-1),
        );
      if (vGender) {
        this.picked = vGender;
      } else {
        this.picked = 1;
      }
    },
    checkGender(pVogal, pSufix) {
      if (!pSufix && ["i", "o", "u"].includes(pVogal)) return 1;
      if (pSufix && ["i", "o", "u", "e"].includes(pVogal)) return 1;
      if (["a", "e"].includes(pVogal)) return 2;
      return false;
    },
    changeState() {
      this.picked = this.picked === 1 ? 2 : 1;
    },
  },
};
</script>
