<style src="./RgSearchButton.scss" lang="scss" scoped></style>
<template>
  <div class="rg-search-button">
    <RgButton
      :title="title"
      :data-id="dataId"
      :permission="permission"
      :disabled="disabled"
      :class="{
        'rg-search-button -small': small,
        'rg-search-button -medium': medium,
        'rg-search-button -large': large,
      }"
      class="button"
      type="button"
      @click="clicked"
    >
      <div v-show="medium || large" class="text">
        <span>Buscar</span>
      </div>

      <div v-show="large" class="separator" />

      <div v-if="showNormalCaption && !medium" class="icon-search">
        <IconSearchExam />
      </div>

      <div v-if="showWaiting && !medium" class="spinner">
        <div class="icon-spinner" />
      </div>

      <div v-if="showDone && !medium" class="icon-check">
        <IconCheck />
      </div>
    </RgButton>
  </div>
</template>
<script>
import { IconCheck, IconSearchExam } from "~tokio/primitive/icon/symbols";
import RgButton from "~tokio/primitive/button/rg-button/RgButton.vue";
import RgButtonMixin from "~tokio/primitive/button/rg-button/RgButtonMixin.js";

let timoutDone = null;
const timeoutAction = null;

export default {
  name: "RgSearchButton",
  components: {
    IconCheck,
    IconSearchExam,
    RgButton,
  },
  mixins: [RgButtonMixin],
  props: {
    id: {
      type: String,
      default: "",
    },
    waitLabel: {
      type: String,
      default: "Buscando",
    },
    title: {
      type: String,
      default: "Buscar",
    },
    dataId: {
      type: String,
      default: "buscar",
    },
  },
  data() {
    return {
      requestDone: false,
      timeDone: false,
      showNormalCaption: true,
      showWaiting: false,
      showDone: false,
    };
  },
  methods: {
    clicked() {
      this.actionSubmit();
      this.$emit("submit", true, this.actionDone);
    },
    actionSubmit() {
      this.showNormalCaption = false;
      this.showWaiting = true;
      this.showDone = false;

      return new Promise((resolve, reject) => {
        timoutDone = setTimeout(() => {
          this.timeDone = true;
          this.finish();
          resolve();
        }, 800);
      });
    },
    actionDone() {
      this.showNormalCaption = false;
      this.showWaiting = false;
      this.showDone = true;

      // return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.actionNormalState();
        // resolve();
      }, 800);
      // });
    },
    actionNormalState() {
      this.showNormalCaption = true;
      this.showWaiting = false;
      this.showDone = false;
      clearTimeout(timoutDone);
      clearTimeout(timeoutAction);
    },
    done() {
      this.requestDone = true;
    },
    fail() {
      this.actionNormalState();
    },
    finish() {
      if (this.requestDone && this.timeDone) {
        this.requestDone = false;
        this.timeDone = false;
        this.$emit("complete");
        this.actionDone();
      }
    },
  },
};
</script>
