import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchTransferenceForm(
    $fab_id: Int
    $tfn_data_resposta: String
    $limit: Int
    $offset: Int
    $orderDirection: String
    $orderField: String
  ) {
    SearchTransferenceForm(
      fab_id: $fab_id
      tfn_data_resposta: $tfn_data_resposta
      limit: $limit
      offset: $offset
      orderDirection: $orderDirection
      orderField: $orderField
    ) {
      rows {
        tfn_id
        tfn_ficha_nova
        tfn_data_resposta
        tfn_ficha_original
        tfn_resposta_solicitacao
        original_form {
          microarea {
            mic_descricao
          }
          pab_nome
          patientHome {
            pdo_id
            home {
              address {
                end_logradouro
                end_numero
                neighborhood {
                  bai_nome
                }
              }
            }
          }
        }
        new_form {
          microarea {
            mic_descricao
          }
          header_form {
            team {
              equ_nome_equipe
            }
            basic_attention_employee {
              employee {
                person {
                  pes_nome
                }
              }
            }
          }
        }
      }
      count
    }
  }
`;

export default async (pContext, pFilter) => {
  try {
    const variables = { ...pFilter, tfn_data_resposta: null };
    const { data } = await GraphQLClient.query({
      query,
      fetchPolicy: "no-cache",
      variables,
    });
    return data.SearchTransferenceForm;
  } catch (err) {
    console.log(err);
    return err.graphQLErrors;
  }
};
