import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (context, { intIdFila, intQtdPosicoes }) => {
  return AuthLegacyRequest.post("consultas/controller-fila-consulta/recuar", {
    intIdFila,
    intQtdPosicoes,
  })
    .then(({ data }) => {
      data = HandleResponse(data);
      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
