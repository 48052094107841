import SurgeryStoreHelper from "./surgery-store-helper/SurgeryStoreHelper";
import moment from "moment";

const mutations = {
  ADD_FILTER(state, pFilterItem) {
    const filterListAdded = Object.assign({}, state.filterListBy, pFilterItem);
    state.filterListBy = filterListAdded;
  },
  REMOVE_FILTER(state, pFilterItem) {
    const tmpFilterList = { ...state.filterListBy };
    delete tmpFilterList[pFilterItem];
    state.filterListBy = tmpFilterList;
  },
  LIST_ITEMS(state, pListItems) {
    state.listItems = pListItems;
  },
  PUSH_LIST_ITEMS(state, pListItems) {
    state.listItems.push(...pListItems);
  },
  CHANGE_SURGERY_CALENDAR(state, pObjCalendar) {
    state.calendar.actualDate = pObjCalendar.date;
    state.calendar.sector = pObjCalendar.sector;
    state.calendar.config = {
      startTime: pObjCalendar.date.getTime(),
      endTime: new Date(
        `${moment(pObjCalendar.date).format("YYYY-MM-DD")}T23:59:59`,
      ).getTime(),
    };
  },
  LOAD_CALENDAR(state, pSurgeries) {
    const calendarMap = {};
    const groupedByStage = SurgeryStoreHelper.groupByStage(pSurgeries);

    const groupedByTime = {};
    for (const pStageItem in groupedByStage) {
      groupedByTime[pStageItem] = SurgeryStoreHelper.groupByTime(
        groupedByStage[pStageItem] || [],
      );
      calendarMap[pStageItem] = SurgeryStoreHelper.organizeMap(
        groupedByTime[pStageItem],
      );
    }
    state.calendar.calendarMap = calendarMap;
  },
  LOAD_STAGES(state, pStages) {
    state.stages = pStages;
  },
  SCROLL_COLUMN_CONTAINER(state, pScrollPosition) {
    const actualPosition = state.calendar.stageColumnContainer.posLeft;
    state.calendar.stageColumnContainer.posLeft =
      parseInt(actualPosition) + pScrollPosition;
  },
  SET_STAGE_CONTAINER_WIDTH(state, pStageWidth) {
    state.calendar.stageColumnContainer.stageContainerWidth = pStageWidth;
  },
  SET_STAGE_INSIDE_WIDTH(state, pStageWidth) {
    state.calendar.stageColumnContainer.stageInsideWidth = pStageWidth;
  },
  SELECT_ITEM(state, pSurgeryEditing) {
    state.selectedItem = pSurgeryEditing;
  },
  CLEAN_SELECTION(state) {
    state.selectedItem = null;
  },
  // ADD_PAGE_OFFET (state) { state.filterListBy.offset += state.filterListBy.limit },
  // REMOVE_PAGE_OFFET (state) { state.filterListBy.offset -= state.filterListBy.limit },
  // RESET_PAGE_OFFSET (state) { state.filterListBy.offset = 0 }
};

export default mutations;
