/**
 * Monta o número de apresentacao
 * @param {string} pPeriod
 */
const MountPresentation = (pPeriod) => {
  let year = parseInt(pPeriod.substring(0, 4));
  let month = parseInt(pPeriod.substring(4, 6));

  // #1640 apresentação deve ser 1 mês após a competência
  if (month === 12) {
    month = 1;
    year += 1;
  } else {
    month = month + 1;
  }

  return month.toString().padStart(2, "0") + year.toString();
};

export default MountPresentation;
