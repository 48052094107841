<template>
  <RgSuggest
    ref="rgSuggest"
    v-model="inputValue"
    :data-id="dataId"
    :label="label"
    :placeholder="placeholder"
    :search-on-focus="searchOnFocus"
    :rules="rules"
    :disabled="disabled"
    min="2"
  >
    <RgSuggestItem
      v-for="(item, idx) in suggestionList"
      :key="idx"
      :data-item="dataItem"
      :idx="idx"
      :item="item"
      class="rg-suggest-procedure"
    >
      <div class="procedure-row-top">
        <span class="procedure-name">
          {{ item.inp_novo_codigo_procedimento }}
        </span>
      </div>
      <div class="procedure-row-info">
        {{ item.inp_novo_nome_procedimento }}
      </div>
    </RgSuggestItem>
  </RgSuggest>
</template>
<script>
import { isEmpty } from "lodash";
import {
  RgSuggest,
  RgSuggestItem,
  RgSuggestMixin,
} from "~tokio/primitive/selection";
import SearchProcedures from "./action/SearchProcedures";
export default {
  name: "RgSuggestProcedure",
  components: {
    RgSuggest,
    RgSuggestItem,
  },
  mixins: [RgSuggestMixin],
  props: {
    fieldCaption: {
      default: "inp_novo_nome_procedimento",
    },
    alertEmptyResult: {
      type: Boolean,
      default: false,
    },
    dataItem: {
      type: String,
      default: "",
    },

    patientGender: {
      type: String,
      default: "",
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      default: "Procedimento",
    },
  },
  data() {
    return {
      captionRaw: "inp_novo_codigo_procedimento - inp_novo_nome_procedimento",
    };
  },
  computed: {
    searchOnFocus() {
      return this.inputValue === "" || this.inputValue === null;
    },
  },
  mounted() {
    this.captionRaw = this.fieldCaption;
  },
  methods: {
    validate() {
      if (this.$refs.rgSuggest) this.$refs.rgSuggest.validate();
    },
    cleanValidate() {
      if (this.$refs.rgSuggest) this.$refs.rgSuggest.cleanValidate();
    },
    doSearch(searchQuery, pLimit = null) {
      const variables = this.defineQuery(searchQuery, pLimit);
      return this.searchProcedure(variables);
    },
    async searchProcedure(variables) {
      const procedures = await SearchProcedures({ variables });
      if (this.alertEmptyResult && procedures.count === 0) {
        this.$toaster.warning("Nenhum procedimento encontrado");
      }

      return procedures;
    },
    defineQuery(pSearchTerm, pLimit) {
      const variables = {
        inp_novo_nome_procedimento: null,
        inp_novo_codigo_procedimento: null,
        inp_sexo: this.patientGender,
        limit: pLimit,
      };

      const query = pSearchTerm ? pSearchTerm.toString().trim() : null;
      const isSearchByCode = !isNaN(query);

      if (isSearchByCode) {
        variables.inp_novo_codigo_procedimento = query;
      } else {
        variables.inp_novo_nome_procedimento = query;
      }

      return variables;
    },
    forceSelection(pValue) {
      const hasData = !isEmpty(pValue);
      const isValidData =
        hasData &&
        pValue.inp_novo_nome_procedimento &&
        pValue.inp_novo_codigo_procedimento > 0;

      if (hasData && isValidData) {
        this.$refs.rgSuggest.itemSelected = true;
        if (
          !this.suggestionList ||
          (this.suggestionList && this.suggestionList.length === 0)
        ) {
          this.suggestionList.push({
            inp_novo_codigo_procedimento: pValue.inp_novo_codigo_procedimento,
            inp_novo_nome_procedimento: pValue.inp_novo_nome_procedimento,
          });
        }
        this.$refs.rgSuggest.$parent.selectingItemFromSuggestList(pValue);
      }
    },
  },
};
</script>
