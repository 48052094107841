import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  return AuthLegacyRequest.post(
    "exames2/controller-exame-escala/buscar",
    variables,
  )
    .then(({ data }) => {
      if (data?.dados?.recordSet) {
        const tmp = data.dados.recordSet;
        tmp.map((el) => {
          const color = el.ees_cor.split("0x");
          const hex = color && color[1];
          const hexFormatted = hex && "#" + ("000000" + hex).slice(-6);
          el.ees_cor = hexFormatted;
        });

        data.dados.data = data.dados.recordSet;

        delete data.dados.recordSet;
      }

      return data.dados;
    })
    .catch((pErr) => {
      console.log("error", pErr);
      throw pErr;
    });
};
