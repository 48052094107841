import moment from "moment";
import { pick } from "lodash";
import { BrazilianDateToDatabase } from "~utils/date";

const SurgeryPerformHelper = {};

SurgeryPerformHelper.defineFormSteps = {
  basicInfo: {
    cci_id_funcionarios: "",
    cci_id_locais_atendimento: "",
    cci_id_internacoes_clinicas: "",
    cci_id_raios_x: "",
    cci_id_tipos_anestesias: "",
    cci_id_tipos_cirurgias: 1,
    cci_id_tipos_procedimentos: 1,
    cci_id_setores: "",
    cci_data_inicio: "",
    cci_hora_inicio: "",
    cci_duracao: "",
    cci_reserva_sangue: 0,
    cci_cti: 0,
    cci_observacao: "",
    cci_ativo: "",
    cci_numero: "",
    ccf_id_contaminacoes: "",
    ccf_data_entrada: "",
    ccf_hora_entrada: "",
    ccf_data_inicio: "",
    ccf_hora_inicio: "",
    ccf_data_termino: "",
    ccf_hora_termino: "",
    ccf_data_saida: "",
    ccf_hora_saida: "",
    procedures: [],
    employees: [],
    materials: [],
    kits: [],
    equipments: [],
    surgery_relationship: null,
    cci_id_finalidades: 0,
    cci_retorno_infeccao: 0,
  },
  additionalInfo: {
    ccf_diagnostico_pre_operatorio: "",
    ccf_relatorio_histopatologico: "",
    ccf_achados_per_operatorio: "",
    ccf_intercorrencias: "",
    ccf_descricao_cirurgia: "",
  },
  anesthesia: {
    ane_altura: "",
    ane_analgesias: "",
    ane_anestesia: "",
    ane_data_anestesia: "",
    ane_hora_anestesia: "",
    ane_id: "",
    ane_id_controles_cirurgicos_efetivacoes: "",
    ane_ocorrencias: "",
    ane_peso: "",
    ane_risco: "",
    ane_suporte_ventilatorio: "",
    ane_tecnica: "",
  },
  postAnesthesias: [],
  exitReason: {
    ccs_data: "",
    ccs_hora: "",
    ccs_id: "",
    ccs_id_controles_cirurgicos_efetivacoes: "",
    ccs_id_motivos_saidas: "",
    ccs_observacao: "",
  },
};

SurgeryPerformHelper.setFormSteps = (
  pCciId,
  pPacientesCirurgicosId,
  pFormSteps,
) => {
  const ret = {
    cci_id: pCciId,
    cci_id_funcionarios: pFormSteps.basicInfo.cci_id_funcionarios,
    cci_id_locais_atendimento: pFormSteps.basicInfo.cci_id_locais_atendimento,
    cci_id_pacientes_cirurgicos: pPacientesCirurgicosId,
    cci_id_internacoes_clinicas:
      pFormSteps.basicInfo.cci_id_internacoes_clinicas,
    cci_id_raios_x: pFormSteps.basicInfo.cci_id_raios_x,
    cci_id_tipos_anestesias: pFormSteps.basicInfo.cci_id_tipos_anestesias,
    cci_id_tipos_cirurgias: pFormSteps.basicInfo.cci_id_tipos_cirurgias,
    cci_id_tipos_procedimentos: pFormSteps.basicInfo.cci_id_tipos_procedimentos,
    cci_id_setores: pFormSteps.basicInfo.cci_id_setores,
    cci_data_inicio: pFormSteps.basicInfo.ccf_data_entrada,
    cci_hora_inicio: moment(
      pFormSteps.basicInfo.ccf_hora_inicio,
      "HH mm ss",
    ).format("HH:mm:ss"),
    cci_duracao: pFormSteps.cci_duracao,
    cci_reserva_sangue: pFormSteps.basicInfo.cci_reserva_sangue,
    cci_cti: pFormSteps.basicInfo.cci_cti,
    cci_observacao: pFormSteps.basicInfo.cci_observacao,
    cci_ativo: pFormSteps.cci_ativo,
    employees: filterObjectsKeys(pFormSteps.basicInfo.employees, [
      "ceq_id_funcoes",
      "ceq_id_funcionarios",
      "ceq_id_ocupacoes_cbo",
      "ceq_ocupacoes_cbo",
      "ceq_nome_funcionario",
    ]),
    kits: filterObjectsKeys(pFormSteps.basicInfo.kits, [
      "kic_id_tipos_kits_cirurgicos",
    ]),
    equipments: filterObjectsKeys(pFormSteps.basicInfo.equipments, [
      "cec_id_tipos_equipamentos_cirurgicos",
    ]),
    materials: filterObjectsKeys(pFormSteps.basicInfo.materials, [
      "cmc_id_tipos_materiais_cirurgicos",
      "cmc_qtd",
    ]),
    procedures: filterObjectsKeys(pFormSteps.basicInfo.procedures, [
      "cpr_id_classes",
      "cpr_id_portes",
      "cpr_id_tabelas_unificadas",
    ]),
    surgery_relationship: pFormSteps.basicInfo.surgery_relationship,
    cci_id_finalidades: pFormSteps.basicInfo.cci_id_finalidades,
    cci_retorno_infeccao: pFormSteps.basicInfo.cci_retorno_infeccao,
    perform: {
      info: {
        ccf_id: pFormSteps.additionalInfo.ccf_id,
        ccf_id_contaminacoes: pFormSteps.basicInfo.ccf_id_contaminacoes,
        ccf_data_entrada: BrazilianDateToDatabase(
          pFormSteps.basicInfo.ccf_data_entrada,
        ),
        ccf_hora_entrada: moment(
          pFormSteps.basicInfo.ccf_hora_entrada,
          "HH mm ss",
        ).format("HH:mm:ss"),
        ccf_data_inicio: BrazilianDateToDatabase(
          pFormSteps.basicInfo.ccf_data_inicio,
        ),
        ccf_hora_inicio: moment(
          pFormSteps.basicInfo.ccf_hora_inicio,
          "HH mm ss",
        ).format("HH:mm:ss"),
        ccf_data_termino: BrazilianDateToDatabase(
          pFormSteps.basicInfo.ccf_data_termino,
        ),
        ccf_hora_termino: moment(
          pFormSteps.basicInfo.ccf_hora_termino,
          "HH mm ss",
        ).format("HH:mm:ss"),
        ccf_data_saida: BrazilianDateToDatabase(
          pFormSteps.basicInfo.ccf_data_saida,
        ),
        ccf_hora_saida: moment(
          pFormSteps.basicInfo.ccf_hora_saida,
          "HH mm ss",
        ).format("HH:mm:ss"),
        ccf_diagnostico_pre_operatorio:
          pFormSteps.additionalInfo.ccf_diagnostico_pre_operatorio,
        ccf_relatorio_histopatologico:
          pFormSteps.additionalInfo.ccf_relatorio_histopatologico,
        ccf_achados_per_operatorio:
          pFormSteps.additionalInfo.ccf_achados_per_operatorio,
        ccf_intercorrencias: pFormSteps.additionalInfo.ccf_intercorrencias,
        ccf_descricao_cirurgia:
          pFormSteps.additionalInfo.ccf_descricao_cirurgia,
      },
      postAnesthesias: formatPostAnesthesiaToSave(pFormSteps.postAnesthesias),
      anesthesia: formatAnesthesiaToSave(pFormSteps.anesthesia),
      exitReason: formatExitReasonToSave(pFormSteps.exitReason),
    },
  };
  return ret;
};

SurgeryPerformHelper.fillBasicStructure = (pForm) => {
  const structure = {
    cci_id: pForm.cci_id,
    cci_id_funcionarios: pForm.cci_id_funcionarios,
    cci_id_locais_atendimento: pForm.cci_id_locais_atendimento,
    cci_id_pacientes_cirurgicos: pForm.cci_id_pacientes_cirurgicos,
    cci_id_internacoes_clinicas: pForm.cci_id_internacoes_clinicas,
    cci_id_raios_x: pForm.cci_id_raios_x,
    cci_id_tipos_anestesias: pForm.cci_id_tipos_anestesias,
    cci_id_tipos_cirurgias: pForm.cci_id_tipos_cirurgias,
    cci_id_tipos_procedimentos: pForm.cci_id_tipos_procedimentos,
    cci_id_setores: pForm.cci_id_setores,
    cci_data_inicio: moment(pForm.cci_data_inicio).format("DD/MM/YYYY"),
    cci_hora_inicio: pForm.cci_hora_inicio,
    cci_duracao: pForm.cci_duracao,
    cci_reserva_sangue: pForm.cci_reserva_sangue,
    cci_cti: pForm.cci_cti,
    cci_observacao: pForm.cci_observacao,
    cci_ativo: pForm.cci_ativo,
    surgery_relationship: pForm.surgery_relationship,
    cci_id_finalidades: pForm.cci_id_finalidades,
    cci_retorno_infeccao: pForm.cci_retorno_infeccao,
    procedures: pForm.procedures,
    employees: pForm.employees,
    materials: pForm.materials,
    kits: pForm.kits,
    equipments: pForm.equipments,
  };

  return structure;
};

SurgeryPerformHelper.fillBasicPerformInfo = (pForm) => {
  let structure = {};
  if (pForm.perform && pForm.perform.info) {
    structure = {
      ccf_data_entrada: moment(pForm.perform.info.ccf_data_entrada).format(
        "DD/MM/YYYY",
      ),
      ccf_hora_entrada: pForm.perform.info.ccf_hora_entrada,
      ccf_data_inicio: moment(pForm.perform.info.ccf_data_inicio).format(
        "DD/MM/YYYY",
      ),
      ccf_hora_inicio: pForm.perform.info.ccf_hora_inicio,
      ccf_data_termino: moment(pForm.perform.info.ccf_data_termino).format(
        "DD/MM/YYYY",
      ),
      ccf_hora_termino: pForm.perform.info.ccf_hora_termino,
      ccf_data_saida: moment(pForm.perform.info.ccf_data_saida).format(
        "DD/MM/YYYY",
      ),
      ccf_hora_saida: pForm.perform.info.ccf_hora_saida,
      ccf_id_contaminacoes: pForm.perform.info.ccf_id_contaminacoes,
    };
  }
  return structure;
};

SurgeryPerformHelper.fillBasicInfo = (pForm, pFormStep) => {
  let basicStructure = {};
  let basicPerformInfoStructure = {};

  basicStructure = SurgeryPerformHelper.fillBasicStructure(pForm);
  basicPerformInfoStructure = SurgeryPerformHelper.fillBasicPerformInfo(pForm);

  pFormStep.basicInfo = { ...basicStructure, ...basicPerformInfoStructure };
};

SurgeryPerformHelper.fillAdditionalInfo = (pForm, pFormStep) => {
  pFormStep.additionalInfo =
    pForm.perform && pForm.perform.info ? pForm.perform.info : {};
};

SurgeryPerformHelper.fillAnesthesia = (pForm, pFormStep) => {
  pFormStep.anesthesia =
    pForm.perform && pForm.perform.anesthesia ? pForm.perform.anesthesia : {};
};

SurgeryPerformHelper.fillExitReason = (pForm, pFormStep) => {
  if (pForm.perform && pForm.perform.exitReason) {
    pFormStep.exitReason = pForm.perform.exitReason;
    pFormStep.exitReason.ccs_hora = moment(
      pFormStep.exitReason.ccs_hora,
      "HH mm ss",
    ).format("HH:mm");
  } else {
    pFormStep.exitReason = {
      ccs_data: "",
      ccs_hora: "",
      ccs_id: "",
      ccs_id_controles_cirurgicos_efetivacoes: "",
      ccs_id_motivos_saidas: "",
      ccs_observacao: "",
    };
  }
};

SurgeryPerformHelper.fillPostAnesthesias = (pForm, pFormStep) => {
  pFormStep.postAnesthesias =
    pForm.perform && pForm.perform.postAnesthesias
      ? pForm.perform.postAnesthesias
      : [];
};

const formatExitReasonToSave = (pExitReason) => {
  const returnExitReason = {};
  for (const exitReason in pExitReason) {
    const isNotEmpty =
      pExitReason[exitReason] && pExitReason[exitReason] !== "";
    if (isNotEmpty) returnExitReason[exitReason] = pExitReason[exitReason];
  }
  if (returnExitReason && returnExitReason.ccs_data)
    returnExitReason.ccs_data = BrazilianDateToDatabase(
      returnExitReason.ccs_data,
    );
  if (returnExitReason && returnExitReason.mos_nome)
    delete returnExitReason.mos_nome;
  return returnExitReason;
};

const formatAnesthesiaToSave = (pAnesthesia) => {
  let returnAnesthesia = {};
  for (const anesthesia in pAnesthesia) {
    const isNotEmpty =
      pAnesthesia[anesthesia] && pAnesthesia[anesthesia] !== "";
    if (isNotEmpty) returnAnesthesia[anesthesia] = pAnesthesia[anesthesia];
  }
  const cloneReturnAnesthesia = Object.assign({}, returnAnesthesia);
  delete cloneReturnAnesthesia.ane_id;
  delete cloneReturnAnesthesia.ane_id_controles_cirurgicos_efetivacoes;

  if (returnAnesthesia.ane_data_anestesia)
    returnAnesthesia.ane_data_anestesia = BrazilianDateToDatabase(
      pAnesthesia.ane_data_anestesia,
    );
  if (returnAnesthesia.ane_hora_anestesia)
    returnAnesthesia.ane_hora_anestesia = moment(
      pAnesthesia.ane_hora_anestesia,
      "HH mm ss",
    ).format("HH:mm:ss");

  if (Object.keys(cloneReturnAnesthesia).length === 0) returnAnesthesia = {};
  return returnAnesthesia;
};

const formatPostAnesthesiaToSave = (postAnesthesias) => {
  const postAnesthesia = postAnesthesias.map((pAnesthesia) => {
    const retPostAnesthesia = { ...pAnesthesia };
    retPostAnesthesia.poa_data_pos_anestesico = BrazilianDateToDatabase(
      retPostAnesthesia.poa_data_pos_anestesico,
    );
    retPostAnesthesia.poa_hora_pos_anestesico = moment(
      retPostAnesthesia.poa_hora_pos_anestesico,
      "HH:mm",
    ).format("HH:mm:ss");
    return retPostAnesthesia;
  });
  return postAnesthesia;
};

const filterObjectsKeys = (pObjs, pKeys) => {
  return pObjs.map((pObj) => pick(pObj, pKeys));
};

export { SurgeryPerformHelper };
