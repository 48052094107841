import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query($relationshipSectotId: Int) {
    disablingEmployeeRelationshipSectors(
      vni_id_vinculos_setores: $relationshipSectotId
    ) {
      rows {
        vni_id
        vni_id_vinculos_setores
        vni_id_motivos_inabilitar_funcionarios
        vni_data_inicial
        vni_data_final
        disablingReason {
          mif_nome
        }
      }
    }
  }
`;

export default async (store, pData = {}) => {
  const criterias = pData.criterias || {};
  const variables = {
    relationshipSectotId: criterias.vni_id_vinculos_setores || undefined,
  };

  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.disablingEmployeeRelationshipSectors.rows;
  } catch (err) {
    return err.graphQLErrors;
  }
};
