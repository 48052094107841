import {
  GraphQLClient,
  gql,
  removeTypename,
} from "@/common/request/GraphQLClient";
import { BrazilianDateFormat } from "~utils/date";
// dom_latitude,
// dom_longitude,
// dom_unico_original,
// dom_unico_alterado,

const query = gql`
  query HomeRegisterForm($dom_id: Int) {
    SearchHome(dom_id: $dom_id) {
      rows {
        dom_id
        dom_id_cabecalho_fichas
        dom_id_endereco
        dom_id_situacao_moradia
        dom_id_localizacao_moradia
        dom_id_tipos_domicilio
        dom_id_producao_rural
        dom_id_acesso_domicilio
        dom_id_material_construcao_domicilio
        dom_id_abastecimento_agua
        dom_id_tratamento_agua
        dom_id_escoamento_banheiro
        dom_latitude
        dom_longitude
        dom_id_destino_lixo
        dom_id_microareas
        dom_id_tipos_imovel
        dom_id_instituicoes_permanencia
        dom_numero_moradores
        dom_numero_comodos
        dom_energia_eletrica
        dom_descricao
        dom_data_atendimento
        dom_animais_domicilio
        dom_qtd_animais_domicilio
        dom_nome
        dom_rg
        dom_status_recusa
        dom_fora_area

        address {
          end_logradouro
          end_numero
          end_complemento
          end_cep
          end_referencia
          neighborhood {
            bai_nome
          }
        }

        animals {
          tad_id
          tad_tipos_animais
        }
        family_responsables {
          ref_id
          ref_id_domicilio
          ref_id_renda_familiar
          ref_prontuario_familia
          ref_documento
          ref_nascimento
          ref_membros_familia
          ref_reside_desde
          ref_mudou
          patient {
            microarea {
              mic_id
              mic_descricao
            }
            pab_id
            pab_id_cabecalho_fichas
            pab_id_pacientes
            pab_id_grau_parentesco
            pab_id_ocupacoes_cbo
            pab_id_escolaridade_atencao_basica
            pab_id_situacao_mercado_trabalho
            pab_id_orientacao_sexual
            pab_id_microareas
            pab_id_saida_cidadao_cadastro
            pab_id_etnias
            pab_id_genero_cidadao
            pab_comunidade_tradicional
            pab_mae_desconhecida
            pab_pai_desconhecido
            pab_frequenta_escola_creche
            pab_cuidador_tradicional
            pab_grupo_comunitario
            pab_possui_plano_saude
            pab_sincronizacao
            pab_nome_ocupacao
            pab_celular
            pab_unico_original
            pab_unico_alterado
            pab_data_atendimento
            pab_fora_area
            pab_data_naturalizacao
            pab_portaria_naturalizacao
            pab_data_entrada_brasil
            pab_responsavel_familiar
            pab_id_sexos
            pab_id_ab_etnias
            pab_id_nacionalidades
            pab_numero_pis
            pab_documento_responsavel
            pab_documento
            pab_nome_mae
            pab_nome_pai
            pab_nome
            pab_email
            pab_nascimento
            pab_nome_social
            pab_id_municipio_nascimento
            pab_status_recusa
            pab_rg
          }
          members(includeResponsable: 1) {
            pab_id
          }
        }
        homePhone
        referencePhone
      }
      count
    }
  }
`;

export default async (pContext, pFilter) => {
  try {
    const variables = { ...pFilter };
    const { data } = await GraphQLClient.query({
      query,
      fetchPolicy: "no-cache",
      variables,
    });

    const result = removeTypename(data.SearchHome.rows[0]);

    const { ...rest } = result;
    rest.family_responsables = rest.family_responsables.map((item) => {
      const ref_reside_desde = item.ref_reside_desde
        ? BrazilianDateFormat(item.ref_reside_desde)
        : null;
      const ref_nascimento = item.ref_nascimento
        ? BrazilianDateFormat(item.ref_nascimento)
        : null;
      return {
        ...item,
        ref_reside_desde,
        ref_nascimento,
      };
    });
    return rest;
  } catch (err) {
    console.log(err);
    return err.graphQLErrors;
  }
};
