import { GraphQLClient, gql } from "@/common/request/GraphQLClient";
import { BrazilianDateFormat } from "~utils/date";

const query = gql`
  query HomeVisitForm($cvd_id: Int) {
    SearchHomeVisitForm(cvd_id: $cvd_id) {
      rows {
        cvd_id
        cvd_id_cabecalho_fichas
        cvd_id_turno
        cvd_id_desfecho_visita
        cvd_id_sexos
        cvd_id_microareas
        cvd_id_tipos_imovel
        cvd_visita_compartilhada
        cvd_data
        cvd_prontuario
        cvd_documento
        cvd_nascimento
        cvd_observacao
        cvd_fora_area
        cvd_peso
        cvd_altura
        visit_reason {
          mvi_id
        }
        active_search {
          bat_id
        }
        followup {
          aco_id
        }
        environment_control {
          cov_id
        }
      }
      count
    }
  }
`;
export default async (pContext, pFilter) => {
  try {
    const variables = { ...pFilter };
    const { data } = await GraphQLClient.query({
      query,
      fetchPolicy: "no-cache",
      variables,
    });
    const result = data.SearchHomeVisitForm.rows[0];

    result.visit_reason = result.visit_reason.map((i) => i.mvi_id);
    result.active_search = result.active_search.map((i) => i.bat_id);
    result.followup = result.followup.map((i) => i.aco_id);
    result.environment_control = result.environment_control.map(
      (i) => i.cov_id,
    );
    result.cvd_nascimento = BrazilianDateFormat(result.cvd_nascimento);
    result.cvd_data = BrazilianDateFormat(result.cvd_data);

    return result;
  } catch (err) {
    console.log(err);
    return err.graphQLErrors;
  }
};
