<style src="./RgPatientCardCommands.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="rg-commands">
    <ul class="rg-commands--list-container">
      <!-- <li class="rg-commands --list-item">
        <rg-enable-disable-patient
          :patient-status="patientData.pac_bloqueado"
          @status-changed="changeStatus" />
      </li> -->
      <!-- <li class="rg-commands--list-item">
        <rg-enable-disable-patient
          :patient-status="patientData.pac_bloqueado"
          @status-changed="changeStatus" />
      </li> -->
      <li
        class="rg-commands--list-item"
        title="Imprimir carteirinha"
        @click="printDoc('/printcard', hasPrintCardPermission)"
      >
        <div v-show="loadingPrintCard" class="rg-spinner rg-spinner__small" />
        <IconCard v-show="!loadingPrintCard" class="rg-commands--icon" />
        <div
          v-if="!hasPrintCardPermission"
          class="permission-locked--action-button"
        >
          <IconLocked class="icon-lock--action-button" />s
        </div>
      </li>

      <li
        class="rg-commands--list-item"
        title="Imprimir ficha"
        @click="printDoc('/printrecord', hasPrintRecordPermission)"
      >
        <div v-show="loadingPrintRecord" class="rg-spinner rg-spinner__small" />
        <IconRecords v-show="!loadingPrintRecord" class="rg-commands--icon" />
        <div
          v-if="!hasPrintRecordPermission"
          class="permission-locked--action-button"
        >
          <IconLocked class="icon-lock--action-button" />
        </div>
      </li>

      <li
        class="newborn-action rg-commands--list-item"
        title="Vincular recém-nascido"
        @click="associateNewBorn()"
      >
        <IconNewborn v-show="!loadingPrintRecord" class="rg-commands--icon" />
      </li>
    </ul>
    <!-- <rg-iframe :src="printLink" v-if="showPrintRecord || showPrintCard" class="iframe-hidden"></rg-iframe> -->
  </section>
</template>

<script>
import {
  IconCard,
  IconRecords,
  IconLocked,
  IconNewborn,
} from "~tokio/primitive/icon";

export default {
  name: "RgPatientCardCommands",
  components: {
    IconCard,
    IconRecords,
    IconLocked,
    IconNewborn,
  },
  props: {
    patientData: {
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      showPrintRecord: false,
      showPrintCard: false,
      hashRefresh: "123",
      loadingPrintRecord: false,
      loadingPrintCard: false,
      linkTo: null,
    };
  },
  computed: {
    printLink() {
      return `${this.linkTo}?pacId=${this.patientData.pac_id}&${this.hashRefresh}`;
    },
    hasPrintCardPermission() {
      return this.$Permissions.global.has(
        "cadastro.prontuario.imprimirCarteirinha",
      );
    },
    hasPrintRecordPermission() {
      return this.$Permissions.global.has("cadastro.prontuario.imprimirFicha");
    },
  },
  mounted() {},
  methods: {
    associateNewBorn() {
      this.$emit("associate-newborn");
    },
    printDoc(pDoc, pPermission) {
      this.$toaster.warning("Funcionalidade não implementada!");
    },
  },
};
</script>
