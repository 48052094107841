import AuthRequest from "~common/request/AuthRequest";
import Store from "@/store";

export default () => {
  const uns_id = Store.getters["Login/GET_UNIT_HEALTH_ID"];
  return AuthRequest.get("billing/bdsia/get-current-period", { uns_id }).then(
    (response) => {
      const formatedPeriod = _formatPeriod(response.data);
      Store.commit("Billing/BDSia/SET_NUMBER", formatedPeriod);
      Store.commit("Billing/BDSia/SET_PERIOD_DATE", response.data);
      return formatedPeriod;
    },
  );
};

const _formatPeriod = (pPeriod) => {
  const periodMonth = pPeriod.substr(4, 2);
  const periodYear = pPeriod.substr(0, 4);
  return `Mês ${periodMonth} de ${periodYear}`;
};
