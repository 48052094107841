<template>
  <svg
    id="Layer_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <path
      class="st0"
      d="M425.9,459.9v43.3c0,4.9-4,8.8-8.8,8.8H94.9c-4.9,0-8.8-4-8.8-8.8v-43.3c0-33.3,19.8-62,48.3-75
      c-11.6-9.2-29.3-21.7-42.2-48.4c-17.9-37.2-14.9-81.1,8-115.4c12.4-18.6,19-40.5,19-62.9v-47.8C119.2,49.4,168.6,0,229.5,0h53
      c60.9,0,110.3,49.4,110.3,110.3c0,50.6-1.2,60.5,4.9,80.7c8.6,28.5,20,32,28.8,61c13.8,45.5-1.5,95.2-39,125.1l-9.8,7.8
      C406.1,397.8,425.9,426.5,425.9,459.9L425.9,459.9z"
    />
    <path
      class="st1"
      d="M425.9,459.9v43.3c0,4.9-4,8.8-8.8,8.8H94.9c-4.9,0-8.8-4-8.8-8.8v-43.3c0-45.4,36.8-82.3,82.3-82.3
      c6.8,0,13.2-1.5,19-4.1l0.3,0.6l63,48.8c3.2,2.5,7.6,2.5,10.8,0l63-48.8l0.3-0.6c5.8,2.6,12.2,4.1,19,4.1
      C388.9,377.6,425.9,414.3,425.9,459.9z"
    />
    <path
      class="st2"
      d="M356.9,161.4c4.7,0.3,8.4,4.1,8.4,8.8V223c0,45.5-27.9,84.6-67.5,101c-71.6,29.7-151-23.1-151-101V171
      c0-5.2,4.4-9.3,9.6-8.8c53,4.8,105.1-8.6,148.9-38.1c5-3.4,11.8-0.9,13.4,4.9C324.1,149.1,338.9,160.4,356.9,161.4L356.9,161.4z"
    />
    <path
      class="st3"
      d="M387.4,377.1l-9.8,7.8c-10.4-4.7-21.9-7.3-34-7.3c-25.2,0-45.7-20.3-45.7-45.6v-7.5l-0.2-0.4
      c39.6-16.4,67.5-55.4,67.5-101v-52.8c0-4.7-3.7-8.5-8.4-8.8c-17.8-1-32.8-12.2-38.3-32.4c-1.6-5.8-8.4-8.3-13.4-4.9
      c-43.8,29.5-95.9,42.9-148.9,38.1c-5.2-0.5-9.6,3.6-9.6,8.8V223c0,45.5,27.9,84.6,67.5,101l-0.2,0.4v7.5c0,25.3-20.5,45.6-45.7,45.6
      c-12.1,0-23.6,2.6-34,7.3l-9.8-7.8c-47.5-37.8-58-105.6-24.5-156c12.4-18.6,19-40.5,19-62.9v-47.8C119.2,49.4,168.6,0,229.5,0h53
      c60.9,0,110.3,49.4,110.3,110.3v47.8c0,22.4,6.6,44.3,19,62.9C445.6,271.7,434.7,339.5,387.4,377.1L387.4,377.1z"
    />
    <path
      class="st4"
      d="M299.2,342.5c-27.2,11.4-57.1,12.3-86.4,0c1.5-6.2,1.2-10.2,1.2-18.1l0.2-0.4c26.6,11,56.9,11.1,83.5,0l0.2,0.4
      C297.9,332.3,297.7,336.3,299.2,342.5z"
    />
    <path
      class="st5"
      d="M332.3,11.9c11.3,5.7,6.4,22.9-6.2,21.7c-3.5-0.3-7-0.5-10.5-0.5h-53c-60.9,0-110.3,49.4-110.3,110.3v13.4
      c0,7.9-5.5,7.7-5.5,14.1c0,58.3,0.2,54.6-0.6,57.1c-8.7,25.5-19.1,29.2-27.5,57c-4.6,15.2-6,30.8-4.4,46
      c1.3,12.4-15.9,18.3-22.2,5.4c-17.8-36.9-15-80.8,8-115.4c12.4-18.6,19-40.5,19-62.9v-47.8C119.2,49.4,168.6,0,229.5,0h53
      C300.4,0,317.4,4.3,332.3,11.9L332.3,11.9z"
    />
    <path
      class="st6"
      d="M235.1,296.2c16.5,11,3.9,36.6-14.8,30c-41.1-14.4-73.5-52.8-73.5-103.2V171c0-5.2,4.4-9.3,9.6-8.8
      c4.2,0.4,8.9,0.7,13.9,0.8c9.1,0.3,16.3,7.8,16.3,16.8v25.5C186.5,243.8,206.1,276.8,235.1,296.2L235.1,296.2z"
    />
    <path
      class="st7"
      d="M205.8,388.2c5.2,4.1,2.5,12.6-4.2,12.6c-46.7,0.2-82.5,37.7-82.5,82.3v29H94.9c-4.9,0-8.8-4-8.8-8.8v-43.3
      c0-45.4,36.8-82.3,82.3-82.3c6.8,0,13.2-1.5,19-4.1l0.3,0.6L205.8,388.2z"
    />
    <path
      class="st7"
      d="M306.2,388.2c-5.2,4.1-2.5,12.6,4.2,12.6c46.7,0.2,82.5,37.7,82.5,82.3v29h24.3c4.9,0,8.8-4,8.8-8.8v-43.3
      c0-45.4-36.8-82.3-82.3-82.3c-6.8,0-13.2-1.5-19-4.1l-0.3,0.6L306.2,388.2z"
    />
  </svg>
</template>

<script>
export default {
  name: "IconPatientWoman",
};
</script>

<style type="text/css">
.st0 {
  fill: #ffe171;
}
.st1 {
  fill: #407baf;
}
.st2 {
  fill: #ffe67b;
}
.st3 {
  fill: #42434d;
}
.st4 {
  fill: #ffc37b;
}
.st5 {
  fill: #4d4e59;
}
.st6 {
  fill: #ffffa5;
}
.st7 {
  fill: #3bc0f2;
}
</style>
