<template>
  <Modulebox
    title="Transferência de Consulta Profissional"
    class="transfer-appointments"
  >
    <div class="content">
      <FormBase class="area" title="Origem">
        <RgValidatorForm ref="validatorOrigin">
          <section class="origin-grid">
            <div class="form">
              <RgInputDate
                ref="dateOrigin"
                v-model="form.origin.date"
                placeholder="dd/mm/aaaa"
                data-id="data"
                :rules="{ required: true }"
                :minDate="Date.now()"
              />

              <ComboboxSector
                ref="comboboxSectorOrigin"
                v-model="form.origin.selectedIdSector"
                label="Setor"
                :intIdUnidade="form.loginUnitHealthId"
                :rules="{ required: true }"
              />

              <div class="employee">
                <ComboboxOriginEmployee
                  ref="comboboxOriginEmployee"
                  v-model="form.origin.selectedIdEmployee"
                  label="Escala"
                  :disabled="!form.origin.selectedIdSector || !form.origin.date"
                  :intIdUnidadeSaude="form.loginUnitHealthId"
                  :intIdSetor="form.origin.selectedIdSector"
                  :strData="form.origin.date"
                  :rules="{ required: true }"
                  @loading="loadingOriginEmployee"
                />
              </div>
            </div>

            <div class="table">
              <div class="content">
                <div class="title">Pacientes agendados</div>
                <SmartTable
                  ref="smartTableOrigin"
                  name="TransferAppointments"
                  v-bind="propsSmartTableOrigin"
                  class="smart-table"
                  @getMultLines="onlistSelectedOriginPatients"
                />
              </div>
            </div>

            <div class="preview">
              <div class="title">Pacientes para transferência</div>
              <div class="preview-box">
                <div
                  v-for="(item, index) in form.listSelectedOriginPatients"
                  :key="`pb-${index}`"
                  class="item"
                >
                  {{ item.pes_nome }}
                </div>
              </div>
              <div class="footer">
                <div class="end">
                  Pacientes selecionados:
                  {{ form.listSelectedOriginPatients.length }}
                </div>
              </div>
            </div>
          </section>
        </RgValidatorForm>
      </FormBase>

      <FormBase class="area" title="Destino">
        <RgValidatorForm ref="validatorDestiny">
          <section class="destiny-grid">
            <div class="form">
              <RgInputDate
                ref="dateDestiny"
                v-model="form.destiny.date"
                placeholder="dd/mm/aaaa"
                data-id="data"
                class="inputitem"
                :rules="{ required: true }"
                :minDate="Date.now()"
              />

              <ComboboxSector
                ref="comboboxSectorDestiny"
                v-model="form.destiny.selectedIdSector"
                label="Setor"
                :intIdUnidade="form.loginUnitHealthId"
                :rules="{ required: true }"
              />

              <ComboboxPlace
                ref="comboboxPlaceDestiny"
                v-model="form.destiny.selectedIdPlace"
                label="Local de Atendimento"
                :disabled="!form.destiny.selectedIdSector"
                :intIdSetor="form.destiny.selectedIdSector"
                :rules="{ required: true }"
              />

              <ComboboxDestinyEmployee
                ref="comboboxDestinyEmployee"
                v-model="form.destiny.selectedIdEmployee"
                :disabled="!form.destiny.selectedIdSector || !form.destiny.date"
                :strData="form.destiny.date"
                label="Escala"
                :intIdUnidadeSaude="form.loginUnitHealthId"
                :intIdSetor="form.destiny.selectedIdSector"
                :blnHagAtendimento="null"
                :rules="{ required: true }"
              />

              <div
                :class="{
                  available: vacanciesAvaliable,
                  notAvailable: vacanciesNotAvaliable,
                }"
                class="vacancies"
              >
                {{ vacanciesText }}
              </div>
            </div>
          </section>
        </RgValidatorForm>
      </FormBase>
    </div>

    <div class="appointments-footer">
      <RgCleanButton
        small
        type="button"
        title="Limpar"
        @click="actionCleanForm"
      />

      <div class="margin-left">
        <RgSaveButton
          ref="saveButton"
          large
          class="save"
          type="button"
          label="Transferir"
          @click="actionSaveForm"
        />
      </div>
    </div>

    <ModalPrintTransfer
      :v-if="showModalPrintTransfer"
      :show="showModalPrintTransfer"
      @confirm="actionPrint"
      @close="closeModalPrint"
    />
  </Modulebox>
</template>

<script>
import {
  RgInputDate,
  RgCleanButton,
  RgSaveButton,
  RgValidatorForm,
} from "~tokio/primitive";
import SmartTable from "~tokio/foundation/smart-table/SmartTable.vue";

import ComboboxPlace from "$appointment/submodules/schedule/component/combobox/transfer-place/RgComboboxTransferPlace";
import ComboboxSector from "$appointment/submodules/schedule/component/combobox/transfer-sector/RgComboboxTransferSector";
import ComboboxOriginEmployee from "$appointment/submodules/schedule/component/combobox/transfer-origin-employee/RgComboboxTransferOriginEmployee.vue";
import ComboboxDestinyEmployee from "$appointment/submodules/schedule/component/combobox/transfer-destiny-employee/RgComboboxTransferDestinyEmployee.vue";

import ModalPrintTransfer from "$appointment/submodules/schedule/component/modal/modal-print-transfer/ModalPrintTransfer";
import PrintTransferHTML from "$appointment/submodules/schedule/html/transfer/Transfer";

import Modulebox from "~tokio/foundation/modulebox/Modulebox";
import FormBase from "~tokio/foundation/form-base/FormBase";

const FORM_BASE = {
  origin: {
    date: null,
    selectedIdSector: null,
    selectedIdEmployee: null,
    employee: null,
    sector: null,
  },
  destiny: {
    date: null,
    selectedIdSector: null,
    selectedIdPlace: null,
    selectedIdEmployee: null,
    employee: null,
    sector: null,
  },
  listSelectedOriginPatients: [],
  listOriginPatients: [],
  listDestinyPatients: [],
  availableVacancies: 0,
  loginUnitHealthId: null,
  loginUnitHealthName: null,
};

const COLUMN_TABLE = [
  { name: "Paciente", key: "pes_nome" },
  { name: "Horário", key: "con_hora" },
];

export default {
  name: "TransferAppointments",
  components: {
    RgCleanButton,
    RgSaveButton,
    RgInputDate,
    SmartTable,
    ComboboxSector,
    ComboboxOriginEmployee,
    ComboboxPlace,
    ComboboxDestinyEmployee,
    ModalPrintTransfer,
    Modulebox,
    FormBase,
    RgValidatorForm,
  },

  data() {
    return {
      form: this.$utils.obj.DeepCopy(FORM_BASE),
      showModalPrintTransfer: false,
      sessionIdPrint: null,
    };
  },

  computed: {
    propsSmartTableOrigin() {
      const body = this.form.listOriginPatients;
      const total = this.form.listOriginPatients.length;
      const columns = COLUMN_TABLE;
      const indexColumn = "id";
      const showPagination = false;
      const multSelect = true;
      const removeBtnColumns = true;
      const hasCheck = true;

      return {
        body,
        total,
        columns,
        indexColumn,
        showPagination,
        multSelect,
        removeBtnColumns,
        hasCheck,
      };
    },

    disableDestiny() {
      return this.form.listSelectedOriginPatients.length <= 0;
    },

    disableBtnTransfer() {
      let disable = false;
      if (this.disableDestiny) {
        disable = true;
      } else {
        if (!this.form.destiny.employee || !this.form.destiny.selectedIdPlace) {
          disable = true;
        }
      }

      return disable;
    },

    vacanciesText() {
      if (
        this.form.availableVacancies <
        this.form.listSelectedOriginPatients.length
      ) {
        return `Vagas insuficientes`;
      }
      return `Vagas disponíveis: ${this.form.availableVacancies}`;
    },
    vacanciesAvaliable() {
      return (
        this.form.availableVacancies > 0 &&
        this.form.availableVacancies >=
          this.form.listSelectedOriginPatients.length
      );
    },
    vacanciesNotAvaliable() {
      return (
        this.form.availableVacancies === 0 ||
        this.form.availableVacancies <
          this.form.listSelectedOriginPatients.length
      );
    },
  },

  watch: {
    "form.origin.date"(value) {
      this.form.origin.selectedIdSector = null;
      this.form.origin.selectedIdEmployee = null;
      this.form.origin.employee = null;
    },

    "form.destiny.date"(value) {
      this.form.destiny.selectedIdSector = null;
    },

    "form.origin.employee"(value) {
      if (value) {
        this.changeListOriginPatients();
      }
    },

    "form.origin": {
      deep: true,
      handler(value) {
        if (this.isChargeOriginList(value)) {
        } else if (this.isCleanOriginList(value)) {
          this.form.listOriginPatients = [];
        }

        if (!value.selectedIdSector) {
          this.form.origin.employee = null;
          this.form.origin.selectedIdEmployee = null;
        }
      },
    },

    "form.destiny": {
      deep: true,
      handler(value) {
        if (this.isCleanDestinyList(value)) {
          this.form.listDestinyPatients = [];
        }

        if (!value.selectedIdSector) {
          this.form.destiny.employee = null;
          this.form.destiny.selectedIdEmployee = null;
        }
      },
    },

    "form.origin.selectedIdEmployee"(value) {
      if (!value) {
        this.form.origin.employee = null;
      } else if (Array.isArray(value)) {
        this.form.origin.employee = value[0].item;
      }
    },

    "form.origin.selectedIdSector"(value) {
      if (Array.isArray(value)) {
        this.form.origin.sector = value[0].item;
      }

      if (value === false) {
        this.form.origin.sector = null;
      }
    },

    "form.destiny.selectedIdEmployee"(value) {
      if (Array.isArray(value)) {
        this.form.destiny.employee = value[0].item;
        this.form.availableVacancies = value[0].item.eag_quantidade_restantes;
      }

      if (value === false) {
        this.form.destiny.employee = null;
      }
    },

    "form.destiny.selectedIdSector"(value) {
      if (Array.isArray(value)) {
        this.form.destiny.sector = value[0].item;
      }

      if (value === false) {
        this.form.destiny.sector = null;
      }

      this.form.destiny.selectedIdEmployee = null;
      this.form.destiny.selectedIdPlace = null;
      this.form.destiny.employee = null;
      this.form.availableVacancies = 0;
    },
  },

  mounted() {
    this.actionCleanForm();
  },

  methods: {
    loadingOriginEmployee(isLoading) {
      if (!isLoading) {
        this.$refs.smartTableOrigin.cleanMultSelectedRow();
        this.form.listSelectedOriginPatients = [];
        this.form.listOriginPatients = [];
      }
    },

    isChargeOriginList(value) {
      return value.date && value.employee && value.selectedIdSector;
    },

    isCleanOriginList(value) {
      return value.date || value.employee || value.selectedIdSector;
    },

    isChargeDestinyList(value) {
      return (
        value.date &&
        value.employee &&
        value.selectedIdSector &&
        value.selectedIdPlace
      );
    },

    isCleanDestinyList(value) {
      return value.date || value.employee || value.selectedIdSector;
    },

    async changeListOriginPatients() {
      try {
        this.$refs.smartTableOrigin.cleanMultSelectedRow();
        this.form.listSelectedOriginPatients = [];
        this.form.listOriginPatients = [];

        this.$loader.start();
        this.form.listOriginPatients = await this.$store.dispatch(
          "Appointment/Schedule/GET_TRANSFER_ORIGIN_PATIENTS",
          {
            intIdEscalaAtendimento: this.form.origin.employee.eag_id,
            strDataConsulta: this.form.origin.date,
            intIdLocalAtendimento: null,
          },
        );
      } finally {
        this.$loader.finish();
      }
    },

    onlistSelectedOriginPatients(list) {
      this.form.listSelectedOriginPatients = list;
    },

    actionCleanForm() {
      this.form = this.$utils.obj.DeepCopy(FORM_BASE);

      this.form.loginUnitHealthId = this.$store.getters[
        "Login/GET_UNIT_HEALTH_ID"
      ];

      this.form.loginUnitHealthName = this.$store.getters[
        "Login/GET_UNIT_HEALTH_NAME"
      ];

      this.$refs.smartTableOrigin.cleanMultSelectedRow();

      this.$refs.dateOrigin.cleanValidate();
      this.$refs.comboboxSectorOrigin.cleanValidate();
      this.$refs.comboboxOriginEmployee.cleanValidate();
      this.$refs.dateDestiny.cleanValidate();
      this.$refs.comboboxSectorDestiny.cleanValidate();
      this.$refs.comboboxPlaceDestiny.cleanValidate();
      this.$refs.comboboxDestinyEmployee.cleanValidate();
    },

    async isFormValidOrigin() {
      return this.$refs.validatorOrigin
        ? this.$refs.validatorOrigin.validate()
        : false;
    },

    async isFormValidDestiny() {
      return this.$refs.validatorDestiny
        ? this.$refs.validatorDestiny.validate()
        : false;
    },

    async actionSaveForm() {
      try {
        const validadeFormO = await this.isFormValidOrigin();
        const validadeFormD = await this.isFormValidDestiny();

        if (!validadeFormO || !validadeFormD) {
          this.$toaster.warning("Verifique os campos");
          this.$refs.saveButton.actionDone();
          return;
        }

        if (this.form.listSelectedOriginPatients.length <= 0) {
          this.$toaster.warning(
            "É necessário selecionar consultas para realizar a transferência",
          );
          this.$refs.saveButton.actionDone();
          return;
        }

        if (this.disableBtnTransfer) {
          this.$toaster.error("Por favor, preencha todos os campos.");
          this.$refs.saveButton.actionDone();
          return;
        }

        if (this.vacanciesNotAvaliable) {
          this.$toaster.error("Não há vagas disponíveis.");
          this.$refs.saveButton.actionDone();
          return;
        }

        const consultas = [];

        this.form.listSelectedOriginPatients.map((el) => {
          consultas.push(el.con_id);
        });

        let destinyEmp = this.form.destiny.employee;

        if (destinyEmp.item) {
          destinyEmp = destinyEmp.item;
        }

        const histAgendamento = {
          hag_id_dias_semana: destinyEmp.eag_id_dias_semana,
          hag_id_escalas_agendamentos: destinyEmp.eag_id,
          hag_data_consulta: this.form.destiny.employee.data_consulta,
          hag_intervalo_consultas: destinyEmp.eag_intervalo_consultas,
          hag_quantidade_consultas: destinyEmp.eag_quantidade_consultas,
          hag_hora_inicio_consulta_funcionario:
            destinyEmp.eag_inicio_atendimentos,
        };

        if (this.isEqualOriginDestiny()) {
          this.$toaster.error(
            "A transferência não pode ser feita para a mesma escala no mesmo dia.",
          );

          this.$refs.saveButton.actionDone();

          return;
        }

        const obj = {
          arrFormData: {
            consultas,
            histAgendamento,
          },

          intIdHistoricosAgendamentoDestino: destinyEmp.hag_id
            ? destinyEmp.hag_id
            : "0",

          intIdLocalAtendimento: this.form.destiny.selectedIdPlace,

          intIdEscalaAgendamentoOrigem: this.form.origin.employee.eag_id,

          blnPermissaoTransferenciaConsultaNormal: true,
          blnPermissaoTransferenciaConsultaReservaTecnica: true,
        };

        this.sessionIdPrint = await this.$store.dispatch(
          "Appointment/Schedule/TRANSFER",
          obj,
        );

        this.$refs.saveButton.actionDone();

        if (this.sessionIdPrint.status === false) {
          this.$toaster.error(this.sessionIdPrint.msg);
        } else {
          await this.changeListOriginPatients();
          this.showModalPrintTransfer = true;
          this.form.listSelectedOriginPatients = [];
          this.$refs.smartTableOrigin.cleanMultSelectedRow();
        }
      } catch (e) {
        this.$toaster.error(e.message);
        this.$refs.saveButton.fail();
      }
    },

    async actionPrint() {
      try {
        const arr = {
          pes_nome_destino: this.form.destiny.employee.pes_nome,
          pes_nome_origem: this.form.origin.employee.pes_nome,
          set_nome_destino: this.form.destiny.sector.set_nome,
          set_nome_origem: this.form.origin.sector.set_nome,
          uns_nome: this.form.loginUnitHealthName,
        };

        const obj = {
          strSessao: this.sessionIdPrint,
          arrFiltros: arr,
        };

        const snapPrint = await this.$store.dispatch(
          "Appointment/Schedule/PRINT_TRANSFER",
          obj,
        );

        const html = PrintTransferHTML(snapPrint);
        this.$utils.print.printHtml(html);
      } catch (e) {
      } finally {
        this.closeModalPrint();
      }
    },

    closeModalPrint() {
      this.actionCleanForm();
      this.showModalPrintTransfer = false;
    },

    isEqualOriginDestiny() {
      const isSameDate = this.form.destiny.date === this.form.origin.date;

      const isSameSector =
        this.form.destiny.selectedIdSector ===
        this.form.origin.selectedIdSector;

      const isSameEmployee =
        this.form.destiny.selectedIdEmployee ===
        this.form.origin.selectedIdEmployee;

      return isSameDate && isSameSector && isSameEmployee;
    },
  },
};
</script>
