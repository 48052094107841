<style src="./RgSurgeryKitTypeForm.scss" lang="scss" scoped></style>
<template lang="html">
  <div class="surgery-kit-type-form">
    <RgForm @submit="submit">
      <div class="row">
        <RgInputDebounce
          v-model="form.tkc_nome"
          :rules="{ required: true }"
          class="col-12"
          label="Kit Cirúrgico:"
          placeholder="Digite o kit cirúrgico"
          @debounced="doDebounce"
        />
      </div>
    </RgForm>
  </div>
</template>

<script>
import { RgInputDebounce, toast } from "~tokio/primitive";
import { RgForm } from "~tokio/foundation/form";
import SurgeryKitModel from "$surgery-center/surgery/model/SurgeryKitModel";
export default {
  name: "RgSurgeryKitTypeForm",
  components: { RgForm, RgInputDebounce },
  data: () => {
    return {
      form: {
        tkc_nome: "",
        tkc_ativo: "",
        tkc_id: "",
      },
    };
  },
  methods: {
    doDebounce() {
      this.$emit("search-for", this.form.tkc_nome);
    },
    submit(pErr, pDone) {
      if (pErr) {
        return;
      }
      SurgeryKitModel.create({ tkc_nome: this.form.tkc_nome })
        .then((pRes) => {
          pDone();
          this.$emit("saved", pRes);
        })
        .catch((pErr) => {
          pDone();
          toast.error(pErr.response.errors[0].msg, "Erro ao Realizar Operação");
        });
    },
    cleanForm() {
      this.form = {
        tkc_nome: "",
        tkc_ativo: "",
        tkc_id: "",
      };
    },
  },
};
</script>
