import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

const arrayExceptions = ["AdoUnableDeleteEntryException"];

export default async (context, intIdAgendamento, idModulo) => {
  return AuthLegacyRequest.post(
    "tabelas/controller-agendamento-externo/excluir",
    intIdAgendamento,
    idModulo,
  )
    .then(({ data }) => {
      const response = {};
      const { dados, detalhes_exececao, trace } = data;

      let exceptionName = "";
      let hasError = false;

      const dataIsNotNumber = isNaN(dados);

      if (dataIsNotNumber) {
        hasError = true;
        exceptionName = arrayExceptions.includes(dados)
          ? dados
          : "Generic Exception";
      }

      response.hasError = hasError;
      response.dados = dados;
      response.detalhes_exececao = detalhes_exececao;
      response.mensagem = trace;
      response.exception = exceptionName;

      if (hasError) {
        throw new Error(trace);
      }

      return response;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
