/**
 * @param {*} pExamInfo
 * @param {Int} Status
 */
const PrepareBioslabExamToSave = (pExamInfo, pStatus, reschedule) => {
  const obj = {
    leb_id: pExamInfo.leb_id,
    leb_id_profissional_solicitante: pExamInfo.leb_id_profissional_solicitante,
    leb_profissional_solicitante: replaceSpecial(
      pExamInfo.leb_profissional_solicitante,
    ),
    leb_cpf_profissional: pExamInfo.leb_cpf_profissional,
    leb_uf_profissional: pExamInfo.leb_uf_profissional,
    leb_sigla_conselho: pExamInfo.leb_sigla_conselho,
    leb_numero_conselho: pExamInfo.leb_numero_conselho,
    leb_nome_paciente: replaceSpecial(pExamInfo.leb_nome_paciente),
    leb_sexo_paciente: pExamInfo.leb_sexo_paciente,
    leb_cid: pExamInfo.exe_codigo_cid,
    leb_peso: pExamInfo.leb_peso || null,
    leb_altura: pExamInfo.leb_altura || null,
    leb_menstruada: pExamInfo.leb_menstruada,
    leb_informacoes_menstruacao: replaceSpecial(
      pExamInfo.leb_informacoes_menstruacao,
    ),
    leb_medicamentos: replaceSpecial(pExamInfo.leb_medicamentos),
    // leb_id_usuario_autorizador: ,
    // leb_data_autorizacao: ,
    leb_id_laboratoriais_status_pedido: pStatus,
    leb_id_boletins: pExamInfo.exe_id_boletins,
    leb_id_internacoes: pExamInfo.leb_id_internacoes,
    leb_data_agendamento: pExamInfo.leb_data_agendamento,
    leb_hora_agendamento: pExamInfo.leb_hora_agendamento,
    leb_data_solicitacao: pExamInfo.leb_data_solicitacao,
    leb_prontuario: pExamInfo.leb_prontuario,
    leb_codigo_solicitacao: pExamInfo.leb_codigo_solicitacao,
  };

  if (!reschedule) {
    obj.leb_cns_paciente = pExamInfo.extraInfoPerson.leb_cns_paciente;
    obj.leb_endereco_paciente = replaceSpecial(
      pExamInfo.extraInfoPerson.leb_endereco_paciente,
    );
    obj.leb_logradouro_paciente = replaceSpecial(
      pExamInfo.extraInfoPerson.leb_logradouro_paciente,
    );
    obj.leb_numero_paciente = pExamInfo.extraInfoPerson.leb_end_numero_paciente;
    obj.leb_complemento_paciente = replaceSpecial(
      pExamInfo.extraInfoPerson.leb_end_complemento_paciente,
    );
    obj.leb_id_pacientes = pExamInfo.leb_id_pacientes;
    obj.leb_nascimento_paciente =
      pExamInfo.extraInfoPerson.leb_nascimento_paciente;
    obj.leb_bairro_paciente = replaceSpecial(
      pExamInfo.extraInfoPerson.leb_bairro_paciente,
    );
    obj.leb_municipio_paciente = replaceSpecial(
      pExamInfo.extraInfoPerson.leb_municipio_paciente,
    );
    obj.leb_uf_paciente = pExamInfo.extraInfoPerson.leb_uf_paciente;
    obj.leb_cep_paciente = pExamInfo.extraInfoPerson.leb_cep_paciente;
    obj.leb_telefone_paciente = pExamInfo.extraInfoPerson.leb_telefone_paciente;
    obj.leb_cor_paciente = pExamInfo.extraInfoPerson.leb_cor_paciente;
    obj.leb_nacionalidade_paciente = replaceSpecial(
      pExamInfo.extraInfoPerson.leb_nacionalidade_paciente,
    );
    obj.leb_naturalidade_paciente = replaceSpecial(
      pExamInfo.extraInfoPerson.leb_naturalidade_paciente,
    );
  }

  return obj;
};

const replaceSpecial = (str) => {
  if (str === null || str === "" || str === undefined) {
    return str;
  } else {
    const oldCaracter =
      "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ`^~";
    const newCaracter =
      "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr   ";
    let newstr = "";
    let i, change, a;
    for (i = 0; i < str.length; i++) {
      change = false;
      for (a = 0; a < oldCaracter.length; a++) {
        if (str.substr(i, 1) === oldCaracter.substr(a, 1)) {
          newstr += newCaracter.substr(a, 1);
          change = true;
          break;
        }
      }
      if (change === false) {
        newstr += str.substr(i, 1);
      }
    }
    return newstr;
  }
};

export default PrepareBioslabExamToSave;
