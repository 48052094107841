// import { GraphQLClient, gql } from '@/common/request/GraphQLClient'

// const query = gql`mutation CreateEmployee ($employee: EmployeeInputType) {
// 	CreateEmployee (employee: $employee) {
//     fun_id
//   }
// }`

export default async ({ rootState }, { pes_id = null }) => {
  // const variables = {
  //   employee: {
  //     fun_id_pessoas: pes_id,
  //     fun_id_municipios: rootState.Login.user.usu_id_municipios,
  //     fun_id_clientes: rootState.Login.user.usu_id_clientes,
  //     fun_ativo: true,
  //     fun_habilitado: true
  //   }
  // }
  // try {
  //   const { data } = await GraphQLClient.query({ query, variables, fetchPolicy: 'no-cache' })
  //   return data.employees.rows
  // } catch (err) {
  //   return Promise.reject(err.grapQLErrors)
  // }
};
