<template lang="html">
  <section class="breadcrumb">
    <ul class="list">
      <li v-for="(step, idx) in breadcrumb" :key="idx" class="item">
        <div v-if="idx < breadcrumb.length - 1" class="link">
          <a href="#" class="label" @click="goToRoute(step.link)">{{
            step.label
          }}</a>
        </div>

        <div v-else class="link">
          <span class="label">
            {{ step.label }}
          </span>
        </div>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  name: "Breadcrumb",
  data() {
    return {
      breadcrumb: [],
    };
  },
  watch: {
    $route() {
      this.fetchData();
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      if (this.$route) this.breadcrumb = this.$route.meta.breadcrumb;
    },
    goToRoute(pLink) {
      if (pLink) {
        if (typeof pLink === "function") {
          return pLink(this.$route);
        }
        this.$router.push(pLink);
      }
    },
  },
};
</script>
