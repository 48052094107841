import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query RelationshipSectors($relationshipId: Int) {
    employeeRelationshipSectors(vus_id_vinculos: $relationshipId) {
      rows {
        vus_id
        vus_id_setores
        vus_id_vinculos
        vus_carga_horaria_setores
        vus_responsavel
        vus_ativo
        sector {
          set_nome
          unit_health {
            uns_id
            uns_nome
            address {
              neighborhood {
                city {
                  mun_nome
                  state {
                    est_sigla
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default async ({ commit }, { relationshipId = null }) => {
  const variables = {
    relationshipId,
  };

  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.employeeRelationshipSectors.rows;
  } catch (err) {
    return err.graphQLErrors;
  }
};
