<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SITUATION from "./enum/SituationEnum";
export default {
  name: "RgComboboxSituation",
  mixins: [RgComboboxMixin],
  data() {
    return {
      mData: [
        { value: SITUATION.REGULADOS, text: "Regulados" },
        { value: SITUATION.PENDENTES, text: "Pendentes" },
        { value: SITUATION.ESPERA, text: "Em espera" },
      ],
    };
  },
};
</script>
