import GetModulePermissionsByUnitHealthId from "../../service/GetModulePermissionsByUnitHealthId";
import GetOperationsPermissions from "~tokio/foundation/main-header/main-menu/menu-map/GetOperationsPermissions";
import { setPermissionsMap } from "@/app/access-control/model/PermissionManager";

export default async ({ commit, state }) => {
  const namespaces = await GetOperationsPermissions();

  try {
    const { data } = await GetModulePermissionsByUnitHealthId(namespaces);

    if (!data) {
      throw new Error("Falha ao Carregar Permissões do Usuário");
    }

    commit("UPDATE_PERMISSIONS", data);
    setPermissionsMap(state.permissions);
  } catch (err) {
    return err;
  }
};
