<style src="./EffectivenessAdditionalInfo.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="related-info">
    <RgValidatorForm ref="form" class="row">
      <section class="related-field col-6">
        <span class="label">Diagnóstico Pré-operatório</span>
        <textarea
          v-model="form.ccf_diagnostico_pre_operatorio"
          class="input-textarea"
        />
      </section>
      <section class="related-field col-6">
        <span class="label">Relatório Histopatológico</span>
        <textarea
          v-model="form.ccf_relatorio_histopatologico"
          class="input-textarea"
        />
      </section>
      <section class="related-field col-6">
        <span class="label">Achados Per-Operatório</span>
        <textarea
          v-model="form.ccf_achados_per_operatorio"
          class="input-textarea"
        />
      </section>
      <section class="related-field col-6">
        <span class="label">Intercorrências</span>
        <textarea v-model="form.ccf_intercorrencias" class="input-textarea" />
      </section>
      <section class="related-field col-6">
        <span class="label">Descrição da cirurgia</span>
        <textarea
          v-model="form.ccf_descricao_cirurgia"
          class="input-textarea"
        />
      </section>
    </RgValidatorForm>
  </section>
</template>
<script>
import { RgValidatorMixin, RgValidatorForm } from "~tokio/primitive";

export default {
  name: "EffectivenessAdditionalInfo",
  components: {
    RgValidatorForm,
  },
  mixins: [RgValidatorMixin],
  props: {
    value: {
      default: null,
    },
  },
  data() {
    return {
      form: {
        ccf_diagnostico_pre_operatorio: "",
        ccf_relatorio_histopatologico: "",
        ccf_achados_per_operatorio: "",
        ccf_intercorrencias: "",
        ccf_descricao_cirurgia: "",
      },
    };
  },
  computed: {
    validValue() {
      return this.form;
    },
  },
  watch: {
    form: {
      handler: function (pValue) {
        this.$emit("input", this.form);
      },
      deep: true,
    },
    value(pValue, pOldValue) {
      this.form = pValue;
    },
  },
  methods: {
    validate() {
      this.isValid = this.$refs.form.validate();
      this.error = this.$refs.form.getError();
      return this.isValid;
    },
  },
};
</script>
