import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchPreviewValuesReport(
    $unsId: Int
    $presentation: String
    $status: String
    $limit: Int
    $offset: Int
    $toPrint: Boolean
    $toExcel: Boolean
    $toCsv: Boolean
  ) {
    SearchPreviewValuesReport(
      unsId: $unsId
      presentation: $presentation
      iaf_faturado: $status
      limit: $limit
      offset: $offset
      toPrint: $toPrint
      toExcel: $toExcel
      toCsv: $toCsv
    ) {
      html
      count
      csv
      excel
      fileName
      rows {
        iaf_id
        iaf_apresentacao
        quantidade_aih
        total_servico_hospitalar
        total_servico_profissional
        total
      }
    }
  }
`;
export default async ({ commit }, pParameters) => {
  try {
    const variables = {
      ...pParameters,
    };
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    const html =
      !data.SearchPreviewValuesReport || !data.SearchPreviewValuesReport.html
        ? ""
        : data.SearchPreviewValuesReport.html.replace('\\"', '"');
    const count = !data.SearchPreviewValuesReport
      ? 0
      : data.SearchPreviewValuesReport.count;
    const csv = !data.SearchPreviewValuesReport
      ? 0
      : data.SearchPreviewValuesReport.csv;
    const excel = !data.SearchPreviewValuesReport
      ? 0
      : data.SearchPreviewValuesReport.excel;
    const fileName = !data.SearchPreviewValuesReport
      ? 0
      : data.SearchPreviewValuesReport.fileName;
    return {
      html,
      count,
      csv,
      excel,
      fileName,
      rows: data.SearchPreviewValuesReport.rows,
    };
  } catch (error) {
    throw new Error("Houve um problema ao obter dados do servidor");
  }
};
