<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchProceduresQueue from "./action/SearchProceduresQueue";

export default {
  name: "RgComboboxProceduresQueue",
  mixins: [RgComboboxMixin],
  props: {},
  data: () => {
    return {};
  },
  watch: {
    unitId() {
      this.fillData();
    },
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];
      // Não consegui identificar porque as vezes esse parâmetro vem como objeto e não int.
      if (typeof this.unitId === "object") {
        return;
      }
      const procedures = await SearchProceduresQueue(this.unitId);
      procedures.forEach((item) => {
        this.mData.push({
          value: item.data,
          text: item.label,
          procedure: item.procedimento,
        });
      });
    },
  },
};
</script>
