import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query searchCustomHospitalizationOccupationLog(
    $int_id: Int!
    $searchAll: String
    $limit: Int
    $offset: Int
  ) {
    customHospitalizationOccupationLog(
      int_id: $int_id
      limit: $limit
      offset: $offset
      searchAll: $searchAll
    ) {
      count
      rows {
        ioc_id
        usu_nome
        ocp_nome
        ioc_data
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    return data.customHospitalizationOccupationLog;
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors);
  }
};
