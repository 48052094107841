<style src="./InputCpf.scss" lang="scss" scoped></style>

<template>
  <section class="input-cpf">
    <RgInputCpf v-model="form.info.cpf_numero" />
  </section>
</template>

<script>
import { RgInputCpf } from "~tokio/primitive";
import GetCpfByPersonId from "./actions/GetCpfByPersonId";

export default {
  name: "InputCpf",
  components: {
    RgInputCpf,
  },
  props: {
    pesId: null,
    documentData: {
      default: null,
    },
  },
  data() {
    return {
      form: {
        info: {
          cpf_numero: "",
        },
        mutationName: "AddCpfToPerson",
        mutationParams: "$pes_id: Int, $cpf_numero: String",
        mutationArgs: "pes_id: $pes_id, cpf_numero: $cpf_numero",
        mutationReturnField: "cpf_numero",
      },
    };
  },
  watch: {
    "form.info": {
      handler: function () {
        const form = JSON.parse(JSON.stringify(this.form));
        form.info.cpf_numero = form.info.cpf_numero
          ? form.info.cpf_numero.replace(/(\.|-)/gi, "")
          : "";
        this.$emit("formatted-data", form);
      },
      deep: true,
    },
    documentData(pValue) {
      this.form.info = pValue.info;
    },
  },
  mounted() {
    this.readInfo();
  },
  methods: {
    async readInfo() {
      const data = await GetCpfByPersonId({ pesId: this.pesId });
      this.form.info.cpf_numero = data && data.cpf ? data.cpf.cpf_numero : "";
    },
  },
};
</script>
