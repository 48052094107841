import SAVE_HOSPITALIZATION from "./SaveHospitalization";
import SEARCH_HOSPITALIZATION from "./SearchHospitalization";
import SEARCH_CUSTOM_HISTORY_TRANSFER from "./SearchCustomHistoryTransfer";
import SEARCH_CUSTOM_HOSPITALIZATION_OCCUPATION_LOG from "./SearchCustomHospitalizationOccupationLog";
import SEARCH_CUSTOM_HOSPITALIZATION_OUTPUT_DETAIL from "./SearchCustomHospitalizationOutputDetail";
import GET_EMPLOYEE_RELATIONSHIP from "./GetEmployeeRelationship";
import PRINT_HOSPITALIZATION_AUTHORIZATION_REQUEST from "./PrintHospitalizationAuthorizationRequest";
import PRINT_REGISTRATION_HOSPITALIZATION_PATIENT from "./PrintRegistrationHospitalizationPatient";
import GET_PATIENT_HOSPITALIZED from "./SearchPatientHospitalized";

export default {
  SAVE_HOSPITALIZATION,
  SEARCH_HOSPITALIZATION,
  SEARCH_CUSTOM_HOSPITALIZATION_OUTPUT_DETAIL,
  GET_EMPLOYEE_RELATIONSHIP,
  SEARCH_CUSTOM_HISTORY_TRANSFER,
  SEARCH_CUSTOM_HOSPITALIZATION_OCCUPATION_LOG,
  PRINT_HOSPITALIZATION_AUTHORIZATION_REQUEST,
  PRINT_REGISTRATION_HOSPITALIZATION_PATIENT,
  GET_PATIENT_HOSPITALIZED,
};
