<template lang="html">
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 401.991 401.991"
    style="enable-background: new 0 0 438.533 438.533"
    xml:space="preserve"
  >
    <g>
      <path
        d="M228.405,146.179h-54.816c-7.613,0-14.084,2.665-19.414,7.995c-5.33,5.33-7.994,11.798-7.994,19.414v54.82
        c0,7.617,2.665,14.086,7.994,19.41c5.33,5.332,11.801,7.994,19.414,7.994h54.816c7.614,0,14.083-2.662,19.411-7.994
        c5.328-5.324,7.994-11.793,7.994-19.41v-54.82c0-7.616-2.666-14.087-7.994-19.414
        C242.488,148.844,236.019,146.179,228.405,146.179z"
      />
      <path
        d="M82.224,146.179H27.406c-7.611,0-14.084,2.665-19.414,7.995C2.662,159.503,0,165.972,0,173.587v54.82
        c0,7.617,2.662,14.086,7.992,19.41c5.33,5.332,11.803,7.994,19.414,7.994h54.818c7.611,0,14.084-2.662,19.414-7.994
        c5.33-5.324,7.994-11.793,7.994-19.41v-54.82c0-7.616-2.664-14.087-7.994-19.414C96.308,148.847,89.835,146.179,82.224,146.179z"
      />
      <path
        d="M394,154.174c-5.331-5.33-11.806-7.995-19.417-7.995h-54.819c-7.621,0-14.089,2.665-19.418,7.995
        c-5.328,5.33-7.994,11.798-7.994,19.414v54.82c0,7.617,2.666,14.086,7.994,19.41c5.329,5.332,11.797,7.994,19.418,7.994h54.819
        c7.611,0,14.086-2.662,19.417-7.994c5.325-5.324,7.991-11.793,7.991-19.41v-54.82C401.991,165.972,399.332,159.5,394,154.174z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Ellipsis",
};
</script>
