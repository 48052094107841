<style src="./PermissionLoader.scss" lang="scss" scoped></style>
<template>
  <div v-show="toClose" class="background-loader">
    <div class="indicator">
      <!-- Loading -->
      <div :class="{ 'align-loading': status === 0 }" class="content-body">
        <div class="block">
          <div class="gear"></div>
        </div>
        <span class="edit-text">{{ titleLoader }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PermissionLoader",
  props: {
    titleSuccess: {
      type: String,
      default: "",
    },
    titleLoader: {
      type: String,
      default: "",
    },
    titleFailed: {
      type: String,
      default: "",
    },
    status: {
      type: Number,
      default: 0,
    },
    toClose: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  watch: {
    status(pValue) {
      this.status = pValue;
    },
    toClose(pValue) {
      this.toClose = pValue;
    },
  },
  methods: {
    close() {
      this.toClose = false;
      this.$emit("close", false);
    },
  },
};
</script>
