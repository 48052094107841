<style src="./PersonalAdditionalInfo.scss" lang="scss" scoped></style>

<template lang="html">
  <RgForm ref="formBasic" action-label="Atualizar" @submit="update">
    <section class="personal-additional-info">
      <div class="row">
        <div class="col-12">
          <RgInput
            v-model="personForm.pes_pai"
            :rules="{ name: true }"
            label="Nome do Pai"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-6 col-md-12">
          <RgComboboxReligion
            v-model="personForm.pes_id_religiao"
            label="Religião"
          />
        </div>
        <div class="col-6 col-md-12">
          <RgComboboxBloodType
            v-model="personForm.pes_id_tipos_sanguineos"
            label="Tipo Sanguíneo"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-6 col-md-12">
          <RgComboboxMaritalStatus
            v-model="personForm.pes_id_estados_civis"
            label="Estado Civil"
          />
        </div>
        <div class="col-6 col-md-12">
          <RgComboboxEthnicity
            v-model="personForm.pes_id_etnias"
            label="Etnia"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-6 col-md-12">
          <RgSuggestNationality
            v-model="nationality"
            :rules="{ forceSelection: true }"
            label="Nacionalidade"
            placeholder="Informe o nome do país"
            @selected="selectingNationality"
          />
        </div>
        <div class="col-6 col-md-12">
          <RgSuggestCity
            v-model="city"
            :rules="{ forceSelection: true }"
            label="Cidade Natal"
            placeholder="Informe o nome da cidade"
            @selected="selectingCity"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <RgComboboxScholarity
            v-model="personForm.pes_id_escolaridades"
            label="Escolaridade"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-6 col-md-12">
          <RgInput v-model="personForm.pes_profissao" label="Profissão" />
        </div>
        <div class="col-6 col-md-12">
          <RgComboboxJobStatus
            v-model="personForm.pes_id_situacao_trabalho"
            label="Situação Profissional"
          />
        </div>
      </div>
      <!-- :rules="{ forceSelection: true }" -->
      <div class="row">
        <div class="col-6 col-md-6 col-xs-12">
          <RgSuggestUser
            v-model="user"
            label="Usuário"
            placeholder="Informe o nome do Usuário"
            @selected="selectingUser"
          />
        </div>
      </div>
    </section>
  </RgForm>
</template>

<script>
import { RgInput } from "~tokio/primitive";
import { RgForm } from "~tokio/foundation/form";

import RgComboboxScholarity from "$patient/common/components/combobox/rg-combobox-scholarity/RgComboboxScholarity";
import RgComboboxJobStatus from "$patient/common/components/combobox/rg-combobox-job-status/RgComboboxJobStatus";
import RgComboboxBloodType from "$patient/common/components/combobox/rg-combobox-blood-type/RgComboboxBloodType";
import RgComboboxEthnicity from "$patient/common/components/combobox/rg-combobox-ethnicity/RgComboboxEthnicity";
import RgComboboxReligion from "$patient/common/components/combobox/rg-combobox-religion/RgComboboxReligion";
import RgComboboxMaritalStatus from "$patient/common/components/combobox/rg-combobox-marital-status/RgComboboxMaritalStatus";
import RgSuggestNationality from "$patient/common/components/suggest/rg-suggest-nationality/RgSuggestNationality";
import RgSuggestUser from "$patient/common/components/suggest/rg-suggest-user/RgSuggestUser";
import RgSuggestCity from "$patient/common/components/suggest/rg-suggest-city/RgSuggestCity";

export default {
  name: "PersonalAdditionalInfo",
  components: {
    RgInput,
    RgSuggestCity,
    RgSuggestUser,
    RgForm,
    RgComboboxReligion,
    RgComboboxMaritalStatus,
    RgComboboxEthnicity,
    RgComboboxBloodType,
    RgSuggestNationality,
    RgComboboxScholarity,
    RgComboboxJobStatus,
  },
  props: {
    pesId: {
      default: null,
    },
  },
  data() {
    return {
      nationality: "",
      city: "",
      personForm: {
        pes_id_escolaridades: null,
        pes_id_estados_civis: null,
        pes_id_etnias: null,
        pes_id_municipios: null,
        pes_id_nacionalidades: null,
        pes_id_religiao: null,
        pes_id_situacao_trabalho: null,
        pes_id_tipos_sanguineos: null,
        pes_obs: "",
        pes_pai: "",
        pes_profissao: "",
        pes_id_usuarios: null,
      },
      user: "",
    };
  },
  computed: {},
  watch: {
    "$store.state.Patient.additionalInfo": function (pValue) {
      this.nationality = pValue.nationality;
      this.city = pValue.city;
      this.user = pValue.user;
      Object.assign(this.personForm, pValue);
    },
  },
  methods: {
    update(pRequestAction) {
      this.$store
        .dispatch("Patient/UPDATE_ADDITIONAL_INFO", this.personForm)
        .then(() => {
          this.$toaster.success("Cadastro atualizado com sucesso!");
          this.$refs.formBasic.actionCompleted();
        })
        .catch((pErr) => {
          this.$toaster.error(pErr[0].message);
          this.resetUser();
          this.$refs.formBasic.actionCompleted();
        });
    },
    verifyHometown(pSelectedValue) {
      if (pSelectedValue !== 1) {
        this.personForm.pes_id_municipios = null;
      }
    },
    selectingNationality(pNationalityInfo) {
      this.personForm.pes_id_nacionalidades = pNationalityInfo.source.nac_id;
      this.nationality = pNationalityInfo.source.nac_nome;
    },
    selectingCity(pCityInfo) {
      this.personForm.pes_id_municipios = pCityInfo.source.mun_id;
    },
    selectingUser(pSelection) {
      this.personForm.pes_id_usuarios = pSelection.source.usu_id;
    },
    resetUser() {
      this.personForm.pes_id_usuarios = null;
      this.user = null;
    },
    // loadPersonInformation (pPersonId) {
    //   return this.$store.dispatch('Patient/GET_PERSON_BY_ID', { pesId: pPersonId })
    //     .then(() => {
    //       const basicInfo = this.$store.getters['Patient/GET_BASIC_DATA']
    //       this.address.publicPlaceType = basicInfo.address.publicPlaceType.tlg_nome
    //       this.address.cityName = basicInfo.address.neighborhood.city.mun_nome
    //       this.personForm = Object.assign({}, basicInfo)
    //       this.personForm.pes_nascimento = this.personForm.pes_nascimento.split('-').reverse().join('/')
    //       return this.personForm
    //     }).catch((pErr) => {
    //       toast.error('Falha ao Carregar as informações Adicionais da pessoa')
    //     })
    // }
  },
};
</script>
