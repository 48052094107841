const ProcedureAdditionalDataVerification = {
  procedureTypes: {
    AIH_PRINCIPAL: "03",
    AIH_ESPECIAL: "04",
    AIH_SECUNDARIO: "05",
  },

  getAdditionalData(procedureCode) {
    if (!procedureCode) return;
    const additionalData = {};
    const group = this.getGroup(procedureCode);
    const subGroup = this.getSubgroup(procedureCode);
    // let organizationForm = this.getOrganizationForm
    additionalData.hasMaterial = this.hasMaterial(group);
    additionalData.hasTincture = this.hasTincture(procedureCode);
    additionalData.hasChildbirthTincture = this.hasChildbirthTincture(
      procedureCode,
    );
    additionalData.hasVasectomy = this.hasVasectomy(procedureCode);
    additionalData.hasCivilRegistry = this.hasCivilRegistry(procedureCode);
    additionalData.hasNeonatal = this.hasNeonatal(group, procedureCode);
    additionalData.hasWorkAccident = this.hasWorkAccident(procedureCode);
    additionalData.hasChildbirth = this.hasChildbirth(procedureCode);
    additionalData.hasTeam = this.hasTeam(group, subGroup, procedureCode);
    return additionalData;
  },

  getGroup(procedureCode) {
    return procedureCode.substring(0, 2);
  },

  getSubgroup(procedureCode) {
    return procedureCode.substring(2, 4);
  },

  getOrganizationForm(procedureCode) {
    return procedureCode.substring(4, 2);
  },

  getAihType(pRegister) {
    return this.procedureTypes.filter((item) => item.value === pRegister);
  },

  getRequiredDocuments(pProcedure) {
    if (!pProcedure.value) return;
    if (!pProcedure.co_registro) return;

    const group = this.getGroup(pProcedure.value);
    const register = pProcedure.co_registro;
    const VL_SP = parseInt(pProcedure.vl_sp) > 0;
    // const VL_SA = parseInt(pProcedure.vl_sa) > 0
    // const VL_SH = parseInt(pProcedure.vl_sh) > 0
    const QT_PONTOS = parseInt(pProcedure.qt_pontos) > 0;

    const documents = {
      CBO: { required: false },
      CNES: { required: false },
      CPF: { required: false }, // NO CASO TRABALHAMOS COM O CNS DO PROFISSIONAL
      CNPJ_FORNECEDOR: { required: false },
    };

    if (group === "02") {
      if (register === this.procedureTypes.AIH_PRINCIPAL) {
        // EXIGE CPF + CBO
        documents.CBO.required = true;
        documents.CPF.required = true;
        return documents;
      }
      if (register === this.procedureTypes.AIH_ESPECIAL && VL_SP) {
        // COM VALOR SP: EXIGE CPF + CBO
        documents.CBO.required = true;
        documents.CPF.required = true;
        return documents;
      }
      if (register === this.procedureTypes.AIH_SECUNDARIO && QT_PONTOS) {
        // COM PONTOS EXIGE CPF + CBO
        documents.CBO.required = true;
        documents.CPF.required = true;
        return documents;
      }
      // DEMAIS PROCEDIMENTOS: CNES
      documents.CNES.required = true;
      return documents;
    }
    if (group === "03") {
      if (register === this.procedureTypes.AIH_PRINCIPAL) {
        // EXIGE CPF + CBO
        documents.CBO.required = true;
        documents.CPF.required = true;
        return documents;
      }
      if (register === this.procedureTypes.AIH_ESPECIAL) {
        // EXIGE CPF + CBO
        documents.CBO.required = true;
        documents.CPF.required = true;
        return documents;
      }
      if (register === this.procedureTypes.AIH_SECUNDARIO) {
        if (QT_PONTOS) {
          // COM PONTOS - EXIGE CPF + CBO
          documents.CBO.required = true;
          documents.CPF.required = true;
          return documents;
        }
        if (
          pProcedure.value === "0301010048" ||
          pProcedure.value === "0301010170"
        ) {
          // 0301010048  || 0301010170 - EXIGE CPF + CBO
          documents.CBO.required = true;
          documents.CPF.required = true;
          return documents;
        }
        // DEMAIS PROCEDIMENTOS: FACULTATIVO CPF + CBO
        return documents;
      }
    }
    if (group === "04") {
      if (register === this.procedureTypes.AIH_PRINCIPAL) {
        // EXIGE CPF + CBO
        documents.CBO.required = true;
        documents.CPF.required = true;
        return documents;
      }
      if (register === this.procedureTypes.AIH_ESPECIAL) {
        // EXIGE CPF + CBO
        documents.CBO.required = true;
        documents.CPF.required = true;
        return documents;
      }
      if (register === this.procedureTypes.AIH_SECUNDARIO && QT_PONTOS) {
        // COM PONTOS: EXIGE CPF + CBO
        documents.CBO.required = true;
        documents.CPF.required = true;
        return documents;
      }
      // DEMAIS PROCEDIMENTOS FACULTATIVO: CPF + CBO
      return documents;
    }
    if (group === "05") {
      if (register === this.procedureTypes.AIH_PRINCIPAL) {
        // EXIGE CPF + CBO
        documents.CBO.required = true;
        documents.CPF.required = true;
        return documents;
      }
      if (register === this.procedureTypes.AIH_ESPECIAL) {
        // EXIGE CPF + CBO
        documents.CBO.required = true;
        documents.CPF.required = true;
        return documents;
      }
      if (register === this.procedureTypes.AIH_SECUNDARIO && QT_PONTOS) {
        // COM PONTOS: EXIGE CPF + CBO
        documents.CBO.required = true;
        documents.CPF.required = true;
        return documents;
      }
      // DEMAIS PROCEDIMENTOS: CNES
      documents.CNES.required = true;
      return documents;
    }
    if (group === "06") {
      documents.CNES.required = true;
      return documents;
    }
    if (group === "07") {
      documents.CNPJ_FORNECEDOR.required = true;
      return documents;
    }
    if (group === "08") {
      // EXIGE CPF + CBO e CNES apenas para o procedimento - 0802020011
      if (pProcedure.value === "0802020011") {
        documents.CBO.required = true;
        documents.CPF.required = true;
        documents.CNES.required = true;
        return documents;
      }
      // DEMAIS PROCEDIMENTOS: CNES
      documents.CNES.required = true;
      return documents;
    }

    return documents;
  },

  // Verifica se Existe Material
  hasMaterial(group) {
    return group === "07";
  },

  // Verifica se Existe Laqueadura
  hasTincture(procedureCode) {
    if (procedureCode === "0409060186") {
      return true;
    }
    return false;
  },

  // Verifica se Existe Vasectomia
  hasVasectomy(procedureCode) {
    return procedureCode === "0409040240";
  },

  // Verifica se Existe Registro Civil
  hasCivilRegistry(procedureCode) {
    return procedureCode === "0801010047";
  },

  // Verifica se Existe Neonatal
  hasNeonatal(group, procedureCode) {
    if (group !== "08") return false;
    if (
      procedureCode === "0802010067" ||
      procedureCode === "0802010121" ||
      procedureCode === "0802010130" ||
      procedureCode === "0802010164" ||
      procedureCode === "0802010237" ||
      procedureCode === "0802010245"
    ) {
      return true;
    }
    return false;
  },

  // Verifica se Existe Acidente de Trabalho
  hasWorkAccident(procedureCode) {
    return procedureCode === "0802020020";
  },

  // Verifica se Existe Parto
  hasChildbirth(procedureCode) {
    if (
      procedureCode === "0310010039" ||
      procedureCode === "0310010047" ||
      procedureCode === "0310010055" ||
      procedureCode === "0411010034" ||
      procedureCode === "0411010026"
    ) {
      return true;
    }
    return false;
  },

  // Verifica se Existe Parto com laqueadura
  hasChildbirthTincture(procedureCode) {
    if (procedureCode === "0411010042") {
      return true;
    }
    return false;
  },

  // Verifica se Existe Equipe
  hasTeam(group, subGroup, procedureCode) {
    let has = false;
    if (group === "02") {
      // SubGrupo 01
      if (
        procedureCode === "0201010038" ||
        procedureCode === "0201010127" ||
        procedureCode === "0201010135" ||
        procedureCode === "0201010143" ||
        procedureCode === "0201010160" ||
        procedureCode === "0201010208" ||
        procedureCode === "0201010240" ||
        procedureCode === "0201010259" ||
        procedureCode === "0201010267" ||
        procedureCode === "0201010275" ||
        procedureCode === "0201010305" ||
        procedureCode === "0201010313" ||
        procedureCode === "0201010321" ||
        procedureCode === "0201010330" ||
        procedureCode === "0201010402" ||
        procedureCode === "0201010534"
      ) {
        has = true;
      }

      // SubGrupo 09
      if (procedureCode === "0209040033" || procedureCode === "0209040050") {
        has = true;
      }

      // SubGrupo 11
      if (
        procedureCode === "0211020028" ||
        procedureCode === "0211050091" ||
        procedureCode === "0211050105" ||
        procedureCode === "0211050148"
      ) {
        has = true;
      }
    }

    // Grupo 03
    if (group === "03") {
      if (procedureCode === "0309010039") {
        has = true;
      }
    }

    // Grupo 04
    if (group === "04") {
      if (subGroup === "01") {
        has = true;
        if (
          procedureCode === "0401010015" ||
          procedureCode === "0401010023" ||
          procedureCode === "0401010031" ||
          procedureCode === "0401010040" ||
          procedureCode === "0401010058" ||
          procedureCode === "0401010082" ||
          procedureCode === "0401010104"
        ) {
          has = false;
        }
      }

      if (subGroup === "04") {
        has = true;
        if (
          procedureCode === "0404010253" ||
          procedureCode === "0404010342" ||
          procedureCode === "0404020054" ||
          procedureCode === "0404020100" ||
          procedureCode === "0404020445" ||
          procedureCode === "0404020615" ||
          procedureCode === "0404020623" ||
          procedureCode === "0404020674"
        ) {
          has = false;
        }
      }

      if (subGroup === "06") {
        has = true;
        if (
          procedureCode === "0406010129" ||
          procedureCode === "0406010722" ||
          procedureCode === "0406010749" ||
          procedureCode === "0406020086" ||
          procedureCode === "0406020094" ||
          procedureCode === "0406020183"
        ) {
          has = false;
        }
      }

      if (subGroup === "07") {
        has = true;
        if (
          procedureCode === "0407010025" ||
          procedureCode === "0407010246" ||
          procedureCode === "0407010254" ||
          procedureCode === "0407010262" ||
          procedureCode === "0407020128" ||
          procedureCode === "0407040196" ||
          procedureCode === "0407040218"
        ) {
          has = false;
        }
      }

      if (subGroup === "08") {
        has = true;
        if (
          procedureCode === "0408010126" ||
          procedureCode === "0408020113" ||
          procedureCode === "0408030488" ||
          procedureCode === "0408040211" ||
          procedureCode === "0408050098" ||
          procedureCode === "0408060719"
        ) {
          has = false;
        }
      }

      if (subGroup === "09") {
        has = true;
        if (
          procedureCode === "0409010359" ||
          procedureCode === "0409020036" ||
          procedureCode === "0409040029" ||
          procedureCode === "0409040061" ||
          procedureCode === "0409070122" ||
          procedureCode === "0409070165" ||
          procedureCode === "0409070181"
        ) {
          has = false;
        }
      }

      if (subGroup === "10") {
        has = true;
        if (procedureCode === "0410010049") {
          has = false;
        }
      }

      if (subGroup === "12") {
        has = true;
        if (procedureCode === "0412010062" || procedureCode === "0412030055") {
          has = false;
        }
      }

      if (subGroup === "13") {
        has = true;
        if (procedureCode === "0413010074") {
          has = false;
        }
      }

      if (subGroup === "14") {
        if (
          procedureCode === "0414010027" ||
          procedureCode === "0414010035" ||
          procedureCode === "0414010256" ||
          procedureCode === "0414010272" ||
          procedureCode === "0414010329" ||
          procedureCode === "0414010345" ||
          procedureCode === "0414020413"
        ) {
          has = true;
        }
      }

      if (subGroup === "15") {
        has = true;
        if (
          procedureCode === "0415010012" ||
          procedureCode === "0415030013" ||
          procedureCode === "0415040043"
        ) {
          has = false;
        }
      }

      if (
        subGroup === "02" ||
        subGroup === "03" ||
        subGroup === "05" ||
        subGroup === "11" ||
        subGroup === "16"
      ) {
        has = true;
      }

      if (subGroup === "17" || subGroup === "18") {
        has = false;
      }
    }

    // Grupo 05
    if (group === "05") {
      if (subGroup === "01") {
        if (procedureCode === "0501030069") {
          has = true;
        }
      }

      if (subGroup === "03") {
        if (
          procedureCode === "0503020010" ||
          procedureCode === "0503020028" ||
          procedureCode === "0503030023" ||
          procedureCode === "0503030040" ||
          procedureCode === "0503030066" ||
          procedureCode === "0503030074" ||
          procedureCode === "0503030082" ||
          procedureCode === "0503030090" ||
          procedureCode === "0503030104"
        ) {
          has = true;
        }
      }

      if (subGroup === "05") {
        if (
          procedureCode === "0505010097" ||
          procedureCode === "0505010100" ||
          procedureCode === "0505010119" ||
          procedureCode === "0505010127" ||
          procedureCode === "0505020041" ||
          procedureCode === "0505020050" ||
          procedureCode === "0505020068" ||
          procedureCode === "0505020076" ||
          procedureCode === "0505020084" ||
          procedureCode === "0505020092" ||
          procedureCode === "0505020106" ||
          procedureCode === "0505020114" ||
          procedureCode === "0505020122"
        ) {
          has = true;
        }
      }

      if (subGroup === "02" || subGroup === "04" || subGroup === "06") {
        has = false;
      }
    }

    return has;
  },
};

export default ProcedureAdditionalDataVerification;
