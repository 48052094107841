<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
export default {
  name: "RgComboboxSurgerySuspendedReasons",
  mixins: [RgComboboxMixin],
  data: () => {
    return {};
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];
      const data = await this.$store.dispatch(
        "SurgeryCenter/Surgery/GET_SURGERY_SUSPENDED_REASONS",
      );
      data.rows.forEach((item) => {
        this.mData.push({ value: item.msu_id, text: item.msu_nome });
      });
    },
  },
};
</script>
