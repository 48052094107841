<template lang="html">
  <svg
    width="11.153mm"
    height="12.909mm"
    version="1.1"
    viewBox="0 0 11.153 12.909"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="matrix(.025253 0 0 .025253 -.73676 .14123)" fill="#e6e6e6">
      <g fill="#e6e6e6">
        <path
          d="m250 0c-53.876 0-97.696 43.832-97.696 97.708v50.62c0 53.88 43.82 97.712 97.696 97.712 53.88 0 97.696-43.832 97.696-97.712v-50.62c1e-3 -53.876-43.816-97.708-97.696-97.708z"
          fill="#e6e6e6"
        />
      </g>
      <g fill="#e6e6e6">
        <path
          d="m465.46 373.36c-31.284-53.312-81.288-92.836-140.8-111.29-1.38-0.428-2.872-0.064-3.9 0.952-27.684 27.296-62.084 48.592-70.684 53.736-9.18-5.868-47.848-31.072-70.836-53.736-1.024-1.016-2.528-1.38-3.9-0.952-59.52 18.46-109.52 57.984-140.79 111.3-0.716 1.22-0.716 2.736 0 3.956 44.408 75.672 126.96 122.68 215.45 122.68 88.492 0 171.05-47.008 215.46-122.68 0.72-1.224 0.72-2.74 0-3.96zm-82.584 29.144c0 2.156-2.052 3.804-4.204 3.804h-26.46c-2.156 0-4.508 1.852-4.508 4.012v27.364c0 2.152-1.152 3.796-3.308 3.796h-23.764c-2.156 0-4.188-1.644-4.188-3.796v-27.364c-8e-3 -2.156-1.48-4.012-3.636-4.012h-27.6c-2.156 0-3.94-1.652-3.94-3.804v-23.56c0-2.156 1.784-3.896 3.94-3.896h27.6c2.156 0 3.628-1.76 3.628-3.92v-27.396c0-2.156 2.032-3.856 4.188-3.856h23.508c2.152 0 3.568 1.7 3.568 3.856v27.476c0 2.16 2.088 3.836 4.248 3.836h26.724c2.152 0 4.204 1.824 4.204 3.98z"
          fill="#e6e6e6"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconAttendanceBioslab",
};
</script>
