<style src="./RgRadioYesNo.scss" lang="scss" scoped></style>
<template lang="html">
  <fieldset class="rg-radio-yes-no-container">
    <label class="description--radio">{{ label }}</label>
    <div class="form-radio">
      <label class="radio">
        <input
          v-model="yesNo"
          :name="groupName"
          :value="yesValue"
          type="radio"
          class="rg-radio"
        />
        <span> {{ yesLabel }} </span>
      </label>
      <label class="radio">
        <input
          v-model="yesNo"
          :name="groupName"
          :value="noValue"
          class="rg-radio"
          type="radio"
          @click="noClicked"
        />
        <span> {{ noLabel }} </span>
      </label>
    </div>
  </fieldset>
</template>

<script>
export default {
  name: "RgRadioYesNo",
  props: {
    label: {
      type: String,
      default: "Sim ou Não",
    },
    yesValue: {
      default: true,
    },
    noValue: {
      default: false,
    },
    value: {
      default: null,
    },
    defaultValue: {
      default: null,
    },
    yesLabel: {
      type: String,
      default: "Sim",
    },
    noLabel: {
      type: String,
      default: "Não",
    },
  },
  data() {
    return {
      yesNo: null,
    };
  },
  computed: {
    groupName: function () {
      return `yesno-group-${this.labelToGroup}`;
    },
    labelToGroup: function () {
      return this.label.trim().toLowerCase().replace(/ /gi, "-");
    },
  },
  watch: {
    yesNo: function () {
      this.$emit("input", this.yesNo);
    },
    value: function () {
      this.yesNo = this.value;
    },
  },
  mounted() {
    this.yesNo = this.defaultValue === null ? this.yesValue : this.defaultValue;
  },
  methods: {
    noClicked() {
      this.$emit("noClicked", this.yesNo);
    },
  },
};
</script>
