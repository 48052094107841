<template lang="html">
  <svg
    width="20"
    height="20"
    viewBox="0 0 23 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 10C0 10.5 0.4 11 1 11H6.9V14C6.9 14.4 7.1 14.7 7.5 14.9C7.9 15 8.3 15 8.6 14.7L12.7 10.7C13.1 10.3 13.1 9.7 12.7 9.4L8.6 5.4C8.3 5 7.9 4.9 7.5 5.1C7.2 5.2 6.9 5.6 6.9 6V9H1C0.4 9 0 9.5 0 10Z"
      fill="white"
    />
    <path
      d="M13.4 17.9999H3C2.4 17.9999 2 17.5999 2 16.9999V14.8999C2 14.2999 1.6 13.8999 1 13.8999C0.4 13.8999 0 14.2999 0 14.8999V16.9999C0 18.6999 1.3 19.9999 3 19.9999H14C13.7 19.3999 13.4 18.6999 13.4 17.9999Z"
      fill="white"
    />
    <path
      d="M1 6.1C1.6 6.1 2 5.7 2 5.1V3C2 2.4 2.4 2 3 2H9H17C17.2 2 17.4 2.1 17.6 2.2C17.6 2.2 17.6 2.2 17.7 2.2C17.7 2.2 17.7 2.2 17.7 2.3C17.9 2.6 18 2.8 18 3V12.3C18.1 12.3 18.3 12.3 18.4 12.3C19 12.3 19.5 12.4 20 12.6V3C20 2.2 19.7 1.4 19.1 0.9C18.6 0.3 17.8 0 17 0H9H3C1.3 0 0 1.3 0 3V5.1C0 5.7 0.4 6.1 1 6.1Z"
      fill="white"
    />
    <path
      d="M18.4 13C16 13 14 15 14 17.4C14 19.8 16 21.8 18.4 21.8C20.8 21.8 22.8 19.8 22.8 17.4C22.8 15 20.8 13 18.4 13ZM20.6 18.1H16.4C16 18.1 15.8 17.8 15.8 17.5C15.8 17.2 16.1 16.9 16.4 16.9H20.6C21 16.9 21.2 17.2 21.2 17.5C21.2 17.8 20.9 18.1 20.6 18.1Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "CancelExit",
};
</script>
