import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  const arrFiltro = variables.arrFormData;
  return AuthLegacyRequest.post("/emergencias/controller-boletim/buscar", {
    arrFiltro,
  })
    .then(({ data }) => {
      data = HandleResponse(data);

      data.dados.data = data.dados.recordSet;

      data.dados.data = data.dados.data.map((item) => {
        item.entrada = `${item.bol_data} - ${item.bol_hora}`;
        return item;
      });

      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
