<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";
import SearchProceduresQueueByUnit from "./action/SearchProceduresQueueByUnit";
export default {
  name: "RgSelectProceduresQueueByUnit",
  extends: RgSelect,
  props: {
    unitHealthId: {
      required: true,
      type: Number,
      default: 0,
    },
    alterProcedure: {
      required: false,
    },
    labelForm: {
      type: String,
      default: "Procedimento",
    },
    filtering: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      procedures: [],
    };
  },
  computed: {
    hasUnitHealth() {
      return this.unitHealthId && this.unitHealthId > 0;
    },
  },
  watch: {
    unitHealthId: async function (pValue, pPrev) {
      if (pValue && pValue !== pPrev) {
        this.searchProcedures();
      }
    },
    procedures: function (val) {
      this.updateOptions();
    },
  },
  mounted() {
    this.searchProcedures();
  },
  methods: {
    getData() {
      return this.procedures;
    },
    async searchProcedures() {
      if (this.hasUnitHealth) {
        const data = await SearchProceduresQueueByUnit({
          idUnidadeSolicitante: this.unitHealthId,
        });

        this.procedures = data.map((item) => {
          return {
            id: item.data,
            value: item.nome,
            label: item.nome,
            procedure: item.procedimento,
          };
        });
      }
    },
  },
};
</script>
