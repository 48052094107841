import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

import moment from "moment";

const sortDate = (key) => {
  return (a, b) =>
    moment(b[key], "DD/MM/YYYY HH:mm") - moment(a[key], "DD/MM/YYYY HH:mm");
};

export default async (context, variables) => {
  return AuthLegacyRequest.post(
    "consultas/controller-escala-agendamento/buscar-historico",
    variables,
  )
    .then(({ data }) => {
      if (data.dados === "0") {
        return [];
      }

      data = HandleResponse(data);
      if (data?.dados) {
        const historyScaleList = data.dados.map((item) => {
          item.validade = `${item.eag_data_inicio_escala} - ${item.eag_data_fim_escala}`;
          return item;
        });

        const sortHistoryScaleList = historyScaleList.sort(
          sortDate("elo_data"),
        );

        return sortHistoryScaleList;
      }

      return [];
    })
    .catch((pErr) => {
      throw pErr;
    });
};
