<style src="./RgSuggestDdd.scss" lang="scss" scoped></style>
<template>
  <div class="rg-suggest-ddd">
    <RgSuggest
      ref="innerSuggest"
      v-model="inputValue"
      :label="label"
      :placeholder="placeholder"
      :search-on-focus="searchOnFocus"
      :rules="rules"
    >
      <RgSuggestItem
        v-for="(item, idx) in suggestionList"
        :key="idx"
        :idx="idx"
        :item="item"
        class="ddd-item"
      >
        <div class="person-row-top">
          <span class="pes-name"
            ><strong>{{ item.ddd_codigo }}</strong></span
          >
        </div>
      </RgSuggestItem>
    </RgSuggest>
  </div>
</template>
<script>
import { RgSuggest, RgSuggestMixin, RgSuggestItem } from "~tokio/primitive";
import { SearchDdd } from "./action";

export default {
  name: "RgSuggestDdd",
  components: {
    RgSuggest,
    RgSuggestItem,
  },
  mixins: [RgSuggestMixin],
  data() {
    return {
      captionRaw: "ddd_codigo",
    };
  },

  computed: {
    searchOnFocus() {
      return this.inputValue === "" || this.inputValue === null;
    },
  },

  methods: {
    doSearch(pSearchTerm) {
      const variables = { ddd_codigo: pSearchTerm };
      return SearchDdd({ variables });
    },

    validate() {
      this.$refs.innerSuggest.validate();
    },

    cleanValidate() {
      this.$refs.innerSuggest.cleanValidate();
    },
  },
};
</script>
