<style lang="scss" src="./RgSuspendModal.scss" scoped></style>
<template>
  <div v-if="show">
    <RgBaseModal :show="show" @close="close">
      <div slot="icon" class="icon">
        <IconDanger class="icon-danger" />
      </div>

      <div slot="header" class="confirm-header">
        <h3>{{ title }}</h3>
      </div>

      <div slot="body" class="confirm-body">
        <h4>{{ message }}</h4>
        <div class="alert--container">
          <RgComboboxSurgerySuspendedReason
            v-model="suspend.ccu_id_motivos_suspensoes"
            class="combobox-suspend-reason"
            label="Motivo da Suspensão"
          />
          <RgInput
            v-model="suspend.ccs_observacao"
            :rules="{ required: true }"
            label="Observação"
            class="input--obs"
          />
        </div>
      </div>

      <div slot="footer" class="confirm-actions-footer">
        <button
          class="btn btn-confirmed btn-success"
          @click.stop.prevent="yesClicked"
        >
          Sim, tenho certeza
        </button>
        <button
          class="btn btn-denied btn-danger"
          @click.stop.prevent="noClicked"
        >
          Cancelar
        </button>
      </div>
    </RgBaseModal>
  </div>
</template>
<script type="text/javascript">
import { RgBaseModal, IconDanger, RgInput, toast } from "~tokio/primitive";
import RgComboboxSurgerySuspendedReason from "@/app/surgery-center/surgery/component/combobox/rg-combobox-surgery-suspended-reasons/RgComboboxSurgerySuspendedReason";
export default {
  name: "RgSuspendModal",
  components: {
    RgBaseModal,
    IconDanger,
    RgComboboxSurgerySuspendedReason,
    RgInput,
  },
  props: {
    title: {
      type: String,
      default: "Confirm",
    },
    message: {
      type: String,
      default: "Are you sure?",
    },
    show: {
      type: Boolean,
      default: undefined,
    },
    surgeryId: {
      default: "",
    },
    sectorId: {
      required: true,
    },
  },
  data() {
    return {
      suspend: {
        ccu_id_motivos_suspensoes: "",
        ccu_observacao: "",
      },
    };
  },
  methods: {
    suspendSurgery: function () {
      return this.$store.dispatch("SurgeryCenter/Surgery/SUSPEND_SURGERY", {
        ccu_observacao: this.suspend.ccs_observacao,
        ccu_id_controles_cirurgicos: this.surgeryId,
        ccu_id_motivos_suspensoes: this.suspend.ccu_id_motivos_suspensoes,
      });
    },
    yesClicked: function () {
      const data = { ...this.suspend, cci_id_setores: this.sectorId };
      if (data.ccu_id_motivos_suspensoes) {
        return this.suspendSurgery()
          .then((pRes) => {
            if (pRes) {
              this.$store.dispatch("SurgeryCenter/Surgery/filter");
              this.suspend.ccu_id_motivos_suspensoes = "";
              this.suspend.ccu_observacao = "";
              this.$emit("confirmed");
              toast.success(
                "O pedido de suspensão foi efetuado com sucesso",
                "Suspensão efetuada...",
              );
            }
          })
          .catch((pErr) => {
            toast.error(pErr[0].message, "Falha ao Suspender Cirurgia");
          });
      } else {
        toast.error(
          "Para Suspender uma cirurgia é obrigatório informar o seu motivo do suspensão",
          "Falha ao suspender...",
        );
      }
    },
    noClicked: function () {
      this.$emit("denied");
      this.suspend.ccu_id_motivos_suspensoes = "";
      this.suspend.ccu_observacao = "";
    },
    close: function () {
      this.$emit("close");
    },
  },
};
</script>
