<style src="./ExamLaboratory.scss" lang="scss" scoped></style>
<template lang="html">
  <div class="exam-laboratory-detail">
    <RgSearch
      ref="RgSearch"
      v-model="mutableExamList"
      :search-function="searchFilter"
      :clear-function="cleanForm"
      :build-filter="generateFilter"
      :new-url="'/exam/laboratory/details'"
      :item-height="74"
      result-title="Listagem de Exames Laboratoriais"
    >
      <div slot="filters" class="exam-laboratory-detail-filter">
        <div class="grid">
          <div class="selectinput">
            <RgComboboxUnithealth
              v-model="form.selectedUnity"
              permission="exame2"
              default-text="Todas"
              class="inputitem"
            />
          </div>

          <div class="selectinput">
            <RgComboboxSector
              ref="RgComboboxSector"
              v-model="form.selectedSector"
              :disabled="disableSector"
              :unit-health="form.selectedUnity"
              class="inputitem"
              default-text="Todos"
            />
          </div>

          <div class="selectinput">
            <RgSuggestEmployee
              ref="requestedEmployee"
              v-model="form.suggestRequestedEmployee"
              :rules="{ forceSelection: startToTypeSuggestRequestedEmployee }"
              data-id="profissional-solicitante"
              data-item="profissional-solicitante"
              label="Profissional Solicitante"
              placeholder="Digite o nome do Profissional"
              class="inputitem"
              @selected="selectingRequestedEmployee"
            />
          </div>

          <div class="selectinput">
            <RgInput
              v-model="form.solicitationNumber"
              placeholder="Informe o protocolo"
              data-id="numero-solicitacao"
              label="Protocolo"
              class="inputitem"
            />
          </div>

          <div class="filter-title">
            <span class="title"> Dados do Paciente </span>
          </div>

          <div class="selectinput">
            <RgInput
              v-model="form.patient"
              data-id="paciente"
              data-item="paciente"
              label="Paciente"
              class="inputitem"
              placeholder="Digite o nome do Paciente"
            />
          </div>

          <div class="selectinput">
            <RgSuggestSmartPerson
              ref="smartPerson"
              v-model="form.suggestDocument"
              :with-patient-module="['examLaboratory']"
              :rules="{ forceSelection: true }"
              :enabled-patient="false"
              :radioCurrent="form.radiostatus"
              :removeFilter="FILTER_REMOVE"
              class="inputitem document"
              @selected="selectingPatient"
            />
          </div>

          <div class="selectinput">
            <RgInputCns
              v-model="form.cns"
              placeholder="Informe o CNS"
              data-id="numero-solicitacao"
              label="CNS"
              class="inputitem"
            />
          </div>

          <div class="filter-title">
            <span class="title"> Período da Solicitação </span>
          </div>

          <div class="selectinput date">
            <RgInputDate
              v-model="form.requestInitialDate"
              placeholder="dd/mm/aaaa"
              data-id="data-inicio-solicitacao"
              label="Data inicial"
              class="inputitem"
            />
            <RgInputDate
              v-model="form.requestFinalDate"
              placeholder="dd/mm/aaaa"
              data-id="data-final-solicitacao"
              label="Data final"
              class="inputitem"
            />
          </div>

          <div
            :class="{ 'agendation-disable': !canSelectEffectivatedPeriod }"
            class="filter-title"
          >
            <span class="title"> Período do Agendamento </span>
          </div>

          <div class="selectinput date">
            <RgInputDate
              v-model="form.schedulingInitialDate"
              :class="{ disable: !canSelectEffectivatedPeriod }"
              :disabled="!canSelectEffectivatedPeriod"
              placeholder="dd/mm/aaaa"
              data-id="data-inicio-agendamento"
              label="Data inicial"
              class="inputitem"
            />

            <RgInputDate
              v-model="form.schedulingFinalDate"
              :class="{ disable: !canSelectEffectivatedPeriod }"
              :disabled="!canSelectEffectivatedPeriod"
              placeholder="dd/mm/aaaa"
              data-id="data-final-agendamento"
              label="Data final"
              class="inputitem"
            />
          </div>

          <div class="grid exam-status">
            <RgRadioExamStatusLaboratory
              v-model="form.examStatus"
              class="custom-radio"
            />
          </div>
        </div>
      </div>

      <div class="result-search-list">
        <div class="exam-list">
          <div
            v-for="(item, indexItem) in mutableExamList"
            :key="indexItem"
            class="exam"
          >
            <div class="item">
              <div class="calendar">
                <IconCalendarLaboratoryExam
                  :color="
                    getColorCalendar(item.leb_id_laboratoriais_status_pedido)
                  "
                  class="icon-calendar"
                />
              </div>

              <div class="patient">
                <strong>{{ item.leb_nome_paciente }} </strong>
              </div>

              <div class="column-1">
                <div>
                  Solicitação:
                  <strong>{{ formatDate(item.leb_data_solicitacao) }} </strong>
                </div>
                <div>
                  Agendamento:
                  <strong
                    >{{
                      formatDate(
                        item.leb_data_agendamento,
                        item.leb_id_laboratoriais_status_pedido,
                      )
                    }}
                  </strong>
                  <strong>{{ formatHour(item.leb_hora_agendamento) }} </strong>
                </div>
              </div>
              <div class="column-2">
                <div>
                  Prontuário:
                  <strong
                    >{{ item.patient.pac_prontuario_unico || "-" }}
                  </strong>
                </div>
                <div>
                  CNS:
                  <strong
                    >{{ item.patient.person.cns.crs_numero || "-" }}
                  </strong>
                </div>
              </div>
              <div class="column-3">
                <div
                  :title="item.leb_profissional_solicitante"
                  class="employee"
                >
                  Profissional solicitante:
                  <strong>{{ item.leb_profissional_solicitante }} </strong>
                </div>
                <div>
                  Protocolo:
                  <strong>{{ item.leb_codigo_solicitacao || "-" }} </strong>
                </div>
              </div>

              <div class="actions">
                <div class="butons">
                  <RgEditButton
                    class="item-button"
                    title="Editar"
                    @click="selectItem(item, indexItem)"
                  />

                  <RgPrinterButton
                    :disabled="
                      !disablePrint(item.leb_id_laboratoriais_status_pedido)
                    "
                    data-id="etiqueta"
                    class="item-button"
                    title="Imprimir etiquetas"
                    @click="showModalTags(item)"
                  />

                  <RgErrorButton
                    :disabled="
                      !disableError(item.leb_id_laboratoriais_status_pedido)
                    "
                    data-id="erro"
                    class="item-button error"
                    title="Erros"
                    @click="showModalError(item)"
                  />

                  <RgPdfButton
                    :disabled="!hasExamResult(item.biosLabInfo)"
                    data-id="baixar"
                    title="Baixar resultado"
                    class="item-button"
                    :open-url="hasExamResult(item.biosLabInfo)"
                    :send-url="getUrl(item.biosLabInfo)"
                    @click="download(item)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </RgSearch>

    <MedicalLoader
      :to-close="loading"
      :status="status.loader"
      :title-loader="'Aguarde, enviando para o Bioslab...'"
      :title-success="'Enviado com sucesso!'"
      :title-failed="'Erro no envio para o Bioslab... '"
      @close="closeLoader"
    />

    <ModalShowError
      :show="showError"
      :procedures="errors"
      @close="closeErrorModal"
    />

    <ModalShowTags
      :can-print="canPrint"
      :bioslab-id="examBioslabId"
      :show="showTags"
      @close="closeTagModal"
    />
  </div>
</template>

<script>
import RgSearch from "~tokio/foundation/rg-search/RgSearch.vue";
import moment from "moment";
import { isEmpty } from "lodash";
import {
  IconCalendarLaboratoryExam,
  RgInputDate,
  RgInput,
  RgSuggestEmployee,
  RgSuggestSmartPerson,
  RgComboboxUnithealth,
  RgComboboxSector,
  RgEditButton,
  RgPrinterButton,
  RgPdfButton,
  RgErrorButton,
} from "~tokio/primitive";
import {
  ModalShowError,
  ModalShowTags,
} from "$exam/submodules/view/individual-effectivation/component/modal";
import MedicalLoader from "~tokio/primitive/loader/medical-loader/MedicalLoader";
import RgInputCns from "$person/common/components/input/rg-input-cns/RgInputCns";
import RgRadioExamStatusLaboratory from "$exam/submodules/view/individual-effectivation/component/radio/rg-radio-exam-status-laboratory/RgRadioExamStatusLaboratory";
import EXAM_STATUS_LABORATORY from "$exam/submodules/view/individual-effectivation/component/radio/rg-radio-exam-status-laboratory/GetExamStatusLaboratory";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb.js";

const REQUESTED = 1;
const SCHEDULED = 2;
const PENDING = 3;
const PARTIALLY_ATTENDED = 4;
const ATTENDED = 5;

export default {
  name: "ExamLaboratory",
  components: {
    IconCalendarLaboratoryExam,
    MedicalLoader,
    ModalShowError,
    ModalShowTags,
    RgSearch,
    RgInput,
    RgRadioExamStatusLaboratory,
    RgInputDate,
    RgSuggestEmployee,
    RgComboboxUnithealth,
    RgSuggestSmartPerson,
    RgComboboxSector,
    RgEditButton,
    RgPrinterButton,
    RgPdfButton,
    RgInputCns,
    RgErrorButton,
  },
  filters: {
    existsItem: (pValue) =>
      pValue && pValue.length > 0 ? pValue : "NÃO EXISTE",
  },
  data() {
    return {
      loading: false,
      errors: [],
      status: {
        loader: 0,
      },
      suggestEmployee: "",
      examBioslabId: 0,
      form: {
        suggestRequestedEmployee: "",
        suggestDocument: "",
        examStatus: [],
        sector: null,
        radiostatus: "cpf",
        cns: null,
        cpf: null,
        nascimento: null,
        solicitationNumber: null,
        leb_id_profissional_solicitante: null,
        vin_id: null,
        patient: null,
        patientId: null,
        recordNumber: null,
        leb_nascimento_paciente: null,
        requestInitialDate: null,
        requestFinalDate: null,
        schedulingInitialDate: null,
        schedulingFinalDate: null,
        selectedUnity: null,
        selectedSector: null,
      },
      selectedItem: {},
      showError: false,
      showTags: false,
      mutableExamList: [],
    };
  },

  computed: {
    canPrint() {
      return this.selectedItem.leb_id_laboratoriais_status_pedido !== ATTENDED;
    },

    canSelectEffectivatedPeriod() {
      return !this.form.examStatus
        ? false
        : this.form.examStatus.includes(EXAM_STATUS_LABORATORY.AGENDADO) ||
            this.form.examStatus.includes(EXAM_STATUS_LABORATORY.PENDENTE) ||
            this.form.examStatus.includes(EXAM_STATUS_LABORATORY.ATENDIDO) ||
            this.form.examStatus.includes(
              EXAM_STATUS_LABORATORY.PARCIALMENTE_ATENDIDO,
            );
    },

    startToTypeSuggestEmployee() {
      return !isEmpty(this.suggestEmployee);
    },

    startToTypeSuggestRequestedEmployee() {
      return !isEmpty(this.form.suggestRequestedEmployee);
    },

    isThereExams() {
      return this.mutableExamList && this.mutableExamList.length > 0;
    },

    disableSector() {
      return !this.form.selectedUnity;
    },
  },

  watch: {
    canSelectEffectivatedPeriod(pValue) {
      if (!pValue) {
        this.form.schedulingInitialDate = "";
        this.form.schedulingFinalDate = "";
      }
    },
  },
  created() {
    this.FILTER_REMOVE = [
      { label: "Nome", col: "peopleName" },
      { label: "CNS", col: "cns" },
    ];
  },

  destroyed() {
    const existThisRoute = ValidateIfRouteExistInBreadscrumb(
      "/exam/laboratory",
      this.$route.meta.breadcrumb,
    );

    if (!existThisRoute) {
      this.$store.commit("Exam/RESET_FILTER_BIOSLAB");
    }
  },

  mounted() {
    this.$store.commit("Exam/Laboratory/RESET_EXAMINFO");
    this.form.requestInitialDate = moment()
      .subtract(1, "days")
      .format("DD/MM/YYYY");
    this.form.requestFinalDate = moment().format("DD/MM/YYYY");

    const hasFilter = this.$store.getters["Exam/GET_FILTER_BIOSLAB"];
    if (hasFilter) {
      this.reSearch(hasFilter);
    }
  },

  methods: {
    async reSearch(pFilter) {
      this.form = pFilter;
      this.form.examStatus = !pFilter.examStatus ? [] : pFilter.examStatus;

      if (this.form.vin_id) {
        this.$refs.requestedEmployee.forceSelection({
          pes_nome: this.form.suggestRequestedEmployee,
          vin_id: this.form.vin_id,
        });
      }

      if (this.form.patientId) {
        this.$refs.smartPerson.fillPatientById(this.form.patientId);
      }

      this.$nextTick(async () => {
        await this.$refs.RgSearch.performSearch();
      });
    },

    searchFilter(pData) {
      return this.$store.dispatch(
        "Exam/Laboratory/SEARCH_BIOSLAB_LABORATORY_EXAM",
        { ...pData },
      );
    },

    generateFilter() {
      const variable = {
        unitHealth: this.form.selectedUnity ? this.form.selectedUnity : null,
        sectorId: this.form.selectedSector ? this.form.selectedSector : null,
        examStatus:
          this.form.examStatus && this.form.examStatus.length > 0
            ? this.form.examStatus
            : null,
        cns: this.form.cns ? this.form.cns : null,
        patient:
          this.form.patient && this.form.patient.length > 0
            ? this.form.patient
            : null,
        patientId: this.form.patientId ? this.form.patientId : null,
        requestEmployeeId: this.form.leb_id_profissional_solicitante,
        solicitationNumber:
          this.form.solicitationNumber &&
          this.form.solicitationNumber.length > 0
            ? this.form.solicitationNumber
            : null,
        recordNumber:
          this.form.recordNumber && this.form.recordNumber.length > 0
            ? Number(this.form.recordNumber)
            : null,
        leb_nascimento_paciente:
          this.form.leb_nascimento_paciente &&
          this.form.leb_nascimento_paciente.length > 0
            ? this.$utils.date.BrazilianDateToDatabase(
                this.form.leb_nascimento_paciente,
              )
            : null,
        requestInitialDate:
          this.form.requestInitialDate &&
          this.form.requestInitialDate.length > 0
            ? this.$utils.date.BrazilianDateToDatabase(
                this.form.requestInitialDate,
              )
            : null,
        requestFinalDate:
          this.form.requestFinalDate && this.form.requestFinalDate.length > 0
            ? this.$utils.date.BrazilianDateToDatabase(
                this.form.requestFinalDate,
              )
            : null,
        schedulingInitialDate:
          this.form.schedulingInitialDate &&
          this.form.schedulingInitialDate.length > 0
            ? this.$utils.date.BrazilianDateToDatabase(
                this.form.schedulingInitialDate,
              )
            : null,
        schedulingFinalDate:
          this.form.schedulingFinalDate &&
          this.form.schedulingFinalDate.length > 0
            ? this.$utils.date.BrazilianDateToDatabase(
                this.form.schedulingFinalDate,
              )
            : null,
      };

      this.$store.commit("Exam/SET_FILTER_BIOSLAB", this.form);
      return variable;
    },

    selectItem(pItem) {
      this.$router.push({
        name: "exam.laboratory.details",
        params: { examData: pItem },
      });
    },

    selectingPatient(pValue) {
      if (pValue && pValue.source) {
        this.form.patientId = pValue.source.pes_id;
        this.form.cpf = pValue.source.cpf ? pValue.source.cpf.cpf_numero : null;
        this.form.nascimento = this.$utils.date.BrazilianDateFormat(
          pValue.source.pes_nascimento,
        );
        this.form.prontuario =
          pValue.source.patient && pValue.source.patient.pac_prontuario_unico
            ? pValue.source.patient.pac_prontuario_unico
            : null;
      } else {
        this.form.patientId = null;
        this.form.cpf = null;
        this.form.prontuario = null;
      }
    },

    selectingRequestedEmployee(pEmployee) {
      const employeeData = pEmployee.source;
      if (
        employeeData &&
        employeeData.employeeRelationship &&
        employeeData.employeeRelationship.employee
      ) {
        this.form.leb_id_profissional_solicitante =
          employeeData.employeeRelationship.employee.fun_id;
        this.form.vin_id = employeeData.vin_id;
      } else {
        this.form.leb_id_profissional_solicitante = null;
      }
    },

    formatDate(pValue, pStatus) {
      let date = "";

      if (!pValue && pStatus === 1) {
        date = "Pedido não agendado";
      } else {
        date = pValue ? moment(pValue).format("DD/MM/YYYY") : "";
      }

      return date;
    },

    formatHour(pValue) {
      const hour = pValue ? pValue.substring(5, 0) : "";

      return hour;
    },

    getColorCalendar(pStatus) {
      switch (pStatus) {
        case REQUESTED:
          return "#56B0C9";
        case SCHEDULED:
          return "#B46CFF";
        case PENDING:
          return "#EDC76A";
        case PARTIALLY_ATTENDED:
          return "#FFA56A";
        case ATTENDED:
          return "#66D48F";
        default:
          return "#fff";
      }
    },

    download(pItem) {
      this.hasExamResult(pItem.biosLabInfo);
      this.getUrl(pItem.biosLabInfo);
    },

    getUrl(pItem) {
      var url = "";
      if (pItem) {
        if (pItem.length > 0) {
          const result = pItem.find((item) => {
            return item.lpe_url_resultado !== null;
          });

          url = result ? result.lpe_url_resultado : "";
        } else {
          url = pItem.lpe_url_resultado || "";
        }
      }
      return window.esusng.serverConfig.reportsURL + url;
    },

    disablePrint(status) {
      return status === SCHEDULED;
    },

    disableError(status) {
      return status === PENDING;
    },

    hasExamResult(pItem) {
      var hasResult = false;
      if (pItem.length > 0) {
        hasResult = pItem.some((item) => {
          return item.lpe_url_resultado && item.lpe_url_resultado.length > 0;
        });
      } else {
        hasResult =
          pItem.lpe_url_resultado && pItem.lpe_url_resultado.length > 0;
      }
      return hasResult;
    },

    showModalTags(pItem) {
      this.showTags = true;
      this.selectedItem = pItem;
      this.examBioslabId = pItem.leb_id;
    },

    showModalError(pItem) {
      this.showError = true;
      this.errors = [];

      if (pItem.leb_mensagem_erro !== "" && pItem.leb_mensagem_erro) {
        this.errors.push({ leb_mensagem_erro: pItem.leb_mensagem_erro });
      }

      if (pItem.biosLabInfo.length >= 1) {
        pItem.biosLabInfo.forEach(async (element) => {
          const procedures = await this.$store.dispatch(
            "Bioslab/SEARCH_BIOSLAB_LABORATORY_EXAM_LIST",
            {
              lle_codigo_sigtap: element.lpe_codigo_sigtap,
              lle_codigo_bioslab_mne: element.lpe_codigo_exame_bioslab_mne,
            },
          );

          if (procedures) {
            const {
              lle_procedimentos: procedureName,
              lle_ativo: active,
            } = procedures[0];

            if (active !== "0") {
              this.errors.push({
                ...element,
                nome: procedureName,
              });
            }
          }
        });
      }
    },

    closeTagModal() {
      this.showTags = false;
    },

    closeErrorModal() {
      this.showError = false;
    },

    closeLoader() {
      this.loading = false;
    },

    cleanForm() {
      this.loading = false;
      this.form.suggestRequestedEmployee = "";
      this.form.suggestDocument = "";
      this.form.radiostatus = "cpf";
      this.form = {
        examStatus: [],
        cns: null,
        cpf: null,
        nascimento: null,
        prontuario: null,
        leb_id_profissional_solicitante: null,
        patient: null,
        recordNumber: null,
        leb_nascimento_paciente: null,
        requestInitialDate: moment().subtract(1, "days").format("DD/MM/YYYY"),
        requestFinalDate: moment().format("DD/MM/YYYY"),
        schedulingInitialDate: null,
        schedulingFinalDate: null,
        selectedUnity: null,
        selectedSector: null,
        patientId: null,
      };
      this.mutableExamList = [];

      this.$store.commit("Exam/RESET_FILTER_BIOSLAB");
      this.$emit("clear-search", true);
    },
  },
};
</script>
