<style src="./BillingList.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="billing-list">
    <ModuleBox
      :granted="hasPermission"
      :disabled="!hasBofId"
      title="Procedimentos adicionados"
      class="module-box"
    >
      <ListContent @edit-procedure-item="editProcedureItem" />
    </ModuleBox>
  </section>
</template>
<script>
import ModuleBox from "~tokio/foundation/modulebox/Modulebox";
import ListContent from "../list-content/ListContent";
export default {
  name: "BulletinList",
  components: {
    ModuleBox,
    ListContent,
  },
  data() {
    return {};
  },
  computed: {
    hasBofId() {
      return this.$store.getters["Billing/Billing/HAS_BOF_ID"];
    },
    hasPermission() {
      return this.$Permissions.global.has(
        "faturamento.atendimentosEmergencia",
        this.$store.getters["Login/GET_UNI_HEALTH_ID"],
      );
    },
  },
  methods: {
    editProcedureItem(pProcedureItem) {
      this.$emit("edit-procedure-item", pProcedureItem);
    },
  },
};
</script>
