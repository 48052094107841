<style
  src="./RgSurgeryEmployeeFunctionsGroupForm.scss"
  lang="scss"
  scoped
></style>
<template lang="html">
  <div class="surgery-employee-functions-form">
    <RgForm @submit="submit">
      <div class="row">
        <RgInputDebounce
          v-model="form.grc_nome"
          :rules="{ required: true }"
          class="col-12"
          label="Grupo Cirúrgico"
          placeholder="Digite o grupo de funções"
          @debounced="doDebounce"
        />
      </div>
    </RgForm>
  </div>
</template>

<script>
import { RgInputDebounce, toast } from "~tokio/primitive";
import { RgForm } from "~tokio/foundation/form";
import SurgeryEmployeeFunctionsGroupModel from "$surgery-center/surgery/model/SurgeryEmployeeFunctionsGroupModel";
export default {
  name: "RgSurgeryEmployeeFunctionsGroupForm",
  components: { RgForm, RgInputDebounce },
  data: () => {
    return {
      form: {
        grc_nome: "",
        grc_id: "",
        grc_ativo: "",
      },
    };
  },
  methods: {
    doDebounce() {
      this.$emit("search-for", this.form.grc_nome);
    },
    submit(pErr, pDone) {
      if (pErr) {
        return;
      }
      SurgeryEmployeeFunctionsGroupModel.create(this.formatForm())
        .then((pRes) => {
          pDone();
          this.$emit("saved", pRes);
        })
        .catch((pErr) => {
          pDone();
          toast.error(pErr.response.errors[0].msg, "Erro ao Realizar Operação");
        });
    },
    cleanForm() {
      this.form = {
        grc_nome: "",
        grc_id: "",
        grc_ativo: "",
      };
    },
    formatForm() {
      const form = { ...this.form };
      delete form.grc_ativo;

      return form;
    },
  },
};
</script>
