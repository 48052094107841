export default {
  patientInfo: {
    bof_id: "",
    bof_id_unidades_saude: "",
    bof_nome: "",
    bof_sexo: "",
    bof_cns: "",
    bof_data_nascimento: "",
    bof_cod_raca: "",
    bof_cod_nacionalidade: "",
    bof_cod_etnia: "",
    bof_tel: "",
    bof_tel_ddd: "",
    bof_ddd_telefone: "",
    bof_email: "",
    bof_cep: "",
    bof_cod_municipio: "",
    bof_cod_tipo_logradouro: "",
    bof_endereco: "",
    bof_endereco_numero: "",
    bof_endereco_complemento: "",
    bof_bairro: "",
    bof_faturado: "",
  },
  patientBofId: null,
  proceduresList: [],
  procedureStatus: {},
};
