import { OpenMenuModule } from "~common/utils/app/menu";

import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

import ExamPatientObservationQueue from "$exam/submodules/schedule/views/queue-observation/ExamPatientObservationQueue";

export default {
  path: "/exam/schedule/externally-schedule/patient-observation-queue",
  name: "exam.schedule.externally-schedule.patient-observation-queue",
  components: {
    default: ExamPatientObservationQueue,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Exame", link: OpenMenuModule("exam") },
      {
        label: "Agendados Externamente",
        link: "/exam/schedule/externally-schedule",
      },
      { label: "Observações do Paciente na Fila" },
    ],
  },
};
