import { GraphQLClient, gql } from "@/common/request/GraphQLClient";
import moment from "moment";

const query = gql`
  query(
    $uns_id: Int!
    $patientId: Int!
    $initialDate: String
    $finalDate: String
    $status: [Int]
  ) {
    surgeries(
      uns_id: $uns_id
      pac_id: $patientId
      data_inicial: $initialDate
      data_final: $finalDate
      cci_id_status_controles_cirurgicos: $status
    ) {
      count
      rows {
        cci_id
        cci_data_inicio
        cci_numero
        cci_id_status_controles_cirurgicos
        surgery_patient {
          patient {
            pac_id
          }
        }
        surgery_performed {
          ccf_id
          contamination {
            cot_id
            cot_nome
          }
        }
        requesting_clinic {
          inc_nome
        }
      }
    }
  }
`;

/**
 * @param {Object} pStore
 * @param {Object} pCriterias Objeto com informações de busca
 * @param {Number} pCriterias.uns_id O id da unidade de saúde (unidade de saude será utilizado caso nenhum passado)
 * @param {Number} pCriterias.patientId O id do Paciente
 * @param {Number} pCriterias.status Status da Cirurgia
 */

export default async (pStore, pCriterias = {}) => {
  const today = moment().format("YYYY-MM-DD");
  const twoYearsAgo = moment().subtract(2, "years").format("YYYY-MM-DD");

  const variables = sanitizeFields(pCriterias, twoYearsAgo, today, pStore);

  try {
    const { data } = await GraphQLClient.query({ query, variables });
    return data.surgeries.rows;
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors);
  }
};

const sanitizeFields = (pFields, pInitialDate, pFinalDate, pStore) => {
  const variables = {
    uns_id: pFields.uns_id || pStore.rootGetters["Login/GET_UNIT_HEALTH_ID"],
    patientId: pFields.patientId,
    initialDate: pInitialDate,
    finalDate: pFinalDate,
  };
  variables.status = [pFields.status];
  return variables;
};
