import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (context, intIdPessoa) => {
  return AuthLegacyRequest.post("/emergencias/controller-boletim/obter-pai", {
    intIdPessoa,
  })
    .then(({ data }) => {
      const { dados } = HandleResponse(data);
      const { pes_pai } = dados[0];

      return pes_pai;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
