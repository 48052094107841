<style src="./EmployeeFilter.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="employee-filter row">
    <div class="fieldset col-12">
      <RgSuggestEmployee
        v-model="form.employee"
        :rules="{ forceSelection: true }"
        class="row-field"
        label="Profissional que realizou"
        @selected="selectingEmployee"
      />
    </div>

    <div class="fieldset col-6">
      <InputCns class="row-field" label="Número do CNS" />
    </div>
    <div class="fieldset col-6">
      <InputCpf class="row-field" label="Número do CPF" />
    </div>
    <div class="fieldset col-6">
      <RgInput class="row-field" label="Conselho de classe" />
    </div>
    <div class="fieldset col-6">
      <RgInput class="row-field" label="Data de admissão" />
    </div>

    <div class="fieldset col-12">
      <RgInput class="row-field" label="Especialização" />
    </div>

    <div class="fieldset col-12">
      <RgInput class="row-field" label="Tipo de vínculo" />
    </div>

    <fieldset class="col-12">
      <label class="radio">
        <input
          v-model="satus"
          type="checkbox"
          name="test1"
          value="1"
          class="rg-radio"
        />
        <span>Ativo</span>
      </label>
      <label class="radio">
        <input
          v-model="satus"
          type="checkbox"
          name="test2"
          value="2"
          class="rg-radio"
        />
        <span>Inativo</span>
      </label>
    </fieldset>

    <input
      v-for="(item, itemIdx) in numbers"
      :key="itemIdx"
      v-model="numbers[itemIdx]"
    />

    {{ numbers }}
  </section>
</template>

<script>
import { RgSuggestEmployee, RgInput } from "~tokio/primitive/";
import { InputCns, InputCpf } from "$person/common/components/document-types";

export default {
  name: "EmployeeFilter",
  components: {
    RgSuggestEmployee,
    RgInput,
    InputCns,
    InputCpf,
  },
  data() {
    return {
      numbers: [0, 0],
      satus: "",
      satus2: "",
      form: {
        employee: "",
      },
    };
  },
  methods: {
    selectingEmployee() {},
  },
};
</script>
