import { render, staticRenderFns } from "./BulletinList.vue?vue&type=template&id=dec9ecec&scoped=true&lang=html&"
import script from "./BulletinList.vue?vue&type=script&lang=js&"
export * from "./BulletinList.vue?vue&type=script&lang=js&"
import style0 from "./BulletinList.scss?vue&type=style&index=0&id=dec9ecec&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dec9ecec",
  null
  
)

export default component.exports