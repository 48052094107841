const _storage = window.localStorage;
class LocalStorage {
  setItem(key, value) {
    return _storage.setItem(key, value);
  }

  getItem(key) {
    return _storage.getItem(key);
  }

  removeItem(key) {
    return _storage.removeItem(key);
  }

  setObject(key, object) {
    return this.setItem(key, JSON.stringify(object));
  }

  getObject(key) {
    return JSON.parse(this.getItem(key));
  }
}

export default new LocalStorage();
