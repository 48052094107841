import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation SaveFavorite($favorite: FavoriteOperationInputType!) {
    SaveFavorite(favorite: $favorite) {
      opf_id
      opf_id_unidades_saude
      opf_id_usuarios
      opf_rota
      opf_nome_rota
      opf_nome_icone
      opf_modulos
      opf_permissao
      opf_ativo
    }
  }
`;

const SaveFavoriteOperation = async (store, variables) => {
  try {
    const { data } = await GraphQLClient.mutate({ mutation, variables });
    return data.SaveFavorite;
  } catch (err) {
    const jsonResponse = JSON.parse(JSON.stringify(err));
    return Promise.reject(jsonResponse.graphQLErrors[0].message);
  }
};

export default SaveFavoriteOperation;
