<template lang="html">
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 198.084 198.084"
    style="enable-background: new 0 0 198.084 198.084"
    xml:space="preserve"
  >
    <path
      d="M197.951,77.097l-16.024,78.532c-1.222,5.987-6.488,10.288-12.599,10.288H20.196c-8.135,0-14.225-7.459-12.599-15.429
  L21.94,80.197c1.29-6.32,6.849-10.859,13.299-10.859h150.14h6.237c3.068,0,5.558,2.11,6.266,4.856
  C198.117,75.109,198.155,76.094,197.951,77.097z M12.142,78.198c2.23-10.928,11.943-18.86,23.097-18.86h150.076
  c-0.6-5.713-5.444-10.181-11.314-10.181H94.819c-1.605-9.628-9.995-16.989-20.07-16.989H13.35C5.989,32.167,0,38.156,0,45.517
  v92.186L12.142,78.198z"
    />
  </svg>
</template>

<script>
export default {
  name: "Open",
};
</script>
