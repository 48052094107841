import LocalStorage from "~common/local-storage/LocalStorage";
import ReportCss from "./ReportCss";

const setupPrint = function (data, css) {
  const popupPrint = window.open("", "popupPrint", "width=1000,height=1000");
  popupPrint.document.open("text/html", "replace");
  popupPrint.document.write(ReportCss(css));
  popupPrint.document.write(data.html);

  const imageLogoContainer = popupPrint.document.getElementById(
    "imageLogoContainer",
  );
  if (imageLogoContainer) {
    imageLogoContainer.src = LocalStorage.getObject("client-logo");
  }

  popupPrint.document.close();
  setTimeout(() => {
    popupPrint.focus();
    popupPrint.print();
  }, 500);
};

const PrintReport = function (pData, pCustomCss = "") {
  setTimeout(setupPrint(pData, pCustomCss), 500);
};

export default PrintReport;
