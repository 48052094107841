<style src="./ContactOptions.scss" lang="scss" scoped></style>
<template lang="html">
  <div class="contact-options dropdown">
    <button type="button" class="button-open">
      <IconEllipsis />
    </button>

    <div class="dropdown-content">
      <ul class="options-container">
        <li
          v-if="haveMyEsus === false"
          class="item"
          @click="activeMyEsusStatus"
        >
          Ativar MeuEsus nesse número
        </li>

        <li
          v-if="haveMyEsus === true"
          class="item"
          @click="inactiveMyEsusStatus"
        >
          Desativar MeuEsus nesse número
        </li>

        <li v-show="haveMyEsus" class="item" @click="resetPassword">
          Reenviar senha para MeuEsus
        </li>

        <li class="remove-bottom" @click="removeContact">
          <IconGarbage class="item-icon" />
          Remover contato
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { IconEllipsis, IconGarbage } from "~tokio/primitive/icon";
import ClickOutside from "vue-click-outside";
import { mapGetters } from "vuex";
import MyEsusStatus from "$patient/common/service/my-esus/Status";

export default {
  name: "ContactOptions",
  components: {
    IconEllipsis,
    IconGarbage,
  },
  directives: {
    ClickOutside,
  },
  props: {
    contactInfo: null,
    contactItemIndex: null,
  },
  data() {
    return {
      panelOpened: true,
    };
  },
  computed: {
    ...mapGetters({
      myEsusInfo: ["Patient/GET_MY_ESUS_DATA"],
    }),
    haveMyEsus() {
      return (
        this.myEsusInfo.mes_ddd_celular ===
        `${this.contactInfo.ddd.ddd_codigo}${this.contactInfo.tel_numero}`
      );
    },
  },
  mounted() {},
  methods: {
    // closePanel () {
    //   this.panelOpened = false
    // },
    // openOptions () {
    //   this.panelOpened = true
    // },
    resetPassword() {
      const myEsusData = this.defineMyEsusData();
      myEsusData.personStatus.mes_status = MyEsusStatus["RESET-PASSWORD"];
      this.$store.dispatch("Patient/UPDATE_MY_ESUS", myEsusData);
      // this.closePanel()
    },
    activeMyEsusStatus() {
      const myEsusData = this.defineMyEsusData();
      myEsusData.personStatus.mes_status = MyEsusStatus["TO-ACTIVE"];
      this.$store.dispatch("Patient/UPDATE_MY_ESUS", myEsusData);
      // this.closePanel()
    },
    inactiveMyEsusStatus() {
      const myEsusData = this.defineMyEsusData();
      myEsusData.personStatus.mes_status = MyEsusStatus["TO-INACTIVE"];
      this.$store.dispatch("Patient/UPDATE_MY_ESUS", myEsusData);
      // this.closePanel()
    },
    defineMyEsusData() {
      const sanitizedNumber = this.contactInfo.tel_numero.replace(/[^\d]/i, "");
      return {
        personStatus: {
          mes_id_pessoas: this.$store.getters["Patient/GET_PERSON_ID"],
          mes_id_ddds: this.contactInfo.ddd.ddd_id,
          mes_ddd_celular: `${this.contactInfo.ddd.ddd_codigo}${sanitizedNumber}`,
          mes_celular: sanitizedNumber,
          mes_status: MyEsusStatus["TO-ACTIVE"],
          mes_data: new Date(),
        },
      };
    },
    removeContact() {
      const removeTelephone = {
        phoneId: this.contactInfo.tel_id,
        contactIndex: this.contactItemIndex,
      };
      this.$emit("suspend-item", this.contactItemIndex);
      this.$store.dispatch("Patient/DELETE_TELEPHONE", removeTelephone);
      // this.closePanel()
    },
  },
};
</script>
