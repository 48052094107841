import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query NotEffectivedExamsReason($emn_id: Int) {
    notEffectivedExamsReason(emn_id: $emn_id) {
      rows {
        emn_id
        emn_nome
      }
      count
    }
  }
`;

export default async ({ commit }, emn_id) => {
  const { data } = await GraphQLClient.query({
    query,
    variables: { emn_id },
    fetchPolicy: "no-cache",
  }).catch((err) => {
    throw err;
  });
  return data.notEffectivedExamsReason.count === 1
    ? data.notEffectivedExamsReason.rows[0]
    : data.notEffectivedExamsReason;
};
