<template lang="html">
  <section class="show-icons">
    <div
      v-for="(icon, idx) in iconObj"
      :key="idx"
      class="show-icons--container"
    >
      <div class="show-icons--icon-container">
        <div :is="icon" class="show-icons--icon-item"></div>
      </div>
      <span>{{ icon.name }}</span>
    </div>
  </section>
</template>

<script>
import * as icons from "./index";

export default {
  name: "ShowIcons",
  components: {
    icons,
  },
  data() {
    return {
      iconObj: null,
      iconList: [],
    };
  },
  mounted() {
    const tmpObj = {};
    for (const icon in icons) {
      tmpObj[icons[icon].name] = icons[icon];
    }
    this.iconObj = tmpObj;
  },
};
</script>

<style lang="scss">
.show-icons {
  width: 100vw;
  display: flex;
  flex-wrap: wrap;

  .show-icons--container {
    display: flex;
    flex: 0 0 150px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 150px;
    margin: 20px;

    height: 50px;
    .show-icons--icon-container {
      .show-icons--icon-item {
        width: 30px;
        height: 30px;
        transition: width 0.1s, height 0.1s;

        &:hover {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
}
</style>
