import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchSecondaryProcedureReport(
    $unsId: Int
    $specialSecondaryProcedure: String
    $presentation: String
    $status: String
    $limit: Int
    $offset: Int
    $toPrint: Boolean
    $toExcel: Boolean
    $toCsv: Boolean
  ) {
    SearchSecondaryProcedureReport(
      uns_id: $unsId
      inp_novo_codigo_procedimento: $specialSecondaryProcedure
      iaf_apresentacao: $presentation
      iaf_faturado: $status
      limit: $limit
      offset: $offset
      toPrint: $toPrint
      toExcel: $toExcel
      toCsv: $toCsv
    ) {
      html
      count
      csv
      excel
      fileName
      rows {
        icf_nome
        iaf_prontuario
        iaf_numero_aih
        inp_novo_nome_procedimento
      }
    }
  }
`;
export default async (
  { commit },
  {
    uns_id,
    toPrint,
    toExcel,
    toCsv,
    initialDate,
    finalDate,
    specialSecondaryProcedure,
    presentation,
    status,
    limit,
    offset,
  },
) => {
  presentation = !presentation.indexOf("/")
    ? presentation
    : presentation.replace("/", "");
  try {
    const variables = {
      unsId: uns_id,
      toPrint,
      toCsv,
      toExcel,
      initialDate,
      finalDate,
      specialSecondaryProcedure,
      presentation,
      status,
      limit,
      offset,
    };
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    const html =
      !data.SearchSecondaryProcedureReport ||
      !data.SearchSecondaryProcedureReport.html
        ? ""
        : data.SearchSecondaryProcedureReport.html.replace('\\"', '"');
    const count = !data.SearchSecondaryProcedureReport
      ? 0
      : data.SearchSecondaryProcedureReport.count;
    const csv = !data.SearchSecondaryProcedureReport
      ? 0
      : data.SearchSecondaryProcedureReport.csv;
    const excel = !data.SearchSecondaryProcedureReport
      ? 0
      : data.SearchSecondaryProcedureReport.excel;
    const fileName = !data.SearchSecondaryProcedureReport
      ? 0
      : data.SearchSecondaryProcedureReport.fileName;
    return {
      html,
      count,
      csv,
      excel,
      fileName,
      rows: data.SearchSecondaryProcedureReport.rows,
    };
  } catch (error) {
    throw new Error("Houve um problema ao obter dados do servidor");
  }
};
