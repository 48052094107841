import GetProcedureList from "../action/getProcedureList";

export default {
  LOAD_PROCEDURE_LIST: ({ commit, state }) => {
    commit("RESET_PROCEDURES_LIST");
    const pBofId = state.patientInfo.bof_id;
    if (!pBofId) return false;
    GetProcedureList({ bof_id: pBofId }).then((pResult) => {
      commit("STORE_PROCEDURES_LIST", pResult.proceduresList);
    });
  },
};
