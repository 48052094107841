import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation($iacId: Int) {
    DeleteHospitalizationAihSecondaryCid(iac_id: $iacId) {
      iac_id
    }
  }
`;

export default async ({ rootState }, pIacId) => {
  const variables = {
    iacId: pIacId,
  };
  try {
    const { data } = await GraphQLClient.mutate({
      mutation,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.DeleteHospitalizationAihSecondaryCid;
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors);
  }
};
