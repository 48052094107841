<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchScholarity from "./action/SearchScholarity";

export default {
  name: "RgComboboxScholarity",
  mixins: [RgComboboxMixin],
  data: () => {
    return {};
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];
      const data = await SearchScholarity({ limit: 15 });
      data.forEach((item) => {
        this.mData.push({ value: item.esc_id, text: item.esc_nome });
      });
    },
  },
};
</script>
