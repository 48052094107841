<style src="./PrintReportTeamProductivity.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="print-report">
    <PrintHeader
      :unit-health-info="unitHealthInfo"
      title="Relatório de Produtividade dos Profissionais"
    />

    <ul class="report-list">
      <li class="list-item">
        <ul class="list-content">
          <li>
            <ul class="list-content-header">
              <li class="column-number">#</li>
              <li class="column-qt">Qt</li>
              <li class="column-material">Profissional / Função</li>
            </ul>
          </li>
          <li v-for="(item, itemIdx) in fillData" :key="itemIdx">
            <ul class="list-content-values">
              <li class="column-number">{{ itemIdx + 1 }}</li>
              <li class="column-qt">{{ item.quantity }}</li>
              <li class="column-material">
                {{ item.pes_nome }} - {{ item.fnc_nome }}
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </section>
</template>

<script>
import LocalStorage from "~common/local-storage/LocalStorage";
import UnitHealthInfoModel from "$surgery-center/unit-health/model/UnitHealthInfo";
import PrintHeader from "$surgery-center/reports/component/print-header/PrintHeader";
import ReportSearchModel from "$surgery-center/reports/model/ReportSearchModel";

export default {
  name: "PrintReportMaterial",
  components: {
    PrintHeader,
  },
  data() {
    return {
      filter: {},
      fillData: [],
      unitHealthInfo: {
        name: "",
        city: "",
        state: "",
        address: "",
        number: "",
      },
      headerInfo: {
        name: "",
        city: "",
        state: "",
        address: "",
        number: "",
      },
    };
  },
  mounted() {
    const auxData = this.getParams(window.location.search);
    this.incomingData = JSON.parse(auxData);
    this.$nextTick(() => {
      this.performSearch(this.incomingData);
    });
  },
  methods: {
    performSearch(pFilters) {
      ReportSearchModel.getReportEmployeeProductivity(pFilters)
        .then((pRes) => this.fillReportData(pRes))
        .then(() => this.getUnitHealthData())
        .then(() => {
          setTimeout(() => {
            window.print();
          }, 500);
        })
        .catch((pErr) => {
          // reject(new Error('Nenhum Mapa Cirurgico Encontrado'))
        });
    },
    getParams(pParams) {
      const AUX = decodeURIComponent(pParams);
      const firstSplit = AUX.toString().split(/=|&/gi);
      const paramsSplited = firstSplit[1].split(",");
      return paramsSplited;
    },
    fillReportData(pData) {
      this.fillData = pData;
      return Promise.resolve();
    },
    getUnitHealthData() {
      const unitHealthId = LocalStorage.getObject("current-unit-health");
      return new Promise((resolve, reject) => {
        UnitHealthInfoModel.showHeaderInfo(unitHealthId).then((res) => {
          this.unitHealthInfo.name = res.data.uns_nome;
          this.unitHealthInfo.city = res.data.mun_nome;
          this.unitHealthInfo.state = res.data.est_sigla;
          this.unitHealthInfo.address = res.data.ebd_logradouro;
          this.unitHealthInfo.number = res.data.end_numero;
          resolve();
        });
      });
    },
  },
};
</script>
