<style src="./AboutSoftware.scss" lang="scss" scoped></style>
<template>
  <Modulebox ref="modulebox" title="Sobre o Sistema" class="about-software">
    <div class="about-software-body">
      <div class="intro">
        <div class="about">
          <p class="text">
            Sistema: <span class="strong">{{ system }}</span>
          </p>

          <p class="text">
            Versão Atual: <span class="strong">{{ version }}</span>
          </p>

          <p class="text">
            Última Atualização:
            <span class="strong">{{ releaseDate.replaceAll("-", "/") }}</span>
          </p>
        </div>
      </div>

      <div
        v-for="(item, index) in versionsEsus"
        ref="listVersion"
        :key="item.index"
        class="list-versions"
      >
        <h3 class="title unselect" @click="showMore(index)">
          Atualização {{ item.ave_nome_versao }}
          <IconArrowCircleRight
            class="svg"
            :class="{ rotate: activeIndex.includes(index) }"
          />
        </h3>

        <div v-show="activeIndex.includes(index)" class="description">
          <div class="text-att" v-html="item.ave_descricao" />
        </div>
      </div>
    </div>

    <div slot="footer" class="about-software-footer">
      <MediumButton class="back-btn" label="Voltar" @click="goBack" />
    </div>
  </Modulebox>
</template>

<script type="text/javascript">
import Modulebox from "~tokio/foundation/modulebox/Modulebox";

import { MediumButton, IconArrowCircleRight } from "~tokio/primitive";
export default {
  name: "AboutSoftware",
  components: {
    Modulebox,
    MediumButton,
    IconArrowCircleRight,
  },

  data() {
    return {
      versionsEsus: [],
      activeIndex: [0],
    };
  },

  computed: {
    version() {
      return window.esusng.version;
    },

    system() {
      return window.esusng.system;
    },

    releaseDate() {
      return window.esusng.releaseDate;
    },
  },

  created() {
    this.ITEM_HEIGHT = 40;
  },

  mounted() {
    const version = this.$route.params;
    this.getVersionData(version);
  },

  methods: {
    goBack() {
      this.$router.go(-1);
    },

    showMore(pIndex) {
      const indexInActive = this.activeIndex.findIndex(
        (item) => item === pIndex,
      );

      this.activeIndex.includes(pIndex)
        ? this.activeIndex.splice(indexInActive, 1)
        : this.activeIndex.push(pIndex);
    },

    async getVersionData(pVersion) {
      try {
        this.versionsEsus = await this.$store.dispatch(
          "Home/SEARCH_CHANGELOG",
          {
            ave_nome_versao: window.esusng.version,
          },
        );

        if (pVersion && pVersion.ave_id) {
          const index = this.versionsEsus.findIndex(
            (item) => item.ave_id === pVersion.ave_id,
          );

          this.activeIndex = [index];

          this.$nextTick(() => {
            // posiciona o scroll no item certo
            this.$refs.modulebox.$refs.myself.scrollTop =
              (index + 1) * this.ITEM_HEIGHT;
          });
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>
