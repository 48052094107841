<template lang="html">
  <svg
    id="svg8"
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns:svg="http://www.w3.org/2000/svg"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    width="3.8734701mm"
    height="6.6462898mm"
    viewBox="0 0 3.8734701 6.6462898"
    version="1.1"
    inkscape:version="0.92.1 r15371"
    sodipodi:docname="arroww.svg"
  >
    <defs id="defs2">
      <pattern
        id="EMFhbasepattern"
        y="0"
        x="0"
        height="6"
        width="6"
        patternUnits="userSpaceOnUse"
      />
    </defs>
    <sodipodi:namedview
      id="base"
      pagecolor="#ffffff"
      bordercolor="#666666"
      borderopacity="1.0"
      inkscape:pageopacity="0.0"
      inkscape:pageshadow="2"
      inkscape:zoom="5.6"
      inkscape:cx="-14.318131"
      inkscape:cy="9.1738707"
      inkscape:document-units="mm"
      inkscape:current-layer="layer1"
      showgrid="false"
      inkscape:window-width="1680"
      inkscape:window-height="987"
      inkscape:window-x="-8"
      inkscape:window-y="-8"
      inkscape:window-maximized="1"
      fit-margin-top="0"
      fit-margin-left="0"
      fit-margin-right="0"
      fit-margin-bottom="0"
    />
    <metadata id="metadata5">
      <rdf:RDF>
        <cc:Work rdf:about="">
          <dc:format>image/svg+xml</dc:format>
          <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
          <dc:title></dc:title>
        </cc:Work>
      </rdf:RDF>
    </metadata>
    <g
      id="layer1"
      inkscape:label="Camada 1"
      inkscape:groupmode="layer"
      transform="translate(-109.94422,-146.26614)"
    >
      <path
        id="path4488"
        d="m 113.41552,152.84893 0.33867,-0.33867 c 0.0423,-0.0423 0.0635,-0.10583 0.0635,-0.14816 0,-0.0635 -0.0212,-0.10583 -0.0635,-0.14817 l -2.62465,-2.62464 2.62465,-2.62465 c 0.0423,-0.0423 0.0635,-0.0847 0.0635,-0.14817 0,-0.0635 -0.0212,-0.10583 -0.0635,-0.14816 l -0.33867,-0.33867 c -0.0423,-0.0423 -0.0847,-0.0635 -0.14816,-0.0635 -0.0635,0 -0.10583,0.0212 -0.14817,0.0635 l -3.11147,3.11148 c -0.0423,0.0423 -0.0635,0.0847 -0.0635,0.14817 0,0.0635 0.0212,0.10583 0.0635,0.14816 l 3.11147,3.11148 c 0.0423,0.0423 0.0847,0.0635 0.14817,0.0635 0.0635,0 0.10583,-0.0212 0.14816,-0.0635 z"
        style="
          fill-opacity: 1;
          fill-rule: nonzero;
          stroke: none;
          stroke-width: 0.26458332;
        "
        inkscape:connector-curvature="0"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: "LeftPointArrow",
};
</script>
