<style src="./SurgeryEquipment.scss" lang="scss" scoped></style>
<template lang="html">
  <div class="surgery-equipment">
    <section class="teams-form">
      <form class="teams-list-form row" @submit.prevent="insertItem">
        <div class="equipment-combo col-11">
          <RgTypeaheadSurgeryEquipment
            v-model="lItem.value"
            label="Equipamento"
            placeholder="Digite o equipamento"
            @select="seletedItem"
          />
        </div>
        <div class="form-submit col-1">
          <button class="button">
            <IconAdd class="button--icon" />
          </button>
        </div>
      </form>
    </section>
    <section class="form-list">
      <div class="requesting-list">
        <ul>
          <li>
            <ul class="form-list--container-header row">
              <li class="form-list--employee col-11">
                Equipamentos Cirúrgicos
              </li>
              <li class="form-list--remove col-1" />
            </ul>
          </li>
          <li
            v-for="(item, i) in templateListItems"
            :key="i"
            class="form-list--container-row"
          >
            <ul class="form-list--container-content row">
              <li class="form-list--employee col-11">{{ item.text }}</li>
              <li class="form-list--remove col-1">
                <RgRemoveSmall @confirm-remove="removeItem(i)" />
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
import { IconAdd, RgRemoveSmall } from "~tokio/primitive";
import RgTypeaheadSurgeryEquipment from "$surgery-center/surgery/component/combobox/rg-typeahead-surgery-equipment/RgTypeaheadSurgeryEquipment";
import SurgeryEquipmentModel from "$surgery-center/surgery/model/SurgeryEquipmentModel";
import SurgeryAggregateBaseMixin from "$surgery-center/surgery/component/mixin/SurgeryAggregateBaseMixin";
export default {
  name: "SurgeryEquipment",
  components: {
    IconAdd,
    RgTypeaheadSurgeryEquipment,
    RgRemoveSmall,
  },
  extends: SurgeryAggregateBaseMixin,
  methods: {
    fillList() {
      SurgeryEquipmentModel.getEquipmentsById(this.surgeryId).then((pList) => {
        const mapList = pList.map((pListItem) => {
          return {
            text: pListItem.tie_nome,
            value: pListItem.cec_id_tipos_equipamentos_cirurgicos,
          };
        });

        this.templateListItems = mapList;
        this.updateModel(mapList);
      });
    },
    updateModel(pItem) {
      if (!pItem) return [];
      const arrFiltered = pItem.map((item) => {
        return {
          cec_id_tipos_equipamentos_cirurgicos: item.value,
        };
      });
      this.$emit("input", arrFiltered);
      return arrFiltered;
    },
  },
};
</script>
