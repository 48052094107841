<style src="./EffectivenessExitReason.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="effectiveness-exit-reason">
    <section class="row">
      <RgInputDate
        v-model="form.ccs_data"
        label="Data"
        class="input-textarea col-4"
      />
      <RgInputHour
        v-model="form.ccs_hora"
        label="Hora"
        class="input-textarea col-4"
      />
      <RgComboboxExitReason
        v-model="form.ccs_id_motivos_saidas"
        label="Motivo"
        class="input-textarea col-4"
      />
    </section>

    <div class="related-field row">
      <span class="label">Observação</span>
      <textarea v-model="form.ccs_observacao" class="input-textarea col-12" />
    </div>
  </section>
</template>
<script>
import { RgInputHour, RgInputDate } from "~tokio/primitive";
import RgComboboxExitReason from "$surgery-center/surgery/component/combobox/rg-combobox-exit-reason/RgComboboxExitReason";

export default {
  name: "EffectivenessExitReason",
  components: { RgInputHour, RgInputDate, RgComboboxExitReason },
  props: {
    value: {
      default: null,
    },
  },
  data() {
    return {
      form: {
        ccs_id: "",
        ccs_id_controles_cirurgicos_efetivacoes: "",
        ccs_id_motivos_saidas: "",
        ccs_data: "",
        ccs_hora: "",
        ccs_observacao: "",
      },
    };
  },
  watch: {
    form: {
      handler: function (pValue, pOldValue) {
        const validReturn = this.rudeValidateFields();
        this.$emit("input", validReturn ? this.form : {});
      },
      deep: true,
    },
    value(pValue, pOldValue) {
      if (Object.keys(pValue).length > 0) this.form = pValue;
    },
  },
  methods: {
    rudeValidateFields() {
      const isValid =
        this.form.ccs_data.length > 0 &&
        this.form.ccs_hora.length > 0 &&
        this.form.ccs_id_motivos_saidas > 0;
      return isValid;
    },
  },
};
</script>
