<template lang="html">
  <svg
    enable-background="new 0 0 473 473"
    version="1.1"
    viewBox="0 0 473 473"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="m317.67 214.42 5.667-86.42h20.951v-90h-98.384v-38h-113.23v38h-98.384v90h20.951l20 305h140.57c23.578 24.635 56.766 40 93.478 40 71.368 0 129.43-58.062 129.43-129.43 0-68.551-53.572-124.82-121.05-129.15zm-155-184.42h53.232v8h-53.232v-8zm-98.384 38h250v30h-250v-30zm39.049 335-18.033-275h207.97l-5.77 87.985c-61.031 10.388-107.64 63.642-107.64 127.59 0 21.411 5.231 41.622 14.475 59.43h-90.996zm205.95 40c-54.826 0-99.43-44.604-99.43-99.43s44.604-99.429 99.43-99.429 99.43 44.604 99.43 99.429-44.604 99.43-99.43 99.43z"
      />
      <polygon
        points="342.25 289.4 309.28 322.36 276.32 289.4 255.11 310.61 288.07 343.57 255.11 376.53 276.32 397.75 309.28 364.78 342.25 397.75 363.46 376.53 330.5 343.57 363.46 310.61"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: "Discard",
};
</script>
