import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchPatientHistory(
    $pes_base: Int
    $pes_id: Int!
    $id_modulo: Int
    $regulacao: Int
    $initialDate: String
    $finalDate: String
    $searchAll: String
    $limit: Int
    $offset: Int
  ) {
    patientHistory(
      pes_base: $pes_base
      pes_id: $pes_id
      id_modulo: $id_modulo
      regulacao: $regulacao
      initialDate: $initialDate
      finalDate: $finalDate
      searchAll: $searchAll
      limit: $limit
      offset: $offset
    ) {
      count
      rows {
        id
        id_fila
        id_modulo
        nome_modulo
        uns_nome
        set_nome
        data
        hora
        regulacao
        usu_nome
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    return data.patientHistory;
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors);
  }
};
