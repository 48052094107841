<template lang="html">
  <div v-if="hasPermission" class="rg-dropdown-menu-hospitalization">
    <button
      :class="{
        '-empty': generateSituationStatus === 1,
        '-waiting': generateSituationStatus === 2,
        '-full': generateSituationStatus === 3,
        '-desinfecting': generateSituationStatus === 4,
        '-maintenance': generateSituationStatus === 5,
      }"
      class="button"
    >
      <IconArrow class="svg" />
    </button>
    <div :class="{ 'show-up': showUp }" class="dropdown">
      <span
        v-for="(element, index) in itens"
        :key="index"
        class="item"
        @click="openBedFlow(element)"
      >
        {{ element.name }}
      </span>
    </div>
  </div>
</template>
<script>
import { IconArrow } from "~tokio/primitive/icon/symbols";
import TYPEBED from "./GetTypeBedValues";

export default {
  name: "RgDropdownMenuHospitalization",
  components: {
    IconArrow,
  },
  props: {
    generateSituationStatus: {
      type: Number,
      default: 0,
    },
    hospitalization: {
      type: Object,
      default: () => {},
    },
    showUp: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      itens: [],
    };
  },
  computed: {
    hasTypeBed() {
      const hosp =
        this.hospitalization &&
        this.hospitalization.tipos_do_leito ===
          "NÃO EXISTEM TIPOS VINCULADOS AO LEITO";
      return hosp;
    },
    hasPermission() {
      return this.itens && this.itens.length > 0;
    },
  },
  watch: {
    generateSituationStatus(pValue) {
      this.setMenuItem();
    },
  },
  mounted() {
    this.setMenuItem();
  },
  methods: {
    getPermissionChangeToEmpty() {
      return this.$Permissions.global.has(
        "internacao.mapaLeitos.trocarSituacaoLeitoParaVazio",
      );
    },
    getPermissionChangeToReservation() {
      return this.$Permissions.global.has(
        "internacao.mapaLeitos.trocarSituacaoLeitoParaReserva",
      );
    },
    getPermissionChangeToBusy() {
      return this.$Permissions.global.has(
        "internacao.mapaLeitos.trocarSituacaoLeitoParaOcupado",
      );
    },
    getPermissionChangeToMaintenance() {
      return this.$Permissions.global.has(
        "internacao.mapaLeitos.trocarSituacaoLeitoParaImpedimento",
      );
    },
    getPermissionChangeToDisinfection() {
      return this.$Permissions.global.has(
        "internacao.mapaLeitos.trocarSituacaoLeitoParaDesinfeccao",
      );
    },

    setMenuItem() {
      this.itens = [];

      const type = this.generateSituationStatus;
      switch (type) {
        case TYPEBED.VAZIO:
          this.createItemByPermission([
            TYPEBED.OCUPADO,
            TYPEBED.RESERVA,
            TYPEBED.DESINFECCAO,
            TYPEBED.MANUTENCAO,
          ]);
          break;
        case TYPEBED.OCUPADO:
          this.createItemByPermission([TYPEBED.DESINFECCAO]);
          break;
        case TYPEBED.RESERVA:
          this.createItemByPermission([TYPEBED.VAZIO, TYPEBED.OCUPADO]);
          break;
        case TYPEBED.DESINFECCAO:
          this.createItemByPermission([TYPEBED.VAZIO, TYPEBED.MANUTENCAO]);
          break;
        case TYPEBED.MANUTENCAO:
          this.createItemByPermission([TYPEBED.DESINFECCAO]);
          break;
      }
    },

    createItemByPermission(pValue) {
      const permissionToEmpty = this.getPermissionChangeToEmpty();
      const permissionToReservation = this.getPermissionChangeToReservation();
      const permissionToBusy = this.getPermissionChangeToBusy();
      const permissionToMaintenance = this.getPermissionChangeToMaintenance();
      const permissionToDisinfection = this.getPermissionChangeToDisinfection();

      for (const typeBed of pValue) {
        if (typeBed === TYPEBED.VAZIO && permissionToEmpty) {
          this.itens.push({ name: "Vazio", value: TYPEBED.VAZIO });
        }
        if (typeBed === TYPEBED.OCUPADO && permissionToBusy) {
          this.itens.push({ name: "Ocupado", value: TYPEBED.OCUPADO });
        }
        if (typeBed === TYPEBED.RESERVA && permissionToReservation) {
          this.itens.push({ name: "Reserva", value: TYPEBED.RESERVA });
        }
        if (typeBed === TYPEBED.DESINFECCAO && permissionToDisinfection) {
          this.itens.push({ name: "Desinfecção", value: TYPEBED.DESINFECCAO });
        }
        if (typeBed === TYPEBED.MANUTENCAO && permissionToMaintenance) {
          this.itens.push({ name: "Manutenção", value: TYPEBED.MANUTENCAO });
        }
      }
    },

    openBedFlow(pFlow) {
      if (
        this.hasTypeBed &&
        (pFlow.value === TYPEBED.OCUPADO || pFlow.value === TYPEBED.RESERVA)
      ) {
        const warning =
          "Não é possível, alterar situação do leito, pois o mesmo não possui tipos vinculados a ele.";
        this.$toaster.warning(warning);
      } else {
        this.$emit("bedFlow", {
          operation: pFlow,
          status: this.generateSituationStatus,
          hospitalization: this.hospitalization,
        });
      }
    },
  },
};
</script>
