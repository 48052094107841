import GetShifts from "./GetShifts";
import GetPropertyTypes from "./GetPropertyTypes";
import GetPermitedUnitHealths from "./GetPermitedUnitHealths";
import GetAreaTeam from "./GetAreaTeam";
import GetEmployeesAb from "./GetEmployeesAb";
import FindHeaderById from "./FindHeaderById";
import GetNationality from "./GetNationality";
import GetMicroarea from "./GetMicroarea";
import GetFamilyIncomeOptions from "./GetFamilyIncomeOptions";
import GetFamilyMembers from "./GetFamilyMembers";
import GetLoggedInEmployeeAB from "./GetLoggedInEmployeeAB";
import GetTeamByEmployeeAB from "./GetTeamByEmployeeAB";
import SearchPatientAB from "./SearchPatientAB";
import SearchPatientEsus from "./SearchPatientEsus";

export default {
  GetShifts,
  GetPropertyTypes,
  GetPermitedUnitHealths,
  GetAreaTeam,
  GetEmployeesAb,
  FindHeaderById,
  GetMicroarea,
  GetFamilyIncomeOptions,
  GetNationality,
  GetFamilyMembers,
  GetLoggedInEmployeeAB,
  GetTeamByEmployeeAB,
  SearchPatientAB,
  SearchPatientEsus,
};
