<template lang="html">
  <svg
    id="Capa_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 612.004 612.004"
    style="enable-background: new 0 0 612.004 612.004"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M499.147,160.094L330.222,329.019c-6.472,6.472-15.075,10.035-24.223,10.035c-9.146,0-17.749-3.561-24.218-10.035
			L112.854,160.094c-25.822-25.817-67.674-25.817-93.491,0c-25.817,25.819-25.817,67.674,0,93.491L188.29,422.508
			c31.443,31.445,73.245,48.764,117.712,48.764s86.269-17.319,117.714-48.761l168.925-168.925c25.817-25.817,25.817-67.674,0-93.491
			C566.822,134.277,524.962,134.277,499.147,160.094z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "ArrowPointDown",
};
</script>
