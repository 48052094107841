import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import ExamSearchPatientExclude from "$exam/submodules/schedule/views/search-patient-exclude/ExamSearchPatientExclude.vue";
import { OpenMenuModule } from "~common/utils/app/menu";

export default {
  path: "/exam/schedule/patient-exclude",
  name: "exam.schedule.patient-exclude",
  components: {
    default: ExamSearchPatientExclude,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Exame", link: OpenMenuModule("exam") },
      {
        label: "Pacientes Excluídos pela Fila",
        link: "/exam/schedule/patient-exclude",
      },
    ],
  },
};
