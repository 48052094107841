<template lang="html">
  <div v-if="show" class="modal-change-professional-scale">
    <RgValidatorForm ref="validator">
      <RgBaseModal
        ref="Modal"
        v-shortkey="['esc']"
        :show="show"
        with-close-button
        class="rg-base-modal"
        @close="close"
        @shortkey.native="close"
      >
        <div slot="header" class="header">
          <div class="title">Editar Escala de Atendimento</div>
        </div>

        <div slot="body" class="body">
          <FormBase class="form-base" title="Escala do Dia">
            <div class="grid informations">
              <div>
                Unidade de Saúde:
                <strong>
                  {{ mData.uns_nome ? mData.uns_nome : this.UNITHEALTHNAME }}
                </strong>
              </div>

              <div>
                Setor:
                <strong> {{ mData.set_nome }} </strong>
              </div>

              <div>
                Profissional:
                <strong>
                  {{ mData.pes_nome ? mData.pes_nome : mData.fun_nome }}
                </strong>
              </div>

              <div>
                Data:
                <strong>
                  {{
                    mData.data_consulta
                      ? mData.data_consulta
                      : mData.DataConsulta
                  }}
                </strong>
              </div>
            </div>

            <div class="inputs">
              <div class="input">
                <RgInput
                  v-model="mData.eag_inicio_atendimentos"
                  v-mask="'##:##'"
                  class="field"
                  label="Horário inicial de atendimento"
                  :rules="{ required: true }"
                />
              </div>

              <div class="input">
                <RgInput
                  v-model="mData.eag_quantidade_consultas"
                  v-mask="'###'"
                  class="field"
                  label="Quantidade de consultas"
                  :rules="{ required: true }"
                />
              </div>

              <div class="input">
                <RgInput
                  v-model="mData.eag_intervalo_consultas"
                  v-mask="'###'"
                  class="field"
                  label="Intervalo entre consultas"
                  :rules="{ required: true, fn: validateMinute }"
                />
              </div>

              <div class="input">
                <RgInput
                  v-model="mData.eag_final_atendimentos"
                  v-mask="'##:##'"
                  class="field"
                  :class="{ disable: true }"
                  disabled
                  label="Último horário de atendimento"
                />
              </div>

              <div class="input">
                <RgInput
                  v-model="lastSchedule"
                  v-mask="'##:##'"
                  disabled
                  :class="{ disable: true }"
                  class="field"
                  label="Horário final de atendimento"
                />
              </div>
            </div>
          </FormBase>
        </div>

        <div slot="footer" class="footer">
          <div class="footer-buttons">
            <RgCancelButton class="cancel" medium @click="close" />

            <RgConfirmButton
              ref="confirmButton"
              v-shortkey="['enter']"
              large
              class="confirm"
              @shortkey.native="save"
              @click="save"
            />
          </div>
        </div>
      </RgBaseModal>
    </RgValidatorForm>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import FormBase from "~tokio/foundation/form-base/FormBase";
import {
  RgInput,
  RgBaseModal,
  RgConfirmButton,
  RgCancelButton,
  RgValidatorForm,
} from "~tokio/primitive";
import moment from "moment";

import { IsValid } from "~common/utils/date";

export default {
  name: "ModalChangeProfessionalScale",
  components: {
    RgInput,
    RgBaseModal,
    RgConfirmButton,
    RgCancelButton,
    FormBase,
    RgValidatorForm,
  },
  directives: { mask },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
    normalScalePermission: {
      type: Number,
      default: 1,
    },
    technicalScalePermission: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      lastSchedule: null,
      mData: {},
      payload: {},
    };
  },
  watch: {
    show(pValue) {
      if (!pValue) {
        this.close();
      }
    },
    data: {
      handler() {
        this.updateLocalData();
      },
      deep: true,
    },
    "mData.eag_inicio_atendimentos"(pValue) {
      this.calculateLastSchedule();
    },

    "mData.eag_intervalo_consultas"(pValue) {
      this.calculateLastSchedule();
    },

    "mData.eag_quantidade_consultas"(pValue) {
      this.calculateLastSchedule();
    },
  },
  created() {
    this.UNITHEALTHNAME = this.$store.getters["Login/GET_UNIT_HEALTH_NAME"];
  },
  mounted() {
    this.updateLocalData();
    this.calculateLastSchedule();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    updateLocalData() {
      this.mData = this.$utils.obj.DeepCopy(this.data);
    },
    calculateLastSchedule() {
      this.mData.eag_inicio_atendimentos =
        this.mData.eag_inicio_atendimentos.length > 4
          ? this.mData.eag_inicio_atendimentos.substr(0, 5)
          : this.mData.eag_inicio_atendimentos;
      const durationInMinutes =
        this.mData.eag_intervalo_consultas *
        (this.mData.eag_quantidade_consultas - 1);
      const endTime = moment(this.mData.eag_inicio_atendimentos, "HH:mm")
        .add(durationInMinutes, "minutes")
        .format("HH:mm");
      this.mData.eag_final_atendimentos = endTime;
      this.lastSchedule = moment(endTime, "HH:mm")
        .add(this.mData.eag_intervalo_consultas, "minutes")
        .format("HH:mm");
    },

    async save() {
      try {
        const isInvalid = !(await this.isFormValid());
        if (isInvalid) {
          this.$toaster.warning("Verifique os campos");
          this.$refs.confirmButton.actionDone();

          return false;
        } else {
          const invalidHours = !this.validHours();

          if (invalidHours) {
            return false;
          }

          this.$refs.confirmButton.actionSubmit();

          this.makePayload();

          const response = await this.$store.dispatch(
            "Appointment/Schedule/SAVE_PROFESSIONAL_SCALE",
            this.payload,
          );

          if (response.hasError) {
            if (
              response.mensagem ===
              "Horário final menor que a última consulta agendada."
            ) {
              this.$toaster.error(
                "A escala ultrapassou 23:59. Caso seja necessário o plantão duplo, faça duas escalas.",
              );
              return false;
            } else {
              return this.$emit("error", response);
            }
          }

          this.$toaster.success("Salvo com sucesso!");
          this.$emit("reSearch", true);
          this.close();
        }
      } catch (error) {
        this.$toaster.warning(`Erro ao alterar dados do dia ${error}`);
        console.error(`Erro ao alterar dados do dia ${error}`);
        this.$refs.confirmButton.fail();
      } finally {
        this.$refs.confirmButton.actionDone();
      }
    },

    makePayload() {
      this.payload = {
        hag_data_consulta: this.mData.data_consulta || this.mData.DataConsulta,
        hag_hora_inicio_consulta_funcionario: this.mData
          .eag_inicio_atendimentos,
        hag_id: this.mData.hag_id,
        hag_id_dias_semana: this.mData.eag_id_dias_semana,
        hag_id_escalas_agendamentos: this.mData.eag_id,
        hag_intervalo_consultas: this.mData.eag_intervalo_consultas,
        hag_quantidade_consultas: this.mData.eag_quantidade_consultas,
        hag_ultimo_horario: this.mData.eag_final_atendimentos,
        horarioFinal: this.lastSchedule,
        permissaoEscalaNormal: this.normalScalePermission,
        permissaoReservaTecnica: this.technicalScalePermission,
      };
    },

    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    validHours() {
      if (
        !IsValid(
          `1900-01-01 ${this.mData.eag_inicio_atendimentos}`,
          "YYYY-MM-DD LT",
          true,
        )
      ) {
        this.$toaster.warning("Horário inicial de atendimento inválido.");
        this.$refs.confirmButton.actionDone();
        return false;
      }
      return true;
    },

    validateMinute(pValue, pErrors) {
      const isInvalidRange =
        pValue && (Number(pValue) <= 0 || Number(pValue) > 59);

      if (isInvalidRange) {
        pErrors.push("Informe um número maior que 0 e menor que 60");
        return false;
      }

      return true;
    },
  },
};
</script>
