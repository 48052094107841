import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query($lpe_id: Int, $lpe_id_laboratoriais_exames_bioslab: Int) {
    SearchBioslabLaboratoryPatientExams(
      lpe_id: $lpe_id
      lpe_id_laboratoriais_exames_bioslab: $lpe_id_laboratoriais_exames_bioslab
    ) {
      rows {
        lpe_id
        lpe_id_laboratoriais_exames_bioslab
        lpe_codigo_sigtap
        lpe_codigo_exame_bioslab_mne
        lpe_objeto_analise
        lpe_material_biologico
        lpe_identificador_exame_bioslab
        lpe_situacao
        lpe_mensagem_erro
      }
    }
  }
`;

export default async (variables) => {
  try {
    const { data } = await GraphQLClient.query({ query, variables });
    return data.SearchBioslabLaboratoryPatientExams.rows;
  } catch (err) {
    const jsonResponse = JSON.parse(JSON.stringify(err));
    return Promise.reject(jsonResponse.graphQLErrors[0].message);
  }
};
