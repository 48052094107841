import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (context, intIdSetor) => {
  return AuthLegacyRequest.post(
    "consultas/controller-marcacao-consulta/obter-vinculo-intervalo-periodo",
    intIdSetor,
  )
    .then(({ data }) => {
      data = HandleResponse(data);
      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
