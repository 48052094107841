import { render, staticRenderFns } from "./RgRadioList.vue?vue&type=template&id=48947174&scoped=true&lang=html&"
import script from "./RgRadioList.vue?vue&type=script&lang=js&"
export * from "./RgRadioList.vue?vue&type=script&lang=js&"
import style0 from "./RgRadioList.scss?vue&type=style&index=0&id=48947174&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48947174",
  null
  
)

export default component.exports