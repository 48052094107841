import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchPatientAB(
    $pab_nome: String
    $pab_nome_mae: String
    $pab_documento: String
    $data_nascimento_inicial: String
    $data_nascimento_final: String
    $pab_ativo: Boolean
    $limit: Int
    $offset: Int
    $orderDirection: String
    $orderField: String
  ) {
    SearchPatientBasicAttention(
      pab_nome: $pab_nome
      pab_nome_mae: $pab_nome_mae
      pab_documento: $pab_documento
      data_nascimento_inicial: $data_nascimento_inicial
      data_nascimento_final: $data_nascimento_final
      pab_ativo: $pab_ativo
      limit: $limit
      offset: $offset
      orderDirection: $orderDirection
      orderField: $orderField
    ) {
      rows {
        pab_id
        pab_mae_desconhecida
        pab_nome_mae
        pab_nascimento
        pab_responsavel_familiar
        pab_nome
        pab_documento
        pab_id_sexos
        header_form {
          cfi_id_funcionarios_atencao_basica
          cfi_id_equipes
        }
        existCitizenRegister {
          scc_id_motivo_saida_cadastro
        }
      }
      count
    }
  }
`;

export default async (pContext, pFilter) => {
  try {
    const variables = { ...pFilter, pab_ativo: true };
    const { data } = await GraphQLClient.query({
      query,
      fetchPolicy: "no-cache",
      variables,
    });
    return data.SearchPatientBasicAttention;
  } catch (err) {
    console.log(err);
    return err.graphQLErrors;
  }
};
