import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

import { OpenMenuModule } from "~common/utils/app/menu";

import UnitHealthRegisterRoute from "$unit-health/view/register/UnitHealthRegister";

export default {
  path: "/unit-health/register/edit",
  name: "unit-health.register.edit",
  components: {
    default: UnitHealthRegisterRoute,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Cadastros", link: OpenMenuModule("register") },
      { label: "Unidades de Saúde", link: "/unit-health/search" },
      { label: "Editar Unidade", link: "/unit-health/register/edit" },
    ],
  },
};
