<style src="./HospitalizationBillingSearch.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="hospitalization">
    <div class="filter-panel">
      <HospitalizationFilter @submit-filter="submitHospitalizationFilter" />
    </div>
    <div class="content">
      <HospitalizationList
        ref="hospitalizationList"
        :pagination="pagination"
        @selectHospitalization="billing"
        @reloadSearch="search"
      >
        <div slot="actions" class="hospitalization-list-action">
          <button
            :disabled="offsetZero"
            class="btn btn-small btn-primary"
            @click="anterior"
          >
            Anterior
          </button>
          <button
            :disabled="contentLessThanOffset"
            class="btn btn-small btn-primary"
            @click="proxima"
          >
            Próxima
          </button>
        </div>
      </HospitalizationList>
    </div>
  </section>
</template>

<script>
import {
  HospitalizationFilter,
  HospitalizationList,
} from "$billing/hospitalization-billing/common/component";
// import BdSiaModel from '$billing/bdsia/model/BdSia'

export default {
  name: "Hospitalization",
  components: {
    HospitalizationFilter,
    HospitalizationList,
  },
  data() {
    return {
      filterData: {
        uns_id: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        status: "null",
        period: this.$store.getters["Billing/BDSia/GET_PERIOD_DATE"],
      },
      pagination: {
        limit: 5,
        offset: 0,
        total: 0,
        current: 1,
      },
    };
  },
  computed: {
    uns_id() {
      return this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    },
    numberOfPages() {
      return Math.ceil(this.pagination.total / this.pagination.limit);
    },
    offsetZero() {
      return this.pagination.offset === 0;
    },
    getHospitalizationAihList() {
      return this.$store.getters[
        "Billing/HospitalizationBilling/GET_HOSPITALIZATION_AIH_LIST"
      ];
    },
    listSize() {
      return this.getHospitalizationAihList &&
        this.getHospitalizationAihList.count
        ? this.getHospitalizationAihList.count
        : 0;
    },
    contentLessThanOffset() {
      return this.pagination.limit * this.pagination.current >= this.listSize;
    },
  },
  watch: {
    uns_id: function () {
      this.filterData.uns_id = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    },
  },
  mounted() {
    this.calcLimit();
    this.search();
  },
  methods: {
    billing({ int_id, iaf_id, inf_aih_numero_anterior }) {
      if (int_id)
        this.$store.commit(
          "Billing/HospitalizationBilling/SET_HOSPITALIZATION_ID",
          int_id,
        );
      if (iaf_id)
        this.$store.commit(
          "Billing/HospitalizationBilling/SET_HOSPITALIZATION_BILLING_AIH_ID",
          iaf_id,
        );
      if (inf_aih_numero_anterior)
        this.$store.commit(
          "Billing/HospitalizationBilling/SET_BILLING_INFO_TAB_AIH_NUMERO_ANTERIOR",
          inf_aih_numero_anterior,
        );

      this.$router.push("detail");
    },
    submitHospitalizationFilter(pFilterData) {
      this.pagination.offset = 0;
      this.pagination.current = 1;
      this.filterData = pFilterData;
      this.search();
    },
    proxima() {
      this.pagination.offset += this.pagination.limit;
      this.pagination.current += 1;
      this.search();
    },
    anterior() {
      if (this.pagination.offset <= 0) {
        this.pagination.offset = 0;
      } else {
        this.pagination.offset -= this.pagination.limit;
        this.pagination.current -= 1;
      }
      this.search();
    },
    calcLimit() {
      const titleFooterHeight = 42 + 39;
      const itemHeigth = 74 + 14; // Item + Margin Bottom
      const hospitalizationListHeight =
        this.$refs.hospitalizationList.$el.clientHeight - titleFooterHeight;
      this.pagination.limit = Math.floor(
        hospitalizationListHeight / itemHeigth,
      );
    },
    async search() {
      const pResult = await this.$store.dispatch(
        "Billing/HospitalizationBilling/GET_HOSPITALIZATION_AIH_LIST",
        { ...this.filterData, ...this.pagination },
      );

      this.$store.commit(
        "Billing/HospitalizationBilling/SET_HOSPITALIZATION_AIH_LIST",
        pResult,
      );
    },
  },
};
</script>
