import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

const formatDateTime = (pItem) => {
  pItem.bol_data = `${pItem.bol_data} ${pItem.bol_hora}`;

  return pItem;
};

export default async (context, variable) => {
  return AuthLegacyRequest.post(
    "/emergencias/controller-boletim/obter-unidades-pacientes-possui-boletim-sem-saida",
    variable,
  )
    .then(({ data }) => {
      const { dados } = HandleResponse(data);

      dados.map(formatDateTime);

      return dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
