import { render, staticRenderFns } from "./InternalizationReport.vue?vue&type=template&id=45b2d105&scoped=true&"
import script from "./InternalizationReport.vue?vue&type=script&lang=js&"
export * from "./InternalizationReport.vue?vue&type=script&lang=js&"
import style0 from "./Internalization-report.scss?vue&type=style&index=0&id=45b2d105&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45b2d105",
  null
  
)

export default component.exports