import BaseModel from "~common/model/BaseModel";
import Store from "@/store";
class Sector extends BaseModel {
  defineEndPointURI() {
    return "surgery-center/sector";
  }

  getCurrentUnitHealth() {
    return Store.getters["Login/GET_UNIT_HEALTH_ID"];
  }

  listPermited(unitHealthId) {
    const unitHealth = unitHealthId || this.getCurrentUnitHealth();
    return this._request.get("access-control/get-permited-sector", {
      uns_id: unitHealth,
      permission: "centro_cirurgico",
    });
  }
}
export default new Sector();
