<template lang="html">
  <div class="modal-patient-no-way-out-bulletin">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="modal-patient-no-way-out-bulletin-header">
        <h1 class="title">Este paciente possui boletins sem saída</h1>
      </div>

      <div slot="body" class="modal-patient-no-way-out-bulletin-body">
        <span class="description">Deseja prosseguir com o novo boletim?</span>

        <FormBase class="form-base" title="Boletins sem saída">
          <SmartTable
            ref="smartTable"
            name="ModalPatientNoWayOurBulletin"
            class="patient-no-way-out-bulletin-smart-table"
            :item-per-page="7"
            :max-register="7"
            :columns="COLUMNS"
            :body="listNoWayOutBulletin"
            :total="listNoWayOutBulletin.length"
            :show-pagination="false"
            :show-table-menu="false"
          />
        </FormBase>
      </div>
      <div slot="footer" class="modal-patient-no-way-out-bulletin-footer">
        <RgCancelButton medium label="Cancelar" @click="getCancel" />
        <RgSaveButton medium label="Salvar" @click="getSave" />
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { RgBaseModal, RgCancelButton, RgSaveButton } from "~tokio/primitive";
import FormBase from "~tokio/foundation/form-base/FormBase";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";

export default {
  name: "ModalPatientNoWayOutBulletin",
  components: {
    RgBaseModal,
    FormBase,
    SmartTable,
    RgCancelButton,
    RgSaveButton,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    listNoWayOutBulletin: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      mutablePatientNoWayOutBulletin: [],
      totalItems: 0,
    };
  },

  computed: {
    hasPatientNoWayOutBulletin() {
      return (
        Array.isArray(this.mutablePatientNoWayOutBulletin) &&
        this.mutablePatientNoWayOutBulletin.length > 0
      );
    },
  },

  watch: {
    show(pValue) {
      if (!pValue) {
        this.close();
      }
    },

    PatientNoWayOutBulletin(pValue) {
      this.mutablePatientNoWayOutBulletin = pValue;
    },
  },

  mounted() {
    this.mutablePatientNoWayOutBulletin = this.listNoWayOutBulletin;
  },

  created() {
    this.COLUMNS = [
      { name: "Boletim", key: "bol_numero" },
      { name: "Entrada", key: "bol_data" },
      { name: "Unidade de Saúde", key: "uns_nome" },
      { name: "Setor", key: "set_nome" },
    ];
  },

  methods: {
    close() {
      this.mutablePatientNoWayOutBulletin = [];
      this.$emit("close");
    },
    getSave() {
      this.$emit("save");
    },
    getCancel() {
      this.$emit("close");
    },
  },
};
</script>
