export default {
  SET_PATIENT_INFO: (state, pPatientInfo) => {
    state.patientInfo = pPatientInfo;
    state.patientBofId = pPatientInfo.bof_id;
  },
  STORE_BOF_ID: (state, pBofId) => {
    state.patientInfo.bof_id = pBofId;
    state.patientBofId = pBofId;
  },
  STORE_PROCEDURES_LIST: (state, pProceduresList) => {
    state.proceduresList = pProceduresList;
  },
  RESET_PROCEDURES_LIST: (state) => {
    state.proceduresList = [];
  },
  DESTROY_BILLING: (state) => {
    state.proceduresList = [];
    state.patientInfo = {};
    state.patientBofId = null;
  },
  RESET_PROCEDURES_STATUS: (state) => {
    state.procedureStatus = {};
  },
  SET_PROCEDURES_STATUS_BY_ID: (state, { arrayIndex, status, result }) => {
    const objStatus = { arrayIndex, status, result };
    state.procedureStatus[`item${arrayIndex}`] = {};
    state.procedureStatus[`item${arrayIndex}`] = objStatus;
  },
};
