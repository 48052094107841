export default {
  breed: [],
  ethnicities: [],
  genre: [],
  sexualOrientation: [],
  degreeKinship: [],
  occupations: [],
  schoolingAb: [],
  laborMarket: [],
  childSupport: [],
  deficiency: [],
  reasonOut: [],
  weight: [],
  heartDisease: [],
  kidneyDisease: [],
  respiratoryDisease: [],
  streetTime: [],
  foodDay: [],
  personalHygiene: [],
  foodOrigin: [],
};
