import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  console.log("variables:", variables);
  return AuthLegacyRequest.post(
    "pacientes/controller-paciente/buscar-historico-geral-paciente",
    variables,
  )
    .then(({ data }) => {
      data = HandleResponse(data);
      data.dados.map((item) => {
        item.dataHora = item.data + " " + item.hora;
      });
      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
