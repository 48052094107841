import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query searchHealthPlan($pls_ativo: Boolean) {
    healthPlan(pls_ativo: $pls_ativo) {
      rows {
        pls_id
        pls_id_clientes
        pls_nome
        pls_obs
        pls_ativo
      }
    }
  }
`;

export default async (variables) => {
  try {
    const active = variables.pls_ativo || true;
    variables = { ...variables, pls_ativo: active };

    const { data } = await GraphQLClient.query({ query, variables });
    return data.healthPlan.rows;
  } catch (err) {
    return Promise.reject(err.graphQLErrors[0].message);
  }
};
