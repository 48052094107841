<template lang="html">
  <div v-show="show" class="modal-pre-service-details">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <div class="title">Detalhes do Pré-Atendimento</div>
      </div>

      <div slot="body" class="body">
        <div class="informations">
          <span class="data">
            Paciente:
            <b>
              {{ getValue(preServiceData, "prc_nome") }}
            </b>
          </span>

          <span class="data">
            Unidade de Saúde:
            <b>
              {{ getValue(preServiceData, "uns_nome") }}
            </b>
          </span>

          <span class="data">
            Setor:
            <b>
              {{ getValue(preServiceData, "set_nome") }}
            </b>
          </span>
        </div>

        <FormBase title="Dados do Pré-Atendimento" class="pre-service-info">
          <div class="content">
            <span>
              Pressão Arterial Sistólica:
              <b>{{
                preServiceData && preServiceData.prc_pressao_arterial_max
                  ? preServiceData.prc_pressao_arterial_max + " mmHg"
                  : "Não informado"
              }}</b>
            </span>

            <span>
              Glicemia Capilar:
              <b>{{
                preServiceData && preServiceData.prc_glicemia_capilar
                  ? preServiceData.prc_glicemia_capilar + " mg/dL"
                  : "Não informado"
              }}</b>
            </span>

            <span>
              Temperatura:
              <b>{{
                preServiceData && preServiceData.prc_temperatura
                  ? preServiceData.prc_temperatura + " °C"
                  : "Não informado"
              }}</b>
            </span>

            <span>
              Pressão Arterial Diastólica:
              <b>{{
                preServiceData && preServiceData.prc_pressao_arterial_min
                  ? preServiceData.prc_pressao_arterial_min + " mmHg"
                  : "Não informado"
              }}</b>
            </span>

            <span>
              Antes da Insulina:
              <b>{{ getValue(preServiceData, "insulina") }}</b>
            </span>
          </div>
          <hr class="separator" />
          <div class="more-info">
            <span>
              Profissional Responsável:
              <b>
                {{ getValue(preServiceData, "pes_nome") }}
              </b>
            </span>

            <span
              >Nome da Ocupação
              <b>
                {{ getValue(preServiceData, "ocp_nome") }}
              </b>
            </span>
          </div>
        </FormBase>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import { RgBaseModal } from "~tokio/primitive";
import FormBase from "~tokio/foundation/form-base/FormBase";

export default {
  name: "ModalPreServiceDetails",
  components: {
    RgBaseModal,
    FormBase,
  },
  directives: { mask },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    preServiceData: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    show(pValue) {
      console.log("preServiceData:", this.preServiceData);
      if (!pValue) {
        this.close();
      }
    },
  },
  methods: {
    getValue(pItem, pKey) {
      const value = pItem && pItem[pKey] ? pItem[pKey] : "Não informado";
      return value;
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
