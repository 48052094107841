<template lang="html">
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M256,276.673c-28.016,0-50.81,22.793-50.81,50.81c0,13.895,5.775,27.33,15.857,36.891v45.875
      c0,19.273,15.68,34.953,34.953,34.953s34.953-15.68,34.953-34.953v-45.875c10.078-9.555,15.857-22.993,15.857-36.891
      C306.81,299.466,284.017,276.673,256,276.673z M273.98,346.558c-4.851,4.571-7.633,10.96-7.633,17.53v46.161
      c0,5.705-4.64,10.345-10.345,10.345c-5.705,0-10.345-4.64-10.345-10.345v-46.161c0-6.57-2.782-12.96-7.63-17.527
      c-5.304-5.003-8.226-11.778-8.226-19.078c0-14.447,11.755-26.202,26.202-26.202s26.202,11.755,26.202,26.202
      C282.202,334.783,279.28,341.558,273.98,346.558z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M404.978,209.876h-236.44v-97.804c0-48.227,39.234-87.464,87.462-87.464s87.463,39.237,87.463,87.464v44.268
      c0,6.795,5.51,12.304,12.304,12.304s12.304-5.508,12.304-12.304v-44.268C368.071,50.275,317.796,0,256,0
      S143.929,50.275,143.929,112.072v97.804h-36.908c-20.353,0-36.911,16.559-36.911,36.911v228.301
      c0,20.353,16.558,36.911,36.911,36.911h297.957c20.353,0,36.911-16.558,36.911-36.911V246.788
      C441.89,226.435,425.331,209.876,404.978,209.876z M417.282,475.089c0,6.784-5.519,12.304-12.304,12.304H107.022
      c-6.784,0-12.304-5.519-12.304-12.304V246.788c0-6.784,5.52-12.304,12.304-12.304h297.957c6.784,0,12.304,5.519,12.304,12.304
      V475.089z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "Unlock",
};
</script>
