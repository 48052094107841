import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query searchQueueListReport(
    $toPrint: Boolean
    $toCsv: Boolean
    $toExcel: Boolean
    $limit: Int
    $offset: Int
  ) {
    searchQueueListReport(
      toPrint: $toPrint
      toCsv: $toCsv
      toExcel: $toExcel
      limit: $limit
      offset: $offset
    ) {
      html
      count
      csv
      excel
      fileName
      rows {
        OCUPACAO
        QUANTIDADE
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.searchQueueListReport;
  } catch (err) {
    return err.graphQLErrors;
  }
};
