<template lang="html">
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.8599 10.1082C18.9512 7.78282 14.9103 5.06909 10.5 5.06909C6.07391 5.06909 2.03282 7.80127 0.140144 10.1082C-0.0467146 10.336 -0.0467146 10.664 0.140144 10.8917C2.09405 13.2734 6.14444 15.9309 10.5 15.9309C15.0103 15.9309 19.0341 13.1162 20.8599 10.8918C21.0467 10.664 21.0467 10.336 20.8599 10.1082ZM14.6956 10.5C14.6956 12.8134 12.8135 14.6956 10.5 14.6956C8.18642 14.6956 6.30433 12.8133 6.30433 10.5C6.30433 8.18652 8.18646 6.30434 10.5 6.30434C12.8137 6.30438 14.6956 8.18676 14.6956 10.5ZM1.45198 10.5001C2.14716 9.77761 3.82337 8.2183 6.1764 7.21751C4.69973 9.15782 4.69915 11.8405 6.1757 13.7815C4.38662 13.0233 2.72466 11.8253 1.45198 10.5001ZM14.8236 13.7824C16.3003 11.8421 16.3008 9.15943 14.8243 7.21842C16.6133 7.97668 18.2753 9.17471 19.548 10.4998C18.8528 11.2224 17.1766 12.7816 14.8236 13.7824Z"
    />
    <path
      d="M8.40039 10.4999C8.40039 11.6579 9.34242 12.5999 10.5004 12.5999C11.6583 12.5999 12.6004 11.6579 12.6004 10.4999C12.6004 9.34194 11.6583 8.3999 10.5004 8.3999C9.34242 8.3999 8.40039 9.34194 8.40039 10.4999ZM11.3651 10.4999C11.3651 10.9767 10.9772 11.3646 10.5004 11.3646C10.0236 11.3646 9.63568 10.9767 9.63568 10.4999C9.63568 10.0231 10.0236 9.63519 10.5004 9.63519C10.9772 9.63519 11.3651 10.0231 11.3651 10.4999Z"
    />
    <path
      d="M10.5005 2.67647C10.8416 2.67647 11.1181 2.39992 11.1181 2.05882V0.617646C11.1181 0.276541 10.8416 0 10.5005 0C10.1594 0 9.88281 0.276541 9.88281 0.617646V2.05882C9.88281 2.39992 10.1594 2.67647 10.5005 2.67647Z"
    />
    <path
      d="M14.1848 3.50257C14.4809 3.67148 14.8582 3.5685 15.0273 3.27203L15.8493 1.83085C16.0182 1.53455 15.9151 1.15737 15.6188 0.988344C15.3224 0.819356 14.9453 0.922503 14.7762 1.21889L13.9543 2.66006C13.7853 2.95633 13.8885 3.33355 14.1848 3.50257Z"
    />
    <path
      d="M5.97155 3.27202C6.14054 3.56833 6.51772 3.67151 6.81406 3.50253C7.11037 3.33354 7.21356 2.95632 7.04457 2.66002L6.22261 1.21884C6.05362 0.922537 5.67636 0.819349 5.3801 0.988336C5.08379 1.15732 4.9806 1.53454 5.14959 1.83085L5.97155 3.27202Z"
    />
    <path
      d="M10.5005 18.3235C10.1594 18.3235 9.88281 18.6 9.88281 18.9411V20.3823C9.88281 20.7234 10.1594 21 10.5005 21C10.8416 21 11.1181 20.7234 11.1181 20.3823V18.9411C11.1181 18.6 10.8416 18.3235 10.5005 18.3235Z"
    />
    <path
      d="M6.81406 17.4975C6.51776 17.3285 6.14058 17.4317 5.97155 17.728L5.14959 19.1692C4.9806 19.4655 5.08379 19.8427 5.3801 20.0117C5.67644 20.1807 6.05362 20.0774 6.22261 19.7812L7.04457 18.34C7.2136 18.0437 7.11037 17.6665 6.81406 17.4975Z"
    />
    <path
      d="M15.0273 17.728C14.8583 17.4317 14.481 17.3285 14.1848 17.4975C13.8885 17.6664 13.7853 18.0437 13.9543 18.34L14.7762 19.7811C14.9452 20.0774 15.3224 20.1807 15.6188 20.0117C15.9151 19.8427 16.0182 19.4655 15.8493 19.1692L15.0273 17.728Z"
    />
  </svg>
</template>

<script>
export default {
  name: "Show",
};
</script>
