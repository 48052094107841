import LocalStorage from "~common/local-storage/LocalStorage";

const PatientListHTML = (snap, clientName) => {
  const logo = LocalStorage.getObject("client-logo");
  let body = "";
  snap.recordSet.map((el) => {
    body += `
    <tr>
      <td>${el.pes_nome}</td>
      <td>${el.tel_num}</td>
      <td>${el.fle_nome_procedimento}</td>
      <td>${el.subProcedimento}</td>
      <td>${el.lex_nome}</td>
      <td>${el.usu_nome}</td>
      <td>${el.aex_data}</td>
    </tr>
      `;
  });

  const html = `
   <style type="text/css">

    @media print {
        body {
            margin: 0;
            padding: 0;
            line-height: 1.4em;
            font: 12pt Georgia, "Times New Roman", Times, serif;
            color: #000;
        }

        table {
            page-break-inside: auto;
        }

        tr {
            page-break-inside: avoid; page-break-after: auto;
        }

        footer {
            page-break-inside: avoid; page-break-after: auto;
        }
    }

    body {
        font-family: Verdana, Geneva, sans-serif;
    }

    .uma-coluna div {
        width: 100%;
        margin: 3px 0 0 0;
        float: left;
    }

    .duas-colunas div {
        width: 50%;
        margin: 3px 0 0 0;
        float: left;
    }

    .w33 {
        width: 33%;
        float: left;
    }

    .texto-direita {
        text-align: right;
        font-size: 6pt !important;
        font-weight: 700;
    }

    .texto-observacao {
        line-height: 12px;
    }

    .texto-observacao font {
        font-size: 8pt !important;
    }

    .texto-observacao p {
    margin: 5px 0;
    }

    header h1 {
        margin: 45px 0 0 0;
        text-align: center;
        text-transform: uppercase;
        font-size: 12pt;
        font-family: Verdana, Geneva, sans-serif;
        font-weight: bold;
    }

    header span {
        font-size: 7pt;
        font-weight: bold;
    }

    header div:first-child {
        margin: 0 0 5px 0;
    }

    .logoCliente {
        float: left;
        max-width: 150px;
        max-height: 100px;
        width: 150px;
        height: 100px;
    }

    .logoEsus{
        float: right;
        max-width:  150px;
        max-height: 100px;
        width:  150px;
        height: 100px;
    }

    .info {
        margin: 60px 0 0 0;
    }

    .info-filtro span {
        text-transform: uppercase;
        font-size: 9pt;
    }

    .info-filtro span:first-child {
        font-weight: bold;
    }

    table {
        margin: 150px 0 15px 0;
        border-collapse: collapse;
        width: 100%;
    }

    th:nth-child(1),
    tr td:nth-child(1) {
        width: 30%;
    }

    th:nth-child(2),
    tr td:nth-child(2) {
        width: 10%;
        text-align: center;
    }

    th:nth-child(3),
    tr td:nth-child(3) {
        width: 20%;
    }

    th:nth-child(4),
    tr td:nth-child(4) {
        width: 20%;
    }

    th:nth-child(5),
    tr td:nth-child(5) {
        width: 15%;
    }

    td, th {
        padding: 8px;
        border: 1px solid #ddd;
        font-size: 9pt;
    }

    footer {
        width: 100%;
        margin: 50px 0 0 0;
        font-size: 9pt;
    }

    .footer-recebimento {
        text-align: center;
    }

    .footer-recebimento div {
        margin: 0 0 25px 0;
    }

    .footer-recebimento div:nth-child(2) {
        margin: 0 0 35px 0;
    }

    .footer-recebimento div:nth-child(3) {
        margin: 0 0 5px 0;
    }

    .footer-emissao {
        font-size: 6pt;
    }

    .footer-emissao span:last-child {
        float: right;
    }
    </style>
    <header>
			<div><span>${clientName}</span></div>
			<div class="w33"><img src="${logo}" class="logoCliente"></div>
			<div class="w33"><h1>Pacientes Agendados Externamente</h1></div>
			<div class="w33"><img src="/static/images/logo-esus.png" class="logoEsus"></div>
	</header>
	<br>
	<table style="width:100%">

		<tr>
		  <th>Paciente</th>
		  <th>Telefone</th>
		  <th>Procedimento</th>
		  <th>SubProcedimentos</th>
		  <th>Local de destino</th>
      <th>Usuário</th>
      <th>Data</th>
		</tr>

	${body}

</table>
      `;

  return html;
};

export default PatientListHTML;
