import CREATE_EMPLOYEE from "./CreateEmployee";
import GET_EMPLOYEE_LIST from "./GetEmployeeList";
import CHECK_IF_EMPLOYEE_EXISTS from "./CheckIfEmployeeExists";
import GET_EMPLOYEE_BY_ID from "./GetEmployeeById";
import GET_EMPLOYEE_BY_PERSON_ID from "./GetEmployeeByPersonId";
import GENERATE_EMPLOYEE_FROM_PERSON from "./GenerateEmployeeFromPerson";
import SAVE_CLASS_COUNCIL from "./SaveClassCouncil";
import SAVE_PROFESSIONAL_REGISTRATION from "./SaveProfessionalRegistration";
import UPDATE_PROFESSIONAL_REGISTRATION from "./UpdateProfessionalRegistration";
import REMOVE_PROFESSIONAL_REGISTRATION from "./RemoveProfessionalRegistration";
import GET_CLASS_COUNCIL from "./GetClassCouncil";
import REMOVE_EMPLOYEE from "./RemoveEmployee";
import GET_HISTORIC_EMPLOYEE_RELATIONSHIP from "./GetHistoricEmployeeRelationshipList";
import CREATE_EMPLOYEE_RELATIONSHIP from "./CreateEmployeeRelationship";
import CREATE_EMPLOYEE_RELATIONSHIP_SECTOR from "./CreateEmployeeRelationshipSector";
import GET_EMPLOYEE_RELATIONSHIP_LIST_BY_EMPLOYEE_ID from "./GetEmployeeRelationshipListByEmployeeId";
import REMOVE_EMPLOYEE_RELATIONSHIP from "./RemoveEmployeeRelationship";
import REMOVE_EMPLOYEE_RELATIONSHIP_SECTOR from "./RemoveEmployeeRelationshipSector";
import UPDATE_EMPLOYEE_RELATIONSHIP_BY_ID from "./UpdateEmployeeRelationshipById";
import UPDATE_EMPLOYEE_RELATIONSHIP_SECTOR_BY_ID from "./UpdateEmployeeRelationshipSectorById";
import GET_EMPLOYEEE_RELATIONSHIP_SECTOR_LIST from "./GetEmployeeRelationshipSectorList";
import SEARCH_REASON_DISABLE_EMPLOYEE from "./SearchReasonDisableEmployee";
import GET_EMPLOYEE_SECTOR_RELATIONSHIPS from "./GetEmployeeSectorRelationships";
import SEARCH_DISABLING_EMPLOYEE_RELATIONSHIP_SECTORS from "./SearchDisablingEmployeeRelationshipSectors";
import REMOVE_DISABLING_RELATIONSHIP_SECTOR from "./RemoveDisablingRelationshipSector";
import ENABLE_RELATIONSHIP_EMPLOYEE_PERIOD from "./EnableRelationshipEmployeePeriod";
import DISABLE_RELATIONSHIP_EMPLOYEE_PERIOD from "./DisbleRelationshipEmployeePeriod";
import ENABLE_DISABLE_RELATIONSHIP from "./EnableDisableRelationship";

export default {
  CREATE_EMPLOYEE,
  GET_EMPLOYEE_LIST,
  CHECK_IF_EMPLOYEE_EXISTS,
  GET_EMPLOYEE_BY_ID,
  GET_EMPLOYEE_BY_PERSON_ID,
  GET_EMPLOYEEE_RELATIONSHIP_SECTOR_LIST,
  GENERATE_EMPLOYEE_FROM_PERSON,
  SAVE_CLASS_COUNCIL,
  SAVE_PROFESSIONAL_REGISTRATION,
  UPDATE_PROFESSIONAL_REGISTRATION,
  GET_CLASS_COUNCIL,
  REMOVE_EMPLOYEE,
  GET_HISTORIC_EMPLOYEE_RELATIONSHIP,
  CREATE_EMPLOYEE_RELATIONSHIP,
  CREATE_EMPLOYEE_RELATIONSHIP_SECTOR,
  GET_EMPLOYEE_RELATIONSHIP_LIST_BY_EMPLOYEE_ID,
  REMOVE_EMPLOYEE_RELATIONSHIP,
  REMOVE_EMPLOYEE_RELATIONSHIP_SECTOR,
  UPDATE_EMPLOYEE_RELATIONSHIP_BY_ID,
  UPDATE_EMPLOYEE_RELATIONSHIP_SECTOR_BY_ID,
  REMOVE_PROFESSIONAL_REGISTRATION,
  SEARCH_REASON_DISABLE_EMPLOYEE,
  GET_EMPLOYEE_SECTOR_RELATIONSHIPS,
  SEARCH_DISABLING_EMPLOYEE_RELATIONSHIP_SECTORS,
  REMOVE_DISABLING_RELATIONSHIP_SECTOR,
  ENABLE_RELATIONSHIP_EMPLOYEE_PERIOD,
  DISABLE_RELATIONSHIP_EMPLOYEE_PERIOD,
  ENABLE_DISABLE_RELATIONSHIP,
};
