import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query IndividualInformation($document: String!) {
    SearchPatientBasicAttention(pab_documento: $document) {
      rows {
        microarea {
          mic_id
          mic_descricao
        }
        family {
          pab_id
        }
        pab_id
        pab_id_cabecalho_fichas
        pab_id_pacientes
        pab_id_grau_parentesco
        pab_id_ocupacoes_cbo
        pab_id_escolaridade_atencao_basica
        pab_id_situacao_mercado_trabalho
        pab_id_orientacao_sexual
        pab_id_microareas
        pab_id_saida_cidadao_cadastro
        pab_id_etnias
        pab_id_genero_cidadao
        pab_comunidade_tradicional
        pab_mae_desconhecida
        pab_pai_desconhecido
        pab_frequenta_escola_creche
        pab_cuidador_tradicional
        pab_grupo_comunitario
        pab_possui_plano_saude
        pab_sincronizacao
        pab_nome_ocupacao
        pab_celular
        pab_unico_original
        pab_unico_alterado
        pab_data_atendimento
        pab_fora_area
        pab_data_naturalizacao
        pab_portaria_naturalizacao
        pab_data_entrada_brasil
        pab_responsavel_familiar
        pab_id_sexos
        pab_id_ab_etnias
        pab_id_nacionalidades
        pab_numero_pis
        pab_documento_responsavel
        pab_documento
        pab_nome_mae
        pab_nome_pai
        pab_nome
        pab_email
        pab_nascimento
        pab_nome_social
        pab_id_municipio_nascimento
        pab_status_recusa
        pab_rg
      }
      count
    }
  }
`;

export default async (pContext, pDocument) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      fetchPolicy: "no-cache",
      variables: { document: pDocument },
    });
    if (data && data.SearchPatientBasicAttention.count > 0) {
      return data.SearchPatientBasicAttention.rows[0];
    }
    return null;
  } catch (err) {
    console.log(err);
    return err.graphQLErrors;
  }
};
