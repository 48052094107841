import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query($employeeId: Int!, $active: Boolean!) {
    employeeRelationshipSectors(fun_id: $employeeId, vus_ativo: $active) {
      count
      rows {
        employeeRelationship {
          vin_id
          employee {
            person {
              pes_nome
            }
          }
        }
      }
    }
  }
`;

export default async (store, pData = {}) => {
  const criterias = pData.criterias || {};
  const variables = {
    employeeId: criterias.employeeId || undefined,
    active: criterias.sectorRelationshipActive || true,
  };

  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.employeeRelationshipSectors.rows;
  } catch (err) {
    return err.graphQLErrors;
  }
};
