<template lang="html">
  <svg
    width="10.069mm"
    height="11.507mm"
    version="1.1"
    viewBox="0 0 10.069 11.507"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(-50.775 -121.61)">
      <g transform="matrix(.021621 0 0 .021621 50.236 121.79)">
        <path
          d="m348.92 37.751c50.334 50.334 50.334 131.94 0 182.28s-131.94 50.334-182.28 0-50.334-131.94 0-182.28c50.334-50.335 131.94-50.335 182.28 0"
        />
        <path
          d="m455.49 350.67c-117.5-79.391-277.92-79.391-395.42 0-17.433 11.769-27.848 31.656-27.848 53.211v111.68h451.11v-111.68c0-21.555-10.416-41.442-27.848-53.211z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconPatientBioslab",
};
</script>
