import LocalStorage from "~common/local-storage/LocalStorage";

const ScheduleVoucherHTML = (snap) => {
  const logo = LocalStorage.getObject("client-logo");

  const html = `
  <!DOCTYPE html>
  <html>

    <head>
      <style media="print,screen" rel="stylesheet" type="text/css">

          p { widows:5; orphans:5}ul { widows:5; orphans:5}ol { widows:5; orphans:5}.divisor { border-style: dashed; width:100%; }.pageBreak { page-break-after: always; border-style: dashed; width:100%; visibility: hidden}.linha { border-bottom:2px inset; }.linhaDotted { border-bottom: 2px dotted; }.linhaDottedBlack {  border-bottom: 2px dotted black; }.linhaVertical { border-left:2px inset; }.quadro { border: 2px dotted; }.fonteTitulo { font-size: 16px }.fonteTituloComprovanteHtmlExames { font-family: Verdana, Arial, Helvetica, sans-serif;	font-size: 16px; font-weight: bold; }.titulosComprovanteExames { font-size: 12px; font-family: Verdana, Arial, Helvetica, sans-serif; }.titulo2ComprovantesExames { font-size: 13px; font-family: Verdana, Arial, Helvetica, sans-serif; }.fonteComprovanteExames { font-size: 12px; font-family: Verdana, Arial, Helvetica, sans-serif; } .fonteRecomendacoesComprovanteExames { font-size: 12px; font-family: Verdana, Arial, Helvetica, sans-serif;text-aligh:justify } </style><meta http-equiv="Content-Type" content="text/html; charset=UTF-8"><title>Esus</title>

    </head>

    <body>
      <meta charset="utf-8">

      <style>
        /*** Início reset css ***/
        html, body, div, span, applet, object, iframe,
        h1, h2, h3, h4, h5, h6, p, blockquote, pre,
        a, abbr, acronym, address, big, cite, code,
        del, dfn, em, img, ins, kbd, q, s, samp,
        small, strike, strong, sub, sup, tt, var,
        b, u, i, center,
        dl, dt, dd, ol, ul, li,
        fieldset, form, label, legend,
        table, caption, tbody, tfoot, thead, tr, th, td,
        article, aside, canvas, details, embed,
        figure, figcaption, footer, header, hgroup,
        menu, nav, output, ruby, section, summary,
        time, mark, audio, video {
          margin: 0;
          padding: 0;
          border: 0;
          font-size: 100%;
          font: inherit;
          vertical-align: baseline;
        }
        /*** Fim reset css ***/

        /*** Início configuração impressão A4 ***/
        body {
          font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
          background: rgb(204,204,204);
        }

        page {
          background: white;
          display: block;
          margin: 0 auto;
          margin-bottom: 0.5cm;
          box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);

        /*** Tamanho folha A4 ***/
          width: 21cm;
          height: 29.7cm;
        }
        /*** Fim configuração impressão A4 ***/

        /*** Início Grid ***/
        .row::after {
          content: "";
          clear: both;
          display: table;
        }
        [class*="col-"] {
          float: left;
        }
        .col-1 {width: 8.33%;}
        .col-2 {width: 16.66%;}
        .col-3 {width: 25%;}
        .col-4 {width: 33.33%;}
        .col-5 {width: 41.66%;}
        .col-6 {width: 50%;}
        .col-7 {width: 58.33%;}
        .col-8 {width: 66.66%;}
        .col-9 {width: 75%;}
        .col-10 {width: 83.33%;}
        .col-11 {width: 91.66%;}
        .col-12 {width: 100%;}
        /*** Fim Grid ***/

        /*** Início style geral ***/
        .text-center {
          text-align: center;
        }
        hr {
          width: 80%;
          border-top: 1px solid #444;
        }
        /*** Fim style geral ***/

        /*** Início cabeçalho ***/
        .header {
          padding-top: 15pt;
          padding-bottom: 6pt;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 10pt;
        }
        .header p {
          padding-top: .3cm;
        }
        .logo-cliente {
          width: 90px;
        }
        .logo-esus {
          width: 110px;
        }
        /*** Fim cabeçalho ***/

        /*** Início filtros ***/
        .filtro {
          font-size: 8pt;
          padding-top: 6pt;
        }
        .filtro div div {
          margin-top: 2px;
          margin-bottom: 2px;
        }
        .filtro-label {
          font-weight: bold;
          text-align: right;
        }
        .filtro-resposta {
          margin-left: 5px;
          margin-right: -5px;
        }
        /*** Fim filtros ***/

        /*** Início informações ***/
        .informacoes {
          margin-top: 8pt;
          font-size: 8pt;
        }
        .informacoes-dados {
          padding: 4pt 0;
        }
        .informacoes-dados {
          border-bottom: 1px solid #333;
        }
        /*** Fim informações ***/
      </style>


      <div id="page">
        <!-- Início cabeçalho -->
        <div class="row header text-center">
          <div class="col-3">
            <img class="logo-cliente" src="${logo}">
          </div>

          <div class="col-6">
            <p>Prefeitura Municipal</p>
            <p>Ficha de Transferência de Consulta</p>
          </div>

        </div>
        <!-- Fim cabeçalho -->

        <hr>

        <!-- Início filtros -->
        <div class="row filtro">
          <div class="col-7">
            <div class="col-4 filtro-label"><p>Unidade de Saúde:</p></div>
            <div class="col-8 filtro-resposta"><p>${snap.uns_nome}</p></div>

            <div class="col-4 filtro-label"><p>Do Profissional:</p></div>
            <div class="col-8 filtro-resposta"><p>${snap.pes_nome_origem}</p></div>

            <div class="col-4 filtro-label"><p>Para o Profissional:</p></div>
            <div class="col-8 filtro-resposta"><p>${snap.pes_nome_destino}</p></div>
          </div>

          <div class="col-5">
            <div class="col-4 filtro-label"><p>Setor Origem:</p></div>
            <div class="col-8 filtro-resposta"><p>${snap.set_nome_origem}</p></div>

            <div class="col-4 filtro-label"><p>Setor Destino:</p></div>
            <div class="col-8 filtro-resposta"><p>${snap.set_nome_destino}</p></div>
          </div>
        </div>
        <!-- Fim filtros -->


        <!-- Início informações --->
        <div class="informacoes">
          <div>
            <div class="row informacoes-dados">
              <div class="col-4 text-center">Nome</div>
              <div class="col-2 text-center">Pront. Único</div>
              <div class="col-2 text-center">Tel</div>
              <div class="col-2 text-center">Data Origem</div>
              <div class="col-2 text-center">Data Atual</div>
            </div>
          </div>

          <div>
            ${snap.dados_formatado}
          </div>
        </div>
        <!-- Fim informações --->
      </div>
    </body>
  </html>
    `;

  return html;
};

export default ScheduleVoucherHTML;
