export default {
  name: "PaginateMixin",
  data() {
    return {
      selectedItem: null,
      paginate: {
        list: [],
        pageNow: 0,
        perPage: 10,
        searchFilter: "",
      },
    };
  },
  computed: {
    paginatedList() {
      const filteredList = this.filteringList(this.paginate.list);
      const deepList = filteredList;
      const splittedList = deepList.splice(
        this.paginate.perPage * this.paginate.pageNow,
        this.paginate.perPage,
      );
      return splittedList;
    },
    pageCount() {
      return this.paginate.list.length;
    },
  },
  methods: {
    forwardPage() {
      ++this.paginate.pageNow;
    },
    backwardPage() {
      --this.paginate.pageNow;
    },
    filteringList() {
      let filteredList = [];
      if (this.paginate.searchFilter !== "") {
        this.paginate.pageNow = 0;
        const deepList = Array.from(this.paginate.list);
        filteredList = deepList.filter((pItem) => {
          return (
            pItem.text
              .toLowerCase()
              .indexOf(this.paginate.searchFilter.toLowerCase()) > -1
          );
        });
      } else {
        filteredList = Array.from(this.paginate.list);
      }

      return filteredList;
    },
    searchFor(pSearch) {
      this.paginate.searchFilter = pSearch;
    },
    selectItem(pItemId) {
      this.selectedItem = pItemId;
    },
  },
};
