<template lang="html">
  <svg version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m511.82 255.86-233.34-255.86v153.27h-27.105c-67.145 0-130.27 26.148-177.75 73.629-47.48 47.48-73.629 110.61-73.629 177.76v107.35l44.781-49.066c59.902-65.629 144.93-103.59 233.71-104.46v153.25zm-481.82 179v-30.215c0-59.133 23.027-114.73 64.84-156.54s97.406-64.84 156.54-64.84h57.105v-105.84l162.73 178.44-162.73 178.44v-105.84h-26.918c-94.703 0-185.77 38.652-251.57 106.41z"
      fill="#c0cadd"
    />
  </svg>
</template>
<script>
export default {
  name: "Entry",
};
</script>
