import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import AppointmentScaleSearch from "$appointment/submodules/register/views/appointment-scale-search/AppointmentScaleSearch";
import { OpenMenuModule } from "~common/utils/app/menu";

export default {
  path: "/appointment/register/scale/search",
  name: "appointment.register.scale.search",
  components: {
    default: AppointmentScaleSearch,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Consulta", link: OpenMenuModule("appointment") },
      {
        label: "Escalas de Atendimento",
        link: "/appointment/register/scale/search",
      },
    ],
  },
};
