<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
export default {
  name: "RgComboboxOrderFilterBioslab",
  mixins: [RgComboboxMixin],
  data: () => {
    return {
      mData: [
        { value: "leb_data_agendamento", text: "Data de Agendamento" },
        { value: "leb_data_solicitacao", text: "Data de Solicitação" },
      ],
    };
  },
  mounted() {},
};
</script>
