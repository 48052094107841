export default {
  namespaced: true,
  state: () => {
    return {
      loading: false,
      message: "Aguarde...",
      defaultTimeout: 10000, // ten seconds
    };
  },
  getters: {},
  mutations: {
    CLEAN_LOADER: (state) => {
      state.loading = false;
      state.message = "Aguarde...";
    },
    START_LOADER: (state, msg) => {
      state.loading = true;
      state.message = msg || "Aguarde...";
    },
    UPDATE_MESSAGE: (state, msg) => {
      state.message = msg || state.message;
    },
  },
};
