<style src="./ClinicFilter.scss" lang="scss" scoped></style>
<template lang="html">
  <div class="clinic-filter">
    <FilterBaseContainer :filter-key="filterKey" @close="closeFilter()">
      <RgComboboxRequestingClinician
        default-text="TODOS"
        label="Buscar por Clinica"
        default-option-value="0"
        @change="selectClinic"
      />
    </FilterBaseContainer>
  </div>
</template>

<script>
import FilterBaseContainer from "../filter-base-container/FilterBaseContainer";
import FilterBaseContainerMixin from "../filter-base-container/FilterBaseContainerMixin";
import RgComboboxRequestingClinician from "$surgery-center/surgery/component/combobox/rg-combobox-requesting-clinician/RgComboboxRequestingClinician";

export default {
  name: "ClinicFilter",
  components: {
    FilterBaseContainer,
    RgComboboxRequestingClinician,
  },
  mixins: [FilterBaseContainerMixin],
  mounted() {
    this.filterKey = "inc_id";
    const clinicFilterValue = this.$store.getters[
      "SurgeryCenter/Surgery/filterValue"
    ]("inc_id");
    if (clinicFilterValue) this.filterValue = clinicFilterValue;
  },
  methods: {
    selectClinic(pValue) {
      if (pValue && pValue.length > 0) {
        this.filterValue = pValue[0].value;
      } else {
        this.filterValue = false;
      }
      this.changeFilter("inc_id");
    },
  },
};
</script>
