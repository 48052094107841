<template>
  <div class="appointment-period-report">
    <div class="grid">
      <div class="filter-title">
        <span class="title">Período de Consultas</span>
      </div>

      <div class="selectinput _flex">
        <RgInputDate
          v-model="form.initialDate"
          label="Data Inicial"
          class="inputitem"
          :rules="{
            required: true,
          }"
        />
        <RgInputDate
          v-model="form.finalDate"
          label="Data Final"
          class="inputitem mg"
          :rules="{ required: true }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { RgInputDate } from "~tokio/primitive";

const moment = require("moment");

export default {
  name: "AppointmentPeriodReport",
  components: {
    RgInputDate,
  },
  data() {
    return {
      resultTitle: "Período de Consultas",
      form: {
        initialDate: moment().add(-2, "days").format("DD/MM/YYYY"),
        finalDate: moment().format("DD/MM/YYYY"),
      },
    };
  },
  computed: {
    printReportCss() {
      return (
        "@page {size: landscape;}" +
        ".report-column-1 { white-space: normal !important; text-align: left }" +
        ".report-column-3 { white-space: normal !important }" +
        ".report-column-4 { white-space: normal !important; text-align: left }" +
        ".report-column-5 { white-space: normal !important; text-align: left }" +
        ".report-column-6 { white-space: normal !important; text-align: left }" +
        ".report-column-9 { white-space: normal !important; text-align: left }" +
        ".report-column-10 { white-space: normal !important; text-align: left }"
      );
    },

    columnTable() {
      return this.COLUMN;
    },

    getNameSmartTable() {
      return "AppointmentPeriodReport";
    },

    getLegend() {
      return this.legend;
    },
  },

  created() {
    this.COLUMN = [
      { name: "Paciente", key: "PACIENTE", align: "left" },
      { name: "Idade", key: "IDADE" },
      { name: "Telefones", key: "TELEFONES" },
      { name: "Endereço", key: "ENDERECO", align: "left" },
      { name: "Nome da Mãe", key: "RESPONSAVEL_PACIENTE", align: "left" },
      { name: "Unidade de Saúde", key: "UNIDADE_SAUDE", align: "left" },
      { name: "Data Atendimento", key: "DATA_ATENDIMENTO" },
      { name: "Hora Atendimento", key: "HORA_ATENDIMENTO" },
      { name: "Funcionário", key: "FUNCIONARIO", align: "left" },
      { name: "Ocupação", key: "OCUPACAO", align: "left" },
    ];
  },

  mounted() {},

  methods: {
    buildFilter() {
      this.mountLegend();
      return {
        initialDate: String(this.form.initialDate),
        finalDate: String(this.form.finalDate),
      };
    },

    mountLegend() {
      this.legend = "";
    },

    async doSearch(pData) {
      try {
        this.$loader.start("Carregando...");

        return this.$store.dispatch(
          "Appointment/Report/GET_APPOINTMENT_PERIOD_REPORT",
          pData,
        );
      } catch (error) {
        return false;
      } finally {
        this.$loader.finish(1000);
      }
    },

    clearFilter() {
      this.form = {
        initialDate: moment().add(-2, "days").format("DD/MM/YYYY"),
        finalDate: moment().format("DD/MM/YYYY"),
      };
    },
  },
};
</script>
