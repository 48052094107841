var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"smart-table",class:{ disable: _vm.disabled }},[(_vm.showTableMenu)?_c('div',{staticClass:"table-menu"},[(_vm.showSelectAllPage && _vm.multSelect && _vm.body.length > 0)?_c('div',{staticClass:"mult-selectors"},[(!_vm.selectAll)?_c('button',{staticClass:"check",attrs:{"type":"button"},on:{"click":_vm.selectAllList}},[_c('IconBox')],1):_vm._e(),(_vm.selectAll)?_c('button',{staticClass:"check",attrs:{"type":"button"},on:{"click":_vm.unselectAllList}},[_c('IconCheckedBox')],1):_vm._e(),_c('button',{staticClass:"check",attrs:{"type":"button","disable":_vm.body.length === 0},on:{"click":_vm.reverseListSelection}},[_c('IconArrowBox')],1),_c('span',{staticClass:"text"},[_vm._v("Linhas selecionadas: "+_vm._s(_vm.multSelectedRow.length))])]):_vm._e(),_vm._t("legends"),_vm._t("top-buttons"),_c('div',{staticClass:"dropdown-button box-scroll",class:{ 'dropdown-button-fixed': _vm.fixedDropdown }},[(!_vm.removeBtnColumns)?_c('div',{staticClass:"button"},[_c('span',{staticClass:"text unselect"},[_vm._v("Colunas")]),_c('IconArrow',{staticClass:"svg"})],1):_vm._e(),_c('div',{staticClass:"dropdown",class:{
          'dropdown-fixed': _vm.fixedDropdown,
          'dropdown-absolute': !_vm.fixedDropdown,
        }},[_c('span',{staticClass:"label"},[_vm._v("Colunas Exibidas")]),_vm._l((_vm.activeHeader),function(col,index){return _c('label',{key:col.key,staticClass:"item actives unselect",class:{ 'disable unselect': _vm.hasOnlyOneActiveHeader },attrs:{"disabled":_vm.hasOnlyOneActiveHeader}},[_vm._v(" "+_vm._s(col.name)+" "),_c('input',{staticClass:"checkbox",attrs:{"id":index,"type":"checkbox"},domProps:{"checked":col.active},on:{"change":function($event){return _vm.toggleStatus(col, col.key)}}})])}),_c('span',{staticClass:"label"},[_vm._v("Colunas Não Exibidas")]),_vm._l((_vm.inactiveHeader),function(col,index){return _c('label',{key:col.key,staticClass:"item inactives unselect"},[_vm._v(" "+_vm._s(col.name)+" "),_c('input',{staticClass:"checkbox",attrs:{"id":index,"type":"checkbox"},domProps:{"checked":col.active},on:{"change":function($event){return _vm.toggleStatus(col, col.key)}}})])})],2)])],2):_vm._e(),_c('div',{ref:"scrollArea",staticClass:"table-dropdown",style:(_vm.actDynamicHeight)},[_c('div',{ref:"itemHeight",staticClass:"table"},[_c('thead',{staticClass:"theader"},[_c('tr',{staticClass:"title"},_vm._l((_vm.columnTable),function(col,idx){return _c('th',{directives:[{name:"show",rawName:"v-show",value:(col.active),expression:"col.active"}],key:idx,staticClass:"text",style:({ textAlign: col.align })},[_vm._v(" "+_vm._s(col.name)+" ")])}),0)]),_c('tbody',{ref:"tbody",staticClass:"tbody"},_vm._l((_vm.dataTable),function(item,index){return _c('tr',{key:_vm.getRowKey(item, index),staticClass:"body",class:{
            active: _vm.isSelectedRow(item, index),
            disable: _vm.isDisabledRow(item),
            opaque: _vm.isOpaque ? _vm.isOpaque.check(item) : false,
            color: _vm.colorLine && !_vm.circleIndicator ? _vm.colorLine.get(item) : null,
          },style:({
            'background-color':
              _vm.colorLine && !_vm.circleIndicator ? _vm.colorLine.get(item) : null,
          }),attrs:{"disabled":_vm.isDisabledRow(item)},on:{"click":function($event){return _vm.getItem(item, index)}}},_vm._l((_vm.columnTable),function(col,idx){return _c('td',{directives:[{name:"show",rawName:"v-show",value:(col.active),expression:"col.active"}],key:idx,staticClass:"data -datalimit",style:({ textAlign: col.align })},[_c('div',{class:{
                'has-check':
                  ((_vm.circleIndicator || _vm.hasCheck) &&
                    (_vm.isSelectedAndCheckRow(index, idx) ||
                      (idx === _vm.firstIndexSelected && _vm.showCircleColor))) ||
                  col.statusColor,
              }},[(_vm.hasLines)?_c('span',{staticClass:"marks"},[(_vm.isSelectedAndCheckRow(index, idx))?_c('span',{staticClass:"check"},[_c('IconCheck',{staticClass:"svg"})],1):_vm._e(),(
                    ((idx === _vm.firstIndexSelected && _vm.showColorInFirstColumn) ||
                      col.statusColor) &&
                    _vm.showCircleColor
                  )?_c('ColorIndication',{staticClass:"circle-color",attrs:{"value":_vm.colorLine.get(item)}}):_vm._e()],1):_vm._e(),_c('span',{staticClass:"info"},[_vm._v(_vm._s(item[col.key] || "-"))])])])}),0)}),0)])]),_c('RgPagination',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPagination),expression:"showPagination"}],ref:"paginator",attrs:{"data-length":_vm.total,"item-per-page":_vm.itemPerPage,"max-register":_vm.maxRegister},on:{"pagination":_vm.setPagination}}),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }