/**
 * @param  {Object} pObject Objeto a ser clonado
 * @returns {Object} O paramentro recebido caso seja inválido ou o objeto clonado
 */
export const DeepCopy = (pObject) => {
  if (typeof pObject !== "object" || pObject === null) {
    return pObject;
  }

  const clone = Array.isArray(pObject) ? [] : {};

  for (const key in pObject) {
    clone[key] = DeepCopy(pObject[key]);
  }

  return clone;
};
