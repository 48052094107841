<style src="./RgComboboxHealthPlan.scss" lang="scss"></style>
<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchHealthPlan from "./action/SearchHealthPlan";
import SearchHospitalizationHealthPlan from "./action/SearchHospitalizationHealthPlan";

export default {
  name: "RgComboboxHealthPlan",
  mixins: [RgComboboxMixin],
  props: {
    label: {
      default: "Convênio",
    },
    modId: {
      type: Number,
      default: null,
    },
    unitHealth: {
      default: null,
    },
  },
  data: () => {
    return {};
  },
  watch: {
    unitHealth() {
      this.fillData();
    },
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];
      if (this.modId) {
        const data = await SearchHospitalizationHealthPlan({
          pls_ativo: true,
          mod_id: this.modId,
          uns_id: this.unitHealth || 0,
        });

        data.forEach((item) => {
          this.mData.push({
            value: item.pls_id,
            text: item.pls_nome,
          });
        });
      } else {
        const data = await SearchHealthPlan({});
        data.forEach((item) => {
          this.mData.push({
            value: item.pls_id,
            text: item.pls_nome,
          });
        });
      }
    },
    blur() {
      this.$emit("blur");
      this.validate();
    },
  },
};
</script>
