import AuthRequest from "~common/request/AuthRequest";
import { BrazilianDateToDatabase } from "~utils/date";

/**
 * @param {Object} payload
 */
export default async ({ rootState }, payload) => {
  const variables = sanitizeFields({ ...payload });
  try {
    return AuthRequest.get("/queue/queue-info/download", {
      responseType: "blob",
      ...variables,
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

const sanitizeFields = ({ data }) => {
  return {
    initialDate:
      data.initialDate.length > 0
        ? BrazilianDateToDatabase(data.initialDate)
        : null,
    finalDate:
      data.finalDate.length > 0
        ? BrazilianDateToDatabase(data.finalDate)
        : null,
    queueType: data.queueType,
    queueStatus: data.queueStatus,
  };
};
