export default {
  PERSON_LIST: (state, { list }) => {
    state.personList = list;
  },
  BASIC_INFO: (state, data) => {
    state.basicData = { ...data };
  },
  TOGGLE_PERSON_STATUS_BY_PES_ID: (state, { personId, status }) => {
    const personData = JSON.parse(JSON.stringify(state.personList));
    const computedList = personData.map((item) => {
      item.pes_ativo = item.pes_id === personId ? status : item.pes_ativo;
      return item;
    });

    state.personList = computedList;
  },
  ADDITIONAL_INFO: (state, data) => {
    state.additionalInfo = { ...data };
  },
  PHONE_INFO: (state, data) => {
    state.phoneData = [...data];
  },
  MY_ESUS_INFO: (state, data) => {
    state.myEsus = data;
  },
  PATIENT_RECORD_INFO: (state, data) => {
    state.patientRecord = { ...data };
  },
  ADD_NEW_PHONE: (state, phoneInfo) => {
    state.phoneData.push(phoneInfo);
  },
  SET_PERSON_ID: (state, pesId) => {
    state.basicData.pes_id = pesId;
  },
  SET_NEWBORN: (state, newBorn) => {
    state.newBorn = newBorn;
  },
  REMOVE_TELEPHONE_FROM_LIST: (state, { contactIndex }) => {
    state.phoneData.splice(contactIndex, 1);
  },
  DOCUMENTS_LIST_TYPE: (state, { list = null }) => {
    state.documents.documentsType = list;
  },
  DESTROY_PERSON_INFO: (state) => {
    state.basicData = {
      pes_id: null,
      pes_nome: "",
      pes_nome_social: "",
      pes_id_sexos: "",
      pes_mae: "",
      pes_nascimento: "",
      pes_foto: "",
      pes_ativo: null,
      address: {
        end_cep: "",
        end_logradouro: "",
        publicPlaceType: {
          tlg_id: "",
        },
        subNeighborhood: {
          sub_nome: "",
        },
        neighborhood: {
          bai_nome: "",
          city: {
            mun_id: "",
            state: {
              est_id: "",
            },
          },
        },
      },
    };
    state.additionalInfo = {
      nationality: null,
      city: null,
      pes_pai: "",
      pes_id_religiao: null,
      pes_id_tipos_sanguineos: null,
      pes_id_estados_civis: null,
      pes_id_etnias: null,
      pes_id_nacionalidades: null,
      pes_id_municipios: null,
      pes_id_escolaridades: null,
      pes_id_situacao_trabalho: null,
      pes_profissao: "",
      pes_id_usuarios: null,
      user: "",
    };
    state.phoneData = [];
    state.patientRecord = {
      pac_prontuario_unico: null,
      record_numbers: [],
    };
    state.myEsus = {};
  },
  SET_MY_ESUS: (state, data) => {
    state.myEsus = data;
  },
};
