<style src="./RgInputTextArea.scss" lang="scss" scoped></style>
<template>
  <fieldset class="rg-input--component">
    <RgFormBase :label="label" :optional="optional">
      <div class="rg-input--base">
        <div class="rg-input--textbox-container">
          <RgValidationAlert :alert="error" class="rg-input--alert" />
          <textarea
            :id="id"
            ref="inputFieldId"
            v-model="inputValue"
            :data-id="dataId"
            :type="type"
            :class="{ 'on-error': error.length > 0 }"
            :tabindex="tabIndex"
            class="rg-input--textbox input--textarea"
            @blur="validateBlur"
            @focus="checkFocus"
            @change="change"
          />
        </div>
      </div>
    </RgFormBase>
  </fieldset>
</template>
<script>
import { RgFormBase } from "~tokio/foundation/container";
import { RgValidatorMixin, RgValidationAlert } from "../../validation";
import RgInputMixin from "../mixin/RgInputMixin";

export default {
  name: "RgInputTextArea",
  components: {
    RgValidationAlert,
    RgFormBase,
  },
  mixins: [RgValidatorMixin, RgInputMixin],
  props: {
    type: {
      type: String,
      default: "text",
    },
    change: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  methods: {
    validateBlur() {
      this.validate();
      this.$emit("blur");
    },
  },
};
</script>
