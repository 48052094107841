<script>
import { RgRemoteComboboxMixin } from "~tokio/primitive";
import SearchCid from "./action/SearchCid";

export default {
  name: "RgComboboxCid",
  mixins: [RgRemoteComboboxMixin],
  props: {
    label: {
      type: String,
      default: "CID",
    },
    procedure: {
      type: String,
      default: null,
    },
    excludeCid: {
      default: null,
    },
    alwaysReturnValues: {
      type: Boolean,
      default: false,
    },
    loadAllExceptExternalViolence: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      debug: false,
    };
  },
  watch: {
    procedure: function (pNewvalue) {
      this.getDataFromRemote();
    },
  },
  methods: {
    searchCidByProcedure(codProcedure) {
      return SearchCid({ codProcedure });
    },
    searchAllCids() {
      return SearchCid({ groupBy: "cid_codigo" });
    },
    async doSearch() {
      let result = Promise.resolve([]);
      if (this.procedure) {
        result = this.loadAllExceptExternalViolence
          ? await this.searchAllCids()
          : await this.searchCidByProcedure(this.procedure);

        if (result && Array.isArray(result)) {
          if (result.length === 0 && this.alwaysReturnValues) {
            result = await this.searchAllCids();
          }

          if (this.excludeCid) {
            result = result.filter(
              (item) => item.cid_codigo !== this.excludeCid,
            );
          }
        }
      }
      return result;
    },
    formatItem(item) {
      return {
        value: item.cid_codigo,
        text: `${item.cid_codigo} - ${item.cid_nome}`,
      };
    },
  },
};
</script>
