import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchPatientEsus(
    $pes_nome: String
    $pes_mae: String
    $cpf: String
    $cns: String
    $data_nascimento_inicial: String
    $data_nascimento_final: String
    $limit: Int
    $offset: Int
    $orderDirection: String
    $orderField: String
  ) {
    people(
      pes_nome: $pes_nome
      pes_mae: $pes_mae
      cpf: $cpf
      cns: $cns
      data_nascimento_inicial: $data_nascimento_inicial
      data_nascimento_final: $data_nascimento_final
      limit: $limit
      offset: $offset
      orderDirection: $orderDirection
      orderField: $orderField
    ) {
      rows {
        pes_mae
        pes_pai
        pes_nome
        pes_nascimento
        pes_id_sexos
        pes_email
        pes_id_etnias
        pes_id_municipios
        pes_nome_social
        pes_id_nacionalidades
        city {
          state {
            est_id
          }
        }
        pisPasep {
          pis_numero
        }
        cns {
          crs_numero
        }
        cpf {
          cpf_numero
        }
      }
      count
    }
  }
`;

export default async (pContext, pFilter) => {
  try {
    const variables = { ...pFilter };
    const { data } = await GraphQLClient.query({
      query,
      fetchPolicy: "no-cache",
      variables,
    });
    return data.people;
  } catch (err) {
    console.log(err);
    return err.graphQLErrors;
  }
};
