<style src="./RgValidationAlert.scss" lang="scss" scoped></style>
<template lang="html">
  <div class="rg-input--validate-container">
    <div v-if="alert.length > 0" :title="alert">
      <slot name="validate">
        <IconExclamationInverse class="icon" />
      </slot>
    </div>
  </div>
</template>

<script>
import { IconExclamationInverse } from "~tokio/primitive/icon";

export default {
  name: "RgValidationAlert",
  components: {
    IconExclamationInverse,
  },
  props: {
    alert: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
};
</script>
