import { toast } from "~common/component/tokio/primitive/notification";

/**
 * Seta no array acumulador a mensagem de erro e mostra na tela um toast com a mesma.
 *
 * @param {string} pText Texto a ser alertado
 * @param {string[]} pErrors Array acumulador de erros
 * @param {string} pErrorType Tipo de toast
 * @param {boolean} pPreventAutoHide Previne se vai esconder toast automaticamente
 * @param {string} pTitle Título do toast
 * @param {{timeOut = 10000, extendedTimeOut = 5000}} pOptions Opções extras para apresentação do Toast
 * @returns {boolean}
 */
const AlertError = (
  pText,
  pErrors = [],
  pErrorType = "warning",
  pPreventAutoHide = false,
  pTitle = "",
  pOptions = { timeOut: 10000, extendedTimeOut: 5000 },
) => {
  pErrors.push(pText);

  if (pPreventAutoHide) {
    pOptions.timeOut = 0;
    pOptions.extendedTimeOut = 0;
  }

  toast[pErrorType](pText, pTitle, pOptions);

  return false;
};

export default AlertError;
