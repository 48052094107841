<style src="./RgComboboxDocumentType.scss" lang="scss"></style>
<script>
import { RgRemoteComboboxMixin } from "~tokio/primitive";
import SearchDocumentTypes from "./action/SearchDocumentTypes";

export default {
  name: "RgComboboxDocumentType",
  mixins: [RgRemoteComboboxMixin],
  props: {
    label: {
      default: "Tipo de Documento",
    },
  },
  data: () => {
    return {};
  },
  methods: {
    doSearch() {
      return SearchDocumentTypes({});
    },
    formatItem(pData) {
      return {
        value: pData.doc_id,
        text: pData.doc_nome,
      };
    },
  },
};
</script>
