<style src="./Home.scss" lang="scss" scoped></style>
<template>
  <section class="home">
    <h2 class="title welcome">Meu Painel de Acesso</h2>

    <div class="area">
      <section class="modules-area box">
        <div class="section">
          <RecentOperationContainer />
        </div>
      </section>
    </div>

    <div class="area-favorite">
      <section class="modules-area box">
        <div class="section">
          <FavoriteOperationContainer />
        </div>
      </section>
    </div>

    <section class="changelog box"><ChangelogContainer /></section>

    <div class="configuration box">
      <ConfigurationContainer title="Acesso Rápido" />
    </div>
  </section>
</template>

<script>
import {
  FavoriteOperationContainer,
  RecentOperationContainer,
  ConfigurationContainer,
  ChangelogContainer,
} from "$home/components";

export default {
  name: "Welcome",
  components: {
    FavoriteOperationContainer,
    RecentOperationContainer,
    ConfigurationContainer,
    ChangelogContainer,
  },
};
</script>
