import actions from "./actions";
import Schedule from "$appointment/submodules/schedule/store";
import Register from "$appointment/submodules/register/store";
import Report from "$appointment/submodules/report/store";
import mutations from "./mutations";

export default {
  namespaced: true,
  actions,
  mutations,
  modules: {
    Schedule,
    Register,
    Report,
  },
};
