<template lang="html">
  <div v-show="show" class="modal-types-print">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <div class="title">Emissão de Comprovante</div>
      </div>

      <div slot="body" class="body">
        <FormBase class="form-base" title="Emitir Comprovante?">
          <div class="grid informations">
            <div class="line">
              <button
                class="btn-generate"
                title="Clique para imprimir na Impressora Térmica 1"
                @click="printThermalOneWay"
              >
                <span class="span">Impressora Térmica 1 via</span>
              </button>
            </div>

            <div class="line">
              <button
                class="btn-generate"
                title="Clique para imprimir na Impressora Térmica 2"
                @click="printThermalTwoWay"
              >
                <span class="span">Impressora Térmica 2 vias</span>
              </button>
            </div>

            <div class="line">
              <button
                class="btn-generate"
                title="Impressora Normal"
                @click="printScheduleVoucher"
              >
                <span class="span">Impressora Normal</span>
              </button>
            </div>
          </div>
        </FormBase>
      </div>

      <div slot="footer" class="footer">
        <div class="footer-buttons">
          <RgCancelButton class="cancel" medium @click="close" />
        </div>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import ScheduleVoucherHTML from "$hospitalization/hospitalizations/queue/html/ScheduleVoucherHTML";
import ScheduleThermalOneWayHTML from "$hospitalization/hospitalizations/queue/html/ScheduleThermalOneWayHTML";
import ScheduleThermalTwoWayHTML from "$hospitalization/hospitalizations/queue/html/ScheduleThermalTwoWayHTML";
import { RgBaseModal, RgCancelButton } from "~tokio/primitive";
import FormBase from "~tokio/foundation/form-base/FormBase";

export default {
  name: "ModalTypesPrint",
  components: {
    RgBaseModal,
    FormBase,
    RgCancelButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    hopitalizationId: {
      default: 0,
    },
  },
  data() {
    return {};
  },
  watch: {
    show(pValue) {
      if (!pValue) {
        this.close();
      }
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async printThermalOneWay() {
      const data = await this.$store.dispatch(
        "Hospitalization/Queue/GET_DATA_SCHEDULE_VOUCHER",
        { intIdConsulta: this.hospitalizationId },
      );
      const html = ScheduleThermalOneWayHTML(data);

      this.$utils.print.printHtml(html);
      this.close();
    },
    async printThermalTwoWay() {
      const data = await this.$store.dispatch(
        "Hospitalization/Queue/GET_DATA_SCHEDULE_VOUCHER",
        { intIdConsulta: this.hospitalizationId },
      );
      const html = ScheduleThermalTwoWayHTML(data);

      this.$utils.print.printHtml(html);
      this.close();
    },
    async printScheduleVoucher() {
      const data = await this.$store.dispatch(
        "Hospitalization/Queue/GET_DATA_SCHEDULE_VOUCHER",
        { intIdConsulta: this.hospitalizationId },
      );
      const html = ScheduleVoucherHTML(data);

      this.$utils.print.printHtml(html);
      this.close();
    },
  },
};
</script>
