<template>
  <ModuleBox :title="title" :granted="permission" class="registration-bulletin">
    <QuickRegistrationBulletin
      ref="form"
      class="emergency-registration-bulletin"
      :edit="edit"
      @actionSaveForm="saveForm"
      @disableSave="disableSave"
    />

    <div class="footer">
      <RgCleanButton
        v-show="!edit"
        small
        type="button"
        title="Limpar"
        class="buttons"
        @click="openModalClearForm"
      />

      <RgCancelButton
        medium
        class="buttons"
        type="button"
        title="Cancelar"
        @click="goBack"
      />

      <RgSaveButton
        ref="save"
        medium
        class="buttons"
        type="button"
        :disabled="disableButtonSave"
        @click="verifyPatientNoWayOutBulletin"
      />
    </div>

    <ModalConfirmFieldClearing
      :show="modalConfirmFieldClearing"
      @getYes="cleanForm"
      @getOut="closeModalConfirmFieldClearing"
    />
  </ModuleBox>
</template>

<script>
import ModuleBox from "~tokio/foundation/modulebox/Modulebox";
import QuickRegistrationBulletin from "$emergency/common/component/quick-registration-bulletin/QuickRegistrationBulletin";
import {
  ModalConfirmFieldClearing,
  RgCleanButton,
  RgSaveButton,
  RgCancelButton,
} from "~tokio/primitive";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";

export default {
  name: "EmergencyBulletinRegistration",
  components: {
    ModuleBox,
    QuickRegistrationBulletin,
    ModalConfirmFieldClearing,
    RgCleanButton,
    RgSaveButton,
    RgCancelButton,
  },

  data() {
    return {
      modalConfirmFieldClearing: false,
      preRegistrationData: null,
      showModalPatientNoWayOutBulletin: false,
      noWayOutBulletins: [],
      disableButtonSave: false,
      disableButtonSaveByPatient: false,
    };
  },

  computed: {
    title() {
      const isEditionRoute =
        this.$route.name === "emergency.bulletin.edit-registration";

      return isEditionRoute ? "Editar Boletim" : "Cadastrar Boletim";
    },

    edit() {
      return this.$route.name === "emergency.bulletin.edit-registration";
    },

    permission() {
      return true;
    },

    isPreRegistrationRoute() {
      const valid = ValidateIfRouteExistInBreadscrumb(
        "/emergency/pre-registration",
        this.$route.meta.breadcrumb,
      );
      return valid;
    },
  },

  async mounted() {
    if (this.isPreRegistrationRoute) {
      this.preRegistrationData = this.$store.getters[
        "Emergency/PreRegistration/GET_SELECT_ROW_PRE_REGISTER"
      ];
    }
  },

  methods: {
    async saveForm() {
      try {
        this.$refs.save.actionSubmit();

        const response = await this.$refs.form.saveForm();

        this.$refs.save.actionDone();

        if (!response) return false;

        this.goBack();
      } catch (err) {
        this.$refs.save.fail();
      }
    },

    disableSave(pStatus) {
      this.disableButtonSave = pStatus;
    },

    cleanForm() {
      this.$refs.form.cleanForm();
      this.modalConfirmFieldClearing = false;
    },

    openModalClearForm() {
      this.modalConfirmFieldClearing = true;
    },

    closeModalConfirmFieldClearing() {
      this.modalConfirmFieldClearing = false;
    },

    goBack() {
      if (this.isPreRegistrationRoute) {
        const routeName = "emergency.pre-registration";
        this.$router.push({ name: routeName });
        return;
      }

      this.$router.push("/emergency/bulletin");
    },

    async verifyPatientNoWayOutBulletin() {
      try {
        await this.$refs.form.verifyPatientNoWayOutBulletin();
        this.$refs.save.actionDone();
      } catch (err) {
        this.$refs.save.fail();
        console.error("err:", err);
      }
    },
  },
};
</script>
