import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  return AuthLegacyRequest.post(
    "consultas/controller-marcacao-consulta/transferir-consultas",
    variables,
  )
    .then(({ data }) => {
      if (data.dados === "ConsultaEfetivadaException") {
        return {
          status: false,
          msg: data.trace,
        };
      }
      return data.dados;
    })
    .catch((pErr) => {
      console.log("error", pErr);
      throw pErr;
    });
};
