const VACANCIES = {
  UMA: "1",
  DUAS: "2",
  TRES: "3",
  QUATRO: "4",
  CINCO: "5",
  SEIS: "6",
  SETE: "7",
  OITO: "8",
  NOVE: "9",
  DEZ: "10",
};

export default VACANCIES;
