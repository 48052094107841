<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchJobStatus from "./action/SearchJobStatus";

export default {
  name: "RgComboboxJobtStatus",
  mixins: [RgComboboxMixin],
  data: () => {
    return {};
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];
      const data = await SearchJobStatus();
      data.forEach((item) => {
        this.mData.push({ value: item.sit_id, text: item.sit_nome });
      });
    },
  },
};
</script>
