import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query($nacNome: String, $limit: Int) {
    nationalities(nac_nome: $nacNome, limit: $limit) {
      count
      rows {
        nac_id
        nac_nome
      }
    }
  }
`;

export default async (variables) => {
  const { data } = await GraphQLClient.query({ query, variables }).catch(
    (err) => {
      console.error(err);
    },
  );
  return data.nationalities;
};
