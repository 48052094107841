import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async (sectorId, procedureName) => {
  return AuthLegacyRequest.post(
    "/exames2/controller-exame-procedimento/combo-box-profissionais-por-setor-procedimento",
    { intIdSetor: sectorId, strNomeProcedimento: procedureName },
  )
    .then(({ data }) => {
      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
