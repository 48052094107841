<style src="./ConsolidatedList.scss" lang="scss" scoped></style>
<template>
  <section class="consolidated-list row">
    <ul class="list-container">
      <li class="list-item">
        <span class="col-4 title">Ocupação</span>
        <span class="col-4 title">Procedimento</span>
        <span class="col-1 title title-age">Idade</span>
        <span class="col-1 title">Quantidade</span>
        <span class="col-2" />
      </li>
      <li
        v-for="(item, itemIdx) in consolidatedBillingList"
        :key="itemIdx"
        class="list-item"
      >
        <div class="container-occupation col-4">
          <span class="code" @click="edit({ fco_cbo: item.fco_cbo })">{{
            item.fco_cbo
          }}</span>
          <span class="occupation-name">
            {{ item.occupation.NO_OCUPACAO }}
          </span>
        </div>

        <div class="container-procedure col-4">
          <span
            class="code"
            @click="
              edit({ fco_codigo_procedimento: item.fco_codigo_procedimento })
            "
            >{{ item.fco_codigo_procedimento }}</span
          >
          <span class="procedure-name">
            {{ item.procedure.NO_PROCEDIMENTO }}
          </span>
        </div>

        <div class="container-age col-1">
          <span v-show="item.fco_idade" class="age-value">{{
            item.fco_idade
          }}</span>
          <span v-show="item.fco_idade" class="age-caption">anos</span>
        </div>

        <div class="container-quantity col-1">
          <span class="quantity-value">{{ item.fco_quantidade }}</span>
        </div>

        <div class="commands col-2">
          <div class="edit-small">
            <button
              type="button"
              class="edit-small--trytoedit"
              tabindex="-1"
              @click.stop.prevent="editConsolidatedBilling(item.fco_id)"
            >
              <IconPencil />
            </button>
          </div>

          <RgRemoveSmall
            class="btn-margin-bottom"
            @confirm-remove="removeConsolidatedBilling(item.fco_id)"
          />
        </div>
      </li>
    </ul>
  </section>
</template>

<script>
import { RgRemoveSmall } from "~tokio/primitive/button";
import { IconPencil } from "~tokio/primitive/icon/symbols";
import { mapGetters } from "vuex";

export default {
  name: "ConsolidatedList",
  components: {
    RgRemoveSmall,
    IconPencil,
  },
  computed: {
    ...mapGetters({
      consolidatedBillingList:
        "Billing/ConsolidatedBilling/LIST_CONSOLIDATED_BILLING",
    }),
  },
  mounted() {},
  methods: {
    editConsolidatedBilling(pId) {
      const item = this.getConsolidatedBillingItem(pId);
      if (item && item.length > 0) this.$emit("edit-data", item[0]);
    },
    getConsolidatedBillingItem(pId) {
      return this.$store.getters[
        "Billing/ConsolidatedBilling/GET_CONSOLIDATED_BILLING_ITEM"
      ]({ fco_id: pId });
    },
    removeConsolidatedBilling(pId) {
      this.$store.dispatch(
        "Billing/ConsolidatedBilling/REMOVE_CONSOLIDATED_BILLING",
        { variables: { consolidatedBilling: pId } },
      );
    },
    edit(pData) {
      this.$emit("edit-data", pData);
    },
  },
};
</script>
