import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  return AuthLegacyRequest.post(
    "consultas/controller-marcacao-consulta/total-de-consultas-do-dia",
    variables,
  )
    .then(({ data }) => {
      if (data.dados === "0") {
        return [];
      }
      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
