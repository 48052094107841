import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query GetCertificate {
    GetCertificate {
      certificate
    }
  }
`;

const QZCertificateRequest = (variables) => {
  return GraphQLClient.query({ query, variables, fetchPolicy: "no-cache" });
};

export { QZCertificateRequest };
export default QZCertificateRequest;
