import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query($idUnidadeSaude: Int, $idUsuario: Int) {
    SearchPeriodHospitalizationBilling(
      cft_id_unidades_saudes: $idUnidadeSaude
      cft_id_usuarios: $idUsuario
    ) {
      rows {
        cft_competencia
      }
    }
  }
`;

export default async (
  { commit },
  { cft_id_unidades_saudes, cft_id_usuarios },
) => {
  try {
    const variables = {
      idUnidadeSaude: cft_id_unidades_saudes,
      idUsuario: cft_id_usuarios,
    };
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.SearchPeriodHospitalizationBilling.rows[0];
  } catch (err) {
    return err.graphQLErrors;
  }
};
