<template lang="html">
  <section class="brand">
    <img :src="logoImg" class="logo" alt="Logo" @click="redirectToHome" />
  </section>
</template>

<script>
export default {
  name: "Brand",
  data() {
    return {
      logoImg: "/static/images/logo-esus+.png",
    };
  },
  methods: {
    redirectToHome() {
      if (this.$route.fullPath !== "/") {
        this.$router.push("/");
      }
    },
  },
};
</script>
