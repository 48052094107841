<style src="./RgSurgeryEquipmentTypeForm.scss" lang="scss" scoped></style>
<template lang="html">
  <div class="surgery-Equipment-type-form">
    <RgForm @submit="submit">
      <div class="row">
        <RgInputDebounce
          v-model="form.tie_nome"
          :rules="{ required: true }"
          class="col-12"
          label="Equipamento Cirúrgico:"
          placeholder="Digite o equipamento"
          @debounced="doDebounce"
        />
      </div>
    </RgForm>
  </div>
</template>

<script>
import { RgInputDebounce, toast } from "~tokio/primitive";
import { RgForm } from "~tokio/foundation/form";
import SurgeryEquipmentModel from "$surgery-center/surgery/model/SurgeryEquipmentModel";
export default {
  name: "RgSurgeryEquipmentTypeForm",
  components: { RgForm, RgInputDebounce },
  data: () => {
    return {
      form: {
        tie_nome: "",
        tie_ativo: "",
        tie_id: "",
      },
    };
  },
  methods: {
    doDebounce() {
      this.$emit("search-for", this.form.tie_nome);
    },
    submit(pErr, pDone) {
      if (pErr) {
        return;
      }
      SurgeryEquipmentModel.create({ tie_nome: this.form.tie_nome })
        .then((pRes) => {
          pDone();
          this.$emit("saved", pRes);
        })
        .catch((pErr) => {
          pDone();
          toast.error(pErr.response.errors[0].msg, "Erro ao Realizar Operação");
        });
    },
    cleanForm() {
      this.form = {
        tie_nome: "",
        tie_ativo: "",
        tie_id: "",
      };
    },
  },
};
</script>
