<style src="./EmployeeRelationshipSector.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="employee-relationship-sector">
    <RgForm
      ref="formDetails"
      class="relationship-sector-form"
      action-label="Salvar"
    >
      <div class="row">
        <RgComboboxUnithealth v-model="uns_id" :defaul-value="true" />

        <RgComboboxSector
          v-model="form.vus_id_setores"
          :unit-health="uns_id"
          class="col-8"
          label="Setores"
        />

        <RgRadioYesNo
          v-model="form.vus_responsavel"
          :default-value="false"
          :yes-value="true"
          :no-value="false"
          label="Responsável"
          class="rg-radio col-4 combobox-row"
        />

        <RgInputNumber
          v-model="form.vus_carga_horaria_setores"
          class="col-6"
          label="Carga horária"
        />

        <RgInputNumber
          v-model="remainingWorkload"
          :readonly="true"
          class="col-6 readonly"
          label="Horas restantes no setor"
        />
      </div>
      <div slot="command" class="commands">
        <button
          v-if="!editMode"
          type="button"
          class="btn-small btn-success"
          @click="createEmployeeRelationshipSector"
        >
          Salvar Novo
        </button>
        <button
          v-if="editMode"
          type="button"
          class="btn-small btn-warning btn-space"
          @click="cancelEditRelationshipSector"
        >
          Cancelar Edição
        </button>
        <button
          v-if="editMode"
          type="button"
          class="btn-small btn-primary btn-space"
          @click="updateEmployeeRelationshipSector"
        >
          Atualizar
        </button>
      </div>
    </RgForm>

    <span class="form-title">Lista de Vínculos Setores</span>
    <ul class="relationship-sector-list-container">
      <li
        v-for="(listItem, listIdx) in relationshipSectorList"
        :key="listIdx"
        :class="{ 'list-actived': currentItemActived === listIdx }"
        class="relationship-sector-list-item"
        @click="editRelationshipSector(listItem, listIdx)"
      >
        <div
          :class="{
            'employee-status__active': listItem.vus_ativo,
            'employee-status__inactive': !listItem.vus_ativo,
          }"
          class="employee-status"
        />
        <div class="place">
          <span class="unit-health">{{
            listItem.sector.unit_health.uns_nome
          }}</span
          ><br />
          <span class="sector">{{ listItem.sector.set_nome }}</span>
        </div>
        <div class="info">
          <span class="locate">
            {{
              listItem.sector.unit_health.address.neighborhood.city.mun_nome
            }}/
            {{
              listItem.sector.unit_health.address.neighborhood.city.state
                .est_sigla
            }}
          </span>
          <br />
          <span class="workload"
            >{{ listItem.vus_carga_horaria_setores }} horas</span
          >
        </div>
        <div class="commands">
          <PopupRelationshipSectorOptions
            :active-status="listItem.vus_ativo"
            @remove-relationship-sector="
              removeEmployeeRelationshipSector(listItem)
            "
            @activate-relationship-sector="activateRelationshipSector(listItem)"
            @deactivate-relationship-sector="
              deactivateRelationshipSector(listItem)
            "
          />
        </div>
      </li>
    </ul>
  </section>
</template>

<script>
import { RgInputNumber } from "~tokio/primitive";
import { RgForm } from "~tokio/foundation/form";
import {
  RgComboboxUnithealth,
  RgComboboxSector,
} from "~tokio/primitive/combobox/";
import RgRadioYesNo from "$surgery-center/foundation/component/rg-radio-yes-no/RgRadioYesNo";
import PopupRelationshipSectorOptions from "$employee/common/components/popup/relationship-sector-options/RelationshipSectorOptions";

export default {
  name: "EmployeeRelationshipSector",
  components: {
    RgInputNumber,
    RgComboboxSector,
    RgComboboxUnithealth,
    RgRadioYesNo,
    RgForm,
    PopupRelationshipSectorOptions,
  },
  data() {
    return {
      editMode: false,
      currentItemActived: null,
      selectedRelationshipSectorId: null,
      remainingWorkload: null,
      currentTimeBefore: 0,
      uns_id: null,
      form: {
        vus_id_setores: null,
        vus_carga_horaria_setores: null,
        vus_responsavel: false,
        vus_ativo: true,
      },
      relationshipSectorList: [],
    };
  },
  computed: {
    relationship() {
      return this.$store.getters["Employee/GET_RELATIONSHIP"];
    },
  },
  watch: {
    relationship: function () {
      this.fillList();
    },
    "form.vus_carga_horaria_setores": function () {
      this.calculateRemainingWorkload();
    },
  },
  methods: {
    async createEmployeeRelationshipSector() {
      const variables = {
        employeeRelationshipSector: {
          vus_id_vinculos: this.relationship.vin_id,
          ...this.form,
        },
      };
      try {
        await this.$store.dispatch(
          "Employee/CREATE_EMPLOYEE_RELATIONSHIP_SECTOR",
          variables,
        );
        this.$toaster.success("Vínculo Setor salvo com sucesso!");
        this.$emit("relationship-sector-created");
        this.fillList();
        this.cleanFields();
      } catch (e) {
        this.$toaster.error("O vínculo setor não pode ser salvo.");
      }
    },
    async updateEmployeeRelationshipSector() {
      const variables = {
        employeeRelationshipSectorId: this.selectedRelationshipSectorId,
        employeeRelationshipSector: {
          ...this.form,
        },
      };
      try {
        await this.$store.dispatch(
          "Employee/UPDATE_EMPLOYEE_RELATIONSHIP_SECTOR_BY_ID",
          variables,
        );
        this.$toaster.success("Vínculo atualizado com sucesso!");
        this.$emit("employee-relationship-sector-updated");
        this.cancelEditRelationshipSector();
        this.fillList();
        this.cleanFields();
      } catch (e) {
        this.$toaster.error("O vínculo não pode ser atualizado");
      }
    },
    async GetListEmployeeRelationshipSectors() {
      if (this.relationship.vin_id !== null) {
        const variables = { relationshipId: this.relationship.vin_id };
        return this.$store.dispatch(
          "Employee/GET_EMPLOYEEE_RELATIONSHIP_SECTOR_LIST",
          variables,
        );
      }
    },
    async removeEmployeeRelationshipSector({ vus_id }) {
      try {
        await this.$store.dispatch(
          "Employee/REMOVE_EMPLOYEE_RELATIONSHIP_SECTOR",
          { employeeRelationshipSectorId: vus_id },
        );
        this.fillList();
        this.$toaster.success("Vínculo removido com sucesso!");
      } catch (e) {
        this.$toaster.error(
          e.message,
          "Não foi possível excluir vínculo setor.",
        );
      }
    },
    async activateRelationshipSector({ vus_id }) {
      const variables = {
        employeeRelationshipSectorId: vus_id,
        employeeRelationshipSector: {
          vus_ativo: true,
        },
      };
      try {
        await this.$store.dispatch(
          "Employee/UPDATE_EMPLOYEE_RELATIONSHIP_SECTOR_BY_ID",
          variables,
        );
        this.fillList();
        this.$toaster.success("Vínculo Setor ativado com sucesso!");
        this.$emit("employee-relationship-sector-updated");
        this.cancelEditRelationshipSector();
      } catch (e) {
        this.$toaster.error("O vínculo setor não pode ser ativado");
      }
    },
    async deactivateRelationshipSector({ vus_id }) {
      const variables = {
        employeeRelationshipSectorId: vus_id,
        employeeRelationshipSector: {
          vus_ativo: false,
        },
      };
      try {
        await this.$store.dispatch(
          "Employee/UPDATE_EMPLOYEE_RELATIONSHIP_SECTOR_BY_ID",
          variables,
        );
        this.$toaster.success("Vínculo setor desativado com sucesso!");
        this.fillList();
        this.$emit("employee-relationship-sector-updated");
        this.cancelEditRelationshipSector();
      } catch (e) {
        this.$toaster.error("O vínculo setor não pode ser desativado");
      }
    },
    async fillList() {
      this.currentItemActived = null;
      this.cleanFields();
      this.relationshipSectorList = await this.GetListEmployeeRelationshipSectors();
      this.calculateRemainingWorkload();
    },
    cleanFields() {
      this.currentTimeBefore = 0;
      this.remainingWorkload = null;
      this.uns_id = null;
      this.form = {
        vus_id_setores: null,
        vus_carga_horaria_setores: null,
        vus_responsavel: false,
        vus_ativo: true,
      };
    },
    cancelEditRelationshipSector() {
      this.currentTimeBefore = 0;
      this.cleanFields();
      this.currentItemActived = null;
      this.editMode = false;
      this.selectedRelationshipSectorId = null;
      this.$store.commit("Employee/RESET_RELATIONSHIP_SECTOR");
    },
    editRelationshipSector(pItem, listIdx) {
      this.currentTimeBefore = pItem.vus_carga_horaria_setores;
      this.currentItemActived = listIdx;
      this.editMode = true;
      this.selectedRelationshipSectorId = pItem.vus_id;
      this.uns_id = pItem.sector.unit_health.uns_id;
      this.form.vus_id_setores = pItem.vus_id_setores;
      this.form.vus_carga_horaria_setores = pItem.vus_carga_horaria_setores;
      this.form.vus_responsavel = pItem.vus_responsavel;
      this.form.vus_ativo = pItem.vus_ativo;
      this.$store.commit("Employee/SET_RELATIONSHIP_SECTOR", {
        ...this.form,
        vus_id: pItem.vus_id,
      });
    },
    calculateRemainingWorkload() {
      const time = this.relationship.vin_carga_horaria;
      const currentTime = Number(this.form.vus_carga_horaria_setores);

      if (
        this.relationshipSectorList &&
        this.relationshipSectorList.length > 0 &&
        time
      ) {
        const timeSectors = this.relationshipSectorList
          .map((item) => item.vus_carga_horaria_setores)
          .reduce((item, total) => total + item);
        this.remainingWorkload =
          time - (timeSectors + currentTime) + this.currentTimeBefore;
      }
    },
  },
};
</script>
