<template>
  <RgSelect
    v-model="value"
    :options="mutableOptions"
    :multiple="multiple"
    :label-form="labelForm"
    :close-on-select="closeOnSelect"
    :value="value"
  />
</template>

<script>
import RgSelect from "./RgSelect";

export default {
  name: "RgRemoteSelect",
  components: {
    RgSelect,
  },
  props: {
    /**
     * Tell a label to RgFormBase
     * @type {String}
     */
    labelForm: {
      type: String,
      default: "Selecione:",
    },
    /**
     * Equivalent to the `multiple` attribute on a `<select>` input.
     * @type {Boolean}
     */
    multiple: {
      type: Boolean,
      default: false,
    },
    /**
     * Close a dropdown when an option is chosen. Set to false to keep the dropdown
     * open (useful when combined with multi-select, for example)
     * @type {Boolean}
     */
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
    canBeSelected: {
      type: Function,
      default: (item) => {
        return true;
      },
    },
    getDataOnMount: {
      type: Boolean,
      default: true,
    },
    value: {
      default: null,
    },
  },
  data() {
    return {
      /** @type {Array<{value: number, label: string, canBeSelected: boolean}>} */
      options: [],
      /** @type {Array<{value: number, label: string, canBeSelected: boolean}>} */
      mutableOptions: [],
    };
  },
  watch: {
    value: function (val) {
      this.$emit("input", val);
    },
  },
  mounted() {
    if (this.getDataOnMount) {
      this.getData();
    }
  },
  methods: {
    doSearch() {
      throw new Error("You need to override doSearch");
    },
    formatItem(item) {
      return item;
    },
    getData() {
      return this.getDataFromRemote();
    },
    async getDataFromRemote() {
      try {
        const response = await this.doSearch();
        this.fillData(response);
      } catch (error) {
        console.log(error);
      }
    },
    fillData(data) {
      this.options = data.map((item) => {
        return this.formatItem(item);
      });

      if (this.mutableOptions.length === 0) {
        this.mutableOptions = this.options;
      } else {
        this.mutableOptions = this.mutableOptions.map((item) => {
          return this.formatItem(item);
        });
      }
    },
  },
};
</script>
