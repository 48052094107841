<style src="./NoticeBoard.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="notice-board">
    <div class="notice-board--context">
      <div class="notice-board--notice-icon" @click="toggleNoticeOpen">
        <IconBells />
      </div>
    </div>
  </section>
</template>

<script>
import { IconBells } from "~tokio/primitive/";

export default {
  name: "NoticeBoard",
  components: {
    IconBells,
  },
  data() {
    return {
      noticeOpen: false,
    };
  },
  methods: {
    toggleNoticeOpen() {
      this.noticeOpen = !this.noticeOpen;
    },
  },
};
</script>
