import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  const sendData = {
    arrFormData: variables,
  };

  return AuthLegacyRequest.post("tabelas/controller-tuss/buscar", sendData)
    .then(({ data }) => {
      data = HandleResponse(data);

      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
