<style src="./RgComboboxPlace.scss" lang="scss"></style>
<script>
import { isNumber } from "lodash";
import { RgRemoteComboboxMixin } from "~tokio/primitive";
import ListPlaces from "./action/ListPlaces";

export default {
  name: "RgComboboxPlace",
  mixins: [RgRemoteComboboxMixin],
  props: {
    label: {
      default: "Local de Atendimento",
    },
    sector: {
      default: false,
      required: true,
    },
    isNecessary: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {};
  },
  computed: {
    filter() {
      if (!isNumber(this.sector)) {
        this.resetCombobox();
        return null;
      } else {
        return {
          set_id: this.sector,
        };
      }
    },
  },
  methods: {
    doSearch() {
      const sectorId = this.sector;
      return ListPlaces({ sectorId });
    },
    formatItem(pData) {
      return {
        value: pData.lca_id,
        text: pData.lca_nome,
      };
    },
    blur() {
      if (this.isNecessary) {
        this.validate();
        this.$emit("blur");
      }
    },
  },
};
</script>
