<template lang="html">
  <svg
    width="33.651mm"
    height="30.91mm"
    version="1.1"
    viewBox="0 0 33.651 30.91"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(-81.939 -124.5)">
      <g transform="matrix(.065814 0 0 .065814 82.218 123.7)">
        <g>
          <path
            d="m482 196.35v44.651h-42.656v-13.47h-188.83v13.47h-96.825c-6.349-30.218-31.43-52.855-61.338-52.855h-62.351v-99.015h-30.001v333.74h30.001v-44.651h220.51v44.639h188.83v-44.639h42.656v44.651h30.001v-181.87h2e-3v-44.651h-30.001zm-452 21.797h62.352c13.514 0 25.151 9.421 30.21 22.854h-92.562zm220.51 130.07h-220.51v-77.218h220.51v77.218zm30.001 44.639v-135.33h128.82v135.33h-128.82zm201.48-44.639h-42.656v-77.218h42.656z"
          />
        </g>
        <g>
          <polygon
            points="329.93 310.19 298.85 310.19 298.85 340.2 329.93 340.2 329.93 371.27 359.93 371.27 359.93 340.2 391.01 340.2 391.01 310.19 359.93 310.19 359.93 279.12 329.93 279.12"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "HospitalizationTwo",
};
</script>
