<template lang="html">
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 20.691383 41.084339"
    style="enable-background: new 0 0 20.691383 41.084339"
    xml:space="preserve"
  >
    <g transform="translate(-469.65431,-360.72322)">
      <path
        d="m 480.50024,401.80756 0,-41.08434"
        style="
          display: inline;
          fill: none;
          fill-rule: evenodd;
          stroke: #9aa4b7;
          stroke-width: 1.20000005;
          stroke-linecap: butt;
          stroke-linejoin: miter;
          stroke-miterlimit: 4;
          stroke-dasharray: 4.8, 1.2;
          stroke-dashoffset: 0;
          stroke-opacity: 0.85087723;
        "
      />

      <circle
        r="8.3456917"
        cy="380.96155"
        cx="480"
        style="
          display: inline;
          opacity: 1;
          fill: #ffffff;
          fill-opacity: 1;
          fill-rule: nonzero;
          stroke-width: 4;
          stroke-linecap: butt;
          stroke-linejoin: miter;
          stroke-miterlimit: 4;
          stroke-dasharray: none;
          stroke-dashoffset: 0;
          stroke-opacity: 1;
        "
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Status",
};
</script>
