<style src="./PrintSurgery.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="surgery-print page-break">
    <div class="print-head">
      <SurgeryPrintHeader
        :unit-health="incomingData.unitHealth"
        :username="username"
        :print-date="printDate"
      />
      <section>
        <PatientHeader :patient-data="surgeryInfoData" />
        <PatientInfo :patient-data="surgeryInfoData" />
        <SurgeryInfo :surgery-data="surgeryInfoData" />
        <SurgeryDate :surgery-data="surgeryInfoData" />
        <SurgeryProcedures :surgery-data="surgeryInfoData" />
        <SurgeryObs :surgery-obs="surgeryInfoData" />
      </section>
    </div>
  </section>
</template>

<script>
import SurgeryPrintHeader from "./surgery-print-header/SurgeryPrintHeader";
import PatientHeader from "./patient-header/PatientHeader";
import PatientInfo from "./patient-info/PatientInfo";
import SurgeryInfo from "./surgery-info/SurgeryInfo";
import SurgeryDate from "./surgery-date/SurgeryDate";
import SurgeryProcedures from "./surgery-procedures/SurgeryProcedures";
import SurgeryObs from "./surgery-obs/SurgeryObs";
import moment from "moment";
moment.locale("pt-br");

export default {
  name: "PrintSurgery",
  components: {
    SurgeryPrintHeader,
    PatientHeader,
    PatientInfo,
    SurgeryInfo,
    SurgeryDate,
    SurgeryProcedures,
    SurgeryObs,
  },
  data() {
    return {
      incomingData: {},
      fillData: {},
      patientInfo: {},
    };
  },
  computed: {
    printDate() {
      const formatedData = moment(new Date()).format("DD/MM/YYYY HH:mm:ss");
      return `Data Impressão: ${formatedData}`;
    },
    username() {
      return this.$store.getters["Login/GET_USER_NAME"];
    },
    surgeryInfoData() {
      const allInfo = Object.assign({}, this.incomingData);
      return allInfo;
    },
  },
  mounted() {
    const auxData = this.getParams(window.location.search);
    this.incomingData = JSON.parse(auxData);
    window.print();
  },
  methods: {
    fillPrintForm(pFilters) {
      window.print();
    },
    getParams(pParams) {
      const AUX = decodeURIComponent(pParams);
      const firstSplit = AUX.toString().split(/=|&/gi);
      const paramsSplited = firstSplit[1].split(",");
      return paramsSplited;
    },
    concatPlace(pItem) {
      const retJoined = [];
      const sector = pItem.centro_cirurgico;
      const placeAttendance = pItem.sala;
      const bed = pItem.inl_nome;
      if (sector) retJoined.push(sector);
      if (placeAttendance) retJoined.push(placeAttendance);
      if (bed) retJoined.push(bed);
      return retJoined.join(" / ");
    },
  },
};
</script>
