<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchReligion from "./action/SearchReligion";

export default {
  name: "RgComboboxReligion",
  mixins: [RgComboboxMixin],
  data: () => {
    return {};
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];
      const data = await SearchReligion();
      data.forEach((item) => {
        this.mData.push({ value: item.rel_id, text: item.rel_nome });
      });
    },
  },
};
</script>
