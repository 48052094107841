import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query GetUnitHealthByBedType(
    $itl_id: Int
    $inl_id: Int
    $situation: SituationBedTypeEnum
    $permission: Int
    $uns_id: Int!
  ) {
    unitHealthByBedType(
      itl_id: $itl_id
      inl_id: $inl_id
      situation: $situation
      permission: $permission
      uns_id: $uns_id
    ) {
      rows {
        uns_id
        uns_nome
      }
    }
  }
`;

export default async (variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      fetchPolicy: "no-cache",
      variables,
    });
    return data.unitHealthByBedType.rows;
  } catch (err) {
    return Promise.reject(err.graphQLErrors[0].message);
  }
};
