import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation CreateEmployeeRelationship(
    $employeeRelationship: EmployeeRelationshipInputType
  ) {
    CreateEmployeeRelationship(employeeRelationship: $employeeRelationship) {
      vin_id
    }
  }
`;

export default async ({ rootState }, variables) => {
  try {
    const { data } = await GraphQLClient.mutate({
      mutation,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.CreateEmployeeRelationship;
  } catch (err) {
    return Promise.reject(err.graphQLErrors);
  }
};
