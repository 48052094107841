import { render, staticRenderFns } from "./RgRadioExamType.vue?vue&type=template&id=1983de48&scoped=true&lang=html&"
import script from "./RgRadioExamType.vue?vue&type=script&lang=js&"
export * from "./RgRadioExamType.vue?vue&type=script&lang=js&"
import style0 from "./RgRadioExamType.scss?vue&type=style&index=0&id=1983de48&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1983de48",
  null
  
)

export default component.exports