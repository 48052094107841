<style src="./RgComboboxHospitalizationSector.scss" lang="scss"></style>
<script>
import { RgRemoteComboboxMixin } from "~tokio/primitive";
import GetSectorByBedType from "./action/GetSectorByBedType";

export default {
  name: "RgComboboxHospitalizationSector",
  mixins: [RgRemoteComboboxMixin],
  props: {
    label: {
      type: String,
      default: "Setor",
    },
    unitHealth: {
      default: null,
    },
    bedTypeId: {
      type: Number,
      default: 0,
    },
    inlId: {
      type: Number,
      default: 0,
    },
    fromMapBed: {
      type: Boolean,
      default: false,
    },
    currentSituation: {
      type: String,
      default: "VAZIO",
    },
    permission: {
      type: Number,
      default: null,
    },
  },
  watch: {
    unitHealth: function () {
      this.getDataFromRemote();
    },
    inlId(pValue, pPreviously) {
      const isDiferentValue = pValue !== pPreviously;
      if (isDiferentValue && this.inlId && this.fromMapBed) {
        this.getDataFromRemote();
      }
    },
  },
  methods: {
    doSearch() {
      if (
        !this.unitHealth ||
        this.unitHealth === null ||
        this.unitHealth === 0
      ) {
        return Promise.resolve([]);
      }

      return GetSectorByBedType({
        uns_id: this.unitHealth,
        itl_id: this.bedTypeId,
        inl_id: this.inl_id,
        situation: this.currentSituation,
        permission: this.permission,
      });
    },
    formatItem(item) {
      return {
        value: item.set_id,
        text: `${item.set_nome}`,
      };
    },
    blur() {
      this.validate();
      this.$emit("blur");
    },
  },
};
</script>
