export default {
  PERSON_LIST: (state) => state.personList,
  EXISTS_PES_ID: (state) => !!state.basicData.pes_id,
  GET_PERSON_ID: (state) => state.basicData.pes_id,
  GET_BASIC_DATA: (state) => state.basicData,
  LIST_CONTACTS: (state) => state.phoneData,
  LIST_DOCUMENTS_TYPES: (state) => state.documents.documentsType,
  GET_PERSON_NAME: (state) => state.basicData.pes_nome,
  GET_MY_ESUS_DATA: (state) => state.myEsus,
  IS_NEWBORN: (state) => state.newBorn === true,
  GET_PATIENT_RECORD: (state) => state.patientRecord,
};
