import SurgeryControlModel from "$surgery-center/surgery/model/SurgeryControlModel";
import Store from "@/store";

export default async ({ state, commit }) => {
  const unitHealth = Store.getters["Login/GET_UNIT_HEALTH_ID"];
  const filters = JSON.parse(JSON.stringify(state.filterListBy));
  filters.offset = state.listItems.length;

  const searchResult = await SurgeryControlModel.search({
    ...filters,
    uns_id: unitHealth,
  });
  if (searchResult.data && searchResult.data.length === 0) {
    return false;
  } else {
    commit("PUSH_LIST_ITEMS", searchResult.data);
    return true;
  }
};
