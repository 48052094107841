<template>
  <div class="appointment-schedule-queue">
    <RgSearch
      ref="rgsearch"
      v-model="mutableAppointmentQueue"
      :build-filter="generateFilter"
      :search-function="searchFilter"
      :clear-function="cleanForm"
      :item-height="40.6"
      :max-register="30"
      :show-title="true"
      :result-title="'Regulação'"
      :show-empty-message="true"
      :showFooter="hasAppointmentsQueue"
      @viewClickPagination="viewClickPagination"
      @submitFromButton="actionCleanSelection"
    >
      <div slot="filters" class="appointment-schedule-queue-filter">
        <div class="grid">
          <div class="selectinput">
            <RgSuggestCboSynonyms
              ref="cboSynonyms"
              v-model="cbo"
              :select-first="true"
              :show-default-option="true"
              :disabled="modalPatientImageInRow"
              label="Ocupação"
              class="inputitem"
              noUserPermission
              with-bond
              @selected="selectingCbo"
            />
          </div>

          <div class="selectinput">
            <RgComboboxUnithealthQueue
              v-model="form.unit"
              :unit-health-id="getUnitHealthId"
              :module-id="appointmentModuleId"
              :hasRegularPermission="hasRegularPermission"
              :hasViewRequestUnitHealthPermission="
                hasViewRequestUnitHealthPermission
              "
              label="Unidade de Saúde Solicitante"
              class="inputitem"
              :default-text="'Todas'"
              :disabled="disabledByModal"
            />
          </div>

          <div class="filter-title">
            <span class="title">Dados do Paciente</span>
          </div>

          <div class="selectinput">
            <RgInput
              ref="patient"
              v-model="form.patientName"
              :disabled="disableNameInput"
              :class="{ disable: disableNameInput }"
              class="inputitem"
              placeholder="Digite o nome do paciente"
              label="Paciente"
            />
          </div>

          <div class="selectinput">
            <RgSuggestSmartPerson
              ref="person"
              v-model="form.document"
              :disabled="disableDocumentInput"
              :class="{ disable: disableDocumentInput }"
              :with-patient-module="['appointment']"
              :enabled-patient="false"
              :rules="{ forceSelection: true }"
              filterOnlyDocument
              class="inputitem"
              @selected="selectingPatient"
            />
          </div>

          <div class="selectinput">
            <RgComboboxPriority
              v-model="form.priority"
              :module-id="appointmentModuleId"
              :disabled="disabledByModal"
              label="Prioridade"
              default-text="Todas"
              class="inputitem"
            />
          </div>

          <div class="selectinput">
            <RgComboboxSituation
              v-model="form.situation"
              :default-text="'Todas'"
              :disabled="disabledByModal"
              label="Situação"
              class="inputitem"
            />
          </div>

          <div class="selectinput">
            <RgSuggestState
              ref="state"
              v-model="form.est_sigla"
              label="Estado"
              :disabled="disabledByModal"
              placeholder="Digite o estado"
              class="inputitem state"
              @selected="selectingState"
            />
          </div>

          <div class="selectinput">
            <RgSuggestCity
              v-model="form.mun_nome"
              :class="{ disable: disableCounty }"
              :disabled="disableCounty || disabledByModal"
              :state="form.est_id"
              label="Município"
              placeholder="Digite o município"
              class="inputitem city"
              @selected="selectingMunicipality"
            />
          </div>

          <div class="filter-title">
            <span class="title">Período do Pedido</span>
          </div>

          <div class="selectinput">
            <div class="period">
              <RgInputDate
                v-model="form.initialOrderPeriod"
                :rules="{ fn: verifyOrderInitialDate }"
                label="Data Inicial"
                class="date"
                :disabled="disabledByModal"
              />

              <RgInputDate
                v-model="form.finalOrderPeriod"
                label="Data Final"
                class="date"
                :disabled="disabledByModal"
              />
            </div>
          </div>

          <div class="filter-title">
            <span class="title">Período de Inclusão na Fila</span>
          </div>

          <div class="selectinput">
            <div class="period">
              <RgInputDate
                v-model="form.initialInclusionDate"
                :rules="{ fn: verifyInclusionInitialDate }"
                label="Data Inicial"
                class="date"
                :disabled="disabledByModal"
              />

              <RgInputDate
                v-model="form.finalInclusionDate"
                label="Data Final"
                class="date"
                :disabled="disabledByModal"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="mutableAppointmentQueue.length <= 0"
        slot="menu-top"
        class="top-button"
      >
        <RgNewButton
          large
          title="Inserir na fila"
          class="item"
          :disabled="disabledByModal"
          :permission="hasInsertPermission"
          @click="registrationQueue"
        />
      </div>

      <div class="menu-top">
        <div v-show="hasAppointmentsQueue" class="top-btn">
          <div v-if="!equalListSize" class="select-btn" @click="selectAllList">
            <IconBox />
          </div>

          <div v-if="equalListSize" class="select-btn" @click="unselectAllList">
            <IconCheckedBox />
          </div>

          <div
            class="select-btn"
            title="Inverter seleção"
            @click="reverseListSelection"
          >
            <IconArrowBox />
          </div>

          <p class="text">
            Linhas Selecionadas:
            {{ selectedRows.length }}
          </p>

          <div class="position-btn">
            <input
              v-model="queueAmountAdvance"
              class="field"
              maxlength="3"
              :disabled="!hasOnlyOneLineSelected || disabledByModal"
              :class="{
                disable: !hasOnlyOneLineSelected || disabledByModal,
              }"
              title="Informe o tamanho de intervalo para o passo de subida na fila"
            />
            <SmallButton
              :disabled="!hasOnlyOneLineSelected || disabledByModal"
              :backgroundColor="'#f96b70'"
              :permission="hasRegularPermission"
              @click="advancePatientInQueue"
            >
              <IconArrowSmall slot="icon" class="svg" />
            </SmallButton>

            <input
              v-model="queueAmountBack"
              class="field"
              maxlength="3"
              :disabled="!hasOnlyOneLineSelected || disabledByModal"
              :class="{ disable: !hasOnlyOneLineSelected || disabledByModal }"
              title="Informe o tamanho de intervalo para o passo de descida na fila"
            />
            <SmallButton
              :disabled="!hasOnlyOneLineSelected || disabledByModal"
              :backgroundColor="'#f96b70'"
              :permission="hasRegularPermission"
              @click="backPatientInQueue"
            >
              <IconArrowSmall slot="icon" class="svg arrow-invert" />
            </SmallButton>

            <input
              v-model="queuePosition"
              class="field large"
              maxlength="3"
              :disabled="!hasOnlyOneLineSelected || disabledByModal"
              :class="{ disable: !hasOnlyOneLineSelected || disabledByModal }"
              title="Informe a posição que deseja para mover o registro na fila"
            />
            <SmallButton
              :disabled="!hasOnlyOneLineSelected || disabledByModal"
              :backgroundColor="'#1e88a9'"
              :permission="hasRegularPermission"
              @click="movePatientInQueue"
            >
              <IconArrowDouble slot="icon" class="svg" />
            </SmallButton>
          </div>
        </div>

        <div class="more-buttons">
          <RgNewButton
            small
            title="Inserir na fila"
            class="insert-button"
            :disabled="disabledByModal"
            :permission="hasInsertPermission"
            @click="registrationQueue"
          />

          <RgLessButton
            v-show="hasAppointmentsQueue"
            :disabled="!enableButtonForSelectedLines || disabledByModal"
            :permission="hasDeletePermission"
            title="Excluir Paciente da Fila"
            @click="openModalConfirmQueueDeletion"
          />

          <RgEditButton
            v-show="hasAppointmentsQueue"
            :disabled="!hasOnlyOneLineSelected || disabledByModal"
            :permission="hasEditPermission"
            title="Editar Fila"
            @click="alterQueue"
          />

          <RgShowButton
            v-show="hasAppointmentsQueue"
            :disabled="!hasOnlyOneLineSelected || disabledByModal"
            title="Visualizar paciente na fila"
            @click="openModalPatientImageInRow"
          />

          <RgPrinterButton
            v-show="hasAppointmentsQueue"
            :disabled="!hasOnlyOneLineSelected || disabledByModal"
            title="Imprimir o comprovante de paciente na fila"
            @click="printProofInsertionQueue"
          />

          <RgDropdown
            v-show="hasAppointmentsQueue"
            :disabled="!hasOnlyOneLineSelected || disabledByModal"
            :item="listSelected[0] || {}"
            :action-options="optionsDropdownMore(listSelected)"
            class="dropdown-more"
          />

          <div
            v-if="mutableAppointmentQueue && mutableAppointmentQueue.length > 0"
            class="dropdown-button box-scroll"
          >
            <div class="button">
              <span class="text unselect">Colunas</span>
              <IconArrow class="svg" />
            </div>

            <div class="dropdown dropdown-absolute">
              <span class="label unselect">Colunas Exibidas</span>

              <label
                v-for="(col, index) in activeHeader"
                :key="col.key"
                :class="{ 'disable unselect': hasOnlyOneActiveHeader }"
                :disabled="hasOnlyOneActiveHeader"
                class="item actives unselect"
              >
                {{ col.name }}
                <input
                  :id="index"
                  :checked="col.active"
                  class="checkbox"
                  type="checkbox"
                  @change="toogleStatus(col, col.key)"
                />
              </label>

              <span class="label unselect">Colunas Não Exibidas</span>

              <label
                v-for="(col, index) in inactiveHeader"
                :key="col.key"
                class="item inactives unselect"
              >
                {{ col.name }}
                <input
                  :id="index"
                  :checked="col.active"
                  class="checkbox"
                  type="checkbox"
                  @change="toogleStatus(col, col.key)"
                />
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="appointment-schedule-queue-result">
        <div class="content-table">
          <table class="table">
            <tr>
              <th class="title-table" />
              <th
                v-for="(col, index) in header"
                v-show="col.active"
                :key="index"
                class="title-table"
                :style="{ textAlign: col.align }"
              >
                {{ col.name }}
              </th>
            </tr>

            <tr
              v-for="(item, index) in mutableAppointmentQueue"
              :key="index"
              :class="{ selected: selectedRows.includes(item.fil_id) }"
              class="tr row-content"
              @click="getValueRow(item, index)"
            >
              <td class="icon-check">
                <div v-if="selectedRows.includes(item.fil_id)" class="check">
                  <IconCheck />
                </div>
              </td>

              <td
                v-for="(col, index) in header"
                v-show="col.active"
                :key="index"
                class="result"
                :class="{ priority: item[col.key] === item.pfi_nome }"
                :style="{ textAlign: col.align }"
              >
                <span :class="{ breath: item[col.key] === item.pfi_nome }">
                  {{ item[col.key] || "-" }}
                </span>
                <div
                  v-if="item[col.key] === item.pfi_nome"
                  class="circle"
                  :title="item.pfi_nome"
                  :style="{
                    background: priorityColor(item),
                  }"
                ></div>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div
        v-show="hasAppointmentsQueue"
        slot="menu-bottom"
        class="painel-button"
      >
        <div class="others-btn">
          <RgHourglassButton
            :disabled="!hasOnlyOneLineSelected || disabledByModal"
            small
            title="Pendência"
            class="bottom"
            @click="pendenciesQueue"
          />

          <RgRegulateButton
            small
            title="Regular"
            class="bottom regulate"
            :disabled="
              !enableButtonForSelectedLines ||
              disabledRegulateButton ||
              disabledByModal
            "
            :permission="hasRegularPermission"
            @click="regulate"
          />

          <RgUnregulateButton
            small
            title="Desregular"
            class="bottom unregulate"
            :disabled="
              !enableButtonForSelectedLines ||
              disabledUnregulateButton ||
              disabledByModal
            "
            :permission="hasRegularPermission"
            @click="unRegulate"
          />

          <RgNewScheduleButton
            :disabled="!enableButtonForSelectedLines || disabledByModal"
            :permission="hasSchedulePermission"
            small
            title="Agendar"
            class="bottom"
            @click="scheduleRegulationQueue"
          />

          <RgExternScheduleButton
            :disabled="!enableButtonForSelectedLines || disabledByModal"
            :permission="hasExternalSchedulePermission"
            small
            title="Agendar externo"
            class="bottom"
            @click="externalSchedule"
          />
        </div>
      </div>
    </RgSearch>

    <ModalConfirmDeletion
      v-bind="propsModalConfirmDeletion"
      @close="closeModalConfirmQueueDeletion"
      @reSearch="fillFilter"
    />

    <ModalPatientImageInRow
      ref="modalPatientImageInRow"
      :show="modalPatientImageInRow"
      :fill-id="Number(selectedRows[0])"
      :modId="appointmentModuleId"
      @close="closeModalPatientImageInRow"
      @reSearch="reSearchAct"
    />

    <ModalTypesPrint
      ref="modalTypePrint"
      :show="modalTypesPrint"
      :appointment-id="appointmentIdToPrint"
      @close="closeModalTypesPrint"
      @printed="printed"
    />

    <ModalTypePrintInsertQueue
      :show="modalPrint"
      :filId="filId || 0"
      @close="closeModalPrint"
    />

    <ModalSimpleHistory
      :show="showModalSimpleHistory"
      :filter="filterHistoryQueue"
      @close="showModalSimpleHistory = false"
    />
  </div>
</template>

<script>
import {
  RgInputDate,
  RgSuggestCboSynonyms,
  RgComboboxUnithealthQueue,
  RgNewButton,
  RgNewScheduleButton,
  RgDropdown,
  RgHourglassButton,
  RgRegulateButton,
  RgUnregulateButton,
  RgExternScheduleButton,
  RgSuggestSmartPerson,
  IconCheck,
  RgEditButton,
  RgPrinterButton,
  RgShowButton,
  RgInput,
  IconArrow,
  IconCheckedBox,
  IconArrowSmall,
  IconArrowBox,
  IconArrowDouble,
  IconBox,
  RgLessButton,
  RgComboboxSituation,
  RgComboboxPriority,
  SmallButton,
} from "~tokio/primitive";

import RgSuggestState from "$person/common/components/suggest/rg-suggest-state/RgSuggestState";
import ModalTypePrintInsertQueue from "$appointment/submodules/schedule/component/modal/modal-type-print-insert-queue/ModalTypePrintInsertQueue";

import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";
import {
  ModalPatientImageInRow,
  ModalConfirmDeletion,
} from "~tokio/primitive/modal";
import { RgSuggestCity } from "$patient/common/components";
import RgSearch from "~tokio/foundation/rg-search/RgSearch";
import { AlertError } from "~tokio/primitive/notification";
import ModalTypesPrint from "$appointment/submodules/schedule/component/modal/modal-types-print/ModalTypesPrint";

import {
  printThermalOneWay,
  printThermalTwoWay,
  printScheduleVoucher,
} from "$appointment/common/utils/printsScheduleVoucherAppointment";
import { mapGetters } from "vuex";

import ModalSimpleHistory from "$queue/common/components/modal/modal-simple-history/ModalSimpleHistory";
import moment from "moment";

export default {
  name: "AppointmentScheduleQueue",
  components: {
    RgSearch,
    RgInputDate,
    RgSuggestState,
    RgSuggestCity,
    RgComboboxUnithealthQueue,
    RgComboboxPriority,
    RgNewButton,
    RgComboboxSituation,
    RgNewScheduleButton,
    RgHourglassButton,
    RgRegulateButton,
    RgUnregulateButton,
    RgExternScheduleButton,
    RgSuggestSmartPerson,
    IconCheck,
    IconArrow,
    IconArrowSmall,
    IconBox,
    IconArrowDouble,
    IconArrowBox,
    IconCheckedBox,
    RgSuggestCboSynonyms,
    RgEditButton,
    RgShowButton,
    RgInput,
    RgPrinterButton,
    RgLessButton,
    ModalPatientImageInRow,
    ModalConfirmDeletion,
    RgDropdown,
    ModalTypesPrint,
    ModalTypePrintInsertQueue,
    ModalSimpleHistory,
    SmallButton,
  },

  data() {
    return {
      selectedRows: [],
      listSelected: [],
      mutableAppointmentQueue: [],
      queueData: {},
      header: this.COLUMNS,
      form: {
        ocp_id: null,
        unit: null,
        patientName: null,
        patientId: null,
        priority: null,
        situation: null,
        est_id: null,
        est_sigla: null,
        mun_id: null,
        mun_nome: null,
        initialOrderPeriod: null,
        finalOrderPeriod: null,
        initialInclusionDate: null,
        finalInclusionDate: null,
        ocp_codigo: null,
        soc_nome: null,
        document: null,
      },
      cbo: "",
      filId: null,
      modalConfirmQueueDeletion: false,
      modalPatientImageInRow: false,
      disabledRegulateButton: false,
      disabledUnregulateButton: false,
      disableNameInput: false,
      disableDocumentInput: false,
      appointmentIdToPrint: null,
      modalPrint: false,
      modalTypesPrint: false,
      queueAmountAdvance: 1,
      queueAmountBack: 1,
      queuePosition: 1,
      appointmentId: [],
      showModalSimpleHistory: false,
      selectedQueueOrder: [],
    };
  },

  computed: {
    ...mapGetters({
      userLoginId: "Login/GET_USER_ID",
      unitHealthId: "Login/GET_UNIT_HEALTH_ID",
      getColumns: "User/GET_SMART_TABLE_COLUMNS",
    }),

    disabledByModal() {
      return (
        this.modalConfirmQueueDeletion ||
        this.modalPatientImageInRow ||
        this.modalTypesPrint ||
        this.modalPrint ||
        this.showModalSimpleHistory
      );
    },

    propsModalConfirmDeletion() {
      const maxlength = 250;
      const title = "Excluir Paciente(s) da Fila";
      const subtitle = "A operação não poderá ser desfeita";
      const show = this.modalConfirmQueueDeletion;
      const confirm = (reason) => this.deleteQueue(reason);

      return { show, confirm, maxlength, title, subtitle };
    },

    activeHeader() {
      const isHeaderFilled = this.header && this.header.length > 0;
      if (isHeaderFilled) {
        return this.header.filter((item) => item.active);
      }
      return false;
    },

    appointmentModuleId() {
      return this.$store.state.Login.route_module_map.appointment;
    },

    inactiveHeader() {
      const isHeaderFilled = this.header && this.header.length > 0;
      if (isHeaderFilled) {
        return this.header.filter((item) => !item.active);
      }
      return false;
    },

    hasOnlyOneActiveHeader() {
      return this.activeHeader.length === 1;
    },

    enableButtonForSelectedLines() {
      return this.selectedRows.length > 0;
    },

    hasOnlyOneLineSelected() {
      return this.selectedRows.length === 1;
    },

    hasAppointmentsQueue() {
      return this.mutableAppointmentQueue.length > 0;
    },

    disableCounty() {
      return !this.form.est_id;
    },

    equalListSize() {
      return this.listSelected.length === this.mutableAppointmentQueue.length;
    },

    filterHistoryQueue() {
      if (this.listSelected[0] === undefined) {
        return {};
      }
      const { fil_id, pes_id, pes_nome } = this.listSelected[0];
      return { fil_id, pes_id, pes_nome };
    },

    patientIsRegulate() {
      return this.listSelected.every((item) => {
        return item.fcp_ordem_regulada !== "0";
      });
    },

    preferences() {
      const preferences = {
        allowedEditRegulatedPatient: !!Number(
          this.$store.getters["Login/GET_PREFERENCES"][
            "tViewBuscaFilaConsulta.rbt_alterar_paciente_regulado"
          ],
        ),
        printOptionsPreference: this.$store.getters["Login/GET_PREFERENCES"][
          "tViewBuscaFilaConsulta.opcoesImpressao"
        ],
      };
      return preferences;
    },

    hasInsertPermission() {
      return !!this.$Permissions.global.has("consulta.filaConsulta.incluir");
    },

    hasEditPermission() {
      return !!this.$Permissions.global.has("consulta.filaConsulta.alterar");
    },

    hasDeletePermission() {
      return !!this.$Permissions.global.has("consulta.filaConsulta.excluir");
    },

    hasSchedulePermission() {
      return (
        !!this.$Permissions.global.has("consulta.filaConsulta.agendar") ||
        !!this.$Permissions.global.has(
          "consulta.filaConsulta.agendarPacienteForaTopoFila",
        ) ||
        !!this.$Permissions.global.has(
          "consulta.filaConsulta.agendarPacientesEmConsultaPelaFilaEmLote",
        )
      );
    },

    hasSchedulePermissionOnePatient() {
      return (
        !!this.$Permissions.global.has("consulta.filaConsulta.agendar") ||
        !!this.$Permissions.global.has(
          "consulta.filaConsulta.agendarPacienteForaTopoFila",
        )
      );
    },

    hasSchedulePermissionOnePatientNotFirst() {
      return !!this.$Permissions.global.has(
        "consulta.filaConsulta.agendarPacienteForaTopoFila",
      );
    },

    hasSchedulePermissionBatch() {
      return !!this.$Permissions.global.has(
        "consulta.filaConsulta.agendarPacientesEmConsultaPelaFilaEmLote",
      );
    },

    hasRegularPermission() {
      return !!this.$Permissions.global.has("consulta.filaConsulta.regular");
    },

    hasExternalSchedulePermission() {
      return !!this.$Permissions.global.has(
        "consulta.filaConsulta.agendarExternamente",
      );
    },

    hasViewRequestUnitHealthPermission() {
      return !!this.$Permissions.global.has(
        "consulta.filaConsulta.visualizarUnidadeSolicitante",
      );
    },

    getUnitHealthId() {
      return this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    },
  },

  watch: {
    "form.est_sigla"(pValue, pPrev) {
      if (!pValue || pValue !== pPrev) {
        this.form.mun_id = null;
        this.form.mun_nome = "";
      }
    },

    "form.patientName"(pValue) {
      if (pValue && pValue.length > 0) {
        this.form.document = null;
        this.form.patientId = null;
        this.disableDocumentInput = true;
      } else {
        this.disableDocumentInput = false;
      }
    },

    "form.document"(pValue) {
      if (pValue && pValue.length > 0) {
        this.disableNameInput = true;
        this.form.patientName = null;
      } else {
        this.disableNameInput = false;
      }
    },

    activeHeader(pValue, pPrev) {
      if (pPrev && pValue[0].key !== "without_result") {
        const link = this.$route.path;

        this.$store.commit("User/SET_SMART_TABLE_COLUMNS", {
          link,
          usu_id: this.userLoginId,
          uns_id: this.unitHealthId,
          columns: pValue,
          name: this.name,
        });
      }
    },

    listSelected(pValue) {
      const isRegulate = pValue.every((item) => {
        return item.fcp_ordem_regulada !== "0";
      });

      const isUnregulate = pValue.every((item) => {
        return item.fcp_ordem_regulada === "0";
      });

      if (isRegulate && !isUnregulate) {
        this.disabledRegulateButton = true;
        this.disabledUnregulateButton = false;
      }

      if (!isRegulate && isUnregulate) {
        this.disabledRegulateButton = false;
        this.disabledUnregulateButton = true;
      }

      if (!isRegulate && !isUnregulate) {
        this.disabledRegulateButton = true;
        this.disabledUnregulateButton = true;
      }
    },
  },

  created() {
    this.COLUMNS = [
      { name: "Ordem", key: "ordem_regulada", align: "center" },
      { name: "Pendência", key: "pendencia", align: "center" },
      { name: "Prioridade", key: "pfi_nome", align: "center" },
      { name: "Paciente", key: "pes_nome", align: "left" },
      { name: "Telefone", key: "telefone", align: "center" },
      { name: "Ocupação", key: "nome", align: "left" },
      { name: "Idade", key: "idade", align: "center" },
      { name: "Município", key: "mun_nome", align: "left" },
      { name: "Anexo", key: "anexo", align: "center" },
      { name: "Data na Fila", key: "fil_data", align: "center" },
      { name: "Data do Pedido", key: "fil_data_pedido", align: "center" },
      {
        name: "Unidade de Saúde Inserção",
        key: "unidade_fila",
        align: "left",
      },
      {
        name: "Unidade de Saúde Solicitante",
        key: "uns_solicitante",
        align: "left",
      },
      { name: "Usuário", key: "usu_nome", align: "left" },
    ];
  },

  mounted() {
    this.$store.commit("Person/Patient/DESTROY_LAST_PERSON_ID_SAVE");
    this.$store.commit(
      "Appointment/Schedule/UNSELECT_QUEUE_EXTERNAL_APPOINTMENT_DATA",
    );

    const isAppontmentSchedule = this.$route.params.isAppontmentSchedule;
    if (isAppontmentSchedule) {
      this.viewAppointmentScheduleVoucher(this.$route.params.appointmentId);
    }
    this.mountTableHeader(this.COLUMNS, 6);
    this.fillFilter();
  },

  beforeDestroy() {
    const validateIfExist = ValidateIfRouteExistInBreadscrumb(
      "/appointment/schedule/queue",
      this.$route.meta.breadcrumb,
    );

    if (!validateIfExist) {
      this.$store.commit("Appointment/Schedule/RESET_FILTER_APPOINTMENT_QUEUE");
    }
  },

  methods: {
    mountTableHeader(ArrColumns, initialColumns = 1) {
      let header;
      const link = this.$route.path;
      const savedHeader = this.getColumns(
        link,
        this.userLoginId,
        this.unitHealthId,
        this.name,
      );

      if (savedHeader) {
        const activeKey = [];
        savedHeader.columns.forEach((item) => {
          activeKey.push(item.key);
        });

        header = ArrColumns.map((item) => {
          if (activeKey.includes(item.key)) {
            return {
              name: item.name,
              key: item.key,
              align: item.align,
              active: true,
              ...item,
            };
          } else {
            return {
              name: item.name,
              key: item.key,
              align: item.align,
              active: false,
              ...item,
            };
          }
        });
      } else {
        header = ArrColumns.map((item, idx) => {
          const active = idx < initialColumns;
          return {
            name: item.name,
            key: item.key,
            active,
            ...item,
          };
        });
      }

      this.header = header;
      return header;
    },

    searchFilter(pData) {
      return this.$store.dispatch(
        "Appointment/Schedule/SEARCH_APPOINTMENT_QUEUE",
        pData,
      );
    },

    generateFilter() {
      const variables = {
        arrFormData: {
          soc_id: this.form.soc_id,
          uns_id: this.form.unit,
          uns_atual: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
          pfi_id: this.form.priority,
          situacao: this.form.situation,
          est_id: this.form.est_id,
          mun_id: this.form.mun_id,
          periodoInicial: this.form.initialOrderPeriod,
          periodoInicialFila: this.form.initialInclusionDate,
          periodoFinal: this.form.finalOrderPeriod,
          periodoFinalFila: this.form.finalInclusionDate,
          permissaoRegular: this.hasRegularPermission ? 1 : 0,
          permissaoUnidadeSolicitante: this.hasViewRequestUnitHealthPermission
            ? 1
            : 0,
          somenteRegulados: false,
          pes_id: this.form.patientId,
          pes_nome: this.form.patientName,
        },
      };

      this.$store.commit(
        "Appointment/Schedule/SET_FILTER_APPOINTMENT_QUEUE",
        this.form,
      );

      return variables;
    },

    async getValueRow(pItem) {
      if (this.selectedRows.includes(pItem.fil_id)) {
        this.selectedRows.splice(this.selectedRows.indexOf(pItem.fil_id), 1);
        // Remoção via objeto estava com problemas, quando executava alguma ação.
        // ... Por isso usei o filter como solução.
        // this.listSelected.splice(this.listSelected.indexOf(pItem), 1);
        this.listSelected = this.listSelected.filter(function (el) {
          return el.fil_id !== pItem.fil_id;
        });
        this.filId = Number(pItem.fil_id) || null;
        this.selectedQueueOrder.splice(
          this.selectedQueueOrder.indexOf(pItem.ordem_regulada),
          1,
        );
      } else {
        this.selectedRows.push(pItem.fil_id);
        this.listSelected.push(pItem);
        this.filId = Number(pItem.fil_id) || null;
        this.selectedQueueOrder.push(pItem.ordem_regulada);
      }
    },

    validateSchedule() {
      const equalsOccupation = this.listSelected.every((item) => {
        return this.listSelected[0].ocp_id === item.ocp_id;
      });

      if (!equalsOccupation) {
        throw new Error(
          "A ocupação dos pacientes selecionados precisam ser iguais",
        );
      }

      const isRegulate = this.listSelected.every((item) => {
        return item.fcp_ordem_regulada !== "0";
      });

      if (!isRegulate) {
        throw new Error("Só é possível agendar para pacientes regulados");
      }

      const hasSchedulePermission = this.validateSchedulePermission();

      return equalsOccupation && isRegulate && hasSchedulePermission;
    },

    validateSchedulePermission() {
      // permissões
      const minValueOrder = 1;
      const selectedQueueOrderSort = this.selectedQueueOrder.sort();
      const firstOrderSelected =
        Number(selectedQueueOrderSort[0]) === minValueOrder;

      const hasOnlyOnePatientSelected = this.selectedRows.length === 1;

      if (hasOnlyOnePatientSelected) {
        // fora da odem selecionado e sem permissao de agendar fora do topo da fila
        if (
          !firstOrderSelected &&
          !this.hasSchedulePermissionOnePatientNotFirst
        ) {
          throw new Error("Sem permissão para agendar fora do topo da fila");
        }
      } else {
        // permissao em lote
        if (!this.hasSchedulePermissionBatch) {
          throw new Error("Sem permissão para agendar em lote");
        }

        const allQueueOrder = this.mutableAppointmentQueue.map(
          (row) => row.ordem_regulada,
        );
        // verifica se os pacientes foram selecionados em ordem
        const hasConsectivePatients = this.checkIfArrayHasConsecutiveElements(
          allQueueOrder,
          this.selectedQueueOrder,
        );

        // não tem a permissão para fora do topo, não estão em sequência ou não é o primeiro na ordem
        if (
          !this.hasSchedulePermissionOnePatientNotFirst &&
          (!firstOrderSelected || !hasConsectivePatients)
        ) {
          throw new Error(
            "Sem permissão para agendar fora do topo da fila. Apenas o primeiro e subsequentes são permitidos",
          );
        }
      }
      return true;
    },

    checkIfArrayHasConsecutiveElements(allItens, itensSelected) {
      itensSelected.sort();
      const startIndex = allItens.lastIndexOf(itensSelected[0]);
      let consecutive = true;
      let countFound = 0;
      for (let i = startIndex; i < allItens.length; i++) {
        itensSelected.indexOf(allItens[i]) === -1
          ? (consecutive = false)
          : countFound++;
        if (countFound === itensSelected.length) {
          break;
        }
      }
      return consecutive;
    },

    validateExternalSchedule() {
      const isRegulate = this.listSelected.every((item) => {
        return item.fcp_ordem_regulada !== "0";
      });

      if (!isRegulate) {
        throw new Error("Só é possível agendar para pacientes regulados.");
      }

      const hasSchedulePermission = this.validateSchedulePermission();

      return isRegulate && hasSchedulePermission;
    },

    selectAllList() {
      this.mutableAppointmentQueue.forEach((item) => {
        if (!this.selectedRows.includes(item.fil_id)) {
          this.selectedRows.push(item.fil_id);
          this.listSelected.push(item);
        }
      });
    },

    unselectAllList() {
      this.mutableAppointmentQueue.forEach((item) => {
        if (this.selectedRows.includes(item.fil_id)) {
          this.selectedRows.splice(this.selectedRows.indexOf(item.fil_id), 1);
          this.listSelected.splice(this.listSelected.indexOf(item), 1);
        }
      });
    },

    reverseListSelection() {
      this.mutableAppointmentQueue.forEach((item) => {
        if (this.selectedRows.includes(item.fil_id)) {
          this.selectedRows.splice(this.selectedRows.indexOf(item.fil_id), 1);
          this.listSelected.splice(this.listSelected.indexOf(item), 1);
        } else {
          this.selectedRows.push(item.fil_id);
          this.listSelected.push(item);
        }
      });
    },

    viewClickPagination(pValue) {
      if (pValue) {
        this.unselectAllList();
      }
    },

    toogleStatus(pColumn, pKey) {
      pColumn.active = !pColumn.active;

      const index = this.getHeaderIndex(pKey);
      const activesLength = this.getActiveColumnsLength();
      const maxReached = activesLength > this.MAX_COLUMNS;
      const middle = activesLength / 2;
      const currentIndex = index + 1;

      if (maxReached) {
        const idx =
          currentIndex > middle
            ? this.getFirsActiveColumnIndex()
            : this.getLastActiveColumnIndex();

        this.header[idx].active = false;
      }
    },

    getHeaderIndex(pKey) {
      return this.header.findIndex((item) => item.key === pKey);
    },

    getActiveColumnsLength() {
      return this.getActiveColumnsIndex().length;
    },

    getActiveColumnsIndex() {
      const actives = [];
      this.header.forEach((item, index) => {
        if (item.active) actives.push(index);
      });
      return actives;
    },

    getLastActiveColumnIndex() {
      const activeColumns = this.getActiveColumnsIndex();
      return activeColumns[activeColumns.length - 1];
    },

    getFirsActiveColumnIndex() {
      return this.getActiveColumnsIndex()[0];
    },

    pendenciesQueue() {
      if (this.listSelected && this.listSelected.length > 1) {
        this.$toaster.warning(
          "Para essa operação só é permitida a seleção de um único paciente",
        );
        return;
      }
      this.$store.commit(
        "Appointment/Schedule/SELECT_QUEUE_EXTERNAL_APPOINTMENT_DATA",
        Object.values(this.listSelected),
      );
      this.$router.push({ name: "appointment.schedule.queue.pendencies" });
    },

    registrationQueue() {
      this.$router.push({
        name: "appointment.schedule.queue.insert",
        params: { fromRegulation: true },
      });
    },

    alterQueue() {
      if (
        this.patientIsRegulate &&
        !this.preferences.allowedEditRegulatedPatient
      ) {
        this.$toaster.warning("Não é possível alterar paciente regulado.");
        return;
      }
      if (this.listSelected && this.listSelected.length > 1) {
        this.$toaster.warning(
          "Para essa operação só é permitida a seleção de um único paciente",
        );
        return;
      }
      this.$store.commit(
        "Appointment/Schedule/SELECT_QUEUE_EXTERNAL_APPOINTMENT_DATA",
        Object.values(this.listSelected),
      );
      this.$router.push({ name: "appointment.schedule.queue.edit-insert" });
    },

    async deleteQueue(reason) {
      try {
        const arrQueue = this.listSelected.map((ele) => {
          return ele.fil_id;
        });

        const variables = {
          arrQueue: arrQueue,
          reason: reason,
        };

        await this.$store.dispatch(
          "Appointment/Schedule/REMOVE_APPOINTMENT_QUEUE",
          variables,
        );

        this.$toaster.success("Paciente(s) removido(s) da fila com sucesso!");
      } catch (pErr) {
        this.$toaster.error(pErr, "Erro ao tentar remover o paciente da fila");
      } finally {
        this.$loader.finish();
      }
    },

    scheduleRegulationQueue() {
      try {
        if (this.validateSchedule()) {
          this.$store.commit(
            "Appointment/Schedule/SELECT_QUEUE_EXTERNAL_APPOINTMENT_DATA",
            Object.values(this.listSelected),
          );
          this.$router.push({ name: "appointment.schedule.queue.schedule" });
          return;
        }
        return;
      } catch (Err) {
        AlertError(Err.message || Err);
      }
    },

    externalSchedule() {
      try {
        if (this.validateExternalSchedule()) {
          this.$store.commit(
            "Appointment/Schedule/SELECT_QUEUE_EXTERNAL_APPOINTMENT_DATA",
            Object.values(this.listSelected),
          );

          this.$router.push({
            name: "appointment.schedule.queue.external-schedule-form",
          });
        }
      } catch (Err) {
        AlertError(Err.message || Err);
      }
    },

    fontColor(pColor, pColKey, pPriority) {
      if (pColKey !== pPriority) {
        return false;
      }

      const listColor = [
        "ff",
        "fe",
        "fd",
        "fc",
        "fb",
        "fa",
        "f9",
        "f8",
        "f7",
        "f6",
        "f5",
        "f4",
        "f3",
        "f2",
        "f1",
        "f0",
      ];

      const split = pColor.split("");
      const first = `${split[1]}${split[2]}`;
      const second = `${split[3]}${split[4]}`;
      const third = `${split[5]}${split[6]}`;

      if (
        listColor.includes(first) &&
        listColor.includes(second) &&
        listColor.includes(third)
      ) {
        return true;
      }
      return false;
    },

    priorityColor(pValue) {
      return pValue.pfi_cor.replace("0x", "#");
    },

    selectingCbo(pValue) {
      if (pValue && pValue.source) {
        this.form.soc_id = pValue.source.soc_id;
        this.form.ocp_codigo = pValue.source.ocp_codigo;
        this.form.soc_nome = pValue.source.soc_nome;
      } else {
        this.form.soc_id = null;
        this.form.ocp_codigo = null;
        this.form.soc_nome = null;
      }
    },

    selectingPatient(pValue) {
      if (pValue && pValue.source) {
        this.form.patientName = null;
        this.form.patientId = pValue.source.pes_id;
      } else {
        this.form.patientId = null;
      }
    },

    selectingState(pValue) {
      if (pValue && pValue.source) {
        this.form.est_id = pValue.source.est_id;
      } else {
        this.form.est_id = null;
      }
    },

    selectingMunicipality(pValue) {
      if (pValue && pValue.source) {
        this.form.mun_id = pValue.source.mun_id;
      } else {
        this.form.mun_id = null;
      }
    },

    async fillFilter() {
      try {
        const existFilterData = this.$store.getters[
          "Appointment/Schedule/GET_FILTER_APPOINTMENT_QUEUE"
        ];
        if (existFilterData) {
          this.$loader.start("Carregando dados da busca de fila");
          if (this.$refs.cboSynonyms) {
            this.$refs.cboSynonyms.forceSelection({
              soc_id: existFilterData.soc_id,
              ocp_codigo: existFilterData.ocp_codigo,
              soc_nome: existFilterData.soc_nome,
            });
          }
          await this.$refs.state.forceSelection({
            est_id: existFilterData.est_id,
            est_sigla: existFilterData.est_sigla,
          });
          if (this.$refs.person && existFilterData.patientId) {
            this.$refs.person.fillPatientById(existFilterData.patientId);
          }
          this.form = existFilterData;
          this.clearRowsSelected();
          await this.$refs.rgsearch.performSearch();
        }
      } catch (Err) {
        this.$toaster.warning("Erro ao carregar os dados da navegação", Err);
      } finally {
        this.$loader.finish();
      }
    },

    async reSearchAct() {
      await this.$refs.rgsearch.performSearch();
    },

    async advancePatientInQueue() {
      try {
        if (this.listSelected[0].ordem_regulada === "EM ESPERA") {
          this.$toaster.warning(
            "Não é possível ordenar um paciente que não esteja regulado.",
          );
          return;
        }

        if (!this.queueAmountAdvance) {
          this.$toaster.warning("Informe a posição na fila.");
          return;
        }

        await this.$store.dispatch(
          "Appointment/Schedule/ADVANCE_PATIENT_QUEUE",
          {
            intIdFila: this.listSelected[0].fil_id,
            intQtdPosicoes: this.queueAmountAdvance,
          },
        );
        await this.$refs.rgsearch.performSearch();
        this.queueAmountAdvance = 1;
        this.$toaster.success("Operação realizada com sucesso!");
      } catch (Err) {
        this.$toaster.error(Err.message || Err);
      }
    },

    async backPatientInQueue() {
      try {
        if (this.listSelected[0].ordem_regulada === "EM ESPERA") {
          this.$toaster.warning(
            "Não é possível ordenar um paciente que não esteja regulado.",
          );
          return;
        }

        if (!this.queueAmountBack) {
          this.$toaster.warning("Informe a posição na fila.");
          return;
        }

        await this.$store.dispatch("Appointment/Schedule/BACK_PATIENT_QUEUE", {
          intIdFila: this.listSelected[0].fil_id,
          intQtdPosicoes: this.queueAmountBack,
        });
        await this.$refs.rgsearch.performSearch();
        this.queueAmountBack = 1;
        this.$toaster.success("Operação realizada com sucesso!");
      } catch (Err) {
        this.$toaster.error(Err.message || Err);
      }
    },

    async movePatientInQueue() {
      try {
        const isRegulate = this.listSelected.every((item) => {
          return item.fcp_ordem_regulada !== "0";
        });

        if (!isRegulate) {
          throw new Error(
            "Não é possível ordernar um paciente que não esteja regulado.",
          );
        }
        await this.$store.dispatch("Appointment/Schedule/MOVE_PATIENT_QUEUE", {
          intIdFila: this.selectedRows[0],
          intPosicao: this.queuePosition,
        });
        await this.$refs.rgsearch.performSearch();
        this.queuePosition = 1;
        this.$toaster.success("Operação realizada com sucesso!");
      } catch (Err) {
        this.$toaster.error(Err.message || Err);
      }
    },

    async regulate() {
      try {
        this.hasAnyPendency();

        const onlyWithoutPendency = (ele) => ele.fil_pendencia === "0";
        const formatArrayPayload = (ele) => ele.fil_id;

        const arrQueues = this.listSelected
          .filter(onlyWithoutPendency)
          .map(formatArrayPayload);

        if (arrQueues.length > 0) {
          await this.$store.dispatch(
            "Appointment/Schedule/REGULATION_APPOITMENT_QUEUE",
            arrQueues,
          );

          this.clearRowsSelected();
          await this.$refs.rgsearch.performSearch();
          this.$toaster.success(
            "Paciente(s) regulado(s) com sucesso.",
            "Sucesso!",
          );
        }
      } catch (Err) {
        this.$toaster.error(Err.message || Err);
      } finally {
        this.$loader.finish();
      }
    },

    async unRegulate() {
      try {
        const arrQueues = this.listSelected.map((ele) => {
          return ele.fil_id;
        });

        await this.$store.dispatch(
          "Appointment/Schedule/UNREGULATE_APPOITMENT_QUEUE",
          arrQueues,
        );

        this.clearRowsSelected();
        await this.$refs.rgsearch.performSearch();
        this.$toaster.success("Paciente(s) desregulado(s) com sucesso.");
      } catch (Err) {
        this.$toaster.error(Err.message || Err);
      }
    },

    printProofInsertionQueue() {
      this.modalPrint = true;
    },

    optionsDropdownMore(pItem) {
      return [
        {
          label: "Observações do paciente na fila",
          action: this.observationPatient,
          disable: !this.hasOnlyOneLineSelected,
        },
        {
          label: "Histórico do paciente na fila",
          action: this.patientHistoryQueue,
        },
        {
          label: "Histórico de ocupação do paciente",
          action: this.patientOccupationHistory,
        },
      ];
    },

    observationPatient() {
      if (this.filId) {
        const appointmentsData = this.listSelected[0];

        this.$router.push({
          name: "appointment.schedule.queue.patient-observation-queue",
          params: { appointmentsData },
        });
      } else {
        this.$toaster.warning(
          "Este paciente não foi cadastrado pela fila de regulação.",
        );
      }
    },

    patientImagesQueue() {
      this.$toaster.warning("Não implementado");
    },

    patientHistoryQueue() {
      this.showModalSimpleHistory = true;
    },

    patientOccupationHistory() {
      this.$toaster.warning("Não implementado");
    },

    openModalConfirmQueueDeletion() {
      this.modalConfirmQueueDeletion = true;
    },

    closeModalConfirmQueueDeletion() {
      this.modalConfirmQueueDeletion = false;
      this.clearRowsSelected();
    },

    openModalPatientImageInRow() {
      this.modalPatientImageInRow = true;
    },

    closeModalPatientImageInRow(hasSavedFiles) {
      this.modalPatientImageInRow = false;
      this.mutableAppointmentQueue.forEach((item) => {
        // row found update
        if (item.fil_id === this.selectedRows[0]) {
          item.anexo = hasSavedFiles === true ? "SIM" : "NÃO";
        }
      });
      this.clearRowsSelected();
    },

    openModalTypesPrint() {
      this.modalTypesPrint = true;
    },

    closeModalTypesPrint() {
      this.modalTypesPrint = false;
    },

    clearRowsSelected() {
      this.listSelected = [];
      this.selectedRows = [];
      this.selectedQueueOrder = [];
    },

    closeModalPrint() {
      this.modalPrint = false;
    },

    actionCleanSelection() {
      this.selectedRows = [];
      this.listSelected = [];
      this.selectedQueueOrder = [];
    },

    cleanForm() {
      this.selectedRows = [];
      this.listSelected = [];
      this.selectedQueueOrder = [];
      this.mutableAppointmentQueue = [];
      this.cbo = null;

      this.disableNameInput = false;
      this.disableDocumentInput = false;
      this.form = {
        unit: null,
        patientName: null,
        priority: null,
        situation: null,
        est_id: null,
        est_sigla: null,
        mun_nome: null,
        initialOrderPeriod: null,
        finalOrderPeriod: null,
        initialInclusionDate: null,
        finalInclusionDate: null,
        document: null,
      };
      this.queueAmountAdvance = 1;
      this.queueAmountBack = 1;
      this.queuePosition = 1;
    },

    async viewAppointmentScheduleVoucher(appointmentID) {
      this.appointmentIdToPrint = appointmentID;

      switch (this.preferences.printOptionsPreference) {
        case "2": // Impressão Normal
          this.openModalTypesPrint();
          break;
        case "6": // Impressão Normal
          await printScheduleVoucher(this.appointmentIdToPrint);
          await this.registerScheduleVoucherBatch();
          break;
        case "7": // Impressora Térmica 1 via
          await printThermalOneWay(this.appointmentIdToPrint);
          await this.registerScheduleVoucherBatch();
          break;
        case "8": // Impressora Térmica 2 vias
          await printThermalTwoWay(this.appointmentIdToPrint);
          await this.registerScheduleVoucherBatch();
          break;
        default:
          break;
      }
    },

    hasAnyPendency() {
      const hasPendency = (element) => element.fil_pendencia !== "0";
      const isPendency = this.listSelected.find(hasPendency);
      if (isPendency) {
        this.$toaster.warning(
          "Pacientes com pendências não podem ser regulados.",
          "Atenção!",
        );
      }
    },

    printed() {
      this.registerScheduleVoucherBatch();
    },

    async registerScheduleVoucherBatch() {
      try {
        await this.$store.dispatch(
          "Appointment/Schedule/REGISTER_SCHEDULE_VOUCHER_BATCH",
          { intIdConsulta: this.appointmentIdToPrint },
        );
      } catch (pError) {
        this.$toaster.error(pError.message || pError);
      }
    },

    verifyOrderInitialDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialOrderPeriod, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalOrderPeriod, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }
    },

    verifyInclusionInitialDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialInclusionDate, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalInclusionDate, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }
    },
  },
};
</script>
