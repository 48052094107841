<template>
  <div class="history-schedule-filter">
    <div class="grid">
      <div class="selectinput">
        <RgComboboxUnithealth
          v-model="form.uns_id"
          :rules="{ required: true }"
          class="inputitem"
        />
      </div>
      <div class="filter-title">
        <span class="title">Período da Consulta</span>
      </div>

      <div class="selectinput _flex">
        <RgInputDate
          ref="initialDate"
          v-model="form.initialDate"
          :rules="{ required: true }"
          label="Data Inicial"
          placeholder="dd/mm/aaaa"
          class="inputitem"
        />
        <RgInputDate
          ref="finalDate"
          v-model="form.finalDate"
          :rules="{ required: true }"
          :max-date="getMaxDateInitial"
          :min-date="getMinDateInitial"
          label="Data Final"
          placeholder="dd/mm/aaaa"
          class="inputitem mg"
        />
      </div>

      <div class="filter-title">
        <span class="title">Paciente</span>
      </div>

      <div class="selectinput">
        <RgSuggestSmartPerson
          ref="patient"
          v-model="suggestPatient"
          :enabled-patient="false"
          :rules="{ forceSelection: true, required: true }"
          class="inputpatient"
          :extraData="['telephone']"
          @selected="selectingPatient"
        />
      </div>

      <div class="filter-title">
        <span class="title">Ordenação</span>
      </div>

      <div class="selectinput">
        <RgComboboxOrderBy
          v-model="form.orderby"
          :data="ORDERS"
          :rules="{ required: true }"
          label="Ordenar por"
          class="inputitem"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  RgComboboxUnithealth,
  RgInputDate,
  RgSuggestSmartPerson,
  RgComboboxOrderBy,
} from "~tokio/primitive";

import moment from "moment";

const FORM_FILTER = {
  uns_id: null,
  pes_nome: null,
  pac_prontuario_unico: null,
  cns_or_cpf: "",
  telephones: "",
  sex: "",
  age: null,
  pes_id: null,
  initialDate: moment().add(-30, "days").format("DD/MM/YYYY"),
  finalDate: moment().format("DD/MM/YYYY"),
  orderby: 1,
  orderByLabel: "Data e Hora",
};

export default {
  name: "AppointmentDisabledPeriodsReport",
  components: {
    RgComboboxUnithealth,
    RgInputDate,
    RgSuggestSmartPerson,
    RgComboboxOrderBy,
  },
  data() {
    return {
      resultTitle: "Histórico de Agendamentos do Paciente",
      suggestPatient: null,
      form: this.$utils.obj.DeepCopy(FORM_FILTER),
    };
  },
  computed: {
    printReportCss() {
      return (
        "@page {size: landscape;}" +
        ".report-column-8 { white-space: normal !important; }"
      );
    },
    getNameSmartTable() {
      return "AppointmentHistoryScheduleReport";
    },
    columnTable() {
      return this.COLUMN;
    },
    getMaxDateInitial() {
      return this.form.initialDate
        ? new Date(
            moment(this.form.initialDate, "DD/MM/YYYY")
              .add(30, "days")
              .format(),
          )
        : null;
    },

    getMinDateInitial() {
      return this.form.initialDate
        ? new Date(moment(this.form.initialDate, "DD/MM/YYYY").format())
        : null;
    },
  },
  watch: {
    "form.initialDate"(pValue, pPrev) {
      if (!pValue || pValue !== pPrev) {
        this.form.finalDate = "";
      }

      if (pValue && !this.form.finalDate) {
        this.form.finalDate = moment(pValue, "DD/MM/YYYY")
          .add(30, "days")
          .format("DD/MM/YYYY");
      }
    },
  },
  mounted() {
    this.form.uns_id = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
  },
  created() {
    this.COLUMN = [
      { name: "Consulta", key: "DATA_HORA_CONSULTA" },
      { name: "Setor", key: "SETOR", align: "left" },
      { name: "Ocupação", key: "OCUPACAO", align: "left" },
      { name: "Profissional", key: "PROFISSIONAL_DE_SAUDE", align: "left" },
      { name: "Objetivo", key: "OBJETIVO", align: "left" },
      { name: "Situação", key: "SITUACAO", align: "left" },
      { name: "Marcação", key: "MARCACAO" },
    ];

    this.ORDERS = [
      { value: 1, text: "Data e Hora" },
      { value: 2, text: "Setor" },
      { value: 3, text: "Profissional" },
    ];
  },
  methods: {
    buildFilter() {
      const payLoad = {
        uns_id: this.form.uns_id || null,
        pes_id: this.form.pes_id || null,
        pes_nome: this.form.pes_nome || null,
        pac_prontuario_unico: this.form.pac_prontuario_unico || null,
        cns_or_cpf: this.form.cns_or_cpf || null,
        initialDate: this.form.initialDate,
        finalDate: this.form.finalDate,
        telephones: this.form.telephones,
        age: this.form.age,
        sex: this.form.sex,
        orderby: this.form.orderby,
        orderByLabel: this.form.orderByLabel,
      };
      return payLoad;
    },

    async doSearch(pData) {
      try {
        return this.$store.dispatch(
          "Appointment/Report/GET_APPOINTMENT_HISTORY_SCHEDULE_REPORT",
          pData,
        );
      } catch (error) {
        return false;
      }
    },

    selectingPatient(pPatient) {
      const source = pPatient.source ? pPatient.source : null;
      const hasPerson = source && source.pes_id > 0;

      if (hasPerson) {
        this.form.pes_id = source.pes_id;
        this.form.pes_nome = source.pes_nome;

        if (source.telephones.rows.length > 0) {
          let contact = "";

          source.telephones.rows.forEach((item) => {
            contact += `(${item.ddd.ddd_codigo}) ${item.tel_numero} `;
          });

          this.form.telephones = contact;
        }

        if (source.gender.sex_sigla) {
          this.form.sex =
            source.gender.sex_sigla === "F" ? "Feminino" : "Masculino";
        }

        if (source.pes_nascimento) {
          const ageDifference = moment().diff(source.pes_nascimento);
          const diffDuration = moment.duration(ageDifference);
          this.form.age = diffDuration.years();
        }

        if (source.patient && source.patient.pac_prontuario_unico > 0)
          this.form.pac_prontuario_unico = source.patient.pac_prontuario_unico;

        if (source.cns && source.cns.crs_numero)
          this.form.cns_or_cpf = source.cns.crs_numero;
        else if (source.cpf && source.cpf.cpf_numero)
          this.form.cns_or_cpf = source.cpf.cpf_numero;
      } else {
        this.form.pes_id = null;
        this.form.pac_prontuario_unico = null;
        this.form.age = null;
        this.form.pes_nome = "";
        this.form.cns_or_cpf = "";
        this.form.telephones = "";
        this.form.sex = "";
      }
    },

    clearFilter() {
      this.form = this.$utils.obj.DeepCopy(FORM_FILTER);
      this.suggestPatient = null;
      this.$refs.patient.cleanValidate();
      this.form.uns_id = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    },
  },
};
</script>
