import GET_PERSON_BY_ID from "./GetPersonById";
import SAVE_PERSON from "./SavePerson";
import UPDATE_PERSON from "./UpdatePerson";
import UPDATE_ADDITIONAL_INFO from "./UpdateAdditionalInfo";
import ADD_PERSON_PHONE from "./AddPersonPhone";
import DELETE_TELEPHONE from "./DeleteTelephone";
import GET_DOCUMENTS_LIST from "./GetDocumentsList";
import ADD_NEW_DOCUMENT from "./AddNewDocument";
import CREATE_NEW_PATIENT from "./CreateNewPatient";
import SEARCH_CADSUS from "./SearchCadsus";

export default {
  GET_PERSON_BY_ID,
  SAVE_PERSON,
  UPDATE_PERSON,
  UPDATE_ADDITIONAL_INFO,
  ADD_PERSON_PHONE,
  DELETE_TELEPHONE,
  GET_DOCUMENTS_LIST,
  ADD_NEW_DOCUMENT,
  CREATE_NEW_PATIENT,
  SEARCH_CADSUS,
};
