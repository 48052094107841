import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query usersFilter(
    $statusUser: Boolean!
    $digitedUf: String
    $digitedLoginName: String
    $digitedName: String
    $digitedMun: String
    $digitedMuID: Int
    $digitedUfID: Int
    $limit: Int
    $offset: Int
    $IdUnityHealth: Int
  ) {
    users(
      usu_nome: $digitedName
      usu_login: $digitedLoginName
      usu_id_municipios: $digitedMuID
      usu_ativo: $statusUser
      usu_id_unidades_saude_padrao: $IdUnityHealth
      mun_nome: $digitedMun
      est_sigla: $digitedUf
      est_id: $digitedUfID
      limit: $limit
      offset: $offset
    ) {
      count
      rows {
        usu_id
        usu_id_grupos_usuario
        usu_id_municipios
        usu_id_unidades_saude_padrao
        usu_id_setor_padrao
        usu_id_clientes
        usu_nome
        usu_login
        usu_ativo
        unitHealth {
          tus_id
          tus_id_clientes
          tus_codigo
          tus_nome
        }
        city {
          mun_id
          mun_nome
          state {
            est_id
            est_sigla
          }
        }
      }
    }
  }
`;

export default async ({ commit }, { variables }) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    }).catch((err) => {
      console.error(err);
    });

    commit("SET_LIST", { list: data.users.rows });
    commit("SET_COUNT", { count: data.users.count });
    return Promise.resolve(data.users);
  } catch (err) {
    const jsonResponse = JSON.parse(JSON.stringify(err));
    return Promise.reject(jsonResponse.graphQLErrors[0].message);
  }
};
