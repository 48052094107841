<style src="./SpecialtiesAttendancesByDay.scss" lang="scss" scoped></style>
<template>
  <section class="report-container row">
    <div class="row filter-fields">
      <RgComboboxUnithealth v-model="form.uns_id" :rules="{ required: true }" />
    </div>
    <div class="row filter-fields">
      <RgInputDate
        v-model="form.initialDate"
        :rules="{ required: true }"
        class="col-6"
        label="Data inicial"
        placeholder="Informe a data de início"
      />

      <RgInputDate
        v-model="form.finalDate"
        :rules="{ required: true }"
        class="col-6"
        label="Data final"
        placeholder="Informe a data final"
      />
    </div>
    <RgIframe v-if="showPrint" :src="printLink" class="iframe-hidden" />
  </section>
</template>

<script>
import { RgInputDate } from "~tokio/primitive";
import RgIframe from "~tokio/foundation/rg-iframe/RgIframe";
import moment from "moment";
import RgComboboxUnithealth from "$billing/common/component/rg-combobox-unithealth/RgComboboxUnithealth";

export default {
  name: "SpecialtiesAttendancesByDay",
  components: {
    RgInputDate,
    RgIframe,
    RgComboboxUnithealth,
  },
  filters: {
    formatDateBR(pDate) {
      return moment(pDate, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
  },
  data() {
    return {
      resultTitle: "Registro Diario por Especialidade",
      form: {
        initialDate: moment().format("DD/MM/YYYY"),
        finalDate: moment().add(2, "days").format("DD/MM/YYYY"),
        uns_id: 0,
      },
      showPrint: false,
      hashRefresh: "123",
    };
  },
  computed: {
    columnTable() {
      return this.COLUMN;
    },
    getNameSmartTable() {
      return "SpecialtiesAttendancesByDay";
    },
  },
  created() {
    this.COLUMN = [
      { name: "BAM", key: "bol_numero" },
      { name: "Nome do Paciente", key: "pes_nome" },
      { name: "Especialização", key: "esp_nome" },
    ];
  },
  mounted() {
    this.form.uns_id = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
  },
  methods: {
    buildFilter() {
      const data = {
        uns_id: this.form.uns_id,
        initialDate: String(this.form.initialDate),
        finalDate: String(this.form.finalDate),
      };
      return data;
    },
    async doSearch(pData) {
      try {
        return this.$store.dispatch(
          "Emergency/Report/GET_ATTENDANCES_SPECIALTIES_BY_DAY",
          pData,
        );
      } catch (error) {
        return false;
      }
    },
    clearFilter() {
      this.form = {
        initialDate: "",
        finalDate: "",
        uns_id: 0,
      };
    },
    printReportCss() {
      return (
        ".report-column-1-print { width: 200px; }" +
        ".report-column-2-print { width: 100px; }" +
        ".report-column-3-print { width: 200px; }" +
        ".report-column-4-print { width: 130px; }" +
        ".report-column-5-print { width: 130px; }" +
        ".report-column-6-print { width: 130px; }" +
        "tbody tr:nth-of-type(odd) { background-color: rgba(0,0,0,.05); }"
      );
    },

    formatDate(pDate) {
      this.$utils.date.BrazilianDateToDatabase(pDate);
    },
  },
};
</script>
