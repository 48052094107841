// scale
import SEARCH_SCALE_LIST from "./appointment-scale/SearchScaleList";
import DIVISION_APPOINTMENT_SCALE from "./appointment-scale/DivisionAppointmentScale";
import SEARCH_HISTORY_SCALE_LIST from "./appointment-scale/SearchHistoryScaleList";
import ADD_APPOINTMENT_SCALE from "./appointment-scale/AddAppointmentScale";
import EDIT_APPOINTMENT_SCALE from "./appointment-scale/EditAppointmentScale";
import DELETE_APPOINTMENT_SCALE from "./appointment-scale/DeleteAppointmentScale";
import GET_EMPLOYEE_ATTENDACE_SCALE_DATA from "./appointment-scale/GetEmployeeAttendaceScaleData";

// objective
import SEARCH_APPOINTMENT_OBJECTIVE from "./appointment-objective/SearchAppointmentObjective";
import ADD_APPOINTMENT_OBJECTIVE from "./appointment-objective/RegisterAppointmentObjective";
import EDIT_APPOINTMENT_OBJECTIVE from "./appointment-objective/EditAppointmentObjective";
import ACTIVE_APPOINTMENT_OBJECTIVE from "./appointment-objective/ActiveAppointmentObjective";
import INACTIVE_APPOINTMENT_OBJECTIVE from "./appointment-objective/InactiveAppointmentObjective";
import SEARCH_APPOINTMENT_OBJECTIVE_UNIFIED from "./appointment-objective/SearchObjectiveUnified";
import SEARCH_APPOINTMENT_OBJECTIVE_TUSS from "./appointment-objective/SearchObjectiveTUSS";
import EXPORT_APPOINTMENT_OBJECTIVE_TUSS from "./appointment-objective/ExportAppointmentObjectiveTUSS";
import EXPORT_APPOINTMENT_OBJECTIVE_UNIFIED from "./appointment-objective/ExportAppointmentObjectiveUnified";

// priority
import SEARCH_APPOINTMENT_PRIORITY from "./appointment-priority/SearchAppointmentPrioritys";
import ADD_APPOINTMENT_PRIORITY from "./appointment-priority/AddAppointmentPriority";
import DELETE_APPOINTMENT_PRIORITY from "./appointment-priority/DeleteAppointmentPriority";
import ACTIVE_APPOINTMENT_PRIORITY from "./appointment-priority/ActiveAppointmentPriority";
import EDIT_APPOINTMENT_PRIORITY from "./appointment-priority/EditAppointmentPriority";

export default {
  SEARCH_APPOINTMENT_OBJECTIVE,
  SEARCH_SCALE_LIST,
  DIVISION_APPOINTMENT_SCALE,
  ADD_APPOINTMENT_SCALE,
  EDIT_APPOINTMENT_SCALE,
  DELETE_APPOINTMENT_SCALE,
  SEARCH_HISTORY_SCALE_LIST,
  SEARCH_APPOINTMENT_PRIORITY,
  ADD_APPOINTMENT_PRIORITY,
  ADD_APPOINTMENT_OBJECTIVE,
  DELETE_APPOINTMENT_PRIORITY,
  ACTIVE_APPOINTMENT_PRIORITY,
  EXPORT_APPOINTMENT_OBJECTIVE_TUSS,
  EXPORT_APPOINTMENT_OBJECTIVE_UNIFIED,
  SEARCH_APPOINTMENT_OBJECTIVE_UNIFIED,
  SEARCH_APPOINTMENT_OBJECTIVE_TUSS,
  GET_EMPLOYEE_ATTENDACE_SCALE_DATA,
  EDIT_APPOINTMENT_OBJECTIVE,
  ACTIVE_APPOINTMENT_OBJECTIVE,
  INACTIVE_APPOINTMENT_OBJECTIVE,
  EDIT_APPOINTMENT_PRIORITY,
};
