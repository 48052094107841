<template>
  <Modulebox :title="title" :granted="permission" class="exam-form-schedule">
    <QuickExamScheduleForm
      ref="form"
      class="exam-form-schedule-form"
      :examData="examData"
      :isPerDay="isPerDay"
      @canSave="onCanSave"
      @saveFormByModalConfirmSchedule="onSaveFormByModalConfirmSchedule"
    />

    <div class="footer">
      <RgCleanButton
        v-if="!isEditing"
        small
        title="Limpar"
        @click="confirmCleanForm"
      />

      <RgCancelButton medium class="cancel" @click="cancel" />

      <RgSaveButton
        ref="saveButton"
        medium
        class="save"
        :disabled="!canSave"
        @click="saveForm"
      />
    </div>

    <ModalConfirmFieldClearing
      ref="confirmClearing"
      :show="modalConfirmFieldClearing"
      @getYes="getCleanFormMethod"
      @getOut="closeModalConfirmFieldClearing"
    />
  </Modulebox>
</template>
<script>
import {
  RgCleanButton,
  RgSaveButton,
  RgCancelButton,
  ModalConfirmFieldClearing,
} from "~tokio/primitive";
import Modulebox from "~tokio/foundation/modulebox/Modulebox";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";
import QuickExamScheduleForm from "$exam/common/component/quick-exam-schedule-form/QuickExamScheduleForm";

export default {
  name: "ExamFormSchedulePerDay",
  components: {
    Modulebox,
    QuickExamScheduleForm,
    RgSaveButton,
    RgCancelButton,
    RgCleanButton,
    ModalConfirmFieldClearing,
  },
  data: () => {
    return {
      scheduleLoadInfo: null,
      typeSchedule: false,
      modalConfirmFieldClearing: false,
      canSave: false,
    };
  },
  computed: {
    employeeData() {
      return this.$store.state.Exam.Schedule.selected_employee_data;
    },

    examData() {
      return Object.assign({}, this.selectedPatientData, this.scheduleLoadInfo);
    },

    isEditing() {
      if (parseInt(this.examData?.exa_id) > 0) {
        return true;
      }
      return false;
    },

    selectedPatientData() {
      const patientData = this.$store.state.Exam.Schedule.selected_patient_data;
      return patientData;
    },

    permission() {
      // TODO - ver permissões
      // return this.$Permissions.global.has('exame2.cadastroExameProcedimento')
      return true;
    },

    existRoutePerPeriods() {
      if (this.isEditing) {
        return this.$route.name === "exam.schedule.per-period.edit.form";
      }
      return this.$route.name === "exam.schedule.per-period.form";
    },
    title() {
      if (this.existRoutePerPeriods) {
        return this.typeSchedule
          ? "Agendamento Múltiplo de Exames"
          : "Agendar Exame";
      } else {
        return this.isEditing ? "Editar Exame" : "Agendar Exame";
      }
    },
    isPerDay() {
      const tmp = ValidateIfRouteExistInBreadscrumb(
        "/exam/schedule/per-day",
        this.$route.meta.breadcrumb,
      );
      if (!tmp) {
        return false;
      }
      return true;
    },
  },

  destroyed() {
    const existThisRouteFromSchedulePerDay = ValidateIfRouteExistInBreadscrumb(
      "/exam/schedule/per-day/form",
      this.$route.meta.breadcrumb,
    );

    const existThisRouteFromEditSchedulePerDay = ValidateIfRouteExistInBreadscrumb(
      "/exam/schedule/per-day/edit/form",
      this.$route.meta.breadcrumb,
    );

    const existThisRouteFromSchedulePerPeriods = ValidateIfRouteExistInBreadscrumb(
      "/exam/schedule/per-periods/form",
      this.$route.meta.breadcrumb,
    );

    const existThisRouteFromEditSchedulePerPeriods = ValidateIfRouteExistInBreadscrumb(
      "/exam/schedule/per-period/edit/form",
      this.$route.meta.breadcrumb,
    );

    if (
      !existThisRouteFromSchedulePerDay &&
      !existThisRouteFromEditSchedulePerDay &&
      !existThisRouteFromSchedulePerPeriods &&
      !existThisRouteFromEditSchedulePerPeriods
    ) {
      this.removeSelectedData();
      this.$store.commit("Exam/Schedule/CLEAN_FORM_SCHEDULE");
    }
  },

  async created() {
    if (this.selectedPatientData) {
      this.scheduleLoadInfo = await this.$store.dispatch(
        "Exam/Schedule/SCHEDULE_LOAD",
        {
          intIdExameAgendamentoProcedimento: this.examData.eap_id,
        },
      );
    }
  },
  methods: {
    onCanSave(val) {
      this.canSave = val;
    },
    getCleanFormMethod() {
      this.$refs.form.cleanForm();
      this.modalConfirmFieldClearing = false;
    },

    confirmCleanForm() {
      this.modalConfirmFieldClearing = true;
    },

    closeModalConfirmFieldClearing() {
      this.modalConfirmFieldClearing = false;
    },

    changeTypeOfSchedule() {
      this.typeSchedule = !this.typeSchedule;
    },

    removeSelectedData() {
      // em nenhum momento está havendo SELECT_EXAM_DATA
      // this.$store.commit("Exam/Schedule/UNSELECT_EXAM_DATA");
    },

    onSaveFormByModalConfirmSchedule(result) {
      this.pushRouter(result);
    },

    async saveForm() {
      try {
        this.$loader.start();
        this.$refs.saveButton.actionSubmit();

        const result = await this.$refs.form.saveForm();
        this.pushRouter(result);

        this.$refs.saveButton.actionDone();
      } catch (pError) {
        this.$refs.saveButton.fail();
        this.$toaster.error(pError);
      } finally {
        this.$loader.finish();
      }
    },

    pushRouter(result) {
      if (result !== false) {
        this.removeSelectedData();

        if (typeof result === "number") {
          const params = {
            isExamSchedule: true,
            examId: result,
          };

          this.changeRoute(params);
        } else {
          this.changeRoute();
        }
      }
    },

    changeRoute(params = null) {
      const routeName = this.isPerDay
        ? "exam.schedule.per-day"
        : "exam.schedule.per-period";

      this.$router.push({ name: routeName, params });
    },

    async searchFilterAppointmentSchedule() {
      try {
        this.$loader.start();
        await this.$refs.rgsearch.performSearch();

        this.mutableAppointmentSchedule = await this.$store.dispatch(
          "Appointment/Schedule/SEARCH_APPOINTMENT_SCHEDULE",
          this.payloadAppointmentSchedule,
        );
      } catch (Err) {
        this.$toaster.error(Err);
      } finally {
        this.$loader.finish();
      }
    },

    cancel() {
      const existThisRouteFromSchedulePerDay = ValidateIfRouteExistInBreadscrumb(
        "/exam/schedule/per-day",
        this.$route.meta.breadcrumb,
      );

      const existThisRouteFromSchedulePerPeriods = ValidateIfRouteExistInBreadscrumb(
        "/exam/schedule/per-period",
        this.$route.meta.breadcrumb,
      );

      if (existThisRouteFromSchedulePerDay) {
        this.$router.push("/exam/schedule/per-day");
      }

      if (existThisRouteFromSchedulePerPeriods) {
        this.$router.push("/exam/schedule/per-period");
      }
    },
  },
};
</script>
