<style src="./ModalEnableDisablePeriod.scss" lang="scss" scoped></style>
<template lang="html">
  <div v-if="show" class="modal-enable-disable-period">
    <RgValidatorForm ref="validator">
      <RgBaseModal
        ref="Modal"
        v-shortkey="['esc']"
        :show="show"
        class="rg-base-modal"
        with-close-button
        @close="close"
        @shortkey.native="close"
      >
        <div slot="header" class="header">
          {{ title }}
        </div>

        <div slot="body" class="body">
          <FormBase title="Período" class="form-area">
            <div class="period">
              <RgInputDate
                v-model="form.initialDate"
                :rules="{ required: true, fn: validateInitialDate }"
                :min-date="new Date()"
                class="input-date"
                label="Data de Início"
                placeholder="dd/mm/aaaa"
              />
              <RgInputDate
                v-model="form.finalDate"
                :rules="{ required: true }"
                :min-date="new Date()"
                class="input-date"
                label="Data de Término"
                placeholder="dd/mm/aaaa"
              />
              <RgComboboxReason
                v-if="isDisable"
                v-model="form.reason"
                :rules="{ required: true }"
                :isAppointment="isAppointment"
                label="Motivo"
              />
            </div>
          </FormBase>
          <FormBase title="Profissionais" class="form-area">
            <div class="professional">
              <SmartTable
                ref="tableUnified"
                name="ModalEnableDisabledPeriod"
                :columns="COLUMN"
                :body="professionalData"
                :total="total"
                :initial-columns="4"
                :show-pagination="false"
                :removeBtnColumns="true"
                index-column="index"
                dontSelect
              />
            </div>
          </FormBase>
        </div>

        <div slot="footer" class="footer">
          <RgCancelButton medium @click="cancel" />
          <RgConfirmButton medium @click="confirm" />
        </div>
      </RgBaseModal>
    </RgValidatorForm>
  </div>
</template>

<script>
import { SmartTable } from "~tokio/foundation";
import {
  RgValidatorForm,
  RgBaseModal,
  RgCancelButton,
  RgConfirmButton,
  RgInputDate,
  RgComboboxReason,
} from "~tokio/primitive";

import FormBase from "~tokio/foundation/form-base/FormBase";

import moment from "moment";

export default {
  name: "ModalEnableDisablePeriod",
  components: {
    RgValidatorForm,
    SmartTable,
    RgBaseModal,
    FormBase,
    RgCancelButton,
    RgConfirmButton,
    RgInputDate,
    RgComboboxReason,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "",
    },
    professionalData: {
      type: Array,
      default: () => [],
    },
    unitHealthId: {
      required: true,
      default: 0,
    },
    isAppointment: {
      type: Boolean,
    },
  },
  data() {
    return {
      form: {
        initialDate: null,
        finalDate: null,
        reason: null,
      },
      routeName: "",
      total: null,
      observation: null,
    };
  },
  computed: {
    isEnable() {
      return this.type && this.type.includes("turnOnAct");
    },
    isDisable() {
      return this.type && this.type.includes("turnOffAct");
    },
    title() {
      return this.isEnable ? "Habilitar Período" : "Inabilitar Período";
    },
    hasPermissionNormalScale() {
      return !!this.$Permissions.sector.has(
        "consulta.habilitacaoInabilitacaoAtendimento.habilitarInabilitar",
        this.professionalData.set_id,
      );
    },
    hasPermissionTechnicalReserveScale() {
      return !!this.$Permissions.sector.has(
        "consulta.habilitacaoInabilitacaoAtendimento.habilitarInabilitarReservaTecnica",
        this.professionalData.set_id,
      );
    },
  },
  watch: {
    show(pValue) {
      if (pValue) {
        this.routeName = this.$route.name;
        this.total = Number(this.professionalData.length);
      } else {
        this.close();
      }
    },

    "form.reason"(pValue) {
      if (typeof pValue === "object") {
        this.observation = pValue && pValue[0].text;
      }
    },
  },
  created() {
    this.COLUMN = [
      { name: "Unidade de Saúde", key: "uns_nome" },
      { name: "Setor", key: "set_nome" },
      { name: "Profissional", key: "pes_nome" },
      { name: "Ocupação", key: "ocp_nome" },
    ];
  },

  methods: {
    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    async confirm() {
      if (!(await this.isFormValid())) {
        this.$toaster.warning("Verifique os campos.");
        return false;
      }

      this.isEnable ? this.enablePeriods() : this.disablePeriods();
    },

    formatDataExam() {
      const auxArr = [];

      this.professionalData.forEach((item) => {
        auxArr.push({ set_id: item.set_id, vus_id: item.vus_id });
      });
      const variables = {
        arrVinculosSetores: auxArr,
        strDataInicial: this.form.initialDate,
        strDataFinal: this.form.finalDate,
        strOperacao: this.isEnable ? "HABILITAR" : "INABILITAR",
        intIdObservacao: this.isEnable ? 0 : this.form.reason,
      };

      return variables;
    },

    formatDataAppointment() {
      const auxArr = [];

      this.professionalData.forEach((item) => {
        auxArr.push({ set_id: item.set_id, vus_id: item.vus_id });
      });

      const variables = {
        arrFormData: {
          arrVinculosSetores: auxArr,
          intIdUnidadeSaude: this.unitHealthId,
          strDataInicial: this.form.initialDate,
          strDataFinal: this.form.finalDate,
          strOperacao: this.isEnable ? "HABILITAR" : "INABILITAR",
          intObservacao: this.isEnable ? null : this.form.reason,
          strObservacao: this.isEnable ? null : this.observation,
          blnPermissaoEscalaNormal: this.hasPermissionNormalScale,
          blnPermissaoEscalaReservaTecnica: this
            .hasPermissionTechnicalReserveScale,
        },
      };

      return variables;
    },

    async enablePeriods() {
      try {
        const modules = this.isAppointment ? "Appointment" : "Exam";
        const enable = `${modules}/Schedule/ENABLE_DISABLE_PERIODS`;

        let variables = {};

        if (this.isAppointment) {
          variables = this.formatDataAppointment();
        } else {
          variables = this.formatDataExam();
        }

        const data = await this.$store.dispatch(enable, variables);

        if (data.dados === 0) {
          this.$toaster.warning(
            "Não existem escalas dentro do período informado",
          );
        } else {
          this.$toaster.success("Período habilitado com sucesso!");
          this.$emit("reSearch");
        }

        this.close();
      } catch (pErr) {
        const error = pErr.toString();

        if (error.includes("Error:")) {
          this.$toaster.error(
            error.substring(6, error.length),
            "Erro ao habilitar período.",
          );
        } else {
          this.$toaster.error("Erro ao habilitar período.", pErr);
        }
      }
    },

    async disablePeriods() {
      try {
        const modules = this.isAppointment ? "Appointment" : "Exam";
        const path = `${modules}/Schedule/VALIDATE_DISABLE_PERIODS`;
        const auxArr = [];

        this.professionalData.forEach((item) => {
          auxArr.push({ set_id: item.set_id, vus_id: item.vus_id });
        });

        const validateVariables = {
          arrVinculosSetores: auxArr,
          strDataInicial: this.form.initialDate,
          strDataFinal: this.form.finalDate,
          strOperacao: "INABILITAR",
          intObservacao: this.form.reason,
        };

        const response = await this.$store.dispatch(path, {
          arrFormData: validateVariables,
        });

        const isSuccessResponse = response.hasError === false;

        if (isSuccessResponse) {
          const path = `${modules}/Schedule/ENABLE_DISABLE_PERIODS`;

          let variables = {};

          if (this.isAppointment) {
            variables = this.formatDataAppointment();
          } else {
            variables = this.formatDataExam();
          }

          const data = await this.$store.dispatch(path, variables);

          if (data.dados === 0) {
            this.$toaster.warning(
              "Não existem escalas dentro do período informado.",
            );
          } else {
            this.$toaster.success("Período inabilitado com sucesso!");
            this.$emit("reSearch");
          }

          this.close();
        } else {
          this.close();
          return this.$emit("error", response);
        }
      } catch (pErr) {
        const error = pErr.toString();

        if (error.includes("Error:")) {
          this.$toaster.error(
            error.substring(6, error.length),
            "Erro ao inabilitar período.",
          );
        } else {
          this.$toaster.error("Erro ao inabilitar período.", pErr);
        }
      }
    },

    validateInitialDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialDate, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalDate, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push(
          "A data de início não pode ser maior que a data de término",
        );
        return false;
      }
    },

    cleanForm() {
      this.form = {
        initialDate: null,
        finalDate: null,
        reason: null,
      };
      this.routeName = "";
      this.total = null;
    },

    cancel() {
      this.close();
    },

    close() {
      this.cleanForm();
      this.$emit("close");
    },
  },
};
</script>
