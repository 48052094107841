import Store from "@/store";
import AuthRequest from "~common/request/AuthRequest";
import { BrazilianDateToDatabase } from "~utils/date";

export default () => {
  return new Promise((resolve, reject) => {
    const BofIdBoletins =
      Store.getters["Billing/Bulletin/GET_SELECTED_BULLETIN"];
    const BofCnes = Store.getters["Login/GET_UNIT_HEALTH_CNES"];
    const bofUnitHealthId = Store.getters["Login/GET_UNIT_HEALTH_ID"];
    const BofCompetencia = Store.getters["Billing/BDSia/GET_PERIOD_DATE"];
    const Payload = Object.assign(
      {},
      Store.getters["Billing/Billing/GET_ALL_INFO"],
    );
    const BofFaturado = Payload.bof_faturado === 2 ? 2 : 0;

    Payload.bof_id_boletins = BofIdBoletins;
    Payload.bof_cnes = BofCnes;
    Payload.bof_id_unidades_saude = bofUnitHealthId;
    Payload.bof_competencia = BofCompetencia;
    Payload.bof_faturado = BofFaturado;
    Payload.bof_data_nascimento = BrazilianDateToDatabase(
      Payload.bof_data_nascimento,
    );
    Payload.bof_cep = Payload.bof_cep.replace(/[^\d]+/g, "");
    Payload.bof_cns = Payload.bof_cns
      ? Payload.bof_cns.replace(/[^\d]+/g, "")
      : "";

    delete Payload.pes_id;

    if (!Payload.bof_id) {
      Create(Payload).then(() => {
        resolve();
      });
    } else {
      Update(Payload).then(() => {
        resolve();
      });
    }
  });
};

const Create = (Payload) => {
  return new Promise((resolve, reject) => {
    AuthRequest.post(
      "/billing/bulletin-billing/create-bulletin-billing",
      Payload,
    )
      .then((pResult) => {
        Store.commit("Billing/Billing/STORE_BOF_ID", pResult.data.bof_id);
        resolve();
      })
      .catch(() => {
        throw new Error("Erro ao realizar cadastro");
      });
  });
};

const Update = (Payload) => {
  console.log(Payload);
  return new Promise((resolve, reject) => {
    AuthRequest.put(
      "/billing/bulletin-billing/update-bulletin-billing",
      Payload,
    )
      .then((pResult) => {
        Store.commit("Billing/Billing/STORE_BOF_ID", pResult.data.bof_id);
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};
