import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async (context, intIdFilaPendencia, intIdModulo) => {
  console.log("intIdFilaPendencia:", intIdFilaPendencia);
  console.log("v:", intIdModulo);
  return AuthLegacyRequest.post(
    "tabelas/controller-fila-pendencia/excluir-resolucao",
    intIdFilaPendencia,
    intIdModulo,
  )
    .then(({ data }) => {})
    .catch((pErr) => {
      throw pErr;
    });
};
