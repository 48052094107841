<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchDocumentType from "./action/SearchDocumentType.js";

export default {
  name: "RgComboboxDocumentType",
  mixins: [RgComboboxMixin],
  data: () => {
    return {};
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];
      const data = await SearchDocumentType();
      data.forEach((item) => {
        this.mData.push({ value: item.nac_id, text: item.nac_nome });
      });
    },
  },
};
</script>
