import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchAihPerSpecialtyReport(
    $unsId: Int
    $specialty: String
    $presentation: String
    $status: String
    $limit: Int
    $offset: Int
    $toPrint: Boolean
    $toCsv: Boolean
    $toExcel: Boolean
  ) {
    SearchAihPerSpecialtyReport(
      uns_id: $unsId
      inf_codigo_especialidade_leito: $specialty
      iaf_apresentacao: $presentation
      iaf_faturado: $status
      limit: $limit
      offset: $offset
      toPrint: $toPrint
      toCsv: $toCsv
      toExcel: $toExcel
    ) {
      html
      count
      csv
      excel
      fileName
      rows {
        iaf_id
        icf_nome
        iaf_prontuario
        iaf_numero_aih
        ils_nome
      }
    }
  }
`;
export default async (
  { commit },
  {
    uns_id,
    toPrint,
    toCsv,
    toExcel,
    initialDate,
    finalDate,
    specialty,
    presentation,
    status,
    limit,
    offset,
  },
) => {
  presentation = !presentation.indexOf("/")
    ? presentation
    : presentation.replace("/", "");
  try {
    const variables = {
      unsId: uns_id,
      toCsv,
      toExcel,
      toPrint,
      initialDate,
      finalDate,
      specialty,
      presentation,
      status,
      limit,
      offset,
    };
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    const html =
      !data.SearchAihPerSpecialtyReport ||
      !data.SearchAihPerSpecialtyReport.html
        ? ""
        : data.SearchAihPerSpecialtyReport.html.replace('\\"', '"');
    const count = !data.SearchAihPerSpecialtyReport
      ? 0
      : data.SearchAihPerSpecialtyReport.count;
    const csv = !data.SearchAihPerSpecialtyReport
      ? 0
      : data.SearchAihPerSpecialtyReport.csv;
    const excel = !data.SearchAihPerSpecialtyReport
      ? 0
      : data.SearchAihPerSpecialtyReport.excel;
    const fileName = !data.SearchAihPerSpecialtyReport
      ? 0
      : data.SearchAihPerSpecialtyReport.fileName;
    return {
      html,
      count,
      csv,
      excel,
      fileName,
      rows: data.SearchAihPerSpecialtyReport.rows,
    };
  } catch (error) {
    throw new Error("Houve um problema ao obter dados do servidor");
  }
};
