import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query Deficiency {
    SearchDeficiency {
      rows {
        def_id
        def_nome
        def_codigo_ab
      }
    }
  }
`;

export default async ({ commit, state }) => {
  if (state.deficiency.length > 0) {
    return;
  }
  try {
    const { data } = await GraphQLClient.query({
      query,
      fetchPolicy: "no-cache",
    });
    commit("SET_DEFICIENCY", data.SearchDeficiency.rows);
  } catch (err) {
    console.log(err);
    return err.graphQLErrors;
  }
};
