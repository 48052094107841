<style src="./SurgeryMap.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="map-container">
    <ModuleBox
      :granted="hasPermission"
      class="surgery-map-filter"
      title="Filtro Cirúrgico"
    >
      <SurgeryMapFilter @map-data="formData" />
    </ModuleBox>
    <ModuleBox
      :granted="hasPermission"
      class="surgery-map"
      title="Mapa Cirúrgico"
    >
      <SurgeryMapForm :fill-data="formMap" />
    </ModuleBox>
  </section>
</template>

<script>
import ModuleBox from "~tokio/foundation/modulebox/Modulebox";
import SurgeryMapForm from "$surgery-center/surgery/component/surgery-map-form/SurgeryMapForm";
import SurgeryMapFilter from "$surgery-center/surgery/component/surgery-map-form/surgery-map-filter/SurgeryMapFilter";

export default {
  name: "SurgeryMap",
  components: {
    ModuleBox,
    SurgeryMapForm,
    SurgeryMapFilter,
  },
  data() {
    return {
      formMap: null,
    };
  },
  computed: {},
  methods: {
    formData(pData) {
      this.formMap = pData;
    },
    hasPermission() {
      return this.$Permissions.global.has(
        "centro_cirurgico.cirurgia.mapaCirurgico",
      );
    },
  },
};
</script>
