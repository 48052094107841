<style src="./RgSuggestPlace.scss" lang="scss" scoped></style>
<template>
  <div class="rg-suggest-place">
    <RgSuggest
      ref="rgSuggest"
      v-model="inputValue"
      :label="label"
      :disabled="disabled"
      :placeholder="placeholder"
      :tab-index="tabIndex"
      :rules="rules"
      min="2"
    >
      <RgSuggestItem
        v-for="(item, idx) in suggestionList"
        :key="idx"
        :idx="idx"
        :item="item"
        class="place-item"
      >
        <div class="place-row-top">
          <span class="place-name">{{ item.tlg_nome }}</span>
        </div>
      </RgSuggestItem>
    </RgSuggest>
  </div>
</template>
<script>
import { RgSuggest, RgSuggestMixin, RgSuggestItem } from "~tokio/primitive";
import { SearchPlace } from "./action";

export default {
  name: "RgSuggestPlace",
  components: {
    RgSuggest,
    RgSuggestItem,
  },
  mixins: [RgSuggestMixin],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      captionRaw: "tlg_nome",
    };
  },
  methods: {
    doSearch(pSearchTerm) {
      return SearchPlace({ tlgNome: pSearchTerm });
    },
  },
};
</script>
