<style src="./ScheduleListResult.scss" lang="scss" scoped></style>

<template lang="html">
  <section ref="listContainer" class="schedule-list-result">
    <div v-if="!granted" class="permission-locked" />
    <div v-if="listItems.length === 0" class="list-empty">
      <IconEmpty class="icon-empty" />
      <p class="description">Não foram encontrados registros de pedidos</p>
    </div>

    <ul v-if="listItems.length > 0" class="list-container">
      <li
        v-for="(item, itemIdx) in listItems"
        :key="item.cci_id"
        :keys="itemIdx"
        :class="{ 'selected-item': item.cci_id === selectedItemId }"
        class="list-items"
        @click="selectSurgery(item)"
      >
        <GuideLine :cc-status="item.cci_id_status_controles_cirurgicos" />
        <RequestDate
          :date="item.cci_data_inicio"
          :hours="item.cci_data_criacao"
        />
        <SurgeryDetails :data="item" />
      </li>
    </ul>

    <!-- <div class="loading-more-results">
      <div class="load-more-label" v-show="!emptyResult">
        <icon-up-arrow class="load-icon" />
        <span class="load-label">Carregando mais resultados...</span>
      </div>

      <div class="load-more-label" v-show="emptyResult">
        <icon-empty class="load-icon"/>
        <span class="load-label">A pesquisa não retornou resultados.</span>
      </div>
    </div> -->
  </section>
</template>

<script>
import GuideLine from "./guide-line/GuideLine";
import RequestDate from "./request-date/RequestDate";
import SurgeryDetails from "./surgery-details/SurgeryDetails";
import { IconEmpty } from "~tokio/primitive";

export default {
  name: "ScheduleListResult",
  components: {
    GuideLine,
    RequestDate,
    SurgeryDetails,
    IconEmpty,
  },
  props: {
    granted: {
      default: true,
    },
  },
  // directives: { infiniteScroll },
  data() {
    return {
      // busy: false,
      // loadMoreLabel: '',
      emptyResult: false,
    };
  },
  computed: {
    listItems() {
      return this.$store.state.SurgeryCenter.Surgery.listItems;
    },
    selectedItemId() {
      return this.$store.getters["SurgeryCenter/Surgery/getSelectedItemId"];
    },
    hasPermission() {
      return true;
    },
    registerCount() {
      const items = this.listItems.length;
      const plural = items > 1 ? "s" : "";
      return `${items} Registro${plural} encontrado${plural}`;
    },
  },
  watch: {
    listItems: function (pNew, pOld) {
      if (pNew.length < pOld.length) {
        this.$refs.listContainer.scrollTop = 0;
      }
    },
  },
  methods: {
    selectSurgery(item) {
      if (item.cci_id === this.selectedItemId) {
        this.$store.commit("SurgeryCenter/Surgery/CLEAN_SELECTION");
        return;
      }
      this.$store.dispatch("SurgeryCenter/Surgery/editSurgery", { item });
      window.location.href = "#scheduling-surgery";
    },
    // async loadMore (a) {
    //   let isBottom = this.$refs.listContainer.clientHeight + this.$refs.listContainer.scrollTop === this.$refs.listContainer.scrollHeight

    //   if (isBottom) {
    //     this.emptyResult = false
    //     const hasResult = await this.$store.dispatch('SurgeryCenter/Surgery/LOAD_MORE_RESULTS')
    //     if (hasResult) {
    //       const scrollPosition = this.$refs.listContainer.scrollHeight - this.$refs.listContainer.clientHeight
    //       this.$refs.listContainer.scrollTop = scrollPosition - 50
    //       this.busy = false
    //     } else {
    //       setTimeout(() => {
    //         this.emptyResult = true
    //       }, 1500)
    //     }
    //   }
    // }
  },
};
</script>
