import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async (context, intIdAgendamento, idModulo) => {
  return AuthLegacyRequest.post(
    "tabelas/controller-agendamento-externo/excluir",
    intIdAgendamento,
    idModulo,
  )
    .then(({ data }) => {})
    .catch((pErr) => {
      throw pErr;
    });
};
