<style src="./PersonSearch.scss" lang="scss" scoped></style>
<template lang="html">
  <div class="patient-search">
    <RgPersonSearch @search="search" @person-insert="patientInsert" />

    <section class="search-content">
      <section class="search-result">
        <RgPersonCard
          v-for="(searchResultItem, idx) in personList"
          :key="idx"
          :person-id="searchResultItem.pac_id"
          :patient-data="searchResultItem"
          :list-index="idx"
          class="person-card-item"
          @content-click="personSelected"
          @new-born="newBorn"
          @title-click="personTitleSelected"
          @open-map="openMap"
        />

        <div v-show="personList.length < 1" class="search-result--no-result">
          <IconEmpty class="search-result--icon" />
          <span class="search-result--no-result--title"
            >Nenhum resultado encontrado</span
          >
          <span class="search-result--no-result--descr"
            >Tentamos achar o paciente mencionado e até nomes parecidos mas não
            tivemos sucesso.</span
          >
        </div>
      </section>
    </section>

    <!--<rg-person-map @close-map="closeMap" v-if="showMap"/> -->
  </div>
</template>

<script>
import { IconEmpty } from "~tokio/primitive/icon";
import { RgPersonSearch, RgPersonCard } from "$patient/common/components";
import { mapGetters } from "vuex";

export default {
  name: "PersonSearch",
  components: {
    IconEmpty,
    RgPersonSearch,
    RgPersonCard,
  },
  data() {
    return {
      personSearch: {
        search: "",
      },
      showMap: false,
      people: [],
    };
  },
  computed: {
    ...mapGetters({
      personList: "Patient/PERSON_LIST",
    }),
  },
  mounted() {
    this.$store.dispatch("Patient/GET_PERSON_LIST");
  },
  methods: {
    search(query) {
      this.$store.dispatch("Patient/GET_PERSON_LIST", query);
    },
    patientInsert(pInsertPerson) {
      this.$router.push({ name: "patient.detail" });
    },
    personSelected({ pes_id }) {
      this.$store.commit("Patient/SET_PERSON_ID", pes_id);
      this.$router.push({ name: "patient.detail" });
    },
    newBorn({ pes_id }) {
      this.$store.commit("Patient/SET_NEWBORN", true);
      this.$store.commit("Patient/SET_PERSON_ID", pes_id);
      this.$router.push({ name: "patient.detail" });
    },
    personTitleSelected() {},
    openMap() {},
  },
};
</script>
