import moment from "moment";
import documentTypes from "../../common/service/DocumentTypes";

export const preparePersonInfo = (pFields, pDocuments = null) => {
  const telData =
    pFields.telephones && pFields.telephones.rows
      ? pFields.telephones.rows[0]
      : "";
  const ddd = telData && telData.ddd ? telData.ddd.ddd_codigo : "";
  const telephone = telData ? telData.tel_numero : "";
  const cns =
    pFields.cns && pFields.cns.crs_numero ? pFields.cns.crs_numero : "";
  const document = prepareDocumentFromDocuments(pDocuments);

  return {
    icf_nome: pFields.pes_nome,
    icf_data_nascimento: moment(pFields.pes_nascimento).format("DD/MM/YYYY"),
    icf_nome_mae: pFields.pes_mae,
    icf_nome_responsavel: null,
    icf_cns: cns,
    icf_sexo: pFields.gender.sex_sigla,
    icf_cod_raca: pFields.ethnicity.etn_codigo_sisaih,
    icf_cod_etnia: "",
    icf_tel_ddd: ddd,
    icf_tel: telephone,
    icf_cod_tipo_logradouro: pFields.address.publicPlaceType.tlg_codigo,
    icf_logradouro: pFields.address.end_logradouro,
    icf_logradouro_numero: pFields.address.end_numero,
    icf_complemento: pFields.address.end_complemento,
    icf_cep: pFields.address.end_cep,
    icf_bairro: pFields.address.neighborhood.bai_nome,
    icf_cod_municipio: pFields.address.neighborhood.city.mun_codigo,
    icf_uf: pFields.address.neighborhood.city.state.est_sigla,
    icf_cod_nacionalidade: pFields.nationality.nac_codigo,
    icf_tipo_documento: document.icf_tipo_documento,
    icf_documento: document.icf_documento,
  };
};

export const prepareDocumentFromDocuments = (pDocuments = null) => {
  let document = {
    icf_tipo_documento: "",
    icf_documento: "",
  };
  if (pDocuments) {
    const documentKey = Object.keys(pDocuments).find(
      (key) => !!pDocuments[key],
    );
    if (documentKey) {
      document = {
        icf_tipo_documento: documentKey,
        icf_documento: pDocuments[documentKey],
      };
    }
  }
  return document;
};

export const preparePatientInfo = (pInfo, ignoreId = false) => {
  const patientInfo = Object.assign({}, removeGraphQLTypeName(pInfo));

  patientInfo.icf_data_nascimento = moment(
    patientInfo.icf_data_nascimento,
  ).format("DD/MM/YYYY");
  patientInfo.icf_tipo_documento = prepareDocumentsType(
    patientInfo.icf_tipo_documento,
    "govId",
    "id",
  );

  if (ignoreId) delete patientInfo.icf_id;

  return patientInfo;
};

export const prepareAihInfo = (pInfo) => {
  const aihInfo = Object.assign({}, removeGraphQLTypeName(pInfo));

  aihInfo.iaf_data_internacao = moment(aihInfo.iaf_data_internacao).format(
    "DD/MM/YYYY",
  );
  aihInfo.iaf_data_saida = moment(aihInfo.iaf_data_saida).format("DD/MM/YYYY");
  aihInfo.iaf_tipo_aih = parseInt(aihInfo.iaf_tipo_aih);

  return aihInfo;
};

export const prepareDateToBR = (pDate) => {
  return moment(pDate).format("DD/MM/YYYY");
};

export const prepareDocuments = (pFields) => {
  const types = ["cpf", "pisPasep", "rg", "newBirthCertificate"];
  const result = Object.keys(pFields);

  const filtered = result
    .filter((key) => types.includes(key))
    .reduce((obj, key) => {
      return {
        ...obj,
        [documentTypes[key].id]: Object.values(pFields[key])[0],
      };
    }, {});
  return filtered;
};

export const prepareDocumentsType = (pFields, pItemSearch, pItemReturn) => {
  const id = parseInt(pFields);
  if (id) {
    const itemSearch = pItemSearch || "id";
    const itemReturn = pItemReturn || "govId";
    const result = Object.values(documentTypes).find((item) => {
      if (item[itemSearch] === id) {
        return item;
      }
    });
    if (result) return result[itemReturn];
    return "";
  }
};

export const getRecordNumber = (pFields) => {
  return pFields.record_numbers.rows[0]
    ? pFields.record_numbers.rows[0].ppr_numero
    : "";
};

export const removeGraphQLTypeName = (pFields) => {
  const data = Object.assign({}, pFields);
  if (data.__typename) {
    delete data.__typename;
  }
  return data;
};

export const prepareDateToSave = (pDate) => {
  return pDate ? pDate.split("/").reverse().join("-") : null;
};

export const removeSpecialCharacters = (pDocument) => {
  return pDocument ? pDocument.replace(/[^\d]+/g, "") : null;
};

export const preparePatientToSave = (pInfo) => {
  const patientInfo = Object.assign({}, pInfo);

  patientInfo.icf_data_nascimento = prepareDateToSave(
    patientInfo.icf_data_nascimento,
  );
  patientInfo.icf_cep = removeSpecialCharacters(patientInfo.icf_cep);
  patientInfo.icf_tipo_documento = prepareDocumentsType(
    patientInfo.icf_tipo_documento,
    "id",
    "govId",
  );
  patientInfo.icf_documento = removeSpecialCharacters(
    patientInfo.icf_documento,
  );
  return patientInfo;
};

export const prepareAihToSave = (pInfo) => {
  const aihInfo = Object.assign({}, pInfo);

  aihInfo.iaf_data_internacao = prepareDateToSave(aihInfo.iaf_data_internacao);
  aihInfo.iaf_data_saida = prepareDateToSave(aihInfo.iaf_data_saida);
  aihInfo.iaf_numero_aih = removeSpecialCharacters(aihInfo.iaf_numero_aih);
  aihInfo.iaf_tipo_aih = "0" + aihInfo.iaf_tipo_aih;
  return aihInfo;
};

export const prepareHospitalizationToSave = (pInfo) => {
  const hospitalizationInfo = Object.assign({}, pInfo);

  hospitalizationInfo.inf_data_autorizacao = prepareDateToSave(
    hospitalizationInfo.inf_data_autorizacao,
  );
  hospitalizationInfo.inf_cnpj_empregador = removeSpecialCharacters(
    hospitalizationInfo.inf_cnpj_empregador,
  );

  return hospitalizationInfo;
};

export const prepareCidToSave = (pData) => {
  const omitDescription = (key, value) =>
    key === "description" ? undefined : value;
  const cidList = JSON.parse(JSON.stringify(pData), omitDescription);
  return cidList;
};

export const prepareHospitalizationProcedureToSave = (pData) => {
  if (!pData) return null;
  const omitProcedure = (key, value) =>
    key === "procedure" ? undefined : value;
  const hospitalizationProceduresList = JSON.parse(
    JSON.stringify(pData),
    omitProcedure,
  );
  for (let index = 0; index < hospitalizationProceduresList.length; index++) {
    if (hospitalizationProceduresList[index].iap_codigo_servico === "false") {
      hospitalizationProceduresList[index].iap_codigo_servico = null;
    }
    if (
      hospitalizationProceduresList[index].iap_codigo_classificacao === "false"
    ) {
      hospitalizationProceduresList[index].iap_codigo_classificacao = null;
    }
  }

  return hospitalizationProceduresList;
};
