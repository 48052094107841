<style src="./RgBulletinBillingStatusOptions.scss" lang="scss" scoped></style>
<template>
  <div class="rg-bulletin-billing-status-option">
    <span class="label">{{ label }}</span>
    <div class="container">
      <div
        :class="{ 'active-open': selectedStatus.includes('null') }"
        class="radio show-open"
        @click="addStatus('null')"
      >
        Aberto
      </div>
      <div
        :class="{ 'active-billed': selectedStatus.includes('0') }"
        class="radio show-billed"
        @click="addStatus('0')"
      >
        Faturado
      </div>
      <div
        :class="{ 'active-imported': selectedStatus.includes('2') }"
        class="radio show-imported"
        @click="addStatus('2')"
      >
        Importado
      </div>
      <div
        :class="{ 'active-exported': selectedStatus.includes('1') }"
        class="radio show-exported"
        @click="addStatus('1')"
      >
        Exportado
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RgBulletinBillingStatusOptions",
  props: {
    label: {
      default: "Status",
    },
    value: {
      default: function () {
        return "null";
      },
    },
  },
  data() {
    return {
      selectedStatus: "null",
    };
  },
  watch: {
    value(pValue) {
      this.selectedStatus = pValue;
    },
  },
  methods: {
    addStatus(pItem) {
      this.selectedStatus = pItem;
      this.$emit("input", this.selectedStatus);
    },
  },
};
</script>
