<style src="./GridStageHour.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="grid-stage-hour">
    <div
      v-for="(stage, idx) in stageMap"
      :key="idx"
      :ref="'stageContainer_' + idx"
      class="stage-column"
    >
      <GridBlock
        v-for="stageBlocks in stageItemMap[stage.lca_id]"
        :key="stageBlocks.startTime"
        :stage-id="idx"
        :hour-block="stageBlocks.startTime"
        :details="stageBlocks.surgeries"
      />
    </div>
  </section>
</template>

<script>
import GridBlock from "./grid-block/GridBlock";
import { mapGetters } from "vuex";

export default {
  name: "HourGuide",
  components: {
    GridBlock,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      stageItemMap: "SurgeryCenter/Surgery/getAllSurgeryStage",
      stageMap: "SurgeryCenter/Surgery/getAllStages",
    }),
  },
  mounted() {
    this.mapAllStages();
  },
  methods: {
    mapAllStages() {},
  },
};
</script>
