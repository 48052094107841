<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchSectorProcedureProfessional from "./action/SearchSectorProcedureProfessional";

export default {
  name: "RgComboxUnithealth",
  mixins: [RgComboboxMixin],
  props: {
    unithHealthId: {
      type: Number,
    },
    procedureName: {
      type: String,
    },
    submoduleId: {
      type: Number,
    },
  },
  data: () => {
    return {};
  },
  watch: {
    unithHealthId() {
      this.fillData();
    },
    procedureName() {
      this.fillData();
    },
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];

      if (this.unithHealthId && this.procedureName && this.submoduleId) {
        const data = await SearchSectorProcedureProfessional(
          this.unithHealthId,
          this.procedureName,
          this.submoduleId,
        );

        data.forEach((item) => {
          if (item.enabled === "1") {
            this.mData.push({ value: item.data, text: item.label });
          }
        });

        if (this.mData.length === 1) {
          this.$emit("selectItem", this.mData[0].value);
        }
      }
    },
  },
};
</script>
