import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  return AuthLegacyRequest.post(
    "consultas/controller-paciente-agendado-fila-consulta/buscar",
    variables,
  )
    .then(({ data }) => {
      data = HandleResponse(data);

      data.dados.data = data.dados.recordSet;
      data.dados.data.map((item) => {
        item.pfi_cor = item.pfi_cor.replace("0x", "#");
      });

      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
