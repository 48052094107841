<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchEmployeeSectorOccupation from "./action/SearchEmployeeSectorOccupation";
export default {
  name: "RgComboboxEmployeeSectorOccupation",
  mixins: [RgComboboxMixin],
  props: {
    sectorId: {
      required: true,
    },
    occupationId: {
      required: true,
    },
  },
  watch: {
    sectorId() {
      this.fillData();
    },
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      if (!this.sectorId || !this.occupationId) return Promise.resolve([]);

      this.mData = [];

      this.disableLoading = true;
      const data = await SearchEmployeeSectorOccupation({
        intIdSetor: this.sectorId,
        intIdOcupacao: this.occupationId,
      });

      data.forEach((item) => {
        this.mData.push({ value: item.data, text: item.pes_nome });
      });
      this.disableLoading = false;
    },
  },
};
</script>
