<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchOccupationSynonymsUnit from "./action/SearchOccupationSynonymsUnit";
export default {
  name: "RgComboboxOccupationSynonymsUnit",
  mixins: [RgComboboxMixin],
  props: {
    healthUnitId: {
      required: true,
    },
  },
  data: () => {
    return {};
  },
  watch: {
    healthUnitId() {
      this.fillData();
    },
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];
      if (!this.healthUnitId) {
        return;
      }
      const data = await SearchOccupationSynonymsUnit({
        intIdUnidade: this.healthUnitId,
      });
      data.forEach((item) => {
        this.mData.push({
          value: item.data,
          text: item.label,
          ocp_id: Number(item.ocp_id),
        });
      });
    },
  },
};
</script>
