<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchPlaceForSector from "./action/SearchPlaceForSector";

export default {
  name: "RgComboboxPlaces",
  mixins: [RgComboboxMixin],
  props: {
    sectorId: {
      type: Number,
    },
  },
  data: () => {
    return {};
  },
  watch: {
    sectorId() {
      this.fillData();
    },
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];

      if (this.sectorId) {
        const data = await SearchPlaceForSector({
          intIdSetor: this.sectorId,
        });
        data.forEach((item) => {
          this.mData.push({ value: item.data, text: item.lca_nome });
        });
        if (this.mData.length === 1) {
          this.$emit("selectItem", this.mData[0].value);
        }
      }
    },
  },
};
</script>
