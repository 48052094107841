import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query waterTreatment {
    SearchWaterTreatment(limit: 100) {
      rows {
        tra_id
        tra_codigo_ab
        tra_nome_tratamento
      }
      count
    }
  }
`;

export default async ({ commit, state }) => {
  if (state.water_treatment.length > 0) {
    return;
  }
  try {
    const { data } = await GraphQLClient.query({
      query,
      fetchPolicy: "no-cache",
    });
    commit("SET_WATER_TREATMENT", data.SearchWaterTreatment.rows);
  } catch (err) {
    console.log(err);
    return err.graphQLErrors;
  }
};
