<template>
  <section class="patient-details">
    <Modulebox :granted="hasPermission" :title="title" class="register-base">
      <!-- <div slot="title" class="manager-page">
        <rg-history-button
          v-if="existsPesId"
          large
          @click="showModalHistoryPatient"
        />
      </div> -->

      <QuickPatientDetails
        ref="patientDetails"
        show-anvil-button
        @fail="statusFail"
        @success="statusSuccess"
      />

      <footer slot="footer" class="footer">
        <RgCleanButton
          v-show="!existsPesId"
          small
          data-id="limpar"
          type="button"
          title="Limpar"
          class="buttons"
          @click="showConfirmFieldClearing"
        />

        <RgCancelButton
          v-show="hasPermission"
          medium
          class="buttons"
          type="button"
          title="Cancelar"
          @click="goBack"
        />

        <RgSaveButton
          v-show="hasPermission"
          ref="saveButton"
          medium
          data-id="salvar"
          type="button"
          title="Salvar"
          class="buttons"
          @click="save"
        />
      </footer>
    </Modulebox>

    <!-- :TODO: COMENTADO POR ENQUANTO -->
    <!-- <modal-history-register
      :show="modalHistoryPatient"
      :data="{pes_id: pesId}"
      @close="closeModalHistoryPatient"
    /> -->

    <ModalConfirmFieldClearing
      ref="confirmClearing"
      :show="modalConfirmFieldClearing"
      @getYes="confirmCleanForm"
      @getOut="closeConfirmFieldClearing"
      @close="closeConfirmFieldClearing"
    />

    <MedicalLoader
      :to-close="loading"
      :title-loader="'Aguarde...'"
      :title-success="'Sucesso!'"
      :title-failed="'Erro. '"
      @close="closeLoader"
    />
  </section>
</template>

<script>
import Modulebox from "~tokio/foundation/modulebox/Modulebox";
import QuickPatientDetails from "$person/patient/component/container/quick-patient-details/QuickPatientDetails";
import {
  RgCleanButton,
  RgSaveButton,
  RgCancelButton,
  MedicalLoader,
} from "~tokio/primitive";
import { mapGetters } from "vuex";

import ModalConfirmFieldClearing from "~tokio/primitive/modal/modal-confirm-field-clearing/ModalConfirmFieldClearing";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";

export default {
  name: "PatientDetails",
  components: {
    Modulebox,
    RgSaveButton,
    RgCleanButton,
    RgCancelButton,
    ModalConfirmFieldClearing,
    QuickPatientDetails,
    MedicalLoader,
  },

  data() {
    return {
      loading: false,
      modalHistoryPatient: false,
      modalConfirmFieldClearing: false,
      pesId: null,
    };
  },

  computed: {
    ...mapGetters({
      existsPesId: "Person/Patient/EXISTS_PES_ID",
      isNewBorn: "Person/Patient/IS_NEWBORN",
    }),

    hasPermission() {
      if (this.existsPesId) {
        return this.$Permissions.global.has("cadastro.pessoa.alterar");
      } else {
        return this.$Permissions.global.has("cadastro.pessoa.incluir");
      }
    },

    title() {
      if (!this.existsPesId) {
        return "Cadastrar Paciente";
      }
      if (this.existsPesId && !this.isNewBorn && this.hasPermission) {
        return "Editar Paciente";
      }
      if (this.isNewBorn) {
        return "Vincular Recém-Nascido";
      }
      if (!this.hasPermission) {
        return "Cadastro de Paciente";
      }
      return "";
    },
  },

  methods: {
    statusSuccess() {
      this.$refs.saveButton.actionDone();
    },

    statusFail() {
      this.$refs.saveButton.fail();
    },

    save() {
      this.$refs.patientDetails.savePatient();
    },

    showModalHistoryPatient() {
      this.modalHistoryPatient = true;
    },

    closeLoader() {
      this.loading = false;
    },

    closeModalHistoryPatient() {
      this.modalHistoryPatient = false;
    },

    showConfirmFieldClearing() {
      this.modalConfirmFieldClearing = true;
    },

    closeConfirmFieldClearing() {
      this.modalConfirmFieldClearing = false;
    },

    confirmCleanForm() {
      this.modalConfirmFieldClearing = false;
      this.$refs.patientDetails.clean();
    },

    goBack() {
      const routerMapBedValid = ValidateIfRouteExistInBreadscrumb(
        "/hospitalization/bed/search",
        this.$route.meta.breadcrumb,
      );

      if (routerMapBedValid) {
        this.$router.push({
          name: "hospitalization.bed.search.new-hospitalization",
          params: this.$route.params,
        });
      } else {
        const routerValid = ValidateIfRouteExistInBreadscrumb(
          "/person/patient/details",
          this.$route.meta.breadcrumb,
        );

        routerValid
          ? this.$router.push("/person/patient/search")
          : this.$router.go(-1);
      }
    },
  },
};
</script>
