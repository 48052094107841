import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation CreatePatient(
    $person: PersonInputType!
    $address: AddressInputType!
    $documents: DocumentsInputType
    $attachments: [AttachmentsInputType]
    $telephones: [TelephoneInputType]
    $patient: PatientInputType
    $notifications: [NotificationPeopleInputType]
  ) {
    CreatePerson(
      person: $person
      address: $address
      documents: $documents
      attachments: $attachments
      telephones: $telephones
      notifications: $notifications
      patient: $patient
      isCreatePatient: true
    ) {
      rows {
        pes_id
        pes_nome
      }
      success
      message
    }
  }
`;

const CreatePatient = async (store, personInfo) => {
  const variables = personInfo;

  try {
    const { data } = await GraphQLClient.mutate({ mutation, variables });
    return data.CreatePerson;
  } catch (err) {
    const jsonResponse = JSON.parse(JSON.stringify(err));
    return Promise.reject(jsonResponse.graphQLErrors[0].message);
  }
};

export default CreatePatient;
