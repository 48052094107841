import {
  GraphQLClient,
  gql,
  removeTypename,
} from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation($transference: TransferenceInputType) {
    SaveTransferenceForm(transference: $transference) {
      tfn_id
    }
  }
`;

export default async ({ commit }, pData) => {
  try {
    const variables = removeTypename(pData);
    const { data } = await GraphQLClient.mutate({ mutation, variables });
    return { errors: null, data };
  } catch (err) {
    if (err.graphQLErrors) {
      try {
        return { errors: JSON.parse(err.graphQLErrors[0].message), data: null };
      } catch (e) {
        return { errors: err.graphQLErrors, data: null };
      }
    }
  }
};
