import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async ({ intIdSetor, intVusId, strNomeProcedimento }) => {
  return AuthLegacyRequest.post(
    "exames2/controller-exame-agendamento-procedimento/combo-box-convenios-com-procedimento-e-profissional",
    { intIdSetor, intVusId, strNomeProcedimento },
  )
    .then(({ data }) => {
      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
