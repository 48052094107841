<template lang="html">
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 20.002 20.002"
    style="enable-background: new 0 0 20.002 20.002"
    xml:space="preserve"
  >
    <g>
      <path
        d="M19.557,9.155l-1.849-1.232l0.984-1.993c0.148-0.3,0.137-0.654-0.03-0.943
    c-0.168-0.29-0.468-0.477-0.802-0.498l-2.218-0.143l-0.144-2.218c-0.02-0.334-0.208-0.635-0.497-0.802
    c-0.29-0.167-0.645-0.18-0.943-0.03l-1.991,0.985l-1.233-1.849c-0.371-0.557-1.293-0.557-1.664,0L7.936,2.28L5.944,1.296
    c-0.299-0.15-0.654-0.137-0.943,0.03c-0.29,0.167-0.477,0.468-0.498,0.802L4.36,4.345L2.142,4.488
    C1.808,4.51,1.507,4.697,1.34,4.986S1.161,5.63,1.31,5.929l0.984,1.993L0.445,9.155C0.167,9.341,0,9.653,0,9.987
    s0.167,0.646,0.445,0.832l1.85,1.233L1.31,14.044c-0.148,0.3-0.137,0.654,0.03,0.943s0.468,0.477,0.802,0.498l2.218,0.143
    l0.143,2.218c0.021,0.333,0.208,0.634,0.498,0.801s0.642,0.179,0.943,0.031l1.992-0.985l1.233,1.849
    c0.186,0.278,0.498,0.445,0.832,0.445s0.646-0.167,0.832-0.445l1.233-1.849l1.991,0.985c0.299,0.148,0.653,0.136,0.943-0.03
    c0.29-0.167,0.477-0.468,0.498-0.802l0.143-2.217l2.219-0.144c0.334-0.021,0.635-0.208,0.802-0.498s0.179-0.644,0.03-0.943
    l-0.984-1.992l1.849-1.233c0.278-0.186,0.445-0.498,0.445-0.832C20.002,9.653,19.835,9.34,19.557,9.155z M15.525,12.152l0.71,1.435
    l-1.6,0.104c-0.502,0.033-0.901,0.432-0.934,0.934l-0.103,1.598l-1.435-0.709c-0.45-0.224-0.996-0.077-1.275,0.342l-0.887,1.33
    l-0.889-1.333c-0.191-0.287-0.508-0.445-0.833-0.445c-0.149,0-0.3,0.033-0.442,0.104l-1.436,0.709l-0.103-1.598
    c-0.032-0.501-0.432-0.901-0.934-0.934l-1.596-0.103l0.71-1.435c0.223-0.451,0.076-0.997-0.342-1.275L2.803,9.987l1.332-0.888
    C4.553,8.82,4.699,8.274,4.477,7.824l-0.71-1.436l1.6-0.103c0.502-0.033,0.901-0.432,0.934-0.934l0.103-1.598l1.435,0.709
    C8.287,4.683,8.835,4.538,9.114,4.12l0.887-1.331l0.889,1.333c0.279,0.418,0.826,0.563,1.275,0.342l1.436-0.71l0.104,1.599
    c0.033,0.501,0.433,0.9,0.934,0.933l1.598,0.103l-0.709,1.437c-0.223,0.451-0.076,0.996,0.342,1.275l1.332,0.888l-1.333,0.889
    C15.449,11.155,15.303,11.701,15.525,12.152z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "GeometrySuperStar",
};
</script>
