module.exports = `
icf_id
icf_nome
icf_nome_mae
icf_nome_responsavel
icf_cns
icf_sexo
icf_data_nascimento
icf_cod_nacionalidade
icf_cod_raca
icf_cod_etnia
icf_cep
icf_cod_municipio
icf_cod_tipo_logradouro
icf_logradouro_numero
icf_logradouro
icf_complemento
icf_bairro
icf_uf
icf_tel
icf_tel_ddd
icf_tipo_documento
icf_documento
icf_indigente
`;
