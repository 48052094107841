import AuthRequest from "~common/request/AuthRequest";

export default (pData) => {
  if (!pData) return Promise.result(false);
  return AuthRequest.get(
    "billing/bulletin-billing/find-bulletin-billing-with-procedure",
    pData,
  ).then((pResult) => {
    return pResult.data;
  });
};
