<template lang="html">
  <fieldset class="rg-radio-hospitalization-aih">
    <span class="label">Listar apenas :</span>
    <div class="container">
      <div
        :class="{ 'active-aih': selectedStatus.includes(AIH_INFO.COM_AIH) }"
        class="radio show-aih"
        @click="toggleStatus(AIH_INFO.COM_AIH)"
      >
        Com AIH
      </div>
      <div
        :class="{ 'active-out-aih': selectedStatus.includes(AIH_INFO.SEM_AIH) }"
        class="radio show-out-aih"
        @click="toggleStatus(AIH_INFO.SEM_AIH)"
      >
        Sem AIH
      </div>
    </div>
  </fieldset>
</template>

<script>
import AIH_INFO from "./getHospitalizationAihInfo";

export default {
  name: "RgRadioHospitalizationAih",
  props: {
    value: {
      default: null,
    },
  },
  data() {
    return {
      selectedStatus: [],
    };
  },
  computed: {
    AIH_INFO() {
      return { ...AIH_INFO };
    },
  },
  watch: {
    value(pValue) {
      this.selectedStatus = pValue;
    },
  },
  mounted() {
    this.selectedStatus = this.value || [1, 2];
  },
  methods: {
    toggleStatus(pItem) {
      if (this.selectedStatus.includes(pItem)) {
        this.selectedStatus.splice(this.selectedStatus.indexOf(pItem), 1);
      } else {
        this.selectedStatus.push(pItem);
      }
      this.$emit("input", this.selectedStatus);
    },
  },
};
</script>
