<template>
  <div class="exam-schedule-queue">
    <RgSearch
      ref="rgsearch"
      v-model="mutableExamQueue"
      v-bind="propsRGSearch"
      @viewClickPagination="viewClickPagination"
      @submitFromButton="actionCleanSelection"
    >
      <div slot="filters" class="exam-schedule-queue-filter">
        <div class="grid">
          <div class="selectinput">
            <RgComboboxProceduresQueue
              ref="comboProceduresQueueByUnit"
              v-model="form.procedureId"
              class="inputitem"
              label="Procedimento"
              default-text="Selecione"
              :disabled="disabledByModal"
            />
          </div>

          <div class="selectinput">
            <RgComboboxSubproceduresQueue
              ref="comboProcedures"
              v-model="form.subprocedureId"
              class="inputitem"
              label="Subprocedimento"
              :procedureName="form.procedureName"
              :disabled="!form.procedureId || disabledByModal"
              default-text="Todos"
            />
          </div>

          <div class="selectinput">
            <RgComboboxUnithealthQueue
              ref="unitHealth"
              v-model="form.unit"
              :unit-health-id="getUnitHealthId"
              :module-id="examModuleId"
              :hasRegularPermission="hasRegularPermission"
              :hasViewRequestUnitHealthPermission="
                hasViewRequestUnitHealthPermission
              "
              label="Unidade de Saúde Solicitante"
              class="inputitem"
              :default-text="'Todas'"
              :disabled="disabledByModal"
            />
          </div>

          <div class="filter-title">
            <span class="title">Dados do Paciente</span>
          </div>

          <div class="selectinput">
            <RgInput
              ref="patient"
              v-model="form.patientName"
              :disabled="disableNameInput"
              :class="{ disable: disableNameInput }"
              class="inputitem"
              placeholder="Digite o nome do paciente"
              label="Paciente"
            />
          </div>

          <div class="selectinput">
            <RgSuggestSmartPerson
              ref="document"
              v-model="form.document"
              :disabled="disableDocumentInput"
              :class="{ disable: disableDocumentInput }"
              :with-patient-module="['exam2']"
              :enabled-patient="false"
              :rules="{ forceSelection: true }"
              filterOnlyDocument
              class="inputitem"
              @selected="selectingPatient"
            />
          </div>

          <div class="selectinput">
            <RgComboboxPriority
              v-model="form.priority"
              :module-id="33"
              label="Prioridade"
              default-text="Todas"
              class="inputitem"
              :disabled="disabledByModal"
            />
          </div>

          <div class="selectinput">
            <RgComboboxSituation
              v-model="form.situation"
              :default-text="'Todas'"
              label="Situação"
              class="inputitem"
              :disabled="disabledByModal"
            />
          </div>

          <div class="selectinput">
            <RgSuggestState
              ref="state"
              v-model="form.est_sigla"
              label="Estado"
              class="inputitem state"
              placeholder="Digite o estado"
              :disabled="disabledByModal"
              @selected="selectingState"
            />
          </div>

          <div class="selectinput">
            <RgSuggestCity
              v-model="form.mun_nome"
              :class="{ disable: disableCounty }"
              :disabled="disableCounty || disabledByModal"
              :state="form.est_id"
              label="Município"
              placeholder="Digite o município"
              class="inputitem city"
              @selected="selectingMunicipality"
            />
          </div>

          <div class="filter-title">
            <span class="title">Período do Pedido</span>
          </div>

          <div class="selectinput">
            <div class="period">
              <RgInputDate
                v-model="form.initialOrderPeriod"
                :rules="{ fn: verifyOrderInitialDate }"
                placeholder="dd/mm/aaaa"
                label="Data Inicial"
                class="date"
                :disabled="disabledByModal"
              />

              <RgInputDate
                v-model="form.finalOrderPeriod"
                placeholder="dd/mm/aaaa"
                label="Data Final"
                class="date"
                :disabled="disabledByModal"
              />
            </div>
          </div>

          <div class="filter-title">
            <span class="title">Período de Inclusão na Fila</span>
          </div>

          <div class="selectinput">
            <div class="period">
              <RgInputDate
                v-model="form.initialInclusionDate"
                :rules="{ fn: verifyInclusionInitialDate }"
                placeholder="dd/mm/aaaa"
                label="Data Inicial"
                class="date"
                :disabled="disabledByModal"
              />

              <RgInputDate
                v-model="form.finalInclusionDate"
                placeholder="dd/mm/aaaa"
                label="Data Final"
                class="date"
                :disabled="disabledByModal"
              />
            </div>
          </div>

          <div class="selectinput">
            <RgRadioList
              v-model="form.filterRadio"
              label="Listar agendamentos possíveis"
              :items="itemsRadioFilter"
              :disabled="disabledByModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="mutableExamQueue.length <= 0"
        slot="menu-top"
        class="top-button"
      >
        <RgNewButton
          large
          title="Inserir na fila"
          class="item"
          :class="{ insert: !hasExamQueue }"
          :disabled="disabledByModal"
          :permission="hasInsertPermission"
          @click="registrationQueue"
        />
      </div>

      <div class="menu-top">
        <div v-show="hasExamQueue" class="top-btn">
          <div v-if="!equalListSize" class="select-btn" @click="selectAllList">
            <IconBox />
          </div>

          <div v-if="equalListSize" class="select-btn" @click="unselectAllList">
            <IconCheckedBox />
          </div>

          <div
            class="select-btn"
            title="Inverter seleção"
            @click="reverseListSelection"
          >
            <IconArrowBox />
          </div>

          <p class="text">
            Linhas Selecionadas:
            {{ selectedRows.length }}
          </p>

          <div class="position-btn">
            <input
              v-model="queueAmountAdvance"
              :disabled="!hasOnlyOneLineSelected"
              :class="{ disable: !hasOnlyOneLineSelected }"
              class="field"
              maxlength="3"
              title="Informe o tamanho de intervalo para o passo de subida na fila"
            />
            <SmallButton
              :disabled="!hasOnlyOneLineSelected"
              :backgroundColor="'#f96b70'"
              :permission="hasRegularPermission"
              @click="advancePatientInQueue"
            >
              <IconArrowSmall slot="icon" class="svg" />
            </SmallButton>

            <input
              v-model="queueAmountBack"
              :disabled="!hasOnlyOneLineSelected"
              :class="{ disable: !hasOnlyOneLineSelected }"
              class="field"
              maxlength="3"
              title="Informe o tamanho de intervalo para o passo de descida na fila"
            />
            <SmallButton
              :disabled="!hasOnlyOneLineSelected"
              :backgroundColor="'#f96b70'"
              :permission="hasRegularPermission"
              @click="backPatientInQueue"
            >
              <IconArrowSmall slot="icon" class="svg arrow-invert" />
            </SmallButton>

            <input
              v-model="queuePosition"
              :disabled="!hasOnlyOneLineSelected"
              :class="{ disable: !hasOnlyOneLineSelected }"
              class="field large"
              maxlength="3"
              title="Informe o posição deseja para mover o registro na fila"
            />
            <SmallButton
              :disabled="!hasOnlyOneLineSelected"
              :backgroundColor="'#1e88a9'"
              :permission="hasRegularPermission"
              @click="movePatientInQueue"
            >
              <IconArrowDouble slot="icon" class="svg" />
            </SmallButton>
          </div>
        </div>

        <div class="more-buttons">
          <RgNewButton
            small
            title="Inserir na fila"
            class="insert-button"
            :class="{ insert: !hasExamQueue }"
            :disabled="disabledByModal"
            :permission="hasInsertPermission"
            @click="registrationQueue"
          />

          <RgLessButton
            v-show="hasExamQueue"
            :disabled="!enableButtonForSelectedLines || disabledByModal"
            :permission="hasDeletePermission"
            title="Excluir Paciente da Fila"
            @click="openModalConfirmQueueDeletion"
          />

          <RgEditButton
            v-show="hasExamQueue"
            :disabled="!hasOnlyOneLineSelected || disabledByModal"
            :permission="hasEditPermission"
            title="Editar Fila"
            @click="alterQueue"
          />

          <RgShowButton
            v-show="hasExamQueue"
            :disabled="!hasOnlyOneLineSelected || disabledByModal"
            title="Visualizar paciente na fila"
            @click="openModalPatientImageInRow"
          />

          <RgPrinterButton
            v-show="hasExamQueue"
            :disabled="!hasOnlyOneLineSelected || disabledByModal"
            title="Imprimir o comprovante de paciente na fila"
            @click="printProofInsertionQueue"
          />

          <RgDropdown
            v-show="hasExamQueue"
            :disabled="!hasOnlyOneLineSelected || disabledByModal"
            :item="listSelected[0] || {}"
            :action-options="optionsDropdownMore(listSelected)"
            class="dropdown-more"
          />

          <div
            v-if="mutableExamQueue && mutableExamQueue.length > 0"
            class="dropdown-button box-scroll"
          >
            <div class="button">
              <span class="text unselect">Colunas</span>
              <IconArrow class="svg" />
            </div>

            <div class="dropdown dropdown-absolute">
              <span class="label unselect">Colunas Exibidas</span>

              <label
                v-for="(col, index) in activeHeader"
                :key="col.key"
                :class="{ 'disable unselect': hasOnlyOneActiveHeader }"
                :disabled="hasOnlyOneActiveHeader"
                class="item actives unselect"
              >
                {{ col.name }}
                <input
                  :id="index"
                  :checked="col.active"
                  class="checkbox"
                  type="checkbox"
                  @change="toogleStatus(col, col.key)"
                />
              </label>

              <span class="label unselect">Colunas Não Exibidas</span>

              <label
                v-for="(col, index) in inactiveHeader"
                :key="col.key"
                class="item inactives unselect"
              >
                {{ col.name }}
                <input
                  :id="index"
                  :checked="col.active"
                  class="checkbox"
                  type="checkbox"
                  @change="toogleStatus(col, col.key)"
                />
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="exam-schedule-queue-result">
        <div class="content-table">
          <table class="table">
            <tr>
              <th class="title-table" />
              <th
                v-for="(col, index) in header"
                v-show="col.active"
                :key="index"
                class="title-table"
                :style="{ textAlign: col.align }"
              >
                {{ col.name }}
              </th>
            </tr>

            <tr
              v-for="(item, index) in mutableExamQueue"
              :key="index"
              :class="{ selected: selectedRows.includes(item.fil_id) }"
              class="tr row-content"
              @click="getValueRow(item, index)"
            >
              <td class="icon-check">
                <div v-if="selectedRows.includes(item.fil_id)" class="check">
                  <IconCheck />
                </div>
              </td>

              <td
                v-for="(col, index) in header"
                v-show="col.active"
                :key="index"
                class="result"
                :style="{ textAlign: col.align }"
                :class="{
                  priority: item[col.key] === item.pfi_nome,
                }"
              >
                <span :class="{ breath: item[col.key] === item.pfi_nome }">
                  {{ item[col.key] || "-" }}
                </span>
                <div
                  v-if="item[col.key] === item.pfi_nome"
                  class="circle"
                  :title="item.pfi_nome"
                  :style="{
                    background: priorityColor(item),
                  }"
                ></div>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div v-show="hasExamQueue" slot="menu-bottom" class="painel-button">
        <div class="others-btn">
          <RgHourglassButton
            :disabled="!hasOnlyOneLineSelected || disabledByModal"
            small
            title="Pendência"
            class="bottom hourglass"
            @click="pendenciesQueue"
          />

          <RgRegulateButton
            small
            title="Regular"
            class="bottom regulate"
            :disabled="
              !enableButtonForSelectedLines ||
              disabledRegulateButton ||
              disabledByModal
            "
            :permission="hasRegularPermission"
            @click="regulateAct"
          />

          <RgUnregulateButton
            small
            title="Desregular"
            class="bottom unregulate"
            :disabled="
              !enableButtonForSelectedLines ||
              disabledUnregulateButton ||
              disabledByModal
            "
            :permission="hasRegularPermission"
            @click="unRegulateAct"
          />

          <RgNewScheduleButton
            :disabled="!enableButtonForSelectedLines || disabledByModal"
            :permission="hasSchedulePermission"
            small
            title="Agendar"
            class="bottom"
            @click="scheduleRegulationQueue"
          />

          <RgExternScheduleButton
            :disabled="!enableButtonForSelectedLines || disabledByModal"
            :permission="hasExternalSchedulePermission"
            small
            title="Agendar externo"
            class="bottom"
            @click="clickExternalSchedule"
          />
        </div>
      </div>
    </RgSearch>

    <ModalConfirmDeletion
      v-bind="propsModalConfirmDeletion"
      @close="closeModalConfirmQueueDeletion"
      @reSearch="fillFilter"
    />

    <ModalPatientImageInRow
      ref="modalPatientImageInRow"
      :show="modalPatientImageInRow"
      :fill-id="Number(selectedRows[0])"
      :modId="examModuleId"
      @reSearch="reSearchAct"
      @close="closeModalPatientImageInRow"
    />

    <ModalTypesPrint
      ref="modalTypePrint"
      :show="modalTypesPrint"
      :exam-ids="examIdsToPrint"
      :is-in-batch="true"
      @close="closeModalTypesPrint"
      @printed="printed"
    />

    <ModalTypePrintInsertQueue
      :show="modalPrint"
      :filId="filId || 0"
      @close="closeModalPrint"
    />

    <ModalSimpleHistory
      :show="showModalSimpleHistory"
      :filter="filterHistoryQueue"
      @close="showModalSimpleHistory = false"
    />
  </div>
</template>

<script>
import {
  RgInputDate,
  RgComboboxUnithealthQueue,
  RgNewButton,
  RgNewScheduleButton,
  RgDropdown,
  RgHourglassButton,
  RgRegulateButton,
  RgUnregulateButton,
  RgExternScheduleButton,
  RgSuggestSmartPerson,
  IconCheck,
  RgEditButton,
  RgPrinterButton,
  RgShowButton,
  IconArrow,
  IconCheckedBox,
  IconArrowBox,
  IconArrowSmall,
  IconArrowDouble,
  IconBox,
  RgLessButton,
  RgComboboxSituation,
  RgComboboxPriority,
  RgInput,
  SmallButton,
} from "~tokio/primitive";

import RgSuggestState from "$person/common/components/suggest/rg-suggest-state/RgSuggestState";
import ModalTypePrintInsertQueue from "$exam/submodules/schedule/component/modal/modal-types-print-insert-queue/ModalTypesPrintInsertQueue";

import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";
import {
  ModalPatientImageInRow,
  ModalConfirmDeletion,
} from "~tokio/primitive/modal";
import { RgSuggestCity } from "$patient/common/components";
import RgSearch from "~tokio/foundation/rg-search/RgSearch";
import { AlertError } from "~tokio/primitive/notification";
import RgComboboxProceduresQueue from "$exam/submodules/schedule/component/combobox/rg-combobox-procedures-queue/RgComboboxProceduresQueue";
import RgComboboxSubproceduresQueue from "$exam/submodules/schedule/component/combobox/rg-combobox-subprocedures-queue/RgComboboxSubproceduresQueue";
import RgRadioList from "~tokio/primitive/radio/rg-radio-list/RgRadioList.vue";

import { ModalTypesPrint } from "$exam/submodules/schedule/component/";

import ModalSimpleHistory from "$queue/common/components/modal/modal-simple-history/ModalSimpleHistory";
import moment from "moment";
import { mapGetters } from "vuex";

import {
  ScheduleVoucherHTML,
  ScheduleThermalOneWayHTML,
} from "$exam/submodules/schedule/html";

const baseForm = {
  procedureId: null,
  procedureName: "",
  subprocedureName: "",
  subprocedure: null,
  unit: null,
  priority: null,
  situation: null,
  est_id: null,
  est_sigla: null,
  mun_id: null,
  mun_nome: null,
  initialOrderPeriod: null,
  finalOrderPeriod: null,
  initialInclusionDate: null,
  finalInclusionDate: null,
  filterRadio: null,
  patientName: null,
  patientId: null,
  document: null,
};

export default {
  name: "ExamScheduleQueue",
  components: {
    RgSearch,
    RgInputDate,
    RgSuggestState,
    RgSuggestCity,
    RgComboboxUnithealthQueue,
    RgComboboxPriority,
    RgNewButton,
    RgComboboxSituation,
    RgNewScheduleButton,
    RgHourglassButton,
    RgRegulateButton,
    RgUnregulateButton,
    RgExternScheduleButton,
    RgSuggestSmartPerson,
    IconCheck,
    IconArrow,
    RgInput,
    IconArrowSmall,
    IconArrowBox,
    IconBox,
    IconArrowDouble,
    IconCheckedBox,
    RgEditButton,
    RgShowButton,
    RgPrinterButton,
    RgLessButton,
    ModalPatientImageInRow,
    ModalConfirmDeletion,
    RgDropdown,
    ModalTypesPrint,
    ModalTypePrintInsertQueue,
    RgComboboxSubproceduresQueue,
    RgComboboxProceduresQueue,
    RgRadioList,
    ModalSimpleHistory,
    SmallButton,
  },

  data() {
    return {
      selectedRows: [],
      listSelected: [],
      mutableExamQueue: [],
      queueData: {},
      header: this.COLUMNS,
      unitLogged: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
      form: Object.assign({}, baseForm),
      filId: null,
      modalConfirmQueueDeletion: false,
      modalPatientImageInRow: false,
      disabledRegulateButton: false,
      disabledUnregulateButton: false,
      modalPrint: false,
      modalTypesPrint: false,
      examIdsToPrint: null,
      disableNameInput: false,
      disableDocumentInput: false,
      queueAmountAdvance: 1,
      queueAmountBack: 1,
      queuePosition: 1,
      itemsRadioFilter: [
        {
          value: 1,
          text: "Sim",
        },
        {
          value: 2,
          text: "Não",
        },
        {
          value: 0,
          text: "Indiferente",
        },
      ],
      showModalSimpleHistory: false,
      selectedQueueOrder: [],
    };
  },

  computed: {
    ...mapGetters({
      userLoginId: "Login/GET_USER_ID",
      unitHealthId: "Login/GET_UNIT_HEALTH_ID",
      getColumns: "User/GET_SMART_TABLE_COLUMNS",
    }),

    disabledByModal() {
      return (
        this.modalConfirmQueueDeletion ||
        this.modalPatientImageInRow ||
        this.modalTypesPrint ||
        this.modalPrint
      );
    },

    propsRGSearch() {
      const buildFilter = () => this.generateFilter();
      const searchFunction = (data) => this.actionsSearchFilter(data);
      const clearFunction = () => this.actionCleanFilterForm();
      const showFooter = this.hasExamQueue;
      const maxRegister = 30;
      const itemHeight = 40.6;
      const backendLegacy = true;
      const showTitle = true;
      const showEmptyMessage = true;
      const resultTitle = "Regulação";

      return {
        buildFilter,
        searchFunction,
        clearFunction,
        itemHeight,
        backendLegacy,
        maxRegister,
        showTitle,
        showEmptyMessage,
        showFooter,
        resultTitle,
      };
    },

    propsModalConfirmDeletion() {
      const maxlength = 250;
      const title = "Excluir Paciente(s) da Fila";
      const subtitle = "A operação não poderá ser desfeita";
      const show = this.modalConfirmQueueDeletion;
      const confirm = (reason) => this.deleteQueue(reason);

      return { show, confirm, maxlength, title, subtitle };
    },

    examModuleId() {
      return this.$store.state.Login.route_module_map.exam;
    },
    activeHeader() {
      const isHeaderFilled = this.header && this.header.length > 0;
      if (isHeaderFilled) {
        return this.header.filter((item) => item.active);
      }
      return false;
    },

    inactiveHeader() {
      const isHeaderFilled = this.header && this.header.length > 0;
      if (isHeaderFilled) {
        return this.header.filter((item) => !item.active);
      }
      return false;
    },

    hasOnlyOneActiveHeader() {
      return this.activeHeader.length === 1;
    },

    enableButtonForSelectedLines() {
      return this.selectedRows.length > 0;
    },

    hasOnlyOneLineSelected() {
      return this.selectedRows.length === 1;
    },

    hasExamQueue() {
      return this.mutableExamQueue.length > 0;
    },

    disableCounty() {
      return !this.form.est_id;
    },

    printOptionsPreference() {
      return this.$store.getters["Login/GET_PREFERENCES"][
        "tViewBuscaFilaExame.opcoesImpressao"
      ];
    },

    equalListSize() {
      return this.listSelected.length === this.mutableExamQueue.length;
    },

    filterHistoryQueue() {
      if (this.listSelected[0] === undefined) {
        return {};
      }
      const { fil_id, pes_id, pes_nome } = this.listSelected[0];
      return { fil_id, pes_id, pes_nome };
    },

    hasInsertPermission() {
      return !!this.$Permissions.global.has("exame2.filaExame.incluir");
    },

    hasEditPermission() {
      return !!this.$Permissions.global.has("exame2.filaExame.alterar");
    },

    hasDeletePermission() {
      return !!this.$Permissions.global.has("exame2.filaExame.excluir");
    },

    hasSchedulePermission() {
      return !!this.$Permissions.global.has("exame2.filaExame.agendar");
    },

    hasSchedulePermissionOnePatient() {
      return !!this.$Permissions.global.has(
        "exame2.filaExame.agendarPacienteForaTopoFila",
      );
    },

    hasSchedulePermissionOnePatientNotFirst() {
      return !!this.$Permissions.global.has(
        "exame2.filaExame.agendarPacienteForaTopoFila",
      );
    },

    hasSchedulePermissionBatch() {
      return !!this.$Permissions.global.has(
        "exame2.filaExame.agendarPacientesPelaFilaEmLote",
      );
    },

    hasRegularPermission() {
      return !!this.$Permissions.global.has("exame2.filaExame.regular");
    },

    hasExternalSchedulePermission() {
      return !!this.$Permissions.global.has(
        "exame2.filaExame.agendarExternamente",
      );
    },

    hasViewRequestUnitHealthPermission() {
      return !!this.$Permissions.global.has(
        "exame2.filaExame.visualizarUnidadeSolicitante",
      );
    },

    getUnitHealthId() {
      return this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    },
  },

  watch: {
    "form.subprocedureId"(pValue) {
      if (!pValue) {
        this.form.subprocedureName = null;
      }

      if (pValue[0]?.text) {
        this.form.subprocedureName = pValue[0].text;
      }
    },

    "form.procedureId"(pValue) {
      if (!pValue) {
        this.form.procedureName = null;
      } else {
        if (pValue[0]?.procedure) {
          this.form.procedureName = pValue[0].procedure;
        } else if (pValue[0]?.text) {
          this.form.procedureName = pValue[0].text;
        }
      }
    },

    "form.est_id"(pValue, pPrev) {
      if (!pValue || pValue !== pPrev) {
        this.form.mun_id = null;
        this.form.mun_nome = "";
      }
    },

    "form.patientName"(pValue) {
      if (pValue && pValue.length > 0) {
        this.form.document = null;
        this.form.patientId = null;
        this.disableDocumentInput = true;
      } else {
        this.disableDocumentInput = false;
      }
    },

    "form.document"(pValue) {
      if (pValue && pValue.length > 0) {
        this.disableNameInput = true;
        this.form.patientName = null;
      } else {
        this.disableNameInput = false;
      }
    },

    listSelected(pValue) {
      const isRegulate = pValue.every((item) => {
        return item.fep_ordem_regulada !== "0";
      });

      const isUnregulate = pValue.every((item) => {
        return item.fep_ordem_regulada === "0";
      });

      if (isRegulate && !isUnregulate) {
        this.disabledRegulateButton = true;
        this.disabledUnregulateButton = false;
      }

      if (!isRegulate && isUnregulate) {
        this.disabledRegulateButton = false;
        this.disabledUnregulateButton = true;
      }

      if (!isRegulate && !isUnregulate) {
        this.disabledRegulateButton = true;
        this.disabledUnregulateButton = true;
      }
    },

    activeHeader(pValue, pPrev) {
      if (pPrev && pValue[0].key !== "without_result") {
        const link = this.$route.path;

        this.$store.commit("User/SET_SMART_TABLE_COLUMNS", {
          link,
          usu_id: this.userLoginId,
          uns_id: this.unitHealthId,
          columns: pValue,
          name: this.name,
        });
      }
    },
  },

  created() {
    this.COLUMNS = [
      { name: "Ordem", key: "ordem_regulada", align: "center" },
      { name: "Pendência", key: "pendencia", align: "center" },
      { name: "Prioridade", key: "pfi_nome", align: "center" },
      { name: "Paciente", key: "pes_nome", align: "left" },
      { name: "Telefone", key: "telefone", align: "center" },
      { name: "Idade", key: "idade", align: "center" },
      { name: "Município", key: "mun_nome", align: "left" },
      { name: "Procedimento", key: "nome", align: "left" },
      { name: "SubProcedimentos", key: "subprocedimentos", align: "left" },
      { name: "Anexo", key: "anexo", align: "center" },
      { name: "Data do Pedido", key: "fil_data_pedido", align: "center" },
      { name: "Data na Fila", key: "fil_data", align: "center" },
      { name: "Unidade de Saúde Inserção", key: "unidade_fila", align: "left" },
      {
        name: "Unidade de Saúde Solicitante",
        key: "uns_solicitante",
        align: "left",
      },
      { name: "Usuário", key: "usu_nome", align: "left" },
    ];
  },

  mounted() {
    this.$store.commit("Person/Patient/DESTROY_LAST_PERSON_ID_SAVE");
    this.$store.commit("Exam/Schedule/UNSELECT_PATIENT_LIST_QUEUE_DATA");

    const isExamSchedule = this.$route.params.isExamSchedule;
    if (isExamSchedule) {
      this.viewExamScheduleVoucher(this.$route.params.examId);
    }

    this.mountTableHeader(this.COLUMNS, 6);
    this.fillFilter();
  },

  beforeDestroy() {
    const validateIfExist = ValidateIfRouteExistInBreadscrumb(
      "/exam/schedule/queue",
      this.$route.meta.breadcrumb,
    );

    if (!validateIfExist) {
      this.$store.commit("Exam/Schedule/RESET_FILTER_EXAM_QUEUE");
    }
  },

  methods: {
    mountTableHeader(ArrColumns, initialColumns = 1) {
      let header;
      const link = this.$route.path;
      const savedHeader = this.getColumns(
        link,
        this.userLoginId,
        this.unitHealthId,
        this.name,
      );

      if (savedHeader) {
        const activeKey = [];
        savedHeader.columns.forEach((item) => {
          activeKey.push(item.key);
        });

        header = ArrColumns.map((item) => {
          if (activeKey.includes(item.key)) {
            return {
              name: item.name,
              key: item.key,
              align: item.align,
              active: true,
              ...item,
            };
          } else {
            return {
              name: item.name,
              key: item.key,
              align: item.align,
              active: false,
              ...item,
            };
          }
        });
      } else {
        header = ArrColumns.map((item, idx) => {
          const active = idx < initialColumns;
          return {
            name: item.name,
            key: item.key,
            active,
            ...item,
          };
        });
      }

      this.header = header;
      return header;
    },

    async actionsSearchFilter(pData) {
      return this.$store.dispatch("Exam/Schedule/SEARCH_EXAM_QUEUE", pData);
    },

    generateFilter() {
      const variables = {
        arrFormData: {
          fle_nome_procedimento: this.form.procedureName
            ? this.form.procedureName
            : "TODOS",
          esu_nome_exames_procedimentos_filho: this.form.subprocedureName,
          uns_id: this.form.unit || 0,
          uns_atual: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
          pfi_id: this.form.priority,
          situacao: this.form.situation,
          est_id: this.form.est_id,
          mun_id: this.form.mun_id,
          periodoInicial: this.form.initialOrderPeriod,
          periodoInicialFila: this.form.initialInclusionDate,
          periodoFinal: this.form.finalOrderPeriod,
          periodoFinalFila: this.form.finalInclusionDate,
          permissaoRegular: this.hasRegularPermission ? 1 : 0,
          permissaoUnidadeSolicitante: this.hasViewRequestUnitHealthPermission
            ? 1
            : 0,
          somenteRegulados: false,
          rgb_agendado: this.form.filterRadio,
          pes_nome: this.form.patientName,
          pes_id: this.form.patientId,
        },
      };

      this.$store.commit("Exam/Schedule/SET_FILTER_EXAM_QUEUE", this.form);

      return variables;
    },

    async getValueRow(pItem) {
      if (this.selectedRows.includes(pItem.fil_id)) {
        this.selectedRows.splice(this.selectedRows.indexOf(pItem.fil_id), 1);
        // Remoção via objeto estava com problemas, quando executava alguma ação.
        // ... Por isso usei o filter como solução.
        // this.listSelected.splice(this.listSelected.indexOf(pItem), 1);
        this.listSelected = this.listSelected.filter(function (el) {
          return el.fil_id !== pItem.fil_id;
        });
        this.filId = Number(pItem.fil_id) || null;
        this.selectedQueueOrder.splice(
          this.selectedQueueOrder.indexOf(pItem.ordem_regulada),
          1,
        );
      } else {
        this.selectedRows.push(pItem.fil_id);
        this.listSelected.push(pItem);
        this.filId = Number(pItem.fil_id) || null;
        this.selectedQueueOrder.push(pItem.ordem_regulada);
      }
    },

    validateSchedule() {
      const equalsProcedure = this.listSelected.every((item) => {
        return (
          this.listSelected[0].fle_nome_procedimento ===
          item.fle_nome_procedimento
        );
      });

      if (!equalsProcedure) {
        throw new Error(
          "O procedimento dos pacientes selecionados precisam ser iguais",
        );
      }

      const isRegulate = this.listSelected.every((item) => {
        return item.fep_ordem_regulada !== "0";
      });

      if (!isRegulate) {
        throw new Error("Só é possível agendar para pacientes regulados");
      }

      const hasSchedulePermission = this.validateSchedulePermission();

      return equalsProcedure && isRegulate && hasSchedulePermission;
    },

    validateSchedulePermission() {
      // permissões
      const minValueOrder = 1;
      const selectedQueueOrderSort = this.selectedQueueOrder.sort();
      const firstOrderSelected =
        Number(selectedQueueOrderSort[0]) === minValueOrder;

      const hasOnlyOnePatientSelected = this.selectedRows.length === 1;

      if (hasOnlyOnePatientSelected) {
        // fora da odem selecionado e sem permissao de agendar fora do topo da fila
        if (
          !firstOrderSelected &&
          !this.hasSchedulePermissionOnePatientNotFirst
        ) {
          throw new Error("Sem permissão para agendar fora do topo da fila");
        }
      } else {
        // permissao em lote
        if (!this.hasSchedulePermissionBatch) {
          throw new Error("Sem permissão para agendar em lote");
        }

        const allQueueOrder = this.mutableExamQueue.map(
          (row) => row.ordem_regulada,
        );
        // verifica se os pacientes foram selecionados em ordem
        const hasConsectivePatients = this.checkIfArrayHasConsecutiveElements(
          allQueueOrder,
          this.selectedQueueOrder,
        );

        // não tem a permissão para fora do topo, não estão em sequência ou não é o primeiro na ordem
        if (
          !this.hasSchedulePermissionOnePatientNotFirst &&
          (!firstOrderSelected || !hasConsectivePatients)
        ) {
          throw new Error(
            "Sem permissão para agendar fora do topo da fila. Apenas o primeiro e subsequentes são permitidos",
          );
        }
      }
      return true;
    },

    checkIfArrayHasConsecutiveElements(allItens, itensSelected) {
      itensSelected.sort();
      const startIndex = allItens.lastIndexOf(itensSelected[0]);
      let consecutive = true;
      let countFound = 0;
      for (let i = startIndex; i < allItens.length; i++) {
        itensSelected.indexOf(allItens[i]) === -1
          ? (consecutive = false)
          : countFound++;
        if (countFound === itensSelected.length) {
          break;
        }
      }
      return consecutive;
    },

    selectAllList() {
      this.mutableExamQueue.forEach((item) => {
        if (!this.selectedRows.includes(item.fil_id)) {
          this.selectedRows.push(item.fil_id);
          this.listSelected.push(item);
        }
      });
    },

    unselectAllList() {
      this.mutableExamQueue.forEach((item) => {
        if (this.selectedRows.includes(item.fil_id)) {
          this.selectedRows.splice(this.selectedRows.indexOf(item.fil_id), 1);
          this.listSelected.splice(this.listSelected.indexOf(item), 1);
        }
      });
    },

    reverseListSelection() {
      this.mutableExamQueue.forEach((item) => {
        if (this.selectedRows.includes(item.fil_id)) {
          this.selectedRows.splice(this.selectedRows.indexOf(item.fil_id), 1);
          this.listSelected.splice(this.listSelected.indexOf(item), 1);
        } else {
          this.selectedRows.push(item.fil_id);
          this.listSelected.push(item);
        }
      });
    },

    viewClickPagination(pValue) {
      if (pValue) {
        this.unselectAllList();
      }
    },

    toogleStatus(pColumn, pKey) {
      pColumn.active = !pColumn.active;

      const index = this.getHeaderIndex(pKey);
      const activesLength = this.getActiveColumnsLength();
      const maxReached = activesLength > this.MAX_COLUMNS;
      const middle = activesLength / 2;
      const currentIndex = index + 1;

      if (maxReached) {
        const idx =
          currentIndex > middle
            ? this.getFirsActiveColumnIndex()
            : this.getLastActiveColumnIndex();

        this.header[idx].active = false;
      }
    },

    getHeaderIndex(pKey) {
      return this.header.findIndex((item) => item.key === pKey);
    },

    getActiveColumnsLength() {
      return this.getActiveColumnsIndex().length;
    },

    getActiveColumnsIndex() {
      const actives = [];
      this.header.forEach((item, index) => {
        if (item.active) actives.push(index);
      });
      return actives;
    },

    getLastActiveColumnIndex() {
      const activeColumns = this.getActiveColumnsIndex();
      return activeColumns[activeColumns.length - 1];
    },

    getFirsActiveColumnIndex() {
      return this.getActiveColumnsIndex()[0];
    },

    pendenciesQueue() {
      if (this.listSelected && this.listSelected.length > 1) {
        this.$toaster.warning(
          "Para essa operação só é permitida a seleção de um único paciente",
        );
        return;
      }
      this.$store.commit(
        "Exam/Schedule/SELECT_PATIENT_LIST_QUEUE_DATA",
        Object.values(this.listSelected),
      );
      this.$router.push({ name: "exam.schedule.queue.pendencies" });
    },

    registrationQueue() {
      this.$router.push({
        name: "exam.schedule.queue.queue-insert",
        params: { fromRegulation: true },
      });
    },

    alterQueue() {
      if (this.listSelected && this.listSelected.length > 1) {
        this.$toaster.warning(
          "Para essa operação só é permitida a seleção de um único paciente",
        );
        return;
      }
      this.$store.commit(
        "Exam/Schedule/SELECT_PATIENT_LIST_QUEUE_DATA",
        Object.values(this.listSelected),
      );
      this.$router.push({ name: "exam.schedule.queue.edit-insert" });
    },

    async deleteQueue(reason) {
      try {
        const arrQueue = this.listSelected.map((ele) => {
          return ele.fil_id;
        });

        const variables = {
          arrQueue: arrQueue,
          reason: reason,
        };

        await this.$store.dispatch(
          "Exam/Schedule/REMOVE_EXAM_QUEUE",
          variables,
        );

        this.$toaster.success("Paciente(s) removido(s) da fila com sucesso!");
      } catch (pErr) {
        this.$toaster.error(pErr, "Erro ao tentar remover o paciente da fila");
      } finally {
        this.$loader.finish();
      }
    },

    scheduleRegulationQueue() {
      try {
        if (this.validateSchedule()) {
          this.$store.commit(
            "Exam/Schedule/SELECT_PATIENT_LIST_QUEUE_DATA",
            Object.values(this.listSelected),
          );
          this.$router.push({ name: "exam.schedule.queue.schedule" });
          return;
        }
        return;
      } catch (Err) {
        AlertError(Err.message || Err);
      }
    },

    validateExternalSchedule() {
      const isRegulate = this.listSelected.every((item) => {
        return item.fep_ordem_regulada !== "0";
      });

      if (!isRegulate) {
        throw new Error("Só é possível agendar para pacientes regulados.");
      }

      const hasSchedulePermission = this.validateSchedulePermission();

      return isRegulate && hasSchedulePermission;
    },

    clickExternalSchedule() {
      try {
        if (this.validateExternalSchedule()) {
          this.$store.commit(
            "Exam/Schedule/SELECT_PATIENT_LIST_QUEUE_DATA",
            Object.values(this.listSelected),
          );

          this.$router.push({
            name: "exam.schedule.queue.external-schedule-form",
          });
        }
      } catch (Err) {
        AlertError(Err.message || Err);
      }
    },

    priorityColor(pValue) {
      return pValue.pfi_cor.replace("0x", "#");
    },

    selectingPatient(pValue) {
      if (pValue && pValue.source) {
        this.form.patientName = null;
        this.form.patientId = pValue.source.pes_id;
      } else {
        this.form.patientId = null;
      }
    },

    selectingState(pValue) {
      if (pValue && pValue.source) {
        this.form.est_id = pValue.source.est_id;
      } else {
        this.form.est_id = null;
      }
    },

    selectingMunicipality(pValue) {
      if (pValue && pValue.source) {
        this.form.mun_id = pValue.source.mun_id;
      } else {
        this.form.mun_id = null;
      }
    },

    async fillFilter() {
      try {
        const existFilterData = this.$store.getters[
          "Exam/Schedule/GET_FILTER_EXAM_QUEUE"
        ];

        this.$loader.start("Carregando dados da busca de fila");

        if (existFilterData) {
          await this.$refs.state.forceSelection({
            est_id: existFilterData.est_id,
            est_sigla: existFilterData.est_sigla,
          });

          if (this.$refs.document && existFilterData.patientId)
            this.$refs.document.fillPatientById(existFilterData.patientId);

          this.form = existFilterData;

          this.clearRowsSelected();
          await this.$refs.rgsearch.performSearch();
        }
      } catch (Err) {
        this.$toaster.warning("Erro ao carregar os dados da navegação", Err);
      } finally {
        this.$loader.finish();
      }
    },

    async reSearchAct() {
      await this.$refs.rgsearch.performSearch();
    },

    async advancePatientInQueue() {
      try {
        if (this.listSelected[0].ordem_regulada === "EM ESPERA") {
          this.$toaster.warning(
            "Não é possível ordenar um paciente que não esteja regulado.",
          );
          return;
        }

        if (!this.queueAmountAdvance) {
          this.$toaster.warning("Informe a posição na fila.");
          return;
        }

        await this.$store.dispatch("Exam/Schedule/ADVANCE_PATIENT_QUEUE", {
          intIdFila: this.listSelected[0].fil_id,
          intQtdPosicoes: this.queueAmountAdvance,
        });
        await this.$refs.rgsearch.performSearch();
        this.queueAmountAdvance = 1;
        this.$toaster.success("Operação realizada com sucesso!");
      } catch (Err) {
        this.$toaster.error(Err.message || Err);
      }
    },

    async backPatientInQueue() {
      try {
        if (this.listSelected[0].ordem_regulada === "EM ESPERA") {
          this.$toaster.warning(
            "Não é possível ordenar um paciente que não esteja regulado.",
          );
          return;
        }

        if (!this.queueAmountBack) {
          this.$toaster.warning("Informe a posição na fila.");
          return;
        }

        await this.$store.dispatch("Exam/Schedule/BACK_PATIENT_QUEUE", {
          intIdFila: this.listSelected[0].fil_id,
          intQtdPosicoes: this.queueAmountBack,
        });
        await this.$refs.rgsearch.performSearch();
        this.queueAmountBack = 1;
        this.$toaster.success("Operação realizada com sucesso!");
      } catch (Err) {
        this.$toaster.error(Err.message || Err);
      }
    },

    async movePatientInQueue() {
      try {
        const isRegulate = this.listSelected.every((item) => {
          return item.fep_ordem_regulada !== "0";
        });

        if (!isRegulate) {
          throw new Error(
            "Não é possível ordernar um paciente que não esteja regulado.",
          );
        }
        await this.$store.dispatch("Exam/Schedule/MOVE_PATIENT_QUEUE", {
          intIdFila: this.selectedRows[0],
          intPosicao: this.queuePosition,
        });
        await this.$refs.rgsearch.performSearch();
        this.queuePosition = 1;
        this.$toaster.success("Operação realizada com sucesso!");
      } catch (Err) {
        this.$toaster.error(Err.message || Err);
      }
    },

    async regulateAct() {
      try {
        this.hasAnyPendency();

        const onlyWithoutPendency = (ele) =>
          ele.fil_pendencia === "0" || ele.fil_pendencia === "";
        const formatArrayPayload = (ele) => ele.fil_id;

        const arrQueues = this.listSelected
          .filter(onlyWithoutPendency)
          .map(formatArrayPayload);

        if (arrQueues.length > 0) {
          await this.$store.dispatch(
            "Exam/Schedule/REGULATE_EXAM_QUEUE",
            arrQueues,
          );

          this.clearRowsSelected();
          await this.$refs.rgsearch.performSearch();
          this.$toaster.success(
            "Paciente(s) regulado(s) com sucesso.",
            "Sucesso!",
          );
        }
      } catch (Err) {
        this.$toaster.error(Err.message || Err);
      } finally {
        this.$loader.finish();
      }
    },

    async unRegulateAct() {
      try {
        const arrQueues = this.listSelected.map((ele) => {
          return ele.fil_id;
        });

        await this.$store.dispatch(
          "Exam/Schedule/UNREGULATE_EXAM_QUEUE",
          arrQueues,
        );

        this.clearRowsSelected();
        await this.$refs.rgsearch.performSearch();
        this.$toaster.success("Paciente(s) desregulado(s) com sucesso.");
      } catch (Err) {
        this.$toaster.error(Err.message || Err);
      }
    },

    printProofInsertionQueue() {
      this.modalPrint = true;
    },

    optionsDropdownMore(pItem) {
      return [
        {
          label: "Observações do paciente na fila",
          action: this.observationPatient,
          disable: !this.hasOnlyOneLineSelected,
        },
        {
          label: "Histórico do paciente na fila",
          action: this.patientHistoryQueue,
        },
        // {
        //   label: "Histórico de ocupação do paciente",
        //   action: this.patientOccupationHistory,
        // },
      ];
    },

    observationPatient() {
      if (this.filId) {
        const patientData = this.listSelected[0];

        this.$router.push({
          name: "exam.schedule.queue.observation",
          params: { patientData },
        });
      } else {
        this.$toaster.warning(
          "Este paciente não foi cadastrado pela fila de regulação.",
        );
      }
    },

    patientImagesQueue() {
      this.$toaster.warning("Não implementado");
    },

    patientHistoryQueue() {
      this.showModalSimpleHistory = true;
    },

    patientOccupationHistory() {
      this.$toaster.warning("Não implementado");
    },

    openModalConfirmQueueDeletion() {
      this.modalConfirmQueueDeletion = true;
    },

    closeModalConfirmQueueDeletion() {
      this.modalConfirmQueueDeletion = false;
      this.clearRowsSelected();
    },

    openModalPatientImageInRow() {
      this.modalPatientImageInRow = true;
    },

    closeModalPatientImageInRow() {
      this.modalPatientImageInRow = false;
      this.clearRowsSelected();
    },

    openModalTypesPrint() {
      this.modalTypesPrint = true;
    },

    closeModalTypesPrint() {
      this.modalTypesPrint = false;
    },

    clearRowsSelected() {
      this.listSelected = [];
      this.selectedRows = [];
      this.selectedQueueOrder = [];
    },

    closeModalPrint() {
      this.modalPrint = false;
    },

    actionCleanSelection() {
      this.selectedRows = [];
      this.listSelected = [];
      this.selectedQueueOrder = [];
    },

    actionCleanFilterForm() {
      this.selectedRows = [];
      this.listSelected = [];
      this.selectedQueueOrder = [];
      this.mutableExamQueue = [];
      this.queueAmountAdvance = 1;
      this.queueAmountBack = 1;
      this.queuePosition = 1;
      this.form = Object.assign({}, baseForm);
      this.$refs.document.cleanValidate();
    },

    async viewExamScheduleVoucher(examIds) {
      this.examIdsToPrint = examIds;
      switch (this.printOptionsPreference) {
        case "2": // Abre as opções de impressão
          await this.openModalTypesPrint();
          break;
        case "3": // Impressora Térmica
          await this.printThermalOneWay();
          break;
        case "4": // Impressora Normal
          await this.printScheduleVoucher();
          break;
        default:
          break;
      }
    },

    hasAnyPendency() {
      const hasPendency = (element) =>
        element.fil_pendencia !== "0" && element.fil_pendencia !== "";
      const isPendency = this.listSelected.find(hasPendency);
      if (isPendency) {
        this.$toaster.warning(
          "Pacientes com pendências não podem ser regulados.",
          "Atenção!",
        );
      }
    },

    verifyOrderInitialDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialOrderPeriod, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalOrderPeriod, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }
    },

    verifyInclusionInitialDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialInclusionDate, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalInclusionDate, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }
    },

    printed(pIdsExams) {
      this.registerScheduleVoucherBatch(pIdsExams);
    },

    async registerScheduleVoucherBatch(pIdsExams) {
      try {
        await this.$store.dispatch(
          "Exam/Schedule/REGISTER_SCHEDULE_VOUCHER_BATCH",
          pIdsExams,
        );
      } catch (pError) {
        console.error(pError);
        this.$toaster.error(pError.message || pError);
      }
    },

    async printScheduleVoucher() {
      const data = await this.getDataToPrint();
      const html = ScheduleVoucherHTML(data);
      await this.registerScheduleVoucherBatch(this.examIdsToPrint);

      this.$utils.print.printHtml(html);
    },

    async printThermalOneWay() {
      const data = await this.getDataToPrint();
      const html = ScheduleThermalOneWayHTML(data);
      await this.registerScheduleVoucherBatch(this.examIdsToPrint);

      this.$utils.print.printHtml(html);
    },

    async getDataToPrint() {
      const payload = { ids: this.examIdsToPrint };
      const path = "GET_PRINT_SCHEDULE_BATCH";

      return await this.$store.dispatch(`Exam/Schedule/${path}`, payload);
    },
  },
};
</script>
