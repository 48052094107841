<style
  src="./HospitalizationBillingHospitalizationInfo.scss"
  lang="scss"
  scoped
></style>
<template lang="html">
  <section class="hospitalization-billing-hospitalization-info">
    <RgForm
      ref="form"
      v-shortkey="['ctrl', 's']"
      :action-external-disabled-status="!canSave"
      :show-next-button="true"
      class="form"
      @submit="saveHospitalizationBillingInfoTab"
      @shortkey.native="saveByShortKey"
      @nextClicked="increaseStepProgress"
    >
      <div class="row">
        <RgTypeaheadProcedure
          ref="requestedProcedure"
          v-model="hospitalizationInfoForm.inf_procedimento_solicitado"
          :rules="{ fn: validateRequestedProcedure }"
          :type="'AIH_PRINCIPAL'"
          class="col-3"
          label="Procedimento Solicitado:"
          placeholder="Digite a sugestão"
          @valueChange="validateProcedures($event, 'solicitada')"
        />

        <RgTypeaheadProcedure
          ref="mainProcedure"
          v-model="hospitalizationInfoForm.inf_procedimento_principal"
          :rules="{ fn: validateMainProcedure }"
          :type="'AIH_PRINCIPAL'"
          class="col-3"
          label="Procedimento Principal:"
          placeholder="Digite a sugestão"
          @valueChange="validateProcedures($event, 'principal')"
        />

        <RgComboboxHospitalizationModality
          v-model="hospitalizationInfoForm.inf_codigo_modalidade"
          :rules="{ fn: validateModality }"
          class="col-3"
          label="Modalidade:"
        />

        <RgTypeaheadHospitalizationBedSpecialty
          ref="bedSpecialty"
          v-model="hospitalizationInfoForm.inf_codigo_especialidade_leito"
          :rules="{ fn: validateBedTypeProcedure }"
          class="col-3"
          label="Especialidade do Leito:"
        />
      </div>

      <div class="row">
        <RgComboboxHospitalizationServiceCharacter
          v-model="hospitalizationInfoForm.inf_codigo_carater_internacao"
          :rules="requiredRule"
          class="col-3"
          label="Carater de Atendimento:"
        />

        <RgSuggestCid
          ref="mainCid"
          v-model="mainCid"
          :procedure="hospitalizationInfoForm.inf_procedimento_principal"
          :rules="requiredRule"
          class="col-3"
          label="CID Principal:"
          @cid-code="updateCidCode"
        />

        <RgTypeaheadHospitalizationReasonForClosure
          v-model="hospitalizationInfoForm.inf_motivo_encerramento"
          :rules="{ required: validationTabs, fn: validateReasonForClosure }"
          class="col-3"
          label="Motivo de Encerramento:"
        />

        <RgSuggestEmployee
          ref="requester"
          v-model="requester"
          :rules="{ fn: validateRequester }"
          :caption-raw="professionalCaptionRaw"
          class="col-3"
          label="Doc. do Prof. Solici:"
          placeholder="Nº CNS - Nome do Profissional"
          @selected="selectedEmployee($event, 'REQUESTER')"
        />
      </div>

      <div class="row">
        <RgSuggestEmployee
          ref="responsible"
          v-model="responsible"
          :rules="{ fn: validateResponsible }"
          :caption-raw="professionalCaptionRaw"
          class="col-3"
          label="Doc. do Prof. Resp:"
          placeholder="Nº CNS - Nome do Profissional"
          @selected="selectedEmployee($event, 'RESPONSIBLE')"
        />

        <RgSuggestEmployeeAuthorizer
          ref="authorizer"
          v-model="authorizer"
          :rules="{ fn: validateAutorizer }"
          :caption-raw="professionalCaptionRaw"
          class="col-3"
          label="Doc. do Autorizador:"
          placeholder="Nº CNS - Nome do Profissional"
          @selected="selectedEmployee($event, 'AUTHORIZER')"
        />

        <RgInputDate
          ref="autorizationDate"
          v-model="hospitalizationInfoForm.inf_data_autorizacao"
          :rules="{ fn: validateAutorizationDate }"
          class="col-2"
          label="Data de Autorização:"
          placeholder="  /  /"
        />

        <RgSuggestHospitalizationAih
          v-model="previousAih"
          :rules="{ fn: validatePreviousAih }"
          invalid-message="Número da AIH precisa ter 13 números e constar no sistema"
          class="col-2"
          label="AIH Anterior:"
        />

        <RgSuggestHospitalizationAih
          v-model="nextAih"
          :rules="{ fn: validateNextAih }"
          invalid-message="Número da AIH precisa ter 13 números e constar no sistema"
          class="col-2"
          label="AIH Posterior:"
        />
      </div>

      <div class="row">
        <span class="subtitle">
          <RgTitle
            title="Preencher em caso de Causas Externas (Acidentes ou Violências)"
          />
        </span>
      </div>
      <div class="row">
        <RgInputCnpj
          ref="cnpj"
          v-model="hospitalizationInfoForm.inf_cnpj_empregador"
          :rules="externalCauseRules"
          class="col-2"
          label="CNPJ do empregador:"
        />

        <RgComboboxHospitalizationSocialSecurityBond
          ref="securityBound"
          v-model="hospitalizationInfoForm.inf_vinculos_previdencia"
          :rules="externalCauseRules"
          class="col-2"
          label="Vínculo da previdência:"
        />

        <RgTypeaheadHospitalizationCnaer
          ref="cnaer"
          v-model="hospitalizationInfoForm.inf_cnaer"
          :rules="externalCauseRules"
          class="col-4"
          label="CNAER:"
        />

        <RgTypeaheadCbo
          ref="cbo"
          v-model="cbo"
          :rules="{ fn: validateCbo }"
          class="col-4"
          label="CBO Completa:"
          placeholder="Código do CBO"
        />
      </div>

      <div class="row">
        <span class="subtitle">
          <RgTitle title="Solicitação de liberação de crítica" />
        </span>
      </div>
      <div class="row justify-content-start">
        <RgRemoteSelectCriticalReleaseRequest
          v-model="releaseRequestArray"
          class="col-4"
          @input="validateCriticalReleaseItems"
        />
        <div class="col-6 justify-block">
          <RgFormBase label="Justificativa:" class="justify-label" />
          <textarea
            v-model="hospitalizationInfoForm.inf_justificativa"
            :disabled="hasPatientCns()"
            :rules="justificationRules"
            cols="15"
            rows="2"
            maxlength="50"
          />
        </div>
      </div>

      <div class="row justify-content-around">
        <div class="col justification-observation">
          Obs.: Preencher quando o paciente não tiver
          <span title="Cartão Nacional de Saúde">CNS</span> - 50 caracteres
        </div>
      </div>
    </RgForm>
  </section>
</template>

<script>
import { isEmpty } from "lodash";
import EventBus from "~common/event/bus/EventBus";
import moment from "moment";

import { RgForm } from "~tokio/foundation/form";
import { RgFormBase } from "~tokio/foundation/container";
import { RgInputDate, RgInputCnpj, RgTitle } from "~tokio/primitive";

import {
  RgTypeaheadProcedure,
  RgSuggestEmployee,
  RgSuggestEmployeeAuthorizer,
} from "$billing/common";
import RgTypeaheadCbo from "$billing/common/component/rg-typeahead-cbo/RgTypeaheadCbo";
import RgTypeaheadHospitalizationBedSpecialty from "../../suggest/rg-typeahead-hospitalization-bed-specialty/RgTypeaheadHospitalizationBedSpecialty";
import RgTypeaheadHospitalizationReasonForClosure from "../../suggest/rg-typeahead-hospitalization-reason-for-closure/RgTypeaheadHospitalizationReasonForClosure";
import RgSuggestHospitalizationAih from "../../suggest/rg-suggest-hospitalization-aih/RgSuggestHospitalizationAih";
import RgTypeaheadHospitalizationCnaer from "../../suggest/rg-typeahead-hospitalization-cnaer/RgTypeaheadHospitalizationCnaer";

import RgComboboxHospitalizationModality from "../../combobox/rg-combobox-hospitalization-modality/RgComboboxHospitalizationModality";
import RgRemoteSelectCriticalReleaseRequest from "../../selection/rg-remote-select-critical-release-request/RgRemoteSelectCriticalReleaseRequest";
import RgComboboxHospitalizationServiceCharacter from "../../combobox/rg-combobox-hospitalization-service-character/RgComboboxHospitalizationServiceCharacter";
import RgComboboxHospitalizationSocialSecurityBond from "../../combobox/rg-combobox-hospitalization-social-security-bond/RgComboboxHospitalizationSocialSecurityBond";
import RgSuggestCid from "../../suggest/rg-suggest-cid/RgSuggestCid";

import CriticalReleaseRequestToLineDB from "../../selection/rg-remote-select-critical-release-request/action/CriticalReleaseRequestToLineDB";
import CriticalReleaseRequestLineDBToData from "../../selection/rg-remote-select-critical-release-request/action/CriticalReleaseRequestLineDBToData";
import ValidateAihNumber from "../../../service/ValidateAihNumber";
import ProcedureChangeValue from "../../../service/ProcedureChangeValue";
import CheckBedTypeProcedure from "../../../../store/actions/CheckBedTypeProcedure";
import CheckModalityProcedure from "../../../../store/actions/CheckModalityProcedure";
import {
  AlertError,
  AddWeekdays,
  GetSplittedInfo,
  ValidateCbo,
  SpecialProcedures,
} from "../../../service/";
import RgInputSuscardHelper from "~tokio/primitive/input/rg-input-suscard/RgInputSuscardHelper";
export default {
  name: "HospitalizationBillingHospitalizationInfo",
  components: {
    RgForm,
    RgFormBase,
    RgInputCnpj,
    RgInputDate,
    RgSuggestCid,
    RgTypeaheadProcedure,
    RgTypeaheadCbo,
    RgSuggestEmployee,
    RgSuggestEmployeeAuthorizer,
    RgTitle,
    RgTypeaheadHospitalizationBedSpecialty,
    RgTypeaheadHospitalizationReasonForClosure,
    RgSuggestHospitalizationAih,
    RgComboboxHospitalizationModality,
    RgComboboxHospitalizationServiceCharacter,
    RgComboboxHospitalizationSocialSecurityBond,
    RgRemoteSelectCriticalReleaseRequest,
    RgTypeaheadHospitalizationCnaer,
  },
  props: {
    validationTabs: {
      type: Boolean,
      default: false,
    },
    savingFather: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      saving: false,
      professionalCaptionRaw: "crs_numero - pes_nome",
      actionTypeLabel: "Salvar",
      buttonLabel: {
        create: "Confirmar",
        update: "Atualizar",
      },
      childbirthProcedures: [
        "0411010034", // Parto Cesariano
        "0411010042", // Parto Cesariano c/ laqueadura tubaria
        "0411010026", // Parto Cesariano em gestação de alto risco;
        "0310010039", // Parto Normal
        "0310010055", // Parto Normal em centro de parto normal (CPN)
        "0310010047", // Parto Normal em gestação de alto risco
      ],
      documentType: 2, // 1- CPF | 2- CNS
      releaseRequestArray: [],
      cbo: null,
      requester: null,
      responsible: null,
      authorizer: null,
      previousAih: null,
      nextAih: null,
      mainProcedureErrors: null,
      requestedProcedureErrors: null,
      mainProcedureItem: null,
      requestedProcedureItem: null,
      mainCid: null,
      loaded: {
        mainProcedure: false,
        requestedProcedure: false,
      },
      hospitalizationInfoForm: {
        inf_id: null,
        inf_procedimento_principal: null,
        inf_mudanca_procedimento: null,
        inf_procedimento_solicitado: null,
        inf_codigo_modalidade: null,
        inf_codigo_especialidade_leito: null,
        inf_codigo_carater_internacao: null,
        inf_cid_principal: null,
        inf_motivo_encerramento: null,
        inf_nome_solicitante: null,
        inf_documento_solicitante: null,
        inf_documento_tipo_solicitante: null,
        inf_nome_responsavel: null,
        inf_documento_responsavel: null,
        inf_documento_tipo_responsavel: null,
        inf_nome_autorizador: null,
        inf_documento_autorizador: null,
        inf_documento_tipo_autorizador: null,
        inf_data_autorizacao: null,
        inf_aih_numero_anterior: null,
        inf_aih_numero_posterior: null,
        inf_cnpj_empregador: null,
        inf_cnaer: null,
        inf_vinculos_previdencia: null,
        inf_cbo: null,
        inf_codigo_solicitacao_liberacao: null,
        inf_justificativa: null,
      },
    };
  },
  computed: {
    requiredRule() {
      return { required: this.validationTabs };
    },
    externalCauseRules() {
      let rules = {};
      if (this.externalCauseRequired()) {
        rules = { required: true };
      } else {
        rules = { required: false };
      }
      return rules;
    },
    justificationRules() {
      let rules = {};
      if (this.hasPatientCns()) {
        rules = { max: 0 };
      } else {
        rules = { max: 50 };
      }
      return rules;
    },
    competence() {
      return !isEmpty(this.aihCompetence)
        ? this.aihCompetence
        : this.$store.getters[
            "Billing/PeriodHospitalizationBilling/GET_PERIOD_HOSPITALIZATION_BILLING"
          ];
    },
    aihCompetence() {
      return this.$store.getters[
        "Billing/HospitalizationBilling/GET_PRESENTATION"
      ];
    },
    getHospitalizationBillingPath() {
      return "Billing/HospitalizationBilling/";
    },
    setHospitalizationBillingInfoTabPath() {
      return (
        this.getHospitalizationBillingPath +
        "SET_HOSPITALIZATION_BILLING_INFO_TAB"
      );
    },
    setHospitalizationBillingProceduresPath() {
      return (
        this.getHospitalizationBillingPath +
        "SET_HOSPITALIZATION_BILLING_PROCEDURES"
      );
    },
    setHospitalizationBillingCharacterPath() {
      return (
        this.getHospitalizationBillingPath +
        "SET_HOSPITALIZATION_BILLING_CHARACTER"
      );
    },
    getHospitalizationAihReleaseRequestCodePath() {
      return (
        this.getHospitalizationBillingPath +
        "GET_HOSPITALIZATION_AIH_RELEASE_REQUEST_CODE"
      );
    },
    hospitalizationAihReleaseRequestCode() {
      return {
        ...this.$store.getters[
          this.getHospitalizationBillingPath +
            "GET_HOSPITALIZATION_AIH_RELEASE_REQUEST_CODE"
        ],
      };
    },
    hospitalizationBillingInfoTab() {
      return {
        ...this.$store.getters[
          this.getHospitalizationBillingPath +
            "GET_HOSPITALIZATION_BILLING_INFO_TAB"
        ],
      };
    },
    hospitalizationBillingAihInfoTab() {
      return {
        ...this.$store.getters[
          this.getHospitalizationBillingPath + "GET_HOSPITALIZATION_AIH_INFO"
        ],
      };
    },
    patientCns() {
      return this.$store.getters[
        this.getHospitalizationBillingPath + "GET_PATIENT_CNS"
      ];
    },
    aihNumber() {
      return this.$store.getters[
        this.getHospitalizationBillingPath + "GET_AIH_NUMBER"
      ];
    },
    hospitalizationDate() {
      return this.$store.getters[
        this.getHospitalizationBillingPath + "GET_HOSPITALIZATION_DATE"
      ];
    },
    exitDate() {
      return this.$store.getters[
        this.getHospitalizationBillingPath + "GET_EXIT_DATE"
      ];
    },
    getHospitalizationPatientInfo() {
      return this.$store.getters[
        this.getHospitalizationBillingPath + "GET_PATIENT_INFO_TAB"
      ];
    },
    canSave() {
      return !this.savingFather && !this.saving;
    },
  },
  watch: {
    hospitalizationBillingInfoTab(pData) {
      this.setDataFromState(pData);
    },
    "hospitalizationInfoForm.inf_procedimento_solicitado"(pValue) {
      const oldValue = this.hospitalizationInfoForm.inf_procedimento_solicitado;
      if (pValue)
        this.hospitalizationInfoForm.inf_procedimento_principal = pValue;
      this.changeOfProcedure(this.loaded.requestedProcedure);
      if (!this.loaded.requestedProcedure && oldValue)
        this.loaded.requestedProcedure = true;
    },
    "hospitalizationInfoForm.inf_procedimento_principal"(pValue) {
      const oldValue = this.hospitalizationInfoForm.inf_procedimento_principal;
      this.changeOfProcedure(this.loaded.mainProcedure);
      this.$refs.bedSpecialty.validate();
      if (!this.loaded.mainProcedure && oldValue)
        this.loaded.mainProcedure = true;
    },
    async releaseRequestArray() {
      if (this.releaseRequestArray.length < 1) {
        this.clearReleaseRequestCode();
        this.validateProcedures(this.mainProcedureItem, "principal");
        this.validateProcedures(this.requestedProcedureItem, "solicitado");
        return;
      }

      await this.$store.dispatch(
        this.getHospitalizationAihReleaseRequestCodePath,
        CriticalReleaseRequestToLineDB(this.releaseRequestArray),
      );

      this.hospitalizationInfoForm.inf_codigo_solicitacao_liberacao = this.hospitalizationAihReleaseRequestCode.ics_codigo;
      this.validateProcedures(this.mainProcedureItem, "principal");
      this.validateProcedures(this.requestedProcedureItem, "solicitado");
    },
    requester(pValue) {
      const req = GetSplittedInfo(pValue, true);
      if (req) {
        this.hospitalizationInfoForm.inf_documento_solicitante = req.document;
        this.hospitalizationInfoForm.inf_nome_solicitante = req.name;
      }
    },
    authorizer(pValue) {
      const aut = GetSplittedInfo(pValue, true);
      if (aut) {
        this.hospitalizationInfoForm.inf_documento_autorizador = aut.document;
        this.hospitalizationInfoForm.inf_nome_autorizador = aut.name;
      }
    },
    responsible(pValue) {
      const res = GetSplittedInfo(pValue, true);
      if (res) {
        this.hospitalizationInfoForm.inf_documento_responsavel = res.document;
        this.hospitalizationInfoForm.inf_nome_responsavel = res.name;
      }
    },
    cbo(pValue) {
      const splittedCbo = GetSplittedInfo(pValue, true);
      if (splittedCbo) {
        this.hospitalizationInfoForm.inf_cbo = splittedCbo.document;
      }
    },
    "hospitalizationInfoForm.inf_justificativa"(pValue) {
      this.hospitalizationInfoForm.inf_justificativa = pValue
        ? pValue
            .replace(/[^\w\s]/gi, "")
            .replace(/\d+/g, "")
            .toUpperCase()
        : null;
    },
    "getHospitalizationPatientInfo.icf_sexo"(pValue) {
      this.validateProcedures(this.mainProcedureItem, "principal");
      this.validateProcedures(this.requestedProcedureItem, "solicitado");
    },
    "getHospitalizationPatientInfo.icf_data_nascimento"(pValue) {
      this.validateProcedures(this.mainProcedureItem, "principal");
      this.validateProcedures(this.requestedProcedureItem, "solicitado");
    },
    "hospitalizationInfoForm.inf_codigo_carater_internacao"(pValue) {
      this.$refs.autorizationDate.validate();
      this.$refs.responsible.validate();
      // FIXME: Estava apagando por algum motivo outros campos
      // this.$store.commit(
      //   this.setHospitalizationBillingCharacterPath,
      //   pValue
      // )
    },
    "hospitalizationInfoForm.inf_aih_numero_anterior"(pValue) {
      this.previousAih = pValue;
    },
    "hospitalizationInfoForm.inf_aih_numero_posterior"(pValue) {
      this.nextAih = pValue;
    },
    savingFather(pData) {
      this.saving = pData;
    },
  },
  mounted() {
    // Escutando para atualizar o vuex
    EventBus.$on("updateVuex", this.setDataToState);
    EventBus.$on("resetData", this.resetData);
    this.setDataFromState(); // Atualizada o Data com as informações que vem do estado (salvas no localstorage ou vindo da busca)
    setTimeout(() => {
      this.mainCid = this.hospitalizationInfoForm.inf_cid_principal; // Define o CID Principal
      this.$nextTick(() => {
        if (this.$refs.mainCid)
          this.$refs.mainCid.loadCid(
            this.hospitalizationInfoForm.inf_cid_principal,
          );
      });
    }, 1500);
  },
  methods: {
    saveByShortKey(pError, pRequestAction) {
      this.$refs.form.doAction(pRequestAction);
    },
    increaseStepProgress() {
      this.$emit("increaseStepProgress", 1);
    },
    saveHospitalizationBillingInfoTab(pError, pRequestAction) {
      this.$nextTick(async () => {
        if (!pError && this.canSave) {
          this.saving = true;
          this.$emit("saveInfoTabs", pRequestAction);
          return true;
        }
      });

      return false;
    },
    updateCidCode(pValue) {
      this.hospitalizationInfoForm.inf_cid_principal = !isEmpty(pValue)
        ? pValue
        : null;
    },
    resetData() {
      Object.assign(this.$data, this.$options.data.apply(this));
    },
    clearReleaseRequestCode() {
      this.hospitalizationInfoForm.inf_codigo_solicitacao_liberacao = null;
      const data = {
        ics_id: null,
        ics_codigo: null,
        ics_tempo_permanencia: 0,
        ics_idade_menor: 0,
        ics_idade_maior: 0,
        ics_qtd: 0,
        ics_telefone: 0,
        ics_cns: 0,
      };
      this.$store.commit(
        this.getHospitalizationBillingPath +
          "SET_HOSPITALIZATION_AIH_RELEASE_REQUEST_CODE",
        data,
      );
    },
    setDataFromState(pData = null) {
      const data = pData || this.hospitalizationBillingInfoTab;
      this.hospitalizationInfoForm = data;
      this.$nextTick(() => {
        this.setProfessionals(); // Cria os objetos de acordo com o estado do Vuex
        this.changeOfProcedure(); // Define se o procedimento solicitado é diferente do procedimento principal
        this.setDocumentType(); // Define o tipo padrão dos documentos dos profissionais
        this.setCriticalReleaseRequest(); // Cria array das solicitações de liberação de crítica
        this.setCbo(); // Define a CBO que veio da Internação / Internação AIH
        this.setPreviousAndNextAih(); // Define as AIH's anterior e posterior
      });
    },
    changeOfProcedure(setProcedures = false) {
      this.hospitalizationInfoForm.inf_mudanca_procedimento = ProcedureChangeValue(
        this.hospitalizationInfoForm.inf_procedimento_principal,
        this.hospitalizationInfoForm.inf_procedimento_solicitado,
      );
      if (setProcedures) {
        this.hospitalizationInfoForm.inf_cid_principal = null;
        this.hospitalizationInfoForm.inf_codigo_especialidade_leito = null;

        this.$refs.bedSpecialty.inputValue = null;
        this.$refs.mainCid.reset();
      }
    },
    setDocumentType() {
      this.hospitalizationInfoForm.inf_documento_tipo_solicitante = this.documentType;
      this.hospitalizationInfoForm.inf_documento_tipo_responsavel = this.documentType;
      this.hospitalizationInfoForm.inf_documento_tipo_autorizador = this.documentType;
    },
    setProfessionals() {
      this.requester = null;
      if (this.hospitalizationInfoForm.inf_documento_solicitante)
        this.requester = `${this.hospitalizationInfoForm.inf_documento_solicitante}`;
      if (this.hospitalizationInfoForm.inf_nome_solicitante)
        this.requester += ` - ${this.hospitalizationInfoForm.inf_nome_solicitante}`;

      this.responsible = null;
      if (this.hospitalizationInfoForm.inf_documento_responsavel)
        this.responsible = `${this.hospitalizationInfoForm.inf_documento_responsavel}`;
      if (this.hospitalizationInfoForm.inf_nome_responsavel)
        this.responsible += ` - ${this.hospitalizationInfoForm.inf_nome_responsavel}`;

      this.authorizer = null;
      if (this.hospitalizationInfoForm.inf_documento_autorizador)
        this.authorizer = `${this.hospitalizationInfoForm.inf_documento_autorizador}`;
      if (this.hospitalizationInfoForm.inf_nome_autorizador)
        this.authorizer += ` - ${this.hospitalizationInfoForm.inf_nome_autorizador}`;
    },
    setCriticalReleaseRequest() {
      if (!this.hospitalizationInfoForm.inf_codigo_solicitacao_liberacao)
        return;

      this.releaseRequestArray = CriticalReleaseRequestLineDBToData(
        this.hospitalizationAihReleaseRequestCode,
      );
    },
    setCbo() {
      this.cbo = this.hospitalizationInfoForm.inf_cbo || "";
    },
    setPreviousAndNextAih() {
      this.previousAih = this.hospitalizationInfoForm.inf_aih_numero_anterior;
      this.nextAih = this.hospitalizationInfoForm.inf_aih_numero_posterior;
    },
    setDataToState() {
      this.$store.commit(this.setHospitalizationBillingInfoTabPath, {
        ...this.hospitalizationInfoForm,
      });
    },
    /**
     * @returns {Boolean}
     */
    externalCauseRequired() {
      const res =
        [3, 4].includes(
          this.hospitalizationInfoForm.inf_codigo_carater_internacao,
        ) ||
        !isEmpty(this.hospitalizationInfoForm.inf_cnpj_empregador) ||
        !isEmpty(this.hospitalizationInfoForm.inf_vinculos_previdencia) ||
        !isEmpty(this.hospitalizationInfoForm.inf_cnaer) ||
        !isEmpty(this.cbo);
      return res;
    },
    hasPatientCns() {
      const has = !isEmpty(this.patientCns);
      if (has) this.hospitalizationInfoForm.inf_justificativa = null;
      return has;
    },
    tabIsValid(pErr) {
      return this.$refs.form.validate();
    },
    setAihToState() {
      this.hospitalizationInfoForm.inf_aih_numero_anterior = isEmpty(
        this.previousAih,
      )
        ? null
        : this.previousAih.replace(/\D+/g, "").trim().substring(0, 13);
      this.hospitalizationInfoForm.inf_aih_numero_posterior = isEmpty(
        this.nextAih,
      )
        ? null
        : this.nextAih.replace(/\D+/g, "").trim().substring(0, 13);
    },
    // ============================================== VALIDATIONS
    async validateMainProcedure(pItem, pErrors) {
      if (this.validationTabs) {
        if (!pItem || pItem.length === 0) {
          return AlertError("Procedimento Principal não preenchido", pErrors);
        }
      }

      if (this.mainProcedureErrors.error.length > 0) {
        pErrors.push(this.mainProcedureErrors.error);
        return this.mainProcedureErrors.valid;
      }

      if (this.hospitalizationBillingAihInfoTab.iaf_tipo_aih === 5) {
        const cmp = this.$store.getters[
          "Billing/PeriodHospitalizationBilling/GET_PERIOD_HOSPITALIZATION_BILLING"
        ];
        const data = await this.$store.dispatch(
          "Billing/HospitalizationBilling/GET_PROCEDURE_DETAILS",
          {
            CO_PROCEDIMENTO: this.hospitalizationInfoForm
              .inf_procedimento_principal,
            DT_COMPETENCIA: cmp,
          },
        );

        const mainProcedureDetails = JSON.parse(JSON.stringify(data));

        const hasLongStay = mainProcedureDetails.filter(
          (item) => item.CO_DETALHE === "003",
        );
        if (hasLongStay.length <= 0) {
          return AlertError(
            "Procedimento Principal deve admitir longa permanencia",
            pErrors,
          );
        }
      }

      return true;
    },
    validateRequestedProcedure(pItem, pErrors) {
      if (this.validationTabs) {
        if (!pItem || pItem.length === 0) {
          return AlertError("Procedimento Solicitado não preenchido", pErrors);
        }
      }

      if (this.requestedProcedureErrors.error.length > 0) {
        pErrors.push(this.requestedProcedureErrors.error);
        return this.requestedProcedureErrors.valid;
      }

      return true;
    },
    validateProcedures(pItem, pField) {
      const error = [];

      if (!isEmpty(pItem)) {
        const validateSex = this.validateProcedureSex(pItem, error);
        const validateAge = this.validateProcedureAge(pItem, error);
        const tempObject = {
          error,
          valid: validateSex && validateAge,
        };

        if (pField === "principal") {
          this.mainProcedureItem = pItem;
          this.mainProcedureErrors = tempObject;
          this.$refs.mainProcedure.validate();
        } else {
          this.requestedProcedureItem = pItem;
          this.requestedProcedureErrors = tempObject;
          this.$refs.requestedProcedure.validate();
        }
      }
    },
    validateProcedureSex(pItem, pErrors) {
      if (!pItem) return;
      if (
        pItem.tp_sexo !== this.getHospitalizationPatientInfo.icf_sexo &&
        pItem.tp_sexo !== "I"
      ) {
        return AlertError(
          "Procedimento incompatível com o sexo do paciente",
          pErrors,
        );
      }

      return true;
    },
    validateProcedureAge(pItem, pErrors) {
      if (!pItem || !this.getHospitalizationPatientInfo.icf_data_nascimento)
        return;
      if (pItem.vl_idade_minima === "9999" || pItem.vl_idade_maxima === "9999")
        return;

      const minAge = (pItem.vl_idade_minima / 12).toFixed(1);
      const maxAge = (pItem.vl_idade_maxima / 12).toFixed(1);
      const patienteAge = moment(
        this.getHospitalizationPatientInfo.icf_data_nascimento,
        "DD/MM/YYYY",
      );
      const diffDuration = moment.duration(moment().diff(patienteAge));
      const age = diffDuration.years() + "." + diffDuration.months();

      if (
        parseFloat(age) > parseFloat(maxAge) &&
        this.hospitalizationAihReleaseRequestCode.ics_idade_maior !== 1
      ) {
        return AlertError(
          "Procedimento incompatível com a idade do paciente",
          pErrors,
        );
      }
      if (
        parseFloat(age) < parseFloat(minAge) &&
        this.hospitalizationAihReleaseRequestCode.ics_idade_menor !== 1
      ) {
        return AlertError(
          "Procedimento incompatível com a idade do paciente",
          pErrors,
        );
      }

      return true;
    },
    validateAutorizationDate(pDate, pErrors) {
      if (this.validationTabs) {
        if (isEmpty(this.exitDate)) {
          return AlertError("Data de saída deve ser preenchida", pErrors);
        }

        if (isEmpty(this.hospitalizationDate)) {
          return AlertError("Data de internação deve ser preenchida", pErrors);
        }
      }

      if (!isEmpty(pDate)) {
        const date = moment(pDate, "DD-MM-YYYY");
        const hospitalizationDate = isEmpty(this.hospitalizationDate)
          ? false
          : moment(this.hospitalizationDate, "DD-MM-YYYY");
        let maxDate = null;

        if (this.hospitalizationInfoForm.inf_codigo_carater_internacao === 1) {
          if (isEmpty(pDate)) {
            return AlertError(
              "Data de internação deve ser preenchida e ser anterior a data de internação",
              pErrors,
            );
          }

          if (hospitalizationDate) {
            if (date.isAfter(hospitalizationDate)) {
              return AlertError(
                "Data de autorização deve ser anterior a data de internação",
                pErrors,
              );
            }

            maxDate = hospitalizationDate.add(-15, "day");
            if (date.isBefore(maxDate)) {
              return AlertError(
                "Data de autorização deve ser no máximo 15 dias retroativos a data de internação",
                pErrors,
              );
            }
          }
        } else if (hospitalizationDate) {
          maxDate = AddWeekdays(hospitalizationDate, 2);
          if (
            date.isBefore(hospitalizationDate) ||
            !date.isSameOrBefore(maxDate)
          ) {
            return AlertError(
              "Data de autorização deve ser igual ou até 2 dias úteis após a data de internação",
              pErrors,
            );
          }
        }
      }

      return true;
    },
    isRequesterAutorizerValid() {
      return (
        this.validationTabs &&
        !isEmpty(this.requester) &&
        !isEmpty(this.authorizer) &&
        this.requester === this.authorizer
      );
    },
    isResponsibleAutorizerValid() {
      return this.validationTabs && this.responsible === this.authorizer;
    },
    isProfessionalDocumentValid(pProfessional, pErrors) {
      const splittedProfessional = GetSplittedInfo(pProfessional);
      const containOnlyZeroRule = /^0+$/;

      if (
        this.validationTabs &&
        (!splittedProfessional || !splittedProfessional.document)
      ) {
        pErrors.push("Campo obrigatório");
        return false;
      } else {
        if (!splittedProfessional.document && splittedProfessional.name) {
          pErrors.push("CNS deve conter 15 caracteres");
          return false;
        }

        if (
          splittedProfessional.document.length !== 15 &&
          splittedProfessional.name
        ) {
          pErrors.push("CNS deve conter 15 caracteres");
          return false;
        }

        if (
          splittedProfessional.document.length !== 15 &&
          !splittedProfessional.name
        ) {
          pErrors.push("CNS deve conter 15 caracteres");
          return false;
        }

        const valid = RgInputSuscardHelper.checkDigit(
          splittedProfessional.document,
          pErrors,
        );

        if (!valid) {
          return AlertError("CNS inválido", pErrors);
        }

        if (containOnlyZeroRule.test(splittedProfessional.document)) {
          return AlertError("Documento do Profissional Inválido", pErrors);
        }
      }

      return true;
    },
    validateRequester(pRequester, pErrors) {
      if (!this.isProfessionalDocumentValid(pRequester, pErrors)) return false;

      if (this.isRequesterAutorizerValid()) {
        return AlertError(
          "CNS do Solicitante igual ao CNS do Autorizador",
          pErrors,
        );
      }

      return true;
    },
    validateAutorizer(pAutorizerProfessional, pErrors) {
      if (!this.isProfessionalDocumentValid(pAutorizerProfessional, pErrors))
        return false;

      if (this.isRequesterAutorizerValid()) {
        return AlertError(
          "CNS do Autorizador igual ao CNS do Solicitante",
          pErrors,
        );
      }

      if (this.isResponsibleAutorizerValid()) {
        return AlertError(
          "CNS do Prof. Autorizador igual ao Prof. Responsável",
          pErrors,
        );
      }

      return true;
    },
    validateResponsible(pResponsible, pErrors) {
      if (!this.isProfessionalDocumentValid(pResponsible, pErrors))
        return false;

      if (
        this.hospitalizationInfoForm.inf_codigo_carater_internacao === 3 &&
        isEmpty(pResponsible)
      ) {
        return AlertError("CNS do Prof. Responsável não preenchido", pErrors);
      }

      if (this.isResponsibleAutorizerValid()) {
        return AlertError(
          "CNS do Prof. Responsável igual ao Prof. Autorizador",
          pErrors,
        );
      }

      return true;
    },
    validateAihs(pAihNumber, pOtherAihNumber, pErrors, next = false) {
      const aihNumber = isEmpty(pAihNumber)
        ? ""
        : pAihNumber.substr(0, 12) + "-" + pAihNumber.substr(12, 1);
      const otherAihNumber = isEmpty(pOtherAihNumber)
        ? ""
        : pOtherAihNumber.substr(0, 12) + "-" + pOtherAihNumber.substr(12, 1);

      // Valida se o número está correto
      if (!ValidateAihNumber(aihNumber, pErrors)) return false;

      // Verifica se o número da aih atual é igual ao número da aih passada
      if (!isEmpty(aihNumber) && aihNumber === this.aihNumber) {
        return AlertError(
          "O Nº não pode ser igual ao nº da AIH Atual.",
          pErrors,
        );
      }

      // Verifica se o número da aih anterior é igual ao número da aih posterior
      if (!isEmpty(otherAihNumber) && otherAihNumber === aihNumber) {
        return AlertError(
          "O Nº da AIH Anterior e da AIH Posterior não podem ser iguais.",
          pErrors,
        );
      }

      // Caso #1772
      // Quando é preciso a permanência do RN, deve constar o número da AIH da mãe no campo da AIH anterior da AIH do RN
      // e no campo da AIH posterior da AIH da mãe, o número da AIH do recém nascido.
      if (
        next &&
        this.validationTabs &&
        isEmpty(pAihNumber) &&
        ["62", "67"].includes(
          this.hospitalizationInfoForm.inf_motivo_encerramento,
        )
      ) {
        return AlertError(
          "Motivo de encerramento exige AIH Posterior.",
          pErrors,
        );
      }

      return true;
    },
    validatePreviousAih(pAih, pErrors) {
      this.setAihToState();
      return this.validateAihs(
        this.hospitalizationInfoForm.inf_aih_numero_anterior,
        this.hospitalizationInfoForm.inf_aih_numero_posterior,
        pErrors,
      );
    },
    validateNextAih(pAih, pErrors) {
      this.setAihToState();
      return this.validateAihs(
        this.hospitalizationInfoForm.inf_aih_numero_posterior,
        this.hospitalizationInfoForm.inf_aih_numero_anterior,
        pErrors,
        true,
      );
    },
    async validateModality(pModality, pErrors) {
      if (this.validationTabs && !pModality) {
        pErrors.push("Campo obrigatório");
        return false;
      }

      const variables = {
        procedure: this.hospitalizationInfoForm.inf_procedimento_principal,
        modality: this.hospitalizationInfoForm.inf_codigo_modalidade,
        competence: this.competence,
      };

      const valid = await CheckModalityProcedure(variables);

      if (!valid)
        return AlertError(
          "Procedimento principal incompatível com a modalidade da AIH",
          pErrors,
        );

      return true;
    },
    async validateBedTypeProcedure(pBedType, pErrors) {
      if (this.validationTabs && isEmpty(pBedType)) {
        pErrors.push("Campo obrigatório");
        return false;
      }

      const variables = {
        bedType: this.hospitalizationInfoForm.inf_codigo_especialidade_leito,
        procedure: this.hospitalizationInfoForm.inf_procedimento_principal,
        competence: this.competence,
      };

      const valid = await CheckBedTypeProcedure(variables);

      if (!valid)
        return AlertError(
          "Procedimento principal incompatível com a especialidade da AIH",
          pErrors,
        );

      return true;
    },
    validateCriticalReleaseItems(pEvent) {
      const hasOlderAge =
        this.releaseRequestArray.filter((item) => item.value === 2).length > 0;
      const hasYoungerAge =
        this.releaseRequestArray.filter((item) => item.value === 3).length > 0;
      if (hasOlderAge && hasYoungerAge) {
        AlertError(
          "Não é possível selecionar idade maior, e idade menor em liberações de criticas",
        );
        this.releaseRequestArray = [];
      }
    },
    validateReasonForClosure(pEvent, pErrors) {
      const hasChildBirth = this.childbirthProcedures.includes(
        this.hospitalizationInfoForm.inf_procedimento_principal,
      );
      if (hasChildBirth) {
        if (pEvent) {
          const splitted = pEvent.split(" - ")[0];
          if (
            [
              "61", // ALTA DA MAE / PUERPERA E DO RECEM-NASCIDO
              "62", // ALTA DA MAE / PUERPERA E PERMANENCIA DO RECEM-NASCIDO
              "63", // ALTA DA MAE / PUERPERA E OBITO DO RECEM-NASCIDO
              "64", // ALTA DA MAE / PUERPERA COM OBITO FETAL
              "65", // OBITO DA GESTANTE E DO CONCEPTO
              "66", // OBITO DA MAE / PUERPERA E ALTA DO RECEM-NASCIDO
              "67", //  OBITO DA MAE / PUERPERA E PERMANENCIA DO RECEM-NASCIDO
            ].includes(splitted)
          ) {
            return true;
          } else {
            pErrors.push("Modalidade incompatível com o procedimento.");
            return false;
          }
        }
        return true;
      }
      return true;
    },
    validateCbo(pCbo, pErrors) {
      const required =
        [3, 4].includes(
          this.hospitalizationInfoForm.inf_codigo_carater_internacao,
        ) ||
        !isEmpty(this.hospitalizationInfoForm.inf_cnpj_empregador) ||
        !isEmpty(this.hospitalizationInfoForm.inf_vinculos_previdencia) ||
        !isEmpty(this.hospitalizationInfoForm.inf_cnaer);
      if (required && isEmpty(pCbo))
        return AlertError("Campo obrigatório", pErrors);
      return SpecialProcedures.includes(
        this.hospitalizationInfoForm.inf_procedimento_principal,
      )
        ? true
        : ValidateCbo(
            this.hospitalizationInfoForm.inf_procedimento_principal,
            this.hospitalizationInfoForm.inf_cbo,
            this.competence,
            pErrors,
          );
    },
    selectedEmployee(pEvent, pMethodToCall) {
      this.$nextTick(() => {
        let valid = true;
        if (pEvent.textRaw) {
          if (pMethodToCall === "REQUESTER") {
            valid = this.validateRequester(pEvent.textRaw);
            if (valid) this.$refs.requester.cleanValidate();
          }
          if (pMethodToCall === "RESPONSIBLE") {
            valid = this.validateResponsible(pEvent.textRaw);
            if (valid) this.$refs.responsible.cleanValidate();
          }
          if (pMethodToCall === "AUTHORIZER") {
            valid = this.validateAutorizer(pEvent.textRaw);
            if (valid) this.$refs.authorizer.cleanValidate();
          }
        }
      });
    },
  },
};
</script>
