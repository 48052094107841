<template lang="html">
  <svg
    width="10.287mm"
    height="10.287mm"
    version="1.1"
    viewBox="0 0 10.287 10.287"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(-34.896 -159.06)">
      <g transform="matrix(.01803 0 0 .01803 35.417 159.54)" fill="#666">
        <g fill="#666">
          <path d="m0 0v170h170v-170zm130 130h-90v-90h90z" fill="#666" />
        </g>
        <g fill="#666">
          <rect x="65" y="65" width="40" height="40" fill="#666" />
        </g>
        <g fill="#666">
          <path d="m342 0v170h170v-170zm130 130h-90v-90h90z" fill="#666" />
        </g>
        <g fill="#666">
          <rect x="407" y="65" width="40" height="40" fill="#666" />
        </g>
        <g fill="#666">
          <path d="m0 342v170h170v-170zm130 130h-90v-90h90z" fill="#666" />
        </g>
        <g fill="#666">
          <rect x="65" y="407" width="40" height="40" fill="#666" />
        </g>
        <g fill="#666">
          <rect x="40" y="197" width="40" height="40" fill="#666" />
        </g>
        <g fill="#666">
          <polygon
            points="80 277 119 277 119 317 159 317 159 277 120 277 120 237 80 237"
            fill="#666"
          />
        </g>
        <g fill="#666">
          <rect x="280" y="77" width="40" height="40" fill="#666" />
        </g>
        <g fill="#666">
          <rect x="200" y="40" width="40" height="77" fill="#666" />
        </g>
        <g fill="#666">
          <rect x="240" width="40" height="40" fill="#666" />
        </g>
        <g fill="#666">
          <polygon
            points="240 157 200 157 200 197 280 197 280 117 240 117"
            fill="#666"
          />
        </g>
        <g fill="#666">
          <polygon
            points="240 356 280 356 280 395 360 395 360 355 280 355 280 316 240 316 240 237 200 237 200 317 240 317"
            fill="#666"
          />
        </g>
        <g fill="#666">
          <rect x="280" y="197" width="40" height="80" fill="#666" />
        </g>
        <g fill="#666">
          <path
            d="m472 236v-39h-73v40h-39v40h40v39h112v-80zm0 40h-72v-39h72z"
            fill="#666"
          />
        </g>
        <g fill="#666">
          <rect x="472" y="355" width="40" height="80" fill="#666" />
        </g>
        <g fill="#666">
          <rect x="320" y="277" width="40" height="40" fill="#666" />
        </g>
        <g fill="#666">
          <rect x="360" y="395" width="40" height="40" fill="#666" />
        </g>
        <g fill="#666">
          <rect x="400" y="355" width="40" height="40" fill="#666" />
        </g>
        <g fill="#666">
          <polygon
            points="400 512 440 512 440 475 472 475 472 435 400 435"
            fill="#666"
          />
        </g>
        <g fill="#666">
          <rect x="200" y="356" width="40" height="76" fill="#666" />
        </g>
        <g fill="#666">
          <polygon
            points="240 512 280 512 280 472 319 472 319 512 359 512 359 472 320 472 320 432 240 432"
            fill="#666"
          />
        </g>
        <g fill="#666">
          <rect x="120" y="197" width="80" height="40" fill="#666" />
        </g>
        <g fill="#666">
          <rect y="237" width="40" height="80" fill="#666" />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconQRCode",
};
</script>
