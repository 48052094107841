<style src="./RgInputMasked.scss" lang="scss"></style>
<template lang="html">
  <fieldset class="rg-input--component rg-input-masked">
    <RgFormBase :title="title" :label="label" :required="isRequired">
      <div slot="right-label">
        <RgValidationAlert :alert="error" class="rg-input--alert" />
      </div>
      <div class="rg-input--base">
        <div v-if="mask" class="rg-input--textbox-container">
          <input
            ref="inputFieldId"
            v-model="modelValue"
            v-mask="{ mask, tokens }"
            :style="styles"
            :class="{ 'on-error': error.length > 0 }"
            :placeholder="placeholder"
            :disabled="disabled"
            :tabindex="disabled ? -1 : tabIndex"
            :readonly="disabled"
            :maxlength="maxlength"
            type="text"
            @blur="blur"
            @focus="checkFocus"
            @change="change"
            @keyup="keyup"
          />
          <div v-if="typeMask" class="masked">{{ typeMask }}</div>
        </div>
        <div v-else class="rg-input--textbox-container">
          <input
            ref="inputFieldId"
            v-model="modelValue"
            :style="styles"
            :class="{ 'on-error': error.length > 0 }"
            :placeholder="placeholder"
            :disabled="disabled"
            :tabindex="disabled ? -1 : tabIndex"
            :readonly="disabled"
            :maxlength="maxlength"
            type="text"
            @blur="blur"
            @focus="checkFocus"
            @change="change"
            @keyup="keyup"
          />
          <div v-if="typeMask" class="masked">{{ typeMask }}</div>
        </div>
      </div>
    </RgFormBase>
  </fieldset>
</template>

<script>
import { RgFormBase } from "~tokio/foundation/container";
import RgValidationAlert from "~tokio/primitive/validation/rg-validation-alert/RgValidationAlert";
import RgInputMixin from "~tokio/primitive/input/mixin/RgInputMixin";
import RgValidatorMixin from "~tokio/primitive/validation/RgValidatorMixin";

import { mask } from "vue-the-mask";

export default {
  name: "RgInputMasked",
  components: {
    RgValidationAlert,
    RgFormBase,
  },
  directives: {
    mask,
  },
  mixins: [RgValidatorMixin, RgInputMixin],
  props: {
    label: {
      type: String,
      default: "Texto",
    },
    mask: {
      default: "",
    },
    typeMask: {
      type: String,
      default: "",
    },
    styles: {
      default: null,
    },
    withMask: {
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    change: {
      type: Function,
      default: () => {},
    },
    keyup: {
      type: Function,
      default: () => {},
    },
    title: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    maxlength: {
      type: Number,
    },
    tokens: {
      type: Object,
      default: () => {
        return {
          "#": { pattern: /\d/ },
          X: { pattern: /[0-9a-zA-Z]/ },
          S: { pattern: /[a-zA-Z]/ },
          A: { pattern: /[a-zA-Z]/, transform: (v) => v.toLocaleUpperCase() },
          a: { pattern: /[a-zA-Z]/, transform: (v) => v.toLocaleLowerCase() },
          "!": { escape: true },
        };
      },
    },
  },
  data() {
    return {
      modelValue: "",
    };
  },
  computed: {
    isRequired() {
      return this.rules && this.rules.required;
    },
  },
  watch: {
    value(pValue) {
      this.modelValue = pValue;
      this.inputValue = this.value;
    },
    modelValue(pVal, pValOld) {
      this.inputValue =
        this.withMask || !this.modelValue
          ? this.modelValue
          : this.modelValue.replace(/\s/g, "");
      this.$emit("input", this.inputValue);
    },
  },
  methods: {
    blur() {
      this.$emit("blur", this.value);
      this.validate();
    },
  },
};
</script>
