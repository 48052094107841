<template lang="html">
  <svg
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 104.36555 85.285736"
    height="85.286mm"
    width="104.37mm"
    version="1.1"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:dc="http://purl.org/dc/elements/1.1/"
  >
    <defs>
      <clipPath id="clipPath6985" clipPathUnits="userSpaceOnUse">
        <path d="m0 560h2375.2v-560h-2375.2z" />
      </clipPath>
    </defs>
    <g transform="translate(-43.61 -119.61)">
      <g transform="matrix(1.6866 0 0 -1.6866 -1324 519.65)">
        <g>
          <g></g>
        </g>
      </g>
      <g transform="matrix(1.6866 0 0 -1.6866 -1324 519.65)">
        <g></g>
      </g>
      <g>
        <g transform="matrix(1.6866 0 0 -1.6866 -1324 519.65)">
          <g style="fill: #b3b3b3">
            <g style="fill: #b3b3b3">
              <g style="fill: #b3b3b3">
                <path
                  d="m855.89 237.18c-.708 0-1.288-.582-1.288-1.29v-1.306h-3.77v1.306c0 .708-.579 1.29-1.29 1.29h-.322c-.709 0-1.289-.582-1.289-1.29v-1.306h-3.768v1.306c0 .708-.582 1.29-1.288 1.29h-.324c-.71 0-1.29-.582-1.29-1.29v-1.306h-3.767v1.306c0 .708-.583 1.29-1.292 1.29h-.322c-.708 0-1.289-.582-1.289-1.29v-1.306h-6.104v-38.67h34.817v34.763l-3.978 3.907h-1.823v1.306c0 .708-.581 1.29-1.289 1.29zm.324-7.803c.708 0 1.289.582 1.289 1.29v1.336h.77l2.453-2.41.036-31.114-29.696.015v33.509h3.525v-1.336c0-.708.581-1.29 1.289-1.29h.322c.709 0 1.292.582 1.292 1.29v1.336h3.767v-1.336c0-.708.58-1.29 1.29-1.29h.324c.706 0 1.288.582 1.288 1.29v1.336h3.768v-1.336c0-.708.58-1.29 1.289-1.29h.322c.711 0 1.29.582 1.29 1.29v1.336h3.77v-1.336c0-.708.58-1.29 1.288-1.29z"
                />
              </g>
            </g>
          </g>
        </g>
        <g transform="matrix(1.6866 0 0 -1.6866 -1324 519.65)">
          <g clip-path="url(#clipPath6985)">
            <g transform="translate(824.14 222.71)">
              <path
                d="m0 0h3.525v-1.336c0-.708.58-1.29 1.289-1.29h.323c.709 0 1.291.582 1.291 1.29v1.336h3.767v-1.336c0-.708.58-1.29 1.29-1.29h.324c.707 0 1.289.582 1.289 1.29v1.336h3.767v-1.336c0-.708.58-1.29 1.289-1.29h.323c.71 0 1.289.582 1.289 1.29v1.336h3.77v-1.336c0-.708.58-1.29 1.289-1.29h.322c.71 0 1.29.582 1.29 1.29v1.336h.77l2.453-2.41.036-31.114-29.696.015zm28.26 2.581h-1.823v1.306c0 .708-.58 1.29-1.29 1.29h-.322c-.709 0-1.289-.582-1.289-1.29v-1.306h-3.77v1.306c0 .708-.579 1.29-1.289 1.29h-.323c-.709 0-1.289-.582-1.289-1.29v-1.306h-3.767v1.306c0 .708-.582 1.29-1.289 1.29h-.324c-.71 0-1.29-.582-1.29-1.29v-1.306h-3.767v1.306c0 .708-.582 1.29-1.291 1.29h-.323c-.709 0-1.289-.582-1.289-1.29v-1.306h-6.103v-38.67h34.816v34.763z"
              />
            </g>
          </g>
        </g>
        <g transform="matrix(-.25089 .37668 -.37668 -.25089 160.26 156.08)">
          <path
            d="m63.386 16.193.002-.002c.002-.003.004-.006.006-.01.172-.195.298-.43.399-.678.032-.076.053-.148.076-.225.058-.191.094-.389.106-.596.006-.076.018-.148.016-.226 0-.04.01-.076.008-.115-.014-.239-.062-.47-.136-.687-.006-.023-.022-.041-.03-.064-.088-.239-.214-.451-.363-.645-.021-.027-.028-.063-.05-.09l-10.311-12.146c-.789-.93-2.084-.948-2.894-.037-.808.91-.823 2.402-.032 3.334l5.558 6.549c-8.121-1.076-16.104.633-16.481.717-24.646 4.467-42.087 27.227-38.88 50.736.159 1.164 1.028 1.992 2.019 1.992.106 0 .212-.009.320-.027 1.116-.203 1.878-1.409 1.704-2.696-2.857-20.94 13.056-41.282 35.537-45.358.103-.024 8.351-1.794 16.117-.574l-8.577 5.093c-1.005.598-1.398 2.02-.881 3.177.516 1.159 1.748 1.608 2.756 1.017l13.52-8.028c.183-.111.347-.25.491-.411z"
          />
        </g>
        <g transform="matrix(.30352 -.33572 .33572 .30352 31.22 174.5)">
          <path
            d="m63.386 16.193.002-.002c.002-.003.004-.006.006-.01.172-.195.298-.43.399-.678.032-.076.053-.148.076-.225.058-.191.094-.389.106-.596.006-.076.018-.148.016-.226 0-.04.01-.076.008-.115-.014-.239-.062-.47-.136-.687-.006-.023-.022-.041-.03-.064-.088-.239-.214-.451-.363-.645-.021-.027-.028-.063-.05-.09l-10.311-12.146c-.789-.93-2.084-.948-2.894-.037-.808.91-.823 2.402-.032 3.334l5.558 6.549c-8.121-1.076-16.104.633-16.481.717-24.646 4.467-42.087 27.227-38.88 50.736.159 1.164 1.028 1.992 2.019 1.992.106 0 .212-.009.320-.027 1.116-.203 1.878-1.409 1.704-2.696-2.857-20.94 13.056-41.282 35.537-45.358.103-.024 8.351-1.794 16.117-.574l-8.577 5.093c-1.005.598-1.398 2.02-.881 3.177.516 1.159 1.748 1.608 2.756 1.017l13.52-8.028c.183-.111.347-.25.491-.411z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "RegulationPool",
};
</script>
