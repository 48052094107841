export default {
  shifts: [],
  property_types: [],
  unit_healths: [],
  family_income_otpions: [],
  nationalities: [],
  logged_employee: {},
  team_linked: [],
  form_types: {
    CADASTRO_INDIVIDUAL: 1,
    CADASTRO_DOMICILIAR: 2,
    FICHA_DE_ATENDIMENTO_INDIVIDUAL: 3,
    FICHA_DE_ATENDIMENTO_ODONTOLÓGICO_INDIVIDUAL: 4,
    FICHA_DE_ATIVIDADE_COLETIVA: 5,
    FICHA_DE_PROCEDIMENTOS: 6,
    FICHA_DE_VISITA_DOMICILIAR: 7,
    MARCADORES_DE_CONSUMO_ALIMENTAR: 8,
    AVALIAÇÃO_DE_ELEGIBILIDADE_E_ADMISSÃO: 9,
    ATENDIMENTO_DOMICILIAR: 10,
    FICHA_ZIKA_MICROCEFALIA: 11,
    FICHA_DE_VACINAÇÃO: 12,
  },
};
