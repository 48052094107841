import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (context, variable) => {
  return AuthLegacyRequest.post(
    "exames2/controller-exame-escala-agendamento/obter-dados-ficha-controle-ambulatorial",
    variable,
  )
    .then(({ data }) => {
      data = HandleResponse(data);

      if (data?.dados?.corpo?.recordSet?.length > 0) {
        return data.dados.corpo.recordSet;
      }

      return null;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
