<template lang="html">
  <svg
    id="Capa_1"
    enable-background="new 0 0 512 512"
    height="512"
    viewBox="0 0 512 512"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="XMLID_74_">
      <g id="XMLID_118_">
        <path
          id="XMLID_214_"
          d="m430.5 254.5h-202.5v-54.833c0-21.689-17.645-39.334-39.333-39.334h-33.333c-7.761 0-14.451-4.607-17.525-11.221 15.188-4.979 26.191-19.281 26.191-36.112v-59.25c0-5.522-4.477-10-10-10h-18v-23.75h18c5.523 0 10-4.477 10-10s-4.477-10-10-10h-144c-5.523 0-10 4.477-10 10v492c0 5.522 4.477 10 10 10h40c5.523 0 10-4.478 10-10v-482h56v23.75h-18c-5.523 0-10 4.478-10 10v59.25c0 17.864 12.395 32.88 29.033 36.921 4.053 17.403 19.681 30.412 38.3 30.412h33.333c10.661 0 19.333 8.673 19.333 19.334v56.042c-9.827 2.221-18.54 7.41-25.134 14.584-8.753-14.542-24.69-24.293-42.865-24.293h-8c-27.57 0-50 22.43-50 50 0 11.248 3.735 21.638 10.027 30h-.027c-5.523 0-10 4.478-10 10v166c0 5.522 4.477 10 10 10h48c5.523 0 10-4.478 10-10v-108h102c5.523 0 10-4.478 10-10s-4.477-10-10-10h-112c-5.523 0-10 4.478-10 10v108h-28v-146h390v146h-28v-108c0-5.522-4.477-10-10-10h-112c-5.523 0-10 4.478-10 10s4.477 10 10 10h102v108c0 5.522 4.477 10 10 10h48c5.523 0 10-4.478 10-10v-166c0-44.94-36.56-81.5-81.5-81.5zm-390.5 237.5h-20v-472h20zm104-428.25v20h-36v-20zm-36 49.25v-9.25h36v9.25c0 9.925-8.075 18-18 18s-18-8.075-18-18zm24 213c-16.542 0-30-13.458-30-30s13.458-30 30-30h8c16.542 0 30 13.458 30 30s-13.458 30-30 30zm58 0v-22.75c0-15.853 12.897-28.75 28.75-28.75h211.75c30.506 0 55.897 22.328 60.688 51.5z"
        />
        <path
          id="XMLID_230_"
          d="m352 88h18v18c0 5.522 4.477 10 10 10h40c5.523 0 10-4.478 10-10v-18h18c5.523 0 10-4.478 10-10v-40c0-5.522-4.477-10-10-10h-18v-18c0-5.523-4.477-10-10-10h-40c-5.523 0-10 4.477-10 10v18h-18c-5.523 0-10 4.478-10 10v40c0 5.522 4.477 10 10 10zm10-40h18c5.523 0 10-4.478 10-10v-18h20v18c0 5.522 4.477 10 10 10h18v20h-18c-5.523 0-10 4.478-10 10v18h-20v-18c0-5.522-4.477-10-10-10h-18z"
        />
        <path
          id="XMLID_231_"
          d="m297 374c-2.63 0-5.21 1.069-7.07 2.93-1.86 1.86-2.93 4.44-2.93 7.07s1.07 5.21 2.93 7.069c1.86 1.86 4.44 2.931 7.07 2.931s5.21-1.07 7.07-2.931c1.86-1.859 2.93-4.439 2.93-7.069s-1.07-5.21-2.93-7.07c-1.86-1.861-4.44-2.93-7.07-2.93z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "HospitalizationOne",
};
</script>
