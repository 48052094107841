<template lang="html">
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="19" height="19" rx="5" fill="#0088aa" />
    <path
      d="M7.36086 14.7881C7.22723 14.9241 7.0449 15 6.8555 15C6.6661 15 6.48377 14.9241 6.35013 14.7881L2.31414 10.7035C1.89529 10.2796 1.89529 9.59238 2.31414 9.16935L2.8195 8.65785C3.23848 8.23402 3.91688 8.23402 4.33573 8.65785L6.8555 11.2077L13.6643 4.31787C14.0832 3.89404 14.7623 3.89404 15.1805 4.31787L15.6859 4.82937C16.1047 5.2532 16.1047 5.94033 15.6859 6.36349L7.36086 14.7881Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "CheckedBox",
};
</script>
