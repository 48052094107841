<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
export default {
  name: "RgComboboxOrderFilter",
  mixins: [RgComboboxMixin],
  data: () => {
    return {
      mData: [{ value: "exe_data_solicitacao", text: "Data de solicitação" }],
    };
  },
  mounted() {},
};
</script>
