import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import AppointmentPriority from "$appointment/submodules/register/views/appointment-priority/AppointmentPriority";
import { OpenMenuModule } from "~common/utils/app/menu";

export default {
  path: "/appointment/register/priority",
  name: "appointment.register.priority",
  components: {
    default: AppointmentPriority,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Consulta", link: OpenMenuModule("appointment") },
      {
        label: "Cadastro de Prioridades",
        link: "/appointment/register/priority",
      },
    ],
  },
};
