/**
 * @param {{ graphQLErrors: any[], networkError: any }} pError
 */
const HandleActionError = (pError) => {
  let message = "";

  if (pError.graphQLErrors && pError.graphQLErrors.length > 0) {
    message = pError.graphQLErrors[0].message;
  }

  if (
    pError.networkError &&
    pError.networkError.result.errors &&
    pError.networkError.result.errors.length > 0
  ) {
    message = pError.networkError.result.errors[0].message;
  }

  return { message };
};

module.exports = HandleActionError;
